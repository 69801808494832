import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteFiles, UserFile } from 'src/api'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { clickFile, setClickTreeOrlist, setPopupVisible, setPwd, setTreeTag } from './myFolderSlice'
import { Menu, message, Table, Tooltip } from 'antd'
import { Iconfont } from 'src/components'
import { handleDownload } from 'src/util'
import { ColumnsType } from 'antd/lib/table'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import copy from 'copy-to-clipboard'
import styles from './myTableDetail.module.scss'


interface MyTableDetailProps {
  files: any
  refresh: () => Promise<any>
}

export const MyTableDetailPage: React.FC<MyTableDetailProps> = (props) => {
  const dispatch = useDispatch()
  const { files, refresh } = props
  const { treeTag, pwd, PopupVisible } = useSelector((state) => state.myFolder)
  const [col, setCol] = useState<any>([])
  const [rowInfo, setRowInfo] = useState<any>({})
  const [location, setLocation] = useState<any>({
    x: 0,
    y: 0
  })

  const newArr = files?.map((item: any) => {
    delete item.children
    return item
  })



  const columns: ColumnsType<any> = [
    {
      title: '名称',
      dataIndex: 'connectionName',
      ellipsis: true,
      width: 300,
      render: (connectionName, _record, index) => (
        <Tooltip title={<span style={{wordBreak: 'break-all'}}>{connectionName}</span>}>
          <Iconfont type={_record?.isDir ? 'icon-wenjianjia' : 'icon-wenjian'} style={{ width: '20px' }} /> {connectionName}
        </Tooltip>
      )
    },
    {
      title: '修改日期',
      dataIndex: 'updateTime',
      width: 200
    },
    {
      title: '类型',
      dataIndex: 'fileType',
      width: 100
    },
    {
      title: '大小',
      dataIndex: 'size',
      width: 100
    },
    // {
    //   title: '创建人',
    //   dataIndex: 'userId',
    //   width: '200px'
    // }
  ]

 

  const newColumns = useMemo(() => {
    const aa = pwd?.path?.split('/').includes('systemSpace')
    if (aa) {
      columns.push(
        {
          title: '创建人',
          dataIndex: 'userId',
          width: 160
        }
      )
    }
    return columns
  }, [pwd?.path])

  useEffect(() => {
    setCol(newColumns)
    console.log(newColumns, '======newColumns')
  },[newColumns])

  const { run: tryDeleteFiles } = useRequest(deleteFiles, {
    manual: true,
    onSuccess: () => {
      message.success('删除成功')
      refresh()
      dispatch(setPwd(pwd?.path))
    }
  })

  const tryDownloadFile = useCallback((path: string) => {
    handleDownload({ href: `/export/download?path=${path}` })
  }, [])

  const onRowFun = (record: any) => {
    return {
      onClick: (event: any) => handleClick(event, record),
      onDoubleClick: (event: any) => handleDoubleClick(event, record),
      onContextMenu: (event: any) => handleContextMenu(event, record)
    }
  }

  const handleClick = (event: any, record: UserFile) => {

    const { path } = record
    dispatch(clickFile(path))
    dispatch(setTreeTag(path))
    event.stopPropagation()
  }

  const handleDoubleClick = (event: any, record: UserFile) => {
    const { type, path } = record
    if (type === 'file') {
      tryDownloadFile(path)
      return
    }
    if (type === 'directory') {
      dispatch(setPwd(path))
      refresh()
      return
    }
  }

  const handleContextMenu = (event: any, record: UserFile) => {
    event.preventDefault()
    const { type, path } = record
    setRowInfo(record)
    dispatch(setTreeTag(path))
    document.addEventListener(`click`, function onClickOutside() {
      dispatch(setPopupVisible(false))
      document.removeEventListener(`click`, onClickOutside)
    })
    dispatch(setPopupVisible(true))
    setLocation({
      x: event?.clientX,
      y: event?.clientY
    })
  }

  const Popup = () => {
    const { isSystemSpaceDirChild, path, type, isDir } = rowInfo
    let MenuItem = []
    if (isSystemSpaceDirChild) {
      MenuItem = [<Menu.Item key="copyPath" onClick={() => {
        copy(path)
        message.success('复制成功')
      }}>复制文件地址</Menu.Item>]
    } else {
      MenuItem = [
        <Menu.Item key="dele" onClick={() => tryDeleteFiles([path])}>删除</Menu.Item>,
        <Menu.Item key="rename" onClick={() => {
          dispatch(showModal('ReNameModal'))
          dispatch(setClickTreeOrlist('list'))
        }}>重命名</Menu.Item>,
        <Menu.Item key="move" onClick={() => {
          dispatch(showModal('MoveModal'))
        }}>移动</Menu.Item>,
        <Menu.Item key="copyPath" onClick={() => {
          copy(path)
          message.success('复制成功')
        }}>复制文件地址</Menu.Item>
      ]
      if (type !== 'directory' && !isDir) {
        MenuItem.unshift(<Menu.Item key="download" onClick={() => tryDownloadFile(path)}>下载</Menu.Item>)
      } else {
        MenuItem.push(<Menu.Item key="newFolder" onClick={() => dispatch(showModal('ModalNewFolder'))}>新建文件夹</Menu.Item>)
      }
    }
    return <Menu className={styles.popup} style={{ left: `${location?.x}px`, top: `${location?.y}px` }}>
      {
        MenuItem
      }
    </Menu>
  }

  return (
    <div className={styles.myTableWrap}>
      <Table
        className={styles.myTable}
        dataSource={newArr}
        columns={col}
        rowClassName={(re) => {
          return treeTag === re?.path ? styles.bgcolor : ''
        }}
        size='small'
        pagination={false}
        onRow={onRowFun}
        scroll={{ y: `calc(100vh - 300px)` }}
      />
      {
        PopupVisible && <Popup />
      }
    </div>
  )
}
