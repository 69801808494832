import React, { useState } from 'react'
import * as _ from 'lodash'
import { Row, Col, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { useRequest } from 'src/hook'
import { findExecuteDetail, executeDetailRes } from 'src/api'
import { ModalAuthorizationDetail } from './ModalAuthorizationDetail'
import { ModalFlowDetail } from 'src/pageTabs/flowPages/flowDetails'
import styles from './index.module.scss'

export const ExpandedRowContent = ({ record }: { record: any }) => {
  const { checkPermissionId, executeSql, executor, originalSql } = record
  const [visible, setVisible] = useState(false) // 流程详情 modal visible
  const [activeRecord, setActiveRecord] = useState<any>() // 流程详情 当前 流程 record记录

  const { data: detail, loading } = useRequest(
    () =>
      checkPermissionId &&
      executor &&
      findExecuteDetail({ userId: executor, checkPermissionId }),
    {
      refreshDeps: [executor, checkPermissionId],
    },
  )

  return (
    <div className={styles.rowContent}>
      <div style={{marginBottom: 4}}>操作SQL &nbsp; &nbsp; {originalSql}</div>
      <div>执行SQL &nbsp; &nbsp; {executeSql}</div>
      <div className={styles.operationList}>
        <Row>
          <Col span={6}>关联授权</Col>
          <Col span={6}>操作类型</Col>
          <Col span={6}>授权人</Col>
          <Col span={6}>授权时间</Col>
        </Row>
        {!_.isEmpty(detail) ? (
          detail.map((item: executeDetailRes) => (
            <Spin spinning={loading} key={item?.id}>
              <Row className={styles.operationItem} key={item?.id}>
                <Col
                  span={6}
                  className={styles.moreInfo}
                  onClick={() => {
                    setActiveRecord(item)
                    setVisible(true)
                  }}
                >
                  {item?.authedPermissionName}
                </Col>
                <Col span={6}>{item?.operateType}</Col>
                <Col span={6}>{item?.authUserName}</Col>
                <Col span={6}>{item.time}</Col>
              </Row>
            </Spin>
          ))
        ) : (
          <div
            style={{ textAlign: 'center', paddingTop: 10, color: '#667084' }}
          >
            暂无数据
          </div>
        )}
      </div>
      <ModalFlowDetail
        key={Number(activeRecord?.id)}
        record={activeRecord}
        visible={visible && activeRecord?.operateType === '审核'}
        applyId={Number(activeRecord?.id) as any}
        kind="approval"
        onCancel={() => setVisible(false)}
        footer={null}
      />
      <ModalAuthorizationDetail
        key={Number(activeRecord?.id)}
        visible={visible && activeRecord?.operateType === '授权'}
        record={activeRecord}
        onCancel={() => setVisible(false)}
      />
    </div>
  )
}
