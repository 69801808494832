import { Result } from 'antd';
import React from 'react';

export const NoAccess: React.FC = () => (
  <Result
    status="403"
    title="403"
    subTitle="对不起，您没有访问此资源的权限"
  />
);
