import React, { useState, useMemo } from 'react'
import { Form, Input, InputNumber, Select, Switch } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { ExportFormats, QueryResult } from 'src/api'
import { ExportApplyModal } from './ExportApplyModal'

interface IProp {
  visible: boolean
  result: QueryResult
  setVisible: (visible: boolean) => void
  hanldeExportAll: (data: any) => Promise<any>
  [p: string]: any
}

export const ResultAllExport: React.FC<IProp> = ({
  visible,
  setVisible,
  hanldeExportAll,
  result,
  permissionResult
}) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [addResultExportForm] = Form.useForm()
  const [visibleExportApply, setVisibleExportApply] = useState(false)

  const handleSubmitAddResultExport = () => {
    // 导出申请
    // 单次行数限制：全部导出，不管行数限制，提交的时候都强制走审批
    const {exportFlow, exportLimit, exportClearText } = permissionResult
    
    addResultExportForm.validateFields().then(d => {
      const { isExportClearText = false } = d
      if (exportFlow &&
        ((exportLimit == null && !exportClearText)
         || (exportLimit) 
         || (exportClearText && isExportClearText)
         )) 
      {
        setVisibleExportApply(true)
        setVisible(false)
        return
      }
      delete d.isExportClearText
      hanldeExportAll(d).then(() => setVisible(false))
        .finally(() => setConfirmLoading(false))
    })
  }

  const ExportSelectOptions = useMemo(
    () =>
      ExportFormats.filter(item => {
        // mongo目前只能导出成txt格式,先在这里过滤,以后更多类型支持了再优化
        if(result.dataSourceType === 'MongoDB'){
          return item === 'TXT'
        }
        return true
      }).map((format) => {
        if (format === 'SQL') {
          return {
            value: format,
            label: format,
            disabled: !result.resultSetSqlExport  || !permissionResult?.exportOther,
          }
        }
        if(format === 'PDF'){
          return {
            value: format,
            label: format,
            disabled: !permissionResult?.exportPdf,
          }
        }
        return {
          value: format,
          label: format,
          disabled: !permissionResult?.exportOther
        }
      }),
    [result.resultSetSqlExport, permissionResult?.exportOther],
  )
  const initExportFormatValuie = ExportSelectOptions?.filter(i=>!i?.disabled)[0]?.value
  return (
    <>
    <UIModal
      title="全部导出"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleSubmitAddResultExport}
      afterClose={() => addResultExportForm.resetFields()}
      width={480}
      confirmLoading={confirmLoading}
    >
      <Form form={addResultExportForm} {...FormLayout}>
        <Form.Item
          name="fileName"
          label="文件名"
          rules={[{ required: true, message: '请填写文件名' }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="exportFormat"
          label="导出格式"
          rules={[{ required: true, message: '请选择导出格式' }]}
          initialValue={initExportFormatValuie}
        >
          <Select options={ExportSelectOptions}></Select>
        </Form.Item>
        {
          permissionResult?.exportClearText && 
          <Form.Item
            name="isExportClearText"
            label="是否导出明文"
          >
            <Switch checkedChildren="是" unCheckedChildren="否" />
          </Form.Item>
        }
        <div className="ant-row">
          <div className="ant-col ant-col-8"></div>
          <div className="ant-col ant-col-13" style={{ fontSize: '13px' }}>数据量过大时，建议使用“常规导出”分批次执行</div>
        </div>
      </Form>
    </UIModal>
    {
      visibleExportApply && 
      <ExportApplyModal
        data={result}
        exportForm={addResultExportForm}
        visible={visibleExportApply}
        setVisible={setVisibleExportApply}
        object={"execute"}
        exportType="allExport"
      />
    }
    </>
  )
}
