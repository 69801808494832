import React, { useCallback, useMemo, useState } from 'react'
import { Tooltip, Dropdown, Menu, Button, Divider, Slider } from 'antd'
import { Iconfont, LinkButton } from 'src/components'
import classNames from 'classnames'
import styles from './toolbar.module.scss'
import Service from 'src/service'
import { PaneType } from '../../queryTabs/queryTabsSlice'
import { DataSourceType, getCountOfQueryResult, QueryBase, stopCountOfQueryResult } from 'src/api'
import { useRequest } from 'src/hook'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'
import { EyeInvisibleOutlined } from '@ant-design/icons'

const toolbarActionTypes = [
  'refresh',
  'add',
  'delete',
  'confirm',
  'cancel',
  'view',
  'plaintext',
] as const
export type ToolbarActionType = typeof toolbarActionTypes[number]

interface ToolbarAction {
  action: ToolbarActionType
  title: string
  isDivider?: boolean
  iconName: string
}

interface ToolbarProps {
  showActions?: boolean
  enabledActions?: ToolbarActionType[]
  readOnly?: boolean
  onRefresh?: () => void
  onAddRow?: () => void
  onDeleteRow?: () => void
  onConfirmModify?: () => void
  onScalePage?: (num: number) => void
  onCancelModify?: () => void
  onViewCell?: () => void
  isDataExportPermissionEnabled: boolean
  showExported: boolean
  applyDataExportPermission: () => void
  onOpenExportModal: (key: string) => void
  onOpenFieldsModal?: () => void
  paneType?: PaneType
  refreshable?: boolean
  /** 当前连接的类型**/
  connectionType?: DataSourceType;
  // 执行 SQL 的时候必需的关键参数，获取结果总行数时是对 SQL 语句用COUNT(*)包裹后的结果
  executePayload?: QueryBase
  scale?: number
  type?: string
  permissionResult?: any
  dataExport?: any
  /** 列过滤展示 */
  filterNames?: any[]
  txMode?: 'auto' | 'manual'
  isDesensitized?: boolean
}

const toolbarActions: ToolbarAction[] = [
  {
    action: 'refresh',
    title: '刷新',
    isDivider: true,
    iconName: 'icon-sync-alt',
  },
  {
    action: 'add',
    title: '添加',
    iconName: 'icon-add-ag',
  },
  {
    action: 'delete',
    title: '删除',
    isDivider: true,
    iconName: 'icon-jianqu-copy-copy',
  },
  {
    action: 'confirm',
    title: '锁定',
    iconName: 'icon-queren-copy1',
  },
  {
    action: 'cancel',
    title: '取消',
    isDivider: true,
    iconName: 'icon-chehui',
  },
  {
    action: 'view',
    title: '查看行/单元格',
    iconName: 'icon-chakan',
  },
  {
    action: 'plaintext',
    title: '查看明文',
    iconName: 'icon-chakanmingwen',
  },
]

const defaultEnabledActions = toolbarActions.map(({ action }) => action)

export const ResultToolbar: React.FC<ToolbarProps> = (props) => {
  const [hasRequiredTotalCount, setHasRequiredTotalCount] = useState(false)
  const { handleExternalInfo } = useHandleExternalInfo()

  const {
    data: fetchTotalResponse,
    run: queryingCount,
    loading: queryingCountLoading,
  } = useRequest(getCountOfQueryResult, {
    manual: true,
  })

  const realTotalCount = useMemo(
    () => {
      if (fetchTotalResponse) { 
        handleExternalInfo({ type: 'LOG', msg: fetchTotalResponse.executeLogInfo.message })
        return fetchTotalResponse.count
      }
      return
    },
    [fetchTotalResponse, handleExternalInfo],
  )

  const { run: stopQueryingCount } = useRequest(stopCountOfQueryResult, {
    manual: true,
  })
  // eg like this.
  // stopQueryingCount({
  //   tabKey: executePayload?.tabKey!,
  //   connectionId: executePayload?.connectionId!,
  //   databaseName: executePayload?.databaseName!,
  // })

  const {
    showActions = true,
    enabledActions = defaultEnabledActions,
    readOnly,
    onRefresh,
    onAddRow,
    onDeleteRow,
    onConfirmModify,
    onScalePage,
    onCancelModify,
    onViewCell,
    applyDataExportPermission,
    isDataExportPermissionEnabled,
    onOpenExportModal,
    onOpenFieldsModal,
    paneType,
    showExported,
    refreshable,
    connectionType,
    executePayload,
    scale = 100,
    type,
    permissionResult,
    dataExport,
    filterNames,
    txMode,
    isDesensitized,
  } = props

  const {resultSetCount, exportSelectValue, exportDefault} = permissionResult || {}

  const isActionEnable = useCallback(
    (action: ToolbarActionType) => {
      /** db2 时 禁用其他操作 只返回刷新**/
      if (connectionType === "DB2") {
        return action === 'refresh'
      }
      /** 星环 不支持增加和删除 **/
      if (connectionType === 'Inceptor' || connectionType === 'StarRocks') {
        if (action === 'delete' || action === 'add') return false
      }
      // 只读情况下（editable为false），新增行和删除行不可用
      if (readOnly) {
        return action !== 'delete' && action !== 'add'
      }
      
      // view 不需要和其他权限比如删除等绑定到一起
      if ((paneType !== 'tSql') || action === 'view') {
        return (refreshable !== undefined && refreshable) && enabledActions.includes(action)
      } else {
        return !readOnly && enabledActions.includes(action)
      }
    },
    [enabledActions, readOnly, paneType, refreshable],
  )

  const getToolbarActionIcon = (
    action: ToolbarActionType,
    iconName: ToolbarAction['iconName'],
  ) => {
    let icon = null
    switch (action) {
      case 'refresh':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
              !isActionEnable('refresh') && styles.disabled,
            )}
            onClick={() => {
              if (isActionEnable('cancel') && onCancelModify) {
                onCancelModify()
              }
              if (isActionEnable('refresh') && onRefresh) {
                onRefresh()
              }
            }}
          />
        )
        break
      case 'add':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
              !isActionEnable('add') && styles.disabled,
            )}
            onClick={() => {
              if (isActionEnable('add') && onAddRow) onAddRow()
            }}
          />
        )
        break
      case 'delete':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
              !isActionEnable('delete') && styles.disabled,
            )}
            onClick={() => {
              if (isActionEnable('delete') && onDeleteRow) onDeleteRow()
            }}
          />
        )
        break
      case 'confirm':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
              !isActionEnable('confirm') && styles.disabled,
            )}
            onClick={() => {
              if (isActionEnable('confirm') && onConfirmModify)
                onConfirmModify()
            }}
          />
        )
        break
      case 'cancel':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
              !isActionEnable('cancel') && styles.disabled,
            )}
            onClick={() => {
              if (isActionEnable('cancel') && onCancelModify) onCancelModify()
            }}
          />
        )
        break
      case 'view':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
              !isActionEnable('view') && styles.disabled,
            )}
            onClick={() => {
              onViewCell && onViewCell()
            }}
          />
        )
        break
      case 'plaintext':
        icon = (
          <Iconfont
            type={iconName}
            className={classNames(
              styles.toolbarIcon,
            )}
            onClick={() => {
              onOpenFieldsModal && onOpenFieldsModal()
            }}
          />
        )
        break
    }
    return icon
  }

  // const exportHandleMap = {
  //   ResultExport: () => {
  //     onOpenExportModal('AddResultExport')
  //     dispatch(showModal('AddResultExport'))
  //   },
  //   SelectedResultExport: () => {
  //     dispatch(showModal('AddSelectedResultExport'))
  //   },
  // }
  const handleExportClick = (key: any) => {

    onOpenExportModal(key)
  }

  const handleQueryCount = () => {
    const params = {
      ...executePayload,
      autoCommit: txMode === 'auto'
    }
    queryingCount(params).then(() => {
      setHasRequiredTotalCount(true)
    })
  }

  const exportRender = (
    <Menu onClick={({ key }) => handleExportClick(key as any)}>
      {connectionType !== "Hive" && showExported && <Menu.Item key="ResultExport" disabled={!exportDefault}>常规导出</Menu.Item>}
      {connectionType !== "MongoDB" && exportSelectValue && <Menu.Item key="SelectedResultExport" disabled={!exportSelectValue}>选中导出</Menu.Item>}
      {connectionType !== "Hive" && <Menu.Item key="ResultAllExport" disabled={!exportDefault}>全部导出</Menu.Item>}
    </Menu>
  )

  const applyDataExportPermissionRender = Service.moduleService.isModuleExist(
    '/flow',
  ) && dataExport?.status ? (
    <Button onClick={applyDataExportPermission as any} size="small" type="link">
      申请导出权限
    </Button>
  ) : (
    <span className={styles.exportButton}>暂无导出权限</span>
  )

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarLeft}>
        {showActions &&
          toolbarActions.map(({ action, title, isDivider, iconName }) => {
            if(action === 'plaintext' && !isDesensitized){
              return undefined
            }
            return <span key={action}>
              <Tooltip title={title} placement="bottomLeft">
                {getToolbarActionIcon(action, iconName)}
              </Tooltip>
              {isDivider && (
                <Divider type="vertical" className={styles.divider}></Divider>
              )}
            </span>
          })}
      </div>
      <div className={styles.toolbarRight}>
        {
        type !== 'explain' && 
          <div className={styles.scaleWrapper}>
            <div className={styles.scaleContent}>
              <Tooltip title="缩小">
                <Iconfont
                  type="icon-jianqu-copy-copy"
                  className={classNames(styles.toolbarIcon,
                    scale <=70 &&styles.disabled
                  ) }
                  onClick={() => onScalePage  && scale >70 && onScalePage(scale - 10)}
                />
              </Tooltip>
              <Slider
                value={scale}
                max={200}
                min={70}
                step={10}
                className={styles.toolbarSilder}
                tipFormatter={(val?: number) => `${val}x`}
                onChange={(val: number) => {
                  if (onScalePage) onScalePage(val)
                }}
              />
              <Tooltip title="放大">
                <Iconfont
                  type="icon-add-ag"
                  className={classNames(styles.toolbarIcon,
                    scale >=200&& styles.disabled ) }
                  onClick={() => scale <200 && onScalePage && onScalePage(scale + 10)}
                />
              </Tooltip>
            </div>
          </div>
        }
        {isDataExportPermissionEnabled ? (
          <Dropdown overlay={exportRender}>
            <Iconfont
              type="icon-xiazai"
              className={classNames(styles.toolbarIcon)}
            />
          </Dropdown>
        ) : (
          applyDataExportPermissionRender
        )}
        {filterNames && filterNames?.length > 0 &&
          <Tooltip title={`列过滤字段：${filterNames}`}>
            <EyeInvisibleOutlined style={{ fontSize: '16px' }} />
          </Tooltip>
        }
        {refreshable && connectionType !== 'Hive' && connectionType !== 'Inceptor' &&
          (hasRequiredTotalCount ? (
            <span>{`结果总行数: ${realTotalCount || 0}`}</span>
          ) : (
            <LinkButton
              onClick={handleQueryCount}
              disabled={hasRequiredTotalCount || !resultSetCount}
              loading={queryingCountLoading}
              // 与工具栏的样式风格统一
              className={styles.toolbarIcon}
              style={{ fontSize: 14, fontWeight: 'normal', padding: '0 5px' }}
            >
              查询结果总行数
            </LinkButton>
          ))}
      </div>
    </div>
  )
}
