import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Space, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { ConnectionIcon, Iconfont } from 'src/components'
import { setVisibleNodeDetail } from 'src/pageTabs/queryPage/queryPageSlice'
import { getNodeDetail, NodeDetailResponse } from 'src/api'

import styles from './index.module.scss'

export const NodeDetailPane: React.FC = () => {
  const [nodeDetail, setNodeDetail] = useState<NodeDetailResponse | null>(null)
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false)
  const { selectedNode } = useSelector((state) => state.sdt)
  const { visibleNodeDetail, visibleTaskPane, visibleApplicationShop } = useSelector(
    (state) => state.queryPage,
  )
  const dispatch = useDispatch()

  const { nodeName, nodeType } = selectedNode
  const { childCount, childType, connectionType } = nodeDetail || {}
  const isConnection = nodeType === 'connection'
  const showChild = childCount === null || childCount === undefined

  const getSubTitle = () => {
    if (isConnection) {
      return connectionType
    }
    return showChild ? nodeType : `${childCount} ${childType}`
  }

  useEffect(() => {
    if (!visibleNodeDetail || !selectedNode.key) return
    const {
      connectionId,
      connectionType,
      nodeName,
      nodePath,
      nodeType,
      groupId,
    } = selectedNode
    setLoadingDetail(true)
    getNodeDetail({
      connectionId,
      connectionType,
      nodeType,
      nodeName,
      nodePath,
      groupId,
    })
      .then((data) => {
        setNodeDetail(data)
      })
      .catch(() => {
        setNodeDetail(null)
      })
      .finally(() => {
        setLoadingDetail(false)
      })
  }, [selectedNode, visibleNodeDetail])

  const noInfoTip = <div className={styles.noInfoTip}>未选择树节点</div>

  if (visibleTaskPane) {
    return null
  }

  if (visibleApplicationShop) {
    return null
  }

  if (!visibleNodeDetail) {
    return (
      <div
        className={styles.nodeDetailInActiveWrapper}
        onClick={() => {
          dispatch(setVisibleNodeDetail(true))
        }}
      >
        <Iconfont type="icon-info-circle-filled" />
        <div className={styles.nodeName}>{nodeName || '暂无树节点信息'}</div>
      </div>
    )
  }

  return (
    <div className={styles.nodeDetailActiveWrapper}>
      <div className={styles.nodeDetailHeader}>
        <div>
          <Iconfont
            type="icon-info-circle-filled"
            style={{ color: '#4384ec', marginRight: 8 }}
          />
          树节点信息
        </div>
        <div>
          <CloseOutlined
            className={styles.nodeDetailClose}
            onClick={() => {
              dispatch(setVisibleNodeDetail(false))
            }}
          />
        </div>
      </div>
      <div className={styles.nodeDetailContent}>
        {!selectedNode.key && noInfoTip}
        {selectedNode.key && (
          <Spin spinning={loadingDetail}>
            <Space
              className={styles.nodeDetailFields}
              direction="vertical"
              size={16}
            >
              <div className={styles.fieldGeneral}>
                {isConnection && (
                  <div className={styles.fieldGeneralIcon}>
                    <ConnectionIcon type={connectionType!} fontSize={40} />
                  </div>
                )}
                <div>
                  <div className={styles.fieldGeneralTitle}>{nodeName}</div>
                  <div className={styles.fieldGeneralSubTitle}>
                    {getSubTitle()}
                  </div>
                </div>
              </div>
              {nodeDetail &&
                Object.entries(nodeDetail.nodeOptionInfo)
                  .filter(([, value]) => value !== null)
                  .map(([key, value]) => {
                    return (
                      <div className={styles.fieldItem} key={key}>
                        <div className={styles.fieldKey}>{key}</div>
                        <div className={styles.fieldValue}>{String(value)}</div>
                      </div>
                    )
                  })}
            </Space>
          </Spin>
        )}
      </div>
    </div>
  )
}
