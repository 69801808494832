import { useEffect, useState } from 'react'
import { getSyncSwitch } from 'src/api'
import { useRequest } from 'src/hook'

// 获取数据字典系统同步开关
export function useDictSearch() {
  const [dictSearchIsOn, setDictSearchIsOn] = useState(false)
  const { data } = useRequest(getSyncSwitch)
  useEffect(() => {
    if (data !== undefined) {
      setDictSearchIsOn(data)
    }
  }, [data])

  return {
    dictSearchIsOn,
    setDictSearchIsOn,
  }
}
