import React, { useState } from "react";
import { Tabs, Input, Button, Space } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { exportStrictModeLog } from "src/api";
import { useRequest } from "src/hook";
import { ParsingFallStatement } from "./ParsingFallStatement";
import styles from "./index.module.scss";
import { handleDownload } from 'src/util'
import { openNotification } from 'src/components/ExportNotification'

export const ParsingResTabs = () => {
  const [searchValue, setSearchValue] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("FALL");

  const { run: exportLog } = useRequest(exportStrictModeLog, { manual: true });

  const PARSING_TABS: { [k: string]: string } = {
    FALL: "解析失败语句",
  };

    const exportData = () => {
        exportStrictModeLog({ sqlName: searchValue }).then(res => {
            const origin = window?.location?.origin;
            openNotification(
                '任务 解析失败导出',
                '已经执行完成，文件生成成功',
                () => handleDownload({ href: `${origin}/export/export/download/${res}` })
            )
        })
    }

  const tabExtraContent = {
    right: (
      <Space>
        <Input
          width={136}
          allowClear
          placeholder="搜索SQL语句"
          prefix={<SearchOutlined />}
          onChange={(e: any) => {
            setSearchValue(e.target.value)
          }}
        />
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={exportData}
        >
          导出
        </Button>
      </Space>
    ),
  };

  return (
    <div className={styles.parsingResultContainer}>
      <Tabs
        className={styles.resultTab}
        activeKey={activeTabKey}
        onChange={(key) => setActiveTabKey(key)}
        tabBarExtraContent={tabExtraContent}
      >
        {Object.keys(PARSING_TABS).map((tab) => (
          <Tabs.TabPane tab={PARSING_TABS[tab]} key={tab} />
        ))}
      </Tabs>
      {activeTabKey === "FALL" && (
        <ParsingFallStatement searchValue={searchValue} />
      )}
    </div>
  );
};
