import React from 'react'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { MENU_FLOW } from 'src/constants';
import { SimpleBreadcrumbs } from 'src/components'
import { SearchTablePage } from './search-table'
import styles from './index.module.scss'


const breadcrumbData = [
	{ title: MENU_FLOW },
	{ 
		title: "我的申请",
		href: '/mine_apply',
  },
	{
		separator: '/',
		title: "资源搜索"
  }
];

export const MyApplyResourceSearchPage = () => {

	return (
		<div className={styles.resourceWrap}>
			<SimpleBreadcrumbs items={breadcrumbData} />
			<div className={styles.content}>
        <ErrorBoundary>
				  <SearchTablePage />
        </ErrorBoundary>
			</div>
		</div>
	)
}
