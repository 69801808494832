/*
 * @Author: yangchao yangchao@bintools.cn
 * @Date: 2023-10-08 10:03:22
 * @LastEditors: yangchao yangchao@bintools.cn
 * @LastEditTime: 2023-10-08 13:51:28
 * @FilePath: /cq-enterprise-frontend/src/hook/useHandleExternalInfo.ts
 * @Description: 
 */
import { addLog } from "src/store/extraSlice/logsSlice"
import { useDispatch } from "./reactReduxHooks"
import { useDownloadNotificationRenderer } from "src/features/websocket/useDownloadNotification"
import { pushBatchCreateConnectionLog } from "src/pageTabs/queryPage/sdt"
import { setTaskProgressMap } from "src/pageTabs/downloadPage/downloadPageSlice"
import { useCallback } from "react"
import { useRequest } from "./useRequest"
import { addSegmentMsg } from "src/pageTabs/queryPage/resultTabs/resultTabsSlice"

export const ExternalInfoType = ['LOG', 'DOWNLOAD', 'BATCH_CREATE_CONNECTION', 'PROGRESS']
export type TExternalInfo = typeof ExternalInfoType[number]

interface IExternalInfo {
  type: TExternalInfo,
  msg: any
}

/**
 * @description: 轮询间隔（单位ms）
 * @return {*}
 */
const pollingTime = 300

/**
 * @description: 统一处理轮询结果
 * @return {*}
 */

export const useHandleExternalInfo = (handle?: any, cb?: any) => {
  const dispatch = useDispatch()
  const [renderNotification] = useDownloadNotificationRenderer()
  const handleExternalInfo = useCallback((data: IExternalInfo) => {
    try {
      const { type, msg } = data
      switch (type) {
        // 执行日志
        case 'LOG': {
          dispatch(addLog([msg]))
          return
        }
        // 导出完成
        case 'DOWNLOAD': {
          renderNotification(JSON.parse(msg))
          return
        }

        case 'SEGMENT_EXECUTION':
          dispatch(addSegmentMsg(msg))
          return

        /* 批量创建连接 */
        case 'BATCH_CREATE_CONNECTION':
          dispatch(pushBatchCreateConnectionLog(msg))
          break
        /* 批量导入用户 */
        case 'BATCH_CREATE_USER':
          //这里偷个懒，直接用批量创建连接的模块存错位信息
          dispatch(pushBatchCreateConnectionLog(msg))
          break
        /* 导出进度 */
        case 'PROGRESS':
          dispatch(setTaskProgressMap(msg))
          break
        default:
          break
      }
    } catch (error) {}
  }, [dispatch, renderNotification])

  const {
    run: fetchTaskResult,
    cancel: cancelFetchTaskResult
  } = useRequest(
    handle,
    {
      manual: true,
      pollingInterval: pollingTime,
      onSuccess: (data, params) => {
        // 只有收到返回结果且处于RUNNING状态才需要持续轮询
        if (!(data && data.executeStatus === 'RUNNING')) {
          cancelFetchTaskResult()
        }
        if (data && data.messageData) {
          cb && cb(data.messageData)
        }
      },
      // 考虑onError时是否需要停止轮询
    }
  )
  
  return { handleExternalInfo, fetchTaskResult }
}