import React, { useEffect, useState } from 'react'
import {
  Card,
  message,
  Form,
  Upload,
  Modal,
  Image,
  Tooltip
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import ImageCropper from 'antd-image-cropper'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useRequest } from 'src/hook'
import { getLogo, getBackImg, deleteImgLogo } from 'src/api'
import styles from './index.module.scss'
import 'src/styles/layout.scss'
import Logo from 'src/assets/logo-text.png'
import BackImg from 'src/assets/img/img.png'
import PlaceholderImg from 'src/assets/img/placeholder-img.jpg'

export const LogoImageCard = () => {
  const [modalVisibleFlag, setModalVisibleFlag] = useState(false)
  const [saveImgSrc, setSaveImgSrc] = useState('')
  const [imglogoThumbUrl, setImgLogoThumbUrl] = useState('')
  const baseAddress = "/user";

  const [logoImg, setlogoImg] = useState<UploadFile[]>([
    //@ts-ignore
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: Logo,
    }
  ]);

  const [backImg, setBackImg] = useState<UploadFile[]>([
    //@ts-ignore
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: BackImg,
    }
  ]);

  const { data: logoUrl, loading: logoLoading } = useRequest(getLogo, {
    onSuccess: (res) => {
      let img = {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: res && res != "" ? baseAddress + res : Logo,
      }

      logoImg.pop()
      //@ts-ignore
      logoImg.push(img)
      setlogoImg([...logoImg])
    }
  })
  const { data: backImgUrl, loading: backLoading } = useRequest(getBackImg, {
    onSuccess: (res) => {
      let img = {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: res && res != "" ? baseAddress + res : BackImg,
      }

      backImg.pop()
      //@ts-ignore
      backImg.push(img)
      setBackImg([...backImg])
    }
  })


  useEffect(() => {
    if (logoImg && logoImg.length) {
      getImgLogo(logoImg[0])
    }
  }, [logoImg])

  const getImgLogo = async (img: any) => {
    // 缩略图默认1:1正方形 要转成上传比例回显
    let src = ''
    if (img?.originFileObj) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(img?.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setImgLogoThumbUrl(src)
  }


  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'; 
    if (!isJpgOrPng) {
      message.error('只支持jpeg|png|jpg格式的图片');
    }
    const isLt2M = file.size / 1024 < 200;
    if (!isLt2M) {
      message.error('图片不能超过200kb!');
    }
    return isJpgOrPng && isLt2M;
  };

  const onChangeLogo: UploadProps['onChange'] = ({ fileList: newFileList, event, file }) => {
    if (event) {
      message.success('公司logo上传成功')
    }
    setlogoImg(newFileList);
  };

  const onChangeBackImg: UploadProps['onChange'] = ({ fileList: newFileList, event, file }) => {
    if (event) {
      message.success('背景图片上传成功')
    }
    setBackImg(newFileList);
  };

  const onRemoveLogo = (val: any) => {
    let flag = false
    Modal.confirm({
      content: '确定要删除公司logo吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        flag = true
        logoDeleteRun('logo')
        setlogoImg([]);
      },
      onCancel() {
        flag = false
      },
    });
    return flag
  }

  const onRemoveBackImg = (val: any) => {
    let flag = false
    Modal.confirm({
      content: '确定要删除背景图片吗?',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        // logoFlag = true
        logoDeleteRun('background')
        setBackImg([]);
      },
      onCancel() {
        flag = false
      }
    });
    return flag
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setSaveImgSrc(src)
    setModalVisibleFlag(true)
  };

  const { data: logoData, run: logoDeleteRun } = useRequest(deleteImgLogo, {
    manual: true,
    formatResult: ({ key }) => key,
    onSuccess: (key: string) => {
      message.success('删除成功')
    },
  })


  const imgprops = {
    aspectRatio: 11 / 4,
    grid: true,
    crop: true,
    modalTitle: '上传图片',
    modalOk: '上传',
    minWidth: 50,
    minHeight: 50,
  }

  return (
    <section className="cq-new-card flow-card" id="LogoImageCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title" style={{ flex: 1 }}>
          Logo替换
          <Tooltip title="下次页面刷新更新logo">
            <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
          </Tooltip>
        </h3>
        <h3 className="cq-card__title" style={{ flex: 1 }}>预览</h3>
      </div>
      <section className="card__content" style={{ display: 'flex' }}>
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={backLoading && logoLoading}
          style={{ flex: '50%' }}
        >
          <Form.Item label={
            <>
              公司logo
              <Tooltip title="建议上传图片比例为11:4，图片格式为 png、jpg、jpeg，图片大小不超过 200KB">
                <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
              </Tooltip>
            </>
          }>
            <Form.Item
              name="logo"
              noStyle
            >
              <ImageCropper {...imgprops}>
                <Upload
                  action="/user/sys/logo"
                  listType="picture-card"
                  fileList={logoImg}
                  beforeUpload={beforeUpload}
                  onChange={onChangeLogo}
                  onPreview={onPreview}
                  onRemove={onRemoveLogo}
                // showUploadList={{showRemoveIcon: editing}}
                >
                  {logoImg.length < 1 && '+ 上传图片'}
                </Upload>
              </ImageCropper>
            </Form.Item>
          </Form.Item>
          {/* <Form.Item label={
            <>
              登录背景图
              <Tooltip title="建议上传图片比例为1:1，图片格式为 png、jpg、jpeg，图片大小不超过 200KB">
                <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
              </Tooltip>
            </>
          }>
            <Form.Item
              name="logoBak"
              noStyle
            >
              <ImageCropper {...imgprops}>
                <Upload
                  action="/user/sys/background"
                  listType="picture-card"
                  fileList={backImg}
                  beforeUpload={beforeUpload}
                  onChange={onChangeBackImg}
                  onPreview={onPreview}
                  onRemove={onRemoveBackImg}
                //   showUploadList={{showRemoveIcon: editing}}
                >
                  {backImg.length < 1 && '+ 上传图片'}
                </Upload>
              </ImageCropper>
            </Form.Item>
          </Form.Item> */}
        </Card>
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={backLoading && logoLoading}
          style={{ flex: '50%', position: 'relative' }}
        >
          <img src={PlaceholderImg} style={{ width: '90%' }} />
          <img src={logoImg && logoImg.length ? (logoImg[0].url ? logoImg[0].url : imglogoThumbUrl) : Logo} style={{ position: 'absolute', zIndex: 1000, left: '170px', top: '70px', width: '30%' }} />
          {/* <img src={backImg && backImg.length ? (backImg[0].url ? backImg[0].url : backImg[0].thumbUrl) : BackImg} style={{ position: 'absolute', width: '50%', left: '24px' }} /> */}
        </Card>
      </section>
      <Modal
        visible={modalVisibleFlag}
        onCancel={() => setModalVisibleFlag(false)}
        footer={null}
        width={500}
      >
        <div style={{ textAlign: 'center' }}>
          <Image src={saveImgSrc} />
        </div>
      </Modal>
    </section>
  )
}
