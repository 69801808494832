import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Card,
  message,
  Form,
  Typography,
  Radio,
  Input,
  Button,
  Row,
  Col,
  Tooltip,
  Select,
  Divider,
  Spin,
  Modal,
  Space,
  Tag,
  AutoComplete
} from 'antd'
import * as _ from 'lodash'
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { useRequest, useSelector } from 'src/hook'
import { FormInstance } from 'antd/lib/form'
import { LinkButton } from 'src/components'
import { SettingFormContext } from '../SettingFormContext'
import {
  getSms,
  updateSms,
  smsTestPhone,
  getUserLoginSetting,
  putUserLoginSetting,
  convertMapToStringMap,
  twoArrToMap,
  validatePhone,
  validateJson,
  getParam,
} from 'src/api'
import styles from './index.module.scss'
import { useForm } from 'rc-field-form'
import { SMSChannelEnum, typeEnum } from 'src/constants'
import { useLocation } from 'react-router-dom'

const { Text } = Typography

interface IJsonParams {
  id: number, 
  inputs: string[]
}

interface IProps {
  tabKey: string,
  [p: string]: any
}

export const PhoneMsmCard = (props: IProps) => {
  const {tabKey, setCurrent, current} = props

  const { userInfo } = useSelector(state => state.login)
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const FormLayout = {
    labelCol: { span: 5 },
    wrapperCol: { offset: 1, span: 14 },
  }

  const state = useLocation()?.state as {record: any, actionType: string}

  // headers HTTP头部
  const [linesHeaders, setLinesHeaders] = useState<IJsonParams[]>([]);// 输入框数据[id,input]
  const [errorHeaders, setErrorHeaders] = useState('');
  // urlParams API参数
  const [linesUrlParams, setLinesUrlParams] = useState<IJsonParams[]>([]);  // input
  const [errorUrlParams, setErrorUrlParams] = useState(''); // 报错
  // params 参数
  const [linesParams, setLinesParams] = useState<IJsonParams[]>([]);
  const [errorParams, setErrorParams] = useState('');
  const [editing, setEditing] = useState(false)
  const [method, setMethod] = useState<string>('get')
  const [smsChannel, setSmsChannel] = useState<string>('')
  const [settingData, setSettingData] = useState<any>()
  const [optionLoading, setOptionLoading] = useState(false)
  const [showValidate, setShowValidate] = useState(false);
  //测试成功后填写的参数
  const [valueOfSuccessfulTest, setValueOfSuccessfulTest] = useState<any>({});

  const [businessTypeOptions, setBusineseTypeOptions] = useState<any>();
  const [actionType, setActionType] = useState<string>('');
  // 字段-参数
  const [paramParams, setParamParams] = useState<any>([]);
  const [curBusinessType, setCurBusinessType] = useState<string>('') // 生效告警类型

  const { loading, run: getSmsRun } = useRequest(getSms, {manual: true})

  //获取登录方式
  const {data: loginSetting, run: runGetLoginSetting } = useRequest(getUserLoginSetting, {
    manual: true
  }) 

   //获取生效告警类型对应的参数，提示值
   const { run: runGetParam } = useRequest(getParam, {
    manual: true,
    onSuccess: (res: any)=>{
      setParamParams(res?.map((item: {name: string, description: string})=>({
        label:(
                <Space>
                  <span>{item?.name}</span>
                  {item?.description && <Tag color="blue">{item?.description}</Tag>}
                </Space>
              ), 
        value: item?.name
      }
      )))
  }
})

  // 生效告警类型 options
  const getBusinessTypeOptions = () => {
    let options: {value: string, label: string}[] = []
    for (const key in typeEnum) {
      if (typeEnum.hasOwnProperty(key)) {
        let labelTmp = typeEnum[key as keyof typeof typeEnum] 
        options.push({
          value: key,
          label: ['CPU_USAGE', 'MEMORY_USAGE', 'UPLOAD_FILE_SIZE', 'DOWNLOAD_FILE_SIZE', 'IMPORT_TASK_COUNT', 'EXPORT_TASK_COUNT', 'LICENSE_REMAINING'].includes(key) ?
          labelTmp + '超过阈值' : labelTmp
        })
      } else {
        console.log('Key not found');
      }
    }
    setBusineseTypeOptions(options)
  }

  const setFormVal = useCallback(() => {
    form.setFieldsValue({loginSetting: loginSetting?.toString()})
    if (settingData) {
      form.setFieldsValue({...settingData})
    } else {
      form.setFieldsValue({'method': 'get'})
    }
    // 告警配置-复制，清空 通道名称、生效告警类型、与告警类型相关的参数
    if (actionType === "copy") {
      ['smsName', 'businessType', 'content', 'param', 'urlParams', 'body', 'headers'].forEach((item: any) => {
        const fields: any = {};
        fields[item] = undefined;
        form.setFieldsValue(fields);
      })
      // form.setFieldsValue({'smsName': undefined})
      // form.setFieldsValue({'businessType': undefined})
      // form.setFieldsValue({'content': undefined}) // 消息内容

      // form.setFieldsValue({'param': undefined}) // 参数
      // form.setFieldsValue({'urlParams': undefined})// API参数
      // form.setFieldsValue({'body': undefined})// body参数
      // form.setFieldsValue({'headers': undefined})// HTTP头部
    }
    const { urlParams, headers, templateParam } = settingData || {}
    let urlParamsTmp: any=[];
    let headerTmp: any=[];
    let paramsTmp: any=[];
    if (urlParams && Object.entries(urlParams).length !== 0) {
      Object.keys(urlParams).forEach((item: string) => {
        urlParamsTmp.push({id:urlParamsTmp.length, inputs: [item, urlParams[item]]})
      })
    }
    if (headers && Object.entries(headers).length !== 0) {
      Object.keys(headers).forEach((item: string) => {
        headerTmp.push({id:headerTmp.length, inputs: [item, headers[item]]})
      })
    }
    if (templateParam && Object.entries(templateParam).length !== 0) {
      Object.keys(templateParam).forEach((item: string) => {
        if(['huawei', 'tencent'].includes(settingData?.supplierType)){
          paramsTmp.push({id:paramsTmp.length, inputs: [templateParam[item]]})
        }else{
          paramsTmp.push({id:paramsTmp.length, inputs: [item, templateParam[item]]})
        }
      })
    }
    setLinesUrlParams(urlParamsTmp)
    setLinesHeaders(headerTmp)
    setLinesParams(paramsTmp)
    setSmsChannel(settingData?.supplierType)
    if (tabKey==="alarmConfig") {
      setCurBusinessType(form.getFieldValue('businessType'))
    }
  }, [form, settingData, loginSetting])

  useEffect(() => {
    setFormVal()
  }, [settingData, setFormVal, form, loginSetting, ])

  useEffect(()=>{
    checkAllInputFilled()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[linesParams, linesHeaders, linesUrlParams])
  
  useEffect(() => {
    form.resetFields()
    if (tabKey === "alarmConfig") {
      setEditing(true);
      getBusinessTypeOptions();
      // runGetParam(curBusinessType);
    } else {
      setEditing(false);
    }
    fetchData();
    runGetLoginSetting();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  useEffect(() => {
    if (tabKey === "alarmConfig") {
      runGetParam(curBusinessType);
    } 
  }, [curBusinessType, tabKey])

  const fetchData = async () => {
    if (!!state?.record) {  // 告警配置
      setSettingData(state?.record)
      setActionType(state?.actionType)
    } else if (['commandReview', 'twoFactorAuth'].includes(tabKey)){
      // 获取配置数据
      getSmsRun().then ((res: any) => {
        let businessType = ''
        if(tabKey === 'commandReview') {  // 命令复核
          businessType = 'DOUBLE_CHECK'
        } else if (tabKey === 'twoFactorAuth') {  // 双因素认证
          businessType = 'SMS'
        }
        let resItem: any = {}
        !!res && res.map((item: any, index: number) => {
          if (item?.businessType && businessType === item?.businessType) {
            resItem = item
          }
        })
        if (resItem?.businessType !== businessType) {
          setSettingData({})
        } else {
          setSettingData(resItem)
        }
      })
    }
  };

  // 检查input的key字段是否 已填写
  const checkInputFilled = (theLines: IJsonParams[]) => {
    return theLines.every(
      (line) => line.inputs?.every(val=>val?.trim() !== '')
    );
  }

  const checkAllInputFilled = () => {
    let hasEmpty = false
    if(!checkInputFilled(linesParams)){
      hasEmpty = true
      setErrorParams('存在为空的变量值');
    }else{
      setErrorParams('')
    }
    if(!checkInputFilled(linesHeaders)){
      hasEmpty = true
      setErrorHeaders('存在为空的变量值');
    }else{
      setErrorHeaders('')
    }
    if(!checkInputFilled(linesUrlParams)){
      hasEmpty = true
      setErrorUrlParams('存在为空的变量值');
    }else{
      setErrorUrlParams('')
    }
    if(hasEmpty){
      return
    }
    setShowValidate(false)
  }

  const handleReset = () => {
    const fieldsToPreserve = ['smsName', 'businessType']; // 指定要保留的字段名称
    const formValues = form.getFieldsValue();
    const updatedFormValues: any = {};
    for (const fieldName in formValues) {
      if (fieldsToPreserve.includes(fieldName)) {
        updatedFormValues[fieldName] = formValues[fieldName]; // 保留指定字段的值
      } else {
        updatedFormValues[fieldName] = undefined; // 清空非指定字段的值
      }
    }
    // 使用更新后的字段值重新设置表单的值
    form.setFieldsValue(updatedFormValues);
  }

  const handleSmsChannelChange = (value: string) => {
    resetState()
    setSmsChannel(value)
    if (tabKey === 'alarmConfig') {
      handleReset()
    } else {
      form.resetFields()
    }
    form.setFieldsValue({supplierType: value,loginSetting: 'false'})
  }
  
  const handleMehtodChange = (e: any) => {
    setMethod(e.target.value)
  }

  const onClickEdit = () => { 
    setActionType("edit");
    if(linesUrlParams.length === 0 && ['CUSTOM', 'mengwangyun'].includes(smsChannel) ) {
      linesUrlParams.push({ id: 1, inputs: ['', ''] })
      setLinesUrlParams(linesUrlParams)
    }
    setEditing(true)
  }

  const resetState =() => {
    setLinesParams([])
    setLinesHeaders([])
    setLinesUrlParams([])
    setErrorParams('')
    setErrorHeaders('')
    setErrorUrlParams('')
    // form.resetFields()
    setValueOfSuccessfulTest({})
  }

  const handleCancle = () => {
    resetState()
    form.resetFields()
    setEditing(false)
    setFormVal()
  }

  // 除 测试电话号码外, 表单的字段
  const getFormParam = () => {
    let param: string[] = ["supplierType"]
    if (tabKey === "alarmConfig") {
      param.push("smsName", "businessType")
    }
    if(method==="post") {
      param.push("body")
    } 
    if(smsChannel==="huawei"){
      param.push("channelId")
    }
    if(smsChannel==="tencent"){
      param.push("smsAppId")
    }
    if(['alibaba', 'huawei', 'tencent'].includes(smsChannel)){
      param.push("accessKeyId", "accessKeySecret", "templateCode", "signName")
    }
    if(['CUSTOM'].includes(smsChannel)){  
      param.push("baseUrl", "method", "urlParams", "headers")
    }
    if(smsChannel==='monyun'){
      param.push("accessKeySecret", "content")
    }
    return param
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hanldleRequest = (operateType: string) => {
    let param: string[] = getFormParam();
    const parmasHeaders = linesHeaders.map((line) => line.inputs)
    const parmasUrlParams = linesUrlParams.map((line) => line.inputs)
    const parmasParams = linesParams.map((line) => line.inputs)
    const paramsMap = parmasParams?.flat(1).reduce((acc: any, curr, index) => {
      acc[index] = curr;
      return acc;
    }, {});
    let paraments: any = {
      urlParams: convertMapToStringMap(twoArrToMap(parmasUrlParams)),
      headers: convertMapToStringMap(twoArrToMap(parmasHeaders)),
      templateParam: ['huawei', 'tencent'].includes(smsChannel) ? paramsMap : convertMapToStringMap(twoArrToMap(parmasParams)),
    }
    if(errorHeaders||errorUrlParams||errorParams){
      setShowValidate(true)
      return
    }
    // 发送测试短信、保存
    switch (operateType) {
      case 'test':
        testForm(param, paraments)
        break
      case 'save':
        setValueOfSuccessfulTest((val: any) => {
          saveForm(param, paraments, val);
          return val;
        })
        break
    }
  }

  // 测试网关
  const testForm = (param: string[], paraments: any) => {
    form
      .validateFields([
        'phone',
        ...param,
      ])
      .then((vals: any) => {
        let params: any = {
          ...vals,
          ...paraments,
        }
        if (tabKey === 'commandReview') {
          params.businessType = 'DOUBLE_CHECK'
        } else if (tabKey === 'twoFactorAuth') {
          params.businessType = 'SMS'
        }
        if (actionType === 'edit') {
          params.id = settingData?.id
        }
        setOptionLoading(true)
        smsTestPhone(params).then(res=>{
          if(res) {
            const cloneParams = _.cloneDeep(params)
            delete cloneParams?.phone;
          setValueOfSuccessfulTest(cloneParams)
            message.success("验证成功")
          } else {
            message.error("验证失败")
          }
        }).catch(err=>{
          console.error("验证失败", err)
        }).finally(()=>{
          setOptionLoading(false)
        })
      })
  }

// 保存
  const saveForm = (param: string[], paraments: any, valueOfSuccessfulTestTmp: any) => {
    form
      .validateFields([
        ...param,
        'phone'
      ])
      .then(async(vals: any) => {
        let params: any = {
          ...vals,
          ...paraments,
        }
        if (tabKey === 'commandReview') {
          params.businessType = 'DOUBLE_CHECK'
          params.id = settingData?.id
        } else if (tabKey === 'twoFactorAuth') {
          params.businessType = 'SMS'
          params.id = settingData?.id
        } else if (actionType === 'edit') {
          params.id = settingData?.id
        }

        let cloneFormFieldValues = _.cloneDeep(params);
        delete cloneFormFieldValues.phone;

        if (_.isEmpty(valueOfSuccessfulTestTmp)) {
          return message.warning('请确保短信通道手机验证成功后再保存')
        }
     
        // 比较保存和测试数据是否一致 除手机号外
        if (!_.isEqual(cloneFormFieldValues,valueOfSuccessfulTestTmp)) {
          return message.warning('短信通道配置发生变更，请再次发送测试短信验证')
        }
        
        setOptionLoading(true)
        await putUserLoginSetting(form.getFieldValue('loginSetting'))
        
        updateSms(params).then((val) => {
          message.success('保存成功')
          fetchData()
          runGetLoginSetting();
          handleCancle()
          if (tabKey === "alarmConfig") {
            setCurrent(2)
          }
        })
        .catch((err: any) => {
          console.error('保存失败', err)
        })
        .finally(() => {
          setOptionLoading(false)
        })
      }).catch((err: any)=>{
        console.error('form error:', err)
      })
  }

  const onSelectLoginSetting = (value: string) => {
   if (value === 'true') {
    Modal.confirm({
      width: 500,
      centered: true,
      icon: <ExclamationCircleFilled style={{color: '#3262FF'}}/>,
      title: <h3>确认设置所有用户登录方式为短信认证登录吗？</h3>,
      content: (
        <div>
          <Text style={{fontSize: 12, color: '#5e6c84'}}>设置前，请确保系统中已有用户都进行了手机绑定，设置后，平台中所有用户的登录设置将变为“短信认证”</Text>
        </div>
      ),
      okText: '是',
      cancelText: '否',
      onOk() {
        form.setFieldsValue({loginSetting: 'true'})
      },
      onCancel() {
        form.setFieldsValue({loginSetting: 'false'})
      }
    });
   }
  }

  // 告警配置-短信通道-下一步
  const handleNextStep = () => {
    let param: string[] = getFormParam();
    form.validateFields(param).then(values => {
      setCurrent(1)
    }).catch(errors => {
      console.log('校验失败：', errors);
    });
  }

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={()=>hanldleRequest('save')}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => handleCancle()}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => onClickEdit()}>编辑</LinkButton>
      )}
    </div>
  )

  const renderBtn = useMemo(() => {
    if(current === 0) {
      return (
        <>
          <Button onClick={() => {form.resetFields()}}>重置</Button>
          <Button type='primary' onClick={() => {handleNextStep()}} style={{marginLeft: 9}}>下一步</Button>
        </>
      )
    } else if (current === 1) {
      return (
        <>
          <Button onClick={() => {setCurrent(0)}}>上一步</Button>
          <Button type='primary' onClick={() => {hanldleRequest('save')}} style={{marginLeft: 9}}>提交</Button>
        </>
      )
    }
    return <></>
  }, [current, setCurrent])

  const renderCard = useMemo(() => {
    const handleAddLine = (type: string) => {
      let arr: any[] = [], errorFn, successFn
      switch(type){
        case 'urlParams':
          arr = linesUrlParams;
          errorFn = setErrorUrlParams;
          successFn = setLinesUrlParams;
          break;
        case 'headers':
          arr = linesHeaders;
          errorFn = setErrorHeaders;
          successFn = setLinesHeaders;
          break;
        case 'param':
          arr = linesParams;
          errorFn = setErrorParams;
          successFn = setLinesParams;
          break;
      }
      if (!checkInputFilled(arr)) {
        errorFn && errorFn('存在为空的变量值');
        setShowValidate(true)
        return;
      }
      let newLineId = arr.length ? arr[arr.length-1]?.id + 1 : 0
      let newLine = {}
      if(type==='param' && ['huawei', 'tencent'].includes(smsChannel)) {
        newLine = { id: newLineId, inputs: [''] }
      }else{
        newLine = { id: newLineId, inputs: ['', ''] }
      }
      successFn && successFn([...arr, newLine]);
    };
  
    const handleDeleteLine = (lineId: number, fieldType: string) => {
      if (fieldType === "urlParams") {
        const updatedLines = linesUrlParams.filter((line) => line.id !== lineId);
        setLinesUrlParams(updatedLines);
        // API参数已改为非必填,无需强制至少一对键值
        // if(linesUrlParams.length===1) {setLinesUrlParams([{ id: 1, inputs: ['', ''] }])}
      } else if (fieldType === "headers") {
        const updatedLines = linesHeaders.filter((line) => line.id !== lineId);
        setLinesHeaders(updatedLines);
      }else if (fieldType === "params") {
        const updatedLines = linesParams.filter((line) => line.id !== lineId);
        setLinesParams(updatedLines);
      }
    };
  
    const handleInputChange = (value: any, lineId: number, inputIndex: number, type:string) => {
      let arr: any[] = [], updateCall = (p: any) => {}

      switch(type){
        case 'urlParams':
          arr = linesUrlParams;
          updateCall = setLinesUrlParams;
          break;
        case 'headers':
          arr = linesHeaders;
          updateCall = setLinesHeaders;
          break;
        case 'params':
          arr = linesParams;
          updateCall = setLinesParams;
          break;
      }
      const result: any = arr.map((line: any) => {
        if (line.id === lineId) {
          const newInputs = [...line.inputs];
          newInputs[inputIndex] = value;
          return { ...line, inputs: newInputs };
        }
        return line;
      });
      updateCall(result);
    };
  
    // 展示加密的数据
    const renderStarTags = (txt: string) => {
      let star: string[] = []
      for(let i=0; i<txt?.length; i++){
        star.push('*')
      }
      return star.join('')
    }

    const businessTypeHandleOnChange = (e: any) => {
      setCurBusinessType(e)
    }

    return (
      <Spin spinning={optionLoading}>
        <div
          style={{
            position: 'absolute',
            top: '-50px',
            right: '0px',
          }}
        >
          { tabKey !== "alarmConfig" && extraOperations}
        </div>
        <Form component={false} form={form} {...FormLayout}>
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          {
            tabKey !== "alarmConfig" || (tabKey === "alarmConfig" && current === 0) ? 
              <>
                {
                  tabKey === "alarmConfig" && current === 0?
                  <Form.Item
                    label="通道名称"
                    name='smsName'
                    rules={[{ required: true, message: '通道名称不可为空' }]}
                  >
                    {
                      editing ? 
                      <Input style={{ width: '100%' }} placeholder='请填写通道名称'/>
                      : <></>
                      // <Text>{settingData?.channelName}</Text>
                    }
                  </Form.Item>
                  : <></>
                }
                {
                  tabKey === "alarmConfig" && current === 0?
                  <Form.Item
                    label="生效告警类型"
                    name='businessType'
                    rules={[{ required: true, message: '生效告警类型不可为空' }]}
                  >
                    {
                      editing ? 
                      <Select 
                        placeholder="请选择生效告警类型" 
                        options={businessTypeOptions} 
                        onChange={(e) => businessTypeHandleOnChange(e)}
                      />
                      : <></>
                    }
                  </Form.Item>
                  : <></>
                }
                
                <Form.Item label="短信通道" name='supplierType'>
                  {
                    editing ? 
                    <Select 
                      placeholder="请选择短信通道" 
                      options={SMSChannelEnum} 
                      onChange={handleSmsChannelChange}
                    />
                    : SMSChannelEnum?.filter((i: any)=>i?.value===settingData?.supplierType)?.[0]?.label
                  }
                </Form.Item>
                {/* 类型一: 阿里云\华为云\腾讯云 */}
                {
                  ['alibaba', 'huawei', 'tencent'].includes(smsChannel) && 
                  <>
                    <Form.Item 
                      label={smsChannel=== 'tencent' ? "SecretID" : "AccessKeyID"}
                      name={"accessKeyId"}
                      rules={[{ required: true, message: 'AccessKeyID不可为空' }]}
                    >
                      {
                        editing 
                          ? <Input.Password style={{ width: '100%' }} />
                          : <Text>{renderStarTags(settingData?.accessKeyId)}</Text>
                      }
                    </Form.Item>
                    <Form.Item 
                      label={smsChannel=== 'tencent' ? "SecretKey" : "AccessSecret"}
                      name={"accessKeySecret"}
                      rules={[{ required: true, message: 'AccessSecret不可为空' }]}
                    >
                      {
                        editing 
                          ? <Input.Password style={{ width: '100%' }} />
                          : <Text>{renderStarTags(settingData?.accessKeySecret)}</Text>
                      }
                    </Form.Item>
                    <Form.Item 
                      label="短信签名名称"
                      name={'signName'}
                      rules={[{ required: true, message: '短信签名名称不可为空' }]}
                    >
                      {
                        editing 
                          ? <Input style={{ width: '100%' }} />
                          : <Text>{settingData?.signName}</Text>
                      }
                    </Form.Item>
                    <Form.Item 
                      label="短信模板ID"
                      name={'templateCode'}
                      rules={[{ required: true, message: '短信模板ID不可为空' }]}
                    >
                      {
                        editing 
                          ? <Input style={{ width: '100%' }} />
                          : <Text>{settingData?.templateCode}</Text>
                      }
                    </Form.Item>
                    {/* 腾讯云 */}
                    {
                      smsChannel === 'tencent' &&
                      <Form.Item 
                        label="AppId"
                        name={'smsAppId'}
                        rules={[{ required: true, message: 'AppId不可为空' }]}
                      >
                        {
                          editing 
                            ? <Input style={{ width: '100%' }} />
                            : <Text>{settingData?.smsAppId}</Text>
                        }
                      </Form.Item>
                    }
                    {/* 华为云 */}
                    {
                      smsChannel === 'huawei' && 
                      <Form.Item 
                        label="签名通道号"
                        name={'channelId'}
                        rules={[{ required: true, message: '签名通道号不可为空' }]}
                      >
                        {
                          editing 
                            ? <Input style={{ width: '100%' }} />
                            : <Text>{settingData?.channelId}</Text>
                        }
                      </Form.Item>
                    }

                    <Form.Item 
                      label="参数"
                      name={'param'}
                    >
                      <div>
                        {editing && <Button onClick={()=>handleAddLine('param')} type='primary'>添加</Button>}
                        <div>
                          {
                            ['huawei', 'tencent'].includes(smsChannel)
                            ? <Row>
                                <Col span={10} push={10}>
                                  { editing && <span>操作</span>}
                                </Col>
                              </Row>
                            : <Row>
                                <Col span={8}>
                                  <span>变量</span>
                                </Col>
                                <Col span={8} push={1}>
                                  <span>值</span>
                                </Col>
                                <Col span={2} push={2}>
                                  { editing && <span>操作</span>}
                                </Col>
                              </Row>
                          }
                        </div>
                        {(linesParams).map((line) => (
                          <div key={line.id} style={{ marginBottom: '10px' }}>
                            <Row>
                              {line.inputs.map((input: any, index: any) => (
                                <Col span={8} push={index===1? 1 : 0} key={"col"+index}>
                                  {
                                    editing
                                    ? <Tooltip title={input}>
                                      {tabKey === "alarmConfig" ?
                                        <AutoComplete
                                          key={index}
                                          value={input}
                                          options={paramParams}
                                          onSelect={(e) => handleInputChange(e, line.id, index, 'params')}
                                          dropdownStyle={{ minWidth: 500 }}
                                        >
                                          <Input
                                            key={index}
                                            allowClear
                                            placeholder={`请输入内容${index + 1}`}
                                            value={input}
                                            onChange={(e) => handleInputChange(e?.target?.value, line.id, index, 'params')}
                                          />
                                        </AutoComplete>
                                      : <Input
                                          key={index}
                                          allowClear
                                          placeholder={`请输入内容${index + 1}`}
                                          value={input}
                                          onChange={(e) => handleInputChange(e?.target?.value, line.id, index, 'params')}
                                        />
                                      }
                                        {/* <Input
                                          key={index}
                                          allowClear
                                          placeholder={`请输入内容${index + 1}`}
                                          value={input}
                                          onChange={(e) => handleInputChange(e, line.id, index, 'params')}
                                        /> */}
                                      </Tooltip>
                                    : <span className={styles.headersValue}>{input}</span>
                                  }
                                </Col>
                              ))}
                              <Col span={2} push={2} key="delButton">
                                {editing && <DeleteOutlined onClick={() => handleDeleteLine(line.id, "params")} style={{ color: "red" }}/>}
                              </Col>
                            </Row>
                          </div>
                        ))}
                        {editing && showValidate && <p style={{ color: 'red' }}>{errorParams}</p>}
                      </div>
                    </Form.Item>
                  </>
                }
                {/* 类型二: 梦网云 */}
                {
                  smsChannel === 'monyun' && 
                  <>
                    <Form.Item 
                      label="AccessSecret"
                      name={"accessKeySecret"}
                      rules={[{ required: true, message: 'AccessSecret不可为空' }]}
                    >
                      {
                        editing 
                          ? <Input.Password style={{ width: '100%' }} />
                          : <Text>{renderStarTags(settingData?.accessKeySecret)}</Text>
                      }
                    </Form.Item>
                    <Form.Item 
                      label="消息内容"
                      name={'content'}
                      rules={[{ required: true, message: '消息内容不可为空' }]}
                      extra={
                        editing && tabKey === "alarmConfig" && paramParams && <div>当前告警类型支持的参数
                        <div className={styles.contentExtra}>
                        {
                          paramParams.map((item: any) => {
                            return <div className={styles.contentLabel}>{item?.label}</div>
                          })
                        }
                        </div>
                      </div>
                      }
                    >
                      {
                        editing 
                          ? <TextArea rows={3} style={{ width: '100%' }} />
                          : <Text>{settingData?.content}</Text>
                      }
                    </Form.Item>
                  </>
                }
                {/* 类型三: 自定义 */}
                {
                  smsChannel === 'CUSTOM' && 
                  <>
                    <Form.Item 
                      label="请求地址（url）"
                      name={'baseUrl'}
                      rules={[{ required: true, message: '请求地址不可为空' }]}
                    >
                      {
                        editing 
                          ? <Input style={{ width: '100%' }} />
                          : <Text>{settingData?.baseUrl}</Text>
                      }
                    </Form.Item>
                    <Form.Item 
                      label="请求类型（method）"
                      name="method"
                      rules={[{ required: true, message: '请选择' }]}
                    >
                      {
                        editing
                          ? <Radio.Group onChange={handleMehtodChange}>
                              <Radio value={'post'}>
                                POST
                              </Radio>
                              <Radio value={'get'}>GET</Radio>
                            </Radio.Group>
                          : <Text>{settingData?.method}</Text>
                      }
                    </Form.Item>
                    <Form.Item 
                      label="API参数"
                      name="urlParams"
                      className={styles.urlParamsForm}
                    >
                      <div>
                        {editing && <Button onClick={()=>{handleAddLine('urlParams')}} type='primary'>添加</Button>}
                        <div>
                          <Row>
                            <Col span={8}>
                              <span>变量</span>
                            </Col>
                            <Col span={8} push={1}>
                              <span>值</span>
                            </Col>
                            <Col span={2} push={2}>
                              { editing && <span>操作</span>}
                            </Col>
                          </Row>
                        </div>
                        {(linesUrlParams).map((line) => (
                          <div key={line.id} style={{ marginBottom: '10px' }}>
                            <Row>
                              {line.inputs.map((input: any, index: any) => (
                                <Col span={8} push={index===1? 1 : 0} key={"col"+index}>
                                  {
                                    editing
                                    ? <Tooltip title={input}>
                                        <Input
                                          key={index}
                                          allowClear
                                          placeholder={`请输入内容${index + 1}`}
                                          value={input}
                                          onChange={(e) => handleInputChange(e?.target?.value, line.id, index, "urlParams")}
                                        />
                                      </Tooltip>
                                    : <span className={styles.urlParamsValue}>{input}</span>
                                  }
                                </Col>
                              ))}
                              <Col span={2} push={2} key="delButton">
                                {editing && <DeleteOutlined onClick={() => handleDeleteLine(line.id, "urlParams")} style={{ color: "red" }}/>}
                              </Col>
                            </Row>
                          </div>
                        ))}
                        {editing && showValidate && <p style={{ color: 'red' }}>{errorUrlParams}</p>}
                      </div>
                    </Form.Item>
                    {
                      method==="post" && 
                      <Form.Item label="body参数">
                        {editing?
                          <Form.Item
                            name="body"
                            rules={[
                              {
                                validator(_rule, value, callback) { validateJson(_rule, value, callback, false) }
                              }
                            ]}
                          >
                            <Input
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        : <Text>{settingData && settingData?.body}</Text>}
                      </Form.Item>
                    }
                    <Form.Item label="HTTP头部">
                      <Form.Item
                        name="headers"
                        className={styles.headersForm}
                      >
                        <div>
                          {editing && <Button onClick={()=>handleAddLine('headers')} type='primary'>添加</Button>}
                          <div>
                            <Row>
                              <Col span={8}>
                                <span>变量</span>
                              </Col>
                              <Col span={8} push={1}>
                                <span>值</span>
                              </Col>
                              <Col span={2} push={2}>
                                { editing && <span>操作</span>}
                              </Col>
                            </Row>
                          </div>
                            {(linesHeaders).map((line) => (
                              <div key={line.id} style={{ marginBottom: '10px' }}>
                                <Row>
                                  {line.inputs.map((input: any, index: any) => (
                                    <Col span={8} push={index===1? 1 : 0} key={"col"+index}>
                                      {
                                        editing
                                        ? <Tooltip title={input}>
                                            <Input
                                              key={index}
                                              allowClear
                                              placeholder={`请输入内容${index + 1}`}
                                              value={input}
                                              onChange={(e) => handleInputChange(e?.target?.value, line.id, index, "headers")}
                                            />
                                          </Tooltip>
                                        : <span className={styles.headersValue}>{input}</span>
                                      }
                                    </Col>
                                  ))}
                                  <Col span={2} push={2} key="delButton">
                                    {editing && <DeleteOutlined onClick={() => handleDeleteLine(line.id, "headers")} style={{ color: "red" }}/>}
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            {editing && showValidate && <p style={{ color: 'red' }}>{errorHeaders}</p>}
                        </div>
                      </Form.Item>
                    </Form.Item>
                  </>
                }
              </>
            : <></>
          }
          {
            (tabKey !== "alarmConfig" || (tabKey === "alarmConfig" && current === 1)) && editing &&
            <>
              <Form.Item
                name="phone"
                required
                label="接收人手机号码"
                rules={[
                  { required:true, message: '请输入手机号码！' },
                  { validator: validatePhone }
                ]}
                extra={
                  <Button type="primary" onClick={()=>hanldleRequest("test")} className={styles.testSmsButton}>
                    发送测试短信
                  </Button>
                }
              >
                <Input style={{ width: '60%', marginRight: 10 }} />
              </Form.Item>
            </>
          }
          { tabKey === "twoFactorAuth" && 
            <>
              {/* <Divider style={{margin: '40px 0 40px'}} /> */}
                <Form.Item
                  name="loginSetting"
                  label="系统用户登录设置"
                >
                  {
                    editing ? 
                      <Select onChange={onSelectLoginSetting}>
                        <Select.Option value="false">用户自定义</Select.Option>
                        <Select.Option value="true">强制短信认证登录</Select.Option>
                      </Select>
                    : <Text>{loginSetting ? '强制短信认证登录': '用户自定义'}</Text>
                  }
                
                </Form.Item>
            </>
          }
        </Card>
        {
          tabKey === "alarmConfig" && 
          <div className={styles.footer}>
            <div className={styles.footerBtn}>{renderBtn}</div>
          </div>
        }
        </Form>
      </Spin>
    )
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showValidate,
    loginSetting,
    loading,
    editing,
    errorHeaders,
    errorParams,
    errorUrlParams, 
    linesHeaders,
    linesParams,
    linesUrlParams, 
    method, 
    settingData, 
    smsChannel, 
    current, 
    tabKey,
    paramParams,
  ])

  return (
    <>
    {renderCard}
    </>
  )
}
