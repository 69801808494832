/*
 * @Author: fuzhenghao
 * @Date: 2023-01-06 11:23:59
 * @LastEditTime: 2023-01-06 11:27:40
 * @LastEditors: fuzhenghao
 * @Description:
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\FlowManage.tsx
 */
import React from 'react'
import { useParams } from 'react-router-dom'
import { Card } from 'antd'

import { FlowManagePage } from './FlowManagePage'
import styles from './flowPage.module.scss'

export const FlowManage = () => {
  return (
    <Card
      className={styles.contentCard}
      title="管理流程"
      bordered={false}
      bodyStyle={{ padding: 0, flex: 1, overflow: 'hidden' }}
    >
      <FlowManagePage />
    </Card>
  )
}
