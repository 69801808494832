import React, { useEffect, useState } from 'react';
import { history } from 'src/util'
import { checkIp } from 'src/api';
import { useDispatch } from './hook';
import { setIpAccess } from './appPages/login/loginSlice';
import { NoAccess } from 'src/appPages/403';

const ipCheck = (WrappedComponent: any) => {
  return (props: any) => {
    const dispatch = useDispatch();
    const [isAccess, setIsAccess] = useState(true);

    useEffect(() => {
      checkIp().then(res => {
        dispatch(setIpAccess(res));
        setIsAccess(res);
        if (!res) {
          history.push('/403');
        }
      })
    }, [])

    return <>{isAccess ? <WrappedComponent {...props} /> : <NoAccess />}</>;
    // return <><WrappedComponent {...props} /></>;
  };
};

export default ipCheck;