import React, { useRef, useState } from 'react'
import { Button } from 'antd'
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import type { DataSourceType } from 'src/api'
import { isSupport, getEnumOptions } from 'src/util'

import { GridConfigBase } from '../../../resultTabs/resultContentGrid'

interface ITabColumnPane {
  connectionId: string | number | undefined
  connectionType: DataSourceType
  theme: string
  onPreviewSQL: (params: any) => void
}

export const TabColumnPane = ({
  connectionType,
  connectionId,
  theme,
  onPreviewSQL,
}: ITabColumnPane) => {
  const mockData: object[] = []
  const defaultColDef: ColDef = { editable: true }

  const gridColumnApiRef = useRef<ColumnApi>()
  const [rowData, setRowData] = useState<any[]>([])
  const gridApiRef = useRef<GridApi>()

  const cellBooleanEditor: ColDef | ColGroupDef = {
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: { values: ['true', 'false'] },
  }

  const getColumnTypeCellEditor = (connectionType: DataSourceType) => {
    switch (connectionType) {
      default:
        return 'agSelectCellEditor'
    }
  }

  const getColumnDefs = (
    connectionType: DataSourceType,
  ): (ColDef | ColGroupDef)[] => {
    const baseCol = [
      {
        field: 'columnName',
        headerName: '字段名',
      },
      {
        field: 'dataType',
        headerName: '类型',
        cellEditor: getColumnTypeCellEditor(connectionType),
        cellEditorParams: {
          values: getEnumOptions('dataTypeEnum'),
        },
      },
      {
        field: 'length',
        headerName: '长度',
        cellEditor: 'numericEditor',
        cellEditorParams: {
          min: 0,
          maxLength: 10,
        },
      },
      {
        field: 'scale',
        headerName: '小数点',
        cellEditor: 'numericEditor',
        cellEditorParams: {
          min: 0,
          maxLength: 10,
        },
      },
      {
        field: 'defaultValue',
        headerName: '默认值',
        cellRenderer: 'simpleTextRenderer',
      },
      {
        field: 'comment',
        headerName: '注释',
        cellEditor: 'agLargeTextCellEditor',
      },
      {
        field: 'virtual',
        headerName: '虚拟',
        hide: !isSupport('supportedColumnProperty.virtualColumn'),
        ...cellBooleanEditor,
      },
      { field: 'nullable', headerName: '允许空', ...cellBooleanEditor },
      {
        field: 'autoIncrease',
        headerName: '可自增',
        hide: !isSupport('supportedColumnProperty.autoIncrease'),
        ...cellBooleanEditor,
      },
      { field: 'isPrimary', headerName: '主键', ...cellBooleanEditor },
    ]

    return baseCol;
  }

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
    gridColumnApiRef.current = params.columnApi
    setRowData(mockData)
    gridApiRef.current.sizeColumnsToFit()
  }

  const deleteColumn = () => {
    const selectedRows = gridApiRef.current?.getSelectedRows()
    gridApiRef.current?.applyTransactionAsync(
      { remove: selectedRows },
      () => {},
    )
  }

  const addColumn = () => {
    gridApiRef.current?.applyTransactionAsync(
      { add: [{ }] },
      (res) => {
        gridApiRef.current?.startEditingCell({
          rowIndex: Number(res.add[0]?.rowIndex),
          colKey: 'columnName',
        })
      },
    )
  }

  return (
    <>
      <div style={{ margin: '0 0 16px 0' }}>
        <Button size="small" className="mr8" onClick={addColumn}>
          添加列
        </Button>
        <Button size="small" className="mr16" onClick={deleteColumn}>
          删除列
        </Button>
        <Button
          type="primary"
          size="small"
          className="mr16"
          onClick={() => onPreviewSQL(gridApiRef.current)}
        >
          预览
        </Button>
      </div>
      <div
        id="myGrid"
        style={{
          height: '60vh',
          width: '100%',
        }}
        // todo: 更好地统一处理主题样式
        className={
          theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
        }
      >
        <AgGridReact
          {...GridConfigBase}
          modules={[ClientSideRowModelModule]}
          columnDefs={getColumnDefs(connectionType)}
          defaultColDef={defaultColDef}
          rowDragManaged={true}
          animateRows={true}
          onGridReady={onGridReady}
          rowData={rowData}
          rowSelection="single"
          stopEditingWhenGridLosesFocus
        />
      </div>
    </>
  )
}
