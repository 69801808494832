import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, message, Form, Typography, Tooltip } from 'antd'
import { useRequest } from 'src/hook'
import { FormInstance } from 'antd/lib/form'
import { CustomInputNumber } from 'src/components'
import { SettingFormContext } from '../SettingFormContext'
import {
  getImportNumber,
  getExportNumber,
  postImportNumber,
  postExportNumber,
  getUserLoginIpList,
  postUserLoginIpList,
  getUserSysFile,
  postUserSysFile,
  getUserSysLogin,
  postUserSysLogin
} from 'src/api'
import styles from './index.module.scss'
import { FormOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import classnames from 'classnames'

const { Text } = Typography

export const OtherCard = () => {
  const [editing, setEditing] = useState('')
  const form = useContext(SettingFormContext) as FormInstance

  const {
    data: settingData,
    loading,
    run,
  } = useRequest(() =>
    Promise.all([
      getImportNumber(),
      getExportNumber(),
      getUserLoginIpList(),
      getUserSysFile(),
      getUserSysLogin(),
    ]),
  )

  const setFormVal = useCallback(() => {
    if (settingData) {
      form.setFields([
        {
          name: 'importNumber',
          value: settingData[0],
        },
        {
          name: 'exportNumber',
          value: settingData[1],
        },
        {
          name: 'loginIpImpose',
          value: settingData[2]
        },
        {
          name: 'readSize',
          value: settingData[3]?.readSize
        },
        {
          name: 'uploadSize',
          value: settingData[3]?.uploadSize
        },
        {
          name: 'accountLockPeriod',
          value: settingData[4]? settingData[4] : 0
        }
      ])
    }
  }, [form, settingData])

  useEffect(() => {
    setFormVal()
  }, [settingData, setFormVal, form])


  const { run: postImportNumberRun } = useRequest(postImportNumber, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditing('')
      run()
    },
    onError(e, params) {
      setEditing('')
    },
  })

  const { run: postExportNumberRun } = useRequest(postExportNumber, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditing('')
      run()
    },
    onError(e, params) {
      setEditing('')
    },
  })

  const { run: postUserLoginIpListRun } = useRequest(postUserLoginIpList, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditing('')
      run()
    },
    onError(e, params) {
      setEditing('')
    },
  })
  const { run: postUserSysFileRun } = useRequest(postUserSysFile, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditing('')
      run()
    },
    onError(e, params) {
      setEditing('')
    },
  })
  const { run: postUserSysLoginRun } = useRequest(postUserSysLogin, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditing('')
      run()
    },
    onError(e, params) {
      setEditing('')
    },
  })
  const setSave = (val: any, stringName: string) => {
    if (stringName === 'importNumber') {
      form.validateFields().then(({ importNumber }) => {
        postImportNumberRun(val)
      })
    } else if (stringName === 'exportNumber') {
      form.validateFields().then(({ exportNumber }) => {
        postExportNumberRun(val)
      })
    } else if (stringName === 'readSize') {
      form.validateFields().then(({ readSize }) => {
        postUserSysFileRun({
          readSize: Number(val),
          uploadSize: form.getFieldValue('uploadSize')
        })
      })
    } else if (stringName === 'uploadSize') {
      form.validateFields().then(({ readSize }) => {
        postUserSysFileRun({
          readSize: form.getFieldValue('readSize') ? form.getFieldValue('readSize') : 0,
          uploadSize: Number(val)
        })
      })
    } else if (stringName === 'accountLockPeriod') {
      form.validateFields().then(({ accountLockPeriod }) => {
        postUserSysLoginRun(Number(val))
      })
    } else if (stringName === 'loginIpImpose') {
      let str = val.split(',')
      let newArr = []
      newArr = str.filter((item: any) => item.trim())
      if (newArr?.length < 10) {
        postUserLoginIpListRun(newArr)
      } else {
        message.error('最多只能输入10个用户ip')
      }
    }
    // run()
  }

  return (
    <section className="cq-new-card flow-card" id="OtherCard" >
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">基础设置</h3>
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          {/* 导入任务数 */}
          <Form.Item label="导入任务数">
            <Form.Item
              name="importNumber"
              hidden={!(editing === 'importNumber')}
              noStyle
              rules={[
                {
                  min: 1,
                  message: '请输入大于0的数值',
                  type: 'number',
                  transform: (v) => Number(v),
                },
              ]}
            >
              <CustomInputNumber
                autoFocus
                placeholder="请输入数值"
                onBlur={(e) => {
                  setSave(e.target.value, 'importNumber')
                }} />
            </Form.Item>
            {editing !== 'importNumber' && (
              <div style={{ display: 'flex' }}> <Text className={classnames(styles.w300,styles.disInB)}>{settingData && settingData[0]}</Text> <FormOutlined onClick={() => {
                setEditing('importNumber')
              }} /></div>
            )}
          </Form.Item>
          {/* 导出任务数 */}
          <Form.Item label="导出任务数">
            <Form.Item
              name="exportNumber"
              noStyle
              hidden={!(editing === 'exportNumber')}
              rules={[
                {
                  min: 1,
                  message: '请输入大于0的数值',
                  type: 'number',
                  transform: (v) => Number(v),
                },
              ]}
            >
              <CustomInputNumber
                autoFocus
                placeholder="请输入数值"
                onBlur={(e) => {
                  setSave(e.target.value, 'exportNumber')
                }} />
            </Form.Item>
            {editing !== 'exportNumber' && (
              <div style={{ display: 'flex' }}> <Text className={classnames(styles.w300,styles.disInB)}>{settingData && settingData[1]}</Text> <FormOutlined onClick={() => {
                setEditing('exportNumber')
              }} /></div>
            )}
          </Form.Item>
          <Form.Item label="文件读取限制（MB）">
            <Form.Item
              name="readSize"
              noStyle
              hidden={!(editing === 'readSize')}
              rules={[
                {
                  min: 1,
                  max: 100,
                  message: '请输入1-100区间的数值',
                  type: 'number',
                  transform: (v) => Number(v),
                },
              ]}
            >
              <CustomInputNumber
                autoFocus
                placeholder="请输入数值"
                onBlur={(e) => {
                  setSave(e.target.value, 'readSize')
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
            {editing !== 'readSize' && (
              <div style={{ display: 'flex' }}> <Text className={classnames(styles.w300,styles.disInB)}>{settingData && settingData[3]?.readSize}</Text> <FormOutlined onClick={() => {
                setEditing('readSize')
              }} /></div>
            )}
          </Form.Item>
         
          <Form.Item label="文件上传限制（MB）">
          {
            (editing === 'uploadSize') && 
            <Form.Item name="uploadSize"
            noStyle
            rules={[
              {
                min: 1,
                max: 100,
                message: '请输入1-100区间的数值',
                type: 'number',
                transform: (v) => Number(v),
              },
            ]}
          >
            <CustomInputNumber
              autoFocus
              placeholder="请输入数值"
              onBlur={(e) => {
                setSave(e.target.value, 'uploadSize')
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>
          }
            {editing !== 'uploadSize' && (
              <div style={{ display: 'flex' }}> <Text className={classnames(styles.w300,styles.disInB)}>{settingData && settingData[3]?.uploadSize}</Text> <FormOutlined onClick={() => {
                setEditing('uploadSize')
              }} /></div>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                账号锁定期限（天）
                <Tooltip title="在设定的期限内未登录的账号将被锁定">
                  <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            }
          >
          {
            (editing === 'accountLockPeriod') && 
            <Form.Item name="accountLockPeriod"
            noStyle
            rules={[
              {
                min: 1,
                message: '请输入大于0的数值',
                type: 'number',
                transform: (v) => Number(v),
              },
            ]}
          >
            <CustomInputNumber
              autoFocus
              placeholder="请输入数值"
              onBlur={(e) => {
                setSave(e.target.value, 'accountLockPeriod')
              }}
              style={{ width: '100%' }}
              type={'int'}
            />
          </Form.Item>
          }
            {editing !== 'accountLockPeriod' && (
              <div style={{ display: 'flex' }}> 
              <Text className={classnames(styles.w300,styles.disInB)}>{settingData && settingData[4]? settingData[4] : 0}</Text> 
              <FormOutlined onClick={() => {
                setEditing('accountLockPeriod')
              }} />
              </div>
            )}
          </Form.Item>
          {/* 登录限制改为访问设置，这里不做展示 */}
          {/* <Form.Item label={
            <>
              用户登录限制
              <Tooltip title="最多支持填写10个用户IP，以英文 ” , “ 形式分割">
                <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
              </Tooltip>
            </>
          }>
            <Form.Item name="loginIpImpose" noStyle hidden={!(editing === 'loginIpImpose')}>
              <TextArea autoFocus placeholder="请输入数值" onBlur={(e) => {
                setSave(e.target.value, 'loginIpImpose')
              }} />
            </Form.Item>
            {editing !== 'loginIpImpose' && (
              <div style={{ display: 'flex' }}> <Text className={classnames(styles.w300,styles.disInB)}>{settingData && settingData[2]}</Text> <FormOutlined onClick={() => {
                setEditing('loginIpImpose')
              }} /></div>
            )}
          </Form.Item> */}
        </Card>
      </section>
    </section>
  )
}
