import React, { useState } from 'react'
import { Steps, Upload, message, Button } from 'antd'
import {
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons'

import { UIModal } from 'src/components'
import { useModal } from 'src/hook'

import styles from './index.module.scss'

const { Step } = Steps


export const UploadDesenSettingModal = ({
  onUploadField,
}: {
  onUploadField: (file: any) => void
}) => {
  const { visible, closeModal } = useModal('UploadDesensSettingModal')

  /* step 0 */
  const [uploadFileList, setUploadFileList] = useState([])
  const saveFile = (_: any, fileList: any) => {
    setUploadFileList(() => fileList)
    return false
  }
  const downloadTemplateFile = () => {
    window.location.href='/dms/connection/downloadMaskTemplate'
  }
  const uploadContent = (
    <div>
      <h2>上传</h2>
      <div>
        <div style={{ marginTop: '44px' }}>
          <h3>模板文件下载</h3>
          <span>下载模板文件，并按照 excel 内格式填写需要生成连接。</span>
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            size="small"
            onClick={downloadTemplateFile}
          >
            模板下载
          </Button>
        </div>
        <div style={{ marginTop: '44px' }}>
          <h3>文件上传</h3>
          <span>请将填写好的文件上传至此处，并且点击上传进行生成。</span>
          <Upload
            beforeUpload={saveFile}
            multiple={false}
            fileList={uploadFileList}
            accept=".csv"
            onRemove  ={()=>{setUploadFileList([])}}
          >
            <Button
              icon={<UploadOutlined />}
              size="small"
              type="primary"
              shape="round"
            >
              选择文件
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  )

  const uploadFile = async () => {
    if (!uploadFileList || !uploadFileList[0]) {
      message.error('请上传文件')
      return
    }
    try {
      await onUploadField({ file: uploadFileList[0] })
    } catch (error) {
      console.error('上传文件失败')
    }
  }

  const stepRenderList = [
    {
      title: '文件上传',
      content: uploadContent,
      okText: '上传',
      onOk: uploadFile,
    },
    {
      title: '',
      content: [],
      okText: '',
      okButtonProps: { disabled: true },
    },
    {
      title: '',
      content: [],
      footer: [],
    },
  ]

  const { content, title, ...rest } = stepRenderList[0]
  return (
    <UIModal
      title="脱敏配置导入"
      visible={visible}
      onCancel={closeModal}
      // afterClose={[]}
      {...rest}
    >
      <div style={{ display: 'flex', height: '300px' }}>
        <div>
          <Steps
            direction="vertical"
            current={0}
            size="small"
            style={{ height: '300px' }}
          >
            {stepRenderList.map(({ title }) => (
              <Step key={title} />
            ))}
          </Steps>
        </div>
        <div className={styles.contentwrap}>{content}</div>
      </div>
    </UIModal>
  )
}
