import React, { useState } from 'react'
import { Button, message, Progress, Steps, Upload } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'

import { batchImportUserModal, getBatchImportUserResult } from 'src/api'
import { UIModal } from 'src/components'
import { useModal, useRequest } from 'src/hook'

import styles from './BatchImportUserModal.module.scss'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'

const { Step } = Steps



export const BatchImportUserModal = ({
  parentDeptId,
}: {
  parentDeptId: string
}) => {
  const { visible, closeModal } = useModal('BatchImportUserModal')
  const { handleExternalInfo } = useHandleExternalInfo()
  const [importState, setImportState] = useState('');
  /* step 0 */
  const [uploadFileList, setUploadFileList] = useState([])
  const saveFile = (_: any, fileList: any) => {
    setUploadFileList(() => fileList)
    return false
  }
  const doCloseModal = ()=>{
    closeModal()
    setStep(0)
    setUploadFileList([])
  }
  const downloadTemplateFile = () => {
    window.location.href='/user/users/export/consumertemplate'
    // window.open('/consumer-template.xlsx')
  }
  const uploadContent = (
    <div style={{marginLeft: '20%', marginTop: '5%'}}>
      {/* <h2>上传</h2> */}
      <div>
        <div style={{ marginTop: '24px' }}>
          <h3>模板文件下载</h3>
          <span>下载模板文件，并按照 excel 内格式填写信息。</span>
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            size="small"
            onClick={downloadTemplateFile}
          >
            模板下载
          </Button>
        </div>
        <div style={{ marginTop: '24px' }}>
          <h3>文件上传</h3>
          <span>请将填写好的文件上传至此处，并且点击上传进行生成。</span>
          <Upload
            beforeUpload={saveFile}
            multiple={false}
            accept=".xlsx, .xls"
            fileList={uploadFileList}
            onRemove={() => {
              setUploadFileList([])
            }}
          >
            <Button
              icon={<UploadOutlined />}
              size="small"
              type="primary"
              shape="round"
            >
              选择文件
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  )

  const { fetchTaskResult } = useHandleExternalInfo(getBatchImportUserResult, (data: any) => {
    handleExternalInfo({ type: 'BATCH_CREATE_USER', msg: data.messageData })
  })

  const uploadFile = async () => {
    if (!uploadFileList || !uploadFileList[0]) {
      message.error('请上传批量生成连接文件')
      return
    }
    try {
      setImportState('EXECUTE')
      setStep(1)
      batchImportUserModal(parentDeptId, { file: uploadFileList[0] })
        .then((value)=>{
        setImportState(value?.name)
        setLogContent(value?.content)
      }).finally(()=>{
        setStep(2)
        setImportState('SUCCESS')
      })
    } catch (error) {
      console.error('上传文件失败')
    }
  }

  /* step 1 */
  const processContent = (
    <div style={{marginTop: '5%'}}>
      {/* <h2>批量创建中</h2> */}
      <OnProcessCircle showInfo={importState}></OnProcessCircle>
    </div>
  )

  /* step 2 */
  // const [doneStatus, setDoneStatus] = useState<'SUCCESS' | 'FAILED'>('SUCCESS')
  const [logContent, setLogContent] = useState<string>("")
  const resultContent = (
    <div className={styles.donewrap} style={{marginTop: '16%'}}>
      {/* <h2>创建结果</h2> */}
      <div className={styles.iconwrap}>
        {/* <DoneIcon status={doneStatus}></DoneIcon> */}
        <DoneContentRender content={logContent}></DoneContentRender>
      </div>
    </div>
  )

  const [step, setStep] = useState(0)
  const stepRenderList = [
    {
      title: '文件上传',
      content: uploadContent,
      okText: '上传',
      onOk: uploadFile,
    },
    {
      title: '上传文件中',
      content: processContent,
      okText: '上传文件中',
      okButtonProps: { disabled: true },
    },
    {
      title: '上传结束',
      content: resultContent,
      footer: [<Button type='primary' onClick={doCloseModal}>完成</Button>],
      // okText: '完成',
      // onOk: closeModal,
    },
  ]


  const { content, title, ...rest } = stepRenderList[step]
  return (
    <UIModal
      title="批量导入用户"
      visible={visible}
      onCancel={doCloseModal}
      {...rest}
    >
      <div style={{ display: 'flex', height: '300px', flexDirection: 'column' }}>
        <div>
          <Steps
            // direction="vertical"
            current={step}
            // size="small"
            // style={{ height: '300px' }}
          >
            {stepRenderList.map(({ title },index) => (
              <Step key={index} title={title}/>
            ))}
          </Steps>
        </div>
        <div className={styles.contentwrap}>{content}</div>
      </div>
    </UIModal>
  )
}

const OnProcessCircle = (props: { showInfo?: string }) => {
  const { showInfo = 'VERIFY' } = props
  const showMap = {
    VERIFY: '校验中',
    EXECUTE: '执行中',
    SUCCESS: '成功',
    FAILED: '失败',
  } as any
  const show = showMap[showInfo] ? showMap[showInfo] : showMap['VERIFY']
  return (
    <div className={styles.processWrap}>
      <Progress
        className={styles.process}
        type="circle"
        format={() => ''}
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
        percent={100}
        width={208}
      />
      <span className={styles.processtext}>{show}</span>
    </div>
  )
}

// const DoneIcon = (props: { status: 'SUCCESS' | 'FAILED' }) => {
//   const { status } = props
//   if (status === 'SUCCESS') {
//     return <SmileOutlined style={{ color: '#4384ec' }} />
//   }
//   return <CloseCircleOutlined style={{ color: 'red' }} />
// }

const getFailResAry = (data?: string) => {
  if (!data) return []
  const failResReg = /失败连接详情:(.*)$/g
  const resStringAry = failResReg.exec(data)
  if (!resStringAry || !resStringAry[1]) return []
  const resAry = JSON.parse(resStringAry[1])
  if (!resAry || !resAry[0]) return []
  return resAry.map((res: string) => res.split(','))
}

const getResString = (data?: string) => {
  if (!data) return ''
  const failResReg = /,失败连接详情(.*)$/g
  return data.replace(failResReg, '')
}
const DoneContentRender = ({ content }: { content?: string }) => {
  const resString = getResString(content)
  const failArray = getFailResAry(content)
  return (
    <div className={styles.textwrap}>
      <div className={styles.text}>{resString}</div>
      <div className={styles.failRes}>
        {failArray?.map((failRes: any[], i: any) => (
          <div>
            <span className={styles.failtname}>
              [{i + 1}]&nbsp;{failRes?.[0]}
            </span>
            <span className={styles.failtreason}>{failRes?.[1]}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
