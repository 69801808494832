import { DataSourceType } from 'src/api';
import type { SdtNodeType } from 'src/types';

//设计表结构
const NORMAL_TABLE_STRUCTURE_TABS = [
  'columns',
  'indexes',
  'constraints',
  'fkConstraints',
  'triggers',
  'partition',
  'option'
];

const STARROCKS_TABS = ['columns', 'indexes', 'partition', 'temporaryPartition'];
const Vertica_TABS = ['columns','constraints','fkConstraints','option'];

//一些数据源特殊处理
export const getPointDataSourceTabs = (
  connectionType: DataSourceType,
  nodeType: SdtNodeType | undefined
) => {
  if (!connectionType) return [];
  switch (connectionType) {
    case 'StarRocks':
      if (nodeType === 'foreignTable') {
        return ['columns'];
      }
      return STARROCKS_TABS;
    case 'Vertica':
      return Vertica_TABS;
    case 'GBase':
      return NORMAL_TABLE_STRUCTURE_TABS.slice(0,NORMAL_TABLE_STRUCTURE_TABS.length - 2)
    default: 
      return NORMAL_TABLE_STRUCTURE_TABS
  }
};
