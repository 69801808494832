import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DatabasePermissionState {
  status: boolean

}

const initialState: DatabasePermissionState = {
  status: false
}
export const sdtPermissionSlice = createSlice({
  name: 'sdtPermission',
  initialState,
  reducers: {
    setSdtPermissionStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload
    },
  },
})

export const sdtPermissionReducer = sdtPermissionSlice.reducer

export const { setSdtPermissionStatus, } = sdtPermissionSlice.actions
