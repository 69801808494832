import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { Form, Input, Spin, Typography, Switch, Descriptions, Tooltip } from 'antd'
import { GridApi, ColDef } from '@ag-grid-community/core'
import useRequest from '@ahooksjs/use-request'
import { ModalProps } from 'antd/lib/modal'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { UIModal } from 'src/components'
import {
  CellRenderType,
  PlainRowData,
  CellRenderResultData,
  RowData,
} from 'src/api'
import styles from './cellViewer.module.scss'
import {getImageSrcFromHex } from 'src/util'

const { Text } = Typography

const ReadOnlyTypes: CellRenderType[] = [
  'base64-image',
  'base64-jpg',
  'base64-png',
  'binary',
  'binary-image',
  'binary-jpg',
  'binary-png',
]

export const isCellTag = (value: string) => {
  if (value.length > 50) return false
  if (!~value.indexOf('<')) return false
  const CellTagRegex = /([0-9] bytes|\.[0-9]{2} (KB|MB|GB)|too large)>$/
  return CellTagRegex.test(value)
}

interface CellViewerModalProps {
  gridApi: GridApi | null
  rowData?: Record<string, unknown>
  editable: boolean;
  resultData: RowData[]
  visible: boolean
  setVisible: (visible: boolean) => void
  updateFocusedCell: (newData: PlainRowData, oldData: PlainRowData) => void
  downloadFocusedCell: (newData: PlainRowData, oldData: PlainRowData) => void
  fetchFocusedCell: (
    newData: PlainRowData,
    oldData: PlainRowData,
  ) => Promise<CellRenderResultData[]>
}

export const RowViewerModal: React.FC<CellViewerModalProps> = (props) => {
  const {
    gridApi,
    rowData,
    visible,
    resultData,
    setVisible,
    updateFocusedCell,
    fetchFocusedCell,
    editable
  } = props

  const {
    data: completeRow,
    run: tryGetCompleteCell,
    loading: loadingGetCompleteCell,
  } = useRequest(
    () => {
      const oldRowData = getCurrentRow()
      const fields = Object.keys(oldRowData)
      if (!fields || !fields.length) return
      const detailRowData = Promise.all(
        fields.map((field) => fetchCellDetail(field, oldRowData)),
      )
      return detailRowData
    },
    { manual: true },
  )
 
  useEffect(() => {
    if (!visible) return
    tryGetCompleteCell()
  }, [tryGetCompleteCell, visible])

  //查询resultData信息
  const getResultDetailCell = (field: string) => {

    const focusedCell:  any = gridApi?.getFocusedCell();
    const { rowIndex } = focusedCell;
    //结果集resultData cell renderType
    const cellContentDetail = resultData?.[rowIndex]?.[field];
    return cellContentDetail?.renderType || ''
  }
  
  const fetchCellDetail = async (field: string, oldRowData: any) => {
    const oldValue = oldRowData[field]
    if (isCellTag(String(oldValue))) {
      return fetchFocusedCell({ [field]: 'DoesNotMatter' }, oldRowData).then(
        (res) => {    
          if (!res[0]) return { key: field, value: '', brief: oldValue }
          const { resultData } = res[0]
          return { key: field, ...resultData[0]?.[field], brief: oldValue }
        },
      )
    } else {
      const renderType = getResultDetailCell(field);
      return new Promise((resolve) =>
        resolve({ key: field, value: oldValue, brief: oldValue, renderType: renderType }),
      ) as Promise<any>
    }
  }

  const getCurrentRowInfo = useCallback(() => {
    if (!gridApi) return
    const columnDefs: ColDef[] = gridApi
      .getColumnDefs()
      .filter((el) => !el.hasOwnProperty('headerName'))
    if (columnDefs.length < 1) return

    const columnsInfo = columnDefs.map((col) => {
      const data = Object.assign(
        {},
        { dataType: col.cellEditorParams.cellEditorType },
        {
          comment: col.headerComponentParams.comment || '',
        },
      )
      return Object.assign({}, { colId: col.colId }, { data: data })
    })

    const currentRowColumnData = rowData || {}
    const keyArr = Object.keys(currentRowColumnData)

    const rowInfoData = keyArr.map((key) => {
      const index = columnsInfo.findIndex(({ colId }) => colId === key)
      return { [key]: currentRowColumnData[key], ...columnsInfo[index]?.data }
    })

    return rowInfoData
  }, [gridApi, rowData])

  const getCurrentRow = () => {
    return rowData || {}
  }

  const handleSaveCell = async () => {
    const values = await rowEditorForm.getFieldsValue(
      true,
      ({ touched }) => touched,
    )
    if (!isEmpty(values)) {
      const oldRowData = getCurrentRow()
      updateFocusedCell(values, oldRowData)
    }
    setVisible(false)
  }

  const [isEditing, setIsEditing] = useState(false)

  const ButtonProps: Partial<ModalProps> = {
    okText: '确定',
    okButtonProps: { onClick: handleSaveCell },
    cancelText: '取消',
    cancelButtonProps: { onClick: () => setVisible(false) },
  }

  const [rowEditorForm] = Form.useForm()

  const modalTitle = (
    <span>
      <span>行详情</span>
      <Switch
        size="small"
        checkedChildren="编辑"
        unCheckedChildren="查看"
        checked={isEditing}
        disabled={!editable}
        onChange={(v) => setIsEditing(v)}
        style={{ marginLeft: '4px' }}
      ></Switch>
    </span>
  )

  //特殊处理CQ-1330
  const formatValue = (formatValue: any) => {

    if (formatValue === null) {
      return `<null>`
    }else if (typeof formatValue === 'object' && formatValue !== null) {
      return `<${JSON.stringify(formatValue)}>`
    }else if (typeof formatValue === 'boolean') {
      return formatValue?.toString()
    }else {
      return formatValue ?? ''
    } 
  }

  const getRenderedContentInfo = useCallback(() => {
    const rowValues = getCurrentRowInfo() as any
    if (!rowValues) return

    let rowRender = []
    for (let i = 0; i < rowValues.length; i++) {
      const rowRenderItem = (
        <Descriptions key={i} size='small' className={styles.dataOperaRowFieldDetail}>
          {Object.keys(rowValues[i]).map((field: string) => {
            let defaultContent = <Text>{formatValue(rowValues[i][field]) }</Text>;
            if (['binary-image','binary-jpg','binary-png', 'binary-gif'].includes(getResultDetailCell(field))) {
              const src = getImageSrcFromHex(rowValues[i][field]);
              defaultContent =   <img
              src={src}
              alt=""
              style={{ width: '100%' }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            ></img>
            }
            return (
              <Descriptions.Item 
                key={field} 
                label={ <Tooltip title={field }>{ field?.length > 8 ? field?.replace(/^(.{8}).+$/, (match, p1) => p1 + '...') : field }</Tooltip> } 
              >
                <Tooltip placement="topLeft" title={defaultContent} overlayClassName={styles.overlayValue}>
                  { defaultContent }
                </Tooltip>  
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      )

      rowRender.push(rowRenderItem)
    }

    return rowRender
  }, [getCurrentRowInfo])

  const renderContentInfo = useMemo(() => {
    return getRenderedContentInfo()
  }, [getRenderedContentInfo])

  const checkCellEditable = (cellCompleteData: any) => {
    const isReadOnly = ReadOnlyTypes.includes(
      cellCompleteData?.renderType || null,
    )
    return cellCompleteData?.editable || !isReadOnly
  }

  const formItemRender = (cellCompleteData: any) => {
    const { key, value } = cellCompleteData
    const valueShow = value?.length > 100 ? value.slice(0, 100) : value
    const cellEditable = checkCellEditable(cellCompleteData)
    return (
      <Form.Item className={styles.cellLabel} name={key} initialValue={valueShow} label={<Tooltip title={key}>{key}</Tooltip>} key={key}>
        <Input style={{ width: '100%' }} disabled={!cellEditable}></Input>
      </Form.Item>
    )
  }

  return (
    <UIModal
      title={modalTitle}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={800}
      afterClose={() => {
        rowEditorForm.resetFields()
        setIsEditing(false)
      }}
      {...ButtonProps}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          wordBreak: 'break-all',
          overflow: 'hidden',
        }}
      >
        <Spin
          wrapperClassName={classNames(styles.fullWidth)}
          spinning={loadingGetCompleteCell}
        >
          {!isEditing && renderContentInfo}
          {isEditing && (
            <Form
              form={rowEditorForm}
              style={{ width: '100%' }}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
            >
              {completeRow?.map((cellCompleteData) =>
                formItemRender(cellCompleteData),
              )}
            </Form>
          )}
        </Spin>
      </div>
    </UIModal>
  )
}
