import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Form, Input, message } from 'antd'
import classnames from 'classnames'
import { useForm } from 'antd/lib/form/Form'
import { useRequest } from 'src/hook'
import { approveApplication } from 'src/api'
import { useHistory } from 'react-router-dom'
import styles from '../index.module.scss'

const FormLayout = {
  labelCol: { span: 0 },
  wrapperCol: { offset: 0, span: 8 },
}

interface ListProps {
  approvedComment?: any
  applyId: string,
  flowTaskId: string
}

export const AuditInfoPage: FC<ListProps> = (props) => {
  const { approvedComment, flowTaskId, applyId } = props
  const [form] = useForm()
  const history = useHistory()
  const [approveType, setApproveType] = useState<
    'rejected' | 'fullilled' | 'pending'
  >('pending')

  useEffect(() => {
    form.setFields([
      { name: "approvedComment", value: approvedComment }
    ])
  }, [approvedComment])

  // 同意审批
  const { run: runApprove, fetches: fetchesApprove } = useRequest(
    approveApplication,
    {
      manual: true,
      // fetchKey: (id) => id,
      onSuccess: () => {
        if (approveType === 'fullilled') {
          message.success('通过成功')
        } else {
          message.success('拒绝成功')
        }
        history.push('/mine_approve')
      },
    },
  )

  // 同意
  const agreeBtn = () => {
    setApproveType('fullilled')
    form.validateFields().then((res) => {
      let approveParams = {
        flowId: applyId,
        taskId: flowTaskId,
        approvedFlag: true,
        approvedComment: res?.approvedComment,
        approvedTime: '',
      }
      runApprove(approveParams).finally(() => {
        setApproveType('pending')
      })
    })


    // setApproveType('fullilled')
    //@ts-ignore
  }

  const isEmpty = () => {
    form.validateFields().then((res) => {
      let approveParams = {
        flowId: applyId,
        taskId: flowTaskId || '',
        approvedFlag: false,
        approvedComment: res?.approvedComment,
        approvedTime: '',
      }
      setApproveType('rejected')
      //@ts-ignore
      runApprove(approveParams).finally(() => {
        setApproveType('pending')
      })
    })
  }
  return (
    <Card
      title="审核信息"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27, styles.detailCard)}
      actions={[
        <div>
          <Button type='primary' onClick={() => agreeBtn()}>审批通过</Button>
          <Button onClick={() => isEmpty()}>审批拒绝</Button>
        </div>
      ]}
    >
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="备注"
          name="approvedComment"
          // rules={[{ required: true }, {min: 1, max: 100}]}
        >
          <Input.TextArea allowClear maxLength={100}/>
        </Form.Item>
      </Form>
    </Card>
  )
}
