import React, { useContext, useEffect, useRef } from 'react'
import { Input, message, Table, Tag } from 'antd'
import { EditorContext, LinkButton, UIModal } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { deleteFavorite, searchFavorites, updateFavoriteStatus } from 'src/api'
import { ColumnsType } from 'antd/lib/table'
import { Range } from 'monaco-editor'
import debounce from 'lodash/debounce'
import styles from './index.module.scss'

const columns: ColumnsType<any> = [
  { dataIndex: 'statement', title: '语句', ellipsis: true },
  {
    dataIndex: 'statementName',
    title: '别名',
    render: (statementName) =>
      statementName && (
        <Tag className={styles.favoritesTableTag}>{statementName}</Tag>
      ),
    ellipsis: true,
    width: '112px',
  },
  {
    dataIndex: 'updateTime',
    title: '更新时间',
    render: (updateTime) => (updateTime || '').slice(5),
    width: '136px',
  },
  { dataIndex: 'selectedNum', title: '使用次数', width: '96px' },
]

export const FavoriteManagementModal = () => {
  const dispatch = useDispatch()
  const { ModalFavoriteManagement } = useSelector((state) => state.modal)

  const {
    run: tryGetFavorites,
    data: favorites = [],
    refresh: reGetFavorites,
    loading: loadingGetFavorites,
    mutate: mutateGetFavorites,
  } = useRequest(searchFavorites, { manual: true, debounceInterval: 350 })
  useEffect(() => {
    if (!ModalFavoriteManagement) return
    mutateGetFavorites([])
    tryGetFavorites()
    focusRef.current?.focus()
  }, [ModalFavoriteManagement, tryGetFavorites, mutateGetFavorites])

  const { run: tryDeleteFavorite } = useRequest(deleteFavorite, {
    manual: true,
    onSuccess: () => {
      message.success('收藏删除成功')
      reGetFavorites()
    },
  })

  const { run: silentlyUpdateFavoriteStatus } = useRequest(
    updateFavoriteStatus,
    { manual: true },
  )

  const { editorInstance } = useContext(EditorContext)
  const columnsWithAction: ColumnsType<any> = [
    ...columns,
    {
      dataIndex: '操作',
      render: (_, record) => {
        const { statement, id } = record
        return (
          <>
            <LinkButton
              onClick={() => {
                silentlyUpdateFavoriteStatus(id)
                dispatch(hideModal('ModalFavoriteManagement'))
                // todo: 提出公共方法，获取 editor content
                // todo: 提取公共方法，处理「使用收藏」
                var position = editorInstance?.getPosition()
                if (!position) return
                // 插入到光标下一行，聚焦编辑器，移动光标到插入内容前
                const { lineNumber } = position
                editorInstance?.executeEdits('', [
                  {
                    range: new Range(lineNumber + 1, 1, lineNumber + 1, 1),
                    text: statement + '\n',
                    forceMoveMarkers: true,
                  },
                ])
                editorInstance?.focus()
                editorInstance?.setPosition({
                  lineNumber: lineNumber + 1,
                  column: 0,
                })
              }}
            >
              使用
            </LinkButton>
            <LinkButton
              danger
              onClick={debounce(() => tryDeleteFavorite(id), 500)}
            >
              删除
            </LinkButton>
          </>
        )
      },
      width: '160px',
    },
  ]

  const focusRef = useRef<Input>(null)

  return (
    <UIModal
      title="收藏管理"
      visible={ModalFavoriteManagement}
      onCancel={() => dispatch(hideModal('ModalFavoriteManagement'))}
      width={'80vw'}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText="返回"
      bodyStyle={{ height: 576, padding: '0 16px' }}
    >
      <Input.Search
        className={styles.searchInput}
        placeholder="搜索语句或别名"
        size="small"
        onSearch={(keyword) => tryGetFavorites({ keyword })}
        onChange={(e) => {
          const keyword = e.target.value || ''
          tryGetFavorites({ keyword })
        }}
        ref={focusRef}
        loading={loadingGetFavorites}
      ></Input.Search>
      <Table
        dataSource={favorites}
        columns={columnsWithAction}
        size="small"
        loading={loadingGetFavorites}
      ></Table>
    </UIModal>
  )
}
