import { useState, useEffect, useCallback, RefCallback } from 'react'
import { editor } from 'monaco-editor'
import { hackAddCommand } from './useLSP'
import './initMonaco'

type MonacoInitHook = (
  options?: editor.IStandaloneEditorConstructionOptions,
  override?: editor.IEditorOverrideServices,
) => [editor.IStandaloneCodeEditor | null, RefCallback<HTMLDivElement>]

/**
 * @description 根据传入的参数初始化一个 monaco editor instance
 * @param options
 * @param override
 * @return [编辑器实例 ref, container callback ref]
 */
export const useMonaco: MonacoInitHook = (options?, override?) => {
  const [
    editorInstance,
    setEditorInstance,
  ] = useState<editor.IStandaloneCodeEditor | null>(null)

  const containerRef: React.RefCallback<HTMLElement> = useCallback(
    (node) => {
      if (node === null) return
      const instance = editor.create(node, options, override)
      hackAddCommand(instance as any)
      setEditorInstance(instance)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    return () => {
      editorInstance?.dispose()
    }
  }, [editorInstance])

  return [editorInstance, containerRef]
}
