import { CellRenderResultData } from 'src/api';
import { CELL_TYPE } from 'src/pageTabs/queryPage/resultTabs/resultContentGrid/constants';


//16进制字符串转化为二进制
export const transferHexStringToOtherTypes = (hexString: string, type:CELL_TYPE ) => {
  if (hexString.startsWith('0x')) {
    hexString = hexString.match(/^0x(\S*)/)?.[1] || '';
  }
  //目前字符串默认添加
  hexString = hexString.replace(/[^A-Fa-f0-9]/g, '')
 
  if (hexString.length % 2) {
    // error: cleaned hex string length is odd
    return
  }

  const binary = []
  for (let i = 0; i < hexString.length / 2; i++) {
    const h = hexString.substr(i * 2, 2)
    binary[i] = parseInt(h, 16)
  }
  //解析为二进制
  const byteArray = new Uint8Array(binary);

  let str: any = hexString;

  switch(type) {
    case 'GBK':
    default:
      const decoder = new TextDecoder(type);
      // 将 Uint8Array 转换为 UTF-8 字符串
      str = decoder.decode(byteArray);
      break;
  }
  return str;
}
//方法一
export const getImageSrcFromHex = (hexString: string) => {
  if (hexString.startsWith('0x')) {
    hexString = hexString.match(/^0x(\S*)/)?.[1] || '';
  }
  //目前字符串默认添加
  hexString = hexString.replace(/[^A-Fa-f0-9]/g, '')
 
  if (hexString.length % 2) {
    // error: cleaned hex string length is odd
    return
  }

  const binary = []
  for (let i = 0; i < hexString.length / 2; i++) {
    const h = hexString.substr(i * 2, 2)
    binary[i] = parseInt(h, 16)
  }
  //解析为二进制
  const byteArray = new Uint8Array(binary)

  return window.URL.createObjectURL(
    new Blob([byteArray], { type: 'application/octet-stream' }),
  )
}
//方法二
export const HexToImage = (hexString: string) => {
  if (hexString.length % 2) {
    return ''
  }
  // 单元格 16进制展示时字符串前默认添加了0x
  if (hexString.startsWith('0x')) {
    hexString = hexString.match(/^0x(\S*)/)?.[1] || '';
  }

  let bString = "";
  for( var i = 0; i < hexString?.length; i +=2) {
    //将16进制转化为10进制 并生成对应的字符串；
      bString += String.fromCharCode( parseInt( hexString.substr( i, 2), 16));
  }
    //btoa转化为base64
  return 'data:image/png;base64,' + btoa(bString);
}

/**
 * format execute reasult
 */
export const formatExecuteResult = (res: CellRenderResultData[]) =>
  res.map((queryResult) => {
    const { resultData } = queryResult
    const plainResultData = resultData.map((result) => {
      if (typeof result !== 'object') return result
      const plainResult: { [key: string]: any } = {}
      for (let key in result) {
        plainResult[key] = result[key].value
      }
      return plainResult
    })
    return {
      ...queryResult,
      resultData: plainResultData,
      detailedResultData: resultData,
    }
  })

export const getLimitedString = (
  data: Object | string | undefined,
  limitedLength: number = 50,
) => {
  if (!data) return ''
  const stringifyData = typeof data === 'string' ? data : JSON.stringify(data)
  if (!stringifyData) return ''
  const length = stringifyData.length
  return length > limitedLength
    ? `${stringifyData.slice(0, limitedLength)}...`
    : stringifyData
}

export const getColLimitLength = (colNum: number) => {
  const ROW_MAX_STRING_NUMBER = 1000
  const Col_MIN_STRING_NUMBER = 50
  return Col_MIN_STRING_NUMBER > ROW_MAX_STRING_NUMBER / colNum
    ? Col_MIN_STRING_NUMBER
    : Math.floor(ROW_MAX_STRING_NUMBER / colNum)
}

/** transform hex to string */
export const hexToString = (hex: string): string => {
  // 后端传过来的是不带空格的十六进制字符串
  if (!!hex) {
    let hexArray: string[] = []
    const length = hex.length
    for (let i = 0; i < length; i += 2) {
      hexArray.push(hex.slice(i, i + 2))
    }
    const hexArrayNumber = hexArray.map((item) => {
      return Number('0x' + item)
    })
    return String.fromCharCode(...hexArrayNumber)
  } else {
    return '??'
  }
}

// transfer string to hex
export const stringToHex = (str: string | number): string | number => {
  if(typeof(str) === 'number'){
    return str.toString(16)
  }
  if (str === '') return ''
  const hexCharCode = []
  // hexCharCode.push('0x')
  for (var i = 0; i < str.length; i++) {
    hexCharCode.push(str.charCodeAt(i).toString(16))
  }
  return hexCharCode.join('')
}
