import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataSourceType } from 'src/api'
import { AppThunk } from 'src/store'

interface CreateConnectionState {
  visibleCreateConnection: boolean
  dataSourceType: DataSourceType
  mode: 'add' | 'copy'
}

const initialState: CreateConnectionState = {
  visibleCreateConnection: false,
  dataSourceType: 'Other',
  mode: 'add',
}

export const createConnectionSlice = createSlice({
  name: 'createConnection',
  initialState,
  reducers: {
    setAddConnectionMode: (state, action: PayloadAction<'add' | 'copy'>) => {
      state.mode = action.payload
    },
    startCreateConnection: (state, action: PayloadAction<DataSourceType>) => {
      state.dataSourceType = action.payload
      state.visibleCreateConnection = true
    },
    finishCreateConnection: (state) => {
      state.visibleCreateConnection = false
    },
  },
})

export const createConnectionReducer = createConnectionSlice.reducer

export const {
  setAddConnectionMode,
  startCreateConnection,
  finishCreateConnection,
} = createConnectionSlice.actions

/** 添加连接，分 创建/复制 两种情况 */
export const addConnection = (
  type: DataSourceType,
  mode: 'add' | 'copy' = 'add',
): AppThunk => (dispatch) => {
  dispatch(setAddConnectionMode(mode))
  dispatch(startCreateConnection(type))
}
