/**
 * RSA 引入使用方法
 */
import {JSEncrypt} from 'jsencrypt'
/**
 * SM2 引入使用方法
 */
const sm2 = require('sm-crypto').sm2

export interface IEncryptKey {
    password: string
    algorithm: string
    publicKey: string
}

export const setEncrypt = (params: IEncryptKey) => {
    let passwordMsg: string | boolean
    if(params.algorithm === 'SM2') {
        const key = atob(params.publicKey)
        const encData = sm2.doEncrypt(params.password, key, 1 )
        passwordMsg = btoa(unescape(encodeURIComponent(encData)))
    } else { // RSA 加密
        const jsencrypt = new JSEncrypt()
        jsencrypt.setPublicKey(params.publicKey)
        let flag = jsencrypt.encrypt(params.password)
        if (flag) {
          passwordMsg = flag
        } else {
            passwordMsg = ''
        }
    }
    return passwordMsg
}