import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PersonalCenterState {
  emailBindFlag: boolean // 用户是否设置了邮箱
  loginType: string // 登录类型
  userEmail: string // 用户邮箱
  isBindEmail: boolean  // true:绑定 false: 解绑
  smsBindFlag: boolean  // 用户是否设置了短信验证
  isBindPhone: boolean  // 用户是否绑定了手机号
  userPhone: string // 用户手机号
  isBindOtp: boolean  // 用户是否绑定了otp
  isOpenOtp: boolean  // 用户是否启用了otp
  isOpenSms: boolean  // 用户是否启用了短信认证
}

const initialState: PersonalCenterState = {
  emailBindFlag: false,
  loginType: '',
  userEmail: '',
  isBindEmail: false,
  smsBindFlag: false,
  isBindPhone: false,
  userPhone: '',
  isBindOtp: false,
  isOpenOtp: false,
  isOpenSms: false,
}
export const PersonalCenterSlice = createSlice({
  name: 'PersonalCenterSlice',
  initialState,
  reducers: {
    setEmailBindFlag: (state, action: PayloadAction<boolean>) => {
      state.emailBindFlag = action.payload
    },
    setLoginType: (state, action: PayloadAction<string>) => {
      state.loginType = action.payload
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload
    },
    setIsBindEmail: (state, action: PayloadAction<boolean>) => {
      state.isBindEmail = action.payload
    },
    setSmsBindFlag: (state, action: PayloadAction<boolean>) => {
      state.smsBindFlag = action.payload
    },
    setIsBindPhone: (state, action: PayloadAction<boolean>) => {
      state.isBindPhone = action.payload
    },
    setIsBindOtp:(state, action: PayloadAction<boolean>) => {
      state.isBindOtp = action.payload
    },
    setIsOpenOtp:(state, action: PayloadAction<boolean>) => {
      state.isOpenOtp = action.payload
    },
    setIsOpenSms:(state, action: PayloadAction<boolean>) => {
      state.isOpenSms = action.payload
    },
    setUserPhone: (state, action: PayloadAction<string>) => {
      state.userPhone = action.payload
    },
  },
})

export const personalCenterReducer = PersonalCenterSlice.reducer

export const {
  setEmailBindFlag,
  setLoginType,
  setUserEmail,
  setIsBindEmail,
  setSmsBindFlag,
  setIsBindPhone,
  setUserPhone,
  setIsBindOtp,
  setIsOpenOtp,
  setIsOpenSms
} = PersonalCenterSlice.actions
