import React, { useMemo } from 'react'
import { useDispatch } from 'src/hook'
import { Menu, Dropdown, message } from 'antd'
import { Encoding, ENCODINGS } from 'src/constants'
import { updateMonacoTabEncoding } from '../queryTabs/queryTabsSlice'

export const EncodingDropdownMenu = React.memo(({ children }) => {
  const dispatch = useDispatch()
  const menu = useMemo(
    () => (
      <Menu
        onClick={(event) => {
          message.info({content: '切换编码格式后请重新打开文件'})
          const encoding = event.key as Encoding
          dispatch(updateMonacoTabEncoding({ encoding }))
        }}
      >
        {ENCODINGS.map((encoding) => (
          <Menu.Item key={encoding}>{encoding}</Menu.Item>
        ))}
      </Menu>
    ),
    [dispatch],
  )

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      {children}
    </Dropdown>
  )
})
