import { fetchPost, fetchGet, fetchDelete } from "../customFetch";

export interface IObjectPermissionParams {
  roleId: number;
  nodePath: string;
  dataSourceType: string;
}
export interface IObjectPermissionRes {
  canOperation: boolean;
  operationsVoList: any
}
export const getAutomaticObjectPermission= (params: IObjectPermissionParams): Promise<IObjectPermissionRes> => { 
	return fetchPost(`/user/permissionCollection/getPermissionsPanelUpObject`,params);
}