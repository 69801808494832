import React, { useEffect } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { Form, Select } from 'antd'
import { getFlowApproveUser } from 'src/api'
import { FormFieldElementEntity } from 'src/types'
import type { FormInstance } from 'rc-field-form'

export const ApproverSelector = ({
  connectionId,
  value,
  onChange,
  form,
}: {
  connectionId?: React.Key
  value?: React.Key
  onChange?: (value: React.Key) => void
  form: FormInstance
}) => {
  const { userId } = useSelector((state) => state.login.userInfo)
  const { data, loading, run, reset } = useRequest(getFlowApproveUser, {
    manual: true,
    formatResult: (data) => {
      const { connectUser } = data
      if (!connectUser) return
      return connectUser.map((item) => {
        return {
          label: `${item.userName}(${item.userId})`,
          value: item.userId,
        }
      })
    },
  })
  useEffect(() => {
    if (userId && connectionId) {
      run({ connectionId, userId })
      // return () => {
      //   reset()
      //   form.resetFields(['approveUserId'])
      // }
    }
  }, [connectionId, form, reset, run, userId])

  return (
    <Select
      loading={loading}
      options={data}
      value={value}
      onChange={onChange}
      placeholder="请选择审批人"
    />
  )
}

export const FormItemApprover = () => {
  return (
    <Form.Item
      shouldUpdate={(prev, next) => prev?.elements !== next?.elements}
      noStyle
    >
      {(form) => {
        // ! 人工推断的类型。一是因为这里没有 instance 上下文，二是 form.getFieldValue 没有实现类型推断
        const elements = form.getFieldValue('elements') as
          | FormFieldElementEntity[]
          | undefined

        const connectionId = elements?.[0]?.connectionId

        return (
          <Form.Item
            label="审批人"
            name="approveUserId"
            rules={[{ required: true, message: '请选择审核人' }]}
          >
            <ApproverSelector form={form} connectionId={connectionId} />
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}
