// 访问申请-我的申请-申请清单（申请资源：可编辑）
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Card, Dropdown, Menu, Table, message, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import {
  CloseCircleOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { columnsDetail, detailExpamdedRow } from '../../common/columns';
import { useDispatch, useRequest, useSelector } from 'src/hook';
import {
  getPermissionTemplateInfo,
  getUserRoleNocheck,
  saveDraftFat,
  getToolTemplateInfo
} from 'src/api';
import classnames from 'classnames';
import { setOnDeleteItem, setRoleChange } from '../../accessRequestSlice';
import styles from '../index.module.scss';
import { Iconfont } from 'src/components';
import PermissionLevelModal from '../../components/PermissionLevelModal'
import { resetPermissionModal } from 'src/pageTabs/authorizationManagePage/commonMethods'

const getRoleTypeValue = (data: any[], key: any) =>
  data?.filter((i: any) => i?.roleType == key)?.[0]?.roleName;

const getPermissionValue = (data: any[], key: any) =>
  data?.filter((i: any) => i?.id == key)?.[0]?.name;

interface ListProps {
  delScFn: (val: any) => void;
  refreshAppResource: () => void;
}

export const ResourceBidPage: FC<ListProps> = (props) => {
  const { delScFn, refreshAppResource } = props;
  const { requestListData } = useSelector((state) => state.accessRequest);

  const scrollX = columnsDetail?.length * 100;
  const newColumns = useMemo(() => {
    const newColumns = columnsDetail.map((item: any) => {
      if (item?.dataIndex === 'connectionName') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return (
              <span>
                <Iconfont
                  type={`icon-connection-${record?.connection?.connectionType}`}
                  style={{ marginRight: 4 }}
                />
                {record?.connection?.connectionName}
              </span>
            );
          },
        };
      }
      return item;
    });

    //样式
    return newColumns;
  }, []);
  return (
    <Card
      title="申请资源"
      className={styles.borderShow}
      extra={
        <Button type="primary">
          <Link to="/mine_apply/search">
            <PlusOutlined />
            &nbsp;&nbsp;添加资源
          </Link>
        </Button>
      }
    >
      <Table
        rowKey="id"
        className={styles.tablePage}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <ExpandedRowContent
                record={record}
                data={record?.businessDataListNoChild}
                delScFn={delScFn}
                refreshAppResource={refreshAppResource}
              />
            );
          },
        }}
        columns={newColumns}
        dataSource={requestListData}
        size="small"
        scroll={{ x: scrollX, y: 490 }}
      />
    </Card>
  );
};

export const ExpandedRowContent = ({
  record,
  data,
  delScFn,
  refreshAppResource,
}: {
  record: any;
  data: any;
  delScFn: (val: any) => void;
  refreshAppResource: () => void;
}) => {
  const {dataSourceType, nodeType: selectNodeType} = data?.[0]
  const dispatch = useDispatch();
  const { requestListData } = useSelector((state) => state.accessRequest);

  const [toolsModalVisible, setToolsModalVisible] = useState(false)
  const [editToolItem, setEditToolItem] = useState<any>({})
  const [templateOperationsSet, setTemplateOperationsSet] = useState<any[]>([])
  const [templateEnum, setTemplateEnum] = useState<any[]>([])

	// 查询工具权限模板
	useEffect(()=>{
		if(dataSourceType && selectNodeType){
			queryToolsAuthTemplate(dataSourceType, selectNodeType)
		}
	},[dataSourceType, selectNodeType])
	const queryToolsAuthTemplate = (dataSourceType: string, nodeType: string) =>{
		getToolTemplateInfo(dataSourceType, nodeType).then((res: any)=>{
			setTemplateEnum(res)
		}).catch((err: any)=>{
			console.error('工具权限模板查询失败', err)
		})
	}

  const { data: userRoleData = [] } = useRequest(getUserRoleNocheck);
  // 可选权限模版
  const {
    data: permissionData = [],
    run: queryTemplate,
    loading,
  } = useRequest(getPermissionTemplateInfo, {
    manual: true,
    formatResult(data) {
      let newArr = data?.filter((i: any) => {
        if (
          i?.status !== 'DISABLE_TEMPLATE' &&
          i?.status !== 'DISABLE_TEMPLATE_AND_PERMISSION'
        ) {
          return i;
        }
      });
      return newArr;
    },
  });
  //保存草稿， 当作删除接口
  const { run: draftRun } = useRequest(saveDraftFat, {
    manual: true,
    onSuccess: () => {
      message.success('删除成功');
      refreshAppResource();
    },
  });

  useEffect(() => {
    if (record?.connection) {
      queryTemplate(record?.connection?.connectionType);
    }
  }, [record]);

  const roleMenu = (record: any) => (
    <Menu
      className={classnames(styles.optionTxt, styles.menuWrap)}
      onClick={(info) =>
        dispatch(setRoleChange({ info, item: record, name: 'roleId' }))
      }
    >
      {userRoleData?.map((item: any) => (
        <Menu.Item key={item?.roleType}>{item?.roleName}</Menu.Item>
      ))}
    </Menu>
  );

  const permissionMenu = (record: any) => (
    <Menu
      className={classnames(styles.optionTxt, styles.menuWrap)}
      onClick={async(info) =>{
         //判断是否重置
        if (!record?.valid) {
          const resetOrNot = await  resetPermissionModal({
            title:'是否更改权限等级',
            content: '此schema下数据库对象存在其他权限设置,更改权限等级相当于重置此schema下的其他权限设置'
          })
          if (!resetOrNot) {
            return
          }
        }
        dispatch(setRoleChange({ info, item: {...record,valid: true}, name: 'permissionType' }))
      }
      }
    >
      {permissionData?.map((item: any) => (
        <Menu.Item
          key={item?.id}
          disabled={[
            'DISABLE_TEMPLATE_AND_PERMISSION',
            'DISABLE_TEMPLATE',
          ].includes(item?.status) || ['无权限'].includes(item?.name)}
        >
          {item?.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  // 编辑工具权限
  const handleEditToolPermission = (item: any) => {
    setEditToolItem(item)
    setTemplateOperationsSet(item?.templateOperationsSet)
    setToolsModalVisible(true)
  }

  // 工具权限修改
  const updateToolsAuth = (params: any) => {
    dispatch(setRoleChange({ info:{key: params}, item: editToolItem, name: 'templateOperationsSet' }))
  }

  const newExpandColumns = useMemo(() => {
    const newColumns = detailExpamdedRow.map((item: any) => {
      if (item?.dataIndex === 'nodeName') {
        return {
          ...item,
          render: (val: any, record: any) => (
            <div>
              <Iconfont
                type={`${
                  record?.nodeType === 'datasource'
                    ? `icon-connection-${record?.nodeName}`
                    : record?.nodeType === 'group'
                    ? 'icon-shujukuwenjianjia'
                    : record?.nodeType === 'connection'
                    ? `icon-${record?.dataSourceType}`
                    : `icon-${record?.nodeType}`
                } `}
              />
              <span>{record?.nodeName}</span>
            </div>
          ),
        };
      }
      if (item?.dataIndex === 'actions') {
        return {
          ...item,
          render: (val: any, childRecord: any) => (
            <CloseCircleOutlined
              className={styles.icon}
              onClick={() => {
                // delScFn(record?.flowUUID)
                // console.log('=====删除', record)
                // 如果这里使用调接口删除资源，会刷新整个表格重置原来选好的申请权限数据
                // 2.4.5版本暂定，可优化为权限申请和删除都调接口这样用户刷新再回来数据不会丢失
                // dispatch(setOnDeleteItem(record))
				//base-2.4.7  使用保存草稿接口直接删除
                const deleteItem = requestListData?.map((item) => {
                  if (item?.id === record?.id) {
                    return {
                      ...item,
                      businessDataListNoChild:
                        item?.businessDataListNoChild.filter(
                          (child: any) => child?.id !== childRecord?.id
                        ),
                    };
                  }
                  return item;
                });
                draftRun({
                  list: deleteItem,
                });
              }}
            />
          ),
        };
      }
      // 权限申请
      if (item?.dataIndex === 'permissionTypeName') {
        return {
          ...item,
          render: (val: any, record: any) => {
            return (
              <Tooltip  title={!record?.valid && '此用户在此层级下存在其他权限设置'}>
                <span className={classnames({
                   [styles.deleteLine]: !record?.valid,
                })}>
                  {getPermissionValue(permissionData, record?.permissionType)?.length > 10 ? `${getPermissionValue(permissionData, record?.permissionType)?.slice(0,10)}...`:getPermissionValue(permissionData, record?.permissionType)}
                </span>
                <Dropdown overlay={() => permissionMenu(record)}>
                  <DownOutlined
                    className={classnames(styles.ml10, styles.options)}
                  />
                </Dropdown>
              </Tooltip>
            );
          },
        };
      }
      // 角色
      if (item?.dataIndex === 'roleName') {
        return {
          ...item,
          render: (val: any, record: any, index: number) => (
            <>
              <span>{getRoleTypeValue(userRoleData, record?.roleId)}</span>
              {/* {
								index === 0 && <Dropdown overlay={() => roleMenu(record)}>
									<DownOutlined
										className={classnames(styles.ml10, styles.options)}
									/>
								</Dropdown>
							} */}
						</>
					),
				}
			}

      // 工具权限申请
      if(item?.dataIndex === 'toolsAuth') {
        return {
          ...item,
          render: (val: any, record: any) => {
            let resultName: any = []
            record?.templateOperationsSet?.forEach((item: any) => {
              const matchedObejct = templateEnum?.find((res:any) => res?.objectType === item?.objectType);
              if (matchedObejct) {
                item?.operations?.forEach((val: any)=>{
                  const matchedItem = matchedObejct?.operations?.find((res:any) => res?.operation === val);
                  if(matchedItem?.operationName){
                    resultName.push(matchedItem?.operationName)
                  }
                })
              }
            });
					  const title = resultName?.join(',') || '-'
            return (
              (!record?.children) && 
              <Tooltip
                title={title}
              >
                <span
                  className={styles.options}
                  onClick={() =>
                    handleEditToolPermission(record)
                  }
                >
                  请选择
                  <DownOutlined className={classnames('ml4 options')} style={{fontSize: '14px'}} />
                </span>
              </Tooltip>
            )
          },
        }
      }

			return item
		})
		return newColumns
	}, [permissionData, templateEnum])

  return (
    <>
      <Table
        rowKey="id"
        loading={loading}
        columns={newExpandColumns}
        dataSource={data}
        pagination={false}
        size="middle"
      />
      {/* 工具权限 */}
      {toolsModalVisible && (
        <PermissionLevelModal
          visible={toolsModalVisible}
          optionType="editToolPermission"
          selectNodeType={editToolItem?.nodeType}
          dataSourceType={editToolItem?.dataSourceType}
          setModalVisible={setToolsModalVisible}
          templateOperationsSet={templateOperationsSet}
          callback={updateToolsAuth}
        />
      )}
    </>
  );
};
