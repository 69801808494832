import React from 'react'
import { useSelector } from 'src/hook'
import { Form, Input } from 'antd'

export const FormItemApplicant = () => {
  const { userName, telephone } = useSelector((state) => state.login.userInfo)

  return (
    <>
      <Form.Item label="申请人">
        <Input value={userName} readOnly bordered={false} />
      </Form.Item>
      {/* <Form.Item label="手机号码">
        <Input
          value={telephone || '（空）'}
          readOnly
          bordered={false}
          disabled={!telephone}
        />
      </Form.Item> */}
    </>
  )
}
