import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { PhoneMsmCard } from './PhoneMsmCard'
import { AlarmConfigCard } from './SmsGatewayCard/AlarmConfigCard';

interface IProps {
  subTabKey?: string
}
export const SmsGatewayCard = (props: IProps) => {

  const [tabsKey, setTabsKey] = useState<string>("commandReview")

  useEffect(() => {
    if (props?.subTabKey) {
      setTabsKey(props?.subTabKey)
    }
  }, [])

  return (
    <section className="cq-new-card flow-card" id="PhoneMsmCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">短信网关设置</h3>
      </div>
      <section className="card__content" style={{ padding: '0 16px' }}>
        <Tabs
          activeKey={tabsKey}
          onChange={(e) => {
            setTabsKey(e)
          }}
        >
          <Tabs.TabPane key="commandReview" tab="命令复核">
            <PhoneMsmCard tabKey={tabsKey} />
          </Tabs.TabPane>
          <Tabs.TabPane key="twoFactorAuth" tab="双因素认证">
            <PhoneMsmCard tabKey={tabsKey} />
          </Tabs.TabPane>
          <Tabs.TabPane key="alarmConfig" tab="告警配置">
            <AlarmConfigCard tabKey={tabsKey} />
          </Tabs.TabPane>
        </Tabs>
      </section>
    </section>
  );
};