import { createSlice, PayloadAction } from '@reduxjs/toolkit'
/**
 * 加密算法 SM2 | RSA
 */
interface AppearanceState {
  algorithm: string
  publicKey: string
}

const initialState: AppearanceState = {
  algorithm: '',
  publicKey:''
}
export const encryptionSlice = createSlice({
  name: 'encryption',
  initialState,
  reducers: {
    setAlgorithm: (state, action: PayloadAction<string>) => {
      state.algorithm = action.payload
    },
    setPublicKey: (state, action: PayloadAction<string>) => {
      const editorPromptOnClose = action.payload
      state.publicKey = editorPromptOnClose
    }
  },
})

export const encryptionReducer = encryptionSlice.reducer

export const { setAlgorithm, setPublicKey } = encryptionSlice.actions
