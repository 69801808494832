import React, { useState, useEffect, useCallback } from 'react'
import { Button, List } from 'antd'
import { LinkButton, ResizeTable, UIModal } from 'src/components'
import styles from './index.module.scss'
import {
  compileInvalidObjects,
  compileInvalidObjectsErrors,
  getInvalidObjects,
} from 'src/api'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'

interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
}

export const CompileInvalidPane = ({ databaseInfo }: CreateTablePaneProps) => {
  const [data, setData] = useState<any>([])
  const [errorList, setErrorList] = useState<any>([])
  const [tableLoading, setTableLoading] = useState(false)
  const [errorListLoading, setErrorListLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const { connectionId, connectionType, nodeName, nodeType } = databaseInfo

  //获取当前连接下编译无效对象列表
  const getInvalidObject = useCallback(async () => {
    try {
      setTableLoading(true)
      const { invalidObjects } = await getInvalidObjects({
        connectionId,
        connectionType,
        nodeName,
        nodeType,
      })
      setData(invalidObjects)
    } catch {
      setData([])
    } finally {
      setTableLoading(false)
    }
  }, [connectionId, connectionType, nodeName, nodeType])

  //前端发送请求，后端再次编译无效对象，并刷新编译结果
  const compileInvalidObject = useCallback(async () => {
    try {
      setTableLoading(true)
      for (let i = 0; 50 * i < data.length; i++) {
        if (50 * (i + 1) > data.length) {
          await compileInvalidObjects({
            connectionId: connectionId,
            connectionType: connectionType,
            invalidObjects: data.slice(50 * i, data.length),
          })
        } else {
          await compileInvalidObjects({
            connectionId: connectionId,
            connectionType: connectionType,
            invalidObjects: data.slice(50 * i, 50 * (i + 1)),
          })
        }
      }
      getInvalidObject()
    } catch {
      setData([])
    } finally {
      setTableLoading(false)
    }
  }, [connectionId, connectionType, data, getInvalidObject])

  //获取编译无效对象错误日志
  const compileInvalidObjectsError = useCallback(
    async (record) => {
      try {
        setErrorListLoading(true)
        const { errors } = await compileInvalidObjectsErrors({
          connectionId: connectionId,
          connectionType: connectionType,
          name: record.name,
          owner: record.owner,
          objType: record.type,
        })
        const list = errors.map((obj: any) => {
          return obj.error
        })
        setErrorList(list)
      } catch {
        setErrorList([])
      } finally {
        setErrorListLoading(false)
      }
    },
    [connectionId, connectionType],
  )

  const columns = [
    {
      title: 'OWNER',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '编译时间',
      dataIndex: 'compiledTime',
      key: 'compiledTime',
    },
    {
      title: '错误查看',
      dataIndex: 'error',
      key: 'error',
      render: (_:any, record:any) => {
      return (
        <LinkButton onClick={() => {
          compileInvalidObjectsError(record)
          setVisible(true)
        }}>查看</LinkButton>
      )
    }
    }
  ]

  useEffect(() => {
    getInvalidObject()
  }, [getInvalidObject])
  return (
    <div className={styles.panel}>
      <Button
        onClick={() => {
          compileInvalidObject()
        }}
        style={{ marginTop: 32, marginLeft: 20 }}
      >
        编译无效对象
      </Button>
      <Button
        onClick={() => {
          getInvalidObject()
        }}
        disabled={tableLoading === true && true}
        style={{ marginTop: 32, marginLeft: 20 }}
      >
        刷新列表
      </Button>
      <ResizeTable
        className={styles.table}
        size="small"
        loading={tableLoading}
        dataSource={data}
        columns={columns}
        style={{ marginTop: 40, marginLeft: 20 }}
      ></ResizeTable>
      <UIModal
        title="错误日志"
        className={styles.uimodal}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <List
          className={styles.list}
          pagination={false}
          dataSource={errorList}
          loading={errorListLoading}
          renderItem={(item: any) => {
            return <List.Item>{item}</List.Item>
          }}
        ></List>
      </UIModal>
    </div>
  )
}
