import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import * as _ from 'lodash'
import { AppThunk, RootState } from 'src/store'
import { persistReducer, PURGE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  DataSourceType,
  getNodeChildList,
  openSdtNode,
  SdtNodeType,
  TreeNode,
  showSdtNodeView,
  NodeEntity,
  ResponseNodeEntity,
  getCallStatement,
  DataSourceWithAdditionalSchema,
  SdtResponse,
  openTaskExecution,
  openRefreshTask
} from 'src/api'
import { getToolbarConnections } from 'src/store/extraSlice/editorSlice'
import { getInfoFromPath } from 'src/util'
import { addPane, PaneType } from '../queryTabs/queryTabsSlice'
import { formatTreeNode } from './const.sdt'
import { indexedDBStorage } from 'src/store/indexedDB'

export const NodeTypesSupportEditorView: SdtNodeType[] = [
  'table',
  'view',
  'collection',
  'function',
  'procedure',
  'synonym',
  'sequence',
  'package',
  'type',
  'packageBody',
  'materializedView',
  'syncMaterializedView',
  'asyncMaterializedView',
  'trigger',
]

export interface IBatchCreateConnectionLog {
  name: 'VERIFY' | 'EXECUTE' | 'SUCCESS' | 'FAILED'
  content: string
  createTime: number
}

interface SdtState {
  treeData: TreeNode[]
  selectedNode: TreeNode
  // todo: 理清 selected 和 rightCliicked 关系
  rightClickedNode: Partial<TreeNode>
  expandedKeys: (string | number)[]
  loadedKeys: (string | number)[]
  treeLoading: boolean
  batchCreateConnectionLog: IBatchCreateConnectionLog[]
  /**
   * nodePath => node children
   */
  treeNodeChildrenMap: Record<string, ResponseNodeEntity[]>
  treeNodeChildrenFetching: Record<string, boolean>
  permissionFlag?: boolean,
  resourceMap: any
}

const initialState: SdtState = {
  treeData: [],
  selectedNode: {} as TreeNode,
  rightClickedNode: {},
  expandedKeys: [],
  loadedKeys: [],
  treeLoading: false,
  batchCreateConnectionLog: [],
  treeNodeChildrenMap: {},
  treeNodeChildrenFetching: {},
  resourceMap: {}
}
const getTargetNode = (nodePath: string, treeData: TreeNode[]) => {
  const queue = [...treeData]
  while (queue.length) {
    const currentNode = queue.shift()
    if (!currentNode) return
    if (currentNode.nodePath === nodePath) {
      return currentNode
    }
    if (currentNode.children) {
      queue.push(...currentNode.children)
    }
  }
}
const isChildKeyOf = (childKey: string, parentKey: string) =>
  childKey !== parentKey && childKey.startsWith(parentKey)

// 更新树节点 children
export const fetchTreeNodeChildren = createAsyncThunk<
  SdtResponse,
  NodeEntity,
  { state: RootState }
>(
  'sdt/fetchTreeNodeChildren',
  async (params) => {
    const list = await getNodeChildList(params)
    return list
  },
  {
    condition({ nodePath }, { getState }) {
      const {
        sdt: { treeNodeChildrenFetching },
      } = getState()
      const fetching = treeNodeChildrenFetching[nodePath]
      // 应用当前已经在获取数据，则不再重复请求
      return !fetching
    },
  },
)

// 直接从 map 中获取节点列表，如果尚未请求过，触发请求
export const getTreeNodeChildren = createAsyncThunk<
  ResponseNodeEntity[],
  NodeEntity,
  { state: RootState }
>('sdt/getTreeNodeChildren', async (params, { dispatch, getState }) => {
  const { nodePath } = params
  if (!nodePath) {
    throw new Error('invalid node')
  }
  const {
    sdt: { treeNodeChildrenMap, treeNodeChildrenFetching },
    sdtPermission: { status = false }
  } = getState()

  const memo = treeNodeChildrenMap[nodePath]

  //load 缓存中有
  if (memo) {
    dispatch(
      setTreeDataNodeChildren({
        nodePath,
        children: memo.map(formatTreeNode),
      }),
    )
    return memo
  }
  const isParentLoading = Object.keys(treeNodeChildrenFetching).some(d => treeNodeChildrenFetching[d] && d !== nodePath && nodePath.includes(d))
  if (isParentLoading) {
    const timeout = nodePath.split('/')?.length ?? 0;
    return new Promise((res) => {
      setTimeout(async function () {
        const list = await dispatch(fetchTreeNodeChildren({
          ...params,
          globalHavePermissionFlag: status
        })).unwrap()
        res(list?.data)
      }, timeout * 200)
    })
  }

  // 字典搜索 需要 查找到父节点，父节点hasChild =false则不进行请求
  const parentNodePath = nodePath && nodePath.match(/.*\//)?.[0].slice(0, -1);
  const findParentNode: any = parentNodePath && treeNodeChildrenMap?.[parentNodePath]?.find(i => i.nodePath === nodePath)

  if (findParentNode?.hasChild === false && !_.isEmpty(findParentNode)) {
    return []
  }
  const list = await dispatch(fetchTreeNodeChildren({
    ...params,
    globalHavePermissionFlag: status
  })).unwrap()
  return list?.data
})

export const sdtSlice = createSlice({
  name: 'sdt',
  initialState,
  reducers: {
    setTreeData(state, action: PayloadAction<TreeNode[]>) {
      state.treeData = action.payload
    },
    // 重置某节点的子树
    refreshChildTreeCache(state, action: PayloadAction<string>) {
      const nodePath = action.payload
      const { loadedKeys, treeNodeChildrenMap } = state
      // 重置目标节点所有子节点的加载状态
      state.loadedKeys = loadedKeys.filter(
        (key) => !isChildKeyOf(key.toString(), nodePath),
      )
      // 重置目标节点所有子节点的列表缓存
      for (const key of Object.keys(treeNodeChildrenMap)) {
        if (isChildKeyOf(key.toString(), nodePath)) {
          delete treeNodeChildrenMap[key]
        }
      }
    },
    setTreeDataNodeChildren: (
      state,
      action: PayloadAction<{
        nodePath: string
        children: TreeNode[]
      }>,
    ) => {
      const { nodePath, children } = action.payload
      const parent = getTargetNode(nodePath, state.treeData)
      if (parent) {
        parent.children = children
      }
    },
    resetTreeNodeChildrenMap: (state) => {
      state.treeNodeChildrenMap = {}
    },
    setSelectedNode: (state, action: PayloadAction<any>) => {
      state.selectedNode = action.payload
    },
    setRightClickedNode: (state, action: PayloadAction<any>) => {
      state.selectedNode = action.payload
      state.rightClickedNode = action.payload
    },
    setExpandedKeys: (state, action: PayloadAction<(string | number)[]>) => {
      state.expandedKeys = action.payload
    },
    setLoadedKeys: (state, action: PayloadAction<(string | number)[]>) => {
      state.loadedKeys = action.payload
    },
    setTreeLoading: (state, action: PayloadAction<boolean>) => {
      state.treeLoading = action.payload
    },
    pushBatchCreateConnectionLog: (
      state,
      action: PayloadAction<IBatchCreateConnectionLog>,
    ) => {
      state.batchCreateConnectionLog.push(action.payload)
    },
    clearBatchCreateConnectionLog: (state) => {
      state.batchCreateConnectionLog = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreeNodeChildren.pending, (state, action) => {
      const { nodePath } = action.meta.arg
      const { treeNodeChildrenFetching } = state
      treeNodeChildrenFetching[nodePath] = true
    })
    builder.addCase(fetchTreeNodeChildren.fulfilled, (state, action) => {
      const list = action.payload
      const { nodePath, nodeType, connectionId } = action.meta.arg
      const target = getTargetNode(nodePath, state.treeData)
      // 结果更新到 treeData
      // if (target && list?.length) {
      //   target.children = list.map(formatTreeNode)
      //   // ! 当前后端没有返回 childCount
      //   // target.childCount = list.length
      // }
      // 当list为空时，需要清空children，否则原元素还存在
      if (target) {
        if (list?.data.length) target.children = list?.data.map(formatTreeNode)
        else target.children = []
      }
      // memoize
      state.treeNodeChildrenMap[nodePath] = list?.data
      state.treeNodeChildrenFetching[nodePath] = false
      // 资源纳管 连接层 
      if (nodeType === 'connection') {
        state.resourceMap[connectionId] = list?.resourceLimit
      }
    })
    builder.addCase(fetchTreeNodeChildren.rejected, (state, action) => {
      const { nodePath } = action.meta.arg
      state.expandedKeys = state.expandedKeys.filter((key) => key !== nodePath)
      state.loadedKeys = state.loadedKeys.filter((key) => key !== nodePath)
      state.treeNodeChildrenFetching[nodePath] = false
    })
  },
})

const reducer = sdtSlice.reducer
const reducerWithPurge: typeof reducer = (state, action) => {
  if (action.type === PURGE) {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}
export const sdtReducer = persistReducer(
  {
    key: 'sdt',
    version: 3,
    storage: indexedDBStorage,
    whitelist: ['expandedKeys'],
  },
  reducerWithPurge,
)

export const {
  setTreeData,
  refreshChildTreeCache,
  setTreeDataNodeChildren,
  setSelectedNode,
  setRightClickedNode,
  setExpandedKeys,
  setLoadedKeys,
  setTreeLoading,
  resetTreeNodeChildrenMap,
  pushBatchCreateConnectionLog,
  clearBatchCreateConnectionLog,
} = sdtSlice.actions

export const fetchConnections = (): AppThunk => async (dispatch, getState) => {
  const {
    sdt: { expandedKeys },
    sdtPermission: { status }
  } = getState()
  dispatch(setTreeLoading(true))
  dispatch(getToolbarConnections())
  getNodeChildList({ nodeType: 'root', nodePath: '/root', globalHavePermissionFlag: status })
    .then((list) => {
      const treeData = list?.data.map(formatTreeNode)
      dispatch(setTreeData(treeData))
      batch(() => {
        dispatch(setLoadedKeys([]))
        dispatch(setExpandedKeys([...expandedKeys]))
      })
    })
    .finally(() => {
      dispatch(setTreeLoading(false))
    })
}

export const refreshOnRoot = (): AppThunk => (dispatch) => {
  dispatch(resetTreeNodeChildrenMap())
  dispatch(fetchConnections())
}

export const viewElementInEditor =
  (selectedNode: Partial<TreeNode>): AppThunk =>
    (dispatch) => {
      const { nodeType, nodePath, nodePathWithType } = selectedNode
      if (!nodeType) return
      // 后端处理视图和物化视图一样，将物化视图的nodeType改为'view'
      let nodeTypeTrans = nodeType
      if (nodeType === 'materializedView') {
        nodeTypeTrans = 'view'
      }
      const { connectionType, connectionId, nodeName } = selectedNode
      const schemaName = getInfoFromPath(nodePath, 'schema', connectionType)
      const nodeParams = {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType: nodeTypeTrans,
      }
      openSdtNode(nodeParams).then(({ generatedSql }) => {
        const databaseName = getInfoFromPath(nodePath, 'database', connectionType)
        let plSql = ['procedure', 'function', 'synonym'].includes(nodeType)
         //StarRocks 查看函数自动执行
        if (connectionType === 'StarRocks' && nodeType === 'function') {
          plSql = false;
        }
        // CQ-4506 OceanBase，右键打开类型，不需要自动执行
        if (connectionType === 'OceanBase' && nodeType === 'type') {
          plSql = true;
        }
        const paneType = plSql
          ? connectionType === 'SQLServer'
            ? 'tSql'
            : 'plSql'
          : 'monaco'
        dispatch(
          addPane({
            tabName: nodeName,
            value: generatedSql,
            connectionId,
            connectionType,
            databaseName: ['DamengDB', 'DB2'].includes(connectionType as any) ? schemaName : databaseName,
            schemaName,
            plSql,
            tSql: paneType === 'tSql',
            autoRun: !plSql,
            paneType: paneType,
          }),
        )
      })
    }
  // 打开任务
export const openTaskExecutionInEditor =
(selectedNode: Partial<TreeNode>): AppThunk =>
  (dispatch) => {
    const { nodeType, nodePath } = selectedNode
    if (!nodeType) return
    // 后端处理视图和物化视图一样，将物化视图的nodeType改为'view'
    let nodeTypeTrans = nodeType
    // if (['materializedView', 'syncMaterializedView', 'asyncMaterializedView'].includes(nodeType)) {
    //   nodeTypeTrans = 'view'
    // }
    const { connectionType, connectionId, nodeName } = selectedNode
    const schemaName = getInfoFromPath(nodePath, 'schema', connectionType)
    const nodeParams = {
      connectionId,
      connectionType,
      nodeName,
      nodePath,
      nodeType: nodeTypeTrans,
    }
    openTaskExecution(nodeParams).then(({ generatedSql }) => {
      const databaseName = getInfoFromPath(nodePath, 'database', connectionType)
      let plSql = ['procedure', 'function', 'synonym'].includes(nodeType)
      //StarRocks 查看函数自动执行
      if (connectionType === 'StarRocks' && nodeType === 'function') {
        plSql =false;
      }
      const paneType = plSql
        ? connectionType === 'SQLServer'
          ? 'tSql'
          : 'plSql'
        : 'monaco'
      // mariaDB mysql oracle oracleCDB postgres sqlserver 六大数据源打开普通编译器 不分句
      let editorFlag = false
      if (connectionType === 'MySQL' || connectionType === 'OracleCDB' || connectionType === 'Oracle' || connectionType === 'SQLServer' || connectionType === 'PostgreSQL' || connectionType === 'MariaDB') {
        editorFlag = true
      }
      
      dispatch(
        addPane({
          tabName: nodeName,
          value: generatedSql,
          connectionId,
          connectionType,
          databaseName: ['DamengDB', 'DB2'].includes(connectionType as any) ? schemaName : databaseName,
          schemaName,
          plSql,
          tSql: paneType === 'tSql',
          autoRun: !plSql,
          paneType: editorFlag ? 'monaco' : paneType,
        }),
      )
    })
}
// 刷新任务
export const openRefreshTaskInEditor =
(selectedNode: Partial<TreeNode>): AppThunk =>
(dispatch) => {
  const { nodeType, nodePath } = selectedNode
  if (!nodeType) return
  // 后端处理视图和物化视图一样，将物化视图的nodeType改为'view'
  let nodeTypeTrans = nodeType
  // if (['materializedView', 'syncMaterializedView', 'asyncMaterializedView'].includes(nodeType)) {
  //   nodeTypeTrans = 'view'
  // }
  const { connectionType, connectionId, nodeName } = selectedNode
  const schemaName = getInfoFromPath(nodePath, 'schema', connectionType)
  const nodeParams = {
    connectionId,
    connectionType,
    nodeName,
    nodePath,
    nodeType: nodeTypeTrans,
  }
  openRefreshTask(nodeParams).then(({ generatedSql }) => {
    const databaseName = getInfoFromPath(nodePath, 'database', connectionType)
    let plSql = ['procedure', 'function', 'synonym'].includes(nodeType)
    //StarRocks 查看函数自动执行
    if (connectionType === 'StarRocks' && nodeType === 'function') {
      plSql =false;
    }
    const paneType = plSql
      ? connectionType === 'SQLServer'
        ? 'tSql'
        : 'plSql'
      : 'monaco'
    // mariaDB mysql oracle oracleCDB postgres sqlserver 六大数据源打开普通编译器 不分句
    let editorFlag = false
    if (connectionType === 'MySQL' || connectionType === 'OracleCDB' || connectionType === 'Oracle' || connectionType === 'SQLServer' || connectionType === 'PostgreSQL' || connectionType === 'MariaDB') {
      editorFlag = true
    }
    
    dispatch(
      addPane({
        tabName: nodeName,
        value: generatedSql,
        connectionId,
        connectionType,
        databaseName: ['DamengDB', 'DB2'].includes(connectionType as any) ? schemaName : databaseName,
        schemaName,
        plSql,
        tSql: paneType === 'tSql',
        autoRun: !plSql,
        paneType: editorFlag ? 'monaco' : paneType,
      }),
    )
  })
}
/** 调用存储过程 */
export const callProcedure = (selectedNode: Partial<TreeNode>): AppThunk =>
  (dispatch) => {
    const { connectionId, connectionType, nodeName, nodePath, nodeType, nodePathWithType } = selectedNode
    const nodeParams = {
      connectionId,
      connectionType,
      nodeName,
      nodePath,
      nodeType,
      nodePathWithType,
    }
    getCallStatement(nodeParams).then((res) => {
      const databaseName = getInfoFromPath(
        nodePath,
        'database',
        connectionType,
      )
      const generatedSql = res.generatedSql
      const plSql = res.plSql as boolean
      const schemaName = getInfoFromPath(
        nodePath,
        'schema',
        connectionType,
      )
      const paneType = plSql
        ? connectionType === 'SQLServer'
          ? 'tSql'
          : 'plSql'
        : 'monaco'
      dispatch(
        addPane({
          tabName: nodeName,
          value: generatedSql || '',
          connectionId,
          connectionType,
          databaseName,
          schemaName,
          plSql,
          // CQ-4478 函数调用和存储过程调用默认不执行
          // autoRun: !!plSql,
          autoRun: false,
          paneType: paneType,
          menuType: 'call'
        }),
      )
    })
  }
export const showViewInEditor =
  (selectedNode: Partial<TreeNode>): AppThunk =>
    (dispatch) => {
      const { nodeType, nodePath } = selectedNode
      if (!nodeType) return
      const { connectionType, connectionId, nodeName, nodePathWithType } = selectedNode
      const nodeParams = {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodeType,
        nodePathWithType,
      }
      showSdtNodeView(nodeParams).then(({ generatedSql }) => {
        const databaseName = getInfoFromPath(nodePath, 'database')
        const plSql = [
          'trigger',
          'procedure',
          'function',
          'synonym',
          // 'sequence',
          'package',
          'type',
          'packageBody',
        ].includes(nodeType)
        const paneType = plSql
          ? connectionType === 'SQLServer'
            ? 'tSql'
            : 'plSql'
          : 'monaco'
        dispatch(
          addPane({
            tabName: nodeName,
            value: generatedSql,
            connectionId,
            connectionType,
            // databaseName: (['PostgreSQL'] as DataSourceType[]).includes(
            //   connectionType!,
            // )
            //   ? getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
            //   : databaseName,
            databaseName,
            plSql,
            tSql: paneType === 'tSql',
            autoRun: false,
            paneType: paneType,
            schemaName:
              !!connectionType &&
                DataSourceWithAdditionalSchema.includes(connectionType)
                ? getInfoFromPath(nodePath, 'schema', connectionType)
                : undefined,
          }),
        )
      })
    }

/** 合并 plSql 和 tSql 两者编辑器除了icon以外其他相同*/
export const openSqlEditor =
  (openType: PaneType, selectedNode: Partial<TreeNode>): AppThunk =>
    (dispatch) => {
      const { nodePath } = selectedNode
      const { connectionType, connectionId, nodeName } = selectedNode
      const databaseName = getInfoFromPath(nodePath, 'database')
      const isTsql = openType === 'tSql' ? true : false
      dispatch(
        addPane({
          tabName: nodeName,
          connectionId,
          connectionType,
          databaseName: (['PostgreSQL'] as DataSourceType[]).includes(
            connectionType!,
          )
            ? getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
            : databaseName,
          plSql: true,
          tSql: isTsql,
          paneType: openType,
        }),
      )
    }

export const viewRedisKey =
  (selectedNode: Partial<TreeNode>): AppThunk =>
    (dispatch) => {
      const { nodeType, nodePath } = selectedNode
      if (!nodeType) return
      const { connectionType, connectionId, nodeName, nodePathWithType } = selectedNode
      const databaseName = getInfoFromPath(nodePath, 'database')
      dispatch(
        addPane({
          tabName: [databaseName, nodeName].join(' / '),
          connectionType,
          connectionId,
          databaseName,
          paneType: 'grid',
          nodePath,
          nodeName,
          nodeType,
          nodePathWithType
        }),
      )
    }
