/**
 * 流程表单 数据库元素字段控件（不包括 FormItem）{数据订正单选}
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { TreeSelect, Tag } from 'antd'
import {
  getDataChangedDatabaseList,
  getFlowConnectionNodes,
  FlowElementTreeNodeEntity,
} from 'src/api'
import { Iconfont, RenderDatabaseFieldIcon } from 'src/components'
import type { LegacyDataNode } from 'rc-tree-select/lib/interface'
import styles from './index.module.scss'

/** 元素节点元信息 */
type ElementNodeProps = any
interface ElementTreeSelectProps {
  experiment?: boolean //也可以用来判断是否为测试环境
  value?: string
  onChange?: (value?: string) => void
  selectedNodeConnectionType?: string
  setDatabaseType?: (params: {type: string, connectionId: string}) => void 
  dependFieldValue?: string; //允许本身修改
  selectedNodeConnectionName?: string
  [p: string]: any
}

export const SelectDataChangeTreeNodeSingle: React.FC<ElementTreeSelectProps> =
  React.memo(({ experiment = false, value, onChange, setDatabaseType, selectedNodeConnectionType, dependFieldValue, selectedNodeConnectionName, updateTemplateName }) => {
    const searchValue = useRef<string>('')
    // tree select 受控属性 expandedKeys & loadedKeys
    const [treeExpandedKeys, setTreeExpandedKeys] = useState<React.Key[]>([])
    const [treeLoadedKeys, setTreeLoadedKeys] = useState<React.Key[]>([])
    const [treeSelectedNodeInfo, setSelectedNodeInfo] = useState<any>({}); //用来展示label
    // tree map, 记录元素树节点的引用
    const treeMapRef = useRef(new Map<string, LegacyDataNode>())

    // ! 后续后端应该从 cookie 获取用户信息，不从表单传参
    const { userId } = useSelector((state) => state.login.userInfo)

    const mapDataToTreeNode = useCallback((data: FlowElementTreeNodeEntity) => {
      // 从 data 中解构出需要的参数
      const {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      } = data
      const nodeProps: ElementNodeProps = {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      }

      const treeNode = {
        key: nodePathWithType,
        value: nodePathWithType,
        title: nodeName,
        icon: <Iconfont type={`icon-${nodeType}`} />,
        props: nodeProps,
        isLeaf: !hasChild,
      };
      treeMapRef.current.set(nodePathWithType, treeNode);
      return treeNode
    }, [])

    // 获取元素树连接层级
    const {
      data: root,
      run: fetchConnections,
      mutate: setRoot,
    } = useRequest(getDataChangedDatabaseList, {
      manual: true,
      formatResult: (data) => {
        // 构造与子节点结构统一的连接节点
        const root = data.map((node) => {
          const {
            connectionId,
            nodeName,
            nodeType,
            nodePath,
            nodePathWithType,
            connectionType,
            hasChild,
            experiment,
            templateName
          } = node
          const icon = `icon-connection-${connectionType}`
          const nodeProps: ElementNodeProps = {
            connectionId,
            connectionType,
            nodePath,
            nodePathWithType,
            nodeType,
            nodeName,
            hasChild,
            templateName
          }
          const treeNode = {
            key: nodePathWithType,
            value: nodePathWithType,
            title: nodeName,
            icon: <Iconfont type={icon} />,
            props: nodeProps,
            selectable: false,
            connectionType,
            experiment,
            isLeaf: !hasChild,
          };
          treeMapRef.current.set(nodePathWithType, treeNode)
          return treeNode
        })
        return root
      },
    })
    useEffect(() => {
      if (userId) {
        fetchConnections()
      }
    }, [fetchConnections, userId])

    useEffect(() => {

      setSelectedNodeInfo({
        connectionName: selectedNodeConnectionName,
        connectionType: selectedNodeConnectionType
      })
    },[selectedNodeConnectionName,selectedNodeConnectionType])
    // 获取数据库元素节点子列表
    const { run: loadChildren } = useRequest(getFlowConnectionNodes, {
      manual: true,
      fetchKey: (params) => params.nodePathWithType,
      formatResult: (data) => data.map(mapDataToTreeNode),
    })

    const labeledValue = useMemo(() => {

      const element = value

      if (element) {
        return {
          value: element,
          label: <div style={{display: 'flex',alignItems: 'center'}}><Tag color={experiment ? '#CEE4FF': '#FDDBDB'} style={{color: experiment ? '#124BC8': '#EA6C6C'}}>
            {experiment? '测试': '生产'} </Tag>
            {RenderDatabaseFieldIcon(element, (treeSelectedNodeInfo?.connectionType || selectedNodeConnectionType), (treeSelectedNodeInfo?.connectionName || selectedNodeConnectionName))}
            </div>,
        }
      }
    }, [experiment, value, selectedNodeConnectionName, selectedNodeConnectionType])

    const rootFilterBySimulateNodePath = useMemo(() => {
        let originConnectionList = root?.filter(node => node?.experiment === experiment);
        if (selectedNodeConnectionType && dependFieldValue) {
          originConnectionList = originConnectionList?.filter(origin => origin?.connectionType === selectedNodeConnectionType)
        }
      return originConnectionList;

    },[root, selectedNodeConnectionType,experiment, dependFieldValue])

    const loadMoreData = async (node: LegacyDataNode) => {

      const key = node.key as string
      const props = node.props as ElementNodeProps
      try {
        const children = await loadChildren({
          ...props,
          flowType: 'dataCorrection',
          userId,
        })
        const targetNode = treeMapRef.current.get(key)
        if (!targetNode) return
        targetNode.children = children
        setRoot((root) => [...root])
        // 懒加载成功，加入 loadedKeys
        setTreeLoadedKeys((keys) => [...keys, key])
      } catch {
        // 懒加载失败，自动收起节点
        setTreeExpandedKeys((keys) => keys.filter((el) => el !== key))
      }
    }

    /** 
     * treeselect 在异步load数据并且支持搜索的情况下，点击搜索节点时无法触发异步load方法
     * 可以参考此issue，但是实际并无法解决问题，因此这里做个额外处理
     * https://github.com/ant-design/ant-design/issues/42366
     * https://github.com/ant-design/ant-design/issues/36063
     * */
    const handleExpand = (keys: React.Key[]) => {
      setTreeExpandedKeys(keys)
      // 没有搜索值时使用自带的load方法
      if (searchValue.current) {
        const unloadedKeys = keys.filter((key) => !treeLoadedKeys.some((loadedKey) => key === loadedKey))
        console.log('unloaded:', unloadedKeys)
        unloadedKeys.forEach(async (key) => {
          try {
            const targetNode = treeMapRef.current.get(key as string)
            if (!targetNode) return
            const children = await loadChildren({
              ...targetNode.props,
              flowType: 'dataCorrection',
              userId,
            })
            targetNode.children = children
            setRoot((root) => [...root])
            // 懒加载成功，加入 loadedKeys
            setTreeLoadedKeys((keys) => [...keys, key])
          } catch {
            // 懒加载失败，自动收起节点
            setTreeExpandedKeys((keys) => keys.filter((el) => el !== key))
          }
        })
      }
    }

    const getTemplateName = (value: string) => {
      const matchKey = '/' + value?.split('/')?.[1]
      const treeMap = treeMapRef.current
      const targetNode = treeMap.get(matchKey)
      return targetNode?.props?.templateName || '';
    }
    return (
      <TreeSelect
        dropdownClassName={styles.treeSelectDropdown}
        treeData={rootFilterBySimulateNodePath}
        value={labeledValue}
        // value={['/CONNECTION:1/DATABASE:postgres']}
        loadData={loadMoreData}
        onChange={(cvalue) => {
          const templateName = getTemplateName(cvalue?.value)
          updateTemplateName && updateTemplateName(templateName)
          //@ts-ignore
          onChange?.(cvalue?.value);
          if (!cvalue) {
            //@ts-ignore
            onChange?.(cvalue?.value)
          }
          const treeMap = treeMapRef.current
      
          // 过滤掉不存在的节点
            //@ts-ignore
          const targetNode = treeMap.get(cvalue?.value)
          if (!targetNode) return
        
          // 获取connectionName
          const connectionId = targetNode?.props?.connectionId;
          const connectionInfo = connectionId &&  treeMap.get(`/CONNECTION:${connectionId}`);
          setDatabaseType && setDatabaseType({type: targetNode?.props?.connectionType, connectionId})
          setSelectedNodeInfo({
            connectionName: connectionInfo?.title,
            connectionType: targetNode?.props?.connectionType
          })
          // const { hasChild, nodePathWithType, ...elementValue } =
          //   targetNode.props as ElementNodeProps
          //   onChange?.(nodePathWithType)
        }}
        treeExpandedKeys={treeExpandedKeys}
        onTreeExpand={handleExpand}
        treeLoadedKeys={treeLoadedKeys}
        showSearch
        treeNodeFilterProp="title"
        treeIcon
        allowClear
        placeholder="请选择数据库元素"
        // 为了在回填 元素树选中节点 时，在相关节点没有加载时能给出正确的渲染
        labelInValue={true}
        getPopupContainer={(triggerNode) => triggerNode.parentNode || document.body}
        onSearch={(value) => searchValue.current = value}
      />
    )
})
