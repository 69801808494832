import React, { useRef, useState, useCallback, useEffect } from 'react'
import * as _ from 'lodash'
import {  Descriptions, Input, message, Tabs } from 'antd'
import {
  executeMenuActionSql,
  newGenerateCreateSql,
  GenerateSqlParams,
} from 'src/api'

import { getSchemaFormConnectionType, correctComment } from 'src/util'
import { INCLUDE_SCHEMA_CONNECTIONS } from 'src/constants'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { refreshOnRoot } from '../../sdt'
import { TabColumnPane, TabSqlPane } from './components'
import styles from './index.module.scss'

const { TabPane } = Tabs
interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
}

export const CreateNewTablePane = ({
  databaseInfo,
  queryTabKey,
}: CreateTablePaneProps) => {
  const { theme } = useSelector((state) => state.appearance)

  const {
    connectionId,
    connectionType = 'MySQL',
    databaseName,
    nodePath,
  } = databaseInfo

  const { toolbarConnections } = useSelector((state) => state.editor)

  const connection = toolbarConnections?.find(
    (t) => t.connectionId === connectionId,
  )
  const connectionName = connection?.alias
    ? connection.alias
    : connection?.nodeName

    const schemaName = (() => {
      if (!nodePath) return ;
      let transformedSchemaName = getSchemaFormConnectionType(connectionType, nodePath);
       return transformedSchemaName;
    })()

  //创建表OracleCDB operatingObject 特殊处理
  const operatingObject = ['OracleCDB'].includes(connectionType)
    ? schemaName
    : databaseName
 // 缓存所有待提交的参数
 const generateParamsRef = useRef<Partial<GenerateSqlParams>>({})

  const [generatedSql, setGeneratedSql] = useState('')
  const [tableName, setTableName] = useState<string>('')
  const [tableComment, setTableComment] = useState<string>('')
  const [activeTabKey, setActiveTabKey] = useState<string>('columns')

  const dispatch = useDispatch()

  const getBaseFieldInfo = () => {
    let baseFiles: {schemaName?: string; databaseName?: string}= {schemaName};
     // todo: 从后端或数据源模块获取配置，自动处理数据源差异

    if (!['DamengDB', 'DB2'].includes(connectionType)) {
      baseFiles.databaseName = databaseName
    }

    generateParamsRef.current={
      connectionType,
      ...generateParamsRef.current,
      ...baseFiles
    }
  }

  const tryGenerateSql = (rowData: any) => {
    const columns: any[] = []
    const primaryKeys: any[] = []
    //@ts-ignore
    rowData?.forEachNode((rowNode, index) => {
      const { data } = rowNode
      const { columnName, isPrimary, comment } = data
      // 修正备注中带特殊符号的
      columns.push({
        ...data,
        comment: correctComment(connectionType, comment),
      })
      isPrimary ==='true' && primaryKeys.push(columnName)
    })

    let sqlParams: GenerateSqlParams = {
      ...generateParamsRef.current,
      connectionType,
      tableName,
      primaryKeys,
      columns,
    }

    if (tableComment) {
      sqlParams.tableInfo = {
        comment: correctComment(connectionType, tableComment),
      }
    }
    //方式tableName comment 修改重新执行这里
    generateParamsRef.current = sqlParams;
    newGenerateCreateSql(sqlParams)
      .then(({ sql }) => setGeneratedSql(sql))
      .catch(() => setGeneratedSql(''))
  }

  const [isTableCreated, setIsTableCreated] = useState<boolean>(false)

  const { run: createTable } = useRequest(executeMenuActionSql, {
    manual: true,
    onSuccess: (result) => {
      const [{ executeError }] = result
      if (executeError) {
        return message.error(executeError.message)
      }
      message.success('创建表成功')
      setIsTableCreated(true)
      dispatch(refreshOnRoot())
    },
  })

  useEffect(() => {
    getBaseFieldInfo()
  },[connectionType, connectionId])

  const onCreateTable = () => {
    if (!connectionId || !generatedSql) return
    createTable({
      connectionId,
      dataSourceType: connectionType,
      databaseName,
      operatingDatabase: databaseName,
      operatingObject: operatingObject,
      // plSql,
      tabKey: queryTabKey,
      statements: [generatedSql],
    })
  }

  // 表 或 表注释 生成sql,避免重复操作
  const onGenreateBaseInfoSql = () => {

    if (generateParamsRef.current) {
    //@ts-ignore
      newGenerateCreateSql({...generateParamsRef.current})
      .then(({ sql }) => setGeneratedSql(sql))
      .catch(() => setGeneratedSql(''))
    }
   
  }
  //change 太过频繁
  const onChangeBaseInfo = useCallback(
    _.throttle(onGenreateBaseInfoSql, 1000)
    , [])

  return (
    <div className={styles.createNewTablePane}>
      <Descriptions column={2}>
        <Descriptions.Item label="连接名">{connectionName}</Descriptions.Item>
        {!['DamengDB', 'DB2'].includes(connectionType) && (
          <Descriptions.Item label="数据库">{databaseName}</Descriptions.Item>
        )}
        {INCLUDE_SCHEMA_CONNECTIONS.includes(connectionType) && (
          <Descriptions.Item label="Schema">{schemaName}</Descriptions.Item>
        )}
        <Descriptions.Item label="表名">
          <Input.TextArea
            value={tableName}
            onChange={(e) => {
              console.log(3333);
              
              setTableName(e.target.value);
              generateParamsRef.current = {
                ...generateParamsRef.current,
                tableName: e.target.value
              }
              onChangeBaseInfo()
            }}
            style={{ width: 300 }}
            rows={2}
            className="mr16"
          />
        </Descriptions.Item>
        <Descriptions.Item label="表注释">
          <Input.TextArea
            value={tableComment}
            onChange={async(e) => {
              setTableComment(e.target.value)
              if (_.isEmpty(e.target.value)) {
                delete generateParamsRef.current?.tableInfo
              }else {
                generateParamsRef.current = {
                  ...generateParamsRef.current,
                  tableInfo:{
                    comment: correctComment(connectionType, e.target.value)
                  } 
                }
              }
              onChangeBaseInfo()
            }}
            style={{ width: 300 }}
            rows={2}
          />
        </Descriptions.Item>
      </Descriptions>
      <Tabs
        tabPosition="top"
        activeKey={activeTabKey}
        className={styles.tab}
        onChange={(key) => setActiveTabKey(key)}
      >
        <TabPane tab="字段" key="columns" tabKey="columns">
          <TabColumnPane
            connectionId={connectionId}
            connectionType={connectionType}
            theme={theme}
            onPreviewSQL={(rowData: GenerateSqlParams) => {
              setActiveTabKey('sql')
              tryGenerateSql(rowData)
            }}
          />
        </TabPane>
        <TabPane tab="SQL 预览" key="sql">
          <TabSqlPane
            isTableCreated={isTableCreated}
            onCreateTable={onCreateTable}
            onGeneratedSql={generatedSql}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}
