import React, { PropsWithChildren } from 'react'
import { useSelector } from 'src/hook'
import { Col, Divider, Row } from 'antd'
import { LazyWrapper } from 'src/components'
import { activeMonacoPaneInfoSelector } from '../../queryTabsSlice'
import { MonacoToolbarSessionSelectors } from './MonacoToolbarSessionSelector'
import { MonacoToolbarExecutions } from './MonacoToolbarExecutions'
import { MonacoToolbarEdit } from './MonacoToolbarEdit'
import { MonacoToolbarPlSqlEdit } from './MonacoToolbarPlSqlEdit'
import { MonacoToolbarFavorite } from './MonacoToolbarFavorite'
import { MonacoToolbarHelp } from './MonacoToolbarHelp'
import { RecentlyOpenedFiles } from './RecentlyOpenedFiles'
import { SaveDocument } from './SaveDocument'
import { MonacoExportStatement } from './MonacoExportStatement';
import styles from './index.module.scss'
import classNames from 'classnames'

interface ToolbarProps {
  onPressExecute: (flashbackSql?: string) => void
  clearModelDec: () => void
  onPreCommit?: () => void
  supportTransaction?: boolean
  oneLibraryAndOneConnection?: boolean
}

export const MonacoToolbar = React.memo(
  ({
    onPressExecute,
    clearModelDec,
    onPreCommit,
    supportTransaction,
    oneLibraryAndOneConnection,
  }: PropsWithChildren<ToolbarProps>) => {
    const { tabKeyList, paneInfoMap } = useSelector((state) => state.queryTabs)
    const panes = tabKeyList.map((key) => paneInfoMap[key])
    const activeMonacoPaneInfo = useSelector(activeMonacoPaneInfoSelector)
    const encoding = activeMonacoPaneInfo?.encoding

    if (!activeMonacoPaneInfo) {
      return null
    }

    const tabKey = activeMonacoPaneInfo.key
    return (
      <div className={classNames(styles.monacoToolbarWrapper, 'monaco-toolbar')}>
        <Row gutter={[16, 4]}>
          <Col>
            {/**
             * ! HACK 临时处理
             * 长 sql 执行时，请求数据库列表和切库请求会因为响应超时报错
             * 为了快速解决客户问题，把 session selector 改为多实例
             * 合理做法可能是把整个 toolbar 改为多实例，需要时间重新设计组件
             */}
            {panes.map((pane) => (
              <LazyWrapper key={pane.key} active={tabKey === pane.key}>
                <MonacoToolbarSessionSelectors
                  paneInfo={pane}
                  clearModelDec={clearModelDec}
                  oneLibraryAndOneConnection={oneLibraryAndOneConnection}
                />
              </LazyWrapper>
            ))}
          </Col>

          <Col>
            <Row align="middle">
              {panes.map((pane) => (
                <LazyWrapper key={pane.key} active={tabKey === pane.key}>
                  <MonacoToolbarExecutions
                    paneInfo={pane}
                    onPressExecute={(flashbackSql) => onPressExecute(flashbackSql)}
                    onPreCommit={onPreCommit}
                    supportTransaction={supportTransaction}
                  />
                </LazyWrapper>
              ))}

              <Divider type="vertical" className={styles.divider}></Divider>
              {activeMonacoPaneInfo.plSql ? (
                <MonacoToolbarPlSqlEdit />
              ) : (
                <MonacoToolbarEdit />
              )}
              <Divider type="vertical" className={styles.divider}></Divider>
              <MonacoToolbarFavorite />
              <Divider type="vertical" className={styles.divider}></Divider>
              <RecentlyOpenedFiles encoding={encoding} />
              <Divider type="vertical" className={styles.divider}></Divider>
              <SaveDocument encoding={encoding} />
              <Divider type="vertical" className={styles.divider}></Divider>
              <MonacoToolbarHelp />
              <Divider type="vertical" className={styles.divider}></Divider>
              <MonacoExportStatement />
            </Row>
          </Col>
        </Row>
      </div>
    )
  },
)
