import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { AppEntity, getAdminAppList } from 'src/api'

interface AppManagementState {
  adminAppList: AppEntity[]
  appListLoading: boolean
  selectedApp: AppEntity | null
}

const initialState: AppManagementState = {
  adminAppList: [],
  appListLoading: false,
  selectedApp: null,
}

export const appManagementSlice = createSlice({
  name: 'appManagement',
  initialState,
  reducers: {
    setAdminAppList(state, action: PayloadAction<AppEntity[]>) {
      state.adminAppList = action.payload
    },
    setAppListLoading: (state, action: PayloadAction<boolean>) => {
      state.appListLoading = action.payload
    },
    setSelectedApp: (state, action: PayloadAction<AppEntity>) => {
      state.selectedApp = action.payload
    },
  },
})

export const appManagementReducer = appManagementSlice.reducer

export const {
  setAdminAppList,
  setAppListLoading,
  setSelectedApp,
} = appManagementSlice.actions

export const getAndSetAdminAppList = (): AppThunk => (dispatch) => {
  dispatch(setAppListLoading(true))
  getAdminAppList()
    .then((adminAppList) => {
      dispatch(setAdminAppList(adminAppList))
    })
    .finally(() => dispatch(setAppListLoading(false)))
}
