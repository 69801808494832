import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Tabs } from 'antd'
import { FlowTablePending } from './FlowTablePending'
import { FlowTableResolved } from './FlowTableResolved'
import styles from './flowPage.module.scss'

export const FlowPendingApproval = () => {
  const history = useHistory()
  const params = useParams<{ status: 'pending' | 'resolved' }>()
  const activeTab = params.status

  return (
    <Card
      className={styles.contentCard}
      title="我的审批"
      bordered={false}
      bodyStyle={{ padding: 0, flex: 1, overflow: "hidden" }}
    >
      <Tabs
        className={styles.approvalTab}
        centered
        // TODO: 使用 hex
        tabBarStyle={{ marginBottom: 0, background: "rgba(51,87,255,0.04)" }}
        activeKey={activeTab}
        onChange={(key) => history.push(`/flow_apply/approval/${key}`)}
      >
        {/* 待处理 */}
        <Tabs.TabPane tab="待处理" key="pending">
          <FlowTablePending isFocused={activeTab === "pending"} />
        </Tabs.TabPane>
        {/* 已处理 */}
        <Tabs.TabPane tab="已处理" key="resolved">
          <FlowTableResolved isFocused={activeTab === "resolved"} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}
