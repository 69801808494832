import React, { useEffect } from 'react'
import { useDispatch, useSelector, } from 'src/hook'
import { loginCas, setRedirectUrl } from './loginSlice'
import { useLocation, useHistory } from 'react-router-dom'

export const WaitLogin = () => {
    // 获取ticket
    const ticket = useLocation()?.search   
    const history = useHistory()
    if(!ticket){
        history.push('/login')
    }

    const dispatch = useDispatch()
    const { isLoggedIn, userInfo, redirectUrl } = useSelector((state) => state.login)
    const pathname = decodeURIComponent(redirectUrl)

    const contentStyle = {
        fontSize: '30px',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    }

    useEffect(() => {
        if (!ticket) return
        dispatch(loginCas(ticket))
    }, [ticket, dispatch])

    useEffect(() => {
        if (isLoggedIn && userInfo && pathname) {
            history.push(pathname)
            // 清除pathname记录
            dispatch(setRedirectUrl("/"))
        }
    }, [history, isLoggedIn, pathname, userInfo, dispatch])

    return <div style={contentStyle}>
        <div>
            三方自动登录中......
        </div>
    </div>
}
