import React, { useEffect, useState } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { RcSegmented } from 'src/components'
import type { IApplyProps } from 'src/api';
import 'rc-segmented/assets/index.css'
import styles from './searchHeader.module.scss'

export const SearchHeader = ({
  searchParams,
  setSearchParams,
  refresh
}: {
  searchParams: IApplyProps;
  setSearchParams: (params: any) => void;
  refresh: () => void;
}) => {
  const [options, setOptions] = useState(['待审核', '已审核', '已完成'])

  useEffect(() => {
    // 我的申请中增加已撤回tab
    if (location.pathname === '/data_change_mine_apply') {
      setOptions([...options, '已撤回'])
    }
  }, [location.pathname])

  return (
    <div className={styles['header-search']}>
      <RcSegmented
        options={options}
        value={searchParams?.status}
        onChange={(val) => setSearchParams({ status: val })}
      />
       <Button
            icon={<RedoOutlined className={styles['refresh-btn']} />}
            onClick={() => refresh()}
          />
    </div>
  )
}
