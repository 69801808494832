/*
 * @Author: fuzhenghao
 * @Date: 2022-11-23 14:38:15
 * @LastEditTime: 2022-11-30 17:48:35
 * @LastEditors: fuzhenghao
 * @Description: newFlowProcess.tsx接口文件
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\flowDetails\newFlowProcess.interface.ts
 */

/**
 *
 * @前端开发 付政昊
 * @后端开发 邢利民
 * @description: 流程审批-主流程中-单个节点所处状态
 */
export type TFlowNodeType = 'sign' | 'orSign' | 'common'
export enum FlowNodeType {
  sign = '会签审核',
  orSign = '或签审核',
  common = '普通审核',
}
/**
 * @description 流程审批-主流程-单个节点-个人审批结果所处状态
 * @前端开发 付政昊
 * @后端开发 邢利民
 * @notice 没有接口文档,开发请注意正通常流程中应有撤回状态,由于功能目前没有所以暂时未添加,如需添加请同后端开发对齐接口
 *
 */
export type TFlowNodeResult =
  | 'pending'
  | 'pass'
  | 'refuse'
  | 'signPass'
  | 'signRefuse'
  | 'orSignPass'
  | 'orsignRefuse'
  | 'withdraw'

export enum FlowNodeResult {
  pending = '正在审核',
  pass = '审核通过',
  refuse = '审核驳回',
  signPass = '会签审核通过',
  signRefuse = '会签审核驳回',
  orSignPass = '或签审核通过',
  orsignRefuse = '或签审核驳回',
  withdraw = '撤回'
}
export type TProcessSteps = Array<TProcessStep>
export type TApprover = {
  approvedComment: string
  approvedCount: number
  approvedResult: TFlowNodeResult
  commitUserId?: string
  flowInstanceId: string
  flowTaskName: string
  approvedUser?: string
}
export type TProcessStep = {
  flowNodeName: string
  flowNodeResult: TFlowNodeResult
  flowNodeType: TFlowNodeType
  flowProcessInfos: Array<TApprover>
}

export type TCustomDot = {
  status?: TFlowNodeResult
  index: number
}

export interface INewFlowProcess {
  steps: TProcessSteps
}

export type TStepStats = 'wait' | 'process' | 'finish' | 'error'
