// re-export typed react-redux hooks
import { RootState, AppDispatch } from 'src/store'
import {
  useSelector as originalUseSelector,
  useDispatch as originalUseDispatch,
  TypedUseSelectorHook,
} from 'react-redux'

export const useSelector: TypedUseSelectorHook<RootState> = originalUseSelector
export const useDispatch = (): AppDispatch => originalUseDispatch()
