interface DownloadOptions {
  href: string
  download?: string
}
export const handleDownload = ({
  href,
  download = 'download',
}: DownloadOptions) => {
  const link = document.createElement('a')
  link.href = href
  link.download = download
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  link.remove()
}
