import { fetchPost } from './customFetch'

// 判断页面能否编辑
interface IPramas {
  systemPermissionType: string,
  nodePathWithType?: string
}
export const pagePermissionCanEdit = (params: IPramas) => {
  return fetchPost('/user/permission/support/edit', params)
}
