import { Form, Input, Radio, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { RefObject } from "react";

const { Item } = Form;
const desensOptions: Array<{
	label: string;
	value: number;
}> = [
	{
		label: "0",
		value: 0,
	},
	{
		label: "1",
		value: 1,
	},
];

interface IProps {
  formRef: RefObject<FormInstance<any>>
  initialValue?: Record<string, any>
  disabled?: boolean
}
const ThirdForm = ({ formRef, disabled }: IProps): JSX.Element => {
	return (
		<Form ref={formRef}>
			<Item label="执行方式">
				<Radio.Group>
					<Radio disabled>立即执行</Radio>
				</Radio.Group>
			</Item>
			<Item
				style={{ width: 200 }}
				label="任务名称"
				name={"taskName"}
				rules={[{ required: true, message: "请输入任务名称" }]}
				initialValue={""}
			>
				<Input disabled={disabled} />
			</Item>
			{/* <Item
				label="脱敏等级"
				name={"desensitizationGrade"}
				style={{ width: 200 }}
				initialValue={0}
			>
				<Select options={desensOptions}></Select>
			</Item> */}
			<Item
				label="允许表覆盖"
				style={{ width: 200 }}
				name={"overwriteTarget"}
				initialValue={false}
			>
				<Radio.Group disabled={disabled}>
					<Radio value={true}>是</Radio>
					<Radio value={false}>否</Radio>
				</Radio.Group>
			</Item>
		</Form>
	);
};

export { ThirdForm };

