import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: InitialState = {
  tableDataCollection: {},
}

export const designTableSlice = createSlice({
  name: 'designTable',
  initialState,
  reducers: {
    updateDesignInfo(state, action: PayloadAction<{tabKey: string,rowData: any}>) {
      const {tabKey,rowData=[]} = action.payload
      const {tableDataCollection} = state
      //类型错误导致存储字段失败
      tableDataCollection[tabKey] = rowData.map((item: any) => ({...item, length: Number(item.length)}))
     
    }
  },
})

export const { updateDesignInfo } = designTableSlice.actions

export const designTableReducer = designTableSlice.reducer

// types
interface InitialState {
    tableDataCollection: {[key: string]: any }
}
