import { Space, Typography, Tooltip } from "antd";
import React, { ReactNode } from "react";
import { Iconfont } from "src/components";

interface BasicObjectLabel {
	sourceType?: string;
	connectionName?: string;
	isConnectionDeleted?: boolean;
	database?: string;
	schema?: string;
}

interface BasicObjectChildLabel {
	sourceSchema?: string;
	sourceTable?: string;
	targetSchema?: string;
	targetTable?: string;
}

export function renderState(state: number): string | ReactNode {
	if (state === 0) {
		return "初始化";
	} else if (state === 1) {
		return <span><Iconfont type='icon-daizhixing' style={{marginRight: 4}} />等待中</span>;
	} else if (state === 2) {
		return "运行中";
	} else if (state === 3) {
		return "暂停";
	} else if (state === 4) {
		return "中止";
	} else if (state === 5) {
		return <span><Iconfont type='icon-chenggong' style={{marginRight: 4}} />传输成功</span>;
	} else if (state === 6) {
		return <span><Iconfont type='icon-shibai' style={{marginRight: 4}} />失败</span>;
	}
}

export function formatSourceData(data: { sourceType?: string; sourceName?: string; isSourceDeleted?: boolean; sourceDatabase?: string; sourceSchema?: string }): BasicObjectLabel {
	return {
		sourceType: data.sourceType,
		connectionName: data.sourceName,
		isConnectionDeleted: data.isSourceDeleted,
		database: data.sourceDatabase,
		schema: data.sourceSchema,
	};
}

export function formatTargetData(data: { targetType?: string; targetName?: string; isTargetDeleted?: boolean; targetDatabase?: string; targetSchema?: string }): BasicObjectLabel {
	return {
		sourceType: data.targetType,
		connectionName: data.targetName,
		isConnectionDeleted: data.isTargetDeleted,
		database: data.targetDatabase,
		schema: data.targetSchema,
	};
}

/**
 * 处理表单中的对象名称
 * @example 0708.SCHEMA
 */
export function renderObjectLabel(data: BasicObjectLabel) {
  const { sourceType, connectionName, isConnectionDeleted, database, schema } = data;

  return (
    <>
      {!!sourceType && (
        <Space>
					<Tooltip title={sourceType}>
						<Iconfont type={`icon-connection-${sourceType}`} style={{marginRight: 4}} />
					</Tooltip>
        </Space>
      )}
      {!!connectionName && (
        <Typography.Text
          style={{ color: "green" }}
          delete={!!isConnectionDeleted}
        >{`${connectionName}.`}</Typography.Text>
      )}
      {!!database && <Typography.Text style={{ color: "gray" }}>{`${database}.`}</Typography.Text>}
      {!!schema && <Typography.Text>{`${schema}`}</Typography.Text>}
    </>
  );
}


/**
 * 处理表单中的子任务源表名
 * @example 0708.SCHEMA
 */
export function renderChildSource(data: BasicObjectChildLabel) {
	const { sourceSchema, sourceTable} = data;
	const text = `${sourceSchema ? sourceSchema + "." : ""}${sourceTable ? sourceTable : ""}`;

	return <span>{text}</span>;
}

/**
 * 处理表单中的子任务目标表名
 * @example 0708.SCHEMA
 */
export function renderChildTarget(data: BasicObjectChildLabel) {
	const { targetSchema, targetTable} = data;
	const text = `${targetSchema ? targetSchema + "." : ""}${targetTable ? targetTable : ""}`;

	return <span>{text}</span>;
}

