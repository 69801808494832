import React, { useEffect, useState, useMemo } from 'react'
import { Chart } from '@antv/g2'
import { DatePicker, Radio, Spin } from 'antd'
import { getAuditUserAmountUnit } from 'src/api'
import { useRequest } from 'src/hook'
import chartStyles from './chart.module.scss'
import { EmptyChart } from './EmptyChart'
import { getUnit, getUnitBase, getUnixTimeRange, getCustomUnitBase } from './util'
import DataSet from '@antv/data-set'
import dayjs, { Dayjs } from 'dayjs'

export const UserCountChart = () => {
  const [timeRange, setTimeRange] = useState<1 | 7 | 30 | any>(7)
  const [rangePickerTimes, setRangePickerTimes] = useState<string[] | null>([
    dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    dayjs().endOf('d').format('YYYY-MM-DD')
  ]
  )

  const { data = [], loading } = useRequest(
    () => {
      let defaultParams = {
        unit: getUnit(timeRange),
        ...getUnixTimeRange(timeRange),
      }
      if (rangePickerTimes) {
        const startTimes =  Number(dayjs(rangePickerTimes[0]).startOf('d').format('x'));
        const endTimes = Number(dayjs(rangePickerTimes[1]).endOf('d').format('x'));
        defaultParams = {
          ...defaultParams,
          beginTimeMs:startTimes,
          endTimeMs: endTimes,
        }
      }
      return getAuditUserAmountUnit(defaultParams)
    },
    {
      formatResult: (data) => {
        let baseUnit = getUnitBase(timeRange)

        if (rangePickerTimes) {
          baseUnit = getCustomUnitBase(rangePickerTimes[0], rangePickerTimes[1])
        }
        const rows = new DataSet.DataView()
          .source(
            data
              .map(({ ...rest }) => ({ ...rest, notUnitBase: true }))
              .concat(baseUnit),
          )
          // 填充刻度，例如值为 2021-02-8 00:00:00，unit 为 DAY，需要填充之前 timeRange 天的 unit string
          .transform({
            // 补全行
            type: 'fill-rows',
            groupBy: ['notUnitBase'],
            orderBy: ['unit'],
            fillBy: 'order',
          })
          .transform({
            // 补全列
            type: 'impute',
            field: 'amount', // 待补全字段
            groupBy: ['notUnitBase'], // 分组字段集（传空则不分组）
            method: 'value', // 补全为常量
            value: 0,
          })
          .rows.filter(({ notUnitBase }) => notUnitBase)
          .map(({ unit = '', ...rest }) => ({
            unit: timeRange === 1 ? new Date(unit).getHours() : unit,
            ...rest,
          }))
        return rows
      },
      refreshDeps: [timeRange, rangePickerTimes],
    },
  )

  const renderUserCountChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
    })

    chart.data(data)
    chart.scale({
      unit: {
        range: [0.05, 0.9],
        formatter: (v) =>  (v < 25 ? v + ' 时' : dayjs(v).format('YYYY-MM-DD')),
      },
      amount: {
        alias: '使用人数',
        nice: true,
      },
    })

    chart.tooltip({
      showMarkers: false,
    })
    chart.interaction('active-region')
    chart.interval().position('unit*amount').color('#989ffb')
    chart.render()
    return chart
  }

  useEffect(() => {
    if (!data || !data[0]) return
    const chart = renderUserCountChart('user-count', data)
    return () => chart.destroy()
  }, [data])

  const rangeValue = useMemo(() => {
    if (rangePickerTimes === null) {
      return null
    } else {
      const range = rangePickerTimes.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [rangePickerTimes])

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.userCountWrapper}>
        <div className={chartStyles.toolbar}>
          <Radio.Group
            buttonStyle="solid"
            size="small"
            value={timeRange}
            onChange={(e) => {
              setTimeRange(e.target.value);
              if (e.target.value === 1) {
                setRangePickerTimes([dayjs().format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD')])
              }else if (e.target.value === 7) {
                setRangePickerTimes([dayjs().subtract(7,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD')])
              }else {
                setRangePickerTimes([dayjs().subtract(30,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD')])
              }
            }}
          >
            <Radio.Button value={1}>今日</Radio.Button>
            <Radio.Button value={7}>7 天</Radio.Button>
            <Radio.Button value={30}>30 天</Radio.Button>
          </Radio.Group>
          <div>
            <DatePicker.RangePicker
              style={{ marginLeft: 10 }}
              //@ts-ignore
              value={rangeValue}
              onChange={(dates, dateStrings) => {
                if (!dateStrings[0]) {
                  setRangePickerTimes(null)
                  setTimeRange(7)
                } else {
                  const diffDays = dayjs(dateStrings[1]).diff(
                    dateStrings[0],
                    'day',
                  )
                  //当日
                  if (diffDays === 0 &&  dayjs().isSame(dayjs(dateStrings[1]),'day')) {
                    setTimeRange(1)
                  }else {
                    setTimeRange(diffDays)
                  }
                  setRangePickerTimes(dateStrings)
                }
              }}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
              />
          </div>
        </div>
        <div id="user-count" className={chartStyles.userCount}>
          {!data[0] && !loading && <EmptyChart></EmptyChart>}
        </div>
      </div>
    </Spin>
  )
}
