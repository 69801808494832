//表信息
export const DATABASE_TABLE_INFO_FIELD_MAPPING = {
  StarRocks: ['engine', 'model'],
};
//表结构所有字段映射
export const DESIGN_TABS_FILED_MAPPING: { [k: string]: string } = {
  columns: '字段',
  indexes: '索引',
  constraints: '约束',
  fkConstraints: '外键',
  triggers: '触发器',
  partition: '分区',
  temporaryPartition: '临时分区',
};
//设计表 可编辑类型
export const ENABLE_DESIGN_FIELDS = [
  'columns',
  'indexes',
  'constraints',
  'fkConstraints',
  'triggers',
];
