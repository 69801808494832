import React from "react";
import { Modal, Input } from "antd";
import styles from './index.module.scss';
import classNames from "classnames";

const BtnWithConfirmModal = ({
  title,
  btnText,
  onClick,
  hideReason = false
}: {
  title: React.ReactNode;
  btnText: string;
  hideReason?: boolean;
  onClick: (reason?: string) => void;
}) => {
  const handleModalConfirm = () => {

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => {
          onClick(val);
          closeFn();
        },
      });
    };

    const { update } = Modal.confirm({
      title: title || '确认？',
      centered: true,
      content: !hideReason && (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      onOk: () => {
       onClick()
      },
    });
  };

  return <span className={classNames(styles.normalBtn)} onClick={() => handleModalConfirm()}>{btnText || "删除"}</span>;
};

export default BtnWithConfirmModal;