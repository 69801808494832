import React, { FC, useEffect, useState } from 'react'
import { Form, message } from 'antd'
import moment from 'moment'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import Flow from 'src/components/Process'
import {
  ApplyItem,
  getDataChangeDetail,
  approveApplication,
  applicationAmend
} from 'src/api'
import { useRequest } from 'src/hook'
import { DATA_REQUEST_APPLY_TYPES } from 'src/constants'
import { SimpleBreadcrumbs, RcSegmented } from 'src/components'
import {
  DataUpdateForm,
  SQLCheckForm,
  ReviewProcess,
  SubmitApplicationForm,
  ExecuteChange,
  SimuleEnvironmentValidation,
} from "../../components";
import { FormContext } from '../../ApplyFormContext'
import styles from './index.module.scss'

interface ApplyProps {}

const applyFlow = [
  {
    title: '申请数据变更',
    iconText: '建',
  },
  {
    title: '连接管理员',
    iconText: '审',
  },
  {
    title: '申请完成',
    iconText: '完',
  },
]

export const Apply: FC<ApplyProps> = ({}) => {
 
  const history = useHistory()
  const [form] = Form.useForm()
  //@ts-ignore
  const { state: { flowTaskId = "", applyUserId = '' },
  } = useLocation();
  const { id } = useParams<{ id?: string;editId?: string }>()
  const isViewDetail = !!id ;

  //审批主流程 通过状态控制吧
  const [currentApprovalStep, setCurrentApprovalStep] = useState(0)
  //当前申请流程
  const [currentStep, setCurrentStep] = useState(0)
  //存储当前数据
  const [currentContextValue, setCurrentContextValue] = useState<
    ApplyItem | any
  >()

  //申请详情
  const { data:applyDetail, run: runGetDataChangeDetail, refresh } = useRequest(getDataChangeDetail, {
    manual: true,
    onSuccess: (data:ApplyItem) => {
      if (data) {
        form.setFieldsValue({
          ...data,
          executeTime: data?.executeTime ?moment(data.executeTime) : null
        })
      }
   
      setCurrentContextValue({...data, executeTime: data?.executeTime ?moment(data.executeTime) : null})
      if (data?.executeStatus === '审批中') {
        setCurrentApprovalStep(1)
        setCurrentStep(3)
      }else {
        setCurrentApprovalStep(2)
        setCurrentStep(2)
      }
    },
  })

  // 通过驳回审批
  const { run: runExecuteFlowTask } = useRequest(approveApplication, {
    manual: true,
  })

    //修改执行时间
    const { run: runApplicationAmend } = useRequest(applicationAmend, {
      manual: true,
    })


  useEffect(() => {

    if (isViewDetail ) {
      runGetDataChangeDetail(id)
    }else {
      form.setFieldsValue({type: 1})
    }
  }, [isViewDetail,id])

  const onExecuteFlowTask = async (type: 'pass' | 'reject',params:any) => {
    let defaultFlowParams = params;

    if(type === 'pass') {
     
      defaultFlowParams = {
          approvedFlag: true,
          flowId: currentContextValue?.flowId,
          approvedTime:  '',
          taskId: flowTaskId || '',
        } 

        if (params?.approveUserComment || params?.executeTimeType !== applyDetail?.executeTimeType || (params?.executeTime && applyDetail?.executeTime &&(params?.executeTime !== applyDetail?.executeTime))) {

          await runApplicationAmend({
            id: params?.id,
            approveUserComment: params?.approveUserComment,
            executeTimeType: params?.executeTimeType || applyDetail?.executeTimeType,
            executeTime: params?.executeTime || ''
          })
          runExecuteFlowTask(defaultFlowParams).then(() => {
            message.success( '通过成功' )
            refresh()
          })
        }else {
          runExecuteFlowTask(defaultFlowParams).then(() => {
            message.success( '通过成功' )
            refresh()
          })
        }

    }else {
      if (params?.approveUserComment || params?.executeTimeType !== applyDetail?.executeTimeType || (params?.executeTime && applyDetail?.executeTime &&(params?.executeTime !== applyDetail?.executeTime))) {
        await runApplicationAmend({
          id: params?.id,
          approveUserComment: params?.approveUserComment,
          executeTimeType: params?.executeTimeType || applyDetail?.executeTimeType,
          executeTime: params?.executeTime || ''
        })
      }
    
       const res  = await runExecuteFlowTask({
        approvedFlag: false,
        flowId: defaultFlowParams?.flowId,
        refuseReasonVo:{},
        taskId: flowTaskId || ''
      })
      if (res) {
        message.success( '驳回成功')
        history.go(-1)
      }
    }

  }


  const APPLY_TYPES_OPTIONS = (() => {
    return Object.keys(DATA_REQUEST_APPLY_TYPES).map((key) => ({
      //@ts-ignore
      label: DATA_REQUEST_APPLY_TYPES[key],
      value: key,
      //@ts-ignore
      disabled: Number(key) === 2,
    }))
  })()

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '我的审批',
      href: '/data_change_mine_approve',
      // CQ-3664 消息通知--数据变更类型的消息通知点击查看详情，详情页面包屑跳转问题
      // onclick: () => history.go(-1)
    },
    {
      separator: '/',
      title:  '工单详情',
    },
  ]

  const applicantStepItems = [
    {
      description: (
        <DataUpdateForm
          form={form}
          itemStepMark={0}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType={"MINE_APPROVE"}
        />
      ),
    },
    {
      hidden: isViewDetail && currentContextValue?.executeStatus !== "审批中",
      description: (
        <SQLCheckForm
          itemStepMark={1}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
        />
      ),
    },
    // 模拟环境验证
    {
      hidden: isViewDetail && (currentContextValue?.executeStatus !== "审批中" || currentContextValue?.type === 1),
      description: (
        <SimuleEnvironmentValidation
          form={form}
          itemStepMark={2}
          currentStep={currentStep}
          applyUserId={applyUserId}
          currentRouteType={"MINE_APPROVE"}
        />
      ),
    },
    {
      hidden: isViewDetail && currentContextValue?.executeStatus !== "审批中",
      description: (
        <SubmitApplicationForm
          form={form}
          itemStepMark={3}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType={"MINE_APPROVE"}
          onExecuteFlowTask={(type: "pass" | "reject", params: any) =>
            onExecuteFlowTask(type, params)
          }
        />
      ),
    },
    //查看
    {
      hidden: isViewDetail && currentContextValue?.executeStatus === "审批中",
      description: (
        <ReviewProcess
          itemStepMark={1}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          flowTaskId={flowTaskId}
        />
      ),
    },
    {
      hidden: isViewDetail && currentContextValue?.executeStatus === "审批中",
      description: (
        <ExecuteChange
          itemStepMark={2}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType={"MINE_APPROVE"}
        />
      ),
    },
  ];

  return (
    <FormContext.Provider
      value={{
        form,
        currentContextValue,
        setCurrentContextValue,
        setCurrentStep,
      }}
    >
      <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
      <div className={styles['application-detail']}>
        <Form form={form} name='basic'>
          <Form.Item name="type" initialValue={1}>
            {!isViewDetail && (
              <RcSegmented
                options={APPLY_TYPES_OPTIONS}
                value={currentContextValue?.type || 1}
              />
            )}
          </Form.Item>
        </Form>
        <Flow
          items={applyFlow}
          current={currentApprovalStep}
          className={styles['approval-step']}
        />
        <div className={styles.applyContent}>
          <Flow
            items={applicantStepItems}
            current={currentStep}
            direction="vertical"
          />
        </div>
      </div>
    </FormContext.Provider>
  )
}

export default Apply
