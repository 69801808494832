import React, { useEffect, useRef } from 'react'
import { Form, Input, message } from 'antd'
import { UIModal } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { saveFavorite } from 'src/api'

export const FavoriteEditModal = () => {
  const dispatch = useDispatch()
  const { ModalFavoriteAdd } = useSelector((state) => state.modal)
  const { lastSavedFavorite } = useSelector((state) => state.queryTabs)
  const { id, statement } = lastSavedFavorite || {}
  const [favoriteAddForm] = Form.useForm()

  const { run: trySaveFavorite, loading: loadingSubmit } = useRequest(
    saveFavorite,
    {
      manual: true,
      onSuccess: () => {
        message.success('保存成功')
        handleHideModal()
      },
    },
  )

  const handleSubmitAddApp = () => {
    favoriteAddForm.validateFields().then((values) => {
      const { statementName, statement } = values
      if (!statementName) {
        // todo: 前后端统一逻辑，空值时也保存成功
        message.success('保存成功')
        handleHideModal()
        return
      }
      trySaveFavorite({ id, statementName, statement })
    })
  }

  const handleHideModal = () => {
    dispatch(hideModal('ModalFavoriteAdd'))
  }

  const focusRef = useRef<Input>(null)
  useEffect(() => {
    if (!ModalFavoriteAdd) return
    focusRef.current?.focus()
  }, [ModalFavoriteAdd])

  return (
    <UIModal
      title="收藏语句"
      visible={ModalFavoriteAdd}
      width={640}
      onCancel={handleHideModal}
      onOk={() => favoriteAddForm.submit()}
      confirmLoading={loadingSubmit}
      afterClose={() => favoriteAddForm.resetFields()}
    >
      <Form
        form={favoriteAddForm}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 17 }}
        onFinish={handleSubmitAddApp}
      >
        <Form.Item label="别名" name="statementName">
          <Input ref={focusRef} placeholder="设置别名方便记忆和搜索"></Input>
        </Form.Item>
        <Form.Item
          label="语句"
          name="statement"
          initialValue={statement}
          rules={[{ required: true, message: '请输入语句' }]}
        >
          <Input.TextArea
            minLength={5}
            autoSize={{ maxRows: 20 }}
          ></Input.TextArea>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
