import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Alert, Form, Input, Select, Switch } from 'antd'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { FormLayout } from 'src/constants'
import { DumpTypeMap, DumpTypes, getTaskResult, makeSdtNodeExport } from 'src/api'
import { useDownloadNotificationRenderer } from 'src/features/websocket/useDownloadNotification'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'
import { ExportApplyModal } from '../../resultTabs/resultContentGrid/ExportApplyModal'

interface AddResultExportProps {
  sdtNodeExportParams: any
  [p: string]: any
}


const ExportSelectOptions = DumpTypes.map((format) => ({
  value: format,
  label: DumpTypeMap.get(format),
}))

export const AddSdtNodeExportModal = ({
  sdtNodeExportParams,
  selectedNode
}: AddResultExportProps) => {
  const { connectionId, connectionType, nodeName, nodeType, nodePath, nodePathWithType } =
    sdtNodeExportParams
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const { AddSdtNodeExport } = useSelector((state) => state.modal)
  const { sdtBasicSettingData } = useSelector((state) => state.queryTabs)
  const dispatch = useDispatch()
  const [renderNotification] = useDownloadNotificationRenderer()
  const [addResultExportForm] = Form.useForm()

  const [visibleExportApply, setVisibleExportApply] = useState(false)

  const exportDataNumInitValue = 500  // 导出数据行数 初始值

  const { fetchTaskResult } = useHandleExternalInfo(getTaskResult, (data: any) => {
    renderNotification(data)
  })

  const validatorRowNumber = (_: any, v: number) => {
    if (!v) return Promise.reject('请填写导出行数')
    if (v < 1 || v > 10000000) return Promise.reject('行数限制为[10000000]')
    return Promise.resolve()
  }


  const handleSubmitAddResultExport = () => {
    const { maskedPathMap } = sdtNodeExportParams
    const { settingType, count } = sdtBasicSettingData
    addResultExportForm.validateFields().then((values) => {
      const { dumpType, fileName, exportDataNum, isExportClearText } = values
      //  导出方式为 明文导出+表有锁+是否导出明文switch为true === 走审批
      if ((settingType ===  'ALL_EXPORT_NEED_APPROVAL')
        || ((settingType === 'CLEAR_TEXT_EXPORT_NEED_APPROVAL' 
        && (maskedPathMap && Object.keys(maskedPathMap)?.length > 0 && nodeType === 'table' && isExportClearText )))
        ||  (settingType === 'EXPORT_ROW_LIMIT' && count < exportDataNum)
      ){
        setVisibleExportApply(true)
        dispatch(hideModal('AddSdtNodeExport'))
        return
      }
      setConfirmLoading(true)
      makeSdtNodeExport({
        dumpType,
        fileName,
        exportDataNum,
        connectionId,
        connectionType,
        nodeName,
        nodeType,
        nodePath,
        nodePathWithType,
      })
        .then((data) => {
          fetchTaskResult(data)
          dispatch(hideModal('AddSdtNodeExport'))
          // 关闭后需要回到初始状态
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  return (
    <>
    <UIModal
      title="转储 SQL 文件"
      visible={AddSdtNodeExport}
      onCancel={() => {
        dispatch(hideModal('AddSdtNodeExport'))
      }}
      onOk={handleSubmitAddResultExport}
      afterClose={() => addResultExportForm.resetFields()}
      width={480}
      confirmLoading={confirmLoading}
    >
      <Form form={addResultExportForm} {...FormLayout}>
        <Form.Item
          name="nodeName_reserved_word"
          label="节点名称"
          initialValue={nodeName}
        >
          <Input disabled></Input>
        </Form.Item>
        <Form.Item
          name="fileName"
          label="文件名"
          rules={[{ required: true, message: '请填写文件名' }]}
        >
          <Input></Input>
        </Form.Item>
        {['database', 'oracleUser', 'schema'].includes(nodeType) || 'Hive' === connectionType || 'Inceptor' === connectionType ? (
          <Form.Item
            name="dumpType"
            label="转储类型"
            initialValue={'Inceptor' === connectionType ? DumpTypes[1] : DumpTypes && DumpTypes[1]}
          >
            <Select disabled options={ExportSelectOptions}></Select>
          </Form.Item>
        ) : (
          <>
            <Form.Item
              name="dumpType"
              label="转储类型"
              rules={[{ required: true, message: '请选择转储类型' }]}
              initialValue={DumpTypes && DumpTypes[0]}
            >
              <Select
                options={ExportSelectOptions}
              />
            </Form.Item>
        
            <Form.Item noStyle dependencies={['dumpType']}>
              {({getFieldValue}) => {
                const dumpTypeValue = getFieldValue('dumpType')
                if (dumpTypeValue === 'DUMPDATAANDSTRUCTURE') {
                  return   <Form.Item
                    name="exportDataNum"
                    label="导出数据行数"
                    initialValue={exportDataNumInitValue}
                    required
                    rules={[{ validator: validatorRowNumber }]}
                  >
                    <Input style={{ width: '100%' }}></Input>
                  </Form.Item>
                }
              }}
            </Form.Item>
            {
              sdtBasicSettingData?.settingType === 'CLEAR_TEXT_EXPORT_NEED_APPROVAL' && 
              <Form.Item
                name="isExportClearText"
                label="是否导出明文"
              >
                <Switch checkedChildren="是" unCheckedChildren="否" />
              </Form.Item>
            }
          </>
        )}
        <Form.Item 
          noStyle 
          dependencies={['dumpType']}
          hidden={sdtBasicSettingData?.settingType !== 'EXPORT_ROW_LIMIT'}
        >
           {({getFieldValue}) => {
                const dumpTypeValue = getFieldValue('dumpType')
                if (dumpTypeValue === 'DUMPDATAANDSTRUCTURE') {
                  return   <Alert
                  message={`导出行数限制：${sdtBasicSettingData?.count}行，若导出行数大于限制值，需要走审批`}
                  type="warning"
                  showIcon
                  style={{ fontSize: 12 }}
                />
                }
              }}
            </Form.Item>
      </Form>
    </UIModal>
    {
      visibleExportApply &&
      <ExportApplyModal
        data={selectedNode}
        exportForm={addResultExportForm}
        visible={visibleExportApply}
        setVisible={setVisibleExportApply}
        object={"sdtRightClick"}
      />
    }
    </>
  )
}
