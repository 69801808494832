import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Button, Form, Input, message, Tabs } from 'antd'
import { UIModal } from 'src/components'
import { hideModal, showModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  otpCheckAccount,
  untieUserOTPKey,
  sendEmailVerificationCode,
  examineCode,
  updateUserEmail,
  verifyUserOTPKeyCode,
  sendPhoneVerificationCode,
  checkUserPhoneCode,
  updateUserPhone,
  sendPhoneCode,
  getOtpStatus,
  setClose
} from 'src/api'
import styles from './index.module.scss'
import { useCounter, useInterval } from 'react-use'
import { setUserPhone } from '../personalCenterSlice'

const FormLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
}
export const ModalVerification: React.FC<{ 
  markFlag?: any, 
  refresh: () => Promise<void>, 
  refreshEmail: () => Promise<void>,
  refreshLoginSetting: ()=> Promise<void>,
  setLoginSettingFuncStr: (s: string) => void,
}> = ({
    markFlag,
    refresh,
    refreshEmail,
    refreshLoginSetting,
    setLoginSettingFuncStr
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const visible = useSelector(
    (state) => state.modal.VerificationModal,
  )
  const { isBindEmail, loginType, userEmail, smsBindFlag, isBindPhone, userPhone, isOpenOtp } = useSelector(
    (state) => state.personalCenter,
  )
  const { userId } = useSelector((state) => state.login.userInfo)
  const [tabsKey, setTabesKey] = useState('accountTabs')

  const [count, { dec, reset, set }] = useCounter(60, 60, 0)
  useEffect(() => {
    set(0)
  }, [set])

  const inputRef = useRef<Input>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useInterval(() => dec(1), 1000)


  /** 账号密码验证  */
  const { run: checkAccountRun } = useRequest(otpCheckAccount, {
    manual: true,
    onSuccess() {
      if (markFlag === 'email' && !isBindEmail) {
        updateUserEmailRun({ email: '' })
      } else if (markFlag === 'email' && isBindEmail) {
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('EmailEditModal'))
      } else if (markFlag === 'phone' ) { // 手机号码
        dispatch(hideModal('VerificationModal'))  // hide 验证身份
        dispatch(showModal('PhoneEditModal')) // show 手机 绑定/修改
      } else if (markFlag === 'otpUnBind') {
        untieUserOTPKeyRun()
      } else if (markFlag === 'bindWwChat') {
        // 绑定企业微信
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('WwChatBindModal'))
      } else if (markFlag === 'unBindWwChat') {
        // 解绑企业微信
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('WwChatUnBindModal'))
      } else {
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('OtpBindingtModal'))
      }
    }
  })

  /** 发送邮箱验证码 */
  const { run: sendEmailCode } = useRequest(sendEmailVerificationCode, {
    manual: true,
    onSuccess(data) {
      reset();
      message.success(data?.message)
    }
  })

  /** 检查邮箱验证码 */
  const { run: examineCodeRun } = useRequest(examineCode, {
    manual: true,
    onSuccess(data) {
      if (markFlag === 'email' && !isBindEmail) {
        updateUserEmailRun({ email: '' })
      } else if (markFlag === 'phone' ) { 
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('PhoneEditModal'))
        // Test
        // dispatch(setUserPhone("17689727255"))
      } else if (markFlag === 'bindWwChat') {
        // 绑定企业微信
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('WwChatBindModal'))
      } else if (markFlag === 'unBindWwChat') {
        // 解绑企业微信
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('WwChatUnBindModal'))
      } else {
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('OtpBindingtModal'))
      }
    }
  })

  /** 更新邮箱 */
  const { run: updateUserEmailRun } = useRequest(updateUserEmail, {
    manual: true,
    onSuccess(data) {
      dispatch(hideModal('VerificationModal'))
      refreshEmail()
    }
  })


  /**  发送验证码 */
  const { run: sendPhoneCodeRun } = useRequest(sendPhoneCode, {
    manual: true,
    onSuccess(res) {
      reset()
      message.success(`验证码已发送`)
    }
  })

  /** 检查手机号验证码 */
  const { run: checkUserPhoneCodeRun } = useRequest(checkUserPhoneCode, {
    manual: true,
    onSuccess(data) {
      
      if (markFlag === 'phone') {
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('PhoneEditModal')) 
      } else if (markFlag === 'bindWwChat') {
        // 绑定企业微信
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('WwChatBindModal'))
      } else if (markFlag === 'unBindWwChat') {
        // 解绑企业微信
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('WwChatUnBindModal'))
      }else {
        dispatch(hideModal('VerificationModal'))
        dispatch(showModal('OtpBindingtModal'))
      }
    }
  })

  /** otp 校验 */
  const { run: verifyUserOTPKeyCodeRun } = useRequest(verifyUserOTPKeyCode, {
    manual: true,
    onSuccess(data) {
      untieUserOTPKeyRun()
    }
  })
  /** 解绑 */
  const { run: untieUserOTPKeyRun } = useRequest(untieUserOTPKey, {
    manual: true,
    onSuccess(data) {
      message.success('解绑成功')
      setLoginSettingFuncStr('')  // 解绑, 已关闭认证
      
      dispatch(hideModal('VerificationModal'))
      refresh()
    }
  })


  const AccountPassword = () => {
    return (
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="账号"
          name="userId"
        >
          <span>{userId}</span>
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password autoFocus />
        </Form.Item>
      </Form>
    )
  }
  const EmailElement = () => {
    return (
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="邮箱"
          name="email"
        >
          <span>{userEmail}</span>
        </Form.Item>
        <Form.Item
          label="验证码"

        >

          <div className={styles.disflex}>
            <Form.Item
              name="authCode"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input ref={inputRef} autoFocus/>
            </Form.Item>
            {
              // (countDown == 60 || countDown == 0) ?
              <Button
                disabled={!!count}
                style={{marginLeft: 10}}
                onClick={() => {
                  sendEmailCode?.()
                }}>
                {count ? `${count}s` : '发送验证码'}
              </Button>
              // <div>{countDown + 's'}</div>
            }
          </div>
        </Form.Item>
        <div style={{ marginLeft: '35px' }}>
          <p>1. 接收验证码的邮箱号为您账号中绑定的邮箱号</p>
          <p>2. 发送验证码后，您可以在邮箱中获取（1分钟内未收到，建议在垃圾邮件中查看）</p>
        </div>
      </Form>
    )
  }

  const PhoneElement = () => {
    return (
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="手机号码"
          name="phone"
        >
          <span>{userPhone && userPhone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</span>
        </Form.Item>
        <Form.Item
          label="验证码"
        >
          <div className={styles.disflex}>
            <Form.Item
              name="authCode"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input ref={inputRef} autoFocus />
            </Form.Item>
            {
              <Button
                disabled={!!count}
                type='primary'
                className={styles.ml10}
                onClick={() => {
              
                  sendPhoneCodeRun?.(userPhone)
                }}>
                {count ? `${count}s` : '获取验证码'}
              </Button>
            }
          </div>
        </Form.Item>
        <div style={{ marginLeft: '35px' }}>
          <p>1. 接收验证码的手机号为您账号中绑定的安全手机号</p>
          <p>2. 发送验证码后，您可以在手机短信中获取（1分钟内未收到，建议在垃圾短信中查看）</p>
        </div>
      </Form>
    )
  }

  const OTPUnBindElement = () => {
    return (
      <div className={styles.pt20}>
        <p style={{ marginLeft: '7%' }}>请在 Authy 或 Google 身份验证器 手机应用获取获取动态OTP码</p>
        <Form form={form} {...FormLayout}>
          <Form.Item
            label="OTP码"
            name="otpCode"
            rules={[{ required: true, message: 'OTP码' }]}
          >
            <Input autoFocus />
          </Form.Item>
        </Form>
      </div>
    )
  }

  useEffect(() => {
    if (!userId) return
    if (userId && visible) {
      form.setFields([
        { name: 'userId', value: userId },
        { name: 'email', value: userEmail }
      ])
      if (markFlag === 'otpUnBind') {
        setTabesKey('otpUnBindTabs')
      } else if (loginType === 'cq') {
        setTabesKey('accountTabs')
      }else if (markFlag === 'phone') {
        setTabesKey('phoneTabs')
      }else{
        setTabesKey('emailTabs')
      }
    } else {
      form.resetFields()
    }
  }, [userId, visible])

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (tabsKey === 'accountTabs') {
        checkAccountRun(values)
      } else if (tabsKey === 'otpUnBindTabs') {
        userId && verifyUserOTPKeyCodeRun({ userId, otpPassword: values?.otpCode })
      } else if (tabsKey === 'emailTabs') {
        userId && examineCodeRun({ code: values?.authCode, userId })
      } else if (tabsKey === 'phoneTabs') { // to do 
        console.log("立即验证", values, userId)
        // Test
        // checkUserPhoneCodeRun()
        userId && checkUserPhoneCodeRun({ phone: userPhone, code: values?.authCode })
      }
      // if (markFlag === 'email' && isBindEmail) {  // 绑定邮箱
      //   dispatch(hideModal('VerificationModal'))
      //   dispatch(showModal('EmailEditModal'))
      // } else if (markFlag === 'email' && !isBindEmail) {
      //   if (tabsKey === 'accountTabs') {
      //     checkAccountRun(values)
      //   } else {
      //     userId && examineCodeRun({ code: values?.authCode, userId })
      //   }
      // } else {
      //   if (tabsKey === 'accountTabs') {
      //     checkAccountRun(values)
      //   } else if (tabsKey === 'otpUnBindTabs') {
      //     userId && verifyUserOTPKeyCodeRun({ userId, otpPassword: values?.otpCode })
      //   } else {
      //     userId && examineCodeRun({ code: values?.authCode, userId })
      //   }
      // }
    })
  }

  return (
    <UIModal
      title={
        (markFlag === 'otpUnBind' && 'OTP解绑') ||
        (markFlag === 'email' && !isBindEmail && '邮箱解绑')
      }
      visible={visible}
      onOk={onSubmit}
      okText={
        (markFlag === 'otpUnBind' || (markFlag === 'email' && !isBindEmail)) ? '立即解绑' : '立即验证'
      }
      onCancel={() => {
        dispatch(hideModal('VerificationModal'))
        form.resetFields()
      }}
    >
      <div>
        {
          markFlag !== 'otpUnBind' && <div style={{ background: '#F7F9FC', textAlign: 'center', padding: '10px 0' }}>
            <h3>验证身份</h3>
            <p>为确保账号 {userId}是您本人操作，请任意选择一种方式验证身份</p>
          </div>
        }
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <div style={{ flex: 2 }}>
            <Tabs
              className={styles.verificationTab}
              tabPosition={'left'}
              // type="card"
              activeKey={tabsKey}
              onChange={(e) => {
                setTabesKey(e)
              }}
            >
              {
                markFlag === 'otpUnBind' && <Tabs.TabPane tab='OTP验证' key="otpUnBindTabs" />
              }
              {
                loginType === 'cq' && <Tabs.TabPane tab='账号密码验证' key="accountTabs" />
              }
              {
                (markFlag !== 'otpUnBind' && isBindPhone) && <Tabs.TabPane tab='手机号验证' key="phoneTabs" />  // 已绑定手机号-show, 否则-hide
              }
              {
                (markFlag !== 'otpUnBind' && userEmail) && <Tabs.TabPane tab='邮箱验证' key="emailTabs" />
              }
            </Tabs>
          </div>
          <div style={{ flex: 8, background: '#F7F9FC', padding: '10px 0' }}>
            {
              tabsKey === 'otpUnBindTabs' && <OTPUnBindElement />
            }
            {
              tabsKey === 'emailTabs' && <EmailElement />
            }
            {
              tabsKey === 'phoneTabs' && <PhoneElement />
            }
            {
              tabsKey === 'accountTabs' && <AccountPassword />
            }
          </div>
        </div>
      </div>
    </UIModal>
  )
}
