import React from "react";
import { DataNode } from "antd/lib/tree";
import { Iconfont } from "src/components";
import styles from '../index.module.scss';
import classnames from 'classnames';

// 构造树结构
export const generateTree = (data: any[]) => {
	const allId = data?.map(i => i.id)?.filter((i, index, arr) => arr.indexOf(i) === index);
	const allParentId = data?.map(i => i.parentId)?.filter((i, index, arr) => arr.indexOf(i) === index);

	const validateParentId = (item: any) => {
		return !!data?.filter((i: any) => {
			return i?.parentId === item?.id && item?.nodeType !== i?.nodeType;
		})?.length;
	};

	const filterData = data.filter((item, _, arr) => {
		// 自身是父级（且id和parentId不能重复）
		if (allParentId.includes(item.id) && validateParentId(item)) {
			item.children = arr.filter(i => i.parentId === item.id);
		}
		// 没有父级
		if (!allId.includes(item.parentId) || !item.parentId) {
			return true;
		}
		return false;
	});

	const formatTree = (data: any[], connectionType?: string): any[] =>
		data.map((item: any) => {
			const { nodeName, nodeType, connection, id } = item;
			const icon = `${nodeType === "datasource" ? `icon-connection-${nodeName}` : nodeType === "group" ? "icon-shujukuwenjianjia" : nodeType === "connection" ? `icon-${connection?.connectionType}` : `icon-${nodeType}`} `;
			item.key = item.nodePath; // 构造唯一id
			item.title = item.nodeName;
			item.connectionType = connectionType || id
			item.icon = <Iconfont 
                    type={icon} 
                    className={classnames(styles.mr4, styles.color008dff, {
                      [styles.colorf00]: connection?.testModel === 1 && nodeType === "connection",
                      [styles.colorgreen]: !!connection?.testModel && connection?.testModel !== 1 && nodeType === "connection",
                    })}
                  ></Iconfont>;
			if (item.children) {
				item.children = formatTree(item.children, item.connectionType);
			}
			item.props = { ...item };
			return item;
		});
	return formatTree(filterData);
};

export const getConnExpandNodeNeedPathKeys = (path: string,needSplice?: boolean) => {
  let result = [];
  const parts = path?.split('/') || [];
  let current = '';
  for (let i = 1; i < parts.length; i++) {
    current += '/' + parts[i];
    result.push(current);
  }
  if (needSplice) {
    result.splice(0, 1); // 删除第一个空字符串
  }
 
  return result;
}

export const matchKeyword = (target = '', substring = '') => {
  if (!target) return false
  return target.toLowerCase().indexOf(substring.toLowerCase()) > -1
}

	/**
	 * pass a key to find the relative node
	 */
  export function findNodeByKey(nodes: DataNode[], key: string): DataNode | undefined {
		if (!key || !nodes || !nodes.length) return;

		for (const item of nodes) {
			// find target node
			//@ts-ignore
			if (item?.props?.nodePath === key) {
				return item;
			}

			if (item?.children && item?.children?.length > 0) {
				const res = findNodeByKey(item.children, key);
				if (res) return res;
			}
		}
		return;
	}