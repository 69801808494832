/**
 * 自定义时间组件
 */
import React, { useEffect, useState } from 'react'
import { Button, Select, TimePicker, message } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment';
import styles from './index.module.scss'

interface IProps {
  type?: string,   // 构造key值, 多个实例时需要设置该属性，不然key会重复
  handleOk?: () => void,  // 手动授权-安全设置，字段编辑即保存，没有 确认保存的按钮
  [p: string]: any
}

const generateId = (type: string, data:any[]) => { 
  return type + "_" + data.length++;
}
const CycleTimes = (props: IProps) => {
  const {disabled = false, type = '', value, addButtonStyle, handleOk } = props;
  
  const initValue = value?.length
    ? value?.map((item: any, index: number) => {
        return {
          ...item,
          key: type + "_" + index++,
        };
      })
    : [{ key: generateId(type, []) }];

  const [data, setData] = useState<any[]>(initValue); 

  useEffect(() => {
    const value = data
      ?.filter((i) => i && !i?.deleteFlag)
      ?.map((i: any) => { 
        const { dayOfWeek, startTime, endTime } = i;
        return {
          dayOfWeek,
          startTime,
          endTime,
        };
      });
    handleOk && handleOk()
    props.onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleAdd = () => {
    const hasEmpty = data
      ?.filter((i) => !i.deleteFlag)
      ?.filter((i: any) => Object.keys(i).length < 4)?.length;
    if (hasEmpty) {
      message.error('请先完善已有的时间范围')
      return
    }
    if (data.length > 6) {
      message.error('最多添加7个')
      return
    }
    const key = generateId(type, data);
    setData((d: any[]) => { 
      return d.concat([{ key }])?.filter(i=>i);
    });
  }

  const handleRemove = (key: any) => {
    setData((d: any) => {
      d.map((r:any) => {
        if (r?.key === key) {
          r.deleteFlag = true;
        } 
        return { ...r }
      })
      return [...d]
    })
  }

  useEffect(() => {
    let num = 0;
    data
      ?.filter((i) => !i?.deleteFlag)
      ?.map((item: any) => {
        num = num + 1
      })
    
    // 全部都被清掉时, 默认显示一条空记录
    if (num === 0) {
      const key = generateId(type, data);
      setData((d: any[]) => { 
        return d.concat([{ key }])?.filter(i=>i);
      });
    }
  }, [handleRemove])

  const handleValueChange = (value: any, key:string, type: string) => {
    setData((d) => {
      d.map((i: any) => {
        if (i?.key === key) {
          if (type === "times") {
            i.startTime = value[0]
            i.endTime = value[1];
          } else { 
            i[type] = value;
          }
        }
        return i;
      })?.filter(i=>i);
      return [...d];
    });
  };

  return (
    <div className={styles.cycleTimeWrap}>
      <div className={styles.addBtn} style={addButtonStyle} >
        <Button type="link" 
          onClick={() => {
            !disabled && handleAdd();
          }}
        >
          <PlusOutlined /> 添加
        </Button>
      </div>
      {data
        ?.filter((i) => !i?.deleteFlag)
        ?.map((item: any) => (
          <div key={item?.key} className={styles.mb10}>
            <Select
              disabled={disabled}
              placeholder="请选择"
              style={{ width: 100 }}
              options={[
                { value: "EVERY_DAY", label: "每天" },
                { value: "MONDAY", label: "周一" },
                { value: "TUESDAY", label: "周二" },
                { value: "WEDNESDAY", label: "周三" },
                { value: "THURSDAY", label: "周四" },
                { value: "FRIDAY", label: "周五" },
                { value: "SATURDAY", label: "周六" },
                { value: "SUNDAY", label: "周日" },
              ]}
              value={item?.dayOfWeek}
              onChange={(val) => handleValueChange(val, item?.key, "dayOfWeek")}
            />
            <TimePicker.RangePicker
              disabled={disabled}
              allowClear={false}
              format="HH:mm"
              style={{ width: 200, marginLeft: 10 }}
              onChange={(_, val) => handleValueChange(val, item?.key, "times")}
              value={
                item?.startTime && item?.endTime
                  ? [
                      moment(item?.startTime, "HH:mm"),
                      moment(item?.endTime, "HH:mm"),
                    ]
                  : undefined
              }
            />
            <Button
              type='link'
              className={styles.ml10}
              onClick={() => !disabled && handleRemove(item?.key)}
            >
              <DeleteOutlined /> 清空
            </Button>
          </div>
        ))}
    </div>
  );
}
export default CycleTimes
