import React, {useEffect, useContext} from 'react'
import { useSelector,  } from 'src/hook'
import { Form } from 'antd'
import {
  FormItemApplicant,
  FormItemApprover,
  FormItemReason,
  FormItemTimeLimit,
  FormItemOperations,
  ElementTreeSelect,
} from '../flowFormItems'
import { FormFieldElementEntity, FormFieldsDataManipulation } from 'src/types'
import { FlowModalForm } from './FlowModalForm'
import { launchFlowApply } from 'src/api'

export const ModalFormDataManipulation = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const flowType = 'dataManipulation'

  const [form] = Form.useForm()
  const onResetApprover = () => {
    form && form.setFieldsValue({approveUserId:''})
  }

  return (
    <FlowModalForm<FormFieldsDataManipulation>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return

        const {
          approveUserId,
          applyReason,
          elements,
          timeLimit,
          operationList,
        } = values
        const {
          connectionId,
          connectionType: dataSourceType,
          nodeType,
        } = elements?.[0]
        const expiredTimeLimit = timeLimit.preset || timeLimit.custom
        const nodePathList = elements.map(({ nodePath }) => nodePath)
        const nodePathWithTypeList = elements.map(({ nodePathWithType }) => nodePathWithType)
        return launchFlowApply({
          applyUserId,
          email,
          applyReason,
          expiredTimeLimit,
          flowType,
          connectionId,
          dataSourceType,
          nodePathList,
          nodePathWithTypeList,
          nodeType,
          firstApproveUserId: approveUserId,
          operationList,
        })
      }}
    >
      <FormItemApplicant />
      <Form.Item
        label="数据库元素"
        name="elements"
        required
        rules={[
          {
            validator: (_, value?: FormFieldElementEntity[]) => {
              if (!value?.length) {
                return Promise.reject('请选择数据库元素')
              }
              const ruleObj = value[0]
              if(ruleObj.nodeType === 'synonym' && value.length > 1) {
                return Promise.reject('同义词只能选择一个')
              }
              for (const el of value) {
                if (el.connectionId !== ruleObj.connectionId) {
                  return Promise.reject('只能选择相同连接下的元素')
                }
                if (el.nodeType !== ruleObj.nodeType) {
                  return Promise.reject('只能选择相同类型的元素')
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <ElementTreeSelect flowType={flowType} onChange={onResetApprover}/>
      </Form.Item>
      <FormItemOperations />
      <FormItemApprover />
      <FormItemReason />
      <FormItemTimeLimit />
    </FlowModalForm>
  )
})
