import { combineReducers } from '@reduxjs/toolkit'

import { createConnectionReducer } from './connectionWizard/createConnectionSlice'
import { createDatabaseReducer } from './databaseWizard/createDatabaseSlice'

export * from './connectionWizard/createConnectionSlice'
export * from './databaseWizard/createDatabaseSlice'

export const wizardsReducer = combineReducers({
  createConnection: createConnectionReducer,
  createDatabase: createDatabaseReducer,
})
