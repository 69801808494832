import baseConfig from './base'
import { WatermarkComponentProps } from '@pansy/react-watermark'

interface IProjectConfig {
  modules: string[]
  projectName: string
  waterMarkConfig: WatermarkComponentProps
}

class ProjectConfig implements IProjectConfig {
  public projectName: string = 'base'
  public modules: string[] = baseConfig.modules
  public waterMarkConfig = baseConfig.waterMarkConfig
}

export default new ProjectConfig()
