import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, Form, Switch, Input, Typography, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useRequest } from 'src/hook'
import { LinkButton } from 'src/components'
import { getAdConfig, putAdConfig } from 'src/api'
import { SettingFormContext } from '../SettingFormContext'
import styles from './index.module.scss'

const { Text } = Typography

export const AdCard = () => {
  const [editing, setEditing] = useState(false)
  const form = useContext(SettingFormContext) as FormInstance

  const { data: adConfig, loading, run: getConfig } = useRequest(getAdConfig)
  const { run: putConfig } = useRequest(putAdConfig, {
    manual: true,
    onSuccess: () => {
      getConfig()
    }
  })

  const setFormVals = useCallback((val:any) => {
    form.setFields([
      { name: 'adDirectSwitch', value: adConfig.adDirectSwitch },
      { name: 'adDirectLdapAddress', value: adConfig.adDirectLdapAddress },
      { name: 'adDirectSearchBase', value: adConfig.adDirectSearchBase },
      { name: 'adDirectUsernameSuffix', value: adConfig.adDirectUsernameSuffix },
    ])
  }, [adConfig, form])

  const handleSave = useCallback(() => {
    form
      .validateFields([
        'adDirectSwitch',
        'adDirectLdapAddress',
        'adDirectSearchBase',
        'adDirectUsernameSuffix',
      ])
      .then((val) => {
        putConfig(val).then((val) => {
          if(!!val.resMsg) {
            message.success(val.resMsg)
          }
        })
        setFormVals(val)
      })
      .then(() => {        
        setEditing(false)
      }).catch((error) => { console.error(error) })
  }, [form, putConfig, setFormVals])

  useEffect(() => {
    if (!!adConfig) {
      setFormVals(adConfig)
    }
  }, [adConfig, form, setFormVals])

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => {
          if(!!adConfig) {
            setFormVals(adConfig)
          }
          setEditing(true)}
        }>编辑</LinkButton>
      )}
    </div>
  )

  const urlValidator = (_: any, value: string) => {
    if(!value) {
      return Promise.reject('请输入 URL')
    }
    const passed = value.startsWith('ldap://') || value.startsWith('LDAP://')
    if (!passed) {
      return Promise.reject('必须以 ldap:// 或者 LDAP:// 开头')
    } else {
      return Promise.resolve()
    }
  }

  const suffixValidator = (_: any, value: string) => {
    const passed = value?.startsWith('@')
    if(!passed && value) {
      return Promise.reject('必须以 @ 开头')
    } else if(value.length > 30) {
      return Promise.reject('字符长度不能超过 30')
    } else {
      return Promise.resolve()
    }
  }

  return (
    <section className="cq-card flow-card" id="AdCard" style={{border: 'none'}}>
      <div className={styles.singleTitle}>
        {/* <h3 className="cq-card__title">AD 域配置</h3> */}
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          {/* <Form> */}
          <Form.Item label="是否开启">
            <Form.Item
              hidden={!editing}
              name="adDirectSwitch"
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
            {!editing && (
              <Text>
                {!!adConfig && adConfig.adDirectSwitch ? '开启' : '关闭'}
              </Text>
            )}
          </Form.Item>

          <Form.Item label="AD 域地址">
            {editing && <Form.Item
              hidden={!editing}
              name="adDirectLdapAddress"
              noStyle
              rules={[{ required: true, validator: urlValidator }]}
            >
              <Input placeholder='LDAP://example:port'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!adConfig && adConfig.adDirectLdapAddress}</Text>
            )}
          </Form.Item>

          <Form.Item label="搜索目录">
           {editing && <Form.Item
              hidden={!editing}
              name="adDirectSearchBase"
              noStyle
              rules={[{ required: true, validator: (_: any, value: string) => {
                if(!value) {
                  return Promise.reject('请输入搜索目录')
                } else {
                  return Promise.resolve()
                }
              } }]}
            >
              <Input placeholder='OU=测试组,DC=liu,DC=com'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!adConfig && adConfig.adDirectSearchBase}</Text>
            )}
          </Form.Item>

          <Form.Item label="登录用户名称后缀">
           {editing && <Form.Item
              hidden={!editing}
              name="adDirectUsernameSuffix"
              noStyle
              rules={[{ validator: suffixValidator}]}
            >
              <Input placeholder='@bintools.com'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!adConfig && adConfig.adDirectUsernameSuffix}</Text>
            )}
          </Form.Item>
          {/* </Form> */}
        </Card>
      </section>
    </section>
  )
}
