import React, {useEffect, useRef, useState} from 'react';
import { Card, message, Form, Upload, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import styles from './index.module.scss';
import 'src/styles/layout.scss';

export const UploadCqDataCard = () => {
  const [jsonFile] = useState<UploadFile[]>([]);
  const [logs, setLogs] = useState<string[]>([]);
  const logContainerRef = useRef<HTMLDivElement>(null);

  const beforeUpload = (file: RcFile) => {
    const isJson = file.type === 'application/json';
    if (!isJson) {
      message.error('只支持JSON格式的文件');
    }
    return isJson;
  };

  const customRequest = async ({file, onSuccess,}: {
      file: RcFile;
      onSuccess: (response: any, file: RcFile) => void;
      onError: (error: Error) => void;
  }) => {
      // 使用Fetch API或其他HTTP库将文件上传到后端
       const formData = new FormData();
       formData.append('file', file);
       const response = await fetch('/user//cloudquery/all/import/upload-json-file', {
           method: 'POST',
           body: formData,
       });
       if (response.ok) {
           const responseData = await response.json();
           if (responseData.resCode == 10000){
               onSuccess(responseData, file); // 上传成功时触发回调
               message.success('上传文件成功！');
           }else {
               onSuccess(responseData, file); // 上传成功时触发回调
               message.error(responseData.resMsg);
           }
       }
  };

  async function fetchData() {
      try {
          // 发起数据请求的代码，使用Fetch API
          const response = await fetch('/user/cloudquery/all/import/log', {
              method: 'GET', // 将请求方法改为GET
          });
          if (response.ok) {
              const responseData = await response.json();
              const data = responseData.data;
              if (Array.isArray(data)) {
                  data.forEach((log) => {
                      addLog(log); // 将每条日志添加到日志状态中
                  });
              }
          } else {
              console.error('Fetch failed with status:', response.status);
          }
      } catch (error) {
          console.error('Error:', error);
      }
  }

  const addLog = (logMessage: string) => {
      setLogs((prevLogs) => [...prevLogs, logMessage]);
  };

  const clearLogs = () => {
      setLogs([]);
  };


  // 生成日志文件并创建下载链接
  const downloadLogs = () => {
      // 将日志数组连接成一个字符串，每行一个日志
      const logData = logs.join('\n');
      // 创建一个Blob对象，用于保存日志数据
      const blob = new Blob([logData], { type: 'text/plain' });
      // 创建一个URL对象，用于生成下载链接
      const url = URL.createObjectURL(blob);
      // 创建一个下载链接
      const a = document.createElement('a');
      a.href = url;
      a.download = 'cloudquery_import.log'; // 设置下载文件的名称
      // 模拟点击下载链接
      a.click();
      // 释放URL对象以减少内存占用
      URL.revokeObjectURL(url);
  };


    // 渲染日志项并应用不同级别的颜色
    const renderLogItem = (log: string, index: number) => {
        let color = 'black'; // 默认颜色
        if (log.includes('INFO')) {
            color = 'green'; // INFO级别的颜色
        } else if (log.includes('ERROR')) {
            color = 'red'; // ERROR级别的颜色
        }

        return (
            <li
                key={index}
                style={{
                    marginBottom: '8px',
                    borderBottom: '1px solid #ccc',
                    padding: '4px 0',
                    fontFamily: 'monospace',
                    color: color,
                }}
            >
                {log}
            </li>
        );
    };

  useEffect(() => {
      const interval = setInterval(fetchData, 2000); // 2秒钟获取一次数据
      return () => {
          clearInterval(interval); // 清除定时器
      };
  }, []);

  useEffect(() => {
      // 每当logs数组更新时，滚动到底部
      if (logContainerRef.current) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
      }
  }, [logs]);

  return (
      <section className="cq-new-card flow-card" id="JsonFileCard">
        <div className="cq-card__headerbar">
          <h3 className="cq-card__title" style={{ flex: 1 }}>
            数据迁移
            <Tooltip title="可将其他机器导出的数据进行上传，迁移其数据到本机器当中">
              <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
            </Tooltip>
          </h3>
        </div>
        <section className="card__content" style={{ display: 'flex' }}>
          <Card className={styles.settingCardContent} bordered={false} style={{ flex: '50%' }}>
            <Form.Item
                label={
                  <>
                    上传文件
                    <Tooltip title="导入模式为增量导入，不会覆盖原有数据，请保证当前机器无历史数据，避免导入的数据不完整">
                      <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                    </Tooltip>
                  </>
                }
            >
              <Form.Item name="jsonFile" noStyle>
                <Upload
                    listType="text"
                    fileList={jsonFile}
                    beforeUpload={beforeUpload}
                    customRequest={customRequest} // 使用自定义上传逻辑
                    showUploadList={false}
                >
                  <div
                      style={{
                        border: '1px dashed #d9d9d9',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                  >
                    <p>点击或拖动文件到此处上传</p>
                  </div>
                </Upload>
              </Form.Item>
            </Form.Item>

              <div style={{ border: '1px solid #e8e8d8', borderRadius: '4px', padding: '16px', backgroundColor: '#f8f8f8', height: '550px' }}>
                  <h4 style={{ fontFamily: 'monospace' }}>日志：</h4>
                  <div ref={logContainerRef} style={{ height: '100%', overflow: 'auto' }}>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                          {logs.map((log, index) => renderLogItem(log, index))}
                      </ul>
                  </div>
              </div>
              <button onClick={clearLogs} style={{ marginTop: '16px', backgroundColor: 'red', color: 'white', border: '1px solid red', borderRadius: '4px' }}>
                  清空日志
              </button>
              <button onClick={downloadLogs} style={{ marginLeft: '16px', backgroundColor: 'blue', color: 'white', border: '1px solid blue', borderRadius: '4px' }}>
                  下载日志
              </button>
          </Card>
        </section>
      </section>
  );
}
