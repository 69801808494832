import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { queryBatchSql } from 'src/api'

interface IState {
  loading: boolean
  batchSqlInfo: any;
}

const initialState: IState = {
  loading: false,
  batchSqlInfo: {}
}

export const sqlBatchExecutionSlice = createSlice({
  name: "sqlBatchExecution",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      const newValue = action.payload
      state.loading = newValue;
    },
    setBatchSqlInfo:(state, action: PayloadAction<any>) => {
      const newValue = action.payload
      state.batchSqlInfo = {...state.batchSqlInfo, ...newValue};
    },
  },
});

export const sqlBatchExecutionReducer = sqlBatchExecutionSlice.reducer

export const { 
  setLoading, 
  setBatchSqlInfo, 
} = sqlBatchExecutionSlice.actions;


export const queryBatchSqlList =
  (params: any): AppThunk =>
    (dispatch) => {
      dispatch(setLoading(true))
      queryBatchSql(params).then((res) => {
        dispatch(setBatchSqlInfo({...res, paraments: params}))
      }).catch((err: any)=>{
        console.error('查询失败', err)
      }).finally(()=>{
        console.log('查询完成')
        dispatch(setLoading(false))
      })
    }