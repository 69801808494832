/**
 * 连接池管理
 */
import React, { useEffect, useState } from "react"
import { Table, Tabs, Tooltip, Spin, Button } from 'antd'
import { QuestionCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import { queryConnectionTableInfo, stopExecute, explainPlan } from "src/api"
import { useRequest } from "src/hook"
import { Iconfont } from 'src/components'
import { renderTableFields, getScrollX } from 'src/util'
import classnames from 'classnames'
import styles from './index.module.scss'

const ConnnectionManage = (props: any) => {
  const { connectionId, dataSourceType, permissionlist, canEdit } = props;
  const { roleNameList } = permissionlist || {}
  const [activeKey, setActiveKey] = useState("1");
  const [selectRow, setSelectRow] = useState<any>({})
  const [explainData, setExplainData] = useState<any[]>([]);
  const [stopLoading, setStopLoading] = useState(false);

  // 获取连接池管理列表信息
  const {
    data = [],
    run: queryData,
    refresh,
    loading
  } = useRequest(queryConnectionTableInfo, {
    manual: true,
    formatResult: (res: any) => { 
      return res?.map((item:any, index: string) => {
        return {
          index,
          ...item
        }
      })
    },
    onSuccess: (res: any) => { 
      const item = res[0] || {}
      setSelectRow(item);
    }
  });

  // 执行计划查询
  const { run: queryExplainPlan, loading: explainLoading } = useRequest(
    explainPlan,
    {
      manual: true,
      formatResult: (res: any) => {
        return res?.map((i: any) => {
          i.column = i?.columnInfos?.map((d: any, index: string) => {
            return {
              title: d?.columnName,
              dataIndex: d?.columnName,
              key: d?.columnName,
              width: !index ? 80 : 150,
              fixed: !index && "left",
              ellipsis: true,
              render: (item: any) => item?.value,
            };
          });
          i?.resultData?.map((item: any, index: string) => {
            return {
              index,
              ...item,
            };
          });
          return i;
        });
      },
      onSuccess: (res: any[]) => {
        setExplainData(res);
      },
    }
  );

  // 执行计划查询
  const getExplainPlan = (record: any) => {
    const {
      connectionId,
      explainDatasource: databaseName,
      currentStatementList: statements,
    } = record || {};
    const params = {
      dataSourceType,
      connectionId,
      databaseName,
      statements,
    };
    queryExplainPlan(params)
      .then()
      .catch((err: any) => {
        console.log("执行计划error：", err);
      });
  };

  useEffect(() => {
    const hasSelectData = selectRow && Object.keys(selectRow)?.length;
    if (activeKey === "2") {
      if (!hasSelectData) {
        setExplainData([]);
      } else { 
        getExplainPlan(selectRow);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, selectRow]);

  useEffect(() => {
    const params: any = {
      dataSourceType,
      connectionId,
    };
    queryData(params);
  }, [connectionId, dataSourceType, queryData]);

  // 终止连接
  const handleStop = (record: any) => {
    const { connectionId, connectionObjectId } = record || {};
    const params = {
      connectionId,
      connectionObjectId,
    };
    setStopLoading(true)
    stopExecute(params)
      .then(() => {
        refresh();
      })
      .catch((err: any) => {
        console.log("终止error：", err);
      }).finally(() => { 
        setStopLoading(false)
      });
  };

  const hanldeTabsChange = (key: string) => {
    setActiveKey(key);
  };

  const columns: any[] = [
    {
      title: "用户名",
      dataIndex: "userName",
      key: "userName",
      with: 200,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: "Database",
      dataIndex: "dataBase",
      key: "dataBase",
      with: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: "Schema",
      dataIndex: "schema",
      key: "schema",
      with: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: "状态",
      dataIndex: "currentStatus",
      key: "currentStatus",
      with: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: "累计连接时长",
      dataIndex: "totalConnectTime",
      key: "totalConnectTime",
      with: 200,
      render: (txt: string) => (
        <>
          {renderTableFields(txt)}
          &nbsp;
          <Tooltip title="jdbc与数据库持续连接的时长">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: "运行时长",
      dataIndex: "totalRunTime",
      key: "totalRunTime",
      with: 200,
      render: (txt: string) => (
        <>
          {renderTableFields(txt)}
          &nbsp;
          <Tooltip title="用户占用连接时长">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      title: "状态管理",
      dataIndex: "option",
      key: "option",
      fixed: "right",
      width: 100,
      render: (_: string, record: any) => (
        canEdit
        ? <Button
            type="link"
            className={classnames(styles.options)}
            onClick={() => handleStop(record)}
          >
            终止
          </Button>
        : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
            <Button
              type="link"
              disabled={true}
            >
              终止
            </Button>
          </Tooltip>
      ),
    },
  ];

  const scrollX = 150 * columns?.length > 1000 ? 150 * columns?.length : 1000;
  if (loading || stopLoading) { 
    return <Spin spinning={true} className={styles.loading} />;
  }
  return (
    <div className={styles.connectionManageWrap}>
      <div
        className={classnames(styles.flexRight, styles.options, styles.mb10)}
        onClick={refresh}
      >
        <ReloadOutlined style={{ marginRight: 2 }} />
        刷新
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="index"
        scroll={{ x: getScrollX(columns), y: 200 }}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: (event) => {
              setSelectRow(record);
              setActiveKey("1");
            },
          };
        }}
        rowClassName={(record: any) => {
          return record?.index === selectRow?.index ? styles.selectedItem : "";
        }}
      />
      <Tabs activeKey={activeKey} onChange={hanldeTabsChange}>
        <Tabs.TabPane tab={<><Iconfont type='icon-SQL' />SQL</>} key="1">
          {(selectRow?.currentStatementList || [])?.map(
            (item: string, index: string) => (
              <div className={styles.mb10} style={{wordBreak: 'break-all'}}>
                【{index + 1}】{item}
              </div>
            )
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={<><Iconfont type='icon-zhihangjihua' />执行计划</>} key="2" className={styles.explainWrap}>
          {explainLoading && (
            <Spin spinning={true} className={styles.loading} />
          )}
          {explainData?.map((i: any, index: number) => (
            <div className={styles.mb20} key={index}>
              <div className={styles.title}>
                【{index + 1}】SQL: {i?.statement}
              </div>
              {!!i?.resultData?.length ? (
                <Table
                  bordered
                  rowKey="index"
                  columns={i?.column}
                  dataSource={i?.resultData}
                  pagination={false}
                  scroll={{ x: scrollX }}
                />
              ) : (
                <div className={styles.colorf00}>
                  {i?.executeError?.message}
                </div>
              )}
            </div>
          ))}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
export default ConnnectionManage