import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { ICellRendererParams } from '@ag-grid-community/core'
import { InputNumber } from 'antd'

interface IColumnSelectEditorProps extends ICellRendererParams {
  min?: number | null,
  maxLength?: number
}

export const NumericEditor = forwardRef(
  (props: IColumnSelectEditorProps, ref) => {
    const [value, setValue] = useState<number | any>()

    const { data, colDef, min=null, maxLength } = props
    useEffect(() => {
      const originValue = data[colDef?.field || '']
      setValue(originValue)
    }, [colDef, data])

    const [editing, setEditing] = useState(true)
    const refContainer = useRef(null)

    useImperativeHandle(ref, () => {
      return {
        getValue() {

          let formatValue = value
           const reg = min === null ? true : formatValue >= min;
           //输入类型 纯数字
          if(typeof formatValue === 'number' && reg) {
            formatValue =  `${value}`
          }else {
            formatValue = formatValue?.toString()?.replace(/[^0-9]| [-]/ig,'')    
          }
          try {
            formatValue =maxLength ?  formatValue.slice(0,maxLength) : formatValue

          } catch (error) {
            formatValue = ''
          }
           
          
          return formatValue ? Number(formatValue) : null
        },
        isPopup() {
          return true
        },
        afterGuiAttached: () => {
          getSelection()?.empty()
        },
      }
    })

    useEffect(() => {
      if (!editing) {
        props.api.stopEditing()
      }
    }, [editing, props.api])


    const onChange = (v: number|any) => {
      setEditing(true)
      setValue(v)
    }



    return (
      <div
        ref={refContainer}
        tabIndex={1} // ag-grd: important - without this the key presses wont be caught
      >
       <InputNumber
          className='inputNumber'
          value={value}
          min={min || 0}
          onChange={onChange}
          style={{ width: '200px' }}

        ></InputNumber>
      </div>
    )
  },
)
