import React, { FC, useContext, useEffect} from 'react'
import { Badge, Card, Button, Steps } from 'antd'
import * as _ from 'lodash'
import classNames from 'classnames'
import { useRequest } from 'src/hook'
import { CURRENT_ROUTE_TYPE } from 'src/constants'
import { getNewFlowProcess } from 'src/api'
import { FormContext } from '../ApplyFormContext'
import { EmptyCardContent } from './EmptyCardContent'
import styles from './index.module.scss'

interface ReviewProcess {
  currentStep: number
  itemStepMark: number //当前步骤
  isViewDetail: boolean
  flowTaskId?: string;
  currentRouteType?: CURRENT_ROUTE_TYPE;
  onWithDrawRequest?: (flowId: number) => void
}

export const ReviewProcess: FC<ReviewProcess> = ({
  currentStep,
  itemStepMark,
  isViewDetail,
  flowTaskId,
  currentRouteType,
  onWithDrawRequest,
}) => {
  const { currentContextValue } = useContext(FormContext)

  //详情
  const { data: list=[], run: getFlowSteps } = useRequest(getNewFlowProcess, {
    manual: true,
  })

  useEffect(() => {
    if (itemStepMark <= currentStep && currentContextValue?.flowUUID) {
      getFlowSteps(currentContextValue?.flowUUID)
    }
  }, [itemStepMark, currentStep, currentContextValue?.flowUUID])

  if (itemStepMark > currentStep) return <EmptyCardContent title="审核" />

  return (
    <Card
      title="审核"
      className={styles['review-process-card']}
      extra={
        currentContextValue?.executeStatus === '审批中' && currentRouteType==='MINE_APPLY' && (
          <Button
            type="primary"
            onClick={() => onWithDrawRequest && onWithDrawRequest(currentContextValue?.flowId)}
          >
            撤回申请
          </Button>
        )
      }
    >
      <Steps direction="horizontal" current={0}>
        {list &&
          list.map((flow: any, index: number) => {
            const { flowProcessInfos = [],flowNodeResult } = flow
            return (
              <Steps.Step
                key={index}
                title={<div className={styles['step-title']}>{ index === 0
                  ? '申请数据变更'
                  : flowProcessInfos?.[0]?.approvedUser}</div>
                 
                }
                description={
                  <div className={styles.desc}>
                 {flowNodeResult === 'pass' ?  '审批通过' : flowNodeResult === 'pending' ? '审批中' : currentContextValue?.executeStatus}
                   {
                    currentContextValue?.executeStatus !== '已撤回' &&
                   <span className={styles.time}>{
                    index===0 ? currentContextValue?.applyTime :flowProcessInfos?.[0]?.approvedTime}</span>
                  }
                  </div>
                }
                icon={
                  <div
                    className={classNames(styles['step-icon'], {
                      [styles.start]: index === 0,
                    })}
                  >
                    <span
                      className={classNames(styles.icon, {
                        [styles.pending]: flowNodeResult === 'pending',
                      })}
                    >
                      {index === 0 ? '建' : '审'}
                    </span>
                    {index !== 0 && (
                      <Badge
                        dot={true}
                        offset={[8, 10]}
                        className={styles['icon-badge']}
                        color={flowNodeResult === 'pending' ? '#3262FF': '#36B839'}
                      ></Badge>
                    )}
                  </div>
                }
                
              ></Steps.Step>
            )
          })}
      </Steps>
    </Card>
  )
}
