import React, { FC, useState, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Dropdown, message, Modal, Input } from 'antd'
import { useRequest, useSelector } from 'src/hook'
import { getUrlSearchParams} from 'src/util'
import { ORDER_MAPPING} from 'src/constants'
import { getMyApproveList, approveApplication, applicationAmend, ApplyItem, IApplyProps } from 'src/api'
import { SimpleTable, SimpleBreadcrumbs } from 'src/components'
import { SearchHeader, renderMenuList } from '../common'
import { columns } from './columns'
import { getFilteredDatabaseList } from '../utils'
import ModalDataChangeSelectNewApprover from 'src/pageTabs/flowPages/flowDetails/ModalDataChangeSelectNewApprover'
import { SearchOutlined } from '@ant-design/icons'
import styles from './index.module.scss'

interface ListProps {}

export const List: FC<ListProps> = ({ ...rest }) => {
  const defaultSearchParams = {
    currentPage: 1,
    pageSize: 10,
    status: '待审核',
  } as IApplyProps

  const history = useHistory();
  const {search} = useLocation<{status: string}>();
  const { dataSourceList } = useSelector((state) => state.dataSource);

  const [searchParams, setSearchParams] = useState(defaultSearchParams)
  const [searchValue, setSearchValue] = useState('');

  const [currentActionRecord, setCurrentActionRecord] = useState<{
    label: string
    type: string
    id: number
  }>()

  const [visible_setNewApprover, setVisible_setNewApprover] = useState(false) // 新审批人 modal visible
  const [currentTransferApprover, setCurrentTransferApprover] = useState<
    Array<string>
  >([]) // 新审批人 modal visible

  const {
    data: approvalList,
    run: runGetTableDataSource,
    refresh: refreshApproveList,
  } = useRequest(() => getMyApproveList(searchParams), {
    refreshDeps: [searchParams],
    debounceInterval: 300,
  })

  // 通过驳回审批
  const { run: runApprove } = useRequest(approveApplication, {
    manual: true,
    // fetchKey: (id) => id,
    onSuccess: () => {
      if (currentActionRecord?.label === 'pass') {
        message.success('通过成功')
      } else {
        message.success('驳回成功')
      }
      refreshApproveList()
    },
  })

  useEffect(() => {
  
    if (search) {
     const {status} =  getUrlSearchParams(search);
    setSearchParams({
      ...searchParams,
      status: decodeURIComponent(status),
      keyword: searchValue
    })
    }
  },[])

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '我的审核',
    },
  ]

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(e?.target?.value);
    setSearchParams({
      ...searchParams,
      keyword: e?.target?.value as string,
    })
  }

  const getActions = (status: string) => {
    switch (status) {
      case '待审核':
        return [
          { label: '任务转交', key: 'transfer' },
          { label: '通过', key: 'pass' },
          { label: '驳回', key: 'reject' },
        ]
      default:
        return [{ label: '查看详情', key: 'view' }]
    }
  }

  function confirm(record: ApplyItem ) {
    const { flowId, flowTaskId} = record
    async  function isEmpty(closeFn: any, val?: string) {
      if (!val) {
        message.warn('请输入驳回理由')
        return
      }
      // runReject(applyId!, { refuseReason: val })

      let approveParams = {
        flowId,
        taskId: flowTaskId || '',
        approvedFlag: false,
        refuseReasonVo:{},
        approvedTime: '',
      }
      await applicationAmend({
        //@ts-ignore
        id: record.id,
        approveUserComment: val
      })
      //@ts-ignore
      runApprove(approveParams)

      closeFn()
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => isEmpty(closeFn, val),
      })
    }

    const { update } = Modal.confirm({
      title: '驳回理由',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    })
  }

  const newColumns = useMemo(() => {
    const actions = getActions(searchParams.status)

    const afterFormatColumns = columns.map((item) => {
      if (item.dataIndex === 'nodePath') {
        return {
          ...item,
          filterMultiple: false,
          filters: getFilteredDatabaseList(dataSourceList).map((item: any) => ({
            //@ts-ignore
            text: item?.dataSourceName,
            value: item?.dataSourceName,
          })),
        }
      }
      if (item.dataIndex === 'actions') {
        return {
          ...item,
          render: (_: any, record: any) => (
            <Dropdown
              overlay={renderMenuList({
                menus: actions,
                onChange: (key) => {
                  switch (key) {
                    case '查看详情':
                      history.push({pathname: `/data_change_mine_approve/${record.id}/detail`, state: { flowTaskId: record?.flowTaskId, applyUserId: record?.applyUserId },})
                      break
                    case '任务转交':
                      setVisible_setNewApprover(true)
                      setCurrentTransferApprover([record.flowTaskId]) //应该使用当前userId
                      setCurrentActionRecord({
                        label: '任务转交',
                        type: key,
                        id: record.id,
                      })
                      break
                    case '通过':
                      setCurrentActionRecord({
                        label: 'pass',
                        type: key,
                        id: record.id,
                      })
                      runApprove({
                        flowId: record?.flowId,
                        approvedFlag: true,
                        taskId: record?.flowTaskId,
                        approvedComment: '',
                        approvedTime: '',
                      })
                      break
                    case '驳回':
                      setCurrentActionRecord({
                        label: 'reject',
                        type: key,
                        id: record.id,
                      })
                      confirm(record)
                      break
                    default:
                      break
                  }
                },
              })}
            >
              <div style={{ cursor: 'pointer' }}>... </div>
            </Dropdown>
          ),
        }
      }
      return item
    })

    //样式
    return afterFormatColumns
  }, [searchParams?.status])

  return (
    <>
      <div className={styles["extra-action"]}>
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <Input
          style={{ width: "200px" }}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          placeholder="搜索标题"
          value={searchValue}
          onChange={handleInputChange}
          allowClear
        />
      </div>
      <div>
        <SearchHeader
          searchParams={searchParams}
          refresh={() => refreshApproveList()}
          setSearchParams={(params: any) => {
            history.push(`/data_change_mine_approve?status=${params?.status}`)
            setSearchParams({
              ...searchParams,
              ...params,
              currentPage: 1,
              keyword: searchValue
            })
          }}
        />
        <SimpleTable
          size="small"
          rowKey="id"
          //@ts-ignore
          columns={newColumns}
          total={approvalList?.total || 0}
          dataSource={approvalList?.content || []}
          searchParams={searchParams}
          setSearchParams={(params: any) =>{
            let translateParams = params;
            if (params?.type) {
              translateParams.type = Number(params?.type?.[0]);
            }
            if (params?.applyTime) {
              translateParams.sortItem = 'applyTime';
              //@ts-ignore
              translateParams.sortType = ORDER_MAPPING[params.applyTime];
              delete translateParams?.applyTime;
            }
            if (params?.executeStatus) {
              translateParams.executeStatus = params?.executeStatus?.[0];
            }
            if (params?.executeTime) {
              translateParams.sortItem = 'executeTime';
              //@ts-ignore
              translateParams.sortType = ORDER_MAPPING[params.executeTime];
            }
              translateParams.dataSourceType = params?.nodePath?.[0];
              delete translateParams?.nodePath;
              delete translateParams?.executeTime;
            setSearchParams({ ...translateParams, keyword: searchValue })
          }}
        />
      </div>
      <ModalDataChangeSelectNewApprover
        cleanParentComponentData={() => {
          setCurrentTransferApprover([]);
          setSearchParams({ ...defaultSearchParams, keyword: searchValue })
        }}
        userTasks={currentTransferApprover}
        visible_setNewApprover={visible_setNewApprover}
        setVisible_setNewApprover={setVisible_setNewApprover}
      />
    </>
  )
}
