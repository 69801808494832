import projectConfig from 'src/projectConfig'
import { FlowPage } from 'src/pageTabs/flowPages/Flow'
import { AppManagementPage } from 'src/pageTabs/appManagementPage'
import { AuditPage } from 'src/pageTabs/auditPage'
import { AuditOverviewPage, AuditExecutionPage,AuditOperationPage } from 'src/pageTabs/audit'
import ConnectionManagementPage from 'src/pageTabs/connectionManagementPage'
import { DownloadPage } from 'src/pageTabs/downloadPage'
import { MyFolderPage } from 'src/pageTabs/myFolderPage'
import { OrganizationPage } from 'src/pageTabs/organizationPage'
import { QueryPage } from 'src/pageTabs/queryPage'
import { SettingPage } from 'src/pageTabs/settingPage'
import { SqlLogPage } from 'src/pageTabs/sqlLogPage'
import { SysPermsPage } from 'src/pageTabs/sysPermsPage'
import { AlarmPage } from 'src/pageTabs/Alarm2Page'
import { SysRolesPage } from 'src/pageTabs/sysRolesPage'
import { SysMaskingInternalPage } from 'src/pageTabs/SysMaskingInternalPage'
import { UserPermsPage } from 'src/pageTabs/userPermsPage'
// import { MonitorPage } from 'src/pageTabs/monitorPages'
import { PostTable } from 'src/pageTabs/postPage'
import { UserMesPage } from 'src/pageTabs/userMesPage'
import { DockerMonitor, HostMonitor } from 'src/pageTabs/sysMonitorPage'
import { DashBoard } from 'src/pageTabs/dashBoard'
import { AppSecret } from 'src/pageTabs/appSecretPage'
import { UserParameterP } from 'src/pageTabs/userParameter'
import AuthorizationManagePage from 'src/pageTabs/authorizationManagePage'
import { DataProtection } from 'src/pageTabs/dataProtection'
import { CreateTransferTask, TransferTaskRecord, TransferDetailPage } from 'src/pageTabs/dataTransfer'
import { MyApplicationPage, MyApprovalPage, WorkorderManagementPage } from 'src/pageTabs/data-change'
import { PersonalCenterPage } from 'src/pageTabs/PersonalCenter'
import { MyApplyRequestPage, MyApplyApprovalPage, MyWorkOrderVisitPage } from 'src/pageTabs/access-request'
import { FlowDesginer } from 'src/pageTabs/flowPages/FlowDesginer'

interface IGetModuleList {
  (): IModuleModel[]
}

interface ICheckModuleExist {
  (key: IModuleMapKeys): number
}

export interface IModuleModel {
  key?: string
  title: string
  per?: string
  component: (() => JSX.Element) | React.FC;
}

interface IModuleService {
  getModuleList: IGetModuleList
  checkModuleExist: ICheckModuleExist
}

export type IModuleMapKeys = keyof typeof MODULE_MAP

const MODULE_MAP: { [key: string]: IModuleModel } = {
  '/my-application':{
    title:'我的申请',
    component: MyApplicationPage,
  },
  '/data_change_mine_approve':{
    title:'我的审批',
    component: MyApprovalPage,
  },
  '/data_change_work_order_management':{
    title:'工单管理',
    component: WorkorderManagementPage,
  },
  '/analysis-overview': {
    title: '审计概览',
    component: AuditOverviewPage,
  },
  '/analysis-execution': {
    title: '语句明细',
    component: AuditExecutionPage,
  },
  '/analysis-operation': {
    title: '操作记录',
    component: AuditOperationPage,
  },
  '/alarm': {
    title: '告警',
    component: AlarmPage,
  },
  '/app-management': {
    title: '应用列表',
    component: AppManagementPage,
  },
  '/connection_management': {
    title: '连接管理',
    component: ConnectionManagementPage,
  },
  '/data-protection': {
    title: '数据保护',
    component: DataProtection,
  },
  '/create-transfer-task': {
    title: '创建任务',
    component: CreateTransferTask
  },
  '/transfer-task-record': {
    title: '任务记录',
    component: TransferTaskRecord
  },
  '/transfer-task-record-details': {
    title: '查看详情',
    component: TransferDetailPage
  },
  '/download': {
    title: '任务中心',
    component: DownloadPage,
  },
  '/my-folder': {
    title: '个人文件夹',
    component: MyFolderPage,
  },
  '/sql-log': {
    title: '执行历史',
    component: SqlLogPage,
  },
  '/mes-management': {
    title: '个人消息',
    component: UserMesPage,
  },
  '/organization/structure': {
    title: '组织架构',
    component: OrganizationPage,
  },
  // '/organization/post': {
  //   title: '岗位管理',
  //   component: PostTable,
  // },
  '/organization/userParameter': {
    title: '用户参数',
    component: UserParameterP,
  },
  '/query': { title: '数据查询', component: QueryPage },
  '/setting': {
    title: '系统设置',
    component: SettingPage,
  },
  '/my-permissions': {
    title: '个人权限',
    component: UserPermsPage,
  },
  '/system/permission-management': {
    title: '权限管理',
    component: SysPermsPage,
  },
  '/system/role-management': {
    title: '角色管理',
    component: SysRolesPage,
  },
  // '/system/maskingInternal-management': {
  //   title: '内置脱敏管理',
  //   component: SysMaskingInternalPage,
  // },
  '/flow': {
    title: '流程',
    component: FlowPage,
  },
  // '/connection-monitor': {
  //   title: '监控',
  //   component: MonitorPage,
  // },
  '/container_monitor':{
    title: '容器监控',
    component: DockerMonitor,
  },
  '/machine_monitor':{
    title: '主机监控',
    component: HostMonitor,
  },
  '/sysmonitor/host': {
    title: '主机监控',
    component: HostMonitor,
  },
  '/sysmonitor/docker': {
    title: '容器监控',
    component: DockerMonitor,
  },
  '/dashboard': {
    title: '权限看板',
    component: DashBoard,
  },
  '/app-secret': {
    title: '应用密钥',
    component: AppSecret,
  },
  '/authorization-management': {
    title: '手动授权',
    component: AuthorizationManagePage,
  },
  '/personal-center': {
    title: '个人中心',
    component: PersonalCenterPage,
  },
  '/mine_apply': {
    title: '我的申请',
    component: MyApplyRequestPage,
  },
  '/mine_approve': {
    title: '我的审批',
    component: MyApplyApprovalPage,
  },
  '/flow_work_order_management': {
    title: '工单管理',
    component: MyWorkOrderVisitPage,
  },
  '/flow_design': {
    title: '自定义流程',
    component: FlowDesginer
  }
}

class ModuleService implements IModuleService {
  public getModuleList() {
    // 0 获取配置文件指定加载的模块
    const { modules } = projectConfig
    // 1 导出模块信息
    const moduleList = modules.map((key: string) => ({
      key,
      ...MODULE_MAP[key],
    }))
    return moduleList
  }

  public getModule(key: IModuleMapKeys) {
    return {
      key,
      ...MODULE_MAP[key],
    }
  }

  public checkModuleExist(key: IModuleMapKeys) {
    const { modules } = projectConfig
    return modules.includes(String(key)) ? 1 : 0
  }

  public isModuleExist(key: IModuleMapKeys): boolean {
    const { modules } = projectConfig
    return modules.includes(String(key)) ? true : false
  }
}

export default new ModuleService()
