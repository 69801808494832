import React, { useEffect } from 'react'
import DataSet from '@antv/data-set'
import { Chart } from '@antv/g2'
import dayjs from 'dayjs'
import * as _ from 'lodash'
import { Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { EmptyChart } from '../Charts'
import { formattedTimeAliasValue, getFormattedAlias } from './utils'
import chartStyles from './chart.module.scss'
import { judgeIsNoData } from '../../utils'

export const ExecutionTimeLineChart = ({
  chartData,
  timeUnit,
  loading
}: {
  chartData: any;
  timeUnit?: any;
  loading: boolean
}) => {
  const history = useHistory()


  const renderExecutionTimeChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
    })
 
    data = new DataSet.DataView()
    .source(data)
    .transform({
      type: 'fill-rows',
      groupBy: ['type'],
      orderBy: ['posX','alias'],
      fillBy: 'order'
    })
    .transform({
       type: 'impute',
       field: 'amount',
       groupBy: ['type'],
       method: 'value',
       value: 0
    })
    .rows.filter(({ type }) => type)
    .sort((a, b) => (dayjs(a.posX).isBefore(b.posX) ? -1 : 1))
    .map(({posX, alias, ...rest}) => {
       //不存在alias的特殊处理
       let newAlias = alias
       const realAlias = alias && alias.split("---");
       if (
        realAlias[0] === "null"
      ) {
      
        newAlias =formattedTimeAliasValue(timeUnit, posX);
      }
      return ({
        alias: newAlias,
        ...rest
         })
    } )

    chart.data(data)
    chart.scale({
      alias: {
        range: [0.02, 0.92],
        formatter: (v) =>{
          return getFormattedAlias(v.toLocaleString())
        } ,
      },
      amount: {
        formatter: (v) => (v || '0') + 'ms',
        nice: true,
      },
    })

    chart.tooltip({
      showCrosshairs: true,
      shared: true,
    })

    chart.line().position('alias*amount').color('type')

    chart
      .point()
      .position('alias*amount')
      .color('type')
      .shape('circle')
      .style({
        stroke: '#fff',
        lineWidth: 1,
      })

    chart.render()
    return chart
  }

  useEffect(() => {
    if (!chartData || !chartData[0] || judgeIsNoData(chartData)) return
    const chart = renderExecutionTimeChart('normal-execution-time', chartData)
    return () => chart.destroy()
  }, [chartData])

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.executionTimeWrapper}>
        <div id="normal-execution-time" className={chartStyles.executionTime}>
          {((!chartData[0] && !loading) || judgeIsNoData(chartData)) && <EmptyChart></EmptyChart>}
        </div>
      </div>
    </Spin>
  )
}
