import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  message,
  Form,
  Input,
  Typography,
  Select,
  Tooltip
} from 'antd'
import { FormOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useRequest } from 'src/hook'
import { setWatermarkSetting, getWatermarkSetting, getWatermarkKey, getWatermarkValue, getPdfWatermarkValue, getExcelWatermarkValue } from 'src/api'
import styles from './index.module.scss'
import 'src/styles/layout.scss'
import { SettingFormContext } from '../SettingFormContext';
import { FormInstance } from 'antd/lib/form';
const { Text } = Typography
const { Option } = Select;


export const WatermarkCard = () => {
  const form = useContext(SettingFormContext) as FormInstance
  const [editing, setEditing] = useState('')
  const [watermarkValue, setWatermarkValue] = useState('')
  const [pdfwatermarkValue, setPdfWatermarkValue] = useState('')
  const [excelwatermarkValue, setExcelWatermarkValue] = useState('')
  const { data: filterData, loading, } = useRequest(() => getWatermarkKey({pageNum: 0, pageSize: 999 }), {
  })
  const { run: saveWaterMark } = useRequest(setWatermarkSetting, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditing('')
      refresh()
      pdfRefresh()
      excelRefresh()
    }
  })
  const { data: dataValue, run: watermarkSettingRun, refresh, loading: wloading } = useRequest(() => getWatermarkSetting('WATERMARK_SETTING'), {
    manual: true,
    onSuccess(val) {
      getwaterValueRun()
    }
  })
  const { data: pdfDataValue, run: pdfwatermarkSettingRun, refresh: pdfRefresh, loading: pdfloading } = useRequest(() => getWatermarkSetting('PDF_WATERMARK_SETTING'), {
    manual: true,
    onSuccess(val) {
      getPdfwaterValueRun()
    }
  })
  const { data: excelDataValue, run: excelwatermarkSettingRun, refresh: excelRefresh, loading: excelloading } = useRequest(() => getWatermarkSetting('EXCEL_WATERMARK_SETTING'), {
    manual: true,
    onSuccess(val) {
      getExcelwaterValueRun()
    }
  })

  const { data: watermarkvalue, run: getwaterValueRun } = useRequest(getWatermarkValue, {
    manual: true
  })

  const { data: pdfwatermarkvalue, run: getPdfwaterValueRun } = useRequest(getPdfWatermarkValue, {
    manual: true
  })

  const { data: excelwatermarkvalue, run: getExcelwaterValueRun } = useRequest(getExcelWatermarkValue, {
    manual: true
  })

  useEffect(() => {
    watermarkSettingRun()
    pdfwatermarkSettingRun()
    excelwatermarkSettingRun()
  }, [watermarkSettingRun, pdfwatermarkSettingRun, excelwatermarkSettingRun])

  useEffect(() => {
    setWatermarkValue(dataValue)
  }, [dataValue])

  useEffect(() => {
    setPdfWatermarkValue(pdfDataValue)
  }, [pdfDataValue])

  useEffect(() => {
    setExcelWatermarkValue(excelDataValue)
  }, [excelDataValue])

  const waterMarkValueMap = new Map([
    ["WATERMARK_SETTING", watermarkValue],
    ["PDF_WATERMARK_SETTING", pdfwatermarkValue],
    ["EXCEL_WATERMARK_SETTING", excelwatermarkValue],
  ])

  const setSave = (val: any, stringName: string) => {
    saveWaterMark({
      type: stringName,
      value: waterMarkValueMap.get(stringName)
    })
  }

  const SvgTextBg = (props: any) => {
    const { text = 'waterMark', fontSize = 16, fillOpacity = '0.6', fillColor = '#666', value } = props
    const res = `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="180px" height="120px" viewBox="0 0 180 180">
          <text x="-100" y="-30" fill='${fillColor}'  transform = "translate(100,100)rotate(20,10,60)" fill-opacity='${fillOpacity}' font-size='${fontSize}'> ${value && value.length ? value.join(' ') : value}</text>
        </svg>`

    const blob = new Blob([res], {
      type: 'image/svg+xml',
    })

    const url = URL.createObjectURL(blob)

    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${url})`,
          top: '-40px',
          left: 0,
          zIndex: 999,
          pointerEvents: 'none', //点击穿透
        }}
      ></div>
    )
  }
  return (
    <section className="cq-new-card flow-card" id="LogoImageCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title" style={{ flex: 1 }}>
          水印设置
        </h3>
      </div>
      <section className="card__content" style={{ display: 'flex' }}>
        <Card
          className={styles.settingCardContent}
          bordered={false}
          style={{ flex: '50%' }}
          loading={wloading || pdfloading || excelloading}
        >
          <Form.Item
            label={
              <>
                水印设置
                <Tooltip title="支持最多选择三项参数">
                  <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            }
            style={{ height: '200px' }}
          >
            <Form.Item
              name={'watermarkName'}
              noStyle
              hidden={!(editing === 'watermark')}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(e) => {
                  if (e.length > 3) {
                    e.pop()
                  }
                  setWatermarkValue(e.toString())
                }}
                defaultValue={dataValue ? dataValue.split(',') : []}
                ref={function (input) {
                  if (input != null) {
                    input.focus();
                  }
                }}
                onBlur={() => {
                  setSave(watermarkValue, 'WATERMARK_SETTING')
                }}
              >
                {
                  !!filterData && filterData?.content.length && filterData?.content.map((item: any, index: number) => {
                    return <Option key={index} label={'${' + item.nameSpace + '.' + item?.tagName + '}'} value={'${' + item.nameSpace + '.' + item?.tagName + '}'} >{'${' + item.nameSpace + '.' + item?.tagName + '}'}</Option>
                  })
                }
              </Select>
              {/* <Input
                style={{ width: '100%' }}
                ref={function (input) {
                  if (input != null) {
                    input.focus();
                  }
                }}
                onBlur={(e) => {
                  setSave(e.target.value, 'WATERMARK_SETTING')
                }}
              /> */}
            </Form.Item>
            {editing !== 'watermark' && (
              <div style={{ display: 'flex' }}> <Text style={{ width: '300px', display: 'inline-block' }}>{dataValue}</Text> <FormOutlined onClick={() => {
                setEditing('watermark')
              }} /></div>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                PDF水印设置
                <Tooltip title="支持最多选择三项参数">
                  <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            }
            style={{ height: '200px' }}
          >
            <Form.Item
              name={'pdfWatermarkName'}
              noStyle
              hidden={!(editing === 'pdfWatermark')}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(e) => {
                  if (e.length > 3) {
                    e.pop()
                  }
                  setPdfWatermarkValue(e.toString())
                }}
                defaultValue={pdfDataValue ? pdfDataValue.split(',') : []}
                ref={function (input) {
                  if (input != null) {
                    input.focus();
                  }
                }}
                onBlur={() => {
                  setSave(pdfwatermarkValue, 'PDF_WATERMARK_SETTING')
                }}
              >
                {
                  !!filterData && filterData?.content.length && filterData?.content.map((item: any, index: number) => {
                    return <Option key={index} label={'${' + item.nameSpace + '.' + item?.tagName + '}'} value={'${' + item.nameSpace + '.' + item?.tagName + '}'} >{'${' + item.nameSpace + '.' + item?.tagName + '}'}</Option>
                  })
                }
              </Select>
            </Form.Item>
            {editing !== 'pdfWatermark' && (
              <div style={{ display: 'flex' }}> <Text style={{ width: '300px', display: 'inline-block' }}>{pdfDataValue}</Text> <FormOutlined onClick={() => {
                setEditing('pdfWatermark')
              }} /></div>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                EXCEL水印设置
                <Tooltip title="支持最多选择三项参数">
                  <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            }
            style={{ height: '200px' }}
          >
            <Form.Item
              name={'excelWatermarkName'}
              noStyle
              hidden={!(editing === 'excelWatermark')}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                onChange={(e) => {
                  if (e.length > 3) {
                    e.pop()
                  }
                  setExcelWatermarkValue(e.toString())
                }}
                defaultValue={excelDataValue ? excelDataValue.split(',') : []}
                ref={function (input) {
                  if (input != null) {
                    input.focus();
                  }
                }}
                onBlur={() => {
                  setSave(excelwatermarkValue, 'EXCEL_WATERMARK_SETTING')
                }}
              >
                {
                  !!filterData && filterData?.content.length && filterData?.content.map((item: any, index: number) => {
                    return <Option key={index} label={'${' + item.nameSpace + '.' + item?.tagName + '}'} value={'${' + item.nameSpace + '.' + item?.tagName + '}'} >{'${' + item.nameSpace + '.' + item?.tagName + '}'}</Option>
                  })
                }
              </Select>
            </Form.Item>
            {editing !== 'excelWatermark' && (
              <div style={{ display: 'flex' }}> <Text style={{ width: '300px', display: 'inline-block' }}>{excelDataValue}</Text> <FormOutlined onClick={() => {
                setEditing('excelWatermark')
              }} /></div>
            )}
          </Form.Item>
        </Card>
        <Card
          style={{ flex: '50%' }}
          bordered={false}
          className={styles.settingCardContent}
          loading={wloading || pdfloading || excelloading}
        >
          <div
            style={{ height: '200px', margin: '10px 0 15px 0', background: '#F7F9FC' }}
          >
            <label>预览：</label>
            <div style={{ height: '100%', position: 'relative' }}>
              <SvgTextBg  {...{ value: watermarkvalue }} />
            </div>
          </div>
          <div
            style={{ height: '200px', background: '#F7F9FC' }}
          >
            <label>预览：</label>
            <div style={{ height: '100%', position: 'relative' }}>
              <SvgTextBg  {...{ value: pdfwatermarkvalue }} />
            </div>
          </div>
          <div
            style={{ height: '200px', background: '#F7F9FC' }}
          >
            <label>预览：</label>
            <div style={{ height: '100%', position: 'relative' }}>
              <SvgTextBg  {...{ value: excelwatermarkvalue }} />
            </div>
          </div>
        </Card>
      </section>
    </section>
  )
}
