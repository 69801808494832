import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Form, Input, message, Select } from 'antd'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  editApp,
  getAllUsers,
  getAppLanguageList,
  AppLanguageOption,
} from 'src/api'
import { FormLayout } from 'src/constants'
import { getAndSetAdminAppList } from './appManagementPageSlice'

export const EditAppModal = () => {
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const { EditApp } = useSelector((state) => state.modal)
  const { selectedApp } = useSelector((state) => state.appManagement)

  const [editAppForm] = Form.useForm()

  const handleSubmitEditApp = () => {
    editAppForm.validateFields().then((values) => {
      setConfirmLoading(true)
      editApp({ ...selectedApp, ...values })
        .then(() => {
          message.success('应用编辑成功')
          dispatch(getAndSetAdminAppList())
          dispatch(hideModal('EditApp'))
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  const [userList, setUserList] = useState<any[]>([])

  const userSelectOptions = userList.map((user) => {
    const { userId, userName } = user
    return {
      label: `${userName}（${userId}）`,
      value: userId,
    }
  })

  const getAndSetUserList = () => {
    getAllUsers().then(setUserList)
  }

  const [languageList, setLanguageList] = useState<AppLanguageOption[]>([])
  const languageSelectOptions = languageList.map(({ name, value }) => ({
    label: name,
    value,
  }))

  const getAndSetLanguageList = () => {
    getAppLanguageList().then(setLanguageList)
  }

  useEffect(() => {
    getAndSetUserList()
    getAndSetLanguageList()
  }, [])

  useEffect(() => {
    if (!EditApp) return
    editAppForm.setFieldsValue(selectedApp)
  }, [editAppForm, selectedApp, EditApp])

  if (!selectedApp) return null

  return (
    <UIModal
      title="编辑应用"
      visible={EditApp}
      onCancel={() => dispatch(hideModal('EditApp'))}
      onOk={() => handleSubmitEditApp()}
      afterClose={() => editAppForm.resetFields()}
      width={640}
      confirmLoading={confirmLoading}
    >
      <Form form={editAppForm} {...FormLayout}>
        <Form.Item
          name="appName"
          label="应用名称"
          rules={[{ required: true, message: '请输入应用名称' }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item name="appCode" label="应用 ID">
          <Input disabled></Input>
        </Form.Item>
        <Form.Item name="desc" label="应用描述">
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="appAdminId"
          label="负责人"
          rules={[{ required: true, message: '请输入负责人' }]}
        >
          <Select
            options={userSelectOptions}
            optionFilterProp="label"
            showSearch
          ></Select>
        </Form.Item>
        <Form.Item
          name="dbaId"
          label="DBA"
          rules={[{ required: true, message: '请输入 DBA' }]}
        >
          <Select
            options={userSelectOptions}
            optionFilterProp="label"
            showSearch
          ></Select>
        </Form.Item>
        <Form.Item name="hosts" label="主机列表">
          <Select mode="tags" disabled></Select>
        </Form.Item>
        <Form.Item name="language" label="应用语言">
          <Select options={languageSelectOptions} disabled></Select>
        </Form.Item>
        <Form.Item name="labels" label="标签">
          <Select mode="tags" allowClear notFoundContent="请输入标签"></Select>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
