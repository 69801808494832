import React from 'react'
import { useSelector } from 'src/hook'
import { Form, Radio } from 'antd'
import { FlowModalForm } from './FlowModalForm'
import {
  FormItemReason,
  FormItemApplicant,
  FormItemCorrectionSql,
  FormItemRollbackSql,
  FormItemApprover,
  ElementTreeSelectSingle,
} from '../flowFormItems'
import { FormFieldsDataCorrection } from 'src/types'
import { launchFlowApply } from 'src/api'
import styles from '../flowForm.module.scss'
import { useForm } from 'antd/lib/form/Form'

export const ModalFormDataCorrection = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const flowType = 'dataCorrection'

  const [form] = useForm<FormFieldsDataCorrection>()

  const onResetApprover = () => {
    form && form.setFieldsValue({approveUserId:''})
  }
  
  return (
    <FlowModalForm<FormFieldsDataCorrection>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return
        const {
          approveUserId,
          applyReason,
          elements,
          executeText,
          executeType,
          rollbackText,
        } = values
        const {
          connectionId,
          connectionType: dataSourceType,
          nodeType,
        } = elements?.[0]
        const nodePathList = elements.map(({ nodePath }) => nodePath)
        const nodePathWithTypeList = elements.map(({ nodePathWithType }) => nodePathWithType)

        return launchFlowApply({
          applyUserId,
          email,
          applyReason,
          flowType,
          connectionId,
          dataSourceType,
          nodePathList,
          nodePathWithTypeList,
          nodeType,
          firstApproveUserId: approveUserId,
          executeText,
          executeType,
          rollbackText,
        })
      }}
    >
      <FormItemApplicant />
      <Form.Item
        label="数据库元素"
        name="elements"
        rules={[{ required: true, message: '请选择一个数据库' }]}
      >
        <ElementTreeSelectSingle onChange={onResetApprover} />
      </Form.Item>
      <FormItemCorrectionSql />
      <FormItemRollbackSql />
      <Form.Item
        label="订正执行时机"
        name="executeType"
        initialValue="auto"
        rules={[{ required: true, message: '请选择订正执行时机' }]}
      >
        <Radio.Group className={styles.radioGroup}>
          <Radio value="auto">审批通过后自动执行</Radio>
          <Radio value="manual">手动执行</Radio>
        </Radio.Group>
      </Form.Item>
      <FormItemApprover />
      <FormItemReason />
    </FlowModalForm>
  )
})
