/**
 * 新建分组(可新增连接)
 */
import React, { useEffect, useState, useMemo } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames'
import * as _ from 'lodash';
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { Form, Checkbox, Modal, Switch, Input, message, Row, Col, Tooltip, Select } from 'antd'
import { useRequest } from 'src/hook'
import {
  queryCanAddConnections,
  createConnectionGroup,
  addConnection,
  queryGroupConnections,
  removeConnection,
  modifyConnectionGroupName
} from 'src/api'
import { stringByteLength, customLengthWord } from 'src/util'

import styles from './index.module.scss'

interface PropsI {
  groupInfo?: any
  visible: boolean
  [p: string]: any
}

const AddGroupModal = (props: PropsI) => {
  const { visible, groupInfo, handleClose, refresh } = props
  const { groupId, groupName, modalType, connectionType  } = groupInfo
  const isEdit = groupInfo && groupInfo.modalType !== 'createGroup';
 
  const [form] = Form.useForm()
  const [checkedConnectionIds, setCheckedConnectionIds] = useState<CheckboxValueType[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)

  // 获取连接列表
  const { data: connectionList = [], run: getConnections } = useRequest((params: any) => {
    let actionRequest: any = queryCanAddConnections;
    if (modalType === 'moveoutConnection') {
      actionRequest = queryGroupConnections;
    }
    return actionRequest(params)
  }, {
    manual: true,
    formatResult: (data: any) => {
      let connectionList: any[] = [];
      if (modalType !== 'moveoutConnection') {
        connectionList = data;
      } else {
        connectionList = data?.connectionList || []
      }

      return connectionList?.map((item: any) => {
        return {
          ...item,
          label: item?.connectionName,
          value: item?.connectionId,
        }
      })
    }
  })

  //添加连接
  const { run: runAddConnections } = useRequest(addConnection, {
    manual: true,
    onSuccess: () => {
      message.success("添加连接成功");
      handleClose();
      refresh();
    }
  });
  //移出连接
  const { run: runremoveConnections } = useRequest(removeConnection, {
    manual: true,
    onSuccess: () => {
      message.success("移出连接成功");
      handleClose();
      refresh();
    }
  });
  //修改分组名称
  const {run: renameGroupName } = useRequest(modifyConnectionGroupName, { 
    manual: true,
    onSuccess: () => {
      message.success('修改成功');
      handleClose();
      refresh();
    }
  })

  useEffect(() => {
    if (modalType === '') return;
    // 新增组|添加连接 --获取不在分组内的连接
    if (connectionType && !['moveoutConnection', 'modifyGroupName'].includes(modalType)) {
      getConnections(connectionType)
    } else if (groupId && modalType === 'moveoutConnection') {
      //组内连接
      getConnections(groupId)
    }
  }, [connectionType, groupId, modalType])

  const onChangeOptionStatus = (e: CheckboxChangeEvent) => {
    let cloneConnectionIds = _.cloneDeep(checkedConnectionIds);

    if (e.target.checked) {
     cloneConnectionIds = cloneConnectionIds.concat([e.target.value]);
    }else {
      cloneConnectionIds = cloneConnectionIds.filter(i => i !== e.target.value);
    }
  
    form.setFieldsValue({ connectionIds: cloneConnectionIds });
    setCheckedConnectionIds(cloneConnectionIds);
    setIndeterminate(!!cloneConnectionIds?.length && cloneConnectionIds?.length < connectionList.length)
    setSearchValue('')
  }

  const onCheckAllConnections = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const connectionIds = connectionList?.map((i: any) => i?.connectionId)

      setCheckedConnectionIds(connectionIds)
      form.setFieldsValue({ connectionIds: connectionIds })
    } else {

      form.setFieldsValue({ connectionIds: [] })
      setCheckedConnectionIds([])
    }
    setIndeterminate(false)
  }

  // 分组保存
  const handleOk = () => {
    form.validateFields().then((values) => {
      if (isEdit) {
        const params: any = {
          id: groupId,
          ...values,
          connectionIds: checkedConnectionIds,
          dataSourceType: groupInfo?.connectionType
        };
        setLoading(true);
        if (modalType === 'addConnection') {
          runAddConnections(params)
        }else if (modalType === 'modifyGroupName') {
          renameGroupName({id: groupId, groupName: values?.groupName})
        } else {
          //@ts-ignore
          runremoveConnections(checkedConnectionIds)
        }

        return;
      } else {
        // 新增组
        const groupName = values.groupName?.trim();
        if (!groupName) {
          return message.error("组名不能为空！");
        }
        const params: any = {
          datasource: connectionType,
          ...values,
          connectionIds: checkedConnectionIds
        };
        setLoading(true);
        createConnectionGroup(params)
          .then((_) => {
            message.success("新增成功");
            handleClose();
            refresh();
          })
          .catch((err) => {
            console.log("新建分组失败", err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
  }

  const renderModalTitle = useMemo(() => {
  
    switch(modalType) {
      case 'modifyGroupName':
        return '修改分组名称';
      case 'moveoutConnection':
        return '移出连接';
      case 'addConnection':
        return '添加连接';
       default:
        return '新建分组'     
    }
  },[isEdit, modalType])

  const selectOptions = useMemo(() => {
    let cloneConnections = _.cloneDeep(connectionList)
    return searchValue ? cloneConnections.filter(i => i?.label.toLowerCase().includes(searchValue.toLowerCase())) : cloneConnections
  }, [searchValue, connectionList])

  return (
    <Modal
      title={renderModalTitle}
      visible={visible}
      onCancel={handleClose}
      onOk={handleOk}
      okButtonProps={{ loading }}
      className={styles.addGroupModal}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        initialValues={{
          groupName: groupName,
        }}
        labelCol={{span: 4}}
      >
        <Form.Item
          label="分组名称"
          name="groupName"
          rules={[{ required: true, message: "请输入分组名" }]}
        >
          <Input placeholder="请输入" disabled={isEdit && (isEdit && modalType !== 'modifyGroupName')} />
        </Form.Item>
        {
          modalType !== 'modifyGroupName' && 
          <Form.Item
            label="选择连接"
            name="connectionIds"
            rules={[{ required: isEdit ? true: false, message: "请选择连接" }]}
          >
            <Select
              showSearch
              onSearch={(val: string) => setSearchValue(val)}
              mode="multiple"
              allowClear
              maxTagCount={3}
              onClear={() => {
                setCheckedConnectionIds([]);
                setIndeterminate(false);
              }}
              onBlur={() => setTimeout(() => setSearchValue(''),1000)}
              onChange={(value:string[]) => {
            
              form.setFieldsValue({ connectionIds: value });
              setCheckedConnectionIds(value);
              setIndeterminate(!!value?.length && value?.length < connectionList?.length)
              }}
              placeholder="请选择"
              optionLabelProp="label"
              optionFilterProp='label'
              options={selectOptions}
              dropdownRender={() => selectOptions.length ? (
                <div style={{ padding: '6px 12px' }} >
                  {
                    !searchValue &&
                    <>
                      <Checkbox
                        checked={connectionList.length === checkedConnectionIds.length}
                        indeterminate={indeterminate}
                        onChange={onCheckAllConnections} style={{ marginBottom: 5 }}>全选</Checkbox>
                      <br></br>
                    </>
                  }
                  {
                    selectOptions?.map(option => (
                      <div key={option?.value}>
                        <Checkbox 
                          checked={checkedConnectionIds?.includes(option?.value)}
                          onChange={onChangeOptionStatus}
                          value={option?.value}
                          >
                          {option?.label}
                        </Checkbox>
                      </div>
                    ))
                  }
                </div>
              )
                : <span style={{padding: '10px 10px'}}>暂无数据</span>
              }
            />
          </Form.Item>
        }
        {
          modalType === 'addConnection' &&
          <Form.Item
            labelCol={{span: 10}}
            initialValue={false}
            label={
              <Tooltip title='开启后新添加进分组的连接会继承分组此前的相关设置，包括权限设置、数据保护设置等'>
                是否继承分组上相关设置 <QuestionCircleOutlined />
              </Tooltip>
            }
            name="inheritToken"
          >
            <Switch checkedChildren='启用' unCheckedChildren='禁用' />
          </Form.Item>
        }
      </Form>
    </Modal>
  );
}
export default AddGroupModal

