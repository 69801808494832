import { TreeNode } from 'src/api';

export const stringByteLength = (string: string) => { 
    if (!string) { 
        return 0
    }
    let length = 0
    Array.from(string).forEach( (char: string) => {
        if (char.charCodeAt(0) > 255) {
        // 字符编码大于255，说明是双字节字符
            length += 2
        } else {
            length++
        }
    })
    return length
}

export const customLengthWord = (string: string, length: number, start?: number) => {
    let curLength = 0, result = '', startIndex = start || 0
    Array.from(string).forEach((char: string, index) => {
        if (index < startIndex || curLength > length) {
          return 
        }
        if (char.charCodeAt(0) > 255) {
            // 字符编码大于255，说明是双字节字符
            curLength += 2
        } else {
            curLength++
        }
        result += char
    })
    return result
}

export const treeToArray = (tree: any[]) => {
  let arr: any[] = []
  const flatTree = (data: any) => {
    data?.forEach((i: any) => {
      flatTree(i?.children)
      const item = { ...i }
      delete item?.children
      arr.push(item)
    })
  }
  flatTree(tree)
  return arr
}

// 构造树结构
export const generateTree = (data: any[]) => {
    const allId = data
      ?.map((i) => i.id)
      ?.filter((i, index, arr) => arr.indexOf(i) === index)
    const allParentId = data
      ?.map((i) => i.parentId)
      ?.filter((i, index, arr) => arr.indexOf(i) === index)
    
    const validateParentId = (item:any) => { 
      return !!data?.filter((i: any) => { 
        return i?.parentId===item?.id && item?.nodeType !== i?.nodeType
      })?.length
    }
      
    const filterData = data.filter((item, _, arr) => {
      // 自身是父级（且id和parentId不能重复）
      if (allParentId.includes(item.id) && validateParentId(item)) {
        item.children = arr.filter((i) => i.parentId === item.id)
      }
      // 没有父级
      if (!allId.includes(item.parentId) || !item.parentId) {
        return true
      }
      return false
    })
  
    const formatTree = (data: any[]): any[] =>
      data.map((item: any) => {
        const {connectionType,nodeType, nodeName, connection ={}} =  item;
        item.key = item?.nodePath  // 唯一key
        item.title = item.nodeName

        item.connectionType =  item?.nodeType === 'datasource' ? nodeName :  connection?.connectionType;

        if (item.children) {
          item.children = formatTree(item.children);
        }
        return { ...item }
      })
    return formatTree(filterData)
}

export const matchKeyword = (target = '', substring = '') => {
    if (!target) return false
    return target.toLowerCase().indexOf(substring.toLowerCase()) > -1
}

export const getConnExpandNodeNeedPathKeys = (path: string,needSplice?: boolean) => {
    let result = [];
    const parts = path?.split('/') || [];
    let current = '';
    for (let i = 1; i < parts.length; i++) {
      current += '/' + parts[i];
      result.push(current);
    }
    if (needSplice) {
      result.splice(0, 1); // 删除第一个空字符串
    }
   
    return result;
  } 

 export const getTargetNode = (treeData: TreeNode[],nodePath: string, ) => {
    const queue = [...treeData]
    while (queue.length) {
      const currentNode = queue.shift()
      if (!currentNode) return
      if (currentNode.nodePath === nodePath) {
        return currentNode
      }
      if (currentNode.children) {
        queue.push(...currentNode.children)
      }
    }
  }