import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'

export type FilePath = string
interface MyFolderState {
  pwd: { path: FilePath }
  selectedFiles: FilePath[]
  // todo: selectedFiles 扩展为 files 而非仅 file paths
  multipleMode: boolean
  treeTag: any,
  singleFile: any,
  clickTreeOrlist: string
  serachValue: string
  PopupVisible: boolean
  connectionName: any // 第三级目录及以下对应的文件名
}

const initialState: MyFolderState = {
  pwd: { path: '' },
  selectedFiles: [],
  multipleMode: false,
  treeTag: '',
  singleFile: {},
  clickTreeOrlist:'',
  serachValue: '',
  PopupVisible: false,
  connectionName: ''
}

export const myFolderSlice = createSlice({
  name: 'myFolder',
  initialState,
  reducers: {
    setPwd: (state, action: PayloadAction<FilePath>) => {
      const path = action.payload
      state.selectedFiles = []
      state.pwd = { path }
    },
    setSelectedFiles: (state, action: PayloadAction<FilePath[]>) => {
      const selectedFiles = action.payload
      state.selectedFiles = selectedFiles
    },
    clickFile: (state, action: PayloadAction<FilePath>) => {
      const path = action.payload
      if (!state.multipleMode) {
        state.selectedFiles = [path]
        return
      }
      const clicked = state.selectedFiles.some((filePath) => filePath === path)
      if (clicked) {
        state.selectedFiles = state.selectedFiles.filter(
          (filePath) => filePath !== path,
        )
      } else {
        state.selectedFiles.push(path)
      }
    },
    setMultipleMode: (state, action: PayloadAction<boolean>) => {
      state.multipleMode = action.payload
    },
    setTreeTag: (state, action: PayloadAction<string | number>) => {
      state.treeTag = action.payload
    },
    setSingleFile: (state, action: PayloadAction<any>) => {
      state.singleFile = action.payload
    },
    setClickTreeOrlist: (state, action: PayloadAction<string>) => {
      state.clickTreeOrlist = action.payload
    },
    setSerachValue: (state, action: PayloadAction<string>) => {
      state.serachValue = action.payload
    },
    setPopupVisible: (state, action: PayloadAction<boolean>) => {
      state.PopupVisible = action.payload
    },
    setConnectionName: (state, action: PayloadAction<any>) => {
      state.connectionName = action.payload
    },
  },
})

export const myFolderReducer = myFolderSlice.reducer

export const {
  setPwd,
  setSelectedFiles,
  clickFile,
  setMultipleMode,
  setTreeTag,
  setSingleFile,
  setClickTreeOrlist,
  setSerachValue,
  setPopupVisible,
  setConnectionName
} = myFolderSlice.actions

export const resetMyFolder = (): AppThunk => (dispatch) => {
  dispatch(setSelectedFiles([]))
  dispatch(setPwd('/'))
}
