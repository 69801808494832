import type { ColumnsType } from 'antd/es/table';



export interface BusinessWarnConfigColumnsDataType {
  key: string;
  type: string;
  warnCondition: number | string;
  alarmMethod: string;
  targetPerson: string;
  userId: string[];
  status?: boolean;
  operation?: any;
}
export const BusinessWarnConfigColumns: ColumnsType<BusinessWarnConfigColumnsDataType> = [
  {
    title: '业务告警类型',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '告警条件',
    dataIndex: 'warnCondition',
    key: 'warnCondition',
  },
  {
    title: '告警方式',
    dataIndex: 'alarmMethod',
    key: 'alarmMethod',
  },
  {
    title: '消息接收人类型',
    dataIndex: 'targetPerson',
    key: 'targetPerson',
  }
]

// 告警类型 枚举
export const typeEnum = {
  CPU_USAGE: "CPU使用率",
  MEMORY_USAGE: "内存使用率",
  SYSTEM_ERROR: "系统异常",
  UPLOAD_FILE_SIZE: "文件上传大小",
  // DOWNLOAD_FILE_SIZE: "文件读取大小",
  IMPORT_TASK_COUNT: "导入任务数",
  EXPORT_TASK_COUNT: "导出任务数",
  LICENSE_REMAINING: "license剩余天数",
  OVER_PERMISSION: "越权操作",
  HIGH_RISK: "高危操作",
  SLOW_SQL: "慢SQL",
  BATCH_EXECUTE: "批量执行",
  SQL_CHECK: "SQL审核",
}

// 告警方式 枚举
interface Data {
  [key: string]: string;
  PHONE: string;
  SYSTEM: string;
  EMAIL: string;
}
export const alarmMethodsEnum: Data = {
  PHONE: "手机短信",
  SYSTEM: "系统消息",
  EMAIL: "邮件"
}

// 消息接收人类型 枚举
interface TargetPersonEnumData {
  [key: string]: string;
  PERSONAL: string;
  DEPT: string;
  CONN: string;
}
export const TargetPersonEnum: TargetPersonEnumData = {
  PERSONAL: "个人",
  DEPT: "部门负责人",
  CONN: "连接管理员"
}

// 告警类别
export const alarmTypeEnum = {
  0: "系统告警",
  1: '业务告警',
}

// 业务告警配置-业务告警类型
export const BusinessAlarmTypeOption = [
  {label: "高危操作", value:"HIGH_RISK"},
  {label: "越权操作", value: "OVER_PERMISSION"},
  {label: "慢SQL", value:"SLOW_SQL"},
  {label: "批量执行", value: "BATCH_EXECUTE"},
  {label: "SQL审核", value: "SQL_CHECK"}
]

// 系统告警配置 - 高危等级
export const highRiskClassOptions = [
  {
    label: "高",
    value: "高"
  },
  {
    label: "中",
    value: "中"
  },
  {
    label: "低",
    value: "低"
  }
]

// 系统告警配置 - 审核等级
export const auditClassOptions = [
  {
    label: "error",
    value: "error"
  },
  {
    label: "warn",
    value: "warn"
  },
  {
    label: "notice",
    value: "notice"
  }
]



export const SMSChannelEnum = [
  {
    label: '阿里云',
    value: 'alibaba'
  },
  {
    label: '腾讯云',
    value: 'tencent'
  },
  {
    label: '华为云',
    value: 'huawei'
  },
  {
    label: '梦网云',
    value: 'monyun'
  },
  {
    label: '自定义',
    value: 'CUSTOM'
  }
]