import React, { useEffect, useState } from 'react'

import { useRequest } from 'src/hook'

import { getSysMonitorUrl } from './api'

export const DockerMonitor = () => {
  const [canAccess, setCanAccess] = useState(false)

  const { data } = useRequest(getSysMonitorUrl, { defaultParams: ['docker'] })
  const { addr } = data || {};

  useEffect(() => {
    if (addr) {
      const realAddr = addr.substr(0, addr.indexOf('?'))

      fetch(realAddr).then(res => {
        if (res.status === 200) {
          setCanAccess(true)
        }
      })
    }
  }, [addr])

  return (
    <div>
      {
        canAccess ? <iframe
          id="docker"
          title="docker"
          src={data?.addr}
          style={{ width: '100%', height: 'calc(100vh - 48px)' }}
          frameBorder="0"
        ></iframe> : <>服务器出错了……请稍后再试</>
      }
    </div>
  )
}
