import React, { FC, useEffect, useReducer, useRef, useState } from 'react'
import monaco, { editor } from 'monaco-editor'
import type { IDisposable } from 'monaco-editor'
import { Button } from 'antd'
import { format } from 'sql-formatter'
import styles from './SimpleEditor.module.scss'
interface SimpleEditorProps {
  id: string
  height?: number
  value?: string
  readOnly?: boolean
  onChange?: (newValue: string) => void
  theme?: string
}

const initRecord = {
  customCaseConversion: true,
}
function simpleReducer(state: any, action: { type: any; payload: any }) {
  switch (action.type) {
    case 'update':
      return {
        ...state,
        ...action.payload,
      }
  }
}

export const SimpleEditor: FC<SimpleEditorProps> =({
  id,
  height = 270,
  value: valueProps,
  readOnly = false,
  onChange,
  theme
}) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor>()
  const [record, dispatchRecord] = useReducer(simpleReducer, initRecord)
  const subscriptionRef = useRef<IDisposable>()
  const [isEditorReady, setIsEditorReady] = useState<Boolean>(false)

  // onChange
  useEffect(() => {
    if (isEditorReady && onChange) {
      subscriptionRef.current?.dispose()
      subscriptionRef.current = editorRef.current?.onDidChangeModelContent(
        (event) => {
          onChange(editorRef.current!.getValue())
        },
      )
    }
  }, [isEditorReady, onChange])

  useEffect(() => {
    const myEditor = editor.create(document.getElementById(id) as HTMLElement, {
      value: ``,
      language: 'sql',
      automaticLayout: true,
      readOnly,
      minimap: { enabled: false },
      theme,  // 手动设置主题
    })
    editorRef.current = myEditor
    setIsEditorReady(true)
  }, [readOnly, theme])

  useEffect(() => {
    if (valueProps === editorRef.current!.getValue()) return
    triggerChange(valueProps)
  }, [valueProps])

  const triggerChange = (newValue: string | undefined) => {
    let param = newValue ?? ''
    editorRef.current?.setValue(param)
    // if (typeof onChange === 'function') {
    //   onChange(param)
    // }
  }

  const menuList = [
    {
      name: '大小写转换',
      id: 'customCaseConversion',
      hide: false,
      action: () => {
        let result = editorRef.current?.getValue().toUpperCase()
        if (!record.customCaseConversion) {
          result = editorRef.current?.getValue().toLowerCase()
        }
        dispatchRecord({
          type: 'update',
          payload: {
            customCaseConversion: !record.customCaseConversion,
          },
        })
        triggerChange(result)
      },
    },
    {
      name: '格式化',
      id: 'customFormat',
      hide: false,
      action: () => {
        let result = format(editorRef.current?.getValue() as string, {
          language: 'sql',
        })
        triggerChange(result)
      },
    },
  ]

  return (
    <div className={styles['editor-wrap']} id={styles['simple-editor']}>
      <section className={styles['editor-actions']}>
        {/* actions */}
        {menuList.map((item) => {
          return (
            <Button type="text" key={item.id} onClick={item.action}>
              {item.name}
            </Button>
          )
        })}
      </section>
      <div
        id={id}
        className={styles.container}
        style={{ minHeight: `${height}px` }}
      ></div>
    </div>
  )
}

export default SimpleEditor
