import React, { FC } from 'react'
import { Card, Descriptions } from 'antd'
import classnames from 'classnames'
import { applyStatusMap } from '../../constants'
import styles from '../index.module.scss'

interface ListProps {
  remarkData: []
}

export const ApprovalRemarksPag: FC<ListProps> = (props) => {
  const { remarkData} = props
  return (
    <Card
      title= "审批信息"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27)}
    >
      {
        remarkData && remarkData?.length ? remarkData?.map((item: any, index: number) => {
          return <Descriptions key={index}>
            <Descriptions.Item label={item?.approvedUser}> {applyStatusMap[item?.approvedResult]}</Descriptions.Item>
            <Descriptions.Item label="备注">{item?.approvedComment ? item?.approvedComment : '-'}</Descriptions.Item>
          </Descriptions>
        }) : ''
      }

    </Card>
  )
}
