import React, { useState } from 'react'
import { Card, Row, Col, Table, message, Space, Tooltip } from 'antd'
import {} from 'src/'

import { LinkButton } from 'src/components'
import {
  getAllMes,
  putReadMes,
  putReadMess,
  checkUserFlowRole,
  batchDelMessagesPost,
} from 'src/api'
import { useRequest, useSelector, useDispatch } from 'src/hook'
import { useHistory } from 'react-router-dom'
import { getMessages } from 'src/store/extraSlice/useMesSlice'
import { ModalFlowDetail } from 'src/pageTabs/flowPages/flowDetails'
import { Status } from './Status'
import type { TablePaginationConfig } from 'antd/lib/table'

interface MesType {
  [key: string]: {
    name: string
    opName: string
    opClick: (id: number, record: any, msgId?: number) => void
  }
}

interface Message {
  id: number
  sender: string
  userId: string
  message: string
  msgId: number
  msgType: string
  msgSourceType: string
  createdAt: string
  updatedAt: string
  status: string
}

export const UserMesPage = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])
  const userId: string =
    useSelector((state) => state.login.userInfo.userId) || ''
  const history = useHistory()
  const dispatch = useDispatch()

  /* 流程表单 */
  const [flowVisible, setFlowVisible] = useState<boolean>(false)
  const [flowMode, setFlowMode] = useState<'application' | 'approval'>(
    'approval',
  )
  const [flowId, setFlowId] = useState(null)
  // 流程详情 当前 流程 record记录
  const [activeRecord, setActiveRecord] = useState<any>()

  const {
    data: dataSource,
    loading,
    run: getMes,
    refresh: refreshMes,
  } = useRequest(getAllMes, {
    // pageNum, pageSize
    defaultParams: [1, 10],
    onSuccess: () => {
      // 每次刷新列表时重置选中项
      setSelectedRowKeys([])
    },
  })

  const mesTypeMap: MesType = {
    EXPORT: {
      name: '导出任务',
      opName: '查看',
      opClick: async (id: number) => {
        await putReadMes(id)
        dispatch(getMessages(userId))
        history.push('/download')
      },
    },
    PROCESS: {
      name: '流程消息',
      opName: '查看',
      opClick: async (id: number, msgId: any, record: any) => {
        await putReadMes(id)
      },
    },
    AUDIT_WARNING: {
      name: '审计告警',
      opName: '查看',
      opClick: async (id: number, msgId) => {
        await putReadMes(id)
        dispatch(getMessages(userId))
        // TODO: 审计详情表格
        refreshMes()
      },
    },
    ALARM: {
      name: '告警通知',
      opName: '',
      opClick: async () => {},
    },
  }

  const columns = [
    {
      title: '发送人',
      dataIndex: 'sender',
    },
    {
      title: '消息类型',
      dataIndex: 'msgSourceType',
      render: (t: string) => mesTypeMap[t]?.name,
    },
    {
      title: '消息详情',
      dataIndex: 'message',
      render: (message: string) => {
        return <Tooltip title={message}>
          { message?.replace(/^(.{50}).+$/, (match, p1) => p1 + '...')}
        </Tooltip>
      }
    },
    {
      title: '时间',
      dataIndex: 'createdAt',
    },
    {
      title: '状态',
      render: (record: any) => {
        return <Status status={record.status} />
      },
    },
    {
      title: '操作',
      dataIndex: 'type',
      render: (val: string,record: any) => val !== 'other' && (
          <span
            onClick={() => {
              mesTypeMap['PROCESS']?.opClick(record?.id, record?.msgId, record)
                history.push({
                  pathname: record.type === "dataCorrection" || record.type === "publishChanges" ?
                      // 发布变更
                      //数据变更
                      (record.applyType === "APPROVAL" ?
                              `/data_change_mine_approve/${record?.dataChangeId}/detail`
                              :
                              `/data_change_mine_apply/${record?.dataChangeId}/detail`
                      )
                      :
                      (record.applyType === "APPROVAL" ?
                              `/mine_approve/${record?.flowUUID}/detail`
                          :
                              `/mine_apply/${record?.flowUUID}/detail`
                      ),
                  state: {
                    ...record,
                    mainUUID: record?.flowMainUUID,
                    originType: 'message'
                  }
                })
            }}
            style={{ color: '#3f84e9', cursor: 'pointer' }}
          >
           查看详情
          </span>
      ),
    },
  ]

  const pagination: TablePaginationConfig = {
    total: dataSource?.total,
    onChange: (page, pageSize) => {
      getMes(page, pageSize || 10)
    },
    showTotal: (total) => `共 ${total} 条`,
  }

  return (
    <section className="page-content page-content-scroll">
      <h1>个人消息</h1>
      <Card
        size="small"
        title={
          <Row justify="space-between" style={{ width: '100%' }}>
            <div style={{ fontWeight: 'bold', color: '#333' }}>消息列表</div>
            <Col>
              <LinkButton
                onClick={async () => {
                  if (selectedRowKeys.length) {
                    await putReadMess(selectedRowKeys)
                    dispatch(getMessages(userId))
                    refreshMes()
                  } else {
                    message.info('未勾选未读信息')
                  }
                }}
              >
                标记已读
              </LinkButton>
              {/* <LinkButton
                onClick={async () => {
                  if (selectedRowKeys.length) {
                    await putReadMess(selectedRowKeys)
                    dispatch(getMessages(userId))
                    refreshMes()
                  } else {
                    message.info('未勾选未读信息')
                  }
                }}
              >
                批量删除
              </LinkButton> */}
            </Col>
          </Row>
        }
      >
        <Table
          dataSource={dataSource?.data || []}
          loading={loading}
          columns={columns}
          rowKey={(record) => record.id}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) =>
              setSelectedRowKeys(selectedRowKeys as number[]),
            getCheckboxProps: (r: Message) => ({
              disabled: r.status === '已读',
            }),
          }}
          size="small"
          pagination={pagination}
        />
      </Card>
      <ModalFlowDetail
        key={flowId}
        record={activeRecord}
        visible={Boolean(flowVisible && flowId)}
        applyId={flowId as any}
        kind={flowMode}
        onCancel={() => setFlowVisible(false)}
      ></ModalFlowDetail>
    </section>
  )
}
