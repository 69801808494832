import { toInteger } from 'lodash'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import {
  UserEntity,
  getOrgTreeData,
  OrgTreeNode,
  getSearchUsers,
  searchAllUsers,
} from 'src/api'

interface OrganizationState {
  userList: UserEntity[]
  userListLoading: boolean
  /** org tree loading status */
  treeLoading: boolean
  /** org tree selected node */
  selectedNode: OrgTreeNode | null
  orgTreeData: OrgTreeNode[]
  /** org tree expanded keys */
  expandedKeys: string[]
  organizationPageNum: number
  organizationPageSize: number
  organizationTotalNum: number
  organizationSearch: string
  deptUserId: string | number 
}

const initialState: OrganizationState = {
  userList: [],
  userListLoading: false,
  treeLoading: false,
  selectedNode: null,
  orgTreeData: [],
  expandedKeys: [],
  organizationPageNum: 0,
  organizationPageSize: 10,
  organizationTotalNum: 0,
  organizationSearch: '',
  deptUserId: ''
}

export const fetchDeptUserList = createAsyncThunk(
  'org/fetchDeptUserList',
  async ({
    dept,
    search,
    pageNum,
    pageSize,
  }: {
    dept: OrgTreeNode
    search?: string
    pageNum: number
    pageSize: number
  }) => {
    switch (dept.orgType) {
      case 'COMPANY':
      case "SUB_COMPANY":
        const allUsers = await searchAllUsers({
          nameValue: search,
          companyId: toInteger(dept.id),
          pageNum: pageNum,
          pageSize: pageSize,
        })
        return allUsers
      case 'DEPT':
        const deptUsers = await getSearchUsers({
          orgId: toInteger(dept.id),
          nameValue: search,
          pageNum: pageNum,
          pageSize: pageSize,
        })
        return deptUsers
      case 'GROUP':
        const groupUsers = await getSearchUsers({
          orgId: toInteger(dept.id),
          nameValue: search,
          pageNum: pageNum,
          pageSize: pageSize,
        })
        return groupUsers
      default:
        throw new Error('不存在的节点类型')
    }
  },
)

export const organizationSlice = createSlice({
  name: 'org',
  initialState,
  reducers: {
    setSelectedNode(state, action: PayloadAction<OrgTreeNode | null>) {
      state.selectedNode = action.payload
    },

    setOrgTreeData(state, action: PayloadAction<OrgTreeNode[]>) {
      state.orgTreeData = action.payload
    },

    setTreeLoading(state, action: PayloadAction<boolean>) {
      state.treeLoading = action.payload
    },

    setExpandedKeys(state, action: PayloadAction<string[]>) {
      state.expandedKeys = action.payload
    },
    setOrganizationSearch(state, action: PayloadAction<string>) {
      state.organizationSearch = action.payload
    },
    setDeptUsersManagerId(state,  action: PayloadAction<any>) {
      state.deptUserId = action.payload
    }
  },
  extraReducers: (build) => {
    build.addCase(fetchDeptUserList.pending, (state) => {
      state.userListLoading = true
    })
    build.addCase(fetchDeptUserList.fulfilled, (state, action) => {
      const newUserList = action.payload.content.map((item: any) => {
        if (item?.userId == state.deptUserId) {
          item.principalFlag = true
        } else {
          item.principalFlag = false
        }
        return item
      })
      state.userList = newUserList
      state.organizationPageNum = action.payload.pageNum
      state.organizationPageSize = action.payload.pageSize
      state.organizationTotalNum = action.payload.totalElements
      state.userListLoading = false
    })
    build.addCase(fetchDeptUserList.rejected, (state, action) => {
      if (action.meta.aborted) return
      state.userListLoading = false
    })
  },
})

export const organizationReducer = organizationSlice.reducer

export const {
  setSelectedNode,
  setOrgTreeData,
  setTreeLoading,
  setExpandedKeys,
  setOrganizationSearch,
  setDeptUsersManagerId
} = organizationSlice.actions
import { setCompanyId, setCompanyName } from './companySlice'
import { stat } from 'fs'
export const fetchOrgTreeData = (): AppThunk => (dispatch) => {
  getOrgTreeData()
    .then((rootNode) => {
      if ('COMPANY' === rootNode.orgType || 'SUB_COMPANY' === rootNode.orgType) {
        dispatch(setCompanyName(rootNode.name))
        dispatch(setCompanyId(rootNode.id))
      }
      dispatch(setOrgTreeData([rootNode]))
      // 默认选择根节点
      // dispatch(setSelectedNode(rootNode))
      const getAllKeys = (nodeList: any[]): any[] =>
        nodeList.reduce(
          (list: any[], node: any) =>
            list.concat(node.key).concat(getAllKeys(node.children)),
          [],
        )
      dispatch(setExpandedKeys(getAllKeys([rootNode])))
    })
    .finally(() => dispatch(setTreeLoading(false)))
}

// 对于已经选择节点修改后是不需要重新选择跟节点的，这里临时座位组节点的解决方案
export const fetchOrgTreeDataNoSelectNode = (): AppThunk => (dispatch) => {
  getOrgTreeData()
    .then((rootNode) => {
      if ('COMPANY' === rootNode.orgType || 'SUB_COMPANY' === rootNode.orgType) {
        dispatch(setCompanyName(rootNode.name))
        dispatch(setCompanyId(rootNode.id))
      }
      dispatch(setOrgTreeData([rootNode]))
      const getAllKeys = (nodeList: any[]): any[] =>
        nodeList.reduce(
          (list: any[], node: any) =>
            list.concat(node.key).concat(getAllKeys(node.children)),
          [],
        )
      dispatch(setExpandedKeys(getAllKeys([rootNode])))
    })
    .finally(() => dispatch(setTreeLoading(false)))
}
