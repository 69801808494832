import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Form, Input, message, TreeSelect, Select } from 'antd'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { fetchOrgTreeData } from '../organizationSlice'
import {addSubCompany, OrgTreeNode} from 'src/api'
import { FormLayout } from 'src/constants'
import { nameValidator } from 'src/util/nameValidator'

const traversalOrgTreeNode = (rootNode: OrgTreeNode): any => {
  const { title, name, orgType, children } = rootNode
  return {
    title,
    value: name,
    selectable: ['COMPANY', 'DEPT', 'SUB_COMPANY'].includes(orgType),
    children: (children || []).map(traversalOrgTreeNode),
  }
}

export const AddSubCompanyModal = ({ usersList }: { usersList: any }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { selectedNode, orgTreeData } = useSelector(
    (state) => state.organization,
  )

  const userSelectOptions = usersList?.map((user: any) => {
    const { userId, userName } = user
    return {
      label: `${userName}（${userId}）`,
      value: userId,
    }
  })

  const initialSelectedParent = useMemo(() => {
    if (selectedNode?.orgType === 'DEPT' || selectedNode?.orgType === 'SUB_COMPANY') {
      return selectedNode?.name
    } else {
      return orgTreeData[0]?.name
    }
  }, [selectedNode, orgTreeData])

  const treeSelectData = useMemo(() => {
    return orgTreeData.map(traversalOrgTreeNode)
  }, [orgTreeData])

  const { AddSubCompany } = useSelector((state) => state.modal)
  const [addSubCompanyForm] = Form.useForm()

  const handleSubmitAddSubCompany = () => {
    addSubCompanyForm.validateFields().then((values) => {
      const { name, description, parent, principal } = values
      let parId: any = null
      if (
        selectedNode?.orgType === 'DEPT' ||
        selectedNode?.orgType === 'SUB_COMPANY' ||
        selectedNode?.orgType === 'COMPANY'
      ) {
        parId = selectedNode?.id
      }
      const parents = [parId]

      setConfirmLoading(true)
      addSubCompany({ name, description, parents, principal, orgType: 'SUB_COMPANY'})
        .then(() => {
          message.success('合作方添加成功')
          dispatch(fetchOrgTreeData())
          dispatch(hideModal('AddSubCompany'))
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  const modalLabel = selectedNode?.orgType === 'SUB_COMPANY' ? '所属合作方' : '所属公司';

  return (
    <UIModal
      title="添加合作方"
      visible={AddSubCompany}
      onCancel={() => dispatch(hideModal('AddSubCompany'))}
      onOk={() => handleSubmitAddSubCompany()}
      width={480}
      confirmLoading={confirmLoading}
    >
      {/* todo 全局统一： Modal 组合 Form 时设置 Form preserve: false，而不是 Modal afterClose 重置表单 */}
      <Form form={addSubCompanyForm} {...FormLayout} preserve={false}>
        <Form.Item
          name="parent"
          label={modalLabel}
          initialValue={initialSelectedParent}
        >
          <TreeSelect treeData={treeSelectData} treeDefaultExpandAll disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="合作方名称"
          rules={[
            { required: true, message: '请输入合作方名称' },
            { max: 20, min: 2, message: '合作方名称须在2-20个字之间' },
            { validator: nameValidator },
          ]}
        >
          <Input></Input>
        </Form.Item>
        {/* <Form.Item name="description" label="描述和备注">
          <Input></Input>
        </Form.Item> */}
        {/*<Form.Item*/}
        {/*  name="principal"*/}
        {/*  label="负责人"*/}
        {/*  rules={[{ required: true, message: '请输入负责人' }]}*/}
        {/*>*/}
        {/*  <Select*/}
        {/*    options={userSelectOptions}*/}
        {/*    optionFilterProp="label"*/}
        {/*    showSearch*/}
        {/*  ></Select>*/}
        {/*</Form.Item>*/}
      </Form>
    </UIModal>
  )
}
