// 访问申请-我的申请-申请单（申请资源：仅展示）
import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Table } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { getToolTemplateInfo } from 'src/api'
import { columnsDetail, detailExpamdedRow, detailFineGritExpamdedRow } from '../../common/columns'
import styles from '../index.module.scss'

interface ListProps {
	data: any,
	priGranType: string
 }

export const ResourceBidPage: FC<ListProps> = (props) => {
	const {data, priGranType} = props
	const { dataSourceType, nodeType } = data?.dataList?.[0]?.businessDataListNoChild?.[0] || {}
	const [rowId, setRowId] = useState<any>([])
	const [templateEnum, setTemplateEnum] = useState<any[]>([])

	// 查询工具权限模板
	useEffect(()=>{
		if(dataSourceType && nodeType){
			queryToolsAuthTemplate(dataSourceType, nodeType)
		}
	},[dataSourceType, nodeType])
	const queryToolsAuthTemplate = (dataSourceType: string, nodeType: string) =>{
		getToolTemplateInfo(dataSourceType, nodeType).then((res: any)=>{
			setTemplateEnum(res)
		}).catch((err: any)=>{
			console.error('工具权限模板查询失败', err)
		})
	}

	const expandedRowRender = (record: any) => {
			const newColumns = (priGranType === 'THIN' ? detailFineGritExpamdedRow : detailExpamdedRow)?.map((item: any)=>{
				// 工具权限申请
				if(item?.dataIndex === 'toolsAuth') {
					return {
						...item,
						render: (val: any, record: any) => {
							let resultName: any = []
							record?.templateOperationsSet?.forEach((item: any) => {
								const matchedObejct = templateEnum?.find((res:any) => res?.objectType === item?.objectType);
								if (matchedObejct) {
									item?.operations?.forEach((val: any)=>{
										const matchedItem = matchedObejct?.operations?.find((res:any) => res?.operation === val);
										if(matchedItem?.operationName){
											resultName.push(matchedItem?.operationName)
										}
									})
								}
							});
							return resultName?.join(',') || '-'
						},
					}
				}
				return item
			})
		
		return <Table columns={newColumns} dataSource={record?.businessDataListNoChild} pagination={false} size="middle" />;
	}

	useEffect(() => {
		if (data?.dataList?.length) {
			const newArr: any[] = []
			data?.dataList?.forEach((item: any) => {
				newArr.push(item?.id)
			})
			setRowId(newArr)
		}
	},[data])

	const scrollX = columnsDetail?.length * 100;

	return (
		<Card
			title="申请资源"
			className={styles.borderShow}
			extra={
				<Button
					type="primary"
				>
					<Link to="/mine_apply/search">
						<PlusOutlined /> 
						&nbsp;&nbsp;添加资源
					</Link>
				</Button>
			}
		>
			<Table
				rowKey="id"
				className={styles.tablePage}
				expandable={{
					expandedRowRender: (record) => {
						return expandedRowRender(record)
					},
					defaultExpandAllRows: true,
					expandedRowKeys: rowId,
					onExpandedRowsChange: (e) => {
						setRowId(e)
					}
				}}
				
				columns={columnsDetail}
				dataSource={data?.dataList}
				size="small"
				scroll={{ x: scrollX, y: 490 }}
			/>
		</Card>
	)
}
