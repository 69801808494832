import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, Form, Switch, Input, Typography, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useRequest } from 'src/hook'
import { LinkButton } from 'src/components'
import { getCasConfig, putCasConfig } from 'src/api'
import { SettingFormContext } from '../SettingFormContext'
import styles from './index.module.scss'

const { Text } = Typography

export const CasCard = () => {
  const [editing, setEditing] = useState(false)
  const form = useContext(SettingFormContext) as FormInstance

  const { data: casConfig, loading, run: getConfig } = useRequest(getCasConfig)
  const { run: putConfig } = useRequest(putCasConfig, {
    manual: true,
    onSuccess: () => {
      getConfig()
    }
  })

  const setFormVals = useCallback((val:any) => {
    form.setFields([
      { name: 'casSwitch', value: casConfig.casSwitch },
      { name: 'casServerLoginUrl', value: casConfig.casServerLoginUrl },
      { name: 'casServerValidateUrl', value: casConfig.casServerValidateUrl },
      { name: 'casServerLogoutUrl', value: casConfig.casServerLogoutUrl },
      { name: 'casCQServerName', value: casConfig.casCQServerName },
      { name: 'casUserNameSuffix', value: casConfig.casUserNameSuffix },
    ])
  }, [casConfig, form])

  const handleSave = useCallback(() => {
    form
      .validateFields([
        'casSwitch',
        'casServerLoginUrl',
        'casServerValidateUrl',
        'casServerLogoutUrl',
        'casCQServerName',
        'casUserNameSuffix',
      ])
      .then((val) => {
        putConfig(val).then((val) => {
          if(!!val.resMsg){
            message.success(val.resMsg)
          }
        })
        setFormVals(val)
      })
      .then(() => {
        setEditing(false)
      }).catch((error) => { console.error(error) })
  }, [form, putConfig, setFormVals])

  useEffect(() => {
    if (!!casConfig) {
      setFormVals(casConfig)
    }
  }, [casConfig, form, setFormVals])

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              if(!!casConfig) {
                setFormVals(casConfig)
              }
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
      )}
    </div>
  )

  const urlValidator = (_: any, value: string) => {
    if(!value) {
      return Promise.reject('请输入 URL')
    }
    const passed = value.startsWith('http://') || value.startsWith('https://')
    if (!passed) {
      return Promise.reject('必须以 http:// 或者 https:// 开头')
    } else {
      return Promise.resolve()
    }
  }

  const suffixValidator = (_: any, value: string) => {
    if(!value) {
      return Promise.reject('请输入后缀名')
    }
    const reg = /^[a-zA-Z]{1,}$/
    if(!reg.test(value)) {
      return Promise.reject('只能以英文字母作为后缀名称')
    } else if(value.length > 10) {
      return Promise.reject('长度不能超过 10')
    } else {
      return Promise.resolve()
    }
  }

  return (
    <section className="cq-card flow-card" id="CasCard" style={{border: 'none'}}>
      <div className={styles.singleTitle}>
        {/* <h3 className="cq-card__title">
          CAS 配置
          &nbsp; 
          <span className={styles.casSettingTip}>
            (保存后需要重启 User 服务才能生效)
          </span>
        </h3> */}
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          {/* <Form> */}
          <Form.Item label="是否开启">
            <Form.Item
              hidden={!editing}
              name="casSwitch"
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
            {!editing && (
              <Text>
                {!!casConfig && casConfig.casSwitch ? '开启' : '关闭'}
              </Text>
            )}
          </Form.Item>

          <Form.Item label="登录地址">
           { editing && <Form.Item
              hidden={!editing}
              name="casServerLoginUrl"
              noStyle
              rules={[{ required: true, validator: urlValidator }]}
            >
              <Input placeholder='http(s)://example:port/cas/login'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.casServerLoginUrl}</Text>
            )}
          </Form.Item>

          <Form.Item label="验证地址">
            {editing && <Form.Item
              hidden={!editing}
              name="casServerValidateUrl"
              noStyle
              rules={[{ required: true, validator: urlValidator }]}
            >
              <Input placeholder='http(s)://example:port/cas'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.casServerValidateUrl}</Text>
            )}
          </Form.Item>

          <Form.Item label="登出地址">
            {editing && <Form.Item
              hidden={!editing}
              name="casServerLogoutUrl"
              noStyle
              rules={[{ required: true, validator: urlValidator }]}
            >
              <Input placeholder='http(s)://example:port/cas/logout'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.casServerLogoutUrl}</Text>
            )}
          </Form.Item>

          <Form.Item label="服务部署地址">
           {editing && <Form.Item
              hidden={!editing}
              name="casCQServerName"
              noStyle
              rules={[{ required: true, validator: urlValidator }]}
            >
              <Input placeholder='http(s)://cloudquery:port'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.casCQServerName}</Text>
            )}
          </Form.Item>

          <Form.Item label="登录用户名称后缀">
           {editing && <Form.Item
              hidden={!editing}
              name="casUserNameSuffix"
              noStyle
              rules={[{ required: true , validator: suffixValidator}]}
            >
              <Input></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.casUserNameSuffix}</Text>
            )}
          </Form.Item>
          {/* </Form> */}
        </Card>
      </section>
    </section>
  )
}
