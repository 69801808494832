import { useState } from 'react'
import { useDispatch } from 'src/hook'
import { showModal, ModalName } from 'src/store/extraSlice/modalVisibleSlice'

export interface MyRecordContext {
  mode: 'create' | 'edit'
  record?: any
  refresh?: any
  modalName?: ModalName
}

export type IRecordContext = MyRecordContext

export const useTableRecordContext = (refresh: any, modalName: string) => {
  const dispatch = useDispatch()
  const [record, setRecord] = useState<any>()
  const [mode, setMode] = useState('create')

  const setEditContext = (record: any) => {
    setRecord(record)
    setMode('edit')
    dispatch(showModal(modalName as any))
  }

  const setCreateContext = () => {
    setRecord(null)
    setMode('create')
    dispatch(showModal(modalName as any))
  }
  return {
    recordContext: {
      mode,
      record,
      refresh,
      modalName,
    },
    setEditContext,
    setCreateContext,
  } as {
    recordContext: IRecordContext
    setEditContext: any
    setCreateContext: any
  }
}
