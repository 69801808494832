import * as _ from 'lodash';

export const getFilteredDatabaseList = (data: any) => {
  //数据变更 过滤 mongodb,redis
  if (_.isEmpty(data))  return [];
   return data && data.filter((item: any) => !['MongoDB', 'Redis'].includes(item?.dataSourceName) )
}

export const autoScrollToScreenCenter = (id: string) => {
   //自动滚动到屏幕中间
   const element = document.getElementById(id)
   element?.scrollIntoView(false);
}