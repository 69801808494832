/**
 * 选择管理员列表弹框
 */
import React, { useState } from 'react'
import { Modal, message, Tree, Tooltip, Input, Row, Col, Radio } from 'antd'
import { useRequest } from "src/hook";
import {
  queryManageList,
  updateGroupManage,
} from 'src/api'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Iconfont } from 'src/components'
import {flatData, treeNodeAdapter} from './utils'
import styles from './index.module.scss'

interface PropsI {
  groupId?: string
  connectionId?: string
  onCancel: () => void
  [p: string]: any
}

const ChooseConnectionManage = (props: PropsI) => {
  const { groupId, connectionId, onCancel } = props


  const [selectedKeys, setSelectKeys] = useState<any[]>([]);
  const [checkedUserInfo, setCheckedUserInfo] = useState<any[]>([]);
  const [searchUserList, setSearchUserList] = useState<any[]>([])

  // 可以更改的管理员信息
  const { data: treeData=[] } = useRequest(queryManageList, {
      formatResult:(res: any[])=>{
        return treeNodeAdapter(res)
      }
    }
  )

  // 管理员更改
  const handleOk = () => {
    const fn =  updateGroupManage
    const userId = checkedUserInfo[0]?.userId
    const params: any =  { groupId, userId }
    fn(params)
      .then((_: any) => {
        message.success('管理员更新成功')
        onCancel()

      })
      .catch((err: any) => {
        console.error('管理员更新失败', err)
      })
  }

  //  tree select
  const handleSelect = (_: any, e: any) => {
    const {name, id, key, type} = e?.node
    if(type==='USER'){
      const userInfo = [{
        userName: name,
        userId: id,
        key
      }]
      setSelectKeys([key])
      setCheckedUserInfo(userInfo)
    }
  }

  // 搜索
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.trim();
    const searchUserList = flatData(treeData).map((item: any) => {
      const {id, type, name, key } = item
      if (value && name?.includes(value) && type === 'USER') {
        return {
          id,
          name,
          key
        };
      }
      return null
    }).filter((item, i, self) => item && self.indexOf(item) === i);

    const uniqueUserIds = searchUserList && searchUserList.reduce((accumulator, current: any) => {
      const existingItem = accumulator.find((item:any) => item?.id === current?.id);
      if (!existingItem) {
        //@ts-ignore
        return accumulator.concat([current]);
      } else {
        return accumulator;
      }
    }, []);
    setSearchUserList(uniqueUserIds || [])
    setSelectKeys([]);
    setCheckedUserInfo([]);
  }

  const handleRadioChange = (e: any) => {
    const value = e.target.value
    const checkedUsers = flatData(treeData)
      ?.filter((i: any) => value === i?.key)
      ?.map((i: any) => {
        return {
          userName: i?.name,
          userId: i?.id,
          key: i?.key
        };
      });
    setCheckedUserInfo(checkedUsers);
    setSelectKeys([value]);
  }

  // 删除选择的用户
  const handleCloseItem = () => {
    setCheckedUserInfo([])
    setSelectKeys([])
  }

  const titleRender = (node: any) => {
    const { type, title } = node
    let icon = null
    switch (type) {
      case 'COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'SUB_COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'DEPT':
        icon = <Iconfont type="icon-org-department" />
        break
      case 'GROUP':
        icon = <Iconfont type="icon-org-group" />
        break
      case 'USER':
        icon = <Iconfont type="icon-org-user" />
        break
      default:
        break
    }
    const showTitle =
      title?.length > 15 ? (
        <Tooltip title={title}>{title.substring(0, 15) + '...'}</Tooltip>
      ) : (
        title
      )
    return (
      <>
        {icon}&nbsp;{showTitle}
      </>
    )
  }
  return (
 
      <Modal
        width={620}
        visible={true}
        title="请选择"
        onCancel={() => onCancel()}
        onOk={handleOk}
        destroyOnClose
        className={styles.chooseManageModal}
      >
        <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>组织架构</div>
          <Input.Search
            style={{ width: 280, marginBottom: 8 }}
            onChange={handleSearch}
          />
          {!searchUserList?.length && !!treeData?.length && (
            <Tree
              className={styles.treeWrap}
              titleRender={titleRender}
              treeData={treeData}
              onSelect={handleSelect}
              selectedKeys={selectedKeys}
            />
          )}
          {/* 搜索用户列表 */}
          {!!searchUserList?.length && (
            <div className={styles.searchUserListWrap}>
              <Radio.Group
                style={{ width: "100%" }}
                value={selectedKeys?.[0]}
                onChange={handleRadioChange}
              >
                {searchUserList?.map((item) => {
                  return (
                    <Row key={item?.key} style={{margin:'8px 0'}}>
                      <Col span={24}>
                        <Radio value={item?.key} className={styles.flexItem}>
                          <span className={styles.flexItem}>
                            <Iconfont
                              type="icon-org-user"
                              style={{marginRight: '8px'}}
                            />
                            <span>
                              <div>{`${item?.name}(${item?.id})`}</div>
                              <div>{item?.path}</div>
                            </span>
                          </span>
                        </Radio>
                      </Col>
                    </Row>
                  );
                })}
              </Radio.Group>
            </div>
          )}
        </div>
        <div className={styles.right}>
          {!!checkedUserInfo?.length && (
            <div
              className={styles.title}
            >{`已选择用户`}</div>
          )}
          <div className={styles.userWrap}>
            {checkedUserInfo?.map((item: any) => {
              const renderUser =
                item?.userName?.length > 12 ? (
                  <Tooltip title={item?.userName}>
                    {item?.userName.substring(0, 12) + "..."}
                  </Tooltip>
                ) : (
                  item?.userName
                );
              return (
                <div className={styles.selectItem} key={item.key}>
                  {renderUser}
                  <CloseCircleOutlined
                    onClick={() => handleCloseItem()}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      </Modal>
  );
}
export default ChooseConnectionManage
