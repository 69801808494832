import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'

interface CreateDatabaseState {
  visibleCreateDatabase: boolean
}

const initialState: CreateDatabaseState = {
  visibleCreateDatabase: false,
}

export const createDatabaseSlice = createSlice({
  name: 'createDatabase',
  initialState,
  reducers: {
    setVisibleCreateDatabase: (state, action: PayloadAction<boolean>) => {
      state.visibleCreateDatabase = action.payload
    },
  },
})

export const createDatabaseReducer = createDatabaseSlice.reducer

export const { setVisibleCreateDatabase } = createDatabaseSlice.actions

export const finishCreateDatabase = (): AppThunk => (dispatch) => {
  dispatch(setVisibleCreateDatabase(false))
}

export const startCreateDatabase = (): AppThunk => (dispatch) => {
  dispatch(setVisibleCreateDatabase(true))
}
