import React, { useState, useCallback } from 'react'
import { TreeSelect } from 'antd'
import { useDispatch, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { Form, message } from 'antd'
import { refreshOnRoot } from '../sdtSlice'
import { getSdtGroups, moveConnectionToSdtGroup } from 'src/api'

export const MoveToSdtGroupModal = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { MoveToSdtGroup } = useSelector((state) => state.modal)
  const { selectedNode } = useSelector((state) => state.sdt)
  const { connectionId, parentGroupId: oldGroupId } = selectedNode as any
  const [moveToSdtGroupForm] = Form.useForm()

  const [sdtGroupTree, setSdtGroupTree] = useState<any>([
    {
      connectionId: 0,
      connectionType: null,
      nodeType: 'connectionGroup',
      nodeName: '根组',
      nodePath: '/root',
      groupId: 0,
      hasChild: true,
      parentGroupId: null,
      nodeDescription: null,
      title: '根组',
      key: 0,
      value: 0,
    },
  ])

  const findTargetTreeNode = useCallback(
    (nodePath: string | number) => {
      const queue = [...sdtGroupTree]
      while (true) {
        const currNode = queue.shift()
        if (!currNode) return
        if (currNode.nodePath === nodePath) {
          return currNode
        }
        if (currNode.children && currNode.children[0]) {
          queue.push(...currNode.children)
        }
      }
    },
    [sdtGroupTree],
  )

  const onLoadData = useCallback(
    (treeNode: any) => {
      return new Promise(async (resolve, reject) => {
        try {
          const { groupId, nodePath } = treeNode
          const children = await getSdtGroups(groupId)
          const parentNode = findTargetTreeNode(nodePath)
          parentNode.children =
            children && children[0]
              ? children.map((child: any) => ({
                  ...child,
                  key: child.groupId,
                  value: child.groupId,
                  title: child.nodeName,
                }))
              : []
          setSdtGroupTree([...sdtGroupTree])
          resolve(true)
        } catch (error) {
          reject(error)
        }
      })
    },
    [findTargetTreeNode, sdtGroupTree],
  )

  return (
    <UIModal
      title="移动到组"
      visible={MoveToSdtGroup}
      onCancel={() => dispatch(hideModal('MoveToSdtGroup'))}
      onOk={() => {
        moveToSdtGroupForm.validateFields().then(({ groupId }) => {
          setConfirmLoading(true)
          moveConnectionToSdtGroup(connectionId!, oldGroupId || 0, groupId)
            .then(() => {
              message.success('移动到组成功')
              dispatch(hideModal('MoveToSdtGroup'))
              dispatch(refreshOnRoot())
            })
            .finally(() => setConfirmLoading(false))
        })
      }}
      afterClose={() => moveToSdtGroupForm.resetFields()}
      width={480}
      confirmLoading={confirmLoading}
    >
      <Form form={moveToSdtGroupForm}>
        <Form.Item
          label="移入组"
          name="groupId"
          rules={[{ required: true, message: `移入组不能为空` }]}
          initialValue={0}
        >
          <TreeSelect
            style={{ width: '100%' }}
            loadData={onLoadData}
            treeData={sdtGroupTree}
          ></TreeSelect>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
