import { fetchGet, fetchPost } from './customFetch'

/** 获取当前版本信息 */
export const getCurrentVersionInfo = () => {
  return fetchGet(`/cqupgrade/obtain_version`)
}

/** 获取最新版本信息 */
export const getLatestVersionInfo = () => {
  return fetchGet(`/cqupgrade/inspect_update`)
}

/** 开始更新 */
export const startUpdating = () => {
  return fetchGet(`/cqupgrade/start_upgrade`)
}

export const startUpdateAgent = () => {
  return fetchGet(`/cqupgrade/update_agent`)
}

export const updateStatus = [
  'serving',
  'checking',
  'downloading',
  'upgrading',
  'restarting',
  'rollingBack',
  'updateAgent',
] as const

export type UpdateStatus = typeof updateStatus[number]

export const getAppStatus = (): Promise<{
  appStatus: UpdateStatus
}> => {
  return fetchGet(`/cqupgrade/app_status`)
}

/** 获取系统更新状态 */
export const getUpdatingStatus = (): Promise<{
  appStatus: UpdateStatus
}> => {
  return fetchGet(`/cqupgrade/obtain_status`)
}

export interface BackupEntity {
  fileName: string
  fileSize: string
  updatedTime: string
}
export const getBackups = (): Promise<BackupEntity[]> => {
  return fetchGet(`/cqupgrade/backupList`)
}

export const startBackup = (): Promise<boolean> => {
  return fetchGet(`/cqupgrade/backup`)
}

export const startRestore = (fileName: string): Promise<boolean> => {
  return fetchPost(`/cqupgrade/restore`, { fileName })
}
