import {
  fetchPost,
  fetchGet,
  fetchDelete,
  fetchPut,
  fetchPostFormData,
} from './customFetch'
import {
  ResponseNodeEntity,
  RequestNodeInfo,
  DataSourceType,
  NodeEntity,
  SdtResponse,
} from '../types'
import { WizardItem } from './wizrdForm'

export const getNodeChildList = (
  params: Partial<NodeEntity>,
): Promise<SdtResponse> => {
  const {
    connectionId,
    connectionType,
    nodeType,
    nodeName,
    funcAndProcOriginName,
    nodePath,
    nodePathWithType,
    groupId,
    globalHavePermissionFlag,
  } = params
  return fetchPost(`/dms/meta/node`, {
    connectionId,
    connectionType,
    nodeType,
    nodeName,
    funcAndProcOriginName,
    nodePath,
    nodePathWithType,
    groupId,
    globalHavePermissionFlag
  })
}

export type MenuType =
  | 'connectionSwitch'
  | 'openQuery'
  | 'openTerminal'
  | 'new'
  | 'delete'
  | 'edit'
  | 'open'
  | 'openPlSql'
  | 'openTsql'
  | 'openTaskExecution' //打开任务
  | 'openRefreshTask'  //刷新任务
  | 'rename'
  | 'managerGroup'
  | 'connectionManagement' /** 连接管理 */
  | 'copy'
  | 'copyConnection'
  | 'refresh'
  | 'rename'
  | 'openRedisKey'
  | 'deleteGroup' /** 连接从组中移除 */
  | 'addGroup' /** 连接移动到组 */
  // 表
  | 'viewTableStructure' /** 查看表结构 */
  | 'designTable' /** 设计表 */
  | 'emptyTable' /** 清空表 */
  | 'truncateTable' /** 截断表 */
  | 'dumpSQL' /** 转储 SQL 文件 */
  | 'moveToGroup' /** 移动到组 */
  | 'createSubGroup' /** 移动子组 */
  | 'updateConnAlias' /** 修改别名 */
  | 'connectionAccess' /* 连接访问提权 */
  | 'dataMasking' /* 脱敏提权 */
  | 'dataExport' /* 转储提权 */
  | 'call' /** 调用存储过程等 */
  | 'divider' /** 前端 mock 的类型，应当由后端来定义 */
  | 'show' /* 查看视图 */
  | 'dumpExport' /* dump 格式导出 */
  | 'dumpImport' /* dump 格式导入 */
  | 'dumpExportDmp'
  | 'syncDataDict' /* 同步数据字典 */
  | 'dumpImportSql' /** sql 格式导入 */
  | 'compileInvalidObjects' /* 编译无效对象 */
  | 'openMySQLProcedure' /* mysql编辑器 */
  | 'import' /** 文本导入 */
  | 'dataSync' /** 元数据同步 */
  | 'debugProcedure' /** 存储过程组-debug存储过程 */
  | 'connectionPool' /** 连接池 */
  | 'connectionPoolRestUpdate' /** 连接池重置 */
  | 'connectionPoolRestDelete' /** 连接池回收 */
  | 'batchExecute' /** 批量执行 */

export interface MenuEntity {
  childMenuList: MenuEntity[]
  valid: boolean
  menuName: string
  menuType: MenuType
  handleClick?: Function
  disabled?: boolean
  hide: boolean
}

export const getNodeMenu = (
  node: Partial<RequestNodeInfo & { userId: string }>,
) => {
  const {
    connectionId,
    connectionType,
    nodeType,
    nodeName,
    funcAndProcOriginName,
    nodePath,
    nodePathWithType,
    nodePermissionLimits,
    userId,
  } = node
  const params = {
    connectionId,
    connectionType,
    nodeType,
    nodeName,
    funcAndProcOriginName,
    nodePath,
    nodePathWithType,
    nodePermissionLimits,
    userId,
  }
  return fetchPost(`/dms/meta/menus`, params)
}

/** sql执行次数 连接数据库 */
export const getDbsUnderSpecifiedConnection = (connectionId: number | string) => {
  return fetchGet(`/user/resource/database/${connectionId}`)
}

export type EditorToolbarButton = 'EXPLANATION' | 'FLASHBACK'
export interface DataSource {
  dataSourceType: 'GXX' | 'BigData' | 'NoSQL' | 'aliyun'
  // todo: dataSourceType 待定义
  dataSourceName: DataSourceType
  connectionForm: WizardItem[]
  editorToolbarButtons: EditorToolbarButton[]
  dataSourceLevel: number
}
export const getSupportedConnectionTypes = (): Promise<DataSource[]> => {
  return fetchGet(`/dms/meta/datasource_list`)
}

/** 添加组 */
export const addSdtGroup = (
  groupName: string,
  parentGroupId: string | number,
) => {
  return fetchPost(`/dms/menu/group/${parentGroupId}/${groupName}`)
}

/** 删除组 */
export const deleteSdtGroup = (groupId: number | string) => {
  return fetchDelete(`/dms/menu/group/${groupId}`)
}

/** 连接移动到组 */
export const moveConnectionToSdtGroup = (
  connectionId: number | string,
  oldGroupId: number | string,
  newGroupId: number | string,
) => {
  return fetchPost(
    `/dms/menu/group/${connectionId}/${oldGroupId}/${newGroupId}`,
  )
}

/** 连接移出组 */
// todo: 合并到连接移动到组，移动到 default no group
export const removeConnectionfromSdtGroup = (
  connectionId: number | string,
  groupId: number | string,
) => {
  return fetchDelete(`/dms/menu/group/${connectionId}/${groupId}`)
}

/** 获取用户 SDT 中的组 */
export const getSdtGroups = (
  groupId = 0 as string | number,
): Promise<NodeEntity[]> => {
  return fetchGet(`/dms/connection/groups/${groupId}`)
}

/** 删除连接、库、表和视图等 SDT 节点 */
export const deleteSdtNode = (params: any) => {
  return fetchDelete(`/dms/menu`, params)
}

export const closeConnection = (connectionId: number | string) => {
  return fetchGet(`/dms/menu/close/${connectionId}`)
}

/** 打开表和视图等 */
export const openSdtNode = (params: any) => {
  return fetchPost(`/dms/menu/open`, params)
}
/** 打开任务等 */
export const openTaskExecution = (params: any) => {
  return fetchPost(`/dms/menu/openTaskExecution`, params)
}

/** 同步物化视图查看刷新任务等 */
export const openRefreshTask = (params: any) => {
  return fetchPost(`/dms/menu/openRefreshTask`, params)
}

/* 打开视图、包、包体、序列 */
export const showSdtNodeView = (params: any) => {
  return fetchPost(`/dms/menu/show`, params)
}

/** 获取存储过程等元素的调用语句 */
export const getCallStatement = (params: any) => {
  return fetchPost(`/dms/menu/callStatement`, params)
}

/** 清空表 */
export const emptyTable = (node: Partial<RequestNodeInfo>) => {
  return fetchPost(`/dms/menu/table/empty`, node)
}

/** 截断表 */
export const truncateTable = (node: Partial<RequestNodeInfo>) => {
  return fetchPost(`/dms/menu/table/truncate`, node)
}

/** 重命名 */
export const renameSdtNode = (node: Partial<RequestNodeInfo>) => {
  return fetchPost(`/dms/menu/rename`, node)
}

/** 数据源排序 */
export const sortConnection_api = ({
  groupId,
  connectionId,
  preConnectionId,
}: {
  groupId: string | null
  connectionId: string
  preConnectionId: string
}) => {
  return fetchPut(
    `/dms/menu/connect/sort/${groupId}/${connectionId}/${preConnectionId}`,
  )
}

interface NodeDetailRequest {
  connectionId?: number | string
  connectionType?: string
  nodeType: string
  groupId?: number
  hasChild?: boolean
  nodeName?: string
  nodePath?: string
  oracleUser?: string
}

export interface NodeDetailResponse {
  connectionType: string
  nodeName: string
  nodeType: string
  childCount: number | null
  childType: string | null
  nodeOptionInfo: object
}

export const getNodeDetail = (
  params: NodeDetailRequest,
): Promise<NodeDetailResponse> => {
  return fetchPost(`/dms/meta/nodeDetail`, params)
}

export const updateConnAlias = ({
  groupId,
  connectionId,
  alias,
}: {
  groupId: string | null
  connectionId: string
  alias: string
}) => {
  return fetchPut(`/dms/menu/connect/alias/${groupId}/${connectionId}/${alias}`)
}

/* 导入连接 */
export const batchCreateConnectionModal = (data: any) => {
  return fetchPostFormData(`/dms/connection/importConn`, data)
}

/** 同步数据字典 */
export const postSyncDataDict = (params: Partial<NodeEntity>) => {
  return fetchPost('/dms/fullTextSearch/syncTask', params)
}

/* 编译无效对象 */
export const compileInvalidObjects = (parm: {
  connectionId?: number | string
  connectionType?: string
  invalidObjects: object
}) => {
  return fetchPost(`/dms/menu/compileInvalidObjects`, parm)
}

/* 编译无效对象失败日志 */
export const compileInvalidObjectsErrors = (parm: {
  connectionId?: number | string
  connectionType?: string
  name: string
  owner: string
  objType: string
}) => {
  return fetchPost(`/dms/menu/compileInvalidObjectsErrors`, parm)
}

/* 获取无效对象 */
export const getInvalidObjects = (parm: {
  connectionId?: number | string
  connectionType?: string
  nodeName?: string
  nodeType?: string
}) => {
  return fetchPost(`/dms/menu/invalidObjects`, parm)
}

/* 右键-元数据同步 */
export const syncMetaData = (params: {
  connectionId: string | number
  connectionType: DataSourceType
  nodeName: string
  nodeType: string
  nodePath: string
}) => fetchPost(`/dict/sync/delta`, params)

/* 获取数据字典系统同步开关 */
export const getSyncSwitch = (): Promise<boolean> =>
  fetchGet(`/dict/sync/switch`)

/**
 * 存储过程组-子菜单-右键-debug存储过程
 *
 */
export const debugStoreProcess: any = (params: {
  connId: string | number
  dbName: string
  elementName: string
  schemaName: string
  elementType: string
  dbType: string
  ip: any
  oid?:string
}) => {
  return fetchPost(`/dbggw/init/requestDebug`, params)
  // return new Promise((resolve): any => {
  //   resolve({ data: 'Dockquery://', code: '1' })
  // })
}

/**
 * 细粒度提权加入申请清单
*/
export const addRequisitionToCart: any = (params: {
  flowType: string
  nodeType: string
  nodePath: string
  nodePathWithType: string
  connectionId: string | number
  groupName: string
  operation: string
  dataSourceType: string
  nodeName: string
}) => {
  return fetchPost(`/api/flow/addRequisitionToCart`, params)
}

/**
 * 细粒度提权 获取清单
*/
export const getCartDataThin: any = () => {
  return fetchPost(`/api/flow/getCartDataThin`)
}

/**
 * 细粒度提权申请
 */

export const startFlowInstance: any = (params: {
  priGranType: string
  endTime: string
  beginTime: string
  title: string
  remark: string | number
  flowType: string
}) => {
  return fetchPost(`/api/flow/apply`, params)
}

export interface ISearchSdtItem {
  nodeType: string;
  nodePath: string;
  nodePathWithType: string;
  nodeShowName: string;
  dataSourceType: string;
  havingPermission: boolean;
}
/* 数字字典查询列表 */
export const getSearchSdtList = (params: {query: string}): Promise<ISearchSdtItem[]> =>
  fetchGet(`/dms/fullTextSearch/searchSdt`,params)
