import { fetchDelete, fetchGet, fetchPost } from './customFetch'
import { DataSourceType, QueryParams, SdtNodeType } from '../types'
import { ExecuteStatus } from './query'
import { DownloadMsg } from 'src/features/websocket/useDownloadNotification'

export const ExportFormats = ['EXCEL', 'CSV', 'TXT', 'PDF', 'SQL'] as const
export type ExportFormat = typeof ExportFormats[number]

export const DumpTypes = ['DUMPDATAANDSTRUCTURE', 'DUMPSTRUCTURE']
export type DumpType = typeof DumpTypes[number]
export const DumpTypeMap = new Map([
  ['DUMPSTRUCTURE', '仅结构'],
  ['DUMPDATAANDSTRUCTURE', '结构 + 数据'],
])

export const taskStatus = [
  'CREATE',
  'PROCESSING',
  'SUCCESS',
  'FAILURE',
  'DOWNLOADED',
  'QUEUING'
] as const
export type TaskStatus = typeof taskStatus[number]

export interface ExportRequest {
  connectionId: number | string
  connectionType: DataSourceType
  exportDataNum: number
  fileName: string
  taskTime?: string
}

export interface ResultExportRequest extends ExportRequest {
  operatingObject?: string
  databaseName?: string
  statement: string
  exportFormat: ExportFormat
  resultNum: number
  containTempTable: boolean
  tabKey: string
  [p: string]: any
}

export interface SdtNodeExportRequest extends ExportRequest {
  dumpType: DumpType
  nodeName: string
  nodeType: SdtNodeType
  nodePath: string
  nodePathWithType: string
}

export interface ExportEntity {
  taskId: number
  fileName: string
  statement: string
  exportFormat: ExportFormat
  exportDataNum: number
  taskStatus: TaskStatus
  taskCreateTime: number
  origin: string
  errorLog: string
}

export interface TaskResult {
  executeStatus: ExecuteStatus,
  messageData: DownloadMsg
}

export interface ExportDetail extends ExportEntity {}

export const getTaskResult = (params: QueryParams): Promise<TaskResult> => {
  return fetchPost(`/export/export/message`, params)
}

export const getExportList = (params?: {
  current?: number
  pageSize?: number
}): Promise<{ content: ExportEntity[]; total: number; current: number }> => {
  return fetchPost(`/export/export/page`, params)
}

export const makeResultExport = (
  params: ResultExportRequest,
): Promise<QueryParams> => {
  return fetchPost(`/export/export`, params)
}

export const makeResultAllExport = (
  params: ResultExportRequest,
): Promise<QueryParams> => {
  return fetchPost(`/export/export/fullExport`, params)
}
//校验选中导出 权限
export const getSelectedStatementResult = (
  params: any,
): Promise<any> => {
  return fetchPost(`dms/segment/statement/exportFileType`, params)
}

export const makeSdtNodeExport = (
  params: SdtNodeExportRequest,
): Promise<QueryParams> => {
  return fetchPost(`/export/export/menu`, params)
}

export const getExportDetail = (exportId: number): Promise<ExportDetail> => {
  return fetchGet(`/export/export/info/${exportId}`)
}

export const deleteExport = (exportId: number): Promise<void> => {
  return fetchDelete(`/export/export/${exportId}`)
}
