import React, { useEffect, useMemo } from 'react'
import * as _ from 'lodash'
import { Modal, Table } from 'antd'
import { useRequest } from 'src/hook'
import { getExecutePlan, IExecutePlanRes } from 'src/api'

export const ExecutePlanDetail = ({
  title,
  visible = false,
  selectedRecord = {},
  onCancel,
}: {
  title: string
  visible: boolean
  selectedRecord: { sqlStatement?: string; nodePath?: string }
  onCancel: () => void
}) => {
  const {
    data: plans,
    loading,
    run: getExecutePlans,
  } = useRequest<IExecutePlanRes>(getExecutePlan, { manual: true })

  useEffect(() => {
    if (selectedRecord?.sqlStatement && selectedRecord?.nodePath) {
      getExecutePlans({
        nodePath: selectedRecord.nodePath,
        sqlStatement: selectedRecord.sqlStatement,
      })
    }
   
  }, [selectedRecord])

  const planRes = useMemo(() => {
    if (_.isEmpty(plans)) return {}
    const { columnNames = [], content = [] } = plans as IExecutePlanRes

    const formatColumns = columnNames.map((field: string) => ({
      title: field,
      dataIndex: field,
      ellipse: true,
    }))

    const formatDataSource = content.map((item: any) => {
      let dataItem: any = {}
      columnNames.map((key: string, index: number) => {
        dataItem[key] = item[index] || ''
      })
      return dataItem
    })

    return {
      columns: formatColumns,
      dataSource: formatDataSource,
    }
  }, [plans])

  return (
    <Modal
      title={title}
      width={700}
      centered
      visible={visible}
      onCancel={() => onCancel()}
      footer={null}
    >
      <Table
        loading={loading}
        dataSource={planRes?.dataSource || []}
        columns={planRes?.columns || []}
        pagination={{
          total: planRes?.dataSource?.length || 0,
        }}
        rowKey="id"
        size='small'
        scroll={{x: true}}
        // style={{width: '600px',overflow: 'auto'}}
      />
    </Modal>
  )
}
