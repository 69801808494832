import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Form, Input, message, Select } from 'antd'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  addApp,
  AppLanguageOption,
  getAllUsers,
  getAppLanguageList,
} from 'src/api'
import { FormLayout } from 'src/constants'
import { getAndSetAdminAppList } from './appManagementPageSlice'
import { getSplitListValue, validatorAccount } from 'src/util'

export const AddAppModal = () => {
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const { AddApp } = useSelector((state) => state.modal)
  const [addAppForm] = Form.useForm()

  const handleSubmitAddApp = () => {
    addAppForm.validateFields().then((values) => {
      setConfirmLoading(true)
      addApp(values)
        .then(() => {
          message.success('应用添加成功')
          dispatch(getAndSetAdminAppList())
          dispatch(hideModal('AddApp'))
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  const [userList, setUserList] = useState<any[]>([])

  const userSelectOptions = userList.map((user) => {
    const { userId, userName } = user
    return {
      label: `${userName}（${userId}）`,
      value: userId,
    }
  })

  const getAndSetUserList = () => {
    getAllUsers().then(setUserList)
  }

  const [languageList, setLanguageList] = useState<AppLanguageOption[]>([])
  const languageSelectOptions = languageList.map(({ name, value }) => ({
    label: name,
    value,
  }))

  const getAndSetLanguageList = () => {
    getAppLanguageList().then(setLanguageList)
  }

  useEffect(() => {
    getAndSetUserList()
    getAndSetLanguageList()
  }, [])

  return (
    <UIModal
      title="添加应用"
      visible={AddApp}
      onCancel={() => dispatch(hideModal('AddApp'))}
      onOk={() => handleSubmitAddApp()}
      afterClose={() => addAppForm.resetFields()}
      width={640}
      confirmLoading={confirmLoading}
    >
      <Form form={addAppForm} {...FormLayout}>
        <Form.Item
          name="appName"
          label="应用名称"
          rules={[{ required: true, message: '请输入应用名称' }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="appCode"
          label="应用 ID"
          required
          rules={[{ validator: validatorAccount }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item name="desc" label="应用描述">
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="appAdminId"
          label="负责人"
          rules={[{ required: true, message: '请输入负责人' }]}
        >
          <Select
            options={userSelectOptions}
            optionFilterProp="label"
            showSearch
          ></Select>
        </Form.Item>
        <Form.Item
          name="dbaId"
          label="DBA"
          rules={[{ required: true, message: '请输入 DBA' }]}
        >
          <Select
            options={userSelectOptions}
            optionFilterProp="label"
            showSearch
          ></Select>
        </Form.Item>
        <Form.Item
          name="hosts"
          label="主机列表"
          help="0.0.0.0 通配"
          rules={[{ required: true, message: '请输入主机列表' }]}
        >
          <Select
            mode="tags"
            allowClear
            onChange={(value: string[]) => {
              addAppForm.setFields([
                { name: 'hosts', value: getSplitListValue(value) },
              ])
            }}
            notFoundContent="逗号或分号隔开多台主机"
          ></Select>
        </Form.Item>
        <Form.Item
          name="language"
          label="应用语言"
          rules={[{ required: true, message: '请选择应用语言' }]}
        >
          <Select
            options={languageSelectOptions}
            optionFilterProp="label"
            showSearch
          ></Select>
        </Form.Item>
        <Form.Item name="labels" label="标签">
          <Select mode="tags" allowClear notFoundContent="请输入标签"></Select>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
