import React from 'react'
import { Form, Radio } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useDispatch, useSelector } from 'src/hook'
import { setFileType } from 'src/store/extraSlice/textImportSlice'

export function TextTypeForm({ form }: { form?: FormInstance }) {
  const { fileType } = useSelector((state) => state.textImport)
  const dispatch = useDispatch()

  return (
    <Form form={form} labelCol={{ span: 8 }} labelAlign="left">
      <Form.Item label="导入类型"></Form.Item>
      <Form.Item label="文本文件" initialValue={fileType} name="textType">
        <Radio.Group
          onChange={(e) => {
            dispatch(setFileType(e.target.value))
          }}
        >
          <Radio value="txt">文本文件(*.txt)</Radio>
          <Radio value="csv">CSV 文件(*.csv)</Radio>
          {/*<Radio value="xls">Excel 文件(*.xls)</Radio>*/}
          <Radio value="xlsx">Excel 文件(*.xlsx)</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  )
}
