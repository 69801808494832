import { Button, Popconfirm, Switch, Table, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRequest } from 'src/hook';
import { EditAlarmConfigModal } from '../alarmConfigCard/EditAlarmConfigModal';
import { delAlarm, getAlarm, updateAlarmStatus } from 'src/api';
import { TargetPersonEnum, typeEnum } from 'src/constants/systemSetting';
import { getValueByKey } from 'src/util';
import { isArray } from 'lodash';
import styles from '../index.module.scss'

interface systemWarnConfigColumnsDataType {
  key: string;
  type: string;
  value: number | string;
  alarmMethods: string[];
  targetPerson: string;
  userId: string[];
  status?: boolean;
  operation?: any;
}

interface IProps {
  tabNum: number,
  tabKey: string
}

// 参考用户管理
export const AlarmConfigListCard = (props: IProps) => {
  const {tabNum, tabKey} = props
  const { Text } = Typography
  const [editData, setEditData] = useState<any>();
  const [editType, setEditType] = useState<string>('');
  const [editAlarmConfigModalVisible, setEditAlarmConfigModalVisible] = useState<boolean>(false);

  // 查询
  const {
    data: alarmData,
    run: runGetAlarm,
    loading,
    refresh
  } = useRequest(getAlarm, {
    manual: true,
    onSuccess: (res) => {
      // 重新请求获取 告警列表
    },
  })

  // 删除
  const {
    run: runDelAlarm,
  } = useRequest(delAlarm, {
    manual: true,
    onSuccess: (res) => {
      message.success("删除成功")
    },
    onError: (err) => {
      message.error("删除失败")
      console.log('delAlarm 删除失败:>> ', err);
    }
  })

  useEffect(() => {
    runGetAlarm(tabNum)
  }, [runGetAlarm, tabNum])


  // update 状态
  const { fetches, run: runUpdateAlarmStatus } = useRequest(updateAlarmStatus, {
    manual: true,
    onSuccess: () => {
      // 重新请求获取 告警列表
      runGetAlarm(tabNum)
      message.success("编辑成功")
    },
    onError: (err) => {
      message.error("编辑失败")
      console.log('updateAlarmStatus 失败 :>> ', err);
    }
  })

  

  const handleEdit = (record: any) => {
    setEditData(record)
    if (tabNum === 0) {
      setEditType("editSystem")
    } else if (tabNum === 1) {
      setEditType("editBusiness")
    }
    setEditAlarmConfigModalVisible(true)
  }

  const columns = [
    {
      title: (tabNum === 0) ? '系统告警类型' : '业务告警类型' ,
      dataIndex: 'type',
      key: 'type',
      render: (text: string[]) => {
        const str =  getValueByKey(typeEnum, text)
        return <Text>{str}</Text>
      }
    },
    {
      title: (tabNum === 0) ? '阈值' : '告警条件' ,
      dataIndex: 'value',
      key: 'value',
      render: (text: string[]) => {
        return <Text>{text ? text : '—'}</Text>
      }
    },
    {
      title: '告警方式',
      dataIndex: 'methodDetail',
      key: 'methodDetail',
      ellipsis: true,
      render: (text: string[]) => {
        return <Text>{text? text : "—"}</Text>
      }
    },
    {
      title: '消息接收人类型',
      dataIndex: 'targetPerson',
      key: 'targetPerson',
      render: (text: string) => {
        const str = text ? getValueByKey(TargetPersonEnum, text) : ''
        return <Text>{str? str : "—"}</Text>
      }
    },
    {
      title: '消息接收人',
      dataIndex: 'userNameWithIds',
      key: 'userNameWithIds',
      ellipsis: true,
      render: (text: string[]) => {
        let str = "—"
        if (text && text.length > 0) {
          str = text.join(",")
        }
        return <Text>{str}</Text>
      }
    },
    {
      title: '状态',
      key: 'status',
      render: (record: any) => (
        <Switch
          checkedChildren="启用"
          unCheckedChildren="禁用"
          checked={record?.status}
          loading={fetches[record.id]?.loading}
          onChange={(checked) => {
            runUpdateAlarmStatus({ id: record.id, status: checked })
          }}
        />
      ),
    },
    {
      title: '操作',
      key: 'operation',
      render: (record: any) => (
        <div className={styles.displayInLineFlex}>
        <Button
          type = 'link'
          onClick={() => handleEdit(record)}
          style={{ paddingLeft: 0}}
        >
          编辑
        </Button>
        </div>
      ),
    },
  ]

  return (
    <>
    <Table
      key={tabKey}
      columns={columns}
      dataSource={alarmData}
      pagination={false}
      loading={loading}
    />
    {
      editAlarmConfigModalVisible &&
      <EditAlarmConfigModal
        visible={editAlarmConfigModalVisible}
        setVisible={setEditAlarmConfigModalVisible}
        data={editData}
        operationType={editType}
        refresh={refresh}/>
    }
    
    </>
  )
}
