import React from 'react';
import { Input, Button} from 'antd';

export const TabSqlPane = ({
    isTableCreated,
    onCreateTable,
    onGeneratedSql
}: {
    isTableCreated: boolean;
    onCreateTable: () => void;
    onGeneratedSql: string;
}) => {
  return (
    <>
      <Button
        size="small"
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={onCreateTable}
        disabled={isTableCreated}
      >
        提交
      </Button>
      <Input.TextArea
        readOnly
        value={onGeneratedSql}
        autoSize={{ minRows: 6, maxRows: 6 }}
      />
    </>
  )
}
