/*
 * @Author: fuzhenghao
 * @Date: 2022-11-17 20:08:40
 * @LastEditTime: 2022-12-02 15:53:27
 * @LastEditors: fuzhenghao
 * @Description:
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\queryPage\sdt\DropdownMenu\utils.ts
 */

import { DataSourceType } from 'src/api'
import { getInfoFromPath } from 'src/util'

export function getSchemaName(
  nodePath: string = '',
  connectionType: string = 'MySQL',
) {
  let schemaName = ['SQLServer', 'PostgreSQL', 'PolarDB', 'MogDB', 'OracleCDB','StarRocks'].includes(
    connectionType,
  )
    ? getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
    : connectionType === 'DamengDB'
    ? getInfoFromPath(nodePath, 'schema', 'DamengDB')
    : getInfoFromPath(nodePath, 'connection', connectionType as DataSourceType)

  return schemaName
}
