import { editor } from 'monaco-editor'

/**
 * @description 获取 editor 中选中语句
 * @param editorInstance
 */
export const getSelectedText = (
  editorInstance: editor.IStandaloneCodeEditor,
) => {
  const model = editorInstance.getModel()
  const selection = editorInstance.getSelection()
  let selectedText: string | undefined
  if (model && selection) {
    selectedText = model.getValueInRange(selection).trim()
  }
  return selectedText
}

/**
 * @description 获取待执行语句
 * @param editorInstance
 */
export const getSqlToExecute = (
  editorInstance: editor.IStandaloneCodeEditor,
) => {
  return getSelectedText(editorInstance) || editorInstance.getValue().trim()
}
