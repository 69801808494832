
import { fetchPost, fetchGet, fetchDelete } from "../customFetch";

export interface IHierarchicalAuthNodeItem {
  id: number;
  name: string;
  adminUserId: string;
  adminUserName: string
  parentId: null
  select?: boolean
  count: number,
  childrenList: IHierarchicalAuthNodeItem[]
}
// tree
export const getHierarchicalAuthGroup = (): Promise<IHierarchicalAuthNodeItem[]> => { 
	return fetchGet(`/user/permission/authorizationGroup/sdt`);
}

export interface IResourceItem {
  nodeName: string;
  creatorId: string;
  creatorName: string;
  description: null;
  adminUserInfos: string[];
  nodeType: string;
  nodePathWithType: string;
  childrenList?: IResourceItem[]
}

// resource list
export const getSingleNodeResource= (groupId: number): Promise<IResourceItem[]> => { 
	return fetchGet(`/user/permission/authorizationGroup/sdt/${groupId}`);
}

interface ISaveResourceParams {
  id?: number;
  parentId: number;
  name: string;
  description: string;
  adminUserId: string;
  nodePathWithTypeList: string[]
}
// resource list
export const saveAuthResources= (params: ISaveResourceParams): Promise<any> => { 
	return fetchPost(`/user/permission/authorizationGroup/save`,params);
}

//解除
export const deleteGroupAuth= (params: {groupId: number}): Promise<any> => { 
	return fetchDelete(`/user/permission/authorizationGroup/delete`,params);
}

//修改 选中资源
export const getSelectedTreeResource= (groupId: number): Promise<IResourceItem[]> => { 
	return fetchGet(`/user/permission/authorizationGroup/sdt/select/${groupId}`);
}