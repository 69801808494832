import { SplitSqlStatement } from "./SqlParserEngine";
import { ISqlParserContext } from "./sql/SqlParserContext";
import { ISqlStatement } from "./sql/SqlStatement";

export function handleSqlSplit(
  dataSource: string,
  text: string
): ISqlStatement[] {
  const sqlParserContext: ISqlParserContext = {
    dataSource: dataSource,
    sqlContent: text,
  };
  return SplitSqlStatement(sqlParserContext);
}
