import React from 'react'
import { Button } from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd/lib/button'
import classNames from 'classnames'
// import styles from './index.module.scss'

export const CreateButton: React.FC<ButtonProps> = (props) => {
  const { children, className, ...rest } = props
  return (
    <Button
      className={classNames(className)}
      type="primary"
      icon={<PlusSquareOutlined />}
      {...rest}
    >
      {children}
    </Button>
  )
}
