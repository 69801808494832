import React, { useRef } from 'react'
import {
  Col,
  Space,
  Tooltip,
  Dropdown,
  Input,
  Menu,
  message,
  Row,
  Tag,
} from 'antd'
import { Range } from 'monaco-editor'
import { Iconfont, LinkButton } from 'src/components'
import { FavoriteEditModal } from './FavoriteEditModal'
import { FavoriteManagementModal } from './FavoriteManagementModal'
import { useDispatch, useRequest } from 'src/hook'
import { CloseCircleFilled } from '@ant-design/icons'
import { debounce } from 'lodash'
import {
  saveFavorite,
  searchFavorites,
  updateFavoriteStatus,
  deleteFavorite,
} from 'src/api'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { getSqlToExecute } from 'src/util'
import { setLastSavedFavorite } from '../../queryTabsSlice'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import styles from './index.module.scss'

export const MonacoToolbarFavorite = () => {
  const [editorInstance] = useEditorInstance()
  const dispatch = useDispatch()
  const { run: tryAddFavorite } = useRequest(saveFavorite, {
    manual: true,
    onSuccess: (favorite) => {
      dispatch(setLastSavedFavorite(favorite))
      message.success(
        <>
          收藏添加成功
          <LinkButton
            onClick={() => {
              dispatch(showModal('ModalFavoriteAdd'))
            }}
          >
            设置别名
          </LinkButton>
        </>,
      )
    },
  })

  const {
    run: tryGetFavorites,
    data: favorites = [],
    refresh: reGetFavorites,
    loading: loadingGetFavorites,
    mutate: mutateGetFavorites,
  } = useRequest(searchFavorites, {
    manual: true,
    throttleInterval: 350,
    formatResult: (favorites) => favorites.slice(0, 11),
  })
  const { run: silentlyUpdateFavoriteStatus } = useRequest(
    updateFavoriteStatus,
    { manual: true },
  )
  const { run: tryDeleteFavorite } = useRequest(deleteFavorite, {
    manual: true,
    onSuccess: () => {
      message.success('收藏删除成功')
      reGetFavorites()
    },
  })

  const focusRef = useRef<Input>(null)

  const FavoritesMenu = (
    <Menu className={styles.favoriteMenu}>
      <Menu.Item>
        <Input.Search
          onClick={(e) => e.stopPropagation()}
          onSearch={(keyword, e) => {
            e?.stopPropagation()
            tryGetFavorites({ keyword })
          }}
          onChange={(e) => {
            const keyword = e.target.value || ''
            tryGetFavorites({ keyword })
          }}
          placeholder="搜索语句或别名"
          size="small"
          ref={focusRef}
          loading={loadingGetFavorites}
          // todo: 添加 allowClear。
          // todo: 现在如果添加，点击清除按钮会击穿导致 Dropdown 意外收起
        ></Input.Search>
      </Menu.Item>
      <Menu.Divider />
      {favorites.map((favorite) => {
        const { statement = '', statementName, id, digestStatement } = favorite
        return (
          <Menu.Item
            onClick={() => {
              silentlyUpdateFavoriteStatus(id!)
              // todo: 处理多光标插入
              // todo: 提取公共方法，处理「使用收藏」
              var position = editorInstance?.getPosition()
              if (!position) return
              // 插入到光标下一行，聚焦编辑器，移动光标到插入内容前
              const { lineNumber } = position
              editorInstance?.executeEdits('', [
                {
                  range: new Range(lineNumber + 1, 1, lineNumber + 1, 1),
                  text: statement + '\n',
                  forceMoveMarkers: true,
                },
              ])
              editorInstance?.focus()
              editorInstance?.setPosition({
                lineNumber: lineNumber + 1,
                column: 0,
              })
            }}
            key={id}
          >
            <div className={styles.favoriteMenuItem}>
              <Row justify="space-between" className={styles.itemContent}>
                <div className={styles.itemSql}>{digestStatement}</div>
                {statementName && (
                  <Tag
                    className={styles.itemTag}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {statementName}
                  </Tag>
                )}
              </Row>
              <CloseCircleFilled
                onClick={(e) => {
                  e.stopPropagation()
                  tryDeleteFavorite(id!)
                }}
                className={styles.closeIcon}
              />
            </div>
          </Menu.Item>
        )
      })}
      {favorites[0] ? (
        <>
          <Menu.Divider />
          <Menu.Item
            onClick={() => dispatch(showModal('ModalFavoriteManagement'))}
          >
            更多...
          </Menu.Item>
        </>
      ) : (
        <Menu.Item disabled>暂无收藏</Menu.Item>
      )}
    </Menu>
  )

  const handleAddFavorite = debounce(() => {
    if (!editorInstance) return
    const sql = getSqlToExecute(editorInstance)
    if (!sql) return message.info('未选中语句')
    tryAddFavorite({ statement: sql })
  }, 500)

  return (
    <Col>
      <Space size={1}>
        <Tooltip title="添加收藏" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-favorite"
            onClick={handleAddFavorite}
          />
        </Tooltip>
        <Dropdown
          overlay={FavoritesMenu}
          placement="bottomCenter"
          trigger={['hover']}
          mouseLeaveDelay={0.3}
          onVisibleChange={(visible) => {
            if (!visible) return
            mutateGetFavorites([])
            tryGetFavorites({ limit: 10 })
            focusRef.current?.setValue('')
            focusRef.current?.select()
          }}
        >
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-favorite-list"
          />
        </Dropdown>
      </Space>
      <FavoriteEditModal />
      <FavoriteManagementModal />
    </Col>
  )
}
