import React, { useRef, useState, useEffect, useMemo, useReducer } from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  FilterOutlined,
  CaretUpFilled,
  CaretDownFilled,
} from '@ant-design/icons'
import { IHeaderParams } from '@ag-grid-community/core'
import classNames from 'classnames'
import styles from './CustomHeader.module.scss'

type SortStatus = 'asc' | 'desc' | ''

interface CustomHeaderProps extends IHeaderParams {
  headerName: string
  comment: string
}

export const CustomHeader: React.FC<CustomHeaderProps> = (props) => {
  const {
    headerName,
    comment,
    enableMenu,
    enableSorting,
    column,
    showColumnMenu,
    setSort,
  } = props
  const [, forceUpdate] = useReducer((r) => r + 1, 0)
  const [sortStatus, setSortStatus] = useState<SortStatus>('')
  const filterActive = column.isFilterActive()

  const menuButtonRef = useRef<any>(null)

  const nextSortStatus = useMemo(() => {
    switch (sortStatus) {
      case 'asc':
        return 'desc'
      case 'desc':
        return ''
      default:
        return 'asc'
    }
  }, [sortStatus])

  useEffect(() => {
    const onSortChanged = () => {
      let sortStatus: SortStatus = ''
      if (column.isSortAscending()) sortStatus = 'asc'
      if (column.isSortDescending()) sortStatus = 'desc'
      setSortStatus(sortStatus)
    }
    const onFilterChanged = () => {
      forceUpdate()
    }

    const debounce = (fn: ()=>void, wait: number) => {
      let timeId: any = null
      return () => {
        if(timeId !== null) {
          clearTimeout(timeId)
        }
        timeId = setTimeout(fn, wait)
      }
    }
    column.addEventListener('sortChanged', onSortChanged)
    column.addEventListener('filterChanged', debounce(onFilterChanged, 1000))
    return () => {
      column.removeEventListener('sortChanged', onSortChanged)
      column.removeEventListener('filterChanged', debounce(onFilterChanged, 1000))
    }
  }, [column])

  return (
    <div className={styles.headerWrapper}>
      <div
        className={styles.headerSortersWithTooltip}
        onClick={(e) => {
          if (!enableSorting) return
          setSort(nextSortStatus, e.shiftKey)
        }}
      >
        <Tooltip title={comment}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <span>{headerName}</span>
            {!!comment && comment.trim().length > 0 && <InfoCircleOutlined style={{marginLeft: '5px'}} />}
            {enableSorting && (
              <span className={styles.headerSorters}>
                <CaretUpFilled
                  className={classNames(
                    styles.headerSortUp,
                    sortStatus === 'asc' && styles.active,
                  )}
                />
                <CaretDownFilled
                  className={classNames(
                    styles.headerSortDown,
                    sortStatus === 'desc' && styles.active,
                  )}
                />
              </span>
            )}
          </div>
        </Tooltip>
      </div>
      {enableMenu && (
        <span
          className={styles.headerMenu}
          ref={(menuButton) => {
            menuButtonRef.current = menuButton
          }}
          onClick={(e) => {
            showColumnMenu(menuButtonRef.current)
            e.stopPropagation()
          }}
        >
          <FilterOutlined
            className={classNames(filterActive && styles.active)}
          />
        </span>
      )}
    </div>
  )
}
