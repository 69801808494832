import React from 'react';
import moment from 'moment';
import { Button, Modal, Space, Tooltip} from 'antd';
import { ExclamationCircleFilled} from '@ant-design/icons';
import type {  IDesensImportTaskItem } from 'src/api'
import styles from './index.module.scss';

const showDeleteConfirm = (record: IDesensImportTaskItem) => {
  Modal.info({
    title: record?.fileName,
    icon: <ExclamationCircleFilled />,
    content: record?.errorMessage,
  });
};


export const columns = ({
  onDeleteUploadedFile
}: {
  onDeleteUploadedFile: (ids: number[]) => void;
},  permissionlist: any, permissionSupportData: boolean) =>[
    {
      dataIndex: 'datasourceType',
      title: '数据库类型'
    },
    {
      dataIndex: 'connectionName',
      title: '连接名'
    },
    {
      dataIndex: 'databaseName',
      title: '数据库名'
    },
    {
      dataIndex: 'fileName',
      title: '导入文件名'
    },
    {
      dataIndex: 'completedTime',
      title: '完成时间',
      render: (time: string) => moment.utc(time).local().format('YYYY-MM-DD HH:mm:ss')
    },
    {
      dataIndex: 'countOfSucceed',
      title: '导入行数',
      width: 100,
    },
    {
      dataIndex: 'countOfFailed',
      title: '失败行数',
      width: 100,
      render: (num: number) => <span style={{color: '#DF3930'}}>{num}</span>
    },
    {
      dataIndex: 'errorMessage',
      title: '操作',
      fixed: 'right',
      render: (mes: string, record: IDesensImportTaskItem) => <Space>
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          }
        >
          <Button
            type='link'
            className={styles.linkBtn}
            onClick={() => onDeleteUploadedFile([record.id])}
            disabled={!permissionSupportData}
          >
            删除
          </Button>
        </Tooltip>
       {
        record?.countOfFailed ? <span className={styles.linkBtn} onClick={() => showDeleteConfirm(record)}>失败详情</span> : ''
       }
      </Space>
    },
  ]