import { Alert, Tabs } from "antd";
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "src/hook";
import { setActiveTab } from "../DataProtectionPageSlice";
import { DEFAULT_KEY, TABS_PANE } from "../constant";
import { DispatchView } from "./dispatch";
import { TabKey } from "./type";
import styles from '../index.module.scss'
import ImportDesConfig from "./ImportDesConfig";
import { CommonEditDesensRulesModal, PolicySettingModal, DesensitizedUserListModal } from './Dialog'

const DataProtectionMain = (): JSX.Element => {
  const defaultActiveKey = DEFAULT_KEY;
  const dispatch = useDispatch();
  const { selectedNode, activeTab, importDesConfigModalVisible } = useSelector((state) => state.dataProtection);

  const { nodeType = '' } = selectedNode?.props ?? {} as any;


  const filteredTabs = useMemo(() => {
    if (!nodeType) return TABS_PANE;

    let newTabs = cloneDeep(TABS_PANE);

    if (nodeType && nodeType === "table") {
      newTabs["DESENS"] = "脱敏设置";
      //@ts-ignore
      delete newTabs['DATA_INFO']
    }
    return newTabs;
  }, [TABS_PANE, nodeType]);

  return (
    <div  className={styles.dataProtectWrap} style={{backgroundColor: '#fff', height: '100%'}}>
      {
        importDesConfigModalVisible ?
         <ImportDesConfig />
         :
         <>    
            <Tabs
              defaultActiveKey={defaultActiveKey}
              tabPosition="top"
              animated
              onChange={(e) => dispatch(setActiveTab(e))}
              activeKey={activeTab}
            >
              {Object.keys(filteredTabs).map((key) => (
                <Tabs.TabPane tab={filteredTabs[key as TabKey]} key={key} />
              ))}
            </Tabs>
            <div  className={styles.viewContent}>
            { 
              activeTab === "FILTER_ROW" && 
              <Alert
                message="需要进行行过滤设置请展开到具体表上即可设置"
                type="info"
                showIcon
                style={{marginBottom: 10}}
              />
            }
              <DispatchView />
            </div>
            {/* 编辑脱敏字段 公用*/}
            <CommonEditDesensRulesModal />
            {/* 策略修改 */}
            <PolicySettingModal />
            {/* 申请脱敏用户列表 */}
            <DesensitizedUserListModal />
         </>
      }
    </div>
  );
};

export { DataProtectionMain };

