// 访问申请-我的申请-申请清单
import React, { FC } from 'react'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { MENU_FLOW } from 'src/constants';
import { SimpleBreadcrumbs } from 'src/components'
import { SubmitApplicationsPage } from './submit-applications'
import { ResourceBidPage } from './resource-bid'
import useRequest from '@ahooksjs/use-request'
import { deleteCartDataFat, getCartDataFAT } from 'src/api'
import { Spin, message } from 'antd'
import { setRequestListData } from '../accessRequestSlice'
import { useDispatch } from 'src/hook'
import styles from './index.module.scss'


const breadcrumbData = [
	{ title: MENU_FLOW },
	{
		title: "我的申请",
		href: '/mine_apply',
	},
	{
		separator: '/',
		title: "申请清单",
	}
];

export const ApplicationCartPage = () => {
  const dispatch = useDispatch()
	const { data, loading, refresh } = useRequest(getCartDataFAT, {
		formatResult(res) {
			dispatch(setRequestListData(res?.list))
			return res || []
		}
	})

	const {run: delDataFat} = useRequest(deleteCartDataFat, {
		manual: true,
		onSuccess() {
			message.success('删除成功')
			refresh()
		}
	})

	const delCartFn = (flowUUID: any) => {
		delDataFat(flowUUID)
	}

	return (
		<div className={styles.submitApplicationWrap}>
			<SimpleBreadcrumbs items={breadcrumbData} />
			<Spin spinning={loading}>
				<div className={styles.content}>
					<ErrorBoundary>
						<ResourceBidPage delScFn={delCartFn} refreshAppResource={() => refresh()}/>
					</ErrorBoundary>
					<ErrorBoundary>
						<SubmitApplicationsPage 
							beginTime={data?.beginTime}
							endTime={data?.endTime}
							title={data?.title}
							remark={data?.remark}
						/>
					</ErrorBoundary>
				</div>
			</Spin>
		</div>
	)
}
