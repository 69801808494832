/**
 * @param prevRow 修改前的行数据
 * @param nextRow 修改后的行数据
 * @return object with altered fields
 */

import { PlainRowData } from 'src/api'

export const getAlteredRowData = (
  prevRow: PlainRowData,
  nextRow: PlainRowData,
) => {
  const altered: PlainRowData = {}
  for (let key of Object.keys(nextRow)) {
    if (
      [null, undefined, ''].some((v) => v === prevRow[key])&&
      [null, undefined, ''].some((v) => v === nextRow[key])
    ) {
      continue
    }
    if (nextRow[key] !== prevRow[key]) {
      altered[key] = nextRow[key]
    }
  }
  return altered
}
