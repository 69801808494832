import dayjs from 'dayjs'

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null]

export const formatDateRange = ([start, end]: RangeValue): [
  number | undefined,
  number | undefined,
] => {
  return [start?.startOf('d').valueOf(), end?.endOf('d').valueOf()]
}
