import { UploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Upload, Tooltip, message } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './index.css'

interface DragableUploadListItemProps {
  file: UploadFile;
  fileRenderList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
  onRemove: (p: any) => void;
  [p: string]: any
}

interface IProps {
  showUploadList?: boolean, // 是否展示上传列表， 默认展示
  accept?: string, 
  multiple?: boolean,
  directory?: boolean,
  [p: string]: any
}

// 是否超过限制大小
const isLimitSize = (fileSize: number, uploadSize: number) => !uploadSize || (fileSize / 1024 / 1024) < uploadSize

export const CustomDragableUploadList = (
  { 
    showUploadList=true,
    accept, 
    multiple, 
    directory, 
    uploadSize,
    buttonProps, 
    fileListWidth, 
    value, 
    onChange, 
    handleMoveRow, 
    handleDeleteRow,
    callBackIsLimitSize,
    notAllowCompresPack,
  }: IProps) => {
  const [fileRenderList, setFileRenderList] = useState<any[]>([]);

  useEffect(()=>{
    if(value){
      setFileRenderList(value)
    }
  },[value])

  const moveRow = (dragIndex: number, hoverIndex: number)=>{
    handleMoveRow && handleMoveRow(dragIndex, hoverIndex)
  }
 
  const handleUploadChange: UploadProps['onChange'] = ({ file, fileList: newFileList }) => {
    const isCompressed = ['application/zip', 'application/x-rar-compressed', 'application/x-zip-compressed'].includes(file.type);
    // 禁止上传压缩包
    if(notAllowCompresPack && isCompressed){
      return
    }
    // 单个文件大小校验
    if (!isLimitSize(file?.size, uploadSize)) {
      return 
    }
    let filterNewFileList = [...newFileList]
    if(uploadSize){
      // 过滤单个大小超过限制的文件
      filterNewFileList = filterNewFileList.filter((item: any) => (item?.size / 1024 / 1024) < uploadSize)
    }
    if(notAllowCompresPack){
      // 过滤压缩包文件
      filterNewFileList = filterNewFileList.filter((item: any) => !['application/zip', 'application/x-rar-compressed'].includes(item?.type))
    }
    // @ts-ignore
    const relativePath = file?.webkitRelativePath;
    const folderName = relativePath?.split("/")[0];
    onChange && onChange(filterNewFileList, fileRenderList, folderName);
  };

  // 从文件列表中移除文件
  const handleRemove = (index: number) => {
    handleDeleteRow && handleDeleteRow(index)
  };
 
  // 统计多个文件大小
  let totalSize = 0;
  const customRequest = ({file}: any) => {
    totalSize += file?.size
    const limitSizeFlag = isLimitSize(totalSize, uploadSize)
    callBackIsLimitSize && callBackIsLimitSize(limitSizeFlag)
  }

  const uploadProps: UploadProps = {
    accept,
    multiple,
    directory,
    disabled: buttonProps?.disabled,
    showUploadList: false,
    fileList: value,
    onChange: handleUploadChange,
    beforeUpload: (file: any) => {
      const isCompressed = ['application/zip', 'application/x-rar-compressed', 'application/x-zip-compressed'].includes(file.type);
      // 禁止上传压缩包
      if(notAllowCompresPack && isCompressed){
        message.error('禁止上传压缩包文件！');
        return false
      }
      // 单个文件大小校验
      if (!isLimitSize(file?.size, uploadSize)) {
        message.error(`文件大小不能超过${uploadSize}M!`);
        return false
      }
      return true
    },
    customRequest: customRequest
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Upload {...uploadProps}>
        {
          buttonProps?.tooltipTxt ?
            <Tooltip title={buttonProps.tooltipTxt}>
              <Button 
                {...buttonProps} 
                icon={<UploadOutlined />}
              >
                {buttonProps?.buttonText ?? "选择文件"}
              </Button>
            </Tooltip>
          : <Button 
              {...buttonProps} 
              icon={<UploadOutlined />}
            >
              {buttonProps?.buttonText ?? "选择文件"}
            </Button>
        }
        
      </Upload>
      {
        showUploadList &&
        fileRenderList?.map((file: any)=>{
          return (
            <DragableUploadListItem 
              file={file}
              fileRenderList={fileRenderList}
              moveRow={moveRow}
              onRemove={handleRemove}
              fileListWidth={fileListWidth}
            />
          )
        })
      }
    </DndProvider>
  );
};

const DragableUploadListItem = ({
  file,
  fileRenderList,
  fileListWidth='100%',
  moveRow,
  onRemove,
}: DragableUploadListItemProps) => {
  const type = 'DragableUploadList';
  const ref = useRef(null);
  const index = fileRenderList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
 
  return (
    <div
      ref={ref}
      className={`upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', width: fileListWidth }}
    >
      <span>
        <PaperClipOutlined className='mr4'/>
        {file?.name}
      </span>
      <DeleteOutlined className='options' onClick={()=>onRemove(index)}/>
    </div>
  );
};