import React from "react";
import { useSelector } from "src/hook";
import { initialView } from "./initialView";

const DispatchView = (): JSX.Element => {
  const { selectedNode, activeTab } = useSelector(state => state.dataProtection);

  if (!selectedNode) return <div></div>;

  const dispatchInstance = initialView();

  const { nodeType } = selectedNode.props ?? {};
  const key = `${nodeType}_${activeTab}`;
  
  return dispatchInstance.apply(key);
};

export { DispatchView };

