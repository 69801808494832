import { Button, Space, Spin, Table, Tooltip, Typography, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { permissionSupport, removeFilterRule, updateFilterRule } from "src/api";
import { useRequest, useSelector } from "src/hook";
import { RuleRequest } from "./TableFilterRow";
import { getCurrentModulePermissionByUrl } from "src/util";

interface TableType {
  id: number;
  rowFilterId: string;
  content: string;
  status: 0 | 1;
  nodePathWiteType: string;
  createdAt: string;
  updateAt: string;
}

// second table
const FilterRuleTable = (): JSX.Element => {
  const { selectedNode, selectedNodePermissionInfo } = useSelector(state => state.dataProtection);
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};
  
  const { loading, tableInfo, queryRule, refreshView } = useContext(RuleRequest)!;

  const { run: removeRule } = useRequest(removeFilterRule, {
    manual: true,
    onSuccess() {
      message.success("删除成功");
      refreshView();
    },
  });

  const { run: toggleStatus } = useRequest(updateFilterRule, {
    manual: true,
    onSuccess() {
      message.success("修改成功");
      refreshView();
    },
    onError() {
      refreshView();
    },
  });

  function handleRemove(id: number) {
    removeRule({ id: String(id) });
  }

  function handleToggle(record: TableType) {
    toggleStatus({
      id: String(record.id),
      status: record.status ? 0 : 1,
    });
  }

  const columns: ColumnsType<TableType> = [
    { dataIndex: "id", title: "序号", width: "120px" },
    {
      dataIndex: "content",
      title: "过滤规则",
      ellipsis: true,
    },
    {
      dataIndex: "status",
      title: "状态",
      width: "120px",
      ellipsis: true,
      render: value => {
        return value ? <Typography.Text>启用</Typography.Text> : <Typography.Text disabled>禁用</Typography.Text>;
      },
    },
    {
      key: "operation",
      title: "操作",
      align: "center",
      render(_, record) {
        return (
          <Space>
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
            >
              <Button
                type="link"
                style={{ color: permissionSupportData ?"#324564" : "#bfbfbf" }}
                onClick={() => handleRemove(record.id)}
                disabled={!permissionSupportData}
              >
                移除
              </Button>
            </Tooltip>
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
            >
              {record.status ? (
                <Button
                  type="link"
                  onClick={() => handleToggle(record)}
                  disabled={!permissionSupportData}
                >
                  禁用
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => handleToggle(record)}
                  disabled={!permissionSupportData}
                >
                  启用
                </Button>
              )}
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedNode) {
      //@ts-ignore
      const { connectionType: dataSourceType, nodePathWithType, nodePath } = selectedNode.props;
      const params = {
        dataSourceType,
        nodePathWithType,
        nodePath,
      };
      queryRule(params);
    }
  }, [selectedNode, queryRule]);

  return (
    <Spin spinning={loading}>
      <Table
        title={() => <div style={{ fontSize: "18px" }}>行过滤规则</div>}
        style={{ marginBottom: "40px" }}
        columns={columns}
        dataSource={tableInfo}
        pagination={false}
      ></Table>
    </Spin>
  );
};

export { FilterRuleTable };

