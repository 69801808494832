import { Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { queryTransferTask } from "src/api/dataTransfer";
import { Iconfont } from "src/components";
import {getScrollX} from 'src/util'
import { useRequest } from "src/hook";
import { formatSourceData, formatTargetData, renderObjectLabel, renderState } from "../utility";
import styles from './index.module.scss';

const onFinishStatus: number[] = [5];

const OnFinishTable = ({ searchValue }: { searchValue: string }): JSX.Element => {
	const firstMounted = useRef<boolean>(true);
	const {
		data,
		run: queryTask,
		loading,
		pagination,
	} = useRequest(
		async (_, params) => {
			return queryTransferTask(params);
		},
		{
			pollingInterval: 10000,
      debounceInterval: 300,
			pollingWhenHidden: false,
			manual: true,
			paginated: true,
			formatResult(res) {
				return {
					list: res.data,
					total: res.totalItem,
				};
			},
		},
	);

	useEffect(() => {
		if (firstMounted.current) {
			firstMounted.current = false;
		}
		return () => {
			firstMounted.current = true;
		};
	}, []);

  const getData = () => {
    if(searchValue){
      pagination.current = 1;
    }
    const params = {
      pageNo: pagination.current,
      pageSize: 20,
      status: onFinishStatus,
      keyword: searchValue
    };
    const pageParams = {
      current: params.pageNo,
      pageSize: params.pageSize,
    };

    queryTask(pageParams, params);
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTask, searchValue]);

	function getLoadingStatus(): boolean {
		return firstMounted.current ? loading : false;
	}

	const handleTableChange = (
    pagination: any,
    filters: Record<string, any>,
  ) => {
		const {current, pageSize} = pagination
		const {taskType: taskTypes} = filters

		const params: any = {
			pageNo: current,
			pageSize: 20,
			status: onFinishStatus,
      keyword: searchValue
		};
		if(taskTypes){
			params.taskTypes = taskTypes
		}
		const pageParams = {
			current,
			pageSize,
		};
		queryTask(pageParams, params);
	}
	return (
		<Table
		  className={styles.table}
			columns={RenderColumns()}
			loading={getLoadingStatus()}
			dataSource={data?.list ?? []}
			// @ts-ignore
			pagination={{ ...pagination, showSizeChanger: false }}
			scroll={{ x: getScrollX(RenderColumns()), y: `calc(100vh - 250px)` }}
			onChange={handleTableChange}
		></Table>
	);
};



const RenderColumns = (): ColumnsType<any> => {
	const history = useHistory();

	const lookDetail = (data: any) => {
		history.push({
			pathname: '/transfer-task-record-details',
			state: {
				parentId: data?.id,
				statusFlag: false,
				parentDetail: data
			}
		})
	}

	return [
		{
			dataIndex: "id",		
			title: "任务编号",
			width: 120,
			fixed: "left",
			render:(txt: string, record: any)=>{
				const idStr = record?.id?.toString()
				const serialNumberStr= record?.serialNumber?.toString() 
				const showTxt = serialNumberStr ? serialNumberStr : idStr
				return <a onClick={() => { lookDetail(record) }}>
					{ 
						showTxt
							? 
								<Tooltip title={showTxt}>{showTxt?.length >16 ? showTxt?.substring(0,16)+'...' : showTxt}</Tooltip> 
							: '_'
					}
				</a>
			}
		},
		{
			dataIndex: "taskName",
			title: "任务名称",
			width: 100,
			ellipsis: true,
			fixed: "left",
		},
		{
			dataIndex: "taskType",
			title: "任务类型",
			width: 120,
			ellipsis: true,
			filters: [
				{
					text: '静态脱敏',
					value: 'STATIC_DESENSITIZATION',
				},
				{
					text: '数据传输',
					value: 'DATA_TRANSMISSION',
				},
			],
			render(value) {
				switch (value) {
					case "STATIC_DESENSITIZATION": {
						return "静态脱敏";
					}
					case "DATA_TRANSMISSION": {
						return "数据传输";
					}
					default:
						return "";
				}
			},
		},
		// {
		// 	dataIndex: "desensitizationGrade",
		// 	title: "脱敏等级",
		// 	width: "100px",
		// 	ellipsis: true,
		// },
		{
			key: "source",
			title: "源",
			width: 300,
			ellipsis: true,
			render(value, record) {
				return renderObjectLabel(formatSourceData(record));
			},
		},
		{
			key: "target",
			title: "目标",
			width: 300,
			ellipsis: true,
			render(value, record) {
				return renderObjectLabel(formatTargetData(record));
			},
		},
		{
			dataIndex: "state",
			title: "状态",
			width: 100,
			ellipsis: true,
			render(value) {
				return renderState(value);
			},
		},
		{
			dataIndex: "createTime",
			title: "发起时间",
			width: 200,
			ellipsis: true,
			render(value) {
				return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
			},
		},
		{
			dataIndex: "updateTime",
			title: "更新时间",
			width: 200,
			ellipsis: true,
			render(value) {
				return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
			},
		},
		{
			dataIndex: "owner",
			title: "创建人",
			ellipsis: true,
			width: 100,
		},
		{
			key: "operation",
			title: "操作",
			ellipsis: true,
			width: 100,
			render(value, record) {
				return <Space>
					<Tooltip title="查看详情">
						<Iconfont
							type="icon-chakanxiangqing"
							className={classNames(styles.iconcolor, styles.fs18)}
							onClick={() => {lookDetail(record)}}
						/>
					</Tooltip>
				</Space>
			},
		}
	];
};

export { OnFinishTable };

