
import React, { useEffect, useState, useMemo } from 'react'
import { Chart } from '@antv/g2';
import { Button, Spin } from 'antd';
import chartStyles from './chart.module.scss'


interface IProps {
  data: any[],
  userOperationUnit: string,
  setUserOperationUnit: (a: any) => void,
  loading: any,
}

const UserOperationChart = (props: IProps) => {
  const { data, userOperationUnit, setUserOperationUnit, loading } = props;

  const userOperationRenderChart = (container: any, chartData: any) => {
    const chart = new Chart({
      container: container,
      autoFit: true,
    });
    chart.data(chartData);
    chart.scale('amount', {
      alias: '操作量',
    });

    chart.scale('unit', {
      type: 'time',
      mask: 'YYYY-MM-DD HH:mm:ss', // 设置日期时间格式
    });

    chart.axis('unit', false);
    chart.axis('amount', false);

    chart.tooltip({
      showMarkers: false,
    });
    chart.interval().position('unit*amount');
    chart.interaction('element-active');
    chart.render();
    return chart;
  }

  useEffect(() => {
    const chart = userOperationRenderChart('userOperationContainer', data);
    return () => chart.destroy();
  }, [data]);


  // 当日/当月
  const changeUnit = (unit: string) => {
    setUserOperationUnit(unit);
  }

  const renderBotton = useMemo(() => {
    return (
      <span
        style={{ paddingBottom: 0, marginBottom: 0, position: "absolute", right: 0, top: -55 }}>
        <Button
        className={chartStyles.bottonStyle}
        onClick={() => changeUnit("DAY")} size='small'
        style={{
          color: (userOperationUnit==="DAY"? "#0256FF" : "#454458")
        }}
        >
          当日
        </Button>
        <Button
        className={chartStyles.bottonStyle}
        onClick={() => changeUnit("MONTH")} size='small'
        style={{
          color: (userOperationUnit==="MONTH"? "#0256FF" : "#454458")
        }}
        >当月</Button>
      </span>
    )
  }, [userOperationUnit])

  return (
    <div className={chartStyles.userOperationWrapper}>
      {renderBotton}
      <Spin spinning={loading}>
      <div id="userOperationContainer" className={chartStyles.userOperation}></div>
      </Spin>
    </div>
  )
}
export default UserOperationChart;


