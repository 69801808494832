/**
 * 不同对象级别授权
 */
import React, { useEffect, useState } from 'react'
import { Input, Alert, Table, Spin, Button, Dropdown, Menu } from "antd"
import * as _ from 'lodash'
import {
  queryDmsNodeData,
  queryTableGroupData,
} from "src/api";
import { SearchOutlined, UserOutlined } from "@ant-design/icons"
import type { ColumnsType } from "antd/es/table"
import type { TableRowSelection } from "antd/es/table/interface"
// import AuthorizeDrawer from './authorizeDrawer'
import NewAuthorizeDrawer from './newAuthorizeDrawer'
import styles from "./index.module.scss";
import classnames from 'classnames'
import { useRequest, useSelector } from 'src/hook';
import { renderTableFields } from 'src/util'
import { Iconfont } from 'src/components';
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip';

interface IProps { 
	[p:string]:any
}
const ObjectLevelAuthorization = (props: IProps) => {
  const { selectTreeItem, selectNodeType } = props;

  const [activeKey, setActiveKey] = useState<string>("1");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectItems, setSelectItems] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 1,
  });
  const [data, setData] = useState<any[]>([])
  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: any}>()

  const { data: nodeDatas, run: queryNodes, loading: nodeLoading } = useRequest(
    queryDmsNodeData,
    {
      manual: true,
    }
  );

  useEffect(() => {
    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPermissionlist(permissionList?.DATABASE_MANAGEMENT?.[pathName])
  }, [])

  // 列表数据查询
  const {
    data: dataList=[],
    run: getTablesData,
    loading,
    refresh
  } = useRequest(queryTableGroupData, {
    manual: true,
    onSuccess: (res: any[]) => {
      setData(res || [])
    }
  });
  useEffect(() => {
    if (!selectTreeItem) {
      return;
    }
    const {
      nodeName,
      nodePath,
      nodePathWithType,
      nodeType,
      sdt: { connectionId, connectionType },
    } = selectTreeItem;
    const params = {
      connectionId,
      connectionType,
      nodeType,
      nodeName,
      nodePath,
      nodePathWithType,
    };
    queryNodes(params)
      .then((res: any) => {
        setPagination({
          current: 1,
          pageSize: 10,
          total: res?.length,
        });
        setSelectedRowKeys([]);
        setSelectedRows([])
        setSelectItems([]);
        const paraments = res?.slice(0, 10);
        getTablesData(paraments);
      })
      .catch((err: any) => {
        console.log("查询所有元素失败:", err);
      });
  }, [queryNodes, getTablesData, selectTreeItem]);

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: selectedRowKeys,
    onSelectAll(selected, newSelectedRows) {
     
      const curRowKeys = newSelectedRows.map(row => row?.nodePath);
      
      let cloneSelectedRowKeys = _.cloneDeep(selectedRowKeys);
      let cloneSelectedRows = _.cloneDeep(selectedRows);
   
      if (selected) {
        cloneSelectedRows = cloneSelectedRows.concat(newSelectedRows)
        cloneSelectedRowKeys = cloneSelectedRowKeys.concat(curRowKeys)
      }else {
        const curKeys = data.map(row => row?.nodePath);
        cloneSelectedRows = cloneSelectedRows.filter(cr => cr && !curKeys.includes(cr?.nodePath));
        cloneSelectedRowKeys = cloneSelectedRowKeys.filter(k => !curKeys.includes(k))
   
      }
      setSelectedRowKeys(cloneSelectedRowKeys.filter(i => !_.isEmpty(i)));
      setSelectedRows(cloneSelectedRows.filter(i => !_.isEmpty(i)));
    },
    onSelect(item, selected) {
    
      let cloneSelectedRowKeys = _.cloneDeep(selectedRowKeys);
      let cloneSelectedRows = _.cloneDeep(selectedRows);
     
      if (selected) {
        cloneSelectedRows = cloneSelectedRows.concat([item])
        cloneSelectedRowKeys = cloneSelectedRowKeys.concat([item.nodePath])
      }else {
        cloneSelectedRows = cloneSelectedRows.filter(cr => cr?.nodePath !== item.nodePath );
        cloneSelectedRowKeys = cloneSelectedRowKeys.filter(k => k !== item.nodePath)
      }
      setSelectedRowKeys(cloneSelectedRowKeys);
      setSelectedRows(cloneSelectedRows);
    },
  };

  // 单个授权、安全设置
  const handleMenuClick = (e: any, record: any) => {
    setActiveKey(e.key);
    setDrawerVisible(true);
    setSelectItems([record]);
  };

  // 批量授权、安全设置
  const handleBatchSetting = (e: any) => {
    setActiveKey(e.key);
    setDrawerVisible(true);
    setSelectItems(selectedRows);
  };

  // 关闭抽屉并刷新数据
  const handleClose = (needRefresh?: boolean) => {
    setDrawerVisible(false);
    setSelectedRowKeys([]);
    setSelectedRows([])
    setSelectItems([]);
    needRefresh && refresh();
  };

  // 页码修改
  const handlePageChange = (page: number, pageSize: number = 10) => {
 
    const start = pageSize * (page - 1) || 0;
    const end = pageSize * page;
    const paraments = nodeDatas?.slice(start, end);
    
    getTablesData(paraments);
    setPagination((p) => {
      return { ...p, current: page, pageSize };
    });
  };

  // 搜索
  const handleSearch = (e: any) => {
    const value = e.target.value?.trim()?.toLowerCase()
    if (value) {
      const result = nodeDatas.filter((i: any) =>
        i?.objectName?.toLowerCase()?.includes(value)
      );
      setPagination({
        current: 1,
        pageSize: 10,
        total: result?.length,
      });

      const paraments = result?.slice(0, 10);
      getTablesData(paraments);
    } else { 
      setPagination({
        current: 1,
        pageSize: 10,
        total: nodeDatas?.length,
      });
   
      const paraments = nodeDatas?.slice(0, 10);
      getTablesData(paraments);
    }
  }

  // 操作
  const clickOperation = (key: string, record: any) => {
    setActiveKey(key);
    setDrawerVisible(true);
    setSelectItems([record]);
  }

  const columns: ColumnsType<any> = [
    {
      title: selectTreeItem?.nodeName==='表' ? '表名' : selectTreeItem?.nodeName,
      dataIndex: "objectName",
      key: "objectName",
      width: 130,
      render: (txt: string, record: any) => (
        <>
          <Iconfont type={`icon-table`} />
          <span className={styles.ml4}>{renderTableFields(txt)}</span>
        </>
      ),
    },
    {
      title: "权限更新",
      dataIndex: "authUpdateMsg",
      key: "authUpdateMsg",
      width: 110,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: "权限用户",
      dataIndex: "authUserCnt",
      key: "authUserCnt",
      width: 80,
      render: (txt: string, record: any) => (
        <span>
          <UserOutlined style={{marginRight: 2}} />
          {renderTableFields(txt)}
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 150,
      render: (_: any, record: any) => {
        return (
          <>
          <Button type="link" className={styles.pl0} onClick={()=>clickOperation("1", record)}>用户授权</Button>
          <Button type="link" onClick={()=>clickOperation("2", record)} >安全设置</Button>
          </>
        )
      }
    },
  ];

  if (selectTreeItem?.nodeType === "synonymsGroup") {
    columns.splice(1, 0, {
      title: "同义词类型",
      dataIndex: "synonymType",
      key: "synonymType",
      width: 160,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    });
  }

  return (
    <Spin spinning={nodeLoading || loading}>
      <div className={styles.tableWrap}>
        <div className={styles.header}>
          <span className={styles.title}>
            {selectTreeItem?.nodeName + "对象"}
          </span>
          <Input
            style={{ width: "200px" }}
            prefix={<SearchOutlined />}
            placeholder={`搜索${selectTreeItem?.nodeName}`}
            onChange={handleSearch}
          />
        </div>
        <Alert
          className={styles.mb20}
          message={
            <span>
              当前授权对象可对
              <span className={styles.color3357ff}>
                单个{selectTreeItem?.nodeName}
              </span>
              授权，如需批量操作可点击
              <span className={styles.color3357ff}>批量设置</span>
            </span>
          }
          type="info"
          showIcon
          closable
        />
        {!!data?.length && (
          <>
          {
            permissionlist && 
            <PermissionTooltip
              title="手动授权"
              permissionlist={permissionlist}
              align={{points: ['bl', 'tl'] ,offset: [0, 4]}}
            >
              <Dropdown
                overlay={
                  permissionlist?.isOnlyRead ?
                  <></>
                  : <Menu
                      className={styles.options}
                      style={{ width: "82px" }}
                      onClick={(e) => selectedRowKeys?.length && handleBatchSetting(e)}
                    >
                      <Menu.Item key="1">用户授权</Menu.Item>
                      <Menu.Item key="2">安全设置</Menu.Item>
                    </Menu>
                }
              >
                <div
                  className={classnames(styles.mb10, {
                    [styles.options]: selectedRowKeys?.length,
                    [styles.disabled]: !selectedRowKeys?.length || permissionlist?.isOnlyRead,
                  })}
                >
                  批量设置
                </div>
              </Dropdown>
            </PermissionTooltip>
          }
            <Table
              rowSelection={rowSelection}
              rowKey={(item) => item.nodePath}
              columns={columns}
              dataSource={data}
              pagination={{
                ...pagination,
                onChange: (page, pageSize) => handlePageChange(page, pageSize),
              }}
              scroll={{
                y: "50vh",
              }}
            />
          </>
        )}
        {/* 权限设置 */}
        {drawerVisible && (
          <NewAuthorizeDrawer
            visible
            handleClose={handleClose}
            selectItems={selectItems}
            initActiveKey={activeKey}
            permissionlist={permissionlist}
            selectNodeType={selectNodeType}
          />
        )}
      </div>
    </Spin>
  );
};
export default ObjectLevelAuthorization;
