import { useState, useCallback, useRef } from 'react'

interface DOMRectReadOnly {
  readonly x: number
  readonly y: number
  readonly width: number
  readonly height: number
  readonly top: number
  readonly right: number
  readonly bottom: number
  readonly left: number
}

export const useResizeObserver = (): [
  DOMRectReadOnly | undefined,
  React.RefCallback<HTMLElement>,
] => {
  const [rect, setRect] = useState<DOMRectReadOnly>()

  // https://react.docschina.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const roRef = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        if (!entry.contentRect.height) continue
        setRect(entry.contentRect)
      }
    }),
  )

  const domRef: React.RefCallback<HTMLElement> = useCallback((node) => {
    if (node === null) {
      return roRef.current.disconnect()
    }
    roRef.current.observe(node)
  }, [])

  return [rect, domRef]
}
