import { Button, Space, Spin, Switch, Table, Tooltip, Typography, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useMemo } from "react";
import { getFilterRule, permissionSupport, removeFilterRule, updateFilterRule } from "src/api";
import { useRequest, useSelector } from "src/hook";
import { getCurrentModulePermissionByUrl } from "src/util";

interface TableType {
  id: number;
  rowFilterId: string;
  content: string;
  status: 0 | 1;
  nodePathWiteType: string;
  createdAt: string;
  updateAt: string;
}

const SchemaFilterRow = (): JSX.Element => {
  const { selectedNode, selectedNodePermissionInfo } = useSelector(state => state.dataProtection);
  const { loading, data: tableInfo, run: queryRule, refresh: refreshView } = useRequest(getFilterRule, { manual: true });
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const { run: removeRule } = useRequest(removeFilterRule, {
    manual: true,
    onSuccess() {
      message.success("删除成功");
      refreshView();
    },
    onError() {
      message.error("删除失败");
      refreshView();
    },
  });

  const { run: toggleStatus } = useRequest(updateFilterRule, {
    manual: true,
    onSuccess() {
      message.success("修改成功");
      refreshView();
    },
    onError() {
      refreshView();
    },
  });

  function handleRemove(id: number) {
    removeRule({ id: String(id) });
  }

  function handleToggle(record: TableType) {
    toggleStatus({
      id: String(record.id),
      status: record.status ? 0 : 1,
    });
  }

  const columns: ColumnsType<TableType> = [
    { dataIndex: "id", title: "序号" },
    {
      dataIndex: "objectPath",
      title: "对象路径",
      ellipsis: true,
    },
    {
      dataIndex: "objectName",
      title: "对象名称",
      ellipsis: true,
    },
    {
      dataIndex: "content",
      title: "过滤规则",
      width: "320px",
      ellipsis: true,
    },
    {
      dataIndex: "status",
      title: "状态",
      ellipsis: true,
			render: (value, record) => {
				// return value ? <Typography.Text>启用</Typography.Text> : <Typography.Text disabled>禁用</Typography.Text>;
        return (
          <Tooltip title={
            !permissionSupportData?
            `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
            : null
            } 
          >
            <Switch
              checked={value ? true : false}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onClick={() => handleToggle(record)}
              disabled={!permissionSupportData}
            />
          </Tooltip>
        )
			},
    },
    {
      key: "operation",
      title: "操作",
      align: "center",
      render(_, record) {
        return (
          <Space>
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
            >
              <Button
                type="link"
                style={{ color: permissionSupportData? "#324564" : "#bfbfbf" }}
                onClick={() => handleRemove(record.id)}
                disabled={!permissionSupportData}
              >
                移除
              </Button>
            </Tooltip>
            {/* {record.status ? (
              <Button
                type="link"
                onClick={() => handleToggle(record)}
              >
                禁用
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => handleToggle(record)}
              >
                启用
              </Button>
            )} */}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedNode) {
      //@ts-ignore
      const { connectionType: dataSourceType, nodePathWithType, nodePath } = selectedNode.props;
      const params = {
        dataSourceType,
        nodePathWithType,
        nodePath,
      };
      queryRule(params);
    }
  }, [selectedNode, queryRule]);

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={tableInfo}
        pagination={false}
        scroll={{ y: `calc(100vh - 248px)`}}
      ></Table>
    </Spin>
  );
};

export { SchemaFilterRow };

