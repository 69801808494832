import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Form, message, Select } from 'antd'
import { UIModal } from 'src/components'
import { fetchOrgTreeData,fetchDeptUserList, setDeptUsersManagerId, setOrganizationSearch } from '../organizationSlice'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { editDept, getDeptUsers, getDeptUsersManagerId } from 'src/api'
import { toInteger } from 'lodash'

export const ModalEdptPrincipal = () => {
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.ModalEdptPrincipal)
  const selectedNode = useSelector((state) => state.organization.selectedNode)
  const {
    organizationPageSize,
  } = useSelector((state) => state.organization)

  const [deptUserList, setDeptUserList] = useState<any[]>([])

  const { run: fetchRunDeptUserList } = useRequest(getDeptUsers, { manual: true })

  const userSelectOptions = deptUserList?.map((user: any) => {
    const { userId, userName } = user
    return {
      label: `${userName}（${userId}）`,
      value: userId,
    }
  })

  const [form] = Form.useForm()

  useEffect(() => {
    if (selectedNode?.orgType === 'DEPT') {
      fetchRunDeptUserList(selectedNode.id).then((data) => {
        setDeptUserList(data)
        if (data) {
          const index = data.findIndex((element: any) => element.isPrincipal)
          const value = data[index]?.userId
            ? `${data[index]?.userName}（${data[index]?.userId}）`
            : ''
          form.setFieldsValue({ principal: value })
        }
      })
    }
  }, [fetchRunDeptUserList, selectedNode, visible, form])

  const { loading, run } = useRequest(editDept, {
    manual: true,
    onSuccess: () => {
      message.success('修改成功')
      dispatch(fetchOrgTreeData())
      dispatch(hideModal('ModalEdptPrincipal'))
    },
  })

   const { run: getUsersManagerId } = useRequest(getDeptUsersManagerId, { manual: true,
    onSuccess(data) {
      form.setFieldsValue({principal: data?.userId})
    }
  })

  useEffect(() => {
    if (selectedNode?.id && visible) {
      getUsersManagerId(selectedNode?.id)
    }
  }, [selectedNode, visible])

  const modalTitle = selectedNode?.orgType === 'DEPT' ? '修改部门负责人' : '修改合作方负责人';

  return (
    <UIModal
      title={modalTitle}
      visible={visible}
      onCancel={() => {
        dispatch(hideModal('ModalEdptPrincipal'))
      }}
      onOk={() => {
        form.validateFields().then(({ principal }) => {
          const index = principal.indexOf('（')
          const principalCheck =
            index > -1 ? principal.slice(index + 1, -1) : principal
          selectedNode?.name &&
            run({ principal: principalCheck, id: toInteger(selectedNode?.id) })
            dispatch(setDeptUsersManagerId(principalCheck))
            dispatch(setOrganizationSearch(''));
            !!selectedNode && dispatch(
              fetchDeptUserList({
                dept: selectedNode,
                search: '',
                pageNum: 0,
                pageSize: organizationPageSize,
              }),
            )
            
        })
      }}
      confirmLoading={loading}
      width={320}
    >
      <Form form={form}>
        <Form.Item
          name="principal"
          label="负责人"
          rules={[{ required: true, message: '请输入负责人' }]}
        >
          <Select
            options={userSelectOptions}
            optionFilterProp="label"
            showSearch
          ></Select>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
