import {
  findDataSourceDesensScanList,
  findGroupDesensScanList,
  findConnectionDesensScanList,
  findDataSourceDesensScanResultList,
  findGroupDesensScanResultList,
  findConnectionDesensScanResultList,
} from "src/api";
import type { NodeTypeKey } from "../type.d";

export const getDesensScanTaskAction = (type: NodeTypeKey) => {
  switch (type) {
    case "datasource":
      return findDataSourceDesensScanList;
    case "group":
      return findGroupDesensScanList;
    case "connection":
    case "database":
    case "schema":
    case "tableGroup":
    case "table":
      return findConnectionDesensScanList;
    default:
      return findConnectionDesensScanList;
  }
};

export const getDesensScanResultAction = (type: NodeTypeKey): any => {
  switch (type) {
    case "datasource":
      return findDataSourceDesensScanResultList;
    case "group":
      return findGroupDesensScanResultList;
    case "connection":
    case "database":
    case "schema":
    case "tableGroup":
    case "table":
      return findConnectionDesensScanResultList;
    default:
      return null;
  }
};
