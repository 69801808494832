import React, { useEffect, useState } from "react";
import * as _ from 'lodash'
import { Modal, Tooltip, Switch, Space, message, Button } from "antd";
import { ExclamationCircleFilled, RedoOutlined } from "@ant-design/icons";
import {
  setDesensitizeResourceStatus,
  batchDeleteDesensResource,
  permissionSupport,
} from "src/api";
import { SimpleTable } from "src/components";
import { useRequest, useSelector, useDispatch } from "src/hook";
import { showModal } from "src/store/extraSlice/modalVisibleSlice";
import type { NodeTypeKey } from "../type";
import { formatRuleName } from "../utility";
import { getDesensScanResultAction } from "./utils";
import { setDesensRuleSettingObj } from "../../DataProtectionPageSlice";
import styles from "./index.module.scss";
import classnames from "classnames";

interface ISearchParams {
  current: number;
  pageSize: number;
  groupId?: number | null;
  nodePath?: string | null;
  datasourceType?: string | null;
}
const { confirm } = Modal;
//除table 层其他页面全部公用 除了接口不一样
const DesensScanRuleContent = ({ type, isRefresh }: { type: NodeTypeKey, isRefresh: boolean, [p: string]: any }) => {
  const dispatch = useDispatch();

  const { selectedNode, selectedNodePermissionInfo } = useSelector((state) => state.dataProtection);
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const [searchParams, setSearchParams] = useState<ISearchParams | any>({});

  //不同类型， 接口不同参数不同
  const {
    data: scanResult = {},
    loading,
    refresh,
    run: getScanResult,
  } = useRequest(
    () => {
      const action = getDesensScanResultAction(type);

      return !_.isEmpty(searchParams) && action && action(searchParams);
    },
    { refreshDeps: [searchParams] }
  );

  //批量删除
  const { run: batchDeleteDesens } = useRequest(batchDeleteDesensResource, {
    manual: true,
    onSuccess: () => {
      message.success("删除成功");
      setSearchParams({ ...searchParams, current: 1 });
    },
  });
  useEffect(() => {
 
    if (selectedNode && type) {
      let defaultParams: ISearchParams = {
        current: 1,
        pageSize: 10,
      };
      if (type === "datasource") {
        //@ts-ignore
        defaultParams.datasourceType = selectedNode.props.id;
      } else if (type === "group") {
        defaultParams.groupId = selectedNode.props.id;
      } else {
        //@ts-ignore
        defaultParams.nodePath = selectedNode.props.nodePathWithType;
      }
      setSearchParams(defaultParams);
    }
  }, [selectedNode, type,isRefresh]);

  const { run: setStatus } = useRequest(setDesensitizeResourceStatus, {
    manual: true,
    onSuccess: () => {
      message.success("修改成功");
      refresh();
    },
  });

  async function handleSetStatus(record: any) {
    await setStatus({
      id: [record.id],
      status: !record.status,
    });
  }

  const showDeleteConfirm = (params: { resourceIds: number[] }) => {
    confirm({
      title: `确定删除？`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      onOk() {
        batchDeleteDesens(params);
      },
    });
  };

  const columns: any = [
    {
      dataIndex: "connectionName",
      title: "字段路径",
      width: 200,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 20 ? value : value?.slice(0, 20) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "matchRule",
      title: "匹配规则",
      ellipsis: true,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 15 ? value : value?.slice(0, 15) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "ruleName",
      title: "脱敏规则",
      ellipsis: true,
      render(value: string) {
        return formatRuleName(value);
      },
    },
    {
      dataIndex: "ruleParam",
      title: "算法",
      ellipsis: true,
      render(value: string) {
        return (
          <Tooltip title={value}>
            {value?.length <= 15 ? value : value?.slice(0, 15) + "..."}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "source",
      title: "来源",
    },
    {
      dataIndex: "status",
      title: "状态",
      render: (value: string, record: any) => (
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          } 
        >
          <Switch
            checked={value ? true : false}
            checkedChildren="启用"
            unCheckedChildren="关闭"
            onChange={(checked: boolean) => {
              handleSetStatus(record);
            }}
            disabled={!permissionSupportData}
          />
        </Tooltip>
      ),
    },
    {
      dataIndex: "options",
      title: "操作",
      fixed: "right",
      render(value: string, record: any) {
        return (
          <Space size="small" align="start">
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
              className={styles.displayFlex}
            >
              <Button
                type="link"
                className={classnames(styles.linkBtn, styles.padding0)}
                onClick={() => {
                  dispatch(showModal("CommonEditDesensRulesModal"));
                  dispatch(
                    setDesensRuleSettingObj({
                      refresh,
                      editRecord: record,
                      isScanResult: true //扫描规则
                    })
                  );
                }}
                disabled={!permissionSupportData}
              >
                编辑
              </Button>
              <Button
                type="link"
                className={classnames(styles.deleteBtn, styles.padding0)}
                onClick={() => showDeleteConfirm({ resourceIds: [record?.id] })}
                disabled={!permissionSupportData}
              >
                删除
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className={styles.desensScanResultPart}>
      <div className={styles.resultHeader}>
        <div className={styles.title}>扫描结果</div>
        <Button
            icon={<RedoOutlined  />}
            onClick={() => refresh()}
          />
      </div>
      <SimpleTable
        rowKey="id"
        total={scanResult?.total ?? 0}
        loading={loading}
        columns={columns}
        dataSource={scanResult?.list || []}
        scroll={{x: 'max-content'}}
        searchParams={{...searchParams,currentPage: searchParams?.current}}
        setSearchParams={(params: any) => {
   
          setSearchParams({
            ...searchParams,
            pageSize: params?.pageSize,
            current: params?.currentPage
          })
        }}
      />
    </div>
  );
};

export default DesensScanRuleContent;
