import React, { useEffect, useState, memo } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Switch,
  Tooltip,
  Space,
  Row,
  Col,
  Button,
  message
} from 'antd';
import classnames from 'classnames'
import {
  updateConnectionWorkConfig,
  getConnectionConfig
} from 'src/api'
import { SqlBackupLocationTree } from './SqlBackupLocationTree';
import styles from '../../index.module.scss';

interface IProps {
  connectionId?: string;
  dataSourceType: string;
  [p: string]: any
}

export const ConnectionDataBackup = memo((props: IProps) => {
  const { connectionId, dataSourceType, permissionlist, canEdit } = props;
  
  const [form] = Form.useForm();
  //数据备份状态
  const [sqlBackupStatus, setSqlBackupStatus] = useState(false);
  //初始化数据
  const [initSqlBackupValues, setInitSqlBackupValues] = useState<any>({});

  const getSqlBackupSetting = async () => {
    await Promise.all([
      getConnectionConfig({
        connectionId,
        variable: 'sqlBackUp',
      }),
      getConnectionConfig({
        connectionId,
        variable: 'sqlBackUpLocation',
      }),
      getConnectionConfig({
        connectionId,
        variable: 'sqlFlashBack',
      }),
    ]).then(([res1, res2, res3]) => {
      const res = {
        sqlBackupLocation: res2?.variable_value,
        sqlBackupStatus: JSON.parse(res1?.variable_value) || false,
        sqlFlashBackSetting: JSON.parse(res3?.variable_value) || false
      }

      form.setFieldsValue(res);
      setInitSqlBackupValues(res);
      setSqlBackupStatus(JSON.parse(res1?.variable_value))
    });
  };
  useEffect(() => {
    if (connectionId) {
      getSqlBackupSetting()
    }
  }, [connectionId])


  const onChangeBackupSetting = async (status: boolean, location: string, sqlFlashBackSetting: boolean) => {
    await Promise.all([
      updateConnectionWorkConfig({
        connectionId,
        variable: 'sqlBackUp',
        variable_value: status,
      }),
      status && updateConnectionWorkConfig({
        connectionId,
        variable: 'sqlBackUpLocation',
        variable_value: location,
      }),
      status && updateConnectionWorkConfig({
        connectionId,
        variable: 'sqlFlashBack',
        variable_value: sqlFlashBackSetting,
      })
    ])
  };

  const handleSave = () => {
    form.validateFields().then(async (values: any) => {
      onChangeBackupSetting(values.sqlBackupStatus, values?.sqlBackupLocation, values?.sqlFlashBackSetting)
        .then(() => {
          message.success('备份配置成功')

          getSqlBackupSetting();
        })
        .catch((err: any) => {
          console.error('备份配置失败', err)
        })
    })
  }

  const handleCancle = () => {
    setSqlBackupStatus(initSqlBackupValues?.sqlBackupStatus || false)
    form.setFieldsValue({...initSqlBackupValues})
  }

  const renderLabel = () => {
    return (
      <Space>
        数据备份
        <Tooltip title="仅支持对delete、update、drop、truncate操作进行数据备份">
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
    );
  };

  const renderSQLFlashLabel = () => {
    return (
      <Space>
        SQL闪回功能
        <Tooltip
          title="开启闪回功能的前提是开启数据备份"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Space>
    );
  };
  return (
    <>
      <div className={classnames(styles.settingTitle, styles.mt20)}>
        备份配置
      </div>

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Row align='bottom'>
          <Col span={12}>
            <Form.Item
              label={renderLabel()}
              name="sqlBackupStatus"
              valuePropName="checked"
            >
              {
                canEdit 
                ? <Switch onChange={(status) => setSqlBackupStatus(status)} />
                : <Tooltip title={`您当前的角色是[${permissionlist?.roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                    <Switch disabled={true}/>
                  </Tooltip>
              }
            </Form.Item>
            {sqlBackupStatus && (
              <>
                <Form.Item
                  label="备份位置"
                  name="sqlBackupLocation"
                  dependencies={['sqlBackupStatus']}
                  rules={[{ required: true }]}
                >
                  <SqlBackupLocationTree
                    dataSourceType={dataSourceType}
                    sqlBackupStatus={sqlBackupStatus}
                    initLocationInfo={initSqlBackupValues}
                  />
                </Form.Item>
                <Form.Item
                  label={renderSQLFlashLabel()}
                  name="sqlFlashBackSetting"
                  valuePropName="checked"
                >
                  {
                    canEdit 
                    ? <Switch />
                    : <Tooltip title={`您当前的角色是[${permissionlist?.roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                        <Switch disabled={true}/>
                      </Tooltip>
                  }
                </Form.Item>
              </>
            )}

          </Col>
          <Col span={10}>
            <Form.Item>
              {
                canEdit 
                ? <>
                    <Button type="primary" onClick={handleSave}>
                      保存
                    </Button>
                    <Button className={styles.ml10} onClick={handleCancle}>取消</Button>
                  </>
                : <Tooltip title={`您当前的角色是[${permissionlist?.roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                    <Button type="primary" disabled={true}>
                      保存
                    </Button>
                    <Button className={styles.ml10} disabled={true}>
                      取消
                    </Button>
                  </Tooltip>
              }
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
});
