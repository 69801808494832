import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'

export const orgTypes = ['DEPT', 'GROUP', 'COMPANY', 'SUB_COMPANY'] as const
export type OrgType = typeof orgTypes[number]

export interface OrgTreeNode {
  key: string
  title: string
  name: string
  orgType: OrgType
  children: OrgTreeNode[]
  departmentNum: number
  id: string
  parentId: string | undefined
  companyId: number
  externalDepartment: boolean
}

const treeNodeAdapter = (orgTreeNode: OrgTreeNode): any => {
  // console.log(orgTreeNode, 'orgTreeNode===')
  const { name, orgType, children, id } = orgTreeNode
  return {
    ...orgTreeNode,
    // key: orgType + '__' + name,
    key: orgType + '__' + id,
    title: name,
    children: (children || []).map((node) => treeNodeAdapter(node)),
  }
}

export let showSubCompany = true;

export const changeShowSubCompany = () => {
  showSubCompany = !showSubCompany;
};

export const getShowSubCompany = () => showSubCompany;

export const getOrgTreeData = () => {
  return fetchGet(`/user/org/std/${getShowSubCompany()}`).then((rootNode: OrgTreeNode) => {
    return treeNodeAdapter(rootNode)
  })
}

interface AddDeptRequest {
  /**部门id */
  id?: number | string
  /** 部门名称 */
  name?: string
  /** 部门负责人 */
  principal?: string
  /** 描述 */
  description?: string
  /** 父级部门 */
  parents?: string[]
  /** 部门类型 */
  orgType?: OrgType
  /** 是否外部部门 **/
  externalDepartment?: boolean
}

/** 添加部门 */
export const addDept = (params: AddDeptRequest) => {
  return fetchPost(`/user/org`, params)
}

/** 添加合作方 */
export const addSubCompany = (params: AddDeptRequest) => {
  return fetchPost(`/user/org`, params)
}

/** 编辑部门 */
export const editDept = (params: AddDeptRequest) => {
  return fetchPut(`/user/org/dept`, params)
}

/** 删除部门 */
// export const deleteDept = (deptName: string) => {
//   return fetchDelete(`/user/org/dept/${deptName}`)
// }

interface AddOrgGroupRequest {
  /** 组名称 */
  name: string
  /** 组描述 */
  description?: string
  /** 成员 id 集合 */
  members?: string[]
  /** 父级部门名称 */
  parentOrg?: string
}

/** 添加组 */
export const addOrgGroup = (params: AddOrgGroupRequest) => {
  return fetchPost(`/user/org/group`, params)
}

/** 删除组 | 部门 */
export const deleteOrgGroup = (groupName: string) => {
  return fetchDelete(`/user/org/${groupName}`)
}

interface EditUsersRequest {
  groupId: number
  orgType: OrgType
  addUsers: string[]
  removeUsers: string[]
}

/** 编辑部门用户 */
export const editDeptUsers = (params: EditUsersRequest) => {
  return fetchPut(`/user/org/dept/members`, params)
}

/** 编辑组用户 */
export const editGroupUsers = (params: EditUsersRequest) => {
  return fetchPut(`/user/org/group/members`, params)
}

/* 重置 otpkey */
export const resetOtpKey = (data: { userId: string }) => {
  return fetchPost(`/user/login/resetOtpKey`, data)
}

/* 获取用户权限列表详情 */
export const getUserPermission_api = (data: {
  condition?: string
  permissionType?: string
  userId: string
}) => {
  return fetchPost(`/user/permission/user/permissions`, data)
}
