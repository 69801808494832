import React from 'react'
import { Button, Card } from 'antd'
import classnames from 'classnames'
import styles from '../index.module.scss'
import { VerticalAlignBottomOutlined } from '@ant-design/icons'

interface IProps {
  uploadFileData: {
    fileName: string,
    taskId: number;
    taskStatus: 'DOWNLOADED' | 'SUCCESS';
    errorLog?: string;
  }
}

export const UploadFilePag = (props: IProps) => {
  const { uploadFileData } = props
  const { taskId, fileName } = uploadFileData

  // 下载附件
  const downloadFile = (taskId: number) => {
    window.open(`/export/export/download/${taskId}`, '_self')
  }

  return (
    <Card
      title="附件信息"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27)}
    >
      {
        ['DOWNLOADED', 'SUCCESS'].includes(uploadFileData?.taskStatus) ?
          <Button
            type="link"
            onClick={() => downloadFile(taskId)}
          >
            {fileName}<VerticalAlignBottomOutlined />
          </Button>
          : `导出异常：${uploadFileData?.errorLog}`
      }
    </Card>
  )
}
