import { KeyMod, KeyCode } from 'monaco-editor'

/* 
  KeyMod.WinCtrl means windows (meta) key on Windows/Linux and ctrl key on macOS
  KeyMod.CtrlCmd means ctrl key on Windows/Linux and meta key on macOS
*/
const platformIKeyMod = () => {
  // 判断是否是mac系统
  const isMac = navigator.userAgent.indexOf('Macintosh') >= 0;
  
  return {
    ctrl: isMac ? KeyMod.WinCtrl : KeyMod.CtrlCmd,
    shift: KeyMod.Shift,
    alt: KeyMod.Alt,
  }
}

// 输入的快捷键转换成 monaco-editor 识别的快捷键字段
export const keyStringSplit = (key: string) => {

  const IKeyMode = platformIKeyMod()

  const regNum = /^[0-9]$/

  const index = key.indexOf('/')
  const keyArr = index === -1 ? key.split('+') : key.slice(0, index).split('+')

  const k1 = keyArr[0].toLocaleLowerCase(),
        k2 = regNum.test(keyArr[1]) ? keyArr[1] : keyArr[1].toUpperCase()

  const kMode = IKeyMode[k1 as keyof typeof IKeyMode]
  const kCode = KeyCode[`KEY_${k2}` as keyof typeof KeyCode]

  return [kMode | kCode]
}