import React from 'react'
import { Transfer, Table } from 'antd'
import { TransferProps } from 'antd/lib/transfer'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import difference from 'lodash/difference'
import styles from './index.module.scss'

interface TableTransferProps extends Partial<TransferProps> {
  columns?: ColumnsType<any>
  leftColumns?: ColumnsType<any>
  rightColumns?: ColumnsType<any>
  /** 总数据池, 右表格根据 targetKeys 从中筛选出表格数据。不传的话, 则从 dataSource 中筛选 */
  totalDataSource?: any[]
  tableLoading?: { left?: boolean; right?: boolean }
  pagination?: false | TablePaginationConfig | undefined
}

// todo: TransferItem ts generic type definition
export const TableTransfer: React.FC<TableTransferProps> = (props) => {
  const {
    columns,
    leftColumns,
    rightColumns,
    totalDataSource,
    tableLoading,
    pagination = {},
    ...restProps
  } = props
  const { targetKeys = [], rowKey = (record) => record.key } = restProps

  return (
    <Transfer
      showSelectAll={false}
      listStyle={{ minHeight: 496 }}
      {...restProps}
    >
      {({
        direction,
        onItemSelectAll,
        onItemSelect,
        filteredItems,
        selectedKeys: listSelectedKeys,
      }) => {
        const leftDataSource = filteredItems.map((item) => ({
          ...item,
          disabled: targetKeys.includes(rowKey(item)),
        }))

        const rightDataSource = (
          totalDataSource || filteredItems
        ).filter((item) => targetKeys.includes(rowKey(item)))

        let currentColumns = columns;
        if (!currentColumns) {
          currentColumns = direction === 'left' ? leftColumns : rightColumns;
        }

        return (
          <Table
            rowClassName={styles.tableTransferRow}
            dataSource={direction === 'left' ? leftDataSource : rightDataSource}
            loading={
              direction === 'left' ? tableLoading?.left : tableLoading?.right
            }
            rowKey={rowKey}
            columns={currentColumns}
            rowSelection={{
              getCheckboxProps: (item) => ({ disabled: item.disabled }),
              onSelectAll(selected, selectedRows) {
                const treeSelectedKeys = selectedRows
                  .filter((item) => !item.disabled)
                  .map(rowKey)
                const diffKeys = selected
                  ? difference(treeSelectedKeys, listSelectedKeys)
                  : difference(listSelectedKeys, treeSelectedKeys)
                onItemSelectAll(diffKeys, selected)
              },
              onSelect(item, selected) {
                onItemSelect(rowKey(item), selected)
              },
              selectedRowKeys: listSelectedKeys,
            }}
            onRow={(item) => ({
              onClick: () => {
                if (item.disabled) return
                onItemSelect(
                  rowKey(item),
                  !listSelectedKeys.includes(rowKey(item)),
                )
              },
            })}
            size="small"
            pagination={pagination}
          />
        )
      }}
    </Transfer>
  )
}
