const splitReducer = (splitter: string) => (prev: any[], curr: any) => {
  return prev.concat(String(curr).split(splitter))
}

export const getSplitListValue = (value: any[]) => {
  const splitListValue = (value || [])
    .reduce(splitReducer(','), [])
    .reduce(splitReducer('，'), [])
    .reduce(splitReducer(';'), [])
    .reduce(splitReducer('；'), [])
    .map((str) => str.trim())
    .filter(Boolean)
  return splitListValue
}
