import {storeRegister} from 'src/store/storeRegister'
import cloneDeep from 'lodash/cloneDeep'
// 枚举类型
import {DataSourceType} from "../types";

// 枚举常量key
export type EnumKeyType = 'dataTypeEnum' | 'indexTypeEnum' | 'constraintTypeEnum' | 'updateRuleEnum' | 'deleteRuleEnum' 
| 'triggerEventEnum' | 'triggerTimingEnum' | 'triggerLevelEnum'

// 获取基础设计表信息
export function getBaseConfig(type?: DataSourceType) {
    let store = storeRegister.getStore()?.getState()

    if(store){
        const { activeTabKey, paneInfoMap } = store?.queryTabs
        const targetPaneInfo = paneInfoMap[activeTabKey]
        const targetPaneInfoClone = cloneDeep(targetPaneInfo)
        const dataSourceMetaClone = cloneDeep(store.dataSourceMeta)
        let { connectionType } = targetPaneInfoClone
 
        return  dataSourceMetaClone[connectionType!] || {}

    }else{
        return {}
    }

}

/**
 * 是否渲染字段
 */
export function isSupport(key: string) {
    if (!key) return false

    const [main,subject] = key.split('.')
    if(main && subject){
        // @ts-ignore
        return  getBaseConfig()?.[main]?.[subject]
    }
    // 没找到 默认不渲染
    return false
}

/**
 * 获取下拉框枚举类型
 * @param keyType EnumKeyType
 */
export function getEnumOptions(keyType:EnumKeyType){
    // @ts-ignore
    return  getBaseConfig()?.[keyType] || []
}


/**
 * 根据不同的数据库类型，修正`备注`字段中，带有'的情况
 * @param connectionType
 * @param comment
 */
export function correctComment(connectionType:any,comment:string){
    
    // 1.specialTypes类型： 包含单引号的时候，在该单引号前再加一个单引号(起转义作用)
    const specialTypes = ['Oracle','PostgreSQL','SQLserver','OracleCDB','GaussDB','MogDB','Greenplum']
    if (specialTypes.includes(connectionType)) {
        comment = comment?.replace(/'/, "\'\'")
    } else{
        // 2. 除specialTypes 外其他默认添加\ ['MariaDB','PolarDB','TDSQL','TIDB','GoldenDB','MySQL']
        comment = comment?.replace(/\'/g, "\\'")
    }
    return comment
}
