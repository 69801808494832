import { editor, languages, IRange } from 'monaco-editor'
import { SqlSplitter, getOperatingObject } from 'src/util'
import { DatabaseInfo } from '../queryTabsSlice'
import {
  DataSource,
  DataSourceType,
  getCompletionItemSuggestions,
} from 'src/api'
import * as sqlFormatter from 'sql-formatter'
import { formatOptionalLangs } from 'src/constants'
import parser from 'editor_parser'
import { LSP_LANGUAGE_ID } from 'src/components'

const { EditParserFactory, ParserRequest } = parser

type ConnectionContext = DatabaseInfo

export const customizeLanguage = (
  language: string,
  dataSourceDescInfo: { [key in DataSourceType]?: DataSource },
  connectionContext?: ConnectionContext,
) => {
  // Register a new language
  if (!languages.getEncodedLanguageId(language)) {
    languages.register({ id: language })
  }

  if (!connectionContext) return []
  const { connectionId, connectionType, databaseName, schemaName } =
    connectionContext

  const disposers = []

  if (language !== LSP_LANGUAGE_ID) {
    // Register a completion item provider for the new language
    const completionDisposer = languages.registerCompletionItemProvider(
      language,
      {
        triggerCharacters: ['.'],
        provideCompletionItems: async (model, position) => {
          // model 当前语言的注册 id
          const owner = model.getModeId()

          const textUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          })
          const splitter = new SqlSplitter(textUntilPosition)
          // 距光标位置最近的一条语句
          const statement = splitter.split().pop()
          if (!statement) return

          // 当前光标位置的 word 与其位置信息
          const { word, startColumn, endColumn } =
            model.getWordUntilPosition(position)
          // insert range
          const range: IRange = {
            startColumn,
            endColumn,
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
          }         

          // 调用补全解析
          const parser = new ParserRequest(
            getFallbackDataSourceStrategy(connectionType),
            statement,
            statement.length,
            word,
          )

          const parserResponse = await EditParserFactory.statementParser(parser)

          const { completionFormat, completionResponse, errorMark } =
            parserResponse

          if (errorMark) {
            // set model markers
            const { promptInfo, startOffset, endOffset } = errorMark
            const markers = {
              severity: 6,
              message: promptInfo,
              startLineNumber: position.lineNumber,
              startColumn: startOffset,
              endLineNumber: position.lineNumber,
              endColumn: endOffset,
            }
            editor.setModelMarkers(model, owner, [markers])
          } else {
            editor.setModelMarkers(model, owner, [])
          }

          if (completionResponse) {
            // need request
            const {
              expectType,
              tableName,
              databaseName: inputDatabase,
            } = completionResponse

            // ! 将 backtick 过滤掉，否则接口会报错，后续在 parser 里统一逻辑
            const tables =
              tableName?.map((table: any) => table.replace(/`/g, '')) || []

            const dataSourceLevel =
              dataSourceDescInfo[connectionType]?.dataSourceLevel

            const operatingObject = getOperatingObject(
              { databaseName, schemaName },
              connectionType,
            )

            const params = {
              connectionId,
              dataSourceType: connectionType,
              databaseName:
                dataSourceLevel === 2 ? inputDatabase : databaseName,
              operatingObject:
                dataSourceLevel === 2
                  ? operatingObject
                  : inputDatabase || schemaName,
              promptType: expectType,
              tables,
            }
            try {
              const response = await getCompletionItemSuggestions(params)
              const suggestions = response.map((item) => ({
                ...item,
                range,
                kind: languages.CompletionItemKind.Text,
              }))
              return { suggestions }
            } catch {}
          } else {
            // front end parser
            const suggestions = completionFormat.map((item: any) => {
              const { insertText = '', ...rest } = item
              return {
                ...rest,
                insertText,
                range,
                kind: languages.CompletionItemKind.Keyword,
              }
            })
            return { suggestions }
          }
        },
      },
    )
    disposers.push(completionDisposer)
  }

  const formatterDisposer = languages.registerDocumentFormattingEditProvider(
    language,
    {
      provideDocumentFormattingEdits(model) {
        const language = formatOptionalLangs.find(
          (lang:any) => lang === connectionType.toLowerCase(),
        )
        const formatted = sqlFormatter.format(model.getValue(), {
          language,
        })

        const range = model.getFullModelRange()
        return [{ text: formatted, range }]
      },
    },
  )

  disposers.push(formatterDisposer)

  return disposers
}

function getFallbackDataSourceStrategy(
  dataSource: DataSourceType,
): DataSourceType {
  switch (dataSource) {
    case 'MySQL':
    case 'MariaDB':
    case 'dble':
    case 'RDSMySQL':
    case 'Hive':
    case 'Inceptor':
    case 'DamengDB':
    case 'DB2':
    case 'StarRocks':
      return 'MySQL'
    case 'Oracle':
    case 'HANA':
    case 'OceanBase':
    case 'PolarDB':
    case 'Vertica':
    case 'Impala':
      return 'Oracle'
    case 'PostgreSQL':
    case 'GaussDB':
    case 'OpenGauss':
    case 'KingBasePG':
    case 'KingBaseOracle':
      return 'PostgreSQL'
    case 'MongoDB':
      return 'MongoDB'
    case 'SQLServer':
      return 'SQLServer'
    default:
      return 'MySQL'
  }
}