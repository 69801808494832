import React from 'react'
import Segmented from 'rc-segmented'
import styles from './index.module.scss'
import 'rc-segmented/assets/index.css'
import type { SegmentedProps, SegmentedValue } from 'rc-segmented/lib/index'

interface IProps extends SegmentedProps {
  onChange?: (val: SegmentedValue) => void
}

export const RcSegmented = ({ onChange, ...rest }: IProps) => {
   
  return (
     //@ts-ignore
    <Segmented
      {...rest}
      onChange={(val) => onChange &&onChange(val)}
      className={styles['search-status']}
    />
  )
}
