/**
 * 用户权限列表
 */
import React from "react"
import { Table } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { renderTableFields, getScrollX } from 'src/util'
import styles from './index.module.scss'

const UserAuthorizeList = (props: any) => { 
	const { handleShowContentChange } = props

	const columns: any[] = [
    {
      title: 'UserName',
      dataIndex: 'uName',
      key: 'uName',
      width: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: 'PRIVILEGRE',
      dataIndex: 'pName',
      key: 'pName',
      width: 200,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: 'admin_aption',
      dataIndex: 'ap',
      key: 'ap',
      width: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: 'common',
      dataIndex: 'cm',
      key: 'cm',
      width: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: 'INHERITED',
      dataIndex: 'id',
      key: 'id',
      width: 120,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    }
  ]
	return (
    <div className={styles.addOrEditConnectionWrap}>
			<div className={styles.returnLine}>
        <ArrowLeftOutlined
          className={styles.backIcon}
          onClick={() => {
            handleShowContentChange('tabs')
          }}
        />
        数据库用户权限: TODO 2期
      </div>
      <Table
        columns={columns}
        dataSource={[
          {
            uName: '组名一',
            pName: '连接名',
            add: '链接地址',
            ser: '服务ID',
            user: '用户abc',
            con: '管理admin',
            creer: '创建者',
          },
        ]}
        scroll={{ x: getScrollX(columns), y: 'calc(100vh - 300px)' }}
        pagination={{ pageSize: 10, total: 0, current: 1 }}
      />
    </div>
  )
}
export default UserAuthorizeList