import React, { ReactNode, useEffect, useState } from 'react'
import { Modal, Tabs, Form, Switch, Radio, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { dataChangeParam, getDataChangeParam, IDataParamType } from 'src/api'
import { DATA_CORRECTION, RELEASE_CHANGES } from '../common/common'

export const ParamSettingModal = ({ visible, onClose }: { visible: boolean, onClose: () => void }) => {
  const [form] = useForm()
  const [currentKey, setCurrentKey] = useState(DATA_CORRECTION)
  const [dataValues, setDataValues] = useState<IDataParamType>()
  const [releaseValues, setReleaseValues] = useState<IDataParamType>()
  const [backup, setBackup] = useState(false)

  useEffect(() => {
    if (visible) {
      setCurrentKey(DATA_CORRECTION)
      getData(DATA_CORRECTION)
      getData(RELEASE_CHANGES)
    }
  }, [visible])

  const getData = (key: string) => {
    getDataChangeParam(key).then((res: IDataParamType) => {
      if (key === DATA_CORRECTION) {
        // 弹框第一次打开才会触发请求，这时候初始化数据，做显示隐藏的控制
        setDataValues(res)
        form.setFieldsValue(res)
      } else if (key === RELEASE_CHANGES) {
        setReleaseValues(res)
      }
    })
  }

  const changeData = (key: string, values: IDataParamType) => {
    return dataChangeParam({ type: key, ...values } as IDataParamType)
  }

  const handleOk = () => {
    Promise.all([
      changeData(DATA_CORRECTION, dataValues as IDataParamType),
      changeData(RELEASE_CHANGES, releaseValues as IDataParamType)
    ]).then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i]) {
          continue;
        } else {
          // 有一个接口报错，就整个都错
          return;
        }
      }
      message.success('更新成功')
    })
    onClose()
  }

  const onChangeFormData = (value: Record<string, string | boolean>, allvalues?: any) => {
    if (currentKey === DATA_CORRECTION) {
      setDataValues(allvalues)
    } else if (currentKey === RELEASE_CHANGES) {
      setReleaseValues(allvalues)
    }
  }


  const onChangeTab = (key: string) => {
    setCurrentKey(key)
    if (key === DATA_CORRECTION) {
      form.setFieldsValue(dataValues as IDataParamType)
    } else if (key === RELEASE_CHANGES) {
      form.setFieldsValue(releaseValues as IDataParamType)
    }
  }

  const renderForm = (): ReactNode => {
    return <Form
      name="basic"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={dataValues}
      onFinish={handleOk}
      autoComplete="off"
      onValuesChange={onChangeFormData}
    >
      <Form.Item
        label="整体事务"
        name="overallTransaction"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </Form>;
  }

  const tabs = [
    {
      label: '数据订正',
      key: DATA_CORRECTION,
      children: renderForm()
    },
    {
      label: '发布变更',
      key: RELEASE_CHANGES,
      children: renderForm()
    }
  ]

  return (
    <Modal
      title="参数配置"
      onOk={handleOk}
      onCancel={onClose}
      visible={visible}
    >
      <Tabs
        activeKey={currentKey}
        tabPosition="left"
        type="card"
        onChange={onChangeTab}
      >
        {
          tabs.map(item => {
            const { key, children, label } = item

            return <Tabs.TabPane
              key={key}
              tab={label}
            >{children}</Tabs.TabPane>
          })
        }
      </Tabs>
    </Modal>
  );
}
