import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  permissionCollections: any;
  connectionObjectPermissions: any
}

const initialState: IState = {
  permissionCollections: {},
  connectionObjectPermissions: {}
}

export const automaticAuthorizationOperateSlice = createSlice({
  name: "automaticAuthorizationOperate",
  initialState,
  reducers: {
    updatePermissionCollections: (state, action: PayloadAction<any>) => {
      const newValue = action.payload
      state.permissionCollections = {...state.permissionCollections, ...newValue};
    },
    resetPermissionCollections: (state) => {
      state.permissionCollections = {}
    },
    updateConnectionObjectPermissions: (state, action: PayloadAction<any>) => {
      const settings = action.payload
      state.connectionObjectPermissions = settings;
    },
    resetConnectionObjectPermissions: (state) => {
      state.connectionObjectPermissions = {}
    }
  },
});

export const automaticAuthorizationOperateReducer = automaticAuthorizationOperateSlice.reducer

export const { 
  updatePermissionCollections, 
  resetPermissionCollections,
  updateConnectionObjectPermissions,
  resetConnectionObjectPermissions
} = automaticAuthorizationOperateSlice.actions;
