import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import classNames from 'classnames'
import React, { useCallback, useRef, useState, useMemo } from "react";
import { getTaskResult, makeResultAllExport, QueryResult } from 'src/api'
import { CustomHeader, indexColDef } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import Service from 'src/service'
import { AddSelectedResultExportModal } from './AddSelectedResultExportModal'
import styles from './grid.module.scss'
import { defaultColDef, GridConfigBase } from './gridConfig/GridConfig'
import { 
  updateResultTabs 
} from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import { ResultToolbar } from './ResultToolbar'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { AddResultExportModal } from './AddResultExportModal'
import { openFlowForm } from 'src/pageTabs/flowPages'
import { ResultAllExport } from './ResultAllExport'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo';
import { useDownloadNotificationRenderer } from 'src/features/websocket/useDownloadNotification';

interface ResultGridProps {
  result: QueryResult
  tabResultKey: string
  queryKey: string
  type?: 'explain' | 'execute'
}
const emptyFunction = () => { }

export const TSQLResultGrid: React.FC<ResultGridProps> = (props) => {
  const dispatch = useDispatch()
  const [renderNotification] = useDownloadNotificationRenderer()

  const { result, queryKey, tabResultKey } = props
  const {
    connectionId,
    columnInfos,
    resultData,
    editable,
    dataExport,
    refreshable,
    scale,
    dataSourceType,
    operatingObject,
    databaseName,
    statement,
    permissionResult
  } = result;

  const { theme } = useSelector((state) => state.appearance)
  const { tabInfoMap } = useSelector((state) => state.queryTabs)
  const { 
    resultTabMap,
  } = useSelector((state) => state.resultTabs)
  const paneType = tabInfoMap[queryKey].paneType

  const columnDefs: ColDef[] = columnInfos.map((def) => {
    const { columnName, comment, desensitization } = def
    const isDesens = Service.moduleService.isModuleExist('/flow')
      ? desensitization?.status
      : false
    return {
      field: columnName,
      sortable: false,
      filter: false,
      headerComponentFramework: CustomHeader,
      headerComponentParams: { headerName: columnName, comment, isDesens },
    }
  })

  columnDefs.unshift(indexColDef)

  const gridApiRef = useRef<GridReadyEvent | null>(null)

  const handleGridReady = (options: GridReadyEvent): void => {
    gridApiRef.current = options
  }

  const [visibleCommonExport, setVisibleCommonExport] = useState(false)
  const [visibleSelectedExport, setVisibleSelectedExport] = useState(false)
  const [visibleExportAll, setVisibleExportAll] = useState(false)
  const isDataExportPermissionEnabled = Service.moduleService.isModuleExist(
    '/flow',
  )
    ? dataExport.status
    : false
  // 放大缩小页面
  const handleScaleView = (num: number) => {

    //设置单独结果集scale值 queryKey {}
    const resultTabs = Object.keys(resultTabMap).map(key => {
      if (key === tabResultKey) {
        return {
          key,
          info: {
            ...resultTabMap[key].info,
            scale: num
          }
        }
      }
      return resultTabMap[key]
    })
    
    dispatch(updateResultTabs(resultTabs))
  }

  const onOpenExportModal = (key: string) => {
    const visibleMap = {
      ResultExport: () => setVisibleCommonExport(true),
      SelectedResultExport: () => setVisibleSelectedExport(true),
      ResultAllExport: () => setVisibleExportAll(true),
    }
    if (key in visibleMap) {
      visibleMap[key as keyof typeof visibleMap]()
    }
  }

  /* 打开申请流程权限表单 */
  const applyDataExportPermission = async () => {
    dispatch(
      openFlowForm({
        type: 'dataExport',
      }),
    )
  }

  const { fetchTaskResult } = useHandleExternalInfo(getTaskResult, (data: any) => {
    renderNotification(data)
  })
  
  // 补充 result 全导出方法
  const handleExportAllResult = useCallback(
    (data: any) => {
      const params = {
        connectionId: result.connectionId,
        connectionType: result.dataSourceType,
        databaseName: result.databaseName,
        operatingObject: result.operatingObject,
        statement: result.statement,
        containTempTable: result.containTempTable,
        tabKey: result.tabKey,
      }
      return makeResultAllExport(Object.assign({}, params, data)).then((data) => {
        fetchTaskResult(data)
      })
    },
    [result, fetchTaskResult],
  )

  /** 获取可用总行数方法 */
  // const getTotal = useCallback(
  //   (callback: (v: number) => void) => {
  //     permissionExportNumber({
  //       connectionId,
  //       connectionType: result.dataSourceType,
  //       databaseName: result.databaseName,
  //       statement: result.statement,
  //       containTempTable: result.containTempTable,
  //       tabKey: result.tabKey,
  //     }).then((v) => {
  //       callback(v)
  //     })
  //   },
  //   [result, dataExport, visibleCommonExport, connectionId],
  // )

  const executePayload = useMemo<any>(
    () => ({
      connectionId,
      dataSourceType,
      operatingObject,
      databaseName,
      statements: [statement] || [],
      tabKey: queryKey,
    }),
    [
      connectionId,
      dataSourceType,
      databaseName,
      operatingObject,
      queryKey,
      statement,
    ]
  );
  return (
    <div className={styles.resultGrid}>
      <div className={styles.resultContent}>
        <ResultToolbar
          enabledActions={[]}
          paneType={paneType}
          onRefresh={emptyFunction}
          onAddRow={emptyFunction}
          onDeleteRow={emptyFunction}
          onConfirmModify={emptyFunction}
          onCancelModify={emptyFunction}
          onViewCell={emptyFunction}
          readOnly={!editable}
          refreshable={refreshable}
          isDataExportPermissionEnabled={isDataExportPermissionEnabled}
          applyDataExportPermission={applyDataExportPermission}
          onOpenExportModal={onOpenExportModal}
          onScalePage={handleScaleView}
          showExported={dataExport.showExported}
          scale={scale === undefined ? 100 : scale}
          executePayload={executePayload}
          permissionResult={permissionResult}
          dataExport={dataExport}
        />
        <div
          id={`${tabResultKey}gridWrapper`}
          className={classNames(
            styles.gridWrapper,
            theme === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"
          )}
        >
          <AgGridReact
            {...GridConfigBase}
            modules={[ClientSideRowModelModule]}
            gridOptions={{ rowData: resultData }}
            columnDefs={columnDefs}
            defaultColDef={{ ...defaultColDef, editable: false }}
            onGridReady={handleGridReady}
          />
        </div>
      </div>
      <AddSelectedResultExportModal
        visible={visibleSelectedExport}
        setVisible={setVisibleSelectedExport}
        gridApi={gridApiRef.current?.api || null}
      />
      <AddResultExportModal
        result={result}
        // getTotal={getTotal}
        visible={visibleCommonExport}
        setVisible={setVisibleCommonExport}
      />
      <ResultAllExport
        result={result}
        visible={visibleExportAll}
        setVisible={setVisibleExportAll}
        hanldeExportAll={handleExportAllResult}
      />
    </div>
  );
}
