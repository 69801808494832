import Service from '../index'
import { IModuleModel, IModuleMapKeys } from '../moduleService'
import { store } from 'src/store'
import { flowTypes, PERM_STORE as PERM_STOREs, IPermKey, IPerm } from './PermStore'
import { isEmpty, find } from 'lodash'

/* todo:和原有ui 权限命名不一致，但是没关系 */
type UiPerm =
  /* 系统设置 */
  /* 000001 */
  | 'SystemSettings'
  /* 连接管理 */
  /* 000010 */
  | 'ConnectionSettings'
  /* 审计分析 */
  /* 000100 */
  | 'AuditAnalysis'
    /* 新版审计分析 */
  /* 000100 */
  /* 监控 */
  /* 001000 */
  | 'Monitor'
  /* 流程 */
  /* 010000 */
  | 'FlowProcess'
  /* 告警分析 */
  /* 100000 */
  | 'Warning'
  /* 权限 dashboard */
  /* 1000000 */
  | 'Dashboard'

interface IGetPermModuleList {
  (): IModuleModel[]
}

interface ICheckUiPerm {
  (uiPerm: UiPerm): number
}

interface IPermService {
  getPermModuleList: IGetPermModuleList
  PERM_STORE: typeof PERM_STOREs
  hasChildByPermKey: any
  isPermKey: any
  getPermKeys: any
  getPermData: any
}

const cq3IgnoredPerms = ['objectFilter', 'executeTimes', 'executeRows', 'executeDate', 'highRiskOperation', 'Desensitization', 'timeLimitGroup', 'resourceLimitGroup']

// 后端权限和前端模块的关联关系
const FRONETEND_MODULE_UI_PERM_MAP: { [key in IModuleMapKeys]: UiPerm } = {

  '/analysis-operation': 'AuditAnalysis',
  '/analysis-execution': 'AuditAnalysis',
  '/analysis-overview': 'AuditAnalysis',
  // '/alarm': 'Warning',
  '/organization': 'SystemSettings',
  '/system/role-management': 'SystemSettings',
  '/system/permission-management': 'SystemSettings',
  '/system/maskingInternal-management': 'SystemSettings',
  '/system': 'SystemSettings',
  '/sysmonitor': 'SystemSettings',
  '/sysmonitor/host': 'SystemSettings',
  '/sysmonitor/docker': 'SystemSettings',
  '/app-management': 'SystemSettings',
  '/setting': 'SystemSettings',
  '/connection_management': 'ConnectionSettings',
  // '/connection-monitor': 'Monitor',
  '/flow': 'FlowProcess',
  '/dashboard': 'Dashboard',
  '/app-secret': 'SystemSettings',
  '/authorization-management': 'ConnectionSettings',
}

class PermService implements IPermService {

  PERM_STORE: typeof PERM_STOREs 
  constructor(cqVersion?: string){
    if(cqVersion === 'CQ3') {
      this.PERM_STORE = PERM_STOREs.filter(p => !cq3IgnoredPerms.includes(p.key))
    } else {
      this.PERM_STORE = PERM_STOREs
    }
  }

  public getPermModuleList() {
    // 0 获取项目的 module
    const projectModuleList = Service.moduleService.getModuleList()
    // todo:根据用户角色进行过滤
    const filterModuleListByUIPerm = projectModuleList.filter((it) =>
      this.checkModulePerm(it.key),
    )
    return filterModuleListByUIPerm
  }

  /**
   * 校验模块权限，默认拥有权限
   *
   * @memberof PermService
   */
  public checkModulePerm = (moduleKey: IModuleMapKeys): boolean => {
    let hasPerm = 1
    // 0 前端是否存在该模块
    hasPerm = hasPerm & Service.moduleService.checkModuleExist(moduleKey)
    // 0 存在对应 ui 权限限制进行校验
    if (moduleKey in FRONETEND_MODULE_UI_PERM_MAP) {
      const UIPermName = FRONETEND_MODULE_UI_PERM_MAP[moduleKey]
      hasPerm = hasPerm & this.checkUiPerm(UIPermName)
    }
    return Boolean(hasPerm)
  }

  public checkAuthWithCode = (uiPerm: UiPerm, code = 0) => {
    switch (uiPerm) {
      // 系统设置
      case 'SystemSettings':
        return (code >> 0) & 1
      // 连接管理
      case 'ConnectionSettings':
        return (code >> 1) & 1
      // 审计
      case 'AuditAnalysis':
        return (code >> 2) & 1
      // 监控
      case 'Monitor':
        return (code >> 3) & 1
      // 流程
      case 'FlowProcess':
        return (code >> 4) & 1
      // 告警
      case 'Warning':
        return (code >> 5) & 1
      // 权限dashboard
      case 'Dashboard':
        return (code >> 6) & 1
      default:
        return 0
    }
  }

  public checkUiPerm: ICheckUiPerm = (uiPerm) => {
    const { login } = store.getState()

    // todo: 优化算法
    // todo: 与 UiPerm 结合，自动枚举
    // todo: 无需外部提供入参 systemAuthString。调整当前函数位置，在函数内获取 context 中的 systemAuthString

    return this.checkAuthWithCode(uiPerm, login.userInfo.systemAuthCode)
    // return checkAuthWithCode(uiPerm, 15)
  }

  public getChildPermByPermKey = (permKey?: IPermKey) => {
    if (!permKey) return this.PERM_STORE.filter((it) => !it.parentKey)
    return this.PERM_STORE.filter((it) => it.parentKey === permKey)
  }

  public hasChildByPermKey = (permKey: IPermKey) => {
    return !isEmpty(this.getChildPermByPermKey(permKey))
  }

  public isPermKey = (permKey: any) => {
    if (!permKey) return false
    return !!find(this.PERM_STORE, { key: permKey })
  }

  public getPermKeys = () => {
    return this.PERM_STORE.map(({ key }) => key).filter((key) => key)
  }

  public getPermData = (fliter: Partial<IPerm>) => {
    return find(this.PERM_STORE, fliter)
  }

  public isFlowType = (flowType: string) => {
    return flowTypes.some((flow) => flow === flowType)
  }
}


export const permService =  new PermService()
export const permServiceCq3 = new PermService('CQ3')