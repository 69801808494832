import React from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Switch } from 'antd'
import { setQueryTheme } from 'src/store/extraSlice/appearanceSlice'
import { patchUserConfig } from 'src/api'

export const ThemeSwitch = () => {
  const dispatch = useDispatch()
  const { theme } = useSelector((state) => state.appearance)
  return (
    <Switch
      defaultChecked={theme === 'dark' ? true : false}
      onChange={(checked) => {
        const theme = checked ? 'dark' : 'light'
        dispatch(setQueryTheme(theme))
        patchUserConfig({ theme })
      }}
      checkedChildren={
        <span role="img" aria-label="moon">
          🌙
        </span>
      }
      unCheckedChildren={
        <span role="img" aria-label="sun">
          ☀️
        </span>
      }
      size="small"
      style={{
        marginLeft: 16,
        backgroundColor: theme === 'dark' ? '#3f84e9' : '#38383c',
      }}
    />
  )
}
