import { Card, Checkbox } from 'antd';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import './sysRoles.scss';
import { cloneDeep } from 'lodash';

const StatusCard = ({isEdit}: {isEdit: boolean}) => {
  const initStatus = [
    {
      name: '全部权限 未授权',
      type: 'WRITE_NOT_AUTH'
    },
    {
      name: '全部权限 已授权',
      type: 'WRITE_AUTHED'
    },
    {
      name: '全部权限 必选',
      type: 'WRITE_Required'
    },
    {
      name: '只读权限 未授权',
      type: 'READ_NOT_AUTH'
    },
    {
      name: '只读权限 已授权',
      type: 'READ_AUTHED'
    },
    {
      name: '可读权限 必选',
      type: 'READ_Required'
    },
  ]
  const [showStatus, setShowStatus] = useState<any>(initStatus)

  useEffect(() => {
    if (!isEdit) {
      setShowStatus((status: any[]) => {
        return status.map((item) => {
          if (!item?.type.includes('NOT')) {
            return cloneDeep(item);
          }
        });
      });
    } else {
      setShowStatus(initStatus)
    }
  }, [isEdit])

  const getIconSty = (status: string) => {
    let sty = ''
    if (isEdit) {
      switch (status) {
        case 'READ_Required':
          sty = 'readRequired-back';
          break;
        case 'WRITE_Required':
          sty = 'writeRequired-back';
          break;
        case 'READ_NOT_AUTH':
          sty = 'readunauth-back';
          break;
        case 'READ_AUTHED':
          sty = 'readauth-back';
          break;
        case 'WRITE_NOT_AUTH':
          sty = 'writeunauth-back';
          break;
        case 'WRITE_AUTHED':
          sty = 'writeauth-back';
          break;
      }
    } else if (!isEdit) {
      switch (status) {
        case 'READ_Required':
          sty = 'readRequired-back';
          break;
        case 'WRITE_Required':
          sty = 'writeRequired-back';
          break;
        case 'READ_AUTHED':
          sty = 'readauth-back-notEdit';
          break;
        case 'WRITE_AUTHED':
          sty = 'writeauth-back-notEdit';
          break;
      }
    }
    return sty;
  }

  return (
    <Card
      key='authDef'
      title={isEdit ? '编辑权限样式定义' : '权限样式定义'}
      bordered={false}
    >
      {
        !!showStatus && showStatus.map((item: any, index: any) => {
          return item && <div className='sys-roles-menu-second-level'>
            <Checkbox
              key={index}
              disabled={true}
              checked={true}
              indeterminate={(['READ_NOT_AUTH', 'READ_AUTHED', 'READ_Required'].includes(item?.type)) ? true : false}
              className={classnames(
                'checkbox-position',
                getIconSty(item?.type),
                'notClick', 
              )}
            />
            <span>{item?.name}</span>
          </div>
        })
      }
    </Card>
  )
};

export default StatusCard;