import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { message } from 'antd'
import { UIModal } from 'src/components'
import { UserManagement } from './UserManagement'
import { fetchDeptUserList } from 'src/pageTabs/organizationPage/organizationSlice'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { editGroupUsers } from 'src/api'
import difference from 'lodash/difference'
import { fetchOrgTreeDataNoSelectNode } from '../organizationSlice'
import { toInteger } from 'lodash'

export const UserManagementModal: React.FC = () => {
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  // 该节点已有的用户列表
  const { userList: currentUserList } = useSelector(
    (state) => state.organization,
  )

  const [targetKeys, setTargetKeys] = useState<string[]>([])
  const { organizationPageSize, organizationSearch } = useSelector(
    (state) => state.organization,
  )

  useEffect(() => {
    // 已有用户列表变化(切换了选中节点)
    const targetKeys = currentUserList?.map(({ userId }) => userId) || []
    setTargetKeys(targetKeys)
  }, [currentUserList])

  const visible = useSelector((state) => state.modal.UserManagement)
  const { selectedNode } = useSelector((state) => state.organization)

  const handleConfirm = useCallback(() => {
    if (!selectedNode) return
    const userIdList = currentUserList.map(({ userId }) => userId)
    const addUsers = difference(targetKeys, userIdList)
    const removeUsers = difference(userIdList, targetKeys)
    const { id: groupId, orgType } = selectedNode

    setConfirmLoading(true)

    editGroupUsers({
      addUsers,
      removeUsers,
      groupId: toInteger(groupId),
      orgType,
    })
      .then(() => {
        dispatch(fetchOrgTreeDataNoSelectNode())
        message.success('成员编辑成功')
        dispatch(
          fetchDeptUserList({
            dept: selectedNode,
            search: organizationSearch,
            pageNum: 0,
            pageSize: organizationPageSize,
          }),
        )
        dispatch(hideModal('UserManagement'))
      })
      .finally(() => {
        setConfirmLoading(false)
      })
  }, [dispatch, currentUserList, selectedNode, targetKeys])

  return (
    <UIModal
      visible={visible}
      title="成员管理"
      onCancel={() => {
        dispatch(hideModal('UserManagement'))
      }}
      onOk={handleConfirm}
      afterClose={() => {
        const targetKeys = currentUserList.map(({ userId }) => userId)
        setTargetKeys(targetKeys)
      }}
      confirmLoading={confirmLoading}
      width={'80vw'}
      bodyStyle={{ height: 676, padding: '16px 24px' }}
    >
      <div style={{ marginBottom: 16 }}>{selectedNode?.name}</div>
      <UserManagement
        targetKeys={targetKeys}
        onTargetKeysChange={setTargetKeys}
      />
    </UIModal>
  )
}
