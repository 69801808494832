import React, { useCallback, useMemo } from 'react'
import { useRequest, useSelector, useDispatch } from 'src/hook'
import { fetchDeptUserList } from '../organizationSlice'
import { Form, message, Select } from 'antd'
import { UIModal } from 'src/components'
import { CopyUser, GetCopyUser, UserEntity } from 'src/api'
import { useModal } from 'src/hook'

export const CopyUserModal = ({ record, refreshPermission }: any) => {
  const { visible, closeModal } = useModal('CopyUser')
  const [form] = Form.useForm()
  const { data: users, loading: loadingUsers } = useRequest(GetCopyUser)
  const { organizationPageSize, organizationSearch } = useSelector(
    (state) => state.organization,
  )
  const options: { label: React.ReactElement; value: string }[] | undefined =
    useMemo(
      () =>
        users?.map((user: UserEntity) => ({
          label: user.userName,
          value: user.userId,
        })),
      [users],
    )

  const dept = useSelector((state) => state.organization.selectedNode)

  const dispatch = useDispatch()

  const onOk = useCallback(async () => {
    try {
      const values = await form.validateFields()
      CopyUser({
        sourceUser: values.copyUser.value,
        targetUser: record.userId,
      }).catch(() => {}).finally(() => {
        refreshPermission({ userId: record.userId })
      })
      message.success(`复制${values?.copyUser.label}拥有角色成功`)
      form.resetFields()
      if (dept) {
        dispatch(fetchDeptUserList({ dept, search: organizationSearch, pageNum: 0, pageSize: organizationPageSize }))
      }
      closeModal()
    } catch (e) {}
  }, [closeModal, dept, dispatch, form, record.userId, refreshPermission])

  return (
    <UIModal
      title="用户角色复制"
      visible={visible}
      onCancel={() => {
        form.resetFields()
        closeModal()
      }}
      onOk={onOk}
      width={420}
    >
      <Form form={form}>
        <Form.Item label=" 复制用户" name="copyUser">
          <Select
            labelInValue
            loading={loadingUsers}
            placeholder="请先选择用户姓名"
            showSearch
          >
            {options?.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}({value})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
