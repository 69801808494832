import { fetchGet, fetchPost } from './customFetch'

// 获取 慢sql top10 接口
export const getSql = () => {
  return fetchPost(`/analyze/audit_report/slow/sql`)
}

// 用户授权情况
export const getUerAuthorization = () => {
  return fetchGet(`analyze/operate_log/flow/amount`)
}

// 操作卡片 /analyze/audit_report/card/operate
interface Params {
  "unit": string,
}
export const getOperateCard = (params: Params) => {
  return fetchPost(`/analyze/audit_report/card/operate`, params)
}

// 活跃用户Card /analyze/audit_report/card/operate
interface Params {
  "unit": string,
}
export const getActiveUserCard = (params: Params) => {
  return fetchPost(` /analyze/audit_report/card/user`, params)
}

// 审计概览-顶部数据接口
export const getCardCenter = () => {
  return fetchPost(` /analyze/audit_report/card/center`)
}

