import React, { useMemo, useState } from 'react'
import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
  matchPath
} from 'react-router'
import { Button, Layout, Card, Menu } from 'antd'
import { SimpleBreadcrumbs } from 'src/components'
import { FlowApply } from './FlowApply'
import { FlowMyApplication } from './FlowMyApplication'
import { FlowPendingApproval } from './FlowPendingApproval'
import { FlowManage } from './FlowManage'
import { FlowDesginer } from './FlowDesginer'
import { VerticalLeftOutlined, VerticalRightOutlined } from '@ant-design/icons'
import { Iconfont } from 'src/components'
import classNames from 'classnames'
import styles from './flowPage.module.scss'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { useSelector } from 'src/hook'

const { Sider } = Layout

const flowSubRoutesAll = [
  {
    to: "/flow_apply",
    routerPath: "/flow_apply",
    breadcrumbName: "申请流程",
    component: <FlowApply />,
    icon: <Iconfont type="icon-apply" />,
  },
  {
    to: "/flow_apply/approval/pending",
    routerPath: "/flow_apply/approval/:status(pending|resolved)",
    breadcrumbName: "我的审批",
    component: <FlowPendingApproval />,
    icon: <Iconfont type="icon-approval" />,
  },
  {
    to: "/flow_apply/application",
    routerPath: "/flow_apply/application",
    breadcrumbName: "我的申请",
    component: <FlowMyApplication />,
    icon: <Iconfont type="icon-application" />,
  },
  // {
  //   to: "/flow_apply/design",
  //   routerPath: "/flow_apply/design",
  //   breadcrumbName: "设计流程",
  //   component: <FlowDesginer />,
  //   icon: <Iconfont type="icon-application" />,
  // },
  {
    to: "/flow_apply/approval/manageFlow",
    routerPath: "/flow_apply/approval/manageFlow",
    breadcrumbName: "管理流程",
    component: <FlowManage />,
    icon: <Iconfont type="icon-approval" />,
  },
];

export function FlowPage() {
  const history = useHistory()
  const { pathname } = useLocation()

  const { owner } = useSelector((state: any) => state.login.userInfo)

  const flowSubRoutes = useMemo(() => {
    return flowSubRoutesAll.filter((item) => {
      let result = true
      if (item?.routerPath === '/flow/design' || item?.routerPath === '/flow/approval/manageFlow') {
        return owner
      }
      return result
    })
  }, [])

  const activeSubRoute = flowSubRoutes.find(({ routerPath }) => {
    return matchPath(pathname, {
      path: routerPath,
    })?.isExact;
  });

  // sider collapse status
  const [collapsed, setCollapsed] = useState(false)
  const breadcrumbData = [
    {
      title: "流程",
    },
    {
      title: activeSubRoute?.breadcrumbName || "",
    },
  ];
  return (
    <Layout className={styles.wrapper}>
      <div style={{ padding: "0 20px" }}>
        <SimpleBreadcrumbs items={breadcrumbData} />
      </div>
      <Layout className={styles.layout}>
        <Sider
          className={classNames(
            styles.sider,
            !collapsed && styles.siderUncollapsed
          )}
          theme="light"
          width={246}
          collapsed={collapsed}
        >
          <Card
            title={collapsed ? "" : "全部流程"}
            extra={
              <Button
                type="text"
                size="small"
                onClick={() => setCollapsed((state) => !state)}
                icon={
                  collapsed ? (
                    <VerticalLeftOutlined />
                  ) : (
                    <VerticalRightOutlined />
                  )
                }
              />
            }
            bordered={false}
            {...(!collapsed && { headStyle: { padding: 0 } })}
            bodyStyle={{ padding: 0 }}
          >
            <Menu
              className={styles.siderMenu}
              selectedKeys={[activeSubRoute?.to || "/flow_apply"]}
              onClick={({ key }) => history.push(key as string)}
            >
              {flowSubRoutes.map(({ to, breadcrumbName, icon }) => (
                <Menu.Item key={to} icon={icon}>
                  {breadcrumbName}
                </Menu.Item>
              ))}
            </Menu>
          </Card>
        </Sider>
        <div className={styles.content}>
          <Switch>
            {flowSubRoutes.map(({ routerPath, component }) => (
              <Route key={routerPath} path={routerPath} exact>
                <ErrorBoundary>{component}</ErrorBoundary>
              </Route>
            ))}
            <Redirect
              to={
                flowSubRoutes.length > 0
                  ? flowSubRoutes[0].routerPath
                  : "/login"
              }
            />
          </Switch>
        </div>
      </Layout>
    </Layout>
  );
}
