/*
 * @Author: fuzhenghao
 * @Date: 2023-01-17 09:42:37
 * @LastEditTime: 2023-01-17 11:19:49
 * @LastEditors: fuzhenghao
 * @Description: 消息相关接口
 * @FilePath: \cq-enterprise-frontend\src\api\userMessage.ts
 */

import { fetchGet, fetchPost } from './customFetch'

/* ----------------------------------- api ---------------------------------- */

const batchDelMessagesApi = `user/msg/batchDelMessages`

/* -------------------------------- interface ------------------------------- */

export interface IParams_batchDelMessagesPost {
  ids: Array<string | number>
}

/* ---------------------------------- 接口请求 ---------------------------------- */

export const getAllMes = (pageNum: number, pageSize: number) => {
  return fetchGet(`/user/msg/getMessageByUserId/${pageNum}/${pageSize}`)
}

export const getUnReadMes = (userId: string) => {
  return fetchGet(`/user/msg/getMessageUnRead/${userId}`)
}

export const getUnReadMesNum = (userId: string) => {
  return fetchGet(`/user/msg/getMessageUnReadNum/${userId}`)
}

export const putReadMes = (id: number) => {
  return fetchPost(`/user/msg/readMessage/${id}`)
}

export const putReadMess = (ids: Number[]) => {
  return fetchPost(`/user/msg/markedMessageReaded`, ids)
}

export const batchDelMessagesPost = (params: IParams_batchDelMessagesPost): Promise<number> => {
  return fetchPost(batchDelMessagesApi, params)
}
