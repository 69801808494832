import React from "react";
import { Layout } from "antd";
import "src/styles/layout.scss";
import { ErrorBoundary, SimpleBreadcrumbs } from "src/components";
import { DetailTable } from './detailTable'
import styles from "./index.module.scss";

const { Content } = Layout;

const BREADCRUMB_ITEMS = [
	{
		title: "数据脱敏",
	},
	{
		title: "任务记录",
		href: '/transfer-task-record',
	},
  {
		title: "查看详情",
	},
];

export const TransferDetailPage = () => {
	return (
		<Layout className={styles.dataTransferPageWrap}>
			<div>
				<SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
			</div>
			<Layout className={styles.mainWrap}>
				<Content>
					<ErrorBoundary>
            <DetailTable/>
					</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};
