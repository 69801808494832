import React from 'react'
import { useSelector } from 'src/hook'
import { Form, InputNumber } from 'antd'
import {
  ElementTreeSelect,
  FormItemApplicant,
  FormItemApprover,
  FormItemReason,
  FormItemTimeLimit,
} from '../flowFormItems'
import { FlowModalForm } from './FlowModalForm'
import { FormFieldElementEntity, FormFieldsDataExport } from 'src/types'
import { launchFlowApply } from 'src/api'

export const ModalFormDataExport = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const flowType = 'dataExport'

  const [form] = Form.useForm()
  const onResetApprover = () => {
    form && form.setFieldsValue({approveUserId:''})
  }

  return (
    <FlowModalForm<FormFieldsDataExport>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return

        const { approveUserId, applyReason, elements, timeLimit, numberLimit } =
          values
        const {
          connectionId,
          connectionType: dataSourceType,
          nodeType,
        } = elements?.[0]
        const expiredTimeLimit = timeLimit.preset || timeLimit.custom
        const nodePathList = elements.map(({ nodePath }) => nodePath)
        return launchFlowApply({
          applyUserId,
          email,
          applyReason,
          expiredTimeLimit,
          flowType,
          connectionId,
          dataSourceType,
          nodePathList,
          nodeType,
          firstApproveUserId: approveUserId,
          exportNumLimit: numberLimit,
        })
      }}
    >
      <FormItemApplicant />
      <Form.Item
        label="数据库元素"
        name="elements"
        required
        rules={[
          {
            validator: (_, value?: FormFieldElementEntity[]) => {
              if (!value?.length) {
                return Promise.reject('请选择数据库元素')
              }
              const ruleObj = value[0]
              for (const el of value) {
                if (el.connectionId !== ruleObj.connectionId) {
                  return Promise.reject('只能选择相同连接下的元素')
                }
                if (el.nodeType !== ruleObj.nodeType) {
                  return Promise.reject('只能选择相同类型的元素')
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <ElementTreeSelect flowType={flowType} onChange={onResetApprover} />
      </Form.Item>
      {/* <Form.Item
        label="导出行数"
        name="numberLimit"
        initialValue={1000}
        validateFirst={true}
        rules={[
          {
            type: 'number',
            max: 99999999,
            message: '最大阈值为99999999',
          },
          {
            type: 'number',
            min: -1,
            message: '最小阈值为-1',
          },
          {
            type: 'number',
            required: true,
            message: '请输入导出行数',
          },
        ]}
      >
        <InputNumber
          placeholder="输入-1 表示无导出行数限制"
          style={{ width: '100%' }}
          max={99999999}
          min={1}
        />
      </Form.Item> */}
      <FormItemApprover />
      <FormItemReason />
      <FormItemTimeLimit />
    </FlowModalForm>
  )
})
