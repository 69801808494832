import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { ICellRendererParams } from '@ag-grid-community/core'
import styles from './SelectEditor.module.scss'

interface SelectEditorProps extends ICellRendererParams {
  values: string[]
}

export const SelectEditor = forwardRef((props: SelectEditorProps, ref) => {
  const { values = [] } = props
  const [value, setValue] = useState(props.value)
  const [editing, setEditing] = useState(true)
  const refContainer = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value
      },
      isPopup() {
        return true
      },
    }
  })

  // todo: 渲染组件时 focus
  // todo: 键盘操作

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing()
    }
  }, [editing, props.api])

  return (
    <div
      ref={refContainer}
      className={styles.select}
      tabIndex={1} // ag-grd: important - without this the key presses wont be caught
    >
      {values.map((value) => (
        <div
          onClick={() => {
            setValue(value)
            setEditing(false)
          }}
          key={value}
          className={styles.selectItem}
        >
          {value}
        </div>
      ))}
    </div>
  )
})
