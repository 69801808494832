import React, { useEffect, useMemo, useState } from 'react'
import { Upload, Button, Spin, message } from 'antd'
import { RcFile } from 'antd/lib/upload'
import {
  calculateHash,
  CHUNK_SIZE,
  getFileSuffix,
  readFileMd5,
  splitFile,
  useTimer,
} from './utils'
import { uploadFileChunks, validateFileExist } from 'src/api'
import { UploadProps } from 'antd/es/upload/interface'
import styles from './index.module.scss'

interface IBigFileUploadProps extends UploadProps {
  children: React.ReactElement
  requestParams?: {
    upload?: Record<string, string>
    validate?: Record<string, string>
  }
  refresh?: () => void
}

export const BigFileUpload = (props: IBigFileUploadProps) => {
  // const [fileName, setFileName] = useState('')
  const { refresh, requestParams, directory,  ...others } = props
  const [fileSize, setFileSize] = useState(0)
  // const [chunkList, setChunkList] = useState<any[]>([])
  //   const [hashPercentage, setHashPercentage] = useState(0)
  const [uploadFileList, setUploadFileList] = useState<RcFile[]>([])
  // const [uploading, setUploading] = useState<boolean>(false)
  const [uploadProcess, setUploadProcess] = useState<{
    uploading: boolean
    current: Number
    total: Number
  }>({
    uploading: false,
    current: 0,
    total: 0,
  })

  const timerInstance = useTimer(fileSize)

  const beforeUpload = (file: RcFile) => {
    setUploadFileList([file])
    // // 文件分片上传
    handleUploadFile(file)
    return false
  }

  const handleUploadFile = async (file: RcFile) => {
    const chunkList = splitFile(file)
    const fileName = file.name
    // setFileName(file.name)
    setFileSize(file.size)
    // setChunkList(chunkList)
    if (!file.name) {
      message.warning('请先选择文件')
      return
    }
    if (chunkList.length === 0) {
      message.warning('文件拆分中，请稍后...')
      return
    }
    // 上传loading状态
    setUploadProcess({
      ...uploadProcess,
      uploading: true,
    })
    // 计算hash
    const hash = await calculateHash(chunkList)
    // console.log('文件的hash为：', hash, chunkList)
 
    try {
      const {uploaded, list } = await validateFileExist({
        md5: hash,
        suffix: getFileSuffix(fileName),
        filePath: requestParams?.upload?.path,
      })
      if (uploaded) {
        setUploadProcess({
          ...uploadProcess,
          uploading: false,
        })
        message.warning('文件已存在，无需重复上传')
        return
      }
      if (list && list.length > 0) {
        message.info(`已上传文件区块:${list.length}/${chunkList.length}`)
      }

      const chunksData = chunkList
        .map(async ({ chunk }, index) => {
          const md5 = await readFileMd5(chunk)
          return {
            listLength: chunkList.length,
            //   chunk: chunk,
            hash: `${hash}-${index}`,
            md5,
            index,
            file: chunk,
            md5All: hash,
            fileName: fileName,
          }
        })
        .filter(async (item2) => {
          return !list ? true : list.indexOf((await item2).md5) === -1
        })
      // 开始上传分片
      uploadChunks(chunksData, hash)
    } catch (error) {
        setUploadProcess({
          ...uploadProcess,
          uploading: false,
        })
      }
  }

  const uploadChunks = async (chunksData: any, hash: string) => {
    let currentUploadIndex = 0
    // const suffix = getFileSuffix(fileName)
    timerInstance?.startTimer()
    const uploadChunk = (index: number) => {
      chunksData[index]?.then((d: any) => {
        const {
          listLength,
          md5,
          // hash,
          index: cindex,
          file,
          md5All,
          fileName,
        } = d
        const beforetime = new Date().getTime()
        uploadFileChunks({
          listLength,
          md5,
          // hash,
          index: cindex,
          file,
          md5All,
          fileName,
          ...requestParams?.upload,
          // suffix,
          chunkSize: String(CHUNK_SIZE),
        }).then((data) => {
          const requesttime = (new Date().getTime() - beforetime) / 1000
          timerInstance?.updateCurrenLostTimeBySpeed(file.size / requesttime)
          setUploadProcess({
            total: chunksData.length,
            uploading: true,
            current: currentUploadIndex + 1,
          })
          if (data && currentUploadIndex === chunksData.length - 1) {
            message.success('文件上传合并完成！')
            setUploadProcess({
              uploading: false,
              total: 0,
              current: 0,
            })
            props.refresh?.()
            timerInstance?.stopTimer()
            return
          }
          if (data) {
            currentUploadIndex++
            if (currentUploadIndex < chunksData.length)
              uploadChunk(currentUploadIndex)
          }
        }).catch((error) => {
          setUploadProcess({
            ...uploadProcess,
            uploading: false,
          })
        })
      })
    }
    uploadChunk(currentUploadIndex)
  }

  useEffect(() => {
    if (!uploadProcess.uploading) timerInstance?.stopTimer()
  }, [uploadProcess, timerInstance])

  const loadingMsg = useMemo(() => {
    const newMsg = `预计剩余时长${timerInstance?.lostTime}秒`
    // const oldMsg = `当前上传进度${uploadProcess.current}/${uploadProcess.total}`
    return timerInstance?.start ? newMsg : '正在计算上传剩余时长'
    // return uploadProcess.total === 0
    //   ? '文件切分中'
    //   : `当前上传进度${uploadProcess.current}/${uploadProcess.total}`
  }, [timerInstance])

  return (
    <Spin
      spinning={uploadProcess.uploading}
      tip={loadingMsg}
      wrapperClassName={styles.bigFileUploadSpin}
    >
      <Upload
        {...others}
        beforeUpload={beforeUpload}
        fileList={uploadFileList}
        showUploadList={false}
        multiple
        directory={directory ?? false}
      />
      {/* <Button>选择文件</Button> */}
      {/* </Upload> */}
      {/* <Button onClick={handleUploadFile}>上传文件</Button> */}
    </Spin>
  )
}
