import React, { useState, useEffect } from 'react'
import { executeMenuActionSql } from 'src/api'
import { UIModal } from 'src/components'
import { useRequest, useSelector } from 'src/hook'
import { SqlSplitter } from 'src/util'
import { DataSourceType } from 'src/api'
import { Input, message } from 'antd'
import { activePaneInfoSelector } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'

interface ISQLComfirmModalProps {
  visible: boolean
  setVisible: any
  originSql: string
  connectionId: string | number | undefined
  connectionType: DataSourceType
  databaseName: string | undefined
  queryTabKey: string | undefined
  successfulCallback: any
}

export const SQLComfirmModal = (props: ISQLComfirmModalProps) => {
  const {
    visible,
    setVisible,
    originSql,
    connectionId,
    connectionType,
    databaseName,
    queryTabKey,
    successfulCallback,
  } = props

  const { plSql } = useSelector(activePaneInfoSelector) || {}

  const { run: editIndex } = useRequest(
    (generatedSql: string) => {
      if (!connectionId) return
      const splitter = new SqlSplitter(generatedSql || '')
      const statements = splitter.split()
      return executeMenuActionSql({
        connectionId,
        dataSourceType: connectionType,
        databaseName,
        operatingObject: databaseName,
        plSql,
        tabKey: queryTabKey,
        statements,
      })
    },
    {
      manual: true,
      // 索引编辑在多条执行语句情况下，存在部分执行语句成功、部分语句失败的情况
      onSuccess: (data: any) => {
        let success_sum = 0,
          failure_sum = 0
        if (!data || data.length < 1) return message.error('执行语句为空')
        for (let item of data) {
          item.success ? success_sum++ : failure_sum++
        }
        let method =
          failure_sum === 0
            ? message.success
            : success_sum === 0
            ? message.error
            : message.warn

        method(
          `共执行${data?.length}条，成功${success_sum}条, 失败${failure_sum}条`,
        )
      },
    },
  )

  const [sql, setSql] = useState(originSql)
  useEffect(() => {
    setSql(originSql)
  }, [originSql])

  const onOk = async () => {
    try {
      await editIndex(sql)
      setVisible(false)
      successfulCallback && successfulCallback()
    } catch (error) {
      console.error(error)
      // message.error(`设计索引失败`)
    }
  }

  return (
    <UIModal
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={onOk}
      width={640}
      okText="执行"
      title="索引设计"
    >
      <Input.TextArea
        onChange={(e) => setSql(e.target.value)}
        value={sql}
        autoSize={{ minRows: 5 }}
      ></Input.TextArea>
    </UIModal>
  )
}
