import React, { useState, useEffect, Key } from 'react'
import { Form, Tree, message } from 'antd'
import { Iconfont, UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { useDispatch, useSelector } from 'src/hook';
import { openResultLogForm } from '../resultLogs/resultLogSlice';
import { QueryResult, getCheckSms } from 'src/api';

interface IProps {
  visible: boolean,
  setVisible: (val: boolean) => void,
  result: QueryResult,
  doubleCheckType: 'NONE' | 'SMS' | 'OTP' | null | undefined,
  filterNames: string[]
}

export const DesensitizedFieldsModal: React.FC<IProps> = (props) => {
  const {
    visible,
    setVisible,
    result,
    filterNames,
  } = props;

  const {
    statementObject,
    columnInfos,
    doubleCheckType,
    tabKey = '',
    connectionId,
    statement,
    dataSourceType,
    checkUserId,
    checkUserName,
    departmentName,
    checkPermissionId,
    checkedList,
  } = result || {};

  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const { executeActiveTabParams } = useSelector((state) => state?.queryTabs);

  const [treeData, setTreeData] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([statementObject as string]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [errorInfo, setErrorInfo] = useState<any>({ validateStatus: undefined, help: undefined });

  useEffect(() => {
    if (visible) {
      setCheckedKeys(checkedList || []);
      setExpandedKeys([statementObject as string]);
      setSelectedKeys([]);
    }
  }, [visible])

  useEffect(() => {
    if (Array.isArray(columnInfos)) {
      const list: { title: any; key: any; }[] = [];
      columnInfos.forEach(item => {
        const { desensitization, columnName } = item || {};
        const { status } = desensitization || {};
        if (status) {
          list.push({ title: <><Iconfont type='icon-columnGroup' /> {columnName}</>, key: columnName });
        }
      })
      if (Array.isArray(checkedList)) {
        checkedList.map(item => {
          list.push({ title: <><Iconfont type='icon-columnGroup' /> {item}</>, key: item })
        })
      }
      if (Array.isArray(filterNames)) {
        filterNames.map(item => {
          list.push({ title: <><Iconfont type='icon-columnGroup' /> {item}</>, key: item })
        })
      }
      setTreeData([{
        title: <><Iconfont type='icon-table' /> 结果集字段</>,
        key: statementObject,
        children: list,
      }])
    }
  }, [columnInfos])

  const onExpand = (expandedKeysValue: Key[]) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue: Key[] | {
    checked: Key[];
    halfChecked: Key[];
  }) => {
    setCheckedKeys(checkedKeysValue as Key[]);
  };

  const onSelect = (selectedKeysValue: Key[], info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  const onClose = () => {
    setVisible(false)
  }

  const onOk = () => {
    if (checkedKeys?.length > 0) {
      const values = executeActiveTabParams[tabKey] || {};
      setErrorInfo({ validateStatus: undefined, help: undefined });
      onClose();

      dispatch(
        openResultLogForm({
          type: `apply${doubleCheckType}Check`,
          fields: {
            ...values,
            connectionId,
            statements: [statement],
            plaintextColumns: checkedKeys.filter(item => item !== statementObject),
            permissionEchoResult: {
              doubleCheck: doubleCheckType,
              connectionType: dataSourceType,
              checkUserId,
              checkUserName,
              departmentName,
            },
            checkPermissionId,
            queryTabKey: tabKey,
          },
        })
      );
      // 发送验证码
      if (connectionId && doubleCheckType === 'SMS') {
        getCheckSms({
          connectionId: connectionId,
          queryTabKey: tabKey
        }).then((res) => {
          if (res) {
            message.success("验证码已发送")
          }
        })
      }
    } else {
      setErrorInfo({ validateStatus: 'error', help: '请选择列' })
    }
  }

  return (
    <>
      <UIModal
        title="选择字段"
        visible={visible}
        onCancel={onClose}
        onOk={onOk}
        width={526}
      >
        <Form form={form} {...FormLayout}>
          <Form.Item
            name="filesName"
            {...errorInfo}
          >
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
              treeData={treeData}
            />
          </Form.Item>
        </Form>
      </UIModal>
    </>
  )
}
