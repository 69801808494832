import React, { useEffect, useMemo, useState } from 'react'
import { Table, Tooltip, Badge } from 'antd'
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs'
import * as _ from 'lodash'
import { useSelector, useRequest } from 'src/hook'
import {
  getAndSetAuditColumn,
  findAuditOperateLogs,
  findAuditExecutionLogs,
  AuditExecutionParams,
  AuditExecutionLogParams,
  getOperateTypeEnum
} from 'src/api'
import {
  DEFAULT_OPERATE_LOG_COLUMNS,
  OPERATE_COLUMN_FIELDS,
} from 'src/constants'
import { SearchHeader } from './SearchHeader'
import { CustomColumnFieldPanel } from './CustomColumnFieldPanel'
import styles from './index.module.scss'

const transform = (query: AuditExecutionLogParams) =>
  _.omitBy(
    query,
    (value) =>
      value === null ||
      typeof value === 'undefined' ||
      (Array.isArray(value) && value.length === 0),
  )


interface IProps {
  isUnauthorized?: boolean | undefined,
  param?: any | undefined,
}
export const CustomColumnTable = (props: IProps) => {

  const { isUnauthorized, param } = props;
  const defaultTableParams = {
    limit: 10, //条数
    offset: 0, //页数
    timeRange: [
      dayjs().subtract(3, 'day').startOf('d').valueOf(),
      dayjs().endOf('d').valueOf(),
    ],
    resultFlag: undefined,
  }
  const location = useLocation<{ state: { timeRange: any } }>();
  const { userId } = useSelector((state) => state.login.userInfo);

  const [isShowCustomColumnPanel, setIsShowCustomColumnPanel] = useState(false)
  const [tableParams, setTableParams] =
    useState<AuditExecutionLogParams>(defaultTableParams)
  const [tableColumns, setTableColumns] = useState<string[]>([])

  useEffect(() => {
    if (isUnauthorized) {
      if (param) {
        setTableParams({
          ...tableParams,
          ...param,
        })
      }
    } else {
      if (location?.state) {
        setTableParams({
          ...tableParams,
          ...location?.state,
        })
      }
    }
  }, [location?.state]);

  const { loading: columnLoading, run: getAndSetColumnFields } = useRequest(
    getAndSetAuditColumn,
    {
      manual: true,
      onSuccess: (data: AuditExecutionParams) => {
        const res = data?.columns ?? []
        setTableColumns(res)
        setIsShowCustomColumnPanel(false)
      },
    },
  )

  const {
    data: logs,
    loading,
    refresh,
  } = useRequest(
    () => {
      let defaultParams = transform(tableParams)
      if (!_.isEmpty(defaultParams?.executors) && defaultParams?.executors) {
        defaultParams.userIds = defaultParams?.executors
        delete defaultParams?.executors
      }
      if (tableParams?.timeRange) {
        //@ts-ignore
        defaultParams.executeBeginMs = defaultParams.timeRange?.[0]
        //@ts-ignore
        defaultParams.executeEndMs = defaultParams.timeRange?.[1]
        delete defaultParams?.timeRange
      }
      //@ts-ignore
      return findAuditOperateLogs({
        ...defaultParams,
        //@ts-ignore
        offset: defaultParams?.offset * defaultParams?.limit,
      })
    },
    {
      refreshDeps: [tableParams],
    },
  )

  const { data: operateTypeEnum = [] } = useRequest(getOperateTypeEnum, {
    formatResult: (res: any) => {
      const result = Object.keys(res).map((i: any) => {
        return {
          text: res[i],
          value: res[i],
        }
      })
      return result
    }
  })

  useEffect(() => {
    userId &&
      getAndSetColumnFields({
        userId,
        type: 'OPERATE',
      })
  }, [userId])

  const formatColumns = useMemo(() => {
    let currentColumns = DEFAULT_OPERATE_LOG_COLUMNS
    if (!_.isEmpty(tableColumns)) {
      currentColumns = tableColumns
    }
    return currentColumns.map((key) => {
      let columnItem: any = {
        key,
        //@ts-ignore
        title: OPERATE_COLUMN_FIELDS[key],
        dataIndex: key,
        ellipsis: true,
      }
      if (key === 'resultFlag') {
        //@ts-ignore
        columnItem.render = (val: any) => (
          val ? (
            <Badge status="success" text='成功' />
          ) : (
            <Badge status="error" text='失败' />
          )
        )
        columnItem.filters = [{
          text: '执行成功',
          value: 1,
        }, {
          text: '执行失败',
          value: 0,
        }]
        columnItem.filterMultiple = false
      }
      if (key === 'detail') {
        //@ts-ignore
        columnItem.render = (val: string) => {
          return (
            <Tooltip title={val}>
              {val && val.length > 60 ? `${val.slice(0, 60)}...` : val}
            </Tooltip>
          )
        }
      }

      // 操作栏增加搜索
      if (key === 'operateEntryPoint' && operateTypeEnum?.length) {
        columnItem.filters = operateTypeEnum
      }
      return columnItem
    })
  }, [DEFAULT_OPERATE_LOG_COLUMNS, tableColumns, operateTypeEnum])

  const handleTableChange = (
    pagination: any,
    filters: Record<string, any>,
  ) => {
    const { current, pageSize } = pagination
    const { operateEntryPoint = [], resultFlag } = filters
    setTableParams({
      ...tableParams,
      offset: current - 1,
      limit: pageSize || 10,
      operates: operateEntryPoint,
      resultFlag: resultFlag?.[0]
    })
  }

  return (
    <>
      <div className={styles.tableHeader}>
        <SearchHeader
          queryParams={tableParams}
          setSearchParams={(values: any) =>
            setTableParams({ ...tableParams, ...values })
          }
          refresh={() => refresh()}
          showCustomColumnPanel={() => setIsShowCustomColumnPanel(true)}
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table
          rowKey="id"
          size="middle"
          loading={loading || columnLoading}
          columns={formatColumns}
          dataSource={logs?.data || []}
          indentSize={30}
          scroll={{ x: formatColumns?.length > 7 ? 1640 : 'max-content' }}
          tableLayout="auto"
          className={styles.customColumnTable}
          pagination={{
            className: styles.pagination,
            showSizeChanger: true,
            pageSize: logs?.limit || 10,
            current: Number(logs?.offset || 0) / (logs?.limit || 10) + 1,
            total: logs?.total || 0,
            showTotal: (total) => `共 ${total || 0} 条`,
          }}
          onChange={handleTableChange}
        />
      </div>

      <CustomColumnFieldPanel
        defaultColumnFields={
          _.isEmpty(tableColumns) ? DEFAULT_OPERATE_LOG_COLUMNS : tableColumns
        }
        visible={isShowCustomColumnPanel}
        onCancel={() => setIsShowCustomColumnPanel(false)}
        onOk={(fields: string[]) =>
          userId &&
          getAndSetColumnFields({ columns: fields, userId, type: 'OPERATE' })
        }
      />
    </>
  )
}
