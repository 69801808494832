import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, Form, Switch, Input, Typography, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useRequest } from 'src/hook'
import { LinkButton } from 'src/components'
import { getLdapConfig, putLdapConfig } from 'src/api'
import { SettingFormContext } from '../SettingFormContext'
import styles from './index.module.scss'

const { Text } = Typography

export const OpenLdapCard = () => {
  const [editing, setEditing] = useState(false)
  const form = useContext(SettingFormContext) as FormInstance

  const { data: casConfig, loading, run: getConfig } = useRequest(getLdapConfig)
  const { run: putConfig } = useRequest(putLdapConfig, {
    manual: true,
    onSuccess: () => {
      getConfig()
    }
  })

  const setFormVals = useCallback((val: any) => {
    form.setFields([
      { name: 'openLdapSwitch', value: casConfig.openLdapSwitch },
      { name: 'adminSecret', value: casConfig.adminSecret },
      { name: 'openLdapAdminName', value: casConfig.openLdapAdminName },
      { name: 'openLdapUrl', value: casConfig.openLdapUrl },
      { name: 'searchScope', value: casConfig.searchScope }
    ])
  }, [casConfig, form])

  const handleSave = useCallback(() => {
    form
      .validateFields([
        'openLdapSwitch',
        'adminSecret',
        'openLdapAdminName',
        'openLdapUrl',
        'searchScope',
      ])
      .then((val) => {
        // @ts-ignore
        putConfig(val).then((val) => {
          if (!!val.resMsg) {
            message.success(val.resMsg)
          }
        })
        setFormVals(val)
      })
      .then(() => {
        setEditing(false)
      }).catch((error) => { console.error(error) })
  }, [form, putConfig, setFormVals])

  useEffect(() => {
    if (!!casConfig) {
      setFormVals(casConfig)
    }
  }, [casConfig, form, setFormVals])

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              if (!!casConfig) {
                setFormVals(casConfig)
              }
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
          <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
        )}
    </div>
  )

  const openLdapUrlValidator = (_: any, value: string) => {
    if (!value) {
      return Promise.reject('请输入登录地址')
    }
    const reg = /(ldap|LDAP|ldaps|LDAPS):\/\/([\w.]+\/?)\S*/
    if (!reg.test(value)) {
      return Promise.reject('OpenLdap登录地址必须以ldap(s)://或者LDAP(s)://开头')
    } else {
      return Promise.resolve()
    }
  }

  return (
    <section className="cq-card flow-card" id="OpenLdapCard" style={{border: 'none'}}>
      <div className={styles.singleTitle}>
        {/* <h3 className="cq-card__title">
          OpenLdap 配置
        </h3> */}
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          {/* <Form> */}
          <Form.Item label="是否开启">
            <Form.Item
              hidden={!editing}
              name="openLdapSwitch"
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
            {!editing && (
              <Text>
                {!!casConfig && casConfig.openLdapSwitch ? '开启' : '关闭'}
              </Text>
            )}
          </Form.Item>

          <Form.Item label="管理员账号">
            {editing && <Form.Item
              hidden={!editing}
              name="openLdapAdminName"
              noStyle
            >
              <Input placeholder='管理员账号'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.openLdapAdminName}</Text>
            )}
          </Form.Item>

          <Form.Item label="管理员密码">
            {editing && <Form.Item
              hidden={!editing}
              name="adminSecret"
              noStyle
            >
              <Input placeholder='管理员密码'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.adminSecret}</Text>
            )}
          </Form.Item>

          <Form.Item label="登录地址">
            {editing && <Form.Item
              hidden={!editing}
              name="openLdapUrl"
              noStyle
              rules={[{ required: true, validator: openLdapUrlValidator }]}
            >
              <Input placeholder='登录地址'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.openLdapUrl}</Text>
            )}
          </Form.Item>

          <Form.Item label="搜索范围">
            {editing && <Form.Item
              hidden={!editing}
              name="searchScope"
              noStyle
            >
              <Input placeholder='搜索范围'></Input>
            </Form.Item>}
            {!editing && (
              <Text>{!!casConfig && casConfig.searchScope}</Text>
            )}
          </Form.Item>
          {/* </Form> */}
        </Card>
      </section>
    </section>
  )
}
