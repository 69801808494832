import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AccessRequestState {
  applySearchValue: string
  requestListData: any[]

}

const initialState: AccessRequestState = {
  applySearchValue: '',
  requestListData: []
}
export const AccessRequestSlice = createSlice({
  name: 'accessRequest',
  initialState,
  reducers: {
    setApplySearchValue: (state, action: PayloadAction<string>) => {
      state.applySearchValue = action.payload
    },
    setRequestListData: (state, action: PayloadAction<any[]>) => {
      state.requestListData = action.payload
    },
    setOnDeleteItem: (state, action: PayloadAction<any>) => {
      const record = action.payload
      const newData = state.requestListData?.filter((item: any, index: number) => {
        if (Number(item?.connection?.connectionId) === Number(record?.connectionId)) {
          let childArr = item?.businessDataListNoChild?.filter((val: any) => {
            if (val?.id != record?.id) return val
          })
          item.businessDataListNoChild = childArr
          return item
        }
        return item
      })
      let arr = newData.filter(item => {
        if (item?.businessDataListNoChild.length) {
          return item
        }
      })
      state.requestListData = arr
    },
    setRoleChange: (state, action: PayloadAction<any>) => {
      const {info, item, name} = action.payload
      const newData = state.requestListData?.map((_val: any) => {
        if (Number(item?.connectionId) === Number(_val?.connection?.connectionId)) {
          let childArr = _val?.businessDataListNoChild?.map((_: any) => {
            if (_?.id === item?.id) {
              _[name] = info?.key
              _.valid = item?.valid
              return _
            } else {
              return _
            }
          })
          _val.businessDataListNoChild = childArr
        }
        return _val
      })
      state.requestListData = newData
    }
  },
})

export const accessRequestReducer = AccessRequestSlice.reducer

export const { 
  setApplySearchValue, 
  setRequestListData, 
  setOnDeleteItem, 
  setRoleChange 
} = AccessRequestSlice.actions
