import React from "react";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  DATA_REQUEST_APPLY_TYPES,
  EXECUTE_TYPES,
  APPROVE_COLUMN_FILTER,
} from "src/constants";
import { getColumnStatusWithIcon } from "../common";

import { Iconfont, renderWithOneDatabaseNodeIcon } from "src/components";
import { generateObjectName } from "src/util";

export const columns = [
  {
    title: "编号",
    dataIndex: "id",
    fixed: "left",
    ellipsis: true,
    render: (val: number, record: any) => (
      <Tooltip title={record?.flowUUID}>
        <Link to={`/data_change_work_order_management/${val}/detail`}>
          {record?.flowUUID}
        </Link>
      </Tooltip>
    ),
  },
  {
    title: "申请人",
    dataIndex: "applyUserName",
    ellipsis: true,
    render: (val: string, record: any) => (
      <Tooltip title={`${val ?? ''}（${record?.applyUserId})`}>
        {`${val}（${record?.applyUserId})`}
      </Tooltip>
    ),
  },
  {
    title: "类型",
    dataIndex: "type",
    filters: Object.keys(DATA_REQUEST_APPLY_TYPES).map((key) => ({
      text: DATA_REQUEST_APPLY_TYPES[Number(key)],
      value: key,
    })),
    filterMultiple: false,
    render: (val: number) => DATA_REQUEST_APPLY_TYPES[val],
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "发起时间",
    dataIndex: "applyTime",
    sorter: true,
  },
  {
    title: "当前状态",
    dataIndex: "executeStatus", //方便筛选使用，参数冲突
    filterMultiple: false,
    filters: APPROVE_COLUMN_FILTER.map((key) => ({
      //@ts-ignore
      text: key,
      value: key,
    })),
    render: (val: EXECUTE_TYPES, record: any) =>
      getColumnStatusWithIcon(record?.status),
  },
  {
    title: "数据库",
    dataIndex: "nodePath",
    with: "200px",
    ellipsis: true,
    render: (nodePath: string, record: any) => {
      return (
        <>
          <Tooltip title={record?.dataSourceType}>
            <Iconfont
              type={`icon-${record?.dataSourceType ?? "database"}`}
              style={{ fontSize: "16px", marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip
            title={renderWithOneDatabaseNodeIcon(
              nodePath,
              record?.connectionName,
              record?.dataSourceType
            )}
          >
            {record?.connectionName}.{generateObjectName(nodePath)}
          </Tooltip>
        </>
      );
    },
  },
  {
    title: "当前审批人",
    dataIndex: "approveUserName",
    ellipsis: true,
    render: (val: string) =>
      val ? (
        <Tooltip title={val}>
          {val}
        </Tooltip>
      ) : (
        "-"
      ),
  },
  {
    title: "执行时间",
    dataIndex: "executeTime",
    sorter: true,
    render: (val: number) => val ?? "-",
  },
  {
    title: "操作",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
  },
];
