const baseConfig = {
  modules: [
    '/flow',
    // data-update
    '/data_change_mine_apply',
    '/data_change_mine_approve',
    '/data_change_work_order_management',
    // '/analyze',
    '/analysis-overview',
    '/analysis-execution',
    '/analysis-operation',
    '/connection_management',
    '/data-protection',
    '/create-transfer-task',
    '/transfer-task-record',
    '/transfer-task-record-details',
    '/download',
    '/me',
    '/my-folder',
    '/personalize',
    '/sql-log',
    '/mes-management',
    '/organization',
    // '/organization/post',
    '/organization/userParameter',
    '/organization/structure',
    '/query',
    '/setting',
    '/my-permissions',
    '/system',
    '/system/permission-management',
    '/system/role-management',
    '/system/maskingInternal-management',
    '/logout',
    // '/connection-monitor',
    '/container_monitor',
    '/machine_monitor',
    '/sysmonitor',
    '/sysmonitor/host',
    '/sysmonitor/docker',
    '/dashboard',
    '/alarm',
    '/app-secret',
    '/authorization-management',
    '/personal-center'
  ],
  waterMarkConfig: {
    fontSize: 16,
  },
}

export default baseConfig
