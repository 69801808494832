import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'

export type FilePath = string
interface UserAvatarStste {
 userAvatar: string
}

const initialState: UserAvatarStste = {
  userAvatar: ''
}

export const userAvatarSlice = createSlice({
  name: 'sysUserAcatar',
  initialState,
  reducers: {
    setUserAvatar: (state, action: PayloadAction<string>) => {
      state.userAvatar = action.payload
    },
  },
})

export const userAvatarReducer = userAvatarSlice.reducer

export const {
  setUserAvatar,
} = userAvatarSlice.actions
