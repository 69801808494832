import { AgGridReactProps } from '@ag-grid-community/react'
import { ColDef } from '@ag-grid-community/core'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import locale from './locale'
import { BLOCK_SIZE } from 'src/constants'
import {
  BooleanEditor,
  SelectEditor,
  SelectInputEditor,
  SimpleMarkRenderer,
  SimpleTextRenderer,
  TextEditor,
  ColumnSelectEditor,
  ColumnMultiSelectEditor,
  AntdSelectEditor,
  NumericEditor,
  RowIndexRenderer,
  SimpleTextRendererWithContextMenu,
  RowIndexRendererWithContextMenu,
} from 'src/components'

export const GridConfigBase: AgGridReactProps = {
  localeText: locale,
  rowHeight: 28,
  headerHeight: 32,
  rowSelection: 'multiple',
  tooltipShowDelay: 500,
  enableCellTextSelection: true,
  ensureDomOrder: true,
  frameworkComponents: {
    // renderer
    booleanRenderer: SimpleMarkRenderer,
    simpleTextRenderer: SimpleTextRenderer,
    SimpleTextRendererWithContextMenu,
    RowIndexRenderer,
    RowIndexRendererWithContextMenu,
    // editor
    textEditor: TextEditor,
    selectEditor: SelectEditor,
    selectInputEditor: SelectInputEditor,
    booleanEditor: BooleanEditor,
    columnSelectEditor: ColumnSelectEditor,
    columnMultiSelectEditor: ColumnMultiSelectEditor,
    antdSelectEditor:AntdSelectEditor,
    numericEditor:NumericEditor
  },
  /**
   * If true, then dots in field names (e.g. address.firstline) are not treated as deep references.
   * Allows you to use dots in your field name if you prefer.
   */
  suppressFieldDotNotation: true,
}

export const defaultColDef: ColDef = {
  editable: true,
  resizable: true,
  suppressKeyboardEvent: (params) => {
    const { editing, event } = params
    // 非编辑状态下禁用退格键
    return !editing && event.code === 'Backspace'
  },
  tooltipValueGetter: ({ value }) => value,
  cellClass: ({ colDef }) => {
    if (!colDef?.headerComponentParams){
      return 'aggrid-cell'
    }
    if (colDef?.headerComponentParams.isDesens) {
      return 'aggrid-cell aggrid-cell-isDesens'
    }
    return 'aggrid-cell'
  },
}

export const infiniteModeOptions = {
  modules: [InfiniteRowModelModule],
  editType: 'fullRow',
  rowModelType: 'infinite',
  infiniteInitialRowCount: 1,
  cacheBlockSize: BLOCK_SIZE,
  // maxBlocksInCache: 5, 最大缓存数量 设置会回滚触发请求导致执行行数空白CQ-1297
  blockLoadDebounceMillis: 150,
}

export { BLOCK_SIZE }
