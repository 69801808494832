import { Divider, Form, Input, Select, message } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { UpdateDesensAlgo, getDesensitizeRules } from 'src/api'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { DesensitizationSettingItems } from './DesensitizationSettingItems'
import { resetDesensRuleSettingObj } from '../../DataProtectionPageSlice'

export const CommonEditDesensRulesModal = (): JSX.Element => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { selectedNode, desensRuleSettingObj ={} } = useSelector(
    (state) => state.dataProtection,
  )
  const {refresh, editRecord, isScanResult = false} = desensRuleSettingObj;
  const visible = useSelector((state) => state.modal.CommonEditDesensRulesModal)
  
  const [option, setOption] = useState<string>('')

  const { run: runUpdateRules, loading } = useRequest(UpdateDesensAlgo, {
    manual: true,
    onSuccess: async () => {
      message.success(`设置成功`)
      dispatch(hideModal('CommonEditDesensRulesModal'))
      refresh()
      form.resetFields()
      dispatch(resetDesensRuleSettingObj());
      setOption('')
    },
  })

  // 脱敏算法
  const { data: selectOptions = [], run: getDenseRules } = useRequest(
    getDesensitizeRules,
    {
      manual: true,
      formatResult: (data) =>
        data.map(
          (item: {
            ruleType: string
            ruleParam: object
            values: string[]
            ruleName: string
          }) => {
            return {
              label: item.ruleName,
              value: item.ruleType,
              props: {
                ruleParam: item.ruleParam,
                options: item.values,
              },
            }
          },
        ),
    },
  )

  useEffect(() => {
  
    if (selectedNode && visible && editRecord?.columnType) {
      const { connectionType: datasourceType } = selectedNode.props
      getDenseRules({ columnType: editRecord?.columnType, datasourceType})
    }
    if (editRecord) {

      form.setFieldsValue({...editRecord});
        setOption(editRecord?.ruleName || '')
    }
  }, [getDenseRules, selectedNode, visible, editRecord])

  async function submitForm(values: any) {
    const params = {
      ...values,
      
    }
    if (params?.ruleParam?.dateRange) {
      const { dateRange } = values.ruleParam as { dateRange: moment.Moment[] }

      params.ruleParam.min = +dateRange[0].format('x')
      params.ruleParam.max = +dateRange[1].format('x')
      delete params.ruleParam.dateRange
    }
    if(editRecord) {
      params.id = editRecord.id;
    }
    if (values?.ruleParam) {
      params.algoParam = values?.ruleParam
      delete params.ruleParam
    }

    runUpdateRules(params)
  }

  return (
    <UIModal
      title={isScanResult ? '编辑扫描结果':'编辑脱敏字段'}
      visible={visible}
      onOk={() => {
        form.submit()
      }}
      onCancel={() => {
        dispatch(hideModal('CommonEditDesensRulesModal'))
        setOption('')
        dispatch(resetDesensRuleSettingObj());
      }}
      afterClose={() => form.resetFields()}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={submitForm} {...FormLayout}>
        <Form.Item
          name="ruleName"
          label="脱敏字段来源"
        >
         {editRecord?.source}
        </Form.Item>
        <Form.Item 
          name="matchRule" 
          label="匹配规则"
          hidden={!isScanResult}
        >
         {editRecord?.matchRule}
        </Form.Item>
        <Form.Item
          name="ruleName"
          label="脱敏算法"
          rules={[{ required: true, message: '请选择脱敏规则' }]}
        >
          <Select
            options={selectOptions as any}
            optionFilterProp="label"
            showSearch
            onChange={(v: string) => {
              form.setFieldsValue({ruleName: v})
              setOption(v)
            }}
          ></Select>
        </Form.Item>
        <Divider style={{ fontSize: '10px' }}>脱敏算法设置</Divider>
        {option.length > 0 && (
          <DesensitizationSettingItems
            option={option}
            form={form}
            //@ts-ignore
            values={{
              ...selectOptions?.find((i: any) => i.value === option)?.props,
              ...(!_.isEmpty(editRecord?.algoParam) && editRecord?.ruleName === option  ? {ruleParam: editRecord?.algoParam}: {})
            }}
          />
        )}
      </Form>
    </UIModal>
  )
}
