/**
 * 流程表单 数据库元素字段控件（不包括 FormItem）
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { TreeSelect } from 'antd'
import {
  getFlowConnectionList,
  getFlowConnectionNodes,
  FlowElementTreeNodeEntity,
} from 'src/api'
import { Iconfont } from 'src/components'
import type { FlowType, FormFieldElementEntity } from 'src/types'
import type { LegacyDataNode, DataNode } from 'rc-tree-select/lib/interface'
import styles from '../flowForm.module.scss'

/** 元素节点元信息 */
type ElementNodeProps = Pick<
  FlowElementTreeNodeEntity,
  | 'connectionId'
  | 'connectionType'
  | 'nodeName'
  | 'nodePath'
  | 'nodeType'
  | 'nodePathWithType'
  | 'hasChild'
>

interface ElementTreeSelectProps {
  flowSelectSwitch?: boolean
  flowType: FlowType
  value?: FormFieldElementEntity[]
  disabledEdit?:boolean
  onChange?: (value: FormFieldElementEntity[]) => void
}

export const ElementTreeSelect: React.FC<ElementTreeSelectProps> = React.memo(
  ({ flowSelectSwitch, flowType, disabledEdit = false, value, onChange }) => {
    // tree select 受控属性 expandedKeys & loadedKeys
    const [treeExpandedKeys, setTreeExpandedKeys] = useState<React.Key[]>([])
    const [treeLoadedKeys, setTreeLoadedKeys] = useState<React.Key[]>([])
    // tree map, 记录元素树节点的引用
    const treeMapRef = useRef(new Map<string, LegacyDataNode>())

    // ! 后续后端应该从 cookie 获取用户信息，不从表单传参
    const { userId } = useSelector((state) => state.login.userInfo)

    // tree select 当前 value(受控)
    const treeSelectValue = useMemo(() => {
      if (!value) return
      const checkedValue = value.map(({ nodeName, nodePath }) => ({
        value: nodePath,
        label: nodeName || nodePath,
        halfChecked: false,
      }))
      const nodePathList = checkedValue?.map(({ value }) => value)
      const keySet = new Set<string>()
      nodePathList?.forEach((nodePath) => {
        let keys = nodePath.match(/^\/root\/0\/.+(?=\/)/g)
        while (keys) {
          const [key] = keys
          keySet.add(key)
          keys = key.match(/^\/root\/0\/.+(?=\/)/g)
        }
      })
      const halfCheckedValue = [...keySet].map((key) => ({
        value: key,
        halfChecked: true,
      }))
      return [...checkedValue, ...halfCheckedValue]
    }, [value])

    const mapDataToTreeNode = useCallback((data: FlowElementTreeNodeEntity) => {
      // 从 data 中解构出需要的参数
      const {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      } = data
      const nodeProps: ElementNodeProps = {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      }

      const treeNode = {
        key: nodePath,
        value: nodePath,
        title: nodeName,
        icon: <Iconfont type={`icon-${nodeType}`} />,
        props: nodeProps,
        isLeaf: !hasChild,
      }
      treeMapRef.current.set(nodePath, treeNode)
      return treeNode
    }, [])

    /**
     * 各流程通用的 节点可选状态 逻辑
     * @property validNodeType 第一个选项的 nodeType（流程公共业务逻辑：只能选同类型节点）
     * @property validConnectionId 第一个选项的 connectionId（流程公共业务逻辑：只能选同连接下的节点）
     */
    const commonRule = useMemo(() => {
      const first = value?.[0]
      if (first) {
        return {
          validNodeType: first.nodeType,
          validConnectionId: first.connectionId,
        }
      }
    }, [value])
    const validConnectionId = commonRule?.validConnectionId
    const validNodeType = commonRule?.validNodeType

    /**
     * 根据流程类型的业务逻辑，控制节点的可交互状态
     * @param root mutable
     */
    const handleDisableNode = useCallback(
      (root?: DataNode[]) => {
        root?.forEach((node) => {
          const props = node.props as ElementNodeProps
          const disabled =
            (validConnectionId && validConnectionId !== props.connectionId) ||
            (validNodeType && validNodeType !== props.nodeType)
          node.disableCheckbox = disabled
          handleDisableNode(node.children)
        })
      },
      [validConnectionId, validNodeType],
    )

    // 获取元素树连接层级
    const {
      data: root,
      run: fetchConnections,
      mutate: setRoot,
    } = useRequest(getFlowConnectionList, {
      manual: true,
      formatResult: (data) => {
        // 构造与子节点结构统一的连接节点
        const root = data.map((node) => {
          const {
            connectionId,
            nodeName,
            nodePath,
            nodePathWithType,
            nodeType,
            connectionType,
            hasChild,
          } = node
          const icon = `icon-connection-${connectionType}`
          const nodeProps: ElementNodeProps = {
            connectionId,
            connectionType,
            nodePath,
            nodePathWithType,
            nodeType,
            nodeName,
            hasChild,
          }
          const treeNode = {
            key: nodePath,
            value: nodePath,
            title: nodeName,
            icon: <Iconfont type={icon} />,
            props: nodeProps,
            isLeaf: !hasChild || (!flowSelectSwitch && flowSelectSwitch !== undefined)
          }
          treeMapRef.current.set(nodePath, treeNode)
          return treeNode
        })
        handleDisableNode(root)
        return root
      },
    })
    useEffect(() => {
      if (flowType && userId) {
        fetchConnections({ flowType, userId })
      }
    }, [fetchConnections, flowType, userId])

    // 获取数据库元素节点子列表
    const { run: loadChildren } = useRequest(getFlowConnectionNodes, {
      manual: true,
      fetchKey: (params) => params.nodePath,
      formatResult: (data) => data.map(mapDataToTreeNode),
    })

    useEffect(() => {
      if (!root) return
      setRoot((root) => {
        handleDisableNode(root)
        return [...root]
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleDisableNode, setRoot])

    return (
      <TreeSelect
        disabled={disabledEdit}
        dropdownClassName={styles.treeSelectDropdown}
        treeData={root}
        loadData={async (node) => {
          const key = node.key as string
          const props = node.props as ElementNodeProps
          try {
            const children = await loadChildren({
              ...props,
              flowType,
              userId,
            })
            const targetNode = treeMapRef.current.get(key)
            if (!targetNode) return
            // 遍历并设置 checkbox 状态
            handleDisableNode(children)
            targetNode.children = children
            setRoot((root) => [...root])
            // 懒加载成功，加入 loadedKeys
            setTreeLoadedKeys((keys) => [...keys, key])
          } catch {
            // 懒加载失败，自动收起节点
            setTreeExpandedKeys((keys) => keys.filter((el) => el !== key))
          }
        }}
        value={treeSelectValue}
        onChange={(value) => {
          // 过滤掉 halfChecked
          const checkedValue = value.filter(({ halfChecked }) => !halfChecked)
          // 过滤掉不存在的节点
          const treeMap = treeMapRef.current
          const targetNodes: LegacyDataNode[] = []
          checkedValue.forEach(({ value: nodePath }) => {
            const node = treeMap.get(nodePath)
            if (node) {
              targetNodes.push(node)
            }
          })
          // 映射
          const elementsValue: FormFieldElementEntity[] = targetNodes.map(
            (node) => {
              const props = node.props as ElementNodeProps
              const { hasChild, ...rest } = props
              return rest
            },
          )
          onChange?.(elementsValue)
        }}
        treeExpandedKeys={treeExpandedKeys}
        onTreeExpand={setTreeExpandedKeys}
        treeLoadedKeys={treeLoadedKeys}
        treeCheckable
        treeCheckStrictly
        showSearch
        treeNodeFilterProp="title"
        treeIcon
        maxTagCount={2}
        maxTagTextLength={8}
        allowClear
        placeholder="请选择数据库元素"
        // 为了在回填 元素树选中节点 时，在相关节点没有加载时能给出正确的渲染
        labelInValue={true}
      />
    )
  },
)
