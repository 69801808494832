import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CaretDownOutlined,
  CaretRightOutlined,
  FolderOpenOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import * as _ from 'lodash'
import dayjs from 'dayjs'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, Tag, Tooltip, Typography, Badge } from 'antd'
import {
  EXECUTION_COLUMN_FIELDS,
  DEFAULT_EXECUTE_LOG_COLUMNS,
  SQL_OPERATE_TYPES,
} from 'src/constants'
import {
  getAndSetAuditColumn,
  findAuditExecutionLogs,
  AuditExecutionParams,
  AuditExecutionLogParams,
  DataSourceTypes,
  downloadSqlFile,
  downloadExecuteLog
} from 'src/api'
import { Iconfont } from 'src/components'
import { useSelector, useRequest } from 'src/hook'
import { SearchHeader } from './SearchHeader'
import { ExpandedRowContent } from './ExpandedRowContent'
import { CustomColumnFieldPanel } from './CustomColumnFieldPanel'
import styles from './index.module.scss'
import { handleDownload, renderErrorFlag } from 'src/util'

const transform = (query: AuditExecutionLogParams) =>
  _.omitBy(
    query,
    (value) =>
      value === null ||
      typeof value === 'undefined' ||
      (Array.isArray(value) && value.length === 0),
  )

const getNormalizedType = (uncheckedType: string) => {
  const normalizedType = DataSourceTypes.find(
    (type) => type.toUpperCase() === uncheckedType,
  )
  return normalizedType || 'Other'
}

interface IProps {
  isUnauthorized?: boolean | undefined,
  param?: any | undefined,
}

export const CustomColumnTable = (props: IProps) => {

  const { isUnauthorized, param } = props;
  const history = useHistory()
  const location = useLocation<{ state: { timeRange: any } }>()
  const { userId } = useSelector((state) => state.login.userInfo)

  const defaultTableParams = {
    limit: 10, //条数
    offset: 0, //起始条数
    executors: [],
    timeRange: [
      dayjs().subtract(3, 'day').startOf('d').valueOf(),
      dayjs().endOf('d').valueOf(),
    ],
    resultFlag: undefined,
    actuatorType: 0,
  }

  const [isShowCustomColumnPanel, setIsShowCustomColumnPanel] = useState(false)

  const [tableParams, setTableParams] =
    useState<AuditExecutionLogParams>(defaultTableParams)
  const [columns, setColumns] = useState<string[]>([])

  const { run: getAndSetColumnFields } = useRequest(getAndSetAuditColumn, {
    manual: true,
    onSuccess: (data: AuditExecutionParams) => {
      const res = data?.columns ?? []
      setColumns(res)
      setIsShowCustomColumnPanel(false)
    },
  })

  const {
    data: logs,
    loading,
    refresh,
  } = useRequest(
    () => {
      let defaultParams = transform(tableParams)
      if (!_.isEmpty(tableParams?.dbTypes) && Array.isArray(tableParams?.dbTypes) && tableParams?.dbTypes[0]) {
        defaultParams.dbTypes = tableParams.dbTypes.map((dataSourceType) => {
          return dataSourceType && dataSourceType.toUpperCase()
        }
        )
      }

      if (tableParams?.timeRange) {
        //@ts-ignore
        defaultParams.executeBeginMs = defaultParams.timeRange?.[0]
        //@ts-ignore
        defaultParams.executeEndMs = defaultParams.timeRange?.[1]
        delete defaultParams?.timeRange
      }

      //@ts-ignore
      return findAuditExecutionLogs({
        ...defaultParams,
        //@ts-ignore
        offset: defaultParams?.offset * defaultParams?.limit,
      })
    },
    {
      refreshDeps: [tableParams],
    },
  )

  // history 带过来的参数
  useEffect(() => {
    if (isUnauthorized) {
      if (param) {
        setTableParams({
          ...tableParams,
          ...param,
        })
      }
    } else {
      if (location?.state) {
        setTableParams({
          ...tableParams,
          ...location?.state,
        })
      }
    }
  }, [])

  useEffect(() => {
    userId &&
      getAndSetColumnFields({
        userId,
        type: 'EXECUTE',
      })
  }, [userId])

  const tryDownloadFile = useCallback((path: string) => {
    handleDownload({ href: `/export/download?path=${path}` })
  }, [])


  // 操作结果日志下载
  const handleDownloadResultLog = (checkPermissionId: string, executeSql: string) => {
    const params = {
      id: checkPermissionId,
      fileName: executeSql
    }
    downloadExecuteLog(params).then().catch((err: any)=>{
      console.error('下载操作结果日志失败', err)
    })
    
  }

  const formatColumns = useMemo(() => {
    let currentColumns = DEFAULT_EXECUTE_LOG_COLUMNS
    if (!_.isEmpty(columns)) {
      currentColumns = columns
    }
    return currentColumns.map((key) => {
      let columnItem = {
        key,
        //@ts-ignore
        title: EXECUTION_COLUMN_FIELDS[key],
        dataIndex: key,
        width: 100
      }
      if (key === 'deptName') { // 部门名
        //@ts-ignore
        columnItem.render = (_: any, record: any) => (
          <span style={{ color: '#3262FF', cursor: "pointer" }} onClick={() => {
            setTableParams({
              ...tableParams,
              offset: 0, //起始条数
              depts: [record?.deptName]
            })
          }}>
            {record?.deptName}
          </span>
        )
      }
      if (key === 'executor') { // 用户名
        //@ts-ignore
        columnItem.render = (_: any, record: any) => (
          <span style={{ color: '#3262FF', cursor: "pointer" }} onClick={() => {
            history.push({
              pathname: '/suser_detail',
              state: { userId: record?.executor }
            })
            // setTableParams({
            //   ...tableParams,
            //   offset: 0, //起始条数
            //   executors: [record?.executor]
            // })
          }}>
            {record?.executor}
          </span>
        )
      }
      if (key === 'connectionName') {
        //@ts-ignore
        columnItem.render = (_: any, record: any) => (
          <>
            <Iconfont
              type={
                'icon-connection-' +
                (getNormalizedType(record?.dbType) || 'Other')
              }
              className="mr8"
              style={{ fontSize: 14, opacity: 1 }}
            ></Iconfont>
            <span style={{ color: '#3262FF', cursor: "pointer" }} onClick={() => {
              setTableParams({
                ...tableParams,
                offset: 0, //起始条数
                connectionName: [record?.connectionName]
              })
            }}>
              {record?.connectionName}
            </span>
          </>
        )
      }
      if (key === 'objectName') {
        //@ts-ignore
        columnItem.render = (list: any, record: any) =>
          _.isArray(list) && (
            <Tooltip
              title={list.map((key) => (
                <div key={key}>{key}</div>
              ))}
            >
              <div style={{ fontSize: 12, color: '#3262FF', cursor: "pointer" }} onClick={() => {
                setTableParams({
                  ...tableParams,
                  offset: 0, //起始条数
                  objectNames: list,
                  connectionName: [record?.connectionName]
                })
              }}>{list[0]}</div>

            </Tooltip>
          )
      }
      if (key === 'resultFlag') {
        //@ts-ignore
        columnItem.render = (val: any, { errorMsg, checkPermissionId, executeSql, sqlType }) =>{
          return (
            <>
              {
                val === null ? (
                  <Tooltip
                    placement="topRight"
                    title={errorMsg || '终端执行语句无法获取'}
                  >
                    <InfoCircleOutlined style={{ margin: '0 2px', cursor: 'help' }} />
                  </Tooltip>
                ) : val === 1 ? (
                  <Badge status="success" text="执行成功" />
                ) : (
                  <Badge status="error" text={renderErrorFlag(val)} />
                )
              }
              {
                sqlType==='SCRIPT_FILE' &&
                <FolderOpenOutlined className='options ml4' onClick={()=>handleDownloadResultLog(checkPermissionId, executeSql)} />
              }
            </>
          )
        }
      }
      if (key === 'sqlType') {
        //@ts-ignore
        columnItem.render = (val: string) => {
          return val && <Tag color={SQL_OPERATE_TYPES[val]}>{val}</Tag>
        }
      }
      if (key === 'errorMsg') {
        //@ts-ignore
        columnItem.render = (val: string) =>
          <Tooltip
            title={val}
          >
            <Typography.Text ellipsis={true}
              style={{ width: 200 }}
            >{val}</Typography.Text>
          </Tooltip>

      }
      if (key === 'executeSql') {
        //@ts-ignore
        columnItem.render = (val: string, record: any) => {
          return (
            <Typography.Paragraph style={{ width: 180, fontSize: 12, wordBreak: 'break-all' }} copyable={{ text: val }}>
              {val?.length > 66 ? <Tooltip title={val}>{val.substring(0, 66) + '...'}</Tooltip> : val}
              {
                record?.filePath && 
                  <FolderOpenOutlined
                    onClick={()=> record?.sqlType==='SCRIPT_FILE'
                     ? downloadSqlFile({attachPath: record?.filePath})
                     : tryDownloadFile(record.filePath)
                    }
                    className='options ml4'
                  />
              }
            </Typography.Paragraph>
          )
        }
      }
      if (key === 'inTrans') {
        //@ts-ignore
        columnItem.render = (val: boolean) =>
          (<>{val ? '手动模式' : '自动模式'}</>)
      }
      if (key === 'tabKey') { // 执行窗口ID
        //@ts-ignore
        columnItem.render = (val: string) => (
          <span style={{ color: '#3262FF', cursor: "pointer" }} onClick={() => {
            setTableParams({
              ...tableParams,
              offset: 0, //起始条数
              tabKey: val
            })
          }}>
            {val}
          </span>
        )
      }
      return columnItem
    })
  }, [DEFAULT_EXECUTE_LOG_COLUMNS, columns, tableParams])

  return (
    <>
      <div className={styles.tableHeader}>
        <SearchHeader
          isUnauthorized={isUnauthorized}
          queryParams={tableParams}
          setSearchParams={(values: any) => {
            setTableParams({ ...values })
          }
          }
          refresh={() => refresh()}
          showCustomColumnPanel={() => setIsShowCustomColumnPanel(true)}
        />
      </div>
      <div className={styles.tableWrapper}>
        <Table
          rowKey="id"
          size="middle"
          loading={loading}
          columns={formatColumns}
          dataSource={logs?.data || []}
          indentSize={30}
          scroll={{ x: formatColumns?.length > 7 ? 1740 : 'max-content', y: `calc(100vh - 260px) ` }}
          tableLayout="auto"
          className={styles.customColumnTable}
          pagination={{
            className: styles.pagination,
            showSizeChanger: true,
            pageSize: logs?.limit || 10,
            current: Number(logs?.offset || 0) / (logs?.limit || 10) + 1,
            total: logs?.total || 0,
            showTotal: (total) => `共 ${total || 0} 条`,
            onChange: (page: number, pageSize?: number) => {
              setTableParams({
                ...tableParams,
                offset: page - 1,
                limit: pageSize || 10,
              })
            },
          }}
          expandable={{
            childrenColumnName: `${styles.expandedRowContent}`,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
              ),
            expandedRowRender: (record: any) => (
              <ExpandedRowContent record={record} />
            ),
            rowExpandable: (record) => true,
          }}
        />
      </div>
      <CustomColumnFieldPanel
        defaultColumnFields={
          _.isEmpty(columns) ? DEFAULT_EXECUTE_LOG_COLUMNS : columns
        }
        visible={isShowCustomColumnPanel}
        onCancel={() => {
          setIsShowCustomColumnPanel(false)
        }}
        onOk={(fields: string[]) =>
          userId &&
          getAndSetColumnFields({ columns: fields, userId, type: 'EXECUTE' })
        }
      />
    </>
  )
}
