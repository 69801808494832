import React from 'react'
import { useSelector } from 'src/hook'
import { Form } from 'antd'
import {
  ElementTreeSelect,
  FormItemApplicant,
  FormItemApprover,
  FormItemReason,
  FormItemTimeLimit,
} from '../flowFormItems'
import { FlowModalForm } from './FlowModalForm'
import { launchFlowApply } from 'src/api'
import type {
  FormFieldElementEntity,
  FormFieldsConnectionAccess,
} from 'src/types'
import { useForm } from 'antd/lib/form/Form'

export const ModalFormConnectionAccess = React.memo(() => {

  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const flowType = 'connectionAccess'
  const {flowSelectSwitch} = useSelector(state => state.login.userInfo)

  const [form] = useForm()

  const onResetApprover = () => {
    form && form.setFieldsValue({approveUserId:''})
  }

  return (
    <FlowModalForm<FormFieldsConnectionAccess>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return

        const { approveUserId, applyReason, elements, timeLimit } = values
        const {
          connectionId,
          connectionType: dataSourceType,
          nodeType,
        } = elements?.[0]
        const expiredTimeLimit = timeLimit.preset || timeLimit.custom
        const nodePathList = elements.map(({ nodePath }) => nodePath)
        const nodePathWithTypeList = elements.map(({ nodePathWithType }) => nodePathWithType)
        return launchFlowApply({
          applyUserId,
          email,
          applyReason,
          expiredTimeLimit,
          flowType,
          connectionId,
          dataSourceType,
          nodePathList,
          nodePathWithTypeList,
          nodeType,
          firstApproveUserId: approveUserId,
        })
      }}
    >
      <FormItemApplicant />
      <Form.Item
        label="数据库元素"
        name="elements"
        required
        rules={[
          {
            validator: (_, value?: FormFieldElementEntity[]) => {
              if (!value?.length) {
                return Promise.reject('请选择数据库元素')
              }
              const ruleObj = value[0]
              for (const el of value) {
                if (el.connectionId !== ruleObj.connectionId) {
                  return Promise.reject('只能选择相同连接下的元素')
                }
                if (el.nodeType !== ruleObj.nodeType) {
                  return Promise.reject('只能选择相同类型的元素')
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <ElementTreeSelect 
          flowType={flowType} 
          flowSelectSwitch={flowSelectSwitch}
          onChange={onResetApprover}
         />
      </Form.Item>
      <FormItemApprover />
      <FormItemReason />
      <FormItemTimeLimit />
    </FlowModalForm>
  )
})
