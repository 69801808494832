import { isArray } from "lodash";

/**
 * @description 根据key获取Json中的value
*/
export const getValueByKey = (jsonData: any, key: any) => {
  if(isArray(key)) {
    let res: string[] = []
    for (const dataKey in jsonData) {
      if (Object.prototype.hasOwnProperty.call(jsonData, dataKey)) {
        if (key.includes(dataKey)) {
          res.push(jsonData[dataKey])
        }
      }
    }
    return res.join(",")
  } else {
    for (const dataKey in jsonData) {
      if (Object.prototype.hasOwnProperty.call(jsonData, dataKey)) {
        if (key === dataKey) {
          return jsonData[dataKey]
        }
      }
    }
  }
  return ''
}


/**
 * @description 根据value获取Json中的key
*/
export const getKeyByValue = (jsonData: any, value: any) => {
  if(isArray(value)) {
    let res: string[] = []
    for (const key in jsonData) {
      if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
        if (value.includes(jsonData[key])) {
          res.push(jsonData[key])
        }
      }
    }
    return res
  } else {
    for (const key in jsonData) {
      if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
        if (jsonData[key] === value) {
          return key
        }
      }
    }
    return ''
  }
}