/*
 * @Author: fuzhenghao
 * @Date: 2022-09-21 11:09:52
 * @LastEditTime: 2022-09-24 17:49:24
 * @LastEditors: fuzhenghao
 * @Description: 
 * @FilePath: \cq-enterprise-frontend\src\hook\useTick.tsx
 */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useRequest } from './useRequest'
import { useSelector } from './reactReduxHooks'
import { useTimeoutFn } from 'react-use'
import { throttle } from 'lodash'
import { userLogout, getExpireTimeByMin } from '../api'
import { message } from 'antd'
import { LogoutMessage } from 'src/features/LogoutMessage'
import { ProviderStore } from 'src'
import { useDispatch } from 'src/hook'
import { setIsLoggedIn } from 'src/appPages/login/loginSlice'
import { useHistory} from 'react-router-dom'

// import { persistor } from '../store'

// ! 临时处理，登录失效登出不该由前端控制，主动调用登出接口自身也可能会失败
// ! 后端处理好 session 失效问题后，移除这个 hook
/**
 * 倒计时登出
 */
export const useTick = () => {
  const dispatch = useDispatch()
  const history =  useHistory()
  // 根据接口返回的 session 有效时长，设置登出定时器
  const { data: delay = 30 } = useRequest(getExpireTimeByMin)
  const logout = useCallback(() => {
    userLogout().then(() => {
      // persistor.purge()
      message.destroy()
      message.warn(
        <ProviderStore>
          <LogoutMessage />
        </ProviderStore>,
      )
      dispatch(setIsLoggedIn(false))
      history.push('/login')
    })
  }, [])
  const [, cancelTimer, resetTimer] = useTimeoutFn(logout,  delay * 60 * 1000)

  const { paneInfoMap, tabKeyList, tabInfoMap } = useSelector(
    (state) => state.queryTabs,
  )
  const isExecuting = useMemo(() => {
    for (const tabKey of tabKeyList) {
      if (tabInfoMap[tabKey].paneType !== 'monaco') continue
      if (paneInfoMap[tabKey].pending) {
        return true
      }
    }
    return false
  }, [paneInfoMap, tabInfoMap, tabKeyList])

  useEffect(() => {
    if (!isExecuting) {
      const throttledReset = throttle(resetTimer, 5000, { trailing: false })
      window.addEventListener('keydown', throttledReset)
      window.addEventListener('mousemove', throttledReset)
      window.addEventListener('mousedown', throttledReset)
      return () => {
        window.removeEventListener('keydown', throttledReset)
        window.removeEventListener('mousemove', throttledReset)
        window.removeEventListener('mousedown', throttledReset)
      }
    }
  }, [isExecuting, resetTimer])

  useEffect(() => {
    if (isExecuting) {
      cancelTimer()
      return () => {
        resetTimer()
      }
    }
  }, [cancelTimer, isExecuting, resetTimer])
}
