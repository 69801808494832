import React, { Component } from 'react'
import styles from './index.less'
import { Popconfirm, Button, message } from 'antd'
import { refuseAllFlowTasks } from '../../../../../api/flow'
import { StopOutlined } from '@ant-design/icons'

const stopLocalInstanceProgress = (flowType) => {
  refuseAllFlowTasks(flowType).then(() => {
    message.success('当前实例流程已全部停止')
  })
}

class EditingTools extends Component {
  handleOpen = () => {
    this.file.click()
  }

  render() {
    const {
      onOpenFIle,
      onZoomIn,
      onZoomOut,
      onZoomReset,
      onUndo,
      onRedo,
      onSave,
      onDownloadXml,
      onDownloadSvg,
      onPreview,
      onPreviewXml,
      buttonText,
      selectedProcess,
    } = this.props

    const warningText = `确定清除\n${buttonText}实例\n下正在执行的所有流程吗?(此操作不可逆!)`

    return (
      <div className="editingTools">
        <ul className="controlList">
          {/* <li className={`${styles.control} ${styles.line}`}>
                        <input
                            ref={file => {
                                this.file = file;
                            }}
                            className={styles.openFile}
                            type="file"
                            onChange={onOpenFIle}
                        />
                        <button type="button" title="打开BPMN文件" onClick={this.handleOpen}>
                            <i className={styles.openfile} />
                        </button>
                    </li> */}

          {/* <li className={styles.control}>
                        <button type="button" title="撤销" onClick={onUndo}>
                            <i className={styles.undo} />
                        </button>
                    </li>
                    <li className={`${styles.control} ${styles.line}`}>
                        <button type="button" title="恢复" onClick={onRedo}>
                            <i className={styles.redo} />
                        </button>
                    </li>

                    <li className={styles.control}>
                        <button type="button" title="重置大小" onClick={onZoomReset}>
                            <i className={styles.zoom} />
                        </button>
                    </li>
                    <li className={styles.control}>
                        <button type="button" title="放大" onClick={onZoomIn}>
                            <i className={styles.zoomIn} />
                        </button>
                    </li>
                    <li className={`${styles.control} ${styles.line}`}>
                        <button type="button" title="缩小" onClick={onZoomOut}>
                            <i className={styles.zoomOut} />
                        </button>
                    </li> */}

          <li className={'control'}>
            <button type="button" title="保存流程" onClick={onSave}>
              <i className="save" />
            </button>
          </li>
          <li className={'control'}>
            <button type="button" title="下载BPMN文件" onClick={onDownloadXml}>
              <i className={'download'} />
            </button>
          </li>
          {selectedProcess ? (
            <li className={'control'}>
              <Popconfirm
                placement="topLeft"
                title={warningText}
                onConfirm={() => {
                  stopLocalInstanceProgress(selectedProcess)
                }}
                okText="确定"
                cancelText="取消"
              >
                <button type="button" title="中止当前实例流程">
                  <StopOutlined style={{ fontSize: '17px' }} />
                </button>
              </Popconfirm>
            </li>
          ) : null}
          {/*
                    <li className={styles.control}>
                        <button type="button" title="下载流程图片" onClick={onDownloadSvg}>
                            <i className={styles.image} />
                        </button>
                    </li>
                    <li className={styles.control}>
                        <button type="button" title="预览流程图片" onClick={onPreview}>
                            <i className={styles.preview} />
                        </button>
                    </li> */}
          {/* <li className={styles.control}>
                        <button type="button" title="查看流程xml" onClick={onPreviewXml}>
                            <i className={styles.preview} />
                        </button>
                    </li> */}
        </ul>
      </div>
    )
  }
}

export default EditingTools
