import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Form, Input, message, Radio, TreeSelect, Select, Tooltip, Button, Modal } from 'antd'
import {
  addUser,
  editUser,
  setClose,
  setOpenSms,
  setOpenUserOTPKey,
} from 'src/api'
import { FormLayout } from 'src/constants'

interface IProps {
  userId?: string
  option: any
  loginSetVisible: boolean
  setLoginSetVisible: (b: boolean) => void
  refresh: () => void
  setLoginSettingFuncStr: (s: string) => void
}
export const LoginSetting = (props: IProps) => {

  const { userId, option, loginSetVisible, setLoginSetVisible, refresh, setLoginSettingFuncStr } = props
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        if (userId) {
          switch(values.func) {
            case "smsAuth" || !values.func:
              setOpenSms(userId).then((res) => {
                if (res) {
                  message.success("设置成功")
                  setLoginSetVisible(false)
                  refresh()
                } else {
                  message.success("设置失败")
                }
              })
              break
            case "otpAuth":
              setOpenUserOTPKey(userId).then((res) => {
                if (res) {
                  message.success("设置成功")
                  setLoginSetVisible(false)
                  refresh()
                } else {
                  message.success("设置失败")
                }
              })
              break
            case "closeAuth":
              setClose(userId).then((res) => {
                if (res) {
                  message.success("设置成功")
                  setLoginSetVisible(false)
                  refresh()
                  setLoginSettingFuncStr("")
                } else {
                  message.success("设置失败")
                }
              })
              break
          }
        }
      })
      .catch(errorInfo => {
        console.log('登录方式-表单校验失败:', errorInfo);
      });
  };
  
  return (
    <Modal
      visible={loginSetVisible}
      title={"登录设置"}
      onCancel={() => setLoginSetVisible(false) }
      onOk={handleOk}
    >
      <Form form={form}>
        <Form.Item
          name="func" 
          label="双因素认证方式" 
          rules={[
            {
              required: true,
            },
          ]}>
        <Select
          // defaultValue="smsAuth"
          style={{ width: 120 }}
          options={option}
        />
        </Form.Item>
      </Form>
      <div>登入时需要通过设置的双因素方式进行认证</div>
    </Modal>
  );
}
