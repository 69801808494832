import React, { useEffect, useState } from 'react'
import { useModal, useRequest, useDispatch, useSelector } from 'src/hook'
import { getUserPermission_api, removeUser } from 'src/api'
import { UIModal, LinkButton } from 'src/components'
import { Table, Button, Popconfirm, message } from 'antd'
import { CopyUserModal } from './CopyUserModal'
import { fetchDeptUserList } from '../organizationSlice'
import {Tooltip} from "antd";
const columns = [
  { dataIndex: 'permissionName', title: '权限名称',
    ellipsis:true,
    render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容
  },
  // { dataIndex: 'type', title: '权限类型', ellipsis: true,
  //   render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容

  // },
  { dataIndex: 'description', title: '描述', ellipsis: true,
    render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容
  },
  { dataIndex: 'connection', title: '连接', ellipsis: true,
    render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容
  },
  { dataIndex: 'schema', title: '库', ellipsis: true,
    render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容
  },
  { dataIndex: 'table', title: '表', ellipsis: true,
    render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容
  },
  // { dataIndex: 'roleName', title: '角色名称', ellipsis: true,
  //   render: (text:string) => <Tooltip title={text}>{text}</Tooltip>, // 鼠标悬停时显示完整文本内容
  // },
]

export const UserPermDetaiModal = ({ record }: any) => {
  const dispatch = useDispatch()
  const { visible, closeModal } = useModal('UserPermDetaiModal')
  const { data, run } = useRequest(getUserPermission_api, {
    manual: true,
    formatResult: (data) => {
      const permissions = data.permissions
      const canCopyOtherRole = data.canCopyOtherRole
      const canRemove = data.canRemove
      const canCopied = data.canCopied
      return { permissions, canCopyOtherRole, canRemove, canCopied }
    },
  })
  const [copytRecord, setCopytRecord] = useState({}) as any
  const dept = useSelector((state) => state.organization.selectedNode)
  const { organizationPageSize, organizationSearch } = useSelector(
    (state) => state.organization,
  )

  useEffect(() => {
    if (record.userId) {
      run({ userId: record.userId })
    }
  }, [record.userId, run])
  return (
    <UIModal
      title="用户权限详情"
      visible={visible}
      onCancel={closeModal}
      footer={[
        <>
          {data?.canRemove && (
            <Popconfirm
              title="确认移除该用户权限?"
              onConfirm={() => {
                removeUser(record.userId)
                  .then(() => {
                    message.success('移除用户权限成功')
                    run({ userId: record.userId })
                    if (dept) {
                      dispatch(
                        fetchDeptUserList({
                          dept,
                          search: organizationSearch,
                          pageNum: 0,
                          pageSize: organizationPageSize,
                        }),
                      )
                    }
                  })
                  .catch((error) => {
                    console.error(error)
                    // message.error('移除用户权限失败')
                  })
              }}
            >
              <LinkButton danger>移除</LinkButton>
            </Popconfirm>
          )}

          <Button key="back" type="primary" onClick={closeModal}>
            关闭
          </Button>
        </>,
      ]}
    >
      <Table
        dataSource={data?.permissions}
        columns={columns}
        size="small"
        pagination={{ hideOnSinglePage: true }}
      ></Table>
      <CopyUserModal record={copytRecord} refreshPermission={run} />
    </UIModal>
  )
}
