import React, { useEffect } from "react";
import { Modal, Descriptions } from "antd";
import { useDispatch, useRequest, useSelector } from "src/hook";
import { hideModal } from "src/store/extraSlice/modalVisibleSlice";
import { getVersion, getVersionIsCommunity } from "src/api";

export const UpdateVersion = () => {
  const { UpdateVersion: visible } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const { data, run } = useRequest(getVersion, { manual: true });
  const { data: isCommunity, run: fetchVersion } = useRequest(
    getVersionIsCommunity,
    {
      manual: true,
    }
  );
  useEffect(() => {
    if (visible) {
      run();
      fetchVersion();
    }
  }, [visible]);

  return (
    <Modal
      title="版本信息"
      visible={visible}
      onCancel={() => dispatch(hideModal("UpdateVersion"))}
      footer={null}
    >
      <Descriptions layout="horizontal" column={1}>
        <Descriptions.Item label="版本号">
          {data?.versionNumber}
        </Descriptions.Item>
        <Descriptions.Item label="核心服务版本">
          {data?.serviceVersion?.join("，")}
        </Descriptions.Item>
        <Descriptions.Item label="发布时间">
          {data?.packageTime}
        </Descriptions.Item>
        {isCommunity?.version === "community" && (
          <Descriptions.Item label="版权声明">
            CloudQuery询盾 ©2024杭州图尔兹信息技术有限公司版权所有
          </Descriptions.Item>
        )}
      </Descriptions>
    </Modal>
  );
};
