import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Tree } from 'antd'
import { DataNode } from 'antd/lib/tree'
import isArray from 'lodash/isArray'
import styles from './index.module.scss'
import classNames from 'classnames'

/** 可展开节点的 title */
const BranchNode: React.FC<{
  field: React.ReactNode
  type?: string
  length?: number
}> = ({ field, type, length }) => {
  return (
    <>
      <span className={styles.field}>{field}</span>
      {type === 'object' && (
        <span className={styles.count}>{`{${length || ''}}`}</span>
      )}
      {type === 'array' && (
        <span className={styles.count}>{`[${length || ''}]`}</span>
      )}
    </>
  )
}

/** 叶子节点的 title */
const LeafNode: React.FC<{
  field: React.ReactNode
  value: string | number | boolean | null
  type?: string
}> = ({ field, value, type }) => {
  return (
    <>
      <span className={classNames(styles.field, styles.colon)}>{field}</span>
      <span className={type && styles[type]}>
        {typeof value === 'string' ? `"${value}"` : `${value}`}
      </span>
    </>
  )
}

const getMetaType = (meta: any) => {
  let type: string | undefined = typeof meta
  if (meta === null) {
    type = 'null'
  }
  if (isArray(meta)) {
    type = 'array'
  }
  return type
}

type JsonNodeMeta =
  | string
  | number
  | boolean
  | null
  | object
  | JsonNodeMeta[]
  | undefined

interface JsonTreeNode extends DataNode {
  meta?: JsonNodeMeta
}

export const JsonTree: React.FC<{
  dataSource?: any[]
  height?: number
}> = React.memo(({ dataSource, height }) => {
  const nodeMapRef = useRef<Map<string, any>>(new Map())
  const [jsonRoots, setJsonRoots] = useState<JsonTreeNode[]>()
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>()

  useEffect(() => {
    const nodeMap = nodeMapRef.current
    const nextState = dataSource?.map((json, index) => {
      /** 节点元信息 */
      let meta
      try {
        meta = JSON.parse(json)
      } catch {}
      /** 节点元信息类型 */
      const type = getMetaType(meta)
      /** 是否是叶子节点 */
      const isLeaf = !['object', 'array'].includes(type)
      /** object | array 类型节点的字段数 */
      let length = 0

      if (!isLeaf) {
        length = Object.keys(meta as object).length
      }

      const key = `0-${index}`
      const docTitle = <span className={styles.root}>JSON</span>
      const docNode: JsonTreeNode = {
        key,
        title: isLeaf ? (
          <LeafNode field={docTitle} value={meta} type={type} />
        ) : (
          <BranchNode field={docTitle} type={type} length={length} />
        ),
        meta,
        isLeaf,
      }
      nodeMap.set(key, docNode)
      return docNode
    })
    setJsonRoots(nextState)
  }, [dataSource])

  useEffect(() => {
    setExpandedKeys(dataSource?.map((_, index) => `0-${index}`))
  }, [dataSource])

  const handleLoadData: (treeNode: JsonTreeNode) => Promise<void> = useCallback(
    ({ key, meta }) => {
      const nodeMap = nodeMapRef.current
      const target = nodeMap.get(key as string)
      // 健壮性处理
      if (!target) return Promise.resolve()
      if (typeof meta !== 'object') return Promise.resolve()
      if (meta === null) return Promise.resolve()

      // 构造子节点
      const children = Object.entries(meta as object).map(
        ([field, value], index) => {
          const type = getMetaType(value)
          const isLeaf = !['object', 'array'].includes(type)
          let length = 0
          if (!isLeaf) {
            length = Object.keys(value as object).length
          }
          const itemKey = `${key}-${index}`
          const node = {
            key: itemKey,
            title: isLeaf ? (
              <LeafNode field={field} value={value} type={type} />
            ) : (
              <BranchNode field={field} length={length} type={type} />
            ),
            meta: value,
            isLeaf,
          }
          nodeMap.set(itemKey, node)
          return node
        },
      )
      target.children = children
      setJsonRoots((state) => {
        if (!state) return state
        return [...state]
      })
      return Promise.resolve()
    },
    [],
  )

  return (
    <Tree
      className={styles.jsonTree}
      treeData={jsonRoots}
      loadData={handleLoadData}
      height={height}
      selectable={false}
      expandedKeys={expandedKeys}
      onExpand={setExpandedKeys}
    />
  )
})
