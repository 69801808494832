import { Divider, Form, Input, Select, message } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { addResourcesRule, getResourcesAllRule } from 'src/api'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { nameValidator } from 'src/util'
import { DesensitizationSettingItems } from './DesensitizationSettingItems'

export const DesensRulesModalPage = ({
  editRecord,
  refresh,
  clearEditRecord,
}: {
  editRecord?: any;
  refresh: () => Promise<(() => Promise<any>)[]>
  clearEditRecord: () => void;
}): JSX.Element => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { selectedNode } = useSelector(
    (state) => state.dataProtection,
  )
  const visible = useSelector((state) => state.modal.DesensRulesModal)
  
  const [option, setOption] = useState<string>('')

  const { run: addRulesRun, loading } = useRequest(addResourcesRule, {
    manual: true,
    onSuccess: async () => {
      message.success(`设置成功`)
      dispatch(hideModal('DesensRulesModal'))
      refresh()
      form.resetFields()
      clearEditRecord()
      setOption('')
    },
  })

  // 脱敏算法
  const { data: selectOptions, run: getDenseRules } = useRequest(
    getResourcesAllRule,
    {
      manual: true,
      formatResult: (data) =>
        data.map(
          (item: {
            ruleType: string
            ruleParam: object
            values: string[]
            ruleName: string
          }) => {
            return {
              label: item.ruleName,
              value: item.ruleType,
              props: {
                ruleParam: item.ruleParam,
                options: item.values,
              },
            }
          },
        ),
    },
  )

  useEffect(() => {
    if (selectedNode && visible) {
      getDenseRules()
    }
    if (editRecord) {

      form.setFieldsValue({...editRecord});
        setOption(editRecord?.algoName || '')
    }
  }, [getDenseRules, selectedNode, visible, editRecord])

  async function submitForm(values: any) {
    const params = {
      ...values,
      
    }
    if (params?.ruleParam?.dateRange) {
      const { dateRange } = values.ruleParam as { dateRange: moment.Moment[] }

      params.ruleParam.min = +dateRange[0].format('x')
      params.ruleParam.max = +dateRange[1].format('x')
      delete params.ruleParam.dateRange
    }
    if(editRecord) {
      params.id = editRecord.id;
    }
    if (values?.ruleParam) {
      params.algoParam = values?.ruleParam
      delete params.ruleParam
    }
    addRulesRun(params)
  }
  return (
    <UIModal
      title={'设置脱敏算法'}
      visible={visible}
      onOk={() => {
        form.submit()
        setOption("");
      }}
      onCancel={() => {
        dispatch(hideModal('DesensRulesModal'))
        setOption('')
        clearEditRecord()
      }}
      afterClose={() => form.resetFields()}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={submitForm} {...FormLayout}>
        <Form.Item
          name="ruleName"
          label="规则名"
          rules={[
            { required: true, message: '请输入规则名' },
            { validator: nameValidator },
          ]}
        >
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item name="description" label="规则描述">
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} maxLength={200} />
        </Form.Item>
        <Form.Item 
          name="resource" 
          label="匹配规则"
          rules={[
            { required: true, message: '请输入匹配规则' },
          ]}
        >
          <Input placeholder='请输入正则表达式'/>
        </Form.Item>
        <Form.Item
          name="algoName"
          label="脱敏算法"
          rules={[{ required: true, message: '请选择脱敏规则' }]}
        >
          <Select
            options={selectOptions as any}
            optionFilterProp="label"
            showSearch
            onChange={(v: string) => {
              form.setFieldsValue({algoName: v})
              setOption(v)
            }}
          ></Select>
        </Form.Item>
        <Divider style={{ fontSize: '10px' }}>脱敏算法设置</Divider>
        {option.length > 0 && (
          <DesensitizationSettingItems
            option={option}
            form={form}
            values={{
              ...selectOptions?.find((i: any) => i.value === option)?.props,
              ...(!_.isEmpty(editRecord?.algoParam) && editRecord?.algoName === option  ? {ruleParam: editRecord?.algoParam}: {})
            }}
          />
        )}
      </Form>
    </UIModal>
  )
}
