import React, { useEffect, useState, useMemo } from "react";
import { Chart } from '@antv/g2';
import { Button, Spin } from 'antd';
import chartStyles from './chart.module.scss'



interface IProps {
  data: any[],
  unit: string,
  setUnit: (a: any) => void,
  loading: any,
}

const ActiveUserChart = (props: IProps) => {

  const { data, unit, setUnit, loading } = props;

  const chartRender=(container: any, chartData: any)=>{
    // const data = [
    //   { type: '在线', value: 68 }
    // ];
    const chart = new Chart({
      container: container,
      autoFit: true,
    });
    chart.data(data);
    chart.legend(false);
    chart.tooltip({
      showMarkers: false
    });
    
    chart.facet('rect', {
      fields: ['type'],
      // padding: 20,
      showTitle: false,
      eachView: (view, facet) => {
        const data: any = facet?.data;
        let color:any;
        if(data) {
          if (data[0].type === '在线') {
            color = '#13C2C2';
          }
          data.push({ type: '离线', value: 100 - data[0].value });
          view.data(data);
          view.coordinate('theta', {
            radius: 0.8,
            innerRadius: 0.72
          });
          view
            .interval()
            .adjust('stack')
            .position('value')
            .color('type', [color, '#eceef1'])
            .label("type", (val)=>{
              return {
                content: (obj) => {
                  return obj.type+" : "+obj.value+"%"
                },
                // offset: 20,
                layout: {
                  type: 'fixed-overlap', 
                  // type:'overlap'
                },
              }
            })
            .style({
              opacity: 1,
            });
          view.interaction('element-active');
        }
      }
    });
    chart.render();
    return chart;
  }

  useEffect(() => {
    const chart = chartRender("activeUserContainer", data);
    return () => chart.destroy();
  },[data]);

  // 当日/当月
  const changeUnit = (unit: string)=> {
    setUnit(unit);
  }


  const renderBotton = useMemo(() => {
    return (
      <span
        style={{ paddingBottom: 0, marginBottom: 0, position: "absolute", right: 0, top: -55 }}>
        <Button
        className={chartStyles.bottonStyle}
        onClick={() => changeUnit("DAY")} size='small'
        style={{
          color: (unit==="DAY"? "#0256FF" : "#454458")
        }}
        >
          当日
        </Button>
        <Button
        className={chartStyles.bottonStyle}
        onClick={() => changeUnit("MONTH")} size='small'
        style={{
          color: (unit==="MONTH"? "#0256FF" : "#454458")
        }}
        >当月</Button>
      </span>
    )
  }, [unit])

return (
  <div className={chartStyles.activeUserWrapper}>
    {renderBotton}
    <Spin spinning={loading}>
    <div id="activeUserContainer" className={chartStyles.activeUser}></div>
    </Spin>
  </div>
)
};
export default ActiveUserChart;

