import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Menu,
  Space,
  Tooltip,
  Popover,
  Typography,
  message,
  Progress,
} from 'antd'
import { CheckOutlined, DownOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { Iconfont } from 'src/components'
import {
  explainEditorSql,
  // getTxMode,
  PaneInfo,
  setPrepared,
  setTabExecutionStatus,
  updatePaneInfo,
} from '../../queryTabsSlice'

import styles from './index.module.scss'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import {
  // setTxModeOfConnection,
  getExecutedSql,
  commitTransaction,
  rollbackTransaction,
  getConnectionConfig,
  cancelExecute,
} from 'src/api'
import { getSelectedText, getSqlToExecute } from 'src/util'
import { TxModeType } from 'src/constants'
import { ModalShowMoreCommit } from './ModalShowMoreCommit'
import { setLastErrorPosition } from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import { SqlFlashBackModal } from './SqlFlashBackModal'

interface MonacoToolbarExecutionsProps {
  paneInfo: PaneInfo
  onPressExecute: (flashbackSql?: string) => void
  onPreCommit?: () => void
  supportTransaction?: boolean
}

/**
 * sql related controllers in editor toolbar
 */
export const MonacoToolbarExecutions = React.memo(
  ({ onPressExecute, onPreCommit, paneInfo, supportTransaction }: MonacoToolbarExecutionsProps) => {
    const dispatch = useDispatch()
    const executeActiveTabInfo = useSelector((state) => state.queryTabs.executeActiveTabInfo)

    const {
      key: tabKey,
      connectionId,
      connectionType,
      plSql,
      pending: executionPending,
      tSql: isTSQL,
      value,
      txMode,
      menuType,
    } = paneInfo
    // !HACK 需要做数据源特性统一抽象，否则将无法迭代下去
    // 获取事务等接口目标 database
    const databaseName = useMemo(() => {
      const { databaseName } = paneInfo
      // if (['DamengDB', 'Vertica', 'DB2'].includes(connectionType!)) {
      //   return schemaName
      // }
      // 都需要传databaseName
      return databaseName
    }, [paneInfo])

    const canExecute = value

    const dataSourceList = useSelector(
      (state) => state.dataSource.dataSourceList,
    )
    const dataSourceImplementedExplain = useMemo(
      () =>
        dataSourceList
          .filter(({ editorToolbarButtons }) =>
            editorToolbarButtons.includes('EXPLANATION'),
          )
          .map(({ dataSourceName }) => dataSourceName),
      [dataSourceList],
    )
    const noSqlDataSource = useMemo(
      () =>
        dataSourceList
          .filter(({ dataSourceType }) => dataSourceType === 'NoSQL')
          .map(({ dataSourceName }) => dataSourceName),
      [dataSourceList],
    )

    // 可以执行计划的数据源
    const supportExplanation =
      connectionType && dataSourceImplementedExplain.includes(connectionType)
    // NoSQL 类型的数据源
    const isNoSQL = connectionType && noSqlDataSource.includes(connectionType)

    const [editorInstance] = useEditorInstance()


    // set tx mode
    // const { run: setTxMode } = useRequest(setTxModeOfConnection, {
    //   manual: true,
    //   onSuccess: (_, [params]) => {
    //     const { connectionId, tabKey, databaseName } = params
    //     // TODO:
    //     dispatch(getTxMode({ connectionId, tabKey, databaseName }))
    //   },
    // })
    const setTxMode = useCallback(
      ({ tabKey, txMode, ...rest }) => {
        dispatch(updatePaneInfo({ key: tabKey, paneInfo: { txMode } }))
      },
      [dispatch],
    )

    // get connection tx mode config
    const { data: allowAutoCommit, run: getTxModeConfig } = useRequest(
      getConnectionConfig,
      {
        manual: true,
        formatResult: ({ variable_value }) => {
          try {
            return Boolean(JSON.parse(variable_value))
            // return true
          } catch { }
        },
        onSuccess: (allowAutoCommit) => {
          // CQ-4392 右键触发函数调用时，为手动模式
          let mode = 'manual' as 'auto' | 'manual'

          if (menuType !== 'call') {
            mode = allowAutoCommit ? 'auto' : 'manual'
          }
          dispatch(
            updatePaneInfo({
              key: tabKey,
              paneInfo: { txMode: mode },
            }),
          )
          dispatch(setPrepared({ key: tabKey, prepared: true }))
        }
      },
    )

    // fetch tx mode TODO:
    // useEffect(() => {
    //   if (connectionId && !isNoSQL) {
    //     // dispatch(getTxMode({ connectionId, tabKey, databaseName }))
    //     dispatch(
    //       updatePaneInfo({
    //         key: tabKey,
    //         paneInfo: { txMode: allowAutoCommit ? 'auto' : 'manual' },
    //       }),
    //     )
    //   }
    // }, [
    //   connectionId,
    //   dispatch,
    //   isNoSQL,
    //   tabKey,
    //   // databaseName,
    //   allowAutoCommit,
    // ])

    useEffect(() => {
      if (!connectionId || !tabKey) return
      // 不支持事物时，执行中的默认值为true，自动提交。
      // http://share.bintools.cn:8080/pages/viewpage.action?pageId=2176650
      if (!supportTransaction) {
        dispatch(
          updatePaneInfo({
            key: tabKey,
            paneInfo: { txMode: 'auto' },
          }),
        )
        return
      }
      if (!isNoSQL) {
        getTxModeConfig({ connectionId, variable: 'allowAutoCommit' })
      }

    }, [connectionId, getTxModeConfig, tabKey, isNoSQL, supportTransaction, dispatch])

    // get commit list
    const {
      data: commitList,
      run: getCommitList,
      refresh: refreshCommitList,
    } = useRequest(getExecutedSql, {
      manual: true,
      formatResult: (data) => {
        return data?.slice().reverse()
      },
    })
    useEffect(() => {
      if (
        tabKey &&
        connectionId &&
        !executionPending &&
        txMode === 'manual' &&
        !isNoSQL
      ) {
        getCommitList({ tabKey, connectionId, databaseName })
      }
    }, [
      connectionId,
      getCommitList,
      tabKey,
      executionPending,
      isNoSQL,
      txMode,
      databaseName,
    ])

    const disableCommitAndRollback = txMode === 'auto' || !commitList?.length

    // commit transaction
    const { run: commit, loading: committing } = useRequest(commitTransaction, {
      manual: true,
      fetchKey: ({ tabKey }) => tabKey,
      onSuccess: () => refreshCommitList(),
    })

    // rollback transaction
    const { run: rollback, loading: rollbacking } = useRequest(
      rollbackTransaction,
      {
        manual: true,
        fetchKey: ({ tabKey }) => tabKey,
        onSuccess: () => refreshCommitList(),
      },
    )

    // cancel execution
    const { run: cancel, loading: cancelling } = useRequest(cancelExecute, {
      manual: true,
      onSuccess: () => {
        if (!tabKey) return
        dispatch(setTabExecutionStatus({ key: tabKey, pending: false }))
      },
    })

    // show more commit
    const [modalVisible, setModalVisible] = useState(false)
    const handleCloseModal = useCallback(() => {
      setModalVisible(false)
    }, [])

    const noSupportTransition =
      connectionType === 'Impala' || connectionType === 'Hive' || connectionType === 'Inceptor'

    const { tabExecutionStatusPercentageMap } = useSelector(state => state.queryTabs)
    let tabExecutionStatusPercentage = null
    if (tabExecutionStatusPercentageMap != null) {
      tabExecutionStatusPercentage = tabExecutionStatusPercentageMap[tabKey]
    }
    if (tabExecutionStatusPercentage == null) {
      tabExecutionStatusPercentage = { tabKey: tabKey, executePercentage: 0, executeStatusMessage: '未执行' }
    }

    const executePercentage = tabExecutionStatusPercentage.executePercentage
    const executeStatusMessage = tabExecutionStatusPercentage.executeStatusMessage

    const lastErrorPosition = useSelector((state) => state.resultTabs.lastErrorPosition)
    return (
      <Col>
        <Space size={1}>
          {isTSQL && (
            <Tooltip title="预检" placement="bottomLeft" arrowPointAtCenter>
              <Iconfont
                type="icon-yujian"
                className={classNames(
                  styles.monacoToolbarIcon,
                  executionPending && styles.iconActive,
                  !canExecute && styles.iconDisabled,
                )}
                onClick={onPreCommit}
              />
            </Tooltip>
          )}

          <Tooltip
            title={
              editorInstance && getSelectedText(editorInstance)
                ? '执行选中语句'
                : '执行'
            }
            placement="bottomLeft"
            arrowPointAtCenter
          >
            <Iconfont
              className={classNames(
                styles.monacoToolbarIcon,
                styles.iconExecute,
                executionPending && styles.iconActive,
                !canExecute && styles.iconDisabled,
              )}
              type="icon-run"
              onClick={() => {
                if (executionPending) return
                onPressExecute()
                //执行语句之前 去除对应窗口 历史错误SQL语句的位置
                if (tabKey) {
                  if (tabKey in lastErrorPosition && !!lastErrorPosition[tabKey]) {
                    let tmp = { ...lastErrorPosition }
                    tmp[tabKey] = {
                      'endColumn': 0,
                      'endLineNumber': 0,
                      'startColumn': 0,
                      'startLineNumber': 0
                    }
                    dispatch(
                      setLastErrorPosition(tmp)
                    )
                  }
                }
              }}
            />
          </Tooltip>
          <Tooltip title="终止" placement="bottomLeft" arrowPointAtCenter>
            <Iconfont
              type="icon-cancel"
              className={classNames(
                styles.monacoToolbarIcon,
                styles.iconCancel,
                !executionPending && styles.iconDisabled,
                cancelling && styles.iconActive,
              )}
              onClick={() => {
                if (tabKey && connectionId) {
                  if (executeActiveTabInfo && executeActiveTabInfo[tabKey]?.messageId) {
                    let messageId = executeActiveTabInfo[tabKey].messageId
                    cancel({ tabKey, connectionId, messageId, databaseName })
                  }
                }
              }}
            />
          </Tooltip>
          {supportExplanation && !plSql && (
            <Tooltip title="执行计划" placement="bottomLeft" arrowPointAtCenter>
              <Iconfont
                className={classNames(
                  styles.monacoToolbarIcon,
                  !canExecute && styles.iconDisabled,
                )}
                type="icon-explain"
                onClick={() => {
                  if (!editorInstance) return
                  dispatch(explainEditorSql(getSqlToExecute(editorInstance)))
                }}
              />
            </Tooltip>
          )}
          {(!isNoSQL && supportTransaction) && (
            <Popover
              title={<Typography.Text strong>待处理语句</Typography.Text>}
              content={
                <>
                  <Space direction="vertical">
                    {commitList?.slice(0, 5)?.map((statement, index) => (
                      <Typography.Text
                        key={index}
                        ellipsis
                        style={{ maxWidth: 480 }}
                      >
                        {statement}
                      </Typography.Text>
                    ))}
                  </Space>

                  {commitList?.length && commitList.length > 5 ? (
                    <>
                      <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                      <Typography.Link
                        onClick={() => setModalVisible(true)}
                      >{`查看更多（${commitList.length - 5
                        }条）`}</Typography.Link>
                    </>
                  ) : null}
                </>
              }
              arrowPointAtCenter
              placement="bottomLeft"
            >
              <Space
                size={1}
                className={classNames(
                  styles.commitAndRollbackGroup,
                  disableCommitAndRollback && styles.commitDisabled,
                )}
              >
                <CheckOutlined
                  className={classNames(
                    styles.monacoToolbarIcon,
                    styles.iconExecute,
                    disableCommitAndRollback && styles.iconDisabled,
                    committing && styles.iconActive,
                  )}
                  onClick={() => {
                    if (tabKey && connectionId) {
                      commit({ tabKey, connectionId, databaseName })
                    }
                  }}
                />

                <Iconfont
                  type="icon-rollback"
                  className={classNames(
                    styles.monacoToolbarIcon,
                    styles.iconCancel,
                    disableCommitAndRollback && styles.iconDisabled,
                    rollbacking && styles.iconActive,
                  )}
                  onClick={() => {
                    if (tabKey && connectionId) {
                      rollback({ tabKey, connectionId, databaseName })
                      //临时解决方案
                      //@ts-ignore
                      // global.handleRefresh && global.handleRefresh()
                    }
                  }}
                />
              </Space>
            </Popover>
          )}
          <SqlFlashBackModal
            connectionId={connectionId}
            isPending={executionPending}
            tabKey={tabKey}
            visible
            executeFlashbackSql={(flashbackSql) => onPressExecute(flashbackSql)}
          />
          {(!isNoSQL && supportTransaction) && (
            <Dropdown
              overlay={
                <Menu
                  onClick={({ key }) => {
                    if (!tabKey || !connectionId) return

                    // 手动切自动也需要校验是否存在未提交的语句
                    // http://share.bintools.cn:8080/pages/viewpage.action?pageId=2176650
                    if (
                      commitList &&
                      commitList?.length !== 0 &&
                      key === 'auto'
                    ) {
                      message.error('请先提交或回滚当前事务')
                      return
                    }

                    const txMode = key as TxModeType
                    setTxMode({ tabKey, connectionId, txMode, databaseName })
                  }}
                >
                  <Menu.Item key="auto" disabled={!allowAutoCommit}>
                    自动
                  </Menu.Item>
                  <Menu.Item key="manual" disabled={noSupportTransition}>
                    手动
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                className={classNames(
                  styles.monacoToolbarIcon,
                  styles.txDropdownTrigger,
                  {
                    [styles.iconDisabled]: !connectionId || executionPending,
                  },
                )}
                type="text"
                size="small"
              >
                <>
                  {txMode
                    ? `Tx: ${txMode === 'auto' ? '自动' : '手动'}`
                    : 'Transaction'}
                  <DownOutlined style={{ marginLeft: 2 }} />
                </>
              </Button>
            </Dropdown>
          )}

          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <div style={{
                  marginTop: '15px', // 调整进度条向下移动的距离
                }}>
                  <Tooltip title={`进度：${executePercentage}%`}>
                    <Progress
                      percent={executePercentage}
                      status="active"
                      style={{
                        background: 'linear-gradient(90deg, #1890ff ' + executePercentage + '%, transparent 0%)',
                        height: '8px',
                        borderRadius: '4px',
                        border: '1px solid #1890ff',
                        width: '100%',
                      }}
                      // 自定义格式化函数，不显示进度数字
                      format={() => null}
                    />
                  </Tooltip>
                </div>
              </div>
              <div style={{ marginLeft: 10 }}>
                {executeStatusMessage}
              </div>
            </div>
          </div>

        </Space>
        <ModalShowMoreCommit
          visible={modalVisible}
          commitList={commitList}
          closeModal={handleCloseModal}
        />
      </Col>
    )
  },
)
