/**
 * 连接概览
 */
import React, { useEffect } from 'react'
import { Card, Row, Col, Spin, Tooltip, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { useRequest, useDispatch } from 'src/hook'
import { setIsTabEditConnection } from 'src/pageTabs/connectionManagementPage/connectionManagementPageSlice'
import {
  queryDBTableCount,
  queryConnectionCount,
  querySQLCount,
} from 'src/api'
import { Iconfont } from 'src/components'
import classnames from 'classnames'
import styles from './index.module.scss'

interface IProps {
  setCurTabs: (p: string) => void
  handleShowContentChange: (to: string, form?: string) => void
  [p: string]: any
}
const ConnectionOverview = (props: IProps) => {
  const history = useHistory()
  const {
    connectionId,
    connectionName,
    dataSourceType,
    connectionPool,
    connectionPoolLoading,
    setCurTabs,
    handleEditContent,
    handleShowContentChange,
    permissionlist,
    canEdit
  } = props

  const { roleNameList } = permissionlist || {}

  const dispatch = useDispatch()

  // 库表统计数据
  const {
    data: dbSchemaNums,
    loading: dbSchemaNumsLoading,
    run: getDBTableCount,
  } = useRequest(queryDBTableCount, { manual: true })

  // 查询数据源连接池连接数量
  const {
    data: connectionNums,
    loading: connectionNumsLoading,
    run: getConnectionCount,
  } = useRequest(queryConnectionCount, {
    manual: true,
  })

  // 查询今日SQL、总SQL
  const { data: sqlCountInfo, loading: sqlCountLoading, run: getSQLCount } =
    useRequest(querySQLCount, {
      manual: true
    })

  useEffect(() => {
    if (connectionName && connectionId) {
      getSQLCount(connectionName)
      getDBTableCount(connectionId)
      getConnectionCount(connectionId)
    }
  }, [
    connectionName,
    connectionId,
    getSQLCount,
    getDBTableCount,
    getConnectionCount,
  ])

  return (
    <div className={styles.connectionOverviewTabs}>
      <Card hoverable className={classnames(styles.noCursor, styles.cardWrap)}>
        <Spin spinning={connectionPoolLoading}>
          <div className={styles.header}>
            <Iconfont
              type={`icon-${dataSourceType}`}
              className={classnames(
                styles.mr10,
                styles.fs30,
                styles.bgcolord38787,
                styles.circle48
              )}
            />
            <div>
              <div className={styles.title}>{dataSourceType}</div>
              <div className={styles.desc}>数据源类型</div>
            </div>
          </div>
          {connectionPool?.map((item: any, index: string) => {
            const showValue = item?.type==='table'?
            item?.value?.map((i: any)=><div style={{marginBottom:6}}>{i?.connectionUrl + ':' + i?.connectionPort}</div>)
            :
              item?.value?.length > 13 ? (
                <Tooltip title={item?.value}>
                  {item?.value?.substring(0, 13) + "..."}
                </Tooltip>
              ) : (
                item?.value
              );

            // 隐藏密码、备注
            if(["remark", "password"].includes(item.field)){
              return null
            }

            return (
              <Row className={styles.mb10} key={index}>
                <Col span={10} style={{color:'#868FA3'}}>{item?.label}：</Col>
                {/* 用户信息点击事件 */}
                {item?.field === "userName" ? (
                  <Col
                    span={14}
                  // className={styles.options}
                  // onClick={() => {
                  //   handleShowContentChange("userAuthorize", "tabs");
                  // }}
                  >
                    {showValue}
                  </Col>
                ) : (
                  <Col span={14}>{showValue}</Col>
                )}
              </Row>
            );
          })}
          {
            canEdit 
            ? <Button
              type='link'
              className={classnames(styles.options, styles.padding0)}
              onClick={() => {
                dispatch(setIsTabEditConnection(true))
                handleEditContent({ connectionId });
              }}
              style={{padding: 0}}
            >
              编辑连接
            </Button>
            : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
                <Button
                  type='link'
                  className={classnames(styles.options, styles.padding0)}
                  style={{padding: 0}}
                  disabled={true}
                >
                  编辑连接
                </Button>
              </Tooltip>  
          }
        </Spin>
      </Card>
      <div className={styles.cardWrap}>
        <Card
          hoverable
          onClick={() => setCurTabs("3")}
        >
          <Spin spinning={connectionNumsLoading}>
            <div className={styles.flex}>
              <div className={styles.flex1}>
                <div className={styles.nums}>
                  <span className={styles.col3357ff}>
                    {connectionNums?.userNum || 0}
                  </span>
                  /
                  <span className={styles.col3357ff}>
                    {connectionNums?.connectionNum || 0}
                  </span>
                </div>
                <div>用户数量/连接数量</div>
              </div>
              <Iconfont
                type="icon-renyuan"
                className={classnames(
                  styles.fs30,
                  styles.bgcolor3357FF,
                  styles.circle64,
                  styles.ml10
                )}
              />
            </div>
          </Spin>
        </Card>
        <Card
          hoverable
          className={styles.mt20}
          onClick={() => history.push({
            pathname: `/statement_detail`, state: {
              connectionIds: [connectionId]
            }
          })}
        >
          <Spin spinning={sqlCountLoading}>
            <div className={styles.flex}>
              <div className={styles.flex1}>
                <div className={styles.nums}>
                  <span className={styles.col3357ff}>
                    {sqlCountInfo?.today || 0}
                  </span>
                  /
                  <span className={styles.col3357ff}>
                    {sqlCountInfo?.total || 0}
                  </span>
                </div>
                <div className={styles.desc}>今日SQL/执行总数</div>
              </div>
              <Iconfont
                type="icon-ai-code"
                className={classnames(
                  styles.fs30,
                  styles.bgcolor23B899,
                  styles.circle64,
                  styles.ml10
                )}
              />
            </div>
          </Spin>
        </Card>
      </div>
      <Card
        hoverable
        className={styles.cardWrap}
        onClick={() => setCurTabs("2")}
      >
        <Spin spinning={dbSchemaNumsLoading}>
          <div className={classnames(styles.title, styles.mb20)}>资源管理</div>
          <div className={styles.items}>
            <span>
              <Iconfont
                type="icon-schema_disable"
                className={classnames(
                  styles.fs18,
                  styles.color2B58BA,
                  styles.mr4
                )}
              />
              逻辑库
            </span>
            <span className={classnames(styles.nums, styles.col3357ff)}>
              {dbSchemaNums?.databaseNum || 0}
            </span>
          </div>
          <div className={styles.items}>
            <span>
              <Iconfont
                type="icon-schema_nomal"
                className={classnames(
                  styles.fs18,
                  styles.colorgreen,
                  styles.mr4
                )}
              />
              Schema
            </span>
            <span className={classnames(styles.nums, styles.col3357ff)}>
              {dbSchemaNums?.schemaNum || 0}
            </span>
          </div>
          <div className={styles.items}>
            <span>
              <Iconfont
                type="icon-table"
                className={classnames(
                  styles.fs18,
                  styles.color2B58BA,
                  styles.mr4
                )}
              />
              表数量
            </span>
            <span className={classnames(styles.nums, styles.col3357ff)}>
              {dbSchemaNums?.tableNum || 0}
            </span>
          </div>
        </Spin>
      </Card>
    </div>
  );
}

export default ConnectionOverview
