import React, { useCallback } from 'react'
import { Col, Space, Tooltip } from 'antd'
import { Iconfont } from 'src/components'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import { editor } from 'monaco-editor'
import styles from './index.module.scss'
import { FontSizeOutlined } from '@ant-design/icons'

export const MonacoToolbarEdit = React.memo(() => {
  const [editorInstance] = useEditorInstance()

  const handleChangeFontSize = useCallback(
    (change: number) => {
      if (!editorInstance) return
      const fontSize = editorInstance.getOption(editor.EditorOption.fontSize)
      editorInstance.updateOptions({ fontSize: fontSize + change })
    },
    [editorInstance],
  )

  return (
    <Col>
      <Space size={1}>
        <Tooltip title="大小写转换" placement="bottomLeft" arrowPointAtCenter>
          <FontSizeOutlined
            className={styles.monacoToolbarIcon}
            onClick={() => {
              editorInstance?.getAction('custom.edit.uppercase').run()
            }}
          />
        </Tooltip>
        <Tooltip title="格式化" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-formatpainter-fill"
            onClick={() => {
              editorInstance?.getAction('editor.action.formatDocument').run()
            }}
          />
        </Tooltip>
        <Tooltip title="全部折叠" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-angle-double-up"
            onClick={() => {
              editorInstance?.getAction('editor.foldAll').run()
            }}
          />
        </Tooltip>
        <Tooltip title="全部展开" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-angle-double-down"
            onClick={() => {
              editorInstance?.getAction('editor.unfoldAll').run()
            }}
          />
        </Tooltip>
        <Tooltip title="放大字体" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-fontbig"
            onClick={() => {
              handleChangeFontSize(2)
            }}
          />
        </Tooltip>
        <Tooltip title="缩小字体" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            className={styles.monacoToolbarIcon}
            type="icon-fontsmall"
            onClick={() => {
              handleChangeFontSize(-2)
            }}
          />
        </Tooltip>
      </Space>
    </Col>
  )
})
