import React, { useEffect, useState, useMemo, useCallback } from "react";
import { VerticalAlignBottomOutlined } from '@ant-design/icons'
import * as _ from "lodash";
import { DataNode } from "antd/lib/tree";
import classnames from "classnames";
import { TreeNodeProps } from "rc-tree-select/lib/TreeNode";
import { Button, Space, Spin, Tooltip, Tree, Select } from "antd";
import { FlowElementTreeNodeEntity, queryGroupNodes, queryTreeNode } from "src/api";
import { Iconfont } from "src/components";
import { useDispatch, useRequest, useSelector } from "src/hook";
import type { ResponseNodeEntity } from "src/types";
import { actionIconNodeTypes } from 'src/constants';
import {
	getProtectSdtSearchList,
	permissionSupport
} from 'src/api';
import { getCurrentModulePermissionByUrl } from "src/util";
import {
	matchKeyword,
	getConnExpandNodeNeedPathKeys,
	findNodeByKey,
	generateTree
} from './util';
import { setSelectedNode, setImportDesConfigModalVisible , setSelectedNodePermissionInfo} from "../DataProtectionPageSlice";
import styles from "../index.module.scss";

interface IProps {
	[p: string]: any
}

/** 元素节点元信息 */
export type ElementNodeProps = Pick<FlowElementTreeNodeEntity, "connectionId" | "connectionType" | "nodeName" | "nodePath" | "nodeType" | "hasChild"> & {
	nodePathWithType: string;
	groupId: number | null | undefined;
};

type NewResponseNodeEntity = ResponseNodeEntity & {
	nodePathWithType: string;
	sdt: any;
};

const { DirectoryTree } = Tree;


const renderTitle = (i: any) => {
	let iconType = `icon-${i?.nodeType === 'connection' ? i?.sdt?.connectionType : i?.nodeType}`

	return <div>
		<Iconfont
			type={iconType}
			style={{ marginRight: 4 }} /><span>{i?.nodeShowName}</span>
	</div>
}

const SdtTree = (props: IProps): JSX.Element => {
	const { permissionlist } = props;
	const dispatch = useDispatch();
	const {
		selectedNode
	} = useSelector((state) => state.dataProtection)
	const isOnlySuperUser = permissionlist?.roleNameList.length === 1 && permissionlist?.roleNameList?.includes("高级用户")

  const { permissionList } = useSelector((state) => state?.login);
  const { selectedNodePermissionInfo } = useSelector(state => state.dataProtection);
	const [total, setTotal] = useState<number>(0);
	const [treeData, setTreeData] = useState<DataNode[]>([]);
	const [expandedKeys, setExpandedKeys] = useState<any[]>([])
	const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
	//所有点开过的节点 做缓存{path: []}
	const [treeNodeChildrenMap, setTreeNodeChildrenMap] = useState<any>({});
	//字典搜索内容
	const [dictSearchValue, setDictSearchValue] = useState<any>(null);
	//字典搜索options
	const [dictOptions, setDictOptions] = useState<any>([])

  const moduleName = "DATA_PROTECT";
  
  //模块权限查询
  const modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, moduleName)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(permissionList)])


  // 判断是否可编辑
  const { run: runPermissionSupport } = useRequest(permissionSupport, {
    manual: true,
    onSuccess: (res) => {
      dispatch(setSelectedNodePermissionInfo({...selectedNodePermissionInfo,
        [moduleName] :{
          "modulePermissionObj": modulePermissionObj,
          "permissionSupportData": res
        }
      }))
    }
  })

	// 左侧treeData
	const { loading: rootLoading, run } = useRequest(queryGroupNodes, { manual: true });

	//数据字典远程搜索 全部资源
	const { loading: dictLoading, run: onSearchSdtNodes } = useRequest(getProtectSdtSearchList, {
		manual: true,
		debounceInterval: 800,
		formatResult: (data) => {
			return data?.map((i: any) => ({
				label: renderTitle(i),
				value: i?.nodePath,
				key: i?.nodePath,
				...i,
			}))
		},
		onSuccess(res) {
			setDictOptions(res)
		}
	})

	useEffect(() => {
		if (isOnlySuperUser) {
			setTreeData([])
			return
		}
		run(true).then(res => {
			setTotal(res.total);

			const formatTreeNodes = generateTree(res?.nodeList)?.filter(node => node?.children?.length);
			let newTreeNodes = formatTreeNodes?.filter(item => {
				if (item?.id !== "Redis" && item?.id !== "MongoDB") {
					return item
				}
			})
			setTreeData(newTreeNodes);
			setSelectedKeys([newTreeNodes?.[0]?.key ?? []]);

			const cloneNode = _.cloneDeep(newTreeNodes?.[0] ?? {});
			dispatch(setSelectedNode(cloneNode as any));

      // 获取该node下，用户是否可编辑
      if (!cloneNode?.props?.nodePathWithType) {
        runPermissionSupport({
          systemPermissionType: moduleName,
          nodePathWithType: null
        })
      } else {
        runPermissionSupport({
          systemPermissionType: moduleName,
          nodePathWithType: cloneNode?.props?.nodePathWithType
        })
      }
		});
	}, [run]);

	// 默认选中
	useEffect(() => {

		const defaultSelectedItem = treeData && treeData.find((item: any) => item.nodeType === "datasource");
		if (!selectedKeys.length) {
			const cloneDefaultNode = _.cloneDeep(defaultSelectedItem);
			const defaultSelectedKeys = cloneDefaultNode?.key;
			dispatch(setSelectedNode(cloneDefaultNode as any));
			setSelectedKeys([defaultSelectedKeys]);
		}
	}, [treeData]);

	/**
	 * 获取子节点
	 */
	const { run: loadChildren, loading: childLoading } = useRequest(queryTreeNode, {
		manual: true,
		formatResult: res => {
			return res.filter(filterTreeNode).map(formatTreeNode);
		},
	});

	const onSelectFilterOption = async (v: string, option: any) => {

		setDictSearchValue(v);
		const searchName: string = _.last(option?.nodeShowName?.split('.')) || ''
		const { nodePath, nodeType, nodePathWithType, key, sdt = {} } = option;
		const { connectionType, groupName } = sdt;
		const allExpandKeys = getConnExpandNodeNeedPathKeys(option?.nodePath, true);
		//顶层为数据源 对应的所有nodePath
		let shouldExpandKeys = allExpandKeys.slice(0, allExpandKeys.length - 1);

		//如果选中是连接 则不需要掉接口
		if (['connection', 'group'].includes(nodeType)) {
			//展开节点是 数据源类型、组
			setExpandedKeys([`/${connectionType}`, `/${connectionType}/${groupName}`]);
			setSelectedKeys(nodePath)
			await dispatch(setSelectedNode({ ...option, props: {...option, ...(option?.sdt || {})} }));
			return
		}
		//再次搜索 tree已经生成 不需要再次请求接口
		if (treeNodeChildrenMap[nodePath]) {
			setDictSearchValue(searchName);
			setExpandedKeys([...new Set(shouldExpandKeys)])
			await dispatch(setSelectedNode({ ...option, props: {...option,...(option?.sdt || {})} }));
			return
		}

		//父级 nodePath总长度
		const parentNodePathWithTypes = getConnExpandNodeNeedPathKeys(nodePathWithType);

		//以下层级 nodePathWithTypes 和上个节点保持一致
		const level1Names = ['表', '外表', '视图组', '集合', '物化视图', '键组', '文件组', '函数组', '存储过程组', '任务组', '同义词', '序列组', '触发器组', '数据库连接', '包', '包体', '任务组', '字典组', 'GridFs存储桶', '用户自定义函数', 'flexTableGroup']
		const level2Names = ['列组', '索引组', '约束组', '外键组', '触发器组', '分区组'];
		let asyncActions: any = [];
		let nodePathWithTypeArr = parentNodePathWithTypes;

		//@ts-ignore
		shouldExpandKeys?.map((key: string, index: number) => {
			//是否是表...结尾
			let endType = key.substr(key.lastIndexOf('/') + 1);
			//如果是组 则nodePathWithType = null;
			if (level1Names.includes(endType) || level2Names.includes(endType)) {
				const preNType = nodePathWithTypeArr[index - 1]; //这里可能不对
				nodePathWithTypeArr.splice(index, 0, preNType);
			}
		})

		shouldExpandKeys.map((key, index) => asyncActions.push({ nodePath: key, nodePathWithType: nodePathWithTypeArr[index] }))

		let cloneTreeData = _.cloneDeep(treeData);
		let cloneTreeNodeChildrenMap = _.cloneDeep(treeNodeChildrenMap);

		for (const node of asyncActions) {
			try {

				//防止重新请求 导致其他查询过数据被清空
				if (!cloneTreeNodeChildrenMap[node.nodePath]) {

					const { newTreeData, newTreeNodeChildrenMap } = await fetchNodeChildren({ ...node, props: { ...node, key: node.nodePath } }, cloneTreeData, cloneTreeNodeChildrenMap)
					cloneTreeData = newTreeData;
					cloneTreeNodeChildrenMap = newTreeNodeChildrenMap;
				};

			} catch (error) {
				console.log('异步调用出错了', error);
				break;
			}
		}
		//包括数据源 或组
		shouldExpandKeys = shouldExpandKeys.concat([`/${connectionType}`, `/${connectionType}/${groupName}`])
		await setTreeData(cloneTreeData);
		setTreeNodeChildrenMap(cloneTreeNodeChildrenMap)
		setExpandedKeys([...new Set(shouldExpandKeys)])
		await dispatch(setSelectedNode({ ...option, props: {...option, ...(option?.sdt || {})} }));
		setDictSearchValue(searchName)
	}

	/**
	 * 只保留 dataSource, table, group, root, connection, database, schema, tableGroup
	 */
	function filterTreeNode(node: NewResponseNodeEntity): boolean {
		const retainType = ["dataSource", "table", "group", "root", "connection", "database", "schema", "tableGroup", "oracleUser"];
		const { nodeType } = node?.sdt || node;
		return retainType.includes(nodeType) ? true : false;
	}

	function formatTreeNode(node: NewResponseNodeEntity): DataNode {
		const { connectionId, nodeName, nodePath, nodeType, connectionType, hasChild, nodePathWithType, groupId } = node?.sdt || {};

		let icon = `icon-${nodeType}`;
		if (nodeType === "connection") {
			icon = `icon-connection-${connectionType}`;
		}
   //以后尽量不要使用这样的方法， 结构复杂 构造树的时候也复杂 造成问题多
		const nodeProps: ElementNodeProps = {
			connectionId: connectionId as number,
			connectionType,
			nodePath,
			nodeType,
			nodeName,
			hasChild,
			nodePathWithType,
			groupId,
		};
		const treeNode = {
			key: nodePath,
			value: nodePath,
			title: nodeName,
			icon: <Iconfont type={icon} />,
			props: nodeProps,
			// table 不展开
			isLeaf: !hasChild || nodeType === "table",
		};
		return treeNode;
	}

	const fetchNodeChildren: any = async (node: TreeNodeProps, updatedTreeData?: any[], updateTreeNodeChildrenMap?: any) => {

		//不存在缓存 重新请求
		const props = node?.props;
		const key = node?.props?.nodePath;
		let cloneTreeData = _.cloneDeep(updatedTreeData || treeData);
		let cloneTreeNodeChildrenMap = _.cloneDeep(updateTreeNodeChildrenMap || treeNodeChildrenMap);

		const connectionId = props?.id ? props.id : props?.connectionId;
		const connectionType = props?.connection ? props?.connection?.connectionType : props?.connectionType;

		const params = {
			connectionId: connectionId,
			connectionType: connectionType,
			nodeType: props?.nodeType,
			nodeName: props?.nodeName,
			nodePath: props?.nodePath,
			nodePathWithType: props?.nodePathWithType,
		};
		const children = await loadChildren({
			...params,
		});

		const target = findNodeByKey(cloneTreeData, key);

		if (target) {
			target.children = children || [];

			cloneTreeNodeChildrenMap[key] = children;

			return {
				newTreeData: cloneTreeData,
				newTreeNodeChildrenMap: cloneTreeNodeChildrenMap
			};
		}
	}

	async function onLoadData(node: TreeNodeProps) {
		const { nodeType } = node.props;
		if (nodeType === "datasource" || nodeType === "group") {
			return;
		}
		let cloneTreeData = _.cloneDeep(treeData);
		let cloneTreeNodeChildrenMap = _.cloneDeep(treeNodeChildrenMap)
		const { newTreeData, newTreeNodeChildrenMap } = await fetchNodeChildren(node);

		cloneTreeData = newTreeData;
		cloneTreeNodeChildrenMap = newTreeNodeChildrenMap;
		setTreeData(cloneTreeData);
		setTreeNodeChildrenMap(cloneTreeNodeChildrenMap)
	}

	function onShowImportList() {
		//清空选中信息
		setSelectedKeys([]);
		dispatch(setSelectedNode({} as any));
		dispatch(setImportDesConfigModalVisible(true))
	}

	const filterNodesNotMatch = useCallback(
		(nodes: any[]): any[] =>
			nodes.filter((node) => {
			
				//@ts-ignore
        if (selectedNode?.sdt?.connectionType !== (node.connectionType || node?.props?.connectionType)) return false

				//@ts-ignore
				const name: string = _.last(selectedNode?.nodeShowName?.split('.')) || ''

				const isSameConnection = selectedNode?.nodePath?.startsWith(node?.props?.nodePath);
        
				//后端搜索 筛选信息只有一条， 前端筛选模糊匹配
				const keywordHit = isSameConnection && matchKeyword(node.title, name)  //确保位置准确 不然可能出现多个层级

				if (!keywordHit  && node?.children) {
					node.children = filterNodesNotMatch(node.children)
				}
				return keywordHit || node.children?.length
			}),
		[dictSearchValue],
	)

	const filteredTreeData: any[] = useMemo(
		() =>
			dictSearchValue ? filterNodesNotMatch(_.cloneDeep(treeData)) : treeData,
		[dictSearchValue, JSON.stringify(treeData)],
	)

	return (
		<div className={styles.treeContainer}>
			<div className={styles.treeHeader}>
				<Space>
					<span style={{ color: '#667084' }}>资源列表:</span>
					<span style={{ color: '#667084' }}>
						{
							isOnlySuperUser ?
								0
								: total
						}
					</span>
				</Space>
				<Tooltip title={
					permissionlist?.isOnlyRead ?
						`您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
						: null
				}
				>
					<Button
						type="link"
						className={classnames(styles.padding0)}
						onClick={() => onShowImportList()}
						disabled={permissionlist?.isOnlyRead}
					>
						<VerticalAlignBottomOutlined rotate={-90} color="#3262FF" style={{ marginRight: 8 }} />
						脱敏配置导入
					</Button>
				</Tooltip>
			</div>
			<Select
				allowClear
				showSearch
				filterOption={false}
				loading={dictLoading}
				showArrow={false}
				value={dictSearchValue}
				dropdownMatchSelectWidth={false}
				onSelect={onSelectFilterOption}
				onClear={() => { setDictSearchValue(null); }}
				onSearch={(text) => {
					setDictOptions([])
					onSearchSdtNodes({ query: text })
				}}
				className={styles.protectSearch}
				placeholder="请输入搜索内容"
				options={dictOptions || []}
			/>
			<Spin spinning={rootLoading} wrapperClassName={styles.spinContainer}>
				<DirectoryTree
					className={styles.sdtTree}
					titleRender={(node: any) => {
						let count: number = 0;
						if (["datasource", "group"].includes(node.props.nodeType) && node?.layer?.childCount) {
							count = node.layer.childCount;
						}

						return `${node.props.nodeName}${count ? "(" + count + ")" : ""}`;
					}}
					treeData={filteredTreeData}
					loadData={async node => {
						onLoadData(node as unknown as TreeNodeProps);
					}}
					expandAction={false}
					selectedKeys={selectedNode?.key ? [selectedNode.key] : undefined}
					onSelect={(_key, { node }: any) => {
						setSelectedKeys([node?.key]);
						const cloneNode = _.cloneDeep(node);
						dispatch(setSelectedNode(cloneNode as any));

            // 获取该node下，用户是否可编辑
            if (!cloneNode?.props?.nodePathWithType) {
              runPermissionSupport({
                systemPermissionType: moduleName,
                nodePathWithType: null
              })
            } else {
              runPermissionSupport({
                systemPermissionType: moduleName,
                nodePathWithType: cloneNode?.props?.nodePathWithType
              })
            }
						dispatch(setImportDesConfigModalVisible(false))
					}}
					expandedKeys={[...expandedKeys]}
					onExpand={(expandedKeys) =>
						setExpandedKeys(expandedKeys)
					}
				></DirectoryTree>
					{!rootLoading  && !filteredTreeData.length && (
						<div className={styles.treePlaceholder}>暂无元素</div>
					)}
			</Spin>
		</div>
	);
};

export { SdtTree };

