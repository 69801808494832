import React from 'react'
import { RoleEntity } from 'src/api'

type IRoleRecordContext = {
  mode: 'add' | 'edit'
  record?: RoleEntity
  refreshRoles?: () => Promise<any>
}

export const RoleRecordContext = React.createContext<IRoleRecordContext>({
  mode: 'add',
})
