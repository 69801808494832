import { editor } from 'monaco-editor'

const darkTheme: editor.IStandaloneThemeData = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    {
      token: 'string.sql',
      foreground: '#ffffff',
    },
  ],
  colors: {
    // ref: https://microsoft.github.io/monaco-editor/playground.html#customizing-the-appearence-exposed-colors
    'editor.background': '#252526',
    'editorGutter.background': '#3e3e45',
    'editorLineNumber.foreground': '#838383',
  },
}

const lightTheme: editor.IStandaloneThemeData = {
  base: 'vs',
  inherit: true,
  rules: [
    {
      token: 'string.sql',
      foreground: '#555555',
    },
  ],
  colors: {
    // 选中行
    'editorLineNumber.foreground': '#838383',
  },
}

// 注册主题
editor.defineTheme('dark', darkTheme)
editor.defineTheme('light', lightTheme)

// 清除原生 context menu 项
// https://github.com/microsoft/monaco-editor/issues/1280
const menus = require('monaco-editor/esm/vs/platform/actions/common/actions')
  .MenuRegistry._menuItems
menus.clear()
