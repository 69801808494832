import { RcFile } from 'antd/lib/upload'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SparkMD5 from 'spark-md5'

// 获取文件后缀名
export const getFileSuffix = (fileName: string) => {
  let arr = fileName.split('.')
  if (arr.length > 0) {
    return arr[arr.length - 1]
  }
  return ''
}
export const CHUNK_SIZE = 100 * 1024 * 1024
// 拆分文件
export const splitFile = (file: RcFile, size = CHUNK_SIZE) => {
  const fileChunkList = []
  let curChunkIndex = 0
  while (curChunkIndex <= file.size) {
    const chunk = file.slice(curChunkIndex, curChunkIndex + size)
    fileChunkList.push({ chunk })
    curChunkIndex += size
  }
  return fileChunkList
}

export const readFileBuffer = (file: Blob) => {
  return new Promise((res) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      res(e.target?.result)
    }
    reader.readAsArrayBuffer(file)
  })
}

export const readFileMd5 = async (file: Blob) => {
  const spark = new SparkMD5.ArrayBuffer()
  const buffer = (await readFileBuffer(file)) as ArrayBuffer
  spark.append(buffer)
  return spark.end()
}

export const calculateHash = (chunkList: any): Promise<string> => {
  return new Promise(async (resolve) => {
    const spark = new SparkMD5.ArrayBuffer()
    const loadNext = async (index: number) => {
      if (index === chunkList.length) {
        resolve(spark.end())
      } else {
        const fileBuffer = (await readFileBuffer(
          chunkList[index].chunk,
        )) as ArrayBuffer
        spark.append(fileBuffer)
        loadNext(index + 1)
      }
    }
    loadNext(0)
  })
}

//节流函数
function useThrottleValue(value: any, duration: number) {
  const [throttleValue, setThrottleValue] = useState(value)
  let Local = useRef({ flag: true }).current
  useEffect(() => {
    let timer: any
    if (Local.flag) {
      Local.flag = false
      setThrottleValue(value)
      setTimeout(() => (Local.flag = true), duration)
    } else {
      timer = setTimeout(() => setThrottleValue(value), duration)
    }
    return () => clearTimeout(timer)
  }, [value, duration, Local])
  return throttleValue
}

export const useTimer = (outTotal: number) => {
  // speed 按秒 速度
  const defaultNetSpeed = 2 * 1024 * 1024
  const [isStartTimer, setStart] = useState<boolean>(false)
  const [totalTime, setTotalTime] = useState<number>(99)
  const [overTime, setOverTime] = useState<number>(0)
  const [total, setTotal] = useState<number>(outTotal)
  const [speed, setSpeed] = useState<number>(defaultNetSpeed)

  const debounceSetTotalTime = useMemo(() => {
    const wrapped = (val: number) => {
      setTotalTime(val)
    }
    return debounce(wrapped, 1000)
  }, [])

  useEffect(() => setTotal(outTotal), [outTotal])

  useEffect(() => {
    debounceSetTotalTime(Number((total / speed).toFixed()))
  }, [total, speed, debounceSetTotalTime])

  useEffect(() => {
    const timerRecorder = setInterval(() => {
      // 定时器常存 只有开启时才进行计算
      if (isStartTimer) {
        // 容错 当时间走完  但是还没停止时候 永远保留时间1s
        if (totalTime - overTime - 1 > 0) {
          setOverTime(overTime + 1)
        }
      }
    }, 1000)
    return () => {
      clearInterval(timerRecorder)
    }
  }, [isStartTimer, overTime, totalTime])

  const updateCurrenLostTimeBySpeed = useCallback(
    (speed: number) => setSpeed(speed),
    [setSpeed],
  )

  const stopTimer = () => {
    setStart(false)
    setTotalTime(0)
  }

  const startTimer = () => {
    setStart(true)
  }

  // const throttleTimeValue = useMemo(() => {
  //   const wrapped = () => totalTime - overTime
  //   return throttle(wrapped, 2000)
  // }, [totalTime, overTime])

  // const lostTime = useMemo(() => throttleTimeValue(), [throttleTimeValue])

  // console.log(lostTime)
  const lostTime = useThrottleValue(totalTime - overTime, 1000)

  return {
    startTimer,
    lostTime: lostTime,
    start: isStartTimer,
    updateCurrenLostTimeBySpeed,
    stopTimer,
  }
}
