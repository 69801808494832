import { Form, message } from 'antd'
import * as _ from 'lodash'
import React, { useEffect } from 'react'
import { getFlowPermission, deleteFlowPermission } from 'src/api'
import { SimpleTable, UIModal, BtnWithConfirmModal } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { resetDesensitizedUserListObj } from '../../DataProtectionPageSlice'

export const DesensitizedUserListModal = (): JSX.Element => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const {  desensitizedUserListObj ={} } = useSelector(
    (state) => state.dataProtection,
  )
  const {callback, nodePath, datasourceType} = desensitizedUserListObj;
  const visible = useSelector((state) => state.modal.DesensitizedUserListModal)
  
  const { data: desensPermissionList = [], run: runGetFlowPermission, loading } = useRequest(getFlowPermission, {
    manual: true
  })

  //删除
  const { run: runDelete } = useRequest(deleteFlowPermission, {
    manual: true,
    onSuccess: async () => {
      message.success(`删除成功`)
      runGetFlowPermission({
        nodePathWithType: nodePath,
        dataSourceType :datasourceType
      })
    },
  })

  useEffect(() => {

    if (datasourceType && nodePath && visible) {
      runGetFlowPermission({
        nodePathWithType: nodePath,
        dataSourceType :datasourceType
      })
    }
   
  }, [ nodePath, datasourceType, visible])

  const columns = [
    {
        dataIndex: 'userName',
        title: '用户名',
        render: (name: string, record: any) => `${name}(${record?.userId})`
    },
    {
        dataIndex: 'deptName',
        title: '所属部门'
    },
    {
        dataIndex: 'expr',
        title: '生效时间',
    },
    {
        dataIndex: 'permissionId',
        title: '操作',
        render: (id: number) =>  <BtnWithConfirmModal title='删除后将收回此用户对此数据的脱敏权限，确认删除？' btnText='删除'  hideReason={true} onClick={() => runDelete({permissionIds: [id]})}/> 
    }
    ]

  return (
    <UIModal
      title='申请脱敏用户列表'
      visible={visible}
      onCancel={() => {
        dispatch(hideModal('DesensitizedUserListModal'))
        dispatch(resetDesensitizedUserListObj());
        callback?.()
      }}
      afterClose={() => form.resetFields()}
      confirmLoading={loading}
      footer={null}
    >
      <SimpleTable 
        loading={loading}
        dataSource={desensPermissionList}
        columns={columns}
        total={0}
        scroll={{}}
      />
    </UIModal>
  )
}
