import React, { useCallback, useState, useContext } from 'react'
import { Card, message, Form, Typography, Checkbox } from 'antd'
import { useRequest } from 'src/hook'
import { LinkButton } from 'src/components'
import { getInitMenuPerms_api, postInitMenuPerms_api } from 'src/api'
import styles from './index.module.scss'
import Service from 'src/service'
import { SettingFormContext } from '../SettingFormContext'
import { FormInstance } from 'antd/lib/form'

const { Text } = Typography

export const InitMenuPermsCard: React.FC = () => {
  const form = useContext(SettingFormContext) as FormInstance

  const {
    loading: fetchLoading,
    data,
    refresh,
  } = useRequest(getInitMenuPerms_api, {
    onSuccess: (data) => {
      const choosedList =
        data?.filter((it: { choose: boolean }) => it.choose) || []
      form.setFields([
        {
          name: 'initMenuPerms',
          value: choosedList.map((it: { name: string }) => it.name),
        },
      ])
    },
    formatResult: (data) => {
      return data
        ?.filter(({ name }: any) => !['Warning', 'Monitor', 'DashBoard'].includes(name))
        ?.map((it: { name: any }) => ({
          ...it,
          label: Service.permService.getPermData({ key: it.name })?.name,
        }))
    },
  })

  const { loading: saveLoading, run: saveInitRoles } = useRequest(
    postInitMenuPerms_api,
    {
      manual: true,
      onSuccess: () => {
        message.success('修改成功')
        setEditing(false)
        refresh()
      },
    },
  )

  const [editing, setEditing] = useState(false)

  const handleSave = useCallback(() => {
    form.validateFields(['initMenuPerms']).then(({ initMenuPerms }) => {
      saveInitRoles(initMenuPerms)
    })
  }, [form, saveInitRoles])

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave} loading={saveLoading}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              const choosedList =
                data?.filter((it: { choose: boolean }) => it.choose) || []
              form.setFields([
                {
                  name: 'initMenuPerms',
                  value: choosedList.map((it: { name: string }) => it.name),
                },
              ])
              form.validateFields(['initMenuPerms'])
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
      )}
    </div>
  )

  const choosedList = data?.filter((it: { choose: boolean }) => it.choose) || []
  return (
    <section className="cq-card flow-card" id="InitMenuPermsCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">初始菜单权限</h3>
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={fetchLoading}
        >
          <Form.Item label="初始权限">
            <Form.Item name="initMenuPerms" hidden={!editing} noStyle>
              <Checkbox.Group>
                {data?.map((it: any) => (
                  <Checkbox value={it.name} key={it.name}>
                    {it.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
            {!editing && (
              <Text>
                {choosedList?.map((it: any) => it.label).join(',') || ''}
              </Text>
            )}
          </Form.Item>
        </Card>
      </section>
    </section>
  )
}
