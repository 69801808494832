import React from 'react';
import { Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

interface IResetPermissionModalProps {
  title?: string;
  content?: string;
}
export const resetPermissionModal = ({
  title,
  content
}: IResetPermissionModalProps ) => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: title || '是否重置所选用户权限等级',
      icon: <InfoCircleFilled style={{color: '#faad14'}}/>,
      content: content || '所选用户在此层级下存在其他权限设置,重置将清空下层级所选用户的权限设置',
      okText: '是',
      cancelText: '否',
      centered: true,
      onOk: () => {
       resolve(true)
      },
      onCancel: () => {
        resolve(false)
      }
    });
  })
}
