import DataSet from '@antv/data-set'
import { Chart } from '@antv/g2'
import { Radio, Spin, DatePicker, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import * as _ from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import {
  getAuditExecuteCostAvgUnit,
  getToolbarDataNodeList,
  getDbsUnderSpecifiedConnection,
  AuditSqlParams
} from 'src/api'
import { useRequest } from 'src/hook'
import chartStyles from './chart.module.scss'
import { EmptyChart } from './EmptyChart'
import {
  getUnit,
  getUnitBase,
  getCustomUnitBase,
  getUnixTimeRange,
} from './util'
import type { NodeEntity } from 'src/types'
import { useHistory } from 'react-router-dom'

export const ExecutionTimeChart = () => {
  const history = useHistory()
  const [timeRange, setTimeRange] = useState<1 | 7 | 30 | any>(7)
  const [rangePickerTimes, setRangePickerTimes] = useState<string[] | null>(
    [dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
    dayjs().endOf('d').format('YYYY-MM-DD')]
  )
  const [selectedConnectionInfo, setSelectedConnectionInfo] = useState<any>({})
  const [dbName, setDbName] = useState<string[]>([])

  const { data: connectionNames = [] } = useRequest(
    () =>
      getToolbarDataNodeList({
        nodeType: 'root',
      }),
    {
      formatResult: (data: NodeEntity[]) => {
        return (
          data &&
          data.map((item) => ({
            ...item,
            label: item.nodeName,
            value: item.nodeName,

          }))
        )
      },
    },
  )

  const { data: dbNames } = useRequest(
    () => {
      if (selectedConnectionInfo?.connectionId) {
        return getDbsUnderSpecifiedConnection(selectedConnectionInfo?.connectionId)
      }
    },
    {
      formatResult: (data) => {
        return (
          data &&
          data.map((db: string) => ({
            label: db,
            value: db,
          }))
        )
      },
      refreshDeps: [selectedConnectionInfo?.connectionId],
    },
  )

  const { data = [], loading } = useRequest(
    () => {
      let defaultParams: AuditSqlParams = {
        unit: getUnit(timeRange),
        ...getUnixTimeRange(timeRange),
        dbName
      }
      if (selectedConnectionInfo?.nodeName) {
        defaultParams.connectionName = selectedConnectionInfo?.nodeName;
      }
      if (rangePickerTimes) {
        const beginTimeMs = Number(
          dayjs(rangePickerTimes[0]).startOf('d').format('x'),
        )
        const endTimeMs = Number(
          dayjs(rangePickerTimes[1]).endOf('d').format('x'),
        )
        defaultParams = {
          ...defaultParams,
          beginTimeMs,
          endTimeMs,
        }
      }
      return getAuditExecuteCostAvgUnit(defaultParams)
    },
    {
      formatResult: (data) => {
        let baseUnit = getUnitBase(timeRange)

        if (rangePickerTimes) {
          baseUnit = getCustomUnitBase(rangePickerTimes[0], rangePickerTimes[1])
        }

        return (
          new DataSet.DataView()
            .source(data.concat(baseUnit))
            // 填充刻度，例如值为 2021-02-8 00:00:00，unit 为 DAY，需要填充之前 timeRange 天的 unit string
            .transform({
              // 补全行
              type: 'fill-rows',
              groupBy: ['connectionName'],
              orderBy: ['unit'],
              fillBy: 'order',
            })
            .transform({
              // 补全列
              type: 'impute',
              field: 'executeCost', // 待补全字段
              groupBy: ['connectionName'], // 分组字段集（传空则不分组）
              method: 'value', // 补全为常量
              value: 0,
            })
            .rows.filter(({ connectionName }) => connectionName)
            .map(({ unit = '', ...rest }) => ({
              unit: timeRange === 1 ? new Date(unit).getHours() : unit,
              ...rest,
            }))
        )
      },
      refreshDeps: [
        timeRange,
        rangePickerTimes,
        dbName,
        selectedConnectionInfo,
      ],
    },
  )
  const renderExecutionTimeChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
    })
    // data = data.sort((a, b) => b.executeCost - a.executeCost)
    data = data.sort((a, b) => (dayjs(a.unit).isBefore(b.unit) ? -1 : 1))
    // console.log(data)
    chart.data(data)
    chart.scale({
      unit: {
        range: [0.02, 0.92],
        formatter: (v) => (v < 25 ? v + ' 时' : dayjs(v).format('YYYY-MM-DD')),
      },
      executeCost: {
        formatter: (v) => (v || '0') + 'ms',
        nice: true,
      },
    })

    chart.tooltip({
      showCrosshairs: true,
      shared: true,
    })

    chart.line().position('unit*executeCost').color('connectionName')

    chart
      .point()
      .position('unit*executeCost')
      .color('connectionName')
      .shape('circle')
      .style({
        stroke: '#fff',
        lineWidth: 1,
      })
    chart.on('plot:click', (ev: any) => {
    
      if (ev?.data?.data) {
        let time = []
        if (timeRange == 1) {
          time = [Number(dayjs().startOf('day').format('x')), Number(dayjs().endOf('day').format('x'))]
        } else {
          time = [Number(dayjs(ev?.data?.data?.unit).format('x')), Number(dayjs(ev?.data?.data?.unit).endOf('day').format('x'))]
        }
        let params = {
          timeRange: time,
          connectionName: [ev?.data?.data?.connectionName]
        } as any
        if (ev?.data?.data?.dbName) {
          params.databaseName = [ev?.data?.data?.dbName]
        }
        history.push({
          pathname: `/statement_detail`, state: params
        })
      }
    })
    chart.render()
    return chart
  }

  useEffect(() => {
    if (!data || !data[0]) return
    const chart = renderExecutionTimeChart('execution-time', data)
    return () => chart.destroy()
  }, [data])

  const rangeValue = useMemo(() => {
    if (rangePickerTimes === null) {
      return null
    } else {
      const range = rangePickerTimes.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [rangePickerTimes])

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.executionTimeWrapper}>
        <div className={chartStyles.toolbar}>
          <Radio.Group
            buttonStyle="solid"
            size="small"
            value={timeRange}
            onChange={(e) => {
              setTimeRange(e.target.value)
              if (e.target.value === 1) {
                setRangePickerTimes([
                  dayjs().format('YYYY-MM-DD'),
                  dayjs().format('YYYY-MM-DD'),
                ])
              } else if (e.target.value === 7) {
                setRangePickerTimes([
                  dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
                  dayjs().format('YYYY-MM-DD'),
                ])
              } else {
                setRangePickerTimes([
                  dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
                  dayjs().format('YYYY-MM-DD'),
                ])
              }
            }}
          >
            <Radio.Button value={1}>今日</Radio.Button>
            <Radio.Button value={7}>7 天</Radio.Button>
            <Radio.Button value={30}>30 天</Radio.Button>
          </Radio.Group>
          <div>
            <DatePicker.RangePicker
              style={{ marginLeft: 10 }}
              //@ts-ignore
              value={rangeValue}
              onChange={(dates, dateStrings) => {
                if (!dateStrings[0]) {
                  setRangePickerTimes(null)
                  setTimeRange(7)
                } else {
                  const diffDays = dayjs(dateStrings[1]).diff(
                    dateStrings[0],
                    'day',
                  )
                  //当日
                  if (
                    diffDays === 0 &&
                    dayjs().isSame(dayjs(dateStrings[1]), 'day')
                  ) {
                    setTimeRange(1)
                  } else {
                    setTimeRange(diffDays)
                  }
                  setRangePickerTimes(dateStrings)
                }
              }}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            />
            <Select
              allowClear
              showSearch
              maxTagCount={1}
              placeholder="连接名"
              options={connectionNames}
              style={{ margin: '0 10px', width: 80 }}
              onChange={(_, option: any) => {
                setSelectedConnectionInfo(option);
                setDbName([])
              }}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            />
            <Select
              showSearch
              mode="multiple"
              maxTagCount={1}
              placeholder="库名"
              value={dbName}
              options={dbNames}
              style={{ width: 80 }}
              onChange={(value: string[]) => setDbName(value)}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            />
          </div>
        </div>
        <div id="execution-time" className={chartStyles.executionTime}>
          {!data[0] && !loading && <EmptyChart></EmptyChart>}
        </div>
      </div>
    </Spin>
  )
}
