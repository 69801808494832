import React, { useCallback, useContext, useEffect, useState } from 'react'
import { InformationDetails } from './InformationDetails'

export const InformationCard = () => {

  return (
    <div>
      <InformationDetails/>
    </div>
  )
}
