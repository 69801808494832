import React, { useState } from 'react';
import classNames from 'classnames';
import { Layout, Breadcrumb, Radio } from 'antd';
import { CustomColumnTable as OperationCustomColumnTable } from '../operationPage/CustomColumnTable';
import { CustomColumnTable } from '../executionPage/CustomColumnTable';
import 'src/styles/layout.scss';
import styles from '../executionPage/index.module.scss';
import operationStyles from '../operationPage/index.module.scss';
import { Link, useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

const { Header, Content } = Layout

type IType = 'operate_record' | 'statement_detail';

export const UnauthorizedOperationPage = () => {
  const [tabValue, setTabValue] = useState<IType>('statement_detail');
  const location = useLocation<{[x: string]: any; state: { timeRange: any, overPermFlag: string }}>();
  const locationState = location?.state;
  const history = useHistory()

  let param_detail: {
    timeRange: any,
    extratTypes?: any,
  } = {
    timeRange: locationState?.timeRange,
    extratTypes: locationState?.extratTypes,
  };

  let param_operation: {
    timeRange: any,
    overPermFlag?: string,
  } = {
    timeRange: locationState?.timeRange,
    overPermFlag: locationState?.overPermFlag,
  };

  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header" title='越权操作'>
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>审计分析</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item><Link to="/audit_view">审计概览</Link></Breadcrumb.Item>
          <Breadcrumb.Separator>/</Breadcrumb.Separator>
          <Breadcrumb.Item>越权操作</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main">
        <Content
          className={classNames('cq-content', { [styles.executionContent]: true, [operationStyles.operationContent]: true })}
        >
          <Radio.Group className={styles.unauthorizedOperationPage} value={tabValue} onChange={(e) => setTabValue(e.target.value)}>
            <Radio.Button value="statement_detail">SQL越权</Radio.Button>
            <Radio.Button value="operate_record">操作越权</Radio.Button>
          </Radio.Group>
          {/* 因为这个组件两个地方在用，所以传入isUnauthorized判断需不需要原有组件里的执行成功/执行失败组件 */}
          {tabValue === 'statement_detail' && <CustomColumnTable isUnauthorized={true}  param={param_detail}/>}
          {tabValue === 'operate_record' && <OperationCustomColumnTable isUnauthorized={true}  param={param_operation}/>}
        </Content>
      </Layout>
    </Layout>
  );
}

