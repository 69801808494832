import React from 'react'
import { Form } from 'antd'
import { Comp } from './FormItemRollbackSql'

export const FormItemCorrectionSql = () => {
  return (
    <Form.Item
      label="订正语句"
      name="executeText"
      rules={[{ required: true, message: '请输入订正语句' }]}
    >
      <Comp />
    </Form.Item>
  )
}
