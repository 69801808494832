import React, { useEffect, useState, useCallback, ReactNode } from 'react'
import { TreeSelect } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { find, uniqWith } from 'lodash'
import { Iconfont } from 'src/components'
import {
  getOrganizationTree_api,
  getRoleBindOrganization_api,
  OrganizationNode,
  RoleEntity,
} from 'src/api'

interface OrganizationTreeNode extends OrganizationNode {
  id: string
  pId: string | number
  value: string
  title: ReactNode
  isLeaf: boolean
}

const getOrgnizationRootNodeData = (): OrganizationNode => ({
  name: '',
  orgType: 'COMPANY',
  nodePath: '',
})

const formatTreeRootNode = (
  node: OrganizationNode[],
  pId: OrganizationTreeNode['pId'],
): OrganizationTreeNode[] => {
  return node?.map((n) => {
    let icon = null
    switch (n.orgType) {
      case 'COMPANY':
        icon = <Iconfont type="icon-org-company" className="mr8" />
        n.checkable = false
        n.selectable = false
        break
      case 'DEPT':
        icon = <Iconfont type="icon-org-department" className="mr8" />
        break
      case 'GROUP':
        icon = <Iconfont type="icon-org-group" className="mr8" />
        break
      default:
        icon = <Iconfont type="icon-org-user" className="mr8" />
        break
    }

    return {
      id: n.nodePath,
      value: n.nodePath,
      title: (
        <span>
          {icon}
          {n.name}
        </span>
      ),
      isLeaf: n.orgType === 'USER',
      pId,
      ...n,
    }
  })
}

function getOrganizationNodeArray(
  nodePathAry: string[] | null,
  orgType: OrganizationNode['orgType'],
): OrganizationNode[] {
  if (!nodePathAry || !nodePathAry[0]) return []
  const nodeArray = nodePathAry.map((nodePath) => ({
    name: nodePath.split('/').pop() || '',
    nodePath,
    orgType,
  }))
  return nodeArray
}

export const ConnBindOrganizationForm: React.FC<{
  form?: FormInstance
  mode: 'add' | 'edit'
  record?: RoleEntity
  connectionId?: string | number
  formValue?: OrganizationNode[]
  setFormValue: (data: OrganizationNode[]) => void
}> = ({ record, formValue, setFormValue }) => {
  const [treeData, setTreeData] = useState<OrganizationTreeNode[]>()

  const initTreeNode = async () => {
    const rootNode = await getOrganizationTree_api(getOrgnizationRootNodeData())
    const treeRootNode = formatTreeRootNode(rootNode, 0)
    setTreeData([...treeRootNode])
  }

  useEffect(() => {
    initTreeNode && initTreeNode()
  }, [])

  const onLoadData = (pNode: OrganizationTreeNode) => {
    return new Promise(async (resolve) => {
      const childNodes = await getOrganizationTree_api(pNode)
      const treeChildNodes = formatTreeRootNode(childNodes, pNode.id)
      setTreeData(treeData?.concat(treeChildNodes))
      resolve(true)
    })
  }

  const initForm = useCallback(async () => {
    if (record?.roleName) {
      const initValue = await getRoleBindOrganization_api({
        roleName: record?.roleName,
      })
      const { deptName, grantUser, groupName } = initValue
      setFormValue([
        ...getOrganizationNodeArray(deptName, 'DEPT'),
        ...getOrganizationNodeArray(grantUser, 'USER'),
        ...getOrganizationNodeArray(groupName, 'GROUP'),
      ])
    }
  }, [record, setFormValue])

  useEffect(() => {
    initForm && initForm()
  }, [initForm, record])

  const onchange = (nodeList: any) => {
    const totalNodeStore: OrganizationNode[] = uniqWith(
      [...(treeData || []), ...(formValue || [])],
      (a, b) => a.nodePath === b.nodePath,
    )
    const nodeDataList = nodeList.reduce(
      (prev: OrganizationNode[], nodePath: string) => {
        const target = find(totalNodeStore, { nodePath })
        if (!target) return prev
        prev.push({
          nodePath: target.nodePath,
          name: target.name,
          orgType: target.orgType,
        })
        return prev
      },
      [],
    )
    setFormValue(nodeDataList)
  }

  if (!formValue) {
    return <div>loading</div>
  }

  return (
    <div>
      <TreeSelect
        treeDataSimpleMode
        treeData={treeData}
        loadData={onLoadData as any}
        treeCheckable={true}
        showCheckedStrategy="SHOW_PARENT"
        style={{ width: '100%' }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        value={formValue.map((it) => it.nodePath) as any}
        onChange={onchange}
      ></TreeSelect>
    </div>
  )
}
