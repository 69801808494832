import { AccountPasswordRule } from 'src/api'

export const getPatternsFromCode = (patternCode: number): number[] => {
  const patterns = []
  let pow = 0
  while (patternCode > 0) {
    patternCode & 1 && patterns.push((patternCode & 1) * (1 << pow))
    pow += 1
    patternCode = patternCode >> 1
  }
  return patterns
}
export const getCodeFromPatterns = (patterns: number[]) => {
  return patterns.reduce((prev, curr) => prev + curr, 0)
}

const PatternMap: { [key: number]: string } = {
  1: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  2: 'abcdefghijklmnopqrstuvwxyz',
  4: '1234567890',
  8: '!@#$%&*()',
}

export const getRandomPassword = (passwordRule: AccountPasswordRule) => {
  const { pattern, length } = passwordRule
  const patterns = getPatternsFromCode(pattern)
  const passwordArray = patterns.map((code) => PatternMap[code])
  var password = []
  let n = 0
  for (let i = 0; i < length; i++) {
    // If password length less than 9, all value random
    if (password.length < length - passwordArray.length) {
      // Get random passwordArray index
      let arrayRandom = Math.floor(Math.random() * passwordArray.length)
      // Get password array value
      let passwordItem = passwordArray[arrayRandom]
      // Get password array value random index
      // Get random real value
      let item = passwordItem[Math.floor(Math.random() * passwordItem.length)]
      password.push(item)
    } else {
      // If password large then 9, lastest 4 password will push in according to the random password index
      // Get the array values sequentially
      let newItem = passwordArray[n]
      let lastItem = newItem[Math.floor(Math.random() * newItem.length)]
      // Get array splice index
      let spliceIndex = Math.floor(Math.random() * password.length)
      password.splice(spliceIndex, 0, lastItem)
      n++
    }
  }
  return password.join('')
}

export const getRandomUserPassword = (length: number = 16) => {
  length = Number(length)
  let passwordArray = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    'abcdefghijklmnopqrstuvwxyz',
    '1234567890',
  ]
  var password = []
  let n = 0
  for (let i = 0; i < length; i++) {
    // If password length less than 9, all value random
    if (password.length < length - 3) {
      // Get random passwordArray index
      let arrayRandom = Math.floor(Math.random() * 3)
      // Get password array value
      let passwordItem = passwordArray[arrayRandom]
      // Get password array value random index
      // Get random real value
      let item = passwordItem[Math.floor(Math.random() * passwordItem.length)]
      password.push(item)
    } else {
      // If password large then 9, lastest 4 password will push in according to the random password index
      // Get the array values sequentially
      let newItem = passwordArray[n]
      let lastItem = newItem[Math.floor(Math.random() * newItem.length)]
      // Get array splice index
      let spliceIndex = Math.floor(Math.random() * password.length)
      password.splice(spliceIndex, 0, lastItem)
      n++
    }
  }
  return password.join('')
}
