/* 文件切割 size */
const SLICE_SIZE = 1024 * 1024 * 2
export const sliceReadFile = (file: File, cb: any) => {
  const chunkList = []
  /* 0 分割 file */
  let start = 0
  const totalSize = file.size
  while (start < totalSize) {
    const end = start + SLICE_SIZE
    const chunk = file.slice(start, end)
    chunkList.push(chunk)
    start = end
  }
  if (!chunkList || !chunkList[0]) {
    console.error(`${file.name}文件为空文件`)
  }
  /* 1 按顺序读取内容调取 cb */
  chunkList.reduce(
    (pre: any, chunk: any, i: number) =>
      pre
        .then(() => cb(chunk, i))
        .catch(() => console.error(`第${i}块数据读取失败`)),
    Promise.resolve(),
  )
}
