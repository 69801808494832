// query pagination
export const BLOCK_SIZE = 100

// tsql pagiantion
export const TSQL_BLOCK_SIZE = 1000

// todo: utf32 和 ibm855 浏览器 TextDecoder api 不支持, 后续还可以把支持的编码类型逐步添加进来
// encoding
export const ENCODINGS = [
  'UTF-8',
  'GB18030',
  'GBK',
  'US-ASCII',
  'Big5',
  'EUC-JP',
  'EUC-KR',
  'IBM866',
  'ISO-2022-KR',
  'ISO-2022-JP',
  'UTF-16',
  'UTF-16BE',
  'UTF-16LE',
] as const
export type Encoding = typeof ENCODINGS[number] | string

// end of line
export const EOL_SEQUENCE = ['LF', 'CRLF'] as const
export type EOL_SEQUENCE_TYPE = typeof EOL_SEQUENCE[number]

export const eolCharMap = new Map<string | undefined, EOL_SEQUENCE_TYPE>([
  ['\n', 'LF'],
  ['\r\n', 'CRLF'],
])

// transaction mode
export const txMode = ['auto', 'manual'] as const
export type TxModeType = typeof txMode[number]
