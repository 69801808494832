import React from 'react'
import { Iconfont } from 'src/components'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import {
  HistoryRecord,
  RoleEntity,
  UserConnection,
  UserConnectionInfo,
} from 'src/api'
import { renderItemField } from './render'

export const userInfoLabelMap: Record<string, string> = {
  dept: '部门',
  email: '邮箱',
  images: '用户头像',
  jobNumber: '工号',
  password: '用户密码',
  // postId: '岗位 ID',
  postName: '岗位名称',
  sessionId: '会话 ID',
  telephone: '联系电话',
  userCategory: '用户类别',
  userGender: '性别',
  userId: '账号',
  userName: '用户名',
  userStatus: '用户状态',
  isPrincipal: '部门负责人',
}

export const userTableColumns = [
  { title: '用户名', dataIndex: 'userName', ellipsis: true },
  { title: '账号', dataIndex: 'userId', ellipsis: true },
  //{ title: '所属部门', dataIndex: 'dept', ellipsis: true },
]

export const UserRolesColumns: ColumnsType<RoleEntity> = [
  { title: '角色名', dataIndex: 'roleName', ellipsis: true },
  { title: '描述', dataIndex: 'description', ellipsis: true },
  {
    dataIndex: 'permissionNames',
    title: '拥有权限',
    render(_, record) {
      const { permissionNames } = record
      return permissionNames
        .map(({ permissionName }) => permissionName)
        .join(', ')
    },
    ellipsis: true,
  },
  {
    title: '有效期',
    dataIndex: 'validPeriod',
    ellipsis: true,
    width: '96px',
    render(text) {
      return text === '永久' ? text : dayjs(text).format('YYYY-MM-DD')
    },
  },
  {
    title: '当前状态',
    dataIndex: 'valid',
    ellipsis: true,
    render: (valid) => (valid ? '有效' : '无效'),
    width: '96px',
  },
]

// ! deprecated. Use UserConnectionInfoColumns below.
// ! creator -> creatorName
export const UserConnectionsColumns: ColumnsType<UserConnection> = [
  {
    title: '连接名',
    dataIndex: 'connectionName',
    ellipsis: true,
    render: (connectionName, record) => {
      const { connectionType } = record
      return (
        <>
          <Iconfont
            type={'icon-connection-' + (connectionType || 'Other')}
            className="mr8"
            style={{ fontSize: 14 }}
          ></Iconfont>
          {connectionName}
        </>
      )
    },
  },
  { title: '创建人', dataIndex: 'creator', ellipsis: true, width: '104px' },
]

export const UserConnectionInfoColumns: ColumnsType<UserConnectionInfo> = [
  {
    title: '连接名',
    dataIndex: 'connectionName',
    ellipsis: true,
    render: (connectionName, record) => {
      const { connectionType, deleted } = record
      return (
        <>
          <Iconfont
            type={'icon-connection-' + (connectionType || 'Other')}
            className="mr8"
            style={{ fontSize: 14, opacity: deleted ? 0.55 : 1 }}
          ></Iconfont>
          {deleted && <span style={{ opacity: 0.55 }}>(已删除) </span>}
          {connectionName}
        </>
      )
    },
  },
  { title: '创建人', dataIndex: 'creator', ellipsis: true, width: '104px' },
]

export const UserHistoryColumns: ColumnsType<HistoryRecord> = [
  {
    title: '执行连接',
    dataIndex: 'connectionInfo',
    ellipsis: true,
    render: (connectionInfo) => {
      const { connectionName, connectionType, deleted } = connectionInfo || {}
      // todo: {} 为健壮性处理，应由后端保证
      return (
        <>
          <Iconfont
            type={'icon-connection-' + (connectionType || 'Undefined')}
            className="mr8"
            style={{ fontSize: 14, opacity: deleted ? 0.55 : 1 }}
          ></Iconfont>
          {deleted && <span style={{ opacity: 0.55 }}>(已删除) </span>}
          {connectionName}
        </>
      )
    },
    width: 168,
  },
  {
    title: '执行数据库',
    dataIndex: 'databaseName',
    ellipsis: true,
    width: '120px',
  },

  {
    title: 'SQL 语句',
    dataIndex: 'sql',
    render: renderItemField('longString'),
  },
  {
    title: '执行结果',
    dataIndex: 'resultStatus',
    render: renderItemField('successStatus'),
    width: '80px',
  },
  {
    title: '执行时间',
    dataIndex: 'startTime',
    render: renderItemField('timeString'),
    width: '152px',
  },
  {
    title: '耗时(ms)',
    dataIndex: 'timeConsuming',
    ellipsis: true,
    width: '88px',
  },
]

export const UserPermsColumns = [
  {
    title: '权限名',
    dataIndex: 'permissionName',
    ellipsis: true,
    width: '240px',
  },
  {
    title: '权限类型',
    dataIndex: 'permissionType',
    ellipsis: true,
    width: '240px',
  },
  {
    title: '权限描述',
    dataIndex: 'permissionDesc',
    width: '240px',
    ellipsis: true,
  },
  { title: '操作权限', dataIndex: 'operations', ellipsis: true },
]
