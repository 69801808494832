import { Breadcrumb, Layout } from "antd";
import React from "react";
import { ErrorBoundary, SimpleBreadcrumbs } from "src/components";
import "src/styles/layout.scss";
import { MainContent } from "./MainContent/MainContent";
import styles from "./index.module.scss";

const { Content } = Layout;

const BREADCRUMB_ITEMS = [
	{
		title: "静态脱敏",
	},
	{
		title: "创建任务",
	},
];

export const CreateTransferTask = () => {
	return (
		<Layout className={styles.dataTransferPageWrap}>
			<div>
				<SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
			</div>
			<Layout className={styles.mainWrap}>
				<Content className={styles.content}>
					<ErrorBoundary>
						<MainContent />
					</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};
