interface optionsIprops {
  label: string;
  value: string | number
}
// 高危提示
export const levelMenu: optionsIprops[] = [
  {
    value: 1,
    label: '只提示不拦截',
  },
  {
    value: 2,
    label: '拦截提示走数据订正',
  },
  {
    value: 3,
    label: '拦截提示申请高危操作',
  },
]

// 高危等级枚举
export const highRiskLevelMenu: optionsIprops[] = [
  {
    value: 1,
    label: '低',
  },
  {
    value: 2,
    label: '中',
  },
  {
    value: 3,
    label: '高',
  },
]