import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'src/hook'
import { Col, Dropdown, Menu } from 'antd'
import { editor } from 'monaco-editor'
import { eolCharMap, EOL_SEQUENCE, EOL_SEQUENCE_TYPE } from 'src/constants'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import { updateMonacoTabEol } from '../queryTabs/queryTabsSlice'
import { TextButton } from './TextButton'

export const EndOfLineSelector = React.memo(
  ({ endOfLine }: PropsWithChildren<{ endOfLine?: EOL_SEQUENCE_TYPE }>) => {
    const dispatch = useDispatch()
    const [editorInstance] = useEditorInstance()

    useEffect(() => {
      if (!endOfLine) return
      editorInstance?.getModel()?.pushEOL(editor.EndOfLineSequence[endOfLine])
    }, [editorInstance, endOfLine])

    useEffect(() => {
      if (!editorInstance) return
      const eolChar = editorInstance.getModel()?.getEOL()
      const modelEol = eolCharMap.get(eolChar)
      dispatch(updateMonacoTabEol({ endOfLine: modelEol }))
    }, [dispatch, editorInstance])

    return (
      <Col title="选择换行符">
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                const nextEOL = key as EOL_SEQUENCE_TYPE
                dispatch(updateMonacoTabEol({ endOfLine: nextEOL }))
              }}
            >
              {EOL_SEQUENCE.map((eol) => (
                <Menu.Item key={eol}>{eol}</Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <TextButton>{endOfLine}</TextButton>
        </Dropdown>
      </Col>
    )
  },
)
