import React from 'react';
import { Iconfont } from 'src/components';

//构建tree结构
export function setTreeNodeKey(node: any) {

    if (!node) return
    node.key = node.nodePath
  node.title = <div
    title={node?.alias || node.nodeName}
    style={{ display: 'inline-block', width: 120, marginBottom: '-6px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
  >{node?.alias || node.nodeName}</div>
    node.selectable = node.nodeType !== 'connectionGroup'
    node.value = node.nodePath
    node.icon =  <Iconfont  
      style={ node.nodeType === 'connection' ? { } : { color: '#3f84e9' }}  
      type={node.nodeType !== 'connection' ? 'icon-folder-fill': `icon-connection-${node.connectionType}`} />
    if (node.children) {
      node.children.forEach((child: any) => setTreeNodeKey(child))
    }
  }
