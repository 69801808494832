import React, { FC } from 'react'
import {
  Switch,
  Route,
} from 'react-router'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { Layout } from 'antd'
import classNames from 'classnames'
import { List } from './List'
import { Apply } from './apply'
import styles from './index.module.scss'

const { Content } = Layout

interface ListProps {}

const updateDataRoutes = [
  {
    pathname: "/data_change_mine_apply",
    children: (
      <ErrorBoundary>
        <List />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/data_change_mine_apply/apply",
    children: (
      <ErrorBoundary>
        <Apply />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/data_change_mine_apply/:id/detail",
    children: (
      <ErrorBoundary>
        <Apply />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/data_change_mine_apply/:editId/edit",
    children: (
      <ErrorBoundary>
        <Apply />
      </ErrorBoundary>
    ),
  },
];

export const MyApplicationPage: FC<ListProps> = ({ ...rest }) => {

  return (
    <Layout className="cq-container update-data">
      <Layout className="cq-main">
        <Content
          className={classNames('cq-content', { [styles['change-request-page']]: true })}
        >
          <Switch>
            {updateDataRoutes.map(({ pathname, children }) => (
              <Route
                exact
                path={pathname}
                key={pathname}
                render={() => children}
              />
            ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}
