import React, { useMemo, useState } from 'react'
import { useQuery, useRequest, useSelector } from 'src/hook'
import { Select } from 'antd'
import { getUserConnections, getUserPerms, getPermTypes } from 'src/api'
import { PermType, UserPermsColumns } from 'src/constants'
import { useHistory } from 'react-router-dom'
import { ResizeTable } from 'src/components'

export const UserPermsPage = () => {
  const [permType, setPermType] = useState<PermType | undefined>()

  const {
    userInfo: { userId },
  } = useSelector((state) => state.login)

  const {
    data: userConnections,
    loading: loadingGetUserConnections,
  } = useRequest(() => getUserConnections(userId!))

  const { data } = useRequest(getPermTypes, {
    formatResult: (data) =>
      data?.map((it) => ({ label: it.message, value: it.logo })),
  })

  const ConnectionOptions = userConnections?.map(
    ({ connectionName: label, connectionId: value }) => ({ label, value }),
  )
  const PermTypeOptions = data || []

  // search params
  const history = useHistory()
  const search = useQuery()
  const connectionId = useMemo(
    () => Number(search.get('c')) || ConnectionOptions?.[0].value,
    [ConnectionOptions, search],
  )

  const { data: userPerms, loading: loadingGetUserPerms } = useRequest(
    async () => {
      if (!connectionId) return
      return getUserPerms({
        connectionId,
        permType,
        condition: '',
      })
    },
    {
      refreshDeps: [connectionId, permType],
    },
  )

  return (
    <div className="page-content page-content-scroll">
      <h1>个人权限</h1>
      <div style={{ marginBottom: 16 }}>
        <span>连接：</span>
        <Select
          style={{ width: 144 }}
          size="small"
          value={connectionId}
          onChange={(id) => history.replace({ search: `c=${id}` })}
          options={ConnectionOptions}
          loading={loadingGetUserConnections}
          placeholder="请选择连接"
          showSearch
        ></Select>

        <span className="ml8">权限类型：</span>
        <Select
          style={{ width: 144 }}
          size="small"
          value={permType}
          onChange={setPermType}
          options={PermTypeOptions}
          showSearch
          allowClear
        ></Select>
      </div>
      <ResizeTable
        dataSource={userPerms}
        columns={UserPermsColumns}
        size="small"
        loading={loadingGetUserPerms}
        pagination={{ hideOnSinglePage: true }}
        rowKey={(record) => record.permissionId}
      ></ResizeTable>
    </div>
  )
}
