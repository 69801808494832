import React from 'react'
import styles from './sider.module.scss'

interface ResultSiderProps {
  children: React.ReactText
}

export const ResultSider: React.FC<ResultSiderProps> = ({ children }) => {
  return (
    <div className={styles.rowCountSider}>
      <span className={styles.rowCountText}>{children}</span>
    </div>
  )
}
