import React from "react";
import { useDispatch, useSelector } from "src/hook";
import { Switch, Tooltip, Row, Col } from "antd";
import { setSdtPermissionStatus } from "src/store/extraSlice/sdtPermissionSlice";

export const SdtPermissionSwitch = ({
  onChange
}: {
  onChange: () => void;
}) => {

  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.sdtPermission);
  return (
    <Tooltip
      placement="bottomRight"
      title={
        <Row>
          <Col>开启： 只展示有权限的数据库对象</Col>
          <Col>
            关闭：
            展示所有数据库对象
          </Col>
        </Row>
      }
    >
      <Switch
        defaultChecked={status}
        onChange={(checked) => {
          dispatch(setSdtPermissionStatus(checked));
          onChange();
        }}
        size="small"
        style={{
          margin: 4,
        }}
      />
    </Tooltip>
  );
};
