import { fetchGet, fetchPost } from './customFetch'
import { SqlType } from '../types'

/**
 * @description: 按时间维度统计规则执行数
 * @param {number} beginTimeMs: 开始时间(ms)
 * @param {number} endTimeMs: 结束时间(ms)
 * @param {string} ruleNames: 规则类型
 * @param {string} unit 时间维度
 * @return {*} ruleAmount: 执行sql日志总数, ruleAuthorityAmount: 越权操作日志总数, ruleOperateAmount: 异常用户日志总数
 */
export type AlarmUnit = 'MONTH' | 'DAY' | 'HOUR'
export interface HighRistCountRequest {
  beginTimeMs: number
  endTimeMs: number
  unit: AlarmUnit,
  ruleNames?: string[]
}

interface highRiskPerUnit {
  unit: string
  amount: number,
  ruleName: string
}

export const getHighRiskCountPerUnit = (
  params: HighRistCountRequest,
): Promise<highRiskPerUnit[]> => {
  return fetchPost(`/audit/display/alert_report/get_alert_report`, params)
}

/**
 * @description: 应用告警查询规则次数
 * @params {}
 * @return {*} ruleAmount: 执行sql日志总数, ruleAuthorityAmount: 越权操作日志总数, ruleOperateAmount: 异常用户日志总数
 */
export interface alarmOverview {
  ruleAmount: number, 
	ruleAuthorityAmount: number, 
	ruleOperateAmount: number
}

export const getAlarmOverview = (): Promise<alarmOverview> => {
  return fetchGet(`/audit/display/alert_report/get_rule_count`)
}


/**
 * @description: 主机状态
 * @param {*} 
 * @return {*} cpu_usage: 0.6, disk_usage: 0.43, memory_usage: 0.51
 */
 export interface hostStatus {
  ccpuCoreSize: number,
	cpuUsage: number,
	diskSize: number,
	diskUsage: number,
	memorySize: number,
	memoryUsage: number
}

export const getHostStatus = (): Promise<hostStatus> => {
  return fetchPost(`/audit/display/alert_report/show_host_status`)
}

/**
 * @description: 查询高危动作告警明细
 */
 export interface AlertLogRequest {
  /** 数据库类型 */
  dbTypes: string[],
  /** 执行开始时间(ms) */
	executeBeginMs: number,
  /** 执行结束时间(ms) */
	executeEndMs: number,
  /** 执行人 */
	executors: string[],
  /** 查询返回行数 */
	limit: number,
  /** 开始下标 */
	offset: number,
  /** 危险等级 */
	riskLevels: string[],
  /** 规则名称 */ 
	ruleNames: string[],
	sqlTypes: SqlType[]
}

interface AlertLogResponse {
  data: string[]
  limit: number
  offset: number
  total: number
}

export const getHighRiskReportDetail = (
  params: AlertLogRequest,
): Promise<AlertLogResponse> => {
  return fetchPost(`/audit/display/alert_report/load_alert_log`, params)
}

/**
 * @description: 导出高危动作报告
 */
 export const exportHighRiskReports = (
  params: Partial<AlertLogRequest>,
) => {
  return fetchPost(`/audit/display/alert_report/export_alert_log_sql`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

/**
 * @description: 查询异常用户告警明细
 */

export interface AbnormalUserRequest {
  /** 执行开始时间(ms) */
  executeBeginMs: number,
  /** 执行结束时间(ms) */
	executeEndMs: number,
	/** 执行人 */
	executors: string[],
	/** 查询返回行数 */
	limit: number,
  /** 开始下标 */
	offset: number,
  /** 危险等级 */
	riskLevels: string[],
	/** 规则名称 */ 
	ruleNames: string[],
  /** 动作名称 */ 
  operateNames: string[]
}

export const getAbnormalUserReportDetail = (
  params: AlertLogRequest,
): Promise<AlertLogResponse> => {
  return fetchPost(`/audit/display/alert_report/load_alert_operate_log`, params)
}

/**
 * @description: 导出异常用户报告
 */
 export const exportAbnormalUserReports = (
  params: Partial<AlertLogRequest>,
) => {
  return fetchPost(`/audit/display/alert_report/export_alert_user_log`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}

/**
 * @description: 查询越权操作告警明细
 */
export interface AlertAuthorityRequest {
  /** 数据库类型 */
  dbTypes: string[],
	/** 执行开始时间(ms) */
	executeBeginMs: number,
  /** 执行结束时间(ms) */
	executeEndMs: number,
  /** 执行人 */
	executors: string[],
  /** 查询返回行数 */
	limit: number,
  /** 开始下标 */
	offset: number,
	sqlTypes: SqlType[]
}

interface AlertAuthorityReponse {
  data: string[]
  limit: number
  offset: number
  total: number
}

export const getAlertAuthorityReportDetail = (
  params: AlertAuthorityRequest,
): Promise<AlertAuthorityReponse> => {
  return fetchPost(`/audit/display/alert_report/load_alert_authority_log`, params)
}

/**
 * @description: 导出越权操作报告
 */
export const exportAlertAuthorityReports = (
  params: Partial<AlertAuthorityRequest>,
) => {
  return fetchPost(`/audit/display/alert_report/export_alert_authority_log`, {
    ...params,
    limit: 0,
    offset: 0,
  })
}