import { store, persistor } from './index'

// This is some bit of hack code in order to fix circular references between reducers and fetch api.
// To access store outside redux, use storeRegister.getStore, so does persistor.
// Another way to fix this may be separating thunk actions from reducer slice file.
// https://github.com/reduxjs/redux-toolkit/issues/167
// https://github.com/react-navigation/react-navigation/issues/362#issuecomment-294306592

type AppStore = typeof store | null
type AppPersistor = typeof persistor | null

class StoreRegister {
  store: AppStore = null

  persistor: AppPersistor = null

  register(store: AppStore, persistor: AppPersistor) {
    this.store = store
    this.persistor = persistor
  }

  getStore() {
    return this.store
  }

  getPersistor() {
    return this.persistor
  }
}

export const storeRegister = new StoreRegister()
