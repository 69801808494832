import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRequest, useSelector, useDispatch } from 'src/hook'
import { Form, Input, message, Transfer, TreeSelect } from 'antd'
import { Iconfont, UIModal } from 'src/components'
import { FormInstance } from 'antd/lib/form'
import {
  SdtNodeType,
  getPermsByNodeType,
  getConnectionTree,
  addPermByParams,
} from 'src/api'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { PermRecordContext } from '../contexts/PermRecordContext'
import { ConnectionContext } from '../contexts/ConnectionContext'
import { nameValidator } from 'src/util/nameValidator'
import { FormLayout } from 'src/constants'
import styles from './overrideTreeNodeWrapper.module.scss'

type IFormContext = {
  /** antd form instance */
  form?: FormInstance
  /** nodePath => node */
  treeNodeMap: Map<string, any>
}
const FormContext = React.createContext<IFormContext>({
  treeNodeMap: new Map(),
})

interface NodeTreeSelectProps {
  value?: string[]
  onChange?: (value: any) => void
}

/** 数据库资源树选择组件 */
const NodeTreeSelect: React.FC<NodeTreeSelectProps> = ({ value, onChange }) => {
  const { treeNodeMap } = useContext(FormContext)
  const { connection } = useContext(ConnectionContext)

  const [treeLoadedKeys, setTreeLoadedKeys] = useState<React.ReactText[]>([])
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<React.ReactText[]>(
    [],
  )

  // 判断已选 node 是否都是属于同类型
  // const nodeTypeList = value?.map((nodePath) => {
  //   return treeNodeMap.get(nodePath)?.nodeType
  // })
  // const currentType = nodeTypeList ? nodeTypeList[0] : undefined
  // const hasMultiType = nodeTypeList?.some((type) => type !== currentType)

  // 遍历传入的树节点, map 成 treeData 的格式, 并且将 nodePath => node 的键值对存入 treeNodeMap
  const loops = useCallback(
    (list?: any[]): any[] | undefined => {
      if (!list) return
      const mappedList = list.map((originNode) => {
        const {
          nodeName,
          nodePath,
          nodeType,
          connectionType,
          children,
          hasChild,
        } = originNode

        let icon = `icon-${nodeType}`
        if (nodeType === 'connection') {
          icon = `icon-connection-${connectionType}`
        }

        const node = {
          ...originNode,
          key: nodePath,
          value: nodePath,
          title: nodeName,
          icon: <Iconfont type={icon} />,
          isLeaf: !hasChild,
          children: loops(children),
        }
        treeNodeMap.set(nodePath, node)
        return node
      })
      return mappedList
    },
    [treeNodeMap],
  )

  const { data: root, mutate } = useRequest(
    () =>
      new Promise<any[] | undefined>((resolve, reject) => {
        if (connection) {
          const {
            connectionId,
            connectionType,
            connectionName,
            nodeType,
          } = connection
          resolve(
            loops([
              {
                connectionId,
                connectionType,
                nodeType: nodeType as SdtNodeType,
                nodeName: connectionName,
                nodePath: `/root/${connectionId}`,
                hasChild: true,
              },
            ]),
          )
        } else {
          reject()
        }
      }),
  )

  const { run: loadData } = useRequest(getConnectionTree, {
    manual: true,
    fetchKey: ({ nodePath }) => nodePath || '',
    formatResult: loops,
  })

  return (
    <TreeSelect
      dropdownClassName={styles.treeSelectDropdown}
      treeData={root}
      loadData={async (node) => {
        const {
          connectionId,
          connectionType,
          nodeType,
          hasChild,
          nodeName,
          nodePath,
        } = node
        if (!treeNodeMap.get(nodePath)) {
          treeNodeMap.set(nodePath, node)
        }
        const targetNode = treeNodeMap.get(nodePath)
        try {
          const branch = await loadData({
            connectionId,
            connectionType,
            nodeType,
            hasChild,
            nodeName,
            nodePath,
          })
          mutate((root) => {
            if (!root) return
            targetNode.children = loops(branch)
            return [...root]
          })
          setTreeLoadedKeys([...treeLoadedKeys, nodePath])
        } catch {
          setTreeExpandedKeys(
            treeExpandedKeys.filter((key) => key !== nodePath),
          )
        }
      }}
      treeLoadedKeys={treeLoadedKeys}
      treeExpandedKeys={treeExpandedKeys}
      onTreeExpand={(expandedKeys) => {
        setTreeExpandedKeys(expandedKeys)
      }}
      multiple
      value={value}
      placeholder="请选择同类型元素"
      onChange={(value) => {
        onChange && onChange(value)
      }}
      treeNodeFilterProp="title"
      showSearch
      treeIcon
      maxTagCount={2}
      maxTagTextLength={10}
      allowClear
      showCheckedStrategy="SHOW_PARENT"
      treeCheckable
      treeDefaultExpandedKeys={
        connection ? [`/root/${connection.connectionId}`] : undefined
      }
    />
  )
}

interface PermsTransferProps {
  value?: string[]
  onChange?: (value: any) => void
  nodeType?: SdtNodeType
}

/** 权限选择穿梭框组件 */
const PermsTransfer: React.FC<PermsTransferProps> = ({
  value,
  onChange,
  nodeType,
}) => {
  const { connection } = useContext(ConnectionContext)
  const { data, run } = useRequest(getPermsByNodeType, {
    manual: true,
    formatResult: (data) =>
      data.map(({ opName }) => ({ key: opName, title: opName })),
  })

  useEffect(() => {
    if (connection && nodeType) {
      const { connectionId, connectionType } = connection
      run({ connectionId, dataSourceType: connectionType, nodeType })
    }
  }, [connection, nodeType, run])

  return (
    <Transfer
      dataSource={nodeType && data}
      targetKeys={value}
      onChange={(targetKeys) => {
        onChange && onChange(targetKeys)
      }}
      render={(record) => record.title || record.key}
      showSearch
      titles={['', '操作权限']}
      listStyle={{ minHeight: 360, width: 240 }}
      style={{ justifyContent: 'center' }}
    />
  )
}

export const ModalConnEditDataSourcePerm: React.FC = () => {
  const visible = useSelector(
    (state) => state.modal.ModalConnEditDataSourcePerm,
  )
  const dispatch = useDispatch()
  const treeNodeMapRef = useRef(new Map())
  const [form] = Form.useForm<{
    name: string
    description?: string
    targetNodes: string[]
    perms: string[]
  }>()

  const { connection } = useContext(ConnectionContext)
  const { mode, record, refreshPerms } = useContext(PermRecordContext)
  const isEditing = mode === 'edit'

  const { run: addPerm, loading: loadingAdd } = useRequest(addPermByParams, {
    manual: true,
    onSuccess: () => {
      message.success('添加成功')
      dispatch(hideModal('ModalConnEditDataSourcePerm'))
      refreshPerms && refreshPerms()
    },
  })

  return (
    <UIModal
      title={mode === 'add' ? '新增数据操作权限' : '编辑数据操作权限'}
      visible={visible}
      onOk={() => form.submit()}
      onCancel={() => {
        dispatch(hideModal('ModalConnEditDataSourcePerm'))
      }}
      afterClose={() => form.resetFields()}
      confirmLoading={loadingAdd}
    >
      <FormContext.Provider
        value={{ form, treeNodeMap: treeNodeMapRef.current }}
      >
        <Form
          name="dataSourcePerm"
          form={form}
          onFinish={(values) => {
            if (!connection) return
            const { connectionType } = connection
            const { name, targetNodes, perms, description } = values
            const objType = treeNodeMapRef.current.get(targetNodes[0])?.nodeType
            addPerm({
              dataSourceType: connectionType,
              name,
              description,
              objNames: targetNodes,
              objType,
              operations: perms,
              permissionType: 'dataSource',
            })
          }}
          {...FormLayout}
        >
          <Form.Item
            name="name"
            label="权限名称"
            {...(!isEditing && {
              rules: [
                { required: true, message: '请输入权限名称' },
                { validator: nameValidator },
              ],
            })}
          >
            <Input readOnly={isEditing} bordered={!isEditing} maxLength={50} />
          </Form.Item>
          <Form.Item name="description" label="权限描述">
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 2 }}
              maxLength={200}
            />
          </Form.Item>
          <Form.Item
            name="targetNodes"
            label="数据库元素"
            required
            rules={[
              () => ({
                validator(_rule, value) {
                  if (value?.length) {
                    const nodeTypeList: (
                      | SdtNodeType
                      | undefined
                    )[] = (value as any[]).map(
                      (nodePath: string) =>
                        treeNodeMapRef.current.get(nodePath)?.nodeType,
                    )
                    const currentType = nodeTypeList[0]
                    const hasMultiType = nodeTypeList.some(
                      (type) => type !== currentType,
                    )
                    if (hasMultiType) {
                      return Promise.reject('只能选择同类型元素')
                    } else {
                      return Promise.resolve()
                    }
                  } else {
                    return Promise.reject('请选择数据库元素')
                  }
                },
              }),
            ]}
          >
            <NodeTreeSelect />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => {
              // 如果选中的数据库资源列表 nodeType 改变, 则重新渲染权限字段
              if (prev.targetNodes && next.targetNodes) {
                const prevNodeType = treeNodeMapRef.current.get(
                  prev.targetNodes[0],
                )?.nodeType
                const nextNodeType = treeNodeMapRef.current.get(
                  next.targetNodes[0],
                )?.nodeType
                return prevNodeType !== nextNodeType
              }
              return true
            }}
          >
            {({ getFieldValue }) => {
              const targetNodes = getFieldValue('targetNodes') || []
              const nodeType = treeNodeMapRef.current.get(targetNodes[0])
                ?.nodeType
              return (
                <Form.Item
                  name="perms"
                  rules={[{ required: true, message: '请选择操作权限' }]}
                  wrapperCol={{ span: 24 }}
                >
                  <PermsTransfer
                    nodeType={mode === 'add' ? nodeType : record?.nodeType}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Form>
      </FormContext.Provider>
    </UIModal>
  )
}
