import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import {
  Card,
  message,
  Form,
  Typography,
  Switch,
  Tooltip,
  InputNumber,
  Radio,
  Input,
  Checkbox,
  Button,
} from 'antd'
import { QuestionCircleOutlined, ExclamationCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { useRequest } from 'src/hook'
import { FormInstance } from 'antd/lib/form'
import { LinkButton } from 'src/components'
import { SettingFormContext } from '../SettingFormContext'
import { getSysPasswordPolicy, updateSysPasswordPolicy } from 'src/api'
import styles from './index.module.scss'
import { passwordSpecialCharacterValidator, passwordValidator, passwordValidatorSelf } from 'src/util/nameValidator'

const { Text } = Typography

export const PasswordCard = () => {
  const [editing, setEditing] = useState(false)
  const [isSecretPassword, setIsSecretPassword] = useState(true)
  const [messageDayFlag, setMessageDayFlag] = useState(false)
  const form = useContext(SettingFormContext) as FormInstance

  const { loading, run } = useRequest(getSysPasswordPolicy, { manual: true })
  const [settingData, setSettingData] = useState<any>();
  const [userPasswordStrong, setUserPasswordStrong] = useState<string>('systemBuiltPwdStrong')

  // 保存[登出]状态
  // useEffect(() => {
  //   if (settingData) {
  //     dispatch(setExitPrompt(settingData[8] as boolean))
  //   }
  // }, [dispatch, settingData])

  const checkValue = (arr: any) => {
    let valueArr: any = [];
    for (let key in arr) {
      if (arr.hasOwnProperty(key)) {
        if (arr[key]) {
          valueArr[valueArr.length] = key;
        }
      }
    }
    return valueArr;
  }

  const setFormVal = useCallback(() => {
    if (settingData) {
      form.setFields([
        {
          name: 'maxDay',
          value: settingData?.maxDay,
          errors: undefined,
        },
        {
          name: 'noRepeatCount',
          value: settingData?.noRepeatCount,
          errors: undefined,
        },
        {
          name: 'expireWarning',
          value: settingData?.expireWarningDay ? true : false,
        },
        {
          name: 'expireWarningDay',
          value: settingData?.expireWarningDay,
          errors: undefined,
        },
        {
          name: 'maxFailureCount',
          value: settingData?.maxFailureCount,
          errors: undefined,
        },
        {
          name: 'lockoutMinute',
          value: settingData?.lockoutMinute,
          errors: undefined,
        },
        {
          name: 'systemPasswordStrong',
          value: settingData?.systemPasswordStrong,
          errors: undefined,
        },
        {
          name: 'passwordMin',
          value: settingData?.passwordMin,
          errors: undefined,
        },
        {
          name: 'passwordMax',
          value: settingData?.passwordMax,
          errors: undefined,
        },
        {
          name: 'pwdInclude',
          value: checkValue(
            {
              "containDigits": settingData?.containDigits,
              "containUpperLetters": settingData?.containUpperLetters,
              "containLowerLetters": settingData?.containLowerLetters,
              "containSymbols": settingData?.containSymbols,
            }),
          errors: undefined,
        },
        {
          name: 'defaultPassword',
          value: settingData?.defaultPassword,
          errors: undefined,
        },
      ])
      setMessageDayFlag(settingData?.expireWarningDay ? true : false)
    }
  }, [form, settingData])

  useEffect(() => {
    setFormVal()
  }, [settingData, setFormVal, form])

  let param: string[] = [];
  useEffect(() => {
    run().then(res => {
      setSettingData(res);
      if (res.systemPasswordStrong || !Object.keys(res).includes("systemPasswordStrong")) {
        setUserPasswordStrong('systemBuiltPwdStrong')
      } else {
        setUserPasswordStrong('customization')
      }
    })
  }, [])

  useEffect(() => {
    if (userPasswordStrong === "customization") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      param = ['passwordMax', 'passwordMin', 'pwdInclude']
    }
  }, [userPasswordStrong])


  const handleSave = useCallback(() => {
    Modal.confirm({
      content: '修改 "配置用户密码有效期(天)" 建议重置一次密码，如已重置，请忽略！',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        form
          .validateFields([
            'maxDay',
            'noRepeatCount',
            'expireWarning',
            'expireWarningDay',
            'maxFailureCount',
            'lockoutMinute',

            'userPwdStrong',
            ...param,
            'defaultPassword',
          ])
          .then((vals) => {
            let newParams: any
            if (userPasswordStrong === 'customization') {
              newParams = {
                containDigits: vals?.pwdInclude.includes('containDigits') ? true : false,
                containUpperLetters: vals?.pwdInclude.includes('containUpperLetters') ? true : false,
                containLowerLetters: vals?.pwdInclude.includes('containLowerLetters') ? true : false,
                containSymbols: vals?.pwdInclude.includes('containSymbols') ? true : false,
              }
            } else {
              newParams = {}
            }

            let params = {
              ...vals,
              expireWarningDay: vals?.expireWarning ? vals?.expireWarningDay : 0,

              systemPasswordStrong: userPasswordStrong === 'systemBuiltPwdStrong' ? true : false,
              ...newParams,

            } as any
            delete params.expireWarning
            delete params.userPwdStrong
            delete params.pwdInclude


            updateSysPasswordPolicy(params).then((val) => {
              message.success('修改成功')
              run().then(res => {
                setSettingData(res);
                if (res.systemPasswordStrong) {
                  setUserPasswordStrong('systemBuiltPwdStrong')
                } else {
                  setUserPasswordStrong('customization')
                }
              })
            })
              .catch(() => { })
              .finally(() => setEditing(false))
          })
        },
        onCancel() {
          // logout()
        },
      });
    }, [form, run, userPasswordStrong])
    
  // 用户密码强度radio
  const onChange = (e: any) => {
    const radioVal = e.target.value;
    setUserPasswordStrong(radioVal);
  };

  // 密码中需包含checkbox
  const options = [
    { label: '数字', value: 'containDigits' },
    { label: '大写字母', value: 'containUpperLetters' },
    { label: '小写字母', value: 'containLowerLetters' },
    { label: '特殊字符', value: 'containSymbols' },
  ];


  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              setFormVal()
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
      )}
    </div>
  )

  const renderCustomization = useMemo(() => {
    return (
      <>
        <Form.Item label="长度下限" name="长度下限" rules={[{ required: true }]}>
          <Form.Item
            name="passwordMin"
            hidden={!editing}
            noStyle
            rules={[
              {
                required: true,
                min: 1,
                max: 19,
                message: '请输入',
                type: 'number',
                pattern: /^[1-9][1-9]*$/,
              },
            ]}
          >
            <InputNumber
              min={1}
              max={19}
              placeholder="> 0"
              style={{ width: '18%' }}
              precision={0}
            />
          </Form.Item>
          {!editing && (
            //@ts-ignore
            <Text>{settingData && settingData?.passwordMin}</Text>
          )}
        </Form.Item>
        <Form.Item label="长度上限" name="长度上限" rules={[{ required: true }]}>
          <Form.Item
            name="passwordMax"
            hidden={!editing}
            noStyle
            dependencies={['passwordMin']}
            rules={[
              {
                required: true,
                min: 2,
                max: 20,
                message: '请输入大于0的数值，并且该数值大于下限值',
                type: 'number',
                pattern: /^[1-9][1-9]*$/,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('passwordMin') < value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('上限必须大于下限!'));
                },
              }),
            ]}
          >
            <InputNumber
              min={2}
              max={20}
              placeholder="> 下限"
              style={{ width: '18%' }}
              precision={0}
            />
          </Form.Item>
          {!editing && (
            //@ts-ignore
            <Text>{settingData && settingData?.passwordMax}</Text>
          )}
        </Form.Item>
        <Form.Item label="密码中需包含" name="密码中需包含" rules={[{ required: true }]} >
          <Form.Item
            name="pwdInclude"
            noStyle
            rules={[
              {
                required: true,
                message: "请选择",
              },
            ]}
          >
            <Checkbox.Group disabled={!editing} options={options} />
          </Form.Item>
        </Form.Item>
      </>
    )
  }, [editing, options, settingData]);

  const renderPasswordRules = useMemo(() => {
    if (userPasswordStrong === 'systemBuiltPwdStrong') {
      return [{ validator: passwordValidator }, { validator: passwordSpecialCharacterValidator }]
    } else if (userPasswordStrong === 'customization') {
      return [({ getFieldValue }: { getFieldValue: Function }) => ({
        validator(_rule: any, value: string, callback: any) {
          passwordValidatorSelf(_rule, value, callback,
            {
              passwordMax: getFieldValue('passwordMax') ? getFieldValue('passwordMax') : 0,
              passwordMin: getFieldValue('passwordMin') ? getFieldValue('passwordMin') : 0,
              containDigits: getFieldValue('pwdInclude')?.includes('containDigits') ? true : false,
              containUpperLetters: getFieldValue('pwdInclude')?.includes('containUpperLetters') ? true : false,
              containLowerLetters: getFieldValue('pwdInclude')?.includes('containLowerLetters') ? true : false,
              containSymbols: getFieldValue('pwdInclude')?.includes('containSymbols') ? true : false,
            })
        },
      }),]
    }
  }, [userPasswordStrong])

  return (
    <section className="cq-new-card flow-card" id="PasswordCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">密码策略</h3>
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          <Form.Item label={
            <>
              用户密码有效期(天)
              <Tooltip title="修改此配置之前，建议所有用户重置一次密码">
                <ExclamationCircleOutlined style={{ paddingLeft: 5, color: '#ff4d4f' }} />
              </Tooltip>
            </>
          }>
            <Form.Item
              name="maxDay"
              noStyle
              hidden={!editing}
              rules={[{ 
                min: 1,message: '请输入1及以上的有效数值', type: 'number',
                transform: (v) => Number(v)
              }]}
            >
              <InputNumber 
                placeholder="请输入密码有效期" 
                min={1}
                style={{ width: '100%' }}
                precision={0}
              />
            </Form.Item>
            {!editing && <Text>{settingData && settingData?.maxDay}</Text>}
          </Form.Item>
          <Form.Item
            label={
              <>
                用户密码重复周期(次)
                <Tooltip title="设定周期内不允许重复修改为相同密码">
                  <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            }
          >
            <Form.Item
              name={'noRepeatCount'}
              noStyle
              hidden={!editing}
              rules={[
                { 
                min: 1,max: 10,message: '请输入1-10有效数值', type: 'number',
                transform: (v) => Number(v)
                }]}
            >
              <InputNumber
                min={0}
                max={10}
                placeholder="请输入用户密码重复周期次数"
                style={{ width: '100%' }}
                precision={0}
              />
            </Form.Item>
            {!editing && (
              <Text>{settingData && settingData?.noRepeatCount}</Text>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                是否开启密码到期提示
                <Tooltip title="开关关闭则不提示密码到期消息">
                  <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            }
          >
            <Form.Item
              name="expireWarning"
              hidden={!editing}
              noStyle
              valuePropName="checked"
            >
              <Switch
                onChange={(val) => {
                  form.setFieldsValue({
                    expireWarningDay: val
                      ? Math.max(settingData?.expireWarningDay, 1)
                      : 0,
                  })
                  setMessageDayFlag(val)
                }}
              />
            </Form.Item>
            {!editing && (
              <Text>
                {settingData && settingData?.expireWarningDay ? '开启' : '关闭'}
              </Text>
            )}
          </Form.Item>
          <Form.Item label="密码到期前提示消息(天)">
            <Form.Item
              name="expireWarningDay"
              hidden={!editing}
              noStyle
              rules={[
                {
                  min: messageDayFlag ? 1 : 0,
                  max: 15,
                  message: '请输入1-15有效数值', 
                  type: 'number',
                  transform: (v) => Number(v)
                },
              ]}
            >
              <InputNumber
                min={messageDayFlag ? 1 : 0}
                max={15}
                placeholder="请输入密码到期提示天数"
                style={{ width: '100%' }}
                precision={0}
                disabled={editing ? (messageDayFlag ? false : true) : false}
              />
            </Form.Item>
            {!editing && (
              //@ts-ignore
              <Text>{settingData && settingData?.expireWarningDay}</Text>
            )}
          </Form.Item>
          {/* 导出任务数 */}
          <Form.Item label="锁定账号输入次数">
            <Form.Item
              name="maxFailureCount"
              hidden={!editing}
              noStyle
              rules={[{ 
                min: 1,message: '请输入1及以上的有效数值', type: 'number',
                transform: (v) => Number(v) }]}
            >
              <InputNumber
                min={1}
                placeholder="请输入锁定账号输入次数"
                style={{ width: '100%' }}
                precision={0}
              />
            </Form.Item>
            {!editing && (
              //@ts-ignore
              <Text>{settingData && settingData?.maxFailureCount}</Text>
            )}
          </Form.Item>
          <Form.Item label="账号锁定时长(分钟)">
            <Form.Item
              name="lockoutMinute"
              hidden={!editing}
              noStyle
              rules={[
                {
                  min: 1,
                  max:999999,
                  message: '请输入1-999999有效数值',
                  type: 'number',
                  pattern: /^[1-9][1-9]*$/,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={999999}
                placeholder="请输入账号锁定时长"
                style={{ width: '100%' }}
                precision={0}
              />
            </Form.Item>
            {!editing && (
              //@ts-ignore
              <Text>{settingData && settingData?.lockoutMinute}</Text>
            )}
          </Form.Item>
          <Form.Item label={"用户密码强度"} name={"用户密码强度"}>
            {editing ?
              <Form
                initialValues={{
                  'userPwdStrong': userPasswordStrong,
                }}
                name="userPwdStrong"
                onFieldsChange={() => { form.validateFields(["defaultPassword"]); }}
              >
                <Form.Item
                  name="userPwdStrong"
                  label="userPwdStrong"
                  hidden={!editing}
                  noStyle
                  rules={[
                    {
                      message: '请选择',
                    },
                  ]}
                >
                  <Radio.Group onChange={onChange} value={userPasswordStrong}>
                    <Radio value={'systemBuiltPwdStrong'}>
                      系统内置密码强度
                      <Tooltip title="密码长度9-16位，需包含数字、大写字母、小写字母、特殊字符">
                        <QuestionCircleOutlined style={{ paddingLeft: 5 }} />
                      </Tooltip>
                    </Radio>
                    <Radio value={'customization'}>自定义</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
              : <Text>
                {settingData && (settingData?.systemPasswordStrong || !Object.keys(settingData).includes("systemPasswordStrong")) ?
                  "系统内置密码强度"
                  : "自定义"}
              </Text>}
          </Form.Item>
          {
            (editing && userPasswordStrong === 'customization') ||
              (!editing && (!settingData?.systemPasswordStrong || !Object.keys(settingData).includes("systemPasswordStrong"))) ?
              renderCustomization
              : <></>
          }
          <Form.Item label="新增用户密码">
            {editing ?
              <Form.Item
                name='defaultPassword'
                hidden={!editing}
                noStyle
                dependencies={['passwordMin', 'passwordMax', 'pwdInclude', 'userPwdStrong']}
                rules={
                  renderPasswordRules
                }
              >
                <Input.Password
                  placeholder="请输入"
                  style={{ width: '100%' }}
                />
              </Form.Item>
              : <Text>
                {
                  isSecretPassword ?
                    <>******<Button type='link' onClick={() => { setIsSecretPassword(false) }}><EyeInvisibleOutlined /></Button></> :
                    <>{settingData?.defaultPassword}<Button type='link' onClick={() => { setIsSecretPassword(true) }}><EyeOutlined /></Button></>
                }
              </Text>}
          </Form.Item>
        </Card>
      </section>
    </section>
  )
}
