import { columns } from './../pageTabs/data-change/workorder-management/columns';
import { MYSQL_COLUMN_TYPES, ORACLE_COLUMN_TYPES, SQLSERVER_COLUMN_TYPES, IMPALA_COLUMN_TYPES, VERTICA_COLUMN_TYPES, HIVE_COLUMN_TYPES, POSTGRESQL_COLUMN_TYPES } from 'src/constants'
import { DataSourceType } from 'src/api'
import React from 'react'
export const getColumnTypeOptions = (connectionType: DataSourceType) => {
    switch (connectionType) {
      case 'Hive':
      case 'Inceptor':
        return HIVE_COLUMN_TYPES
      case 'Impala':
        return IMPALA_COLUMN_TYPES
      case 'Vertica':
        return VERTICA_COLUMN_TYPES    
      case 'Oracle':
      case 'OracleCDB':
        return ORACLE_COLUMN_TYPES
      case 'SQLServer':
        return SQLSERVER_COLUMN_TYPES
      case 'PostgreSQL':
      case 'KingBasePG':
      case 'KingBaseOracle':
        return POSTGRESQL_COLUMN_TYPES
      case 'MySQL':
      default:
        return MYSQL_COLUMN_TYPES
    }
  }
  //xx.xx
  export function generateObjectName(path: string, pointTypes?: string[]): string {
    try {
      const targetType: string[] = pointTypes ||  ['DATABASE', 'SCHEMA', 'TABLE']
  
      const path2Arr = path.split('/').filter((i) => i)
      const result: string[] = []
  
      path2Arr.forEach((item) => {
        const splitArr = item.split(':')
        if (targetType.includes(splitArr[0])) {
          result.push(splitArr[1])
        }
      })
  
      return result.length ? result.join('.') : ''
    } catch (error) {
      return ''
    }
  }
  
///CONNECTION:1/DATABASE:database_name
 export const getPointNodePathFiledValue: any = (path: string, type: string) => {
    if (!type || !path) return;
  
    const typeToUpper = type.toUpperCase();
      const regex = new RegExp(`${typeToUpper}:(\\w+)`);
      const match = path.match(regex);
  
      return match?.[1] ?? null;
  };
  
  export const getExpandNodeNeedPathKeys = (path: string,needSplice?: boolean) => {
    let result = [];
    const parts = path?.split('/') || [];
    let current = '';
    for (let i = 1; i < parts.length; i++) {
      current += '/' + parts[i];
      result.push(current);
    }
    if (needSplice) {
      result.splice(0, 1); // 删除第一个空字符串
    }
   
    return result;
}

  