import React from "react";
import { ConnectionDesensitize } from "./TableView/ConnectionDesens";
import { ConnectionFilterRow } from "./TableView/ConnectionFilterRow";
import { DataInfoTablePage } from "./TableView/DataInfoTable";
import { DatabaseDesensitize } from "./TableView/DatabaseDesensitize";
import { GroupDesensitize } from "./TableView/GroupDesensitize";
import { GroupFilterRow } from "./TableView/GroupFilterRow";
import { RootDesensitize } from "./TableView/RootDesensitize";
import { RootFilterRow } from "./TableView/RootFilterRow";
import { SchemaDesensitize } from "./TableView/SchemaDesensitize";
import { SchemaFilterRow } from "./TableView/SchemaFilterRow";
import { TableDesensitize } from "./TableView/TableDesensitize";
import { TableFilterRow } from "./TableView/TableFilterRow/TableFilterRow";
import { DispatchViewService } from "./ViewService";
import CommonRightWrapper from './CommonRigthWrapper'
import DesensScanWrapper from './desensScan'
import { Config } from "./type";

function initialView() {
  const dispatchInstance = new DispatchViewService();

  // config 以 KV 形式存储 table view
  // 其中 key 为 `${NodeTypeKey}_${TabKey}` 形式，如 'datasource_DESENS'
  // 其中 value 为 JSX.Element
  const config: Config = {
    // 脱敏字段 目前页面只是接口不同，如果以后每个页面差异很大，再考虑单个拆分，不然改动四个页面工作量比较大
    datasource_DESENS: <CommonRightWrapper type='datasource'/>,
    group_DESENS: <CommonRightWrapper type='group'/>,
    connection_DESENS: <CommonRightWrapper type='connection'/>,
    schema_DESENS: <CommonRightWrapper type='schema'/>,
    oracleUser_DESENS: <CommonRightWrapper type='schema'/>,
    database_DESENS: <CommonRightWrapper type='database'/>,
    tableGroup_DESENS: <CommonRightWrapper type='tableGroup' />,
    table_DESENS: <TableDesensitize />,

    // 脱敏数据
    datasource_DATA_INFO: <DataInfoTablePage />,
    group_DATA_INFO: <DataInfoTablePage />,
    connection_DATA_INFO: <DataInfoTablePage />,
    schema_DATA_INFO: <DataInfoTablePage />,
    oracleUser_DATA_INFO: <DataInfoTablePage />,
    database_DATA_INFO: <DataInfoTablePage />,
    tableGroup_DATA_INFO: <DataInfoTablePage />,

    // 行过滤设置
    datasource_FILTER_ROW: <RootFilterRow />,
    connection_FILTER_ROW: <ConnectionFilterRow />,
    group_FILTER_ROW: <GroupFilterRow />,
    schema_FILTER_ROW: <SchemaFilterRow />,
    oracleUser_FILTER_ROW: <SchemaFilterRow />,
    table_FILTER_ROW: <TableFilterRow />,

    //脱敏扫描
    datasource_SCAN: <DesensScanWrapper type="datasource"/>,
    connection_SCAN: <DesensScanWrapper type='connection'/>,
    group_SCAN: <DesensScanWrapper type="group"/>,
    database_SCAN: <DesensScanWrapper type="database"/>,
    schema_SCAN: <DesensScanWrapper type="schema"/>,
    oracleUser_SCAN: <DesensScanWrapper type="schema"/>,
    tableGroup_SCAN:<DesensScanWrapper type="tableGroup"/>,
    table_SCAN: <DesensScanWrapper type='table'/>,
  };

  dispatchInstance.init(config);
  return dispatchInstance;
}

export { initialView };

