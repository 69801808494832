import React, { useEffect } from 'react';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'src/hook';
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice';
import { wwUnBindChat } from 'src/api';

export const WwChatUnBindModal = (props: { refresh: () => void }) => {
  const { refresh } = props;
  const dispatch = useDispatch()

  const visible = useSelector(
    (state) => state.modal.WwChatUnBindModal,
  )

  const handleCancel = () => {
    dispatch(hideModal('WwChatUnBindModal'))
  }

  const handleOk = () => {
    wwUnBindChat().then((res: any) => {
      if (res) {
        message.success('解绑成功');
        refresh();
        handleCancel();
      } else {
        message.success('解绑失败，请联系管理员');
      }
    })
  }

  useEffect(() => {
    if (visible) {
      Modal.confirm({
        title: '确定解除企业微信绑定吗？',
        icon: <ExclamationCircleOutlined />,
        okText: '确认',
        cancelText: '取消',
        onOk: handleOk,
        onCancel: handleCancel
      });
    }
  }, [visible])

  return (
    <></>
  );
}
