import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { QueryLogItem } from 'src/types'
import dayjs from 'dayjs'
import { chunk, cloneDeep } from 'lodash'

type LogQueue = {
  total: number
  logs: QueryLogItem[]
}

type LogsMap = Record<string, LogQueue | undefined>

/** 每个 tab 下保存的最大非错误日志日志数量 */
const MAX_LOGS_PERTAB = 10000

const initialState: LogsMap = {}
export const logsSlice = createSlice({
  name: 'queryLogs',
  initialState,
  reducers: {
    resetLogs: (
      state,
      action: PayloadAction<string | string[] | undefined>,
    ) => {
      let targets = action.payload
      if (typeof targets === 'undefined') {
        return {}
      }
      if (typeof targets === 'string') {
        targets = [targets]
      }
      targets.forEach((queryTabKey) => {
        delete state[queryTabKey]
      })
    },
    addLog: (state, action: PayloadAction<QueryLogItem[]>) => {
      const logItems = action.payload;
      if (logItems && logItems.length) {
        const tabKey = logItems[0].tabKey;
        const tabLogQueue = state[tabKey];

        const tabLogQueueMirror = cloneDeep(tabLogQueue);
        if (!tabLogQueueMirror) {
          logItems.forEach((item, index) => {
            item.logIndex = index + 1;
          });
          state[tabKey] = {total: logItems.length, logs: logItems};
        } else {
          const tabLogs = tabLogQueueMirror.logs;
          const startIndex = (tabLogs[tabLogs.length - 1]?.logIndex ?? 0) + 1;

          logItems.forEach((item, index) => {
            item.logIndex = startIndex + index;
          });

          tabLogQueueMirror.logs.push(...logItems);
          tabLogQueueMirror.total += logItems.length;

          // 维持队列长度
          if (tabLogQueueMirror.logs.length > MAX_LOGS_PERTAB) {
            // 超出限制长度，从头部截取
            tabLogQueueMirror.logs.splice(0, tabLogQueueMirror.logs.length - MAX_LOGS_PERTAB);
          }

          state[tabKey] = tabLogQueueMirror;
        }
      }
    },
  },
})

export const logsReducer = logsSlice.reducer

export const { resetLogs, addLog } = logsSlice.actions
