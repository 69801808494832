import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { useSelector } from 'src/hook'
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {Dropdown, Descriptions, Spin, Menu, message} from 'antd'
import { DatabaseInfo } from '../queryTabsSlice'
import { getInfoFromPath } from 'src/util'
import { openRedisKey } from 'src/api'
import classNames from 'classnames'
import styles from './index.module.scss'
import {GridConfigBase, IRightMenuKey} from '../../resultTabs/resultContentGrid'
import copy from "copy-to-clipboard";
import {GridReadyEvent} from "@ag-grid-community/core";

const GridPaneColDef: AgGridColumnProps = {
  resizable: true,
  tooltipValueGetter: ({ value }) => value,
}

interface GridPaneProps {
  databaseInfo: Partial<DatabaseInfo>
}

export const GridPane: React.FC<GridPaneProps> = ({ databaseInfo }) => {
  const { theme } = useSelector((state) => state.appearance)
  const {
    connectionId,
    connectionType,
    databaseName,
    nodePath,
    nodeName,
    nodeType,
    nodePathWithType
  } = databaseInfo
  const connectionName = getInfoFromPath(nodePath, 'connection')

  const [loadingRows, setLoadingRows] = useState<boolean>(false)
  const [rowData, setRowData] = useState<any[]>([])
  const [columnInfos, setColumnInfos] = useState<any[]>([])
  const columnDefs = columnInfos.map(({ columnName }) => ({
    field: columnName,
  }))

  useEffect(() => {
    if (connectionType === 'Redis') {
      const params = {
        connectionId,
        nodeName,
        nodePath,
        nodeType,
        nodePathWithType
      }
      setLoadingRows(true)
      openRedisKey(params)
        .then(([data]) => {
          const { values, columnInfos } = data
          setRowData(values)
          setColumnInfos(columnInfos)
        })
        .catch(() => {})
        .finally(() => {
          setLoadingRows(false)
        })
    }
  }, [connectionId, connectionType, nodePath, nodeName, nodeType])

  const gridApiRef = useRef<GridReadyEvent | null>(null);
  const handleGridReady = (options: GridReadyEvent): void => {
    gridApiRef.current = options
  }
  /* 获取选中单元格 */
  const getSelectCellData = useCallback(() => {
    const { column } = gridApiRef.current?.api.getFocusedCell()!;
    const { field } = column.getColDef();
    if (!field) return;
    const nodeList = gridApiRef.current?.api.getSelectedNodes();
    if (!nodeList || !nodeList[0]) return [];
    //获取选中的第0行
    const cellData = nodeList[0].data;
    //返回列名是这个的单元格
    console.log(cellData, '---0000');

    return cellData[field];
  }, []);

  /* 单元格复制 */
  const handleCopyCell = useCallback(() => {
    const selectData = getSelectCellData();
    copy(selectData);
  }, [getSelectCellData]);
  const RightMenuHandleMap = useMemo(
    () =>
      ({
        CopyCell: handleCopyCell,
      }) as { [key in IRightMenuKey]: any },
    [handleCopyCell],
  )

  const handleRightMenu = useCallback(
    ({ key }: { key: IRightMenuKey }) => {
      if (key in RightMenuHandleMap) {
        RightMenuHandleMap[key]()
      } else {
        message.error(`右键菜单${key}`)
      }
    },
    [RightMenuHandleMap],
  )

  const allowCopy = useSelector((state) => state.login.userInfo.copySetting)
  const rightMenu = useMemo(() => {
    return (
      <Menu onClick={handleRightMenu as any}>
        <Menu.Divider />
        <Menu.Item key="CopyCell" disabled={!allowCopy}>
          复制单元格
        </Menu.Item>
      </Menu>
    )
  }, [handleRightMenu])

  return (
    <div className={styles.gridPaneWrapper}>
      <Descriptions>
        <Descriptions.Item label="连接名">{connectionName}</Descriptions.Item>
        <Descriptions.Item label="数据库">{databaseName}</Descriptions.Item>
      </Descriptions>

      <div
        className={classNames(
          styles.gridContent,
          theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham',
        )}
      >
        <Dropdown overlay={rightMenu} trigger={["contextMenu"]}>
        <Spin
          spinning={loadingRows}
          wrapperClassName={styles.gridSpinContainer}
        >
          <AgGridReact
            {...GridConfigBase}
            defaultColDef={GridPaneColDef}
            modules={[ClientSideRowModelModule]}
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={handleGridReady}
            onCellFocused={(event) => {
              if (event.rowIndex != null) {

                const rowNode = event.api.getDisplayedRowAtIndex(
                    event.rowIndex,
                )
                event.api.deselectAll() //取消所有选中
                rowNode?.setSelected(true)
              }
            }}
          />
        </Spin>
        </Dropdown>
      </div>
    </div>
  )
}
