import React, { useEffect, useState, useRef } from "react"
import { Drawer, Button } from "antd";
import { Iconfont } from "src/components"
import { useRequest } from "src/hook";
import {
  getUserAuthorizeEnum,
} from "src/api";
import UserAuthorize from "./UserAuthorize"
import AddUserModal from "../AddUserModal"
import SecuritySettingWrapper from '../SecuritySettingDrawer'
import styles from './index.module.scss'
import classnames from "classnames";

/**
 * 权限设置抽屉
 */
interface IProps {
  visible: boolean;
  handleClose: (p?: boolean) => void;
  [p: string]: any;
}

const NewAuthorizeDrawer = (props: IProps) => {

  const { initActiveKey, visible, handleClose, selectItems, permissionlist, selectNodeType } = props;
  const { nodePathWithType, connectionType, nodeType } = selectItems?.[0] || {};
  const nodePathWithTypeList = selectItems?.map((i: any) => i?.nodePathWithType)
  const drawerTitle = selectItems?.map((item: any) => item.objectName)?.join('、')
  const [addModalVisible, setAddModalVisible] = useState(false);
  const userAuthorizeRef = useRef(null)

  // 查询用户权限枚举
  const {
    data: authorizeEnum = [],
    run: queryUserAuthorizeEnum,
  } = useRequest(getUserAuthorizeEnum, {
    manual: true,
  });

  useEffect(() => {
    if (nodeType && connectionType) {
      const params = {
        nodeType,
        dataSourceType: connectionType,
      };
      queryUserAuthorizeEnum(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryUserAuthorizeEnum,
    connectionType,
    nodeType,
  ]);

  // const handleOk = () => {
  //   const callback = () =>  handleClose(true)
  //   if (initActiveKey === "1") {
  //     // @ts-ignore
  //     userAuthorizeRef.current?.updateUserPermissions(callback);
  //   }
  //   if (initActiveKey === "2") {
  //     // @ts-ignore
  //     userAuthorizeRef.current?.onSubmit(callback)
  //   }
  // };

  const refreshUserList = () => {
    // @ts-ignore
    userAuthorizeRef.current?.refreshUserList();
  }

  return (
    <Drawer
      width={1000}
      maskClosable={false}
      visible={visible}
      onClose={()=>handleClose()}
      className={styles.authorizeDrawerWrap}
      title={
        <div className={styles.title}>
          {initActiveKey === "1" ? (<span>用户授权</span>) : (<span>安全设置</span>)}
          <Iconfont className={classnames(styles.ml25, styles.mr5)} type={`icon-${nodeType}`} />
          <span className={styles.name}>{drawerTitle}</span>
        </div>
      }
      footer={false}
      // footer={
      //   initActiveKey === "1" ?
      //   <>
      //     <Button onClick={()=>handleClose()}>取消</Button>
      //     <Button type="primary" className={styles.ml10} onClick={handleOk}>
      //       确定
      //     </Button>
      //   </>
      //   : false
      // }
    >
      {initActiveKey === "1" && (
        <UserAuthorize
          ref={userAuthorizeRef}
          authorizeEnum={authorizeEnum}
          dataSourceType={connectionType}
          nodePathWithTypeList={nodePathWithTypeList}
          selectItems={selectItems}
          permissionlist={permissionlist}
          selectNodeType={selectNodeType}
        />
      )}
      {initActiveKey === "2" && (
        <SecuritySettingWrapper showObject ref={userAuthorizeRef} nodePathWithType={nodePathWithType} nodePathWithTypeList={nodePathWithTypeList}/>
      )}
      {addModalVisible && (
        <AddUserModal
          visible
          handleClose={() => {
            setAddModalVisible(false);
          }}
          refreshList={refreshUserList}
          authorizeEnum={authorizeEnum}
          dataSourceType={connectionType}
          nodePathWithTypeList={nodePathWithTypeList}
          selectNodeType={selectNodeType}
        />
      )}
    </Drawer>
  );
};
export default NewAuthorizeDrawer;