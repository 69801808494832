import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/hook'
import type { QueryLogItem } from 'src/types'
import { Button, Tooltip, Typography, message } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { openFlowForm } from 'src/pageTabs/flowPages'
import { openResultLogForm } from './resultLogSlice'
import Service from 'src/service'
import classNames from 'classnames'
import styles from './index.module.scss'
import { setErrorJump } from '../resultTabsSlice'
import { getCheckSms } from 'src/api';
import { renderErrorFlag } from 'src/util';

export const OberserverContext = React.createContext({
  sizeMap: new Map<number, number>(),
  observer: new ResizeObserver(() => {}),
});

const WasteTime = (props: any) => {
  const { time } = props;
  const timeFormat = time === '-' ? time : `${time}ms`;
  return (
    <div>
      耗时 <QuestionCircleOutlined /> : {timeFormat}
    </div>
  )
};

interface LogMessageProps {
  log: QueryLogItem
  lastMessageId: string | undefined
  index: number
  offset: number
  queryKey: string
}

interface LogMessageProps {
  log: QueryLogItem;
  index: number;
  offset: number;
  queryKey: string;
}

const { Text } = Typography;

export const LogMessage = ({
  log,
  lastMessageId,
  index,
  offset,
  queryKey,
}: PropsWithChildren<LogMessageProps>) => {


  const [connectionId, setConnectionId] = useState<string | number>()
  const [connectionType, setConnectionType] = useState<string>()
  const history = useHistory()
  const {
    timestamp,
    statement,
    error,
    executionWarning,
    duration,
    affectedRows,
    executeError,
    messageId,
    errorFlag
  } = log

  const success = errorFlag === 1
  
  let errorPositionStr = ''
  let errorPosition: {
    endColumn: number
    endLineNumber: number
    startColumn: number
    startLineNumber: number
  }

  if (
    executeError && 
    executeError.position && 
    !(
      executeError.position.startLine === 0 && 
      executeError.position.startCol === 0 &&
      executeError.position.stopLine === 0 &&
      executeError.position.stopCol === 0
    )
  ) {
    errorPositionStr = "(起始行:" + executeError.position.startLine + 
    ",起始列:" + executeError.position.startCol + 
    ",终止行:" + executeError.position.stopLine + 
    ",终止列:" + executeError.position.stopCol + ")"

    errorPosition = {
      'endColumn': executeError.position.stopCol,
      'endLineNumber': executeError.position.stopLine,
      'startColumn': executeError.position.startCol,
      'startLineNumber': executeError.position.startLine
    }
  }


  // 如果日志返回相关信息，则按照返回信息回填表单，否则只回填数据库元素（数据库层级）
  const { paneInfoMap, executeActiveTabParams } = useSelector(
    (state) => state?.queryTabs
  );
  const info = paneInfoMap[queryKey];
  const treeData = useSelector((state) => state.sdt.treeData);

  // 获取连接节点
  const node = treeData.find((t) => t.connectionId === info.connectionId);
  const nodeName = log?.permissionEchoResult?.nodeName;
  const nodePath = log?.permissionEchoResult?.nodePath
    ? `${log.permissionEchoResult.nodePath}`
    : `/root/${connectionId}`;
  const nodePathWithType = log?.permissionEchoResult?.nodePathWithType;
  const nodeType = log?.permissionEchoResult?.nodeType
    ? log.permissionEchoResult.nodeType
    : 'connection';
  const operationType = log?.permissionEchoResult?.operationType
    ? [log.permissionEchoResult.operationType]
    : [];
  const permissionNodes = [
    {
      connectionId,
      connectionType,
      nodeName,
      nodePath,
      nodePathWithType,
      nodeType,
    },
  ] as any;

  useEffect(() => {
    if (node?.connectionId) {
      setConnectionId(node?.connectionId);
    }
    //存储在folder中时候treeData查找不到
    if (info?.connectionId) {
      setConnectionId(info?.connectionId);
    }
  }, [node?.connectionId, queryKey, info?.connectionId]);

  useEffect(() => {
    if (node?.connectionType) {
      setConnectionType(node?.connectionType);
    }
    //存储在folder中时候treeData查找不到
    if (info?.connectionType) {
      setConnectionType(info?.connectionType);
    }
  }, [node?.connectionType, info?.connectionType]);

  const dispatch = useDispatch();

  const permissionButtonRender = useCallback(
    (log: QueryLogItem) => {
      if (!Service.moduleService.isModuleExist('/flow')) {
        return null;
      }
      const {
        permissionLimits,
        statement,
        permissionEchoResult,
      } = log;
      const {
        doubleCheck,
        operationType = '',
        level,
        nodePathWithType,
        connectionType,
        connectionName,
        connectionId
      } = permissionEchoResult || {};

      const permissionData = permissionLimits?.[0];
      if (!permissionData) return null;
      const { permissionType } = permissionData;
      const { flowType, name } = Service.permService.getPermData({
        key: permissionType,
      }) as any;
      if (!flowType) return null;

      const renderOTPBtn = () => {
        return doubleCheck === 'OTP' &&  <Button
          size='small'
          type='link'
          style={{margin:'0 4px'}}
          onClick={() => {
            if (connectionId && connectionType) {
              const values = executeActiveTabParams[queryKey] || {};
              dispatch(
                openResultLogForm({
                  type: 'applyOTPCheck',
                  fields: {
                    ...values,
                    connectionId,
                    statements: [statement],
                    permissionEchoResult,
                    queryTabKey: queryKey
                  },
                })
              );
            }
          }}
        >
          命令复核
         </Button>
      }

      const renderSMSBtn = () => {
        return <Button
          size='small'
          type='link'
          style={{margin:'0 4px'}}
          onClick={() => {
            // 发送验证码
            if (connectionId) {
              getCheckSms({
                  connectionId: connectionId,
                  queryTabKey:queryKey
              }).then((res)=>{
                if (res) {
                  message.success("验证码已发送")
                }
              })
            } else {
              message.success("验证码发送失败")
            }
            
            if (connectionId && connectionType) {
              const values = executeActiveTabParams[queryKey] || {};
              dispatch(
                openResultLogForm({
                  type: 'applySMSCheck',
                  fields: {
                    ...values,
                    connectionId,
                    statements: [statement],
                    permissionEchoResult,
                    queryTabKey: queryKey
                  },
                })
              );
            }
          }}
        >
          短信复核
         </Button>
      }

        //高危 level = 2 走数据订正
        if (
          permissionEchoResult?.permissionType === 'highRiskOperation' &&
          level === 2
        ) {
          return (
            <>
              {doubleCheck === 'OTP' ? renderOTPBtn() : doubleCheck === 'SMS' ? renderSMSBtn() : ''}
              <Button
                type='link'
                style={{margin:'0 4px'}}
                onClick={() =>
                  history.push({
                    pathname: '/data_change_mine_apply/apply',
                    state: {
                      nodePath: nodePathWithType,
                      sqlStatement:statement,
                      dataSourceType: connectionType,
                      connectionName:connectionName,
                      connectionId
                    },
                  })
                }
              >
                申请数据订正
              </Button>
            </>
          );
        }

      return (
        <>
          {doubleCheck === 'OTP' ? renderOTPBtn() : doubleCheck === 'SMS' ? renderSMSBtn() : ''}
         
          {flowType !== 'dataManipulation' && (
            <Button
              onClick={() => {
                if (connectionId && connectionType) {
                  dispatch(
                    openFlowForm({
                      type: flowType,
                      fields: {
                        elements: permissionNodes,
                        operationList: [operationType],
                      },
                    })
                  );
                }
              }}
              size='small'
              type='link'
              style={{margin:'0 4px'}}
            >
              申请{name}
            </Button>
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, connectionId, connectionType]
  );

  // const { sizeMap, observer } = useContext(OberserverContext);

  const contentRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const node = contentRef.current;
  //   if (!node) return;
  //   observer.observe(node);
  //   return () => {
  //     // 组件销毁，停止观测
  //     const index = node.getAttribute('data-index');
  //     sizeMap.delete(Number(index));
  //     observer.unobserve(node);
  //   };
  // }, [observer, sizeMap]);


  const handleJump = () => {
    dispatch(setErrorJump(true))
  }

  const renderPromptContent = () => {
    const childrenCon = permissionButtonRender(log)?.props?.children
    const { permissionEchoResult } = log
    if((childrenCon && typeof childrenCon==='string') || (typeof childrenCon === 'object' && childrenCon?.length && childrenCon?.filter((i: any)=>i)?.length)){
      return (
        <span style={{fontWeight:'bold'}} className={styles.primary}>您可以通过 [{permissionButtonRender(log)}] 来处理</span>
      )
    }
    if(permissionEchoResult){
      return (
        <Text className={styles.text} type='danger'>
          请联系管理员
        </Text>
      )
    }
    return null
  }

  return (
    <div className={styles.singleLog} ref={contentRef} data-index={index}>
      <div className={styles.logInfo}>
        <Text className={classNames(styles.text, styles.primary)} strong>
          [{log?.logIndex}]
        </Text>
        <Text
          className={classNames(
            styles.text,
            styles.secondary,
            styles.timestamp
          )}
          type='secondary'
        >
          [{dayjs(timestamp).format('MM-DD HH:mm:ss')}]
        </Text>
        <Tooltip title={statement} mouseEnterDelay={0.5} placement='topLeft'>
          <Text
            className={classNames(
              styles.text,
              success && styles.primary,
              styles.statement
            )}
            ellipsis
            {...(!success && { type: 'danger' })}
          >
            {
              (messageId === lastMessageId) ? 
              <div onClick={()=>handleJump()}>
                {statement}
              </div>
              :statement
            }
          </Text>
        </Tooltip>
        <Tooltip title='执行耗时为数据库执行 SQL 的耗时'>
          <Text
            className={classNames(styles.text, styles.primary, styles.duration)}
            ellipsis
          >
            {duration === null || duration === undefined ? (
              <WasteTime time='-' />
            ) : (
              <WasteTime time={duration} />
            )}
          </Text>
        </Tooltip>
        <Text
          className={classNames(styles.text, styles.status)}
          type={success ? 'success' : 'danger'}
        >
          {renderErrorFlag(errorFlag)}
        </Text>
        <Text
          className={classNames(
            styles.text,
            styles.primary,
            styles.affectedRows
          )}
          ellipsis
        >
          影响行数：{affectedRows}
        </Text>
      </div>

      {error && (
        <div className={styles.logError}>
          <Text className={classNames(styles.text, styles.secondary)}>
            异常信息：
          </Text>
          <br />
          <Text className={styles.text} type='danger'>
            {error}
          </Text>
          {
            renderPromptContent()
          }
        </div>
      )}
      {executionWarning && (
        <div className={styles.logError}>
          <Text className={classNames(styles.text, styles.secondary)}>
            警告信息：
          </Text>
          <br />
          <Text className={styles.text} type='warning'>
            {executionWarning}
          </Text>
          {permissionButtonRender(log)}
        </div>
      )}
    </div>
  );
};
