import React from 'react'
import { Card } from 'antd'
import styles from './index.module.scss'

export const EmptyCardContent = ({ title }: { title: React.ReactNode }) => {
  return (
    <Card
      title={title}
      bodyStyle={{ height: 104 }}
      className={styles['empty-card']}
    ></Card>
  )
}
