import React from 'react';
import { Button, notification } from 'antd';

export const openNotification = (title: string, des: string, download: () => void, duration?: number) => {
  return notification.success({
    placement: 'bottomLeft',
    duration: duration || null,
    message: title,
    description: (
      <>
        {des}
        <Button
          type='link'
          onClick={() => {
            download()
          }}
        >
          下载
        </Button>
      </>
    ),
  });
};
