
import React, { useRef, useEffect, useState } from 'react';
import { Tooltip } from 'antd';

const TextWithTooltip = ({ text, maxLength =0 }: {
    text: string;
    maxLength?: number;
}) => {
  const containerRef = useRef<any>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedText, setTruncatedText] = useState(text);

  const textAdaptiveWidth = () => {
    const containerWidth = containerRef?.current?.offsetWidth;
    const textWidth = containerRef?.current.scrollWidth;

    if (textWidth > containerWidth) {
      setIsTruncated(true);
      const ratio = containerWidth / textWidth;
      const truncatedLength = Math.floor(text.length * ratio) - 3; // subtracting 3 for ellipsis
      setTruncatedText(`${text.slice(0, truncatedLength)}...`);
    } else {
      setIsTruncated(false);
      setTruncatedText(text);
    }
  }

  const textLimitMaxLength = () => {
    if (text?.length > maxLength) {
        setIsTruncated(true);
        setTruncatedText(`${text.slice(0, maxLength)}...`);
    }else {
        setIsTruncated(false);
    }
  }

  useEffect(() => {
    if (maxLength) {
        textLimitMaxLength()
    }else {
        textAdaptiveWidth()
    }
  }, [text, maxLength]);

  return (
    <div ref={containerRef} style={{ display: 'inline-block', overflow: 'hidden' }}>
      {isTruncated ? (
        <Tooltip title={text}>
          <span style={{cursor: 'pointer`'}}>{truncatedText}</span>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

export default TextWithTooltip;
