import React, { useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { Form, Input, message } from 'antd'
import { refreshOnRoot } from '../sdtSlice'
import { renameSdtNode } from 'src/api'
import { getNodeParams } from 'src/util'

export const RenameSdtNodeModal = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { RenameSdtNode } = useSelector((state) => state.modal)
  const { rightClickedNode } = useSelector((state) => state.sdt)
  const [renameSdtNodeForm] = Form.useForm()
  return (
    <UIModal
      title="重命名"
      visible={RenameSdtNode}
      onCancel={() => dispatch(hideModal('RenameSdtNode'))}
      onOk={() => {
        renameSdtNodeForm.validateFields().then(({ newNodeName }) => {
          const nodeParams = {
            ...getNodeParams(rightClickedNode),
            nodeName: newNodeName,
          }
          setConfirmLoading(true)
          renameSdtNode(nodeParams)
            .then(() => {
              message.success('重命名成功')
              dispatch(hideModal('RenameSdtNode'))
              dispatch(refreshOnRoot())
            })
            .finally(() => setConfirmLoading(false))
        })
      }}
      afterClose={() => renameSdtNodeForm.resetFields()}
      width={320}
      confirmLoading={confirmLoading}
    >
      <Form form={renameSdtNodeForm}>
        <Form.Item
          label="节点名称"
          name="oldNodeName"
          initialValue={rightClickedNode.nodeName}
        >
          <Input disabled></Input>
        </Form.Item>
        <Form.Item
          label="新名称"
          name="newNodeName"
          rules={[{ required: true, message: `节点名称不能为空` }]}
        >
          <Input placeholder="请输入节点名称"></Input>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
