import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Spin, List } from 'antd'
import { useRequest, useSelector } from 'src/hook'
import { getSql } from 'src/api/getNewData'
import chartStyles from './chart.module.scss'
import classnames from 'classnames'

export const SlowSqlChart = () => {
  const history = useHistory();
  const [data, setData] = useState<any>();
  const [dataSource, setDataSource] = useState<any>();

  // const {
  //   userInfo: { userName },
  // } = useSelector((state) => state.login);
  // const { userAvatar } = useSelector((state) => state.sysUserAvatar)
  const { loading, run } = useRequest(getSql, { manual: true });


  const setListDataFormat = (data: any) => {
    let arr: any = [];
    data.map((item: any) => {
      arr.push({
        sqlId: item.id,
        executor: item.executor,  // 用户名
        executorName: item.executorName,  // 账号
        sqlType: item.sqlType,
        executeSql: item.executeSql,
        executeCostSecond: item.executeCostSecond,
      })
    })
    setDataSource(arr);
  }

  useEffect(() => {
    run().then(res => {
      setData(res);
    })
  }, []);

  useEffect(() => {
    if (data) {
      setListDataFormat(data);
    }
  }, [data]);

  const toLink = (link: string, sqlId: any) => {
    let state:any;
    console.log("state:",state);
    history.push({
      pathname: link,
      state: {
        id: sqlId
      },
    })
  }

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.slowSqlWrapper}>
        <List
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={(item: any, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className={chartStyles.listPre}>
                    <span
                      className={classnames(chartStyles.fs28, chartStyles.listImgWrap, chartStyles.listPreMarginRight)}
                      style={
                        {
                          backgroundColor:
                            index === 0 ?
                              "#D96B67"
                              : index === 1 ?
                                "#E2C655"
                                : index === 2 ?
                                  "#84DCE6"
                                  : "#E2E2E2",
                          color:
                            index < 3 ?
                              "#FFFFFF"
                              : "#3D3D3D"
                        }
                      }
                    >{++index}</span>
                    {
                      // userAvatar ? (
                      //   <img
                      //     alt=''
                      //     src={userAvatar}
                      //     className={chartStyles.fs28}
                      //   />
                      // ) : (<span className={classnames(chartStyles.fs28, chartStyles.userImgWrap)}>{userName?.substring(0, 1)}</span>)
                    }
                    <span className={chartStyles.listPreName}>{item.executorName+"("+item.executor+")"}</span>
                  </div>
                }
                title={
                  <div style={{ cursor: "pointer"}} onClick={()=>toLink("/statement_detail", item.sqlId)}>
                    
                    <p className={chartStyles.content1}>
                      <span className={chartStyles.sqlType}>&nbsp;&nbsp;{item.sqlType}&nbsp;&nbsp;</span>
                      {item.executeSql}
                    </p>
                    <span className={chartStyles.sqlCost}>{(item.executeCostSecond) + "s"}</span>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </Spin>
  )
}
