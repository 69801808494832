import React, { FC } from 'react'
import {
  Switch,
  Route,
} from 'react-router'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { Layout } from 'antd'
import classNames from 'classnames'
import { MyApplyRequest } from './MyApplyRequest'
import { MyApplyRequestDetailPage } from '../my-apply-request-detail'
import { MyApplyResourceSearchPage } from '../resource-search'
import styles from './index.module.scss'
import { ApplicationCartPage } from '../application-cart'

const { Content } = Layout

interface ListProps { }

const updateDataRoutes = [
  {
    pathname: "/mine_apply",
    children: (
      <ErrorBoundary>
        <MyApplyRequest />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/mine_apply/:id/detail",
    children: (
      <ErrorBoundary>
        <MyApplyRequestDetailPage />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/mine_apply/search",
    children: (
      <ErrorBoundary>
        <MyApplyResourceSearchPage />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/mine_apply/application",
    children: (
      <ErrorBoundary>
        <ApplicationCartPage />
      </ErrorBoundary>
    ),
  },
];

export const MyApplyRequestPage: FC<ListProps> = ({ ...rest }) => {

  return (
    <Layout className="cq-container">
      <Layout className="cq-main" style={{padding: 0}}>
        <Content
          className={classNames('cq-content', { [styles['change-request-page']]: true })}
        >
          <Switch>
            {updateDataRoutes.map(({ pathname, children }) => (
              <Route
                exact
                path={pathname}
                key={pathname}
                render={() => children}
              />
            ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}
