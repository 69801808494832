export default function getDefaultXml() {
  const diagramXML = `<?xml version="1.0" encoding="UTF-8"?>
  <bpmn2:definitions 
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL"
    xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI"
    xmlns:dc="http://www.omg.org/spec/DD/20100524/DC"
    xmlns:di="http://www.omg.org/spec/DD/20100524/DI"
    xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd"
    id="sample-diagram"
    targetNamespace="http://activiti.org/bpmn">
      <bpmn2:process id="Process_1" isExecutable="true" name="" normal:isGreen="0" >
      </bpmn2:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_1" >
        <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
        </bpmndi:BPMNPlane>
      </bpmndi:BPMNDiagram>
    </bpmn2:definitions>`;

  return diagramXML;
}
