import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'src/hook'
import { TableTransfer } from 'src/components'
import { getAllUsers, getDeptUsers, UserEntity } from 'src/api'
import uniqBy from 'lodash/uniqBy'
import { userTableColumns } from 'src/constants'

interface UserManagementProps {
  targetKeys: string[]
  onTargetKeysChange: (targetKeys: string[]) => void
}

export const UserManagement: React.FC<UserManagementProps> = ({
  targetKeys,
  onTargetKeysChange,
}) => {
  const [loadingDataSource, setLoadingDataSource] = useState<boolean>(false)

  // 可选用户列表
  const [dataSource, setDataSource] = useState<any[]>([])
  // 该节点已有的用户列表
  const { userList: currentUserList, userListLoading } = useSelector(
    (state) => state.organization,
  )

  const totalDataSource = useMemo(() => {
    return uniqBy(dataSource.concat(currentUserList), 'userId')
  }, [currentUserList, dataSource])

  const { selectedNode, orgTreeData } = useSelector(
    (state) => state.organization,
  )

  // 当前选中节点的父节点
  const parentNode = useMemo(() => {
    if (!selectedNode) return orgTreeData[0]
    const pos: string | undefined = (selectedNode as any).pos
    if (!pos) return orgTreeData[0]
    const parentNode = pos
      .split('-')
      .slice(2, -1)
      .reduce((prev, next) => prev.children[Number(next)], orgTreeData[0])
    return parentNode
  }, [orgTreeData, selectedNode])

  useEffect(() => {
    // 获取 parentNode 人员列表
    setLoadingDataSource(true)
    if (!parentNode) return
    //如果是组直接用当前节点的id来查
    if (selectedNode?.orgType === 'GROUP'){
      getDeptUsers(selectedNode.id)
          .then(setDataSource)
          .finally(() => {
            setLoadingDataSource(false)
          })
      return;
    }
    const { name, orgType, id } = parentNode
    switch (orgType) {
      case 'COMPANY':
        getAllUsers()
          .then(setDataSource)
          .finally(() => {
            setLoadingDataSource(false)
          })
        break
      case 'DEPT':
        getDeptUsers(id)
          .then(setDataSource)
          .finally(() => {
            setLoadingDataSource(false)
          })
        break
      default:
        break
    }
  }, [parentNode])

  const handleTransferChange = (targetKeys: string[]) => {
    onTargetKeysChange(targetKeys)
  }

  return (
    <TableTransfer
      // todo: 修复穿梭框左上角全选选项
      columns={userTableColumns}
      dataSource={dataSource}
      totalDataSource={totalDataSource}
      targetKeys={targetKeys}
      onChange={handleTransferChange}
      tableLoading={{
        left: loadingDataSource,
        right: userListLoading,
      }}
      filterOption={(inputValue, record) => {
        const { userName, userId, dept } = (record as unknown) as UserEntity
        return (
          (userName || '')?.indexOf(inputValue) !== -1 ||
          (userId || '')?.indexOf(inputValue) !== -1 ||
          (dept || '')?.indexOf(inputValue) !== -1
        )
      }}
      showSearch
      rowKey={(record) => record.userId}
      style={{ minHeight: 580 }}
      titles={['', '小组成员']}
    />
  )
}
