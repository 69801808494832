
import React, { useState } from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import { IUserInfoList } from './HighRiskResourceTable';
import Table, { ColumnsType } from 'antd/lib/table';

interface IProps {
  data: IUserInfoList[];
  onDelete: (permissionId: number) => void;
}

export const EffectiveUserModal = (props: IProps) => {
  const { data, onDelete } = props || {};
  const [visible, setVisibe] = useState(false);

  const handleOpen = () => {
    setVisibe(true);
  }

  const handleCancel = () => {
    setVisibe(false);
  }

  const columns: ColumnsType<IUserInfoList> = [
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
      render: (value, record) => {
        const { userId } = record;
        return `${userId}（${value}）`
      },
      width: 180,
      ellipsis: true,
    },
    {
      title: '所属部门',
      dataIndex: 'deptName',
      key: 'deptName',
      width: 100
    },
    {
      title: '生效时间',
      dataIndex: 'expr',
      key: 'expr',
      width: 360
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        const { permissionId } = record as any;

        return <Popconfirm
          title="确定删除吗"
          onConfirm={() => onDelete(permissionId)}
          okText="确定"
          cancelText="取消"
        >
          <Button
            type="link"
            danger
            style={{ paddingLeft: 0 }}
          >删除</Button>
        </Popconfirm>
      }
    },
  ];

  return (
    <div>
      <a onClick={handleOpen}>查看</a>
      <Modal
        title="生效用户列表"
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        width={800}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="permissionId"
          scroll={{
            y: "500px",
          }}
        />
      </Modal>
    </div>
  );
}
