import { IconFontProps } from '@ant-design/icons/lib/components/IconFont'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TreeNode {
  key: string
  value: string
  title: string
  icon?: React.FC<IconFontProps<string>>
  isLeaf: boolean
  nodeType: string
  nodeName: string
  id: string | number
  nodePathWithType: string | null
  nodePath: string
  connection: {
    connectionType: string
  }
}

interface InitialState {
  visibleResourceModal: boolean
  trackingContext: any
}

const initialState: InitialState =  {
  visibleResourceModal: false,
  trackingContext: {}
}

export const hierarchicalAuthSlice = createSlice({
  name: 'hierarchicalRM',
  initialState,
  reducers: {
    openResourceModal(state, action:  any){
      console.log(1111);
      
     state.trackingContext = action.payload;
     state.visibleResourceModal = true
    },
    closeResourceModal(state){
      state.trackingContext = {};
      state.visibleResourceModal = false
     }
  },
})
export const hierarchicalResourceManagementReducer = hierarchicalAuthSlice.reducer

export const {
  openResourceModal,
  closeResourceModal
} = hierarchicalAuthSlice.actions
