import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";
import { Radio, Spin, DatePicker, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";

import chartStyles from "./chart.module.scss";
import { EmptyChart } from "../Charts";

import { useHistory } from "react-router-dom";
import { judgeIsNoData } from "../../utils";

export const ExecutionTimeRingChart = ({
  chartData,
  loading,
}: {
  chartData: any;
  loading: boolean;
}) => {
  const history = useHistory();

  const renderExecutionTimeChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
    });
    data = data
      .map(({ execute_avg = {}, ...rest }) => {
        const amount =
          Object.values(execute_avg)?.reduce((a: number, v: any) => a + v, 0) ??
          0;

        return {
          ...rest,
          amount: Number(amount),
        };
      })
      .sort((a, b) => b.amount - a.amount);

    chart.data(data);
    chart.coordinate("theta", {
      radius: 0.75,
      innerRadius: 0.6,
    });

    chart.scale({

      alias: {
        formatter: (v) => {
          const realAlias = v && v.split("---");
          return realAlias[0] && realAlias[0] !== "null"
            ? realAlias[0]
            : realAlias[1];
        },
      },
    });
    chart
      .interval()
      .position("amount")
      .color("alias")
      .adjust("stack")
      .tooltip("alias*amount", (alias, amount) => {
        const realAlias = alias && alias.split("---");
        const name =
          realAlias[0] && realAlias[0] !== "null" ? realAlias[0] : realAlias[1];
        return {
          name,
          value: amount.toLocaleString() + "ms",
        };
      });

    chart.render();
    return chart;
  };

  useEffect(() => {
    if (!chartData || !chartData[0] || judgeIsNoData(chartData)) return;
    const chart = renderExecutionTimeChart("normal-execution-time", chartData);
    return () => chart.destroy();
  }, [chartData]);

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.executionTimeWrapper}>
        <div id="normal-execution-time" className={chartStyles.executionTime}>
          {((!chartData[0] && !loading) || judgeIsNoData(chartData,'amount')) && <EmptyChart></EmptyChart>}
        </div>
      </div>
    </Spin>
  );
};
