import React, { useEffect, useState } from 'react';
import { Button, Spin, Table, Tag, Popconfirm, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useRequest } from 'src/hook';
import {
  getAccessList,
  checkDeleteAccess,
  deleteAccess,
  IAccessProps,
} from 'src/api';
import dayjs from 'dayjs';
import AccessPolicyModal from './AccessPolicyModal';

const ACCESS_VALUE = 0;
const NO_ACCESS_VALUE = 1;

export const AccessPolicyCard = () => {
  const [visible, setVisible] = useState(false);
  const [filterParams, setFilterParams] = useState<IAccessProps>()
  const [currentValue, setCurrentValue] = useState<IAccessProps>();
  const [remindContent, setReminContent] = useState('确定删除吗？');

  const {
    data,
    loading,
    run: updateList
  } = useRequest((condition: number | undefined) => getAccessList(condition), {
    manual: true,
    formatResult: (data) => {
      return data.map((item: IAccessProps) => {
        return {
          key: item.id,
          ...item
        }
      })
    },
  })

  useEffect(() => {
    updateList(-1);
  }, [])

  const checkAccess = (id: number) => {
    checkDeleteAccess(id).then(res => {
      if (!res) {
        setReminContent("该ip正在活跃，确定删除吗？");
      } else {
        setReminContent("确定删除吗？");
      }
    })
  }

  const columns: ColumnsType<IAccessProps> = [
    {
      title: '来源',
      dataIndex: 'ipAddr',
      key: 'ipAddr',
    },
    {
      title: '访问策略',
      dataIndex: 'type',
      key: 'type',
      filters: [
        {
          text: '白名单策略',
          value: ACCESS_VALUE,
        },
        {
          text: '黑名单策略',
          value: NO_ACCESS_VALUE,
        },
      ],
      filterMultiple: false,
      render: (value, record) => {
        if (!value)
          return <Tag color="green" key={record?.id}>白名单策略</Tag>
        return <Tag color="red" key={record?.id}>黑名单策略</Tag>
      }
    },
    {
      title: '备注',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render(value) {
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) => {
        return <>
          <Button style={{ padding: 0 }} type='link' onClick={() => editAccess(record)}>编辑</Button>
          <Popconfirm
            title={remindContent}
            onConfirm={() => delAccess(value)}
            okText="确定"
            cancelText="取消"
          >
            <Button
              type='link'
              danger
              onClick={() => checkAccess(value)}
            >删除</Button>
          </Popconfirm>
        </>
      }
    }
  ];

  const onChangeTableParams = (pagination: any, filters: any) => {
    const { type } = filters || {};
    if (Array.isArray(type) && type.length) {
      updateList(type[0]);
    } else {
      updateList(-1);
    }
    setFilterParams(filters);
  }

  const editAccess = (values: IAccessProps) => {
    setCurrentValue(values);
    openModal()
  }

  const addAccess = () => {
    setCurrentValue(undefined);
    openModal()
  }

  const delAccess = (id: number) => {
    deleteAccess(id).then(() => {
      message.success('删除成功');
      onSuccess();
    });
  }

  const openModal = () => {
    setVisible(true);
  }

  const onCancel = () => {
    setVisible(false);
  }

  const onSuccess = () => {
    updateList(filterParams?.type ?? -1);
    onCancel();
  }

  return (
    <section className="cq-new-card flow-card">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">访问设置</h3>
        <div className="cq-header__action">
          <Button onClick={addAccess} type='primary'>新增访问规则</Button>
        </div>
      </div>

      <section className="pd-20">
        <Spin
          spinning={loading}
        >
          <Table
            columns={columns}
            dataSource={data}
            onChange={onChangeTableParams}
            pagination={false}
          />
        </Spin>
        <AccessPolicyModal
          key={JSON.stringify(currentValue)}
          value={currentValue}
          visible={visible}
          callback={onSuccess}
          onCancel={onCancel}
        />
      </section>
    </section>
  );
}






