import React, { useEffect, useState} from "react";
import { useSelector } from "src/hook";
import { 
    ViewType,
    getDataResourceDesensitizeList,
    getDesensitizeResourceList,
    getGroupDesensitizeList
 } from "src/api";
import { RootDesensitize } from "./TableView/RootDesensitize";

type IType =
  | "connection"
  | "database"
  | "datasource"
  | "group"
  | "schema"
  | "tableGroup";
//除table 层其他页面全部公用 除了接口不一样
const CommonRightWrapper = ({ type }: { type: IType }) => {

    const { selectedNode } = useSelector((state) => state.dataProtection);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false)

  const getActionAndParams = (type: IType) => {
    if (!selectedNode) return {}
   
    switch (type) {
      case "datasource":
       
        return {
          action: getDataResourceDesensitizeList,
          params: {
            datasourceType:selectedNode.props?.id
        },
        };
     
      case "connection":
        const {
            id: connectionId,
            nodePathWithType: nodePath,
            nodeType: findType,
          } = selectedNode.props
       if (!connectionId) return {}
        return {
          action: getDesensitizeResourceList,
          params: {
            connectionId: +connectionId as number,
            nodePath,
            findType: findType as ViewType,
          },
        };
      case "database":
      case "schema":
      case "tableGroup":
        return {
          action: getDesensitizeResourceList,
          params: {
            connectionId: selectedNode.props?.connectionId as number,
            nodePath: selectedNode.props?.nodePathWithType,
            findType: selectedNode.props?.nodeType as ViewType,
          },
        };
      case "group":
        return {
          action: getGroupDesensitizeList,
          params: {
            groupId: `${selectedNode?.props?.id}`
          },
        };
      default: 
       return {}
    }
  };
  //不同类型， 接口不同参数不同
 
  const fetchList = async() => {
    if (!selectedNode) return 
    setLoading(true)
    const {action, params} =  getActionAndParams(type) as any;
    if (action && params) {
        const data = await  action(params);
        setList(data || [])
    }
    setLoading(false)
  }
  useEffect(() => {
  
   fetchList()
  },[selectedNode, type])

  return <RootDesensitize loading={loading} dataSource={list} refresh={() =>fetchList()}/>;
};

export default CommonRightWrapper;
