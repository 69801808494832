import React from 'react'
import { createFromIconfontCN } from '@ant-design/icons'
import { DataSourceType } from 'src/api'
import { normalizeConnectionType } from 'src/util'

export const Iconfont = createFromIconfontCN()

export const ConnectionIcon = ({
  type,
  fontSize,
}: {
  type: string
  fontSize?: string | number
}) => {
  const connectionType: DataSourceType = normalizeConnectionType(type)
  return (
    <Iconfont
      type={'icon-connection-' + (connectionType || 'Other')}
      className="mr8"
      style={fontSize ? { fontSize } : {}}
    ></Iconfont>
  )
}
