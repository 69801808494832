import React, { useEffect, useState } from 'react'
import * as _ from 'lodash';
import { useRequest } from 'src/hook'
import { message, Modal, Tooltip, Select,  Form, Radio,DatePicker } from 'antd'
import {
  addPermissionUser,
  getPermissionTemplateInfo,
  updatePermissionRelationUsers,
  updatePermissionTemplateInfo
} from "src/api";
import { resetPermissionModal } from '../commonMethods'
import styles from './index.module.scss'
import moment, { Moment } from 'moment';
interface PropsI {
  visible: boolean
  [p:string]: any
}

const SetOperationAuthModal = (props: PropsI) => {
  const {
    type,
    isFineGrainedAuthorization= false, //相当于细粒度授权 不需要权限校验
    dataSourceType,
    groupId,
    nodePathWithType,
    visible,
    handleClose,
    refreshList,
		authorizeEnum,
    nodePathWithTypeList,
    info,
    userIds,
    selectNodeType,
  } = props;

  const [loading, setLoading] = useState(false)
  const [templateId, setTemplateId] = useState<any>();

  const [form] = Form.useForm();


  // 可选权限模版
  const { data: permissionTemplate = [], run: queryTemplate } = useRequest(
    getPermissionTemplateInfo,
    {
      manual: true,
    },
  )

  useEffect(() => {
    if (dataSourceType) {
      queryTemplate(dataSourceType)
    }
  }, [dataSourceType, queryTemplate])

  // 权限选择
  const handleAuthChange = async(value: string) => {
    //判断是否存在权限冲突
    if(!isFineGrainedAuthorization) {
      const hasValidFalse = info.find((item: any) => !item?.templateValid);

      if (!!hasValidFalse) {
        const resetOrNot = await  resetPermissionModal({
          title: '是否重置所选用户权限等级',
          content:'所选用户在此层及下存在其他权限设置,重置将清空下层及所选用户的权限设置'
        })
        if (!resetOrNot) {
          setTemplateId(null)
          return
        }
      }
    }

    //存在
    setTemplateId(value)
  }

  // 设置权限等级
	const handleSetPermissionTemplate = async() => {
    if (!templateId) {
      message.error("请先选择权限模版");
      return;
    }
    const effectiveTime = form.getFieldValue('effectiveTime') || []
    const params: any = {
      userIds: userIds,
      // templateId: templateId!,
      beginTime: effectiveTime[0]?.startOf('s').valueOf(),
      endTime: effectiveTime[1]?.endOf('s').valueOf(),
    };

    if (selectNodeType === 'group') {
      params.groupId = groupId
      params.templateId = templateId!
    } else {
      if (type === "fineGrit") {
        params.nodePathWithTypeList = nodePathWithTypeList
        params.operations = templateId
        params.dataSourceType = dataSourceType
      } else {
        params.nodePathWithType = nodePathWithType
        params.templateId = templateId!
      }
      
    }
    
    setLoading(true)
    if (type === 'fineGrit') {
      updatePermissionRelationUsers([params])
      .then((res) => {
          handleClose()
          refreshList()
          message.success('修改成功')
        })
        .catch((err: any) => {
          console.error('修改权限等级失败：', err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      updatePermissionTemplateInfo(params)
        .then(() => {
          handleClose()
          refreshList()
          message.success('修改成功')
        })
        .catch((err: any) => {
          console.error('修改权限等级失败：', err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const renderAuthorizeList = () => { 
    if (authorizeEnum) {
      const options = authorizeEnum?.map((item: any) => {
        return {
          label: item?.operationName,
          value: item?.operation,
        };
      });
      return (
        <Select
          placeholder="请选择"
          mode="tags"
          maxTagCount={4}
          options={options}
          style={{ width: "100%" }}
          onChange={handleAuthChange}
        />
      );
    } else {
      const options = permissionTemplate?.filter((item: any)=>![
        "DISABLE_TEMPLATE",
        "DISABLE_TEMPLATE_AND_PERMISSION",
      ].includes(item?.status))?.map((item: any) => {
        return {
          label: <Tooltip title={item?.description}>{item?.name}</Tooltip>,
          value: item?.id
        };
      });
      return (
        <Select
          placeholder="请选择"
          options={options}
          value={templateId}
          style={{ width: "100%" }}
          onChange={handleAuthChange}
        />
      );
    }
  }

  const onValuesChange = (values: any) => {
    if (form.getFieldValue("timeType") === 'forever') {
      form.setFieldsValue({ effectiveTime: [] })
    }
  }

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf('day');
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledTime = (now: any, type: any) => {
    let time: any = moment();
    let hour = moment(time).hour();
    let minute = moment(time).minute();
    let second = moment(time).second();

    if (moment(now).day() === moment(time).day()) {
      return ({
        disabledHours: () =>range(0, hour),
        disabledMinutes: () => range(0,minute),
        disabledSeconds: () =>  range(0,second)
      })
    }
     return {}
  }

  return (
    <Modal
      style={{ top: 80 }}
      width={620}
      className={styles.addUserModal}
      visible={visible}
      title={ type === 'fineGrit' ? "批量设置操作权限" : "批量设置权限等级"}
      onCancel={handleClose}
      onOk={handleSetPermissionTemplate}
      okButtonProps={{ loading }}
    >
      <Form
        form={form}
        onValuesChange={onValuesChange}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        initialValues={{ timeType: "forever" }}
      >
        <Form.Item label={ type === 'fineGrit' ? "操作权限" : "权限等级"}>
          {/* 权限渲染 */}
          {renderAuthorizeList()}
        </Form.Item>
        <Form.Item label="生效时间" name="timeType">
          <Radio.Group>
            <Radio value="forever">永久</Radio>
            <Radio value="custom">自定义时间段</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.timeType !== currentValues.timeType}
        >
          {({ getFieldValue }) =>
            getFieldValue('timeType') === 'custom' ? (
              <Form.Item
                label="时间段"
                name="effectiveTime"
                rules={[
                  { required: true }, 
                  {
                  validator(_rule, value, cb) {
                    const s = moment(value?.[0]).valueOf();
                    const e = moment(value?.[1]).valueOf();

                    if (e < moment().valueOf()) {
                      cb('请重新选择时间，结束时间必须大于当前时间')
                    } else if (s > e) {
                      cb("起始时间必须小于结束时间");
                    }
                    cb();
                  },
                }]}
              >
                <DatePicker.RangePicker
                  disabledDate={disabledDate}
                  disabledTime={disabledTime}
                  showTime={{
                    hideDisabledOptions: true,
                  }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default SetOperationAuthModal
