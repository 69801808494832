import {ApplyStatus, FlowDetailFields, flowFilterTypes, FlowType, flowTypes} from 'src/types'

export const flowTypeLabelMap: Record<FlowType, string> = {
  dataCorrection: '数据订正',
  dataManipulation: '数据操作提权',
  connectionAccess: '连接访问提权',
  dataExport: '数据导出提权',
  dataMasking: '数据脱敏提权',
  highRisk: '高危资源提权',
  executionTimes: '执行次数提权',
  executionRows: '执行行数提权',
  menuAccess: 'UI 提权',
  desensitizedResource: '敏感资源申请'
}

export const flowTimeLimits = [
  { label: '一周', value: 24 * 7, tooltip: '7 天' },
  { label: '一月', value: 24 * 30, tooltip: '30 天' },
  { label: '三月', value: 24 * 90, tooltip: '90 天' },
  { label: '半年', value: 24 * 180, tooltip: '180 天' },
  { label: '一年', value: 24 * 365, tooltip: '365 天' },
  { label: '永久', value: 0 },
]

// 菜单权限选项
export const menuOptions = [
  { label: '系统设置', value: 'SystemSettings' },
  { label: '数据库管理', value: 'ConnectionSettings' },
  { label: '审计分析', value: 'AuditAnalysis' },
  // { label: '监控', value: 'Monitor' },
  // { label: '告警', value: 'Warning' },
]

// 状态筛选选项
export const statusOptions: { label: string; value: '*' | ApplyStatus }[] = [
  { label: '全部状态', value: '*' },
  { label: '已通过', value: 'pass' },
  { label: '已驳回', value: 'refuse' },
  { label: '已撤销', value: 'withdraw' },
  { label: '审批中', value: 'pending' },
]

// 提交日期筛选选项
export const applyTimeOptions = [
  { label: '全部', value: '*' },
  { label: '近7日', value: '-7' },
  { label: '近30日', value: '-30' },
]

// 流程类型筛选选项
export const flowTypeOptions = [{ value: '*', label: '全部类型' }].concat(
    flowFilterTypes.map((type) => ({
    value: type,
    label: flowTypeLabelMap[type],
  })),
)

// 流程详情 field => label
export const flowDetailFieldLabelMap: Partial<
  Record<keyof FlowDetailFields, string>
> = {
  uuid: '审批编号',
  applyReason: '申请原因',
  applyTime: '发起时间',
  applyUserName: '申请人',
  flowType: '申请类型',
  connectionName: '数据源连接',
  deptName: '所在部门',
  executeText: '订正语句',
  rollbackText: '回退语句',
  rollbackMessage: '回退结果',
  executeType: '订正执行时机',
  nodeList: '数据库元素',
  objName: '菜单权限',
  operationList: '操作类型',
  expiredTimeLimit: '期限',
  rowNumLimit: '执行行数',
  dataCorrectionCount: '订正影响行数',
  rollbackCount: '回退影响行数',
  executeTimeLimit: '执行次数',
  exportNumLimit: '导出行数',
  updateTime: '最后更新时间',
  errorMessage: '订正结果',
  refuseReason: '驳回理由',
}

// 流程详情 各类型 Description 包含的字段
export const flowTypeMapToDetailFields: Record<
  FlowType | any,
  (keyof FlowDetailFields)[]
> = {
  dataCorrection: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'executeText',
    'rollbackText',
    'executeType',
    'errorMessage',
    'rollbackMessage',
    'dataCorrectionCount',
    'rollbackCount',
    'updateTime',
    'applyReason',
    'refuseReason',
  ],
  connectionAccess: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
  dataManipulation: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'operationList',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
  dataExport: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'exportNumLimit',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
  dataMasking: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
  highRisk: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'updateTime',
    'applyReason',
    'operationList',
    'expiredTimeLimit',
    'refuseReason',
  ],
  executionRows: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'rowNumLimit',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
  executionTimes: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'connectionName',
    'nodeList',
    'executeTimeLimit',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
  menuAccess: [
    'uuid',
    'applyUserName',
    'deptName',
    'applyTime',
    'flowType',
    'objName',
    'updateTime',
    'applyReason',
    'expiredTimeLimit',
    'refuseReason',
  ],
}
