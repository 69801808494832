import { Col, Form, Row, Select, Spin, Transfer } from "antd";
import { FormInstance } from "antd/lib/form";
import { TransferItem } from "antd/lib/transfer";
import React, { RefObject, useEffect, useState } from "react";
import { getTableListBySchema, queryTreeNode } from "src/api";
import { useRequest, useSelector } from "src/hook";
import { Iconfont } from "src/components";

const { Item } = Form;

interface IProps {
  formRef: RefObject<FormInstance<any>>
  initialValue?: Record<string, any>
  disabled?: boolean
}

const SecondForm = ({ formRef, disabled }: IProps): JSX.Element => {
	const { sourceConnection, targetConnection } = useSelector(state => state.createTransfer);


	const { run: queryNode, loading: schemaLoading } = useRequest((key, params) => queryTreeNode(params), {
		manual: true,
		fetchKey: key => key,
		formatResult(res) {
			return res.map((item: any) => {
				return {
					label: item?.nodeName,
					value: item?.nodeName,
					props: item,
				};
			});
		},
	});

	const { run: getTableBySchema, loading: transferLoading } = useRequest((key, params) => getTableListBySchema(params), {
		manual: true,
		fetchKey: key => key,
		formatResult(res) {
			return res.map((item: any) => {
				return {
					key: item.nodeName,
					title: item.nodeName,
					description: item.nodeType,
					props: item,
				};
			});
		},
	});

	const [isDataBase, setIsDataBase] = useState<boolean>(false);
	const [sourceDataBaseOptions, setSourceDataBaseOptions] = useState([]);
	const [targetDataBaseOptions, setTargetDataBaseOptions] = useState([]);
	const [sourceSchemaOptions, setSourceSchemaOptions] = useState([]);
	const [targetSchemaOptions, setTargetSchemaOptions] = useState([]);
	const [targetKeys, setTargetKey] = useState<string[]>([]);
	const [transferData, setTransferData] = useState<Array<TransferItem>>([]);
	const { firstFormInstance } = useSelector(state => state.createTransfer);

	// source
	useEffect(() => {
		if (sourceConnection) {
			// sourceSchema
			const sourceParams = {
				connectionId: sourceConnection.connectionId,
				connectionType: sourceConnection.connectionType,
				nodeType: "connection",
				nodeName: sourceConnection.connectionName,
				nodePath: `/root/${sourceConnection.connectionId}`,
				nodePathWithType: `/CONNECTION:${sourceConnection.connectionId}`,
			};

			queryNode("source", sourceParams).then(res => {
				if (res[0].props.newNodeType === "DATABASE") {
					const result = res?.map((i: any)=>{
						return {
							...i,
							label:<span><Iconfont type={`icon-database`} style={{marginRight: 4}} />{i?.label}</span>,
							value: i?.value,
						}
					})
					setSourceDataBaseOptions(result);
					setIsDataBase(true);
				} else if (res[0].props.newNodeType === "SCHEMA") {
					const result = res?.map((i: any)=>{
						return {
							...i,
							label:<span><Iconfont type={`icon-schema`} style={{marginRight: 4}} />{i?.label}</span>,
							value: i?.value,
						}
					})
					setSourceSchemaOptions(result);
					setIsDataBase(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceConnection]);

	// target
	useEffect(() => {
		if (targetConnection) {
			// targetSchema
			const targetParams = {
				connectionId: targetConnection.connectionId,
				connectionType: targetConnection.connectionType,
				nodeType: "connection",
				nodeName: targetConnection.connectionName,
				nodePath: `/root/${targetConnection.connectionId}`,
				nodePathWithType: `/CONNECTION:${targetConnection.connectionId}`,
			};

			queryNode("target", targetParams).then(res => {
				if (res[0].props.newNodeType === "DATABASE") {
					const result = res?.map((i: any)=>{
						return {
							...i,
							label:<span><Iconfont type={`icon-database`} style={{marginRight: 4}} />{i?.label}</span>,
							value: i?.value,
						}
					})
					setTargetDataBaseOptions(result);
					setIsDataBase(true);
				} else if (res[0].props.newNodeType === "SCHEMA") {
					const result = res?.map((i: any)=>{
						return {
							...i,
							label:<span><Iconfont type={`icon-schema`} style={{marginRight: 4}} />{i?.label}</span>,
							value: i?.value,
						}
					})
					setTargetSchemaOptions(result);
					setIsDataBase(false);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetConnection]);

	function handleSourceDataBaseChange(_: string, options: any) {
		const { sdt, nodeType, nodeName, nodePath, nodePathWithType } = options.props;
		const params = {
			connectionId: sdt?.connectionId,
			connectionType: sdt?.connectionType,
			nodeType: nodeType,
			nodeName: nodeName,
			nodePath: nodePath,
			nodePathWithType: nodePathWithType,
		};
		queryNode("source", params).then(res => {
			const result = res?.map((i: any)=>{
				return {
					...i,
					label:<span><Iconfont type={`icon-schema`} style={{marginRight: 4}} />{i?.label}</span>,
					value: i?.value,
				}
			})
			setSourceSchemaOptions(result);
		});
	}

	function handleTargeDataBaseChange(_: string, options: any) {
		const { sdt, nodeType, nodeName, nodePath, nodePathWithType } = options.props;
		const params = {
			connectionId: sdt?.connectionId,
			connectionType: sdt?.connectionType,
			nodeType: nodeType,
			nodeName: nodeName,
			nodePath: nodePath,
			nodePathWithType: nodePathWithType,
		};
		queryNode("target", params).then(res => {
			setTargetSchemaOptions(res);
		});
	}

	function handleSelectChange(_: string, options: any) {
		const { sdt, connection, nodeName, nodePath, nodePathWithType, nodeType } = options.props;
		const { connectionType } = connection ?? {};

		const params = {
			connectionId: sdt?.connectionId,
			connectionType,
			nodeType,
			nodeName,
			nodePath,
			nodePathWithType,
		};
		getTableBySchema("source", params).then(res => {
			setTransferData(res);
		});
	}

	function handleTransferChange(targetKeys: string[]): void {
		setTargetKey([...targetKeys]);
	}


	const layout = {
		labelCol:{ span: 10 },
		wrapperCol:{ span: 14 }
	}
	return (
		<Form  
			ref={formRef}	
		>
			{isDataBase && (
				<Spin spinning={schemaLoading}>
					<Row justify="space-around">
						<Col span={12}>
							<Col span={24}>
								<Item
									{...layout}
									label={"选择源DATABASE"}
									name={"sourceDatabase"}
									rules={[{ required: true, message: "请选择源SCHEMA" }]}
								>
									<Select
										disabled={disabled}
										style={{width: 240}}
										onChange={handleSourceDataBaseChange}
										options={sourceDataBaseOptions}
									></Select>
								</Item>
							</Col>
						</Col>
						<Col span={12}>
							<Col span={24}>
								<Item
									{...layout}
									label={"选择目标DATABASE"}
									name={"targetDatabase"}
									rules={[{ required: true, message: "请选择目标SCHEMA" }]}
								>
									<Select
										disabled={disabled}
										style={{width: 240}}
										onChange={handleTargeDataBaseChange}
										options={targetDataBaseOptions}
									></Select>
								</Item>
							</Col>
						</Col>
					</Row>
				</Spin>
			)}
			<Spin spinning={schemaLoading}>
				<Row justify="space-around">
					<Col span={12}>
						<Col span={22}>
							<Item
								{...layout}
								label={"选择源SCHEMA"}
								name={"sourceSchema"}
								rules={[{ required: true, message: "请选择源SCHEMA" }]}
							>
								<Select
									disabled={disabled}
									style={{width: 240}}
									onChange={handleSelectChange}
									options={sourceSchemaOptions}
								></Select>
							</Item>
						</Col>
					</Col>
					<Col span={12}>
						<Col span={22}>
							<Item
								{...layout}
								label={"选择目标SCHEMA"}
								name={"targetSchema"}
								dependencies={["sourceSchema"]}
								rules={[
									{ required: true, message: "请选择目标SCHEMA" },
									({ getFieldValue }) => ({
										validator(_, value) {
											const formVa = formRef.current
											// 连接不同 或者 database不同 的时候 不用校验 schema是否一致
											if (firstFormInstance?.sourceId != firstFormInstance?.targetId || 
												formVa?.getFieldValue('sourceDatabase') != formVa?.getFieldValue('targetDatabase') ||
												!value || getFieldValue("sourceSchema") !== value 
											) {
												return Promise.resolve();
											}
											return Promise.reject(new Error("请选择不同的 SCHEMA"));
										},
									}),
								]}
							>
								<Select style={{width: 240}} disabled={disabled} options={targetSchemaOptions}></Select>
							</Item>
						</Col>
					</Col>
				</Row>
			</Spin>
			<Spin spinning={transferLoading}>
				<Item
					label={"选择表传输"}
					name={"dataDuplicateTables"}
					rules={[{ required: true, message: "请选择表传输" }]}
				>
					<Transfer
						disabled={disabled}
						listStyle={{ width: "326px", height: "500px" }}
						titles={["源", "目标"]}
						dataSource={transferData}
						targetKeys={targetKeys}
						onChange={handleTransferChange}
						showSearch
						render={item => {
							return item.title ?? " ";
						}}
					/>
				</Item>
			</Spin>
		</Form>
	);
};

export { SecondForm };

