/*
 * @Author: fuzhenghao
 * @Date: 2023-01-06 14:43:03
 * @LastEditTime: 2023-01-10 17:51:22
 * @LastEditors: fuzhenghao
 * @Description:
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\flowDetails\ModalSelectNewApprover.tsx
 */
import { Form, Select, Tag } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { UIModal } from 'src/components'
import styles from '../flowPage.module.scss'
import { getAllApprovalUsers, transferNewApprover } from 'src/api'
import useRequest from '@ahooksjs/use-request'

interface IProps {
  userTasks: Array<string>
  visible_setNewApprover: boolean
  setVisible_setNewApprover: Function
  cleanParentComponentData: Function
}

export default function ModalDataChangeSelectNewApprover(props: IProps) {
  // 流程详情 modal visible
  let {
    visible_setNewApprover: visible,
    userTasks,
    setVisible_setNewApprover: setVisible,
    cleanParentComponentData,
  } = props
  const [form] = Form.useForm()

  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }
  const onOk = async () => {
    form.validateFields().then((values) => {
      let params = {
        referUserId: values.referUserId,
        userTasks,
      }
      transferNewApprover(params).then(() => {
        onCancel()
        cleanParentComponentData()
      })
    })
  }

  const { data, run } = useRequest(getAllApprovalUsers, {
    manual: true,
  })

  const userOptionListRender = useMemo(() => {

    return data && data
       .map((item: any) => {
        return <Select.Option key={item?.userId} value={item?.userId}>
          <Tag color="blue">{item?.dept}</Tag> &nbsp;
          {`${item?.userName}（${item?.userId}）`}
         
          </Select.Option>
      })
  }, [data])

  const onSearch = (value: string) => {}

  useEffect(() => {
    run()
  }, [])

  return (
    <UIModal
      title={<span className={styles.flowDetailTitle}>选择新审批人</span>}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form form={form}>
        <Form.Item
          label="审批人列表"
          name="referUserId"
          rules={[{ required: true, message: '请选择要转审的人员' }]}
        >
          <Select
            optionFilterProp="children"
            showSearch={true}
            // onSearch={onSearch}
          >
            {userOptionListRender}
          </Select>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
