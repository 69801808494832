import { UserParameterValue } from '../api'
export const DataSourceTypes = [
  'dble',
  'GaussDB',
  'OpenGauss',
  'GaussDB_DWS',
  'HighGo',
  'Greenplum',
  'MariaDB',
  'MongoDB',
  'MySQL',
  'OceanBaseMySQL',
  'Oracle',
  'OracleCDB',
  'PostgreSQL',
  'KingBasePG',
  'KingBaseOracle',
  'RDSMySQL',
  'Redis',
  'ShardingSphere',
  'SQLServer',
  'StarRocks',
  'DamengDB',
  'OceanBase',
  'PolarDB',
  'Other',
  'Vertica',
  'Trino',
  'Impala',
  'Hive',
  'Inceptor',
  'DB2',
  'MogDB',
  'StarRocks',
  'Phoenix',
  'GBase',
  'Oscar',
  'TDSQL',
  'HANA',
  'TeleDB',
  'TiDB',
  'TDSQLPG',
] as const

export type DataSourceType = (typeof DataSourceTypes)[number]

export const DataSourceTypesSupportExplain = [
  'MySQL',
  'Oracle',
  'SQLServer',
  'Inceptor',
  'Hive',
]
export const DataSourceTypesBelongToNoSQL = ['MongoDB', 'Redis']
export const DataSourceWithAdditionalSchema: DataSourceType[] = [
  'DamengDB',
  'PolarDB',
  'PostgreSQL',
  'GaussDB',
  'OpenGauss',
  'GaussDB_DWS',
  'HighGo',
  'Greenplum',
  'SQLServer',
  'DB2',
  'MogDB',
  'OracleCDB',
  'StarRocks',
  'Vertica',
  'Trino',
  'TDSQLPG',
]

// todo: 考虑构建前脚本从后端获取 sql types，SqlTypes 作为 fallback
export const SqlTypes = [
  'SELECT',
  'DELETE',
  'UPDATE',
  'INSERT',
  'CREATE',
  'DROP',
  'ALTER',
  'OTHER',
  'EXPORT',
] as const

export type SqlType = (typeof SqlTypes)[number]

export type SdtNodeType =
  | 'datasource'
  | 'group'
  | 'root'
  | 'connectionGroup'
  | 'connection'
  | 'catalog'
  | 'database'
  | 'schema'
  | 'table'
  | 'view'
  | 'column'
  | 'tableGroup'
  | 'viewGroup'
  | 'functionGroup'
  | 'udfGroup'
  | 'udf'
  | 'storedProcedureGroup'
  | 'favoriteGroup'
  | 'columnGroup'
  | 'oracleUser'
  | 'redisDataBase'
  | 'redisKey'
  | 'file'
  | 'collection'
  | 'function'
  | 'procedure'
  | 'synonym'
  | 'sequence'
  | 'package'
  | 'type'
  | 'packageBody'
  | 'materializedView'
  | 'trigger'
  | 'oracleUser'
  | 'triggerGroup'
  | 'foreignTable'
  | 'syncMaterializedView'
  | 'asyncMaterializedView'


export interface NodeEntity {
  connectionId: number | string
  connectionType: DataSourceType
  tableName?: string
  groupId?: number
  nodeName: string
  funcAndProcOriginName?: string
  nodePath: string
  nodePathWithType: string
  nodeType: SdtNodeType
  nodePermissionLimits?: { permissionType: string; permissionPath: string }[]
  parentGroupId?: number
  schemaName?: string
  databaseName?: string
  alias?: string
  permissionList?: { [key: string]: boolean }
  /**
   * ! 后端对某些不可切库的目标节点的特殊处理，值明确为 false 时不可切库
   */
  switchable?: boolean
  // 识别缺少的权限
  applyPermissionType?: string
  devModel?: boolean
  havingPermission?: boolean
  globalHavePermissionFlag?: boolean
  sourceOperationList?: any
  flowUUID?: string
  maskedPathMap?: any
}

interface NodeOptions extends ColumnOption, MethodOption {}
export interface BaseOption {
  tooltipItems: { label: string; value: string }[]
  remark: string
  dataType: string
}
export interface ColumnOption extends BaseOption {
  primaryKey: boolean
  foreignKey: boolean
}
export interface MethodOption extends BaseOption {}

export interface ResponseNodeExtra {
  hasChild: boolean
  nodeDescription?: string // will be duprecated, use tooltipOptions in nodeOptions instead
  alias?: string
  nodeOptions: NodeOptions
  childCount: number
}

export interface RequestNodeExtra {
  oracleUser?: string
}

export interface RequestNodeInfo extends NodeEntity, RequestNodeExtra {}
export interface ResponseNodeEntity extends NodeEntity, ResponseNodeExtra {}
export interface SdtResponse extends ResponseNodeEntity {
  data: ResponseNodeEntity[],
  resourceLimit: boolean
}


export interface TreeNode extends RequestNodeInfo, ResponseNodeEntity {
  key: string
  children?: TreeNode[]
  title?: string
  isLeaf?: boolean
}

export interface IPerMenuItem {
  id: number;
  accessMode: string;
  description: string | null;
  name: string;
  object: string;
  permissionType: string;
  required: boolean;
}
export interface IModuleItem {
  accessMode: string;
  items: IPerMenuItem[];
  menuName: string;
  menuType: string;
}

export interface UserConfig {
  editorPromptOnClose: boolean
  theme: 'dark' | 'light'
  hotKeys: IHotKeyKinds | null
  encoding: string
}

export interface UserEntity {
  dept: string
  email: string
  images: any
  jobNumber: string
  password: any
  positionId: any
  positionName: any
  telephone: string
  userGender: any
  userId: string
  roleIds: any[]
  userName: string
  userStatus: 'NORMAL' | 'LOCKED'
  sessionId: string
  /** 是否拥有连接 */
  owner: boolean
  systemAuthCode: number
  personalSettings: UserConfig
  firstLogin: boolean
  tabMax: number
  watermarkSetting: boolean
  fileMax: number
  copySetting: boolean
  /** 允许IP */
  permitIps: any
  createFrom: string
  /** 流程select开关 */
  flowSelectSwitch: boolean
  /** 登出状态 */
  saveEditorContent: boolean
  /** 编码格式 */
  encoding: string
  /** 用户角色 */
  systemRoles?: string[]
  /** cq3/6/9 版本区分 */
  productGrade: 'CQ3' | 'CQ6' | 'CQ9'
  /** 密码过期提示 */
  warnings: string[]
  departmentName: string
  departmentId: number
  companyId: number
  parameterValues?: UserParameterValue[]
  onlineStatus?: boolean
  principalFlag?: boolean
  permissionMenus?: IModuleItem[]
  roles: any[]
  twoFactorSetting: any
  version?: string | null
  //是否强制短信认证
  forceBindPhone?: boolean
  canUnbindPhone?: boolean
  // 审计范围
  auditUser?: Record<string,string>
  auditRange?: any[]
  // 角色id arr
  rolesId?: number[]
}

export interface QueryBase {
  databaseName?: string
  connectionId: number | string
  dataSourceType: DataSourceType
  /* 数据库连接信息最下层 ，是对operatingDatabase的优化替换 */
  operatingObject?: string
  operatingDatabase?: string
  tabKey?: string
  plSql?: boolean
  tSql?: boolean
  autoCommit?: boolean
  isFlashbackAction?: boolean | undefined
}

/**
 * @description: 查询任务执行结果的参数
 * @return {*}
 */
export interface QueryParams {
  messageId: string,
  channelId: string,
  groupName: string
}

/**
 * @description: 危险等级： 低：0， 中：1， 高：2
 */
export const RiskLevels = ['低', '中', '高']

export type RiskLevel = (typeof RiskLevels)[number]

/**
 * @description: 动作规则名字
 */
export const RuleNames = [
  '截断表',
  '更新表不带条件',
  '删除表',
  '修改表结构',
  '异常删除数据',
]

export type RuleName = (typeof RuleNames)[number]

/**
 * @description: 动作名字
 */
export const OperateNames = ['LOGIN']

export type OperateName = (typeof OperateNames)[number]

// 快捷键类型：执行计划、执行、提示
export interface IHotKeyKinds {
  execPlan?: string
  execute?: string
  prompt?: string
  quickFind?: string
  multiEdit?: string
  comment?: string
  [p: string]: any
}
export interface IHotKeys {
  hotKeys: IHotKeyKinds
}
export interface IEncryptKeys {
  algorithm: string
  publicKey: string
}

export interface IWwLoginInfo {
  appId: string,
  agentId: string,
  enable: boolean,
}

export interface IPermissionList {
  "FLOW_APPLY": {
    "MINE_APPLY": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "MINE_APPROVE": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "FLOW_WORK_ORDER_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "FLOW_DESIGN": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] }
  },
  "DATA_TRANSFORM": {
    "DATA_TRANSFORM": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] }
  },
  "DATA_CHANGE": {
    "DATA_CHANGE_MINE_APPLY": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "DATA_CHANGE_MINE_APPROVE": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "DATA_CHANGE_WORK_ORDER_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "DATA_CHANGE_FLOW_DESIGN": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] }
  },
  "AUDIT_ANALYZE": {
    "AUDIT_VIEW": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "OBJECT_AUDIT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "SUSER_AUDIT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "PERMISSION_DASHBOARD": { "isOnlyRead": true, "roleNameList": [], "roleTypeList": [] }
  },
  "SYSTEM_MONITOR": {
    "CONTAINER_MONITOR": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "MACHINE_MONITOR": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] }
  },
  "DATABASE_MANAGEMENT": {
    "CONNECTION_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "AUTH_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "AUTOMATIC_AUTHORIZATION": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "RULE_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "HIERARCHICAL_AUTH_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] }
  },
  "SYSTEM_MANAGEMENT": {
    "USER_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "ROLE_MANAGEMENT": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] },
    "SYSTEM_SETTINGS": { "isOnlyRead": false, "roleNameList": [], "roleTypeList": [] }
  }
}
