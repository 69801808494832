import { useRef, useEffect, useState, useCallback } from 'react'
import { find } from 'lodash'

const gridStatus = ['NORMAL', 'UPDATE', 'INSERT', 'DELETE', 'CLONE'] as const
export type IGridStatus = typeof gridStatus[number]
const actions = [
  'reset',
  'startUpdate',
  'stopUpdate',
  'startDelete',
  'startInsert',
  'startClone'
] as const
export type IAction = typeof actions[number]
type ITransition = {
  action: IAction
  from: IGridStatus | IGridStatus[]
  to: IGridStatus
}

const transition: ITransition[] = [
  {
    action: 'reset',
    from: ['DELETE', 'INSERT', 'UPDATE', 'CLONE'],
    to: 'NORMAL',
  },
  {
    action: 'startUpdate',
    from: ['NORMAL', 'DELETE'],
    to: 'UPDATE',
  },
  {
    action: 'stopUpdate',
    from: 'UPDATE',
    to: 'NORMAL',
  },
  {
    action: 'startDelete',
    from: 'NORMAL',
    to: 'DELETE',
  },
  {
    action: 'startInsert',
    from: ['NORMAL', 'DELETE'],
    to: 'INSERT',
  },
  {
    action: 'startClone',
    from: ['NORMAL', 'DELETE'],
    to: "CLONE"
  }
]

/* 结果集状态机 */
/* rule0 初始状态为NORMAL */
/* rule1 触发状态转移及转移规则详见transition */
/* rule2 记录近十次 status history */
/* TODO:transition hook，状态流转自动触发动作 */
const useGridStatus = () => {
  const statusRef = useRef<IGridStatus>('NORMAL')

  const [innerStatus, setInnerStatus] = useState<IGridStatus>('NORMAL')
  const [statusHistory, setStatusHistory] = useState<IGridStatus[]>([
    innerStatus,
  ])
  const setStatus = (nextStatus: IGridStatus) => {
    if (!nextStatus || !gridStatus.includes(nextStatus)) {
      return
    }
    setInnerStatus(nextStatus)
  }

  const dispatchAction = (action: IAction) => {
    if (!action || !actions.includes(action)) {
      return
    }
    const status = statusRef.current
    const matchAction = find(transition, { action })
    if (
      !matchAction ||
      !(matchAction.from === status || matchAction.from.includes(status))
    ) {
      return
    }
    setStatus(matchAction.to)
  }

  const historyPush = useCallback((status: IGridStatus) => {
    setStatusHistory((prev) => [...prev, status].slice(0, 9))
  }, [])

  useEffect(() => {
    statusRef.current = innerStatus
    historyPush(innerStatus)
  }, [historyPush, innerStatus])

  return {
    status: innerStatus,
    statusRef,
    setStatus,
    dispatchAction,
    statusHistory,
  }
}

export { useGridStatus }
