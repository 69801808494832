import React, { useContext, useEffect } from 'react'
import { message, Form, Input, Radio } from 'antd'
import { useRequest, useDispatch } from 'src/hook'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'

import { addUserParameters, editUserParameters } from 'src/api'
import { RecordContext } from './userParameterPage'
import { userParameterTagValidator, userParameterValidator } from 'src/util'

const optionsWithDisabled = [
    { label: '是', value: true },
    { label: '否', value: false }
];

interface PropsI {
    visible: boolean
    handleClose: () => void
    callback: () => void
}
/* 编辑表单 */
export default function ParameterModal(props: PropsI) {
    const { visible, handleClose, callback } = props
    const { mode, modalName, record, refresh } = useContext(RecordContext)

    const isEdit = mode === 'edit'
    const ActionText = isEdit ? '编辑' : '新增'
    const TitleText = '参数'
    const requestFn = isEdit ? editUserParameters : addUserParameters
    const { run, loading } = useRequest(requestFn, {
        manual: true,
        onSuccess: () => {
            message.success(`${ActionText}成功`)
            dispatch(hideModal(modalName as any))
            handleClose && handleClose()
            callback && callback()
        },
    })

    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const submitForm = (v: any) => {
        const payload = {
            ...v,
            id: record?.id,
            accessMode: record?.accessMode
        }
        run(payload)
    }

    useEffect(() => {
        if (visible && isEdit && record) {
            form.setFieldsValue(record)
        }
    }, [form, isEdit, record, visible])


    return (
        <UIModal
            title={`${ActionText}${TitleText}`}
            visible={visible}
            onOk={() => form.submit()}
            onCancel={handleClose}
            afterClose={() => form.resetFields()}
            confirmLoading={loading}
        >
            <Form form={form} onFinish={submitForm} {...FormLayout}>
                <Form.Item
                    name="parameterName"
                    label="参数名称"
                    rules={[
                        { required: true, message: '请输入参数名称' },
                        { validator: userParameterValidator },
                    ]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name="tagName"
                    label="参数标签"
                    rules={[
                        { required: true, message: '请输入参数标签' },
                        { validator: userParameterTagValidator },
                    ]}
                >
                    <Input></Input>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="备注"
                >
                    <Input.TextArea
                        maxLength={100}
                    />
                </Form.Item>
                <Form.Item
                    name="needed"
                    label="必填项"
                    rules={[
                        { required: true, message: '必填项不可为空' },
                    ]}
                >
                    <Radio.Group
                        options={optionsWithDisabled}
                        // onChange={onChange4}
                        // value={value4}
                        // optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>
            </Form>
        </UIModal>
    )
}
