import React, { useCallback, useState } from 'react'
import { Col, Space, Tooltip, message } from 'antd'
import { Iconfont } from 'src/components'
import { getOperatingObject, getSelectedText } from 'src/util'
import { 
  makeResultAllExport, 
  getSelectedStatementResult,
  getTaskResult
 } from 'src/api'
import { useRequest, useSelector } from 'src/hook'
import { activeMonacoPaneInfoSelector } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import { ResultAllExport } from 'src/pageTabs/queryPage/resultTabs/resultContentGrid/ResultAllExport'
import { useDownloadNotificationRenderer } from 'src/features/websocket/useDownloadNotification'
import styles from './index.module.scss';
import classNames from 'classnames'

export const MonacoExportStatement = React.memo(() => {

  const activePaneInfo = useSelector(activeMonacoPaneInfoSelector)
  const { handleExternalInfo } = useHandleExternalInfo();
  const [renderNotification] = useDownloadNotificationRenderer()

  const [editorInstance] = useEditorInstance();
  const selectedStatement = editorInstance && getSelectedText(editorInstance);
  const [visibleExportAll, setVisibleExportAll] = useState(false)

  //获取语句执行结果
  const { data: exportPermission } = useRequest(() => {
    if (!activePaneInfo) return
    const { key, plSql, tSql, connectionId, connectionType, databaseName, schemaName } = activePaneInfo;
    return selectedStatement && connectionType && getSelectedStatementResult({
      connectionId,
      dataSourceType: connectionType,
      operatingObject: getOperatingObject(
        { databaseName, schemaName },
        connectionType,
      ),
      offset: 0,
      rowCount: 100,
      databaseName,
      tabKey: key,
      plSql,
      tSql,
      autoCommit: activePaneInfo?.txMode === 'auto',
      statements: [
        selectedStatement
      ],
    })
  }, {
    formatResult: (res: any) => {
      return res?.executionInfos?.[0]?.response
    },
    refreshDeps: [selectedStatement, activePaneInfo?.key]
  });

  const { fetchTaskResult } = useHandleExternalInfo(getTaskResult, (data: any) => {
    renderNotification(data)
  })

  const handleExportAllResult = useCallback(
    (data: any) => {
      const params = {
        connectionId: activePaneInfo?.connectionId,
        connectionType: activePaneInfo?.connectionType,
        databaseName: activePaneInfo?.databaseName,

        operatingObject: activePaneInfo && getOperatingObject(
          activePaneInfo,
          //@ts-ignore
          activePaneInfo?.connectionType,
        ),
        statement: editorInstance && getSelectedText(editorInstance),
        containTempTable: false,
        tabKey: activePaneInfo?.key,
      }
      return makeResultAllExport(Object.assign({}, params, data)).then((data) => {
        fetchTaskResult(data)
      })
    },
    [activePaneInfo],
  )

  if (activePaneInfo?.connectionType === 'Hive' ) {
    return <></>
  }

  return (
    <Col>
      <Space size={1}>
        <Tooltip title={(editorInstance && getSelectedText(editorInstance) && (!exportPermission?.permissionResult?.exportDefault || !exportPermission?.dataExport?.status)) ? '暂无导出权限' : "导出选中语句"} placement="bottomLeft" arrowPointAtCenter>
          <Iconfont
            type="icon-xiazai"
            className={classNames(styles.monacoToolbarIcon,
              (editorInstance && getSelectedText(editorInstance) && (!exportPermission?.permissionResult?.exportDefault || !exportPermission?.dataExport?.status)) && styles.iconDisabledWithEvent) }
            onClick={() => {
              if (!editorInstance || !getSelectedText(editorInstance) 
              ) {
                return message.error('请选中导出语句')
              }
              if (exportPermission?.permissionResult?.exportDefault && exportPermission?.dataExport?.status) {
                setVisibleExportAll(true)
              }
            }}
          />
        </Tooltip>
      </Space>
      <ResultAllExport
        result={{ ...(exportPermission || {}) }}
        visible={visibleExportAll}
        setVisible={setVisibleExportAll}
        hanldeExportAll={handleExportAllResult}
        permissionResult={exportPermission?.permissionResult || {}}
      />
    </Col>
  )
})