import React, { useEffect, useMemo, useState } from 'react'
import { useRequest } from 'src/hook'
import { Button, Checkbox, Col, Form, Input, Popover, Radio, Row, Spin, Tooltip, Tree, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Iconfont, TableTransfer } from 'src/components'
import { getAllUsers, queryOrgSdtWithUser, RoleEntity, UserEntity } from 'src/api'
import { userTableColumns } from 'src/constants'
import { DownOutlined } from '@ant-design/icons'
import _, { cloneDeep } from 'lodash'
import { AUDIT_ROLE } from './common'
import styles from './form.module.scss'

export const SysBindUserForm: React.FC<{
  form?: FormInstance
  mode: 'add' | 'edit'
  record?: RoleEntity
  onFormValueChange?: (value: string[]) => void
  onAuditRangeChange?: (value: any[]) => void
}> = ({ form, mode, record, onFormValueChange, onAuditRangeChange = () => { } }) => {
  const { type } = record || {};

  const { loading } = useRequest(
    getAllUsers,
    {
      onSuccess: (data) => {
        setDataSource(data);
      }
    }
  )

  const [dataSource,setDataSource] = useState<any[]>([])

  useEffect(() => {
    if (mode === 'edit' && record) {
      const userIds = record.userInfos.map(({ userId }) => userId)
      form?.setFieldsValue({ boundUsers: userIds })
    }
  }, [form, mode, record])
  
  const onValuesChange = (values: any) => {
    const { boundUsers } = values || {};

    onFormValueChange && onFormValueChange(boundUsers);
  }

  const onCurrentAuditRangeChange = (v: any) => {
    const { userId, auditRange } = v || {};
    const newData = cloneDeep(dataSource);
    for (let i = 0; i < newData.length; i++) {
      const { userId: curUserId } = newData[i];
      if (curUserId === userId) {
        newData[i].auditRange = auditRange;
        break;
      }
    }
    setDataSource(newData)

    let res: any[] = [];
    const values = form?.getFieldValue('boundUsers') || []
    newData.forEach(item => {
      const { userId, auditRange } = item || {}
      if (values.includes(userId)) {
        res.push({ userId, auditRange: auditRange?.map((item: { userId: any }) => item.userId) || null })
      }
    })
    onAuditRangeChange(res)
  }

  const auditColums = [
    { title: '用户名', dataIndex: 'userName', ellipsis: true },
    { title: '账号', dataIndex: 'userId', ellipsis: true },
    {
      title: '审计范围', dataIndex: 'auditRange', render: (val: any[],record:any) => {
        const {userId} = record || {};

        return <AuditRange value={val} userId={userId} onCurrentAuditRangeChange={onCurrentAuditRangeChange}/>
      }
    }
  ]

  return (
    <Form name="bindUserForm" form={form} component={false} onValuesChange={onValuesChange} className={styles.bindUserFormBox}>
      <Form.Item name="boundUsers" valuePropName="targetKeys" noStyle>
        <TableTransfer
          tableLoading={{ left: loading }}
          dataSource={dataSource}
          columns={type !== AUDIT_ROLE ? userTableColumns : undefined}
          rightColumns={auditColums}
          leftColumns={userTableColumns}
          showSearch
          filterOption={(inputValue, record) => {
            const { userId, userName } = (record as unknown) as UserEntity
            return (
              (userId || '')?.indexOf(inputValue) !== -1 ||
              (userName || '')?.indexOf(inputValue) !== -1
            )
          }}
          rowKey={(record) => record.userId}
          titles={['', '已绑用户']}
        />
      </Form.Item>
    </Form>
  )
}

const handleValue = (v: any[] | null, afterData: any[]) => {
  const res: any[] | null = [];
  if (Array.isArray(v) && v.length) {
    if (v[0]?.key) {
      return v
    } else if (typeof v[0] !== 'object') {
      const loopData = (dataList: any[]) => {
        for (let i = 0; i < dataList.length; i++) {
          const { userId, children } = dataList[i];
          if (children?.length) {
            loopData(children);
          }
          if (v.includes(userId)) {
            res.push(dataList[i]);
          }
        }
      }
      loopData(afterData);
      return res?.length ? res : null;
    }
  }
  return v;
}

// 列表里审计范围的自定义或者全部用户的组件
const AuditRange = (
  {
    value: currentValue,
    userId,
    onCurrentAuditRangeChange = (v) => { }
  }: {
    value: any[] | null,
    userId: string,
    onCurrentAuditRangeChange: (v: any) => void
  }
) => {
  const [value, setValue] = useState<any[] | null>(currentValue || [])
  const [isUserChoiceOpen, setIsUserChoiceOpen] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(value?.map(item => item.key) || []);
  const [realCheckedUsers, setRealCheckedUsers] = useState<any[]>(value || []);
  const [searchUsers, setSearchUsers] = useState<any[]>([]);
  const [auditType, setAuditType] = useState('all');
  
  // 查询组织架构用户数据
  const { data = [], run, loading } = useRequest(queryOrgSdtWithUser, {
    manual: true,
    formatResult: (res: any[]) => {
      return treeNodeAdapter(res)
    },
    onSuccess: (res) => {
      setExpandedKeys([res?.[0]?.key])
      setValue(handleValue(currentValue, res))
    }
  })

  useEffect(() => {
    if (isUserChoiceOpen) {
      run();
    }
  }, [isUserChoiceOpen])

  useEffect(() => {
    setAuditType(currentValue?.length ? "custom" : "all")
  }, [currentValue])

  useEffect(() => {
    setCheckedKeys(value?.map((item: { key: any }) => item.key) || [])
    setRealCheckedUsers(value || [])
  }, [isUserChoiceOpen, value])

  const treeData = useMemo(() => {
    const loop = (data: any[]): any[] => {
      return data.map((item: any) => {
        let strTitle = item.title
        const index = strTitle?.indexOf(searchValue)
        const beforeStr = strTitle?.substring(0, index)
        const afterStr = strTitle?.slice(index + searchValue?.length)
        let title = strTitle
        if (index > 15 || (strTitle?.length > 15 && index === -1)) {
          title = (
            <Tooltip title={strTitle}>
              {strTitle.substring(0, 15) + '...'}
            </Tooltip>
          )
        } else if (index > -1) {
          if (strTitle.length > 15) {
            const searchValueIndex = searchValue?.length + index
            if (searchValueIndex > 15) {
              title = (
                <Tooltip title={strTitle}>
                  <span>
                    {beforeStr}
                    <span style={{ color: '#3357ff' }}>
                      {searchValue.substring(index, 15)}
                    </span>
                    ...
                  </span>
                </Tooltip>
              )
            } else {
              title = (
                <Tooltip title={strTitle}>
                  <span>
                    {beforeStr}
                    <span style={{ color: '#3357ff' }}>{searchValue}</span>
                    {strTitle.substring(searchValueIndex, 15)}...
                  </span>
                </Tooltip>
              )
            }
          } else {
            title = (
              <span>
                {beforeStr}
                <span style={{ color: '#3357ff' }}>{searchValue}</span>
                {afterStr}
              </span>
            )
          }
        }

        if (item.children) {
          return {
            ...item,
            title,
            children: loop(item.children),
          }
        }
        return {
          ...item,
          title,
        }
      })
    }
    return loop(data)
  }, [data, searchValue])

  const treeNodeAdapter = (arr: any[], pKey?: ''): any => {
    return arr?.map((item: any) => {
      const { name, orgId, userId, type, child = [], path } = item
      const id = orgId || userId
      const key = pKey ? pKey + '_' + id : id

      return {
        id,
        name,
        type,
        key,
        title: type === "USER" && userId ? `${name}(${userId})` : name,
        children: child?.length ? treeNodeAdapter(child, key).filter(
          (item: { children: any; type: string }) => item.children?.length || item.type === "USER"
        ) : [],
        isLeaf: !child?.length,
        path,
        userId
      }
    })
  }

  const titleRender = (node: any) => {
    const { type, title } = node
    let icon = null
    switch (type) {
      case 'COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'SUB_COMPANY':
        icon = <Iconfont type="icon-org-company" />
        break
      case 'DEPT':
        icon = <Iconfont type="icon-org-department" />
        break
      case 'GROUP':
        icon = <Iconfont type="icon-org-group" />
        break
      case 'USER':
        icon = <Iconfont type="icon-org-user" />
        break
      default:
        break
    }
    const showTitle =
      title?.length > 15 ? (
        <Tooltip title={title}>{title.substring(0, 15) + '...'}</Tooltip>
      ) : (
        title
      )
    return (
      <>
        {icon}&nbsp;{showTitle}
      </>
    )
  }

  // tree数据拉平
  const flatData = () => {
    let dataList: any[] = []
    const generateList = (data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i]
        const { id, type, name, path, key, userId } = node
        dataList.push({ id, type, name, path, key, userId });
        if (node.children) {
          generateList(node.children)
        }
      }
    }
    generateList(treeData)
    return dataList
  }

  const onCheck = async (checkedKeysValue: any, info: any) => {
    const checkedUsers = info.checkedNodes?.filter((i: any) => i?.type === 'USER' && checkedKeysValue.includes(i?.key))
      ?.map((i: any) => {
        return {
          userName: i?.name,
          userId: i?.id,
          path: i?.path,
          key: i?.key
        };
      })
    setRealCheckedUsers(checkedUsers)
    setCheckedKeys(checkedKeysValue)
  }

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
  }

  const handleCheckboxChange = (e: any) => {
    const { value = '', checked = false } = e.target || {};
    let cloneRealCheckedUsers = _.cloneDeep(realCheckedUsers);
    let cloneCheckedKeys = _.cloneDeep(checkedKeys);
    if (!checked) {
      cloneRealCheckedUsers = cloneRealCheckedUsers.filter((item: any) => item?.key !== value);
      cloneCheckedKeys = cloneCheckedKeys.filter((key: any) => key !== value);
    } else {

      const newCheckedUsers = flatData()
        ?.filter((i: any) => i?.type === "USER" && value === i?.key)
        ?.map((i: any) => {
          return {
            userName: i?.name,
            userId: i?.id,
            path: i?.path,
            key: i?.key
          };
        });
      cloneRealCheckedUsers = cloneRealCheckedUsers.concat(newCheckedUsers);
      cloneCheckedKeys.push(value);
    }

    setRealCheckedUsers(cloneRealCheckedUsers);
    setCheckedKeys(cloneCheckedKeys);
  }

  // 搜索
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.trim();
    const searchUsers = flatData().map((item: any) => {
      const { id, type, name, path, key, userId } = item
      if (!value || type !== "USER") {
        return null
      }
      const nameToLowerCase = name?.toLowerCase()
      const userIdToLowerCase = userId?.toLowerCase()
      const valueToLowerCase = value?.toLowerCase()
      if (nameToLowerCase?.includes(valueToLowerCase) || userIdToLowerCase?.includes(valueToLowerCase)) {
        return {
          id,
          name,
          path,
          key
        };
      }
      return null
    }).filter((item, i, self) => item && self.indexOf(item) === i)

    setSearchUsers(searchUsers)
    setSearchValue(e.target.value)
  }

  const onAuditValueChange = () => {
    if (auditType === 'custom' && !realCheckedUsers?.length) {
      return message.error('您没有选择任何用户')
    }
    onCurrentAuditRangeChange({ userId, auditRange: auditType === 'all' ? null : realCheckedUsers })
    if (auditType === 'all') {
      setCheckedKeys([])
      setRealCheckedUsers([])
    }
    closePopover()
  }

  const closePopover = () => {
    setIsUserChoiceOpen(false)
  }

  const onChangeAuditType = (e: any) => {
    const { value } = e.target || {};
    setAuditType(value);
  }

  return <span onClick={e => e.stopPropagation()}>
    <Popover
      visible={isUserChoiceOpen}
      content={
        <div>
          {
            auditType === 'custom' && <>
              <Input.Search
                allowClear
                value={searchValue}
                style={{ width: 280, marginBottom: 8 }}
                onChange={handleSearch}
              />
              {!searchValue && (
                <div style={{ height: '180px', overflow: 'auto' }}>
                  {
                    loading ?
                      <Spin spinning={loading} />
                      :
                      <Tree
                        checkable
                        className={styles.treeWrap}
                        titleRender={titleRender}
                        treeData={treeData}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                      />
                  }
                </div>
              )}
              {/* 搜索用户列表 */}
              {searchValue && !!searchUsers?.length && (
                <div className={styles.searchUserListWrap}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={checkedKeys}
                  >
                    {searchUsers?.map((item) => {
                      return (
                        <Row key={item?.key} style={{ margin: '8px 0' }}>
                          <Col span={24}>
                            <Checkbox value={item?.key} className={styles.flexItem} onChange={handleCheckboxChange}>
                              <span className={styles.flexItem}>
                                <Iconfont
                                  type="icon-org-user"
                                  className="mr4"
                                />
                                <span>
                                  <div>{`${item?.name}(${item?.id})`}</div>
                                  <div>{item?.path}</div>
                                </span>
                              </span>
                            </Checkbox>
                          </Col>
                        </Row>
                      );
                    })}
                  </Checkbox.Group>
                </div>
              )}
            </>
          }
          <div className={styles.bindUserFormBox_btnGroup}>
            <Button size="small" onClick={closePopover}>取消</Button>
            <Button size="small" type="primary" onClick={() => onAuditValueChange()}>确定</Button>
          </div>
        </div>
      }
      title={
        <Radio.Group value={auditType} onChange={onChangeAuditType}>
          <Radio value='all'>全部范围</Radio>
          <Radio value='custom'>自定义</Radio>
        </Radio.Group>
      }
      trigger={['click']}
    >
      <a onClick={() => { setIsUserChoiceOpen(true) }}>
        {auditType === "custom" ? '自定义' : '全部用户'} <DownOutlined />
      </a>
    </Popover >
  </span>
}
