import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  fetchPostFormData,
} from './customFetch'
import { DataSourceType, SdtNodeType, TreeNode } from '../types'

interface ITaskJob {
  connectionId?: number
  cronExpression: string
  description: string
  id?: string
  jobName: string
  jobResource: any
  jobTypes: 'DATA_EXPORT' | 'DESENS_AUTO_DISCOVERY'
  jobStatus: 0 | 1 | 2 | 3 | 4
  lastExecuteTime: string
}

export interface IDesenScanJob extends ITaskJob {
  jobResource: {
    dataSourceType: DataSourceType
    connectionId?: any
    objNames: any[]
    nodeType: SdtNodeType
    autoScanning: any[]
  }
  jobTypes: 'DESENS_AUTO_DISCOVERY'
}

/* 获取所有扫描任务列表 */
export const getDesenScanJobs_api = (
  data: any,
): Promise<{ list: IDesenScanJob[]; total: number }> => {
  data.jobType = 'DESENS_AUTO_DISCOVERY'
  return fetchPost('/export/task/find', data)
}

/* 删除扫描任务 */
export const deleteDesenScanJobs_api = (params: any[]) => {
  return fetchDelete('/export/task', params)
}

/* 创建扫描任务 */
export const createDesenScanJobs_api = (data: IDesenScanJob) => {
  return fetchPost('/export/task', data)
}

/* 编辑扫描任务 */
export const editDesenScanJobs_api = (data: IDesenScanJob) => {
  return fetchPut('/export/task', data)
}

/* 修改任务状态 */
export const changeDesenScanJobStatus_api = (data: any) => {
  return fetchPut(
    `/export/task/${data.taskId}/status?jobStatus=${data.jobStatus}`,
  )
}

/* 获取扫描任务详情 */
export const getDesenScanJobDetail_api = (taskId: any) => {
  return fetchGet(`/export/task/${taskId}`)
}

/* cron表达式校验 */
export const checkJobCron = (data: string) => {
  return fetchPost('/export/task/common/check/cron', data)
}

/* 手动执行扫描任务 */
export const execScantTask = (taskId: string) => {
  return fetchPut(`/export/task/${taskId}/trigger`)
}

/* dump 格式导出 */
export const dumpExport = (
  data: TreeNode & { fileName: string; dataDumpFileType: 'SQL' | 'DMP' },
) => {
  return fetchPost(`/export/dump/export`, data)
}

/* dump 格式导入 */
export const dumpImport = (
  data: TreeNode & { fileName: string; dataDumpFileType: 'SQL' | 'DMP' },
) => {
  return fetchPost(` /export/dump/import`, data)
}

/* dump 导入上传文件 */
export const dumpUpload = (data: any) => {
  return fetchPostFormData(`/export/dump/upload`, data)
}

/* 获取用户任务列表 */
export const getUserImportTaskList = (
  taskStatus?: 'SUCCESS' | 'FAILURE' | 'PROCESSING',
) => {
  return fetchGet(
    `/export/dump`,
    taskStatus && { taskStatus: taskStatus as any },
  )
}

/**
 * 检查导出文件是否存在
 */
export const checkFileExistance = (taskId: string, origin = 'dms') => {
  return fetchGet(`/${origin}/export/exists/${taskId}`)
}
