import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Space,
  message,
  Select,
  Tooltip
} from "antd";
import { FormInstance } from "antd/lib/form";
import { FormOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useRequest } from "src/hook";
import { Iconfont, LinkButton } from "src/components";
import { getSqlMerrorIgnore, postSqlMerrorIgnore } from "src/api";
import styles from "./index.module.scss";
import { ParsingResTabs } from "./ParsingResList";
import { SettingFormContext } from "../../SettingFormContext";

type DataSource = {
  id: number;
  dataSourceType: string;
  flag: boolean;
};

export const ParsingConfigurationCard = () => {
  const form = useContext(SettingFormContext) as FormInstance;

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState<React.Key[]>();
  const [selectedLabels, setSelectedLabels] = useState<string>();

  const {
    data: settingData,
    loading,
    refresh
  } = useRequest<DataSource[]>(() =>getSqlMerrorIgnore());

  const { run: saveChanged } = useRequest(
    postSqlMerrorIgnore,
    {
      manual: true,
      onSuccess: () => {
        message.success('修改成功')
        setIsEditing(false)
        refresh()
      },
    },
  )
  useEffect(() => {
    const selected = settingData?.filter(({ flag }) => flag).map(({ id }) => id)
    const labels = settingData?.filter(({flag}) => flag).map(({dataSourceType}) => dataSourceType)
    setValue(selected)
    setSelectedLabels(labels?.join(','))
  }, [settingData])

  useEffect(() => {
    form.setFields([
      {
        name: "check",
        value: settingData,
      },
    ]);
  }, [form, loading]);

  const generatePostData = (data: React.Key[] | undefined) => {
    if(!data) return []

    const postData = settingData?.map(({ id, dataSourceType }) => {
      if (data.includes(id)) {
        return {
          dataSourceType,
          flag: true,
        }
      } else {
        return {
          dataSourceType,
          flag: false,
        }
      }
    })
    return postData
  }

  const dataSourceOptions = settingData && settingData?.map(({ id, dataSourceType }) => ({
    value: id,
    label: <>
      <Iconfont
        type={"icon-connection-" + dataSourceType}
        className="mr8"
        style={{ fontSize: 14 }}
      ></Iconfont>
      {dataSourceType}
    </>,
    search: dataSourceType
  }));

  return (
    <section className="cq-new-card flow-card" id="ParsingCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">解析配置</h3>
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          <Form.Item label={
          // <Tooltip title=''>
            '宽松拦截模式'
            // <QuestionCircleOutlined style={{ paddingLeft: 5 }} /></Tooltip>
          } 
            labelCol={{span: 3}}>
          <Space align="baseline">
            <Form.Item
              name="check"
              // hidden={!editing}
              noStyle
              valuePropName="checked"
        
              // wrapperCol={{ span: 8 }}
            >
              {isEditing ? (
                <Select
                  mode="multiple"
                  style={{minWidth: 300}}
                  placeholder="选择数据源"
                  optionFilterProp={'search'}
                  options={dataSourceOptions}
                  value={value}
                  maxTagCount={3}
                  onChange={(values) => {
                   setValue(values);
                  }}
                  allowClear
                />
              ) : (
                    <div style={{ display: "inline-block", minWidth: 300, overflowWrap: "anywhere", lineHeight: "32px" }}>
                      {selectedLabels}
                    </div>
              )}
            </Form.Item>
            <Form.Item noStyle>
            {isEditing ?
              <LinkButton
              key="save"
              onClick={() => saveChanged(generatePostData(value)! )}
            >
  
              保存
            </LinkButton>
            :
              <FormOutlined
             style={{ color:"#3357FF"}}
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            }
            </Form.Item>
          </Space>
          </Form.Item>
        </Card>
        <ParsingResTabs />
      </section>
    </section>
  );
};
