import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { getLastConnectionStatus, testConnection } from 'src/api'
import { useRequest } from 'src/hook';

interface IProps {
  params: any;
  updateNow?: boolean;
  gap?: number;
  alreadyTestedRusult?: boolean | undefined;
}

const SUCCESS = 'success';
const FAIL = 'fail';
const NONE = 'none';

type IStatus = typeof SUCCESS | typeof FAIL | typeof NONE;

const COLOR_MAP = {
  [SUCCESS]: '#87d068',
  [FAIL]: '#f50',
  [NONE]: '#fff'
}

export const ConnectionStatus = (props: IProps) => {
  const { params, updateNow, alreadyTestedRusult } = props;

  // 标识组件状态，0是第一次渲染，1是第一次请求，此组件需要初次渲染时不请求，后续更新需要请求testConnection
  const [flag, setFlag] = useState(0);
  const [status, setStatus] = useState<IStatus>(NONE);

  const { loading: testLoading, run: updateStatus } = useRequest(() => testConnection(params), {
    manual: true,
    onSuccess: () => setStatus(SUCCESS),
    onError: () => setStatus(FAIL),
  });

  const { loading: lastStatusLoading } = useRequest(
    () => getLastConnectionStatus({ connectionId: params?.connectionId }),
    {
      onSuccess: (res: { status: IStatus }) => {
        const { status } = res;
        setStatus(status);
      }
    }
  );

  useEffect(() => {
    let result = NONE as IStatus;
    switch (alreadyTestedRusult) {
      case true:
        result = SUCCESS;
        break;
      case false:
        result = FAIL;
        break;
    }
    setStatus(result)
  }, [alreadyTestedRusult])

  useEffect(() => {
    if (flag) {
      updateStatus();
    }
    setFlag(flag + 1);
  }, [updateNow, updateStatus])

  const loading = testLoading || lastStatusLoading;
  const style = {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: loading ? COLOR_MAP[NONE] : COLOR_MAP[status]
  }

  // 标识为none就展示空气
  if (status === NONE && !loading) {
    return <></>;
  }

  return (
    <div style={{ display: "inline-block", marginRight: "8px" }}>
      <Spin size="small" spinning={loading}>
        <div style={style} />
      </Spin>
    </div>
  );
}
