import dayjs from 'dayjs'
import { AuditUnit } from 'src/api'

export const getUnixTimeRange = (
  days: 1 | 7 | 30,
): { beginTimeMs: number; endTimeMs: number } => {
  const date = new Date()
  const y = date.getFullYear()
  const m = date.getMonth()
  const d = date.getDate()
  const Today = {
    beginTimeMs: Number(new Date(y, m, d)),
    endTimeMs: Number(new Date(y, m, d, 24)) - 1,
  }
  const Last7Days = {
    beginTimeMs: Number(new Date(y, m, d - 6, 0)),
    endTimeMs: Number(new Date(y, m, d, 24)) - 1,
  }
  const Last30Days = {
    beginTimeMs: Number(new Date(y, m, d - 29, 0)),
    endTimeMs: Number(new Date(y, m, d, 24)) - 1,
  }
  switch (days) {
    case 30:
      return Last30Days
    case 7:
      return Last7Days
    case 1:
    default:
      return Today
  }
}

export const getUnit = (days: 1 | 7 | 30 | 0): AuditUnit => {
  switch (days) {
    case 30:
      return 'DAY'
    case 7:
      return 'DAY'
    case 1:
      return 'HOUR'
    case 0:
      return 'HOUR' //单独选择某一天
    default:
      return 'DAY'
  }
}

export const getUnitBase = (days: 1 | 7 | 30): any[] => {
  const date = new Date()
  const y = date.getFullYear()
  const m = date.getMonth()
  const d = date.getDate()

  if (days === 1) {
    const unitBase = Array.from({ length: 24 }, (_, i) => ({
      unit: dayjs(new Date(y, m, d, i)).format('YYYY-MM-DD HH:mm:ss'),
    }))
    return unitBase
  }
  const unitBase = Array.from({ length: days }, (_, i) => ({
    unit: dayjs(new Date(y, m, d - days + i + 1,8 )).format('YYYY-MM-DD HH:mm:ss'),
  }))
  return unitBase
}
//自定义时间范围
export const getCustomUnitBase = (startTDate: string, endDate: string): any[] => {
  const diffDays = dayjs(endDate).diff(startTDate,'day') + 1;
   const date = new Date(startTDate)
  const y = date.getFullYear()
  const m = date.getMonth()
  const d = date.getDate()
 
  if (diffDays === 1) {
    const unitBase = Array.from({ length: 24 }, (_, i) => ({
      unit: dayjs(new Date(y, m, d, i)).format('YYYY-MM-DD HH:mm:ss'),
    }))
    return unitBase
  }
 
  const unitBase = Array.from({ length: diffDays}, (_, i) => ({
    unit: dayjs(new Date(y, m, d +i,8)).format('YYYY-MM-DD HH:mm:ss'),
  }))

  return unitBase
}

export const ChartColors = [
  '#2f57fb',
  '#12b799',
  '#ff7a7a',
  '#7a79fb',
  '#8bfecf',
  '#9e58fa',
]

export const ChartColorsGradient = ChartColors.map(
  (color) => `l(100) 0:${color}ff 1:${color}66`,
)
