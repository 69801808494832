import { fetchPost } from "../customFetch";

// 0:初始化, 1:等待中, 2:运行中, 3:暂停, 4:中止, 5:成功, 6:失败
export type TaskStatus = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface QueryTask {
	pageNo: number | null;
	pageSize: number | null;
	status: number[];
}

export interface QueryChildTask extends QueryTask {
	parentId: number;
}

export interface CreateTransferTask {
	sourceId: number;
	sourceType: string;
	sourceDatabase: string;
	sourceSchema: string;
	targetId: number;
	targetType: string;
	targetDatabase: string;
	targetSchema: string;
	overwriteTarget: boolean;
	dataDuplicateTables: string[];
	owner: string;
	taskName: string;
	desensitizationGrade: number;
}

export interface TaskItem {
	id: number;
	state: number;
	sourceId: number;
	sourceType: string;
	sourceDatabase: string;
	sourceSchema: string;
	targetId: 1;
	targetType: string;
	targetDatabase: string;
	targetSchema: string;
	overwriteTarget: boolean;
	owner: string;
	taskName: string;
	desensitizationGrade: number;
	taskType: string;
	taskId: string;
	totalTaskNum: number;
	successNum: number;
	failureNum: number;
	createTime: string;
	bytesSpeed: string;
	parentId: number;
	taskGroupId: number;
}

export interface QueryTaskResponse {
	pageNo: number;
	pageSize: number;
	totalPage: number;
	totalItem: number;
	data: Array<TaskItem>;
}

export interface QueryChildTaskResponse extends QueryTaskResponse {}
// export interface QueryChildTaskResponse extends QueryTaskResponse {
// 	taskGroupId: number;
// 	parentId: number;
// 	log: string;
// 	sourceTable: string;
// 	targetTable: string;
// 	endTime: string;
// 	totalReadRecords: number;
// 	desensColumns: number;
// }

/**
 * 停止静态脱敏任务
 * @param params.id 任务 id
 * @returns
 */
export function stopTransferTask(params: number) {
	return fetchPost("/desens/transfer/dataDuplication/stop", params as unknown as string);
}

/**
 * 暂停静态脱敏任务
 * @param params.id 任务 id
 * @returns
 */
export function pauseTransferTask(params: number) {
	return fetchPost("/desens/transfer/dataDuplication/pause", params as unknown as string);
}

/**
 * 续传静态脱敏任务
 * @param params.id 任务 id
 * @returns
 */
export function resumeTransferTask(params: number) {
	return fetchPost("/desens/transfer/dataDuplication/resume", params as unknown as string);
}

/*
 * 失败任务重新开始
 * @param params.id 任务 id
 * @returns
 */
export function resStartTask(params: number) {
	return fetchPost("/desens/transfer/dataDuplication/retry", params as unknown as string);
}

/**
 * 查询静态脱敏子任务
 * @param {number} params.pageNo 页码：null或< 0时不分页: 1
 * @param {number} params.pageSize 分页大小：null或< 0时不分页: 20
 * @param {number} params.status 0:初始化, 1:等待中, 2:运行中, 3:暂停, 4:中止, 5:成功, 6:失败
 * @param {number} params.parentId 父任务 id
 * @returns
 */
export function queryChildTransferTask(params: QueryChildTask): Promise<QueryChildTaskResponse> {
	return fetchPost("/desens/transfer/taskGroup/query", params);
}

/**
 * 查询静态脱敏任务
 * @param {number} params.pageNo 页码：null或< 0时不分页: 1
 * @param {number} params.pageSize 分页大小：null或< 0时不分页: 20
 * @param {number} params.status 0:初始化, 1:等待中, 2:运行中, 3:暂停, 4:中止, 5:成功, 6:失败
 * @returns
 */
export function queryTransferTask(params: QueryTask): Promise<QueryTaskResponse> {
	return fetchPost("/desens/transfer/dataDuplication/query", params);
}

/**
 * 创建静态脱敏任务
 * @returns
 */
export function createTransferTask(params: CreateTransferTask) {
	return fetchPost("/desens/transfer/dataDuplication/create", params);
}

/**
 * 测试连接
 */
export function testConnect(params: { connectionId: number; dataSourceType: string }) {
	return fetchPost("/dms/connection/effectiveness4Id", params);
}

/**
 * 查询静态脱敏子任务
 * @param {number} params.pageNo 页码：null或< 0时不分页: 1
 * @param {number} params.pageSize 分页大小：null或< 0时不分页: 20
 * @param {number} params.status 0:初始化, 1:等待中, 2:运行中, 3:暂停, 4:中止, 5:成功, 6:失败
 * @param {number} params.parentId 
 * @returns
 */
export function queryTransferTaskGroup(params: QueryTask): Promise<QueryTaskResponse> {
	return fetchPost("/desens/transfer/taskGroup/query", params);
}

/**
 * 暂停静态脱敏子任务
 * @param params.ddtId 父任务id
 * @param params.ttgId 子任务id
 * @returns
 */
export function pauseTaskGroup(params: {
	ddtId: string | number,
	ttgId: string | number
}) {
	return fetchPost("/desens/transfer/taskGroup/pause", params);
}

/**
 * 续传静态脱敏子任务
 * @param params.ddtId 父任务id
 * @param params.ttgId 子任务id
 * @returns
 */
export function resumeTaskGroup(params: {
	ddtId: string | number,
	ttgId: string | number
}) {
	return fetchPost("/desens/transfer/taskGroup/resume", params);
}

/**
 * 重试静态脱敏子任务
 * @param params.ddtId 父任务id
 * @param params.ttgId 子任务id
 * @returns
 */
export function retryTaskGroup(params: {
	ddtId: string | number,
	ttgId: string | number
}) {
	return fetchPost("/desens/transfer/taskGroup/retry", params);
}

/**
 * 查看静态脱敏子任务日志
 * @param params.id 任务 id
 * @returns
 */
export function logTaskGroup(params: number) {
	return fetchPost("/desens/transfer/taskGroup/log", params as unknown as string);
}