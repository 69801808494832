import React, { useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { Form, Input, message } from 'antd'
import { refreshOnRoot } from '../sdtSlice'
import { addSdtGroup } from 'src/api'
import { nameValidator } from 'src/util/nameValidator'

export const AddSubSdtGroupModal = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { AddSubSdtGroup } = useSelector((state) => state.modal)
  const { selectedNode } = useSelector((state) => state.sdt)
  const [addSdtGroupForm] = Form.useForm()
  const { groupId, nodeName } = selectedNode
  return (
    <UIModal
      title="添加子组"
      visible={AddSubSdtGroup}
      onCancel={() => dispatch(hideModal('AddSubSdtGroup'))}
      onOk={() => {
        addSdtGroupForm.validateFields().then(({ groupName }) => {
          if (!groupId) return
          setConfirmLoading(true)
          addSdtGroup(groupName, groupId)
            .then(() => {
              message.success('添加组成功')
              dispatch(hideModal('AddSubSdtGroup'))
              dispatch(refreshOnRoot())
            })
            .finally(() => setConfirmLoading(false))
        })
      }}
      afterClose={() => addSdtGroupForm.resetFields()}
      width={320}
      confirmLoading={confirmLoading}
    >
      <Form form={addSdtGroupForm}>
        <Form.Item label="父组名称">
          <span>{nodeName}</span>
        </Form.Item>
        <Form.Item
          label="组名"
          name="groupName"
          rules={[
            { required: true, message: '请输入组名称' },
            { max: 30 },
            { validator: nameValidator },
          ]}
        >
          <Input placeholder="请输入组名"></Input>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
