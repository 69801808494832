import React from 'react'
import { Form, Input, Spin } from 'antd'
import { UIModal } from 'src/components'
import { BaseResult } from '@ahooksjs/use-request/lib/types'

interface SaveTableDesignProps {
  visibleSaveDesign: boolean
  setVisibleSaveDesign: (visible: boolean) => void
  requestGeneratedSql: BaseResult<string, []>
  editTable: (generatedSql: string) => Promise<any>
  tableName?: string
}

export const SaveTableDesignModal: React.FC<SaveTableDesignProps> = (props) => {
  const {
    requestGeneratedSql,
    tableName,
    visibleSaveDesign,
    setVisibleSaveDesign,
    editTable,
  } = props

  const {
    data: generatedSql,
    loading: loadingGetSql,
    mutate: mutateSql,
  } = requestGeneratedSql

  const handleExecute = () => {
    if (!generatedSql) return
    editTable(generatedSql).then(() => setVisibleSaveDesign(false))
  }

  return (
    <UIModal
      title={`${tableName} - 修改表`}
      visible={visibleSaveDesign && !!generatedSql}
      onCancel={() => setVisibleSaveDesign(false)}
      onOk={handleExecute}
      okButtonProps={{ disabled: !generatedSql }}
      afterClose={() => mutateSql('')}
      width={640}
      okText="执行"
    >
      <Spin spinning={loadingGetSql}>
        <Form>
          <Input.TextArea
            autoSize={{ minRows: 5 }}
            readOnly
            value={generatedSql}
          ></Input.TextArea>
        </Form>
      </Spin>
    </UIModal>
  )
}
