import React from 'react';
import { Form, Input, Spin } from 'antd';
import { useRequest } from 'src/hook';
import type { DataSourceType } from 'src/types';
import { getTableOption_api } from 'src/api';
import { getPointDatabaseOptions, ALL_DATABASE_OPTION_FIELDS } from './utils';
import styles from './index.module.scss';

interface IOptionPaneProps {
  connectionId: string | number | undefined;
  connectionType: DataSourceType;
  databaseName: string | undefined;
  schemaName: string | undefined;
  tableName: string;
}

const OptionPane = ({
  connectionId,
  connectionType,
  databaseName,
  schemaName,
  tableName,
}: IOptionPaneProps) => {
  const [form] = Form.useForm();

  const OPTIONS = connectionType && getPointDatabaseOptions(connectionType);
  const { loading } = useRequest(
    () =>
      getTableOption_api({
        connectionId,
        connectionType,
        databaseName,
        schemaName,
        tableName,
      }),
    {
      onSuccess: (data: any) => {
        form.setFieldsValue({ ...data });
      },
      refreshDeps: [tableName],
    }
  );

  return (
    <Form
      form={form}
      labelAlign="left"
      labelCol={{ span: 6, offset: 0 }}
      wrapperCol={{ span: 20 }}
      className={styles.optionForm}
    >
      <Spin spinning={loading}>
        <div className={styles.partitionDetail}>
          {OPTIONS?.map((option) => (
            <Form.Item label={ALL_DATABASE_OPTION_FIELDS[option]} name={option}>
              <Input disabled />
            </Form.Item>
          ))}
        </div>
      </Spin>
    </Form>
  );
};

export default OptionPane;
