import React, { useEffect, useState } from 'react'
import { Card, Form, Switch, Select } from 'antd'
import { useRequest, useDispatch, useSelector } from 'src/hook'
import { FormInstance } from 'antd/lib/form'
import {
  getUserConfig,
  patchUserConfig
} from 'src/api'
import { setEditorPromptOnClose } from 'src/store/extraSlice/appearanceSlice'
import { setHotKeys } from 'src/store/extraSlice/settingSlice'
import { ENCODINGS, FormLayout } from 'src/constants'
import { setUserInfo } from 'src/appPages/login/loginSlice'
import { HotKeys } from '@bintoolsfe/bintools_ui'

const encodingList = ENCODINGS.map(e => {
  const v = e.valueOf()
  return { label: v, value: v }
})

export const PreferenceSetting = () => {

  const dispatch = useDispatch()
  const [configForm] = Form.useForm()
  const { execute, execPlan, prompt } = useSelector(state => state.setting.hotKeys)
  const [value, setValue] = useState<string>('UTF-8')

  const { userInfo } = useSelector(state => state.login)
  const { encoding: encod, ...rest } = userInfo

  const { data: userConfig, run: tryGetUserConfig } = useRequest(
    getUserConfig,
    {
      onSuccess: ({ editorPromptOnClose, hotKeys }) => {
        dispatch(setEditorPromptOnClose(editorPromptOnClose))
        if (!!hotKeys) {
          const { execute, execPlan, prompt } = hotKeys
          !!execute && dispatch(setHotKeys({ execute: execute }))
          !!execPlan && dispatch(setHotKeys({ execPlan: execPlan }))
          !!prompt && dispatch(setHotKeys({ prompt: prompt }))
        }
      },
    },
  )
  const { run: trySetUserConfig } = useRequest(patchUserConfig, {
    manual: true,
  })
  const { editorPromptOnClose, encoding: encodingSet } = userConfig || {}

  // 快捷键配置重复检测
  const duplicatCheck = (form: FormInstance<any>) => {
    const valuesObj = form.getFieldsValue(['execute', 'execPlan', 'prompt'])
    const valuesArray = Object.values(valuesObj).filter(v => v !== undefined)
    const valuesArrayCopy = Array.from(new Set(valuesArray))
    // 重复返回 true
    return valuesArray.length !== valuesArrayCopy.length
  }

  // 快捷键配置校验
  const hotKeyValidator = (_: any, val: string) => {
    if (!val.includes('+')) {
      return Promise.reject('快捷键必须为组合键，请重新输入')
    }
    const isPassed = hotKeyValidate(val)
    if (!isPassed) {
      return Promise.reject(
        '快捷键格式: ctrl/alt/shift/command + 数字或字母',
      )
    }
    if (duplicatCheck(configForm)) {
      return Promise.reject('不能重复，请重新配置快捷键')
    }
    return Promise.resolve()
  }

  const onChange = (v: any) => {
    const key = Object.values(v).join()
    const isDuplicated = duplicatCheck(configForm)
    const isPassed = hotKeyValidate(key)
    const keyValues = configForm.getFieldsValue()

    if (key.includes('+') && !isDuplicated && isPassed) {
      dispatch(setHotKeys(v))
      trySetUserConfig({ hotKeys: keyValues }).finally(() => tryGetUserConfig())
    } else return
  }

  useEffect(() => {
    configForm.setFieldsValue({ execPlan: execPlan, execute: execute, prompt: prompt })
  }, [execPlan, execute, prompt, configForm])

  useEffect(() => {
    if (encodingSet) {
      setValue(encodingSet)
    }
  }, [encodingSet])
  return (
    <section className="cq-new-card flow-card">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">偏好设置</h3>
      </div>
      <section className="card__content">
        <Card bordered={false}>
          <Form form={configForm} {...FormLayout}>
            <Form.Item label="编辑器离开提示">
              <Switch
                checkedChildren="开启"
                unCheckedChildren="关闭"
                checked={editorPromptOnClose}
                onChange={(editorPromptOnClose) => {
                  trySetUserConfig({ editorPromptOnClose }).finally(() =>
                    tryGetUserConfig(),
                  )
                }}
              ></Switch>
            </Form.Item>
            <Form.Item label="编码格式">
              <Select
                value={value}
                options={encodingList}
                onChange={(encoding: string) => {
                  setValue(encoding)
                  trySetUserConfig({ encoding }).finally(() => {
                    tryGetUserConfig()
                    const userInfoCopy = Object.assign({}, userInfo, { personalSettings: { encoding: encoding, ...rest } })
                    dispatch(setUserInfo(userInfoCopy))
                  })
                }}
              ></Select>
            </Form.Item>
          </Form>
          <Form form={configForm} {...FormLayout}>
            <Form.Item
              label="提示快捷键"
              name="prompt"
              rules={[{ validator: hotKeyValidator }]}
              initialValue={prompt}
            >
              <HotKeys needTest={false} onChange={(v: any) => onChange({ prompt: v })} />
            </Form.Item>
            <Form.Item
              label="执行快捷键"
              name="execute"
              rules={[{ validator: hotKeyValidator }]}
              initialValue={execute}
            >
              <HotKeys needTest={false} onChange={(v: any) => onChange({ execute: v })} />
            </Form.Item>
            <Form.Item
              label="执行计划快捷键"
              name="execPlan"
              rules={[{ validator: hotKeyValidator }]}
              initialValue={execPlan}
            >
              <HotKeys needTest={false} onChange={(v: any) => onChange({ execPlan: v })} />
            </Form.Item>
          </Form>
        </Card>
      </section>
    </section>
  )
}

const hotKeyValidate = (key: string) => {
  const varArr = key.split('+')
  const reg = /^[0-9a-z]$/
  const keyModCheck = ['ctrl', 'alt', 'shift', 'win', 'command'].includes(varArr[0])
  const keyCodeCheck = reg.test(varArr[1])

  return keyModCheck && keyCodeCheck
}
