import React from 'react'
import ReactJson from 'react-json-view'

import { UIModal } from 'src/components'
import styles from './mongoJsonViewModal.module.scss'
import { useSelector } from 'src/hook'

interface IProps {
  dataSource: any[]
  visible: boolean
  setVisible: Function
}

export const MongoJsonViewerModal = ({
  dataSource,
  visible,
  setVisible,
}: IProps) => {
  const { theme } = useSelector((state) => state.appearance)
  const allowCopy = useSelector((state) => state.login.userInfo.copySetting)

  if (!dataSource || !visible) return null

  return (
    <UIModal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      bodyStyle={{ minHeight: '500px' }}
      title="查看详情"
      className={styles.modal}
    >
      <ReactJson
        src={dataSource || {}}
        theme={theme === 'dark' ? 'monokai' : 'bright:inverted'}
        style={{ backgroundColor: 'transparent' }}
        name={null}
        enableClipboard={allowCopy}
        displayDataTypes={false}
        quotesOnKeys={false}
        collapsed={false}
      ></ReactJson>
    </UIModal>
  )
}
