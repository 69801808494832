import React from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Card, Col, Row } from 'antd'
import { openFlowForm } from './flowFormsSlice'
import { flowTypes, flowTypesCq3 } from 'src/types'
import { flowTypeLabelMap } from './constants'
import { Iconfont } from 'src/components'
import kebabCase from 'lodash/kebabCase'
import upperFirst from 'lodash/upperFirst'
import styles from './flowPage.module.scss'
import classNames from 'classnames'

export const FlowApply = () => {
  const dispatch = useDispatch()
  const { productGrade } = useSelector((state) => state.login)

  /** 只展示数据操作提权 其余隐藏掉 */
  const newFlowTypesCq3 = flowTypesCq3.filter(item => {
    if (item === 'dataManipulation') {
      return item
    }
  })
  /**  只展示数据操作提权 其余隐藏掉 */
  const newFlowTypes = flowTypes?.filter(item => {
    if (item === 'dataManipulation') {
      return item
    }
  })

  const flowTypesFilter = productGrade === "CQ3" ? newFlowTypesCq3 : newFlowTypes

  return (
    <Card
      className={styles.contentCard}
      title="申请流程"
      bordered={false}
      bodyStyle={{ padding: 0 }}
    >
      <div className={styles.flowApplyWrapper}>
        <Row gutter={[100, 96]}>
          {flowTypesFilter.map((type) => (
            <Col key={type}>
              <div
                className={styles.flowTrigger}
                onClick={() => {
                  dispatch(openFlowForm({ type }))
                }}
              >
                <div
                  className={classNames(
                    styles.flowTriggerIcon,
                    styles[`flowTrigger${upperFirst(type)}`],
                  )}
                >
                  <Iconfont type={`icon-flow-${kebabCase(type)}`} />
                </div>
                <div className={styles.flowTriggerText}>
                  {flowTypeLabelMap[type]}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Card>
  )
}
