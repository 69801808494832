import React from 'react'
import { Row } from 'antd'
import { UserCard } from './UserCard'
import { OperationCard } from './OperationCard'

export const StatisticalCard = ({ daysSpan = 7 }: any) => {
  
  return (
    <Row gutter={[24, 24]}>
      <OperationCard></OperationCard>
      <UserCard></UserCard>
    </Row>
  );
}
