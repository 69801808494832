import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Table, DatePicker, Select, Row, Col, Button, Popconfirm } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table'
import {
  getUserHistoryRecordsBySearch,
  DataSourceType,
  SqlType,
  ExecutionReportRequest,
  SqlTypes,
  exportExecutionReportsByUserLog,
  getToolbarDataNodeList
} from 'src/api'
import { UICard } from 'src/components'
import { UserHistoryColumns } from 'src/constants'
import { useRequest, useQuery } from 'src/hook'
import dayjs, { Dayjs } from 'dayjs'
import { formatDateRange, handleDownload } from 'src/util'
import moment from 'moment';
import { openNotification } from 'src/components/ExportNotification'

const { RangePicker } = DatePicker
const { Option } = Select

interface FilterParams {
  executors: string[]
  timeRange: [number, number] | null
  dbTypes?: DataSourceType[]
  sqlTypes?: SqlType[]
  resultFlag?: string
}

// 根据组件 filterParams 状态构造出查询请求所需要的参数
const getRequestParams = (filterParams: FilterParams) => {
  const { executors, timeRange, dbTypes, sqlTypes, resultFlag } = filterParams
  // 构造查询参数
  const params: Partial<ExecutionReportRequest> = {
    executeBeginMs: timeRange?.[0],
    executeEndMs: timeRange?.[1],
  }
  if (executors.length) {
    params.executors = executors
  }
  if (dbTypes && dbTypes[0]) {
    params.dbTypes = dbTypes.map((dataSourceType) =>
      dataSourceType.toUpperCase(),
    )
  }
  if (sqlTypes && sqlTypes[0]) {
    params.sqlTypes = sqlTypes
  }
  if (resultFlag !== undefined) {
    params.resultFlag = Number(resultFlag)
  }
  return params
}


export const UserSqlLogs = () => {
  // 新增查询状态
  const query = useQuery()
  const [filterParams, setFilterParams] = useState<FilterParams>({
    executors: [],
    timeRange: [
      dayjs().subtract(3, 'day').startOf('d').valueOf(),
      dayjs().endOf('d').valueOf(),
    ],
    resultFlag: query.get('result') || undefined,
  })
  // 全部用户列表
  const [connectionList, setConnectionList] = useState<any[]>([])

  // 分页器状态
  const [pageState, setPageState] = useState({
    current: 1,
    pageSize: 10,
  })

  // table dataSource, total and loading status
  const [dataSource, setDataSource] = useState<any>([])
  const [total, setTotal] = useState<number>()
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)

  const { executors, timeRange, sqlTypes, resultFlag } = filterParams
  const rangeValue: any = useMemo(() => {
    if (timeRange === null) {
      return null
    } else {
      const range = timeRange.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [timeRange])

  const SqlTypeOptions = SqlTypes.map((type) => ({ label: type, value: type }))

  // 初次加载请求[执行连接]接口
  useEffect(() => {
    const getConnectionList = async () => {
      try {
        const connections = await getToolbarDataNodeList({
          nodeType: 'root',
        })
        setConnectionList(connections)
      } catch { }
    }
    getConnectionList()
  }, [])

  const {
    data: userHistoryData = { totalCount: 0, historyRecords: [] },
    run: tryGetUserHistoryRecords,
    loading: loadingGetRecords,
  } = useRequest(getUserHistoryRecordsBySearch, {
    manual: true,
    onSuccess: (res) => {
      // const {
      //   pagination: { page, pageSize },
      // } = res
      setDataSource(userHistoryData)
      setTotal(total)
      // setPageState({ current: page, pageSize})
    },
  })

  // 获取分页数据
  const getDataSource = useCallback(
    async (params) => {
      const { current, pageSize } = pageState
      const offset = (current - 1) * pageSize
      const limit = pageSize
      try {
        setLoadingTableData(true)
        tryGetUserHistoryRecords({
          ...params,
          limit,
          offset,
          pagination: { pageSize, page: current },
        })
      } catch {
        setDataSource([])
        setTotal(undefined)
      } finally {
        setLoadingTableData(false)
      }
    },
    [pageState, tryGetUserHistoryRecords],
  )

  useEffect(() => {
    const params = getRequestParams(filterParams)
    getDataSource(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, getDataSource])


  const pagination: TablePaginationConfig = {
    showQuickJumper: true,
    total: userHistoryData.totalCount,
    onChange: (current, pageSize = 10) => {
      setPageState({ current, pageSize })
    },
  }

  const exportData = () => {
    exportExecutionReportsByUserLog(getRequestParams(filterParams)).then(res => {
      const origin = window?.location?.origin;
      openNotification(
        '任务 执行历史导出',
        '已经执行完成，文件生成成功',
        () => handleDownload({ href: `${origin}/export/export/download/${res}` })
      )
    })
  }

  const extra = <Row justify="end" gutter={10}>
    过滤条件:
    <Col>
      <Select
        mode="multiple"
        placeholder="执行连接"
        value={executors}
        onChange={(selected) => {
          setFilterParams({ ...filterParams, executors: selected })
        }}
        maxTagCount={2}
        allowClear
        style={{ minWidth: 144 }}
        size="small"
        optionFilterProp="label"
      >
        {connectionList.map((connection) => {
          const { connectionId, nodeName } = connection
          return (
            <Option key={connectionId} value={connectionId} label={nodeName}>
              {nodeName}
            </Option>
          )
        })}
      </Select>
    </Col>
    <Col>
      <Select
        placeholder="语句类型"
        value={sqlTypes}
        onChange={(sqlTypes) => {
          setFilterParams({ ...filterParams, sqlTypes })
        }}
        allowClear
        style={{ minWidth: 96 }}
        mode="multiple"
        maxTagCount={2}
        size="small"
        options={SqlTypeOptions}
      ></Select>
    </Col>
    <Col>
      <Select
        placeholder="执行结果"
        value={resultFlag}
        onChange={(resultFlag: string) => {
          setFilterParams({ ...filterParams, resultFlag })
        }}
        allowClear
        style={{ minWidth: 96 }}
        size="small"
      >
        <Option key="1" value="1">
          执行成功
        </Option>
        <Option key="0" value="0">
          执行失败
        </Option>
      </Select>
    </Col>
    <Col>
      <RangePicker
        value={rangeValue}
        ranges={{
          '今日': [moment(), moment()],
          '一周': [moment().startOf('week'), moment().endOf('week')],
          '一月': [moment().startOf('month'), moment().endOf('month')],
        }}
        onChange={(dates: any) => {
          if (dates === null) {
            return setFilterParams({
              ...filterParams,
              timeRange: null,
            })
          }
          const [start, end] = formatDateRange(dates)
          if (start && end) {
            setFilterParams({
              ...filterParams,
              timeRange: [start, end],
            })
          }
        }}
        allowClear
        size="small"
        placeholder={['开始日期', '结束日期']}
      />
    </Col>
    <Col>
      <Button
        disabled={loadingTableData}
        onClick={() => setPageState({ current: 1, pageSize: 10 })}
        size="small"
        type="primary"
        className="mr8"
      >
        查询
      </Button>
      <Button
        onClick={() =>
          setFilterParams({
            executors: [],
            timeRange: null,
          })
        }
        size="small"
        className="mr8"
      >
        重置
      </Button>
      <Popconfirm
        title="确定导出当前筛选结果？"
        onConfirm={exportData}
        placement="bottomRight"
      >
        <Button size="small">导出</Button>
      </Popconfirm>
    </Col>
  </Row>

  return (
    <UICard extra={extra} bodyStyle={{ minHeight: 509 }}>
      <Table
        size="small"
        columns={UserHistoryColumns}
        dataSource={dataSource.historyRecords}
        pagination={{
          ...pagination,
          ...pageState
        }}
        loading={loadingGetRecords}
        scroll={{ y: '50vh' }}
      ></Table>
    </UICard>
  )
}
