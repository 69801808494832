import { v4 as uuid } from 'uuid'
import type { ColDef } from '@ag-grid-community/core'

export const indexColId = uuid()

export const indexColDef: ColDef = {
  colId: indexColId,
  headerName: '',
  cellClass: 'number-cell',
  width: 50,
  cellRenderer: 'rowIndexRenderer',
  sortable: false,
  editable: false,
}
