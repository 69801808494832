import React, { useState, useCallback, useEffect } from 'react'
import { Table, Tooltip } from 'antd'
import { TableProps, ColumnsType } from 'antd/lib/table'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import classNames from 'classnames'

import style from './index.module.scss'

/* TODO:表格拖拽多次渲染优化 */
export const ResizeTable = <RecordType extends object = any>(
  props: TableProps<RecordType>,
) => {
  const { columns } = props

  const [innerColumns, setInnerColumns] = useState<ColumnsType>([])

  /* 修改列宽包裹函数 */
  const resizeWrap = useCallback(
    (i: number) =>
      (_: any, { size }: { size: { width: number } }) => {
        const nextCol = [...innerColumns]
        nextCol[i] = {
          ...nextCol[i],
          width: size.width,
        }
        setInnerColumns(nextCol)
      },
    [innerColumns, setInnerColumns],
  )

  /* 双击header 自动换行 */
  const autoTextWrap = useCallback(
    (i: number) => (_: any, p: any) => {
      const nextCol = [...innerColumns]
      nextCol[i] = {
        ...nextCol[i],
        ellipsis: !nextCol[i]?.ellipsis,
      }
      setInnerColumns(nextCol)
    },
    [innerColumns, setInnerColumns],
  )

  useEffect(() => {
    if (!columns) return
    setInnerColumns([...columns] as any)
  }, [columns, setInnerColumns])

  if (!innerColumns) return null

  const myCol = innerColumns?.map((col: any, i) => {
    const nextCol = { ...col } as any
    nextCol.onHeaderCell = (column: any) => ({
      width: column.width || 200,
      onResize: resizeWrap(i),
      onDoubleClick: autoTextWrap(i),
    })

    if (col.ellipsis && col.dataIndex && !col.render) {
      nextCol.render = (content: any) => {
        if (!content) return null
        return <Tooltip title={content}>{content}</Tooltip>
      }
    }

    return nextCol
  })

  return (
    <Table<RecordType>
      className={classNames(style.CQTable, props.className)}
      {...props}
      pagination={{
        showTotal: (total, range) => {
          return `总共${total}条数据`;
        }
      }}
      columns={myCol}
      components={{ header: { cell: ResizableTitle } }}
      scroll={{ x: '100%', y: `calc(100vh - 360px)` }}
    ></Table>
  )
}

const ResizableTitle = (props: {
  onResize: (e: React.SyntheticEvent, data: ResizeCallbackData) => any
  width?: number
  className?: string
  [props: string]: any
}) => {
  const { onResize, width = 200, className, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} className={classNames(className, style.tableHeader)} />
    </Resizable>
  )
}
