/**
 * 用户管理（用户授权）
 */
import React, { useEffect, useState } from 'react'
import { Table, Dropdown, Menu, Modal, message, Button, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import {
  getUserRole,
  updateUserRole,
  getPermissionTemplateInfo,
  updatePermissionTemplateInfo,
  removeConnectionUser,
  togglePermissionUser,
  queryUserTool,
} from 'src/api'
import { useRequest } from 'src/hook'
import OperationPermissionsModal from './OperationPermissionsModal'
import { renderTableFields, getScrollX } from 'src/util'
import classnames from 'classnames'
import styles from './index.module.scss'
import PermissionInfoModal from './PermissionInfoModal'
import { resetPermissionModal } from './commonMethods'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'
import SetOperationAuthModal from './objectLevelAuthorization/SetOperationAuthModal'

const getRoleTypeValue = (data: any[], key: string) =>
  data?.filter((i: any) => i?.roleType === key)?.[0]?.roleName

const getPermissionValue = (data: any[], key: string) =>
  data?.filter((i: any) => i?.id === key)?.[0]?.name || '无权限'

interface PropsI { 
  groupId ?: string,
  nodePathWithType?: string,
  selectNodeType: string,
  dataSourceType: string,
  [p:string]: any
}
const UserAuthorize = (props: PropsI) => {
  const {
    data,
    refreshList,
    groupId,
    selectNodeType,
    dataSourceType,
    nodePathWithType,
    permissionlist,
  } = props

  const { isOnlyRead = false, roleNameList = [] } = permissionlist || {}
  const [modalVisible, setModalVisible] = useState(false)
  const [permissionModalVisible, setPermissionModalVisible] = useState<boolean>(false)
  const [userId, setUserId] = useState<any>()
  const [templateOperationsSet, setTemplateOperationsSet] = useState([])
  const [params, setParams] = useState<{
    userId: string, 
    nodePathWithType: string | undefined, 
    groupId: number | string | undefined
  }>()
  // 记录当前点击的record的时间范围，组件传参需要
  const [timeRange, setTimeRange] = useState<any>({ beginTime: undefined, endTime: undefined })
  
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowInfo, setSelectedRowInfo] = useState<any>();
  const [optionType, setOptionType] = useState<string>('editToolPermission');
  // 权限等级
  const [templateIdModalVisible, setTemplateIdModalVisible] = useState<boolean>(false);

  // 获取连接角色枚举
  const { data: userRoleData = [] } = useRequest(getUserRole)

  // 获取权限等级枚举
  const { data: permissionData = [], run: getPermissionEnum } = useRequest(
    getPermissionTemplateInfo,
    { manual: true },
  )

  useEffect(() => {
    setSelectedRowKeys([])
  }, [data])

  useEffect(() => {
    if (dataSourceType) {
      getPermissionEnum(dataSourceType)
    }
  }, [dataSourceType, getPermissionEnum])

  // 修改用户连接角色
  const handleRoleChange = (info: any, item: any) => {
    let params: any = {
      userId: item?.userId,
      roleType: info?.key,
    }
    if (selectNodeType === 'group') {
      params.groupId = groupId
    } else {
      params.nodePathWithType = nodePathWithType
    }
    updateUserRole(params)
      .then(() => {
        refreshList()
        message.success('修改成功')
      })
      .catch()
  }

  // 修改权限等级
  const handlePermissionChange = async(info: any, item: any) => {
    const { beginTime, endTime } = item || {};
    const params: any = {
      userIds: [item?.userId],
      templateId: info?.key,
      // beginTime: new Date(beginTime).getTime() || undefined,
      // endTime: new Date(endTime).getTime() || undefined,
    }
    //存在冲突时, 修改权限给提示
    if (!item?.templateValid) {
      const resetOrNot = await  resetPermissionModal({
        title: '是否重置此用户权限等级',
        content:'此用户在此层及下存在其他权限设置,重置将清空下层及所选用户的权限设置'
      })
      if (!resetOrNot) {
        return
      }
    }
    if (selectNodeType === 'group') {
      params.groupId = groupId
    } else {
      params.nodePathWithType = nodePathWithType
    }
    updatePermissionTemplateInfo(params)
      .then(() => {
        refreshList()
        message.success('修改成功')
      })
      .catch((err) => {
        console.error('修改权限等级失败：', err)
      })
  }

  // 移除用户
  const handleRemoveUser = (item: any, isBatch: boolean = false) => {
    Modal.confirm({
      title: '确认移除用户吗?',
      okText: '确定',
      cancelText:'取消',
      onOk: () => {
        let userIds: any;
        if (isBatch) {
          userIds = selectedRowInfo.filter((item: any) => {
            return item?.supportDelete === true && item?.userId;
          }).map((item: any) => item.userId);
        }
        let params: any = {
          userIds: isBatch? userIds : [item.userId],
        }
        if (selectNodeType === 'group') {
          params.groupId = groupId
        } else {
          params.nodePathWithType = nodePathWithType
        }
        removeConnectionUser(params)
          .then(() => {
            refreshList()
            message.success('移除成功')
          })
          .catch()
      },
    })
  }

  // 禁用、启用用户
  const handleToggleStatus = (item: any, isBatch: boolean = false, batchStatus?: boolean) => {
    let userIds: any;
    if (isBatch) {
      userIds = selectedRowInfo.filter((item: any) => {
        return item?.supportDisable === true && item?.userId;
      }).map((item: any) => item.userId);
    }
    let params: any = {
      userIds: isBatch? userIds : [item?.userId],
      status: isBatch? batchStatus : !item?.status,
    }
    if (selectNodeType === 'group') {
      params.groupId = groupId
    } else {
      params.nodePathWithTypeList = [nodePathWithType]
    }
    togglePermissionUser(params)
      .then(() => {
        refreshList()
        message.success('操作成功')
      })
      .catch()
  }
  // 编辑工具权限
  const handleEditToolPermission = (item: any) => {
    const { endTime, beginTime } = item || {};
    const params = {
      userId: item?.userId,
      groupId,
      nodePathWithType,
      dataSourceType
    }
    setOptionType("editToolPermission")
    // 查询工具权限选中值
    queryUserTool(params)
      .then((res: any) => {
        setTemplateOperationsSet(res)
        setUserId(item?.userId)
        setTimeRange({
          endTime: new Date(endTime).getTime() || undefined,
          beginTime: new Date(beginTime).getTime() || undefined
        })
        setModalVisible(true)
      })
      .catch((err: any) => {
        console.error('查询工具权限失败:', err)
      })
  }

  const roleMenu = (record: any) => (
    <Menu
      className={classnames(styles.optionTxt, styles.menuWrap)}
      onClick={(info) => handleRoleChange(info, record)}
    >
      {/* 不可以切换连接角色 */}
      {userRoleData?.filter((i: any)=>i.roleType !== 'CONNECTION_ADMIN_ROLE')?.map((item: any) => (
        <Menu.Item key={item?.roleType}>{item?.roleName}</Menu.Item>
      ))}
    </Menu>
  );

  const permissionMenu = (record: any) => (
    <Menu
      className={classnames(styles.optionTxt, styles.menuWrap)}
      onClick={(info) => handlePermissionChange(info, record)}
    >
      {permissionData?.filter((i: any)=>!["DISABLE_TEMPLATE_AND_PERMISSION", "DISABLE_TEMPLATE"].includes(i?.status))?.map((item: any) => (
        <Menu.Item
          key={item?.id}
        >
          {item?.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  // 模版权限是否置灰
  const templateStatusDisabled = (templateId: string) => { 
    const tstatus =  permissionData
       ?.filter((i: any) => i?.id === templateId)
       ?.map((i: any) => i?.status)?.[0];
    return ["DISABLE_TEMPLATE", "DISABLE_TEMPLATE_AND_PERMISSION"].includes(tstatus)
  }
   
  // 查看详情
  const getPermissionInfo = (item: { userId: any }) => {
    setUserId(item?.userId)
    const params = {
      userId: item.userId, 
      nodePathWithType: nodePathWithType, 
      groupId: groupId
    }
    setParams(params)
    setPermissionModalVisible(true)
  }

  const columns: any[] = [
    {
      title: "用户名",
      dataIndex: "userName",
      key: "userName",
      width: 200,
      render:(txt: string, record: any)=><span>{`${renderTableFields(txt)}(${renderTableFields(record.userId)})`}</span>
    },
    {
      title: "连接角色",
      dataIndex: "roleType",
      key: "roleType",
      width: 200,
      render: (txt: string, record: any) => (
        <>
          <span
            className={classnames({
              [styles["deleteLine"]]: !record?.roleValid,
              [styles["colore999"]]: !record?.roleValid,
              [styles["disabled"]]: !record?.supportEditRole,
            })}
          >
            {getRoleTypeValue(userRoleData, txt)}
          </span>
          {/* 用户授权都不可更改连接角色 */}
          {false && (
            <Dropdown overlay={() => roleMenu(record)}>
              <DownOutlined
                className={classnames(styles.ml10, styles.options)}
              />
            </Dropdown>
          )}
        </>
      ),
    },
    {
      title: "权限等级",
      dataIndex: "templateId",
      key: "templateId",
      width: 200,
      render: (txt: string, record: any) => (
        <>
          <Tooltip  title={!record?.templateValid && '此用户在此层级下存在其他权限设置'}>
            <span
                className={classnames({
                  [styles["deleteLine"]]: !record?.templateValid,
                  [styles["colore999"]]:
                    templateStatusDisabled(record?.templateId) ||
                    !record?.supportEditTemplate,
                  [styles["disabled"]]: !record?.supportEditTemplate,
                })}
              >
                {getPermissionValue(permissionData, txt)}
            </span>
          </Tooltip>
          {record?.supportEditTemplate && (
            <Dropdown overlay={() => permissionMenu(record)}>
              <DownOutlined
                className={classnames(styles.ml10, styles.options)}
              />
            </Dropdown>
          )}
        </>
      ),
    },
    {
      title: '工具权限',
      dataIndex: 'role',
      key: 'role',
      width: 120,
      render: (_: string, record: any) => (
        <PermissionTooltip
          title="手动授权"
          permissionlist={permissionlist}
        >
          <Button
            type='link'
            className={classnames({
              // [styles.disabled]: !record?.supportEditTool,
              [styles.options]: record?.supportEditTool,
            })}
            onClick={() =>
              record?.supportEditTool && handleEditToolPermission(record)
            }
            disabled={isOnlyRead || !record?.supportEditTool}
          >
            编辑
          </Button>
        </PermissionTooltip>
      ),
    },
    {
      title: '权限详情',
      dataIndex: 'permissionInfo',
      key: 'permissionInfo',
      width: 120,
      render: (_: string, record: any) => (
        <Button
          type='link'
          style={{ paddingLeft: 0}}
          onClick={()=>getPermissionInfo(record)}
        >
          查看详情
        </Button>
      ),
    },
    {
      title: '有效时间',
      dataIndex: 'beginTime',
      key: 'beginTime',
      width: 300,
      render: (val: string, record: any) => {
        const { endTime } = record || {};
        return <>{val ? `${val}-${endTime}` : '永久'}</>
      }
    },
    {
      title: "操作",
      dataIndex: "option",
      key: "option",
      width: 120,
      render: (_: string, record: any) => (
        <>
          <PermissionTooltip
            title="手动授权"
            permissionlist={permissionlist}
          >
            <Tooltip
              title={!record?.supportDelete ? `此用户设置于上层级，无法移除，请从上层开始移除，或者进行禁用/更换权限等级操作` : null}
            >
              <Button
                type='link'
                className={classnames({
                  // [styles.disabled]: !record?.supportDelete,
                  [styles.options]: record?.supportDelete,
                }, styles.padding0)}
                onClick={() => record?.supportDelete && handleRemoveUser(record, false)}
                disabled={isOnlyRead || !record?.supportDelete}
                style={{padding: 0}}
              >
                移除
              </Button>
            </Tooltip>
            <Button
              type='link'
              className={classnames(styles.ml10, {
                // [styles.disabled]: !record?.supportDisable,
                [styles.options]: record?.supportDisable,
              }, styles.padding0)}
              onClick={() => {
                record?.supportDisable && handleToggleStatus(record, false);
              }}
              disabled={isOnlyRead || !record?.supportDisable}
              style={{padding: 0}}
            >
              {record?.status ? "禁用" : "启用"}
            </Button>
          </PermissionTooltip>
        </>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], info: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowInfo(info);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  // 在数据源中设置 key 值为 ID 字段
  const dataWithKeys = data.map((item: any) => {
    return { ...item, key: item?.userId };
  });


  const isSupportBatchDelete = (info: any) => {
    let arr = info && info.filter((item: any) => item?.supportDelete === true)
    if (arr && arr.length > 0) {
      return true
    } else {
      return false
    }
  }
  
  const menu = (
    <Menu>
      <Menu.Item onClick={() => { handleRemoveUser({}, true) }} disabled={!isSupportBatchDelete(selectedRowInfo)}>
        <Tooltip title={!isSupportBatchDelete(selectedRowInfo) && '此用户设置于上层级，无法移除，请从上层开始移除，或者进行禁用/更换权限等级操作'}>
          <span>批量移除</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item onClick={() => { handleToggleStatus({}, true, false) }}>批量禁用</Menu.Item>
      <Menu.Item onClick={() => { handleToggleStatus({}, true, true) }}>批量启用</Menu.Item>
      <Menu.Item onClick={() => { setTemplateIdModalVisible(true) }}>批量设置权限等级</Menu.Item>
      <Menu.Item onClick={() => { setModalVisible(true); setOptionType("batchEditToolPermission"); setUserId(selectedRowKeys) }}>批量编辑工具权限</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown disabled={!hasSelected} overlay={menu}>
        <Button type="primary" style={{position: 'absolute', right: 0, top: 7, width: 136}}>批量操作<DownOutlined /></Button>
      </Dropdown>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataWithKeys}
        scroll={{
          x: getScrollX(columns),
          y: 'calc(100vh - 430px)',
        }}
        pagination={false}
        className={styles.customUaTable}
        rowClassName={(record)=>!record?.status ? 'invalidGreyBg' : ''}
      />
      {/* 权限等级 */}
      { templateIdModalVisible && (
        <SetOperationAuthModal
          visible
          groupId={groupId}
          nodePathWithType={nodePathWithType}
          dataSourceType={dataSourceType}
          handleClose={() => setTemplateIdModalVisible(false)}
          refreshList={refreshList}
          selectNodeType={selectNodeType}
          info={selectedRowInfo}
          userIds={selectedRowKeys}
        />)
      }
      {/* 工具权限 */}
      {modalVisible && (
        <OperationPermissionsModal
          visible={modalVisible}
          optionType={optionType}
          userId={userId}
          groupId={groupId}
          selectNodeType={selectNodeType}
          nodePathWithType={nodePathWithType}
          dataSourceType={dataSourceType}
          setModalVisible={setModalVisible}
          callback={refreshList}
          templateOperationsSet={optionType==='batchEditToolPermission'?[] : templateOperationsSet}
          timeRange={timeRange}
        />
      )}
      {/* 权限详情 */}
      {permissionModalVisible && (
        <PermissionInfoModal
          visible={permissionModalVisible}
          setVisible={setPermissionModalVisible}
          params={params}
          userId={userId}
          permissionlist={permissionlist}
        />
      )}
    </>
  )
}
export default UserAuthorize