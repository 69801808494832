import React, { useState, useRef, useEffect, useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { GridConfigBase } from 'src/pageTabs/queryPage/resultTabs/resultContentGrid'
import { GridReadyEvent, GridApi, ColumnApi } from '@ag-grid-community/core'
import { useSelector } from 'src/hook'
import { Button, message } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty, keyBy, isEqual, uniq, uniqBy } from 'lodash'
import { desginIndexs_api, IDesginIndex } from 'src/api'
import { DataSourceType, IIndex, IIndexOperation } from 'src/types'
import { SQLComfirmModal } from './SQLComfirmModal'
import { getInfoFromPath } from 'src/util'

export interface IDesignIndexProps {
  connectionId?: string | number
  connectionType: DataSourceType
  databaseName?: string
  tableName: string
  columns: { columnName: string; [key: string]: any }[]
  indexes?: IIndex[]
  queryTabKey?: string
  nodePath?: string
  successfulCallback: () => void
}

// 默认oracle
const COLUMN_DEFS = [
  {
    headerName: '索引名',
    field: 'indexName',
    editable: true,
  },
  {
    headerName: '列名',
    field: 'columnName',
    cellEditor: 'columnSelectEditor',
    editable: true,
  },
  {
    headerName: '索引类型',
    field: 'indexType',
    editable: true,
    cellEditor: 'selectEditor',
    cellEditorParams: {
      cellHeight: 50,
      values: ['NORMAL', 'UNIQUE'],
    },
  },
]

// sqlserver 索引与 oracle有所不同
const COLUMN_DEFS_SQLSERVER = [
  {
    headerName: '索引名',
    field: 'indexName',
    editable: true,
  },
  {
    headerName: '列名',
    field: 'columnName',
    cellEditor: 'columnSelectEditor',
    editable: true,
  },
  {
    headerName: '索引类型',
    field: 'indexType',
    editable: true,
    cellEditor: 'selectEditor',
    cellEditorParams: {
      cellHeight: 50,
      values: ['CLUSTERED', 'NONCLUSTERED'],
    },
  },
  {
    headerName: '是否唯一',
    field: 'unique',
    editable: true,
    cellEditor: 'selectEditor',
    cellEditorParams: {
      cellHeight: 50,
      values: ['true', 'false'],
    },
  },
]

export const DesignIndex = (props: IDesignIndexProps) => {
  const {
    indexes = [],
    columns = [],
    connectionId,
    connectionType,
    databaseName,
    tableName,
    queryTabKey,
    nodePath,
  } = props

  const { theme } = useSelector((state) => state.appearance)

  const [rowData, setRowData] = useState<IIndex[]>([])

  const gridApiRef = useRef<GridApi>()
  const gridColumnApiRef = useRef<ColumnApi>()

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
    gridColumnApiRef.current = params.columnApi
    gridApiRef.current.sizeColumnsToFit()
  }

  useEffect(() => {
    if (isEmpty(indexes)) return
    setRowData(cloneDeep(indexes))
  }, [indexes])

  const addIndex = () => {
    if (connectionType === 'SQLServer') {
      gridApiRef.current?.applyTransactionAsync(
        { add: [{ indexType: 'CLUSTERED', unique: 'true' }] },
        (res) => {
          gridApiRef.current?.startEditingCell({
            rowIndex: Number(res.add[0]?.rowIndex),
            colKey: 'indexName',
          })
        },
      )
    } else {
      gridApiRef.current?.applyTransactionAsync(
        { add: [{ indexType: 'NORMAL' }] },
        (res) => {
          gridApiRef.current?.startEditingCell({
            rowIndex: Number(res.add[0]?.rowIndex),
            colKey: 'indexName',
          })
        },
      )
    }
  }

  const deleteIndex = () => {
    const selectedRows = gridApiRef.current?.getSelectedRows()
    const selectedNodes = gridApiRef.current?.getSelectedNodes()
    if (!selectedRows || !selectedRows[0] || !selectedNodes) return
    gridApiRef.current?.applyTransactionAsync({ remove: selectedRows })
    const { rowIndex } = selectedNodes[0] as any
    setRowData((prevData) => {
      prevData?.splice(rowIndex, 1)
      return prevData
    })
  }

  const getGridData = () => {
    const data: IIndex[] = []
    gridApiRef.current?.forEachNode((node) => {
      data.push(node.data)
    })
    return data
  }

  const updateRowData = () => {
    const rowData = getGridData()
    setRowData(rowData)
  }

  const schemaName =
    connectionType === 'SQLServer'
      ? getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
      : getInfoFromPath(nodePath, 'connection', connectionType)

  const submitIndexs = async () => {
    try {
      const payload = getBasePayload()
      const indexesOperation = getIndexesWithOperation()
      payload.userInputs.indexOperation = indexesOperation
      payload.userInputs.schemaName = schemaName
      const { generatedSql } = await desginIndexs_api(payload)
      setGeneratedSql(generatedSql)
      setSqlComfirmVisible(true)
    } catch (error) {
      message.success(`索引语句生成失败`)
    }
  }

  /* 生成待执行索引操作 */
  const getIndexesWithOperation = ():
    | (IIndex & {
        operationType: IIndexOperation
      })[]
    | undefined => {
    if (isEmpty(indexes))
      return uniqBy(rowData, 'indexName').map((it) => ({
        ...it,
        operationType: 'ADD',
      }))
    /* 0 key ---> columnKey */
    /* 1 inPrev & !inNext drop*/
    /* 2 !inPrev & inNext add*/
    /* 3 inPrev & inNext & diff dropPrev & addNext */
    const prevIndexesMap = keyBy(indexes, 'indexName')
    const nextIndexesMap = keyBy(rowData, 'indexName')
    let indexesOperation = [] as unknown as (IIndex & {
      operationType: IIndexOperation
    })[]
    const indexesArray = uniq(
      [...(indexes || []), ...(rowData || [])].map((it) => it.indexName),
    )
    indexesArray.forEach((indexName) => {
      const prevRecord = prevIndexesMap[indexName]
      const nextRecord = nextIndexesMap[indexName]
      const isInPrev = !!prevRecord
      const isInNext = !!nextRecord
      const isDiff = isInPrev && isInNext && !isEqual(prevRecord, nextRecord)
      const dropPrev = (isInPrev && !isInNext) || isDiff
      const addNext = (!isInPrev && isInNext) || isDiff
      dropPrev &&
        indexesOperation.push({
          ...prevRecord,
          operationType: 'DROP',
        })
      addNext &&
        indexesOperation.push({
          ...nextRecord,
          operationType: 'ADD',
        })
    })
    return indexesOperation
  }

  /* 获取基础请求数据 */
  const getBasePayload = () => {
    return {
      connectionId,
      connectionType,
      userInputs: {
        databaseName,
        tableName,
        indexOperation: [],
        schemaName,
      },
    } as unknown as IDesginIndex
  }

  const columnDefs = useMemo(() => {
    let columnDefs = COLUMN_DEFS
    if (connectionType === 'SQLServer') {
      columnDefs = COLUMN_DEFS_SQLSERVER
    }
    return columnDefs.map((column, index) => {
      const columnNameOptions = columns.map((it) => it.columnName)
      if (index === 1) {
        return {
          ...column,
          cellEditorParams: { columnsOptions: columnNameOptions },
        }
      }
      return column
    })
  }, [columns, connectionType])

  const [sqlComfirmVisible, setSqlComfirmVisible] = useState<boolean>(false)
  const [generatedSql, setGeneratedSql] = useState('')

  // 根据后端返回的indexType前缀决定显示“CLUSTERED”还是“NONCLUSTERED”
  // 这里前端传值和后端返回不一致为临时方案
  const rowDataFormat = (rowData: any) => {
    return rowData.map((item: any) => {
      const itemCopy = { ...item }
      let temp = itemCopy.indexType
      if (temp.slice(0, 3) === 'CLU') {
        temp = 'CLUSTERED'
      }
      if (temp.slice(0, 3) === 'NON') {
        temp = 'NONCLUSTERED'
      }
      itemCopy.indexType = temp
      return itemCopy
    })
  }

  return (
    <div>
      <div style={{ marginBottom: '16px' }}>
        <Button size="small" onClick={addIndex} className="mr8">
          增加索引
        </Button>
        <Button size="small" onClick={deleteIndex} className="mr8">
          删除索引
        </Button>
        <Button size="small" type="primary" onClick={submitIndexs}>
          保存
        </Button>
      </div>
      <div
        id="myGrid"
        style={{
          height: '50vh',
          width: '100%',
        }}
        className={
          theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
        }
      >
        <AgGridReact
          {...GridConfigBase}
          modules={[ClientSideRowModelModule]}
          columnDefs={columnDefs}
          defaultColDef={{ editable: true, resizable: true }}
          animateRows={true}
          onGridReady={onGridReady}
          rowData={rowDataFormat(rowData)}
          rowSelection="single"
          onCellEditingStopped={updateRowData}
        />
      </div>
      <SQLComfirmModal
        visible={sqlComfirmVisible}
        setVisible={setSqlComfirmVisible}
        originSql={generatedSql}
        connectionId={connectionId}
        connectionType={connectionType}
        databaseName={databaseName}
        queryTabKey={queryTabKey}
        successfulCallback={props.successfulCallback}
      ></SQLComfirmModal>
    </div>
  )
}
