import React, { useCallback, useEffect } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Form, InputNumber, message, Select } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { getExpireTimeByMin, postOvertime } from 'src/api'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'

export const ModalChangeLoginHodlTime: React.FC<{ loginTime?: any, refresh: () => Promise<number> }> = ({
  loginTime,
  refresh
}) => {
  const dispatch = useDispatch()
  const visible = useSelector(
    (state) => state.modal.LoginHoldTimeModal,
  )
  const [form] = Form.useForm()

  // const { data, run: getData } = useRequest(getExpireTimeByMin, {
  //   manual: true
  // })

  const { loading, run } = useRequest(postOvertime, {
    manual: true,
    onSuccess: () => {
      message.success('修改成功')
      dispatch(hideModal('LoginHoldTimeModal'))
      refresh()
    },
  })

  // useEffect(() => {
  //   if (visible) {
  //     getData()
  //   }
  // }, [visible])

  const setFormVal = useCallback(() => {
    if (loginTime) {
      form.setFields([
        { name: 'loginOvertime', value: Number(loginTime) },
      ])
    }
  }, [form, loginTime])

  useEffect(() => {
    setFormVal()
  }, [loginTime, setFormVal, form])

  const valueParser = (value:string | undefined) => {
    // 先修一下 CQ-2210
    let rep = value!.split(" ").pop()
    if (typeof rep === "undefined") {
      return "30"
    }
    let s = value!.replace(rep, '');
    if (isNaN(Number(s))) {
      return "30"
    }
    if (Number(s) > 180) {
      return "180"
    }
    if (Number(s) < 1) {
      return "1"
    }
    return s
  }

  return (
    <UIModal
      title="修改登录保持时间"
      visible={visible}
      onOk={() => {
        form.validateFields().then(({ loginOvertime }) => {
          run(loginOvertime)
          // run({ userId, oldP, newP })
        })
      }}
      // todo: 修改密码后端返回成功失败而非成功后 401
      // todo: 重新登录交互
      okText="确定"
      onCancel={() => {
        dispatch(hideModal('LoginHoldTimeModal'))
        // form.resetFields(['oldP', 'newP', 'confirm'])
      }}
      confirmLoading={loading}
    // width={480}
    >
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="登录保持时间"
          name="loginOvertime"
          rules={[{ required: true, message: '请输入登录保持时间' }]}
          extra='超过该时间系统会退出登录，默认为 30 分钟，范围大于等于 1 分钟，最多 180分钟 '
        >
          <InputNumber
            min={1}
            max={180}
            formatter={value => `${Number(value)} min`}
            parser={value => valueParser(value)}
          />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
