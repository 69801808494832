/* eslint-disable jsx-a11y/no-access-key */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, useRequest } from 'src/hook'
import {
  Layout,
  Button,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Tabs,
  Progress,
} from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { LinkButton, SimpleBreadcrumbs } from 'src/components'
import { getAndSetExportList, ITaskProgress } from './downloadPageSlice'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import {
  checkFileExistance,
  deleteExport,
  ExportEntity,
  TaskStatus,
} from 'src/api'
import { renderItemField } from 'src/constants'
import 'src/styles/layout.scss'

import TextImportTasktable from './TextImportTaskTable'
import { handleDownload } from 'src/util'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { ErrorLogModal } from './ErrorLogModal'

const { Header, Content } = Layout

const renderTaskStatus = (taskStatus: TaskStatus) => {
  switch (taskStatus) {
    case 'SUCCESS':
      return <div style={{ color: '#3f84e9' }}>成功</div>
    case 'FAILURE':
      return '失败'
    case 'DOWNLOADED':
      return '已下载'
    case 'PROCESSING':
      return '处理中'
    case 'CREATE':
      return '正在创建'
    case "QUEUING":
      return '队列中'
    default:
      return taskStatus
  }
}


const columns: ColumnsType<ExportEntity> = [
  { dataIndex: 'fileName', title: '文件名', width: 200, ellipsis: true },
  {
    dataIndex: 'statement',
    title: '导出语句',
    render: renderItemField('longString'),
  },
  { dataIndex: 'exportFormat', title: '导出格式', width: 104, ellipsis: true },
  { dataIndex: 'exportDataNum', title: '导出行数', width: 104, ellipsis: true },
  {
    dataIndex: 'taskCreateTime',
    title: '任务创建时间',
    width: 128,
    ellipsis: true,
    render: (taskCreateTime) => {
      taskCreateTime = taskCreateTime || ''
      return (
        <Tooltip title={taskCreateTime} placement="topRight">
          {taskCreateTime.slice(5)}
        </Tooltip>
      )
    },
  },
]

const breadcrumbData = [
  { title: "个人中心" },
  {
    title: "任务中心",
  },
];

export const DownloadPage = () => {
  const [pageSizeState, setPageSizeState] = useState(10)
  const [currentKey, setCurrentKey] = useState('1')
  const [refreshFlag, setRefreshFlag] = useState(false)
  const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState<boolean>(false)
  const [errorLog, setErrorLog] = useState<string>('');
  const dispatch = useDispatch()
  const { exportList, page, tableLoading, taskProgressMap } = useSelector(
    (state) => state.download,
  )


  useEffect(() => {
    fetchList({ current: 0, pageSize: pageSizeState })
  }, [])

  const refreshList = () => {
    if (currentKey === '1') {
      fetchList({
        pageSize: pageSizeState,
        current: page?.current,
      })
    } else {
      setRefreshFlag(true)
    }
  }

  useEffect(() => {
    refreshFlag && setTimeout(() => setRefreshFlag(false))
  }, [refreshFlag])

  const fetchList = useCallback((params: { current?: number, pageSize?: number }) => {
    dispatch(getAndSetExportList(params))
  }, [dispatch, getAndSetExportList])

  const handleDeleteTask = (taskId: number) => {
    deleteExport(taskId).then(() => {
      message.success('删除成功')
      refreshList()
    })
  }

  const pagination: TablePaginationConfig = {
    showSizeChanger: true,
    showQuickJumper: false,
    pageSizeOptions: ['10', '20', '50', '100'],
    defaultPageSize: 10,
    total: page?.total,
    current: (page?.current ?? 0) + 1,
    onChange: (current, pageSize) => {
      setPageSizeState(pageSize!)
      fetchList({ current: current - 1, pageSize })
    },
  }

  const { run: checkFile, fetches: fetchesCheckFile } = useRequest(
    checkFileExistance,
    {
      manual: true,
      fetchKey: (id, origin) => id,
    },
  )


  /* 任务进度渲染函数 */
  const renderTaskProgress = (taskId: ITaskProgress['taskId']) => {
    if (!taskProgressMap[taskId]) return
    const { percentage } = taskProgressMap[taskId] as ITaskProgress
    return <Progress type="line" percent={percentage} width={16} />
  }

  const columnsWithActions: ColumnsType<ExportEntity> = [
    ...columns,
    {
      dataIndex: 'taskStatus',
      title: '任务状态',
      width: 80,
      ellipsis: true,
      render: (status: TaskStatus, record) => {
        return status === 'PROCESSING' && taskProgressMap[record.taskId]
          ? renderTaskProgress(record.taskId)
          : renderTaskStatus(status)
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (_, record) => {
        const { taskId, taskStatus, origin, errorLog } = record
        return (
          <>
            {
              ['FAILURE','CREATE_FAILURE'].includes(taskStatus) ? 
              <LinkButton
                onClick={() => {
                  setIsErrorLogModalVisible(true)
                  setErrorLog(errorLog)
                }}
              >
                查看
              </LinkButton>
              : 
              <LinkButton
                // 数据库有脏数据, 会出现 查看和下载 都不满足的taskStatus, 此时显示置灰的下载
                disabled={!['SUCCESS', 'DOWNLOADED'].includes(taskStatus)}
                onClick={() => {
                  // ! BAD: 使用 a 标签下载是最符合标准的，如果资源不存在，直接报 404 或 410 即可
                  checkFile(taskId.toString(), origin).then(() => {
                    handleDownload({
                      href: `/${origin}/export/download/${taskId}`,
                    })
                  })
                }}
                loading={fetchesCheckFile[taskId]?.loading}
              >
                下载
              </LinkButton>
            }
            <Popconfirm
              title="确定删除此任务?"
              onConfirm={() => handleDeleteTask(taskId)}
              okButtonProps={{ danger: true }}
            >
              <LinkButton danger>删除</LinkButton>
            </Popconfirm>
          </>
        )
      },
      width: 120,
    },
  ]

  return (
    <>
    <Layout className="cq-container">
      <Header className="cq-header">
        <div className="cq-header__main" style={{padding: '0px 20px'}}>
          <SimpleBreadcrumbs items={breadcrumbData}/>
          <div className="cq-header__action">
            <Button
              type="primary"
              icon={<RedoOutlined />}
              onClick={() => refreshList()}
            >
              刷新列表
            </Button>
          </div>
        </div>
      </Header>
      <Layout className="cq-main">
        <Content className="cq-content">
          <section className="cq-card cq-card--padding">
            <Tabs accessKey={currentKey} style={{ overflow: 'auto' }} onChange={(key) => {
              setCurrentKey(key)
            }}>
              <Tabs.TabPane tabKey="1" key="1" tab="下载任务">
                <ErrorBoundary>
                  <Table
                    columns={columnsWithActions}
                    dataSource={exportList}
                    rowKey={(record) => record.taskId}
                    loading={tableLoading}
                    size="small"
                    // pagination={{
                    //   current: (page?.current ?? 0) + 1,
                    //   total: page?.total,
                    //   pageSize
                    // }}
                    pagination={pagination}
                    // onChange={({ current, pageSize }) => { fetchList({ current: (current ?? 1) - 1, pageSize }) }}
                  ></Table>
                </ErrorBoundary>
              </Tabs.TabPane>
              {/*<Tabs.TabPane tabKey="2" key="2" tab="导入/导出任务">*/}
              {/*  {*/}
              {/*    currentKey === '2' && !refreshFlag && (<ErrorBoundary>*/}
              {/*      <TaskTable></TaskTable>*/}
              {/*    </ErrorBoundary>)*/}
              {/*  }*/}
              {/*</Tabs.TabPane>*/}
              <Tabs.TabPane tabKey="3" key="3" tab="文本导入任务">
                {
                  currentKey === '3' && !refreshFlag && (<ErrorBoundary>
                    <TextImportTasktable></TextImportTasktable>
                  </ErrorBoundary>)
                }
              </Tabs.TabPane>
            </Tabs>
          </section>
        </Content>
      </Layout>
    </Layout>
    {
      isErrorLogModalVisible && 
      <ErrorLogModal
        isModalVisible={isErrorLogModalVisible}
        setIsModalVisible={setIsErrorLogModalVisible}
        errorLog={errorLog}
      />
    }
    </>
  )
}
