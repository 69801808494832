import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  visibleSdt: boolean
  visibleNodeDetail: boolean
  visibleQueryTabs: boolean
  visibleTaskPane: boolean
  visibleApplicationShop: boolean
  // 加入申请单标识
  addToCartFlag: any

}

const initialState: InitialState = {
  visibleSdt: true,
  visibleNodeDetail: false,
  visibleQueryTabs: true,
  visibleTaskPane: false,
  visibleApplicationShop: false,
  addToCartFlag: 0
}

export const queryPageSlice = createSlice({
  name: 'queryPage',
  initialState,
  reducers: {
    setVisibleSdt(state, action: PayloadAction<boolean>) {
      state.visibleSdt = action.payload
    },
    setVisibleNodeDetail(state, action: PayloadAction<boolean>) {
      state.visibleNodeDetail = action.payload
    },
    setVisibleTaskPane(state, action: PayloadAction<boolean>) {
      state.visibleTaskPane = action.payload
    },
    setVisibleQueryTabs(state, action: PayloadAction<boolean>) {
      state.visibleQueryTabs = action.payload
    },
    setVisibleApplicationShop(state, action: PayloadAction<boolean>) {
      state.visibleApplicationShop = action.payload
    },
    setAddToCartFlag(state, action: PayloadAction<any>) {
      state.addToCartFlag = action.payload
    },
  },
})

export const {
  setVisibleSdt,
  setVisibleNodeDetail,
  setVisibleQueryTabs,
  setVisibleTaskPane,
  setVisibleApplicationShop,
  setAddToCartFlag
} = queryPageSlice.actions

export const queryPageReducer = queryPageSlice.reducer
