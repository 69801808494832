import React from 'react'
import { useSelector } from 'src/hook'
import { Form } from 'antd'
import {
  ElementTreeSelect,
  FormItemApplicant,
  FormItemEffectiveTime,
  FormItemReason,
  FormItemTitle,
  FormItemOperations
} from '../flowFormItems'
import { FormFieldElementEntity, FormFieldsHighRisk } from 'src/types'
import { FlowModalForm } from './FlowModalForm'
import { launchFlowApply } from 'src/api'

export const ModalFormHighRisk = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const flowType = 'highRisk'

  const [form] = Form.useForm()
  const onResetApprover = () => {
    form && form.setFieldsValue({approveUserId:''})
  }

  return (
    <FlowModalForm<FormFieldsHighRisk>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return

        const { approveUserId, applyReason, elements,  operationList, time, title } = values
        const {
          connectionId,
          connectionType: dataSourceType,
          nodeType,
        } = elements?.[0]

        const nodePathList = elements.map(({ nodePath }) => nodePath)
        const nodePathWithTypeList = elements.map(({ nodePathWithType }) => nodePathWithType)
 
        return launchFlowApply({
          applyUserId,
          email,
          remark:applyReason,
          applyReason,
          flowType,
          connectionId,
          dataSourceType,
          nodePathList,
          nodePathWithTypeList,
          nodeType,
       
          firstApproveUserId: approveUserId,
          operationList,
          title: title,
          priGranType: 'highRisk',
          useCartDataThinTiling: false,
          beginTime:time?.[0].format('YYYY-MM-DD HH:mm:ss'),
          endTime: time?.[1].format('YYYY-MM-DD HH:mm:ss'),
        })
      }}
    >
      <FormItemApplicant />
      <FormItemTitle />
      <Form.Item
        label="数据库元素"
        name="elements"
        required
        shouldUpdate
        rules={[
          {
            validator: (_, value?: FormFieldElementEntity[]) => {
              if (!value?.length) {
                return Promise.reject('请选择数据库元素')
              }
              const ruleObj = value[0]
              for (const el of value) {
                if (el.connectionId !== ruleObj.connectionId) {
                  return Promise.reject('只能选择相同连接下的元素')
                }
                if (el.nodeType !== ruleObj.nodeType) {
                  return Promise.reject('只能选择相同类型的元素')
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <ElementTreeSelect flowType="highRisk" onChange={onResetApprover} disabledEdit/>
      </Form.Item>
      <FormItemOperations disabledEdit/>
      <FormItemEffectiveTime />
      <FormItemReason />
    </FlowModalForm>
  )
})
