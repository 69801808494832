import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'
import { DataSourceType } from '../types'

export interface AppEntity {
  appName: string
  creator: string
  desc: string
  id: number
  locked: boolean
  key: number
  title: string
  children?: any[]
}

export type AppLanguage =
  | 'JAVA'
  | 'PHP'
  | 'PYTHON'
  | 'CSHARP'
  | 'CPP'
  | 'C'
  | 'OTHER'

export interface AddAppAccountRequest {
  accountName: string
  password: string
  appId: number | string
  connectionId: number | string
  databaseName: string
  effectiveTime: number
  operates: string[]
  whiteList: string[]
}

export interface AppAccount extends AddAppAccountRequest {
  accountId: number
  createTime: number
  endTime: number
  connectionName: string
  appName: string
}
export interface AppDatabaseRequest {
  appId: number
  connectionId: number | string
  databaseName: string
}

export interface AppDatabase extends AppDatabaseRequest {
  connectionName: string
  connectionType: DataSourceType
}

export interface AppDetail extends AppEntity {}

/** 负责人应用列表 */
export const getAdminAppList = (): Promise<AppEntity[]> => {
  return fetchGet(`/user/sys/applications`)
}
/** DBA 应用列表 */
export const getOwnerAppList = (): Promise<AppEntity[]> => {
  return fetchGet(`/user/owner/applications`)
}

export interface AppConnection {
  connectionId: number | string
  connectionName: string
  connectionType: string
  children?: any[]
  title: string
  appId: number
  databaseName: string
}

/** 连接视角 SDT，连接列表 */
export const getAppConnections = (): Promise<AppConnection[]> => {
  return fetchGet(`/user/app/connections`)
}

/** 连接视角，连接 app 列表 */
export const getConnectionApps = (connectionId: number | string) => {
  return fetchGet(`/user/app/applications/${connectionId}`)
}

/** 连接视角，连接 account 列表 */
export const getConnectionAccounts = (connectionId: number | string) => {
  return fetchGet(`/user/owner/applications/accounts/${connectionId}`)
}

/** 连接和应用 account 列表 */
export const getConnectionAppAccounts = (
  appId: number | string,
  connectionId: number | string,
) => {
  return fetchGet(`/user/owner/applications/accounts/${appId}/${connectionId}`)
}

export const addApp = (params: Partial<AppEntity>): Promise<AppEntity> => {
  return fetchPost(`/user/sys/applications`, params)
}

export const editApp = (params: Partial<AppEntity>): Promise<AppEntity> => {
  return fetchPut(`/user/sys/applications`, params)
}

export const getAppDetail = (appId: number): Promise<AppDetail> => {
  return fetchGet(`/user/sys/applications/${appId}`)
}

export const deleteApp = (appId: number): Promise<void> => {
  return fetchDelete(`/user/sys/applications/${appId}`)
}

/** 应用视角：应用下的数据库 */
export const getAppDatabaseList = (appId: number): Promise<AppDatabase[]> => {
  return fetchGet(`/user/owner/applications/${appId}`)
}

export interface AppConnectionDatabase {
  connectionId: number | string
  connectionName: string
  connectionType: string
  databaseName: string
}

/** 连接视角：连接下的应用数据库 */
export const getAppConnectionDatabases = (connectionId: number | string) => {
  return fetchGet(`/user/app/databases/${connectionId}`)
}

/** app 绑定数据库 */
export const addAppDatabases = (params: AppDatabaseRequest[]) => {
  return fetchPost(`/user/owner/applications/database`, params)
}
/** 移除 app 数据库绑定 */
export const removeAppDatabases = (params: AppDatabaseRequest[]) => {
  return fetchDelete(`/user/owner/applications/database`, params)
}

// todo: 后端完善接口。目前只支持三个参数必填，获取库在应用下的账号
export const getAppAccountList = (
  appId: number,
  connectionId?: number,
  databaseName?: string,
) => {
  return fetchGet(
    `/user/owner/applications/accounts/${appId}/${connectionId}/${databaseName}`,
  )
}

export const addAppAccounts = (params: AddAppAccountRequest[]) => {
  return fetchPost(`/user/owner/applications/accounts`, params)
}

export const deleteAppAccount = (accountId: number) => {
  return fetchDelete(`/user/owner/applications/accounts/${accountId}`)
}

export const batchDeleteAppAccount = (ids: Array<number | string>) => {
  return fetchDelete(`/user/owner/applications/accounts`, ids)
}

export const changeAppAccountPassword = (
  accountId: number,
  password: string,
) => {
  /* 密码加密 */
  return fetchPut(`/user/owner/applications/changePassword`, {
    accountId,
    password: btoa(unescape(encodeURIComponent(password))),
  })
}

export interface AppLanguageOption {
  name: string
  value: AppLanguage
}

export const getAppLanguageList = (): Promise<AppLanguageOption[]> => {
  return fetchGet(`/user/owner/applications/language`)
}

// 应用分析
export interface AppSql {
  /** 平均耗时，单位 μs */
  costAvg: number
  /** 总耗时，单位 ms */
  costTotal: number
  executeCount: number
  hostname: string
  id: number | string
  lastExecuteAt: string
  /** 模板 SQL */
  template: string
}
type TopSqlSortColumn =
  | 'costAvg'
  | 'costTotal'
  | 'executeCount'
  | 'lastExecutedAt'
interface TopSqlRequest {
  appCode: string
  limit?: number
  sortColumn?: TopSqlSortColumn
  days?: number
}
export const getAppTopSqls = (params: TopSqlRequest): Promise<AppSql[]> => {
  const { appCode, limit = 0, sortColumn = 'executeCount', days = 1 } = params
  return fetchPost(`/audit/app_analysis/query_top_sql`, {
    appCode,
    limit,
    sortColumn,
    days,
  })
}
export const getAppSlowSqls = (
  appCode: string,
  days: number,
  limit?: number,
): Promise<AppSql[]> => {
  return getAppTopSqls({ appCode, days, limit, sortColumn: 'costAvg' })
}

export interface AppTopTable {
  databaseObject: string
  executeCount: number | string
}
interface TopTablesRequest {
  appCode: string
}
export const getAppTopTables = (
  params: TopTablesRequest,
): Promise<AppTopTable[]> => {
  return fetchPost(`/audit/app_analysis/query_thermal`, params)
}
