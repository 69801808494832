import { Divider, Form, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  DesensRuleName,
  getDesensitizeRules,
  updateDesensitizeRules,
} from 'src/api/dataProtection'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { DesensitizationSettingItems } from './DesensitizationSettingItems'

export const EditDesensitization = ({
  refresh,
}: {
  refresh: () => Promise<(() => Promise<any>)[]>
}): JSX.Element => {
  const [form] = Form.useForm()
  const { selectedNode, columnType, nodePathOfColumn } = useSelector(
    (state) => state.dataProtection,
  )
  const dispatch = useDispatch()

  const [option, setOption] = useState<string>('')

  const { run, loading } = useRequest(updateDesensitizeRules, {
    manual: true,
    onSuccess: async () => {
      message.success(`设置成功`)
      dispatch(hideModal('DesensitizationDialog'))
    },
  })

  // 脱敏算法
  const { data: selectOptions, run: getDenseRules } = useRequest(
    getDesensitizeRules,
    {
      manual: true,
      formatResult: (data) =>
        data.map(
          (item: {
            ruleType: string
            ruleParam: object
            values: string[]
            ruleName: string
          }) => {
            return {
              label: item.ruleName,
              value: item.ruleType,
              props: {
                ruleParam: item.ruleParam,
                options: item.values,
              },
            }
          },
        ),
    },
  )

  useEffect(() => {
    if (selectedNode && columnType) {
      const { connectionType: datasourceType } = selectedNode.props
      getDenseRules({ columnType, datasourceType })
    }
  }, [getDenseRules, selectedNode, columnType])

  async function submitForm(values: any) {
    // 日期时间格式分成两个字段传值
    if (values?.ruleParam?.dateRange) {
      const { dateRange } = values.ruleParam as { dateRange: moment.Moment[] }

      values.ruleParam.min = +dateRange[0].format('x')
      values.ruleParam.max = +dateRange[1].format('x')
      delete values.ruleParam.dateRange
    }

    if (selectedNode && columnType && nodePathOfColumn) {
      const { connectionType: datasourceType } = selectedNode.props
      const params = {
        nodePath: nodePathOfColumn,
        datasourceType,
        columnType,
        ruleParam: values.ruleParam,
        ruleName: option as DesensRuleName,
      }
      await run(params)
      await refresh()
      setOption('')
    }
  }

  const visible = useSelector((state) => state.modal.DesensitizationDialog)

  return (
    <UIModal
      title={'设置脱敏算法'}
      visible={visible}
      onOk={() => {
        form.submit()
      }}
      onCancel={() => {
        dispatch(hideModal('DesensitizationDialog'))
        setOption('')
      }}
      afterClose={() => form.resetFields()}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={submitForm} {...FormLayout}>
        <Form.Item
          name="ruleName"
          label="脱敏算法"
          rules={[{ required: true, message: '请选择脱敏规则' }]}
        >
          <Select
            options={selectOptions as any}
            optionFilterProp="label"
            showSearch
            onChange={(v: string) => {
              setOption(v)
            }}
          ></Select>
        </Form.Item>
        <Divider style={{ fontSize: '10px' }}>脱敏算法设置</Divider>
        {option.length > 0 && (
          <DesensitizationSettingItems
            option={option}
            form={form}
            values={selectOptions?.find((i) => i.value === option)?.props}
          />
        )}
      </Form>
    </UIModal>
  )
}
