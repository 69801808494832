import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useRequest } from 'src/hook'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Tooltip } from 'antd'
import styles from './index.module.scss'
import classNames from 'classnames'
import UserOperationChart from './Charts/UserOperationChart'
import { getOperateCard } from 'src/api/getNewData';
import { QuestionCircleOutlined } from '@ant-design/icons'

export const OperationCard = () => {

  const [userOperationChartData, setUserOperationChartData] = useState<any[]>([]);
  const [userOperationUnit, setUserOperationUnit] = useState<string>("DAY");  // 操作card

  // 操作Card
  const {
    data: userOperationData = {
      allCount: 0, //用户操作总量
      executeCount: 0, //用户执行语句总量
      successCount: 0, //执行语句成功
      errorCount: 0, //执行语句失败
      operateCount: 0, // 用户系统操作总量
      highRiskCount: 0, //高危操作量
      overPermCount: 0, //越权操作量
      growth: 0, // 环比增长
      executeTimes: [], //构建上边的柱状图
    },
    loading: userOperationLoading,
    run: queryUserOperationData,
  } = useRequest(getOperateCard, {
    manual: true,
  });


  useEffect(() => {
    queryUserOperationData({ unit: userOperationUnit }).then(res => {
      setUserOperationChartData(res.executeTimes);
    });
  }, []);

  useEffect(() => {
    queryUserOperationData({ unit: userOperationUnit }).then(res => {
      setUserOperationChartData(res.executeTimes);
    });
  }, [userOperationUnit]);

  // 操作Card
  const {
    allCount, //用户操作总量
    executeCount, //用户执行语句总量
    successCount, //执行语句成功
    errorCount, //执行语句失败
    operateCount, // 用户系统操作总量
    highRiskCount, //高危操作量
    overPermCount, //越权操作量
    growth: growth_operation, // 环比增长
  } = userOperationData;

  const history = useHistory();

  const operateLinkMap = new Map([
    ["用户操作总量", null],
    ["执行语句总量", "/statement_detail"],
    ["系统操作总量", "/operate_record"],
    ["执行语句成功", "/statement_detail"],
    ["执行语句失败", "/statement_detail"],
    ["高危操作量", "/statement_detail"],
    ["越权操作量", "/operate_unauthorized"],
  ]);

  // 跳转
  const toLink = (link: any, str: string) => {
    let state: any;
    let timeKey: any = userOperationUnit.toLocaleLowerCase();
    let timeRange = [dayjs().startOf(timeKey).valueOf(),
    dayjs().endOf(timeKey).valueOf(),];
    switch (str) {
      case "执行语句总量":
        state = {
          timeRange: timeRange
        };
        break;
      case "系统操作总量":
        state = {
          timeRange: timeRange
        };
        break;
      case "执行语句成功":
        state = {
          timeRange: timeRange,
          resultFlag: 1,
        };
        break;
      case "执行语句失败":
        state = {
          timeRange: timeRange,
          resultFlag: 0,
        };
        break;
      case "高危操作量":
        state = {
          timeRange: timeRange,
          highFlag: 1,
        };
        break;
      case "越权操作量":
        state = {
          timeRange: timeRange,
          extratTypes: ["EXTRAT_FLAG"],
          overPermFlag: "1"
        };
        break;
    }
    history.push({
      pathname: link,
      state: state,
    })
  }


  const renderOperateCardDiv = (text: string, color: string, amount: any, colSpan: number) => {
    let res: any = [];
    for (let [key, value] of operateLinkMap) {
      if (key === text) {
        if (value) {
          res.push(
            <Col span={colSpan} className={styles.countNumber} key={key}>
              <div onClick={() => toLink(value, key)} className={classNames(styles.dataCardHover, styles.dataCard)}>
                <span style={{ color: color }}>{amount.toLocaleString()}</span>
                <div className={classNames(styles.countText)}>{key}</div>
              </div>
            </Col>
          )
        } else {
          res.push(
            <Col span={colSpan} className={styles.countNumber} key={key}>
              <div className={classNames(styles.dataCard)}>
                <span style={{ color: color }}>{amount.toLocaleString()}</span>
                <div className={classNames(styles.countText)}>{key}</div>
              </div>
            </Col>
          )
        }
      }
    }
    return (res);
  }

  const RowContent = (props: {
    dataCount?: any
    dataText?: any
    color?: any
    colCount: number
    colSpan: number
    countSize?: string
  }) => {
    const {
      dataCount,
      dataText,
      color,
      colCount,
      colSpan,
      countSize,
    } = props;

    const res: any = [];
    let textIndex = 0;
    let colorIndex = 0;
    dataCount.map((item: any, index: React.Key | null | undefined) => {
      let data_text = dataText[textIndex++];
      let data_color = color[colorIndex++];
      if (countSize === "small") {
        res.push(
          <Col span={colSpan} className={styles.countNumberSize} key={index + item}>
            {
              (item ? (item + "%") : '-')
            }
            <div className={styles.countText}>{data_text}</div>
          </Col>
        )
      } else {
        res.push(
          renderOperateCardDiv(data_text, data_color, item, colSpan)
        )
      }
    });
    while (res.length < colCount) {
      res.push(
        <Col span={colSpan} className={styles.countNumber} key={res.length}>
          <div className={styles.countText}></div>
        </Col>
      )
    }
    return (
      res
    )
  }

  const renderTooltip = (
    <div className={styles.operateCardTooltip}>
      <Tooltip title={"当前系统中可审计的用户所有操作记录数"}>
        <QuestionCircleOutlined />
      </Tooltip>
    </div>
  )

  // 用户操作量
  const OverviewCountLeft = (props: {
    title: string
    // link?: string
  }) => {
    const {
      title,
      // link,
    } = props;

    const CardContent = (
      <Card
        title={title}
        headStyle={{ border: 0, fontSize: 18, fontWeight: 'bolder' }}
        className={styles.statisticalCardWrapper}
        extra={renderTooltip}
      >
        <Row>
          <Col span={13}>
            <Row>
              <RowContent
                dataCount={[allCount, executeCount, operateCount]}
                dataText={["用户操作总量", "执行语句总量", "系统操作总量"]}
                color={[]}
                colCount={4}
                colSpan={6}
              />
            </Row>
            <Row>
              <RowContent
                dataCount={[successCount, errorCount, highRiskCount, overPermCount]}
                dataText={["执行语句成功", "执行语句失败", "高危操作量", "越权操作量"]}
                color={['#01901B', '#EA0000', '#EA0000', '#E88752']}
                colCount={4}
                colSpan={6}
              />
            </Row>
          </Col>
          <Col span={11}>
            <Row>
              <Col span={24}>
                <UserOperationChart
                  data={userOperationChartData}
                  userOperationUnit={userOperationUnit}
                  setUserOperationUnit={setUserOperationUnit}
                  loading={userOperationLoading}
                />
              </Col>
            </Row>
            <Row align='bottom'>
              <RowContent
                dataCount={[growth_operation]}
                dataText={["环比"]}
                color={[]}
                colCount={1}
                colSpan={4}
                countSize="small"
              />
            </Row>
          </Col>
        </Row>
      </Card>
    );

    return (
      <Col span={16} className={styles.drillDownChartWrapper}>
        {CardContent}
      </Col>
    )
  }



  return (
    <OverviewCountLeft
      title={"用户操作量"}
    />
  );
}
