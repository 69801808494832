import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Form, Input, message } from 'antd'
import { UIModal } from 'src/components'
import { fetchOrgTreeData } from '../organizationSlice'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { editDept } from "src/api";
import { nameValidator } from 'src/util/nameValidator'

export const ModalChangeDeptName = () => {
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.ModalChangeDeptName);
  const selectedNode = useSelector((state) => state.organization.selectedNode);
  const { loading, run } = useRequest(editDept, {
    manual: true,
    onSuccess: () => {
      message.success("修改成功");
      dispatch(fetchOrgTreeData());
      dispatch(hideModal("ModalChangeDeptName"));
    },
  });

  const inputRef = useRef<Input>(null)
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({name: selectedNode?.name})
      inputRef.current?.focus()
    }
  }, [visible, selectedNode, form])

  const modalTitle = selectedNode?.orgType === 'DEPT' ? '修改部门名称' : '修改合作方名称';
  const modalInput = selectedNode?.orgType === 'DEPT' ? '部门名称' : '合作方名称';

  return (
    <UIModal
      title={modalTitle}
      visible={visible}
      onCancel={() => {
        dispatch(hideModal("ModalChangeDeptName"));
      }}
      onOk={() => {
        form.validateFields().then(({ name }) => {
          const params = {
            name,
            id: selectedNode?.id
          }
          run(params)
        })
      }}
      confirmLoading={loading}
      width={320}
    >
      <Form form={form}>
        <Form.Item
          name="name"
          rules={[
            { required: true, message: '请输入部门名称' },
            { validator: nameValidator },
          ]}
        >
          <Input ref={inputRef} placeholder={modalInput} />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
