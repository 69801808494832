export type IPermKey =
  | 'operatingGroup'
  | 'timeLimitGroup'
  | 'resourceLimitGroup'
  | 'dataSource'
  | 'dataExport'
  | 'objectFilter'
  | 'rowFilter'
  | 'executeTimes'
  | 'executeRows'
  | 'executeDate'
  | 'highRiskOperation'
  | 'Desensitization'
  | 'ui_menu'
  | 'SystemSettings'
  | 'ConnectionSettings'
  | 'AuditAnalysis'
  | 'Monitor'
  | 'FlowProcess'
  | 'Warning'

/**
 * 流程类型
 */
export const flowTypes = [
  'dataCorrection',
  'dataManipulation',
  'connectionAccess',
  'dataExport',
  'dataMasking',
  'highRisk',
  'executionTimes',
  'executionRows',
  'menuAccess',
] as const

/**
 * 流程类型
 * @type dataCorrection 数据订正
 * @type dataManipulation 数据操作提权
 * @type connectionAccess 连接访问提权
 * @type dataExport 数据导出提权
 * @type dataMasking 数据脱敏提权
 * @type highRisk 高危资源提权
 * @type executionTimes 执行次数提权
 * @type executionRows 执行行数提权
 * @type menuAccess 功能提权
 */
export type FlowType = typeof flowTypes[number]

export interface IPerm {
  key: IPermKey
  value: IPermKey
  name: string
  parentKey: IPermKey | null
  flowType: FlowType | null
}

/** 权限仓库 */
export const PERM_STORE: IPerm[] = [
  {
    key: 'operatingGroup',
    value: 'operatingGroup',
    name: '操作权限',
    parentKey: null,
    flowType: null,
  },
  {
    key: 'timeLimitGroup',
    value: 'timeLimitGroup',
    name: '时间权限',
    parentKey: null,
    flowType: null,
  },
  {
    key: 'resourceLimitGroup',
    value: 'resourceLimitGroup',
    name: '受限资源权限',
    parentKey: null,
    flowType: null,
  },
  {
    key: 'dataSource',
    value: 'dataSource',
    name: '数据源操作权限',
    parentKey: 'operatingGroup',
    flowType: 'dataManipulation',
  },
  {
    key: 'dataExport',
    value: 'dataExport',
    name: '数据导出权限',
    parentKey: 'operatingGroup',
    flowType: 'dataExport',
  },
  // {
  //   key: 'objectFilter',
  //   value: 'objectFilter',
  //   name: '过滤权限',
  //   parentKey: 'operatingGroup',
  //   flowType: null,
  // },
  // {
  //   key: 'rowFilter',
  //   value: 'rowFilter',
  //   name: '行过滤权限',
  //   parentKey: 'operatingGroup',
  //   flowType: null,
  // },
  // {
  //   key: 'executeTimes',
  //   value: 'executeTimes',
  //   name: '执行次数权限',
  //   parentKey: 'operatingGroup',
  //   flowType: 'executionTimes',
  // },
  // {
  //   key: 'executeRows',
  //   value: 'executeRows',
  //   name: '执行行数权限',
  //   parentKey: 'operatingGroup',
  //   flowType: 'executionRows',
  // },
  {
    key: 'executeDate',
    value: 'executeDate',
    name: '执行时间权限',
    parentKey: 'timeLimitGroup',
    flowType: null,
  },
  {
    key: 'highRiskOperation',
    value: 'highRiskOperation',
    name: '高危资源',
    parentKey: 'resourceLimitGroup',
    flowType: 'highRisk',
  },
  {
    key: 'Desensitization',
    value: 'Desensitization',
    name: '脱敏资源',
    parentKey: 'Desensitization',
    flowType: 'dataMasking',
  },
  {
    key: 'ui_menu',
    value: 'ui_menu',
    name: '菜单权限',
    parentKey: 'ui_menu',
    flowType: 'menuAccess',
  },
  {
    key: 'SystemSettings',
    value: 'SystemSettings',
    name: '系统设置',
    parentKey: 'ui_menu',
    flowType: 'menuAccess',
  },
  {
    key: 'ConnectionSettings',
    value: 'ConnectionSettings',
    name: '连接设置',
    parentKey: 'ui_menu',
    flowType: 'menuAccess',
  },
  {
    key: 'AuditAnalysis',
    value: 'AuditAnalysis',
    name: '审计分析',
    parentKey: 'ui_menu',
    flowType: 'menuAccess',
  },
  // {
  //   key: 'Monitor',
  //   value: 'Monitor',
  //   name: '监控',
  //   parentKey: 'ui_menu',
  //   flowType: 'menuAccess',
  // },
  {
    key: 'FlowProcess',
    value: 'FlowProcess',
    name: '流程',
    parentKey: 'ui_menu',
    flowType: 'menuAccess',
  },
  // {
  //   key: 'Warning',
  //   value: 'Warning',
  //   name: '告警',
  //   parentKey: 'ui_menu',
  //   flowType: 'menuAccess',
  // },
]
