import type { ResponseNodeEntity, TreeNode } from 'src/types'

export const formatTreeNode = (node: ResponseNodeEntity): TreeNode => {
  const { nodeName, nodePath, hasChild } = node
  return {
    ...node,
    key: nodePath,
    title: nodeName,
    isLeaf: !hasChild,
  }
}

export const matchKeyword = (target = '', substring = '') => {
  if (!target) return false
  return target.toLowerCase().indexOf(substring.toLowerCase()) > -1
}

/** 搜索节点的返回值转换成NodeTree
 * @param ResponseNodeTree 服务器返回的节点树
 * @return treeNode 转换后的节点树
 */
export function convertResponseTreeToNodeTree(
  ResponseNodeTree: ResponseNodeEntity & { children?: ResponseNodeEntity[] },
) {
  const { children, ...node } = ResponseNodeTree
  node.hasChild = !!children?.length
  const treeNode = formatTreeNode(node)
  if (children && children.length) {
    treeNode.children = children.map(convertResponseTreeToNodeTree)
  }
  return treeNode
}
/**
 * 获取节点以及其子节点expandKeys以展开
 * @param treeNode
 * @returns keys
 */
export function getExpandKeysNodeTreeAllChildren(treeNode: TreeNode) {
  // if (!treeNode.children || !treeNode.children.length) {
  //   return [treeNode.key]
  // }
  const keys: string[] = []
  const getKeys = (node: TreeNode) => {
    if (!node.isLeaf) keys.push(node.key)
    if (node.children) {
      node.children.forEach((child) => getKeys(child))
    }
  }
  getKeys(treeNode)
  return keys
}

export const getExpandNodeNeedPathKeys = (path: string,needSplice?: boolean) => {
    let result = [];
    const parts = path?.split('/') || [];
    let current = '';
    for (let i = 1; i < parts.length; i++) {
      current += '/' + parts[i];
      result.push(current);
    }
    if (needSplice) {
      result.splice(0, 1); // 删除第一个空字符串
    }
   
    return result;
}
export const getPreExpandNodeNeedPathKeys = (path: string) => {
  let result = [];
  const parts = path?.split('/') || [];
  let current = '/root';
  for (let i = 2; i < parts.length-1; i++) {
    current += '/' + parts[i];
    result.push(current);
  }
 
  return result;
}
export const getGroupExpandNodeNeedPathKeys = (path: string,pre: string) => {
  let result = [];
  const parts = path?.split('/') || [];
  let current = pre;
  for (let i = 0; i < parts.length; i++) {
    current +=  ((i === parts.length || i === 0) ? '' : '/') + parts[i] ;
    result.push(current);
  }
  return result;
}