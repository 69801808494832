import React, { useState, useEffect, useMemo } from 'react'
import { SettingOutlined, RedoOutlined } from '@ant-design/icons'
import { useQuery } from 'src/hook'
import { Row, Col, Select, Radio, Button, Popconfirm } from 'antd'
import { DatePicker } from 'src/components'
import dayjs, { Dayjs } from 'dayjs'
import {
  getAuditUsers,
  OperationReportRequest,
  exportOperateLog,
} from 'src/api'
import styles from './index.module.scss'
import { formatDateRange, handleDownload } from 'src/util'
import { openNotification } from 'src/components/ExportNotification'

const { RangePicker } = DatePicker
const { Option } = Select

interface FilterParams {
  executors?: string[]
  timeRange?: [number, number] | null
  resultFlag?: string
  limit?: number
}

// 根据组件 filterParams 状态构造出查询请求所需要的参数
const getRequestParams = (filterParams: FilterParams) => {
  const { executors, timeRange, resultFlag } = filterParams
  // 构造查询参数
  const params: Partial<OperationReportRequest> = {
    executeBeginMs: timeRange?.[0],
    executeEndMs: timeRange?.[1],
  }
  if (executors?.length) {
    params.userIds = executors
  }
  if (resultFlag !== undefined) {
    params.resultFlag = Number(resultFlag)
  }
  delete filterParams.timeRange
  return { ...filterParams, ...params }
}

export const SearchHeader = ({
  queryParams = {},
  setSearchParams,
  refresh,
  showCustomColumnPanel,
}: {
  queryParams: any;
  setSearchParams: (values: any) => void
  refresh: () => void
  showCustomColumnPanel: () => void
}) => {

  const [filterParams, setFilterParams] = useState<FilterParams>(queryParams)
  // 全部用户列表
  const [userList, setUserList] = useState<any[]>([])

  const {
    executors,
    timeRange,
    resultFlag,
  } = filterParams
  const rangeValue = useMemo(() => {
    if (timeRange === null) {
      return null
    } else {
      const range = timeRange && timeRange.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [timeRange])

  useEffect(() => {
    // 获取用户列表
    const getUserList = async () => {
      try {
        const users = await getAuditUsers()
        setUserList(users)
      } catch { }
    }
    getUserList()
  }, [])

  useEffect(() => {
    setFilterParams(queryParams);
  }, [queryParams]);

  const exportData = () => {
    exportOperateLog(getRequestParams(filterParams) as Partial<OperationReportRequest>).then((res: number) => {
      const origin = window?.location?.origin;
      openNotification(
        '任务 操作记录导出',
        '已经执行完成，文件生成成功',
        () => handleDownload({ href: `${origin}/export/export/download/${res}` })
      )
    })
  }

  return (
    <div className={styles.searchHeader}>
      <Row justify="end" gutter={16} style={{ alignItems: 'center' }}>
        <label>过滤条件:</label>
        <Col>
          <Select
            mode="multiple"
            showArrow
            placeholder="用户"
            value={executors}
            onChange={(selected) => {
              setFilterParams({ ...filterParams, executors: selected })
            }}
            maxTagCount={2}
            allowClear
            style={{ minWidth: 144 }}
            optionFilterProp="label"
          >
            {userList.map((user) => {
              const { userId, userName } = user
              return (
                <Option
                  key={userId}
                  value={userId}
                  label={`${userName}(${userId})`}
                >
                  {`${userName}(${userId})`}
                </Option>
              )
            })}
          </Select>
        </Col>
        <Col>
          <RangePicker
            value={rangeValue}
            onChange={(dates) => {
              if (dates === null) {
                return setFilterParams({
                  ...filterParams,
                  timeRange: null,
                })
              }
              const [start, end] = formatDateRange(dates)
              if (start && end) {
                setFilterParams({
                  ...filterParams,
                  timeRange: [start, end],
                })
              }
            }}
            allowClear
            placeholder={['开始日期', '结束日期']}
          />
        </Col>
        <Col>
          <Button
            onClick={() => setSearchParams({ ...filterParams, offset: 0 })}
            type="primary"
            className="mr8"
          >
            查询
          </Button>
          <Button
            onClick={() =>
              setFilterParams({
                executors: [],
                timeRange: null,
                resultFlag: undefined,
              })
            }
            className="mr8"
          >
            重置
          </Button>
          <Popconfirm
            title="确定导出当前筛选结果？"
            onConfirm={exportData}
            placement="bottomRight"
          >
            <Button className="mr8">导出</Button>
          </Popconfirm>
          <Button
            icon={<RedoOutlined className={styles.refreshIcon} />}
            onClick={() => refresh()}
          />
          <Button
            className={styles.settingIcon}
            icon={<SettingOutlined />}
            type="primary"
            onClick={() => showCustomColumnPanel()}
          />
        </Col>
      </Row>
    </div>
  )
}
