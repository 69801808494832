import React, { useCallback } from 'react'
import { Form, Space, Typography } from 'antd'
import type { FlowDetailFields } from 'src/types'
import {
  flowDetailFieldLabelMap,
  flowTimeLimits,
  flowTypeLabelMap,
  flowTypeMapToDetailFields,
  menuOptions,
} from '../constants'
import { Iconfont } from 'src/components'
import dayjs from 'dayjs'
import styles from './flowDetail.module.scss'

export const FlowDescriptions = ({
  details,
}: {
  details?: FlowDetailFields
}) => {
  const renderField = useCallback(
    (details: FlowDetailFields, field: keyof FlowDetailFields) => {
      const nullText = <span className={styles.emptyField}>（空）</span>
      switch (field) {
        // 时间 format
        case 'applyTime':
        case 'updateTime':
          // ! 防止后端返回 null
          return dayjs(details[field] || '').format('YYYY-MM-DD HH:mm:ss')

        // 连接名（带图标）
        case 'connectionName': {
          const { connectionName, dataSourceType } = details
          return (
            <Space>
              <Iconfont type={`icon-connection-${dataSourceType}`} />
              {connectionName}
            </Space>
          )
        }

        // [数据订正] 执行方式
        case 'executeType':
          if (details[field] === 'auto') {
            return '审批通过后自动执行'
          }
          if (details[field] === 'manual') {
            return '审批通过后手动执行（点击对话框底部"订正"按钮）'
          }
          break

        // [数据订正]审批通过显示count
        case 'dataCorrectionCount':
        case 'rollbackCount':
            return details[field]
        // [数据订正] 执行语句/回退语句/错误信息
        case 'executeText':
        case 'rollbackText':
        case 'errorMessage':
          return details[field] ? (
            <Typography.Paragraph
              ellipsis={{ rows: 2, expandable: true }}
              style={{ marginBottom: 0 }}
            >
              {details[field]}
            </Typography.Paragraph>
          ) : (
            nullText
          )

        // 申请类型映射到中文
        case 'flowType':
          return flowTypeLabelMap[details[field]]

        // 期限
        case 'expiredTimeLimit': {
          const preset = flowTimeLimits.find(
            ({ value }) => details[field] === value,
          )
          if (preset) {
            return preset.label
          }
          if (details[field] === null) {
            return '永久'
          }
          return `${details[field]} 小时`
        }

        // list
        case 'operationList':
        case 'nodeList':
          return details[field]?.join(', ')

        // 菜单
        case 'objName':
          return details[field]
            ?.split(',')
            ?.map((el) => menuOptions.find(({ value }) => value === el)?.label)
            .join(', ')
        // 默认直接渲染值
        default:
          return details[field] || nullText
      }
    },
    [],
  )

  if (!details) return null

  const { flowType ,flowPhase,applyResult,isRollBack,isExecute } = details

  const fields = flowTypeMapToDetailFields[flowType]
  // 之前使用!applyreason 替换 applyResult!=='refuse'来判断
  //isRollBack/isExecute 为true判断是否展示两个影响行数字段
  const fieldsFiltered = applyResult !=='refuse' ? fields.filter(f=>  !(f === "refuseReason" || (f === 'exportNumLimit') || (!isExecute && f ==='dataCorrectionCount' ) || (!isRollBack && f ==='rollbackCount'))): fields.filter(f=> !(f =='dataCorrectionCount' || f ==='rollbackCount' || f === 'exportNumLimit'))
  return (
    <Form
      component={false}
      colon={false}
      labelAlign="left"
      labelCol={{ span: 4, offset: 1 }}
      wrapperCol={{ span: 18 }}
    >
      {fieldsFiltered?.map((field) => (
        <Form.Item
          className={styles.flowDetailItem}
          key={field}
          label={flowDetailFieldLabelMap[field]}
        >
          {renderField(details, field)}
        </Form.Item>
      ))}
    </Form>
  )
}
