import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'
// import type { IConnectionTreeNode } from 'src/pageTabs/connectionManagementPage/connectionTree/types'

export type AppProbeStatus = 'ACTIVE' | 'INACTIVE' | 'UNKNOWN'
export interface AppLocation {
  appCode: string
  appStatus: AppProbeStatus
  hostId: string | null
  id: number | string
  ipAddress: string | null
  probeStatus: AppProbeStatus
}

/* 资源管理 查询 展开*/
export interface ResourceDataType {
  id: number | string
  connectionId: number | string
  dbName: string
  schemaName: string
  note?: string
  enableStatus: boolean
  createBy?: string
  lastUpdateBy?: string
  createTime?: string
  lastUpdateTime?: string
  ideleted?: boolean
  admin?: string
  children?: any,
  searchName: string,
  onlyId: number | string
  hasChild: boolean
  parentId?: number | string
  dbTier?: boolean // 是否是dbName层
  databaseId?: number | string | null
  parentDBId?: number | string | undefined
}

/* 资源管理 启/禁用 */
export interface ResourceBatchStatusType {
  id?: number | string
  connectionId: number | string
  dbNameList?: any[]
  idList?: any[],
  enableStatus: boolean,
  schemaName: string | null
  parentId?: string | null | number
  dropPermission?: boolean
}

/* 新增内置规则 */
export interface AddRulesType {
  ruleName: string
  description: string
  resource: string
  algoName: string
  algoParam: any
}

export const getAppLocations = (
  appId: number | string,
): Promise<AppLocation[]> => {
  return fetchGet(`/user/app/appHostProbeStatus/${appId}`)
}

interface BindAppsRequest {
  appIds: number[]
  connectionId?: string | number
  connectionName?: string
  connectionType?: string
}
export const bindConnectionApps = (params: BindAppsRequest) => {
  return fetchPost(`/user/connections/app`, params)
}

export const getConnectionOverview = () => {
  return fetchGet(`/user/sys/statistics`)
}

interface UnbindAppsRequest {
  appId: number | string
  connectionId: number | string
}
export const unbindAppFromConnection = (params: UnbindAppsRequest) => {
  return fetchDelete(`/user/connections/app`, params)
}

type ConnectionGeneralConfigName = 'accountPasswordRule'
export interface AccountPasswordRule {
  pattern: number
  length: number
}
type ConnectionGeneralConfig = AccountPasswordRule

export const getConnectionGeneralConfig = (
  connectionId: number | string,
  configName: ConnectionGeneralConfigName,
): Promise<AccountPasswordRule> => {
  // todo: 暂时只处理 accountPasswordRule
  return fetchGet(
    `/user/app/connection/${connectionId}/generalConfig/${configName}`,
  )
}

export const setConnectionGeneralConfig = (
  connectionId: number | string,
  configName: ConnectionGeneralConfigName,
  params: ConnectionGeneralConfig,
) => {
  return fetchPost(
    `/user/app/connection/${connectionId}/generalConfig/${configName}`,
    params,
  )
}

// 获取连接通用设置
// todo: 后端给的新接口, 需要和旧的合并
type ConnectionConfigContext = {
  connectionId: number | string
  variable: string
  variable_value: string
}

export const getConnectionConfig = ({
  connectionId,
  variable,
}: {
  connectionId: number | string | undefined
  variable: string;
  dataSourceType?: string;
  path?: string;
}): Promise<ConnectionConfigContext> => {
  return fetchGet(
    `/user/connections/conectionSetting/${connectionId}/${variable}`,
  )
}

export const setConnectionConfig = (
  params: ConnectionConfigContext,
): Promise<ConnectionConfigContext> => {
  return fetchPost(`/user/connections/conectionSetting`, params)
}

interface IDesensRule {
  description?: string
  id?: number
  matchMethod: 'EXPRESSING'
  matchRule: string
  ruleName: string
  ruleStatus?: boolean
}

/* 获取脱敏规则 */
export const getDesensRules_api = (
  data: any,
): Promise<{ list: IDesensRule[]; total: number }> => {
  return fetchPost('/desens/desens/rule/find', data)
}

/* 保存所有脱敏规则 */
export const saveDesensRule_api = (data: IDesensRule) => {
  return fetchPost('/desens/desens/rule', data)
}

/* 更新敏感规则信息 */
export const updateDesensRule_api = (data: IDesensRule) => {
  return fetchPut('/desens/desens/rule', data)
}

/* 删除脱敏规则信息 */
export const deleteDesensRule_api = (params: any[]) => {
  return fetchDelete('/desens/desens/rule', params)
}

/* 更改脱敏规则状态 */
export const changeDesensRuleStatus_api = (
  ruleId: string,
  connectionId: string,
  operational: string,
) => {
  return fetchPut(`/desens/desens/rule/system`, {
    ruleId,
    connectionId,
    operational,
  })
}

/* 获取所有的扫描结果 */
export const getDesenScanResults_api = (data: any) => {
  return fetchPost('/desens/desens/scanner/find', data)
}

/* 添加为敏感资源 */
export const createDesensResource_api = (data: any) => {
  return fetchPost('/desens/desens/scanner/resource', data)
}

/* 删除扫描结果 */
export const deleteDesensScanResults_api = (scannerId: string) => {
  return fetchDelete(`/desens/desens/scanner/${scannerId}`)
}

/* 查询有效的脱敏规则 */
export const getUsefulDesenRules_api = (connectionId: string) => {
  return fetchGet(`/desens/desens/common/${connectionId}`)
}
//删除脱敏规则
export const deleteDesensRule = ({ id }: {
  id: number | string
}) => {
  return fetchDelete(`/desens/desens/internal/delete/rule/${id}`)
}

/** 在数据源下边的层级修改内置脱敏状态 */
export const updateSchemaInternal = (params: {
  connectionId: number | string
  ruleId: number | string
  nodePathWithType: string | null
  operational: string
}) => {
  return fetchPost(`/desens/desens/internal/update`, params)
}

/** 在数据源层级获取内置脱敏 */
export const getInternalDatasourceFind = (params: {
  datasourceType: string
  condition?: string
}) => {
  return fetchPost(`/desens/desens/internal/datasource/find`, params)
}
/** 在组层级获取内置脱敏 */
export const getInternalGroupFind = (params: {
  groupId: string
  condition?: string
}) => {
  return fetchPost(`/desens/desens/internal/group/find`, params)
}
/** 在数据源层级修改内置脱敏状态 */
export const updateDatasourceInternal = (params: {
  ruleId: number | string
  datasourceType: string
  operational: string | null
}) => {
  return fetchPost(`/desens/desens/internal/datasource/update`, params)
}
/** 在组层级修改内置脱敏状态 */
export const updateGroupInternalStatus = (params: {
  ruleId: number | string
  datasourceType: string
  operational: string | null
}) => {
  return fetchPost(`/desens/desens/internal/group/update`, params)
}

/** 查询内置脱敏规则 */
export const getInternalDenseRules = (params: {
  connectionId: number | string
  condition?: string
  datasourceType: string
  nodePathWithType: string | null
}) => {
  return fetchPost(`/desens/desens/internal/find`, params)
}

/** 更新内置脱敏规则状态 */
export const updateInternalDenseStatus = (params: {
  connectionId: number | string
  ruleId: number | string
  operational: 'ENABLE' | 'DEACTIVATE'
}) => {
  return fetchPut(`/desens/desens/internal`, params)
}

/** 查询系统内置脱敏规则 */
export const getSysInternalDenseRules = (params: {
  ruleId?: number | string
  ruleName?: string
}) => {
  return fetchPost(`/desens/desens/internal/sys/find`, params)
}

/** 更新系统内置脱敏规则状态 */
export const updateSysInternalDenseStatus = (params: {
  ruleId: number | string
  ruleName: string
}) => {
  return fetchPut(`/desens/desens/internal/update/sys/rule`, params)
}

/* 获取数据脱敏算法类型 */
export const getDesenDlgorithmTypes = () => {
  return fetchGet('/desens/desens/common/algorithm')
}

/* 查询用户权限详情 */
export const getUsersPermDetail = (data: {
  permissionId: number | string
  userIds: any
  permissionType: string
}) => {
  return fetchPost('/user/permission/permission/user', data)
}

/* 更新用户权限 */
export const updateUserPermission = (data: {
  permissionId: number | string
  userIds: any
  permissionType: string
}) => {
  return fetchPost('/user/permission/update/permission', data)
}

/* 删除用户流程权限 */
export const deleteUserFlowPermission = (data: {
  permissionId: number | string
  userIds: any
}) => {
  return fetchPost('/user/permission/delete/flow/permission', data)
}

/** 脱敏权限用户绑定 */
export const dataMaskingBindUsers = (data: {
  permissionId: number | string
  grantUser?: string[]
  removeUser?: string[]
}) => {
  return fetchPost(`/user/permission/desensitization/grant/user`, data)
}

/** 连接通用设置 保存授权管理员 */
export const assignConnectionOwners = (params: {
  connectionId: string | number
  userIds?: string[] // ! 这个接口有点别扭，不传 userIds 字段时为获取 ConnectionOwners 列表
}) => {
  return fetchPost(`/user/connections/user/setting`, params)
}

/** 连接通用设置 保存授权管理员 */
export const assignConnectionApprovals = (params: {
  connectionId: string | number
  userIds?: string[] // ! 这个接口有点别扭，不传 userIds 字段时为获取 ConnectionOwners 列表
}) => {
  return fetchPost(`/user/connections/connection/approval/setting`, params)
}

/* 获取行过滤权限内置表达式 */
export const getRowFilterBuildInExp_api = (): Promise<Record<string, string>> =>
  fetchGet(`/dms/common/row/filter/expression`)

/* 获取行过滤权限内置条件 */
export const getRowFilterBuildInCondition_api = (): Promise<
  Record<string, string>
> => fetchGet(`/dms/common/row/filter/condition`)

/* 获取用户连接管理列表 */
// export const getConnectionTree_api = (
//   data: Partial<IConnectionTreeNode>,
// ): Promise<IConnectionTreeNode[]> => fetchPost(`/dms/connection`, data)

/* 行过滤权限是否显示 */
export const getRowFilterPermissionSwitch = (): Promise<boolean> => {
  return fetchGet(`/user/permission/show_row_filter_permission`)
}


/* 资源管理 查询 */
export const getDmsResourceData = (
  connectionId: number | string,
  connectionType: string | any
): Promise<ResourceDataType[]> => {
  return fetchGet(`/dms/resource/${connectionId}/${connectionType}`)
}

/* 资源管理 展开 */
export const getDmsResourceUnfold = (
  connectionId: number | string,
  dbName: string,
  connectionType: string | any
): Promise<ResourceDataType[]> => {
  return fetchGet(`/dms/resource/${connectionId}/${dbName}/${connectionType}`)
}

/* 资源管理 全局启/禁用 */
export const changeResourceBatchStatus = (params: Partial<ResourceBatchStatusType>): Promise<ResourceDataType[]> => {
  return fetchPut(`/dms/resource/batchChangeStatus`, params)
}
//批量禁用启用
export const batchPartChangeStatus = (params: Partial<ResourceBatchStatusType[]>): Promise<ResourceDataType[]> => {
  return fetchPost(`/dms/resource/partChangeStatus`, params)
}

/* 资源管理 单行 启/禁用 */
export const changeResourceRowStatus = (params: Partial<ResourceBatchStatusType>): Promise<ResourceDataType[]> => {
  return fetchPut(`/dms/resource/changeStatus`, params)
}

/* 获取所有支持的算法 */
export const getResourcesAllRule = () => {
  return fetchGet('/desens/resource/all/rule')
}

/* 新增内置规则 */
export const addResourcesRule = (params: Partial<AddRulesType>) => {
  return fetchPost('/desens/desens/internal/add/rule', params)
}
/* 获取秘钥 */
export const findSysSecret = (params: {
  connectionId: number | string
}) => {
  return fetchPost(`/desens/desens/internal/find/sys/secret`, params)
}

/* 变更秘钥 */
export const updateSysSecret = (params: {
  connectionId: number | string
  secretKey: string
}) => {
  return fetchPost(`/desens/desens/internal/update/sys/secret`, params)
}

/* SQL闪回 */
export const sqlFlashBackSetting = (params: {
  connectionId: undefined | string
  variable: string
  variable_value: boolean
}) => {
  return fetchPost(`/user/connections/conectionSetting`, params)
}

/* 是否开启数据备份 */
export const getSqlBackLocationSetting = (params: {
  connectionId: undefined | string
  variable: string
  variable_value: boolean
}) => {
  return fetchPost(`/user/connections/conectionSetting`, params)
}

/* 获取SQL闪回配置 */
export const getSqlFlashBack = (
  connectionId: number | string | undefined,
  variable: string | undefined
) => {
  return fetchGet(`/user/connections/conectionSetting/${connectionId}/${variable}`)
}