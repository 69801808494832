import React, { useState } from "react"
import { Button, Popconfirm, Table, Typography, message } from "antd"
import { ColumnsType } from "antd/lib/table";
import { useHistory } from 'react-router'
import { delSms, getSms } from "src/api";
import { useRequest } from "src/hook";
import { getValueByKey } from "src/util";
import { typeEnum } from "src/constants";

interface DataType {
  key: string;
  name: string;
  type: string;
}

interface IProps {
  tabKey: string
  [p: string]: any
}

export const AlarmConfigCard = (props: IProps) => {
  
  const { Text } = Typography

  const history = useHistory()
  const [dataSource, setDataSource] = useState<any>()

  const { 
    loading,
    data: smsData,
    run: getSmsRun
  } = useRequest(getSms, {
    onSuccess: (res) => {
      let data: any = []
      !!res && res.map((item: any) => {
        if (item?.businessType && !['SMS', 'DOUBLE_CHECK'].includes(item?.businessType)) {
          data.push(item)
        }
      })
      setDataSource(data)
    }
  })

  const {
    run: delSmsRun
  } = useRequest(delSms, {
    manual: true,
    onSuccess: (res) => {
      message.success('删除成功')
      getSmsRun()
    }
  })

  const columns: ColumnsType<DataType> = [
    {
      title: '短信通道模板名称',
      dataIndex: 'smsName',
      key: 'smsName',
      width: "40%",
      render: (text: any) => <>{text}</>,
    },
    {
      title: '生效告警类型',
      dataIndex: 'businessType',
      key: 'businessType',
      width: "30%",
      render: (text: string[]) => {
        const str =  getValueByKey(typeEnum, text)
        return <Text>{str}</Text>
      }
    },
    {
      title: '操作',
      key: 'action',
      width: "30%",
      render: (text: any, record: any) => (
        <>
        <Button type="link" onClick={() => handleOnClick("edit", record)}>
          编辑
        </Button>
        <Button type="link" onClick={() => handleOnClick("copy", record)}>
          复制
        </Button>
        <Popconfirm
          title="确认要删除吗？"
          onConfirm={() => {delSmsRun(text.id)}}
          okText="是"
          cancelText="否"
        >
          <Button type="link">
            删除
          </Button>
        </Popconfirm>
        </>
      ),
    },
  ];

  const handleOnClick = (actionType: string, record?: any) => {
    let params = {
      record: null,
      actionType: actionType
    } as any
    if (actionType !== "add") {
      params.record = record
    }
    history.push({
      pathname: `/system_addChannelModal`, state: params
    })
  }
  
  return (
    <>
      <Button type="primary" style={{marginBottom: 20}} onClick={() => {handleOnClick("add")}}>新建短信通道模板</Button>
      <Table
        key={"alarmConfigCard"}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: 470 }} 
      />
    </>
  )
}