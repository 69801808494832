/**
 * 权限等级弹框（查看/编辑）
 */
import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Tabs, Row, Col, Checkbox, Spin } from 'antd'
import {
  getPermissionTemplate,
  getToolTemplateInfo,
} from 'src/api'
import { useSelector, useDispatch } from 'src/hook'
import { setPermissionTemplate } from './permissionLevelSlice'
import styles from './index.module.scss'

interface IProps {
  visible: boolean
  setModalVisible: (v: boolean) => void
  optionType: string
  dataSourceType: string
  [p: string]: any
}
const PermissionLevelModal = (props: IProps) => {
  const {
    visible,
    optionType,
    dataSourceType,
    selectNodeType,
    setModalVisible,
    templateOperationsSet,  // 工具权限选中值
    callback,
  } = props

  const dispatch = useDispatch()
  const { permissionTemplate } = useSelector(
    (state) => state.permissionLevelData
  );
  const [activeTabKey, setActiveTabKey] = useState<string>()
  const [checkedParams, setCheckedParams] = useState<any[]>([])
  const [loading, setLoading]= useState(false)

  useEffect(() => {
    if (dataSourceType) {
      // 编辑
      if (optionType === 'editToolPermission') {
        queryToolTemplate(getToolTemplateInfo, dataSourceType, selectNodeType)
      } else {
        queryTemplate(getPermissionTemplate, dataSourceType)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceType, optionType, selectNodeType])

  useEffect(() => {
    // 回填勾选值
   if (templateOperationsSet?.length) { 
      setCheckedParams(templateOperationsSet)
    }
  }, [templateOperationsSet])

  // 查询所有权限模版
  const queryTemplate = (fn: (p: string) => any, dataSourceType: string) => {
    setLoading(true)
    fn(dataSourceType)
      .then((data: any) => {
        setActiveTabKey(data[0]?.objectType)
        dispatch(setPermissionTemplate(data))
      })
      .catch((err: any) => {
        console.error('查询失败', err)
      }).finally(()=>{
        setLoading(false)
      })
  }

  const queryToolTemplate = (fn: (p: string, n: string) => any, dataSourceType: string, nodeType: string) => {
    setLoading(true)
    fn(dataSourceType, nodeType)
        .then((data: any) => {
          dispatch(setPermissionTemplate(data))
          setActiveTabKey(data[0]?.objectType)
        })
        .catch((err: any) => {
          console.error('查询失败', err)
        }).finally(()=>{
      setLoading(false)
    })
  }

  const handleTabsChange = (activeKey: string) => {
    setActiveTabKey(activeKey)
  }

  const handleChange = (operations: any[], objectType: string) => {
    setCheckedParams((data: any) => {
      const has = data?.filter((i: any) => i?.objectType === objectType)?.length
      if (has) {
        data = data.map((i: any) => {
          if (i?.objectType === objectType) {
            return {
              ...i,
              operations,
            }
          }
          return { ...i }
        })
      } else {
        data = data.concat([
          {
            objectType,
            operations,
          },
        ])
      }
      return [...data]
    })
  }

  // 修改工具权限
  const handlePermissionSave = () => {
    setModalVisible(false)
    callback && callback(checkedParams)
  }

  // 查看或没有权限模板都不可以提交
  const btnDisabled = optionType === 'look' || !permissionTemplate?.length
  return (
    <Modal
      width={1000}
      title="操作权限"
      okText="提交"
      visible={visible}
      onCancel={() => setModalVisible(false)}
      onOk={handlePermissionSave}
      okButtonProps={{ disabled: btnDisabled, loading }}
      destroyOnClose
    >
      <Spin spinning={loading}>
        <Tabs onChange={handleTabsChange} activeKey={activeTabKey} className={styles.customAuthorizeTab}>
          {permissionTemplate?.map((item: any) => {
            const checkedValues = checkedParams?.filter(
              (i: any) => i?.objectType === item?.objectType,
            )?.[0]?.operations

            const allOptions = permissionTemplate
            .filter((i: any) => i?.objectType === activeTabKey)?.[0]
            ?.operations?.map((i: any) => i?.operation)

            return (
              <Tabs.TabPane tab={item?.objectTypeName} key={item?.objectType}>
                <CheckBoxItem
                  item={item}
                  optionType={optionType}
                  activeTabKey={activeTabKey}
                  allOptions={allOptions}
                  checkedValues={checkedValues}
                  checkChangeCallback={handleChange}
                />
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </Spin>
    </Modal>
  )
}
export default PermissionLevelModal

const CheckBoxItem = (props: any) => {
  const {
    item,
    optionType,
    activeTabKey,
    allOptions,
    checkedValues=[],
    checkChangeCallback,
  } = props
 
  const { indeterminate, checkAll, checkedList } = useMemo(() => {
    const checkAll = checkedValues?.length === allOptions?.length
    const checkedList = checkedValues
    const indeterminate =
      !!checkedValues?.length && checkedValues?.length < allOptions?.length
    return {
      checkAll,
      checkedList,
      indeterminate,
    }
  }, [allOptions, checkedValues])

  const onCheckAllChange = (e: any) => {
    const checkedList = e.target.checked ? allOptions : []
    checkChangeCallback(checkedList, activeTabKey)
  }

  const handleGroupChange = (checkedList: any) => {
    checkChangeCallback(checkedList, activeTabKey)
  }

  return (
    <>
      <Row className={'mb10'}>
        <Checkbox
          checked={checkAll}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          disabled={optionType === 'look'}
        >
          全选
        </Checkbox>
      </Row>
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={handleGroupChange}
        value={checkedList}
        disabled={optionType === 'look'}
      >
        {item?.operations?.map((res: any, index: number) => (
          <Row className={'mb10'} key={index}>
            <Col span={8}>
              <Checkbox className={'mr8'} value={res.operation} />
              {res.operationName}
            </Col>
            <Col span={16}>{res.description}</Col>
          </Row>
        ))}
      </Checkbox.Group>
    </>
  )
}

