import { message, Tree } from 'antd'
import { DataNode, EventDataNode } from 'antd/lib/tree'
import React, { useEffect, useState } from 'react'
import { getFileContent, getUserFiles, UserFile } from 'src/api'
import { UIModal } from 'src/components'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import { useDispatch, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { setFilePath } from 'src/store/extraSlice/textImportSlice'
import {
  setMonacoServerFile,
  updateMonacoTabEncoding,
  updateMonacoTabName,
} from '../../queryTabsSlice'

export interface FileDataNode extends DataNode {
  userFile: UserFile
}

export const updateTreeData = (
  list: DataNode[],
  key: React.Key,
  children: DataNode[],
): DataNode[] => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      }
    } else if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      }
    }
    return node
  })
}

const formatTreeData = (userFiles: UserFile[]): FileDataNode[] => {
  return userFiles.map((userFile) => {
    const { path, name, type } = userFile
    return {
      title: name,
      key: path,
      isLeaf: type !== 'directory',
      selectable: type !== 'directory',
      userFile,
    }
  })
}

export const ServerFilesModal = ({ModalOpenServerFile}:{ModalOpenServerFile?:boolean}) => {
  const dispatch = useDispatch()
  const [editorInstance] = useEditorInstance()
  const [treeData, setTreeData] = useState<any[]>([])

  const ModalTextImportWizard = useSelector((state) => state.modal.ModalTextImportWizard)
  const { fileType } = useSelector(
    (state) => state.textImport,
  )

  const tryOpenFile = async (userFile: UserFile) => {
    const { path, name } = userFile
    const model = editorInstance?.getModel()
    if (!model) return
    return getFileContent(path).then(({ content, encoding }) => {
      dispatch(setMonacoServerFile(userFile))
      dispatch(updateMonacoTabName(name))
      dispatch(updateMonacoTabEncoding({ encoding }))
      model.pushEditOperations(
        null,
        [{ range: model.getFullModelRange(), text: content }],
        () => null,
      )
      editorInstance?.focus()
    })
  }

  const handleOpenFile = (file?: FileDataNode) => {
    file = file || selectedNodes[0]
    if (!file) return message.error('请选择文件')
    if (file.userFile.type === 'directory') return
    if(ModalTextImportWizard) {
      const title = file.title?.toString()!
      const suffix = title?.split('.')?.pop()!;

      // 校验是否为选择的文件类型
      if(fileType && suffix !== fileType){
        return message.error(`请选择${fileType}类型文件`)
      }

      const isTr = ['txt', 'csv', 'xls', 'xlsx'].includes(suffix)
      if(!isTr){
        return message.error('只支持 .txt/.csv/.xls/.xlsx 类型文件')
      }
      dispatch(setFilePath(file.key as string))
    } else {
      tryOpenFile(file.userFile)
    }
    // todo: 加入 loading 交互
    dispatch(hideModal('ModalOpenServerFile'))
  }

  const onLoadData = ({ key, children }: EventDataNode): Promise<void> => {
    return new Promise((resolve) => {
      if (children) {
        resolve()
        return
      }
      getUserFiles({ path: String(key), condition: '' }).then((userFiles) => {
        setTreeData((origin) =>
          updateTreeData(origin, key, formatTreeData(userFiles)),
        )
        resolve()
      })
    })
  }

  useEffect(() => {
    if (!ModalOpenServerFile) return
    getUserFiles({ path:'/', condition: '' }).then((userFiles) => {
      setTreeData(formatTreeData(userFiles))
    })
  }, [ModalOpenServerFile])

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['/'])
  const [selectedNodes, setSelectedNodes] = useState<FileDataNode[]>([])
  return (
    <UIModal
      title="打开文件"
      visible={ModalOpenServerFile}
      onCancel={() => dispatch(hideModal('ModalOpenServerFile'))}
      onOk={() => handleOpenFile()}
      width={520}
      bodyStyle={{ height: '50vh' }}
      afterClose={() => {
        setExpandedKeys([])
        setTreeData([])
        setSelectedNodes([])
      }}
      okText="打开"
    >
      <Tree.DirectoryTree
        loadData={onLoadData}
        treeData={treeData}
        expandedKeys={expandedKeys}
        onExpand={(expandedKeys) => setExpandedKeys(expandedKeys)}
        expandAction="doubleClick"
        onSelect={(_selectedKeys, { selectedNodes }) =>
          setSelectedNodes(selectedNodes as FileDataNode[])
        }
        titleRender={(node) => {
          return (
            <span
              onDoubleClick={() => {
                handleOpenFile(node as FileDataNode)
              }}
            >
              {node.title}
            </span>
          )
        }}
      ></Tree.DirectoryTree>
    </UIModal>
  )
}
