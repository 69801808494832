import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core'
import { Descriptions, Spin, Tooltip } from 'antd'
import { DataSourceType, getTableColumns } from 'src/api'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import styles from './viewTableStructurePane.module.scss'
import { GridConfigBase } from '../../resultTabs/resultContentGrid'
import classnames from 'classnames'

type TabOption = 'columns' | 'indexes' | 'foreignKeys'

const getTabTitle = (tabOption: TabOption) => {
  switch (tabOption) {
    case 'columns':
      return '字段'
    case 'indexes':
      return '索引'
    case 'foreignKeys':
      return '外键'
  }
}

const defaultColDef: ColDef = {
  resizable: true,
  editable: false,
  tooltipValueGetter: ({ value }) => value,
}

interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
}

export const ViewTableStructurePane = ({
  databaseInfo,
}: CreateTablePaneProps) => {
  const { theme } = useSelector((state) => state.appearance)
  const {
    connectionId,
    connectionType = 'MySQL',
    nodePath,
    nodePathWithType,
    nodeName,
    nodeType,
    databaseName,
  } = databaseInfo

  const [tabOption, setTabOption] = useState<TabOption>('columns')

  const getFieldDefs = (connectionType: DataSourceType) => {
    const baseColumnDefs: ColDef[] = [
      { field: 'columnName', headerName: '列名', minWidth: 160 },
      { field: 'columnType', headerName: '类型', minWidth: 120 },
      { field: 'columnLength', headerName: '长度' },
      { field: 'precision', headerName: '精度' },
      // { field: 'unit', headerName: '单位' } /** 暂不支持 */,
      {
        field: 'defaultValue',
        headerName: '默认值',
        cellRenderer: 'simpleTextRenderer',
      },
      { field: 'columnComment', headerName: '备注', minWidth: 120 },
      {
        field: 'allowNull',
        headerName: '允许 null',
        cellRenderer: 'booleanRenderer',
      },
      {
        field: 'autoIncrease',
        headerName: '自增',
        cellRenderer: 'booleanRenderer',
      },
      {
        field: 'primary',
        headerName: '是否主键',
        cellRenderer: 'booleanRenderer',
      },
    ]
    switch (connectionType) {
      case 'Oracle':
      case 'OracleCDB':
      case 'SQLServer':
      case 'StarRocks':
        return baseColumnDefs.filter((def) => def.field !== 'autoIncrease')
      case 'Hive':
      case 'Inceptor':
        return baseColumnDefs.filter((def) =>
          ['columnName', 'columnType', 'columnComment'].includes(
            def.field || '',
          ),
        )
      case 'MySQL':
      default:
        return baseColumnDefs
    }
  }
  const getIndexDefs = useCallback((): (ColDef | ColGroupDef)[] => {
    const baseIndexDefs = [
      { field: 'indexName', headerName: '索引名', minWidth: 200 },
      { field: 'tableName', headerName: '所属表' },
      { field: 'columnName', headerName: '索引列' },
      { field: 'indexType', headerName: '索引类型' },
      { field: 'sortType', headerName: '索引排序' },
      {
        field: 'unique',
        headerName: '是否唯一',
        cellRenderer: 'booleanRenderer',
      },
    ]
    switch (connectionType) {
      case 'Hive':
      case 'Inceptor':
        return baseIndexDefs
          .filter((def) => !['sortType', 'unique'].includes(def.field))
          .concat([
            { field: 'idx_tab_name', headerName: '索引表名' },
            { field: 'comment', headerName: '注释' },
          ])
      default:
        return baseIndexDefs
    }
  }, [connectionType])
  const getForeignKeyDefs = (): (ColDef | ColGroupDef)[] => {
    return [
      { field: 'foreignKeyName', headerName: '外键名称', minWidth: 200 },
      { field: 'columnName', headerName: '列名称' },
      { field: 'referencedSchemaName', headerName: '参照模式' },
      { field: 'referencedTableName', headerName: '参照表' },
      {
        field: 'referencedColumnNames',
        headerName: '参照列名',
        valueFormatter: ({ value }) =>
          // todo: Defensive code。应由后端保证字段类型准确
          Array.isArray(value) ? value.join(', ') : value,
      },
      { field: 'onDelete', headerName: '删除时' },
      { field: 'onUpdate', headerName: '更新时' },
    ]
  }

  const {
    data: tableAndColumns,
    run: tryGetTableColumns,
    loading: loadingGetTableColumns,
  } = useRequest(
    () => {
      if (!(connectionId && nodeName && nodePath && nodePathWithType && nodeType)) return
      return getTableColumns({
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        databaseName,
      })
    },
    { manual: true },
  )
  const { tableComment } = tableAndColumns || {}

  const rowData = useMemo(
    () => tableAndColumns?.[tabOption] || [],
    [tabOption, tableAndColumns],
  )

  const getColumnDefs = useCallback(
    (tabOption: TabOption) => {
      switch (tabOption) {
        case 'columns':
          return getFieldDefs(connectionType)
        case 'indexes':
          return getIndexDefs()
        case 'foreignKeys':
        default:
          return getForeignKeyDefs()
      }
    },
    [connectionType, getIndexDefs],
  )

  useEffect(() => {
    gridApiRef.current?.setColumnDefs(getColumnDefs(tabOption))
  }, [tabOption, getColumnDefs])

  useEffect(() => {
    gridApiRef.current?.sizeColumnsToFit()
  }, [rowData])

  useEffect(() => {
    tryGetTableColumns()
  }, [tryGetTableColumns])

  const gridApiRef = useRef<GridApi>()
  const gridColumnApiRef = useRef<ColumnApi>()

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
    gridColumnApiRef.current = params.columnApi
  }

  const getTabButton = (option: TabOption) => (
    <div
      className={classnames(
        styles.tabButton,
        option === tabOption ? styles.active : '',
      )}
      onClick={() => setTabOption(option)}
    >
      {getTabTitle(option)}
    </div>
  )

  return (
    <Spin spinning={loadingGetTableColumns} wrapperClassName={styles.page}>
      <div className={styles.body}>
        <div className={styles.tabBar}>
          {getTabButton('columns')}
          {getTabButton('indexes')}
          {getTabButton('foreignKeys')}
        </div>
        <div className={styles.content}>
          <div className={styles.tableInfo}>
            <Descriptions column={1}>
              <Descriptions.Item label="表注释">
                <Tooltip title={tableComment}>
                  <div className={styles.descContent}>
                    {tableComment || (
                      <div className={styles.placeholder}>(无)</div>
                    )}
                  </div>
                </Tooltip>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className={styles.columnInfo}>
            <div
              id="myGrid"
              style={{
                height: '68vh',
                width: '100%',
              }}
              // todo: 更好地统一处理主题样式
              className={
                theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
              }
            >
              <AgGridReact
                {...GridConfigBase}
                modules={[ClientSideRowModelModule]}
                columnDefs={getFieldDefs(connectionType)}
                defaultColDef={defaultColDef}
                rowDragManaged={true}
                animateRows={true}
                onGridReady={onGridReady}
                rowData={rowData}
                rowSelection="single"
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}
