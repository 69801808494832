import React from 'react'
import { Form } from 'antd'
import {
  // BackupCard,
  EmailCard,
  OtherCard,
  PasswordCard,
  LogoImageCard
} from './settingCards'

import { SettingFormContext } from './SettingFormContext'
import { ParsingConfigurationCard } from './settingCards/ParsingConfigurationCard'
import { AuthorizationCard } from './settingCards/AuthorizationCard'
import { SingleSignOnCard } from './settingCards/SingleSignOnCard'
import { AppSecret }  from './settingCards/appSecretPage'
import { WatermarkCard } from './settingCards/WatermarkCard'
import { UploadCqDataCard } from './settingCards/UploadCqDataCard';
import { AccessPolicyCard } from './settingCards/AccessPolicyCard';
import { AlarmConfigCard } from './settingCards/AlarmConfigCard'
import { SmsGatewayCard } from './settingCards/SmsGatewayCard'

const FormLayout = {
  labelCol: { span: 5 },
  wrapperCol: { offset: 1, span: 14 },
}
interface IProps {
  activeKey: string
  subTabKey: string
}
export const SettingBasicPage = (props: IProps) => {
  const { activeKey, subTabKey } = props
  const [form] = Form.useForm()

  return (
    <SettingFormContext.Provider value={form}>
      <Form component={false} form={form} {...FormLayout}>
        {
          !!activeKey && activeKey === 'LogoImageCard' && <LogoImageCard />
        }
         {
          !!activeKey && activeKey === 'AppSecretPage' &&  <AppSecret />
        }
        {
          !!activeKey && activeKey === 'EmailCard' && <EmailCard />
        }
        {
          !!activeKey && activeKey === 'SmsGatewayCard' &&  <SmsGatewayCard subTabKey={subTabKey} />
        }
        {
          !!activeKey && activeKey === 'SingleSignOnCard' &&  <SingleSignOnCard />
        }
        {
          !!activeKey && activeKey === 'OtherCard' && <OtherCard />
        }
        {
          !!activeKey && activeKey === 'PasswordCard' && <PasswordCard />
        }
        {
          !!activeKey && activeKey === 'ParsingCard' && <ParsingConfigurationCard />
        }
        {
          !!activeKey && activeKey === 'AuthorizationCard' && <AuthorizationCard />
        }
        {
          !!activeKey && activeKey === 'WatermarkCard' && <WatermarkCard />
        }
        {
          !!activeKey && activeKey === 'UploadCqDataCard' && <UploadCqDataCard />
        }
        {
          !!activeKey && activeKey === 'AccessPolicyCard' && <AccessPolicyCard />
        }
        {
          !!activeKey && activeKey === 'AlarmConfigCard' && <AlarmConfigCard />
        }
      </Form>
    </SettingFormContext.Provider>
  )
}
