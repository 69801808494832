/**
 * 手机号解绑
 */
import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Tabs, Button, message } from 'antd'
import { useCounter, useInterval } from 'react-use' 
import { useRequest, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import { sendPhoneCode, unBindPhone, sendEmailVerificationCode } from 'src/api'

const FormLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
}

interface IProps {
  visible: boolean
  setVisible: (b: boolean) => void
  [p: string]: any
}
const PhoneUnbindModal = ({visible, setVisible, refresh}: IProps) => {
  const [form] = Form.useForm()
  const { userPhone, userEmail, loginType } = useSelector((state) => state.personalCenter)
  const { userId } = useSelector((state) => state.login.userInfo)
  const [tabsKey, setTabsKey] = useState<string>('1')
  const [phoneCount, { dec:phoneDec, reset: phoneReset, set: phoneSet }] = useCounter(60, 60, 0)
  useInterval(() => phoneDec(1), 1000)
  useEffect(() => {
    phoneSet(0)
  }, [phoneSet])

  const [emailCount, { dec: emailDec, reset: emailReset, set: emailSet }] = useCounter(60, 60, 0)
  useInterval(() => emailDec(1), 1000)
  useEffect(() => {
    emailSet(0)
  }, [emailSet])

  useEffect(() => {
    if (loginType === 'cq') {
      setTabsKey('1')
    } else if (userPhone) {
      setTabsKey('2')
    }else if (userEmail){
      setTabsKey('3')
    }
  },[loginType, userPhone, userEmail])

  const handleTabChange = (val: string) => {
    setTabsKey(val)
  }

  /** 发送手机验证码 */
  const { run: sendPhoneCodeRun } = useRequest(sendPhoneCode, {
    manual: true,
    onSuccess() {
      message.success(`验证码已发送`)
      phoneReset()
    }
  })

  /** 发送邮箱验证码 */
  const { run: sendEmailCode } = useRequest(sendEmailVerificationCode, {
    manual: true,
    onSuccess(data) {
      message.success(data?.message)
      emailReset()
    }
  })
  
  const handleSubmit = () => {
    form.validateFields().then((values: any) => {
      console.log(values)
      let params: any = { }
      if (tabsKey === '1') {
        params = {
          type:'PWD',
          password: values?.password
        }
      }else if(tabsKey === '2'){
        params = {
          type:'SMS',
          phoneCode: values?.phoneCode
        }
      }else {
        params = {
          type:'EMAIL',
          emailCode: values?.emailCode
        }
      }
      unBindPhone(params).then(() => {
        message.success('解绑成功')
        setVisible(false)
        refresh && refresh()
        form.resetFields();
      }).catch((err: any) => {
        console.error('解绑失败', err)
      })
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  const getValidateCode = () => {
    if(!userPhone){
      message.warning('没有可验证的手机号')
      return
    }
    sendPhoneCodeRun(userPhone)?.then().catch((err: any)=>{console.error('发送验证码失败', err)})
  }

  const AccountPassword = () => {
    return (
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="账号"
          name="userId"
        >
          <span>{userId}</span>
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password autoFocus />
        </Form.Item>
      </Form>
    )
  }

  const PhoneElement = () => {
    return (
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="手机号码"
          name="phone"
        >
          <span>{userPhone && userPhone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</span>
        </Form.Item>
        <Form.Item
          label="验证码"
        >
          <div className="flex">
            <Form.Item
              name="phoneCode"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input autoFocus />
            </Form.Item>
            <Button
              disabled={!!phoneCount}
              type='primary'
              className="ml10"
              onClick={getValidateCode}>
              {phoneCount ? `${phoneCount}s` : '获取验证码'}
            </Button>
          </div>
        </Form.Item>
        <div style={{ marginLeft: '35px' }}>
          <p>1. 接收验证码的手机号为您账号中绑定的安全手机号</p>
          <p>2. 发送验证码后，您可以在手机短信中获取（1分钟内未收到，建议在垃圾短信中查看）</p>
        </div>
      </Form>
    )
  }

  const EmailElement = () => {
    return (
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="邮箱"
          name="email"
        >
          <span>{userEmail}</span>
        </Form.Item>
        <Form.Item
          label="验证码"

        >
          <div className="flex">
            <Form.Item
              name="emailCode"
              rules={[{ required: true, message: '请输入验证码' }]}
            >
              <Input autoFocus />
            </Form.Item>
            <Button
              className="ml10"
              type='primary'
              disabled={!!emailCount}
              onClick={() => {
                sendEmailCode()?.then().catch((err)=>{console.error('发送验证码失败', err)})
              }}>
              {emailCount ? `${emailCount}s` : '发送验证码'}
            </Button>
          </div>
        </Form.Item>
        <div style={{ marginLeft: '35px' }}>
          <p>1. 接收验证码的邮箱号为您账号中绑定的邮箱号</p>
          <p>2. 发送验证码后，您可以在邮箱中获取（1分钟内未收到，建议在垃圾邮件中查看）</p>
        </div>
      </Form>
    )
  }

  return (
    <UIModal 
      title="手机解绑" 
      visible={visible} 
      okText="立即解绑"
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <div style={{display:'flex', margin: '10px 0'}}>
        <div style={{ flex: 2 }}>
          <Tabs
            tabPosition={'left'}
            activeKey={tabsKey}
            onChange={handleTabChange}
          >
            {
              loginType === 'cq' &&
              <Tabs.TabPane tab='账号密码验证' key="1" />
            }
            {
              userPhone && 
              <Tabs.TabPane tab='手机号验证' key="2" />
            }
            {
              userEmail && 
              <Tabs.TabPane tab='邮箱验证' key="3" />
            }
          </Tabs>
        </div>
        <div style={{ flex: 8, background: '#F7F9FC', padding: '10px 0' }}>
          {
            tabsKey === '1' && <AccountPassword />
          }
          {
            tabsKey === '2' && <PhoneElement />
          }
          {
            tabsKey === '3' && <EmailElement />
          }
        </div>
      </div>
    </UIModal>
  )
}
export default PhoneUnbindModal