import { useSelector, useDispatch } from 'src/hook'
import { hideModal, ModalName, showModal } from 'src/store/extraSlice/modalVisibleSlice'

export const useModal = (modelName: ModalName) => {
  const visible = useSelector((state) => state.modal[modelName])
  const dispatch = useDispatch()
  const closeModal = () => dispatch(hideModal(modelName))
  const openModal = () => dispatch(showModal(modelName))
  return { visible, closeModal, openModal }
}
