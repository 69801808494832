// sql审核结果弹框内容
import React, { useEffect, useState } from "react"
import { Modal, Pagination, Tooltip, Button } from "antd"
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'src/hook'
import { setExamineReviewResult } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'

interface IProps {
  activePaneKey: string
  execSegment: (params: any) => void,
}
const SqlExecuteAuditeModal = ({
  execSegment,
  activePaneKey
}: IProps) => {
  const dispatch = useDispatch()
  const { executeActiveTabParams } = useSelector((state) => state?.queryTabs);
  const [reviewCurrent, setReviewCurrent] = useState<number>(1)
  const examineReviewResult = useSelector((state) => state.queryTabs.examineReviewResult)
  const [examineModalVisible, setExamineModalVisible] = useState(false)
  const { reviewItems=[],  } = examineReviewResult || {}

  useEffect(()=>{
    return ()=>{
      dispatch(setExamineReviewResult({}))  // 重置审核结果
    }
  },[dispatch])

  useEffect(()=>{
    setExamineModalVisible(!!reviewItems?.length)
  }, [reviewItems, reviewItems?.length])

  const handlePageChange = (page: number) =>{
    setReviewCurrent(page)
  }

  const handleExamineModalClose = (execute?: boolean) => {
    setExamineModalVisible(false)
    dispatch(setExamineReviewResult({}))  // 重置审核结果
    if(execute && activePaneKey){
      const memoParams = executeActiveTabParams[activePaneKey] || {}
      const params: any = {...memoParams, reviewResultId: examineReviewResult?.reviewResultId}
      delete params?.statements
      execSegment(params)
    }
  }

  const renderMessageInfo = (data: any[], type: string, color: string) => data?.map(
    (item: any, index: number)=>{
      const {brief, description, params} = item
      const paramParseArr = params && JSON.parse(params)
      return (
        <div key={index} className='flexAlignCenter mb10'>
          <span style={{color}} className='mr10 tc lh18' >
            <ExclamationCircleOutlined />
            <div>{type}</div>
          </span>
          <Tooltip 
            title={
              <>
                <div>{description}</div>
                {
                  paramParseArr?.length &&
                  <div>参数：{paramParseArr?.map((i: any)=>(`${i?.desc}:${i?.value}`))?.join(',')}</div>
                }
              </>
            }
          >
            <span>{brief}</span>
          </Tooltip> 
        </div>
      )
    }
  )
  if(!examineModalVisible){
    return null
  }
  return (
    <Modal
      maskClosable={false}
      visible={examineModalVisible}
      onCancel={()=>handleExamineModalClose()}
      title="SQL审核结果"
      footer={
        [3,4,6].includes(examineReviewResult?.flag) 
        ?
          [
            <Button onClick={()=>handleExamineModalClose(true)}>继续执行</Button>,
            <Button onClick={()=>handleExamineModalClose()} className='ml10' type="primary">返回修改</Button>
          ]
        :
          [
            <Button  onClick={()=>handleExamineModalClose()} className='ml10' type="primary">返回修改</Button>
          ]
      }
    >
      {/* title */}
      <div className='mb10 fwb'>{`${reviewCurrent}/${reviewItems?.length}`}<span className='ml10'>{reviewItems[reviewCurrent-1]?.statement}</span></div>
      {/* content */}
      <div style={{maxHeight: '400px', overflowY: 'auto'}}>
        {/* error */}
        {
          !!reviewItems[reviewCurrent-1]?.error?.length &&
          renderMessageInfo(reviewItems[reviewCurrent-1]?.error, 'error', '#FF3232')
        }
        {/* warn */}
        {
        !!reviewItems[reviewCurrent-1]?.warn?.length &&
        renderMessageInfo(reviewItems[reviewCurrent-1]?.warn, 'warn', '#F3833D')
        }
        {/* notice */}
        {
        !!reviewItems[reviewCurrent-1]?.notice?.length &&
        renderMessageInfo(reviewItems[reviewCurrent-1]?.notice, 'notice', '#3262ff80')
        }
      </div>
      <Pagination 
        size='small'
        total={reviewItems?.length} 
        pageSize={1} 
        current={reviewCurrent} 
        onChange={handlePageChange}
        style={{textAlign: 'center', marginTop: '20px'}}
      />
    </Modal>
  )
}

export default SqlExecuteAuditeModal