/**
 * 因为 antd 版本的问题，无法直接使用 @ant-design/pro-form
 * 这里实现一个简单的 modal form，并做与流程业务耦合的一层封装
 */

import React, { PropsWithChildren, useEffect } from 'react'
import { useRequest } from 'src/hook'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'src/hook'
import { Form, message } from 'antd'
import { FlowType } from 'src/types'
import { closeFlowForm } from '../flowFormsSlice'
import { flowTypeLabelMap } from '../constants'
import { FormProps } from 'antd/lib/form'
import { FormLayout } from 'src/constants'
import { UIModal } from 'src/components'

interface FlowModalFormProps<T> extends FormProps<T> {
  type: FlowType
  request?: (values: T) => Promise<void>
}

export function FlowModalForm<FormValues>(
  props: PropsWithChildren<FlowModalFormProps<FormValues>>,
) {
  const { type, form: userForm, request, ...rest } = props
  // submit request
  const { loading, run } = useRequest(request || Promise.resolve(), {
    manual: true,
    onSuccess: () => {
      message.success('提交成功')
    },
  })

  const dispatch = useDispatch()

  const [builtInForm] = Form.useForm<FormValues>()
  const form = userForm || builtInForm

  const { visibleFlows, trackingContext } = useSelector(
    (state) => state.flowForms,
  )
  const visible = visibleFlows.includes(type)

  useEffect(() => {
    if (!trackingContext) return
    // 触发回填表单
    const { type: trackingType, fields } = trackingContext
    if (trackingType === type && fields) {
      // 表单类型一致且回填信息存在，回填表单
      // ! setFieldsValue 参数类型为 antd 自定义的 RecursivePartial<T>
      form.setFieldsValue(fields as any)
    }
  }, [form, trackingContext, type])

  return createPortal(
    <div>
      <Form
        form={form}
        preserve={false}
        onFinish={(values) => {
          run(values).then(() => {
            dispatch(closeFlowForm(type))
          })
        }}
        {...FormLayout}
        wrapperCol={{ span: 12 }}
        {...rest}
      >
        <UIModal
          title={flowTypeLabelMap[type]}
          visible={visible}
          onCancel={() => dispatch(closeFlowForm(type))}
          getContainer={false}
          okText="提交"
          okButtonProps={{ htmlType: 'submit' }}
          confirmLoading={loading}
        >
          {props.children}
        </UIModal>
      </Form>
    </div>,
    document.body,
  )
}
