import React, { useEffect, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { Checkbox, Form, Select } from 'antd'
import {
  FormItemApplicant,
  FormItemReason,
  FormItemTimeLimit,
} from '../flowFormItems'
import { FlowModalForm } from './FlowModalForm'
import { FormFieldsMenuAccess } from 'src/types'
import { getFlowApproveUser, launchFlowApply } from 'src/api'
import { menuOptions } from '../constants'
import styles from '../flowForm.module.scss'

export const ModalFormMenuAccess = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const visible = useSelector((state) => state.flowForms.visibleFlows).includes(
    'menuAccess',
  )

  const {
    data: approveUsers,
    loading: loadingApproveUsers,
    reset: resetApproveUsers,
    run: fetchApproveUsers,
  } = useRequest(getFlowApproveUser, {
    manual: true,
    formatResult: (data) => {
      const { deptUser, systemUsers } = data
      // 一级审批人
      const firstApproveOptions = deptUser
        ? [
            {
              label: `${deptUser.userName}(${deptUser.userId})`,
              value: deptUser.userId,
            },
          ]
        : []
      //  二级审批人
      const secondApproveOptions = systemUsers.map(({ userId, userName }) => ({
        label: `${userName}(${userId})`,
        value: userId,
      }))
      return {
        firstApproveOptions,
        secondApproveOptions,
      }
    },
  })
  useEffect(() => {
    if (applyUserId && visible) {
      fetchApproveUsers({ userId: applyUserId })
      return () => {
        resetApproveUsers()
      }
    }
  }, [applyUserId, fetchApproveUsers, resetApproveUsers, visible])

  // 部门负责人（一级审批人）是否已设置 的校验
  const [deptUserValidator, setDeptUserValidator] = useState<{
    validateStatus?: '' | 'success' | 'warning' | 'error' | 'validating'
    help?: string
  }>()
  useEffect(() => {
    if (!approveUsers) return
    const { firstApproveOptions } = approveUsers
    if (firstApproveOptions.length === 0) {
      // 部门未设置负责人
      setDeptUserValidator({
        validateStatus: 'error',
        help: '您所属部门尚未设置负责人，请联系系统管理员',
      })
    } else {
      setDeptUserValidator({
        validateStatus: '',
      })
    }
  }, [approveUsers])

  const flowType = 'menuAccess'

  return (
    <FlowModalForm<FormFieldsMenuAccess>
      type={flowType}
      name={`flow-${flowType}`}
      request={async (values) => {
        if (!applyUserId) return

        const {
          applyReason,
          menus,
          firstApproveUserId,
          secondApproveUserId,
          timeLimit,
        } = values

        const expiredTimeLimit = timeLimit.preset || timeLimit.custom
        return launchFlowApply({
          applyUserId,
          email,
          applyReason,
          expiredTimeLimit,
          flowType,
          objName: menus,
          firstApproveUserId,
          secondApproveUserId,
        })
      }}
    >
      <FormItemApplicant />
      <Form.Item
        label="菜单"
        name="menus"
        required
        rules={[
          {
            validator: (_, value?: string[]) => {
              if (value?.length) return Promise.resolve()
              return Promise.reject('请选择需要提权的功能')
            },
          },
        ]}
      >
        <Checkbox.Group
          className={styles.checkboxGroup}
          options={menuOptions}
        />
      </Form.Item>
      <Form.Item
        label="一级审批人"
        name="firstApproveUserId"
        {...deptUserValidator}
        rules={[{ required: true, message: '请选择一级审批人' }]}
      >
        <Select
          loading={loadingApproveUsers}
          options={approveUsers?.firstApproveOptions}
          placeholder="请选择审批人"
        />
      </Form.Item>
      <Form.Item
        label="二级审批人"
        name="secondApproveUserId"
        rules={[{ required: true, message: '请选择二级审批人' }]}
      >
        <Select
          loading={loadingApproveUsers}
          options={approveUsers?.secondApproveOptions}
          placeholder="请选择审批人"
        />
      </Form.Item>
      <FormItemReason />
      <FormItemTimeLimit />
    </FlowModalForm>
  )
})
