import React from 'react'
import { Popconfirm, Button } from 'antd'
import errorImage from 'src/assets/img/errorBoundary.svg'
import './index.css'

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <div className="error-container">
          <div className="error-content">
            <img src={errorImage} alt="error image" height={128} />
            <div className="error-text">
              页面渲染错误，建议刷新或者清理缓存数据
            </div>
            <div>
              <Button
                className="linkbutton"
                type="primary"
                onClick={() => location.reload()}
              >
                刷新
              </Button>
              <Popconfirm
                className="linkbutton"
                title="确认重置吗？重置会清除所有当前用户数据"
                onConfirm={() => {
                  localStorage.clear()
                  location.reload()
                }}
              >
                <Button>清理缓存数据</Button>
              </Popconfirm>
            </div>
          </div>
        </div>
      )
    }

    return <>{this.props.children}</>;
  }
}
