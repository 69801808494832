import React, { useEffect } from 'react'
import { Checkbox, Form, Input, Switch } from 'antd'
import { DatePicker } from 'src/components'
import { FormInstance } from 'antd/lib/form'
import dayjs, { Dayjs } from 'dayjs'
import { RoleInfoEntity } from 'src/api'
import { LabeledValue } from 'antd/lib/select'
import { looseNameValidator } from 'src/util/nameValidator'
import { FormLayout } from 'src/constants'

const { RangePicker } = DatePicker

const durationOptions = [
  { label: '周日', value: '1' },
  { label: '周一', value: '2' },
  { label: '周二', value: '3' },
  { label: '周三', value: '4' },
  { label: '周四', value: '5' },
  { label: '周五', value: '6' },
  { label: '周六', value: '7' },
]

export interface RoleInfoFormValues {
  id: number
  name: string
  description?: string
  timeLimit?: boolean
  duration?: Dayjs[] | null
  period?: string[]
}

export const EditRoleForm: React.FC<{
  form?: FormInstance
  mode: 'add' | 'edit'
  record?: RoleInfoEntity
  roleOptions?: LabeledValue[]
}> = ({ form, mode, record, roleOptions }) => {
  const isEditing = mode === 'edit'

  useEffect(() => {
    if (record && isEditing) {
      const { name, description, beginDate, endDate, dayMask } = record
      const timeLimit = Boolean(beginDate || endDate || dayMask)
      const period = dayMask?.split('')
      const duration = [
        beginDate && dayjs(beginDate),
        endDate && dayjs(endDate),
      ]
      form?.setFieldsValue({
        name,
        description,
        timeLimit,
        period,
        duration,
      })
    }
  }, [form, isEditing, record])

  return (
    <Form name="roleInfoForm" form={form} {...FormLayout}>
      <Form.Item
        name="name"
        label="角色名称"
        {...(!isEditing && {
          rules: [
            { required: true, message: '请输入角色名称' },
            { validator: looseNameValidator },
          ],
        })}
      >
        <Input readOnly={isEditing} bordered={!isEditing} />
      </Form.Item>
      <Form.Item name="description" label="角色描述">
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 2 }} maxLength={200} />
      </Form.Item>
      <Form.Item name="timeLimit" label="角色时效" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) => {
          return prev.timeLimit !== next.timeLimit
        }}
      >
        {({ getFieldValue }) => {
          const timeLimit = getFieldValue('timeLimit')
          return timeLimit ? (
            <>
              <Form.Item name="duration" label="时间段">
                <RangePicker
                  disabledDate={(currentDate) =>
                    currentDate && currentDate < dayjs().startOf('day')
                  }
                />
              </Form.Item>
              <Form.Item name="period" label="周期">
                <Checkbox.Group options={durationOptions} />
              </Form.Item>
            </>
          ) : null
        }}
      </Form.Item>
    </Form>
  )
}
