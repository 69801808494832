import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Select,
  Tooltip,
  Tree,
} from 'antd'
import { EditorContext, Iconfont, UIModal } from 'src/components'
import styles from './index.module.scss'
import { getUserFiles, saveDocument, UserFile } from 'src/api'
import { FileDataNode, updateTreeData } from './ServerFilesModal'
import { EventDataNode } from 'antd/lib/tree'
import { Encoding, ENCODINGS, FormLayout } from 'src/constants'
import { useDispatch, useSelector } from 'src/hook'
import { setMonacoServerFile, updateMonacoTabName } from '../../queryTabsSlice'
import { fileNameValidator } from 'src/util'
import classNames from 'classnames'
import { CaretDownOutlined } from '@ant-design/icons'

const RootFile: UserFile = {
  path: '/',
  name: '个人文件夹',
  type: 'directory',
  isDir: true,
}

const formatTreeData = (userFiles: UserFile[]): FileDataNode[] => {
  return (
    userFiles
      .filter((userFile) => userFile.path !== '/Export')
      // todo: remove hack
      .map((userFile) => {
        const { path, name, type } = userFile
        return {
          title: name,
          key: path,
          userFile,
          isLeaf: type !== 'directory',
          disabled: type !== 'directory',
        }
      })
  )
}

export const SaveDocument = ({
  encoding,
}: PropsWithChildren<{ encoding?: Encoding }>) => {
  const dispatch = useDispatch()
  const [saveDocForm] = Form.useForm()
  const [visibleSaveDoc, setVisibleSaveDoc] = useState(false)
  const { editorInstance } = useContext(EditorContext)

  const { paneInfoMap, activeTabKey } = useSelector((state) => state.queryTabs)
  const { serverFile } = paneInfoMap[activeTabKey] || {}

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['/'])
  const [selectedNodes, setSelectedNodes] = useState<FileDataNode[]>(
    formatTreeData([RootFile]),
  )

  const [treeData, setTreeData] = useState<any[]>(formatTreeData([RootFile]))

  const onLoadData = ({ key, children }: EventDataNode): Promise<void> => {
    return new Promise((resolve) => {
      if (children) {
        resolve()
        return
      }
      getUserFiles({path: String(key), condition: ''}).then((userFiles) => {
        setTreeData((origin) =>
          updateTreeData(origin, key, formatTreeData(userFiles)),
        )
        resolve()
      })
    })
  }

  useEffect(() => {
    if (!visibleSaveDoc) return
    getUserFiles({path:'/', condition: ''}).then((userFiles) => {
      setExpandedKeys(['/'])
      setTreeData((origin) =>
        updateTreeData(origin, '/', formatTreeData(userFiles)),
      )
    })
  }, [visibleSaveDoc])

  // 重置表单 encoding
  useEffect(() => {
    if (!visibleSaveDoc) return
    saveDocForm.setFields([{ name: 'encode', value: encoding || 'UTF-8' }])
  }, [visibleSaveDoc, encoding, saveDocForm])

  const focusRef = useRef<Input>(null)
  useEffect(() => {
    if (!visibleSaveDoc) return
    focusRef.current?.select()
  }, [visibleSaveDoc])

  const [saveMode, setSaveMode] = useState<'SAVE' | 'SAVE_AS'>('SAVE')

  const handleSave = () => {
    const selectedNode = selectedNodes[0]
    saveDocForm.validateFields().then((values) => {
      const name = values.fileName
      const path = (String(selectedNode.key) + '/' + name).replace('//', '/')
      const content = String(editorInstance?.getValue())
      saveDocument(content, path, values.encode).then(() => {
        dispatch(
          setMonacoServerFile({ isDir: false, name, path, type: 'file' }),
        )
        dispatch(updateMonacoTabName(name))
        setVisibleSaveDoc(false)
        message.success('保存成功')
      })
    })
  }

  return (
    <span
      className={classNames(
        styles.saveDocument,
        styles.monacoToolbarIcon,
        styles.multiIcon,
      )}
    >
      <Tooltip title="保存文档" placement="bottomLeft" arrowPointAtCenter>
        <Iconfont
          className={styles.multiIconMain}
          type="icon-file-save"
          onClick={() => {
            setSaveMode('SAVE')
            if (!serverFile) {
              setVisibleSaveDoc(true)
              return
            }
            saveDocument(
              String(editorInstance?.getValue()),
              String(serverFile.path),
              encoding,
            ).then(() => {
              message.success('保存成功')
            })
          }}
        />
      </Tooltip>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item>
              <div
                onClick={() => {
                  setSaveMode('SAVE_AS')
                  setVisibleSaveDoc(true)
                }}
              >
                另存为...
              </div>
            </Menu.Item>
          </Menu>
        }
        placement="bottomCenter"
        trigger={['hover']}
      >
        <CaretDownOutlined className={styles.multiIconSub} />
      </Dropdown>
      <UIModal
        title={saveMode === 'SAVE' ? '保存文档' : '文档另存为'}
        visible={visibleSaveDoc}
        onOk={handleSave}
        okText="保存"
        onCancel={() => setVisibleSaveDoc(false)}
        afterClose={() => {
          saveDocForm.resetFields()
          setExpandedKeys([])
          setTreeData(formatTreeData([RootFile]))
          setSelectedNodes(formatTreeData([RootFile]))
        }}
        width={520}
        bodyStyle={{ padding: '16px 24px 0' }}
      >
        <div style={{ height: '25vh', overflow: 'auto' }}>
          <Tree.DirectoryTree
            className={styles.overrideTreeNode}
            loadData={onLoadData}
            treeData={treeData}
            expandedKeys={expandedKeys}
            onExpand={(expandedKeys) => setExpandedKeys(expandedKeys)}
            expandAction="doubleClick"
            onSelect={(_selectedKeys, { selectedNodes }) =>
              setSelectedNodes(selectedNodes as FileDataNode[])
            }
            selectedKeys={selectedNodes.map((selectedNode) => selectedNode.key)}
            titleRender={(node) => {
              return (
                <div
                  style={{ display: 'inline', width: '100%' }}
                  onClick={() => {
                    const { userFile } = node as FileDataNode
                    const { type, name } = userFile
                    if (type === 'directory') return
                    saveDocForm.setFields([{ name: 'fileName', value: name }])
                  }}
                >
                  {node.title}
                </div>
              )
            }}
          ></Tree.DirectoryTree>
          {(!treeData || !treeData[0]) && <div>暂无数据</div>}
        </div>
        <div style={{ margin: 24 }}></div>
        <Form
          form={saveDocForm}
          {...FormLayout}
          size="small"
          initialValues={{ fileName: '未命名文档.sql', encode: encoding }}
        >
          <Form.Item
            label="存储为"
            name="fileName"
            rules={[
              { required: true, message: '名称不能为空' },
              { validator: fileNameValidator },
              { max: 25, message: '名称最长 25 个字符' },
            ]}
          >
            <Input ref={focusRef}></Input>
          </Form.Item>
          <Form.Item
            label="编码"
            name="encode"
            rules={[{ required: true, message: '编码不能为空' }]}
          >
            <Select
              showSearch
              options={ENCODINGS.map((encoding) => ({
                label: encoding,
                value: encoding,
              }))}
            ></Select>
          </Form.Item>
        </Form>
      </UIModal>
    </span>
  )
}
