import React, { FC } from 'react'
import { Steps } from 'antd';
import classNames from 'classnames';
import { StepProps, StepsProps } from 'antd/lib/steps'
import styles from './index.module.scss'

interface ItemProps extends StepProps{
  iconText?: string | React.ReactNode
  badge?: any
  status?: any;
  type?: string;
  hidden?: boolean;
}

interface ProcessProps extends StepsProps {
  items: ItemProps[];
  className?: string;
}

export const Process: FC<ProcessProps> = ({
  items,
  className,
  labelPlacement,
  ...rest
}) => {
  
  return (
    <Steps
      {...rest}
      size='default'
      labelPlacement={labelPlacement || "vertical"}
      className={`${styles.wrap} ${className}`}
    >
      {items.filter(({ hidden= false}) => !hidden)
      .map(({ iconText, status, badge,...rest }, idx) => (
        <Steps.Step
          key={idx}
            icon={ iconText &&
              <div className={classNames(styles.iconWrapper, {
               [styles.success]: status === 0,
               [styles.error]: status === 1
              }) }>
                <span className={styles.icon}>{iconText}</span>
                {badge}
              </div>
            }
          {...rest}
        />
      ))}
    </Steps>
  )
}

export default Process
