import React from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'src/hook';
import { CasCard } from './CasCard';
import { OauthCard } from './OauthCard';
import { OpenLdapCard } from './OpenLdapCard';
import { AdCard } from './AdCard';

export const SingleSignOnCard = () => {
  
  const { userInfo } = useSelector((state) => state.login);

  return (
    <section className="cq-new-card flow-card" id="SingleSignOnCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">单点登录配置</h3>
        {/* {extraOperations} */}
      </div>
      <section className="card__content" style={{ padding: '0 16px' }}>
        <Tabs>
          <Tabs.TabPane key="openLdap" tab="OpenLdap配置">
            <OpenLdapCard />
          </Tabs.TabPane>
          <Tabs.TabPane key="ad" tab="AD域配置">
            <AdCard />
          </Tabs.TabPane>
          {userInfo?.version !== 'community' && (
            <>
              <Tabs.TabPane key="cas" tab="CAS配置">
                <CasCard />
              </Tabs.TabPane>
              <Tabs.TabPane key="oauth" tab="Oauth2.0配置">
                <OauthCard />
              </Tabs.TabPane>
            </>
          )}
        </Tabs>
      </section>
    </section>
  );
};
