import React, { useRef, useImperativeHandle, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Drawer, Form, Radio, Space, Button, message, Alert, Tabs, Spin } from 'antd';
import { useModal, useSelector, useRequest } from 'src/hook';
import {
  getBasicSetting,
  getHighRiskPermission,
  IHighRiskPermissionRes,
  saveHighRiskPermission,
} from 'src/api';
import { SecurityFormLayout } from './constants';
import HighRiskOperation from './HighRiskOperation';
import BasicTimeSetting from './BasicTimeSetting';
import styles from './index.module.scss';
import { Iconfont } from 'src/components';
import classnames from 'classnames';
import BasicSetting from './BasicSetting';
import HighRiskOperationSetting from './HighRiskOperationSetting';

interface ISecuritySettingWrapperProps {
  showObject?: boolean;
  nodePathWithType?: string;
  nodePathWithTypeList?: string[];
}

const HIGHT_Risk_TYPES: { [k: string]: string } = {
  1: '只提示不拦截',
  2: '拦截提示走数据订正',
  3: '拦截提示申请高危操作',
};

const SecuritySettingWrapper = (
  { showObject, nodePathWithTypeList = [], nodePathWithType }: ISecuritySettingWrapperProps,
  ref: any
) => {
  const [form] = Form.useForm();
  const timeRef = useRef(null);
  const highRiskOperationSettingRef = useRef<any>()
  const [editPermissionList, setEditPermissionList] = useState<boolean>(false)

  const { visible, closeModal } = useModal('SecuritySetting');
  const [activeKey, setActiveKey] = useState<string>("basicSetting");
  const [highRiskOperationsVoList, setHighRiskOperationsVoList] = 
    useState<{
      objectType: string, 
      operations: {
        operation: string, // 操作
        value: string | number, // 影响行数
        level: string | number, // 高危提示
        highRiskLevel: string | number // 高危等级
      }[]}[]>([])  // 存储编辑保存的值

  const { selectedTreeNode } = useSelector(
    (state) => state.authorizationManagement
  );

  // 保存用于保存设置 的当前选中的节点的信息参数
  const [saveParams, setSaveParams] = useState<{
    dataSourceType: string | null,
    groupId: string | null,
    nodePathWithTypeList: string | null,
  }>({
    dataSourceType: null,
    groupId: null,
    nodePathWithTypeList: null,
  })

  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: any}>()

  useEffect(() => {
    const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    setPermissionlist(permissionList?.DATABASE_MANAGEMENT?.[pathName])
  }, [])

  useEffect(() => {
    let params: any = {
      dataSourceType: selectedTreeNode?.connection?.connectionType,
      groupId: null,
      nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType],
    };
    if (selectedTreeNode?.nodeType === 'datasource') {
      params.dataSourceType = selectedTreeNode?.nodeName;
      params.nodePathWithTypeList = null;
    } else if (selectedTreeNode?.nodeType === 'group') {
      params = {
        ...params,
        groupId: selectedTreeNode?.id,
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        nodePathWithTypeList: null,
      };
    }
    setSaveParams(params)
  }, [
    selectedTreeNode?.connection?.connectionType,
    selectedTreeNode?.id,
    selectedTreeNode?.nodeName,
    selectedTreeNode?.nodePathWithType,
    selectedTreeNode?.nodeType
  ])

  //查询 高危操作设置
  const {
    data: permissionTemplate,
    loading,
    run: runGetHighRiskPermission,
  } = useRequest<IHighRiskPermissionRes>(getHighRiskPermission, {
    manual: true,
    onSuccess: (data: any) => {
    },
  });
  //查询 基础设置
  const {
    data: basicTemplate,
    loading: basicLoading,
    run: runGetBasicSetting,
  } = useRequest(getBasicSetting, {
    manual: true,
    onSuccess: (data: any) => {
      form.setFieldsValue({
        count: data.count || 0,
        settingType: data.settingType,
      });
    },
  });

  const { run: runSaveHighRiskPermission } = useRequest(
    saveHighRiskPermission,
    {
      manual: true,
      onSuccess: () => {
        message.success('提交成功');
        closeModal();
      },
    }
  );

  useEffect(() => {
    if (_.isEmpty(selectedTreeNode) || (!visible && !showObject)) return;
    if (selectedTreeNode?.nodeType === 'datasource') {
      runGetBasicSetting({
        dataSourceType: selectedTreeNode?.nodeName,
        groupId: null,
        nodePathWithTypeList: null,
      });
      runGetHighRiskPermission({
        dataSourceType: selectedTreeNode?.nodeName,
        groupId: null,
        nodePathWithTypeList: null,
      });
    } else if (selectedTreeNode?.nodeType === 'group') {
      runGetBasicSetting({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: selectedTreeNode?.id,
        nodePathWithTypeList: null,
      });
      runGetHighRiskPermission({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: selectedTreeNode?.id,
        nodePathWithTypeList: null,
      });
    } else {
      runGetBasicSetting({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: null,
        nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType],
      });
      runGetHighRiskPermission({
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: null,
        nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType],
      });
    }
    setEditPermissionList(false)
  }, [selectedTreeNode?.key, visible, showObject]);

  useEffect(() => {
    if (_.isEmpty(selectedTreeNode) || (!visible && !showObject)) return;
    if (editPermissionList) {
      if (selectedTreeNode?.nodeType === 'datasource') {
        runGetHighRiskPermission({
          dataSourceType: selectedTreeNode?.nodeName,
          groupId: null,
          nodePathWithTypeList: null,
        });
      } else if (selectedTreeNode?.nodeType === 'group') {
        runGetHighRiskPermission({
          dataSourceType: selectedTreeNode?.connection?.connectionType,
          groupId: selectedTreeNode?.id,
          nodePathWithTypeList: null,
        });
      } else {
        runGetHighRiskPermission({
          dataSourceType: selectedTreeNode?.connection?.connectionType,
          groupId: null,
          nodePathWithTypeList: showObject
            ? nodePathWithTypeList
            : [selectedTreeNode?.nodePathWithType],
        });
      }
    }
    setEditPermissionList(false)
  }, [editPermissionList]);

  const onSubmit = async (callback?: () => void) => {
    form.validateFields().then((values) => {
      let params: any = {
        dataSourceType: selectedTreeNode?.connection?.connectionType,
        groupId: null,
        nodePathWithTypeList: showObject
          ? nodePathWithTypeList
          : [selectedTreeNode?.nodePathWithType],
      };
      if (selectedTreeNode?.nodeType === 'datasource') {
        params.dataSourceType = selectedTreeNode?.nodeName;
        params.nodePathWithTypeList = null;
      } else if (selectedTreeNode?.nodeType === 'group') {
        params = {
          ...params,
          groupId: selectedTreeNode?.id,
          dataSourceType: selectedTreeNode?.connection?.connectionType,
          nodePathWithTypeList: null,
        };
      }

      const timeCallback = (status?: string) => {
        if (status === 'success') {
          if (_.isEmpty(permissionTemplate?.operationsVoList)) {
            Promise.resolve( callback?.())
            return
          }
          runSaveHighRiskPermission({
            ...params,
            ...values,
            level: Number(values?.level),
          }).then(() => callback?.());
        }
      };
      if (!['datasource', 'group'].includes(selectedTreeNode?.nodeType)) {
        //@ts-ignore
        timeRef?.current?.handleOk(timeCallback);
      } else  {
        if (_.isEmpty(permissionTemplate?.operationsVoList)) return
        //只有高危操作
        runSaveHighRiskPermission({
          ...params,
          ...values,
          level: Number(values?.level),
        }).then(() => callback?.());
      }
    });
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  const onClear = () => {
    closeModal();
    form.resetFields();
    setActiveKey("basicSetting");
    highRiskOperationSettingRef?.current?.updateFilterValidate()
  };

  // 基础设置
  const renderBasicSetting = (
    <Spin spinning={basicLoading}>
      <div style={{ height: 'calc(100vh - 200px)', overflowY: 'auto' }}>
        {/* 通用 */}
        {!['datasource', 'group'].includes(selectedTreeNode?.nodeType) &&
          (visible || showObject) && (
            <BasicTimeSetting
              // ref={timeRef}
              // form={form}
              connectionId={
                selectedTreeNode?.nodeType === 'connection'
                  ? selectedTreeNode?.id
                  : selectedTreeNode?.sdt?.connectionId
              }
              nodeType={selectedTreeNode?.nodeType}
              nodePathWithType={showObject ? nodePathWithType:  selectedTreeNode?.nodePathWithType}
              nodePathWithTypeList={
                showObject
                  ? nodePathWithTypeList
                  : [selectedTreeNode?.nodePathWithType]
              }
              saveParams={saveParams}
              permissionlist={permissionlist}
            />
          )}
          <BasicSetting
            form={form}
            options={basicTemplate?.selectValues}
            saveParams={saveParams}
            permissionlist={permissionlist}
          />
      </div>
    </Spin>
  );

  // 高危操作设置
  const renderHighRiskOperationSetting = (
    <HighRiskOperationSetting
      ref={highRiskOperationSettingRef}
      loading={loading}
      permissionTemplate={permissionTemplate?.operationsVoList || []}
      permissionlist={permissionlist}
      setEditPermissionList={setEditPermissionList}
    />
  );

  const renderTabs = (
    <Tabs
      size="small"
      className={styles.card}
      type="card"
      tabPosition="top"
      animated
      activeKey={activeKey ? activeKey : "basicSetting"}
      onChange={(key) => setActiveKey(key)}
    >
      <Tabs.TabPane
        tab="基础设置"
        key="basicSetting"
      >
        {renderBasicSetting}
      </Tabs.TabPane>
      <Tabs.TabPane
        tab="高危操作设置"
        key="highRiskOperationSetting"
      >
        {renderHighRiskOperationSetting}
      </Tabs.TabPane>
    </Tabs>
  )

  if (showObject) {
    return renderTabs;
  }
  
  // const renderHighRiskOperationSetting = (
  //   <>
  //   <Alert
  //     type="warning"
  //     className={styles.hightRiskAlert}
  //     message="此设置将会批量设置该层级包含的所有数据库对象，请确认批量设置后操作"
  //   />
  //   {constant}
  //   </>
  // );


  return (
    <Drawer
      title={
        <div>
          <span>安全设置</span>
          <Iconfont
            className={classnames(styles.ml25, styles.mr5)}
            type=
            {`${
              selectedTreeNode?.nodeType === "datasource"
                ? `icon-connection-${selectedTreeNode?.nodeName}`
                : selectedTreeNode?.nodeType === "group" 
                ? "icon-shujukuwenjianjia"
                : selectedTreeNode?.nodeType === "connection"
                ? `icon-${selectedTreeNode?.connection.connectionType}`
                : `icon-${selectedTreeNode?.nodeType}`
            } `}
          />
          <span className={styles.fontColor}>{selectedTreeNode?.nodeName}</span>
        </div>
      }
      key={selectedTreeNode?.id}
      width={1100}
      visible={visible}
      maskClosable={false}
      onClose={() => onClear()}
      className={styles.securitySetting}
      // footer={
      //   <Space>
      //     <Button type="primary" onClick={() => onSubmit()}>
      //       确定
      //     </Button>
      //     <Button onClick={() => onClear()}>取消</Button>
      //   </Space>
      // }
      footer={false}
    >
      {renderTabs}
    </Drawer>
  );
};

export default React.forwardRef(SecuritySettingWrapper);
