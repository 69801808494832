/**
 * 关联用户
 */
import React, { useEffect, useState } from "react"
import { Modal, Form, Table, Row, message, Button, Spin, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { TableRowSelection } from 'antd/es/table/interface'
import { queryPermissionUser, deletePermissionUser } from 'src/api'
import { useRequest } from 'src/hook'
import { renderTableFields } from 'src/util'
import classnames from 'classnames'
import styles from './index.module.scss'
import PermissionTooltip from "src/components/PermissionTooltip/PermissionTooltip"

interface DataType {
  userName: string
  userId: string
  objects: any,
  [p:string]: any
}

interface IProps {
  visible: boolean
  setRelationUserVisible: (v: boolean) => void
  [p: string]: any
}

const RelationUser = (props: IProps) => {
  const { perssionInfo, visible, permissionlist, setRelationUserVisible } = props
  const { isOnlyRead, roleNameList } = permissionlist || {}
  const [form] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>()
  const [loading, setLoading]=useState(false)

  // 查询关联用户列表
  const { data: relationList=[], run, refresh } = useRequest(queryPermissionUser, {manual: true})
  
  useEffect(() => {
    if (perssionInfo?.id) {
      run(perssionInfo.id)
    }
  }, [run, perssionInfo])

  const rowSelection: TableRowSelection<DataType> = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  // 删除用户
  const handleDeleteUser = (userId?: string) => {
    const params: any = {
      id: perssionInfo?.id,
      userIds: userId ? [userId] : selectedRowKeys,
    }
    Modal.confirm({
      title: '确认删除吗?',
      okText: '确定',
      cancelText:'取消',
      onOk: () => { 
        setLoading(true)
        deletePermissionUser(params)
        .then(() => {
          message.success('删除成功')
          setSelectedRowKeys([])
          refresh()
        })
        .catch((err: any) => {
          console.error('删除失败', err)
        }).finally(()=>{
          setLoading(false)
        })
      }
    })
  } 

  const columns: ColumnsType<DataType> = [
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
      width: 160,
      render:(txt: string)=><span>{renderTableFields(txt)}</span>
    },
    {
      title: '关联资源',
      dataIndex: 'objects',
      key: 'objects',
      width: 300,
      render: (_: string, record: any) => (
        <div>
          {record.objects?.map((item: any, index: string) => {
            return (
              <Row key={index} className={styles.mb2}>
                {item}
              </Row>
            )
          })}
        </div>
      ),
    },
    {
      title: '操作',
      dataIndex: 'option',
      key: 'option',
      width: 100,
      render: (_: string, record: any) =>
      <PermissionTooltip
        title="手动授权"
        permissionlist={permissionlist}
      >
        <Button
          type="link"
          className={classnames(styles.options)}
          onClick={() => { handleDeleteUser(record?.userId) }}
          disabled={isOnlyRead}
          style={{padding: 0}}
        >
          删除
        </Button>
      </PermissionTooltip>
    },
  ]
  return (
    <Modal
      width={1000}
      title="关联用户"
      okText="关闭"
      visible={visible}
      onOk={() => setRelationUserVisible(false)}
      onCancel={() => setRelationUserVisible(false)}
    >
      <Spin spinning={loading}>
        <Form layout="inline" form={form}>
          <Form.Item label="权限名">{perssionInfo?.name}</Form.Item>
          <Form.Item label="备注">{perssionInfo?.description}</Form.Item>
          <Form.Item label="数据源类型">{perssionInfo?.dataSourceType}</Form.Item>
        </Form>
        <PermissionTooltip
          title="手动授权"
          permissionlist={permissionlist}
        >
          <Button
            type="primary"
            disabled={!selectedRowKeys?.length || isOnlyRead}
            onClick={() => handleDeleteUser()}
            className={classnames(styles.mb10, styles.ml10)}
          >
            删除
          </Button>
        </PermissionTooltip>
        <Table
          rowSelection={rowSelection}
          rowKey={"userId"}
          columns={columns}
          dataSource={relationList}
          pagination={false}
          scroll={{
            y: "50vh",
          }}
        />
      </Spin>
    </Modal>
  );
}
export default RelationUser