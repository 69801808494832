import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  ReactNode,
} from 'react'
import { ICellRendererParams } from '@ag-grid-community/core'
import styles from './ColumnSelectEditor.module.scss'
import { Select } from 'antd'

interface IColumnSelectEditorProps extends ICellRendererParams {
  async?: boolean
  columnsOptions: any 
}

export const ColumnMultiSelectEditor = forwardRef(
  (props: IColumnSelectEditorProps, ref) => {

    const [isLoading, setIsLoading] = useState(false)
    const [newOptions, setNewOptions] = useState<string[]>([]) 
    const [value, setValue] = useState<string[]>([])

    const { data, colDef, async, columnsOptions = [] } = props

    const setSelectOptions = () => {
      setIsLoading(true)
      let defaultSelectOptions = columnsOptions;
      if (async && typeof columnsOptions === 'function') {
      
        columnsOptions({data,colDef})?.then((res: any) => {
          defaultSelectOptions = res
          setIsLoading(false)
          setNewOptions(defaultSelectOptions)
        })
      }else {
        setIsLoading(false)
        setNewOptions(defaultSelectOptions)
      }
     
    }

    useEffect(() => {
      const originValue = data[colDef?.field || ''] || []
      setValue(originValue)
      setSelectOptions()
    }, [colDef, data])

    const [editing, setEditing] = useState(true)
    const refContainer = useRef(null)

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
        isPopup() {
          return false; //true 失焦不能存储数据; 目前样式改变
        },
        afterGuiAttached: () => {
          getSelection()?.empty()
        },
      }
    })

    useEffect(() => {
      if (!editing) {
        props.api.stopEditing()
      }
    }, [editing, props.api])


    const dropdownRender = (originNode: ReactNode) => {
      return (
        <div className={styles.selecdropdown}>
          {originNode}
        </div>
      )
    }

    const options = newOptions?.map((colName) => ({
      label: colName,
      value: `${colName}`,
    }))

    const onChange = (v: string[]) => {
      setEditing(true)
      setValue(v)
    }

    const onDropdownVisibleChange = (open: boolean) => {
      setEditing(open)
    }

    return (
      <div
        ref={refContainer}
        tabIndex={1} // ag-grd: important - without this the key presses wont be caught
      >
        <Select
          className={'columnselect'}
          value={value}
          showArrow
          options={options}
          loading={isLoading}
          dropdownRender={dropdownRender}
          onChange={onChange}
          size='small'
          mode="multiple"
          style={{ width: '100%'}}
          onDropdownVisibleChange={onDropdownVisibleChange}
          optionLabelProp="value"
          dropdownStyle={{ padding: 0 }}
          dropdownClassName={'selectdropdown'}
        ></Select>
      </div>
    )
  },
)
