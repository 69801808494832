import React, { RefCallback } from 'react'
import { editor } from 'monaco-editor'

type IEditorContext = {
  editorInstance: editor.IStandaloneCodeEditor | null
  containerRef: RefCallback<HTMLDivElement> | null
}

export const EditorContext = React.createContext<IEditorContext>({
  editorInstance: null,
  containerRef: null,
})
