import React, { useCallback, useContext, useState, useEffect } from 'react'
import { Card, Form, Switch, Input, Typography, Radio, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useRequest } from 'src/hook'
import { LinkButton } from 'src/components'
import { putOauthConfig, getOauthConfig, IOauthConfig } from 'src/api'
import { SettingFormContext } from '../SettingFormContext'
import styles from './index.module.scss'
import { isEmpty } from 'lodash'

const { Text } = Typography

export const OauthCard = () => {
  const [editing, setEditing] = useState(false)
  const form = useContext(SettingFormContext) as FormInstance

  const {
    data: oauthConfig,
    loading,
    run: getConfig,
  } = useRequest(getOauthConfig)

  const { run: putConfig } = useRequest<IOauthConfig>(putOauthConfig, {
    manual: true,
    onSuccess: () => {
      getConfig()
      setEditing(false)
    },
  })
  const setFormVals = useCallback(
    (val: any) => {
      form.setFields([
        { name: 'oauth2Switch', value: oauthConfig.oauth2Switch || false },
        { name: 'clientId', value: oauthConfig.clientId },
        { name: 'clientSecret', value: oauthConfig.clientSecret },
        {
          name: 'userAuthorizationUrl',
          value: oauthConfig.userAuthorizationUrl,
        },
        { name: 'accessTokenUrl', value: oauthConfig.accessTokenUrl },
        { name: 'oauth2CQServerUrl', value: oauthConfig.oauth2CQServerUrl },
        { name: 'resourceUserInfoUrl', value: oauthConfig.resourceUserInfoUrl },
        { name: 'resourceUserInfoParma', value: oauthConfig.resourceUserInfoParma },
        {
          name: 'oauth2IsStandard',
          value: oauthConfig.oauth2IsStandard
        }
      ])
    },
    [oauthConfig, form],
  )

  useEffect(() => {
    if (!!oauthConfig) {
      setFormVals(oauthConfig)
    }
  }, [oauthConfig, form, setFormVals])

  const handleSave = () => {
    form
      .validateFields([
        'oauth2Switch',
        'clientId',
        'clientSecret',
        'userAuthorizationUrl',
        'accessTokenUrl',
        'oauth2CQServerUrl',
        'resourceUserInfoUrl',
        'resourceUserInfoParma',
        'oauth2IsStandard'
      ])
      .then((val) => {
        if (isEmpty(val)) return 
        putConfig(val)
      })
  }

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              if (!!oauthConfig) {
                setFormVals(oauthConfig)
              }
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
      )}
    </div>
  )

  const urlValidator = (_: any, value: string) => {
    if (!value) {
      return Promise.resolve()
    }
    const passed = value.startsWith('http://') || value.startsWith('https://')
    if (!passed) {
      return Promise.reject('必须以 http:// 或者 https:// 开头')
    } else {
      return Promise.resolve()
    }
  }

  return (
    <section className="cq-card flow-card" id="OauthCard" style={{border: 'none'}}>
      <div className={styles.singleTitle}>
        {/* <h3 className="cq-card__title">Oauth2.0 配置</h3> */}
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={loading}
        >
          {/* <Form> */}
          <Form.Item label="是否开启">
            <Form.Item
              hidden={!editing}
              name="oauth2Switch"
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>
            {!editing && (
              <Text>
                {!!oauthConfig && oauthConfig.oauth2Switch ? '开启' : '关闭'}
              </Text>
            )}
          </Form.Item>
          <Form.Item
            label={
              <>
                版本类型
                <Tooltip title="标准版：认证中心为官方标准接口，逻辑已在Java中实现，客户版:因客户认证中心有自定义开发，需要使用python实现授权认证流程，需要定制使用Python获取用户信息">
                  <QuestionCircleOutlined style={{ marginLeft: 2 }} />
                </Tooltip>
              </>
            }
          >
            {editing && (
              <Form.Item hidden={!editing} name="oauth2IsStandard" noStyle>
                <Radio.Group
                  defaultValue={true}
                  options={[
                    { label: '标准版', value: true },
                    { label: '客户版', value: false },
                  ]}
                />
              </Form.Item>
            )}
            {!editing && (
              <Text>
                {!!oauthConfig && oauthConfig.oauth2IsStandard
                  ? '标准版'
                  : '客户版'}
              </Text>
            )}
          </Form.Item>
          <Form.Item label="认证中心authorize地址">
            {editing && (
              <Form.Item
                hidden={!editing}
                name="userAuthorizationUrl"
                noStyle
                rules={[{ required: false, validator: urlValidator }]}
              >
                <Input placeholder="http(s)://example:port"></Input>
              </Form.Item>
            )}
            {!editing && (
              <Text>{!!oauthConfig && oauthConfig.userAuthorizationUrl}</Text>
            )}
          </Form.Item>

          <Form.Item label="认证中心token地址">
            {editing && (
              <Form.Item
                hidden={!editing}
                name="accessTokenUrl"
                noStyle
                rules={[{ required: false, validator: urlValidator }]}
              >
                <Input placeholder="http(s)://example:port"></Input>
              </Form.Item>
            )}
            {!editing && (
              <Text>{!!oauthConfig && oauthConfig.accessTokenUrl}</Text>
            )}
          </Form.Item>
          <Form.Item label="服务部署地址">
            {editing && (
              <Form.Item
                hidden={!editing}
                name="oauth2CQServerUrl"
                noStyle
                rules={[{ required: false, validator: urlValidator }]}
              >
                <Input placeholder="http(s)://cloudquery:port/waitOauthLogin"></Input>
              </Form.Item>
            )}
            {!editing && (
              <Text>{!!oauthConfig && oauthConfig.oauth2CQServerUrl}</Text>
            )}
          </Form.Item>

          <Form.Item label="认证中心授权客户端ID">
            {editing && (
              <Form.Item
                hidden={!editing}
                name="clientId"
                noStyle
              >
                <Input></Input>
              </Form.Item>
            )}
            {!editing && <Text>{!!oauthConfig && oauthConfig.clientId}</Text>}
          </Form.Item>
          <Form.Item label="认证中心授权客户端密钥">
            {editing && (
              <Form.Item hidden={!editing} name="clientSecret" noStyle>
                <Input></Input>
              </Form.Item>
            )}
            {!editing && (
              <Text>{!!oauthConfig && oauthConfig.clientSecret}</Text>
            )}
          </Form.Item>
          <Form.Item label="用户信息openAPI地址">
            {editing && (
              <Form.Item
                hidden={!editing}
                name="resourceUserInfoUrl"
                noStyle
                rules={[{ required: false, validator: urlValidator }]}
              >
                <Input placeholder="http(s)://example:port"></Input>
              </Form.Item>
            )}
            {!editing && (
              <Text>{!!oauthConfig && oauthConfig.resourceUserInfoUrl}</Text>
            )}
          </Form.Item>
          <Form.Item label="用户信息openAPI字段名">
            {editing && (
              <Form.Item hidden={!editing} name="resourceUserInfoParma" noStyle>
                <Input />
              </Form.Item>
            )}
            {!editing && (
              <Text>{!!oauthConfig && oauthConfig.resourceUserInfoParma}</Text>
            )}
          </Form.Item>
          {/* </Form> */}
        </Card>
      </section>
    </section>
  )
}
