import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CompanyState {
  companyId: string
  companyName: string
}

const initialState: CompanyState = {
  companyId: '100',
  companyName: '',
}
export const companySlice = createSlice({
  name: 'encryption',
  initialState,
  reducers: {
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload
    },
  },
})

export const companyReducer = companySlice.reducer

export const { setCompanyId, setCompanyName } = companySlice.actions
