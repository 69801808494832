import React from 'react';
import { Tabs } from 'antd';
import { AlarmConfigListCard } from './alarmConfigCard/AlarmConfigListCard';
import styles from './index.module.scss';

export const AlarmConfigCard = () => {

  return (
    <section className="cq-new-card flow-card" id="AlarmConfigCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">告警配置</h3>
      </div>
      <section className="card__content" style={{ padding: '0 16px' }}>
        <Tabs>
          <Tabs.TabPane key="systems" tab="系统告警配置" >
            <AlarmConfigListCard tabNum={0} tabKey={"sysTemAlarmConfig"} />
          </Tabs.TabPane>
          <Tabs.TabPane key="business" tab="业务告警配置" >
            <AlarmConfigListCard tabNum={1} tabKey={"businessAlarmConfig"} />
          </Tabs.TabPane>
        </Tabs>
      </section>
    </section>
  );
};