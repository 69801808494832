// ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { storeRegister } from './storeRegister'

import { persistedReducer } from './reducer'

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
})

export const persistor = persistStore(store)

storeRegister.register(store, persistor)

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type AppDispatch = typeof store.dispatch
