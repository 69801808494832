import React, { useCallback, useEffect } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Form, message, Select } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { getTwoFactor, postTwoFactor } from 'src/api'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'

export const ModalChangeLoginSettings = () => {
  const dispatch = useDispatch()
  const visible = useSelector(
    (state) => state.modal.LoginSettingsModal,
  )
  const [form] = Form.useForm()

  const {data, run: getData} = useRequest(getTwoFactor, {
    manual: true
  })

  const { loading, run } = useRequest(postTwoFactor, {
    manual: true,
    onSuccess: () => {
      message.success('修改成功')
      dispatch(hideModal('LoginSettingsModal'))
    },
  })

  useEffect(() => {
    if (visible) {
      getData()
    }
  }, [visible])

  const setFormVal = useCallback(() => {
    if (data) {
      form.setFields([
        { name: 'twoFactor', value: data},
      ])
    }
  }, [form, data])

  useEffect(() => {
    setFormVal()
  }, [data, setFormVal, form])


  return (
    <UIModal
      title="登录设置"
      visible={visible}
      onOk={() => {
        form.validateFields().then(({twoFactor}) => {
          run(twoFactor)
          // run({ userId, oldP, newP })
        })
      }}
      // todo: 修改密码后端返回成功失败而非成功后 401
      // todo: 重新登录交互
      okText="确定"
      onCancel={() => {
        dispatch(hideModal('LoginSettingsModal'))
        // form.resetFields(['oldP', 'newP', 'confirm'])
      }}
      confirmLoading={loading}
    // width={480}
    >
      <Form form={form} {...FormLayout}>
        <Form.Item
          label="双重因素认证"
          name="twoFactor"
          rules={[{ required: true, message: '请选择认证方式' }]}
        >
          <Select
            defaultValue={data}
            // style={{ width: 120 }}
            allowClear
            options={[
              {
                value: 'NONE',
                label: 'NONE',
              },
              {
                value: 'MESSAGE',
                label: '短信',
              },
              {
                value: 'OTP',
                label: 'OTP',
              }
            ]}
          />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
