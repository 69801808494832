import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
  useReducer,
} from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useRequest } from 'src/hook'
import { Button, Space, Form, Steps, message } from 'antd'
import { EditRoleForm, RoleInfoFormValues } from '../forms/EditRoleForm'
import { SysGrantPermForm } from '../forms/SysGrantPermForm'
import { LazyWrapper } from 'src/components'
import { SysBindUserForm } from '../forms/SysBindUserForm'
import { addRole } from 'src/api'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { RoleRecordContext } from '../contexts/RoleRecordContext'
import styles from './wizard.module.scss'

enum WizardStep {
  addRole = 0,
  grantPerms = 1,
  bindUsers = 2,
}

export const SysAddRoleWizard = ({
  submitterRef,
}: {
  submitterRef: React.RefObject<HTMLElement>
}) => {
  const dispatch = useDispatch()
  const [current, setCurrent] = useState<WizardStep>(WizardStep.addRole)
  const furthestStepRef = useRef<WizardStep>(WizardStep.addRole)

  // !HACK
  const [, forceUpdate] = useReducer((r) => r + 1, 0)
  useEffect(() => {
    forceUpdate()
  }, [])

  useEffect(() => {
    const furthestStep = furthestStepRef.current
    furthestStepRef.current = current > furthestStep ? current : furthestStep
  }, [current])

  const [roleInfoForm] = Form.useForm()
  const [grantPermsForm] = Form.useForm()
  const [bindUsersForm] = Form.useForm()

  const { mode, record, refreshRoles } = useContext(RoleRecordContext)

  const steps = [
    {
      title: '新增角色',
      content: <EditRoleForm form={roleInfoForm} mode={mode} />,
    },
    {
      title: '授予权限',
      content: (
        <SysGrantPermForm form={grantPermsForm} mode={mode} record={record} />
      ),
    },
    {
      title: '绑定用户',
      content: (
        <SysBindUserForm form={bindUsersForm} mode={mode} record={record} />
      ),
    },
  ]

  const { loading: loadingAdd, run: runAddRole } = useRequest(addRole, {
    manual: true,
    onSuccess: () => {
      message.success('添加成功')
      dispatch(hideModal('ModalSysAddRoleWizard'))
      refreshRoles && refreshRoles()
    },
  })

  const handleConfirmWizard = useCallback(async () => {
    try {
      const roleInfoFormValues = await roleInfoForm.validateFields()
      const {id, name, description, duration, period } =
        roleInfoFormValues as RoleInfoFormValues

      const [beginDate, endDate] =
        duration?.map((date) => date.format('YYYYMMDD')) || []
      const dayMask = period?.join('')

      let grantPerms: string[] | undefined
      let grantUser: string[] | undefined
      const furthestStep = furthestStepRef.current
      if (furthestStep > WizardStep.addRole) {
        grantPerms = (await grantPermsForm.validateFields())
          .grantedPerms as string[]
      }
      if (furthestStep > WizardStep.grantPerms) {
        grantUser = (await bindUsersForm.validateFields())
          .boundUsers as string[]
      }

      runAddRole({
        id,
        type: 'SYSTEM_ROLE',
        name,
        description,
        beginDate,
        endDate,
        dayMask,
        grantPerms,
        grantUser,
      })
    } catch {}
  }, [bindUsersForm, grantPermsForm, roleInfoForm, runAddRole])

  const submitter = (
    <Space>
      <Button
        className={styles.submitterBtn}
        onClick={() => {
          setCurrent((current) =>
            current > WizardStep.addRole ? current - 1 : current,
          )
        }}
        disabled={current <= WizardStep.addRole}
      >
        上一步
      </Button>
      {current < WizardStep.bindUsers ? (
        <Button
          className={styles.submitterBtn}
          onClick={async () => {
            try {
              if (current >= WizardStep.addRole) {
                await roleInfoForm.validateFields()
              }

              setCurrent((current) =>
                current < WizardStep.bindUsers ? current + 1 : current,
              )
            } catch {}
          }}
        >
          下一步
        </Button>
      ) : (
        <Button
          className={styles.submitterBtn}
          type="primary"
          onClick={() => handleConfirmWizard()}
          loading={loadingAdd}
        >
          完成
        </Button>
      )}
    </Space>
  )

  return (
    <div className="add-role-steps-form">
      <Steps current={current}>
        {steps.map(({ title }, index) => (
          <Steps.Step title={title} key={index} />
        ))}
      </Steps>

      {steps.map(({ content }, index) => (
        <LazyWrapper
          className={styles.stepsForm}
          active={current === index}
          key={index}
        >
          {content}
        </LazyWrapper>
      ))}
      {submitterRef.current &&
        ReactDOM.createPortal(submitter, submitterRef.current)}
    </div>
  )
}
