import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core'
import { Button, Descriptions, Input, message, Tabs } from 'antd'
import {
  DataSourceType,
  executeMenuActionSql,
  generateCreateSql,
  GenerateSqlRequest,
  getTableColumns,
} from 'src/api'
import {
  DatabaseInfo,
  removeQueryPane,
} from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { getInfoFromPath } from 'src/util'
import styles from './index.module.scss'
import { refreshOnRoot } from '../../sdt'
import { GridConfigBase } from '../../resultTabs/resultContentGrid'
import { DesignIndex } from '../DesignIndex'
import { getColumnTypeOptions ,correctComment} from 'src/util'
// import { activePaneInfoSelector } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'

const { TabPane } = Tabs

const defaultColDef: ColDef = { editable: true }

const mockData: object[] = []

const cellBooleanEditor: ColDef | ColGroupDef = {
  cellEditor: 'agSelectCellEditor',
  cellEditorParams: { values: [true, false] },
}

const getColumnTypeCellEditor = (connectionType: DataSourceType) => {
  switch (connectionType) {
    default:
      return 'agSelectCellEditor'
  }
}

export const getColumnDefs = (
  connectionType: DataSourceType,
): (ColDef | ColGroupDef)[] => {
  const baseCol = [
    {
      headerName: '排序',
      rowDrag: true,
      editable: false,
    },
    {
      field: 'columnName',
      headerName: '列名',
    },
    {
      field: 'columnType',
      headerName: '类型',
      cellEditor: getColumnTypeCellEditor(connectionType),
      cellEditorParams: {
        values: getColumnTypeOptions(connectionType),
      },
    },
    {
      field: 'columnLength',
      headerName: '长度',
      cellRenderer: 'simpleTextRenderer',
      valueParser: (v: any) => +v.newValue,
    },
    {
      field: 'defaultValue',
      headerName: '默认值',
      cellRenderer: 'simpleTextRenderer',
    },
    {
      field: 'columnComment',
      headerName: '备注',
      cellEditor: 'agLargeTextCellEditor',
    },
    { field: 'allowNull', headerName: '允许 null', ...cellBooleanEditor },
    { field: 'autoIncrease', headerName: '自增', ...cellBooleanEditor },
    { field: 'isPrimary', headerName: '主键', ...cellBooleanEditor },
  ]

  switch (connectionType) {
    case 'Hive':
    case 'Inceptor':
    case 'Impala':
      let col = baseCol.filter(
        (it: any) =>
          it.field &&
          !['defaultValue', 'allowNull', 'autoIncrease', 'isPrimary'].includes(
            it.field,
          ),
      )

      col.splice(3, 0, {
        field: 'precision',
        headerName: '精度',
        valueParser: (v: any) => +v.newValue,
      })
      return col
    case 'SQLServer':
      let sqlServerCol = baseCol.slice()
      sqlServerCol.splice(3, 0, {
        field: 'precision',
        headerName: '精度',
        valueParser: (v: any) => +v.newValue,
      })
      return sqlServerCol
    case 'Vertica':
      return baseCol.filter(
        (it: any) =>
          it.field && !['defaultValue', 'autoIncrease'].includes(it.field),
      )
    default:
      return baseCol
  }
}

interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
}

export const CreateTablePane = ({
  databaseInfo,
  queryTabKey,
}: CreateTablePaneProps) => {
  const { theme } = useSelector((state) => state.appearance)
  const {
    connectionId,
    connectionType = 'MySQL',
    databaseName,
    nodePath,
    nodePathWithType,
    nodeType,
  } = databaseInfo

  // const { plSql } = useSelector(activePaneInfoSelector) || {}

  const { toolbarConnections } = useSelector((state) => state.editor)
  const connection = toolbarConnections?.find(
    (t) => t.connectionId === connectionId,
  )
  const connectionName = connection?.alias
    ? connection.alias
    : connection?.nodeName
  const schemaName = (() => {
    switch (connectionType) {
      case 'DamengDB':
      case 'DB2':
        return getInfoFromPath(nodePath, 'schema', 'DamengDB')
      case 'SQLServer':
      case 'PolarDB':
      case 'PostgreSQL':
        return getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
      case 'MogDB':
        return getInfoFromPath(nodePath, 'schema', 'MogDB')
      case 'OracleCDB':
      case 'StarRocks':
        return getInfoFromPath(nodePath, 'schema', 'OracleCDB')
      case 'StarRocks':
        return getInfoFromPath(nodePath, 'schema', 'StarRocks')
      default:
        return getInfoFromPath(nodePath, 'connection', connectionType)
    }
  })()

  //创建表OracleCDB operatingObject 特殊处理
  const operatingObject =['OracleCDB'].includes(connectionType) ? schemaName : databaseName

  const [rowData, setRowData] = useState<any[]>([])
  const [generatedSql, setGeneratedSql] = useState('')
  const [tableName, setTableName] = useState<string>('')
  const [tableComment, setTableComment] = useState<string>('')
  const dispatch = useDispatch()

  const gridApiRef = useRef<GridApi>()
  const gridColumnApiRef = useRef<ColumnApi>()

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
    gridColumnApiRef.current = params.columnApi
    setRowData(mockData)
    gridApiRef.current.sizeColumnsToFit()
  }

  const addColumn = () => {
    gridApiRef.current?.applyTransactionAsync(
      { add: [{ allowNull: true }] },
      (res) => {
        gridApiRef.current?.startEditingCell({
          rowIndex: Number(res.add[0]?.rowIndex),
          colKey: 'columnName',
        })
      },
    )
  }

  const api = gridApiRef.current

  const deleteColumn = () => {
    const selectedRows = api?.getSelectedRows()
    api?.applyTransactionAsync({ remove: selectedRows }, () => tryGenerateSql())
  }

  interface TableInfo {
    tableName: string
    tableComment?: string
  }
  interface UserInputs {
    databaseName?: string
    schemaName?: string
    tableInfo: TableInfo
    columnInfos: any
    primaryKeyInfos: any
  }

  const tryGenerateSql = useCallback(() => {
    const columnInfos: any[] = []
    const primaryKeyInfos: any[] = []
    api?.forEachNode((rowNode, index) => {
      const { data } = rowNode
      const { columnName, isPrimary,columnComment } = data
      // 修正备注中带特殊符号的
      columnInfos.push({...data,columnComment:correctComment(connectionType,columnComment)})
      isPrimary && primaryKeyInfos.push({ columnName })
    })
    const tableInfo: TableInfo = { tableName }
    if (tableComment) {
      tableInfo.tableComment = correctComment(connectionType,tableComment) 
    }
    const userInputs: UserInputs = {
      tableInfo,
      columnInfos,
      primaryKeyInfos,
    }
    // todo: 从后端或数据源模块获取配置，自动处理数据源差异
    if (
      ['SQLServer', 'DamengDB', 'DB2', 'PolarDB', 'PostgreSQL', 'MogDB','OracleCDB','StarRocks'].includes(
        connectionType,
      )
    ) {
      userInputs.schemaName = schemaName
    }
    if (!['DamengDB', 'DB2'].includes(connectionType)) {
      userInputs.databaseName = databaseName
    }
    const params: GenerateSqlRequest = {
      dataSourceType: connectionType || 'MySQL',
      nodeType: 'table',
      userInputs,
    }
    generateCreateSql(params)
      .then(({ generatedSql }) => setGeneratedSql(generatedSql))
      .catch(() => setGeneratedSql(''))
  }, [api, connectionType, databaseName, tableName, tableComment, schemaName])

  const { data: columns, run: getColumns } = useRequest(
    () => {
      if (!(connectionId && tableName && nodePath && nodeType && nodePathWithType))
        return Promise.reject(`缺少参数`)
      const fullNodePath = `${nodePath}/${nodeType === 'tableGroup' ? tableName.toLocaleUpperCase() : ''
        }`
      return getTableColumns({
        connectionId,
        connectionType,
        // 对sql server 类型特殊处理  bug cq-576
        nodeName: connectionType === 'SQLServer' ? tableName : tableName.toLocaleUpperCase(),
        nodePath: fullNodePath,
        nodePathWithType: nodePathWithType,
        nodeType: 'table',
      })
    },
    {
      manual: true,
      formatResult(data) {
        const { columns } = data
        return columns?.map((column) => ({
          columnName: column.columnName,
        }))
      },
    },
  )

  const [isTableCreated, setIsTableCreated] = useState<boolean>(false)

  const { run: createTable } = useRequest(executeMenuActionSql, {
    manual: true,
    onSuccess: (result) => {
      const [{ executeError }] = result
      if (executeError) {
        return message.error(executeError.message)
      }
      getColumns()
      message.success('创建表成功')
      setIsTableCreated(true)
      dispatch(refreshOnRoot())
    },
  })

  const removePane = () => {
    dispatch(removeQueryPane(queryTabKey))
  }

  useEffect(() => {
    tryGenerateSql()
  }, [tableName, tryGenerateSql])

  return (
    <div className={styles.createTablePane}>
      <Tabs tabPosition="left" className={styles.tab}>
        <TabPane tab="表" key="table" tabKey="table">
          <Descriptions>
            <Descriptions.Item label="连接名">
              {connectionName}
            </Descriptions.Item>
            {!['DamengDB', 'DB2'].includes(connectionType) && (
              <Descriptions.Item label="数据库">
                {databaseName}
              </Descriptions.Item>
            )}
            {['SQLServer', 'DamengDB', 'DB2', 'PolarDB', 'PostgreSQL', 'OracleCDB','StarRocks'].includes(
              connectionType,
            ) && (
                <Descriptions.Item label="Schema">{schemaName}</Descriptions.Item>
              )}
          </Descriptions>
          <div style={{ color: theme === 'dark' ? 'white' : 'initial' }}>
            <span>表名：</span>
            <Input
              value={tableName}
              onChange={(e) => setTableName(e.target.value)}
              style={{ width: 160 }}
              className="mr16"
            ></Input>
            <span>表注释：</span>
            <Input
              value={tableComment}
              onChange={(e) => setTableComment(e.target.value)}
              style={{ width: 160 }}
            ></Input>
          </div>
          <div style={{ margin: '16px 0' }}>
            <Button size="small" className="mr8" onClick={addColumn}>
              添加列
            </Button>
            <Button size="small" className="mr16" onClick={deleteColumn}>
              删除列
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                if (!connectionId) return
                createTable({
                  connectionId,
                  dataSourceType: connectionType,
                  databaseName,
                  operatingDatabase: databaseName,
                  operatingObject: operatingObject,
                  // plSql,
                  tabKey: queryTabKey,
                  statements: [generatedSql],
                })
              }}
              disabled={isTableCreated}
            >
              提交
            </Button>
          </div>
          <div
            id="myGrid"
            style={{
              height: '30vh',
              width: '100%',
            }}
            // todo: 更好地统一处理主题样式
            className={
              theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
            }
          >
            <AgGridReact
              {...GridConfigBase}
              modules={[ClientSideRowModelModule]}
              columnDefs={getColumnDefs(connectionType)}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              animateRows={true}
              onGridReady={onGridReady}
              rowData={rowData}
              rowSelection="single"
              stopEditingWhenGridLosesFocus
              onCellValueChanged={() => tryGenerateSql()}
            />
          </div>
          <div
            style={{
              margin: '16px 0',
              color: theme === 'dark' ? 'white' : 'black',
            }}
          >
            生成 SQL:
          </div>
          <Input.TextArea
            readOnly
            value={generatedSql}
            autoSize={{ minRows: 6, maxRows: 6 }}
          />
        </TabPane>
        {connectionType === 'SQLServer' && (
          <TabPane tab="索引" key="index" tabKey="index">
            <DesignIndex
              connectionId={connectionId}
              databaseName={databaseName}
              connectionType={connectionType}
              tableName={tableName}
              columns={columns || []}
              successfulCallback={removePane}
              queryTabKey={queryTabKey}
              nodePath={nodePath}
            ></DesignIndex>
          </TabPane>
        )}
      </Tabs>
    </div>
  )
}
