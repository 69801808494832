import React, { RefCallback, useCallback } from 'react'
import { Form, InputNumber, Radio, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { flowTimeLimits } from '../constants'
import styles from '../flowForm.module.scss'

export const FormItemTimeLimit = () => {
  const inputRef: RefCallback<HTMLInputElement> = useCallback(
    (input) => input?.focus(),
    [],
  )

  return (
    <Form.Item
      label="期限"
      name={['timeLimit', 'preset']}
      rules={[{ required: true, message: '请选择期限' }]}
      initialValue={7 * 24}
    >
      <Radio.Group className={styles.radioGroup}>
        {flowTimeLimits.map(({ label, value, tooltip }) => (
          <Radio key={label} value={value}>
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <QuestionCircleOutlined style={{ marginLeft: 4 }} />
              </Tooltip>
            )}
          </Radio>
        ))}
        {/* 自定义 */}
        <Radio value={false}>
          自定义
          <Form.Item dependencies={['timeLimit', 'preset']} noStyle>
            {({ getFieldValue }) => {
              const hidden = getFieldValue(['timeLimit', 'preset']) !== false
              if (hidden) return null
              return (
                <>
                  <Form.Item
                    name={['timeLimit', 'custom']}
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        type: 'integer',
                        min: 1,
                        message: '请输入一个正整数',
                      },
                    ]}
                    noStyle
                  >
                    <InputNumber
                      ref={inputRef}
                      className={styles.labelInput}
                      min={1}
                      size="small"
                    />
                  </Form.Item>
                  {!hidden && '小时'}
                </>
              )
            }}
          </Form.Item>
        </Radio>
      </Radio.Group>
    </Form.Item>
  )
}
