/*
 * @Author: fuzhenghao
 * @Date: 2023-01-06 11:27:03
 * @LastEditTime: 2023-01-16 16:48:19
 * @LastEditors: fuzhenghao
 * @Description: 我的审批-管理流程
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\FlowManagePage.tsx
 */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery, useRequest, useResizeObserver, useSelector } from 'src/hook'
import { message, Select, Space, Table } from 'antd'
import { FlowType } from 'src/types'
import { ColumnsType } from 'antd/lib/table'
import {
  IList,
  approveApplication,
  getAllSimpleUsers,
  rejectApplication,
  searchPendingFlow,
} from 'src/api'
import { useHistory } from 'react-router-dom'
import {
  applyTimeOptions,
  flowTimeLimits,
  flowTypeLabelMap,
  flowTypeOptions,
  statusOptions,
} from './constants'
import dayjs from 'dayjs'
import { SearchOutlined } from '@ant-design/icons'
import { LinkButton } from 'src/components'
import { ModalFlowDetail } from './flowDetails'
import styles from './flowPage.module.scss'
import ModalSelectNewApprover from './flowDetails/ModalSelectNewApprover'

const { Option } = Select

export const FlowManagePage = React.memo(() => {
  const userId = useSelector((state) => state.login.userInfo.userId)

  const [visible, setVisible] = useState(false) // 流程详情 modal visible
  const [activeFlowId, setActiveFlowId] = useState<string>() // 流程详情 当前 流程 id
  const [activeRecord, setActiveRecord] = useState<any>() // 流程详情 当前 流程 record记录
  const [visible_setNewApprover, setVisible_setNewApprover] = useState(false) // 新审批人 modal visible
  const [clickRow_transerNewApprover, setClickRow_transerNewApprover] =
    useState<Array<string>>([]) // 新审批人 modal visible

  // search params
  const history = useHistory()
  const search = useQuery()
  const currentPage = Number(search.get('p')?.toString()) || 1
  const pageSize = Number(search.get('s')?.toString()) || 10
  const [typeFilter, setTypeFilter] = useState<string>()
  const [timeFilter, setTimeFilter] = useState<string>()
  const [approveType, setApproveType] = useState<
    'rejected' | 'fullilled' | 'pending'
  >('pending')
  const inputValueRef = useRef<string>()

  const {
    data,
    loading,
    run,
    refresh: refreshList,
  } = useRequest(searchPendingFlow, {
    manual: true,
    formatResult: (data) => {
      let { pageNumber, pageSize, list } = data
      const newList = list.map((item: any, index: number) => {
        item.indexKey = (pageNumber - 1) * pageSize + index + 1
        return item
      })

      const total = data.totalCount

      return { list: newList, total }
    },
    refreshOnWindowFocus: true,
    focusTimespan: 600000,
  })

  const [userList, setUserList] = useState<any[]>([]) // 全部用户列表

  const handleRequest = useCallback(() => {
    if (userId) {
      const applyType = typeFilter !== '*' ? typeFilter : undefined
      const applyUserName = inputValueRef.current || undefined
      let startApplyTime
      let endApplyTime
      switch (timeFilter) {
        case '-7':
          startApplyTime = dayjs().subtract(7, 'd').format()
          endApplyTime = dayjs().format()
          break
        case '-30':
          startApplyTime = dayjs().subtract(30, 'd').format()
          endApplyTime = dayjs().format()
          break
        default:
          break
      }

      run({
        userId,
        pageSize,
        currentPage,
        applyType,
        startApplyTime,
        endApplyTime,
        applyUserName,
        approveUserId: '',
      })
    }
  }, [currentPage, pageSize, run, timeFilter, typeFilter, userId])

  useEffect(() => {
    handleRequest()
  }, [handleRequest])

  useEffect(() => {
    // 获取用户列表
    const getUserList = async () => {
      try {
        const users = await getAllSimpleUsers()
        setUserList(users)
      } catch {}
    }
    getUserList()
  }, [])

  // 通过审批
  const { run: runApprove } = useRequest(
    approveApplication,
    {
      manual: true,
      // fetchKey: (id) => id,
      onSuccess: () => {
        if (approveType === 'fullilled') {
          message.success('通过成功')
        } else {
          message.success('驳回成功')
        }
        refreshList()
      },
    },
  )
  // 驳回审批
  const { run: runReject, fetches: fetchesReject } = useRequest(
    rejectApplication,
    {
      manual: true,
      fetchKey: (id) => id,
      onSuccess: () => {
        message.success('驳回成功')
        refreshList()
      },
    },
  )

  const handleChangePagination = (current: number, pageSize?: number) => {
    search.set('p', current.toString())
    if (pageSize) {
      search.set('s', pageSize.toString())
    }
    history.replace({ search: search.toString() })
  }

  // ? ant table dom 层级很多，纵向滚动通过 css 精确计算很麻烦；
  // ? 这里用 resize obserber 监听到的值粗略减去表头和分页器，作为 y 轴 max-height
  const [rect, domRef] = useResizeObserver()

  const columns: ColumnsType<IList> = [
    { dataIndex: 'uuid', title: '编号', width: 168, ellipsis: true },
    {
      dataIndex: 'applyUserName',
      title: '申请人',
      width: 70,
      ellipsis: true,
    },
    {
      dataIndex: 'applyType',
      title: '类型',
      render: (_, { applyType }) => flowTypeLabelMap[applyType as FlowType],
      width: 114,
      ellipsis: true,
    },
    { dataIndex: 'deptName', title: '发起部门', width: 72, ellipsis: true },
    {
      dataIndex: 'applyTime',
      title: '发起时间',
      render: (_, { applyTime }) =>
        dayjs(applyTime).format('YYYY-MM-DD HH:mm:ss'),
      width: 114,
    },
    {
      dataIndex: 'expiredTimeLimit',
      title: '使用期限',
      render: (_, record) => {
        if (record.hasOwnProperty('expiredTimeLimit')) {
          let { expiredTimeLimit } = record
          if (expiredTimeLimit === null) return '永久'
          return (
            flowTimeLimits.find(({ value }) => value === expiredTimeLimit)
              ?.label || `${expiredTimeLimit} 小时`
          )
        } else {
          return null
        }
      },
      width: 72,
      ellipsis: true,
    },
    {
      dataIndex: 'connectionName',
      title: '数据源连接',
      width: 114,
      ellipsis: true,
    },
    {
      dataIndex: 'currentAssignee',
      title: '审批人账号列',
      width: 70,
      ellipsis: true,
    },
    {
      dataIndex: 'currentAssigneeName',
      title: '审批人',
      width: 70,
      ellipsis: true,
    },
    {
      dataIndex: 'dataBaseName',
      title: '数据库',
      render: (_, { dataBaseName }) =>
        // 去重
        Array.from(new Set(dataBaseName?.split(','))).join(', '),
      width: 114,
      ellipsis: true,
    },
    {
      dataIndex: 'applyStatus',
      title: '状态',
      render: (_) => {
        let renderResult = ''
        statusOptions.map((option) => {
          let { value, label } = option
          if (value === _) {
            renderResult = label
          }
        })
        return renderResult
      },
      width: 60,
      ellipsis: true,
    },
    {
      key: 'action',
      title: '操作',
      render: (_, record) => {
        let { applyId } = record
        return (
          <Space>
            <LinkButton
              className={styles.linkButton}
              onClick={() => {
                setClickRow_transerNewApprover([record.flowTaskId])
                setVisible_setNewApprover(true)
              }}
            >
              工作转审
            </LinkButton>
            <LinkButton
              className={styles.linkButton}
              onClick={() => {
                setActiveFlowId(applyId as string)
                setActiveRecord(record)
                setVisible(true)
              }}
            >
              查看详情
            </LinkButton>
          </Space>
        )
      },
      width: 114,
    },
  ]

  // rowSelection objects indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      )
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {
      console.log(record, selected, selectedRows)
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      console.log(selected, selectedRows, changeRows)
    },
  }
console.log(clickRow_transerNewApprover,'1');

  return (
    <div className={styles.contentCardBody} ref={domRef}>
      <div className={styles.searchArea}>
        <Space>
          <Select
            className={styles.searchSelect}
            placeholder="流程类型"
            value={typeFilter}
            options={flowTypeOptions}
            onChange={setTypeFilter}
            style={{ width: 120 }}
          />
          <Select
            className={styles.searchSelect}
            placeholder="提交日期"
            value={timeFilter}
            onChange={setTimeFilter}
            options={applyTimeOptions}
          />
        </Space>
        <Select
          placeholder="搜索审批人账号..."
          onChange={(selected: string) => {
            inputValueRef.current = selected
            console.log(selected)
            handleRequest()
          }}
          allowClear
          style={{ minWidth: 144, width: 256 }}
          size="middle"
          optionFilterProp="label"
          showSearch={true}
        >
          {userList.map((user) => {
            const { userId, userName } = user
            return (
              <Option
                key={userId}
                value={userId}
                label={`${userName}(${userId})`}
              >
                {`${userName}(${userId})`}
              </Option>
            )
          })}
        </Select>
      </div>
      <Table<IList>
        className={styles.table}
        // rowSelection={{ ...rowSelection }}
        columns={columns}
        rowKey={({ indexKey }) => indexKey}
        dataSource={data?.list}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: data?.total,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handleChangePagination,
        }}
        size="middle"
        scroll={{ x: rect?.width, y: rect ? rect.height - 200 : 0 }}
      />
      {/* 流程详情 moda */}
      <ModalFlowDetail
        key={activeFlowId}
        record={activeRecord}
        visible={visible}
        applyId={activeFlowId as any}
        kind="approval"
        onCancel={() => {
          setVisible(false)
          setActiveRecord(null)
        }}
        refreshList={refreshList}
      />
      {
        // TODO 此处模态框中的选择接口是允许批量的, 鉴于目前只存在九个流程,因此先不进行批量选择,后续需要进行更改
      }
      <ModalSelectNewApprover
        cleanParentComponentData={() => {
          console.log(1111);
          
          setClickRow_transerNewApprover([])
        }}
        userTasks={clickRow_transerNewApprover}
        visible_setNewApprover={visible_setNewApprover}
        setVisible_setNewApprover={setVisible_setNewApprover}
      />
    </div>
  )
})
