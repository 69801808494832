
export const DATA_REQUEST_APPLY_TYPES: {[key: number]: string}  = {
  1: '数据订正',
  2: '发布变更',
}

export type LabelKey = keyof typeof DATA_CHANGE_FILTER_TYPES

export const DATA_CHANGE_FILTER_TYPES = {
  APPLIED: '我发起的',
  APPROVED: '我审批的',
  MANAGE: '工单管理',
}

export const SQL_TYPES:  {[key: number]: string} = {
  1: '文本',
  2: '上传附件',
}

export const APPLY_COLUMN_FILTER = ['草稿', '审批中','待执行','执行中','执行成功','执行失败','已撤回', '已驳回','任务中止']

export const APPROVE_COLUMN_FILTER =['审批中','待执行','执行中','执行成功','执行失败','任务中止']

export type EXECUTE_TYPES =
  '草稿' 
  | '待审核'
  | '已审核'
  | '已完成'
  | '审批中'
  | '待执行'
  | '执行中'
  | '已执行'
  | '执行成功'
  | '执行失败'
  | '已撤回'
  | '已驳回'
  | '任务中止'
  | '回退成功'
  | '回退失败'
  | '被中止'

export const EXECUTE_TYPE_COLOR_MAPPING: {[key: string]: string}  = {
  '审批中': '#2db7f5',
  '待执行': 'orange',
  '执行中': 'green',
  '已执行': '#108ee9',
  '执行成功': '#87d068',
  '执行失败': '#f50',
  '已撤回': 'cyan',
  '已驳回': 'blue',
  'default': 'cyan'
}


export type CURRENT_ROUTE_TYPE =
  | 'MINE_APPLY'
  | 'MINE_APPROVE'
  | 'FLOW_WORK_ORDER_MANAGEMENT'

  export const ORDER_MAPPING = {
      ascend: 'asc',
      descend: 'desc',
  }