import { Space } from "antd";
import React, { createContext } from "react";
import { getFilterRule } from "src/api";
import { useRequest } from "src/hook";
import { AddRuleBox } from "./AddRuleBox";
import { FieldInfoTable } from "./FieldInfoTable";
import { FilterRuleTable } from "./FilterRuleTable";

const RuleRequest = createContext<{ loading: boolean; tableInfo: any; queryRule: (params: any) => Promise<any>; refreshView: () => Promise<any> } | null>(null);

const TableFilterRow = (): JSX.Element => {
  const { loading, data: tableInfo, run: queryRule, refresh: refreshView } = useRequest(getFilterRule, { manual: true });

  return (
    <Space direction="vertical">
      <FieldInfoTable />
      <RuleRequest.Provider value={{ loading, tableInfo, queryRule, refreshView }}>
        <AddRuleBox />
        <FilterRuleTable />
      </RuleRequest.Provider>
    </Space>
  );
};

export { RuleRequest, TableFilterRow };

