import React, { useEffect, useState, useMemo, useCallback } from 'react'
import useRequest from '@ahooksjs/use-request'
import classNames from 'classnames'
import { ModalProps } from 'antd/lib/modal'
import { Form, Input, Spin, Typography, Select, Button, Tooltip } from 'antd'
import { UIModal } from 'src/components'
import {
  CellData,
  CellRenderType,
  PlainRowData,
  CellRenderResultData,
  RowData,
} from 'src/api'
import type { GridApi } from '@ag-grid-community/core'
import { getImageSrcFromHex, transferHexStringToOtherTypes } from 'src/util'
import { JsonTree } from 'src/components/JsonTree/JsonTree'
import { useCopyableChange } from '../useCopyableChange'
import { VIEW_CELL_TYPES, CELL_TYPE} from './constants'
import styles from './cellViewer.module.scss'

const { Text } = Typography

const ReadOnlyTypes: CellRenderType[] = [
  'base64-image',
  'base64-jpg',
  'base64-png',
  'binary',
  'binary-image',
  'binary-jpg',
  'binary-png',
]

export const isCellTag = (value: string) => {
  if (value.length > 50) return false
  if (!~value.indexOf('<')) return false
  const CellTagRegex = /([0-9] bytes|\.[0-9]{2} (KB|MB|GB)|too large)>$/
  return CellTagRegex.test(value)
}

interface CellViewerModalProps {
  gridApi: GridApi | null
  rowIndex: number | null
  rowData?: Record<string, unknown>
  resultData: RowData[]
  visible: boolean
  type: string
  isExplain: boolean
  editable: boolean
  allowResultCopy: boolean
  allowBinaryCellDownload: boolean
  setVisible: (visible: boolean) => void
  updateFocusedCell: (newData: PlainRowData, oldData: PlainRowData) => void
  downloadFocusedCell: (newData: PlainRowData, oldData: PlainRowData) => void
  fetchFocusedCell: (
    newData: PlainRowData,
    oldData: PlainRowData,
  ) => Promise<CellRenderResultData[]>
}

export const CellViewerModal: React.FC<CellViewerModalProps> = (props) => {
  const {
    gridApi,
    rowIndex,
    rowData,
    resultData,
    visible,
    allowBinaryCellDownload,
    setVisible,
    updateFocusedCell,
    editable,
    allowResultCopy,
    downloadFocusedCell,
    fetchFocusedCell,
    type,
    isExplain,
  } = props

  const [isEditing, setIsEditing] = useState(false);
  //column binary 类型支持筛选
  const [displayMode, setDisplayMode] = useState<CELL_TYPE>('UTF-8')


  const {
    data: completeRow,
    run: tryGetCompleteCell,
    loading: loadingGetCompleteCell,
    mutate,
  } = useRequest(
    () => {
      const oldRowData = getCurrentRow()
      const field = getFieldOfFocusedCell()
      if (!field) return
      const oldValue = oldRowData[field]
  
      if (isCellTag(String(oldValue))) {
        return fetchFocusedCell({ [field]: 'DoesNotMatter' }, oldRowData).then(
          (res) => {
            if (!res[0]) return { [field]: { value: '' } }
            const { resultData } = res[0]
            return resultData[0]
          },
        )
      } else {
        return new Promise((resolve) =>
          resolve({ [field]: { value: oldValue } }),
        ) as Promise<RowData>
      }
    },
    { manual: true },
  )

  useEffect(() => {
    if (!visible) return
    tryGetCompleteCell()
  }, [tryGetCompleteCell, visible])

  const getFieldOfFocusedCell = () => {
    if (!gridApi) return
    const focusedCell = gridApi.getFocusedCell()
    if (!focusedCell) return
    const { column } = focusedCell
    const { field } = column.getColDef()
    return field
  }

  const getDetailedCell = (): CellData | null => {
    if (!visible) return null
    const field = getFieldOfFocusedCell()
    if (!field) return null
    const focusedCell = getFocusedCell()
    if (!focusedCell) return null
    if (!resultData) {
      // 处理未实现 detailed cell data 的数据源
      if (!gridApi) return null
      const nodeList = gridApi.getSelectedNodes()
      if (!nodeList || !nodeList[0]) return null
      return { value: nodeList[0].data[field] }
    }
 
    const { rowIndex } = focusedCell
    if (!resultData[rowIndex]) {
      // todo: 新增行数据后允许编辑。调整 ag-grid cell value 结构，避免 hack 对象
      const oldRowData = getCurrentRow()
      return { value: oldRowData[field], editable: true }
    }
    return resultData[rowIndex][field]
  }
  //设置默认筛选状态
  useEffect(() => {
    const { renderType = '' }  = getDetailedCell() || {};

    //如果是图片 默认先选中图片格式 其他暂不做处理
    if (renderType && ['binary-image', 'binary-jpg','binary-png','binary-gif'].includes(renderType)) {
      setDisplayMode('IMG');
    }

  },[gridApi, visible])

  const getFocusedCell = () => {
    if (!gridApi) return
    return gridApi.getFocusedCell()
  }
  //获取列单元格信息
  const getFocusedColumnRenderType= () => {
    if (!gridApi) return
    const { cellEditorParams } = gridApi.getFocusedCell()?.column?.getColDef() || {};
    if (!cellEditorParams) return
   
    return {
      ...(cellEditorParams || {})
    }
  }

  const getCurrentRow = () => {
    return rowData || {}
  }

  const getCompleteValue = () => {
    const field = getFieldOfFocusedCell()
    if (!field) return
    if (!completeRow) return
    const completeCell = completeRow[field]
    if (!completeCell) return
    // null undefined 编辑栏textarea不显示值
    if (['[object Null]', '[object Undefined]'].includes(Object.prototype.toString.call(completeCell.value))) {
      return completeCell.value;
    } else if (typeof completeCell.value === 'object') {
      return JSON.stringify(completeCell.value);
    } else if (typeof(completeCell.value) === 'boolean') {
      return completeCell.value.toString()
    }
    return completeCell.value;
  }

  const getRenderedContent = useCallback((copyable?: boolean) => {
    if (!visible) return 
   
    const field = getFieldOfFocusedCell()
 
    if (!field) return
    if (!completeRow) {
      return <div style={{ color: 'rgba(0, 0, 0, 0.35)' }}>(暂不支持)</div>
    }
    const completeCell = completeRow[field]
    if (!completeCell) return
    let { value } = completeCell;
    
    const { renderType = '' }  = getDetailedCell() || {};

    if (typeof(value) === 'boolean') {
      value = value.toString()
    }
    if (!value) return <div style={{ color: 'rgba(0, 0, 0, 0.35)' }}>(空)</div>

    let content: React.ReactNode;
    //如果column renderType是binary 则根据选项内容展示
    const columnInfoRenderType =  getFocusedColumnRenderType();
    if (columnInfoRenderType?.renderType === 'binary') {
      //当前选中类型 图片格式 为binary的后端规定只有binary-开头的四个
      switch(displayMode) {
        case 'IMG':
          const src = getImageSrcFromHex(value)
          content = <img src={src} alt="" style={{width: '100%',height: '100%'}}  
            onContextMenu={(e) => {
              e.preventDefault()
          }}></img>
          break;
        default:
          const text = transferHexStringToOtherTypes(value,displayMode);
          content = (
            <Text style={{ whiteSpace: 'pre-wrap' }} copyable={allowResultCopy}>
              {text}
            </Text>
          )
          break
      }
     
    }else {
      //不是column renderType不是binary 则走原逻辑 暂时先不删除binary相关处理
      switch (renderType) {
        case 'binary-image':
        case 'binary-jpg':
        case 'binary-png':
        case 'binary-gif':
          const src = getImageSrcFromHex(value)
          content = <img src={src} alt="" style={{width: '100%'}}  
            onContextMenu={(e) => {
              e.preventDefault()
          }}></img>
          break
        case 'base64-image':
        case 'base64-jpg':
        case 'base64-png':
        case 'base64-gif':
          content = <img src={value} alt="" style={{width: '100%'}}
          onContextMenu={(e) => {
            e.preventDefault()
          }}></img>
          break
        case 'text-json':
          content = <JsonTree dataSource={[value]} height={480} />
          break
        case 'binary':
        default:
          content = (
            <Text style={{ whiteSpace: 'pre-wrap' }} copyable={allowResultCopy}>
              {value}
            </Text>
          )
      }
    }

    return (
      <Spin
        wrapperClassName={classNames(
          renderType === 'text-json' && styles.fullWidth,
        )}
        spinning={loadingGetCompleteCell}
      >
        {content}
      </Spin>
    )
  },[displayMode,visible, completeRow])

  const handleSaveCell = async () => {
    const values = await cellEditorForm.validateFields()
    const { cellValue } = values
    const field = getFieldOfFocusedCell()
    if (!field) return null
    const oldRowData = getCurrentRow()
    updateFocusedCell({ [field]: cellValue }, oldRowData)
    setVisible(false)
  }

  const handleDownloadCell = async () => {
    const field = getFieldOfFocusedCell()
    if (!field) return null
    const oldRowData = getCurrentRow()
    downloadFocusedCell({ [field]: 'DoesNotMatter' }, oldRowData)
    setVisible(false)
  }

  const ButtonPropsWhenEditing: Partial<ModalProps> = {
    okText: '保存并关闭',
    okButtonProps: { onClick: handleSaveCell },
    cancelText: '取消',
    cancelButtonProps: { onClick: () => setIsEditing(false) },
  }
  const ButtonPropsWhenNotEditing: Partial<ModalProps> = {
    okText: '返回',
    okButtonProps: {
      onClick: () => {
        setVisible(false)
        setDisplayMode('UTF-8')
      }
    },
    cancelText: '编辑',
    cancelButtonProps: {
      onClick: () => setIsEditing(true),
      disabled:
        !editable || (!(
          completeRow && completeRow[getFieldOfFocusedCell() || '']?.editable
        ) &&
          !getDetailedCell()?.editable) ||
        ReadOnlyTypes.includes(getDetailedCell()?.renderType || null),
    },
  }

  const ButtonPropsWhenExplain: Partial<ModalProps> = {
    footer: (
      <>
        <Button type="primary" onClick={() => setVisible(false)}>返回</Button>
      </>
    )
  }

  const [cellEditorForm] = Form.useForm()

  // copyable
  const [copyableRef, allowCopy] = useCopyableChange()

  return (
    <UIModal
      title={
      <div className={styles.cellModalTitle}>
        <div>{
        isEditing ? '编辑单元格' :
         <>
          查看单元格 &nbsp;&nbsp;
          {
            getFocusedColumnRenderType()?.renderType === 'binary' && 
            <Select 
              value={displayMode} 
              options={VIEW_CELL_TYPES.map(type => ({label: type, value: type}))}
              onChange={(v) => setDisplayMode(v)}
            />
          }
        </>
        }
        </div>
        { getFocusedColumnRenderType()?.renderType === 'binary' && 
           <Tooltip 
              title={ !getFocusedColumnRenderType()?.downloadable ?  '没主键或字段不支持下载' :!allowBinaryCellDownload ? '无结果集二进制文件下载权限' : ''}>
              <Button type='link' disabled={!allowBinaryCellDownload || !getFocusedColumnRenderType()?.downloadable} className={styles.primaryText}  onClick={handleDownloadCell} >
               下载
              </Button>
           </Tooltip>
        }
      </div>}
      closable={false}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={640}
      afterClose={() => {
        cellEditorForm.resetFields()
        setIsEditing(false)
        mutate({})
      }}
      {...(type === 'explain' || isExplain
        ? ButtonPropsWhenExplain
        : isEditing
          ? ButtonPropsWhenEditing
          : ButtonPropsWhenNotEditing)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          wordBreak: 'break-all',
          overflow: 'auto',
          maxHeight: 500
        }}
        ref={copyableRef}
      >
        {!isEditing && getRenderedContent(allowCopy)}
        {isEditing && type !== 'explain' && (
          <Form form={cellEditorForm} style={{ width: '100%' }}>
            <Form.Item name="cellValue" initialValue={getCompleteValue()}>
              <Input.TextArea style={{ minHeight: 160 }} />
            </Form.Item>
          </Form>
        )}
      </div>
    </UIModal>
  )
}
