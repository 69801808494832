import React, { useMemo, useCallback } from 'react'
import { Dropdown, Menu } from 'antd'
import { indexColId } from './const.agFrameworkComponents'
import type { ICellRendererParams } from '@ag-grid-community/core'
import type { IGridContext } from './types.agFrameworkComponents'

enum MenuKey {
  viewCell = 'viewCell',
  viewRow = 'viewRow',
  copyCell = 'copyCell',
  copyRow = 'copyRow',
  cloneRow = 'cloneRow'
}

export const useContextMenu = (params: ICellRendererParams) => {
  const { context, node, column } = params
  const colId = column?.getColId()
  const {
    disableContextMenu,
    copyable,
    allowClone,
    handleCopyCell,
    handleCopyRow,
    handleViewCell,
    handleViewRow,
    handleCloneRow
  } = (context as IGridContext) || {}

  const handleMenuClick = useCallback(
    (menuKey: MenuKey) => {
      switch (menuKey) {
        case MenuKey.viewCell:
          handleViewCell?.(params)
          return
        case MenuKey.viewRow:
          handleViewRow?.(params)
          return
        case MenuKey.copyCell:
          handleCopyCell?.(params)
          return
        case MenuKey.copyRow:
          handleCopyRow?.(params)
          return
        case MenuKey.cloneRow:
          handleCloneRow?.(params)
          return
      }
    },
    [handleCopyCell, handleCopyRow, handleViewCell, handleViewRow, params],
  )

  const overlay = useMemo(
    () => (
      <Menu onClick={({ key }) => handleMenuClick(key as MenuKey)}>
        <Menu.Item key={MenuKey.viewCell} disabled={colId === indexColId}>
          查看单元格
        </Menu.Item>
        <Menu.Item key={MenuKey.viewRow}>查看单行</Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key={MenuKey.copyCell}
          disabled={copyable === false || colId === indexColId}
        >
          复制单元格
        </Menu.Item>
        <Menu.Item key={MenuKey.copyRow} disabled={copyable === false}>
          复制单行/多行
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key={MenuKey.cloneRow} disabled={!allowClone}>
          克隆单行
        </Menu.Item>
      </Menu>
    ),
    [colId, copyable, handleMenuClick],
  )

  const wrapper = (wrapped: React.ReactElement | null) => {
    if (wrapped == null) return null
    // TODO: 更新 antd 版本，补充 destroyPopupOnHide
    return (
      <Dropdown
        overlay={overlay}
        disabled={disableContextMenu}
        trigger={['contextMenu']}
        onVisibleChange={(visible) => {
          if (visible) {
            // 呼出右键菜单时，选中当前行并聚焦当前单元格
            // ? 如果目标行已经处于选中状态，启用 multiple select
            const selected = node.isSelected()
            node.setSelected(true, !selected)
          }
        }}
      >
        {wrapped}
      </Dropdown>
    )
  }

  return [wrapper]
}
