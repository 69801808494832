import React, { useState } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input'

interface InputShowCountProps extends Omit<InputProps, 'onChange'> {
  value?: string
  maxLength?: number;
  onChange?: (value: string) => void
  inputRef?: React.RefObject<Input>
}

export const InputShowCount = (props: InputShowCountProps) => {

  const {value, maxLength, onChange } = props

  const [count, setCount] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value;
    setCount(value.length)
    setInputValue(value);
    onChange && onChange(value);
  };
  
  return (
    <Input
      maxLength={maxLength}
      suffix={`${count}/${maxLength}`}
      value={value !== undefined ? value : inputValue}
      onChange={handleOnChange}
    />
  )
}