import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Chart } from '@antv/g2'
import DataSet from '@antv/data-set'
import dayjs, { Dayjs } from 'dayjs'
import { Radio, Spin, DatePicker, Select } from 'antd'
import { useRequest } from 'src/hook'
import { getAuditSqlLogUnit, getSupportedConnectionTypes } from 'src/api'
import {
  ChartColors,
  ChartColorsGradient,
  getUnit,
  getUnitBase,
  getUnixTimeRange,
  getCustomUnitBase,
} from './util'
import { EmptyChart } from './EmptyChart'
import chartStyles from './chart.module.scss'

export const SqlCountChart = () => {
  const history = useHistory()
  const [dbTypes, setDBTypes] = useState<string[]>([])
  const [timeRange, setTimeRange] = useState<1 | 7 | 30 | any>(7)
  const [rangePickerTimes, setRangePickerTimes] = useState<string[] | null>(
    [dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
    dayjs().endOf('d').format('YYYY-MM-DD')]
  )

  const { data = [], loading } = useRequest(
    () => {
      let defaultParams = {
        dbTypes,
        unit: getUnit(timeRange),
        ...getUnixTimeRange(timeRange),
      }
      if (rangePickerTimes) {
        const startTimes = Number(dayjs(rangePickerTimes[0]).startOf('d').format('x'));
        const endTimes = Number(dayjs(rangePickerTimes[1]).endOf('d').format('x'));

        defaultParams = {
          ...defaultParams,
          beginTimeMs: startTimes,
          endTimeMs: endTimes,
        }
      }

      return getAuditSqlLogUnit(defaultParams)
    },

    {
      formatResult: (data) => {

        let baseUnit = getUnitBase(timeRange)

        if (rangePickerTimes) {
          baseUnit = getCustomUnitBase(rangePickerTimes[0], rangePickerTimes[1])
        }
        return (
          new DataSet.DataView()
            .source(data.concat(baseUnit))
            // 填充刻度，例如值为 2021-02-8 00:00:00，unit 为 DAY，需要填充之前 timeRange 天的 unit string
            .transform({
              // 补全行
              type: 'fill-rows',
              groupBy: ['dbType'],
              orderBy: ['unit'],
              fillBy: 'order',
            })
            .transform({
              // 补全列
              type: 'impute',
              field: 'amount', // 待补全字段
              groupBy: ['dbType'], // 分组字段集（传空则不分组）
              method: 'value', // 补全为常量
              value: 0,
            })
            .rows.filter(({ dbType }) => dbType)
            .map(({ unit = '', ...rest }) => ({
              unit: timeRange === 1 ? new Date(unit).getHours() : unit,
              ...rest,
            }))
        )
      },
      refreshDeps: [timeRange, rangePickerTimes, dbTypes],
    },
  )

  const { data: connectionTypes = [] } = useRequest(
    getSupportedConnectionTypes,
    {
      formatResult: (data) => {
        return data?.map((dataSource) => ({
          label: dataSource?.dataSourceName,
          value: dataSource?.dataSourceName,
        }))
      },
    },
  )

  const renderSqlCountChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
    })

    const dbTypeWeightByTotal = data
      .reduce((prev, curr) => {
        const record = prev.find((record: any) => record.dbType === curr.dbType)
        if (!record) return prev.concat({ ...curr })
        record.amount += curr.amount
        return prev
      }, [])
      .sort((a: any, b: any) => b.amount - a.amount)
      .map((record: any) => record.dbType)
      .reverse()
    data.sort((a, b) => {
      // 双条件排序：日期升序，日期一样，总数更大的数据库类型靠前
      if (a.unit === b.unit) {
        return (
          dbTypeWeightByTotal.indexOf(b.dbType) -
          dbTypeWeightByTotal.indexOf(a.dbType)
        )
      }
      return a.unit < b.unit ? -1 : 1
    })

    chart.data(data)

    chart.scale({
      unit: {
        range: [0.02, 0.92],
        formatter: (v) => (v < 25 ? v + ' 时' : dayjs(v).format('YYYY-MM-DD')),
      },
      amount: {
        minTickInterval: 1,
        range: [0, 0.95],
        formatter: (v) => (v || '0') + ' 次',
      },
    })

    chart.tooltip({
      showCrosshairs: true,
      shared: true,
    })

    chart.axis('amount', {
      label: {
        formatter: (val) => {
          return val
        },
      },
    })

    chart.line().position('unit*amount').color('dbType', ChartColorsGradient)
    chart.area().position('unit*amount').color('dbType', ChartColorsGradient)

    // tooltip 会根据颜色去重，如果 line 和 area 颜色不一致，tooltip 标签会重复

    chart
      .point()
      .position('unit*amount')
      .color('dbType', ChartColors)
      .shape('circle')
      .style({
        stroke: '#fff',
        lineWidth: 1,
      })
    // new Add -- 听绘图区plot的点击事件
    chart.on('plot:click', (ev: any) => {
      
      Number(dayjs(ev?.data?.data?.unit).format('x'))
      if (ev?.data?.data) {
        let time = []
        if (timeRange == 1) {
          time = [Number(dayjs().startOf('day').format('x')), Number(dayjs().endOf('day').format('x'))]
        } else {
          time = [Number(dayjs(ev?.data?.data?.unit).format('x')), Number(dayjs(ev?.data?.data?.unit).endOf('day').format('x'))]
        }
        history.push({
          pathname: `/statement_detail`, state: { 
            timeRange: time,
            dbTypes: [ev?.data?.data?.dbType]
           }
        })
      }
    })

    chart.render()
    return chart
  }

  useEffect(() => {
    if (!data || !data[0]) return
    const chart = renderSqlCountChart('sql-count', data)
    return () => chart.destroy()
  }, [data])

  const rangeValue = useMemo(() => {
    if (rangePickerTimes === null) {
      return null
    } else {
      const range = rangePickerTimes.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [rangePickerTimes])


  return (
    <Spin spinning={loading}>
      <div className={chartStyles.sqlCountWrapper}>
        <div className={chartStyles.toolbar}>
          <Radio.Group
            buttonStyle="solid"
            size="small"
            value={timeRange}
            onChange={(e) => {
              setTimeRange(e.target.value);
              if (e.target.value === 1) {
                setRangePickerTimes([dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
              } else if (e.target.value === 7) {
                setRangePickerTimes([dayjs().subtract(6, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
              } else {
                setRangePickerTimes([dayjs().subtract(29, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])
              }
            }}
          >
            <Radio.Button value={1}>今日</Radio.Button>
            <Radio.Button value={7}>7 天</Radio.Button>
            <Radio.Button value={30}>30 天</Radio.Button>
          </Radio.Group>
          <div>
            <DatePicker.RangePicker
              style={{ marginLeft: 10 }}
              //@ts-ignore
              value={rangeValue}
              onChange={(dates, dateStrings) => {
                if (!dateStrings[0]) {
                  setRangePickerTimes(null)
                  setTimeRange(7)
                } else {
                  const diffDays = dayjs(dateStrings[1]).diff(
                    dateStrings[0],
                    'day',
                  )
                  //当日
                  if (diffDays === 0 && dayjs().isSame(dayjs(dateStrings[1]), 'day')) {
                    setTimeRange(1)
                  } else {
                    setTimeRange(diffDays)
                  }
                  setRangePickerTimes(dateStrings)
                }
              }}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            />
            <Select
              showSearch
              mode="multiple"
              showArrow
              maxTagCount={1}
              placeholder='数据库种类'
              options={connectionTypes}
              style={{ marginLeft: 10, width: 140 }}
              onChange={(values: string[]) => setDBTypes(values)}
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
            />
          </div>
        </div>
        <div id="sql-count" className={chartStyles.sqlCount}>
          {!data[0] && !loading && <EmptyChart></EmptyChart>}
        </div>
      </div>
    </Spin>
  )
}
