import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatementExecuteParams } from 'src/api'

const initialState: InitialState = {
    visibleResultLogModalTypes: [],
}

export const resultLogForm = createSlice({
  name: 'resultLog',
  initialState,
  reducers: {
    openResultLogForm(state, action: PayloadAction<{type: string,fields: StatementExecuteParams | any}>) {
      const { type, fields = {} } = action.payload
      if (!state.visibleResultLogModalTypes.includes(type)) {
        // 打开指定表单
        state.visibleResultLogModalTypes.push(type)
      }
      // 设置表单回填上下文
      state.excuateContext = {...fields };
    },
    closeResultLogForm(state, action: PayloadAction<string>) {
      if (!action.payload) {
        // 关闭所有表单
        state.visibleResultLogModalTypes = []
        return
      }
      // 关闭指定表单
      state.visibleResultLogModalTypes = state.visibleResultLogModalTypes.filter(
        (el) => el !== action.payload,
      )
    },
  },
})

export const { openResultLogForm, closeResultLogForm } = resultLogForm.actions

export const resultLogFormReducer = resultLogForm.reducer

// types
interface InitialState {
  visibleResultLogModalTypes: string[]
  excuateContext?:any
}
