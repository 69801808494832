// 模拟环境验证
import React, { useState, useContext, useEffect } from "react";
import * as _ from "lodash";
import { Button, Card, Tabs, Spin, Typography, Tooltip } from "antd";
import {
  simulationExecute,
  pollingExecuteResult,
  executeSqlLog,
  rollbackSqlLog,
  SimulationExecuteI
} from "src/api";
import styles from "./index.module.scss";
import { useRequest } from "src/hook";
import { FormContext } from "../ApplyFormContext";
import moment from "moment";
import classnames from "classnames";
import {autoScrollToScreenCenter} from '../utils'
import { EmptyCardContent } from "./EmptyCardContent";

export const SimuleEnvironmentValidation = (props: any) => {
  const {
    itemStepMark,
    currentStep,
    form,
    applyUserId = "",
    currentRouteType,
  } = props;

  const { setCurrentStep, currentContextValue, setCurrentContextValue } = useContext(FormContext);

  const [loading, setLoading] = useState(false);
  const sqlType = form.getFieldValue("sqlType");

  //控制 执行验证/回退验证 按钮显示
  const [sqlBtnVisible, setSqlBtnVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("executeLog");
  const [logFileNames, setLogFileNames] = useState<{fallbackLogName?: string;executeLogName?:string}>({});
  const [executionLog, setExecutionLog] = useState<any[]>([]);
  const [fallbackLog, seFallbackLog] = useState<any[]>([]);

  //动态查询当前模拟库日志
  const { run: handleSimulationExecute } = useRequest(simulationExecute, {
    manual: true,
  });

  //执行sql日志
  const { run: handleExecuteSqlLog } = useRequest(executeSqlLog, {
    manual: true,
    onSuccess: (res = []) => {
      setExecutionLog(res);
    },
  });
  //回退sql模拟执行日志
  const { run: handleRollbackSqlLog } = useRequest(rollbackSqlLog, {
    manual: true,
    onSuccess: (res = []) => {
      seFallbackLog(res);
    },
  });

  useEffect(() => {
    if (
      currentRouteType === "MINE_APPROVE" &&
      currentContextValue?.executeStatus === "审批中" &&
      currentContextValue?.id
    ) {
      handleExecuteSqlLog(currentContextValue.id);
      handleRollbackSqlLog(currentContextValue.id);
      setLogFileNames({
        executeLogName: currentContextValue?.simulateExecuteLog,
        fallbackLogName: currentContextValue?.simulateRollbackLog
      })
    }
  }, [currentRouteType, currentContextValue?.executeStatus]);

  const handleValidate = (key: string) => {
    setActiveKey(key);

    const { simulateNodePath } = form.getFieldsValue();
    let params: SimulationExecuteI = {
      sqlType,
      nodePath: simulateNodePath,
      simulateType: key === "executeLog" ? 1 : 2,
      dataSourceType: currentContextValue?.dataSourceType,
      sqlContent:
        key === "executeLog"
          ? currentContextValue?.sqlStatement
          : currentContextValue?.rollbackSqlStatement,
      sqlOwnerId: applyUserId,
    };
    if (currentContextValue?.flowUUID) {
      params.dataChangeId =  currentContextValue?.id;
    }
    setLoading(true);
    handleSimulationExecute(params)
      .then((simualteKey: string) => {
        // 轮询执行结果
        pollingResult(simualteKey, key);
        setSqlBtnVisible(!sqlBtnVisible);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error("验证失败", err);
      });
  };

  // 轮询执行结果
  const pollingResult = (simualteKey: string, activeKey: string) => {
    const timer = setInterval(() => {
      pollingExecuteResult(simualteKey)
        .then((res) => {
          const {
            isFinishExecute,
            logFilePath,
            statementExecuteLogList = [],
            isExist = true, //true继续轮询， false 没有对应的执行任务
          } = res;
          if (isFinishExecute || !isExist) {
            if (activeKey === "executeLog") {
              setExecutionLog(statementExecuteLogList);
       
              setLogFileNames({
                ...logFileNames,
                executeLogName: logFilePath,
              });
              setCurrentContextValue({
                ...currentContextValue,
                simulateExecuteLog: logFilePath
              })
            } else {
              seFallbackLog(statementExecuteLogList);
              setLogFileNames({
                ...logFileNames,
                fallbackLogName: logFilePath,
              });
              setCurrentContextValue({
                ...currentContextValue,
                simulateRollbackLog: logFilePath
              })
            }
            setLoading(false);
            clearInterval(timer);
          }
        })
        .catch((err) => {
          setLoading(false);
          clearInterval(timer);
          console.error("轮询结果失败：", err);
        });
    }, 3000);
  };

  // 导出
  const handleDownload = () => {
    //审批人 导出上次执行日志
    if (activeKey === "executeLog" && logFileNames?.executeLogName) {
      window.open(`/api/flow/dataChange/exportSimulateExecuteLog/${logFileNames?.executeLogName}`)
    } else {
      logFileNames?.fallbackLogName && window.open(`/api/flow/dataChange/exportSimulateExecuteLog/${logFileNames?.fallbackLogName}`);
    }
  };

  const handleActiveKeyChange = (key: string) => {
    setActiveKey(key);
  };

  const renderLog = (data: any[]) => {
    return (
      <div className={styles.logWrap}>
        {data?.map((item, index: number) => {
          const {
            timestamp,
            affectedRows,
            errMsg,
            duration,
            statement,
            success,
          } = item;
          return (
            <div className={styles.logItem}>
              【{++index}】
              <span className={styles.color999}>
                [{timestamp ? moment(timestamp).format("MM-DD HH:mm:ss") : ""}]
              </span>
              <span className={classnames(styles.ml20, styles.wordBreak)}>
                {statement}
              </span>
              <span className={styles.ml20}>耗时：{duration}ms</span>
              <span
                className={classnames(
                  {
                    [styles.color36b839]: success,
                    [styles.colorf00]: !success,
                  },
                  styles.ml20
                )}
              >
                {success ? "成功" : "失败"}
              </span>
              <span className={styles.ml20}>影响行数：{affectedRows}</span>
              {!success && (
                <Tooltip title={errMsg} mouseEnterDelay={0.5}>
                  <Typography.Text
                    ellipsis
                    className={styles.ml20}
                    style={{ width: 250 }}
                  >
                    失败原因：{errMsg}
                  </Typography.Text>
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  if (itemStepMark > currentStep)
    return <EmptyCardContent title="模拟环境验证" />;

  return (
    <Spin spinning={loading}>
      <Card
        title="模拟环境验证"
        className={styles['step-card']}
        id="simuleEnvironmentValidationCard"
        extra={
          itemStepMark <= currentStep &&
          ((activeKey === "executeLog" && logFileNames?.executeLogName) ||
            (activeKey === "fallbackLog" && logFileNames?.fallbackLogName)) && (
            <Button
              type="primary"
              disabled={_.isEmpty(logFileNames)}
              onClick={handleDownload}
            >
              导出
            </Button>
          )
        }
        actions={
          itemStepMark < currentStep || itemStepMark === currentStep
            ? [
                <div className={styles.cardActionsButton}>
                  <Button
                    className={styles.optionBtn}
                    type="primary"
                    onClick={() => handleValidate("executeLog")}
                    disabled={
                      !(
                        currentContextValue?.sqlStatement &&
                        !sqlBtnVisible &&
                        currentContextValue?.simulateNodePath
                      )
                    }
                  >
                    执行验证
                  </Button>
                  <Button
                    className={styles.optionBtn}
                    type="primary"
                    onClick={() => handleValidate("fallbackLog")}
                    disabled={
                      !(
                        currentContextValue?.rollbackSqlStatement &&
                        sqlBtnVisible &&
                        currentContextValue?.simulateNodePath
                      )
                    }
                  >
                    回退验证
                  </Button>
                  {currentRouteType === "MINE_APPLY" &&
                    itemStepMark === currentStep && (
                      <Button
                        type="primary"
                        onClick={() => {
                            setCurrentStep(currentStep + 1)
                            autoScrollToScreenCenter('submitApplicationCard')
                          }
                        }
                      >
                        下一步
                      </Button>
                    )}
                </div>,
              ]
            : []
        }
      >
        <Tabs activeKey={activeKey} onChange={handleActiveKeyChange}>
          <Tabs.TabPane key="executeLog" tab="执行日志">
            {renderLog(executionLog)}
          </Tabs.TabPane>
          <Tabs.TabPane key="fallbackLog" tab="回退日志">
            {renderLog(fallbackLog)}
          </Tabs.TabPane>
        </Tabs>
        <div className={styles.logListWrap}></div>
      </Card>
    </Spin>
  );
};
