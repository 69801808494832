import type { RuleObject } from "antd/lib/form";

export const nameValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|[A-Za-z0-9]|[_])*$/;
  if (!reg.test(value)) {
    return Promise.reject("只能包含中英文、数字和下划线");
  }
  return Promise.resolve();
};

/** 连接名/角色名的正则校验 */
export const looseNameValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|[A-Za-z0-9]|[_.:])*$/;
  if (value?.length > 50) {
    return Promise.reject("长度上限 50 个字符");
  }
  if (!reg.test(value)) {
    return Promise.reject("只能包含中英文、数字和特定符号（ . : _ ）");
  }
  return Promise.resolve();
};

// todo: 优雅重构
export const fileNameValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|[A-Za-z0-9]|[.])*$/;
  if (!value) return Promise.resolve();
  if (value.match(reg)) {
    return Promise.resolve();
  } else {
    return Promise.reject("只能包含中英文、数字和 .");
  }
};
export const folderNameValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|[A-Za-z0-9])*$/;
  if (!value) return Promise.resolve();
  if (value.match(reg)) {
    return Promise.resolve();
  } else {
    return Promise.reject("只能包含中英文和数字");
  }
};

export const validatorAccount = (_rule: RuleObject, value: string) => {
  const reg = /^[a-zA-Z][a-zA-Z0-9_]{0,35}$/;
  if (value.length > 36) {
    return Promise.reject("长度上限 36 个字符");
  }
  if (!reg.test(value)) {
    return Promise.reject("字母开头的字母、数字和下划线组合");
  }
  return Promise.resolve();
};

export const passwordValidator1 = (_rule: any, val: string, cb: any) => {
  const pwReg = /^(?=.*[a-z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^]{8,16}$/;
  if (!val) cb("请输入密码");
  if (!pwReg.test(val)) {
    cb("密码长度 8~16 字符，至少包含数字和大小写字母");
  }
  cb();
};

interface Params {
  passwordMax: number;
  passwordMin: number;
  containDigits: boolean;
  containUpperLetters: boolean;
  containLowerLetters: boolean;
  containSymbols: boolean;
}
// 动态生成正则表达式：密码强度
function generatePasswordRegex(params: Params): RegExp {
  const {
    passwordMax,
    passwordMin,
    containDigits,
    containUpperLetters,
    containLowerLetters,
    containSymbols,
  } = params;
  let pattern = "^";
  if (passwordMin && passwordMax && passwordMax > passwordMin) {
    // 长度限制
    pattern += `(?=.{${passwordMin},${passwordMax}}$)`;
    if (containDigits) {
      pattern += "(?=.*\\d)";
    }
    if (containUpperLetters) {
      pattern += "(?=.*[A-Z])";
    }
    if (containLowerLetters) {
      pattern += "(?=.*[a-z])";
    }
    if (containSymbols) {
      pattern += "(?=.*[!@#$%^&*?])";
    }
  }
  pattern += ".*$";
  return new RegExp(pattern);
}
function removeLastComma(str: string, mark: string): string {
  const lastIndex = str.lastIndexOf(mark);
  if (lastIndex !== -1) {
    return str.substring(0, lastIndex);
  }
  return str;
}
// 密码强度校验-自定义
export const passwordValidatorSelf = (
  _rule: any,
  val: string,
  cb: any,
  params: Params
) => {
  const {
    passwordMax,
    passwordMin,
    containDigits,
    containUpperLetters,
    containLowerLetters,
    containSymbols,
  } = params;
  if (!val) cb("请输入密码");
  if (
    !(
      passwordMax &&
      passwordMin &&
      (containDigits ||
        containUpperLetters ||
        containLowerLetters ||
        containSymbols)
    ) ||
    !(passwordMax > passwordMin)
  ) {
    return cb("请完善自定义规则");
  }
  let includeParam =
    (containDigits ? "数字、" : "") +
    (containLowerLetters ? "小写字母、" : "") +
    (containUpperLetters ? "大写字母、" : "") +
    (containSymbols ? "特殊字符、" : "");
  const pwReg = generatePasswordRegex(params);
  if (!pwReg.test(val)) {
    return cb(
      "密码长度 " +
        passwordMin +
        "~" +
        passwordMax +
        " 字符，至少包含" +
        removeLastComma(includeParam, "、")
    );
  }
  return cb();
};

// 校验特殊字符 CQ-4913
export const passwordSpecialCharacterValidator = (_rule: any, val: string) => {
  let specialCharacter = ['(', ')', '\\', '|', '[', ']', '{', '}', '/', '`', ',', '.', ';', '"', "'", '<', '>', '+', '-', '_', '·'],
    currentChar = '';
  for (let i = 0; i < specialCharacter.length; i++) {
    if (val.includes(specialCharacter[i])) {
      currentChar = specialCharacter[i];
      break;
    }
  }
  if (currentChar) {
    return Promise.reject(`您输入的字符中包含不合法特殊字符${currentChar}`);
  } else {
    return Promise.resolve();
  }
};

// 密码强度校验
export const passwordValidator = (_rule: any, val: string) => {
  const pwReg = [
    {
      name: "strong",
      formula:
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*?])[\da-zA-Z!@#$%^&*?]{9,16}$/,
      prompt: "",
    },
    {
      name: "medium",
      formula:
        /^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)(?![!@#$%^&*?]+$)[\da-zA-z!@#$%^&*?]{5,16}$/,
      prompt:
        "密码强度：中，密码长度9-16位，需包含数字、大写字母、小写字母、特殊字符（!@#$%^&*?）",
    },
    {
      name: "weak",
      formula: /^(?=.*[0-9A-Za-z!@#$%^&*]).{1,16}$/,
      prompt:
        "密码强度：弱，密码长度9-16位，需包含数字、大写字母、小写字母、特殊字符（!@#$%^&*?）",
    },
  ];
  if (!val) return Promise.reject("请输入密码");
  for (const item of pwReg) {
    if (item.formula.test(val)) {
      if (item.name === "strong") {
        return Promise.resolve();
      } else {
        return Promise.reject(item.prompt);
      }
    }
  }
  return Promise.reject(
    "不符合内置规则：强，密码长度9-16位，包含数字、大写字母、小写字母、特殊字符（!@#$%^&*?）"
  );
};

/* 不允许为中文 */
export const noChineseValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]/g;
  if (!value) return Promise.reject("请输入密码");
  if (reg.test(value)) {
    return Promise.reject("密码不允许包含中文");
  }
  return Promise.resolve();
};
/** 用户参数 参数名验证 中文、字母、数字、"_"、"#","-*/
export const userParameterValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|[A-Za-z0-9]|[_、#-])*$/;
  if (value?.length > 10) {
    return Promise.reject("长度上限 10 个字符");
  }
  if (!reg.test(value)) {
    return Promise.reject('参数名字只能中文、字母、数字、"_"、"#","-"');
  }
  return Promise.resolve();
};

/** 用户参数 参数标签验证 只能是字母 */
export const userParameterTagValidator = (_rule: RuleObject, value: string) => {
  const reg = /^(?:[a-zA-Z])*$/;
  if (value?.length > 10) {
    return Promise.reject("长度上限 10 个字符");
  }
  if (!reg.test(value)) {
    return Promise.reject("参数标签字只能是字母");
  }
  return Promise.resolve();
};

/** 邮箱校验规则 */
export const userEmailValidator = (_rule: any, val: string, cb: any) => {
  const reg = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  if (!val) cb("请输入合法的邮箱");
  if (!reg.test(val)) {
    cb("请输入合法的邮箱");
  }
  cb();
};

/** 手机号校验规则 */
export const userPhoneValidator = (_: any, value: any) => {
  if (!value) {
    return Promise.reject("请输入手机号码");
  }
  // 使用正则表达式验证手机号码格式
  const regex = /^1[3456789]\d{9}$/;
  if (!regex.test(value)) {
    return Promise.reject("请输入有效的手机号码");
  }
  return Promise.resolve();
};

/** 登录账号 参数名验证 中文、字母、数字、"_"、"#","- 长度2-20*/
export const userIdValidator = (_rule: RuleObject, value: string) => {
  const reg =
    /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]|[A-Za-z0-9]|[_、#-])*$/;
  if (!value) return Promise.reject("请输入登录账号");
  if (value?.length < 2 || value?.length > 20) {
    return Promise.reject("登录账号的长度在2-20");
  }
  if (!reg.test(value)) {
    return Promise.reject('登录账号只能中文、字母、数字、"_"、"#","-"');
  }
  return Promise.resolve();
};
