import React from 'react'
import { Tooltip } from 'antd'
import {
  getSpecialConnectionTypePermissions,
} from 'src/constants'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { getSdtThinPermissionIconType } from 'src/util';
import { applyStatusMap, priGranTypeMap } from '../constants'
import classNames from 'classnames'
import styles from "./index.module.scss";
import { Iconfont } from 'src/components'


export const columnsRequest: ColumnsType<any> = [
  {
    title: '申请单号',
    dataIndex: 'uuid',
    width: 200,
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: 200,
    render: (title, record) => {
      return <span>{title ? title : '-'}</span>
    }
  },
  {
    title: '类型',
    dataIndex: 'priGranType',
    width: 150,
    filters: Object.keys(priGranTypeMap).map((key) => ({
      text: priGranTypeMap[key],
      value: key,
    })),
    filterMultiple: false,
    render: (_, { priGranType }) => {
      return <span>{ priGranTypeMap[priGranType] ?  priGranTypeMap[priGranType] : '-'}</span>
    },
  },
  {
    title: '申请人',
    dataIndex: 'applyUserName',
    width: 150,
    render: (applyUserName, record) => (
      <div>
        {
          record?.applyUserName && record?.applyUserId ? 
          (record?.applyUserName + '(' + record?.applyUserId + ')') : 
          (
            record?.applyUserName ? record?.applyUserName : 
            (record?.applyUserId ? '(' + record?.applyUserId + ')' : '-')
          )
        }
        {/* <span>{applyUserName}</span>
        <span className={styles.dept}>{deptName}</span> */}
      </div>
    ),
  },
  {
    title: '状态',
    dataIndex: 'applyStatus',
    width: 100,
    render: (_, { applyStatus }) => {
      return <span>
        <span
          className={classNames(
            styles.statusDot,
            applyStatus === 'pending' && styles.pendingBack,
            (applyStatus === 'pass' || applyStatus === 'already') && styles.alreadyBack,
            applyStatus === 'power' && styles.powerBack,
            applyStatus === 'refuse' && styles.rejectBack,
            applyStatus === 'withdraw' && styles.rejectBack,
          )}
        ></span>
        {applyStatusMap[applyStatus]}
      </span>
    },
  },
  {
    title: '开始时间',
    dataIndex: 'beginTime',
    width: 200,
    render: (_, { beginTime }) => {
      return beginTime ? dayjs(beginTime).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    sorter: true
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    width: 200,
    render: (_, { endTime }) =>{
      return endTime ? dayjs(endTime).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
  },
  {
    title: '当前审批人',
    dataIndex: 'currentAssignee',
    width: 150,
    render: (currentAssigneeName, record) => (
        <div>
          {
            !record?.currentAssigneeName ?
                (!record?.currentAssignee ? '-': record?.currentAssignee)
                :
                (record?.currentAssigneeName + '(' + record?.currentAssignee + ')')
          }
        </div>
    ),
  },
  {
    title: '操作',
    dataIndex: 'actions',
    render: () => '-',
    width: 200,
    // align: 'center',
  },
]

export const columnsRequestExpandRow: ColumnsType<any> = [
  {
    title: '子工单号',
    dataIndex: 'uuid',
    key: 'uuid',
    width: 200,
  },
  {
    title: '资源数量',
    dataIndex: 'resourceCount',
    key: 'resourceCount',
  },
  {
    title: '连接数量',
    dataIndex: 'connectionCount',
    key: 'connectionCount',
  },
  {
    title: '落权人',
    dataIndex: 'connectionManager',
    key: 'connectionManager',
    render: (_: string, record: any) =>`${_} ${record?.connectionManagerId ? `(${record?.connectionManagerId})` : ''}`
  },
  {
    title: '状态',
    dataIndex: 'applyStatus',
    key: 'status',
    render: (_, { applyStatus }) => {
      return <span>
        <span
          className={classNames(
            styles.statusDot,
            applyStatus === 'pending' && styles.pendingBack,
            applyStatus === 'already' && styles.alreadyBack,
            applyStatus === 'power' && styles.powerBack,
            applyStatus === 'rejected' && styles.rejectBack,
            applyStatus === 'stop' && styles.rejectBack,
          )}
        ></span>
        {applyStatusMap[applyStatus]}
      </span>
    },
  },
]


export const columnsDetail: ColumnsType<any> = [
  {
    title: '连接名称',
    dataIndex: 'connectionName',
    render: (_, record) => {
      return <span>
        <Iconfont type={`icon-connection-${record?.connection?.connectionType}`} style={{ marginRight: 4 }} />
        {record?.connection?.connectionName}
      </span>
    }
  },
  {
    title: '实例名称',
    dataIndex: 'instanceName',
    render: (_, record) => {

      if (record?.connection?.connectionType === 'Redis') {
        return record?.connection?.userInputs?.connectionMembers?.map((item: any) =>item?.connectionUrl + ':' + item?.connectionPort )
      }
      return <span>
        {record?.connection?.userInputs?.connectionUrl + ':' + record?.connection?.userInputs?.connectionPort}
      </span>
    },
  },
  {
    title: '服务名',
    dataIndex: 'serverName',
    width: 100,
    render: (_, record) => {
      return <span>{(record?.connection?.userInputs?.serviceName) ? (record?.connection?.userInputs?.serviceName) : '-'}</span>
    }
  },
  {
    title: '版本号',
    dataIndex: 'version',
    render: (_, record) => {
      return <span>{record?.connection?.userInputs?.dataSourceVersion}</span>
    }
  },
  {
    title: '连接用户',
    dataIndex: 'connectionUser',
    render: (_, record) => {
      return <span>{record?.connection?.userInputs?.userName}</span>
    }
  },
  {
    title: '连接管理员',
    dataIndex: 'connectionAdminis',
    render: (_, record) => {
      return <span>
        {
          record?.connection?.managerName && record?.connection?.manager ? 
          (record?.connection?.managerName + '(' + record?.connection?.manager + ')') : 
          (
            record?.connection?.managerName ? record?.connection?.managerName : 
            (record?.connection?.manager ? '(' + record?.connection?.manager + ')' : '-')
          )
        }
      </span>
    }
  }
]

// 申请单 粗粒度
export const detailExpamdedRow: ColumnsType<any> = [
  {
    title: '资源名称',
    dataIndex: 'nodeName',
    key: 'nodeName',
    width: '20%',
    ellipsis: true
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: '20%',
    ellipsis: true,
    render: (_, record) => {
      return <span>{_ ? _ : '-'}</span>
    }
  },
  // { title: '分类', dataIndex: 'serveName', key: 'serveName', width: '10%', ellipsis: true },
  {
    title: '权限申请',
    dataIndex: 'permissionTypeName',
    key: 'permissionTypeName',
    width: '20%',
  },
  {
    title: '工具权限',
    dataIndex: 'toolsAuth',
    key: 'toolsAuth',
    width: '20%',
    ellipsis: true
  },
  {
    title: '角色权限',
    dataIndex: 'roleName',
    key: 'roleName',
    width: '10%',
    ellipsis: true
  },
  { title: '', dataIndex: 'actions', key: 'actions', width: '10%', ellipsis: true },
]

// 申请单 细粒度
export const detailFineGritExpamdedRow: ColumnsType<any> = [
  { title: '对象名称', dataIndex: 'nodeName', key: 'nodeName', width: '20%', ellipsis: true },
  {
    title: '类型',
    dataIndex: 'groupName',
    key: 'groupName',
    // render: (_, { flowType }) => priGranTypeMap[flowType],
    width: '10%'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    width: '20%',
    ellipsis: true,
    render: (_, record) => {
      return <span>{_ ? _ : '-'}</span>
    }
  },
  {
    title: '注释',
    dataIndex: 'connectionNum',
    key: 'connectionNum',
    width: '20%',
    ellipsis: true,
    render: (_, record) => {
      return <span>{_ ? _ : '-'}</span>
    }
  },
  {
    title: '权限申请',
    dataIndex: 'sourceOperationList',
    key: 'sourceOperationList',
    width: '20%',
    ellipsis: true,
    render: (sourceOperationList, record) => {

      let aa = getSpecialConnectionTypePermissions(record?.dataSourceType)[record?.nodeType]?.map((type: string) => {
        let permissionTypeStatus = sourceOperationList[type]
        const permissionType = getSdtThinPermissionIconType(type);
        let iconType = permissionTypeStatus === '1' ? `icon-type-${permissionType}` : (
          permissionTypeStatus === '0' ? `icon-type-${permissionType}_disabled` : `icon-type-${permissionType}_add`
        )
        return (
          <Tooltip title={type} key={type}>
            <Iconfont
              type={iconType}
              className={styles.permissionLimitIcon}
            />
          </Tooltip>
        )
      })
      return aa
    }
  },
  // { title: '', dataIndex: 'actions', key: 'actions' },
]

export const resourceExpamdedRow: ColumnsType<any> = [
  { title: '资源名称', dataIndex: 'nodeName', key: 'nodeName', width: 200, ellipsis: true },
  { title: '备注', dataIndex: 'remark', key: 'remark', width: 300, ellipsis: true },
  { title: '权限等级申请', dataIndex: 'authLevel', key: 'authLevel'},
  { title: '工具权限申请', dataIndex: 'toolsAuth', key: 'toolsAuth', width: 160, ellipsis: true },
  { title: '操作', dataIndex: 'actions', key: 'actions' }
]


