import React from 'react'
import { Popover, Space, Steps } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { renderStatusTag } from '../utils'
import { ApplyStatus } from 'src/types'
import classNames from 'classnames'
import styles from './flowDetail.module.scss'

dayjs.extend(relativeTime)

type StepsStatus = 'wait' | 'process' | 'finish' | 'error'
type ProcessSteps = {
  label: string
  time?: string
  userName?: string
  status?: string
}[]

export const OldFlowProcess = ({ steps }: { steps?: ProcessSteps }) => {
  const customDot = (
    dot: React.ReactNode,
    { status, index }: { status?: StepsStatus; index: number },
  ) => {
    if (status === 'error') {
      return (
        <div
          className={classNames(styles.processDot, styles.processDotError)}
        ></div>
      )
    }
    if (status === 'finish') {
      return (
        <CheckCircleFilled
          className={classNames(styles.processDot, styles.processDotFinish)}
        />
      )
    }
    if (status === 'process') {
      const relativeTime = dayjs().from(dayjs(steps?.[index - 1]?.time), true)
      return (
        <Popover
          content={
            <div className={styles.processNodePopover}>
              <div className={styles.processNode}>
                <span>{steps?.[index].userName}</span>
                <ul className={styles.processNodeStatus}>
                  <li>未响应</li>
                </ul>
              </div>
              <div
                className={styles.processNodeDuration}
              >{`耗时：${relativeTime}`}</div>
            </div>
          }
        >
          {dot}
        </Popover>
      )
    }
    return dot
  }

  const lastPassed = steps?.map(({ status }) => status).lastIndexOf('pass')

  return (
    <Steps
      className={styles.steps}
      size="small"
      progressDot={customDot}
      current={Number(lastPassed) + 1}
    >
      {steps?.map(({ label, time, userName, status: _status }, index) => {
        const status = _status as ApplyStatus
        let stepStatus: StepsStatus | undefined
        switch (status) {
          case 'refuse':
            stepStatus = 'error'
            break
          case 'pass':
            stepStatus = 'finish'
            break
          default:
            break
        }

        return (
          <Steps.Step
            key={index}
            status={stepStatus}
            description={
              <div
                className={classNames(
                  styles.stepItem,
                  index > (lastPassed || 0) + 1 && styles.stepItemWait,
                  stepStatus === 'error' && styles.stepItemError,
                )}
              >
                <div className={classNames(styles.stepTitle)}>{label}</div>
                <div className={styles.stepContent}>
                  <Space>
                    {userName && <span>{userName}</span>}
                    {index !== 0 &&
                      status !== 'pending' &&
                      renderStatusTag(status)}
                  </Space>
                  {time && (
                    <div className={styles.stepTime}>
                      {dayjs(time).format('YYYY-MM-DD HH:mm')}
                    </div>
                  )}
                </div>
              </div>
            }
          />
        )
      })}
    </Steps>
  )
}
