import {
	Button,
	message,
	Space,
	Table,
	Tag,
	Switch,
	Modal,
	Input,
  Tooltip
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState, useMemo } from 'react';
import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import {
	deleteDesensResource,
	getDesensitizeResourceList,
	permissionSupport,
	setDesensitizeResourceStatus,
	ViewType
} from 'src/api/dataProtection';
import { getRowFilterTableInfo } from 'src/api/rowFilter';
import { useDispatch, useRequest, useSelector } from 'src/hook';
import { showModal } from 'src/store/extraSlice/modalVisibleSlice';
import { setColumnType, setNodePathOfColumn } from '../../DataProtectionPageSlice';
import { EditDesensitization } from '../Dialog/EditDesensitization';
import { formatRuleName } from '../utility';
import { setDesensRuleSettingObj } from '../../DataProtectionPageSlice'
import styles from './index.module.scss';
import { getCurrentModulePermissionByUrl } from 'src/util/utils';
import classnames from 'classnames';

interface TableType {
	columnName: string;
	comments: string;
	dataType: string;
	example: string;
	ruleName?: string;
	nodePathWithType: string;
	desensitizeable?: boolean;
}

interface DesensitizeType {
	id: number;
	connectionName: string;
	nodePath: string;
	columnName: string;
	ruleName: string;
	ruleParam: string;
	status: boolean;
	desensitizeable?: boolean;
}

const { confirm} = Modal;
/**
 * 渲染 table 列信息
 * @returns ColumnsType<DataType>
 */
const RenderTableColumns = ({
	refresh
}: {
	refresh: () => Promise<any>
}, permissionSupportData: boolean,
  permissionlist: {
    isOnlyRead: boolean
    roleNameList: string[]
  }): ColumnsType<TableType> => {
	const dispatch = useDispatch();

	return [
		{
			dataIndex: "columnName",
			key: "columnName",
			title: "字段名",
			ellipsis: true,
		},
		{
			dataIndex: "comments",
			key: "comments",
			title: "注释",
			ellipsis: true,
		},
		{
			dataIndex: "dataType",
			key: "dataType",
			title: "字段类型",
			ellipsis: true,
		},
		{
			key: "ruleName",
			dataIndex: 'ruleName',
			title: "脱敏规则",
            render: (val: string) => val ? formatRuleName(val) : '-'
		},
		{
			dataIndex: "example",
			key: "example",
			title: "数据样例",
			ellipsis: true,
		},
		{
			key: "action",
			title: "操作",
			render(value, record) {
				return  (
					<Space>
						{
							record.ruleName ?
                <Tooltip title={
                  !permissionSupportData?
                  `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
                  : null
                  } 
                >
                  <span
                    className={classnames(styles.linkBtn, !permissionSupportData? styles.disabled : '')}
                    onClick={() => {
                      if (!permissionSupportData) return 
                      dispatch(showModal("CommonEditDesensRulesModal"));
                      dispatch(
                        setDesensRuleSettingObj({
                          refresh,
                          editRecord: record,
                        })
                      );
                    }}
                  >
                    编辑规则
                  </span>
                </Tooltip>
                :
                <Tooltip title={
                  !permissionSupportData?
                  `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
                  : null
                  }
                >
                  <span
                    className={classnames(record?.desensitizeable ? styles.linkBtn: styles.disabledBtn, 
                      !permissionSupportData? styles.disabled : '',
                      )}
                    onClick={() =>  {
                      if (!record?.desensitizeable || !permissionSupportData) return 
                      dispatch(setColumnType(record.dataType));
                      dispatch(setNodePathOfColumn(record.nodePathWithType));
                      dispatch(showModal("DesensitizationDialog"));
                    }}
                  >
                    设置规则
                  </span>
                </Tooltip>
						}
					</Space>
				)
			},
		},
	];
};

/**
 * 渲染脱敏规则列信息
 */
const RenderDesensitizeColumns = ({ refresh }: { refresh: () => Promise<any> },
  permissionSupportData: boolean,
  permissionlist: {
    isOnlyRead: boolean
    roleNameList: string[]
  }): ColumnsType<DesensitizeType> => {
	const dispatch = useDispatch();

	const { run: setStatus } = useRequest(setDesensitizeResourceStatus, {
		manual: true,
	});

	const { run: deleteResource } = useRequest(deleteDesensResource, {
		manual: true,
		onSuccess() {
			message.success("删除成功");
		},
	});

	async function handleSetStatus(record: DesensitizeType) {
		await setStatus({
			id: [record.id],
			status: !record.status,
		});
		refresh();
	}
	const showDeleteConfirm = (record: any) => {
		confirm({
		  title: '确定删除?',
		  icon: <ExclamationCircleFilled />,
		  async onOk() {
			await deleteResource(record.id);
		refresh();
		  },
		});
	  };


	return [
		{
			dataIndex: "columnName",
			key: "columnName",
			title: "字段名",
			ellipsis: true,
		},
		{
			dataIndex: "ruleName",
			key: "ruleName",
			title: "脱敏规则",
			ellipsis: true,
			render(value) {
				return formatRuleName(value);
			},
		},
		{
			dataIndex: "ruleParam",
			key: "ruleParam",
			title: "算法",
			ellipsis: true,
		},
		{
			dataIndex: 'source',
			title: '来源',
		  },
		{
			dataIndex: "status",
			key: "status",
			title: "状态",
			render: (value, record) =>
      <Tooltip title={
        !permissionSupportData?
        `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
        : null
        }
      >
        <Switch 
          checked={value ? true : false}
          checkedChildren='启用' 
          unCheckedChildren='关闭'
          onChange={(checked: boolean) => {
            handleSetStatus(record)
          }}
        disabled={!permissionSupportData}
        />
      </Tooltip>
      ,
		},
		{
			dataIndex: "options",
			key: "options",
			title: "操作",
			render(value, record) {
				return <Space size='small' align='start'>
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          }
        >
          <span className={classnames(styles.linkBtn, !permissionSupportData? styles.disabled : '')}
            onClick={() => {
              if (!permissionSupportData) return
              dispatch(showModal('CommonEditDesensRulesModal'))
              dispatch(setDesensRuleSettingObj({
                refresh,
                editRecord: record
              }))
            }} >
            编辑
          </span>
        </Tooltip>
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${permissionlist?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          }
        >
          <span className={classnames(styles.deleteBtn, !permissionSupportData? styles.disabled : '')}
            onClick={() => {
              if (!permissionSupportData) return
              showDeleteConfirm(record)
            }}
          >
            删除
          </span>
        </Tooltip>
				</Space>
			  },
		},
	];
};

const TableDesensitize = (): JSX.Element => {
	const { selectedNode, selectedNodePermissionInfo } = useSelector(state => state.dataProtection);
  const {modulePermissionObj, permissionSupportData } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

	const [searchParams, setSearchParams] = useState<string>();

	const {
		run: getResourceList,
		loading: secondTableLoading,
		data: resourceList,
		refresh: refreshRuleTable,
	} = useRequest(getDesensitizeResourceList, {
		manual: true,
	});

	const {
		run: getTableInfo,
		loading: firstTableLoading,
		data: tableInfoData,
		refresh: refreshInfoTable,
	} = useRequest(getRowFilterTableInfo, {
		manual: true,
	});

	function generateTableData() {
		if (!tableInfoData || tableInfoData.length === 0) {
			return;
		}

		return tableInfoData.map((tableInfo: { nodePathWithType: string; nodeOptions: any; nodeName: string; desensitizeable?: boolean }) => {
			const target = resourceList?.find((resource: { nodePath: string }) => {
				return tableInfo.nodePathWithType === resource.nodePath;
			});

			if (target) {	
				return {
					columnName: tableInfo.nodeName,
					comments: tableInfo.nodeOptions.comments,
					dataType: tableInfo.nodeOptions.dataType,
					example: tableInfo.nodeOptions.example,
					isNullable: tableInfo.nodeOptions.isNullable,
					nodePathWithType: tableInfo.nodePathWithType,
					ruleName: target.ruleName,
					ruleParam: target.ruleParam,
					algoParam: target.algoParam || {},
					columnType: target.columnType,
					id: target.id,
					desensitizeable: tableInfo?.desensitizeable
				};
			} else {
				return {
					columnName: tableInfo.nodeName,
					comments: tableInfo.nodeOptions.comments,
					dataType: tableInfo.nodeOptions.dataType,
					example: tableInfo.nodeOptions.example,
					isNullable: tableInfo.nodeOptions.isNullable,
					nodePathWithType: tableInfo.nodePathWithType,
					desensitizeable: tableInfo?.desensitizeable
				};
			}
		});
	}

	function refreshAll() {
		return Promise.all([refreshInfoTable(), refreshRuleTable()]);
	}

	useEffect(() => {
		if (selectedNode) {
			const { connectionId, nodePath, nodePathWithType, nodeType: findType, connectionType, nodeName } = selectedNode.props;

			getResourceList({
				connectionId: +connectionId as number,
				nodePath: nodePathWithType,
				findType: findType as ViewType,
			});

			getTableInfo({
				connectionId,
				connectionType,
				nodeType: findType,
				nodeName,
				nodePath,
				nodePathWithType: nodePathWithType,
			});
		}
	}, [getResourceList, getTableInfo, selectedNode]);

	const filteredDesensFieldList = useMemo(() => {
		const list = generateTableData();
      if (!searchParams) return list;
	   return list?.filter((item: any) => item?.columnName?.toLowerCase().includes(searchParams?.toLowerCase()))
	},[generateTableData(), searchParams])

	return (
		<>
		 <div className={styles.desensFieldHeader}>
				<div className={styles.title}>脱敏规则管理</div>
				<Input
				  allowClear
					prefix={<SearchOutlined />}
					placeholder="搜索字段名"
					className={styles.inputSearch}
					onChange={(e: any) => setSearchParams(e.target.value)}
				/>
		 </div>
			<Table
				style={{ marginBottom: "40px" }}
				columns={RenderTableColumns({refresh: refreshRuleTable}, permissionSupportData, modulePermissionObj)}
				loading={firstTableLoading}
				dataSource={filteredDesensFieldList}
				pagination={false}
				scroll={{y: `calc(100vh / 2  - 60px)`}}
			></Table>
			<div>
			<div className={styles.title}>脱敏字段详情</div>
			<Table
				style={{ marginBottom: "80px",marginTop: 16 }}
				columns={RenderDesensitizeColumns({ refresh: refreshRuleTable }, permissionSupportData, modulePermissionObj)}
				loading={secondTableLoading}
				dataSource={resourceList}
				pagination={false}
			></Table>
			</div>
		
			<EditDesensitization refresh={() => refreshAll()} />
		</>
	);
};

export { TableDesensitize };

