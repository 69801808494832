/*
 * @Author: yangchao yangchao@bintools.cn
 * @Date: 2023-09-19 13:55:28
 * @LastEditors: yangchao yangchao@bintools.cn
 * @LastEditTime: 2023-09-21 10:16:33
 * @FilePath: /cq-enterprise-frontend/src/pageTabs/queryPage/resultTabs/resultLogs/ResultLogs.tsx
 * @Description: 
 */
import React, { useRef, useEffect, memo, useState } from 'react'
import { VariableSizeList } from 'react-window'
import { useDispatch, useResizeObserver, useSelector } from 'src/hook'
import { QueryLogItem } from 'src/types'
import { LogMessage, OberserverContext } from './LogMessage'
import styles from './index.module.scss'
import { setResultPanelHeight } from '../resultTabsSlice'
import { calculateRowHeight } from 'src/util'

interface ResultLogProps {
  queryKey: string
}

export const ResultLogs: React.FC<ResultLogProps> = memo(({ queryKey }) => {
  const tabLog = useSelector((state) => state.logs[queryKey])
  const panelHeight = useSelector((state) => state.resultTabs.height)
  const dispatch = useDispatch()

  const [ lastMessageId, setLastMessageId ] = useState<string | undefined>('');
  
  const [rect, ref] = useResizeObserver()
  const sizeMapRef = useRef(new Map<number, number>())
  const listRef = useRef<VariableSizeList>(null)
  // 去掉监听 元素变化事件
  //（observer 监听的元素发生频繁的大小变化，
  // 有大量的日志元素需要监听，可能会对性能产生一定的影响，导致页面卡顿或者白屏现象）
  const resizeObserverRef = useRef(
    new ResizeObserver((entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.getAttribute('data-index'))
        const height = entry.target.getBoundingClientRect().height
        if (height) {
          sizeMapRef.current.set(index, height)
          listRef.current?.resetAfterIndex(index)
        }
      })
    }),
  )

  useEffect(() => {
    if (tabLog) {
      const { logs } = tabLog
      if (logs[logs.length-1].messageId) {
        setLastMessageId(logs[logs.length-1].messageId)
      }
      // 滚动到最后一条索引
      listRef.current?.scrollToItem(logs.length - 1, 'end')
    }
    return () => {
      listRef.current?.resetAfterIndex(0)
    }
  }, [tabLog, queryKey])

  /** 
   * resize之后保存当前高度，防止切换到其他页面然后再切换回来时高度
   * 重新计算造成log渲染卡顿问题
   */
  useEffect(() => {
    if (rect && rect.height) {
      dispatch(setResultPanelHeight(rect.height))
    }
  }, [rect])

  const getItemSize = (index: number): number => {
    if (tabLog) {
      let tabLogHeight = 50
      const {error, executionWarning, success} = tabLog.logs[index]

      if (success && !executionWarning && !error) {
        return tabLogHeight
      }

      let text = ""
      let height = 0
      // 异常信息，警告信息
      if (error && executionWarning) {
        text = error + executionWarning
        height  = calculateRowHeight(text, '800px',  100)
      } else {
        text = error? error : executionWarning
        height  = calculateRowHeight(text, '800px',  70)
      }

      return height
    }
    return 0
  }
  
  return (
    <div ref={ref} className={styles.logWrapper}>
      {/* <OberserverContext.Provider
        value={{
          sizeMap: sizeMapRef.current,
          observer: resizeObserverRef.current,
        }}
      > */}
        <VariableSizeList
          className={styles.logContent}
          ref={listRef}
          itemData={tabLog?.logs}
          itemCount={tabLog?.logs.length || 0}
          estimatedItemSize={40}
          // itemSize={(index) => sizeMapRef.current.get(index) || 40}
          itemSize={(index)=>getItemSize(index)}
          height={(panelHeight ?? rect?.height) - 20} // 20px for overflow
          width="100%"
        >
          {({ style, index, data }) => {
            const logs = data as QueryLogItem[]
            const offset = (tabLog?.total || 0) - logs.length
            return (
              <div style={style}>
                <LogMessage 
                  key={index}
                  log={logs[index]} 
                  lastMessageId={lastMessageId}
                  index={index} 
                  offset={offset} 
                  queryKey={queryKey}
                />
              </div>
            )
          }}
        </VariableSizeList>
      {/* </OberserverContext.Provider> */}
    </div>
  )
})
