import React, { useState } from "react";
import { Button, Input, Layout } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from 'src/hook'
import { ErrorBoundary, SimpleBreadcrumbs } from "src/components";
import { MainContent } from "./MainContent/MainContent";
import styles from "./index.module.scss";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;

const BREADCRUMB_ITEMS = [
	{
		title: "静态脱敏",
    key: "static"
	},
	{
		title: "任务记录",
    key: "record"
	},
];

export const TransferTaskRecord = () => {
	const history = useHistory();

  const [searchValue, setSearchValue] = useState('');

	function handleCreateTask() {
		history.push("/create-transfer-task");
	}

  const handleInputChange = (e: { persist: () => void; target: { value: React.SetStateAction<string> } })=> {
    setSearchValue(e?.target?.value)
  }

	const { roles=[] } = useSelector((state) => state.login.userInfo);

	// 只有高级用户和DBA角色可以创建任务
	const canCreateTask = roles?.map(i=>i?.type)?.includes('SENIOR_ROLE') || 
                        roles?.map(i=>i?.type)?.includes('DBA_ROLE') || 
                        roles?.map(i=>i?.type)[0].startsWith("CUSTOM_SYSTEM")
	return (
		<Layout className={styles.dataTransferPageWrap}>
			<div className={styles.header}>
				<SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <div>
          <Input
            style={{ marginRight: "10px", width: "200px" }}
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="搜索名称"
            value={searchValue}
            onChange={handleInputChange}
            allowClear
          />
          <Button
            style={{ textAlign: "right" }}
            type="primary"
            onClick={handleCreateTask}
            disabled={!canCreateTask}
          >
            创建任务
          </Button>
        </div>
			</div>
			<Layout className={styles.mainWrap}>
				<Content>
					<ErrorBoundary>
            <MainContent searchValue={searchValue} />
					</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};
