import React from 'react'
import { Form, Select, Input, Checkbox, Radio, Tooltip, Space } from 'antd'
import { WizardItem } from 'src/api'
import { FormItemProps } from 'antd/lib/form'
import { FormTailLayout } from 'src/constants'
import { looseNameValidator } from 'src/util'
import { ConnectionMembersTable } from 'src/features/wizards/wizardFormItem/ConnectionMembersTable'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface IWizardFormProps {
  spec: Partial<WizardItem>
  labelWithTooltip?: boolean
}

const GenerateFormItem = ({
  spec,
  labelWithTooltip,
}: IWizardFormProps) => {
  const { type, label, field, required, options, suffix, value, hide, disabled } = spec
  const labelWithTips = (
    <Space size="small">
      {label}
      {suffix && (
        <Tooltip title={suffix}>
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </Space>
  )
  const formProps: FormItemProps = {
    label: labelWithTooltip ? labelWithTips : label,
    name: field,
    initialValue: value,
    rules: [{ required, message: `${label}不能为空` }],
  }

  const getFormItemContent = () => {
    switch (type) {
      case 'checkbox':
        formProps.valuePropName = 'checked'
        return <Checkbox disabled={disabled}>{suffix}</Checkbox>
      case 'select':
        const SelectOptions = options?.map(({ key, title }: any) => ({
          label: title,
          value: key,
        }))
        return (
          <Select
            disabled={disabled}
            placeholder={`请选择${label}`}
            showSearch
            optionFilterProp="label"
            options={SelectOptions}
            allowClear
          ></Select>
        )
      case 'radio':
        return (
          <Radio.Group disabled={disabled}>
            {options?.map(({ key, title }: { key: string; title: string }) => (
              <Radio key={key} value={key}>
                {title}
              </Radio>
            ))}
          </Radio.Group>
        )
      case 'password':
        return (
          <Input.Password
            disabled={disabled}
            visibilityToggle={false}
            placeholder={`请输入${label}`}
            autoComplete="off"
          ></Input.Password>
        )
      case 'tag':
        return (
          <Select placeholder={`请选择或添加${label}`} mode="tags" disabled={disabled}>
            {options?.map(({ key, title }: any) => (
              <Select.Option key={key} value={key}>
                {title}
              </Select.Option>
            ))}
          </Select>
        )
      case 'textarea':
        return <Input.TextArea placeholder={`请输入${label}`} disabled={disabled}></Input.TextArea>
      case 'table':
        // todo: 响应值格式统一之后去掉
        if (!(value instanceof Array)) {
          formProps.initialValue = []
        }
        return <ConnectionMembersTable />
      // connectionUrlView是自动生成的，所以不需要这个输入文案提示
      default:
        return <Input autoComplete="off" placeholder={field === 'connectionUrlView' ? '' : `请输入${label}`} disabled={disabled} />
    }
  }

  const Content = getFormItemContent()
  const style = hide ? { display: 'none' } : {}

  // ! anti pattern. 暂时处理，需要和后端约定配置化表单的 schema
  if (field === 'connectionName') {
    formProps.rules?.push({ validator: looseNameValidator })
  }

  return label ? (
    <Form.Item {...formProps} key={String(formProps.name)} style={style}>
      {Content}
    </Form.Item>
  ) : (
    <Form.Item
      {...formProps}
      key={String(formProps.name)}
      {...FormTailLayout}
      style={style}
    >
      {Content}
    </Form.Item>
  )
}
export default GenerateFormItem