import { Alert, Form, Input, InputNumber, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { saveFlowApply } from 'src/api'
import { Iconfont, UIModal } from 'src/components'
import { useSelector } from 'src/hook'

interface IProps {
  data: any,
  exportForm: any,
  object: string,
  visible: boolean,
  setVisible: (b: boolean) => void
  [p: string]: any
}


export const ExportApplyModal = (props: IProps) => {

  const { data, exportForm, object, visible, setVisible, exportType } = props
  const { userInfo } = useSelector((state) => state.login)
  const [exportApplyForm] = Form.useForm()
  const { TextArea } = Input

  const [nodeInfo ,setNodeInfo] = useState<{nodeType: any, nodeName: any}>()
  const [exportFormData, setExportFormData] = useState<any>(exportForm.getFieldsValue())
  
  const handleOk = () => {
    exportApplyForm.validateFields().then((values) => {
      const {
        applyReason,
        executeText,
        exportNumLimit,
        title,
      } = values
      const {
        userId,
        email
      } = userInfo
      const {
        connectionId,
        dataSourceType, 
        connectionType, //sdt
        databaseName,
        operatingObject, //schemaName
        nodePathWithType, //sdt
      } = data

      let params = {}
      switch (object) {
        case "execute":
          params = {
            fileName: exportFormData?.fileName,
            applyUserId: userId,
            email,
            title,
            executeText,
            exportNumLimit,
            exportFormat: exportFormData?.exportFormat,
            remark: applyReason,
            applyReason,
            connectionId,
            dataSourceType,
            nodePathWithTypeList: data?.permissionResult?.allObjectPathSting,
            databaseName,
            schemaName: operatingObject,
            flowType: "exportTask",
            priGranType: "exportTask",
            useCartDataThinTiling: false,
            exportClearText: data?.permissionResult?.exportClearText
          }
          break
        case "sdtRightClick":
          params = {
            fileName: exportFormData?.fileName,
            applyUserId: userId,
            email,
            title,
            remark: applyReason,
            applyReason,
            exportNumLimit,
            connectionId,
            dataSourceType: connectionType,
            nodePathWithTypeList: [nodePathWithType],
            dumpType: exportFormData.dumpType,  // 导出类型
            flowType: "exportTask",
            priGranType: "exportTask",
            useCartDataThinTiling: false,
            exportClearText: false // 待确定是哪个值
          }
          break
      }
      
      saveFlowApply(params).then((res: any) => {
        message.success("导出申请提交成功，您可以在流程中查看申请进度")
        setVisible(false)
      }).catch((err: any) => {
        message.error("导出申请提交失败")
        console.log('导出申请提交 失败 :>> ', err);
      })
    })
    
  };

  useEffect(() => {
    const {
      exportDataNum,
    } = exportFormData

    const {permissionResult, statementObject, statement} = data
    let nodeType: string = ''
    let nodeName: string = ''
    switch (object) {
      case "execute":
        nodeType = permissionResult?.allObjectPathSting?.[0]?.split("/")?.filter((item: string) => {
          if (statementObject?.includes('.')) {
            if (statementObject && item.includes(statementObject?.split(".")[statementObject?.split(".").length-1])) {
              return true
            }
            return false
          } else {
            if (statementObject && item.includes(statementObject)) {
              return true
            }
            return false
          }
        })
        nodeType = nodeType?.[0]?.split(":")?.[0]?.toLowerCase()
        nodeName = statementObject
        break
      case "sdtRightClick":
        nodeType = data.nodeType
        nodeName = data.nodeName
        break
    }
    
    setNodeInfo({
      nodeType: nodeType,
      nodeName: nodeName
    })
    exportApplyForm.setFieldsValue({
      exportDataNum,
      applyUserId: userInfo?.userId,  // 申请人
      databaseElement: nodeName,  // 数据库元素
      executeText: statement, // 导出SQL
      exportNumLimit: exportDataNum,  // 导出行数
    })
  }, [data])

  return (
    <UIModal
      title="导出申请"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleOk}
      okText="提交"
      afterClose={() => exportApplyForm.resetFields()}
      width={526}
    >
      <Form
        form={exportApplyForm}
        labelCol={{span: 5}}
        wrapperCol={{span: 16}}
      >
       <Form.Item
          name="applyUserId"
          label="申请人"
        >
          <Input
            disabled={true}
            style={{ width: '100%', color: 'black' }}
            bordered={false}
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: '请填写标题' }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="databaseElement"
          label="数据库元素"
          rules={[{ required: true}]}
        >
          <Input
            disabled={true}
            prefix={<Iconfont type= {`${`icon-${nodeInfo?.nodeType}`
            } `} />}
          />
        </Form.Item>
        {
          object === "execute" &&
          <Form.Item
            name="executeText"
            label="导出SQL"
            rules={[{ required: true}]}
          >
            <Input
              disabled={true}
              style={{ width: '100%' }}
            />
          </Form.Item>
        }
        {
          ( (object=== 'execute' && exportType !== "allExport") || (object ==='sdtRightClick' &&  exportFormData.dumpType !== 'DUMPSTRUCTURE')) &&
          <Form.Item
            name="exportNumLimit"
            label="导出行数"
            rules={[{ required: true}]}
          >
            <InputNumber
              disabled={true}
              min={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        }
        <Form.Item
          name="applyReason"
          label="申请原因"
          rules={[{ required: true, message: '请填写申请原因' }]}
        >
          <TextArea style={{ width: '100%' }} rows={4} />
        </Form.Item>
        <Alert
            message="导出申请通过后，会自动创建导出任务，您可在任务中心/申请详情中下载"
            type="warning"
            showIcon
            style={{ fontSize: 12 }}
          />
      </Form>
    </UIModal>
  )
}