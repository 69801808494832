import {  DataSourceTypes } from 'src/api'
import { Range } from 'monaco-editor'
import { useSelector } from 'src/hook';

export const getCustomDataSouceType = (type: string) => {
  const customType = DataSourceTypes.find(
    (val) => val.toUpperCase() === type,
  )
  return customType
}

export const sleep = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, duration)
  })
}

// 计算 以选中文本为基础的起始行列在编辑器中的全局文本中对应的 起始行列
export const calculateErrorPositionInFullText = (
  fullModelRang: Range,
  selectRange: any,
  errorPosition: {
    startLineNumber: any,
    endLineNumber: any,
    startColumn: any,
    endColumn: any
  }
) => {
  let newSelectRange = {
    startLineNumber: selectRange.startLineNumber,
    endLineNumber: selectRange.endLineNumber,
    startColumn: selectRange.startColumn,
    endColumn: selectRange.endColumn,
  }
  
  let newErrorPosition = JSON.parse(JSON.stringify(errorPosition))

  if (newSelectRange.startLineNumber === newSelectRange.endLineNumber) {
    if (newSelectRange.startColumn > newSelectRange.endColumn) {
      let tmp = newSelectRange.startColumn
      newSelectRange.startColumn = newSelectRange.endColumn
      newSelectRange.endColumn = tmp
    }
  } else if (newSelectRange.startLineNumber > newSelectRange.endLineNumber) {
    let tmp = newSelectRange.startColumn
    newSelectRange.startColumn = newSelectRange.endColumn
    newSelectRange.endColumn = tmp
    tmp = newSelectRange.startLineNumber
    newSelectRange.startLineNumber = newSelectRange.endLineNumber
    newSelectRange.endLineNumber = tmp
  }

  if (newSelectRange.startLineNumber>0) {
    newErrorPosition.startLineNumber = newErrorPosition.startLineNumber + (newSelectRange.startLineNumber-fullModelRang.startLineNumber)
    newErrorPosition.startColumn = newErrorPosition.startColumn + (newSelectRange.startColumn-fullModelRang.startColumn)
    newErrorPosition.endLineNumber = newErrorPosition.endLineNumber + (newSelectRange.startLineNumber-fullModelRang.startLineNumber)
    newErrorPosition.endColumn = newErrorPosition.endColumn + (newSelectRange.startColumn-fullModelRang.startColumn)
  }
  return newErrorPosition;
}

//细粒度提权图标类型处理
export const getSdtThinPermissionIconType = (type: string) => {
  if (!type) return '';
  //default CREATE_TRIGGER
  let permissionType = type.split('_')[0];
  //CQ_OPEN_TRIGGER 
  if (type.startsWith('CQ_')) {
    permissionType = type.split('_')[1];
  }
  return permissionType?.toLocaleLowerCase();
}

//根据系统角色权限来禁掉页面一些操作
export const getCurrentModulePermissionByUrl = (permissionCollection: any,menuType: string  ): {
    isOnlyRead: boolean;
    roleNameList: string[];
    roleTypeList: string[];
} => {
  
  if (!permissionCollection || !menuType) return {isOnlyRead: false, roleNameList: [], roleTypeList: []};
  //路由和菜单映射找到对应权限     // 判断 该登录权限下,该模块 只读/可编辑
  const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionCollection)
  if ( !!permissionCollection?.[menuType] && !Object.keys(permissionCollection?.[menuType]).includes(pathName.toString())) {
    return permissionCollection?.[menuType]
  }
  return permissionCollection?.[menuType]?.[pathName]
}

/**
 * 计算给定文本的高度
 * (text: 给定文本，dist：文本渲染后bottom增加的高度 )
 * return 给定文本的渲染高度
*/
export const calculateRowHeight = (text: string, width: string, dist: number) : number => {
  const div = document.createElement('div')
  div.style.position = 'absolute'
  div.style.visibility = 'hidden'
  div.style.height = 'auto'
  div.style.width = width

  div.innerText = text
  document.body.appendChild(div)
  
  const height = div.offsetHeight + dist
  document.body.removeChild(div)

  return height
}

export const renderErrorFlag = (flag: number) => {
  switch (flag) {
    case 1:
      return '执行成功';
    case 2:
      return '解析失败';
    case 3:
      return '鉴权失败';
    case 4:
      return '执行失败';
    case 5:
      return '审核失败';
  }
  return '失败';
}
/**
 * 判断数组中是否存在指定元素，若存在，则删除数组指定元素，返回修改后的数组
 * @param param: 指定元素，即由此得到开始删除的位置
 * @param count: 删除count个元素
 * @returns 修改后的数组
 */
export const delArrParams = (param: number, count: number, array: any): any => {
  if (array.includes(param)) {
    const index = array.indexOf(param); // 查找元素的索引位置
    if (index > -1) {
      array.splice(index, count); // 删除元素
    }
  }
  return array
}