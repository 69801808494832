import React, { PropsWithChildren } from 'react'
import styles from './loginCard.module.scss'

export const LoginCard = ({ children, notification }: PropsWithChildren<{ notification?: JSX.Element }>) => {

  return (
    <div className={styles.login}>
      {notification}
      <div className={styles.loginBox}>
        <div className={styles.loginCard}>{children}</div>
      </div>
    </div>
  )
}
