// Generated from ./src/sql-parser/mysql/grammar/MySqlLexer.g4 by ANTLR 4.13.1
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import {
	ATN,
	ATNDeserializer,
	CharStream,
	DecisionState, DFA,
	Lexer,
	LexerATNSimulator,
	RuleContext,
	PredictionContextCache,
	Token
} from "antlr4";
export default class MySqlLexer extends Lexer {
	public static readonly SPACE = 1;
	public static readonly SPEC_MYSQL_COMMENT = 2;
	public static readonly COMMENT_INPUT = 3;
	public static readonly LINE_COMMENT = 4;
	public static readonly ADD = 5;
	public static readonly ALL = 6;
	public static readonly ALTER = 7;
	public static readonly ALWAYS = 8;
	public static readonly ANALYZE = 9;
	public static readonly AND = 10;
	public static readonly ARRAY = 11;
	public static readonly AS = 12;
	public static readonly ASC = 13;
	public static readonly ATTRIBUTE = 14;
	public static readonly BEFORE = 15;
	public static readonly BETWEEN = 16;
	public static readonly BOTH = 17;
	public static readonly BUCKETS = 18;
	public static readonly BY = 19;
	public static readonly CALL = 20;
	public static readonly CASCADE = 21;
	public static readonly CASE = 22;
	public static readonly CAST = 23;
	public static readonly CHANGE = 24;
	public static readonly CHARACTER = 25;
	public static readonly CHECK = 26;
	public static readonly COLLATE = 27;
	public static readonly COLUMN = 28;
	public static readonly CONDITION = 29;
	public static readonly CONSTRAINT = 30;
	public static readonly CONTINUE = 31;
	public static readonly CONVERT = 32;
	public static readonly CREATE = 33;
	public static readonly CROSS = 34;
	public static readonly CURRENT = 35;
	public static readonly CURRENT_ROLE = 36;
	public static readonly CURRENT_USER = 37;
	public static readonly CURSOR = 38;
	public static readonly DATABASE = 39;
	public static readonly DATABASES = 40;
	public static readonly DECLARE = 41;
	public static readonly DEFAULT = 42;
	public static readonly DELAYED = 43;
	public static readonly DELETE = 44;
	public static readonly DESC = 45;
	public static readonly DESCRIBE = 46;
	public static readonly DETERMINISTIC = 47;
	public static readonly DIAGNOSTICS = 48;
	public static readonly DISTINCT = 49;
	public static readonly DISTINCTROW = 50;
	public static readonly DROP = 51;
	public static readonly EACH = 52;
	public static readonly ELSE = 53;
	public static readonly ELSEIF = 54;
	public static readonly EMPTY = 55;
	public static readonly ENCLOSED = 56;
	public static readonly ENFORCED = 57;
	public static readonly ESCAPED = 58;
	public static readonly EXCEPT = 59;
	public static readonly EXISTS = 60;
	public static readonly EXIT = 61;
	public static readonly EXPLAIN = 62;
	public static readonly FALSE = 63;
	public static readonly FETCH = 64;
	public static readonly FOR = 65;
	public static readonly FORCE = 66;
	public static readonly FOREIGN = 67;
	public static readonly FROM = 68;
	public static readonly FULLTEXT = 69;
	public static readonly GENERATED = 70;
	public static readonly GET = 71;
	public static readonly GRANT = 72;
	public static readonly GROUP = 73;
	public static readonly HAVING = 74;
	public static readonly HIGH_PRIORITY = 75;
	public static readonly HISTOGRAM = 76;
	public static readonly IF = 77;
	public static readonly IGNORE = 78;
	public static readonly IGNORED = 79;
	public static readonly IN = 80;
	public static readonly INDEX = 81;
	public static readonly INFILE = 82;
	public static readonly INNER = 83;
	public static readonly INOUT = 84;
	public static readonly INSERT = 85;
	public static readonly INTERVAL = 86;
	public static readonly INTO = 87;
	public static readonly IS = 88;
	public static readonly ITERATE = 89;
	public static readonly JOIN = 90;
	public static readonly KEY = 91;
	public static readonly KEYS = 92;
	public static readonly KILL = 93;
	public static readonly LATERAL = 94;
	public static readonly LEADING = 95;
	public static readonly LEAVE = 96;
	public static readonly LEFT = 97;
	public static readonly LIKE = 98;
	public static readonly LIMIT = 99;
	public static readonly LINEAR = 100;
	public static readonly LINES = 101;
	public static readonly LOAD = 102;
	public static readonly LOCK = 103;
	public static readonly LOCKED = 104;
	public static readonly LOOP = 105;
	public static readonly LOW_PRIORITY = 106;
	public static readonly MASTER_BIND = 107;
	public static readonly MASTER_SSL_VERIFY_SERVER_CERT = 108;
	public static readonly MATCH = 109;
	public static readonly MAXVALUE = 110;
	public static readonly MINVALUE = 111;
	public static readonly MODIFIES = 112;
	public static readonly NATURAL = 113;
	public static readonly NOT = 114;
	public static readonly NO_WRITE_TO_BINLOG = 115;
	public static readonly NULL_LITERAL = 116;
	public static readonly NUMBER = 117;
	public static readonly ON = 118;
	public static readonly OPTIMIZE = 119;
	public static readonly OPTION = 120;
	public static readonly OPTIONAL = 121;
	public static readonly OPTIONALLY = 122;
	public static readonly OR = 123;
	public static readonly ORDER = 124;
	public static readonly OUT = 125;
	public static readonly OUTER = 126;
	public static readonly OUTFILE = 127;
	public static readonly OVER = 128;
	public static readonly PARTITION = 129;
	public static readonly PRIMARY = 130;
	public static readonly PROCEDURE = 131;
	public static readonly PURGE = 132;
	public static readonly RANGE = 133;
	public static readonly READ = 134;
	public static readonly READS = 135;
	public static readonly REFERENCES = 136;
	public static readonly REGEXP = 137;
	public static readonly RELEASE = 138;
	public static readonly RENAME = 139;
	public static readonly REPEAT = 140;
	public static readonly REPLACE = 141;
	public static readonly REQUIRE = 142;
	public static readonly RESIGNAL = 143;
	public static readonly RESTRICT = 144;
	public static readonly RETAIN = 145;
	public static readonly RETURN = 146;
	public static readonly REVOKE = 147;
	public static readonly RIGHT = 148;
	public static readonly RLIKE = 149;
	public static readonly SCHEMA = 150;
	public static readonly SCHEMAS = 151;
	public static readonly SELECT = 152;
	public static readonly SET = 153;
	public static readonly SEPARATOR = 154;
	public static readonly SHOW = 155;
	public static readonly SIGNAL = 156;
	public static readonly SKIP_ = 157;
	public static readonly SKIP_QUERY_REWRITE = 158;
	public static readonly SPATIAL = 159;
	public static readonly SQL = 160;
	public static readonly SQLEXCEPTION = 161;
	public static readonly SQLSTATE = 162;
	public static readonly SQLWARNING = 163;
	public static readonly SQL_BIG_RESULT = 164;
	public static readonly SQL_CALC_FOUND_ROWS = 165;
	public static readonly SQL_SMALL_RESULT = 166;
	public static readonly SSL = 167;
	public static readonly STACKED = 168;
	public static readonly STARTING = 169;
	public static readonly STATEMENT = 170;
	public static readonly STRAIGHT_JOIN = 171;
	public static readonly TABLE = 172;
	public static readonly TERMINATED = 173;
	public static readonly THEN = 174;
	public static readonly TO = 175;
	public static readonly TRAILING = 176;
	public static readonly TRIGGER = 177;
	public static readonly TRUE = 178;
	public static readonly UNDO = 179;
	public static readonly UNION = 180;
	public static readonly UNIQUE = 181;
	public static readonly UNLOCK = 182;
	public static readonly UNSIGNED = 183;
	public static readonly UPDATE = 184;
	public static readonly USAGE = 185;
	public static readonly USE = 186;
	public static readonly USING = 187;
	public static readonly VALUES = 188;
	public static readonly WHEN = 189;
	public static readonly WHERE = 190;
	public static readonly WHILE = 191;
	public static readonly WITH = 192;
	public static readonly WRITE = 193;
	public static readonly XOR = 194;
	public static readonly ZEROFILL = 195;
	public static readonly TINYINT = 196;
	public static readonly SMALLINT = 197;
	public static readonly MEDIUMINT = 198;
	public static readonly MIDDLEINT = 199;
	public static readonly INT = 200;
	public static readonly INT1 = 201;
	public static readonly INT2 = 202;
	public static readonly INT3 = 203;
	public static readonly INT4 = 204;
	public static readonly INT8 = 205;
	public static readonly INTEGER = 206;
	public static readonly BIGINT = 207;
	public static readonly REAL = 208;
	public static readonly DOUBLE = 209;
	public static readonly PRECISION = 210;
	public static readonly FLOAT = 211;
	public static readonly FLOAT4 = 212;
	public static readonly FLOAT8 = 213;
	public static readonly DECIMAL = 214;
	public static readonly DEC = 215;
	public static readonly NUMERIC = 216;
	public static readonly DATE = 217;
	public static readonly TIME = 218;
	public static readonly TIMESTAMP = 219;
	public static readonly DATETIME = 220;
	public static readonly YEAR = 221;
	public static readonly CHAR = 222;
	public static readonly VARCHAR = 223;
	public static readonly NVARCHAR = 224;
	public static readonly NATIONAL = 225;
	public static readonly BINARY = 226;
	public static readonly VARBINARY = 227;
	public static readonly TINYBLOB = 228;
	public static readonly BLOB = 229;
	public static readonly MEDIUMBLOB = 230;
	public static readonly LONG = 231;
	public static readonly LONGBLOB = 232;
	public static readonly TINYTEXT = 233;
	public static readonly TEXT = 234;
	public static readonly MEDIUMTEXT = 235;
	public static readonly LONGTEXT = 236;
	public static readonly ENUM = 237;
	public static readonly VARYING = 238;
	public static readonly SERIAL = 239;
	public static readonly YEAR_MONTH = 240;
	public static readonly DAY_HOUR = 241;
	public static readonly DAY_MINUTE = 242;
	public static readonly DAY_SECOND = 243;
	public static readonly HOUR_MINUTE = 244;
	public static readonly HOUR_SECOND = 245;
	public static readonly MINUTE_SECOND = 246;
	public static readonly SECOND_MICROSECOND = 247;
	public static readonly MINUTE_MICROSECOND = 248;
	public static readonly HOUR_MICROSECOND = 249;
	public static readonly DAY_MICROSECOND = 250;
	public static readonly JSON_ARRAY = 251;
	public static readonly JSON_ARRAYAGG = 252;
	public static readonly JSON_ARRAY_APPEND = 253;
	public static readonly JSON_ARRAY_INSERT = 254;
	public static readonly JSON_CONTAINS = 255;
	public static readonly JSON_CONTAINS_PATH = 256;
	public static readonly JSON_DEPTH = 257;
	public static readonly JSON_EXTRACT = 258;
	public static readonly JSON_INSERT = 259;
	public static readonly JSON_KEYS = 260;
	public static readonly JSON_LENGTH = 261;
	public static readonly JSON_MERGE = 262;
	public static readonly JSON_MERGE_PATCH = 263;
	public static readonly JSON_MERGE_PRESERVE = 264;
	public static readonly JSON_OBJECT = 265;
	public static readonly JSON_OBJECTAGG = 266;
	public static readonly JSON_OVERLAPS = 267;
	public static readonly JSON_PRETTY = 268;
	public static readonly JSON_QUOTE = 269;
	public static readonly JSON_REMOVE = 270;
	public static readonly JSON_REPLACE = 271;
	public static readonly JSON_SCHEMA_VALID = 272;
	public static readonly JSON_SCHEMA_VALIDATION_REPORT = 273;
	public static readonly JSON_SEARCH = 274;
	public static readonly JSON_SET = 275;
	public static readonly JSON_STORAGE_FREE = 276;
	public static readonly JSON_STORAGE_SIZE = 277;
	public static readonly JSON_TABLE = 278;
	public static readonly JSON_TYPE = 279;
	public static readonly JSON_UNQUOTE = 280;
	public static readonly JSON_VALID = 281;
	public static readonly JSON_VALUE = 282;
	public static readonly NESTED = 283;
	public static readonly ORDINALITY = 284;
	public static readonly PATH = 285;
	public static readonly AVG = 286;
	public static readonly BIT_AND = 287;
	public static readonly BIT_OR = 288;
	public static readonly BIT_XOR = 289;
	public static readonly COUNT = 290;
	public static readonly CUME_DIST = 291;
	public static readonly DENSE_RANK = 292;
	public static readonly FIRST_VALUE = 293;
	public static readonly GROUP_CONCAT = 294;
	public static readonly LAG = 295;
	public static readonly LAST_VALUE = 296;
	public static readonly LEAD = 297;
	public static readonly MAX = 298;
	public static readonly MIN = 299;
	public static readonly NTILE = 300;
	public static readonly NTH_VALUE = 301;
	public static readonly PERCENT_RANK = 302;
	public static readonly RANK = 303;
	public static readonly ROW_NUMBER = 304;
	public static readonly STD = 305;
	public static readonly STDDEV = 306;
	public static readonly STDDEV_POP = 307;
	public static readonly STDDEV_SAMP = 308;
	public static readonly SUM = 309;
	public static readonly VAR_POP = 310;
	public static readonly VAR_SAMP = 311;
	public static readonly VARIANCE = 312;
	public static readonly CURRENT_DATE = 313;
	public static readonly CURRENT_TIME = 314;
	public static readonly CURRENT_TIMESTAMP = 315;
	public static readonly LOCALTIME = 316;
	public static readonly CURDATE = 317;
	public static readonly CURTIME = 318;
	public static readonly DATE_ADD = 319;
	public static readonly DATE_SUB = 320;
	public static readonly EXTRACT = 321;
	public static readonly LOCALTIMESTAMP = 322;
	public static readonly NOW = 323;
	public static readonly POSITION = 324;
	public static readonly SUBSTR = 325;
	public static readonly SUBSTRING = 326;
	public static readonly SYSDATE = 327;
	public static readonly TRIM = 328;
	public static readonly UTC_DATE = 329;
	public static readonly UTC_TIME = 330;
	public static readonly UTC_TIMESTAMP = 331;
	public static readonly ACCOUNT = 332;
	public static readonly ACTION = 333;
	public static readonly AFTER = 334;
	public static readonly AGGREGATE = 335;
	public static readonly ALGORITHM = 336;
	public static readonly ANY = 337;
	public static readonly AT = 338;
	public static readonly AUTHORS = 339;
	public static readonly AUTOCOMMIT = 340;
	public static readonly AUTOEXTEND_SIZE = 341;
	public static readonly AUTO_INCREMENT = 342;
	public static readonly AVG_ROW_LENGTH = 343;
	public static readonly BEGIN = 344;
	public static readonly BINLOG = 345;
	public static readonly BIT = 346;
	public static readonly BLOCK = 347;
	public static readonly BOOL = 348;
	public static readonly BOOLEAN = 349;
	public static readonly BTREE = 350;
	public static readonly CACHE = 351;
	public static readonly CASCADED = 352;
	public static readonly CHAIN = 353;
	public static readonly CHANGED = 354;
	public static readonly CHANNEL = 355;
	public static readonly CHECKSUM = 356;
	public static readonly PAGE_CHECKSUM = 357;
	public static readonly CIPHER = 358;
	public static readonly CLASS_ORIGIN = 359;
	public static readonly CLIENT = 360;
	public static readonly CLOSE = 361;
	public static readonly CLUSTERING = 362;
	public static readonly COALESCE = 363;
	public static readonly CODE = 364;
	public static readonly COLUMNS = 365;
	public static readonly COLUMN_FORMAT = 366;
	public static readonly COLUMN_NAME = 367;
	public static readonly COMMENT = 368;
	public static readonly COMMIT = 369;
	public static readonly COMPACT = 370;
	public static readonly COMPLETION = 371;
	public static readonly COMPRESSED = 372;
	public static readonly COMPRESSION = 373;
	public static readonly CONCURRENT = 374;
	public static readonly CONNECT = 375;
	public static readonly CONNECTION = 376;
	public static readonly CONSISTENT = 377;
	public static readonly CONSTRAINT_CATALOG = 378;
	public static readonly CONSTRAINT_SCHEMA = 379;
	public static readonly CONSTRAINT_NAME = 380;
	public static readonly CONTAINS = 381;
	public static readonly CONTEXT = 382;
	public static readonly CONTRIBUTORS = 383;
	public static readonly COPY = 384;
	public static readonly CPU = 385;
	public static readonly CYCLE = 386;
	public static readonly CURSOR_NAME = 387;
	public static readonly DATA = 388;
	public static readonly DATAFILE = 389;
	public static readonly DEALLOCATE = 390;
	public static readonly DEFAULT_AUTH = 391;
	public static readonly DEFINER = 392;
	public static readonly DELAY_KEY_WRITE = 393;
	public static readonly DES_KEY_FILE = 394;
	public static readonly DIRECTORY = 395;
	public static readonly DISABLE = 396;
	public static readonly DISCARD = 397;
	public static readonly DISK = 398;
	public static readonly DO = 399;
	public static readonly DUMPFILE = 400;
	public static readonly DUPLICATE = 401;
	public static readonly DYNAMIC = 402;
	public static readonly ENABLE = 403;
	public static readonly ENCRYPTED = 404;
	public static readonly ENCRYPTION = 405;
	public static readonly ENCRYPTION_KEY_ID = 406;
	public static readonly END = 407;
	public static readonly ENDS = 408;
	public static readonly ENGINE = 409;
	public static readonly ENGINES = 410;
	public static readonly ERROR = 411;
	public static readonly ERRORS = 412;
	public static readonly ESCAPE = 413;
	public static readonly EVEN = 414;
	public static readonly EVENT = 415;
	public static readonly EVENTS = 416;
	public static readonly EVERY = 417;
	public static readonly EXCHANGE = 418;
	public static readonly EXCLUSIVE = 419;
	public static readonly EXPIRE = 420;
	public static readonly EXPORT = 421;
	public static readonly EXTENDED = 422;
	public static readonly EXTENT_SIZE = 423;
	public static readonly FAILED_LOGIN_ATTEMPTS = 424;
	public static readonly FAST = 425;
	public static readonly FAULTS = 426;
	public static readonly FIELDS = 427;
	public static readonly FILE_BLOCK_SIZE = 428;
	public static readonly FILTER = 429;
	public static readonly FIRST = 430;
	public static readonly FIXED = 431;
	public static readonly FLUSH = 432;
	public static readonly FOLLOWING = 433;
	public static readonly FOLLOWS = 434;
	public static readonly FOUND = 435;
	public static readonly FULL = 436;
	public static readonly FUNCTION = 437;
	public static readonly GENERAL = 438;
	public static readonly GLOBAL = 439;
	public static readonly GRANTS = 440;
	public static readonly GROUP_REPLICATION = 441;
	public static readonly HANDLER = 442;
	public static readonly HASH = 443;
	public static readonly HELP = 444;
	public static readonly HISTORY = 445;
	public static readonly HOST = 446;
	public static readonly HOSTS = 447;
	public static readonly IDENTIFIED = 448;
	public static readonly IGNORE_SERVER_IDS = 449;
	public static readonly IMPORT = 450;
	public static readonly INCREMENT = 451;
	public static readonly INDEXES = 452;
	public static readonly INITIAL_SIZE = 453;
	public static readonly INPLACE = 454;
	public static readonly INSERT_METHOD = 455;
	public static readonly INSTALL = 456;
	public static readonly INSTANCE = 457;
	public static readonly INSTANT = 458;
	public static readonly INVISIBLE = 459;
	public static readonly INVOKER = 460;
	public static readonly IO = 461;
	public static readonly IO_THREAD = 462;
	public static readonly IPC = 463;
	public static readonly ISOLATION = 464;
	public static readonly ISSUER = 465;
	public static readonly JSON = 466;
	public static readonly KEY_BLOCK_SIZE = 467;
	public static readonly LANGUAGE = 468;
	public static readonly LAST = 469;
	public static readonly LEAVES = 470;
	public static readonly LESS = 471;
	public static readonly LEVEL = 472;
	public static readonly LIST = 473;
	public static readonly LOCAL = 474;
	public static readonly LOGFILE = 475;
	public static readonly LOGS = 476;
	public static readonly MASTER = 477;
	public static readonly MASTER_AUTO_POSITION = 478;
	public static readonly MASTER_CONNECT_RETRY = 479;
	public static readonly MASTER_DELAY = 480;
	public static readonly MASTER_HEARTBEAT_PERIOD = 481;
	public static readonly MASTER_HOST = 482;
	public static readonly MASTER_LOG_FILE = 483;
	public static readonly MASTER_LOG_POS = 484;
	public static readonly MASTER_PASSWORD = 485;
	public static readonly MASTER_PORT = 486;
	public static readonly MASTER_RETRY_COUNT = 487;
	public static readonly MASTER_SSL = 488;
	public static readonly MASTER_SSL_CA = 489;
	public static readonly MASTER_SSL_CAPATH = 490;
	public static readonly MASTER_SSL_CERT = 491;
	public static readonly MASTER_SSL_CIPHER = 492;
	public static readonly MASTER_SSL_CRL = 493;
	public static readonly MASTER_SSL_CRLPATH = 494;
	public static readonly MASTER_SSL_KEY = 495;
	public static readonly MASTER_TLS_VERSION = 496;
	public static readonly MASTER_USER = 497;
	public static readonly MAX_CONNECTIONS_PER_HOUR = 498;
	public static readonly MAX_QUERIES_PER_HOUR = 499;
	public static readonly MAX_ROWS = 500;
	public static readonly MAX_SIZE = 501;
	public static readonly MAX_UPDATES_PER_HOUR = 502;
	public static readonly MAX_USER_CONNECTIONS = 503;
	public static readonly MEDIUM = 504;
	public static readonly MEMBER = 505;
	public static readonly MERGE = 506;
	public static readonly MESSAGE_TEXT = 507;
	public static readonly MID = 508;
	public static readonly MIGRATE = 509;
	public static readonly MIN_ROWS = 510;
	public static readonly MODE = 511;
	public static readonly MODIFY = 512;
	public static readonly MUTEX = 513;
	public static readonly MYSQL = 514;
	public static readonly MYSQL_ERRNO = 515;
	public static readonly NAME = 516;
	public static readonly NAMES = 517;
	public static readonly NCHAR = 518;
	public static readonly NEVER = 519;
	public static readonly NEXT = 520;
	public static readonly NO = 521;
	public static readonly NOCACHE = 522;
	public static readonly NOCOPY = 523;
	public static readonly NOCYCLE = 524;
	public static readonly NOMAXVALUE = 525;
	public static readonly NOMINVALUE = 526;
	public static readonly NOWAIT = 527;
	public static readonly NODEGROUP = 528;
	public static readonly NONE = 529;
	public static readonly ODBC = 530;
	public static readonly OFFLINE = 531;
	public static readonly OFFSET = 532;
	public static readonly OF = 533;
	public static readonly OJ = 534;
	public static readonly OLD_PASSWORD = 535;
	public static readonly ONE = 536;
	public static readonly ONLINE = 537;
	public static readonly ONLY = 538;
	public static readonly OPEN = 539;
	public static readonly OPTIMIZER_COSTS = 540;
	public static readonly OPTIONS = 541;
	public static readonly OWNER = 542;
	public static readonly PACK_KEYS = 543;
	public static readonly PAGE = 544;
	public static readonly PAGE_COMPRESSED = 545;
	public static readonly PAGE_COMPRESSION_LEVEL = 546;
	public static readonly PARSER = 547;
	public static readonly PARTIAL = 548;
	public static readonly PARTITIONING = 549;
	public static readonly PARTITIONS = 550;
	public static readonly PASSWORD = 551;
	public static readonly PASSWORD_LOCK_TIME = 552;
	public static readonly PHASE = 553;
	public static readonly PLUGIN = 554;
	public static readonly PLUGIN_DIR = 555;
	public static readonly PLUGINS = 556;
	public static readonly PORT = 557;
	public static readonly PRECEDES = 558;
	public static readonly PRECEDING = 559;
	public static readonly PREPARE = 560;
	public static readonly PRESERVE = 561;
	public static readonly PREV = 562;
	public static readonly PROCESSLIST = 563;
	public static readonly PROFILE = 564;
	public static readonly PROFILES = 565;
	public static readonly PROXY = 566;
	public static readonly QUERY = 567;
	public static readonly QUICK = 568;
	public static readonly REBUILD = 569;
	public static readonly RECOVER = 570;
	public static readonly RECURSIVE = 571;
	public static readonly REDO_BUFFER_SIZE = 572;
	public static readonly REDUNDANT = 573;
	public static readonly RELAY = 574;
	public static readonly RELAY_LOG_FILE = 575;
	public static readonly RELAY_LOG_POS = 576;
	public static readonly RELAYLOG = 577;
	public static readonly REMOVE = 578;
	public static readonly REORGANIZE = 579;
	public static readonly REPAIR = 580;
	public static readonly REPLICATE_DO_DB = 581;
	public static readonly REPLICATE_DO_TABLE = 582;
	public static readonly REPLICATE_IGNORE_DB = 583;
	public static readonly REPLICATE_IGNORE_TABLE = 584;
	public static readonly REPLICATE_REWRITE_DB = 585;
	public static readonly REPLICATE_WILD_DO_TABLE = 586;
	public static readonly REPLICATE_WILD_IGNORE_TABLE = 587;
	public static readonly REPLICATION = 588;
	public static readonly RESET = 589;
	public static readonly RESTART = 590;
	public static readonly RESUME = 591;
	public static readonly RETURNED_SQLSTATE = 592;
	public static readonly RETURNING = 593;
	public static readonly RETURNS = 594;
	public static readonly REUSE = 595;
	public static readonly ROLE = 596;
	public static readonly ROLLBACK = 597;
	public static readonly ROLLUP = 598;
	public static readonly ROTATE = 599;
	public static readonly ROW = 600;
	public static readonly ROWS = 601;
	public static readonly ROW_FORMAT = 602;
	public static readonly RTREE = 603;
	public static readonly SAVEPOINT = 604;
	public static readonly SCHEDULE = 605;
	public static readonly SECURITY = 606;
	public static readonly SEQUENCE = 607;
	public static readonly SERVER = 608;
	public static readonly SESSION = 609;
	public static readonly SHARE = 610;
	public static readonly SHARED = 611;
	public static readonly SIGNED = 612;
	public static readonly SIMPLE = 613;
	public static readonly SLAVE = 614;
	public static readonly SLOW = 615;
	public static readonly SNAPSHOT = 616;
	public static readonly SOCKET = 617;
	public static readonly SOME = 618;
	public static readonly SONAME = 619;
	public static readonly SOUNDS = 620;
	public static readonly SOURCE = 621;
	public static readonly SQL_AFTER_GTIDS = 622;
	public static readonly SQL_AFTER_MTS_GAPS = 623;
	public static readonly SQL_BEFORE_GTIDS = 624;
	public static readonly SQL_BUFFER_RESULT = 625;
	public static readonly SQL_CACHE = 626;
	public static readonly SQL_NO_CACHE = 627;
	public static readonly SQL_THREAD = 628;
	public static readonly START = 629;
	public static readonly STARTS = 630;
	public static readonly STATS_AUTO_RECALC = 631;
	public static readonly STATS_PERSISTENT = 632;
	public static readonly STATS_SAMPLE_PAGES = 633;
	public static readonly STATUS = 634;
	public static readonly STOP = 635;
	public static readonly STORAGE = 636;
	public static readonly STORED = 637;
	public static readonly STRING = 638;
	public static readonly SUBCLASS_ORIGIN = 639;
	public static readonly SUBJECT = 640;
	public static readonly SUBPARTITION = 641;
	public static readonly SUBPARTITIONS = 642;
	public static readonly SUSPEND = 643;
	public static readonly SWAPS = 644;
	public static readonly SWITCHES = 645;
	public static readonly TABLE_NAME = 646;
	public static readonly TABLESPACE = 647;
	public static readonly TABLE_TYPE = 648;
	public static readonly TEMPORARY = 649;
	public static readonly TEMPTABLE = 650;
	public static readonly THAN = 651;
	public static readonly TRADITIONAL = 652;
	public static readonly TRANSACTION = 653;
	public static readonly TRANSACTIONAL = 654;
	public static readonly TRIGGERS = 655;
	public static readonly TRUNCATE = 656;
	public static readonly UNBOUNDED = 657;
	public static readonly UNDEFINED = 658;
	public static readonly UNDOFILE = 659;
	public static readonly UNDO_BUFFER_SIZE = 660;
	public static readonly UNINSTALL = 661;
	public static readonly UNKNOWN = 662;
	public static readonly UNTIL = 663;
	public static readonly UPGRADE = 664;
	public static readonly USER = 665;
	public static readonly USE_FRM = 666;
	public static readonly USER_RESOURCES = 667;
	public static readonly VALIDATION = 668;
	public static readonly VALUE = 669;
	public static readonly VARIABLES = 670;
	public static readonly VIEW = 671;
	public static readonly VIRTUAL = 672;
	public static readonly VISIBLE = 673;
	public static readonly WAIT = 674;
	public static readonly WARNINGS = 675;
	public static readonly WINDOW = 676;
	public static readonly WITHOUT = 677;
	public static readonly WORK = 678;
	public static readonly WRAPPER = 679;
	public static readonly X509 = 680;
	public static readonly XA = 681;
	public static readonly XML = 682;
	public static readonly YES = 683;
	public static readonly EUR = 684;
	public static readonly USA = 685;
	public static readonly JIS = 686;
	public static readonly ISO = 687;
	public static readonly INTERNAL = 688;
	public static readonly QUARTER = 689;
	public static readonly MONTH = 690;
	public static readonly DAY = 691;
	public static readonly HOUR = 692;
	public static readonly MINUTE = 693;
	public static readonly WEEK = 694;
	public static readonly SECOND = 695;
	public static readonly MICROSECOND = 696;
	public static readonly ADMIN = 697;
	public static readonly APPLICATION_PASSWORD_ADMIN = 698;
	public static readonly AUDIT_ABORT_EXEMPT = 699;
	public static readonly AUDIT_ADMIN = 700;
	public static readonly AUTHENTICATION_POLICY_ADMIN = 701;
	public static readonly BACKUP_ADMIN = 702;
	public static readonly BINLOG_ADMIN = 703;
	public static readonly BINLOG_ENCRYPTION_ADMIN = 704;
	public static readonly CLONE_ADMIN = 705;
	public static readonly CONNECTION_ADMIN = 706;
	public static readonly ENCRYPTION_KEY_ADMIN = 707;
	public static readonly EXECUTE = 708;
	public static readonly FILE = 709;
	public static readonly FIREWALL_ADMIN = 710;
	public static readonly FIREWALL_EXEMPT = 711;
	public static readonly FIREWALL_USER = 712;
	public static readonly FLUSH_OPTIMIZER_COSTS = 713;
	public static readonly FLUSH_STATUS = 714;
	public static readonly FLUSH_TABLES = 715;
	public static readonly FLUSH_USER_RESOURCES = 716;
	public static readonly GROUP_REPLICATION_ADMIN = 717;
	public static readonly INNODB_REDO_LOG_ARCHIVE = 718;
	public static readonly INNODB_REDO_LOG_ENABLE = 719;
	public static readonly INVOKE = 720;
	public static readonly LAMBDA = 721;
	public static readonly NDB_STORED_USER = 722;
	public static readonly PASSWORDLESS_USER_ADMIN = 723;
	public static readonly PERSIST_RO_VARIABLES_ADMIN = 724;
	public static readonly PRIVILEGES = 725;
	public static readonly PROCESS = 726;
	public static readonly RELOAD = 727;
	public static readonly REPLICATION_APPLIER = 728;
	public static readonly REPLICATION_SLAVE_ADMIN = 729;
	public static readonly RESOURCE_GROUP_ADMIN = 730;
	public static readonly RESOURCE_GROUP_USER = 731;
	public static readonly ROLE_ADMIN = 732;
	public static readonly ROUTINE = 733;
	public static readonly S3 = 734;
	public static readonly SERVICE_CONNECTION_ADMIN = 735;
	public static readonly SESSION_VARIABLES_ADMIN = 736;
	public static readonly SET_USER_ID = 737;
	public static readonly SHOW_ROUTINE = 738;
	public static readonly SHUTDOWN = 739;
	public static readonly SUPER = 740;
	public static readonly SYSTEM_VARIABLES_ADMIN = 741;
	public static readonly TABLES = 742;
	public static readonly TABLE_ENCRYPTION_ADMIN = 743;
	public static readonly VERSION_TOKEN_ADMIN = 744;
	public static readonly XA_RECOVER_ADMIN = 745;
	public static readonly ARMSCII8 = 746;
	public static readonly ASCII = 747;
	public static readonly BIG5 = 748;
	public static readonly CP1250 = 749;
	public static readonly CP1251 = 750;
	public static readonly CP1256 = 751;
	public static readonly CP1257 = 752;
	public static readonly CP850 = 753;
	public static readonly CP852 = 754;
	public static readonly CP866 = 755;
	public static readonly CP932 = 756;
	public static readonly DEC8 = 757;
	public static readonly EUCJPMS = 758;
	public static readonly EUCKR = 759;
	public static readonly GB18030 = 760;
	public static readonly GB2312 = 761;
	public static readonly GBK = 762;
	public static readonly GEOSTD8 = 763;
	public static readonly GREEK = 764;
	public static readonly HEBREW = 765;
	public static readonly HP8 = 766;
	public static readonly KEYBCS2 = 767;
	public static readonly KOI8R = 768;
	public static readonly KOI8U = 769;
	public static readonly LATIN1 = 770;
	public static readonly LATIN2 = 771;
	public static readonly LATIN5 = 772;
	public static readonly LATIN7 = 773;
	public static readonly MACCE = 774;
	public static readonly MACROMAN = 775;
	public static readonly SJIS = 776;
	public static readonly SWE7 = 777;
	public static readonly TIS620 = 778;
	public static readonly UCS2 = 779;
	public static readonly UJIS = 780;
	public static readonly UTF16 = 781;
	public static readonly UTF16LE = 782;
	public static readonly UTF32 = 783;
	public static readonly UTF8 = 784;
	public static readonly UTF8MB3 = 785;
	public static readonly UTF8MB4 = 786;
	public static readonly ARCHIVE = 787;
	public static readonly BLACKHOLE = 788;
	public static readonly CSV = 789;
	public static readonly FEDERATED = 790;
	public static readonly INNODB = 791;
	public static readonly MEMORY = 792;
	public static readonly MRG_MYISAM = 793;
	public static readonly MYISAM = 794;
	public static readonly NDB = 795;
	public static readonly NDBCLUSTER = 796;
	public static readonly PERFORMANCE_SCHEMA = 797;
	public static readonly TOKUDB = 798;
	public static readonly REPEATABLE = 799;
	public static readonly COMMITTED = 800;
	public static readonly UNCOMMITTED = 801;
	public static readonly SERIALIZABLE = 802;
	public static readonly GEOMETRYCOLLECTION = 803;
	public static readonly GEOMCOLLECTION = 804;
	public static readonly GEOMETRY = 805;
	public static readonly LINESTRING = 806;
	public static readonly MULTILINESTRING = 807;
	public static readonly MULTIPOINT = 808;
	public static readonly MULTIPOLYGON = 809;
	public static readonly POINT = 810;
	public static readonly POLYGON = 811;
	public static readonly ABS = 812;
	public static readonly ACOS = 813;
	public static readonly ADDDATE = 814;
	public static readonly ADDTIME = 815;
	public static readonly AES_DECRYPT = 816;
	public static readonly AES_ENCRYPT = 817;
	public static readonly AREA = 818;
	public static readonly ASBINARY = 819;
	public static readonly ASIN = 820;
	public static readonly ASTEXT = 821;
	public static readonly ASWKB = 822;
	public static readonly ASWKT = 823;
	public static readonly ASYMMETRIC_DECRYPT = 824;
	public static readonly ASYMMETRIC_DERIVE = 825;
	public static readonly ASYMMETRIC_ENCRYPT = 826;
	public static readonly ASYMMETRIC_SIGN = 827;
	public static readonly ASYMMETRIC_VERIFY = 828;
	public static readonly ATAN = 829;
	public static readonly ATAN2 = 830;
	public static readonly BENCHMARK = 831;
	public static readonly BIN = 832;
	public static readonly BIT_COUNT = 833;
	public static readonly BIT_LENGTH = 834;
	public static readonly BUFFER = 835;
	public static readonly CATALOG_NAME = 836;
	public static readonly CEIL = 837;
	public static readonly CEILING = 838;
	public static readonly CENTROID = 839;
	public static readonly CHARACTER_LENGTH = 840;
	public static readonly CHARSET = 841;
	public static readonly CHAR_LENGTH = 842;
	public static readonly COERCIBILITY = 843;
	public static readonly COLLATION = 844;
	public static readonly COMPRESS = 845;
	public static readonly CONCAT = 846;
	public static readonly CONCAT_WS = 847;
	public static readonly CONNECTION_ID = 848;
	public static readonly CONV = 849;
	public static readonly CONVERT_TZ = 850;
	public static readonly COS = 851;
	public static readonly COT = 852;
	public static readonly CRC32 = 853;
	public static readonly CREATE_ASYMMETRIC_PRIV_KEY = 854;
	public static readonly CREATE_ASYMMETRIC_PUB_KEY = 855;
	public static readonly CREATE_DH_PARAMETERS = 856;
	public static readonly CREATE_DIGEST = 857;
	public static readonly CROSSES = 858;
	public static readonly DATEDIFF = 859;
	public static readonly DATE_FORMAT = 860;
	public static readonly DAYNAME = 861;
	public static readonly DAYOFMONTH = 862;
	public static readonly DAYOFWEEK = 863;
	public static readonly DAYOFYEAR = 864;
	public static readonly DECODE = 865;
	public static readonly DEGREES = 866;
	public static readonly DES_DECRYPT = 867;
	public static readonly DES_ENCRYPT = 868;
	public static readonly DIMENSION = 869;
	public static readonly DISJOINT = 870;
	public static readonly ELT = 871;
	public static readonly ENCODE = 872;
	public static readonly ENCRYPT = 873;
	public static readonly ENDPOINT = 874;
	public static readonly ENGINE_ATTRIBUTE = 875;
	public static readonly ENVELOPE = 876;
	public static readonly EQUALS = 877;
	public static readonly EXP = 878;
	public static readonly EXPORT_SET = 879;
	public static readonly EXTERIORRING = 880;
	public static readonly EXTRACTVALUE = 881;
	public static readonly FIELD = 882;
	public static readonly FIND_IN_SET = 883;
	public static readonly FLOOR = 884;
	public static readonly FORMAT = 885;
	public static readonly FOUND_ROWS = 886;
	public static readonly FROM_BASE64 = 887;
	public static readonly FROM_DAYS = 888;
	public static readonly FROM_UNIXTIME = 889;
	public static readonly GEOMCOLLFROMTEXT = 890;
	public static readonly GEOMCOLLFROMWKB = 891;
	public static readonly GEOMETRYCOLLECTIONFROMTEXT = 892;
	public static readonly GEOMETRYCOLLECTIONFROMWKB = 893;
	public static readonly GEOMETRYFROMTEXT = 894;
	public static readonly GEOMETRYFROMWKB = 895;
	public static readonly GEOMETRYN = 896;
	public static readonly GEOMETRYTYPE = 897;
	public static readonly GEOMFROMTEXT = 898;
	public static readonly GEOMFROMWKB = 899;
	public static readonly GET_FORMAT = 900;
	public static readonly GET_LOCK = 901;
	public static readonly GLENGTH = 902;
	public static readonly GREATEST = 903;
	public static readonly GTID_SUBSET = 904;
	public static readonly GTID_SUBTRACT = 905;
	public static readonly HEX = 906;
	public static readonly IFNULL = 907;
	public static readonly INET6_ATON = 908;
	public static readonly INET6_NTOA = 909;
	public static readonly INET_ATON = 910;
	public static readonly INET_NTOA = 911;
	public static readonly INSTR = 912;
	public static readonly INTERIORRINGN = 913;
	public static readonly INTERSECTS = 914;
	public static readonly ISCLOSED = 915;
	public static readonly ISEMPTY = 916;
	public static readonly ISNULL = 917;
	public static readonly ISSIMPLE = 918;
	public static readonly IS_FREE_LOCK = 919;
	public static readonly IS_IPV4 = 920;
	public static readonly IS_IPV4_COMPAT = 921;
	public static readonly IS_IPV4_MAPPED = 922;
	public static readonly IS_IPV6 = 923;
	public static readonly IS_USED_LOCK = 924;
	public static readonly LAST_INSERT_ID = 925;
	public static readonly LCASE = 926;
	public static readonly LEAST = 927;
	public static readonly LENGTH = 928;
	public static readonly LINEFROMTEXT = 929;
	public static readonly LINEFROMWKB = 930;
	public static readonly LINESTRINGFROMTEXT = 931;
	public static readonly LINESTRINGFROMWKB = 932;
	public static readonly LN = 933;
	public static readonly LOAD_FILE = 934;
	public static readonly LOCATE = 935;
	public static readonly LOG = 936;
	public static readonly LOG10 = 937;
	public static readonly LOG2 = 938;
	public static readonly LOWER = 939;
	public static readonly LPAD = 940;
	public static readonly LTRIM = 941;
	public static readonly MAKEDATE = 942;
	public static readonly MAKETIME = 943;
	public static readonly MAKE_SET = 944;
	public static readonly MASTER_POS_WAIT = 945;
	public static readonly MBRCONTAINS = 946;
	public static readonly MBRDISJOINT = 947;
	public static readonly MBREQUAL = 948;
	public static readonly MBRINTERSECTS = 949;
	public static readonly MBROVERLAPS = 950;
	public static readonly MBRTOUCHES = 951;
	public static readonly MBRWITHIN = 952;
	public static readonly MD5 = 953;
	public static readonly MLINEFROMTEXT = 954;
	public static readonly MLINEFROMWKB = 955;
	public static readonly MONTHNAME = 956;
	public static readonly MPOINTFROMTEXT = 957;
	public static readonly MPOINTFROMWKB = 958;
	public static readonly MPOLYFROMTEXT = 959;
	public static readonly MPOLYFROMWKB = 960;
	public static readonly MULTILINESTRINGFROMTEXT = 961;
	public static readonly MULTILINESTRINGFROMWKB = 962;
	public static readonly MULTIPOINTFROMTEXT = 963;
	public static readonly MULTIPOINTFROMWKB = 964;
	public static readonly MULTIPOLYGONFROMTEXT = 965;
	public static readonly MULTIPOLYGONFROMWKB = 966;
	public static readonly NAME_CONST = 967;
	public static readonly NULLIF = 968;
	public static readonly NUMGEOMETRIES = 969;
	public static readonly NUMINTERIORRINGS = 970;
	public static readonly NUMPOINTS = 971;
	public static readonly OCT = 972;
	public static readonly OCTET_LENGTH = 973;
	public static readonly ORD = 974;
	public static readonly OVERLAPS = 975;
	public static readonly PERIOD_ADD = 976;
	public static readonly PERIOD_DIFF = 977;
	public static readonly PI = 978;
	public static readonly POINTFROMTEXT = 979;
	public static readonly POINTFROMWKB = 980;
	public static readonly POINTN = 981;
	public static readonly POLYFROMTEXT = 982;
	public static readonly POLYFROMWKB = 983;
	public static readonly POLYGONFROMTEXT = 984;
	public static readonly POLYGONFROMWKB = 985;
	public static readonly POW = 986;
	public static readonly POWER = 987;
	public static readonly QUOTE = 988;
	public static readonly RADIANS = 989;
	public static readonly RAND = 990;
	public static readonly RANDOM = 991;
	public static readonly RANDOM_BYTES = 992;
	public static readonly RELEASE_LOCK = 993;
	public static readonly REVERSE = 994;
	public static readonly ROUND = 995;
	public static readonly ROW_COUNT = 996;
	public static readonly RPAD = 997;
	public static readonly RTRIM = 998;
	public static readonly SEC_TO_TIME = 999;
	public static readonly SECONDARY_ENGINE_ATTRIBUTE = 1000;
	public static readonly SESSION_USER = 1001;
	public static readonly SHA = 1002;
	public static readonly SHA1 = 1003;
	public static readonly SHA2 = 1004;
	public static readonly SCHEMA_NAME = 1005;
	public static readonly SIGN = 1006;
	public static readonly SIN = 1007;
	public static readonly SLEEP = 1008;
	public static readonly SOUNDEX = 1009;
	public static readonly SQL_THREAD_WAIT_AFTER_GTIDS = 1010;
	public static readonly SQRT = 1011;
	public static readonly SRID = 1012;
	public static readonly STARTPOINT = 1013;
	public static readonly STRCMP = 1014;
	public static readonly STR_TO_DATE = 1015;
	public static readonly ST_AREA = 1016;
	public static readonly ST_ASBINARY = 1017;
	public static readonly ST_ASTEXT = 1018;
	public static readonly ST_ASWKB = 1019;
	public static readonly ST_ASWKT = 1020;
	public static readonly ST_BUFFER = 1021;
	public static readonly ST_CENTROID = 1022;
	public static readonly ST_CONTAINS = 1023;
	public static readonly ST_CROSSES = 1024;
	public static readonly ST_DIFFERENCE = 1025;
	public static readonly ST_DIMENSION = 1026;
	public static readonly ST_DISJOINT = 1027;
	public static readonly ST_DISTANCE = 1028;
	public static readonly ST_ENDPOINT = 1029;
	public static readonly ST_ENVELOPE = 1030;
	public static readonly ST_EQUALS = 1031;
	public static readonly ST_EXTERIORRING = 1032;
	public static readonly ST_GEOMCOLLFROMTEXT = 1033;
	public static readonly ST_GEOMCOLLFROMTXT = 1034;
	public static readonly ST_GEOMCOLLFROMWKB = 1035;
	public static readonly ST_GEOMETRYCOLLECTIONFROMTEXT = 1036;
	public static readonly ST_GEOMETRYCOLLECTIONFROMWKB = 1037;
	public static readonly ST_GEOMETRYFROMTEXT = 1038;
	public static readonly ST_GEOMETRYFROMWKB = 1039;
	public static readonly ST_GEOMETRYN = 1040;
	public static readonly ST_GEOMETRYTYPE = 1041;
	public static readonly ST_GEOMFROMTEXT = 1042;
	public static readonly ST_GEOMFROMWKB = 1043;
	public static readonly ST_INTERIORRINGN = 1044;
	public static readonly ST_INTERSECTION = 1045;
	public static readonly ST_INTERSECTS = 1046;
	public static readonly ST_ISCLOSED = 1047;
	public static readonly ST_ISEMPTY = 1048;
	public static readonly ST_ISSIMPLE = 1049;
	public static readonly ST_LINEFROMTEXT = 1050;
	public static readonly ST_LINEFROMWKB = 1051;
	public static readonly ST_LINESTRINGFROMTEXT = 1052;
	public static readonly ST_LINESTRINGFROMWKB = 1053;
	public static readonly ST_NUMGEOMETRIES = 1054;
	public static readonly ST_NUMINTERIORRING = 1055;
	public static readonly ST_NUMINTERIORRINGS = 1056;
	public static readonly ST_NUMPOINTS = 1057;
	public static readonly ST_OVERLAPS = 1058;
	public static readonly ST_POINTFROMTEXT = 1059;
	public static readonly ST_POINTFROMWKB = 1060;
	public static readonly ST_POINTN = 1061;
	public static readonly ST_POLYFROMTEXT = 1062;
	public static readonly ST_POLYFROMWKB = 1063;
	public static readonly ST_POLYGONFROMTEXT = 1064;
	public static readonly ST_POLYGONFROMWKB = 1065;
	public static readonly ST_SRID = 1066;
	public static readonly ST_STARTPOINT = 1067;
	public static readonly ST_SYMDIFFERENCE = 1068;
	public static readonly ST_TOUCHES = 1069;
	public static readonly ST_UNION = 1070;
	public static readonly ST_WITHIN = 1071;
	public static readonly ST_X = 1072;
	public static readonly ST_Y = 1073;
	public static readonly SUBDATE = 1074;
	public static readonly SUBSTRING_INDEX = 1075;
	public static readonly SUBTIME = 1076;
	public static readonly SYSTEM_USER = 1077;
	public static readonly TAN = 1078;
	public static readonly TIMEDIFF = 1079;
	public static readonly TIMESTAMPADD = 1080;
	public static readonly TIMESTAMPDIFF = 1081;
	public static readonly TIME_FORMAT = 1082;
	public static readonly TIME_TO_SEC = 1083;
	public static readonly TOUCHES = 1084;
	public static readonly TO_BASE64 = 1085;
	public static readonly TO_DAYS = 1086;
	public static readonly TO_SECONDS = 1087;
	public static readonly TP_CONNECTION_ADMIN = 1088;
	public static readonly UCASE = 1089;
	public static readonly UNCOMPRESS = 1090;
	public static readonly UNCOMPRESSED_LENGTH = 1091;
	public static readonly UNHEX = 1092;
	public static readonly UNIX_TIMESTAMP = 1093;
	public static readonly UPDATEXML = 1094;
	public static readonly UPPER = 1095;
	public static readonly UUID = 1096;
	public static readonly UUID_SHORT = 1097;
	public static readonly VALIDATE_PASSWORD_STRENGTH = 1098;
	public static readonly VERSION = 1099;
	public static readonly WAIT_UNTIL_SQL_THREAD_AFTER_GTIDS = 1100;
	public static readonly WEEKDAY = 1101;
	public static readonly WEEKOFYEAR = 1102;
	public static readonly WEIGHT_STRING = 1103;
	public static readonly WITHIN = 1104;
	public static readonly YEARWEEK = 1105;
	public static readonly Y_FUNCTION = 1106;
	public static readonly X_FUNCTION = 1107;
	public static readonly VAR_ASSIGN = 1108;
	public static readonly PLUS_ASSIGN = 1109;
	public static readonly MINUS_ASSIGN = 1110;
	public static readonly MULT_ASSIGN = 1111;
	public static readonly DIV_ASSIGN = 1112;
	public static readonly MOD_ASSIGN = 1113;
	public static readonly AND_ASSIGN = 1114;
	public static readonly XOR_ASSIGN = 1115;
	public static readonly OR_ASSIGN = 1116;
	public static readonly STAR = 1117;
	public static readonly DIVIDE = 1118;
	public static readonly MODULE = 1119;
	public static readonly PLUS = 1120;
	public static readonly MINUS = 1121;
	public static readonly DIV = 1122;
	public static readonly MOD = 1123;
	public static readonly EQUAL_SYMBOL = 1124;
	public static readonly GREATER_SYMBOL = 1125;
	public static readonly LESS_SYMBOL = 1126;
	public static readonly EXCLAMATION_SYMBOL = 1127;
	public static readonly BIT_NOT_OP = 1128;
	public static readonly BIT_OR_OP = 1129;
	public static readonly BIT_AND_OP = 1130;
	public static readonly BIT_XOR_OP = 1131;
	public static readonly DOT = 1132;
	public static readonly LR_BRACKET = 1133;
	public static readonly RR_BRACKET = 1134;
	public static readonly COMMA = 1135;
	public static readonly SEMI = 1136;
	public static readonly AT_SIGN = 1137;
	public static readonly ZERO_DECIMAL = 1138;
	public static readonly ONE_DECIMAL = 1139;
	public static readonly TWO_DECIMAL = 1140;
	public static readonly SINGLE_QUOTE_SYMB = 1141;
	public static readonly DOUBLE_QUOTE_SYMB = 1142;
	public static readonly REVERSE_QUOTE_SYMB = 1143;
	public static readonly COLON_SYMB = 1144;
	public static readonly CHARSET_REVERSE_QOUTE_STRING = 1145;
	public static readonly FILESIZE_LITERAL = 1146;
	public static readonly START_NATIONAL_STRING_LITERAL = 1147;
	public static readonly STRING_LITERAL = 1148;
	public static readonly DECIMAL_LITERAL = 1149;
	public static readonly HEXADECIMAL_LITERAL = 1150;
	public static readonly REAL_LITERAL = 1151;
	public static readonly NULL_SPEC_LITERAL = 1152;
	public static readonly BIT_STRING = 1153;
	public static readonly STRING_CHARSET_NAME = 1154;
	public static readonly DOT_ID = 1155;
	public static readonly ID = 1156;
	public static readonly REVERSE_QUOTE_ID = 1157;
	public static readonly HOST_IP_ADDRESS = 1158;
	public static readonly LOCAL_ID = 1159;
	public static readonly GLOBAL_ID = 1160;
	public static readonly ERROR_RECONGNIGION = 1161;
	public static readonly EOF = Token.EOF;

	public static readonly channelNames: string[] = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN", 
                                                                                      "MYSQLCOMMENT", 
                                                                                      "ERRORCHANNEL" ];
	public static readonly literalNames: (string | null)[] = [ null, null, 
                                                            null, null, 
                                                            null, "'ADD'", 
                                                            "'ALL'", "'ALTER'", 
                                                            "'ALWAYS'", 
                                                            "'ANALYZE'", 
                                                            "'AND'", "'ARRAY'", 
                                                            "'AS'", "'ASC'", 
                                                            "'ATTRIBUTE'", 
                                                            "'BEFORE'", 
                                                            "'BETWEEN'", 
                                                            "'BOTH'", "'BUCKETS'", 
                                                            "'BY'", "'CALL'", 
                                                            "'CASCADE'", 
                                                            "'CASE'", "'CAST'", 
                                                            "'CHANGE'", 
                                                            "'CHARACTER'", 
                                                            "'CHECK'", "'COLLATE'", 
                                                            "'COLUMN'", 
                                                            "'CONDITION'", 
                                                            "'CONSTRAINT'", 
                                                            "'CONTINUE'", 
                                                            "'CONVERT'", 
                                                            "'CREATE'", 
                                                            "'CROSS'", "'CURRENT'", 
                                                            "'CURRENT_ROLE'", 
                                                            "'CURRENT_USER'", 
                                                            "'CURSOR'", 
                                                            "'DATABASE'", 
                                                            "'DATABASES'", 
                                                            "'DECLARE'", 
                                                            "'DEFAULT'", 
                                                            "'DELAYED'", 
                                                            "'DELETE'", 
                                                            "'DESC'", "'DESCRIBE'", 
                                                            "'DETERMINISTIC'", 
                                                            "'DIAGNOSTICS'", 
                                                            "'DISTINCT'", 
                                                            "'DISTINCTROW'", 
                                                            "'DROP'", "'EACH'", 
                                                            "'ELSE'", "'ELSEIF'", 
                                                            "'EMPTY'", "'ENCLOSED'", 
                                                            "'ENFORCED'", 
                                                            "'ESCAPED'", 
                                                            "'EXCEPT'", 
                                                            "'EXISTS'", 
                                                            "'EXIT'", "'EXPLAIN'", 
                                                            "'FALSE'", "'FETCH'", 
                                                            "'FOR'", "'FORCE'", 
                                                            "'FOREIGN'", 
                                                            "'FROM'", "'FULLTEXT'", 
                                                            "'GENERATED'", 
                                                            "'GET'", "'GRANT'", 
                                                            "'GROUP'", "'HAVING'", 
                                                            "'HIGH_PRIORITY'", 
                                                            "'HISTOGRAM'", 
                                                            "'IF'", "'IGNORE'", 
                                                            "'IGNORED'", 
                                                            "'IN'", "'INDEX'", 
                                                            "'INFILE'", 
                                                            "'INNER'", "'INOUT'", 
                                                            "'INSERT'", 
                                                            "'INTERVAL'", 
                                                            "'INTO'", "'IS'", 
                                                            "'ITERATE'", 
                                                            "'JOIN'", "'KEY'", 
                                                            "'KEYS'", "'KILL'", 
                                                            "'LATERAL'", 
                                                            "'LEADING'", 
                                                            "'LEAVE'", "'LEFT'", 
                                                            "'LIKE'", "'LIMIT'", 
                                                            "'LINEAR'", 
                                                            "'LINES'", "'LOAD'", 
                                                            "'LOCK'", "'LOCKED'", 
                                                            "'LOOP'", "'LOW_PRIORITY'", 
                                                            "'MASTER_BIND'", 
                                                            "'MASTER_SSL_VERIFY_SERVER_CERT'", 
                                                            "'MATCH'", "'MAXVALUE'", 
                                                            "'MINVALUE'", 
                                                            "'MODIFIES'", 
                                                            "'NATURAL'", 
                                                            "'NOT'", "'NO_WRITE_TO_BINLOG'", 
                                                            "'NULL'", "'NUMBER'", 
                                                            "'ON'", "'OPTIMIZE'", 
                                                            "'OPTION'", 
                                                            "'OPTIONAL'", 
                                                            "'OPTIONALLY'", 
                                                            "'OR'", "'ORDER'", 
                                                            "'OUT'", "'OUTER'", 
                                                            "'OUTFILE'", 
                                                            "'OVER'", "'PARTITION'", 
                                                            "'PRIMARY'", 
                                                            "'PROCEDURE'", 
                                                            "'PURGE'", "'RANGE'", 
                                                            "'READ'", "'READS'", 
                                                            "'REFERENCES'", 
                                                            "'REGEXP'", 
                                                            "'RELEASE'", 
                                                            "'RENAME'", 
                                                            "'REPEAT'", 
                                                            "'REPLACE'", 
                                                            "'REQUIRE'", 
                                                            "'RESIGNAL'", 
                                                            "'RESTRICT'", 
                                                            "'RETAIN'", 
                                                            "'RETURN'", 
                                                            "'REVOKE'", 
                                                            "'RIGHT'", "'RLIKE'", 
                                                            "'SCHEMA'", 
                                                            "'SCHEMAS'", 
                                                            "'SELECT'", 
                                                            "'SET'", "'SEPARATOR'", 
                                                            "'SHOW'", "'SIGNAL'", 
                                                            "'SKIP'", "'SKIP_QUERY_REWRITE'", 
                                                            "'SPATIAL'", 
                                                            "'SQL'", "'SQLEXCEPTION'", 
                                                            "'SQLSTATE'", 
                                                            "'SQLWARNING'", 
                                                            "'SQL_BIG_RESULT'", 
                                                            "'SQL_CALC_FOUND_ROWS'", 
                                                            "'SQL_SMALL_RESULT'", 
                                                            "'SSL'", "'STACKED'", 
                                                            "'STARTING'", 
                                                            "'STATEMENT'", 
                                                            "'STRAIGHT_JOIN'", 
                                                            "'TABLE'", "'TERMINATED'", 
                                                            "'THEN'", "'TO'", 
                                                            "'TRAILING'", 
                                                            "'TRIGGER'", 
                                                            "'TRUE'", "'UNDO'", 
                                                            "'UNION'", "'UNIQUE'", 
                                                            "'UNLOCK'", 
                                                            "'UNSIGNED'", 
                                                            "'UPDATE'", 
                                                            "'USAGE'", "'USE'", 
                                                            "'USING'", "'VALUES'", 
                                                            "'WHEN'", "'WHERE'", 
                                                            "'WHILE'", "'WITH'", 
                                                            "'WRITE'", "'XOR'", 
                                                            "'ZEROFILL'", 
                                                            "'TINYINT'", 
                                                            "'SMALLINT'", 
                                                            "'MEDIUMINT'", 
                                                            "'MIDDLEINT'", 
                                                            "'INT'", "'INT1'", 
                                                            "'INT2'", "'INT3'", 
                                                            "'INT4'", "'INT8'", 
                                                            "'INTEGER'", 
                                                            "'BIGINT'", 
                                                            "'REAL'", "'DOUBLE'", 
                                                            "'PRECISION'", 
                                                            "'FLOAT'", "'FLOAT4'", 
                                                            "'FLOAT8'", 
                                                            "'DECIMAL'", 
                                                            "'DEC'", "'NUMERIC'", 
                                                            "'DATE'", "'TIME'", 
                                                            "'TIMESTAMP'", 
                                                            "'DATETIME'", 
                                                            "'YEAR'", "'CHAR'", 
                                                            "'VARCHAR'", 
                                                            "'NVARCHAR'", 
                                                            "'NATIONAL'", 
                                                            "'BINARY'", 
                                                            "'VARBINARY'", 
                                                            "'TINYBLOB'", 
                                                            "'BLOB'", "'MEDIUMBLOB'", 
                                                            "'LONG'", "'LONGBLOB'", 
                                                            "'TINYTEXT'", 
                                                            "'TEXT'", "'MEDIUMTEXT'", 
                                                            "'LONGTEXT'", 
                                                            "'ENUM'", "'VARYING'", 
                                                            "'SERIAL'", 
                                                            "'YEAR_MONTH'", 
                                                            "'DAY_HOUR'", 
                                                            "'DAY_MINUTE'", 
                                                            "'DAY_SECOND'", 
                                                            "'HOUR_MINUTE'", 
                                                            "'HOUR_SECOND'", 
                                                            "'MINUTE_SECOND'", 
                                                            "'SECOND_MICROSECOND'", 
                                                            "'MINUTE_MICROSECOND'", 
                                                            "'HOUR_MICROSECOND'", 
                                                            "'DAY_MICROSECOND'", 
                                                            "'JSON_ARRAY'", 
                                                            "'JSON_ARRAYAGG'", 
                                                            "'JSON_ARRAY_APPEND'", 
                                                            "'JSON_ARRAY_INSERT'", 
                                                            "'JSON_CONTAINS'", 
                                                            "'JSON_CONTAINS_PATH'", 
                                                            "'JSON_DEPTH'", 
                                                            "'JSON_EXTRACT'", 
                                                            "'JSON_INSERT'", 
                                                            "'JSON_KEYS'", 
                                                            "'JSON_LENGTH'", 
                                                            "'JSON_MERGE'", 
                                                            "'JSON_MERGE_PATCH'", 
                                                            "'JSON_MERGE_PRESERVE'", 
                                                            "'JSON_OBJECT'", 
                                                            "'JSON_OBJECTAGG'", 
                                                            "'JSON_OVERLAPS'", 
                                                            "'JSON_PRETTY'", 
                                                            "'JSON_QUOTE'", 
                                                            "'JSON_REMOVE'", 
                                                            "'JSON_REPLACE'", 
                                                            "'JSON_SCHEMA_VALID'", 
                                                            "'JSON_SCHEMA_VALIDATION_REPORT'", 
                                                            "'JSON_SEARCH'", 
                                                            "'JSON_SET'", 
                                                            "'JSON_STORAGE_FREE'", 
                                                            "'JSON_STORAGE_SIZE'", 
                                                            "'JSON_TABLE'", 
                                                            "'JSON_TYPE'", 
                                                            "'JSON_UNQUOTE'", 
                                                            "'JSON_VALID'", 
                                                            "'JSON_VALUE'", 
                                                            "'NESTED'", 
                                                            "'ORDINALITY'", 
                                                            "'PATH'", "'AVG'", 
                                                            "'BIT_AND'", 
                                                            "'BIT_OR'", 
                                                            "'BIT_XOR'", 
                                                            "'COUNT'", "'CUME_DIST'", 
                                                            "'DENSE_RANK'", 
                                                            "'FIRST_VALUE'", 
                                                            "'GROUP_CONCAT'", 
                                                            "'LAG'", "'LAST_VALUE'", 
                                                            "'LEAD'", "'MAX'", 
                                                            "'MIN'", "'NTILE'", 
                                                            "'NTH_VALUE'", 
                                                            "'PERCENT_RANK'", 
                                                            "'RANK'", "'ROW_NUMBER'", 
                                                            "'STD'", "'STDDEV'", 
                                                            "'STDDEV_POP'", 
                                                            "'STDDEV_SAMP'", 
                                                            "'SUM'", "'VAR_POP'", 
                                                            "'VAR_SAMP'", 
                                                            "'VARIANCE'", 
                                                            "'CURRENT_DATE'", 
                                                            "'CURRENT_TIME'", 
                                                            "'CURRENT_TIMESTAMP'", 
                                                            "'LOCALTIME'", 
                                                            "'CURDATE'", 
                                                            "'CURTIME'", 
                                                            "'DATE_ADD'", 
                                                            "'DATE_SUB'", 
                                                            "'EXTRACT'", 
                                                            "'LOCALTIMESTAMP'", 
                                                            "'NOW'", "'POSITION'", 
                                                            "'SUBSTR'", 
                                                            "'SUBSTRING'", 
                                                            "'SYSDATE'", 
                                                            "'TRIM'", "'UTC_DATE'", 
                                                            "'UTC_TIME'", 
                                                            "'UTC_TIMESTAMP'", 
                                                            "'ACCOUNT'", 
                                                            "'ACTION'", 
                                                            "'AFTER'", "'AGGREGATE'", 
                                                            "'ALGORITHM'", 
                                                            "'ANY'", "'AT'", 
                                                            "'AUTHORS'", 
                                                            "'AUTOCOMMIT'", 
                                                            "'AUTOEXTEND_SIZE'", 
                                                            "'AUTO_INCREMENT'", 
                                                            "'AVG_ROW_LENGTH'", 
                                                            "'BEGIN'", "'BINLOG'", 
                                                            "'BIT'", "'BLOCK'", 
                                                            "'BOOL'", "'BOOLEAN'", 
                                                            "'BTREE'", "'CACHE'", 
                                                            "'CASCADED'", 
                                                            "'CHAIN'", "'CHANGED'", 
                                                            "'CHANNEL'", 
                                                            "'CHECKSUM'", 
                                                            "'PAGE_CHECKSUM'", 
                                                            "'CIPHER'", 
                                                            "'CLASS_ORIGIN'", 
                                                            "'CLIENT'", 
                                                            "'CLOSE'", "'CLUSTERING'", 
                                                            "'COALESCE'", 
                                                            "'CODE'", "'COLUMNS'", 
                                                            "'COLUMN_FORMAT'", 
                                                            "'COLUMN_NAME'", 
                                                            "'COMMENT'", 
                                                            "'COMMIT'", 
                                                            "'COMPACT'", 
                                                            "'COMPLETION'", 
                                                            "'COMPRESSED'", 
                                                            "'COMPRESSION'", 
                                                            "'CONCURRENT'", 
                                                            "'CONNECT'", 
                                                            "'CONNECTION'", 
                                                            "'CONSISTENT'", 
                                                            "'CONSTRAINT_CATALOG'", 
                                                            "'CONSTRAINT_SCHEMA'", 
                                                            "'CONSTRAINT_NAME'", 
                                                            "'CONTAINS'", 
                                                            "'CONTEXT'", 
                                                            "'CONTRIBUTORS'", 
                                                            "'COPY'", "'CPU'", 
                                                            "'CYCLE'", "'CURSOR_NAME'", 
                                                            "'DATA'", "'DATAFILE'", 
                                                            "'DEALLOCATE'", 
                                                            "'DEFAULT_AUTH'", 
                                                            "'DEFINER'", 
                                                            "'DELAY_KEY_WRITE'", 
                                                            "'DES_KEY_FILE'", 
                                                            "'DIRECTORY'", 
                                                            "'DISABLE'", 
                                                            "'DISCARD'", 
                                                            "'DISK'", "'DO'", 
                                                            "'DUMPFILE'", 
                                                            "'DUPLICATE'", 
                                                            "'DYNAMIC'", 
                                                            "'ENABLE'", 
                                                            "'ENCRYPTED'", 
                                                            "'ENCRYPTION'", 
                                                            "'ENCRYPTION_KEY_ID'", 
                                                            "'END'", "'ENDS'", 
                                                            "'ENGINE'", 
                                                            "'ENGINES'", 
                                                            "'ERROR'", "'ERRORS'", 
                                                            "'ESCAPE'", 
                                                            "'EVEN'", "'EVENT'", 
                                                            "'EVENTS'", 
                                                            "'EVERY'", "'EXCHANGE'", 
                                                            "'EXCLUSIVE'", 
                                                            "'EXPIRE'", 
                                                            "'EXPORT'", 
                                                            "'EXTENDED'", 
                                                            "'EXTENT_SIZE'", 
                                                            "'FAILED_LOGIN_ATTEMPTS'", 
                                                            "'FAST'", "'FAULTS'", 
                                                            "'FIELDS'", 
                                                            "'FILE_BLOCK_SIZE'", 
                                                            "'FILTER'", 
                                                            "'FIRST'", "'FIXED'", 
                                                            "'FLUSH'", "'FOLLOWING'", 
                                                            "'FOLLOWS'", 
                                                            "'FOUND'", "'FULL'", 
                                                            "'FUNCTION'", 
                                                            "'GENERAL'", 
                                                            "'GLOBAL'", 
                                                            "'GRANTS'", 
                                                            "'GROUP_REPLICATION'", 
                                                            "'HANDLER'", 
                                                            "'HASH'", "'HELP'", 
                                                            "'HISTORY'", 
                                                            "'HOST'", "'HOSTS'", 
                                                            "'IDENTIFIED'", 
                                                            "'IGNORE_SERVER_IDS'", 
                                                            "'IMPORT'", 
                                                            "'INCREMENT'", 
                                                            "'INDEXES'", 
                                                            "'INITIAL_SIZE'", 
                                                            "'INPLACE'", 
                                                            "'INSERT_METHOD'", 
                                                            "'INSTALL'", 
                                                            "'INSTANCE'", 
                                                            "'INSTANT'", 
                                                            "'INVISIBLE'", 
                                                            "'INVOKER'", 
                                                            "'IO'", "'IO_THREAD'", 
                                                            "'IPC'", "'ISOLATION'", 
                                                            "'ISSUER'", 
                                                            "'JSON'", "'KEY_BLOCK_SIZE'", 
                                                            "'LANGUAGE'", 
                                                            "'LAST'", "'LEAVES'", 
                                                            "'LESS'", "'LEVEL'", 
                                                            "'LIST'", "'LOCAL'", 
                                                            "'LOGFILE'", 
                                                            "'LOGS'", "'MASTER'", 
                                                            "'MASTER_AUTO_POSITION'", 
                                                            "'MASTER_CONNECT_RETRY'", 
                                                            "'MASTER_DELAY'", 
                                                            "'MASTER_HEARTBEAT_PERIOD'", 
                                                            "'MASTER_HOST'", 
                                                            "'MASTER_LOG_FILE'", 
                                                            "'MASTER_LOG_POS'", 
                                                            "'MASTER_PASSWORD'", 
                                                            "'MASTER_PORT'", 
                                                            "'MASTER_RETRY_COUNT'", 
                                                            "'MASTER_SSL'", 
                                                            "'MASTER_SSL_CA'", 
                                                            "'MASTER_SSL_CAPATH'", 
                                                            "'MASTER_SSL_CERT'", 
                                                            "'MASTER_SSL_CIPHER'", 
                                                            "'MASTER_SSL_CRL'", 
                                                            "'MASTER_SSL_CRLPATH'", 
                                                            "'MASTER_SSL_KEY'", 
                                                            "'MASTER_TLS_VERSION'", 
                                                            "'MASTER_USER'", 
                                                            "'MAX_CONNECTIONS_PER_HOUR'", 
                                                            "'MAX_QUERIES_PER_HOUR'", 
                                                            "'MAX_ROWS'", 
                                                            "'MAX_SIZE'", 
                                                            "'MAX_UPDATES_PER_HOUR'", 
                                                            "'MAX_USER_CONNECTIONS'", 
                                                            "'MEDIUM'", 
                                                            "'MEMBER'", 
                                                            "'MERGE'", "'MESSAGE_TEXT'", 
                                                            "'MID'", "'MIGRATE'", 
                                                            "'MIN_ROWS'", 
                                                            "'MODE'", "'MODIFY'", 
                                                            "'MUTEX'", "'MYSQL'", 
                                                            "'MYSQL_ERRNO'", 
                                                            "'NAME'", "'NAMES'", 
                                                            "'NCHAR'", "'NEVER'", 
                                                            "'NEXT'", "'NO'", 
                                                            "'NOCACHE'", 
                                                            "'NOCOPY'", 
                                                            "'NOCYCLE'", 
                                                            "'NOMAXVALUE'", 
                                                            "'NOMINVALUE'", 
                                                            "'NOWAIT'", 
                                                            "'NODEGROUP'", 
                                                            "'NONE'", "'ODBC'", 
                                                            "'OFFLINE'", 
                                                            "'OFFSET'", 
                                                            "'OF'", "'OJ'", 
                                                            "'OLD_PASSWORD'", 
                                                            "'ONE'", "'ONLINE'", 
                                                            "'ONLY'", "'OPEN'", 
                                                            "'OPTIMIZER_COSTS'", 
                                                            "'OPTIONS'", 
                                                            "'OWNER'", "'PACK_KEYS'", 
                                                            "'PAGE'", "'PAGE_COMPRESSED'", 
                                                            "'PAGE_COMPRESSION_LEVEL'", 
                                                            "'PARSER'", 
                                                            "'PARTIAL'", 
                                                            "'PARTITIONING'", 
                                                            "'PARTITIONS'", 
                                                            "'PASSWORD'", 
                                                            "'PASSWORD_LOCK_TIME'", 
                                                            "'PHASE'", "'PLUGIN'", 
                                                            "'PLUGIN_DIR'", 
                                                            "'PLUGINS'", 
                                                            "'PORT'", "'PRECEDES'", 
                                                            "'PRECEDING'", 
                                                            "'PREPARE'", 
                                                            "'PRESERVE'", 
                                                            "'PREV'", "'PROCESSLIST'", 
                                                            "'PROFILE'", 
                                                            "'PROFILES'", 
                                                            "'PROXY'", "'QUERY'", 
                                                            "'QUICK'", "'REBUILD'", 
                                                            "'RECOVER'", 
                                                            "'RECURSIVE'", 
                                                            "'REDO_BUFFER_SIZE'", 
                                                            "'REDUNDANT'", 
                                                            "'RELAY'", "'RELAY_LOG_FILE'", 
                                                            "'RELAY_LOG_POS'", 
                                                            "'RELAYLOG'", 
                                                            "'REMOVE'", 
                                                            "'REORGANIZE'", 
                                                            "'REPAIR'", 
                                                            "'REPLICATE_DO_DB'", 
                                                            "'REPLICATE_DO_TABLE'", 
                                                            "'REPLICATE_IGNORE_DB'", 
                                                            "'REPLICATE_IGNORE_TABLE'", 
                                                            "'REPLICATE_REWRITE_DB'", 
                                                            "'REPLICATE_WILD_DO_TABLE'", 
                                                            "'REPLICATE_WILD_IGNORE_TABLE'", 
                                                            "'REPLICATION'", 
                                                            "'RESET'", "'RESTART'", 
                                                            "'RESUME'", 
                                                            "'RETURNED_SQLSTATE'", 
                                                            "'RETURNING'", 
                                                            "'RETURNS'", 
                                                            "'REUSE'", "'ROLE'", 
                                                            "'ROLLBACK'", 
                                                            "'ROLLUP'", 
                                                            "'ROTATE'", 
                                                            "'ROW'", "'ROWS'", 
                                                            "'ROW_FORMAT'", 
                                                            "'RTREE'", "'SAVEPOINT'", 
                                                            "'SCHEDULE'", 
                                                            "'SECURITY'", 
                                                            "'SEQUENCE'", 
                                                            "'SERVER'", 
                                                            "'SESSION'", 
                                                            "'SHARE'", "'SHARED'", 
                                                            "'SIGNED'", 
                                                            "'SIMPLE'", 
                                                            "'SLAVE'", "'SLOW'", 
                                                            "'SNAPSHOT'", 
                                                            "'SOCKET'", 
                                                            "'SOME'", "'SONAME'", 
                                                            "'SOUNDS'", 
                                                            "'SOURCE'", 
                                                            "'SQL_AFTER_GTIDS'", 
                                                            "'SQL_AFTER_MTS_GAPS'", 
                                                            "'SQL_BEFORE_GTIDS'", 
                                                            "'SQL_BUFFER_RESULT'", 
                                                            "'SQL_CACHE'", 
                                                            "'SQL_NO_CACHE'", 
                                                            "'SQL_THREAD'", 
                                                            "'START'", "'STARTS'", 
                                                            "'STATS_AUTO_RECALC'", 
                                                            "'STATS_PERSISTENT'", 
                                                            "'STATS_SAMPLE_PAGES'", 
                                                            "'STATUS'", 
                                                            "'STOP'", "'STORAGE'", 
                                                            "'STORED'", 
                                                            "'STRING'", 
                                                            "'SUBCLASS_ORIGIN'", 
                                                            "'SUBJECT'", 
                                                            "'SUBPARTITION'", 
                                                            "'SUBPARTITIONS'", 
                                                            "'SUSPEND'", 
                                                            "'SWAPS'", "'SWITCHES'", 
                                                            "'TABLE_NAME'", 
                                                            "'TABLESPACE'", 
                                                            "'TABLE_TYPE'", 
                                                            "'TEMPORARY'", 
                                                            "'TEMPTABLE'", 
                                                            "'THAN'", "'TRADITIONAL'", 
                                                            "'TRANSACTION'", 
                                                            "'TRANSACTIONAL'", 
                                                            "'TRIGGERS'", 
                                                            "'TRUNCATE'", 
                                                            "'UNBOUNDED'", 
                                                            "'UNDEFINED'", 
                                                            "'UNDOFILE'", 
                                                            "'UNDO_BUFFER_SIZE'", 
                                                            "'UNINSTALL'", 
                                                            "'UNKNOWN'", 
                                                            "'UNTIL'", "'UPGRADE'", 
                                                            "'USER'", "'USE_FRM'", 
                                                            "'USER_RESOURCES'", 
                                                            "'VALIDATION'", 
                                                            "'VALUE'", "'VARIABLES'", 
                                                            "'VIEW'", "'VIRTUAL'", 
                                                            "'VISIBLE'", 
                                                            "'WAIT'", "'WARNINGS'", 
                                                            "'WINDOW'", 
                                                            "'WITHOUT'", 
                                                            "'WORK'", "'WRAPPER'", 
                                                            "'X509'", "'XA'", 
                                                            "'XML'", "'YES'", 
                                                            "'EUR'", "'USA'", 
                                                            "'JIS'", "'ISO'", 
                                                            "'INTERNAL'", 
                                                            "'QUARTER'", 
                                                            "'MONTH'", "'DAY'", 
                                                            "'HOUR'", "'MINUTE'", 
                                                            "'WEEK'", "'SECOND'", 
                                                            "'MICROSECOND'", 
                                                            "'ADMIN'", "'APPLICATION_PASSWORD_ADMIN'", 
                                                            "'AUDIT_ABORT_EXEMPT'", 
                                                            "'AUDIT_ADMIN'", 
                                                            "'AUTHENTICATION_POLICY_ADMIN'", 
                                                            "'BACKUP_ADMIN'", 
                                                            "'BINLOG_ADMIN'", 
                                                            "'BINLOG_ENCRYPTION_ADMIN'", 
                                                            "'CLONE_ADMIN'", 
                                                            "'CONNECTION_ADMIN'", 
                                                            "'ENCRYPTION_KEY_ADMIN'", 
                                                            "'EXECUTE'", 
                                                            "'FILE'", "'FIREWALL_ADMIN'", 
                                                            "'FIREWALL_EXEMPT'", 
                                                            "'FIREWALL_USER'", 
                                                            "'FLUSH_OPTIMIZER_COSTS'", 
                                                            "'FLUSH_STATUS'", 
                                                            "'FLUSH_TABLES'", 
                                                            "'FLUSH_USER_RESOURCES'", 
                                                            "'GROUP_REPLICATION_ADMIN'", 
                                                            "'INNODB_REDO_LOG_ARCHIVE'", 
                                                            "'INNODB_REDO_LOG_ENABLE'", 
                                                            "'INVOKE'", 
                                                            "'LAMBDA'", 
                                                            "'NDB_STORED_USER'", 
                                                            "'PASSWORDLESS_USER_ADMIN'", 
                                                            "'PERSIST_RO_VARIABLES_ADMIN'", 
                                                            "'PRIVILEGES'", 
                                                            "'PROCESS'", 
                                                            "'RELOAD'", 
                                                            "'REPLICATION_APPLIER'", 
                                                            "'REPLICATION_SLAVE_ADMIN'", 
                                                            "'RESOURCE_GROUP_ADMIN'", 
                                                            "'RESOURCE_GROUP_USER'", 
                                                            "'ROLE_ADMIN'", 
                                                            "'ROUTINE'", 
                                                            "'S3'", "'SERVICE_CONNECTION_ADMIN'", 
                                                            null, "'SET_USER_ID'", 
                                                            "'SHOW_ROUTINE'", 
                                                            "'SHUTDOWN'", 
                                                            "'SUPER'", "'SYSTEM_VARIABLES_ADMIN'", 
                                                            "'TABLES'", 
                                                            "'TABLE_ENCRYPTION_ADMIN'", 
                                                            "'VERSION_TOKEN_ADMIN'", 
                                                            "'XA_RECOVER_ADMIN'", 
                                                            "'ARMSCII8'", 
                                                            "'ASCII'", "'BIG5'", 
                                                            "'CP1250'", 
                                                            "'CP1251'", 
                                                            "'CP1256'", 
                                                            "'CP1257'", 
                                                            "'CP850'", "'CP852'", 
                                                            "'CP866'", "'CP932'", 
                                                            "'DEC8'", "'EUCJPMS'", 
                                                            "'EUCKR'", "'GB18030'", 
                                                            "'GB2312'", 
                                                            "'GBK'", "'GEOSTD8'", 
                                                            "'GREEK'", "'HEBREW'", 
                                                            "'HP8'", "'KEYBCS2'", 
                                                            "'KOI8R'", "'KOI8U'", 
                                                            "'LATIN1'", 
                                                            "'LATIN2'", 
                                                            "'LATIN5'", 
                                                            "'LATIN7'", 
                                                            "'MACCE'", "'MACROMAN'", 
                                                            "'SJIS'", "'SWE7'", 
                                                            "'TIS620'", 
                                                            "'UCS2'", "'UJIS'", 
                                                            "'UTF16'", "'UTF16LE'", 
                                                            "'UTF32'", "'UTF8'", 
                                                            "'UTF8MB3'", 
                                                            "'UTF8MB4'", 
                                                            "'ARCHIVE'", 
                                                            "'BLACKHOLE'", 
                                                            "'CSV'", "'FEDERATED'", 
                                                            "'INNODB'", 
                                                            "'MEMORY'", 
                                                            "'MRG_MYISAM'", 
                                                            "'MYISAM'", 
                                                            "'NDB'", "'NDBCLUSTER'", 
                                                            "'PERFORMANCE_SCHEMA'", 
                                                            "'TOKUDB'", 
                                                            "'REPEATABLE'", 
                                                            "'COMMITTED'", 
                                                            "'UNCOMMITTED'", 
                                                            "'SERIALIZABLE'", 
                                                            "'GEOMETRYCOLLECTION'", 
                                                            "'GEOMCOLLECTION'", 
                                                            "'GEOMETRY'", 
                                                            "'LINESTRING'", 
                                                            "'MULTILINESTRING'", 
                                                            "'MULTIPOINT'", 
                                                            "'MULTIPOLYGON'", 
                                                            "'POINT'", "'POLYGON'", 
                                                            "'ABS'", "'ACOS'", 
                                                            "'ADDDATE'", 
                                                            "'ADDTIME'", 
                                                            "'AES_DECRYPT'", 
                                                            "'AES_ENCRYPT'", 
                                                            "'AREA'", "'ASBINARY'", 
                                                            "'ASIN'", "'ASTEXT'", 
                                                            "'ASWKB'", "'ASWKT'", 
                                                            "'ASYMMETRIC_DECRYPT'", 
                                                            "'ASYMMETRIC_DERIVE'", 
                                                            "'ASYMMETRIC_ENCRYPT'", 
                                                            "'ASYMMETRIC_SIGN'", 
                                                            "'ASYMMETRIC_VERIFY'", 
                                                            "'ATAN'", "'ATAN2'", 
                                                            "'BENCHMARK'", 
                                                            "'BIN'", "'BIT_COUNT'", 
                                                            "'BIT_LENGTH'", 
                                                            "'BUFFER'", 
                                                            "'CATALOG_NAME'", 
                                                            "'CEIL'", "'CEILING'", 
                                                            "'CENTROID'", 
                                                            "'CHARACTER_LENGTH'", 
                                                            "'CHARSET'", 
                                                            "'CHAR_LENGTH'", 
                                                            "'COERCIBILITY'", 
                                                            "'COLLATION'", 
                                                            "'COMPRESS'", 
                                                            "'CONCAT'", 
                                                            "'CONCAT_WS'", 
                                                            "'CONNECTION_ID'", 
                                                            "'CONV'", "'CONVERT_TZ'", 
                                                            "'COS'", "'COT'", 
                                                            "'CRC32'", "'CREATE_ASYMMETRIC_PRIV_KEY'", 
                                                            "'CREATE_ASYMMETRIC_PUB_KEY'", 
                                                            "'CREATE_DH_PARAMETERS'", 
                                                            "'CREATE_DIGEST'", 
                                                            "'CROSSES'", 
                                                            "'DATEDIFF'", 
                                                            "'DATE_FORMAT'", 
                                                            "'DAYNAME'", 
                                                            "'DAYOFMONTH'", 
                                                            "'DAYOFWEEK'", 
                                                            "'DAYOFYEAR'", 
                                                            "'DECODE'", 
                                                            "'DEGREES'", 
                                                            "'DES_DECRYPT'", 
                                                            "'DES_ENCRYPT'", 
                                                            "'DIMENSION'", 
                                                            "'DISJOINT'", 
                                                            "'ELT'", "'ENCODE'", 
                                                            "'ENCRYPT'", 
                                                            "'ENDPOINT'", 
                                                            "'ENGINE_ATTRIBUTE'", 
                                                            "'ENVELOPE'", 
                                                            "'EQUALS'", 
                                                            "'EXP'", "'EXPORT_SET'", 
                                                            "'EXTERIORRING'", 
                                                            "'EXTRACTVALUE'", 
                                                            "'FIELD'", "'FIND_IN_SET'", 
                                                            "'FLOOR'", "'FORMAT'", 
                                                            "'FOUND_ROWS'", 
                                                            "'FROM_BASE64'", 
                                                            "'FROM_DAYS'", 
                                                            "'FROM_UNIXTIME'", 
                                                            "'GEOMCOLLFROMTEXT'", 
                                                            "'GEOMCOLLFROMWKB'", 
                                                            "'GEOMETRYCOLLECTIONFROMTEXT'", 
                                                            "'GEOMETRYCOLLECTIONFROMWKB'", 
                                                            "'GEOMETRYFROMTEXT'", 
                                                            "'GEOMETRYFROMWKB'", 
                                                            "'GEOMETRYN'", 
                                                            "'GEOMETRYTYPE'", 
                                                            "'GEOMFROMTEXT'", 
                                                            "'GEOMFROMWKB'", 
                                                            "'GET_FORMAT'", 
                                                            "'GET_LOCK'", 
                                                            "'GLENGTH'", 
                                                            "'GREATEST'", 
                                                            "'GTID_SUBSET'", 
                                                            "'GTID_SUBTRACT'", 
                                                            "'HEX'", "'IFNULL'", 
                                                            "'INET6_ATON'", 
                                                            "'INET6_NTOA'", 
                                                            "'INET_ATON'", 
                                                            "'INET_NTOA'", 
                                                            "'INSTR'", "'INTERIORRINGN'", 
                                                            "'INTERSECTS'", 
                                                            "'ISCLOSED'", 
                                                            "'ISEMPTY'", 
                                                            "'ISNULL'", 
                                                            "'ISSIMPLE'", 
                                                            "'IS_FREE_LOCK'", 
                                                            "'IS_IPV4'", 
                                                            "'IS_IPV4_COMPAT'", 
                                                            "'IS_IPV4_MAPPED'", 
                                                            "'IS_IPV6'", 
                                                            "'IS_USED_LOCK'", 
                                                            "'LAST_INSERT_ID'", 
                                                            "'LCASE'", "'LEAST'", 
                                                            "'LENGTH'", 
                                                            "'LINEFROMTEXT'", 
                                                            "'LINEFROMWKB'", 
                                                            "'LINESTRINGFROMTEXT'", 
                                                            "'LINESTRINGFROMWKB'", 
                                                            "'LN'", "'LOAD_FILE'", 
                                                            "'LOCATE'", 
                                                            "'LOG'", "'LOG10'", 
                                                            "'LOG2'", "'LOWER'", 
                                                            "'LPAD'", "'LTRIM'", 
                                                            "'MAKEDATE'", 
                                                            "'MAKETIME'", 
                                                            "'MAKE_SET'", 
                                                            "'MASTER_POS_WAIT'", 
                                                            "'MBRCONTAINS'", 
                                                            "'MBRDISJOINT'", 
                                                            "'MBREQUAL'", 
                                                            "'MBRINTERSECTS'", 
                                                            "'MBROVERLAPS'", 
                                                            "'MBRTOUCHES'", 
                                                            "'MBRWITHIN'", 
                                                            "'MD5'", "'MLINEFROMTEXT'", 
                                                            "'MLINEFROMWKB'", 
                                                            "'MONTHNAME'", 
                                                            "'MPOINTFROMTEXT'", 
                                                            "'MPOINTFROMWKB'", 
                                                            "'MPOLYFROMTEXT'", 
                                                            "'MPOLYFROMWKB'", 
                                                            "'MULTILINESTRINGFROMTEXT'", 
                                                            "'MULTILINESTRINGFROMWKB'", 
                                                            "'MULTIPOINTFROMTEXT'", 
                                                            "'MULTIPOINTFROMWKB'", 
                                                            "'MULTIPOLYGONFROMTEXT'", 
                                                            "'MULTIPOLYGONFROMWKB'", 
                                                            "'NAME_CONST'", 
                                                            "'NULLIF'", 
                                                            "'NUMGEOMETRIES'", 
                                                            "'NUMINTERIORRINGS'", 
                                                            "'NUMPOINTS'", 
                                                            "'OCT'", "'OCTET_LENGTH'", 
                                                            "'ORD'", "'OVERLAPS'", 
                                                            "'PERIOD_ADD'", 
                                                            "'PERIOD_DIFF'", 
                                                            "'PI'", "'POINTFROMTEXT'", 
                                                            "'POINTFROMWKB'", 
                                                            "'POINTN'", 
                                                            "'POLYFROMTEXT'", 
                                                            "'POLYFROMWKB'", 
                                                            "'POLYGONFROMTEXT'", 
                                                            "'POLYGONFROMWKB'", 
                                                            "'POW'", "'POWER'", 
                                                            "'QUOTE'", "'RADIANS'", 
                                                            "'RAND'", "'RANDOM'", 
                                                            "'RANDOM_BYTES'", 
                                                            "'RELEASE_LOCK'", 
                                                            "'REVERSE'", 
                                                            "'ROUND'", "'ROW_COUNT'", 
                                                            "'RPAD'", "'RTRIM'", 
                                                            "'SEC_TO_TIME'", 
                                                            "'SECONDARY_ENGINE_ATTRIBUTE'", 
                                                            "'SESSION_USER'", 
                                                            "'SHA'", "'SHA1'", 
                                                            "'SHA2'", "'SCHEMA_NAME'", 
                                                            "'SIGN'", "'SIN'", 
                                                            "'SLEEP'", "'SOUNDEX'", 
                                                            "'SQL_THREAD_WAIT_AFTER_GTIDS'", 
                                                            "'SQRT'", "'SRID'", 
                                                            "'STARTPOINT'", 
                                                            "'STRCMP'", 
                                                            "'STR_TO_DATE'", 
                                                            "'ST_AREA'", 
                                                            "'ST_ASBINARY'", 
                                                            "'ST_ASTEXT'", 
                                                            "'ST_ASWKB'", 
                                                            "'ST_ASWKT'", 
                                                            "'ST_BUFFER'", 
                                                            "'ST_CENTROID'", 
                                                            "'ST_CONTAINS'", 
                                                            "'ST_CROSSES'", 
                                                            "'ST_DIFFERENCE'", 
                                                            "'ST_DIMENSION'", 
                                                            "'ST_DISJOINT'", 
                                                            "'ST_DISTANCE'", 
                                                            "'ST_ENDPOINT'", 
                                                            "'ST_ENVELOPE'", 
                                                            "'ST_EQUALS'", 
                                                            "'ST_EXTERIORRING'", 
                                                            "'ST_GEOMCOLLFROMTEXT'", 
                                                            "'ST_GEOMCOLLFROMTXT'", 
                                                            "'ST_GEOMCOLLFROMWKB'", 
                                                            "'ST_GEOMETRYCOLLECTIONFROMTEXT'", 
                                                            "'ST_GEOMETRYCOLLECTIONFROMWKB'", 
                                                            "'ST_GEOMETRYFROMTEXT'", 
                                                            "'ST_GEOMETRYFROMWKB'", 
                                                            "'ST_GEOMETRYN'", 
                                                            "'ST_GEOMETRYTYPE'", 
                                                            "'ST_GEOMFROMTEXT'", 
                                                            "'ST_GEOMFROMWKB'", 
                                                            "'ST_INTERIORRINGN'", 
                                                            "'ST_INTERSECTION'", 
                                                            "'ST_INTERSECTS'", 
                                                            "'ST_ISCLOSED'", 
                                                            "'ST_ISEMPTY'", 
                                                            "'ST_ISSIMPLE'", 
                                                            "'ST_LINEFROMTEXT'", 
                                                            "'ST_LINEFROMWKB'", 
                                                            "'ST_LINESTRINGFROMTEXT'", 
                                                            "'ST_LINESTRINGFROMWKB'", 
                                                            "'ST_NUMGEOMETRIES'", 
                                                            "'ST_NUMINTERIORRING'", 
                                                            "'ST_NUMINTERIORRINGS'", 
                                                            "'ST_NUMPOINTS'", 
                                                            "'ST_OVERLAPS'", 
                                                            "'ST_POINTFROMTEXT'", 
                                                            "'ST_POINTFROMWKB'", 
                                                            "'ST_POINTN'", 
                                                            "'ST_POLYFROMTEXT'", 
                                                            "'ST_POLYFROMWKB'", 
                                                            "'ST_POLYGONFROMTEXT'", 
                                                            "'ST_POLYGONFROMWKB'", 
                                                            "'ST_SRID'", 
                                                            "'ST_STARTPOINT'", 
                                                            "'ST_SYMDIFFERENCE'", 
                                                            "'ST_TOUCHES'", 
                                                            "'ST_UNION'", 
                                                            "'ST_WITHIN'", 
                                                            "'ST_X'", "'ST_Y'", 
                                                            "'SUBDATE'", 
                                                            "'SUBSTRING_INDEX'", 
                                                            "'SUBTIME'", 
                                                            "'SYSTEM_USER'", 
                                                            "'TAN'", "'TIMEDIFF'", 
                                                            "'TIMESTAMPADD'", 
                                                            "'TIMESTAMPDIFF'", 
                                                            "'TIME_FORMAT'", 
                                                            "'TIME_TO_SEC'", 
                                                            "'TOUCHES'", 
                                                            "'TO_BASE64'", 
                                                            "'TO_DAYS'", 
                                                            "'TO_SECONDS'", 
                                                            "'TP_CONNECTION_ADMIN'", 
                                                            "'UCASE'", "'UNCOMPRESS'", 
                                                            "'UNCOMPRESSED_LENGTH'", 
                                                            "'UNHEX'", "'UNIX_TIMESTAMP'", 
                                                            "'UPDATEXML'", 
                                                            "'UPPER'", "'UUID'", 
                                                            "'UUID_SHORT'", 
                                                            "'VALIDATE_PASSWORD_STRENGTH'", 
                                                            "'VERSION'", 
                                                            "'WAIT_UNTIL_SQL_THREAD_AFTER_GTIDS'", 
                                                            "'WEEKDAY'", 
                                                            "'WEEKOFYEAR'", 
                                                            "'WEIGHT_STRING'", 
                                                            "'WITHIN'", 
                                                            "'YEARWEEK'", 
                                                            "'Y'", "'X'", 
                                                            "':='", "'+='", 
                                                            "'-='", "'*='", 
                                                            "'/='", "'%='", 
                                                            "'&='", "'^='", 
                                                            "'|='", "'*'", 
                                                            "'/'", "'%'", 
                                                            "'+'", "'-'", 
                                                            "'DIV'", "'MOD'", 
                                                            "'='", "'>'", 
                                                            "'<'", "'!'", 
                                                            "'~'", "'|'", 
                                                            "'&'", "'^'", 
                                                            "'.'", "'('", 
                                                            "')'", "','", 
                                                            "';'", "'@'", 
                                                            "'0'", "'1'", 
                                                            "'2'", "'''", 
                                                            "'\"'", "'`'", 
                                                            "':'" ];
	public static readonly symbolicNames: (string | null)[] = [ null, "SPACE", 
                                                             "SPEC_MYSQL_COMMENT", 
                                                             "COMMENT_INPUT", 
                                                             "LINE_COMMENT", 
                                                             "ADD", "ALL", 
                                                             "ALTER", "ALWAYS", 
                                                             "ANALYZE", 
                                                             "AND", "ARRAY", 
                                                             "AS", "ASC", 
                                                             "ATTRIBUTE", 
                                                             "BEFORE", "BETWEEN", 
                                                             "BOTH", "BUCKETS", 
                                                             "BY", "CALL", 
                                                             "CASCADE", 
                                                             "CASE", "CAST", 
                                                             "CHANGE", "CHARACTER", 
                                                             "CHECK", "COLLATE", 
                                                             "COLUMN", "CONDITION", 
                                                             "CONSTRAINT", 
                                                             "CONTINUE", 
                                                             "CONVERT", 
                                                             "CREATE", "CROSS", 
                                                             "CURRENT", 
                                                             "CURRENT_ROLE", 
                                                             "CURRENT_USER", 
                                                             "CURSOR", "DATABASE", 
                                                             "DATABASES", 
                                                             "DECLARE", 
                                                             "DEFAULT", 
                                                             "DELAYED", 
                                                             "DELETE", "DESC", 
                                                             "DESCRIBE", 
                                                             "DETERMINISTIC", 
                                                             "DIAGNOSTICS", 
                                                             "DISTINCT", 
                                                             "DISTINCTROW", 
                                                             "DROP", "EACH", 
                                                             "ELSE", "ELSEIF", 
                                                             "EMPTY", "ENCLOSED", 
                                                             "ENFORCED", 
                                                             "ESCAPED", 
                                                             "EXCEPT", "EXISTS", 
                                                             "EXIT", "EXPLAIN", 
                                                             "FALSE", "FETCH", 
                                                             "FOR", "FORCE", 
                                                             "FOREIGN", 
                                                             "FROM", "FULLTEXT", 
                                                             "GENERATED", 
                                                             "GET", "GRANT", 
                                                             "GROUP", "HAVING", 
                                                             "HIGH_PRIORITY", 
                                                             "HISTOGRAM", 
                                                             "IF", "IGNORE", 
                                                             "IGNORED", 
                                                             "IN", "INDEX", 
                                                             "INFILE", "INNER", 
                                                             "INOUT", "INSERT", 
                                                             "INTERVAL", 
                                                             "INTO", "IS", 
                                                             "ITERATE", 
                                                             "JOIN", "KEY", 
                                                             "KEYS", "KILL", 
                                                             "LATERAL", 
                                                             "LEADING", 
                                                             "LEAVE", "LEFT", 
                                                             "LIKE", "LIMIT", 
                                                             "LINEAR", "LINES", 
                                                             "LOAD", "LOCK", 
                                                             "LOCKED", "LOOP", 
                                                             "LOW_PRIORITY", 
                                                             "MASTER_BIND", 
                                                             "MASTER_SSL_VERIFY_SERVER_CERT", 
                                                             "MATCH", "MAXVALUE", 
                                                             "MINVALUE", 
                                                             "MODIFIES", 
                                                             "NATURAL", 
                                                             "NOT", "NO_WRITE_TO_BINLOG", 
                                                             "NULL_LITERAL", 
                                                             "NUMBER", "ON", 
                                                             "OPTIMIZE", 
                                                             "OPTION", "OPTIONAL", 
                                                             "OPTIONALLY", 
                                                             "OR", "ORDER", 
                                                             "OUT", "OUTER", 
                                                             "OUTFILE", 
                                                             "OVER", "PARTITION", 
                                                             "PRIMARY", 
                                                             "PROCEDURE", 
                                                             "PURGE", "RANGE", 
                                                             "READ", "READS", 
                                                             "REFERENCES", 
                                                             "REGEXP", "RELEASE", 
                                                             "RENAME", "REPEAT", 
                                                             "REPLACE", 
                                                             "REQUIRE", 
                                                             "RESIGNAL", 
                                                             "RESTRICT", 
                                                             "RETAIN", "RETURN", 
                                                             "REVOKE", "RIGHT", 
                                                             "RLIKE", "SCHEMA", 
                                                             "SCHEMAS", 
                                                             "SELECT", "SET", 
                                                             "SEPARATOR", 
                                                             "SHOW", "SIGNAL", 
                                                             "SKIP_", "SKIP_QUERY_REWRITE", 
                                                             "SPATIAL", 
                                                             "SQL", "SQLEXCEPTION", 
                                                             "SQLSTATE", 
                                                             "SQLWARNING", 
                                                             "SQL_BIG_RESULT", 
                                                             "SQL_CALC_FOUND_ROWS", 
                                                             "SQL_SMALL_RESULT", 
                                                             "SSL", "STACKED", 
                                                             "STARTING", 
                                                             "STATEMENT", 
                                                             "STRAIGHT_JOIN", 
                                                             "TABLE", "TERMINATED", 
                                                             "THEN", "TO", 
                                                             "TRAILING", 
                                                             "TRIGGER", 
                                                             "TRUE", "UNDO", 
                                                             "UNION", "UNIQUE", 
                                                             "UNLOCK", "UNSIGNED", 
                                                             "UPDATE", "USAGE", 
                                                             "USE", "USING", 
                                                             "VALUES", "WHEN", 
                                                             "WHERE", "WHILE", 
                                                             "WITH", "WRITE", 
                                                             "XOR", "ZEROFILL", 
                                                             "TINYINT", 
                                                             "SMALLINT", 
                                                             "MEDIUMINT", 
                                                             "MIDDLEINT", 
                                                             "INT", "INT1", 
                                                             "INT2", "INT3", 
                                                             "INT4", "INT8", 
                                                             "INTEGER", 
                                                             "BIGINT", "REAL", 
                                                             "DOUBLE", "PRECISION", 
                                                             "FLOAT", "FLOAT4", 
                                                             "FLOAT8", "DECIMAL", 
                                                             "DEC", "NUMERIC", 
                                                             "DATE", "TIME", 
                                                             "TIMESTAMP", 
                                                             "DATETIME", 
                                                             "YEAR", "CHAR", 
                                                             "VARCHAR", 
                                                             "NVARCHAR", 
                                                             "NATIONAL", 
                                                             "BINARY", "VARBINARY", 
                                                             "TINYBLOB", 
                                                             "BLOB", "MEDIUMBLOB", 
                                                             "LONG", "LONGBLOB", 
                                                             "TINYTEXT", 
                                                             "TEXT", "MEDIUMTEXT", 
                                                             "LONGTEXT", 
                                                             "ENUM", "VARYING", 
                                                             "SERIAL", "YEAR_MONTH", 
                                                             "DAY_HOUR", 
                                                             "DAY_MINUTE", 
                                                             "DAY_SECOND", 
                                                             "HOUR_MINUTE", 
                                                             "HOUR_SECOND", 
                                                             "MINUTE_SECOND", 
                                                             "SECOND_MICROSECOND", 
                                                             "MINUTE_MICROSECOND", 
                                                             "HOUR_MICROSECOND", 
                                                             "DAY_MICROSECOND", 
                                                             "JSON_ARRAY", 
                                                             "JSON_ARRAYAGG", 
                                                             "JSON_ARRAY_APPEND", 
                                                             "JSON_ARRAY_INSERT", 
                                                             "JSON_CONTAINS", 
                                                             "JSON_CONTAINS_PATH", 
                                                             "JSON_DEPTH", 
                                                             "JSON_EXTRACT", 
                                                             "JSON_INSERT", 
                                                             "JSON_KEYS", 
                                                             "JSON_LENGTH", 
                                                             "JSON_MERGE", 
                                                             "JSON_MERGE_PATCH", 
                                                             "JSON_MERGE_PRESERVE", 
                                                             "JSON_OBJECT", 
                                                             "JSON_OBJECTAGG", 
                                                             "JSON_OVERLAPS", 
                                                             "JSON_PRETTY", 
                                                             "JSON_QUOTE", 
                                                             "JSON_REMOVE", 
                                                             "JSON_REPLACE", 
                                                             "JSON_SCHEMA_VALID", 
                                                             "JSON_SCHEMA_VALIDATION_REPORT", 
                                                             "JSON_SEARCH", 
                                                             "JSON_SET", 
                                                             "JSON_STORAGE_FREE", 
                                                             "JSON_STORAGE_SIZE", 
                                                             "JSON_TABLE", 
                                                             "JSON_TYPE", 
                                                             "JSON_UNQUOTE", 
                                                             "JSON_VALID", 
                                                             "JSON_VALUE", 
                                                             "NESTED", "ORDINALITY", 
                                                             "PATH", "AVG", 
                                                             "BIT_AND", 
                                                             "BIT_OR", "BIT_XOR", 
                                                             "COUNT", "CUME_DIST", 
                                                             "DENSE_RANK", 
                                                             "FIRST_VALUE", 
                                                             "GROUP_CONCAT", 
                                                             "LAG", "LAST_VALUE", 
                                                             "LEAD", "MAX", 
                                                             "MIN", "NTILE", 
                                                             "NTH_VALUE", 
                                                             "PERCENT_RANK", 
                                                             "RANK", "ROW_NUMBER", 
                                                             "STD", "STDDEV", 
                                                             "STDDEV_POP", 
                                                             "STDDEV_SAMP", 
                                                             "SUM", "VAR_POP", 
                                                             "VAR_SAMP", 
                                                             "VARIANCE", 
                                                             "CURRENT_DATE", 
                                                             "CURRENT_TIME", 
                                                             "CURRENT_TIMESTAMP", 
                                                             "LOCALTIME", 
                                                             "CURDATE", 
                                                             "CURTIME", 
                                                             "DATE_ADD", 
                                                             "DATE_SUB", 
                                                             "EXTRACT", 
                                                             "LOCALTIMESTAMP", 
                                                             "NOW", "POSITION", 
                                                             "SUBSTR", "SUBSTRING", 
                                                             "SYSDATE", 
                                                             "TRIM", "UTC_DATE", 
                                                             "UTC_TIME", 
                                                             "UTC_TIMESTAMP", 
                                                             "ACCOUNT", 
                                                             "ACTION", "AFTER", 
                                                             "AGGREGATE", 
                                                             "ALGORITHM", 
                                                             "ANY", "AT", 
                                                             "AUTHORS", 
                                                             "AUTOCOMMIT", 
                                                             "AUTOEXTEND_SIZE", 
                                                             "AUTO_INCREMENT", 
                                                             "AVG_ROW_LENGTH", 
                                                             "BEGIN", "BINLOG", 
                                                             "BIT", "BLOCK", 
                                                             "BOOL", "BOOLEAN", 
                                                             "BTREE", "CACHE", 
                                                             "CASCADED", 
                                                             "CHAIN", "CHANGED", 
                                                             "CHANNEL", 
                                                             "CHECKSUM", 
                                                             "PAGE_CHECKSUM", 
                                                             "CIPHER", "CLASS_ORIGIN", 
                                                             "CLIENT", "CLOSE", 
                                                             "CLUSTERING", 
                                                             "COALESCE", 
                                                             "CODE", "COLUMNS", 
                                                             "COLUMN_FORMAT", 
                                                             "COLUMN_NAME", 
                                                             "COMMENT", 
                                                             "COMMIT", "COMPACT", 
                                                             "COMPLETION", 
                                                             "COMPRESSED", 
                                                             "COMPRESSION", 
                                                             "CONCURRENT", 
                                                             "CONNECT", 
                                                             "CONNECTION", 
                                                             "CONSISTENT", 
                                                             "CONSTRAINT_CATALOG", 
                                                             "CONSTRAINT_SCHEMA", 
                                                             "CONSTRAINT_NAME", 
                                                             "CONTAINS", 
                                                             "CONTEXT", 
                                                             "CONTRIBUTORS", 
                                                             "COPY", "CPU", 
                                                             "CYCLE", "CURSOR_NAME", 
                                                             "DATA", "DATAFILE", 
                                                             "DEALLOCATE", 
                                                             "DEFAULT_AUTH", 
                                                             "DEFINER", 
                                                             "DELAY_KEY_WRITE", 
                                                             "DES_KEY_FILE", 
                                                             "DIRECTORY", 
                                                             "DISABLE", 
                                                             "DISCARD", 
                                                             "DISK", "DO", 
                                                             "DUMPFILE", 
                                                             "DUPLICATE", 
                                                             "DYNAMIC", 
                                                             "ENABLE", "ENCRYPTED", 
                                                             "ENCRYPTION", 
                                                             "ENCRYPTION_KEY_ID", 
                                                             "END", "ENDS", 
                                                             "ENGINE", "ENGINES", 
                                                             "ERROR", "ERRORS", 
                                                             "ESCAPE", "EVEN", 
                                                             "EVENT", "EVENTS", 
                                                             "EVERY", "EXCHANGE", 
                                                             "EXCLUSIVE", 
                                                             "EXPIRE", "EXPORT", 
                                                             "EXTENDED", 
                                                             "EXTENT_SIZE", 
                                                             "FAILED_LOGIN_ATTEMPTS", 
                                                             "FAST", "FAULTS", 
                                                             "FIELDS", "FILE_BLOCK_SIZE", 
                                                             "FILTER", "FIRST", 
                                                             "FIXED", "FLUSH", 
                                                             "FOLLOWING", 
                                                             "FOLLOWS", 
                                                             "FOUND", "FULL", 
                                                             "FUNCTION", 
                                                             "GENERAL", 
                                                             "GLOBAL", "GRANTS", 
                                                             "GROUP_REPLICATION", 
                                                             "HANDLER", 
                                                             "HASH", "HELP", 
                                                             "HISTORY", 
                                                             "HOST", "HOSTS", 
                                                             "IDENTIFIED", 
                                                             "IGNORE_SERVER_IDS", 
                                                             "IMPORT", "INCREMENT", 
                                                             "INDEXES", 
                                                             "INITIAL_SIZE", 
                                                             "INPLACE", 
                                                             "INSERT_METHOD", 
                                                             "INSTALL", 
                                                             "INSTANCE", 
                                                             "INSTANT", 
                                                             "INVISIBLE", 
                                                             "INVOKER", 
                                                             "IO", "IO_THREAD", 
                                                             "IPC", "ISOLATION", 
                                                             "ISSUER", "JSON", 
                                                             "KEY_BLOCK_SIZE", 
                                                             "LANGUAGE", 
                                                             "LAST", "LEAVES", 
                                                             "LESS", "LEVEL", 
                                                             "LIST", "LOCAL", 
                                                             "LOGFILE", 
                                                             "LOGS", "MASTER", 
                                                             "MASTER_AUTO_POSITION", 
                                                             "MASTER_CONNECT_RETRY", 
                                                             "MASTER_DELAY", 
                                                             "MASTER_HEARTBEAT_PERIOD", 
                                                             "MASTER_HOST", 
                                                             "MASTER_LOG_FILE", 
                                                             "MASTER_LOG_POS", 
                                                             "MASTER_PASSWORD", 
                                                             "MASTER_PORT", 
                                                             "MASTER_RETRY_COUNT", 
                                                             "MASTER_SSL", 
                                                             "MASTER_SSL_CA", 
                                                             "MASTER_SSL_CAPATH", 
                                                             "MASTER_SSL_CERT", 
                                                             "MASTER_SSL_CIPHER", 
                                                             "MASTER_SSL_CRL", 
                                                             "MASTER_SSL_CRLPATH", 
                                                             "MASTER_SSL_KEY", 
                                                             "MASTER_TLS_VERSION", 
                                                             "MASTER_USER", 
                                                             "MAX_CONNECTIONS_PER_HOUR", 
                                                             "MAX_QUERIES_PER_HOUR", 
                                                             "MAX_ROWS", 
                                                             "MAX_SIZE", 
                                                             "MAX_UPDATES_PER_HOUR", 
                                                             "MAX_USER_CONNECTIONS", 
                                                             "MEDIUM", "MEMBER", 
                                                             "MERGE", "MESSAGE_TEXT", 
                                                             "MID", "MIGRATE", 
                                                             "MIN_ROWS", 
                                                             "MODE", "MODIFY", 
                                                             "MUTEX", "MYSQL", 
                                                             "MYSQL_ERRNO", 
                                                             "NAME", "NAMES", 
                                                             "NCHAR", "NEVER", 
                                                             "NEXT", "NO", 
                                                             "NOCACHE", 
                                                             "NOCOPY", "NOCYCLE", 
                                                             "NOMAXVALUE", 
                                                             "NOMINVALUE", 
                                                             "NOWAIT", "NODEGROUP", 
                                                             "NONE", "ODBC", 
                                                             "OFFLINE", 
                                                             "OFFSET", "OF", 
                                                             "OJ", "OLD_PASSWORD", 
                                                             "ONE", "ONLINE", 
                                                             "ONLY", "OPEN", 
                                                             "OPTIMIZER_COSTS", 
                                                             "OPTIONS", 
                                                             "OWNER", "PACK_KEYS", 
                                                             "PAGE", "PAGE_COMPRESSED", 
                                                             "PAGE_COMPRESSION_LEVEL", 
                                                             "PARSER", "PARTIAL", 
                                                             "PARTITIONING", 
                                                             "PARTITIONS", 
                                                             "PASSWORD", 
                                                             "PASSWORD_LOCK_TIME", 
                                                             "PHASE", "PLUGIN", 
                                                             "PLUGIN_DIR", 
                                                             "PLUGINS", 
                                                             "PORT", "PRECEDES", 
                                                             "PRECEDING", 
                                                             "PREPARE", 
                                                             "PRESERVE", 
                                                             "PREV", "PROCESSLIST", 
                                                             "PROFILE", 
                                                             "PROFILES", 
                                                             "PROXY", "QUERY", 
                                                             "QUICK", "REBUILD", 
                                                             "RECOVER", 
                                                             "RECURSIVE", 
                                                             "REDO_BUFFER_SIZE", 
                                                             "REDUNDANT", 
                                                             "RELAY", "RELAY_LOG_FILE", 
                                                             "RELAY_LOG_POS", 
                                                             "RELAYLOG", 
                                                             "REMOVE", "REORGANIZE", 
                                                             "REPAIR", "REPLICATE_DO_DB", 
                                                             "REPLICATE_DO_TABLE", 
                                                             "REPLICATE_IGNORE_DB", 
                                                             "REPLICATE_IGNORE_TABLE", 
                                                             "REPLICATE_REWRITE_DB", 
                                                             "REPLICATE_WILD_DO_TABLE", 
                                                             "REPLICATE_WILD_IGNORE_TABLE", 
                                                             "REPLICATION", 
                                                             "RESET", "RESTART", 
                                                             "RESUME", "RETURNED_SQLSTATE", 
                                                             "RETURNING", 
                                                             "RETURNS", 
                                                             "REUSE", "ROLE", 
                                                             "ROLLBACK", 
                                                             "ROLLUP", "ROTATE", 
                                                             "ROW", "ROWS", 
                                                             "ROW_FORMAT", 
                                                             "RTREE", "SAVEPOINT", 
                                                             "SCHEDULE", 
                                                             "SECURITY", 
                                                             "SEQUENCE", 
                                                             "SERVER", "SESSION", 
                                                             "SHARE", "SHARED", 
                                                             "SIGNED", "SIMPLE", 
                                                             "SLAVE", "SLOW", 
                                                             "SNAPSHOT", 
                                                             "SOCKET", "SOME", 
                                                             "SONAME", "SOUNDS", 
                                                             "SOURCE", "SQL_AFTER_GTIDS", 
                                                             "SQL_AFTER_MTS_GAPS", 
                                                             "SQL_BEFORE_GTIDS", 
                                                             "SQL_BUFFER_RESULT", 
                                                             "SQL_CACHE", 
                                                             "SQL_NO_CACHE", 
                                                             "SQL_THREAD", 
                                                             "START", "STARTS", 
                                                             "STATS_AUTO_RECALC", 
                                                             "STATS_PERSISTENT", 
                                                             "STATS_SAMPLE_PAGES", 
                                                             "STATUS", "STOP", 
                                                             "STORAGE", 
                                                             "STORED", "STRING", 
                                                             "SUBCLASS_ORIGIN", 
                                                             "SUBJECT", 
                                                             "SUBPARTITION", 
                                                             "SUBPARTITIONS", 
                                                             "SUSPEND", 
                                                             "SWAPS", "SWITCHES", 
                                                             "TABLE_NAME", 
                                                             "TABLESPACE", 
                                                             "TABLE_TYPE", 
                                                             "TEMPORARY", 
                                                             "TEMPTABLE", 
                                                             "THAN", "TRADITIONAL", 
                                                             "TRANSACTION", 
                                                             "TRANSACTIONAL", 
                                                             "TRIGGERS", 
                                                             "TRUNCATE", 
                                                             "UNBOUNDED", 
                                                             "UNDEFINED", 
                                                             "UNDOFILE", 
                                                             "UNDO_BUFFER_SIZE", 
                                                             "UNINSTALL", 
                                                             "UNKNOWN", 
                                                             "UNTIL", "UPGRADE", 
                                                             "USER", "USE_FRM", 
                                                             "USER_RESOURCES", 
                                                             "VALIDATION", 
                                                             "VALUE", "VARIABLES", 
                                                             "VIEW", "VIRTUAL", 
                                                             "VISIBLE", 
                                                             "WAIT", "WARNINGS", 
                                                             "WINDOW", "WITHOUT", 
                                                             "WORK", "WRAPPER", 
                                                             "X509", "XA", 
                                                             "XML", "YES", 
                                                             "EUR", "USA", 
                                                             "JIS", "ISO", 
                                                             "INTERNAL", 
                                                             "QUARTER", 
                                                             "MONTH", "DAY", 
                                                             "HOUR", "MINUTE", 
                                                             "WEEK", "SECOND", 
                                                             "MICROSECOND", 
                                                             "ADMIN", "APPLICATION_PASSWORD_ADMIN", 
                                                             "AUDIT_ABORT_EXEMPT", 
                                                             "AUDIT_ADMIN", 
                                                             "AUTHENTICATION_POLICY_ADMIN", 
                                                             "BACKUP_ADMIN", 
                                                             "BINLOG_ADMIN", 
                                                             "BINLOG_ENCRYPTION_ADMIN", 
                                                             "CLONE_ADMIN", 
                                                             "CONNECTION_ADMIN", 
                                                             "ENCRYPTION_KEY_ADMIN", 
                                                             "EXECUTE", 
                                                             "FILE", "FIREWALL_ADMIN", 
                                                             "FIREWALL_EXEMPT", 
                                                             "FIREWALL_USER", 
                                                             "FLUSH_OPTIMIZER_COSTS", 
                                                             "FLUSH_STATUS", 
                                                             "FLUSH_TABLES", 
                                                             "FLUSH_USER_RESOURCES", 
                                                             "GROUP_REPLICATION_ADMIN", 
                                                             "INNODB_REDO_LOG_ARCHIVE", 
                                                             "INNODB_REDO_LOG_ENABLE", 
                                                             "INVOKE", "LAMBDA", 
                                                             "NDB_STORED_USER", 
                                                             "PASSWORDLESS_USER_ADMIN", 
                                                             "PERSIST_RO_VARIABLES_ADMIN", 
                                                             "PRIVILEGES", 
                                                             "PROCESS", 
                                                             "RELOAD", "REPLICATION_APPLIER", 
                                                             "REPLICATION_SLAVE_ADMIN", 
                                                             "RESOURCE_GROUP_ADMIN", 
                                                             "RESOURCE_GROUP_USER", 
                                                             "ROLE_ADMIN", 
                                                             "ROUTINE", 
                                                             "S3", "SERVICE_CONNECTION_ADMIN", 
                                                             "SESSION_VARIABLES_ADMIN", 
                                                             "SET_USER_ID", 
                                                             "SHOW_ROUTINE", 
                                                             "SHUTDOWN", 
                                                             "SUPER", "SYSTEM_VARIABLES_ADMIN", 
                                                             "TABLES", "TABLE_ENCRYPTION_ADMIN", 
                                                             "VERSION_TOKEN_ADMIN", 
                                                             "XA_RECOVER_ADMIN", 
                                                             "ARMSCII8", 
                                                             "ASCII", "BIG5", 
                                                             "CP1250", "CP1251", 
                                                             "CP1256", "CP1257", 
                                                             "CP850", "CP852", 
                                                             "CP866", "CP932", 
                                                             "DEC8", "EUCJPMS", 
                                                             "EUCKR", "GB18030", 
                                                             "GB2312", "GBK", 
                                                             "GEOSTD8", 
                                                             "GREEK", "HEBREW", 
                                                             "HP8", "KEYBCS2", 
                                                             "KOI8R", "KOI8U", 
                                                             "LATIN1", "LATIN2", 
                                                             "LATIN5", "LATIN7", 
                                                             "MACCE", "MACROMAN", 
                                                             "SJIS", "SWE7", 
                                                             "TIS620", "UCS2", 
                                                             "UJIS", "UTF16", 
                                                             "UTF16LE", 
                                                             "UTF32", "UTF8", 
                                                             "UTF8MB3", 
                                                             "UTF8MB4", 
                                                             "ARCHIVE", 
                                                             "BLACKHOLE", 
                                                             "CSV", "FEDERATED", 
                                                             "INNODB", "MEMORY", 
                                                             "MRG_MYISAM", 
                                                             "MYISAM", "NDB", 
                                                             "NDBCLUSTER", 
                                                             "PERFORMANCE_SCHEMA", 
                                                             "TOKUDB", "REPEATABLE", 
                                                             "COMMITTED", 
                                                             "UNCOMMITTED", 
                                                             "SERIALIZABLE", 
                                                             "GEOMETRYCOLLECTION", 
                                                             "GEOMCOLLECTION", 
                                                             "GEOMETRY", 
                                                             "LINESTRING", 
                                                             "MULTILINESTRING", 
                                                             "MULTIPOINT", 
                                                             "MULTIPOLYGON", 
                                                             "POINT", "POLYGON", 
                                                             "ABS", "ACOS", 
                                                             "ADDDATE", 
                                                             "ADDTIME", 
                                                             "AES_DECRYPT", 
                                                             "AES_ENCRYPT", 
                                                             "AREA", "ASBINARY", 
                                                             "ASIN", "ASTEXT", 
                                                             "ASWKB", "ASWKT", 
                                                             "ASYMMETRIC_DECRYPT", 
                                                             "ASYMMETRIC_DERIVE", 
                                                             "ASYMMETRIC_ENCRYPT", 
                                                             "ASYMMETRIC_SIGN", 
                                                             "ASYMMETRIC_VERIFY", 
                                                             "ATAN", "ATAN2", 
                                                             "BENCHMARK", 
                                                             "BIN", "BIT_COUNT", 
                                                             "BIT_LENGTH", 
                                                             "BUFFER", "CATALOG_NAME", 
                                                             "CEIL", "CEILING", 
                                                             "CENTROID", 
                                                             "CHARACTER_LENGTH", 
                                                             "CHARSET", 
                                                             "CHAR_LENGTH", 
                                                             "COERCIBILITY", 
                                                             "COLLATION", 
                                                             "COMPRESS", 
                                                             "CONCAT", "CONCAT_WS", 
                                                             "CONNECTION_ID", 
                                                             "CONV", "CONVERT_TZ", 
                                                             "COS", "COT", 
                                                             "CRC32", "CREATE_ASYMMETRIC_PRIV_KEY", 
                                                             "CREATE_ASYMMETRIC_PUB_KEY", 
                                                             "CREATE_DH_PARAMETERS", 
                                                             "CREATE_DIGEST", 
                                                             "CROSSES", 
                                                             "DATEDIFF", 
                                                             "DATE_FORMAT", 
                                                             "DAYNAME", 
                                                             "DAYOFMONTH", 
                                                             "DAYOFWEEK", 
                                                             "DAYOFYEAR", 
                                                             "DECODE", "DEGREES", 
                                                             "DES_DECRYPT", 
                                                             "DES_ENCRYPT", 
                                                             "DIMENSION", 
                                                             "DISJOINT", 
                                                             "ELT", "ENCODE", 
                                                             "ENCRYPT", 
                                                             "ENDPOINT", 
                                                             "ENGINE_ATTRIBUTE", 
                                                             "ENVELOPE", 
                                                             "EQUALS", "EXP", 
                                                             "EXPORT_SET", 
                                                             "EXTERIORRING", 
                                                             "EXTRACTVALUE", 
                                                             "FIELD", "FIND_IN_SET", 
                                                             "FLOOR", "FORMAT", 
                                                             "FOUND_ROWS", 
                                                             "FROM_BASE64", 
                                                             "FROM_DAYS", 
                                                             "FROM_UNIXTIME", 
                                                             "GEOMCOLLFROMTEXT", 
                                                             "GEOMCOLLFROMWKB", 
                                                             "GEOMETRYCOLLECTIONFROMTEXT", 
                                                             "GEOMETRYCOLLECTIONFROMWKB", 
                                                             "GEOMETRYFROMTEXT", 
                                                             "GEOMETRYFROMWKB", 
                                                             "GEOMETRYN", 
                                                             "GEOMETRYTYPE", 
                                                             "GEOMFROMTEXT", 
                                                             "GEOMFROMWKB", 
                                                             "GET_FORMAT", 
                                                             "GET_LOCK", 
                                                             "GLENGTH", 
                                                             "GREATEST", 
                                                             "GTID_SUBSET", 
                                                             "GTID_SUBTRACT", 
                                                             "HEX", "IFNULL", 
                                                             "INET6_ATON", 
                                                             "INET6_NTOA", 
                                                             "INET_ATON", 
                                                             "INET_NTOA", 
                                                             "INSTR", "INTERIORRINGN", 
                                                             "INTERSECTS", 
                                                             "ISCLOSED", 
                                                             "ISEMPTY", 
                                                             "ISNULL", "ISSIMPLE", 
                                                             "IS_FREE_LOCK", 
                                                             "IS_IPV4", 
                                                             "IS_IPV4_COMPAT", 
                                                             "IS_IPV4_MAPPED", 
                                                             "IS_IPV6", 
                                                             "IS_USED_LOCK", 
                                                             "LAST_INSERT_ID", 
                                                             "LCASE", "LEAST", 
                                                             "LENGTH", "LINEFROMTEXT", 
                                                             "LINEFROMWKB", 
                                                             "LINESTRINGFROMTEXT", 
                                                             "LINESTRINGFROMWKB", 
                                                             "LN", "LOAD_FILE", 
                                                             "LOCATE", "LOG", 
                                                             "LOG10", "LOG2", 
                                                             "LOWER", "LPAD", 
                                                             "LTRIM", "MAKEDATE", 
                                                             "MAKETIME", 
                                                             "MAKE_SET", 
                                                             "MASTER_POS_WAIT", 
                                                             "MBRCONTAINS", 
                                                             "MBRDISJOINT", 
                                                             "MBREQUAL", 
                                                             "MBRINTERSECTS", 
                                                             "MBROVERLAPS", 
                                                             "MBRTOUCHES", 
                                                             "MBRWITHIN", 
                                                             "MD5", "MLINEFROMTEXT", 
                                                             "MLINEFROMWKB", 
                                                             "MONTHNAME", 
                                                             "MPOINTFROMTEXT", 
                                                             "MPOINTFROMWKB", 
                                                             "MPOLYFROMTEXT", 
                                                             "MPOLYFROMWKB", 
                                                             "MULTILINESTRINGFROMTEXT", 
                                                             "MULTILINESTRINGFROMWKB", 
                                                             "MULTIPOINTFROMTEXT", 
                                                             "MULTIPOINTFROMWKB", 
                                                             "MULTIPOLYGONFROMTEXT", 
                                                             "MULTIPOLYGONFROMWKB", 
                                                             "NAME_CONST", 
                                                             "NULLIF", "NUMGEOMETRIES", 
                                                             "NUMINTERIORRINGS", 
                                                             "NUMPOINTS", 
                                                             "OCT", "OCTET_LENGTH", 
                                                             "ORD", "OVERLAPS", 
                                                             "PERIOD_ADD", 
                                                             "PERIOD_DIFF", 
                                                             "PI", "POINTFROMTEXT", 
                                                             "POINTFROMWKB", 
                                                             "POINTN", "POLYFROMTEXT", 
                                                             "POLYFROMWKB", 
                                                             "POLYGONFROMTEXT", 
                                                             "POLYGONFROMWKB", 
                                                             "POW", "POWER", 
                                                             "QUOTE", "RADIANS", 
                                                             "RAND", "RANDOM", 
                                                             "RANDOM_BYTES", 
                                                             "RELEASE_LOCK", 
                                                             "REVERSE", 
                                                             "ROUND", "ROW_COUNT", 
                                                             "RPAD", "RTRIM", 
                                                             "SEC_TO_TIME", 
                                                             "SECONDARY_ENGINE_ATTRIBUTE", 
                                                             "SESSION_USER", 
                                                             "SHA", "SHA1", 
                                                             "SHA2", "SCHEMA_NAME", 
                                                             "SIGN", "SIN", 
                                                             "SLEEP", "SOUNDEX", 
                                                             "SQL_THREAD_WAIT_AFTER_GTIDS", 
                                                             "SQRT", "SRID", 
                                                             "STARTPOINT", 
                                                             "STRCMP", "STR_TO_DATE", 
                                                             "ST_AREA", 
                                                             "ST_ASBINARY", 
                                                             "ST_ASTEXT", 
                                                             "ST_ASWKB", 
                                                             "ST_ASWKT", 
                                                             "ST_BUFFER", 
                                                             "ST_CENTROID", 
                                                             "ST_CONTAINS", 
                                                             "ST_CROSSES", 
                                                             "ST_DIFFERENCE", 
                                                             "ST_DIMENSION", 
                                                             "ST_DISJOINT", 
                                                             "ST_DISTANCE", 
                                                             "ST_ENDPOINT", 
                                                             "ST_ENVELOPE", 
                                                             "ST_EQUALS", 
                                                             "ST_EXTERIORRING", 
                                                             "ST_GEOMCOLLFROMTEXT", 
                                                             "ST_GEOMCOLLFROMTXT", 
                                                             "ST_GEOMCOLLFROMWKB", 
                                                             "ST_GEOMETRYCOLLECTIONFROMTEXT", 
                                                             "ST_GEOMETRYCOLLECTIONFROMWKB", 
                                                             "ST_GEOMETRYFROMTEXT", 
                                                             "ST_GEOMETRYFROMWKB", 
                                                             "ST_GEOMETRYN", 
                                                             "ST_GEOMETRYTYPE", 
                                                             "ST_GEOMFROMTEXT", 
                                                             "ST_GEOMFROMWKB", 
                                                             "ST_INTERIORRINGN", 
                                                             "ST_INTERSECTION", 
                                                             "ST_INTERSECTS", 
                                                             "ST_ISCLOSED", 
                                                             "ST_ISEMPTY", 
                                                             "ST_ISSIMPLE", 
                                                             "ST_LINEFROMTEXT", 
                                                             "ST_LINEFROMWKB", 
                                                             "ST_LINESTRINGFROMTEXT", 
                                                             "ST_LINESTRINGFROMWKB", 
                                                             "ST_NUMGEOMETRIES", 
                                                             "ST_NUMINTERIORRING", 
                                                             "ST_NUMINTERIORRINGS", 
                                                             "ST_NUMPOINTS", 
                                                             "ST_OVERLAPS", 
                                                             "ST_POINTFROMTEXT", 
                                                             "ST_POINTFROMWKB", 
                                                             "ST_POINTN", 
                                                             "ST_POLYFROMTEXT", 
                                                             "ST_POLYFROMWKB", 
                                                             "ST_POLYGONFROMTEXT", 
                                                             "ST_POLYGONFROMWKB", 
                                                             "ST_SRID", 
                                                             "ST_STARTPOINT", 
                                                             "ST_SYMDIFFERENCE", 
                                                             "ST_TOUCHES", 
                                                             "ST_UNION", 
                                                             "ST_WITHIN", 
                                                             "ST_X", "ST_Y", 
                                                             "SUBDATE", 
                                                             "SUBSTRING_INDEX", 
                                                             "SUBTIME", 
                                                             "SYSTEM_USER", 
                                                             "TAN", "TIMEDIFF", 
                                                             "TIMESTAMPADD", 
                                                             "TIMESTAMPDIFF", 
                                                             "TIME_FORMAT", 
                                                             "TIME_TO_SEC", 
                                                             "TOUCHES", 
                                                             "TO_BASE64", 
                                                             "TO_DAYS", 
                                                             "TO_SECONDS", 
                                                             "TP_CONNECTION_ADMIN", 
                                                             "UCASE", "UNCOMPRESS", 
                                                             "UNCOMPRESSED_LENGTH", 
                                                             "UNHEX", "UNIX_TIMESTAMP", 
                                                             "UPDATEXML", 
                                                             "UPPER", "UUID", 
                                                             "UUID_SHORT", 
                                                             "VALIDATE_PASSWORD_STRENGTH", 
                                                             "VERSION", 
                                                             "WAIT_UNTIL_SQL_THREAD_AFTER_GTIDS", 
                                                             "WEEKDAY", 
                                                             "WEEKOFYEAR", 
                                                             "WEIGHT_STRING", 
                                                             "WITHIN", "YEARWEEK", 
                                                             "Y_FUNCTION", 
                                                             "X_FUNCTION", 
                                                             "VAR_ASSIGN", 
                                                             "PLUS_ASSIGN", 
                                                             "MINUS_ASSIGN", 
                                                             "MULT_ASSIGN", 
                                                             "DIV_ASSIGN", 
                                                             "MOD_ASSIGN", 
                                                             "AND_ASSIGN", 
                                                             "XOR_ASSIGN", 
                                                             "OR_ASSIGN", 
                                                             "STAR", "DIVIDE", 
                                                             "MODULE", "PLUS", 
                                                             "MINUS", "DIV", 
                                                             "MOD", "EQUAL_SYMBOL", 
                                                             "GREATER_SYMBOL", 
                                                             "LESS_SYMBOL", 
                                                             "EXCLAMATION_SYMBOL", 
                                                             "BIT_NOT_OP", 
                                                             "BIT_OR_OP", 
                                                             "BIT_AND_OP", 
                                                             "BIT_XOR_OP", 
                                                             "DOT", "LR_BRACKET", 
                                                             "RR_BRACKET", 
                                                             "COMMA", "SEMI", 
                                                             "AT_SIGN", 
                                                             "ZERO_DECIMAL", 
                                                             "ONE_DECIMAL", 
                                                             "TWO_DECIMAL", 
                                                             "SINGLE_QUOTE_SYMB", 
                                                             "DOUBLE_QUOTE_SYMB", 
                                                             "REVERSE_QUOTE_SYMB", 
                                                             "COLON_SYMB", 
                                                             "CHARSET_REVERSE_QOUTE_STRING", 
                                                             "FILESIZE_LITERAL", 
                                                             "START_NATIONAL_STRING_LITERAL", 
                                                             "STRING_LITERAL", 
                                                             "DECIMAL_LITERAL", 
                                                             "HEXADECIMAL_LITERAL", 
                                                             "REAL_LITERAL", 
                                                             "NULL_SPEC_LITERAL", 
                                                             "BIT_STRING", 
                                                             "STRING_CHARSET_NAME", 
                                                             "DOT_ID", "ID", 
                                                             "REVERSE_QUOTE_ID", 
                                                             "HOST_IP_ADDRESS", 
                                                             "LOCAL_ID", 
                                                             "GLOBAL_ID", 
                                                             "ERROR_RECONGNIGION" ];
	public static readonly modeNames: string[] = [ "DEFAULT_MODE", ];

	public static readonly ruleNames: string[] = [
		"SPACE", "SPEC_MYSQL_COMMENT", "COMMENT_INPUT", "LINE_COMMENT", "ADD", 
		"ALL", "ALTER", "ALWAYS", "ANALYZE", "AND", "ARRAY", "AS", "ASC", "ATTRIBUTE", 
		"BEFORE", "BETWEEN", "BOTH", "BUCKETS", "BY", "CALL", "CASCADE", "CASE", 
		"CAST", "CHANGE", "CHARACTER", "CHECK", "COLLATE", "COLUMN", "CONDITION", 
		"CONSTRAINT", "CONTINUE", "CONVERT", "CREATE", "CROSS", "CURRENT", "CURRENT_ROLE", 
		"CURRENT_USER", "CURSOR", "DATABASE", "DATABASES", "DECLARE", "DEFAULT", 
		"DELAYED", "DELETE", "DESC", "DESCRIBE", "DETERMINISTIC", "DIAGNOSTICS", 
		"DISTINCT", "DISTINCTROW", "DROP", "EACH", "ELSE", "ELSEIF", "EMPTY", 
		"ENCLOSED", "ENFORCED", "ESCAPED", "EXCEPT", "EXISTS", "EXIT", "EXPLAIN", 
		"FALSE", "FETCH", "FOR", "FORCE", "FOREIGN", "FROM", "FULLTEXT", "GENERATED", 
		"GET", "GRANT", "GROUP", "HAVING", "HIGH_PRIORITY", "HISTOGRAM", "IF", 
		"IGNORE", "IGNORED", "IN", "INDEX", "INFILE", "INNER", "INOUT", "INSERT", 
		"INTERVAL", "INTO", "IS", "ITERATE", "JOIN", "KEY", "KEYS", "KILL", "LATERAL", 
		"LEADING", "LEAVE", "LEFT", "LIKE", "LIMIT", "LINEAR", "LINES", "LOAD", 
		"LOCK", "LOCKED", "LOOP", "LOW_PRIORITY", "MASTER_BIND", "MASTER_SSL_VERIFY_SERVER_CERT", 
		"MATCH", "MAXVALUE", "MINVALUE", "MODIFIES", "NATURAL", "NOT", "NO_WRITE_TO_BINLOG", 
		"NULL_LITERAL", "NUMBER", "ON", "OPTIMIZE", "OPTION", "OPTIONAL", "OPTIONALLY", 
		"OR", "ORDER", "OUT", "OUTER", "OUTFILE", "OVER", "PARTITION", "PRIMARY", 
		"PROCEDURE", "PURGE", "RANGE", "READ", "READS", "REFERENCES", "REGEXP", 
		"RELEASE", "RENAME", "REPEAT", "REPLACE", "REQUIRE", "RESIGNAL", "RESTRICT", 
		"RETAIN", "RETURN", "REVOKE", "RIGHT", "RLIKE", "SCHEMA", "SCHEMAS", "SELECT", 
		"SET", "SEPARATOR", "SHOW", "SIGNAL", "SKIP_", "SKIP_QUERY_REWRITE", "SPATIAL", 
		"SQL", "SQLEXCEPTION", "SQLSTATE", "SQLWARNING", "SQL_BIG_RESULT", "SQL_CALC_FOUND_ROWS", 
		"SQL_SMALL_RESULT", "SSL", "STACKED", "STARTING", "STATEMENT", "STRAIGHT_JOIN", 
		"TABLE", "TERMINATED", "THEN", "TO", "TRAILING", "TRIGGER", "TRUE", "UNDO", 
		"UNION", "UNIQUE", "UNLOCK", "UNSIGNED", "UPDATE", "USAGE", "USE", "USING", 
		"VALUES", "WHEN", "WHERE", "WHILE", "WITH", "WRITE", "XOR", "ZEROFILL", 
		"TINYINT", "SMALLINT", "MEDIUMINT", "MIDDLEINT", "INT", "INT1", "INT2", 
		"INT3", "INT4", "INT8", "INTEGER", "BIGINT", "REAL", "DOUBLE", "PRECISION", 
		"FLOAT", "FLOAT4", "FLOAT8", "DECIMAL", "DEC", "NUMERIC", "DATE", "TIME", 
		"TIMESTAMP", "DATETIME", "YEAR", "CHAR", "VARCHAR", "NVARCHAR", "NATIONAL", 
		"BINARY", "VARBINARY", "TINYBLOB", "BLOB", "MEDIUMBLOB", "LONG", "LONGBLOB", 
		"TINYTEXT", "TEXT", "MEDIUMTEXT", "LONGTEXT", "ENUM", "VARYING", "SERIAL", 
		"YEAR_MONTH", "DAY_HOUR", "DAY_MINUTE", "DAY_SECOND", "HOUR_MINUTE", "HOUR_SECOND", 
		"MINUTE_SECOND", "SECOND_MICROSECOND", "MINUTE_MICROSECOND", "HOUR_MICROSECOND", 
		"DAY_MICROSECOND", "JSON_ARRAY", "JSON_ARRAYAGG", "JSON_ARRAY_APPEND", 
		"JSON_ARRAY_INSERT", "JSON_CONTAINS", "JSON_CONTAINS_PATH", "JSON_DEPTH", 
		"JSON_EXTRACT", "JSON_INSERT", "JSON_KEYS", "JSON_LENGTH", "JSON_MERGE", 
		"JSON_MERGE_PATCH", "JSON_MERGE_PRESERVE", "JSON_OBJECT", "JSON_OBJECTAGG", 
		"JSON_OVERLAPS", "JSON_PRETTY", "JSON_QUOTE", "JSON_REMOVE", "JSON_REPLACE", 
		"JSON_SCHEMA_VALID", "JSON_SCHEMA_VALIDATION_REPORT", "JSON_SEARCH", "JSON_SET", 
		"JSON_STORAGE_FREE", "JSON_STORAGE_SIZE", "JSON_TABLE", "JSON_TYPE", "JSON_UNQUOTE", 
		"JSON_VALID", "JSON_VALUE", "NESTED", "ORDINALITY", "PATH", "AVG", "BIT_AND", 
		"BIT_OR", "BIT_XOR", "COUNT", "CUME_DIST", "DENSE_RANK", "FIRST_VALUE", 
		"GROUP_CONCAT", "LAG", "LAST_VALUE", "LEAD", "MAX", "MIN", "NTILE", "NTH_VALUE", 
		"PERCENT_RANK", "RANK", "ROW_NUMBER", "STD", "STDDEV", "STDDEV_POP", "STDDEV_SAMP", 
		"SUM", "VAR_POP", "VAR_SAMP", "VARIANCE", "CURRENT_DATE", "CURRENT_TIME", 
		"CURRENT_TIMESTAMP", "LOCALTIME", "CURDATE", "CURTIME", "DATE_ADD", "DATE_SUB", 
		"EXTRACT", "LOCALTIMESTAMP", "NOW", "POSITION", "SUBSTR", "SUBSTRING", 
		"SYSDATE", "TRIM", "UTC_DATE", "UTC_TIME", "UTC_TIMESTAMP", "ACCOUNT", 
		"ACTION", "AFTER", "AGGREGATE", "ALGORITHM", "ANY", "AT", "AUTHORS", "AUTOCOMMIT", 
		"AUTOEXTEND_SIZE", "AUTO_INCREMENT", "AVG_ROW_LENGTH", "BEGIN", "BINLOG", 
		"BIT", "BLOCK", "BOOL", "BOOLEAN", "BTREE", "CACHE", "CASCADED", "CHAIN", 
		"CHANGED", "CHANNEL", "CHECKSUM", "PAGE_CHECKSUM", "CIPHER", "CLASS_ORIGIN", 
		"CLIENT", "CLOSE", "CLUSTERING", "COALESCE", "CODE", "COLUMNS", "COLUMN_FORMAT", 
		"COLUMN_NAME", "COMMENT", "COMMIT", "COMPACT", "COMPLETION", "COMPRESSED", 
		"COMPRESSION", "CONCURRENT", "CONNECT", "CONNECTION", "CONSISTENT", "CONSTRAINT_CATALOG", 
		"CONSTRAINT_SCHEMA", "CONSTRAINT_NAME", "CONTAINS", "CONTEXT", "CONTRIBUTORS", 
		"COPY", "CPU", "CYCLE", "CURSOR_NAME", "DATA", "DATAFILE", "DEALLOCATE", 
		"DEFAULT_AUTH", "DEFINER", "DELAY_KEY_WRITE", "DES_KEY_FILE", "DIRECTORY", 
		"DISABLE", "DISCARD", "DISK", "DO", "DUMPFILE", "DUPLICATE", "DYNAMIC", 
		"ENABLE", "ENCRYPTED", "ENCRYPTION", "ENCRYPTION_KEY_ID", "END", "ENDS", 
		"ENGINE", "ENGINES", "ERROR", "ERRORS", "ESCAPE", "EVEN", "EVENT", "EVENTS", 
		"EVERY", "EXCHANGE", "EXCLUSIVE", "EXPIRE", "EXPORT", "EXTENDED", "EXTENT_SIZE", 
		"FAILED_LOGIN_ATTEMPTS", "FAST", "FAULTS", "FIELDS", "FILE_BLOCK_SIZE", 
		"FILTER", "FIRST", "FIXED", "FLUSH", "FOLLOWING", "FOLLOWS", "FOUND", 
		"FULL", "FUNCTION", "GENERAL", "GLOBAL", "GRANTS", "GROUP_REPLICATION", 
		"HANDLER", "HASH", "HELP", "HISTORY", "HOST", "HOSTS", "IDENTIFIED", "IGNORE_SERVER_IDS", 
		"IMPORT", "INCREMENT", "INDEXES", "INITIAL_SIZE", "INPLACE", "INSERT_METHOD", 
		"INSTALL", "INSTANCE", "INSTANT", "INVISIBLE", "INVOKER", "IO", "IO_THREAD", 
		"IPC", "ISOLATION", "ISSUER", "JSON", "KEY_BLOCK_SIZE", "LANGUAGE", "LAST", 
		"LEAVES", "LESS", "LEVEL", "LIST", "LOCAL", "LOGFILE", "LOGS", "MASTER", 
		"MASTER_AUTO_POSITION", "MASTER_CONNECT_RETRY", "MASTER_DELAY", "MASTER_HEARTBEAT_PERIOD", 
		"MASTER_HOST", "MASTER_LOG_FILE", "MASTER_LOG_POS", "MASTER_PASSWORD", 
		"MASTER_PORT", "MASTER_RETRY_COUNT", "MASTER_SSL", "MASTER_SSL_CA", "MASTER_SSL_CAPATH", 
		"MASTER_SSL_CERT", "MASTER_SSL_CIPHER", "MASTER_SSL_CRL", "MASTER_SSL_CRLPATH", 
		"MASTER_SSL_KEY", "MASTER_TLS_VERSION", "MASTER_USER", "MAX_CONNECTIONS_PER_HOUR", 
		"MAX_QUERIES_PER_HOUR", "MAX_ROWS", "MAX_SIZE", "MAX_UPDATES_PER_HOUR", 
		"MAX_USER_CONNECTIONS", "MEDIUM", "MEMBER", "MERGE", "MESSAGE_TEXT", "MID", 
		"MIGRATE", "MIN_ROWS", "MODE", "MODIFY", "MUTEX", "MYSQL", "MYSQL_ERRNO", 
		"NAME", "NAMES", "NCHAR", "NEVER", "NEXT", "NO", "NOCACHE", "NOCOPY", 
		"NOCYCLE", "NOMAXVALUE", "NOMINVALUE", "NOWAIT", "NODEGROUP", "NONE", 
		"ODBC", "OFFLINE", "OFFSET", "OF", "OJ", "OLD_PASSWORD", "ONE", "ONLINE", 
		"ONLY", "OPEN", "OPTIMIZER_COSTS", "OPTIONS", "OWNER", "PACK_KEYS", "PAGE", 
		"PAGE_COMPRESSED", "PAGE_COMPRESSION_LEVEL", "PARSER", "PARTIAL", "PARTITIONING", 
		"PARTITIONS", "PASSWORD", "PASSWORD_LOCK_TIME", "PHASE", "PLUGIN", "PLUGIN_DIR", 
		"PLUGINS", "PORT", "PRECEDES", "PRECEDING", "PREPARE", "PRESERVE", "PREV", 
		"PROCESSLIST", "PROFILE", "PROFILES", "PROXY", "QUERY", "QUICK", "REBUILD", 
		"RECOVER", "RECURSIVE", "REDO_BUFFER_SIZE", "REDUNDANT", "RELAY", "RELAY_LOG_FILE", 
		"RELAY_LOG_POS", "RELAYLOG", "REMOVE", "REORGANIZE", "REPAIR", "REPLICATE_DO_DB", 
		"REPLICATE_DO_TABLE", "REPLICATE_IGNORE_DB", "REPLICATE_IGNORE_TABLE", 
		"REPLICATE_REWRITE_DB", "REPLICATE_WILD_DO_TABLE", "REPLICATE_WILD_IGNORE_TABLE", 
		"REPLICATION", "RESET", "RESTART", "RESUME", "RETURNED_SQLSTATE", "RETURNING", 
		"RETURNS", "REUSE", "ROLE", "ROLLBACK", "ROLLUP", "ROTATE", "ROW", "ROWS", 
		"ROW_FORMAT", "RTREE", "SAVEPOINT", "SCHEDULE", "SECURITY", "SEQUENCE", 
		"SERVER", "SESSION", "SHARE", "SHARED", "SIGNED", "SIMPLE", "SLAVE", "SLOW", 
		"SNAPSHOT", "SOCKET", "SOME", "SONAME", "SOUNDS", "SOURCE", "SQL_AFTER_GTIDS", 
		"SQL_AFTER_MTS_GAPS", "SQL_BEFORE_GTIDS", "SQL_BUFFER_RESULT", "SQL_CACHE", 
		"SQL_NO_CACHE", "SQL_THREAD", "START", "STARTS", "STATS_AUTO_RECALC", 
		"STATS_PERSISTENT", "STATS_SAMPLE_PAGES", "STATUS", "STOP", "STORAGE", 
		"STORED", "STRING", "SUBCLASS_ORIGIN", "SUBJECT", "SUBPARTITION", "SUBPARTITIONS", 
		"SUSPEND", "SWAPS", "SWITCHES", "TABLE_NAME", "TABLESPACE", "TABLE_TYPE", 
		"TEMPORARY", "TEMPTABLE", "THAN", "TRADITIONAL", "TRANSACTION", "TRANSACTIONAL", 
		"TRIGGERS", "TRUNCATE", "UNBOUNDED", "UNDEFINED", "UNDOFILE", "UNDO_BUFFER_SIZE", 
		"UNINSTALL", "UNKNOWN", "UNTIL", "UPGRADE", "USER", "USE_FRM", "USER_RESOURCES", 
		"VALIDATION", "VALUE", "VARIABLES", "VIEW", "VIRTUAL", "VISIBLE", "WAIT", 
		"WARNINGS", "WINDOW", "WITHOUT", "WORK", "WRAPPER", "X509", "XA", "XML", 
		"YES", "EUR", "USA", "JIS", "ISO", "INTERNAL", "QUARTER", "MONTH", "DAY", 
		"HOUR", "MINUTE", "WEEK", "SECOND", "MICROSECOND", "ADMIN", "APPLICATION_PASSWORD_ADMIN", 
		"AUDIT_ABORT_EXEMPT", "AUDIT_ADMIN", "AUTHENTICATION_POLICY_ADMIN", "BACKUP_ADMIN", 
		"BINLOG_ADMIN", "BINLOG_ENCRYPTION_ADMIN", "CLONE_ADMIN", "CONNECTION_ADMIN", 
		"ENCRYPTION_KEY_ADMIN", "EXECUTE", "FILE", "FIREWALL_ADMIN", "FIREWALL_EXEMPT", 
		"FIREWALL_USER", "FLUSH_OPTIMIZER_COSTS", "FLUSH_STATUS", "FLUSH_TABLES", 
		"FLUSH_USER_RESOURCES", "GROUP_REPLICATION_ADMIN", "INNODB_REDO_LOG_ARCHIVE", 
		"INNODB_REDO_LOG_ENABLE", "INVOKE", "LAMBDA", "NDB_STORED_USER", "PASSWORDLESS_USER_ADMIN", 
		"PERSIST_RO_VARIABLES_ADMIN", "PRIVILEGES", "PROCESS", "RELOAD", "REPLICATION_APPLIER", 
		"REPLICATION_SLAVE_ADMIN", "RESOURCE_GROUP_ADMIN", "RESOURCE_GROUP_USER", 
		"ROLE_ADMIN", "ROUTINE", "S3", "SERVICE_CONNECTION_ADMIN", "SESSION_VARIABLES_ADMIN", 
		"SET_USER_ID", "SHOW_ROUTINE", "SHUTDOWN", "SUPER", "SYSTEM_VARIABLES_ADMIN", 
		"TABLES", "TABLE_ENCRYPTION_ADMIN", "VERSION_TOKEN_ADMIN", "XA_RECOVER_ADMIN", 
		"ARMSCII8", "ASCII", "BIG5", "CP1250", "CP1251", "CP1256", "CP1257", "CP850", 
		"CP852", "CP866", "CP932", "DEC8", "EUCJPMS", "EUCKR", "GB18030", "GB2312", 
		"GBK", "GEOSTD8", "GREEK", "HEBREW", "HP8", "KEYBCS2", "KOI8R", "KOI8U", 
		"LATIN1", "LATIN2", "LATIN5", "LATIN7", "MACCE", "MACROMAN", "SJIS", "SWE7", 
		"TIS620", "UCS2", "UJIS", "UTF16", "UTF16LE", "UTF32", "UTF8", "UTF8MB3", 
		"UTF8MB4", "ARCHIVE", "BLACKHOLE", "CSV", "FEDERATED", "INNODB", "MEMORY", 
		"MRG_MYISAM", "MYISAM", "NDB", "NDBCLUSTER", "PERFORMANCE_SCHEMA", "TOKUDB", 
		"REPEATABLE", "COMMITTED", "UNCOMMITTED", "SERIALIZABLE", "GEOMETRYCOLLECTION", 
		"GEOMCOLLECTION", "GEOMETRY", "LINESTRING", "MULTILINESTRING", "MULTIPOINT", 
		"MULTIPOLYGON", "POINT", "POLYGON", "ABS", "ACOS", "ADDDATE", "ADDTIME", 
		"AES_DECRYPT", "AES_ENCRYPT", "AREA", "ASBINARY", "ASIN", "ASTEXT", "ASWKB", 
		"ASWKT", "ASYMMETRIC_DECRYPT", "ASYMMETRIC_DERIVE", "ASYMMETRIC_ENCRYPT", 
		"ASYMMETRIC_SIGN", "ASYMMETRIC_VERIFY", "ATAN", "ATAN2", "BENCHMARK", 
		"BIN", "BIT_COUNT", "BIT_LENGTH", "BUFFER", "CATALOG_NAME", "CEIL", "CEILING", 
		"CENTROID", "CHARACTER_LENGTH", "CHARSET", "CHAR_LENGTH", "COERCIBILITY", 
		"COLLATION", "COMPRESS", "CONCAT", "CONCAT_WS", "CONNECTION_ID", "CONV", 
		"CONVERT_TZ", "COS", "COT", "CRC32", "CREATE_ASYMMETRIC_PRIV_KEY", "CREATE_ASYMMETRIC_PUB_KEY", 
		"CREATE_DH_PARAMETERS", "CREATE_DIGEST", "CROSSES", "DATEDIFF", "DATE_FORMAT", 
		"DAYNAME", "DAYOFMONTH", "DAYOFWEEK", "DAYOFYEAR", "DECODE", "DEGREES", 
		"DES_DECRYPT", "DES_ENCRYPT", "DIMENSION", "DISJOINT", "ELT", "ENCODE", 
		"ENCRYPT", "ENDPOINT", "ENGINE_ATTRIBUTE", "ENVELOPE", "EQUALS", "EXP", 
		"EXPORT_SET", "EXTERIORRING", "EXTRACTVALUE", "FIELD", "FIND_IN_SET", 
		"FLOOR", "FORMAT", "FOUND_ROWS", "FROM_BASE64", "FROM_DAYS", "FROM_UNIXTIME", 
		"GEOMCOLLFROMTEXT", "GEOMCOLLFROMWKB", "GEOMETRYCOLLECTIONFROMTEXT", "GEOMETRYCOLLECTIONFROMWKB", 
		"GEOMETRYFROMTEXT", "GEOMETRYFROMWKB", "GEOMETRYN", "GEOMETRYTYPE", "GEOMFROMTEXT", 
		"GEOMFROMWKB", "GET_FORMAT", "GET_LOCK", "GLENGTH", "GREATEST", "GTID_SUBSET", 
		"GTID_SUBTRACT", "HEX", "IFNULL", "INET6_ATON", "INET6_NTOA", "INET_ATON", 
		"INET_NTOA", "INSTR", "INTERIORRINGN", "INTERSECTS", "ISCLOSED", "ISEMPTY", 
		"ISNULL", "ISSIMPLE", "IS_FREE_LOCK", "IS_IPV4", "IS_IPV4_COMPAT", "IS_IPV4_MAPPED", 
		"IS_IPV6", "IS_USED_LOCK", "LAST_INSERT_ID", "LCASE", "LEAST", "LENGTH", 
		"LINEFROMTEXT", "LINEFROMWKB", "LINESTRINGFROMTEXT", "LINESTRINGFROMWKB", 
		"LN", "LOAD_FILE", "LOCATE", "LOG", "LOG10", "LOG2", "LOWER", "LPAD", 
		"LTRIM", "MAKEDATE", "MAKETIME", "MAKE_SET", "MASTER_POS_WAIT", "MBRCONTAINS", 
		"MBRDISJOINT", "MBREQUAL", "MBRINTERSECTS", "MBROVERLAPS", "MBRTOUCHES", 
		"MBRWITHIN", "MD5", "MLINEFROMTEXT", "MLINEFROMWKB", "MONTHNAME", "MPOINTFROMTEXT", 
		"MPOINTFROMWKB", "MPOLYFROMTEXT", "MPOLYFROMWKB", "MULTILINESTRINGFROMTEXT", 
		"MULTILINESTRINGFROMWKB", "MULTIPOINTFROMTEXT", "MULTIPOINTFROMWKB", "MULTIPOLYGONFROMTEXT", 
		"MULTIPOLYGONFROMWKB", "NAME_CONST", "NULLIF", "NUMGEOMETRIES", "NUMINTERIORRINGS", 
		"NUMPOINTS", "OCT", "OCTET_LENGTH", "ORD", "OVERLAPS", "PERIOD_ADD", "PERIOD_DIFF", 
		"PI", "POINTFROMTEXT", "POINTFROMWKB", "POINTN", "POLYFROMTEXT", "POLYFROMWKB", 
		"POLYGONFROMTEXT", "POLYGONFROMWKB", "POW", "POWER", "QUOTE", "RADIANS", 
		"RAND", "RANDOM", "RANDOM_BYTES", "RELEASE_LOCK", "REVERSE", "ROUND", 
		"ROW_COUNT", "RPAD", "RTRIM", "SEC_TO_TIME", "SECONDARY_ENGINE_ATTRIBUTE", 
		"SESSION_USER", "SHA", "SHA1", "SHA2", "SCHEMA_NAME", "SIGN", "SIN", "SLEEP", 
		"SOUNDEX", "SQL_THREAD_WAIT_AFTER_GTIDS", "SQRT", "SRID", "STARTPOINT", 
		"STRCMP", "STR_TO_DATE", "ST_AREA", "ST_ASBINARY", "ST_ASTEXT", "ST_ASWKB", 
		"ST_ASWKT", "ST_BUFFER", "ST_CENTROID", "ST_CONTAINS", "ST_CROSSES", "ST_DIFFERENCE", 
		"ST_DIMENSION", "ST_DISJOINT", "ST_DISTANCE", "ST_ENDPOINT", "ST_ENVELOPE", 
		"ST_EQUALS", "ST_EXTERIORRING", "ST_GEOMCOLLFROMTEXT", "ST_GEOMCOLLFROMTXT", 
		"ST_GEOMCOLLFROMWKB", "ST_GEOMETRYCOLLECTIONFROMTEXT", "ST_GEOMETRYCOLLECTIONFROMWKB", 
		"ST_GEOMETRYFROMTEXT", "ST_GEOMETRYFROMWKB", "ST_GEOMETRYN", "ST_GEOMETRYTYPE", 
		"ST_GEOMFROMTEXT", "ST_GEOMFROMWKB", "ST_INTERIORRINGN", "ST_INTERSECTION", 
		"ST_INTERSECTS", "ST_ISCLOSED", "ST_ISEMPTY", "ST_ISSIMPLE", "ST_LINEFROMTEXT", 
		"ST_LINEFROMWKB", "ST_LINESTRINGFROMTEXT", "ST_LINESTRINGFROMWKB", "ST_NUMGEOMETRIES", 
		"ST_NUMINTERIORRING", "ST_NUMINTERIORRINGS", "ST_NUMPOINTS", "ST_OVERLAPS", 
		"ST_POINTFROMTEXT", "ST_POINTFROMWKB", "ST_POINTN", "ST_POLYFROMTEXT", 
		"ST_POLYFROMWKB", "ST_POLYGONFROMTEXT", "ST_POLYGONFROMWKB", "ST_SRID", 
		"ST_STARTPOINT", "ST_SYMDIFFERENCE", "ST_TOUCHES", "ST_UNION", "ST_WITHIN", 
		"ST_X", "ST_Y", "SUBDATE", "SUBSTRING_INDEX", "SUBTIME", "SYSTEM_USER", 
		"TAN", "TIMEDIFF", "TIMESTAMPADD", "TIMESTAMPDIFF", "TIME_FORMAT", "TIME_TO_SEC", 
		"TOUCHES", "TO_BASE64", "TO_DAYS", "TO_SECONDS", "TP_CONNECTION_ADMIN", 
		"UCASE", "UNCOMPRESS", "UNCOMPRESSED_LENGTH", "UNHEX", "UNIX_TIMESTAMP", 
		"UPDATEXML", "UPPER", "UUID", "UUID_SHORT", "VALIDATE_PASSWORD_STRENGTH", 
		"VERSION", "WAIT_UNTIL_SQL_THREAD_AFTER_GTIDS", "WEEKDAY", "WEEKOFYEAR", 
		"WEIGHT_STRING", "WITHIN", "YEARWEEK", "Y_FUNCTION", "X_FUNCTION", "VAR_ASSIGN", 
		"PLUS_ASSIGN", "MINUS_ASSIGN", "MULT_ASSIGN", "DIV_ASSIGN", "MOD_ASSIGN", 
		"AND_ASSIGN", "XOR_ASSIGN", "OR_ASSIGN", "STAR", "DIVIDE", "MODULE", "PLUS", 
		"MINUS", "DIV", "MOD", "EQUAL_SYMBOL", "GREATER_SYMBOL", "LESS_SYMBOL", 
		"EXCLAMATION_SYMBOL", "BIT_NOT_OP", "BIT_OR_OP", "BIT_AND_OP", "BIT_XOR_OP", 
		"DOT", "LR_BRACKET", "RR_BRACKET", "COMMA", "SEMI", "AT_SIGN", "ZERO_DECIMAL", 
		"ONE_DECIMAL", "TWO_DECIMAL", "SINGLE_QUOTE_SYMB", "DOUBLE_QUOTE_SYMB", 
		"REVERSE_QUOTE_SYMB", "COLON_SYMB", "QUOTE_SYMB", "CHARSET_REVERSE_QOUTE_STRING", 
		"FILESIZE_LITERAL", "START_NATIONAL_STRING_LITERAL", "STRING_LITERAL", 
		"DECIMAL_LITERAL", "HEXADECIMAL_LITERAL", "REAL_LITERAL", "NULL_SPEC_LITERAL", 
		"BIT_STRING", "STRING_CHARSET_NAME", "DOT_ID", "ID", "REVERSE_QUOTE_ID", 
		"HOST_IP_ADDRESS", "LOCAL_ID", "GLOBAL_ID", "CHARSET_NAME", "EXPONENT_NUM_PART", 
		"ID_LITERAL", "DQUOTA_STRING", "SQUOTA_STRING", "BQUOTA_STRING", "HEX_DIGIT", 
		"DEC_DIGIT", "BIT_STRING_L", "IP_ADDRESS", "ERROR_RECONGNIGION",
	];


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(this, MySqlLexer._ATN, MySqlLexer.DecisionsToDFA, new PredictionContextCache());
	}

	public get grammarFileName(): string { return "MySqlLexer.g4"; }

	public get literalNames(): (string | null)[] { return MySqlLexer.literalNames; }
	public get symbolicNames(): (string | null)[] { return MySqlLexer.symbolicNames; }
	public get ruleNames(): string[] { return MySqlLexer.ruleNames; }

	public get serializedATN(): number[] { return MySqlLexer._serializedATN; }

	public get channelNames(): string[] { return MySqlLexer.channelNames; }

	public get modeNames(): string[] { return MySqlLexer.modeNames; }

	public static readonly _serializedATN: number[] = [4,0,1161,13703,6,-1,
	2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,
	2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,
	7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,
	23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,
	2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,
	38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,
	7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,
	52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,
	2,60,7,60,2,61,7,61,2,62,7,62,2,63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,
	67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,
	7,74,2,75,7,75,2,76,7,76,2,77,7,77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,
	81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,
	2,89,7,89,2,90,7,90,2,91,7,91,2,92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,
	96,7,96,2,97,7,97,2,98,7,98,2,99,7,99,2,100,7,100,2,101,7,101,2,102,7,102,
	2,103,7,103,2,104,7,104,2,105,7,105,2,106,7,106,2,107,7,107,2,108,7,108,
	2,109,7,109,2,110,7,110,2,111,7,111,2,112,7,112,2,113,7,113,2,114,7,114,
	2,115,7,115,2,116,7,116,2,117,7,117,2,118,7,118,2,119,7,119,2,120,7,120,
	2,121,7,121,2,122,7,122,2,123,7,123,2,124,7,124,2,125,7,125,2,126,7,126,
	2,127,7,127,2,128,7,128,2,129,7,129,2,130,7,130,2,131,7,131,2,132,7,132,
	2,133,7,133,2,134,7,134,2,135,7,135,2,136,7,136,2,137,7,137,2,138,7,138,
	2,139,7,139,2,140,7,140,2,141,7,141,2,142,7,142,2,143,7,143,2,144,7,144,
	2,145,7,145,2,146,7,146,2,147,7,147,2,148,7,148,2,149,7,149,2,150,7,150,
	2,151,7,151,2,152,7,152,2,153,7,153,2,154,7,154,2,155,7,155,2,156,7,156,
	2,157,7,157,2,158,7,158,2,159,7,159,2,160,7,160,2,161,7,161,2,162,7,162,
	2,163,7,163,2,164,7,164,2,165,7,165,2,166,7,166,2,167,7,167,2,168,7,168,
	2,169,7,169,2,170,7,170,2,171,7,171,2,172,7,172,2,173,7,173,2,174,7,174,
	2,175,7,175,2,176,7,176,2,177,7,177,2,178,7,178,2,179,7,179,2,180,7,180,
	2,181,7,181,2,182,7,182,2,183,7,183,2,184,7,184,2,185,7,185,2,186,7,186,
	2,187,7,187,2,188,7,188,2,189,7,189,2,190,7,190,2,191,7,191,2,192,7,192,
	2,193,7,193,2,194,7,194,2,195,7,195,2,196,7,196,2,197,7,197,2,198,7,198,
	2,199,7,199,2,200,7,200,2,201,7,201,2,202,7,202,2,203,7,203,2,204,7,204,
	2,205,7,205,2,206,7,206,2,207,7,207,2,208,7,208,2,209,7,209,2,210,7,210,
	2,211,7,211,2,212,7,212,2,213,7,213,2,214,7,214,2,215,7,215,2,216,7,216,
	2,217,7,217,2,218,7,218,2,219,7,219,2,220,7,220,2,221,7,221,2,222,7,222,
	2,223,7,223,2,224,7,224,2,225,7,225,2,226,7,226,2,227,7,227,2,228,7,228,
	2,229,7,229,2,230,7,230,2,231,7,231,2,232,7,232,2,233,7,233,2,234,7,234,
	2,235,7,235,2,236,7,236,2,237,7,237,2,238,7,238,2,239,7,239,2,240,7,240,
	2,241,7,241,2,242,7,242,2,243,7,243,2,244,7,244,2,245,7,245,2,246,7,246,
	2,247,7,247,2,248,7,248,2,249,7,249,2,250,7,250,2,251,7,251,2,252,7,252,
	2,253,7,253,2,254,7,254,2,255,7,255,2,256,7,256,2,257,7,257,2,258,7,258,
	2,259,7,259,2,260,7,260,2,261,7,261,2,262,7,262,2,263,7,263,2,264,7,264,
	2,265,7,265,2,266,7,266,2,267,7,267,2,268,7,268,2,269,7,269,2,270,7,270,
	2,271,7,271,2,272,7,272,2,273,7,273,2,274,7,274,2,275,7,275,2,276,7,276,
	2,277,7,277,2,278,7,278,2,279,7,279,2,280,7,280,2,281,7,281,2,282,7,282,
	2,283,7,283,2,284,7,284,2,285,7,285,2,286,7,286,2,287,7,287,2,288,7,288,
	2,289,7,289,2,290,7,290,2,291,7,291,2,292,7,292,2,293,7,293,2,294,7,294,
	2,295,7,295,2,296,7,296,2,297,7,297,2,298,7,298,2,299,7,299,2,300,7,300,
	2,301,7,301,2,302,7,302,2,303,7,303,2,304,7,304,2,305,7,305,2,306,7,306,
	2,307,7,307,2,308,7,308,2,309,7,309,2,310,7,310,2,311,7,311,2,312,7,312,
	2,313,7,313,2,314,7,314,2,315,7,315,2,316,7,316,2,317,7,317,2,318,7,318,
	2,319,7,319,2,320,7,320,2,321,7,321,2,322,7,322,2,323,7,323,2,324,7,324,
	2,325,7,325,2,326,7,326,2,327,7,327,2,328,7,328,2,329,7,329,2,330,7,330,
	2,331,7,331,2,332,7,332,2,333,7,333,2,334,7,334,2,335,7,335,2,336,7,336,
	2,337,7,337,2,338,7,338,2,339,7,339,2,340,7,340,2,341,7,341,2,342,7,342,
	2,343,7,343,2,344,7,344,2,345,7,345,2,346,7,346,2,347,7,347,2,348,7,348,
	2,349,7,349,2,350,7,350,2,351,7,351,2,352,7,352,2,353,7,353,2,354,7,354,
	2,355,7,355,2,356,7,356,2,357,7,357,2,358,7,358,2,359,7,359,2,360,7,360,
	2,361,7,361,2,362,7,362,2,363,7,363,2,364,7,364,2,365,7,365,2,366,7,366,
	2,367,7,367,2,368,7,368,2,369,7,369,2,370,7,370,2,371,7,371,2,372,7,372,
	2,373,7,373,2,374,7,374,2,375,7,375,2,376,7,376,2,377,7,377,2,378,7,378,
	2,379,7,379,2,380,7,380,2,381,7,381,2,382,7,382,2,383,7,383,2,384,7,384,
	2,385,7,385,2,386,7,386,2,387,7,387,2,388,7,388,2,389,7,389,2,390,7,390,
	2,391,7,391,2,392,7,392,2,393,7,393,2,394,7,394,2,395,7,395,2,396,7,396,
	2,397,7,397,2,398,7,398,2,399,7,399,2,400,7,400,2,401,7,401,2,402,7,402,
	2,403,7,403,2,404,7,404,2,405,7,405,2,406,7,406,2,407,7,407,2,408,7,408,
	2,409,7,409,2,410,7,410,2,411,7,411,2,412,7,412,2,413,7,413,2,414,7,414,
	2,415,7,415,2,416,7,416,2,417,7,417,2,418,7,418,2,419,7,419,2,420,7,420,
	2,421,7,421,2,422,7,422,2,423,7,423,2,424,7,424,2,425,7,425,2,426,7,426,
	2,427,7,427,2,428,7,428,2,429,7,429,2,430,7,430,2,431,7,431,2,432,7,432,
	2,433,7,433,2,434,7,434,2,435,7,435,2,436,7,436,2,437,7,437,2,438,7,438,
	2,439,7,439,2,440,7,440,2,441,7,441,2,442,7,442,2,443,7,443,2,444,7,444,
	2,445,7,445,2,446,7,446,2,447,7,447,2,448,7,448,2,449,7,449,2,450,7,450,
	2,451,7,451,2,452,7,452,2,453,7,453,2,454,7,454,2,455,7,455,2,456,7,456,
	2,457,7,457,2,458,7,458,2,459,7,459,2,460,7,460,2,461,7,461,2,462,7,462,
	2,463,7,463,2,464,7,464,2,465,7,465,2,466,7,466,2,467,7,467,2,468,7,468,
	2,469,7,469,2,470,7,470,2,471,7,471,2,472,7,472,2,473,7,473,2,474,7,474,
	2,475,7,475,2,476,7,476,2,477,7,477,2,478,7,478,2,479,7,479,2,480,7,480,
	2,481,7,481,2,482,7,482,2,483,7,483,2,484,7,484,2,485,7,485,2,486,7,486,
	2,487,7,487,2,488,7,488,2,489,7,489,2,490,7,490,2,491,7,491,2,492,7,492,
	2,493,7,493,2,494,7,494,2,495,7,495,2,496,7,496,2,497,7,497,2,498,7,498,
	2,499,7,499,2,500,7,500,2,501,7,501,2,502,7,502,2,503,7,503,2,504,7,504,
	2,505,7,505,2,506,7,506,2,507,7,507,2,508,7,508,2,509,7,509,2,510,7,510,
	2,511,7,511,2,512,7,512,2,513,7,513,2,514,7,514,2,515,7,515,2,516,7,516,
	2,517,7,517,2,518,7,518,2,519,7,519,2,520,7,520,2,521,7,521,2,522,7,522,
	2,523,7,523,2,524,7,524,2,525,7,525,2,526,7,526,2,527,7,527,2,528,7,528,
	2,529,7,529,2,530,7,530,2,531,7,531,2,532,7,532,2,533,7,533,2,534,7,534,
	2,535,7,535,2,536,7,536,2,537,7,537,2,538,7,538,2,539,7,539,2,540,7,540,
	2,541,7,541,2,542,7,542,2,543,7,543,2,544,7,544,2,545,7,545,2,546,7,546,
	2,547,7,547,2,548,7,548,2,549,7,549,2,550,7,550,2,551,7,551,2,552,7,552,
	2,553,7,553,2,554,7,554,2,555,7,555,2,556,7,556,2,557,7,557,2,558,7,558,
	2,559,7,559,2,560,7,560,2,561,7,561,2,562,7,562,2,563,7,563,2,564,7,564,
	2,565,7,565,2,566,7,566,2,567,7,567,2,568,7,568,2,569,7,569,2,570,7,570,
	2,571,7,571,2,572,7,572,2,573,7,573,2,574,7,574,2,575,7,575,2,576,7,576,
	2,577,7,577,2,578,7,578,2,579,7,579,2,580,7,580,2,581,7,581,2,582,7,582,
	2,583,7,583,2,584,7,584,2,585,7,585,2,586,7,586,2,587,7,587,2,588,7,588,
	2,589,7,589,2,590,7,590,2,591,7,591,2,592,7,592,2,593,7,593,2,594,7,594,
	2,595,7,595,2,596,7,596,2,597,7,597,2,598,7,598,2,599,7,599,2,600,7,600,
	2,601,7,601,2,602,7,602,2,603,7,603,2,604,7,604,2,605,7,605,2,606,7,606,
	2,607,7,607,2,608,7,608,2,609,7,609,2,610,7,610,2,611,7,611,2,612,7,612,
	2,613,7,613,2,614,7,614,2,615,7,615,2,616,7,616,2,617,7,617,2,618,7,618,
	2,619,7,619,2,620,7,620,2,621,7,621,2,622,7,622,2,623,7,623,2,624,7,624,
	2,625,7,625,2,626,7,626,2,627,7,627,2,628,7,628,2,629,7,629,2,630,7,630,
	2,631,7,631,2,632,7,632,2,633,7,633,2,634,7,634,2,635,7,635,2,636,7,636,
	2,637,7,637,2,638,7,638,2,639,7,639,2,640,7,640,2,641,7,641,2,642,7,642,
	2,643,7,643,2,644,7,644,2,645,7,645,2,646,7,646,2,647,7,647,2,648,7,648,
	2,649,7,649,2,650,7,650,2,651,7,651,2,652,7,652,2,653,7,653,2,654,7,654,
	2,655,7,655,2,656,7,656,2,657,7,657,2,658,7,658,2,659,7,659,2,660,7,660,
	2,661,7,661,2,662,7,662,2,663,7,663,2,664,7,664,2,665,7,665,2,666,7,666,
	2,667,7,667,2,668,7,668,2,669,7,669,2,670,7,670,2,671,7,671,2,672,7,672,
	2,673,7,673,2,674,7,674,2,675,7,675,2,676,7,676,2,677,7,677,2,678,7,678,
	2,679,7,679,2,680,7,680,2,681,7,681,2,682,7,682,2,683,7,683,2,684,7,684,
	2,685,7,685,2,686,7,686,2,687,7,687,2,688,7,688,2,689,7,689,2,690,7,690,
	2,691,7,691,2,692,7,692,2,693,7,693,2,694,7,694,2,695,7,695,2,696,7,696,
	2,697,7,697,2,698,7,698,2,699,7,699,2,700,7,700,2,701,7,701,2,702,7,702,
	2,703,7,703,2,704,7,704,2,705,7,705,2,706,7,706,2,707,7,707,2,708,7,708,
	2,709,7,709,2,710,7,710,2,711,7,711,2,712,7,712,2,713,7,713,2,714,7,714,
	2,715,7,715,2,716,7,716,2,717,7,717,2,718,7,718,2,719,7,719,2,720,7,720,
	2,721,7,721,2,722,7,722,2,723,7,723,2,724,7,724,2,725,7,725,2,726,7,726,
	2,727,7,727,2,728,7,728,2,729,7,729,2,730,7,730,2,731,7,731,2,732,7,732,
	2,733,7,733,2,734,7,734,2,735,7,735,2,736,7,736,2,737,7,737,2,738,7,738,
	2,739,7,739,2,740,7,740,2,741,7,741,2,742,7,742,2,743,7,743,2,744,7,744,
	2,745,7,745,2,746,7,746,2,747,7,747,2,748,7,748,2,749,7,749,2,750,7,750,
	2,751,7,751,2,752,7,752,2,753,7,753,2,754,7,754,2,755,7,755,2,756,7,756,
	2,757,7,757,2,758,7,758,2,759,7,759,2,760,7,760,2,761,7,761,2,762,7,762,
	2,763,7,763,2,764,7,764,2,765,7,765,2,766,7,766,2,767,7,767,2,768,7,768,
	2,769,7,769,2,770,7,770,2,771,7,771,2,772,7,772,2,773,7,773,2,774,7,774,
	2,775,7,775,2,776,7,776,2,777,7,777,2,778,7,778,2,779,7,779,2,780,7,780,
	2,781,7,781,2,782,7,782,2,783,7,783,2,784,7,784,2,785,7,785,2,786,7,786,
	2,787,7,787,2,788,7,788,2,789,7,789,2,790,7,790,2,791,7,791,2,792,7,792,
	2,793,7,793,2,794,7,794,2,795,7,795,2,796,7,796,2,797,7,797,2,798,7,798,
	2,799,7,799,2,800,7,800,2,801,7,801,2,802,7,802,2,803,7,803,2,804,7,804,
	2,805,7,805,2,806,7,806,2,807,7,807,2,808,7,808,2,809,7,809,2,810,7,810,
	2,811,7,811,2,812,7,812,2,813,7,813,2,814,7,814,2,815,7,815,2,816,7,816,
	2,817,7,817,2,818,7,818,2,819,7,819,2,820,7,820,2,821,7,821,2,822,7,822,
	2,823,7,823,2,824,7,824,2,825,7,825,2,826,7,826,2,827,7,827,2,828,7,828,
	2,829,7,829,2,830,7,830,2,831,7,831,2,832,7,832,2,833,7,833,2,834,7,834,
	2,835,7,835,2,836,7,836,2,837,7,837,2,838,7,838,2,839,7,839,2,840,7,840,
	2,841,7,841,2,842,7,842,2,843,7,843,2,844,7,844,2,845,7,845,2,846,7,846,
	2,847,7,847,2,848,7,848,2,849,7,849,2,850,7,850,2,851,7,851,2,852,7,852,
	2,853,7,853,2,854,7,854,2,855,7,855,2,856,7,856,2,857,7,857,2,858,7,858,
	2,859,7,859,2,860,7,860,2,861,7,861,2,862,7,862,2,863,7,863,2,864,7,864,
	2,865,7,865,2,866,7,866,2,867,7,867,2,868,7,868,2,869,7,869,2,870,7,870,
	2,871,7,871,2,872,7,872,2,873,7,873,2,874,7,874,2,875,7,875,2,876,7,876,
	2,877,7,877,2,878,7,878,2,879,7,879,2,880,7,880,2,881,7,881,2,882,7,882,
	2,883,7,883,2,884,7,884,2,885,7,885,2,886,7,886,2,887,7,887,2,888,7,888,
	2,889,7,889,2,890,7,890,2,891,7,891,2,892,7,892,2,893,7,893,2,894,7,894,
	2,895,7,895,2,896,7,896,2,897,7,897,2,898,7,898,2,899,7,899,2,900,7,900,
	2,901,7,901,2,902,7,902,2,903,7,903,2,904,7,904,2,905,7,905,2,906,7,906,
	2,907,7,907,2,908,7,908,2,909,7,909,2,910,7,910,2,911,7,911,2,912,7,912,
	2,913,7,913,2,914,7,914,2,915,7,915,2,916,7,916,2,917,7,917,2,918,7,918,
	2,919,7,919,2,920,7,920,2,921,7,921,2,922,7,922,2,923,7,923,2,924,7,924,
	2,925,7,925,2,926,7,926,2,927,7,927,2,928,7,928,2,929,7,929,2,930,7,930,
	2,931,7,931,2,932,7,932,2,933,7,933,2,934,7,934,2,935,7,935,2,936,7,936,
	2,937,7,937,2,938,7,938,2,939,7,939,2,940,7,940,2,941,7,941,2,942,7,942,
	2,943,7,943,2,944,7,944,2,945,7,945,2,946,7,946,2,947,7,947,2,948,7,948,
	2,949,7,949,2,950,7,950,2,951,7,951,2,952,7,952,2,953,7,953,2,954,7,954,
	2,955,7,955,2,956,7,956,2,957,7,957,2,958,7,958,2,959,7,959,2,960,7,960,
	2,961,7,961,2,962,7,962,2,963,7,963,2,964,7,964,2,965,7,965,2,966,7,966,
	2,967,7,967,2,968,7,968,2,969,7,969,2,970,7,970,2,971,7,971,2,972,7,972,
	2,973,7,973,2,974,7,974,2,975,7,975,2,976,7,976,2,977,7,977,2,978,7,978,
	2,979,7,979,2,980,7,980,2,981,7,981,2,982,7,982,2,983,7,983,2,984,7,984,
	2,985,7,985,2,986,7,986,2,987,7,987,2,988,7,988,2,989,7,989,2,990,7,990,
	2,991,7,991,2,992,7,992,2,993,7,993,2,994,7,994,2,995,7,995,2,996,7,996,
	2,997,7,997,2,998,7,998,2,999,7,999,2,1000,7,1000,2,1001,7,1001,2,1002,
	7,1002,2,1003,7,1003,2,1004,7,1004,2,1005,7,1005,2,1006,7,1006,2,1007,7,
	1007,2,1008,7,1008,2,1009,7,1009,2,1010,7,1010,2,1011,7,1011,2,1012,7,1012,
	2,1013,7,1013,2,1014,7,1014,2,1015,7,1015,2,1016,7,1016,2,1017,7,1017,2,
	1018,7,1018,2,1019,7,1019,2,1020,7,1020,2,1021,7,1021,2,1022,7,1022,2,1023,
	7,1023,2,1024,7,1024,2,1025,7,1025,2,1026,7,1026,2,1027,7,1027,2,1028,7,
	1028,2,1029,7,1029,2,1030,7,1030,2,1031,7,1031,2,1032,7,1032,2,1033,7,1033,
	2,1034,7,1034,2,1035,7,1035,2,1036,7,1036,2,1037,7,1037,2,1038,7,1038,2,
	1039,7,1039,2,1040,7,1040,2,1041,7,1041,2,1042,7,1042,2,1043,7,1043,2,1044,
	7,1044,2,1045,7,1045,2,1046,7,1046,2,1047,7,1047,2,1048,7,1048,2,1049,7,
	1049,2,1050,7,1050,2,1051,7,1051,2,1052,7,1052,2,1053,7,1053,2,1054,7,1054,
	2,1055,7,1055,2,1056,7,1056,2,1057,7,1057,2,1058,7,1058,2,1059,7,1059,2,
	1060,7,1060,2,1061,7,1061,2,1062,7,1062,2,1063,7,1063,2,1064,7,1064,2,1065,
	7,1065,2,1066,7,1066,2,1067,7,1067,2,1068,7,1068,2,1069,7,1069,2,1070,7,
	1070,2,1071,7,1071,2,1072,7,1072,2,1073,7,1073,2,1074,7,1074,2,1075,7,1075,
	2,1076,7,1076,2,1077,7,1077,2,1078,7,1078,2,1079,7,1079,2,1080,7,1080,2,
	1081,7,1081,2,1082,7,1082,2,1083,7,1083,2,1084,7,1084,2,1085,7,1085,2,1086,
	7,1086,2,1087,7,1087,2,1088,7,1088,2,1089,7,1089,2,1090,7,1090,2,1091,7,
	1091,2,1092,7,1092,2,1093,7,1093,2,1094,7,1094,2,1095,7,1095,2,1096,7,1096,
	2,1097,7,1097,2,1098,7,1098,2,1099,7,1099,2,1100,7,1100,2,1101,7,1101,2,
	1102,7,1102,2,1103,7,1103,2,1104,7,1104,2,1105,7,1105,2,1106,7,1106,2,1107,
	7,1107,2,1108,7,1108,2,1109,7,1109,2,1110,7,1110,2,1111,7,1111,2,1112,7,
	1112,2,1113,7,1113,2,1114,7,1114,2,1115,7,1115,2,1116,7,1116,2,1117,7,1117,
	2,1118,7,1118,2,1119,7,1119,2,1120,7,1120,2,1121,7,1121,2,1122,7,1122,2,
	1123,7,1123,2,1124,7,1124,2,1125,7,1125,2,1126,7,1126,2,1127,7,1127,2,1128,
	7,1128,2,1129,7,1129,2,1130,7,1130,2,1131,7,1131,2,1132,7,1132,2,1133,7,
	1133,2,1134,7,1134,2,1135,7,1135,2,1136,7,1136,2,1137,7,1137,2,1138,7,1138,
	2,1139,7,1139,2,1140,7,1140,2,1141,7,1141,2,1142,7,1142,2,1143,7,1143,2,
	1144,7,1144,2,1145,7,1145,2,1146,7,1146,2,1147,7,1147,2,1148,7,1148,2,1149,
	7,1149,2,1150,7,1150,2,1151,7,1151,2,1152,7,1152,2,1153,7,1153,2,1154,7,
	1154,2,1155,7,1155,2,1156,7,1156,2,1157,7,1157,2,1158,7,1158,2,1159,7,1159,
	2,1160,7,1160,2,1161,7,1161,2,1162,7,1162,2,1163,7,1163,2,1164,7,1164,2,
	1165,7,1165,2,1166,7,1166,2,1167,7,1167,2,1168,7,1168,2,1169,7,1169,2,1170,
	7,1170,2,1171,7,1171,1,0,4,0,2347,8,0,11,0,12,0,2348,1,0,1,0,1,1,1,1,1,
	1,1,1,1,1,4,1,2358,8,1,11,1,12,1,2359,1,1,1,1,1,1,1,1,1,1,1,2,1,2,1,2,1,
	2,5,2,2371,8,2,10,2,12,2,2374,9,2,1,2,1,2,1,2,1,2,1,2,1,3,1,3,1,3,1,3,5,
	3,2385,8,3,10,3,12,3,2388,9,3,1,3,3,3,2391,8,3,1,3,5,3,2394,8,3,10,3,12,
	3,2397,9,3,1,3,3,3,2400,8,3,1,3,1,3,3,3,2404,8,3,1,3,1,3,1,3,1,3,3,3,2410,
	8,3,1,3,1,3,3,3,2414,8,3,3,3,2416,8,3,1,3,1,3,1,4,1,4,1,4,1,4,1,5,1,5,1,
	5,1,5,1,6,1,6,1,6,1,6,1,6,1,6,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,8,1,8,1,8,1,
	8,1,8,1,8,1,8,1,8,1,9,1,9,1,9,1,9,1,10,1,10,1,10,1,10,1,10,1,10,1,11,1,
	11,1,11,1,12,1,12,1,12,1,12,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,
	1,13,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,15,1,15,1,15,1,15,1,15,1,15,1,
	15,1,15,1,16,1,16,1,16,1,16,1,16,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,
	1,18,1,18,1,18,1,19,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,20,1,
	20,1,20,1,21,1,21,1,21,1,21,1,21,1,22,1,22,1,22,1,22,1,22,1,23,1,23,1,23,
	1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,
	25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,27,
	1,27,1,27,1,27,1,27,1,27,1,27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,
	28,1,28,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,29,1,30,1,30,
	1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,31,1,31,1,31,1,31,1,31,1,31,1,31,1,
	31,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,33,1,33,1,33,1,33,1,33,1,33,1,34,
	1,34,1,34,1,34,1,34,1,34,1,34,1,34,1,35,1,35,1,35,1,35,1,35,1,35,1,35,1,
	35,1,35,1,35,1,35,1,35,1,35,1,36,1,36,1,36,1,36,1,36,1,36,1,36,1,36,1,36,
	1,36,1,36,1,36,1,36,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,38,1,38,1,38,1,
	38,1,38,1,38,1,38,1,38,1,38,1,39,1,39,1,39,1,39,1,39,1,39,1,39,1,39,1,39,
	1,39,1,40,1,40,1,40,1,40,1,40,1,40,1,40,1,40,1,41,1,41,1,41,1,41,1,41,1,
	41,1,41,1,41,1,42,1,42,1,42,1,42,1,42,1,42,1,42,1,42,1,43,1,43,1,43,1,43,
	1,43,1,43,1,43,1,44,1,44,1,44,1,44,1,44,1,45,1,45,1,45,1,45,1,45,1,45,1,
	45,1,45,1,45,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,46,
	1,46,1,46,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,
	48,1,48,1,48,1,48,1,48,1,48,1,48,1,48,1,48,1,49,1,49,1,49,1,49,1,49,1,49,
	1,49,1,49,1,49,1,49,1,49,1,49,1,50,1,50,1,50,1,50,1,50,1,51,1,51,1,51,1,
	51,1,51,1,52,1,52,1,52,1,52,1,52,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,54,
	1,54,1,54,1,54,1,54,1,54,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,
	56,1,56,1,56,1,56,1,56,1,56,1,56,1,56,1,56,1,57,1,57,1,57,1,57,1,57,1,57,
	1,57,1,57,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,59,1,
	59,1,59,1,60,1,60,1,60,1,60,1,60,1,61,1,61,1,61,1,61,1,61,1,61,1,61,1,61,
	1,62,1,62,1,62,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,63,1,64,1,64,1,
	64,1,64,1,65,1,65,1,65,1,65,1,65,1,65,1,66,1,66,1,66,1,66,1,66,1,66,1,66,
	1,66,1,67,1,67,1,67,1,67,1,67,1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,
	68,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,69,1,70,1,70,1,70,1,70,
	1,71,1,71,1,71,1,71,1,71,1,71,1,72,1,72,1,72,1,72,1,72,1,72,1,73,1,73,1,
	73,1,73,1,73,1,73,1,73,1,74,1,74,1,74,1,74,1,74,1,74,1,74,1,74,1,74,1,74,
	1,74,1,74,1,74,1,74,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,
	76,1,76,1,76,1,77,1,77,1,77,1,77,1,77,1,77,1,77,1,78,1,78,1,78,1,78,1,78,
	1,78,1,78,1,78,1,79,1,79,1,79,1,80,1,80,1,80,1,80,1,80,1,80,1,81,1,81,1,
	81,1,81,1,81,1,81,1,81,1,82,1,82,1,82,1,82,1,82,1,82,1,83,1,83,1,83,1,83,
	1,83,1,83,1,84,1,84,1,84,1,84,1,84,1,84,1,84,1,85,1,85,1,85,1,85,1,85,1,
	85,1,85,1,85,1,85,1,86,1,86,1,86,1,86,1,86,1,87,1,87,1,87,1,88,1,88,1,88,
	1,88,1,88,1,88,1,88,1,88,1,89,1,89,1,89,1,89,1,89,1,90,1,90,1,90,1,90,1,
	91,1,91,1,91,1,91,1,91,1,92,1,92,1,92,1,92,1,92,1,93,1,93,1,93,1,93,1,93,
	1,93,1,93,1,93,1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,95,1,95,1,95,1,
	95,1,95,1,95,1,96,1,96,1,96,1,96,1,96,1,97,1,97,1,97,1,97,1,97,1,98,1,98,
	1,98,1,98,1,98,1,98,1,99,1,99,1,99,1,99,1,99,1,99,1,99,1,100,1,100,1,100,
	1,100,1,100,1,100,1,101,1,101,1,101,1,101,1,101,1,102,1,102,1,102,1,102,
	1,102,1,103,1,103,1,103,1,103,1,103,1,103,1,103,1,104,1,104,1,104,1,104,
	1,104,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,
	1,105,1,105,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,1,106,
	1,106,1,106,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,
	1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,
	1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,108,1,108,1,108,1,108,
	1,108,1,108,1,109,1,109,1,109,1,109,1,109,1,109,1,109,1,109,1,109,1,110,
	1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,111,1,111,1,111,1,111,
	1,111,1,111,1,111,1,111,1,111,1,112,1,112,1,112,1,112,1,112,1,112,1,112,
	1,112,1,113,1,113,1,113,1,113,1,114,1,114,1,114,1,114,1,114,1,114,1,114,
	1,114,1,114,1,114,1,114,1,114,1,114,1,114,1,114,1,114,1,114,1,114,1,114,
	1,115,1,115,1,115,1,115,1,115,1,116,1,116,1,116,1,116,1,116,1,116,1,116,
	1,117,1,117,1,117,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,
	1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,120,1,120,1,120,1,120,1,120,
	1,120,1,120,1,120,1,120,1,121,1,121,1,121,1,121,1,121,1,121,1,121,1,121,
	1,121,1,121,1,121,1,122,1,122,1,122,1,123,1,123,1,123,1,123,1,123,1,123,
	1,124,1,124,1,124,1,124,1,125,1,125,1,125,1,125,1,125,1,125,1,126,1,126,
	1,126,1,126,1,126,1,126,1,126,1,126,1,127,1,127,1,127,1,127,1,127,1,128,
	1,128,1,128,1,128,1,128,1,128,1,128,1,128,1,128,1,128,1,129,1,129,1,129,
	1,129,1,129,1,129,1,129,1,129,1,130,1,130,1,130,1,130,1,130,1,130,1,130,
	1,130,1,130,1,130,1,131,1,131,1,131,1,131,1,131,1,131,1,132,1,132,1,132,
	1,132,1,132,1,132,1,133,1,133,1,133,1,133,1,133,1,134,1,134,1,134,1,134,
	1,134,1,134,1,135,1,135,1,135,1,135,1,135,1,135,1,135,1,135,1,135,1,135,
	1,135,1,136,1,136,1,136,1,136,1,136,1,136,1,136,1,137,1,137,1,137,1,137,
	1,137,1,137,1,137,1,137,1,138,1,138,1,138,1,138,1,138,1,138,1,138,1,139,
	1,139,1,139,1,139,1,139,1,139,1,139,1,140,1,140,1,140,1,140,1,140,1,140,
	1,140,1,140,1,141,1,141,1,141,1,141,1,141,1,141,1,141,1,141,1,142,1,142,
	1,142,1,142,1,142,1,142,1,142,1,142,1,142,1,143,1,143,1,143,1,143,1,143,
	1,143,1,143,1,143,1,143,1,144,1,144,1,144,1,144,1,144,1,144,1,144,1,145,
	1,145,1,145,1,145,1,145,1,145,1,145,1,146,1,146,1,146,1,146,1,146,1,146,
	1,146,1,147,1,147,1,147,1,147,1,147,1,147,1,148,1,148,1,148,1,148,1,148,
	1,148,1,149,1,149,1,149,1,149,1,149,1,149,1,149,1,150,1,150,1,150,1,150,
	1,150,1,150,1,150,1,150,1,151,1,151,1,151,1,151,1,151,1,151,1,151,1,152,
	1,152,1,152,1,152,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,
	1,153,1,154,1,154,1,154,1,154,1,154,1,155,1,155,1,155,1,155,1,155,1,155,
	1,155,1,156,1,156,1,156,1,156,1,156,1,157,1,157,1,157,1,157,1,157,1,157,
	1,157,1,157,1,157,1,157,1,157,1,157,1,157,1,157,1,157,1,157,1,157,1,157,
	1,157,1,158,1,158,1,158,1,158,1,158,1,158,1,158,1,158,1,159,1,159,1,159,
	1,159,1,160,1,160,1,160,1,160,1,160,1,160,1,160,1,160,1,160,1,160,1,160,
	1,160,1,160,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,162,
	1,162,1,162,1,162,1,162,1,162,1,162,1,162,1,162,1,162,1,162,1,163,1,163,
	1,163,1,163,1,163,1,163,1,163,1,163,1,163,1,163,1,163,1,163,1,163,1,163,
	1,163,1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,164,
	1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,164,1,165,1,165,1,165,
	1,165,1,165,1,165,1,165,1,165,1,165,1,165,1,165,1,165,1,165,1,165,1,165,
	1,165,1,165,1,166,1,166,1,166,1,166,1,167,1,167,1,167,1,167,1,167,1,167,
	1,167,1,167,1,168,1,168,1,168,1,168,1,168,1,168,1,168,1,168,1,168,1,169,
	1,169,1,169,1,169,1,169,1,169,1,169,1,169,1,169,1,169,1,170,1,170,1,170,
	1,170,1,170,1,170,1,170,1,170,1,170,1,170,1,170,1,170,1,170,1,170,1,171,
	1,171,1,171,1,171,1,171,1,171,1,172,1,172,1,172,1,172,1,172,1,172,1,172,
	1,172,1,172,1,172,1,172,1,173,1,173,1,173,1,173,1,173,1,174,1,174,1,174,
	1,175,1,175,1,175,1,175,1,175,1,175,1,175,1,175,1,175,1,176,1,176,1,176,
	1,176,1,176,1,176,1,176,1,176,1,177,1,177,1,177,1,177,1,177,1,178,1,178,
	1,178,1,178,1,178,1,179,1,179,1,179,1,179,1,179,1,179,1,180,1,180,1,180,
	1,180,1,180,1,180,1,180,1,181,1,181,1,181,1,181,1,181,1,181,1,181,1,182,
	1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,183,1,183,1,183,1,183,
	1,183,1,183,1,183,1,184,1,184,1,184,1,184,1,184,1,184,1,185,1,185,1,185,
	1,185,1,186,1,186,1,186,1,186,1,186,1,186,1,187,1,187,1,187,1,187,1,187,
	1,187,1,187,1,188,1,188,1,188,1,188,1,188,1,189,1,189,1,189,1,189,1,189,
	1,189,1,190,1,190,1,190,1,190,1,190,1,190,1,191,1,191,1,191,1,191,1,191,
	1,192,1,192,1,192,1,192,1,192,1,192,1,193,1,193,1,193,1,193,1,194,1,194,
	1,194,1,194,1,194,1,194,1,194,1,194,1,194,1,195,1,195,1,195,1,195,1,195,
	1,195,1,195,1,195,1,196,1,196,1,196,1,196,1,196,1,196,1,196,1,196,1,196,
	1,197,1,197,1,197,1,197,1,197,1,197,1,197,1,197,1,197,1,197,1,198,1,198,
	1,198,1,198,1,198,1,198,1,198,1,198,1,198,1,198,1,199,1,199,1,199,1,199,
	1,200,1,200,1,200,1,200,1,200,1,201,1,201,1,201,1,201,1,201,1,202,1,202,
	1,202,1,202,1,202,1,203,1,203,1,203,1,203,1,203,1,204,1,204,1,204,1,204,
	1,204,1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,206,1,206,1,206,
	1,206,1,206,1,206,1,206,1,207,1,207,1,207,1,207,1,207,1,208,1,208,1,208,
	1,208,1,208,1,208,1,208,1,209,1,209,1,209,1,209,1,209,1,209,1,209,1,209,
	1,209,1,209,1,210,1,210,1,210,1,210,1,210,1,210,1,211,1,211,1,211,1,211,
	1,211,1,211,1,211,1,212,1,212,1,212,1,212,1,212,1,212,1,212,1,213,1,213,
	1,213,1,213,1,213,1,213,1,213,1,213,1,214,1,214,1,214,1,214,1,215,1,215,
	1,215,1,215,1,215,1,215,1,215,1,215,1,216,1,216,1,216,1,216,1,216,1,217,
	1,217,1,217,1,217,1,217,1,218,1,218,1,218,1,218,1,218,1,218,1,218,1,218,
	1,218,1,218,1,219,1,219,1,219,1,219,1,219,1,219,1,219,1,219,1,219,1,220,
	1,220,1,220,1,220,1,220,1,221,1,221,1,221,1,221,1,221,1,222,1,222,1,222,
	1,222,1,222,1,222,1,222,1,222,1,223,1,223,1,223,1,223,1,223,1,223,1,223,
	1,223,1,223,1,224,1,224,1,224,1,224,1,224,1,224,1,224,1,224,1,224,1,225,
	1,225,1,225,1,225,1,225,1,225,1,225,1,226,1,226,1,226,1,226,1,226,1,226,
	1,226,1,226,1,226,1,226,1,227,1,227,1,227,1,227,1,227,1,227,1,227,1,227,
	1,227,1,228,1,228,1,228,1,228,1,228,1,229,1,229,1,229,1,229,1,229,1,229,
	1,229,1,229,1,229,1,229,1,229,1,230,1,230,1,230,1,230,1,230,1,231,1,231,
	1,231,1,231,1,231,1,231,1,231,1,231,1,231,1,232,1,232,1,232,1,232,1,232,
	1,232,1,232,1,232,1,232,1,233,1,233,1,233,1,233,1,233,1,234,1,234,1,234,
	1,234,1,234,1,234,1,234,1,234,1,234,1,234,1,234,1,235,1,235,1,235,1,235,
	1,235,1,235,1,235,1,235,1,235,1,236,1,236,1,236,1,236,1,236,1,237,1,237,
	1,237,1,237,1,237,1,237,1,237,1,237,1,238,1,238,1,238,1,238,1,238,1,238,
	1,238,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,
	1,240,1,240,1,240,1,240,1,240,1,240,1,240,1,240,1,240,1,241,1,241,1,241,
	1,241,1,241,1,241,1,241,1,241,1,241,1,241,1,241,1,242,1,242,1,242,1,242,
	1,242,1,242,1,242,1,242,1,242,1,242,1,242,1,243,1,243,1,243,1,243,1,243,
	1,243,1,243,1,243,1,243,1,243,1,243,1,243,1,244,1,244,1,244,1,244,1,244,
	1,244,1,244,1,244,1,244,1,244,1,244,1,244,1,245,1,245,1,245,1,245,1,245,
	1,245,1,245,1,245,1,245,1,245,1,245,1,245,1,245,1,245,1,246,1,246,1,246,
	1,246,1,246,1,246,1,246,1,246,1,246,1,246,1,246,1,246,1,246,1,246,1,246,
	1,246,1,246,1,246,1,246,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,
	1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,248,
	1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,
	1,248,1,248,1,248,1,248,1,249,1,249,1,249,1,249,1,249,1,249,1,249,1,249,
	1,249,1,249,1,249,1,249,1,249,1,249,1,249,1,249,1,250,1,250,1,250,1,250,
	1,250,1,250,1,250,1,250,1,250,1,250,1,250,1,251,1,251,1,251,1,251,1,251,
	1,251,1,251,1,251,1,251,1,251,1,251,1,251,1,251,1,251,1,252,1,252,1,252,
	1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,
	1,252,1,252,1,252,1,253,1,253,1,253,1,253,1,253,1,253,1,253,1,253,1,253,
	1,253,1,253,1,253,1,253,1,253,1,253,1,253,1,253,1,253,1,254,1,254,1,254,
	1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,255,
	1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,
	1,255,1,255,1,255,1,255,1,255,1,255,1,256,1,256,1,256,1,256,1,256,1,256,
	1,256,1,256,1,256,1,256,1,256,1,257,1,257,1,257,1,257,1,257,1,257,1,257,
	1,257,1,257,1,257,1,257,1,257,1,257,1,258,1,258,1,258,1,258,1,258,1,258,
	1,258,1,258,1,258,1,258,1,258,1,258,1,259,1,259,1,259,1,259,1,259,1,259,
	1,259,1,259,1,259,1,259,1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,260,
	1,260,1,260,1,260,1,260,1,261,1,261,1,261,1,261,1,261,1,261,1,261,1,261,
	1,261,1,261,1,261,1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,262,
	1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,263,1,263,1,263,1,263,
	1,263,1,263,1,263,1,263,1,263,1,263,1,263,1,263,1,263,1,263,1,263,1,263,
	1,263,1,263,1,263,1,263,1,264,1,264,1,264,1,264,1,264,1,264,1,264,1,264,
	1,264,1,264,1,264,1,264,1,265,1,265,1,265,1,265,1,265,1,265,1,265,1,265,
	1,265,1,265,1,265,1,265,1,265,1,265,1,265,1,266,1,266,1,266,1,266,1,266,
	1,266,1,266,1,266,1,266,1,266,1,266,1,266,1,266,1,266,1,267,1,267,1,267,
	1,267,1,267,1,267,1,267,1,267,1,267,1,267,1,267,1,267,1,268,1,268,1,268,
	1,268,1,268,1,268,1,268,1,268,1,268,1,268,1,268,1,269,1,269,1,269,1,269,
	1,269,1,269,1,269,1,269,1,269,1,269,1,269,1,269,1,270,1,270,1,270,1,270,
	1,270,1,270,1,270,1,270,1,270,1,270,1,270,1,270,1,270,1,271,1,271,1,271,
	1,271,1,271,1,271,1,271,1,271,1,271,1,271,1,271,1,271,1,271,1,271,1,271,
	1,271,1,271,1,271,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,
	1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,
	1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,273,1,273,1,273,
	1,273,1,273,1,273,1,273,1,273,1,273,1,273,1,273,1,273,1,274,1,274,1,274,
	1,274,1,274,1,274,1,274,1,274,1,274,1,275,1,275,1,275,1,275,1,275,1,275,
	1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,
	1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,
	1,276,1,276,1,276,1,276,1,276,1,276,1,277,1,277,1,277,1,277,1,277,1,277,
	1,277,1,277,1,277,1,277,1,277,1,278,1,278,1,278,1,278,1,278,1,278,1,278,
	1,278,1,278,1,278,1,279,1,279,1,279,1,279,1,279,1,279,1,279,1,279,1,279,
	1,279,1,279,1,279,1,279,1,280,1,280,1,280,1,280,1,280,1,280,1,280,1,280,
	1,280,1,280,1,280,1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,281,
	1,281,1,281,1,282,1,282,1,282,1,282,1,282,1,282,1,282,1,283,1,283,1,283,
	1,283,1,283,1,283,1,283,1,283,1,283,1,283,1,283,1,284,1,284,1,284,1,284,
	1,284,1,285,1,285,1,285,1,285,1,286,1,286,1,286,1,286,1,286,1,286,1,286,
	1,286,1,287,1,287,1,287,1,287,1,287,1,287,1,287,1,288,1,288,1,288,1,288,
	1,288,1,288,1,288,1,288,1,289,1,289,1,289,1,289,1,289,1,289,1,290,1,290,
	1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,291,1,291,1,291,1,291,
	1,291,1,291,1,291,1,291,1,291,1,291,1,291,1,292,1,292,1,292,1,292,1,292,
	1,292,1,292,1,292,1,292,1,292,1,292,1,292,1,293,1,293,1,293,1,293,1,293,
	1,293,1,293,1,293,1,293,1,293,1,293,1,293,1,293,1,294,1,294,1,294,1,294,
	1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,296,
	1,296,1,296,1,296,1,296,1,297,1,297,1,297,1,297,1,298,1,298,1,298,1,298,
	1,299,1,299,1,299,1,299,1,299,1,299,1,300,1,300,1,300,1,300,1,300,1,300,
	1,300,1,300,1,300,1,300,1,301,1,301,1,301,1,301,1,301,1,301,1,301,1,301,
	1,301,1,301,1,301,1,301,1,301,1,302,1,302,1,302,1,302,1,302,1,303,1,303,
	1,303,1,303,1,303,1,303,1,303,1,303,1,303,1,303,1,303,1,304,1,304,1,304,
	1,304,1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,306,1,306,1,306,1,306,
	1,306,1,306,1,306,1,306,1,306,1,306,1,306,1,307,1,307,1,307,1,307,1,307,
	1,307,1,307,1,307,1,307,1,307,1,307,1,307,1,308,1,308,1,308,1,308,1,309,
	1,309,1,309,1,309,1,309,1,309,1,309,1,309,1,310,1,310,1,310,1,310,1,310,
	1,310,1,310,1,310,1,310,1,311,1,311,1,311,1,311,1,311,1,311,1,311,1,311,
	1,311,1,312,1,312,1,312,1,312,1,312,1,312,1,312,1,312,1,312,1,312,1,312,
	1,312,1,312,1,313,1,313,1,313,1,313,1,313,1,313,1,313,1,313,1,313,1,313,
	1,313,1,313,1,313,1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,314,
	1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,315,1,315,1,315,
	1,315,1,315,1,315,1,315,1,315,1,315,1,315,1,316,1,316,1,316,1,316,1,316,
	1,316,1,316,1,316,1,317,1,317,1,317,1,317,1,317,1,317,1,317,1,317,1,318,
	1,318,1,318,1,318,1,318,1,318,1,318,1,318,1,318,1,319,1,319,1,319,1,319,
	1,319,1,319,1,319,1,319,1,319,1,320,1,320,1,320,1,320,1,320,1,320,1,320,
	1,320,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,
	1,321,1,321,1,321,1,321,1,322,1,322,1,322,1,322,1,323,1,323,1,323,1,323,
	1,323,1,323,1,323,1,323,1,323,1,324,1,324,1,324,1,324,1,324,1,324,1,324,
	1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,326,1,326,
	1,326,1,326,1,326,1,326,1,326,1,326,1,327,1,327,1,327,1,327,1,327,1,328,
	1,328,1,328,1,328,1,328,1,328,1,328,1,328,1,328,1,329,1,329,1,329,1,329,
	1,329,1,329,1,329,1,329,1,329,1,330,1,330,1,330,1,330,1,330,1,330,1,330,
	1,330,1,330,1,330,1,330,1,330,1,330,1,330,1,331,1,331,1,331,1,331,1,331,
	1,331,1,331,1,331,1,332,1,332,1,332,1,332,1,332,1,332,1,332,1,333,1,333,
	1,333,1,333,1,333,1,333,1,334,1,334,1,334,1,334,1,334,1,334,1,334,1,334,
	1,334,1,334,1,335,1,335,1,335,1,335,1,335,1,335,1,335,1,335,1,335,1,335,
	1,336,1,336,1,336,1,336,1,337,1,337,1,337,1,338,1,338,1,338,1,338,1,338,
	1,338,1,338,1,338,1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,
	1,339,1,339,1,340,1,340,1,340,1,340,1,340,1,340,1,340,1,340,1,340,1,340,
	1,340,1,340,1,340,1,340,1,340,1,340,1,341,1,341,1,341,1,341,1,341,1,341,
	1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,342,1,342,1,342,
	1,342,1,342,1,342,1,342,1,342,1,342,1,342,1,342,1,342,1,342,1,342,1,342,
	1,343,1,343,1,343,1,343,1,343,1,343,1,344,1,344,1,344,1,344,1,344,1,344,
	1,344,1,345,1,345,1,345,1,345,1,346,1,346,1,346,1,346,1,346,1,346,1,347,
	1,347,1,347,1,347,1,347,1,348,1,348,1,348,1,348,1,348,1,348,1,348,1,348,
	1,349,1,349,1,349,1,349,1,349,1,349,1,350,1,350,1,350,1,350,1,350,1,350,
	1,351,1,351,1,351,1,351,1,351,1,351,1,351,1,351,1,351,1,352,1,352,1,352,
	1,352,1,352,1,352,1,353,1,353,1,353,1,353,1,353,1,353,1,353,1,353,1,354,
	1,354,1,354,1,354,1,354,1,354,1,354,1,354,1,355,1,355,1,355,1,355,1,355,
	1,355,1,355,1,355,1,355,1,356,1,356,1,356,1,356,1,356,1,356,1,356,1,356,
	1,356,1,356,1,356,1,356,1,356,1,356,1,357,1,357,1,357,1,357,1,357,1,357,
	1,357,1,358,1,358,1,358,1,358,1,358,1,358,1,358,1,358,1,358,1,358,1,358,
	1,358,1,358,1,359,1,359,1,359,1,359,1,359,1,359,1,359,1,360,1,360,1,360,
	1,360,1,360,1,360,1,361,1,361,1,361,1,361,1,361,1,361,1,361,1,361,1,361,
	1,361,1,361,1,362,1,362,1,362,1,362,1,362,1,362,1,362,1,362,1,362,1,363,
	1,363,1,363,1,363,1,363,1,364,1,364,1,364,1,364,1,364,1,364,1,364,1,364,
	1,365,1,365,1,365,1,365,1,365,1,365,1,365,1,365,1,365,1,365,1,365,1,365,
	1,365,1,365,1,366,1,366,1,366,1,366,1,366,1,366,1,366,1,366,1,366,1,366,
	1,366,1,366,1,367,1,367,1,367,1,367,1,367,1,367,1,367,1,367,1,368,1,368,
	1,368,1,368,1,368,1,368,1,368,1,369,1,369,1,369,1,369,1,369,1,369,1,369,
	1,369,1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,370,
	1,371,1,371,1,371,1,371,1,371,1,371,1,371,1,371,1,371,1,371,1,371,1,372,
	1,372,1,372,1,372,1,372,1,372,1,372,1,372,1,372,1,372,1,372,1,372,1,373,
	1,373,1,373,1,373,1,373,1,373,1,373,1,373,1,373,1,373,1,373,1,374,1,374,
	1,374,1,374,1,374,1,374,1,374,1,374,1,375,1,375,1,375,1,375,1,375,1,375,
	1,375,1,375,1,375,1,375,1,375,1,376,1,376,1,376,1,376,1,376,1,376,1,376,
	1,376,1,376,1,376,1,376,1,377,1,377,1,377,1,377,1,377,1,377,1,377,1,377,
	1,377,1,377,1,377,1,377,1,377,1,377,1,377,1,377,1,377,1,377,1,377,1,378,
	1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,
	1,378,1,378,1,378,1,378,1,378,1,379,1,379,1,379,1,379,1,379,1,379,1,379,
	1,379,1,379,1,379,1,379,1,379,1,379,1,379,1,379,1,379,1,380,1,380,1,380,
	1,380,1,380,1,380,1,380,1,380,1,380,1,381,1,381,1,381,1,381,1,381,1,381,
	1,381,1,381,1,382,1,382,1,382,1,382,1,382,1,382,1,382,1,382,1,382,1,382,
	1,382,1,382,1,382,1,383,1,383,1,383,1,383,1,383,1,384,1,384,1,384,1,384,
	1,385,1,385,1,385,1,385,1,385,1,385,1,386,1,386,1,386,1,386,1,386,1,386,
	1,386,1,386,1,386,1,386,1,386,1,386,1,387,1,387,1,387,1,387,1,387,1,388,
	1,388,1,388,1,388,1,388,1,388,1,388,1,388,1,388,1,389,1,389,1,389,1,389,
	1,389,1,389,1,389,1,389,1,389,1,389,1,389,1,390,1,390,1,390,1,390,1,390,
	1,390,1,390,1,390,1,390,1,390,1,390,1,390,1,390,1,391,1,391,1,391,1,391,
	1,391,1,391,1,391,1,391,1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,392,
	1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,393,1,393,1,393,1,393,
	1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,394,1,394,1,394,
	1,394,1,394,1,394,1,394,1,394,1,394,1,394,1,395,1,395,1,395,1,395,1,395,
	1,395,1,395,1,395,1,396,1,396,1,396,1,396,1,396,1,396,1,396,1,396,1,397,
	1,397,1,397,1,397,1,397,1,398,1,398,1,398,1,399,1,399,1,399,1,399,1,399,
	1,399,1,399,1,399,1,399,1,400,1,400,1,400,1,400,1,400,1,400,1,400,1,400,
	1,400,1,400,1,401,1,401,1,401,1,401,1,401,1,401,1,401,1,401,1,402,1,402,
	1,402,1,402,1,402,1,402,1,402,1,403,1,403,1,403,1,403,1,403,1,403,1,403,
	1,403,1,403,1,403,1,404,1,404,1,404,1,404,1,404,1,404,1,404,1,404,1,404,
	1,404,1,404,1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,405,
	1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,406,1,406,1,406,1,406,
	1,407,1,407,1,407,1,407,1,407,1,408,1,408,1,408,1,408,1,408,1,408,1,408,
	1,409,1,409,1,409,1,409,1,409,1,409,1,409,1,409,1,410,1,410,1,410,1,410,
	1,410,1,410,1,411,1,411,1,411,1,411,1,411,1,411,1,411,1,412,1,412,1,412,
	1,412,1,412,1,412,1,412,1,413,1,413,1,413,1,413,1,413,1,414,1,414,1,414,
	1,414,1,414,1,414,1,415,1,415,1,415,1,415,1,415,1,415,1,415,1,416,1,416,
	1,416,1,416,1,416,1,416,1,417,1,417,1,417,1,417,1,417,1,417,1,417,1,417,
	1,417,1,418,1,418,1,418,1,418,1,418,1,418,1,418,1,418,1,418,1,418,1,419,
	1,419,1,419,1,419,1,419,1,419,1,419,1,420,1,420,1,420,1,420,1,420,1,420,
	1,420,1,421,1,421,1,421,1,421,1,421,1,421,1,421,1,421,1,421,1,422,1,422,
	1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,423,1,423,
	1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,
	1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,424,1,424,1,424,1,424,
	1,424,1,425,1,425,1,425,1,425,1,425,1,425,1,425,1,426,1,426,1,426,1,426,
	1,426,1,426,1,426,1,427,1,427,1,427,1,427,1,427,1,427,1,427,1,427,1,427,
	1,427,1,427,1,427,1,427,1,427,1,427,1,427,1,428,1,428,1,428,1,428,1,428,
	1,428,1,428,1,429,1,429,1,429,1,429,1,429,1,429,1,430,1,430,1,430,1,430,
	1,430,1,430,1,431,1,431,1,431,1,431,1,431,1,431,1,432,1,432,1,432,1,432,
	1,432,1,432,1,432,1,432,1,432,1,432,1,433,1,433,1,433,1,433,1,433,1,433,
	1,433,1,433,1,434,1,434,1,434,1,434,1,434,1,434,1,435,1,435,1,435,1,435,
	1,435,1,436,1,436,1,436,1,436,1,436,1,436,1,436,1,436,1,436,1,437,1,437,
	1,437,1,437,1,437,1,437,1,437,1,437,1,438,1,438,1,438,1,438,1,438,1,438,
	1,438,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,440,1,440,1,440,1,440,
	1,440,1,440,1,440,1,440,1,440,1,440,1,440,1,440,1,440,1,440,1,440,1,440,
	1,440,1,440,1,441,1,441,1,441,1,441,1,441,1,441,1,441,1,441,1,442,1,442,
	1,442,1,442,1,442,1,443,1,443,1,443,1,443,1,443,1,444,1,444,1,444,1,444,
	1,444,1,444,1,444,1,444,1,445,1,445,1,445,1,445,1,445,1,446,1,446,1,446,
	1,446,1,446,1,446,1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,447,
	1,447,1,447,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,
	1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,449,1,449,1,449,1,449,
	1,449,1,449,1,449,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,
	1,450,1,451,1,451,1,451,1,451,1,451,1,451,1,451,1,451,1,452,1,452,1,452,
	1,452,1,452,1,452,1,452,1,452,1,452,1,452,1,452,1,452,1,452,1,453,1,453,
	1,453,1,453,1,453,1,453,1,453,1,453,1,454,1,454,1,454,1,454,1,454,1,454,
	1,454,1,454,1,454,1,454,1,454,1,454,1,454,1,454,1,455,1,455,1,455,1,455,
	1,455,1,455,1,455,1,455,1,456,1,456,1,456,1,456,1,456,1,456,1,456,1,456,
	1,456,1,457,1,457,1,457,1,457,1,457,1,457,1,457,1,457,1,458,1,458,1,458,
	1,458,1,458,1,458,1,458,1,458,1,458,1,458,1,459,1,459,1,459,1,459,1,459,
	1,459,1,459,1,459,1,460,1,460,1,460,1,461,1,461,1,461,1,461,1,461,1,461,
	1,461,1,461,1,461,1,461,1,462,1,462,1,462,1,462,1,463,1,463,1,463,1,463,
	1,463,1,463,1,463,1,463,1,463,1,463,1,464,1,464,1,464,1,464,1,464,1,464,
	1,464,1,465,1,465,1,465,1,465,1,465,1,466,1,466,1,466,1,466,1,466,1,466,
	1,466,1,466,1,466,1,466,1,466,1,466,1,466,1,466,1,466,1,467,1,467,1,467,
	1,467,1,467,1,467,1,467,1,467,1,467,1,468,1,468,1,468,1,468,1,468,1,469,
	1,469,1,469,1,469,1,469,1,469,1,469,1,470,1,470,1,470,1,470,1,470,1,471,
	1,471,1,471,1,471,1,471,1,471,1,472,1,472,1,472,1,472,1,472,1,473,1,473,
	1,473,1,473,1,473,1,473,1,474,1,474,1,474,1,474,1,474,1,474,1,474,1,474,
	1,475,1,475,1,475,1,475,1,475,1,476,1,476,1,476,1,476,1,476,1,476,1,476,
	1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,
	1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,477,1,478,1,478,1,478,
	1,478,1,478,1,478,1,478,1,478,1,478,1,478,1,478,1,478,1,478,1,478,1,478,
	1,478,1,478,1,478,1,478,1,478,1,478,1,479,1,479,1,479,1,479,1,479,1,479,
	1,479,1,479,1,479,1,479,1,479,1,479,1,479,1,480,1,480,1,480,1,480,1,480,
	1,480,1,480,1,480,1,480,1,480,1,480,1,480,1,480,1,480,1,480,1,480,1,480,
	1,480,1,480,1,480,1,480,1,480,1,480,1,480,1,481,1,481,1,481,1,481,1,481,
	1,481,1,481,1,481,1,481,1,481,1,481,1,481,1,482,1,482,1,482,1,482,1,482,
	1,482,1,482,1,482,1,482,1,482,1,482,1,482,1,482,1,482,1,482,1,482,1,483,
	1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,
	1,483,1,483,1,484,1,484,1,484,1,484,1,484,1,484,1,484,1,484,1,484,1,484,
	1,484,1,484,1,484,1,484,1,484,1,484,1,485,1,485,1,485,1,485,1,485,1,485,
	1,485,1,485,1,485,1,485,1,485,1,485,1,486,1,486,1,486,1,486,1,486,1,486,
	1,486,1,486,1,486,1,486,1,486,1,486,1,486,1,486,1,486,1,486,1,486,1,486,
	1,486,1,487,1,487,1,487,1,487,1,487,1,487,1,487,1,487,1,487,1,487,1,487,
	1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,
	1,488,1,488,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,
	1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,490,1,490,1,490,1,490,
	1,490,1,490,1,490,1,490,1,490,1,490,1,490,1,490,1,490,1,490,1,490,1,490,
	1,491,1,491,1,491,1,491,1,491,1,491,1,491,1,491,1,491,1,491,1,491,1,491,
	1,491,1,491,1,491,1,491,1,491,1,491,1,492,1,492,1,492,1,492,1,492,1,492,
	1,492,1,492,1,492,1,492,1,492,1,492,1,492,1,492,1,492,1,493,1,493,1,493,
	1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,
	1,493,1,493,1,493,1,493,1,494,1,494,1,494,1,494,1,494,1,494,1,494,1,494,
	1,494,1,494,1,494,1,494,1,494,1,494,1,494,1,495,1,495,1,495,1,495,1,495,
	1,495,1,495,1,495,1,495,1,495,1,495,1,495,1,495,1,495,1,495,1,495,1,495,
	1,495,1,495,1,496,1,496,1,496,1,496,1,496,1,496,1,496,1,496,1,496,1,496,
	1,496,1,496,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,
	1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,1,497,
	1,497,1,497,1,497,1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,
	1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,1,498,
	1,499,1,499,1,499,1,499,1,499,1,499,1,499,1,499,1,499,1,500,1,500,1,500,
	1,500,1,500,1,500,1,500,1,500,1,500,1,501,1,501,1,501,1,501,1,501,1,501,
	1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,501,
	1,501,1,501,1,501,1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,
	1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,1,502,
	1,503,1,503,1,503,1,503,1,503,1,503,1,503,1,504,1,504,1,504,1,504,1,504,
	1,504,1,504,1,505,1,505,1,505,1,505,1,505,1,505,1,506,1,506,1,506,1,506,
	1,506,1,506,1,506,1,506,1,506,1,506,1,506,1,506,1,506,1,507,1,507,1,507,
	1,507,1,508,1,508,1,508,1,508,1,508,1,508,1,508,1,508,1,509,1,509,1,509,
	1,509,1,509,1,509,1,509,1,509,1,509,1,510,1,510,1,510,1,510,1,510,1,511,
	1,511,1,511,1,511,1,511,1,511,1,511,1,512,1,512,1,512,1,512,1,512,1,512,
	1,513,1,513,1,513,1,513,1,513,1,513,1,514,1,514,1,514,1,514,1,514,1,514,
	1,514,1,514,1,514,1,514,1,514,1,514,1,515,1,515,1,515,1,515,1,515,1,516,
	1,516,1,516,1,516,1,516,1,516,1,517,1,517,1,517,1,517,1,517,1,517,1,518,
	1,518,1,518,1,518,1,518,1,518,1,519,1,519,1,519,1,519,1,519,1,520,1,520,
	1,520,1,521,1,521,1,521,1,521,1,521,1,521,1,521,1,521,1,522,1,522,1,522,
	1,522,1,522,1,522,1,522,1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,
	1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,525,
	1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,526,1,526,
	1,526,1,526,1,526,1,526,1,526,1,527,1,527,1,527,1,527,1,527,1,527,1,527,
	1,527,1,527,1,527,1,528,1,528,1,528,1,528,1,528,1,529,1,529,1,529,1,529,
	1,529,1,530,1,530,1,530,1,530,1,530,1,530,1,530,1,530,1,531,1,531,1,531,
	1,531,1,531,1,531,1,531,1,532,1,532,1,532,1,533,1,533,1,533,1,534,1,534,
	1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,535,
	1,535,1,535,1,535,1,536,1,536,1,536,1,536,1,536,1,536,1,536,1,537,1,537,
	1,537,1,537,1,537,1,538,1,538,1,538,1,538,1,538,1,539,1,539,1,539,1,539,
	1,539,1,539,1,539,1,539,1,539,1,539,1,539,1,539,1,539,1,539,1,539,1,539,
	1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,541,1,541,1,541,1,541,
	1,541,1,541,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,542,
	1,543,1,543,1,543,1,543,1,543,1,544,1,544,1,544,1,544,1,544,1,544,1,544,
	1,544,1,544,1,544,1,544,1,544,1,544,1,544,1,544,1,544,1,545,1,545,1,545,
	1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,545,
	1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,545,1,546,1,546,1,546,1,546,
	1,546,1,546,1,546,1,547,1,547,1,547,1,547,1,547,1,547,1,547,1,547,1,548,
	1,548,1,548,1,548,1,548,1,548,1,548,1,548,1,548,1,548,1,548,1,548,1,548,
	1,549,1,549,1,549,1,549,1,549,1,549,1,549,1,549,1,549,1,549,1,549,1,550,
	1,550,1,550,1,550,1,550,1,550,1,550,1,550,1,550,1,551,1,551,1,551,1,551,
	1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,
	1,551,1,551,1,551,1,552,1,552,1,552,1,552,1,552,1,552,1,553,1,553,1,553,
	1,553,1,553,1,553,1,553,1,554,1,554,1,554,1,554,1,554,1,554,1,554,1,554,
	1,554,1,554,1,554,1,555,1,555,1,555,1,555,1,555,1,555,1,555,1,555,1,556,
	1,556,1,556,1,556,1,556,1,557,1,557,1,557,1,557,1,557,1,557,1,557,1,557,
	1,557,1,558,1,558,1,558,1,558,1,558,1,558,1,558,1,558,1,558,1,558,1,559,
	1,559,1,559,1,559,1,559,1,559,1,559,1,559,1,560,1,560,1,560,1,560,1,560,
	1,560,1,560,1,560,1,560,1,561,1,561,1,561,1,561,1,561,1,562,1,562,1,562,
	1,562,1,562,1,562,1,562,1,562,1,562,1,562,1,562,1,562,1,563,1,563,1,563,
	1,563,1,563,1,563,1,563,1,563,1,564,1,564,1,564,1,564,1,564,1,564,1,564,
	1,564,1,564,1,565,1,565,1,565,1,565,1,565,1,565,1,566,1,566,1,566,1,566,
	1,566,1,566,1,567,1,567,1,567,1,567,1,567,1,567,1,568,1,568,1,568,1,568,
	1,568,1,568,1,568,1,568,1,569,1,569,1,569,1,569,1,569,1,569,1,569,1,569,
	1,570,1,570,1,570,1,570,1,570,1,570,1,570,1,570,1,570,1,570,1,571,1,571,
	1,571,1,571,1,571,1,571,1,571,1,571,1,571,1,571,1,571,1,571,1,571,1,571,
	1,571,1,571,1,571,1,572,1,572,1,572,1,572,1,572,1,572,1,572,1,572,1,572,
	1,572,1,573,1,573,1,573,1,573,1,573,1,573,1,574,1,574,1,574,1,574,1,574,
	1,574,1,574,1,574,1,574,1,574,1,574,1,574,1,574,1,574,1,574,1,575,1,575,
	1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,
	1,576,1,576,1,576,1,576,1,576,1,576,1,576,1,576,1,576,1,577,1,577,1,577,
	1,577,1,577,1,577,1,577,1,578,1,578,1,578,1,578,1,578,1,578,1,578,1,578,
	1,578,1,578,1,578,1,579,1,579,1,579,1,579,1,579,1,579,1,579,1,580,1,580,
	1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,
	1,580,1,580,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,
	1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,582,1,582,1,582,
	1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,582,
	1,582,1,582,1,582,1,582,1,582,1,583,1,583,1,583,1,583,1,583,1,583,1,583,
	1,583,1,583,1,583,1,583,1,583,1,583,1,583,1,583,1,583,1,583,1,583,1,583,
	1,583,1,583,1,583,1,583,1,584,1,584,1,584,1,584,1,584,1,584,1,584,1,584,
	1,584,1,584,1,584,1,584,1,584,1,584,1,584,1,584,1,584,1,584,1,584,1,584,
	1,584,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,
	1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,
	1,585,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,
	1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,
	1,586,1,586,1,586,1,586,1,586,1,587,1,587,1,587,1,587,1,587,1,587,1,587,
	1,587,1,587,1,587,1,587,1,587,1,588,1,588,1,588,1,588,1,588,1,588,1,589,
	1,589,1,589,1,589,1,589,1,589,1,589,1,589,1,590,1,590,1,590,1,590,1,590,
	1,590,1,590,1,591,1,591,1,591,1,591,1,591,1,591,1,591,1,591,1,591,1,591,
	1,591,1,591,1,591,1,591,1,591,1,591,1,591,1,591,1,592,1,592,1,592,1,592,
	1,592,1,592,1,592,1,592,1,592,1,592,1,593,1,593,1,593,1,593,1,593,1,593,
	1,593,1,593,1,594,1,594,1,594,1,594,1,594,1,594,1,595,1,595,1,595,1,595,
	1,595,1,596,1,596,1,596,1,596,1,596,1,596,1,596,1,596,1,596,1,597,1,597,
	1,597,1,597,1,597,1,597,1,597,1,598,1,598,1,598,1,598,1,598,1,598,1,598,
	1,599,1,599,1,599,1,599,1,600,1,600,1,600,1,600,1,600,1,601,1,601,1,601,
	1,601,1,601,1,601,1,601,1,601,1,601,1,601,1,601,1,602,1,602,1,602,1,602,
	1,602,1,602,1,603,1,603,1,603,1,603,1,603,1,603,1,603,1,603,1,603,1,603,
	1,604,1,604,1,604,1,604,1,604,1,604,1,604,1,604,1,604,1,605,1,605,1,605,
	1,605,1,605,1,605,1,605,1,605,1,605,1,606,1,606,1,606,1,606,1,606,1,606,
	1,606,1,606,1,606,1,607,1,607,1,607,1,607,1,607,1,607,1,607,1,608,1,608,
	1,608,1,608,1,608,1,608,1,608,1,608,1,609,1,609,1,609,1,609,1,609,1,609,
	1,610,1,610,1,610,1,610,1,610,1,610,1,610,1,611,1,611,1,611,1,611,1,611,
	1,611,1,611,1,612,1,612,1,612,1,612,1,612,1,612,1,612,1,613,1,613,1,613,
	1,613,1,613,1,613,1,614,1,614,1,614,1,614,1,614,1,615,1,615,1,615,1,615,
	1,615,1,615,1,615,1,615,1,615,1,616,1,616,1,616,1,616,1,616,1,616,1,616,
	1,617,1,617,1,617,1,617,1,617,1,618,1,618,1,618,1,618,1,618,1,618,1,618,
	1,619,1,619,1,619,1,619,1,619,1,619,1,619,1,620,1,620,1,620,1,620,1,620,
	1,620,1,620,1,621,1,621,1,621,1,621,1,621,1,621,1,621,1,621,1,621,1,621,
	1,621,1,621,1,621,1,621,1,621,1,621,1,622,1,622,1,622,1,622,1,622,1,622,
	1,622,1,622,1,622,1,622,1,622,1,622,1,622,1,622,1,622,1,622,1,622,1,622,
	1,622,1,623,1,623,1,623,1,623,1,623,1,623,1,623,1,623,1,623,1,623,1,623,
	1,623,1,623,1,623,1,623,1,623,1,623,1,624,1,624,1,624,1,624,1,624,1,624,
	1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,
	1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,626,1,626,
	1,626,1,626,1,626,1,626,1,626,1,626,1,626,1,626,1,626,1,626,1,626,1,627,
	1,627,1,627,1,627,1,627,1,627,1,627,1,627,1,627,1,627,1,627,1,628,1,628,
	1,628,1,628,1,628,1,628,1,629,1,629,1,629,1,629,1,629,1,629,1,629,1,630,
	1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,630,
	1,630,1,630,1,630,1,630,1,630,1,631,1,631,1,631,1,631,1,631,1,631,1,631,
	1,631,1,631,1,631,1,631,1,631,1,631,1,631,1,631,1,631,1,631,1,632,1,632,
	1,632,1,632,1,632,1,632,1,632,1,632,1,632,1,632,1,632,1,632,1,632,1,632,
	1,632,1,632,1,632,1,632,1,632,1,633,1,633,1,633,1,633,1,633,1,633,1,633,
	1,634,1,634,1,634,1,634,1,634,1,635,1,635,1,635,1,635,1,635,1,635,1,635,
	1,635,1,636,1,636,1,636,1,636,1,636,1,636,1,636,1,637,1,637,1,637,1,637,
	1,637,1,637,1,637,1,638,1,638,1,638,1,638,1,638,1,638,1,638,1,638,1,638,
	1,638,1,638,1,638,1,638,1,638,1,638,1,638,1,639,1,639,1,639,1,639,1,639,
	1,639,1,639,1,639,1,640,1,640,1,640,1,640,1,640,1,640,1,640,1,640,1,640,
	1,640,1,640,1,640,1,640,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,
	1,641,1,641,1,641,1,641,1,641,1,641,1,642,1,642,1,642,1,642,1,642,1,642,
	1,642,1,642,1,643,1,643,1,643,1,643,1,643,1,643,1,644,1,644,1,644,1,644,
	1,644,1,644,1,644,1,644,1,644,1,645,1,645,1,645,1,645,1,645,1,645,1,645,
	1,645,1,645,1,645,1,645,1,646,1,646,1,646,1,646,1,646,1,646,1,646,1,646,
	1,646,1,646,1,646,1,647,1,647,1,647,1,647,1,647,1,647,1,647,1,647,1,647,
	1,647,1,647,1,648,1,648,1,648,1,648,1,648,1,648,1,648,1,648,1,648,1,648,
	1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,650,1,650,
	1,650,1,650,1,650,1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,
	1,651,1,651,1,651,1,652,1,652,1,652,1,652,1,652,1,652,1,652,1,652,1,652,
	1,652,1,652,1,652,1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,
	1,653,1,653,1,653,1,653,1,653,1,654,1,654,1,654,1,654,1,654,1,654,1,654,
	1,654,1,654,1,655,1,655,1,655,1,655,1,655,1,655,1,655,1,655,1,655,1,656,
	1,656,1,656,1,656,1,656,1,656,1,656,1,656,1,656,1,656,1,657,1,657,1,657,
	1,657,1,657,1,657,1,657,1,657,1,657,1,657,1,658,1,658,1,658,1,658,1,658,
	1,658,1,658,1,658,1,658,1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,659,
	1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,660,1,660,1,660,
	1,660,1,660,1,660,1,660,1,660,1,660,1,660,1,661,1,661,1,661,1,661,1,661,
	1,661,1,661,1,661,1,662,1,662,1,662,1,662,1,662,1,662,1,663,1,663,1,663,
	1,663,1,663,1,663,1,663,1,663,1,664,1,664,1,664,1,664,1,664,1,665,1,665,
	1,665,1,665,1,665,1,665,1,665,1,665,1,666,1,666,1,666,1,666,1,666,1,666,
	1,666,1,666,1,666,1,666,1,666,1,666,1,666,1,666,1,666,1,667,1,667,1,667,
	1,667,1,667,1,667,1,667,1,667,1,667,1,667,1,667,1,668,1,668,1,668,1,668,
	1,668,1,668,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,
	1,670,1,670,1,670,1,670,1,670,1,671,1,671,1,671,1,671,1,671,1,671,1,671,
	1,671,1,672,1,672,1,672,1,672,1,672,1,672,1,672,1,672,1,673,1,673,1,673,
	1,673,1,673,1,674,1,674,1,674,1,674,1,674,1,674,1,674,1,674,1,674,1,675,
	1,675,1,675,1,675,1,675,1,675,1,675,1,676,1,676,1,676,1,676,1,676,1,676,
	1,676,1,676,1,677,1,677,1,677,1,677,1,677,1,678,1,678,1,678,1,678,1,678,
	1,678,1,678,1,678,1,679,1,679,1,679,1,679,1,679,1,680,1,680,1,680,1,681,
	1,681,1,681,1,681,1,682,1,682,1,682,1,682,1,683,1,683,1,683,1,683,1,684,
	1,684,1,684,1,684,1,685,1,685,1,685,1,685,1,686,1,686,1,686,1,686,1,687,
	1,687,1,687,1,687,1,687,1,687,1,687,1,687,1,687,1,688,1,688,1,688,1,688,
	1,688,1,688,1,688,1,688,1,689,1,689,1,689,1,689,1,689,1,689,1,690,1,690,
	1,690,1,690,1,691,1,691,1,691,1,691,1,691,1,692,1,692,1,692,1,692,1,692,
	1,692,1,692,1,693,1,693,1,693,1,693,1,693,1,694,1,694,1,694,1,694,1,694,
	1,694,1,694,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,
	1,695,1,695,1,696,1,696,1,696,1,696,1,696,1,696,1,697,1,697,1,697,1,697,
	1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,
	1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,697,1,698,
	1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,698,
	1,698,1,698,1,698,1,698,1,698,1,698,1,699,1,699,1,699,1,699,1,699,1,699,
	1,699,1,699,1,699,1,699,1,699,1,699,1,700,1,700,1,700,1,700,1,700,1,700,
	1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,
	1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,701,1,701,
	1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,702,
	1,702,1,702,1,702,1,702,1,702,1,702,1,702,1,702,1,702,1,702,1,702,1,702,
	1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,
	1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,
	1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,
	1,705,1,705,1,705,1,705,1,705,1,705,1,705,1,705,1,705,1,705,1,705,1,705,
	1,705,1,705,1,705,1,705,1,705,1,706,1,706,1,706,1,706,1,706,1,706,1,706,
	1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,
	1,706,1,706,1,707,1,707,1,707,1,707,1,707,1,707,1,707,1,707,1,708,1,708,
	1,708,1,708,1,708,1,709,1,709,1,709,1,709,1,709,1,709,1,709,1,709,1,709,
	1,709,1,709,1,709,1,709,1,709,1,709,1,710,1,710,1,710,1,710,1,710,1,710,
	1,710,1,710,1,710,1,710,1,710,1,710,1,710,1,710,1,710,1,710,1,711,1,711,
	1,711,1,711,1,711,1,711,1,711,1,711,1,711,1,711,1,711,1,711,1,711,1,711,
	1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,
	1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,712,1,713,1,713,
	1,713,1,713,1,713,1,713,1,713,1,713,1,713,1,713,1,713,1,713,1,713,1,714,
	1,714,1,714,1,714,1,714,1,714,1,714,1,714,1,714,1,714,1,714,1,714,1,714,
	1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,
	1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,715,1,716,1,716,1,716,
	1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,
	1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,717,1,717,1,717,
	1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,
	1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,718,1,718,1,718,
	1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,
	1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,719,1,719,1,719,1,719,
	1,719,1,719,1,719,1,720,1,720,1,720,1,720,1,720,1,720,1,720,1,721,1,721,
	1,721,1,721,1,721,1,721,1,721,1,721,1,721,1,721,1,721,1,721,1,721,1,721,
	1,721,1,721,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,
	1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,1,722,
	1,722,1,722,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,
	1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,1,723,
	1,723,1,723,1,723,1,723,1,723,1,724,1,724,1,724,1,724,1,724,1,724,1,724,
	1,724,1,724,1,724,1,724,1,725,1,725,1,725,1,725,1,725,1,725,1,725,1,725,
	1,726,1,726,1,726,1,726,1,726,1,726,1,726,1,727,1,727,1,727,1,727,1,727,
	1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,727,
	1,727,1,727,1,727,1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,
	1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,1,728,
	1,728,1,728,1,728,1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,
	1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,729,
	1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,730,
	1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,730,1,731,1,731,1,731,1,731,
	1,731,1,731,1,731,1,731,1,731,1,731,1,731,1,732,1,732,1,732,1,732,1,732,
	1,732,1,732,1,732,1,733,1,733,1,733,1,734,1,734,1,734,1,734,1,734,1,734,
	1,734,1,734,1,734,1,734,1,734,1,734,1,734,1,734,1,734,1,734,1,734,1,734,
	1,734,1,734,1,734,1,734,1,734,1,734,1,734,1,735,3,735,9262,8,735,1,735,
	1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,
	1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,1,735,
	3,735,9289,8,735,1,736,1,736,1,736,1,736,1,736,1,736,1,736,1,736,1,736,
	1,736,1,736,1,736,1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,
	1,737,1,737,1,737,1,737,1,738,1,738,1,738,1,738,1,738,1,738,1,738,1,738,
	1,738,1,739,1,739,1,739,1,739,1,739,1,739,1,740,1,740,1,740,1,740,1,740,
	1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,
	1,740,1,740,1,740,1,740,1,740,1,740,1,741,1,741,1,741,1,741,1,741,1,741,
	1,741,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,
	1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,
	1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,743,
	1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,743,1,744,1,744,1,744,1,744,
	1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,
	1,744,1,745,1,745,1,745,1,745,1,745,1,745,1,745,1,745,1,745,1,746,1,746,
	1,746,1,746,1,746,1,746,1,747,1,747,1,747,1,747,1,747,1,748,1,748,1,748,
	1,748,1,748,1,748,1,748,1,749,1,749,1,749,1,749,1,749,1,749,1,749,1,750,
	1,750,1,750,1,750,1,750,1,750,1,750,1,751,1,751,1,751,1,751,1,751,1,751,
	1,751,1,752,1,752,1,752,1,752,1,752,1,752,1,753,1,753,1,753,1,753,1,753,
	1,753,1,754,1,754,1,754,1,754,1,754,1,754,1,755,1,755,1,755,1,755,1,755,
	1,755,1,756,1,756,1,756,1,756,1,756,1,757,1,757,1,757,1,757,1,757,1,757,
	1,757,1,757,1,758,1,758,1,758,1,758,1,758,1,758,1,759,1,759,1,759,1,759,
	1,759,1,759,1,759,1,759,1,760,1,760,1,760,1,760,1,760,1,760,1,760,1,761,
	1,761,1,761,1,761,1,762,1,762,1,762,1,762,1,762,1,762,1,762,1,762,1,763,
	1,763,1,763,1,763,1,763,1,763,1,764,1,764,1,764,1,764,1,764,1,764,1,764,
	1,765,1,765,1,765,1,765,1,766,1,766,1,766,1,766,1,766,1,766,1,766,1,766,
	1,767,1,767,1,767,1,767,1,767,1,767,1,768,1,768,1,768,1,768,1,768,1,768,
	1,769,1,769,1,769,1,769,1,769,1,769,1,769,1,770,1,770,1,770,1,770,1,770,
	1,770,1,770,1,771,1,771,1,771,1,771,1,771,1,771,1,771,1,772,1,772,1,772,
	1,772,1,772,1,772,1,772,1,773,1,773,1,773,1,773,1,773,1,773,1,774,1,774,
	1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,775,1,775,1,775,1,775,1,775,
	1,776,1,776,1,776,1,776,1,776,1,777,1,777,1,777,1,777,1,777,1,777,1,777,
	1,778,1,778,1,778,1,778,1,778,1,779,1,779,1,779,1,779,1,779,1,780,1,780,
	1,780,1,780,1,780,1,780,1,781,1,781,1,781,1,781,1,781,1,781,1,781,1,781,
	1,782,1,782,1,782,1,782,1,782,1,782,1,783,1,783,1,783,1,783,1,783,1,784,
	1,784,1,784,1,784,1,784,1,784,1,784,1,784,1,785,1,785,1,785,1,785,1,785,
	1,785,1,785,1,785,1,786,1,786,1,786,1,786,1,786,1,786,1,786,1,786,1,787,
	1,787,1,787,1,787,1,787,1,787,1,787,1,787,1,787,1,787,1,788,1,788,1,788,
	1,788,1,789,1,789,1,789,1,789,1,789,1,789,1,789,1,789,1,789,1,789,1,790,
	1,790,1,790,1,790,1,790,1,790,1,790,1,791,1,791,1,791,1,791,1,791,1,791,
	1,791,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,792,
	1,793,1,793,1,793,1,793,1,793,1,793,1,793,1,794,1,794,1,794,1,794,1,795,
	1,795,1,795,1,795,1,795,1,795,1,795,1,795,1,795,1,795,1,795,1,796,1,796,
	1,796,1,796,1,796,1,796,1,796,1,796,1,796,1,796,1,796,1,796,1,796,1,796,
	1,796,1,796,1,796,1,796,1,796,1,797,1,797,1,797,1,797,1,797,1,797,1,797,
	1,798,1,798,1,798,1,798,1,798,1,798,1,798,1,798,1,798,1,798,1,798,1,799,
	1,799,1,799,1,799,1,799,1,799,1,799,1,799,1,799,1,799,1,800,1,800,1,800,
	1,800,1,800,1,800,1,800,1,800,1,800,1,800,1,800,1,800,1,801,1,801,1,801,
	1,801,1,801,1,801,1,801,1,801,1,801,1,801,1,801,1,801,1,801,1,802,1,802,
	1,802,1,802,1,802,1,802,1,802,1,802,1,802,1,802,1,802,1,802,1,802,1,802,
	1,802,1,802,1,802,1,802,1,802,1,803,1,803,1,803,1,803,1,803,1,803,1,803,
	1,803,1,803,1,803,1,803,1,803,1,803,1,803,1,803,1,804,1,804,1,804,1,804,
	1,804,1,804,1,804,1,804,1,804,1,805,1,805,1,805,1,805,1,805,1,805,1,805,
	1,805,1,805,1,805,1,805,1,806,1,806,1,806,1,806,1,806,1,806,1,806,1,806,
	1,806,1,806,1,806,1,806,1,806,1,806,1,806,1,806,1,807,1,807,1,807,1,807,
	1,807,1,807,1,807,1,807,1,807,1,807,1,807,1,808,1,808,1,808,1,808,1,808,
	1,808,1,808,1,808,1,808,1,808,1,808,1,808,1,808,1,809,1,809,1,809,1,809,
	1,809,1,809,1,810,1,810,1,810,1,810,1,810,1,810,1,810,1,810,1,811,1,811,
	1,811,1,811,1,812,1,812,1,812,1,812,1,812,1,813,1,813,1,813,1,813,1,813,
	1,813,1,813,1,813,1,814,1,814,1,814,1,814,1,814,1,814,1,814,1,814,1,815,
	1,815,1,815,1,815,1,815,1,815,1,815,1,815,1,815,1,815,1,815,1,815,1,816,
	1,816,1,816,1,816,1,816,1,816,1,816,1,816,1,816,1,816,1,816,1,816,1,817,
	1,817,1,817,1,817,1,817,1,818,1,818,1,818,1,818,1,818,1,818,1,818,1,818,
	1,818,1,819,1,819,1,819,1,819,1,819,1,820,1,820,1,820,1,820,1,820,1,820,
	1,820,1,821,1,821,1,821,1,821,1,821,1,821,1,822,1,822,1,822,1,822,1,822,
	1,822,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,
	1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,824,1,824,1,824,1,824,
	1,824,1,824,1,824,1,824,1,824,1,824,1,824,1,824,1,824,1,824,1,824,1,824,
	1,824,1,824,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,
	1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,826,1,826,1,826,
	1,826,1,826,1,826,1,826,1,826,1,826,1,826,1,826,1,826,1,826,1,826,1,826,
	1,826,1,827,1,827,1,827,1,827,1,827,1,827,1,827,1,827,1,827,1,827,1,827,
	1,827,1,827,1,827,1,827,1,827,1,827,1,827,1,828,1,828,1,828,1,828,1,828,
	1,829,1,829,1,829,1,829,1,829,1,829,1,830,1,830,1,830,1,830,1,830,1,830,
	1,830,1,830,1,830,1,830,1,831,1,831,1,831,1,831,1,832,1,832,1,832,1,832,
	1,832,1,832,1,832,1,832,1,832,1,832,1,833,1,833,1,833,1,833,1,833,1,833,
	1,833,1,833,1,833,1,833,1,833,1,834,1,834,1,834,1,834,1,834,1,834,1,834,
	1,835,1,835,1,835,1,835,1,835,1,835,1,835,1,835,1,835,1,835,1,835,1,835,
	1,835,1,836,1,836,1,836,1,836,1,836,1,837,1,837,1,837,1,837,1,837,1,837,
	1,837,1,837,1,838,1,838,1,838,1,838,1,838,1,838,1,838,1,838,1,838,1,839,
	1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,839,
	1,839,1,839,1,839,1,839,1,840,1,840,1,840,1,840,1,840,1,840,1,840,1,840,
	1,841,1,841,1,841,1,841,1,841,1,841,1,841,1,841,1,841,1,841,1,841,1,841,
	1,842,1,842,1,842,1,842,1,842,1,842,1,842,1,842,1,842,1,842,1,842,1,842,
	1,842,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,844,
	1,844,1,844,1,844,1,844,1,844,1,844,1,844,1,844,1,845,1,845,1,845,1,845,
	1,845,1,845,1,845,1,846,1,846,1,846,1,846,1,846,1,846,1,846,1,846,1,846,
	1,846,1,847,1,847,1,847,1,847,1,847,1,847,1,847,1,847,1,847,1,847,1,847,
	1,847,1,847,1,847,1,848,1,848,1,848,1,848,1,848,1,849,1,849,1,849,1,849,
	1,849,1,849,1,849,1,849,1,849,1,849,1,849,1,850,1,850,1,850,1,850,1,851,
	1,851,1,851,1,851,1,852,1,852,1,852,1,852,1,852,1,852,1,853,1,853,1,853,
	1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,
	1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,
	1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,
	1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,1,854,
	1,854,1,854,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,
	1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,855,1,856,
	1,856,1,856,1,856,1,856,1,856,1,856,1,856,1,856,1,856,1,856,1,856,1,856,
	1,856,1,857,1,857,1,857,1,857,1,857,1,857,1,857,1,857,1,858,1,858,1,858,
	1,858,1,858,1,858,1,858,1,858,1,858,1,859,1,859,1,859,1,859,1,859,1,859,
	1,859,1,859,1,859,1,859,1,859,1,859,1,860,1,860,1,860,1,860,1,860,1,860,
	1,860,1,860,1,861,1,861,1,861,1,861,1,861,1,861,1,861,1,861,1,861,1,861,
	1,861,1,862,1,862,1,862,1,862,1,862,1,862,1,862,1,862,1,862,1,862,1,863,
	1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,864,1,864,1,864,
	1,864,1,864,1,864,1,864,1,865,1,865,1,865,1,865,1,865,1,865,1,865,1,865,
	1,866,1,866,1,866,1,866,1,866,1,866,1,866,1,866,1,866,1,866,1,866,1,866,
	1,867,1,867,1,867,1,867,1,867,1,867,1,867,1,867,1,867,1,867,1,867,1,867,
	1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,869,1,869,
	1,869,1,869,1,869,1,869,1,869,1,869,1,869,1,870,1,870,1,870,1,870,1,871,
	1,871,1,871,1,871,1,871,1,871,1,871,1,872,1,872,1,872,1,872,1,872,1,872,
	1,872,1,872,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,874,
	1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,
	1,874,1,874,1,874,1,874,1,875,1,875,1,875,1,875,1,875,1,875,1,875,1,875,
	1,875,1,876,1,876,1,876,1,876,1,876,1,876,1,876,1,877,1,877,1,877,1,877,
	1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,879,
	1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,
	1,880,1,880,1,880,1,880,1,880,1,880,1,880,1,880,1,880,1,880,1,880,1,880,
	1,880,1,881,1,881,1,881,1,881,1,881,1,881,1,882,1,882,1,882,1,882,1,882,
	1,882,1,882,1,882,1,882,1,882,1,882,1,882,1,883,1,883,1,883,1,883,1,883,
	1,883,1,884,1,884,1,884,1,884,1,884,1,884,1,884,1,885,1,885,1,885,1,885,
	1,885,1,885,1,885,1,885,1,885,1,885,1,885,1,886,1,886,1,886,1,886,1,886,
	1,886,1,886,1,886,1,886,1,886,1,886,1,886,1,887,1,887,1,887,1,887,1,887,
	1,887,1,887,1,887,1,887,1,887,1,888,1,888,1,888,1,888,1,888,1,888,1,888,
	1,888,1,888,1,888,1,888,1,888,1,888,1,888,1,889,1,889,1,889,1,889,1,889,
	1,889,1,889,1,889,1,889,1,889,1,889,1,889,1,889,1,889,1,889,1,889,1,889,
	1,890,1,890,1,890,1,890,1,890,1,890,1,890,1,890,1,890,1,890,1,890,1,890,
	1,890,1,890,1,890,1,890,1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,
	1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,
	1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,892,1,892,1,892,1,892,1,892,
	1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,
	1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,892,1,893,1,893,1,893,
	1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,893,
	1,893,1,893,1,894,1,894,1,894,1,894,1,894,1,894,1,894,1,894,1,894,1,894,
	1,894,1,894,1,894,1,894,1,894,1,894,1,895,1,895,1,895,1,895,1,895,1,895,
	1,895,1,895,1,895,1,895,1,896,1,896,1,896,1,896,1,896,1,896,1,896,1,896,
	1,896,1,896,1,896,1,896,1,896,1,897,1,897,1,897,1,897,1,897,1,897,1,897,
	1,897,1,897,1,897,1,897,1,897,1,897,1,898,1,898,1,898,1,898,1,898,1,898,
	1,898,1,898,1,898,1,898,1,898,1,898,1,899,1,899,1,899,1,899,1,899,1,899,
	1,899,1,899,1,899,1,899,1,899,1,900,1,900,1,900,1,900,1,900,1,900,1,900,
	1,900,1,900,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,902,1,902,
	1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,903,1,903,1,903,1,903,1,903,
	1,903,1,903,1,903,1,903,1,903,1,903,1,903,1,904,1,904,1,904,1,904,1,904,
	1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,905,1,905,1,905,
	1,905,1,906,1,906,1,906,1,906,1,906,1,906,1,906,1,907,1,907,1,907,1,907,
	1,907,1,907,1,907,1,907,1,907,1,907,1,907,1,908,1,908,1,908,1,908,1,908,
	1,908,1,908,1,908,1,908,1,908,1,908,1,909,1,909,1,909,1,909,1,909,1,909,
	1,909,1,909,1,909,1,909,1,910,1,910,1,910,1,910,1,910,1,910,1,910,1,910,
	1,910,1,910,1,911,1,911,1,911,1,911,1,911,1,911,1,912,1,912,1,912,1,912,
	1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,913,1,913,
	1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,914,1,914,1,914,
	1,914,1,914,1,914,1,914,1,914,1,914,1,915,1,915,1,915,1,915,1,915,1,915,
	1,915,1,915,1,916,1,916,1,916,1,916,1,916,1,916,1,916,1,917,1,917,1,917,
	1,917,1,917,1,917,1,917,1,917,1,917,1,918,1,918,1,918,1,918,1,918,1,918,
	1,918,1,918,1,918,1,918,1,918,1,918,1,918,1,919,1,919,1,919,1,919,1,919,
	1,919,1,919,1,919,1,920,1,920,1,920,1,920,1,920,1,920,1,920,1,920,1,920,
	1,920,1,920,1,920,1,920,1,920,1,920,1,921,1,921,1,921,1,921,1,921,1,921,
	1,921,1,921,1,921,1,921,1,921,1,921,1,921,1,921,1,921,1,922,1,922,1,922,
	1,922,1,922,1,922,1,922,1,922,1,923,1,923,1,923,1,923,1,923,1,923,1,923,
	1,923,1,923,1,923,1,923,1,923,1,923,1,924,1,924,1,924,1,924,1,924,1,924,
	1,924,1,924,1,924,1,924,1,924,1,924,1,924,1,924,1,924,1,925,1,925,1,925,
	1,925,1,925,1,925,1,926,1,926,1,926,1,926,1,926,1,926,1,927,1,927,1,927,
	1,927,1,927,1,927,1,927,1,928,1,928,1,928,1,928,1,928,1,928,1,928,1,928,
	1,928,1,928,1,928,1,928,1,928,1,929,1,929,1,929,1,929,1,929,1,929,1,929,
	1,929,1,929,1,929,1,929,1,929,1,930,1,930,1,930,1,930,1,930,1,930,1,930,
	1,930,1,930,1,930,1,930,1,930,1,930,1,930,1,930,1,930,1,930,1,930,1,930,
	1,931,1,931,1,931,1,931,1,931,1,931,1,931,1,931,1,931,1,931,1,931,1,931,
	1,931,1,931,1,931,1,931,1,931,1,931,1,932,1,932,1,932,1,933,1,933,1,933,
	1,933,1,933,1,933,1,933,1,933,1,933,1,933,1,934,1,934,1,934,1,934,1,934,
	1,934,1,934,1,935,1,935,1,935,1,935,1,936,1,936,1,936,1,936,1,936,1,936,
	1,937,1,937,1,937,1,937,1,937,1,938,1,938,1,938,1,938,1,938,1,938,1,939,
	1,939,1,939,1,939,1,939,1,940,1,940,1,940,1,940,1,940,1,940,1,941,1,941,
	1,941,1,941,1,941,1,941,1,941,1,941,1,941,1,942,1,942,1,942,1,942,1,942,
	1,942,1,942,1,942,1,942,1,943,1,943,1,943,1,943,1,943,1,943,1,943,1,943,
	1,943,1,944,1,944,1,944,1,944,1,944,1,944,1,944,1,944,1,944,1,944,1,944,
	1,944,1,944,1,944,1,944,1,944,1,945,1,945,1,945,1,945,1,945,1,945,1,945,
	1,945,1,945,1,945,1,945,1,945,1,946,1,946,1,946,1,946,1,946,1,946,1,946,
	1,946,1,946,1,946,1,946,1,946,1,947,1,947,1,947,1,947,1,947,1,947,1,947,
	1,947,1,947,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,
	1,948,1,948,1,948,1,948,1,949,1,949,1,949,1,949,1,949,1,949,1,949,1,949,
	1,949,1,949,1,949,1,949,1,950,1,950,1,950,1,950,1,950,1,950,1,950,1,950,
	1,950,1,950,1,950,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,
	1,951,1,952,1,952,1,952,1,952,1,953,1,953,1,953,1,953,1,953,1,953,1,953,
	1,953,1,953,1,953,1,953,1,953,1,953,1,953,1,954,1,954,1,954,1,954,1,954,
	1,954,1,954,1,954,1,954,1,954,1,954,1,954,1,954,1,955,1,955,1,955,1,955,
	1,955,1,955,1,955,1,955,1,955,1,955,1,956,1,956,1,956,1,956,1,956,1,956,
	1,956,1,956,1,956,1,956,1,956,1,956,1,956,1,956,1,956,1,957,1,957,1,957,
	1,957,1,957,1,957,1,957,1,957,1,957,1,957,1,957,1,957,1,957,1,957,1,958,
	1,958,1,958,1,958,1,958,1,958,1,958,1,958,1,958,1,958,1,958,1,958,1,958,
	1,958,1,959,1,959,1,959,1,959,1,959,1,959,1,959,1,959,1,959,1,959,1,959,
	1,959,1,959,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,
	1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,960,
	1,960,1,960,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,
	1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,
	1,961,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,
	1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,963,1,963,1,963,1,963,
	1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,
	1,963,1,963,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,
	1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,965,
	1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,
	1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,966,1,966,1,966,1,966,1,966,
	1,966,1,966,1,966,1,966,1,966,1,966,1,967,1,967,1,967,1,967,1,967,1,967,
	1,967,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,
	1,968,1,968,1,968,1,969,1,969,1,969,1,969,1,969,1,969,1,969,1,969,1,969,
	1,969,1,969,1,969,1,969,1,969,1,969,1,969,1,969,1,970,1,970,1,970,1,970,
	1,970,1,970,1,970,1,970,1,970,1,970,1,971,1,971,1,971,1,971,1,972,1,972,
	1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,973,
	1,973,1,973,1,973,1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,
	1,975,1,975,1,975,1,975,1,975,1,975,1,975,1,975,1,975,1,975,1,975,1,976,
	1,976,1,976,1,976,1,976,1,976,1,976,1,976,1,976,1,976,1,976,1,976,1,977,
	1,977,1,977,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,
	1,978,1,978,1,978,1,978,1,979,1,979,1,979,1,979,1,979,1,979,1,979,1,979,
	1,979,1,979,1,979,1,979,1,979,1,980,1,980,1,980,1,980,1,980,1,980,1,980,
	1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,
	1,981,1,982,1,982,1,982,1,982,1,982,1,982,1,982,1,982,1,982,1,982,1,982,
	1,982,1,983,1,983,1,983,1,983,1,983,1,983,1,983,1,983,1,983,1,983,1,983,
	1,983,1,983,1,983,1,983,1,983,1,984,1,984,1,984,1,984,1,984,1,984,1,984,
	1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,985,1,985,1,985,1,985,
	1,986,1,986,1,986,1,986,1,986,1,986,1,987,1,987,1,987,1,987,1,987,1,987,
	1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,989,1,989,1,989,1,989,
	1,989,1,990,1,990,1,990,1,990,1,990,1,990,1,990,1,991,1,991,1,991,1,991,
	1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,992,1,992,1,992,
	1,992,1,992,1,992,1,992,1,992,1,992,1,992,1,992,1,992,1,992,1,993,1,993,
	1,993,1,993,1,993,1,993,1,993,1,993,1,994,1,994,1,994,1,994,1,994,1,994,
	1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,996,1,996,
	1,996,1,996,1,996,1,997,1,997,1,997,1,997,1,997,1,997,1,998,1,998,1,998,
	1,998,1,998,1,998,1,998,1,998,1,998,1,998,1,998,1,998,1,999,1,999,1,999,
	1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,
	1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,
	1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,
	1000,1,1000,1,1000,1,1001,1,1001,1,1001,1,1001,1,1002,1,1002,1,1002,1,1002,
	1,1002,1,1003,1,1003,1,1003,1,1003,1,1003,1,1004,1,1004,1,1004,1,1004,1,
	1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1005,1,1005,1,1005,
	1,1005,1,1005,1,1006,1,1006,1,1006,1,1006,1,1007,1,1007,1,1007,1,1007,1,
	1007,1,1007,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1009,
	1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,
	1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,
	1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1010,1,1010,1,1010,1,1010,1,
	1010,1,1011,1,1011,1,1011,1,1011,1,1011,1,1012,1,1012,1,1012,1,1012,1,1012,
	1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1013,1,1013,1,1013,1,1013,1,
	1013,1,1013,1,1013,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,
	1,1014,1,1014,1,1014,1,1014,1,1015,1,1015,1,1015,1,1015,1,1015,1,1015,1,
	1015,1,1015,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,
	1,1016,1,1016,1,1016,1,1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,
	1017,1,1017,1,1017,1,1018,1,1018,1,1018,1,1018,1,1018,1,1018,1,1018,1,1018,
	1,1018,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,
	1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1021,
	1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,
	1021,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,
	1,1022,1,1022,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,
	1023,1,1023,1,1023,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,
	1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1025,1,1025,1,1025,1,1025,1,
	1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1026,1,1026,
	1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,
	1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,
	1,1027,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,
	1028,1,1028,1,1028,1,1029,1,1029,1,1029,1,1029,1,1029,1,1029,1,1029,1,1029,
	1,1029,1,1029,1,1029,1,1029,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,
	1030,1,1030,1,1030,1,1030,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,
	1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1032,1,
	1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,
	1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1033,1,1033,1,
	1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,
	1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1034,1,1034,1,1034,1,1034,1,
	1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,
	1,1034,1,1034,1,1034,1,1034,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,
	1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,
	1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,
	1035,1,1035,1,1035,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,
	1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,
	1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,
	1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,
	1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1038,
	1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,
	1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1038,1,1039,1,1039,1,1039,
	1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,1,
	1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,
	1,1040,1,1040,1,1040,1,1040,1,1040,1,1041,1,1041,1,1041,1,1041,1,1041,1,
	1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,
	1,1042,1,1042,1,1042,1,1042,1,1042,1,1042,1,1042,1,1042,1,1042,1,1042,1,
	1042,1,1042,1,1042,1,1042,1,1042,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,
	1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,
	1043,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,
	1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1045,1,1045,1,1045,1,1045,1,
	1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1046,
	1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,
	1046,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,
	1,1047,1,1048,1,1048,1,1048,1,1048,1,1048,1,1048,1,1048,1,1048,1,1048,1,
	1048,1,1048,1,1048,1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,
	1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1050,1,1050,1,
	1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,
	1,1050,1,1050,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,
	1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,
	1,1051,1,1051,1,1051,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,
	1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,
	1,1052,1,1052,1,1052,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,
	1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1054,
	1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,
	1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1055,1,1055,1,1055,
	1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,
	1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1056,1,1056,1,1056,1,1056,
	1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1057,1,
	1057,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,
	1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,
	1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1059,1,1059,1,1059,1,1059,
	1,1059,1,1059,1,1059,1,1059,1,1059,1,1059,1,1059,1,1059,1,1059,1,1059,1,
	1059,1,1059,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,
	1,1060,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,
	1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1062,1,1062,1,1062,1,1062,
	1,1062,1,1062,1,1062,1,1062,1,1062,1,1062,1,1062,1,1062,1,1062,1,1062,1,
	1062,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,
	1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1064,1,
	1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,
	1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1065,1,1065,1,1065,1,1065,1,
	1065,1,1065,1,1065,1,1065,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,
	1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1067,1,1067,1,1067,1,
	1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,
	1,1067,1,1067,1,1067,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,
	1068,1,1068,1,1068,1,1068,1,1069,1,1069,1,1069,1,1069,1,1069,1,1069,1,1069,
	1,1069,1,1069,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,
	1070,1,1070,1,1071,1,1071,1,1071,1,1071,1,1071,1,1072,1,1072,1,1072,1,1072,
	1,1072,1,1073,1,1073,1,1073,1,1073,1,1073,1,1073,1,1073,1,1073,1,1074,1,
	1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,
	1,1074,1,1074,1,1074,1,1074,1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,1,
	1075,1,1075,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,
	1,1076,1,1076,1,1076,1,1077,1,1077,1,1077,1,1077,1,1078,1,1078,1,1078,1,
	1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1079,1,1079,1,1079,1,1079,1,1079,
	1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1080,1,1080,1,
	1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,
	1,1080,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,
	1081,1,1081,1,1081,1,1082,1,1082,1,1082,1,1082,1,1082,1,1082,1,1082,1,1082,
	1,1082,1,1082,1,1082,1,1082,1,1083,1,1083,1,1083,1,1083,1,1083,1,1083,1,
	1083,1,1083,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,
	1,1084,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1086,1,
	1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1087,
	1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,
	1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1088,1,1088,
	1,1088,1,1088,1,1088,1,1088,1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,
	1089,1,1089,1,1089,1,1089,1,1089,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,
	1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,
	1090,1,1090,1,1090,1,1090,1,1091,1,1091,1,1091,1,1091,1,1091,1,1091,1,1092,
	1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,
	1092,1,1092,1,1092,1,1092,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,
	1,1093,1,1093,1,1093,1,1094,1,1094,1,1094,1,1094,1,1094,1,1094,1,1095,1,
	1095,1,1095,1,1095,1,1095,1,1096,1,1096,1,1096,1,1096,1,1096,1,1096,1,1096,
	1,1096,1,1096,1,1096,1,1096,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,
	1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,
	1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,
	1098,1,1098,1,1098,1,1098,1,1098,1,1098,1,1098,1,1098,1,1099,1,1099,1,1099,
	1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,
	1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,
	1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,
	1100,1,1100,1,1100,1,1100,1,1100,1,1100,1,1100,1,1100,1,1101,1,1101,1,1101,
	1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,1,1102,1,1102,1,
	1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,
	1,1102,1,1103,1,1103,1,1103,1,1103,1,1103,1,1103,1,1103,1,1104,1,1104,1,
	1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1105,1,1105,1,1106,1,1106,
	1,1107,1,1107,1,1107,1,1108,1,1108,1,1108,1,1109,1,1109,1,1109,1,1110,1,
	1110,1,1110,1,1111,1,1111,1,1111,1,1112,1,1112,1,1112,1,1113,1,1113,1,1113,
	1,1114,1,1114,1,1114,1,1115,1,1115,1,1115,1,1116,1,1116,1,1117,1,1117,1,
	1118,1,1118,1,1119,1,1119,1,1120,1,1120,1,1121,1,1121,1,1121,1,1121,1,1122,
	1,1122,1,1122,1,1122,1,1123,1,1123,1,1124,1,1124,1,1125,1,1125,1,1126,1,
	1126,1,1127,1,1127,1,1128,1,1128,1,1129,1,1129,1,1130,1,1130,1,1131,1,1131,
	1,1132,1,1132,1,1133,1,1133,1,1134,1,1134,1,1135,1,1135,1,1136,1,1136,1,
	1137,1,1137,1,1138,1,1138,1,1139,1,1139,1,1140,1,1140,1,1141,1,1141,1,1142,
	1,1142,1,1143,1,1143,1,1144,1,1144,1,1144,3,1144,13422,8,1144,1,1145,1,
	1145,1,1145,1,1145,1,1146,4,1146,13429,8,1146,11,1146,12,1146,13430,1,1146,
	1,1146,1,1147,1,1147,1,1147,1,1148,1,1148,1,1148,3,1148,13441,8,1148,1,
	1149,4,1149,13444,8,1149,11,1149,12,1149,13445,1,1150,1,1150,1,1150,1,1150,
	1,1150,4,1150,13453,8,1150,11,1150,12,1150,13454,1,1150,1,1150,1,1150,1,
	1150,1,1150,1,1150,4,1150,13463,8,1150,11,1150,12,1150,13464,3,1150,13467,
	8,1150,1,1151,5,1151,13470,8,1151,10,1151,12,1151,13473,9,1151,1,1151,1,
	1151,4,1151,13477,8,1151,11,1151,12,1151,13478,1,1151,4,1151,13482,8,1151,
	11,1151,12,1151,13483,1,1151,1,1151,1,1151,1,1151,5,1151,13490,8,1151,10,
	1151,12,1151,13493,9,1151,1,1151,1,1151,4,1151,13497,8,1151,11,1151,12,
	1151,13498,1,1151,1,1151,1,1151,4,1151,13504,8,1151,11,1151,12,1151,13505,
	1,1151,1,1151,3,1151,13510,8,1151,1,1152,1,1152,1,1152,1,1153,1,1153,1,
	1154,1,1154,1,1154,1,1155,1,1155,1,1155,1,1156,1,1156,1,1157,1,1157,1,1158,
	1,1158,1,1158,1,1159,1,1159,1,1159,4,1159,13533,8,1159,11,1159,12,1159,
	13534,3,1159,13537,8,1159,1,1160,1,1160,1,1160,4,1160,13542,8,1160,11,1160,
	12,1160,13543,1,1160,3,1160,13547,8,1160,1,1161,1,1161,1,1161,1,1161,1,
	1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,
	1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,
	1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,1,1161,
	1,1161,1,1161,1,1161,1,1161,1,1161,3,1161,13590,8,1161,1,1162,1,1162,3,
	1162,13594,8,1162,1,1162,4,1162,13597,8,1162,11,1162,12,1162,13598,1,1163,
	5,1163,13602,8,1163,10,1163,12,1163,13605,9,1163,1,1163,4,1163,13608,8,
	1163,11,1163,12,1163,13609,1,1163,5,1163,13613,8,1163,10,1163,12,1163,13616,
	9,1163,1,1164,1,1164,1,1164,1,1164,1,1164,1,1164,5,1164,13624,8,1164,10,
	1164,12,1164,13627,9,1164,1,1164,1,1164,1,1165,1,1165,1,1165,1,1165,1,1165,
	1,1165,5,1165,13637,8,1165,10,1165,12,1165,13640,9,1165,1,1165,1,1165,1,
	1166,1,1166,1,1166,1,1166,5,1166,13648,8,1166,10,1166,12,1166,13651,9,1166,
	1,1166,1,1166,1,1167,1,1167,1,1168,1,1168,1,1169,1,1169,1,1169,4,1169,13662,
	8,1169,11,1169,12,1169,13663,1,1169,1,1169,1,1170,4,1170,13669,8,1170,11,
	1170,12,1170,13670,1,1170,1,1170,4,1170,13675,8,1170,11,1170,12,1170,13676,
	1,1170,5,1170,13680,8,1170,10,1170,12,1170,13683,9,1170,1,1170,1,1170,5,
	1170,13687,8,1170,10,1170,12,1170,13690,9,1170,1,1170,1,1170,4,1170,13694,
	8,1170,11,1170,12,1170,13695,3,1170,13698,8,1170,1,1171,1,1171,1,1171,1,
	1171,4,2359,2372,13603,13609,0,1172,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,
	17,9,19,10,21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,39,20,
	41,21,43,22,45,23,47,24,49,25,51,26,53,27,55,28,57,29,59,30,61,31,63,32,
	65,33,67,34,69,35,71,36,73,37,75,38,77,39,79,40,81,41,83,42,85,43,87,44,
	89,45,91,46,93,47,95,48,97,49,99,50,101,51,103,52,105,53,107,54,109,55,
	111,56,113,57,115,58,117,59,119,60,121,61,123,62,125,63,127,64,129,65,131,
	66,133,67,135,68,137,69,139,70,141,71,143,72,145,73,147,74,149,75,151,76,
	153,77,155,78,157,79,159,80,161,81,163,82,165,83,167,84,169,85,171,86,173,
	87,175,88,177,89,179,90,181,91,183,92,185,93,187,94,189,95,191,96,193,97,
	195,98,197,99,199,100,201,101,203,102,205,103,207,104,209,105,211,106,213,
	107,215,108,217,109,219,110,221,111,223,112,225,113,227,114,229,115,231,
	116,233,117,235,118,237,119,239,120,241,121,243,122,245,123,247,124,249,
	125,251,126,253,127,255,128,257,129,259,130,261,131,263,132,265,133,267,
	134,269,135,271,136,273,137,275,138,277,139,279,140,281,141,283,142,285,
	143,287,144,289,145,291,146,293,147,295,148,297,149,299,150,301,151,303,
	152,305,153,307,154,309,155,311,156,313,157,315,158,317,159,319,160,321,
	161,323,162,325,163,327,164,329,165,331,166,333,167,335,168,337,169,339,
	170,341,171,343,172,345,173,347,174,349,175,351,176,353,177,355,178,357,
	179,359,180,361,181,363,182,365,183,367,184,369,185,371,186,373,187,375,
	188,377,189,379,190,381,191,383,192,385,193,387,194,389,195,391,196,393,
	197,395,198,397,199,399,200,401,201,403,202,405,203,407,204,409,205,411,
	206,413,207,415,208,417,209,419,210,421,211,423,212,425,213,427,214,429,
	215,431,216,433,217,435,218,437,219,439,220,441,221,443,222,445,223,447,
	224,449,225,451,226,453,227,455,228,457,229,459,230,461,231,463,232,465,
	233,467,234,469,235,471,236,473,237,475,238,477,239,479,240,481,241,483,
	242,485,243,487,244,489,245,491,246,493,247,495,248,497,249,499,250,501,
	251,503,252,505,253,507,254,509,255,511,256,513,257,515,258,517,259,519,
	260,521,261,523,262,525,263,527,264,529,265,531,266,533,267,535,268,537,
	269,539,270,541,271,543,272,545,273,547,274,549,275,551,276,553,277,555,
	278,557,279,559,280,561,281,563,282,565,283,567,284,569,285,571,286,573,
	287,575,288,577,289,579,290,581,291,583,292,585,293,587,294,589,295,591,
	296,593,297,595,298,597,299,599,300,601,301,603,302,605,303,607,304,609,
	305,611,306,613,307,615,308,617,309,619,310,621,311,623,312,625,313,627,
	314,629,315,631,316,633,317,635,318,637,319,639,320,641,321,643,322,645,
	323,647,324,649,325,651,326,653,327,655,328,657,329,659,330,661,331,663,
	332,665,333,667,334,669,335,671,336,673,337,675,338,677,339,679,340,681,
	341,683,342,685,343,687,344,689,345,691,346,693,347,695,348,697,349,699,
	350,701,351,703,352,705,353,707,354,709,355,711,356,713,357,715,358,717,
	359,719,360,721,361,723,362,725,363,727,364,729,365,731,366,733,367,735,
	368,737,369,739,370,741,371,743,372,745,373,747,374,749,375,751,376,753,
	377,755,378,757,379,759,380,761,381,763,382,765,383,767,384,769,385,771,
	386,773,387,775,388,777,389,779,390,781,391,783,392,785,393,787,394,789,
	395,791,396,793,397,795,398,797,399,799,400,801,401,803,402,805,403,807,
	404,809,405,811,406,813,407,815,408,817,409,819,410,821,411,823,412,825,
	413,827,414,829,415,831,416,833,417,835,418,837,419,839,420,841,421,843,
	422,845,423,847,424,849,425,851,426,853,427,855,428,857,429,859,430,861,
	431,863,432,865,433,867,434,869,435,871,436,873,437,875,438,877,439,879,
	440,881,441,883,442,885,443,887,444,889,445,891,446,893,447,895,448,897,
	449,899,450,901,451,903,452,905,453,907,454,909,455,911,456,913,457,915,
	458,917,459,919,460,921,461,923,462,925,463,927,464,929,465,931,466,933,
	467,935,468,937,469,939,470,941,471,943,472,945,473,947,474,949,475,951,
	476,953,477,955,478,957,479,959,480,961,481,963,482,965,483,967,484,969,
	485,971,486,973,487,975,488,977,489,979,490,981,491,983,492,985,493,987,
	494,989,495,991,496,993,497,995,498,997,499,999,500,1001,501,1003,502,1005,
	503,1007,504,1009,505,1011,506,1013,507,1015,508,1017,509,1019,510,1021,
	511,1023,512,1025,513,1027,514,1029,515,1031,516,1033,517,1035,518,1037,
	519,1039,520,1041,521,1043,522,1045,523,1047,524,1049,525,1051,526,1053,
	527,1055,528,1057,529,1059,530,1061,531,1063,532,1065,533,1067,534,1069,
	535,1071,536,1073,537,1075,538,1077,539,1079,540,1081,541,1083,542,1085,
	543,1087,544,1089,545,1091,546,1093,547,1095,548,1097,549,1099,550,1101,
	551,1103,552,1105,553,1107,554,1109,555,1111,556,1113,557,1115,558,1117,
	559,1119,560,1121,561,1123,562,1125,563,1127,564,1129,565,1131,566,1133,
	567,1135,568,1137,569,1139,570,1141,571,1143,572,1145,573,1147,574,1149,
	575,1151,576,1153,577,1155,578,1157,579,1159,580,1161,581,1163,582,1165,
	583,1167,584,1169,585,1171,586,1173,587,1175,588,1177,589,1179,590,1181,
	591,1183,592,1185,593,1187,594,1189,595,1191,596,1193,597,1195,598,1197,
	599,1199,600,1201,601,1203,602,1205,603,1207,604,1209,605,1211,606,1213,
	607,1215,608,1217,609,1219,610,1221,611,1223,612,1225,613,1227,614,1229,
	615,1231,616,1233,617,1235,618,1237,619,1239,620,1241,621,1243,622,1245,
	623,1247,624,1249,625,1251,626,1253,627,1255,628,1257,629,1259,630,1261,
	631,1263,632,1265,633,1267,634,1269,635,1271,636,1273,637,1275,638,1277,
	639,1279,640,1281,641,1283,642,1285,643,1287,644,1289,645,1291,646,1293,
	647,1295,648,1297,649,1299,650,1301,651,1303,652,1305,653,1307,654,1309,
	655,1311,656,1313,657,1315,658,1317,659,1319,660,1321,661,1323,662,1325,
	663,1327,664,1329,665,1331,666,1333,667,1335,668,1337,669,1339,670,1341,
	671,1343,672,1345,673,1347,674,1349,675,1351,676,1353,677,1355,678,1357,
	679,1359,680,1361,681,1363,682,1365,683,1367,684,1369,685,1371,686,1373,
	687,1375,688,1377,689,1379,690,1381,691,1383,692,1385,693,1387,694,1389,
	695,1391,696,1393,697,1395,698,1397,699,1399,700,1401,701,1403,702,1405,
	703,1407,704,1409,705,1411,706,1413,707,1415,708,1417,709,1419,710,1421,
	711,1423,712,1425,713,1427,714,1429,715,1431,716,1433,717,1435,718,1437,
	719,1439,720,1441,721,1443,722,1445,723,1447,724,1449,725,1451,726,1453,
	727,1455,728,1457,729,1459,730,1461,731,1463,732,1465,733,1467,734,1469,
	735,1471,736,1473,737,1475,738,1477,739,1479,740,1481,741,1483,742,1485,
	743,1487,744,1489,745,1491,746,1493,747,1495,748,1497,749,1499,750,1501,
	751,1503,752,1505,753,1507,754,1509,755,1511,756,1513,757,1515,758,1517,
	759,1519,760,1521,761,1523,762,1525,763,1527,764,1529,765,1531,766,1533,
	767,1535,768,1537,769,1539,770,1541,771,1543,772,1545,773,1547,774,1549,
	775,1551,776,1553,777,1555,778,1557,779,1559,780,1561,781,1563,782,1565,
	783,1567,784,1569,785,1571,786,1573,787,1575,788,1577,789,1579,790,1581,
	791,1583,792,1585,793,1587,794,1589,795,1591,796,1593,797,1595,798,1597,
	799,1599,800,1601,801,1603,802,1605,803,1607,804,1609,805,1611,806,1613,
	807,1615,808,1617,809,1619,810,1621,811,1623,812,1625,813,1627,814,1629,
	815,1631,816,1633,817,1635,818,1637,819,1639,820,1641,821,1643,822,1645,
	823,1647,824,1649,825,1651,826,1653,827,1655,828,1657,829,1659,830,1661,
	831,1663,832,1665,833,1667,834,1669,835,1671,836,1673,837,1675,838,1677,
	839,1679,840,1681,841,1683,842,1685,843,1687,844,1689,845,1691,846,1693,
	847,1695,848,1697,849,1699,850,1701,851,1703,852,1705,853,1707,854,1709,
	855,1711,856,1713,857,1715,858,1717,859,1719,860,1721,861,1723,862,1725,
	863,1727,864,1729,865,1731,866,1733,867,1735,868,1737,869,1739,870,1741,
	871,1743,872,1745,873,1747,874,1749,875,1751,876,1753,877,1755,878,1757,
	879,1759,880,1761,881,1763,882,1765,883,1767,884,1769,885,1771,886,1773,
	887,1775,888,1777,889,1779,890,1781,891,1783,892,1785,893,1787,894,1789,
	895,1791,896,1793,897,1795,898,1797,899,1799,900,1801,901,1803,902,1805,
	903,1807,904,1809,905,1811,906,1813,907,1815,908,1817,909,1819,910,1821,
	911,1823,912,1825,913,1827,914,1829,915,1831,916,1833,917,1835,918,1837,
	919,1839,920,1841,921,1843,922,1845,923,1847,924,1849,925,1851,926,1853,
	927,1855,928,1857,929,1859,930,1861,931,1863,932,1865,933,1867,934,1869,
	935,1871,936,1873,937,1875,938,1877,939,1879,940,1881,941,1883,942,1885,
	943,1887,944,1889,945,1891,946,1893,947,1895,948,1897,949,1899,950,1901,
	951,1903,952,1905,953,1907,954,1909,955,1911,956,1913,957,1915,958,1917,
	959,1919,960,1921,961,1923,962,1925,963,1927,964,1929,965,1931,966,1933,
	967,1935,968,1937,969,1939,970,1941,971,1943,972,1945,973,1947,974,1949,
	975,1951,976,1953,977,1955,978,1957,979,1959,980,1961,981,1963,982,1965,
	983,1967,984,1969,985,1971,986,1973,987,1975,988,1977,989,1979,990,1981,
	991,1983,992,1985,993,1987,994,1989,995,1991,996,1993,997,1995,998,1997,
	999,1999,1000,2001,1001,2003,1002,2005,1003,2007,1004,2009,1005,2011,1006,
	2013,1007,2015,1008,2017,1009,2019,1010,2021,1011,2023,1012,2025,1013,2027,
	1014,2029,1015,2031,1016,2033,1017,2035,1018,2037,1019,2039,1020,2041,1021,
	2043,1022,2045,1023,2047,1024,2049,1025,2051,1026,2053,1027,2055,1028,2057,
	1029,2059,1030,2061,1031,2063,1032,2065,1033,2067,1034,2069,1035,2071,1036,
	2073,1037,2075,1038,2077,1039,2079,1040,2081,1041,2083,1042,2085,1043,2087,
	1044,2089,1045,2091,1046,2093,1047,2095,1048,2097,1049,2099,1050,2101,1051,
	2103,1052,2105,1053,2107,1054,2109,1055,2111,1056,2113,1057,2115,1058,2117,
	1059,2119,1060,2121,1061,2123,1062,2125,1063,2127,1064,2129,1065,2131,1066,
	2133,1067,2135,1068,2137,1069,2139,1070,2141,1071,2143,1072,2145,1073,2147,
	1074,2149,1075,2151,1076,2153,1077,2155,1078,2157,1079,2159,1080,2161,1081,
	2163,1082,2165,1083,2167,1084,2169,1085,2171,1086,2173,1087,2175,1088,2177,
	1089,2179,1090,2181,1091,2183,1092,2185,1093,2187,1094,2189,1095,2191,1096,
	2193,1097,2195,1098,2197,1099,2199,1100,2201,1101,2203,1102,2205,1103,2207,
	1104,2209,1105,2211,1106,2213,1107,2215,1108,2217,1109,2219,1110,2221,1111,
	2223,1112,2225,1113,2227,1114,2229,1115,2231,1116,2233,1117,2235,1118,2237,
	1119,2239,1120,2241,1121,2243,1122,2245,1123,2247,1124,2249,1125,2251,1126,
	2253,1127,2255,1128,2257,1129,2259,1130,2261,1131,2263,1132,2265,1133,2267,
	1134,2269,1135,2271,1136,2273,1137,2275,1138,2277,1139,2279,1140,2281,1141,
	2283,1142,2285,1143,2287,1144,2289,0,2291,1145,2293,1146,2295,1147,2297,
	1148,2299,1149,2301,1150,2303,1151,2305,1152,2307,1153,2309,1154,2311,1155,
	2313,1156,2315,1157,2317,1158,2319,1159,2321,1160,2323,0,2325,0,2327,0,
	2329,0,2331,0,2333,0,2335,0,2337,0,2339,0,2341,0,2343,1161,1,0,42,3,0,9,
	10,13,13,32,32,2,0,9,9,32,32,2,0,10,10,13,13,2,0,65,65,97,97,2,0,68,68,
	100,100,2,0,76,76,108,108,2,0,84,84,116,116,2,0,69,69,101,101,2,0,82,82,
	114,114,2,0,87,87,119,119,2,0,89,89,121,121,2,0,83,83,115,115,2,0,78,78,
	110,110,2,0,90,90,122,122,2,0,67,67,99,99,2,0,73,73,105,105,2,0,66,66,98,
	98,2,0,85,85,117,117,2,0,70,70,102,102,2,0,79,79,111,111,2,0,72,72,104,
	104,2,0,75,75,107,107,2,0,71,71,103,103,2,0,77,77,109,109,2,0,86,86,118,
	118,2,0,80,80,112,112,2,0,88,88,120,120,2,0,74,74,106,106,2,0,81,81,113,
	113,8,0,71,71,75,75,77,77,84,84,103,103,107,107,109,109,116,116,7,0,36,
	36,46,46,48,57,65,90,95,95,97,122,128,65535,2,0,43,43,45,45,6,0,36,36,48,
	57,65,90,95,95,97,122,128,65535,5,0,36,36,65,90,95,95,97,122,128,65535,
	2,0,34,34,92,92,2,0,39,39,92,92,1,0,96,96,3,0,48,57,65,70,97,102,1,0,48,
	57,1,0,48,49,2,0,46,46,48,57,3,0,48,58,65,70,97,102,13786,0,1,1,0,0,0,0,
	3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,0,
	0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,0,0,0,25,1,
	0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,35,1,0,0,0,
	0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,45,1,0,0,0,0,47,1,
	0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,1,0,0,0,0,57,1,0,0,0,
	0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,69,1,
	0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,0,0,0,
	0,81,1,0,0,0,0,83,1,0,0,0,0,85,1,0,0,0,0,87,1,0,0,0,0,89,1,0,0,0,0,91,1,
	0,0,0,0,93,1,0,0,0,0,95,1,0,0,0,0,97,1,0,0,0,0,99,1,0,0,0,0,101,1,0,0,0,
	0,103,1,0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,109,1,0,0,0,0,111,1,0,0,0,0,
	113,1,0,0,0,0,115,1,0,0,0,0,117,1,0,0,0,0,119,1,0,0,0,0,121,1,0,0,0,0,123,
	1,0,0,0,0,125,1,0,0,0,0,127,1,0,0,0,0,129,1,0,0,0,0,131,1,0,0,0,0,133,1,
	0,0,0,0,135,1,0,0,0,0,137,1,0,0,0,0,139,1,0,0,0,0,141,1,0,0,0,0,143,1,0,
	0,0,0,145,1,0,0,0,0,147,1,0,0,0,0,149,1,0,0,0,0,151,1,0,0,0,0,153,1,0,0,
	0,0,155,1,0,0,0,0,157,1,0,0,0,0,159,1,0,0,0,0,161,1,0,0,0,0,163,1,0,0,0,
	0,165,1,0,0,0,0,167,1,0,0,0,0,169,1,0,0,0,0,171,1,0,0,0,0,173,1,0,0,0,0,
	175,1,0,0,0,0,177,1,0,0,0,0,179,1,0,0,0,0,181,1,0,0,0,0,183,1,0,0,0,0,185,
	1,0,0,0,0,187,1,0,0,0,0,189,1,0,0,0,0,191,1,0,0,0,0,193,1,0,0,0,0,195,1,
	0,0,0,0,197,1,0,0,0,0,199,1,0,0,0,0,201,1,0,0,0,0,203,1,0,0,0,0,205,1,0,
	0,0,0,207,1,0,0,0,0,209,1,0,0,0,0,211,1,0,0,0,0,213,1,0,0,0,0,215,1,0,0,
	0,0,217,1,0,0,0,0,219,1,0,0,0,0,221,1,0,0,0,0,223,1,0,0,0,0,225,1,0,0,0,
	0,227,1,0,0,0,0,229,1,0,0,0,0,231,1,0,0,0,0,233,1,0,0,0,0,235,1,0,0,0,0,
	237,1,0,0,0,0,239,1,0,0,0,0,241,1,0,0,0,0,243,1,0,0,0,0,245,1,0,0,0,0,247,
	1,0,0,0,0,249,1,0,0,0,0,251,1,0,0,0,0,253,1,0,0,0,0,255,1,0,0,0,0,257,1,
	0,0,0,0,259,1,0,0,0,0,261,1,0,0,0,0,263,1,0,0,0,0,265,1,0,0,0,0,267,1,0,
	0,0,0,269,1,0,0,0,0,271,1,0,0,0,0,273,1,0,0,0,0,275,1,0,0,0,0,277,1,0,0,
	0,0,279,1,0,0,0,0,281,1,0,0,0,0,283,1,0,0,0,0,285,1,0,0,0,0,287,1,0,0,0,
	0,289,1,0,0,0,0,291,1,0,0,0,0,293,1,0,0,0,0,295,1,0,0,0,0,297,1,0,0,0,0,
	299,1,0,0,0,0,301,1,0,0,0,0,303,1,0,0,0,0,305,1,0,0,0,0,307,1,0,0,0,0,309,
	1,0,0,0,0,311,1,0,0,0,0,313,1,0,0,0,0,315,1,0,0,0,0,317,1,0,0,0,0,319,1,
	0,0,0,0,321,1,0,0,0,0,323,1,0,0,0,0,325,1,0,0,0,0,327,1,0,0,0,0,329,1,0,
	0,0,0,331,1,0,0,0,0,333,1,0,0,0,0,335,1,0,0,0,0,337,1,0,0,0,0,339,1,0,0,
	0,0,341,1,0,0,0,0,343,1,0,0,0,0,345,1,0,0,0,0,347,1,0,0,0,0,349,1,0,0,0,
	0,351,1,0,0,0,0,353,1,0,0,0,0,355,1,0,0,0,0,357,1,0,0,0,0,359,1,0,0,0,0,
	361,1,0,0,0,0,363,1,0,0,0,0,365,1,0,0,0,0,367,1,0,0,0,0,369,1,0,0,0,0,371,
	1,0,0,0,0,373,1,0,0,0,0,375,1,0,0,0,0,377,1,0,0,0,0,379,1,0,0,0,0,381,1,
	0,0,0,0,383,1,0,0,0,0,385,1,0,0,0,0,387,1,0,0,0,0,389,1,0,0,0,0,391,1,0,
	0,0,0,393,1,0,0,0,0,395,1,0,0,0,0,397,1,0,0,0,0,399,1,0,0,0,0,401,1,0,0,
	0,0,403,1,0,0,0,0,405,1,0,0,0,0,407,1,0,0,0,0,409,1,0,0,0,0,411,1,0,0,0,
	0,413,1,0,0,0,0,415,1,0,0,0,0,417,1,0,0,0,0,419,1,0,0,0,0,421,1,0,0,0,0,
	423,1,0,0,0,0,425,1,0,0,0,0,427,1,0,0,0,0,429,1,0,0,0,0,431,1,0,0,0,0,433,
	1,0,0,0,0,435,1,0,0,0,0,437,1,0,0,0,0,439,1,0,0,0,0,441,1,0,0,0,0,443,1,
	0,0,0,0,445,1,0,0,0,0,447,1,0,0,0,0,449,1,0,0,0,0,451,1,0,0,0,0,453,1,0,
	0,0,0,455,1,0,0,0,0,457,1,0,0,0,0,459,1,0,0,0,0,461,1,0,0,0,0,463,1,0,0,
	0,0,465,1,0,0,0,0,467,1,0,0,0,0,469,1,0,0,0,0,471,1,0,0,0,0,473,1,0,0,0,
	0,475,1,0,0,0,0,477,1,0,0,0,0,479,1,0,0,0,0,481,1,0,0,0,0,483,1,0,0,0,0,
	485,1,0,0,0,0,487,1,0,0,0,0,489,1,0,0,0,0,491,1,0,0,0,0,493,1,0,0,0,0,495,
	1,0,0,0,0,497,1,0,0,0,0,499,1,0,0,0,0,501,1,0,0,0,0,503,1,0,0,0,0,505,1,
	0,0,0,0,507,1,0,0,0,0,509,1,0,0,0,0,511,1,0,0,0,0,513,1,0,0,0,0,515,1,0,
	0,0,0,517,1,0,0,0,0,519,1,0,0,0,0,521,1,0,0,0,0,523,1,0,0,0,0,525,1,0,0,
	0,0,527,1,0,0,0,0,529,1,0,0,0,0,531,1,0,0,0,0,533,1,0,0,0,0,535,1,0,0,0,
	0,537,1,0,0,0,0,539,1,0,0,0,0,541,1,0,0,0,0,543,1,0,0,0,0,545,1,0,0,0,0,
	547,1,0,0,0,0,549,1,0,0,0,0,551,1,0,0,0,0,553,1,0,0,0,0,555,1,0,0,0,0,557,
	1,0,0,0,0,559,1,0,0,0,0,561,1,0,0,0,0,563,1,0,0,0,0,565,1,0,0,0,0,567,1,
	0,0,0,0,569,1,0,0,0,0,571,1,0,0,0,0,573,1,0,0,0,0,575,1,0,0,0,0,577,1,0,
	0,0,0,579,1,0,0,0,0,581,1,0,0,0,0,583,1,0,0,0,0,585,1,0,0,0,0,587,1,0,0,
	0,0,589,1,0,0,0,0,591,1,0,0,0,0,593,1,0,0,0,0,595,1,0,0,0,0,597,1,0,0,0,
	0,599,1,0,0,0,0,601,1,0,0,0,0,603,1,0,0,0,0,605,1,0,0,0,0,607,1,0,0,0,0,
	609,1,0,0,0,0,611,1,0,0,0,0,613,1,0,0,0,0,615,1,0,0,0,0,617,1,0,0,0,0,619,
	1,0,0,0,0,621,1,0,0,0,0,623,1,0,0,0,0,625,1,0,0,0,0,627,1,0,0,0,0,629,1,
	0,0,0,0,631,1,0,0,0,0,633,1,0,0,0,0,635,1,0,0,0,0,637,1,0,0,0,0,639,1,0,
	0,0,0,641,1,0,0,0,0,643,1,0,0,0,0,645,1,0,0,0,0,647,1,0,0,0,0,649,1,0,0,
	0,0,651,1,0,0,0,0,653,1,0,0,0,0,655,1,0,0,0,0,657,1,0,0,0,0,659,1,0,0,0,
	0,661,1,0,0,0,0,663,1,0,0,0,0,665,1,0,0,0,0,667,1,0,0,0,0,669,1,0,0,0,0,
	671,1,0,0,0,0,673,1,0,0,0,0,675,1,0,0,0,0,677,1,0,0,0,0,679,1,0,0,0,0,681,
	1,0,0,0,0,683,1,0,0,0,0,685,1,0,0,0,0,687,1,0,0,0,0,689,1,0,0,0,0,691,1,
	0,0,0,0,693,1,0,0,0,0,695,1,0,0,0,0,697,1,0,0,0,0,699,1,0,0,0,0,701,1,0,
	0,0,0,703,1,0,0,0,0,705,1,0,0,0,0,707,1,0,0,0,0,709,1,0,0,0,0,711,1,0,0,
	0,0,713,1,0,0,0,0,715,1,0,0,0,0,717,1,0,0,0,0,719,1,0,0,0,0,721,1,0,0,0,
	0,723,1,0,0,0,0,725,1,0,0,0,0,727,1,0,0,0,0,729,1,0,0,0,0,731,1,0,0,0,0,
	733,1,0,0,0,0,735,1,0,0,0,0,737,1,0,0,0,0,739,1,0,0,0,0,741,1,0,0,0,0,743,
	1,0,0,0,0,745,1,0,0,0,0,747,1,0,0,0,0,749,1,0,0,0,0,751,1,0,0,0,0,753,1,
	0,0,0,0,755,1,0,0,0,0,757,1,0,0,0,0,759,1,0,0,0,0,761,1,0,0,0,0,763,1,0,
	0,0,0,765,1,0,0,0,0,767,1,0,0,0,0,769,1,0,0,0,0,771,1,0,0,0,0,773,1,0,0,
	0,0,775,1,0,0,0,0,777,1,0,0,0,0,779,1,0,0,0,0,781,1,0,0,0,0,783,1,0,0,0,
	0,785,1,0,0,0,0,787,1,0,0,0,0,789,1,0,0,0,0,791,1,0,0,0,0,793,1,0,0,0,0,
	795,1,0,0,0,0,797,1,0,0,0,0,799,1,0,0,0,0,801,1,0,0,0,0,803,1,0,0,0,0,805,
	1,0,0,0,0,807,1,0,0,0,0,809,1,0,0,0,0,811,1,0,0,0,0,813,1,0,0,0,0,815,1,
	0,0,0,0,817,1,0,0,0,0,819,1,0,0,0,0,821,1,0,0,0,0,823,1,0,0,0,0,825,1,0,
	0,0,0,827,1,0,0,0,0,829,1,0,0,0,0,831,1,0,0,0,0,833,1,0,0,0,0,835,1,0,0,
	0,0,837,1,0,0,0,0,839,1,0,0,0,0,841,1,0,0,0,0,843,1,0,0,0,0,845,1,0,0,0,
	0,847,1,0,0,0,0,849,1,0,0,0,0,851,1,0,0,0,0,853,1,0,0,0,0,855,1,0,0,0,0,
	857,1,0,0,0,0,859,1,0,0,0,0,861,1,0,0,0,0,863,1,0,0,0,0,865,1,0,0,0,0,867,
	1,0,0,0,0,869,1,0,0,0,0,871,1,0,0,0,0,873,1,0,0,0,0,875,1,0,0,0,0,877,1,
	0,0,0,0,879,1,0,0,0,0,881,1,0,0,0,0,883,1,0,0,0,0,885,1,0,0,0,0,887,1,0,
	0,0,0,889,1,0,0,0,0,891,1,0,0,0,0,893,1,0,0,0,0,895,1,0,0,0,0,897,1,0,0,
	0,0,899,1,0,0,0,0,901,1,0,0,0,0,903,1,0,0,0,0,905,1,0,0,0,0,907,1,0,0,0,
	0,909,1,0,0,0,0,911,1,0,0,0,0,913,1,0,0,0,0,915,1,0,0,0,0,917,1,0,0,0,0,
	919,1,0,0,0,0,921,1,0,0,0,0,923,1,0,0,0,0,925,1,0,0,0,0,927,1,0,0,0,0,929,
	1,0,0,0,0,931,1,0,0,0,0,933,1,0,0,0,0,935,1,0,0,0,0,937,1,0,0,0,0,939,1,
	0,0,0,0,941,1,0,0,0,0,943,1,0,0,0,0,945,1,0,0,0,0,947,1,0,0,0,0,949,1,0,
	0,0,0,951,1,0,0,0,0,953,1,0,0,0,0,955,1,0,0,0,0,957,1,0,0,0,0,959,1,0,0,
	0,0,961,1,0,0,0,0,963,1,0,0,0,0,965,1,0,0,0,0,967,1,0,0,0,0,969,1,0,0,0,
	0,971,1,0,0,0,0,973,1,0,0,0,0,975,1,0,0,0,0,977,1,0,0,0,0,979,1,0,0,0,0,
	981,1,0,0,0,0,983,1,0,0,0,0,985,1,0,0,0,0,987,1,0,0,0,0,989,1,0,0,0,0,991,
	1,0,0,0,0,993,1,0,0,0,0,995,1,0,0,0,0,997,1,0,0,0,0,999,1,0,0,0,0,1001,
	1,0,0,0,0,1003,1,0,0,0,0,1005,1,0,0,0,0,1007,1,0,0,0,0,1009,1,0,0,0,0,1011,
	1,0,0,0,0,1013,1,0,0,0,0,1015,1,0,0,0,0,1017,1,0,0,0,0,1019,1,0,0,0,0,1021,
	1,0,0,0,0,1023,1,0,0,0,0,1025,1,0,0,0,0,1027,1,0,0,0,0,1029,1,0,0,0,0,1031,
	1,0,0,0,0,1033,1,0,0,0,0,1035,1,0,0,0,0,1037,1,0,0,0,0,1039,1,0,0,0,0,1041,
	1,0,0,0,0,1043,1,0,0,0,0,1045,1,0,0,0,0,1047,1,0,0,0,0,1049,1,0,0,0,0,1051,
	1,0,0,0,0,1053,1,0,0,0,0,1055,1,0,0,0,0,1057,1,0,0,0,0,1059,1,0,0,0,0,1061,
	1,0,0,0,0,1063,1,0,0,0,0,1065,1,0,0,0,0,1067,1,0,0,0,0,1069,1,0,0,0,0,1071,
	1,0,0,0,0,1073,1,0,0,0,0,1075,1,0,0,0,0,1077,1,0,0,0,0,1079,1,0,0,0,0,1081,
	1,0,0,0,0,1083,1,0,0,0,0,1085,1,0,0,0,0,1087,1,0,0,0,0,1089,1,0,0,0,0,1091,
	1,0,0,0,0,1093,1,0,0,0,0,1095,1,0,0,0,0,1097,1,0,0,0,0,1099,1,0,0,0,0,1101,
	1,0,0,0,0,1103,1,0,0,0,0,1105,1,0,0,0,0,1107,1,0,0,0,0,1109,1,0,0,0,0,1111,
	1,0,0,0,0,1113,1,0,0,0,0,1115,1,0,0,0,0,1117,1,0,0,0,0,1119,1,0,0,0,0,1121,
	1,0,0,0,0,1123,1,0,0,0,0,1125,1,0,0,0,0,1127,1,0,0,0,0,1129,1,0,0,0,0,1131,
	1,0,0,0,0,1133,1,0,0,0,0,1135,1,0,0,0,0,1137,1,0,0,0,0,1139,1,0,0,0,0,1141,
	1,0,0,0,0,1143,1,0,0,0,0,1145,1,0,0,0,0,1147,1,0,0,0,0,1149,1,0,0,0,0,1151,
	1,0,0,0,0,1153,1,0,0,0,0,1155,1,0,0,0,0,1157,1,0,0,0,0,1159,1,0,0,0,0,1161,
	1,0,0,0,0,1163,1,0,0,0,0,1165,1,0,0,0,0,1167,1,0,0,0,0,1169,1,0,0,0,0,1171,
	1,0,0,0,0,1173,1,0,0,0,0,1175,1,0,0,0,0,1177,1,0,0,0,0,1179,1,0,0,0,0,1181,
	1,0,0,0,0,1183,1,0,0,0,0,1185,1,0,0,0,0,1187,1,0,0,0,0,1189,1,0,0,0,0,1191,
	1,0,0,0,0,1193,1,0,0,0,0,1195,1,0,0,0,0,1197,1,0,0,0,0,1199,1,0,0,0,0,1201,
	1,0,0,0,0,1203,1,0,0,0,0,1205,1,0,0,0,0,1207,1,0,0,0,0,1209,1,0,0,0,0,1211,
	1,0,0,0,0,1213,1,0,0,0,0,1215,1,0,0,0,0,1217,1,0,0,0,0,1219,1,0,0,0,0,1221,
	1,0,0,0,0,1223,1,0,0,0,0,1225,1,0,0,0,0,1227,1,0,0,0,0,1229,1,0,0,0,0,1231,
	1,0,0,0,0,1233,1,0,0,0,0,1235,1,0,0,0,0,1237,1,0,0,0,0,1239,1,0,0,0,0,1241,
	1,0,0,0,0,1243,1,0,0,0,0,1245,1,0,0,0,0,1247,1,0,0,0,0,1249,1,0,0,0,0,1251,
	1,0,0,0,0,1253,1,0,0,0,0,1255,1,0,0,0,0,1257,1,0,0,0,0,1259,1,0,0,0,0,1261,
	1,0,0,0,0,1263,1,0,0,0,0,1265,1,0,0,0,0,1267,1,0,0,0,0,1269,1,0,0,0,0,1271,
	1,0,0,0,0,1273,1,0,0,0,0,1275,1,0,0,0,0,1277,1,0,0,0,0,1279,1,0,0,0,0,1281,
	1,0,0,0,0,1283,1,0,0,0,0,1285,1,0,0,0,0,1287,1,0,0,0,0,1289,1,0,0,0,0,1291,
	1,0,0,0,0,1293,1,0,0,0,0,1295,1,0,0,0,0,1297,1,0,0,0,0,1299,1,0,0,0,0,1301,
	1,0,0,0,0,1303,1,0,0,0,0,1305,1,0,0,0,0,1307,1,0,0,0,0,1309,1,0,0,0,0,1311,
	1,0,0,0,0,1313,1,0,0,0,0,1315,1,0,0,0,0,1317,1,0,0,0,0,1319,1,0,0,0,0,1321,
	1,0,0,0,0,1323,1,0,0,0,0,1325,1,0,0,0,0,1327,1,0,0,0,0,1329,1,0,0,0,0,1331,
	1,0,0,0,0,1333,1,0,0,0,0,1335,1,0,0,0,0,1337,1,0,0,0,0,1339,1,0,0,0,0,1341,
	1,0,0,0,0,1343,1,0,0,0,0,1345,1,0,0,0,0,1347,1,0,0,0,0,1349,1,0,0,0,0,1351,
	1,0,0,0,0,1353,1,0,0,0,0,1355,1,0,0,0,0,1357,1,0,0,0,0,1359,1,0,0,0,0,1361,
	1,0,0,0,0,1363,1,0,0,0,0,1365,1,0,0,0,0,1367,1,0,0,0,0,1369,1,0,0,0,0,1371,
	1,0,0,0,0,1373,1,0,0,0,0,1375,1,0,0,0,0,1377,1,0,0,0,0,1379,1,0,0,0,0,1381,
	1,0,0,0,0,1383,1,0,0,0,0,1385,1,0,0,0,0,1387,1,0,0,0,0,1389,1,0,0,0,0,1391,
	1,0,0,0,0,1393,1,0,0,0,0,1395,1,0,0,0,0,1397,1,0,0,0,0,1399,1,0,0,0,0,1401,
	1,0,0,0,0,1403,1,0,0,0,0,1405,1,0,0,0,0,1407,1,0,0,0,0,1409,1,0,0,0,0,1411,
	1,0,0,0,0,1413,1,0,0,0,0,1415,1,0,0,0,0,1417,1,0,0,0,0,1419,1,0,0,0,0,1421,
	1,0,0,0,0,1423,1,0,0,0,0,1425,1,0,0,0,0,1427,1,0,0,0,0,1429,1,0,0,0,0,1431,
	1,0,0,0,0,1433,1,0,0,0,0,1435,1,0,0,0,0,1437,1,0,0,0,0,1439,1,0,0,0,0,1441,
	1,0,0,0,0,1443,1,0,0,0,0,1445,1,0,0,0,0,1447,1,0,0,0,0,1449,1,0,0,0,0,1451,
	1,0,0,0,0,1453,1,0,0,0,0,1455,1,0,0,0,0,1457,1,0,0,0,0,1459,1,0,0,0,0,1461,
	1,0,0,0,0,1463,1,0,0,0,0,1465,1,0,0,0,0,1467,1,0,0,0,0,1469,1,0,0,0,0,1471,
	1,0,0,0,0,1473,1,0,0,0,0,1475,1,0,0,0,0,1477,1,0,0,0,0,1479,1,0,0,0,0,1481,
	1,0,0,0,0,1483,1,0,0,0,0,1485,1,0,0,0,0,1487,1,0,0,0,0,1489,1,0,0,0,0,1491,
	1,0,0,0,0,1493,1,0,0,0,0,1495,1,0,0,0,0,1497,1,0,0,0,0,1499,1,0,0,0,0,1501,
	1,0,0,0,0,1503,1,0,0,0,0,1505,1,0,0,0,0,1507,1,0,0,0,0,1509,1,0,0,0,0,1511,
	1,0,0,0,0,1513,1,0,0,0,0,1515,1,0,0,0,0,1517,1,0,0,0,0,1519,1,0,0,0,0,1521,
	1,0,0,0,0,1523,1,0,0,0,0,1525,1,0,0,0,0,1527,1,0,0,0,0,1529,1,0,0,0,0,1531,
	1,0,0,0,0,1533,1,0,0,0,0,1535,1,0,0,0,0,1537,1,0,0,0,0,1539,1,0,0,0,0,1541,
	1,0,0,0,0,1543,1,0,0,0,0,1545,1,0,0,0,0,1547,1,0,0,0,0,1549,1,0,0,0,0,1551,
	1,0,0,0,0,1553,1,0,0,0,0,1555,1,0,0,0,0,1557,1,0,0,0,0,1559,1,0,0,0,0,1561,
	1,0,0,0,0,1563,1,0,0,0,0,1565,1,0,0,0,0,1567,1,0,0,0,0,1569,1,0,0,0,0,1571,
	1,0,0,0,0,1573,1,0,0,0,0,1575,1,0,0,0,0,1577,1,0,0,0,0,1579,1,0,0,0,0,1581,
	1,0,0,0,0,1583,1,0,0,0,0,1585,1,0,0,0,0,1587,1,0,0,0,0,1589,1,0,0,0,0,1591,
	1,0,0,0,0,1593,1,0,0,0,0,1595,1,0,0,0,0,1597,1,0,0,0,0,1599,1,0,0,0,0,1601,
	1,0,0,0,0,1603,1,0,0,0,0,1605,1,0,0,0,0,1607,1,0,0,0,0,1609,1,0,0,0,0,1611,
	1,0,0,0,0,1613,1,0,0,0,0,1615,1,0,0,0,0,1617,1,0,0,0,0,1619,1,0,0,0,0,1621,
	1,0,0,0,0,1623,1,0,0,0,0,1625,1,0,0,0,0,1627,1,0,0,0,0,1629,1,0,0,0,0,1631,
	1,0,0,0,0,1633,1,0,0,0,0,1635,1,0,0,0,0,1637,1,0,0,0,0,1639,1,0,0,0,0,1641,
	1,0,0,0,0,1643,1,0,0,0,0,1645,1,0,0,0,0,1647,1,0,0,0,0,1649,1,0,0,0,0,1651,
	1,0,0,0,0,1653,1,0,0,0,0,1655,1,0,0,0,0,1657,1,0,0,0,0,1659,1,0,0,0,0,1661,
	1,0,0,0,0,1663,1,0,0,0,0,1665,1,0,0,0,0,1667,1,0,0,0,0,1669,1,0,0,0,0,1671,
	1,0,0,0,0,1673,1,0,0,0,0,1675,1,0,0,0,0,1677,1,0,0,0,0,1679,1,0,0,0,0,1681,
	1,0,0,0,0,1683,1,0,0,0,0,1685,1,0,0,0,0,1687,1,0,0,0,0,1689,1,0,0,0,0,1691,
	1,0,0,0,0,1693,1,0,0,0,0,1695,1,0,0,0,0,1697,1,0,0,0,0,1699,1,0,0,0,0,1701,
	1,0,0,0,0,1703,1,0,0,0,0,1705,1,0,0,0,0,1707,1,0,0,0,0,1709,1,0,0,0,0,1711,
	1,0,0,0,0,1713,1,0,0,0,0,1715,1,0,0,0,0,1717,1,0,0,0,0,1719,1,0,0,0,0,1721,
	1,0,0,0,0,1723,1,0,0,0,0,1725,1,0,0,0,0,1727,1,0,0,0,0,1729,1,0,0,0,0,1731,
	1,0,0,0,0,1733,1,0,0,0,0,1735,1,0,0,0,0,1737,1,0,0,0,0,1739,1,0,0,0,0,1741,
	1,0,0,0,0,1743,1,0,0,0,0,1745,1,0,0,0,0,1747,1,0,0,0,0,1749,1,0,0,0,0,1751,
	1,0,0,0,0,1753,1,0,0,0,0,1755,1,0,0,0,0,1757,1,0,0,0,0,1759,1,0,0,0,0,1761,
	1,0,0,0,0,1763,1,0,0,0,0,1765,1,0,0,0,0,1767,1,0,0,0,0,1769,1,0,0,0,0,1771,
	1,0,0,0,0,1773,1,0,0,0,0,1775,1,0,0,0,0,1777,1,0,0,0,0,1779,1,0,0,0,0,1781,
	1,0,0,0,0,1783,1,0,0,0,0,1785,1,0,0,0,0,1787,1,0,0,0,0,1789,1,0,0,0,0,1791,
	1,0,0,0,0,1793,1,0,0,0,0,1795,1,0,0,0,0,1797,1,0,0,0,0,1799,1,0,0,0,0,1801,
	1,0,0,0,0,1803,1,0,0,0,0,1805,1,0,0,0,0,1807,1,0,0,0,0,1809,1,0,0,0,0,1811,
	1,0,0,0,0,1813,1,0,0,0,0,1815,1,0,0,0,0,1817,1,0,0,0,0,1819,1,0,0,0,0,1821,
	1,0,0,0,0,1823,1,0,0,0,0,1825,1,0,0,0,0,1827,1,0,0,0,0,1829,1,0,0,0,0,1831,
	1,0,0,0,0,1833,1,0,0,0,0,1835,1,0,0,0,0,1837,1,0,0,0,0,1839,1,0,0,0,0,1841,
	1,0,0,0,0,1843,1,0,0,0,0,1845,1,0,0,0,0,1847,1,0,0,0,0,1849,1,0,0,0,0,1851,
	1,0,0,0,0,1853,1,0,0,0,0,1855,1,0,0,0,0,1857,1,0,0,0,0,1859,1,0,0,0,0,1861,
	1,0,0,0,0,1863,1,0,0,0,0,1865,1,0,0,0,0,1867,1,0,0,0,0,1869,1,0,0,0,0,1871,
	1,0,0,0,0,1873,1,0,0,0,0,1875,1,0,0,0,0,1877,1,0,0,0,0,1879,1,0,0,0,0,1881,
	1,0,0,0,0,1883,1,0,0,0,0,1885,1,0,0,0,0,1887,1,0,0,0,0,1889,1,0,0,0,0,1891,
	1,0,0,0,0,1893,1,0,0,0,0,1895,1,0,0,0,0,1897,1,0,0,0,0,1899,1,0,0,0,0,1901,
	1,0,0,0,0,1903,1,0,0,0,0,1905,1,0,0,0,0,1907,1,0,0,0,0,1909,1,0,0,0,0,1911,
	1,0,0,0,0,1913,1,0,0,0,0,1915,1,0,0,0,0,1917,1,0,0,0,0,1919,1,0,0,0,0,1921,
	1,0,0,0,0,1923,1,0,0,0,0,1925,1,0,0,0,0,1927,1,0,0,0,0,1929,1,0,0,0,0,1931,
	1,0,0,0,0,1933,1,0,0,0,0,1935,1,0,0,0,0,1937,1,0,0,0,0,1939,1,0,0,0,0,1941,
	1,0,0,0,0,1943,1,0,0,0,0,1945,1,0,0,0,0,1947,1,0,0,0,0,1949,1,0,0,0,0,1951,
	1,0,0,0,0,1953,1,0,0,0,0,1955,1,0,0,0,0,1957,1,0,0,0,0,1959,1,0,0,0,0,1961,
	1,0,0,0,0,1963,1,0,0,0,0,1965,1,0,0,0,0,1967,1,0,0,0,0,1969,1,0,0,0,0,1971,
	1,0,0,0,0,1973,1,0,0,0,0,1975,1,0,0,0,0,1977,1,0,0,0,0,1979,1,0,0,0,0,1981,
	1,0,0,0,0,1983,1,0,0,0,0,1985,1,0,0,0,0,1987,1,0,0,0,0,1989,1,0,0,0,0,1991,
	1,0,0,0,0,1993,1,0,0,0,0,1995,1,0,0,0,0,1997,1,0,0,0,0,1999,1,0,0,0,0,2001,
	1,0,0,0,0,2003,1,0,0,0,0,2005,1,0,0,0,0,2007,1,0,0,0,0,2009,1,0,0,0,0,2011,
	1,0,0,0,0,2013,1,0,0,0,0,2015,1,0,0,0,0,2017,1,0,0,0,0,2019,1,0,0,0,0,2021,
	1,0,0,0,0,2023,1,0,0,0,0,2025,1,0,0,0,0,2027,1,0,0,0,0,2029,1,0,0,0,0,2031,
	1,0,0,0,0,2033,1,0,0,0,0,2035,1,0,0,0,0,2037,1,0,0,0,0,2039,1,0,0,0,0,2041,
	1,0,0,0,0,2043,1,0,0,0,0,2045,1,0,0,0,0,2047,1,0,0,0,0,2049,1,0,0,0,0,2051,
	1,0,0,0,0,2053,1,0,0,0,0,2055,1,0,0,0,0,2057,1,0,0,0,0,2059,1,0,0,0,0,2061,
	1,0,0,0,0,2063,1,0,0,0,0,2065,1,0,0,0,0,2067,1,0,0,0,0,2069,1,0,0,0,0,2071,
	1,0,0,0,0,2073,1,0,0,0,0,2075,1,0,0,0,0,2077,1,0,0,0,0,2079,1,0,0,0,0,2081,
	1,0,0,0,0,2083,1,0,0,0,0,2085,1,0,0,0,0,2087,1,0,0,0,0,2089,1,0,0,0,0,2091,
	1,0,0,0,0,2093,1,0,0,0,0,2095,1,0,0,0,0,2097,1,0,0,0,0,2099,1,0,0,0,0,2101,
	1,0,0,0,0,2103,1,0,0,0,0,2105,1,0,0,0,0,2107,1,0,0,0,0,2109,1,0,0,0,0,2111,
	1,0,0,0,0,2113,1,0,0,0,0,2115,1,0,0,0,0,2117,1,0,0,0,0,2119,1,0,0,0,0,2121,
	1,0,0,0,0,2123,1,0,0,0,0,2125,1,0,0,0,0,2127,1,0,0,0,0,2129,1,0,0,0,0,2131,
	1,0,0,0,0,2133,1,0,0,0,0,2135,1,0,0,0,0,2137,1,0,0,0,0,2139,1,0,0,0,0,2141,
	1,0,0,0,0,2143,1,0,0,0,0,2145,1,0,0,0,0,2147,1,0,0,0,0,2149,1,0,0,0,0,2151,
	1,0,0,0,0,2153,1,0,0,0,0,2155,1,0,0,0,0,2157,1,0,0,0,0,2159,1,0,0,0,0,2161,
	1,0,0,0,0,2163,1,0,0,0,0,2165,1,0,0,0,0,2167,1,0,0,0,0,2169,1,0,0,0,0,2171,
	1,0,0,0,0,2173,1,0,0,0,0,2175,1,0,0,0,0,2177,1,0,0,0,0,2179,1,0,0,0,0,2181,
	1,0,0,0,0,2183,1,0,0,0,0,2185,1,0,0,0,0,2187,1,0,0,0,0,2189,1,0,0,0,0,2191,
	1,0,0,0,0,2193,1,0,0,0,0,2195,1,0,0,0,0,2197,1,0,0,0,0,2199,1,0,0,0,0,2201,
	1,0,0,0,0,2203,1,0,0,0,0,2205,1,0,0,0,0,2207,1,0,0,0,0,2209,1,0,0,0,0,2211,
	1,0,0,0,0,2213,1,0,0,0,0,2215,1,0,0,0,0,2217,1,0,0,0,0,2219,1,0,0,0,0,2221,
	1,0,0,0,0,2223,1,0,0,0,0,2225,1,0,0,0,0,2227,1,0,0,0,0,2229,1,0,0,0,0,2231,
	1,0,0,0,0,2233,1,0,0,0,0,2235,1,0,0,0,0,2237,1,0,0,0,0,2239,1,0,0,0,0,2241,
	1,0,0,0,0,2243,1,0,0,0,0,2245,1,0,0,0,0,2247,1,0,0,0,0,2249,1,0,0,0,0,2251,
	1,0,0,0,0,2253,1,0,0,0,0,2255,1,0,0,0,0,2257,1,0,0,0,0,2259,1,0,0,0,0,2261,
	1,0,0,0,0,2263,1,0,0,0,0,2265,1,0,0,0,0,2267,1,0,0,0,0,2269,1,0,0,0,0,2271,
	1,0,0,0,0,2273,1,0,0,0,0,2275,1,0,0,0,0,2277,1,0,0,0,0,2279,1,0,0,0,0,2281,
	1,0,0,0,0,2283,1,0,0,0,0,2285,1,0,0,0,0,2287,1,0,0,0,0,2291,1,0,0,0,0,2293,
	1,0,0,0,0,2295,1,0,0,0,0,2297,1,0,0,0,0,2299,1,0,0,0,0,2301,1,0,0,0,0,2303,
	1,0,0,0,0,2305,1,0,0,0,0,2307,1,0,0,0,0,2309,1,0,0,0,0,2311,1,0,0,0,0,2313,
	1,0,0,0,0,2315,1,0,0,0,0,2317,1,0,0,0,0,2319,1,0,0,0,0,2321,1,0,0,0,0,2343,
	1,0,0,0,1,2346,1,0,0,0,3,2352,1,0,0,0,5,2366,1,0,0,0,7,2415,1,0,0,0,9,2419,
	1,0,0,0,11,2423,1,0,0,0,13,2427,1,0,0,0,15,2433,1,0,0,0,17,2440,1,0,0,0,
	19,2448,1,0,0,0,21,2452,1,0,0,0,23,2458,1,0,0,0,25,2461,1,0,0,0,27,2465,
	1,0,0,0,29,2475,1,0,0,0,31,2482,1,0,0,0,33,2490,1,0,0,0,35,2495,1,0,0,0,
	37,2503,1,0,0,0,39,2506,1,0,0,0,41,2511,1,0,0,0,43,2519,1,0,0,0,45,2524,
	1,0,0,0,47,2529,1,0,0,0,49,2536,1,0,0,0,51,2546,1,0,0,0,53,2552,1,0,0,0,
	55,2560,1,0,0,0,57,2567,1,0,0,0,59,2577,1,0,0,0,61,2588,1,0,0,0,63,2597,
	1,0,0,0,65,2605,1,0,0,0,67,2612,1,0,0,0,69,2618,1,0,0,0,71,2626,1,0,0,0,
	73,2639,1,0,0,0,75,2652,1,0,0,0,77,2659,1,0,0,0,79,2668,1,0,0,0,81,2678,
	1,0,0,0,83,2686,1,0,0,0,85,2694,1,0,0,0,87,2702,1,0,0,0,89,2709,1,0,0,0,
	91,2714,1,0,0,0,93,2723,1,0,0,0,95,2737,1,0,0,0,97,2749,1,0,0,0,99,2758,
	1,0,0,0,101,2770,1,0,0,0,103,2775,1,0,0,0,105,2780,1,0,0,0,107,2785,1,0,
	0,0,109,2792,1,0,0,0,111,2798,1,0,0,0,113,2807,1,0,0,0,115,2816,1,0,0,0,
	117,2824,1,0,0,0,119,2831,1,0,0,0,121,2838,1,0,0,0,123,2843,1,0,0,0,125,
	2851,1,0,0,0,127,2857,1,0,0,0,129,2863,1,0,0,0,131,2867,1,0,0,0,133,2873,
	1,0,0,0,135,2881,1,0,0,0,137,2886,1,0,0,0,139,2895,1,0,0,0,141,2905,1,0,
	0,0,143,2909,1,0,0,0,145,2915,1,0,0,0,147,2921,1,0,0,0,149,2928,1,0,0,0,
	151,2942,1,0,0,0,153,2952,1,0,0,0,155,2955,1,0,0,0,157,2962,1,0,0,0,159,
	2970,1,0,0,0,161,2973,1,0,0,0,163,2979,1,0,0,0,165,2986,1,0,0,0,167,2992,
	1,0,0,0,169,2998,1,0,0,0,171,3005,1,0,0,0,173,3014,1,0,0,0,175,3019,1,0,
	0,0,177,3022,1,0,0,0,179,3030,1,0,0,0,181,3035,1,0,0,0,183,3039,1,0,0,0,
	185,3044,1,0,0,0,187,3049,1,0,0,0,189,3057,1,0,0,0,191,3065,1,0,0,0,193,
	3071,1,0,0,0,195,3076,1,0,0,0,197,3081,1,0,0,0,199,3087,1,0,0,0,201,3094,
	1,0,0,0,203,3100,1,0,0,0,205,3105,1,0,0,0,207,3110,1,0,0,0,209,3117,1,0,
	0,0,211,3122,1,0,0,0,213,3135,1,0,0,0,215,3147,1,0,0,0,217,3177,1,0,0,0,
	219,3183,1,0,0,0,221,3192,1,0,0,0,223,3201,1,0,0,0,225,3210,1,0,0,0,227,
	3218,1,0,0,0,229,3222,1,0,0,0,231,3241,1,0,0,0,233,3246,1,0,0,0,235,3253,
	1,0,0,0,237,3256,1,0,0,0,239,3265,1,0,0,0,241,3272,1,0,0,0,243,3281,1,0,
	0,0,245,3292,1,0,0,0,247,3295,1,0,0,0,249,3301,1,0,0,0,251,3305,1,0,0,0,
	253,3311,1,0,0,0,255,3319,1,0,0,0,257,3324,1,0,0,0,259,3334,1,0,0,0,261,
	3342,1,0,0,0,263,3352,1,0,0,0,265,3358,1,0,0,0,267,3364,1,0,0,0,269,3369,
	1,0,0,0,271,3375,1,0,0,0,273,3386,1,0,0,0,275,3393,1,0,0,0,277,3401,1,0,
	0,0,279,3408,1,0,0,0,281,3415,1,0,0,0,283,3423,1,0,0,0,285,3431,1,0,0,0,
	287,3440,1,0,0,0,289,3449,1,0,0,0,291,3456,1,0,0,0,293,3463,1,0,0,0,295,
	3470,1,0,0,0,297,3476,1,0,0,0,299,3482,1,0,0,0,301,3489,1,0,0,0,303,3497,
	1,0,0,0,305,3504,1,0,0,0,307,3508,1,0,0,0,309,3518,1,0,0,0,311,3523,1,0,
	0,0,313,3530,1,0,0,0,315,3535,1,0,0,0,317,3554,1,0,0,0,319,3562,1,0,0,0,
	321,3566,1,0,0,0,323,3579,1,0,0,0,325,3588,1,0,0,0,327,3599,1,0,0,0,329,
	3614,1,0,0,0,331,3634,1,0,0,0,333,3651,1,0,0,0,335,3655,1,0,0,0,337,3663,
	1,0,0,0,339,3672,1,0,0,0,341,3682,1,0,0,0,343,3696,1,0,0,0,345,3702,1,0,
	0,0,347,3713,1,0,0,0,349,3718,1,0,0,0,351,3721,1,0,0,0,353,3730,1,0,0,0,
	355,3738,1,0,0,0,357,3743,1,0,0,0,359,3748,1,0,0,0,361,3754,1,0,0,0,363,
	3761,1,0,0,0,365,3768,1,0,0,0,367,3777,1,0,0,0,369,3784,1,0,0,0,371,3790,
	1,0,0,0,373,3794,1,0,0,0,375,3800,1,0,0,0,377,3807,1,0,0,0,379,3812,1,0,
	0,0,381,3818,1,0,0,0,383,3824,1,0,0,0,385,3829,1,0,0,0,387,3835,1,0,0,0,
	389,3839,1,0,0,0,391,3848,1,0,0,0,393,3856,1,0,0,0,395,3865,1,0,0,0,397,
	3875,1,0,0,0,399,3885,1,0,0,0,401,3889,1,0,0,0,403,3894,1,0,0,0,405,3899,
	1,0,0,0,407,3904,1,0,0,0,409,3909,1,0,0,0,411,3914,1,0,0,0,413,3922,1,0,
	0,0,415,3929,1,0,0,0,417,3934,1,0,0,0,419,3941,1,0,0,0,421,3951,1,0,0,0,
	423,3957,1,0,0,0,425,3964,1,0,0,0,427,3971,1,0,0,0,429,3979,1,0,0,0,431,
	3983,1,0,0,0,433,3991,1,0,0,0,435,3996,1,0,0,0,437,4001,1,0,0,0,439,4011,
	1,0,0,0,441,4020,1,0,0,0,443,4025,1,0,0,0,445,4030,1,0,0,0,447,4038,1,0,
	0,0,449,4047,1,0,0,0,451,4056,1,0,0,0,453,4063,1,0,0,0,455,4073,1,0,0,0,
	457,4082,1,0,0,0,459,4087,1,0,0,0,461,4098,1,0,0,0,463,4103,1,0,0,0,465,
	4112,1,0,0,0,467,4121,1,0,0,0,469,4126,1,0,0,0,471,4137,1,0,0,0,473,4146,
	1,0,0,0,475,4151,1,0,0,0,477,4159,1,0,0,0,479,4166,1,0,0,0,481,4177,1,0,
	0,0,483,4186,1,0,0,0,485,4197,1,0,0,0,487,4208,1,0,0,0,489,4220,1,0,0,0,
	491,4232,1,0,0,0,493,4246,1,0,0,0,495,4265,1,0,0,0,497,4284,1,0,0,0,499,
	4301,1,0,0,0,501,4317,1,0,0,0,503,4328,1,0,0,0,505,4342,1,0,0,0,507,4360,
	1,0,0,0,509,4378,1,0,0,0,511,4392,1,0,0,0,513,4411,1,0,0,0,515,4422,1,0,
	0,0,517,4435,1,0,0,0,519,4447,1,0,0,0,521,4457,1,0,0,0,523,4469,1,0,0,0,
	525,4480,1,0,0,0,527,4497,1,0,0,0,529,4517,1,0,0,0,531,4529,1,0,0,0,533,
	4544,1,0,0,0,535,4558,1,0,0,0,537,4570,1,0,0,0,539,4581,1,0,0,0,541,4593,
	1,0,0,0,543,4606,1,0,0,0,545,4624,1,0,0,0,547,4654,1,0,0,0,549,4666,1,0,
	0,0,551,4675,1,0,0,0,553,4693,1,0,0,0,555,4711,1,0,0,0,557,4722,1,0,0,0,
	559,4732,1,0,0,0,561,4745,1,0,0,0,563,4756,1,0,0,0,565,4767,1,0,0,0,567,
	4774,1,0,0,0,569,4785,1,0,0,0,571,4790,1,0,0,0,573,4794,1,0,0,0,575,4802,
	1,0,0,0,577,4809,1,0,0,0,579,4817,1,0,0,0,581,4823,1,0,0,0,583,4833,1,0,
	0,0,585,4844,1,0,0,0,587,4856,1,0,0,0,589,4869,1,0,0,0,591,4873,1,0,0,0,
	593,4884,1,0,0,0,595,4889,1,0,0,0,597,4893,1,0,0,0,599,4897,1,0,0,0,601,
	4903,1,0,0,0,603,4913,1,0,0,0,605,4926,1,0,0,0,607,4931,1,0,0,0,609,4942,
	1,0,0,0,611,4946,1,0,0,0,613,4953,1,0,0,0,615,4964,1,0,0,0,617,4976,1,0,
	0,0,619,4980,1,0,0,0,621,4988,1,0,0,0,623,4997,1,0,0,0,625,5006,1,0,0,0,
	627,5019,1,0,0,0,629,5032,1,0,0,0,631,5050,1,0,0,0,633,5060,1,0,0,0,635,
	5068,1,0,0,0,637,5076,1,0,0,0,639,5085,1,0,0,0,641,5094,1,0,0,0,643,5102,
	1,0,0,0,645,5117,1,0,0,0,647,5121,1,0,0,0,649,5130,1,0,0,0,651,5137,1,0,
	0,0,653,5147,1,0,0,0,655,5155,1,0,0,0,657,5160,1,0,0,0,659,5169,1,0,0,0,
	661,5178,1,0,0,0,663,5192,1,0,0,0,665,5200,1,0,0,0,667,5207,1,0,0,0,669,
	5213,1,0,0,0,671,5223,1,0,0,0,673,5233,1,0,0,0,675,5237,1,0,0,0,677,5240,
	1,0,0,0,679,5248,1,0,0,0,681,5259,1,0,0,0,683,5275,1,0,0,0,685,5290,1,0,
	0,0,687,5305,1,0,0,0,689,5311,1,0,0,0,691,5318,1,0,0,0,693,5322,1,0,0,0,
	695,5328,1,0,0,0,697,5333,1,0,0,0,699,5341,1,0,0,0,701,5347,1,0,0,0,703,
	5353,1,0,0,0,705,5362,1,0,0,0,707,5368,1,0,0,0,709,5376,1,0,0,0,711,5384,
	1,0,0,0,713,5393,1,0,0,0,715,5407,1,0,0,0,717,5414,1,0,0,0,719,5427,1,0,
	0,0,721,5434,1,0,0,0,723,5440,1,0,0,0,725,5451,1,0,0,0,727,5460,1,0,0,0,
	729,5465,1,0,0,0,731,5473,1,0,0,0,733,5487,1,0,0,0,735,5499,1,0,0,0,737,
	5507,1,0,0,0,739,5514,1,0,0,0,741,5522,1,0,0,0,743,5533,1,0,0,0,745,5544,
	1,0,0,0,747,5556,1,0,0,0,749,5567,1,0,0,0,751,5575,1,0,0,0,753,5586,1,0,
	0,0,755,5597,1,0,0,0,757,5616,1,0,0,0,759,5634,1,0,0,0,761,5650,1,0,0,0,
	763,5659,1,0,0,0,765,5667,1,0,0,0,767,5680,1,0,0,0,769,5685,1,0,0,0,771,
	5689,1,0,0,0,773,5695,1,0,0,0,775,5707,1,0,0,0,777,5712,1,0,0,0,779,5721,
	1,0,0,0,781,5732,1,0,0,0,783,5745,1,0,0,0,785,5753,1,0,0,0,787,5769,1,0,
	0,0,789,5782,1,0,0,0,791,5792,1,0,0,0,793,5800,1,0,0,0,795,5808,1,0,0,0,
	797,5813,1,0,0,0,799,5816,1,0,0,0,801,5825,1,0,0,0,803,5835,1,0,0,0,805,
	5843,1,0,0,0,807,5850,1,0,0,0,809,5860,1,0,0,0,811,5871,1,0,0,0,813,5889,
	1,0,0,0,815,5893,1,0,0,0,817,5898,1,0,0,0,819,5905,1,0,0,0,821,5913,1,0,
	0,0,823,5919,1,0,0,0,825,5926,1,0,0,0,827,5933,1,0,0,0,829,5938,1,0,0,0,
	831,5944,1,0,0,0,833,5951,1,0,0,0,835,5957,1,0,0,0,837,5966,1,0,0,0,839,
	5976,1,0,0,0,841,5983,1,0,0,0,843,5990,1,0,0,0,845,5999,1,0,0,0,847,6011,
	1,0,0,0,849,6033,1,0,0,0,851,6038,1,0,0,0,853,6045,1,0,0,0,855,6052,1,0,
	0,0,857,6068,1,0,0,0,859,6075,1,0,0,0,861,6081,1,0,0,0,863,6087,1,0,0,0,
	865,6093,1,0,0,0,867,6103,1,0,0,0,869,6111,1,0,0,0,871,6117,1,0,0,0,873,
	6122,1,0,0,0,875,6131,1,0,0,0,877,6139,1,0,0,0,879,6146,1,0,0,0,881,6153,
	1,0,0,0,883,6171,1,0,0,0,885,6179,1,0,0,0,887,6184,1,0,0,0,889,6189,1,0,
	0,0,891,6197,1,0,0,0,893,6202,1,0,0,0,895,6208,1,0,0,0,897,6219,1,0,0,0,
	899,6237,1,0,0,0,901,6244,1,0,0,0,903,6254,1,0,0,0,905,6262,1,0,0,0,907,
	6275,1,0,0,0,909,6283,1,0,0,0,911,6297,1,0,0,0,913,6305,1,0,0,0,915,6314,
	1,0,0,0,917,6322,1,0,0,0,919,6332,1,0,0,0,921,6340,1,0,0,0,923,6343,1,0,
	0,0,925,6353,1,0,0,0,927,6357,1,0,0,0,929,6367,1,0,0,0,931,6374,1,0,0,0,
	933,6379,1,0,0,0,935,6394,1,0,0,0,937,6403,1,0,0,0,939,6408,1,0,0,0,941,
	6415,1,0,0,0,943,6420,1,0,0,0,945,6426,1,0,0,0,947,6431,1,0,0,0,949,6437,
	1,0,0,0,951,6445,1,0,0,0,953,6450,1,0,0,0,955,6457,1,0,0,0,957,6478,1,0,
	0,0,959,6499,1,0,0,0,961,6512,1,0,0,0,963,6536,1,0,0,0,965,6548,1,0,0,0,
	967,6564,1,0,0,0,969,6579,1,0,0,0,971,6595,1,0,0,0,973,6607,1,0,0,0,975,
	6626,1,0,0,0,977,6637,1,0,0,0,979,6651,1,0,0,0,981,6669,1,0,0,0,983,6685,
	1,0,0,0,985,6703,1,0,0,0,987,6718,1,0,0,0,989,6737,1,0,0,0,991,6752,1,0,
	0,0,993,6771,1,0,0,0,995,6783,1,0,0,0,997,6808,1,0,0,0,999,6829,1,0,0,0,
	1001,6838,1,0,0,0,1003,6847,1,0,0,0,1005,6868,1,0,0,0,1007,6889,1,0,0,0,
	1009,6896,1,0,0,0,1011,6903,1,0,0,0,1013,6909,1,0,0,0,1015,6922,1,0,0,0,
	1017,6926,1,0,0,0,1019,6934,1,0,0,0,1021,6943,1,0,0,0,1023,6948,1,0,0,0,
	1025,6955,1,0,0,0,1027,6961,1,0,0,0,1029,6967,1,0,0,0,1031,6979,1,0,0,0,
	1033,6984,1,0,0,0,1035,6990,1,0,0,0,1037,6996,1,0,0,0,1039,7002,1,0,0,0,
	1041,7007,1,0,0,0,1043,7010,1,0,0,0,1045,7018,1,0,0,0,1047,7025,1,0,0,0,
	1049,7033,1,0,0,0,1051,7044,1,0,0,0,1053,7055,1,0,0,0,1055,7062,1,0,0,0,
	1057,7072,1,0,0,0,1059,7077,1,0,0,0,1061,7082,1,0,0,0,1063,7090,1,0,0,0,
	1065,7097,1,0,0,0,1067,7100,1,0,0,0,1069,7103,1,0,0,0,1071,7116,1,0,0,0,
	1073,7120,1,0,0,0,1075,7127,1,0,0,0,1077,7132,1,0,0,0,1079,7137,1,0,0,0,
	1081,7153,1,0,0,0,1083,7161,1,0,0,0,1085,7167,1,0,0,0,1087,7177,1,0,0,0,
	1089,7182,1,0,0,0,1091,7198,1,0,0,0,1093,7221,1,0,0,0,1095,7228,1,0,0,0,
	1097,7236,1,0,0,0,1099,7249,1,0,0,0,1101,7260,1,0,0,0,1103,7269,1,0,0,0,
	1105,7288,1,0,0,0,1107,7294,1,0,0,0,1109,7301,1,0,0,0,1111,7312,1,0,0,0,
	1113,7320,1,0,0,0,1115,7325,1,0,0,0,1117,7334,1,0,0,0,1119,7344,1,0,0,0,
	1121,7352,1,0,0,0,1123,7361,1,0,0,0,1125,7366,1,0,0,0,1127,7378,1,0,0,0,
	1129,7386,1,0,0,0,1131,7395,1,0,0,0,1133,7401,1,0,0,0,1135,7407,1,0,0,0,
	1137,7413,1,0,0,0,1139,7421,1,0,0,0,1141,7429,1,0,0,0,1143,7439,1,0,0,0,
	1145,7456,1,0,0,0,1147,7466,1,0,0,0,1149,7472,1,0,0,0,1151,7487,1,0,0,0,
	1153,7501,1,0,0,0,1155,7510,1,0,0,0,1157,7517,1,0,0,0,1159,7528,1,0,0,0,
	1161,7535,1,0,0,0,1163,7551,1,0,0,0,1165,7570,1,0,0,0,1167,7590,1,0,0,0,
	1169,7613,1,0,0,0,1171,7634,1,0,0,0,1173,7658,1,0,0,0,1175,7686,1,0,0,0,
	1177,7698,1,0,0,0,1179,7704,1,0,0,0,1181,7712,1,0,0,0,1183,7719,1,0,0,0,
	1185,7737,1,0,0,0,1187,7747,1,0,0,0,1189,7755,1,0,0,0,1191,7761,1,0,0,0,
	1193,7766,1,0,0,0,1195,7775,1,0,0,0,1197,7782,1,0,0,0,1199,7789,1,0,0,0,
	1201,7793,1,0,0,0,1203,7798,1,0,0,0,1205,7809,1,0,0,0,1207,7815,1,0,0,0,
	1209,7825,1,0,0,0,1211,7834,1,0,0,0,1213,7843,1,0,0,0,1215,7852,1,0,0,0,
	1217,7859,1,0,0,0,1219,7867,1,0,0,0,1221,7873,1,0,0,0,1223,7880,1,0,0,0,
	1225,7887,1,0,0,0,1227,7894,1,0,0,0,1229,7900,1,0,0,0,1231,7905,1,0,0,0,
	1233,7914,1,0,0,0,1235,7921,1,0,0,0,1237,7926,1,0,0,0,1239,7933,1,0,0,0,
	1241,7940,1,0,0,0,1243,7947,1,0,0,0,1245,7963,1,0,0,0,1247,7982,1,0,0,0,
	1249,7999,1,0,0,0,1251,8017,1,0,0,0,1253,8027,1,0,0,0,1255,8040,1,0,0,0,
	1257,8051,1,0,0,0,1259,8057,1,0,0,0,1261,8064,1,0,0,0,1263,8082,1,0,0,0,
	1265,8099,1,0,0,0,1267,8118,1,0,0,0,1269,8125,1,0,0,0,1271,8130,1,0,0,0,
	1273,8138,1,0,0,0,1275,8145,1,0,0,0,1277,8152,1,0,0,0,1279,8168,1,0,0,0,
	1281,8176,1,0,0,0,1283,8189,1,0,0,0,1285,8203,1,0,0,0,1287,8211,1,0,0,0,
	1289,8217,1,0,0,0,1291,8226,1,0,0,0,1293,8237,1,0,0,0,1295,8248,1,0,0,0,
	1297,8259,1,0,0,0,1299,8269,1,0,0,0,1301,8279,1,0,0,0,1303,8284,1,0,0,0,
	1305,8296,1,0,0,0,1307,8308,1,0,0,0,1309,8322,1,0,0,0,1311,8331,1,0,0,0,
	1313,8340,1,0,0,0,1315,8350,1,0,0,0,1317,8360,1,0,0,0,1319,8369,1,0,0,0,
	1321,8386,1,0,0,0,1323,8396,1,0,0,0,1325,8404,1,0,0,0,1327,8410,1,0,0,0,
	1329,8418,1,0,0,0,1331,8423,1,0,0,0,1333,8431,1,0,0,0,1335,8446,1,0,0,0,
	1337,8457,1,0,0,0,1339,8463,1,0,0,0,1341,8473,1,0,0,0,1343,8478,1,0,0,0,
	1345,8486,1,0,0,0,1347,8494,1,0,0,0,1349,8499,1,0,0,0,1351,8508,1,0,0,0,
	1353,8515,1,0,0,0,1355,8523,1,0,0,0,1357,8528,1,0,0,0,1359,8536,1,0,0,0,
	1361,8541,1,0,0,0,1363,8544,1,0,0,0,1365,8548,1,0,0,0,1367,8552,1,0,0,0,
	1369,8556,1,0,0,0,1371,8560,1,0,0,0,1373,8564,1,0,0,0,1375,8568,1,0,0,0,
	1377,8577,1,0,0,0,1379,8585,1,0,0,0,1381,8591,1,0,0,0,1383,8595,1,0,0,0,
	1385,8600,1,0,0,0,1387,8607,1,0,0,0,1389,8612,1,0,0,0,1391,8619,1,0,0,0,
	1393,8631,1,0,0,0,1395,8637,1,0,0,0,1397,8664,1,0,0,0,1399,8683,1,0,0,0,
	1401,8695,1,0,0,0,1403,8723,1,0,0,0,1405,8736,1,0,0,0,1407,8749,1,0,0,0,
	1409,8773,1,0,0,0,1411,8785,1,0,0,0,1413,8802,1,0,0,0,1415,8823,1,0,0,0,
	1417,8831,1,0,0,0,1419,8836,1,0,0,0,1421,8851,1,0,0,0,1423,8867,1,0,0,0,
	1425,8881,1,0,0,0,1427,8903,1,0,0,0,1429,8916,1,0,0,0,1431,8929,1,0,0,0,
	1433,8950,1,0,0,0,1435,8974,1,0,0,0,1437,8998,1,0,0,0,1439,9021,1,0,0,0,
	1441,9028,1,0,0,0,1443,9035,1,0,0,0,1445,9051,1,0,0,0,1447,9075,1,0,0,0,
	1449,9102,1,0,0,0,1451,9113,1,0,0,0,1453,9121,1,0,0,0,1455,9128,1,0,0,0,
	1457,9148,1,0,0,0,1459,9172,1,0,0,0,1461,9193,1,0,0,0,1463,9213,1,0,0,0,
	1465,9224,1,0,0,0,1467,9232,1,0,0,0,1469,9235,1,0,0,0,1471,9261,1,0,0,0,
	1473,9290,1,0,0,0,1475,9302,1,0,0,0,1477,9315,1,0,0,0,1479,9324,1,0,0,0,
	1481,9330,1,0,0,0,1483,9353,1,0,0,0,1485,9360,1,0,0,0,1487,9383,1,0,0,0,
	1489,9403,1,0,0,0,1491,9420,1,0,0,0,1493,9429,1,0,0,0,1495,9435,1,0,0,0,
	1497,9440,1,0,0,0,1499,9447,1,0,0,0,1501,9454,1,0,0,0,1503,9461,1,0,0,0,
	1505,9468,1,0,0,0,1507,9474,1,0,0,0,1509,9480,1,0,0,0,1511,9486,1,0,0,0,
	1513,9492,1,0,0,0,1515,9497,1,0,0,0,1517,9505,1,0,0,0,1519,9511,1,0,0,0,
	1521,9519,1,0,0,0,1523,9526,1,0,0,0,1525,9530,1,0,0,0,1527,9538,1,0,0,0,
	1529,9544,1,0,0,0,1531,9551,1,0,0,0,1533,9555,1,0,0,0,1535,9563,1,0,0,0,
	1537,9569,1,0,0,0,1539,9575,1,0,0,0,1541,9582,1,0,0,0,1543,9589,1,0,0,0,
	1545,9596,1,0,0,0,1547,9603,1,0,0,0,1549,9609,1,0,0,0,1551,9618,1,0,0,0,
	1553,9623,1,0,0,0,1555,9628,1,0,0,0,1557,9635,1,0,0,0,1559,9640,1,0,0,0,
	1561,9645,1,0,0,0,1563,9651,1,0,0,0,1565,9659,1,0,0,0,1567,9665,1,0,0,0,
	1569,9670,1,0,0,0,1571,9678,1,0,0,0,1573,9686,1,0,0,0,1575,9694,1,0,0,0,
	1577,9704,1,0,0,0,1579,9708,1,0,0,0,1581,9718,1,0,0,0,1583,9725,1,0,0,0,
	1585,9732,1,0,0,0,1587,9743,1,0,0,0,1589,9750,1,0,0,0,1591,9754,1,0,0,0,
	1593,9765,1,0,0,0,1595,9784,1,0,0,0,1597,9791,1,0,0,0,1599,9802,1,0,0,0,
	1601,9812,1,0,0,0,1603,9824,1,0,0,0,1605,9837,1,0,0,0,1607,9856,1,0,0,0,
	1609,9871,1,0,0,0,1611,9880,1,0,0,0,1613,9891,1,0,0,0,1615,9907,1,0,0,0,
	1617,9918,1,0,0,0,1619,9931,1,0,0,0,1621,9937,1,0,0,0,1623,9945,1,0,0,0,
	1625,9949,1,0,0,0,1627,9954,1,0,0,0,1629,9962,1,0,0,0,1631,9970,1,0,0,0,
	1633,9982,1,0,0,0,1635,9994,1,0,0,0,1637,9999,1,0,0,0,1639,10008,1,0,0,
	0,1641,10013,1,0,0,0,1643,10020,1,0,0,0,1645,10026,1,0,0,0,1647,10032,1,
	0,0,0,1649,10051,1,0,0,0,1651,10069,1,0,0,0,1653,10088,1,0,0,0,1655,10104,
	1,0,0,0,1657,10122,1,0,0,0,1659,10127,1,0,0,0,1661,10133,1,0,0,0,1663,10143,
	1,0,0,0,1665,10147,1,0,0,0,1667,10157,1,0,0,0,1669,10168,1,0,0,0,1671,10175,
	1,0,0,0,1673,10188,1,0,0,0,1675,10193,1,0,0,0,1677,10201,1,0,0,0,1679,10210,
	1,0,0,0,1681,10227,1,0,0,0,1683,10235,1,0,0,0,1685,10247,1,0,0,0,1687,10260,
	1,0,0,0,1689,10270,1,0,0,0,1691,10279,1,0,0,0,1693,10286,1,0,0,0,1695,10296,
	1,0,0,0,1697,10310,1,0,0,0,1699,10315,1,0,0,0,1701,10326,1,0,0,0,1703,10330,
	1,0,0,0,1705,10334,1,0,0,0,1707,10340,1,0,0,0,1709,10367,1,0,0,0,1711,10393,
	1,0,0,0,1713,10414,1,0,0,0,1715,10428,1,0,0,0,1717,10436,1,0,0,0,1719,10445,
	1,0,0,0,1721,10457,1,0,0,0,1723,10465,1,0,0,0,1725,10476,1,0,0,0,1727,10486,
	1,0,0,0,1729,10496,1,0,0,0,1731,10503,1,0,0,0,1733,10511,1,0,0,0,1735,10523,
	1,0,0,0,1737,10535,1,0,0,0,1739,10545,1,0,0,0,1741,10554,1,0,0,0,1743,10558,
	1,0,0,0,1745,10565,1,0,0,0,1747,10573,1,0,0,0,1749,10582,1,0,0,0,1751,10599,
	1,0,0,0,1753,10608,1,0,0,0,1755,10615,1,0,0,0,1757,10619,1,0,0,0,1759,10630,
	1,0,0,0,1761,10643,1,0,0,0,1763,10656,1,0,0,0,1765,10662,1,0,0,0,1767,10674,
	1,0,0,0,1769,10680,1,0,0,0,1771,10687,1,0,0,0,1773,10698,1,0,0,0,1775,10710,
	1,0,0,0,1777,10720,1,0,0,0,1779,10734,1,0,0,0,1781,10751,1,0,0,0,1783,10767,
	1,0,0,0,1785,10794,1,0,0,0,1787,10820,1,0,0,0,1789,10837,1,0,0,0,1791,10853,
	1,0,0,0,1793,10863,1,0,0,0,1795,10876,1,0,0,0,1797,10889,1,0,0,0,1799,10901,
	1,0,0,0,1801,10912,1,0,0,0,1803,10921,1,0,0,0,1805,10929,1,0,0,0,1807,10938,
	1,0,0,0,1809,10950,1,0,0,0,1811,10964,1,0,0,0,1813,10968,1,0,0,0,1815,10975,
	1,0,0,0,1817,10986,1,0,0,0,1819,10997,1,0,0,0,1821,11007,1,0,0,0,1823,11017,
	1,0,0,0,1825,11023,1,0,0,0,1827,11037,1,0,0,0,1829,11048,1,0,0,0,1831,11057,
	1,0,0,0,1833,11065,1,0,0,0,1835,11072,1,0,0,0,1837,11081,1,0,0,0,1839,11094,
	1,0,0,0,1841,11102,1,0,0,0,1843,11117,1,0,0,0,1845,11132,1,0,0,0,1847,11140,
	1,0,0,0,1849,11153,1,0,0,0,1851,11168,1,0,0,0,1853,11174,1,0,0,0,1855,11180,
	1,0,0,0,1857,11187,1,0,0,0,1859,11200,1,0,0,0,1861,11212,1,0,0,0,1863,11231,
	1,0,0,0,1865,11249,1,0,0,0,1867,11252,1,0,0,0,1869,11262,1,0,0,0,1871,11269,
	1,0,0,0,1873,11273,1,0,0,0,1875,11279,1,0,0,0,1877,11284,1,0,0,0,1879,11290,
	1,0,0,0,1881,11295,1,0,0,0,1883,11301,1,0,0,0,1885,11310,1,0,0,0,1887,11319,
	1,0,0,0,1889,11328,1,0,0,0,1891,11344,1,0,0,0,1893,11356,1,0,0,0,1895,11368,
	1,0,0,0,1897,11377,1,0,0,0,1899,11391,1,0,0,0,1901,11403,1,0,0,0,1903,11414,
	1,0,0,0,1905,11424,1,0,0,0,1907,11428,1,0,0,0,1909,11442,1,0,0,0,1911,11455,
	1,0,0,0,1913,11465,1,0,0,0,1915,11480,1,0,0,0,1917,11494,1,0,0,0,1919,11508,
	1,0,0,0,1921,11521,1,0,0,0,1923,11545,1,0,0,0,1925,11568,1,0,0,0,1927,11587,
	1,0,0,0,1929,11605,1,0,0,0,1931,11626,1,0,0,0,1933,11646,1,0,0,0,1935,11657,
	1,0,0,0,1937,11664,1,0,0,0,1939,11678,1,0,0,0,1941,11695,1,0,0,0,1943,11705,
	1,0,0,0,1945,11709,1,0,0,0,1947,11722,1,0,0,0,1949,11726,1,0,0,0,1951,11735,
	1,0,0,0,1953,11746,1,0,0,0,1955,11758,1,0,0,0,1957,11761,1,0,0,0,1959,11775,
	1,0,0,0,1961,11788,1,0,0,0,1963,11795,1,0,0,0,1965,11808,1,0,0,0,1967,11820,
	1,0,0,0,1969,11836,1,0,0,0,1971,11851,1,0,0,0,1973,11855,1,0,0,0,1975,11861,
	1,0,0,0,1977,11867,1,0,0,0,1979,11875,1,0,0,0,1981,11880,1,0,0,0,1983,11887,
	1,0,0,0,1985,11900,1,0,0,0,1987,11913,1,0,0,0,1989,11921,1,0,0,0,1991,11927,
	1,0,0,0,1993,11937,1,0,0,0,1995,11942,1,0,0,0,1997,11948,1,0,0,0,1999,11960,
	1,0,0,0,2001,11987,1,0,0,0,2003,12000,1,0,0,0,2005,12004,1,0,0,0,2007,12009,
	1,0,0,0,2009,12014,1,0,0,0,2011,12026,1,0,0,0,2013,12031,1,0,0,0,2015,12035,
	1,0,0,0,2017,12041,1,0,0,0,2019,12049,1,0,0,0,2021,12077,1,0,0,0,2023,12082,
	1,0,0,0,2025,12087,1,0,0,0,2027,12098,1,0,0,0,2029,12105,1,0,0,0,2031,12117,
	1,0,0,0,2033,12125,1,0,0,0,2035,12137,1,0,0,0,2037,12147,1,0,0,0,2039,12156,
	1,0,0,0,2041,12165,1,0,0,0,2043,12175,1,0,0,0,2045,12187,1,0,0,0,2047,12199,
	1,0,0,0,2049,12210,1,0,0,0,2051,12224,1,0,0,0,2053,12237,1,0,0,0,2055,12249,
	1,0,0,0,2057,12261,1,0,0,0,2059,12273,1,0,0,0,2061,12285,1,0,0,0,2063,12295,
	1,0,0,0,2065,12311,1,0,0,0,2067,12331,1,0,0,0,2069,12350,1,0,0,0,2071,12369,
	1,0,0,0,2073,12399,1,0,0,0,2075,12428,1,0,0,0,2077,12448,1,0,0,0,2079,12467,
	1,0,0,0,2081,12480,1,0,0,0,2083,12496,1,0,0,0,2085,12512,1,0,0,0,2087,12527,
	1,0,0,0,2089,12544,1,0,0,0,2091,12560,1,0,0,0,2093,12574,1,0,0,0,2095,12586,
	1,0,0,0,2097,12597,1,0,0,0,2099,12609,1,0,0,0,2101,12625,1,0,0,0,2103,12640,
	1,0,0,0,2105,12662,1,0,0,0,2107,12683,1,0,0,0,2109,12700,1,0,0,0,2111,12719,
	1,0,0,0,2113,12739,1,0,0,0,2115,12752,1,0,0,0,2117,12764,1,0,0,0,2119,12781,
	1,0,0,0,2121,12797,1,0,0,0,2123,12807,1,0,0,0,2125,12823,1,0,0,0,2127,12838,
	1,0,0,0,2129,12857,1,0,0,0,2131,12875,1,0,0,0,2133,12883,1,0,0,0,2135,12897,
	1,0,0,0,2137,12914,1,0,0,0,2139,12925,1,0,0,0,2141,12934,1,0,0,0,2143,12944,
	1,0,0,0,2145,12949,1,0,0,0,2147,12954,1,0,0,0,2149,12962,1,0,0,0,2151,12978,
	1,0,0,0,2153,12986,1,0,0,0,2155,12998,1,0,0,0,2157,13002,1,0,0,0,2159,13011,
	1,0,0,0,2161,13024,1,0,0,0,2163,13038,1,0,0,0,2165,13050,1,0,0,0,2167,13062,
	1,0,0,0,2169,13070,1,0,0,0,2171,13080,1,0,0,0,2173,13088,1,0,0,0,2175,13099,
	1,0,0,0,2177,13119,1,0,0,0,2179,13125,1,0,0,0,2181,13136,1,0,0,0,2183,13156,
	1,0,0,0,2185,13162,1,0,0,0,2187,13177,1,0,0,0,2189,13187,1,0,0,0,2191,13193,
	1,0,0,0,2193,13198,1,0,0,0,2195,13209,1,0,0,0,2197,13236,1,0,0,0,2199,13244,
	1,0,0,0,2201,13278,1,0,0,0,2203,13286,1,0,0,0,2205,13297,1,0,0,0,2207,13311,
	1,0,0,0,2209,13318,1,0,0,0,2211,13327,1,0,0,0,2213,13329,1,0,0,0,2215,13331,
	1,0,0,0,2217,13334,1,0,0,0,2219,13337,1,0,0,0,2221,13340,1,0,0,0,2223,13343,
	1,0,0,0,2225,13346,1,0,0,0,2227,13349,1,0,0,0,2229,13352,1,0,0,0,2231,13355,
	1,0,0,0,2233,13358,1,0,0,0,2235,13360,1,0,0,0,2237,13362,1,0,0,0,2239,13364,
	1,0,0,0,2241,13366,1,0,0,0,2243,13368,1,0,0,0,2245,13372,1,0,0,0,2247,13376,
	1,0,0,0,2249,13378,1,0,0,0,2251,13380,1,0,0,0,2253,13382,1,0,0,0,2255,13384,
	1,0,0,0,2257,13386,1,0,0,0,2259,13388,1,0,0,0,2261,13390,1,0,0,0,2263,13392,
	1,0,0,0,2265,13394,1,0,0,0,2267,13396,1,0,0,0,2269,13398,1,0,0,0,2271,13400,
	1,0,0,0,2273,13402,1,0,0,0,2275,13404,1,0,0,0,2277,13406,1,0,0,0,2279,13408,
	1,0,0,0,2281,13410,1,0,0,0,2283,13412,1,0,0,0,2285,13414,1,0,0,0,2287,13416,
	1,0,0,0,2289,13421,1,0,0,0,2291,13423,1,0,0,0,2293,13428,1,0,0,0,2295,13434,
	1,0,0,0,2297,13440,1,0,0,0,2299,13443,1,0,0,0,2301,13466,1,0,0,0,2303,13509,
	1,0,0,0,2305,13511,1,0,0,0,2307,13514,1,0,0,0,2309,13516,1,0,0,0,2311,13519,
	1,0,0,0,2313,13522,1,0,0,0,2315,13524,1,0,0,0,2317,13526,1,0,0,0,2319,13529,
	1,0,0,0,2321,13538,1,0,0,0,2323,13589,1,0,0,0,2325,13591,1,0,0,0,2327,13603,
	1,0,0,0,2329,13617,1,0,0,0,2331,13630,1,0,0,0,2333,13643,1,0,0,0,2335,13654,
	1,0,0,0,2337,13656,1,0,0,0,2339,13658,1,0,0,0,2341,13697,1,0,0,0,2343,13699,
	1,0,0,0,2345,2347,7,0,0,0,2346,2345,1,0,0,0,2347,2348,1,0,0,0,2348,2346,
	1,0,0,0,2348,2349,1,0,0,0,2349,2350,1,0,0,0,2350,2351,6,0,0,0,2351,2,1,
	0,0,0,2352,2353,5,47,0,0,2353,2354,5,42,0,0,2354,2355,5,33,0,0,2355,2357,
	1,0,0,0,2356,2358,9,0,0,0,2357,2356,1,0,0,0,2358,2359,1,0,0,0,2359,2360,
	1,0,0,0,2359,2357,1,0,0,0,2360,2361,1,0,0,0,2361,2362,5,42,0,0,2362,2363,
	5,47,0,0,2363,2364,1,0,0,0,2364,2365,6,1,1,0,2365,4,1,0,0,0,2366,2367,5,
	47,0,0,2367,2368,5,42,0,0,2368,2372,1,0,0,0,2369,2371,9,0,0,0,2370,2369,
	1,0,0,0,2371,2374,1,0,0,0,2372,2373,1,0,0,0,2372,2370,1,0,0,0,2373,2375,
	1,0,0,0,2374,2372,1,0,0,0,2375,2376,5,42,0,0,2376,2377,5,47,0,0,2377,2378,
	1,0,0,0,2378,2379,6,2,0,0,2379,6,1,0,0,0,2380,2381,5,45,0,0,2381,2382,5,
	45,0,0,2382,2386,1,0,0,0,2383,2385,7,1,0,0,2384,2383,1,0,0,0,2385,2388,
	1,0,0,0,2386,2384,1,0,0,0,2386,2387,1,0,0,0,2387,2391,1,0,0,0,2388,2386,
	1,0,0,0,2389,2391,5,35,0,0,2390,2380,1,0,0,0,2390,2389,1,0,0,0,2391,2395,
	1,0,0,0,2392,2394,8,2,0,0,2393,2392,1,0,0,0,2394,2397,1,0,0,0,2395,2393,
	1,0,0,0,2395,2396,1,0,0,0,2396,2403,1,0,0,0,2397,2395,1,0,0,0,2398,2400,
	5,13,0,0,2399,2398,1,0,0,0,2399,2400,1,0,0,0,2400,2401,1,0,0,0,2401,2404,
	5,10,0,0,2402,2404,5,0,0,1,2403,2399,1,0,0,0,2403,2402,1,0,0,0,2404,2416,
	1,0,0,0,2405,2406,5,45,0,0,2406,2407,5,45,0,0,2407,2413,1,0,0,0,2408,2410,
	5,13,0,0,2409,2408,1,0,0,0,2409,2410,1,0,0,0,2410,2411,1,0,0,0,2411,2414,
	5,10,0,0,2412,2414,5,0,0,1,2413,2409,1,0,0,0,2413,2412,1,0,0,0,2414,2416,
	1,0,0,0,2415,2390,1,0,0,0,2415,2405,1,0,0,0,2416,2417,1,0,0,0,2417,2418,
	6,3,0,0,2418,8,1,0,0,0,2419,2420,7,3,0,0,2420,2421,7,4,0,0,2421,2422,7,
	4,0,0,2422,10,1,0,0,0,2423,2424,7,3,0,0,2424,2425,7,5,0,0,2425,2426,7,5,
	0,0,2426,12,1,0,0,0,2427,2428,7,3,0,0,2428,2429,7,5,0,0,2429,2430,7,6,0,
	0,2430,2431,7,7,0,0,2431,2432,7,8,0,0,2432,14,1,0,0,0,2433,2434,7,3,0,0,
	2434,2435,7,5,0,0,2435,2436,7,9,0,0,2436,2437,7,3,0,0,2437,2438,7,10,0,
	0,2438,2439,7,11,0,0,2439,16,1,0,0,0,2440,2441,7,3,0,0,2441,2442,7,12,0,
	0,2442,2443,7,3,0,0,2443,2444,7,5,0,0,2444,2445,7,10,0,0,2445,2446,7,13,
	0,0,2446,2447,7,7,0,0,2447,18,1,0,0,0,2448,2449,7,3,0,0,2449,2450,7,12,
	0,0,2450,2451,7,4,0,0,2451,20,1,0,0,0,2452,2453,7,3,0,0,2453,2454,7,8,0,
	0,2454,2455,7,8,0,0,2455,2456,7,3,0,0,2456,2457,7,10,0,0,2457,22,1,0,0,
	0,2458,2459,7,3,0,0,2459,2460,7,11,0,0,2460,24,1,0,0,0,2461,2462,7,3,0,
	0,2462,2463,7,11,0,0,2463,2464,7,14,0,0,2464,26,1,0,0,0,2465,2466,7,3,0,
	0,2466,2467,7,6,0,0,2467,2468,7,6,0,0,2468,2469,7,8,0,0,2469,2470,7,15,
	0,0,2470,2471,7,16,0,0,2471,2472,7,17,0,0,2472,2473,7,6,0,0,2473,2474,7,
	7,0,0,2474,28,1,0,0,0,2475,2476,7,16,0,0,2476,2477,7,7,0,0,2477,2478,7,
	18,0,0,2478,2479,7,19,0,0,2479,2480,7,8,0,0,2480,2481,7,7,0,0,2481,30,1,
	0,0,0,2482,2483,7,16,0,0,2483,2484,7,7,0,0,2484,2485,7,6,0,0,2485,2486,
	7,9,0,0,2486,2487,7,7,0,0,2487,2488,7,7,0,0,2488,2489,7,12,0,0,2489,32,
	1,0,0,0,2490,2491,7,16,0,0,2491,2492,7,19,0,0,2492,2493,7,6,0,0,2493,2494,
	7,20,0,0,2494,34,1,0,0,0,2495,2496,7,16,0,0,2496,2497,7,17,0,0,2497,2498,
	7,14,0,0,2498,2499,7,21,0,0,2499,2500,7,7,0,0,2500,2501,7,6,0,0,2501,2502,
	7,11,0,0,2502,36,1,0,0,0,2503,2504,7,16,0,0,2504,2505,7,10,0,0,2505,38,
	1,0,0,0,2506,2507,7,14,0,0,2507,2508,7,3,0,0,2508,2509,7,5,0,0,2509,2510,
	7,5,0,0,2510,40,1,0,0,0,2511,2512,7,14,0,0,2512,2513,7,3,0,0,2513,2514,
	7,11,0,0,2514,2515,7,14,0,0,2515,2516,7,3,0,0,2516,2517,7,4,0,0,2517,2518,
	7,7,0,0,2518,42,1,0,0,0,2519,2520,7,14,0,0,2520,2521,7,3,0,0,2521,2522,
	7,11,0,0,2522,2523,7,7,0,0,2523,44,1,0,0,0,2524,2525,7,14,0,0,2525,2526,
	7,3,0,0,2526,2527,7,11,0,0,2527,2528,7,6,0,0,2528,46,1,0,0,0,2529,2530,
	7,14,0,0,2530,2531,7,20,0,0,2531,2532,7,3,0,0,2532,2533,7,12,0,0,2533,2534,
	7,22,0,0,2534,2535,7,7,0,0,2535,48,1,0,0,0,2536,2537,7,14,0,0,2537,2538,
	7,20,0,0,2538,2539,7,3,0,0,2539,2540,7,8,0,0,2540,2541,7,3,0,0,2541,2542,
	7,14,0,0,2542,2543,7,6,0,0,2543,2544,7,7,0,0,2544,2545,7,8,0,0,2545,50,
	1,0,0,0,2546,2547,7,14,0,0,2547,2548,7,20,0,0,2548,2549,7,7,0,0,2549,2550,
	7,14,0,0,2550,2551,7,21,0,0,2551,52,1,0,0,0,2552,2553,7,14,0,0,2553,2554,
	7,19,0,0,2554,2555,7,5,0,0,2555,2556,7,5,0,0,2556,2557,7,3,0,0,2557,2558,
	7,6,0,0,2558,2559,7,7,0,0,2559,54,1,0,0,0,2560,2561,7,14,0,0,2561,2562,
	7,19,0,0,2562,2563,7,5,0,0,2563,2564,7,17,0,0,2564,2565,7,23,0,0,2565,2566,
	7,12,0,0,2566,56,1,0,0,0,2567,2568,7,14,0,0,2568,2569,7,19,0,0,2569,2570,
	7,12,0,0,2570,2571,7,4,0,0,2571,2572,7,15,0,0,2572,2573,7,6,0,0,2573,2574,
	7,15,0,0,2574,2575,7,19,0,0,2575,2576,7,12,0,0,2576,58,1,0,0,0,2577,2578,
	7,14,0,0,2578,2579,7,19,0,0,2579,2580,7,12,0,0,2580,2581,7,11,0,0,2581,
	2582,7,6,0,0,2582,2583,7,8,0,0,2583,2584,7,3,0,0,2584,2585,7,15,0,0,2585,
	2586,7,12,0,0,2586,2587,7,6,0,0,2587,60,1,0,0,0,2588,2589,7,14,0,0,2589,
	2590,7,19,0,0,2590,2591,7,12,0,0,2591,2592,7,6,0,0,2592,2593,7,15,0,0,2593,
	2594,7,12,0,0,2594,2595,7,17,0,0,2595,2596,7,7,0,0,2596,62,1,0,0,0,2597,
	2598,7,14,0,0,2598,2599,7,19,0,0,2599,2600,7,12,0,0,2600,2601,7,24,0,0,
	2601,2602,7,7,0,0,2602,2603,7,8,0,0,2603,2604,7,6,0,0,2604,64,1,0,0,0,2605,
	2606,7,14,0,0,2606,2607,7,8,0,0,2607,2608,7,7,0,0,2608,2609,7,3,0,0,2609,
	2610,7,6,0,0,2610,2611,7,7,0,0,2611,66,1,0,0,0,2612,2613,7,14,0,0,2613,
	2614,7,8,0,0,2614,2615,7,19,0,0,2615,2616,7,11,0,0,2616,2617,7,11,0,0,2617,
	68,1,0,0,0,2618,2619,7,14,0,0,2619,2620,7,17,0,0,2620,2621,7,8,0,0,2621,
	2622,7,8,0,0,2622,2623,7,7,0,0,2623,2624,7,12,0,0,2624,2625,7,6,0,0,2625,
	70,1,0,0,0,2626,2627,7,14,0,0,2627,2628,7,17,0,0,2628,2629,7,8,0,0,2629,
	2630,7,8,0,0,2630,2631,7,7,0,0,2631,2632,7,12,0,0,2632,2633,7,6,0,0,2633,
	2634,5,95,0,0,2634,2635,7,8,0,0,2635,2636,7,19,0,0,2636,2637,7,5,0,0,2637,
	2638,7,7,0,0,2638,72,1,0,0,0,2639,2640,7,14,0,0,2640,2641,7,17,0,0,2641,
	2642,7,8,0,0,2642,2643,7,8,0,0,2643,2644,7,7,0,0,2644,2645,7,12,0,0,2645,
	2646,7,6,0,0,2646,2647,5,95,0,0,2647,2648,7,17,0,0,2648,2649,7,11,0,0,2649,
	2650,7,7,0,0,2650,2651,7,8,0,0,2651,74,1,0,0,0,2652,2653,7,14,0,0,2653,
	2654,7,17,0,0,2654,2655,7,8,0,0,2655,2656,7,11,0,0,2656,2657,7,19,0,0,2657,
	2658,7,8,0,0,2658,76,1,0,0,0,2659,2660,7,4,0,0,2660,2661,7,3,0,0,2661,2662,
	7,6,0,0,2662,2663,7,3,0,0,2663,2664,7,16,0,0,2664,2665,7,3,0,0,2665,2666,
	7,11,0,0,2666,2667,7,7,0,0,2667,78,1,0,0,0,2668,2669,7,4,0,0,2669,2670,
	7,3,0,0,2670,2671,7,6,0,0,2671,2672,7,3,0,0,2672,2673,7,16,0,0,2673,2674,
	7,3,0,0,2674,2675,7,11,0,0,2675,2676,7,7,0,0,2676,2677,7,11,0,0,2677,80,
	1,0,0,0,2678,2679,7,4,0,0,2679,2680,7,7,0,0,2680,2681,7,14,0,0,2681,2682,
	7,5,0,0,2682,2683,7,3,0,0,2683,2684,7,8,0,0,2684,2685,7,7,0,0,2685,82,1,
	0,0,0,2686,2687,7,4,0,0,2687,2688,7,7,0,0,2688,2689,7,18,0,0,2689,2690,
	7,3,0,0,2690,2691,7,17,0,0,2691,2692,7,5,0,0,2692,2693,7,6,0,0,2693,84,
	1,0,0,0,2694,2695,7,4,0,0,2695,2696,7,7,0,0,2696,2697,7,5,0,0,2697,2698,
	7,3,0,0,2698,2699,7,10,0,0,2699,2700,7,7,0,0,2700,2701,7,4,0,0,2701,86,
	1,0,0,0,2702,2703,7,4,0,0,2703,2704,7,7,0,0,2704,2705,7,5,0,0,2705,2706,
	7,7,0,0,2706,2707,7,6,0,0,2707,2708,7,7,0,0,2708,88,1,0,0,0,2709,2710,7,
	4,0,0,2710,2711,7,7,0,0,2711,2712,7,11,0,0,2712,2713,7,14,0,0,2713,90,1,
	0,0,0,2714,2715,7,4,0,0,2715,2716,7,7,0,0,2716,2717,7,11,0,0,2717,2718,
	7,14,0,0,2718,2719,7,8,0,0,2719,2720,7,15,0,0,2720,2721,7,16,0,0,2721,2722,
	7,7,0,0,2722,92,1,0,0,0,2723,2724,7,4,0,0,2724,2725,7,7,0,0,2725,2726,7,
	6,0,0,2726,2727,7,7,0,0,2727,2728,7,8,0,0,2728,2729,7,23,0,0,2729,2730,
	7,15,0,0,2730,2731,7,12,0,0,2731,2732,7,15,0,0,2732,2733,7,11,0,0,2733,
	2734,7,6,0,0,2734,2735,7,15,0,0,2735,2736,7,14,0,0,2736,94,1,0,0,0,2737,
	2738,7,4,0,0,2738,2739,7,15,0,0,2739,2740,7,3,0,0,2740,2741,7,22,0,0,2741,
	2742,7,12,0,0,2742,2743,7,19,0,0,2743,2744,7,11,0,0,2744,2745,7,6,0,0,2745,
	2746,7,15,0,0,2746,2747,7,14,0,0,2747,2748,7,11,0,0,2748,96,1,0,0,0,2749,
	2750,7,4,0,0,2750,2751,7,15,0,0,2751,2752,7,11,0,0,2752,2753,7,6,0,0,2753,
	2754,7,15,0,0,2754,2755,7,12,0,0,2755,2756,7,14,0,0,2756,2757,7,6,0,0,2757,
	98,1,0,0,0,2758,2759,7,4,0,0,2759,2760,7,15,0,0,2760,2761,7,11,0,0,2761,
	2762,7,6,0,0,2762,2763,7,15,0,0,2763,2764,7,12,0,0,2764,2765,7,14,0,0,2765,
	2766,7,6,0,0,2766,2767,7,8,0,0,2767,2768,7,19,0,0,2768,2769,7,9,0,0,2769,
	100,1,0,0,0,2770,2771,7,4,0,0,2771,2772,7,8,0,0,2772,2773,7,19,0,0,2773,
	2774,7,25,0,0,2774,102,1,0,0,0,2775,2776,7,7,0,0,2776,2777,7,3,0,0,2777,
	2778,7,14,0,0,2778,2779,7,20,0,0,2779,104,1,0,0,0,2780,2781,7,7,0,0,2781,
	2782,7,5,0,0,2782,2783,7,11,0,0,2783,2784,7,7,0,0,2784,106,1,0,0,0,2785,
	2786,7,7,0,0,2786,2787,7,5,0,0,2787,2788,7,11,0,0,2788,2789,7,7,0,0,2789,
	2790,7,15,0,0,2790,2791,7,18,0,0,2791,108,1,0,0,0,2792,2793,7,7,0,0,2793,
	2794,7,23,0,0,2794,2795,7,25,0,0,2795,2796,7,6,0,0,2796,2797,7,10,0,0,2797,
	110,1,0,0,0,2798,2799,7,7,0,0,2799,2800,7,12,0,0,2800,2801,7,14,0,0,2801,
	2802,7,5,0,0,2802,2803,7,19,0,0,2803,2804,7,11,0,0,2804,2805,7,7,0,0,2805,
	2806,7,4,0,0,2806,112,1,0,0,0,2807,2808,7,7,0,0,2808,2809,7,12,0,0,2809,
	2810,7,18,0,0,2810,2811,7,19,0,0,2811,2812,7,8,0,0,2812,2813,7,14,0,0,2813,
	2814,7,7,0,0,2814,2815,7,4,0,0,2815,114,1,0,0,0,2816,2817,7,7,0,0,2817,
	2818,7,11,0,0,2818,2819,7,14,0,0,2819,2820,7,3,0,0,2820,2821,7,25,0,0,2821,
	2822,7,7,0,0,2822,2823,7,4,0,0,2823,116,1,0,0,0,2824,2825,7,7,0,0,2825,
	2826,7,26,0,0,2826,2827,7,14,0,0,2827,2828,7,7,0,0,2828,2829,7,25,0,0,2829,
	2830,7,6,0,0,2830,118,1,0,0,0,2831,2832,7,7,0,0,2832,2833,7,26,0,0,2833,
	2834,7,15,0,0,2834,2835,7,11,0,0,2835,2836,7,6,0,0,2836,2837,7,11,0,0,2837,
	120,1,0,0,0,2838,2839,7,7,0,0,2839,2840,7,26,0,0,2840,2841,7,15,0,0,2841,
	2842,7,6,0,0,2842,122,1,0,0,0,2843,2844,7,7,0,0,2844,2845,7,26,0,0,2845,
	2846,7,25,0,0,2846,2847,7,5,0,0,2847,2848,7,3,0,0,2848,2849,7,15,0,0,2849,
	2850,7,12,0,0,2850,124,1,0,0,0,2851,2852,7,18,0,0,2852,2853,7,3,0,0,2853,
	2854,7,5,0,0,2854,2855,7,11,0,0,2855,2856,7,7,0,0,2856,126,1,0,0,0,2857,
	2858,7,18,0,0,2858,2859,7,7,0,0,2859,2860,7,6,0,0,2860,2861,7,14,0,0,2861,
	2862,7,20,0,0,2862,128,1,0,0,0,2863,2864,7,18,0,0,2864,2865,7,19,0,0,2865,
	2866,7,8,0,0,2866,130,1,0,0,0,2867,2868,7,18,0,0,2868,2869,7,19,0,0,2869,
	2870,7,8,0,0,2870,2871,7,14,0,0,2871,2872,7,7,0,0,2872,132,1,0,0,0,2873,
	2874,7,18,0,0,2874,2875,7,19,0,0,2875,2876,7,8,0,0,2876,2877,7,7,0,0,2877,
	2878,7,15,0,0,2878,2879,7,22,0,0,2879,2880,7,12,0,0,2880,134,1,0,0,0,2881,
	2882,7,18,0,0,2882,2883,7,8,0,0,2883,2884,7,19,0,0,2884,2885,7,23,0,0,2885,
	136,1,0,0,0,2886,2887,7,18,0,0,2887,2888,7,17,0,0,2888,2889,7,5,0,0,2889,
	2890,7,5,0,0,2890,2891,7,6,0,0,2891,2892,7,7,0,0,2892,2893,7,26,0,0,2893,
	2894,7,6,0,0,2894,138,1,0,0,0,2895,2896,7,22,0,0,2896,2897,7,7,0,0,2897,
	2898,7,12,0,0,2898,2899,7,7,0,0,2899,2900,7,8,0,0,2900,2901,7,3,0,0,2901,
	2902,7,6,0,0,2902,2903,7,7,0,0,2903,2904,7,4,0,0,2904,140,1,0,0,0,2905,
	2906,7,22,0,0,2906,2907,7,7,0,0,2907,2908,7,6,0,0,2908,142,1,0,0,0,2909,
	2910,7,22,0,0,2910,2911,7,8,0,0,2911,2912,7,3,0,0,2912,2913,7,12,0,0,2913,
	2914,7,6,0,0,2914,144,1,0,0,0,2915,2916,7,22,0,0,2916,2917,7,8,0,0,2917,
	2918,7,19,0,0,2918,2919,7,17,0,0,2919,2920,7,25,0,0,2920,146,1,0,0,0,2921,
	2922,7,20,0,0,2922,2923,7,3,0,0,2923,2924,7,24,0,0,2924,2925,7,15,0,0,2925,
	2926,7,12,0,0,2926,2927,7,22,0,0,2927,148,1,0,0,0,2928,2929,7,20,0,0,2929,
	2930,7,15,0,0,2930,2931,7,22,0,0,2931,2932,7,20,0,0,2932,2933,5,95,0,0,
	2933,2934,7,25,0,0,2934,2935,7,8,0,0,2935,2936,7,15,0,0,2936,2937,7,19,
	0,0,2937,2938,7,8,0,0,2938,2939,7,15,0,0,2939,2940,7,6,0,0,2940,2941,7,
	10,0,0,2941,150,1,0,0,0,2942,2943,7,20,0,0,2943,2944,7,15,0,0,2944,2945,
	7,11,0,0,2945,2946,7,6,0,0,2946,2947,7,19,0,0,2947,2948,7,22,0,0,2948,2949,
	7,8,0,0,2949,2950,7,3,0,0,2950,2951,7,23,0,0,2951,152,1,0,0,0,2952,2953,
	7,15,0,0,2953,2954,7,18,0,0,2954,154,1,0,0,0,2955,2956,7,15,0,0,2956,2957,
	7,22,0,0,2957,2958,7,12,0,0,2958,2959,7,19,0,0,2959,2960,7,8,0,0,2960,2961,
	7,7,0,0,2961,156,1,0,0,0,2962,2963,7,15,0,0,2963,2964,7,22,0,0,2964,2965,
	7,12,0,0,2965,2966,7,19,0,0,2966,2967,7,8,0,0,2967,2968,7,7,0,0,2968,2969,
	7,4,0,0,2969,158,1,0,0,0,2970,2971,7,15,0,0,2971,2972,7,12,0,0,2972,160,
	1,0,0,0,2973,2974,7,15,0,0,2974,2975,7,12,0,0,2975,2976,7,4,0,0,2976,2977,
	7,7,0,0,2977,2978,7,26,0,0,2978,162,1,0,0,0,2979,2980,7,15,0,0,2980,2981,
	7,12,0,0,2981,2982,7,18,0,0,2982,2983,7,15,0,0,2983,2984,7,5,0,0,2984,2985,
	7,7,0,0,2985,164,1,0,0,0,2986,2987,7,15,0,0,2987,2988,7,12,0,0,2988,2989,
	7,12,0,0,2989,2990,7,7,0,0,2990,2991,7,8,0,0,2991,166,1,0,0,0,2992,2993,
	7,15,0,0,2993,2994,7,12,0,0,2994,2995,7,19,0,0,2995,2996,7,17,0,0,2996,
	2997,7,6,0,0,2997,168,1,0,0,0,2998,2999,7,15,0,0,2999,3000,7,12,0,0,3000,
	3001,7,11,0,0,3001,3002,7,7,0,0,3002,3003,7,8,0,0,3003,3004,7,6,0,0,3004,
	170,1,0,0,0,3005,3006,7,15,0,0,3006,3007,7,12,0,0,3007,3008,7,6,0,0,3008,
	3009,7,7,0,0,3009,3010,7,8,0,0,3010,3011,7,24,0,0,3011,3012,7,3,0,0,3012,
	3013,7,5,0,0,3013,172,1,0,0,0,3014,3015,7,15,0,0,3015,3016,7,12,0,0,3016,
	3017,7,6,0,0,3017,3018,7,19,0,0,3018,174,1,0,0,0,3019,3020,7,15,0,0,3020,
	3021,7,11,0,0,3021,176,1,0,0,0,3022,3023,7,15,0,0,3023,3024,7,6,0,0,3024,
	3025,7,7,0,0,3025,3026,7,8,0,0,3026,3027,7,3,0,0,3027,3028,7,6,0,0,3028,
	3029,7,7,0,0,3029,178,1,0,0,0,3030,3031,7,27,0,0,3031,3032,7,19,0,0,3032,
	3033,7,15,0,0,3033,3034,7,12,0,0,3034,180,1,0,0,0,3035,3036,7,21,0,0,3036,
	3037,7,7,0,0,3037,3038,7,10,0,0,3038,182,1,0,0,0,3039,3040,7,21,0,0,3040,
	3041,7,7,0,0,3041,3042,7,10,0,0,3042,3043,7,11,0,0,3043,184,1,0,0,0,3044,
	3045,7,21,0,0,3045,3046,7,15,0,0,3046,3047,7,5,0,0,3047,3048,7,5,0,0,3048,
	186,1,0,0,0,3049,3050,7,5,0,0,3050,3051,7,3,0,0,3051,3052,7,6,0,0,3052,
	3053,7,7,0,0,3053,3054,7,8,0,0,3054,3055,7,3,0,0,3055,3056,7,5,0,0,3056,
	188,1,0,0,0,3057,3058,7,5,0,0,3058,3059,7,7,0,0,3059,3060,7,3,0,0,3060,
	3061,7,4,0,0,3061,3062,7,15,0,0,3062,3063,7,12,0,0,3063,3064,7,22,0,0,3064,
	190,1,0,0,0,3065,3066,7,5,0,0,3066,3067,7,7,0,0,3067,3068,7,3,0,0,3068,
	3069,7,24,0,0,3069,3070,7,7,0,0,3070,192,1,0,0,0,3071,3072,7,5,0,0,3072,
	3073,7,7,0,0,3073,3074,7,18,0,0,3074,3075,7,6,0,0,3075,194,1,0,0,0,3076,
	3077,7,5,0,0,3077,3078,7,15,0,0,3078,3079,7,21,0,0,3079,3080,7,7,0,0,3080,
	196,1,0,0,0,3081,3082,7,5,0,0,3082,3083,7,15,0,0,3083,3084,7,23,0,0,3084,
	3085,7,15,0,0,3085,3086,7,6,0,0,3086,198,1,0,0,0,3087,3088,7,5,0,0,3088,
	3089,7,15,0,0,3089,3090,7,12,0,0,3090,3091,7,7,0,0,3091,3092,7,3,0,0,3092,
	3093,7,8,0,0,3093,200,1,0,0,0,3094,3095,7,5,0,0,3095,3096,7,15,0,0,3096,
	3097,7,12,0,0,3097,3098,7,7,0,0,3098,3099,7,11,0,0,3099,202,1,0,0,0,3100,
	3101,7,5,0,0,3101,3102,7,19,0,0,3102,3103,7,3,0,0,3103,3104,7,4,0,0,3104,
	204,1,0,0,0,3105,3106,7,5,0,0,3106,3107,7,19,0,0,3107,3108,7,14,0,0,3108,
	3109,7,21,0,0,3109,206,1,0,0,0,3110,3111,7,5,0,0,3111,3112,7,19,0,0,3112,
	3113,7,14,0,0,3113,3114,7,21,0,0,3114,3115,7,7,0,0,3115,3116,7,4,0,0,3116,
	208,1,0,0,0,3117,3118,7,5,0,0,3118,3119,7,19,0,0,3119,3120,7,19,0,0,3120,
	3121,7,25,0,0,3121,210,1,0,0,0,3122,3123,7,5,0,0,3123,3124,7,19,0,0,3124,
	3125,7,9,0,0,3125,3126,5,95,0,0,3126,3127,7,25,0,0,3127,3128,7,8,0,0,3128,
	3129,7,15,0,0,3129,3130,7,19,0,0,3130,3131,7,8,0,0,3131,3132,7,15,0,0,3132,
	3133,7,6,0,0,3133,3134,7,10,0,0,3134,212,1,0,0,0,3135,3136,7,23,0,0,3136,
	3137,7,3,0,0,3137,3138,7,11,0,0,3138,3139,7,6,0,0,3139,3140,7,7,0,0,3140,
	3141,7,8,0,0,3141,3142,5,95,0,0,3142,3143,7,16,0,0,3143,3144,7,15,0,0,3144,
	3145,7,12,0,0,3145,3146,7,4,0,0,3146,214,1,0,0,0,3147,3148,7,23,0,0,3148,
	3149,7,3,0,0,3149,3150,7,11,0,0,3150,3151,7,6,0,0,3151,3152,7,7,0,0,3152,
	3153,7,8,0,0,3153,3154,5,95,0,0,3154,3155,7,11,0,0,3155,3156,7,11,0,0,3156,
	3157,7,5,0,0,3157,3158,5,95,0,0,3158,3159,7,24,0,0,3159,3160,7,7,0,0,3160,
	3161,7,8,0,0,3161,3162,7,15,0,0,3162,3163,7,18,0,0,3163,3164,7,10,0,0,3164,
	3165,5,95,0,0,3165,3166,7,11,0,0,3166,3167,7,7,0,0,3167,3168,7,8,0,0,3168,
	3169,7,24,0,0,3169,3170,7,7,0,0,3170,3171,7,8,0,0,3171,3172,5,95,0,0,3172,
	3173,7,14,0,0,3173,3174,7,7,0,0,3174,3175,7,8,0,0,3175,3176,7,6,0,0,3176,
	216,1,0,0,0,3177,3178,7,23,0,0,3178,3179,7,3,0,0,3179,3180,7,6,0,0,3180,
	3181,7,14,0,0,3181,3182,7,20,0,0,3182,218,1,0,0,0,3183,3184,7,23,0,0,3184,
	3185,7,3,0,0,3185,3186,7,26,0,0,3186,3187,7,24,0,0,3187,3188,7,3,0,0,3188,
	3189,7,5,0,0,3189,3190,7,17,0,0,3190,3191,7,7,0,0,3191,220,1,0,0,0,3192,
	3193,7,23,0,0,3193,3194,7,15,0,0,3194,3195,7,12,0,0,3195,3196,7,24,0,0,
	3196,3197,7,3,0,0,3197,3198,7,5,0,0,3198,3199,7,17,0,0,3199,3200,7,7,0,
	0,3200,222,1,0,0,0,3201,3202,7,23,0,0,3202,3203,7,19,0,0,3203,3204,7,4,
	0,0,3204,3205,7,15,0,0,3205,3206,7,18,0,0,3206,3207,7,15,0,0,3207,3208,
	7,7,0,0,3208,3209,7,11,0,0,3209,224,1,0,0,0,3210,3211,7,12,0,0,3211,3212,
	7,3,0,0,3212,3213,7,6,0,0,3213,3214,7,17,0,0,3214,3215,7,8,0,0,3215,3216,
	7,3,0,0,3216,3217,7,5,0,0,3217,226,1,0,0,0,3218,3219,7,12,0,0,3219,3220,
	7,19,0,0,3220,3221,7,6,0,0,3221,228,1,0,0,0,3222,3223,7,12,0,0,3223,3224,
	7,19,0,0,3224,3225,5,95,0,0,3225,3226,7,9,0,0,3226,3227,7,8,0,0,3227,3228,
	7,15,0,0,3228,3229,7,6,0,0,3229,3230,7,7,0,0,3230,3231,5,95,0,0,3231,3232,
	7,6,0,0,3232,3233,7,19,0,0,3233,3234,5,95,0,0,3234,3235,7,16,0,0,3235,3236,
	7,15,0,0,3236,3237,7,12,0,0,3237,3238,7,5,0,0,3238,3239,7,19,0,0,3239,3240,
	7,22,0,0,3240,230,1,0,0,0,3241,3242,7,12,0,0,3242,3243,7,17,0,0,3243,3244,
	7,5,0,0,3244,3245,7,5,0,0,3245,232,1,0,0,0,3246,3247,7,12,0,0,3247,3248,
	7,17,0,0,3248,3249,7,23,0,0,3249,3250,7,16,0,0,3250,3251,7,7,0,0,3251,3252,
	7,8,0,0,3252,234,1,0,0,0,3253,3254,7,19,0,0,3254,3255,7,12,0,0,3255,236,
	1,0,0,0,3256,3257,7,19,0,0,3257,3258,7,25,0,0,3258,3259,7,6,0,0,3259,3260,
	7,15,0,0,3260,3261,7,23,0,0,3261,3262,7,15,0,0,3262,3263,7,13,0,0,3263,
	3264,7,7,0,0,3264,238,1,0,0,0,3265,3266,7,19,0,0,3266,3267,7,25,0,0,3267,
	3268,7,6,0,0,3268,3269,7,15,0,0,3269,3270,7,19,0,0,3270,3271,7,12,0,0,3271,
	240,1,0,0,0,3272,3273,7,19,0,0,3273,3274,7,25,0,0,3274,3275,7,6,0,0,3275,
	3276,7,15,0,0,3276,3277,7,19,0,0,3277,3278,7,12,0,0,3278,3279,7,3,0,0,3279,
	3280,7,5,0,0,3280,242,1,0,0,0,3281,3282,7,19,0,0,3282,3283,7,25,0,0,3283,
	3284,7,6,0,0,3284,3285,7,15,0,0,3285,3286,7,19,0,0,3286,3287,7,12,0,0,3287,
	3288,7,3,0,0,3288,3289,7,5,0,0,3289,3290,7,5,0,0,3290,3291,7,10,0,0,3291,
	244,1,0,0,0,3292,3293,7,19,0,0,3293,3294,7,8,0,0,3294,246,1,0,0,0,3295,
	3296,7,19,0,0,3296,3297,7,8,0,0,3297,3298,7,4,0,0,3298,3299,7,7,0,0,3299,
	3300,7,8,0,0,3300,248,1,0,0,0,3301,3302,7,19,0,0,3302,3303,7,17,0,0,3303,
	3304,7,6,0,0,3304,250,1,0,0,0,3305,3306,7,19,0,0,3306,3307,7,17,0,0,3307,
	3308,7,6,0,0,3308,3309,7,7,0,0,3309,3310,7,8,0,0,3310,252,1,0,0,0,3311,
	3312,7,19,0,0,3312,3313,7,17,0,0,3313,3314,7,6,0,0,3314,3315,7,18,0,0,3315,
	3316,7,15,0,0,3316,3317,7,5,0,0,3317,3318,7,7,0,0,3318,254,1,0,0,0,3319,
	3320,7,19,0,0,3320,3321,7,24,0,0,3321,3322,7,7,0,0,3322,3323,7,8,0,0,3323,
	256,1,0,0,0,3324,3325,7,25,0,0,3325,3326,7,3,0,0,3326,3327,7,8,0,0,3327,
	3328,7,6,0,0,3328,3329,7,15,0,0,3329,3330,7,6,0,0,3330,3331,7,15,0,0,3331,
	3332,7,19,0,0,3332,3333,7,12,0,0,3333,258,1,0,0,0,3334,3335,7,25,0,0,3335,
	3336,7,8,0,0,3336,3337,7,15,0,0,3337,3338,7,23,0,0,3338,3339,7,3,0,0,3339,
	3340,7,8,0,0,3340,3341,7,10,0,0,3341,260,1,0,0,0,3342,3343,7,25,0,0,3343,
	3344,7,8,0,0,3344,3345,7,19,0,0,3345,3346,7,14,0,0,3346,3347,7,7,0,0,3347,
	3348,7,4,0,0,3348,3349,7,17,0,0,3349,3350,7,8,0,0,3350,3351,7,7,0,0,3351,
	262,1,0,0,0,3352,3353,7,25,0,0,3353,3354,7,17,0,0,3354,3355,7,8,0,0,3355,
	3356,7,22,0,0,3356,3357,7,7,0,0,3357,264,1,0,0,0,3358,3359,7,8,0,0,3359,
	3360,7,3,0,0,3360,3361,7,12,0,0,3361,3362,7,22,0,0,3362,3363,7,7,0,0,3363,
	266,1,0,0,0,3364,3365,7,8,0,0,3365,3366,7,7,0,0,3366,3367,7,3,0,0,3367,
	3368,7,4,0,0,3368,268,1,0,0,0,3369,3370,7,8,0,0,3370,3371,7,7,0,0,3371,
	3372,7,3,0,0,3372,3373,7,4,0,0,3373,3374,7,11,0,0,3374,270,1,0,0,0,3375,
	3376,7,8,0,0,3376,3377,7,7,0,0,3377,3378,7,18,0,0,3378,3379,7,7,0,0,3379,
	3380,7,8,0,0,3380,3381,7,7,0,0,3381,3382,7,12,0,0,3382,3383,7,14,0,0,3383,
	3384,7,7,0,0,3384,3385,7,11,0,0,3385,272,1,0,0,0,3386,3387,7,8,0,0,3387,
	3388,7,7,0,0,3388,3389,7,22,0,0,3389,3390,7,7,0,0,3390,3391,7,26,0,0,3391,
	3392,7,25,0,0,3392,274,1,0,0,0,3393,3394,7,8,0,0,3394,3395,7,7,0,0,3395,
	3396,7,5,0,0,3396,3397,7,7,0,0,3397,3398,7,3,0,0,3398,3399,7,11,0,0,3399,
	3400,7,7,0,0,3400,276,1,0,0,0,3401,3402,7,8,0,0,3402,3403,7,7,0,0,3403,
	3404,7,12,0,0,3404,3405,7,3,0,0,3405,3406,7,23,0,0,3406,3407,7,7,0,0,3407,
	278,1,0,0,0,3408,3409,7,8,0,0,3409,3410,7,7,0,0,3410,3411,7,25,0,0,3411,
	3412,7,7,0,0,3412,3413,7,3,0,0,3413,3414,7,6,0,0,3414,280,1,0,0,0,3415,
	3416,7,8,0,0,3416,3417,7,7,0,0,3417,3418,7,25,0,0,3418,3419,7,5,0,0,3419,
	3420,7,3,0,0,3420,3421,7,14,0,0,3421,3422,7,7,0,0,3422,282,1,0,0,0,3423,
	3424,7,8,0,0,3424,3425,7,7,0,0,3425,3426,7,28,0,0,3426,3427,7,17,0,0,3427,
	3428,7,15,0,0,3428,3429,7,8,0,0,3429,3430,7,7,0,0,3430,284,1,0,0,0,3431,
	3432,7,8,0,0,3432,3433,7,7,0,0,3433,3434,7,11,0,0,3434,3435,7,15,0,0,3435,
	3436,7,22,0,0,3436,3437,7,12,0,0,3437,3438,7,3,0,0,3438,3439,7,5,0,0,3439,
	286,1,0,0,0,3440,3441,7,8,0,0,3441,3442,7,7,0,0,3442,3443,7,11,0,0,3443,
	3444,7,6,0,0,3444,3445,7,8,0,0,3445,3446,7,15,0,0,3446,3447,7,14,0,0,3447,
	3448,7,6,0,0,3448,288,1,0,0,0,3449,3450,7,8,0,0,3450,3451,7,7,0,0,3451,
	3452,7,6,0,0,3452,3453,7,3,0,0,3453,3454,7,15,0,0,3454,3455,7,12,0,0,3455,
	290,1,0,0,0,3456,3457,7,8,0,0,3457,3458,7,7,0,0,3458,3459,7,6,0,0,3459,
	3460,7,17,0,0,3460,3461,7,8,0,0,3461,3462,7,12,0,0,3462,292,1,0,0,0,3463,
	3464,7,8,0,0,3464,3465,7,7,0,0,3465,3466,7,24,0,0,3466,3467,7,19,0,0,3467,
	3468,7,21,0,0,3468,3469,7,7,0,0,3469,294,1,0,0,0,3470,3471,7,8,0,0,3471,
	3472,7,15,0,0,3472,3473,7,22,0,0,3473,3474,7,20,0,0,3474,3475,7,6,0,0,3475,
	296,1,0,0,0,3476,3477,7,8,0,0,3477,3478,7,5,0,0,3478,3479,7,15,0,0,3479,
	3480,7,21,0,0,3480,3481,7,7,0,0,3481,298,1,0,0,0,3482,3483,7,11,0,0,3483,
	3484,7,14,0,0,3484,3485,7,20,0,0,3485,3486,7,7,0,0,3486,3487,7,23,0,0,3487,
	3488,7,3,0,0,3488,300,1,0,0,0,3489,3490,7,11,0,0,3490,3491,7,14,0,0,3491,
	3492,7,20,0,0,3492,3493,7,7,0,0,3493,3494,7,23,0,0,3494,3495,7,3,0,0,3495,
	3496,7,11,0,0,3496,302,1,0,0,0,3497,3498,7,11,0,0,3498,3499,7,7,0,0,3499,
	3500,7,5,0,0,3500,3501,7,7,0,0,3501,3502,7,14,0,0,3502,3503,7,6,0,0,3503,
	304,1,0,0,0,3504,3505,7,11,0,0,3505,3506,7,7,0,0,3506,3507,7,6,0,0,3507,
	306,1,0,0,0,3508,3509,7,11,0,0,3509,3510,7,7,0,0,3510,3511,7,25,0,0,3511,
	3512,7,3,0,0,3512,3513,7,8,0,0,3513,3514,7,3,0,0,3514,3515,7,6,0,0,3515,
	3516,7,19,0,0,3516,3517,7,8,0,0,3517,308,1,0,0,0,3518,3519,7,11,0,0,3519,
	3520,7,20,0,0,3520,3521,7,19,0,0,3521,3522,7,9,0,0,3522,310,1,0,0,0,3523,
	3524,7,11,0,0,3524,3525,7,15,0,0,3525,3526,7,22,0,0,3526,3527,7,12,0,0,
	3527,3528,7,3,0,0,3528,3529,7,5,0,0,3529,312,1,0,0,0,3530,3531,7,11,0,0,
	3531,3532,7,21,0,0,3532,3533,7,15,0,0,3533,3534,7,25,0,0,3534,314,1,0,0,
	0,3535,3536,7,11,0,0,3536,3537,7,21,0,0,3537,3538,7,15,0,0,3538,3539,7,
	25,0,0,3539,3540,5,95,0,0,3540,3541,7,28,0,0,3541,3542,7,17,0,0,3542,3543,
	7,7,0,0,3543,3544,7,8,0,0,3544,3545,7,10,0,0,3545,3546,5,95,0,0,3546,3547,
	7,8,0,0,3547,3548,7,7,0,0,3548,3549,7,9,0,0,3549,3550,7,8,0,0,3550,3551,
	7,15,0,0,3551,3552,7,6,0,0,3552,3553,7,7,0,0,3553,316,1,0,0,0,3554,3555,
	7,11,0,0,3555,3556,7,25,0,0,3556,3557,7,3,0,0,3557,3558,7,6,0,0,3558,3559,
	7,15,0,0,3559,3560,7,3,0,0,3560,3561,7,5,0,0,3561,318,1,0,0,0,3562,3563,
	7,11,0,0,3563,3564,7,28,0,0,3564,3565,7,5,0,0,3565,320,1,0,0,0,3566,3567,
	7,11,0,0,3567,3568,7,28,0,0,3568,3569,7,5,0,0,3569,3570,7,7,0,0,3570,3571,
	7,26,0,0,3571,3572,7,14,0,0,3572,3573,7,7,0,0,3573,3574,7,25,0,0,3574,3575,
	7,6,0,0,3575,3576,7,15,0,0,3576,3577,7,19,0,0,3577,3578,7,12,0,0,3578,322,
	1,0,0,0,3579,3580,7,11,0,0,3580,3581,7,28,0,0,3581,3582,7,5,0,0,3582,3583,
	7,11,0,0,3583,3584,7,6,0,0,3584,3585,7,3,0,0,3585,3586,7,6,0,0,3586,3587,
	7,7,0,0,3587,324,1,0,0,0,3588,3589,7,11,0,0,3589,3590,7,28,0,0,3590,3591,
	7,5,0,0,3591,3592,7,9,0,0,3592,3593,7,3,0,0,3593,3594,7,8,0,0,3594,3595,
	7,12,0,0,3595,3596,7,15,0,0,3596,3597,7,12,0,0,3597,3598,7,22,0,0,3598,
	326,1,0,0,0,3599,3600,7,11,0,0,3600,3601,7,28,0,0,3601,3602,7,5,0,0,3602,
	3603,5,95,0,0,3603,3604,7,16,0,0,3604,3605,7,15,0,0,3605,3606,7,22,0,0,
	3606,3607,5,95,0,0,3607,3608,7,8,0,0,3608,3609,7,7,0,0,3609,3610,7,11,0,
	0,3610,3611,7,17,0,0,3611,3612,7,5,0,0,3612,3613,7,6,0,0,3613,328,1,0,0,
	0,3614,3615,7,11,0,0,3615,3616,7,28,0,0,3616,3617,7,5,0,0,3617,3618,5,95,
	0,0,3618,3619,7,14,0,0,3619,3620,7,3,0,0,3620,3621,7,5,0,0,3621,3622,7,
	14,0,0,3622,3623,5,95,0,0,3623,3624,7,18,0,0,3624,3625,7,19,0,0,3625,3626,
	7,17,0,0,3626,3627,7,12,0,0,3627,3628,7,4,0,0,3628,3629,5,95,0,0,3629,3630,
	7,8,0,0,3630,3631,7,19,0,0,3631,3632,7,9,0,0,3632,3633,7,11,0,0,3633,330,
	1,0,0,0,3634,3635,7,11,0,0,3635,3636,7,28,0,0,3636,3637,7,5,0,0,3637,3638,
	5,95,0,0,3638,3639,7,11,0,0,3639,3640,7,23,0,0,3640,3641,7,3,0,0,3641,3642,
	7,5,0,0,3642,3643,7,5,0,0,3643,3644,5,95,0,0,3644,3645,7,8,0,0,3645,3646,
	7,7,0,0,3646,3647,7,11,0,0,3647,3648,7,17,0,0,3648,3649,7,5,0,0,3649,3650,
	7,6,0,0,3650,332,1,0,0,0,3651,3652,7,11,0,0,3652,3653,7,11,0,0,3653,3654,
	7,5,0,0,3654,334,1,0,0,0,3655,3656,7,11,0,0,3656,3657,7,6,0,0,3657,3658,
	7,3,0,0,3658,3659,7,14,0,0,3659,3660,7,21,0,0,3660,3661,7,7,0,0,3661,3662,
	7,4,0,0,3662,336,1,0,0,0,3663,3664,7,11,0,0,3664,3665,7,6,0,0,3665,3666,
	7,3,0,0,3666,3667,7,8,0,0,3667,3668,7,6,0,0,3668,3669,7,15,0,0,3669,3670,
	7,12,0,0,3670,3671,7,22,0,0,3671,338,1,0,0,0,3672,3673,7,11,0,0,3673,3674,
	7,6,0,0,3674,3675,7,3,0,0,3675,3676,7,6,0,0,3676,3677,7,7,0,0,3677,3678,
	7,23,0,0,3678,3679,7,7,0,0,3679,3680,7,12,0,0,3680,3681,7,6,0,0,3681,340,
	1,0,0,0,3682,3683,7,11,0,0,3683,3684,7,6,0,0,3684,3685,7,8,0,0,3685,3686,
	7,3,0,0,3686,3687,7,15,0,0,3687,3688,7,22,0,0,3688,3689,7,20,0,0,3689,3690,
	7,6,0,0,3690,3691,5,95,0,0,3691,3692,7,27,0,0,3692,3693,7,19,0,0,3693,3694,
	7,15,0,0,3694,3695,7,12,0,0,3695,342,1,0,0,0,3696,3697,7,6,0,0,3697,3698,
	7,3,0,0,3698,3699,7,16,0,0,3699,3700,7,5,0,0,3700,3701,7,7,0,0,3701,344,
	1,0,0,0,3702,3703,7,6,0,0,3703,3704,7,7,0,0,3704,3705,7,8,0,0,3705,3706,
	7,23,0,0,3706,3707,7,15,0,0,3707,3708,7,12,0,0,3708,3709,7,3,0,0,3709,3710,
	7,6,0,0,3710,3711,7,7,0,0,3711,3712,7,4,0,0,3712,346,1,0,0,0,3713,3714,
	7,6,0,0,3714,3715,7,20,0,0,3715,3716,7,7,0,0,3716,3717,7,12,0,0,3717,348,
	1,0,0,0,3718,3719,7,6,0,0,3719,3720,7,19,0,0,3720,350,1,0,0,0,3721,3722,
	7,6,0,0,3722,3723,7,8,0,0,3723,3724,7,3,0,0,3724,3725,7,15,0,0,3725,3726,
	7,5,0,0,3726,3727,7,15,0,0,3727,3728,7,12,0,0,3728,3729,7,22,0,0,3729,352,
	1,0,0,0,3730,3731,7,6,0,0,3731,3732,7,8,0,0,3732,3733,7,15,0,0,3733,3734,
	7,22,0,0,3734,3735,7,22,0,0,3735,3736,7,7,0,0,3736,3737,7,8,0,0,3737,354,
	1,0,0,0,3738,3739,7,6,0,0,3739,3740,7,8,0,0,3740,3741,7,17,0,0,3741,3742,
	7,7,0,0,3742,356,1,0,0,0,3743,3744,7,17,0,0,3744,3745,7,12,0,0,3745,3746,
	7,4,0,0,3746,3747,7,19,0,0,3747,358,1,0,0,0,3748,3749,7,17,0,0,3749,3750,
	7,12,0,0,3750,3751,7,15,0,0,3751,3752,7,19,0,0,3752,3753,7,12,0,0,3753,
	360,1,0,0,0,3754,3755,7,17,0,0,3755,3756,7,12,0,0,3756,3757,7,15,0,0,3757,
	3758,7,28,0,0,3758,3759,7,17,0,0,3759,3760,7,7,0,0,3760,362,1,0,0,0,3761,
	3762,7,17,0,0,3762,3763,7,12,0,0,3763,3764,7,5,0,0,3764,3765,7,19,0,0,3765,
	3766,7,14,0,0,3766,3767,7,21,0,0,3767,364,1,0,0,0,3768,3769,7,17,0,0,3769,
	3770,7,12,0,0,3770,3771,7,11,0,0,3771,3772,7,15,0,0,3772,3773,7,22,0,0,
	3773,3774,7,12,0,0,3774,3775,7,7,0,0,3775,3776,7,4,0,0,3776,366,1,0,0,0,
	3777,3778,7,17,0,0,3778,3779,7,25,0,0,3779,3780,7,4,0,0,3780,3781,7,3,0,
	0,3781,3782,7,6,0,0,3782,3783,7,7,0,0,3783,368,1,0,0,0,3784,3785,7,17,0,
	0,3785,3786,7,11,0,0,3786,3787,7,3,0,0,3787,3788,7,22,0,0,3788,3789,7,7,
	0,0,3789,370,1,0,0,0,3790,3791,7,17,0,0,3791,3792,7,11,0,0,3792,3793,7,
	7,0,0,3793,372,1,0,0,0,3794,3795,7,17,0,0,3795,3796,7,11,0,0,3796,3797,
	7,15,0,0,3797,3798,7,12,0,0,3798,3799,7,22,0,0,3799,374,1,0,0,0,3800,3801,
	7,24,0,0,3801,3802,7,3,0,0,3802,3803,7,5,0,0,3803,3804,7,17,0,0,3804,3805,
	7,7,0,0,3805,3806,7,11,0,0,3806,376,1,0,0,0,3807,3808,7,9,0,0,3808,3809,
	7,20,0,0,3809,3810,7,7,0,0,3810,3811,7,12,0,0,3811,378,1,0,0,0,3812,3813,
	7,9,0,0,3813,3814,7,20,0,0,3814,3815,7,7,0,0,3815,3816,7,8,0,0,3816,3817,
	7,7,0,0,3817,380,1,0,0,0,3818,3819,7,9,0,0,3819,3820,7,20,0,0,3820,3821,
	7,15,0,0,3821,3822,7,5,0,0,3822,3823,7,7,0,0,3823,382,1,0,0,0,3824,3825,
	7,9,0,0,3825,3826,7,15,0,0,3826,3827,7,6,0,0,3827,3828,7,20,0,0,3828,384,
	1,0,0,0,3829,3830,7,9,0,0,3830,3831,7,8,0,0,3831,3832,7,15,0,0,3832,3833,
	7,6,0,0,3833,3834,7,7,0,0,3834,386,1,0,0,0,3835,3836,7,26,0,0,3836,3837,
	7,19,0,0,3837,3838,7,8,0,0,3838,388,1,0,0,0,3839,3840,7,13,0,0,3840,3841,
	7,7,0,0,3841,3842,7,8,0,0,3842,3843,7,19,0,0,3843,3844,7,18,0,0,3844,3845,
	7,15,0,0,3845,3846,7,5,0,0,3846,3847,7,5,0,0,3847,390,1,0,0,0,3848,3849,
	7,6,0,0,3849,3850,7,15,0,0,3850,3851,7,12,0,0,3851,3852,7,10,0,0,3852,3853,
	7,15,0,0,3853,3854,7,12,0,0,3854,3855,7,6,0,0,3855,392,1,0,0,0,3856,3857,
	7,11,0,0,3857,3858,7,23,0,0,3858,3859,7,3,0,0,3859,3860,7,5,0,0,3860,3861,
	7,5,0,0,3861,3862,7,15,0,0,3862,3863,7,12,0,0,3863,3864,7,6,0,0,3864,394,
	1,0,0,0,3865,3866,7,23,0,0,3866,3867,7,7,0,0,3867,3868,7,4,0,0,3868,3869,
	7,15,0,0,3869,3870,7,17,0,0,3870,3871,7,23,0,0,3871,3872,7,15,0,0,3872,
	3873,7,12,0,0,3873,3874,7,6,0,0,3874,396,1,0,0,0,3875,3876,7,23,0,0,3876,
	3877,7,15,0,0,3877,3878,7,4,0,0,3878,3879,7,4,0,0,3879,3880,7,5,0,0,3880,
	3881,7,7,0,0,3881,3882,7,15,0,0,3882,3883,7,12,0,0,3883,3884,7,6,0,0,3884,
	398,1,0,0,0,3885,3886,7,15,0,0,3886,3887,7,12,0,0,3887,3888,7,6,0,0,3888,
	400,1,0,0,0,3889,3890,7,15,0,0,3890,3891,7,12,0,0,3891,3892,7,6,0,0,3892,
	3893,5,49,0,0,3893,402,1,0,0,0,3894,3895,7,15,0,0,3895,3896,7,12,0,0,3896,
	3897,7,6,0,0,3897,3898,5,50,0,0,3898,404,1,0,0,0,3899,3900,7,15,0,0,3900,
	3901,7,12,0,0,3901,3902,7,6,0,0,3902,3903,5,51,0,0,3903,406,1,0,0,0,3904,
	3905,7,15,0,0,3905,3906,7,12,0,0,3906,3907,7,6,0,0,3907,3908,5,52,0,0,3908,
	408,1,0,0,0,3909,3910,7,15,0,0,3910,3911,7,12,0,0,3911,3912,7,6,0,0,3912,
	3913,5,56,0,0,3913,410,1,0,0,0,3914,3915,7,15,0,0,3915,3916,7,12,0,0,3916,
	3917,7,6,0,0,3917,3918,7,7,0,0,3918,3919,7,22,0,0,3919,3920,7,7,0,0,3920,
	3921,7,8,0,0,3921,412,1,0,0,0,3922,3923,7,16,0,0,3923,3924,7,15,0,0,3924,
	3925,7,22,0,0,3925,3926,7,15,0,0,3926,3927,7,12,0,0,3927,3928,7,6,0,0,3928,
	414,1,0,0,0,3929,3930,7,8,0,0,3930,3931,7,7,0,0,3931,3932,7,3,0,0,3932,
	3933,7,5,0,0,3933,416,1,0,0,0,3934,3935,7,4,0,0,3935,3936,7,19,0,0,3936,
	3937,7,17,0,0,3937,3938,7,16,0,0,3938,3939,7,5,0,0,3939,3940,7,7,0,0,3940,
	418,1,0,0,0,3941,3942,7,25,0,0,3942,3943,7,8,0,0,3943,3944,7,7,0,0,3944,
	3945,7,14,0,0,3945,3946,7,15,0,0,3946,3947,7,11,0,0,3947,3948,7,15,0,0,
	3948,3949,7,19,0,0,3949,3950,7,12,0,0,3950,420,1,0,0,0,3951,3952,7,18,0,
	0,3952,3953,7,5,0,0,3953,3954,7,19,0,0,3954,3955,7,3,0,0,3955,3956,7,6,
	0,0,3956,422,1,0,0,0,3957,3958,7,18,0,0,3958,3959,7,5,0,0,3959,3960,7,19,
	0,0,3960,3961,7,3,0,0,3961,3962,7,6,0,0,3962,3963,5,52,0,0,3963,424,1,0,
	0,0,3964,3965,7,18,0,0,3965,3966,7,5,0,0,3966,3967,7,19,0,0,3967,3968,7,
	3,0,0,3968,3969,7,6,0,0,3969,3970,5,56,0,0,3970,426,1,0,0,0,3971,3972,7,
	4,0,0,3972,3973,7,7,0,0,3973,3974,7,14,0,0,3974,3975,7,15,0,0,3975,3976,
	7,23,0,0,3976,3977,7,3,0,0,3977,3978,7,5,0,0,3978,428,1,0,0,0,3979,3980,
	7,4,0,0,3980,3981,7,7,0,0,3981,3982,7,14,0,0,3982,430,1,0,0,0,3983,3984,
	7,12,0,0,3984,3985,7,17,0,0,3985,3986,7,23,0,0,3986,3987,7,7,0,0,3987,3988,
	7,8,0,0,3988,3989,7,15,0,0,3989,3990,7,14,0,0,3990,432,1,0,0,0,3991,3992,
	7,4,0,0,3992,3993,7,3,0,0,3993,3994,7,6,0,0,3994,3995,7,7,0,0,3995,434,
	1,0,0,0,3996,3997,7,6,0,0,3997,3998,7,15,0,0,3998,3999,7,23,0,0,3999,4000,
	7,7,0,0,4000,436,1,0,0,0,4001,4002,7,6,0,0,4002,4003,7,15,0,0,4003,4004,
	7,23,0,0,4004,4005,7,7,0,0,4005,4006,7,11,0,0,4006,4007,7,6,0,0,4007,4008,
	7,3,0,0,4008,4009,7,23,0,0,4009,4010,7,25,0,0,4010,438,1,0,0,0,4011,4012,
	7,4,0,0,4012,4013,7,3,0,0,4013,4014,7,6,0,0,4014,4015,7,7,0,0,4015,4016,
	7,6,0,0,4016,4017,7,15,0,0,4017,4018,7,23,0,0,4018,4019,7,7,0,0,4019,440,
	1,0,0,0,4020,4021,7,10,0,0,4021,4022,7,7,0,0,4022,4023,7,3,0,0,4023,4024,
	7,8,0,0,4024,442,1,0,0,0,4025,4026,7,14,0,0,4026,4027,7,20,0,0,4027,4028,
	7,3,0,0,4028,4029,7,8,0,0,4029,444,1,0,0,0,4030,4031,7,24,0,0,4031,4032,
	7,3,0,0,4032,4033,7,8,0,0,4033,4034,7,14,0,0,4034,4035,7,20,0,0,4035,4036,
	7,3,0,0,4036,4037,7,8,0,0,4037,446,1,0,0,0,4038,4039,7,12,0,0,4039,4040,
	7,24,0,0,4040,4041,7,3,0,0,4041,4042,7,8,0,0,4042,4043,7,14,0,0,4043,4044,
	7,20,0,0,4044,4045,7,3,0,0,4045,4046,7,8,0,0,4046,448,1,0,0,0,4047,4048,
	7,12,0,0,4048,4049,7,3,0,0,4049,4050,7,6,0,0,4050,4051,7,15,0,0,4051,4052,
	7,19,0,0,4052,4053,7,12,0,0,4053,4054,7,3,0,0,4054,4055,7,5,0,0,4055,450,
	1,0,0,0,4056,4057,7,16,0,0,4057,4058,7,15,0,0,4058,4059,7,12,0,0,4059,4060,
	7,3,0,0,4060,4061,7,8,0,0,4061,4062,7,10,0,0,4062,452,1,0,0,0,4063,4064,
	7,24,0,0,4064,4065,7,3,0,0,4065,4066,7,8,0,0,4066,4067,7,16,0,0,4067,4068,
	7,15,0,0,4068,4069,7,12,0,0,4069,4070,7,3,0,0,4070,4071,7,8,0,0,4071,4072,
	7,10,0,0,4072,454,1,0,0,0,4073,4074,7,6,0,0,4074,4075,7,15,0,0,4075,4076,
	7,12,0,0,4076,4077,7,10,0,0,4077,4078,7,16,0,0,4078,4079,7,5,0,0,4079,4080,
	7,19,0,0,4080,4081,7,16,0,0,4081,456,1,0,0,0,4082,4083,7,16,0,0,4083,4084,
	7,5,0,0,4084,4085,7,19,0,0,4085,4086,7,16,0,0,4086,458,1,0,0,0,4087,4088,
	7,23,0,0,4088,4089,7,7,0,0,4089,4090,7,4,0,0,4090,4091,7,15,0,0,4091,4092,
	7,17,0,0,4092,4093,7,23,0,0,4093,4094,7,16,0,0,4094,4095,7,5,0,0,4095,4096,
	7,19,0,0,4096,4097,7,16,0,0,4097,460,1,0,0,0,4098,4099,7,5,0,0,4099,4100,
	7,19,0,0,4100,4101,7,12,0,0,4101,4102,7,22,0,0,4102,462,1,0,0,0,4103,4104,
	7,5,0,0,4104,4105,7,19,0,0,4105,4106,7,12,0,0,4106,4107,7,22,0,0,4107,4108,
	7,16,0,0,4108,4109,7,5,0,0,4109,4110,7,19,0,0,4110,4111,7,16,0,0,4111,464,
	1,0,0,0,4112,4113,7,6,0,0,4113,4114,7,15,0,0,4114,4115,7,12,0,0,4115,4116,
	7,10,0,0,4116,4117,7,6,0,0,4117,4118,7,7,0,0,4118,4119,7,26,0,0,4119,4120,
	7,6,0,0,4120,466,1,0,0,0,4121,4122,7,6,0,0,4122,4123,7,7,0,0,4123,4124,
	7,26,0,0,4124,4125,7,6,0,0,4125,468,1,0,0,0,4126,4127,7,23,0,0,4127,4128,
	7,7,0,0,4128,4129,7,4,0,0,4129,4130,7,15,0,0,4130,4131,7,17,0,0,4131,4132,
	7,23,0,0,4132,4133,7,6,0,0,4133,4134,7,7,0,0,4134,4135,7,26,0,0,4135,4136,
	7,6,0,0,4136,470,1,0,0,0,4137,4138,7,5,0,0,4138,4139,7,19,0,0,4139,4140,
	7,12,0,0,4140,4141,7,22,0,0,4141,4142,7,6,0,0,4142,4143,7,7,0,0,4143,4144,
	7,26,0,0,4144,4145,7,6,0,0,4145,472,1,0,0,0,4146,4147,7,7,0,0,4147,4148,
	7,12,0,0,4148,4149,7,17,0,0,4149,4150,7,23,0,0,4150,474,1,0,0,0,4151,4152,
	7,24,0,0,4152,4153,7,3,0,0,4153,4154,7,8,0,0,4154,4155,7,10,0,0,4155,4156,
	7,15,0,0,4156,4157,7,12,0,0,4157,4158,7,22,0,0,4158,476,1,0,0,0,4159,4160,
	7,11,0,0,4160,4161,7,7,0,0,4161,4162,7,8,0,0,4162,4163,7,15,0,0,4163,4164,
	7,3,0,0,4164,4165,7,5,0,0,4165,478,1,0,0,0,4166,4167,7,10,0,0,4167,4168,
	7,7,0,0,4168,4169,7,3,0,0,4169,4170,7,8,0,0,4170,4171,5,95,0,0,4171,4172,
	7,23,0,0,4172,4173,7,19,0,0,4173,4174,7,12,0,0,4174,4175,7,6,0,0,4175,4176,
	7,20,0,0,4176,480,1,0,0,0,4177,4178,7,4,0,0,4178,4179,7,3,0,0,4179,4180,
	7,10,0,0,4180,4181,5,95,0,0,4181,4182,7,20,0,0,4182,4183,7,19,0,0,4183,
	4184,7,17,0,0,4184,4185,7,8,0,0,4185,482,1,0,0,0,4186,4187,7,4,0,0,4187,
	4188,7,3,0,0,4188,4189,7,10,0,0,4189,4190,5,95,0,0,4190,4191,7,23,0,0,4191,
	4192,7,15,0,0,4192,4193,7,12,0,0,4193,4194,7,17,0,0,4194,4195,7,6,0,0,4195,
	4196,7,7,0,0,4196,484,1,0,0,0,4197,4198,7,4,0,0,4198,4199,7,3,0,0,4199,
	4200,7,10,0,0,4200,4201,5,95,0,0,4201,4202,7,11,0,0,4202,4203,7,7,0,0,4203,
	4204,7,14,0,0,4204,4205,7,19,0,0,4205,4206,7,12,0,0,4206,4207,7,4,0,0,4207,
	486,1,0,0,0,4208,4209,7,20,0,0,4209,4210,7,19,0,0,4210,4211,7,17,0,0,4211,
	4212,7,8,0,0,4212,4213,5,95,0,0,4213,4214,7,23,0,0,4214,4215,7,15,0,0,4215,
	4216,7,12,0,0,4216,4217,7,17,0,0,4217,4218,7,6,0,0,4218,4219,7,7,0,0,4219,
	488,1,0,0,0,4220,4221,7,20,0,0,4221,4222,7,19,0,0,4222,4223,7,17,0,0,4223,
	4224,7,8,0,0,4224,4225,5,95,0,0,4225,4226,7,11,0,0,4226,4227,7,7,0,0,4227,
	4228,7,14,0,0,4228,4229,7,19,0,0,4229,4230,7,12,0,0,4230,4231,7,4,0,0,4231,
	490,1,0,0,0,4232,4233,7,23,0,0,4233,4234,7,15,0,0,4234,4235,7,12,0,0,4235,
	4236,7,17,0,0,4236,4237,7,6,0,0,4237,4238,7,7,0,0,4238,4239,5,95,0,0,4239,
	4240,7,11,0,0,4240,4241,7,7,0,0,4241,4242,7,14,0,0,4242,4243,7,19,0,0,4243,
	4244,7,12,0,0,4244,4245,7,4,0,0,4245,492,1,0,0,0,4246,4247,7,11,0,0,4247,
	4248,7,7,0,0,4248,4249,7,14,0,0,4249,4250,7,19,0,0,4250,4251,7,12,0,0,4251,
	4252,7,4,0,0,4252,4253,5,95,0,0,4253,4254,7,23,0,0,4254,4255,7,15,0,0,4255,
	4256,7,14,0,0,4256,4257,7,8,0,0,4257,4258,7,19,0,0,4258,4259,7,11,0,0,4259,
	4260,7,7,0,0,4260,4261,7,14,0,0,4261,4262,7,19,0,0,4262,4263,7,12,0,0,4263,
	4264,7,4,0,0,4264,494,1,0,0,0,4265,4266,7,23,0,0,4266,4267,7,15,0,0,4267,
	4268,7,12,0,0,4268,4269,7,17,0,0,4269,4270,7,6,0,0,4270,4271,7,7,0,0,4271,
	4272,5,95,0,0,4272,4273,7,23,0,0,4273,4274,7,15,0,0,4274,4275,7,14,0,0,
	4275,4276,7,8,0,0,4276,4277,7,19,0,0,4277,4278,7,11,0,0,4278,4279,7,7,0,
	0,4279,4280,7,14,0,0,4280,4281,7,19,0,0,4281,4282,7,12,0,0,4282,4283,7,
	4,0,0,4283,496,1,0,0,0,4284,4285,7,20,0,0,4285,4286,7,19,0,0,4286,4287,
	7,17,0,0,4287,4288,7,8,0,0,4288,4289,5,95,0,0,4289,4290,7,23,0,0,4290,4291,
	7,15,0,0,4291,4292,7,14,0,0,4292,4293,7,8,0,0,4293,4294,7,19,0,0,4294,4295,
	7,11,0,0,4295,4296,7,7,0,0,4296,4297,7,14,0,0,4297,4298,7,19,0,0,4298,4299,
	7,12,0,0,4299,4300,7,4,0,0,4300,498,1,0,0,0,4301,4302,7,4,0,0,4302,4303,
	7,3,0,0,4303,4304,7,10,0,0,4304,4305,5,95,0,0,4305,4306,7,23,0,0,4306,4307,
	7,15,0,0,4307,4308,7,14,0,0,4308,4309,7,8,0,0,4309,4310,7,19,0,0,4310,4311,
	7,11,0,0,4311,4312,7,7,0,0,4312,4313,7,14,0,0,4313,4314,7,19,0,0,4314,4315,
	7,12,0,0,4315,4316,7,4,0,0,4316,500,1,0,0,0,4317,4318,7,27,0,0,4318,4319,
	7,11,0,0,4319,4320,7,19,0,0,4320,4321,7,12,0,0,4321,4322,5,95,0,0,4322,
	4323,7,3,0,0,4323,4324,7,8,0,0,4324,4325,7,8,0,0,4325,4326,7,3,0,0,4326,
	4327,7,10,0,0,4327,502,1,0,0,0,4328,4329,7,27,0,0,4329,4330,7,11,0,0,4330,
	4331,7,19,0,0,4331,4332,7,12,0,0,4332,4333,5,95,0,0,4333,4334,7,3,0,0,4334,
	4335,7,8,0,0,4335,4336,7,8,0,0,4336,4337,7,3,0,0,4337,4338,7,10,0,0,4338,
	4339,7,3,0,0,4339,4340,7,22,0,0,4340,4341,7,22,0,0,4341,504,1,0,0,0,4342,
	4343,7,27,0,0,4343,4344,7,11,0,0,4344,4345,7,19,0,0,4345,4346,7,12,0,0,
	4346,4347,5,95,0,0,4347,4348,7,3,0,0,4348,4349,7,8,0,0,4349,4350,7,8,0,
	0,4350,4351,7,3,0,0,4351,4352,7,10,0,0,4352,4353,5,95,0,0,4353,4354,7,3,
	0,0,4354,4355,7,25,0,0,4355,4356,7,25,0,0,4356,4357,7,7,0,0,4357,4358,7,
	12,0,0,4358,4359,7,4,0,0,4359,506,1,0,0,0,4360,4361,7,27,0,0,4361,4362,
	7,11,0,0,4362,4363,7,19,0,0,4363,4364,7,12,0,0,4364,4365,5,95,0,0,4365,
	4366,7,3,0,0,4366,4367,7,8,0,0,4367,4368,7,8,0,0,4368,4369,7,3,0,0,4369,
	4370,7,10,0,0,4370,4371,5,95,0,0,4371,4372,7,15,0,0,4372,4373,7,12,0,0,
	4373,4374,7,11,0,0,4374,4375,7,7,0,0,4375,4376,7,8,0,0,4376,4377,7,6,0,
	0,4377,508,1,0,0,0,4378,4379,7,27,0,0,4379,4380,7,11,0,0,4380,4381,7,19,
	0,0,4381,4382,7,12,0,0,4382,4383,5,95,0,0,4383,4384,7,14,0,0,4384,4385,
	7,19,0,0,4385,4386,7,12,0,0,4386,4387,7,6,0,0,4387,4388,7,3,0,0,4388,4389,
	7,15,0,0,4389,4390,7,12,0,0,4390,4391,7,11,0,0,4391,510,1,0,0,0,4392,4393,
	7,27,0,0,4393,4394,7,11,0,0,4394,4395,7,19,0,0,4395,4396,7,12,0,0,4396,
	4397,5,95,0,0,4397,4398,7,14,0,0,4398,4399,7,19,0,0,4399,4400,7,12,0,0,
	4400,4401,7,6,0,0,4401,4402,7,3,0,0,4402,4403,7,15,0,0,4403,4404,7,12,0,
	0,4404,4405,7,11,0,0,4405,4406,5,95,0,0,4406,4407,7,25,0,0,4407,4408,7,
	3,0,0,4408,4409,7,6,0,0,4409,4410,7,20,0,0,4410,512,1,0,0,0,4411,4412,7,
	27,0,0,4412,4413,7,11,0,0,4413,4414,7,19,0,0,4414,4415,7,12,0,0,4415,4416,
	5,95,0,0,4416,4417,7,4,0,0,4417,4418,7,7,0,0,4418,4419,7,25,0,0,4419,4420,
	7,6,0,0,4420,4421,7,20,0,0,4421,514,1,0,0,0,4422,4423,7,27,0,0,4423,4424,
	7,11,0,0,4424,4425,7,19,0,0,4425,4426,7,12,0,0,4426,4427,5,95,0,0,4427,
	4428,7,7,0,0,4428,4429,7,26,0,0,4429,4430,7,6,0,0,4430,4431,7,8,0,0,4431,
	4432,7,3,0,0,4432,4433,7,14,0,0,4433,4434,7,6,0,0,4434,516,1,0,0,0,4435,
	4436,7,27,0,0,4436,4437,7,11,0,0,4437,4438,7,19,0,0,4438,4439,7,12,0,0,
	4439,4440,5,95,0,0,4440,4441,7,15,0,0,4441,4442,7,12,0,0,4442,4443,7,11,
	0,0,4443,4444,7,7,0,0,4444,4445,7,8,0,0,4445,4446,7,6,0,0,4446,518,1,0,
	0,0,4447,4448,7,27,0,0,4448,4449,7,11,0,0,4449,4450,7,19,0,0,4450,4451,
	7,12,0,0,4451,4452,5,95,0,0,4452,4453,7,21,0,0,4453,4454,7,7,0,0,4454,4455,
	7,10,0,0,4455,4456,7,11,0,0,4456,520,1,0,0,0,4457,4458,7,27,0,0,4458,4459,
	7,11,0,0,4459,4460,7,19,0,0,4460,4461,7,12,0,0,4461,4462,5,95,0,0,4462,
	4463,7,5,0,0,4463,4464,7,7,0,0,4464,4465,7,12,0,0,4465,4466,7,22,0,0,4466,
	4467,7,6,0,0,4467,4468,7,20,0,0,4468,522,1,0,0,0,4469,4470,7,27,0,0,4470,
	4471,7,11,0,0,4471,4472,7,19,0,0,4472,4473,7,12,0,0,4473,4474,5,95,0,0,
	4474,4475,7,23,0,0,4475,4476,7,7,0,0,4476,4477,7,8,0,0,4477,4478,7,22,0,
	0,4478,4479,7,7,0,0,4479,524,1,0,0,0,4480,4481,7,27,0,0,4481,4482,7,11,
	0,0,4482,4483,7,19,0,0,4483,4484,7,12,0,0,4484,4485,5,95,0,0,4485,4486,
	7,23,0,0,4486,4487,7,7,0,0,4487,4488,7,8,0,0,4488,4489,7,22,0,0,4489,4490,
	7,7,0,0,4490,4491,5,95,0,0,4491,4492,7,25,0,0,4492,4493,7,3,0,0,4493,4494,
	7,6,0,0,4494,4495,7,14,0,0,4495,4496,7,20,0,0,4496,526,1,0,0,0,4497,4498,
	7,27,0,0,4498,4499,7,11,0,0,4499,4500,7,19,0,0,4500,4501,7,12,0,0,4501,
	4502,5,95,0,0,4502,4503,7,23,0,0,4503,4504,7,7,0,0,4504,4505,7,8,0,0,4505,
	4506,7,22,0,0,4506,4507,7,7,0,0,4507,4508,5,95,0,0,4508,4509,7,25,0,0,4509,
	4510,7,8,0,0,4510,4511,7,7,0,0,4511,4512,7,11,0,0,4512,4513,7,7,0,0,4513,
	4514,7,8,0,0,4514,4515,7,24,0,0,4515,4516,7,7,0,0,4516,528,1,0,0,0,4517,
	4518,7,27,0,0,4518,4519,7,11,0,0,4519,4520,7,19,0,0,4520,4521,7,12,0,0,
	4521,4522,5,95,0,0,4522,4523,7,19,0,0,4523,4524,7,16,0,0,4524,4525,7,27,
	0,0,4525,4526,7,7,0,0,4526,4527,7,14,0,0,4527,4528,7,6,0,0,4528,530,1,0,
	0,0,4529,4530,7,27,0,0,4530,4531,7,11,0,0,4531,4532,7,19,0,0,4532,4533,
	7,12,0,0,4533,4534,5,95,0,0,4534,4535,7,19,0,0,4535,4536,7,16,0,0,4536,
	4537,7,27,0,0,4537,4538,7,7,0,0,4538,4539,7,14,0,0,4539,4540,7,6,0,0,4540,
	4541,7,3,0,0,4541,4542,7,22,0,0,4542,4543,7,22,0,0,4543,532,1,0,0,0,4544,
	4545,7,27,0,0,4545,4546,7,11,0,0,4546,4547,7,19,0,0,4547,4548,7,12,0,0,
	4548,4549,5,95,0,0,4549,4550,7,19,0,0,4550,4551,7,24,0,0,4551,4552,7,7,
	0,0,4552,4553,7,8,0,0,4553,4554,7,5,0,0,4554,4555,7,3,0,0,4555,4556,7,25,
	0,0,4556,4557,7,11,0,0,4557,534,1,0,0,0,4558,4559,7,27,0,0,4559,4560,7,
	11,0,0,4560,4561,7,19,0,0,4561,4562,7,12,0,0,4562,4563,5,95,0,0,4563,4564,
	7,25,0,0,4564,4565,7,8,0,0,4565,4566,7,7,0,0,4566,4567,7,6,0,0,4567,4568,
	7,6,0,0,4568,4569,7,10,0,0,4569,536,1,0,0,0,4570,4571,7,27,0,0,4571,4572,
	7,11,0,0,4572,4573,7,19,0,0,4573,4574,7,12,0,0,4574,4575,5,95,0,0,4575,
	4576,7,28,0,0,4576,4577,7,17,0,0,4577,4578,7,19,0,0,4578,4579,7,6,0,0,4579,
	4580,7,7,0,0,4580,538,1,0,0,0,4581,4582,7,27,0,0,4582,4583,7,11,0,0,4583,
	4584,7,19,0,0,4584,4585,7,12,0,0,4585,4586,5,95,0,0,4586,4587,7,8,0,0,4587,
	4588,7,7,0,0,4588,4589,7,23,0,0,4589,4590,7,19,0,0,4590,4591,7,24,0,0,4591,
	4592,7,7,0,0,4592,540,1,0,0,0,4593,4594,7,27,0,0,4594,4595,7,11,0,0,4595,
	4596,7,19,0,0,4596,4597,7,12,0,0,4597,4598,5,95,0,0,4598,4599,7,8,0,0,4599,
	4600,7,7,0,0,4600,4601,7,25,0,0,4601,4602,7,5,0,0,4602,4603,7,3,0,0,4603,
	4604,7,14,0,0,4604,4605,7,7,0,0,4605,542,1,0,0,0,4606,4607,7,27,0,0,4607,
	4608,7,11,0,0,4608,4609,7,19,0,0,4609,4610,7,12,0,0,4610,4611,5,95,0,0,
	4611,4612,7,11,0,0,4612,4613,7,14,0,0,4613,4614,7,20,0,0,4614,4615,7,7,
	0,0,4615,4616,7,23,0,0,4616,4617,7,3,0,0,4617,4618,5,95,0,0,4618,4619,7,
	24,0,0,4619,4620,7,3,0,0,4620,4621,7,5,0,0,4621,4622,7,15,0,0,4622,4623,
	7,4,0,0,4623,544,1,0,0,0,4624,4625,7,27,0,0,4625,4626,7,11,0,0,4626,4627,
	7,19,0,0,4627,4628,7,12,0,0,4628,4629,5,95,0,0,4629,4630,7,11,0,0,4630,
	4631,7,14,0,0,4631,4632,7,20,0,0,4632,4633,7,7,0,0,4633,4634,7,23,0,0,4634,
	4635,7,3,0,0,4635,4636,5,95,0,0,4636,4637,7,24,0,0,4637,4638,7,3,0,0,4638,
	4639,7,5,0,0,4639,4640,7,15,0,0,4640,4641,7,4,0,0,4641,4642,7,3,0,0,4642,
	4643,7,6,0,0,4643,4644,7,15,0,0,4644,4645,7,19,0,0,4645,4646,7,12,0,0,4646,
	4647,5,95,0,0,4647,4648,7,8,0,0,4648,4649,7,7,0,0,4649,4650,7,25,0,0,4650,
	4651,7,19,0,0,4651,4652,7,8,0,0,4652,4653,7,6,0,0,4653,546,1,0,0,0,4654,
	4655,7,27,0,0,4655,4656,7,11,0,0,4656,4657,7,19,0,0,4657,4658,7,12,0,0,
	4658,4659,5,95,0,0,4659,4660,7,11,0,0,4660,4661,7,7,0,0,4661,4662,7,3,0,
	0,4662,4663,7,8,0,0,4663,4664,7,14,0,0,4664,4665,7,20,0,0,4665,548,1,0,
	0,0,4666,4667,7,27,0,0,4667,4668,7,11,0,0,4668,4669,7,19,0,0,4669,4670,
	7,12,0,0,4670,4671,5,95,0,0,4671,4672,7,11,0,0,4672,4673,7,7,0,0,4673,4674,
	7,6,0,0,4674,550,1,0,0,0,4675,4676,7,27,0,0,4676,4677,7,11,0,0,4677,4678,
	7,19,0,0,4678,4679,7,12,0,0,4679,4680,5,95,0,0,4680,4681,7,11,0,0,4681,
	4682,7,6,0,0,4682,4683,7,19,0,0,4683,4684,7,8,0,0,4684,4685,7,3,0,0,4685,
	4686,7,22,0,0,4686,4687,7,7,0,0,4687,4688,5,95,0,0,4688,4689,7,18,0,0,4689,
	4690,7,8,0,0,4690,4691,7,7,0,0,4691,4692,7,7,0,0,4692,552,1,0,0,0,4693,
	4694,7,27,0,0,4694,4695,7,11,0,0,4695,4696,7,19,0,0,4696,4697,7,12,0,0,
	4697,4698,5,95,0,0,4698,4699,7,11,0,0,4699,4700,7,6,0,0,4700,4701,7,19,
	0,0,4701,4702,7,8,0,0,4702,4703,7,3,0,0,4703,4704,7,22,0,0,4704,4705,7,
	7,0,0,4705,4706,5,95,0,0,4706,4707,7,11,0,0,4707,4708,7,15,0,0,4708,4709,
	7,13,0,0,4709,4710,7,7,0,0,4710,554,1,0,0,0,4711,4712,7,27,0,0,4712,4713,
	7,11,0,0,4713,4714,7,19,0,0,4714,4715,7,12,0,0,4715,4716,5,95,0,0,4716,
	4717,7,6,0,0,4717,4718,7,3,0,0,4718,4719,7,16,0,0,4719,4720,7,5,0,0,4720,
	4721,7,7,0,0,4721,556,1,0,0,0,4722,4723,7,27,0,0,4723,4724,7,11,0,0,4724,
	4725,7,19,0,0,4725,4726,7,12,0,0,4726,4727,5,95,0,0,4727,4728,7,6,0,0,4728,
	4729,7,10,0,0,4729,4730,7,25,0,0,4730,4731,7,7,0,0,4731,558,1,0,0,0,4732,
	4733,7,27,0,0,4733,4734,7,11,0,0,4734,4735,7,19,0,0,4735,4736,7,12,0,0,
	4736,4737,5,95,0,0,4737,4738,7,17,0,0,4738,4739,7,12,0,0,4739,4740,7,28,
	0,0,4740,4741,7,17,0,0,4741,4742,7,19,0,0,4742,4743,7,6,0,0,4743,4744,7,
	7,0,0,4744,560,1,0,0,0,4745,4746,7,27,0,0,4746,4747,7,11,0,0,4747,4748,
	7,19,0,0,4748,4749,7,12,0,0,4749,4750,5,95,0,0,4750,4751,7,24,0,0,4751,
	4752,7,3,0,0,4752,4753,7,5,0,0,4753,4754,7,15,0,0,4754,4755,7,4,0,0,4755,
	562,1,0,0,0,4756,4757,7,27,0,0,4757,4758,7,11,0,0,4758,4759,7,19,0,0,4759,
	4760,7,12,0,0,4760,4761,5,95,0,0,4761,4762,7,24,0,0,4762,4763,7,3,0,0,4763,
	4764,7,5,0,0,4764,4765,7,17,0,0,4765,4766,7,7,0,0,4766,564,1,0,0,0,4767,
	4768,7,12,0,0,4768,4769,7,7,0,0,4769,4770,7,11,0,0,4770,4771,7,6,0,0,4771,
	4772,7,7,0,0,4772,4773,7,4,0,0,4773,566,1,0,0,0,4774,4775,7,19,0,0,4775,
	4776,7,8,0,0,4776,4777,7,4,0,0,4777,4778,7,15,0,0,4778,4779,7,12,0,0,4779,
	4780,7,3,0,0,4780,4781,7,5,0,0,4781,4782,7,15,0,0,4782,4783,7,6,0,0,4783,
	4784,7,10,0,0,4784,568,1,0,0,0,4785,4786,7,25,0,0,4786,4787,7,3,0,0,4787,
	4788,7,6,0,0,4788,4789,7,20,0,0,4789,570,1,0,0,0,4790,4791,7,3,0,0,4791,
	4792,7,24,0,0,4792,4793,7,22,0,0,4793,572,1,0,0,0,4794,4795,7,16,0,0,4795,
	4796,7,15,0,0,4796,4797,7,6,0,0,4797,4798,5,95,0,0,4798,4799,7,3,0,0,4799,
	4800,7,12,0,0,4800,4801,7,4,0,0,4801,574,1,0,0,0,4802,4803,7,16,0,0,4803,
	4804,7,15,0,0,4804,4805,7,6,0,0,4805,4806,5,95,0,0,4806,4807,7,19,0,0,4807,
	4808,7,8,0,0,4808,576,1,0,0,0,4809,4810,7,16,0,0,4810,4811,7,15,0,0,4811,
	4812,7,6,0,0,4812,4813,5,95,0,0,4813,4814,7,26,0,0,4814,4815,7,19,0,0,4815,
	4816,7,8,0,0,4816,578,1,0,0,0,4817,4818,7,14,0,0,4818,4819,7,19,0,0,4819,
	4820,7,17,0,0,4820,4821,7,12,0,0,4821,4822,7,6,0,0,4822,580,1,0,0,0,4823,
	4824,7,14,0,0,4824,4825,7,17,0,0,4825,4826,7,23,0,0,4826,4827,7,7,0,0,4827,
	4828,5,95,0,0,4828,4829,7,4,0,0,4829,4830,7,15,0,0,4830,4831,7,11,0,0,4831,
	4832,7,6,0,0,4832,582,1,0,0,0,4833,4834,7,4,0,0,4834,4835,7,7,0,0,4835,
	4836,7,12,0,0,4836,4837,7,11,0,0,4837,4838,7,7,0,0,4838,4839,5,95,0,0,4839,
	4840,7,8,0,0,4840,4841,7,3,0,0,4841,4842,7,12,0,0,4842,4843,7,21,0,0,4843,
	584,1,0,0,0,4844,4845,7,18,0,0,4845,4846,7,15,0,0,4846,4847,7,8,0,0,4847,
	4848,7,11,0,0,4848,4849,7,6,0,0,4849,4850,5,95,0,0,4850,4851,7,24,0,0,4851,
	4852,7,3,0,0,4852,4853,7,5,0,0,4853,4854,7,17,0,0,4854,4855,7,7,0,0,4855,
	586,1,0,0,0,4856,4857,7,22,0,0,4857,4858,7,8,0,0,4858,4859,7,19,0,0,4859,
	4860,7,17,0,0,4860,4861,7,25,0,0,4861,4862,5,95,0,0,4862,4863,7,14,0,0,
	4863,4864,7,19,0,0,4864,4865,7,12,0,0,4865,4866,7,14,0,0,4866,4867,7,3,
	0,0,4867,4868,7,6,0,0,4868,588,1,0,0,0,4869,4870,7,5,0,0,4870,4871,7,3,
	0,0,4871,4872,7,22,0,0,4872,590,1,0,0,0,4873,4874,7,5,0,0,4874,4875,7,3,
	0,0,4875,4876,7,11,0,0,4876,4877,7,6,0,0,4877,4878,5,95,0,0,4878,4879,7,
	24,0,0,4879,4880,7,3,0,0,4880,4881,7,5,0,0,4881,4882,7,17,0,0,4882,4883,
	7,7,0,0,4883,592,1,0,0,0,4884,4885,7,5,0,0,4885,4886,7,7,0,0,4886,4887,
	7,3,0,0,4887,4888,7,4,0,0,4888,594,1,0,0,0,4889,4890,7,23,0,0,4890,4891,
	7,3,0,0,4891,4892,7,26,0,0,4892,596,1,0,0,0,4893,4894,7,23,0,0,4894,4895,
	7,15,0,0,4895,4896,7,12,0,0,4896,598,1,0,0,0,4897,4898,7,12,0,0,4898,4899,
	7,6,0,0,4899,4900,7,15,0,0,4900,4901,7,5,0,0,4901,4902,7,7,0,0,4902,600,
	1,0,0,0,4903,4904,7,12,0,0,4904,4905,7,6,0,0,4905,4906,7,20,0,0,4906,4907,
	5,95,0,0,4907,4908,7,24,0,0,4908,4909,7,3,0,0,4909,4910,7,5,0,0,4910,4911,
	7,17,0,0,4911,4912,7,7,0,0,4912,602,1,0,0,0,4913,4914,7,25,0,0,4914,4915,
	7,7,0,0,4915,4916,7,8,0,0,4916,4917,7,14,0,0,4917,4918,7,7,0,0,4918,4919,
	7,12,0,0,4919,4920,7,6,0,0,4920,4921,5,95,0,0,4921,4922,7,8,0,0,4922,4923,
	7,3,0,0,4923,4924,7,12,0,0,4924,4925,7,21,0,0,4925,604,1,0,0,0,4926,4927,
	7,8,0,0,4927,4928,7,3,0,0,4928,4929,7,12,0,0,4929,4930,7,21,0,0,4930,606,
	1,0,0,0,4931,4932,7,8,0,0,4932,4933,7,19,0,0,4933,4934,7,9,0,0,4934,4935,
	5,95,0,0,4935,4936,7,12,0,0,4936,4937,7,17,0,0,4937,4938,7,23,0,0,4938,
	4939,7,16,0,0,4939,4940,7,7,0,0,4940,4941,7,8,0,0,4941,608,1,0,0,0,4942,
	4943,7,11,0,0,4943,4944,7,6,0,0,4944,4945,7,4,0,0,4945,610,1,0,0,0,4946,
	4947,7,11,0,0,4947,4948,7,6,0,0,4948,4949,7,4,0,0,4949,4950,7,4,0,0,4950,
	4951,7,7,0,0,4951,4952,7,24,0,0,4952,612,1,0,0,0,4953,4954,7,11,0,0,4954,
	4955,7,6,0,0,4955,4956,7,4,0,0,4956,4957,7,4,0,0,4957,4958,7,7,0,0,4958,
	4959,7,24,0,0,4959,4960,5,95,0,0,4960,4961,7,25,0,0,4961,4962,7,19,0,0,
	4962,4963,7,25,0,0,4963,614,1,0,0,0,4964,4965,7,11,0,0,4965,4966,7,6,0,
	0,4966,4967,7,4,0,0,4967,4968,7,4,0,0,4968,4969,7,7,0,0,4969,4970,7,24,
	0,0,4970,4971,5,95,0,0,4971,4972,7,11,0,0,4972,4973,7,3,0,0,4973,4974,7,
	23,0,0,4974,4975,7,25,0,0,4975,616,1,0,0,0,4976,4977,7,11,0,0,4977,4978,
	7,17,0,0,4978,4979,7,23,0,0,4979,618,1,0,0,0,4980,4981,7,24,0,0,4981,4982,
	7,3,0,0,4982,4983,7,8,0,0,4983,4984,5,95,0,0,4984,4985,7,25,0,0,4985,4986,
	7,19,0,0,4986,4987,7,25,0,0,4987,620,1,0,0,0,4988,4989,7,24,0,0,4989,4990,
	7,3,0,0,4990,4991,7,8,0,0,4991,4992,5,95,0,0,4992,4993,7,11,0,0,4993,4994,
	7,3,0,0,4994,4995,7,23,0,0,4995,4996,7,25,0,0,4996,622,1,0,0,0,4997,4998,
	7,24,0,0,4998,4999,7,3,0,0,4999,5000,7,8,0,0,5000,5001,7,15,0,0,5001,5002,
	7,3,0,0,5002,5003,7,12,0,0,5003,5004,7,14,0,0,5004,5005,7,7,0,0,5005,624,
	1,0,0,0,5006,5007,7,14,0,0,5007,5008,7,17,0,0,5008,5009,7,8,0,0,5009,5010,
	7,8,0,0,5010,5011,7,7,0,0,5011,5012,7,12,0,0,5012,5013,7,6,0,0,5013,5014,
	5,95,0,0,5014,5015,7,4,0,0,5015,5016,7,3,0,0,5016,5017,7,6,0,0,5017,5018,
	7,7,0,0,5018,626,1,0,0,0,5019,5020,7,14,0,0,5020,5021,7,17,0,0,5021,5022,
	7,8,0,0,5022,5023,7,8,0,0,5023,5024,7,7,0,0,5024,5025,7,12,0,0,5025,5026,
	7,6,0,0,5026,5027,5,95,0,0,5027,5028,7,6,0,0,5028,5029,7,15,0,0,5029,5030,
	7,23,0,0,5030,5031,7,7,0,0,5031,628,1,0,0,0,5032,5033,7,14,0,0,5033,5034,
	7,17,0,0,5034,5035,7,8,0,0,5035,5036,7,8,0,0,5036,5037,7,7,0,0,5037,5038,
	7,12,0,0,5038,5039,7,6,0,0,5039,5040,5,95,0,0,5040,5041,7,6,0,0,5041,5042,
	7,15,0,0,5042,5043,7,23,0,0,5043,5044,7,7,0,0,5044,5045,7,11,0,0,5045,5046,
	7,6,0,0,5046,5047,7,3,0,0,5047,5048,7,23,0,0,5048,5049,7,25,0,0,5049,630,
	1,0,0,0,5050,5051,7,5,0,0,5051,5052,7,19,0,0,5052,5053,7,14,0,0,5053,5054,
	7,3,0,0,5054,5055,7,5,0,0,5055,5056,7,6,0,0,5056,5057,7,15,0,0,5057,5058,
	7,23,0,0,5058,5059,7,7,0,0,5059,632,1,0,0,0,5060,5061,7,14,0,0,5061,5062,
	7,17,0,0,5062,5063,7,8,0,0,5063,5064,7,4,0,0,5064,5065,7,3,0,0,5065,5066,
	7,6,0,0,5066,5067,7,7,0,0,5067,634,1,0,0,0,5068,5069,7,14,0,0,5069,5070,
	7,17,0,0,5070,5071,7,8,0,0,5071,5072,7,6,0,0,5072,5073,7,15,0,0,5073,5074,
	7,23,0,0,5074,5075,7,7,0,0,5075,636,1,0,0,0,5076,5077,7,4,0,0,5077,5078,
	7,3,0,0,5078,5079,7,6,0,0,5079,5080,7,7,0,0,5080,5081,5,95,0,0,5081,5082,
	7,3,0,0,5082,5083,7,4,0,0,5083,5084,7,4,0,0,5084,638,1,0,0,0,5085,5086,
	7,4,0,0,5086,5087,7,3,0,0,5087,5088,7,6,0,0,5088,5089,7,7,0,0,5089,5090,
	5,95,0,0,5090,5091,7,11,0,0,5091,5092,7,17,0,0,5092,5093,7,16,0,0,5093,
	640,1,0,0,0,5094,5095,7,7,0,0,5095,5096,7,26,0,0,5096,5097,7,6,0,0,5097,
	5098,7,8,0,0,5098,5099,7,3,0,0,5099,5100,7,14,0,0,5100,5101,7,6,0,0,5101,
	642,1,0,0,0,5102,5103,7,5,0,0,5103,5104,7,19,0,0,5104,5105,7,14,0,0,5105,
	5106,7,3,0,0,5106,5107,7,5,0,0,5107,5108,7,6,0,0,5108,5109,7,15,0,0,5109,
	5110,7,23,0,0,5110,5111,7,7,0,0,5111,5112,7,11,0,0,5112,5113,7,6,0,0,5113,
	5114,7,3,0,0,5114,5115,7,23,0,0,5115,5116,7,25,0,0,5116,644,1,0,0,0,5117,
	5118,7,12,0,0,5118,5119,7,19,0,0,5119,5120,7,9,0,0,5120,646,1,0,0,0,5121,
	5122,7,25,0,0,5122,5123,7,19,0,0,5123,5124,7,11,0,0,5124,5125,7,15,0,0,
	5125,5126,7,6,0,0,5126,5127,7,15,0,0,5127,5128,7,19,0,0,5128,5129,7,12,
	0,0,5129,648,1,0,0,0,5130,5131,7,11,0,0,5131,5132,7,17,0,0,5132,5133,7,
	16,0,0,5133,5134,7,11,0,0,5134,5135,7,6,0,0,5135,5136,7,8,0,0,5136,650,
	1,0,0,0,5137,5138,7,11,0,0,5138,5139,7,17,0,0,5139,5140,7,16,0,0,5140,5141,
	7,11,0,0,5141,5142,7,6,0,0,5142,5143,7,8,0,0,5143,5144,7,15,0,0,5144,5145,
	7,12,0,0,5145,5146,7,22,0,0,5146,652,1,0,0,0,5147,5148,7,11,0,0,5148,5149,
	7,10,0,0,5149,5150,7,11,0,0,5150,5151,7,4,0,0,5151,5152,7,3,0,0,5152,5153,
	7,6,0,0,5153,5154,7,7,0,0,5154,654,1,0,0,0,5155,5156,7,6,0,0,5156,5157,
	7,8,0,0,5157,5158,7,15,0,0,5158,5159,7,23,0,0,5159,656,1,0,0,0,5160,5161,
	7,17,0,0,5161,5162,7,6,0,0,5162,5163,7,14,0,0,5163,5164,5,95,0,0,5164,5165,
	7,4,0,0,5165,5166,7,3,0,0,5166,5167,7,6,0,0,5167,5168,7,7,0,0,5168,658,
	1,0,0,0,5169,5170,7,17,0,0,5170,5171,7,6,0,0,5171,5172,7,14,0,0,5172,5173,
	5,95,0,0,5173,5174,7,6,0,0,5174,5175,7,15,0,0,5175,5176,7,23,0,0,5176,5177,
	7,7,0,0,5177,660,1,0,0,0,5178,5179,7,17,0,0,5179,5180,7,6,0,0,5180,5181,
	7,14,0,0,5181,5182,5,95,0,0,5182,5183,7,6,0,0,5183,5184,7,15,0,0,5184,5185,
	7,23,0,0,5185,5186,7,7,0,0,5186,5187,7,11,0,0,5187,5188,7,6,0,0,5188,5189,
	7,3,0,0,5189,5190,7,23,0,0,5190,5191,7,25,0,0,5191,662,1,0,0,0,5192,5193,
	7,3,0,0,5193,5194,7,14,0,0,5194,5195,7,14,0,0,5195,5196,7,19,0,0,5196,5197,
	7,17,0,0,5197,5198,7,12,0,0,5198,5199,7,6,0,0,5199,664,1,0,0,0,5200,5201,
	7,3,0,0,5201,5202,7,14,0,0,5202,5203,7,6,0,0,5203,5204,7,15,0,0,5204,5205,
	7,19,0,0,5205,5206,7,12,0,0,5206,666,1,0,0,0,5207,5208,7,3,0,0,5208,5209,
	7,18,0,0,5209,5210,7,6,0,0,5210,5211,7,7,0,0,5211,5212,7,8,0,0,5212,668,
	1,0,0,0,5213,5214,7,3,0,0,5214,5215,7,22,0,0,5215,5216,7,22,0,0,5216,5217,
	7,8,0,0,5217,5218,7,7,0,0,5218,5219,7,22,0,0,5219,5220,7,3,0,0,5220,5221,
	7,6,0,0,5221,5222,7,7,0,0,5222,670,1,0,0,0,5223,5224,7,3,0,0,5224,5225,
	7,5,0,0,5225,5226,7,22,0,0,5226,5227,7,19,0,0,5227,5228,7,8,0,0,5228,5229,
	7,15,0,0,5229,5230,7,6,0,0,5230,5231,7,20,0,0,5231,5232,7,23,0,0,5232,672,
	1,0,0,0,5233,5234,7,3,0,0,5234,5235,7,12,0,0,5235,5236,7,10,0,0,5236,674,
	1,0,0,0,5237,5238,7,3,0,0,5238,5239,7,6,0,0,5239,676,1,0,0,0,5240,5241,
	7,3,0,0,5241,5242,7,17,0,0,5242,5243,7,6,0,0,5243,5244,7,20,0,0,5244,5245,
	7,19,0,0,5245,5246,7,8,0,0,5246,5247,7,11,0,0,5247,678,1,0,0,0,5248,5249,
	7,3,0,0,5249,5250,7,17,0,0,5250,5251,7,6,0,0,5251,5252,7,19,0,0,5252,5253,
	7,14,0,0,5253,5254,7,19,0,0,5254,5255,7,23,0,0,5255,5256,7,23,0,0,5256,
	5257,7,15,0,0,5257,5258,7,6,0,0,5258,680,1,0,0,0,5259,5260,7,3,0,0,5260,
	5261,7,17,0,0,5261,5262,7,6,0,0,5262,5263,7,19,0,0,5263,5264,7,7,0,0,5264,
	5265,7,26,0,0,5265,5266,7,6,0,0,5266,5267,7,7,0,0,5267,5268,7,12,0,0,5268,
	5269,7,4,0,0,5269,5270,5,95,0,0,5270,5271,7,11,0,0,5271,5272,7,15,0,0,5272,
	5273,7,13,0,0,5273,5274,7,7,0,0,5274,682,1,0,0,0,5275,5276,7,3,0,0,5276,
	5277,7,17,0,0,5277,5278,7,6,0,0,5278,5279,7,19,0,0,5279,5280,5,95,0,0,5280,
	5281,7,15,0,0,5281,5282,7,12,0,0,5282,5283,7,14,0,0,5283,5284,7,8,0,0,5284,
	5285,7,7,0,0,5285,5286,7,23,0,0,5286,5287,7,7,0,0,5287,5288,7,12,0,0,5288,
	5289,7,6,0,0,5289,684,1,0,0,0,5290,5291,7,3,0,0,5291,5292,7,24,0,0,5292,
	5293,7,22,0,0,5293,5294,5,95,0,0,5294,5295,7,8,0,0,5295,5296,7,19,0,0,5296,
	5297,7,9,0,0,5297,5298,5,95,0,0,5298,5299,7,5,0,0,5299,5300,7,7,0,0,5300,
	5301,7,12,0,0,5301,5302,7,22,0,0,5302,5303,7,6,0,0,5303,5304,7,20,0,0,5304,
	686,1,0,0,0,5305,5306,7,16,0,0,5306,5307,7,7,0,0,5307,5308,7,22,0,0,5308,
	5309,7,15,0,0,5309,5310,7,12,0,0,5310,688,1,0,0,0,5311,5312,7,16,0,0,5312,
	5313,7,15,0,0,5313,5314,7,12,0,0,5314,5315,7,5,0,0,5315,5316,7,19,0,0,5316,
	5317,7,22,0,0,5317,690,1,0,0,0,5318,5319,7,16,0,0,5319,5320,7,15,0,0,5320,
	5321,7,6,0,0,5321,692,1,0,0,0,5322,5323,7,16,0,0,5323,5324,7,5,0,0,5324,
	5325,7,19,0,0,5325,5326,7,14,0,0,5326,5327,7,21,0,0,5327,694,1,0,0,0,5328,
	5329,7,16,0,0,5329,5330,7,19,0,0,5330,5331,7,19,0,0,5331,5332,7,5,0,0,5332,
	696,1,0,0,0,5333,5334,7,16,0,0,5334,5335,7,19,0,0,5335,5336,7,19,0,0,5336,
	5337,7,5,0,0,5337,5338,7,7,0,0,5338,5339,7,3,0,0,5339,5340,7,12,0,0,5340,
	698,1,0,0,0,5341,5342,7,16,0,0,5342,5343,7,6,0,0,5343,5344,7,8,0,0,5344,
	5345,7,7,0,0,5345,5346,7,7,0,0,5346,700,1,0,0,0,5347,5348,7,14,0,0,5348,
	5349,7,3,0,0,5349,5350,7,14,0,0,5350,5351,7,20,0,0,5351,5352,7,7,0,0,5352,
	702,1,0,0,0,5353,5354,7,14,0,0,5354,5355,7,3,0,0,5355,5356,7,11,0,0,5356,
	5357,7,14,0,0,5357,5358,7,3,0,0,5358,5359,7,4,0,0,5359,5360,7,7,0,0,5360,
	5361,7,4,0,0,5361,704,1,0,0,0,5362,5363,7,14,0,0,5363,5364,7,20,0,0,5364,
	5365,7,3,0,0,5365,5366,7,15,0,0,5366,5367,7,12,0,0,5367,706,1,0,0,0,5368,
	5369,7,14,0,0,5369,5370,7,20,0,0,5370,5371,7,3,0,0,5371,5372,7,12,0,0,5372,
	5373,7,22,0,0,5373,5374,7,7,0,0,5374,5375,7,4,0,0,5375,708,1,0,0,0,5376,
	5377,7,14,0,0,5377,5378,7,20,0,0,5378,5379,7,3,0,0,5379,5380,7,12,0,0,5380,
	5381,7,12,0,0,5381,5382,7,7,0,0,5382,5383,7,5,0,0,5383,710,1,0,0,0,5384,
	5385,7,14,0,0,5385,5386,7,20,0,0,5386,5387,7,7,0,0,5387,5388,7,14,0,0,5388,
	5389,7,21,0,0,5389,5390,7,11,0,0,5390,5391,7,17,0,0,5391,5392,7,23,0,0,
	5392,712,1,0,0,0,5393,5394,7,25,0,0,5394,5395,7,3,0,0,5395,5396,7,22,0,
	0,5396,5397,7,7,0,0,5397,5398,5,95,0,0,5398,5399,7,14,0,0,5399,5400,7,20,
	0,0,5400,5401,7,7,0,0,5401,5402,7,14,0,0,5402,5403,7,21,0,0,5403,5404,7,
	11,0,0,5404,5405,7,17,0,0,5405,5406,7,23,0,0,5406,714,1,0,0,0,5407,5408,
	7,14,0,0,5408,5409,7,15,0,0,5409,5410,7,25,0,0,5410,5411,7,20,0,0,5411,
	5412,7,7,0,0,5412,5413,7,8,0,0,5413,716,1,0,0,0,5414,5415,7,14,0,0,5415,
	5416,7,5,0,0,5416,5417,7,3,0,0,5417,5418,7,11,0,0,5418,5419,7,11,0,0,5419,
	5420,5,95,0,0,5420,5421,7,19,0,0,5421,5422,7,8,0,0,5422,5423,7,15,0,0,5423,
	5424,7,22,0,0,5424,5425,7,15,0,0,5425,5426,7,12,0,0,5426,718,1,0,0,0,5427,
	5428,7,14,0,0,5428,5429,7,5,0,0,5429,5430,7,15,0,0,5430,5431,7,7,0,0,5431,
	5432,7,12,0,0,5432,5433,7,6,0,0,5433,720,1,0,0,0,5434,5435,7,14,0,0,5435,
	5436,7,5,0,0,5436,5437,7,19,0,0,5437,5438,7,11,0,0,5438,5439,7,7,0,0,5439,
	722,1,0,0,0,5440,5441,7,14,0,0,5441,5442,7,5,0,0,5442,5443,7,17,0,0,5443,
	5444,7,11,0,0,5444,5445,7,6,0,0,5445,5446,7,7,0,0,5446,5447,7,8,0,0,5447,
	5448,7,15,0,0,5448,5449,7,12,0,0,5449,5450,7,22,0,0,5450,724,1,0,0,0,5451,
	5452,7,14,0,0,5452,5453,7,19,0,0,5453,5454,7,3,0,0,5454,5455,7,5,0,0,5455,
	5456,7,7,0,0,5456,5457,7,11,0,0,5457,5458,7,14,0,0,5458,5459,7,7,0,0,5459,
	726,1,0,0,0,5460,5461,7,14,0,0,5461,5462,7,19,0,0,5462,5463,7,4,0,0,5463,
	5464,7,7,0,0,5464,728,1,0,0,0,5465,5466,7,14,0,0,5466,5467,7,19,0,0,5467,
	5468,7,5,0,0,5468,5469,7,17,0,0,5469,5470,7,23,0,0,5470,5471,7,12,0,0,5471,
	5472,7,11,0,0,5472,730,1,0,0,0,5473,5474,7,14,0,0,5474,5475,7,19,0,0,5475,
	5476,7,5,0,0,5476,5477,7,17,0,0,5477,5478,7,23,0,0,5478,5479,7,12,0,0,5479,
	5480,5,95,0,0,5480,5481,7,18,0,0,5481,5482,7,19,0,0,5482,5483,7,8,0,0,5483,
	5484,7,23,0,0,5484,5485,7,3,0,0,5485,5486,7,6,0,0,5486,732,1,0,0,0,5487,
	5488,7,14,0,0,5488,5489,7,19,0,0,5489,5490,7,5,0,0,5490,5491,7,17,0,0,5491,
	5492,7,23,0,0,5492,5493,7,12,0,0,5493,5494,5,95,0,0,5494,5495,7,12,0,0,
	5495,5496,7,3,0,0,5496,5497,7,23,0,0,5497,5498,7,7,0,0,5498,734,1,0,0,0,
	5499,5500,7,14,0,0,5500,5501,7,19,0,0,5501,5502,7,23,0,0,5502,5503,7,23,
	0,0,5503,5504,7,7,0,0,5504,5505,7,12,0,0,5505,5506,7,6,0,0,5506,736,1,0,
	0,0,5507,5508,7,14,0,0,5508,5509,7,19,0,0,5509,5510,7,23,0,0,5510,5511,
	7,23,0,0,5511,5512,7,15,0,0,5512,5513,7,6,0,0,5513,738,1,0,0,0,5514,5515,
	7,14,0,0,5515,5516,7,19,0,0,5516,5517,7,23,0,0,5517,5518,7,25,0,0,5518,
	5519,7,3,0,0,5519,5520,7,14,0,0,5520,5521,7,6,0,0,5521,740,1,0,0,0,5522,
	5523,7,14,0,0,5523,5524,7,19,0,0,5524,5525,7,23,0,0,5525,5526,7,25,0,0,
	5526,5527,7,5,0,0,5527,5528,7,7,0,0,5528,5529,7,6,0,0,5529,5530,7,15,0,
	0,5530,5531,7,19,0,0,5531,5532,7,12,0,0,5532,742,1,0,0,0,5533,5534,7,14,
	0,0,5534,5535,7,19,0,0,5535,5536,7,23,0,0,5536,5537,7,25,0,0,5537,5538,
	7,8,0,0,5538,5539,7,7,0,0,5539,5540,7,11,0,0,5540,5541,7,11,0,0,5541,5542,
	7,7,0,0,5542,5543,7,4,0,0,5543,744,1,0,0,0,5544,5545,7,14,0,0,5545,5546,
	7,19,0,0,5546,5547,7,23,0,0,5547,5548,7,25,0,0,5548,5549,7,8,0,0,5549,5550,
	7,7,0,0,5550,5551,7,11,0,0,5551,5552,7,11,0,0,5552,5553,7,15,0,0,5553,5554,
	7,19,0,0,5554,5555,7,12,0,0,5555,746,1,0,0,0,5556,5557,7,14,0,0,5557,5558,
	7,19,0,0,5558,5559,7,12,0,0,5559,5560,7,14,0,0,5560,5561,7,17,0,0,5561,
	5562,7,8,0,0,5562,5563,7,8,0,0,5563,5564,7,7,0,0,5564,5565,7,12,0,0,5565,
	5566,7,6,0,0,5566,748,1,0,0,0,5567,5568,7,14,0,0,5568,5569,7,19,0,0,5569,
	5570,7,12,0,0,5570,5571,7,12,0,0,5571,5572,7,7,0,0,5572,5573,7,14,0,0,5573,
	5574,7,6,0,0,5574,750,1,0,0,0,5575,5576,7,14,0,0,5576,5577,7,19,0,0,5577,
	5578,7,12,0,0,5578,5579,7,12,0,0,5579,5580,7,7,0,0,5580,5581,7,14,0,0,5581,
	5582,7,6,0,0,5582,5583,7,15,0,0,5583,5584,7,19,0,0,5584,5585,7,12,0,0,5585,
	752,1,0,0,0,5586,5587,7,14,0,0,5587,5588,7,19,0,0,5588,5589,7,12,0,0,5589,
	5590,7,11,0,0,5590,5591,7,15,0,0,5591,5592,7,11,0,0,5592,5593,7,6,0,0,5593,
	5594,7,7,0,0,5594,5595,7,12,0,0,5595,5596,7,6,0,0,5596,754,1,0,0,0,5597,
	5598,7,14,0,0,5598,5599,7,19,0,0,5599,5600,7,12,0,0,5600,5601,7,11,0,0,
	5601,5602,7,6,0,0,5602,5603,7,8,0,0,5603,5604,7,3,0,0,5604,5605,7,15,0,
	0,5605,5606,7,12,0,0,5606,5607,7,6,0,0,5607,5608,5,95,0,0,5608,5609,7,14,
	0,0,5609,5610,7,3,0,0,5610,5611,7,6,0,0,5611,5612,7,3,0,0,5612,5613,7,5,
	0,0,5613,5614,7,19,0,0,5614,5615,7,22,0,0,5615,756,1,0,0,0,5616,5617,7,
	14,0,0,5617,5618,7,19,0,0,5618,5619,7,12,0,0,5619,5620,7,11,0,0,5620,5621,
	7,6,0,0,5621,5622,7,8,0,0,5622,5623,7,3,0,0,5623,5624,7,15,0,0,5624,5625,
	7,12,0,0,5625,5626,7,6,0,0,5626,5627,5,95,0,0,5627,5628,7,11,0,0,5628,5629,
	7,14,0,0,5629,5630,7,20,0,0,5630,5631,7,7,0,0,5631,5632,7,23,0,0,5632,5633,
	7,3,0,0,5633,758,1,0,0,0,5634,5635,7,14,0,0,5635,5636,7,19,0,0,5636,5637,
	7,12,0,0,5637,5638,7,11,0,0,5638,5639,7,6,0,0,5639,5640,7,8,0,0,5640,5641,
	7,3,0,0,5641,5642,7,15,0,0,5642,5643,7,12,0,0,5643,5644,7,6,0,0,5644,5645,
	5,95,0,0,5645,5646,7,12,0,0,5646,5647,7,3,0,0,5647,5648,7,23,0,0,5648,5649,
	7,7,0,0,5649,760,1,0,0,0,5650,5651,7,14,0,0,5651,5652,7,19,0,0,5652,5653,
	7,12,0,0,5653,5654,7,6,0,0,5654,5655,7,3,0,0,5655,5656,7,15,0,0,5656,5657,
	7,12,0,0,5657,5658,7,11,0,0,5658,762,1,0,0,0,5659,5660,7,14,0,0,5660,5661,
	7,19,0,0,5661,5662,7,12,0,0,5662,5663,7,6,0,0,5663,5664,7,7,0,0,5664,5665,
	7,26,0,0,5665,5666,7,6,0,0,5666,764,1,0,0,0,5667,5668,7,14,0,0,5668,5669,
	7,19,0,0,5669,5670,7,12,0,0,5670,5671,7,6,0,0,5671,5672,7,8,0,0,5672,5673,
	7,15,0,0,5673,5674,7,16,0,0,5674,5675,7,17,0,0,5675,5676,7,6,0,0,5676,5677,
	7,19,0,0,5677,5678,7,8,0,0,5678,5679,7,11,0,0,5679,766,1,0,0,0,5680,5681,
	7,14,0,0,5681,5682,7,19,0,0,5682,5683,7,25,0,0,5683,5684,7,10,0,0,5684,
	768,1,0,0,0,5685,5686,7,14,0,0,5686,5687,7,25,0,0,5687,5688,7,17,0,0,5688,
	770,1,0,0,0,5689,5690,7,14,0,0,5690,5691,7,10,0,0,5691,5692,7,14,0,0,5692,
	5693,7,5,0,0,5693,5694,7,7,0,0,5694,772,1,0,0,0,5695,5696,7,14,0,0,5696,
	5697,7,17,0,0,5697,5698,7,8,0,0,5698,5699,7,11,0,0,5699,5700,7,19,0,0,5700,
	5701,7,8,0,0,5701,5702,5,95,0,0,5702,5703,7,12,0,0,5703,5704,7,3,0,0,5704,
	5705,7,23,0,0,5705,5706,7,7,0,0,5706,774,1,0,0,0,5707,5708,7,4,0,0,5708,
	5709,7,3,0,0,5709,5710,7,6,0,0,5710,5711,7,3,0,0,5711,776,1,0,0,0,5712,
	5713,7,4,0,0,5713,5714,7,3,0,0,5714,5715,7,6,0,0,5715,5716,7,3,0,0,5716,
	5717,7,18,0,0,5717,5718,7,15,0,0,5718,5719,7,5,0,0,5719,5720,7,7,0,0,5720,
	778,1,0,0,0,5721,5722,7,4,0,0,5722,5723,7,7,0,0,5723,5724,7,3,0,0,5724,
	5725,7,5,0,0,5725,5726,7,5,0,0,5726,5727,7,19,0,0,5727,5728,7,14,0,0,5728,
	5729,7,3,0,0,5729,5730,7,6,0,0,5730,5731,7,7,0,0,5731,780,1,0,0,0,5732,
	5733,7,4,0,0,5733,5734,7,7,0,0,5734,5735,7,18,0,0,5735,5736,7,3,0,0,5736,
	5737,7,17,0,0,5737,5738,7,5,0,0,5738,5739,7,6,0,0,5739,5740,5,95,0,0,5740,
	5741,7,3,0,0,5741,5742,7,17,0,0,5742,5743,7,6,0,0,5743,5744,7,20,0,0,5744,
	782,1,0,0,0,5745,5746,7,4,0,0,5746,5747,7,7,0,0,5747,5748,7,18,0,0,5748,
	5749,7,15,0,0,5749,5750,7,12,0,0,5750,5751,7,7,0,0,5751,5752,7,8,0,0,5752,
	784,1,0,0,0,5753,5754,7,4,0,0,5754,5755,7,7,0,0,5755,5756,7,5,0,0,5756,
	5757,7,3,0,0,5757,5758,7,10,0,0,5758,5759,5,95,0,0,5759,5760,7,21,0,0,5760,
	5761,7,7,0,0,5761,5762,7,10,0,0,5762,5763,5,95,0,0,5763,5764,7,9,0,0,5764,
	5765,7,8,0,0,5765,5766,7,15,0,0,5766,5767,7,6,0,0,5767,5768,7,7,0,0,5768,
	786,1,0,0,0,5769,5770,7,4,0,0,5770,5771,7,7,0,0,5771,5772,7,11,0,0,5772,
	5773,5,95,0,0,5773,5774,7,21,0,0,5774,5775,7,7,0,0,5775,5776,7,10,0,0,5776,
	5777,5,95,0,0,5777,5778,7,18,0,0,5778,5779,7,15,0,0,5779,5780,7,5,0,0,5780,
	5781,7,7,0,0,5781,788,1,0,0,0,5782,5783,7,4,0,0,5783,5784,7,15,0,0,5784,
	5785,7,8,0,0,5785,5786,7,7,0,0,5786,5787,7,14,0,0,5787,5788,7,6,0,0,5788,
	5789,7,19,0,0,5789,5790,7,8,0,0,5790,5791,7,10,0,0,5791,790,1,0,0,0,5792,
	5793,7,4,0,0,5793,5794,7,15,0,0,5794,5795,7,11,0,0,5795,5796,7,3,0,0,5796,
	5797,7,16,0,0,5797,5798,7,5,0,0,5798,5799,7,7,0,0,5799,792,1,0,0,0,5800,
	5801,7,4,0,0,5801,5802,7,15,0,0,5802,5803,7,11,0,0,5803,5804,7,14,0,0,5804,
	5805,7,3,0,0,5805,5806,7,8,0,0,5806,5807,7,4,0,0,5807,794,1,0,0,0,5808,
	5809,7,4,0,0,5809,5810,7,15,0,0,5810,5811,7,11,0,0,5811,5812,7,21,0,0,5812,
	796,1,0,0,0,5813,5814,7,4,0,0,5814,5815,7,19,0,0,5815,798,1,0,0,0,5816,
	5817,7,4,0,0,5817,5818,7,17,0,0,5818,5819,7,23,0,0,5819,5820,7,25,0,0,5820,
	5821,7,18,0,0,5821,5822,7,15,0,0,5822,5823,7,5,0,0,5823,5824,7,7,0,0,5824,
	800,1,0,0,0,5825,5826,7,4,0,0,5826,5827,7,17,0,0,5827,5828,7,25,0,0,5828,
	5829,7,5,0,0,5829,5830,7,15,0,0,5830,5831,7,14,0,0,5831,5832,7,3,0,0,5832,
	5833,7,6,0,0,5833,5834,7,7,0,0,5834,802,1,0,0,0,5835,5836,7,4,0,0,5836,
	5837,7,10,0,0,5837,5838,7,12,0,0,5838,5839,7,3,0,0,5839,5840,7,23,0,0,5840,
	5841,7,15,0,0,5841,5842,7,14,0,0,5842,804,1,0,0,0,5843,5844,7,7,0,0,5844,
	5845,7,12,0,0,5845,5846,7,3,0,0,5846,5847,7,16,0,0,5847,5848,7,5,0,0,5848,
	5849,7,7,0,0,5849,806,1,0,0,0,5850,5851,7,7,0,0,5851,5852,7,12,0,0,5852,
	5853,7,14,0,0,5853,5854,7,8,0,0,5854,5855,7,10,0,0,5855,5856,7,25,0,0,5856,
	5857,7,6,0,0,5857,5858,7,7,0,0,5858,5859,7,4,0,0,5859,808,1,0,0,0,5860,
	5861,7,7,0,0,5861,5862,7,12,0,0,5862,5863,7,14,0,0,5863,5864,7,8,0,0,5864,
	5865,7,10,0,0,5865,5866,7,25,0,0,5866,5867,7,6,0,0,5867,5868,7,15,0,0,5868,
	5869,7,19,0,0,5869,5870,7,12,0,0,5870,810,1,0,0,0,5871,5872,7,7,0,0,5872,
	5873,7,12,0,0,5873,5874,7,14,0,0,5874,5875,7,8,0,0,5875,5876,7,10,0,0,5876,
	5877,7,25,0,0,5877,5878,7,6,0,0,5878,5879,7,15,0,0,5879,5880,7,19,0,0,5880,
	5881,7,12,0,0,5881,5882,5,95,0,0,5882,5883,7,21,0,0,5883,5884,7,7,0,0,5884,
	5885,7,10,0,0,5885,5886,5,95,0,0,5886,5887,7,15,0,0,5887,5888,7,4,0,0,5888,
	812,1,0,0,0,5889,5890,7,7,0,0,5890,5891,7,12,0,0,5891,5892,7,4,0,0,5892,
	814,1,0,0,0,5893,5894,7,7,0,0,5894,5895,7,12,0,0,5895,5896,7,4,0,0,5896,
	5897,7,11,0,0,5897,816,1,0,0,0,5898,5899,7,7,0,0,5899,5900,7,12,0,0,5900,
	5901,7,22,0,0,5901,5902,7,15,0,0,5902,5903,7,12,0,0,5903,5904,7,7,0,0,5904,
	818,1,0,0,0,5905,5906,7,7,0,0,5906,5907,7,12,0,0,5907,5908,7,22,0,0,5908,
	5909,7,15,0,0,5909,5910,7,12,0,0,5910,5911,7,7,0,0,5911,5912,7,11,0,0,5912,
	820,1,0,0,0,5913,5914,7,7,0,0,5914,5915,7,8,0,0,5915,5916,7,8,0,0,5916,
	5917,7,19,0,0,5917,5918,7,8,0,0,5918,822,1,0,0,0,5919,5920,7,7,0,0,5920,
	5921,7,8,0,0,5921,5922,7,8,0,0,5922,5923,7,19,0,0,5923,5924,7,8,0,0,5924,
	5925,7,11,0,0,5925,824,1,0,0,0,5926,5927,7,7,0,0,5927,5928,7,11,0,0,5928,
	5929,7,14,0,0,5929,5930,7,3,0,0,5930,5931,7,25,0,0,5931,5932,7,7,0,0,5932,
	826,1,0,0,0,5933,5934,7,7,0,0,5934,5935,7,24,0,0,5935,5936,7,7,0,0,5936,
	5937,7,12,0,0,5937,828,1,0,0,0,5938,5939,7,7,0,0,5939,5940,7,24,0,0,5940,
	5941,7,7,0,0,5941,5942,7,12,0,0,5942,5943,7,6,0,0,5943,830,1,0,0,0,5944,
	5945,7,7,0,0,5945,5946,7,24,0,0,5946,5947,7,7,0,0,5947,5948,7,12,0,0,5948,
	5949,7,6,0,0,5949,5950,7,11,0,0,5950,832,1,0,0,0,5951,5952,7,7,0,0,5952,
	5953,7,24,0,0,5953,5954,7,7,0,0,5954,5955,7,8,0,0,5955,5956,7,10,0,0,5956,
	834,1,0,0,0,5957,5958,7,7,0,0,5958,5959,7,26,0,0,5959,5960,7,14,0,0,5960,
	5961,7,20,0,0,5961,5962,7,3,0,0,5962,5963,7,12,0,0,5963,5964,7,22,0,0,5964,
	5965,7,7,0,0,5965,836,1,0,0,0,5966,5967,7,7,0,0,5967,5968,7,26,0,0,5968,
	5969,7,14,0,0,5969,5970,7,5,0,0,5970,5971,7,17,0,0,5971,5972,7,11,0,0,5972,
	5973,7,15,0,0,5973,5974,7,24,0,0,5974,5975,7,7,0,0,5975,838,1,0,0,0,5976,
	5977,7,7,0,0,5977,5978,7,26,0,0,5978,5979,7,25,0,0,5979,5980,7,15,0,0,5980,
	5981,7,8,0,0,5981,5982,7,7,0,0,5982,840,1,0,0,0,5983,5984,7,7,0,0,5984,
	5985,7,26,0,0,5985,5986,7,25,0,0,5986,5987,7,19,0,0,5987,5988,7,8,0,0,5988,
	5989,7,6,0,0,5989,842,1,0,0,0,5990,5991,7,7,0,0,5991,5992,7,26,0,0,5992,
	5993,7,6,0,0,5993,5994,7,7,0,0,5994,5995,7,12,0,0,5995,5996,7,4,0,0,5996,
	5997,7,7,0,0,5997,5998,7,4,0,0,5998,844,1,0,0,0,5999,6000,7,7,0,0,6000,
	6001,7,26,0,0,6001,6002,7,6,0,0,6002,6003,7,7,0,0,6003,6004,7,12,0,0,6004,
	6005,7,6,0,0,6005,6006,5,95,0,0,6006,6007,7,11,0,0,6007,6008,7,15,0,0,6008,
	6009,7,13,0,0,6009,6010,7,7,0,0,6010,846,1,0,0,0,6011,6012,7,18,0,0,6012,
	6013,7,3,0,0,6013,6014,7,15,0,0,6014,6015,7,5,0,0,6015,6016,7,7,0,0,6016,
	6017,7,4,0,0,6017,6018,5,95,0,0,6018,6019,7,5,0,0,6019,6020,7,19,0,0,6020,
	6021,7,22,0,0,6021,6022,7,15,0,0,6022,6023,7,12,0,0,6023,6024,5,95,0,0,
	6024,6025,7,3,0,0,6025,6026,7,6,0,0,6026,6027,7,6,0,0,6027,6028,7,7,0,0,
	6028,6029,7,23,0,0,6029,6030,7,25,0,0,6030,6031,7,6,0,0,6031,6032,7,11,
	0,0,6032,848,1,0,0,0,6033,6034,7,18,0,0,6034,6035,7,3,0,0,6035,6036,7,11,
	0,0,6036,6037,7,6,0,0,6037,850,1,0,0,0,6038,6039,7,18,0,0,6039,6040,7,3,
	0,0,6040,6041,7,17,0,0,6041,6042,7,5,0,0,6042,6043,7,6,0,0,6043,6044,7,
	11,0,0,6044,852,1,0,0,0,6045,6046,7,18,0,0,6046,6047,7,15,0,0,6047,6048,
	7,7,0,0,6048,6049,7,5,0,0,6049,6050,7,4,0,0,6050,6051,7,11,0,0,6051,854,
	1,0,0,0,6052,6053,7,18,0,0,6053,6054,7,15,0,0,6054,6055,7,5,0,0,6055,6056,
	7,7,0,0,6056,6057,5,95,0,0,6057,6058,7,16,0,0,6058,6059,7,5,0,0,6059,6060,
	7,19,0,0,6060,6061,7,14,0,0,6061,6062,7,21,0,0,6062,6063,5,95,0,0,6063,
	6064,7,11,0,0,6064,6065,7,15,0,0,6065,6066,7,13,0,0,6066,6067,7,7,0,0,6067,
	856,1,0,0,0,6068,6069,7,18,0,0,6069,6070,7,15,0,0,6070,6071,7,5,0,0,6071,
	6072,7,6,0,0,6072,6073,7,7,0,0,6073,6074,7,8,0,0,6074,858,1,0,0,0,6075,
	6076,7,18,0,0,6076,6077,7,15,0,0,6077,6078,7,8,0,0,6078,6079,7,11,0,0,6079,
	6080,7,6,0,0,6080,860,1,0,0,0,6081,6082,7,18,0,0,6082,6083,7,15,0,0,6083,
	6084,7,26,0,0,6084,6085,7,7,0,0,6085,6086,7,4,0,0,6086,862,1,0,0,0,6087,
	6088,7,18,0,0,6088,6089,7,5,0,0,6089,6090,7,17,0,0,6090,6091,7,11,0,0,6091,
	6092,7,20,0,0,6092,864,1,0,0,0,6093,6094,7,18,0,0,6094,6095,7,19,0,0,6095,
	6096,7,5,0,0,6096,6097,7,5,0,0,6097,6098,7,19,0,0,6098,6099,7,9,0,0,6099,
	6100,7,15,0,0,6100,6101,7,12,0,0,6101,6102,7,22,0,0,6102,866,1,0,0,0,6103,
	6104,7,18,0,0,6104,6105,7,19,0,0,6105,6106,7,5,0,0,6106,6107,7,5,0,0,6107,
	6108,7,19,0,0,6108,6109,7,9,0,0,6109,6110,7,11,0,0,6110,868,1,0,0,0,6111,
	6112,7,18,0,0,6112,6113,7,19,0,0,6113,6114,7,17,0,0,6114,6115,7,12,0,0,
	6115,6116,7,4,0,0,6116,870,1,0,0,0,6117,6118,7,18,0,0,6118,6119,7,17,0,
	0,6119,6120,7,5,0,0,6120,6121,7,5,0,0,6121,872,1,0,0,0,6122,6123,7,18,0,
	0,6123,6124,7,17,0,0,6124,6125,7,12,0,0,6125,6126,7,14,0,0,6126,6127,7,
	6,0,0,6127,6128,7,15,0,0,6128,6129,7,19,0,0,6129,6130,7,12,0,0,6130,874,
	1,0,0,0,6131,6132,7,22,0,0,6132,6133,7,7,0,0,6133,6134,7,12,0,0,6134,6135,
	7,7,0,0,6135,6136,7,8,0,0,6136,6137,7,3,0,0,6137,6138,7,5,0,0,6138,876,
	1,0,0,0,6139,6140,7,22,0,0,6140,6141,7,5,0,0,6141,6142,7,19,0,0,6142,6143,
	7,16,0,0,6143,6144,7,3,0,0,6144,6145,7,5,0,0,6145,878,1,0,0,0,6146,6147,
	7,22,0,0,6147,6148,7,8,0,0,6148,6149,7,3,0,0,6149,6150,7,12,0,0,6150,6151,
	7,6,0,0,6151,6152,7,11,0,0,6152,880,1,0,0,0,6153,6154,7,22,0,0,6154,6155,
	7,8,0,0,6155,6156,7,19,0,0,6156,6157,7,17,0,0,6157,6158,7,25,0,0,6158,6159,
	5,95,0,0,6159,6160,7,8,0,0,6160,6161,7,7,0,0,6161,6162,7,25,0,0,6162,6163,
	7,5,0,0,6163,6164,7,15,0,0,6164,6165,7,14,0,0,6165,6166,7,3,0,0,6166,6167,
	7,6,0,0,6167,6168,7,15,0,0,6168,6169,7,19,0,0,6169,6170,7,12,0,0,6170,882,
	1,0,0,0,6171,6172,7,20,0,0,6172,6173,7,3,0,0,6173,6174,7,12,0,0,6174,6175,
	7,4,0,0,6175,6176,7,5,0,0,6176,6177,7,7,0,0,6177,6178,7,8,0,0,6178,884,
	1,0,0,0,6179,6180,7,20,0,0,6180,6181,7,3,0,0,6181,6182,7,11,0,0,6182,6183,
	7,20,0,0,6183,886,1,0,0,0,6184,6185,7,20,0,0,6185,6186,7,7,0,0,6186,6187,
	7,5,0,0,6187,6188,7,25,0,0,6188,888,1,0,0,0,6189,6190,7,20,0,0,6190,6191,
	7,15,0,0,6191,6192,7,11,0,0,6192,6193,7,6,0,0,6193,6194,7,19,0,0,6194,6195,
	7,8,0,0,6195,6196,7,10,0,0,6196,890,1,0,0,0,6197,6198,7,20,0,0,6198,6199,
	7,19,0,0,6199,6200,7,11,0,0,6200,6201,7,6,0,0,6201,892,1,0,0,0,6202,6203,
	7,20,0,0,6203,6204,7,19,0,0,6204,6205,7,11,0,0,6205,6206,7,6,0,0,6206,6207,
	7,11,0,0,6207,894,1,0,0,0,6208,6209,7,15,0,0,6209,6210,7,4,0,0,6210,6211,
	7,7,0,0,6211,6212,7,12,0,0,6212,6213,7,6,0,0,6213,6214,7,15,0,0,6214,6215,
	7,18,0,0,6215,6216,7,15,0,0,6216,6217,7,7,0,0,6217,6218,7,4,0,0,6218,896,
	1,0,0,0,6219,6220,7,15,0,0,6220,6221,7,22,0,0,6221,6222,7,12,0,0,6222,6223,
	7,19,0,0,6223,6224,7,8,0,0,6224,6225,7,7,0,0,6225,6226,5,95,0,0,6226,6227,
	7,11,0,0,6227,6228,7,7,0,0,6228,6229,7,8,0,0,6229,6230,7,24,0,0,6230,6231,
	7,7,0,0,6231,6232,7,8,0,0,6232,6233,5,95,0,0,6233,6234,7,15,0,0,6234,6235,
	7,4,0,0,6235,6236,7,11,0,0,6236,898,1,0,0,0,6237,6238,7,15,0,0,6238,6239,
	7,23,0,0,6239,6240,7,25,0,0,6240,6241,7,19,0,0,6241,6242,7,8,0,0,6242,6243,
	7,6,0,0,6243,900,1,0,0,0,6244,6245,7,15,0,0,6245,6246,7,12,0,0,6246,6247,
	7,14,0,0,6247,6248,7,8,0,0,6248,6249,7,7,0,0,6249,6250,7,23,0,0,6250,6251,
	7,7,0,0,6251,6252,7,12,0,0,6252,6253,7,6,0,0,6253,902,1,0,0,0,6254,6255,
	7,15,0,0,6255,6256,7,12,0,0,6256,6257,7,4,0,0,6257,6258,7,7,0,0,6258,6259,
	7,26,0,0,6259,6260,7,7,0,0,6260,6261,7,11,0,0,6261,904,1,0,0,0,6262,6263,
	7,15,0,0,6263,6264,7,12,0,0,6264,6265,7,15,0,0,6265,6266,7,6,0,0,6266,6267,
	7,15,0,0,6267,6268,7,3,0,0,6268,6269,7,5,0,0,6269,6270,5,95,0,0,6270,6271,
	7,11,0,0,6271,6272,7,15,0,0,6272,6273,7,13,0,0,6273,6274,7,7,0,0,6274,906,
	1,0,0,0,6275,6276,7,15,0,0,6276,6277,7,12,0,0,6277,6278,7,25,0,0,6278,6279,
	7,5,0,0,6279,6280,7,3,0,0,6280,6281,7,14,0,0,6281,6282,7,7,0,0,6282,908,
	1,0,0,0,6283,6284,7,15,0,0,6284,6285,7,12,0,0,6285,6286,7,11,0,0,6286,6287,
	7,7,0,0,6287,6288,7,8,0,0,6288,6289,7,6,0,0,6289,6290,5,95,0,0,6290,6291,
	7,23,0,0,6291,6292,7,7,0,0,6292,6293,7,6,0,0,6293,6294,7,20,0,0,6294,6295,
	7,19,0,0,6295,6296,7,4,0,0,6296,910,1,0,0,0,6297,6298,7,15,0,0,6298,6299,
	7,12,0,0,6299,6300,7,11,0,0,6300,6301,7,6,0,0,6301,6302,7,3,0,0,6302,6303,
	7,5,0,0,6303,6304,7,5,0,0,6304,912,1,0,0,0,6305,6306,7,15,0,0,6306,6307,
	7,12,0,0,6307,6308,7,11,0,0,6308,6309,7,6,0,0,6309,6310,7,3,0,0,6310,6311,
	7,12,0,0,6311,6312,7,14,0,0,6312,6313,7,7,0,0,6313,914,1,0,0,0,6314,6315,
	7,15,0,0,6315,6316,7,12,0,0,6316,6317,7,11,0,0,6317,6318,7,6,0,0,6318,6319,
	7,3,0,0,6319,6320,7,12,0,0,6320,6321,7,6,0,0,6321,916,1,0,0,0,6322,6323,
	7,15,0,0,6323,6324,7,12,0,0,6324,6325,7,24,0,0,6325,6326,7,15,0,0,6326,
	6327,7,11,0,0,6327,6328,7,15,0,0,6328,6329,7,16,0,0,6329,6330,7,5,0,0,6330,
	6331,7,7,0,0,6331,918,1,0,0,0,6332,6333,7,15,0,0,6333,6334,7,12,0,0,6334,
	6335,7,24,0,0,6335,6336,7,19,0,0,6336,6337,7,21,0,0,6337,6338,7,7,0,0,6338,
	6339,7,8,0,0,6339,920,1,0,0,0,6340,6341,7,15,0,0,6341,6342,7,19,0,0,6342,
	922,1,0,0,0,6343,6344,7,15,0,0,6344,6345,7,19,0,0,6345,6346,5,95,0,0,6346,
	6347,7,6,0,0,6347,6348,7,20,0,0,6348,6349,7,8,0,0,6349,6350,7,7,0,0,6350,
	6351,7,3,0,0,6351,6352,7,4,0,0,6352,924,1,0,0,0,6353,6354,7,15,0,0,6354,
	6355,7,25,0,0,6355,6356,7,14,0,0,6356,926,1,0,0,0,6357,6358,7,15,0,0,6358,
	6359,7,11,0,0,6359,6360,7,19,0,0,6360,6361,7,5,0,0,6361,6362,7,3,0,0,6362,
	6363,7,6,0,0,6363,6364,7,15,0,0,6364,6365,7,19,0,0,6365,6366,7,12,0,0,6366,
	928,1,0,0,0,6367,6368,7,15,0,0,6368,6369,7,11,0,0,6369,6370,7,11,0,0,6370,
	6371,7,17,0,0,6371,6372,7,7,0,0,6372,6373,7,8,0,0,6373,930,1,0,0,0,6374,
	6375,7,27,0,0,6375,6376,7,11,0,0,6376,6377,7,19,0,0,6377,6378,7,12,0,0,
	6378,932,1,0,0,0,6379,6380,7,21,0,0,6380,6381,7,7,0,0,6381,6382,7,10,0,
	0,6382,6383,5,95,0,0,6383,6384,7,16,0,0,6384,6385,7,5,0,0,6385,6386,7,19,
	0,0,6386,6387,7,14,0,0,6387,6388,7,21,0,0,6388,6389,5,95,0,0,6389,6390,
	7,11,0,0,6390,6391,7,15,0,0,6391,6392,7,13,0,0,6392,6393,7,7,0,0,6393,934,
	1,0,0,0,6394,6395,7,5,0,0,6395,6396,7,3,0,0,6396,6397,7,12,0,0,6397,6398,
	7,22,0,0,6398,6399,7,17,0,0,6399,6400,7,3,0,0,6400,6401,7,22,0,0,6401,6402,
	7,7,0,0,6402,936,1,0,0,0,6403,6404,7,5,0,0,6404,6405,7,3,0,0,6405,6406,
	7,11,0,0,6406,6407,7,6,0,0,6407,938,1,0,0,0,6408,6409,7,5,0,0,6409,6410,
	7,7,0,0,6410,6411,7,3,0,0,6411,6412,7,24,0,0,6412,6413,7,7,0,0,6413,6414,
	7,11,0,0,6414,940,1,0,0,0,6415,6416,7,5,0,0,6416,6417,7,7,0,0,6417,6418,
	7,11,0,0,6418,6419,7,11,0,0,6419,942,1,0,0,0,6420,6421,7,5,0,0,6421,6422,
	7,7,0,0,6422,6423,7,24,0,0,6423,6424,7,7,0,0,6424,6425,7,5,0,0,6425,944,
	1,0,0,0,6426,6427,7,5,0,0,6427,6428,7,15,0,0,6428,6429,7,11,0,0,6429,6430,
	7,6,0,0,6430,946,1,0,0,0,6431,6432,7,5,0,0,6432,6433,7,19,0,0,6433,6434,
	7,14,0,0,6434,6435,7,3,0,0,6435,6436,7,5,0,0,6436,948,1,0,0,0,6437,6438,
	7,5,0,0,6438,6439,7,19,0,0,6439,6440,7,22,0,0,6440,6441,7,18,0,0,6441,6442,
	7,15,0,0,6442,6443,7,5,0,0,6443,6444,7,7,0,0,6444,950,1,0,0,0,6445,6446,
	7,5,0,0,6446,6447,7,19,0,0,6447,6448,7,22,0,0,6448,6449,7,11,0,0,6449,952,
	1,0,0,0,6450,6451,7,23,0,0,6451,6452,7,3,0,0,6452,6453,7,11,0,0,6453,6454,
	7,6,0,0,6454,6455,7,7,0,0,6455,6456,7,8,0,0,6456,954,1,0,0,0,6457,6458,
	7,23,0,0,6458,6459,7,3,0,0,6459,6460,7,11,0,0,6460,6461,7,6,0,0,6461,6462,
	7,7,0,0,6462,6463,7,8,0,0,6463,6464,5,95,0,0,6464,6465,7,3,0,0,6465,6466,
	7,17,0,0,6466,6467,7,6,0,0,6467,6468,7,19,0,0,6468,6469,5,95,0,0,6469,6470,
	7,25,0,0,6470,6471,7,19,0,0,6471,6472,7,11,0,0,6472,6473,7,15,0,0,6473,
	6474,7,6,0,0,6474,6475,7,15,0,0,6475,6476,7,19,0,0,6476,6477,7,12,0,0,6477,
	956,1,0,0,0,6478,6479,7,23,0,0,6479,6480,7,3,0,0,6480,6481,7,11,0,0,6481,
	6482,7,6,0,0,6482,6483,7,7,0,0,6483,6484,7,8,0,0,6484,6485,5,95,0,0,6485,
	6486,7,14,0,0,6486,6487,7,19,0,0,6487,6488,7,12,0,0,6488,6489,7,12,0,0,
	6489,6490,7,7,0,0,6490,6491,7,14,0,0,6491,6492,7,6,0,0,6492,6493,5,95,0,
	0,6493,6494,7,8,0,0,6494,6495,7,7,0,0,6495,6496,7,6,0,0,6496,6497,7,8,0,
	0,6497,6498,7,10,0,0,6498,958,1,0,0,0,6499,6500,7,23,0,0,6500,6501,7,3,
	0,0,6501,6502,7,11,0,0,6502,6503,7,6,0,0,6503,6504,7,7,0,0,6504,6505,7,
	8,0,0,6505,6506,5,95,0,0,6506,6507,7,4,0,0,6507,6508,7,7,0,0,6508,6509,
	7,5,0,0,6509,6510,7,3,0,0,6510,6511,7,10,0,0,6511,960,1,0,0,0,6512,6513,
	7,23,0,0,6513,6514,7,3,0,0,6514,6515,7,11,0,0,6515,6516,7,6,0,0,6516,6517,
	7,7,0,0,6517,6518,7,8,0,0,6518,6519,5,95,0,0,6519,6520,7,20,0,0,6520,6521,
	7,7,0,0,6521,6522,7,3,0,0,6522,6523,7,8,0,0,6523,6524,7,6,0,0,6524,6525,
	7,16,0,0,6525,6526,7,7,0,0,6526,6527,7,3,0,0,6527,6528,7,6,0,0,6528,6529,
	5,95,0,0,6529,6530,7,25,0,0,6530,6531,7,7,0,0,6531,6532,7,8,0,0,6532,6533,
	7,15,0,0,6533,6534,7,19,0,0,6534,6535,7,4,0,0,6535,962,1,0,0,0,6536,6537,
	7,23,0,0,6537,6538,7,3,0,0,6538,6539,7,11,0,0,6539,6540,7,6,0,0,6540,6541,
	7,7,0,0,6541,6542,7,8,0,0,6542,6543,5,95,0,0,6543,6544,7,20,0,0,6544,6545,
	7,19,0,0,6545,6546,7,11,0,0,6546,6547,7,6,0,0,6547,964,1,0,0,0,6548,6549,
	7,23,0,0,6549,6550,7,3,0,0,6550,6551,7,11,0,0,6551,6552,7,6,0,0,6552,6553,
	7,7,0,0,6553,6554,7,8,0,0,6554,6555,5,95,0,0,6555,6556,7,5,0,0,6556,6557,
	7,19,0,0,6557,6558,7,22,0,0,6558,6559,5,95,0,0,6559,6560,7,18,0,0,6560,
	6561,7,15,0,0,6561,6562,7,5,0,0,6562,6563,7,7,0,0,6563,966,1,0,0,0,6564,
	6565,7,23,0,0,6565,6566,7,3,0,0,6566,6567,7,11,0,0,6567,6568,7,6,0,0,6568,
	6569,7,7,0,0,6569,6570,7,8,0,0,6570,6571,5,95,0,0,6571,6572,7,5,0,0,6572,
	6573,7,19,0,0,6573,6574,7,22,0,0,6574,6575,5,95,0,0,6575,6576,7,25,0,0,
	6576,6577,7,19,0,0,6577,6578,7,11,0,0,6578,968,1,0,0,0,6579,6580,7,23,0,
	0,6580,6581,7,3,0,0,6581,6582,7,11,0,0,6582,6583,7,6,0,0,6583,6584,7,7,
	0,0,6584,6585,7,8,0,0,6585,6586,5,95,0,0,6586,6587,7,25,0,0,6587,6588,7,
	3,0,0,6588,6589,7,11,0,0,6589,6590,7,11,0,0,6590,6591,7,9,0,0,6591,6592,
	7,19,0,0,6592,6593,7,8,0,0,6593,6594,7,4,0,0,6594,970,1,0,0,0,6595,6596,
	7,23,0,0,6596,6597,7,3,0,0,6597,6598,7,11,0,0,6598,6599,7,6,0,0,6599,6600,
	7,7,0,0,6600,6601,7,8,0,0,6601,6602,5,95,0,0,6602,6603,7,25,0,0,6603,6604,
	7,19,0,0,6604,6605,7,8,0,0,6605,6606,7,6,0,0,6606,972,1,0,0,0,6607,6608,
	7,23,0,0,6608,6609,7,3,0,0,6609,6610,7,11,0,0,6610,6611,7,6,0,0,6611,6612,
	7,7,0,0,6612,6613,7,8,0,0,6613,6614,5,95,0,0,6614,6615,7,8,0,0,6615,6616,
	7,7,0,0,6616,6617,7,6,0,0,6617,6618,7,8,0,0,6618,6619,7,10,0,0,6619,6620,
	5,95,0,0,6620,6621,7,14,0,0,6621,6622,7,19,0,0,6622,6623,7,17,0,0,6623,
	6624,7,12,0,0,6624,6625,7,6,0,0,6625,974,1,0,0,0,6626,6627,7,23,0,0,6627,
	6628,7,3,0,0,6628,6629,7,11,0,0,6629,6630,7,6,0,0,6630,6631,7,7,0,0,6631,
	6632,7,8,0,0,6632,6633,5,95,0,0,6633,6634,7,11,0,0,6634,6635,7,11,0,0,6635,
	6636,7,5,0,0,6636,976,1,0,0,0,6637,6638,7,23,0,0,6638,6639,7,3,0,0,6639,
	6640,7,11,0,0,6640,6641,7,6,0,0,6641,6642,7,7,0,0,6642,6643,7,8,0,0,6643,
	6644,5,95,0,0,6644,6645,7,11,0,0,6645,6646,7,11,0,0,6646,6647,7,5,0,0,6647,
	6648,5,95,0,0,6648,6649,7,14,0,0,6649,6650,7,3,0,0,6650,978,1,0,0,0,6651,
	6652,7,23,0,0,6652,6653,7,3,0,0,6653,6654,7,11,0,0,6654,6655,7,6,0,0,6655,
	6656,7,7,0,0,6656,6657,7,8,0,0,6657,6658,5,95,0,0,6658,6659,7,11,0,0,6659,
	6660,7,11,0,0,6660,6661,7,5,0,0,6661,6662,5,95,0,0,6662,6663,7,14,0,0,6663,
	6664,7,3,0,0,6664,6665,7,25,0,0,6665,6666,7,3,0,0,6666,6667,7,6,0,0,6667,
	6668,7,20,0,0,6668,980,1,0,0,0,6669,6670,7,23,0,0,6670,6671,7,3,0,0,6671,
	6672,7,11,0,0,6672,6673,7,6,0,0,6673,6674,7,7,0,0,6674,6675,7,8,0,0,6675,
	6676,5,95,0,0,6676,6677,7,11,0,0,6677,6678,7,11,0,0,6678,6679,7,5,0,0,6679,
	6680,5,95,0,0,6680,6681,7,14,0,0,6681,6682,7,7,0,0,6682,6683,7,8,0,0,6683,
	6684,7,6,0,0,6684,982,1,0,0,0,6685,6686,7,23,0,0,6686,6687,7,3,0,0,6687,
	6688,7,11,0,0,6688,6689,7,6,0,0,6689,6690,7,7,0,0,6690,6691,7,8,0,0,6691,
	6692,5,95,0,0,6692,6693,7,11,0,0,6693,6694,7,11,0,0,6694,6695,7,5,0,0,6695,
	6696,5,95,0,0,6696,6697,7,14,0,0,6697,6698,7,15,0,0,6698,6699,7,25,0,0,
	6699,6700,7,20,0,0,6700,6701,7,7,0,0,6701,6702,7,8,0,0,6702,984,1,0,0,0,
	6703,6704,7,23,0,0,6704,6705,7,3,0,0,6705,6706,7,11,0,0,6706,6707,7,6,0,
	0,6707,6708,7,7,0,0,6708,6709,7,8,0,0,6709,6710,5,95,0,0,6710,6711,7,11,
	0,0,6711,6712,7,11,0,0,6712,6713,7,5,0,0,6713,6714,5,95,0,0,6714,6715,7,
	14,0,0,6715,6716,7,8,0,0,6716,6717,7,5,0,0,6717,986,1,0,0,0,6718,6719,7,
	23,0,0,6719,6720,7,3,0,0,6720,6721,7,11,0,0,6721,6722,7,6,0,0,6722,6723,
	7,7,0,0,6723,6724,7,8,0,0,6724,6725,5,95,0,0,6725,6726,7,11,0,0,6726,6727,
	7,11,0,0,6727,6728,7,5,0,0,6728,6729,5,95,0,0,6729,6730,7,14,0,0,6730,6731,
	7,8,0,0,6731,6732,7,5,0,0,6732,6733,7,25,0,0,6733,6734,7,3,0,0,6734,6735,
	7,6,0,0,6735,6736,7,20,0,0,6736,988,1,0,0,0,6737,6738,7,23,0,0,6738,6739,
	7,3,0,0,6739,6740,7,11,0,0,6740,6741,7,6,0,0,6741,6742,7,7,0,0,6742,6743,
	7,8,0,0,6743,6744,5,95,0,0,6744,6745,7,11,0,0,6745,6746,7,11,0,0,6746,6747,
	7,5,0,0,6747,6748,5,95,0,0,6748,6749,7,21,0,0,6749,6750,7,7,0,0,6750,6751,
	7,10,0,0,6751,990,1,0,0,0,6752,6753,7,23,0,0,6753,6754,7,3,0,0,6754,6755,
	7,11,0,0,6755,6756,7,6,0,0,6756,6757,7,7,0,0,6757,6758,7,8,0,0,6758,6759,
	5,95,0,0,6759,6760,7,6,0,0,6760,6761,7,5,0,0,6761,6762,7,11,0,0,6762,6763,
	5,95,0,0,6763,6764,7,24,0,0,6764,6765,7,7,0,0,6765,6766,7,8,0,0,6766,6767,
	7,11,0,0,6767,6768,7,15,0,0,6768,6769,7,19,0,0,6769,6770,7,12,0,0,6770,
	992,1,0,0,0,6771,6772,7,23,0,0,6772,6773,7,3,0,0,6773,6774,7,11,0,0,6774,
	6775,7,6,0,0,6775,6776,7,7,0,0,6776,6777,7,8,0,0,6777,6778,5,95,0,0,6778,
	6779,7,17,0,0,6779,6780,7,11,0,0,6780,6781,7,7,0,0,6781,6782,7,8,0,0,6782,
	994,1,0,0,0,6783,6784,7,23,0,0,6784,6785,7,3,0,0,6785,6786,7,26,0,0,6786,
	6787,5,95,0,0,6787,6788,7,14,0,0,6788,6789,7,19,0,0,6789,6790,7,12,0,0,
	6790,6791,7,12,0,0,6791,6792,7,7,0,0,6792,6793,7,14,0,0,6793,6794,7,6,0,
	0,6794,6795,7,15,0,0,6795,6796,7,19,0,0,6796,6797,7,12,0,0,6797,6798,7,
	11,0,0,6798,6799,5,95,0,0,6799,6800,7,25,0,0,6800,6801,7,7,0,0,6801,6802,
	7,8,0,0,6802,6803,5,95,0,0,6803,6804,7,20,0,0,6804,6805,7,19,0,0,6805,6806,
	7,17,0,0,6806,6807,7,8,0,0,6807,996,1,0,0,0,6808,6809,7,23,0,0,6809,6810,
	7,3,0,0,6810,6811,7,26,0,0,6811,6812,5,95,0,0,6812,6813,7,28,0,0,6813,6814,
	7,17,0,0,6814,6815,7,7,0,0,6815,6816,7,8,0,0,6816,6817,7,15,0,0,6817,6818,
	7,7,0,0,6818,6819,7,11,0,0,6819,6820,5,95,0,0,6820,6821,7,25,0,0,6821,6822,
	7,7,0,0,6822,6823,7,8,0,0,6823,6824,5,95,0,0,6824,6825,7,20,0,0,6825,6826,
	7,19,0,0,6826,6827,7,17,0,0,6827,6828,7,8,0,0,6828,998,1,0,0,0,6829,6830,
	7,23,0,0,6830,6831,7,3,0,0,6831,6832,7,26,0,0,6832,6833,5,95,0,0,6833,6834,
	7,8,0,0,6834,6835,7,19,0,0,6835,6836,7,9,0,0,6836,6837,7,11,0,0,6837,1000,
	1,0,0,0,6838,6839,7,23,0,0,6839,6840,7,3,0,0,6840,6841,7,26,0,0,6841,6842,
	5,95,0,0,6842,6843,7,11,0,0,6843,6844,7,15,0,0,6844,6845,7,13,0,0,6845,
	6846,7,7,0,0,6846,1002,1,0,0,0,6847,6848,7,23,0,0,6848,6849,7,3,0,0,6849,
	6850,7,26,0,0,6850,6851,5,95,0,0,6851,6852,7,17,0,0,6852,6853,7,25,0,0,
	6853,6854,7,4,0,0,6854,6855,7,3,0,0,6855,6856,7,6,0,0,6856,6857,7,7,0,0,
	6857,6858,7,11,0,0,6858,6859,5,95,0,0,6859,6860,7,25,0,0,6860,6861,7,7,
	0,0,6861,6862,7,8,0,0,6862,6863,5,95,0,0,6863,6864,7,20,0,0,6864,6865,7,
	19,0,0,6865,6866,7,17,0,0,6866,6867,7,8,0,0,6867,1004,1,0,0,0,6868,6869,
	7,23,0,0,6869,6870,7,3,0,0,6870,6871,7,26,0,0,6871,6872,5,95,0,0,6872,6873,
	7,17,0,0,6873,6874,7,11,0,0,6874,6875,7,7,0,0,6875,6876,7,8,0,0,6876,6877,
	5,95,0,0,6877,6878,7,14,0,0,6878,6879,7,19,0,0,6879,6880,7,12,0,0,6880,
	6881,7,12,0,0,6881,6882,7,7,0,0,6882,6883,7,14,0,0,6883,6884,7,6,0,0,6884,
	6885,7,15,0,0,6885,6886,7,19,0,0,6886,6887,7,12,0,0,6887,6888,7,11,0,0,
	6888,1006,1,0,0,0,6889,6890,7,23,0,0,6890,6891,7,7,0,0,6891,6892,7,4,0,
	0,6892,6893,7,15,0,0,6893,6894,7,17,0,0,6894,6895,7,23,0,0,6895,1008,1,
	0,0,0,6896,6897,7,23,0,0,6897,6898,7,7,0,0,6898,6899,7,23,0,0,6899,6900,
	7,16,0,0,6900,6901,7,7,0,0,6901,6902,7,8,0,0,6902,1010,1,0,0,0,6903,6904,
	7,23,0,0,6904,6905,7,7,0,0,6905,6906,7,8,0,0,6906,6907,7,22,0,0,6907,6908,
	7,7,0,0,6908,1012,1,0,0,0,6909,6910,7,23,0,0,6910,6911,7,7,0,0,6911,6912,
	7,11,0,0,6912,6913,7,11,0,0,6913,6914,7,3,0,0,6914,6915,7,22,0,0,6915,6916,
	7,7,0,0,6916,6917,5,95,0,0,6917,6918,7,6,0,0,6918,6919,7,7,0,0,6919,6920,
	7,26,0,0,6920,6921,7,6,0,0,6921,1014,1,0,0,0,6922,6923,7,23,0,0,6923,6924,
	7,15,0,0,6924,6925,7,4,0,0,6925,1016,1,0,0,0,6926,6927,7,23,0,0,6927,6928,
	7,15,0,0,6928,6929,7,22,0,0,6929,6930,7,8,0,0,6930,6931,7,3,0,0,6931,6932,
	7,6,0,0,6932,6933,7,7,0,0,6933,1018,1,0,0,0,6934,6935,7,23,0,0,6935,6936,
	7,15,0,0,6936,6937,7,12,0,0,6937,6938,5,95,0,0,6938,6939,7,8,0,0,6939,6940,
	7,19,0,0,6940,6941,7,9,0,0,6941,6942,7,11,0,0,6942,1020,1,0,0,0,6943,6944,
	7,23,0,0,6944,6945,7,19,0,0,6945,6946,7,4,0,0,6946,6947,7,7,0,0,6947,1022,
	1,0,0,0,6948,6949,7,23,0,0,6949,6950,7,19,0,0,6950,6951,7,4,0,0,6951,6952,
	7,15,0,0,6952,6953,7,18,0,0,6953,6954,7,10,0,0,6954,1024,1,0,0,0,6955,6956,
	7,23,0,0,6956,6957,7,17,0,0,6957,6958,7,6,0,0,6958,6959,7,7,0,0,6959,6960,
	7,26,0,0,6960,1026,1,0,0,0,6961,6962,7,23,0,0,6962,6963,7,10,0,0,6963,6964,
	7,11,0,0,6964,6965,7,28,0,0,6965,6966,7,5,0,0,6966,1028,1,0,0,0,6967,6968,
	7,23,0,0,6968,6969,7,10,0,0,6969,6970,7,11,0,0,6970,6971,7,28,0,0,6971,
	6972,7,5,0,0,6972,6973,5,95,0,0,6973,6974,7,7,0,0,6974,6975,7,8,0,0,6975,
	6976,7,8,0,0,6976,6977,7,12,0,0,6977,6978,7,19,0,0,6978,1030,1,0,0,0,6979,
	6980,7,12,0,0,6980,6981,7,3,0,0,6981,6982,7,23,0,0,6982,6983,7,7,0,0,6983,
	1032,1,0,0,0,6984,6985,7,12,0,0,6985,6986,7,3,0,0,6986,6987,7,23,0,0,6987,
	6988,7,7,0,0,6988,6989,7,11,0,0,6989,1034,1,0,0,0,6990,6991,7,12,0,0,6991,
	6992,7,14,0,0,6992,6993,7,20,0,0,6993,6994,7,3,0,0,6994,6995,7,8,0,0,6995,
	1036,1,0,0,0,6996,6997,7,12,0,0,6997,6998,7,7,0,0,6998,6999,7,24,0,0,6999,
	7000,7,7,0,0,7000,7001,7,8,0,0,7001,1038,1,0,0,0,7002,7003,7,12,0,0,7003,
	7004,7,7,0,0,7004,7005,7,26,0,0,7005,7006,7,6,0,0,7006,1040,1,0,0,0,7007,
	7008,7,12,0,0,7008,7009,7,19,0,0,7009,1042,1,0,0,0,7010,7011,7,12,0,0,7011,
	7012,7,19,0,0,7012,7013,7,14,0,0,7013,7014,7,3,0,0,7014,7015,7,14,0,0,7015,
	7016,7,20,0,0,7016,7017,7,7,0,0,7017,1044,1,0,0,0,7018,7019,7,12,0,0,7019,
	7020,7,19,0,0,7020,7021,7,14,0,0,7021,7022,7,19,0,0,7022,7023,7,25,0,0,
	7023,7024,7,10,0,0,7024,1046,1,0,0,0,7025,7026,7,12,0,0,7026,7027,7,19,
	0,0,7027,7028,7,14,0,0,7028,7029,7,10,0,0,7029,7030,7,14,0,0,7030,7031,
	7,5,0,0,7031,7032,7,7,0,0,7032,1048,1,0,0,0,7033,7034,7,12,0,0,7034,7035,
	7,19,0,0,7035,7036,7,23,0,0,7036,7037,7,3,0,0,7037,7038,7,26,0,0,7038,7039,
	7,24,0,0,7039,7040,7,3,0,0,7040,7041,7,5,0,0,7041,7042,7,17,0,0,7042,7043,
	7,7,0,0,7043,1050,1,0,0,0,7044,7045,7,12,0,0,7045,7046,7,19,0,0,7046,7047,
	7,23,0,0,7047,7048,7,15,0,0,7048,7049,7,12,0,0,7049,7050,7,24,0,0,7050,
	7051,7,3,0,0,7051,7052,7,5,0,0,7052,7053,7,17,0,0,7053,7054,7,7,0,0,7054,
	1052,1,0,0,0,7055,7056,7,12,0,0,7056,7057,7,19,0,0,7057,7058,7,9,0,0,7058,
	7059,7,3,0,0,7059,7060,7,15,0,0,7060,7061,7,6,0,0,7061,1054,1,0,0,0,7062,
	7063,7,12,0,0,7063,7064,7,19,0,0,7064,7065,7,4,0,0,7065,7066,7,7,0,0,7066,
	7067,7,22,0,0,7067,7068,7,8,0,0,7068,7069,7,19,0,0,7069,7070,7,17,0,0,7070,
	7071,7,25,0,0,7071,1056,1,0,0,0,7072,7073,7,12,0,0,7073,7074,7,19,0,0,7074,
	7075,7,12,0,0,7075,7076,7,7,0,0,7076,1058,1,0,0,0,7077,7078,7,19,0,0,7078,
	7079,7,4,0,0,7079,7080,7,16,0,0,7080,7081,7,14,0,0,7081,1060,1,0,0,0,7082,
	7083,7,19,0,0,7083,7084,7,18,0,0,7084,7085,7,18,0,0,7085,7086,7,5,0,0,7086,
	7087,7,15,0,0,7087,7088,7,12,0,0,7088,7089,7,7,0,0,7089,1062,1,0,0,0,7090,
	7091,7,19,0,0,7091,7092,7,18,0,0,7092,7093,7,18,0,0,7093,7094,7,11,0,0,
	7094,7095,7,7,0,0,7095,7096,7,6,0,0,7096,1064,1,0,0,0,7097,7098,7,19,0,
	0,7098,7099,7,18,0,0,7099,1066,1,0,0,0,7100,7101,7,19,0,0,7101,7102,7,27,
	0,0,7102,1068,1,0,0,0,7103,7104,7,19,0,0,7104,7105,7,5,0,0,7105,7106,7,
	4,0,0,7106,7107,5,95,0,0,7107,7108,7,25,0,0,7108,7109,7,3,0,0,7109,7110,
	7,11,0,0,7110,7111,7,11,0,0,7111,7112,7,9,0,0,7112,7113,7,19,0,0,7113,7114,
	7,8,0,0,7114,7115,7,4,0,0,7115,1070,1,0,0,0,7116,7117,7,19,0,0,7117,7118,
	7,12,0,0,7118,7119,7,7,0,0,7119,1072,1,0,0,0,7120,7121,7,19,0,0,7121,7122,
	7,12,0,0,7122,7123,7,5,0,0,7123,7124,7,15,0,0,7124,7125,7,12,0,0,7125,7126,
	7,7,0,0,7126,1074,1,0,0,0,7127,7128,7,19,0,0,7128,7129,7,12,0,0,7129,7130,
	7,5,0,0,7130,7131,7,10,0,0,7131,1076,1,0,0,0,7132,7133,7,19,0,0,7133,7134,
	7,25,0,0,7134,7135,7,7,0,0,7135,7136,7,12,0,0,7136,1078,1,0,0,0,7137,7138,
	7,19,0,0,7138,7139,7,25,0,0,7139,7140,7,6,0,0,7140,7141,7,15,0,0,7141,7142,
	7,23,0,0,7142,7143,7,15,0,0,7143,7144,7,13,0,0,7144,7145,7,7,0,0,7145,7146,
	7,8,0,0,7146,7147,5,95,0,0,7147,7148,7,14,0,0,7148,7149,7,19,0,0,7149,7150,
	7,11,0,0,7150,7151,7,6,0,0,7151,7152,7,11,0,0,7152,1080,1,0,0,0,7153,7154,
	7,19,0,0,7154,7155,7,25,0,0,7155,7156,7,6,0,0,7156,7157,7,15,0,0,7157,7158,
	7,19,0,0,7158,7159,7,12,0,0,7159,7160,7,11,0,0,7160,1082,1,0,0,0,7161,7162,
	7,19,0,0,7162,7163,7,9,0,0,7163,7164,7,12,0,0,7164,7165,7,7,0,0,7165,7166,
	7,8,0,0,7166,1084,1,0,0,0,7167,7168,7,25,0,0,7168,7169,7,3,0,0,7169,7170,
	7,14,0,0,7170,7171,7,21,0,0,7171,7172,5,95,0,0,7172,7173,7,21,0,0,7173,
	7174,7,7,0,0,7174,7175,7,10,0,0,7175,7176,7,11,0,0,7176,1086,1,0,0,0,7177,
	7178,7,25,0,0,7178,7179,7,3,0,0,7179,7180,7,22,0,0,7180,7181,7,7,0,0,7181,
	1088,1,0,0,0,7182,7183,7,25,0,0,7183,7184,7,3,0,0,7184,7185,7,22,0,0,7185,
	7186,7,7,0,0,7186,7187,5,95,0,0,7187,7188,7,14,0,0,7188,7189,7,19,0,0,7189,
	7190,7,23,0,0,7190,7191,7,25,0,0,7191,7192,7,8,0,0,7192,7193,7,7,0,0,7193,
	7194,7,11,0,0,7194,7195,7,11,0,0,7195,7196,7,7,0,0,7196,7197,7,4,0,0,7197,
	1090,1,0,0,0,7198,7199,7,25,0,0,7199,7200,7,3,0,0,7200,7201,7,22,0,0,7201,
	7202,7,7,0,0,7202,7203,5,95,0,0,7203,7204,7,14,0,0,7204,7205,7,19,0,0,7205,
	7206,7,23,0,0,7206,7207,7,25,0,0,7207,7208,7,8,0,0,7208,7209,7,7,0,0,7209,
	7210,7,11,0,0,7210,7211,7,11,0,0,7211,7212,7,15,0,0,7212,7213,7,19,0,0,
	7213,7214,7,12,0,0,7214,7215,5,95,0,0,7215,7216,7,5,0,0,7216,7217,7,7,0,
	0,7217,7218,7,24,0,0,7218,7219,7,7,0,0,7219,7220,7,5,0,0,7220,1092,1,0,
	0,0,7221,7222,7,25,0,0,7222,7223,7,3,0,0,7223,7224,7,8,0,0,7224,7225,7,
	11,0,0,7225,7226,7,7,0,0,7226,7227,7,8,0,0,7227,1094,1,0,0,0,7228,7229,
	7,25,0,0,7229,7230,7,3,0,0,7230,7231,7,8,0,0,7231,7232,7,6,0,0,7232,7233,
	7,15,0,0,7233,7234,7,3,0,0,7234,7235,7,5,0,0,7235,1096,1,0,0,0,7236,7237,
	7,25,0,0,7237,7238,7,3,0,0,7238,7239,7,8,0,0,7239,7240,7,6,0,0,7240,7241,
	7,15,0,0,7241,7242,7,6,0,0,7242,7243,7,15,0,0,7243,7244,7,19,0,0,7244,7245,
	7,12,0,0,7245,7246,7,15,0,0,7246,7247,7,12,0,0,7247,7248,7,22,0,0,7248,
	1098,1,0,0,0,7249,7250,7,25,0,0,7250,7251,7,3,0,0,7251,7252,7,8,0,0,7252,
	7253,7,6,0,0,7253,7254,7,15,0,0,7254,7255,7,6,0,0,7255,7256,7,15,0,0,7256,
	7257,7,19,0,0,7257,7258,7,12,0,0,7258,7259,7,11,0,0,7259,1100,1,0,0,0,7260,
	7261,7,25,0,0,7261,7262,7,3,0,0,7262,7263,7,11,0,0,7263,7264,7,11,0,0,7264,
	7265,7,9,0,0,7265,7266,7,19,0,0,7266,7267,7,8,0,0,7267,7268,7,4,0,0,7268,
	1102,1,0,0,0,7269,7270,7,25,0,0,7270,7271,7,3,0,0,7271,7272,7,11,0,0,7272,
	7273,7,11,0,0,7273,7274,7,9,0,0,7274,7275,7,19,0,0,7275,7276,7,8,0,0,7276,
	7277,7,4,0,0,7277,7278,5,95,0,0,7278,7279,7,5,0,0,7279,7280,7,19,0,0,7280,
	7281,7,14,0,0,7281,7282,7,21,0,0,7282,7283,5,95,0,0,7283,7284,7,6,0,0,7284,
	7285,7,15,0,0,7285,7286,7,23,0,0,7286,7287,7,7,0,0,7287,1104,1,0,0,0,7288,
	7289,7,25,0,0,7289,7290,7,20,0,0,7290,7291,7,3,0,0,7291,7292,7,11,0,0,7292,
	7293,7,7,0,0,7293,1106,1,0,0,0,7294,7295,7,25,0,0,7295,7296,7,5,0,0,7296,
	7297,7,17,0,0,7297,7298,7,22,0,0,7298,7299,7,15,0,0,7299,7300,7,12,0,0,
	7300,1108,1,0,0,0,7301,7302,7,25,0,0,7302,7303,7,5,0,0,7303,7304,7,17,0,
	0,7304,7305,7,22,0,0,7305,7306,7,15,0,0,7306,7307,7,12,0,0,7307,7308,5,
	95,0,0,7308,7309,7,4,0,0,7309,7310,7,15,0,0,7310,7311,7,8,0,0,7311,1110,
	1,0,0,0,7312,7313,7,25,0,0,7313,7314,7,5,0,0,7314,7315,7,17,0,0,7315,7316,
	7,22,0,0,7316,7317,7,15,0,0,7317,7318,7,12,0,0,7318,7319,7,11,0,0,7319,
	1112,1,0,0,0,7320,7321,7,25,0,0,7321,7322,7,19,0,0,7322,7323,7,8,0,0,7323,
	7324,7,6,0,0,7324,1114,1,0,0,0,7325,7326,7,25,0,0,7326,7327,7,8,0,0,7327,
	7328,7,7,0,0,7328,7329,7,14,0,0,7329,7330,7,7,0,0,7330,7331,7,4,0,0,7331,
	7332,7,7,0,0,7332,7333,7,11,0,0,7333,1116,1,0,0,0,7334,7335,7,25,0,0,7335,
	7336,7,8,0,0,7336,7337,7,7,0,0,7337,7338,7,14,0,0,7338,7339,7,7,0,0,7339,
	7340,7,4,0,0,7340,7341,7,15,0,0,7341,7342,7,12,0,0,7342,7343,7,22,0,0,7343,
	1118,1,0,0,0,7344,7345,7,25,0,0,7345,7346,7,8,0,0,7346,7347,7,7,0,0,7347,
	7348,7,25,0,0,7348,7349,7,3,0,0,7349,7350,7,8,0,0,7350,7351,7,7,0,0,7351,
	1120,1,0,0,0,7352,7353,7,25,0,0,7353,7354,7,8,0,0,7354,7355,7,7,0,0,7355,
	7356,7,11,0,0,7356,7357,7,7,0,0,7357,7358,7,8,0,0,7358,7359,7,24,0,0,7359,
	7360,7,7,0,0,7360,1122,1,0,0,0,7361,7362,7,25,0,0,7362,7363,7,8,0,0,7363,
	7364,7,7,0,0,7364,7365,7,24,0,0,7365,1124,1,0,0,0,7366,7367,7,25,0,0,7367,
	7368,7,8,0,0,7368,7369,7,19,0,0,7369,7370,7,14,0,0,7370,7371,7,7,0,0,7371,
	7372,7,11,0,0,7372,7373,7,11,0,0,7373,7374,7,5,0,0,7374,7375,7,15,0,0,7375,
	7376,7,11,0,0,7376,7377,7,6,0,0,7377,1126,1,0,0,0,7378,7379,7,25,0,0,7379,
	7380,7,8,0,0,7380,7381,7,19,0,0,7381,7382,7,18,0,0,7382,7383,7,15,0,0,7383,
	7384,7,5,0,0,7384,7385,7,7,0,0,7385,1128,1,0,0,0,7386,7387,7,25,0,0,7387,
	7388,7,8,0,0,7388,7389,7,19,0,0,7389,7390,7,18,0,0,7390,7391,7,15,0,0,7391,
	7392,7,5,0,0,7392,7393,7,7,0,0,7393,7394,7,11,0,0,7394,1130,1,0,0,0,7395,
	7396,7,25,0,0,7396,7397,7,8,0,0,7397,7398,7,19,0,0,7398,7399,7,26,0,0,7399,
	7400,7,10,0,0,7400,1132,1,0,0,0,7401,7402,7,28,0,0,7402,7403,7,17,0,0,7403,
	7404,7,7,0,0,7404,7405,7,8,0,0,7405,7406,7,10,0,0,7406,1134,1,0,0,0,7407,
	7408,7,28,0,0,7408,7409,7,17,0,0,7409,7410,7,15,0,0,7410,7411,7,14,0,0,
	7411,7412,7,21,0,0,7412,1136,1,0,0,0,7413,7414,7,8,0,0,7414,7415,7,7,0,
	0,7415,7416,7,16,0,0,7416,7417,7,17,0,0,7417,7418,7,15,0,0,7418,7419,7,
	5,0,0,7419,7420,7,4,0,0,7420,1138,1,0,0,0,7421,7422,7,8,0,0,7422,7423,7,
	7,0,0,7423,7424,7,14,0,0,7424,7425,7,19,0,0,7425,7426,7,24,0,0,7426,7427,
	7,7,0,0,7427,7428,7,8,0,0,7428,1140,1,0,0,0,7429,7430,7,8,0,0,7430,7431,
	7,7,0,0,7431,7432,7,14,0,0,7432,7433,7,17,0,0,7433,7434,7,8,0,0,7434,7435,
	7,11,0,0,7435,7436,7,15,0,0,7436,7437,7,24,0,0,7437,7438,7,7,0,0,7438,1142,
	1,0,0,0,7439,7440,7,8,0,0,7440,7441,7,7,0,0,7441,7442,7,4,0,0,7442,7443,
	7,19,0,0,7443,7444,5,95,0,0,7444,7445,7,16,0,0,7445,7446,7,17,0,0,7446,
	7447,7,18,0,0,7447,7448,7,18,0,0,7448,7449,7,7,0,0,7449,7450,7,8,0,0,7450,
	7451,5,95,0,0,7451,7452,7,11,0,0,7452,7453,7,15,0,0,7453,7454,7,13,0,0,
	7454,7455,7,7,0,0,7455,1144,1,0,0,0,7456,7457,7,8,0,0,7457,7458,7,7,0,0,
	7458,7459,7,4,0,0,7459,7460,7,17,0,0,7460,7461,7,12,0,0,7461,7462,7,4,0,
	0,7462,7463,7,3,0,0,7463,7464,7,12,0,0,7464,7465,7,6,0,0,7465,1146,1,0,
	0,0,7466,7467,7,8,0,0,7467,7468,7,7,0,0,7468,7469,7,5,0,0,7469,7470,7,3,
	0,0,7470,7471,7,10,0,0,7471,1148,1,0,0,0,7472,7473,7,8,0,0,7473,7474,7,
	7,0,0,7474,7475,7,5,0,0,7475,7476,7,3,0,0,7476,7477,7,10,0,0,7477,7478,
	5,95,0,0,7478,7479,7,5,0,0,7479,7480,7,19,0,0,7480,7481,7,22,0,0,7481,7482,
	5,95,0,0,7482,7483,7,18,0,0,7483,7484,7,15,0,0,7484,7485,7,5,0,0,7485,7486,
	7,7,0,0,7486,1150,1,0,0,0,7487,7488,7,8,0,0,7488,7489,7,7,0,0,7489,7490,
	7,5,0,0,7490,7491,7,3,0,0,7491,7492,7,10,0,0,7492,7493,5,95,0,0,7493,7494,
	7,5,0,0,7494,7495,7,19,0,0,7495,7496,7,22,0,0,7496,7497,5,95,0,0,7497,7498,
	7,25,0,0,7498,7499,7,19,0,0,7499,7500,7,11,0,0,7500,1152,1,0,0,0,7501,7502,
	7,8,0,0,7502,7503,7,7,0,0,7503,7504,7,5,0,0,7504,7505,7,3,0,0,7505,7506,
	7,10,0,0,7506,7507,7,5,0,0,7507,7508,7,19,0,0,7508,7509,7,22,0,0,7509,1154,
	1,0,0,0,7510,7511,7,8,0,0,7511,7512,7,7,0,0,7512,7513,7,23,0,0,7513,7514,
	7,19,0,0,7514,7515,7,24,0,0,7515,7516,7,7,0,0,7516,1156,1,0,0,0,7517,7518,
	7,8,0,0,7518,7519,7,7,0,0,7519,7520,7,19,0,0,7520,7521,7,8,0,0,7521,7522,
	7,22,0,0,7522,7523,7,3,0,0,7523,7524,7,12,0,0,7524,7525,7,15,0,0,7525,7526,
	7,13,0,0,7526,7527,7,7,0,0,7527,1158,1,0,0,0,7528,7529,7,8,0,0,7529,7530,
	7,7,0,0,7530,7531,7,25,0,0,7531,7532,7,3,0,0,7532,7533,7,15,0,0,7533,7534,
	7,8,0,0,7534,1160,1,0,0,0,7535,7536,7,8,0,0,7536,7537,7,7,0,0,7537,7538,
	7,25,0,0,7538,7539,7,5,0,0,7539,7540,7,15,0,0,7540,7541,7,14,0,0,7541,7542,
	7,3,0,0,7542,7543,7,6,0,0,7543,7544,7,7,0,0,7544,7545,5,95,0,0,7545,7546,
	7,4,0,0,7546,7547,7,19,0,0,7547,7548,5,95,0,0,7548,7549,7,4,0,0,7549,7550,
	7,16,0,0,7550,1162,1,0,0,0,7551,7552,7,8,0,0,7552,7553,7,7,0,0,7553,7554,
	7,25,0,0,7554,7555,7,5,0,0,7555,7556,7,15,0,0,7556,7557,7,14,0,0,7557,7558,
	7,3,0,0,7558,7559,7,6,0,0,7559,7560,7,7,0,0,7560,7561,5,95,0,0,7561,7562,
	7,4,0,0,7562,7563,7,19,0,0,7563,7564,5,95,0,0,7564,7565,7,6,0,0,7565,7566,
	7,3,0,0,7566,7567,7,16,0,0,7567,7568,7,5,0,0,7568,7569,7,7,0,0,7569,1164,
	1,0,0,0,7570,7571,7,8,0,0,7571,7572,7,7,0,0,7572,7573,7,25,0,0,7573,7574,
	7,5,0,0,7574,7575,7,15,0,0,7575,7576,7,14,0,0,7576,7577,7,3,0,0,7577,7578,
	7,6,0,0,7578,7579,7,7,0,0,7579,7580,5,95,0,0,7580,7581,7,15,0,0,7581,7582,
	7,22,0,0,7582,7583,7,12,0,0,7583,7584,7,19,0,0,7584,7585,7,8,0,0,7585,7586,
	7,7,0,0,7586,7587,5,95,0,0,7587,7588,7,4,0,0,7588,7589,7,16,0,0,7589,1166,
	1,0,0,0,7590,7591,7,8,0,0,7591,7592,7,7,0,0,7592,7593,7,25,0,0,7593,7594,
	7,5,0,0,7594,7595,7,15,0,0,7595,7596,7,14,0,0,7596,7597,7,3,0,0,7597,7598,
	7,6,0,0,7598,7599,7,7,0,0,7599,7600,5,95,0,0,7600,7601,7,15,0,0,7601,7602,
	7,22,0,0,7602,7603,7,12,0,0,7603,7604,7,19,0,0,7604,7605,7,8,0,0,7605,7606,
	7,7,0,0,7606,7607,5,95,0,0,7607,7608,7,6,0,0,7608,7609,7,3,0,0,7609,7610,
	7,16,0,0,7610,7611,7,5,0,0,7611,7612,7,7,0,0,7612,1168,1,0,0,0,7613,7614,
	7,8,0,0,7614,7615,7,7,0,0,7615,7616,7,25,0,0,7616,7617,7,5,0,0,7617,7618,
	7,15,0,0,7618,7619,7,14,0,0,7619,7620,7,3,0,0,7620,7621,7,6,0,0,7621,7622,
	7,7,0,0,7622,7623,5,95,0,0,7623,7624,7,8,0,0,7624,7625,7,7,0,0,7625,7626,
	7,9,0,0,7626,7627,7,8,0,0,7627,7628,7,15,0,0,7628,7629,7,6,0,0,7629,7630,
	7,7,0,0,7630,7631,5,95,0,0,7631,7632,7,4,0,0,7632,7633,7,16,0,0,7633,1170,
	1,0,0,0,7634,7635,7,8,0,0,7635,7636,7,7,0,0,7636,7637,7,25,0,0,7637,7638,
	7,5,0,0,7638,7639,7,15,0,0,7639,7640,7,14,0,0,7640,7641,7,3,0,0,7641,7642,
	7,6,0,0,7642,7643,7,7,0,0,7643,7644,5,95,0,0,7644,7645,7,9,0,0,7645,7646,
	7,15,0,0,7646,7647,7,5,0,0,7647,7648,7,4,0,0,7648,7649,5,95,0,0,7649,7650,
	7,4,0,0,7650,7651,7,19,0,0,7651,7652,5,95,0,0,7652,7653,7,6,0,0,7653,7654,
	7,3,0,0,7654,7655,7,16,0,0,7655,7656,7,5,0,0,7656,7657,7,7,0,0,7657,1172,
	1,0,0,0,7658,7659,7,8,0,0,7659,7660,7,7,0,0,7660,7661,7,25,0,0,7661,7662,
	7,5,0,0,7662,7663,7,15,0,0,7663,7664,7,14,0,0,7664,7665,7,3,0,0,7665,7666,
	7,6,0,0,7666,7667,7,7,0,0,7667,7668,5,95,0,0,7668,7669,7,9,0,0,7669,7670,
	7,15,0,0,7670,7671,7,5,0,0,7671,7672,7,4,0,0,7672,7673,5,95,0,0,7673,7674,
	7,15,0,0,7674,7675,7,22,0,0,7675,7676,7,12,0,0,7676,7677,7,19,0,0,7677,
	7678,7,8,0,0,7678,7679,7,7,0,0,7679,7680,5,95,0,0,7680,7681,7,6,0,0,7681,
	7682,7,3,0,0,7682,7683,7,16,0,0,7683,7684,7,5,0,0,7684,7685,7,7,0,0,7685,
	1174,1,0,0,0,7686,7687,7,8,0,0,7687,7688,7,7,0,0,7688,7689,7,25,0,0,7689,
	7690,7,5,0,0,7690,7691,7,15,0,0,7691,7692,7,14,0,0,7692,7693,7,3,0,0,7693,
	7694,7,6,0,0,7694,7695,7,15,0,0,7695,7696,7,19,0,0,7696,7697,7,12,0,0,7697,
	1176,1,0,0,0,7698,7699,7,8,0,0,7699,7700,7,7,0,0,7700,7701,7,11,0,0,7701,
	7702,7,7,0,0,7702,7703,7,6,0,0,7703,1178,1,0,0,0,7704,7705,7,8,0,0,7705,
	7706,7,7,0,0,7706,7707,7,11,0,0,7707,7708,7,6,0,0,7708,7709,7,3,0,0,7709,
	7710,7,8,0,0,7710,7711,7,6,0,0,7711,1180,1,0,0,0,7712,7713,7,8,0,0,7713,
	7714,7,7,0,0,7714,7715,7,11,0,0,7715,7716,7,17,0,0,7716,7717,7,23,0,0,7717,
	7718,7,7,0,0,7718,1182,1,0,0,0,7719,7720,7,8,0,0,7720,7721,7,7,0,0,7721,
	7722,7,6,0,0,7722,7723,7,17,0,0,7723,7724,7,8,0,0,7724,7725,7,12,0,0,7725,
	7726,7,7,0,0,7726,7727,7,4,0,0,7727,7728,5,95,0,0,7728,7729,7,11,0,0,7729,
	7730,7,28,0,0,7730,7731,7,5,0,0,7731,7732,7,11,0,0,7732,7733,7,6,0,0,7733,
	7734,7,3,0,0,7734,7735,7,6,0,0,7735,7736,7,7,0,0,7736,1184,1,0,0,0,7737,
	7738,7,8,0,0,7738,7739,7,7,0,0,7739,7740,7,6,0,0,7740,7741,7,17,0,0,7741,
	7742,7,8,0,0,7742,7743,7,12,0,0,7743,7744,7,15,0,0,7744,7745,7,12,0,0,7745,
	7746,7,22,0,0,7746,1186,1,0,0,0,7747,7748,7,8,0,0,7748,7749,7,7,0,0,7749,
	7750,7,6,0,0,7750,7751,7,17,0,0,7751,7752,7,8,0,0,7752,7753,7,12,0,0,7753,
	7754,7,11,0,0,7754,1188,1,0,0,0,7755,7756,7,8,0,0,7756,7757,7,7,0,0,7757,
	7758,7,17,0,0,7758,7759,7,11,0,0,7759,7760,7,7,0,0,7760,1190,1,0,0,0,7761,
	7762,7,8,0,0,7762,7763,7,19,0,0,7763,7764,7,5,0,0,7764,7765,7,7,0,0,7765,
	1192,1,0,0,0,7766,7767,7,8,0,0,7767,7768,7,19,0,0,7768,7769,7,5,0,0,7769,
	7770,7,5,0,0,7770,7771,7,16,0,0,7771,7772,7,3,0,0,7772,7773,7,14,0,0,7773,
	7774,7,21,0,0,7774,1194,1,0,0,0,7775,7776,7,8,0,0,7776,7777,7,19,0,0,7777,
	7778,7,5,0,0,7778,7779,7,5,0,0,7779,7780,7,17,0,0,7780,7781,7,25,0,0,7781,
	1196,1,0,0,0,7782,7783,7,8,0,0,7783,7784,7,19,0,0,7784,7785,7,6,0,0,7785,
	7786,7,3,0,0,7786,7787,7,6,0,0,7787,7788,7,7,0,0,7788,1198,1,0,0,0,7789,
	7790,7,8,0,0,7790,7791,7,19,0,0,7791,7792,7,9,0,0,7792,1200,1,0,0,0,7793,
	7794,7,8,0,0,7794,7795,7,19,0,0,7795,7796,7,9,0,0,7796,7797,7,11,0,0,7797,
	1202,1,0,0,0,7798,7799,7,8,0,0,7799,7800,7,19,0,0,7800,7801,7,9,0,0,7801,
	7802,5,95,0,0,7802,7803,7,18,0,0,7803,7804,7,19,0,0,7804,7805,7,8,0,0,7805,
	7806,7,23,0,0,7806,7807,7,3,0,0,7807,7808,7,6,0,0,7808,1204,1,0,0,0,7809,
	7810,7,8,0,0,7810,7811,7,6,0,0,7811,7812,7,8,0,0,7812,7813,7,7,0,0,7813,
	7814,7,7,0,0,7814,1206,1,0,0,0,7815,7816,7,11,0,0,7816,7817,7,3,0,0,7817,
	7818,7,24,0,0,7818,7819,7,7,0,0,7819,7820,7,25,0,0,7820,7821,7,19,0,0,7821,
	7822,7,15,0,0,7822,7823,7,12,0,0,7823,7824,7,6,0,0,7824,1208,1,0,0,0,7825,
	7826,7,11,0,0,7826,7827,7,14,0,0,7827,7828,7,20,0,0,7828,7829,7,7,0,0,7829,
	7830,7,4,0,0,7830,7831,7,17,0,0,7831,7832,7,5,0,0,7832,7833,7,7,0,0,7833,
	1210,1,0,0,0,7834,7835,7,11,0,0,7835,7836,7,7,0,0,7836,7837,7,14,0,0,7837,
	7838,7,17,0,0,7838,7839,7,8,0,0,7839,7840,7,15,0,0,7840,7841,7,6,0,0,7841,
	7842,7,10,0,0,7842,1212,1,0,0,0,7843,7844,7,11,0,0,7844,7845,7,7,0,0,7845,
	7846,7,28,0,0,7846,7847,7,17,0,0,7847,7848,7,7,0,0,7848,7849,7,12,0,0,7849,
	7850,7,14,0,0,7850,7851,7,7,0,0,7851,1214,1,0,0,0,7852,7853,7,11,0,0,7853,
	7854,7,7,0,0,7854,7855,7,8,0,0,7855,7856,7,24,0,0,7856,7857,7,7,0,0,7857,
	7858,7,8,0,0,7858,1216,1,0,0,0,7859,7860,7,11,0,0,7860,7861,7,7,0,0,7861,
	7862,7,11,0,0,7862,7863,7,11,0,0,7863,7864,7,15,0,0,7864,7865,7,19,0,0,
	7865,7866,7,12,0,0,7866,1218,1,0,0,0,7867,7868,7,11,0,0,7868,7869,7,20,
	0,0,7869,7870,7,3,0,0,7870,7871,7,8,0,0,7871,7872,7,7,0,0,7872,1220,1,0,
	0,0,7873,7874,7,11,0,0,7874,7875,7,20,0,0,7875,7876,7,3,0,0,7876,7877,7,
	8,0,0,7877,7878,7,7,0,0,7878,7879,7,4,0,0,7879,1222,1,0,0,0,7880,7881,7,
	11,0,0,7881,7882,7,15,0,0,7882,7883,7,22,0,0,7883,7884,7,12,0,0,7884,7885,
	7,7,0,0,7885,7886,7,4,0,0,7886,1224,1,0,0,0,7887,7888,7,11,0,0,7888,7889,
	7,15,0,0,7889,7890,7,23,0,0,7890,7891,7,25,0,0,7891,7892,7,5,0,0,7892,7893,
	7,7,0,0,7893,1226,1,0,0,0,7894,7895,7,11,0,0,7895,7896,7,5,0,0,7896,7897,
	7,3,0,0,7897,7898,7,24,0,0,7898,7899,7,7,0,0,7899,1228,1,0,0,0,7900,7901,
	7,11,0,0,7901,7902,7,5,0,0,7902,7903,7,19,0,0,7903,7904,7,9,0,0,7904,1230,
	1,0,0,0,7905,7906,7,11,0,0,7906,7907,7,12,0,0,7907,7908,7,3,0,0,7908,7909,
	7,25,0,0,7909,7910,7,11,0,0,7910,7911,7,20,0,0,7911,7912,7,19,0,0,7912,
	7913,7,6,0,0,7913,1232,1,0,0,0,7914,7915,7,11,0,0,7915,7916,7,19,0,0,7916,
	7917,7,14,0,0,7917,7918,7,21,0,0,7918,7919,7,7,0,0,7919,7920,7,6,0,0,7920,
	1234,1,0,0,0,7921,7922,7,11,0,0,7922,7923,7,19,0,0,7923,7924,7,23,0,0,7924,
	7925,7,7,0,0,7925,1236,1,0,0,0,7926,7927,7,11,0,0,7927,7928,7,19,0,0,7928,
	7929,7,12,0,0,7929,7930,7,3,0,0,7930,7931,7,23,0,0,7931,7932,7,7,0,0,7932,
	1238,1,0,0,0,7933,7934,7,11,0,0,7934,7935,7,19,0,0,7935,7936,7,17,0,0,7936,
	7937,7,12,0,0,7937,7938,7,4,0,0,7938,7939,7,11,0,0,7939,1240,1,0,0,0,7940,
	7941,7,11,0,0,7941,7942,7,19,0,0,7942,7943,7,17,0,0,7943,7944,7,8,0,0,7944,
	7945,7,14,0,0,7945,7946,7,7,0,0,7946,1242,1,0,0,0,7947,7948,7,11,0,0,7948,
	7949,7,28,0,0,7949,7950,7,5,0,0,7950,7951,5,95,0,0,7951,7952,7,3,0,0,7952,
	7953,7,18,0,0,7953,7954,7,6,0,0,7954,7955,7,7,0,0,7955,7956,7,8,0,0,7956,
	7957,5,95,0,0,7957,7958,7,22,0,0,7958,7959,7,6,0,0,7959,7960,7,15,0,0,7960,
	7961,7,4,0,0,7961,7962,7,11,0,0,7962,1244,1,0,0,0,7963,7964,7,11,0,0,7964,
	7965,7,28,0,0,7965,7966,7,5,0,0,7966,7967,5,95,0,0,7967,7968,7,3,0,0,7968,
	7969,7,18,0,0,7969,7970,7,6,0,0,7970,7971,7,7,0,0,7971,7972,7,8,0,0,7972,
	7973,5,95,0,0,7973,7974,7,23,0,0,7974,7975,7,6,0,0,7975,7976,7,11,0,0,7976,
	7977,5,95,0,0,7977,7978,7,22,0,0,7978,7979,7,3,0,0,7979,7980,7,25,0,0,7980,
	7981,7,11,0,0,7981,1246,1,0,0,0,7982,7983,7,11,0,0,7983,7984,7,28,0,0,7984,
	7985,7,5,0,0,7985,7986,5,95,0,0,7986,7987,7,16,0,0,7987,7988,7,7,0,0,7988,
	7989,7,18,0,0,7989,7990,7,19,0,0,7990,7991,7,8,0,0,7991,7992,7,7,0,0,7992,
	7993,5,95,0,0,7993,7994,7,22,0,0,7994,7995,7,6,0,0,7995,7996,7,15,0,0,7996,
	7997,7,4,0,0,7997,7998,7,11,0,0,7998,1248,1,0,0,0,7999,8000,7,11,0,0,8000,
	8001,7,28,0,0,8001,8002,7,5,0,0,8002,8003,5,95,0,0,8003,8004,7,16,0,0,8004,
	8005,7,17,0,0,8005,8006,7,18,0,0,8006,8007,7,18,0,0,8007,8008,7,7,0,0,8008,
	8009,7,8,0,0,8009,8010,5,95,0,0,8010,8011,7,8,0,0,8011,8012,7,7,0,0,8012,
	8013,7,11,0,0,8013,8014,7,17,0,0,8014,8015,7,5,0,0,8015,8016,7,6,0,0,8016,
	1250,1,0,0,0,8017,8018,7,11,0,0,8018,8019,7,28,0,0,8019,8020,7,5,0,0,8020,
	8021,5,95,0,0,8021,8022,7,14,0,0,8022,8023,7,3,0,0,8023,8024,7,14,0,0,8024,
	8025,7,20,0,0,8025,8026,7,7,0,0,8026,1252,1,0,0,0,8027,8028,7,11,0,0,8028,
	8029,7,28,0,0,8029,8030,7,5,0,0,8030,8031,5,95,0,0,8031,8032,7,12,0,0,8032,
	8033,7,19,0,0,8033,8034,5,95,0,0,8034,8035,7,14,0,0,8035,8036,7,3,0,0,8036,
	8037,7,14,0,0,8037,8038,7,20,0,0,8038,8039,7,7,0,0,8039,1254,1,0,0,0,8040,
	8041,7,11,0,0,8041,8042,7,28,0,0,8042,8043,7,5,0,0,8043,8044,5,95,0,0,8044,
	8045,7,6,0,0,8045,8046,7,20,0,0,8046,8047,7,8,0,0,8047,8048,7,7,0,0,8048,
	8049,7,3,0,0,8049,8050,7,4,0,0,8050,1256,1,0,0,0,8051,8052,7,11,0,0,8052,
	8053,7,6,0,0,8053,8054,7,3,0,0,8054,8055,7,8,0,0,8055,8056,7,6,0,0,8056,
	1258,1,0,0,0,8057,8058,7,11,0,0,8058,8059,7,6,0,0,8059,8060,7,3,0,0,8060,
	8061,7,8,0,0,8061,8062,7,6,0,0,8062,8063,7,11,0,0,8063,1260,1,0,0,0,8064,
	8065,7,11,0,0,8065,8066,7,6,0,0,8066,8067,7,3,0,0,8067,8068,7,6,0,0,8068,
	8069,7,11,0,0,8069,8070,5,95,0,0,8070,8071,7,3,0,0,8071,8072,7,17,0,0,8072,
	8073,7,6,0,0,8073,8074,7,19,0,0,8074,8075,5,95,0,0,8075,8076,7,8,0,0,8076,
	8077,7,7,0,0,8077,8078,7,14,0,0,8078,8079,7,3,0,0,8079,8080,7,5,0,0,8080,
	8081,7,14,0,0,8081,1262,1,0,0,0,8082,8083,7,11,0,0,8083,8084,7,6,0,0,8084,
	8085,7,3,0,0,8085,8086,7,6,0,0,8086,8087,7,11,0,0,8087,8088,5,95,0,0,8088,
	8089,7,25,0,0,8089,8090,7,7,0,0,8090,8091,7,8,0,0,8091,8092,7,11,0,0,8092,
	8093,7,15,0,0,8093,8094,7,11,0,0,8094,8095,7,6,0,0,8095,8096,7,7,0,0,8096,
	8097,7,12,0,0,8097,8098,7,6,0,0,8098,1264,1,0,0,0,8099,8100,7,11,0,0,8100,
	8101,7,6,0,0,8101,8102,7,3,0,0,8102,8103,7,6,0,0,8103,8104,7,11,0,0,8104,
	8105,5,95,0,0,8105,8106,7,11,0,0,8106,8107,7,3,0,0,8107,8108,7,23,0,0,8108,
	8109,7,25,0,0,8109,8110,7,5,0,0,8110,8111,7,7,0,0,8111,8112,5,95,0,0,8112,
	8113,7,25,0,0,8113,8114,7,3,0,0,8114,8115,7,22,0,0,8115,8116,7,7,0,0,8116,
	8117,7,11,0,0,8117,1266,1,0,0,0,8118,8119,7,11,0,0,8119,8120,7,6,0,0,8120,
	8121,7,3,0,0,8121,8122,7,6,0,0,8122,8123,7,17,0,0,8123,8124,7,11,0,0,8124,
	1268,1,0,0,0,8125,8126,7,11,0,0,8126,8127,7,6,0,0,8127,8128,7,19,0,0,8128,
	8129,7,25,0,0,8129,1270,1,0,0,0,8130,8131,7,11,0,0,8131,8132,7,6,0,0,8132,
	8133,7,19,0,0,8133,8134,7,8,0,0,8134,8135,7,3,0,0,8135,8136,7,22,0,0,8136,
	8137,7,7,0,0,8137,1272,1,0,0,0,8138,8139,7,11,0,0,8139,8140,7,6,0,0,8140,
	8141,7,19,0,0,8141,8142,7,8,0,0,8142,8143,7,7,0,0,8143,8144,7,4,0,0,8144,
	1274,1,0,0,0,8145,8146,7,11,0,0,8146,8147,7,6,0,0,8147,8148,7,8,0,0,8148,
	8149,7,15,0,0,8149,8150,7,12,0,0,8150,8151,7,22,0,0,8151,1276,1,0,0,0,8152,
	8153,7,11,0,0,8153,8154,7,17,0,0,8154,8155,7,16,0,0,8155,8156,7,14,0,0,
	8156,8157,7,5,0,0,8157,8158,7,3,0,0,8158,8159,7,11,0,0,8159,8160,7,11,0,
	0,8160,8161,5,95,0,0,8161,8162,7,19,0,0,8162,8163,7,8,0,0,8163,8164,7,15,
	0,0,8164,8165,7,22,0,0,8165,8166,7,15,0,0,8166,8167,7,12,0,0,8167,1278,
	1,0,0,0,8168,8169,7,11,0,0,8169,8170,7,17,0,0,8170,8171,7,16,0,0,8171,8172,
	7,27,0,0,8172,8173,7,7,0,0,8173,8174,7,14,0,0,8174,8175,7,6,0,0,8175,1280,
	1,0,0,0,8176,8177,7,11,0,0,8177,8178,7,17,0,0,8178,8179,7,16,0,0,8179,8180,
	7,25,0,0,8180,8181,7,3,0,0,8181,8182,7,8,0,0,8182,8183,7,6,0,0,8183,8184,
	7,15,0,0,8184,8185,7,6,0,0,8185,8186,7,15,0,0,8186,8187,7,19,0,0,8187,8188,
	7,12,0,0,8188,1282,1,0,0,0,8189,8190,7,11,0,0,8190,8191,7,17,0,0,8191,8192,
	7,16,0,0,8192,8193,7,25,0,0,8193,8194,7,3,0,0,8194,8195,7,8,0,0,8195,8196,
	7,6,0,0,8196,8197,7,15,0,0,8197,8198,7,6,0,0,8198,8199,7,15,0,0,8199,8200,
	7,19,0,0,8200,8201,7,12,0,0,8201,8202,7,11,0,0,8202,1284,1,0,0,0,8203,8204,
	7,11,0,0,8204,8205,7,17,0,0,8205,8206,7,11,0,0,8206,8207,7,25,0,0,8207,
	8208,7,7,0,0,8208,8209,7,12,0,0,8209,8210,7,4,0,0,8210,1286,1,0,0,0,8211,
	8212,7,11,0,0,8212,8213,7,9,0,0,8213,8214,7,3,0,0,8214,8215,7,25,0,0,8215,
	8216,7,11,0,0,8216,1288,1,0,0,0,8217,8218,7,11,0,0,8218,8219,7,9,0,0,8219,
	8220,7,15,0,0,8220,8221,7,6,0,0,8221,8222,7,14,0,0,8222,8223,7,20,0,0,8223,
	8224,7,7,0,0,8224,8225,7,11,0,0,8225,1290,1,0,0,0,8226,8227,7,6,0,0,8227,
	8228,7,3,0,0,8228,8229,7,16,0,0,8229,8230,7,5,0,0,8230,8231,7,7,0,0,8231,
	8232,5,95,0,0,8232,8233,7,12,0,0,8233,8234,7,3,0,0,8234,8235,7,23,0,0,8235,
	8236,7,7,0,0,8236,1292,1,0,0,0,8237,8238,7,6,0,0,8238,8239,7,3,0,0,8239,
	8240,7,16,0,0,8240,8241,7,5,0,0,8241,8242,7,7,0,0,8242,8243,7,11,0,0,8243,
	8244,7,25,0,0,8244,8245,7,3,0,0,8245,8246,7,14,0,0,8246,8247,7,7,0,0,8247,
	1294,1,0,0,0,8248,8249,7,6,0,0,8249,8250,7,3,0,0,8250,8251,7,16,0,0,8251,
	8252,7,5,0,0,8252,8253,7,7,0,0,8253,8254,5,95,0,0,8254,8255,7,6,0,0,8255,
	8256,7,10,0,0,8256,8257,7,25,0,0,8257,8258,7,7,0,0,8258,1296,1,0,0,0,8259,
	8260,7,6,0,0,8260,8261,7,7,0,0,8261,8262,7,23,0,0,8262,8263,7,25,0,0,8263,
	8264,7,19,0,0,8264,8265,7,8,0,0,8265,8266,7,3,0,0,8266,8267,7,8,0,0,8267,
	8268,7,10,0,0,8268,1298,1,0,0,0,8269,8270,7,6,0,0,8270,8271,7,7,0,0,8271,
	8272,7,23,0,0,8272,8273,7,25,0,0,8273,8274,7,6,0,0,8274,8275,7,3,0,0,8275,
	8276,7,16,0,0,8276,8277,7,5,0,0,8277,8278,7,7,0,0,8278,1300,1,0,0,0,8279,
	8280,7,6,0,0,8280,8281,7,20,0,0,8281,8282,7,3,0,0,8282,8283,7,12,0,0,8283,
	1302,1,0,0,0,8284,8285,7,6,0,0,8285,8286,7,8,0,0,8286,8287,7,3,0,0,8287,
	8288,7,4,0,0,8288,8289,7,15,0,0,8289,8290,7,6,0,0,8290,8291,7,15,0,0,8291,
	8292,7,19,0,0,8292,8293,7,12,0,0,8293,8294,7,3,0,0,8294,8295,7,5,0,0,8295,
	1304,1,0,0,0,8296,8297,7,6,0,0,8297,8298,7,8,0,0,8298,8299,7,3,0,0,8299,
	8300,7,12,0,0,8300,8301,7,11,0,0,8301,8302,7,3,0,0,8302,8303,7,14,0,0,8303,
	8304,7,6,0,0,8304,8305,7,15,0,0,8305,8306,7,19,0,0,8306,8307,7,12,0,0,8307,
	1306,1,0,0,0,8308,8309,7,6,0,0,8309,8310,7,8,0,0,8310,8311,7,3,0,0,8311,
	8312,7,12,0,0,8312,8313,7,11,0,0,8313,8314,7,3,0,0,8314,8315,7,14,0,0,8315,
	8316,7,6,0,0,8316,8317,7,15,0,0,8317,8318,7,19,0,0,8318,8319,7,12,0,0,8319,
	8320,7,3,0,0,8320,8321,7,5,0,0,8321,1308,1,0,0,0,8322,8323,7,6,0,0,8323,
	8324,7,8,0,0,8324,8325,7,15,0,0,8325,8326,7,22,0,0,8326,8327,7,22,0,0,8327,
	8328,7,7,0,0,8328,8329,7,8,0,0,8329,8330,7,11,0,0,8330,1310,1,0,0,0,8331,
	8332,7,6,0,0,8332,8333,7,8,0,0,8333,8334,7,17,0,0,8334,8335,7,12,0,0,8335,
	8336,7,14,0,0,8336,8337,7,3,0,0,8337,8338,7,6,0,0,8338,8339,7,7,0,0,8339,
	1312,1,0,0,0,8340,8341,7,17,0,0,8341,8342,7,12,0,0,8342,8343,7,16,0,0,8343,
	8344,7,19,0,0,8344,8345,7,17,0,0,8345,8346,7,12,0,0,8346,8347,7,4,0,0,8347,
	8348,7,7,0,0,8348,8349,7,4,0,0,8349,1314,1,0,0,0,8350,8351,7,17,0,0,8351,
	8352,7,12,0,0,8352,8353,7,4,0,0,8353,8354,7,7,0,0,8354,8355,7,18,0,0,8355,
	8356,7,15,0,0,8356,8357,7,12,0,0,8357,8358,7,7,0,0,8358,8359,7,4,0,0,8359,
	1316,1,0,0,0,8360,8361,7,17,0,0,8361,8362,7,12,0,0,8362,8363,7,4,0,0,8363,
	8364,7,19,0,0,8364,8365,7,18,0,0,8365,8366,7,15,0,0,8366,8367,7,5,0,0,8367,
	8368,7,7,0,0,8368,1318,1,0,0,0,8369,8370,7,17,0,0,8370,8371,7,12,0,0,8371,
	8372,7,4,0,0,8372,8373,7,19,0,0,8373,8374,5,95,0,0,8374,8375,7,16,0,0,8375,
	8376,7,17,0,0,8376,8377,7,18,0,0,8377,8378,7,18,0,0,8378,8379,7,7,0,0,8379,
	8380,7,8,0,0,8380,8381,5,95,0,0,8381,8382,7,11,0,0,8382,8383,7,15,0,0,8383,
	8384,7,13,0,0,8384,8385,7,7,0,0,8385,1320,1,0,0,0,8386,8387,7,17,0,0,8387,
	8388,7,12,0,0,8388,8389,7,15,0,0,8389,8390,7,12,0,0,8390,8391,7,11,0,0,
	8391,8392,7,6,0,0,8392,8393,7,3,0,0,8393,8394,7,5,0,0,8394,8395,7,5,0,0,
	8395,1322,1,0,0,0,8396,8397,7,17,0,0,8397,8398,7,12,0,0,8398,8399,7,21,
	0,0,8399,8400,7,12,0,0,8400,8401,7,19,0,0,8401,8402,7,9,0,0,8402,8403,7,
	12,0,0,8403,1324,1,0,0,0,8404,8405,7,17,0,0,8405,8406,7,12,0,0,8406,8407,
	7,6,0,0,8407,8408,7,15,0,0,8408,8409,7,5,0,0,8409,1326,1,0,0,0,8410,8411,
	7,17,0,0,8411,8412,7,25,0,0,8412,8413,7,22,0,0,8413,8414,7,8,0,0,8414,8415,
	7,3,0,0,8415,8416,7,4,0,0,8416,8417,7,7,0,0,8417,1328,1,0,0,0,8418,8419,
	7,17,0,0,8419,8420,7,11,0,0,8420,8421,7,7,0,0,8421,8422,7,8,0,0,8422,1330,
	1,0,0,0,8423,8424,7,17,0,0,8424,8425,7,11,0,0,8425,8426,7,7,0,0,8426,8427,
	5,95,0,0,8427,8428,7,18,0,0,8428,8429,7,8,0,0,8429,8430,7,23,0,0,8430,1332,
	1,0,0,0,8431,8432,7,17,0,0,8432,8433,7,11,0,0,8433,8434,7,7,0,0,8434,8435,
	7,8,0,0,8435,8436,5,95,0,0,8436,8437,7,8,0,0,8437,8438,7,7,0,0,8438,8439,
	7,11,0,0,8439,8440,7,19,0,0,8440,8441,7,17,0,0,8441,8442,7,8,0,0,8442,8443,
	7,14,0,0,8443,8444,7,7,0,0,8444,8445,7,11,0,0,8445,1334,1,0,0,0,8446,8447,
	7,24,0,0,8447,8448,7,3,0,0,8448,8449,7,5,0,0,8449,8450,7,15,0,0,8450,8451,
	7,4,0,0,8451,8452,7,3,0,0,8452,8453,7,6,0,0,8453,8454,7,15,0,0,8454,8455,
	7,19,0,0,8455,8456,7,12,0,0,8456,1336,1,0,0,0,8457,8458,7,24,0,0,8458,8459,
	7,3,0,0,8459,8460,7,5,0,0,8460,8461,7,17,0,0,8461,8462,7,7,0,0,8462,1338,
	1,0,0,0,8463,8464,7,24,0,0,8464,8465,7,3,0,0,8465,8466,7,8,0,0,8466,8467,
	7,15,0,0,8467,8468,7,3,0,0,8468,8469,7,16,0,0,8469,8470,7,5,0,0,8470,8471,
	7,7,0,0,8471,8472,7,11,0,0,8472,1340,1,0,0,0,8473,8474,7,24,0,0,8474,8475,
	7,15,0,0,8475,8476,7,7,0,0,8476,8477,7,9,0,0,8477,1342,1,0,0,0,8478,8479,
	7,24,0,0,8479,8480,7,15,0,0,8480,8481,7,8,0,0,8481,8482,7,6,0,0,8482,8483,
	7,17,0,0,8483,8484,7,3,0,0,8484,8485,7,5,0,0,8485,1344,1,0,0,0,8486,8487,
	7,24,0,0,8487,8488,7,15,0,0,8488,8489,7,11,0,0,8489,8490,7,15,0,0,8490,
	8491,7,16,0,0,8491,8492,7,5,0,0,8492,8493,7,7,0,0,8493,1346,1,0,0,0,8494,
	8495,7,9,0,0,8495,8496,7,3,0,0,8496,8497,7,15,0,0,8497,8498,7,6,0,0,8498,
	1348,1,0,0,0,8499,8500,7,9,0,0,8500,8501,7,3,0,0,8501,8502,7,8,0,0,8502,
	8503,7,12,0,0,8503,8504,7,15,0,0,8504,8505,7,12,0,0,8505,8506,7,22,0,0,
	8506,8507,7,11,0,0,8507,1350,1,0,0,0,8508,8509,7,9,0,0,8509,8510,7,15,0,
	0,8510,8511,7,12,0,0,8511,8512,7,4,0,0,8512,8513,7,19,0,0,8513,8514,7,9,
	0,0,8514,1352,1,0,0,0,8515,8516,7,9,0,0,8516,8517,7,15,0,0,8517,8518,7,
	6,0,0,8518,8519,7,20,0,0,8519,8520,7,19,0,0,8520,8521,7,17,0,0,8521,8522,
	7,6,0,0,8522,1354,1,0,0,0,8523,8524,7,9,0,0,8524,8525,7,19,0,0,8525,8526,
	7,8,0,0,8526,8527,7,21,0,0,8527,1356,1,0,0,0,8528,8529,7,9,0,0,8529,8530,
	7,8,0,0,8530,8531,7,3,0,0,8531,8532,7,25,0,0,8532,8533,7,25,0,0,8533,8534,
	7,7,0,0,8534,8535,7,8,0,0,8535,1358,1,0,0,0,8536,8537,7,26,0,0,8537,8538,
	5,53,0,0,8538,8539,5,48,0,0,8539,8540,5,57,0,0,8540,1360,1,0,0,0,8541,8542,
	7,26,0,0,8542,8543,7,3,0,0,8543,1362,1,0,0,0,8544,8545,7,26,0,0,8545,8546,
	7,23,0,0,8546,8547,7,5,0,0,8547,1364,1,0,0,0,8548,8549,7,10,0,0,8549,8550,
	7,7,0,0,8550,8551,7,11,0,0,8551,1366,1,0,0,0,8552,8553,7,7,0,0,8553,8554,
	7,17,0,0,8554,8555,7,8,0,0,8555,1368,1,0,0,0,8556,8557,7,17,0,0,8557,8558,
	7,11,0,0,8558,8559,7,3,0,0,8559,1370,1,0,0,0,8560,8561,7,27,0,0,8561,8562,
	7,15,0,0,8562,8563,7,11,0,0,8563,1372,1,0,0,0,8564,8565,7,15,0,0,8565,8566,
	7,11,0,0,8566,8567,7,19,0,0,8567,1374,1,0,0,0,8568,8569,7,15,0,0,8569,8570,
	7,12,0,0,8570,8571,7,6,0,0,8571,8572,7,7,0,0,8572,8573,7,8,0,0,8573,8574,
	7,12,0,0,8574,8575,7,3,0,0,8575,8576,7,5,0,0,8576,1376,1,0,0,0,8577,8578,
	7,28,0,0,8578,8579,7,17,0,0,8579,8580,7,3,0,0,8580,8581,7,8,0,0,8581,8582,
	7,6,0,0,8582,8583,7,7,0,0,8583,8584,7,8,0,0,8584,1378,1,0,0,0,8585,8586,
	7,23,0,0,8586,8587,7,19,0,0,8587,8588,7,12,0,0,8588,8589,7,6,0,0,8589,8590,
	7,20,0,0,8590,1380,1,0,0,0,8591,8592,7,4,0,0,8592,8593,7,3,0,0,8593,8594,
	7,10,0,0,8594,1382,1,0,0,0,8595,8596,7,20,0,0,8596,8597,7,19,0,0,8597,8598,
	7,17,0,0,8598,8599,7,8,0,0,8599,1384,1,0,0,0,8600,8601,7,23,0,0,8601,8602,
	7,15,0,0,8602,8603,7,12,0,0,8603,8604,7,17,0,0,8604,8605,7,6,0,0,8605,8606,
	7,7,0,0,8606,1386,1,0,0,0,8607,8608,7,9,0,0,8608,8609,7,7,0,0,8609,8610,
	7,7,0,0,8610,8611,7,21,0,0,8611,1388,1,0,0,0,8612,8613,7,11,0,0,8613,8614,
	7,7,0,0,8614,8615,7,14,0,0,8615,8616,7,19,0,0,8616,8617,7,12,0,0,8617,8618,
	7,4,0,0,8618,1390,1,0,0,0,8619,8620,7,23,0,0,8620,8621,7,15,0,0,8621,8622,
	7,14,0,0,8622,8623,7,8,0,0,8623,8624,7,19,0,0,8624,8625,7,11,0,0,8625,8626,
	7,7,0,0,8626,8627,7,14,0,0,8627,8628,7,19,0,0,8628,8629,7,12,0,0,8629,8630,
	7,4,0,0,8630,1392,1,0,0,0,8631,8632,7,3,0,0,8632,8633,7,4,0,0,8633,8634,
	7,23,0,0,8634,8635,7,15,0,0,8635,8636,7,12,0,0,8636,1394,1,0,0,0,8637,8638,
	7,3,0,0,8638,8639,7,25,0,0,8639,8640,7,25,0,0,8640,8641,7,5,0,0,8641,8642,
	7,15,0,0,8642,8643,7,14,0,0,8643,8644,7,3,0,0,8644,8645,7,6,0,0,8645,8646,
	7,15,0,0,8646,8647,7,19,0,0,8647,8648,7,12,0,0,8648,8649,5,95,0,0,8649,
	8650,7,25,0,0,8650,8651,7,3,0,0,8651,8652,7,11,0,0,8652,8653,7,11,0,0,8653,
	8654,7,9,0,0,8654,8655,7,19,0,0,8655,8656,7,8,0,0,8656,8657,7,4,0,0,8657,
	8658,5,95,0,0,8658,8659,7,3,0,0,8659,8660,7,4,0,0,8660,8661,7,23,0,0,8661,
	8662,7,15,0,0,8662,8663,7,12,0,0,8663,1396,1,0,0,0,8664,8665,7,3,0,0,8665,
	8666,7,17,0,0,8666,8667,7,4,0,0,8667,8668,7,15,0,0,8668,8669,7,6,0,0,8669,
	8670,5,95,0,0,8670,8671,7,3,0,0,8671,8672,7,16,0,0,8672,8673,7,19,0,0,8673,
	8674,7,8,0,0,8674,8675,7,6,0,0,8675,8676,5,95,0,0,8676,8677,7,7,0,0,8677,
	8678,7,26,0,0,8678,8679,7,7,0,0,8679,8680,7,23,0,0,8680,8681,7,25,0,0,8681,
	8682,7,6,0,0,8682,1398,1,0,0,0,8683,8684,7,3,0,0,8684,8685,7,17,0,0,8685,
	8686,7,4,0,0,8686,8687,7,15,0,0,8687,8688,7,6,0,0,8688,8689,5,95,0,0,8689,
	8690,7,3,0,0,8690,8691,7,4,0,0,8691,8692,7,23,0,0,8692,8693,7,15,0,0,8693,
	8694,7,12,0,0,8694,1400,1,0,0,0,8695,8696,7,3,0,0,8696,8697,7,17,0,0,8697,
	8698,7,6,0,0,8698,8699,7,20,0,0,8699,8700,7,7,0,0,8700,8701,7,12,0,0,8701,
	8702,7,6,0,0,8702,8703,7,15,0,0,8703,8704,7,14,0,0,8704,8705,7,3,0,0,8705,
	8706,7,6,0,0,8706,8707,7,15,0,0,8707,8708,7,19,0,0,8708,8709,7,12,0,0,8709,
	8710,5,95,0,0,8710,8711,7,25,0,0,8711,8712,7,19,0,0,8712,8713,7,5,0,0,8713,
	8714,7,15,0,0,8714,8715,7,14,0,0,8715,8716,7,10,0,0,8716,8717,5,95,0,0,
	8717,8718,7,3,0,0,8718,8719,7,4,0,0,8719,8720,7,23,0,0,8720,8721,7,15,0,
	0,8721,8722,7,12,0,0,8722,1402,1,0,0,0,8723,8724,7,16,0,0,8724,8725,7,3,
	0,0,8725,8726,7,14,0,0,8726,8727,7,21,0,0,8727,8728,7,17,0,0,8728,8729,
	7,25,0,0,8729,8730,5,95,0,0,8730,8731,7,3,0,0,8731,8732,7,4,0,0,8732,8733,
	7,23,0,0,8733,8734,7,15,0,0,8734,8735,7,12,0,0,8735,1404,1,0,0,0,8736,8737,
	7,16,0,0,8737,8738,7,15,0,0,8738,8739,7,12,0,0,8739,8740,7,5,0,0,8740,8741,
	7,19,0,0,8741,8742,7,22,0,0,8742,8743,5,95,0,0,8743,8744,7,3,0,0,8744,8745,
	7,4,0,0,8745,8746,7,23,0,0,8746,8747,7,15,0,0,8747,8748,7,12,0,0,8748,1406,
	1,0,0,0,8749,8750,7,16,0,0,8750,8751,7,15,0,0,8751,8752,7,12,0,0,8752,8753,
	7,5,0,0,8753,8754,7,19,0,0,8754,8755,7,22,0,0,8755,8756,5,95,0,0,8756,8757,
	7,7,0,0,8757,8758,7,12,0,0,8758,8759,7,14,0,0,8759,8760,7,8,0,0,8760,8761,
	7,10,0,0,8761,8762,7,25,0,0,8762,8763,7,6,0,0,8763,8764,7,15,0,0,8764,8765,
	7,19,0,0,8765,8766,7,12,0,0,8766,8767,5,95,0,0,8767,8768,7,3,0,0,8768,8769,
	7,4,0,0,8769,8770,7,23,0,0,8770,8771,7,15,0,0,8771,8772,7,12,0,0,8772,1408,
	1,0,0,0,8773,8774,7,14,0,0,8774,8775,7,5,0,0,8775,8776,7,19,0,0,8776,8777,
	7,12,0,0,8777,8778,7,7,0,0,8778,8779,5,95,0,0,8779,8780,7,3,0,0,8780,8781,
	7,4,0,0,8781,8782,7,23,0,0,8782,8783,7,15,0,0,8783,8784,7,12,0,0,8784,1410,
	1,0,0,0,8785,8786,7,14,0,0,8786,8787,7,19,0,0,8787,8788,7,12,0,0,8788,8789,
	7,12,0,0,8789,8790,7,7,0,0,8790,8791,7,14,0,0,8791,8792,7,6,0,0,8792,8793,
	7,15,0,0,8793,8794,7,19,0,0,8794,8795,7,12,0,0,8795,8796,5,95,0,0,8796,
	8797,7,3,0,0,8797,8798,7,4,0,0,8798,8799,7,23,0,0,8799,8800,7,15,0,0,8800,
	8801,7,12,0,0,8801,1412,1,0,0,0,8802,8803,7,7,0,0,8803,8804,7,12,0,0,8804,
	8805,7,14,0,0,8805,8806,7,8,0,0,8806,8807,7,10,0,0,8807,8808,7,25,0,0,8808,
	8809,7,6,0,0,8809,8810,7,15,0,0,8810,8811,7,19,0,0,8811,8812,7,12,0,0,8812,
	8813,5,95,0,0,8813,8814,7,21,0,0,8814,8815,7,7,0,0,8815,8816,7,10,0,0,8816,
	8817,5,95,0,0,8817,8818,7,3,0,0,8818,8819,7,4,0,0,8819,8820,7,23,0,0,8820,
	8821,7,15,0,0,8821,8822,7,12,0,0,8822,1414,1,0,0,0,8823,8824,7,7,0,0,8824,
	8825,7,26,0,0,8825,8826,7,7,0,0,8826,8827,7,14,0,0,8827,8828,7,17,0,0,8828,
	8829,7,6,0,0,8829,8830,7,7,0,0,8830,1416,1,0,0,0,8831,8832,7,18,0,0,8832,
	8833,7,15,0,0,8833,8834,7,5,0,0,8834,8835,7,7,0,0,8835,1418,1,0,0,0,8836,
	8837,7,18,0,0,8837,8838,7,15,0,0,8838,8839,7,8,0,0,8839,8840,7,7,0,0,8840,
	8841,7,9,0,0,8841,8842,7,3,0,0,8842,8843,7,5,0,0,8843,8844,7,5,0,0,8844,
	8845,5,95,0,0,8845,8846,7,3,0,0,8846,8847,7,4,0,0,8847,8848,7,23,0,0,8848,
	8849,7,15,0,0,8849,8850,7,12,0,0,8850,1420,1,0,0,0,8851,8852,7,18,0,0,8852,
	8853,7,15,0,0,8853,8854,7,8,0,0,8854,8855,7,7,0,0,8855,8856,7,9,0,0,8856,
	8857,7,3,0,0,8857,8858,7,5,0,0,8858,8859,7,5,0,0,8859,8860,5,95,0,0,8860,
	8861,7,7,0,0,8861,8862,7,26,0,0,8862,8863,7,7,0,0,8863,8864,7,23,0,0,8864,
	8865,7,25,0,0,8865,8866,7,6,0,0,8866,1422,1,0,0,0,8867,8868,7,18,0,0,8868,
	8869,7,15,0,0,8869,8870,7,8,0,0,8870,8871,7,7,0,0,8871,8872,7,9,0,0,8872,
	8873,7,3,0,0,8873,8874,7,5,0,0,8874,8875,7,5,0,0,8875,8876,5,95,0,0,8876,
	8877,7,17,0,0,8877,8878,7,11,0,0,8878,8879,7,7,0,0,8879,8880,7,8,0,0,8880,
	1424,1,0,0,0,8881,8882,7,18,0,0,8882,8883,7,5,0,0,8883,8884,7,17,0,0,8884,
	8885,7,11,0,0,8885,8886,7,20,0,0,8886,8887,5,95,0,0,8887,8888,7,19,0,0,
	8888,8889,7,25,0,0,8889,8890,7,6,0,0,8890,8891,7,15,0,0,8891,8892,7,23,
	0,0,8892,8893,7,15,0,0,8893,8894,7,13,0,0,8894,8895,7,7,0,0,8895,8896,7,
	8,0,0,8896,8897,5,95,0,0,8897,8898,7,14,0,0,8898,8899,7,19,0,0,8899,8900,
	7,11,0,0,8900,8901,7,6,0,0,8901,8902,7,11,0,0,8902,1426,1,0,0,0,8903,8904,
	7,18,0,0,8904,8905,7,5,0,0,8905,8906,7,17,0,0,8906,8907,7,11,0,0,8907,8908,
	7,20,0,0,8908,8909,5,95,0,0,8909,8910,7,11,0,0,8910,8911,7,6,0,0,8911,8912,
	7,3,0,0,8912,8913,7,6,0,0,8913,8914,7,17,0,0,8914,8915,7,11,0,0,8915,1428,
	1,0,0,0,8916,8917,7,18,0,0,8917,8918,7,5,0,0,8918,8919,7,17,0,0,8919,8920,
	7,11,0,0,8920,8921,7,20,0,0,8921,8922,5,95,0,0,8922,8923,7,6,0,0,8923,8924,
	7,3,0,0,8924,8925,7,16,0,0,8925,8926,7,5,0,0,8926,8927,7,7,0,0,8927,8928,
	7,11,0,0,8928,1430,1,0,0,0,8929,8930,7,18,0,0,8930,8931,7,5,0,0,8931,8932,
	7,17,0,0,8932,8933,7,11,0,0,8933,8934,7,20,0,0,8934,8935,5,95,0,0,8935,
	8936,7,17,0,0,8936,8937,7,11,0,0,8937,8938,7,7,0,0,8938,8939,7,8,0,0,8939,
	8940,5,95,0,0,8940,8941,7,8,0,0,8941,8942,7,7,0,0,8942,8943,7,11,0,0,8943,
	8944,7,19,0,0,8944,8945,7,17,0,0,8945,8946,7,8,0,0,8946,8947,7,14,0,0,8947,
	8948,7,7,0,0,8948,8949,7,11,0,0,8949,1432,1,0,0,0,8950,8951,7,22,0,0,8951,
	8952,7,8,0,0,8952,8953,7,19,0,0,8953,8954,7,17,0,0,8954,8955,7,25,0,0,8955,
	8956,5,95,0,0,8956,8957,7,8,0,0,8957,8958,7,7,0,0,8958,8959,7,25,0,0,8959,
	8960,7,5,0,0,8960,8961,7,15,0,0,8961,8962,7,14,0,0,8962,8963,7,3,0,0,8963,
	8964,7,6,0,0,8964,8965,7,15,0,0,8965,8966,7,19,0,0,8966,8967,7,12,0,0,8967,
	8968,5,95,0,0,8968,8969,7,3,0,0,8969,8970,7,4,0,0,8970,8971,7,23,0,0,8971,
	8972,7,15,0,0,8972,8973,7,12,0,0,8973,1434,1,0,0,0,8974,8975,7,15,0,0,8975,
	8976,7,12,0,0,8976,8977,7,12,0,0,8977,8978,7,19,0,0,8978,8979,7,4,0,0,8979,
	8980,7,16,0,0,8980,8981,5,95,0,0,8981,8982,7,8,0,0,8982,8983,7,7,0,0,8983,
	8984,7,4,0,0,8984,8985,7,19,0,0,8985,8986,5,95,0,0,8986,8987,7,5,0,0,8987,
	8988,7,19,0,0,8988,8989,7,22,0,0,8989,8990,5,95,0,0,8990,8991,7,3,0,0,8991,
	8992,7,8,0,0,8992,8993,7,14,0,0,8993,8994,7,20,0,0,8994,8995,7,15,0,0,8995,
	8996,7,24,0,0,8996,8997,7,7,0,0,8997,1436,1,0,0,0,8998,8999,7,15,0,0,8999,
	9000,7,12,0,0,9000,9001,7,12,0,0,9001,9002,7,19,0,0,9002,9003,7,4,0,0,9003,
	9004,7,16,0,0,9004,9005,5,95,0,0,9005,9006,7,8,0,0,9006,9007,7,7,0,0,9007,
	9008,7,4,0,0,9008,9009,7,19,0,0,9009,9010,5,95,0,0,9010,9011,7,5,0,0,9011,
	9012,7,19,0,0,9012,9013,7,22,0,0,9013,9014,5,95,0,0,9014,9015,7,7,0,0,9015,
	9016,7,12,0,0,9016,9017,7,3,0,0,9017,9018,7,16,0,0,9018,9019,7,5,0,0,9019,
	9020,7,7,0,0,9020,1438,1,0,0,0,9021,9022,7,15,0,0,9022,9023,7,12,0,0,9023,
	9024,7,24,0,0,9024,9025,7,19,0,0,9025,9026,7,21,0,0,9026,9027,7,7,0,0,9027,
	1440,1,0,0,0,9028,9029,7,5,0,0,9029,9030,7,3,0,0,9030,9031,7,23,0,0,9031,
	9032,7,16,0,0,9032,9033,7,4,0,0,9033,9034,7,3,0,0,9034,1442,1,0,0,0,9035,
	9036,7,12,0,0,9036,9037,7,4,0,0,9037,9038,7,16,0,0,9038,9039,5,95,0,0,9039,
	9040,7,11,0,0,9040,9041,7,6,0,0,9041,9042,7,19,0,0,9042,9043,7,8,0,0,9043,
	9044,7,7,0,0,9044,9045,7,4,0,0,9045,9046,5,95,0,0,9046,9047,7,17,0,0,9047,
	9048,7,11,0,0,9048,9049,7,7,0,0,9049,9050,7,8,0,0,9050,1444,1,0,0,0,9051,
	9052,7,25,0,0,9052,9053,7,3,0,0,9053,9054,7,11,0,0,9054,9055,7,11,0,0,9055,
	9056,7,9,0,0,9056,9057,7,19,0,0,9057,9058,7,8,0,0,9058,9059,7,4,0,0,9059,
	9060,7,5,0,0,9060,9061,7,7,0,0,9061,9062,7,11,0,0,9062,9063,7,11,0,0,9063,
	9064,5,95,0,0,9064,9065,7,17,0,0,9065,9066,7,11,0,0,9066,9067,7,7,0,0,9067,
	9068,7,8,0,0,9068,9069,5,95,0,0,9069,9070,7,3,0,0,9070,9071,7,4,0,0,9071,
	9072,7,23,0,0,9072,9073,7,15,0,0,9073,9074,7,12,0,0,9074,1446,1,0,0,0,9075,
	9076,7,25,0,0,9076,9077,7,7,0,0,9077,9078,7,8,0,0,9078,9079,7,11,0,0,9079,
	9080,7,15,0,0,9080,9081,7,11,0,0,9081,9082,7,6,0,0,9082,9083,5,95,0,0,9083,
	9084,7,8,0,0,9084,9085,7,19,0,0,9085,9086,5,95,0,0,9086,9087,7,24,0,0,9087,
	9088,7,3,0,0,9088,9089,7,8,0,0,9089,9090,7,15,0,0,9090,9091,7,3,0,0,9091,
	9092,7,16,0,0,9092,9093,7,5,0,0,9093,9094,7,7,0,0,9094,9095,7,11,0,0,9095,
	9096,5,95,0,0,9096,9097,7,3,0,0,9097,9098,7,4,0,0,9098,9099,7,23,0,0,9099,
	9100,7,15,0,0,9100,9101,7,12,0,0,9101,1448,1,0,0,0,9102,9103,7,25,0,0,9103,
	9104,7,8,0,0,9104,9105,7,15,0,0,9105,9106,7,24,0,0,9106,9107,7,15,0,0,9107,
	9108,7,5,0,0,9108,9109,7,7,0,0,9109,9110,7,22,0,0,9110,9111,7,7,0,0,9111,
	9112,7,11,0,0,9112,1450,1,0,0,0,9113,9114,7,25,0,0,9114,9115,7,8,0,0,9115,
	9116,7,19,0,0,9116,9117,7,14,0,0,9117,9118,7,7,0,0,9118,9119,7,11,0,0,9119,
	9120,7,11,0,0,9120,1452,1,0,0,0,9121,9122,7,8,0,0,9122,9123,7,7,0,0,9123,
	9124,7,5,0,0,9124,9125,7,19,0,0,9125,9126,7,3,0,0,9126,9127,7,4,0,0,9127,
	1454,1,0,0,0,9128,9129,7,8,0,0,9129,9130,7,7,0,0,9130,9131,7,25,0,0,9131,
	9132,7,5,0,0,9132,9133,7,15,0,0,9133,9134,7,14,0,0,9134,9135,7,3,0,0,9135,
	9136,7,6,0,0,9136,9137,7,15,0,0,9137,9138,7,19,0,0,9138,9139,7,12,0,0,9139,
	9140,5,95,0,0,9140,9141,7,3,0,0,9141,9142,7,25,0,0,9142,9143,7,25,0,0,9143,
	9144,7,5,0,0,9144,9145,7,15,0,0,9145,9146,7,7,0,0,9146,9147,7,8,0,0,9147,
	1456,1,0,0,0,9148,9149,7,8,0,0,9149,9150,7,7,0,0,9150,9151,7,25,0,0,9151,
	9152,7,5,0,0,9152,9153,7,15,0,0,9153,9154,7,14,0,0,9154,9155,7,3,0,0,9155,
	9156,7,6,0,0,9156,9157,7,15,0,0,9157,9158,7,19,0,0,9158,9159,7,12,0,0,9159,
	9160,5,95,0,0,9160,9161,7,11,0,0,9161,9162,7,5,0,0,9162,9163,7,3,0,0,9163,
	9164,7,24,0,0,9164,9165,7,7,0,0,9165,9166,5,95,0,0,9166,9167,7,3,0,0,9167,
	9168,7,4,0,0,9168,9169,7,23,0,0,9169,9170,7,15,0,0,9170,9171,7,12,0,0,9171,
	1458,1,0,0,0,9172,9173,7,8,0,0,9173,9174,7,7,0,0,9174,9175,7,11,0,0,9175,
	9176,7,19,0,0,9176,9177,7,17,0,0,9177,9178,7,8,0,0,9178,9179,7,14,0,0,9179,
	9180,7,7,0,0,9180,9181,5,95,0,0,9181,9182,7,22,0,0,9182,9183,7,8,0,0,9183,
	9184,7,19,0,0,9184,9185,7,17,0,0,9185,9186,7,25,0,0,9186,9187,5,95,0,0,
	9187,9188,7,3,0,0,9188,9189,7,4,0,0,9189,9190,7,23,0,0,9190,9191,7,15,0,
	0,9191,9192,7,12,0,0,9192,1460,1,0,0,0,9193,9194,7,8,0,0,9194,9195,7,7,
	0,0,9195,9196,7,11,0,0,9196,9197,7,19,0,0,9197,9198,7,17,0,0,9198,9199,
	7,8,0,0,9199,9200,7,14,0,0,9200,9201,7,7,0,0,9201,9202,5,95,0,0,9202,9203,
	7,22,0,0,9203,9204,7,8,0,0,9204,9205,7,19,0,0,9205,9206,7,17,0,0,9206,9207,
	7,25,0,0,9207,9208,5,95,0,0,9208,9209,7,17,0,0,9209,9210,7,11,0,0,9210,
	9211,7,7,0,0,9211,9212,7,8,0,0,9212,1462,1,0,0,0,9213,9214,7,8,0,0,9214,
	9215,7,19,0,0,9215,9216,7,5,0,0,9216,9217,7,7,0,0,9217,9218,5,95,0,0,9218,
	9219,7,3,0,0,9219,9220,7,4,0,0,9220,9221,7,23,0,0,9221,9222,7,15,0,0,9222,
	9223,7,12,0,0,9223,1464,1,0,0,0,9224,9225,7,8,0,0,9225,9226,7,19,0,0,9226,
	9227,7,17,0,0,9227,9228,7,6,0,0,9228,9229,7,15,0,0,9229,9230,7,12,0,0,9230,
	9231,7,7,0,0,9231,1466,1,0,0,0,9232,9233,7,11,0,0,9233,9234,5,51,0,0,9234,
	1468,1,0,0,0,9235,9236,7,11,0,0,9236,9237,7,7,0,0,9237,9238,7,8,0,0,9238,
	9239,7,24,0,0,9239,9240,7,15,0,0,9240,9241,7,14,0,0,9241,9242,7,7,0,0,9242,
	9243,5,95,0,0,9243,9244,7,14,0,0,9244,9245,7,19,0,0,9245,9246,7,12,0,0,
	9246,9247,7,12,0,0,9247,9248,7,7,0,0,9248,9249,7,14,0,0,9249,9250,7,6,0,
	0,9250,9251,7,15,0,0,9251,9252,7,19,0,0,9252,9253,7,12,0,0,9253,9254,5,
	95,0,0,9254,9255,7,3,0,0,9255,9256,7,4,0,0,9256,9257,7,23,0,0,9257,9258,
	7,15,0,0,9258,9259,7,12,0,0,9259,1470,1,0,0,0,9260,9262,3,2289,1144,0,9261,
	9260,1,0,0,0,9261,9262,1,0,0,0,9262,9263,1,0,0,0,9263,9264,7,11,0,0,9264,
	9265,7,7,0,0,9265,9266,7,11,0,0,9266,9267,7,11,0,0,9267,9268,7,15,0,0,9268,
	9269,7,19,0,0,9269,9270,7,12,0,0,9270,9271,5,95,0,0,9271,9272,7,24,0,0,
	9272,9273,7,3,0,0,9273,9274,7,8,0,0,9274,9275,7,15,0,0,9275,9276,7,3,0,
	0,9276,9277,7,16,0,0,9277,9278,7,5,0,0,9278,9279,7,7,0,0,9279,9280,7,11,
	0,0,9280,9281,5,95,0,0,9281,9282,7,3,0,0,9282,9283,7,4,0,0,9283,9284,7,
	23,0,0,9284,9285,7,15,0,0,9285,9286,7,12,0,0,9286,9288,1,0,0,0,9287,9289,
	3,2289,1144,0,9288,9287,1,0,0,0,9288,9289,1,0,0,0,9289,1472,1,0,0,0,9290,
	9291,7,11,0,0,9291,9292,7,7,0,0,9292,9293,7,6,0,0,9293,9294,5,95,0,0,9294,
	9295,7,17,0,0,9295,9296,7,11,0,0,9296,9297,7,7,0,0,9297,9298,7,8,0,0,9298,
	9299,5,95,0,0,9299,9300,7,15,0,0,9300,9301,7,4,0,0,9301,1474,1,0,0,0,9302,
	9303,7,11,0,0,9303,9304,7,20,0,0,9304,9305,7,19,0,0,9305,9306,7,9,0,0,9306,
	9307,5,95,0,0,9307,9308,7,8,0,0,9308,9309,7,19,0,0,9309,9310,7,17,0,0,9310,
	9311,7,6,0,0,9311,9312,7,15,0,0,9312,9313,7,12,0,0,9313,9314,7,7,0,0,9314,
	1476,1,0,0,0,9315,9316,7,11,0,0,9316,9317,7,20,0,0,9317,9318,7,17,0,0,9318,
	9319,7,6,0,0,9319,9320,7,4,0,0,9320,9321,7,19,0,0,9321,9322,7,9,0,0,9322,
	9323,7,12,0,0,9323,1478,1,0,0,0,9324,9325,7,11,0,0,9325,9326,7,17,0,0,9326,
	9327,7,25,0,0,9327,9328,7,7,0,0,9328,9329,7,8,0,0,9329,1480,1,0,0,0,9330,
	9331,7,11,0,0,9331,9332,7,10,0,0,9332,9333,7,11,0,0,9333,9334,7,6,0,0,9334,
	9335,7,7,0,0,9335,9336,7,23,0,0,9336,9337,5,95,0,0,9337,9338,7,24,0,0,9338,
	9339,7,3,0,0,9339,9340,7,8,0,0,9340,9341,7,15,0,0,9341,9342,7,3,0,0,9342,
	9343,7,16,0,0,9343,9344,7,5,0,0,9344,9345,7,7,0,0,9345,9346,7,11,0,0,9346,
	9347,5,95,0,0,9347,9348,7,3,0,0,9348,9349,7,4,0,0,9349,9350,7,23,0,0,9350,
	9351,7,15,0,0,9351,9352,7,12,0,0,9352,1482,1,0,0,0,9353,9354,7,6,0,0,9354,
	9355,7,3,0,0,9355,9356,7,16,0,0,9356,9357,7,5,0,0,9357,9358,7,7,0,0,9358,
	9359,7,11,0,0,9359,1484,1,0,0,0,9360,9361,7,6,0,0,9361,9362,7,3,0,0,9362,
	9363,7,16,0,0,9363,9364,7,5,0,0,9364,9365,7,7,0,0,9365,9366,5,95,0,0,9366,
	9367,7,7,0,0,9367,9368,7,12,0,0,9368,9369,7,14,0,0,9369,9370,7,8,0,0,9370,
	9371,7,10,0,0,9371,9372,7,25,0,0,9372,9373,7,6,0,0,9373,9374,7,15,0,0,9374,
	9375,7,19,0,0,9375,9376,7,12,0,0,9376,9377,5,95,0,0,9377,9378,7,3,0,0,9378,
	9379,7,4,0,0,9379,9380,7,23,0,0,9380,9381,7,15,0,0,9381,9382,7,12,0,0,9382,
	1486,1,0,0,0,9383,9384,7,24,0,0,9384,9385,7,7,0,0,9385,9386,7,8,0,0,9386,
	9387,7,11,0,0,9387,9388,7,15,0,0,9388,9389,7,19,0,0,9389,9390,7,12,0,0,
	9390,9391,5,95,0,0,9391,9392,7,6,0,0,9392,9393,7,19,0,0,9393,9394,7,21,
	0,0,9394,9395,7,7,0,0,9395,9396,7,12,0,0,9396,9397,5,95,0,0,9397,9398,7,
	3,0,0,9398,9399,7,4,0,0,9399,9400,7,23,0,0,9400,9401,7,15,0,0,9401,9402,
	7,12,0,0,9402,1488,1,0,0,0,9403,9404,7,26,0,0,9404,9405,7,3,0,0,9405,9406,
	5,95,0,0,9406,9407,7,8,0,0,9407,9408,7,7,0,0,9408,9409,7,14,0,0,9409,9410,
	7,19,0,0,9410,9411,7,24,0,0,9411,9412,7,7,0,0,9412,9413,7,8,0,0,9413,9414,
	5,95,0,0,9414,9415,7,3,0,0,9415,9416,7,4,0,0,9416,9417,7,23,0,0,9417,9418,
	7,15,0,0,9418,9419,7,12,0,0,9419,1490,1,0,0,0,9420,9421,7,3,0,0,9421,9422,
	7,8,0,0,9422,9423,7,23,0,0,9423,9424,7,11,0,0,9424,9425,7,14,0,0,9425,9426,
	7,15,0,0,9426,9427,7,15,0,0,9427,9428,5,56,0,0,9428,1492,1,0,0,0,9429,9430,
	7,3,0,0,9430,9431,7,11,0,0,9431,9432,7,14,0,0,9432,9433,7,15,0,0,9433,9434,
	7,15,0,0,9434,1494,1,0,0,0,9435,9436,7,16,0,0,9436,9437,7,15,0,0,9437,9438,
	7,22,0,0,9438,9439,5,53,0,0,9439,1496,1,0,0,0,9440,9441,7,14,0,0,9441,9442,
	7,25,0,0,9442,9443,5,49,0,0,9443,9444,5,50,0,0,9444,9445,5,53,0,0,9445,
	9446,5,48,0,0,9446,1498,1,0,0,0,9447,9448,7,14,0,0,9448,9449,7,25,0,0,9449,
	9450,5,49,0,0,9450,9451,5,50,0,0,9451,9452,5,53,0,0,9452,9453,5,49,0,0,
	9453,1500,1,0,0,0,9454,9455,7,14,0,0,9455,9456,7,25,0,0,9456,9457,5,49,
	0,0,9457,9458,5,50,0,0,9458,9459,5,53,0,0,9459,9460,5,54,0,0,9460,1502,
	1,0,0,0,9461,9462,7,14,0,0,9462,9463,7,25,0,0,9463,9464,5,49,0,0,9464,9465,
	5,50,0,0,9465,9466,5,53,0,0,9466,9467,5,55,0,0,9467,1504,1,0,0,0,9468,9469,
	7,14,0,0,9469,9470,7,25,0,0,9470,9471,5,56,0,0,9471,9472,5,53,0,0,9472,
	9473,5,48,0,0,9473,1506,1,0,0,0,9474,9475,7,14,0,0,9475,9476,7,25,0,0,9476,
	9477,5,56,0,0,9477,9478,5,53,0,0,9478,9479,5,50,0,0,9479,1508,1,0,0,0,9480,
	9481,7,14,0,0,9481,9482,7,25,0,0,9482,9483,5,56,0,0,9483,9484,5,54,0,0,
	9484,9485,5,54,0,0,9485,1510,1,0,0,0,9486,9487,7,14,0,0,9487,9488,7,25,
	0,0,9488,9489,5,57,0,0,9489,9490,5,51,0,0,9490,9491,5,50,0,0,9491,1512,
	1,0,0,0,9492,9493,7,4,0,0,9493,9494,7,7,0,0,9494,9495,7,14,0,0,9495,9496,
	5,56,0,0,9496,1514,1,0,0,0,9497,9498,7,7,0,0,9498,9499,7,17,0,0,9499,9500,
	7,14,0,0,9500,9501,7,27,0,0,9501,9502,7,25,0,0,9502,9503,7,23,0,0,9503,
	9504,7,11,0,0,9504,1516,1,0,0,0,9505,9506,7,7,0,0,9506,9507,7,17,0,0,9507,
	9508,7,14,0,0,9508,9509,7,21,0,0,9509,9510,7,8,0,0,9510,1518,1,0,0,0,9511,
	9512,7,22,0,0,9512,9513,7,16,0,0,9513,9514,5,49,0,0,9514,9515,5,56,0,0,
	9515,9516,5,48,0,0,9516,9517,5,51,0,0,9517,9518,5,48,0,0,9518,1520,1,0,
	0,0,9519,9520,7,22,0,0,9520,9521,7,16,0,0,9521,9522,5,50,0,0,9522,9523,
	5,51,0,0,9523,9524,5,49,0,0,9524,9525,5,50,0,0,9525,1522,1,0,0,0,9526,9527,
	7,22,0,0,9527,9528,7,16,0,0,9528,9529,7,21,0,0,9529,1524,1,0,0,0,9530,9531,
	7,22,0,0,9531,9532,7,7,0,0,9532,9533,7,19,0,0,9533,9534,7,11,0,0,9534,9535,
	7,6,0,0,9535,9536,7,4,0,0,9536,9537,5,56,0,0,9537,1526,1,0,0,0,9538,9539,
	7,22,0,0,9539,9540,7,8,0,0,9540,9541,7,7,0,0,9541,9542,7,7,0,0,9542,9543,
	7,21,0,0,9543,1528,1,0,0,0,9544,9545,7,20,0,0,9545,9546,7,7,0,0,9546,9547,
	7,16,0,0,9547,9548,7,8,0,0,9548,9549,7,7,0,0,9549,9550,7,9,0,0,9550,1530,
	1,0,0,0,9551,9552,7,20,0,0,9552,9553,7,25,0,0,9553,9554,5,56,0,0,9554,1532,
	1,0,0,0,9555,9556,7,21,0,0,9556,9557,7,7,0,0,9557,9558,7,10,0,0,9558,9559,
	7,16,0,0,9559,9560,7,14,0,0,9560,9561,7,11,0,0,9561,9562,5,50,0,0,9562,
	1534,1,0,0,0,9563,9564,7,21,0,0,9564,9565,7,19,0,0,9565,9566,7,15,0,0,9566,
	9567,5,56,0,0,9567,9568,7,8,0,0,9568,1536,1,0,0,0,9569,9570,7,21,0,0,9570,
	9571,7,19,0,0,9571,9572,7,15,0,0,9572,9573,5,56,0,0,9573,9574,7,17,0,0,
	9574,1538,1,0,0,0,9575,9576,7,5,0,0,9576,9577,7,3,0,0,9577,9578,7,6,0,0,
	9578,9579,7,15,0,0,9579,9580,7,12,0,0,9580,9581,5,49,0,0,9581,1540,1,0,
	0,0,9582,9583,7,5,0,0,9583,9584,7,3,0,0,9584,9585,7,6,0,0,9585,9586,7,15,
	0,0,9586,9587,7,12,0,0,9587,9588,5,50,0,0,9588,1542,1,0,0,0,9589,9590,7,
	5,0,0,9590,9591,7,3,0,0,9591,9592,7,6,0,0,9592,9593,7,15,0,0,9593,9594,
	7,12,0,0,9594,9595,5,53,0,0,9595,1544,1,0,0,0,9596,9597,7,5,0,0,9597,9598,
	7,3,0,0,9598,9599,7,6,0,0,9599,9600,7,15,0,0,9600,9601,7,12,0,0,9601,9602,
	5,55,0,0,9602,1546,1,0,0,0,9603,9604,7,23,0,0,9604,9605,7,3,0,0,9605,9606,
	7,14,0,0,9606,9607,7,14,0,0,9607,9608,7,7,0,0,9608,1548,1,0,0,0,9609,9610,
	7,23,0,0,9610,9611,7,3,0,0,9611,9612,7,14,0,0,9612,9613,7,8,0,0,9613,9614,
	7,19,0,0,9614,9615,7,23,0,0,9615,9616,7,3,0,0,9616,9617,7,12,0,0,9617,1550,
	1,0,0,0,9618,9619,7,11,0,0,9619,9620,7,27,0,0,9620,9621,7,15,0,0,9621,9622,
	7,11,0,0,9622,1552,1,0,0,0,9623,9624,7,11,0,0,9624,9625,7,9,0,0,9625,9626,
	7,7,0,0,9626,9627,5,55,0,0,9627,1554,1,0,0,0,9628,9629,7,6,0,0,9629,9630,
	7,15,0,0,9630,9631,7,11,0,0,9631,9632,5,54,0,0,9632,9633,5,50,0,0,9633,
	9634,5,48,0,0,9634,1556,1,0,0,0,9635,9636,7,17,0,0,9636,9637,7,14,0,0,9637,
	9638,7,11,0,0,9638,9639,5,50,0,0,9639,1558,1,0,0,0,9640,9641,7,17,0,0,9641,
	9642,7,27,0,0,9642,9643,7,15,0,0,9643,9644,7,11,0,0,9644,1560,1,0,0,0,9645,
	9646,7,17,0,0,9646,9647,7,6,0,0,9647,9648,7,18,0,0,9648,9649,5,49,0,0,9649,
	9650,5,54,0,0,9650,1562,1,0,0,0,9651,9652,7,17,0,0,9652,9653,7,6,0,0,9653,
	9654,7,18,0,0,9654,9655,5,49,0,0,9655,9656,5,54,0,0,9656,9657,7,5,0,0,9657,
	9658,7,7,0,0,9658,1564,1,0,0,0,9659,9660,7,17,0,0,9660,9661,7,6,0,0,9661,
	9662,7,18,0,0,9662,9663,5,51,0,0,9663,9664,5,50,0,0,9664,1566,1,0,0,0,9665,
	9666,7,17,0,0,9666,9667,7,6,0,0,9667,9668,7,18,0,0,9668,9669,5,56,0,0,9669,
	1568,1,0,0,0,9670,9671,7,17,0,0,9671,9672,7,6,0,0,9672,9673,7,18,0,0,9673,
	9674,5,56,0,0,9674,9675,7,23,0,0,9675,9676,7,16,0,0,9676,9677,5,51,0,0,
	9677,1570,1,0,0,0,9678,9679,7,17,0,0,9679,9680,7,6,0,0,9680,9681,7,18,0,
	0,9681,9682,5,56,0,0,9682,9683,7,23,0,0,9683,9684,7,16,0,0,9684,9685,5,
	52,0,0,9685,1572,1,0,0,0,9686,9687,7,3,0,0,9687,9688,7,8,0,0,9688,9689,
	7,14,0,0,9689,9690,7,20,0,0,9690,9691,7,15,0,0,9691,9692,7,24,0,0,9692,
	9693,7,7,0,0,9693,1574,1,0,0,0,9694,9695,7,16,0,0,9695,9696,7,5,0,0,9696,
	9697,7,3,0,0,9697,9698,7,14,0,0,9698,9699,7,21,0,0,9699,9700,7,20,0,0,9700,
	9701,7,19,0,0,9701,9702,7,5,0,0,9702,9703,7,7,0,0,9703,1576,1,0,0,0,9704,
	9705,7,14,0,0,9705,9706,7,11,0,0,9706,9707,7,24,0,0,9707,1578,1,0,0,0,9708,
	9709,7,18,0,0,9709,9710,7,7,0,0,9710,9711,7,4,0,0,9711,9712,7,7,0,0,9712,
	9713,7,8,0,0,9713,9714,7,3,0,0,9714,9715,7,6,0,0,9715,9716,7,7,0,0,9716,
	9717,7,4,0,0,9717,1580,1,0,0,0,9718,9719,7,15,0,0,9719,9720,7,12,0,0,9720,
	9721,7,12,0,0,9721,9722,7,19,0,0,9722,9723,7,4,0,0,9723,9724,7,16,0,0,9724,
	1582,1,0,0,0,9725,9726,7,23,0,0,9726,9727,7,7,0,0,9727,9728,7,23,0,0,9728,
	9729,7,19,0,0,9729,9730,7,8,0,0,9730,9731,7,10,0,0,9731,1584,1,0,0,0,9732,
	9733,7,23,0,0,9733,9734,7,8,0,0,9734,9735,7,22,0,0,9735,9736,5,95,0,0,9736,
	9737,7,23,0,0,9737,9738,7,10,0,0,9738,9739,7,15,0,0,9739,9740,7,11,0,0,
	9740,9741,7,3,0,0,9741,9742,7,23,0,0,9742,1586,1,0,0,0,9743,9744,7,23,0,
	0,9744,9745,7,10,0,0,9745,9746,7,15,0,0,9746,9747,7,11,0,0,9747,9748,7,
	3,0,0,9748,9749,7,23,0,0,9749,1588,1,0,0,0,9750,9751,7,12,0,0,9751,9752,
	7,4,0,0,9752,9753,7,16,0,0,9753,1590,1,0,0,0,9754,9755,7,12,0,0,9755,9756,
	7,4,0,0,9756,9757,7,16,0,0,9757,9758,7,14,0,0,9758,9759,7,5,0,0,9759,9760,
	7,17,0,0,9760,9761,7,11,0,0,9761,9762,7,6,0,0,9762,9763,7,7,0,0,9763,9764,
	7,8,0,0,9764,1592,1,0,0,0,9765,9766,7,25,0,0,9766,9767,7,7,0,0,9767,9768,
	7,8,0,0,9768,9769,7,18,0,0,9769,9770,7,19,0,0,9770,9771,7,8,0,0,9771,9772,
	7,23,0,0,9772,9773,7,3,0,0,9773,9774,7,12,0,0,9774,9775,7,14,0,0,9775,9776,
	7,7,0,0,9776,9777,5,95,0,0,9777,9778,7,11,0,0,9778,9779,7,14,0,0,9779,9780,
	7,20,0,0,9780,9781,7,7,0,0,9781,9782,7,23,0,0,9782,9783,7,3,0,0,9783,1594,
	1,0,0,0,9784,9785,7,6,0,0,9785,9786,7,19,0,0,9786,9787,7,21,0,0,9787,9788,
	7,17,0,0,9788,9789,7,4,0,0,9789,9790,7,16,0,0,9790,1596,1,0,0,0,9791,9792,
	7,8,0,0,9792,9793,7,7,0,0,9793,9794,7,25,0,0,9794,9795,7,7,0,0,9795,9796,
	7,3,0,0,9796,9797,7,6,0,0,9797,9798,7,3,0,0,9798,9799,7,16,0,0,9799,9800,
	7,5,0,0,9800,9801,7,7,0,0,9801,1598,1,0,0,0,9802,9803,7,14,0,0,9803,9804,
	7,19,0,0,9804,9805,7,23,0,0,9805,9806,7,23,0,0,9806,9807,7,15,0,0,9807,
	9808,7,6,0,0,9808,9809,7,6,0,0,9809,9810,7,7,0,0,9810,9811,7,4,0,0,9811,
	1600,1,0,0,0,9812,9813,7,17,0,0,9813,9814,7,12,0,0,9814,9815,7,14,0,0,9815,
	9816,7,19,0,0,9816,9817,7,23,0,0,9817,9818,7,23,0,0,9818,9819,7,15,0,0,
	9819,9820,7,6,0,0,9820,9821,7,6,0,0,9821,9822,7,7,0,0,9822,9823,7,4,0,0,
	9823,1602,1,0,0,0,9824,9825,7,11,0,0,9825,9826,7,7,0,0,9826,9827,7,8,0,
	0,9827,9828,7,15,0,0,9828,9829,7,3,0,0,9829,9830,7,5,0,0,9830,9831,7,15,
	0,0,9831,9832,7,13,0,0,9832,9833,7,3,0,0,9833,9834,7,16,0,0,9834,9835,7,
	5,0,0,9835,9836,7,7,0,0,9836,1604,1,0,0,0,9837,9838,7,22,0,0,9838,9839,
	7,7,0,0,9839,9840,7,19,0,0,9840,9841,7,23,0,0,9841,9842,7,7,0,0,9842,9843,
	7,6,0,0,9843,9844,7,8,0,0,9844,9845,7,10,0,0,9845,9846,7,14,0,0,9846,9847,
	7,19,0,0,9847,9848,7,5,0,0,9848,9849,7,5,0,0,9849,9850,7,7,0,0,9850,9851,
	7,14,0,0,9851,9852,7,6,0,0,9852,9853,7,15,0,0,9853,9854,7,19,0,0,9854,9855,
	7,12,0,0,9855,1606,1,0,0,0,9856,9857,7,22,0,0,9857,9858,7,7,0,0,9858,9859,
	7,19,0,0,9859,9860,7,23,0,0,9860,9861,7,14,0,0,9861,9862,7,19,0,0,9862,
	9863,7,5,0,0,9863,9864,7,5,0,0,9864,9865,7,7,0,0,9865,9866,7,14,0,0,9866,
	9867,7,6,0,0,9867,9868,7,15,0,0,9868,9869,7,19,0,0,9869,9870,7,12,0,0,9870,
	1608,1,0,0,0,9871,9872,7,22,0,0,9872,9873,7,7,0,0,9873,9874,7,19,0,0,9874,
	9875,7,23,0,0,9875,9876,7,7,0,0,9876,9877,7,6,0,0,9877,9878,7,8,0,0,9878,
	9879,7,10,0,0,9879,1610,1,0,0,0,9880,9881,7,5,0,0,9881,9882,7,15,0,0,9882,
	9883,7,12,0,0,9883,9884,7,7,0,0,9884,9885,7,11,0,0,9885,9886,7,6,0,0,9886,
	9887,7,8,0,0,9887,9888,7,15,0,0,9888,9889,7,12,0,0,9889,9890,7,22,0,0,9890,
	1612,1,0,0,0,9891,9892,7,23,0,0,9892,9893,7,17,0,0,9893,9894,7,5,0,0,9894,
	9895,7,6,0,0,9895,9896,7,15,0,0,9896,9897,7,5,0,0,9897,9898,7,15,0,0,9898,
	9899,7,12,0,0,9899,9900,7,7,0,0,9900,9901,7,11,0,0,9901,9902,7,6,0,0,9902,
	9903,7,8,0,0,9903,9904,7,15,0,0,9904,9905,7,12,0,0,9905,9906,7,22,0,0,9906,
	1614,1,0,0,0,9907,9908,7,23,0,0,9908,9909,7,17,0,0,9909,9910,7,5,0,0,9910,
	9911,7,6,0,0,9911,9912,7,15,0,0,9912,9913,7,25,0,0,9913,9914,7,19,0,0,9914,
	9915,7,15,0,0,9915,9916,7,12,0,0,9916,9917,7,6,0,0,9917,1616,1,0,0,0,9918,
	9919,7,23,0,0,9919,9920,7,17,0,0,9920,9921,7,5,0,0,9921,9922,7,6,0,0,9922,
	9923,7,15,0,0,9923,9924,7,25,0,0,9924,9925,7,19,0,0,9925,9926,7,5,0,0,9926,
	9927,7,10,0,0,9927,9928,7,22,0,0,9928,9929,7,19,0,0,9929,9930,7,12,0,0,
	9930,1618,1,0,0,0,9931,9932,7,25,0,0,9932,9933,7,19,0,0,9933,9934,7,15,
	0,0,9934,9935,7,12,0,0,9935,9936,7,6,0,0,9936,1620,1,0,0,0,9937,9938,7,
	25,0,0,9938,9939,7,19,0,0,9939,9940,7,5,0,0,9940,9941,7,10,0,0,9941,9942,
	7,22,0,0,9942,9943,7,19,0,0,9943,9944,7,12,0,0,9944,1622,1,0,0,0,9945,9946,
	7,3,0,0,9946,9947,7,16,0,0,9947,9948,7,11,0,0,9948,1624,1,0,0,0,9949,9950,
	7,3,0,0,9950,9951,7,14,0,0,9951,9952,7,19,0,0,9952,9953,7,11,0,0,9953,1626,
	1,0,0,0,9954,9955,7,3,0,0,9955,9956,7,4,0,0,9956,9957,7,4,0,0,9957,9958,
	7,4,0,0,9958,9959,7,3,0,0,9959,9960,7,6,0,0,9960,9961,7,7,0,0,9961,1628,
	1,0,0,0,9962,9963,7,3,0,0,9963,9964,7,4,0,0,9964,9965,7,4,0,0,9965,9966,
	7,6,0,0,9966,9967,7,15,0,0,9967,9968,7,23,0,0,9968,9969,7,7,0,0,9969,1630,
	1,0,0,0,9970,9971,7,3,0,0,9971,9972,7,7,0,0,9972,9973,7,11,0,0,9973,9974,
	5,95,0,0,9974,9975,7,4,0,0,9975,9976,7,7,0,0,9976,9977,7,14,0,0,9977,9978,
	7,8,0,0,9978,9979,7,10,0,0,9979,9980,7,25,0,0,9980,9981,7,6,0,0,9981,1632,
	1,0,0,0,9982,9983,7,3,0,0,9983,9984,7,7,0,0,9984,9985,7,11,0,0,9985,9986,
	5,95,0,0,9986,9987,7,7,0,0,9987,9988,7,12,0,0,9988,9989,7,14,0,0,9989,9990,
	7,8,0,0,9990,9991,7,10,0,0,9991,9992,7,25,0,0,9992,9993,7,6,0,0,9993,1634,
	1,0,0,0,9994,9995,7,3,0,0,9995,9996,7,8,0,0,9996,9997,7,7,0,0,9997,9998,
	7,3,0,0,9998,1636,1,0,0,0,9999,10000,7,3,0,0,10000,10001,7,11,0,0,10001,
	10002,7,16,0,0,10002,10003,7,15,0,0,10003,10004,7,12,0,0,10004,10005,7,
	3,0,0,10005,10006,7,8,0,0,10006,10007,7,10,0,0,10007,1638,1,0,0,0,10008,
	10009,7,3,0,0,10009,10010,7,11,0,0,10010,10011,7,15,0,0,10011,10012,7,12,
	0,0,10012,1640,1,0,0,0,10013,10014,7,3,0,0,10014,10015,7,11,0,0,10015,10016,
	7,6,0,0,10016,10017,7,7,0,0,10017,10018,7,26,0,0,10018,10019,7,6,0,0,10019,
	1642,1,0,0,0,10020,10021,7,3,0,0,10021,10022,7,11,0,0,10022,10023,7,9,0,
	0,10023,10024,7,21,0,0,10024,10025,7,16,0,0,10025,1644,1,0,0,0,10026,10027,
	7,3,0,0,10027,10028,7,11,0,0,10028,10029,7,9,0,0,10029,10030,7,21,0,0,10030,
	10031,7,6,0,0,10031,1646,1,0,0,0,10032,10033,7,3,0,0,10033,10034,7,11,0,
	0,10034,10035,7,10,0,0,10035,10036,7,23,0,0,10036,10037,7,23,0,0,10037,
	10038,7,7,0,0,10038,10039,7,6,0,0,10039,10040,7,8,0,0,10040,10041,7,15,
	0,0,10041,10042,7,14,0,0,10042,10043,5,95,0,0,10043,10044,7,4,0,0,10044,
	10045,7,7,0,0,10045,10046,7,14,0,0,10046,10047,7,8,0,0,10047,10048,7,10,
	0,0,10048,10049,7,25,0,0,10049,10050,7,6,0,0,10050,1648,1,0,0,0,10051,10052,
	7,3,0,0,10052,10053,7,11,0,0,10053,10054,7,10,0,0,10054,10055,7,23,0,0,
	10055,10056,7,23,0,0,10056,10057,7,7,0,0,10057,10058,7,6,0,0,10058,10059,
	7,8,0,0,10059,10060,7,15,0,0,10060,10061,7,14,0,0,10061,10062,5,95,0,0,
	10062,10063,7,4,0,0,10063,10064,7,7,0,0,10064,10065,7,8,0,0,10065,10066,
	7,15,0,0,10066,10067,7,24,0,0,10067,10068,7,7,0,0,10068,1650,1,0,0,0,10069,
	10070,7,3,0,0,10070,10071,7,11,0,0,10071,10072,7,10,0,0,10072,10073,7,23,
	0,0,10073,10074,7,23,0,0,10074,10075,7,7,0,0,10075,10076,7,6,0,0,10076,
	10077,7,8,0,0,10077,10078,7,15,0,0,10078,10079,7,14,0,0,10079,10080,5,95,
	0,0,10080,10081,7,7,0,0,10081,10082,7,12,0,0,10082,10083,7,14,0,0,10083,
	10084,7,8,0,0,10084,10085,7,10,0,0,10085,10086,7,25,0,0,10086,10087,7,6,
	0,0,10087,1652,1,0,0,0,10088,10089,7,3,0,0,10089,10090,7,11,0,0,10090,10091,
	7,10,0,0,10091,10092,7,23,0,0,10092,10093,7,23,0,0,10093,10094,7,7,0,0,
	10094,10095,7,6,0,0,10095,10096,7,8,0,0,10096,10097,7,15,0,0,10097,10098,
	7,14,0,0,10098,10099,5,95,0,0,10099,10100,7,11,0,0,10100,10101,7,15,0,0,
	10101,10102,7,22,0,0,10102,10103,7,12,0,0,10103,1654,1,0,0,0,10104,10105,
	7,3,0,0,10105,10106,7,11,0,0,10106,10107,7,10,0,0,10107,10108,7,23,0,0,
	10108,10109,7,23,0,0,10109,10110,7,7,0,0,10110,10111,7,6,0,0,10111,10112,
	7,8,0,0,10112,10113,7,15,0,0,10113,10114,7,14,0,0,10114,10115,5,95,0,0,
	10115,10116,7,24,0,0,10116,10117,7,7,0,0,10117,10118,7,8,0,0,10118,10119,
	7,15,0,0,10119,10120,7,18,0,0,10120,10121,7,10,0,0,10121,1656,1,0,0,0,10122,
	10123,7,3,0,0,10123,10124,7,6,0,0,10124,10125,7,3,0,0,10125,10126,7,12,
	0,0,10126,1658,1,0,0,0,10127,10128,7,3,0,0,10128,10129,7,6,0,0,10129,10130,
	7,3,0,0,10130,10131,7,12,0,0,10131,10132,5,50,0,0,10132,1660,1,0,0,0,10133,
	10134,7,16,0,0,10134,10135,7,7,0,0,10135,10136,7,12,0,0,10136,10137,7,14,
	0,0,10137,10138,7,20,0,0,10138,10139,7,23,0,0,10139,10140,7,3,0,0,10140,
	10141,7,8,0,0,10141,10142,7,21,0,0,10142,1662,1,0,0,0,10143,10144,7,16,
	0,0,10144,10145,7,15,0,0,10145,10146,7,12,0,0,10146,1664,1,0,0,0,10147,
	10148,7,16,0,0,10148,10149,7,15,0,0,10149,10150,7,6,0,0,10150,10151,5,95,
	0,0,10151,10152,7,14,0,0,10152,10153,7,19,0,0,10153,10154,7,17,0,0,10154,
	10155,7,12,0,0,10155,10156,7,6,0,0,10156,1666,1,0,0,0,10157,10158,7,16,
	0,0,10158,10159,7,15,0,0,10159,10160,7,6,0,0,10160,10161,5,95,0,0,10161,
	10162,7,5,0,0,10162,10163,7,7,0,0,10163,10164,7,12,0,0,10164,10165,7,22,
	0,0,10165,10166,7,6,0,0,10166,10167,7,20,0,0,10167,1668,1,0,0,0,10168,10169,
	7,16,0,0,10169,10170,7,17,0,0,10170,10171,7,18,0,0,10171,10172,7,18,0,0,
	10172,10173,7,7,0,0,10173,10174,7,8,0,0,10174,1670,1,0,0,0,10175,10176,
	7,14,0,0,10176,10177,7,3,0,0,10177,10178,7,6,0,0,10178,10179,7,3,0,0,10179,
	10180,7,5,0,0,10180,10181,7,19,0,0,10181,10182,7,22,0,0,10182,10183,5,95,
	0,0,10183,10184,7,12,0,0,10184,10185,7,3,0,0,10185,10186,7,23,0,0,10186,
	10187,7,7,0,0,10187,1672,1,0,0,0,10188,10189,7,14,0,0,10189,10190,7,7,0,
	0,10190,10191,7,15,0,0,10191,10192,7,5,0,0,10192,1674,1,0,0,0,10193,10194,
	7,14,0,0,10194,10195,7,7,0,0,10195,10196,7,15,0,0,10196,10197,7,5,0,0,10197,
	10198,7,15,0,0,10198,10199,7,12,0,0,10199,10200,7,22,0,0,10200,1676,1,0,
	0,0,10201,10202,7,14,0,0,10202,10203,7,7,0,0,10203,10204,7,12,0,0,10204,
	10205,7,6,0,0,10205,10206,7,8,0,0,10206,10207,7,19,0,0,10207,10208,7,15,
	0,0,10208,10209,7,4,0,0,10209,1678,1,0,0,0,10210,10211,7,14,0,0,10211,10212,
	7,20,0,0,10212,10213,7,3,0,0,10213,10214,7,8,0,0,10214,10215,7,3,0,0,10215,
	10216,7,14,0,0,10216,10217,7,6,0,0,10217,10218,7,7,0,0,10218,10219,7,8,
	0,0,10219,10220,5,95,0,0,10220,10221,7,5,0,0,10221,10222,7,7,0,0,10222,
	10223,7,12,0,0,10223,10224,7,22,0,0,10224,10225,7,6,0,0,10225,10226,7,20,
	0,0,10226,1680,1,0,0,0,10227,10228,7,14,0,0,10228,10229,7,20,0,0,10229,
	10230,7,3,0,0,10230,10231,7,8,0,0,10231,10232,7,11,0,0,10232,10233,7,7,
	0,0,10233,10234,7,6,0,0,10234,1682,1,0,0,0,10235,10236,7,14,0,0,10236,10237,
	7,20,0,0,10237,10238,7,3,0,0,10238,10239,7,8,0,0,10239,10240,5,95,0,0,10240,
	10241,7,5,0,0,10241,10242,7,7,0,0,10242,10243,7,12,0,0,10243,10244,7,22,
	0,0,10244,10245,7,6,0,0,10245,10246,7,20,0,0,10246,1684,1,0,0,0,10247,10248,
	7,14,0,0,10248,10249,7,19,0,0,10249,10250,7,7,0,0,10250,10251,7,8,0,0,10251,
	10252,7,14,0,0,10252,10253,7,15,0,0,10253,10254,7,16,0,0,10254,10255,7,
	15,0,0,10255,10256,7,5,0,0,10256,10257,7,15,0,0,10257,10258,7,6,0,0,10258,
	10259,7,10,0,0,10259,1686,1,0,0,0,10260,10261,7,14,0,0,10261,10262,7,19,
	0,0,10262,10263,7,5,0,0,10263,10264,7,5,0,0,10264,10265,7,3,0,0,10265,10266,
	7,6,0,0,10266,10267,7,15,0,0,10267,10268,7,19,0,0,10268,10269,7,12,0,0,
	10269,1688,1,0,0,0,10270,10271,7,14,0,0,10271,10272,7,19,0,0,10272,10273,
	7,23,0,0,10273,10274,7,25,0,0,10274,10275,7,8,0,0,10275,10276,7,7,0,0,10276,
	10277,7,11,0,0,10277,10278,7,11,0,0,10278,1690,1,0,0,0,10279,10280,7,14,
	0,0,10280,10281,7,19,0,0,10281,10282,7,12,0,0,10282,10283,7,14,0,0,10283,
	10284,7,3,0,0,10284,10285,7,6,0,0,10285,1692,1,0,0,0,10286,10287,7,14,0,
	0,10287,10288,7,19,0,0,10288,10289,7,12,0,0,10289,10290,7,14,0,0,10290,
	10291,7,3,0,0,10291,10292,7,6,0,0,10292,10293,5,95,0,0,10293,10294,7,9,
	0,0,10294,10295,7,11,0,0,10295,1694,1,0,0,0,10296,10297,7,14,0,0,10297,
	10298,7,19,0,0,10298,10299,7,12,0,0,10299,10300,7,12,0,0,10300,10301,7,
	7,0,0,10301,10302,7,14,0,0,10302,10303,7,6,0,0,10303,10304,7,15,0,0,10304,
	10305,7,19,0,0,10305,10306,7,12,0,0,10306,10307,5,95,0,0,10307,10308,7,
	15,0,0,10308,10309,7,4,0,0,10309,1696,1,0,0,0,10310,10311,7,14,0,0,10311,
	10312,7,19,0,0,10312,10313,7,12,0,0,10313,10314,7,24,0,0,10314,1698,1,0,
	0,0,10315,10316,7,14,0,0,10316,10317,7,19,0,0,10317,10318,7,12,0,0,10318,
	10319,7,24,0,0,10319,10320,7,7,0,0,10320,10321,7,8,0,0,10321,10322,7,6,
	0,0,10322,10323,5,95,0,0,10323,10324,7,6,0,0,10324,10325,7,13,0,0,10325,
	1700,1,0,0,0,10326,10327,7,14,0,0,10327,10328,7,19,0,0,10328,10329,7,11,
	0,0,10329,1702,1,0,0,0,10330,10331,7,14,0,0,10331,10332,7,19,0,0,10332,
	10333,7,6,0,0,10333,1704,1,0,0,0,10334,10335,7,14,0,0,10335,10336,7,8,0,
	0,10336,10337,7,14,0,0,10337,10338,5,51,0,0,10338,10339,5,50,0,0,10339,
	1706,1,0,0,0,10340,10341,7,14,0,0,10341,10342,7,8,0,0,10342,10343,7,7,0,
	0,10343,10344,7,3,0,0,10344,10345,7,6,0,0,10345,10346,7,7,0,0,10346,10347,
	5,95,0,0,10347,10348,7,3,0,0,10348,10349,7,11,0,0,10349,10350,7,10,0,0,
	10350,10351,7,23,0,0,10351,10352,7,23,0,0,10352,10353,7,7,0,0,10353,10354,
	7,6,0,0,10354,10355,7,8,0,0,10355,10356,7,15,0,0,10356,10357,7,14,0,0,10357,
	10358,5,95,0,0,10358,10359,7,25,0,0,10359,10360,7,8,0,0,10360,10361,7,15,
	0,0,10361,10362,7,24,0,0,10362,10363,5,95,0,0,10363,10364,7,21,0,0,10364,
	10365,7,7,0,0,10365,10366,7,10,0,0,10366,1708,1,0,0,0,10367,10368,7,14,
	0,0,10368,10369,7,8,0,0,10369,10370,7,7,0,0,10370,10371,7,3,0,0,10371,10372,
	7,6,0,0,10372,10373,7,7,0,0,10373,10374,5,95,0,0,10374,10375,7,3,0,0,10375,
	10376,7,11,0,0,10376,10377,7,10,0,0,10377,10378,7,23,0,0,10378,10379,7,
	23,0,0,10379,10380,7,7,0,0,10380,10381,7,6,0,0,10381,10382,7,8,0,0,10382,
	10383,7,15,0,0,10383,10384,7,14,0,0,10384,10385,5,95,0,0,10385,10386,7,
	25,0,0,10386,10387,7,17,0,0,10387,10388,7,16,0,0,10388,10389,5,95,0,0,10389,
	10390,7,21,0,0,10390,10391,7,7,0,0,10391,10392,7,10,0,0,10392,1710,1,0,
	0,0,10393,10394,7,14,0,0,10394,10395,7,8,0,0,10395,10396,7,7,0,0,10396,
	10397,7,3,0,0,10397,10398,7,6,0,0,10398,10399,7,7,0,0,10399,10400,5,95,
	0,0,10400,10401,7,4,0,0,10401,10402,7,20,0,0,10402,10403,5,95,0,0,10403,
	10404,7,25,0,0,10404,10405,7,3,0,0,10405,10406,7,8,0,0,10406,10407,7,3,
	0,0,10407,10408,7,23,0,0,10408,10409,7,7,0,0,10409,10410,7,6,0,0,10410,
	10411,7,7,0,0,10411,10412,7,8,0,0,10412,10413,7,11,0,0,10413,1712,1,0,0,
	0,10414,10415,7,14,0,0,10415,10416,7,8,0,0,10416,10417,7,7,0,0,10417,10418,
	7,3,0,0,10418,10419,7,6,0,0,10419,10420,7,7,0,0,10420,10421,5,95,0,0,10421,
	10422,7,4,0,0,10422,10423,7,15,0,0,10423,10424,7,22,0,0,10424,10425,7,7,
	0,0,10425,10426,7,11,0,0,10426,10427,7,6,0,0,10427,1714,1,0,0,0,10428,10429,
	7,14,0,0,10429,10430,7,8,0,0,10430,10431,7,19,0,0,10431,10432,7,11,0,0,
	10432,10433,7,11,0,0,10433,10434,7,7,0,0,10434,10435,7,11,0,0,10435,1716,
	1,0,0,0,10436,10437,7,4,0,0,10437,10438,7,3,0,0,10438,10439,7,6,0,0,10439,
	10440,7,7,0,0,10440,10441,7,4,0,0,10441,10442,7,15,0,0,10442,10443,7,18,
	0,0,10443,10444,7,18,0,0,10444,1718,1,0,0,0,10445,10446,7,4,0,0,10446,10447,
	7,3,0,0,10447,10448,7,6,0,0,10448,10449,7,7,0,0,10449,10450,5,95,0,0,10450,
	10451,7,18,0,0,10451,10452,7,19,0,0,10452,10453,7,8,0,0,10453,10454,7,23,
	0,0,10454,10455,7,3,0,0,10455,10456,7,6,0,0,10456,1720,1,0,0,0,10457,10458,
	7,4,0,0,10458,10459,7,3,0,0,10459,10460,7,10,0,0,10460,10461,7,12,0,0,10461,
	10462,7,3,0,0,10462,10463,7,23,0,0,10463,10464,7,7,0,0,10464,1722,1,0,0,
	0,10465,10466,7,4,0,0,10466,10467,7,3,0,0,10467,10468,7,10,0,0,10468,10469,
	7,19,0,0,10469,10470,7,18,0,0,10470,10471,7,23,0,0,10471,10472,7,19,0,0,
	10472,10473,7,12,0,0,10473,10474,7,6,0,0,10474,10475,7,20,0,0,10475,1724,
	1,0,0,0,10476,10477,7,4,0,0,10477,10478,7,3,0,0,10478,10479,7,10,0,0,10479,
	10480,7,19,0,0,10480,10481,7,18,0,0,10481,10482,7,9,0,0,10482,10483,7,7,
	0,0,10483,10484,7,7,0,0,10484,10485,7,21,0,0,10485,1726,1,0,0,0,10486,10487,
	7,4,0,0,10487,10488,7,3,0,0,10488,10489,7,10,0,0,10489,10490,7,19,0,0,10490,
	10491,7,18,0,0,10491,10492,7,10,0,0,10492,10493,7,7,0,0,10493,10494,7,3,
	0,0,10494,10495,7,8,0,0,10495,1728,1,0,0,0,10496,10497,7,4,0,0,10497,10498,
	7,7,0,0,10498,10499,7,14,0,0,10499,10500,7,19,0,0,10500,10501,7,4,0,0,10501,
	10502,7,7,0,0,10502,1730,1,0,0,0,10503,10504,7,4,0,0,10504,10505,7,7,0,
	0,10505,10506,7,22,0,0,10506,10507,7,8,0,0,10507,10508,7,7,0,0,10508,10509,
	7,7,0,0,10509,10510,7,11,0,0,10510,1732,1,0,0,0,10511,10512,7,4,0,0,10512,
	10513,7,7,0,0,10513,10514,7,11,0,0,10514,10515,5,95,0,0,10515,10516,7,4,
	0,0,10516,10517,7,7,0,0,10517,10518,7,14,0,0,10518,10519,7,8,0,0,10519,
	10520,7,10,0,0,10520,10521,7,25,0,0,10521,10522,7,6,0,0,10522,1734,1,0,
	0,0,10523,10524,7,4,0,0,10524,10525,7,7,0,0,10525,10526,7,11,0,0,10526,
	10527,5,95,0,0,10527,10528,7,7,0,0,10528,10529,7,12,0,0,10529,10530,7,14,
	0,0,10530,10531,7,8,0,0,10531,10532,7,10,0,0,10532,10533,7,25,0,0,10533,
	10534,7,6,0,0,10534,1736,1,0,0,0,10535,10536,7,4,0,0,10536,10537,7,15,0,
	0,10537,10538,7,23,0,0,10538,10539,7,7,0,0,10539,10540,7,12,0,0,10540,10541,
	7,11,0,0,10541,10542,7,15,0,0,10542,10543,7,19,0,0,10543,10544,7,12,0,0,
	10544,1738,1,0,0,0,10545,10546,7,4,0,0,10546,10547,7,15,0,0,10547,10548,
	7,11,0,0,10548,10549,7,27,0,0,10549,10550,7,19,0,0,10550,10551,7,15,0,0,
	10551,10552,7,12,0,0,10552,10553,7,6,0,0,10553,1740,1,0,0,0,10554,10555,
	7,7,0,0,10555,10556,7,5,0,0,10556,10557,7,6,0,0,10557,1742,1,0,0,0,10558,
	10559,7,7,0,0,10559,10560,7,12,0,0,10560,10561,7,14,0,0,10561,10562,7,19,
	0,0,10562,10563,7,4,0,0,10563,10564,7,7,0,0,10564,1744,1,0,0,0,10565,10566,
	7,7,0,0,10566,10567,7,12,0,0,10567,10568,7,14,0,0,10568,10569,7,8,0,0,10569,
	10570,7,10,0,0,10570,10571,7,25,0,0,10571,10572,7,6,0,0,10572,1746,1,0,
	0,0,10573,10574,7,7,0,0,10574,10575,7,12,0,0,10575,10576,7,4,0,0,10576,
	10577,7,25,0,0,10577,10578,7,19,0,0,10578,10579,7,15,0,0,10579,10580,7,
	12,0,0,10580,10581,7,6,0,0,10581,1748,1,0,0,0,10582,10583,7,7,0,0,10583,
	10584,7,12,0,0,10584,10585,7,22,0,0,10585,10586,7,15,0,0,10586,10587,7,
	12,0,0,10587,10588,7,7,0,0,10588,10589,5,95,0,0,10589,10590,7,3,0,0,10590,
	10591,7,6,0,0,10591,10592,7,6,0,0,10592,10593,7,8,0,0,10593,10594,7,15,
	0,0,10594,10595,7,16,0,0,10595,10596,7,17,0,0,10596,10597,7,6,0,0,10597,
	10598,7,7,0,0,10598,1750,1,0,0,0,10599,10600,7,7,0,0,10600,10601,7,12,0,
	0,10601,10602,7,24,0,0,10602,10603,7,7,0,0,10603,10604,7,5,0,0,10604,10605,
	7,19,0,0,10605,10606,7,25,0,0,10606,10607,7,7,0,0,10607,1752,1,0,0,0,10608,
	10609,7,7,0,0,10609,10610,7,28,0,0,10610,10611,7,17,0,0,10611,10612,7,3,
	0,0,10612,10613,7,5,0,0,10613,10614,7,11,0,0,10614,1754,1,0,0,0,10615,10616,
	7,7,0,0,10616,10617,7,26,0,0,10617,10618,7,25,0,0,10618,1756,1,0,0,0,10619,
	10620,7,7,0,0,10620,10621,7,26,0,0,10621,10622,7,25,0,0,10622,10623,7,19,
	0,0,10623,10624,7,8,0,0,10624,10625,7,6,0,0,10625,10626,5,95,0,0,10626,
	10627,7,11,0,0,10627,10628,7,7,0,0,10628,10629,7,6,0,0,10629,1758,1,0,0,
	0,10630,10631,7,7,0,0,10631,10632,7,26,0,0,10632,10633,7,6,0,0,10633,10634,
	7,7,0,0,10634,10635,7,8,0,0,10635,10636,7,15,0,0,10636,10637,7,19,0,0,10637,
	10638,7,8,0,0,10638,10639,7,8,0,0,10639,10640,7,15,0,0,10640,10641,7,12,
	0,0,10641,10642,7,22,0,0,10642,1760,1,0,0,0,10643,10644,7,7,0,0,10644,10645,
	7,26,0,0,10645,10646,7,6,0,0,10646,10647,7,8,0,0,10647,10648,7,3,0,0,10648,
	10649,7,14,0,0,10649,10650,7,6,0,0,10650,10651,7,24,0,0,10651,10652,7,3,
	0,0,10652,10653,7,5,0,0,10653,10654,7,17,0,0,10654,10655,7,7,0,0,10655,
	1762,1,0,0,0,10656,10657,7,18,0,0,10657,10658,7,15,0,0,10658,10659,7,7,
	0,0,10659,10660,7,5,0,0,10660,10661,7,4,0,0,10661,1764,1,0,0,0,10662,10663,
	7,18,0,0,10663,10664,7,15,0,0,10664,10665,7,12,0,0,10665,10666,7,4,0,0,
	10666,10667,5,95,0,0,10667,10668,7,15,0,0,10668,10669,7,12,0,0,10669,10670,
	5,95,0,0,10670,10671,7,11,0,0,10671,10672,7,7,0,0,10672,10673,7,6,0,0,10673,
	1766,1,0,0,0,10674,10675,7,18,0,0,10675,10676,7,5,0,0,10676,10677,7,19,
	0,0,10677,10678,7,19,0,0,10678,10679,7,8,0,0,10679,1768,1,0,0,0,10680,10681,
	7,18,0,0,10681,10682,7,19,0,0,10682,10683,7,8,0,0,10683,10684,7,23,0,0,
	10684,10685,7,3,0,0,10685,10686,7,6,0,0,10686,1770,1,0,0,0,10687,10688,
	7,18,0,0,10688,10689,7,19,0,0,10689,10690,7,17,0,0,10690,10691,7,12,0,0,
	10691,10692,7,4,0,0,10692,10693,5,95,0,0,10693,10694,7,8,0,0,10694,10695,
	7,19,0,0,10695,10696,7,9,0,0,10696,10697,7,11,0,0,10697,1772,1,0,0,0,10698,
	10699,7,18,0,0,10699,10700,7,8,0,0,10700,10701,7,19,0,0,10701,10702,7,23,
	0,0,10702,10703,5,95,0,0,10703,10704,7,16,0,0,10704,10705,7,3,0,0,10705,
	10706,7,11,0,0,10706,10707,7,7,0,0,10707,10708,5,54,0,0,10708,10709,5,52,
	0,0,10709,1774,1,0,0,0,10710,10711,7,18,0,0,10711,10712,7,8,0,0,10712,10713,
	7,19,0,0,10713,10714,7,23,0,0,10714,10715,5,95,0,0,10715,10716,7,4,0,0,
	10716,10717,7,3,0,0,10717,10718,7,10,0,0,10718,10719,7,11,0,0,10719,1776,
	1,0,0,0,10720,10721,7,18,0,0,10721,10722,7,8,0,0,10722,10723,7,19,0,0,10723,
	10724,7,23,0,0,10724,10725,5,95,0,0,10725,10726,7,17,0,0,10726,10727,7,
	12,0,0,10727,10728,7,15,0,0,10728,10729,7,26,0,0,10729,10730,7,6,0,0,10730,
	10731,7,15,0,0,10731,10732,7,23,0,0,10732,10733,7,7,0,0,10733,1778,1,0,
	0,0,10734,10735,7,22,0,0,10735,10736,7,7,0,0,10736,10737,7,19,0,0,10737,
	10738,7,23,0,0,10738,10739,7,14,0,0,10739,10740,7,19,0,0,10740,10741,7,
	5,0,0,10741,10742,7,5,0,0,10742,10743,7,18,0,0,10743,10744,7,8,0,0,10744,
	10745,7,19,0,0,10745,10746,7,23,0,0,10746,10747,7,6,0,0,10747,10748,7,7,
	0,0,10748,10749,7,26,0,0,10749,10750,7,6,0,0,10750,1780,1,0,0,0,10751,10752,
	7,22,0,0,10752,10753,7,7,0,0,10753,10754,7,19,0,0,10754,10755,7,23,0,0,
	10755,10756,7,14,0,0,10756,10757,7,19,0,0,10757,10758,7,5,0,0,10758,10759,
	7,5,0,0,10759,10760,7,18,0,0,10760,10761,7,8,0,0,10761,10762,7,19,0,0,10762,
	10763,7,23,0,0,10763,10764,7,9,0,0,10764,10765,7,21,0,0,10765,10766,7,16,
	0,0,10766,1782,1,0,0,0,10767,10768,7,22,0,0,10768,10769,7,7,0,0,10769,10770,
	7,19,0,0,10770,10771,7,23,0,0,10771,10772,7,7,0,0,10772,10773,7,6,0,0,10773,
	10774,7,8,0,0,10774,10775,7,10,0,0,10775,10776,7,14,0,0,10776,10777,7,19,
	0,0,10777,10778,7,5,0,0,10778,10779,7,5,0,0,10779,10780,7,7,0,0,10780,10781,
	7,14,0,0,10781,10782,7,6,0,0,10782,10783,7,15,0,0,10783,10784,7,19,0,0,
	10784,10785,7,12,0,0,10785,10786,7,18,0,0,10786,10787,7,8,0,0,10787,10788,
	7,19,0,0,10788,10789,7,23,0,0,10789,10790,7,6,0,0,10790,10791,7,7,0,0,10791,
	10792,7,26,0,0,10792,10793,7,6,0,0,10793,1784,1,0,0,0,10794,10795,7,22,
	0,0,10795,10796,7,7,0,0,10796,10797,7,19,0,0,10797,10798,7,23,0,0,10798,
	10799,7,7,0,0,10799,10800,7,6,0,0,10800,10801,7,8,0,0,10801,10802,7,10,
	0,0,10802,10803,7,14,0,0,10803,10804,7,19,0,0,10804,10805,7,5,0,0,10805,
	10806,7,5,0,0,10806,10807,7,7,0,0,10807,10808,7,14,0,0,10808,10809,7,6,
	0,0,10809,10810,7,15,0,0,10810,10811,7,19,0,0,10811,10812,7,12,0,0,10812,
	10813,7,18,0,0,10813,10814,7,8,0,0,10814,10815,7,19,0,0,10815,10816,7,23,
	0,0,10816,10817,7,9,0,0,10817,10818,7,21,0,0,10818,10819,7,16,0,0,10819,
	1786,1,0,0,0,10820,10821,7,22,0,0,10821,10822,7,7,0,0,10822,10823,7,19,
	0,0,10823,10824,7,23,0,0,10824,10825,7,7,0,0,10825,10826,7,6,0,0,10826,
	10827,7,8,0,0,10827,10828,7,10,0,0,10828,10829,7,18,0,0,10829,10830,7,8,
	0,0,10830,10831,7,19,0,0,10831,10832,7,23,0,0,10832,10833,7,6,0,0,10833,
	10834,7,7,0,0,10834,10835,7,26,0,0,10835,10836,7,6,0,0,10836,1788,1,0,0,
	0,10837,10838,7,22,0,0,10838,10839,7,7,0,0,10839,10840,7,19,0,0,10840,10841,
	7,23,0,0,10841,10842,7,7,0,0,10842,10843,7,6,0,0,10843,10844,7,8,0,0,10844,
	10845,7,10,0,0,10845,10846,7,18,0,0,10846,10847,7,8,0,0,10847,10848,7,19,
	0,0,10848,10849,7,23,0,0,10849,10850,7,9,0,0,10850,10851,7,21,0,0,10851,
	10852,7,16,0,0,10852,1790,1,0,0,0,10853,10854,7,22,0,0,10854,10855,7,7,
	0,0,10855,10856,7,19,0,0,10856,10857,7,23,0,0,10857,10858,7,7,0,0,10858,
	10859,7,6,0,0,10859,10860,7,8,0,0,10860,10861,7,10,0,0,10861,10862,7,12,
	0,0,10862,1792,1,0,0,0,10863,10864,7,22,0,0,10864,10865,7,7,0,0,10865,10866,
	7,19,0,0,10866,10867,7,23,0,0,10867,10868,7,7,0,0,10868,10869,7,6,0,0,10869,
	10870,7,8,0,0,10870,10871,7,10,0,0,10871,10872,7,6,0,0,10872,10873,7,10,
	0,0,10873,10874,7,25,0,0,10874,10875,7,7,0,0,10875,1794,1,0,0,0,10876,10877,
	7,22,0,0,10877,10878,7,7,0,0,10878,10879,7,19,0,0,10879,10880,7,23,0,0,
	10880,10881,7,18,0,0,10881,10882,7,8,0,0,10882,10883,7,19,0,0,10883,10884,
	7,23,0,0,10884,10885,7,6,0,0,10885,10886,7,7,0,0,10886,10887,7,26,0,0,10887,
	10888,7,6,0,0,10888,1796,1,0,0,0,10889,10890,7,22,0,0,10890,10891,7,7,0,
	0,10891,10892,7,19,0,0,10892,10893,7,23,0,0,10893,10894,7,18,0,0,10894,
	10895,7,8,0,0,10895,10896,7,19,0,0,10896,10897,7,23,0,0,10897,10898,7,9,
	0,0,10898,10899,7,21,0,0,10899,10900,7,16,0,0,10900,1798,1,0,0,0,10901,
	10902,7,22,0,0,10902,10903,7,7,0,0,10903,10904,7,6,0,0,10904,10905,5,95,
	0,0,10905,10906,7,18,0,0,10906,10907,7,19,0,0,10907,10908,7,8,0,0,10908,
	10909,7,23,0,0,10909,10910,7,3,0,0,10910,10911,7,6,0,0,10911,1800,1,0,0,
	0,10912,10913,7,22,0,0,10913,10914,7,7,0,0,10914,10915,7,6,0,0,10915,10916,
	5,95,0,0,10916,10917,7,5,0,0,10917,10918,7,19,0,0,10918,10919,7,14,0,0,
	10919,10920,7,21,0,0,10920,1802,1,0,0,0,10921,10922,7,22,0,0,10922,10923,
	7,5,0,0,10923,10924,7,7,0,0,10924,10925,7,12,0,0,10925,10926,7,22,0,0,10926,
	10927,7,6,0,0,10927,10928,7,20,0,0,10928,1804,1,0,0,0,10929,10930,7,22,
	0,0,10930,10931,7,8,0,0,10931,10932,7,7,0,0,10932,10933,7,3,0,0,10933,10934,
	7,6,0,0,10934,10935,7,7,0,0,10935,10936,7,11,0,0,10936,10937,7,6,0,0,10937,
	1806,1,0,0,0,10938,10939,7,22,0,0,10939,10940,7,6,0,0,10940,10941,7,15,
	0,0,10941,10942,7,4,0,0,10942,10943,5,95,0,0,10943,10944,7,11,0,0,10944,
	10945,7,17,0,0,10945,10946,7,16,0,0,10946,10947,7,11,0,0,10947,10948,7,
	7,0,0,10948,10949,7,6,0,0,10949,1808,1,0,0,0,10950,10951,7,22,0,0,10951,
	10952,7,6,0,0,10952,10953,7,15,0,0,10953,10954,7,4,0,0,10954,10955,5,95,
	0,0,10955,10956,7,11,0,0,10956,10957,7,17,0,0,10957,10958,7,16,0,0,10958,
	10959,7,6,0,0,10959,10960,7,8,0,0,10960,10961,7,3,0,0,10961,10962,7,14,
	0,0,10962,10963,7,6,0,0,10963,1810,1,0,0,0,10964,10965,7,20,0,0,10965,10966,
	7,7,0,0,10966,10967,7,26,0,0,10967,1812,1,0,0,0,10968,10969,7,15,0,0,10969,
	10970,7,18,0,0,10970,10971,7,12,0,0,10971,10972,7,17,0,0,10972,10973,7,
	5,0,0,10973,10974,7,5,0,0,10974,1814,1,0,0,0,10975,10976,7,15,0,0,10976,
	10977,7,12,0,0,10977,10978,7,7,0,0,10978,10979,7,6,0,0,10979,10980,5,54,
	0,0,10980,10981,5,95,0,0,10981,10982,7,3,0,0,10982,10983,7,6,0,0,10983,
	10984,7,19,0,0,10984,10985,7,12,0,0,10985,1816,1,0,0,0,10986,10987,7,15,
	0,0,10987,10988,7,12,0,0,10988,10989,7,7,0,0,10989,10990,7,6,0,0,10990,
	10991,5,54,0,0,10991,10992,5,95,0,0,10992,10993,7,12,0,0,10993,10994,7,
	6,0,0,10994,10995,7,19,0,0,10995,10996,7,3,0,0,10996,1818,1,0,0,0,10997,
	10998,7,15,0,0,10998,10999,7,12,0,0,10999,11000,7,7,0,0,11000,11001,7,6,
	0,0,11001,11002,5,95,0,0,11002,11003,7,3,0,0,11003,11004,7,6,0,0,11004,
	11005,7,19,0,0,11005,11006,7,12,0,0,11006,1820,1,0,0,0,11007,11008,7,15,
	0,0,11008,11009,7,12,0,0,11009,11010,7,7,0,0,11010,11011,7,6,0,0,11011,
	11012,5,95,0,0,11012,11013,7,12,0,0,11013,11014,7,6,0,0,11014,11015,7,19,
	0,0,11015,11016,7,3,0,0,11016,1822,1,0,0,0,11017,11018,7,15,0,0,11018,11019,
	7,12,0,0,11019,11020,7,11,0,0,11020,11021,7,6,0,0,11021,11022,7,8,0,0,11022,
	1824,1,0,0,0,11023,11024,7,15,0,0,11024,11025,7,12,0,0,11025,11026,7,6,
	0,0,11026,11027,7,7,0,0,11027,11028,7,8,0,0,11028,11029,7,15,0,0,11029,
	11030,7,19,0,0,11030,11031,7,8,0,0,11031,11032,7,8,0,0,11032,11033,7,15,
	0,0,11033,11034,7,12,0,0,11034,11035,7,22,0,0,11035,11036,7,12,0,0,11036,
	1826,1,0,0,0,11037,11038,7,15,0,0,11038,11039,7,12,0,0,11039,11040,7,6,
	0,0,11040,11041,7,7,0,0,11041,11042,7,8,0,0,11042,11043,7,11,0,0,11043,
	11044,7,7,0,0,11044,11045,7,14,0,0,11045,11046,7,6,0,0,11046,11047,7,11,
	0,0,11047,1828,1,0,0,0,11048,11049,7,15,0,0,11049,11050,7,11,0,0,11050,
	11051,7,14,0,0,11051,11052,7,5,0,0,11052,11053,7,19,0,0,11053,11054,7,11,
	0,0,11054,11055,7,7,0,0,11055,11056,7,4,0,0,11056,1830,1,0,0,0,11057,11058,
	7,15,0,0,11058,11059,7,11,0,0,11059,11060,7,7,0,0,11060,11061,7,23,0,0,
	11061,11062,7,25,0,0,11062,11063,7,6,0,0,11063,11064,7,10,0,0,11064,1832,
	1,0,0,0,11065,11066,7,15,0,0,11066,11067,7,11,0,0,11067,11068,7,12,0,0,
	11068,11069,7,17,0,0,11069,11070,7,5,0,0,11070,11071,7,5,0,0,11071,1834,
	1,0,0,0,11072,11073,7,15,0,0,11073,11074,7,11,0,0,11074,11075,7,11,0,0,
	11075,11076,7,15,0,0,11076,11077,7,23,0,0,11077,11078,7,25,0,0,11078,11079,
	7,5,0,0,11079,11080,7,7,0,0,11080,1836,1,0,0,0,11081,11082,7,15,0,0,11082,
	11083,7,11,0,0,11083,11084,5,95,0,0,11084,11085,7,18,0,0,11085,11086,7,
	8,0,0,11086,11087,7,7,0,0,11087,11088,7,7,0,0,11088,11089,5,95,0,0,11089,
	11090,7,5,0,0,11090,11091,7,19,0,0,11091,11092,7,14,0,0,11092,11093,7,21,
	0,0,11093,1838,1,0,0,0,11094,11095,7,15,0,0,11095,11096,7,11,0,0,11096,
	11097,5,95,0,0,11097,11098,7,15,0,0,11098,11099,7,25,0,0,11099,11100,7,
	24,0,0,11100,11101,5,52,0,0,11101,1840,1,0,0,0,11102,11103,7,15,0,0,11103,
	11104,7,11,0,0,11104,11105,5,95,0,0,11105,11106,7,15,0,0,11106,11107,7,
	25,0,0,11107,11108,7,24,0,0,11108,11109,5,52,0,0,11109,11110,5,95,0,0,11110,
	11111,7,14,0,0,11111,11112,7,19,0,0,11112,11113,7,23,0,0,11113,11114,7,
	25,0,0,11114,11115,7,3,0,0,11115,11116,7,6,0,0,11116,1842,1,0,0,0,11117,
	11118,7,15,0,0,11118,11119,7,11,0,0,11119,11120,5,95,0,0,11120,11121,7,
	15,0,0,11121,11122,7,25,0,0,11122,11123,7,24,0,0,11123,11124,5,52,0,0,11124,
	11125,5,95,0,0,11125,11126,7,23,0,0,11126,11127,7,3,0,0,11127,11128,7,25,
	0,0,11128,11129,7,25,0,0,11129,11130,7,7,0,0,11130,11131,7,4,0,0,11131,
	1844,1,0,0,0,11132,11133,7,15,0,0,11133,11134,7,11,0,0,11134,11135,5,95,
	0,0,11135,11136,7,15,0,0,11136,11137,7,25,0,0,11137,11138,7,24,0,0,11138,
	11139,5,54,0,0,11139,1846,1,0,0,0,11140,11141,7,15,0,0,11141,11142,7,11,
	0,0,11142,11143,5,95,0,0,11143,11144,7,17,0,0,11144,11145,7,11,0,0,11145,
	11146,7,7,0,0,11146,11147,7,4,0,0,11147,11148,5,95,0,0,11148,11149,7,5,
	0,0,11149,11150,7,19,0,0,11150,11151,7,14,0,0,11151,11152,7,21,0,0,11152,
	1848,1,0,0,0,11153,11154,7,5,0,0,11154,11155,7,3,0,0,11155,11156,7,11,0,
	0,11156,11157,7,6,0,0,11157,11158,5,95,0,0,11158,11159,7,15,0,0,11159,11160,
	7,12,0,0,11160,11161,7,11,0,0,11161,11162,7,7,0,0,11162,11163,7,8,0,0,11163,
	11164,7,6,0,0,11164,11165,5,95,0,0,11165,11166,7,15,0,0,11166,11167,7,4,
	0,0,11167,1850,1,0,0,0,11168,11169,7,5,0,0,11169,11170,7,14,0,0,11170,11171,
	7,3,0,0,11171,11172,7,11,0,0,11172,11173,7,7,0,0,11173,1852,1,0,0,0,11174,
	11175,7,5,0,0,11175,11176,7,7,0,0,11176,11177,7,3,0,0,11177,11178,7,11,
	0,0,11178,11179,7,6,0,0,11179,1854,1,0,0,0,11180,11181,7,5,0,0,11181,11182,
	7,7,0,0,11182,11183,7,12,0,0,11183,11184,7,22,0,0,11184,11185,7,6,0,0,11185,
	11186,7,20,0,0,11186,1856,1,0,0,0,11187,11188,7,5,0,0,11188,11189,7,15,
	0,0,11189,11190,7,12,0,0,11190,11191,7,7,0,0,11191,11192,7,18,0,0,11192,
	11193,7,8,0,0,11193,11194,7,19,0,0,11194,11195,7,23,0,0,11195,11196,7,6,
	0,0,11196,11197,7,7,0,0,11197,11198,7,26,0,0,11198,11199,7,6,0,0,11199,
	1858,1,0,0,0,11200,11201,7,5,0,0,11201,11202,7,15,0,0,11202,11203,7,12,
	0,0,11203,11204,7,7,0,0,11204,11205,7,18,0,0,11205,11206,7,8,0,0,11206,
	11207,7,19,0,0,11207,11208,7,23,0,0,11208,11209,7,9,0,0,11209,11210,7,21,
	0,0,11210,11211,7,16,0,0,11211,1860,1,0,0,0,11212,11213,7,5,0,0,11213,11214,
	7,15,0,0,11214,11215,7,12,0,0,11215,11216,7,7,0,0,11216,11217,7,11,0,0,
	11217,11218,7,6,0,0,11218,11219,7,8,0,0,11219,11220,7,15,0,0,11220,11221,
	7,12,0,0,11221,11222,7,22,0,0,11222,11223,7,18,0,0,11223,11224,7,8,0,0,
	11224,11225,7,19,0,0,11225,11226,7,23,0,0,11226,11227,7,6,0,0,11227,11228,
	7,7,0,0,11228,11229,7,26,0,0,11229,11230,7,6,0,0,11230,1862,1,0,0,0,11231,
	11232,7,5,0,0,11232,11233,7,15,0,0,11233,11234,7,12,0,0,11234,11235,7,7,
	0,0,11235,11236,7,11,0,0,11236,11237,7,6,0,0,11237,11238,7,8,0,0,11238,
	11239,7,15,0,0,11239,11240,7,12,0,0,11240,11241,7,22,0,0,11241,11242,7,
	18,0,0,11242,11243,7,8,0,0,11243,11244,7,19,0,0,11244,11245,7,23,0,0,11245,
	11246,7,9,0,0,11246,11247,7,21,0,0,11247,11248,7,16,0,0,11248,1864,1,0,
	0,0,11249,11250,7,5,0,0,11250,11251,7,12,0,0,11251,1866,1,0,0,0,11252,11253,
	7,5,0,0,11253,11254,7,19,0,0,11254,11255,7,3,0,0,11255,11256,7,4,0,0,11256,
	11257,5,95,0,0,11257,11258,7,18,0,0,11258,11259,7,15,0,0,11259,11260,7,
	5,0,0,11260,11261,7,7,0,0,11261,1868,1,0,0,0,11262,11263,7,5,0,0,11263,
	11264,7,19,0,0,11264,11265,7,14,0,0,11265,11266,7,3,0,0,11266,11267,7,6,
	0,0,11267,11268,7,7,0,0,11268,1870,1,0,0,0,11269,11270,7,5,0,0,11270,11271,
	7,19,0,0,11271,11272,7,22,0,0,11272,1872,1,0,0,0,11273,11274,7,5,0,0,11274,
	11275,7,19,0,0,11275,11276,7,22,0,0,11276,11277,5,49,0,0,11277,11278,5,
	48,0,0,11278,1874,1,0,0,0,11279,11280,7,5,0,0,11280,11281,7,19,0,0,11281,
	11282,7,22,0,0,11282,11283,5,50,0,0,11283,1876,1,0,0,0,11284,11285,7,5,
	0,0,11285,11286,7,19,0,0,11286,11287,7,9,0,0,11287,11288,7,7,0,0,11288,
	11289,7,8,0,0,11289,1878,1,0,0,0,11290,11291,7,5,0,0,11291,11292,7,25,0,
	0,11292,11293,7,3,0,0,11293,11294,7,4,0,0,11294,1880,1,0,0,0,11295,11296,
	7,5,0,0,11296,11297,7,6,0,0,11297,11298,7,8,0,0,11298,11299,7,15,0,0,11299,
	11300,7,23,0,0,11300,1882,1,0,0,0,11301,11302,7,23,0,0,11302,11303,7,3,
	0,0,11303,11304,7,21,0,0,11304,11305,7,7,0,0,11305,11306,7,4,0,0,11306,
	11307,7,3,0,0,11307,11308,7,6,0,0,11308,11309,7,7,0,0,11309,1884,1,0,0,
	0,11310,11311,7,23,0,0,11311,11312,7,3,0,0,11312,11313,7,21,0,0,11313,11314,
	7,7,0,0,11314,11315,7,6,0,0,11315,11316,7,15,0,0,11316,11317,7,23,0,0,11317,
	11318,7,7,0,0,11318,1886,1,0,0,0,11319,11320,7,23,0,0,11320,11321,7,3,0,
	0,11321,11322,7,21,0,0,11322,11323,7,7,0,0,11323,11324,5,95,0,0,11324,11325,
	7,11,0,0,11325,11326,7,7,0,0,11326,11327,7,6,0,0,11327,1888,1,0,0,0,11328,
	11329,7,23,0,0,11329,11330,7,3,0,0,11330,11331,7,11,0,0,11331,11332,7,6,
	0,0,11332,11333,7,7,0,0,11333,11334,7,8,0,0,11334,11335,5,95,0,0,11335,
	11336,7,25,0,0,11336,11337,7,19,0,0,11337,11338,7,11,0,0,11338,11339,5,
	95,0,0,11339,11340,7,9,0,0,11340,11341,7,3,0,0,11341,11342,7,15,0,0,11342,
	11343,7,6,0,0,11343,1890,1,0,0,0,11344,11345,7,23,0,0,11345,11346,7,16,
	0,0,11346,11347,7,8,0,0,11347,11348,7,14,0,0,11348,11349,7,19,0,0,11349,
	11350,7,12,0,0,11350,11351,7,6,0,0,11351,11352,7,3,0,0,11352,11353,7,15,
	0,0,11353,11354,7,12,0,0,11354,11355,7,11,0,0,11355,1892,1,0,0,0,11356,
	11357,7,23,0,0,11357,11358,7,16,0,0,11358,11359,7,8,0,0,11359,11360,7,4,
	0,0,11360,11361,7,15,0,0,11361,11362,7,11,0,0,11362,11363,7,27,0,0,11363,
	11364,7,19,0,0,11364,11365,7,15,0,0,11365,11366,7,12,0,0,11366,11367,7,
	6,0,0,11367,1894,1,0,0,0,11368,11369,7,23,0,0,11369,11370,7,16,0,0,11370,
	11371,7,8,0,0,11371,11372,7,7,0,0,11372,11373,7,28,0,0,11373,11374,7,17,
	0,0,11374,11375,7,3,0,0,11375,11376,7,5,0,0,11376,1896,1,0,0,0,11377,11378,
	7,23,0,0,11378,11379,7,16,0,0,11379,11380,7,8,0,0,11380,11381,7,15,0,0,
	11381,11382,7,12,0,0,11382,11383,7,6,0,0,11383,11384,7,7,0,0,11384,11385,
	7,8,0,0,11385,11386,7,11,0,0,11386,11387,7,7,0,0,11387,11388,7,14,0,0,11388,
	11389,7,6,0,0,11389,11390,7,11,0,0,11390,1898,1,0,0,0,11391,11392,7,23,
	0,0,11392,11393,7,16,0,0,11393,11394,7,8,0,0,11394,11395,7,19,0,0,11395,
	11396,7,24,0,0,11396,11397,7,7,0,0,11397,11398,7,8,0,0,11398,11399,7,5,
	0,0,11399,11400,7,3,0,0,11400,11401,7,25,0,0,11401,11402,7,11,0,0,11402,
	1900,1,0,0,0,11403,11404,7,23,0,0,11404,11405,7,16,0,0,11405,11406,7,8,
	0,0,11406,11407,7,6,0,0,11407,11408,7,19,0,0,11408,11409,7,17,0,0,11409,
	11410,7,14,0,0,11410,11411,7,20,0,0,11411,11412,7,7,0,0,11412,11413,7,11,
	0,0,11413,1902,1,0,0,0,11414,11415,7,23,0,0,11415,11416,7,16,0,0,11416,
	11417,7,8,0,0,11417,11418,7,9,0,0,11418,11419,7,15,0,0,11419,11420,7,6,
	0,0,11420,11421,7,20,0,0,11421,11422,7,15,0,0,11422,11423,7,12,0,0,11423,
	1904,1,0,0,0,11424,11425,7,23,0,0,11425,11426,7,4,0,0,11426,11427,5,53,
	0,0,11427,1906,1,0,0,0,11428,11429,7,23,0,0,11429,11430,7,5,0,0,11430,11431,
	7,15,0,0,11431,11432,7,12,0,0,11432,11433,7,7,0,0,11433,11434,7,18,0,0,
	11434,11435,7,8,0,0,11435,11436,7,19,0,0,11436,11437,7,23,0,0,11437,11438,
	7,6,0,0,11438,11439,7,7,0,0,11439,11440,7,26,0,0,11440,11441,7,6,0,0,11441,
	1908,1,0,0,0,11442,11443,7,23,0,0,11443,11444,7,5,0,0,11444,11445,7,15,
	0,0,11445,11446,7,12,0,0,11446,11447,7,7,0,0,11447,11448,7,18,0,0,11448,
	11449,7,8,0,0,11449,11450,7,19,0,0,11450,11451,7,23,0,0,11451,11452,7,9,
	0,0,11452,11453,7,21,0,0,11453,11454,7,16,0,0,11454,1910,1,0,0,0,11455,
	11456,7,23,0,0,11456,11457,7,19,0,0,11457,11458,7,12,0,0,11458,11459,7,
	6,0,0,11459,11460,7,20,0,0,11460,11461,7,12,0,0,11461,11462,7,3,0,0,11462,
	11463,7,23,0,0,11463,11464,7,7,0,0,11464,1912,1,0,0,0,11465,11466,7,23,
	0,0,11466,11467,7,25,0,0,11467,11468,7,19,0,0,11468,11469,7,15,0,0,11469,
	11470,7,12,0,0,11470,11471,7,6,0,0,11471,11472,7,18,0,0,11472,11473,7,8,
	0,0,11473,11474,7,19,0,0,11474,11475,7,23,0,0,11475,11476,7,6,0,0,11476,
	11477,7,7,0,0,11477,11478,7,26,0,0,11478,11479,7,6,0,0,11479,1914,1,0,0,
	0,11480,11481,7,23,0,0,11481,11482,7,25,0,0,11482,11483,7,19,0,0,11483,
	11484,7,15,0,0,11484,11485,7,12,0,0,11485,11486,7,6,0,0,11486,11487,7,18,
	0,0,11487,11488,7,8,0,0,11488,11489,7,19,0,0,11489,11490,7,23,0,0,11490,
	11491,7,9,0,0,11491,11492,7,21,0,0,11492,11493,7,16,0,0,11493,1916,1,0,
	0,0,11494,11495,7,23,0,0,11495,11496,7,25,0,0,11496,11497,7,19,0,0,11497,
	11498,7,5,0,0,11498,11499,7,10,0,0,11499,11500,7,18,0,0,11500,11501,7,8,
	0,0,11501,11502,7,19,0,0,11502,11503,7,23,0,0,11503,11504,7,6,0,0,11504,
	11505,7,7,0,0,11505,11506,7,26,0,0,11506,11507,7,6,0,0,11507,1918,1,0,0,
	0,11508,11509,7,23,0,0,11509,11510,7,25,0,0,11510,11511,7,19,0,0,11511,
	11512,7,5,0,0,11512,11513,7,10,0,0,11513,11514,7,18,0,0,11514,11515,7,8,
	0,0,11515,11516,7,19,0,0,11516,11517,7,23,0,0,11517,11518,7,9,0,0,11518,
	11519,7,21,0,0,11519,11520,7,16,0,0,11520,1920,1,0,0,0,11521,11522,7,23,
	0,0,11522,11523,7,17,0,0,11523,11524,7,5,0,0,11524,11525,7,6,0,0,11525,
	11526,7,15,0,0,11526,11527,7,5,0,0,11527,11528,7,15,0,0,11528,11529,7,12,
	0,0,11529,11530,7,7,0,0,11530,11531,7,11,0,0,11531,11532,7,6,0,0,11532,
	11533,7,8,0,0,11533,11534,7,15,0,0,11534,11535,7,12,0,0,11535,11536,7,22,
	0,0,11536,11537,7,18,0,0,11537,11538,7,8,0,0,11538,11539,7,19,0,0,11539,
	11540,7,23,0,0,11540,11541,7,6,0,0,11541,11542,7,7,0,0,11542,11543,7,26,
	0,0,11543,11544,7,6,0,0,11544,1922,1,0,0,0,11545,11546,7,23,0,0,11546,11547,
	7,17,0,0,11547,11548,7,5,0,0,11548,11549,7,6,0,0,11549,11550,7,15,0,0,11550,
	11551,7,5,0,0,11551,11552,7,15,0,0,11552,11553,7,12,0,0,11553,11554,7,7,
	0,0,11554,11555,7,11,0,0,11555,11556,7,6,0,0,11556,11557,7,8,0,0,11557,
	11558,7,15,0,0,11558,11559,7,12,0,0,11559,11560,7,22,0,0,11560,11561,7,
	18,0,0,11561,11562,7,8,0,0,11562,11563,7,19,0,0,11563,11564,7,23,0,0,11564,
	11565,7,9,0,0,11565,11566,7,21,0,0,11566,11567,7,16,0,0,11567,1924,1,0,
	0,0,11568,11569,7,23,0,0,11569,11570,7,17,0,0,11570,11571,7,5,0,0,11571,
	11572,7,6,0,0,11572,11573,7,15,0,0,11573,11574,7,25,0,0,11574,11575,7,19,
	0,0,11575,11576,7,15,0,0,11576,11577,7,12,0,0,11577,11578,7,6,0,0,11578,
	11579,7,18,0,0,11579,11580,7,8,0,0,11580,11581,7,19,0,0,11581,11582,7,23,
	0,0,11582,11583,7,6,0,0,11583,11584,7,7,0,0,11584,11585,7,26,0,0,11585,
	11586,7,6,0,0,11586,1926,1,0,0,0,11587,11588,7,23,0,0,11588,11589,7,17,
	0,0,11589,11590,7,5,0,0,11590,11591,7,6,0,0,11591,11592,7,15,0,0,11592,
	11593,7,25,0,0,11593,11594,7,19,0,0,11594,11595,7,15,0,0,11595,11596,7,
	12,0,0,11596,11597,7,6,0,0,11597,11598,7,18,0,0,11598,11599,7,8,0,0,11599,
	11600,7,19,0,0,11600,11601,7,23,0,0,11601,11602,7,9,0,0,11602,11603,7,21,
	0,0,11603,11604,7,16,0,0,11604,1928,1,0,0,0,11605,11606,7,23,0,0,11606,
	11607,7,17,0,0,11607,11608,7,5,0,0,11608,11609,7,6,0,0,11609,11610,7,15,
	0,0,11610,11611,7,25,0,0,11611,11612,7,19,0,0,11612,11613,7,5,0,0,11613,
	11614,7,10,0,0,11614,11615,7,22,0,0,11615,11616,7,19,0,0,11616,11617,7,
	12,0,0,11617,11618,7,18,0,0,11618,11619,7,8,0,0,11619,11620,7,19,0,0,11620,
	11621,7,23,0,0,11621,11622,7,6,0,0,11622,11623,7,7,0,0,11623,11624,7,26,
	0,0,11624,11625,7,6,0,0,11625,1930,1,0,0,0,11626,11627,7,23,0,0,11627,11628,
	7,17,0,0,11628,11629,7,5,0,0,11629,11630,7,6,0,0,11630,11631,7,15,0,0,11631,
	11632,7,25,0,0,11632,11633,7,19,0,0,11633,11634,7,5,0,0,11634,11635,7,10,
	0,0,11635,11636,7,22,0,0,11636,11637,7,19,0,0,11637,11638,7,12,0,0,11638,
	11639,7,18,0,0,11639,11640,7,8,0,0,11640,11641,7,19,0,0,11641,11642,7,23,
	0,0,11642,11643,7,9,0,0,11643,11644,7,21,0,0,11644,11645,7,16,0,0,11645,
	1932,1,0,0,0,11646,11647,7,12,0,0,11647,11648,7,3,0,0,11648,11649,7,23,
	0,0,11649,11650,7,7,0,0,11650,11651,5,95,0,0,11651,11652,7,14,0,0,11652,
	11653,7,19,0,0,11653,11654,7,12,0,0,11654,11655,7,11,0,0,11655,11656,7,
	6,0,0,11656,1934,1,0,0,0,11657,11658,7,12,0,0,11658,11659,7,17,0,0,11659,
	11660,7,5,0,0,11660,11661,7,5,0,0,11661,11662,7,15,0,0,11662,11663,7,18,
	0,0,11663,1936,1,0,0,0,11664,11665,7,12,0,0,11665,11666,7,17,0,0,11666,
	11667,7,23,0,0,11667,11668,7,22,0,0,11668,11669,7,7,0,0,11669,11670,7,19,
	0,0,11670,11671,7,23,0,0,11671,11672,7,7,0,0,11672,11673,7,6,0,0,11673,
	11674,7,8,0,0,11674,11675,7,15,0,0,11675,11676,7,7,0,0,11676,11677,7,11,
	0,0,11677,1938,1,0,0,0,11678,11679,7,12,0,0,11679,11680,7,17,0,0,11680,
	11681,7,23,0,0,11681,11682,7,15,0,0,11682,11683,7,12,0,0,11683,11684,7,
	6,0,0,11684,11685,7,7,0,0,11685,11686,7,8,0,0,11686,11687,7,15,0,0,11687,
	11688,7,19,0,0,11688,11689,7,8,0,0,11689,11690,7,8,0,0,11690,11691,7,15,
	0,0,11691,11692,7,12,0,0,11692,11693,7,22,0,0,11693,11694,7,11,0,0,11694,
	1940,1,0,0,0,11695,11696,7,12,0,0,11696,11697,7,17,0,0,11697,11698,7,23,
	0,0,11698,11699,7,25,0,0,11699,11700,7,19,0,0,11700,11701,7,15,0,0,11701,
	11702,7,12,0,0,11702,11703,7,6,0,0,11703,11704,7,11,0,0,11704,1942,1,0,
	0,0,11705,11706,7,19,0,0,11706,11707,7,14,0,0,11707,11708,7,6,0,0,11708,
	1944,1,0,0,0,11709,11710,7,19,0,0,11710,11711,7,14,0,0,11711,11712,7,6,
	0,0,11712,11713,7,7,0,0,11713,11714,7,6,0,0,11714,11715,5,95,0,0,11715,
	11716,7,5,0,0,11716,11717,7,7,0,0,11717,11718,7,12,0,0,11718,11719,7,22,
	0,0,11719,11720,7,6,0,0,11720,11721,7,20,0,0,11721,1946,1,0,0,0,11722,11723,
	7,19,0,0,11723,11724,7,8,0,0,11724,11725,7,4,0,0,11725,1948,1,0,0,0,11726,
	11727,7,19,0,0,11727,11728,7,24,0,0,11728,11729,7,7,0,0,11729,11730,7,8,
	0,0,11730,11731,7,5,0,0,11731,11732,7,3,0,0,11732,11733,7,25,0,0,11733,
	11734,7,11,0,0,11734,1950,1,0,0,0,11735,11736,7,25,0,0,11736,11737,7,7,
	0,0,11737,11738,7,8,0,0,11738,11739,7,15,0,0,11739,11740,7,19,0,0,11740,
	11741,7,4,0,0,11741,11742,5,95,0,0,11742,11743,7,3,0,0,11743,11744,7,4,
	0,0,11744,11745,7,4,0,0,11745,1952,1,0,0,0,11746,11747,7,25,0,0,11747,11748,
	7,7,0,0,11748,11749,7,8,0,0,11749,11750,7,15,0,0,11750,11751,7,19,0,0,11751,
	11752,7,4,0,0,11752,11753,5,95,0,0,11753,11754,7,4,0,0,11754,11755,7,15,
	0,0,11755,11756,7,18,0,0,11756,11757,7,18,0,0,11757,1954,1,0,0,0,11758,
	11759,7,25,0,0,11759,11760,7,15,0,0,11760,1956,1,0,0,0,11761,11762,7,25,
	0,0,11762,11763,7,19,0,0,11763,11764,7,15,0,0,11764,11765,7,12,0,0,11765,
	11766,7,6,0,0,11766,11767,7,18,0,0,11767,11768,7,8,0,0,11768,11769,7,19,
	0,0,11769,11770,7,23,0,0,11770,11771,7,6,0,0,11771,11772,7,7,0,0,11772,
	11773,7,26,0,0,11773,11774,7,6,0,0,11774,1958,1,0,0,0,11775,11776,7,25,
	0,0,11776,11777,7,19,0,0,11777,11778,7,15,0,0,11778,11779,7,12,0,0,11779,
	11780,7,6,0,0,11780,11781,7,18,0,0,11781,11782,7,8,0,0,11782,11783,7,19,
	0,0,11783,11784,7,23,0,0,11784,11785,7,9,0,0,11785,11786,7,21,0,0,11786,
	11787,7,16,0,0,11787,1960,1,0,0,0,11788,11789,7,25,0,0,11789,11790,7,19,
	0,0,11790,11791,7,15,0,0,11791,11792,7,12,0,0,11792,11793,7,6,0,0,11793,
	11794,7,12,0,0,11794,1962,1,0,0,0,11795,11796,7,25,0,0,11796,11797,7,19,
	0,0,11797,11798,7,5,0,0,11798,11799,7,10,0,0,11799,11800,7,18,0,0,11800,
	11801,7,8,0,0,11801,11802,7,19,0,0,11802,11803,7,23,0,0,11803,11804,7,6,
	0,0,11804,11805,7,7,0,0,11805,11806,7,26,0,0,11806,11807,7,6,0,0,11807,
	1964,1,0,0,0,11808,11809,7,25,0,0,11809,11810,7,19,0,0,11810,11811,7,5,
	0,0,11811,11812,7,10,0,0,11812,11813,7,18,0,0,11813,11814,7,8,0,0,11814,
	11815,7,19,0,0,11815,11816,7,23,0,0,11816,11817,7,9,0,0,11817,11818,7,21,
	0,0,11818,11819,7,16,0,0,11819,1966,1,0,0,0,11820,11821,7,25,0,0,11821,
	11822,7,19,0,0,11822,11823,7,5,0,0,11823,11824,7,10,0,0,11824,11825,7,22,
	0,0,11825,11826,7,19,0,0,11826,11827,7,12,0,0,11827,11828,7,18,0,0,11828,
	11829,7,8,0,0,11829,11830,7,19,0,0,11830,11831,7,23,0,0,11831,11832,7,6,
	0,0,11832,11833,7,7,0,0,11833,11834,7,26,0,0,11834,11835,7,6,0,0,11835,
	1968,1,0,0,0,11836,11837,7,25,0,0,11837,11838,7,19,0,0,11838,11839,7,5,
	0,0,11839,11840,7,10,0,0,11840,11841,7,22,0,0,11841,11842,7,19,0,0,11842,
	11843,7,12,0,0,11843,11844,7,18,0,0,11844,11845,7,8,0,0,11845,11846,7,19,
	0,0,11846,11847,7,23,0,0,11847,11848,7,9,0,0,11848,11849,7,21,0,0,11849,
	11850,7,16,0,0,11850,1970,1,0,0,0,11851,11852,7,25,0,0,11852,11853,7,19,
	0,0,11853,11854,7,9,0,0,11854,1972,1,0,0,0,11855,11856,7,25,0,0,11856,11857,
	7,19,0,0,11857,11858,7,9,0,0,11858,11859,7,7,0,0,11859,11860,7,8,0,0,11860,
	1974,1,0,0,0,11861,11862,7,28,0,0,11862,11863,7,17,0,0,11863,11864,7,19,
	0,0,11864,11865,7,6,0,0,11865,11866,7,7,0,0,11866,1976,1,0,0,0,11867,11868,
	7,8,0,0,11868,11869,7,3,0,0,11869,11870,7,4,0,0,11870,11871,7,15,0,0,11871,
	11872,7,3,0,0,11872,11873,7,12,0,0,11873,11874,7,11,0,0,11874,1978,1,0,
	0,0,11875,11876,7,8,0,0,11876,11877,7,3,0,0,11877,11878,7,12,0,0,11878,
	11879,7,4,0,0,11879,1980,1,0,0,0,11880,11881,7,8,0,0,11881,11882,7,3,0,
	0,11882,11883,7,12,0,0,11883,11884,7,4,0,0,11884,11885,7,19,0,0,11885,11886,
	7,23,0,0,11886,1982,1,0,0,0,11887,11888,7,8,0,0,11888,11889,7,3,0,0,11889,
	11890,7,12,0,0,11890,11891,7,4,0,0,11891,11892,7,19,0,0,11892,11893,7,23,
	0,0,11893,11894,5,95,0,0,11894,11895,7,16,0,0,11895,11896,7,10,0,0,11896,
	11897,7,6,0,0,11897,11898,7,7,0,0,11898,11899,7,11,0,0,11899,1984,1,0,0,
	0,11900,11901,7,8,0,0,11901,11902,7,7,0,0,11902,11903,7,5,0,0,11903,11904,
	7,7,0,0,11904,11905,7,3,0,0,11905,11906,7,11,0,0,11906,11907,7,7,0,0,11907,
	11908,5,95,0,0,11908,11909,7,5,0,0,11909,11910,7,19,0,0,11910,11911,7,14,
	0,0,11911,11912,7,21,0,0,11912,1986,1,0,0,0,11913,11914,7,8,0,0,11914,11915,
	7,7,0,0,11915,11916,7,24,0,0,11916,11917,7,7,0,0,11917,11918,7,8,0,0,11918,
	11919,7,11,0,0,11919,11920,7,7,0,0,11920,1988,1,0,0,0,11921,11922,7,8,0,
	0,11922,11923,7,19,0,0,11923,11924,7,17,0,0,11924,11925,7,12,0,0,11925,
	11926,7,4,0,0,11926,1990,1,0,0,0,11927,11928,7,8,0,0,11928,11929,7,19,0,
	0,11929,11930,7,9,0,0,11930,11931,5,95,0,0,11931,11932,7,14,0,0,11932,11933,
	7,19,0,0,11933,11934,7,17,0,0,11934,11935,7,12,0,0,11935,11936,7,6,0,0,
	11936,1992,1,0,0,0,11937,11938,7,8,0,0,11938,11939,7,25,0,0,11939,11940,
	7,3,0,0,11940,11941,7,4,0,0,11941,1994,1,0,0,0,11942,11943,7,8,0,0,11943,
	11944,7,6,0,0,11944,11945,7,8,0,0,11945,11946,7,15,0,0,11946,11947,7,23,
	0,0,11947,1996,1,0,0,0,11948,11949,7,11,0,0,11949,11950,7,7,0,0,11950,11951,
	7,14,0,0,11951,11952,5,95,0,0,11952,11953,7,6,0,0,11953,11954,7,19,0,0,
	11954,11955,5,95,0,0,11955,11956,7,6,0,0,11956,11957,7,15,0,0,11957,11958,
	7,23,0,0,11958,11959,7,7,0,0,11959,1998,1,0,0,0,11960,11961,7,11,0,0,11961,
	11962,7,7,0,0,11962,11963,7,14,0,0,11963,11964,7,19,0,0,11964,11965,7,12,
	0,0,11965,11966,7,4,0,0,11966,11967,7,3,0,0,11967,11968,7,8,0,0,11968,11969,
	7,10,0,0,11969,11970,5,95,0,0,11970,11971,7,7,0,0,11971,11972,7,12,0,0,
	11972,11973,7,22,0,0,11973,11974,7,15,0,0,11974,11975,7,12,0,0,11975,11976,
	7,7,0,0,11976,11977,5,95,0,0,11977,11978,7,3,0,0,11978,11979,7,6,0,0,11979,
	11980,7,6,0,0,11980,11981,7,8,0,0,11981,11982,7,15,0,0,11982,11983,7,16,
	0,0,11983,11984,7,17,0,0,11984,11985,7,6,0,0,11985,11986,7,7,0,0,11986,
	2000,1,0,0,0,11987,11988,7,11,0,0,11988,11989,7,7,0,0,11989,11990,7,11,
	0,0,11990,11991,7,11,0,0,11991,11992,7,15,0,0,11992,11993,7,19,0,0,11993,
	11994,7,12,0,0,11994,11995,5,95,0,0,11995,11996,7,17,0,0,11996,11997,7,
	11,0,0,11997,11998,7,7,0,0,11998,11999,7,8,0,0,11999,2002,1,0,0,0,12000,
	12001,7,11,0,0,12001,12002,7,20,0,0,12002,12003,7,3,0,0,12003,2004,1,0,
	0,0,12004,12005,7,11,0,0,12005,12006,7,20,0,0,12006,12007,7,3,0,0,12007,
	12008,5,49,0,0,12008,2006,1,0,0,0,12009,12010,7,11,0,0,12010,12011,7,20,
	0,0,12011,12012,7,3,0,0,12012,12013,5,50,0,0,12013,2008,1,0,0,0,12014,12015,
	7,11,0,0,12015,12016,7,14,0,0,12016,12017,7,20,0,0,12017,12018,7,7,0,0,
	12018,12019,7,23,0,0,12019,12020,7,3,0,0,12020,12021,5,95,0,0,12021,12022,
	7,12,0,0,12022,12023,7,3,0,0,12023,12024,7,23,0,0,12024,12025,7,7,0,0,12025,
	2010,1,0,0,0,12026,12027,7,11,0,0,12027,12028,7,15,0,0,12028,12029,7,22,
	0,0,12029,12030,7,12,0,0,12030,2012,1,0,0,0,12031,12032,7,11,0,0,12032,
	12033,7,15,0,0,12033,12034,7,12,0,0,12034,2014,1,0,0,0,12035,12036,7,11,
	0,0,12036,12037,7,5,0,0,12037,12038,7,7,0,0,12038,12039,7,7,0,0,12039,12040,
	7,25,0,0,12040,2016,1,0,0,0,12041,12042,7,11,0,0,12042,12043,7,19,0,0,12043,
	12044,7,17,0,0,12044,12045,7,12,0,0,12045,12046,7,4,0,0,12046,12047,7,7,
	0,0,12047,12048,7,26,0,0,12048,2018,1,0,0,0,12049,12050,7,11,0,0,12050,
	12051,7,28,0,0,12051,12052,7,5,0,0,12052,12053,5,95,0,0,12053,12054,7,6,
	0,0,12054,12055,7,20,0,0,12055,12056,7,8,0,0,12056,12057,7,7,0,0,12057,
	12058,7,3,0,0,12058,12059,7,4,0,0,12059,12060,5,95,0,0,12060,12061,7,9,
	0,0,12061,12062,7,3,0,0,12062,12063,7,15,0,0,12063,12064,7,6,0,0,12064,
	12065,5,95,0,0,12065,12066,7,3,0,0,12066,12067,7,18,0,0,12067,12068,7,6,
	0,0,12068,12069,7,7,0,0,12069,12070,7,8,0,0,12070,12071,5,95,0,0,12071,
	12072,7,22,0,0,12072,12073,7,6,0,0,12073,12074,7,15,0,0,12074,12075,7,4,
	0,0,12075,12076,7,11,0,0,12076,2020,1,0,0,0,12077,12078,7,11,0,0,12078,
	12079,7,28,0,0,12079,12080,7,8,0,0,12080,12081,7,6,0,0,12081,2022,1,0,0,
	0,12082,12083,7,11,0,0,12083,12084,7,8,0,0,12084,12085,7,15,0,0,12085,12086,
	7,4,0,0,12086,2024,1,0,0,0,12087,12088,7,11,0,0,12088,12089,7,6,0,0,12089,
	12090,7,3,0,0,12090,12091,7,8,0,0,12091,12092,7,6,0,0,12092,12093,7,25,
	0,0,12093,12094,7,19,0,0,12094,12095,7,15,0,0,12095,12096,7,12,0,0,12096,
	12097,7,6,0,0,12097,2026,1,0,0,0,12098,12099,7,11,0,0,12099,12100,7,6,0,
	0,12100,12101,7,8,0,0,12101,12102,7,14,0,0,12102,12103,7,23,0,0,12103,12104,
	7,25,0,0,12104,2028,1,0,0,0,12105,12106,7,11,0,0,12106,12107,7,6,0,0,12107,
	12108,7,8,0,0,12108,12109,5,95,0,0,12109,12110,7,6,0,0,12110,12111,7,19,
	0,0,12111,12112,5,95,0,0,12112,12113,7,4,0,0,12113,12114,7,3,0,0,12114,
	12115,7,6,0,0,12115,12116,7,7,0,0,12116,2030,1,0,0,0,12117,12118,7,11,0,
	0,12118,12119,7,6,0,0,12119,12120,5,95,0,0,12120,12121,7,3,0,0,12121,12122,
	7,8,0,0,12122,12123,7,7,0,0,12123,12124,7,3,0,0,12124,2032,1,0,0,0,12125,
	12126,7,11,0,0,12126,12127,7,6,0,0,12127,12128,5,95,0,0,12128,12129,7,3,
	0,0,12129,12130,7,11,0,0,12130,12131,7,16,0,0,12131,12132,7,15,0,0,12132,
	12133,7,12,0,0,12133,12134,7,3,0,0,12134,12135,7,8,0,0,12135,12136,7,10,
	0,0,12136,2034,1,0,0,0,12137,12138,7,11,0,0,12138,12139,7,6,0,0,12139,12140,
	5,95,0,0,12140,12141,7,3,0,0,12141,12142,7,11,0,0,12142,12143,7,6,0,0,12143,
	12144,7,7,0,0,12144,12145,7,26,0,0,12145,12146,7,6,0,0,12146,2036,1,0,0,
	0,12147,12148,7,11,0,0,12148,12149,7,6,0,0,12149,12150,5,95,0,0,12150,12151,
	7,3,0,0,12151,12152,7,11,0,0,12152,12153,7,9,0,0,12153,12154,7,21,0,0,12154,
	12155,7,16,0,0,12155,2038,1,0,0,0,12156,12157,7,11,0,0,12157,12158,7,6,
	0,0,12158,12159,5,95,0,0,12159,12160,7,3,0,0,12160,12161,7,11,0,0,12161,
	12162,7,9,0,0,12162,12163,7,21,0,0,12163,12164,7,6,0,0,12164,2040,1,0,0,
	0,12165,12166,7,11,0,0,12166,12167,7,6,0,0,12167,12168,5,95,0,0,12168,12169,
	7,16,0,0,12169,12170,7,17,0,0,12170,12171,7,18,0,0,12171,12172,7,18,0,0,
	12172,12173,7,7,0,0,12173,12174,7,8,0,0,12174,2042,1,0,0,0,12175,12176,
	7,11,0,0,12176,12177,7,6,0,0,12177,12178,5,95,0,0,12178,12179,7,14,0,0,
	12179,12180,7,7,0,0,12180,12181,7,12,0,0,12181,12182,7,6,0,0,12182,12183,
	7,8,0,0,12183,12184,7,19,0,0,12184,12185,7,15,0,0,12185,12186,7,4,0,0,12186,
	2044,1,0,0,0,12187,12188,7,11,0,0,12188,12189,7,6,0,0,12189,12190,5,95,
	0,0,12190,12191,7,14,0,0,12191,12192,7,19,0,0,12192,12193,7,12,0,0,12193,
	12194,7,6,0,0,12194,12195,7,3,0,0,12195,12196,7,15,0,0,12196,12197,7,12,
	0,0,12197,12198,7,11,0,0,12198,2046,1,0,0,0,12199,12200,7,11,0,0,12200,
	12201,7,6,0,0,12201,12202,5,95,0,0,12202,12203,7,14,0,0,12203,12204,7,8,
	0,0,12204,12205,7,19,0,0,12205,12206,7,11,0,0,12206,12207,7,11,0,0,12207,
	12208,7,7,0,0,12208,12209,7,11,0,0,12209,2048,1,0,0,0,12210,12211,7,11,
	0,0,12211,12212,7,6,0,0,12212,12213,5,95,0,0,12213,12214,7,4,0,0,12214,
	12215,7,15,0,0,12215,12216,7,18,0,0,12216,12217,7,18,0,0,12217,12218,7,
	7,0,0,12218,12219,7,8,0,0,12219,12220,7,7,0,0,12220,12221,7,12,0,0,12221,
	12222,7,14,0,0,12222,12223,7,7,0,0,12223,2050,1,0,0,0,12224,12225,7,11,
	0,0,12225,12226,7,6,0,0,12226,12227,5,95,0,0,12227,12228,7,4,0,0,12228,
	12229,7,15,0,0,12229,12230,7,23,0,0,12230,12231,7,7,0,0,12231,12232,7,12,
	0,0,12232,12233,7,11,0,0,12233,12234,7,15,0,0,12234,12235,7,19,0,0,12235,
	12236,7,12,0,0,12236,2052,1,0,0,0,12237,12238,7,11,0,0,12238,12239,7,6,
	0,0,12239,12240,5,95,0,0,12240,12241,7,4,0,0,12241,12242,7,15,0,0,12242,
	12243,7,11,0,0,12243,12244,7,27,0,0,12244,12245,7,19,0,0,12245,12246,7,
	15,0,0,12246,12247,7,12,0,0,12247,12248,7,6,0,0,12248,2054,1,0,0,0,12249,
	12250,7,11,0,0,12250,12251,7,6,0,0,12251,12252,5,95,0,0,12252,12253,7,4,
	0,0,12253,12254,7,15,0,0,12254,12255,7,11,0,0,12255,12256,7,6,0,0,12256,
	12257,7,3,0,0,12257,12258,7,12,0,0,12258,12259,7,14,0,0,12259,12260,7,7,
	0,0,12260,2056,1,0,0,0,12261,12262,7,11,0,0,12262,12263,7,6,0,0,12263,12264,
	5,95,0,0,12264,12265,7,7,0,0,12265,12266,7,12,0,0,12266,12267,7,4,0,0,12267,
	12268,7,25,0,0,12268,12269,7,19,0,0,12269,12270,7,15,0,0,12270,12271,7,
	12,0,0,12271,12272,7,6,0,0,12272,2058,1,0,0,0,12273,12274,7,11,0,0,12274,
	12275,7,6,0,0,12275,12276,5,95,0,0,12276,12277,7,7,0,0,12277,12278,7,12,
	0,0,12278,12279,7,24,0,0,12279,12280,7,7,0,0,12280,12281,7,5,0,0,12281,
	12282,7,19,0,0,12282,12283,7,25,0,0,12283,12284,7,7,0,0,12284,2060,1,0,
	0,0,12285,12286,7,11,0,0,12286,12287,7,6,0,0,12287,12288,5,95,0,0,12288,
	12289,7,7,0,0,12289,12290,7,28,0,0,12290,12291,7,17,0,0,12291,12292,7,3,
	0,0,12292,12293,7,5,0,0,12293,12294,7,11,0,0,12294,2062,1,0,0,0,12295,12296,
	7,11,0,0,12296,12297,7,6,0,0,12297,12298,5,95,0,0,12298,12299,7,7,0,0,12299,
	12300,7,26,0,0,12300,12301,7,6,0,0,12301,12302,7,7,0,0,12302,12303,7,8,
	0,0,12303,12304,7,15,0,0,12304,12305,7,19,0,0,12305,12306,7,8,0,0,12306,
	12307,7,8,0,0,12307,12308,7,15,0,0,12308,12309,7,12,0,0,12309,12310,7,22,
	0,0,12310,2064,1,0,0,0,12311,12312,7,11,0,0,12312,12313,7,6,0,0,12313,12314,
	5,95,0,0,12314,12315,7,22,0,0,12315,12316,7,7,0,0,12316,12317,7,19,0,0,
	12317,12318,7,23,0,0,12318,12319,7,14,0,0,12319,12320,7,19,0,0,12320,12321,
	7,5,0,0,12321,12322,7,5,0,0,12322,12323,7,18,0,0,12323,12324,7,8,0,0,12324,
	12325,7,19,0,0,12325,12326,7,23,0,0,12326,12327,7,6,0,0,12327,12328,7,7,
	0,0,12328,12329,7,26,0,0,12329,12330,7,6,0,0,12330,2066,1,0,0,0,12331,12332,
	7,11,0,0,12332,12333,7,6,0,0,12333,12334,5,95,0,0,12334,12335,7,22,0,0,
	12335,12336,7,7,0,0,12336,12337,7,19,0,0,12337,12338,7,23,0,0,12338,12339,
	7,14,0,0,12339,12340,7,19,0,0,12340,12341,7,5,0,0,12341,12342,7,5,0,0,12342,
	12343,7,18,0,0,12343,12344,7,8,0,0,12344,12345,7,19,0,0,12345,12346,7,23,
	0,0,12346,12347,7,6,0,0,12347,12348,7,26,0,0,12348,12349,7,6,0,0,12349,
	2068,1,0,0,0,12350,12351,7,11,0,0,12351,12352,7,6,0,0,12352,12353,5,95,
	0,0,12353,12354,7,22,0,0,12354,12355,7,7,0,0,12355,12356,7,19,0,0,12356,
	12357,7,23,0,0,12357,12358,7,14,0,0,12358,12359,7,19,0,0,12359,12360,7,
	5,0,0,12360,12361,7,5,0,0,12361,12362,7,18,0,0,12362,12363,7,8,0,0,12363,
	12364,7,19,0,0,12364,12365,7,23,0,0,12365,12366,7,9,0,0,12366,12367,7,21,
	0,0,12367,12368,7,16,0,0,12368,2070,1,0,0,0,12369,12370,7,11,0,0,12370,
	12371,7,6,0,0,12371,12372,5,95,0,0,12372,12373,7,22,0,0,12373,12374,7,7,
	0,0,12374,12375,7,19,0,0,12375,12376,7,23,0,0,12376,12377,7,7,0,0,12377,
	12378,7,6,0,0,12378,12379,7,8,0,0,12379,12380,7,10,0,0,12380,12381,7,14,
	0,0,12381,12382,7,19,0,0,12382,12383,7,5,0,0,12383,12384,7,5,0,0,12384,
	12385,7,7,0,0,12385,12386,7,14,0,0,12386,12387,7,6,0,0,12387,12388,7,15,
	0,0,12388,12389,7,19,0,0,12389,12390,7,12,0,0,12390,12391,7,18,0,0,12391,
	12392,7,8,0,0,12392,12393,7,19,0,0,12393,12394,7,23,0,0,12394,12395,7,6,
	0,0,12395,12396,7,7,0,0,12396,12397,7,26,0,0,12397,12398,7,6,0,0,12398,
	2072,1,0,0,0,12399,12400,7,11,0,0,12400,12401,7,6,0,0,12401,12402,5,95,
	0,0,12402,12403,7,22,0,0,12403,12404,7,7,0,0,12404,12405,7,19,0,0,12405,
	12406,7,23,0,0,12406,12407,7,7,0,0,12407,12408,7,6,0,0,12408,12409,7,8,
	0,0,12409,12410,7,10,0,0,12410,12411,7,14,0,0,12411,12412,7,19,0,0,12412,
	12413,7,5,0,0,12413,12414,7,5,0,0,12414,12415,7,7,0,0,12415,12416,7,14,
	0,0,12416,12417,7,6,0,0,12417,12418,7,15,0,0,12418,12419,7,19,0,0,12419,
	12420,7,12,0,0,12420,12421,7,18,0,0,12421,12422,7,8,0,0,12422,12423,7,19,
	0,0,12423,12424,7,23,0,0,12424,12425,7,9,0,0,12425,12426,7,21,0,0,12426,
	12427,7,16,0,0,12427,2074,1,0,0,0,12428,12429,7,11,0,0,12429,12430,7,6,
	0,0,12430,12431,5,95,0,0,12431,12432,7,22,0,0,12432,12433,7,7,0,0,12433,
	12434,7,19,0,0,12434,12435,7,23,0,0,12435,12436,7,7,0,0,12436,12437,7,6,
	0,0,12437,12438,7,8,0,0,12438,12439,7,10,0,0,12439,12440,7,18,0,0,12440,
	12441,7,8,0,0,12441,12442,7,19,0,0,12442,12443,7,23,0,0,12443,12444,7,6,
	0,0,12444,12445,7,7,0,0,12445,12446,7,26,0,0,12446,12447,7,6,0,0,12447,
	2076,1,0,0,0,12448,12449,7,11,0,0,12449,12450,7,6,0,0,12450,12451,5,95,
	0,0,12451,12452,7,22,0,0,12452,12453,7,7,0,0,12453,12454,7,19,0,0,12454,
	12455,7,23,0,0,12455,12456,7,7,0,0,12456,12457,7,6,0,0,12457,12458,7,8,
	0,0,12458,12459,7,10,0,0,12459,12460,7,18,0,0,12460,12461,7,8,0,0,12461,
	12462,7,19,0,0,12462,12463,7,23,0,0,12463,12464,7,9,0,0,12464,12465,7,21,
	0,0,12465,12466,7,16,0,0,12466,2078,1,0,0,0,12467,12468,7,11,0,0,12468,
	12469,7,6,0,0,12469,12470,5,95,0,0,12470,12471,7,22,0,0,12471,12472,7,7,
	0,0,12472,12473,7,19,0,0,12473,12474,7,23,0,0,12474,12475,7,7,0,0,12475,
	12476,7,6,0,0,12476,12477,7,8,0,0,12477,12478,7,10,0,0,12478,12479,7,12,
	0,0,12479,2080,1,0,0,0,12480,12481,7,11,0,0,12481,12482,7,6,0,0,12482,12483,
	5,95,0,0,12483,12484,7,22,0,0,12484,12485,7,7,0,0,12485,12486,7,19,0,0,
	12486,12487,7,23,0,0,12487,12488,7,7,0,0,12488,12489,7,6,0,0,12489,12490,
	7,8,0,0,12490,12491,7,10,0,0,12491,12492,7,6,0,0,12492,12493,7,10,0,0,12493,
	12494,7,25,0,0,12494,12495,7,7,0,0,12495,2082,1,0,0,0,12496,12497,7,11,
	0,0,12497,12498,7,6,0,0,12498,12499,5,95,0,0,12499,12500,7,22,0,0,12500,
	12501,7,7,0,0,12501,12502,7,19,0,0,12502,12503,7,23,0,0,12503,12504,7,18,
	0,0,12504,12505,7,8,0,0,12505,12506,7,19,0,0,12506,12507,7,23,0,0,12507,
	12508,7,6,0,0,12508,12509,7,7,0,0,12509,12510,7,26,0,0,12510,12511,7,6,
	0,0,12511,2084,1,0,0,0,12512,12513,7,11,0,0,12513,12514,7,6,0,0,12514,12515,
	5,95,0,0,12515,12516,7,22,0,0,12516,12517,7,7,0,0,12517,12518,7,19,0,0,
	12518,12519,7,23,0,0,12519,12520,7,18,0,0,12520,12521,7,8,0,0,12521,12522,
	7,19,0,0,12522,12523,7,23,0,0,12523,12524,7,9,0,0,12524,12525,7,21,0,0,
	12525,12526,7,16,0,0,12526,2086,1,0,0,0,12527,12528,7,11,0,0,12528,12529,
	7,6,0,0,12529,12530,5,95,0,0,12530,12531,7,15,0,0,12531,12532,7,12,0,0,
	12532,12533,7,6,0,0,12533,12534,7,7,0,0,12534,12535,7,8,0,0,12535,12536,
	7,15,0,0,12536,12537,7,19,0,0,12537,12538,7,8,0,0,12538,12539,7,8,0,0,12539,
	12540,7,15,0,0,12540,12541,7,12,0,0,12541,12542,7,22,0,0,12542,12543,7,
	12,0,0,12543,2088,1,0,0,0,12544,12545,7,11,0,0,12545,12546,7,6,0,0,12546,
	12547,5,95,0,0,12547,12548,7,15,0,0,12548,12549,7,12,0,0,12549,12550,7,
	6,0,0,12550,12551,7,7,0,0,12551,12552,7,8,0,0,12552,12553,7,11,0,0,12553,
	12554,7,7,0,0,12554,12555,7,14,0,0,12555,12556,7,6,0,0,12556,12557,7,15,
	0,0,12557,12558,7,19,0,0,12558,12559,7,12,0,0,12559,2090,1,0,0,0,12560,
	12561,7,11,0,0,12561,12562,7,6,0,0,12562,12563,5,95,0,0,12563,12564,7,15,
	0,0,12564,12565,7,12,0,0,12565,12566,7,6,0,0,12566,12567,7,7,0,0,12567,
	12568,7,8,0,0,12568,12569,7,11,0,0,12569,12570,7,7,0,0,12570,12571,7,14,
	0,0,12571,12572,7,6,0,0,12572,12573,7,11,0,0,12573,2092,1,0,0,0,12574,12575,
	7,11,0,0,12575,12576,7,6,0,0,12576,12577,5,95,0,0,12577,12578,7,15,0,0,
	12578,12579,7,11,0,0,12579,12580,7,14,0,0,12580,12581,7,5,0,0,12581,12582,
	7,19,0,0,12582,12583,7,11,0,0,12583,12584,7,7,0,0,12584,12585,7,4,0,0,12585,
	2094,1,0,0,0,12586,12587,7,11,0,0,12587,12588,7,6,0,0,12588,12589,5,95,
	0,0,12589,12590,7,15,0,0,12590,12591,7,11,0,0,12591,12592,7,7,0,0,12592,
	12593,7,23,0,0,12593,12594,7,25,0,0,12594,12595,7,6,0,0,12595,12596,7,10,
	0,0,12596,2096,1,0,0,0,12597,12598,7,11,0,0,12598,12599,7,6,0,0,12599,12600,
	5,95,0,0,12600,12601,7,15,0,0,12601,12602,7,11,0,0,12602,12603,7,11,0,0,
	12603,12604,7,15,0,0,12604,12605,7,23,0,0,12605,12606,7,25,0,0,12606,12607,
	7,5,0,0,12607,12608,7,7,0,0,12608,2098,1,0,0,0,12609,12610,7,11,0,0,12610,
	12611,7,6,0,0,12611,12612,5,95,0,0,12612,12613,7,5,0,0,12613,12614,7,15,
	0,0,12614,12615,7,12,0,0,12615,12616,7,7,0,0,12616,12617,7,18,0,0,12617,
	12618,7,8,0,0,12618,12619,7,19,0,0,12619,12620,7,23,0,0,12620,12621,7,6,
	0,0,12621,12622,7,7,0,0,12622,12623,7,26,0,0,12623,12624,7,6,0,0,12624,
	2100,1,0,0,0,12625,12626,7,11,0,0,12626,12627,7,6,0,0,12627,12628,5,95,
	0,0,12628,12629,7,5,0,0,12629,12630,7,15,0,0,12630,12631,7,12,0,0,12631,
	12632,7,7,0,0,12632,12633,7,18,0,0,12633,12634,7,8,0,0,12634,12635,7,19,
	0,0,12635,12636,7,23,0,0,12636,12637,7,9,0,0,12637,12638,7,21,0,0,12638,
	12639,7,16,0,0,12639,2102,1,0,0,0,12640,12641,7,11,0,0,12641,12642,7,6,
	0,0,12642,12643,5,95,0,0,12643,12644,7,5,0,0,12644,12645,7,15,0,0,12645,
	12646,7,12,0,0,12646,12647,7,7,0,0,12647,12648,7,11,0,0,12648,12649,7,6,
	0,0,12649,12650,7,8,0,0,12650,12651,7,15,0,0,12651,12652,7,12,0,0,12652,
	12653,7,22,0,0,12653,12654,7,18,0,0,12654,12655,7,8,0,0,12655,12656,7,19,
	0,0,12656,12657,7,23,0,0,12657,12658,7,6,0,0,12658,12659,7,7,0,0,12659,
	12660,7,26,0,0,12660,12661,7,6,0,0,12661,2104,1,0,0,0,12662,12663,7,11,
	0,0,12663,12664,7,6,0,0,12664,12665,5,95,0,0,12665,12666,7,5,0,0,12666,
	12667,7,15,0,0,12667,12668,7,12,0,0,12668,12669,7,7,0,0,12669,12670,7,11,
	0,0,12670,12671,7,6,0,0,12671,12672,7,8,0,0,12672,12673,7,15,0,0,12673,
	12674,7,12,0,0,12674,12675,7,22,0,0,12675,12676,7,18,0,0,12676,12677,7,
	8,0,0,12677,12678,7,19,0,0,12678,12679,7,23,0,0,12679,12680,7,9,0,0,12680,
	12681,7,21,0,0,12681,12682,7,16,0,0,12682,2106,1,0,0,0,12683,12684,7,11,
	0,0,12684,12685,7,6,0,0,12685,12686,5,95,0,0,12686,12687,7,12,0,0,12687,
	12688,7,17,0,0,12688,12689,7,23,0,0,12689,12690,7,22,0,0,12690,12691,7,
	7,0,0,12691,12692,7,19,0,0,12692,12693,7,23,0,0,12693,12694,7,7,0,0,12694,
	12695,7,6,0,0,12695,12696,7,8,0,0,12696,12697,7,15,0,0,12697,12698,7,7,
	0,0,12698,12699,7,11,0,0,12699,2108,1,0,0,0,12700,12701,7,11,0,0,12701,
	12702,7,6,0,0,12702,12703,5,95,0,0,12703,12704,7,12,0,0,12704,12705,7,17,
	0,0,12705,12706,7,23,0,0,12706,12707,7,15,0,0,12707,12708,7,12,0,0,12708,
	12709,7,6,0,0,12709,12710,7,7,0,0,12710,12711,7,8,0,0,12711,12712,7,15,
	0,0,12712,12713,7,19,0,0,12713,12714,7,8,0,0,12714,12715,7,8,0,0,12715,
	12716,7,15,0,0,12716,12717,7,12,0,0,12717,12718,7,22,0,0,12718,2110,1,0,
	0,0,12719,12720,7,11,0,0,12720,12721,7,6,0,0,12721,12722,5,95,0,0,12722,
	12723,7,12,0,0,12723,12724,7,17,0,0,12724,12725,7,23,0,0,12725,12726,7,
	15,0,0,12726,12727,7,12,0,0,12727,12728,7,6,0,0,12728,12729,7,7,0,0,12729,
	12730,7,8,0,0,12730,12731,7,15,0,0,12731,12732,7,19,0,0,12732,12733,7,8,
	0,0,12733,12734,7,8,0,0,12734,12735,7,15,0,0,12735,12736,7,12,0,0,12736,
	12737,7,22,0,0,12737,12738,7,11,0,0,12738,2112,1,0,0,0,12739,12740,7,11,
	0,0,12740,12741,7,6,0,0,12741,12742,5,95,0,0,12742,12743,7,12,0,0,12743,
	12744,7,17,0,0,12744,12745,7,23,0,0,12745,12746,7,25,0,0,12746,12747,7,
	19,0,0,12747,12748,7,15,0,0,12748,12749,7,12,0,0,12749,12750,7,6,0,0,12750,
	12751,7,11,0,0,12751,2114,1,0,0,0,12752,12753,7,11,0,0,12753,12754,7,6,
	0,0,12754,12755,5,95,0,0,12755,12756,7,19,0,0,12756,12757,7,24,0,0,12757,
	12758,7,7,0,0,12758,12759,7,8,0,0,12759,12760,7,5,0,0,12760,12761,7,3,0,
	0,12761,12762,7,25,0,0,12762,12763,7,11,0,0,12763,2116,1,0,0,0,12764,12765,
	7,11,0,0,12765,12766,7,6,0,0,12766,12767,5,95,0,0,12767,12768,7,25,0,0,
	12768,12769,7,19,0,0,12769,12770,7,15,0,0,12770,12771,7,12,0,0,12771,12772,
	7,6,0,0,12772,12773,7,18,0,0,12773,12774,7,8,0,0,12774,12775,7,19,0,0,12775,
	12776,7,23,0,0,12776,12777,7,6,0,0,12777,12778,7,7,0,0,12778,12779,7,26,
	0,0,12779,12780,7,6,0,0,12780,2118,1,0,0,0,12781,12782,7,11,0,0,12782,12783,
	7,6,0,0,12783,12784,5,95,0,0,12784,12785,7,25,0,0,12785,12786,7,19,0,0,
	12786,12787,7,15,0,0,12787,12788,7,12,0,0,12788,12789,7,6,0,0,12789,12790,
	7,18,0,0,12790,12791,7,8,0,0,12791,12792,7,19,0,0,12792,12793,7,23,0,0,
	12793,12794,7,9,0,0,12794,12795,7,21,0,0,12795,12796,7,16,0,0,12796,2120,
	1,0,0,0,12797,12798,7,11,0,0,12798,12799,7,6,0,0,12799,12800,5,95,0,0,12800,
	12801,7,25,0,0,12801,12802,7,19,0,0,12802,12803,7,15,0,0,12803,12804,7,
	12,0,0,12804,12805,7,6,0,0,12805,12806,7,12,0,0,12806,2122,1,0,0,0,12807,
	12808,7,11,0,0,12808,12809,7,6,0,0,12809,12810,5,95,0,0,12810,12811,7,25,
	0,0,12811,12812,7,19,0,0,12812,12813,7,5,0,0,12813,12814,7,10,0,0,12814,
	12815,7,18,0,0,12815,12816,7,8,0,0,12816,12817,7,19,0,0,12817,12818,7,23,
	0,0,12818,12819,7,6,0,0,12819,12820,7,7,0,0,12820,12821,7,26,0,0,12821,
	12822,7,6,0,0,12822,2124,1,0,0,0,12823,12824,7,11,0,0,12824,12825,7,6,0,
	0,12825,12826,5,95,0,0,12826,12827,7,25,0,0,12827,12828,7,19,0,0,12828,
	12829,7,5,0,0,12829,12830,7,10,0,0,12830,12831,7,18,0,0,12831,12832,7,8,
	0,0,12832,12833,7,19,0,0,12833,12834,7,23,0,0,12834,12835,7,9,0,0,12835,
	12836,7,21,0,0,12836,12837,7,16,0,0,12837,2126,1,0,0,0,12838,12839,7,11,
	0,0,12839,12840,7,6,0,0,12840,12841,5,95,0,0,12841,12842,7,25,0,0,12842,
	12843,7,19,0,0,12843,12844,7,5,0,0,12844,12845,7,10,0,0,12845,12846,7,22,
	0,0,12846,12847,7,19,0,0,12847,12848,7,12,0,0,12848,12849,7,18,0,0,12849,
	12850,7,8,0,0,12850,12851,7,19,0,0,12851,12852,7,23,0,0,12852,12853,7,6,
	0,0,12853,12854,7,7,0,0,12854,12855,7,26,0,0,12855,12856,7,6,0,0,12856,
	2128,1,0,0,0,12857,12858,7,11,0,0,12858,12859,7,6,0,0,12859,12860,5,95,
	0,0,12860,12861,7,25,0,0,12861,12862,7,19,0,0,12862,12863,7,5,0,0,12863,
	12864,7,10,0,0,12864,12865,7,22,0,0,12865,12866,7,19,0,0,12866,12867,7,
	12,0,0,12867,12868,7,18,0,0,12868,12869,7,8,0,0,12869,12870,7,19,0,0,12870,
	12871,7,23,0,0,12871,12872,7,9,0,0,12872,12873,7,21,0,0,12873,12874,7,16,
	0,0,12874,2130,1,0,0,0,12875,12876,7,11,0,0,12876,12877,7,6,0,0,12877,12878,
	5,95,0,0,12878,12879,7,11,0,0,12879,12880,7,8,0,0,12880,12881,7,15,0,0,
	12881,12882,7,4,0,0,12882,2132,1,0,0,0,12883,12884,7,11,0,0,12884,12885,
	7,6,0,0,12885,12886,5,95,0,0,12886,12887,7,11,0,0,12887,12888,7,6,0,0,12888,
	12889,7,3,0,0,12889,12890,7,8,0,0,12890,12891,7,6,0,0,12891,12892,7,25,
	0,0,12892,12893,7,19,0,0,12893,12894,7,15,0,0,12894,12895,7,12,0,0,12895,
	12896,7,6,0,0,12896,2134,1,0,0,0,12897,12898,7,11,0,0,12898,12899,7,6,0,
	0,12899,12900,5,95,0,0,12900,12901,7,11,0,0,12901,12902,7,10,0,0,12902,
	12903,7,23,0,0,12903,12904,7,4,0,0,12904,12905,7,15,0,0,12905,12906,7,18,
	0,0,12906,12907,7,18,0,0,12907,12908,7,7,0,0,12908,12909,7,8,0,0,12909,
	12910,7,7,0,0,12910,12911,7,12,0,0,12911,12912,7,14,0,0,12912,12913,7,7,
	0,0,12913,2136,1,0,0,0,12914,12915,7,11,0,0,12915,12916,7,6,0,0,12916,12917,
	5,95,0,0,12917,12918,7,6,0,0,12918,12919,7,19,0,0,12919,12920,7,17,0,0,
	12920,12921,7,14,0,0,12921,12922,7,20,0,0,12922,12923,7,7,0,0,12923,12924,
	7,11,0,0,12924,2138,1,0,0,0,12925,12926,7,11,0,0,12926,12927,7,6,0,0,12927,
	12928,5,95,0,0,12928,12929,7,17,0,0,12929,12930,7,12,0,0,12930,12931,7,
	15,0,0,12931,12932,7,19,0,0,12932,12933,7,12,0,0,12933,2140,1,0,0,0,12934,
	12935,7,11,0,0,12935,12936,7,6,0,0,12936,12937,5,95,0,0,12937,12938,7,9,
	0,0,12938,12939,7,15,0,0,12939,12940,7,6,0,0,12940,12941,7,20,0,0,12941,
	12942,7,15,0,0,12942,12943,7,12,0,0,12943,2142,1,0,0,0,12944,12945,7,11,
	0,0,12945,12946,7,6,0,0,12946,12947,5,95,0,0,12947,12948,7,26,0,0,12948,
	2144,1,0,0,0,12949,12950,7,11,0,0,12950,12951,7,6,0,0,12951,12952,5,95,
	0,0,12952,12953,7,10,0,0,12953,2146,1,0,0,0,12954,12955,7,11,0,0,12955,
	12956,7,17,0,0,12956,12957,7,16,0,0,12957,12958,7,4,0,0,12958,12959,7,3,
	0,0,12959,12960,7,6,0,0,12960,12961,7,7,0,0,12961,2148,1,0,0,0,12962,12963,
	7,11,0,0,12963,12964,7,17,0,0,12964,12965,7,16,0,0,12965,12966,7,11,0,0,
	12966,12967,7,6,0,0,12967,12968,7,8,0,0,12968,12969,7,15,0,0,12969,12970,
	7,12,0,0,12970,12971,7,22,0,0,12971,12972,5,95,0,0,12972,12973,7,15,0,0,
	12973,12974,7,12,0,0,12974,12975,7,4,0,0,12975,12976,7,7,0,0,12976,12977,
	7,26,0,0,12977,2150,1,0,0,0,12978,12979,7,11,0,0,12979,12980,7,17,0,0,12980,
	12981,7,16,0,0,12981,12982,7,6,0,0,12982,12983,7,15,0,0,12983,12984,7,23,
	0,0,12984,12985,7,7,0,0,12985,2152,1,0,0,0,12986,12987,7,11,0,0,12987,12988,
	7,10,0,0,12988,12989,7,11,0,0,12989,12990,7,6,0,0,12990,12991,7,7,0,0,12991,
	12992,7,23,0,0,12992,12993,5,95,0,0,12993,12994,7,17,0,0,12994,12995,7,
	11,0,0,12995,12996,7,7,0,0,12996,12997,7,8,0,0,12997,2154,1,0,0,0,12998,
	12999,7,6,0,0,12999,13000,7,3,0,0,13000,13001,7,12,0,0,13001,2156,1,0,0,
	0,13002,13003,7,6,0,0,13003,13004,7,15,0,0,13004,13005,7,23,0,0,13005,13006,
	7,7,0,0,13006,13007,7,4,0,0,13007,13008,7,15,0,0,13008,13009,7,18,0,0,13009,
	13010,7,18,0,0,13010,2158,1,0,0,0,13011,13012,7,6,0,0,13012,13013,7,15,
	0,0,13013,13014,7,23,0,0,13014,13015,7,7,0,0,13015,13016,7,11,0,0,13016,
	13017,7,6,0,0,13017,13018,7,3,0,0,13018,13019,7,23,0,0,13019,13020,7,25,
	0,0,13020,13021,7,3,0,0,13021,13022,7,4,0,0,13022,13023,7,4,0,0,13023,2160,
	1,0,0,0,13024,13025,7,6,0,0,13025,13026,7,15,0,0,13026,13027,7,23,0,0,13027,
	13028,7,7,0,0,13028,13029,7,11,0,0,13029,13030,7,6,0,0,13030,13031,7,3,
	0,0,13031,13032,7,23,0,0,13032,13033,7,25,0,0,13033,13034,7,4,0,0,13034,
	13035,7,15,0,0,13035,13036,7,18,0,0,13036,13037,7,18,0,0,13037,2162,1,0,
	0,0,13038,13039,7,6,0,0,13039,13040,7,15,0,0,13040,13041,7,23,0,0,13041,
	13042,7,7,0,0,13042,13043,5,95,0,0,13043,13044,7,18,0,0,13044,13045,7,19,
	0,0,13045,13046,7,8,0,0,13046,13047,7,23,0,0,13047,13048,7,3,0,0,13048,
	13049,7,6,0,0,13049,2164,1,0,0,0,13050,13051,7,6,0,0,13051,13052,7,15,0,
	0,13052,13053,7,23,0,0,13053,13054,7,7,0,0,13054,13055,5,95,0,0,13055,13056,
	7,6,0,0,13056,13057,7,19,0,0,13057,13058,5,95,0,0,13058,13059,7,11,0,0,
	13059,13060,7,7,0,0,13060,13061,7,14,0,0,13061,2166,1,0,0,0,13062,13063,
	7,6,0,0,13063,13064,7,19,0,0,13064,13065,7,17,0,0,13065,13066,7,14,0,0,
	13066,13067,7,20,0,0,13067,13068,7,7,0,0,13068,13069,7,11,0,0,13069,2168,
	1,0,0,0,13070,13071,7,6,0,0,13071,13072,7,19,0,0,13072,13073,5,95,0,0,13073,
	13074,7,16,0,0,13074,13075,7,3,0,0,13075,13076,7,11,0,0,13076,13077,7,7,
	0,0,13077,13078,5,54,0,0,13078,13079,5,52,0,0,13079,2170,1,0,0,0,13080,
	13081,7,6,0,0,13081,13082,7,19,0,0,13082,13083,5,95,0,0,13083,13084,7,4,
	0,0,13084,13085,7,3,0,0,13085,13086,7,10,0,0,13086,13087,7,11,0,0,13087,
	2172,1,0,0,0,13088,13089,7,6,0,0,13089,13090,7,19,0,0,13090,13091,5,95,
	0,0,13091,13092,7,11,0,0,13092,13093,7,7,0,0,13093,13094,7,14,0,0,13094,
	13095,7,19,0,0,13095,13096,7,12,0,0,13096,13097,7,4,0,0,13097,13098,7,11,
	0,0,13098,2174,1,0,0,0,13099,13100,7,6,0,0,13100,13101,7,25,0,0,13101,13102,
	5,95,0,0,13102,13103,7,14,0,0,13103,13104,7,19,0,0,13104,13105,7,12,0,0,
	13105,13106,7,12,0,0,13106,13107,7,7,0,0,13107,13108,7,14,0,0,13108,13109,
	7,6,0,0,13109,13110,7,15,0,0,13110,13111,7,19,0,0,13111,13112,7,12,0,0,
	13112,13113,5,95,0,0,13113,13114,7,3,0,0,13114,13115,7,4,0,0,13115,13116,
	7,23,0,0,13116,13117,7,15,0,0,13117,13118,7,12,0,0,13118,2176,1,0,0,0,13119,
	13120,7,17,0,0,13120,13121,7,14,0,0,13121,13122,7,3,0,0,13122,13123,7,11,
	0,0,13123,13124,7,7,0,0,13124,2178,1,0,0,0,13125,13126,7,17,0,0,13126,13127,
	7,12,0,0,13127,13128,7,14,0,0,13128,13129,7,19,0,0,13129,13130,7,23,0,0,
	13130,13131,7,25,0,0,13131,13132,7,8,0,0,13132,13133,7,7,0,0,13133,13134,
	7,11,0,0,13134,13135,7,11,0,0,13135,2180,1,0,0,0,13136,13137,7,17,0,0,13137,
	13138,7,12,0,0,13138,13139,7,14,0,0,13139,13140,7,19,0,0,13140,13141,7,
	23,0,0,13141,13142,7,25,0,0,13142,13143,7,8,0,0,13143,13144,7,7,0,0,13144,
	13145,7,11,0,0,13145,13146,7,11,0,0,13146,13147,7,7,0,0,13147,13148,7,4,
	0,0,13148,13149,5,95,0,0,13149,13150,7,5,0,0,13150,13151,7,7,0,0,13151,
	13152,7,12,0,0,13152,13153,7,22,0,0,13153,13154,7,6,0,0,13154,13155,7,20,
	0,0,13155,2182,1,0,0,0,13156,13157,7,17,0,0,13157,13158,7,12,0,0,13158,
	13159,7,20,0,0,13159,13160,7,7,0,0,13160,13161,7,26,0,0,13161,2184,1,0,
	0,0,13162,13163,7,17,0,0,13163,13164,7,12,0,0,13164,13165,7,15,0,0,13165,
	13166,7,26,0,0,13166,13167,5,95,0,0,13167,13168,7,6,0,0,13168,13169,7,15,
	0,0,13169,13170,7,23,0,0,13170,13171,7,7,0,0,13171,13172,7,11,0,0,13172,
	13173,7,6,0,0,13173,13174,7,3,0,0,13174,13175,7,23,0,0,13175,13176,7,25,
	0,0,13176,2186,1,0,0,0,13177,13178,7,17,0,0,13178,13179,7,25,0,0,13179,
	13180,7,4,0,0,13180,13181,7,3,0,0,13181,13182,7,6,0,0,13182,13183,7,7,0,
	0,13183,13184,7,26,0,0,13184,13185,7,23,0,0,13185,13186,7,5,0,0,13186,2188,
	1,0,0,0,13187,13188,7,17,0,0,13188,13189,7,25,0,0,13189,13190,7,25,0,0,
	13190,13191,7,7,0,0,13191,13192,7,8,0,0,13192,2190,1,0,0,0,13193,13194,
	7,17,0,0,13194,13195,7,17,0,0,13195,13196,7,15,0,0,13196,13197,7,4,0,0,
	13197,2192,1,0,0,0,13198,13199,7,17,0,0,13199,13200,7,17,0,0,13200,13201,
	7,15,0,0,13201,13202,7,4,0,0,13202,13203,5,95,0,0,13203,13204,7,11,0,0,
	13204,13205,7,20,0,0,13205,13206,7,19,0,0,13206,13207,7,8,0,0,13207,13208,
	7,6,0,0,13208,2194,1,0,0,0,13209,13210,7,24,0,0,13210,13211,7,3,0,0,13211,
	13212,7,5,0,0,13212,13213,7,15,0,0,13213,13214,7,4,0,0,13214,13215,7,3,
	0,0,13215,13216,7,6,0,0,13216,13217,7,7,0,0,13217,13218,5,95,0,0,13218,
	13219,7,25,0,0,13219,13220,7,3,0,0,13220,13221,7,11,0,0,13221,13222,7,11,
	0,0,13222,13223,7,9,0,0,13223,13224,7,19,0,0,13224,13225,7,8,0,0,13225,
	13226,7,4,0,0,13226,13227,5,95,0,0,13227,13228,7,11,0,0,13228,13229,7,6,
	0,0,13229,13230,7,8,0,0,13230,13231,7,7,0,0,13231,13232,7,12,0,0,13232,
	13233,7,22,0,0,13233,13234,7,6,0,0,13234,13235,7,20,0,0,13235,2196,1,0,
	0,0,13236,13237,7,24,0,0,13237,13238,7,7,0,0,13238,13239,7,8,0,0,13239,
	13240,7,11,0,0,13240,13241,7,15,0,0,13241,13242,7,19,0,0,13242,13243,7,
	12,0,0,13243,2198,1,0,0,0,13244,13245,7,9,0,0,13245,13246,7,3,0,0,13246,
	13247,7,15,0,0,13247,13248,7,6,0,0,13248,13249,5,95,0,0,13249,13250,7,17,
	0,0,13250,13251,7,12,0,0,13251,13252,7,6,0,0,13252,13253,7,15,0,0,13253,
	13254,7,5,0,0,13254,13255,5,95,0,0,13255,13256,7,11,0,0,13256,13257,7,28,
	0,0,13257,13258,7,5,0,0,13258,13259,5,95,0,0,13259,13260,7,6,0,0,13260,
	13261,7,20,0,0,13261,13262,7,8,0,0,13262,13263,7,7,0,0,13263,13264,7,3,
	0,0,13264,13265,7,4,0,0,13265,13266,5,95,0,0,13266,13267,7,3,0,0,13267,
	13268,7,18,0,0,13268,13269,7,6,0,0,13269,13270,7,7,0,0,13270,13271,7,8,
	0,0,13271,13272,5,95,0,0,13272,13273,7,22,0,0,13273,13274,7,6,0,0,13274,
	13275,7,15,0,0,13275,13276,7,4,0,0,13276,13277,7,11,0,0,13277,2200,1,0,
	0,0,13278,13279,7,9,0,0,13279,13280,7,7,0,0,13280,13281,7,7,0,0,13281,13282,
	7,21,0,0,13282,13283,7,4,0,0,13283,13284,7,3,0,0,13284,13285,7,10,0,0,13285,
	2202,1,0,0,0,13286,13287,7,9,0,0,13287,13288,7,7,0,0,13288,13289,7,7,0,
	0,13289,13290,7,21,0,0,13290,13291,7,19,0,0,13291,13292,7,18,0,0,13292,
	13293,7,10,0,0,13293,13294,7,7,0,0,13294,13295,7,3,0,0,13295,13296,7,8,
	0,0,13296,2204,1,0,0,0,13297,13298,7,9,0,0,13298,13299,7,7,0,0,13299,13300,
	7,15,0,0,13300,13301,7,22,0,0,13301,13302,7,20,0,0,13302,13303,7,6,0,0,
	13303,13304,5,95,0,0,13304,13305,7,11,0,0,13305,13306,7,6,0,0,13306,13307,
	7,8,0,0,13307,13308,7,15,0,0,13308,13309,7,12,0,0,13309,13310,7,22,0,0,
	13310,2206,1,0,0,0,13311,13312,7,9,0,0,13312,13313,7,15,0,0,13313,13314,
	7,6,0,0,13314,13315,7,20,0,0,13315,13316,7,15,0,0,13316,13317,7,12,0,0,
	13317,2208,1,0,0,0,13318,13319,7,10,0,0,13319,13320,7,7,0,0,13320,13321,
	7,3,0,0,13321,13322,7,8,0,0,13322,13323,7,9,0,0,13323,13324,7,7,0,0,13324,
	13325,7,7,0,0,13325,13326,7,21,0,0,13326,2210,1,0,0,0,13327,13328,7,10,
	0,0,13328,2212,1,0,0,0,13329,13330,7,26,0,0,13330,2214,1,0,0,0,13331,13332,
	5,58,0,0,13332,13333,5,61,0,0,13333,2216,1,0,0,0,13334,13335,5,43,0,0,13335,
	13336,5,61,0,0,13336,2218,1,0,0,0,13337,13338,5,45,0,0,13338,13339,5,61,
	0,0,13339,2220,1,0,0,0,13340,13341,5,42,0,0,13341,13342,5,61,0,0,13342,
	2222,1,0,0,0,13343,13344,5,47,0,0,13344,13345,5,61,0,0,13345,2224,1,0,0,
	0,13346,13347,5,37,0,0,13347,13348,5,61,0,0,13348,2226,1,0,0,0,13349,13350,
	5,38,0,0,13350,13351,5,61,0,0,13351,2228,1,0,0,0,13352,13353,5,94,0,0,13353,
	13354,5,61,0,0,13354,2230,1,0,0,0,13355,13356,5,124,0,0,13356,13357,5,61,
	0,0,13357,2232,1,0,0,0,13358,13359,5,42,0,0,13359,2234,1,0,0,0,13360,13361,
	5,47,0,0,13361,2236,1,0,0,0,13362,13363,5,37,0,0,13363,2238,1,0,0,0,13364,
	13365,5,43,0,0,13365,2240,1,0,0,0,13366,13367,5,45,0,0,13367,2242,1,0,0,
	0,13368,13369,7,4,0,0,13369,13370,7,15,0,0,13370,13371,7,24,0,0,13371,2244,
	1,0,0,0,13372,13373,7,23,0,0,13373,13374,7,19,0,0,13374,13375,7,4,0,0,13375,
	2246,1,0,0,0,13376,13377,5,61,0,0,13377,2248,1,0,0,0,13378,13379,5,62,0,
	0,13379,2250,1,0,0,0,13380,13381,5,60,0,0,13381,2252,1,0,0,0,13382,13383,
	5,33,0,0,13383,2254,1,0,0,0,13384,13385,5,126,0,0,13385,2256,1,0,0,0,13386,
	13387,5,124,0,0,13387,2258,1,0,0,0,13388,13389,5,38,0,0,13389,2260,1,0,
	0,0,13390,13391,5,94,0,0,13391,2262,1,0,0,0,13392,13393,5,46,0,0,13393,
	2264,1,0,0,0,13394,13395,5,40,0,0,13395,2266,1,0,0,0,13396,13397,5,41,0,
	0,13397,2268,1,0,0,0,13398,13399,5,44,0,0,13399,2270,1,0,0,0,13400,13401,
	5,59,0,0,13401,2272,1,0,0,0,13402,13403,5,64,0,0,13403,2274,1,0,0,0,13404,
	13405,5,48,0,0,13405,2276,1,0,0,0,13406,13407,5,49,0,0,13407,2278,1,0,0,
	0,13408,13409,5,50,0,0,13409,2280,1,0,0,0,13410,13411,5,39,0,0,13411,2282,
	1,0,0,0,13412,13413,5,34,0,0,13413,2284,1,0,0,0,13414,13415,5,96,0,0,13415,
	2286,1,0,0,0,13416,13417,5,58,0,0,13417,2288,1,0,0,0,13418,13422,3,2281,
	1140,0,13419,13422,3,2283,1141,0,13420,13422,3,2285,1142,0,13421,13418,
	1,0,0,0,13421,13419,1,0,0,0,13421,13420,1,0,0,0,13422,2290,1,0,0,0,13423,
	13424,5,96,0,0,13424,13425,3,2323,1161,0,13425,13426,5,96,0,0,13426,2292,
	1,0,0,0,13427,13429,3,2337,1168,0,13428,13427,1,0,0,0,13429,13430,1,0,0,
	0,13430,13428,1,0,0,0,13430,13431,1,0,0,0,13431,13432,1,0,0,0,13432,13433,
	7,29,0,0,13433,2294,1,0,0,0,13434,13435,7,12,0,0,13435,13436,3,2331,1165,
	0,13436,2296,1,0,0,0,13437,13441,3,2329,1164,0,13438,13441,3,2331,1165,
	0,13439,13441,3,2333,1166,0,13440,13437,1,0,0,0,13440,13438,1,0,0,0,13440,
	13439,1,0,0,0,13441,2298,1,0,0,0,13442,13444,3,2337,1168,0,13443,13442,
	1,0,0,0,13444,13445,1,0,0,0,13445,13443,1,0,0,0,13445,13446,1,0,0,0,13446,
	2300,1,0,0,0,13447,13448,7,26,0,0,13448,13452,5,39,0,0,13449,13450,3,2335,
	1167,0,13450,13451,3,2335,1167,0,13451,13453,1,0,0,0,13452,13449,1,0,0,
	0,13453,13454,1,0,0,0,13454,13452,1,0,0,0,13454,13455,1,0,0,0,13455,13456,
	1,0,0,0,13456,13457,5,39,0,0,13457,13467,1,0,0,0,13458,13459,5,48,0,0,13459,
	13460,7,26,0,0,13460,13462,1,0,0,0,13461,13463,3,2335,1167,0,13462,13461,
	1,0,0,0,13463,13464,1,0,0,0,13464,13462,1,0,0,0,13464,13465,1,0,0,0,13465,
	13467,1,0,0,0,13466,13447,1,0,0,0,13466,13458,1,0,0,0,13467,2302,1,0,0,
	0,13468,13470,3,2337,1168,0,13469,13468,1,0,0,0,13470,13473,1,0,0,0,13471,
	13469,1,0,0,0,13471,13472,1,0,0,0,13472,13474,1,0,0,0,13473,13471,1,0,0,
	0,13474,13476,5,46,0,0,13475,13477,3,2337,1168,0,13476,13475,1,0,0,0,13477,
	13478,1,0,0,0,13478,13476,1,0,0,0,13478,13479,1,0,0,0,13479,13510,1,0,0,
	0,13480,13482,3,2337,1168,0,13481,13480,1,0,0,0,13482,13483,1,0,0,0,13483,
	13481,1,0,0,0,13483,13484,1,0,0,0,13484,13485,1,0,0,0,13485,13486,5,46,
	0,0,13486,13487,3,2325,1162,0,13487,13510,1,0,0,0,13488,13490,3,2337,1168,
	0,13489,13488,1,0,0,0,13490,13493,1,0,0,0,13491,13489,1,0,0,0,13491,13492,
	1,0,0,0,13492,13494,1,0,0,0,13493,13491,1,0,0,0,13494,13496,5,46,0,0,13495,
	13497,3,2337,1168,0,13496,13495,1,0,0,0,13497,13498,1,0,0,0,13498,13496,
	1,0,0,0,13498,13499,1,0,0,0,13499,13500,1,0,0,0,13500,13501,3,2325,1162,
	0,13501,13510,1,0,0,0,13502,13504,3,2337,1168,0,13503,13502,1,0,0,0,13504,
	13505,1,0,0,0,13505,13503,1,0,0,0,13505,13506,1,0,0,0,13506,13507,1,0,0,
	0,13507,13508,3,2325,1162,0,13508,13510,1,0,0,0,13509,13471,1,0,0,0,13509,
	13481,1,0,0,0,13509,13491,1,0,0,0,13509,13503,1,0,0,0,13510,2304,1,0,0,
	0,13511,13512,5,92,0,0,13512,13513,7,12,0,0,13513,2306,1,0,0,0,13514,13515,
	3,2339,1169,0,13515,2308,1,0,0,0,13516,13517,5,95,0,0,13517,13518,3,2323,
	1161,0,13518,2310,1,0,0,0,13519,13520,5,46,0,0,13520,13521,3,2327,1163,
	0,13521,2312,1,0,0,0,13522,13523,3,2327,1163,0,13523,2314,1,0,0,0,13524,
	13525,3,2333,1166,0,13525,2316,1,0,0,0,13526,13527,3,2273,1136,0,13527,
	13528,3,2341,1170,0,13528,2318,1,0,0,0,13529,13536,3,2273,1136,0,13530,
	13537,3,2297,1148,0,13531,13533,7,30,0,0,13532,13531,1,0,0,0,13533,13534,
	1,0,0,0,13534,13532,1,0,0,0,13534,13535,1,0,0,0,13535,13537,1,0,0,0,13536,
	13530,1,0,0,0,13536,13532,1,0,0,0,13537,2320,1,0,0,0,13538,13539,3,2273,
	1136,0,13539,13546,3,2273,1136,0,13540,13542,7,30,0,0,13541,13540,1,0,0,
	0,13542,13543,1,0,0,0,13543,13541,1,0,0,0,13543,13544,1,0,0,0,13544,13547,
	1,0,0,0,13545,13547,3,2333,1166,0,13546,13541,1,0,0,0,13546,13545,1,0,0,
	0,13547,2322,1,0,0,0,13548,13590,3,1491,745,0,13549,13590,3,1493,746,0,
	13550,13590,3,1495,747,0,13551,13590,3,451,225,0,13552,13590,3,1497,748,
	0,13553,13590,3,1499,749,0,13554,13590,3,1501,750,0,13555,13590,3,1503,
	751,0,13556,13590,3,1505,752,0,13557,13590,3,1507,753,0,13558,13590,3,1509,
	754,0,13559,13590,3,1511,755,0,13560,13590,3,1513,756,0,13561,13590,3,1515,
	757,0,13562,13590,3,1517,758,0,13563,13590,3,1521,760,0,13564,13590,3,1523,
	761,0,13565,13590,3,1525,762,0,13566,13590,3,1527,763,0,13567,13590,3,1529,
	764,0,13568,13590,3,1531,765,0,13569,13590,3,1533,766,0,13570,13590,3,1535,
	767,0,13571,13590,3,1537,768,0,13572,13590,3,1539,769,0,13573,13590,3,1541,
	770,0,13574,13590,3,1543,771,0,13575,13590,3,1545,772,0,13576,13590,3,1547,
	773,0,13577,13590,3,1549,774,0,13578,13590,3,1551,775,0,13579,13590,3,1553,
	776,0,13580,13590,3,1555,777,0,13581,13590,3,1557,778,0,13582,13590,3,1559,
	779,0,13583,13590,3,1561,780,0,13584,13590,3,1563,781,0,13585,13590,3,1565,
	782,0,13586,13590,3,1567,783,0,13587,13590,3,1569,784,0,13588,13590,3,1571,
	785,0,13589,13548,1,0,0,0,13589,13549,1,0,0,0,13589,13550,1,0,0,0,13589,
	13551,1,0,0,0,13589,13552,1,0,0,0,13589,13553,1,0,0,0,13589,13554,1,0,0,
	0,13589,13555,1,0,0,0,13589,13556,1,0,0,0,13589,13557,1,0,0,0,13589,13558,
	1,0,0,0,13589,13559,1,0,0,0,13589,13560,1,0,0,0,13589,13561,1,0,0,0,13589,
	13562,1,0,0,0,13589,13563,1,0,0,0,13589,13564,1,0,0,0,13589,13565,1,0,0,
	0,13589,13566,1,0,0,0,13589,13567,1,0,0,0,13589,13568,1,0,0,0,13589,13569,
	1,0,0,0,13589,13570,1,0,0,0,13589,13571,1,0,0,0,13589,13572,1,0,0,0,13589,
	13573,1,0,0,0,13589,13574,1,0,0,0,13589,13575,1,0,0,0,13589,13576,1,0,0,
	0,13589,13577,1,0,0,0,13589,13578,1,0,0,0,13589,13579,1,0,0,0,13589,13580,
	1,0,0,0,13589,13581,1,0,0,0,13589,13582,1,0,0,0,13589,13583,1,0,0,0,13589,
	13584,1,0,0,0,13589,13585,1,0,0,0,13589,13586,1,0,0,0,13589,13587,1,0,0,
	0,13589,13588,1,0,0,0,13590,2324,1,0,0,0,13591,13593,7,7,0,0,13592,13594,
	7,31,0,0,13593,13592,1,0,0,0,13593,13594,1,0,0,0,13594,13596,1,0,0,0,13595,
	13597,3,2337,1168,0,13596,13595,1,0,0,0,13597,13598,1,0,0,0,13598,13596,
	1,0,0,0,13598,13599,1,0,0,0,13599,2326,1,0,0,0,13600,13602,7,32,0,0,13601,
	13600,1,0,0,0,13602,13605,1,0,0,0,13603,13604,1,0,0,0,13603,13601,1,0,0,
	0,13604,13607,1,0,0,0,13605,13603,1,0,0,0,13606,13608,7,33,0,0,13607,13606,
	1,0,0,0,13608,13609,1,0,0,0,13609,13610,1,0,0,0,13609,13607,1,0,0,0,13610,
	13614,1,0,0,0,13611,13613,7,32,0,0,13612,13611,1,0,0,0,13613,13616,1,0,
	0,0,13614,13612,1,0,0,0,13614,13615,1,0,0,0,13615,2328,1,0,0,0,13616,13614,
	1,0,0,0,13617,13625,5,34,0,0,13618,13619,5,92,0,0,13619,13624,9,0,0,0,13620,
	13621,5,34,0,0,13621,13624,5,34,0,0,13622,13624,8,34,0,0,13623,13618,1,
	0,0,0,13623,13620,1,0,0,0,13623,13622,1,0,0,0,13624,13627,1,0,0,0,13625,
	13623,1,0,0,0,13625,13626,1,0,0,0,13626,13628,1,0,0,0,13627,13625,1,0,0,
	0,13628,13629,5,34,0,0,13629,2330,1,0,0,0,13630,13638,5,39,0,0,13631,13632,
	5,92,0,0,13632,13637,9,0,0,0,13633,13634,5,39,0,0,13634,13637,5,39,0,0,
	13635,13637,8,35,0,0,13636,13631,1,0,0,0,13636,13633,1,0,0,0,13636,13635,
	1,0,0,0,13637,13640,1,0,0,0,13638,13636,1,0,0,0,13638,13639,1,0,0,0,13639,
	13641,1,0,0,0,13640,13638,1,0,0,0,13641,13642,5,39,0,0,13642,2332,1,0,0,
	0,13643,13649,5,96,0,0,13644,13648,8,36,0,0,13645,13646,5,96,0,0,13646,
	13648,5,96,0,0,13647,13644,1,0,0,0,13647,13645,1,0,0,0,13648,13651,1,0,
	0,0,13649,13647,1,0,0,0,13649,13650,1,0,0,0,13650,13652,1,0,0,0,13651,13649,
	1,0,0,0,13652,13653,5,96,0,0,13653,2334,1,0,0,0,13654,13655,7,37,0,0,13655,
	2336,1,0,0,0,13656,13657,7,38,0,0,13657,2338,1,0,0,0,13658,13659,7,16,0,
	0,13659,13661,5,39,0,0,13660,13662,7,39,0,0,13661,13660,1,0,0,0,13662,13663,
	1,0,0,0,13663,13661,1,0,0,0,13663,13664,1,0,0,0,13664,13665,1,0,0,0,13665,
	13666,5,39,0,0,13666,2340,1,0,0,0,13667,13669,7,38,0,0,13668,13667,1,0,
	0,0,13669,13670,1,0,0,0,13670,13668,1,0,0,0,13670,13671,1,0,0,0,13671,13672,
	1,0,0,0,13672,13674,5,46,0,0,13673,13675,7,40,0,0,13674,13673,1,0,0,0,13675,
	13676,1,0,0,0,13676,13674,1,0,0,0,13676,13677,1,0,0,0,13677,13698,1,0,0,
	0,13678,13680,7,37,0,0,13679,13678,1,0,0,0,13680,13683,1,0,0,0,13681,13679,
	1,0,0,0,13681,13682,1,0,0,0,13682,13684,1,0,0,0,13683,13681,1,0,0,0,13684,
	13688,5,58,0,0,13685,13687,7,37,0,0,13686,13685,1,0,0,0,13687,13690,1,0,
	0,0,13688,13686,1,0,0,0,13688,13689,1,0,0,0,13689,13691,1,0,0,0,13690,13688,
	1,0,0,0,13691,13693,5,58,0,0,13692,13694,7,41,0,0,13693,13692,1,0,0,0,13694,
	13695,1,0,0,0,13695,13693,1,0,0,0,13695,13696,1,0,0,0,13696,13698,1,0,0,
	0,13697,13668,1,0,0,0,13697,13681,1,0,0,0,13698,2342,1,0,0,0,13699,13700,
	9,0,0,0,13700,13701,1,0,0,0,13701,13702,6,1171,2,0,13702,2344,1,0,0,0,51,
	0,2348,2359,2372,2386,2390,2395,2399,2403,2409,2413,2415,9261,9288,13421,
	13430,13440,13445,13454,13464,13466,13471,13478,13483,13491,13498,13505,
	13509,13534,13536,13543,13546,13589,13593,13598,13603,13609,13614,13623,
	13625,13636,13638,13647,13649,13663,13670,13676,13681,13688,13695,13697,
	3,0,1,0,0,2,0,0,3,0];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!MySqlLexer.__ATN) {
			MySqlLexer.__ATN = new ATNDeserializer().deserialize(MySqlLexer._serializedATN);
		}

		return MySqlLexer.__ATN;
	}


	static DecisionsToDFA = MySqlLexer._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );
}