import {
  useEffect,
  useRef,
  useCallback,
  createContext,
  useContext,
} from 'react'
import * as monaco from 'monaco-editor'
import { listen, MessageConnection } from 'vscode-ws-jsonrpc'
import {
  MonacoLanguageClient,
  CloseAction,
  ErrorAction,
  MonacoServices,
  createConnection,
} from 'monaco-languageclient'
import { DataSourceType } from 'src/types'
import { editor, languages } from 'monaco-editor'
import {
  enhanceLanguageClient,
  getSqlRange,
  IEnhancedMonacoLanguageClient,
} from './enhanceLanguageClient'
import {
  language as LSP_LANGUAGE,
  conf as LSP_LANGUAGE_CONFIG,
} from 'monaco-editor/esm/vs/basic-languages/sql/sql.js'
interface IUseLSP {
  editorInstance: editor.IStandaloneCodeEditor | null
}

export const LSP_LANGUAGE_ID = 'mydsl'
//有问题 应该是不支持LSP 
export const DATASOURCE_SURPPORT_LSP = [
  'SQLServer',
  'MySQL',
  'Oracle',
  'OracleCDB',
  'PostgreSQL',
  'KingBasePG',
  'KingBaseOracle',
  'DamengDB',
  'Vertica',
  'Trino',
  'Impala',
  'Hive',
  'DB2',
  'MogDB',
  'StarRocks',
  'GBase',
  'KingBasePG',
  'KingBaseOracle',
  'Oscar',
  'HANA',
  'StarRocks',
]
export const TOKENIZE_LANGUAGE = 'sql'

// register Monaco languages
monaco.languages.register({
  id: LSP_LANGUAGE_ID,
})

monaco.languages.setMonarchTokensProvider(LSP_LANGUAGE_ID, LSP_LANGUAGE)
monaco.languages.setLanguageConfiguration(LSP_LANGUAGE_ID, LSP_LANGUAGE_CONFIG)

export const getModelLanguageId = (connectionType: DataSourceType) => {
  return DATASOURCE_SURPPORT_LSP.includes(connectionType)
    ? LSP_LANGUAGE_ID
    : TOKENIZE_LANGUAGE
}

export const useLSP = (props: IUseLSP) => {
  const { editorInstance } = props

  const languageClientRef = useRef<IEnhancedMonacoLanguageClient | undefined>()
  const wsRef = useRef<WebSocket | undefined>()
  const disposeRef = useRef<any | undefined>()
  const heartbeatRef = useRef<any | undefined>()

  const initializeLSP = useCallback(() => {
    if (wsRef.current || !editorInstance) return
    console.log(`initialize LSP`)
    const ws = createWebsocket()
    wsRef.current = ws
    // 0 托管 editor实例
    MonacoServices.install(monaco)
    listen({
      webSocket: ws,
      onConnection: (connection) => {
        // 1 启动client
        const languageClient = enhanceLanguageClient(
          createLanguageClient(connection),
          editorInstance,
        )
        const disposable = languageClient.start()
        disposeRef.current = disposable
        // 2 心跳
        languageClient.onReady().then(() => {
          languageClientRef.current = languageClient
          heartbeatRef.current = setInterval(() => {
            languageClient.sendNotification(`heartbeat`)
          }, 30000)
        })
        // 3 连接关闭尝试重连
        connection.onClose(() => {
          console.log(`连接关闭啦`)
          if (wsRef.current && languageClientRef.current) {
            diposeLSP()
            // initializeLSP()
          }
        })
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorInstance])

  const clearHeartbeat = () => {
    if (heartbeatRef.current) {
      clearInterval(heartbeatRef.current)
    }
  }

  const diposeLSP = () => {
    console.log(`dispose LSP`)
    clearHeartbeat()
    disposeRef.current?.dispose()
    languageClientRef.current = undefined
    wsRef.current?.close()
    wsRef.current = undefined
    disposeRef.current = undefined
    heartbeatRef.current = undefined
  }

  useEffect(() => {
    if (!editorInstance) return
    initializeLSP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorInstance, initializeLSP])

  useEffect(() => {
    return () => {
      clearHeartbeat()
      diposeLSP()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { languageClient: languageClientRef.current }
}

const createWebsocket = () => {
  const protocal = window.location.protocol
  const wsCheck = protocal === 'https:' ? 'wss' : 'ws'
  const url = `${wsCheck}://${window.location.host}/lspserver`
  // const url = `ws://192.168.3.75:4389/lspserver`
  const ws = new WebSocket(url)
  return ws
}

function createLanguageClient(
  connection: MessageConnection,
): MonacoLanguageClient {
  const client = new MonacoLanguageClient({
    name: 'Language Client',
    clientOptions: {
      documentSelector: [LSP_LANGUAGE_ID],
      errorHandler: {
        error: () => ErrorAction.Continue,
        closed: () => CloseAction.DoNotRestart,
      },
      middleware: {
        provideCompletionItem: (
          document,
          position,
          context,
          token,
          provideCompletionItems,
        ) => {
          if (!languages.getEncodedLanguageId(TOKENIZE_LANGUAGE)) {
            languages.register({ id: TOKENIZE_LANGUAGE })
          }
          const result = getSqlRange(document, position)
          context.statementRange = result
          return provideCompletionItems(document, position, context, token)
        },
      },
    },
    connectionProvider: {
      get: (errorHandler: any, closeHandler: any) => {
        return Promise.resolve(
          createConnection(connection as any, errorHandler, closeHandler),
        )
      },
    },
  })
  return client
}

/**
 * @description hack executeCommandProvider 指令，调用 editor._commandService.addCommand
 * editor._commandService 属于内部方法，并且 0.21.1版本 该对象上无 addCommand 方法
 * 当前对应的实现是  [editorInstance].addCommand   addCommand(keybinding: number, handler: ICommandHandler, context?: string): string
 * 需要对应 executeCommandProvider commandName 与 keybinding
 * https://github.com/Microsoft/monaco-editor/issues/900
 * @export
 * @param {*} instance
 */

const commandsMap: { [id: string]: number } = {}

export function hackAddCommand(
  instance: editor.IStandaloneCodeEditor & { _commandService: any },
) {
  instance._commandService.addCommand = ({
    id,
    handler,
  }: {
    id: string
    handler: any
  }) => {
    const key = commandsMap[id]
    if (!key) {
      console.log(`${id} keycode map error`)
      return
    }
    instance.addCommand(key, handler)
  }
}

// languageClient为全局唯一实例，共享给编辑器业务组件发送自定义事件
interface ILanguageClientContext {
  languageClient: IEnhancedMonacoLanguageClient | undefined
}

export const LanguageClientContext = createContext<ILanguageClientContext>({
  languageClient: undefined,
})

export const useLanguageClientContext = () => {
  const { languageClient } = useContext(LanguageClientContext)
  return languageClient
}
