import React, { useCallback, useEffect, useState } from 'react'
import {
  useDispatch,
  useQuery,
  useRequest,
  useResizeObserver,
  useSelector,
} from 'src/hook'
import { Button, Card, Table, Select, Space } from 'antd'
import { LinkButton } from 'src/components'
import type { FlowDetailFields, FlowTableRecord, FlowType } from 'src/types'
import { ColumnsType } from 'antd/lib/table'
import { getFlowDetails, getMyApplications } from 'src/api'
import { useHistory } from 'react-router-dom'
import { flowTypeLabelMap, statusOptions } from './constants'
import { ModalFlowDetail } from './flowDetails'
import { renderStatusTag } from './utils/renderStatusTag'
import { openFlowForm } from './flowFormsSlice'
import { getFieldsFromResponse } from './utils'
import dayjs from 'dayjs'
import styles from './flowPage.module.scss'

export const FlowMyApplication = React.memo(() => {
  const userId = useSelector((state) => state.login.userInfo.userId)
  const dispatch = useDispatch()
  // 流程详情 当前 流程 record记录
  const [activeRecord, setActiveRecord] = useState<any>()

  const { run: fetchFlowDetail } = useRequest(getFlowDetails, {
    manual: true,
  })

  const handleApplyAgain = useCallback(
    async (applyId: string, flowType: FlowType) => {
      try {
        // TODO  添加taskId值flowTaskId
        const response = await fetchFlowDetail({ flowId: applyId, taskId: '' })
        dispatch(openFlowForm(getFieldsFromResponse(response)))
      } catch {
        dispatch(openFlowForm({ type: flowType }))
      }
    },
    [dispatch, fetchFlowDetail],
  )

  // 流程详情 modal visible
  const [visible, setVisible] = useState(false)
  // 流程详情 当前 流程 id
  const [activeFlowId, setActiveFlowId] = useState<string>()

  const columns: ColumnsType<FlowTableRecord> = [
    { dataIndex: 'uuid', title: '编号', width: 168, ellipsis: true },
    {
      dataIndex: 'applyType',
      title: '类型',
      render: (_, { applyType }) => flowTypeLabelMap[applyType as FlowType],
      width: 114,
      ellipsis: true,
    },
    {
      dataIndex: 'currentAssignee',
      title: '审批节点',
      width: 72,
      ellipsis: true,
    },
    {
      dataIndex: 'applyTime',
      title: '发起时间',
      render: (_, { applyTime }) =>
        dayjs(applyTime).format('YYYY-MM-DD HH:mm:ss'),
      width: 114,
    },
    {
      dataIndex: 'lastOperatingTime',
      title: '最后操作时间',
      render: (_, { lastOperatingTime }) =>
        dayjs(lastOperatingTime).format('YYYY-MM-DD HH:mm:ss'),
      width: 114,
    },
    {
      dataIndex: 'applyStatus',
      title: '状态',
      render: (_, { applyStatus }) => renderStatusTag(applyStatus),
      width: 72,
      ellipsis: true,
    },
    {
      key: 'action',
      title: '操作',
      render: (_, record) => {
        let { applyId, applyStatus, applyType } = record
        return (
          <Space size="middle">
            <LinkButton
              className={styles.linkButton}
              onClick={() => {
                setActiveFlowId(applyId as string)
                setActiveRecord(record)
                setVisible(true)
              }}
            >
              查看过程
            </LinkButton>
            {['refuse', 'withdraw'].includes(applyStatus as string) && (
              <Button
                className={styles.successBtn}
                onClick={() =>
                  handleApplyAgain(applyId as string, applyType as FlowType)
                }
                size="small"
              >
                再次申请
              </Button>
            )}
          </Space>
        )
      },
      width: 144,
    },
  ]

  // search params
  const history = useHistory()
  const search = useQuery()
  const currentPage = Number(search.get('p')?.toString()) || 1
  const pageSize = Number(search.get('s')?.toString()) || 10
  const [statusFilter, setStatusFilter] = useState<string>()

  // request datasource
  const { data, loading, refresh, run } = useRequest(getMyApplications, {
    manual: true,
    formatResult: (data) => {
      const list = data.myApplyResponseVoList
      const total = data.totalElements
      return { list, total }
    },
    refreshOnWindowFocus: true,
    focusTimespan: 600000,
  })
  useEffect(() => {
    if (userId) {
      const applyStatus = statusFilter !== '*' ? statusFilter : undefined
      run({
        userId,
        pageSize,
        currentPage,
        applyStatus,
      })
    }
  }, [currentPage, pageSize, run, statusFilter, userId])

  const handleChangePagination = (current: number, pageSize?: number) => {
    search.set('p', current.toString())
    if (pageSize) {
      search.set('s', pageSize.toString())
    }
    history.replace({ search: search.toString() })
  }

  // ? ant table dom 层级很多，纵向滚动通过 css 精确计算很麻烦；
  // ? 这里用 resize obserber 监听到的值粗略减去表头和分页器，作为 y 轴 max-height
  const [rect, domRef] = useResizeObserver()

  return (
    <Card
      className={styles.contentCard}
      title="我的申请"
      bordered={false}
      bodyStyle={{ padding: 0, flex: 1, overflow: 'hidden' }}
    >
      <div className={styles.contentCardBody} ref={domRef}>
        <div className={styles.searchArea}>
          <Select
            className={styles.searchSelect}
            value={statusFilter}
            placeholder="审批状态"
            options={statusOptions}
            onChange={setStatusFilter}
          />
        </div>
        <Table<FlowTableRecord>
          className={styles.table}
          columns={columns}
          rowKey={({ applyId }) => applyId}
          dataSource={data?.list}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize,
            total: data?.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: handleChangePagination,
          }}
          size="middle"
          scroll={{ x: rect?.width, y: rect ? rect.height - 200 : 0 }}
        />
      </div>
      {/* 流程详情 moda */}
      <ModalFlowDetail
        key={activeFlowId}
        visible={visible}
        record={activeRecord}
        applyId={activeFlowId as any}
        kind="application"
        onCancel={() => {
          setVisible(false)
          setActiveRecord(null)
          refresh()
        }}
      />
    </Card>
  )
})
