import React from 'react'
import { Form, Input } from 'antd'

export const FormItemReason = () => (
  <Form.Item
    name="applyReason"
    label="申请原因"
    rules={[{ required: true, message: '请输入申请原因' }]}
  >
    <Input.TextArea maxLength={200} allowClear></Input.TextArea>
  </Form.Item>
)
