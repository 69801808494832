import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHotKeys, IHotKeyKinds } from 'src/types'

const initialState: IHotKeys = {
    hotKeys: {
        execPlan: 'Alt+I',
        execute: 'Ctrl+I',
        prompt: 'Ctrl+T',
        quickFind: 'Ctrl+F',
        multiEdit: 'Ctrl+鼠标选中',
        comment: 'Ctrl+/',
        splitSqlStatement: 'Alt+S'
    }
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setHotKeys: (state, action: PayloadAction<IHotKeyKinds>) => {
            state.hotKeys = Object.assign({}, {...state.hotKeys, ...action.payload})
        }
    }
})

export const settingReducer = settingSlice.reducer
export const { setHotKeys } = settingSlice.actions