import React, { useEffect } from 'react'
import { Form, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useDispatch, useSelector } from 'src/hook'
import {
  setTargetTable,
  setTargetTableMessage,
} from 'src/store/extraSlice/textImportSlice'

export function SelectTargetTableForm({ form }: { form?: FormInstance }) {
  const dispatch = useDispatch()
  const { targetTableList, targetTableMessage } = useSelector(
    (state) => state.textImport,
  )
  const options = targetTableList?.map((t) => {
    return {
      label: t.nodeName,
      value: t.nodeName,
    }
  })

  useEffect(()=>{
    //  设置默认值
    if(options?.length){
      const hasMatch = !!options?.filter(i=>i?.value===targetTableMessage?.tableName)?.length
      const v = hasMatch ? targetTableMessage?.tableName : options[0].value
      if(!v){
        return 
      }
      form?.setFieldsValue({targetTable: v})
      dispatch(setTargetTable(v))
      let nodePath = targetTableMessage?.nodePath
      let nodePathWithType = targetTableMessage?.nodePathWithType
      const index = nodePath?.indexOf('表')
      if (nodePath?.split('/')[-1] !== '表') {
        if (!index) return
        nodePath = nodePath?.slice(0, index + 1) + '/' + v
      }
      dispatch(
        setTargetTableMessage(
          Object.assign(
            {},
            targetTableMessage,
            { nodePath: nodePath },
            { nodePathWithType: nodePathWithType },
            { tableName: v },
          ),
        ),
      )
    }
  },[options?.length])

  return (
    // 必填项
    <Form form={form} labelCol={{ span: 8 }} labelAlign="left">
      <Form.Item
        label="选择目标表"
        name="targetTable"
        rules={[{ required: true, message: '请选择目标表' }]}
      >
        <Select
          showSearch
          options={options}
          onChange={(v: string) => {
            dispatch(setTargetTable(v))
            let nodePath = targetTableMessage?.nodePath
            let nodePathWithType = targetTableMessage?.nodePathWithType
            const index = nodePath?.indexOf('表')
            if (nodePath?.split('/')[-1] !== '表') {
              if (!index) return
              nodePath = nodePath?.slice(0, index + 1) + '/' + v
            }
            dispatch(
              setTargetTableMessage(
                Object.assign(
                  {},
                  targetTableMessage,
                  { nodePath: nodePath },
                  { nodePathWithType: nodePathWithType },
                  { tableName: v },
                ),
              ),
            )
          }}
        ></Select>
      </Form.Item>
    </Form>
  )
}
