import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import {
  Card,
  Input,
  message,
  Form,
  Typography,
  Checkbox,
  Button,
  Row,
} from 'antd'
import { useRequest } from 'src/hook'
import { FormInstance } from 'antd/lib/form'
import { CustomInputNumber, LinkButton } from 'src/components'
import { SettingFormContext } from '../SettingFormContext'
import { getEmailConfig, updateEmailConfig, testEmailConfig } from 'src/api'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import styles from './index.module.scss'

const { Text } = Typography

interface EmailTestProps {
  value?: boolean
  onChange?: (value?: boolean) => void
}

const EmailTest: React.FC<EmailTestProps> = ({ value, onChange }) => {
  const form = useContext(SettingFormContext) as FormInstance
  const { loading, run } = useRequest(testEmailConfig, {
    manual: true,
    onSuccess: () => {
      onChange && onChange(true)
    },
    onError: () => {
      onChange && onChange(false)
    },
  })

  return (
    <Row align="middle">
      <Button
        loading={loading}
        onClick={() => {
          const params = form.getFieldValue(['emailConfig'])
          if (!('testEmailAddress' in params)) params.testEmailAddress = ''
          run(params)
        }}
      >
        测试发件邮箱
      </Button>
      {value !== undefined && (
        <span className={styles.testResult}>
          {value ? (
            <CheckCircleFilled
              className={classNames([
                styles.resultIcon,
                styles['icon-success'],
              ])}
            />
          ) : (
            <ExclamationCircleOutlined
              className={classNames([styles.resultIcon, styles['icon-error']])}
            />
          )}
          <span className={styles.resultText}>
            {value ? '测试通过' : '测试未通过'}
          </span>
        </span>
      )}
    </Row>
  )
}

export const EmailCard: React.FC = () => {
  const [cardLoading, setCardLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [emailConfig, setEmailConfig] = useState({
    authCode: '',
    emailAddress: '',
    host: '',
    port: '',
    tls: false,
  })
  const [tick, forceUpdate] = useReducer((r) => r + 1, 0)

  const inputRef = useRef<Input>(null)
  const form = useContext(SettingFormContext) as FormInstance

  useEffect(() => {
    setCardLoading(true)
    getEmailConfig()
      .then((value) => {
        setEmailConfig(value)
        form.setFields([{ name: ['emailConfig'], value }])
      })
      .catch(() => { })
      .finally(() => setCardLoading(false))
  }, [form, tick])

  useEffect(() => {
    if (editing) {
      inputRef.current?.select()
    }
  }, [editing])

  const handleSave = useCallback(() => {
    form
      .validateFields(['emailConfig', 'emailTest'])
      .then(({ emailConfig }) => {
        if ('testEmailAddress' in emailConfig) delete emailConfig.testEmailAddress
        updateEmailConfig(emailConfig)
          .then(() => {
            message.success('修改成功')
            form.resetFields(['emailConfig', 'emailTest'])
            form.setFields([
              { name: 'emailConfig', value: emailConfig },
              { name: 'emailTest', value: undefined },
            ])
            forceUpdate()
          })
          .catch(() => { })
          .finally(() => setEditing(false))
      })
  }, [form])


  const resetEmailTest = useCallback(() => {
    form.setFields([{ name: 'emailTest', value: undefined }])
  }, [form])

  return (
    <section className="cq-new-card flow-card" id="EmailCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">邮箱设置</h3>
        <div className={styles.settingCardExtra}>
          {editing ? (
            [
              <LinkButton key="save" onClick={handleSave}>
                保存
              </LinkButton>,
              <LinkButton
                key="cancel"
                onClick={() => {
                  form.resetFields(['emailConfig', 'emailTest'])
                  form.setFields([
                    { name: 'emailConfig', value: emailConfig },
                    { name: 'emailTest', value: undefined },
                  ])
                  setEditing(false)
                }}
              >
                取消
              </LinkButton>,
            ]
          ) : (
            <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
          )}
        </div>
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={cardLoading}
        >
          <Form.Item label="邮件服务器">
            <Form.Item name={['emailConfig', 'host']} hidden={!editing} noStyle>
              <Input
                ref={inputRef}
                placeholder="请输入邮件服务器"
                onChange={resetEmailTest}
              />
            </Form.Item>
            {!editing && <Text>{emailConfig.host}</Text>}
          </Form.Item>

          <Form.Item label="邮件服务器端口">
            <Form.Item name={['emailConfig', 'port']} hidden={!editing} noStyle>
              <CustomInputNumber
                placeholder="请输入邮件服务器端口"
                onChange={resetEmailTest}
              />
            </Form.Item>
            {!editing && <Text>{emailConfig.port}</Text>}
          </Form.Item>

          <Form.Item label="TLS">
            <Form.Item
              valuePropName="checked"
              name={['emailConfig', 'tls']}
              hidden={!editing}
              noStyle
            >
              <Checkbox onChange={resetEmailTest}>使用 TLS 安全协议</Checkbox>
            </Form.Item>
            {!editing && <Text>{emailConfig.tls ? '开启' : '关闭'}</Text>}
          </Form.Item>

          <Form.Item label="发件邮箱">
            <Form.Item
              name={['emailConfig', 'emailAddress']}
              noStyle
              hidden={!editing}
            >
              <Input onChange={resetEmailTest} placeholder="请输入发件邮箱" />
            </Form.Item>
            {!editing && <Text>{emailConfig.emailAddress}</Text>}
          </Form.Item>

          <Form.Item label="授权码">
            <Form.Item
              name={['emailConfig', 'authCode']}
              noStyle
              hidden={!editing}
            >
              <Input onChange={resetEmailTest} placeholder="请输入授权码" />
            </Form.Item>
            {!editing && <Text>******</Text>}
          </Form.Item>

          {/* 不存到数据库,仅用于测试 */}
          {editing && 
          <Form.Item label="收件邮箱">
            <Form.Item
              name={['emailConfig', 'testEmailAddress']}
              noStyle
              hidden={!editing}
            >
              <Input onChange={resetEmailTest} placeholder="请输入收件邮箱" />
            </Form.Item>
          </Form.Item>
          }

          <Form.Item
            name="emailTest"
            hidden={!editing}
            wrapperCol={{ offset: 7, span: 14 }}
            rules={[
              {
                validateTrigger: [],
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject('请测试通过后再保存'),
              },
            ]}
          >
            <EmailTest />
          </Form.Item>
        </Card>
      </section>
    </section>
  )
}
