import { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { fetchTreeNodeChildren } from 'src/pageTabs/queryPage/sdt'
import type { NodeEntity } from 'src/types'

export const useSdtNodeChildren = (parent?: NodeEntity) => {
  const dispatch = useDispatch()
  const treeNodeChildrenMap = useSelector(
    (state) => state.sdt.treeNodeChildrenMap,
  )
  const [loading, setLoading] = useState<boolean>()
  const data = useMemo(() => {
    if (parent) {
      const memo = treeNodeChildrenMap[parent.nodePath]
      return memo?.filter(({ switchable }) => switchable !== false)
    }
  }, [parent, treeNodeChildrenMap])

  useEffect(() => {
    if (!data && parent) {
      setLoading(true)
      dispatch(fetchTreeNodeChildren(parent)).finally(() => setLoading(false))
    }
  }, [data, dispatch, parent])

  return { data, loading }
}
