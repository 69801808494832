import React, { useEffect } from 'react'
import { Card, Descriptions, Form, Typography } from 'antd'
import { useRequest, useSelector } from 'src/hook'
import { getAuthorizationMessage } from 'src/api'
import styles from './index.module.scss'

const { Text } = Typography

export const AuthorizationCard = () => {

  const { productGrade } = useSelector(state => state.login)

  const {
    data,
    loading,
    run,
  } = useRequest(getAuthorizationMessage, {
    manual: true,
  })

  useEffect(() => {
    run()
  }, [])

  return (
    <>
      <section className="cq-new-card flow-card" id="AuthorizationCard">
        <div className="cq-card__headerbar">
          <h3 className="cq-card__title">授权信息</h3>
        </div>
        <section className="card__content">
          <Card
            className={styles.settingCardContent}
            bordered={false}
            loading={loading}
          >
            <Form.Item label="授权开始时间">
              <Text>{data?.startTime}</Text>
            </Form.Item>
            <Form.Item label="授权结束时间">
              <Text>{data?.endTime}</Text>
            </Form.Item>
            <Form.Item label="数据库纳管数量限制">
              <Text>{data?.databaseNumber}</Text>
            </Form.Item>
            <Form.Item label="已纳管数据库数量">
              <Text>{data?.connectionNumber}</Text>
            </Form.Item>
            <Form.Item label="支持数据库类型">
              <Text>{data?.databaseType?.join(', ')}</Text>
            </Form.Item>
            <Form.Item label="产品型号">
              <Text>{productGrade}</Text>
            </Form.Item>
          </Card>
        </section>
      </section>
    </>
  )
}