import React from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { ResizableBox, ResizableProps } from 'react-resizable'
import { Row, Col } from 'antd'
import classNames from 'classnames'
import { EditorContext, useMonaco } from 'src/components'
import { Sdt } from './sdt/Sdt'
import { NodeDetailPane } from './nodeDetailPane/NodeDetailPane'
import { TaskPane } from './taskPane'
import { QueryTabs } from './queryTabs'
import { BottomToolbar } from './bottomToolbar'
import styles from './index.module.scss'
import { Iconfont } from 'src/components'
import {
  useLSP,
  LanguageClientContext,
} from '../../components/BaseEditor/useLSP'
import { ModalTextImportWizard } from 'src/features/perm/modals'
import { ServerFilesModal } from './queryTabs/monacoPane/monacoToolbar/ServerFilesModal'
import { ErrorBoundary } from 'src/components'
import { ApplicationShopping } from './applicationShopping'

const ResizableBoxProps: ResizableProps = {
  axis: 'x',
  width: 368, // 340
  height: 0,
  minConstraints: [368, 0],  // 316，内容增多得加宽
  maxConstraints: [520, 0],
}

export const QueryPage = () => {
  const dispatch = useDispatch()
  const { visibleSdt } = useSelector((state) => state.queryPage)
  const { ModalOpenServerFile } = useSelector(state => state.modal)

  const ResizeHandle = (
    <div className={styles.resizeHandle}>
      <Iconfont type="icon-handle-8"></Iconfont>
    </div>
  )

  // 创建 editor 实例, 获取容器 ref
  const [editorInstance, containerRef] = useMonaco({
    automaticLayout: true,
    minimap: { enabled: false },
  })

  const { languageClient } = useLSP({ editorInstance })
  return (
    <EditorContext.Provider value={{ editorInstance, containerRef }}>
      <LanguageClientContext.Provider value={{ languageClient }}>
        <section className={styles.queryPageWrapper}>
          <Row className={styles.queryPageContent}>
            <Col
              className={classNames(
                styles.sdtColumn,
                !visibleSdt && styles.hide,
              )}
            >
              <ResizableBox
                className={styles.resizableBox}
                handle={ResizeHandle}
                {...ResizableBoxProps}
              >
                <ErrorBoundary>
                  <Sdt></Sdt>
                </ErrorBoundary>
              </ResizableBox>
            </Col>
            <Col className={styles.queryColumn} flex="1">
              <ErrorBoundary>
                <QueryTabs></QueryTabs>
              </ErrorBoundary>
            </Col>
            <Col className={styles.nodeDetailColumn}>
              <ErrorBoundary>
                <NodeDetailPane />
                <TaskPane />
                <ApplicationShopping />
              </ErrorBoundary>
            </Col>
          </Row>
          <ErrorBoundary>
            <BottomToolbar />
          </ErrorBoundary>
          <ErrorBoundary>
            <ModalTextImportWizard />
          </ErrorBoundary>
          <ErrorBoundary>
            <ServerFilesModal ModalOpenServerFile={ModalOpenServerFile} />
          </ErrorBoundary>
        </section>
      </LanguageClientContext.Provider>
    </EditorContext.Provider>
  )
}
