type IBlock = {
  [key in string | number]: any
} & {
  start: number
  end: number
  value?: string
}

class TextReader {
  public originText: string
  public prevP: number = 0
  public p: number = 0
  public blocks: IBlock[] = []
  private tempBlockParams: { [key in string | number]: any } = {}

  constructor(text: string) {
    this.originText = text
  }

  isOverstep(step = 1) {
    if (step > 0) {
      return this.p + step > this.originText.length
    } else {
      return this.p + step < 0
    }
  }

  peek(step = 1) {
    return this.originText[this.p + step - 1]
  }

  read(step = 1) {
    if (this.isOverstep()) return
    this.p = this.p + step
  }

  mark() {
    const block: IBlock = {
      start: this.prevP,
      end: this.p,
      ...this.tempBlockParams,
    }
    this.blocks.push(block)
    this.prevP = this.p
    this.tempBlockParams = {}
  }

  getBlocksValue() {
    return this.blocks
      .map((it) => this.originText.slice(it.start, it.end).trim())
      .filter(Boolean)
  }
}

/* Transact-SQL splitter */
/* keyword GO */
// 0 GO [count] 本块语句重复执行两遍 ----- count 暂不支持 可以尝试标记
// 1 GO 单独成行，该行只能包括注释
// 2 GO 后面不允许跟 ;
export class TransactSqlSplitter {
  private readonly CHAR_SQ = "'"
  private readonly CHAR_DQ = '"'
  private readonly CHAR_RS = '\\'
  private readonly CHAR_EOL = '\n'
  private readonly CHAR_TAB = '\t'
  private readonly CHAR_CR = '\r'
  private readonly CHAR_EMS = ' '
  private readonly CHAR_HY = '-'

  public textReader: TextReader

  constructor(text: string) {
    this.textReader = new TextReader(text)
  }

  split() {
    while (!this.textReader.isOverstep()) {
      const char = this.textReader.peek()
      if (char === this.CHAR_SQ || char === this.CHAR_DQ) {
        this.enterString(char)
      }
      if (char === 'G' || char === 'g') {
        const { isPrevEOL } = this.isPrevEOL()
        const nextChar = this.textReader.peek(2)
        if (nextChar === (char === 'G' ? 'O' : 'o') && isPrevEOL) {
          const { isNextEOL, step } = this.isNextEOL()
          if (!isNextEOL) {
            this.textReader.read(step)
            continue
          }
          this.textReader.mark()
          this.textReader.prevP = this.textReader.prevP + 2
          this.textReader.read(2)
          continue
        }
      }
      this.textReader.read()
    }
    this.textReader.mark()
    return this.textReader.getBlocksValue()
  }
  splitWithComment() {
    return this.split()
  }

  private isPrevEOL() {
    if (this.textReader.p === 0) return { isPrevEOL: true }
    let backStep = 0
    let flag = false
    while (!this.textReader.isOverstep(backStep)) {
      const char = this.textReader.peek(backStep)
      if ([this.CHAR_CR, this.CHAR_TAB, this.CHAR_EMS].includes(char)) {
        backStep--
        continue
      }
      flag = char === this.CHAR_EOL
      break
    }
    return { isPrevEOL: flag }
  }

  private isNextEOL() {
    let nextStep = 3
    let flag = false
    let isInCommit = false
    while (!this.textReader.isOverstep(nextStep)) {
      const char = this.textReader.peek(nextStep)
      if ([this.CHAR_CR, this.CHAR_TAB, this.CHAR_EMS].includes(char)) {
        nextStep++
        continue
      }
      if (char === this.CHAR_HY && !isInCommit) {
        const nextChar = this.textReader.peek(nextStep + 1)
        if (nextChar === this.CHAR_HY) {
          isInCommit = true
          flag = true
          nextStep = nextStep + 2
          break
        }
      }
      flag = char === this.CHAR_EOL
      break
    }
    if (this.textReader.isOverstep(nextStep)) {
      flag = true
    }
    return { isNextEOL: flag, step: nextStep }
  }

  private enterString(q: "'" | '"') {
    this.textReader.read()
    while (!this.textReader.isOverstep()) {
      const char = this.textReader.peek()
      if (char === this.CHAR_RS) {
        const nextChar = this.textReader.peek(2)
        if (nextChar === q) {
          this.textReader.read(2)
          continue
        }
      }
      if (char === q) {
        this.textReader.read()
        break
      }
      this.textReader.read()
    }
  }

  private matchRepeat() { }

  getBlocks() {
    return this.textReader.blocks
  }

  getBlocksValue() {
    return this.textReader.getBlocksValue()
  }
}
