import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {cancelExecute, getSegmentResults, QueryParams, QueryResult, startExecute} from 'src/api'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import {
  setTabExecutionStatus,
  ExecuteActiveTabInfo,
  setTabExecutionStatusPercentage
} from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import {
  setActiveResultTabKey,
  setExecuteKeyList,
  updateResultTabs,
  saveResultCount,
  addSegmentMsg,
  SegmentPositionMsg,
  addSegmentPositionMsg,
  setLastErrorPosition,
  setIsRun
} from 'src/pageTabs/queryPage/resultTabs/resultTabsSlice'
import { setExamineReviewResult } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { QueryPoolContext } from './QueryPoolContext'
import { addLog } from 'src/store/extraSlice/logsSlice'
import { QueryLogItem } from 'src/types'
import { chunk } from 'lodash'
import { sleep } from 'src/util'
const ComfirmModal = React.lazy(() => import('./ComfirmModal'));

interface PaneProps {
  queryTabKey: string
  executeInfo?: ExecuteActiveTabInfo
  shouldResume: number
  setVisible: (b: boolean) => void
  getHighRiskResultInfoList: (a: any) => any
  setShouldResume: (n: number) => void
  [p: string]: any
}

export const QueryPoolPaneRenderer: React.FC<PaneProps> = ({
  queryTabKey,
  executeInfo,
  shouldResume,
  setShouldResume,
  setVisible,
  getHighRiskResultInfoList,
}) => {
  const dispatch = useDispatch()
  const poolMap = useContext(QueryPoolContext)
  const results = useRef<QueryResult[]>([])
  const lastErrorPosition = useSelector((state) => state.resultTabs.lastErrorPosition)
  const [curErrorPositionInfo, setCurErrorPositionInfo] = useState<{ [key: string]: any }>()
  const [highRiskResultInfoList, setHighRiskResultInfoList] = useState<any>()
  const [params, setParams] = useState<any>() // fetch参数
  const [subShouldResume, setsubShouldResume] = useState<number>(shouldResume)

  // console.log('shouldResume :>> ', shouldResume);

  // 分批次渲染logs
  // const queueLogs = async (logs: QueryLogItem[][]) => {
  //   for (let i = 0; i < logs.length; i++) {
  //     dispatch(addLog(logs[i]))
  //     await sleep(200)
  //   }
  // }

  // 分批次渲染logs
  const queueLogs = (logs: QueryLogItem[]) => {
    dispatch(addLog(logs))
  }

  // const { run, cancel } = useRequest(getSegmentResults, {
  //   manual: true,
  //   pollingInterval: 300,
  //   onSuccess: (data, params) => {
  //     dispatch(setIsRun(true))
  //     // 只有收到返回结果且处于RUNNING状态才需要持续轮询
  //     if (!(data && data.executeStatus === 'RUNNING')) {
  //       cancel()
  //       //有问题多次点击执行会重复清，导致时间不是n秒
  //       if (data.executePercentage === 100){
  //         // 如果执行结束了，等待n秒后执行下面的代码，更新为未执行状态
  //         setTimeout(() => {
  //           console.log('清除执行进度')
  //           if (data.executePercentage === 100 && data.executeStatus !== 'RUNNING'){
  //             dispatch(setTabExecutionStatusPercentage({key: queryTabKey, executePercentage: 0, executeStatusMessage: '未执行'}));
  //           }
  //           // 恢复面板为可执行状态
  //           dispatch(setTabExecutionStatus({ key: queryTabKey, pending: false }))
  //         }, 2000);
  //       }
  //     }
  //     // 更新执行进度
  //     dispatch(setTabExecutionStatusPercentage({ key: queryTabKey, executePercentage: data?.executePercentage || 0, executeStatusMessage : data?.executeStatusMessage}))
  //
  //     if (data && data.messageData) {
  //       const { messageData, messageId } = data
  //       const resultCount = messageData.executionInfos?.length
  //       dispatch(saveResultCount(resultCount))
  //
  //       let logs: QueryLogItem[] = []
  //       messageData.executionInfos.forEach((item) => {
  //         item.executeLogInfo.message.messageId = messageId
  //         if (item?.response) {
  //           results.current.push(item.response)
  //         }
  //         if (item?.response?.executeError) {
  //           item.executeLogInfo.message.executeError = item?.response?.executeError
  //           if (item.response.executeError?.position) {
  //             const position = item.response.executeError?.position
  //             const errorPosition = {
  //               'endColumn': position.stopCol,
  //               'endLineNumber': position.stopLine,
  //               'startColumn': position.startCol,
  //               'startLineNumber': position.startLine
  //             }
  //             // 更新，当前执行的sql语句json中的 错误sql语句的位置
  //             let tmpLastErrorPosition: any = {}
  //             tmpLastErrorPosition[queryTabKey] = errorPosition
  //             let newErrorPosition = {...lastErrorPosition, ...tmpLastErrorPosition}
  //             dispatch(
  //               setLastErrorPosition(newErrorPosition)
  //             )
  //           }
  //         }
  //         logs.push(item.executeLogInfo.message)
  //       })
  //       const logsChunks = chunk(logs, 50)
  //       queueLogs(logsChunks)
  //
  //       /** 全部执行完后再处理结果集 */
  //       // if (data.executeStatus === 'SUCCESS' && results.current.length) {
  //       // 将错误语句之前的正确的SQL语句执行的结果集展示出来
  //       const len = results.current.length
  //       if (len) {
  //         dispatch(setIsRun(false))
  //         // 当有错误sql时，最后一个元素是该sql的结果集，不需要在结果集Tab中显示
  //         if (data.executeStatus === 'FAILED' && results.current[len-1].executeError) {
  //           let delFinalError  = results.current.pop()
  //         }
  //         const final = results.current.slice(-20)
  //         const resultTabs = final.map((info, index) => ({
  //           key: `execute/${queryTabKey}-${index}`,
  //           info,
  //         }))
  //         const list = resultTabs.map(({ key }) => key)
  //         // 先更新 resultTabs, 再设置 keyList
  //         dispatch(updateResultTabs(resultTabs))
  //         dispatch(setExecuteKeyList({ key: queryTabKey, list }))
  //
  //         const currentQueryResult = results.current.filter(r => r.tabKey === queryTabKey && r?.executeError?.message !== undefined && r?.executeError?.message !== "").length > 0;
  //         // 通过判断res 的tabkey 和当前key 一致并且执行后message 不为空 则将本页内的结果集active 设在log
  //         // 执行出错则优先展示log
  //         if (currentQueryResult) {
  //           dispatch(
  //             setActiveResultTabKey({
  //               queryTabKey: queryTabKey,
  //               resultTabKey: `log/${queryTabKey}`,
  //             }),
  //           )
  //         } else {
  //           dispatch(
  //             setActiveResultTabKey({
  //               queryTabKey: queryTabKey,
  //               resultTabKey: list[0] || `log/${queryTabKey}`,
  //             }),
  //           )
  //         }
  //       } else {
  //         // 没有结果集时默认展示log
  //         dispatch(
  //           setActiveResultTabKey({
  //             queryTabKey: queryTabKey,
  //             resultTabKey: `log/${queryTabKey}`,
  //           }),
  //         )
  //       }
  //     }
  //   },
  //   onError: (e) => {
  //     cancel()
  //   }
  // })

  useEffect(() => {
    let newErrorPosition = {...lastErrorPosition, ...curErrorPositionInfo}
    dispatch(setLastErrorPosition(newErrorPosition))
  }, [curErrorPositionInfo])

  useEffect(() => {
          setsubShouldResume(shouldResume)
      }, [shouldResume])

  async function recursiveQuery(params: QueryParams) {
    try {
      const data = await getSegmentResults(params);
      if (!(data && data.executeStatus === 'RUNNING')) {
        // 恢复面板为可执行状态
        dispatch(setTabExecutionStatus({ key: queryTabKey, pending: false }))
      }
      dispatch(setIsRun(true))
      // 更新执行进度
      dispatch(setTabExecutionStatusPercentage({ key: queryTabKey, executePercentage: data?.executePercentage, executeStatusMessage : data?.executeStatusMessage}))
      if (data && data.messageData) {
        const { messageData, messageId } = data
        // 获取sql审核结果
        const { reviewResult } = messageData
        if(reviewResult && Object.keys(reviewResult)?.length) {
          dispatch(setExamineReviewResult(reviewResult))
        }
        const resultCount = messageData.executionInfos?.length
        dispatch(saveResultCount(resultCount))

        let logs: QueryLogItem[] = []
        messageData.executionInfos.forEach((item) => {
          item.executeLogInfo.message.messageId = messageId
          if (item?.response) {
            results.current.push(item.response)
          }
          if (item?.response?.executeError) {
            item.executeLogInfo.message.executeError = item?.response?.executeError
            if (item.response.executeError?.position) {
              const position = item.response.executeError?.position
              const errorPosition = {
                'endColumn': position.stopCol,
                'endLineNumber': position.stopLine,
                'startColumn': position.startCol,
                'startLineNumber': position.startLine
              }
              // 更新，当前执行的sql语句json中的 错误sql语句的位置
              let tmpLastErrorPosition: {[key: string]: any} = {[queryTabKey]:errorPosition}
              setCurErrorPositionInfo(tmpLastErrorPosition)
            }
          }
          logs.push(item.executeLogInfo.message)
        })
        //const logsChunks = chunk(logs, 50)
        queueLogs(logs)
        
        /** 全部执行完后再处理结果集 */
        // if (data.executeStatus === 'SUCCESS' && results.current.length) {
        // 将错误语句之前的正确的SQL语句执行的结果集展示出来
        const len = results.current.length
        if (len) {
          dispatch(setIsRun(false))
          // 当有错误sql时，最后一个元素是该sql的结果集，不需要在结果集Tab中显示
          if (data.executeStatus === 'FAILED' && results.current[len-1].executeError) {
            let delFinalError  = results.current.pop()
          }
          const final = results.current.slice(-20)
          const resultTabs = final.map((info, index) => ({
            key: `execute/${queryTabKey}-${index}`,
            info,
          }))
          const list = resultTabs.map(({ key }) => key)
          // 先更新 resultTabs, 再设置 keyList
          dispatch(updateResultTabs(resultTabs))
          dispatch(setExecuteKeyList({ key: queryTabKey, list }))

          const currentQueryResult = results.current.filter(r => r.tabKey === queryTabKey && r?.executeError?.message !== undefined && r?.executeError?.message !== "").length > 0;
          // 通过判断res 的tabkey 和当前key 一致并且执行后message 不为空 则将本页内的结果集active 设在log 
          // 执行出错则优先展示log
          if (currentQueryResult) {
            dispatch(
              setActiveResultTabKey({
                queryTabKey: queryTabKey,
                resultTabKey: `log/${queryTabKey}`,
              }),
            )
          } else {
            dispatch(
              setActiveResultTabKey({
                queryTabKey: queryTabKey,
                resultTabKey: list[0] || `log/${queryTabKey}`,
              }),
            )
          }
        } else {
          // 没有结果集时默认展示log
          dispatch(
            setActiveResultTabKey({
              queryTabKey: queryTabKey,
              resultTabKey: `log/${queryTabKey}`,
            }),
          )
        }
      }
      if (data && data.executeStatus === 'RUNNING') {
        if (data.highRiskResultInfoList != null && data.highRiskResultInfoList.length > 0){
          // const shouldResume = await confirmWithTimeout(
          //     data.highRiskResultInfoList,
          //     60 // 60秒超时
          // );
          await Promise.all([
            setVisible(true),
            setParams(params),
            setHighRiskResultInfoList(data.highRiskResultInfoList),
            getHighRiskResultInfoList(data.highRiskResultInfoList)
          ]).then(() => {

          });
        }else {
          // 继续下一次查询，等待 300 毫秒
          setTimeout(() => {
            recursiveQuery(params);
          }, 3000);
        }
      }
    } catch (error) {
      console.error('查询状态时出错:', error);
    }
  }

  const confirmWithTimeout = (highRiskResultInfoList: any[], timeout: number) => {
    return new Promise<boolean>((resolve) => {
      console.log(highRiskResultInfoList)
      const dialogContainer = document.createElement('div');
      dialogContainer.style.position = 'fixed';
      dialogContainer.style.top = '50%';
      dialogContainer.style.left = '50%';
      dialogContainer.style.transform = 'translate(-50%, -50%)';
      dialogContainer.style.backgroundColor = 'white';
      dialogContainer.style.padding = '16px';
      dialogContainer.style.border = '1px solid #ccc';
      dialogContainer.style.zIndex = '9999';

      const messageElement = document.createElement('div');
      messageElement.textContent = "操作确认：" + JSON.stringify(highRiskResultInfoList);

      const buttonsContainer = document.createElement('div');

      const confirmButton = document.createElement('button');
      confirmButton.textContent = '是';
      confirmButton.addEventListener('click', () => {
        resolve(true);
        closeDialog();
      });

      const cancelButton = document.createElement('button');
      cancelButton.textContent = `否(${timeout})`;
      cancelButton.addEventListener('click', () => {
        resolve(false);
        closeDialog();
      });

      buttonsContainer.appendChild(cancelButton);
      buttonsContainer.appendChild(confirmButton);

      dialogContainer.appendChild(messageElement);
      dialogContainer.appendChild(buttonsContainer);

      document.body.appendChild(dialogContainer);

      const countdownUpdateInterval = setInterval(() => {
        cancelButton.textContent = `否(${--timeout})`;
      }, 1000);

      const timeoutId = setTimeout(() => {
        resolve(false);
        closeDialog();
      }, timeout * 1000);

      const closeDialog = () => {
        clearInterval(countdownUpdateInterval);
        document.body.removeChild(dialogContainer);
        clearTimeout(timeoutId);
      };
    });
  };

  // 根据 操作确认modal 返回的结果，判断是否结束执行
  const fetchData = async () => {
    try {
      if (params && highRiskResultInfoList) {
        if (subShouldResume === 1){
          await startExecute({messageId: queryTabKey, tabKey: queryTabKey}).then(() => {
            //直接继续 fetch 状态
            recursiveQuery(params);

            setsubShouldResume(2)
            setShouldResume(2)
            setParams('')
          })
        }else if (subShouldResume === 0){
          await cancelExecute({messageId: queryTabKey, tabKey: queryTabKey}).then(() => {
            //直接继续 fetch 状态
            recursiveQuery(params);

            setsubShouldResume(2)
            setShouldResume(2)
            setParams('')
          })
        }
        
      }
    } catch (error) {
      // 处理错误
    }
  };
  useEffect(() => {
    fetchData();
  }, [subShouldResume]);

  // todo: 支持多个messageId查询以及对应的结果处理
  const handleRenderResults = useCallback(
    async (queryContext: QueryParams) => {
      // const key = queryContext.tabKey
      // if (!key) return
      try {
        results.current = []
        // 手动执行一次后开启轮询
        await recursiveQuery(queryContext)
      } catch { }
    },
    [],
  )

  useEffect(() => {

    if (!queryTabKey) return
    handleRenderResults({channelId: "DMS_EXECUTE", groupName: "DMS_EXECUTE", messageId: queryTabKey})
    // if (!executeInfo) return

    // const pool = poolMap?.get(queryTabKey)
    // if (pool) {
    //   const { messageId, channelId, groupName } = executeInfo
    //   const poolGenerator = pool?.getQueryGenerator()
    //   const queryContext = pool?.getQueryContext()

    //   // 超时后重新登录，参数不全不向后端获取执行结果
    //   const keys = Object.keys(queryContext)
    //   if(!keys.includes('connectionId')) return

    //   if (messageId) {
    //     // segment 执行成功，执行下一个segment
    //     const done = poolGenerator?.next().done
    //     if (done) {
    //       // query pool 中语句全部执行，回收 pool 实例
    //       poolMap?.delete(queryTabKey)
    //       handleRenderResults({ messageId, channelId, groupName })
    //     }
    //   } else {
    //     // TODO 执行错误，重新划分 segment，继续执行后续语句
    //     poolMap?.delete(queryTabKey)
    //     // handleRenderResults({ messageId, channelId, groupName }).finally(() => {
    //     //   dispatch(setTabExecutionStatus({ key: queryTabKey, pending: false }))
    //     // })
    //   }
    // }
  }, [dispatch, handleRenderResults, executeInfo,queryTabKey])

  return null
}

export const QueryPoolPane = () => {
  const tabInfoMap = useSelector((state) => state.queryTabs.tabInfoMap)
  const monacoTabs = Object.values(tabInfoMap).filter(({ paneType }) =>
    ['monaco', 'plSql', 'tSql'].includes(paneType),
  )
  // const segmentMsg = useSelector((state) => state.resultTabs.segmentMsg)
  const executeActiveTabInfo = useSelector((state) => state.queryTabs.executeActiveTabInfo)
  const [visible, setVisible] = useState<boolean>(false) // 操作确认弹窗
  const [shouldResume, setShouldResume] = useState<number>(2)
  const [highRiskResultInfoList, setHighRiskResultInfoList] = useState<any>()

  const getHighRiskResultInfoList = (list: any) => {
    setHighRiskResultInfoList(list)
  }

  const getShouldResume = (newTimes: number) => {
    setShouldResume(newTimes)
  }

  return (
    <>
      {monacoTabs.map(({ key }) => {
        let info 
        if (executeActiveTabInfo) {
          info = executeActiveTabInfo[key]
        }
        // const info = infoList?.[infoList.length - 1]
        return (
          <QueryPoolPaneRenderer
            key={key}
            queryTabKey={key}
            executeInfo={info}
            setVisible={setVisible}
            shouldResume={shouldResume}
            setShouldResume={setShouldResume}
            getHighRiskResultInfoList={getHighRiskResultInfoList}
          />
        )
      })}
      {visible && <ComfirmModal
        highRiskResultInfoList={highRiskResultInfoList}
        timeout={60}  // 60s 超时结束
        getShouldResume={getShouldResume}
        visible={visible}
        setVisible={setVisible}
      />}
    </>
  )
}
