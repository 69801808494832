/**
 * 安全设置
 */
import React, { useEffect, useState, useImperativeHandle } from "react";
import { Spin, Form, Radio, Space, message } from "antd";
import CycleTimes from "src/components/CycleTimes";
import {
  querySafeSetting,
  setSafeSetting,
  getSafeSettingAuthorize,
  saveBasicSetting,
} from "src/api";
import { SecurityFormLayout } from "./constants";
import useRequest from "@ahooksjs/use-request";
import PermissionTooltip from "src/components/PermissionTooltip/PermissionTooltip";

interface IProps {
  // form: any;
  connectionId: string;
  fromType: string; // 来源：1、connection:连接、shcme、database级别；2、group:连接下的组级别
  [p: string]: any;
}

const BasicTimeSetting = (props: IProps, ref: any) => {
  const {
    // form,
    connectionId,
    nodePathWithType,
    fromType,
    nodePathWithTypeList,
    saveParams,
    permissionlist,
  } = props;

  const [form] = Form.useForm()

  const [showSearchTime, setShowSearchTime] = useState(false);
  const [showModifyTime, setShowModifyTime] = useState(false);
  const [showUseTime, setShowUseTime] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [initMark, setInitMark] = useState<number>(1);
  const {isOnlyRead, roleNameList} = permissionlist || {}

  // 时间设置查询
  const {
    data: timeSettingInfo,
    run: queryTimeSettings,
    refresh: refreshTimeSettings,
    loading: timeLoading,
  } = useRequest(querySafeSetting, {
    manual: true,
  });

  useEffect(() => {
    // 只查单条状态回显
    if (nodePathWithTypeList?.length !== 1) {
      return;
    }
    if (!connectionId || !nodePathWithType) {
      return;
    }
    const params = {
      connectionId,
      nodePath: nodePathWithType,
    };

    if ( connectionId && nodePathWithType) {
      queryTimeSettings(params);
    }
    
   
  }, [connectionId, nodePathWithType, nodePathWithTypeList]);

  const { run: runSaveBasicSetting } = useRequest(
    saveBasicSetting,
    {
      manual: true,
      onSuccess: (res) => {
        message.success('提交成功')
      },
    }
  );

  // 安全设置是否允许修改
  useEffect(() => {
    if (nodePathWithType) {
      getSafeSettingAuthorize(nodePathWithType)
        .then((res: any) => {
          setCanEdit(res);
        })
        .catch((err: any) => {
          console.error("安全设置权限查询失败：", err);
        });
    }
  }, [connectionId]);

  useEffect(() => {
    if (timeSettingInfo) {
      let searchTime = "ALL",
        customSearchTime,
        modifyTime = "ALL",
        customModifyTime,
        useTime = "ALL",
        customUsesTime;

      timeSettingInfo?.forEach((item: any) => {
        if (item?.accessMode === "READ") {
          searchTime = item?.state;
          customSearchTime = item?.periods;
          if (item?.periods?.length || item?.state === "PERIOD") {
            setShowSearchTime(true);
          }
        }
        if (item?.accessMode === "WRITE") {
          modifyTime = item?.state;
          customModifyTime = item?.periods;
          if (item?.periods?.length || item?.state === "PERIOD") {
            setShowModifyTime(true);
          }
        }
        if (item?.accessMode === "READ_WRITE") {
          useTime = item?.state;
          customUsesTime = item?.periods;
          if (item?.periods?.length || item?.state === "PERIOD") {
            setShowUseTime(true);
          }
        }
      });
      const initialValues = {
        searchTime,
        customSearchTime,
        modifyTime,
        customModifyTime,
        useTime,
        customUsesTime,
      };
      form.setFieldsValue(initialValues);
    } else {
      const initialValues = {
        searchTime: "ALL",
        modifyTime: "ALL",
        useTime: "ALL",
      };
      form.setFieldsValue(initialValues);
    }
  }, [timeSettingInfo, form]);

  const handleFormValuesChange = (curValues: any, allValues: any) => {
    if (curValues?.searchTime === "PERIOD" && !showSearchTime) {
      setShowSearchTime(true);
    } else if (allValues?.searchTime !== "PERIOD") {
      setShowSearchTime(false);
    }
    if (curValues?.modifyTime === "PERIOD" && !showModifyTime) {
      setShowModifyTime(true);
    } else if (allValues?.modifyTime !== "PERIOD") {
      setShowModifyTime(false);
    }
    if (curValues?.useTime === "PERIOD" && !showUseTime) {
      setShowUseTime(true);
    } else if (allValues?.useTime !== "PERIOD") {
      setShowUseTime(false);
    }
    !initMark && handleOk()
    setInitMark(0)
  };

  const hasEmptyData = (data: any[]) => {
    return !!data?.filter((i: any) => {
      if (
        [null, undefined, ""].includes(i?.dayOfWeek) ||
        [null, undefined, ""].includes(i?.startTime) ||
        [null, undefined, ""].includes(i?.endTime)
      ) {
        return true;
      }
      return false;
    })?.length;
  };

  const handleOk = (callback?: (v: string) => void) => {
    form
      .validateFields()
      .then((res: any) => {
        const {
          useTime,
          searchTime,
          modifyTime,
          customUseTime = [],
          customModifyTime = [],
          customSearchTime = [],
        } = res;

        let paraments = [];
        // 查看时间
        if (searchTime) {
          if (searchTime === "PERIOD") {
            const hasEmpty = hasEmptyData(customSearchTime);
            if (hasEmpty || !customSearchTime?.length) {
              // message.error("请先完善查询时间");
              return;
            }
          }
          const item = {
            connectionId,
            nodePathList: nodePathWithTypeList,
            accessMode: "READ",
            state: searchTime,
            periods: customSearchTime,
          };
          paraments.push(item);
        }
        // 修改时间
        if (modifyTime) {
          if (modifyTime === "PERIOD") {
            const hasEmpty = hasEmptyData(customModifyTime);
            if (hasEmpty || !customModifyTime?.length) {
              // message.error("请先完善修改时间");
              return;
            }
          }
          const item = {
            connectionId,
            nodePathList: nodePathWithTypeList,
            accessMode: "WRITE",
            state: modifyTime,
            periods: customModifyTime,
          };
          paraments.push(item);
        }
        // 使用时间
        if (useTime) {
          if (useTime === "PERIOD") {
            const hasEmpty = hasEmptyData(customUseTime);
            if (hasEmpty || !customUseTime?.length) {
              // message.error("请先完善允许使用时间");
              return;
            }
          }
          const item = {
            connectionId,
            nodePathList: nodePathWithTypeList,
            accessMode: "READ_WRITE",
            state: modifyTime,
            periods: customUseTime,
          };
          paraments.push(item);
        }
        if (!paraments.length) {
          return;
        }
        // 表、视图、函数等组细粒度设置
        setSafeSetting(paraments)
          .then(() => {
            callback?.("success");
          })
          .catch((err: any) => {
            console.error("时间设置出错", err);
          });
      })
      // .catch((err: any) => console.error("保存失败", err));
  };
  // 暴露给父组件方法
  // useImperativeHandle(ref, () => ({ handleOk }));

  // 时间记录, 行添加按钮style
  const addButtonStyle = {
    position: "absolute",
    top: "-46px",
    left: "286px",
  };

  // 自定义组和连接级等别设置
  return (
    <Spin spinning={timeLoading}>
      <Form
        form={form}
        {...SecurityFormLayout}
        onValuesChange={handleFormValuesChange}
      >
        {fromType === "函数" ? (
          <>
          <PermissionTooltip
            title="手动授权"
            permissionlist={permissionlist}
            align={{points: ['bl', 'tl'], offset: [250, 4] }}
          >
            <Form.Item label="允许使用时间" name="useTime">
              <Radio.Group disabled={!canEdit || isOnlyRead} onChange={() => handleOk()} >
                <Space direction="vertical">
                  <Radio value={"ALL"}>默认</Radio>
                  <Radio value={"NONE"}>拒绝查询</Radio>
                  <Radio value={"PERIOD"}>自定义时段</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </PermissionTooltip>
            {showUseTime && (
              <Form.Item label=" " name="customUseTime" colon={false}>
                <CycleTimes
                  type="useTime"
                  disabled={!canEdit || isOnlyRead}
                  addButtonStyle={addButtonStyle}
                  handleOk={handleOk}
                />
              </Form.Item>
            )}
          </>
        ) : (
          <>
            <PermissionTooltip
              title="手动授权"
              permissionlist={permissionlist}
              align={{points: ['bl', 'tl'], offset: [250, 4] }}
            >
              <Form.Item label="允许查询时间" name="searchTime">
                <Radio.Group disabled={!canEdit || isOnlyRead} onChange={() => handleOk()}>
                  <Space direction="horizontal">
                    <Radio value={"ALL"}>默认</Radio>
                    <Radio value={"NONE"}>拒绝查询</Radio>
                    <Radio value={"PERIOD"}>自定义时段</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </PermissionTooltip>
            {showSearchTime && (
              <Form.Item label=" " name="customSearchTime" colon={false}>
                <CycleTimes
                  type="searchTime"
                  disabled={!canEdit || isOnlyRead}
                  addButtonStyle={addButtonStyle}
                  handleOk={handleOk}
                />
              </Form.Item>
            )}
            <PermissionTooltip
              title="手动授权"
              permissionlist={permissionlist}
              align={{points: ['bl', 'tl'], offset: [250, 4] }}
            >
              <Form.Item label="允许修改时间" name="modifyTime">
                <Radio.Group disabled={!canEdit || isOnlyRead} onChange={() => handleOk()}>
                  <Space direction="horizontal">
                    <Radio value={"ALL"}>默认</Radio>
                    <Radio value={"NONE"}>拒绝修改</Radio>
                    <Radio value={"PERIOD"}>自定义时段</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </PermissionTooltip>
            {showModifyTime && (
              <Form.Item label=" " name="customModifyTime" colon={false}>
                <CycleTimes
                  type="modifyTime"
                  disabled={!canEdit || isOnlyRead}
                  addButtonStyle={addButtonStyle}
                  handleOk={handleOk}
                />
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </Spin>
  );
};
export default React.forwardRef(BasicTimeSetting);
