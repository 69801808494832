import { fetchGet, fetchPost, fetchPut, fetchDelete, customErrorMessageFetchPostFormData, fetchPostBlobFile } from './customFetch'

// 获取数据源信息
export const getDataSourceEnum = () => {
  return fetchGet(`/user/connections/datasource`)
}

/**
 * 解析文件或者压缩包信息
 * params: files：[]
 */ 
interface parseBatchSqlFileI {
  files: any[]
  connectionId?: string
  sqlFile?: boolean
}
export const parseBatchSqlFile = (params: parseBatchSqlFileI) => {
  return customErrorMessageFetchPostFormData(`/export/batchSql/parse`, params)
}

/**
 * 新建执行任务
 */
interface createBatchSqlI {
  description: string,
  indexFile: string,
  executeMode: string,
  errorHandleMode: string,
  scriptInfos: any[]
}
export const createBatchSql = (params: createBatchSqlI) => {
  return fetchPut(`/export/batchSql/new`, params)
}

/**
 * 分页查询执行任务
 */
interface queryBatchSqlI {
  pageNumber: number,
  pageSize: number,
  status: string,
  period: string,
  startAt: string,
  endAt: string
}
export const queryBatchSql = (params: queryBatchSqlI) => {
  return fetchPost(`/export/batchSql/page`, params)
}

/**
 * 任务详情-任务信息
 */
export const getTaskDetails = (id: string) => {
  return fetchGet(`/export/batchSql/${id}`)
}

/**
 * 任务详情-执行详情
 */
export const queryBatchSqlExecuteDetail = (id: string) => {
  return fetchGet(`/export/batchSql/execute/${id}`)
}


/**
 * 任务信息-下载附件信息
 */
export const downloadSqlFile = (params: any) => {
  return fetchPostBlobFile(`/export/batchSql/attachment/download`, params)
}

/**
 * 查看日志信息
 * @param id 任务详情id
 * @param taskId 任务id
 */
interface ILogPageParam {
  id: string,
  taskId: string
}
export const queryLogPage = (params: ILogPageParam) => {
  return fetchPost(`/export/batchSql/log/page`, params);
}

/**
 * 下载日志
 */
export const downloadExecuteLog = (params: any) => {
  return fetchPostBlobFile(`/export/batchSql/log/download`, params)
}

/**
 * 下载执行错误日志
 */
export const downloadExecuteErrorLog = (params: any) => {
  return fetchPostBlobFile(`/export/batchSql/log/downloadFailureLog`, params)
}

/**
 * 执行任务(批量)
 */
export const executeTask = (taskId: string) => {
  return fetchPost(`/export/batchSql/start/${taskId}`)
}

/**
 * 终止任务(批量)
 */
export const terminateTask = (taskId: string) => {
  return fetchPost(`/export/batchSql/terminate/${taskId}`)
}

/**
 * 单个执行任务
 */
export const singleExecuteTask = (params: any) => {
  return fetchPost(`/export/batchSql/start`, params)
}

/**
 * 终止任务(单个)
 */
export const singTerminateTask = (params: any) => {
  return fetchPost(`/export/batchSql/terminate`, params);
}


/**
 * 下载模板文件
 */
export const downloadTemplateFile = () => {
  return fetchPostBlobFile(`/export/batchSql/template/download`)
}

/**
 * 删除sql上传信息
 */
export const deleteSqlFile = (params: any) => {
  return fetchDelete(`/export/batchSql/file`, params)
}

/**
 * 替换sql文件
 */
interface IPramaReplace {
  files: any,
  fileName: string,
  id: number,
  taskId: number
}
export const replaceSingleSqlFile = (params: IPramaReplace) => {
  return customErrorMessageFetchPostFormData(`/export/batchSql/replaceFile`, params)
}

/**
 * 自动模式重新执行
 */
/**
 * 重新执行任务
 */
interface reExecuteTaskI {
  id: number,
  taskId: number,
  fileOrder: number
}
export const reExecuteTask = (params: reExecuteTaskI) => {
  return fetchPost(`/export/batchSql/autoRestart`, params);
}