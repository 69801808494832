import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Dropdown, Input, Menu } from 'antd'
import { useRequest, useSelector } from 'src/hook'
import { ParamSettingModal } from './ParamSettingModal'
import {
  getMyApplyList,
  executeApplication,
  applyDataChange,
  IApplyProps,
  terminateApplication,
  deleteDraft,
  closeApplication,
  IApplyResData,
  withdrawOrder
} from 'src/api'
import { ORDER_MAPPING } from 'src/constants'
import { getUrlSearchParams } from 'src/util'
import { SimpleTable, SimpleBreadcrumbs } from 'src/components'
import { columns } from './columns'
import { getFilteredDatabaseList } from '../utils'
import { SearchHeader } from '../common'
import styles from './index.module.scss'
import { SearchOutlined } from '@ant-design/icons'

interface ListProps { }

//判断登录人信息； 展示不同页面 区分开吧
export const List: FC<ListProps> = ({ ...rest }) => {
  const [visibeSetting, setVisibleSetting] = useState(false)
  const [searchValue, setSearchValue] = useState('');

  const defaultSearchParams = {
    currentPage: 1,
    pageSize: 10,
    status: '待审核',
  }
  const { dataSourceList } = useSelector((state) => state.dataSource);
  const { userInfo } = useSelector((state) => state.login);
  const { roles } = userInfo || {};
  // roleId === 3 是DBA角色，DBA才能操作参数配置
  const hasOperationPermission = Array.isArray(roles) && roles.some(item => item.roleId === 3)

  const history = useHistory();
  const { search } = useLocation<{ status: string }>();
  const [searchParams, setSearchParams] =
    useState<IApplyProps>(defaultSearchParams)

  const { data: applicationList, loading: applicationLoading, run: runApplication, refresh: refreshApplicationList } =
    useRequest<IApplyResData>(() => getMyApplyList(searchParams), {
      refreshDeps: [searchParams],
      debounceInterval: 300
    })

  //对草稿发起申请
  const { run: runApplyDataChange } = useRequest(applyDataChange, {
    manual: true,
  })

  //删除草稿
  const { run: runDeleteDraft } = useRequest(deleteDraft, {
    manual: true,
    onSuccess: () => setSearchParams({ ...defaultSearchParams, keyword: searchValue }),
  })
  //撤回申请
  const { run: runWithDrawApplication } = useRequest(withdrawOrder, {
    manual: true,
    onSuccess: () => refreshApplicationList()
  })
  //执行
  const { run: runExecuteApplication } = useRequest(executeApplication, {
    manual: true,
    onSuccess: () => runApplication({ ...searchParams, currentPage: 1 })
  })

  //中止
  const { run: runTerminateApplication } = useRequest(terminateApplication, {
    manual: true,
    onSuccess: () => runApplication({ ...searchParams, currentPage: 1 })
  })
  //终止
  const { run: runCloseApplication } = useRequest(closeApplication, {
    manual: true,
    onSuccess: () => runApplication({ ...searchParams, currentPage: 1 })
  })

  useEffect(() => {

    if (search) {
      const { status } = getUrlSearchParams(search);
      setSearchParams({
        ...searchParams,
        status: decodeURIComponent(status),
        keyword: searchValue,
      })
    }
  }, [])

  const handleInputChange = (e: { target: { value: React.SetStateAction<string> } })=> {
    setSearchValue(e?.target?.value);
    setSearchParams({
      ...searchParams,
      keyword: e?.target?.value as string,
    })
  }

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '我的申请',
    },
  ]

  const menu = (
    <Menu>
      <Menu.Item onClick={() => { setVisibleSetting(true) }}>参数配置</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={styles["extra-action"]}>
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <div>
          <Input
            style={{ marginRight: "10px", width: "200px" }}
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="搜索标题"
            value={searchValue}
            onChange={handleInputChange}
            allowClear
          />
          {
            hasOperationPermission ?
              <Dropdown.Button overlay={menu} type="primary">
                <Link to="/data_change_mine_apply/apply">申请变更</Link>
              </Dropdown.Button>
              :
              <Button type="primary">
                <Link to="/data_change_mine_apply/apply">申请变更</Link>
              </Button>
          }
        </div>
      </div>
      <div className={styles["change-request-page"]}>
        <SearchHeader
          searchParams={searchParams}
          refresh={() => refreshApplicationList()}
          setSearchParams={(params: any) => {
            history.push(`/data_change_mine_apply?status=${params?.status}`)
            setSearchParams({
              ...searchParams,
              ...params,
              currentPage: 1,
              keyword: searchValue,
            })
          }}
        />
        <SimpleTable
          size="small"
          rowKey="id"
          loading={applicationLoading}
          //@ts-ignore
          columns={columns({
            status: searchParams?.status,
            history,
            databaseList: getFilteredDatabaseList(dataSourceList),
            onSearch: (params: any) =>
              setSearchParams({ ...searchParams, ...params, currentPage: 1, keyword: searchValue }),
            onDeleteDraft: (id: number) => runDeleteDraft(id), //id
            onReApplyDraft: (record: any) => runApplyDataChange(record),
            onWithDrawApplication: (id: number) =>
              runWithDrawApplication({flowId:id, isMyApply: true}),
            onExecuteApplication: (id: number) => runExecuteApplication(id),
            onTerminateApplication: (id: number) => runTerminateApplication(id),
            onCloseApplication: (id: number) => runCloseApplication(id),
          })}
          total={applicationList?.total || 0}
          searchParams={searchParams}
          setSearchParams={(params: any) => {
            let translateParams = params;

            if (params?.type) {
              translateParams.type = Number(params?.type?.[0]);
            }
            if (params?.applyTime) {
              translateParams.sortItem = 'applyTime';
              //@ts-ignore
              translateParams.sortType = ORDER_MAPPING[params.applyTime];
              delete translateParams?.applyTime;
            }
            if (params?.executeTime) {
              translateParams.sortItem = 'executeTime';
              //@ts-ignore
              translateParams.sortType = ORDER_MAPPING[params.executeTime];
            }
            if (params?.executeStatus) {
              translateParams.executeStatus = params?.executeStatus?.[0];
            }
            translateParams.dataSourceType = params?.nodePath?.[0];
            delete translateParams?.executeTime;
            delete translateParams?.nodePath;
            setSearchParams({ ...translateParams, keyword: searchValue })
          }
          }
          dataSource={applicationList?.content || []}
        />
      </div>
      <ParamSettingModal visible={visibeSetting} onClose={() => { setVisibleSetting(false) }} />
    </>
  );
}

export default List
