import React from 'react'
import { Form } from 'antd'
import { PersonalFormContext } from './PersonalFormContext'
import { InformationCard } from './PersonalCard'
import { SecuritySettings } from './SecuritySettings'
import { UserConnections } from './UserConnections'
import { PreferenceSetting } from './PreferenceSetting'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'

const FormLayout = {
  labelCol: { span: 5 },
  wrapperCol: { offset: 1, span: 14 },
}
interface IProps {
  activeKey: string
}
export const PersonalBasicPage = (props: IProps) => {
  const { activeKey } = props
  const [form] = Form.useForm()

  return (
    <PersonalFormContext.Provider value={form}>
      <Form component={false} form={form} {...FormLayout}>
        {
          !!activeKey && activeKey === 'Information' && <ErrorBoundary>
            <InformationCard />
          </ErrorBoundary>
        }
        {
          !!activeKey && activeKey === 'SecuritySettings' && <ErrorBoundary>
            <SecuritySettings />
          </ErrorBoundary>
        }
        {
          !!activeKey && activeKey === 'AccessibleResource' && <ErrorBoundary>
            <UserConnections />
          </ErrorBoundary> 
        }{
          !!activeKey && activeKey === 'Preferences' && <ErrorBoundary>
            <PreferenceSetting />
          </ErrorBoundary>
        }
      </Form>
    </PersonalFormContext.Provider>
  )
}
