
import React, { useEffect } from 'react';
import { Form, InputNumber, message } from 'antd';
import { UIModal } from 'src/components';
import { useDispatch, useSelector, useRequest } from 'src/hook';
import { updateDatasourceTypeSample, updateGroupSample, updateConnectionSample } from 'src/api';
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice';

export const DesenCaluParameterSettingModal = ({
  defaultValues,
  refresh,
}: {
  defaultValues?: { sampleRate: number; sampledCount: number };
  refresh: () => Promise<(() => Promise<any>)[]>
}) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const { selectedNode } = useSelector(
    (state) => state.dataProtection,
  )
  const visible = useSelector((state) => state.modal.DesensCaluParameterSettingModal)

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [defaultValues, visible])

  const { loading, run: runSetDesenParameter } = useRequest((values) => {

    switch (selectedNode?.newNodeType) {
      case "datasource":
        return updateDatasourceTypeSample({
          ...values,
          dataSourceType: selectedNode.connectionType
        })
      case "group":
        return updateGroupSample({
          ...values,
          groupId: selectedNode?.id
        })
      default:
        return updateConnectionSample({
          ...values,
          nodePathWithType: selectedNode?.props?.nodePathWithType
        })
    }
  }, {
    manual: true,
    onSuccess: () => {
      message.success('设置成功');
      dispatch(hideModal('DesensCaluParameterSettingModal'));
      refresh();

    }
  })

  const handleSubmit = () => {

    form.validateFields().then((vals) => {

      runSetDesenParameter({
        sampledCount: Number(vals?.sampledCount),
        sampleRate: Number(vals?.sampleRate)
      })
    }).catch(() => {
      return Promise.reject()
    })

  }

  return (
    <UIModal
      title={'设置内置脱敏计算参数'}
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => {
        dispatch(hideModal('DesensCaluParameterSettingModal'))
      }}
      width={500}
    >
      <Form form={form}>
        <Form.Item required 
          rules={[
            {
              min: 1,
              max: 100,
              message: '请输入1-100区间的数值',
              type: 'number',
              transform: (v) => Number(v),
            },
          ]} 
          label='采样行数' name='sampledCount'>
          <InputNumber
            min={1}
            max={100}
            style={{ display: 'inline-block', width: 230 }}
          />
        </Form.Item>
        <Form.Item required rules={[
          {
            min: 0,
            max: 100,
            message: '请输入0-100区间的数值',
            type: 'number',
            transform: (v) => Number(v),
          },
        ]} label='命中比率' name='sampleRate'>
          <InputNumber
            step="0.01"
            min={0}
            max={100}
            style={{ display: 'inline-block', width: 230 }}
          />
        </Form.Item>

      </Form>
    </UIModal>
  )
}