import React, { useState } from 'react'
import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'

export function SelectMore(props: SelectProps<any>) {
    const { style, onDropdownVisibleChange, dropdownStyle, ...others } = props

    const [width, setwidth] = useState<number>(160)
    return <Select {...others} style={Object.assign({}, style, { width, transition: 'width .3s' })}
        dropdownStyle={Object.assign({}, dropdownStyle, { width })}
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={(open) => {
            open ? setwidth(260) : setTimeout(() => setwidth(160), 300)
            onDropdownVisibleChange?.(open)
        }} />
} 