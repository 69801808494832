import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'src/hook'
import useRequest from '@ahooksjs/use-request'
import { Card, Col, Modal, Row, Table, Tooltip } from 'antd'
import { UserConnection, viewableResourceDetail, viewableResourceList } from 'src/api'
import { Iconfont, LinkButton } from 'src/components'
import { ColumnsType } from 'antd/lib/table'

export const UserConnections = () => {
  const history = useHistory()

  const [modalVisible, setModalVisible] = useState(false)
  const {
    userInfo: { userId },
  } = useSelector((state) => state.login)
  const {
    data,
    loading: loadingGetUserConnections,
  } = useRequest(() => viewableResourceList(userId!), {
    formatResult(data) {
      return data?.permissions
    }
  })

  const {
    data: dataDetails,
    loading: loadingDetails,
    run: getDetails
  } = useRequest(viewableResourceDetail, {
    manual: true,
    onSuccess() {
      setModalVisible(true)
    }
  })

  const columnsWithAction: ColumnsType<UserConnection> = [
    {
      title: '资源',
      dataIndex: 'fullName',
      ellipsis: true,
      render: (fullName, record) => {
        const { dataSourceType, isTestEnv =false } = record
        return (
          <>
            <Iconfont
              type={'icon-connection-' + dataSourceType}
              className="mr8"
              style={{ fontSize: 14, color: '#2B58BA' }}
            ></Iconfont>
            {fullName}
            {
              isTestEnv && 
              <Tooltip title='测试环境'>
                <Iconfont
                  type="icon-developer"
                  style={{marginLeft: 4}}
                />
            </Tooltip>
            }
           
          </>
        )
      },
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      ellipsis: true,
      width: '210px'
    },
    {
      title: '权限等级',
      dataIndex: 'permissionName',
      ellipsis: true,
      width: '120px'
    },
    {
      title: '到期时间',
      dataIndex: 'expiresTime',
      ellipsis: true,
      width: '160PX'
    },
    {
      title: '权限详情',
      dataIndex: 'action',
      render: (_, record) => (
        <>
          <LinkButton
            onClick={() => {
              getDetails(record)
              // history.push(`/my-permissions?c=${record.connectionId}`)
            }}
          >
            查看权限
          </LinkButton>
        </>
      ),
      width: '100px',
    },
  ]

  const columnsDetails: ColumnsType<any> = [
    {
      title: '管控对象',
      dataIndex: 'objectName'
    },
    {
      title: '对象类型',
      dataIndex: 'objectType'
    },
    {
      title: '权限类型',
      dataIndex: 'permissions',
      render: (_, record) => {
        return <>
          {
            _ && _.length ? _.join(',') : '-'
          }
        </>
      }
    }

  ]

  return (
    <section className="cq-new-card flow-card" style={{ marginBottom: '10.5px' }}>
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">可访问资源</h3>
      </div>
      <section className="card__content">
        <Card
          // todo: UICard 或 util 封装 card title 样式
          size="small"
          bodyStyle={{ minHeight: 509 }}
          bordered={false}
        >
          <Table
            columns={columnsWithAction}
            dataSource={data}
            loading={loadingGetUserConnections}
            rowKey={(record) => record.connectionId}
            size="small"
          ></Table>
        </Card>
        <Modal
          title='权限详情'
          visible={modalVisible}
          footer={[]}
          onCancel={() => setModalVisible(false)}
          width={900}
        >
          <Row style={{marginBottom: '20px'}}>
            <Col span={12}>用户名：{
              (dataDetails?.username && dataDetails?.userId) ? dataDetails?.username + '(' + dataDetails?.userId +')' : 
              (dataDetails?.username ? dataDetails?.username : ( dataDetails?.userId ? '(' + dataDetails?.userId +')' : '-' ))
            }</Col>
            {/* <Col span={12}>用户名：{dataDetails?.userId}</Col> */}
            <Col span={24}>资源：{dataDetails?.resourceName}</Col>
            <Col span={24}>角色：{dataDetails?.roleName}</Col>
            <Col span={24}>权限等级：{dataDetails?.permissionLevel}</Col>
          </Row>
          <Table
            dataSource={dataDetails?.userPermissionDetailVos}
            columns={columnsDetails}
            size={'small'}
          />
        </Modal>
      </section>
    </section>

  )
}
