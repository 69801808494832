/*
 * @Author: fuzhenghao
 * @Date: 2022-11-18 14:09:41
 * @LastEditTime: 2022-11-30 16:59:32
 * @LastEditors: fuzhenghao
 * @Description:
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\utils\renderStatusTag.tsx
 */
import React from 'react'
import { Tag } from 'antd'
import { ApplyStatus } from 'src/types'
import { exhaustiveCheck } from 'src/util'
import classNames from 'classnames'
import styles from '../flowPage.module.scss'
import {
  FlowNodeResult,
  TFlowNodeResult,
} from '../flowDetails/newFlowProcess.interface'

/**
 * 根据流程状态返回对应样式的 Tag
 */
export const renderStatusTag = (applyStatus?: ApplyStatus) => {
  switch (applyStatus) {
    case 'pending':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTagPending)}>
          审批中
        </Tag>
      )
    case 'pass':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTagFinish)}>
          已通过
        </Tag>
      )
    case 'refuse':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTagError)}>
          已驳回
        </Tag>
      )
    case 'withdraw':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTag)}>
          已撤销
        </Tag>
      )
    case undefined:
      return null
    default:
      exhaustiveCheck(applyStatus)
      return null
  }
}

/**
 * @description: 根据流程状态返回对应样式的Tag,新流程tag渲染
 * @param {TFlowNodeResult} applyNewStatus
 * @return {React.ReactElement | null}
 */
export const renderNewStatusTag = (
  applyNewStatus?: TFlowNodeResult,
): React.ReactElement | null => {
  switch (applyNewStatus) {
    case 'pending':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTagPending)}>
          {FlowNodeResult[applyNewStatus]}
        </Tag>
      )
    case 'pass' || 'orSignPass' || 'signPass':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTagFinish)}>
          {FlowNodeResult[applyNewStatus]}
        </Tag>
      )
    case 'refuse' || 'signRefuse' || 'orsignRefuse':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTagError)}>
          {FlowNodeResult[applyNewStatus]}
        </Tag>
      )
    case 'withdraw':
      return (
        <Tag className={classNames(styles.statusTag, styles.statusTag)}>
          {FlowNodeResult[applyNewStatus]}
        </Tag>
      )
    case undefined:
      return null
    default:
      return null
  }
}
