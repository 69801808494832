import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Button, Input, message, Modal, Row, Space, Table, Tooltip } from 'antd'
import { getAndSetAdminAppList, setSelectedApp } from './appManagementPageSlice'
import { ColumnsType } from 'antd/lib/table'
import { AppEntity, deleteApp } from 'src/api'
import { LinkButton } from 'src/components'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { AddAppModal } from './AddAppModal'
import { EditAppModal } from './EditAppModal'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

const columns: ColumnsType<AppEntity> = [
  { dataIndex: 'appName', title: '应用名称' },
  { dataIndex: 'appCode', title: '应用 ID' },
  { dataIndex: 'desc', title: '应用描述' },
  {
    dataIndex: 'hosts',
    title: '主机列表',
    render: (hosts) => (hosts || []).join(', '),
  },
  { dataIndex: 'language', title: '应用语言' },
  { dataIndex: 'appAdminId', title: '负责人' },
  { dataIndex: 'dbaId', title: 'DBA' },
  { dataIndex: 'creator', title: '创建人' },
  {
    dataIndex: 'labels',
    title: '标签',
    render: (labels) => (labels || []).join(', '),
  },
]

export const AppManagementPage = () => {
  const dispatch = useDispatch()
  const { adminAppList, appListLoading } = useSelector(
    (state) => state.appManagement,
  )
  const {
    userInfo: { userId },
  } = useSelector((state) => state.login)

  useEffect(() => {
    dispatch(getAndSetAdminAppList())
  }, [dispatch])

  const handleDeleteTask = (app: AppEntity) => {
    const { id, appName } = app
    Modal.confirm({
      title: `确定删除应用：${appName}？`,
      onOk: () => {
        return deleteApp(id).then(() => {
          message.success('删除成功')
          dispatch(getAndSetAdminAppList())
        })
      },
      centered: true,
      okButtonProps: { danger: true },
    })
  }

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInputRef = useRef<Input | null>(null)
  const handleSearch = (
    selectedKeys: string[],
    confirm: Function,
    dataIndex: string,
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters: Function) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex: string, render?: Function) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInputRef.current = node
          }}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 128, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 60 }}
          >
            搜索
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 60 }}
          >
            重置
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: { [key: string]: any }) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select(), 100)
      }
    },
    render: (text: any, obj?: any, index?: any) => {
      if (render) {
        text = render(text, obj, index)
      }
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
    },
  })

  const handleEditTask = (app: AppEntity) => {
    dispatch(setSelectedApp(app))
    dispatch(showModal('EditApp'))
  }

  const columnsWithActions: ColumnsType<AppEntity> = [
    ...columns.map((column: any) => ({
      ...column,
      ...getColumnSearchProps(column.dataIndex, column.render),
    })),
    {
      dataIndex: 'action',
      render: (v, record) => {
        const isCreator = record.creator === userId
        // ?: Tooltip 直接包裹 button，disabled 时 Tooltip 失效
        // ?: ref: https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
        // ?: ref: https://github.com/react-component/tooltip/issues/18#issuecomment-650864750
        return (
          <Tooltip
            title={!isCreator ? '只可操作当前用户创建的应用' : ''}
            placement="topRight"
          >
            <span style={{ cursor: !isCreator ? 'not-allowed' : 'pointer' }}>
              <LinkButton
                disabled={!isCreator}
                onClick={() => handleEditTask(record)}
                style={isCreator ? {} : { pointerEvents: 'none' }}
              >
                编辑
              </LinkButton>
            </span>
            <span style={{ cursor: !isCreator ? 'not-allowed' : 'pointer' }}>
              <LinkButton
                disabled={!isCreator}
                onClick={() => handleDeleteTask(record)}
                style={isCreator ? {} : { pointerEvents: 'none' }}
                danger
              >
                删除
              </LinkButton>
            </span>
          </Tooltip>
        )
      },
    },
  ]

  const AddAppButton = (
    <LinkButton
      onClick={() => {
        dispatch(showModal('AddApp'))
      }}
    >
      添加应用
    </LinkButton>
  )

  return (
    <div className="page-content page-content-scroll">
      <h1>应用列表</h1>
      <Row style={{ marginBottom: 24 }}>{AddAppButton}</Row>
      <Table
        columns={columnsWithActions}
        dataSource={adminAppList}
        size="small"
        loading={appListLoading}
        pagination={{ hideOnSinglePage: true }}
      ></Table>
      <AddAppModal></AddAppModal>
      <EditAppModal></EditAppModal>
    </div>
  )
}
