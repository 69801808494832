
import * as _ from 'lodash';

export const  getUrlSearchParams = (url: string) =>  {
  
    // 通过 ? 分割获取后面的参数字符串
    let urlStr = url &&  url.split('?')[1]
    // 创建空对象存储参数
    let obj:{[k: string] :any} = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=');

        obj[arr[0]] = arr[1];
    }
    return obj;
}

export const formatUrlSearchParams = (searchObj: Object) => {
    // 默认约定搜索条件都是字符串
    let queryStr = ''
    Object.entries(searchObj).forEach((item, index) => {
      // 字段为空则不拼接到查询条件里
      if (_.isEmpty(item[index]) ) {
        // 仅是结束本轮循环，不是跳出整个循环
        return false
      } 

      if (queryStr[0] !== '?') {
        queryStr = `?${item[0]}=${item[1]}`
      } else {
        queryStr = queryStr + `&${item[0]}=${item[1]}`
      }
    })
    return queryStr
}