import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPostFormData,
  fetchPut,
} from './customFetch'
import { DataSourceType, SdtNodeType, UserEntity } from '../types'
import { PermType } from 'src/constants'

export interface PermEntity {
  permId: string
  permName: string
}

/** 获取连接权限列表 */
export const getConnectionPerms = (
  connectionId: number | string,
): Promise<PermEntity[]> => {
  return fetchGet(`/user/perms/${connectionId}`)
}

interface DataSourcePerm {
  name: string
  value: string
  disabled: boolean
  select: boolean
}

/** 获取数据源账号权限 */
export const getDataSourcePerms = (
  connectionType: DataSourceType,
): Promise<DataSourcePerm[]> => {
  return fetchGet(`/user/perms/databases/${connectionType}`)
}

/** 获取用户在某连接下权限列表 */
export const getUserPermsAtConennection = (
  // !: deprecated
  userId: string,
  connectionId: number | string,
): Promise<PermEntity[]> => {
  return fetchGet(`/user/perms/user/${userId}/${connectionId}`)
}

/** 获取用户所有连接权限 */
export const getUserAllPerms = (
  userId: number | string,
): Promise<PermEntity[]> => {
  return fetchGet(`/user/perms/user/${userId}`)
}

/** (批量) 设置用户在某连接下权限 */
export const setUserPermsBatch = (
  userIds: string[],
  permIds: string[],
  // todo: 与后端协商，统一命名为 connectionId
  connId?: number | string,
) => {
  return fetchPut(`/user/perms/grant`, {
    permIds,
    userIds,
    connId,
  })
}

// 公有权限控制层
/** 获取节点权限列表 */
export const getPermsByNodeType = (params: {
  connectionId: number | string
  dataSourceType: DataSourceType
  nodeType: SdtNodeType
  nodePath?: string
}): Promise<{ opName: string; message: string | null; valid: boolean }[]> => {
  return fetchPost(`/user/permission/datasource`, params)
}

interface PermTableRecord extends BasePermEntity {
  [k: string]: any
}
interface PermTableResponse {
  header: { field: string; label: string }[]
  dataSourcePermissionInfos: PermTableRecord[]
}

/** 查询数据源权限列表 */
export const getDataSourcePermTable = (params: {
  connectionId: number | string
  dataSourceType: DataSourceType
  permType: PermType | 'ALL'
  condition?: string
}): Promise<PermTableResponse> => {
  return fetchPost(`/user/permission/datasource/list`, params)
}

/** 查询数据源权限类型 */
export const getPermTypes = (): Promise<
  { logo: string; message: string }[]
> => {
  return fetchGet(`/user/permission/datasource/type`)
}

/** 查询数据源权限[数据源角色授予权限穿梭框的权限列表] */
export const getPermsByType = ({
  connectionId = 0,
  type,
}: {
  connectionId: number | string
  type: PermType | 'ALL'
}): Promise<
  {
    description: string
    name: string
    permissionId: number | string
    permissionType: string
  }[]
> => {
  return fetchGet(`/user/permission/datasource/${connectionId}/${type}`)
}

/** 查询指定连接下的所有权限(角色授予权限穿梭框的权限列表) */
export const getPermsByConnectionId = (
  connectionId: number | string,
): Promise<
  {
    description: string
    name: string
    permissionId: number | string
    permissionType: string
  }[]
> => {
  return fetchGet(`/user/permission/datasource/${connectionId}`)
}

/** 添加权限信息 */
export const addPermByParams = (params: any) => {
  const { operations } = params.permissionObject
  if (operations && operations.length > 0) {
    // 排序
    operations.sort()
  }
  return fetchPost(`/user/permission/permission`, params)
}

/** 编辑权限信息 */
export const editPermByParams = (params: any) => {
  const { operations } = params.permissionObject
  if (operations && operations.length > 0) {
    // 对除了后两个选项以为的排序
    if (operations.includes('flowProcessApply')) {
      params.permissionObject.operations = sortParams(operations, -2)
    } else {
      // 对所有排序
      params.permissionObject.operations = operations.slice()
    }
  }
  return fetchPost(`/user/permission/permission/update`, params)
}

function sortParams(arr: string[], n: number): string[] {
  const arrSort = arr.slice(0, n)
  const arrRetain = arr.slice(n)
  const arrNew = arrSort.sort().concat(arrRetain)
  return arrNew
}

export interface PermSetEntity {
  name: string
  description: string
  /** 是否是初始化权限集 */
  initPermSet?: boolean
  permissionId: number | string
  permissionType: string
  roles: string
  users: string
  childPerms: BasePermEntity[]
}

/** 获取指定连接权限集列表 */
export const getDataSourcePermSet = ({
  connectionId,
  condition = 'ALL',
}: {
  connectionId: number | string
  condition?: string
}): Promise<PermSetEntity[]> => {
  return fetchGet(
    `/user/permission/permissionSets/${connectionId}/${condition.trim() ? condition : 'ALL'}`,
  )
}

/** 获取连接最小权限集绑定用户 */
export const getInitPermSetBoundUsers = (
  connectionId: number | string,
): Promise<{
  roleName: string
  users?: string[]
  allUser?: UserEntity[]
}> => {
  return fetchGet(`/user/users/connections/${connectionId}`)
}

/** 添加权限集 */
export const addPermSet = (params: {
  childPermission: string[]
  name: string
  description?: string
  permissionType?: string
}) => {
  return fetchPost(`/user/permission/permissions`, params)
}

export const editPermSet = (params: {
  childPermission: string[]
  name: string
  permissionId: number | string
  description?: string
  permissionType?: string
}) => {
  return fetchPut(`/user/permission/permissions`, params)
}

export interface PermSetChildEntity {
  option: string
  permissionName: string
  permissionPath: string
  permissionType: string
}

/** 获取权限集子权限列表 */
export const getPermSetChildren = (
  permissionId: number | string,
): Promise<PermSetChildEntity[]> => {
  return fetchPost(`/user/permission`, { permissionId })
}

export interface BasePermEntity {
  permissionId: number | string
  permissionName: string
  permissionDesc?: string
  permissionType: string
}

export interface SysPermEntity extends BasePermEntity {
  flowPermission: boolean
  expiredTime: string
  roleNames: string
  userInfos: { userId: string; userName: string }[]
}

/** 获取系统权限列表 */
export const getSysPerms = (
  condition: string = 'ALL',
): Promise<SysPermEntity[]> => {
  return fetchGet(`/user/permission/system/${condition}`)
}

/** 删除系统流程权限 */
export const deleteSystemFlowPermission = (permissionId: (number | string)) => {
  return fetchDelete(`/user/permission/system/flow/${permissionId}`)
}

interface ConnectionTreeRequest {
  connectionId: number | string
  connectionType: DataSourceType
  nodeType: SdtNodeType
  hasChild?: boolean
  nodeName?: string
  nodePath?: string
  permissionType?: string
}

export interface ConnectionTreeResponse {
  connectionId: number | string
  connectionType: DataSourceType
  nodeType: SdtNodeType
  groupId?: number | string
  hasChild?: boolean
  nodeDescription?: string
  nodeName?: string
  nodePath?: string
}

/** 权限节点渲染(编辑权限下拉选择的资源树) */
export const getConnectionTree = (
  params: ConnectionTreeRequest,
): Promise<ConnectionTreeResponse[]> => {
  return fetchPost(`/dms/common/permission`, params)
}

/** 删除权限 */
export const deletePermission = (permissionIds: (number | string)[]) => {
  return fetchDelete(`/user/permission`, permissionIds)
}

// 公有角色控制层
/** 添加/编辑角色接口所需参数 */
export interface RoleInfoEntity {
  id: number
  type: string
  connectionId?: string | number
  name: string
  description?: string | null
  beginDate?: string | null
  endDate?: string | null
  /** 生效周期 01234567 [0:星期天, 1:星期一, 2:星期二, ...rest] */
  dayMask?: string | null
  /** 授予权限 */
  grantPerms?: string[]
  /** 绑定用户 */
  grantUser?: string[]
}

/** 添加角色 */
export const addRole = (params: RoleInfoEntity) => {
  return fetchPost(`/user/common`, params)
}

/** 更新角色 */
export const editRole = (params: RoleInfoEntity) => {
  return fetchPut(`/user/common`, params)
}

/** 权限授予角色 */
export const grantPermsToRole = (params: {
  grantPerms: (string | number)[]
  removePerms: (string | number)[]
  roleName: string
  roleId: number
}) => {
  return fetchPost(`/user/common/grant/permission`, params)
}

/** 角色绑定用户 */
export const bindUsersToRole = (params: {
  roleId: number
  grantUser?: string[]
  removeUser?: string[]
  roleName: string
}) => {
  return fetchPost(`/user/common/grant/user`, params)
}

/** 审计员角色绑定用户 
 * [{
    userId: string,
    auditRange: string[]
  }]
  */
export const bindAuditUsersToRole = (params: {
  userVoList: any[]
}) => {
  return fetchPut(`/user/common/user/audit/user`, params)
}

/** 添加角色-数据源 */
export const addRole_dataSource = (params: RoleInfoEntity) => {
  return fetchPost(`/user/connection`, params)
}

/** 更新角色-数据源 */
export const editRole_dataSource = (params: RoleInfoEntity) => {
  return fetchPut(`/user/connection`, params)
}

/** 角色绑定用户-数据源 */
export const bindUsersToRole_dataSource = (params: {
  roleId: number
  grantUser?: string[]
  removeUser?: string[]
  roleName: string
  connectionId?: string | number
}) => {
  return fetchPost(`/user/connection/grant/user`, params)
}

/** 删除角色-数据源 */
export const deleteRole_dataSource = (roleIds: (number | string)[]) => {
  return fetchDelete(`/user/connection/role`, roleIds)
}

/** 权限授予角色-数据源 */
export const grantPermsToRole_dataSource = (params: {
  grantPerms: (number | string)[]
  removePerms: (number | string)[]
  roleName: string
  roleId: number
}) => {
  return fetchPost(`/user/connection/grant/permission`, params)
}

/** 连接添加用户 */
export const addConnectionUsers = (params: {
  grantUser?: string[]
  removeUser?: string[]
  roleName: string
  connectionId: number | string
}) => {
  return fetchPost(`/user/common/connection/add/user`, params)
}

interface UserPermsRequest {
  condition: string
  connectionId: number | string
  permType?: PermType | 'ALL'
}

export const getUserPerms = (params: UserPermsRequest) => {
  return fetchPost(`/user/permission/permissions/search`, params)
}

export interface RoleEntity {
  roleId: number
  description: string
  permissionNames: BasePermEntity[]
  currentRolePerms: BasePermEntity[]
  /** 是否为初始角色 */
  initRole?: boolean
  roleName: string
  type: string
  userInfos: { userId: string; userName: string }[]
  valid: boolean
  validPeriod: string
  roleTemplate?: string
}

/** 获取连接角色列表 */
export const getRolesByConnectionId = (params: {
  connectionId: number | string
  condition?: string
}): Promise<RoleEntity[]> => {
  return fetchPost(`/user/common/datasource`, params)
}

/* 获取当前角色可继承的列表 */
export const getRolesInheritance = (params: {
  connectionId: number | string
  condition?: string
}): Promise<RoleEntity[]> => {
  return fetchPost(`/user/common/inheritance`, params)
}

/** 删除角色 */
export const deleteRole = (roleIds: number[]) => {
  return fetchDelete(`/user/common/role`, roleIds)
}

/** 获取角色列表 */
export const getSysRoles = (
  params: {
    type: string
    name: string
  } = { type: 'SYSTEM_ROLE', name: '' },
): Promise<RoleEntity[]> => {
  return fetchPost(`/user/common/roles`, params)
}

/** 获取系统角色详情 */
export const getRoleInfo = (id: number): Promise<RoleInfoEntity> => {
  return fetchGet(`/user/common/role/${id}`)
}

/** 修改自定义角色 */
export const editRoleInfo = (
  params: {
    roleId: string | number
    roleName?: string
    description?: string
  }
): Promise<any> => {
  return fetchPut(`/user/common/customRole`, params)
}

/**
 * 新增自定义角色
 */
export const saveCustomRole = (
  params: {
    roleName: any
    description: any
    permissionIds: any
    userIds?: any 
    roleTemplate: any // 角色模板名字
  }
): Promise<any> => {
  return fetchPost(`/user/common/customRole`, params)
}

/** 删除自定义角色 */
export const delRole = (
  roleId: string | number
): Promise<any> => {
  return fetchDelete(`/user/common/customRole?roleId=${roleId}`)
}

/** 编辑权限 */
export const editPermission = (
  params: {
    roleId: string | number
    removePerms?: string[]
    grantPerms?: string[]
  }
): Promise<any> => {
  return fetchPost(`/user/common/grant/permission`, params)
}

/** 获取连接角色详情 */
export const getConnectionRoleInfo = (id: number): Promise<RoleInfoEntity> => {
  return fetchGet(`/user/common/connection_role/${id}`)
}

/** 获取角色模板对应的权限 */

export const getRolesTemplate = (param: {templateRole: string}): Promise<any> => {
  return fetchGet(`/user/common/roles/template`, param)
}

/* 行过滤权限检查接口 */
export const checkRowFilterCondition = (data: any) => {
  return fetchPost(`/user/permission/permission/check`, data)
}

/* 获取角色绑定组织架构 */
export const getRoleBindOrganization_api = (params: {
  roleName: string
}): Promise<IRoleBindOrganization> => {
  return fetchGet(`/user/org/dept/group/user`, params)
}

export interface OrganizationNode {
  name: string
  nodePath: string
  orgType: 'USER' | 'DEPT' | 'COMPANY' | 'GROUP' | 'SUB_COMPANY'
  children?: OrganizationNode[]
  deptChildren?: any
  departmentNum?: any
  [p: string]: any
}

/* 异步获取组织架构树 */
export const getOrganizationTree_api = (
  node: OrganizationNode,
): Promise<OrganizationNode[]> => {
  return fetchPost(`/user/org/dept/group/user`, node)
}

export interface IRoleBindOrganization {
  deptName: string[]
  grantUser: string[]
  groupName: string[]
  roleName: string
}

/* 部门组用户角色绑定 */
export const roleBindOrganization_api = (data: IRoleBindOrganization) => {
  return fetchPost(`/user/common/grant/org/user`, data)
}

/**
 * 导出权限（形成文件）
 */
export const permissionExport = (params: {
  datasourceType: string
  permissionType: string
  permissionIds: (string | number)[]
}): Promise<string> => {
  return fetchPost(`/user/permission/export`, params)
}

/**
 * 导入权限
 */
export const permissionImport = ({
  connectionId,
  permissionType,
  file,
}: {
  connectionId: string | number
  permissionType: string
  file: File
}) => {
  return fetchPostFormData(
    `/user/permission/import/${connectionId}/${permissionType}`,
    { file },
  )
}

interface ImportTask {
  /** 任务结束时间 */
  endDate: string
  /** 导入数据异常数量 */
  errorNum: number
  /** 任务 id */
  taskId: number
  /** 任务状态 */
  taskStatus: 'START' | 'IMPORT' | 'END' | 'ERROR'
  /** 导入数据量总行数 */
  totalNum: number
  /** 导入文件名 */
  filename: string
}

/**
 * 查询导入任务列表
 */
export const getPermissionImportTasks = ({
  connectionId,
  permissionType,
}: {
  connectionId: string | number
  permissionType: string
}): Promise<ImportTask[]> => {
  return fetchGet(`/user/permission/task/${connectionId}/${permissionType}`)
}

interface ImportTaskResult {
  id: number | string
  noValidNode: string
  filename: string
  permissionName: string
  permissionResource: string
}

/**
 * 查询导入任务详情
 */
export const getPermissionImportTaskResult = (
  taskId: string,
): Promise<ImportTaskResult[]> => {
  return fetchGet(`/user/permission/task/${taskId}`)
}

/** 修改脱敏资源状态 */
interface ISwitchDensen {
  internalId: string
  status: boolean
}
export const postSwitchDesenStatus = (params: ISwitchDensen) => {
  return fetchPost('/user/permission/permission/change/mask/status', params)
}

/** 查询所有数据源权限操作类型 */
export const getAllDataSourcePerms = (): Promise<string[]> => {
  return fetchGet(`/user/permission/datasource/operate/type`)
}

/** 获取所有数据源角色列表 */
export const getAllDataSourceRoles = () => {
  return fetchGet(`/user/common/datasource/all`)
}

interface IExportNumber {
  connectionType: string
  databaseName: string
  connectionId: number | string
  statement: string
  containTempTable: boolean
  tabKey: string
}

// /** 查询导出权限拥有的导出数量 */
// export const permissionExportNumber = (params: Partial<IExportNumber>) => {
//   return fetchPost('/dms/export/resultSetCountCheck', params)
// }
/** 查看角色权限 */
export const viewingRolePermissions = (
  roleId: string | number
) => {
  return fetchGet(`/user/common/roles/permission/${roleId}`)
}

// 获取角色授权模式状态
export const getRoleAuthStatus = (roleId: string | number) => {
  return fetchGet(`user/common/role/getAuthorizationMode/${roleId}`)
}

// 开启关闭角色授权模式
export const setRoleAuthStatus = (params: {
  roleId: string | number
  open: boolean
}) => {
  return fetchPost(`user/common/role/updateAuthorizationMode`, params)
}

/**
 * 权限看板 数据库元素树
 */
export interface PERMISSIONElementTreeRootNodeEntity {
  alias?: string
  connectionId: number
  connectionType: string
  groupId?: number
  hasChild: boolean
  nodeDescription?: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
  nodeType: SdtNodeType
  parentGroupId?: number
  experiment?: boolean
}
export interface PERMISSIONElementTreeNodeEntity {
  alias?: string
  groupId?: number
  connectionId: number
  connectionType: string
  hasChild: boolean
  nodeDescription?: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
  nodeType: SdtNodeType
  parentGroupId?: number
  experiment?: boolean
  sdt: {
    hasChild: boolean
    connectionId: number
    connectionType: string
  }
  dataSourceName?: string
  dataSourceType?: string
}

/**
 * 导出
 */
 interface exportParams {
  userId: string[]
  nodePath?: string[]
  permissionType?: string[]
  authTimeBegin?: string
  authTimeEnd?: string
  offset?: number
  limit?: number
  exportType?: string
}

export const exportAuthFile = (params: exportParams) => {
  return fetchPost(`/analyze/audit_report/dashboard/export`, params)
}

/**
 * 查询数据库连接
 */
export const getDatabaseConnectionsList = (dataSourceName: string | undefined) => { 
	return fetchGet(`/analyze/audit_report/connections/?datasource=${dataSourceName}`);
}
/**
 * 逐级获取树结构 到列
 */
interface nodeParams {
  connectionId: number
  connectionType: string
  nodeType: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
}
export const getDatabaseNode = (params: nodeParams) => {
  return fetchPost(`/dms/meta/without/permission/check/node`, params)
}