import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import {  ResourceDataType } from 'src/api'
interface ResourceManage {
    resourceData: ResourceDataType[]
    resourceDataBackups: ResourceDataType[],
    pageSize: number,
    pageNum: number,
    current: number,
    total: number,
    globalState: boolean,
    pagingData: ResourceDataType[]

}

const initialState: ResourceManage = {
    resourceData: [],
    resourceDataBackups:[],
    pageSize: 10,
    pageNum: 0,
    current: 1,
    total: 0,
    globalState: false,
    pagingData: []
}

export const resourceSlice = createSlice({
    name: 'resourceManage',
    initialState,
    reducers: {
        setResourceData: (state, action: PayloadAction<ResourceDataType[]>) => {
            state.resourceData = action.payload
            state.resourceDataBackups = state.resourceData
            state.total = action.payload.length
            let newArr = state.resourceData.slice(state.pageNum === 0 ? state.pageNum : (state.pageNum * (state.pageSize)), state.pageNum === 0 ? state.pageSize : ((state.pageNum + 1) * state.pageSize))
            state.pagingData = newArr
        },
        setChildrenData: (state, action) => {
            let childrenArr = action.payload.children?.map((item: ResourceDataType) => {
                item.searchName = item.schemaName
                item.onlyId = item.id
                item.parentId = action.payload.onlyId
                item.dbTier = false;
                return item
            })
            state.resourceData = state.resourceData?.map((item: ResourceDataType) => {
                if (item.onlyId == action.payload.onlyId) {
                    item.children = childrenArr
                }
                return item
            })
            state.resourceDataBackups = state.resourceDataBackups?.map((item: ResourceDataType) => {
                if (item.onlyId == action.payload.onlyId) {
                    item.children = childrenArr
                }
                return item
            })
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            if (!action.payload) {
                state.resourceData = state.resourceDataBackups
                state.total = state.resourceDataBackups.length
            } else {
                let newResourceData = handleData(state.resourceDataBackups, action.payload)
                state.resourceData = newResourceData;
            }
            state.total = state.resourceData.length
            // state.current = state.current
        },
        setPage: (state, action: PayloadAction<{ pageSize: number, pageNum: number }>) => {
            state.pageNum = action.payload.pageNum
            state.pageSize = action.payload.pageSize
            state.current = action.payload.pageNum + 1
            let newArr = state.resourceData.slice(state.pageNum === 0 ? state.pageNum : (state.pageNum * (state.pageSize)), state.pageNum === 0 ? state.pageSize : ((state.pageNum + 1) * state.pageSize))
            state.pagingData = newArr
            let flag = newArr.length ? newArr.every(item => Boolean(item.enableStatus)) : false
            state.globalState = flag
        }
    }
})

function handleData(arr: ResourceDataType[], val: string) {
    let newArr = [] as ResourceDataType[]
    let reg = new RegExp(val, 'i')
    arr.forEach((item: ResourceDataType) => {
        if (item?.children && item?.children.length) {
            let children = handleData(item?.children, val)
            let obj = {
                ...item,
                children
            } as ResourceDataType
            if (children && children.length) {
                newArr.push(obj)
            } else if (item?.searchName.match(reg)) {
                newArr.push({ ...item })
            }
        } else {
            if (item?.searchName.match(reg)) {
                newArr.push(item)
            }
        }
    })
    return newArr
}

export const { setResourceData, setChildrenData, setSearchValue, setPage } = resourceSlice.actions

export const resourceReducer = resourceSlice.reducer