

/**
 * 组织结构用户tree select
 */
import React, { useState } from 'react'
import { useRequest } from 'src/hook'
import { TreeSelect, Tooltip } from 'antd'
import { queryOrgSdtWithUser } from 'src/api'
import { Iconfont } from 'src/components'

const treeNodeAdapter = (arr: any[], pKey?:''): any => {
    return arr?.map((item: any) => {
      const { name, orgId, userId, type, child=[], path } = item
      const id = orgId || userId
      const key = pKey ? pKey + '_' + id : id
  
      return {
        id,
        name,
        type,
        key,
        userId,
        title: type==="USER" ? `${name}(${userId})` : name,
        children: child?.length ? treeNodeAdapter(child, key) : [],
        isLeaf: !child?.length,
        path
      }
    })
  }
  
interface ElementTreeSelectProps {
  value?: string
  onChange?: (value: string) => void
  [p: string]: any
}

const UserTreeSelect: React.FC<ElementTreeSelectProps> =
  React.memo(({ value, onChange, style }) => {
  
    // 查询组织架构用户数据
    const { data = [] } = useRequest(queryOrgSdtWithUser, {
      formatResult: (res: any[]) => {
        return treeNodeAdapter(res)
      },
    })

    const titleRender = (node: any) => {
      const { type, title } = node
      let icon = null
      switch (type) {
        case 'COMPANY':
          icon = <Iconfont type="icon-org-company" />
          break
        case 'SUB_COMPANY':
          icon = <Iconfont type="icon-org-company" />
          break
        case 'DEPT':
          icon = <Iconfont type="icon-org-department" />
          break
        case 'GROUP':
          icon = <Iconfont type="icon-org-group" />
          break
        case 'USER':
          icon = <Iconfont type="icon-org-user" />
          break
        default:
          icon = <Iconfont type="icon-org-user" />
          break
      }
      const showTitle =
        title?.length > 15 ? (
          <Tooltip title={title}>{title.substring(0, 15) + '...'}</Tooltip>
        ) : (
          title
        )
      return (
      <>{icon}&nbsp;{showTitle}</>
      )
    }
    const  renderTreeNodes = (nodes: any) => {
      return nodes?.map((node: any) => {
        const { disabledCheckbox, title,type, key, userId, ...rest } = node;
        if (node.children) {
          return (
            <TreeSelect.TreeNode 
              {...rest} 
              key={key} 
              value={userId} 
              selectable={type ==='USER' ? true: false} 
              title={titleRender(node)} 
            >
              {renderTreeNodes(node.children)}
            </TreeSelect.TreeNode>
          );
        }
        return (
          <TreeSelect.TreeNode 
            {...rest} 
            key={key} 
            value={userId}  
            selectable={type ==='USER' ? true: false} 
            title={titleRender(node)} 
          />
        )     
      });
    }

    return (
      <TreeSelect
        style={style}
        treeIcon
        allowClear
        showSearch={true}
        treeNodeFilterProp="value"
        placeholder="请选择用户"
        value={value}
        onChange={(value:string) => {
          onChange?.(value)
        }}
      >
        {renderTreeNodes(data)}
      </TreeSelect>
    )
  })

export default UserTreeSelect