import React from 'react';
import { Badge, } from 'antd'
import type { EXECUTE_TYPES } from 'src/constants'
import { Iconfont } from 'src/components';
import styles from './searchHeader.module.scss'

export const getColumnStatusWithIcon = (status: EXECUTE_TYPES) => {
  switch (status) {
    case '草稿':
      return <><Iconfont type="icon-caogao" className={styles.statusIcon} />{status}</>
    case '审批中':
      return <Badge color='#0256FF' text={status} className={styles.statusBadge} />
    case '待执行':
      return <><Iconfont type="icon-daizhixing" className={styles.statusIcon} />{status}</>
    case '执行失败':
    case '回退失败':
      return <><Iconfont type="icon-shibai" className={styles.statusIcon} />{status}</>
    case '执行成功':
    case '回退成功':
      return <><Iconfont type="icon-chenggong" className={styles.statusIcon} />{status}</>
    case '被中止':
      return <><Iconfont type="icon-yizhongzhi" className={styles.statusIcon} />{status}</>
    case '已驳回':
      return <Badge color='#EA0000' text={status} className={styles.statusBadge} />
    case '已撤回':
      return <Badge color='#C8C8C8' text={status} className={styles.statusBadge} />
    default:
      return <Badge color='#C8C8C8' text={status} className={styles.statusBadge} />
  }
}

