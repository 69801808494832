/*
 * @Author: yangchao yangchao@bintools.cn
 * @Date: 2023-09-19 13:55:26
 * @LastEditors: yangchao yangchao@bintools.cn
 * @LastEditTime: 2023-09-25 13:39:29
 * @FilePath: /cq-enterprise-frontend/src/App.tsx
 * @Description: 
 */
import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import { ConfigProvider, Spin } from 'antd'
import Login from './appPages/login'
// import { PasswordReset } from './appPages/passwordReset'
import { history } from 'src/util'
import zhCN from 'antd/es/locale/zh_CN'
import 'resize-observer-polyfill'
import "src/assets/js/iconfont.js";
import 'src/assets/js/iconfont_enterprise.js'
import "src/assets/js/iconfont_lastest.js"  //需要更新官方图标库时候直接替换内容 无需再新增
import 'resize-observer-polyfill'
import './styles/index.scss'
import './styles/theme.less'
import { WaitLogin, WaitOauthLogin } from './appPages/login'
import { setRedirectUrl } from './appPages/login/loginSlice'
import { NoAccess } from './appPages/403'
import hoc from './hoc';

const Main = React.lazy(() => import('./appPages/main'))

export const ViewModules = () => {
  const { isLoggedIn, hasIpAccess } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const pathname = window.location.pathname

  useEffect(() => {
    if (
      pathname !== '/login' &&
      pathname !== '/' &&
      pathname !== '/waitlogin' &&
      pathname !== '/403'
    ) {
      dispatch(setRedirectUrl(encodeURIComponent(pathname)))
    }
  }, [pathname, dispatch])


  const fallbackLoading = (
    <div style={{ cursor: 'wait' }}>
      <Spin spinning style={{ color: '#03204a' }} tip="CloudQuery 加载中">
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: '#3f84e9',
          }}
        ></div>
      </Spin>
    </div>
  )

  return (
    <ConfigProvider locale={zhCN}>
      <Router history={history}>
        <Switch>
          <Route exact path="/login">
            {isLoggedIn ? <Redirect to="/system_index" /> : <Login />}
          </Route>
          <Route path="/waitlogin" key="/waitlogin">
            <WaitLogin />
          </Route>
          <Route path="/waitOauthLogin" key="/waitOauthLogin">
            <WaitOauthLogin />
          </Route>
          {/* <Route path="/password_reset">
            <PasswordReset />
          </Route> */}
          <Route>
            {
              !hasIpAccess ?
                <NoAccess /> :
              !isLoggedIn ? (
                <Redirect to="/login" />
              ) : (
                <Suspense fallback={fallbackLoading}>
                  <Main />
                </Suspense>
              )
            }
          </Route>
        </Switch>
      </Router>
    </ConfigProvider>
  );
}

export default hoc(ViewModules)
