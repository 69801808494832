import { fetchGet, fetchPost, fetchPut } from './customFetch'
import { DataSourceType, SdtNodeType } from '../types'
/** RSA ｜ SM2 公共方法 */
import { setEncrypt } from 'src/util/encryptRsaSm2'
import { store } from 'src/store'
import { getTransmissionPublicKey } from 'src/api'

type WizardItemType =
  | 'input'
  | 'password'
  | 'select'
  | 'radio'
  | 'checkbox'
  | 'table'
  | 'textarea'
  | 'tag'
  | 'custom'

export interface WizardItem {
  label: string
  field: string
  type: WizardItemType
  value: any
  options: any
  required: true
  suffix: string
  onChange: Function
  hide: boolean
  disabled?: boolean
}

export const getCreateConnectionForm = (
  dataSourceType: DataSourceType,
): Promise<WizardItem[]> => {
  return fetchGet(`/dms/connection/form/${dataSourceType}`)
}

export const getWizardForm = (params: {
  dataSourceName: string
  nodeType: SdtNodeType
}): Promise<WizardItem[]> => {
  const { dataSourceName, nodeType } = params
  return fetchGet(`/dms/common/form/${dataSourceName}/${nodeType}`)
}

/** 创建 / 编辑连接 */
export interface EditConnectionRequest {
  connectionId?: /** 创建连接无需提供 */ number | string
  dataSourceType?: /** 编辑连接无需提供 */ DataSourceType
  userInputs: any
  copyFrom?: /** 如复制连接 传connectionId */ string | number
}

export const validateConnectionConfig = async (
  params: EditConnectionRequest,
  edit?: boolean
): Promise<boolean> => {
  const getKey = await getTransmissionPublicKey()
  // const { algorithm, publicKey } = store.getState().encryption
  /** SM2|RSA 加密 */
  // 加密算法 如果用户不修改密码 则不传 接口默认返回密码nul
  if (params.userInputs.password === '******' && !edit) {
    delete params.userInputs.password
  } else if (!params.userInputs.password) {
    params.userInputs.password = ''
  } else {
    params.userInputs.password = setEncrypt({algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.userInputs.password})
  }
  /* 密码加密 */
  // params.userInputs.password = btoa(unescape(encodeURIComponent(params.userInputs.password)))
  return fetchPost(`/dms/connection/effectiveness`, params)
}

export const createConnectionConfig = async (
  params: EditConnectionRequest,
  edit?: boolean
): Promise<boolean> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  // const { algorithm, publicKey } = store.getState().encryption
  // 加密算法 如果用户不修改密码 则不传 接口默认返回密码null
  if (params.userInputs.password === '******' && !edit) {
    delete params.userInputs.password
  } else if (!params.userInputs.password) {
    params.userInputs.password = ''
  } else {
    params.userInputs.password = setEncrypt({algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.userInputs.password})
  }
  /* 密码加密 */
  // params.userInputs.password = btoa(unescape(encodeURIComponent(params.userInputs.password)))
  return fetchPost(`/dms/connection/configuration`, params)
}

export const editConnectionConfig = async (
  params: EditConnectionRequest,
  edit?: boolean
): Promise<boolean> => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  // const { algorithm, publicKey } = store.getState().encryption
  // 加密算法 如果用户不修改密码 则不传 接口默认返回密码null
  if (params.userInputs.password === '******' && !edit) {
    delete params.userInputs.password
  } else if (!params.userInputs.password) {
    params.userInputs.password = ''
  } else {
    params.userInputs.password = setEncrypt({algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.userInputs.password})
  }
  /* 密码加密 */
  // params.userInputs.password = btoa(unescape(encodeURIComponent(params.userInputs.password)))
  return fetchPut(`/dms/connection/configuration`, params)
}

export interface newCreateTableFields {
  columnName: string;
  dataType: string;
  length: number;
  scale: number;
  defaultValue: string;
  virtual: boolean;
  nullable: boolean;
  autoIncrease: boolean;
}

export interface GenerateSqlParams {
  connectionType: DataSourceType;
  databaseName?: string; 
  schemaName?: string;
  tableName: string;
  primaryKeys: string[];
  tableInfo?: {
    comment: string
  };
  columns: newCreateTableFields[]
}

//new 创建表 生成sql
export const newGenerateCreateSql = (
  params: GenerateSqlParams,
): Promise<{ sql: string }> => {
  return fetchPost(`/dms/menu/createTable/generateSql`, params)
}
export interface GenerateSqlRequest {
  nodeType: SdtNodeType
  dataSourceType: DataSourceType
  userInputs: any
}
//old 
export const generateCreateSql = (
  params: GenerateSqlRequest,
): Promise<{ generatedSql: string }> => {
  return fetchPost(`/dms/menu/create`, params)
}

/** 获取字符集对应排序规则 options */
export const getCharacterSetOrder = ({
  connectionType,
  condition,
  connectionId,
}: {
  connectionType: DataSourceType
  condition: string
  connectionId: number | string
}) => {
  return fetchGet(
    `/dms/common/form/${connectionType}/${condition}/${connectionId}`,
  )
}

export const getSchemaOwners = ({
  connectionType,
  connectionId,
}: {
  connectionType: DataSourceType
  connectionId: number | string
}): Promise<string[]> => {
  return fetchGet(`/dms/common/getAllOwners/${connectionType}/${connectionId}`)
}

export type TableColumnProperty =
  | 'columnName'
  | 'columnType'
  | 'columnLength'
  | 'precision'
  | 'unit'
  | 'defaultValue'
  | 'columnComment'
  | 'allowNull'
  | 'autoIncrease'
  | 'primary'
  // id 前端生成
  | 'uuid'

export type TableColumn = Partial<Record<TableColumnProperty, any>>
export interface DesignTableOperation {
  columnName: string
  operationType: 'ALTER' | 'ADD' | 'DROP'
  changedProperties: TableColumn
}
interface DesignTableInputs {
  databaseName: string
  schemaName?: string
  tableName: string
  changedTable?: {
    tableName?: string
    tableComment?: string
    hasCommentProperty?: boolean
  }
  columnOperations: DesignTableOperation[]
  primaryKeys: string[]
  currentColumns: TableColumn[]
  alreadyHasPrimaryKey?: boolean
}
export interface DesignTableRequest {
  connectionId: number | string
  connectionType: DataSourceType
  userInputs: DesignTableInputs
}
export const generateDesignTableSql = (
  params: DesignTableRequest,
): Promise<{ generatedSql: string }> => {
  return fetchPost(`/dms/menu/designTable`, params)
}
// 反显url
export const generateConnectionUrl = (
  params: any,
): Promise<any> => {
  return fetchPost(`/dms/connection/connectionUrlView`, params)
}
