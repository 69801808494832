import { format } from 'sql-formatter';
import dayjs from "dayjs";
/* 获得本季度的开始月份 */
function getQuarterStartMonth(time: string) {
  var nowMonth = dayjs(time).month() + 1;
  var quarterStartMonth = 0;
  if (nowMonth < 3) {
    quarterStartMonth = 0;
  }
  if (2 < nowMonth && nowMonth < 6) {
    quarterStartMonth = 3;
  }
  if (5 < nowMonth && nowMonth < 9) {
    quarterStartMonth = 6;
  }
  if (nowMonth > 8) {
    quarterStartMonth = 9;
  }
  return quarterStartMonth;
}

//获取当前季度
const getCurrentTimeQuarter = (time: string) => {
  var currentDate = new Date(time);
  var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，所以要加1

  var currentQuarter;

  if (currentMonth >= 1 && currentMonth <= 3) {
    currentQuarter = 1;
  } else if (currentMonth >= 4 && currentMonth <= 6) {
    currentQuarter = 2;
  } else if (currentMonth >= 7 && currentMonth <= 9) {
    currentQuarter = 3;
  } else {
    currentQuarter = 4;
  }
  return currentQuarter
}
/* 获得本季度的开始日期 */
export function getQuarterStartAndEndDate(startTime: string) {
  const year = dayjs(startTime).year();
  const currentQuarter = Math.floor((dayjs(startTime).month() / 3)) + 1;
  
  const start = dayjs(new Date(year, (currentQuarter - 1) * 3, 1)).format('x');
  const end = dayjs(new Date(year, currentQuarter * 3, 0)).format('x');

  return {
    beginTimeMs: Number(start),
    endTimeMs: Number(end),
  };
}

export const getStartAndEndTime = (type: string, drillValue: string) => {
  switch (type) {
    case "QUARTER":
      return {
        beginTimeMs: Number(dayjs().startOf("year").format("x")),
        endTimeMs: Number(dayjs().endOf("year").format("x")),
      };
    case "MONTH":
      return getQuarterStartAndEndDate(drillValue);
    case 'ALL_MONTH': 
      return {
        beginTimeMs: Number(
          dayjs().startOf("year").format("x")
        ),
        endTimeMs: Number(
          dayjs().endOf("year").format("x")
        ),
      }
    case "WEEK":
      return {
        beginTimeMs: Number(dayjs(drillValue).startOf("month").format("x")),
        endTimeMs: Number(dayjs(drillValue).endOf("month").format("x")),
      };
    case "DAY":
      return {
        beginTimeMs: Number(
          dayjs(drillValue).startOf("week").format("x")
        ),
        endTimeMs: Number(
          dayjs(drillValue).endOf("week").format("x")
        ),
      };
    case "HOUR":
      return {
        beginTimeMs: Number(
          dayjs(drillValue).startOf("day").format("x")
        ),
        endTimeMs: Number(
          dayjs(drillValue).endOf("day").format("x")
        ),
      };

    default:
      return {};
  }
};
export const getDrillStartAndEndTime = (type: string, drillValue: string) => {
  switch (type) {
    case "QUARTER":
      return getQuarterStartAndEndDate(drillValue);
    case "MONTH":
      return {
        beginTimeMs: Number(dayjs(drillValue).startOf("month").format("x")),
        endTimeMs: Number(dayjs(drillValue).endOf("month").format("x")),
      };
    case 'ALL_MONTH': 
      return {
        beginTimeMs: Number(
          dayjs(drillValue).startOf("month").format("x")
        ),
        endTimeMs: Number(
          dayjs(drillValue).endOf("month").format("x")
        ),
      }
    case "WEEK":
      return {
        beginTimeMs: Number(
          dayjs(drillValue).startOf("week").format("x")
        ),
        endTimeMs: Number(
          dayjs(drillValue).endOf("week").format("x")
        ),
      };
    case "DAY":
    case 'HOUR':
      return {
        beginTimeMs: Number(
          dayjs(drillValue).startOf("day").format("x")
        ),
        endTimeMs: Number(
          dayjs(drillValue).endOf("day").format("x")
        ),
      };

    default:
      return {};
  }
};
//处理图标x轴展示月份
export const formattedTimeAliasValue = (unit: string,v: string) => {

  switch(unit) {
    case 'QUARTER':
      const q = getCurrentTimeQuarter(v)
      return "第" + q + " 季度"
    case 'MONTH':
    case 'ALL_MONTH':
      const m = new Date(v).getMonth();
      return  (m + 1) + " 月"
    case 'DAY':
      const d = dayjs(v).format('MM-DD');
      return  d + " 日"
    case 'HOUR':
      const h = new Date(v).getHours();
      return  h + " 时"
    default: 
      return dayjs(v).format('YYYY-MM-DD')
  }
}

export const getFormattedAlias = (alias: any) => {
  const realAlias = alias && alias.split("---");

  return realAlias[0] && realAlias[0] !== "null"
    ? realAlias[0]
    : realAlias[1];
}