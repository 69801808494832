import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Layout, Row, Col, Breadcrumb } from 'antd'
import { ErrorBoundary, ChartCard, SimpleBreadcrumbs } from 'src/components'
import {
  SqlCountChart,
  UserCountChart,
  DbCountChart,
  ExecutionTimeChart,
  SqlConnectionChart
} from './Charts'
import { DrillDownCard } from './DrillDownCard'
import { StatisticalCard } from './StatisticalCard'
import { SlowSqlChart } from './Charts/SlowSqlChart'
import { UserAuthorizationChart } from './Charts/UserAuthorizationChart';
import 'src/styles/layout.scss'
import styles from './index.module.scss'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { getCardCenter } from 'src/api/getNewData'

const { Header, Content } = Layout

const BREADCRUMB_ITEMS = [
  {
    title: "审计分析",
  },
  {
    title: "审计概览",
  },
];
export const AuditOverviewPage = () => {
  const [data, setData] = useState<any>();
  const { run } = useRequest(getCardCenter, { manual: true });
  useEffect(() => {
    run().then(res => {
      setData(res);
    })
  }, []);


  return (
    <Layout className="cq-container">
      <div style={{ padding: '0 20px' }}>
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
      </div>
      <Layout className={classNames("cq-main", { [styles.auditMain]: true })} >
        <Content
          className={classNames('cq-content', { [styles.auditContent]: true })}
        >
          {
            data ?
              <div style={{ padding: 10, fontSize: 14, color: "#868FA3" }}>共 {data.amount} 条数据 · 按{data.sortType}排序 · 更新于 {data.lastTime}</div>
              : <></>
          }
          <ErrorBoundary>
            <div className={styles.chartContainer}>
              <div style={{ marginBottom: '16px' }}>
                {/* 统计数据、跳转点 */}
                <StatisticalCard />
              </div>
              {/* sql执行次数、sql执行平均时长  视图 */}
              <DrillDownCard />
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <ChartCard title={'慢sql TOP10'}>
                    <SlowSqlChart></SlowSqlChart>
                  </ChartCard>
                  <div style={{ margin: 16 }}></div>
                  <ChartCard title={'SQL 执行次数（数据库类型）'}>
                    <SqlCountChart></SqlCountChart>
                  </ChartCard>
                  <div style={{ margin: 16 }}></div>
                  <ChartCard title={'用户使用量'}>
                    <UserCountChart></UserCountChart>
                  </ChartCard>
                  <div style={{ margin: 16 }}></div>
                  <ChartCard title={'SQL 执行次数（数据库连接）'}>
                    <SqlConnectionChart></SqlConnectionChart>
                  </ChartCard>
                </Col>
                <Col span={12}>
                  <ChartCard title={'用户授权情况'}>
                    <UserAuthorizationChart></UserAuthorizationChart>
                  </ChartCard>
                  <div style={{ margin: 16 }}></div>
                  <ChartCard title={'数据库类型'}>
                    <DbCountChart></DbCountChart>
                  </ChartCard>
                  <div style={{ margin: 16 }}></div>
                  <ChartCard title={'SQL 执行平均时长'}>
                    <ExecutionTimeChart></ExecutionTimeChart>
                  </ChartCard>
                </Col>
              </Row>
            </div>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  )
}
