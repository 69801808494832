import React from 'react'
import { message, Typography, Button } from 'antd'
import { setIsLoggedIn, setUserInfo } from 'src/appPages/login/loginSlice'
import { useDispatch } from 'src/hook'

export const LogoutMessage = () => {
  const dispatch = useDispatch()
  return (
    <Typography.Text>
      用户未登录或登录失效，
      <Button
        type="link"
        size="small"
        onClick={() => {
          message.destroy()
          dispatch(setIsLoggedIn(false))
          // 登出时清理用户信息状态
          dispatch(setUserInfo({}))
        }}
      >
        请立即登录！
      </Button>
    </Typography.Text>
  )
}
