import React, { useState, useMemo } from "react";
import * as _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { Card, Row, Col, Radio, Tag, Select, Tabs } from "antd";
import { SqlCountChart } from "./SqlCountChart";
import { ExecutionTimeRingChart } from "./ExecutionTimeRingChart";
import { ExecutionTimeLineChart } from "./ExecutionTimeLineChart";
import { ExecutionTimeBarChart } from "./ExecutionTimeBarChart";
import classNames from "classnames";
import { SqlTypes } from "src/types/types";
import { useRequest } from "src/hook";
import { getAuditDrillDownData } from "src/api";
import styles from "./chart.module.scss";
import { getStartAndEndTime } from "./utils";

const ALL = 'all'
const HIGH_RISK = 'risk'

export const DrillDownCard = () => {
  const X_DEFAULT_MAPPING: { [k: string]: any } = {
    TIME: {
      posXDim: "TIME",
      timeUnit: "QUARTER",
      alias: `${dayjs().year()}年`
    },
    OBJECT: {
      alias: '数据源类型',
      curDrillType: 'dbTypes'
    },
    ORG: {
      orgUnit: "COMPANY",
      alias: '公司'
    },
  };

  const [filterParams, setFilterParams] = useState<any>({
    posXDim: "TIME",
    timeUnit: "QUARTER",
    showDeleted: true,
    highRiskFlag: ALL,
  });

  //存放time查询条件方便展示
  const [currentTime, setCurrentTime] = useState<any>({})
  const [drillPaths, setDrillPaths] = useState<any>([
    { posXDim: "TIME", timeUnit: "QUARTER", alias: `${dayjs().year()}年` },
  ]);

  // 高危和全部的筛选条件
  const [tabkey, setTabkey] = useState(ALL)

  const {
    data = [],
    loading,
    run: getNewData,
  } = useRequest(
    () => {
      let defaultParams: any = filterParams;

      defaultParams = {
        ...defaultParams,
      };
      if (filterParams?.posXDim === 'TIME') {
        defaultParams = {
          ...defaultParams,
          ...getStartAndEndTime(
            defaultParams?.timeUnit,
            defaultParams?.drillTime
          ),
        }
      } else {
        defaultParams = {
          ...defaultParams,
          beginTimeMs: currentTime?.beginTimeMs,
          endTimeMs: currentTime?.endTimeMs
        }
      }


      if (filterParams?.timeUnit === "ALL_MONTH") {
        defaultParams = {
          ...defaultParams,
          timeUnit: "MONTH",
        };
      }
      delete defaultParams?.curDrillType;
      delete defaultParams?.alias;
      delete defaultParams?.objLinkParams;

      return getAuditDrillDownData(defaultParams);
    },

    {
      refreshDeps: [filterParams],
    }
  );

  const transformSqlExecuteCountData = useMemo(() => {
    let splitSqlCountChartData: any = [];
    let executeAvgData: any = [];
    data &&
      data.map((w: any) => {
        let amountObj = w?.amount || {};
        let execute_avg_obj = w?.execute_avg || {};
        //数据为空处理
        if (_.isEmpty(amountObj)) {
          SqlTypes.map(key => {
            amountObj[key] = 0;
            execute_avg_obj[key] = 0;
          })
        }


        Object.keys(amountObj).map((key) =>
          splitSqlCountChartData.push({
            ...w,
            type: key, //操作类型
            originType: w?.type,
            amount: w.amount[key],
            //@ts-ignore
            alias: `${w.alias}---${w.posX}`,
          })
        );

        //平均时长
        if (filterParams?.posXDim !== "ORG") {
          Object.keys(w.execute_avg).map((key) =>
            executeAvgData.push({
              ...w,
              originType: w?.type,
              type: key, //操作类型
              amount: w.execute_avg[key],
              alias: `${w.alias}---${w.posX}`,
            })
          );
        } else {
          executeAvgData.push({
            ...w,
            execute_avg: execute_avg_obj,
            alias: `${w.alias}---${w.posX}`,
          });
        }
      });
    return {
      splitSqlCountChartData,
      executeAvgData,
    };
  }, [_.cloneDeep(data)]);

  const handleClearTimeFilter = () => {
    let cloneFilterParams: any = _.cloneDeep(filterParams);
    if (filterParams?.posXDim !== "TIME") {
      delete cloneFilterParams?.timeUnit;
      delete cloneFilterParams?.beginTimeMs;
      delete cloneFilterParams?.endTimeMs;
    } else {
      //@ts-ignore
      cloneFilterParams = {
        ...cloneFilterParams,
        timeUnit: "QUARTER",
        highRiskFlag: tabkey,
      };
    }

    setFilterParams(cloneFilterParams);
    setCurrentTime({})

    if (cloneFilterParams?.posXDim === 'TIME') {
      let cloneDrillPath = _.cloneDeep(drillPaths);
      cloneDrillPath = [{ posXDim: "TIME", timeUnit: "QUARTER", alias: `${dayjs().year()}年` }];

      setDrillPaths(cloneDrillPath)
    }
  };

  const onChangeTab = (v: string) => {
    setTabkey(v)
    setFilterParams({ ...filterParams, highRiskFlag: v })
  }

  const renderFilterBtns = (
    <div className={styles.filterGroup}>
      <Tabs
        className={styles.filterGroup__all}
        defaultActiveKey={ALL}
        onChange={onChangeTab}>
        <Tabs.TabPane tab="全部" key={ALL} />
        <Tabs.TabPane tab="高危" key={HIGH_RISK} />
      </Tabs>
      <Radio.Group
        value={filterParams?.posXDim}
        buttonStyle="solid"
        className={styles.filterGroup__module}
        onChange={(e) => {
          setFilterParams({
            timeUnit: filterParams?.timeUnit,
            drillTime: filterParams?.drillTime,
            posXDim: e.target.value,
            ...X_DEFAULT_MAPPING[e.target.value],
            operateTypes: [],
            highRiskFlag: tabkey,
          });
          if (e.target.value === 'TIME') {
            setCurrentTime({})
          }
          setDrillPaths([
            {
              timeUnit: filterParams?.timeUnit,
              drillTime: filterParams?.drillTime,
              posXDim: e.target.value,
              ...X_DEFAULT_MAPPING[e.target.value],
            },
          ]);
        }}
      >
        <Radio.Button value="TIME"> 时间 </Radio.Button>
        <Radio.Button value="OBJECT">数据对象</Radio.Button>
        <Radio.Button value="ORG">组织架构</Radio.Button>
      </Radio.Group>
    </div>
  );

  const renderDrillDownPaths = (
    <div>
      {drillPaths && (
        <>
          <span className={styles.disabledPath}>路径：</span>
          {drillPaths?.length > 1 &&
            drillPaths.map((item: any, index: number) => (
              <span key={index}>
                <span
                  className={classNames(styles.path, {
                    [styles.disabledPath]: drillPaths.length === index + 1,
                  })}
                  onClick={() => {
                    if (index + 1 === drillPaths.length) return;
                    const preItem = drillPaths[index];
                    setFilterParams({ ...preItem, highRiskFlag: tabkey });
                    setDrillPaths(drillPaths.splice(0, index + 1));
                  }}
                >
                  {item?.alias || item?.posXDim}
                </span>
                {drillPaths.length !== index + 1 && (
                  <span className={styles.pathLink}>&nbsp;{`>`}&nbsp;</span>
                )}
              </span>
            ))}
        </>
      )}
    </div>
  );


  const SqlTypeOptions = SqlTypes.map((type) => ({ label: type, value: type }));

  return (
    <div className={styles.drillDownChartWrapper}>
      {renderFilterBtns}
      <Card
        title={renderDrillDownPaths}
        className={styles.drillDownChart}
        bordered={false}
        key='card'
        extra={
          currentTime?.alias && (
            <Tag
              closable={true}
              color="processing"
              style={{ userSelect: "none" }}
              onClose={() => handleClearTimeFilter()}
            >

              {`当前查询条件：${currentTime?.alias}
     `}
            </Tag>
          )
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Card
              title="SQL 执行次数"
              bordered={false}
              headStyle={{ border: "none" }}
              className={styles.sqlDrillDownCard}
              extra={
                <Select
                  showSearch
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  value={filterParams?.operateTypes || []}
                  placeholder="操作类型"
                  options={SqlTypeOptions}
                  style={{
                    marginLeft: 10,
                    width: 180,
                  }}
                  onChange={(values: string[]) =>
                    setFilterParams({ ...filterParams, operateTypes: values, highRiskFlag: tabkey })
                  }
                  getPopupContainer={(node)=> node.parentNode}
                />
              }
            >
              <SqlCountChart
                loading={loading}
                chartData={transformSqlExecuteCountData?.splitSqlCountChartData}
                drillFilterParams={filterParams}
                onChangeDrillParams={(params: any) =>
                  setFilterParams({ ...filterParams, ...params, highRiskFlag: tabkey })

                }
                currentTime={currentTime}
                onUpdateDrillPaths={(path: any) => {
                  setDrillPaths(drillPaths.concat(path));
                  setFilterParams({ ...filterParams, ...path, highRiskFlag: tabkey });
                  if (path?.posXDim === 'TIME') {
                    let cloneCurrentTime = _.cloneDeep(currentTime)
                    cloneCurrentTime = path;

                    setCurrentTime({
                      ...cloneCurrentTime,
                      ...getStartAndEndTime(
                        path?.timeUnit,
                        path?.drillTime
                      ),
                    })
                  }
                }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="SQL 执行平均时长"
              bordered={false}
              headStyle={{ border: "none" }}
            >
              {filterParams?.posXDim === "TIME" && (
                <ExecutionTimeLineChart
                  loading={loading}
                  timeUnit={filterParams?.timeUnit}
                  chartData={transformSqlExecuteCountData?.executeAvgData}
                />
              )}
              {filterParams?.posXDim === "OBJECT" && (
                <ExecutionTimeBarChart
                  loading={loading}
                  chartData={transformSqlExecuteCountData?.executeAvgData}
                />
              )}
              {filterParams?.posXDim === "ORG" && (
                <ExecutionTimeRingChart
                  loading={loading}
                  chartData={transformSqlExecuteCountData?.executeAvgData}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
