import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Button, Form, Input, message, Steps } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import styles from './index.module.scss'
import { useCounter, useInterval } from 'react-use'
import {
  setUserPhone,
} from '../personalCenterSlice'
import { examineCode, checkUserPhoneCode, sendCodeBindNewEmail, sendPhoneCode, updateUserEmail, updateUserPhone } from 'src/api'
import { userPhoneValidator } from 'src/util'
import { CheckCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'

const steps = [
  {
    title: '身份验证',
  },
  {
    title: '新手机号验证',
  },
  {
    title: '完成',
  },
];

export const ModalBindPhone: React.FC<{ record?: any, refresh: () => void }> = ({
  record,
  refresh
}) => {
  const dispatch = useDispatch()
  const visible = useSelector(
    (state) => state.modal.PhoneEditModal,
  )
  const { isBindPhone } = useSelector(
    (state) => state.personalCenter,
  )
  const { smsBindFlag, userPhone } = useSelector(
    (state) => state.personalCenter,
  )
  
  const { userId } = useSelector((state) => state.login.userInfo)
  const [current, setCurrent] = useState(1)
  const [count, { dec, reset, set }] = useCounter(60, 60, 0)
  useEffect(() => {
    set(0)
  }, [set])

  const inputRef = useRef<Input>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useInterval(() => dec(1), 1000)
  const [form] = Form.useForm()

  useEffect(() => {
    setCurrent(1)
  }, [visible])


  /**  发送验证码 */
  // Test
  // const sendBindNewPhoneCode = (phone: string) => {
  //   message.success(`验证码已发送: `+phone)
  // }
  const { run: sendBindNewPhoneCode } = useRequest(sendPhoneCode, {
    manual: true,
    onSuccess(res) {
      message.success(`验证码已发送`)
    }
  })

  /** 检查验证码 */
  // Test
  // const checkUserPhoneCodeRun = () => {
  //   setCurrent(2)
  // }
  // const { run: checkUserPhoneCodeRun } = useRequest(checkUserPhoneCode, {
  //   manual: true,
  //   onSuccess(data) {
  //     setCurrent(2)
  //     // updateUserPhoneRun({ phone: form.getFieldValue(['newPhone']) })
  //   }
  // })

  /** 更新手机号 */
  const { run: updateUserPhoneRun } = useRequest(updateUserPhone, {
    manual: true,
    onSuccess(data) {
      dispatch(setUserPhone(form.getFieldValue('newPhone')))
      setCurrent(2);
    }
  })

  return (
    <UIModal
      title={'绑定手机号'}
      visible={visible}
      footer={[]}
      onCancel={() => {
        dispatch(hideModal('PhoneEditModal'))
        form.resetFields()
      }}
    >
      <>
        <Steps current={current} labelPlacement="vertical">
          {steps.map(({ title }, index) => (
            <Steps.Step title={title} key={index} />
          ))}
        </Steps>
        {
          current === 1 && (
            <div className={styles.boxMargin}>
              <Form form={form} {...FormLayout}>
                <Form.Item label="手机号码" >
                  {
                    <Form.Item
                      name="newPhone"
                      rules={[{ validator: userPhoneValidator }]}
                    >
                      <Input  placeholder="请输入手机号码" />
                    </Form.Item>
                  }
                </Form.Item>
                <Form.Item
                  label="验证码"
                >
                  <div className={styles.disflex}>

                    <Form.Item
                      name="authCode"
                      rules={[{ required: true, message: '请输入验证码' }]}
                    >
                      <Input ref={inputRef} autoFocus />
                    </Form.Item>
                    {
                      <Button
                        disabled={!!count}
                        className={styles.ml10}
                        onClick={() => {
                          form.validateFields(['newPhone']).then(({ newPhone }) => {
                            console.log(' newPhone ', newPhone)
                            // todo 根据手机号码 获取验证码 （ 发送验证码 ：/user/login/send/
                            sendBindNewPhoneCode?.(newPhone)?.then(() => {
                              reset() // 接收 验证码短信的 需等待时间重新计时
                            })

                            // Test
                            // sendBindNewPhoneCode?.(newPhone)
                            // reset()
                          })
                        }}>
                        {count ? `${count}s` : '发送验证码'}
                      </Button>
                    }
                  </div>
                </Form.Item>
              </Form>
              <div className={styles.buttonRight}>
                <Button type="primary" onClick={() => {
                  form.validateFields().then((values) => {
                    console.log("下一步", values, userId)
                    userId && updateUserPhoneRun(values.newPhone, values?.authCode)
                  })
                }}>
                  下一步
                </Button>
              </div>
            </div>
          )
        }
        {current === 2 && (
          <div className={styles.boxMargin}>
            <div className={styles.textAlignCenter}>
              <CheckCircleOutlined className={ classNames(styles.securityIconSuccessIcon) } />
              <div className={styles.pt10}>
                {
                  !isBindPhone ? '手机号绑定成功' : '手机号修改成功'
                }
              </div>
              <Button
                type="primary"
                className={styles.mt20}
                onClick={() => {
                  form.validateFields().then(() => {
                    dispatch(hideModal('PhoneEditModal'))
                    form.resetFields();
                    refresh()
                  })
                }}
              >
                完成
              </Button>
            </div>
          </div>
        )}
      </>
    </UIModal>
  )
}
