import { Form, Input, InputNumber, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { DatePicker } from 'src/components'

const { Item } = Form
const { RangePicker } = DatePicker

export const DesensitizationSettingItems = ({
  option,
  form,
  values,
}: {
  option?: string
  form?: FormInstance
  values?: {
    ruleParam: object
    options: string[] | undefined
  }
  }) => {
  if (!option || !values) return <div></div>

  const itemsMap = new Map()
  values.options &&
    itemsMap.set(
      'REPLACE',
      <RandomMappingItems
        form={form!}
        ruleParam={values.ruleParam}
        options={values.options}
      />,
    )
  itemsMap.set('HIDE', <RandomMaskItems />)
  values.options &&
    itemsMap.set(
      'RANDOM_IN_RANGE',
      <ScopeRandomItems
        form={form!}
        options={values.options}
        ruleParam={values.ruleParam}
      />,
    )
  itemsMap.set(
    'RANDOM_FLOATING',
    <RandomFloatItems form={form!} ruleParam={values.ruleParam} />,
  )
  values.options &&
    itemsMap.set(
      'SUB',
      <CutItems
        form={form!}
        ruleParam={values.ruleParam}
        options={values.options}
      />,
    )
  itemsMap.set(
    'ENCRYPTION',
    <EncryptItems form={form!} ruleParam={values.ruleParam} />,
  )

  values.options && itemsMap.set('DATA_SIMULATION',
   <DataSimulationItems   
      form={form!}
      ruleParam={values.ruleParam}
      options={values.options}/>
  )
  return itemsMap.get(option) || <div></div>
}

// 替换
function RandomMappingItems({
  form,
  ruleParam,
  options,
}: {
  form: FormInstance
  ruleParam: object
  options: string[]
}) {
  const formatOptions = options.map((o) => {
    return {
      label: o,
      value: o,
    }
  })
  form.setFieldsValue({ ruleParam })
  return (
    <>
      <Item
        rules={[{ required: true, message: '不能为空' }]}
        label="开始字符位置"
        name={['ruleParam', 'front']}
        initialValue={1}
      >
        <Input type="number" min={1}></Input>
      </Item>
      <Item
        rules={[{ required: true, message: '不能为空' }]}
        label="替换字符数量"
        name={['ruleParam', 'count']}
        initialValue={1}
      >
        <Input type="number" min={1}></Input>
      </Item>
      <Item
        rules={[{ required: true, message: '不能为空' }]}
        label="替换为"
        name={['ruleParam', 'target']}
        initialValue={'数字'}
      >
        <Select options={formatOptions}></Select>
      </Item>
    </>
  )
}

// 隐藏
function RandomMaskItems() {
  return <></>
}

// 范围内随机
function ScopeRandomItems({
  form,
  options,
  ruleParam,
}: {
  form: FormInstance
  options: string[]
  ruleParam: any
}) {
  const [option, setOption] = useState<string>(ruleParam.type)

  const formatOptions = options.map((o) => {
    return {
      label: o,
      value: o,
    }
  })
  
	useEffect(() => {
		const startTime = ruleParam?.dateRange?.[0] ? dayjs(ruleParam.dateRange[0]) : dayjs(new Date());
		const endTime = ruleParam?.dateRange?.[1] ? dayjs(ruleParam.dateRange[1]) : dayjs(new Date()).add(1, "day");

		form.setFieldsValue({ ruleParam: { ...ruleParam, dateRange: [startTime, endTime] } });
	}, [ruleParam]);

  return (
    <>
      <Item
        rules={[{ required: true, message: '不能为空' }]}
        label="数据类型"
        name={['ruleParam', 'type']}
      >
        <Select
          options={formatOptions}
          onChange={(v: string) => {
            setOption(v)
          }}
        ></Select>
      </Item>
      {option === '日期' && (
        <Item
          rules={[{ required: true, message: '不能为空' }]}
          label="范围"
          name={['ruleParam', 'dateRange']}
        >
          <RangePicker />
        </Item>
      )}
      {option === '数字' && (
        <Item label="范围" initialValue={0}>
          <Item
            rules={[{ required: true, message: '不能为空' }]}
            name={['ruleParam', 'min']}
            style={{ display: 'inline-block' }}
          >
            <InputNumber />
          </Item>
          <Item
            rules={[{ required: true, message: '不能为空' }]}
            style={{ display: 'inline-block' }}
          >
            <span style={{ display: 'inline-block' }}>&nbsp;至&nbsp;</span>
          </Item>
          <Item
            rules={[{ required: true, message: '不能为空' }]}
            name={['ruleParam', 'max']}
            style={{ display: 'inline-block' }}
            initialValue={0}
          >
            <InputNumber />
          </Item>
        </Item>
      )}
    </>
  )
}

// 随机浮动
function RandomFloatItems({
  form,
  ruleParam,
}: {
  form: FormInstance
  ruleParam: object
  }) {
  form.setFieldsValue({ ruleParam })

  return (
    <>
      <Item label="范围">
        <Item
          validateFirst
          rules={[{ required: true, message: '不能为空' }]}
          name={['ruleParam', 'min']}
          style={{ display: 'inline-block' }}
          initialValue={0}
        >
          <InputNumber
            formatter={(value) => `${value}%`}
            parser={(value) => value!.replace('%', '')}
          />
        </Item>
        <Item style={{ display: 'inline-block' }}>
          <span style={{ display: 'inline-block' }}>&nbsp;至&nbsp;</span>
        </Item>
        <Item
          validateFirst
          dependencies={['min']}
          rules={[
            { required: true, message: '不能为空' },
            {
              validator: (rule, value) => {
                const minVal = form.getFieldValue('min')
                if (value < minVal) {
                  return Promise.reject(new Error(`不能小于${minVal}%`))
                }
                return Promise.resolve()
              },
            },
          ]}
          name={['ruleParam', 'max']}
          style={{ display: 'inline-block' }}
          initialValue={0}
        >
          <InputNumber
            formatter={(value) => `${value}%`}
            parser={(value) => value!.replace('%', '')}
          />
        </Item>
      </Item>
    </>
  )
}

// 截取
function CutItems({
  form,
  ruleParam,
  options,
}: {
  form: FormInstance
  ruleParam: object
  options: string[]
}) {
  const formatOptions = options.map((o) => {
    return {
      label: o,
      value: o,
    }
  })

  form.setFieldsValue({ ruleParam })

  return (
    <Item label="保留" style={{ textAlign: 'center' }}>
      <Item
        rules={[{ required: true, message: '不能为空' }]}
        name={['ruleParam', 'type']}
        style={{ display: 'inline-block', width: '40%' }}
        initialValue="前"
      >
        <Select options={formatOptions}></Select>
      </Item>
      <Item
        rules={[{ required: true, message: '不能为空' }]}
        name={['ruleParam', 'count']}
        style={{ display: 'inline-block', width: '40%' }}
        initialValue={1}
      >
        <Input type="number"></Input>
      </Item>
      <Item style={{ display: 'inline-block' }}>&nbsp;位</Item>
    </Item>
  )
}

// 加密（sqlserver不支持）
function EncryptItems({
  form,
  ruleParam,
}: {
  form: FormInstance
  ruleParam: object
}) {
  form.setFieldsValue({ ruleParam })

  return (
    <>
      <Item label="加密算法">AES128</Item>
      {/* <Item label="密钥">
        <Item
          rules={[{ required: true, message: '不能为空' }]}
          name={['ruleParam', 'secretKey']}
          style={{ display: 'inline-block', width: '70%' }}
        >
          <Input disabled></Input>
        </Item>
      </Item> */}
    </>
  )
}

// 脱敏仿真
function DataSimulationItems({
  form,
  ruleParam,
  options
}: {
  form: FormInstance
  ruleParam: object
  options: string[]
}) {
  const formatOptions = options.map((o) => {
    return {
      label: o,
      value: o,
    }
  })

  form.setFieldsValue({ ruleParam })

  return (
    <Item
    label='数据仿真类型'
    rules={[{ required: true, message: '不能为空' }]}
    name={['ruleParam', 'ruleName']}
    initialValue={0}
  >
    <Select options={formatOptions || []}></Select>
  </Item>
  )
}