import type { Encoding } from 'src/constants'
import {
  fetchDelete,
  fetchGet,
  fetchWithErrorHandler,
  fetchPost,
  fetchPostFormData,
} from './customFetch'

export type FileType = 'directory' | 'file'
export interface UserFile {
  isDir: boolean
  name: string
  path: string
  type: FileType
  size?: string
  isSystemSpaceDirChild?: boolean
  condition?: string
  connectionName?: string | null
}
export const getUserFiles = (params :{ path: string, condition: string }): Promise<UserFile[]> => {
  return fetchGet(`/export/dir`, { path: params?.path, condition: params?.condition })
}

export const getIncludeDir = (path: string): Promise<UserFile[]> => {
  return fetchGet(`/export/includeDir`, { path })
}

export const makeDirectory = (path: string) => {
  return fetchPost(`/export/dir`, { path })
}

export interface FileInfo {
  path: string
  lastAccessTime: string
  lastModifiedTime: string
  creationTime: string
  size: string
  isSymbolicLink: boolean
  name: string
  isRegularFile: boolean
  childCount: number
  type: FileType
  isOther: boolean
  isDirectory: boolean
  childCountAll: string
}
export const getFileInfo = (path: string): Promise<FileInfo> => {
  return fetchGet(`/export/file/info`, { path })
}
export const createFolder = (path: string) => {
  path = path.replace('//', '/')
  // todo: 统一机制区别处理根目录和非根目录
  return fetchPost(`/export/dir`, { path })
}
export const deleteFiles = (paths: string[]) => {
  return fetchDelete(`/export/files`, paths)
}

export const getRecentFiles = (): Promise<UserFile[]> => {
  return fetchGet(`/export/file/latest`)
}

export const getFileContent = async (path: string) => {
  const response = await fetchWithErrorHandler(
    `/export/file/content?path=${path}`,
  )
  const arrayBuffer = await response.arrayBuffer()
  const encoding = (response.headers.get('Content-Encoding') ||
    'UTF-8') as Encoding
  let textDecoder: TextDecoder
  try {
    textDecoder = new TextDecoder(encoding)
  } catch {
    // fallback to utf-8
    textDecoder = new TextDecoder()
  }
  const content = textDecoder.decode(arrayBuffer)
  return { content, encoding }
}

export const saveDocument = (
  content: string,
  path: string = '/未命名文档.sql',
  encode: string = 'UTF-8',
) => {
  return fetchPost(`/export/upload/json`, {
    content,
    path: path.replace('//', '/'),
    encode,
  })
}

export const uploadFileChunks = (params: any) => {
  return fetchPostFormData(`/export/upload`, params)
}

export const validateFileExist = (params: any) => {
  return fetchPost(`/export/validateFile`, params)
}

/** 文件夹｜文件 重命名 */
export const reNameFiles = (params: {
  newName: string,
  sourcePath: string
}) => {
  return fetchPost(`/export/files/rename?newName=${params?.newName}&sourcePath=${params?.sourcePath}`)
}

/** 文件夹｜文件 移动 */
export const moveFiles = (params: {
  targetPath: string,
  sourcePath: string
}) => {
  return fetchPost(`/export/files/move?sourcePath=${params?.sourcePath}&targetPath=${params?.targetPath}`,)
}