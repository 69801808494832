export const treeToArray = (tree: any[]) => {
  let arr: any[] = []
  const flatTree = (data: any) => {
    data?.forEach((i: any) => {
      flatTree(i?.children)
      const item = { ...i }
      delete item?.children
      arr.push(item)
    })
  }
  flatTree(tree)
  return arr
}