import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { DataSourceType, getDatabaseMeta} from 'src/api'


type DataSourceStateMeta ={
  [key in DataSourceType]?: any
}

interface SetMetaAction {
  dataSource:DataSourceType
  meta:object
}

const initialState: DataSourceStateMeta = {

}
export const dataSourceMetaSlice = createSlice({
  name: 'dataSourceMeta',
  initialState,
  reducers: {
    setDataSourceMeta: (state, action: PayloadAction<SetMetaAction>) => {
      let { dataSource,meta } =  action.payload
      state[dataSource] = meta
    },
  },
})

export const dataSourceMetaReducer = dataSourceMetaSlice.reducer

const { setDataSourceMeta } = dataSourceMetaSlice.actions

export const setDataSourceMetaDispatch = (type:DataSourceType): AppThunk => (dispatch,getState) => {
   const { dataSourceMeta } = getState();
  if ( type in dataSourceMeta) return;
   getDatabaseMeta({connectionType:type}).then((dataSourceMeta) => {
    dispatch(setDataSourceMeta({dataSource:type,meta:dataSourceMeta}))
  })
}
