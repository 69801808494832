import React, { useContext, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Alert, Form, message, Space, Radio } from 'antd'
import { UIModal } from 'src/components'
import { ConnBindUserForm } from '../forms/ConnBindUserForm'
import { ConnBindOrganizationForm } from '../forms/ConnBindOrganizationForm'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { RoleRecordContext } from '../contexts/RoleRecordContext'
import {
  bindUsersToRole_dataSource,
  roleBindOrganization_api,
  OrganizationNode,
} from 'src/api'
import difference from 'lodash/difference'
import { ConnectionContext } from '../contexts/ConnectionContext'

export enum SELECT_TYPE {
  BIND_USER = 'BIND_USER',
  BIND_ORGANIZATION = 'BIND_ORGANIZATION',
}

export const ModalConnBindUser = () => {
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.ModalConnBindUser)
  const { mode, record, refreshRoles } = useContext(RoleRecordContext)
  const [form] = Form.useForm()

  const { connection } = useContext(ConnectionContext)
  const connectionId = connection?.connectionId

  const currentUserIds = record?.userInfos.map(({ userId }) => userId) || []

  const [selectType, setSelectType] = useState<SELECT_TYPE>(
    SELECT_TYPE.BIND_USER,
  )

  const { run: bindUsers, loading: bindingUser } = useRequest(bindUsersToRole_dataSource, {
    manual: true,
    onSuccess: () => {
      message.success('绑定成功')
      dispatch(hideModal('ModalConnBindUser'))
      refreshRoles?.()
    },
  })

  const bindUser = async () => {
    try {
      const formValues = await form.validateFields()
      const boundUsers = (formValues.boundUsers as string[]) || []
      const addList = difference(boundUsers, currentUserIds)
      const removeList = difference(currentUserIds, boundUsers)

      bindUsers({
        //拿不到值给-1
        roleId: record?.roleId || -1,
        grantUser: addList,
        removeUser: removeList,
        roleName: record?.roleName || '',
        connectionId,
      })
    } catch {}
  }

  /* 组织架构data */
  const [organizationRole, setOrganizationRole] = useState<OrganizationNode[]>(
    [],
  )
  const bindOrganzation = async () => {
    const orgMap: any = {
      USER: 'grantUser',
      DEPT: 'deptName',
      GROUP: 'groupName',
    }
    const payload = organizationRole.reduce(
      (prev, node) => {
        const { nodePath, orgType } = node
        if ((!orgMap[orgType] as any) in prev) return prev
        prev[orgMap[orgType]].push(nodePath)
        return prev
      },
      { grantUser: [], deptName: [], groupName: [] } as any,
    )
    await roleBindOrganization_api({
      ...payload,
      roleName: record?.roleName || '',
    })
  }

  const { run: bindOrg, loading: bindingOrg } = useRequest(bindOrganzation, {
    manual: true,
    onSuccess() {
      message.success('绑定成功')
      dispatch(hideModal('ModalConnBindUser'))
      refreshRoles?.()
      setOrganizationRole([])
      setSelectType(SELECT_TYPE.BIND_USER)
    },
  })

  return (
    <UIModal
      title="绑定用户"
      visible={visible}
      onOk={async () => {
        if (!record) return
        if (selectType === SELECT_TYPE.BIND_USER) {
          bindUser()
        } else {
          bindOrg()
        }
      }}
      onCancel={() => dispatch(hideModal('ModalConnBindUser'))}
      confirmLoading={bindingUser || bindingOrg}
      width={800}
      bodyStyle={{ minHeight: '200px' }}
    >
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        {record && (
          <div>
            <strong>角色名：</strong>
            {record.roleName}
          </div>
        )}
        {record?.initRole && (
          <Alert
            type="warning"
            message="从连接初始化角色下解绑用户，会同时将用户从该连接下移除"
          />
        )}
        <Radio.Group
          onChange={(e) => setSelectType(e.target.value)}
          value={selectType}
        >
          {/* <Radio.Button value={SELECT_TYPE.BIND_USER}>用户绑定</Radio.Button>
          <Radio.Button value={SELECT_TYPE.BIND_ORGANIZATION}>
            组/部门绑定
          </Radio.Button> */}
        </Radio.Group>
        {selectType === SELECT_TYPE.BIND_USER && (
          <ConnBindUserForm
            form={form}
            mode={mode}
            record={record}
            connectionId={connectionId}
          />
        )}
        {selectType === SELECT_TYPE.BIND_ORGANIZATION && (
          <ConnBindOrganizationForm
            mode={mode}
            record={record}
            formValue={organizationRole}
            setFormValue={setOrganizationRole}
          />
        )}
      </Space>
    </UIModal>
  )
}
