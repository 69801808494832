import { ColumnsType } from 'antd/lib/table'
import { AppSql } from 'src/api'
import { renderItemField } from './render'

export const AppSqlColumns: ColumnsType<AppSql> = [
  {
    title: 'SQL 模式',
    dataIndex: 'sqlPattern',
    render: renderItemField('longString'),
  },
  {
    title: '最近执行',
    dataIndex: 'lastExecutedAt',
    ellipsis: { showTitle: false },
    width: '120px',
    render: renderItemField('timeString'),
  },
  {
    title: '执行主机',
    dataIndex: 'hostname',
    ellipsis: true,
    width: '120px',
  },
  {
    title: '平均耗时 (μs)',
    dataIndex: 'costAvg',
    ellipsis: true,
    width: '120px',
  },
  {
    title: '总耗时 (ms)',
    dataIndex: 'costTotal',
    ellipsis: true,
    width: '120px',
  },
  {
    title: '执行次数',
    dataIndex: 'executeCount',
    ellipsis: true,
    width: '96px',
  },
]
