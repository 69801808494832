/*
 * @Author: fuzhenghao
 * @Date: 2023-01-06 11:27:03
 * @LastEditTime: 2023-01-11 13:55:59
 * @LastEditors: fuzhenghao
 * @Description: 我的审批-已处理
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\FlowTableResolved.tsx
 */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery, useRequest, useResizeObserver, useSelector } from 'src/hook'
import { Space, Select, Table, Input } from 'antd'
import { FlowTableRecord, FlowType } from 'src/types'
import { ColumnsType } from 'antd/lib/table'
import { getAllSimpleUsers, getResolvedApproval } from 'src/api'
import { useHistory } from 'react-router-dom'
import {
  flowTypeLabelMap,
  statusOptions,
  flowTypeOptions,
  applyTimeOptions,
} from './constants'
import dayjs from 'dayjs'
import { SearchOutlined } from '@ant-design/icons'
import { LinkButton } from 'src/components'
import { ModalFlowDetail } from './flowDetails'
import { renderStatusTag } from './utils'
import styles from './flowPage.module.scss'

const { Option } = Select

export const FlowTableResolved = React.memo(
  ({ isFocused }: { isFocused?: boolean }) => {
    const userId = useSelector((state) => state.login.userInfo.userId)
    const [visible, setVisible] = useState(false) // 流程详情 modal visible
    const [activeFlowId, setActiveFlowId] = useState<string>() // 流程详情 当前 流程 id
    const [activeRecord, setActiveRecord] = useState<any>() // 流程详情 当前 流程 record记录
    const [userList, setUserList] = useState<any[]>([]) // 全部用户列表

    const columns: ColumnsType<FlowTableRecord> = [
      { dataIndex: 'uuid', title: '编号', width: 168, ellipsis: true },
      {
        dataIndex: 'applyUserName',
        title: '申请人',
        width: 114,
        ellipsis: true,
      },
      {
        dataIndex: 'applyType',
        title: '类型',
        render: (_, { applyType }) => flowTypeLabelMap[applyType as FlowType],
        width: 114,
        ellipsis: true,
      },
      { dataIndex: 'deptName', title: '发起部门', width: 72, ellipsis: true },
      {
        dataIndex: 'currentAssignee',
        title: '当前节点',
        width: 72,
        ellipsis: true,
      },
      {
        dataIndex: 'applyTime',
        title: '发起时间',
        render: (_, { applyTime }) =>
          dayjs(applyTime).format('YYYY-MM-DD HH:mm:ss'),
        width: 114,
      },
      {
        dataIndex: 'lastOperatingTime',
        title: '最后操作时间',
        render: (_, { lastOperatingTime }) =>
          dayjs(lastOperatingTime).format('YYYY-MM-DD HH:mm:ss'),
        width: 114,
      },
      {
        dataIndex: 'applyStatus',
        title: '状态',
        render: (_, { applyStatus }) => renderStatusTag(applyStatus),
        width: 88,
        ellipsis: true,
      },
      {
        key: 'action',
        title: '操作',
        render: (_, record) => {
          let { applyId } = record
          return (
            <LinkButton
              className={styles.linkButton}
              onClick={() => {
                setActiveRecord(record)
                setActiveFlowId(applyId as string)
                setVisible(true)
              }}
            >
              查看过程
            </LinkButton>
          )
        },
        width: 114,
      },
    ]

    // search params
    const history = useHistory()
    const search = useQuery()
    const currentPage = Number(search.get('p')?.toString()) || 1
    const pageSize = Number(search.get('s')?.toString()) || 10
    const [statusFilter, setStatusFilter] = useState<string>()
    const [typeFilter, setTypeFilter] = useState<string>()
    const [timeFilter, setTimeFilter] = useState<string>()
    const inputValueRef = useRef<string>()

    // request datasource
    const { data, loading, run } = useRequest(getResolvedApproval, {
      manual: true,
      formatResult: (data) => {
        let { pageNumber, pageSize, list } = data
        const newList = list.map((item: any, index: number) => {
          item.indexKey = (pageNumber - 1) * pageSize + index + 1
          return item
        })

        const total = data.totalCount
        return { list: newList, total }
      },
      refreshOnWindowFocus: true,
      focusTimespan: 600000,
    })
    const handleRequest = useCallback(() => {
      if (userId) {
        const applyType = typeFilter !== '*' ? typeFilter : undefined
        const applyStatus = statusFilter !== '*' ? statusFilter : undefined
        const applyUserName = inputValueRef.current || undefined
        let startApplyTime
        let endApplyTime
        switch (timeFilter) {
          case '-7':
            startApplyTime = dayjs().subtract(7, 'd').format()
            endApplyTime = dayjs().format()
            break
          case '-30':
            startApplyTime = dayjs().subtract(30, 'd').format()
            endApplyTime = dayjs().format()
            break
          default:
            break
        }

        run({
          userId,
          pageSize,
          currentPage,
          applyType,
          applyStatus,
          startApplyTime,
          endApplyTime,
          applyUserName,
        })
      }
    }, [
      currentPage,
      pageSize,
      run,
      statusFilter,
      timeFilter,
      typeFilter,
      userId,
    ])
    useEffect(() => {
      if (isFocused) {
        handleRequest()
      }
    }, [handleRequest, isFocused])

    useEffect(() => {
      // 获取用户列表
      const getUserList = async () => {
        try {
          const users = await getAllSimpleUsers()
          setUserList(users)
        } catch {}
      }
      getUserList()
    }, [])

    const handleChangePagination = (current: number, pageSize?: number) => {
      search.set('p', current.toString())
      if (pageSize) {
        search.set('s', pageSize.toString())
      }
      history.replace({ search: search.toString() })
    }

    // ? ant table dom 层级很多，纵向滚动通过 css 精确计算很麻烦；
    // ? 这里用 resize obserber 监听到的值粗略减去表头和分页器，作为 y 轴 max-height
    const [rect, domRef] = useResizeObserver()

    return (
      <div className={styles.contentCardBody} ref={domRef}>
        <div className={styles.searchArea}>
          <Space>
            <Select
              className={styles.searchSelect}
              value={statusFilter}
              placeholder="审批状态"
              options={statusOptions}
              onChange={setStatusFilter}
            />
            <Select
              className={styles.searchSelect}
              placeholder="流程类型"
              value={typeFilter}
              options={flowTypeOptions}
              onChange={setTypeFilter}
              style={{ width: 120 }}
            />
            <Select
              className={styles.searchSelect}
              placeholder="提交日期"
              value={timeFilter}
              onChange={setTimeFilter}
              options={applyTimeOptions}
            />
          </Space>
          <Select
            placeholder="搜索申请人姓名..."
            onChange={(selected: string) => {
              inputValueRef.current = selected
              console.log(selected)
              handleRequest()
            }}
            allowClear
            style={{ minWidth: 144, width: 256 }}
            size="middle"
            optionFilterProp="label"
            showSearch={true}
          >
            {userList.map((user) => {
              const { userId, userName } = user
              return (
                <Option
                  key={userId}
                  value={userId}
                  label={`${userName}(${userId})`}
                >
                  {`${userName}(${userId})`}
                </Option>
              )
            })}
          </Select>
        </div>
        <Table<FlowTableRecord>
          className={styles.table}
          columns={columns}
          rowKey={({ indexKey }) => indexKey}
          dataSource={data?.list}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize,
            total: data?.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: handleChangePagination,
          }}
          size="middle"
          scroll={{ x: rect?.width, y: rect ? rect.height - 200 : 0 }}
        />
        {/* 流程详情 moda */}
        <ModalFlowDetail
          key={activeFlowId}
          record={activeRecord}
          visible={visible}
          applyId={activeFlowId as any}
          kind="approval"
          onCancel={() => setVisible(false)}
        />
      </div>
    )
  },
)
