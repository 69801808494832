import React, { useState, useEffect, CSSProperties } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

interface LazyWrapperProps {
  active?: boolean
  forceRender?: boolean
  className?: string
  style?: CSSProperties
}

/** 懒渲染容器, 尚未激活则不渲染 children */
export const LazyWrapper: React.FC<LazyWrapperProps> = (props) => {
  const { active, forceRender, className, style, children } = props
  const [visited, setVisited] = useState(false)

  useEffect(() => {
    if (active) {
      setVisited(true)
    }
  }, [active])
  return (
    <div
      className={classNames(
        styles.lazyWrapper,
        active && styles.show,
        className,
      )}
      style={style}
    >
      {(forceRender || active || visited) && children}
    </div>
  )
}
