import React from 'react'
import { useContextMenu } from './useContextMenu'
import { ICellRendererParams } from '@ag-grid-community/core'
import styles from './SimpleMarkRenderer.module.scss'

export const SimpleTextRenderer = (params: ICellRendererParams) => {
  // console.log(params, 'in simple render')
  //若value 为对象  为保证不报错 转为字符串显示
  if (typeof params.value === 'object' && params.value !== null) {
    return <div>{'<' + JSON.stringify(params.value) + '>'}</div>
  }

  let cell = (
    <div className={styles.textCell}>
      {/* {params.value ?? ''} */}
      {typeof params.value === 'boolean'
        ? params.value.toString()
        : params.value ?? ''}
    </div>
  )

  if (params.value === null) {
    cell = <div className={styles.placeholder}>{`<null>`}</div>
    // cell = <input className={styles.placeholder} placeholder="<null>" />
  }

  return cell
}

export const SimpleTextRendererWithContextMenu = (
  params: ICellRendererParams,
) => {
  const [wrapper] = useContextMenu(params)

  return wrapper(SimpleTextRenderer(params))
}
