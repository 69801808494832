import { ISqlStatement } from "../sql/SqlStatement";
import MySqlLexer from "./parser/MySqlLexer";
import { CharStream, Token } from "antlr4";
import { SqlBlockInfo } from "../sql/SqlBlockInfo";

export class MySQLSqlParser {
  public static readonly FlowControlBlockStatementType: number[] = [
    MySqlLexer.IF,
    MySqlLexer.CASE,
    MySqlLexer.WHILE,
    MySqlLexer.REPEAT,
    MySqlLexer.LOOP,
  ];

  public static readonly CreateOrDropObjectWithIfType: number[] = [
    MySqlLexer.FUNCTION,
    MySqlLexer.PROCEDURE,
    MySqlLexer.EVENT,
    MySqlLexer.TABLE,
    MySqlLexer.DATABASE,
    MySqlLexer.SCHEMA,
    MySqlLexer.VIEW,
    MySqlLexer.TRIGGER,
    MySqlLexer.SERVER,
  ];

  split(text: string): ISqlStatement[] {
    const charStream = new CharStream(text);
    const lexer = new MySqlLexer(charStream);
    lexer.removeErrorListeners();

    let start = -1;
    let startLine = 1;
    let startColumn = 0;
    const delimiter = ";";
    let prevToken: Token | null = null;
    let block: SqlBlockInfo | null = null;
    const sqls: ISqlStatement[] = [];
    for (;;) {
      const token = this.nextToken(lexer);
      if (start === -1) {
        start = token.start;
        startLine = token.line;
        startColumn = token.column;
      }
      try {
        if (token.type === Token.EOF) {
          if (start < token.stop && prevToken !== null) {
            sqls.push({
              originalText: text.substring(start, prevToken.stop + 1),
              text: text.substring(start, prevToken?.stop + 1),
              offset: start,
              length: prevToken.stop - start + 1,
              startLine: startLine,
              startColumn: startColumn,
              stopLine: prevToken.line,
              stopColumn:
                prevToken.column + prevToken.stop - prevToken.start + 1,
            });
          }
          break;
        }

        // 处理 ';' 为结束符的情况
        if (token.text === delimiter && block === null) {
          if (start < token.start) {
            sqls.push({
              originalText: text.substring(start, token.stop + 1),
              text: text.substring(start, token.stop + 1),
              offset: start,
              length: token.stop - start + 1,
              startLine: startLine,
              startColumn: startColumn,
              stopLine: token.line,
              stopColumn: token.column + token.stop - token.start + 1,
            });
          }
          start = -1;
          continue;
        }

        if (token.type === MySqlLexer.BEGIN) {
          block = new SqlBlockInfo(block, false);
        } else if (token.type === MySqlLexer.END) {
          if (block !== null) {
            block = block.parent;
          }
        } else if (
          MySQLSqlParser.FlowControlBlockStatementType.includes(token.type)
        ) {

          if (
            block !== null &&
            prevToken !== null &&
            prevToken.type !== MySqlLexer.END
          ) {
            if (
              token.type === MySqlLexer.IF &&
              MySQLSqlParser.CreateOrDropObjectWithIfType.includes(
                prevToken.type
              )
            ) {
            } else {
              block = new SqlBlockInfo(block, false);
            }
          }
        }
      } finally {
        prevToken = token;
      }
    }
    return sqls;
  }

  nextToken(lexer: MySqlLexer): Token {
    while (true) {
      const token = lexer.nextToken();
      if (MySqlLexer.channelNames[token.channel] !== "DEFAULT_TOKEN_CHANNEL") {
        continue;
      }
      return token;
    }
  }
}
