import React, { useEffect } from 'react'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { useRequest } from 'src/hook'
import { getUerAuthorization } from 'src/api/getNewData'
import { Chart } from '@antv/g2';
import { judgeIsNoData } from '../../utils'
import { EmptyChart } from './EmptyChart'

let chart: any;
export const UserAuthorizationChart = () => {
  const { data, loading } = useRequest(getUerAuthorization, { manual: false });
  const history = useHistory();

  const renderUserAuthorizationChart = (container: any, data: any) => {

    const chart = new Chart({
      container,
      autoFit: true,
      height: 300,
      theme: {
        maxColumnWidth: 40,
      },
    });

    // .reverse() 实现和缩略图的联动，初始化缩略图为反向
    chart.data(data.reverse());

    chart.scale('amount', {
      nice: true
    });

    chart.tooltip({
      shared: true,
      showMarkers: false,
    });

    chart.option("slider", {});

    chart
      .interval()
      .adjust('stack')
      .position('unit*amount')
      .color('amountType', ['#e87a77', '#4984f3'])

    // 点击事件，这里仅对不可点击的时候生效，做点击到最后一层跳转到对应语句明细功能
    chart.on("element:click", (ev: any) => {
      const { amountType, unit } = ev?.data?.data;

      const timeRange = [
        dayjs(unit).startOf("day").valueOf(),
        dayjs(unit).endOf("day").valueOf(),];

      const state = {
        timeRange: timeRange,
        operates: [amountType === "通过" ? "审批通过" : "审批拒绝"],
      }

      history.push({
        pathname: "/operate_record",
        state: state,
      })
    })

    chart.interaction('active-region');

    chart.render();
    return chart;
  }


  useEffect(() => {
    if (judgeIsNoData(data)) return;
    chart = renderUserAuthorizationChart("userAuthorizationContainer", data);

    return () => {
      chart && chart.destroy();
      chart = null;
    };
  }, [data]);

  return (
    <Spin spinning={loading}>
      <div id="userAuthorizationContainer">
        {judgeIsNoData(data) && <EmptyChart></EmptyChart>}
      </div>
    </Spin>
  )
}
