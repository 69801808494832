/*
 * @Author: fuzhenghao
 * @Date: 2022-11-17 19:28:36
 * @LastEditTime: 2022-12-02 19:02:07
 * @LastEditors: fuzhenghao
 * @Description:
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\queryPage\queryTabs\terminalPane\TerminalPane.tsx
 */
import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { DataSourceType, getTerminalParams } from 'src/api'

interface TerminalPaneProps {
  connectionId: number | string
}

const dialectAdapter = (dialect: DataSourceType) => {
  // TODO: 是否需要增加更多特定
  switch (dialect) {
    case 'DamengDB':
      return 'damengsql'
    case 'MariaDB':
      return 'mysql'
    case 'MongoDB':
      return 'mongo'
    case 'GaussDB':
    case 'OpenGauss':
      return 'gausssql'
    default:
      return dialect.toLowerCase()
  }
}

export const TerminalPane = ({ connectionId }: TerminalPaneProps) => {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME'

  useEffect(() => {
    //多个窗口情况
    const iframe = document.getElementById(`${connectionId}iframe-terminal`)

    if (iframe) {
     iframe.onload = () => {
        if (isIFrame(iframe) && iframe?.contentWindow) {
          const eleWrapper =
            iframe.contentWindow.document.getElementsByClassName('xterm-rows')
          const ele = eleWrapper && (eleWrapper?.[0] as HTMLElement)
         console.log(ele,'打开终端--');
         
          if (ele) {
            ele.style.width = '100%'
            ele.style.overflowX = 'hidden'
          }
        }
      }
    }
    
  }, [connectionId, url])

  useEffect(() => {
    setLoading(true)
    try {
      getTerminalParams(connectionId).then(({ dialect, ...rest }) => {
        const url =
          '/terminal/?arg=' +
          JSON.stringify({
            dialect: dialectAdapter(dialect),
            ...rest,
          })
        setUrl(url)
      })
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }, [connectionId])
  return (
    <Spin spinning={loading}>
      <div
        style={{
          textAlign: 'center',
          height: 'calc(100vh - 64px - 80px - 24px)',
          lineHeight: '50vh',
        }}
      >
        {url ? (
          <iframe
            src={url}
            style={{ width: '100%', height: '100%' }}
            id={`${connectionId}iframe-terminal`}
            title="terminal"
            frameBorder={0}
          ></iframe>
        ) : (
          <div style={{ fontSize: 24 }}>终端暂不可用</div>
        )}
      </div>
    </Spin>
  )
}
