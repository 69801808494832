import { Button, Card, Form, Steps, message, Spin, Tabs, Anchor } from "antd";
import { FormInstance } from "antd/lib/form";
import React, { ReactNode, useRef, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { createTransferTask } from "src/api/dataTransfer";
import { useDispatch, useRequest, useSelector } from "src/hook";
import { setFirstFormInstance, setSecondFormInstance, setThirdFormInstance, resetAllFilledTaskInfo } from "../CreateTransferTaskSlice";
import styles from '../index.module.scss';
import { FirstForm, SecondForm, ThirdForm } from "./StepForms";

const { Step } = Steps;
const stepMap: Record<string, string> = {
	0: "选择源与目标",
	1: "选择数据库对象",
	2: "提交任务",
};
const FINAL_STEP = 2;

const domScrollIntoView = (id: string) => {
	let anchorElement = document.getElementById(id);
	// 如果对应id的锚点存在，就跳转到锚点
	if(anchorElement) { anchorElement.scrollIntoView(); }
}
const MainContent = (): JSX.Element => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [disabledStep, setDisabledStep]  = useState<number>(2); // 上一步没有设置过的步骤disabled
	const [currentTab, setCurrentTab]= useState<string>('1')
	const firstFormRef = useRef<FormInstance<any>>(null);
	const secondFormRef = useRef<FormInstance<any>>(null);
	const thirdFormRef = useRef<FormInstance<any>>(null);
	
	const { firstFormInstance, secondFormInstance } = useSelector(state => state.createTransfer);
	const { userInfo } = useSelector(state => state.login);
	const { run: postTask, loading } = useRequest(createTransferTask, {
		manual: true,
		onSuccess() {
			message.success("创建成功");
			history.push('/data_transform')
		},
	});

	useEffect(() => {
      return () => {
		dispatch(resetAllFilledTaskInfo())
	  }
	},[])

	function handleSubmitTask() {
		const paraments = currentTab === '1' ? 
		{
			taskType: 'DATA_TRANSMISSION',
			desensitizationGrade: 1
		}
		:
		{
			taskType: 'STATIC_DESENSITIZATION',
			desensitizationGrade: 0
		} 
		firstFormRef.current?.validateFields().then(()=>{
			secondFormRef.current?.validateFields().then(()=>{
				thirdFormRef.current?.validateFields().then(() => {
					thirdFormRef.current && dispatch(setThirdFormInstance(thirdFormRef.current.getFieldsValue()));
		
					const thirdFormInstance = thirdFormRef.current && thirdFormRef.current.getFieldsValue();
			
					const params = {
						...firstFormInstance,
						...secondFormInstance,
						...thirdFormInstance,
						owner: userInfo.userId,
						...paraments
					};
			
					postTask(params as any);
				})
			})
		})
	}

	function renderFirstFormActionsBtn(): ReactNode[] {
		return [
			<Button
				onClick={() => {
					firstFormRef.current?.validateFields().then(() => {
						dispatch(setFirstFormInstance(firstFormRef.current?.getFieldsValue()));
						setCurrentStep(1);
						disabledStep<3 && setDisabledStep(3)
						domScrollIntoView('step2')
					});
				}}
			>
				下一步
			</Button>,
		];
	}

	function renderSecondFormActionsBtn(): ReactNode[] {
		return [
			<Button
				disabled={disabledStep < 3}
				onClick={() => {
					setCurrentStep(0);
					domScrollIntoView('step1')
				}}
			>
				上一步
			</Button>,
			<Button
				disabled={disabledStep < 3}
				onClick={() => {
					secondFormRef.current?.validateFields().then(() => {
						dispatch(setSecondFormInstance(secondFormRef.current?.getFieldsValue()));
						setCurrentStep(2);
						disabledStep<4 && setDisabledStep(4)
						domScrollIntoView('step3')
					});
				}}
			>
				下一步
		</Button>
		];
	}

	function renderThirdFormActionsBtn(): ReactNode[] {
		return [
			<Button
				disabled={disabledStep < 4}
				onClick={() => {
					setCurrentStep(1);
					let anchorElement = document.getElementById('step2');
					// 如果对应id的锚点存在，就跳转到锚点
					if(anchorElement) { anchorElement.scrollIntoView(); }
				}}
			>
				上一步
			</Button>,
			<Button
				disabled={disabledStep < 4}
				type="primary"
				onClick={handleSubmitTask}
			>
				提交任务
			</Button>,
		];
	}

	const handleTabChange = (key: string) => {
		setCurrentTab(key)
	}
	return (
		<Spin spinning={loading}>
			<Tabs 
				type='card' 
				size="small" 
				className={styles.card}
				onChange={handleTabChange} 
				activeKey={currentTab}
			>
				<Tabs.TabPane tab="数据传输" key='1' />
				<Tabs.TabPane tab="静态脱敏" key='2' />
			</Tabs>
			<Steps
				className={styles.steps}
				direction="vertical"
				style={{ paddingBottom: "20px" }}
				current={currentStep}
			>
				<Step 
					description={
						<Card
							id='step1'
							className={styles.stepForms}
							title={stepMap[currentStep]}
							actions={renderFirstFormActionsBtn()}
						>
							<FirstForm formRef={firstFormRef}></FirstForm>
						</Card>
					} 
				/>
				<Step 
					description={
						<Card
							id='step2'
							className={styles.stepForms}
							title={stepMap[currentStep]}
							actions={renderSecondFormActionsBtn()}
						>
							<SecondForm formRef={secondFormRef} disabled={disabledStep < 3}></SecondForm>
						</Card>
					} 
				/>
				<Step 
					description={
						<Card
							id='step3'
							className={styles.stepForms}
							title={stepMap[currentStep]}
							actions={renderThirdFormActionsBtn()}
						>
							<ThirdForm formRef={thirdFormRef} disabled={disabledStep < 4}></ThirdForm>
						</Card>
					}
				/>
			</Steps>
		</Spin>
	);
};

export { MainContent };

