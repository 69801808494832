import React, { useEffect, useRef } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { getUserImportTaskList } from 'src/api'
import { Iconfont } from 'src/components'
import { setVisibleTaskPane } from 'src/pageTabs/queryPage/queryPageSlice'
import type { noop } from '@ahooksjs/use-request/lib/types'
import styles from './index.module.scss'

export const TaskPane = () => {
  const { visibleTaskPane, visibleApplicationShop } = useSelector((state) => state.queryPage)
  const dispatch = useDispatch()
  const {
    data: list,
    run,
    cancel,
  } = useRequest(() => getUserImportTaskList('PROCESSING'), {
    pollingInterval: 3000,
    manual: true,
  })
  const cancelRef = useRef<noop>()
  cancelRef.current = cancel



  useEffect(() => {
    // https://github.com/alibaba/hooks/issues/949#issuecomment-840980843
    // ? 该 issue 中示例也存在问题，应当把 cancel 和 reset 视为一次 run 的副作用
    if (visibleTaskPane) {
      run()
      return () => cancelRef.current?.()
    }
  }, [run, visibleTaskPane])

  if (visibleApplicationShop) {
    return null
  }

  if (!visibleTaskPane) {
    return (
      <div
        className={styles.nodeDetailInActiveWrapper}
        onClick={() => dispatch(setVisibleTaskPane(true))}
      >
        <Iconfont type="icon-renwu" />
        <div className={styles.nodeName}>运行任务</div>
      </div>
    )
  }

  return (
    <div className={styles.nodeDetailActiveWrapper}>
      <div className={styles.nodeDetailHeader}>
        <div>
          <Iconfont
            type="icon-renwu"
            style={{ color: '#4384ec', marginRight: 8 }}
          />
          运行中任务
        </div>
        <div>
          <CloseOutlined
            className={styles.nodeDetailClose}
            onClick={() => dispatch(setVisibleTaskPane(false))}
          />
        </div>
      </div>
      <div className={styles.nodeDetailContent}>
        {list &&
          list.map((item: any) => (
            <div key={item.id} className={styles.item}>
              <div className={styles.fieldItem}>
                <span className={styles.fieldValue}>节点：</span>
                <span className={styles.title}>{item.nodeName}</span>
              </div>
              <div className={styles.fieldItem}>
                <span className={styles.fieldKey}>文件名称：</span>
                <span className={styles.fieldValue}>{item.fileName}</span>
              </div>
              <div className={styles.fieldItem}>
                <span className={styles.fieldKey}>任务类型：</span>
                <span className={styles.fieldValue}>
                  {item.dumpType === 'IMPORT' ? '导入' : '导出'}
                </span>
              </div>
              <div className={styles.fieldItem}>
                <span className={styles.fieldKey}>开始时间：</span>
                <span className={styles.fieldValue}>{item.createAt}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
