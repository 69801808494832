import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormInstance } from "antd/lib/form";

interface InitialState {
	sourceConnection: any;
	targetConnection: any;
	firstFormInstance: any;
	secondFormInstance: FormInstance<any> | null;
	thirdFormInstance: FormInstance<any> | null;
}

const initialState: InitialState = {
	sourceConnection: null,
	targetConnection: null,
	firstFormInstance: null,
	secondFormInstance: null,
	thirdFormInstance: null,
};

export const createTransferSlice = createSlice({
	name: "createTransferSlice",
	initialState,
	reducers: {
		setSourceConnection: (state, action: PayloadAction<any>) => {
			state.sourceConnection = action.payload;
		},
		setTargetConnection: (state, action: PayloadAction<any>) => {
			state.targetConnection = action.payload;
		},
		setFirstFormInstance: (state, action: PayloadAction<any>) => {
			state.firstFormInstance = action.payload;
		},
		setSecondFormInstance: (state, action: PayloadAction<any>) => {
			state.secondFormInstance = action.payload;
		},
		setThirdFormInstance: (state, action: PayloadAction<any>) => {
			state.thirdFormInstance = action.payload;
		},
		resetAllFilledTaskInfo: (state) => {
			//清空所有存储 form相关信息
			state.sourceConnection = null;
			state.targetConnection = null;
			state.firstFormInstance = null;
			state.secondFormInstance = null;
			state.thirdFormInstance = null;
		}
	},
});

export const createTransferReducer = createTransferSlice.reducer;

export const { setSourceConnection, setTargetConnection, setFirstFormInstance, setSecondFormInstance, setThirdFormInstance, resetAllFilledTaskInfo } = createTransferSlice.actions;
