import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import {
  Form,
  Input,
  Spin,
  Tree,
  Row,
  Col,
  InputNumber,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useRequest } from 'src/hook'
import { isSupport } from 'src/util'
import type { DataSourceType } from 'src/types'
import { getNewTablePartitions_api, getNewTableTempPartition_api } from 'src/api'
import { TextWithTooltip } from 'src/components'
import styles from './index.module.scss'

interface IPartitionPaneProps {
  tabKey: 'partition' | 'temporaryPartition';
  connectionId: string | number | undefined
  databaseName: string | undefined
  schemaName: string | undefined
  connectionType: DataSourceType
  disbaledEdit: boolean
  tableName: string
  onSave: (data: any) => void
}
const PARTITION_FIELDS = ['title', 'value', 'partitionSchema', 'partitionFunction', 'tablespaceName', 'comment',
    'partitionType', 'partitionExpression']

const formatTreeData = (org: any, pindex?: number | string) => {
  return org.map((item: any, index?: number) => {
    const haveChildren =
      Array.isArray(item?.subpartitions) && item?.subpartitions?.length > 0
    const key = pindex === undefined ? index : `${pindex}-${index}`;
    return {
      key,
      title: item.name,
      value: item?.value,
      partitionType: item.partitionType,
      partitionExpression: item.partitionExpression,
      partitionSchema: item?.partitionSchema,
      partitionFunction: item?.partitionFunction,
      tablespaceName: item?.tablespaceName,
      comment: item?.comment,
      children: haveChildren ? formatTreeData(item.subpartitions, key) : [],
    }
  })
}

const PartitionPane = ({
  tabKey,
  connectionId,
  databaseName,
  schemaName,
  connectionType,
  disbaledEdit,
  tableName,
  onSave,
}: IPartitionPaneProps) => {
  const [form] = Form.useForm()
  // const isSpecialConnectTypes = FORBID_EDITING_TYPES.includes(connectionType)

  const [notAllowEdit, setNotAllowEdit] = useState(disbaledEdit)
  const [selectedNode, setSelectedNode] = useState({}) as any
  const [partitionData, setPartitionData] = useState([]) as any

  const { data: defaultPaginationInfo, loading } = useRequest(
    () => {
      let requestActionAPI = getNewTablePartitions_api
      if (tabKey === 'temporaryPartition') {
        requestActionAPI = getNewTableTempPartition_api
      }
      return requestActionAPI({
        connectionId,
        connectionType,
        databaseName,
        schemaName,
        tableName,
      })
    }
   ,
    {
      onSuccess: (data: any) => {
        if (_.isEmpty(data) && data) {
          setNotAllowEdit(true)
        }
        let result = formatTreeData(data);
        setPartitionData(result)
      },
      refreshDeps: [tableName, tabKey],
    },
  )

  const handleNodeSelect = (
    _keys: any,
    e: { selected: boolean; selectedNodes: any; node: any; event: any },
  ) => {
    const { selected, node } = e
    //node 包含字段
   
    if (!selected) return
    // 新的node节点赋值
    setSelectedNode(node)

    let formFieldsValue = {} as any
    PARTITION_FIELDS.map((field) => {
      formFieldsValue[field] = node[field]
    })
    form.setFieldsValue({ ...formFieldsValue })
  }

  const transformPartitionFieldData = (arr: any) => {

    return arr?.map((item: any) => {
      const haveChildren =
        Array.isArray(item?.children) && item?.children?.length > 0

      return {
        name: item.title,
        value: item.value,
        partitionType: item.partitionType,
        partitionExpression: item.partitionExpression,
        tablespaceName: item?.tablespaceName,
        comment: item?.comment,
        subpartitions: haveChildren ? transformPartitionFieldData(item.children) : [],
      }
    })
  }

  const titleRender = (nodeData: any) => {
    const { title, nodeType, key, value } = nodeData

    const keyLevel = key?.toString().split('-').length || 1
    const w = keyLevel * 12

    return (
      <div className={styles.treeNode}>
        <div className={styles.nodeContent}>
          <div className={styles.name}>{title}</div>
          <div className={styles.value} style={{ width: `calc(50% + ${w}px)`, display: 'inherit' }}>
           <TextWithTooltip text={value} maxLength={30}/>
          </div>
        </div>
      </div>
    )
  }

  return (
   
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ span: 4, offset: 0 }}
        wrapperCol={{ span: 20 }}
        className={styles.partitionForm}
      >
         <Spin spinning={loading}>
          <Form.Item wrapperCol={{ span: 24 }} className={styles.treeWrapper}>
            <Row className={styles.row}>
              <Col span={12} className={styles.normal}>
                分区名
              </Col>
              <Col span={12} className={styles.normal}>
                分区值
              </Col>
            </Row>
            <Tree
              blockNode
              selectedKeys={[selectedNode?.key]}
              treeData={partitionData || []}
              titleRender={titleRender}
              switcherIcon={<DownOutlined />}
              onSelect={handleNodeSelect}
              className={styles.treeContent}
            />
          </Form.Item>
          <div className={styles.partitionDetail}>
          <Form.Item label="分区个数">
            <InputNumber value={partitionData?.length ?? 0} disabled={true} />
          </Form.Item>
          <Form.Item label="子分区个数">
          <InputNumber
            value={selectedNode?.children?.length ?? 0}
            disabled={true}
            min={0}
          />
          </Form.Item>
          <Form.Item
              label="分区名"
              name="title"
              hidden={!isSupport('supportedPartitionProperty.partitionName')}>
            <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item label="分区值" name="value">
            <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item label="分区类型" name="partitionType">
            <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item label="分区表达式" name="partitionExpression">
            <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item
              label="分区方案"
              name="partitionSchema"
              hidden={!isSupport('supportedPartitionProperty.partitionSchema')}>
              <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item
              label="分区函数"
              name="partitionFunction"
              hidden={!isSupport('supportedPartitionProperty.partitionFunction')}>
              <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item
              label="表空间"
              name="tablespaceName"
              hidden={!isSupport('supportedPartitionProperty.tablespaceName')}>
            <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          <Form.Item
            label="注释"
            name="comment"
            hidden={!isSupport('supportedPartitionProperty.comment')}>
            <Input disabled={notAllowEdit || _.isEmpty(selectedNode)} />
          </Form.Item>
          </div>
          </Spin>
      </Form>
  )
}

export default PartitionPane
