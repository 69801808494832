import React, { useMemo, useState, useEffect } from 'react'
import { useRequest ,useSelector } from 'src/hook'
import { Form, Input, message, Select, Button, Checkbox, Tooltip, } from 'antd'
import { CustomInputNumber, UIModal } from 'src/components'
import {
  getAllSimpleUsers,
  editAlarnConfig,
  getAlarmMethodsStatus,
} from 'src/api'
import { FormLayout } from 'src/constants'
import { useForm } from 'antd/lib/form/Form';
import { BusinessAlarmTypeOption, TargetPersonEnum, alarmMethodsEnum, auditClassOptions, highRiskClassOptions, typeEnum } from 'src/constants/systemSetting'
import { getKeyByValue, getValueByKey } from 'src/util'
import styles from "../index.module.scss"


interface IProps {
  data: any,
  operationType: string,
  visible: boolean,
  setVisible: (b: boolean) => void,
  refresh: (a: any) => void,
}

interface ITargetPersonOption {
  label: string,
  value: string,
  disabled: boolean,
  hint: string | undefined,
}

export const EditAlarmConfigModal = (props: IProps) => {

  const { data, operationType, refresh, visible, setVisible } = props;
  const { userInfo } = useSelector((state) => state?.login)
  const [loading, setLoading] = useState<boolean>(false)
  const [userIdOption, setUserIdOption] = useState<{label: string, value: string}[]>([]);
  const [alarmMethodsOption, setAlarmMethodsOption] = useState<ITargetPersonOption[]>([]);
  const [alarmType, setAlarmType] = useState<string>(); // 业务告警
  const [targetPersonOption, setTargetPersonOption] = useState<{label: string, value: string}[]>([]);// 消息接收人类型select option
  // 消息接收人类型为 部门负责人、连接管理员时，无需选择消息接收人;为个人时，必填
  const [curTargetPerson, setCurTargetPerson] = useState<string>('');
  // 系统告警配置-系统异常，无 阈值字段
  const [curAlarmType, setCurAlarmType] = useState<string>('');

  const [form] = useForm();

  // 获取所有用户
  const {
    data: simpleUsers,
    run: runGetAllSimpleUsers,
  } = useRequest(getAllSimpleUsers, {
    manual: true,
    onSuccess: (res) => {
      // 获取所有用户
      let option: { label: string; value: string }[] = []
      res.map((role: {
        userId: string,
        userName: string,
        phone?: string
      }) => {
        option.push(
          {
            label: `${role?.userName}(${role?.userId})`,
            value: role?.userId
          }
        )
      })
      setUserIdOption(option)
    },
  })

  // 编辑/创建
  const {
    run: runEditAlarnConfig,
  } = useRequest(editAlarnConfig, {
    manual: true,
    onSuccess: (res) => {
      message.success("编辑成功");
      if (operationType === "editSystem") {
        refresh(0)
      } else if (operationType === "editBusiness") {
        refresh(1)
      }
      setVisible(false);
    },
    onError: (err) => {
      console.log('editAlarnConfig 失败:>> ', err);
    }
  })

  // 获取 手机短信、邮箱配置状态
  const {
    run: runGetAlarmMethodsStatus,
  } = useRequest(getAlarmMethodsStatus, {
    manual: true,
    onSuccess: (res) => {
      getAlarmMethodsOption(res);
    },
  })

  // 告警方式
  const getAlarmMethodsOption = (status: any) => {
    let option: ITargetPersonOption[] = []
    for (const key in alarmMethodsEnum) {
      if (Object.prototype.hasOwnProperty.call(alarmMethodsEnum, key)) {
        let localeLowerCaseKey = key.toLocaleLowerCase()
        let disabled = localeLowerCaseKey !== "system" && !(Object.keys(status).includes(localeLowerCaseKey) && status[localeLowerCaseKey])
        let hint = undefined
        if ((disabled && localeLowerCaseKey==="phone")) {
          hint = `请配置短信通道`
        } else if ((disabled && localeLowerCaseKey==="email")) {
          hint = `请配置邮件服务`
        }
        option.push(
          {
            label: alarmMethodsEnum[key],
            value: key,
            disabled: disabled,
            hint: hint
          }
        )
      }
    }

    if (userInfo.version === 'community') {
      option = option.filter((item)=> item.value !== 'PHONE')
    }

    setAlarmMethodsOption(option)
  }

  // 消息接收人类型
  const getTargetPersonOption = () => {
    let option: { label: string; value: string }[] = []
    for (const key in TargetPersonEnum) {
      if (Object.prototype.hasOwnProperty.call(TargetPersonEnum, key)) {
        option.push(
          {
            label: TargetPersonEnum[key],
            value: key
          }
        )
      }
    }
    setTargetPersonOption(option)
  }

  useEffect(() => {
    if (visible) {
      data && setFormVals();
      runGetAlarmMethodsStatus();
      runGetAllSimpleUsers();
      getTargetPersonOption();
    }
  }, [visible, data, operationType])

  const setFormVals = () => {
    const { type, value, alarmMethods, targetPerson, userId } = data
    setAlarmType(type)
    setCurTargetPerson(targetPerson)
    setCurAlarmType(type)
    form.setFields([
      { name: 'type', value: getValueByKey(typeEnum, type) },
      { name: 'value', value: value },
      { name: 'alarmMethods', value: alarmMethods },
      { name: 'targetPerson', value: (operationType === "editBusiness")? targetPerson : getValueByKey(TargetPersonEnum, targetPerson) },
      { name: 'userId', value: userId ?userId : undefined }
    ])
  }


  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const { type, value, alarmMethods, targetPerson, userId } = values
        setLoading(true);
        try {
          // console.log('values :>> ', values);
          // console.log('编辑 :>> ', {
          //   id: data?.id,
          //   type: getKeyByValue(typeEnum, type).toString(),
          //   value: value,
          //   alarmMethods: alarmMethods,
          //   targetPerson: operationType === "editSystem" ? getKeyByValue(TargetPersonEnum, targetPerson).toString() : targetPerson,
          //   userId: userId,
          //   status: data?.status,
          // }); return
          runEditAlarnConfig({
            id: data?.id,
            type: getKeyByValue(typeEnum, type).toString(),
            value: value,
            alarmMethods: alarmMethods,
            targetPerson: operationType === "editSystem" ? getKeyByValue(TargetPersonEnum, targetPerson).toString() : targetPerson,
            userId: userId,
            status: data?.status,
          });
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {});
  }

  const handleTargetPersonOnChange = (e: any) => {
    setCurTargetPerson(e)
  }

  const renderCondition = useMemo(() => {
    switch (alarmType) {
      case "HIGH_RISK":
        return (
          <Form.Item
            name="value"
            label="高危等级"
            rules={[{ required: true }]}
          >
            {/* <Select
              options={highRiskClassOptions}
              allowClear
              disabled={true}
            /> */}
            <Input disabled={true} bordered={false} className={styles.fontColor22223f} />
          </Form.Item>
          )
      case "SLOW_SQL":
        return (
          <Form.Item
            name="value"
            label="阈值(s)"
            rules={[{
              required: true
            }]}
          >
              <CustomInputNumber
                type={'positiveNum'}
                autoFocus
                placeholder="请输入阈值"
                min={0}
                style={{ width: '100%' }}
              />
          </Form.Item>
          )
      case "SQL_CHECK":
        return (
          <Form.Item
            name="value"
            label="审核等级"
            rules={[{ required: true }]}
          >
            {/* <Select
              options={auditClassOptions}
              allowClear
              disabled={true}
            /> */}
            <Input disabled={true} bordered={false} className={styles.fontColor22223f} />
          </Form.Item>
          )
      default:
        return <></>
    }
  }, [alarmType, visible, operationType])


  return (
    <UIModal
      visible={visible}
      key={"editAlarmConfigModal"}
      title={ operationType === "editSystem"? "编辑系统告警配置" : operationType === "editBusiness" ? "编辑业务告警配置" : ""}
      onCancel={() => {
        setVisible(false);
      }
        
    }
      destroyOnClose={false}
      afterClose={() => {
        form.resetFields();
        setVisible(false);
      }}
      confirmLoading={loading}
      forceRender
      footer={
        <>
        <Button key="save" type="primary" onClick={()=>handleOk()} >确认</Button>
        <Button key="cancel" onClick={() => setVisible(false)} >取消</Button>
        </>
      }
    >
      <Form form={form} {...FormLayout} initialValues={form}>
        <Form.Item
          name="type"
          label={operationType === "editSystem" ? "系统告警类型" : "业务告警类型"}
        >
          <Input disabled={true} bordered={false} style={{color: "#22223f"}}/>
        </Form.Item>
        {
          ["editBusiness"].includes(operationType) ?
          renderCondition
          : operationType === "editSystem" && curAlarmType !== "SYSTEM_ERROR" ?
            <Form.Item
              name="value"
              label={form.getFieldValue("type") === "文件上传大小" ? "阈值(MB)" : "阈值"}
              rules={[{
                required: true,
                validator: (_: any, value: number) => {
                  if (!value) {
                    return Promise.reject("请输入阈值");
                  }
                  return Promise.resolve();
                }
              }]}
            >
              <CustomInputNumber
                type={'positiveNum'}
                autoFocus
                placeholder="请输入阈值"
                min={0}
                style={{ width: '100%' }}
              />
            </Form.Item>
          :''
        }
        <Form.Item
          name="alarmMethods"
          label="告警方式"
          rules={[{ required: true, message:"请选择告警方式" }]}
        >
          {/* <Checkbox.Group
            options={alarmMethodsOption}
          /> */}
          <Checkbox.Group>
            {
              alarmMethodsOption.map((item: ITargetPersonOption) => {
                return (
                  <Tooltip title={item?.hint}>
                    <Checkbox key={item?.value} value={item?.value} disabled={item?.disabled}>
                      {item?.label}
                    </Checkbox>
                  </Tooltip>
                )
              })
            }
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          name="targetPerson"
          label="消息接收人类型"
          rules={[{ required: true }]}
        >
          {
            // <Input disabled={true} bordered={false} style={{color: "#22223f"}}/>
            operationType === "editBusiness" ?
            <Select
              allowClear
              options={targetPersonOption}
              onChange={(e: any) => handleTargetPersonOnChange(e)}
            />
            : <Input disabled={true} bordered={false} style={{color: "#22223f"}}/>
            
          }
        </Form.Item>
        {
          !['DEPT', 'CONN'].includes(curTargetPerson) && 
          <Form.Item
            name="userId"
            label="消息接收人"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              options={userIdOption}
            />
          </Form.Item>
        }
      </Form>
    </UIModal>
  );
}
