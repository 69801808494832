import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { AgGridReact } from '@ag-grid-community/react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  ColDef,
} from '@ag-grid-community/core'
import { Descriptions, Divider, Input, message, Spin, Tabs } from 'antd'
import {
  // DataSourceType,
  DesignTableOperation,
  DesignTableRequest,
  executeMenuActionSql,
  generateDesignTableSql,
  getTableColumns,
  TableColumn,
  TableColumnProperty,
  TableColumnsResponse,
} from 'src/api'
import { DatabaseInfo } from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import {
  getInfoFromPath,
  SqlSplitter,
  objectDifference,
  getOperatingObject,
  correctComment,
} from 'src/util'
import styles from './designTablePane.module.scss'
import { GridConfigBase } from '../../resultTabs/resultContentGrid'
import { LinkButton } from 'src/components'
import { DesignIndex } from '../DesignIndex'
import { SaveTableDesignModal } from './SaveTableDesignModal'
import cloneDeep from 'lodash/cloneDeep'
import { refreshOnRoot } from '../../sdt'
import { v4 as uuidv4 } from 'uuid'
import {
  IIndex,
  DataSourceType,
  DataSourceWithAdditionalSchema,
} from 'src/types'
import { getColumnTypeOptions } from 'src/util'

const { TabPane } = Tabs

const defaultColDef: ColDef = { resizable: true, editable: true }

const getColumnTypeCellEditor = (connectionType: DataSourceType) => {
  switch (connectionType) {
    default:
      return 'selectEditor'
  }
}

const getRendererAndEditor = (type: 'boolean', params?: any): ColDef => {
  // todo: 提出 util
  switch (type) {
    case 'boolean':
      return {
        cellRenderer: 'booleanRenderer',
        cellEditor: 'booleanEditor',
      }
  }
}

interface CreateTablePaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
}

export const DesignTablePane = (props: CreateTablePaneProps) => {
  const { databaseInfo, queryTabKey } = props
  const dispatch = useDispatch()
  const { theme } = useSelector((state) => state.appearance)
  const {
    connectionId,
    connectionType = 'MySQL',
    databaseName,
    nodePath,
    nodePathWithType,
    nodeName,
    nodeType,
  } = databaseInfo
  const { toolbarConnections } = useSelector(state => state.editor)
  const connection = toolbarConnections?.find((t) => t.connectionId === connectionId)
  const connectionName = connection?.alias ? connection.alias : connection?.nodeName
  // be careful for this
  const schemaName = ['SQLServer', 'PostgreSQL', 'PolarDB', 'MogDB', 'OracleCDB','StarRocks'].includes(
    connectionType,
  )
    ? getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
    : connectionType === 'DamengDB'
      ? getInfoFromPath(nodePath, 'schema', 'DamengDB')
      : getInfoFromPath(nodePath, 'connection', connectionType)

  // ?: ref 处理 ag-grid 函数闭包。todo: 优雅重构
  const [updatedColumns, setUpdatedColumns] = useState<TableColumn[]>([])
  const updatedColumnsRef = useRef<TableColumn[]>()
  useEffect(() => {
    updatedColumnsRef.current = updatedColumns
  }, [updatedColumns])

  /** 修改某表列，用于 cell editor 触发 onCellValueChanged 或 cell renderer 直接修改值 */
  const changeColumn = (rowData: TableColumn) => {
    setUpdatedColumns([
      ...(updatedColumnsRef.current?.filter(
        (column) => column.columnName !== rowData.columnName,
      ) || []),
      rowData,
    ])
  }

  const getColumnDefs = (connectionType: DataSourceType) => {
    const baseColumnDefs: ColDef[] = [
      {
        field: 'columnName',
        headerName: '列名',
        minWidth: 144,
      },
      {
        field: 'columnType',
        headerName: '类型',
        minWidth: 120,
        cellEditor: getColumnTypeCellEditor(connectionType),
        cellEditorParams: {
          values: getColumnTypeOptions(connectionType),
        },
      },
      {
        field: 'columnLength',
        headerName: '长度',
        cellRenderer: 'simpleTextRenderer',
        cellEditor: 'textEditor',
      },
      {
        field: 'precision',
        headerName: '精度',
        cellRenderer: 'simpleTextRenderer',
        cellEditor: 'textEditor',
      },
      {
        field: 'defaultValue',
        headerName: '默认值',
        cellRenderer: 'simpleTextRenderer',
        cellEditor: 'textEditor',
      },
      {
        field: 'columnComment',
        headerName: '备注',
        cellRenderer: 'simpleTextRenderer',
        cellEditor: 'textEditor',
      },
      {
        field: 'allowNull',
        headerName: '允许 null',
        ...getRendererAndEditor('boolean'),
      },
      {
        field: 'autoIncrease',
        headerName: '自增',
        ...getRendererAndEditor('boolean'),
      },
      {
        field: 'primary',
        headerName: '是否主键',
        ...getRendererAndEditor('boolean'),
      },
    ]
    switch (connectionType) {
      case 'Hive':
      case 'Inceptor':
      case 'Impala':
        let col = baseColumnDefs.filter(
          (it: any) =>
            it.field &&
            ![
              'defaultValue',
              'allowNull',
              'autoIncrease',
              'isPrimary',
              'primary',
              'precision',
            ].includes(it.field),
        )
        col.splice(3, 0, {
          field: 'precision',
          headerName: '精度',
          valueParser: (v: any) => +v.newValue,
        })
        return col
      case 'Vertica':
        return baseColumnDefs.filter(
          (it: any) =>
            it.field &&
            !['defaultValue', 'autoIncrease', 'precision'].includes(it.field),
        )
      case 'Oracle':
      case 'SQLServer':
        return baseColumnDefs.filter((def) => def.headerName !== '自增')
      case 'MySQL':
      default:
        return baseColumnDefs
    }
  }

  const cachedColumnsRef = useRef<TableColumn[]>()
  // 用于比对表设计前后变化

  /* origin indexes */
  const [originIndexes, setOriginIndexes] = useState<IIndex[]>([])

  const formatIndexes = (
    indexes: TableColumnsResponse['indexes'],
  ): IIndex[] => {
    const indexesMap: { [key: string]: IIndex } = {}
    indexes.forEach((item) => {
      const { columnName, indexName, indexType, sortType, unique } = item
      if (indexName in indexesMap) {
        indexesMap[
          indexName
        ].columnName = `${indexesMap[indexName].columnName},${columnName} ${sortType}`
      } else {
        indexesMap[indexName] = {
          indexName,
          indexType: indexType,
          columnName: `${columnName} ${sortType}`,
          unique: unique,
        }
      }
    })
    return Object.values(indexesMap)
  }

  const {
    data: tableAndColumns,
    run: tryGetTableColumns,
    mutate: mutateTableColumns,
    loading: loadingGetTableColumns,
  } = useRequest(
    () => {
      if (!(connectionId && nodeName && nodePath && nodePathWithType && nodeType)) return
      return getTableColumns({
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
      })
    },
    {
      manual: true,
      formatResult: (res) => {
        const { columns, tableComment, indexes } = res
        setNewTableComment(tableComment)
        columns.forEach((column) => (column.uuid = uuidv4()))
        cachedColumnsRef.current = cloneDeep(columns)
        const formatindexes = formatIndexes(indexes)
        setOriginIndexes(formatindexes)
        return res
      },
    },
  )
  const {
    tableComment = '',
    tableName,
    columns = [],
    hasCommentProperty,
    alreadyHasPrimaryKey,
  } = tableAndColumns || {}

  const handleRefresh = () => {
    setUpdatedColumns([])
    setDeletedColumns([])
    mutateTableColumns(undefined)
    setNewTableComment('')
    tryGetTableColumns()
  }

  useEffect(() => {
    tryGetTableColumns()
  }, [tryGetTableColumns])

  const gridApiRef = useRef<GridApi>()
  const gridColumnApiRef = useRef<ColumnApi>()

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api
    gridColumnApiRef.current = params.columnApi
    gridApiRef.current.sizeColumnsToFit()
  }

  const [visibleSaveDesign, setVisibleSaveDesign] = useState(false)

  const [deletedColumns, setDeletedColumns] = useState<TableColumn[]>([])

  const deleteOperations =
    deletedColumns
      .filter(
        (deletedColumn) =>
          !updatedColumns.some(
            (column) => column.columnName === deletedColumn.columnName,
          ),
      )
      .map(
        // todo: 优雅重构，取 cachedColumns 和 newColumns，重合列为 ALTER，newColumns 多出列为 ADD，cachedColumns 多出列为 DELETE
        (column) =>
        ({
          columnName: column.columnName,
          operationType: 'DROP',
        } as DesignTableOperation),
      ) || []

  const alterAndAddOperations = updatedColumns
    .map((column) => {
      const cachedColumn: TableColumn | undefined = (
        cachedColumnsRef.current || []
      ).find((originalColumn) => originalColumn.uuid === column.uuid)
      const { columns: newColumns = [] } = tableAndColumns || {}
      const { uuid: uuid1, ...newColumn } =
        newColumns.find((newColumn) => newColumn.uuid === column.uuid) || {}
      // todo: 分离 ADD 和 ALTER 操作
      if (!cachedColumn) {
        const { uuid, ...changedProperties } = column
        return {
          columnName: column.columnName,
          changedProperties,
          operationType: 'ADD',
          newColumn,
        } as DesignTableOperation
      }
      const { uuid, ...changedProperties } = objectDifference(
        column,
        cachedColumn,
      ) as Partial<TableColumn>
      // todo: 优雅重构
      // todo: 后端处理 autoIncrease、columnType、columnLength 和 precision 四者联动
      const isColumnTypeRelating = (
        Object.keys(changedProperties) as TableColumnProperty[]
      ).some((columnProperty) =>
        (
          [
            'autoIncrease',
            'columnType',
            'columnLength',
            'precision',
          ] as TableColumnProperty[]
        ).includes(columnProperty),
      )
      if (isColumnTypeRelating) {
        changedProperties.autoIncrease = column.autoIncrease
        changedProperties.columnType = column.columnType
        changedProperties.columnLength = column.columnLength
        changedProperties.precision = column.precision
      }
      return {
        columnName: cachedColumn.columnName,
        changedProperties,
        newColumn,
        operationType: 'ALTER',
      } as DesignTableOperation
    })
    .filter((operation) => Object.keys(operation.changedProperties)[0])

  const columnOperations = deleteOperations.concat(alterAndAddOperations)

  const addColumn = () => {
    const newColumn = { allowNull: true, uuid: uuidv4() }
    gridApiRef.current?.applyTransactionAsync({ add: [newColumn] }, (res) => {
      // todo: ag-grid data update + view refresh，而非修改 columns data
      columns.push(newColumn)
      gridApiRef.current?.startEditingCell({
        rowIndex: Number(res.add[0]?.rowIndex),
        colKey: 'columnName',
      })
    })
  }

  const deleteColumn = () => {
    const selectedRows = gridApiRef.current?.getSelectedRows()
    if (!selectedRows) return
    selectedRows.forEach((row) =>
      columns.splice(
        columns.findIndex((column) => column.columnName === row.columnName),
        1,
      ),
    )
    const deletedColumns =
      cachedColumnsRef.current?.filter(
        (cachedColumn) =>
          !columns.some(
            (newColumn) => newColumn.columnName === cachedColumn.columnName,
          ),
      ) || []
    setDeletedColumns(deletedColumns)
    gridApiRef.current?.applyTransactionAsync({ remove: selectedRows })
  }

  const getNewColumns = () => {
    const newColumns: TableColumn[] = []
    gridApiRef.current?.forEachNode((rowNode) => {
      newColumns.push(rowNode.data)
    })
    return newColumns
  }

  const { run: editTable } = useRequest(
    (generatedSql: string) => {
      if (!connectionId) return
      const splitter = new SqlSplitter(generatedSql || '')
      const statements = splitter.split()
      // todo: 处理多语句 error
      return executeMenuActionSql({
        connectionId,
        dataSourceType: connectionType,
        operatingDatabase: databaseName,
        tabKey: queryTabKey,
        statements,
        operatingObject: getOperatingObject(
          { databaseName, schemaName },
          connectionType,
        ),
        databaseName,
      })
    },
    {
      manual: true,
      onSuccess: (result) => {
        const errors = result.reduce((prev, curr) => {
          const { executeError, statement } = curr
          if (executeError)
            return prev.concat(statement + ' ' + executeError.message)
          return prev
        }, [] as string[])

        const refresh = () => {
          dispatch(refreshOnRoot())
          handleRefresh()
        }

        if (!errors[0]) {
          message.success('设计表成功')
          refresh()
          return
        }

        if (errors.length === result.length) {
          message.error('设计表失败', 7)
        } else {
          message.warning(
            `${result.length - errors.length}/${result.length} 条语句执行成功`,
            7,
          )
          refresh()
        }
        errors.slice(0, 3).forEach((error) => message.error(error, 7))
      },
    },
  )

  const [newTableComment, setNewTableComment] = useState('')

  const getChangedTable = () => {
    const changedTable: {
      tableComment?: string
      hasCommentProperty?: boolean
    } = {}
    if ((newTableComment || '') !== (tableComment || '')) {
      changedTable.tableComment = correctComment(connectionType,newTableComment) 
      changedTable.hasCommentProperty = hasCommentProperty
    }
    return changedTable
  }

  const requestGeneratedSql = useRequest(
    () => {
      const params: DesignTableRequest = {
        connectionId: connectionId!,
        connectionType,
        userInputs: {
          databaseName: databaseName!,
          tableName: tableName!,
          columnOperations,
          changedTable: getChangedTable(),
          primaryKeys: getNewColumns()
            .filter((column) => column.primary)
            .map((column) => column.columnName),
          currentColumns: getNewColumns().map(({ uuid,columnComment, ...column }) => ({...column,columnComment:correctComment(connectionType,columnComment)})),
          alreadyHasPrimaryKey,
        },
      }
      if (DataSourceWithAdditionalSchema.includes(connectionType)) {
        params.userInputs.schemaName = schemaName
      }
      return generateDesignTableSql(params)
    },
    {
      manual: true,
      formatResult: ({ generatedSql }) => generatedSql,
      throwOnError: true,
      onSuccess: () => {
        setVisibleSaveDesign(true)
      },
      onError: () => {
        setVisibleSaveDesign(false)
      }
    },
  )

  return (
    <div className={styles.designTablePane}>
      <Tabs tabPosition="left" className={styles.tab}>
        <TabPane tab="表" key="table" tabKey="table">
          <div className={styles.main}>
            <Spin spinning={loadingGetTableColumns}>
              <Descriptions column={2}>
                {/* todo: 升级 antd 版本，使用 contentStyle 和 labelStyle */}
                <Descriptions.Item label="连接名">
                  {connectionName}
                </Descriptions.Item>
                {!['DamengDB', 'DB2'].includes(connectionType) && (
                  <Descriptions.Item label="数据库">
                    {databaseName}
                  </Descriptions.Item>
                )}
                {['SQLServer', 'DamengDB', 'DB2', 'PostgreSQL', 'PolarDB', 'OracleCDB','StarRocks'].includes(
                  connectionType,
                ) && (
                    <Descriptions.Item label="Schema">
                      {schemaName}
                    </Descriptions.Item>
                  )}
                <Descriptions.Item label="表名">{tableName}</Descriptions.Item>
              </Descriptions>
              <div className={styles.label}>
                <span>表注释：</span>
                <Input
                  value={newTableComment}
                  onChange={(e) => setNewTableComment(e.target.value)}
                  style={{ width: 320 }}
                ></Input>
              </div>
              <div style={{ margin: 24 }}></div>
              <div
                id="myGrid"
                style={{
                  height: '50vh',
                  width: '100%',
                }}
                // todo: 更好地统一处理主题样式
                className={
                  theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
                }
              >
                <AgGridReact
                  {...GridConfigBase}
                  modules={[ClientSideRowModelModule]}
                  columnDefs={getColumnDefs(connectionType)}
                  defaultColDef={defaultColDef}
                  rowDragManaged={true}
                  animateRows={true}
                  onGridReady={onGridReady}
                  rowData={columns}
                  stopEditingWhenGridLosesFocus
                  onCellValueChanged={(e) =>
                    e.value !== undefined && changeColumn(e.data)
                  }
                />
              </div>
            </Spin>
          </div>
          <div className={styles.toolbar}>
            <div>
              <LinkButton disabled={loadingGetTableColumns} onClick={addColumn}>
                添加列
              </LinkButton>
              <LinkButton
                disabled={loadingGetTableColumns}
                onClick={deleteColumn}
              >
                删除列
              </LinkButton>
            </div>
            <Divider type="vertical" className={styles.divider}></Divider>
            <div>
              <LinkButton
                disabled={
                  loadingGetTableColumns ||
                  (!columnOperations[0] &&
                    !deletedColumns[0] &&
                    !Object.keys(getChangedTable()).length)
                }
                onClick={() => requestGeneratedSql?.run()}
              >
                保存
              </LinkButton>
              <LinkButton
                disabled={loadingGetTableColumns}
                onClick={handleRefresh}
              >
                还原
              </LinkButton>
            </div>
          </div>
          <SaveTableDesignModal
            visibleSaveDesign={visibleSaveDesign}
            setVisibleSaveDesign={setVisibleSaveDesign}
            requestGeneratedSql={requestGeneratedSql}
            editTable={editTable}
            tableName={tableName}
          ></SaveTableDesignModal>
        </TabPane>
        {connectionType === 'SQLServer' && (
          <TabPane tab="索引" key="index" tabKey="index">
            <DesignIndex
              connectionId={connectionId}
              databaseName={databaseName}
              connectionType={connectionType}
              tableName={tableName || ''}
              columns={columns?.map(({ columnName }) => ({ columnName }))}
              indexes={originIndexes}
              successfulCallback={() => tryGetTableColumns()}
              queryTabKey={queryTabKey}
              nodePath={nodePath}
            ></DesignIndex>
          </TabPane>
        )}
      </Tabs>
    </div>
  )
}
