import React, { useEffect, useState } from "react";
import { useRequest } from "src/hook";
import * as _ from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import { Form, Upload, message, Button, Input } from "antd";
import { uploadDataChangeSql } from "src/api";
import { UploadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib/upload/interface";
import styles from "./index.module.scss";

export const SqlFileUpload = ({
  onChange,
  value,
  btnText,
}: {
  onChange?: any;
  value?: any;
  btnText?: string;
}) => {
  const [fileName, setFileName] = useState("");
  const [fileList, setFileList] = useState<any>([]);

  const onChangeFile: UploadProps["onChange"] = ({ fileList, file }) => {

    if (file?.response?.resCode === 10000) {
      setFileName(file?.response?.data);
      setFileList([file]);
      onChange?.(file?.response?.data)
    } else {
      file?.response?.resMsg && message.error(file?.response?.resMsg);
    }
  };

  useEffect(() => {
   if (value){
    setFileName(value)
   }
  },[value])

  return (
    <>
      <Upload
        accept=".sql"
        action="/api/flow/dataChange/uploadDataChangeSql"
        showUploadList={false}
        onChange={onChangeFile}
      >
        <Button icon={<UploadOutlined />} style={{ marginBottom: 8 }}>
          {btnText ?? "单个文件上传"}
        </Button>
      </Upload>
      {/* 样式修改 */}
      {fileName && (
        <div className={styles.uploadFiles}>
          <span>{fileName}</span>
          <span
            className={styles.removeBtn}
            onClick={() => {
              setFileName("");
              onChange?.("");
            }}
          >
            <DeleteOutlined />
          </span>
        </div>
      )}
    </>
  );
};
