import React from 'react'
import { Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { UIModal } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { twoStepSMSLoginAuth } from 'src/api'
import { commonLoginProcess } from '../loginSlice'

export function TwoFactorSMSConfirmModal({ userId, onDone }: any) {
  const [form] = useForm()
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal['TwoStepLoginBySMS'])

  // 提交表单
  const { run: verifyCodeRun, loading: submitVerifyCodeLoading } = useRequest(
    twoStepSMSLoginAuth,
    {
      manual: true,
      onSuccess(userInfo) {
        // do something ...
        commonLoginProcess(userInfo, dispatch)
      },
    },
  )

  async function handleSubmit() {
    await form.validateFields().then((v) => {
      verifyCodeRun({ authCode: v.verifyCode, userId })
    })
    onDone()
    dispatch(hideModal('TwoStepLoginBySMS'))
  }
  function flowCancel() {
    form.resetFields()
    onDone()
    dispatch(hideModal('TwoStepLoginBySMS'))
  }

  return (
    <UIModal
      title={'输入验证码'}
      visible={visible}
      onOk={handleSubmit}
      okButtonProps={{ loading: submitVerifyCodeLoading }}
      onCancel={flowCancel}
      width={500}
    >
      <Form form={form} labelCol={{ span: 4, offset: 0 }}>
        <Form.Item
          label="验证码"
          name="verifyCode"
          rules={[{ required: true, len: 6 }]}
        >
          <Input allowClear />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
