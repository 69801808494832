/**
 * 流程表单 数据库元素字段控件（不包括 FormItem）
 */
import React, { useEffect, useMemo } from 'react'
import { useRequest } from 'src/hook'
import { TreeSelect } from 'antd'
import {
  getMaskedTreeList,
} from 'src/api'
import { FormFieldsDesensitizedResource } from 'src/types';
import { Iconfont } from 'src/components'
import { generateObjectName } from 'src/util';
import type { FormFieldElementEntity } from 'src/types'
import styles from '../flowForm.module.scss'

interface ElementTreeSelectProps {

  resourceFields?:FormFieldsDesensitizedResource
  value?: FormFieldElementEntity[]
  disabledEdit?:boolean
  onChange?: (value: FormFieldElementEntity[]) => void
}

// 构造树结构
const generateTree = (data: any[]) => {

  const formatTree = (data: any[],pLevel: number): any[] =>
     data.map((item: any) => {
    
      const curLevel = pLevel + 1;
      item.key = item?.nodePathWithType  // 唯一key
      item.title = item?.nodeName
      item.icon=<Iconfont type={`icon-${item?.nodeType === 'connection' ? item?.connectionType : item?.nodeType}`} />
      item.value = item?.nodePathWithType
      //root节点 parentId = null
      item.parentId = item?.parentId || item?.id;
      item.level = curLevel ; //判断root
      if (item.childTreeVo) {
        item.children = formatTree(item.childTreeVo,curLevel);
        item.canDelete = false
        delete item.childTreeVo;
      }
      return { ...item }
    })
  return formatTree(data, 0)
}

export const SyncElementTreeSelect: React.FC<ElementTreeSelectProps> = React.memo(
  ({ resourceFields = {},  disabledEdit = false, value, onChange }) => {

    const { connectionId, connectionType} = resourceFields;

    // tree select 当前 value(受控)
    const treeSelectValue = useMemo(() => {
      if (!value) return

      //@ts-ignore
      const checkedValue = value.map(({label, value}) =>  ({
        value,
        label: <>
        <Iconfont
              type={`icon-${connectionType ?? "database"}`}
              style={{ fontSize: "16px", marginRight: 4 }}
            />
            {generateObjectName(value,['DATABASE', 'SCHEMA', 'TABLE', 'COLUMN'])}
            </>,
      }))
     
      return [...checkedValue]
    }, [value])

    // 获取元素树连接层级
    const {
      data: root,
      run: fetchConnections,
      mutate: setRoot,
    } = useRequest(getMaskedTreeList, {
      manual: true,
      formatResult: (data) => {
        // 构造与子节点结构统一的连接节点
      
        return generateTree(data)
      },
    })
    useEffect(() => {
    
      if (connectionId) {
        
        fetchConnections(connectionId)
      }
    }, [connectionId])

    return (
      <TreeSelect
        disabled={disabledEdit}
        dropdownClassName={styles.treeSelectDropdown}
        treeData={root}
        value={treeSelectValue}
        onChange={(value) => {
 
         //@ts-ignore
          onChange?.(value)
        }}
        treeCheckable
        treeCheckStrictly={false}
        showSearch
        treeIcon
        maxTagCount={2}
        maxTagTextLength={8}
        allowClear
        placeholder="请选择数据库元素"
        // 为了在回填 元素树选中节点 时，在相关节点没有加载时能给出正确的渲染
        labelInValue={true}
        getPopupContainer={(triggerNode) => triggerNode.parentNode || document.body}
      />
    )
  },
)
