import React, { FC } from 'react'
import { Card, Descriptions } from 'antd'
import classnames from 'classnames'
import styles from '../index.module.scss'

interface ListProps {
  remarkData: []
  withdrawalState?: boolean;
}

export const WeightRemarksPag: FC<ListProps> = (props) => {
  const { remarkData, withdrawalState =  false } = props
  return (
    <Card
      title={ withdrawalState ? '撤回信息': "落权信息" }
      className={classnames(styles.borderShow, styles.mt27, styles.mb27)}
    >
      {
        remarkData && remarkData?.length ? remarkData?.map((item: any, index: number) => {
          return <Descriptions key={index}>
            <Descriptions.Item label={item?.approvedUser}> {item?.approvedResultDesc}</Descriptions.Item>
            <Descriptions.Item label="备注">{item?.approvedComment ? item?.approvedComment : '-'}</Descriptions.Item>
          </Descriptions>
        }) : ''
      }

    </Card>
  )
}
