import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'

const modalName = [
  /** connection management(aborted) */
  'AddConnection',
  /** SDT */
  'AddSdtGroup',
  'MoveToSdtGroup',
  'RenameSdtNode',
  /** SDT 添加元素 */
  'ModalAddSchema',
  /** ediitor */
  'ModalFavoriteAdd',
  'ModalFavoriteManagement',
  'ModalOpenServerFile',
  /** organization */
  'AddDept',
  'AddSubCompany',
  'AddOrgGroup',
  'AddUser',
  'UserManagement',
  'ModalChangeCompanyName',
  'ModalChangeDeptName',
  /** app list */
  'AddApp',
  'EditApp',
  // database
  'SecuritySetting',
  /** app management */
  'AddAppDatabases',
  'AddAppAccount',
  /** connection management */
  'ModalAppAccountChangePassword',
  'ModalAddAppAccount',
  'ModalConnectionBindApp',
  'ModalConnectionUserPerm',
  'EditConnection',
  /** export */
  'AddResultExport',
  'AddSelectedResultExport',
  'AddSdtNodeExport',
  /** me page */
  'ModalChangePersonalPassword',
  'ModalChangePersonalEmail',
  /** update */
  'UpdateVersion',
  'ModalRestore',
  /** perm */
  'ModalConnEditRole',
  'ModalConnAddRoleWizard',
  'ModalConnEditDataSourcePerm',
  'ModalConnEditPermSet',
  'ModalConnGrantPerm',
  'ModalConnBindUser',
  'ModalConnBindUserShortcut',
  'ModalConnPermSetChildren',
  'ModalSysAddRoleWizard',
  'ModalTextImportWizard',
  'ModalSysGrantPerm',
  'ModalSysBindUser',
  'ModalSysEditRole',
  /** 个人文件夹 */
  'ModalNewFolder',
  /** 设计表 */
  'ModalSaveTableDesign',
  /** 添加子组 */
  'AddSubSdtGroup',
  /** 修改别名 */
  'UpdateConnAlias',
  /* 权限表单 */
  'ModalDataExport',
  'ModalObjectFilter',
  'ModalRowFilter',
  'ModalExecuteTimes',
  'ModalExecuteRows',
  'ModalExecuteDate',
  'ModalHighRiskOperation',
  'ModalConnEditDesensitizationPerm',
  /* 岗位管理 */
  // 'ModalPost',
  /* 编辑部门负责人 */
  'ModalEdptPrincipal',
  /* 敏感规则 */
  'ModalDesenRule',
  /* 扫描任务 */
  'ModalScanJob',
  /* 添加为敏感资源 */
  'ModalScanJobResource',
  /* 权限影响用户 */
  'ModalPermEffectUsers',
  /* 受限用户管理 */
  'ModalPermEffectUsersManage',
  /* 用户权限详情 */
  'UserPermDetaiModal',
  /* 批量导入连接 */
  'BatchCreateConnectionModal',
  /* SDT-dump 导入 */
  'DumpImportModal',
  /* SDT-dump 导出*/
  'DumpExportModal',
  /* 用户批量导入*/
  'BatchImportUserModal',
  /** sql 导入 */
  'SqlImportModal',
  /*用户权限复制*/
  'CopyUser',
  /* 两步验证短信登录 */
  'TwoStepLoginBySMS',
  /* 脱敏弹窗 */
  'DesensitizationDialog',
  /* 脱敏规则 添加规则 */
  'UploadDesensSettingModal',
  'DesensRulesModal',
  // 编辑 脱敏字段
  'CommonEditDesensRulesModal',
  //脱敏命中比率设置
  'DesensCaluParameterSettingModal',
  //脱敏用户列表
  'DesensitizedUserListModal',
  // 编辑策略
  'DesensPolicySettingModal',
  /* 个人中心 安全设置 登录设置 */
  'LoginSettingsModal',
  /* 个人中心 安全设置 登录保持时间*/
  'LoginHoldTimeModal',
  /* 个人中心 安全设置 验证身份*/
  'VerificationModal',
  /* 个人中心 安全设置 邮箱*/
  'EmailEditModal',
  /* 个人中心 安全设置 手机号*/
  'PhoneEditModal',
  /* 个人中心 安全设置 绑定otp*/
  'OtpBindingtModal',
  /* 个人中心 安全设置 绑定企业微信*/
  'WwChatBindModal',
  /* 个人中心 安全设置 解除绑定企微*/
  'WwChatUnBindModal',
  /* 个人文件夹重命名 */
  'ReNameModal',
  /* 个人文件夹 移动 */
  'MoveModal',
  /* 编辑告警配置 */
  'EditAlarmConfigModal',
  /* 创建/编辑角色 */
  'AddRoleModal',
] as const
export type ModalName = typeof modalName[number]

const initialState: { [key in ModalName]: boolean } = modalName.reduce(
  (final, current) => {
    final[current] = false
    return final
  },
  {} as { [key in ModalName]: boolean },
)

export const modalVisbleSlice = createSlice({
  name: 'modalVisible',
  initialState,
  reducers: {
    setVisibleModal: (
      state,
      action: PayloadAction<{ modalName: ModalName; visible: boolean }>,
    ) => {
      state[action.payload.modalName] = action.payload.visible
    },
  },
})

export const modalVisibleReducer = modalVisbleSlice.reducer

const { setVisibleModal } = modalVisbleSlice.actions

export const showModal =
  (modalName: ModalName): AppThunk =>
  (dispatch) => {
    dispatch(setVisibleModal({ modalName, visible: true }))
  }
export const hideModal =
  (modalName: ModalName): AppThunk =>
  (dispatch) => {
    dispatch(setVisibleModal({ modalName, visible: false }))
  }
