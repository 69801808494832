import React, { useEffect } from 'react'
import { Form, Input, Select, Row, Col, message } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Iconfont } from 'src/components'
import { useDispatch, useSelector } from 'src/hook'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { setFilePath } from 'src/store/extraSlice/textImportSlice'

export function FilePathForm({ form }: { form?: FormInstance }) {
  const dispatch = useDispatch()
  const { filePath, fileType } = useSelector((state) => state.textImport)
  useEffect(() => {
    form?.setFieldsValue({
      importPath: filePath,
    })
  }, [filePath, form])
  return (
    // 所有字段均为必填项
    <Form form={form} labelAlign="left" labelCol={{ span: 6 }}>
      <Row gutter={4}>
        <Col span={22}>
          <Form.Item
            label="文件路径"
            name="importPath"
            rules={[{ required: true, message: '请选择文件' }]}
          >
            <Input
              onChange={(e) => {
                const value = e.target.value?.trim()
                const suffix = value?.split('.')?.pop()!; 
                if(fileType !== suffix){
                  return message.error(`请选择${fileType}类型文件`)
                }
                dispatch(setFilePath(e.target.value))
              }}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={2}>
          <Iconfont
            style={{ fontSize: 30 }}
            type="icon-document"
            onClick={() => dispatch(showModal('ModalOpenServerFile'))}
          ></Iconfont>
        </Col>
      </Row>
      {fileType !== 'xls' && fileType !== 'xlsx' && (
        <Form.Item
          label="编码"
          name="codeType"
          rules={[{ required: true, message: '请选择编码格式' }]}
          initialValue='UTF-8'
        >
          {/* GBK, GB2312, ISO-88591, UTF-8, US-ASCII */}
          <Select options={codeTypes}></Select>
        </Form.Item>
      )}
    </Form>
  )
}

const codeTypes = ['GBK', 'GB2312', 'ISO-88591', 'UTF-8', 'US-ASCII'].map(
  (c) => {
    return {
      label: c,
      value: c,
    }
  },
)
