import React, { FC, useContext } from "react";
import { Card, Button, Tooltip } from "antd";
import moment from "moment";
import { RedoOutlined } from '@ant-design/icons'
import { CURRENT_ROUTE_TYPE } from "src/constants";
import { EmptyCardContent } from "./EmptyCardContent";
import { FormContext } from "../ApplyFormContext";
import styles from "./index.module.scss";

interface IExecuteChange {
  currentStep: number;
  itemStepMark: number; //当前步骤
  isViewDetail: boolean;
  currentRouteType: CURRENT_ROUTE_TYPE;
  onExecuteApplication?: (id: number) => void;
  onTerminateApplication?: (id: number) => void;
  onCloseApplication?: (id: number) => void;
  onExecuteBack?: (id: number) => void;
  onRefresh?: () => void;
}

export const ExecuteChange: FC<IExecuteChange> = ({
  currentStep,
  itemStepMark, //当前步骤
  isViewDetail,
  currentRouteType,
  onExecuteApplication,
  onTerminateApplication,
  onCloseApplication,
  onExecuteBack,
  onRefresh
}) => {
  const { currentContextValue = {} } = useContext(FormContext);

  const { id = "", executeLogPath, rollbackLogPath } = currentContextValue;

  if (itemStepMark > currentStep) return <EmptyCardContent title="执行变更" />;

  //工单管理 只展示日志 自动执行展示时间
  return (
    <Card
      title={
        <>
          执行变更
          <span style={{ fontSize: 14 }}>
            <span style={{ color: "#3262FF" }}>
              &nbsp; &nbsp;{currentContextValue?.executeStatus} &nbsp; &nbsp;
            </span>
            {currentContextValue?.executeTime &&
              !(
                currentContextValue?.executeTimeType === 1 &&
                currentContextValue?.executeStatus === "待执行"
              ) && (
                <span>
                  执行时间:
                  <span style={{ color: "#868FA3" }}>
                    &nbsp; &nbsp;
                    {moment(currentContextValue?.executeTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </span>
                </span>
              )}
              {currentContextValue?.executeStatus === '执行中' && 
              <Tooltip title='刷新'>
                 <RedoOutlined style={{marginLeft: 8, color: '#3262FF'}} onClick={() =>onRefresh && onRefresh()}/>
              </Tooltip>}
          </span>
        </>
      }
      extra={
        currentRouteType === "MINE_APPLY" && (
          <>
            {currentContextValue?.executeStatus === "待执行" && (
              <>
                {/* 手动执行 */}
                {currentContextValue?.executeTimeType === 1 &&  currentContextValue?.ticketStatus === "open" && (
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      id && onExecuteApplication && onExecuteApplication(id)
                    }
                  >
                    执行
                  </Button>
                )}
                {currentContextValue?.ticketStatus === "open" && (
                  <Button
                    type="primary"
                    onClick={() =>
                      id && onTerminateApplication && onTerminateApplication(id)
                    }
                  >
                    中止任务
                  </Button>
                )}
              </>
            )}
            {currentContextValue?.executeStatus === "执行中" && (
              <Button
                type="primary"
                onClick={() =>
                  id && onTerminateApplication && onTerminateApplication(id)
                }
              >
                中止执行
              </Button>
            )}
            {["执行成功", "执行失败", "回退成功", "回退失败"].includes(
              currentContextValue?.executeStatus
            ) &&
              currentContextValue?.ticketStatus === "open" && (
                <Button
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    id && onCloseApplication && onCloseApplication(id)
                  }
                >
                  终止任务
                </Button>
              )}
            {["执行成功"].includes(currentContextValue?.executeStatus) &&
              currentContextValue?.ticketStatus === "open" && (
                <Button
                  type="primary"
                  onClick={() => id && onExecuteBack && onExecuteBack(id)}
                >
                  执行回退
                </Button>
              )}
          </>
        )
      }
      className={styles["step-card"]}
    >
      {itemStepMark === currentStep && (
        <div>
          <div style={{ color: "#667084" }}>
            执行日志：
            {executeLogPath ? (
              <span
                style={{
                  color: "#3262FF",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  executeLogPath &&
                  window.open(`/api/flow/dataChange/${id}/dataChangeLog`)
                }
              >
                查看日志
              </span>
            ) : (
              "-"
            )}
          </div>
            <div style={{ color: "#667084" }}>
              回退日志：
              {rollbackLogPath ? (
                <span
                  style={{
                    color: "#3262FF",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    rollbackLogPath &&
                    window.open(
                      `/api/flow/dataChange/${id}/dataChangeRollbackLog`
                    )
                  }
                >
                  查看日志
                </span>
              ) : (
                "-"
              )}
            </div>
        </div>
      )}
    </Card>
  );
};
