import React, { useEffect, useState } from 'react'
import { Layout, Breadcrumb } from 'antd'
import { OrgSdt } from './orgSdt/OrgSdt'
import { DeptDetail } from './deptDetail/DeptDetail'
import 'src/styles/layout.scss'
import { ErrorBoundary } from 'src/components'
import { useSelector } from 'src/hook'

const { Header, Sider, Content } = Layout

export const OrganizationPage = () => {

  const { permissionList } = useSelector((state) => state?.login)
  const [permissionlist, setPermissionlist] = useState<{isOnlyRead: boolean, roleNameList: any}>()

  useEffect(() => {
    // login返回的key 与路由不一致，
    // const pathName = (window.location.pathname.split("/")[1].toUpperCase() as keyof typeof permissionList)
    // setPermissionlist(permissionList?.SYSTEM_MANAGEMENT?.[pathName])

    setPermissionlist(permissionList?.SYSTEM_MANAGEMENT?.USER_MANAGEMENT)
  }, []);
  
  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header">
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item>用户管理</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main">
        <Sider className="cq-aside" width={246}>
          <ErrorBoundary>
            <OrgSdt permissionlist={permissionlist}></OrgSdt>
          </ErrorBoundary>
        </Sider>
        <Content className="cq-content">
          <ErrorBoundary>
            <DeptDetail permissionlist={permissionlist}></DeptDetail>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  )
}
