import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Theme = 'dark' | 'light'

interface AppearanceState {
  theme: Theme
  editorPromptOnClose: boolean
  reRenderTheRoot: boolean
}

const initialState: AppearanceState = {
  theme: 'light',
  editorPromptOnClose: false,
  reRenderTheRoot: false
}
export const appearanceSlice = createSlice({
  name: 'appearance',
  initialState,
  reducers: {
    setQueryTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload  
    },
    setEditorPromptOnClose: (state, action: PayloadAction<boolean>) => {
      const editorPromptOnClose = action.payload
      state.editorPromptOnClose = editorPromptOnClose
    },
    setReRenderTheRoot: (state, action: PayloadAction<boolean>) => {
      state.reRenderTheRoot = action.payload
    }
  },
})

export const appearanceReducer = appearanceSlice.reducer

export const { setQueryTheme, setEditorPromptOnClose, setReRenderTheRoot } = appearanceSlice.actions
