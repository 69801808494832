  // tree数据拉平
  export const flatData = (treeData: any[]) => {
    let dataList: any[] = []
    const generateList = (data: any[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i]
        const { id, type, name, path, key} = node
        dataList.push({ id, type, name, path, key });
        if (node.children) {
          generateList(node.children)
        }
      }
    }
    generateList(treeData)
    return dataList
  }

  // customTreeData
  export const treeNodeAdapter = (arr: any[], pKey?:''): any => {
    return arr?.map((item: any) => {
      const { name, orgId, userId, type, child=[], path } = item
      const id = orgId || userId
      const key = pKey ? pKey + '_' + id : id
  
      return {
        id,
        name,
        type,
        key,
        title: type==="USER" ? `${name}(${userId})` : name,
        children: child?.length ? treeNodeAdapter(child, key) : [],
        isLeaf: !child?.length,
        path
      }
    })
  }
  