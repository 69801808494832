/* -------------------------------------------------------------------------- */
/*                                  用户信息相关api                                 */
/* -------------------------------------------------------------------------- */
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  fetchPostFormData,
} from './customFetch'
import { RoleEntity } from './permission'
import { DataSourceType, QueryParams, UserConfig, UserEntity } from '../types'
/** RSA ｜ SM2 公共方法 */
import { setEncrypt } from 'src/util/encryptRsaSm2'
import { store } from 'src/store'
import { ExecuteStatus, getTransmissionPublicKey } from 'src/api'
import { DownloadMsg } from 'src/features/websocket/useDownloadNotification'

/* -------------------------------- interface ------------------------------- */
export interface IMaskStatus {
  emailMask: boolean
  phoneMask: boolean
}

export type TResponse_getAllSimpleUsers = Array<{
  userName: string
  userId: string
}>

/* ----------------------------------- 请求 ----------------------------------- */
//opt接口绑定 refresh
export const refreshUserOTPKey = (userId: string) => {
  return fetchGet(`/user/users/userOTPKey/${userId}`)
}

//获取opt initial
export const getUserOTPKey = (userId: string) => {
  return fetchGet(`/user/users/getUserOTPKey/${userId}`)
}

export const getUserDetail = (
  maskStatus: IMaskStatus,
  userId?: number | string,
) => {
  return fetchPost(`/user/users/infos`, maskStatus)
}
export const getAllUsers = () => {
  return fetchGet(`/user/users`)
}

/**
 * @description: 简化版用户列表
 * @param {*}
 * @return {Promise<>}
 */
export const getAllSimpleUsers = (): Promise<TResponse_getAllSimpleUsers> => {
  return fetchGet('/user/users/simpleUsers')
}

/**
 * @description: 流程用户列表
 * @param {*}
 * @return {Promise<>}
 */
export const getAllFlowUsers = (): Promise<TResponse_getAllSimpleUsers> => {
  return fetchGet('/user/login/fetchUsers/flow')
}

/**
 * @description: 数据变更用户列表
 * @param {*}
 * @return {Promise<>}
 */
export const getAllDataChangeUsers = (): Promise<TResponse_getAllSimpleUsers> => {
  return fetchGet('/user/login/fetchUsers/dataChange')
}

/**
 * 审计范围，审计用户
 * @returns {Promise<>}
 */
export const getAuditUsers = (): Promise<TResponse_getAllSimpleUsers> => {
  return fetchGet('/user/users/auditUsers')
}

export const getAllApprovalUsers = (): Promise<string[]> => {
  return fetchGet('/user/common/dbaAndSenior')
}

/** 通过用户名筛选用户列表 */
export const getSearchUsers = (data: {
  userName?: string | undefined
  deptId?: string | undefined
  groupId?: string | undefined
  orgId?: number | undefined
  nameValue?: string | undefined
  pageNum: number
  pageSize: number
}) => {
  return fetchGet(
    `/user/users/userName?orgId=${data?.orgId}${data?.nameValue ? '&nameValue=' + data?.nameValue : '&nameValue'
    }&pageNum=${data?.pageNum}&pageSize=${data?.pageSize}`,
  )
}

export const searchAllUsers = (data: {
  userName?: string | undefined
  companyId?: number | undefined
  nameValue?: string | undefined
  pageNum: number
  pageSize: number
}) => {
  return fetchGet(
    `/user/users/userName/company?companyId=${data?.companyId}${data?.nameValue ? '&nameValue=' + data?.nameValue : '&nameValue'
    }&pageNum=${data?.pageNum}&pageSize=${data?.pageSize}`,
  )
}
export const getConnectionUsers = (connectionId: string | number) => {
  //新版本不需要查连接下的用户，直接查所有
  return fetchGet(`/user/users/simpleUsers`)
}

/** 获取部门下用户列表 */
export const getDeptUsers = (deptId: string | number) => {
  return fetchGet(`/user/org/${deptId}/managerUserId`)
}

/** 获取组用户列表 */
export const getOrgGroupUsers = (groupId: string) => {
  return fetchGet(`/user/users/group/${groupId}`)
}

export const getOwnerConnectionList = (): Promise<any[]> => {
  return fetchGet(`/dms/connection`)
}

export const getTerminalParams = (connectionId: number | string) => {
  return fetchGet(`/dms/connection/${connectionId}`)
}

/** 获取连接下用户列表 */
export const getConnectionUserList = (connectionId: string | number) => {
  return fetchGet(`/user/connections/users/${connectionId}`)
}

/** 连接下设置用户 */
export const setConnectionUsers = (
  connectionId: string | number,
  userIds: string[],
) => {
  return fetchPut(`/user/connections/users`, { connectionId, userId: userIds })
}

/** 连接下移除用户 */
export const removeConnectionUsers = (
  connectionId: string | number,
  userIds: string[],
) => {
  return fetchDelete(`/user/connections/users`, {
    connectionId,
    userId: userIds,
  })
}

/** 新增用户 */
export const addUser = async (params: Partial<UserEntity>) => {
  return fetchPost(`/user/users`, params)
}

/** 角色  */
export const getRoles = (param: { name: string, type: string }) => {
  return fetchPost(`user/common/roles`, param)
}

/** 编辑用户 */
export const editUser = (params: Partial<UserEntity>) => {
  return fetchPut(`/user/users/update`, params)
}

/** 删除用户 */
export const deleteUser = (userId: string) => {
  return fetchDelete(`/user/users/${encodeURIComponent(userId)}`)
}

/** 修改用户密码 */
export const changeUserPassword = async ({
  oldP,
  newP,
}: {
  userId?: string
  oldP?: string
  newP: string
}) => {
  /** SM2|RSA 加密 */
  // const { algorithm, publicKey } = store.getState().encryption
  const getKey = await getTransmissionPublicKey()
  const oldPassword =
    oldP &&
    setEncrypt({
      algorithm: getKey?.algorithm,
      publicKey: getKey?.publicKey,
      password: oldP,
    })
  const newPassword =
    oldP &&
    setEncrypt({
      algorithm: getKey?.algorithm,
      publicKey: getKey?.publicKey,
      password: newP,
    })
  // const oldPassword = oldP && btoa(unescape(encodeURIComponent(oldP)))
  // const newPassword = btoa(unescape(encodeURIComponent(newP)))
  const payload = { oldPassword, newPassword }
  return fetchPut(`/user/users/pwd`, payload)
}
/*修改用户邮箱*/
// export const changeUserEmail = ({
//   email,
//   userId,
// }: {
//   email: string
//   userId: string
// }) => {
//   //若email 为空会导致请求地址404 与后端商议后 传固定字符串empty 避免该问题
//   const formatEmail = email === undefined || email === '' ? 'empty' : email
//   return fetchPut(`/user/users/modify/email/${userId}/${formatEmail}`)
// }

/* 修改信息 */
export const modifyUserInfo = (params: {
  userId: string
  email: string
  telephone: string
}) => {
  return fetchPut('/user/users/modify/userInfo', params)
}

/* 用户第一次登录修改密码 */
export const changeUserPasswordFirstLogin = async ({
  oldP,
  newP,
}: {
  userId?: string
  oldP?: string
  newP: string
}) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  const newPassword = setEncrypt({
    algorithm: getKey?.algorithm,
    publicKey: getKey?.publicKey,
    password: newP,
  })
  // const { algorithm, publicKey } = store.getState().encryption
  // const newPassword = btoa(unescape(encodeURIComponent(newP)))
  return fetchPut(`/user/users/first/pwd`, { newPassword })
}

/** (管理员)修改用户密码 */
export const resetUserPassword = async ({
  userId,
  newP,
}: {
  userId: string
  newP: string
}) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  const newPassword = setEncrypt({
    algorithm: getKey?.algorithm,
    publicKey: getKey?.publicKey,
    password: newP,
  })
  //  const { algorithm, publicKey } = store.getState().encryption
  return fetchPost(`/user/users/pwd`, { userId, newP: newPassword })
}

export const getUserRoles = (): Promise<RoleEntity[]> => {
  return fetchGet(`/user/common/role/user`)
}

export interface UserConnection {
  connectionId: number | string
  connectionName: string
  connectionType: string
  public: boolean
  userName: string
  creator: string
  dataSourceType: string
  isTestEnv: boolean
}
export const getUserConnections = (
  userId: string,
): Promise<UserConnection[]> => {
  return fetchGet(`/user/users/connections/qualified/${userId}`)
}

/** 修改用户状态 */
export const changeUserStatus = ({
  userId,
  flag,
}: {
  userId: number | string
  flag: boolean
}) => {
  return fetchPut(`/user/users/${userId}/${flag}`)
}

export const getUserConfig = (): Promise<UserConfig> => {
  return fetchGet(`/user/users/settings`)
}

export const patchUserConfig = (params: Partial<UserConfig>) => {
  return fetchPost(`/user/users/settings`, params)
}

// 用户执行历史
export interface UserConnectionInfo {
  connectionId: number | string
  connectionType: DataSourceType
  connectionName: string
  creatorName: string
  deleted: boolean
}
export const getCommonlyUsedConnections = (
  params: DaysSpanPagination,
): Promise<{ connectionInfo: UserConnectionInfo[]; totalCount: number }> => {
  return fetchPost(`/user/history/getCommonlyUsedConnections`, params)
}

export interface HistoryRecord {
  id: number | string
  connectionInfo: UserConnectionInfo
  databaseName: string
  sql: string
  resultStatus: /** 结果 */ boolean
  startTime: /** 执行时间 */ string
  timeConsuming: /** 语句耗时 ms */ number
}
interface DaysSpanPagination {
  daysSpan: number
  pagination: {
    page: number
    pageSize: number
  }
}
export interface UserHistoryResponse extends DaysSpanPagination {
  historyRecords: HistoryRecord[]
  totalCount: number
}

export interface ImportResult {
  executeStatus: ExecuteStatus,
  messageData: DownloadMsg
}

export const getUserHistoryRecords = (
  params: DaysSpanPagination,
): Promise<UserHistoryResponse> => {
  const {
    daysSpan = 1,
    pagination = { page: 0, pageSize: 0 },
    ...other
  } = params
  return fetchPost(`/user/history/records`, { daysSpan, pagination, ...other })
}

export const getUserHistoryRecordsBySearch = (
  params: DaysSpanPagination,
): Promise<UserHistoryResponse> => {
  const { pagination = { page: 0, pageSize: 0 }, ...other } = params
  return fetchPost(`/user/history/search/records`, { pagination, ...other })
}

export const deleteUserHistoryRecords = (recordIds: (number | string)[]) => {
  return fetchDelete(`/user/history/records`, recordIds)
}

/* 获取权限详情 */
export const getPermissionDetail = (permKey: string) => {
  return fetchPost(`/user/permission/permissionInfo`, permKey)
}

/**批量导入用户 */
export const batchImportUserModal = (parentDeptId: string, data: any) => {
  return fetchPostFormData(`/user/users/import/user/${parentDeptId}`, data)
}

/** 获取导入结果 */
export const getBatchImportUserResult = (params: QueryParams): Promise<ImportResult> => {
  return fetchPost(`/dms/connection/import/message`, params)
}

/** 移除用户权限 */
export const removeUser = (userId: string) => {
  return fetchPut(`/user/common/rm_role?userId=${userId}`)
}

/** 复制用户权限 */
export const CopyUser = (parms: { sourceUser: string; targetUser: string }) => {
  return fetchPost(`/user/common/copy/roles`, parms)
}

/** 获取可复制用户列表 */
export const GetCopyUser = () => {
  return fetchGet(`/user/users/all_can_copied`)
}

/* 获取logo url */
export const getLogo = () => {
  return fetchGet(`/user/sys/logo`)
}

/* 获取背景图片 url*/
export const getBackImg = () => {
  return fetchGet(`/user/sys/background`)
}

/** 删除图片 logo | backimg*/
export const deleteImgLogo = (name: string) => {
  return fetchGet(`/user/sys/deleteImg?name=${name}`)
}
/**
 * 添加用户
 */
interface addUsertI {
  groupId: number
  userIds: string[]
  connectionIds: number[]
  isReadOnly: boolean,
}
export const addAuthorizeUser = (params: addUsertI) => {
  return fetchPost(`/user/group/addUser`, params)
}

/**
 * 组织架构和人员查询
 */
export const queryOrgWithUser = () => {
  return fetchGet(`/user/org/withUser`)
}
/** 用户参数 分页搜索 */
interface UserParameterType {
  parameterName: string,
  pageNum: number,
  pageSize: number
}

export interface UserParameterEditType {
  id?: number
  parameterName: string
  regex?: string
  tagName: string
  description: string
  needed: boolean
  accessMode: string
}
export interface UserParameterValue {
  id?: number
  value?: string
  parameter?: UserParameterEditType;
}

export interface UserSupplement {
  id?: number
  address: string | null
  area: string | null
  empoyer: string | null
  idCard: string | null
  institutionCode: string | null
  userId: string | null
}

/** 用户参数 分页搜索 */
export const getUserParametersSearch = (params: UserParameterType) => {
  const { parameterName, pageNum, pageSize } = params
  return fetchGet(`/user/parameters?parameterName=${parameterName}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

/** 用户参数 删除 */
export const deleteUserParameters = (tagName: string) => {
  return fetchDelete(`/user/parameters//${tagName}`)
}

/** 用户参数 查看 */
export const relationUserParameters = (tagName: string) => {
  return fetchGet(`/user/parameters/relation/${tagName}`)
}

/* 用户参数 添加 */
export const addUserParameters = (data: Partial<UserParameterEditType>) => {
  return fetchPost(`/user/parameters`, data)
}

/* 用户参数 编辑 */
export const editUserParameters = (data: Partial<UserParameterEditType>) => {
  return fetchPut(`/user/parameters`, data)
}

/**获取部门负责人  */
export const getDeptUsersManagerId = (deptId: string | number) => {
  return fetchGet(`/user/org/managerId/${deptId}`)
}

/** 获取水印参数 分页搜索 */
export const getWatermarkKey = (params: {pageNum: number, pageSize: number}) => {
  const { pageNum, pageSize } = params
  return fetchGet(`/user/parameters/watermark?pageNum=${pageNum}&pageSize=${pageSize}`)
}

/** 获取水印 */
export const getWatermarkValue = () => {
  return fetchGet(`/user/sys/watermark/setting/value?type=WATERMARK_SETTING`)
}
/** 获取pdf水印 */
export const getPdfWatermarkValue = () => {
  return fetchGet(`/user/sys/watermark/setting/value?type=PDF_WATERMARK_SETTING`)
}
/** 获取excel水印 */
export const getExcelWatermarkValue = () => {
  return fetchGet(`/user/sys/watermark/setting/value?type=EXCEL_WATERMARK_SETTING`)
}

/** 个人信息 补充信息 */
export const updateUserSupplementInfo = (data: Partial<any>) => {
  return fetchPost(`/user/users/updateUserParameterValue`, data)
}
/** 个人信息 修改姓名 */
export const changeUserName = (params: { userId: any, userName: any }) => {
  return fetchPut(`/user/users/changeUserName/${params.userId}/${params.userName}`)
}
/** */
export const getUserAvatarAndUserName = () => {
  return fetchGet(`/user/users/getUserAvatarAndUserName`)
}

/** 个人中心 可访问资源 */
export const viewableResourceList = (
  userId: string,
): Promise<any> => {
  return fetchPost(`/user/permission/user/viewableResourceList`, {
    userId
  })
}

/** 个人中心 可访问资源 查看详情 */
export const viewableResourceDetail = (
  params: any,
): Promise<any> => {
  return fetchPost(`/user/permission/user/viewableResourceDetail`, params)
}

/** 批量启用/禁用 */
export const updateUsersStatus = (
  params: {
    userIds: any,
    status: boolean,
  },
): Promise<any> => {
  return fetchPost(`/user/users/batch/change`, params)
}

/** 批量重置密码 */
export const resetPwd = async ({
  userIds,
  newP,
}: {
  userIds: any
  newP: string
}) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  const newPassword = setEncrypt({
    algorithm: getKey?.algorithm,
    publicKey: getKey?.publicKey,
    password: newP,
  })
  //  const { algorithm, publicKey } = store.getState().encryption
  return fetchPost(`/user/users/batch/resetPwd`, { userIds, newP: newPassword })
}

/** 批量删除用户 */
export const deleteUsers = (
  params: {
    userIds: any,
  },
): Promise<any> => {
  return fetchPost(`/user/users/batch/delete`, params)
}

/** 检查当前用户是否绑定企业微信（已绑定，可以进行解绑操作） */
export const checkWwBoundChat = () => {
  return fetchGet(`/user/login/wwBoundChat`)
}

/** 检查企业微信是否绑定过用户,需要扫码后调用 */
export const checkWwPreBindChat = (code: string) => {
  return fetchGet(`/user/login/wwPreBindChat?code=${code}`)
}

/** 确认绑定企业微信 */
export const wwBindChat = (weChatUserId: string) => {
  return fetchGet(`/user/login/wwBindChat?weChatUserId=${weChatUserId}`)
}

/** 解绑企业微信 */
export const wwUnBindChat = () => {
  return fetchGet(`/user/login/wwUnBindChat`)
}

/** sql审核 */

/** 按条件查询模板规则，模板详情使用 */
export const getRuleList = (
  params: {
    "templateId"?: string | number,
    "level"?: string, // "规则等级: ERROR/WARNING/NOTICE"
    "enableStatus"?: boolean,
    "ruleType"?: string, // 语句类型：DML/DDL/DQL/INDEX_OPTIMIZE/OTHER/...
  }) => {
  return fetchPost(`/user/review/rule`, params)
}

/** 新增规则模板时需要根据返回的枚举匹配数据源后拿到值请求规则列表 */
export const getDefaultRuleList = () => {
  return fetchGet(`/user/review/rule/default`)
}

/** 创建规则 */
export const createNewRule = () => {
  return fetchPut(`/user/review/rule`)
}

/** 批量更新规则（状态或等级) */
export const batchUpdateRule = (status: boolean, ids: React.Key[]) => {
  return fetchPost(`/user/review/rule/${status}`, ids)
}

/** 更新规则（状态或等级) */
export const updateRule = (
  params: {
    "id": number,                    // 模板id
    "level": string,           // 等级
    "enableStatus": boolean,       // 启用状态
    "scenario": string    // 场景，多个场景用逗号分隔，例如 "SQL_WINDOW,BATCH_EXECUTE"
  }) => {
  return fetchPost(`/user/review/rule/update`, params)
}

/** 根据id删除一条规则 */
export const delRule = (id: string) => {
  return fetchDelete(`/user/review/rule/${id}`)
}

/**  根据id集批量删除规则 */
export const batchDelRules = (params: any[]) => {
  return fetchDelete(`/user/review/rule`, params)
}

/** 获取规则等级枚举值 */
export const getRuleLevel = () => {
  return fetchGet(`/user/review/rule/level`)
}

/** 按条件查询模板规则page limit */
export const getTemplateList = (
  params: {
    "templateName": string, // "模板名称，可选",
    "description": string, // "描述，可选",
    "dataSourceType": string, //  "数据源类型，可选",
    "pageNumber": number, //  1,
    "pageSize": number
  }) => {
  return fetchPost(`/user/review/template`, params)
}

/** 创建规则模板 */
export const createNewTemplate = (
  params: {
    "dataSourceType": string, // "Oracle", // 数据源类型
    "templateName": string, //"模板",       // 模板名称
    "description": string, //"这是描述" ,  // 模板描述
    "customTemplate": boolean, //true,      // 用户自定义模板
    "connectionIds": any[],  // 连接id
    "ruleList": [{
      "id": number,                   // 规则id
      "dataSourceType": string,       // 数据源类型
      "level": string,                // 等级
      "name": string,                 // 规则名称
      "description": string,          // 规则描述
      "enableStatus": boolean,        // 启用状态
      "customRule": boolean,          // 用户自定义规则
      "ruleType": string,             // 语句类型
      "scenario": string,             // 场景，多个场景用逗号分隔，例如 "SQL_WINDOW,BATCH_EXECUTE"
      "params": string,               // 参数
    }]
  }
) => {
  return fetchPut(`/user/review/template`, params)
}

/** 更新规则模板 */
export const updateTemplate = (
  params: {
    "id": number,
    "templateName": string, // "模板名称，可选",
    "description": string, // "描述，可选",
    "connectionIds": number[] // 连接id,可选
  }) => {
  return fetchPost(`/user/review/template/update`, params)
}

/** 删除规则模板 */
export const delTemplate = (id: number) => {
  return fetchDelete(`/user/review/template/${id}`)
}

/** 获取模板对应的生效连接 */
export const getListByTemplate = (id: number) => {
  return fetchGet(`/user/review/template/${id}`)
}

/** 复制模板 */
export const copyTemplate = (params: {
  "connectionIds": number[], // [11,12,34], // 连接id,可选
  "id": number, // 11,                   // 模板id
  "templateName": string //"模板",       // 模板名称
}) => {
  return fetchPut(`/user/review/template/copy`, params)
}

/** 根据数据源取数据源下连接 */
export const getConnectionByType = (datasourceType:string) => {
  return fetchGet(`/user/review/template/connection/${datasourceType}`)
}

/** 获取数据源和模板对应的枚举 
 * 形如{
 * "PostgreSQL": 3,
 * "MySQL": 1,
 * "Oracle": 2,
 * "SQLServer": 4
 * }
*/
export const getTemplateIdByType = () => {
  return fetchGet(`/user/review/template/default`)
}

/**
 * 批量修改规则场景和规则等级
 * 入参
{
    "ids": [1,2,3,4],                    // 规则id数组 选中的多个规则id
    "level": "ERROR",           // 等级
    "scenario": "SQL_WINDOW"    // 场景，多个场景用逗号分隔，例如 "SQL_WINDOW,BATCH_EXECUTE"
}
 */
export const batchUpdateRules = (params: any) => {
  return fetchPost(`/user/review/rule/batchUpdate`, params)
}