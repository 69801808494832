import React, { useState, useEffect, useMemo } from 'react'
import { SettingOutlined, RedoOutlined } from '@ant-design/icons'
import { useQuery, useRequest } from 'src/hook'
import { Row, Col, Select, Radio, Button, Popconfirm, notification } from 'antd'
import { ConnectionIcon, DatePicker } from 'src/components'
import dayjs, { Dayjs } from 'dayjs'
import {
  DataSourceType,
  getAuditUsers,
  getSupportedConnectionTypes,
  SqlType,
  SqlTypes,
  ExtratTypes,
  ExecutionReportRequest,
  exportAuditLogSql,
} from 'src/api'
import styles from './index.module.scss'
import { formatDateRange, handleDownload } from 'src/util'
import { useLocation } from 'react-router-dom'
import { openNotification } from 'src/components/ExportNotification'

const { RangePicker } = DatePicker
const { Option } = Select

interface FilterParams {
  executors?: string[]
  timeRange?: [number, number] | null
  dbTypes?: DataSourceType[]
  sqlTypes?: SqlType[]
  // 0 执行失败 1 执行成功
  resultFlag?: string
  extratTypes?: ExtratTypes[]
  // 0所有 1执行编辑器 2终端
  actuatorType?: 0 | 1 | 2
  limit?: number
}

// 根据组件 filterParams 状态构造出查询请求所需要的参数
const getRequestParams = (filterParams: FilterParams) => {
  const { executors, timeRange, dbTypes, sqlTypes, resultFlag, extratTypes } = filterParams
  // 构造查询参数
  const params: Partial<ExecutionReportRequest> = {
    executeBeginMs: timeRange?.[0],
    executeEndMs: timeRange?.[1],
  }
  if (executors?.length) {
    params.executors = executors
  }
  if (dbTypes && dbTypes[0]) {
    params.dbTypes = dbTypes.map((dataSourceType) =>
      dataSourceType.toUpperCase(),
    ) as DataSourceType[]
  }
  if (sqlTypes && sqlTypes[0]) {
    params.sqlTypes = sqlTypes
  }
  if (resultFlag !== undefined) {
    params.resultFlag = Number(resultFlag)
  }
  if (extratTypes?.[0]) {
    params.extratTypes = extratTypes
  }
  delete filterParams.timeRange
  delete filterParams?.actuatorType
  return { ...filterParams, ...params }
}

export const SearchHeader = ({
  queryParams = {},
  setSearchParams,
  refresh,
  showCustomColumnPanel,
  isUnauthorized,
}: {
  queryParams: any;
  setSearchParams: (values: any) => void
  refresh: () => void
  showCustomColumnPanel: () => void
  isUnauthorized?: boolean
}) => {
  //@ts-ignore
  // const {state} = useLocation() as any;
  const [filterParams, setFilterParams] = useState<FilterParams>({})
  // 全部用户列表
  const [userList, setUserList] = useState<any[]>([])

  const {
    executors,
    timeRange,
    dbTypes,
    sqlTypes,
    resultFlag,
    extratTypes,
    actuatorType,
  } = filterParams

  const rangeValue = useMemo(() => {
    if (timeRange === null) {
      return null
    } else {
      const range = timeRange && timeRange.map((timestamp) => dayjs(timestamp)) as [
        Dayjs,
        Dayjs,
      ]
      return range
    }
  }, [timeRange])

  useEffect(() => {
    // 获取用户列表
    const getUserList = async () => {
      try {
        const users = await getAuditUsers()
        setUserList(users)
      } catch { }
    }
    getUserList()
  }, [])

  useEffect(() => {
    setFilterParams(queryParams)

  }, [queryParams])


  const { data: connectionTypes, loading: loadingConnectionTypes } = useRequest(
    getSupportedConnectionTypes,
    {
      formatResult: (data) => {
        return data?.map((dataSource) => {
          const { connectionForm } = dataSource
          const connectionFormFiltered = connectionForm.filter(
            ({ label }) => label !== '监控开关',
          )
          return Object.assign({}, dataSource, {
            connectionForm: connectionFormFiltered,
          })
        })
      },
    },
  )

  const exportData = () => {
    exportAuditLogSql(getRequestParams(filterParams) as Partial<ExecutionReportRequest>).then((res: number) => {
      const origin = window?.location?.origin;
      openNotification(
        '任务 语句明细导出',
        '已经执行完成，文件生成成功',
        () => handleDownload({ href: `${origin}/export/export/download/${res}` })
      )
    })
  }

  const ConnectionTypeOptions = connectionTypes?.map(({ dataSourceName }) => ({
    label: (
      <>
        <ConnectionIcon type={dataSourceName} />
        {dataSourceName}
      </>
    ),
    value: dataSourceName,
  }))

  const SqlTypeOptions = SqlTypes.map((type) => ({ label: type, value: type }))

  return (
    <div className={styles.searchHeader}>
      {
        !isUnauthorized ? <Radio.Group
          value={resultFlag}
          buttonStyle="solid"
          onChange={(e: any) =>
            setSearchParams({ ...filterParams,resultFlag: e.target.value, offset: 0 })
          }
        >
          <Radio.Button value={1}>执行成功</Radio.Button>
          <Radio.Button value={0}>执行失败</Radio.Button>
        </Radio.Group> : <>&nbsp;</>
      }

      <Row justify="end" gutter={16} style={{ alignItems: 'center' }}>
        <label>过滤条件:</label>
        <Col>
          <Select
            mode="multiple"
            showArrow
            placeholder="用户"
            value={executors}
            onChange={(selected) => {
              setFilterParams({ ...filterParams, executors: selected })
            }}
            maxTagCount={1}
            maxTagTextLength={10}
            allowClear
            style={{ minWidth: 144 }}
            optionFilterProp="label"
          >
            {userList.map((user) => {
              const { userId, userName } = user
              return (
                <Option
                  key={userId}
                  value={userId}
                  label={`${userName}(${userId})`}
                >
                  {`${userName}(${userId})`}
                </Option>
              )
            })}
          </Select>
        </Col>
        <Col>
          <Select
            showArrow
            placeholder="数据源类型"
            value={dbTypes}
            onChange={(dbTypes) => {
              setFilterParams({ ...filterParams, dbTypes })
            }}
            allowClear
            style={{ minWidth: 144 }}
            mode="multiple"
            maxTagCount={1}
            options={ConnectionTypeOptions}
            loading={loadingConnectionTypes}
          ></Select>
        </Col>
        <Col>
          <Select
            showArrow
            placeholder="语句类型"
            value={sqlTypes}
            onChange={(sqlTypes) => {
              setFilterParams({ ...filterParams, sqlTypes })
            }}
            allowClear
            style={{ minWidth: 96 }}
            mode="multiple"
            maxTagCount={1}
            options={SqlTypeOptions}
          ></Select>
        </Col>
        <Col>
          <Select
            showArrow
            placeholder="执行器类型"
            value={actuatorType}
            onChange={(actuatorType: 0 | 1 | 2) => {
              setFilterParams({ ...filterParams, actuatorType })
            }}
            style={{ minWidth: 96 }}
          >
            <Option key="0" value={0}>
              所有
            </Option>
            <Option key="1" value={1}>
              执行编辑器
            </Option>
            <Option key="2" value={2}>
              终端编辑器
            </Option>
          </Select>
        </Col>
        <Col>
          <RangePicker
            value={rangeValue}
            onChange={(dates) => {
              if (dates === null) {
                return setFilterParams({
                  ...filterParams,
                  timeRange: null,
                })
              }
              const [start, end] = formatDateRange(dates)
              if (start && end) {
                setFilterParams({
                  ...filterParams,
                  timeRange: [start, end],
                })
              }
            }}
            allowClear
            placeholder={['开始日期', '结束日期']}
          />
        </Col>
        <Col>
          <Button
            onClick={() => setSearchParams({ ...filterParams, offset: 0 })}
            type="primary"
            className="mr8"
          >
            查询
          </Button>
          <Button
            onClick={() =>
              setFilterParams({
                executors: [],
                timeRange: null,
                actuatorType: 0,
                sqlTypes: [],
                dbTypes: [],
                resultFlag: undefined,
              })
            }
            className="mr8"
          >
            重置
          </Button>
          <Popconfirm
            title="确定导出当前筛选结果？"
            onConfirm={exportData}
            placement="bottomRight"
          >
            <Button className="mr8">导出</Button>
          </Popconfirm>
          <Button
            icon={<RedoOutlined className={styles.refreshIcon} />}
            onClick={() => refresh()}
          />
          <Button
            className={styles.settingIcon}
            icon={<SettingOutlined />}
            type="primary"
            onClick={() => showCustomColumnPanel()}
          />
        </Col>
      </Row>
    </div>
  )
}
