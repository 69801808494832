import React from 'react'
import { List, Typography } from 'antd'
import { UIModal } from 'src/components'

export const ModalShowMoreCommit = React.memo(
  ({
    commitList,
    visible,
    closeModal,
  }: {
    commitList?: string[]
    visible?: boolean
    closeModal: () => void
  }) => {
    return (
      <UIModal
        visible={visible}
        title="待提交语句"
        footer={null}
        onCancel={() => closeModal()}
        width={480}
      >
        <List
          size="small"
          dataSource={commitList}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text ellipsis>{item}</Typography.Text>
            </List.Item>
          )}
          pagination={{ pageSize: 10 }}
        />
      </UIModal>
    )
  },
)
