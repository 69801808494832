/**
 * @description 属性面板属性控制文件
 * @note 流程校验不使用validate校验,会出现loop属性的问题和bpmnlint默认校验内容冲突
 * // TODO 修改bpmn默认流程校验
 */

'use strict'

var entryFactory = require('../../../factory/EntryFactory')
var getBusinessObject = require('bpmn-js/lib/util/ModelUtil').getBusinessObject,
  elementHelper = require('bpmn-js-properties-panel/lib/helper/ElementHelper'),
  cmdHelper = require('../../../helper/CmdHelper'),
  is = require('bpmn-js/lib/util/ModelUtil').is

var { getAllSimpleUsers, getAllFlowUsers, getAllDataChangeUsers } = require('../../../../../../../../api/')
// 落权节点id
var approvalId = 'fat_power_node'

/* --------------------------- loopCharacteristics -------------------------- */

function getProperty(element, propertyName) {
  var loopCharacteristics = getLoopCharacteristics(element)
  return loopCharacteristics && loopCharacteristics.get(propertyName)
}
/* ----------------------------- common function ---------------------------- */

/**
 * @description: 获取受理人下拉框列表
 * @return {*}
 */
function getData() {
  const getUserData = () => {
    if (location.pathname === '/flow_design') {
      return getAllFlowUsers();
    } else if (location.pathname === '/data_change_flow_design') {
      return getAllDataChangeUsers();
    }
  }

  return getUserData().then((data) => {
    return data.map(({ userName, userId }) => {
      return {
        name: userName + "(" + userId + ")",
        value: userId,
      }
    })
  })
}

/* -------------------------- bpmn:FormalExpression ------------------------- */

/**
 * Get the body of a given expression.
 *
 * @param {ModdleElement<bpmn:FormalExpression>} expression
 *
 * @return {string} the body (value) of the expression
 */
function getBody(expression) {
  return expression && expression.get('body')
}

/**
 * Creates 'bpmn:FormalExpression' element.
 *
 * @param {ModdleElement} parent
 * @param {string} body
 * @param {BpmnFactory} bpmnFactory
 *
 * @result {ModdleElement<bpmn:FormalExpression>} a formal expression
 */
function createFormalExpression(parent, body, bpmnFactory) {
  return elementHelper.createElement(
    'bpmn:FormalExpression',
    { body: body },
    parent,
    bpmnFactory,
  )
}
/* ---------------------------- ExtensionElement ---------------------------- */

function createExtensionElement(element, bpmnFactory) {
  const commands = []
  const extensionElements = elementHelper.createElement(
    'bpmn:ExtensionElements',
    { values: {} },
    element,
    bpmnFactory,
  )
  commands.push(
    cmdHelper.updateProperties(element, {
      extensionElements: extensionElements,
    }),
  )
  const formData = elementHelper.createElement(
    'activiti:ExecutionListener',
    {
      event: 'start',
      class: 'cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener',
    },
    extensionElements,
    bpmnFactory,
  )
  commands.push(
    cmdHelper.addAndRemoveElementsFromList(
      element,
      extensionElements,
      'values',
      'extensionElements',
      [formData],
      [],
    ),
  )
  return commands
}

/* ---------------------------- common  function ---------------------------- */
function judgeMutiRuleChildPropertyStatus(element) {
  let status
  if (ensureMultiInstanceSupported(element)) {
    status = getLoopCharacteristicsValue_isSequential_open(element)
  } else {
    status = false
  }
  return status
}

function getLoopCharacteristicsValue_isSequential_open(element) {
  let status
  if (getLoopCharacteristics(element)) {
    if (getLoopCharacteristics(element).get('isSequential') === 'true') {
      status = false
    } else {
      status = true
    }
  } else {
    status = false
  }
  return status
}

/* ----------------------------------- --- ---------------------------------- */

/**
 * Get the loop characteristics of an element.
 *
 * @param {djs.model.Base} element
 *
 * @return {ModdleElement<bpmn:MultiInstanceLoopCharacteristics>} the loop characteristics
 */
function getLoopCharacteristics(element) {
  var bo = getBusinessObject(element)
  return bo.loopCharacteristics
}

/**
 * Get the loop cardinality of the loop characteristics.
 *
 * @param {djs.model.Base} element
 *
 * @return {ModdleElement<bpmn:FormalExpression>} an expression representing the loop cardinality
 */
function getLoopCardinality(element) {
  return getProperty(element, 'loopCardinality')
}

/**
 * Get the loop cardinality value of the loop characteristics.
 *
 * @param {djs.model.Base} element
 *
 * @return {string} the loop cardinality value
 */
function getLoopCardinalityValue(element) {
  var loopCardinality = getLoopCardinality(element)
  return getBody(loopCardinality)
}

/**
 * Get the completion condition of the loop characteristics.
 *
 * @param {djs.model.Base} element
 *
 * @return {ModdleElement<bpmn:FormalExpression>} an expression representing the completion condition
 */
function getCompletionCondition(element) {
  return getProperty(element, 'completionCondition')
}

/**
 * Get the completion condition value of the loop characteristics.
 *
 * @param {djs.model.Base} element
 *
 * @return {string} the completion condition value
 */
function getCompletionConditionValue(element) {
  var completionCondition = getCompletionCondition(element)
  return getBody(completionCondition)
}

/**
 * Get the 'flowable:collection' attribute value of the loop characteristics.
 *
 * @param {djs.model.Base} element
 *
 * @return {string} the 'flowable:collection' value
 */
function getCollection(element) {
  return getProperty(element, 'activiti:collection')
}

/**
 * Get the 'flowable:elementVariable' attribute value of the loop characteristics.
 *
 * @param {djs.model.Base} element
 *
 * @return {string} the 'flowable:elementVariable' value
 */
function getElementVariable(element) {
  return getProperty(element, 'activiti:elementVariable')
}

/**
 * Updates a specific formal expression of the loop characteristics.
 *
 * @param {djs.model.Base} element
 * @param {string} propertyName
 * @param {string} newValue
 * @param {BpmnFactory} bpmnFactory
 */
function updateFormalExpression(element, propertyName, newValue, bpmnFactory) {
  var loopCharacteristics = getLoopCharacteristics(element)

  var expressionProps = {}

  if (!newValue) {
    // remove formal expression
    expressionProps[propertyName] = undefined
    return cmdHelper.updateBusinessObject(
      element,
      loopCharacteristics,
      expressionProps,
    )
  }

  var existingExpression = loopCharacteristics.get(propertyName)

  if (!existingExpression) {
    // add formal expression
    expressionProps[propertyName] = createFormalExpression(
      loopCharacteristics,
      newValue,
      bpmnFactory,
    )
    return cmdHelper.updateBusinessObject(
      element,
      loopCharacteristics,
      expressionProps,
    )
  }

  // edit existing formal expression
  return cmdHelper.updateBusinessObject(element, existingExpression, {
    body: newValue,
  })
}

function ensureMultiInstanceSupported(element) {
  var loopCharacteristics = getLoopCharacteristics(element)
  console.log(is(loopCharacteristics, 'activiti:Collectable'))

  return (
    !!loopCharacteristics && is(loopCharacteristics, 'activiti:Collectable')
  )
}

module.exports = function (group, element, bpmnFactory, translate) {
  if (is(element, 'bpmn:UserTask')) {
    const isMultiInstanceSupported = entryFactory.selectBox({
      id: 'isMultiInstanceSupported',
      label: translate('Multi-party rule'),
      modelProperty: 'loopCharacteristics',
      selectOptions: [
        { name: '无', value: 0 },
        { name: '多人并行', value: 1 },
        // { name: '多人任意', value: 2 }
      ],
      get: function (element, node) {
        // const bo = getBusinessObject(element)
        if (ensureMultiInstanceSupported(element)) {
          if (getLoopCharacteristics(element).get('isSequential') === 'true') {
            return {
              loopCharacteristics: 0,
            }
          } else {
            return { loopCharacteristics: 1 }
          }
        } else {
          return {
            loopCharacteristics: 0,
          }
        }

        // return {
        //   loopCharacteristics: ensureMultiInstanceSupported(element) ?
        //   (getLoopCharacteristics(element).get("isSequential")=="true"?2:1)
        //    : 0
        // };
      },
      set: function (element, values, node) {
        let commands = []
        if (values.loopCharacteristics != '0') {
          // if (!ensureMultiInstanceSupported(element)) {
          const loopCharacteristics = elementHelper.createElement(
            'bpmn:MultiInstanceLoopCharacteristics',
            {
              isSequential:
                values.loopCharacteristics != '1' ? 'true' : 'false',
              'activiti:collection': 'assigneeList',
              'activiti:elementVariable': 'assignee',
            },
            element,
            bpmnFactory,
          )

          commands.push(
            cmdHelper.updateProperties(element, { extensionElements: null }),
          )
          commands.push(
            cmdHelper.updateProperties(element, {
              loopCharacteristics: loopCharacteristics,
            }),
          )
          return commands.concat(createExtensionElement(element, bpmnFactory))
          // return commands
          // }
        } else {
          const loopCharacteristics = elementHelper.createElement(
            'bpmn:MultiInstanceLoopCharacteristics',
            {
              isSequential:
                values.loopCharacteristics != '1' ? 'true' : 'false',
            },
            element,
            bpmnFactory,
          )
          // commands.push(
          //   cmdHelper.updateProperties(element, { loopCharacteristics: '10' }),
          // )
          // commands.push(
          //   cmdHelper.updateProperties(element, { extensionElements: null }),
          // )
          commands.push(
            cmdHelper.updateProperties(element, {
              loopCharacteristics: loopCharacteristics,
            }),
          )
          // commands.push(
          //   cmdHelper.updateProperties(element, { extensionElements: null }),
          // )
          // commands.push(
          //   cmdHelper.updateProperties(element, {
          //     loopCharacteristics: '0',
          //   }),
          // )
          return commands.concat(createExtensionElement(element, bpmnFactory))
        }
      },
    })
    if (!is(element, 'bpmn:UserTask')) {
      return
    }

    function getAttribute(element, prop) {
      let attr = {}
      const bo = getBusinessObject(element)
      var value = bo.get(prop)
      attr[prop] = value
      return attr
    }

    const assigneebox = entryFactory.selectBox({
      id: 'assignee',
      label: translate('acceptor'),
      selectOptions: function (element) {
        return getData()
      },
      modelProperty: 'activiti:assignee',
      get: function (element) {
        var attr = getAttribute(element, 'activiti:assignee')
        return attr
      },

      set: function (element, values) {
        const bo = getBusinessObject(element)
        return cmdHelper.updateBusinessObject(element, bo, values)
      },
      // hidden: function (element, values) {
      //   return !judgeMutiRuleChildPropertyStatus(element)
      // },

      validate: function (element, values, node) {
        var validation = {}
        // 因为已经在提交的时候可以写入默认值了，所以暂时去掉校验
        // let activitiAssignee_isHidden =
        //   ensureMultiInstanceSupported(element) &&
        //   getAttribute(element, 'activiti:formKey')
        // if (
        //   (values['activiti:assignee'] === '${assignee}' ||
        //     values['activiti:assignee'] === '' ||
        //     !$('#flowable-assignee-select').val()) &&
        //   !activitiAssignee_isHidden
        // ) {
        //   validation['activiti:assignee'] = translate('Must provide a value')
        // }
        return validation
      },
    })

    const candidateUsers = entryFactory.selectBox({
      id: 'candidateUsers',
      label: translate('Candidate Users'),
      modelProperty: 'activiti:candidateUsers',
      multiple: 'multiple', // 加上这个方法变成多选下拉框
      selectOptions: function (element) {
        return getData()
      },
      get: function (element) {
        var attr = getAttribute(element, 'activiti:candidateUsers')
        return attr
      },
      set: function (element, values) {
        const bo = getBusinessObject(element)
        const commands = []
        commands.push(
          cmdHelper.updateBusinessObject(
            element,
            bo,
            Object.assign({ 'activiti:assignee': '${assignee}' }, values),
          ),
        )
        return commands
      },
      // hidden: function (element, values) {
      //   return judgeMutiRuleChildPropertyStatus(element)
      //   // return !ensureMultiInstanceSupported(element)
      // },
      description: '多选择方式：使用ctrl+ 左键选中',
    })

    // const instanceNumber = entryFactory.textField({
    //   id: 'multiInstance-loopCardinality',
    //   label: translate('Loop Cardinality'),
    //   modelProperty: 'loopCardinality',
    //   description: '实例数:按选择人数填写',

    //   get: function (element, node) {
    //     return {
    //       loopCardinality: getLoopCardinalityValue(element),
    //     }
    //   },

    //   set: function (element, values) {
    //     return updateFormalExpression(
    //       element,
    //       'loopCardinality',
    //       values.loopCardinality,
    //       bpmnFactory,
    //     )
    //   },
    //   hidden: function (element, values) {
    //     return !ensureMultiInstanceSupported(element)
    //   },
    // })
    var options = [
      { name: '部门', value: 'org' },
      { name: '个人', value: 'user' },
      // { name: '多人任意', value: 2 }
    ]
    if (location.pathname === '/data_change_flow_design') {
      options.push(
        { name: '管理员', value: 'admin' },
        { name: '连接管理员', value: 'connadmin' }
        )
      }
      /** 需要通过传过来的id确定当前需要哪些数据 */
    const approvalTypeId = getAttribute(element, 'id')?.id
    if (approvalTypeId === approvalId) {
      options.splice(0, 2);
      options.push(
        { name: '个人', value: 'user_power' },
        { name: '连接管理员', value: 'connection_power' }
      );
    }

    const approvalType = entryFactory.selectBox({
      id: 'approval-type',
      // 类型
      label: translate('Approval type'),
      modelProperty: 'activiti:formKey',
      selectOptions: options,
      description: approvalTypeId === approvalId ? '此节点为最后一个审批节点，不允许在此节点后增加新的审批节点，否则将无法保存' : '',
      get: function (element) {
        var attr = getAttribute(element, 'activiti:formKey')
        return attr
      },

      set: function (element, values) {
        const bo = getBusinessObject(element)
        const commands = []
        commands.push(
          cmdHelper.updateBusinessObject(
            element,
            bo,
            Object.assign({ 'activiti:formKey': '${assignee}' }, values),
          ),
          cmdHelper.updateBusinessObject(
            element,
            bo,
            Object.assign({ 'activiti:assignee': '${assignee}' }, values),
          ),
        )
        return commands.concat(createExtensionElement(element, bpmnFactory))
      },
      // hidden: function (element, values) {
        // return !ensureMultiInstanceSupported(element)
      // },
    })

    const finishNumber = entryFactory.textField({
      id: 'multiInstance-completionCondition',
      label: translate('Completion Condition'),
      modelProperty: 'completionCondition',
      description: '完成条件： 完成实例数/实例数>=0-1.0',
      //'示例：${nrOfCompletedInstances/nrOfInstances >= 0.50}。nrOfCompletedInstances:完成实例数，nrOfInstances：实例数',

      get: function (element, value) {
        // return {
        let completionConditionValue = getCompletionCondition(element)?.body
        // }
        return {
          completionCondition: completionConditionValue,
        }
      },

      set: function (element, values) {
        return updateFormalExpression(
          element,
          'completionCondition',
          values.completionCondition,
          bpmnFactory,
        )
      },
      // hidden: function (element, values) {
      //   return judgeMutiRuleChildPropertyStatus(element)
      //   // return !ensureMultiInstanceSupported(element)
      // },

      validate: function (element, values) {
        var validation = {}
        if (!values?.completionCondition) {
          validation.completionCondition = translate('Must provide a value')
        }

        return validation
      },
    })
    let aaa = getAttribute(element, 'activiti:formKey')
    group.entries = group.entries.concat(approvalType)
    if (aaa['activiti:formKey'] === 'user') {
      group.entries = group.entries.concat(isMultiInstanceSupported)
      if (getLoopCharacteristicsValue_isSequential_open(element)) {
        group.entries = group.entries.concat(candidateUsers)
        group.entries = group.entries.concat(finishNumber)

      } else {
        if (
          !(
            aaa?.hasOwnProperty('activiti:formKey') &&
            aaa['activiti:formKey'] === 'false'
          )
        ) {
          group.entries = group.entries.concat(assigneebox)
        }
      }
      // group.entries = group.entries.concat(instanceNumber)

      // group.entries = group.entries.concat(multiInstanceLoopCharacteristics(element, bpmnFactory, translate));
    } else if (
      // 如果是落权节点，选择为个人时候，展示选人选项
      aaa['activiti:formKey'] === 'user_power' &&
      approvalTypeId === approvalId &&
      !(aaa?.hasOwnProperty('activiti:formKey') && aaa['activiti:formKey'] === 'false')
    ) {
      group.entries = group.entries.concat(assigneebox)
    }
  }
}
