import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Button, Form, Input, Steps } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { hideModal, showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { getUserOTPKey, refreshUserOTPKey, bindUserOTPKey } from 'src/api'
import * as OriginQRCode from 'src/assets/js/qrcode.js'
import styles from './index.module.scss'
import classNames from 'classnames'


const QRCode = OriginQRCode.default as any
const steps = [
  {
    title: '身份验证',
  },
  {
    title: '安装应用',
  },
  {
    title: '绑定OTP',
  },
  {
    title: '完成',
  }
];

export const ModalOtpBinding: React.FC<{ refresh: () => Promise<void> }> = ({
  refresh
}) => {
  const dispatch = useDispatch()
  const visible = useSelector(
    (state) => state.modal.OtpBindingtModal,
  )
  const { userId } = useSelector((state) => state.login.userInfo)
  const { loginType } = useSelector(
    (state) => state.personalCenter)

  const [current, setCurrent] = useState(1)
  const [form] = Form.useForm()
  const qrRef = useRef() as any
  const qrDom = document.getElementById('qrcode')

  const [checkCodeKey, setCheckCodeKey] = useState('')

  //初次 二维码
  const { run: getInitialCheckCodeKey } = useRequest(getUserOTPKey, {
    manual: true,
    formatResult: ({ optKey }) => optKey,
    onSuccess: (optKey: string) => {
      setCheckCodeKey(optKey)
    },
  })
  // 刷新二维码
  const { run: getRefreshCheckCodeKey } = useRequest(refreshUserOTPKey, {
    manual: true,
    formatResult: ({ optKey }) => optKey,
    onSuccess: (optKey: string) => {
      setCheckCodeKey(optKey)
    },
  })

  // 绑定otpKey
  const {run: bindOtpKey} = useRequest(bindUserOTPKey, {
    manual: true,
    onSuccess(data) {
      setCurrent(3)
    }
  })

  useEffect(() => {
    if (visible && userId && current == 2) {
      getInitialCheckCodeKey(userId)
    }
    if (userId) {
      let arr=userId.split('');
      arr.splice(2,3,"*","*","*");
      let str=arr.join('');
    }
  }, [userId, current, visible])

  const createQR = useCallback(() => {
    if (qrRef.current) {
      qrRef.current?.clear()
    }
    if (qrDom) {
      qrDom.innerHTML = ''
    }

    if (!qrDom) return
    let uri = 'otpauth://totp/'
    uri += encodeURIComponent(`${userId}-otp` || '')
    const code = checkCodeKey?.slice(0, 32)
    uri += '?secret=' + code
    uri += '&algorithm=SHA1'
    uri += '&digits=6'
    uri += '&period=30'

    var qrImg = new QRCode(qrDom, {
      text: uri,
      width: 200,
      height: 200,
      correctLevel: QRCode.CorrectLevel.H,
    })
    qrRef.current = qrImg
  }, [checkCodeKey, qrDom, qrRef, userId])

  useEffect(() => {
    if (checkCodeKey) {
      createQR()
    }
  }, [createQR, checkCodeKey, qrDom])

  useEffect(() => {
    setCurrent(1)
    form.resetFields()
  }, [visible])

  return (
    <UIModal
      title="OTP绑定"
      visible={visible}
      footer={[]}
      onCancel={() => {
        dispatch(hideModal('OtpBindingtModal'))
      }}
    >
      <>
        <Steps current={current} labelPlacement="vertical">
          {steps.map(({ title }, index) => (
            <Steps.Step title={title} key={index} />
          ))}
        </Steps>
        {
          current === 1 && (
            <div className={styles.boxMargin}>
              <div style={{ marginLeft: '20%' }}>
                <p className={styles.otpRed}>绑定安全设备 账号 {userId} 请按照以下步骤完成绑定操作</p>
                <p>请在手机端下载并安装Authy/Google Authenticator（身份验证器）</p>
                <p>iphone: 在AppStore搜索Authy/Google Authenticator（身份验证器）</p>
                <p>Android : 在应用市场搜索Authy/Google Authenticator（身份验证器）</p>
                <h3 className={styles.otpHint}>安装完成后点击下一步进入绑定页面（如已安装，直接进入下一步）</h3>
              </div>
              <div className={styles.buttonRight}>
                <Button className={styles.mr10} 
                 disabled={loginType !== 'cq'}
                 onClick={() => {
                  dispatch(hideModal('OtpBindingtModal'))
                  dispatch(showModal('VerificationModal'))

                }}>
                  上一步
                </Button>
                <Button type="primary" onClick={() => {
                  setCurrent(2)
                }}>
                  下一步
                </Button>
              </div>
            </div>
          )
        }
        {
          current === 2 && (
            <div className={styles.boxMargin}>
              <div >
                <p className={classNames(
                  styles.otpRed,
                  styles.textAlignCenter
                )}>绑定安全设备 账号 {userId} 请按照以下步骤完成绑定操作</p>
                <p className={styles.textAlignCenter}>使用Authy/Google Authenticator（身份验证器）扫描以下二维码，获取6位验证码</p>

                <div
                  id="qrcode"
                  style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', width: 100, height: 100, marginLeft: '44%' }}
                  onClick={() => userId && getRefreshCheckCodeKey(userId)}
                ></div>
                <Form form={form} {...FormLayout} style={{marginLeft: '36%', marginTop: '10px'}}>
                  <Form.Item
                    name='otpPassword'
                    rules={[{ required: true, message: '请输入 OTP 验证' }]}
                  >
                    <Input placeholder="OTP 验证码" />
                  </Form.Item>
                </Form>
              </div>
              <div className={styles.buttonRight}>
              <Button className={styles.mr10}  onClick={() => {
                  setCurrent(1)
                  
                }}>
                  上一步
                </Button>
                <Button type="primary" onClick={() => {
                  form.validateFields().then((values) => {
                    userId && bindOtpKey({userId, otpPassword: values?.otpPassword})
                  })
                }}>
                  下一步
                </Button>
              </div>
            </div>
          )
        }
        {current === 3 && (
          <div className={styles.boxMargin}>
            <div className={styles.otpBindBack}>
              <h3 className={styles.otpMessageTitle}>绑定成功</h3>
              <p className={styles.otpMessageCon}>您已经成功绑定安全设备。</p>
              <p className={styles.otpMessageCon}>请勿随意删除卸载APP，否则会导致账号无法登陆。</p>
              <p className={styles.otpMessageCon}>如需要卸载APP或更换手机，请先解绑OTP。</p>
            </div>
            <div className={styles.buttonRight}>
              <Button type="primary" onClick={() => {
                form.validateFields().then(() => {
                  dispatch(hideModal('OtpBindingtModal'))
                  refresh()
                  // setCurrent(3)
                })
              }}>
                完成
              </Button>
            </div>
          </div>
        )}
      </>
    </UIModal>
  )
}
