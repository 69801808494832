import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Form, message } from 'antd'
import { UIModal } from 'src/components'
import { SysBindUserForm } from '../forms/SysBindUserForm'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { RoleRecordContext } from '../contexts/RoleRecordContext'
import { bindAuditUsersToRole, bindUsersToRole } from 'src/api'
import difference from 'lodash/difference'
import { AUDIT_ROLE } from '../forms/common'

export const ModalSysBindUser = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.ModalSysBindUser)
  const { mode, record, refreshRoles } = useContext(RoleRecordContext)

  const [onOkProps, setOnOkProps] = useState({ disabled: true })
  const [auditRange, setAuditRange] = useState<any[]>([])

  const currentUserIds = record?.userInfos.map(({ userId }) => userId) || []

  const { loading, run: bindUsers } = useRequest(bindUsersToRole, {
    manual: true,
    onSuccess: () => {
      message.success('绑定成功')
      dispatch(hideModal('ModalSysBindUser'))
      refreshRoles && refreshRoles()
    },
  })

  const { run: bindAuditUsers } = useRequest(bindAuditUsersToRole, {
    manual: true,
    onSuccess: () => {
      dispatch(hideModal('ModalSysBindUser'))
      refreshRoles && refreshRoles()
    },
  })

  useEffect(() => {
    if (visible) {
      onFormValueChange(form.getFieldValue('boundUsers'));
    }
  }, [visible])

  const isEqualArray = (arr1: string[], arr2: string[]) => {
    if (Array.isArray(arr1) && Array.isArray(arr2)) {
      return arr1.every(item => arr2.includes(item)) && arr2.length === arr1.length;
    } else if (!arr1 && !arr2) {
      return true;
    }
    return false
  }

  const onFormValueChange = (value: string[]) => {
    if (isEqualArray(currentUserIds,value)) {
      setOnOkProps({ disabled: true })
    } else {
      setOnOkProps({ disabled: false })
    }
  }

  const onOk = async () => {
    if (!record) return
    try {
      const formValues = await form.validateFields()
      const boundUsers = (formValues.boundUsers as string[]) || []
      const addList = difference(boundUsers, currentUserIds)
      const removeList = difference(currentUserIds, boundUsers)

      if (addList.length === 0 && removeList.length === 0) {
        /**
         * 当addlist和removeList的长度为0，也就是添加的人和删除的人长度为0
         * 用户角色为审计员时候，只调用审计员专用接口，此时绑定成功需要给用户反馈‘修改成功’
        */
        if (record?.type === AUDIT_ROLE) {
          bindAuditUsers({ userVoList: auditRange }).then(res => {
            message.success('修改成功');
          })
        }
        dispatch(hideModal('ModalSysBindUser'))
      } else {
        /**
         * 当addlist和removeList的长度不为0，除了调用绑定用户的接口外，
         * 角色为审计员时候，还需要调用审计员专用接口，此时在绑定用户时候已经给了用户反馈，
         * 所以这里不需要‘修改成功’提醒
         */
        bindUsers({
          grantUser: addList,
          removeUser: removeList,
          roleName: record?.roleName,
          roleId: record.roleId,
        }).then(res => {
          // 如果是审计员，走审计员专属接口
          if (record?.type === AUDIT_ROLE) {
            bindAuditUsers({ userVoList: auditRange })
          }
        })
      }
    } catch { }
  }

  const onAuditRangeChange = (range:any[]) => {
    setOnOkProps({ disabled: false })
    setAuditRange(range);
  }

  return (
    <UIModal
      title="绑定用户"
      visible={visible}
      onCancel={() => dispatch(hideModal('ModalSysBindUser'))}
      onOk={onOk}
      okButtonProps={onOkProps}
      confirmLoading={loading}
    >
      {record && (
        <div style={{ marginBottom: 16 }}>
          <strong>角色名：</strong>
          {record.roleName}
        </div>
      )}
      <SysBindUserForm
        form={form}
        mode={mode}
        record={record}
        onFormValueChange={onFormValueChange}
        onAuditRangeChange={onAuditRangeChange}
      />
    </UIModal>
  )
}
