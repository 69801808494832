import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import Watermark from '@pansy/react-watermark'
import { Iconfont } from 'src/components'
import {
  ResultGrid,
  ResultGridMongo,
  TSQLResultGrid,
} from './resultContentGrid'
import { ResultLogs } from './resultLogs'
import { ResultJson } from './resultJson'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import projectConfigService from 'src/projectConfig'
import {
  setVisibleQueryTabs,
  setVisibleNodeDetail,
  setVisibleSdt,
} from '../queryPageSlice'
import { setActiveResultTabKey, removeResultTabPane } from './resultTabsSlice'
import styles from './index.module.scss'
import { getWatermarkValue } from 'src/api'
import { resetLogs } from 'src/store/extraSlice/logsSlice'

const { TabPane } = Tabs

interface ResultTabsProps {
  /** resultTab 所属的 queryPane 的唯一标识 */
  queryTabKey: string
}

export const ResultTabs: React.FC<ResultTabsProps> = (props) => {
  const dispatch = useDispatch()
  const { queryTabKey } = props
  const {
    executeKeyListMap,
    explainKeyListMap,
    resultTabMap,
    activeResultKeyMap,
  } = useSelector((state) => state.resultTabs)
  const { visibleQueryTabs } = useSelector((state) => state.queryPage)
  const theme = useSelector((state) => state.appearance.theme)

  const executeKeyList = executeKeyListMap[queryTabKey] || []
  const explainKeyList = explainKeyListMap[queryTabKey] || []
  const executeTabs = executeKeyList.map((key) => resultTabMap[key])
  const explainTabs = explainKeyList.map((key) => resultTabMap[key])

  const { tabInfoMap } = useSelector((state) => state.queryTabs)
  const paneType = tabInfoMap[queryTabKey].paneType

  useEffect(() => {
    //设置默认结果集scale
    if (executeTabs && executeTabs.length) {
      executeTabs.map((item) => {
        const scaleVal = item?.info?.scale == null ? 100 : item?.info?.scale;
 
        const keyEleWrapper = document.getElementById(`${item.key}gridWrapper`)?.getElementsByClassName('ag-root-wrapper')
        const rootEle = keyEleWrapper && keyEleWrapper?.[0] as HTMLElement
        
        if (keyEleWrapper && rootEle) {
          
            rootEle.style.cssText = `zoom:${scaleVal}%`
        }

      })
    }

  }, [queryTabKey, executeTabs])

  const onEditResultPane = (targetKey: React.MouseEvent | string, action: 'add' | 'remove') => {
    if (action !== 'remove')  return 
    //@ts-ignore
    dispatch(removeResultTabPane({targetResultKey:targetKey, queryTabKey: queryTabKey}))
  }

  const extraOperations = (
    <div className={styles.extraOperations}>
      {visibleQueryTabs ? (
        <Iconfont
          className={styles.extraOperationIcon}
          type="icon-fullscreen"
          onClick={() => {
            dispatch(setVisibleQueryTabs(false))
            dispatch(setVisibleNodeDetail(false))
            dispatch(setVisibleSdt(false))
          }}
        />
      ) : (
        <Iconfont
          className={styles.extraOperationIcon}
          type="icon-fullscreen-exit"
          onClick={() => {
            dispatch(setVisibleSdt(true))
            dispatch(setVisibleQueryTabs(true))
          }}
        />
      )}
    </div>
  )

  // watermark
  const { watermarkSetting, userName } = useSelector(
    (state) => state.login.userInfo,
  )
  const {data: vatermarkvalue} = useRequest(getWatermarkValue)

  const watermark = watermarkSetting && (
    <Watermark
      {...projectConfigService.waterMarkConfig}
      text={vatermarkvalue && vatermarkvalue?.length ? vatermarkvalue.join(' ') : ''}
      zIndex={99}
      opacity={theme === 'dark' ? 0.4 : 0.2}
    />
  )

  return (
    <Tabs
      defaultActiveKey={`log/${queryTabKey}`}
      activeKey={activeResultKeyMap[queryTabKey]}
      onChange={(activeKey) => {
        dispatch(
          setActiveResultTabKey({ queryTabKey, resultTabKey: activeKey }),
        )
      }}
      type="editable-card"
      hideAdd
      //@ts-ignore
      onEdit={onEditResultPane}
      className={styles.resultTab}
      tabBarGutter={0}
      tabBarStyle={{ marginBottom: 0 }}
      tabBarExtraContent={extraOperations}
      animated={{ inkBar: false, tabPane: false }}
    >
      <TabPane
       closable={false}
        className={styles.resultPaneWrapper}
        key={`log/${queryTabKey}`}
        tab={`执行日志`}  
      >
        <div className={styles.clearLog}>
          <div className='flexAlignCenterJustifyEnd'>
            <span 
              className='options' 
              onClick={() => {
                console.log('清除日志')
                dispatch(resetLogs())
              }}
            >
              清除日志
            </span>
          </div>
        </div>
        <ResultLogs queryKey={queryTabKey} />
      </TabPane>
      {executeTabs.map(({ key, info }, index) => {
        const { dataSourceType, executeError } = info

        if (dataSourceType === 'MongoDB') {
          return [
            <TabPane
             closable={false}
              className={styles.resultPaneWrapper}
              key={key}
              tab={`JSON 视图(${index + 1})`}
            >
              {watermark}
              <ResultJson result={info} />
            </TabPane>,

            <TabPane
              closable={false}
              className={styles.resultPaneWrapper}
              key={`${key}-GRID`}
              tab={`表格视图(${index + 1})`}
            >
              {watermark}
              {!executeError ? (
                <ResultGridMongo result={info} queryKey={queryTabKey}  tabResultKey={key} />
              ) : (
                <div className={styles.resultErrorMessage}>
                  <pre>{executeError.message}</pre>
                </div>
              )}
            </TabPane>,
          ];
        }

        if(executeError?.message){
          return null
        }

        return (
          <TabPane
            className={styles.resultPaneWrapper}
            key={key}
            closable={true}
            tab={`结果（${index + 1}）`}
          >
            {watermark}
            {!executeError ? (
              paneType !== 'tSql' ? (
                <ResultGrid tabResultKey={key} result={info} queryKey={queryTabKey} />
              ) : (
                <TSQLResultGrid tabResultKey={key} result={info} queryKey={queryTabKey} />
              )
            ) : (
              <div className={styles.resultErrorMessage}>
                <pre>{executeError.message}</pre>
              </div>
            )}
          </TabPane>
        )
      })}

      {explainTabs.map(({ key, info }, index) => {
        const { executeError } = info
        return (
          <TabPane
            className={styles.resultPaneWrapper}
            key={key}
            closable={true}
            tab={`解释（${index + 1}）`}
          >
            {watermark}
            {!executeError ? (
              <ResultGrid result={info} queryKey={queryTabKey} type="explain" />
            ) : (
              <div className={styles.resultErrorMessage}>
                <pre>{executeError.message}</pre>
              </div>
            )}
          </TabPane>
        )
      })}
    </Tabs>
  )
}
