import React from 'react'
import { Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

export const UIModal: React.FC<ModalProps> = (props) => {
  const { bodyStyle, style, ...restProps } = props
  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      width={800}
      bodyStyle={{
        ...{
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 144px)',
          minHeight: 100
        },
        ...bodyStyle,
      }}
      style={{
        ...style,
      }}
      {...restProps}
    ></Modal>
  )
}
