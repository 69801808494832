import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Checkbox } from 'antd';
import { DrawerWrapper } from 'src/components';
import {
  OPERATE_COLUMN_FIELDS,
  DEFAULT_OPERATE_LOG_COLUMNS,
} from 'src/constants';

interface IProps {
  defaultColumnFields: string[]
  visible: boolean
  onCancel: () => void
  onOk: (fields: string[]) => void
}

export const CustomColumnFieldPanel = ({
  defaultColumnFields,
  visible = false,
  onCancel,
  onOk,
}: IProps) => {

  const [checkedColumnFields, setCheckedColumnFields] = useState<string[]>([])
  useEffect(() => {
    setCheckedColumnFields(defaultColumnFields);
  },[defaultColumnFields])

  return (
    <DrawerWrapper
      title="自定义列表项"
      width={400}
      visible={visible}
      onCancel={() => {
        onCancel();
        setCheckedColumnFields(defaultColumnFields);
      }}
      onOk={() => {
        onOk(checkedColumnFields)
      }}
    >
      <Checkbox.Group
        value={checkedColumnFields}
        //@ts-ignore
        onChange={(checkedValue: string[]) =>
          checkedValue && setCheckedColumnFields(checkedValue)
        }
      >
        {Object.keys(OPERATE_COLUMN_FIELDS).map((key) => (
          <>
            <Checkbox
              key={key}
              disabled={DEFAULT_OPERATE_LOG_COLUMNS.includes(key)}
              value={key}
            >
              {OPERATE_COLUMN_FIELDS[key]}
            </Checkbox>
            <div style={{ padding: '8px 0' }} />
          </>
        ))}
      </Checkbox.Group>
    </DrawerWrapper>
  )
}
