import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { chunk } from 'lodash'
import {
  executeSqlStatement,
  explainSqlStatement,
  QueryResult,
  StatementExecuteParams,
} from 'src/api'
import { AppThunk, RootState } from 'src/store'
import { addLog } from 'src/store/extraSlice/logsSlice'
import type { QueryBase, QueryLogItem } from 'src/types'
import { sleep } from 'src/util'

interface ResultTabsState {
  /** queryTabKey => resultKey[] */
  executeKeyListMap: Record<string, string[]>
  explainKeyListMap: Record<string, string[]>
  /** resultTabKey => ResultTab */
  resultTabMap: Record<string, ResultTab>
  /** queryTabKey => activeResultTabKey */
  activeResultKeyMap: Record<string, string>
  /** queryTabKey => 查询池当前 segment 执行状态信息 */
  segmentMsg: Record<string, SegmentMsg[] | undefined>
  /** 结果面板的高度 */
  height: number

  segmentPositionMsg: SegmentPositionMsg[]
  resultCount: number
  errorJump: boolean
  // errorPosition: any  // 接口获取的错误信息
  lastErrorPosition: { [key: string]: any }
  isRun: boolean // 是否执行
}

const initialState: ResultTabsState = {
  executeKeyListMap: {},
  explainKeyListMap: {},
  resultTabMap: {},
  activeResultKeyMap: {},
  segmentMsg: {},
  segmentPositionMsg: [],
  resultCount: 0,
  height: 0,
  errorJump: false,
  lastErrorPosition: {},
  isRun: false,
}

export const fetchExplain = createAsyncThunk<
  { queryKey: string; explains: QueryResult[] },
  StatementExecuteParams,
  { state: RootState }
>('resultTabs/fetchExplain', async (params, { dispatch, getState }) => {
  const queryKey = getState().queryTabs.activeTabKey
  const keyList = getState().resultTabs.explainKeyListMap[queryKey] || []
  dispatch(
    setActiveResultTabKey({
      queryTabKey: queryKey,
      resultTabKey: `log/${queryKey}`,
    }),
  )
  dispatch(resetExplainKeyList(queryKey))
  dispatch(deleteResultTabsByKeys(keyList))

  const explains = await explainSqlStatement(params)
  return {
    queryKey,
    explains,
  }
})

export const resultTabsSlice = createSlice({
  name: 'resultTabs',
  initialState,
  reducers: {
    setExecuteKeyList(
      state,
      action: PayloadAction<{ key: string; list: string[] }>,
    ) {
      const { executeKeyListMap } = state
      const { key, list } = action.payload
      executeKeyListMap[key] = list
    },
    resetExplainKeyList(state, action: PayloadAction<string>) {
      const queryKey = action.payload
      state.explainKeyListMap[queryKey] = []
    },
    updateResultTabs(state, action: PayloadAction<ResultTab[]>) {
      const { resultTabMap } = state
      action.payload.forEach(({ key, info }) => {
        resultTabMap[key] = { key, info }
      })
    },
    setPageTotalByQueryKey(state, action: PayloadAction<{ queryKey: string, pageTotal: number }>) {
      const { queryKey,  pageTotal } = action.payload;
      const resultKey = state.activeResultKeyMap[queryKey]
      if (state.resultTabMap[resultKey]) {
        const { key, info } = state.resultTabMap[resultKey]
        state.resultTabMap[resultKey] = {
          key,
          info: {
            ...info,
            pageTotal,
          }
        }
      }
    },
    saveResultCount(state, action: PayloadAction<number>) {
      state.resultCount = action.payload
    },

    // 删除指定 keyList 里所有 key 对应的 resultTabMap value
    deleteResultTabsByKeys(state, action: PayloadAction<string[]>) {
      const { resultTabMap } = state
      action.payload.forEach((key) => {
        delete resultTabMap[key]
      })
    },

    // 删除指定 queryTabKey 对应的 executeKeyList
    deleteExecuteKeyList(state, action: PayloadAction<string>) {
      const { executeKeyListMap } = state
      delete executeKeyListMap[action.payload]
    },

    setActiveResultTabKey(
      state,
      action: PayloadAction<{ queryTabKey: string; resultTabKey: string }>,
    ) {
      const { queryTabKey, resultTabKey } = action.payload
      state.activeResultKeyMap[queryTabKey] = resultTabKey
    },

    // 设置指定 queryTab 下的分块执行状态信息
    addSegmentMsg(state, action: PayloadAction<SegmentMsg>) {
      const segmentMsg = action.payload
      const { tabKey } = segmentMsg
      const  msgList = state.segmentMsg[tabKey]
      if (!msgList) {
        state.segmentMsg[tabKey] = [segmentMsg]
        return
      }
      if (
        !msgList.find(
          ({ segmentIndex }) => segmentIndex === segmentMsg.segmentIndex,
        )
      ) {
        msgList.push(segmentMsg)
      }
    },

    addSegmentPositionMsg(state, action: PayloadAction<SegmentPositionMsg[]>) {
      state.segmentPositionMsg = action.payload;
    },
    setErrorJump(state, action: PayloadAction<boolean>) {
      state.errorJump = action.payload;
    },
    setLastErrorPosition(state, action: PayloadAction<any>) {
      state.lastErrorPosition = action.payload;
    },
    setIsRun(state, action: PayloadAction<any>) {
      state.isRun = action.payload;
    },
    resetSegmentMsg(state, action: PayloadAction<string | undefined>) {
      const tabKey = action.payload
      if (!tabKey) {
        state.segmentMsg = {}
        return
      }
      state.segmentMsg[tabKey] = []
    },
    //删除结果集 | 执行计划解释
    removeResultTabPane(state, action: PayloadAction<{targetResultKey: string; queryTabKey: string}>) {
      const {resultTabMap, executeKeyListMap, explainKeyListMap} = state;

      const {targetResultKey, queryTabKey } = action.payload;
    
      let targetQueryResultKeys = executeKeyListMap[queryTabKey] || [];
      let targetExplainKeys = explainKeyListMap[queryTabKey] || [];
      //result key && explain key不存在
      if (!targetQueryResultKeys.includes(targetResultKey) && !targetExplainKeys.includes(targetResultKey)) return
      
      // 删除tab
      delete resultTabMap[targetResultKey];

      //结果集
      if (targetQueryResultKeys.includes(targetResultKey)) {
        targetQueryResultKeys = targetQueryResultKeys.filter(key => key !== targetResultKey)
        state.executeKeyListMap = {...executeKeyListMap, [queryTabKey]: targetQueryResultKeys}

      }else {
        //执行计划
        targetExplainKeys = targetExplainKeys.filter(key => key !== targetResultKey)
        state.explainKeyListMap = {...explainKeyListMap, [queryTabKey]: targetExplainKeys}
       
      }
        //设置active tab
        state.activeResultKeyMap[queryTabKey] = targetExplainKeys[0] || `log/${queryTabKey}`;
    },
    // 设置结果面板高度
    setResultPanelHeight(state, action: PayloadAction<number>) {
      state.height = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExplain.fulfilled, (state, action) => {
      const { queryKey, explains } = action.payload
      const resultTabs = explains.map((info, index) => ({
        key: `explain/${queryKey}-${index}`,
        info,
      }))
      const list = resultTabs.map(({ key }) => key)
      // 更新 resultTabMap
      resultTabs.forEach(({ key, info }) => {
        state.resultTabMap[key] = { key, info }
      })
      // 设置 explain keyList
      state.explainKeyListMap[queryKey] = list
      // 设置 activeResultTab 为第一个解释集
      state.activeResultKeyMap[queryKey] = list[0] || `log/${queryKey}`
    })
  },
})

export const {
  setExecuteKeyList,
  resetExplainKeyList,
  updateResultTabs,
  setPageTotalByQueryKey,
  saveResultCount,
  deleteResultTabsByKeys,
  deleteExecuteKeyList,
  setActiveResultTabKey,
  addSegmentMsg,
  addSegmentPositionMsg,
  resetSegmentMsg,
  removeResultTabPane,
  setResultPanelHeight,
  setErrorJump,
  setLastErrorPosition,
  setIsRun,
} = resultTabsSlice.actions

// thunk actions
/**
 * @description 更新指定 key 对应的单个 resultTab
 * @param key resultTab 的唯一标识
 * @param params 执行语句接口 PUT 请求的 body 参数
 */
export const updateSingleResultTabByKey =
  (key: string, params: StatementExecuteParams): AppThunk =>
  (dispatch) => {
    // 分批次渲染logs
    const queueLogs = async (logs: QueryLogItem[][]) => {
      for (let i =0; i< logs.length; i++) {
        dispatch(addLog(logs[i]))
        await sleep(50)
      }
    }
    executeSqlStatement(params)
      .then((data) => {
        let logs: QueryLogItem[] = data.executionInfos.map((item) => {
          return item.executeLogInfo.message
        })
        const logsChunks = chunk(logs, 20)
        queueLogs(logsChunks)
        dispatch(updateResultTabs([{ key, info: data?.executionInfos[0]?.response }]))
      })
      .catch(() => {})
  }

/**
 * @description 执行语句, 获取该查询面板下的结果集列表
 * @param key 该查询面板的唯一标识 queryTabKey
 * @param params 执行语句接口 PUT 请求的 body 参数
 */
export const queryExecuteResults =
  (key: string, params: StatementExecuteParams): AppThunk<Promise<void>> =>
  (dispatch, getState) => {
    // 分批次渲染logs
    const queueLogs = async (logs: QueryLogItem[][]) => {
      for (let i =0; i< logs.length; i++) {
        dispatch(addLog(logs[i]))
        await sleep(50)
      }
    }
    dispatch(resetResultList(key))
    return executeSqlStatement(params)
      .then((data) => {
        const { executionInfos, segmentExecutionLog } = data
        const executeSuccess = segmentExecutionLog?.success
        const resultCount = executionInfos[0]?.response?.resultData?.length;
        dispatch(saveResultCount(resultCount))
        let logs: QueryLogItem[] = []
        const resultTabs = executionInfos.map((info, index) => {
          logs.push(info.executeLogInfo.message)
          return ({
            key: `execute/${key}-${index}`,
            info: info.response,
          })
        })
        const logsChunks = chunk(logs, 20)
        queueLogs(logsChunks)
        const list = resultTabs.map(({ key }) => key)
        // 先更新 resultTabs, 再设置 keyList
        dispatch(updateResultTabs(resultTabs))
        dispatch(setExecuteKeyList({ key, list }))
        dispatch(
          setActiveResultTabKey({
            queryTabKey: key,
            resultTabKey: (executeSuccess && list[0]) ? list[0] : `log/${key}` ,
          }),
        )
      })
      .catch(() => {})
  }

export const resetResultList =
  (key: string): AppThunk =>
  (dispatch, getState) => {
    const keyList = getState().resultTabs.executeKeyListMap[key] || []
    // 先清空 keyList, 再删除对应的 resultTabs
    dispatch(
      setActiveResultTabKey({
        queryTabKey: key,
        resultTabKey: `execute/${key}`,
      }),
    )
    dispatch(setExecuteKeyList({ key, list: [] }))
    dispatch(deleteResultTabsByKeys(keyList))
  }

export const resultTabsReducer = resultTabsSlice.reducer

export const activeResultSelector = (state: RootState) => {
  const queryKey = state.queryTabs.activeTabKey
  const resultKey = state.resultTabs.activeResultKeyMap[queryKey]
  const result = state.resultTabs.resultTabMap[resultKey]
  return result
}

export const paneInfoMapSelector = (state: RootState) => {
  return state.queryTabs?.paneInfoMap?.[state.queryTabs?.activeTabKey]
}

//types
export interface ResultTab {
  /** resultTabKey */
  key: string
  info: QueryResult
}

export interface SegmentMsg extends QueryBase {
  tabKey: string
  success: boolean
  /** 分块起始 index */
  segmentIndex: number
  /** 终止语句在分块中的 index */
  statementIndex: number
}

export interface SegmentPositionMsg{
  tabKey: string
  success: boolean
  messageId: string
  /** 错误语句位置 {} */
  position: {
    startLine: number, startCol: number, stopLine: number, stopCol: number
  }
}
