import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Modal, Popconfirm, Tabs, Tooltip, Typography, message } from 'antd'
import { editor } from 'monaco-editor'
import { Iconfont, LazyWrapper } from 'src/components'
import {
  setActiveTabKey,
  removeQueryPane,
  addPane,
  resetQueryTabs,
} from './queryTabsSlice'
import { setVisibleSdt, setVisibleNodeDetail } from '../queryPageSlice'
import { ResultTabs } from '../resultTabs/ResultTabs'
import {
  CreateTablePane,
  CreateNewTablePane,
  MonacoPane,
  GridPane,
  TerminalPane,
  CreateViewPane,
  DesignNewTablePane,
  DesignTablePane,
  ViewTableStructurePane,
  CompileInvalidPane,
} from './index'
import classNames from 'classnames'
import styles from './index.module.scss'
import { useEditorInstance } from 'src/components/BaseEditor/useEditorInstance'
import { getExecutedSql } from 'src/api'
import { ErrorBoundary } from 'src/components'
import {setDataSourceMetaDispatch} from "../../../store/extraSlice/dataSourceMetaSlice";

const { Text } = Typography
//新改造的数据源 
const ModifiedDataBaseTypes = ['MySQL', 'MariaDB', 'Oracle', 'PostgreSQL', 'SQLServer', 'DamengDB', 'OceanBase', 'PolarDB','StarRocks']
//部分数据源只包含查看表
const VIEW_ONLY_TABLE_STRUCTURE_TYPES = ['GaussDB','HighGo','KingBasePG','OpenGauss','KingBaseOracle','OracleCDB','GaussDB_DWS','GBase','OceanBaseMySQL','Vertica']

export const QueryTabs = () => {
  const dispatch = useDispatch()
  const { tabKeyList, tabInfoMap, activeTabKey, paneInfoMap } = useSelector(
    (state) => state.queryTabs,
  )
  const { visibleSdt, visibleQueryTabs } = useSelector(
    (state) => state.queryPage,
  )
  const { theme, editorPromptOnClose } = useSelector(
    (state) => state.appearance,
  )
  // 获取点击中的数据库相关信息
  const { selectedNode } = useSelector(
      (state) => state.sdt,
  )

  const dataSourceMeta = useSelector(
      (state) => state.dataSourceMeta
  )
  const tabs = tabKeyList.map((key) => tabInfoMap[key])
  const editorTabs = tabs.filter(({ paneType }) =>
    ['monaco', 'plSql', 'tSql'].includes(paneType),
  )
  const activePaneType = tabInfoMap[activeTabKey]?.paneType
  const isMonaco = activePaneType === 'monaco'
  const isPlSql = activePaneType === 'plSql'
  const isTsql = activePaneType === 'tSql'

  const [editorInstance] = useEditorInstance()
  // model cache map ref
  const modelCacheRef = useRef<Map<string, editor.ITextModel>>(new Map())

  useEffect(() => {
    return () => {
      if (!modelCacheRef.current) return
      modelCacheRef.current?.forEach((model) => {
        model.dispose()
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modelCacheRef.current?.clear()
    }
  }, [])

  useEffect(()=>{
    // 发请求
    const type = paneInfoMap[activeTabKey]?.connectionType;
    if (!type)  return;
    dispatch(setDataSourceMetaDispatch(type));

  },[activeTabKey])

  /* 关闭 tab 页之前，检查是否有未提交事务 */
  const { run: getIsInTransaction } = useRequest(getExecutedSql, {
    manual: true,
    // 这里需要加 fetchKey，否则新请求会让旧请求持续 pending
    fetchKey: ({ tabKey }) => tabKey,
    formatResult: (data) => data && data[0],
  })

  useEffect(() => {
    if (!tabs.length) {
      dispatch(addPane({}))
    }
  }, [dispatch, tabs])

  /* 获取 pane 是否存在事务及相关信息 */
  const getInTransactionPaneInfo = async (targetKey: string) => {
    /* monaco 类型 pane，检查是否存在事务 */
    const targetTabInfo = tabInfoMap[targetKey]
    const targetPaneInfo = paneInfoMap[targetKey]
    const { key, connectionId, databaseName } = targetPaneInfo
    if (targetTabInfo.paneType === 'monaco' && connectionId) {
      const isInTransaction = await getIsInTransaction({
        tabKey: key,
        connectionId,
        databaseName,
      })
      if (isInTransaction) {
        return {
          isInTransaction: true,
          key,
          tabName: targetTabInfo.tabName,
        }
      }
    }
    return {
      isInTransaction: false,
      key,
      tabName: targetTabInfo.tabName,
    }
  }

  const extraOperations = (
    <div className={styles.extraOperations}>
      {visibleSdt ? (
        <Iconfont
          className={styles.extraOperationIcon}
          type="icon-fullscreen"
          onClick={() => {
            dispatch(setVisibleSdt(false))
            dispatch(setVisibleNodeDetail(false))
          }}
        />
      ) : (
        <Iconfont
          className={styles.extraOperationIcon}
          type="icon-fullscreen-exit"
          onClick={() => {
            dispatch(setVisibleSdt(true))
          }}
        />
      )}
      <Tooltip title="关闭所有数据查询工作区窗口" placement="left">
        <Popconfirm
          title="确定关闭所有数据查询工作区窗口?"
          onConfirm={async () => {
            try {
              const allPaneInfoByInTransaction = await Promise.all(
                tabKeyList.map(getInTransactionPaneInfo),
              )
              const messageInfo = allPaneInfoByInTransaction?.reduce(
                (total, it) => {
                  if (it.isInTransaction) {
                    total = `${total}${total && '、'}${it.tabName}`
                  }
                  return total
                },
                '',
              )
              if (messageInfo) {
                message.error(
                  `${messageInfo} 有尚未提交的事务，请提交或取消后重试`,
                )
                return
              }

              dispatch(resetQueryTabs())
              modelCacheRef.current.forEach((model) => model.dispose())
              modelCacheRef.current.clear()
            } catch (error) {
              Modal.confirm({
                content: `确认强制关闭？`,
                onOk: () => {
                  dispatch(resetQueryTabs())
                  modelCacheRef.current.forEach((model) => model.dispose())
                  modelCacheRef.current.clear()
                },
              })
            }
          }}
          placement="bottomRight"
        >
          <Iconfont
            type="icon-closeAll"
            className={styles.extraOperationIcon}
          />
        </Popconfirm>
      </Tooltip>
    </div>
  )

  return (
    <section className={styles.queryTabsWrapper}>
      <div className={styles.queryTabs}>
        <Tabs
          type="editable-card"
          activeKey={activeTabKey}
          onChange={(activeKey) => {
            dispatch(setActiveTabKey(activeKey))
          }}
          onEdit={async (targetKey, action) => {
            if (action !== 'remove') return
            if (typeof targetKey !== 'string') return
            const removeTab = () => {
              dispatch(removeQueryPane(targetKey))
              // 注销 model, 销毁 cache
              modelCacheRef.current.get(targetKey)?.dispose()
              modelCacheRef.current.delete(targetKey)
            }

            /* 检查是否进行中的事务 */
            try {
              const { isInTransaction, tabName } =
                await getInTransactionPaneInfo(targetKey)
              if (isInTransaction) {
                message.error(`${tabName} 有尚未提交的事务，请提交或取消后重试`)
                return
              }
            } catch (error) {
              Modal.confirm({
                title: '确定强制关闭？',
                onOk: () => removeTab(),
              })
              return
            }

            /* 检查是否是 设计表 */
            if (tabInfoMap[activeTabKey].paneType === 'designTable') {
              Modal.confirm({
                title: '是否确认关闭？',
                onOk: () => removeTab(),
              })
              return
            }

            if (!editorPromptOnClose) return removeTab()
            const getHasContent = () =>
              Boolean(editorInstance?.getModel()?.getValue().trim())
            if (!getHasContent()) return removeTab()
            Modal.confirm({
              title: '未保存的更改将会丢失，确定关闭？',
              onOk: () => removeTab(),
              okText: '确认',
              cancelText: '取消'
            })
          }}
          hideAdd
          tabBarExtraContent={extraOperations}
          tabBarStyle={{ marginBottom: 0 }}
          tabBarGutter={0}
          style={{ minHeight: 40 }}
          className={classNames(!visibleQueryTabs && styles.hide)}
        >
          {tabs.map(({ key, tabName, paneType }) => {
            const getTab = () => {
              if (paneInfoMap[key].serverFile)
                return (
                  <>
                    <Iconfont
                      type="icon-cloud-file"
                      style={{ color: '#5794ff' }}
                    ></Iconfont>
                    <span>{tabName}</span>
                  </>
                )
              switch (paneType) {
                case 'terminal':
                  return (
                    <>
                      <Iconfont type="icon-terminal"></Iconfont>
                      <span>{tabName}</span>
                    </>
                  )
                case 'addTable':
                  return (
                    <>
                      <Iconfont
                        type="icon-plus-square"
                        style={{ color: '#ffbc57' }}
                      ></Iconfont>
                      <span>添加表</span>
                    </>
                  )
                case 'designTable':
                  return (
                    <>
                      <Iconfont
                        type="icon-design-table"
                        style={{ color: '#ffbc57' }}
                      ></Iconfont>
                      <span>设计表</span>
                    </>
                  )
                case 'viewTableStructure':
                  return (
                    <>
                      <Iconfont
                        type="icon-table-structure"
                        style={{ color: '#05d174' }}
                      ></Iconfont>
                      <span>{tabName}</span>
                    </>
                  )
                case 'addView':
                  return (
                    <>
                      <Iconfont
                        type="icon-plus-square"
                        style={{ color: '#ffbc57' }}
                      ></Iconfont>
                      <span>添加视图</span>
                    </>
                  )
                case 'monaco':
                  return (
                    <>
                      <Iconfont
                        type="icon-sql"
                        style={{ color: '#0096d8' }}
                      ></Iconfont>
                      <span>{tabName}</span>
                    </>
                  )
                case 'plSql': {
                  const currentConnectionType = paneInfoMap[key].connectionType;
                  const showImpalaIconConnectionTyps = ['MySQL','StarRocks'];
                  if (currentConnectionType && showImpalaIconConnectionTyps.includes(currentConnectionType)) {
                    return (
                      <>
                        {/* <Iconfont
                          type="icon-connection-Impala"
                          style={{ color: '#f40511' }}
                        ></Iconfont> */}
                        <Iconfont
                          type="icon-sql"
                          style={{ color: '#0096d8' }}
                        ></Iconfont>
                        <span>{tabName}</span>
                      </>
                    )
                  } else {
                    return (
                      <>
                        {/* <Iconfont
                          type="icon-plsql"
                          style={{ color: '#f40511' }}
                        ></Iconfont> */}
                        <Iconfont
                          type="icon-sql"
                          style={{ color: '#0096d8' }}
                        ></Iconfont>
                        <span>{tabName}</span>
                      </>
                    )
                  }
                }
                case 'tSql':
                  return (
                    <>
                      {/* <Iconfont
                        type="icon-T1"
                        style={{ color: '#f40511' }}
                      ></Iconfont> */}
                      <Iconfont
                        type="icon-sql"
                        style={{ color: '#0096d8' }}
                      ></Iconfont>
                      <span>{tabName}</span>
                    </>
                  )
                case 'grid':
                  return (
                    <>
                      <Iconfont type="icon-redisKey"></Iconfont>
                      <span>{tabName}</span>
                    </>
                  )
                case 'compileInvalidObjects':
                  return (
                    <>
                      <Iconfont type="icon-terminal"></Iconfont>
                      <span>编译无效对象</span>
                    </>
                  )
                default:
                  return tabName
              }
            }
            const tab = getTab()
            return (
                <Tabs.TabPane
                  key={key}
                  tab={
                    <Text
                      className={classNames(
                        styles.queryTabTitle,
                        key === activeTabKey && styles.queryTabTitleActive,
                      )}
                      ellipsis
                    >
                      <ErrorBoundary>
                        {tab}
                      </ErrorBoundary>
                    </Text>
                  }
                ></Tabs.TabPane>
            )
          })}
        </Tabs>
        {tabs.map(({ key, paneType }) => {
          const {
            connectionType = 'MySQL',
            connectionId = 0,
            databaseName = '',
            nodePath,
            nodeName,
            nodeType,
            nodePathWithType
          } = paneInfoMap[key]
          const databaseInfo = {
            connectionType,
            connectionId,
            databaseName,
            nodePath,
            nodeName,
            nodeType,
            nodePathWithType
          }
          const getPane = () => {
            switch (paneType) {
              case 'terminal':
                return <TerminalPane connectionId={connectionId}></TerminalPane>
              case 'addTable':
                //改版
                if (ModifiedDataBaseTypes.includes(paneInfoMap[key]?.connectionType || '')) {
                  return (
                    <CreateNewTablePane
                    databaseInfo={databaseInfo}
                    queryTabKey={key}
                  />
                  )
                }
                return (
                  <CreateTablePane
                    databaseInfo={databaseInfo}
                    queryTabKey={key}
                  />
                )
              case 'viewTableStructure':
                // 改版
                const allViewTableStructureTypes = ModifiedDataBaseTypes.concat(VIEW_ONLY_TABLE_STRUCTURE_TYPES);

                if(allViewTableStructureTypes.includes(paneInfoMap[key]?.connectionType || '')){
                  return <DesignNewTablePane
                      databaseInfo={databaseInfo}
                      queryTabKey={key}
                      modeType='viewTableStructure'
                  />
                }
                return (
                  <ViewTableStructurePane
                    databaseInfo={databaseInfo}
                    queryTabKey={key}
                  ></ViewTableStructurePane>
                )
              case 'designTable':
                // 改版
                if (ModifiedDataBaseTypes.includes(paneInfoMap[key]?.connectionType || '')) {
                  return <DesignNewTablePane
                      databaseInfo={databaseInfo}
                      queryTabKey={key}
                      modeType='designTable'
                  />
                }
                return (
                  <DesignTablePane
                    databaseInfo={databaseInfo}
                    queryTabKey={key}
                  />
                )
              case 'addView':
                return (
                  <CreateViewPane
                    databaseInfo={databaseInfo}
                    queryTabKey={key}
                  />
                )
              case 'grid':
                return <GridPane databaseInfo={databaseInfo} />
              case 'compileInvalidObjects':
                return <CompileInvalidPane databaseInfo={databaseInfo} />
              default:
                return null
            }
          }
 
          return (
            <LazyWrapper
              key={key}
              active={key === activeTabKey}
              {...(!['monaco', 'plSql', 'tSql'].includes(paneType) && {
                ...{ style: { flex: 1 } },
              })}
              className={styles.queryPaneWrapper}
            >
              <ErrorBoundary>
                {getPane()}
              </ErrorBoundary>
            </LazyWrapper>
          )
        })}
        <LazyWrapper active={isMonaco || isPlSql || isTsql} style={{ flex: 1 }}>
          <div className={styles.monacoPaneWrapper}>
            <div className={classNames(!visibleQueryTabs && styles.hide)}>
              <ErrorBoundary>
                <MonacoPane modelCache={modelCacheRef.current} theme={theme} />
              </ErrorBoundary>
            </div>
            <div className={styles.resultWrapper}>
              {editorTabs.map(({ key }) => (
                <LazyWrapper
                  className={styles.resultContent}
                  key={key}
                  active={key === activeTabKey}
                >
                  <ErrorBoundary>
                    <ResultTabs queryTabKey={key} />
                  </ErrorBoundary>
                </LazyWrapper>
              ))}
            </div>
          </div>
        </LazyWrapper>
      </div>
    </section>
  )
}
