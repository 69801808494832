import React from 'react';
import classNames from 'classnames';
import { Layout, Breadcrumb } from 'antd';
import { CustomColumnTable } from './CustomColumnTable';
import Watermark from '@pansy/react-watermark'
import { useRequest } from 'src/hook';
import { Link, useHistory } from 'react-router-dom';
import { getWatermarkValue } from 'src/api';
import 'src/styles/layout.scss';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';

const { Header, Content } = Layout

export const AuditExecutionPage = () => {
  const { data = [] } = useRequest(getWatermarkValue)
  const location = useLocation<{highFlag: number | string}>()
  const history = useHistory()

  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header" title='语句明细'>
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>审计分析</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item><Link to="/audit_view">审计概览</Link></Breadcrumb.Item>
          <Breadcrumb.Separator>/</Breadcrumb.Separator>
          <Breadcrumb.Item>{location?.state?.highFlag ? '高危明细' : '语句明细'}</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main">
        <Content
          className={classNames('cq-content', { [styles.executionContent]: true })}
        >
          {/* 因为这个组件两个地方在用，所以传入isUnauthorized判断需不需要原有组件里的执行成功/执行失败组件 */}
          <CustomColumnTable isUnauthorized={false} />
          <Watermark
            opacity={0.2}
            text={data.join(' ')}
            zIndex={99} />
        </Content>
      </Layout>
    </Layout>
  )
}
