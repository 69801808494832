import React, { useEffect } from 'react';
import { Form, Input, Modal, Radio, Tooltip, message } from 'antd';
import {
  checkAccess,
  updateAccess,
  IAccessProps
} from 'src/api';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const validateIP = (ip: string): boolean => {
  const pattern: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([A-F0-9]{1,4}:){7}[A-F0-9]{1,4}$|^::1$|^::$/;
  return pattern.test(ip);
}

const validateCIDR = (cidr: string): boolean => {
  const pattern: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(?:3[0-2]|[12]?[0-9])$|^([A-F0-9]{1,4}:){7}[A-F0-9]{1,4}\/(?:12[0-8]|1[01][0-9]|[1-9]?[0-9])$/;
  return pattern.test(cidr);
}

interface IProps {
  visible: boolean;
  callback: () => void;
  value?: IAccessProps
  onCancel: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AccessPolicyModal = (props: IProps) => {
  const { visible, callback, onCancel, value } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value])

  const updateData = (param: IAccessProps) => {
    updateAccess(param as IAccessProps).then(() => {
      message.success("更新成功");
      form.resetFields();
      callback();
    });
  }

  const handleOk = () => {
    form.validateFields().then((values) => {
      const param = value ? { ...value, ...values } : { ...values };
      checkAccess(param as IAccessProps).then(res => {
        if (!res) {
          // false有影响，true是没影响
          Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: '该ip正在使用CloudQuery，确定要执行该操作吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => updateData(param as IAccessProps)
          });
        } else {
          updateData(param as IAccessProps)
        }
      })
    })
  }

  return (
    <Modal
      title="访问规则设置"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
    >
      <Form {...layout} form={form} name="control-hooks">
        <Form.Item
          name="ipAddr"
          label={
            <Tooltip title="来源支持以下格式，单个IP：192.168.3.2  CIDR:192.168.3.2/25">
              <span>来源 <QuestionCircleOutlined /></span>
            </Tooltip>
          }
          rules={[
            { required: true, message: '输入不能为空' },
            () => ({
              validator(_, value) {
                if (value && (validateIP(value) || validateCIDR(value))) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('输入不合法，请重新检查'));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="type" label="访问策略" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value={0}>白名单策略</Radio>
            <Radio value={1}>黑名单策略</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="comment" label="备注">
          <Input.TextArea />
        </Form.Item>
      </ Form >
    </Modal>
  );
}

export default AccessPolicyModal;