import React from 'react'
import { Form, Upload, message, Button, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

export const FormItemRollbackSql = () => {
  return (
    <Form.Item
      label="回退语句"
      name="rollbackText"
      rules={[{ required: true, message: '请输入回退语句' }]}
    >
      <Comp />
    </Form.Item>
  )
}

export const Comp = ({ onChange, value }: { onChange?: any; value?: any }) => {
  return (
    <>
      <Upload
        accept=".txt,.sql"
        beforeUpload={(file) => {
          // 校验文件类型
          const typeReg = /^.*(\.sql|\.txt)$/i
          if (!typeReg.test(file.name)) {
            message.warning('请选择扩展名为 .sql 或 .txt 的文件')
            return false
          }
          // 校验文件大小
          const isLessThan2M = file.size / 1024 / 1024 < 2
          if (!isLessThan2M) {
            message.warning('文件大小上限 2M')
            return false
          }
          // 读取文件回填入表单
          const reader = new FileReader()
          reader.onload = (e) => {
            const text = e.target?.result
            onChange?.(text)
          }
          reader.readAsText(file)
          return false
        }}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />} style={{ marginBottom: 8 }}>
          打开本地文档
        </Button>
      </Upload>
      <Input.TextArea rows={4} allowClear value={value} onChange={onChange} />
    </>
  )
}
