/**
 * @description: 标记
 */
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Tree, Dropdown, Menu, Row, Spin, message, Modal, Tooltip } from 'antd'
import {
  fetchOrgTreeData,
  setExpandedKeys,
  setSelectedNode,
} from '../organizationSlice'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  AddDeptModal,
  AddSubCompanyModal,
  AddOrgGroupModal,
  ModalChangeCompanyName,
  ModalChangeDeptName,
  ModalEdptPrincipal,
} from '../orgModals'
import {deleteOrgGroup, OrgTreeNode, OrgType, getAllUsers, changeShowSubCompany} from 'src/api'
import styles from './index.module.scss'
import { Iconfont, LinkButton } from 'src/components'
import 'src/styles/layout.scss'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

const MenuItem = Menu.Item

interface IProps {
  [p: string]: any
}

export const OrgSdt = (props: IProps) => {
  const { permissionlist } = props
  const { isOnlyRead, roleNameList } = permissionlist || {}
  const [contextMenuType, setContextMenuType] = useState<OrgType | null>(null)
  const { orgTreeData, treeLoading, selectedNode, expandedKeys } = useSelector(
    (state) => state.organization,
  )
  const dispatch = useDispatch()

  const handleDeleteDept = useCallback(
    (id) => {
      return deleteOrgGroup(id).then(() => {
        message.success('删除成功')
        dispatch(fetchOrgTreeData())
      })
    },
    [dispatch],
  )

  const handleDeleteGroup = useCallback(
    (id) => {
      return deleteOrgGroup(id).then(() => {
        message.success('组删除成功')
        dispatch(fetchOrgTreeData())
      })
    },
    [dispatch],
  )

  const { data: usersList, run: getUsers } = useRequest(getAllUsers, {
    manual: true,
  })

  const handleMenuClick = (menuKey: string | number) => {
    if (!selectedNode) return
    const { name, id } = selectedNode
    const key = menuKey as string
    switch (key) {
      case 'addDept':
        dispatch(showModal('AddDept'))
        getUsers()
        break
      case 'addSubCompany':
        dispatch(showModal('AddSubCompany'))
        getUsers()
        break
      case 'deleteDept':
        const modalTitle = selectedNode?.orgType === 'DEPT' ? '部门' : '合作方';
        Modal.confirm({
          title: `确定删除${modalTitle}：${name}？`,
          onOk: () => handleDeleteDept(id),
          centered: true,
          okButtonProps: { danger: true },
        })
        break

      case 'addGroup':
        dispatch(showModal('AddOrgGroup'))
        break

      case 'deleteGroup':
        Modal.confirm({
          title: `确定删除组：${name}？`,
          onOk: () => handleDeleteGroup(id),
          centered: true,
          okButtonProps: { danger: true },
        })
        break

      case 'changeName':
        dispatch(showModal('ModalChangeCompanyName'))
        break
      case 'changeDeptName':
        dispatch(showModal("ModalChangeDeptName"));
        break
      case 'editPrincipal':
        dispatch(showModal('ModalEdptPrincipal'))
        break
      default:
        break
    }
  }

  useEffect(() => {
    dispatch(fetchOrgTreeData())
  }, [dispatch])

  useEffect(() => { 
    if (orgTreeData) { 
      dispatch(setSelectedNode(orgTreeData[0] as any));
    }
  },[dispatch, orgTreeData])

  const renderMenuItems = () => {
    switch (contextMenuType) {
      case 'COMPANY':
        return [
          <MenuItem key="changeName" disabled={isOnlyRead}>修改名称</MenuItem>,
          <MenuItem key="addDept" disabled={isOnlyRead}>添加部门</MenuItem>,
          // <MenuItem key="addSubCompany">添加合作方</MenuItem>,
        ]
      case 'DEPT':
        return [
          <MenuItem key="changeDeptName" disabled={isOnlyRead}>修改名称</MenuItem>,
          <MenuItem key="addDept" disabled={isOnlyRead}>添加部门</MenuItem>,
          <MenuItem key="addGroup" disabled={isOnlyRead}>添加组</MenuItem>,
          <MenuItem key="editPrincipal" disabled={isOnlyRead}>修改负责人</MenuItem>,
          <MenuItem key="deleteDept" disabled={isOnlyRead}>删除</MenuItem>,
        ];
      case 'SUB_COMPANY':
        return [
          <MenuItem key="changeDeptName" disabled={isOnlyRead}>修改名称</MenuItem>,
          <MenuItem key="addDept" disabled={isOnlyRead}>添加部门</MenuItem>,
          // <MenuItem key="addSubCompany">添加合作方</MenuItem>,
          <MenuItem key="editPrincipal" disabled={isOnlyRead}>修改负责人</MenuItem>,
          <MenuItem key="deleteDept" disabled={isOnlyRead}>删除</MenuItem>,
        ];
      case 'GROUP':
        return [<MenuItem key="deleteGroup" disabled={isOnlyRead}>删除</MenuItem>]

      default:
        return [<MenuItem key="deleteGroup" disabled={isOnlyRead}>删除</MenuItem>]
    }
  }

  const handleButtonClick = () => {
    changeShowSubCompany()
    dispatch(fetchOrgTreeData())
  };

  return (
    <section className="cq-card">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">用户管理</h3>
        <div className="cq-card__action">
          <Tooltip placement="bottomRight" title="收起节点">
            <LinkButton onClick={() => dispatch(setExpandedKeys([]))}>
              <Iconfont type="icon-collapse"></Iconfont>
            </LinkButton>
          </Tooltip>
          {/*<Tooltip placement="bottomRight" title="显示合作方">*/}
          {/*  <LinkButton onClick={handleButtonClick}>*/}
          {/*    <Iconfont type="icon-sync-alt"></Iconfont>*/}
          {/*  </LinkButton>*/}
          {/*</Tooltip>*/}
          <Tooltip placement="bottomRight" title="刷新">
            <LinkButton onClick={() => dispatch(fetchOrgTreeData())}>
              <Iconfont type="icon-sync-alt"></Iconfont>
            </LinkButton>
          </Tooltip>
        </div>
      </div>
      <div className="cq-card__content">
        <Row className={styles.sdtContent}>
          <div style={{ width: '100%', height: '100%' }}>
            <Dropdown
              overlay={
                <PermissionTooltip
                  permissionlist={permissionlist}
                  title={"用户管理"}
                >
                  <Menu onClick={({ key }) => handleMenuClick(key)}>
                    {renderMenuItems()}
                  </Menu>
                </PermissionTooltip>
              }
              trigger={['contextMenu']}
              onVisibleChange={(visible) => {
                if (!visible) {
                  setContextMenuType(null)
                }
              }}
            >
              {/* todo: Dropdown 包裹 Tree，需要给 Tree 包裹一层 div，否则无法右键打开菜单 */}
              <Spin spinning={treeLoading} style={{ height: '100%' }}>
                <Tree
                  className={styles.tree}
                  blockNode
                  titleRender={(node) => {
                    const { orgType, title, departmentNum } =
                      node as OrgTreeNode
                    let icon = null
                    switch (orgType) {
                      case 'COMPANY':
                        icon = <Iconfont type="icon-org-company" />
                        break
                      case 'SUB_COMPANY':
                        icon = <Iconfont type="icon-org-company" />
                        break
                      case 'DEPT':
                        icon = <Iconfont type="icon-org-department" />
                        break
                      case 'GROUP':
                        icon = <Iconfont type="icon-org-group" />
                        break
                      default:
                        break
                    }
                    return (
                      <span className={styles.treeTitleWithIcon}>
                        {icon}
                        {title}
                        <span className={styles.treeTitleNumber}>
                          ({departmentNum})
                        </span>
                      </span>
                    )
                  }}
                  treeData={orgTreeData}
                  onExpand={(expanded) => {
                    const keys = expanded as string[]
                    dispatch(setExpandedKeys(keys))
                  }}
                  selectedKeys={
                    selectedNode?.key ? [selectedNode.key] : undefined
                  }
                  onSelect={(_key, { node }) => {
                    if (node.key === selectedNode?.key) return
                    dispatch(setSelectedNode(node as any))
                  }}
                  expandedKeys={expandedKeys}
                  onRightClick={({ node }) => {
                    const dataNode = node as any
                    setContextMenuType(dataNode.orgType)
                    if (node.key === selectedNode?.key) return
                    dispatch(setSelectedNode(node as any))
                  }}
                />
              </Spin>
            </Dropdown>
          </div>
        </Row>
      </div>

      <AddDeptModal usersList={usersList} />
      <AddSubCompanyModal usersList={usersList} />
      <AddOrgGroupModal />
      <ModalChangeCompanyName />
      <ModalChangeDeptName />
      <ModalEdptPrincipal />
    </section>
  )
}
