import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'src/hook'

/** 切换可复制状态的逻辑 */
export const useCopyableChange = (): [React.RefCallback<Element>, boolean?] => {
  const allowCopy = useSelector((state) => state.login.userInfo.copySetting)
  const dummyRef = useRef<Element | null>(null)

  const nodeRef: React.RefCallback<Element> = useCallback(
    (node) => {
      dummyRef.current = node
      if (!node) return
      if (!allowCopy) {
        node.setAttribute('onselect', 'return false')
        node.setAttribute('onselectstart', 'return false')
        node.setAttribute('oncopy', 'return false')
      }
    },
    [allowCopy],
  )

  useEffect(() => {
    const node = dummyRef.current
    if (!allowCopy) {
      node?.setAttribute('onselect', 'return false')
      node?.setAttribute('onselectstart', 'return false')
      node?.setAttribute('oncopy', 'return false')
    }
  }, [allowCopy])

  return [nodeRef, allowCopy]
}
