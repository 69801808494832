import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { getUnReadMes,getUnReadMesNum } from 'src/api'

interface Message {
  id: number
  sender: string
  userId: string
  message: string
  msgId: number
  msgType: string
  msgSourceType: string
  createdAt: string
  updatedAt: string
  status: string
}

export interface UserMesState {
  userId: string
  num:number
  messages: Message[]
}

 const initialState: UserMesState = {
  userId: '',
  num:0,
  messages: [],
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<UserMesState>) => {
      const { userId, messages, num } = action.payload
      state.userId = userId
      state.messages = messages
      state.num = num
    }
  },
})

export const messageReducer = messageSlice.reducer

export const { setMessages } = messageSlice.actions


export const getMessages = (userId: string): AppThunk => (dispatch) => {
  return Promise.all([getUnReadMes(userId), getUnReadMesNum(userId)]).then((res) => {
    const PayloadData = {
      userId,
      messages:res[0],
      num:res[1],
    } 
    dispatch(setMessages(PayloadData))
  })
}