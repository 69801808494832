import React from 'react'
import {
  ModalFormConnectionAccess,
  ModalFormDataCorrection,
  ModalFormDataExport,
  ModalFormDataManipulation,
  ModalFormDataMasking,
  ModalFormExecutionRows,
  ModalFormExecutionTimes,
  ModalFormHighRisk,
  ModalFormMenuAccess,
  ModalFormSensitiveResource
} from './flowForms'

export const FlowFormModals = () => {
  return (
    <>
      <ModalFormConnectionAccess />
      <ModalFormDataCorrection />
      <ModalFormDataExport />
      <ModalFormDataManipulation />
      <ModalFormDataMasking />
      <ModalFormExecutionRows />
      <ModalFormExecutionTimes />
      <ModalFormHighRisk />
      <ModalFormMenuAccess />
      <ModalFormSensitiveResource />
    </>
  )
}
