import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Card, Divider, message } from 'antd'
import { LinkButton } from 'src/components'
import classnames from 'classnames'
import { ModalChangePersonalPassword } from './ChangePersonalPasswordModal'
import { ModalChangeLoginSettings } from './ChangeLoginSettingsModal'
import { ModalChangeLoginHodlTime } from './ChangeLoginHoldTimeModal'
import { ModalVerification } from './VerificationModal'
// import { ModalBindPhone } from './BindPhoneModal'
import { ModalEmail } from './EmailModal'
import { ModalOtpBinding } from './OtpBindingModal'
import {
  canEditLoginOption,
  getCurrentLoginType,
  getEmailStatus,
  getExpireTimeByMin,
  getOtpStatus,
  getUserEmail,
  canEditPhone,
  getBindPhoneStatus,
  getSmsStatus,
  checkWwBoundChat,
} from 'src/api'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  setEmailBindFlag,
  setIsBindEmail,
  setLoginType,
  setUserEmail,
  setSmsBindFlag,
  setIsBindPhone,
  setUserPhone,
  setIsBindOtp,
  setIsOpenOtp,
  setIsOpenSms,
} from '../personalCenterSlice'
import styles from './index.module.scss'
import { ModalBindPhone } from './BindPhoneModal'
import { LoginSetting } from './LoginSetting'
import { WwChatBindModal } from './WwChatBindModal'
import { WwChatUnBindModal } from './WwChatUnBindModal'
import PhoneUnbindModal from './PhoneUnbindModal'


export const SecuritySettings = () => {
  const dispatch = useDispatch()
  const { userInfo, wwLoginInfo } = useSelector((state) => state.login)
  const { userId } = useSelector((state) => state.login.userInfo)
  const [verificationMark, setVerificationMark] = useState('')
  const [loginSetVisible, setLoginSetVisible] = useState<boolean>(false)
  const [loginSettingFuncStr, setLoginSettingFuncStr] = useState<string | undefined>('')
  const [showUnBindPhoneModal, setShowUnBindPhoneModal] = useState(false)
 
  const { smsBindFlag, isBindPhone, isBindOtp, isOpenOtp, isOpenSms, loginType } = useSelector(
    (state) => state.personalCenter,
  )
  /** 检测手机号是否绑定 */
  // dispatch(setUserPhone("17689727255"))
  const { data: bindPhoneStatusData, loading: bindPhoneLoading, run: bindPhoneStatusDataRun } = useRequest(getBindPhoneStatus, {
    onSuccess(data) {
      dispatch(setIsBindPhone(data))
      if (userInfo?.telephone) {
        dispatch(setUserPhone(userInfo?.telephone))
      }
    }
  })


  /* 查看消息开启状态 */
  const { data: smsStatusData, loading: smsStatusLoading, run: getOpenSmsStatusRun } = useRequest(getSmsStatus, {
    onSuccess(res) {
      dispatch(setSmsBindFlag(res?.hasBind))
      if (res?.hasOpen) {
        setLoginSettingFuncStr("短信认证")
      }
      setIsOpenSms(res?.hasOpen)
    }
  })

  /* 查看OTP开启状态 */
  const { data: otpStatusData, loading: otpLoading, run: getOtpStatusRun } = useRequest(getOtpStatus, {
    onSuccess(res) {
      dispatch(setIsBindOtp(res?.hasBind))
      if (res?.hasOpen) {
        setLoginSettingFuncStr("OTP认证")
      }
      setIsOpenOtp(res?.hasOpen)
    }
  })
  // 是否能够修改登录设置  短信认证
  const { data: editLoginOption, loading: editLoginLoading, run: runEditLoginOption } = useRequest(canEditLoginOption, {
    onSuccess(res) {
      //不能编辑
      if (!res) {
        setLoginSettingFuncStr("短信认证")
      }
    }
  })
  // 是否能够修改手机号 true不能修改
  const { data: editPhone, loading: editPhoneLoading, run: runCanEditPhone } = useRequest(canEditPhone)

  /** 获取email */
  const { data: getUserEmailData, run: getUserEmailDataRun } = useRequest(getUserEmail, {
    onSuccess(data) {
      dispatch(setUserEmail(data?.email))
    }
  })

  /** 获取邮箱状态 */
  const { data: emailStatusData, loading: emailLoading, run: emailStatusDataRun } = useRequest(getEmailStatus, {
    onSuccess(data) {
      dispatch(setEmailBindFlag(data?.userHasBindEmail))
    }
  })

  const { data: loginTypeData, loading: loginTypeLoading } = useRequest(getCurrentLoginType, {
    onSuccess(data) {
      dispatch(setLoginType(data))
    }
  })

  /** 获取企微是否绑定状态 */
  const { data: isBoundWw, run: checkIsBoundWw } = useRequest(checkWwBoundChat, { manual: true })

  /**  登录保持时间 */
  const { data: loginTime, run: loginTimeRefresh } = useRequest(getExpireTimeByMin)

  useEffect(() => {
    if (wwLoginInfo?.enable) {
      checkIsBoundWw()
    }
  }, [])

  const refreshLoginSetting = useCallback(async () => {
    getOpenSmsStatusRun()
    getOtpStatusRun()
  }, [emailStatusData, getUserEmailData])

  const refreshEmail = useCallback(async () => {
    emailStatusDataRun()
    getUserEmailDataRun()
  }, [emailStatusData, getUserEmailData])

  const refreshPhone = () => {
    bindPhoneStatusDataRun();
    runCanEditPhone();
  }

  const loginSettingFuncOption = useMemo(() => {
    let option = [
      {
        value: 'closeAuth',
        label: '关闭认证',
      },
    ]
    if (isBindOtp) {
      option.push({
        value: 'otpAuth',
        label: 'OTP认证',
      })
    }
    if (isBindPhone) {
      option.push({
        value: 'smsAuth',
        label: '短信认证',
      })
    }
    return option
  }, [isBindPhone, isBindOtp])

  const renderLoginSetShow = useMemo(() => {
    return (
      <LinkButton
        disabled={!editLoginOption}
        onClick={() => {
          userId && setLoginSetVisible(true)
        }}
      >
        设置 {loginSettingFuncStr ? "[" + loginSettingFuncStr + "]" : ""}
      </LinkButton>
    )
  }, [loginSettingFuncStr, userId, editLoginOption])

  const renderLoginSetting = useMemo(() => {
    return (
      <LoginSetting
        userId={userId}
        option={loginSettingFuncOption}
        loginSetVisible={loginSetVisible}
        setLoginSetVisible={setLoginSetVisible}
        refresh={refreshLoginSetting}
        setLoginSettingFuncStr={setLoginSettingFuncStr}
      />
    )
  }, [loginSetVisible, loginSettingFuncOption, refreshLoginSetting, userId])

  return (
    <section className="cq-new-card flow-card" style={{ marginBottom: '10.5px' }}>
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">安全设置</h3>
      </div>
      <section className="card__content">
        <Card
          bordered={false}
          loading={otpLoading || smsStatusLoading || bindPhoneLoading || emailLoading || loginTypeLoading || editLoginLoading || editPhoneLoading}
        >
          <div className={styles.securityWrap}>
            <div className={styles.securityItem}>
              <p className={styles.securityItemTitle}>  <CheckCircleOutlined className={classnames(
                styles.securityIcon,
                styles.securityIconSuccess
              )} />
                登录密码
              </p>
              <p className={styles.securityItemDescribe}>安全性高的密码可以使帐号更安全。建议您定期更换密码，设置一个至少包含数字和大小写字母且长度8-16字符的密码</p>
            </div>
            <LinkButton
              onClick={() => {
                dispatch(showModal('ModalChangePersonalPassword'))
              }}
              disabled={!!userInfo?.createFrom}
            >
              修改密码
            </LinkButton>
          </div>

          {userInfo?.version !== 'community' && smsBindFlag &&
            <>
              <Divider />
              <div className={styles.securityWrap}>
                <div className={styles.securityItem}>
                  <p className={styles.securityItemTitle}>  <CheckCircleOutlined className={classnames(
                    styles.securityIcon,
                    isBindPhone ? styles.securityIconSuccess : styles.securityIconError // to do
                  )} />
                    {!isBindPhone &&
                      <span className={styles.securityIconError}>
                        {'[未绑定]'}
                        &nbsp;&nbsp;
                      </span>
                    }
                    手机绑定
                  </p>
                  <p className={styles.securityItemDescribe}>绑定手机, 可用于登录时短信二次认证</p>
                </div>
                 
                <LinkButton
                  disabled={!editPhone}
                  onClick={() => {
                    if(isBindPhone && userInfo?.canUnbindPhone){
                      setShowUnBindPhoneModal(true)
                    }else{
                      setVerificationMark('phone')
                      //马上绑定
                      if (loginType !== 'cq' && !isBindPhone) {
                        dispatch(showModal('PhoneEditModal'))
                      }else {
                        dispatch(showModal('VerificationModal'))
                      }
                    }
                  }}
                >
                  {/* 不是管理员 则需要判断editPhone */}
                  {
                    !editPhone 
                    ? '若需要修改手机号,请联系管理员'
                    : !isBindPhone 
                    ? '马上绑定' 
                    : userInfo?.canUnbindPhone
                    ? '解绑' 
                    :'修改手机号'
                  }
                </LinkButton>
              </div>
            </>
          }
          <Divider />
          {/* wwwwwww */}
          {
            userInfo?.version !== 'community' && wwLoginInfo?.enable && <div>
              <div className={styles.securityWrap}>
                <div className={styles.securityItem}>
                  <p className={styles.securityItemTitle}>
                    <CheckCircleOutlined className={classnames(
                      styles.securityIcon,
                      isBoundWw ? styles.securityIconSuccess : styles.securityIconError
                    )} />
                    {
                      !isBoundWw && <span className={styles.securityIconError}>[未绑定] &nbsp;&nbsp;</span>
                    }
                    企业微信绑定
                  </p>
                  <p className={styles.securityItemDescribe}>绑定企业微信，可通过扫码登录系统</p>
                </div>
                {
                  <LinkButton
                    onClick={() => {
                      if (!isBoundWw && loginTypeData !== 'cq') {
                        dispatch(showModal('WwChatBindModal'))
                      }else {
                       
                        dispatch(showModal('VerificationModal'))
                      }
                      setVerificationMark(isBoundWw ? 'unBindWwChat' : 'bindWwChat')
                    }}
                  >
                    {isBoundWw ? "解绑" : "马上绑定"}
                  </LinkButton>
                }
              </div>
              <Divider />
            </div>
          }
          {
            emailStatusData?.systemEmailEnable && <div>
              <div className={styles.securityWrap}>
                <div className={styles.securityItem}>
                  <p className={styles.securityItemTitle}>  <CheckCircleOutlined className={classnames(
                    styles.securityIcon,
                    emailStatusData?.userHasBindEmail ? styles.securityIconSuccess : styles.securityIconError
                  )} />
                    {
                      !emailStatusData?.userHasBindEmail && <span className={styles.securityIconError}>[未绑定] &nbsp;&nbsp;</span>
                    }
                    邮箱绑定
                  </p>
                  <p className={styles.securityItemDescribe}>绑定邮箱，可用于接收消息</p>
                </div>
                {
                  !emailStatusData?.userHasBindEmail && <LinkButton
                    onClick={() => {
                      dispatch(setIsBindEmail(true))
                      if (!emailStatusData?.userHasBindEmail && loginTypeData !== 'cq') {
                        dispatch(showModal('EmailEditModal'))
                      } else {
                        setVerificationMark('email')
                        dispatch(showModal('VerificationModal'))
                      }
                    }}
                  >
                    马上绑定
                  </LinkButton>
                }
                {
                  emailStatusData?.userHasBindEmail && <LinkButton
                    onClick={() => {
                      dispatch(setIsBindEmail(false))
                      if (!emailStatusData?.userHasBindEmail && loginTypeData !== 'cq') {
                        dispatch(showModal('EmailEditModal'))
                      } else {
                        setVerificationMark('email')
                        dispatch(showModal('VerificationModal'))
                      }
                    }}
                  >
                    解绑
                  </LinkButton>
                }
              </div>
              <Divider />
            </div>
          }
          <div className={styles.securityWrap}>
            <div className={styles.securityItem}>
              <p className={styles.securityItemTitle}>  <CheckCircleOutlined className={classnames(
                styles.securityIcon,
                loginSettingFuncStr ? styles.securityIconSuccess : styles.securityIconError
              )} />
                {
                  !loginSettingFuncStr && <span className={styles.securityIconError}>[未开启] &nbsp;&nbsp;</span>
                }
                登录设置
              </p>
              <p className={styles.securityItemDescribe}>为保障您的账号安全，可设置登录时使用OTP进行双因素认证</p>
            </div>
            {renderLoginSetShow}
            {renderLoginSetting}
          </div>
          <Divider />
          <div className={styles.securityWrap}>
            <div className={styles.securityItem}>
              <p className={styles.securityItemTitle}>  <CheckCircleOutlined className={classnames(
                styles.securityIcon,
                otpStatusData?.hasBind ? styles.securityIconSuccess : styles.securityIconError
              )} />
                {
                  !otpStatusData?.hasBind && <span className={styles.securityIconError}>[未绑定] &nbsp;&nbsp;</span>
                }
                OTP设置
              </p>
              <p className={styles.securityItemDescribe}>绑定虚拟OTP后，您可以在登录时通过它来进行二次校验，也可以进行SQL命令的同步复核</p>
            </div>
            <LinkButton
              onClick={() => {
                if (!otpStatusData?.hasBind) {
                  // 如果用户没有绑定邮箱 并且登录类型不是cq 就跳过验证
                  if (!emailStatusData?.userHasBindEmail && loginTypeData !== 'cq') {
                    dispatch(showModal('OtpBindingtModal'))
                  } else {
                    setVerificationMark('otp')
                    dispatch(showModal('VerificationModal'))
                  }
                } else {
                  setVerificationMark('otpUnBind')
                  dispatch(showModal('VerificationModal'))
                }
              }}
            >
              {
                !otpStatusData?.hasBind ? '马上设置' : '解绑'
              }
            </LinkButton>
          </div>
          <Divider />
          <div className={styles.securityWrap}>
            <div className={styles.securityItem}>
              <p className={styles.securityItemTitle}>  <CheckCircleOutlined className={classnames(
                styles.securityIcon,
                true ? styles.securityIconSuccess : styles.securityIconError
              )} />登录保持时间</p>
              <p className={styles.securityItemDescribe}>您可以设置保持登录的时间长度（大于等于1min，小于等于180min），超过该时间系统会退出登录，目前的设置为{loginTime}min（系统默认是30min） </p>
            </div>
            <LinkButton
              onClick={() => {
                dispatch(showModal('LoginHoldTimeModal'))
              }}
            >
              修改时间
            </LinkButton>
          </div>
        </Card>
      </section>
      <ModalChangePersonalPassword />
      <ModalChangeLoginSettings />
      <ModalBindPhone record={userInfo} refresh={refreshPhone} />
      <ModalChangeLoginHodlTime loginTime={loginTime} refresh={loginTimeRefresh} />
      <ModalVerification
        markFlag={verificationMark}
        refreshLoginSetting={refreshLoginSetting}
        refresh={refreshLoginSetting}
        refreshEmail={refreshEmail}
        setLoginSettingFuncStr={setLoginSettingFuncStr}
      />
      <ModalEmail record={userInfo} refresh={refreshEmail} />
      <ModalOtpBinding refresh={refreshLoginSetting} />
      <WwChatBindModal refresh={checkIsBoundWw} />
      <WwChatUnBindModal refresh={checkIsBoundWw} />
      {/* 手机解绑 */}
      {
        showUnBindPhoneModal &&
        <PhoneUnbindModal
          visible={true}
          setVisible={setShowUnBindPhoneModal}
          refresh={refreshPhone}
        />
      }
    </section>
  )
}
