import React, { useCallback } from 'react'
import { Form, Input, message } from 'antd'

import { dumpExport } from 'src/api'
import { UIModal } from 'src/components'
import { useModal, useSelector } from 'src/hook'

export const DumpExportModal = () => {
  const { visible, closeModal } = useModal('DumpExportModal')
  const { rightClickedNode } = useSelector((state) => state.sdt)
  const [form] = Form.useForm()

  const onOk = useCallback(async () => {
    try {
      const { fileName } = await form.validateFields()
      await dumpExport({
        ...rightClickedNode,
        fileName: String(fileName).toLocaleLowerCase().endsWith('.dmp')
          ? fileName
          : `${fileName}.dmp`,
        dataDumpFileType: 'DMP',
      } as any)
      message.success('开始导出，请于任务中心查看结果')
      closeModal()
    } catch (e) {}
  }, [closeModal, form, rightClickedNode])

  return (
    <UIModal
      title="DMP 导出"
      visible={visible}
      onCancel={() => {
        form.resetFields()
        closeModal()
      }}
      onOk={onOk}
      width={420}
    >
      <Form form={form}>
        <Form.Item
          label="文件名称"
          name="fileName"
          rules={[{ required: true, message: '请输入文件名' }]}
        >
          <Input></Input>
        </Form.Item>
        {/* <Form.Item
          label="文件格式"
          name="dataDumpFileType"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select>
            <Select.Option value="DMP">DMP</Select.Option>
            <Select.Option value="SQL">SQL</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    </UIModal>
  )
}
