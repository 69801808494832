import React from 'react'
import { useSelector } from 'src/hook'
import { Form, InputNumber } from 'antd'
import {
  ElementTreeSelect,
  FormItemApplicant,
  FormItemApprover,
  FormItemReason,
  FormItemTimeLimit,
} from '../flowFormItems'
import { FormFieldElementEntity, FormFieldsExecutionTimes } from 'src/types'
import { FlowModalForm } from './FlowModalForm'
import { launchFlowApply } from 'src/api'

export const ModalFormExecutionTimes = React.memo(() => {
  const { userId: applyUserId, email } = useSelector(
    (state) => state.login.userInfo,
  )
  const flowType = 'executionTimes'

  const [form] = Form.useForm()
  const onResetApprover = () => {
    form && form.setFieldsValue({approveUserId:''})
  }

  return (
    <FlowModalForm<FormFieldsExecutionTimes>
      type={flowType}
      name={`flow-${flowType}`}
      form={form}
      request={async (values) => {
        if (!applyUserId) return

        const {
          approveUserId,
          applyReason,
          elements,
          timeLimit,
          numberLimit,
        } = values
        const {
          connectionId,
          connectionType: dataSourceType,
          nodeType,
        } = elements?.[0]
        const expiredTimeLimit = timeLimit.preset || timeLimit.custom
        const nodePathList = elements.map(({ nodePath }) => nodePath)
        return launchFlowApply({
          applyUserId,
          email,
          applyReason,
          expiredTimeLimit,
          flowType,
          connectionId,
          dataSourceType,
          nodePathList,
          nodeType,
          firstApproveUserId: approveUserId,
          executeTimeLimit: numberLimit,
        })
      }}
    >
      <FormItemApplicant />
      <Form.Item
        label="数据库元素"
        name="elements"
        required
        rules={[
          {
            validator: (_, value?: FormFieldElementEntity[]) => {
              if (!value?.length) {
                return Promise.reject('请选择数据库元素')
              }
              const ruleObj = value[0]
              for (const el of value) {
                if (el.connectionId !== ruleObj.connectionId) {
                  return Promise.reject('只能选择相同连接下的元素')
                }
                if (el.nodeType !== ruleObj.nodeType) {
                  return Promise.reject('只能选择相同类型的元素')
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <ElementTreeSelect flowType="executionTimes" onChange={onResetApprover}/>
      </Form.Item>
      <Form.Item
        label="执行次数"
        name="numberLimit"
        initialValue={1000}
        rules={[{ required: true, message: '请填写执行次数' }]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <FormItemApprover />
      <FormItemReason />
      <FormItemTimeLimit />
    </FlowModalForm>
  )
})
