import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRequest, useSelector } from 'src/hook';
import { Input, Modal, Popconfirm, Table, message, Button, Tooltip } from 'antd';
import { columnsRequest, columnsRequestExpandRow } from '../../common/columns';
import { Link } from 'react-router-dom';
import { BtnWithConfirmModal } from 'src/components';
import {
  approveApplication,
  getPendingApproval,
  getResolvedApproval,
  getApproveListFinish,
  myApplyChildList,
  myApplyChildListAppraved,
  dealWithPermission,
  withdrawOrder,
} from 'src/api';
import ModalDataChangeSelectNewApprover from 'src/pageTabs/flowPages/flowDetails/ModalDataChangeSelectNewApprover';
import classNames from 'classnames';
import {
  applyStatusMap,
  APP_EFFECTIVE_STATES,
  MyApprovalTabKeyType,
  MyApprovalTabKey,
  NO_NEED_TO_LAND_TYPES
} from '../../constants';
import { getEffectiveStatus } from '../../utils';
import styles from '../index.module.scss';
import { getCurrentModulePermissionByUrl } from 'src/util';

export interface IProps {
  activeKey: MyApprovalTabKey;
}

const MyApprovalTablePage: FC<IProps> = ({ ...props }) => {
  const { activeKey } = props;
  const { applySearchValue } = useSelector((state) => state.accessRequest);
  const [currentActionRecord, setCurrentActionRecord] = useState<{
    label: string;
    // type: string
    id: number;
  }>();
  const [visible_setNewApprover, setVisible_setNewApprover] = useState(false); // 新审批人 modal visible
  const [currentTransferApprover, setCurrentTransferApprover] = useState<
    Array<string>
  >([]); // 新审批人 modal visible
  const [approveType, setApproveType] = useState<
    'rejected' | 'fullilled' | 'pending'
  >('pending');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [newData, setNewdData] = useState<any>([]);
  const [filterType, setFilterType] = useState<any>(null);
  const [timeSort, setTimeSort] = useState<string>('desc');

  const userId = useSelector((state) => state.login.userInfo.userId);

  //模块权限查询
  const { permissionList } = useSelector((state) => state?.login)
  let modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, 'FLOW_APPLY') || { isOnlyRead: false, roleNameList: [] };
  },[JSON.stringify(permissionList)])

  // 待审批
  const {
    data: stayData,
    loading: stayLoadingg,
    run: stayRun,
    refresh: stayRefresh,
  } = useRequest(getPendingApproval, {
    manual: true,
    formatResult: (data) => {
      const list = data.list;
      const total = data.totalCount;
      setNewdData(list);
      return { list, total };
    },
  });

  // 已审批
  const {
    data: yetData,
    loading: yetLoading,
    run: yetRun,
    refresh: yetRefresh,
  } = useRequest(getResolvedApproval, {
    manual: true,
    formatResult: (data) => {
      let { pageNumber, pageSize, list } = data;
      const newList = list.map((item: any, index: number) => {
        item.indexKey = (pageNumber - 1) * pageSize + index + 1;
        return item;
      });
      setNewdData(newList);
      const total = data.totalCount;
      return { list: newList, total };
    },
  });

  // 已完成
  const {
    data: finishData,
    loading: finishLoading,
    run: finishRun,
    refresh: finishRefresh,
  } = useRequest(getApproveListFinish, {
    manual: true,
    formatResult: (data) => {
      let { pageNumber, pageSize, list } = data;
      const newList = list.map((item: any, index: number) => {
        item.indexKey = (pageNumber - 1) * pageSize + index + 1;
        return item;
      });

      const total = data.totalCount;
      setNewdData(newList);
      return { list: newList, total };
    },
  });

  // 同意审批
  const { run: runApprove, fetches: fetchesApprove } = useRequest(
    approveApplication,
    {
      manual: true,
      // fetchKey: (id) => id,
      onSuccess: () => {
        if (approveType === 'fullilled') {
          message.success('通过成功');
        } else {
          message.success('驳回成功');
        }
        refreshAll();
      },
    }
  );
  //撤回
  const { run: runWithdrawOrder } = useRequest(withdrawOrder, {
    manual: true,
    onSuccess: () => {
      refreshAll();
    },
  });

  const refreshAll = () => {
    if (activeKey === 'stayApprove') {
      stayRun({
        userId,
        pageSize,
        currentPage: 1,
        priGranType: filterType,
        title: applySearchValue,
        timer: timeSort,
      });
    } else if (activeKey === 'yetApprove') {
      yetRun({
        userId,
        pageSize,
        currentPage: 1,
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
      });
    } else if (activeKey === 'power' || activeKey === 'withdraw') {
      // 待落权 已撤回
      yetRun({
        userId,
        pageSize,
        currentPage: 1,
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
        applyStatus: activeKey,
      });
    } else {
      finishRun({
        userId,
        pageSize,
        currentPage: 1,
        title: applySearchValue,
        timer: timeSort,
        priGranType: filterType,
      });
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if (userId) {
      // 待审批
      if (activeKey === 'stayApprove') {
        stayRun({
          userId,
          pageSize,
          currentPage: 1,
          title: applySearchValue,
          timer: timeSort,
          priGranType: filterType,
        });
      }
      // 已审批
      if (activeKey === 'yetApprove') {
        yetRun({
          userId,
          pageSize,
          currentPage: 1,
          title: applySearchValue,
          timer: timeSort,
          priGranType: filterType,
        });
      }
      // 待落权 已撤回
      if (activeKey === 'power' || activeKey === 'withdraw') {
        yetRun({
          userId,
          pageSize,
          currentPage: 1,
          title: applySearchValue,
          timer: timeSort,
          priGranType: filterType,
          applyStatus: activeKey,
        });
      }
      // 已完成
      if (activeKey === 'finish') {
        finishRun({
          userId,
          pageSize,
          currentPage: 1,
          title: applySearchValue,
          timer: timeSort,
          priGranType: filterType,
        });
      }
      setCurrentPage(1);
      // setPageSize(10)
    }
  }, [activeKey, applySearchValue, userId]);

  // 驳回|拒绝
  const confirm = (applyId: string, flowTaskId: any) => {
    function isEmpty(closeFn: any, val?: string) {
      let approveParams = {
        flowId: applyId,
        taskId: flowTaskId || '',
        approvedFlag: false,
        approvedComment: val,
        approvedTime: '',
      };
      setApproveType('rejected');
      //@ts-ignore
      runApprove(approveParams).finally(() => {
        setApproveType('pending');
      });

      closeFn();
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => isEmpty(closeFn, val),
      });
    };

    const { update } = Modal.confirm({
      title: '备注',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    });
  };

  // 同意
  const agreeBtn = (record: any) => {
    function isEmpty(closeFn: any, val?: string) {
      let approveParams = {
        flowId: record?.applyId,
        taskId: record?.flowTaskId || '',
        approvedFlag: true,
        approvedComment: val,
        approvedTime: '',
      };
      setApproveType('fullilled');
      //@ts-ignore
      runApprove(approveParams).finally(() => {
        setApproveType('pending');
      });
      closeFn();
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => isEmpty(closeFn, val),
      });
    };

    const { update } = Modal.confirm({
      title: '备注',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    });
  };

  // 转审
  const turretBtn = (record: any) => {
    setVisible_setNewApprover(true);
    setCurrentTransferApprover([record.flowTaskId]); //应该使用当前userId
    setCurrentActionRecord({
      label: '任务转交',
      // type: key,
      id: record.id,
    });
  };

  const renderTabAction = (record: any, activeKey: MyApprovalTabKeyType) => {
    if (activeKey === 'stayApprove') {
      return (
        <>
          <Tooltip 
            title={modulePermissionObj?.isOnlyRead?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[工单管理]没有操作权限`
              : null
            }
          >
            <Button
              type='link'
              onClick={() => agreeBtn(record)}
              className={styles.padding0}
              disabled={modulePermissionObj?.isOnlyRead}
            >同意</Button>
            <Button
              type='link'
              className={styles.padding0} 
              onClick={() =>
                confirm(record?.applyId as string, record?.flowTaskId)
              }
              disabled={modulePermissionObj?.isOnlyRead}
            >拒绝</Button>
            <Button
              type='link'
              className={styles.padding0}
              onClick={() => turretBtn(record)}
              disabled={modulePermissionObj?.isOnlyRead}
            >转审</Button>
          </Tooltip>
        </>
      );
    } else if (activeKey === 'yetApprove') {
      return (
        <>
          {/*已通过且（生效中 || 待生效） */}
          {record?.applyStatus === 'pass' &&
            getEffectiveStatus(record?.beginTime, record?.endTime) !==
              APP_EFFECTIVE_STATES.EXPIRED && (
              <BtnWithConfirmModal
                title="撤回将收回申请人获得的权限，确认撤回？"
                btnText="撤回"
                onClick={(reason?: string) => runWithdrawOrder({
                 flowId: Number(record?.applyId),
                 withdrawRemark:reason
                })}
              />
            )}
        </>
      );
    } else if (activeKey === 'finish') {
      return (
        <>
          {/* 所有子工单单都通过 */}
          { !record?.allChildRefuse && record?.approvedUser && getEffectiveStatus(record?.beginTime, record?.endTime) !==
            APP_EFFECTIVE_STATES.EXPIRED && (
            <BtnWithConfirmModal
              title="撤回将收回申请人获得的权限，确认撤回？"
              btnText="撤回"
              onClick={(reason?: string) => runWithdrawOrder({
                flowId: Number(record?.applyId),
                withdrawRemark:reason
               })}
            />
          )}
        </>
      );
    } else {
      return '-';
    }
  };
  const newColumns = useMemo(() => {
    const newColumns = columnsRequest.map((item: any) => {
      if (item?.dataIndex === 'uuid') {
        return {
          ...item,
          render: (val: any, record: any) => (
            <Link
              className={styles.underLine}
              to={{
                pathname: `/mine_approve/${val}/detail`,
                state: record,
              }}
            >
              {val}
            </Link>
          ),
        };
      }
      if (activeKey === 'finish' && item?.dataIndex === 'applyStatus') {
        return {
          ...item,
          render: (applyStatus: any, record: any) => (
            <span>
              <span
                className={classNames(
                  styles.statusDot,
                  applyStatus === 'pending' && styles.pendingBack,
                  (applyStatus === 'pass' || applyStatus === 'already') &&
                    styles.alreadyBack,
                  applyStatus === 'power' && styles.powerBack,
                  applyStatus === 'refuse' && styles.rejectBack
                )}
              ></span>
              {applyStatus === 'pass' ? '已完成' : applyStatusMap[applyStatus]}
            </span>
          ),
        };
      }
      if (item?.dataIndex === 'actions') {
        return {
          ...item,
          fixed: 'right',
          render: (_: any, record: any) => (
            <div className={styles.actionsBtn}>
              {renderTabAction(record, activeKey)}
            </div>
          ),
        };
      }
      return item;
    });
    if (['yetApprove','finish'].includes(activeKey)) {
      newColumns.splice(8, 0, {
        title: '生效状态',
        dataIndex: 'effectiveState',
        width: 100,
        render: (_: string, record: any) => {
          return  ((record?.applyStatus === 'pass' && activeKey === 'yetApprove') || activeKey === 'finish' ) ? getEffectiveStatus(record?.beginTime, record?.endTime): '-';
        },
      });
    }

    //样式
    return newColumns;
  }, [activeKey]);

  const onChange = (pagination: any, filters: any, sorter: any) => {
    setFilterType(filters?.priGranType ? filters?.priGranType[0] : null);
    if (activeKey === 'stayApprove') {
      stayRun({
        userId,
        pageSize: pagination?.pageSize,
        currentPage: pagination?.current,
        priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
        title: applySearchValue,
        timer: sorter?.order === 'ascend' ? 'asc' : 'desc',
      });
    }
    // 已审批
    if (activeKey === 'yetApprove') {
      yetRun({
        userId,
        pageSize: pagination?.pageSize,
        currentPage: pagination?.current,
        priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
        title: applySearchValue,
        timer: sorter?.order === 'ascend' ? 'asc' : 'desc',
      });
    }
    // 待落权 已撤回
    if (activeKey === 'power' || activeKey === 'withdraw') {
      yetRun({
        userId,
        pageSize: pagination?.pageSize,
        currentPage: pagination?.current,
        priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
        title: applySearchValue,
        timer: sorter?.order === 'ascend' ? 'asc' : 'desc',
        applyStatus: activeKey,
      });
    }
    // 已完成
    if (activeKey === 'finish') {
      finishRun({
        userId,
        pageSize: pagination?.pageSize,
        currentPage: pagination?.current,
        priGranType: filters?.priGranType ? filters?.priGranType[0] : null,
        title: applySearchValue,
        timer: sorter?.order === 'ascend' ? 'asc' : 'desc',
      });
    }
    setCurrentPage(pagination?.current);
    setPageSize(pagination?.pageSize);
    setTimeSort(sorter?.order === 'ascend' ? 'asc' : 'desc');
  };

  return (
    <div>
      <Table
        rowKey="uuid"
        loading={stayLoadingg || yetLoading || finishLoading}
        className={styles.tablePage}
        expandable={{
          expandedRowRender: (record: any) => (
            <ExpandedRowContent record={record} activeKey={activeKey} />
          ),
          rowExpandable: (record) => !NO_NEED_TO_LAND_TYPES.includes(record?.priGranType),
        }}
        columns={newColumns}
        dataSource={newData}
        pagination={{
          current: currentPage,
          pageSize,
          total:
            activeKey === 'stayApprove'
              ? stayData?.total
              : ['yetApprove', 'power', 'withdraw'].includes(activeKey)
              ? yetData?.total
              : finishData?.total,
          showSizeChanger: true,
          showQuickJumper: true,
          // onChange: handleChangePagination,
        }}
        size="small"
        rowClassName={(record, index) =>
          ((record?.applyStatus === 'pass' && activeKey === 'yetApprove') || activeKey === 'finish' )  &&
          getEffectiveStatus(record?.beginTime, record?.endTime) ===
            APP_EFFECTIVE_STATES.EXPIRED
            ? styles.notInEffect
            : ''
        }
        scroll={{ x: 'max-content', y: `calc(100vh - 280px)` }}
        onChange={onChange}
      />
      <ModalDataChangeSelectNewApprover
        cleanParentComponentData={() => {
          setCurrentTransferApprover([]);
          if (activeKey === 'stayApprove') {
            stayRefresh();
          }
          // setSearchParams(defaultSearchParams)
        }}
        userTasks={currentTransferApprover}
        visible_setNewApprover={visible_setNewApprover}
        setVisible_setNewApprover={setVisible_setNewApprover}
      />
    </div>
  );
};

export const ExpandedRowContent = ({
  record,
  activeKey,
}: {
  record: any;
  activeKey: string;
}) => {
  const flowUUID = record?.uuid;
  const flowTaskId = record?.flowTaskId;
  const {
    data: childData,
    run: childRun,
    refresh: childRefresh,
    loading,
  } = useRequest(
    activeKey !== 'stayApprove' ? myApplyChildListAppraved : myApplyChildList,
    {
      manual: true,
      refreshDeps: [flowUUID],
    }
  );

  // 落权
  const { run: weightingRun } = useRequest(dealWithPermission, {
    manual: true,
    onSuccess(data) {
      childRefresh();
    },
  });

  useEffect(() => {
    // 和后端协商 只有我的审批 待审批传flowTaskId 其他传字符1
    if (flowUUID) {
      if (activeKey === 'stayApprove') {
        childRun(flowUUID, flowTaskId);
      } else {
        childRun(flowUUID, '1');
      }
    }
  }, [flowUUID]);

  // 驳回|拒绝
  const confirm = (record: any) => {
    function isEmpty(closeFn: any, val?: string) {
      weightingRun({
        flowUUID: record?.uuid,
        powerStatus: 'rejected',
        remarks: val,
      });
      closeFn();
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => isEmpty(closeFn, val),
      });
    };

    const { update } = Modal.confirm({
      title: '拒绝理由',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    });
  };

  // 同意
  const agreeBtn = (record: any) => {
    function isEmpty(closeFn: any, val?: string) {
      weightingRun({
        flowUUID: record?.uuid,
        powerStatus: 'already',
        remarks: val,
      });
      closeFn();
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => isEmpty(closeFn, val),
      });
    };

    const { update } = Modal.confirm({
      title: '同意理由',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    });
  };

  const newExpandColumns = useMemo(() => {
    let newExpandColumns = columnsRequestExpandRow.map((item: any) => {
      if (item?.dataIndex === 'uuid') {
        return {
          ...item,
          render: (val: any, record: any) => (
            <Link
              className={styles.underLine}
              to={{
                pathname: `/mine_approve/${val}/detail`,
                state: {
                  ...record,
                  flag: activeKey === 'stayApprove' ? 'childPending' : '',
                },
              }}
            >
              {val}
            </Link>
          ),
        };
      }

      return item;
    });

    if (activeKey === 'power') {
      newExpandColumns.splice(5, 0, {
        title: '操作',
        dataIndex: 'actions',
        render: (val: any, record: any) => {
          return record?.canOperation ? (
            <div className={styles.actionsBtn}>
              <span onClick={() => agreeBtn(record)}>同意</span>
              <Popconfirm
                placement="topLeft"
                title={'确定要拒绝此工单吗'}
                onConfirm={() =>
                  confirm(record)
                }
                okText="是"
                cancelText="否"
              >
                <span>拒绝</span>
              </Popconfirm>
            </div>
          ) : (
            '-'
          );
        },
      });
    }
    return newExpandColumns;
  }, [activeKey, record]);

  return (
    <Table
      rowKey="uuid"
      loading={loading}
      columns={newExpandColumns}
      dataSource={childData}
      pagination={false}
      size="middle"
      className={styles.childTable}
    />
  );
};

export { MyApprovalTablePage };
