import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { Form, Input, message } from 'antd'
import { refreshOnRoot } from '../sdtSlice'
import { updateConnAlias } from 'src/api'

export const UpdateConnAliasModal = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { UpdateConnAlias } = useSelector((state) => state.modal)
  const { rightClickedNode } = useSelector((state) => state.sdt)
  const [renameSdtNodeForm] = Form.useForm()

  const { parentGroupId, connectionId, alias } = rightClickedNode || {}

  useEffect(() => {
    if (UpdateConnAlias) {
      renameSdtNodeForm.setFieldsValue({ alias })
    }
  }, [UpdateConnAlias])

  return (
    <UIModal
      title="修改别名"
      visible={UpdateConnAlias}
      onCancel={() => dispatch(hideModal('UpdateConnAlias'))}
      onOk={() => {
        renameSdtNodeForm.validateFields().then(({ alias }) => {
          const nodeParams = {
            groupId: parentGroupId || 0,
            connectionId,
            alias,
          }
          setConfirmLoading(true)
          updateConnAlias(nodeParams as any)
            .then(() => {
              message.success('修改别名成功')
              dispatch(hideModal('UpdateConnAlias'))
              dispatch(refreshOnRoot())
            })
            .finally(() => setConfirmLoading(false))
        })
      }}
      afterClose={() => renameSdtNodeForm.resetFields()}
      width={320}
      confirmLoading={confirmLoading}
    >
      <Form form={renameSdtNodeForm}>
        <Form.Item
          label="别名"
          name="alias"
          rules={[{ required: true, message: `连接别名` }]}
        >
          <Input placeholder="请输入别名"></Input>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
