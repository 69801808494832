import React, { useEffect } from 'react'
import { Select, Form, Button } from 'antd'
import { AdvancedSearchForm } from '@bintoolsfe/bintools_ui'
import { useRequest } from 'src/hook'
import {
  getSearchUsers,
  getPermTypes,
  getAllDataSourcePerms,
  getAllDataSourceRoles,
} from 'src/api'
import { DatePicker } from 'src/components'
import styles from './index.module.scss'
import { formatDateRange } from 'src/util'
import { ElementTreeSelect} from './ElementTreeSelect'

const { RangePicker } = DatePicker

export const FilterHeader = ({
  setPageState,
  setParams,
}: {
  setPageState: any
  setParams: any
  getDataSource: any
}) => {
  // 获取用户名
  const { data: userList, run: getUsers } = useRequest(getSearchUsers, {
    manual: true,
    formatResult: (userList) => {
      return userList.map((user: any) => {
        return {
          label: `${user.userName}(${user.userId})`,
          value: user.userName,
        }
      })
    },
  })
  useEffect(() => {
    getUsers({ nameValue: '', pageSize: 0, pageNum: 9999 })
  }, [getUsers])

  // 获取角色名
  const { data: roleList, run: getRoles } = useRequest(getAllDataSourceRoles, {
    manual: true,
    formatResult: (roleList) => {
      return roleList.map((role: any) => {
        return {
          label: role.roleName,
          value: role.roleName,
        }
      })
    },
  })
  useEffect(() => {
    getRoles()
  }, [getRoles])

  /** 获取权限类型 */
  const { data: permTyps, run: getPermissions } = useRequest(getPermTypes, {
    manual: true,
    formatResult: (permTyps) =>
      permTyps.map((p) => ({ label: p.message, value: p.logo })),
  })
  useEffect(() => {
    getPermissions()
  }, [getPermissions])

  /** 获取所有权限 */
  const { data: perms, run: getPerms } = useRequest(getAllDataSourcePerms, {
    manual: true,
    formatResult: (perms) => perms.map((p) => ({ label: p, value: p })),
  })
  useEffect(() => {
    getPerms()
  }, [getPerms])

  // 搜索查询，获取查询数据
  const onSearch = (val: any) => {
    const { authDate } = val
    let authDateToMs
    if (!!authDate && authDate.length > 0) {
      authDateToMs = formatDateRange(authDate)
    }
    val.authDate = authDateToMs
    setPageState({ current: 1, pageSize: 10 })
    setParams(val)
  }

  const customSearchBtn = (searchFn: any) => {
    return (
      <div className={styles.searchBtns}>
        <Button style={{ marginBottom: '24px' }} htmlType="submit">
          搜索
        </Button>
        {/* <Button>导出结果</Button> */}
      </div>
    )
  }

  return (
    <AdvancedSearchForm
      layout="horizontal"
      span={8}
      showSearchBtn
      customSearchBtn={customSearchBtn}
      onSearch={onSearch}
    >
      <Form.Item label="用户名" name="userName" labelCol={{ span: 6 }}>
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="请选择用户"
          optionFilterProp="label"
          options={userList}
          maxTagCount={2}
        ></Select>
      </Form.Item>
      <Form.Item label="角色名" name="roleName" labelCol={{ span: 6 }}>
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="请选择角色"
          optionFilterProp="label"
          options={roleList}
          maxTagCount={2}
        ></Select>
      </Form.Item>
      <Form.Item
        label="数据库元素"
        name="elements"
        labelCol={{ span: 6 }}
      >
        <ElementTreeSelect flowType="dataManipulation" />
      </Form.Item>
      <Form.Item label="权限类型" name="permType" labelCol={{ span: 6 }}>
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="请选择权限类型"
          optionFilterProp="label"
          options={permTyps}
          maxTagCount={2}
        ></Select>
      </Form.Item>
      <Form.Item label="操作" name="operator" labelCol={{ span: 6 }}>
        <Select
          showSearch
          allowClear
          mode="multiple"
          placeholder="请选择操作类型"
          optionFilterProp="label"
          options={perms}
          maxTagCount={2}
        ></Select>
      </Form.Item>
      <Form.Item label="授权时间" name="authDate" labelCol={{ span: 6 }}>
        <RangePicker />
      </Form.Item>
    </AdvancedSearchForm>
  )
}