/**
 * 用于检查 switch case 是否穷尽了所有可能值
 * 例：
 * type Param = 'foo' | 'bar' | 'baz'
 * switch(param as Param) {
 *  case 'foo':
 *    break;
 *  case 'bar':
 *    break;
 *  default:
 *    exhaustiveCheck(param)  // expect ts failure here
 *    break
 * }
 */
export function exhaustiveCheck(_param: never) {
  // should never reach here
}
