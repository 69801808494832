import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, message } from 'antd'
import { reNameFiles } from 'src/api'
import { UIModal } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { setPwd } from './myFolderSlice'
import { folderNameValidator } from 'src/util'

export const ReNameModalPage:  React.FC<{ refresh: () => Promise<void> }> = ({
  refresh
})=> {
  const { ReNameModal } = useSelector((state) => state.modal)
  const { pwd, treeTag, singleFile, clickTreeOrlist } = useSelector((state) => state.myFolder)
  const [ unit, setUnit] = useState('')
  const dispatch = useDispatch()
  const handleCreateNewFolder = () => {
    newFolderForm.validateFields().then((values) => {
      const { newName } = values
      let params = {
        newName,
        sourcePath: treeTag
      }
      runReNameFiles(params).then(() => {
        message.success(`修改成功`)
      })
      dispatch(hideModal('ReNameModal'))
    })
  }
  const { run: runReNameFiles } = useRequest(reNameFiles,
    { manual: true, onSuccess: (data) => {
      refresh()
      if (clickTreeOrlist === 'tree') {
        dispatch(setPwd(data))
      } else {
        dispatch(setPwd(pwd?.path))
      }
    } },
  )
  const [newFolderForm] = Form.useForm()

  const focusRef = useRef<Input>(null)
  useEffect(() => {
    if (!ReNameModal) return
    focusRef.current?.select()
  }, [ReNameModal])

  useEffect(() => {
    if (!singleFile?.name) return
    let str = singleFile?.name?.split(".")
    if (str.length > 1) {
      setUnit(str[str.length -1])
    }
    newFolderForm.setFieldsValue({newName: str[0]})
  }, [singleFile?.name])
  return (
    <UIModal
      title="重命名"
      visible={ReNameModal}
      onCancel={() => dispatch(hideModal('ReNameModal'))}
      onOk={handleCreateNewFolder}
      width={320}
      afterClose={() => newFolderForm.resetFields()}
    >
      <Form
        form={newFolderForm}
        onFinish={handleCreateNewFolder}
      >
        <Form.Item
          name="newName"
          label="名称"
          rules={[
            { required: true, message: '名称不能为空' },
            { validator: folderNameValidator },
            { max: 25, message: '名称最长 25 个字符' },
          ]}
        >
          <Input ref={focusRef} suffix={singleFile?.type !== 'directory' ? '.' + unit : ''}></Input>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
