import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { Input } from 'antd'
import { MENU_FLOW } from 'src/constants';
import { SimpleBreadcrumbs } from 'src/components'
import { SearchOutlined } from '@ant-design/icons'
import { VisitWorkOrderTabsPage } from './visit-work-order-tabs'
import { setApplySearchValue } from '../accessRequestSlice'
import styles from './index.module.scss'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

const breadcrumbData = [
  { title: MENU_FLOW },
  {
    title: "工单管理",
  },
];

export const VisitWorkOrderPage= () => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')

  const onChangeValue = (str: string) => {
    setSearchValue(str)
  }

  const onSearch = _.debounce((e) => {
    dispatch(setApplySearchValue(e?.target?.value))
  }, 500)

  const handleInputChange = (e: { persist: () => void; target: { value: React.SetStateAction<string> } })=> {
    e.persist()
    setSearchValue(e?.target?.value)
    onSearch(e)
  }

  return (
    <div className={styles.myApplyRequestWrap}>
      <div className={styles.headers}>
        <SimpleBreadcrumbs items={breadcrumbData} />
        <div className={styles.flexRow}>
          <Input
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="搜索标题"
            value={searchValue}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.content}>
        <ErrorBoundary>
          <VisitWorkOrderTabsPage onChangeValue={onChangeValue}/>
        </ErrorBoundary>
      </div>
    </div>
  )
}
