import React from 'react';
import { Menu } from 'antd';

export const renderMenuList = ({
    menus,
    onChange
}: {
    menus: any[];
    onChange: (key: any) => void;
}) => {
 return (
    <Menu onClick={({key}) => onChange(key)}>
    {menus.map((item) => (
      <Menu.Item key={item.label}>
        <div>{item.label}</div>
      </Menu.Item>
    ))}
  </Menu>
 )
}