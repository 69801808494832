// 访问申请-我的申请-资源搜索(加入申请单，可以编辑权限等级和工具权限)
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Badge, Button, Input, Select, Table, message, Dropdown, Menu, Tooltip } from 'antd'
import { DownOutlined, SearchOutlined, } from '@ant-design/icons'
import { columnsDetail, resourceExpamdedRow } from '../common/columns'
import { Iconfont } from 'src/components'
import { 
  addRequisitionToCart, 
  getAllDataBaseAndSchema, 
  getCartDataFAT, 
  getConnectionListForFlow, 
  getDataSourceList, 
  getPermissionTemplateInfo,
  getToolTemplateInfo
} from 'src/api'
import { useRequest } from "src/hook"
import classnames from 'classnames'
import PermissionLevelModal from '../components/PermissionLevelModal'
import styles from './index.module.scss'
import { resetPermissionModal } from 'src/pageTabs/authorizationManagePage/commonMethods'

interface ListProps { }

export const SearchTablePage: FC<ListProps> = ({ ...rest }) => {
  const { id } = useParams<{ id?: string; editId?: string }>()
  const [selectValue, setSelectValue] = useState('all')
  const [pageNo, setPageNo] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [inputValue, setInputValue] = useState<string>('')


  const { data, run, loading, refresh } = useRequest(getConnectionListForFlow, {
    manual: true,
    onSuccess(data) {
    }
  })

  const { data: shopCartData, refresh: shopRefresh } = useRequest(getCartDataFAT, {
    formatResult(res) {
      return res || []
    }
  })

  // 获取数据源列表
  const { data: dataSourceList = [] } = useRequest(getDataSourceList, {
    formatResult(res: any[]) {
      let newArr = res?.map((i: any) => ({ label: <><Iconfont type={`icon-connection-${i?.dataSourceName}`} style={{ marginRight: 4 }} />{i?.dataSourceName}</>, value: i?.dataSourceName }))
      newArr.unshift({
        label: <><Iconfont type='icon-link_nomal' style={{ marginRight: 4 }} />全部</>,
        value: 'all'
      })
      return newArr
    },
  })
  const newColumns = useMemo(() => {
    const newColumns = columnsDetail.map((item: any) => {
      if (item?.dataIndex === 'instanceName') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return <div className={styles.instance}>
              <span>{record?.userInputs?.remark}</span>
              <span>
                <Iconfont type={`icon-connection-${record?.connectionType}`} style={{ marginRight: 4 }} />
                {record?.userInputs?.connectionUrl + ':' + record?.userInputs?.connectionPort}
              </span>
            </div>
          },
        }
      }
      if (item?.dataIndex === 'serverName') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return <span>
              {(record?.userInputs?.serviceName) ? (record?.userInputs?.serviceName) : '-'}
            </span>
          },
        }
      }
      if (item?.dataIndex === 'version') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return <span>
              {record?.userInputs?.dataSourceVersion}
            </span>
          },
        }
      }
      if (item?.dataIndex === 'connectionName') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return <span>
              <Iconfont type={`icon-connection-${record?.connectionType}`} style={{ marginRight: 4 }} />
              {record?.userInputs?.connectionName}
            </span>
          },
        }
      }
      if (item?.dataIndex === 'connectionUser') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return <span>
              {record?.userInputs?.userName}
            </span>
          },
        }
      }
      if (item?.dataIndex === 'connectionAdminis') {
        return {
          ...item,
          render: (_: any, record: any) => {
            return <span>
              {
                record?.managerName && record?.manager ? (record?.managerName + '(' + record?.manager + ')') : 
                (
                  record?.managerName? record?.managerName : (record?.manager ? '(' + record?.manager +')' : '-')
                )
              }
            </span>
          },
        }
      }

      return item
    })

    //样式
    return newColumns
  }, [])

  useEffect(() => {
    run({
      pageNo,
      pageSize
    })
  }, [])

  const handleChangePagination = (current: number, pageSize?: number) => {
    setPageNo(current)
    if (pageSize) {
      setPageSize(pageSize)
      run({
        pageNo: current,
        pageSize,
        dataSourceType: selectValue === 'all' ? null : selectValue,
        ip: inputValue
      })
    }
  }

  return (
    <div className={styles.searchTableWrap}>
      <div className={styles.headers}>
        <div className={styles.headerLeft}>
          <Select
            defaultValue={selectValue}
            options={dataSourceList}
            bordered={false}
            onChange={(e) => {
              run({
                pageNo: 1,
                pageSize,
                dataSourceType: e === 'all' ? null : e
              })
              setPageNo(1)
              setSelectValue(e)
            }}
            style={{ width: '280px' }}
          />
          {/* <Option value="all">全部</Option>
            <Option value="reverse">按时间倒序筛选</Option> */}
          {/* </Select> */}
          <Input
            prefix={<SearchOutlined className="site-form-item-icon" />}
            placeholder="请输入IP地址"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e?.target?.value)
            }}
          />
          <Button type="primary" className={styles.ml10} onClick={() => {
            run({
              pageNo: 1,
              pageSize,
              dataSourceType: selectValue === 'all' ? null : selectValue,
              ip: inputValue
            })
            setPageNo(1)
          }}>查询</Button>
        </div>
        <div className={styles.headerRight}>
          <Badge count={shopCartData?.totalCount}>
            <Button
              type="primary"
            // className={styles.shoppBtn}
            >
              <Link to="/mine_apply/application">
                <Iconfont type='icon-gouwuche' style={{ color: '#fff', fontSize: '16px' }} />
                <span>  申请单</span>
              </Link>
            </Button>
          </Badge>
        </div>
      </div>
      <Table
        key="connectionId"
        loading={loading}
        rowKey="connectionId"
        className={styles.tablePage}
        expandable={{
          expandedRowRender: (record: any) => (
            <ExpandedRowContent record={record} refresh={shopRefresh} />
          ),
          rowExpandable: (record) => true
        }}
        columns={newColumns}
        dataSource={data?.data}
        size="small"
        scroll={{ x: true, y: `calc(100vh - 290px)` }}
        pagination={{
          current: pageNo,
          pageSize,
          total: data?.totalCount,
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: handleChangePagination,
        }}
      />
    </div>
  )
}


export const ExpandedRowContent = ({ record, refresh }: { record: any, refresh: () => void }) => {
  const {connectionType: dataSourceType} = record
  const [toolsModalVisible, setToolsModalVisible] = useState(false)
  const [editToolNodePathWithType, setEditToolNodePathWithType] = useState('')
  const [templateOperationsSet, setTemplateOperationsSet] = useState<any[]>([])
  const [dataSource, setDataSource] = useState<any[]>([])
  const [templateEnum, setTemplateEnum] = useState<any[]>([])
  const [selectNodeType, setSelectNodeType] = useState<any>({})
	// 查询工具权限模板
	useEffect(()=>{
    const {connectionType: dataSourceType, nodeType: selectNodeType} = (dataSource?.[0]?.children?.length ? dataSource?.[0]?.children?.[0] : dataSource?.[0]) || {}
		if(dataSourceType && selectNodeType){
      setSelectNodeType(selectNodeType)
			queryToolsAuthTemplate(dataSourceType, selectNodeType)
		}
	},[dataSource])
	const queryToolsAuthTemplate = (dataSourceType: string, nodeType: string) =>{
		getToolTemplateInfo(dataSourceType, nodeType).then((res: any)=>{
			setTemplateEnum(res)
		}).catch((err: any)=>{
			console.error('工具权限模板查询失败', err)
		})
	}

  /**
 * 获取子节点
 */
  const { data, run: loadChildren, loading: childLoading, refresh: childRefresh } = useRequest(getAllDataBaseAndSchema, {
    manual: true,
  });

  const { run: addRun, loading: addLoading } = useRequest(addRequisitionToCart, {
    manual: true,
    onSuccess(data) {
      message.success('成功加入申请单')
      childRefresh()
      refresh()
    }
  })

  useEffect(()=> {
    setDataSource(data)
  },[data])

  useEffect(() => {
    if (record?.connectionId) {
      loadChildren({
        connectionId: record?.connectionId,
        connectionType: record?.connectionType,
        nodePathWithType: record?.nodeResponse?.nodePathWithType,
      })
    }
  }, [record])

  // 获取权限等级枚举
  const { data: permissionData = [], run: getPermissionEnum } = useRequest(
    getPermissionTemplateInfo,
    { manual: true },
  )

  useEffect(() => {
    if (dataSourceType) {
      getPermissionEnum(dataSourceType)
    }
  }, [dataSourceType, getPermissionEnum])

  // 修改权限等级
  const handlePermissionChange = async(info: any, item: any) => {
    //判断是否重置
    if (!item?.valid) {
      const resetOrNot = await  resetPermissionModal({
        title:'是否更改权限等级',
        content: '此schema下数据库对象存在其他权限设置,更改权限等级相当于重置此schema下的其他权限设置'
      })
      if (!resetOrNot) {
        return
      }
    }
    setDataSource(d=>{
      d.map(i=>{
        if(i.children?.length){
          i?.children?.map((r: any)=>{
            if(r?.nodePathWithType===item?.nodePathWithType){
              r.permissionType = Number(info?.key)
              r.valid = true // 冲突后修改 清空下层权限
            }
            return {...r}
          })
        }else if(i?.nodePathWithType===item?.nodePathWithType){
          i.permissionType = Number(info?.key)
          i.valid = true // 冲突后修改 清空下层权限
        }
        return {...i}
      })
      return [...d]
    })
  }

  // 工具权限修改
  const updateToolsAuth = (params: any) => {
    setDataSource((d: any[])=>{
      d.map(i=>{
        if(i.children?.length){
          i?.children?.map((r: any)=>{
            if(r?.nodePathWithType===editToolNodePathWithType){
              r.templateOperationsSet = params
            }
            return {...r}
          })
        }else if(i?.nodePathWithType===editToolNodePathWithType){
          i.templateOperationsSet = params
        }
        return {...i}
      })
      return [...d]
    })
  }

  const getPermissionValue = (data: any[], key: string) =>
  data?.filter((i: any) => i?.id === key)?.[0]?.name || '无权限'

  // 编辑工具权限
  const handleEditToolPermission = (item: any) => {
    setEditToolNodePathWithType(item?.nodePathWithType)
    setTemplateOperationsSet(item?.templateOperationsSet)
    setToolsModalVisible(true)
  }

  const newExpandColumns = useMemo(() => {
    const newExpandColumns = resourceExpamdedRow.map((item: any) => {
      if (item?.dataIndex === 'nodeName') {
        return {
          ...item,
          render: (val: any, record: any) => (
            <div>
              <Iconfont
                type={`${record?.nodeType === "datasource"
                  ? `icon-connection-${record?.nodeName}`
                  : record?.nodeType === "group"
                    ? "icon-shujukuwenjianjia"
                    : record?.nodeType === "connection"
                      ? `icon-${record?.dataSourceType}`
                      : `icon-${record?.nodeType}`
                  } `}
              />
              <span>{record?.nodeName}</span>
            </div>
          )
        }
      }
      if (item?.dataIndex === 'actions') {
        return {
          ...item,
          render: (val: any, record: any) => (
            (!record?.children) && <Button
              type='primary'
              disabled={record?.isAdd}
              onClick={() => {
                addRun({
                  flowType: 'FAT',
                  nodeType: record?.nodeType,
                  nodePath: record?.nodePath,
                  nodePathWithType: record?.nodePathWithType,
                  connectionId: record?.connectionId,
                  dataSourceType: record?.connectionType,
                  nodeName: record?.nodeName,
                  templateOperationsSet: record?.templateOperationsSet,
                  permissionType: record?.permissionType
                })
              }}
            >
              加入申请单
            </Button>
          ),
        }
      }
      // 权限等级申请
      if(item?.dataIndex === 'authLevel') {
        const permissionMenu = (record: any) => {
          return (
            <Menu
              className={classnames(styles.optionTxt, styles.menuWrap)}
              onClick={(info) => handlePermissionChange(info, record)}
            >
              {permissionData?.filter((i: any)=>!["DISABLE_TEMPLATE_AND_PERMISSION", "DISABLE_TEMPLATE"].includes(i?.status))?.map((item: any) => (
                <Menu.Item
                  key={item?.id}
                  disabled={
                    ['无权限'].includes(item?.name)
                  }
                >
                  {item?.name}
                </Menu.Item>
              ))}
            </Menu>
          )
        };
        return {
          ...item,
          render: (txt: string, record: any) => (
            (!record?.children) && <>
             <Tooltip  title={!record?.valid && '此用户在此层级下存在其他权限设置'}>
                <span
                  className={classnames({
                    [styles.deleteLine]: !record?.valid,
                    "colore999": ['无权限'].includes(getPermissionValue(permissionData, record?.permissionType))
                  })}
                >
                  {getPermissionValue(permissionData, record?.permissionType)?.length > 10 ? `${getPermissionValue(permissionData, record?.permissionType)?.slice(0,10)}...`:getPermissionValue(permissionData, record?.permissionType)}
                </span>
              </Tooltip>
              <Dropdown overlay={() => permissionMenu(record)} getPopupContainer={() => document.body}>
                <DownOutlined
                  className={classnames(styles.ml10, styles.options)}
                />
              </Dropdown>
            </>
          ),
        }
      }
      // 工具权限申请
      if(item?.dataIndex === 'toolsAuth') {
        return {
          ...item,
          render: (val: any, record: any) => {
            let resultName: any = []
            record?.templateOperationsSet?.forEach((item: any) => {
              const matchedObejct = templateEnum?.find((res:any) => res?.objectType === item?.objectType);
              if (matchedObejct) {
                item?.operations?.forEach((val: any)=>{
                  const matchedItem = matchedObejct?.operations?.find((res:any) => res?.operation === val);
                  if(matchedItem?.operationName){
                    resultName.push(matchedItem?.operationName)
                  }
                })
              }
            });
					  const title = resultName?.join(',') || '-'
            return (
              (!record?.children) && 
              <Tooltip
                title={title}
              >
                <span
                  className={styles.options}
                  onClick={() =>
                    handleEditToolPermission(record)
                  }
                >
                  请选择
                  <DownOutlined className={classnames('ml4 options')} style={{fontSize: '14px'}} />
                </span>
              </Tooltip>
            )
          },
        }
      }
      return item
    })
    return newExpandColumns
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, permissionData, templateEnum])

  return (
    <>
      <Table
        rowKey="nodePath"
        loading={childLoading || addLoading}
        columns={newExpandColumns}
        dataSource={dataSource}
        pagination={false}
        size="middle"
      />
      {/* 工具权限 */}
      {toolsModalVisible && (
        <PermissionLevelModal
          visible={toolsModalVisible}
          optionType="editToolPermission"
          selectNodeType={selectNodeType}
          dataSourceType={dataSourceType}
          setModalVisible={setToolsModalVisible}
          templateOperationsSet={templateOperationsSet}
          callback={updateToolsAuth}
        />
      )}
    </>
  )
}
