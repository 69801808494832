import { Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { editRoleInfo } from 'src/api';
import { InputShowCount, UIModal } from 'src/components';
import { useDispatch, useRequest, useSelector } from 'src/hook';
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice';

interface IProps {
  type: string,
  roleId: string | number,
  setOperationType: (s: string) => void,
  setNewRoleInfo: (a: any) => void,
  [p: string]: any
}


export const AddRoleModal = (props: IProps) => {
  const { type, roleId, roles, setOperationType, setNewRoleInfo, refreshRoles } = props
  const dispatch = useDispatch();
  const { AddRoleModal } = useSelector((state) => state.modal);
  const [roleTemplateOptions, setRoleTemplateOptions] = useState<{
    value: string,
    label: string,
  }[]>([]);
  const [form] = useForm();
  const { TextArea } = Input;

  useEffect(() => {
    if (AddRoleModal) {
      if (type=== "add") {
        form.resetFields()
      } else if (type === "edit") {
        const info = roles?.find((role: any) => Number(role?.roleId) === Number(roleId))
        form.setFields([
          { name: 'roleName', value: info?.roleName },
          { name: 'roleTemplateId', value: info?.roleTemplate },
          { name: 'description', value: info?.description }
        ])
      }
    }
  }, [AddRoleModal])

  useEffect(() => {
    let optionsTmp: any = []
    roles && roles.map((item: any) => {
      if (optionsTmp.length < 6 && [1, 2, 3, 4, 5].includes(item?.roleId)) {
        optionsTmp.push({
          value: item?.type,
          label: item?.roleName,
        })
      }
    })
    setRoleTemplateOptions(optionsTmp)
  }, [roles])

  // 修改角色
  const { run: runEditRoleInfo } = useRequest(editRoleInfo, {
    manual: true,
    onSuccess: (res) => {
      refreshRoles()
    }
  })

  const handleOk = () => {
    form.validateFields().then((values: any) => {
      const {roleName, roleTemplateId, description} = values

      // 直接调接口保存
      if (type === "edit") {
        runEditRoleInfo({
          roleId: roleId,
          roleName: values?.roleName,
          description: values?.description,
        })
      }
      // 给父组件返回 新增角色的信息，该信息与其权限的保存调同一个接口，保存成功即新增成功，否则失败
      if (type === "add") {
        // roleTemplate 角色模板名称
        setNewRoleInfo({
          roleName: roleName,
          roleTemplate: roleTemplateOptions.filter((item: any) => item?.value === roleTemplateId)[0],
          description: description,
          roleId: roles.find((item: any) => item?.type === roleTemplateId)?.roleId,
        })
        form.resetFields();
      }
      setOperationType(type);
      dispatch(hideModal('AddRoleModal'));
    })
  };


  const handleCancel = () => {
    form.resetFields()
    dispatch(hideModal('AddRoleModal'));
  };

  return (
    <>
      <UIModal
        title={
          type === "add" ? "创建角色"
          : type === "edit" ? "修改角色" : ""
        }
        visible={AddRoleModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={type === "add" ? "下一步" : "确定"}
        cancelText="取消"
        width={650}
      >
        <Form
          name="createRole"
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            label="角色名称"
            name="roleName"
            rules={[{ required: true}]}
          >
            <InputShowCount maxLength={20} />
          </Form.Item>
          {
            type === "add" &&
            <Form.Item
              label="角色模板"
              name="roleTemplateId"
              rules={[{ required: true}]}
            >
              <Select
                value={"1"}
                options={roleTemplateOptions}
              />
            </Form.Item>
          }
          <Form.Item
            label="角色描述"
            name="description"
          >
            <TextArea placeholder="这是一段角色描述文案，限制最多40字。" maxLength={40} />
          </Form.Item>
        </Form>
      </UIModal>
    </>
  );
};