import React, { PropsWithChildren } from 'react'
import { Button } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import classNames from 'classnames'
import styles from './bottomToolbar.module.scss'

export const TextButton = (props: PropsWithChildren<ButtonProps>) => {
  const { className, ...rest } = props
  return (
    <Button
      className={classNames(styles.toolbarTextBtn, className)}
      type="text"
      size="small"
      {...rest}
    ></Button>
  )
}
