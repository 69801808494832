import React, { useEffect, useMemo, useState } from 'react';
import { NodeTypeKey } from '../type.d';
import TopDesensScanTaskContent from './TopDesensScanTaskContent';
import DesensScanRuleContent from './DesensScanRuleContent';
import styles from './index.module.scss';
import { useSelector } from 'src/hook';
import { getCurrentModulePermissionByUrl } from 'src/util/utils';

const DesensScanWrapper = ({
    type
}: {
    type: NodeTypeKey
}) => {

  const [isRefreshScanResult, setIsRefreshScanResult] = useState(false)
  return (
      <div className={styles.scanWrapper}>
        <TopDesensScanTaskContent
          type={type}
          onRefreshScanResult={() => setIsRefreshScanResult(!isRefreshScanResult)}
        />
        <DesensScanRuleContent
          type={type}
          isRefresh={isRefreshScanResult}
        />
      </div>
  )
}

export default DesensScanWrapper;