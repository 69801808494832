import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { getPermissionList } from 'src/api'

interface AuthorizationManageState {
  permissionList: any[];
  authorizeUserList: any[];
  selectedTreeNode: any;
  highRiskCallback: () => void
}

const initialState: AuthorizationManageState = {
  permissionList: [],
  authorizeUserList: [],
  selectedTreeNode: null,
  highRiskCallback: () => {}
}

export const authorizationManageSlice = createSlice({
  name: "authorizationManage",
  initialState,
  reducers: {
    setPermissionList: (state, action: PayloadAction<any[]>) => {
      state.permissionList = action.payload;
    },
    setAuthorizeUserList: (state, action: PayloadAction<any[]>) => {
      state.authorizeUserList = action.payload;
    },
    setSelectedTreeNode:  (state, action: PayloadAction<any>) => {

      state.selectedTreeNode = action.payload;
    },
  },
});

export const authorizationManageReducer = authorizationManageSlice.reducer

export const { setPermissionList, setAuthorizeUserList, setSelectedTreeNode} =
  authorizationManageSlice.actions;

export const queryPermissionList =
  (dataSourceType: string): AppThunk =>
    (dispatch) => {
    getPermissionList(dataSourceType).then((res) => {
      dispatch(setPermissionList(res))
    })
  }