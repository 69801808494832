/**
 * 连接池管理-2期
 */
import React, { useEffect, useState } from "react"
import { useRequest } from 'src/hook'
import { Button, Form, Input, Table, Tabs, Tooltip } from 'antd'
import { Iconfont, LinkButton } from "src/components"
import { updateSysSecret, findSysSecret } from "src/api"
import styles from './index.module.scss'

interface PropsI {
  connectionId?: string
  [p: string]: any
}
const ConnectionSecretKeyPage = (props: PropsI) => {
  const { connectionId, permissionlist, canEdit } = props
  const { roleNameList } = permissionlist || {}
  const [form] = Form.useForm()
  const [statusFlag, setStatusFlag] = useState(false)
  const [dataSecret, setDataSecret] = useState('')

  const {refresh, run: getSysSecret} = useRequest(findSysSecret, {
    manual: true,
    onSuccess(data) {
      setDataSecret(data)
      if (!statusFlag) {
        form?.setFieldsValue({ secretKey: '*********' })
      } else {
        form?.setFieldsValue({ secretKey: data })
      }
    }
  })
  const { run: updateSecretRun } = useRequest(updateSysSecret, {
    manual: true,
    onSuccess() {
      refresh()
    }
  })

  useEffect(() => {
    if (!connectionId) return
    getSysSecret({connectionId})
  }, [connectionId])

  useEffect(() => {
    if (statusFlag) {
      form?.setFieldsValue({ secretKey: dataSecret })
    } else {
      form?.setFieldsValue({ secretKey: '*********' })
    }
  }, [statusFlag])

  const randorm16Letters = () => {
    const sourceLetters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const length = sourceLetters.length
    let randLetter = ''
    for (let i = 0; i < 16; i++) {
      randLetter += sourceLetters.charAt(Math.floor(Math.random() * length))
    }
    connectionId && updateSecretRun({ connectionId,  secretKey: randLetter})
    // return randLetter
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item label="变更秘钥">
        <Form.Item
          rules={[{ required: true, message: '不能为空' }]}
          name="secretKey"
          style={{ display: 'inline-block', width: '63%' }}
        >
          <Input disabled />
        </Form.Item>
        {
          canEdit
          ? <Button type='link' onClick={() => setStatusFlag(!statusFlag)}>
              <Iconfont type={`icon-kejian-${statusFlag}`} />
            </Button>
        : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
            <Button type='link' disabled={true}>
              <Iconfont type={`icon-kejian-${statusFlag}`} />
            </Button>
          </Tooltip>
        }
        {
          canEdit
          ? <Button
              onClick={randorm16Letters}
              type="primary"
            >
              变更秘钥
            </Button>
        : <Tooltip title={`您当前的角色是[${roleNameList?.join(',')}]，对[连接管理]没有操作权限`}>
            <Button
              type="primary"
              disabled={true}
            >
              变更秘钥
            </Button>
          </Tooltip>
        }
      </Form.Item>

    </Form>
  )
}
export default ConnectionSecretKeyPage