import React, { FC, useEffect, useState, useMemo } from 'react'
import { Form, message } from 'antd'
import  moment  from 'moment'
import * as _ from 'lodash'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Flow from 'src/components/Process'
import {
  ApplyItem,
  saveAsDraft,
  getDataChangeDetail,
  applyDataChange,
  terminateApplication,
  executeApplication,
  closeApplication,
  executeBack,
  withdrawOrder
} from 'src/api'
import { useRequest } from 'src/hook'
import { DATA_REQUEST_APPLY_TYPES } from 'src/constants'
import { SimpleBreadcrumbs, RcSegmented } from 'src/components'
import {
  DataUpdateForm,
  SQLCheckForm,
  ReviewProcess,
  SubmitApplicationForm,
  ExecuteChange,
  SimuleEnvironmentValidation,
} from "../../components";
import { FormContext } from '../../ApplyFormContext'
import styles from './index.module.scss'

interface IUrlSearchFields {
  reapply?: boolean;
  applyUserId?: string;
  sqlStatement?: string;
  nodePath?: string;
  dataSourceType?: string;
  connectionName?: string;
  connectionId?: number;
}

const applyFlow = [
  {
    title: '申请数据变更',
    iconText: '建',
  },
  {
    title: '连接管理员',
    iconText: '审',
  },
  {
    title: '申请完成',
    iconText: '完',
  },
]

export const Apply: FC = () => {

  const history = useHistory()
  const [form] = Form.useForm()
    //@ts-ignore
    const {state={}} = useLocation();

  const { id, editId } = useParams<{ id?: string;editId?: string }>()
  const isViewDetail = !!id ;
  //statement nodePath  高危申请内容
  const { reapply = false, applyUserId = "", connectionId, sqlStatement, nodePath, connectionName, dataSourceType } = state as IUrlSearchFields

  //审批主流程 通过状态控制吧
  const [currentApprovalStep, setCurrentApprovalStep] = useState(0)
  //当前申请流程
  const [currentStep, setCurrentStep] = useState(0)
  //存储当前数据
  const [currentContextValue, setCurrentContextValue] = useState<
    ApplyItem | any
    >({type: 1, sqlType: 1})
  const [pageType, setPageType ] = useState<number>(1)

  //申请详情
  const { run: runGetDataChangeDetail, refresh } = useRequest(getDataChangeDetail, {
    manual: true,
    onSuccess: (data:ApplyItem) => {
      if (data) {
        form.setFieldsValue({
          ...data,
          executeTime: data?.executeTime ?moment(data.executeTime) : null
        })
      }
     
      setCurrentContextValue({...data,executeTime: data?.executeTime ?moment(data.executeTime) : null})
      if (data?.executeStatus === '审批中') {
        setCurrentApprovalStep(1)
        setCurrentStep(1)
      }else if (data?.executeStatus && ['草稿','已撤回'].includes(data?.executeStatus)) {
        setCurrentApprovalStep(0)
        setCurrentStep(1)

      } else {
        setCurrentApprovalStep(2)
        setCurrentStep(2)
      }
    },
  })


  //暂存草稿
  const { run: runSaveAsDraft } = useRequest(saveAsDraft, {
    manual: true,
    onSuccess: () => {
      message.success('提交成功')
      history.push('/data_change_mine_apply')
    },
  })
  const { run: runApplyDataChange } = useRequest(applyDataChange, {
    manual: true,
    onSuccess: () => {
      message.success('提交成功')
      history.push('/data_change_mine_apply')
    },
  })

  //撤回
  const { run: runWithdrawRequest } = useRequest(withdrawOrder, {
    manual: true,
    onSuccess: () => {
      message.success('撤回成功')
      history.go(-1)
    },
  })

  //执行
  const { run: runExecuteApplication } = useRequest(executeApplication, {
    manual: true,
    onSuccess: () => {
      refresh();
    }
  })
  //中止
  const { run: runTerminateApplication } = useRequest(terminateApplication, {
    manual: true,
    onSuccess: () => {
      refresh();
    }
  })

  //终止
  const { run: runCloseApplication } = useRequest(closeApplication, {
    manual: true,
    onSuccess: () => {
      history.go(-1)
    }
  })
  //回退
  const { run: runExecuteBack } = useRequest(executeBack, {
    manual: true,
    onSuccess: () => {
      refresh();
    }
  })

  //高危申请赋值
  useEffect(() => {

    if(sqlStatement && nodePath) {
      setCurrentContextValue({
        nodePath,
        sqlStatement,
        dataSourceType,
        connectionName,
        sqlType: 1,
        type: 1,
        connectionId
      })
      form.setFieldsValue({
        nodePath,
        sqlStatement,
        sqlType: 1
      })
    }
  },[sqlStatement, nodePath, dataSourceType, connectionName,connectionId])

  useEffect(() => {

    if (isViewDetail || editId) {
      const idPar:string | undefined = id || editId;
      idPar && runGetDataChangeDetail(idPar)
    }else {
      form.setFieldsValue({type: 1})
    }
  }, [isViewDetail,editId])

  const onSubmitApplicationForm = (type: 'finalize' | 'draft', params: any) => {
    //如果是再次申请不需要id
    if (reapply) {
      delete currentContextValue?.id
    }
    params.rollbackSqlType = params.sqlType;

    if (type === 'finalize') {
      const paraments = { ...params, id: currentContextValue?.id };
      runApplyDataChange(paraments);
    } else {
      const paraments = { ...params, id: editId };
      runSaveAsDraft(paraments);
    }
  }
  const APPLY_TYPES_OPTIONS = (() => {
    return Object.keys(DATA_REQUEST_APPLY_TYPES).map((key) => ({
      //@ts-ignore
      label: DATA_REQUEST_APPLY_TYPES[key],
      value: Number(key),
      //@ts-ignore
      // disabled: Number(key) === 2,
    }))
  })()

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '我的申请',
      href: '/data_change_mine_apply',
      onclick: () => history.go(-1)
    },
    {
      separator: '/',
      title: isViewDetail || editId ? '工单详情' : '申请变更',
    },
  ]

  const applicantStepItems = [
    {
      description: (
        <DataUpdateForm
          form={form}
          itemStepMark={0}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          isEdit={!!editId}
          pageType={pageType}
          currentRouteType='MINE_APPLY'
        />
      ),
    },
    {
      hidden: !(editId || !isViewDetail ),
      description: (
        <SQLCheckForm
          itemStepMark={1}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
        />
      ),
    },
    // 模拟环境验证
    {
      hidden:  !((editId && currentContextValue?.type === 2) ||  (!isViewDetail && currentContextValue?.type === 2)) ,
      description: (
        <SimuleEnvironmentValidation
          form={form}
          itemStepMark={2}
          currentStep={currentStep}
          applyUserId={applyUserId}
          currentRouteType='MINE_APPLY'
        />
      ),
    },
    {
      hidden: !(!isViewDetail || editId),
      description: (
        <SubmitApplicationForm
          form={form}
          itemStepMark={currentContextValue?.type === 1 ? 2: 3}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          isEdit={!!editId}
          onSubmitApplication={(type, params: ApplyItem) =>
            onSubmitApplicationForm(type, params)
          }
        />
      ),
    },
    //查看
    {
      hidden: !isViewDetail,
      description: (
        <ReviewProcess
          itemStepMark={1}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType="MINE_APPLY"
          onWithDrawRequest={(flowId: number) =>
            runWithdrawRequest({flowId,isMyApply: true })
          }
        />
      ),
    },
    {
      hidden: !isViewDetail,
      description: (
        <ExecuteChange
          itemStepMark={2}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType={"MINE_APPLY"}
          onExecuteApplication={(id: number) => runExecuteApplication(id)}
          onTerminateApplication={(id: number) => runTerminateApplication(id)}
          onCloseApplication={(id: number) => runCloseApplication(id)}
          onExecuteBack={(id: number) => runExecuteBack(id)}
          onRefresh={() => refresh()}
        />
      ),
    },
  ];

  const handleTypeChange = (value: any) => { 
    setPageType(value)
    setCurrentStep(0);
    setCurrentContextValue({
      ...currentContextValue,
      type: value,
      dataSourceType: '',
      simulateNodePath: '',
      nodePath: '',
      skip2Step: false
    })
    form.setFieldsValue({
      simulateNodePath: '',
      nodePath: ''
    })
  }

  const filterApplyFlowStep = useMemo(() => {
    let cloneApplyFlowSteps = _.cloneDeep(applyFlow);
    if (isViewDetail && currentContextValue?.executeStatus === '已撤回') {
      cloneApplyFlowSteps = cloneApplyFlowSteps.splice(0,1);
    }
    return cloneApplyFlowSteps;
  },[currentContextValue?.executeStatus,isViewDetail])

  return (
    <div
      style={{
        height: '100%',
        // overflowY: "auto",
        backgroundColor: "#fff",
      }}
    >
      <FormContext.Provider
        value={{
          form,
          currentContextValue,
          setCurrentContextValue,
          setCurrentStep,
        }}
      >
        <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
        <div className={styles["application-detail"]}>
        {!isViewDetail &&
          <Form form={form} name="basic">
            <Form.Item name="type" initialValue={1}>
              <RcSegmented options={APPLY_TYPES_OPTIONS} onChange={handleTypeChange} />
            </Form.Item>
          </Form>
        }
          <Flow
            items={filterApplyFlowStep}
            current={currentApprovalStep}
            className={styles["approval-step"]}
          />
          <div className={styles.applyContent}>
            <Flow
              items={applicantStepItems}
              current={currentStep}
              direction="vertical"
            />
          </div>
        </div>
      </FormContext.Provider>
    </div>
  );
}

export default Apply
