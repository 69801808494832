// 访问申请-我的审批-审批单（申请资源：仅展示）
import React, { FC, useState, useEffect } from 'react'
import { Card, Table } from 'antd'
import { columnsDetail, detailExpamdedRow, detailFineGritExpamdedRow } from '../../common/columns'
import { getToolTemplateInfo } from 'src/api'
import styles from '../index.module.scss'

interface ListProps {
	data: any,
	priGranType: string
}

export const ResourceBidPage: FC<ListProps> = (props) => {
	const { data, priGranType } = props
	const { dataSourceType, nodeType } = data?.dataList?.[0]?.businessDataListNoChild?.[0] || {}

	const [templateEnum, setTemplateEnum] = useState<any[]>([])

	// 查询工具权限模板
	useEffect(()=>{
		if(dataSourceType && nodeType){
			queryToolsAuthTemplate(dataSourceType, nodeType)
		}
	},[dataSourceType, nodeType])
	const queryToolsAuthTemplate = (dataSourceType: string, nodeType: string) =>{
		getToolTemplateInfo(dataSourceType, nodeType).then((res: any)=>{
			setTemplateEnum(res)
		}).catch((err: any)=>{
			console.error('工具权限模板查询失败', err)
		})
	}

	const expandedRowRender = (record: any) => {
		const newColumns = (priGranType === 'THIN' ? detailFineGritExpamdedRow : detailExpamdedRow)?.map((item: any)=>{
			// 工具权限申请
		 if(item?.dataIndex === 'toolsAuth') {
			 return {
				 ...item,
				 render: (val: any, record: any) => {
					 let resultName: any = []
					 record?.templateOperationsSet?.forEach((item: any) => {
						 const matchedObejct = templateEnum?.find((res:any) => res?.objectType === item?.objectType);
						 if (matchedObejct) {
							 item?.operations?.forEach((val: any)=>{
								 const matchedItem = matchedObejct?.operations?.find((res:any) => res?.operation === val);
								 if(matchedItem?.operationName){
									resultName.push(matchedItem?.operationName)
								 }
							 })
						 }
					 });
					 return resultName?.join(',') || '-'
				 },
			 }
		 }
		 return item
	 })
		return <Table columns={newColumns} dataSource={record?.businessDataListNoChild} pagination={false} size="middle" />;
	}

	const scrollX = columnsDetail?.length * 100;

	return (
		<Card
			title="申请资源"
			className={styles.borderShow}
		>
			<Table
				rowKey="id"
				className={styles.tablePage}
				expandable={{
					expandedRowRender: (record) => {
						return expandedRowRender(record)
					},
					// expandIcon: ({ expanded, onExpand, record }) =>
					// 	expanded ? (
					// 		<DownOutlined onClick={(e) => onExpand(record, e)} />
					// 	) : (
					// 		<RightOutlined onClick={(e) => onExpand(record, e)} />
					// 	),
				}}
				columns={columnsDetail}
				dataSource={data?.dataList}
				size="small"
				scroll={{ x: scrollX, y: 490 }}
			/>
		</Card>
	)
}
