import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Chart } from "@antv/g2";
import { DownOutlined } from "@ant-design/icons";
import * as _ from "lodash";
import DataSet from "@antv/data-set";
import { Spin } from "antd";
import dayjs from "dayjs";
import { getStartAndEndTime, formattedTimeAliasValue, getDrillStartAndEndTime } from "./utils";
import { EmptyChart } from "../Charts";
import chartStyles from "./chart.module.scss";
import { judgeIsNoData } from "../../utils";

interface ISqlCountProps {
  chartData: any;
  loading: boolean;
  currentTime: any;
  drillFilterParams: any;
  onChangeDrillParams: (params: any) => void;
  onUpdateDrillPaths: (path: any) => void;
}

let chart: any;
export const SqlCountChart = ({
  chartData,
  loading,
  currentTime,
  drillFilterParams,
  onChangeDrillParams,
  onUpdateDrillPaths,
}: ISqlCountProps) => {
  const history = useHistory();

  //单独自己的状态
  const TIME_UNIT_MAPPING = {
    QUARTER: "MONTH",
    MONTH: "WEEK",
    WEEK: "DAY",
    DAY: "HOUR",
  };
  const OBJECT_FILTER_TYPE_MAPPING: { [k: string]: string } = {
    dbTypes: "connectionName",
    connectionName: "databaseName",
    databaseName: "objectNames",
  };
  const OBJECT_UNIT_MAPPING: { [k: string]: string } = {
    DBTYPE: "CONNECTION",
    CONNECTION: "DATABASE",
    DATABASE: "TABLE",
  };
  const ORG_UNIT_MAPPING: { [k: string]: string } = {
    COMPANY: "DEPT",
  };
  const HIGH_RISK_MAPPING = {
    all: 0,
    risk: 1
  };
  const getFormattedAlias = (alias: any) => {
    const realAlias = alias && alias.split("---");

    return realAlias[0] && realAlias[0] !== "null"
      ? realAlias[0]
      : realAlias[1];
  }
  const renderSqlCountChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
      theme: {
        maxColumnWidth: 40,
      },
    });

    const dbTypeWeightByTotal = data
      .reduce((prev, curr) => {
        const record = prev.find((record: any) => record.type === curr.type);
        if (!record) return prev.concat({ ...curr });
        record.amount += curr.amount;
        return prev;
      }, [])
      .sort((a: any, b: any) => b.amount - a.amount)
      .map((record: any) => record.type)
      .reverse();
   
    data = new DataSet.DataView()
      .source(data)
      .transform({
        type: "fill-rows",
        groupBy: ["type"],
        orderBy: ["posX", "alias"],
        fillBy: "order",
      })
      .transform({
        type: "impute",
        field: "amount",
        groupBy: ["type", "alias"],
        method: "value",
        value: 0,
      })
      .rows.filter(({ type }) => type)
      .sort((a, b) => { 
        // 双条件排序：日期升序，日期一样，总数更大的数据库类型靠前
        if (a.posX === b.posX) {
          return (
            dbTypeWeightByTotal.indexOf(b.amount) -
            dbTypeWeightByTotal.indexOf(a.amount)
          );
        }
        return a.posX < b.posX ? -1 : 1;
      })
      .map(({ alias, posX, ...rest }) => {
        let newAlias = alias;
        //不存在alias的特殊处理
        const realAlias = alias && alias.split("---");
   
        if (
          drillFilterParams?.posXDim === "TIME" && 
          realAlias[0] === "null"
        ) {
          newAlias =formattedTimeAliasValue(drillFilterParams?.timeUnit, posX);
        }

        return {
          alias: newAlias,
          ...rest,
          posX,
        };
      });

    chart.data(data);
    chart.axis("alias", {
      label: {
        autoRotate: false, // 禁用自动旋转
        autoHide: true, // 启用自动隐藏
        autoEllipsis: true, // 启用自动省略
        offset: 10, // 调整偏移量
      },
    });
    // 看看需不需要
    chart.option("slider", {
      formatter: (v: any) => {
        return getFormattedAlias(v)
      },
    });
    chart.scale({
      amount: {
        minTickInterval: 1,
        nice: true,
        range: [0, 0.95],
        formatter: (v) => (v || 0)?.toLocaleString() + " 次",
      },
      alias: {
        field: "alias",
        formatter: (v) => {
           
          return getFormattedAlias(v.toLocaleString())
        },
      },
    });

    chart.tooltip({
      enterable: true,
      showCrosshairs: true,
      shared: true,
      domStyles: { "g2-tooltip-list-item": { cursor: "pointer" } },
    });

    chart.axis("amount", {
      label: {
        formatter: (val) => {
          return val;
        },
      },
    });

    chart.interaction("active-region");
    chart.interval().position("alias*amount").color("type").adjust("stack");


    // 点击事件，这里仅对不可点击的时候生效，做点击到最后一层跳转到对应语句明细功能
    chart.on("element:click", (ev: any) => {
      const { supportDrillDown, posX } = ev?.data?.data;
      if (!supportDrillDown) {
        const { posXDim, highRiskFlag } = drillFilterParams || {};
        const stateParams = {
          highFlag: HIGH_RISK_MAPPING[highRiskFlag as 'all' | 'risk']
        } as any;

        switch (posXDim) {
          case "TIME":
            stateParams.timeRange = [
              dayjs(posX).valueOf(),
              dayjs(posX).add(1, 'hour').valueOf(),
            ];
            break;
          case "ORG":
            stateParams.executors = [posX];
            break;
          case "OBJECT":
            stateParams.objectNames = [posX];
            break;
        };
        history.push({
          pathname: `/statement_detail`,
          state: stateParams,
        })
      }
    })
    
    // 监听 tooltip 的点击事件

    chart.on("element:click", (ev: any) => {
      const { supportDrillDown, posX, alias, uuid } = ev?.data?.data;
      if (!supportDrillDown) return;
      const {timeUnit, orgUnit, dbObjects, curDrillType} = drillFilterParams
      let params: any = {
        ...drillFilterParams,
        alias:getFormattedAlias(alias),
      };

      if (drillFilterParams?.posXDim === "TIME") {
        //@ts-ignore
        params.timeUnit = TIME_UNIT_MAPPING[timeUnit];
        params.drillTime = posX;
      }
      if (drillFilterParams?.posXDim === "ORG") {
        params.orgIds = [posX];
        params.orgUnit = ORG_UNIT_MAPPING[orgUnit];
      }
      if (drillFilterParams?.posXDim === "OBJECT") {
        const dbObjectUnit = drillFilterParams?.dbObjectUnit || "DBTYPE";
        params.dbObjectUnit = OBJECT_UNIT_MAPPING[dbObjectUnit];
        let dbObjectItem = dbObjects?.[0] || {DBTYPE: posX};
        params.curDrillType = OBJECT_FILTER_TYPE_MAPPING[curDrillType]
        params.objLinkParams = {
          ...(drillFilterParams?.objLinkParams || {}),
          [curDrillType]:[getFormattedAlias(alias)]
        }
        dbObjectItem = {
          ...dbObjectItem,
          [dbObjectUnit]: posX,
        };
        params.dbObjects = [dbObjectItem];
      }

      onUpdateDrillPaths(params);
    });
    chart.on("tooltip:show", (ev: any) => {
      let items = ev?.data?.items;
      if (!items?.length) {                                            
        items = [];
        return;
      }
      const tooltipEl = chart.ele.querySelector(".g2-tooltip"); // 获取 tooltip 的 DOM 元素
      const items1 =
        tooltipEl && tooltipEl.querySelectorAll(".g2-tooltip-list-item"); // 获取所有的 tooltip 内容元素

      items1 &&
        items1.forEach((item: any, index: number) => {
          item.setAttribute("data-index", index); // 为每个元素添加一个 data-index 属性

          const handler = () => {
            const data = ev.data.items[index]; // 获取对应的数据
            const { posXDim, curDrillType, objLinkParams, orgUnit, highRiskFlag } = drillFilterParams;
            let { beginTimeMs, endTimeMs } = getDrillStartAndEndTime(
              drillFilterParams?.timeUnit,
              data?.data?.posX
            ) as any;

           if (drillFilterParams?.posXDim !== 'TIME') {
              beginTimeMs = currentTime?.beginTimeMs ||  Number(dayjs().startOf("year").format("x"));
              endTimeMs = currentTime?.endTimeMs || Number(dayjs().endOf("year").format("x"));
           }
       
            let stateParams: any = {
              timeRange: [beginTimeMs, endTimeMs],
              sqlTypes: [data.name],
              highFlag: HIGH_RISK_MAPPING[highRiskFlag as 'all' | 'risk']
            } 
            if (posXDim === 'OBJECT') {
              stateParams = {
                ...stateParams,
                [curDrillType]: data?.title && [data.title],
                ...objLinkParams
              }
            }
        
            if (posXDim === 'ORG' && orgUnit !== 'COMPANY') {
              const {originType, posX} = data?.data || {};

              stateParams = {
                ...stateParams,
                ...(originType === 'USER' ? {executors: [posX]}: {depts: [data.title]})
              }
            }
        
             history.push({
              pathname: `/statement_detail`,
              state: stateParams,
            });
          };
          item.addEventListener("click", handler);
          item.__handler__ = handler; // 将事件处理函数保存在元素上
        });
    });
    chart.on("tooltip:hide", (ev: any) => {
      const tooltipEl = chart.ele.querySelector(".g2-tooltip"); // 获取 tooltip 的 DOM 元素
      const items =
        tooltipEl && tooltipEl.querySelectorAll(".g2-tooltip-list-item"); // 获取所有的 tooltip 内容元素

      items &&
        items.forEach((item, index) => {
          item.removeAttribute("data-index"); // 移除 data-index 属性
          //@ts-ignore
          item.removeEventListener("click", item._handler_); // 移除 click 事件监听器
          //@ts-ignore
          delete item.__handler__; // 删除保存在元素上的事件处理函数
        });
    });
    chart && chart.render();
    return chart;
  };

  useEffect(() => {
    if (!chartData || !chartData[0] || judgeIsNoData(chartData)) return;
  
    if (chart) {
      chart.changeData(chartData);
    } else {
      chart = renderSqlCountChart("drill-down-sql-count", chartData);
    }
    return () => {
      chart && chart.destroy();
      chart = null;
    };
  }, [JSON.stringify(chartData), loading]);

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.sqlCountWrapper}>
        <div id="drill-down-sql-count" className={chartStyles.sqlCount}>
          {((!chartData[0] && !loading) || judgeIsNoData(chartData)) && <EmptyChart></EmptyChart>}
        </div>
        {!_.isEmpty(chartData) && !judgeIsNoData(chartData) &&
        ((drillFilterParams?.timeUnit === 'QUARTER' && drillFilterParams?.posXDim === 'TIME') ||
          (drillFilterParams?.posXDim === 'ORG' && drillFilterParams?.orgUnit === 'COMPANY')) && (
            <div
              className={chartStyles.more}
              onClick={() => {
                if (drillFilterParams?.posXDim === "TIME") {
                  onChangeDrillParams({
                    timeUnit: "ALL_MONTH",
                  });
                  onUpdateDrillPaths({
                    timeUnit: "ALL_MONTH",
                    ...getStartAndEndTime("ALL_MONTH", ""),
                    alias: "所有月份",
                  });
                }
                if (
                  drillFilterParams?.posXDim === "ORG" &&
                  drillFilterParams?.orgUnit === "COMPANY"
                ) {
                  onChangeDrillParams({
                    OrgUnit: "DEPT",
                  });
                  onUpdateDrillPaths({
                    orgUnit: "DEPT",
                    alias: "所有部门",
                  });
                }
              }}
            >
              展开所有
              <DownOutlined />
            </div>
          )}
      </div>
    </Spin>
  );
};
