import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { getCommonlyUsedConnections, UserConnectionInfo } from 'src/api'
import { LinkButton, UICard } from 'src/components'
import { UserConnectionInfoColumns } from 'src/constants'
import { useDispatch, useRequest } from 'src/hook'
import { addPane } from '../queryPage/queryTabs/queryTabsSlice'

export const CommonlyUsedConnections = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const columnsWithAction: ColumnsType<UserConnectionInfo> = [
    ...UserConnectionInfoColumns,
    {
      title: '',
      dataIndex: 'action',
      render: (_, record) => {
        const { connectionId, connectionName, connectionType, deleted } = record
        return (
          <>
            <LinkButton
              onClick={() => {
                dispatch(
                  addPane({
                    tabName: connectionName,
                    connectionType,
                    connectionId,
                  })
                );
                history.push("/system_data_operate");
              }}
              disabled={deleted}
            >
              快捷打开
            </LinkButton>
          </>
        )
      },
      width: '80px',
    },
  ]

  const { data = { connectionInfo: [] }, loading } = useRequest(() =>
    getCommonlyUsedConnections({
      daysSpan: 30,
      pagination: { page: 1, pageSize: 999 },
    }),
  )

  return (
    <UICard title="常用连接" bodyStyle={{ minHeight: 509 }}>
      <Table
        size="small"
        columns={columnsWithAction}
        dataSource={data.connectionInfo}
        loading={loading}
      ></Table>
    </UICard>
  )
}
