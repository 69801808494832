import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { FlowFormTrackingContext, FlowType } from 'src/types'

const initialState: InitialState = {
  visibleFlows: [],
}

export const flowFormsSlice = createSlice({
  name: 'flowForms',
  initialState,
  reducers: {
    openFlowForm(state, action: PayloadAction<FlowFormTrackingContext>) {
      const { type } = action.payload
      if (!state.visibleFlows.includes(type)) {
        // 打开指定表单
        state.visibleFlows.push(type)
      }
      // 设置表单回填上下文
      state.trackingContext = action.payload
    },
    closeFlowForm(state, action: PayloadAction<FlowType | undefined>) {
      if (!action.payload) {
        // 关闭所有表单
        state.visibleFlows = []
        return
      }
      // 关闭指定表单
      state.visibleFlows = state.visibleFlows.filter(
        (el) => el !== action.payload,
      )
    },
  },
})

export const { openFlowForm, closeFlowForm } = flowFormsSlice.actions

export const flowFormsReducer = flowFormsSlice.reducer

// types
interface InitialState {
  visibleFlows: FlowType[]
  trackingContext?: FlowFormTrackingContext
}
