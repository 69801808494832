export const priGranTypeMap: Record<any, string> = {
  THIN: '操作提权',
  FAT: '访问申请',
  highRisk: '高危申请',
  desensitizedResource: '敏感申请',
  exportTask: '导出申请'
}

export type PriGranType = keyof typeof priGranTypeMap;

export const applyStatusMap: Record<any, string> = {
  pending: '待审核',
  already: '已完成',
  power: '待落权',
  rejected: '已拒绝',
  pass: '已通过',
  refuse: '已驳回',
  stop: '已中止',
  withdraw: '已撤回',
}

//不需要落权
export const NO_NEED_TO_LAND_TYPES = ['desensitizedResource', 'highRisk', 'exportTask'];

//需要 附件上传
export const NEED_TO_UPLOAD_FILE = ['exportTask'];


export const APP_EFFECTIVE_STATES = {
 EXPIRED: '已失效',
 PENDING: '待生效',
 ACTIVE: '生效中',
}

export type EffectStatusType =  '待生效' | '生效中' | '已失效'

export type MyApplyTabKey = 'pending' | 'pass' | 'power' | 'already' | 'withdraw';
export type MyApplyTabKeyType = keyof typeof MY_APPLY_TABS;

export const MY_APPLY_TABS: Record<any, string> = {
  pending: '待审核',
  pass: '已审批',
  power: '待落权',
  already: '已完成',
  withdraw: '已撤回',
}

export type MyApprovalTabKey = 'stayApprove' | 'yetApprove' | 'power' | 'finish' | 'withdraw';
export type MyApprovalTabKeyType = keyof typeof MY_APPROVAL_TABS;

export const MY_APPROVAL_TABS: Record<any, string> = {
  stayApprove: '待审批',
  yetApprove: '已审批',
  power: '待落权',
  finish: '已完成',
  withdraw: '已撤回',
}

export type HIGH_RISK_BASIC_FIELD = keyof typeof MY_APPROVAL_TABS;

export const HIGH_RISK_BASIC_INFO: Record<any, string> = {
  applyUserName: '申请人',
  applyUserDept: '所在部门',
  title: '标题',
  createTime: '申请时间',
  dataSourceType: '申请类型',
  connectionName: '数据源连接',
  nodePathWithTypeList: '数据库元素',
  operationList: '操作类型',
  remark: '申请原因',
  time: '生效时间',
  flowType: '申请类型',
  executeText: '执行SQL',
  exportNumLimit: '导出行数',
}

//申请单 基本信息
export const BASIC_ODD_FIELDS = [
  'time',
  'title',
  'remark'
]

export const HIGH_RISK_ODD_FIELDS = [
  'applyUserName',
  'applyUserDept',
  'title',
  'createTime',
  'flowType',
  'connectionName',
  'nodePathWithTypeList',
  'operationList',
  'remark',
  'time',
]

export const EXPORT_TASK_ODD_FIELDS = [
  'applyUserName',
  'applyUserDept',
  'title',
  'createTime',
  'flowType',
  'connectionName',
  'nodePathWithTypeList',
  'executeText',
  'exportNumLimit',
  'remark'  
]

export const DESENSITIZED_RESOURCE_ODD_FIELDS = [
  'applyUserName',
  'applyUserDept',
  'title',
  'createTime',
  'flowType',
  'connectionName',
  'nodePathWithTypeList',
  'remark',
  'time',
]