/*
 * @Author: fuzhenghao
 * @Date: 2022-11-18 14:09:41
 * @LastEditTime: 2022-11-30 17:49:09
 * @LastEditors: fuzhenghao
 * @Description: 流程-我的申请-查看过程-超级管理员的数据脱敏提权审批-流程进度
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\flowDetails\newFlowProcess.tsx
 *
 * @notice: !!!修改代码逻辑请注意
 *
 * @产品经理: 缪睿
 * @前端开发: 付政昊
 * @后端开发: 邢利民
 * @测试人员: 朱以训
 * @讨论时间: 2022.11.21
 * @接口文档: 无
 * @同产品经理的讨论:
 *    前端给出解决方案: 1.后端使用bmpn传回设计流程中的流程节点在前端进行还原 2.后端传递图片流前端进行图片还原
 *    产品经理方案方案: 流程进度相较于之前有明显数据结构改变,总体风格样式使用antd组件维持展现主流程,同之前实现方式相同
 * @探讨点:
 *    前端关注点: 1.由于流程模板是由用户自定义,只展现主流程可能会出现流程实例展示不全面或无法满足的问题
 *                2.可以用一种更为全面的方式规避后续修改代码,投入人手持续消耗开发时间的问题
 *    产品经理关注点: 1.用户数量少
 *                    2.只需要展现主流程,不需要进行整个流程的展现
 * @探讨结果: 使用产品经理给出建议实现, 后续如需展现形式变动, 或出现上述讨论中的矛盾点请同方案提出人讨论
 *
 */
import React, { useState } from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import classNames from 'classnames'
import styles from './flowDetail.module.scss'
import { Button, Descriptions, Divider, message, Popover, Steps } from 'antd'
import {
  TCustomDot,
  INewFlowProcess,
  FlowNodeType,
  FlowNodeResult,
  TStepStats,
  TFlowNodeResult,
} from './newFlowProcess.interface'

dayjs.extend(relativeTime)

function resultToStepState(approvedResult: TFlowNodeResult) {
  let stepStatus: TStepStats
  if (
    approvedResult === 'orSignPass' ||
    approvedResult === 'signPass' ||
    approvedResult === 'pass' ||
    approvedResult === 'withdraw'
  ) {
    stepStatus = 'finish'
  } else if (
    approvedResult === 'orsignRefuse' ||
    approvedResult === 'refuse' ||
    approvedResult === 'signRefuse'
  ) {
    stepStatus = 'error'
  } else {
    stepStatus = 'wait'
  }
  return stepStatus
}

export const NewFlowProcess = (props: INewFlowProcess) => {
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [currentApprover, setCurrentApprover] = useState<number>(0)

  const customDot = (dot: React.ReactNode, e: TCustomDot) => {



    let { status, index } = e
    if (
      status === 'signRefuse' ||
      status === 'orsignRefuse' ||
      status === 'refuse'
    ) {
      return (
        <div
          className={classNames(
            index === currentStep ? styles.processDotActive : styles.processDot,
            styles.processDotError,
          )}
        ></div>
      )
    }
    if (status === 'signPass' || status === 'orSignPass' || status === 'pass' || status === 'withdraw') {
      return (
        <CheckCircleFilled
          className={classNames(
            index === currentStep ? styles.processDotActive : styles.processDot,
            styles.processDotFinish,
          )}
        />
      )
    }

    if (status === 'pending') {
      return (
        <CheckCircleFilled
          className={classNames(
            index === currentStep ? styles.processDotActive : styles.processDot,
            styles.processDotWaiting,
          )}
        />
      )
    }
    return dot
  }

  const onChangeStep = (currentStep: number) => {
    setCurrentStep(currentStep)
  }

  const onChangeApprover = (currentApprover: number) => {
    setCurrentApprover(currentApprover)
  }

  let { steps } = props

  return steps ? (
    <>
      <Steps
        className={styles.steps}
        size="small"
        progressDot={customDot}
        onChange={onChangeStep}
        current={currentStep}
      >
        {steps.map((step: any, index: any) => {
          let { flowNodeResult, flowNodeName, flowNodeType, approvers } = step
          return (
            <>
              <Steps.Step
                key={index}
                status={flowNodeResult}
                description={
                  <div
                    className={classNames(
                      styles.stepItem,
                      // index > (lastPassed || 0) + 1 && styles.stepItemWait,
                      // stepStatus === 'error' && styles.stepItemError,
                    )}
                  >
                    <div className={classNames(styles.stepTitle)}>
                      {flowNodeName}
                    </div>
                  </div>
                }
              />
            </>
          )
        })}
      </Steps>
      <Divider></Divider>
      <div className="steps-content">
        <Descriptions title="当前节点审批信息">
          <Descriptions.Item label="节点审批类型">
            {FlowNodeType[steps[currentStep]?.flowNodeType]}
          </Descriptions.Item>
          <Descriptions.Item label="节点审批状态">
            {FlowNodeResult[steps[currentStep]?.flowNodeResult]}
          </Descriptions.Item>
        </Descriptions>

        <div className={styles.approver}>
          <Steps
            current={currentApprover}
            size="small"
            onChange={onChangeApprover}
            direction="vertical"
          >
            {steps[currentStep]?.flowProcessInfos?.map((approver) => {
              let { commitUserId, approvedUser, approvedResult } = approver
              let title
              if (commitUserId) {
                title = `申请提交人:\n${approvedUser}\n${FlowNodeResult[approvedResult]}`
              } else {
                title = `申请审核人:\n${approvedUser}\n${FlowNodeResult[approvedResult]}`
              }

              let stepStatus: TStepStats = resultToStepState(approvedResult)

              return <Steps.Step status={stepStatus} title={title} />
            })}
          </Steps>
          {/* {FlowNodeType} */}
          {/* <Descriptions title="当前节点信息">
            <Descriptions.Item label="节点审批类型">
              {steps[currentStep].flowNodeType}
            </Descriptions.Item>
            <Descriptions.Item label="节点审批状态">
              {steps[currentStep].flowNodeResult}
            </Descriptions.Item>
          </Descriptions> */}
        </div>
      </div>
      {/* <div className="steps-action">
        {currentStep < steps.length - 1 && (
          <Button type="primary" onClick={next}>
            下一级节点
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success('Processing complete!')}
          >
            完成
          </Button>
        )}
        {currentStep > 0 && (
          <Button style={{ marginLeft: 8 }} onClick={prev}>
            上一级节点
          </Button>
        )}
      </div> */}
    </>
  ) : null
}
