import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'

interface FavoriteContent {
  statementName?: string
  statement?: string
  digestStatement?: string
}

interface FavoriteStatus {
  updateTime?: string
  selectedNum?: number
}

export interface FavoriteEntity extends FavoriteContent, FavoriteStatus {
  id?: number
  userId?: string
}
type SearchScope = 'ALL' | 'STATEMENTNAME' | 'STATEMENT'
interface SearchFavoritesRequest {
  limit?: number
  keyword?: string
  scope?: SearchScope
}

export const saveFavorite = (
  favorite: FavoriteEntity,
): Promise<FavoriteEntity> => {
  return fetchPut(`/user/collect/saveCollect`, favorite)
}

export const searchFavorites = (
  searchParams: SearchFavoritesRequest = {
    limit: 0,
    keyword: '',
    scope: 'ALL',
  },
): Promise<FavoriteEntity[]> => {
  const { limit = 0, keyword = '', scope = 'ALL' } = searchParams
  return fetchPost(`/user/collect/searchCollect`, {
    limit,
    keyword,
    scope,
  } as SearchFavoritesRequest)
}

export const deleteFavorite = (id: number) => {
  return fetchDelete(`/user/collect/deleteCollect/${id}`)
}

export const updateFavoriteStatus = (id: number) => {
  return fetchPost(`/user/collect/selectCollect/${id}`)
}

export const checkFavoriteName = (statementName: string) => {
  return fetchGet(`/user/collect/checkStatementName/${statementName}`)
}
