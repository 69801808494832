import { PaginatedFormatReturn } from "@ahooksjs/use-request/lib/types";
import { Badge, Popconfirm, Progress, Space, Table, Tooltip, Typography, message } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons'
import { ColumnsType } from "antd/lib/table";
import { default as classNames, default as classnames } from "classnames";
import dayjs from "dayjs";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { TaskItem, pauseTransferTask, queryTransferTask, resumeTransferTask, stopTransferTask, resStartTask } from "src/api/dataTransfer";
import { Iconfont } from "src/components";
import { useRequest } from "src/hook";
import { formatSourceData, formatTargetData, renderObjectLabel } from "../utility";
import styles from './index.module.scss';
import { getScrollX } from "src/util";

const onLoadStatus: number[] = [0, 1, 2, 3, 6];

const OnLoadTable = ({ getRefreshMethod, searchValue }: { getRefreshMethod: any, searchValue: string }): JSX.Element => {
	const firstMounted = useRef<boolean>(true);
	const [refreshLoading, setRefreshLoading] = useState<boolean>(false)

	const {
		data,
		run: queryTask,
		loading,
		pagination,
		refresh,
	} = useRequest(
		async (_, params) => {
			return queryTransferTask(params);
		},
		{
			pollingInterval: 5000,
      debounceInterval: 300,
			pollingWhenHidden: false,
			manual: true,
			paginated: true,
			formatResult(res) {
				setRefreshLoading(false)
				return {
					list: res.data,
					total: res.totalItem,
				};
			},
			onError() {
				setRefreshLoading(false)
			}
		},
	);

	useEffect(() => {
		if (firstMounted.current) {
			firstMounted.current = false;
		}
		return () => { firstMounted.current = true }
	}, []);

  const getData = () => {
    if(searchValue){
      pagination.current = 1;
    }
    const params = {
      pageNo: pagination.current,
      pageSize: 20,
      status: onLoadStatus,
      keyword: searchValue
    };
    const pageParams = {
      current: params.pageNo,
      pageSize: params.pageSize,
    };

    queryTask(pageParams, params);
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTask, searchValue]);

	/**
	 * only use loading status when first render
	 * @returns
	 */
	function getLoadingStatus(): boolean {
		return firstMounted.current ? loading : false;
	}

	const onChangeRefreshLoading = (val:boolean) => {
		setRefreshLoading(val)
	}

  getRefreshMethod({ setter: setRefreshLoading, refresh });

	const handleTableChange = (
    pagination: any,
    filters: Record<string, any>,
  ) => {
		const {current, pageSize} = pagination
		const {taskType: taskTypes} = filters
		let params:any = {
			pageNo: current,
			pageSize: 20,
			status: onLoadStatus,
      keyword: searchValue
		};
		if(taskTypes){
			params.taskTypes = taskTypes
		}
		const pageParams = {
			current,
			pageSize,
		};
		queryTask(pageParams, params);
	}

	return (
		<div>
			{/* <div className={classnames(styles.flexJBetween)}>
				<div></div>
				<Button disabled={refreshLoading} className={classnames(styles.mt10, styles.mb10)} onClick={() => {
					setRefreshLoading(true);
					refresh()
				}}>刷新任务</Button>
			</div> */}
			<Table
				className={styles.table}
				columns={RenderColumns({ refresh, onChangeRefreshLoading })}
				loading={getLoadingStatus() || refreshLoading}
				dataSource={data?.list ?? []}
				// @ts-ignore
				pagination={{ ...pagination, showSizeChanger: false }}
				scroll={{ x: getScrollX(RenderColumns({ refresh, onChangeRefreshLoading })), y: `calc(100vh - 250px)` }}
				onChange={handleTableChange}
			></Table>
		</div>
	);
};

const RenderColumns = ({ refresh, onChangeRefreshLoading }: { refresh: () => Promise<PaginatedFormatReturn<TaskItem>>, onChangeRefreshLoading: (val: boolean) => void }): ColumnsType<any> => {
	const history = useHistory();
	/** 取消任务 */
	const { run: cancelTask } = useRequest(stopTransferTask, {
		manual: true,
		onSuccess() {
			message.success("取消成功");
			timeoutRefresh()
		},
		onError() {
			onChangeRefreshLoading(false);
		}
	});

	/** 暂停任务 */
	const { run: pauseTask } = useRequest(pauseTransferTask, {
		manual: true,
		onSuccess() {
			message.success("暂停成功");
			timeoutRefresh()
		},
		onError() {
			onChangeRefreshLoading(false);
		}
	})

	/** 续传任务 */
	const { run: resumeTask } = useRequest(resumeTransferTask, {
		manual: true,
		onSuccess() {
			message.success("续传成功");
			timeoutRefresh()
		},
		onError() {
			onChangeRefreshLoading(false);
		}
	})

	// 失败后重新开始任务
	const {run: failReStart} = useRequest(resStartTask, {
		manual: true,
		onSuccess() {
			message.success("续传成功");
			timeoutRefresh()
		},
		onError() {
			onChangeRefreshLoading(false);
		}
	})

	const timeoutRefresh = () => {
		setTimeout(() => {
			refresh();
		}, 500)
	}

	// 0:初始化, 1:等待中, 2:运行中, 3:暂停, 4:中止, 5:成功, 6:失败
	// 进行中、已暂停、等待中、任务失败
	function renderStatusBadge(record: TaskItem): ReactNode {
		const {successNum, totalTaskNum} = record
		if (record.state === 2) {
			return (
				<span style={{display:'flex', alignItems: 'center', justifyContent: "space-between", color: "blue"}}>
					<span>进行中</span>
					<span>完成{successNum + '/' + totalTaskNum}</span>
				</span>
				
			);
		} else if (record.state === 3) {
			return (
				<span style={{display:'flex', alignItems: 'center', justifyContent: "space-between", color: 'gray'}}>
					<span>已暂停</span>
					<span>完成{successNum + '/' + totalTaskNum}</span>
				</span>
			);
		} else if (record.state === 1) {
			return (
				<span style={{display:'flex', alignItems: 'center', justifyContent: "space-between", color: 'gray'}}>
					<span>等待中</span>
					<span>完成{successNum + '/' + totalTaskNum}</span>
				</span>
			);
		} else if (record.state === 5) {
			return (
				<span style={{display:'flex', alignItems: 'center', justifyContent: "space-between", color: 'green' }}>
					<span>成功</span>
					<span>完成{successNum + '/' + totalTaskNum}</span>
				</span>
			);
		} else if (record.state === 6) {
			return (
				<span style={{display:'flex', alignItems: 'center', justifyContent: "space-between", color: 'red' }}>
					<span>失败</span>
					<span><span>完成{successNum + '/' + totalTaskNum}</span></span>
				</span>
			);
		}

		return;
	}

	const lookDetail = (data: any) => {
		history.push({
			pathname: '/transfer-task-record-details',
			state: {
				parentId: data?.id,
				statusFlag: true,
				parentDetail: data
			}
		})
	}

	/**
	 * 渲染操作按钮
	 * @param record
	 * @returns
	 */
	function renderOperationBtn(record: TaskItem): ReactNode {
		switch (record.state) {
			case 0:
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
				return (
					<Space>
						{
							(record.state === 3 || record.state === 6) && <Tooltip title="开始">
								<Popconfirm
									title="确定开始该任务吗?"
									onConfirm={() => {
										onChangeRefreshLoading(true);
										record.state === 6 ? failReStart(record.id) : resumeTask(record.id)
									}}
								>
									<Iconfont
										type="icon-zanting"
										className={classNames(styles.iconcolor, styles.fs18)}
									/>
								</Popconfirm>
							</Tooltip>
						}
						{
							(record.state === 1 || record.state === 2 ) && <Tooltip title="暂停">
								<Popconfirm
									title="确定暂停该任务吗?"
									onConfirm={() => {
										onChangeRefreshLoading(true);
										pauseTask(record.id)
									}}
								>
									<Iconfont
										type='icon-kaishi'
										className={classNames(styles.iconcolor, styles.fs18)}
									/>
								</Popconfirm>
							</Tooltip>
						}
						{
							record?.state !== 0 && record?.state !== 4 && <Tooltip title="取消任务">
								<Popconfirm
									title="确定取消该任务吗?"
									onConfirm={() => {
										onChangeRefreshLoading(true);
										cancelTask(record.id)
									}}
								>
									<Iconfont
										type='icon-a-rongqi4'
										className={classNames(styles.iconcolor, styles.fs18)}
									/>
								</Popconfirm>
							</Tooltip>
						}
						{
							record.state !== 0 && <Tooltip title="查看详情">
								<Iconfont
									type="icon-chakanxiangqing"
									className={classNames(styles.iconcolor, styles.fs18)}
									onClick={() => {lookDetail(record)}}
								/>
							</Tooltip>
						}
					</Space >
				);
			default:
				return <div>-</div>;
		}
	}

	return [
		{
			dataIndex: "id",		
			title: "任务编号",
			width: 120,
			fixed: "left",
			render:(txt: string, record: any)=>{
				const idStr = record?.id?.toString()
				const serialNumberStr= record?.serialNumber?.toString() 
				const showTxt = serialNumberStr ? serialNumberStr : idStr
				return <a onClick={() => { lookDetail(record) }}>
					{ 
						showTxt
							? 
								<Tooltip title={showTxt}>{showTxt?.length >16 ? showTxt?.substring(0,16)+'...' : showTxt}</Tooltip> 
							: '_'
					}
				</a>
			}
		},
		{
			dataIndex: "taskName",		
			title: "任务名称",
			width: 100,
			ellipsis: true,
			fixed: "left",
		},
		{
			dataIndex: "taskType",		
			title: "任务类型",
			width: 120,
			ellipsis: true,
			filters: [
				{
					text: '静态脱敏',
					value: 'STATIC_DESENSITIZATION',
				},
				{
					text: '数据传输',
					value: 'DATA_TRANSMISSION',
				},
			],
			render(value) {
				switch (value) {
					case "STATIC_DESENSITIZATION": {
						return "静态脱敏";
					}
					case "DATA_TRANSMISSION": {
						return "数据传输";
					}
					default:
						return "";
				}
			},
		},
		// {
		// 	dataIndex: "desensitizationGrade",			
		// 	title: "脱敏等级",
		// 	width: 100,
		// 	ellipsis: true,
		// },
		{
			key: "source",			
			title: "源",
			width: 300,
			ellipsis: true,
			render(value, record) {
				return renderObjectLabel(formatSourceData(record));
			},
		},
		{
			key: "target",			
			title: "目标",
			width: 300,
			ellipsis: true,
			render(value, record) {
				return renderObjectLabel(formatTargetData(record));
			},
		},
		{
			dataIndex: "state",			
			title: "状态",
			width: 220,
			ellipsis: true,
			render(value, record) {
				return (
					<span style={{fontSize: 12}}>
						{/* 已暂停和进行中不需要 */}
						{
							![2,3].includes(record.state) && 
								<div style={{color: 'red', marginBottom: '0px !important', height: 12 }}>
									<ExclamationCircleFilled style={{marginRight: 4}}/>{record.failureNum}
								</div>
						}
						<Progress
							showInfo={false}
							size="small"
							status={record.failureNum && "exception"}
							style={{width: '90%'}}
							percent={((record.successNum) / record.totalTaskNum) * 100}
						/>
						<div style={{width: '90%',  height: 12}}>{renderStatusBadge(record)}</div>
					</span>
				);
			},
		},
		{
			dataIndex: "createTime",
			title: "发起时间",
			width: 200,
			ellipsis: true,
			render(value) {
				return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
			},
		},
		{
			dataIndex: "updateTime",
			title: "更新时间",
			width: 200,
			ellipsis: true,
			render(value) {
				return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
			},
		},
		{
			dataIndex: "owner",
			title: "创建人",
			ellipsis: true,
			width: 200,
		},
		{
			key: "operation",
			title: "操作",
			ellipsis: true,
			fixed: "right",
			width: 100,
			render(value, record) {
				return renderOperationBtn(record);
			},
		},
	];
};

export { OnLoadTable };

