import React, { useEffect, useState } from 'react';
import { Button, Result, Steps } from 'antd';
import { WWLoginParams } from '@wecom/jssdk';
import { UIModal } from 'src/components';
import { WwLoginQrCode } from 'src/components/WwLoginQrCode';
import { useDispatch, useRequest, useSelector } from 'src/hook';
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice';
import { checkWwPreBindChat, wwBindChat } from 'src/api';
import styles from './index.module.scss';

const { Step } = Steps;

export const WwChatBindModal = (props: { refresh: () => void }) => {
  const { refresh = () => { } } = props;
  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(1);
  // 当前用户是否绑定了其他账户，有值的话是绑定了，没有值就是没有绑定其他账户
  const [otherAccount, setOtherAccount] = useState('');
  // 当前用户绑定的其他微信的账号，有即有无则没
  const [currentWwId, setCurrentWwId] = useState('');

  const visible = useSelector((state) => state.modal.WwChatBindModal)
  const { wwLoginInfo } = useSelector((state) => state.login)
  const { appId: appid = '', agentId: agentid = '' } = wwLoginInfo || {};

  const defaultWwLoginConfig = {
    appid,
    agentid,
    redirect_uri: window.location.origin,
    state: 'loginState',
  } as WWLoginParams;

  const { run: binWwChat, loading } = useRequest(wwBindChat, { manual: true })

  useEffect(() => {
    if (visible) {
      setCurrentWwId('');
      setOtherAccount('');
      setCurrentStep(1);
    }
  }, [visible])


  const handleCancel = () => {
    dispatch(hideModal('WwChatBindModal'))
  }

  const onLoginSuccess = (code: string) => {
    checkWwPreBindChat(code).then(res => {
      const { weChatUserId, userId } = res || {};
      setCurrentWwId(weChatUserId);
      if (userId) {
        setOtherAccount(userId);
      } else {
        onOk(weChatUserId);
      }
    })
  }

  const onOk = (id: string) => {
    binWwChat(id).then(() => {
      setCurrentStep(2);
      refresh()
    })
  }

  const onCancel = () => {
    dispatch(hideModal('WwChatBindModal'));
  }

  const genarateEncodeUserId = (userId: string = '') => {
    if (userId.length > 3) {
      return userId.slice(0, -3) + '***';
    } else if (userId.length <= 3) {
      return userId.slice(0, -1) + '*';
    } else {
      return userId;
    }
  }

  const renderWwLogin = () => {
    const userEncodeId = genarateEncodeUserId(otherAccount);

    switch (currentStep) {
      case 1:
        return <div className={styles.qrCodeBox}>
          {
            otherAccount ?
              <div className={styles.otherAccountBox}>
                <div className={styles.otherAccountContent}>当前企业微信账号已经绑定其他账号{userEncodeId}，确定继续绑定将解绑账号{userEncodeId}，是否继续绑定</div>
                <div>
                  <Button onClick={() => onOk(currentWwId)} type="primary" loading={loading}>确定</Button>
                  <Button onClick={onCancel}>取消</Button>
                </div>
              </div>
              :
              <WwLoginQrCode
                elementIdName="personal_center_ww_login"
                loginConfig={defaultWwLoginConfig}
                onLoginSuccess={onLoginSuccess}
              />
          }
        </div>
      case 2:
        return <Result
          status="success"
          title="企业微信绑定成功"
          extra={[
            <Button type="primary" onClick={onCancel}>
              完成
            </Button>
          ]}
        />
    }
  }

  const stepItems = [
    {
      key: 'verifyIdentity',
      title: '验证身份',
    },
    {
      key: 'bindEnterpriseWeChat',
      title: '绑定企业微信',
    },
    {
      key: 'complete',
      title: '完成',
    }
  ]

  return (
    <UIModal
      title="企业微信绑定"
      visible={visible}
      footer={[]}
      onCancel={handleCancel}
    >
      <Steps current={currentStep}>
        {
          stepItems.map(item => {
            const { title, key } = item || {};
            return <Step key={key} title={title} />
          })
        }
      </Steps>
      {renderWwLogin()}
    </UIModal>
  );
}
