import React, { FC } from 'react'
import {
  Switch,
  Route,
} from 'react-router'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { Layout } from 'antd'
import classNames from 'classnames'
import { VisitWorkOrderPage } from './MyVisitWorkOrder'
import { MyApprovalRequestDetailPage } from '../my-apprpval-request-detail'
import styles from './index.module.scss'

const { Content } = Layout

interface ListProps { }

const updateDataRoutes = [
  {
    pathname: "/flow_work_order_management",
    children: (
      <ErrorBoundary>
        <VisitWorkOrderPage />
      </ErrorBoundary>
    ),
  },
  {
    pathname: "/mine_approve/:id/detail",
    children: (
      <ErrorBoundary>
        <MyApprovalRequestDetailPage />
      </ErrorBoundary>
    ),
  },
];

export const MyWorkOrderVisitPage: FC<ListProps> = ({ ...rest }) => {

  return (
    <Layout className="cq-container">
      <Layout className="cq-main" style={{padding: 0}}>
        <Content
          className={classNames('cq-content', { [styles['change-request-page']]: true })}
        >
          <Switch>
            {updateDataRoutes.map(({ pathname, children }) => (
              <Route
                exact
                path={pathname}
                key={pathname}
                render={() => children}
              />
            ))}
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}
