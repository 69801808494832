import React, {useEffect, useRef, useState} from 'react'
import {AgGridReact} from '@ag-grid-community/react'
import { ChangeDetectionStrategyType } from '@ag-grid-community/react/lib/changeDetectionService'
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model'
import {ColumnApi, GridApi, GridReadyEvent} from '@ag-grid-community/core'
import {Button, message,Spin } from 'antd'
import {isEmpty, isEqual, cloneDeepWith, find, omit} from 'lodash'
import {useRequest, useSelector, useDispatch} from 'src/hook'
import {GridConfigBase} from 'src/pageTabs/queryPage/resultTabs/resultContentGrid'
import {
    TableConstraintResponse,
    TableForeignKeyResponse,
    TableColumnResponse, 
    QueryTableInfoParams,
    getTableConstraints_api,
     getTableForeignKey_api, 
     getTableIndexes_api, 
     getTableColumn_api,
     getRefDatabaseNames,
     getRefSchemaNames,
     getRefTableNames,
     getRefColumnNames,
     getTableTriggers_api
} from 'src/api'
import { updateDesignInfo } from '../../designTableSlice'
import {DataSourceType, IIndex} from 'src/types'
import {correctComment, getEnumOptions, isSupport} from 'src/util'
import { TriggerExtraContent } from './TriggerExtraContent'

// 有 schema 的数据源
const DataBaseTypesForHasSchema = ['Oracle', 'PostgreSQL', 'SQLServer', 'DamengDB', 'OceanBase', 'PolarDB']
// 触发器的触发事件可多选的数据源
const DataBaseTypesForHasTriggerMultiEvent = ['Oracle', 'PostgreSQL', 'SQLServer','DamengDB','OceanBase','PolarDB']

export interface IDesignIndexProps {
    connectionId?: string | number
    connectionType: DataSourceType
    nodeType?: string;
    databaseName?: string
    schemaName: string | undefined
    tableName: string
    tableKey: string
    // indexes?: IIndex[]
    originData?: (TableConstraintResponse & { key?: string })[] | (TableForeignKeyResponse & { key?: string })[] | (TableColumnResponse & { key?: string })[] | undefined
    queryTabKey?: string
    nodePath?: string
    nodePathWithType?: string
    successfulCallback: () => void
    onSave: (data: any) => void
    sql?: string
    disabledEdit: boolean
}

const ColumnAPIMapping: {[key: string]: any} = {
    constraints: getTableConstraints_api,
    fkConstraints: getTableForeignKey_api,
    indexes: getTableIndexes_api,
    columns: getTableColumn_api,
    triggers:getTableTriggers_api
}

export const DesignPane = (props: IDesignIndexProps) => {

    const {
        sql,
        connectionId,
        connectionType,
        nodeType,
        databaseName,
        schemaName,
        tableName,
        tableKey,
        nodePath,
        nodePathWithType,
        onSave,
        disabledEdit
    } = props

    const referencedFieldAction = DataBaseTypesForHasSchema.includes(connectionType)
      ? getRefSchemaNames : getRefDatabaseNames
    const gridApiRef = useRef<GridApi>()
    const gridColumnApiRef = useRef<ColumnApi>()
    const {tableDataCollection} = useSelector(state => state.designTable)

    const dispatch = useDispatch()
    const {theme} = useSelector((state) => state.appearance)

    // 表格数据
    const [rowData, setRowData] = useState<any>([])
    //触发器 body
    const [focusRowData,setFocusRowData] = useState<any>({})
     
    // 获取表格信息
    let {
        data: originData,
        loading:getOriginDataLoading,
        refresh
    } = useRequest(
        () => {
            if (!(connectionId && nodePath && tableName)) return
            setFocusRowData({});
            let params = {
                connectionId,
                connectionType,
                databaseName,
                schemaName,
                tableName,
                nodePath,
                nodePathWithType,
            }
           
           const request = ColumnAPIMapping[tableKey]
            return request(params)
        },
        {
            onSuccess: (data) => {
                const tipMapping: any = {
                    columns: 'columnName',
                    indexes: 'indexName',
                    constraints: 'constraintName',
                    fkConstraints: 'constraintName',
                    triggers: 'triggerName'
                }

               

               const originData =  data && cloneDeepWith(data.map((i: any) => {
            
                    // 赋值标示
                    const key = tipMapping[tableKey] || ''
                    
                        // @ts-ignore
                        i.key = i[key];
                    // if (tableKey === 'columns') {
                    //     // @ts-ignore
                    //     i.key = i.columnName;
                    // }
                    return i
                }))
                setRowData(originData)
            },
            refreshDeps:[tableName]
        }
    )

    // 外键 额外调用接口
    const {data: refSchameOrDatabaseData, run: getRefSchameOrDatabaseList} = useRequest(referencedFieldAction,{
        manual: true,
        formatResult: (res) => res?.names || []
    })
    
    const { run: getRefTableList} = useRequest(getRefTableNames,{
          manual: true,
          formatResult: (res) => res?.names || []
      })

      const {run: getRefColumnList} = useRequest(getRefColumnNames,{
          manual: true,
          formatResult: (res) => res?.names || []
      })

      useEffect(() => {
       if (tableKey === 'fkConstraints' && !disabledEdit) {
        getRefSchameOrDatabaseList({connectionId,connectionType})
       }
      },[tableKey,disabledEdit])

    //存储字段数据 其他tab有使用到
    useEffect(() => {
        if (tableKey === 'columns') {
            dispatch(updateDesignInfo({tabKey:tableKey, rowData: rowData}))  
        }
    },[rowData])

    const onGridReady = (params: GridReadyEvent) => {
        gridApiRef.current = params.api
        gridColumnApiRef.current = params.columnApi
        gridApiRef.current.sizeColumnsToFit()
    }

    // 保存后，父组件会置空sql，标示保存操作，需要刷新当前页面请求
    useEffect(() => {
        if (sql === '') {
            refresh()
        }
    }, [sql])

    const getColumnSelect = () => {
        const columnFieldList = tableDataCollection['columns'] || []

        if (isEmpty(columnFieldList) ){
            return []
        }
        return columnFieldList.map((item: any) => item.columnName)
    }

    //联动 字段onchange事件
    const onResetSelectValue = async(values: any) => {

        const { field, rowIndex,oldValue, columns} = values
    
        const rowNode = gridApiRef.current?.getRowNode(rowIndex)

        if ((field === 'refSchemaName' ||field === 'refDatabaseName') && columns[field] !== oldValue){
            rowNode?.setDataValue('refColumnNames','')
            rowNode?.setDataValue('refTableName','')
        }
        if (field === 'refTableName') {
  
            rowNode?.setDataValue('refColumnNames','')
        }
    }
    // 基础列信息
    const getBaseColumn = (tabKey: string, connectionType: string) => {
  
        //约束
        if (tabKey === 'constraints') {
            return [
                {
                    headerName: '约束名',
                    field: 'constraintName',
                    editable: false,
                },
                {
                    headerName: '字段',
                    field: 'columnNames',
                    editable: false,
                    cellEditor: 'columnMultiSelectEditor',
                    cellEditorParams: {
                        columnsOptions: getColumnSelect(),
                    }
                },
                {
                    headerName: '约束类型',
                    minWidth: 120,
                    field: 'constraintType',
                    editable: false,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getEnumOptions('constraintTypeEnum'),
                    }
                },
                {
                    headerName: '注释',
                    field: 'comment',
                    hide: !isSupport('supportedConstraintProperty.comment'),
                    editable: false,
                },
                {
                    headerName: '条件',
                    field: 'condition',
                    hide: !isSupport('supportedConstraintProperty.condition'),
                    editable: false,
                },
                {
                    headerName: '开启',
                    field: 'enabled',
                    hide: !isSupport('supportedConstraintProperty.enabled'),
                    editable: false,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    },
                }
            ]
        } else if (tabKey === 'fkConstraints') {//外键
            return [
                {
                    headerName: '约束名',
                    field: 'constraintName',
                    editable: false,
                },
                {
                    headerName: '字段',
                    field: 'columnNames',
                    editable: false,
                    cellEditor: 'columnMultiSelectEditor',
                    cellEditorParams: {
                        columnsOptions: getColumnSelect(),
                    }
                },
                {
                    headerName: '注释',
                    field: 'comment',
                    hide: !isSupport('supportedFKConstraintProperty.comment'),
                    editable: false,
                },
                {
                    headerName: DataBaseTypesForHasSchema.includes(connectionType) ? '被引用的模式' : '被引用的数据库',
                    field: DataBaseTypesForHasSchema.includes(connectionType) ? 'refSchemaName' : 'refDatabaseName',
                    editable: false,
                    cellEditor: 'antdSelectEditor',
                    cellEditorParams: {
                        columnsOptions:refSchameOrDatabaseData,
                        onValueChange :async(values: any) => {
                        
                            await onResetSelectValue(values)
                        
                        }
                    }   
                },
                {
                    headerName: '被引用的表',
                    field: 'refTableName',
                    editable: false,
                    cellEditor: 'antdSelectEditor',
                    cellEditorParams: {
                        //传过去async方法试试
                        async: true,
                        onValueChange :async(values: any) => {
                            await onResetSelectValue(values)
                        },
                        //@ts-ignore
                        columnsOptions: async(params: any) => {
                            const {data = {}} = params
                        let tableParams:QueryTableInfoParams  = {
                            connectionId,
                            connectionType
                        }
                        if (DataBaseTypesForHasSchema.includes(connectionType)) {
                            tableParams['schemaName']  = data?.refSchemaName
                        }else {
                            tableParams['databaseName'] = data?.refDatabaseName
                        }
                        if ( (data?.refSchemaName || data?.refDatabaseName) && !disabledEdit) {
                            return await getRefTableList({...tableParams})
                        }else {
                            return []
                        }
                    },
                    }
                },
                {
                    headerName: '被引用的字段',
                    field: 'refColumnNames',
                    editable: false,
                    cellEditor: 'columnMultiSelectEditor',
                    cellEditorParams: {
                        //传过去async方法试试
                        async: true,
                        onValueChange :async(values: any) => {
                            await onResetSelectValue(values)
                        },
                        columnsOptions: async(params: any) => {
                            const {data={}} = params
                        let tableParams:QueryTableInfoParams  = {
                            connectionId,
                            connectionType
                        }
                        if (DataBaseTypesForHasSchema.includes(connectionType)) {
                            tableParams['schemaName']  = data?.refSchemaName
                        }else {
                            tableParams['databaseName'] = data?.refDatabaseName
                        }
                
                        if (data?.refTableName && !disabledEdit &&  (data?.refSchemaName || data?.refDatabaseName)) {
                            return await getRefColumnList({...tableParams,tableName:data?.refTableName})
                
                        }else {
                            return []
                        }
                        }
                },
            },
                {
                    headerName: '更新时规则',
                    minWidth: 100,
                    hide: !isSupport('supportedFKConstraintProperty.updateRule'),
                    field: 'updateRule',
                    editable: false,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getEnumOptions('updateRuleEnum'),
                    }
                },
                {
                    headerName: '删除时规则',
                    minWidth: 100,
                    hide: !isSupport('supportedFKConstraintProperty.deleteRule'),
                    field: 'deleteRule',
                    editable: false,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getEnumOptions('deleteRuleEnum')
                    }
                },
                {
                    headerName: '开启',
                    field: 'enabled',
                    hide: !isSupport('supportedFKConstraintProperty.enabled'),
                    editable: false,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    },
                },
            ]
        } else if (tabKey === 'indexes') {
            return [
                {
                    headerName: '索引名',
                    field: 'indexName',
                    editable: false,
                },
                {
                    headerName: '字段',
                    field: 'columnNames',
                    editable: true,
                    cellEditor: 'columnMultiSelectEditor',
                    cellEditorParams: {
                        columnsOptions: getColumnSelect(),
                    }
                },
                {
                    headerName: '类型',
                    minWidth: 120,
                    field: 'indexType',
                    editable: false,
                    cellEditor: 'selectEditor',
                    cellEditorParams: {
                        values: getEnumOptions('indexTypeEnum'),
                    }
                },
                {
                    headerName: '注释',
                    field: 'comment',
                    hide: !isSupport('supportedIndexProperty.comment'),
                    editable: false,
                },
                {
                    headerName: '唯一',
                    field: 'unique',
                    editable: false,
                    cellEditor: 'agSelectCellEditor',
                    hide: !isSupport('supportedIndexProperty.unique'),
                    cellEditorParams: {
                        cellHeight: 50,
                        values: ['true', 'false'],
                    },
                }
            ]
        } else if (tabKey === 'columns') {
            return [
                {
                    headerName: '字段名',
                    field: 'columnName',
                    editable: true,
                },
                {
                    headerName: '类型',
                    minWidth: 120,
                    field: 'dataType',
                    editable: true,
                    cellEditor: 'selectEditor',
                    cellEditorParams: {
                        values: getEnumOptions('dataTypeEnum')
                    }
                },
                {
                    headerName: '长度',
                    field: 'length',
                    editable: true,
                    cellEditor: 'numericEditor',
                    cellEditorParams: {
                        min: 0,
                        maxLength: 10
                    }
                },
                {
                    headerName: '小数点',
                    field: 'scale',
                    hide: !isSupport('supportedColumnProperty.decimal'),
                    editable: true,
                    cellEditor: 'numericEditor',
                    cellEditorParams: {
                        min: 0,
                        maxLength: 10
                    }
                },
                {
                    headerName: '默认值',
                    field: 'defaultValue',
                    editable: true,
                },
                {
                    headerName: '注释',
                    field: 'comment',
                    editable: true,
                },
                {
                    headerName: '虚拟',
                    field: 'virtual',
                    hide: !isSupport('supportedColumnProperty.virtualColumn'),
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    }
                },
                {
                    headerName: '可为空',
                    field: 'nullable',
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    }
                },
                {
                    headerName: '可自增',
                    field: 'autoIncrease',
                    hide: !isSupport('supportedColumnProperty.autoIncrease'),
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    }
                },
                {
                    headerName: '主键',
                    field: 'primary',
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    hide: !isSupport('supportedColumnProperty.primary'),
                    cellEditorParams: {
                        values: ['true', 'false'],
                    }
                },
                {
                    headerName: '排序键',
                    field: 'sortKey',
                    editable: true,
                    hide: !isSupport('supportedColumnProperty.sortKey') || (connectionType === 'StarRocks' && nodeType === 'foreignTable') ,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    }
                }
            ]
        }else if (tabKey === 'triggers') {
            return [
                {
                    headerName: '触发器名',
                    field: 'triggerName',
                    editable: true,
                },
                {
                    headerName: '触发器时机',
                    minWidth: 120,
                    hide: !isSupport('supportedTriggerProperty.timing'),
                    field: 'timing',
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: getEnumOptions('triggerTimingEnum')
                    }
                },
                {
                    headerName: '触发事件',
                    field: 'events',
                    editable: true,
                    cellEditor: DataBaseTypesForHasTriggerMultiEvent.includes(connectionType)
                      ? 'columnMultiSelectEditor' : 'agSelectCellEditor',
                    cellEditorParams: {
                        [DataBaseTypesForHasTriggerMultiEvent.includes(connectionType)
                          ? 'columnsOptions' : 'values']: getEnumOptions('triggerEventEnum')
                    }
                },
                {
                    headerName: '级别',
                    field: 'level',
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    hide: !isSupport('supportedTriggerProperty.level'),
                    cellEditorParams: {
                        values: getEnumOptions('triggerLevelEnum')
                    }
                },
                {
                    headerName: '引用旧值名',
                    field: 'referenceOld',
                    hide: !isSupport('supportedTriggerProperty.referenceOld'),
                    editable: true,
                },
                {
                    headerName: '引用新值名',
                    field: 'referenceNew',
                    hide: !isSupport('supportedTriggerProperty.referenceNew'),
                    editable: true,
                },
                {
                    headerName: '更新字段',
                    field: 'updateColumns',
                    hide: !isSupport('supportedTriggerProperty.updateColumns'),
                    cellEditor: 'columnMultiSelectEditor',
                    cellEditorParams: {
                        columnsOptions: getColumnSelect(),
                    }
                },
                {
                    headerName: 'WHEN子句',
                    field: 'when',
                    hide: !isSupport('supportedTriggerProperty.when'),
                    editable: true,
                },
                {
                    headerName: '注释',
                    field: 'comment',
                    hide: !isSupport('supportedTriggerProperty.comment'),
                    editable: true,
                },
                {
                    headerName: '开启',
                    field: 'enabled',
                    hide: !isSupport('supportedTriggerProperty.enabled'),
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['true', 'false'],
                    }
                }
            ]
        }
    }

    // 新增约束
    const add = () => {
        gridApiRef.current?.applyTransactionAsync(
            {add: [{operationType: 'ADD'}]},
            (res) => {
                gridApiRef.current?.startEditingCell({
                    rowIndex: Number(res.add[0]?.rowIndex),
                    colKey: 'indexName',
                })
                //滚动条自动定位新增数据位置
                gridApiRef?.current?.ensureIndexVisible(Number(res.add[0]?.rowIndex))
            },
        )
    }

    // 删除约束
    const del = () => {
        const selectedRows = gridApiRef.current?.getSelectedRows()
        const selectedNodes = gridApiRef.current?.getSelectedNodes()
        if (!selectedRows || !selectedRows[0] || !selectedNodes) return
        gridApiRef.current?.applyTransactionAsync({remove: selectedRows})
        const {rowIndex} = selectedNodes[0] as any
        setRowData((prevData: any) => {
            prevData?.splice(rowIndex, 1)
            return prevData
        })
    }

    // 获取表格数据
    const getGridData = () => {
        const data: IIndex[] = []
        gridApiRef.current?.forEachNode((node) => {
            data.push(node.data)
        })
        return data
    }

    // 失焦时获取整体表格数据
    const updateRowData = () => {
        const rowData = getGridData()
        // @ts-ignore
        setRowData(rowData)
        dispatch(updateDesignInfo({tabKey:tableKey,rowData} ))
       
    }

    // 提交
    const submit = async () => {
        try {   
            // 找出差异值
            let payload = formatData(getDiffPayload()) || {}

            onSave({[tableKey]: payload, primaryKey: getDiffExtraPayload()})
        } catch (error) {
            message.success(`保存失败`)
        }
    }

    // 计算用户填写和原数据的 diff 结果
    const getDiffPayload = () => {
        // console.log("-> 原始的 originData", originData);
        // console.log("-> 修改后的 rowData 数据", rowData);
        let result:any[] = []

        // 1. 过滤出 operationType 是 add 的为新增
        rowData?.forEach((item:any) => {
            if(item.operationType === 'ADD'){
                result.push({
                    "operationType": "ADD",
                    newData:omit(item,'operationType'),
                    oldData:null
                })
            }
            
        })

        // 2. 遍历之前的数据 originData，如果 key （由 columnName 生成） 有在 rowData 时，表示 修改 alter
        originData?.forEach((origin: any) => {
            let newData = find(rowData, {key: origin.key})
            if (!newData) {
                // 3. 如果不在 rowData，则表示删除
                result.push({
                    "operationType": "DELETE",
                    newData:null, 
                    oldData:{...origin}
                })

            } else if (!isEqual(origin, newData)) {

                // 3.1 如果字段，则把新的字段给出
                result.push({
                    "operationType": "ALTER",
                    newData:omit(newData,'operationType'),
                    oldData:{...origin}
                })
            }
        })

        return result
    }

    // 计算特殊字段的 diff 结果
    const getDiffExtraPayload = () => {
        const primaryKeys: string[] = []
        const newPrimaryKeys: string[] = []
        originData?.forEach((item: any) => {
            if (item.primary === true || item.primary === 'true') {
                primaryKeys.push(item.columnName)
            }
        })

        rowData?.map((item: any) => {
            if (item.primary === true || item.primary === 'true') {
                newPrimaryKeys.push(item.columnName)
            }
        })

        if (!isEqual(primaryKeys, newPrimaryKeys)) {
            return {primaryKeys, newPrimaryKeys}
        }
    }

    const formatData = (dataArr: any[]) => {

        if (!dataArr) return []
        return dataArr.map(item => {
            const {newData = {}} = item
            return {
                ...item,
                newData: isEmpty(newData) ? null: {
                    ...newData,
                    comment: newData?.comment && correctComment(connectionType,newData.comment)
                }
            }
        })
    }

    //设置触发器body字段
    const onResetTriggerBody = (params={}) => {
        
        const {rowIndex, data = {}} = focusRowData;
        console.log(rowIndex,'aaa,params',params);
        
        setFocusRowData({
            rowIndex,
            data: {
                ...data,
                ...params
            }
        })
        let changedRowData = rowData && rowData.map((item: any) => {
         if (item?.triggerName === data?.triggerName) {
          return ({
            ...item, 
            ...params
          })
         }
         return item
        })
         // @ts-ignore
         setRowData(changedRowData)
         dispatch(updateDesignInfo({tabKey:tableKey,rowData: changedRowData} ))
    }
    // 渲染操作表格按钮
    const renderOperation = () => {
     
            return <div style={{marginBottom: '16px'}}>
                <Button size="small" onClick={add} className="mr8">
                    新增
                </Button>
                <Button size="small" onClick={del} className="mr8">
                    删除
                </Button>
                <Button size="small" type="primary" onClick={submit}>
                    预览
                </Button>
            </div>

    }

    return (
        <Spin spinning={getOriginDataLoading}>
            { !disabledEdit && renderOperation()}
            <div
                id="myGrid"
                style={{
                    height: `calc(60vh - 40px)`,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
                className={
                    theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham'
                 }  
            >
                <div style={{flex: 1}}>
                <AgGridReact
                    {...GridConfigBase}
                    modules={[ClientSideRowModelModule]}
                    columnDefs={getBaseColumn(tableKey, connectionType)?.map(i => ({...i, editable:!disabledEdit }))}
                    defaultColDef={{editable: true, resizable: true}}
                    animateRows={true}
                    onGridReady={onGridReady}
                    rowData={rowData}
                    rowSelection="single"
                    onCellEditingStopped={updateRowData}
                    onCellFocused={(event) => {
                        if (event.rowIndex === focusRowData?.rowIndex) return;
                        if (event.rowIndex != null && tableKey === 'triggers') {
                          const rowNode = event.api.getDisplayedRowAtIndex(
                            event.rowIndex,
                          )
                      
                          const { rowIndex=0, data ={}} = rowNode as any
                          setFocusRowData({rowIndex,data})
                        }
                        
                      }}
                    stopEditingWhenGridLosesFocus
                    rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
                />
                   </div>
              {
                    tableKey === 'triggers' && 
                    <TriggerExtraContent 
                     disabledEdit={disabledEdit}
                     connectionId={connectionId}
                     databaseName={databaseName}
                     schemaName={schemaName}
                     connectionType={connectionType}
                     focusRowData={focusRowData}
                     onResetTriggerBody={onResetTriggerBody}
                    />
                }
                
            </div>       
        </Spin>
    )
}
