import React from 'react'
import { Form, Input } from 'antd'

export const FormItemTitle = () => {

  return (
    <Form.Item
    label="标题"
    name="title"
    rules={[{ required: true }, { min: 2, max: 20 }]}
  >
    <Input allowClear />
  </Form.Item>
  )
}
