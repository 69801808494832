import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'
import { Tooltip } from 'antd'
import { Iconfont } from 'src/components'
import { ICellEditorParams } from '@ag-grid-community/core'
import { CellEditorFromBackend } from 'src/api'
import styles from './TextEditor.module.scss'

export interface TextEditorProps extends ICellEditorParams {
  cellEditorType?: CellEditorFromBackend
  nullable?: boolean
  renderType?: string | null
  downloadable?: boolean
}

const getTooltipTitle = (cellEditorType?: CellEditorFromBackend) => {
  switch (cellEditorType) {
    case 'date':
      return 'yyyy-mm-dd'
    case 'time':
      return 'HH:mm:ss'
    case 'timestamp':
      return 'yyyy-mm-dd HH:mm:ss'
    default:
      return ''
  }
}

export const TextEditor = forwardRef((props: TextEditorProps, ref) => {
  const { cellEditorType, nullable } = props
  const [value, setValue] = useState(props.value)
  const [nullSwitch, setNullSwitch] = useState(false)

  const isNull = nullSwitch || value === null

  const refContainer = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (nullSwitch) return null
        return value
      },
      isPopup() {
        return false
      },
      afterGuiAttached: () => {
        if (props.cellStartedEdit) {
          inputRef.current?.focus()
        }
      },
    }
  })

  // todo: 键盘操作

  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div
      ref={refContainer}
      className={styles.wrapper}
      tabIndex={1} // ag-grd: important - without this the key presses wont be caught
    >
      <div className={styles.inputWrapper}>
        <Tooltip title={getTooltipTitle(cellEditorType)}>
          <input
            className={styles.input}
            defaultValue={props.value}
            placeholder={isNull ? '<null>' : ''}
            onChange={(e) => {
              const value = e.target.value
              setValue(value)
              if (value !== null) setNullSwitch(false)
            }}
            ref={inputRef}
          ></input>
        </Tooltip>
      </div>
      {nullable && (
        <div
          className={styles.nullSetter}
          onClick={() => {
            setNullSwitch(!isNull)
            if (value === null) setValue('')
            if (!nullSwitch) {
              // 从非 null 切换为 null
              if (!inputRef.current) return
              inputRef.current.value = ''
            }
          }}
        >
          {isNull ? (
            <Iconfont type="icon-null"></Iconfont>
          ) : (
            <Iconfont type="icon-null1"></Iconfont>
          )}
        </div>
      )}
    </div>
  )
})
