import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { TreeProps } from 'rc-tree/lib/Tree'
import * as _ from 'lodash'
import classnames from 'classnames'
import {
  useSelector,
  useDispatch,
  useResizeObserver,
  useRequest,
} from 'src/hook'
import { ISearchSdtItem, searchTreeNodeByKeyword } from 'src/api'
import { cloneDeep, uniq } from 'lodash'
import {
  Tree,
  Row,
  Select,
  Menu,
  Spin,
  Tooltip,
  Input,
  message,
  Popover,
  Typography,
} from 'antd'
import { Iconfont, SdtPermissionSwitch, ThemeSwitch } from 'src/components'
import {
  CreateConnectionModal,
  CreateDatabaseModal,
} from 'src/features/wizards'
import {  getSpecialConnectionTypePermissions, actionIconNodeTypes } from 'src/constants'
import { DropdownMenu } from './DropdownMenu'
import {
  setTreeData,
  setSelectedNode,
  setRightClickedNode,
  setExpandedKeys,
  setLoadedKeys,
  refreshOnRoot,
  fetchConnections,
  viewElementInEditor,
  showViewInEditor,
  NodeTypesSupportEditorView,
  viewRedisKey,
  getTreeNodeChildren,
  refreshChildTreeCache,
  fetchTreeNodeChildren,
} from './sdtSlice'
import { addConnection } from 'src/features/wizards/wizardsSlice'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import styles from './index.module.scss'
import { addPane } from '../queryTabs/queryTabsSlice'
import { setTargetTableList, setTargetTableMessage, setNoAccessRightClick } from 'src/store/extraSlice/textImportSlice'
import {
  NodeEntity,
  sortConnection_api,
  moveConnectionToSdtGroup,
  getNodeChildList,
  addRequisitionToCart,
  getSearchSdtList
} from 'src/api'
import { openFlowForm } from 'src/pageTabs/flowPages/flowFormsSlice'
import {
  AddSdtGroupModal,
  AddSdtNodeExportModal,
  ModalAddSchema,
  MoveToSdtGroupModal,
  RenameSdtNodeModal,
  AddSubSdtGroupModal,
  UpdateConnAliasModal,
  BatchCreateConnectionModal,
  DumpExportModal,
  DumpImportModal,
  SQLImportModal,
} from './modals'
import { getInfoFromPath, findTreeParentNode, findTreeRootNode, getSdtThinPermissionIconType } from 'src/util'
import type { DataNode } from 'antd/lib/tree'
import type { DataSourceType, ResponseNodeEntity, TreeNode } from 'src/types'
import Service from 'src/service'
import moduleService from 'src/service/moduleService'
import {
  convertResponseTreeToNodeTree,
  getExpandKeysNodeTreeAllChildren,
  matchKeyword,
  getExpandNodeNeedPathKeys,
  getGroupExpandNodeNeedPathKeys,
  getPreExpandNodeNeedPathKeys
} from './const.sdt'
import { paneInfoMapSelector } from '../resultTabs/resultTabsSlice'
import { useDictSearch } from './useDictSearch'
import { setAddToCartFlag } from '../queryPageSlice'
import { useHistory } from 'react-router-dom'

interface RenderNode extends ResponseNodeEntity, DataNode {
  valid?: boolean
  enable?: boolean
}

const { DirectoryTree } = Tree

const getExtraNodePopoverContent = (node: any) => {
  const { nodeOptions } = node
  const { comments, isNullable, dataLength, dataType } = nodeOptions || {}
  return (
    <div className={styles.nodePopoverContent}>
      <Typography.Paragraph>类型:{dataType}</Typography.Paragraph>
      <Typography.Paragraph>长度:{dataLength}</Typography.Paragraph>
      <Typography.Paragraph>
        允许为null:{isNullable ? 'true' : 'false'}
      </Typography.Paragraph>
      <Typography.Paragraph ellipsis copyable={Boolean(comments)}>
        注释:{comments}
      </Typography.Paragraph>
    </div>
  )
}

const noTablePermission = (node: any) => {
  const { nodeType, havingPermission = false} = node
  return  actionIconNodeTypes?.includes(nodeType) && !havingPermission
}

export const Sdt = React.memo(() => {
  const history = useHistory()
  const dispatch = useDispatch()
  const BatchCreateConnectionModalVisible = useSelector(
    (state) => state.modal['BatchCreateConnectionModal'],
  )
  const {devModelConnectionIds = []} = useSelector((state) => state.editor)
  const {status = false } = useSelector((state) => state.sdtPermission)
  const { dataSourceMap } = useSelector((state) => state.dataSource)
  const { dictSearchIsOn } = useDictSearch()
  
  useEffect(() => {
    dispatch(fetchConnections())
    return () => {
      dispatch(setTreeData([]))
    }
  }, [dispatch])

  const {
    userInfo: { userId },
  } = useSelector((state) => state.login)
  const {
    treeData,
    selectedNode,
    expandedKeys,
    loadedKeys,
    treeLoading,
    treeNodeChildrenMap,
    resourceMap
  } = useSelector((state) => state.sdt)
  const { addToCartFlag } = useSelector((state) => state.queryPage)

  const { connectionId, connectionType, nodeName, nodeType, nodePath, nodePathWithType, maskedPathMap } =
    selectedNode || {}
  const sdtNodeExportParams = {
    connectionId,
    connectionType,
    nodeName,
    nodeType,
    nodePath,
    nodePathWithType,
    maskedPathMap,
  }

  // 处理虚拟滚动 height 的计算
  const [rect, domRef] = useResizeObserver()

  useEffect(() => {
    dispatch(setSelectedNode({}))
  }, [dispatch])

  const onLoadData = useCallback(
    async (treeNode: DataNode) => {
      const node = treeNode as RenderNode
      await dispatch(getTreeNodeChildren(node))
    },
    [dispatch],
  )

  const { run: addRun } = useRequest(addRequisitionToCart, {
    manual: true,
    onSuccess(data) {
      let num = addToCartFlag + 1
      message.success('成功加入申请单')
      dispatch(setAddToCartFlag(num))
    }
  })

  /* 无权连接 唤起流程表单 ,数据操作权限对应连接访问提权*/
  /* todo: 整合到后端提取逻辑中 */
  // 目前有连接权限和操作权限，后期应该在后端处理好
  const openDataSourceFlow = async (node: NodeEntity) => {
    const { connectionId, nodeName, connectionType, applyPermissionType } = node
    const permissionNodes = [
      {
        connectionId,
        connectionType,
        nodeName,
        nodePath: `/root/${connectionId}`,
        nodeType: 'connection',
      },
    ] as any

    // 能看到连接的情况下应该是有连接访问权限了，这个时候缺的是数据操作权限
    if (applyPermissionType === 'connectionAccess') {
      dispatch(
        openFlowForm({
          type: 'connectionAccess',
          fields: {
            elements: permissionNodes,
          },
        }),
      )
    } else {
      dispatch(
        openFlowForm({
          type: 'dataManipulation',
          fields: {
            elements: permissionNodes,
          },
        }),
      )
    }

  }

  const handleNodeSelect = (
    _keys: any,
    e: { selected: boolean; selectedNodes: any; node: any; event: any },
  ) => {
   
    const { selected, node } = e
    //table无权限
    const perm = noTablePermission(node)
    if (perm) {
      return
    }
    
    if (selected) {
      dispatch(setSelectedNode(node))
    }
  }
  const { dataSourceList } = useSelector((state) => state.dataSource)

  const DataSourceMenu = (
    <Menu>
      {dataSourceList.map(({ dataSourceName }) => (
        <Menu.Item
          key={dataSourceName}
          onClick={() => dispatch(addConnection(dataSourceName))}
        >
          <Iconfont
            type={'icon-connection-' + dataSourceName}
            className="mr8"
            style={{ fontSize: 14 }}
          ></Iconfont>
          {dataSourceName}
        </Menu.Item>
      ))}
      {!dataSourceList[0] && (
        <Menu.Item key="NoDataSource" disabled>
          暂无可创建数据源类型
        </Menu.Item>
      )}
      {dataSourceList[0] && (
        <Menu.Item
          key="BatchCreate"
          onClick={() => dispatch(showModal('BatchCreateConnectionModal'))}
        >
          <Iconfont
            type="icon-batchcomputepiliangjisuan"
            className="mr8"
            style={{ fontSize: 14 }}
          ></Iconfont>
          批量创建
        </Menu.Item>
      )}
    </Menu>
  )

  interface SdtHeaderIconProps {
    iconType: string
    onClick?: () => void
    title?: string
    style?: React.CSSProperties
  }

  const SdtHeaderIcon = (props: SdtHeaderIconProps) => {
    const { title, onClick, iconType, style } = props
    return (
      <Tooltip placement="bottomRight" title={title || ''}>
        <Iconfont
          className={styles.sdtToolbarIcon}
          type={iconType}
          onClick={onClick}
          style={style}
        ></Iconfont>
      </Tooltip>
    )
  }
  const treeRef = useRef(null)
  const searchInputRef = useRef<Input>(null)
  const [keyword, setKeyword] = useState('')
  const [searchActive, setSearchActive] = useState(true)
  const [hoverNodeKey, setHoverNodeKey] = useState<string | number>('')
  const [hoverNodePathWithType, setHoverNodePathWithType] = useState('')
  const [dictSearchValue, setDictSearchValue] = useState<any>(null)
  const [dictOptions, setDictOptions] = useState<any>([])

  const renderTitle = (i: any) => {
    let iconType = `icon-${i?.nodeType === 'connection' ? i?.dataSourceType: i?.nodeType}`
    //暂时没有具体到列的无权限图标 目前图标不会置灰
    if (actionIconNodeTypes?.includes(i?.nodeType) && !i?.havingPermission){
      iconType = `icon-${i?.nodeType}_noPerm`
    }
 
    return <div>
      <Iconfont
          type={iconType}
          style={{marginRight: 4}}/><span style={!i?.havingPermission ? {color: '#ccc'} : {}}>{ i?.nodeShowName}</span>
    </div>
  }
  //数据字典远程搜索前20条
  const {data: filterSdtOptions, loading: dictLoading,run: onSearchSdtNodes} = useRequest(getSearchSdtList,{
    manual: true,
    formatResult: (data) => {
      return data?.map((i: ISearchSdtItem) => ({
        label:renderTitle(i),
        value: i?.nodePath,
        key: i?.nodePath,
        ...i
      }))
    }
  })

  const toggleSearchActive = () => {
    setSearchActive(!searchActive)
    setDictSearchValue(null);
    setKeyword('')
  }

  useEffect(() => {
    if (searchActive) {
      searchInputRef.current?.focus()
    }
  }, [searchActive])


  const onSelectFilterOption = async(v: string, option: any) => {
   setDictSearchValue(v)
   const searchName: string = _.last(option?.nodeShowName?.split('.')) || ''
   const {nodePath, nodePathWithType} = option;
   const  allExpandKeys = getExpandNodeNeedPathKeys(option?.nodePath, true);
   //nodePat 所有父级
   const shouldExpandKeys = allExpandKeys.slice(0, allExpandKeys.length-1);
   //再次搜索 tree已经生成 不需要再次请求接口
   if (treeNodeChildrenMap[nodePath]) {
    setKeyword(searchName);
    dispatch(setExpandedKeys([...new Set(shouldExpandKeys)]))
    await dispatch(setSelectedNode(option));
    return 
   }

   //父级 nodePath总长度
   const parentNodePathWithTypes = getExpandNodeNeedPathKeys(nodePathWithType);

      //以下层级 nodePathWithTypes 和上个节点保持一致
      const level1Names =['表', '外表', '视图组', '集合', '物化视图', '键组', '文件组', '函数组', '存储过程组', '任务组', '同义词', '序列组', '触发器组', '数据库连接','包', '包体','任务组', '字典组', 'GridFs存储桶', '用户自定义函数', 'flexTableGroup']
      const level2Names =  ['列组','索引组','约束组','外键组','触发器组', '分区组'];
    let asyncActions: any = [];
    let nodePathWithTypeArr =  parentNodePathWithTypes;
   
    
   //@ts-ignore
   shouldExpandKeys?.map((key: string, index: number) => {
    //是否是表...结尾
    let endType =  key.substr(key.lastIndexOf('/') + 1);
    //如果是组 则nodePathWithType = null;
    if (/^\/root(\/g-[\w]+)$/.test(key)) {
      //@ts-ignore
      nodePathWithTypeArr.splice(index, 0, null);
    }else if (level1Names.includes(endType) || level2Names.includes(endType)) {
        const preNType = nodePathWithTypeArr[index-1]; //这里可能不对
        nodePathWithTypeArr.splice(index, 0, preNType);
      }
    })

      shouldExpandKeys.map((key, index) => asyncActions.push({nodePath: key, nodePathWithType: nodePathWithTypeArr[index]}))
 
   
    for (const node of asyncActions) {
      try {
      
      //防止重新请求 导致其他查询过数据被清空
      if (!treeNodeChildrenMap[node.nodePath]) {
        await dispatch(getTreeNodeChildren(node) )
      }; 
    
    } catch (error) {
      console.log('异步调用出错了',error);
      break;
     }
   }
   dispatch(setExpandedKeys([...new Set(shouldExpandKeys)]))
   await dispatch(setSelectedNode(option));
   setKeyword(searchName)
  }

  const onDictSearchSdtNodes = async(value: {query: string}) => {

    const res = await onSearchSdtNodes(value);

   setDictOptions(res)
  }
  
  //获取当前active pane 信息
  const paneInfoMap = useSelector(paneInfoMapSelector)
  const SdtHeader = (
    <Row className={styles.sdtHeader} justify="space-between" align="middle">
      <ThemeSwitch />
      {/* <span className={styles.txt} style={{ paddingLeft: "6px", fontSize: "14px" }}>
        数据源
      </span> */}
      <Row justify="end" style={{alignItems:'center'}}>
        <SdtPermissionSwitch onChange={() => dispatch(refreshOnRoot())}/>
        {searchActive ? (
          <Input
            ref={searchInputRef}
            size="small"
            width={40}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className={styles.searchBar}
            allowClear
            placeholder={"元素名"}
          ></Input>
        )
        :
        <Select
              allowClear
              showSearch
              size='small'
              filterOption={false}
              loading={dictLoading}
              showArrow={false}
              value={dictSearchValue}
              dropdownMatchSelectWidth={false}
              onSelect={onSelectFilterOption}
              onClear={() => { setDictSearchValue(null);setKeyword('')}}
              onSearch={(text) =>  {
                setDictOptions([])
                onDictSearchSdtNodes({query: text})
              }}
              className={styles.searchBar}
              placeholder="元素名（数据字典）"
              options={dictOptions || []}
              />
}
        <SdtHeaderIcon
          onClick={toggleSearchActive}
          iconType="icon-search"
          title={searchActive ?  "数据字典搜索": "前端搜索" }
        />
        <SdtHeaderIcon
          title="新建查询"
          onClick={() => {
            const databaseName = getInfoFromPath(
              nodePath,
              "database",
              connectionType
            );
            //若没有选中节点 使用当前active pane 的信息
            if (selectedNode?.key === undefined) {
              const { connectionId, connectionType, databaseName, schemaName } =
                paneInfoMap ?? {};
              dispatch(
                addPane({
                  connectionId,
                  connectionType,
                  databaseName,
                  schemaName,
                })
              );
              return;
            }
            const schemaName = getInfoFromPath(
              nodePath,
              "schema",
              connectionType
            );
            // 新建查询并自动选择树节点所在的执行上下文
            dispatch(
              addPane({
                connectionId,
                connectionType,
                databaseName,
                schemaName,
              })
            );
          }}
          iconType="icon-query"
        />
        {/* sql批量执行 */}
        {/* <SdtHeaderIcon 
          title='批量执行'
          iconType='icon-piliangzhihang' 
          onClick={()=>history.push('/batch_execute')}
        /> */}
        <SdtHeaderIcon
          title="添加组"
          onClick={() => dispatch(showModal("AddSdtGroup"))}
          iconType="icon-folderplus-fill"
        />
        <SdtHeaderIcon
          title="收起节点"
          onClick={() => dispatch(setExpandedKeys([]))}
          iconType="icon-collapse"
        />
        <SdtHeaderIcon
          title="刷新"
          onClick={() => {
            dispatch(refreshOnRoot())
            setKeyword('')
            setDictSearchValue(null)
          }}
          iconType="icon-sync-alt"
        />
      </Row>
    </Row>
  );

  const titleRender = (node: RenderNode) => {
    const {
      title,
      nodeType,
      connectionType,
      key,
      alias,
      nodePermissionLimits,
      parentGroupId,
      childCount,
      nodeOptions,
      applyPermissionType,
      havingPermission, //提示当前对象是否有权限
      valid,
      enable,
      connectionId,
      permissionList = {},
      nodePathWithType,
      maskedPathMap = {}, //敏感资源
    } = node

    const { dataType, remark } = nodeOptions || {}
    const isShowNodeActionIcons = actionIconNodeTypes.includes(nodeType)

    const getExtraNodeInfo = () => {
      /* 0 列 显示类型，hover 显示其他 */
      if (nodeType === 'column' && dataType) {
        return <span className={styles.extraNodeInfo}>[{dataType}]</span>
      }
      if (childCount) {
        return <span className={styles.extraNodeInfo}>({childCount})</span>
      }
      return null
    }

    const getNodeIcon = () => {
      switch (nodeType) {
        case 'connectionGroup':
          return (
            <Iconfont
              style={{ color: '#3f84e9' }}
              type="icon-folder-fill"
              className="mr8"
            ></Iconfont>
          )
        case 'connection':
          return (
            <Iconfont
              type={'icon-connection-' + connectionType}
              className="mr8"
              style={expandedKeys.includes(key) ? {} : { opacity: 0.5 }}
            ></Iconfont>
          )
        default:
          let defaultIconType = `icon-${nodeType}`
          if (noTablePermission(node)) {
            defaultIconType = `icon-${nodeType}_noPerm`
          } else if (valid === false) {
            defaultIconType = `icon-${nodeType}_disabled`
          }else if (enable === false && nodeType === 'trigger') {
            defaultIconType = `icon-${nodeType}_off`
          }
          return <Iconfont
            type={defaultIconType}
            className="mr8"
          ></Iconfont>
      }
    }
    const nodeIcon = getNodeIcon()
    const displayName = alias || title
    /* 0 权限受限 opacity 0.3 */
    const isPermissionLimit =
      nodePermissionLimits && nodePermissionLimits.length > 0
    /* 1 未展开 opacity 0.8 */
    const notExpanded = !expandedKeys.includes(key)
    /* 2 未授权连接 */
    const connectionUnavailable =
      nodeType === 'connection' && treeNodeChildrenMap[key]?.length === 0
    const isAccessDenied =
      moduleService.isModuleExist('/flow') &&
      (parentGroupId === -1 || connectionUnavailable)

    const resourceFlag = resourceMap[connectionId]

    const handleClickNode = () => {
      if (resourceFlag) return
      if (!isAccessDenied) return
      openDataSourceFlow(node)
    }

    const dataSourceTypeLevel = (type: DataSourceType) => {

      let level: number = dataSourceMap?.[type]?.dataSourceLevel || 3 ;
      if (type === 'SQLServer') {
        level = 3;
      }
      return level
    }
    //表下的触发组不能提权
    const hidePermissionIcon = (hoverNodePathWithType?.split('/').length -1 > dataSourceTypeLevel(connectionType)) && (nodeType ==='triggerGroup' || nodeType ==='trigger')

    const applyAccess = nodeType === 'connection' && applyPermissionType === 'connectionAccess'

    return (
      <div
        className={styles.sdtTitleWrap}
        onClick={handleClickNode}
        id={`${key}_key`}
        onMouseEnter={() => {
          if ( isShowNodeActionIcons) {
            setHoverNodePathWithType(nodePathWithType);
            setHoverNodeKey(key)
          }
         
        }}
        onMouseLeave={() => {
           if (isShowNodeActionIcons) {
             setHoverNodeKey('');
             setHoverNodePathWithType('');
           }
        } }
      >
        {
          resourceFlag ? null : (isAccessDenied && !applyAccess && (
            <span className={styles.applyAccess}>申请操作权限</span>
          ))
        }
        {applyAccess && (
          <span className={styles.applyAccess}>申请连接权限</span>
        )}

        <div
          className={classnames(
            notExpanded && styles.notExpanded,
            resourceFlag ? '' : isAccessDenied && styles.sdtTitle,
          )}
        >
          {nodeIcon}
          {nodeType === 'column' ? (
            <Popover
              content={getExtraNodePopoverContent(node)}
              overlayClassName={styles.popverWrap}
            >
              <span>{displayName}</span>
            </Popover>
          ) : (
            <span>{displayName}</span>
          )}
          {/* {
            havingPermission &&
          nodeType === "connection"  &&
          <Tooltip title='隐藏模式：默认隐藏无权限对象'>
              <Iconfont
                type="icon-unmask-false"
                className={styles.permissionLimitIcon}
              />
          </Tooltip>
          } */}
          {
            devModelConnectionIds && devModelConnectionIds.includes(connectionId) &&
            nodeType === "connection" &&
            <Tooltip title='开发者模式'>
              <Iconfont
                type="icon-developer"
                className={styles.permissionLimitIcon}
              />
            </Tooltip>
          }
          {getExtraNodeInfo()}
          {maskedPathMap && Object.keys(maskedPathMap)?.length > 0 && nodeType === 'table' &&  (
            <Popover
              content='敏感资源'>
              <Iconfont
                type={Object.keys(maskedPathMap).every(key => !!maskedPathMap[key]) ?"icon-lock_sensitive" : "icon-lock"}
                className={styles.permissionLimitIcon}
                onClick={() => {
                  if (Object.keys(maskedPathMap).every(key => !!maskedPathMap[key])) return
                  //无权限的脱敏资源
                  const noPermissionSensitiveResourceElements = Object.keys(maskedPathMap).filter(key => !maskedPathMap[key]).map(key => ({
                    label:'',
                    value:key 
                  }));
               
                  dispatch(
                    openFlowForm({
                      type: 'desensitizedResource',
                      fields: {
                        //@ts-ignore
                        elements: noPermissionSensitiveResourceElements,
                        //@ts-ignore
                        connectionId,
                        connectionType,
                        nodeType,
                      },
                    })
                  );
                }}
              ></Iconfont>
            </Popover>
           )}
          {remark && <span className={styles.nodeRemark}>{remark}</span>}
          {
            hoverNodeKey === key && isShowNodeActionIcons  && !hidePermissionIcon &&
            <span className={styles.tablePermTip}>
              {
               getSpecialConnectionTypePermissions(connectionType)[nodeType]?.map((type: string) => {
                  let permissionTypeStatus = permissionList[type]
                  const permissionType =  getSdtThinPermissionIconType(type);
     
                  let iconType = permissionTypeStatus ? `icon-type-${permissionType}`: `icon-type-${permissionType}_disabled`
                  return (
                    <Tooltip title={type} key={type}>
                      <Iconfont
                        type={iconType}
                        className={styles.permissionLimitIcon}
                        onClick={() => {
                          if (!permissionTypeStatus) {
                            addRun({
                              flowType: 'THIN',
                              nodeType,
                              nodePath: node?.nodePath,
                              nodePathWithType,
                              connectionId,
                              dataSourceType: connectionType,
                              nodeName: node?.nodeName,
                              operation: type
                            })
                          }
                        }}
                      />
                    </Tooltip>
                  )
                }
                )}
            </span>
          }
        </div>
      </div>
    )
  }

  /* 搜索交给接口, 实现全局节点搜索 */
  const filterNodesNotMatch = useCallback(
    (nodes: TreeNode[]): TreeNode[] => 
      nodes.filter((node) => {
        //@ts-ignore
       const name = _.last(selectedNode?.nodeShowName?.split('.')) || ''
       const sLength = selectedNode?.nodePath?.split('/').length || 0; 
       const nLength = node?.nodePath?.split('/').length || 0;
      
      
        //后端搜索 筛选信息只有一条， 前端筛选模糊匹配
        const keywordHit = searchActive ?
         ( matchKeyword(node.nodeName, keyword) ||
          matchKeyword(node.alias, keyword))
          //@ts-ignore
          : sLength === nLength && matchKeyword(node.nodeName,name)  //确保位置准确 不然可能出现多个层级

        if (!keywordHit && node.children) {
          node.children = filterNodesNotMatch(node.children)
        }
        return keywordHit || node.children?.length
      }) ,
    [keyword,searchActive],
  )

  const filteredTreeDataOnLocal = useMemo(() => {
    if (dictSearchIsOn) return // 开启字典同步, 使用远程搜索
    return keyword ? filterNodesNotMatch(cloneDeep(treeData)) : treeData
  }, [dictSearchIsOn, filterNodesNotMatch, treeData])

  /* 监听keyword触发 服务端搜索代码 到573 行 */
  const { data: filteredResult, run: searchByKeyword } = useRequest(
    (condition: string) =>
      searchTreeNodeByKeyword({
        condition,
        userId: userId!,
      }),
    {
      manual: true,
      debounceInterval: 800,
      formatResult: (tree) => {
        return tree.map((item: any) => convertResponseTreeToNodeTree(item))
      },
    },
  )

  const isKeywordValid = (keyword: string) => !!(keyword && keyword.trimStart().length > 3)

  // filteredResult是搜索的结果
  // filteredTreeData是DirectoryTree的数据源
  const filteredTreeDataOnRemote = useMemo(() => {
    if (!dictSearchIsOn) return  // 未开启字典同步, 使用本地搜索
    return isKeywordValid(keyword) ? filteredResult : treeData
  }, [dictSearchIsOn, keyword, filteredResult, treeData])

  useEffect(() => {
    if (!dictSearchIsOn) return
    isKeywordValid(keyword) && searchByKeyword(keyword)
  }, [dictSearchIsOn, keyword, searchByKeyword])

  useEffect(() => {
    if (!dictSearchIsOn) return
    if (isKeywordValid(keyword) && filteredResult) {
      let Keys: string[] = []
      filteredResult?.forEach((item: any) => {
        Keys = Keys.concat(getExpandKeysNodeTreeAllChildren(item))
      })
      dispatch(setExpandedKeys(Keys))
    }
    !keyword && dispatch(setExpandedKeys([]))
  }, [dictSearchIsOn, filteredResult, keyword])

  /* 当在搜索的时候，会展开所有节点，需要移除onLoadData方法否则会自动触发大量请求 */
  const getLoadMethod = useCallback(
    (treeNode: DataNode) => {
      if (dictSearchIsOn && keyword) return Promise.resolve()
      return onLoadData(treeNode)
    },
    [dictSearchIsOn, keyword, onLoadData],
  )

  // 根据dictSyncSwitchOn判断是否启用远程搜索
  const filteredTreeData = useMemo(
    () =>
      dictSearchIsOn ? filteredTreeDataOnRemote! : filteredTreeDataOnLocal!,
    [dictSearchIsOn, filteredTreeDataOnLocal, filteredTreeDataOnRemote],
  )

  const handleDoubleClick = (e: any, node: any) => {

    //当表收起时候 selectedNode依然有值
    if (!selectedNode || (selectedNode?.nodeName !== node?.nodeName)) return

    const { nodeType, nodePath, parentGroupId } = selectedNode
    if (parentGroupId === -1) return
    if (expandedKeys.some((key) => key === nodePath)) return
    if (NodeTypesSupportEditorView.includes(nodeType)) {
      if (
        nodeType === 'sequence' ||
        nodeType === 'package' ||
        nodeType === 'type' ||
        nodeType === 'packageBody' ||
        nodeType === 'trigger'
      )
        dispatch(showViewInEditor(selectedNode))
      else dispatch(viewElementInEditor(selectedNode))
    }
    if (nodeType === 'redisKey') {
      dispatch(viewRedisKey(selectedNode))
    }
    // Redis 以外的连接，双击直接打开查询
    if (nodeType === 'connection' && connectionType !== 'Redis') {
      dispatch(
        addPane({
          tabName: nodeName,
          connectionType,
          connectionId,
        }),
      )
    }
  }

  const isConnecttionNode = ({ nodeType }: any) => nodeType === 'connection'
  const isConnectionGroupNode = ({ nodeType }: any) =>
    nodeType === 'connectionGroup'
  const isSameGroupMember = (nodeA: any, nodeB: any) =>
    nodeA.parentGroupId === nodeB.parentGroupId
  const isAccessDenyConnectionNode = ({
    parentGroupId,
  }: {
    parentGroupId: number
  }) => parentGroupId === -1
  const isAccessDenyGroupNode = ({ groupId }: { groupId: number }) =>
    groupId === -1
  const isAccessDenyNode = (node: any) =>
    isAccessDenyConnectionNode(node) || isAccessDenyGroupNode(node)
  // 拖拽排序
  // todo:组不支持拖拽
  const onDrop: TreeProps['onDrop'] = async (info) => {
    try {
      const { node, dragNode, dropToGap, dropPosition } = info as any

      // 0 无权限组不允许拖拽或排序
      // 1 drag 节点只允许为数据源类型节点
      if (
        !isConnecttionNode(dragNode) ||
        isAccessDenyNode(dragNode) ||
        isAccessDenyNode(node)
      ) {
        message.error('只允许有权限数据源拖拽排序或调整组')
        return
      }
      // 2 入组 drop 节点为组类型 && dropToGap 为 true
      if (
        isConnectionGroupNode(node) &&
        (!dropToGap || (node.expanded && node.dragOverGapBottom))
      ) {
        console.log('开始分组啦')
        const connectionId = dragNode.connectionId
        const oldGroupId = dragNode.parentGroupId
        const newGroupId = node.groupId
        if (oldGroupId === newGroupId) return
        await moveConnectionToSdtGroup(connectionId, oldGroupId, newGroupId)
        dispatch(refreshOnRoot())
        return
      }

      //  3 拖拽到最外层组最上方,只有一个组的情况下
      if (isConnectionGroupNode(node) && dropPosition === -1) {
        const connectionId = dragNode.connectionId
        const oldGroupId = dragNode.parentGroupId
        const newGroupId = node.parentGroupId
        if (oldGroupId === newGroupId) return
        await moveConnectionToSdtGroup(connectionId, oldGroupId, newGroupId)
        dispatch(refreshOnRoot())
        return
      }

      // 3 排序 drop 节点为数据源类型 && drag 和 drop 节点为同组节点
      if (isConnecttionNode(node) && isSameGroupMember(node, dragNode)) {
        console.log('开始排序啦')
        const groupId = dragNode.parentGroupId
        const connectionId = dragNode.connectionId
        const preConnectionId = node.connectionId
        await sortConnection_api({ groupId, connectionId, preConnectionId })
        dispatch(refreshOnRoot())
        return
      }

      // 4 入组 drop 节点为数据源类型 && drag 和 drop 节点非同组节点
      if (isConnecttionNode(node) && !isSameGroupMember(node, dragNode)) {
        console.log('开始入组')
        const connectionId = dragNode.connectionId
        const oldGroupId = dragNode.parentGroupId
        const newGroupId = node.parentGroupId
        await moveConnectionToSdtGroup(connectionId, oldGroupId, newGroupId)
        dispatch(refreshOnRoot())
        return
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <section className={styles.sdtWrapper}>
      {SdtHeader}
      <div ref={domRef} className={styles.sdtContent}>
        <Spin spinning={treeLoading} style={{ height: rect?.height }}>
          <DropdownMenu>
            <DirectoryTree
              className={styles.tree}
              height={rect?.height}
              ref={treeRef}
              loadData={onLoadData}
              treeData={filteredTreeData}
              expandAction="doubleClick"
              selectedKeys={selectedNode?.key ? [selectedNode.key] : undefined}
              onSelect={handleNodeSelect}
              titleRender={titleRender as any}
              blockNode
              showIcon={false}
              loadedKeys={loadedKeys}
              onLoad={(loadedKeys) => dispatch(setLoadedKeys(loadedKeys))}
              expandedKeys={[...expandedKeys]}
              onExpand={(expandedKeys) =>
                dispatch(setExpandedKeys(expandedKeys))
              }
              onRightClick={({ node }: {node: any}) => {
                if (noTablePermission(node)) {
                  dispatch(setNoAccessRightClick(true))
                  return
                }
                dispatch(setNoAccessRightClick(false))
                // @ts-ignore
                if (node.nodeType === 'table') {
                  let root = findTreeRootNode(filteredTreeData, node);
                  let connectionName = root?.nodeName!;
                   //组内 connection 不是root 根节点
                  if (node.nodePath.includes('/root/g-')) {
                    const regex = /\/root\/(g-\d+\/)+/g;
                    const str = node.nodePath.match(regex)?.[0];
             
                    const parGroupPath = str && str.substring(0, str.length - 1);
                    const list = treeNodeChildrenMap?.[parGroupPath] || [];
                    const res  = list?.find(item => item.connectionId === node.connectionId);
                    connectionName = res?.nodeName!;
                  }

                  const parentNode = findTreeParentNode(root, node)
                  if (!parentNode) return
                  const { nodeType, connectionId, connectionType, nodeName, nodePath, nodePathWithType, groupId } = parentNode

                  dispatch(setTargetTableMessage({
                    connectionName,
                    connectionId,
                    dataSourceType: connectionType,
                    nodePath,
                    nodePathWithType,
                    // @ts-ignore
                    tableName: node?.nodeName,
                  }))
                  // getNodeChildList({ connectionId, connectionType, nodeType, nodeName, nodePath, nodePathWithType, groupId, globalHavePermissionFlag: status }).then((v) => {
                  //   dispatch(setTargetTableList(v?.data))
                  // })
                  dispatch(setTargetTableList(treeNodeChildrenMap[nodePath]))
                }

                // 右键表组节点直接获取节点下所有表信息
                // @ts-ignore
                if (node.nodeType === 'tableGroup') {
                  const root = filteredTreeData.find(({ nodePath }: { nodePath: any }) => {
                    //@ts-ignore
                    const targetArr = node.nodePath.split('/')
                    const sourceArr = nodePath.split('/')
                    return sourceArr.every((s: any, index: number) => {
                      return sourceArr[index] === targetArr[index]
                    })
                  })
                  const connectionName = root?.nodeName!
                  // @ts-ignore
                  const { nodeType, connectionId, connectionType, nodeName, nodePath, nodePathWithType, groupId } = node
                  dispatch(setTargetTableMessage({
                    connectionName,
                    connectionId,
                    dataSourceType: connectionType,
                    nodePath,
                    nodePathWithType,
                    tableName: nodeName
                  }))
                  // getNodeChildList({ connectionId, connectionType, nodeType, nodeName, nodePath, nodePathWithType, groupId}).then((v) => {
                  //   dispatch(setTargetTableList(v?.data))
                  // })
                  dispatch(setTargetTableList(treeNodeChildrenMap[nodePath]))
                }

                dispatch(setRightClickedNode(node))
              }}
              onDoubleClick={handleDoubleClick}
              onDrop={onDrop}
              draggable
            />
            {!treeLoading && !filteredTreeData.length && (
              <div className={styles.treePlaceholder}>暂无元素</div>
            )}
          </DropdownMenu>
        </Spin>
      </div>
      <CreateConnectionModal onFinish={() => dispatch(refreshOnRoot())} />
      <CreateDatabaseModal />
      <AddSdtGroupModal />
      <MoveToSdtGroupModal />
      <RenameSdtNodeModal />
      <AddSdtNodeExportModal sdtNodeExportParams={sdtNodeExportParams} selectedNode={selectedNode}/>
      <ModalAddSchema />
      <AddSubSdtGroupModal />
      <UpdateConnAliasModal />
      {BatchCreateConnectionModalVisible && <BatchCreateConnectionModal />}
      <DumpExportModal />
      <DumpImportModal />
      <SQLImportModal />
    </section>
  )
})

