/**
 * 自定义权限等级
 */
import React, { useEffect, useState } from 'react'
import { Table, Drawer, Alert, Tabs, Modal, Radio, message, Row, Input, Button, Tooltip } from 'antd'
import {
  togglePermisssion,
  deletePermissionItem,
  queryPermissionHistory,
} from 'src/api'
import { queryPermissionList } from './authorizationManagePageSlice'
import { useSelector, useDispatch } from 'src/hook'
import RelationUser from './RelationUser'
import OperationPermissionsModal from './OperationPermissionsModal'
import classnames from 'classnames'
import { renderTableFields, getScrollX } from 'src/util'
import { HighRiskResourceTable } from './HighRiskResourceTable';
import styles from './index.module.scss'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

const CUSTOM_PERMISSION_LEVEL = 'customPermissionLevel';
const HIGH_RISKRESOURCE = 'highRiskResource';

interface IProps {
  dataSourceType: string
  [p: string]: any
}

const CustomPermissionList = (props: IProps) => {
  const { dataSourceType, permissionlist } = props
  const { isOnlyRead, roleNameList } = permissionlist || {}
  const dispatch = useDispatch()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [relationUserVisible, setRelationUserVisible] = useState(false)
  const [permissionModalVisible, setPermissionModalVisible] = useState(false)
  const [optionType, setOptionType] = useState('look')
  const [perssionInfo, setPerssionInfo] = useState<any>({})
  const [historyData, setHistoryData] = useState<any[]>([])

  const [searchValue, setSearchValue] = useState('')
  const [tabKey, setTabKey] = useState(CUSTOM_PERMISSION_LEVEL)

  const { permissionList } = useSelector((state) => state.authorizationManagement)

  useEffect(() => {
    if (dataSourceType) {
      queryList(dataSourceType)
      setSearchValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceType])

  // 查询列表
  const queryList = (dataSourceType: string) => {
    dispatch(queryPermissionList(dataSourceType))
  }

  // 禁用/启用权限
  const handleTogglePermission = (item: any) => {
    const { status, id } = item

    const onChange = (e: any) => {
      const value = e.target.value
      update({
        onOk: (fn) => {
          handleConfirmModalOk(fn, status, id, value)
        },
      })
    }
    const { update } = Modal.confirm({
      title: `确认${status === 'ENABLE_TEMPLATE' ? '禁用' : '启用'}权限吗？`,
      okText: '确定',
      cancelText: '取消',
      content:
        status === 'ENABLE_TEMPLATE' ? (
          <Radio.Group
            onChange={onChange}
            defaultValue={'DISABLE_TEMPLATE_AND_PERMISSION'}
          >
            <Radio value={'DISABLE_TEMPLATE_AND_PERMISSION'}>回收权限</Radio>
            <Radio value={'DISABLE_TEMPLATE'}>不回收权限</Radio>
          </Radio.Group>
        ) : null,
      onOk: (fn) => {
        handleConfirmModalOk(fn, status, id)
      },
    })
  }

  // 禁用/启用权限
  const handleConfirmModalOk = (
    closeFn: () => void,
    status: string,
    id: number,
    value = 'DISABLE_TEMPLATE_AND_PERMISSION',
  ) => {
    let checkValue = value
    if (status !== 'ENABLE_TEMPLATE') {
      checkValue = 'ENABLE_TEMPLATE'
    }
    const params = {
      id,
      status: checkValue,
    }
    togglePermisssion(params)
      .then(() => {
        message.success('操作成功')
        queryList(dataSourceType)
      })
      .catch((err: any) => {
        console.error('禁用、启用失败：', err)
      })
    closeFn()
  }

  // 删除权限
  const handleDelete = (item: any) => {
    Modal.confirm({
      title: '确认删除此权限等级吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        const { id } = item
        const ids = [id]
        deletePermissionItem(ids)
          .then(() => {
            message.success('删除成功')
            queryList(dataSourceType)
          })
          .catch((err: any) => {
            console.error('删除失败：', err)
          })
      },
    })
  }

  // 查看修订历史
  const queryHistory = (item: any) => {
    const { id } = item
    setPerssionInfo(item);
    setDrawerVisible(true)
    queryPermissionHistory(id)
      .then((res: any) => {
        setHistoryData(res)
        setDrawerVisible(true)
      })
      .catch((err: any) => {
        console.error('查看历史失败：', err)
      })
  }

  // 查看用户资源
  const handleLookUsers = (record: any) => {
    setPerssionInfo(record)
    setRelationUserVisible(true)
  }

  // 查看权限等级
  const handleLookPermission = (record: any) => {
    setOptionType('look')
    setPerssionInfo(record)
    setPermissionModalVisible(true)
  }

  // 编辑权限等级
  const handleEditPermission = (record: any) => {
    setOptionType('edit')
    setPerssionInfo(record)
    setPermissionModalVisible(true)
  }

  const columns: any[] = [
    {
      title: '权限等级名',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    },
    {
      title: '备注',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    },
    {
      title: '关联用户/资源',
      dataIndex: 'dept',
      key: 'dept',
      width: 140,
      render: (_: string, record: any) => (
        <span
          className={styles.options}
          onClick={() => handleLookUsers(record)}
        >
          查看
        </span>
      ),
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      key: 'creator',
      width: 200,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    },
    {
      title: '最后修改人',
      dataIndex: 'editor',
      key: 'editor',
      width: 200,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    },
    {
      title: '修订历史',
      dataIndex: 'history',
      key: 'history',
      width: 200,
      render: (_: string, record: any) => (
        <span className={styles.options} onClick={() => queryHistory(record)}>
          查看
        </span>
      ),
    },
    {
      title: '操作',
      dataIndex: 'option',
      key: 'option',
      width: 200,
      fixed: 'right',
      render: (_: string, record: any) => (
        <div className={styles.options}>
          <span
            className={classnames('linkText',{
              [styles.disabled]: !record?.supportShow,
            }, styles.padding0)}
            onClick={() => {
              record?.supportShow && handleLookPermission(record)
            }}
          >
            查看
          </span>
          <PermissionTooltip
            title="手动授权"
            permissionlist={permissionlist}
          >
            <span
              className={classnames('linkText', {
                [styles.disabled]: !record?.supportEdit,
              },'ml10')}
              onClick={() => {
                record?.supportEdit && handleEditPermission(record)
              }}
            >
              编辑
            </span>
            <span
              className={classnames(styles.ml10, {
                [styles.disabled]: !record?.supportDelete,
              },'ml10')}
              onClick={() => record?.supportDelete && handleDelete(record)}
              style={{padding: 0}}
            >
              删除
            </span>
            <span
              className={classnames(styles.ml10, {
                [styles.disabled]: !record?.supportDisable,
              },'ml10')}
              onClick={() =>
                record?.supportDisable && handleTogglePermission(record)
              }
            >
              {record?.status === 'ENABLE_TEMPLATE' ? '禁用' : '启用'}
            </span>
          </PermissionTooltip>
        </div>
      ),
    },
  ]

  return (
    <div className={styles.customAuthorizeWrap}>
      <Alert
        className={styles.mb20}
        message="自定义操作权限集合，在授权时可将自定义权限授权给用户"
        type="info"
        showIcon
      />
      <div className={styles.tabBox}>
        <Tabs onChange={setTabKey} activeKey={tabKey}>
          <Tabs.TabPane tab="自定义权限等级" key={CUSTOM_PERMISSION_LEVEL} >
            <Table
              columns={columns}
              dataSource={permissionList}
              rowKey="id"
              scroll={{
                x: getScrollX(columns),
                y: "calc(100vh - 450px)",
              }}
              pagination={false}
              className={styles.customUaTable}
              rowClassName={(record) => record?.status !== 'ENABLE_TEMPLATE' ? 'invalidGreyBg' : ''}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="高危申请详情" key={HIGH_RISKRESOURCE} >
            <HighRiskResourceTable
              searchValue={searchValue}
              dataSourceType={dataSourceType}
              key={dataSourceType}
              permissionlist={permissionlist}
            />
          </Tabs.TabPane>
        </Tabs>
        {tabKey === HIGH_RISKRESOURCE && <Input.Search
          className={styles.highRiskResourceSearch}
          onSearch={setSearchValue}
        />}
      </div>

      {/* 修订历史 */}
      <Drawer
        width={520}
        title="修订历史"
        placement="right"
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
        }}
      >
        <div className={styles.mb10}>权限名：{perssionInfo?.name}</div>
        <div className={styles.mb10}>备注：{perssionInfo?.description}</div>
        <div>数据源类型：{perssionInfo?.dataSourceType}</div>
        <div className={styles.historyContentWrap}>
          {historyData?.map((i: any, index: number) => {
            return (
              <Row
                key={index}
                className={classnames(styles.listItems, styles.mb10)}
              >
                <span className={styles.mr20}>[{i?.createAt}]</span>
                <span className={styles.desc}>
                  {`${i?.userName}(${i?.userId})`}
                  <span
                    className={classnames({
                      [styles.colorgreen]: i?.action === "新增",
                      [styles.colorf00]: i?.action === "删除",
                      [styles.color008dff]: !["新增", "删除"]?.includes(
                        i?.action
                      ),
                    })}
                  >
                    &nbsp;{i?.action}&nbsp;
                  </span>
                  <span style={{ wordBreak: 'break-all' }}>{i?.operationStrings}</span>
                </span>
              </Row>
            );
          })}
        </div>
      </Drawer>

      {/* 关联用户 */}
      {relationUserVisible && (
        <RelationUser
          visible
          perssionInfo={perssionInfo}
          setRelationUserVisible={setRelationUserVisible}
          permissionlist={permissionlist}
        />
      )}

      {/* 操作权限 - 查看或编辑 */}
      {permissionModalVisible && (
        <OperationPermissionsModal
          visible
          optionType={optionType}
          perssionInfo={perssionInfo}
          dataSourceType={dataSourceType}
          setModalVisible={setPermissionModalVisible}
        />
      )}
    </div>
  );
}
export default CustomPermissionList