import { Chart } from '@antv/g2'
import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getAuditDBTypeAmountUnit, SqlCountByDbType } from 'src/api'
import { useRequest } from 'src/hook'
import chartStyles from './chart.module.scss'
import { EmptyChart } from './EmptyChart'
import { ChartColors } from './util'

interface ChartData {
  item: string
  count: number
  percent: number
}

const getAdaptedchartData = (data: SqlCountByDbType[]): ChartData[] => {
  const total = data.reduce((prev, curr) => prev + Number(curr.amount), 0)
  return data.map(({ dbType, amount }) => ({
    item: dbType,
    count: amount,
    percent: amount / total,
  }))
}

export const DbCountChart = () => {
  const history = useHistory()
  const { data = [], loading } = useRequest(getAuditDBTypeAmountUnit, {
    formatResult: (data) => getAdaptedchartData(data),
    onSuccess: () => {
      // 过滤掉item为null的数据
      renderSqlCountChart('db-count', data.filter(d => d.item !== null))
    },
  })
  const renderSqlCountChart = (container: string, data: ChartData[]) => {
    data = data.sort((a, b) => b.count - a.count)
    const chart = new Chart({
      container,
      autoFit: true,
    })

    chart.data(data)
    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.55,
    })

    // 自定义图例
    chart.legend('item', {
      // todo: 增加图表右 padding，目前 legend 太靠近边缘
      position: 'right',
      custom: true, // 告诉 G2 要使用自定义图例
      items: data.map((obj, index) => {
        return {
          name: obj.item,
          value: obj.percent,
          marker: {
            symbol: 'square', // marker 形状
            style: {
              r: 6, // marker 图形半径
              fill: ChartColors[index % ChartColors.length], // marker 颜色，使用默认颜色，同图形对应
            },
          },
        }
      }),
      itemValue: {
        style: {
          fill: '#999',
        },
        formatter: (val) => `${(Number(val) * 100).toFixed(1)}%`,
      },
    })

    chart
      .interval()
      .adjust('stack')
      .position('percent')
      .color('item', ChartColors)
      .label('percent', () => ({
        content: (data) => data.item,
        offset: 24,
      }))
      .tooltip('item*count', (item, count) => {
        return {
          name: item,
          value: count,
        }
      })
    chart.tooltip({
      showTitle: false,
      showMarkers: false,
      itemTpl:
        '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}：{value} 条语句</li>',
    })

    chart.interaction('element-active')
    chart.on('plot:click', (ev: any) => {
      if (ev?.data?.data) {
        history.push({
          pathname: `/statement_detail`, state: {
            dbTypes: [ev?.data?.data?.item],
          }
        })
      }
    })
    //TODO:
    chart.render()
  }

  useEffect(() => { }, [data])
  return (
    <Spin spinning={loading}>
      <div id="db-count" className={chartStyles.dbCount}>
        {!data[0] && !loading && <EmptyChart></EmptyChart>}
      </div>
    </Spin>
  )
}
