import React, { PropsWithChildren, useContext } from 'react'
import { Dropdown, Menu, message, Space, Tooltip, Upload } from 'antd'
import styles from './index.module.scss'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { EditorContext, Iconfont } from 'src/components'
import {
  setMonacoServerFile,
  updateMonaco,
  updateMonacoTabEncoding,
  updateMonacoTabName,
  pushMonacoValue,
} from '../../queryTabsSlice'
import { getFileContent, getRecentFiles, getUserSysFile } from 'src/api'
import { ServerFilesModal } from './ServerFilesModal'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { Encoding } from 'src/constants'
// import { CaretDownOutlined } from '@ant-design/icons'
import { sliceReadFile } from 'src/util'

export const RecentlyOpenedFiles = ({
  encoding,
}: PropsWithChildren<{ encoding?: Encoding }>) => {
  const { activeTabKey } = useSelector((state) => state.queryTabs)
  const { editorInstance } = useContext(EditorContext)
  const dispatch = useDispatch()

  const { data: recentFiles = [], refresh } = useRequest(getRecentFiles, {
    formatResult: (recentFiles) => recentFiles.slice(0, 11),
  })

  const {data: getUserUpload} = useRequest(getUserSysFile)

  /* 系统设置 --- 上传文件上限 */
  const { fileMax } = useSelector((s) => s.login.userInfo)
  return (
    <Space className={styles.recentlyOpenedFiles} size={1}>
      <Dropdown
        overlay={
          <Menu className={styles.recentFilesMenu}>
            {recentFiles.map((recentFile) => {
              const { name, path } = recentFile
              return (
                <Menu.Item
                  onClick={() => {
                    const model = editorInstance?.getModel()
                    getFileContent(path).then(({ content, encoding }) => {
                      dispatch(setMonacoServerFile(recentFile))
                      dispatch(updateMonacoTabName(name))
                      dispatch(updateMonacoTabEncoding({ encoding }))
                      model?.pushEditOperations(
                        null,
                        [
                          {
                            range: model?.getFullModelRange(),
                            text: content,
                          },
                        ],
                        () => null,
                      )
                      editorInstance?.focus()
                    })
                  }}
                  key={path}
                >
                  <div className={styles.recentFile}>{name}</div>
                </Menu.Item>
              )
            })}
            {!recentFiles[0] && <Menu.Item disabled>暂无最近的文档</Menu.Item>}
            <Menu.Divider />
            <Menu.Item
              onClick={() => dispatch(showModal('ModalOpenServerFile'))}
            >
              更多...
            </Menu.Item>
          </Menu>
        }
        placement="bottomCenter"
        trigger={['hover']}
        mouseLeaveDelay={0.3}
        onVisibleChange={(visible) => {
          if (!visible) return
          refresh()
        }}
      >
        <Iconfont
          type="icon-yunpanwenjianjia"
          className={styles.monacoToolbarIcon}
        />
      </Dropdown>
      <Upload
        beforeUpload={(file) => {
          // 后端让前端加默认值100M,理由是网络波动有可能拿不到设置的最大文件大小
          const uploadSize = getUserUpload?.uploadSize || 100
          const fileMbSize = file.size / 1024 / 1024
          const isLessThanSysFileMaxSetting =
          uploadSize && fileMbSize < uploadSize
          if (!isLessThanSysFileMaxSetting) {
            message.warning(`文件大小上限 ${uploadSize}M`)
            return false
          }
          dispatch(updateMonaco({ key: activeTabKey, value: '' }))

          sliceReadFile(file, async (chunk: Blob, i: any) => {
            return new Promise((resolve, reject) => {
              const fileReader = new FileReader()
              fileReader.onload = function (this: any) {
                const text = this.result
                dispatch(
                  pushMonacoValue({
                    key: activeTabKey,
                    tailText: text || '',
                  }),
                )
                resolve(true)
              }
              fileReader.onerror = () => reject(false)
              fileReader.readAsText(chunk, encoding)
            })
          })
          return false
        }}
        showUploadList={false}
      >
        {/* 打开本地文档 */}
        <Tooltip title="打开本地文档" placement="bottomLeft" arrowPointAtCenter>
          <Iconfont type="icon-document" className={styles.monacoToolbarIcon} />
        </Tooltip>
      </Upload>
      <ServerFilesModal />
    </Space>
  )
}
