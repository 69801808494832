import React, { useEffect, useState } from 'react';
import { Button, Popconfirm, Table, Tag, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  getFlowPermissionList,
  delPermission,
} from 'src/api';
import { EffectiveUserModal } from './EffectiveUserModal';
import _ from 'lodash';

export interface IUserInfoList {
  userId: string;
  userName: string;
  deptName: string;
  expr: string;
  permissionId: number;
}

interface DataType {
  operation: string;
  operationName: string;
  object: string;
  userInfoList: IUserInfoList[];
}

interface IProps {
  searchValue: string;
  dataSourceType: string;
  [p: string]: any
}

export const HighRiskResourceTable: any = (props: IProps) => {
  const { searchValue = '', dataSourceType, permissionlist } = props;
  const { isOnlyRead, roleNameList } = permissionlist || {}
  const [currentData, setCurrentData] = useState<DataType[]>([])
  const [allData, setAllData] = useState<DataType[]>([])


  useEffect(() => {
    if (dataSourceType) {
      initData(dataSourceType, searchValue);
    }
  }, [dataSourceType]);

  useEffect(() => {
    if (dataSourceType) {
      updateData(allData, searchValue);
    }
  }, [searchValue]);

  const initData = (dataSourceType: string, searchValue: string) => {
    getFlowPermissionList(dataSourceType).then((res: any[]) => {
      if (Array.isArray(res)) {
        const fomatData = res.map((item, index) => {
          const { operation } = item || {};
          return { ...item, key: `${index}${operation}` }
        })
        setAllData(fomatData);
        // 处理有searchValue的情况
        updateData(fomatData, searchValue);
      }
    })
  }

  const updateData = (data: DataType[], searchValue: string) => {
    const searchData = searchValue ? data.filter(item => {
      const { operation, operationName } = item || {};
      const searchString = `${operation}（${operationName}）`;

      return searchString.indexOf(searchValue.toUpperCase()) > -1
    }) : _.cloneDeep(data);
    setCurrentData(searchData);
  }

  const delPermissions = (permissionIds: number[]) => {
    delPermission({ permissionIds }).then(() => {
      message.success('删除成功');
      initData(dataSourceType, searchValue);
    })
  }

  const columns: ColumnsType<DataType> = [
    {
      title: '高危类型',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => {
        const { operationName } = record || {};

        return <Tag color="#d3d3d3" style={{ whiteSpace: "normal" }}>
          {text}（{operationName}）
        </Tag>
      }

    },
    {
      title: '高危对象',
      dataIndex: 'object',
      key: 'object',
    },
    {
      title: '生效用户',
      dataIndex: 'address',
      key: 'address',
      render: (value, record, index) => {
        const { userInfoList } = record || {};

        return <EffectiveUserModal
          key={`${index}${value}`}
          data={userInfoList}
          onDelete={(id) => delPermissions([id])}
        />
      }
    },
    {
      title: '操作',
      key: 'action',
      render: (value, record, index) => {
        const permissionIds: number[] = [];
        const { userInfoList } = record || {};
        if (Array.isArray(userInfoList)) {
          userInfoList.forEach(item => {
            const { permissionId } = item || {};
            permissionIds.push(permissionId)
          })
        }
        return <Popconfirm
          key={`${index}${value}`}
          title="确定删除吗"
          onConfirm={() => delPermissions(permissionIds)}
          okText="确定"
          cancelText="取消"
        >
          <Button
            type="link"
            danger
            style={{ paddingLeft: 0 }}
          >删除</Button>
        </Popconfirm>
      }
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={currentData}
      pagination={false}
      scroll={{
        y: "calc(100vh - 450px)",
      }}
    />
  );
}
