import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import { Form, Input, message, Select, Spin } from 'antd'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import {
  executeSqlStatement,
  generateCreateSql,
  GenerateSqlRequest,
  getSchemaOwners,
  getWizardForm,
} from 'src/api'
import { getWizardFormItem } from 'src/features/wizards/wizardFormItem'
import { FormLayout } from 'src/constants'
import { refreshOnRoot } from '../sdtSlice'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'

export const ModalAddSchema = () => {
  const dispatch = useDispatch()
  const { ModalAddSchema } = useSelector((state) => state.modal)
  const { selectedNode } = useSelector((state) => state.sdt)
  const { connectionId, connectionType, nodeType, nodePath } = selectedNode
  const databaseName = useMemo(() => nodePath?.split('/').slice(-2)?.[0], [nodePath])
  const { handleExternalInfo } = useHandleExternalInfo()
  

  const {
    data: formSpecs,
    run: tryGetFormSpecs,
    loading: loadingGetFormSpecs,
  } = useRequest(getWizardForm, { manual: true })
  const {
    data: schemaOwners,
    run: tryGetSchemaOwners,
    loading: loadingGetSchemaOwners,
  } = useRequest(getSchemaOwners, { manual: true })

  const ownerOptions = schemaOwners?.map((owner) => ({
    value: owner,
    label: owner,
  }))

  const [addSchemaForm] = Form.useForm()

  useEffect(() => {
    if (!ModalAddSchema) return
    tryGetFormSpecs({
      dataSourceName: connectionType,
      nodeType,
    })
    tryGetSchemaOwners({ connectionType, connectionId })
  }, [
    ModalAddSchema,
    tryGetFormSpecs,
    connectionType,
    connectionId,
    nodeType,
    tryGetSchemaOwners,
  ])

  // generated sql
  const [generatedSql, setGeneratedSql] = useState('')

  const generateSql = () => {
    addSchemaForm
      .validateFields()
      .then((userInputs) => {
        const params: GenerateSqlRequest = {
          nodeType,
          dataSourceType: connectionType!,
          userInputs,
        }
        generateCreateSql(params).then(({ generatedSql }) => {
          setGeneratedSql(generatedSql)
        })
      })
      .catch(() => {
        setGeneratedSql('')
      })
  }

  const { run: tryAddSchema, loading: loadingAddSchema } = useRequest(
    executeSqlStatement,
    {
      manual: true,
      onSuccess: (data) => {
        const { executionInfos } = data
        const [{ response: { executeError } }] = executionInfos
        executionInfos.forEach((item) => {
          handleExternalInfo({ type: 'LOG', msg: item.executeLogInfo.message })
        })
        if (executeError) {
          message.error(executeError.message)
        } else {
          message.success('模式添加成功')
          dispatch(refreshOnRoot())
          dispatch(hideModal('ModalAddSchema'))
        }
      },
    },
  )

  const handleAfterClose = ()=>{
    addSchemaForm.resetFields()
    setGeneratedSql('')
  }

  return (
    <UIModal
      title="添加模式"
      visible={ModalAddSchema}
      onCancel={() => dispatch(hideModal('ModalAddSchema'))}
      onOk={() => {
        tryAddSchema({
          connectionId,
          dataSourceType: connectionType,
          databaseName,
          statements: [generatedSql],
          tabKey: 'ALL',
          actionType: 'EXECUTE'
        })
      }}
      afterClose={() => handleAfterClose()}
      confirmLoading={loadingAddSchema}
    >
      <Spin spinning={loadingGetFormSpecs}>
        <Form
          {...FormLayout}
          form={addSchemaForm}
          onValuesChange={() => {
            setTimeout(() => {
              // ? ref: https://github.com/ant-design/ant-design/issues/26747
              generateSql()
            })
          }}
        >
          {formSpecs
            ?.filter((spec) => spec.field !== 'owner')
            .map((formSpec) => getWizardFormItem({spec:formSpec}))}
            {/* 与动态表单保持一致，只有后端有返回字段的情况下才显示此表单 */}
          {( formSpecs && formSpecs?.findIndex((spec) => spec.field === 'owner') > -1) 
            && <Form.Item label="模式拥有者" name="owner">
            <Select
              placeholder="请选择模式拥有者"
              options={ownerOptions}
              loading={loadingGetSchemaOwners}
              showSearch
              allowClear
            />
          </Form.Item>}
          <Form.Item label="生成 SQL">
            <Input.TextArea
              readOnly
              style={{ backgroundColor: '#fefefe', height: 240 }}
              value={generatedSql}
            />
          </Form.Item>
        </Form>
      </Spin>
    </UIModal>
  )
}
