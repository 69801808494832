import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRequest } from 'src/hook'
import { Card, Input, message, Form, Typography} from 'antd'
import { FormInstance } from 'antd/lib/form'
import { LinkButton } from 'src/components'
import { SettingFormContext } from '../SettingFormContext'
import { getCompanyName, updateCompanyName} from 'src/api'
import styles from './index.module.scss'
import 'src/styles/layout.scss'

const { Text } = Typography

export const CompanyCard: React.FC = () => {
  const {
    loading: fetchLoading,
    data,
    refresh: refreshCompanyName,
  } = useRequest(getCompanyName, {
    onSuccess: ({ company }) => {
      form.setFields([{ name: 'company', value: company }])
    },
  })
  const company = data?.company

  const { loading: saveLoading, run: saveCompanyName } = useRequest(
    updateCompanyName,
    {
      manual: true,
      onSuccess: () => {
        message.success('修改成功')
        setEditing(false)
        refreshCompanyName()
      },
    },
  )

  const [editing, setEditing] = useState(false)
  const inputRef = useRef<Input>(null)
  const form = useContext(SettingFormContext) as FormInstance

  useEffect(() => {
    if (editing) {
      inputRef.current?.select()
    }
  }, [editing])

  const handleSave = useCallback(() => {
    form.validateFields(['company']).then(({ company }) => {
      saveCompanyName(company)
    })
  }, [form, saveCompanyName])

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={handleSave} loading={saveLoading}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              form.setFields([{ name: 'company', value: company }])
              form.validateFields(['company'])
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
      )}
    </div>
  )

  return (
    <section className="cq-card flow-card" id="CompanyCard">
      <div className="cq-card__headerbar">
        <h3 className="cq-card__title">组织架构</h3>
        {extraOperations}
      </div>
      <section className="card__content">
        <Card
          className={styles.settingCardContent}
          bordered={false}
          loading={fetchLoading}
        >
          <Form.Item label="公司名称">
            <Form.Item
              name="company"
              rules={[{ required: true, message: '请输入公司名称' }]}
              hidden={!editing}
              noStyle
            >
              <Input
                ref={inputRef}
                placeholder={'请输入公司名称'}
                onPressEnter={handleSave}
              />
            </Form.Item>
            {!editing && <Text>{company || ''}</Text>}
          </Form.Item>

        </Card>
      </section>
    </section>
  )
}
