/**
 * 权限详情 modal
 */
import { Button, Col, Empty, Modal, Popconfirm, Row, Table, Tabs, message } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { delPermissionInfo, getPermissionInfos } from 'src/api'
import { useRequest } from 'src/hook'
import styles from './index.module.scss'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

interface IProp {
  visible: boolean,
  setVisible: (v: boolean) => void
  params: any
  userId: string | undefined
  [p: string]: any
}

const PermissionInfoModal = (props: IProp) => {
  const { visible, setVisible, params, userId, permissionlist } = props
  const [permissionInfos, setPermissionInfos] = useState<any>([])
  const {isOnlyRead, roleNameList} = permissionlist || {}

  // 查询当前层级下的所有权限
  const { run: getPermissionInfosRun, loading: permissionInfosLoading } = useRequest(
    getPermissionInfos,
    {
      manual: true,
      onSuccess: (res) => {
        setPermissionInfos(res)
      },
      onError: () => {
        setPermissionInfos([])
      }
    }
  )

  useEffect(() => {
    getPermissionInfosRun(params)
  }, [getPermissionInfosRun, params])

  // 删除权限
  const { run: delPermissionInfoRun } = useRequest(
    delPermissionInfo,
    {
      manual: true,
      onSuccess: () => {
        message.success("删除成功")
        getPermissionInfosRun(params)
      },
      onError: () => {
        message.error("删除失败")
      }
    }
  )

  const handleCancel = () => {
    setVisible(false)
  }

  const onConfirm = (item: any) => {
    const delParams = {
      userId: userId,
      permissionIds: [item.permissionId]
    }
    delPermissionInfoRun(delParams)
  }

  const columns = [
    {
      title: '权限',
      dataIndex: 'permissionName',
      key: 'permissionName',
    },
    {
      title: '授权来源',
      dataIndex: 'source',
      key: 'source',
    },
    {
      title: '资源',
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: '有效时间',
      dataIndex: 'expr',
      key: 'expr',
    },
    {
      title: '操作',
      dataIndex: 'canDelete',
      key: 'canDelete',
      render: (txt: boolean, record: any) => (
        <PermissionTooltip
          title="手动授权"
          permissionlist={permissionlist}
        >
          <Popconfirm
            title="确认删除?"
            onConfirm={() => onConfirm(record)}
            okText="确定"
            cancelText="取消"
            disabled={!txt || isOnlyRead}
          >
            <Button
              type='link'
              danger
              style={{ paddingLeft: 0 }}
              disabled={!txt || isOnlyRead}
            >
              删除
            </Button>
          </Popconfirm>
        </PermissionTooltip>
      ),
    },
  ]

  function renderTabPane(tabData: any) {
    const { tabName, tabKey, tabContent } = tabData
    return (
      <Tabs.TabPane tab={tabName} key={tabKey}>
        <Table
          dataSource={tabContent}
          columns={columns}
          pagination={false}
          style={{ height: 400 }}
          scroll={{ y: 340 }}
        />
      </Tabs.TabPane>
    )
  }

  return (
    <Modal
      title="权限详情"
      key="permissionDetaiModal"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >
      {
        !permissionInfos || permissionInfos.length === 0 ?
          // <Tabs defaultActiveKey="1">
          //   <Tabs.TabPane tab={'操作权限'} key={'permissionOperation'}>
          //     <Empty className={styles.empty} description="加载中" style={{ height: 400 }}></Empty>
          //   </Tabs.TabPane>
          // </Tabs>
          <Empty className={styles.empty} description="加载中" style={{ height: 400 }}></Empty>
          :
          <Tabs defaultActiveKey="1">
            {
              permissionInfos &&
              permissionInfos.map((item: any) => {
                return renderTabPane(item)
              })
            }
          </Tabs>
      }
    </Modal>
  )
}

export default PermissionInfoModal