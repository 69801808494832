import { Button, Space, Spin, Switch, Table, Tooltip, Typography, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useMemo, useState } from "react";
import { getFilterRuleInDatasource, permissionSupport, removeFilterRule, updateFilterRule } from "src/api";
import { Iconfont } from "src/components";
import { useRequest, useSelector } from "src/hook";
import { getCurrentModulePermissionByUrl } from "src/util/utils";

interface TableType {
  id: number;
  rowFilterId: string;
  content: string;
  status: 0 | 1;
  nodePathWiteType: string;
  createdAt: string;
  updateAt: string;
  objectName: string;
  objectPath: string;
}

const RootFilterRow = (): JSX.Element => {
  const { selectedNode } = useSelector(state => state.dataProtection);
  const { loading, data: tableInfo, run: queryRule, refresh: refreshView } = useRequest(getFilterRuleInDatasource, { manual: true });
  
  const { permissionList } = useSelector((state) => state?.login)
  //模块权限查询
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, 'DATA_PROTECT')
  },[JSON.stringify(permissionList)])

  // 判断是否可编辑
  const { run: runPermissionSupport, data: permissionSupportData } = useRequest(permissionSupport, {
    manual: true,
    onSuccess: (res) => {}
  })

  useEffect(() => {
    if (!selectedNode?.props?.nodePathWithType) {
      runPermissionSupport({
        systemPermissionType: "DATA_PROTECT",
        nodePathWithType: null
      })
    } else {
      runPermissionSupport({
        systemPermissionType: "DATA_PROTECT",
        nodePathWithType: selectedNode?.props?.nodePathWithType
      })
    }
  }, [selectedNode])
  
  const { run: removeRule } = useRequest(removeFilterRule, {
    manual: true,
    onSuccess() {
      message.success("删除成功");
      refreshView();
    },
    onError() {
      message.error("删除失败");
      refreshView();
    },
  });

  const { run: toggleStatus } = useRequest(updateFilterRule, {
    manual: true,
    onSuccess() {
      message.success("修改成功");
      refreshView();
    },
    onError() {
      refreshView();
    },
  });

  function handleRemove(id: number) {
    removeRule({ id: String(id) });
  }

  function handleToggle(record: TableType) {
    toggleStatus({
      id: String(record.id),
      status: record.status ? 0 : 1,
    });
  }

  const columns: ColumnsType<TableType> = [
    {
      key: "dataSourceType",
      title: "数据源",
      render() {
        const text = selectedNode?.nodeName ?? "";
        const icon = `icon-connection-${text}`;

        return (
          <Space>
            <Iconfont type={icon} />
            <span>{text}</span>
          </Space>
        );
      },
    },
    {
      dataIndex: "objectPath",
      title: "对象路径",
      ellipsis: true,
    },
    {
      dataIndex: "objectName",
      title: "对象名称",
      ellipsis: true,
    },
    {
      dataIndex: "content",
      title: "过滤规则",
      width: "320px",
      ellipsis: true,
    },
    {
      dataIndex: "status",
      title: "状态",
      ellipsis: true,
      render: (value, record) => {
        // return value ? <Typography.Text>启用</Typography.Text> : <Typography.Text disabled>禁用</Typography.Text>;
        return (
          <Tooltip title={
            !permissionSupportData?
            `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
            : null
            } 
          >
            <Switch
              checked={value ? true : false}
              checkedChildren="启用"
              unCheckedChildren="禁用"
              onClick={() => handleToggle(record)}
              disabled={!permissionSupportData}
            />
          </Tooltip>
        )
      },
    },
    {
      key: "operation",
      title: "操作",
      align: "center",
      render(_, record) {
        return (
          <Space>
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
            >
              <Button
                type="link"
                style={{ color: "#324564" }}
                onClick={() => handleRemove(record.id)}
                disabled={!permissionSupportData}
              >
                移除
              </Button>
            </Tooltip>
            {/* {record.status ? (
              <Button
                type="link"
                onClick={() => handleToggle(record)}
              >
                禁用
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => handleToggle(record)}
              >
                启用
              </Button>
            )} */}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (selectedNode) {
      const params = {
        dataSourceType: selectedNode.nodeName,
      };
      queryRule(params);
    }
  }, [selectedNode, queryRule]);

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={tableInfo}
        pagination={false}
      ></Table>
    </Spin>
  );
};

export { RootFilterRow };
