import { DataSourceType } from 'src/types';

export const ALL_DATABASE_OPTION_FIELDS: { [k: string]: string } = {
  tablespaceName: '表空间',
  tablespace: '表空间',
  compression: '表压缩',
  rowMovement: '行移动',
  degree: '与度数并行',
  tableowner: '所有者',
  inhparent: '继承自',
  fillfactor: '填充因子',
  persistence: '是否记录',
  identityColumn: '标识字段',
  seed: '标识种子',
  incr: '标识增量',
  curr: '当前的值',
  logging: '记录',
  cache: '缓存',
  engine: '引擎',
  autoIncrement: '自动递增',
  charset: '字符集',
  collation: '排序规则',
  avgRowLength: '平均行长度',
  minRows: '最小行数',
  maxRows: '最大行数',
  keyBlockSize: '键块大小',
  rowFormat: '行格式',
  statsAutoRecalc: '统计数据自动重计',
  statsPersistent: '总计数据持久',
  statsSamplePages: '统计样本页面',
};

export const SQL_OPTION_FIELDS = [
  'engine',
  'tablespace',
  'autoIncrement',
  'charset',
  'collation',
  'avgRowLength',
  'minRows',
  'maxRows',
  'keyBlockSize',
  'rowFormat',
  'statsAutoRecalc',
  'statsPersistent',
  'statsSamplePages'
];

export const SQLSERVER_OPTION_FIELDS = ['identityColumn', 'seed', 'incr', 'curr'];

export const POSTGRESQL_OPTION_FIELDS = [
  'tableowner',
  'tablespace',
  'inhparent',
  'fillfactor',
  'persistence',
];

export const ORACLE_OPTION_FIELDS = [
  'tablespaceName',
  'logging',
  'compression',
  'cache',
  'rowMovement',
  'degree',
];

export const DEMANGDB_OPTION_FIELDS = [
  'tablespaceName',
  'compression',
  'rowMovement',
  'degree',
];

export const OCEANBASE_OPTION_FIELDS = ['tablespace', 'compression', 'rowMovement'];

export const POLARDB_OPTION_FIELDS = [
  'tableowner',
  'tablespaceName',
  'inhparent',
  'fillfactor',
  'persistence',
];

export const getPointDatabaseOptions = (type: DataSourceType) => {
  switch (type) {
    case 'MySQL':
    case 'MariaDB':
    case 'OceanBaseMySQL':
        return SQL_OPTION_FIELDS; 
    case 'SQLServer':
      return SQLSERVER_OPTION_FIELDS;
    case 'Oracle':
    case 'OracleCDB':
      return ORACLE_OPTION_FIELDS;
    case 'DamengDB':
      return DEMANGDB_OPTION_FIELDS;
    case 'OceanBase':
      return OCEANBASE_OPTION_FIELDS;
    case 'PolarDB':
      return POLARDB_OPTION_FIELDS;
    default:
      return POSTGRESQL_OPTION_FIELDS;
  }
};
