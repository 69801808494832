import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { useHistory } from "react-router-dom"
import { UserList } from './UserList'
import styles from './index.module.scss'
import { CreateButton, Iconfont } from 'src/components'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { BatchImportUserModal } from '../orgModals'
import { OrgType } from 'src/api'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

interface IProps {
  [p: string]: any
}

export const DeptDetail = (props: IProps) => {
  const { permissionlist } = props
  const { isOnlyRead } = permissionlist || {}
  const history = useHistory();
  const dispatch = useDispatch()
  const dept = useSelector((state) => state.organization.selectedNode)
  const deptName = dept?.name || ''
  const toggle =
    dept?.id !== undefined && dept?.orgType === 'COMPANY' ? 'block' : 'none'
  const departmentNum = dept?.departmentNum
  const showOrgText = getOrgaType(dept?.orgType!)
  return (
    <div className="cq-card">
      <div 
      className={classNames(
        styles.positionStatic,
        "cq-card__headerbar" 
      )}>
        <div>
          <h3 className="cq-card__title">
            {deptName}&nbsp;
            <span className={styles.deptNumber}>
              {showOrgText}:{departmentNum}
            </span>
          </h3>
        </div>
        <div className="cq-card__action">
          <div className={styles.actionsRight}>
          <PermissionTooltip
            permissionlist={permissionlist}
            title={"用户管理"}
          >
            <Button
              style={{ display: toggle }}
              onClick={() => {
                dispatch(showModal('BatchImportUserModal'))
              }}
              disabled={isOnlyRead}
            >
              导入用户
            </Button>
          </PermissionTooltip>
          <Button
            className={styles.ml10}
            onClick={() => {
              history.push("/organization/userParameter");
            }}
          >
            <Iconfont type= 'icon-yonghucanshuguanli' />
            用户参数管理
          </Button>
          </div>
        </div>
      </div>
      <section 
      // className="cq-page" 
        className={classNames(
          styles.overflowYUnset,
          "cq-page" 
        )}
      >
        <UserList permissionlist={permissionlist}></UserList>
      </section>

      <BatchImportUserModal
        parentDeptId={
          (dept?.orgType === 'GROUP' ? dept?.parentId : dept?.id) as string
        }
      />
    </div>
  )
}

const getOrgaType = (type: OrgType) => {
  switch (type) {
    case 'GROUP':
      return '组人数'
    case 'DEPT':
      return '部门人数'
    default:
      return '公司人数'
  }
}
