export const DEFAULT_EXECUTE_LOG_COLUMNS = [
  'executor',
  'deptName',
  'connectionName',
  'objectName'
]

export const EXECUTION_COLUMN_FIELDS: {[k: string]: string} = {
  executor: '帐号',
  deptName: '部门',
  connectionName: '数据源',
  objectName: '操作对象',
  executorName: '用户姓名',
  dbType: '数据库类型',
  serverIp: '数据库IP',
  port: '数据库端口',
  executeSql: '操作语句',
  dbEdition: '数据版本',
  executeBegin: '开始时间',
  executeEnd: '结束时间',
  clientIp: '客户端IP',
  sqlType: '操作类型',
  resultFlag: '操作结果',
  errorMsg: '错误信息',
  affectRows: '影响条数',
  executeCost: '耗时（ms）',
  tabKey: '执行窗口ID',
  inTrans: '执行模式',
}

export const DEFAULT_OPERATE_LOG_COLUMNS = [
  'userName',
  'detail',
  'operateEntryPoint',
  'resultFlag',
]

export const OPERATE_COLUMN_FIELDS:{[k: string]: string} = {
  userName: '用户',
  detail: '操作明细',
  operateEntryPoint: '操作',
  resultFlag: '执行结果',
  clientIp: '客户端IP',
  businessType: '功能名称',
  beginTime: '操作时间',
}

export const SQL_OPERATE_TYPES:{[key: string]: string} ={
  SELECT: 'magenta',
  DELETE: 'red',
  INSERT: 'orange',
  MERGE: 'green',
  UPDATE: 'cyan',
  ALTER: 'blue',
  CREATE: 'geekblue',
  DROP: 'purple',
}

export const USER_AUDIT_SQL_DETAIL_DEFAULT_COLUMN =[
  'connectionName',
  'objectName'
]

export const USER_AUDIT_SQL_DETAIL_COLUMN = {
  connectionName: '数据源',
  objectName: '操作对象',
  serverIp: '数据库IP',
  port: '数据库端口',
  executeSql: '操作语句',
  dbEdition: '数据版本',
  executeBegin: '开始时间',
  executeEnd: '结束时间',
  clientIp: '客户端IP',
  sqlType: '操作类型',
  resultFlag: '操作结果',
  errorMsg: '错误信息',
  affectRows: '影响条数',
  executeCost: '耗时（ms）',
}

// 用户审计-用户详情-权限tab 默认必选字段
export const USER_AUDIT_AUTH_DETAIL_DEFAULT_COLUMN =[
  'nodePath',
  'nodeType',
  'permissionTemplate',
  'permissionType',
  'authUser',
  'permissionId',
]
// 用户审计-用户详情-权限tab 全部字段
export const USER_AUDIT_AUTH_DETAIL_COLUMN = {
  permissionType: '权限类型',
  permissionTemplate: '权限等级',
  nodePath: '资源',
  nodeType: '资源类型',
  role: '角色',
  expirationTime: '生效时间',
  authUser: '授权人',
  sourceType: '授权来源',
  authIp: '授权ip',
  authTime: '授权时间',
  permissionId: '权限详情',
}


// 用户审计-权限看板 默认必选字段
export const AUTH_BOARD_DETAIL_DEFAULT_COLUMN =[
  'user',
  'permissionType',
  'permissionTemplate',
  'nodePath',
  'nodeType',
  'authUser',
  'permissionId',
]
// 用户审计-权限看板 全部字段
export const AUTH_BOARD_DETAIL_COLUMN = {
  user: '用户名',
  permissionType: '权限类型',
  permissionTemplate: '权限等级',
  nodePath: '资源',
  nodeType: '资源类型',
  role: '连接角色',
  expirationTime: '生效时间',
  authUser: '授权人',
  sourceType: '授权来源',
  authIp: '授权ip',
  authTime: '授权时间',
  permissionId: '权限详情',
}
