import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PermissionLevelState {
  permissionTemplate: any[]
}

const initialState: PermissionLevelState = {
  permissionTemplate: []
}
export const PermissionLevelSlice = createSlice({
  name: 'permissionLevelSlice',
  initialState,
  reducers: {
    setPermissionTemplate: (state, action: PayloadAction<any[]>) => {
      state.permissionTemplate = action.payload
    },
  },
})

export const PermissionLevelSliceReducer = PermissionLevelSlice.reducer

export const { 
  setPermissionTemplate
} = PermissionLevelSlice.actions
