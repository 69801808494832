import React, { useEffect, useState } from 'react'
import { useRequest } from 'src/hook'
import { TreeSelect, Tooltip } from 'antd'
import { queryOrgSdtWithUser } from 'src/api'
import { Iconfont } from 'src/components'
import { difference } from 'lodash'

const treeNodeAdapter = (arr: any[], pKey?: string): any => {
  return arr?.map((item: any) => {
    const { name, orgId, userId, type, child = [], path } = item
    const id = orgId || userId
    const key = pKey ? pKey + '_' + id : String(id)

    return {
      id,
      name,
      type,
      key,
      userId,
      title: type === "USER" ? `${name}(${userId})` : name,
      children: child?.length ?
        treeNodeAdapter(child, key).filter(
          (item: { children: any; type: string }) => item.children?.length || item.type === "USER"
        ) : [],
      isLeaf: !child?.length,
      path
    }
  })
}

// 从userIds转成keyids，反显用
const handleValue = (v: any[] | undefined, afterData: any[]) => {
  const res: any[] = [];
  if (Array.isArray(v) && v.length) {
    if (v[0]?.key) {
      return v
    } else if (typeof v[0] !== 'object') {
      const loopData = (dataList: any[]) => {
        for (let i = 0; i < dataList.length; i++) {
          const { userId, children } = dataList[i];
          if (children?.length) {
            loopData(children);
          }
          if (v.includes(userId)) {
            res.push(dataList[i]);
          }
        }
      }
      loopData(afterData);
      return res?.length ? res : [];
    }
  }
  return v;
}

// 从keyids转成userIds,onchange出去时候用
const handleKeys = (keys: any[] | undefined, data: any[], exclude?: any[]) => {
  const res: any[] = [];
  if (Array.isArray(keys) && keys.length) {
    if (typeof keys[0] !== 'object') {
      const loopData = (dataList: any[]) => {
        for (let i = 0; i < dataList.length; i++) {
          const { key, children, userId } = dataList[i];
          if (children?.length) {
            loopData(children);
          }
          // 通过keys拿到相同节点下的userids
          // 当前节点包括这个key并且结果集中不包含要添加的这个userId
          // exclude排除在这个
          if (keys.includes(key) && !res.includes(userId)) {
            res.push(userId);
          }
        }
      }
      loopData(data);

      return res.length ? res.filter(item => !exclude?.includes(item)) : [];
    }
  }
  return [];
}

// 找到当前keys对应的所有userIds信息
// 根据这些userIds找到所有对应的key
// 去重

interface ElementTreeSelectProps {
  value?: any[] | undefined
  onChange?: (value: string | any[]) => void
  [p: string]: any
}

const UserTreeSelect: React.FC<ElementTreeSelectProps> =
  React.memo(({ value, onChange, style, ...restProps }) => {

    // 选中的keys的数组跟
    const [checkedKeys, setCheckedKeys] = useState<any>([]);
    const [currentTreeData, setCurrentTreeData] = useState<any[]>([]);

    // 查询组织架构用户数据
    const { data = [] } = useRequest(queryOrgSdtWithUser, {
      formatResult: (res: any[]) => {
        return treeNodeAdapter(res)
      },
      onSuccess: (res) => {
        setCurrentTreeData(res);
      }
    })

    useEffect(() => {
      if (currentTreeData?.length) {
        const currentRealValue = handleValue(value, currentTreeData);
        setCheckedKeys(currentRealValue?.map(item => item.key) || [])
      }
    }, [value, currentTreeData])

    const titleRender = (node: any) => {
      const { type, title } = node
      let icon = null
      switch (type) {
        case 'COMPANY':
          icon = <Iconfont type="icon-org-company" />
          break
        case 'SUB_COMPANY':
          icon = <Iconfont type="icon-org-company" />
          break
        case 'DEPT':
          icon = <Iconfont type="icon-org-department" />
          break
        case 'GROUP':
          icon = <Iconfont type="icon-org-group" />
          break
        case 'USER':
          icon = <Iconfont type="icon-org-user" />
          break
        default:
          icon = <Iconfont type="icon-org-user" />
          break
      }
      const showTitle =
        title?.length > 15 ? (
          <Tooltip title={title}>{title.substring(0, 15) + '...'}</Tooltip>
        ) : (
          title
        )
      return (
        <>{icon}&nbsp;{showTitle}</>
      )
    }

    const renderTreeNodes = (nodes: any) => {
      return nodes?.map((node: any) => {
        const { disabledCheckbox, title, type, key, id, ...rest } = node;
        if (node.children) {
          return (
            <TreeSelect.TreeNode
              {...rest}
              key={key}
              value={key}
              selectable
              checkable
              title={titleRender(node)}
            >
              {renderTreeNodes(node.children)}
            </TreeSelect.TreeNode>
          );
        }
        return (
          <TreeSelect.TreeNode
            {...rest}
            key={key}
            value={key}
            selectable
            checkable
            title={titleRender(node)}
          />
        )
      });
    }

    const handleOnChange = (v: any, b: any, extra: any) => {
      let { checked, preValue = [] } = extra || {};

      if (!checked) {
        preValue = preValue.map((item: { value: any }) => item.value);
        // 取消选中时，与上一次的差值keys
        const diffvalue = difference(preValue, v);
        // 获取差值keys对应的userIds
        const excludeUserIds = handleKeys(diffvalue, currentTreeData);
        onChange?.(handleKeys(v, currentTreeData, excludeUserIds))
      } else {
        onChange?.(handleKeys(v, currentTreeData))
      }
    }

    return (
      <TreeSelect
        style={style}
        treeIcon
        allowClear
        showSearch={true}
        treeNodeFilterProp="name"
        placeholder="请选择用户"
        value={checkedKeys}
        onChange={handleOnChange}
        maxTagCount={3}
        {...restProps}
      >
        {renderTreeNodes(data)}
      </TreeSelect>
    )
  })

export default UserTreeSelect