import React, { FC, useState } from "react";
import { Select, Tabs } from "antd";
import { useDispatch } from 'src/hook'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { MY_APPROVAL_TABS, MyApprovalTabKey, MyApprovalTabKeyType } from '../../constants';
import { VisitWorkOrderTablePage } from '../visit-work-order-table'
import { setApplySearchValue } from "../../accessRequestSlice";
import styles from "../index.module.scss";

const { Option } = Select

export interface IProps {
  onChangeValue: (str: string) => void
}

const VisitWorkOrderTabsPage: FC<IProps> = ({...props}) => {
	const { onChangeValue } = props
  const dispatch = useDispatch()
	// const [selectValue, setSelectValue] = useState('desc')
	const [activeKey, setActiveKey] = useState<MyApprovalTabKeyType>('stayApprove')

	return (
		<div className={styles.tabsPageWrap}>
			<div className={styles.tabsHeader}>
				<div className={styles.tabsLeft}>
					<Tabs
						size="small"
						className={styles.card}
						type="card"
						tabPosition="top"
						animated
						activeKey={activeKey}
						onChange={(e: MyApprovalTabKeyType) => {
							setActiveKey(e)
							dispatch(setApplySearchValue(''))
							onChangeValue('')
						}}
					>
						{
							Object.keys(MY_APPROVAL_TABS).map((key: MyApprovalTabKeyType) => (
								<Tabs.TabPane
									tab={MY_APPROVAL_TABS[key]}
									key={key}
								/>
							))
						}
					</Tabs>
					{/* <div className={classNames(styles.ml10, styles.subtextColor)}>共 20条数据·按创建时间排序·更新于 2022-05-20 13:15:11</div> */}
				</div>
			</div>
			<div>
				<ErrorBoundary>
					<VisitWorkOrderTablePage  activeKey={activeKey}/>
				</ErrorBoundary>
			</div>
		</div>
	);
};

export { VisitWorkOrderTabsPage };
