import React, { useState, useEffect } from 'react';
import { Button } from 'antd'

interface IProps {
  style?: any,
  callback: ()=>Promise<boolean>
  startCountdown?: boolean
}
const SMSVerificationButton = ({callback, style, startCountdown}: IProps) => {
  // 状态变量
  const [countdown, setCountdown] = useState(60); // 倒计时时间
  const [isDisabled, setIsDisabled] = useState(false); // 按钮是否禁用

  // 开始倒计时
  useEffect(()=>{
    setIsDisabled(!!startCountdown)
  },[startCountdown])

  // 倒计时逻辑
  useEffect(() => {
    if (countdown > 0 && isDisabled) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && isDisabled) {
      setIsDisabled(false);
    }
  }, [countdown, isDisabled]);

  // 点击按钮事件处理函数
  const handleClick = async() => {
    // 发送短信验证码的逻辑
    const result = await callback()
    // 设置按钮状态
    if(!!result){
      await setCountdown(60);
      setIsDisabled(true);
    }
  };

  return (
    <Button 
      style={{width: 60, ...style}} 
      onClick={handleClick} 
      disabled={isDisabled}
    >
      {isDisabled ? `${countdown}秒后重新发` : '发送验证码'}
    </Button>
  );
};

export default SMSVerificationButton;