import React, { useState, useCallback, useEffect } from 'react'
import { Table, Divider } from 'antd'
import { FilterHeader } from './SearchHeader'
import { postDashBoardPerm } from 'src/api'
import styles from './index.module.scss'

const columns = [
  {
    title: '用户名',
    dataIndex: 'userName',
    key: 'userName',
    width: 150,
  },
  {
    title: '登录账号',
    dataIndex: 'userId',
    key: 'userId',
    width: 150,
  },
  {
    title: '角色名',
    dataIndex: 'roleName',
    key: 'roleName',
    width: 150,
  },
  {
    title: '权限类型',
    dataIndex: 'permType',
    key: 'permType',
    width: 150,
  },
  {
    title: '操作',
    dataIndex: 'operator',
    key: 'operator',
    width: 80,
  },
  {
    title: '连接名',
    dataIndex: 'connectName',
    key: 'connectName',
    width: 150,
  },
  {
    title: '数据库名称',
    dataIndex: 'nodePath',
    key: 'nodePath',
    width: 150,
  },
  {
    title: '元素类型',
    dataIndex: 'nodeType',
    key: 'nodeType',
    width: 100,
  },
  {
    title: '授权时间',
    dataIndex: 'authDate',
    key: 'authDate',
    sorter: true,
    width: 170,
  },
]

export const DashBoard = () => {
  // 分页器状态
  const [pageState, setPageState] = useState({
    current: 1,
    pageSize: 10,
  })
  // 获取总页数
  const [total, setTotal] = useState<number>()
  // 列表数据
  const [dataSource, setDataSource] = useState<any>([])
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
  // 查询参数
  const [params, setParams] = useState({ 
    pageSize: 10, 
    pageNo: 1,
    sortField: 'authDate',
    sortOrder: 'descend'
  })

  // 获取分页数据
  const getDataSource = useCallback(
    async (params) => {
      const { current, pageSize } = pageState
      const pageNo = current
      try {
        setLoadingTableData(true)
        const { data, totalItem } = await postDashBoardPerm({
          ...params,
          pageSize,
          pageNo,
        })
        setDataSource(data)
        setTotal(totalItem)
      } catch {
        setDataSource([])
        setTotal(undefined)
      } finally {
        setLoadingTableData(false)
      }
    },
    [pageState],
  )
  useEffect(() => {
    getDataSource(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const paramSort = Object.assign(
      {},
      params,
    )
    paramSort.sortField = sorter.field as string;
    paramSort.sortOrder = sorter.order as string
    setParams(paramSort)
  }

  return (
    <div className="cq-content">
      <div className={styles.titleWrapper}>
        <div className={styles.title}>权限看板</div>
      </div>
      <section className="page-content">
        <div className={styles.dashboard}>
          <FilterHeader
            setPageState={setPageState}
            setParams={setParams}
            getDataSource={getDataSource}
          />
          <Divider dashed />
          <Table
            size="small"
            columns={columns}
            dataSource={dataSource}
            loading={loadingTableData}
            pagination={{
              ...pageState,
              onChange: (page, pageSize) => {
                setPageState({ current: page, pageSize: pageSize || 10 })
                setParams(Object.assign({}, params, { current: page, pageSize: pageSize || 10 }))
              },
              total,
              showQuickJumper: true,
            }}
            onChange={handleTableChange}
          ></Table>
        </div>
      </section>
    </div>
  )
}
