import React, { FC, useEffect, useState } from 'react'
import { Form } from 'antd'
import moment from 'moment'
import * as _ from 'lodash'
import {  useParams, useHistory } from 'react-router-dom'
import Flow from 'src/components/Process'
import {
  ApplyItem,
  getDataChangeDetail,
} from 'src/api'
import { useRequest } from 'src/hook'
import { DATA_REQUEST_APPLY_TYPES } from 'src/constants'
import { SimpleBreadcrumbs, RcSegmented } from 'src/components'
import {
  DataUpdateForm,
  ReviewProcess,
  ExecuteChange,
} from '../../components'
import { FormContext } from '../../ApplyFormContext'
import styles from './index.module.scss'

interface ApplyProps {}

const applyFlow = [
  {
    title: '申请数据变更',
    iconText: '建',
  },
  {
    title: '连接管理员',
    iconText: '审',
  },
  {
    title: '申请完成',
    iconText: '完',
  },
]

export const Apply: FC<ApplyProps> = ({}) => {
 
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams<{ id?: string;editId?: string }>()
  const isViewDetail = !!id ;

  //审批主流程 通过状态控制吧
  const [currentApprovalStep, setCurrentApprovalStep] = useState(0)
  //当前申请流程
  const [currentStep, setCurrentStep] = useState(0)
  //存储当前数据
  const [currentContextValue, setCurrentContextValue] = useState<
    ApplyItem | any
  >()

  //申请详情
  const { run: runGetDataChangeDetail } = useRequest(getDataChangeDetail, {
    manual: true,
    onSuccess: (data:ApplyItem) => {

      if (data) {
        form.setFieldsValue({...data});
        setCurrentContextValue({...data, executeTime: moment(data?.executeTime).format('YYYY-MM-DD HH:mm:ss')})
      }
      if (data?.executeStatus === '审批中') {
        setCurrentApprovalStep(1)
        setCurrentStep(1)
      }else {
        setCurrentApprovalStep(2)
        setCurrentStep(2)
      }
    },
  })

  useEffect(() => {

    if (isViewDetail ) {
      runGetDataChangeDetail(id)
    }else {
      form.setFieldsValue({type: 1})
    }
  }, [isViewDetail,id])

  const APPLY_TYPES_OPTIONS = (() => {
    return Object.keys(DATA_REQUEST_APPLY_TYPES).map((key) => ({
      //@ts-ignore
      label: DATA_REQUEST_APPLY_TYPES[key],
      value: key,
      //@ts-ignore
      disabled: Number(key) === 2,
    }))
  })()

  const BREADCRUMB_ITEMS = [
    {
      title: '数据变更',
    },
    {
      title: '工单管理',
      href: '/data_change_work_order_management',
      onclick: () => history.go(-1)
    },
    {
      separator: '/',
      title:  '工单详情',
    },
  ]

  const applicantStepItems = [
    {
      description: (
        <DataUpdateForm
          form={form}
          itemStepMark={0}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentContextValue='FLOW_WORK_ORDER_MANAGEMENT'
        />
      ),
    },
    //查看
    {
      description: (
        <ReviewProcess
          itemStepMark={1}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType={'FLOW_WORK_ORDER_MANAGEMENT'}
        />
      ),
    },
    {
      description: (
        <ExecuteChange
          itemStepMark={2}
          currentStep={currentStep}
          isViewDetail={isViewDetail}
          currentRouteType={'FLOW_WORK_ORDER_MANAGEMENT'}
        />
      ),
    },
  ]

  return (
    <FormContext.Provider
      value={{
        form,
        currentContextValue,
        setCurrentContextValue,
        setCurrentStep,
      }}
    >
      <SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
      <div className={styles['application-detail']}>
        <Form form={form} name='basic'>
          <Form.Item name="type" initialValue={1}>
            {!isViewDetail && (
              <RcSegmented
                options={APPLY_TYPES_OPTIONS}
                value={currentContextValue?.type || 1}
              />
            )}
          </Form.Item>
        </Form>

        {/* <Flow
          items={applyFlow}
          current={currentApprovalStep}
          className={styles['approval-step']}
        /> */}
        <div className={styles.applyContent}>
          <Flow
            items={applicantStepItems}
            current={currentStep}
            direction="vertical"
          />
        </div>
      </div>
    </FormContext.Provider>
  )
}

export default Apply
