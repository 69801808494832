import React, { useCallback, useEffect } from 'react'
import { Form, Skeleton, Badge, Modal } from 'antd'
import { useRequest } from 'src/hook'
import {
  AuditExecutionItem,
  findAuditOperateLogs,
} from 'src/api'
import { OPERATE_COLUMN_FIELDS } from 'src/constants'
import styles from './index.module.scss'

export const ModalAuthorizationDetail = ({
  record,
  visible = false,
  onCancel,
}: {
  visible: boolean
  record: any
  onCancel: () => void
}) => {
  const { data: log, run } = useRequest(findAuditOperateLogs, {
    manual: true,
    formatResult: (data: any) => {
      console.log(data, 'data')
      return data?.data?.[0] ?? []
    },
  })

  useEffect(() => {
    if (record?.id) {
      run({
        limit: 10,
        offset: 0,
        id: record.id,
      })
    }
  }, [record?.id])

  const renderField = useCallback((details: AuditExecutionItem, field: any) => {
    const nullText = <span className={styles.emptyField}>（空）</span>
    switch (field) {
      case 'resultFlag':
        //@ts-ignore
        return details?.[field] ? (
          <Badge status="success" text='成功'/>
        ) : (
          <Badge status="error" text='失败' />
        )
      default:
        //@ts-ignore
        return details?.[field] || nullText
    }
  }, [])

  return (
    <Modal
      title={`${record?.authedPermissionName} 详情`}
      width={800}
      visible={visible}
      onCancel={() => onCancel()}
      footer={null}
      className={styles.analysisExecutionModal}
    >
      <Skeleton loading={false} paragraph={{ rows: 8 }}>
        <Form
          component={false}
          colon={false}
          labelAlign="left"
          labelCol={{ span: 4, offset: 1 }}
          wrapperCol={{ span: 18 }}
        >
          {Object.keys(OPERATE_COLUMN_FIELDS).map((key: string) => (
            <Form.Item
              className={styles.detailItem}
              key={key}
              label={OPERATE_COLUMN_FIELDS[key]}
            >
              {renderField(log, key)}
            </Form.Item>
          ))}
        </Form>
      </Skeleton>
    </Modal>
  )
}
