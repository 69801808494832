import React from 'react'
import { useRequest } from 'src/hook'
import { Layout, Breadcrumb, Form, Popconfirm, Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import {deleteSystemFlowPermission, getSysPerms} from 'src/api'
import {LinkButton} from "../../components";


const { Header, Content } = Layout
export const SysPermsPage = () => {
  const [filterForm] = Form.useForm<{ name: string }>()

  const {
    data: perms,
    loading: loadingPerms,
    run: fetchPerms,
  } = useRequest(getSysPerms)

  // ! HACK
  // 因为需要暂时拿到告警模块，但是后端返回的权限数据中仍然有告警这一项
  // 所以前端暂时手动过滤掉这行数据
  const permissionsWithoutAlarmModule = perms?.filter(
    ({ permissionId }) => permissionId !== 'Cloudquery.Warning',
  )

    // 定义 handleDelete 函数来处理删除操作
    const handleDelete = async (permissionId: (string | number)) => {
        await deleteSystemFlowPermission(permissionId)
        await fetchPerms(filterForm.getFieldValue('name'))
    }

  return (
    <Layout className="cq-container">
      <Header className="cq-header">
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>系统权限</Breadcrumb.Item>
          <Breadcrumb.Item>权限管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className="cq-header__main">
          <h1>权限管理</h1>
          <div className="cq-header__action"></div>
        </div>
      </Header>
      <Layout className="cq-main">
        <Content className="cq-content">
          <section className="cq-card cq-card--padding">
            <div className="cq-card__headerbar">
              <div></div>
              <div>
                <Form
                  name="sysPermsFilter"
                  layout="inline"
                  form={filterForm}
                  onFinish={({ name }) => {
                    if (name === '') {
                      name = 'ALL'
                    }
                    fetchPerms(name)
                  }}
                >
                  <Form.Item name="name" style={{ marginRight: 0 }}>
                    <Input
                      prefix={<SearchOutlined />}
                      placeholder="请输入权限名"
                      allowClear
                      onPressEnter={() => {
                        filterForm.submit()
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
            <section className="cq-table">
              <Table
                rowKey={(record) => record.permissionId}
                dataSource={permissionsWithoutAlarmModule}
                columns={[
                  { dataIndex: 'permissionName', title: '权限名', ellipsis: true },
                  {
                    dataIndex: 'permissionDesc',
                    title: '权限描述',
                    ellipsis: true,
                  },
                  { dataIndex: 'roleNames', title: '授予角色', ellipsis: true },
                  {
                    dataIndex: 'userInfos',
                    title: '影响用户',
                    ellipsis: true,
                    render(_, record) {
                      return record.userInfos
                        .map(({ userName }) => userName)
                        .join(', ')
                    },
                  },
                    {
                        dataIndex: 'expiredTime',
                        title: '有效期',
                        width: 180,
                    },
                    {
                        dataIndex: 'permissionId',
                        title: '操作',
                        align: 'center',
                        width: 80,
                        render: (_, record) => (
                            <Popconfirm
                                title={`确定删除权限 "${record.permissionName}" 吗？`}
                                onConfirm={() => handleDelete(record.permissionId)}
                                okText="确认"
                                cancelText="取消"
                            >
                                <LinkButton danger disabled={!record.flowPermission}>
                                    删除
                                </LinkButton>
                            </Popconfirm>
                        ),
                    },
                ]}
                loading={loadingPerms}
                size="small"
                pagination={{showSizeChanger: true}}
              />
            </section>

          </section>
        </Content>
      </Layout>
    </Layout>
  )
}
