import { SdtNodeType } from 'src/api'
import { flowTimeLimits } from '../constants'
import { exhaustiveCheck } from 'src/util'
import { FlowDetailFields, FlowFormTrackingContext } from 'src/types'

/**
 * 根据流程类型，从对象中获取重新申请表单回填需要的键值(与业务强相关)
 * @param response 从详情接口获取到的所有字段
 * @returns 打开并回调表单 action 所需要的数据结构
 */
export const getFieldsFromResponse = (
  response: FlowDetailFields,
): FlowFormTrackingContext => {
  const {
    flowType,
    connectionId,
    connectionName,
    dataSourceType,
    nodeType,
    nodePathList,
    nodePathWithTypeList,
    expiredTimeLimit,
    applyReason,
  } = response
  // 构造回填“数据库元素”字段所需的结构
  let elements
  if (connectionId && connectionName && dataSourceType && nodeType && nodePathList && nodePathWithTypeList) {
    elements = nodePathList.map((nodePath, i) => {
      let nodeName: string | undefined = nodePath.split('/').pop()
      const nodePathWithType = nodePathWithTypeList[i];
      if (nodeType === 'connection') {
        nodeName = connectionName
      }
      return {
        connectionId,
        connectionType: dataSourceType,
        nodeType: nodeType as SdtNodeType,
        nodePath,
        nodePathWithType,
        nodeName,
      }
    })
  }
  // 构造回填“期限”字段所需的结构
  const preset = flowTimeLimits.find(
    ({ value }) => value === (expiredTimeLimit || 0),
  )
  const timeLimit = preset
    ? { preset: preset.value }
    : {
        preset: false as const,
        custom: expiredTimeLimit || 1,
      }

  switch (flowType) {
    case 'connectionAccess':
      return {
        type: flowType,
        fields: { elements, applyReason, timeLimit },
      }

    case 'dataCorrection': {
      const { executeText, rollbackText, executeType } = response
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          executeText,
          rollbackText,
          executeType: executeType as 'auto' | 'manual' | undefined,
        },
      }
    }

    case 'dataExport': {
      const { exportNumLimit } = response
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          timeLimit,
          numberLimit: exportNumLimit,
        },
      }
    }

    case 'dataManipulation': {
      const { operationList } = response
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          timeLimit,
          operationList,
        },
      }
    }

    case 'dataMasking':
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          timeLimit,
        },
      }

    case 'executionRows': {
      const { rowNumLimit } = response
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          timeLimit,
          numberLimit: rowNumLimit,
        },
      }
    }

    case 'executionTimes': {
      const { executeTimeLimit } = response
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          timeLimit,
          numberLimit: executeTimeLimit,
        },
      }
    }

    case 'highRisk':
      return {
        type: flowType,
        fields: {
          elements,
          applyReason,
          timeLimit,
        },
      }

    case 'menuAccess':
      const { objName } = response
      return {
        type: flowType,
        fields: { menus: objName?.split(',') },
      }
      case 'desensitizedResource':
        return {
          type: flowType,
          fields: {
            elements,
            applyReason,
            timeLimit,
          },
        }
  
    default:
      exhaustiveCheck(flowType)
      return { type: flowType }
  }
}
