import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Form, Input, message, TreeSelect } from 'antd'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { fetchOrgTreeData } from '../organizationSlice'
import { OrgTreeNode, addOrgGroup } from 'src/api'
import { FormLayout } from 'src/constants'
import { nameValidator } from 'src/util/nameValidator'

const traversalOrgTreeNode = (rootNode: OrgTreeNode): any => {
  const { title, name, orgType, children } = rootNode
  return {
    title,
    value: name,
    selectable: ['COMPANY', 'DEPT'].includes(orgType),
    children: (children || []).map(traversalOrgTreeNode),
  }
}

export const AddOrgGroupModal: React.FC = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.AddOrgGroup)

  const { selectedNode, orgTreeData } = useSelector(
    (state) => state.organization,
  )
  const treeSelectData = useMemo(() => {
    return orgTreeData.map(traversalOrgTreeNode)
  }, [orgTreeData])
  const initialSelectedParent = useMemo(() => {
    if (selectedNode?.orgType === 'DEPT') {
      return selectedNode.name
    } else {
      return orgTreeData[0]?.name
    }
  }, [selectedNode, orgTreeData])

  const [addOrgGroupForm] = Form.useForm()

  const handleSubmitAddOrgGroup = () => {
    addOrgGroupForm.validateFields().then((values) => {
      const { name, description, parent } = values
      let parId: any = null
      if (selectedNode?.orgType === 'DEPT') {
        parId = selectedNode?.id
      } 
      const parentOrg = parId === orgTreeData[0]?.id ? undefined : parId

      setConfirmLoading(true)
      addOrgGroup({ name, description, parentOrg })
        .then(() => {
          message.success('组添加成功')
          dispatch(fetchOrgTreeData())
          dispatch(hideModal('AddOrgGroup'))
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  return (
    <UIModal
      title="添加组"
      visible={visible}
      onCancel={() => dispatch(hideModal('AddOrgGroup'))}
      onOk={() => handleSubmitAddOrgGroup()}
      afterClose={() => addOrgGroupForm.resetFields()}
      width={480}
      confirmLoading={confirmLoading}
    >
      <Form form={addOrgGroupForm} {...FormLayout}>
        <Form.Item
          name="parent"
          label="所属部门"
          initialValue={initialSelectedParent}
        >
          <TreeSelect treeData={treeSelectData} treeDefaultExpandAll disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="组名称"
          rules={[
            { required: true, message: '请输入组名称' },
            { max: 20, min: 2, message: '组名称须在2-20个字之间' },
            { validator: nameValidator },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="描述和备注">
          <Input />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
