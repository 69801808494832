import { fetchPost, fetchGet } from './customFetch'
import { NodeEntity } from '../types'

type DataNodeListEntity = Pick<
  NodeEntity,
  'connectionId' | 'connectionType' | 'nodeName' | 'nodeType' | 'nodePath' | 'nodePathWithType' | 'globalHavePermissionFlag'
>

// ! 需要和其他获取节点列表的接口统一
export const getToolbarDataNodeList = (node: Partial<DataNodeListEntity>) => {
  return fetchPost('/dms/connection/toolbar', node).then(
    (connections: DataNodeListEntity[]) =>
      connections.map((connection) => ({
        ...connection,
        nodePath: `/root${connection.nodePath}`,
      })),
  )
}
// ! 获取sdt带分组的连接
export const getConnectionNodeWithGroup = (params: Partial<any>) => {
  return fetchPost('/dms/meta/all/node', params)
}
// !: deprecated
export const establishConnection = (connectionId: number | string) => {
  return fetchGet(`/dms/menu/open/${connectionId}`)
}

export const getConnectionConfigItems = (
  connectionId: number | string,
): Promise<any[]> => {
  return fetchGet(`/dms/connection/form/connection/${connectionId}`).then(
    (data) => {
      return data?.map((it: any) => {
        if (it.field === 'password') {
          // it.value = atob(it.value)
          // 加密算法 密码6个占位符
          it.value = '******'
        }
        return it
      })
    },
  )
}

/** 包括分组节点的连接列表 */
export const getConnectionsWithGroup = (
  node: Partial<NodeEntity>,
): Promise<any[]> => {
  return fetchPost('/dms/connection/toolbar/connection', node)
}

/** 连接池动态表单 */
export const getConnectionPoolConfigItems = (
  connectionId: number | string,
): Promise<any[]> => {
  return fetchGet(`/dms/connection/form/connection_pool_setting/${connectionId}`).then(
    (data) => {
      return data?.map((it: any) => {
        if (it.field === 'password') {
          it.value = atob(it.value)
        }
        return it
      })
    },
  )
}

interface IUserInput {
  connectionId: number | string
  userInputs: {
    maxSize?: number
    minSize?: number
    borrowMaxWaitMillis?: number
    preparePool?: boolean
    testOnBorrow?: boolean
    removeAbandonedTimeout?: number
    autoClosePoolTimeout?: number
    implictCommitTimeout?: number
    transactionType?: string
  }
}

/** 提交连接池 */
export const submitConnectionPool = (params: IUserInput) => {
  return fetchPost('/dms/connection/form/connection_pool_setting', params)
}

interface IConnectionReset {
  connectionId: number | string
  // UPDATE: 重置连接池
  // DELETE: 回收连接池 
  type: 'UPDATE' | 'DELETE'
}

/** 重置连接池 */
export const resetConnectionPool = (params: IConnectionReset) => {
  return fetchPost('/dms/connection/reset_connection_pool', params)
}