import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataSourceType } from 'src/api'
import { IPermKey } from 'src/service'

type ConnectionManagementNodeType =
  | 'connectionGroup'
  | 'connection'
  | 'appGroup'
  | 'app'
  | 'appAccount'
  | 'appMasking'
  | 'appAnalysis'
  | 'user'
  | 'generalConfig'
  | 'masking'
  | 'audit'
  | 'access'
  | 'role'
  | 'permGroup'
  | 'permDataSource'
  | 'permSet'
  | 'maskingRule'
  | 'maskingScanJob'
  | 'maskingInternal'
  | 'resourceConfig'
  | IPermKey
  | null

type AntKey = number | string

interface BaseNode {
  key: AntKey
  title: string
  nodeType: ConnectionManagementNodeType
}

export interface ConnectionNode extends BaseNode {
  connectionId: AntKey
  connectionName: string
  connectionType: DataSourceType
}
export interface AppGroupNode extends BaseNode {
  connection: ConnectionNode
}
export interface PermNode extends BaseNode {
  connection: ConnectionNode
}
export interface GeneralConfigNode extends BaseNode {
  connection: ConnectionNode
}
export interface NodeWithConnection extends BaseNode {
  connection: ConnectionNode
}
export interface AppNode extends BaseNode {
  appName: string
  appId: string
  appCode: string
  connection: ConnectionNode
}
export interface UserNode extends BaseNode {
  connection: ConnectionNode
}
export interface AppChildNode extends BaseNode {
  connection: ConnectionNode
  app: AppNode
}

export interface ConnectionManagementNode
  extends Partial<AppNode>,
    Partial<ConnectionNode>,
    Partial<UserNode>,
    Partial<AppChildNode> {
  key: AntKey
  title: string
  nodeType: ConnectionManagementNodeType
}

export interface ITestConnectionProps {
  connectionId: undefined | string | number,
  result: undefined | boolean,
}

interface ConnectionManagementState {
  selectedNode: ConnectionManagementNode | null
  rightClickedNode: ConnectionManagementNode | null
  sdtExpandedKeys: AntKey[]
  ownerConnections: any[]
  loadingOwnerConnections: boolean
  permSelectRow: any
  expandedGroupNodes: any[]
  isTabEditConnection: boolean
  refreshTabConnection: boolean
  testConnection: ITestConnectionProps[]
}

const initialState: ConnectionManagementState = {
  selectedNode: null,
  rightClickedNode: null,
  sdtExpandedKeys: [],
  ownerConnections: [],
  loadingOwnerConnections: false,
  permSelectRow: null,
  expandedGroupNodes: [],
  isTabEditConnection: false,
  refreshTabConnection: false,
  // 存储当前测试连接的是哪个连接和它的测试结果
  testConnection: [{
    connectionId: undefined,
    result: undefined,
  }]
}

export const connectionManagementSlice = createSlice({
  name: 'connectionManagement',
  initialState,
  reducers: {
    setSelectedNode: (
      state,
      action: PayloadAction<ConnectionManagementNode | null>,
    ) => {
      state.selectedNode = action.payload
    },
    setRightClickedNode: (
      state,
      action: PayloadAction<ConnectionManagementNode | null>,
    ) => {
      state.rightClickedNode = action.payload
    },
    setSdtExpandedKeys: (state, action: PayloadAction<AntKey[]>) => {
      state.sdtExpandedKeys = action.payload
    },
    setOwnerConnections: (state, action: PayloadAction<any[]>) => {
      state.ownerConnections = action.payload
    },
    setLoadingOwnerConnections: (state, action: PayloadAction<boolean>) => {
      state.loadingOwnerConnections = action.payload
    },
    setPermSelectRow: (state, action: PayloadAction<any>) => {
      state.permSelectRow = action.payload
    },
    setExpandedGroupNodes: (state, action: PayloadAction<any[]>) => {
      state.expandedGroupNodes = action.payload
    },
    setIsTabEditConnection: (state, action: PayloadAction<boolean>) => {
      state.isTabEditConnection = action.payload
    },
    setRefreshTabConnection:(state, action: PayloadAction<boolean>) => {
      state.refreshTabConnection = action.payload
    },
    setConnectionResult: (state, action: PayloadAction<ITestConnectionProps[]>) => {
      state.testConnection = action.payload;
    }
  },
})

export const connectionManagementReducer = connectionManagementSlice.reducer

export const {
  setSdtExpandedKeys,
  setSelectedNode,
  setRightClickedNode,
  setOwnerConnections,
  setLoadingOwnerConnections,
  setPermSelectRow,
  setExpandedGroupNodes,
  setIsTabEditConnection,
  setRefreshTabConnection,
  setConnectionResult,
} = connectionManagementSlice.actions
