import React, { useEffect } from 'react'
import { Form, Checkbox } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useSelector, useRequest, useDispatch } from 'src/hook'
import { EditableTable } from './EditableTable'
import { postSourceTableFields, postImportFileMessage } from 'src/api'
import { setTableFieldMap } from 'src/store/extraSlice/textImportSlice'

export function FieldMapForm({
  form,
}: {
  form?: FormInstance
  targetFields: string
}) {
  const { targetTable, sourceTableFieldsRequest, targetTableMessage } =
    useSelector((state) => state.textImport)
  const dispatch = useDispatch()
  // 获取目标字段列表
  const { data: targetTableFieldList, run: getTargetTableFieldList } =
    useRequest(postSourceTableFields, {
      manual: true,
      formatResult: (data) => {
        return data?.map((d) => {
          return {
            label: `${d.columnName}(${d.columnTypeName})`,
            value: `${d.columnName}(${d.columnTypeName})`,
          }
        })
      },
    })
  useEffect(() => {
    if (!targetTableMessage) return
    getTargetTableFieldList({ ...targetTableMessage })
  }, [getTargetTableFieldList, targetTableMessage])

  /** 获取源字段列表 */
  const { data: sourceTableData, run: getSourceTableFieldList } = useRequest(
    postImportFileMessage,
    {
      manual: true,
    },
  )
  useEffect(() => {
    if (!sourceTableFieldsRequest) return
    getSourceTableFieldList({ ...sourceTableFieldsRequest })
  }, [getSourceTableFieldList, sourceTableFieldsRequest])

  const sourceTableList =
    sourceTableData?.fieldNames && sourceTableData?.fieldNames?.length > 0
      ? sourceTableData?.fieldNames
      : sourceTableData?.values[0]

  const sourceTableListMap = new Map(sourceTableList?.map((value, key) => [key, value]))
  let sourceTableListFormat: any[] = []
  sourceTableListMap.forEach((value, key) => {
    sourceTableListFormat.push({label: `${value}(${key})`, value: `${value}(${key})`})
  })

  const dispatchSetTableFieldMap = (param: any) =>
    dispatch(setTableFieldMap(param))

  return (
    <>
      <span>目标表：{targetTable}</span>
      <Form form={form}>
        <Form.Item label="导入报错是否继续导入" valuePropName='checked' name="isContinue" >
          <Checkbox
            onChange={(e) => {
              form?.setFieldsValue({ isContinue: e.target.checked })
            }}
          ></Checkbox>
        </Form.Item>
      </Form>
      {!!sourceTableList && !!targetTableFieldList && (
        <EditableTable
          rowKey="sourceFields"
          targetTableFieldList={targetTableFieldList}
          sourceTableFieldList={sourceTableListFormat}
          setTableFieldMap={dispatchSetTableFieldMap}
        ></EditableTable>
      )}
    </>
  )
}
