/* eslint-disable react/no-danger-with-children */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";
import {
  Badge,
  Button,
  Space,
  Table,
  Progress,
  Typography,
  message,
  Tooltip,
  Modal,
  Descriptions,
  Popconfirm,
} from "antd";
import {
  logTaskGroup,
  pauseTaskGroup,
  queryTransferTaskGroup,
  resumeTaskGroup,
  retryTaskGroup,
  TaskItem,
} from "src/api/dataTransfer";
import { ColumnsType } from "antd/lib/table";
import { useRequest } from "src/hook";
import dayjs from "dayjs";
import {
  formatSourceData,
  formatTargetData,
  renderChildSource,
  renderChildTarget,
  renderObjectLabel,
} from "../transferTaskRecord/utility";
import { Iconfont } from "src/components";
import { renderTableFields } from 'src/util'
import classNames from "classnames";
import classnames from "classnames";
import styles from "./index.module.scss";

const onLoadStatus: number[] = [];
// @ts-ignore
const secendConvertHms = (t: number): string => {
  let hh=0, mm=0, ss=t, showH='00', showM='00'
  if(t > 60){
    // @ts-ignore
    mm = parseInt(ss / 60) 
    // @ts-ignore
    ss = parseInt(ss % 60)
  }
  if(mm>60){
    // @ts-ignore
    hh = parseInt(mm / 60)
    // @ts-ignore
    mm = parseInt(mm % 60)
  }
  if(hh && hh < 10){
    showH = '0' + hh
  }else if(hh){
    showH = '' + hh
  }
  if(mm && mm < 10){
    showM = '0' + mm
  }else if(mm){
    showM = '' + mm
  }
  return `${showH}:${showM}:${ss}`
}
const DetailTable = (): JSX.Element => {
  const state = useLocation() as any;
  const [visbile, setVisible] = useState(false);
  const [statusFlag, setStatusFlag] = useState<boolean>(false);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const firstMounted = useRef<boolean>(true);

  const columns: ColumnsType<any> = [
    {
      key: "flag",
      width: "80px",
      title: "",
      fixed: "left",
      render(value, record) {
        return renderStatusBadge(record);
      },
    },
    {
      dataIndex: "source",
      title: "源表名",
      width: "100px",
      ellipsis: true,
      fixed: "left",
      render(value, record) {
        return renderChildSource(record);
      },
    },
    {
      dataIndex: "target",
      title: "目标表名",
      width: "100px",
      ellipsis: true,
      fixed: "left",
      render(value, record) {
        return renderChildTarget(record);
      },
    },
    // {
    //   dataIndex: "desensitizationGrade",
    //   align: "center",
    //   title: "脱敏等级",
    //   width: "100px",
    //   ellipsis: true,
    // },
    {
      dataIndex: "state",
      title: "状态",
      width: "200px",
      ellipsis: true,
      render(value, record) {
        console.log(record)
        if(record?.state === 5 ){
          return <span><Iconfont type='icon-chuanshuwancheng' style={{marginRight: 4, color: 'green'}} />任务完成</span>
        }
        return (
          <>
            <Progress
              size="small"
              strokeColor={
                !record?.showProgress && record?.state === 3
                  ? {
                      from: "gray",
                      to: "gray",
                    }
                  : ""
              }
              status={
                !record?.showProgress &&
                (record?.state === 6 || record?.state === 4)
                  ? "exception"
                  : record?.state === 5
                  ? "success"
                  : "active"
              }
              percent={record?.showProgress ? Number(record?.progress) : 100}
              showInfo={false}
            ></Progress>
            {
              record.state === 6 && <div style={{textAlign:'left', fontSize: '12px', color:'#666'}}>任务失败</div>
            }
            { record.state === 1 && <div style={{fontSize: '12px', color:'#666', display:'flex', alignItems: 'center', justifyContent: "space-between"}}>
                <span>等待中</span>
                <span>等待时长: {record?.timeRemaining ? secendConvertHms(record?.timeRemaining) : '-'}</span>
              </div>
            }

            {![1,6].includes(record?.state) && record?.showProgress && (
              <div style={{fontSize: '12px', display:'flex', alignItems: 'center', justifyContent: "space-between"}}>
                <span style={{color:'#3262FF'}}>
                  {`${Number(record.bytesSpeed)} MB/S`}
                </span>
                <span>剩余时间: {record?.timeRemaining? secendConvertHms(record?.timeRemaining) : '-'}</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "totalReadRecords",
      title: "数据量",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "createTime",
      title: "开始时间",
      width: "180px",
      ellipsis: true,
      render(value) {
        return value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-";
      },
    },
    {
      dataIndex: "endTime",
      title: "结束时间",
      width: "180px",
      ellipsis: true,
      render(value) {
        return value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-";
      },
    },
    {
      key: "operation",
      title: "操作",
      fixed: "right",
      width: "100px",
      ellipsis: true,
      render(value, record) {
        return statusFlag ? renderOperationBtn(record) : "-";
      },
    },
  ];

  // 静态脱敏展示脱敏列
  if(state?.state?.parentDetail?.taskType === "STATIC_DESENSITIZATION"){
    columns.splice(2, 0,  {
      dataIndex: "desensColumns",
      title: "脱敏列",
      width: "100px",
      ellipsis: true,
      render(value) {
        const val = _.isArray(value) && value?.length && (
          <Tooltip
            title={value.map((key) => (
              <div key={key}>{key}</div>
            ))}
          >
            <div style={{ fontSize: 12 }}>{value[0]}</div>
            {value[1] && <div style={{ fontSize: 12 }}>{value[1]}</div>}
          </Tooltip>
        );
        return val ? val : '-';
      },
    },)
  }

  const {
    data,
    run: queryTask,
    loading,
    pagination,
    refresh,
  } = useRequest(
    async (_, params) => {
      return queryTransferTaskGroup(params);
    },
    {
      pollingInterval: 5000,
      pollingWhenHidden: false,
      manual: true,
      paginated: true,
      formatResult(res) {
        setRefreshLoading(false);
        return {
          list: res.data,
          total: res.totalItem,
        };
      },

      onError() {
        setRefreshLoading(false);
      },
    }
  );

  /* 暂停子任务 */
  const { run: pauseTaskChild } = useRequest(pauseTaskGroup, {
    manual: true,
    onSuccess() {
      message.success("暂停成功");
      // setRefreshLoading(true);
      timeoutRefresh();
    },
    onError() {
      setRefreshLoading(false);
    },
  });

  /* 续传子任务 */
  const { run: resumeTaskChild } = useRequest(resumeTaskGroup, {
    manual: true,
    onSuccess() {
      message.success("续传成功");
      timeoutRefresh();
    },
    onError() {
      setRefreshLoading(false);
    },
  });

  /* 重试子任务 */
  const { run: retryTaskChild } = useRequest(retryTaskGroup, {
    manual: true,
    onSuccess() {
      message.success("重试成功");
      timeoutRefresh();
    },
    onError() {
      setRefreshLoading(false);
    },
  });

  const timeoutRefresh = () => {
    setTimeout(() => {
      refresh();
    }, 500);
  };

  // 0:初始化, 1:等待中, 2:运行中, 3:暂停, 4:中止, 5:成功, 6:失败
  // 进行中、已暂停、等待中、任务失败
  function renderStatusBadge(record: TaskItem): ReactNode {
    if (record.state === 2) {
      return <Badge text="进行中" color="green"></Badge>;
    } else if (record.state === 3) {
      return <Badge text="已暂停" color="gray"></Badge>;
    } else if (record.state === 1) {
      return <Badge text="等待中" color="gray"></Badge>;
    } else if (record.state === 5) {
      return <Badge text="成功" color="blue"></Badge>;
    } else if (record.state === 6) {
      return <Badge text="失败" color="red"></Badge>;
    } else if (record.state === 4) {
      return <Badge text="中止" color="gray"></Badge>;
    }

    return;
  }

  /**
   * 渲染操作按钮
   * @param record
   * @returns
   */
  function renderOperationBtn(record: TaskItem): ReactNode {
    switch (record.state) {
      case 1:
      case 2:
        return (
          <Space>
            <Tooltip title="暂停">
              <Popconfirm
                title="确定暂停该任务吗?"
                onConfirm={() => {
                  setRefreshLoading(true);
                  pauseTaskChild({
                    ddtId: record?.parentId,
                    ttgId: record.taskGroupId,
                  });
                }}
              >
                <Iconfont
                  type="icon-kaishi"
                  className={classNames(styles.iconcolor, styles.fs18)}
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="查看日志">
              <Iconfont
                type="icon-chakanxiangqing"
                className={classNames(styles.iconcolor, styles.fs18)}
                onClick={() => {
                  logTaskChild(record?.taskGroupId);
                }}
              />
            </Tooltip>
          </Space>
        );
      case 0:
      case 3:
      case 4:
      case 5:
      case 6:
        return (
          <Space>
            {record.state === 3 && (
              <Tooltip title="开始">
                <Popconfirm
                  title="确定开始该任务吗?"
                  onConfirm={() => {
                    setRefreshLoading(true);
                    resumeTaskChild({
                      ddtId: record?.parentId,
                      ttgId: record.taskGroupId,
                    });
                  }}
                >
                  <Iconfont
                    type="icon-zanting"
                    className={classNames(styles.iconcolor, styles.fs18)}
                  />
                </Popconfirm>
              </Tooltip>
            )}
            {record.state === 6 && (
              <Tooltip title="重试">
                <Popconfirm
                  title="确定重试该任务吗?"
                  onConfirm={() => {
                    setRefreshLoading(true);
                    retryTaskChild({
                      ddtId: record?.parentId,
                      ttgId: record?.taskGroupId,
                    });
                  }}
                >
                  <Iconfont
                    type="icon-zhongshi"
                    className={classNames(styles.iconcolor, styles.fs18)}
                  />
                </Popconfirm>
              </Tooltip>
            )}
            <Tooltip title="查看日志">
              <Iconfont
                type="icon-chakanxiangqing"
                className={classNames(styles.iconcolor, styles.fs18)}
                onClick={() => {
                  logTaskChild(record?.taskGroupId);
                }}
              />
            </Tooltip>
          </Space>
        );
      default:
        return <div></div>;
    }
  }

  /* 查看子任务日志 */
  const {
    data: logData,
    run: logTaskChild,
    refresh: logTaskRefresh,
  } = useRequest(logTaskGroup, {
    manual: true,
    onSuccess(data) {
      setVisible(true);
    },
  });

  useEffect(() => {
    setStatusFlag(state?.state?.statusFlag);
  }, [state?.state?.statusFlag]);

  useEffect(() => {
    if (firstMounted.current) {
      firstMounted.current = false;
    }
    return () => {
      firstMounted.current = true;
    };
  }, []);

  useEffect(() => {
    const params = {
      pageNo: pagination.current,
      pageSize: 20,
      status: onLoadStatus,
      parentId: state?.state?.parentId,
    };
    const pageParams = {
      current: params.pageNo,
      pageSize: params.pageSize,
    };

    queryTask(pageParams, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTask]);

  /**
   * only use loading status when first render
   * @returns
   */
  function getLoadingStatus(): boolean {
    return firstMounted.current ? loading : false;
  }

  const renderStatus = (state: number) => {
    switch(state){
      case 0:
        return '初始化'
      case 1:
      return '等待中'
      case 2:
        return '运行中'
      case 3:
        return '暂停'
      case 4:
        return '中止'
      case 5:
        return '成功'
      case 6:
        return '失败'
    }
  }

  return (
    <div>
      {state?.state?.parentDetail?.id && (
        <Descriptions title="">
          <Descriptions.Item label="任务编号">
            {state?.state?.parentDetail?.id}
          </Descriptions.Item>
          <Descriptions.Item label="任务名称">
            {state?.state?.parentDetail?.taskName}
          </Descriptions.Item>
          <Descriptions.Item label="任务类型">
            {state?.state?.parentDetail?.taskType === "STATIC_DESENSITIZATION"
              ? "静态脱敏"
              : state?.state?.parentDetail?.taskType === "DATA_TRANSMISSION"
              ? "数据传输"
              : "-"}
          </Descriptions.Item>
          {/* <Descriptions.Item label="脱敏等级">
            {state?.state?.parentDetail?.desensitizationGrade}
          </Descriptions.Item> */}
          <Descriptions.Item label="源">
            {renderObjectLabel(formatSourceData(state?.state?.parentDetail))}
          </Descriptions.Item>
          <Descriptions.Item label="目标">
            {renderObjectLabel(formatTargetData(state?.state?.parentDetail))}
          </Descriptions.Item>
          <Descriptions.Item label="发起时间">
            {dayjs(state?.state?.parentDetail?.createTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="创建人">
            {state?.state?.parentDetail?.owner}
          </Descriptions.Item>
          <Descriptions.Item label="任务状态">
            {renderStatus(state?.state?.parentDetail?.state)}
          </Descriptions.Item>
        </Descriptions>
      )}
      <div className={classnames(styles.flexJBetween)}>
        <div></div>
        <Button
          disabled={refreshLoading}
          type="primary"
          className={classnames(styles.mb20)}
          onClick={() => {
            setRefreshLoading(true);
            refresh();
          }}
        >
          刷新任务
        </Button>
      </div>
      <Table
        size="small"
        columns={columns}
        loading={getLoadingStatus() || refreshLoading}
        dataSource={data?.list ?? []}
        {...(pagination as any)}
        pagination={{ showSizeChanger: false }}
        scroll={{ x: true }}
      ></Table>
      <Modal
        visible={visbile}
        onCancel={() => setVisible(false)}
        footer={[
          <Button type="primary" onClick={() => logTaskRefresh()}>
            刷新
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: logData&& logData.replace(/\r\n/g,'<br/>') }}></div>
      </Modal>
    </div>
  );
};

export { DetailTable };
