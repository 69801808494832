export const stringByteLength = (string: string) => { 
    if (!string) { 
        return 0
    }
    let length = 0
    Array.from(string).forEach( (char: string) => {
        if (char.charCodeAt(0) > 255) {
        // 字符编码大于255，说明是双字节字符
            length += 2
        } else {
            length++
        }
    })
    return length
}

export const customLengthWord = (string: string, length: number, start?: number) => {
    let curLength = 0, result = '', startIndex = start || 0
    Array.from(string).forEach((char: string, index) => {
        if (index < startIndex || curLength > length) {
          return 
        }
        if (char.charCodeAt(0) > 255) {
            // 字符编码大于255，说明是双字节字符
            curLength += 2
        } else {
            curLength++
        }
        result += char
    })
    return result
}

