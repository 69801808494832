import React from 'react'
import { Card } from 'antd'
import { CardProps } from 'antd/lib/card'

export const UICard: React.FC<CardProps> = (props) => {
  const { bodyStyle, style, title, ...restProps } = props
  return (
    <Card
      size="small"
      title={
        <div
          style={{
            fontWeight: 'bold',
            color: '#333',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {title}
        </div>
      }
      bodyStyle={{
        minHeight: 80,
        maxHeight: '65vh',
        overflowY: 'auto',
        ...bodyStyle,
      }}
      style={{
        ...style,
      }}
      {...restProps}
    ></Card>
  )
}
