import React, { useEffect, useRef } from 'react'
import { Form, Input, Tree, message } from 'antd'
import { getIncludeDir, moveFiles } from 'src/api'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { UIModal } from 'src/components'

export const MoveeModalPage: React.FC<{ refresh: () => Promise<void> }> = ({
  refresh
}) => {
  const { MoveModal } = useSelector((state) => state.modal)
  const { treeTag } = useSelector((state) => state.myFolder)
  const dispatch = useDispatch()

  const { data: filesTree, run: getTreeDir } = useRequest(() => getIncludeDir('/'), {
    manual: true,
    formatResult(data) {
      return setTreeValue(data)
    }
  })
  const handleCreateNewFolder = () => {
    newFolderForm.validateFields().then(({ targetPath }) => {
      runMoveFiles({
        targetPath,
        sourcePath: treeTag
      })
    })
  }
  const { run: runMoveFiles } = useRequest(moveFiles,
    {
      manual: true, onSuccess: (data) => {
        message.success('移动成功')
        refresh()
        dispatch(hideModal('MoveModal'))
      }
    },
  )
  const [newFolderForm] = Form.useForm()

  const focusRef = useRef<Input>(null)

  const setTreeValue = (files: any) => {
    const newArr = files?.filter((item: any, index: number) => {
      if (item?.isDir && (item?.isHomeUserDir || item?.isHomeUserDirChild)) {
        item.key = item?.path
        item.title = item?.name
        item.children = setTreeValue(item?.children)
        return item
      }
    })
    return newArr
  }

  useEffect(() => {
    if (!MoveModal) return
    focusRef.current?.select()
    getTreeDir()
  }, [MoveModal])

  return (
    <UIModal
      title="移动"
      visible={MoveModal}
      onCancel={() => dispatch(hideModal('MoveModal'))}
      onOk={handleCreateNewFolder}
      width={600}
      afterClose={() => newFolderForm.resetFields()}
    >
      <Form
        form={newFolderForm}
        onFinish={handleCreateNewFolder}
      >
        <Form.Item
          name="targetPath"
          label=""
          rules={[
            { required: true, message: '请选择目标文件夹' },
          ]}
        >
          <Tree.DirectoryTree
            autoExpandParent={true}
            showIcon
            treeData={filesTree || []}
            onClick={(e, record: any) => {
              newFolderForm.setFieldsValue({ targetPath: record?.path })
            }}
          />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
