
import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface TooltipWithQuestionIcon {
 tooltipTitle: string;
}
const TooltipWithQuestionIcon = ({
    tooltipTitle,

}: TooltipWithQuestionIcon) => {

    return (
        <Tooltip title={tooltipTitle}>
          <QuestionCircleOutlined style={{marginLeft: 4}}/>
       </Tooltip>
    )
}

export default TooltipWithQuestionIcon;