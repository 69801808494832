import { Badge, Button, Col, Form, Row, Select, message } from "antd";
import { PresetStatusColorType } from "antd/es/_util/colors";
import { FormInstance } from "antd/lib/form";
import React, { RefObject, useState } from "react";
import { queryDatasourceConnections } from "src/api";
import { testConnect } from "src/api/dataTransfer";
import { useDispatch, useRequest } from "src/hook";
import { setSourceConnection, setTargetConnection } from "../../CreateTransferTaskSlice";
import { Iconfont } from "src/components";

const { Item } = Form;
const options = [
	{
		label: <span><Iconfont type='icon-connection-Oracle' style={{marginRight: 4}} />Oracle</span>,
		value: "Oracle",
	},
	{
		label: <span><Iconfont type='icon-connection-OracleCDB' style={{marginRight: 4}} />OracleCDB</span>,
		value: "OracleCDB",
	},
	{
		label: <span><Iconfont type='icon-connection-MySQL' style={{marginRight: 4}} />MySQL</span>,
		value: "MySQL",
	},
	{
		label: <span><Iconfont type='icon-connection-PostgreSQL' style={{marginRight: 4}} />PostgreSQL</span>,
		value: "PostgreSQL",
	},
	{
		label: <span><Iconfont type='icon-connection-SQLServer' style={{marginRight: 4}} />SQLServer</span>,
		value: "SQLServer",
	},
];

interface IProps {
  formRef: RefObject<FormInstance<any>>
  initialValue?: Record<string, any>
}
const FirstForm = ({ formRef }: IProps): JSX.Element => {
	const [sourceTestStatus, setSourceTestStatus] = useState<PresetStatusColorType>("default");
	const [targetTestStatus, setTargetTestStatus] = useState<PresetStatusColorType>("default");
	const [loading, setLoading] = useState({
		target: false,
		source: false
	})

	const dispatch = useDispatch();
	const { run: test } = useRequest(testConnect, {
		manual: true,
		onSuccess() {
			message.success("测试成功");
		},
	});

	const {
		run: querySourceConnection,
		data: sourceConnectionList,
		loading: sourceLoading,
	} = useRequest(queryDatasourceConnections, {
		manual: true,
		formatResult(res) {
			return res.map((i: any) => {
				return {
					label: <span><Iconfont type={`icon-${i.connectionType}`} style={{marginRight: 4}} />{i.connectionName}</span>,
					value: i.connectionId,
					props: i,
				};
			});
		},
	});

	const {
		run: queryTargetConnection,
		data: targetConnectionList,
		loading: targetLoading,
	} = useRequest(queryDatasourceConnections, {
		manual: true,
		formatResult(res) {
			return res.map((i: any) => {
				return {
					label: <span><Iconfont type={`icon-${i.connectionType}`} style={{marginRight: 4}} />{i.connectionName}</span>,
					value: i.connectionId,
					props: i,
				};
			});
		},
	});

	/**
	 * 测试连接
	 * @param type 'source' | 'target'
	 */
	function handleTest(type: "source" | "target") {
		const params = {
			dataSourceType: formRef.current?.getFieldValue(`${type}Type`),
			connectionId: formRef.current?.getFieldValue(`${type}Id`),
		};

		if (params.dataSourceType && params.connectionId) {
			setLoading((l) => {
				l[type] = true;
				return { ...l };
			});
			test(params)
				.then(() => {
					if (type === "source") { 
						formRef.current?.setFieldsValue({ testSource: true });
						setSourceTestStatus("success");
					} 
					if (type === "target") {
						formRef.current?.setFieldsValue({ testTarget: true });
						setTargetTestStatus("success");
					}
					
				})
				.catch(() => {
					if (type === "source") setSourceTestStatus("error");
					if (type === "target") setTargetTestStatus("error");
				}).finally(() => { 
					setLoading((l) => {
						l[type] = false;
						return { ...l };
					});
				});
		}
	}

  // 目标连接改变
  const handleTargetTypeChange = (value: any) => {
    formRef.current?.setFieldsValue({
      targetId: undefined,
    });
    const params = value + '&displayOwnedOnly=true'
    queryTargetConnection(params);
  }

	return (
    <Form 
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      ref={formRef}
    >
      <Row>
        <Col span={12}>
          <Item>源连接</Item>
          <Col span={20}>
            <Item
              name={"sourceType"}
              label={"数据源类型"}
              rules={[{ required: true, message: "请选择数据源类型" }]}
            >
              <Select
                style={{width: 240}}
                loading={sourceLoading}
                onChange={(value) => {
                  formRef.current?.setFieldsValue({
                    sourceId: undefined,
                  });
                  const params = value + '&displayOwnedOnly=true'
                  querySourceConnection(params);

                  // 设置目标连接
                  formRef.current?.setFieldsValue({
                    targetType: value,
                  });
                  handleTargetTypeChange(value)
                }}
                options={options}
              ></Select>
            </Item>
          </Col>
          <Col span={20}>
            <Item
              name={"sourceId"}
              label={"连接"}
              rules={[{ required: true, message: "请选择连接" }]}
            >
              <Select
                style={{width: 240}}
                loading={targetLoading}
                onChange={(value, options: any) => {
                  dispatch(setSourceConnection(options.props));
                }}
                options={sourceConnectionList}
              ></Select>
            </Item>
            <Item
              name={"testSource"}
              rules={[{ required: true, message: "请测试连接" }]}
            >
              <Button
                type="link"
                loading={loading?.source}
                onClick={() => handleTest("source")}
              >
                <Badge status={sourceTestStatus}></Badge>测试连接
              </Button>
            </Item>
          </Col>
        </Col>
        <Col span={12}>
          <Item>目标连接</Item>
          <Col span={20}>
            <Item
              name={"targetType"}
              label={"数据源类型"}
              dependencies={["sourceType"]}
              rules={[
                { required: true, message: "请选择数据源类型" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("sourceType") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("请选择相同的数据源"));
                  },
                }),
              ]}
            >
              <Select
                style={{width: 240}}
                onChange={handleTargetTypeChange}
                options={options}
              ></Select>
            </Item>
          </Col>
          <Col span={20}>
            <Item
              name={"targetId"}
              label={"连接"}
              rules={[{ required: true, message: "请选择连接" }]}
            >
              <Select
                style={{width: 240}}
                onChange={(value, options: any) => {
                  dispatch(setTargetConnection(options.props));
                }}
                options={targetConnectionList}
              ></Select>
            </Item>
            <Item
              name={"testTarget"}
              rules={[{ required: true, message: "请测试连接" }]}
            >
              <Button
                type="link"
                loading={loading?.target}
                onClick={() => handleTest("target")}
              >
                <Badge status={targetTestStatus}></Badge>测试连接
              </Button>
            </Item>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export { FirstForm };

