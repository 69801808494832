import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useRequest } from 'src/hook'
import { Button, Form, message, Space, Steps } from 'antd'
import { EditRoleForm, RoleInfoFormValues } from '../forms/EditRoleForm'
import { ConnGrantPermForm } from '../forms/ConnGrantPermForm'
import { LazyWrapper } from 'src/components'
import { addRole_dataSource, getPermTypes, getRolesByConnectionId } from 'src/api'
import { ConnBindUserForm } from '../forms/ConnBindUserForm'
import { RoleRecordContext } from '../contexts/RoleRecordContext'
import { ConnectionContext } from '../contexts/ConnectionContext'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import styles from './wizard.module.scss'

enum WizardStep {
  addRole = 0,
  grantPerms = 1,
  bindUsers = 2,
}

export const ConnAddRoleWizard = ({
  submitterRef,
}: {
  submitterRef: React.RefObject<HTMLElement>
}) => {
  const dispatch = useDispatch()
  const [current, setCurrent] = useState<WizardStep>(WizardStep.addRole)
  const furthestStepRef = useRef<WizardStep>(WizardStep.addRole)

  useEffect(() => {
    const furthestStep = furthestStepRef.current
    furthestStepRef.current = current > furthestStep ? current : furthestStep
  }, [current])

  const [roleInfoForm] = Form.useForm()
  const [grantPermsForm] = Form.useForm()
  const [bindUsersForm] = Form.useForm()

  const { mode, record, refreshRoles } = useContext(RoleRecordContext)
  const { connection } = useContext(ConnectionContext)
  const connectionId = connection?.connectionId

  const { data: permTypes } = useRequest(getPermTypes, {
    cacheKey: 'getPermTypes',
  })

  const { loading: loadingAdd, run: runAddRole } = useRequest(addRole_dataSource, {
    manual: true,
    onSuccess: () => {
      message.success('添加成功')
      dispatch(hideModal('ModalConnAddRoleWizard'))
      refreshRoles && refreshRoles()
    },
  })

  const { data: roleOptions, run: fetchRoleOptions } = useRequest(
    (connectionId) => getRolesByConnectionId({ connectionId }),
    {
      manual: true,
      formatResult: (roles) => {
        return roles.map(({ roleName }) => ({
          label: roleName,
          value: roleName,
        }))
      },
    },
  )

  useEffect(() => {
    if (connection) {
      // 获取
      fetchRoleOptions(connection.connectionId)
    }
  }, [connection, fetchRoleOptions])

  const steps = [
    {
      title: '新增角色',
      content: (
        <EditRoleForm
          form={roleInfoForm}
          mode={mode}
          roleOptions={roleOptions}
        />
      ),
    },
    {
      title: '授予权限',
      content: (
        <ConnGrantPermForm
          permTypes={permTypes}
          form={grantPermsForm}
          mode={mode}
          record={record}
          connectionId={connectionId}
        />
      ),
    },
    {
      title: '绑定用户',
      content: (
        <ConnBindUserForm
          form={bindUsersForm}
          mode={mode}
          record={record}
          connectionId={connectionId}
        />
      ),
    },
  ]

  const handleConfirmWizard = useCallback(async () => {
    try {
      const roleInfoFormValues = await roleInfoForm.validateFields()
      const {
        id,
        name,
        description,
        duration,
        period,
      } = roleInfoFormValues as RoleInfoFormValues

      const [beginDate, endDate] =
        duration?.map((date) => date.format('YYYYMMDD')) || []
      const dayMask = period?.join('')

      let grantPerms: string[] | undefined
      let grantUser: string[] | undefined
      const furthestStep = furthestStepRef.current
      if (furthestStep > WizardStep.addRole) {
        grantPerms = (await grantPermsForm.validateFields())
          .grantedPerms as string[]
      }
      if (furthestStep > WizardStep.grantPerms) {
        grantUser = (await bindUsersForm.validateFields())
          .boundUsers as string[]
      }

      runAddRole({
        id,
        type: 'CONNECTION_ROLE',
        name,
        connectionId,
        description,
        beginDate,
        endDate,
        dayMask,
        grantPerms,
        grantUser,
      })
    } catch {}
  }, [bindUsersForm, connectionId, grantPermsForm, roleInfoForm, runAddRole])

  const submitter = (
    <Space>
      <Button
        className={styles.submitterBtn}
        onClick={() => {
          setCurrent((current) =>
            current > WizardStep.addRole ? current - 1 : current,
          )
        }}
        disabled={current <= WizardStep.addRole}
      >
        上一步
      </Button>
      {current < WizardStep.bindUsers ? (
        <Button
          className={styles.submitterBtn}
          onClick={async () => {
            try {
              if (current >= WizardStep.addRole) {
                await roleInfoForm.validateFields()
              }

              setCurrent((current) =>
                current < WizardStep.bindUsers ? current + 1 : current,
              )
            } catch {}
          }}
        >
          下一步
        </Button>
      ) : (
        <Button
          className={styles.submitterBtn}
          type="primary"
          onClick={() => handleConfirmWizard()}
          loading={loadingAdd}
        >
          完成
        </Button>
      )}
    </Space>
  )

  return (
    <div className="add-role-steps-form">
      <Steps current={current}>
        {steps.map(({ title }, index) => (
          <Steps.Step title={title} key={index} />
        ))}
      </Steps>

      {steps.map(({ content }, index) => (
        <LazyWrapper
          className={styles.stepsForm}
          active={current === index}
          key={index}
        >
          {content}
        </LazyWrapper>
      ))}
      {submitterRef.current &&
        ReactDOM.createPortal(submitter, submitterRef.current)}
    </div>
  )
}
