import React from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { FileInfo, getFileInfo } from 'src/api'
import { FilePath, setSingleFile } from './myFolderSlice'
import styles from './folderStatusBar.module.scss'

interface FolderStatusBarProps {
  filePaths: FilePath[]
}

export const FolderStatusBar: React.FC<FolderStatusBarProps> = (props) => {
  const dispatch = useDispatch()
  const { filePaths } = props
  const { multipleMode, pwd } = useSelector((state) => state.myFolder)
  const { data: fileInfo } = useRequest(
    () => getFileInfo(filePaths[0] || pwd.path),
    {
      refreshDeps: [filePaths],
    },
  )
  const renderFileInfo = (fileInfo?: FileInfo) => {
    if (!fileInfo) return
    dispatch(setSingleFile(fileInfo))
    const { type, childCount, size, lastModifiedTime, childCountAll } = fileInfo
    return (
      <div className={styles.contentRow}>
        {type === 'directory' && (
          <div className={styles.contentItem}>包含 {childCountAll} 个项目，您可查看的项目 {childCount} 个</div>
        )}
        {type === 'directory' && (
          <div className={styles.contentItem}>
            总大小 {size.replace('bytes', '字节')}
            {/* todo: 与后端协商使用文件大小单位对象而非带单位的字符串 */}
          </div>
        )}
        {type === 'file' && (
          <div className={styles.contentItem}>
            文件大小 {size.replace('bytes', '字节')}
            {/* todo: 与后端协商使用文件大小单位对象而非带单位的字符串 */}
          </div>
        )}
        <div className={styles.contentItem}>
          最近修改于 {new Date(lastModifiedTime).toLocaleString()}
        </div>
      </div>
    )
  }
  const getContent = () => {
    if (multipleMode) return null
    return renderFileInfo(fileInfo)
  }
  return <div className={styles.statusBar}>{getContent()}</div>
}
