import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./customFetch"


/**
 * @description: 查询 告警
 * @params {type: 0：系统告警, 1：业务告警}
 * @return {*} 
 */
export const getAlarm = (type: string | number): Promise<any> => {
  return fetchGet(`/user/alarm/find/${type}`)
}

/**
 * @description: 修改状态接口
 * @params { id: number | string, status: boolean}
 * @return {*} 
 */
export const updateAlarmStatus = (params: { id: number | string, status: boolean}): Promise<any> => {
  return fetchPut(`/user/alarm/status`, params)
}

/**
 * @description: 新增/编辑 告警
 * @params { id: number | string, status: boolean}
 * @return {*} 
 */
interface IParams {
  id?: string | number,
  type: string,
  value: string | number,
  alarmMethods: string[],
  targetPerson: string,
  userId: string[],
  status: boolean,
}
export const editAlarnConfig = (
  params: IParams,
): Promise<any> => {
  return fetchPost(`/user/alarm/create`, params)
}

/**
 * @description: 删除告警
 * @params { id: number}
 * @return
 */
export const delAlarm = (id: number) => {
  return fetchDelete(`/user/alarm/delete/${id}`)
}

/**
 * @description: 获取 手机短信、邮箱配置状态
 * @params 
 * @return 
 */
export const getAlarmMethodsStatus = (): Promise<any> => {
  return fetchGet(`/user/alarm/find/status`)
}