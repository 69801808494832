import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { DataSource, getSupportedConnectionTypes, DataSourceType } from 'src/api'

interface DataSourceState {
  dataSourceList: DataSource[]
  dataSourceMap: {[key in DataSourceType]?: DataSource }
}

const initialState: DataSourceState = {
  dataSourceList: [],
  dataSourceMap: {},
}
export const dataSourceSlice = createSlice({
  name: 'dataSource',
  initialState,
  reducers: {
    setDataSourceList: (state, action: PayloadAction<DataSource[]>) => {
      state.dataSourceList = action.payload
      // todo: 扩展 dataSourceList，加索引，而非新增一个 Map
      action.payload.forEach(
        (dataSource) =>
          (state.dataSourceMap[dataSource.dataSourceName] = dataSource),
      )
    },
  },
})

export const dataSourceReducer = dataSourceSlice.reducer

export const { setDataSourceList } = dataSourceSlice.actions

// todo: 作为核心数据源属性，App 加载时获取
export const getAndSetDataSourceList = (): AppThunk => (dispatch) => {
  return getSupportedConnectionTypes().then((dataSourceList) => {
    const dataSourceListFiltered =  dataSourceList?.map((dataSource) => {
      const { connectionForm } = dataSource
      const connectionFormFiltered =  connectionForm.filter(({label}) => label !== "监控开关")
      return Object.assign ({}, dataSource, { connectionForm: connectionFormFiltered})
      })
    dispatch(setDataSourceList(dataSourceListFiltered))
  })
}
