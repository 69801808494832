import { Row } from 'antd'
import React from 'react'
import './index.css'

export const AlarmPage = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Row justify="space-between" align="middle" className="header">
        <h1>告警分析</h1>
      </Row>
      <div className="body">
        <iframe
          title='iframe1'
          width="100%"
          height="100%"
          src="http://localhost:3000/"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  )
}
