/**
 * 自动授权
 */
import { fetchGet, fetchPost } from "./customFetch"

// schema, database, connection 层级选中权限回显接口
export const getPermissionData = (params: any) => {
  return fetchPost(`/user/permissionCollection/getPermissionsPanelUpObject`, params)
}

// 获取用户参数枚举
export const getPermEnum = () => {
  return fetchGet(`/user/autoPerm/expression`)
}

// 测试用户参数
export const testUserPerm = (params: any) => {
  return fetchPost(`/user/autoPerm/test`, params)
}

// 添加编辑权限集
export const createPermissionCollection = (params: {collectionName: string}) => {
  return fetchPost(`/user/permissionCollection/createPermissionCollection`, params)
}

// 更新编辑权限集
export const updatePermissionCollection = (params: any) => {
  return fetchPost(`/user/permissionCollection/updatePermissionCollection`, params)
}

// 重置权限集
export const resetPermissionCollection = (params: {roleId: string}) => {
  return fetchPost(`/user/permissionCollection/resetPermissionCollection`, params)
}
//权限集 重命名
export const renamePermissionCollection = (params: any) => { 
	return fetchPost(`/user/permissionCollection/renamePermissionCollection`, params);
}

// 对象集权限回显
export const getPermissionsPanelObject = (params: any) => {
  return fetchPost(`user/permissionCollection/getPermissionsPanelObject`, params)
}

// 权限集信息查询
export const getPermissionCollection = (params: any) => {
  return fetchPost(`/user/permissionCollection/getPermissionsRoleInfo`, params)  
}

//留痕 tree
export const getSelectedPermissionCollectionNodes= (roleId: string) => {
  return fetchGet(`/user/connections/connection/permissionCollectionNodes/${roleId}`)  
}
interface IPermissionNodeParams {
  connectionId: number
  connectionType: string
  nodeType: string
  nodeName: string
  nodePath: string
  nodePathWithType: string
}
//留痕 treeNode
export const getSelectedPermissionCollectionNode= (params: IPermissionNodeParams) => {
  return fetchPost(`/dms/meta/group/permissionNode`,params)  
}