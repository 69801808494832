/**
 * 操作权限（查看/新增/编辑）
 */
import React, { useEffect, useMemo, useState } from 'react'
import { Modal, Form, Input, Tabs, Row, Col, Checkbox, message, Spin } from 'antd'
import {
  getPermissionTemplate,
  savePermisssion,
  getToolTemplateInfo,
  updateUserTool,
} from 'src/api'
import { useDispatch } from 'src/hook'
import { queryPermissionList } from './authorizationManagePageSlice'
import styles from './index.module.scss'

interface IProps {
  visible: boolean
  setModalVisible: (v: boolean) => void
  optionType: string
  dataSourceType: string
  [p: string]: any
}
const OperationPermissionsModal = (props: IProps) => {
  const {
    visible,
    userId,
    groupId,
    nodePathWithType,
    optionType,
    dataSourceType,
    selectNodeType,
    perssionInfo,   // 查看或编辑权限选中值
    setModalVisible,
    callback,
    templateOperationsSet,  // 工具权限选中值
    timeRange,  // 后端需要
    setCheckedParamsOperationNames,  // 添加用户，获取勾选值
    setTemplateOperationsSet,
  } = props

  const dataSourceTypeValue = perssionInfo?.dataSourceType || dataSourceType
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [activeTabKey, setActiveTabKey] = useState<string>()
  const [checkedParams, setCheckedParams] = useState<any[]>([])
  const [permissionTemplate, setPermissionTemplate] = useState<any[]>([])
  const [loading, setLoading]= useState(false)

  useEffect(() => {
    if (dataSourceTypeValue) {
      // 编辑功能权限
      if (['editToolPermission', 'addToolPermission', 'batchEditToolPermission'].includes(optionType)) {
        queryToolTemplate(getToolTemplateInfo, dataSourceTypeValue, selectNodeType)
      } else {
        queryTemplate(getPermissionTemplate, dataSourceTypeValue)
      }
    }
  }, [dataSourceTypeValue, optionType])

  useEffect(() => {
    // 回填勾选值
    if (perssionInfo?.templateOperationsSet?.length) {
      setCheckedParams(perssionInfo?.templateOperationsSet)
    } else if (templateOperationsSet?.length) { 
      setCheckedParams(templateOperationsSet)
    }
  }, [perssionInfo?.templateOperationsSet, templateOperationsSet])

  // 刷新权限列表数据
  const refreshData = () => {
    dispatch(queryPermissionList(dataSourceTypeValue))
  }

  // 查询所有权限模版
  const queryTemplate = (fn: (p: string) => any, dataSourceType: string) => {
    setLoading(true)
    fn(dataSourceType)
      .then((data: any) => {
        setPermissionTemplate(data)
        setActiveTabKey(data[0]?.objectType)
      })
      .catch((err: any) => {
        console.error('查询失败', err)
      }).finally(()=>{
        setLoading(false)
      })
  }

  const queryToolTemplate = (fn: (p: string, n: string) => any, dataSourceType: string, nodeType: string) => {
    setLoading(true)
    fn(dataSourceType, nodeType)
        .then((data: any) => {
          setPermissionTemplate(data)
          setActiveTabKey(data[0]?.objectType)
        })
        .catch((err: any) => {
          console.error('查询失败', err)
        }).finally(()=>{
      setLoading(false)
    })
  }

  const handleTabsChange = (activeKey: string) => {
    setActiveTabKey(activeKey)
  }

  const handleChange = (operations: any[], objectType: string) => {
    setCheckedParams((data: any) => {
      const has = data?.filter((i: any) => i?.objectType === objectType)?.length
      if (has) {
        data = data.map((i: any) => {
          if (i?.objectType === objectType) {
            return {
              ...i,
              operations,
            }
          }
          return { ...i }
        })
      } else {
        data = data.concat([
          {
            objectType,
            operations,
          },
        ])
      }
      return [...data]
    })
  }

  // 修改工具权限
  const updateUserToolPermissiom = (templateOperationsSet: any[]) => {
    setLoading(true)
    const params: any = {
      userIds: typeof userId === 'string'? [userId] : userId,
      templateOperationsSet,
      dataSourceType: dataSourceTypeValue,
      ...timeRange,
    }
    if (selectNodeType === 'group') {
      params.groupId = groupId
    } else {
      params.nodePathWithType = nodePathWithType
    }
    updateUserTool(params)
      .then(() => {
        message.success('修改成功')
        setModalVisible(false)
        callback && callback()
      })
      .catch((err) => {
        console.error('修改工具权限失败：', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // 新增或编辑权限
  const handlePermissionSave = () => {
    // 编辑功能权限 
    if (['editToolPermission', 'batchEditToolPermission'].includes(optionType)) {
      return updateUserToolPermissiom(checkedParams)
    } 

    // 添加用户，返回 勾选值
    if (optionType === 'addToolPermission') {
      let checkedParamsOperationNames: string[] = []
      checkedParams?.forEach((item: any) => {
        const permissionTemplateTmp = permissionTemplate.find((itemTemplate: any) => itemTemplate?.objectType === item?.objectType) 
        item?.operations.forEach((itemOperation: any) => {
          permissionTemplateTmp?.operations.forEach((itemTemplateOperations: any) => {
            if (itemTemplateOperations?.operation === itemOperation) {
              checkedParamsOperationNames.push(itemTemplateOperations?.operationName)
              return;
            }
          }) 
        })
      })
      setTemplateOperationsSet(checkedParams)
      setCheckedParamsOperationNames(checkedParamsOperationNames)
      setModalVisible(false)
      return 
    }

    form.validateFields().then((values) => {
      setLoading(true)
      const params: any = {
        ...values,
        dataSourceType: dataSourceTypeValue,
        templateOperationsSet: checkedParams,
        ...timeRange,
      }
      if (optionType === 'edit') {
        params.id = perssionInfo?.id
      }
      savePermisssion(params)
        .then(() => {
          message.success('操作成功')
          setModalVisible(false)
          refreshData()
        })
        .catch((err: any) => {
          console.error('操作失败:', err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  // 查看或没有权限模板都不可以提交
  const btnDisabled = optionType === 'look' || !permissionTemplate?.length
  return (
    <Modal
      width={1000}
      title={
        optionType === 'addToolPermission'
          ? ''
          : optionType === 'batchEditToolPermission'
          ? '批量编辑工具权限'
          : optionType === 'editToolPermission'
          ? '操作权限'
          : ''
      }
      okText={optionType === "addToolPermission" ? "确定" : "提交"}
      // okText="提交"
      visible={visible}
      onCancel={() => setModalVisible(false)}
      onOk={handlePermissionSave}
      okButtonProps={{ disabled: btnDisabled, loading }}
      destroyOnClose
    >
      <Spin spinning={loading}>
        {(!['editToolPermission', 'addToolPermission', 'batchEditToolPermission'].includes(optionType)) && (
          <Form layout="inline" form={form} initialValues={perssionInfo}>
            <Form.Item label="权限名" name="name" rules={[{ required: true }]}>
              <Input style={{ width: 200 }} disabled={optionType === 'look'} />
            </Form.Item>
            <Form.Item label="备注" name="description">
              <Input style={{ width: 200 }} disabled={optionType === 'look'} />
            </Form.Item>
            <Form.Item label="数据源类型">{dataSourceTypeValue}</Form.Item>
          </Form>
        )}

        <Tabs
          onChange={handleTabsChange}
          activeKey={activeTabKey}
          className={styles.customAuthorizeTab}
          tabPosition={optionType === "addToolPermission" ? "left" : "top"}
        >
          {permissionTemplate?.map((item: any) => {
            const checkedValues = checkedParams?.filter(
              (i: any) => i?.objectType === item?.objectType,
            )?.[0]?.operations

            return (
              <Tabs.TabPane tab={item?.objectTypeName} key={item?.objectType}>
                <CheckBoxItem
                  item={item}
                  optionType={optionType}
                  activeTabKey={activeTabKey}
                  permissionTemplate={permissionTemplate}
                  checkedValues={checkedValues}
                  checkChangeCallback={handleChange}
                />
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </Spin>
    </Modal>
  )
}
export default OperationPermissionsModal

const CheckBoxItem = (props: any) => {
  const {
    item,
    optionType,
    activeTabKey,
    permissionTemplate,
    checkedValues=[],
    checkChangeCallback,
  } = props
 
 const allOptions = permissionTemplate
   ?.filter((i: any) => i?.objectType === activeTabKey)?.[0]
   ?.operations?.map((i: any) => i?.operation) || []
  
  const { indeterminate, checkAll, checkedList } = useMemo(() => {
    const checkAll = allOptions?.every((i: any)=>checkedValues?.includes(i)) 
    const checkedList = [...checkedValues]
    const indeterminate =
      !!checkedValues.length && checkedValues.length < allOptions.length
    return {
      checkAll,
      checkedList,
      indeterminate,
    }
  }, [allOptions, checkedValues])

  const onCheckAllChange = (e: any) => {
    const checkedList = e.target.checked ? allOptions : []
    checkChangeCallback(checkedList, activeTabKey)
  }

  const handleGroupChange = (checkedList: any) => {
    checkChangeCallback(checkedList, activeTabKey)
  }

  return (
    <>
      <Row className={styles.mb10}>
        <Checkbox
          checked={checkAll}
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          disabled={optionType === 'look'}
        >
          全选
        </Checkbox>
      </Row>
      <Checkbox.Group
        style={{ width: '100%' }}
        onChange={handleGroupChange}
        value={checkedList}
        disabled={optionType === 'look'}
      >
        {item?.operations?.map((res: any, index: number) => (
          <Row className={styles.mb10} key={index}>
            <Col span={8}>
              <Checkbox className={styles.mr8} value={res.operation} />
              {res.operationName}
            </Col>
            <Col span={16}>{res.description}</Col>
          </Row>
        ))}
      </Checkbox.Group>
    </>
  )
}

