import React from 'react'
import { Descriptions } from 'antd';
import type { DataSourceType } from 'src/types';

interface ITableFieldProps {
  engine?: string;
  model?: string;
  [k: string]: string | undefined
}
 const MoreTableInfoFields = ({
  connectionType,
  tableFieldInfos={}
}: {
  connectionType: DataSourceType | undefined;
  tableFieldInfos?: ITableFieldProps 
}) => {

  const itemsMap = new Map()
  itemsMap.set('StarRocks', <StartRocksTableInfo values={tableFieldInfos}/>)

  return itemsMap.get(connectionType) ||<div></div>
}

const StartRocksTableInfo = (
  {
    values
  }: {
    values:ITableFieldProps
  }) => {
  return (
    <Descriptions column={2}>
      <Descriptions.Item label="引擎类型">{values?.engine}</Descriptions.Item>
      <Descriptions.Item label="数据模型">{values?.model}</Descriptions.Item>
    </Descriptions>
  )
}

export default MoreTableInfoFields;

