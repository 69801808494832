import React from 'react'
import { Card } from 'antd'
import { CardProps } from 'antd/lib/card'
import styles from './index.module.scss'

interface ChartCardProps extends CardProps {}

export const ChartCard: React.FC<ChartCardProps> = (props) => {
  const { title, children } = props
  return (
    <Card
      title={title}
      className={styles.card}
      bodyStyle={{ padding: '16px 24px' }}
    >
      {children}
    </Card>
  )
}
