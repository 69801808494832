import { integer } from "monaco-languageclient"
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "./customFetch"
import type { ISearchSdtItem } from './sdt';
/**
 * 新增组
 */
interface addGroupParams { 
    datasource: string,
    groupName: string
    connectionIds?: number[]
}
export const createConnectionGroup = (params: addGroupParams) => {
  return fetchPost(`/user/group/create`, params)
}

/**
 * 修改组名
 */
interface modifyGroupNameParams { 
	id: number,
	groupName: string
}
export const modifyConnectionGroupName = (params: modifyGroupNameParams) => {
  return fetchPost(`/user/group/update`, params)
}

/**
 * 删除组
 */
export const deleteGroup = (id: number) => {
  return fetchDelete(`/user/group/${id}`)
}

/**
 * 查询分组视图
 */
export const queryGroupNodes = (doFilter: boolean) => { 
	return fetchGet(`/user/connections/connection/nodes/${doFilter}`);
}
interface nodeParams {
  pageNo: integer,
  pageSize: integer,
  roleName: String,
}
export const queryGroupNodes_automic = (params: nodeParams) => { 
	return fetchPost(`/user/permissionCollection/getPermissionCollection`, params);
}

/**
 * 根据数据源查连接
 */
export const queryDatasourceConnections = (datasource: string) => {
  return fetchGet(`/user/group/datasource/connections/?datasource=${datasource}`);
}

/**
 * 根据组查询连接
 */
export const queryGroupConnections = (groupId: number) => {
	return fetchGet(`/user/group/${groupId}/connections`)
} 

/**
 * 查询连接(组能新增的所有连接)
 */
// interface queryConnetionParams {
//   groupId: number
// 	datasource: string
// 	[p:string]: any
// }
export const queryCanAddConnections = (	datasource: string) => {
	// const paramsString = Object.keys(params)
  //   .map((key) => key + '=' + params[key])
  //   .join('&')
  return fetchGet(`/user/group/unmap/connections?datasource=${datasource}`)
} 

/* 数字字典查询列表 */
export const getProtectSdtSearchList = (params: {query: string}): Promise<ISearchSdtItem[]> =>
  fetchGet(`/dms/fullTextSearch/searchDataProtectSdt`,params)


/**
 * 添加连接
 */
interface addConnectionParams { 
	id: number
	connectionIds: number[]
  inheritToken?: boolean;
  dataSourceType?: string
}
export const addConnection = (params: addConnectionParams) => {
  return fetchPost(`/user/group/connection/map/create`, params)
}

/**
 * 移出连接（移除组和连接的关系）
 */
export const removeConnection = (connetionIds: number[]) => {
  return fetchDelete(`/user/group/connection/remove`, connetionIds)
}

/**
 * 新建连接
 */
interface createParams {
  dataSourceType: string
  connectionId?: number,
  userInputs: {
    dataSourceVersion: string
    connectionName: string
    connectionUrl: string
    connectionPort: number
    userName: string
    password: string
    remark: string
    devModel: boolean
    dataSourceType: string
  }
}
export const createConnection = (params: createParams) => {
  return fetchPost(`/dms/connection/configuration`, params)
}

/**
 * 编辑连接
 */
export const editConnection = (params: createParams) => {
  return fetchPut(`/dms/connection/configuration`, params)
}

/**
 * 测试连接
 */
export const testConnection = (params: createParams) => {
  return fetchPost(`/dms/connection/effectiveness`, params)
}

/**
 * 获取上一次测试连接的连接状态
 */
export const getLastConnectionStatus = (params: { connectionId: number }) => {
  return fetchPost(`/user/connections/testConnectionStatus`, params)
}

/**
 * 删除连接 
 */
interface deleteParams {
  connectionId: number
  connectionType: string
  nodeName: string
  nodePath: string
  nodeType: string
}
export const deleteConnection = (params: deleteParams) => {
  return fetchDelete(`/dms/menu`, params)
}

/**
 * 根据连接查连接下的资源（pdb、schema）--已有
 */
interface getConnectionInfoParams {
  connectionId: number
  connectionType: string
  nodeName: string
  nodePath: string
  nodeType: string
  groupId: number
}
export const getConnectionInfo = (params: getConnectionInfoParams) => {
  return fetchPost(`/dms/meta/node`, params)
}

/**
 *  数据源信息查询
 */
export const getdmsConnectionConfig = (
  connectionId: number,
) => {
  return fetchGet(`/dms/connection/form/connection/${connectionId}`)
}

/**
 * 根据连接获取连接池配置信息   --已有
 */
export const getConnectionPoolConfig = (connectionId: number) => {
  return fetchGet(
    `/dms/connection/form/connection_pool_setting/${connectionId}`,
  )
}

/**
 * 根据连接更新连接池配置信息   --已有
 */
interface updateConnectionPoolParams {
  connectionId: number
  userInputs: {
    maxSize: number
    minSize: number
    borrowMaxWaitMillis: number
    preparePool: boolean
    testOnBorrow: boolean
    removeAbandonedTimeout: number
    autoClosePoolTimeout: number
    implicitCommitTimeout: number
    transactionType: string
  }
}
export const updateConnectionPoolConfig = (params: updateConnectionPoolParams) => {
  return fetchPost(`/dms/connection/form/connection_pool_setting`, params)
}

/**
 * 根据连接获取事务配置信息   --已有
 */
export const getConnectionWorkConfig = (connectionId: number) => {
  return fetchGet(
    `/user/connections/conectionSetting/${connectionId}/allowAutoCommit`,
  )
}

/**
 * 根据连接更新事务配置信息  --已有
 */
interface updateConnectionWorkParams {
  connectionId: number | string | undefined
  variable: string
  variable_value: boolean | string
}
export const updateConnectionWorkConfig = (params: updateConnectionWorkParams) => {
  return fetchPost(`/user/connections/conectionSetting`, params)
}

/**
 * 数据备份 可选连接
 */
export const getSqlBackupLocationConnections = (params: {
  datasource: string;
  displayOwnerOnly: boolean
}) => {
  return fetchGet(`/analyze/audit_report/connections`,params)
} 

/**
 * 查询链接管理员信息
 */
export const queryConnectionManageInfo = (connectionId: string) => {
  return fetchGet(`/user/group/connection/${connectionId}/manager`)
} 

/**
 * 查询可以更改的管理员列表
 */
export const queryManageList = () => {
  return fetchGet(`/user/org/findUserOrgSdtVo/manager`)
} 

/**
 * 根据连接更新连接管理员信息
 */
interface updateManageParams { 
	connectionId: number
	userId: string
}
export const updateManageInfo = (params: updateManageParams) => {
  return fetchPost(`/user/group/connection/manager`, params)
}

/**
 * 查询数据源连接池连接数量
 */
export const queryConnectionCount = (connectionId: number) => {
  return fetchGet(`/dms/tx/connection/${connectionId}/pool/count`)
} 

/**
 * 查询今日SQL，总SQL   SQLCount
 */
export const querySQLCount = (connectionName:string,) => {
  return fetchGet(`/user/group/${connectionName}/sql/count`)
}

/**
 * 授权的用户数量 
 */
export const queryUserCount = (connectionId: number) => {
  return fetchGet(`/userCount/${connectionId}`)
}

/**
 * 库表统计
 */
export const queryDBTableCount = (connectionId: number) => {
  return fetchGet(`/user/group/${connectionId}/database/table/count`);
};

/**
 * 批量连接查询 （根据connectionId查询连接信息）
 */
export const queryConnectionInfo = (params: string[]) => {
  return fetchPost(`/user/connections/connection`, params)
}

/**
 * 组管理员获取
 */
export const queryGroupManage = (groupId: string) => {
  return fetchGet(`/user/group/${groupId}/manager`)
}

/**
 * 组管理员修改
 */
interface groupParamsI { 
  userId: string,
  groupId: string
}
export const updateGroupManage = (params: groupParamsI) => {
  return fetchPost(`/user/group/${params?.groupId}/manager`, params)
}


/**
 * 获取连接池管理列表信息
 */
interface ConTableInfoParamsI {
  connectionId: string;
  dataSourceType: string;
  [p: string]: any
}
export const queryConnectionTableInfo = (params: ConTableInfoParamsI) => {
  return fetchGet(
    `/dms/tx/connection/get_connection_pool_status_summary/${params?.dataSourceType}/${params?.connectionId}`
  );
};

/**
 * 终止连接 
 */
interface StopParamsI {
  connectionId: number;
  connectionObjectId: string;
}
export const stopExecute = (params: StopParamsI) => {
  return fetchPost(`/dms/tx/connection/closeConnection`, params);
};

/**
 * 执行计划查询
 */
interface planParamsI {
  dataSourceType: string
  connectionId: number
  databaseName: string
  statements: string
}
export const explainPlan = (params: planParamsI) => {
  return fetchPost(`/dms/statement/explain`, params);
};