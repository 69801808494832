import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'src/hook'
import { Form, Input, message, TreeSelect, Checkbox } from 'antd'
import { UIModal } from 'src/components'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { fetchOrgTreeData } from '../organizationSlice'
import { addDept, OrgTreeNode } from 'src/api'
import { FormLayout } from 'src/constants'
import { nameValidator } from 'src/util/nameValidator'

const traversalOrgTreeNode = (rootNode: OrgTreeNode): any => {
  const { title, name, orgType, children } = rootNode
  return {
    title,
    value: name,
    selectable: ['COMPANY', 'DEPT'].includes(orgType),
    children: (children || []).map(traversalOrgTreeNode),
  }
}

export const AddDeptModal = ({ usersList }: { usersList: any }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const { selectedNode, orgTreeData } = useSelector(
    (state) => state.organization,
  )

  const userSelectOptions = usersList?.map((user: any) => {
    const { userId, userName } = user
    return {
      label: `${userName}（${userId}）`,
      value: userId,
    }
  })

  const initialSelectedParent = useMemo(() => {
    if (selectedNode?.orgType === 'DEPT') {
      return selectedNode?.name
    } else {
      return orgTreeData[0]?.name
    }
  }, [selectedNode, orgTreeData])

  const treeSelectData = useMemo(() => {
    return orgTreeData.map(traversalOrgTreeNode)
  }, [orgTreeData])

  const { AddDept } = useSelector((state) => state.modal)
  const [addDeptForm] = Form.useForm()

  const handleSubmitAddDept = () => {
    if (!addDeptForm) {
      console.error('表单实例未正确初始化');
      return;
    }
    addDeptForm.validateFields().then((values) => {
      const { name, description, externalDepartment, principal } = values
      console.log("是否外部部门：" + externalDepartment)
      let parId: any = null
      if (
        selectedNode?.orgType === 'DEPT' ||
        selectedNode?.orgType === 'SUB_COMPANY' ||
        selectedNode?.orgType === 'COMPANY'
      ) {
        parId = selectedNode?.id
      }
      const parents = [parId]

      setConfirmLoading(true)
      addDept({ name, description, parents, principal, orgType:'DEPT', externalDepartment})
        .then(() => {
          message.success('部门添加成功')
          dispatch(fetchOrgTreeData())
          dispatch(hideModal('AddDept'))
        })
        .finally(() => setConfirmLoading(false))
    })
  }

  // 当前选中节点的父节点
  const parentNode = useMemo(() => {
    if (!selectedNode) return orgTreeData[0]
    const pos: string | undefined = (selectedNode as any).pos
    if (!pos) return orgTreeData[0]
    const parentNode = pos
        .split('-')
        .slice(2, -1)
        .reduce((prev, next) => prev.children[Number(next)], orgTreeData[0])
    return parentNode
  }, [orgTreeData, selectedNode])

  const modalLabel = parentNode?.orgType === 'DEPT' ? '所属部门' : '所属公司';

  return (
    <UIModal
      title="添加部门"
      visible={AddDept}
      onCancel={() => dispatch(hideModal('AddDept'))}
      onOk={() => handleSubmitAddDept()}
      width={480}
      confirmLoading={confirmLoading}
    >
      {/* todo 全局统一： Modal 组合 Form 时设置 Form preserve: false，而不是 Modal afterClose 重置表单 */}
      <Form form={addDeptForm} {...FormLayout} preserve={false}>
        <Form.Item
          name="parent"
          label={modalLabel}
          initialValue={initialSelectedParent}
        >
          <TreeSelect treeData={treeSelectData} treeDefaultExpandAll disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="部门名称"
          rules={[
            { required: true, message: '请输入部门名称' },
            { max: 20, min: 2, message: '部门名称须在2-20个字之间' },
            { validator: nameValidator },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
            name="externalDepartment"
            label="部门类型"
            valuePropName="checked"
            getValueFromEvent={(e) => e.target.checked}
            initialValue={selectedNode?.externalDepartment}
        >
          <Checkbox disabled={selectedNode?.externalDepartment}>外部部门</Checkbox>
        </Form.Item>
        {/* <Form.Item name="description" label="描述和备注">
          <Input></Input>
        </Form.Item> */}
        {/*<Form.Item*/}
        {/*  name="principal"*/}
        {/*  label="负责人"*/}
        {/*  rules={[{ required: true, message: '请输入负责人' }]}*/}
        {/*>*/}
        {/*  <Select*/}
        {/*    options={userSelectOptions}*/}
        {/*    optionFilterProp="label"*/}
        {/*    showSearch*/}
        {/*  ></Select>*/}
        {/*</Form.Item>*/}
      </Form>
    </UIModal>
  )
}
