import React, { useState, useRef, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Alert, Tabs, message, Divider } from "antd";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useRequest, useSelector } from "src/hook";
import {
  getTwoFactor,
  needOtpBind_api,
  getSpecialLoginType,
  getSnKeyInfo,
  applyUKey,
  getUkeyResult,
  IKeySeed,
  initUKey,
  getLogo,
  needOauthCertification,
  twoFactorType,
  twoStepSMSLoginAuth,
  twoStepSMSLogin,
  getVersionIsCommunity,
  twoStepSMSADLogin,
} from "src/api";

import {
  login,
  otpLogin,
  adLogin,
  OpenLdaptLogin,
  ukeyLogin,
  smsTwoStepLogin,
  commonLoginProcess,
  getWwLoginInfo,
  setWwLoginInfo,
  userWwLogin,
} from "./loginSlice";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import * as OriginQRCode from "src/assets/js/qrcode.js";
import { Iconfont, LoginCard } from "src/components";
import { noChineseValidator } from "src/util";
import { getBrowser } from "src/util/getBrowser";
import classnames from "classnames";
import styles from "./index.module.scss";
import { ErrorBoundary } from "src/components";
import { useForm } from "antd/lib/form/Form";
import { persistor } from "src/store";
import { TwoFactorSMSConfirmModal } from "./modal/TwoFactorSMSConfirmModal";
import LogoXd from "src/assets/img/logo_xd.png";
import { WwLoginQrCode } from "src/components/WwLoginQrCode";
import { WWLoginPanelSizeType } from "@wecom/jssdk";
import SMSVerificationButton from "src/components/SendMsgBtn";

export const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [adLoadingLogin, setAdLoadingLogin] = useState(false);
  const [userIdBySms, setUserIdBySms] = useState("");
  const [tabsActiveKey, setTabsActiveKey] = useState<string>("normal");
  const [isOtpOpen, setisOtpOpen] = useState(false);
  const [isSmsOpen, setIsSmsOpen] = useState(false);
  const [loginCheckType, setLoginCheckType] = useState("");
  const [needOTPInput, setNeedOTPInput] = useState(false);
  const [needSMSInput, setNeedSMSInput] = useState(false);
  const [isWwChatLogin, setIsWwChatLogin] = useState(false);
  const [startCountdown, setStartCountdown] = useState(false);

  const {
    enable: enableWwLogin,
    appId,
    agentId,
  } = useSelector((state) => state.login.wwLoginInfo) || {};

  /** ukey 相关 */
  // 设置加载字段
  let params = {
    encryptionId: 0,
    result: 0,
    password: "",
    userId: "",
  };
  const loadingError = (txt: string) => {
    setLoadingLogin(false);
    message.error(txt);
  };

  /** 获取特殊登录类型 */
  const { data } = useRequest(getSpecialLoginType);

  //获取version
  const { data: isCommunity, run: fetchVersion } = useRequest(
    getVersionIsCommunity,
    {
      manual: true,
    }
  );

  /*  0 用户密码输入 1 otp 校验码输入/二维码绑定 */
  const [loginType, setLoginType] = useState<
    "UserFirstLogin" | "OtpLogin" | "UserOtpLogin" | "UserSmsLogin" | "WwLogin"
  >("UserFirstLogin");

  /* 记录 userId */
  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getWwLoginInfo());
    fetchVersion();
  }, []);

  useEffect(() => {
    const flag =
      (isOtpOpen && loginType === "UserOtpLogin") ||
      (isOtpOpen && loginType === "UserFirstLogin");
    setNeedOTPInput(flag);
  }, [isOtpOpen, loginType]);

  useEffect(() => {
    const flag =
      (isSmsOpen && loginType === "UserSmsLogin") ||
      (isSmsOpen && loginType === "UserFirstLogin");
    setNeedSMSInput(flag);
  }, [isSmsOpen, loginType]);

  /** AD 登录 */
  const adLog = async (params: any) => {
    try {
      setAdLoadingLogin(true);
      dispatch(adLogin(params)).finally(() => {
        setLoadingLogin(false);
        setAdLoadingLogin(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  /** OpenLdapt登录 */
  const openLdaptLog = async (params: any) => {
    try {
      setAdLoadingLogin(true);
      dispatch(OpenLdaptLogin(params));
    } catch (e) {
      console.log(e);
    } finally {
      setAdLoadingLogin(false);
    }
  };
  function optCheck() {
    setLoginType("UserOtpLogin");
    // getOtpInputFocus()
    setLoadingLogin(false);
  }
  // function optCheck(values: any) {
  //   neeOtpBind(values)
  //     .then((res) => {
  //       const { requireBind } = res
  //       if (requireBind) {
  //         setLoginType('OtpLogin')
  //       }
  //       getOtpInputFocus()
  //     })
  //     .finally(() => setLoadingLogin(false))
  // }

  //oauth2.0 登录
  const oauth2Check = async () => {
    history.push("user/login/oauth2Login");
    // await needOauthCertification()
  };

  /**
   * @description: ukey 本地加密
   */
  const LockSeedPassword = (seed: IKeySeed) => {
    getUkeyResult(seed)
      .then((res: any) => {
        return res.json();
      })
      .then((json) => {
        if (json[0].ret) {
          params.result = json[0].lock;
          onUkeyLogin(params);
        } else {
          message.error("加密信息失败");
        }
      })
      .catch(() => {
        message.error("UKEY验证失败无法登录");
      });
  };

  /**
   * @description: 登录 获取用户信息
   */
  const onUkeyLogin = (values: any) => {
    dispatch(ukeyLogin(values)).finally(() => setLoadingLogin(false));
  };

  const ukeyCheckLogin = () => {
    initUKey()
      .then(() => {
        getSnKeyInfo()
          .then((res: any) => {
            return res.json();
          })
          .then((json) => {
            if (json[0].ret) {
              sendUkeySn(json[0].sn[0]);
            } else {
              loadingError("未检测到UKEY，您需要插入UKEY，才能登录");
            }
          });
      })
      .catch(() => {
        loadingError("未检测到UKEY服务，您需要先安装UKEY服务，才能登录");
      });
  };

  const { run: verifyCodeRun, loading: submitVerifyCodeLoading } = useRequest(
    twoStepSMSLoginAuth,
    {
      manual: true,
      onSuccess(userInfo) {
        // do something ...
        commonLoginProcess(userInfo, dispatch);
      },
      onError() {
        setLoadingLogin(false);
      },
    }
  );

  /**
   * @description: 获取uKey id 和 seed
   * @param {number} sn
   */
  const sendUkeySn = (sn: number) => {
    applyUKey(sn)
      .then((res: any) => {
        params.encryptionId = res.id;
        LockSeedPassword({ sn: sn, seed: res.seed });
      })
      .catch(() => {
        loadingError("获取ukey返回信息失败");
      });
  };

  const onFinish = async (values: any) => {
    params = { ...values };

    // 前后登陆不是同一用户需要清除持久化数据  --不需要清除了

    // if (userIdPersist !== values.userId) {
    // persistor.purge()
    // }

    const type = await twoFactorType({ userId: values?.userId });
    setisOtpOpen(type === "OTP");
    setIsSmsOpen(type === "MESSAGE");
    setLoginCheckType(type);

    const fullValues_cq = Object.assign({}, values, { loginType: "cq" });
    if (loadingLogin) return;
    setLoadingLogin(true);
    setUserId(values.userId);
    // ad登录
    if (tabsActiveKey === "ad") {
      if (!isOtpOpen) {
        adLog(values);
      } else {
        adLogWithOptOpen(values);
      }
      return;
    }
    switch (type) {
      case "OTP":
        // 如果otp开了, 检查一下otp是否需要绑定
        if (!values.otpPassword) {
          optCheck();
          // optCheck(fullValues_cq)
          return;
        }
        // otp登陆
        dispatch(otpLogin(fullValues_cq)).finally(() => setLoadingLogin(false));
        break;
      case "UKEY":
        ukeyCheckLogin();
        break;
      case "MESSAGE":
        // 将 userId 传给 modal，同时后续操作交由 modal 处理
        if (values.verifyCode) {
          await verifyCodeRun({ authCode: values.verifyCode, userId }).finally(
            () => setLoadingLogin(false)
          );
        } else {
          setUserIdBySms(values.userId);
          await dispatch(smsTwoStepLogin(values)).finally(() =>
            setLoadingLogin(false)
          );
        }
        setStartCountdown(true);
        break;
      default:
        dispatch(login(values)).finally(() => setLoadingLogin(false));
    }
  };

  // 当开启otp验证后的ad登录
  function adLogWithOptOpen(values: any) {
    if (loadingLogin) return;
    setLoadingLogin(true);
    setUserId(values.userId);
    const fullValues = Object.assign({}, values, { loginType: "ad" });
    if (isOtpOpen && !values.otpPassword) {
      // 添加loginType=cq/ad
      // optCheck(fullValues)
      optCheck();
      return;
    }
    dispatch(otpLogin(fullValues)).finally(() => setLoadingLogin(false));
  }

  const [form] = useForm();

  const handleSmsChange = (e: any) => {
    const value = e.target.value?.trim();
    form.setFieldsValue({ verifyCode: value });
  };

  const LoginFormItems = (
    <>
      <Form.Item
        name="userId"
        required={false}
        rules={[{ required: true, message: " 请输入用户名" }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="请输入用户名"
        />
      </Form.Item>
      <Form.Item
        className={styles.formItemPassword}
        name="password"
        required={false}
        rules={[{ required: true, validator: noChineseValidator }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="请输入密码"
        />
      </Form.Item>
      {needOTPInput && (
        <Form.Item
          name="otpPassword"
          required={false}
          rules={[{ required: true, message: " 请输入 OTP 验证" }]}
        >
          <Input
            id="otpInput"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="OTP 验证码"
          />
        </Form.Item>
      )}
      {needSMSInput && (
        <>
          <Form.Item
            name="verifyCode"
            required={false}
            rules={[
              { required: true, message: "请输入6位短信验证码验证", len: 6 },
            ]}
          >
            <div className="flexAlignCenter">
              <Input
                id="smsInput"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="短信 验证码"
                allowClear
                style={{ width: 198 }}
                onChange={handleSmsChange}
              />
              {/*  重新发送 */}
              <SMSVerificationButton
                style={{ marginLeft: 10, width: 110, height: 40 }}
                callback={async () => {
                  
                  const values = form.getFieldsValue();
                  const { userId, password } = values;
                  if (!userId || !password) {
                    message.error("请输入用户名和密码");
                    return false;
                  }
                  const params = {
                    userId,
                    password,
                  };
                  try {
                    if (tabsActiveKey === 'ad') {
                     await twoStepSMSADLogin({userId}) 
                    }else {
                      await twoStepSMSLogin(params);
                    }
                  
                    return true;
                  } catch (error) {
                    console.error("短信验证码发送失败", error);
                    return false;
                  }
                }}
                startCountdown={startCountdown}
              />
            </div>
          </Form.Item>
        </>
      )}
    </>
  );

  useEffect(() => {
    let tabsActiveKey = "normal";
    if(data?.adLogin) {
      tabsActiveKey = "ad";
    }else if(data?.casLogin) {
      tabsActiveKey = "cas";
    }else if(data?.oauthLogin) {
      tabsActiveKey = "oauth";
    }else if(data?.openLdapLogin) {
      tabsActiveKey = "ldap";
    }
    setTabsActiveKey(tabsActiveKey);
  }, [data]);

  const TabsLoginPage = (
    <Form name="login" onFinish={onFinish} layout="vertical" form={form}>
      <Tabs
        destroyInactiveTabPane
        activeKey={tabsActiveKey}
        onChange={(aKey) => {
          form.resetFields();
          setTabsActiveKey(aKey);
          setLoginType("UserFirstLogin");
          setisOtpOpen(false);
          setIsSmsOpen(false);
        }}
        className={styles.tabs}
      >
        <Tabs.TabPane key="normal" tab="CQ 登录">
          {LoginFormItems}
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className={styles.loginBtn}
              loading={loadingLogin}
            >
              登录
            </Button>
          </Form.Item>
          {loginCheckType === "UKEY" && (
            <Button type="link" href="/ZFDetectServer.zip">
              UKEY控件下载
              <DownloadOutlined />
            </Button>
          )}
        </Tabs.TabPane>
        {!!data && data.adLogin && (
          <Tabs.TabPane key="ad" tab="AD 登录">
            {LoginFormItems}
            <Form.Item>
              <Button
                className={styles.loginBtn}
                type="primary"
                size="large"
                htmlType="submit"
                loading={adLoadingLogin}
              >
                登录
              </Button>
            </Form.Item>
          </Tabs.TabPane>
        )}
        {!!data && data.openLdapLogin && (
          <Tabs.TabPane key="ldap" tab="OpenLdap 登录">
            {LoginFormItems}
            <Form.Item>
              <Button
                onClick={() => {
                  const params = form.getFieldsValue();
                  openLdaptLog(params);
                }}
                size="large"
                type="primary"
                className={styles.loginBtn}
              >
                登录
              </Button>
            </Form.Item>
          </Tabs.TabPane>
        )}
        {!!data && data.casLogin && (
          <Tabs.TabPane key="cas" tab="CAS 登录">
            {LoginFormItems}
            <Form.Item>
              <Button
                href="user/login/casLogin"
                className={classnames(styles.loginBtn, styles.lh36)}
                size="large"
                type="primary"
              >
                登录
              </Button>
            </Form.Item>
          </Tabs.TabPane>
        )}
        {!!data && data.oauthLogin && (
          <Tabs.TabPane key="oauth" tab="Oauth 登录">
            <Form.Item>
              <Button
                href="user/login/oauth2Login"
                className={classnames(styles.loginBtn, styles.lh36)}
                size="large"
                type="primary"
              >
                登录
              </Button>
            </Form.Item>
          </Tabs.TabPane>
        )}
      </Tabs>
    </Form>
  );

  const { type, versions } = getBrowser();

  const needNotification = type === "Chrome" && versions && versions >= 80;

  const notification = !needNotification ? (
    <Alert
      showIcon
      type="warning"
      message="当前浏览器不支持，建议使用Chrome 浏览器 80.0以上版本！"
      closable
      style={{ zIndex: 9 }}
    />
  ) : undefined;

  const { data: logoUrl } = useRequest(getLogo, {
    formatResult: (res: string) => {
      return res ? "/user" + res : LogoXd;
    },
  });

  const onWwLoginSuccess = (code: string) => {
    dispatch(userWwLogin(code));
  };

  const renderWwChatLogin = () => {
    const config = {
      appid: appId as string,
      agentid: agentId as string,
      redirect_uri: window.location.origin,
      state: "loginState",
      panel_size: "small" as WWLoginPanelSizeType,
    };

    return (
      <>
        {isWwChatLogin ? (
          <div className={styles.wWLoginQrCodeBox}>
            <ArrowLeftOutlined
              onClick={() => setIsWwChatLogin(false)}
              className={styles.wWLoginIcon}
            />
            <WwLoginQrCode
              elementIdName="cloud_query_ww_login"
              loginConfig={config}
              onLoginSuccess={onWwLoginSuccess}
            />
          </div>
        ) : (
          <div className={styles.wWLoginQrCode}>
            <Divider style={{ color: "#aaa" }}>或</Divider>
            <Iconfont
              onClick={() => setIsWwChatLogin(true)}
              type="icon-qiyeweixin"
              style={{ fontSize: "32px" }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <ErrorBoundary>
      <LoginCard notification={notification}>
        <div className={styles.loginHeader}>
          <img width={176} alt="logo" src={logoUrl} />
          <div className={isWwChatLogin ? styles.wwLoginTitle : styles.title}>
            {/* 欢迎使用 <span className={styles.txt}>CloudQuery</span> */}
          </div>
        </div>
        {!isWwChatLogin && TabsLoginPage}
        {enableWwLogin && renderWwChatLogin()}
        {isCommunity?.version === "community" && (
          <div> CloudQuery询盾 ©2024杭州图尔兹信息技术有限公司版权所有</div>
        )}
      </LoginCard>
      <TwoFactorSMSConfirmModal
        userId={userIdBySms}
        onDone={() => {
          setUserIdBySms("");
          setLoadingLogin(false);
        }}
      />
    </ErrorBoundary>
  );
};
