export const PERM_TYPES = 'PERM_TYPES'
export const PERM_TYPES_WITHOUT_PERMSET = 'PERM_TYPES_WITHOUT_PERMSET'
export const BATCH_DELETE = 'BATCH_DELETE'

const permTypes = ['dataSource', 'permissionSet', 'system'] as const

export type PermType = typeof permTypes[number]

export const permTypesLabelMap: { [key in PermType]: string } = {
  dataSource: '数据源操作权限',
  permissionSet: '权限集',
  system: '系统权限',
}

export const NORMAL_DATABASE_PERMISSIONS:{[k: string]: string[]}  = {
 table:  ['SELECT_TABLE','UPDATE_TABLE','INSERT_TABLE','DELETE_TABLE'],
 tableGroup: ['CREATE_TABLE', 'ALTER_TABLE','DROP_TABLE','TRUNCATE','SELECT_TABLE','UPDATE_TABLE','DELETE_TABLE', 'INSERT_TABLE'],
 view: ['SELECT_VIEW','UPDATE_VIEW','DELETE_VIEW','INSERT_VIEW'],
 viewGroup: ['SELECT_VIEW','CREATE_VIEW','ALTER_VIEW','DROP_VIEW','UPDATE_VIEW','DELETE_VIEW','INSERT_VIEW'],
 foreignTable: ['SELECT_FOREIGN_TABLE','UPDATE_FOREIGN_TABLE','DELETE_FOREIGN_TABLE','INSERT_FOREIGN_TABLE'],
 foreignTableGroup: ['SELECT_FOREIGN_TABLE','UPDATE_FOREIGN_TABLE','DELETE_FOREIGN_TABLE','INSERT_FOREIGN_TABLE','CREATE_FOREIGN_TABLE','ALTER_FOREIGN_TABLE','DROP_FOREIGN_TABLE'],
 materializedView: ['SELECT_MATERIALIZED_VIEW'],
 materializedViewGroup: ['CREATE_MATERIALIZED_VIEW','ALTER_MATERIALIZED_VIEW','DROP_MATERIALIZED_VIEW','SELECT_MATERIALIZED_VIEW'],
 function: ['EXECUTE_FUNCTION'],
 functionGroup: ['EXECUTE_FUNCTION','CREATE_FUNCTION', 'ALTER_FUNCTION','DROP_FUNCTION'],
 procedure: ['EXECUTE_PROCEDURE'],
 storedProcedureGroup: ['EXECUTE_PROCEDURE','CREATE_PROCEDURE','ALTER_PROCEDURE','DROP_PROCEDURE'],
 synonym: ['SYNONYM_OPERATE'],
 synonymsGroup: ['SYNONYM_OPERATE', 'CREATE_SYNONYM','ALTER_SYNONYM','DROP_SYNONYM'],
 sequence: ['CQ_OPEN_SEQUENCE'],
 sequenceGroup: ['CQ_OPEN_SEQUENCE','CREATE_SEQUENCE', 'ALTER_SEQUENCE','DROP_SEQUENCE'],
 trigger: ['CQ_OPEN_TRIGGER'],
 triggerGroup: ['ALTER_TRIGGER','CREATE_TRIGGER','DROP_TRIGGER', 'CQ_OPEN_TRIGGER'],
 dbLink: ['DBLINK_OPERATE'],
 dbLinkGroup: ['CREATE_DATABASE_LINK','ALTER_DATABASE_LINK','DROP_DATABASE_LINK','DBLINK_OPERATE'],
 package: ['EXECUTE_PACKAGE'],
 packageGroup: ['EXECUTE_PACKAGE','CREATE_PACKAGE','ALTER_PACKAGE','DROP_PACKAGE'],
 packageBody: ['EXECUTE_PACKAGE'],
 packageBodyGroup:  ['EXECUTE_PACKAGE','CREATE_PACKAGE','ALTER_PACKAGE','DROP_PACKAGE']
}

export const SQL_SERVER_PERMISSION = {
  ...NORMAL_DATABASE_PERMISSIONS,
  synonymsGroup: ['SYNONYM_OPERATE', 'CREATE_SYNONYM','DROP_SYNONYM'],
  dbLinkGroup: ['DBLINK_OPERATE'],
}
export const MONGODB_PERMISSIONS = {
  collection: ['INSERT_COLLECTION','DELETE_COLLECTION','UPDATE_COLLECTION','FIND_COLLECTION','BULK_WRITE_COLLECTION'],
  collectionGroup: ['CREATE_COLLECTION','DROP_COLLECTION'],
  view: ['FIND_VIEW'],
  viewGroup: ['CREATE_VIEW','FIND_VIEW','DROP_VIEW','RENAME_VIEW']
}

export const REDIS_PERMISSIONS = {
  redisKey: ['OPEN_KEY'],
  keyGroup:['OPEN_KEY'],
  file: ['OPEN_KEY'],
  fileGroup: ['OPEN_KEY']
}
export const HIVE_PERMISSION = {
  ...NORMAL_DATABASE_PERMISSIONS,
  view: ['SELECT_VIEW'],
  viewGroup: ['SELECT_VIEW','CREATE_VIEW','ALTER_VIEW','DROP_VIEW']
}
export const VERTICA_PERMISSION = {
    ...NORMAL_DATABASE_PERMISSIONS,
    procedure: [],
    storedProcedureGroup: ['CREATE_PROCEDURE','DROP_PROCEDURE'],
}
export const PHOENIX_PERMISSION = {
  table:  ['SELECT_TABLE', 'UPSERT_TABLE', 'DELETE_TABLE'],
  tableGroup: ['CREATE_TABLE', 'ALTER_TABLE', 'DROP_TABLE', 'SELECT_TABLE', 'UPSERT_TABLE', 'DELETE_TABLE'],
  view: ['SELECT_VIEW'],
  viewGroup: ['SELECT_VIEW', 'CREATE_VIEW', 'ALTER_VIEW', 'DROP_VIEW'],
  function: ['EXECUTE_FUNCTION'],
  functionGroup: ['CREATE_FUNCTION', 'DROP_FUNCTION'],
}
export const TRINO_PERMISSION = {
  ...NORMAL_DATABASE_PERMISSIONS,
  table:  ['SELECT_TABLE','UPDATE_TABLE','INSERT_TABLE','DELETE_TABLE'],
  tableGroup: ['CREATE_TABLE', 'ALTER_TABLE','DROP_TABLE','TRUNCATE_TABLE','SELECT_TABLE','UPDATE_TABLE','DELETE_TABLE', 'INSERT_TABLE'],
  view: ['SELECT_VIEW'],
  viewGroup: ['SELECT_VIEW','CREATE_VIEW','ALTER_VIEW','DROP_VIEW'],
}

export const STARROCKS_PERMISSION = {
  ...NORMAL_DATABASE_PERMISSIONS,
  foreignTableGroup: ['SELECT_FOREIGN_TABLE','UPDATE_FOREIGN_TABLE','DELETE_FOREIGN_TABLE','INSERT_FOREIGN_TABLE','CREATE_FOREIGN_TABLE','DROP_FOREIGN_TABLE'],
  functionGroup: ['EXECUTE_FUNCTION','CREATE_FUNCTION','DROP_FUNCTION'],
  view: ['SELECT_VIEW'],
  viewGroup: ['SELECT_VIEW','CREATE_VIEW','ALTER_VIEW','DROP_VIEW'],
  syncMaterializedView: ['SELECT_SYNC_MATERIALIZED_VIEW'],
  syncMaterializedViewGroup:['SELECT_SYNC_MATERIALIZED_VIEW','CREATE_MATERIALIZED_VIEW','DROP_MATERIALIZED_VIEW'],
  asyncMaterializedView:['SELECT_ASYNC_MATERIALIZED_VIEW'],
  asyncMaterializedViewGroup: ['SELECT_ASYNC_MATERIALIZED_VIEW','ALTER_MATERIALIZED_VIEW','CREATE_MATERIALIZED_VIEW','DROP_MATERIALIZED_VIEW'],
  routineLoadGroup: ['CREATE_ROUTINE_LOAD', 'ALTER_ROUTINE_LOAD']
}

export const HANA_PERMISSION = {
  ...NORMAL_DATABASE_PERMISSIONS,
  synonymsGroup: ['SYNONYM_OPERATE', 'CREATE_SYNONYM','DROP_SYNONYM'],
  triggerGroup: ['CREATE_TRIGGER','DROP_TRIGGER', 'CQ_OPEN_TRIGGER'],
}
export const INCEPTOR_PERMISSION = {
  ...NORMAL_DATABASE_PERMISSIONS,
  view: ['SELECT_VIEW'],
  viewGroup: ['SELECT_VIEW','CREATE_VIEW','ALTER_VIEW','DROP_VIEW'],
  packageGroup: ['EXECUTE_PACKAGE','CREATE_PACKAGE','DROP_PACKAGE'],
  packageBodyGroup:  ['EXECUTE_PACKAGE','CREATE_PACKAGE','DROP_PACKAGE'],
  dbLinkGroup: ['CREATE_DATABASE_LINK','DROP_DATABASE_LINK','DBLINK_OPERATE'],
}

export const getSpecialConnectionTypePermissions: any = (connectionType: string) => {

  switch(connectionType) {
    case 'Redis':
      return REDIS_PERMISSIONS;
    case 'MongoDB':
      return MONGODB_PERMISSIONS;
    case 'SQLServer':
      return SQL_SERVER_PERMISSION;
    case 'Hive':
        return HIVE_PERMISSION;
    case 'Vertica':
        return VERTICA_PERMISSION;
    case 'Phoenix':
       return PHOENIX_PERMISSION;
    case 'Trino':
      return TRINO_PERMISSION;
    case 'StarRocks':
      return STARROCKS_PERMISSION;
    case 'HANA':
      return HANA_PERMISSION;
    case 'Inceptor':
      return INCEPTOR_PERMISSION;
    default:
        return NORMAL_DATABASE_PERMISSIONS;
  }
}
//特殊nodeType 需要另外添加
export const actionIconNodeTypes = Object.keys({
  ...NORMAL_DATABASE_PERMISSIONS,
  ...MONGODB_PERMISSIONS,
  ...REDIS_PERMISSIONS,
  ...STARROCKS_PERMISSION
}).map(type => type);

// 权限类型
export const PERMISSION_TYPE = [
  {
    key: 'datasource',
    value: '数据源操作权限'
  },
  {
    key: 'desensitizedResource',
    value: '敏感资源访问权限'
  },
  {
    key: 'highRiskOperation',
    value: '高危操作权限'
  },
  {
    key: 'permissionTool',
    value: '工具权限'
  }
]

// 资源类型
export const NODE_TYPE = ['schema', 'table', 'view', 'function', 'procedure', 'column', 'connection', 'other']
