import { EXECUTE_TYPES } from 'src/constants'
import { fetchGet, fetchPost, fetchPostFormData } from './customFetch'

export interface IApplyProps {
  type?: number;
  status: string;
  pageSize: number;
  currentPage: number;
  approveUserId?: string;
  keyword?: string;
}

export type ApplyItem = {
  id?: number;
  flowId?: number;
  applyId?: string
  type?: number;
  dataChangeType: number;
  datasourceType?: string;
  flowTaskId?: string;
  flowInstanceId?: string;
  nodePath: any;
  title: string;
  sqlType: number;
  sqlStatement: string;
  executeTimeType: number;
  executeTime?: string;
  status?: EXECUTE_TYPES;
  executeStatus?: EXECUTE_TYPES;
  approveUserId?: string;
  skip2Step?: boolean;
  flowUUID?: string
  rollbackSqlStatement?: string
  [p: string]: any
}

export interface IApplyResData {
  total: number;
  content: ApplyItem[];
}

//我申请的
export const getMyApplyList = (params: IApplyProps): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/myApplyList`, { ...params })
}

//我审批的
export const getMyApproveList = (params: IApplyProps): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/myApproveList`, { ...params })
}

//所有任务
export const getAllApproveList = (params: IApplyProps): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/allApproveList`, { ...params })
}

//发起申请
export const applyDataChange = (params: ApplyItem): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/applyDataChange`, { ...params })
}

//删除草稿
export const deleteDraft = (id: number): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/${id}/delete`)
}


//执行
export const executeApplication = (id: number): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/${id}/execute`)
}

//中止执行
export const terminateApplication = (id: number): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/${id}/terminate`)
}

export interface IApplicationAmendParams {
  id: number;
  approveUserComment: string;
  executeTimeType: number;
  executeTime: string;
}
//中止执行
export const applicationAmend = (params: IApplicationAmendParams): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/amend`, params)
}


//终止执行
export const closeApplication = (id: number): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/${id}/close`)
}

//暂存草稿
export const saveAsDraft = (params: ApplyItem): Promise<IApplyResData> => {
  return fetchPost(`/api/flow/dataChange/saveAsDraft`, { ...params })
}

//数据变更详情
export const getDataChangeDetail = (id: string | number): Promise<IApplyResData> => {
  return fetchGet(`/api/flow/dataChange/${id}`)
}

export interface ISqlSplitProps {
  type: number;
  statement: string;
  pageSize: number,
  currentPage: number
}

export interface ISqpSplitRes {
  total: number;
  statements: string[];
}
//sql检查
export const getSqlSplitStatement = (params: ISqlSplitProps): Promise<ISqpSplitRes> => {
  return fetchPost(`/api/flow/dataChange/splitStatement`, { ...params })
}

// sql审核
interface SqlCheckIParams {
  connectionId: string,
  dataSourceType: string,
  statements: any[],
}
export const getSqlCheck = (params: SqlCheckIParams) => {
  return fetchPost(`/dms/segment/statement/sqlCheck`, params)
}

export interface IExecutePlanParams {
  sqlStatement?: string;
  nodePath?: string;
}

export interface IExecutePlanRes {
  columnNames: string[];
  content?: string[];
}

export type IDataParamType = {
  type?: string,
  overallTransaction: boolean;
  dataBackup: boolean;
  backupLocation?: string;
}

//获取执行计划
export const getExecutePlan = (params: IExecutePlanParams): Promise<IExecutePlanRes> => {
  return fetchPost(`/dms/statement/explainSingle`, params)
}

export const uploadDataChangeFileChunks = (params: any) => {
  return fetchPostFormData(`/export/uploadDataChangeSql`, params)
}

//下载日志
export const downloadLog = (id: number): Promise<any> => {
  return fetchGet(`/api/flow/dataChange/${id}/dataChangeLog`)
}

// 模拟执行
export interface SimulationExecuteI {
  nodePath: string;
  sqlType: number;
  sqlContent: string;
  dataSourceType: string;
  sqlOwnerId: string;
  dataChangeId?: number;
  simulateType: number;
}
export const simulationExecute = (params: SimulationExecuteI) => {
  return fetchPost(`/api/flow/dataChange/simulate`, params);
};

// 轮询执行结果
export const pollingExecuteResult = (simualteKey: string) => {
  return fetchGet(`/dms/segment/statement/simulateLog/${simualteKey}`);
};

//执行回退
export const executeBack = (id: number): Promise<{ id: number }> => {
  return fetchPost(`/api/flow/dataChange/${id}/executeBack`)
}
//执行sql日志
export const executeSqlLog = (id: number): Promise<any> => {
  return fetchGet(`/api/flow/dataChange/${id}/simulateExecuteLog`)
}
//回退sql日志
export const rollbackSqlLog = (id: number): Promise<any> => {
  return fetchGet(`/api/flow/dataChange/${id}/simulateRollbackLog`)
}

//获取执行计划
export const uploadDataChangeSql = (params: IExecutePlanParams): Promise<IExecutePlanRes> => {
  return fetchPost(`/api/flow/dataChange/uploadDataChangeSql`, params)
}

// 设置数据变更参数
export const dataChangeParam = (params: IDataParamType): Promise<any> => {
  return fetchPost(`/api/flow/dataChange/param`, params)
}

//获取数据变更参数
export const getDataChangeParam = (type: string): Promise<IDataParamType> => {
  return fetchGet(`/api/flow/dataChange/param/${type}`)
}