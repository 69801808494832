import React from 'react'
import { Col, Row } from 'antd'
import { UserSqlLogs } from './UserSqlLogs'
import { CommonlyUsedConnections } from './CommonlyUsedConnections'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { SimpleBreadcrumbs } from 'src/components'
const breadcrumbData = [
  { title: "个人中心" },
  {
    title: "执行历史",
  },
];
export const SqlLogPage = () => {
  return (
    <div className="page-content" style={{padding: '0 10px'}}>
      <div style={{padding: '0px 10px'}}>
      <SimpleBreadcrumbs items={breadcrumbData} />
      </div>
      <Row gutter={[24, 24]}>
        <Col span={17}>
          <ErrorBoundary>
            <UserSqlLogs />
          </ErrorBoundary>
        </Col>
        <Col span={7}>
          <ErrorBoundary>
            <CommonlyUsedConnections />
          </ErrorBoundary>
        </Col>
      </Row>
    </div>
  )
}
