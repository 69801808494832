import React from "react";
import { Dropdown, Tooltip, message } from "antd";
import { Link } from "react-router-dom";
import type { EXECUTE_TYPES } from "src/constants";
import { Iconfont, renderWithOneDatabaseNodeIcon } from "src/components";
import { DATA_REQUEST_APPLY_TYPES, APPLY_COLUMN_FILTER } from "src/constants";
import { renderMenuList, getColumnStatusWithIcon } from "../common";
import { generateObjectName } from "src/util";
import { executeBack, getUrgeReminders } from "src/api";

const STATUS_MAPPING = {
  待审核: {
    草稿: [{ label: "删除草稿" }, { label: "编辑", type: "edit" }],
    审批中: [{ label: "撤回申请" }, { label: "催一下" }],
  },
  已审核: {
    已驳回: [{ label: "再次申请" }],
    待执行: [{ label: "执行" }, { label: "中止任务" }],
    执行中: [{ label: "中止执行" }],
    执行成功: [{ label: "终止任务" }, { label: "执行回退" }], //返回已完成
    执行失败: [{ label: "终止任务" }], //返回已完成
    回退成功: [{ label: "终止任务" }], //返回已完成
    回退失败: [{ label: "终止任务" }], //返回已完成
    被中止: [{ label: "终止任务" }, { label: "再次申请" }]
  },
};
const getActions = (status: string, executeStatus: any,executeTime: string) => {
  switch (status) {
    case "待审核":
    case "已审核":
      //@ts-ignore
      let normalBtns = STATUS_MAPPING[status]?.[executeStatus] ?? [{ label: "查看详情" }];
      //手动执行不需要 执行按钮
      if (executeTime) {
        normalBtns = normalBtns?.filter((item: any) => item.label !== '执行' ) 
      }
      return normalBtns
    case "已撤回":
      return [{ label: "再次申请" }]; 
    default:
      return [{ label: "查看详情", key: "view" }]; 
  }
};

export const columns = ({
  status,
  history,
  databaseList,
  onDeleteDraft,
  onReApplyDraft,
  onWithDrawApplication,
  onExecuteApplication,
  onTerminateApplication,
  onCloseApplication,
  onSearch,
}: {
  status: string;
  history: any;
  databaseList: any[];
  onDeleteDraft: (params: any) => void;
  onReApplyDraft: (params: any) => void;
  onWithDrawApplication: (id: number) => void;
  onExecuteApplication: (id: number) => void;
  onTerminateApplication: (id: number) => void;
  onCloseApplication: (id: number) => void;
  onSearch: (params: any) => void;
}) => [
  {
    title: "编号",
    dataIndex: "id",
    fixed: "left",
    ellipsis: true,
    render: (val: string, record: any) =>
      record?.status !== "草稿" ? (
        <Tooltip title={record?.flowUUID}>
           <Link to={`/data_change_mine_apply/${val}/detail`}>
             {record?.flowUUID}
           </Link>
        </Tooltip>
      ) : (
        "--"
      ),
  },
  {
    title: "申请人",
    dataIndex: "applyUserName",
    ellipsis: true,
    render: (val: string, record: any) => (
      <Tooltip title={`${val ?? ''}（${record?.applyUserId})`}>
        {`${val ?? ''}（${record?.applyUserId})`}{" "}
      </Tooltip>
    ),
  },
  {
    title: "类型",
    dataIndex: "type",
    filterMultiple: false,
    filters: Object.keys(DATA_REQUEST_APPLY_TYPES).map((key) => ({
      //@ts-ignore
      text: DATA_REQUEST_APPLY_TYPES[key],
      value: Number(key),
    })),
    render: (val: number) => DATA_REQUEST_APPLY_TYPES[val],
  },
  {
    title: "标题",
    dataIndex: "title",
  },
  {
    title: "发起时间",
    dataIndex: "applyTime",
    sorter: true,
    render: (val: number) => val ?? "-",
  },
  {
    title: "当前状态",
    dataIndex: "executeStatus", //方便筛选使用，参数冲突
    filterMultiple: false,
    filters: status === '已撤回' ?
      false
      :
      APPLY_COLUMN_FILTER.map((key) => ({
        //@ts-ignore
        text: key,
        value: key,
      })),
    render: (val: EXECUTE_TYPES, record: any) =>
      getColumnStatusWithIcon(record?.status),
  },
  {
    title: "数据库",
    dataIndex: "nodePath",
    ellipsis: true,
    filterMultiple: false,
    filters: databaseList.map((item) => ({
      //@ts-ignore
      text: item?.dataSourceName,
      value: item?.dataSourceName,
    })),
    render: (nodePath: string, record: any) => {
      return (
        <>
          <Tooltip title={record?.dataSourceType}>
            <Iconfont
              type={`icon-${record?.dataSourceType ?? "database"}`}
              style={{ fontSize: "16px", marginRight: 4 }}
            />
          </Tooltip>
          <Tooltip
            title={renderWithOneDatabaseNodeIcon(
              nodePath,
              record?.connectionName,
              record?.dataSourceType
            )}
          >
            {record?.connectionName}.{generateObjectName(nodePath)}
          </Tooltip>
        </>
      );
    },
  },
  {
    title: "当前审批人",
    dataIndex: "approveUserName",
    ellipsis: true,
    render: (val: string) =>
      val ? (
        <Tooltip title={val}>
          {val}
        </Tooltip>
      ) : (
        "-"
      ),
  },
  {
    title: "执行时间",
    dataIndex: "executeTime",
    sorter: true,
    render: (val: number) => val ?? "-",
  },
  {
    title: "操作",
    dataIndex: "actions",
    align: "center",
    fixed: "right",
    render: (_: any, record: any) => (
      <Dropdown
        overlay={renderMenuList({
          menus: getActions(status, record?.status, record?.executeTime),
          onChange: (key) => {
            switch (key) {
              case "查看详情":
                history.push({
                  pathname: `/data_change_mine_apply/${record.id}/detail`,
                  state: { applyUserId: record?.applyUserId },
                });
                break;
              case "编辑":
                history.push({
                  pathname: `/data_change_mine_apply/${record.id}/edit`,
                  state: { applyUserId: record?.applyUserId },
                });
                break;
              case "删除草稿":
                onDeleteDraft(record.id);
                break;
              case "再次申请":
                history.push({
                  pathname: `/data_change_mine_apply/${record.id}/edit`,
                  state: { reapply: true, applyUserId: record?.applyUserId },
                });
                break;
              case "申请":
                onReApplyDraft(record);
                break;
              case "撤回申请":
                record?.flowId && onWithDrawApplication(record?.flowId);
                break;
              case "执行":
                onExecuteApplication(record.id);
                break;
              case "中止任务":
                onTerminateApplication(record.id);
                break;
              case "中止执行":
                onCloseApplication(record.id);
                break;
              case "终止任务":
                onCloseApplication(record.id);
                break;
              case "催一下":
                getUrgeReminders({
                  flowId: Number(record?.flowId),
                  senderIds: record?.approveUserId,
                }).then(() => {
                  message.success('催办成功');
                });
                break;
              case "执行回退":
                executeBack(record.id);
                break;
              default:
                break;
            }
          },
        })}
      >
        <div style={{ cursor: "pointer" }}>...</div>
      </Dropdown>
    ),
  },
];
