import { setEncrypt } from 'src/util/encryptRsaSm2'
import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'
import { getTransmissionPublicKey } from './login'

/* 获取otp绑定和开启状态 */
export const getOtpStatus1 = () => {
  return fetchPost(`/user/users/getOtpStatus`)
}

/* 获取邮箱状态 */
export const getEmailStatus = () => {
  return fetchPost(`/user/users/getEmailStatus`)
}

/* 打开用户otpkey */
export const openUserOTPKey = (userId: string | number) => {
  return fetchGet(`/user/users/openUserOTPKey/${userId}?userId=${userId}`)
}

/* 关闭用户otpKey */
export const closeUserOTPKey = (userId: string | number) => {
  return fetchGet(`/user/users/closeUserOTPKey/${userId}?userId=${userId}`)
}

/* otp绑定前账号密码验证 */
export const otpCheckAccount = async (params: any) => {
  /** SM2|RSA 加密 */
  const getKey = await getTransmissionPublicKey()
  params.password  = setEncrypt({algorithm: getKey?.algorithm, publicKey: getKey?.publicKey, password: params.password})
  return fetchPost(`/user/users/otpCheck`, params)
}

/* 发送电子邮件验证码 */
export const sendEmailVerificationCode = () => {
  return fetchGet(`/user/login/sendEmailVerificationCode`)
}

/* 重新发送邮件验证码 */
export const resendEmailVerificationCode = (userId: string | number) => {
  return fetchPost(`/user/login/resend/code?userId=${userId}`)
}

/* 检查验证码 */
export const examineCode = ({
  code,
  userId,
}: {
  code: string
  userId: string
}) => {
  return fetchPost(`/user/login/emailcode/${code}`, userId)
}

/* 绑定otp */
export const bindUserOTPKey = (params: {
  otpPassword: string
  userId: string
}) => {
  return fetchPost(`/user/users/bindUserOTPKey`, params)
}

/* 登录otp */
export const twoFactorType = (params: {
  userId: string | undefined
}) => {
  return fetchPost(`/user/login/twoFactorType`, params)
}

/* 获取登录类型 */
export const getCurrentLoginType = () => {
  return fetchGet(`/user/users/getCurrentLoginType`)
}

/* 获取email */
export const getUserEmail = () => {
  return fetchGet(`/user/users/getUserEmail`)
}

/* 绑定邮箱 发送电子邮件验证码 */
export const sendCodeBindNewEmail = (email: string) => {
  return fetchGet(`/user/login/sendCodeBindNewEmail/${email}`)
}

/** 更新邮箱 */
export const updateUserEmail = (params: {
  email: string
}) => {
  return fetchPost(`/user/users/updateUserEmail`, params)
}

/** otp验证码校验 */
export const verifyUserOTPKeyCode = (params: {
  otpPassword?: string
  password?: string
  userId: string
}) => {
  return fetchPost(`/user/users/checkUserOTPCode`, params)
}

/* otp码解绑 */
export const untieUserOTPKey = () => {
  return fetchPost(`/user/users/untieUserOTPKey`)
}


/* 获取 手机短信设置 状态 */
export const getPhoneSmsStatus = () => {
  return fetchGet(`/user/sys/sms`)
}
/* 绑定手机号 发送验证码 */
export const sendPhoneCode = (phone: string) => {
  return fetchGet(`/user/users/check/${phone}`)
}

/* 检测手机号是否绑定 */
export const getBindPhoneStatus = () => {
  return fetchGet(`/user/users/bind/phone`)
}

/* 发送手机号验证码 接口待确定 */
export const sendPhoneVerificationCode = () => {
  return fetchGet(`/user/login/send/`)
}

/** 更新手机 */
export const updateUserPhone = (phone: string, code: string) => {
  return fetchPut(`/user/users/modify/${phone}/${code}`)
}
/** 校验验证码 */
export const checkUserPhoneCode = (params: {
  phone: string
  code: string
}) => {
  return fetchPost(`/user/login/check`, params)
}

/** 开启短信登录设置 */
export const setOpenSms = (userId: string) => {
  return fetchGet(`/user/users/openSms/${userId}`)
}

/** 开opt登录设置 */
export const setOpenUserOTPKey = (userId: string) => {
  return fetchGet(`/user/users/openUserOTPKey/${userId}`)
}

/** 开启关闭登录设置 */
export const setClose = (userId: string) => {
  return fetchGet(`/user/users/close/${userId}`)
}

/** 查看消息开启状态 */
export const getSmsStatus = () => {
  return fetchGet(`/user/users/getSmsStatus`)
}

/** 查看OTP开启状态 */
export const getOtpStatus = () => {
  return fetchGet(`/user/users/getOtpStatus`)
}

/** 查看登录设置能否编辑 */
export const canEditLoginOption = () => {
  return fetchGet(`/user/users/canEditLoginOption`)
}

/** 是否能修改手机号 */
export const canEditPhone = () => {
  return fetchGet(`/user/users/canEditPhone`)
}

/**
 * 解绑手机号
 */
interface IProps {
  type: string
  password?: string
  phoneCode?: string
  optCode?: string
}
export const unBindPhone = (params: IProps) => {
  return fetchPost(`/user/users/unbindPhone`, params)
}