import React, { useEffect, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Form, Input, message, Popover } from 'antd'
import { LinkButton, UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { changeUserPassword, getSysPasswordPolicy } from 'src/api'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { passwordSpecialCharacterValidator, passwordValidator, passwordValidatorSelf } from 'src/util/nameValidator'
import { userLogout } from 'src/api'
import { persistor } from 'src/store'

export const ModalChangePersonalPassword = () => {
  const dispatch = useDispatch()
  const visible = useSelector(
    (state) => state.modal.ModalChangePersonalPassword,
  )
  const { userId } = useSelector((state) => state.login.userInfo)
  const [form] = Form.useForm()
  const [passwordPolicyData, setPasswordPolicyData] = useState<any>()

  // 获取密码策略
  const { data, run: getSysPasswordPolicyRun } = useRequest(getSysPasswordPolicy, { 
    manual: true,
    onSuccess: (res) => {
      setPasswordPolicyData(res)
    }
  },)

  const { loading, run } = useRequest(changeUserPassword, {
    manual: true,
    onSuccess: () => {
      message.success('修改成功')
      dispatch(hideModal('ModalChangePersonalPassword'))
      userLogout().then(async () => {
        // https://github.com/rt2zz/redux-persist/issues/1015#issuecomment-494492949
        persistor.purge()
      })
    },
  })

  useEffect(() => {
    if (!userId) return
    getSysPasswordPolicyRun()
    form.setFields([{ name: 'userId', value: userId }])
  }, [form, userId])

  const customValidator = (rule: any, value: string, callback: any) => {
    passwordValidatorSelf(rule, value, callback,
      {
        passwordMax : passwordPolicyData.passwordMax ? passwordPolicyData.passwordMax : 16, 
        passwordMin : passwordPolicyData.passwordMin ? passwordPolicyData.passwordMin : 9, 
        containDigits : passwordPolicyData.containDigits, 
        containUpperLetters : passwordPolicyData.containUpperLetters, 
        containLowerLetters : passwordPolicyData.containLowerLetters, 
        containSymbols : passwordPolicyData.containSymbols, 
      })
  }

  return (
    <UIModal
      title="修改密码"
      visible={visible}
      onOk={() => {
        form.validateFields().then(({ userId, oldP, newP }) => {
          run({ userId, oldP, newP })
        })
      }}
      // todo: 修改密码后端返回成功失败而非成功后 401
      // todo: 重新登录交互
      okText="确定并重新登录"
      onCancel={() => {
        dispatch(hideModal('ModalChangePersonalPassword'))
        form.resetFields(['oldP', 'newP', 'confirm'])
      }}
      confirmLoading={loading}
    // width={480}
    >
      {
        passwordPolicyData && 
        <Form form={form} {...FormLayout}>
          <Form.Item label="登录账号">
            <Form.Item name="userId" noStyle>
              <Input disabled hidden />
            </Form.Item>
            {userId}
          </Form.Item>
          <Form.Item
            label="原密码"
            name="oldP"
            rules={[{ required: true, message: '请输入原密码' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="newP"
            dependencies={['oldP']}
            required
            rules={[
              {
                validator: passwordPolicyData?.systemPasswordStrong ? 
                passwordValidator : 
                (_rule, value, callback) => customValidator(_rule, value, callback),
              },
              { validator: passwordSpecialCharacterValidator },
              ({ getFieldValue }) => ({
                validator(_rule, value) {
                  if (!value || getFieldValue('oldP') !== value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('新旧密码不能一致')
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="确认新密码"
            name="confirm"
            dependencies={['newP']}
            rules={[
              { required: true, message: '请确认新密码' },
              ({ getFieldValue }) => ({
                validator(_rule, value) {
                  if (!value || getFieldValue('newP') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('两次输入的新密码不一致')
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Popover content={'请联系系统管理员重置密码'} trigger="click">
            <LinkButton style={{ marginLeft: '32%' }}>忘记原密码？</LinkButton>
          </Popover>
        </Form>
      }
    </UIModal>
  )
}
