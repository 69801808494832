import fetchJsonp from 'fetch-jsonp'

import {
  fetchWithStatusHandler,
  formFetchWithStatusHandler,
  readResponseAsJSON,
  requestInitJSON,
  requestInitFormData,
  downloadResponse as handleDownload,
} from './_util'

export const fetchWithErrorHandler = fetchWithStatusHandler
export const downloadResponse = handleDownload

export const fetchGet = async (
  url: string,
  params?: string[][] | Record<string, any> | string | URLSearchParams,
) => {
  const requestUrl = params ? url + '?' + new URLSearchParams(params) : url
  const response = await fetchWithStatusHandler(requestUrl)

  const contextType=response.headers.get('Content-Type');

  if (contextType ==='text/html;charset=UTF-8') {
    const html = await response.text();

    return {html}
  }
  const data = await readResponseAsJSON(response)
  return data
}

export const fetchPost = async (
  input: RequestInfo,
  params?: object | string,
) => {
  const method = 'POST'
  const { headers, body } = requestInitJSON(params)

  const response = await fetchWithStatusHandler(input, {
    method,
    headers,
    body,
  })
  const data = await readResponseAsJSON(response)
  return data
}

export const fetchPostFormData = async (
  input: RequestInfo,
  params?: object,
) => {
  const method = 'POST'
  const { body } = requestInitFormData(params)

  const response = await fetchWithStatusHandler(input, {
    method,
    body,
  })
    const data = await readResponseAsJSON(response)
    return data
}

export const customErrorMessageFetchPostFormData = async (
  input: RequestInfo,
  params?: object,
) => {
  const method = 'POST'
  const { body } = requestInitFormData(params)

  const response = await formFetchWithStatusHandler(input, {
    method,
    body,
  })
    const data = await readResponseAsJSON(response)
    return data
}

export const fetchPut = async (
  input: RequestInfo,
  params?: object | string,
) => {
  const method = 'PUT'
  const { headers, body } = requestInitJSON(params)

  const response = await fetchWithStatusHandler(input, {
    method,
    headers,
    body,
  })
  const data = await readResponseAsJSON(response)
  return data
}

export const fetchDelete = async (
  input: RequestInfo,
  params?: object | string,
) => {
  const method = 'DELETE'
  const { headers, body } = requestInitJSON(params)

  const response = await fetchWithStatusHandler(input, {
    method,
    headers,
    body,
  })
  const data = await readResponseAsJSON(response)
  return data
}

export const getFetchJsonp = (
  url: string,
  params?: string[][] | Record<string, string> | string | URLSearchParams
) => {
  const requestUrl = params ? url + '?' + new URLSearchParams(params) : url
  return fetchJsonp(requestUrl, { 
    jsonpCallback: 'callback',
    jsonpCallbackFunction: 'jsonpcallback'
  })
}


export const fetchPostBlobFile = async (
  input: RequestInfo,
  params?: object | string,
) => {
  const method = 'POST'
  const { headers, body } = requestInitJSON(params)

  const response = await fetchWithStatusHandler(input, {
    method,
    headers,
    body,
  })
  return downloadResponse(response)
}