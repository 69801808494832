/**
 * 连接列表
 */
import React, { useEffect, useRef, useMemo, useState, forwardRef, useImperativeHandle } from 'react'
import { Table, Input, message, Modal, Menu, Dropdown, Button, Tooltip } from 'antd'
import { DownOutlined, EditOutlined } from '@ant-design/icons'
import { Iconfont } from 'src/components'
import { ConnectionStatus } from './ConnectionStatus'
import {
  modifyConnectionGroupName,
  testConnection,
  deleteConnection,
} from 'src/api'
import { renderTableFields, getScrollX } from 'src/util'
import styles from './index.module.scss'
import classnames from 'classnames'
import { ITestConnectionProps } from './connectionManagementPageSlice'
import { useSelector } from 'src/hook'
import { Link } from 'react-router-dom'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip'

const updateTableData = (data: any[], keys?: any[],testConnection?:ITestConnectionProps[]) => {
  // 对数据list里面的update更新，这样组件监听到update值变化就会去请求然后更新当前连接的状态
  // keys存在，说明触发了批量操作或者单次测试，用拿到的keys去更新list里面update的值
  if (Array.isArray(keys) && keys.length > 0) {
    let newKeys = keys.map(str => Number(str))
    return data.map(item => {
      const { connectionId, update } = item || {};
      return newKeys.includes(connectionId) ? { ...item, update: !update } : { ...item };
    })
  }
  else if (Array.isArray(testConnection) && testConnection.length) {
    return data.map(item => {
      const { connectionId } = item || {};
      const { connectionId: cid, result } = testConnection.find(item => item.connectionId === connectionId) || {}
      return connectionId === cid ? { ...item, alreadyTestedRusult: result } : { ...item };
    })
  } else {
    return data;
  }
}

interface IProps {
  [p: string]: any
}

const ConnectionList = forwardRef((props: IProps, ref) => {
  const {
    data = {},
    curTab = "group",
    selectNodeType,
    dataSourceType,
    handleEditContent,
    handleCopyContent,
    handleShowContentChange,
    handleRefreshTree,
    handleRefresh,
    selectedNodeInfo,
    permissionlist,
    handleDeleteConnection,
  } = props;

  const { testConnection } = useSelector((state) => state.connectionManagement)
  const { userInfo } = useSelector((state) => state.login)

  const { connectionList = [], group = {} } = data
  const { isOnlyRead, roleNameList } = permissionlist || {}
  const inputRef = useRef(null)
  const [isEdit, setIsEdit] = useState(false)
  const [groupName, setGroupName] = useState<any>()
  const [connectionListData, setConnectionListData] = useState<any>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (Array.isArray(connectionList)) {
      setConnectionListData(updateTableData(connectionList, [], testConnection));
      setSelectedRowKeys([]);
    }
  }, [JSON.stringify(connectionList)])

  useEffect(() => {
    setGroupName(group?.groupName)
  }, [group])

  useEffect(() => {
    if (Array.isArray(connectionListData)) {
      setConnectionListData(updateTableData(connectionListData, [], testConnection));
    }
  }, [testConnection])

  useImperativeHandle(ref, () => ({
    handleTestConnection,
  }));
  
    //是DBA且根据菜单权限判断；非DBA的都不能操作 
    const hasMoreMenuPermission = useMemo(() => {
      const isDBA = !permissionlist?.roleNameList?.includes('DBA角色') && !permissionlist?.roleTypeList?.some((item: string) => item.startsWith('CUSTOM_SYSTEM'));
      
      return !isDBA && !permissionlist?.isOnlyRead
    },[permissionlist?.isOnlyRead])

  const handleEditName = () => {
    setIsEdit(true)
    // @ts-ignore
    inputRef?.current && inputRef?.current?.focus()
  }

  const handleNameChange = (e: any) => {
    const value = e.target.value?.trim();
    setGroupName(value);
  }

  // 修改组名
  const handleModifyName = () => {
    if (!groupName) {
      setGroupName(group?.groupName);
      setIsEdit(false);
      return
    }
    const params = {
      id: group?.id,
      groupName,
    };
    modifyConnectionGroupName(params)
      .then(() => {
        message.success("修改成功");
        handleRefreshTree();
      })
      .catch((err: any) => {
        setGroupName(group?.groupName);
        console.error("修改失败", err);
      });
    setIsEdit(false);
  };


  const handleTestConnectionRecord = (record: any) => {
    const {
      connectionId,
      userInputs = {},
    } = record
    const {
      connectionName,
      connectionUrl,
      connectionUrlView,
      connectionPort,
      dataSourceType,
      dataSourceVersion,
      authDatabase,
      database,
      connectionMembers,
      connectionMode,
      mastername,
      userName,
      remark,
      devModel,
      connectionRole,
      connectionType,
      serviceName,
    } = userInputs;
    const params: any = {
      connectionId,
      dataSourceType,
      userInputs: {
        dataSourceVersion,
        connectionName,
        connectionUrl,
        connectionUrlView,
        connectionPort,
        authDatabase,
        database,
        connectionMembers,
        connectionMode,
        mastername,
        userName,
        remark,
        devModel,
        dataSourceType,
        connectionRole,
        connectionType,
        serviceName,
      },
    };
    return params;
  }

  /**
   * 测试连接
   */
  const handleTestConnection = (params: any) => {
    const { connectionId } = params
    setConnectionListData(updateTableData(connectionListData, [connectionId]))
  }


  const handleDeleteConnections = () => {
    const paramMap: any = {};
    const requestArr: any[] = [];
    selectedRowKeys.forEach(item => {
      const data = connectionListData?.filter((itemdata: { connectionId: React.Key }) => itemdata?.connectionId === item);
      const { connectionId, connectionType } = data && data[0];
      const params: any = {
        connectionId,
        connectionType,
        nodePath: '',
        nodeType: 'connection',
      }
      paramMap[item] = params;
    })
    Modal.confirm({
      title: '确认批量删除吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        Object.keys(paramMap).forEach(item => {
          requestArr.push(deleteConnection(paramMap[item]))
        })
        Promise.all(requestArr)
          .then(() => {
            message.success('批量删除成功');
            setSelectedRowKeys([])
          })
          .finally(() => {
            handleRefresh()
          })
      },
    })
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const testConnections = () => {
    setConnectionListData(updateTableData(connectionListData, selectedRowKeys));
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={handleDeleteConnections} disabled={isOnlyRead}>
        <Tooltip title={
          isOnlyRead?
          `您当前的角色是[${roleNameList.join(", ")}], 对[连接管理]没有操作权限`
          : null
          }
          style={{width: 180, display: 'flex', textAlign: 'center'}}
        >
          批量删除
        </Tooltip>
      </Menu.Item>
      {/* <Menu.Item onClick={handleDeleteConnections} disabled={isOnlyRead}>批量删除</Menu.Item> */}
      <Menu.Item onClick={testConnections}>批量测试</Menu.Item>
    </Menu>
  );

  const rowSelection = {
    selectedRowKeys,
    getCheckboxProps: (record: any) => ({
      disabled: !(hasMoreMenuPermission || record?.userId === userInfo?.userId) 
    }),
    onChange: onSelectChange,
  };

  const columns: any[] = [
    {
      title: '连接名称',
      dataIndex: 'connectionName',
      key: 'connectionName',
      width: 200,
      ellipsis: true,
      render: (txt: string, record: any) => {
        const { update, alreadyTestedRusult } = record || {};
        return <>
          <ConnectionStatus
            params={handleTestConnectionRecord(record)}
            updateNow={update}
            alreadyTestedRusult={alreadyTestedRusult} />
          {
            txt ?
              <span
                className={styles.options}
                onClick={() => {
                  handleShowContentChange('tabs', record)
                }}
              >
                { txt?.length>20 ? <Tooltip title={txt}>{txt?.slice(0,20)+'...'}</Tooltip> : txt }
              </span> : '-'
          }
        </>
      }
    },
    {
      title: '链接地址',
      dataIndex: 'connectionUrl',
      key: 'connectionUrl',
      width: 350,
      render: (_: string, record: any) => (
        record.connectionType === 'Redis' ?
          <span>
            {record?.userInputs?.connectionMembers[0]?.connectionUrl +
              ':' +
              record?.userInputs?.connectionMembers[0]?.connectionPort}
          </span>
          :
          <span>
            {record?.userInputs?.connectionUrl +
              ':' +
              record?.userInputs?.connectionPort}
          </span>
      ),
    },
    {
      title: '模板名称',
      dataIndex: 'ruleTemplateName',
      key: 'ruleTemplateName',
      width: 200,
      render: (value: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, record: { ruleTemplateId: number }) => {
        const { ruleTemplateId } = record;
        return <>
          {
            ruleTemplateId ?
              <Link to={`/rule_management/view?templateId=${ruleTemplateId}`} target="_self">{value}</Link>
              : '-'
          }
        </>
      }
    },
    {
      title: 'SID/服务名',
      dataIndex: 'SID',
      key: 'SID',
      width: 200,
      render: (_: string, record: any) => (
        <span>{renderTableFields(record?.userInputs?.serviceName)}</span>
      ),
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      key: 'userName',
      width: 200,
      ellipsis: true,
      render: (_: string, record: any) => (
        <span>{renderTableFields(record?.userInputs?.userName)}</span>
      ),
    },
    {
      title: '连接管理员',
      dataIndex: 'manager',
      key: 'manager',
      width: 200,
      ellipsis: true,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      key: 'creator',
      width: 200,
      ellipsis: true,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    },
    {
      title: '操作',
      dataIndex: 'option',
      key: 'option',
      fixed: 'right',
      width: 180,
      render: (_: string, record: any) => (
        <div className={styles.options}>
          {/* { console.log(hasMoreMenuPermission, 'hasMoreMenuPermission--s')} */}
          <PermissionTooltip
            permissionlist={permissionlist}
            title='连接管理'
            visible={!(hasMoreMenuPermission || record?.userId === userInfo?.userId)}
          >
            <Button
              type='link'
              onClick={() => handleEditContent(record)}
              disabled={!(hasMoreMenuPermission || record?.userId === userInfo?.userId)}
            >
              编辑
            </Button>
          </PermissionTooltip>
          <span
            className={styles.ml10}
            onClick={() => handleTestConnection(handleTestConnectionRecord(record))}
          >
            测试
          </span>
          <Tooltip title={ !hasMoreMenuPermission ? "只有DBA角色才能复制连接": ''}>
            <Button
                type='link'
                className={classnames(styles.ml10)}
                onClick={() => handleCopyContent(record)}
                disabled={!hasMoreMenuPermission}
            >
              复制
            </Button>
          </Tooltip>
            
          <PermissionTooltip
            permissionlist={permissionlist}
            title='连接管理'
            visible={!(hasMoreMenuPermission || record?.userId === userInfo?.userId)}
          >
            <Button
              type='link'
              className={classnames(styles.ml10)}
              onClick={() => handleDeleteConnection(record)}
              disabled={!(hasMoreMenuPermission || record?.userId === userInfo?.userId)}
            >
              删除
            </Button>
          </PermissionTooltip>
        </div>
      ),
    },
  ]

  // 区分第一项是分组名还是实例名
  if (curTab === 'group') {
    columns.splice(1, 0, {
      title: '分组名称',
      dataIndex: 'groupName',
      key: 'groupName',
      width: 200,
      ellipsis: true,
      render: (txt: string) => <span>{renderTableFields(txt)}</span>
    })
  } else if (curTab === 'instance') {
    columns.splice(1, 0, {
      title: "实例名称",
      dataIndex: "groupName",
      key: "groupName",
      width: 200,
      ellipsis: true,
      render: (_: string, record: any) => (
        <span> {record?.hostAddr + ":" + record?.port}</span>
      ),
    });
  }

  const hasSelected = !(Array.isArray(selectedRowKeys) && selectedRowKeys.length > 0);
  const hasGroupInfo = ['datasource', 'group'].includes(selectNodeType)
  const instanceUrl =
    connectionList?.[0]?.userInputs?.connectionUrl +
    ':' +
    connectionList?.[0]?.userInputs?.connectionPort
  const serviceName = connectionList?.[0]?.userInputs?.serviceName

  return (
    <>
      {hasGroupInfo && (
        <div className={styles.headerLine}>
          {selectNodeType === "datasource" ? (
            <div className={styles.namePart}>
              <Iconfont
                type={`icon-connection-${dataSourceType}`}
                className={styles.mr10}
              />
              {dataSourceType}
            </div>
          ) : (
            <div>
              <div className={styles.namePart}>
                <Iconfont
                  type="icon-shujukuwenjianjia"
                  className={styles.mr10}
                />
                {curTab === "group" ? (
                  <>
                    <Input
                      ref={inputRef}
                      style={{
                        width: isEdit ? 200 : 0,
                        padding: isEdit ? "4px 11px" : 0,
                        opacity: isEdit ? 1 : 0,
                      }}
                      value={groupName}
                      onChange={handleNameChange}
                      onBlur={handleModifyName}
                    />
                    <span style={{ opacity: isEdit ? 0 : 1 }}>
                      {groupName}
                      <EditOutlined
                        className={classnames(styles.ml10, styles.options)}
                        onClick={handleEditName}
                      />
                    </span>
                  </>
                ) : (
                  selectedNodeInfo?.nodeName ?? instanceUrl
                )}
              </div>
              {curTab === "group" ? (
                <div className={styles.desc}>创建人：{group?.createUser}</div>
              ) : (
                <div>
                  <div className={styles.desc}>实例地址：{selectedNodeInfo?.nodeName || instanceUrl}</div>
                  <div className={styles.desc}>sid/服务名：{serviceName ?? ''}</div>
                </div>
              )}
            </div>
          )}
          <Dropdown disabled={hasSelected} overlay={menu}>
            <Button type="primary">批量操作<DownOutlined /></Button>
          </Dropdown>
        </div>
      )}
      {/* 连接列表 */}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        rowKey="connectionId"
        dataSource={connectionListData}
        scroll={{ x: getScrollX(columns), y: "calc(100vh - 310px)" }}
        pagination={false}
        className={styles.connnectionList}
      />
    </>
  );
})

export default ConnectionList