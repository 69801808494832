import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Button, Form, Input, message, Steps } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import styles from './index.module.scss'
import { useCounter, useInterval } from 'react-use'
import { examineCode, sendCodeBindNewEmail, updateUserEmail } from 'src/api'
import { userEmailValidator } from 'src/util'

const steps = [
  {
    title: '身份验证',
  },
  {
    title: '新邮箱号验证',
  },
  {
    title: '完成',
  },
];

export const ModalEmail: React.FC<{ record?: any, refresh: () => Promise<any> }> = ({
  record,
  refresh
}) => {
  const dispatch = useDispatch()
  const visible = useSelector(
    (state) => state.modal.EmailEditModal,
  )
  const { isBindEmail, userEmail } = useSelector(
    (state) => state.personalCenter,
  )
  const { userId } = useSelector((state) => state.login.userInfo)
  const [current, setCurrent] = useState(1)
  const [count, { dec, reset, set }] = useCounter(60, 60, 0)
  useEffect(() => {
    set(0)
  }, [set])

  const inputRef = useRef<Input>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useInterval(() => dec(1), 1000)
  const [form] = Form.useForm()

  useEffect(() => {
    setCurrent(1)
  }, [visible])


  /** 未绑定 发送邮箱验证码 */
  const { run: sendBindNewEmailCode } = useRequest(sendCodeBindNewEmail, {
    manual: true,
    onSuccess(data) {
      message.success(`${data.message}`)
    }
  })

  /** 检查邮箱验证码 */
  const { run: examineCodeRun } = useRequest(examineCode, {
    manual: true,
    onSuccess(data) {
      updateUserEmailRun({ email: form.getFieldValue(['newEmail']) })
    }
  })

  /** 更新邮箱 */
  const { run: updateUserEmailRun } = useRequest(updateUserEmail, {
    manual: true,
    onSuccess(data) {
      setCurrent(2)
    }
  })

  return (
    <UIModal
      title={'绑定邮箱'}
      visible={visible}
      footer={[]}
      onCancel={() => {
        dispatch(hideModal('EmailEditModal'))
        form.resetFields()
      }}
    >
      <>
        <Steps current={current} labelPlacement="vertical">
          {steps.map(({ title }, index) => (
            <Steps.Step title={title} key={index} />
          ))}
        </Steps>
        {
          current === 1 && (
            <div className={styles.boxMargin}>
              <Form form={form} {...FormLayout}>
                <Form.Item
                  label="新邮箱号"
                >
                  {
                    isBindEmail ? <Form.Item
                      name="newEmail"
                      rules={[{ validator: userEmailValidator }]}
                    >
                      <Input />
                    </Form.Item> : <p>{userEmail}</p>
                  }
                </Form.Item>
                <Form.Item
                  label="验证码"
                >
                  <div className={styles.disflex}>

                    <Form.Item
                      name="authCode"
                      rules={[{ required: true, message: '请输入验证码' }]}
                    >
                      <Input ref={inputRef} autoFocus />
                    </Form.Item>
                    {
                      // (countDown == 60 || countDown == 0) ?
                      <Button
                        disabled={!!count}
                        className='ml10'
                        onClick={() => {
                          form.validateFields(['newEmail']).then(({ newEmail }) => {
                            sendBindNewEmailCode?.(newEmail)?.then(() => {
                              reset()
                            })
                          })
                        }}>
                        {count ? `${count}s` : '发送验证码'}
                      </Button>
                      // <div>{countDown + 's'}</div>
                    }
                  </div>
                </Form.Item>
              </Form>
              <div className={styles.buttonRight}>
                <Button type="primary" onClick={() => {
                  form.validateFields().then((values) => {
                    userId && examineCodeRun({ code: values?.authCode, userId })
                  })
                }}>
                  下一步
                </Button>
              </div>
            </div>
          )
        }
        {current === 2 && (
          <div className={styles.boxMargin}>
            <div>
              {
                isBindEmail ? '邮箱绑定成功' : '解绑成功'
              }
            </div>
            <div className={styles.buttonRight}>
              <Button type="primary" onClick={() => {
                form.validateFields().then(() => {
                  dispatch(hideModal('EmailEditModal'))
                  refresh()
                  form.resetFields();
                  // setCurrent(2)
                })
              }}>
                完成
              </Button>
            </div>
          </div>
        )}
      </>
    </UIModal>
  )
}
