import React, { useEffect, useState } from 'react';
import { Button, Modal, Table, Tooltip } from 'antd';
import { Iconfont } from 'src/components';
import {
  getSqlFlashBackModalList,
  getSqlFlashBackModalStatus,
  getSqlFlashBack,
  FlashBackSqlDataType,
} from 'src/api';
import { useRequest } from 'src/hook';
import type { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Iprops {
  connectionId: number | string | undefined;
  isPending: boolean | undefined;
  tabKey: string | undefined;
  executeFlashbackSql: (flashbackSql: string) => void
  visible: boolean
}


export const SqlFlashBackModal = (props: Iprops) => {
  const { isPending, connectionId, tabKey, executeFlashbackSql, visible } = props || {};

  // 当前这个闪回的功能是否显示
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //获取当前连接是否配置闪回，初始化判断是否有这个闪回icon展示
  const { run: getSqlFlashBackVisible } = useRequest(() => getSqlFlashBack(connectionId, 'sqlFlashBack'), {
    manual: true,
    onSuccess(data) {
      let { variable_value: sqlFlashBackSettingValue } = data;
      if (sqlFlashBackSettingValue) {
        sqlFlashBackSettingValue = JSON.parse(sqlFlashBackSettingValue);
      } else {
        sqlFlashBackSettingValue = false;
      }
      setIsVisible(sqlFlashBackSettingValue);
    }
  })

  //获取当前状态是否支持闪回，比如已经闪回过的就会返回false，初始化、执行完语句都调此接口
  const { data: canClick, run: getSqlFlashBackStatus } = useRequest(
    () => getSqlFlashBackModalStatus(connectionId, tabKey),
    { manual: true })

  // 获取表格里的闪回语句list
  const { data: dataList, run: getSqlFlashBacklList } = useRequest(
    () => getSqlFlashBackModalList(connectionId, tabKey),
    {
      manual: true,
      formatResult: (data) => {
        return data.map((item, index) => {
          if (index === 0) {
            return { ...item, operation: true }
          } else {
            return item
          }
        })
      }
    })

  useEffect(() => {
    if (connectionId && tabKey) {
      getSqlFlashBackVisible();
    }
  }, [connectionId, tabKey])

  useEffect(() => {
    if (!isPending && connectionId && tabKey) {
      getSqlFlashBackStatus()
    }
  }, [isPending])

  const showModal = () => {
    setIsModalOpen(true);
    getSqlFlashBacklList()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const executeBackSql = (flashbackSql: string) => {
    executeFlashbackSql(flashbackSql);
    handleCancel();
  }

  const columns: ColumnsType<FlashBackSqlDataType> = [
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      width: 100
    },
    {
      title: '执行时间',
      dataIndex: 'date',
      key: 'date',
      width: 200
    },
    {
      title: '执行sql',
      dataIndex: 'executeSql',
      key: 'executeSql',
    },
    {
      title: '闪回sql',
      dataIndex: 'flashBackSql',
      key: 'flashBackSql',
    },
    {
      title: '操作',
      dataIndex: 'flashBackSql',
      key: 'flashBackSql',
      width: 80,
      render: (value, record, index) => {
        const { operation } = record || {};
        return <Button
          onClick={() => executeBackSql(value)}
          disabled={!operation}
          type="link"
          style={{ paddingLeft: 0 }}
        >闪回</Button>
      }
    },
  ];

  if (!visible || !isVisible) {
    return <></>
  }
  return (
    <>
      <Tooltip title="SQL闪回" placement="bottomLeft" arrowPointAtCenter>
        <Iconfont
          className={classNames(
            styles.monacoToolbarIcon,
            styles.iconExecute,
            isPending && styles.iconDisabled,
            !canClick && styles.iconDisabled,
          )}
          type="icon-shanhui"
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title="SQL闪回"
        visible={isModalOpen}
        footer={false}
        width={1000}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          dataSource={dataList}
          columns={columns}
          pagination={{ pageSize: 1 }}
          style={{ wordBreak: "break-all" }}
        />
      </Modal>
    </>
  );
}
