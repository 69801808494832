import React, { useEffect, useRef } from 'react'
import { Form, Input, message } from 'antd'
import { createFolder } from 'src/api'
import { UIModal } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { setPwd } from './myFolderSlice'
import { folderNameValidator } from 'src/util'

export const NewFolderModal:  React.FC<{ refresh: () => Promise<void> }> = ({
  refresh
})=> {
  const { ModalNewFolder } = useSelector((state) => state.modal)
  const { pwd, treeTag } = useSelector((state) => state.myFolder)
  const dispatch = useDispatch()
  const handleCreateNewFolder = () => {
    newFolderForm.validateFields().then((values) => {
      const { folderName } = values
      tryCreateFolder(folderName).then(() => {
        message.success(`新建文件夹「${folderName}」成功`)
      })
      dispatch(hideModal('ModalNewFolder'))
    })
  }
  const { run: tryCreateFolder } = useRequest(
    (folderName) => createFolder(treeTag + '/' + folderName ),
    { manual: true, onSuccess: () => {
      refresh()
      dispatch(setPwd(pwd.path))
    } },
  )
  const [newFolderForm] = Form.useForm()

  const focusRef = useRef<Input>(null)
  useEffect(() => {
    if (!ModalNewFolder) return
    focusRef.current?.select()
  }, [ModalNewFolder])

  return (
    <UIModal
      title="新建文件夹"
      visible={ModalNewFolder}
      onCancel={() => dispatch(hideModal('ModalNewFolder'))}
      onOk={handleCreateNewFolder}
      width={320}
      afterClose={() => newFolderForm.resetFields()}
    >
      <Form
        form={newFolderForm}
        initialValues={{ folderName: '未命名文件夹' }}
        onFinish={handleCreateNewFolder}
      >
        <Form.Item
          name="folderName"
          label="名称"
          rules={[
            { required: true, message: '名称不能为空' },
            { validator: folderNameValidator },
            { max: 25, message: '名称最长 25 个字符' },
          ]}
        >
          <Input ref={focusRef}></Input>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
