import React, { useEffect, useState, FC } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { Form, message, Button, Spin } from 'antd'
import { UIModal } from 'src/components'
import { FormLayout, FormTailLayout } from 'src/constants'
import { finishCreateConnection } from './createConnectionSlice'
import { getWizardFormItem } from '../wizardFormItem'
import {
  createConnectionConfig,
  EditConnectionRequest,
  getConnectionConfigItems,
  validateConnectionConfig,
} from 'src/api'
import { setReRenderTheRoot } from 'src/store/extraSlice/appearanceSlice'

interface CreateConnectionModalProps {
  /** 创建连接成功的回调 */
  onFinish?: () => void
}

export const CreateConnectionModal: FC<CreateConnectionModalProps> = ({
  onFinish,
}) => {
  /** 确认创建连接 loading */
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  /** 测试连接 loading */
  const [testLoading, setTestLoading] = useState<boolean>(false)

  /** 是否是复制连接 */
  const [copyFromFlag, setCopyFromFlag] = useState<boolean>(false)

  const { visibleCreateConnection, dataSourceType, mode } = useSelector(
    (state) => state.wizards.createConnection,
  )
  const rightClickedNode = useSelector((state) => state.sdt.rightClickedNode)
  const connectionId = rightClickedNode.connectionId

  const dispatch = useDispatch()
  const [connectionForm] = Form.useForm()
  const { dataSourceMap } = useSelector((state) => state.dataSource)

  const formSpec = dataSourceMap[dataSourceType]?.connectionForm

  const [hasChanedPassword, setHasChanedPassword] = useState(false)

  const localValidate = () =>
    new Promise<EditConnectionRequest>((resolve) => {
      return connectionForm
        .validateFields()
        .then((values) => {
          const params = {
            dataSourceType,
            userInputs: values,
          }
          resolve(params)
        })
        .catch(() => { })
    })

  const handleCreateConnection = () => {
    localValidate().then((params) => {
      let newParams: any = params
      if (copyFromFlag) {
        newParams = {
          ...params,
          copyFrom: connectionId
        }
      }
      setConfirmLoading(true)
      createConnectionConfig(newParams, hasChanedPassword)
        .then(() => {
          message.success('连接创建成功')
          onFinish?.()
          dispatch(finishCreateConnection())
          /** 在这里添加更新根节点的方法 **/
          dispatch(setReRenderTheRoot(true))
        })
        .finally(() => {
          setConfirmLoading(false)
        })
    })
  }

  const handleValidateConnection = () => {
    localValidate()
      .then((params) => {
        let newParams: any = params
        if (copyFromFlag) {
          newParams = {
            ...params,
            copyFrom: connectionId
          }
        }
        setTestLoading(true)
        return validateConnectionConfig(newParams, hasChanedPassword)
      })
      .then((res) => {
        res ? message.success('连接可用') : message.error('连接失败')
      })
      .finally(() => setTestLoading(false))
  }

  const {
    data: configs,
    loading: loadingConfigs,
    run: fetchConfigs,
  } = useRequest(
    getConnectionConfigItems,
    {
      manual: true,
      formatResult: (data) => {
        return data?.filter(({ label }) => label !== "监控开关")
      }
    }
  )
  // 复制连接回填连接设置
  useEffect(() => {
    setCopyFromFlag(false)
    if (mode !== 'copy' || !connectionId) return
    setCopyFromFlag(true)
    fetchConfigs(connectionId)
  }, [connectionId, fetchConfigs, mode])

  const onValuesChange  = (changedValues: any) => {
    if(changedValues.hasOwnProperty('password')) {
      setHasChanedPassword(true);
    }
  }
  return (
    <UIModal
      visible={visibleCreateConnection}
      onCancel={() => {
        dispatch(finishCreateConnection())
      }}
      onOk={handleCreateConnection}
      afterClose={() => connectionForm.resetFields()}
      title={`创建 ${dataSourceType} 连接`}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loadingConfigs}>
        <Form form={connectionForm} {...FormLayout} onValuesChange={onValuesChange}>
          {mode === 'add' && formSpec?.map(value => getWizardFormItem({spec: value}))}
          {mode === 'copy' && configs?.map(value => getWizardFormItem({spec: value}))}
          <Form.Item {...FormTailLayout}>
            <Button onClick={handleValidateConnection} loading={testLoading}>
              测试连接
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </UIModal>
  )
}
