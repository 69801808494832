import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  path: string
}

const initialState: InitialState = {
  path: '/analyze',
}

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setPath(state, action: PayloadAction<string>) {
      state.path = action.payload
    },
  },
})

export const auditReducer = auditSlice.reducer

export const { setPath } = auditSlice.actions
