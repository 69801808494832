import { Tooltip, Typography } from 'antd'
import React from 'react'
import styles from './index.module.scss'

// todo: 移动到 util
export const getGenderText = (gender: any) => {
  if (gender === 'MALE') return '男'
  if (gender === 'FEMALE') return '女'
  return gender
}

type RenderFieldKey =
  | 'list'
  | 'listString'
  | 'timeString'
  | 'userGender'
  | 'longString'

export const renderItemField = (key: RenderFieldKey | any) => (value: any) => {
  switch (key) {
    case 'userGender':
      return getGenderText(value)
    case 'list':
      return ((value as []) || []).join(', ')
    case 'listString':
      return ((value as string) || '')
        .split(',')
        .map((item) => item.trim())
        .join(', ')
    case 'timeString':
      value = value || ''
      return (
        <Tooltip title={value} placement="top">
          {value}
        </Tooltip>
      )
    case 'longString':
      if (!value) return ''
      // todo: return render object, including ellipsis
      value = String(value)
      if (value.length <= 1) return value
      return (
        <Typography.Paragraph
          copyable
          ellipsis
          style={{ marginBottom: 0 }}
          className={styles.longStringRender}
        >
          {value}
        </Typography.Paragraph>
      )
    case 'successStatus':
      return <div>{value ? '成功' : '失败'}</div>
    case 'isPrincipal':
      return value ? '是' : '否'
    case 'userStatus':
      if (value === 'NORMAL') return '正常'
      if (value === 'LOCKED') return '锁定'
      break
    case 'userCategory':
      if (value === 'USER') return '普通用户'
      if (value === 'OWNER') return '连接管理员'
      if (value === 'GRANTER') return '授权管理员'
      break
    default:
      return String(value)
  }
}
