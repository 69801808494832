import React, { useState, useMemo, useEffect } from "react";
import { UploadOutlined, SearchOutlined , ExclamationCircleFilled} from "@ant-design/icons";
import { Card, Space, Input, Button, message, Modal, Tooltip } from "antd";
import { useDispatch, useRequest, useSelector } from "src/hook";
import { SimpleTable } from "src/components";
import {
  getDesensImportTasks,
  IDesensImportTaskItem,
  uploadDesensResource,
  deleteDesensLog,
  permissionSupport
} from "src/api";
import { columns } from "./columns";
import { UploadDesenSettingModal } from "./UploadDesenSettingModal";
import styles from "./index.module.scss";
import { hideModal, showModal } from "src/store/extraSlice/modalVisibleSlice";
import { getCurrentModulePermissionByUrl } from "src/util";

const { confirm } = Modal;

const ImportDesConfig = () => {
  const dispatch = useDispatch();
  const [searchFileName, setSearchFileName] = useState("");

  const { permissionList } = useSelector((state) => state?.login)
  //模块权限查询
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, 'DATA_PROTECT')
  },[JSON.stringify(permissionList)])
  // 判断是否可编辑
  const { run: runPermissionSupport, data: permissionSupportData } = useRequest(permissionSupport, {
    manual: true,
    onSuccess: (res) => {}
  })

  useEffect(() => {
    runPermissionSupport({
      systemPermissionType: "DATA_PROTECT",
      nodePathWithType: null
    })
  }, [])

  const {
    data = [],
    loading,
    refresh,
  } = useRequest<IDesensImportTaskItem[]>(getDesensImportTasks);
  const { run: uploadFile } = useRequest(uploadDesensResource, {
    manual: true,
    onSuccess: () => {
      message.success("上传成功");
      dispatch(hideModal('UploadDesensSettingModal'));
      refresh();
    },
  });

  const {run: runDeleteLog} = useRequest(deleteDesensLog, {manual: true,
    onSuccess: () => {
      message.success("删除成功");
      refresh();
    },
  })


  const onConfirmDeleteDesnesLog = (resourceIds: number[]) => {
    confirm({
      title: `确定删除此日志信息？`,
      centered: true,
      icon: <ExclamationCircleFilled />,
      onOk() {
        runDeleteLog({resourceIds});
      },
    });
  };
  
  const list = useMemo(() => {
    if (!searchFileName) return data;
    return data?.filter((item) =>
      item?.fileName?.toLowerCase().includes(searchFileName.toLowerCase())
    );
  }, [data, searchFileName]);

  return (
    <Card
      title="脱敏配置导入"
      bordered={false}
      className={styles.desConfigCard}
    >
      <div className={styles.searchHeader}>
        <Space>
          <Input
           allowClear
            prefix={<SearchOutlined />}
            placeholder="搜索文件名"
            className={styles.search}
            onChange={(e: any) => setSearchFileName(e.target.value)}
          />
          <Tooltip title={
            !permissionSupportData?
            `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
            : null
            } 
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={() => dispatch(showModal("UploadDesensSettingModal"))}
              disabled={!permissionSupportData}
            >
              点击上传
            </Button>
          </Tooltip>
        </Space>
      </div>

      <SimpleTable
        loading={loading}
        total={data?.length || 0}
        //@ts-ignore
        columns={columns({
          onDeleteUploadedFile: (ids: number[]) => onConfirmDeleteDesnesLog(ids)
        }, modulePermissionObj, permissionSupportData)}
        dataSource={list || []}
        scroll={{ x: "max-content", y: `calc(100vh - 340px)` }}
        showPagination={false}
      />
      <UploadDesenSettingModal onUploadField={(file: any) => uploadFile(file)}/>
    </Card>
  );
};

export default ImportDesConfig;
