import { createSlice, PayloadAction , createAsyncThunk} from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { batch } from 'react-redux'
import * as _ from 'lodash'
import { getPermissionList, queryGroupNodes_automic } from 'src/api'
import { getTargetNode, specialFormatTree } from './utils';
import {
  getSelectedPermissionCollectionNode, 
  getSelectedPermissionCollectionNodes,
} from 'src/api'
interface AuthorizationAuthorizationManageState {
  permissionList: any[];
  authorizeUserList: any[];
  expendKeys: any[];
  //权限集tree
  loadedTreeNodeMap:  Record<string, any[]>,
  loadedKeys: string[];
  newTreeData: any[];
  treeNodeChildrenFetching: any;
}

const initialState: AuthorizationAuthorizationManageState = {
  permissionList: [],
  authorizeUserList: [],
  expendKeys: [],
  loadedTreeNodeMap:{},
  loadedKeys: [],
  newTreeData: [],
  treeNodeChildrenFetching: {}
}

const initTreeRoot: any = {  // 给treeData套根节点
  id: "0000",
  key: "authorityROOT",
  nodePathWithType: "authorityROOT",
  nodeType: "ROOT",
  nodeName: "全部权限集",
  title: '全部权限集',
  isFolder: true,
  children: [],
}

export const getTreeNodeChildren = createAsyncThunk<
  any[],
  any,
  { state: any }
>('authorizationAuthorizationManage/getTreeNodeChildren', async (params, { dispatch, getState }) => {
  
  const {
    sdt: { treeNodeChildrenMap, treeNodeChildrenFetching },
    sdtPermission: { status = false }
  } = getState()

  const list = await dispatch(fetchTreeNodeChildren({
    ...params,
  })).unwrap()
 
  return list
})

// 更新树节点 children
export const fetchTreeNodeChildren = createAsyncThunk<
  any,
  any,
  { state: any }
>(
  'authorizationAuthorizationManage/fetchTreeNodeChildren',
  async (params) => {
    let children = [];
    if (params?.nodeType) {
      children = await getSelectedPermissionCollectionNode(params);

    }else {
      const res = await getSelectedPermissionCollectionNodes(params?.roleId);
      children = res?.nodeList || []
    }
    return children
  },
  {
    condition({ nodePath }, { getState }) {
      const {
        sdt: { treeNodeChildrenFetching },
      } = getState()
      const fetching = treeNodeChildrenFetching[nodePath]
      // 应用当前已经在获取数据，则不再重复请求
      return !fetching
    },
  },
)

export const fetchConnections = (): AppThunk => async (dispatch, getState) => {
  const {
    sdt: { expandedKeys },
    sdtPermission: { status }
  } = getState()

  queryGroupNodes_automic({   
    pageNo: 0,
    pageSize: 100000000,
    roleName: '' })
    .then((list) => {
    
      let clone = _.cloneDeep(initTreeRoot)
      clone.children = specialFormatTree(list?.list, true);
   
      dispatch(setNewTreeData([clone]))
      batch(() => {
        dispatch(setLoadedKeys([]))
        // dispatch(setExpandedKeys([...expandedKeys]))
      })
    })
    .finally(() => {

    })
}
export const refreshOnRoot = (): AppThunk => (dispatch) => {
 
  dispatch(fetchConnections())
}

export const authorizationAuthorizationManageSlice = createSlice({
  name: "authorizationAuthorizationManage",
  initialState,
  reducers: {
    setPermissionList: (state, action: PayloadAction<any[]>) => {
      state.permissionList = action.payload;
    },
    setAuthorizeUserList: (state, action: PayloadAction<any[]>) => {
      state.authorizeUserList = action.payload;
    },

    setNodeType: (state, action: PayloadAction<any[]>) => {
      state.expendKeys = action.payload;
    },
    setLoadedTreeNodeMap: (state, action: PayloadAction<any>) => {
      state.loadedTreeNodeMap = action.payload;
    },
    setLoadedKeys: (state, action: PayloadAction<any[]>) => {
      state.loadedKeys = action.payload;
    },
    setNewTreeData: (state, action: PayloadAction<any[]>) => {
      state.newTreeData = action.payload;
    },
    setTreeDataNodeChildren: (
      state,
      action: PayloadAction<{
        nodePath: string
        children: any[]
      }>,
    ) => {
      const { nodePath, children } = action.payload
      const parent = getTargetNode(nodePath, state.newTreeData)
      if (parent) {
        parent.children = children
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTreeNodeChildren.pending, (state, action) => {
      const { key } = action.meta.arg
      const { treeNodeChildrenFetching } = state
      treeNodeChildrenFetching[key] = true
    })
    builder.addCase(fetchTreeNodeChildren.fulfilled, (state, action) => {
      const list = action.payload
      const { key } = action.meta.arg
     
      const target: any = getTargetNode(key, state.newTreeData)
    
      if (target) {
        if (list?.length) target.children = specialFormatTree(list,false,target?.roleId)
        else target.children = []
      }
      state.treeNodeChildrenFetching[key] = false
    })
    builder.addCase(fetchTreeNodeChildren.rejected, (state, action) => {
      const { nodePath } = action.meta.arg
    
      state.loadedKeys = state.loadedKeys.filter((key) => key !== nodePath)
      state.treeNodeChildrenFetching[nodePath] = false
    })
  }
});

export const authorizationAuthorizationManageReducer = authorizationAuthorizationManageSlice.reducer

export const { setPermissionList, setAuthorizeUserList,
  setLoadedTreeNodeMap,
  setLoadedKeys,
  setNewTreeData,
 } = authorizationAuthorizationManageSlice.actions;

export const queryPermissionList =
  (dataSourceType: string): AppThunk =>
    (dispatch) => {
    getPermissionList(dataSourceType).then((res) => {
      dispatch(setPermissionList(res))
    })
  }