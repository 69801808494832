import moduleService from './moduleService'
import { permService, permServiceCq3 } from './permService'

const service = {
  moduleService,
  permService,
  permServiceCq3
}

export default service

export type { IPermKey } from './permService/PermStore'
