import React, { useCallback } from 'react'
import { Form, Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { dumpImport, dumpUpload } from 'src/api'
import { UIModal } from 'src/components'
import { useModal, useSelector } from 'src/hook'

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList.slice(-1)
}

export const SQLImportModal = () => {
  const { visible, closeModal } = useModal('SqlImportModal')
  const { rightClickedNode } = useSelector((state) => state.sdt)
  const [form] = Form.useForm()

  const onOk = useCallback(async () => {
    try {
      const value = await form.validateFields()
      const { file } = value
      const fileName = file[0]?.name

      /* 0 上传文件 */
      await dumpUpload({ file: file[0]?.originFileObj })
      /* 1 导入 */
      await dumpImport({
        ...rightClickedNode,
        fileName,
        dataDumpFileType: 'SQL',
      } as any)
      message.success('开始执行')
      closeModal()
    } catch (e) { }
  }, [closeModal, form, rightClickedNode])

  const validateSQL = (_: any, value: any) => {
    if (!value.length) {
      return Promise.reject(`请上传文件`)
    }
    if (value.length > 1) {
      return Promise.reject(`最大上传数量为1`)
    }

    // sql 文件校验
    const sqlReg = /(\.sql)$/i
    if (!sqlReg.test(value[0].name)) {
      return Promise.reject(`文件后缀名为sql/SQL`)
    }
    return Promise.resolve()
  }

  return (
    <UIModal
      title="SQL 导入"
      visible={visible}
      onCancel={() => {
        form.resetFields()
        closeModal()
      }}
      onOk={onOk}
      width={420}
    >
      <Form form={form}>
        <Form.Item
          name="file"
          label="上传文件"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ validator: validateSQL }]}
          required
        >
          <Upload
            beforeUpload={() => {
              return false
            }}
          >
            <Button icon={<UploadOutlined />}>点击上传</Button>
          </Upload>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
