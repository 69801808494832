import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { Tabs, Input, Select, Form } from 'antd'
import { getRefSchemaNames, NameRes, getFunctionNames } from 'src/api'
import useRequest from '@ahooksjs/use-request'

interface IProps {
  disabledEdit: boolean
  connectionType: string
  connectionId: string | undefined | number
  databaseName: string | undefined
  schemaName: string | undefined
  focusRowData: any
  onResetTriggerBody: (params: any) => void
}

export const TriggerExtraContent = React.memo(
  ({
    disabledEdit,
    connectionType,
    connectionId,
    databaseName,
    schemaName,
    focusRowData,
    onResetTriggerBody,
  }: IProps) => {

    //触发函数模式options
    const { data: functionSchemaNames, run: runFunctionSchemaNames } =
      useRequest(getRefSchemaNames, {
        manual: true,
        formatResult: (res: NameRes) => {
          return (
            res?.names &&
            res.names.map((name) => ({ label: name, value: name }))
          )
        },
      })
   //触发函数options
    const { data: functionNames, run: runFunctionNames } = useRequest(
      getFunctionNames,
      { manual: true,
        formatResult: (res: NameRes) => {
            return (
              res?.names &&
              res.names.map((name) => ({ label: name, value: name }))
            )
          },
     },
    )
    const extraContentType = (() => {
      switch (connectionType) {
        case 'Oracle':
        case 'MySQL':
        case 'OceanBaseMySQL':
        case 'GBase':
          return 'Oracle'
        case 'Vertica':
          return ''
        default:
          return 'PostgreSQL'
      }
    })()

    useEffect(() => {
      if (!connectionId || !connectionType || disabledEdit) return
      if (extraContentType ==='PostgreSQL') {
        runFunctionSchemaNames({ connectionId, connectionType })
      }
    }, [extraContentType])

    return (
      <>
        <Tabs>
          {extraContentType === 'Oracle' && (
            <Tabs.TabPane tab="语句块">
              <Input.TextArea
                disabled={disabledEdit}
                autoSize={{ minRows: 6, maxRows: 6 }}
                value={!_.isEmpty(focusRowData) && focusRowData?.data?.body}
                onChange={(e: any) =>
                  onResetTriggerBody({ body: e.target.value })
                }
              />
            </Tabs.TabPane>
          )}
          {extraContentType === 'PostgreSQL' && (
            <Tabs.TabPane tab="General">
              <Form.Item
                label="触发函数模式"
                wrapperCol={{ span: 16 }}
                labelCol={{ span: 0 }}
              >
                <Select
                  allowClear
                  showArrow
                  options={functionSchemaNames}
                  disabled={disabledEdit}
                  style={{ width: 200 }}
                  value={
                    !_.isEmpty(focusRowData) &&
                    focusRowData?.data?.functionSchemaName
                  }
                  onChange={(val) => {
                
                    onResetTriggerBody({
                      functionSchemaName: val,
                      functionName: '',
                    })
                    runFunctionNames({
                        connectionId: connectionId,
                        connectionType: connectionType,
                        databaseName: databaseName,
                        schemaName: val
                    })
                  }
                }
                />
                <Select
                  allowClear
                  showArrow
                  options={functionNames}
                  disabled={disabledEdit}
                  style={{ width: 200 }}
                  value={
                    !_.isEmpty(focusRowData) && focusRowData?.data?.functionName
                  }
                  onChange={(val) => onResetTriggerBody({ functionName: val })}
                />
              </Form.Item>
              <Form.Item
                label="参数"
                wrapperCol={{ span: 8 }}
                labelCol={{ span: 0 }}
              >
                <Input
                  style={{ width: 420 }}
                  disabled={disabledEdit}
                  value={
                    !_.isEmpty(focusRowData) ?
                    focusRowData?.data?.functionParam : ''
                  }
                  onChange={(e) =>
                    onResetTriggerBody({ functionParam: e.target.value })
                  }
                />
              </Form.Item>
            </Tabs.TabPane>
          )}
        </Tabs>
      </>
    )
  },
)
