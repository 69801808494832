import {
  Modal,
  Switch,
  Space,
  Select,
  Input,
  Dropdown,
  Button,
  Menu,
  message,
  Tooltip,
} from 'antd';
import * as _ from 'lodash';
import {
  ExclamationCircleFilled,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import {
  setDesensitizeResourceStatus,
  batchSetPolicy,
  batchDeleteDesensResource,
  IPolicyParams,
  getFlowPermission,
  deleteFlowPermission,
  permissionSupport
} from 'src/api';
import { useDispatch, useRequest, useSelector } from 'src/hook';
import {
  formatRuleName,
  formatSelectOptions,
  formatStatusSelectOptions,
} from '../utility';
import { Iconfont, SimpleTable, TextWithTooltip } from 'src/components';
import { hideModal, showModal } from 'src/store/extraSlice/modalVisibleSlice';
import {
  setDesensRuleSettingObj,
  setDesensPolicySettingObj,
  setDesensitizedUserListObj,
} from '../../DataProtectionPageSlice';
import styles from './index.module.scss';
import classnames from 'classnames';
import { getCurrentModulePermissionByUrl } from 'src/util/utils';

interface DataType {
  id: number;
  connectionName: string;
  nodePath: string;
  columnName: string;
  ruleName: string;
  ruleParam: string;
  status: boolean;
  datasourceType: string
}

const { confirm } = Modal;

const RenderColumns = ({
  refresh,
  onDelete,
  onSetPolicy,
}: {
  refresh: () => Promise<any>;
  onDelete: (ids: number[], fields: {nodePathWithType: string, dataSourceType: string}[]) => void;
  onSetPolicy: (params: IPolicyParams) => void;
}, permissionlist: any, permissionSupportData: boolean): ColumnsType<DataType> => {
  const dispatch = useDispatch();
  const {isOnlyRead = false, roleNameList = []} = permissionlist || {}

  const { run: setStatus } = useRequest(setDesensitizeResourceStatus, {
    manual: true,
  });

  async function handleSetStatus(record: DataType) {
    await setStatus({
      id: [record.id],
      status: !record.status,
    });
    refresh();
  }

  return [
    {
      dataIndex: 'connectionName',
      title: '连接名',
      fixed: 'left',
      width: 200,
      render(value, record: any) {
        return (
          <span>
            <Iconfont
              type={`icon-connection-${record?.datasourceType}`}
              style={{ marginRight: 4 }}
            />
            {value}
          </span>
        );
      },
    },
    {
      dataIndex: 'object',
      title: '对象名称',
      width: 150,
      render(value) {
        return <TextWithTooltip text={value} maxLength={20} />;
      },
    },
    {
      dataIndex: 'columnName',
      title: '字段名',
      width: 100,
    },
    {
      dataIndex: 'ruleName',
      title: '脱敏规则',
      width: 150,
      render(value) {
        return formatRuleName(value);
      },
    },
    {
      dataIndex: 'ruleParam',
      title: '算法',
    },
    {
      dataIndex: 'source',
      title: '来源',
      width: 100,
    },
    {
      dataIndex: 'source',
      title: '申请脱敏用户',
      width: 150,
      render: (_: string, record: any) => (
        <span
          className={styles.linkBtn}
          onClick={() => {

            dispatch(setDesensitizedUserListObj({
              ...record,
              callback: () => refresh(),
            }));
            dispatch(showModal('DesensitizedUserListModal'));
          }}
        >
          查看
        </span>
      ),
    },
    {
      dataIndex: 'policy',
      title: '编辑策略',
      width: 100,
      render: (val: string, record: any) => (
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          } 
        >
          <Button
            type='link'
            className={classnames(styles.linkBtn, styles.padding0)}
            onClick={() => {
              dispatch(showModal('DesensPolicySettingModal'));
              dispatch(
                setDesensPolicySettingObj({
                  policy: val,
                  callback: (policy: string) =>
                    onSetPolicy({ resourceIds: [record.id], policy }),
                })
              );
            }}
            disabled={!permissionSupportData}
          >
            策略
          </Button>
        </Tooltip>
      ),
    },
    {
      dataIndex: 'status',
      title: '状态',
      width: 120,
      render: (value, record) => (
        <Tooltip title={
          !permissionSupportData?
          `您当前的角色是[${roleNameList.join(", ")}], 对[数据保护]没有操作权限`
          : null
          } 
        >
          <Switch
            checked={value ? true : false}
            checkedChildren="启用"
            unCheckedChildren="关闭"
            onChange={(checked: boolean) => {
              handleSetStatus(record);
            }}
            disabled={!permissionSupportData}
          />
        </Tooltip>
      ),
    },
    {
      dataIndex: 'options',
      title: '操作',
      width: 120,
      fixed: 'right',
      render(value, record) {
        return (
          <Space size="small" align="start">
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${roleNameList?.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
              className={styles.displayFlex}
            >
              <Button
                type='link'
                className={classnames(styles.linkBtn, styles.padding0)}
                onClick={() => {
                  dispatch(showModal('CommonEditDesensRulesModal'));
                  dispatch(
                    setDesensRuleSettingObj({
                      refresh,
                      editRecord: record,
                    })
                  );
                }}
                disabled={!permissionSupportData}
              >
                编辑
              </Button>
              <Button
                type='link'
                className={classnames(styles.deleteBtn, styles.padding0)}
                onClick={() => onDelete([record.id], [{nodePathWithType:record?.nodePath, dataSourceType: record?.datasourceType}])}
                disabled={!permissionSupportData}
              >
                删除
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
};

const RootDesensitize = ({
  loading,
  dataSource,
  refresh,
}: {
  loading: boolean;
  dataSource: any;
  refresh: () => Promise<any>;
}): JSX.Element => {

  const dispatch = useDispatch();
  const { selectedNode, selectedNodePermissionInfo ={} } = useSelector((state) => state.dataProtection);

  const {modulePermissionObj = {isOnlyRead: false, roleNameList: []}, permissionSupportData = true } = selectedNodePermissionInfo["DATA_PROTECT"] || {};

  const [searchParams, setSearchParams] = useState<{
    columnName?: string;
    status?: any;
  }>({ status: 'ALL' });
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRowItems, setSelectedRowItems] = useState<any>([]);

  //批量设置策略
  const { run: runBatchSetPolicy } = useRequest(batchSetPolicy, {
    manual: true,
    onSuccess: (res) => {
      message.success('编辑策略成功');
      setSelectedRowKeys([]);
      refresh();
      dispatch(hideModal('DesensPolicySettingModal'));
    },
  });
  //批量删除
  const { run: batchDeleteDesens } = useRequest(batchDeleteDesensResource, {
    manual: true,
    onSuccess: () => {
      message.success('删除成功');
      refresh();
      setSelectedRowKeys([]);
    },
  });

  // 批量 启用/禁用
  const { run: setStatus } = useRequest(setDesensitizeResourceStatus, {
    manual: true,
  });

  useEffect(() => {
    setSearchParams({ status: 'ALL' });
    setSelectedRowKeys([]);
    setSelectedRowItems([]);
  }, [selectedNode?.key]);

  const showDeleteConfirm = async({
    type,
    resourceIds,
    fields
  }: {
    type: 'EXPORT' | 'DELETE' | any;
    resourceIds: number[];
    fields?: {
      nodePathWithType: string;
      dataSourceType: string;
    }[]
  }) => {
    if (type === 'POLICY') {
      dispatch(showModal('DesensPolicySettingModal'));
      dispatch(
        setDesensPolicySettingObj({
          isBatch: true,
          callback: (policy: string) =>
            runBatchSetPolicy({ resourceIds, policy }),
        })
      );
      return;
    }

    confirm({
      title: `确定${type === 'DELETE' ? '删除' : type === 'EXPORT' ? '导出' : type === 'TRUE' ? '启用' : '禁用'}${
        resourceIds?.length
      }个配置?`,
      icon: <ExclamationCircleFilled />,
      content: type=== 'DELETE' && '删除操作将同时删除所有脱敏权限',
      onOk() {
        if (type === 'EXPORT') {
          window.open(
            `/desens/resource/download?nodePath=${resourceIds.join(',')}`
          );
          setSelectedRowKeys([]);
        } else if (type === 'DELETE') {
          //循环 查看脱敏用户 并删除
          const desensUsers = fields?.map(async (item) => {
            return await getFlowPermission(item);
          });
          desensUsers && Promise.all(desensUsers)
          .then(async(results) => {
            let permissionIds: number[] = [];
            results?.map(result => {
              const ids = result?.map((item: any) => item?.permissionId);
              permissionIds = permissionIds.concat(ids);
            });
            //删除
            try {
              if (permissionIds?.length) {
                await deleteFlowPermission({permissionIds})
              }
             
              //获取所有permissionIds
              await batchDeleteDesens({ resourceIds });
            } catch (error) {
              throw new Error()
            }
            
          })
          .catch((error) => {
            console.error(error);
          });
        
        } else if (type === 'TRUE') {
          try {
            if (resourceIds?.length) {
              setStatus({
                id: resourceIds,
                status: true
              }).then(() => {
                setSelectedRowKeys([]);
                refresh()
              })
            }
          } catch (error) {
            throw new Error()
          }
        } else if (type === 'FALSE') {
          try {
            if (resourceIds?.length) {
              setStatus({
                id: resourceIds,
                status: false
              }).then(() => {
                setSelectedRowKeys([]);
                refresh()
              })
            }
          } catch (error) {
            throw new Error()
          }
        }
      },
    });
  };

  const filteredDataSource = useMemo(() => {
    if (!searchParams) return dataSource;
    let newData = _.cloneDeep(dataSource);

    const { status, columnName } = searchParams as any;
    const s = status.toLowerCase();
    if (s !== 'all') {
      newData = newData.filter((item: any) => item?.status?.toString() === s);
    }
    if (columnName) {
      newData = newData.filter((item: any) =>
        item?.columnName?.toLowerCase()?.includes(columnName.toLowerCase())
      );
    }

    return newData;
  }, [dataSource, searchParams]);

  return (
    <div className={styles.rootDesensitizePart}>
      <div className={styles.desensHeader}>
        <Space>
          状态：
          <Select
            options={formatStatusSelectOptions}
            defaultValue="ALL"
            onChange={(type: string) => {
              const cloneSearchParams = _.cloneDeep(searchParams);
              setSearchParams({
                ...cloneSearchParams,
                status: type,
              });
            }}
          />
          <Input
            allowClear
            prefix={<SearchOutlined />}
            className={styles.inputSearch}
            placeholder="搜索脱敏配置"
            onChange={(e: any) => {
              const cloneSearchParams = _.cloneDeep(searchParams);
              setSearchParams({
                ...cloneSearchParams,
                columnName: e.target.value,
              });
            }}
          />
          <Dropdown
            disabled={selectedRowKeys?.length === 0 || !permissionSupportData}
            overlay={
              <Menu
                className={styles.options}
                onClick={(e) => {
                  const fields = selectedRowItems?.map((item: any) => ({nodePathWithType: item?.nodePath, dataSourceType: item?.datasourceType}))
                    showDeleteConfirm({
                      type: e.key,
                      resourceIds: selectedRowKeys,
                      fields
                    })
                  }
                }
              >
                {formatSelectOptions.map((item) => (
                  <Menu.Item key={item?.value}>{item?.label}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <Tooltip title={
              !permissionSupportData?
              `您当前的角色是[${modulePermissionObj?.roleNameList.join(", ")}], 对[数据保护]没有操作权限`
              : null
              } 
            >
              <Button type="primary" disabled={!permissionSupportData}>
              <Space>
                批量操作
                <DownOutlined />
              </Space>
            </Button>
            </Tooltip>
          </Dropdown>
        </Space>
      </div>
      <div className={styles.desensFieldTable}>
        <SimpleTable
          rowKey="id"
          total={filteredDataSource?.length}
          columns={RenderColumns({
            refresh,
            onDelete: (ids: number[], fields:{nodePathWithType: string, dataSourceType: string}[]) =>
              showDeleteConfirm({ type: 'DELETE', resourceIds: ids, fields }),
            onSetPolicy: (params: IPolicyParams) => runBatchSetPolicy(params),
          }, modulePermissionObj, permissionSupportData)}
          loading={loading}
          dataSource={filteredDataSource}
          showPagination={false}
          scroll={{ x: 'max-content', y: `calc(100vh - 310px)` }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys as number[]);
              setSelectedRowItems(selectedRows)
            } 
          }}
        />
      </div>
    </div>
  );
};

export { RootDesensitize };
