import { openDB } from 'idb';

export const openIndexedDB = async () => {
  return openDB('CloudQueryDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('persistedReducer')) {
        db.createObjectStore('persistedReducer');
      }
    },
  });
};

export const indexedDBStorage = {
  getItem: async (key: IDBKeyRange | IDBValidKey) => {
    const db = await openIndexedDB();
    return db.get('persistedReducer', key);
  },
  setItem: async (key: IDBKeyRange | IDBValidKey | undefined, value: any) => {
    const db = await openIndexedDB();
    return db.put('persistedReducer', value, key);
  },
  removeItem: async (key: IDBKeyRange | IDBValidKey) => {
    const db = await openIndexedDB();
    return db.delete('persistedReducer', key);
  },
}
