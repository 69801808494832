import React, { useState, useRef, useCallback, useEffect } from 'react'
import {
  VariableSizeList as List,
  areEqual,
  ListChildComponentProps,
} from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import ReactJson from 'react-json-view'
import AutoSizer from 'react-virtualized-auto-sizer'
import { MessageData, QueryResult, executeSqlStatement } from 'src/api'
import { useRequest, useSelector, useDispatch } from 'src/hook'
import { setTabExecutionStatus } from '../../queryTabs/queryTabsSlice'
import styles from './index.module.scss'
import { useHandleExternalInfo } from 'src/hook/useHandleExternalInfo'

const INIT_ITEM_HEIGHT = 21.8125
const BLOCK_SIZE = 100

export const LazyLoadResultList = ({ result }: { result: QueryResult }) => {
  const dispatch = useDispatch()
  const { handleExternalInfo } = useHandleExternalInfo()
  const {
    connectionId,
    dataSourceType,
    operatingObject,
    databaseName,
    operatingDatabase,
    statement,
    resultData,
    tabKey,
  } = result

  const [cachedDocuments, setCachedDocuments] = useState<Record<string, any>[]>(
    [],
  )
  const loadedKeys = cachedDocuments.map((_, index) => index)
  const [itemCount, setItemCount] = useState(BLOCK_SIZE)
  const txMode = useSelector(state => state.queryTabs.paneInfoMap[tabKey!].txMode)

  const sizeMapRef = useRef(new Map<number, number>())
  const listRef = useRef<List | null>(null)
  const observerRef = useRef(
    new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.getAttribute('data-index'))
        const height = entry.target.getBoundingClientRect().height
        if (height) {
          sizeMapRef.current.set(index, height)
          listRef.current?.resetAfterIndex(index)
        }
      })
    }),
  )

  /* request block data function */
  const { run: fetchBlockData } = useRequest(
    async (offset: number = 0, rowCount: number = BLOCK_SIZE) => {
      const payload = {
        connectionId,
        dataSourceType,
        operatingObject,
        databaseName,
        operatingDatabase,
        statements: [statement],
        offset,
        rowCount,
        tabKey,
        autoCommit: txMode === 'auto',
        actionType: 'ROLLING_RESULT' as const,
      }
      const res = await executeSqlStatement(payload)
      const { executionInfos } = res
      let queryResult: QueryResult[] = []
      executionInfos.forEach((item) => {
        queryResult.push(item.response)
        handleExternalInfo({ type: 'LOG', msg: item.executeLogInfo.message })
      })
      return Promise.resolve(queryResult)
    },
    { manual: true },
  )

  const initFlagRef = useRef(true)
  const handleLoadMore = useCallback(
    async (offset: number) => {
      if (!tabKey) return
      dispatch(setTabExecutionStatus({ key: tabKey, pending: true }))

      try {
        let jsons
        if (initFlagRef.current) {
          jsons = resultData
          initFlagRef.current = false
        } else {
          const [result] = await fetchBlockData(offset)
          jsons = result.resultData
        }

        const documents = jsons.map((el: unknown) => {
          // ! 类型定义不严格，mongo 结果集后端返回的是 string
          const json = el as unknown as string
          try {
            return JSON.parse(json)
          } catch {
            return '格式错误，无法解析'
          }
        })
        setCachedDocuments((cache) => {
          const next = [...cache]
          next.splice(offset, documents.length, ...documents)

          // set itemCount
          if (documents.length < BLOCK_SIZE) {
            setItemCount(next.length)
          } else {
            setItemCount(next.length + 1)
          }

          return next
        })
      } catch {}

      dispatch(setTabExecutionStatus({ key: tabKey, pending: false }))
    },
    [dispatch, fetchBlockData, resultData, tabKey],
  )

  return (
    <div style={{ height: '100%' }} className={styles.resultJson}>
      <div className={styles.resultJsonHeader}>
        {`已加载 ${cachedDocuments.length} 个文档`}
      </div>
      <div className={styles.resultJsonWrapper}>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={(index) => loadedKeys.includes(index)}
              itemCount={itemCount}
              loadMoreItems={handleLoadMore}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  height={height}
                  width={width}
                  itemData={cachedDocuments}
                  itemSize={(index) =>
                    sizeMapRef.current.get(index) || INIT_ITEM_HEIGHT
                  }
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  ref={(list) => {
                    // ! HACK: multiple references
                    const r = ref as unknown as any
                    r(list)
                    listRef.current = list
                  }}
                >
                  {({ data, index, ...rest }) => {
                    return (
                      <Row
                        data={data[index]}
                        index={index}
                        sizeMap={sizeMapRef.current}
                        observer={observerRef.current}
                        {...rest}
                      />
                    )
                  }}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

interface RowProps extends ListChildComponentProps {
  sizeMap: Map<number, number>
  observer: ResizeObserver
}

const Row = React.memo(
  ({ data, index, style, sizeMap, observer }: RowProps) => {
    const { theme } = useSelector((state) => state.appearance)
    const allowCopy = useSelector((state) => state.login.userInfo.copySetting)

    const nodeRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const node = nodeRef.current
      if (!node) return
      observer.observe(node)
      return () => {
        // 组件销毁，停止观测
        const index = node.getAttribute('data-index')
        sizeMap.delete(Number(index))
        observer.unobserve(node)
      }
    }, [observer, sizeMap])

    return (
      <div style={style}>
        {/* 由于需要计算 ReactJson 组件节点高度，需要多一层 div */}
        <div data-index={index} ref={nodeRef}>
          <ReactJson
            src={data}
            theme={theme === 'dark' ? 'monokai' : 'bright:inverted'}
            style={{ backgroundColor: 'transparent' }}
            name={`[${index}] Document`}
            enableClipboard={allowCopy}
            displayDataTypes={false}
            quotesOnKeys={false}
            collapsed={true}
          />
        </div>
      </div>
    )
  },
  areEqual,
)
