import { Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect } from "react";
import { getRowFilterTableInfo, getTableTitleInfo } from "src/api";
import { Iconfont } from "src/components";
import { useRequest, useSelector } from "src/hook";
import styles from './index.module.scss'

// first table
const FieldInfoTable = (): JSX.Element => {
  const { selectedNode } = useSelector(state => state.dataProtection);
  const { loading, data: tableInfo, run } = useRequest(getRowFilterTableInfo, { manual: true });
  const { data: tableTitleInfo, run: fetchTableTitleInfo } = useRequest(getTableTitleInfo, { manual: true });

  const columns: ColumnsType<any> = [
    { title: "字段名", dataIndex: "nodeName" },
    {
      title: "注释",
      key: "comments",
      render(_, record) {
        return record?.nodeOptions?.comments ?? "";
      },
    },
    {
      title: "字段类型",
      key: "fieldType",
      render(_, record) {
        const { dataType, dataLength } = record.nodeOptions ?? {};
        return dataLength ? `${dataType} (${dataLength})` : `${dataType}`;
      },
    },
    {
      title: "样例数据",
      key: "example",
      render(_, record) {
        return record?.nodeOptions?.example ?? "";
      },
    },
  ];

  useEffect(() => {
    if (selectedNode) {
      const { connectionId, nodePath, nodePathWithType, nodeType: findType, connectionType, nodeName } = selectedNode.props;

      /**
       * @description: 暂时通过此方法拿数据库名和schema名
       * @return {*}
       */      
      const allNodesInfo = 
        nodePathWithType.split('/')
          .filter((item) => item.length)
          .reduce((prev, next) => {
            const result = next.split(':')
            return result.length === 2 ? prev.set(result[0], result[1]) : prev
          }, new Map())

      /**
       * @description: 对于oracle和mysql这种两层的, databaseName和schemaName都填一样的,
       * 对于pg这种,database 和 schema 填对应的库名和schema名
       * @return {*}
       */      
      fetchTableTitleInfo({
        connectionId,
        connectionType,
        databaseName: allNodesInfo.get('DATABASE') || allNodesInfo.get('SCHEMA'),
        schemaName: allNodesInfo.get('SCHEMA'),
        // 此处也可用nodeName，无需从info里获取
        tableName: allNodesInfo.get('TABLE')
      })

      run({
        connectionId,
        connectionType,
        nodeType: findType,
        nodeName,
        nodePath,
        nodePathWithType,
      });
    }
  }, [run, selectedNode, fetchTableTitleInfo]);

  return (
    <Spin spinning={loading}>
      <Table
        title={() => (
          <Space>
            <Iconfont
              className={styles.tableIcon}
              type={"icon-table"}
            ></Iconfont>
            <div className={styles.tableName}>{selectedNode?.props.nodeName ?? ""}</div>
            <div className={styles.tableComment}>注释：{ tableTitleInfo?.comment ?? ""}</div>
          </Space>
        )}
        className={styles.filterTable}
        columns={columns}
        dataSource={tableInfo}
        pagination={false}
      ></Table>
    </Spin>
  );
};

export { FieldInfoTable };

