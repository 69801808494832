import React, { useCallback } from 'react'
import { Form, Input, Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { dumpImport, dumpUpload } from 'src/api'
import { UIModal } from 'src/components'
import { useModal, useSelector } from 'src/hook'

const normFile = (e: any) => {
  console.log('Upload event:', e)
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

export const DumpImportModal = () => {
  const { visible, closeModal } = useModal('DumpImportModal')
  const { rightClickedNode } = useSelector((state) => state.sdt)
  const [form] = Form.useForm()

  const onOk = useCallback(async () => {
    try {
      const { file, fileName } = await form.validateFields()
      /* 0 上传文件 */
      await dumpUpload({ file: file[0]?.originFileObj })
      /* 1 导入 */
      await dumpImport({
        ...rightClickedNode,
        fileName,
        dataDumpFileType: 'DMP',
      } as any)
      message.success('开始执行')
      closeModal()
    } catch (e) {}
  }, [closeModal, form, rightClickedNode])

  return (
    <UIModal
      title="DMP 导入"
      visible={visible}
      onCancel={() => {
        form.resetFields()
        closeModal()
      }}
      onOk={onOk}
      width={420}
    >
      <Form form={form}>
        <Form.Item
          label="文件名称"
          name="fileName"
          rules={[{ required: true, message: '请输入文件名' }]}
        >
          <Input></Input>
        </Form.Item>
        {/* <Form.Item
          label="文件格式"
          name="dataDumpFileType"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select>
            <Select.Option value="DMP">DMP</Select.Option>
            <Select.Option value="SQL">SQL</Select.Option>
          </Select>
        </Form.Item> */}
        <Form.Item
          name="file"
          label="上传文件"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          required
        >
          <Upload
            name="logo"
            beforeUpload={(file: any) => {
              form.setFieldsValue({ fileName: file?.name })
              return false
            }}
          >
            <Button icon={<UploadOutlined />}>点击上传</Button>
          </Upload>
        </Form.Item>
      </Form>
    </UIModal>
  )
}
