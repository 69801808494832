// 判断一组图表数据是否为空
export const judgeIsNoData = (data: any[], key?: string) => {
  if (data?.length) {
    return data.every(item => {
      const amount = item[key || 'amount'] || {};
      if (typeof amount === "number") {
        return item.amount === 0;
      } else {
        return Object.keys(amount).every(i => amount[i] === 0)
      }
    });
  }
  return true;
}