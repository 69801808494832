import React from 'react'
import {
  Col,
  Space,
  Tooltip,
  Dropdown,
  Menu,
  Descriptions,
} from 'antd'
import { Iconfont } from 'src/components'
import styles from './index.module.scss'
import { useSelector } from 'src/hook'

export const MonacoToolbarHelp = () => {
  const { execPlan, execute, prompt, quickFind, multiEdit, comment } = useSelector(state => state.setting.hotKeys)

  const format = (key?: string) => {
    if(!key) return
    return key.slice(0,1)[0].toUpperCase() + key.slice(1, -1) + key.slice(-1).toUpperCase()
  }

  const FavoritesMenu = (
    <Menu className={styles.helpMenu} style={{width:'400px'}}>
      <Descriptions title="快捷键列表"  column={1} bordered size="small">
        <Descriptions.Item label="执行（选中语句）">{format(execute)}</Descriptions.Item>
        <Descriptions.Item label="执行计划（选中语句）">{format(execPlan)}</Descriptions.Item>
        <Descriptions.Item label="提示">{format(prompt)}</Descriptions.Item>
        <Descriptions.Item label="快速查找替换">{format(quickFind)}</Descriptions.Item>
        <Descriptions.Item label="多行编辑">{format(multiEdit)}</Descriptions.Item>
        <Descriptions.Item label="注释/取消注释所选行">{format(comment)}</Descriptions.Item>
      </Descriptions>
    </Menu>
  )

  return (
    <Col>
      <Space size={1}>
        <Tooltip title="" placement="bottomLeft" arrowPointAtCenter>
          <Dropdown
            overlay={FavoritesMenu}
            placement="bottomCenter"
            trigger={['hover']}
            mouseLeaveDelay={0.3}
          >
            <Iconfont
              className={styles.monacoToolbarIcon}
              type="icon-kuaijiejian2"
            />
          </Dropdown>
        </Tooltip>
      </Space>
    </Col>
  )
}
