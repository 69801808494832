import DataSet from '@antv/data-set'
import { Chart } from '@antv/g2'
import { Radio, Spin, DatePicker, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import * as _ from 'lodash'
import React, { useEffect, useState, useMemo } from 'react'
import { formattedTimeAliasValue } from './utils'

import chartStyles from './chart.module.scss'
import { EmptyChart } from '../Charts'

import { useHistory } from 'react-router-dom'
import { judgeIsNoData } from '../../utils'

export const ExecutionTimeBarChart = ({
  chartData,
  loading
}: {
  chartData: any;
  loading: boolean
}) => {

  const renderExecutionTimeChart = (container: string, data: any[]) => {
    const chart = new Chart({
      container,
      autoFit: true,
      theme: {
        maxColumnWidth: 30,
      }
    })
    data = data.sort((a, b) => b.amount - a.amount)

    chart.data(data)
    chart.scale({
      
        alias: {
          range: [0.12, 0.92],
          formatter: (v) => {
            const realAlias = v && v.split("---");
            return realAlias[0] && realAlias[0] !== "null"
              ? realAlias[0]
              : realAlias[1];
          },
      },
      amount: {
        formatter: (v) => (v || '0').toLocaleString() + 'ms',
        nice: true,
      },
    })
    chart.axis('amount', { 
      position: 'right',
      label: {
        offset: 10
      }
      });
    chart.tooltip({
      showCrosshairs: true,
      shared: true,
    })
    chart.coordinate().transpose();
    chart
      .interval()
      .position('alias*amount')
      .color('alias')
      .shape('circle')
       
    chart.render()
    return chart
  }

  useEffect(() => {
    if (!chartData || !chartData[0] || judgeIsNoData(chartData)) return
    const chart = renderExecutionTimeChart('normal-execution-time', chartData)
    return () => chart.destroy()
  }, [chartData])

  return (
    <Spin spinning={loading}>
      <div className={chartStyles.executionTimeWrapper}>
        <div id="normal-execution-time" className={chartStyles.executionTime}>
          {((!chartData[0] && !loading) || judgeIsNoData(chartData)) && <EmptyChart></EmptyChart>}
        </div>
      </div>
    </Spin>
  )
}
