import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { getExportList, ExportEntity } from 'src/api'

type Tpage = { current?: number; total?: number; pageSize?: number }

interface DownloadCenterState {
  tableLoading: boolean
  selectedNode: ExportEntity
  exportList: ExportEntity[]
  taskProgressMap: { [key in ITaskProgress['taskId']]: ITaskProgress }
  page: Tpage
}

export interface ITaskProgress {
  percentage: number
  taskId: number
}

const initialState: DownloadCenterState = {
  tableLoading: false,
  selectedNode: {} as ExportEntity,
  exportList: [],
  page: {},
  taskProgressMap: {},
}

export const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    setExportList(
      state,
      action: PayloadAction<{
        content: ExportEntity[]
        current: number
        total: number
      }>,
    ) {
      const { content, ...page } = action.payload ?? {}
      state.exportList = content ?? []
      state.page = page
    },
    setTaskProgressMap(state, action: PayloadAction<ITaskProgress>) {
      const { percentage, taskId } = action.payload
      if (percentage === 100) {
        delete state.taskProgressMap[taskId]
        return
      }
      state.taskProgressMap[taskId] = action.payload
    },
    setTableLoading(state, action: PayloadAction<boolean>) {
      state.tableLoading = action.payload
    },
  },
})

export const downloadReducer = downloadSlice.reducer

export const { setExportList, setTableLoading, setTaskProgressMap } =
  downloadSlice.actions

export const getAndSetExportList =
  (params?: { current?: number; pageSize?: number }): AppThunk =>
  (dispatch) => {
    dispatch(setTableLoading(true))
    getExportList(params)
      .then((exportList) => {
        // todo: 后端排序
        dispatch(setExportList(exportList))
      })
      .finally(() => dispatch(setTableLoading(false)))
  }
