import React, { useEffect } from 'react';
import { WWLoginLangType, WWLoginParams, WWLoginRedirectType, WWLoginType, createWWLoginPanel } from '@wecom/jssdk';

interface IProps {
  elementIdName: string;
  loginConfig: WWLoginParams;
  style?: any;
  isRepeatBind?: boolean;
  repeatBindRender?: () => React.FC;
  onCheckWeComLogin?: (v: any) => void;
  onLoginSuccess?: (v: any) => void;
  onLoginFail?: (v: any) => void;
}

export const WwLoginQrCode = (props: IProps) => {
  const {
    loginConfig,
    elementIdName,
    style,
    onCheckWeComLogin,
    onLoginSuccess,
    onLoginFail
  } = props || {};

  useEffect(() => {
    const wwLogin = createWWLoginPanel({
      el: `#${elementIdName}`,
      params: {
        login_type: 'CorpApp' as WWLoginType,
        lang: 'zh' as WWLoginLangType,
        redirect_type: 'callback' as WWLoginRedirectType,
        ...loginConfig
      },
      onCheckWeComLogin({ isWeComLogin }) {
        console.log(isWeComLogin)
        onCheckWeComLogin && onCheckWeComLogin(isWeComLogin);
      },
      onLoginSuccess({ code }) {
        onLoginSuccess && onLoginSuccess(code);
        console.log({ code });
      },
      onLoginFail(err) {
        onLoginFail && onLoginFail(err);
        console.log(err);
      },
    });

    return () => wwLogin.unmount();
  }, [elementIdName, loginConfig, onCheckWeComLogin, onLoginFail, onLoginSuccess])

  return (
    <span id={elementIdName} style={style} />
  );
}

