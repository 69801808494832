import React, { useState } from 'react'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef } from '@ag-grid-community/core'
import { Button, Descriptions, Input, message, Tooltip } from 'antd'
import {
  testSqlStatement,
  generateCreateSql,
  GenerateSqlRequest,
  StatementExecuteParams,
  executeMenuActionSql,
} from 'src/api'
import {
  DatabaseInfo,
  removeQueryPane,
} from 'src/pageTabs/queryPage/queryTabs/queryTabsSlice'
import { getInfoFromPath, getOperatingObject } from 'src/util'
import { CustomHeader, indexColDef } from 'src/components'
import { GridConfigBase } from '../../resultTabs/resultContentGrid'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import styles from './index.module.scss'
import classNames from 'classnames'
import { refreshOnRoot } from '../../sdt'

interface CreateViewPaneProps {
  databaseInfo: Partial<DatabaseInfo>
  queryTabKey: string
}

export const CreateViewPane = ({
  databaseInfo,
  queryTabKey,
}: CreateViewPaneProps) => {
  const dispatch = useDispatch()
  const [result, setResult] = useState<any>({})
  const [viewName, setViewName] = useState<string>('')
  const [viewComment, setViewComment] = useState<string>('')
  const [statements, setStatements] = useState<string>('')
  const { columnInfos = [] } = result
  const { theme } = useSelector((state) => state.appearance)
  const {
    connectionId = 0,
    connectionType = 'MySQL',
    databaseName,
    nodePath,
  } = databaseInfo
  const { toolbarConnections } = useSelector(state => state.editor)
  const connection = toolbarConnections?.find((t) => t.connectionId === connectionId)
  const connectionName = connection?.alias ? connection.alias : connection?.nodeName
  
  const schemaName = (() => {
    switch (connectionType) {
      case 'SQLServer':
        return getInfoFromPath(nodePath, 'schema', 'PostgreSQL')
      case 'OracleCDB':
      case 'StarRocks':
        return getInfoFromPath(nodePath, 'schema', 'OracleCDB')
      case 'StarRocks':
        return getInfoFromPath(nodePath, 'schema', 'StarRocks')
      case 'DamengDB':
        return getInfoFromPath(nodePath, 'schema', 'DamengDB')
      case 'Vertica':
        return getInfoFromPath(nodePath, 'schema', 'Vertica')
      case 'Trino':
        return getInfoFromPath(nodePath, 'schema', 'Trino')
      default:
        return getInfoFromPath(nodePath, 'connection', connectionType)
    }
  })()

  const columnDefs: ColDef[] = (columnInfos || []).map((def: any) => {
    const { columnName, comment } = def
    return {
      field: columnName,
      headerComponentFramework: CustomHeader,
      headerComponentParams: { headerName: columnName, comment },
    }
  })
  columnDefs.unshift(indexColDef)

  interface ViewInfo {
    viewName: string
    viewComment?: string
  }
  interface UserInputs {
    databaseName?: string
    schemaName?: string
    viewInfo: ViewInfo
    viewSql: string
  }

  const testViewSql = () => {
    setResult([])
    if (!statements) {
      message.error('SQL 语句不能为空')
      return
    }
    const executeParams: StatementExecuteParams = {
      connectionId,
      dataSourceType: connectionType,
      operatingDatabase: databaseName,
      databaseName: databaseName,
      operatingObject: getOperatingObject(
        { databaseName, schemaName },
        connectionType,
      ),
      statements: [statements],
      offset: 0,
      rowCount: 100,
      tabKey: queryTabKey,
    }
    testSqlStatement(executeParams).then((result) => {
      const { executionInfos } = result
      if (executionInfos[0]?.response.executeError) {
        message.error(executionInfos[0].response.executeError.message)
        return
      }
      setResult(executionInfos[0]?.response)
    })
  }

  const { run: createView } = useRequest(executeMenuActionSql, {
    manual: true,
    onSuccess: (result) => {
      // const [{ executeError }] = result
      // if (executeError) {
      //   return message.error(executeError.message)
      // }
      message.success('创建视图成功')
      dispatch(refreshOnRoot())
      dispatch(removeQueryPane(queryTabKey))
    },
  })

  const handleCreateView = () => {
    if (!viewName) {
      message.error('请输入视图名')
      return
    }
    if (!statements) {
      message.error('SQL 语句不能为空')
      return
    }
    const viewInfo: ViewInfo = { viewName }
    if (viewComment) {
      viewInfo.viewComment = viewComment
    }
    const userInputs: UserInputs = {
      databaseName,
      viewInfo,
      viewSql: statements,
    }
    // todo: 从后端或数据源模块获取配置，自动处理数据源差异
    if (['SQLServer', 'DamengDB', 'DB2'].includes(connectionType)) {
      userInputs.schemaName = schemaName
    }
    if (!['DamengDB', 'DB2'].includes(connectionType)) {
      userInputs.databaseName = databaseName
    }
    const params: GenerateSqlRequest = {
      dataSourceType: connectionType || 'MySQL',
      nodeType: 'view',
      userInputs,
    }
    generateCreateSql(params)
      .then(({ generatedSql }) => {
        createView({
          connectionId,
          dataSourceType: connectionType,
          operatingDatabase: databaseName,
          operatingObject: getOperatingObject(
            { databaseName, schemaName },
            connectionType,
          ),
          databaseName,
          statements: [generatedSql],
          tabKey: queryTabKey,
        })
      })
      .catch()
  }

  return (
    <div className={styles.createViewPane}>
      <Descriptions>
        <Descriptions.Item label="连接名">{connectionName}</Descriptions.Item>
        {!['DamengDB'].includes(connectionType) && (
          <Descriptions.Item label="数据库">{databaseName}</Descriptions.Item>
        )}
        {['SQLServer', 'DamengDB', 'DB2','OracleCDB','OracleCDB'].includes(connectionType) && (
          <Descriptions.Item label="Schema">{schemaName}</Descriptions.Item>
        )}
      </Descriptions>
      <div style={{ marginBottom: 16 }}>
        <span>视图名：</span>
        <Input
          value={viewName}
          onChange={(e) => setViewName(e.target.value)}
          style={{ width: 160 }}
          className="mr16"
        ></Input>
        <span>视图注释：</span>
        <Input
          value={viewComment}
          onChange={(e) => setViewComment(e.target.value)}
          style={{ width: 160 }}
        ></Input>
      </div>
      <div style={{ marginBottom: 16 }}>视图 SQL：</div>
      <Input.TextArea
        value={statements}
        onChange={(e) => setStatements(e.target.value)}
        autoSize={{ minRows: 6, maxRows: 6 }}
      />
      <div style={{ margin: '16px 0' }}>
        <Tooltip title="前 100 行执行结果" placement="bottomLeft">
          <Button size="small" className="mr16" onClick={testViewSql}>
            测试
          </Button>
        </Tooltip>
        <Button size="small" type="primary" onClick={handleCreateView}>
          提交
        </Button>
      </div>
      <div
        className={classNames(
          styles.gridContent,
          theme === 'dark' ? 'ag-theme-balham-dark' : 'ag-theme-balham',
        )}
      >
        <AgGridReact
          {...GridConfigBase}
          suppressLoadingOverlay
          modules={[ClientSideRowModelModule]}
          rowData={result.resultData}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  )
}
