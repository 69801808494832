import React, { useEffect } from 'react'
import { useRequest } from 'src/hook'
import { Form, Select } from 'antd'
import { FormFieldElementEntity } from 'src/types'
import { DataSourceType, getPermsByNodeType } from 'src/api'

type RequestParams = Parameters<typeof getPermsByNodeType>[0]
type ValueAndChange = { value?: string[]; onChange: (value?: string[]) => void }

const OperationSelector = ({
  connectionId,
  dataSourceType,
  nodeType,
  nodePath,
  disabledEdit = false,
  value,
  onChange,
}: Partial<RequestParams & ValueAndChange & { disabledEdit?: boolean}>) => {
  const { data, loading, run, reset } = useRequest(getPermsByNodeType, {
    manual: true,
    formatResult: (data) => {
      return data.map(({ opName, message }) => {
        let label
        if (message) {
          label = opName + `(${message})`
        } else {
          label = opName
        }
        return { label, value: opName }
      })
    },
  })
  useEffect(() => {
    if (connectionId && dataSourceType && nodeType) {
      if (nodeType === 'synonym') {
        run({ connectionId, dataSourceType, nodeType, nodePath })
      } else {
        run({ connectionId, dataSourceType, nodeType })
      }
      return () => {
        reset()
      }
    }
  }, [connectionId, dataSourceType, nodeType, nodePath, reset, run])

  return (
    <Select
      disabled={disabledEdit}
      loading={loading}
      value={value}
      onChange={onChange}
      options={data}
      mode="multiple"
      allowClear
      placeholder="请选择操作类型"
    />
  )
}

export const FormItemOperations = ({
  disabledEdit = false
}: {
  disabledEdit?: boolean
}) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {(form) => {
        // ! 人工推断的类型。一是因为这里没有 instance 上下文，二是 form.getFieldValue 没有实现类型推断
        const elements = form.getFieldValue('elements') as
          | FormFieldElementEntity[]
          | undefined

        const firstElement = elements?.[0]
        const connectionId = firstElement?.connectionId
        const connectionType = firstElement?.connectionType as DataSourceType
        const nodeType = firstElement?.nodeType
        const nodePath = firstElement?.nodePath
        const props = {
          connectionId,
          dataSourceType: connectionType,
          nodeType,
          nodePath,
        }

        return (
          <Form.Item
            label="操作类型"
            name="operationList"
            required
            rules={[
              {
                validator: (_, value?: string[]) => {
                  if (value?.length) {
                    return Promise.resolve()
                  }
                  return Promise.reject('请选择至少一个操作类型')
                },
              },
            ]}
          >
            <OperationSelector {...props} disabledEdit={disabledEdit}/>
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}
