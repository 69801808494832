import React, { useState, useEffect } from "react";
import { ColumnsType } from "antd/lib/table";
import { useRequest } from "src/hook";
import { postExecLog } from "src/api";
import { Iconfont, SimpleTable } from "src/components";

interface IPageParam {
    page: number;
    pageSize: number;
  sqlName?: string
}
export const ParsingFallStatement = ({
  searchValue
}: {
  searchValue: string
}) => {

  const [pageParam, setPageParam] = useState<IPageParam>({
    page: 1,
    pageSize: 10,
  });

  const { data, loading } = useRequest(
    () => postExecLog({ 
      pagination: {
      page: pageParam?.page,
      pageSize: pageParam?.pageSize
    },
    sqlName: pageParam?.sqlName
   }),
    {
      refreshDeps: [pageParam],
    }
  );

  useEffect(() => {

    setPageParam({
      ...pageParam,
      sqlName: searchValue,
      page: 1
    })
  },[searchValue])

  const columns: ColumnsType<{
    sql: string;
    resultStatus: boolean;
    executeType: string;
    userId: string;
    startTime: string;
    parsingFailedMessage: string;
    connectionInfo: {
      connectionName: any;
      connectionType: string
    };
  }> = [
    {
      title: "SQL语句",
      dataIndex: "sql",
      key: "sql",
      width: "30%",
      ellipsis: true,
    },
    {
      title: "连接名称",
      dataIndex: "connectionName",
      key: "connectionName",
      render: (value, record) => {
        const { connectionInfo } = record || {}
        return <>{connectionInfo?.connectionName}</>
      }
    },
    {
      title: "数据源类型",
      dataIndex: "databaseType",
      key: "databaseType",
      render: (text, record) => {
        const value = record?.connectionInfo?.connectionType;
        return <>
          <Iconfont
            type={"icon-connection-" + value}
            className="mr8"
            style={{ fontSize: 14 }}
          ></Iconfont>
          {value}
        </>
      }
    },
    {
      title: "执行状态",
      dataIndex: "resultStatus",
      key: "resultStatus",
      render: (value: any) => {
        return <span>{value ? "成功" : "失败"}</span>;
      },
    },
    {
      title: "失败原因",
      dataIndex: "parsingFailedMessage",
      key: "parsingFailedMessage",
      ellipsis: true,
    },
    {
      title: "操作类型",
      dataIndex: "executeType",
      key: "executeType",
    },
    {
      title: "操作用户",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "执行时间",
      dataIndex: "startTime",
      key: "startTime",
    },
  ];

  return (
    <SimpleTable
      size="small"
      rowKey="id"
      loading={loading}
      //@ts-ignore
      columns={columns}
      total={data?.totalCount || 0}
      searchParams={pageParam}
      setSearchParams={(params: any) => {
        setPageParam({
          page: params.currentPage,
          pageSize: params.pageSize,
          sqlName: params?.sqlName
        });
      }}
      dataSource={data?.historyRecords || []}
    />
  );
};
