import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { DrawerWrapper } from 'src/components'
import {
  EXECUTION_COLUMN_FIELDS,
  DEFAULT_EXECUTE_LOG_COLUMNS,
} from 'src/constants'

interface IProps {
  defaultColumnFields: string[]
  visible: boolean
  onCancel: () => void
  onOk: (fields: string[]) => void
}

export const CustomColumnFieldPanel = ({
  defaultColumnFields,
  visible = false,
  onCancel,
  onOk,
}: IProps) => {

  const [checkedColumnFields, setCheckedColumnFields] = useState<string[]>([])

  useEffect(() => {
    setCheckedColumnFields(defaultColumnFields)
  }, [defaultColumnFields])

  return (
    <DrawerWrapper
      title="自定义列表项"
      width={400}
      visible={visible}
      onCancel={() => {
        onCancel();
        setCheckedColumnFields(defaultColumnFields);
      }}
      onOk={() => onOk(checkedColumnFields)}
    >
      <Checkbox.Group
        defaultValue={defaultColumnFields}
        value={checkedColumnFields}
        //@ts-ignore
        onChange={(checkedValue: string[]) =>
          checkedValue && setCheckedColumnFields(checkedValue)
        }
      >
        {Object.keys(EXECUTION_COLUMN_FIELDS).map((key) => (
          <span key={key}>
            <Checkbox
              disabled={DEFAULT_EXECUTE_LOG_COLUMNS.includes(key)}
              value={key}
            >
              {EXECUTION_COLUMN_FIELDS[key]}
            </Checkbox>
            <div style={{ padding: '8px 0' }} />
          </span>
        ))}
      </Checkbox.Group>
    </DrawerWrapper>
  )
}
