import React from 'react'
import classnames from 'classnames'
import { ICellRendererParams } from '@ag-grid-community/core'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styles from './SimpleMarkRenderer.module.scss'

export const SimpleMarkRenderer = (params: ICellRendererParams) => {
  const mark = params.value
  return (
    <div className={classnames(styles.markRenderer, !mark && 'placeholder')}>
      {mark ? <CheckOutlined /> : <CloseOutlined />}
    </div>
  )
}
