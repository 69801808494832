const zhCN = {
  // for filter panel
  page: 'daPage',
  more: 'daMore',
  to: 'daTo',
  of: 'daOf',
  next: 'daNexten',
  last: 'daLasten',
  first: 'daFirsten',
  previous: 'daPreviousen',
  loadingOoo: 'daLoading...',

  // for set filter
  selectAll: 'daSelect Allen',
  searchOoo: 'daSearch...',
  blanks: 'daBlanc',

  // for number filter and text filter
  filterOoo: '关键字...',
  equals: '等于',
  notEqual: '不等于',

  // for the date filter
  dateFormatOoo: 'daYyyy-mm-dd',

  // for number filter
  lessThan: '小于',
  greaterThan: '大于',
  lessThanOrEqual: '小于等于',
  greaterThanOrEqual: '大于等于',
  inRange: '在范围内',
  inRangeStart: '范围内开始',
  inRangeEnd: '范围内家属',

  // for text filter
  contains: '包含',
  notContains: '不包含',
  startsWith: '以输入开头',
  endsWith: '以输入结束',

  // filter conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // filter buttons
  applyFilter: '应用',
  resetFilter: '重置',
  clearFilter: '清除',

  // the header of the default group column
  group: 'laGroup',

  // tool panel
  columns: 'laColumns',
  filters: 'laFilters',
  rowGroupColumns: 'laPivot Cols',
  rowGroupColumnsEmptyMessage: 'la drag cols to group',
  valueColumns: 'laValue Cols',
  pivotMode: 'laPivot-Mode',
  groups: 'laGroups',
  values: 'laValues',
  pivots: 'laPivots',
  valueColumnsEmptyMessage: 'la drag cols to aggregate',
  pivotColumnsEmptyMessage: 'la drag here to pivot',
  toolPanelButton: 'la tool panel',

  // other
  noRowsToShow: '数据为空',
  enabled: 'laEnabled',

  // enterprise menu
  pinColumn: '固定此列',
  valueAggregation: 'laValue Agg',
  autosizeThiscolumn: '自动调整此列宽度',
  autosizeAllColumns: '自动调整所有列宽度',
  groupBy: 'laGroup by',
  ungroupBy: 'laUnGroup by',
  resetColumns: '重置列宽',
  expandAll: 'laOpen-em-up',
  collapseAll: 'laClose-em-up',
  toolPanel: 'laTool Panel',
  export: '导出',
  csvExport: '导出成 CSV',
  excelExport: '导出成 Excel(.xlsx)',
  excelXmlExport: '导出成 XML(.xml)',

  // enterprise menu (charts)
  pivotChartAndPivotMode: 'laPivot Chart & Pivot Mode',
  pivotChart: 'laPivot Chart',
  chartRange: 'laChart Range',

  columnChart: 'laColumn',
  groupedColumn: 'laGrouped',
  stackedColumn: 'laStacked',
  normalizedColumn: 'la100% Stacked',

  barChart: 'laBar',
  groupedBar: 'laGrouped',
  stackedBar: 'laStacked',
  normalizedBar: 'la100% Stacked',

  pieChart: 'laPie',
  pie: 'laPie',
  doughnut: 'laDoughnut',

  line: 'laLine',

  xyChart: 'laX Y (Scatter)',
  scatter: 'laScatter',
  bubble: 'laBubble',

  areaChart: 'laArea',
  area: 'laArea',
  stackedArea: 'laStacked',
  normalizedArea: 'la100% Stacked',

  // enterprise menu pinning
  pinLeft: '固定在左边 <<',
  pinRight: '固定在右边 >>',
  noPin: '取消固定 <>',

  // enterprise menu aggregation and status bar
  sum: 'laSum',
  min: 'laMin',
  max: 'laMax',
  none: 'laNone',
  count: 'laCount',
  average: 'laAverage',
  filteredRows: 'laFiltered',
  selectedRows: 'laSelected',
  totalRows: 'laTotal Rows',
  totalAndFilteredRows: 'laRows',

  // standard menu
  copy: '拷贝',
  copyWithHeaders: '带标题拷贝',
  ctrlC: 'Ctrl+C',
  paste: '黏贴',
  ctrlV: 'Ctrl+V',

  // charts
  pivotChartTitle: 'laPivot Chart',
  rangeChartTitle: 'laRange Chart',
  settings: 'laSettings',
  data: 'laData',
  format: 'laFormat',
  categories: 'laCategories',
  defaultCategory: '(laNone)',
  series: 'laSeries',
  xyValues: 'laX Y Values',
  paired: 'laPaired Mode',
  axis: 'laAxis',
  color: 'laColor',
  thickness: 'laThickness',
  xType: 'laX Type',
  automatic: 'laAutomatic',
  category: 'laCategory',
  number: 'laNumber',
  time: 'laTime',
  xRotation: 'laX Rotation',
  yRotation: 'laY Rotation',
  ticks: 'laTicks',
  width: 'laWidth',
  length: 'laLength',
  padding: 'laPadding',
  chart: 'laChart',
  title: 'laTitle',
  background: 'laBackground',
  font: 'laFont',
  top: 'laTop',
  right: 'laRight',
  bottom: 'laBottom',
  left: 'laLeft',
  labels: 'laLabels',
  size: 'laSize',
  minSize: 'laMinimum Size',
  maxSize: 'laMaximum Size',
  legend: 'laLegend',
  position: 'laPosition',
  markerSize: 'laMarker Size',
  markerStroke: 'laMarker Stroke',
  markerPadding: 'laMarker Padding',
  itemPaddingX: 'laItem Padding X',
  itemPaddingY: 'laItem Padding Y',
  strokeWidth: 'laStroke Width',
  offset: 'laOffset',
  offsets: 'laOffsets',
  tooltips: 'laTooltips',
  callout: 'laCallout',
  markers: 'laMarkers',
  shadow: 'laShadow',
  blur: 'laBlur',
  xOffset: 'laX Offset',
  yOffset: 'laY Offset',
  lineWidth: 'laLine Width',
  normal: 'laNormal',
  bold: 'laBold',
  italic: 'laItalic',
  boldItalic: 'laBold Italic',
  predefined: 'laPredefined',
  fillOpacity: 'laFill Opacity',
  strokeOpacity: 'laLine Opacity',
  columnGroup: 'laColumn',
  barGroup: 'laBar',
  pieGroup: 'laPie',
  lineGroup: 'laLine',
  scatterGroup: 'laScatter',
  areaGroup: 'laArea',
  groupedColumnTooltip: 'laGrouped',
  stackedColumnTooltip: 'laStacked',
  normalizedColumnTooltip: 'la100% Stacked',
  groupedBarTooltip: 'laGrouped',
  stackedBarTooltip: 'laStacked',
  normalizedBarTooltip: 'la100% Stacked',
  pieTooltip: 'laPie',
  doughnutTooltip: 'laDoughnut',
  lineTooltip: 'laLine',
  groupedAreaTooltip: 'laGrouped',
  stackedAreaTooltip: 'laStacked',
  normalizedAreaTooltip: 'la100% Stacked',
  scatterTooltip: 'laScatter',
  bubbleTooltip: 'laBubble',
  noDataToChart: 'laNo data available to be charted.',
  pivotChartRequiresPivotMode: 'laPivot Chart requires Pivot Mode enabled.',
}

export default zhCN
