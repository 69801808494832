import { fetchDelete, fetchGet, fetchPost, fetchPut } from './customFetch'

export interface IPost {
  id?: number
  name: string
  level: 'high' | 'middle' | 'low'
  description?: string
  users?: any[]
  creatorName?: string
  createTime?: string | null
  content?: any[]
  totalElements?: number
  pageNum?: number
  pageSize?: number
}

/* 岗位管理列表 */
export const posts_get_api = ({
  pageNum,
  pageSize,
  postName
}: {
  pageNum: number
  pageSize: number
  postName: string
}): Promise<IPost> => {
  // TODO: 不符合 restful 规范，修改为？pageNum=1 & pageSize=10
  return fetchGet(`/user/post?postName${postName ? '=' + postName : '' }&pageNum=${pageNum}&pageSize=${pageSize}`)
}

/* 新建岗位 */
export const post_post_api = (data: Partial<IPost>) => {
  return fetchPost(`/user/post`, data)
}

/* 编辑岗位 */
export const post_put_api = (data: Partial<IPost>) => {
  return fetchPut(`/user/post`, data)
}

/* 删除岗位 */
export const post_delete_api = (id: number) => {
  return fetchDelete(`/user/post/${id}`)
}
