// Generated from ./src/sql-parser/oracle/grammar/PlSqlLexer.g4 by ANTLR 4.13.1
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import {
	ATN,
	ATNDeserializer,
	CharStream,
	DecisionState, DFA,
	Lexer,
	LexerATNSimulator,
	RuleContext,
	PredictionContextCache,
	Token
} from "antlr4";
import PlSqlLexerBase from './PlSqlLexerBase';

export default class PlSqlLexer extends PlSqlLexerBase {
	public static readonly ABORT = 1;
	public static readonly ABS = 2;
	public static readonly ABSENT = 3;
	public static readonly ACCESS = 4;
	public static readonly ACCESSED = 5;
	public static readonly ACCOUNT = 6;
	public static readonly ACL = 7;
	public static readonly ACOS = 8;
	public static readonly ACROSS = 9;
	public static readonly ACTION = 10;
	public static readonly ACTIONS = 11;
	public static readonly ACTIVATE = 12;
	public static readonly ACTIVE = 13;
	public static readonly ACTIVE_COMPONENT = 14;
	public static readonly ACTIVE_DATA = 15;
	public static readonly ACTIVE_FUNCTION = 16;
	public static readonly ACTIVE_TAG = 17;
	public static readonly ACTIVITY = 18;
	public static readonly ADAPTIVE_PLAN = 19;
	public static readonly ADD = 20;
	public static readonly ADD_COLUMN = 21;
	public static readonly ADD_GROUP = 22;
	public static readonly ADD_MONTHS = 23;
	public static readonly ADJ_DATE = 24;
	public static readonly ADMIN = 25;
	public static readonly ADMINISTER = 26;
	public static readonly ADMINISTRATOR = 27;
	public static readonly ADVANCED = 28;
	public static readonly ADVISE = 29;
	public static readonly ADVISOR = 30;
	public static readonly AFD_DISKSTRING = 31;
	public static readonly AFTER = 32;
	public static readonly AGENT = 33;
	public static readonly AGGREGATE = 34;
	public static readonly A_LETTER = 35;
	public static readonly ALIAS = 36;
	public static readonly ALL = 37;
	public static readonly ALLOCATE = 38;
	public static readonly ALLOW = 39;
	public static readonly ALL_ROWS = 40;
	public static readonly ALTER = 41;
	public static readonly ALTERNATE = 42;
	public static readonly ALWAYS = 43;
	public static readonly ANALYTIC = 44;
	public static readonly ANALYZE = 45;
	public static readonly ANCESTOR = 46;
	public static readonly ANCILLARY = 47;
	public static readonly AND = 48;
	public static readonly AND_EQUAL = 49;
	public static readonly ANOMALY = 50;
	public static readonly ANSI_REARCH = 51;
	public static readonly ANTIJOIN = 52;
	public static readonly ANY = 53;
	public static readonly ANYSCHEMA = 54;
	public static readonly APPEND = 55;
	public static readonly APPENDCHILDXML = 56;
	public static readonly APPEND_VALUES = 57;
	public static readonly APPLICATION = 58;
	public static readonly APPLY = 59;
	public static readonly APPROX_COUNT_DISTINCT = 60;
	public static readonly ARCHIVAL = 61;
	public static readonly ARCHIVE = 62;
	public static readonly ARCHIVED = 63;
	public static readonly ARCHIVELOG = 64;
	public static readonly ARRAY = 65;
	public static readonly AS = 66;
	public static readonly ASC = 67;
	public static readonly ASCII = 68;
	public static readonly ASCIISTR = 69;
	public static readonly ASIN = 70;
	public static readonly ASIS = 71;
	public static readonly ASSEMBLY = 72;
	public static readonly ASSIGN = 73;
	public static readonly ASSOCIATE = 74;
	public static readonly ASYNC = 75;
	public static readonly ASYNCHRONOUS = 76;
	public static readonly ATAN2 = 77;
	public static readonly ATAN = 78;
	public static readonly AT = 79;
	public static readonly ATTRIBUTE = 80;
	public static readonly ATTRIBUTES = 81;
	public static readonly AUDIT = 82;
	public static readonly AUTHENTICATED = 83;
	public static readonly AUTHENTICATION = 84;
	public static readonly AUTHID = 85;
	public static readonly AUTHORIZATION = 86;
	public static readonly AUTOALLOCATE = 87;
	public static readonly AUTO = 88;
	public static readonly AUTOBACKUP = 89;
	public static readonly AUTOEXTEND = 90;
	public static readonly AUTO_LOGIN = 91;
	public static readonly AUTOMATIC = 92;
	public static readonly AUTONOMOUS_TRANSACTION = 93;
	public static readonly AUTO_REOPTIMIZE = 94;
	public static readonly AVAILABILITY = 95;
	public static readonly AVRO = 96;
	public static readonly BACKGROUND = 97;
	public static readonly BACKINGFILE = 98;
	public static readonly BACKUP = 99;
	public static readonly BACKUPS = 100;
	public static readonly BACKUPSET = 101;
	public static readonly BASIC = 102;
	public static readonly BASICFILE = 103;
	public static readonly BATCH = 104;
	public static readonly BATCHSIZE = 105;
	public static readonly BATCH_TABLE_ACCESS_BY_ROWID = 106;
	public static readonly BECOME = 107;
	public static readonly BEFORE = 108;
	public static readonly BEGIN = 109;
	public static readonly BEGINNING = 110;
	public static readonly BEGIN_OUTLINE_DATA = 111;
	public static readonly BEHALF = 112;
	public static readonly BEQUEATH = 113;
	public static readonly BETWEEN = 114;
	public static readonly BFILE = 115;
	public static readonly BFILENAME = 116;
	public static readonly BIGFILE = 117;
	public static readonly BINARY = 118;
	public static readonly BINARY_DOUBLE = 119;
	public static readonly BINARY_DOUBLE_INFINITY = 120;
	public static readonly BINARY_DOUBLE_NAN = 121;
	public static readonly BINARY_FLOAT = 122;
	public static readonly BINARY_FLOAT_INFINITY = 123;
	public static readonly BINARY_FLOAT_NAN = 124;
	public static readonly BINARY_INTEGER = 125;
	public static readonly BIND_AWARE = 126;
	public static readonly BINDING = 127;
	public static readonly BIN_TO_NUM = 128;
	public static readonly BITAND = 129;
	public static readonly BITMAP_AND = 130;
	public static readonly BITMAP = 131;
	public static readonly BITMAPS = 132;
	public static readonly BITMAP_TREE = 133;
	public static readonly BITS = 134;
	public static readonly BLOB = 135;
	public static readonly BLOCK = 136;
	public static readonly BLOCK_RANGE = 137;
	public static readonly BLOCKS = 138;
	public static readonly BLOCKSIZE = 139;
	public static readonly BODY = 140;
	public static readonly BOOLEAN = 141;
	public static readonly BOTH = 142;
	public static readonly BOUND = 143;
	public static readonly BRANCH = 144;
	public static readonly BREADTH = 145;
	public static readonly BROADCAST = 146;
	public static readonly BSON = 147;
	public static readonly BUFFER = 148;
	public static readonly BUFFER_CACHE = 149;
	public static readonly BUFFER_POOL = 150;
	public static readonly BUILD = 151;
	public static readonly BULK = 152;
	public static readonly BY = 153;
	public static readonly BYPASS_RECURSIVE_CHECK = 154;
	public static readonly BYPASS_UJVC = 155;
	public static readonly BYTE = 156;
	public static readonly CACHE = 157;
	public static readonly CACHE_CB = 158;
	public static readonly CACHE_INSTANCES = 159;
	public static readonly CACHE_TEMP_TABLE = 160;
	public static readonly CACHING = 161;
	public static readonly CALCULATED = 162;
	public static readonly CALLBACK = 163;
	public static readonly CALL = 164;
	public static readonly CANCEL = 165;
	public static readonly CANONICAL = 166;
	public static readonly CAPACITY = 167;
	public static readonly CAPTION = 168;
	public static readonly CARDINALITY = 169;
	public static readonly CASCADE = 170;
	public static readonly CASE = 171;
	public static readonly CAST = 172;
	public static readonly CASESENSITIVE = 173;
	public static readonly CATEGORY = 174;
	public static readonly CDBDEFAULT = 175;
	public static readonly CEIL = 176;
	public static readonly CELL_FLASH_CACHE = 177;
	public static readonly CERTIFICATE = 178;
	public static readonly CFILE = 179;
	public static readonly CHAINED = 180;
	public static readonly CHANGE = 181;
	public static readonly CHANGETRACKING = 182;
	public static readonly CHANGE_DUPKEY_ERROR_INDEX = 183;
	public static readonly CHARACTER = 184;
	public static readonly CHAR = 185;
	public static readonly CHAR_CS = 186;
	public static readonly CHARTOROWID = 187;
	public static readonly CHECK_ACL_REWRITE = 188;
	public static readonly CHECK = 189;
	public static readonly CHECKPOINT = 190;
	public static readonly CHILD = 191;
	public static readonly CHOOSE = 192;
	public static readonly CHR = 193;
	public static readonly CHUNK = 194;
	public static readonly CLASS = 195;
	public static readonly CLASSIFICATION = 196;
	public static readonly CLASSIFIER = 197;
	public static readonly CLAUSE = 198;
	public static readonly CLEAN = 199;
	public static readonly CLEANUP = 200;
	public static readonly CLEAR = 201;
	public static readonly C_LETTER = 202;
	public static readonly CLIENT = 203;
	public static readonly CLOB = 204;
	public static readonly CLONE = 205;
	public static readonly CLOSE_CACHED_OPEN_CURSORS = 206;
	public static readonly CLOSE = 207;
	public static readonly CLUSTER_BY_ROWID = 208;
	public static readonly CLUSTER = 209;
	public static readonly CLUSTER_DETAILS = 210;
	public static readonly CLUSTER_DISTANCE = 211;
	public static readonly CLUSTER_ID = 212;
	public static readonly CLUSTERING = 213;
	public static readonly CLUSTERING_FACTOR = 214;
	public static readonly CLUSTER_PROBABILITY = 215;
	public static readonly CLUSTER_SET = 216;
	public static readonly COALESCE = 217;
	public static readonly COALESCE_SQ = 218;
	public static readonly COARSE = 219;
	public static readonly CO_AUTH_IND = 220;
	public static readonly COLD = 221;
	public static readonly COLLECT = 222;
	public static readonly COLUMNAR = 223;
	public static readonly COLUMN_AUTH_INDICATOR = 224;
	public static readonly COLUMN = 225;
	public static readonly COLUMNS = 226;
	public static readonly COLUMN_STATS = 227;
	public static readonly COLUMN_VALUE = 228;
	public static readonly COMMENT = 229;
	public static readonly COMMIT = 230;
	public static readonly COMMITTED = 231;
	public static readonly COMMON = 232;
	public static readonly COMMON_DATA = 233;
	public static readonly COMPACT = 234;
	public static readonly COMPATIBILITY = 235;
	public static readonly COMPILE = 236;
	public static readonly COMPLETE = 237;
	public static readonly COMPLIANCE = 238;
	public static readonly COMPONENT = 239;
	public static readonly COMPONENTS = 240;
	public static readonly COMPOSE = 241;
	public static readonly COMPOSITE = 242;
	public static readonly COMPOSITE_LIMIT = 243;
	public static readonly COMPOUND = 244;
	public static readonly COMPRESS = 245;
	public static readonly COMPUTE = 246;
	public static readonly CONCAT = 247;
	public static readonly CON_DBID_TO_ID = 248;
	public static readonly CONDITIONAL = 249;
	public static readonly CONDITION = 250;
	public static readonly CONFIRM = 251;
	public static readonly CONFORMING = 252;
	public static readonly CON_GUID_TO_ID = 253;
	public static readonly CON_ID = 254;
	public static readonly CON_NAME_TO_ID = 255;
	public static readonly CONNECT_BY_CB_WHR_ONLY = 256;
	public static readonly CONNECT_BY_COMBINE_SW = 257;
	public static readonly CONNECT_BY_COST_BASED = 258;
	public static readonly CONNECT_BY_ELIM_DUPS = 259;
	public static readonly CONNECT_BY_FILTERING = 260;
	public static readonly CONNECT_BY_ISCYCLE = 261;
	public static readonly CONNECT_BY_ISLEAF = 262;
	public static readonly CONNECT_BY_ROOT = 263;
	public static readonly CONNECT = 264;
	public static readonly CONNECT_TIME = 265;
	public static readonly CONSIDER = 266;
	public static readonly CONSISTENT = 267;
	public static readonly CONSTANT = 268;
	public static readonly CONST = 269;
	public static readonly CONSTRAINT = 270;
	public static readonly CONSTRAINTS = 271;
	public static readonly CONSTRUCTOR = 272;
	public static readonly CONTAINER = 273;
	public static readonly CONTAINERS = 274;
	public static readonly CONTAINERS_DEFAULT = 275;
	public static readonly CONTAINER_DATA = 276;
	public static readonly CONTAINER_MAP = 277;
	public static readonly CONTENT = 278;
	public static readonly CONTENTS = 279;
	public static readonly CONTEXT = 280;
	public static readonly CONTINUE = 281;
	public static readonly CONTROLFILE = 282;
	public static readonly CON_UID_TO_ID = 283;
	public static readonly CONVERT = 284;
	public static readonly CONVERSION = 285;
	public static readonly COOKIE = 286;
	public static readonly COPY = 287;
	public static readonly CORR_K = 288;
	public static readonly CORR_S = 289;
	public static readonly CORRUPTION = 290;
	public static readonly CORRUPT_XID_ALL = 291;
	public static readonly CORRUPT_XID = 292;
	public static readonly COS = 293;
	public static readonly COSH = 294;
	public static readonly COST = 295;
	public static readonly COST_XML_QUERY_REWRITE = 296;
	public static readonly COUNT = 297;
	public static readonly COVAR_POP = 298;
	public static readonly COVAR_SAMP = 299;
	public static readonly CPU_COSTING = 300;
	public static readonly CPU_PER_CALL = 301;
	public static readonly CPU_PER_SESSION = 302;
	public static readonly CRASH = 303;
	public static readonly CREATE = 304;
	public static readonly CREATE_FILE_DEST = 305;
	public static readonly CREATE_STORED_OUTLINES = 306;
	public static readonly CREATION = 307;
	public static readonly CREDENTIAL = 308;
	public static readonly CRITICAL = 309;
	public static readonly CROSS = 310;
	public static readonly CROSSEDITION = 311;
	public static readonly CSCONVERT = 312;
	public static readonly CUBE_AJ = 313;
	public static readonly CUBE = 314;
	public static readonly CUBE_GB = 315;
	public static readonly CUBE_SJ = 316;
	public static readonly CUME_DISTM = 317;
	public static readonly CURRENT = 318;
	public static readonly CURRENT_DATE = 319;
	public static readonly CURRENT_SCHEMA = 320;
	public static readonly CURRENT_TIME = 321;
	public static readonly CURRENT_TIMESTAMP = 322;
	public static readonly CURRENT_USER = 323;
	public static readonly CURRENTV = 324;
	public static readonly CURSOR = 325;
	public static readonly CURSOR_SHARING_EXACT = 326;
	public static readonly CURSOR_SPECIFIC_SEGMENT = 327;
	public static readonly CUSTOMDATUM = 328;
	public static readonly CV = 329;
	public static readonly CYCLE = 330;
	public static readonly DANGLING = 331;
	public static readonly DATABASE = 332;
	public static readonly DATA = 333;
	public static readonly DATAFILE = 334;
	public static readonly DATAFILES = 335;
	public static readonly DATAGUARDCONFIG = 336;
	public static readonly DATAMOVEMENT = 337;
	public static readonly DATAOBJNO = 338;
	public static readonly DATAOBJ_TO_MAT_PARTITION = 339;
	public static readonly DATAOBJ_TO_PARTITION = 340;
	public static readonly DATAPUMP = 341;
	public static readonly DATA_SECURITY_REWRITE_LIMIT = 342;
	public static readonly DATE = 343;
	public static readonly DATE_MODE = 344;
	public static readonly DAY = 345;
	public static readonly DAYS = 346;
	public static readonly DBA = 347;
	public static readonly DBA_RECYCLEBIN = 348;
	public static readonly DBMS_STATS = 349;
	public static readonly DB_ROLE_CHANGE = 350;
	public static readonly DBTIMEZONE = 351;
	public static readonly DB_UNIQUE_NAME = 352;
	public static readonly DB_VERSION = 353;
	public static readonly DDL = 354;
	public static readonly DEALLOCATE = 355;
	public static readonly DEBUG = 356;
	public static readonly DEBUGGER = 357;
	public static readonly DEC = 358;
	public static readonly DECIMAL = 359;
	public static readonly DECLARE = 360;
	public static readonly DECOMPOSE = 361;
	public static readonly DECORRELATE = 362;
	public static readonly DECR = 363;
	public static readonly DECREMENT = 364;
	public static readonly DECRYPT = 365;
	public static readonly DEDUPLICATE = 366;
	public static readonly DEFAULT = 367;
	public static readonly DEFAULTS = 368;
	public static readonly DEFAULT_COLLATION = 369;
	public static readonly DEFAULT_CREDENTIAL = 370;
	public static readonly DEFERRABLE = 371;
	public static readonly DEFERRED = 372;
	public static readonly DEFINED = 373;
	public static readonly DEFINE = 374;
	public static readonly DEFINER = 375;
	public static readonly DEGREE = 376;
	public static readonly DELAY = 377;
	public static readonly DELEGATE = 378;
	public static readonly DELETE_ALL = 379;
	public static readonly DELETE = 380;
	public static readonly DELETEXML = 381;
	public static readonly DEMAND = 382;
	public static readonly DENSE_RANKM = 383;
	public static readonly DEPENDENT = 384;
	public static readonly DEPTH = 385;
	public static readonly DEQUEUE = 386;
	public static readonly DEREF = 387;
	public static readonly DEREF_NO_REWRITE = 388;
	public static readonly DESC = 389;
	public static readonly DESCRIPTION = 390;
	public static readonly DESTROY = 391;
	public static readonly DETACHED = 392;
	public static readonly DETERMINES = 393;
	public static readonly DETERMINISTIC = 394;
	public static readonly DICTIONARY = 395;
	public static readonly DIMENSION = 396;
	public static readonly DIMENSIONS = 397;
	public static readonly DIRECT_LOAD = 398;
	public static readonly DIRECTORY = 399;
	public static readonly DIRECT_PATH = 400;
	public static readonly DISABLE_ALL = 401;
	public static readonly DISABLE = 402;
	public static readonly DISABLE_PARALLEL_DML = 403;
	public static readonly DISABLE_PRESET = 404;
	public static readonly DISABLE_RPKE = 405;
	public static readonly DISALLOW = 406;
	public static readonly DISASSOCIATE = 407;
	public static readonly DISCARD = 408;
	public static readonly DISCONNECT = 409;
	public static readonly DISK = 410;
	public static readonly DISKGROUP = 411;
	public static readonly DISKGROUP_PLUS = 412;
	public static readonly DISKS = 413;
	public static readonly DISMOUNT = 414;
	public static readonly DISTINCT = 415;
	public static readonly DISTINGUISHED = 416;
	public static readonly DISTRIBUTED = 417;
	public static readonly DISTRIBUTE = 418;
	public static readonly DML = 419;
	public static readonly DML_UPDATE = 420;
	public static readonly DOCFIDELITY = 421;
	public static readonly DOCUMENT = 422;
	public static readonly DOMAIN_INDEX_FILTER = 423;
	public static readonly DOMAIN_INDEX_NO_SORT = 424;
	public static readonly DOMAIN_INDEX_SORT = 425;
	public static readonly DOUBLE = 426;
	public static readonly DOWNGRADE = 427;
	public static readonly DRIVING_SITE = 428;
	public static readonly DROP_COLUMN = 429;
	public static readonly DROP = 430;
	public static readonly DROP_GROUP = 431;
	public static readonly DSINTERVAL_UNCONSTRAINED = 432;
	public static readonly DST_UPGRADE_INSERT_CONV = 433;
	public static readonly DUMP = 434;
	public static readonly DUMPSET = 435;
	public static readonly DUPLICATE = 436;
	public static readonly DV = 437;
	public static readonly DYNAMIC = 438;
	public static readonly DYNAMIC_SAMPLING = 439;
	public static readonly DYNAMIC_SAMPLING_EST_CDN = 440;
	public static readonly E_LETTER = 441;
	public static readonly EACH = 442;
	public static readonly EDITIONABLE = 443;
	public static readonly EDITION = 444;
	public static readonly EDITIONING = 445;
	public static readonly EDITIONS = 446;
	public static readonly ELEMENT = 447;
	public static readonly ELIM_GROUPBY = 448;
	public static readonly ELIMINATE_JOIN = 449;
	public static readonly ELIMINATE_OBY = 450;
	public static readonly ELIMINATE_OUTER_JOIN = 451;
	public static readonly ELSE = 452;
	public static readonly ELSIF = 453;
	public static readonly EM = 454;
	public static readonly EMPTY_BLOB = 455;
	public static readonly EMPTY_CLOB = 456;
	public static readonly EMPTY_ = 457;
	public static readonly ENABLE_ALL = 458;
	public static readonly ENABLE = 459;
	public static readonly ENABLE_PARALLEL_DML = 460;
	public static readonly ENABLE_PRESET = 461;
	public static readonly ENCODING = 462;
	public static readonly ENCRYPT = 463;
	public static readonly ENCRYPTION = 464;
	public static readonly END = 465;
	public static readonly END_OUTLINE_DATA = 466;
	public static readonly ENFORCED = 467;
	public static readonly ENFORCE = 468;
	public static readonly ENQUEUE = 469;
	public static readonly ENTERPRISE = 470;
	public static readonly ENTITYESCAPING = 471;
	public static readonly ENTRY = 472;
	public static readonly EQUIPART = 473;
	public static readonly ERR = 474;
	public static readonly ERROR_ARGUMENT = 475;
	public static readonly ERROR = 476;
	public static readonly ERROR_ON_OVERLAP_TIME = 477;
	public static readonly ERRORS = 478;
	public static readonly ESCAPE = 479;
	public static readonly ESTIMATE = 480;
	public static readonly EVAL = 481;
	public static readonly EVALNAME = 482;
	public static readonly EVALUATE = 483;
	public static readonly EVALUATION = 484;
	public static readonly EVENTS = 485;
	public static readonly EVERY = 486;
	public static readonly EXCEPT = 487;
	public static readonly EXCEPTION = 488;
	public static readonly EXCEPTION_INIT = 489;
	public static readonly EXCEPTIONS = 490;
	public static readonly EXCHANGE = 491;
	public static readonly EXCLUDE = 492;
	public static readonly EXCLUDING = 493;
	public static readonly EXCLUSIVE = 494;
	public static readonly EXECUTE = 495;
	public static readonly EXEMPT = 496;
	public static readonly EXISTING = 497;
	public static readonly EXISTS = 498;
	public static readonly EXISTSNODE = 499;
	public static readonly EXIT = 500;
	public static readonly EXPAND_GSET_TO_UNION = 501;
	public static readonly EXPAND_TABLE = 502;
	public static readonly EXP = 503;
	public static readonly EXPIRE = 504;
	public static readonly EXPLAIN = 505;
	public static readonly EXPLOSION = 506;
	public static readonly EXPORT = 507;
	public static readonly EXPR_CORR_CHECK = 508;
	public static readonly EXPRESS = 509;
	public static readonly EXTENDS = 510;
	public static readonly EXTENT = 511;
	public static readonly EXTENTS = 512;
	public static readonly EXTERNAL = 513;
	public static readonly EXTERNALLY = 514;
	public static readonly EXTRACTCLOBXML = 515;
	public static readonly EXTRACT = 516;
	public static readonly EXTRACTVALUE = 517;
	public static readonly EXTRA = 518;
	public static readonly FACILITY = 519;
	public static readonly FACT = 520;
	public static readonly FACTOR = 521;
	public static readonly FACTORIZE_JOIN = 522;
	public static readonly FAILED = 523;
	public static readonly FAILED_LOGIN_ATTEMPTS = 524;
	public static readonly FAILGROUP = 525;
	public static readonly FAILOVER = 526;
	public static readonly FAILURE = 527;
	public static readonly FALSE = 528;
	public static readonly FAMILY = 529;
	public static readonly FAR = 530;
	public static readonly FAST = 531;
	public static readonly FASTSTART = 532;
	public static readonly FBTSCAN = 533;
	public static readonly FEATURE = 534;
	public static readonly FEATURE_DETAILS = 535;
	public static readonly FEATURE_ID = 536;
	public static readonly FEATURE_SET = 537;
	public static readonly FEATURE_VALUE = 538;
	public static readonly FETCH = 539;
	public static readonly FILE = 540;
	public static readonly FILE_NAME_CONVERT = 541;
	public static readonly FILEGROUP = 542;
	public static readonly FILESTORE = 543;
	public static readonly FILESYSTEM_LIKE_LOGGING = 544;
	public static readonly FILTER = 545;
	public static readonly FINAL = 546;
	public static readonly FINE = 547;
	public static readonly FINISH = 548;
	public static readonly FIRST = 549;
	public static readonly FIRSTM = 550;
	public static readonly FIRST_ROWS = 551;
	public static readonly FIRST_VALUE = 552;
	public static readonly FIXED_VIEW_DATA = 553;
	public static readonly FLAGGER = 554;
	public static readonly FLASHBACK = 555;
	public static readonly FLASH_CACHE = 556;
	public static readonly FLOAT = 557;
	public static readonly FLOB = 558;
	public static readonly FLEX = 559;
	public static readonly FLOOR = 560;
	public static readonly FLUSH = 561;
	public static readonly FOLDER = 562;
	public static readonly FOLLOWING = 563;
	public static readonly FOLLOWS = 564;
	public static readonly FORALL = 565;
	public static readonly FORCE = 566;
	public static readonly FORCE_XML_QUERY_REWRITE = 567;
	public static readonly FOREIGN = 568;
	public static readonly FOREVER = 569;
	public static readonly FOR = 570;
	public static readonly FORMAT = 571;
	public static readonly FORWARD = 572;
	public static readonly FRAGMENT_NUMBER = 573;
	public static readonly FREELIST = 574;
	public static readonly FREELISTS = 575;
	public static readonly FREEPOOLS = 576;
	public static readonly FRESH = 577;
	public static readonly FROM = 578;
	public static readonly FROM_TZ = 579;
	public static readonly FULL = 580;
	public static readonly FULL_OUTER_JOIN_TO_OUTER = 581;
	public static readonly FUNCTION = 582;
	public static readonly FUNCTIONS = 583;
	public static readonly FTP = 584;
	public static readonly G_LETTER = 585;
	public static readonly GATHER_OPTIMIZER_STATISTICS = 586;
	public static readonly GATHER_PLAN_STATISTICS = 587;
	public static readonly GBY_CONC_ROLLUP = 588;
	public static readonly GBY_PUSHDOWN = 589;
	public static readonly GENERATED = 590;
	public static readonly GET = 591;
	public static readonly GLOBAL = 592;
	public static readonly GLOBALLY = 593;
	public static readonly GLOBAL_NAME = 594;
	public static readonly GLOBAL_TOPIC_ENABLED = 595;
	public static readonly GOTO = 596;
	public static readonly GRANT = 597;
	public static readonly GROUP_BY = 598;
	public static readonly GROUP = 599;
	public static readonly GROUP_ID = 600;
	public static readonly GROUPING = 601;
	public static readonly GROUPING_ID = 602;
	public static readonly GROUPS = 603;
	public static readonly GUARANTEED = 604;
	public static readonly GUARANTEE = 605;
	public static readonly GUARD = 606;
	public static readonly HALF_YEARS = 607;
	public static readonly HASH_AJ = 608;
	public static readonly HASH = 609;
	public static readonly HASHKEYS = 610;
	public static readonly HASH_SJ = 611;
	public static readonly HAVING = 612;
	public static readonly HEADER = 613;
	public static readonly HEAP = 614;
	public static readonly HELP = 615;
	public static readonly HEXTORAW = 616;
	public static readonly HEXTOREF = 617;
	public static readonly HIDDEN_KEYWORD = 618;
	public static readonly HIDE = 619;
	public static readonly HIER_ORDER = 620;
	public static readonly HIERARCHICAL = 621;
	public static readonly HIERARCHIES = 622;
	public static readonly HIERARCHY = 623;
	public static readonly HIGH = 624;
	public static readonly HINTSET_BEGIN = 625;
	public static readonly HINTSET_END = 626;
	public static readonly HOT = 627;
	public static readonly HOUR = 628;
	public static readonly HOURS = 629;
	public static readonly HTTP = 630;
	public static readonly HWM_BROKERED = 631;
	public static readonly HYBRID = 632;
	public static readonly H_LETTER = 633;
	public static readonly IDENTIFIED = 634;
	public static readonly IDENTIFIER = 635;
	public static readonly IDENTITY = 636;
	public static readonly IDGENERATORS = 637;
	public static readonly ID = 638;
	public static readonly IDLE_TIME = 639;
	public static readonly IF = 640;
	public static readonly IGNORE = 641;
	public static readonly IGNORE_OPTIM_EMBEDDED_HINTS = 642;
	public static readonly IGNORE_ROW_ON_DUPKEY_INDEX = 643;
	public static readonly IGNORE_WHERE_CLAUSE = 644;
	public static readonly ILM = 645;
	public static readonly IMMEDIATE = 646;
	public static readonly IMPACT = 647;
	public static readonly IMPORT = 648;
	public static readonly INACTIVE = 649;
	public static readonly INACTIVE_ACCOUNT_TIME = 650;
	public static readonly INCLUDE = 651;
	public static readonly INCLUDE_VERSION = 652;
	public static readonly INCLUDING = 653;
	public static readonly INCREMENTAL = 654;
	public static readonly INCREMENT = 655;
	public static readonly INCR = 656;
	public static readonly INDENT = 657;
	public static readonly INDEX_ASC = 658;
	public static readonly INDEX_COMBINE = 659;
	public static readonly INDEX_DESC = 660;
	public static readonly INDEXED = 661;
	public static readonly INDEXES = 662;
	public static readonly INDEX_FFS = 663;
	public static readonly INDEX_FILTER = 664;
	public static readonly INDEX = 665;
	public static readonly INDEXING = 666;
	public static readonly INDEX_JOIN = 667;
	public static readonly INDEX_ROWS = 668;
	public static readonly INDEX_RRS = 669;
	public static readonly INDEX_RS_ASC = 670;
	public static readonly INDEX_RS_DESC = 671;
	public static readonly INDEX_RS = 672;
	public static readonly INDEX_SCAN = 673;
	public static readonly INDEX_SKIP_SCAN = 674;
	public static readonly INDEX_SS_ASC = 675;
	public static readonly INDEX_SS_DESC = 676;
	public static readonly INDEX_SS = 677;
	public static readonly INDEX_STATS = 678;
	public static readonly INDEXTYPE = 679;
	public static readonly INDEXTYPES = 680;
	public static readonly INDICATOR = 681;
	public static readonly INDICES = 682;
	public static readonly INFINITE = 683;
	public static readonly INFORMATIONAL = 684;
	public static readonly INHERIT = 685;
	public static readonly IN = 686;
	public static readonly INITCAP = 687;
	public static readonly INITIAL = 688;
	public static readonly INITIALIZED = 689;
	public static readonly INITIALLY = 690;
	public static readonly INITRANS = 691;
	public static readonly INLINE = 692;
	public static readonly INLINE_XMLTYPE_NT = 693;
	public static readonly INMEMORY = 694;
	public static readonly IN_MEMORY_METADATA = 695;
	public static readonly INMEMORY_PRUNING = 696;
	public static readonly INNER = 697;
	public static readonly INOUT = 698;
	public static readonly INPLACE = 699;
	public static readonly INSERTCHILDXMLAFTER = 700;
	public static readonly INSERTCHILDXMLBEFORE = 701;
	public static readonly INSERTCHILDXML = 702;
	public static readonly INSERT = 703;
	public static readonly INSERTXMLAFTER = 704;
	public static readonly INSERTXMLBEFORE = 705;
	public static readonly INSTANCE = 706;
	public static readonly INSTANCES = 707;
	public static readonly INSTANTIABLE = 708;
	public static readonly INSTANTLY = 709;
	public static readonly INSTEAD = 710;
	public static readonly INSTR2 = 711;
	public static readonly INSTR4 = 712;
	public static readonly INSTRB = 713;
	public static readonly INSTRC = 714;
	public static readonly INSTR = 715;
	public static readonly INTEGER = 716;
	public static readonly INTERLEAVED = 717;
	public static readonly INTERMEDIATE = 718;
	public static readonly INTERNAL_CONVERT = 719;
	public static readonly INTERNAL_USE = 720;
	public static readonly INTERPRETED = 721;
	public static readonly INTERSECT = 722;
	public static readonly INTERVAL = 723;
	public static readonly INT = 724;
	public static readonly INTO = 725;
	public static readonly INVALIDATE = 726;
	public static readonly INVISIBLE = 727;
	public static readonly IN_XQUERY = 728;
	public static readonly IS = 729;
	public static readonly IS_LEAF = 730;
	public static readonly ISOLATION = 731;
	public static readonly ISOLATION_LEVEL = 732;
	public static readonly ITERATE = 733;
	public static readonly ITERATION_NUMBER = 734;
	public static readonly JAVA = 735;
	public static readonly JOB = 736;
	public static readonly JOIN = 737;
	public static readonly JSON_ARRAYAGG = 738;
	public static readonly JSON_ARRAY = 739;
	public static readonly JSON_EQUAL = 740;
	public static readonly JSON_EXISTS2 = 741;
	public static readonly JSON_EXISTS = 742;
	public static readonly JSONGET = 743;
	public static readonly JSON = 744;
	public static readonly JSON_OBJECTAGG = 745;
	public static readonly JSON_OBJECT = 746;
	public static readonly JSONPARSE = 747;
	public static readonly JSON_QUERY = 748;
	public static readonly JSON_SERIALIZE = 749;
	public static readonly JSON_TABLE = 750;
	public static readonly JSON_TEXTCONTAINS2 = 751;
	public static readonly JSON_TEXTCONTAINS = 752;
	public static readonly JSON_TRANSFORM = 753;
	public static readonly JSON_VALUE = 754;
	public static readonly K_LETTER = 755;
	public static readonly KEEP_DUPLICATES = 756;
	public static readonly KEEP = 757;
	public static readonly KERBEROS = 758;
	public static readonly KEY = 759;
	public static readonly KEY_LENGTH = 760;
	public static readonly KEYSIZE = 761;
	public static readonly KEYS = 762;
	public static readonly KEYSTORE = 763;
	public static readonly KILL = 764;
	public static readonly LABEL = 765;
	public static readonly LANGUAGE = 766;
	public static readonly LAST_DAY = 767;
	public static readonly LAST = 768;
	public static readonly LAST_VALUE = 769;
	public static readonly LATERAL = 770;
	public static readonly LAX = 771;
	public static readonly LAYER = 772;
	public static readonly LDAP_REGISTRATION_ENABLED = 773;
	public static readonly LDAP_REGISTRATION = 774;
	public static readonly LDAP_REG_SYNC_INTERVAL = 775;
	public static readonly LEAF = 776;
	public static readonly LEAD_CDB = 777;
	public static readonly LEAD_CDB_URI = 778;
	public static readonly LEADING = 779;
	public static readonly LEFT = 780;
	public static readonly LENGTH2 = 781;
	public static readonly LENGTH4 = 782;
	public static readonly LENGTHB = 783;
	public static readonly LENGTHC = 784;
	public static readonly LENGTH = 785;
	public static readonly LESS = 786;
	public static readonly LEVEL = 787;
	public static readonly LEVEL_NAME = 788;
	public static readonly LEVELS = 789;
	public static readonly LIBRARY = 790;
	public static readonly LIFECYCLE = 791;
	public static readonly LIFE = 792;
	public static readonly LIFETIME = 793;
	public static readonly LIKE2 = 794;
	public static readonly LIKE4 = 795;
	public static readonly LIKEC = 796;
	public static readonly LIKE_EXPAND = 797;
	public static readonly LIKE = 798;
	public static readonly LIMIT = 799;
	public static readonly LINEAR = 800;
	public static readonly LINK = 801;
	public static readonly LIST = 802;
	public static readonly LN = 803;
	public static readonly LNNVL = 804;
	public static readonly LOAD = 805;
	public static readonly LOB = 806;
	public static readonly LOBNVL = 807;
	public static readonly LOBS = 808;
	public static readonly LOCAL_INDEXES = 809;
	public static readonly LOCAL = 810;
	public static readonly LOCALTIME = 811;
	public static readonly LOCALTIMESTAMP = 812;
	public static readonly LOCATION = 813;
	public static readonly LOCATOR = 814;
	public static readonly LOCKDOWN = 815;
	public static readonly LOCKED = 816;
	public static readonly LOCKING = 817;
	public static readonly LOCK = 818;
	public static readonly LOGFILE = 819;
	public static readonly LOGFILES = 820;
	public static readonly LOGGING = 821;
	public static readonly LOGICAL = 822;
	public static readonly LOGICAL_READS_PER_CALL = 823;
	public static readonly LOGICAL_READS_PER_SESSION = 824;
	public static readonly LOG = 825;
	public static readonly LOGMINING = 826;
	public static readonly LOGOFF = 827;
	public static readonly LOGON = 828;
	public static readonly LOG_READ_ONLY_VIOLATIONS = 829;
	public static readonly LONG = 830;
	public static readonly LOOP = 831;
	public static readonly LOST = 832;
	public static readonly LOWER = 833;
	public static readonly LOW = 834;
	public static readonly LPAD = 835;
	public static readonly LTRIM = 836;
	public static readonly M_LETTER = 837;
	public static readonly MAIN = 838;
	public static readonly MAKE_REF = 839;
	public static readonly MANAGED = 840;
	public static readonly MANAGE = 841;
	public static readonly MANAGEMENT = 842;
	public static readonly MANAGER = 843;
	public static readonly MANDATORY = 844;
	public static readonly MANUAL = 845;
	public static readonly MAP = 846;
	public static readonly MAPPING = 847;
	public static readonly MASTER = 848;
	public static readonly MATCHED = 849;
	public static readonly MATCHES = 850;
	public static readonly MATCH = 851;
	public static readonly MATCH_NUMBER = 852;
	public static readonly MATCH_RECOGNIZE = 853;
	public static readonly MATERIALIZED = 854;
	public static readonly MATERIALIZE = 855;
	public static readonly MAXARCHLOGS = 856;
	public static readonly MAXDATAFILES = 857;
	public static readonly MAXEXTENTS = 858;
	public static readonly MAXIMIZE = 859;
	public static readonly MAXINSTANCES = 860;
	public static readonly MAXLOGFILES = 861;
	public static readonly MAXLOGHISTORY = 862;
	public static readonly MAXLOGMEMBERS = 863;
	public static readonly MAX_SHARED_TEMP_SIZE = 864;
	public static readonly MAXSIZE = 865;
	public static readonly MAXTRANS = 866;
	public static readonly MAXVALUE = 867;
	public static readonly MEASURE = 868;
	public static readonly MEASURES = 869;
	public static readonly MEDIUM = 870;
	public static readonly MEMBER = 871;
	public static readonly MEMBER_CAPTION = 872;
	public static readonly MEMBER_DESCRIPTION = 873;
	public static readonly MEMBER_NAME = 874;
	public static readonly MEMBER_UNIQUE_NAME = 875;
	public static readonly MEMCOMPRESS = 876;
	public static readonly MEMORY = 877;
	public static readonly MERGEACTIONS = 878;
	public static readonly MERGE_AJ = 879;
	public static readonly MERGE_CONST_ON = 880;
	public static readonly MERGE = 881;
	public static readonly MERGE_SJ = 882;
	public static readonly METADATA = 883;
	public static readonly METHOD = 884;
	public static readonly MIGRATE = 885;
	public static readonly MIGRATION = 886;
	public static readonly MINEXTENTS = 887;
	public static readonly MINIMIZE = 888;
	public static readonly MINIMUM = 889;
	public static readonly MINING = 890;
	public static readonly MINUS = 891;
	public static readonly MINUS_NULL = 892;
	public static readonly MINUTE = 893;
	public static readonly MINUTES = 894;
	public static readonly MINVALUE = 895;
	public static readonly MIRRORCOLD = 896;
	public static readonly MIRRORHOT = 897;
	public static readonly MIRROR = 898;
	public static readonly MISSING = 899;
	public static readonly MISMATCH = 900;
	public static readonly MLSLABEL = 901;
	public static readonly MODEL_COMPILE_SUBQUERY = 902;
	public static readonly MODEL_DONTVERIFY_UNIQUENESS = 903;
	public static readonly MODEL_DYNAMIC_SUBQUERY = 904;
	public static readonly MODEL_MIN_ANALYSIS = 905;
	public static readonly MODEL = 906;
	public static readonly MODEL_NB = 907;
	public static readonly MODEL_NO_ANALYSIS = 908;
	public static readonly MODEL_PBY = 909;
	public static readonly MODEL_PUSH_REF = 910;
	public static readonly MODEL_SV = 911;
	public static readonly MODE = 912;
	public static readonly MODIFICATION = 913;
	public static readonly MODIFY_COLUMN_TYPE = 914;
	public static readonly MODIFY = 915;
	public static readonly MOD = 916;
	public static readonly MODULE = 917;
	public static readonly MONITORING = 918;
	public static readonly MONITOR = 919;
	public static readonly MONTH = 920;
	public static readonly MONTHS_BETWEEN = 921;
	public static readonly MONTHS = 922;
	public static readonly MOUNT = 923;
	public static readonly MOUNTPATH = 924;
	public static readonly MOUNTPOINT = 925;
	public static readonly MOVEMENT = 926;
	public static readonly MOVE = 927;
	public static readonly MULTIDIMENSIONAL = 928;
	public static readonly MULTISET = 929;
	public static readonly MV_MERGE = 930;
	public static readonly NAMED = 931;
	public static readonly NAME = 932;
	public static readonly NAMESPACE = 933;
	public static readonly NAN = 934;
	public static readonly NANVL = 935;
	public static readonly NATIONAL = 936;
	public static readonly NATIVE_FULL_OUTER_JOIN = 937;
	public static readonly NATIVE = 938;
	public static readonly NATURAL = 939;
	public static readonly NATURALN = 940;
	public static readonly NAV = 941;
	public static readonly NCHAR_CS = 942;
	public static readonly NCHAR = 943;
	public static readonly NCHR = 944;
	public static readonly NCLOB = 945;
	public static readonly NEEDED = 946;
	public static readonly NEG = 947;
	public static readonly NESTED = 948;
	public static readonly NESTED_TABLE_FAST_INSERT = 949;
	public static readonly NESTED_TABLE_GET_REFS = 950;
	public static readonly NESTED_TABLE_ID = 951;
	public static readonly NESTED_TABLE_SET_REFS = 952;
	public static readonly NESTED_TABLE_SET_SETID = 953;
	public static readonly NETWORK = 954;
	public static readonly NEVER = 955;
	public static readonly NEW = 956;
	public static readonly NEW_TIME = 957;
	public static readonly NEXT_DAY = 958;
	public static readonly NEXT = 959;
	public static readonly NL_AJ = 960;
	public static readonly NLJ_BATCHING = 961;
	public static readonly NLJ_INDEX_FILTER = 962;
	public static readonly NLJ_INDEX_SCAN = 963;
	public static readonly NLJ_PREFETCH = 964;
	public static readonly NLS_CALENDAR = 965;
	public static readonly NLS_CHARACTERSET = 966;
	public static readonly NLS_CHARSET_DECL_LEN = 967;
	public static readonly NLS_CHARSET_ID = 968;
	public static readonly NLS_CHARSET_NAME = 969;
	public static readonly NLS_COMP = 970;
	public static readonly NLS_CURRENCY = 971;
	public static readonly NLS_DATE_FORMAT = 972;
	public static readonly NLS_DATE_LANGUAGE = 973;
	public static readonly NLS_INITCAP = 974;
	public static readonly NLS_ISO_CURRENCY = 975;
	public static readonly NL_SJ = 976;
	public static readonly NLS_LANG = 977;
	public static readonly NLS_LANGUAGE = 978;
	public static readonly NLS_LENGTH_SEMANTICS = 979;
	public static readonly NLS_LOWER = 980;
	public static readonly NLS_NCHAR_CONV_EXCP = 981;
	public static readonly NLS_NUMERIC_CHARACTERS = 982;
	public static readonly NLS_SORT = 983;
	public static readonly NLSSORT = 984;
	public static readonly NLS_SPECIAL_CHARS = 985;
	public static readonly NLS_TERRITORY = 986;
	public static readonly NLS_UPPER = 987;
	public static readonly NO_ACCESS = 988;
	public static readonly NO_ADAPTIVE_PLAN = 989;
	public static readonly NO_ANSI_REARCH = 990;
	public static readonly NOAPPEND = 991;
	public static readonly NOARCHIVELOG = 992;
	public static readonly NOAUDIT = 993;
	public static readonly NO_AUTO_REOPTIMIZE = 994;
	public static readonly NO_BASETABLE_MULTIMV_REWRITE = 995;
	public static readonly NO_BATCH_TABLE_ACCESS_BY_ROWID = 996;
	public static readonly NO_BIND_AWARE = 997;
	public static readonly NO_BUFFER = 998;
	public static readonly NOCACHE = 999;
	public static readonly NO_CARTESIAN = 1000;
	public static readonly NO_CHECK_ACL_REWRITE = 1001;
	public static readonly NO_CLUSTER_BY_ROWID = 1002;
	public static readonly NO_CLUSTERING = 1003;
	public static readonly NO_COALESCE_SQ = 1004;
	public static readonly NO_COMMON_DATA = 1005;
	public static readonly NOCOMPRESS = 1006;
	public static readonly NO_CONNECT_BY_CB_WHR_ONLY = 1007;
	public static readonly NO_CONNECT_BY_COMBINE_SW = 1008;
	public static readonly NO_CONNECT_BY_COST_BASED = 1009;
	public static readonly NO_CONNECT_BY_ELIM_DUPS = 1010;
	public static readonly NO_CONNECT_BY_FILTERING = 1011;
	public static readonly NOCOPY = 1012;
	public static readonly NO_COST_XML_QUERY_REWRITE = 1013;
	public static readonly NO_CPU_COSTING = 1014;
	public static readonly NOCPU_COSTING = 1015;
	public static readonly NOCYCLE = 1016;
	public static readonly NO_DATA_SECURITY_REWRITE = 1017;
	public static readonly NO_DECORRELATE = 1018;
	public static readonly NODELAY = 1019;
	public static readonly NO_DOMAIN_INDEX_FILTER = 1020;
	public static readonly NO_DST_UPGRADE_INSERT_CONV = 1021;
	public static readonly NO_ELIM_GROUPBY = 1022;
	public static readonly NO_ELIMINATE_JOIN = 1023;
	public static readonly NO_ELIMINATE_OBY = 1024;
	public static readonly NO_ELIMINATE_OUTER_JOIN = 1025;
	public static readonly NOENTITYESCAPING = 1026;
	public static readonly NO_EXPAND_GSET_TO_UNION = 1027;
	public static readonly NO_EXPAND = 1028;
	public static readonly NO_EXPAND_TABLE = 1029;
	public static readonly NO_FACT = 1030;
	public static readonly NO_FACTORIZE_JOIN = 1031;
	public static readonly NO_FILTERING = 1032;
	public static readonly NOFORCE = 1033;
	public static readonly NO_FULL_OUTER_JOIN_TO_OUTER = 1034;
	public static readonly NO_GATHER_OPTIMIZER_STATISTICS = 1035;
	public static readonly NO_GBY_PUSHDOWN = 1036;
	public static readonly NOGUARANTEE = 1037;
	public static readonly NO_INDEX_FFS = 1038;
	public static readonly NO_INDEX = 1039;
	public static readonly NO_INDEX_SS = 1040;
	public static readonly NO_INMEMORY = 1041;
	public static readonly NO_INMEMORY_PRUNING = 1042;
	public static readonly NOKEEP = 1043;
	public static readonly NO_LOAD = 1044;
	public static readonly NOLOCAL = 1045;
	public static readonly NOLOGGING = 1046;
	public static readonly NOMAPPING = 1047;
	public static readonly NOMAXVALUE = 1048;
	public static readonly NO_MERGE = 1049;
	public static readonly NOMINIMIZE = 1050;
	public static readonly NOMINVALUE = 1051;
	public static readonly NO_MODEL_PUSH_REF = 1052;
	public static readonly NO_MONITORING = 1053;
	public static readonly NOMONITORING = 1054;
	public static readonly NO_MONITOR = 1055;
	public static readonly NO_MULTIMV_REWRITE = 1056;
	public static readonly NO_NATIVE_FULL_OUTER_JOIN = 1057;
	public static readonly NONBLOCKING = 1058;
	public static readonly NONEDITIONABLE = 1059;
	public static readonly NONE = 1060;
	public static readonly NO_NLJ_BATCHING = 1061;
	public static readonly NO_NLJ_PREFETCH = 1062;
	public static readonly NO = 1063;
	public static readonly NONSCHEMA = 1064;
	public static readonly NO_OBJECT_LINK = 1065;
	public static readonly NOORDER = 1066;
	public static readonly NO_ORDER_ROLLUPS = 1067;
	public static readonly NO_OUTER_JOIN_TO_ANTI = 1068;
	public static readonly NO_OUTER_JOIN_TO_INNER = 1069;
	public static readonly NOOVERRIDE = 1070;
	public static readonly NO_PARALLEL_INDEX = 1071;
	public static readonly NOPARALLEL_INDEX = 1072;
	public static readonly NO_PARALLEL = 1073;
	public static readonly NOPARALLEL = 1074;
	public static readonly NO_PARTIAL_COMMIT = 1075;
	public static readonly NO_PARTIAL_JOIN = 1076;
	public static readonly NO_PARTIAL_ROLLUP_PUSHDOWN = 1077;
	public static readonly NOPARTITION = 1078;
	public static readonly NO_PLACE_DISTINCT = 1079;
	public static readonly NO_PLACE_GROUP_BY = 1080;
	public static readonly NO_PQ_CONCURRENT_UNION = 1081;
	public static readonly NO_PQ_MAP = 1082;
	public static readonly NOPROMPT = 1083;
	public static readonly NO_PQ_REPLICATE = 1084;
	public static readonly NO_PQ_SKEW = 1085;
	public static readonly NO_PRUNE_GSETS = 1086;
	public static readonly NO_PULL_PRED = 1087;
	public static readonly NO_PUSH_PRED = 1088;
	public static readonly NO_PUSH_SUBQ = 1089;
	public static readonly NO_PX_FAULT_TOLERANCE = 1090;
	public static readonly NO_PX_JOIN_FILTER = 1091;
	public static readonly NO_QKN_BUFF = 1092;
	public static readonly NO_QUERY_TRANSFORMATION = 1093;
	public static readonly NO_REF_CASCADE = 1094;
	public static readonly NORELOCATE = 1095;
	public static readonly NORELY = 1096;
	public static readonly NOREPAIR = 1097;
	public static readonly NOREPLAY = 1098;
	public static readonly NORESETLOGS = 1099;
	public static readonly NO_RESULT_CACHE = 1100;
	public static readonly NOREVERSE = 1101;
	public static readonly NO_REWRITE = 1102;
	public static readonly NOREWRITE = 1103;
	public static readonly NORMAL = 1104;
	public static readonly NO_ROOT_SW_FOR_LOCAL = 1105;
	public static readonly NOROWDEPENDENCIES = 1106;
	public static readonly NOSCHEMACHECK = 1107;
	public static readonly NOSEGMENT = 1108;
	public static readonly NO_SEMIJOIN = 1109;
	public static readonly NO_SEMI_TO_INNER = 1110;
	public static readonly NO_SET_TO_JOIN = 1111;
	public static readonly NOSORT = 1112;
	public static readonly NO_SQL_TRANSLATION = 1113;
	public static readonly NO_SQL_TUNE = 1114;
	public static readonly NO_STAR_TRANSFORMATION = 1115;
	public static readonly NO_STATEMENT_QUEUING = 1116;
	public static readonly NO_STATS_GSETS = 1117;
	public static readonly NOSTRICT = 1118;
	public static readonly NO_SUBQUERY_PRUNING = 1119;
	public static readonly NO_SUBSTRB_PAD = 1120;
	public static readonly NO_SWAP_JOIN_INPUTS = 1121;
	public static readonly NOSWITCH = 1122;
	public static readonly NO_TABLE_LOOKUP_BY_NL = 1123;
	public static readonly NO_TEMP_TABLE = 1124;
	public static readonly NOTHING = 1125;
	public static readonly NOTIFICATION = 1126;
	public static readonly NOT = 1127;
	public static readonly NO_TRANSFORM_DISTINCT_AGG = 1128;
	public static readonly NO_UNNEST = 1129;
	public static readonly NO_USE_CUBE = 1130;
	public static readonly NO_USE_HASH_AGGREGATION = 1131;
	public static readonly NO_USE_HASH_GBY_FOR_PUSHDOWN = 1132;
	public static readonly NO_USE_HASH = 1133;
	public static readonly NO_USE_INVISIBLE_INDEXES = 1134;
	public static readonly NO_USE_MERGE = 1135;
	public static readonly NO_USE_NL = 1136;
	public static readonly NO_USE_VECTOR_AGGREGATION = 1137;
	public static readonly NOVALIDATE = 1138;
	public static readonly NO_VECTOR_TRANSFORM_DIMS = 1139;
	public static readonly NO_VECTOR_TRANSFORM_FACT = 1140;
	public static readonly NO_VECTOR_TRANSFORM = 1141;
	public static readonly NOWAIT = 1142;
	public static readonly NO_XDB_FASTPATH_INSERT = 1143;
	public static readonly NO_XML_DML_REWRITE = 1144;
	public static readonly NO_XMLINDEX_REWRITE_IN_SELECT = 1145;
	public static readonly NO_XMLINDEX_REWRITE = 1146;
	public static readonly NO_XML_QUERY_REWRITE = 1147;
	public static readonly NO_ZONEMAP = 1148;
	public static readonly NTH_VALUE = 1149;
	public static readonly NULLIF = 1150;
	public static readonly NULL_ = 1151;
	public static readonly NULLS = 1152;
	public static readonly NUMBER = 1153;
	public static readonly NUMERIC = 1154;
	public static readonly NUM_INDEX_KEYS = 1155;
	public static readonly NUMTODSINTERVAL = 1156;
	public static readonly NUMTOYMINTERVAL = 1157;
	public static readonly NVARCHAR2 = 1158;
	public static readonly NVL2 = 1159;
	public static readonly OBJECT2XML = 1160;
	public static readonly OBJECT = 1161;
	public static readonly OBJ_ID = 1162;
	public static readonly OBJNO = 1163;
	public static readonly OBJNO_REUSE = 1164;
	public static readonly OCCURENCES = 1165;
	public static readonly OFFLINE = 1166;
	public static readonly OFF = 1167;
	public static readonly OFFSET = 1168;
	public static readonly OF = 1169;
	public static readonly OIDINDEX = 1170;
	public static readonly OID = 1171;
	public static readonly OLAP = 1172;
	public static readonly OLD = 1173;
	public static readonly OLD_PUSH_PRED = 1174;
	public static readonly OLS = 1175;
	public static readonly OLTP = 1176;
	public static readonly OMIT = 1177;
	public static readonly ONE = 1178;
	public static readonly ONLINE = 1179;
	public static readonly ONLINELOG = 1180;
	public static readonly ONLY = 1181;
	public static readonly ON = 1182;
	public static readonly OPAQUE = 1183;
	public static readonly OPAQUE_TRANSFORM = 1184;
	public static readonly OPAQUE_XCANONICAL = 1185;
	public static readonly OPCODE = 1186;
	public static readonly OPEN = 1187;
	public static readonly OPERATIONS = 1188;
	public static readonly OPERATOR = 1189;
	public static readonly OPT_ESTIMATE = 1190;
	public static readonly OPTIMAL = 1191;
	public static readonly OPTIMIZE = 1192;
	public static readonly OPTIMIZER_FEATURES_ENABLE = 1193;
	public static readonly OPTIMIZER_GOAL = 1194;
	public static readonly OPTION = 1195;
	public static readonly OPT_PARAM = 1196;
	public static readonly ORA_BRANCH = 1197;
	public static readonly ORA_CHECK_ACL = 1198;
	public static readonly ORA_CHECK_PRIVILEGE = 1199;
	public static readonly ORA_CLUSTERING = 1200;
	public static readonly ORADATA = 1201;
	public static readonly ORADEBUG = 1202;
	public static readonly ORA_DST_AFFECTED = 1203;
	public static readonly ORA_DST_CONVERT = 1204;
	public static readonly ORA_DST_ERROR = 1205;
	public static readonly ORA_GET_ACLIDS = 1206;
	public static readonly ORA_GET_PRIVILEGES = 1207;
	public static readonly ORA_HASH = 1208;
	public static readonly ORA_INVOKING_USERID = 1209;
	public static readonly ORA_INVOKING_USER = 1210;
	public static readonly ORA_INVOKING_XS_USER_GUID = 1211;
	public static readonly ORA_INVOKING_XS_USER = 1212;
	public static readonly ORA_RAWCOMPARE = 1213;
	public static readonly ORA_RAWCONCAT = 1214;
	public static readonly ORA_ROWSCN = 1215;
	public static readonly ORA_ROWSCN_RAW = 1216;
	public static readonly ORA_ROWVERSION = 1217;
	public static readonly ORA_TABVERSION = 1218;
	public static readonly ORA_WRITE_TIME = 1219;
	public static readonly ORDERED = 1220;
	public static readonly ORDERED_PREDICATES = 1221;
	public static readonly ORDER = 1222;
	public static readonly ORDINALITY = 1223;
	public static readonly OR_EXPAND = 1224;
	public static readonly ORGANIZATION = 1225;
	public static readonly OR = 1226;
	public static readonly OR_PREDICATES = 1227;
	public static readonly OSERROR = 1228;
	public static readonly OTHER = 1229;
	public static readonly OUTER_JOIN_TO_ANTI = 1230;
	public static readonly OUTER_JOIN_TO_INNER = 1231;
	public static readonly OUTER = 1232;
	public static readonly OUTLINE_LEAF = 1233;
	public static readonly OUTLINE = 1234;
	public static readonly OUT_OF_LINE = 1235;
	public static readonly OUT = 1236;
	public static readonly OVERFLOW_NOMOVE = 1237;
	public static readonly OVERFLOW = 1238;
	public static readonly OVERLAPS = 1239;
	public static readonly OVER = 1240;
	public static readonly OVERRIDING = 1241;
	public static readonly OWNER = 1242;
	public static readonly OWNERSHIP = 1243;
	public static readonly OWN = 1244;
	public static readonly P_LETTER = 1245;
	public static readonly PACKAGE = 1246;
	public static readonly PACKAGES = 1247;
	public static readonly PARALLEL_ENABLE = 1248;
	public static readonly PARALLEL_INDEX = 1249;
	public static readonly PARALLEL = 1250;
	public static readonly PARAMETERFILE = 1251;
	public static readonly PARAMETERS = 1252;
	public static readonly PARAM = 1253;
	public static readonly PARENT = 1254;
	public static readonly PARENT_LEVEL_NAME = 1255;
	public static readonly PARENT_UNIQUE_NAME = 1256;
	public static readonly PARITY = 1257;
	public static readonly PARTIAL_JOIN = 1258;
	public static readonly PARTIALLY = 1259;
	public static readonly PARTIAL = 1260;
	public static readonly PARTIAL_ROLLUP_PUSHDOWN = 1261;
	public static readonly PARTITION_HASH = 1262;
	public static readonly PARTITION_LIST = 1263;
	public static readonly PARTITION = 1264;
	public static readonly PARTITION_RANGE = 1265;
	public static readonly PARTITIONS = 1266;
	public static readonly PARTNUMINST = 1267;
	public static readonly PASSING = 1268;
	public static readonly PASSWORD_GRACE_TIME = 1269;
	public static readonly PASSWORD_LIFE_TIME = 1270;
	public static readonly PASSWORD_LOCK_TIME = 1271;
	public static readonly PASSWORD = 1272;
	public static readonly PASSWORD_REUSE_MAX = 1273;
	public static readonly PASSWORD_REUSE_TIME = 1274;
	public static readonly PASSWORD_ROLLOVER_TIME = 1275;
	public static readonly PASSWORD_VERIFY_FUNCTION = 1276;
	public static readonly PAST = 1277;
	public static readonly PATCH = 1278;
	public static readonly PATH = 1279;
	public static readonly PATH_PREFIX = 1280;
	public static readonly PATHS = 1281;
	public static readonly PATTERN = 1282;
	public static readonly PBL_HS_BEGIN = 1283;
	public static readonly PBL_HS_END = 1284;
	public static readonly PCTFREE = 1285;
	public static readonly PCTINCREASE = 1286;
	public static readonly PCTTHRESHOLD = 1287;
	public static readonly PCTUSED = 1288;
	public static readonly PCTVERSION = 1289;
	public static readonly PENDING = 1290;
	public static readonly PERCENT_FOUND = 1291;
	public static readonly PERCENT_ISOPEN = 1292;
	public static readonly PERCENT_NOTFOUND = 1293;
	public static readonly PERCENT_KEYWORD = 1294;
	public static readonly PERCENT_RANKM = 1295;
	public static readonly PERCENT_ROWCOUNT = 1296;
	public static readonly PERCENT_ROWTYPE = 1297;
	public static readonly PERCENT_TYPE = 1298;
	public static readonly PERFORMANCE = 1299;
	public static readonly PERIOD_KEYWORD = 1300;
	public static readonly PERMANENT = 1301;
	public static readonly PERMISSION = 1302;
	public static readonly PERMUTE = 1303;
	public static readonly PER = 1304;
	public static readonly PFILE = 1305;
	public static readonly PHYSICAL = 1306;
	public static readonly PIKEY = 1307;
	public static readonly PIPELINED = 1308;
	public static readonly PIPE = 1309;
	public static readonly PIV_GB = 1310;
	public static readonly PIVOT = 1311;
	public static readonly PIV_SSF = 1312;
	public static readonly PLACE_DISTINCT = 1313;
	public static readonly PLACE_GROUP_BY = 1314;
	public static readonly PLAN = 1315;
	public static readonly PLSCOPE_SETTINGS = 1316;
	public static readonly PLS_INTEGER = 1317;
	public static readonly PLSQL_CCFLAGS = 1318;
	public static readonly PLSQL_CODE_TYPE = 1319;
	public static readonly PLSQL_DEBUG = 1320;
	public static readonly PLSQL_OPTIMIZE_LEVEL = 1321;
	public static readonly PLSQL_WARNINGS = 1322;
	public static readonly PLUGGABLE = 1323;
	public static readonly PMEM = 1324;
	public static readonly POINT = 1325;
	public static readonly POLICY = 1326;
	public static readonly POOL_16K = 1327;
	public static readonly POOL_2K = 1328;
	public static readonly POOL_32K = 1329;
	public static readonly POOL_4K = 1330;
	public static readonly POOL_8K = 1331;
	public static readonly POSITIVEN = 1332;
	public static readonly POSITIVE = 1333;
	public static readonly POST_TRANSACTION = 1334;
	public static readonly POWERMULTISET_BY_CARDINALITY = 1335;
	public static readonly POWERMULTISET = 1336;
	public static readonly POWER = 1337;
	public static readonly PQ_CONCURRENT_UNION = 1338;
	public static readonly PQ_DISTRIBUTE = 1339;
	public static readonly PQ_DISTRIBUTE_WINDOW = 1340;
	public static readonly PQ_FILTER = 1341;
	public static readonly PQ_MAP = 1342;
	public static readonly PQ_NOMAP = 1343;
	public static readonly PQ_REPLICATE = 1344;
	public static readonly PQ_SKEW = 1345;
	public static readonly PRAGMA = 1346;
	public static readonly PREBUILT = 1347;
	public static readonly PRECEDES = 1348;
	public static readonly PRECEDING = 1349;
	public static readonly PRECISION = 1350;
	public static readonly PRECOMPUTE_SUBQUERY = 1351;
	public static readonly PREDICATE_REORDERS = 1352;
	public static readonly PRELOAD = 1353;
	public static readonly PREPARE = 1354;
	public static readonly PRESENTNNV = 1355;
	public static readonly PRESENT = 1356;
	public static readonly PRESENTV = 1357;
	public static readonly PRESERVE_OID = 1358;
	public static readonly PRESERVE = 1359;
	public static readonly PRETTY = 1360;
	public static readonly PREVIOUS = 1361;
	public static readonly PREV = 1362;
	public static readonly PRIMARY = 1363;
	public static readonly PRINTBLOBTOCLOB = 1364;
	public static readonly PRIORITY = 1365;
	public static readonly PRIOR = 1366;
	public static readonly PRIVATE = 1367;
	public static readonly PRIVATE_SGA = 1368;
	public static readonly PRIVILEGED = 1369;
	public static readonly PRIVILEGE = 1370;
	public static readonly PRIVILEGES = 1371;
	public static readonly PROCEDURAL = 1372;
	public static readonly PROCEDURE = 1373;
	public static readonly PROCESS = 1374;
	public static readonly PROFILE = 1375;
	public static readonly PROGRAM = 1376;
	public static readonly PROJECT = 1377;
	public static readonly PROPAGATE = 1378;
	public static readonly PROPERTY = 1379;
	public static readonly PROTECTED = 1380;
	public static readonly PROTECTION = 1381;
	public static readonly PROTOCOL = 1382;
	public static readonly PROXY = 1383;
	public static readonly PRUNING = 1384;
	public static readonly PUBLIC = 1385;
	public static readonly PULL_PRED = 1386;
	public static readonly PURGE = 1387;
	public static readonly PUSH_PRED = 1388;
	public static readonly PUSH_SUBQ = 1389;
	public static readonly PX_FAULT_TOLERANCE = 1390;
	public static readonly PX_GRANULE = 1391;
	public static readonly PX_JOIN_FILTER = 1392;
	public static readonly QB_NAME = 1393;
	public static readonly QUARTERS = 1394;
	public static readonly QUERY_BLOCK = 1395;
	public static readonly QUERY = 1396;
	public static readonly QUEUE_CURR = 1397;
	public static readonly QUEUE = 1398;
	public static readonly QUEUE_ROWP = 1399;
	public static readonly QUIESCE = 1400;
	public static readonly QUORUM = 1401;
	public static readonly QUOTA = 1402;
	public static readonly QUOTAGROUP = 1403;
	public static readonly RAISE = 1404;
	public static readonly RANDOM_LOCAL = 1405;
	public static readonly RANDOM = 1406;
	public static readonly RANGE = 1407;
	public static readonly RANKM = 1408;
	public static readonly RAPIDLY = 1409;
	public static readonly RAW = 1410;
	public static readonly RAWTOHEX = 1411;
	public static readonly RAWTONHEX = 1412;
	public static readonly RBA = 1413;
	public static readonly RBO_OUTLINE = 1414;
	public static readonly RDBA = 1415;
	public static readonly READ = 1416;
	public static readonly READS = 1417;
	public static readonly REALM = 1418;
	public static readonly REAL = 1419;
	public static readonly REBALANCE = 1420;
	public static readonly REBUILD = 1421;
	public static readonly RECORD = 1422;
	public static readonly RECORDS_PER_BLOCK = 1423;
	public static readonly RECOVERABLE = 1424;
	public static readonly RECOVER = 1425;
	public static readonly RECOVERY = 1426;
	public static readonly RECYCLEBIN = 1427;
	public static readonly RECYCLE = 1428;
	public static readonly REDACTION = 1429;
	public static readonly REDEFINE = 1430;
	public static readonly REDO = 1431;
	public static readonly REDUCED = 1432;
	public static readonly REDUNDANCY = 1433;
	public static readonly REF_CASCADE_CURSOR = 1434;
	public static readonly REFERENCED = 1435;
	public static readonly REFERENCE = 1436;
	public static readonly REFERENCES = 1437;
	public static readonly REFERENCING = 1438;
	public static readonly REF = 1439;
	public static readonly REFRESH = 1440;
	public static readonly REFTOHEX = 1441;
	public static readonly REGEXP_COUNT = 1442;
	public static readonly REGEXP_INSTR = 1443;
	public static readonly REGEXP_LIKE = 1444;
	public static readonly REGEXP_REPLACE = 1445;
	public static readonly REGEXP_SUBSTR = 1446;
	public static readonly REGISTER = 1447;
	public static readonly REGR_AVGX = 1448;
	public static readonly REGR_AVGY = 1449;
	public static readonly REGR_COUNT = 1450;
	public static readonly REGR_INTERCEPT = 1451;
	public static readonly REGR_R2 = 1452;
	public static readonly REGR_SLOPE = 1453;
	public static readonly REGR_SXX = 1454;
	public static readonly REGR_SXY = 1455;
	public static readonly REGR_SYY = 1456;
	public static readonly REGULAR = 1457;
	public static readonly REJECT = 1458;
	public static readonly REKEY = 1459;
	public static readonly RELATIONAL = 1460;
	public static readonly RELIES_ON = 1461;
	public static readonly RELOCATE = 1462;
	public static readonly RELY = 1463;
	public static readonly REMAINDER = 1464;
	public static readonly REMOTE = 1465;
	public static readonly REMOTE_MAPPED = 1466;
	public static readonly REMOVE = 1467;
	public static readonly RENAME = 1468;
	public static readonly REPAIR = 1469;
	public static readonly REPEAT = 1470;
	public static readonly REPLACE = 1471;
	public static readonly REPLICATION = 1472;
	public static readonly REQUIRED = 1473;
	public static readonly RESETLOGS = 1474;
	public static readonly RESET = 1475;
	public static readonly RESIZE = 1476;
	public static readonly RESOLVE = 1477;
	public static readonly RESOLVER = 1478;
	public static readonly RESOURCE = 1479;
	public static readonly RESPECT = 1480;
	public static readonly RESTART = 1481;
	public static readonly RESTORE_AS_INTERVALS = 1482;
	public static readonly RESTORE = 1483;
	public static readonly RESTRICT_ALL_REF_CONS = 1484;
	public static readonly RESTRICTED = 1485;
	public static readonly RESTRICT_REFERENCES = 1486;
	public static readonly RESTRICT = 1487;
	public static readonly RESULT_CACHE = 1488;
	public static readonly RESULT = 1489;
	public static readonly RESUMABLE = 1490;
	public static readonly RESUME = 1491;
	public static readonly RETENTION = 1492;
	public static readonly RETRY_ON_ROW_CHANGE = 1493;
	public static readonly RETURNING = 1494;
	public static readonly RETURN = 1495;
	public static readonly REUSE = 1496;
	public static readonly REVERSE = 1497;
	public static readonly REVOKE = 1498;
	public static readonly REWRITE_OR_ERROR = 1499;
	public static readonly REWRITE = 1500;
	public static readonly RIGHT = 1501;
	public static readonly ROLE = 1502;
	public static readonly ROLESET = 1503;
	public static readonly ROLES = 1504;
	public static readonly ROLLBACK = 1505;
	public static readonly ROLLING = 1506;
	public static readonly ROLLUP = 1507;
	public static readonly ROWDEPENDENCIES = 1508;
	public static readonly ROWID_MAPPING_TABLE = 1509;
	public static readonly ROWID = 1510;
	public static readonly ROWIDTOCHAR = 1511;
	public static readonly ROWIDTONCHAR = 1512;
	public static readonly ROW_LENGTH = 1513;
	public static readonly ROWNUM = 1514;
	public static readonly ROW = 1515;
	public static readonly ROWS = 1516;
	public static readonly RPAD = 1517;
	public static readonly RTRIM = 1518;
	public static readonly RULE = 1519;
	public static readonly RULES = 1520;
	public static readonly RUNNING = 1521;
	public static readonly SALT = 1522;
	public static readonly SAMPLE = 1523;
	public static readonly SAVE_AS_INTERVALS = 1524;
	public static readonly SAVEPOINT = 1525;
	public static readonly SAVE = 1526;
	public static readonly SB4 = 1527;
	public static readonly SCALE_ROWS = 1528;
	public static readonly SCALE = 1529;
	public static readonly SCAN_INSTANCES = 1530;
	public static readonly SCAN = 1531;
	public static readonly SCHEDULER = 1532;
	public static readonly SCHEMACHECK = 1533;
	public static readonly SCHEMA = 1534;
	public static readonly SCN_ASCENDING = 1535;
	public static readonly SCN = 1536;
	public static readonly SCOPE = 1537;
	public static readonly SCRUB = 1538;
	public static readonly SD_ALL = 1539;
	public static readonly SD_INHIBIT = 1540;
	public static readonly SDO_GEOM_MBR = 1541;
	public static readonly SDO_GEOMETRY = 1542;
	public static readonly SD_SHOW = 1543;
	public static readonly SEARCH = 1544;
	public static readonly SECOND = 1545;
	public static readonly SECONDS = 1546;
	public static readonly SECRET = 1547;
	public static readonly SECUREFILE_DBA = 1548;
	public static readonly SECUREFILE = 1549;
	public static readonly SECURITY = 1550;
	public static readonly SEED = 1551;
	public static readonly SEG_BLOCK = 1552;
	public static readonly SEG_FILE = 1553;
	public static readonly SEGMENT = 1554;
	public static readonly SELECTIVITY = 1555;
	public static readonly SELECT = 1556;
	public static readonly SELF = 1557;
	public static readonly SEMIJOIN_DRIVER = 1558;
	public static readonly SEMIJOIN = 1559;
	public static readonly SEMI_TO_INNER = 1560;
	public static readonly SEQUENCED = 1561;
	public static readonly SEQUENCE = 1562;
	public static readonly SEQUENTIAL = 1563;
	public static readonly SEQ = 1564;
	public static readonly SERIALIZABLE = 1565;
	public static readonly SERIALLY_REUSABLE = 1566;
	public static readonly SERIAL = 1567;
	public static readonly SERVERERROR = 1568;
	public static readonly SERVICE_NAME_CONVERT = 1569;
	public static readonly SERVICE = 1570;
	public static readonly SERVICES = 1571;
	public static readonly SESSION_CACHED_CURSORS = 1572;
	public static readonly SESSION = 1573;
	public static readonly SESSIONS_PER_USER = 1574;
	public static readonly SESSIONTIMEZONE = 1575;
	public static readonly SESSIONTZNAME = 1576;
	public static readonly SET = 1577;
	public static readonly SETS = 1578;
	public static readonly SETTINGS = 1579;
	public static readonly SET_TO_JOIN = 1580;
	public static readonly SEVERE = 1581;
	public static readonly SHARDSPACE = 1582;
	public static readonly SHARED_POOL = 1583;
	public static readonly SHARED = 1584;
	public static readonly SHARE = 1585;
	public static readonly SHARING = 1586;
	public static readonly SHELFLIFE = 1587;
	public static readonly SHOW = 1588;
	public static readonly SHRINK = 1589;
	public static readonly SHUTDOWN = 1590;
	public static readonly SIBLINGS = 1591;
	public static readonly SID = 1592;
	public static readonly SITE = 1593;
	public static readonly SIGNAL_COMPONENT = 1594;
	public static readonly SIGNAL_FUNCTION = 1595;
	public static readonly SIGN = 1596;
	public static readonly SIGNTYPE = 1597;
	public static readonly SIMPLE_INTEGER = 1598;
	public static readonly SIMPLE = 1599;
	public static readonly SINGLE = 1600;
	public static readonly SINGLETASK = 1601;
	public static readonly SINH = 1602;
	public static readonly SIN = 1603;
	public static readonly SIZE = 1604;
	public static readonly SKIP_EXT_OPTIMIZER = 1605;
	public static readonly SKIP_ = 1606;
	public static readonly SKIP_UNQ_UNUSABLE_IDX = 1607;
	public static readonly SKIP_UNUSABLE_INDEXES = 1608;
	public static readonly SMALLFILE = 1609;
	public static readonly SMALLINT = 1610;
	public static readonly SNAPSHOT = 1611;
	public static readonly SOME = 1612;
	public static readonly SORT = 1613;
	public static readonly SOUNDEX = 1614;
	public static readonly SOURCE_FILE_DIRECTORY = 1615;
	public static readonly SOURCE_FILE_NAME_CONVERT = 1616;
	public static readonly SOURCE = 1617;
	public static readonly SPACE_KEYWORD = 1618;
	public static readonly SPECIFICATION = 1619;
	public static readonly SPFILE = 1620;
	public static readonly SPLIT = 1621;
	public static readonly SPREADSHEET = 1622;
	public static readonly SQLDATA = 1623;
	public static readonly SQLERROR = 1624;
	public static readonly SQLLDR = 1625;
	public static readonly SQL = 1626;
	public static readonly FILE_EXT = 1627;
	public static readonly SQL_MACRO = 1628;
	public static readonly SQL_TRACE = 1629;
	public static readonly SQL_TRANSLATION_PROFILE = 1630;
	public static readonly SQRT = 1631;
	public static readonly STALE = 1632;
	public static readonly STANDALONE = 1633;
	public static readonly STANDARD = 1634;
	public static readonly STANDARD_HASH = 1635;
	public static readonly STANDBY_MAX_DATA_DELAY = 1636;
	public static readonly STANDBYS = 1637;
	public static readonly STANDBY = 1638;
	public static readonly STAR = 1639;
	public static readonly STAR_TRANSFORMATION = 1640;
	public static readonly START = 1641;
	public static readonly STARTUP = 1642;
	public static readonly STATEMENT_ID = 1643;
	public static readonly STATEMENT_QUEUING = 1644;
	public static readonly STATEMENTS = 1645;
	public static readonly STATEMENT = 1646;
	public static readonly STATE = 1647;
	public static readonly STATIC = 1648;
	public static readonly STATISTICS = 1649;
	public static readonly STATS_BINOMIAL_TEST = 1650;
	public static readonly STATS_CROSSTAB = 1651;
	public static readonly STATS_F_TEST = 1652;
	public static readonly STATS_KS_TEST = 1653;
	public static readonly STATS_MODE = 1654;
	public static readonly STATS_MW_TEST = 1655;
	public static readonly STATS_ONE_WAY_ANOVA = 1656;
	public static readonly STATS_T_TEST_INDEP = 1657;
	public static readonly STATS_T_TEST_INDEPU = 1658;
	public static readonly STATS_T_TEST_ONE = 1659;
	public static readonly STATS_T_TEST_PAIRED = 1660;
	public static readonly STATS_WSR_TEST = 1661;
	public static readonly STDDEV_POP = 1662;
	public static readonly STDDEV_SAMP = 1663;
	public static readonly STOP = 1664;
	public static readonly STORAGE = 1665;
	public static readonly STORE = 1666;
	public static readonly STREAMS = 1667;
	public static readonly STREAM = 1668;
	public static readonly STRICT = 1669;
	public static readonly STRING = 1670;
	public static readonly STRIPE_COLUMNS = 1671;
	public static readonly STRIPE_WIDTH = 1672;
	public static readonly STRIP = 1673;
	public static readonly STRUCTURE = 1674;
	public static readonly SUBMULTISET = 1675;
	public static readonly SUBPARTITION_REL = 1676;
	public static readonly SUBPARTITIONS = 1677;
	public static readonly SUBPARTITION = 1678;
	public static readonly SUBQUERIES = 1679;
	public static readonly SUBQUERY_PRUNING = 1680;
	public static readonly SUBSCRIBE = 1681;
	public static readonly SUBSET = 1682;
	public static readonly SUBSTITUTABLE = 1683;
	public static readonly SUBSTR2 = 1684;
	public static readonly SUBSTR4 = 1685;
	public static readonly SUBSTRB = 1686;
	public static readonly SUBSTRC = 1687;
	public static readonly SUBTYPE = 1688;
	public static readonly SUCCESSFUL = 1689;
	public static readonly SUCCESS = 1690;
	public static readonly SUMMARY = 1691;
	public static readonly SUPPLEMENTAL = 1692;
	public static readonly SUSPEND = 1693;
	public static readonly SWAP_JOIN_INPUTS = 1694;
	public static readonly SWITCHOVER = 1695;
	public static readonly SWITCH = 1696;
	public static readonly SYNCHRONOUS = 1697;
	public static readonly SYNC = 1698;
	public static readonly SYNONYM = 1699;
	public static readonly SYS = 1700;
	public static readonly SYSASM = 1701;
	public static readonly SYS_AUDIT = 1702;
	public static readonly SYSAUX = 1703;
	public static readonly SYSBACKUP = 1704;
	public static readonly SYS_CHECKACL = 1705;
	public static readonly SYS_CHECK_PRIVILEGE = 1706;
	public static readonly SYS_CONNECT_BY_PATH = 1707;
	public static readonly SYS_CONTEXT = 1708;
	public static readonly SYSDATE = 1709;
	public static readonly SYSDBA = 1710;
	public static readonly SYS_DBURIGEN = 1711;
	public static readonly SYSDG = 1712;
	public static readonly SYS_DL_CURSOR = 1713;
	public static readonly SYS_DM_RXFORM_CHR = 1714;
	public static readonly SYS_DM_RXFORM_NUM = 1715;
	public static readonly SYS_DOM_COMPARE = 1716;
	public static readonly SYS_DST_PRIM2SEC = 1717;
	public static readonly SYS_DST_SEC2PRIM = 1718;
	public static readonly SYS_ET_BFILE_TO_RAW = 1719;
	public static readonly SYS_ET_BLOB_TO_IMAGE = 1720;
	public static readonly SYS_ET_IMAGE_TO_BLOB = 1721;
	public static readonly SYS_ET_RAW_TO_BFILE = 1722;
	public static readonly SYS_EXTPDTXT = 1723;
	public static readonly SYS_EXTRACT_UTC = 1724;
	public static readonly SYS_FBT_INSDEL = 1725;
	public static readonly SYS_FILTER_ACLS = 1726;
	public static readonly SYS_FNMATCHES = 1727;
	public static readonly SYS_FNREPLACE = 1728;
	public static readonly SYS_GET_ACLIDS = 1729;
	public static readonly SYS_GET_COL_ACLIDS = 1730;
	public static readonly SYS_GET_PRIVILEGES = 1731;
	public static readonly SYS_GETTOKENID = 1732;
	public static readonly SYS_GETXTIVAL = 1733;
	public static readonly SYS_GUID = 1734;
	public static readonly SYSGUID = 1735;
	public static readonly SYSKM = 1736;
	public static readonly SYS_MAKE_XMLNODEID = 1737;
	public static readonly SYS_MAKEXML = 1738;
	public static readonly SYS_MKXMLATTR = 1739;
	public static readonly SYS_MKXTI = 1740;
	public static readonly SYSOBJ = 1741;
	public static readonly SYS_OP_ADT2BIN = 1742;
	public static readonly SYS_OP_ADTCONS = 1743;
	public static readonly SYS_OP_ALSCRVAL = 1744;
	public static readonly SYS_OP_ATG = 1745;
	public static readonly SYS_OP_BIN2ADT = 1746;
	public static readonly SYS_OP_BITVEC = 1747;
	public static readonly SYS_OP_BL2R = 1748;
	public static readonly SYS_OP_BLOOM_FILTER_LIST = 1749;
	public static readonly SYS_OP_BLOOM_FILTER = 1750;
	public static readonly SYS_OP_C2C = 1751;
	public static readonly SYS_OP_CAST = 1752;
	public static readonly SYS_OP_CEG = 1753;
	public static readonly SYS_OP_CL2C = 1754;
	public static readonly SYS_OP_COMBINED_HASH = 1755;
	public static readonly SYS_OP_COMP = 1756;
	public static readonly SYS_OP_CONVERT = 1757;
	public static readonly SYS_OP_COUNTCHG = 1758;
	public static readonly SYS_OP_CSCONV = 1759;
	public static readonly SYS_OP_CSCONVTEST = 1760;
	public static readonly SYS_OP_CSR = 1761;
	public static readonly SYS_OP_CSX_PATCH = 1762;
	public static readonly SYS_OP_CYCLED_SEQ = 1763;
	public static readonly SYS_OP_DECOMP = 1764;
	public static readonly SYS_OP_DESCEND = 1765;
	public static readonly SYS_OP_DISTINCT = 1766;
	public static readonly SYS_OP_DRA = 1767;
	public static readonly SYS_OP_DUMP = 1768;
	public static readonly SYS_OP_DV_CHECK = 1769;
	public static readonly SYS_OP_ENFORCE_NOT_NULL = 1770;
	public static readonly SYSOPER = 1771;
	public static readonly SYS_OP_EXTRACT = 1772;
	public static readonly SYS_OP_GROUPING = 1773;
	public static readonly SYS_OP_GUID = 1774;
	public static readonly SYS_OP_HASH = 1775;
	public static readonly SYS_OP_IIX = 1776;
	public static readonly SYS_OP_ITR = 1777;
	public static readonly SYS_OP_KEY_VECTOR_CREATE = 1778;
	public static readonly SYS_OP_KEY_VECTOR_FILTER_LIST = 1779;
	public static readonly SYS_OP_KEY_VECTOR_FILTER = 1780;
	public static readonly SYS_OP_KEY_VECTOR_SUCCEEDED = 1781;
	public static readonly SYS_OP_KEY_VECTOR_USE = 1782;
	public static readonly SYS_OP_LBID = 1783;
	public static readonly SYS_OP_LOBLOC2BLOB = 1784;
	public static readonly SYS_OP_LOBLOC2CLOB = 1785;
	public static readonly SYS_OP_LOBLOC2ID = 1786;
	public static readonly SYS_OP_LOBLOC2NCLOB = 1787;
	public static readonly SYS_OP_LOBLOC2TYP = 1788;
	public static readonly SYS_OP_LSVI = 1789;
	public static readonly SYS_OP_LVL = 1790;
	public static readonly SYS_OP_MAKEOID = 1791;
	public static readonly SYS_OP_MAP_NONNULL = 1792;
	public static readonly SYS_OP_MSR = 1793;
	public static readonly SYS_OP_NICOMBINE = 1794;
	public static readonly SYS_OP_NIEXTRACT = 1795;
	public static readonly SYS_OP_NII = 1796;
	public static readonly SYS_OP_NIX = 1797;
	public static readonly SYS_OP_NOEXPAND = 1798;
	public static readonly SYS_OP_NTCIMG = 1799;
	public static readonly SYS_OP_NUMTORAW = 1800;
	public static readonly SYS_OP_OIDVALUE = 1801;
	public static readonly SYS_OP_OPNSIZE = 1802;
	public static readonly SYS_OP_PAR_1 = 1803;
	public static readonly SYS_OP_PARGID_1 = 1804;
	public static readonly SYS_OP_PARGID = 1805;
	public static readonly SYS_OP_PAR = 1806;
	public static readonly SYS_OP_PART_ID = 1807;
	public static readonly SYS_OP_PIVOT = 1808;
	public static readonly SYS_OP_R2O = 1809;
	public static readonly SYS_OP_RAWTONUM = 1810;
	public static readonly SYS_OP_RDTM = 1811;
	public static readonly SYS_OP_REF = 1812;
	public static readonly SYS_OP_RMTD = 1813;
	public static readonly SYS_OP_ROWIDTOOBJ = 1814;
	public static readonly SYS_OP_RPB = 1815;
	public static readonly SYS_OPTLOBPRBSC = 1816;
	public static readonly SYS_OP_TOSETID = 1817;
	public static readonly SYS_OP_TPR = 1818;
	public static readonly SYS_OP_TRTB = 1819;
	public static readonly SYS_OPTXICMP = 1820;
	public static readonly SYS_OPTXQCASTASNQ = 1821;
	public static readonly SYS_OP_UNDESCEND = 1822;
	public static readonly SYS_OP_VECAND = 1823;
	public static readonly SYS_OP_VECBIT = 1824;
	public static readonly SYS_OP_VECOR = 1825;
	public static readonly SYS_OP_VECXOR = 1826;
	public static readonly SYS_OP_VERSION = 1827;
	public static readonly SYS_OP_VREF = 1828;
	public static readonly SYS_OP_VVD = 1829;
	public static readonly SYS_OP_XMLCONS_FOR_CSX = 1830;
	public static readonly SYS_OP_XPTHATG = 1831;
	public static readonly SYS_OP_XPTHIDX = 1832;
	public static readonly SYS_OP_XPTHOP = 1833;
	public static readonly SYS_OP_XTXT2SQLT = 1834;
	public static readonly SYS_OP_ZONE_ID = 1835;
	public static readonly SYS_ORDERKEY_DEPTH = 1836;
	public static readonly SYS_ORDERKEY_MAXCHILD = 1837;
	public static readonly SYS_ORDERKEY_PARENT = 1838;
	public static readonly SYS_PARALLEL_TXN = 1839;
	public static readonly SYS_PATHID_IS_ATTR = 1840;
	public static readonly SYS_PATHID_IS_NMSPC = 1841;
	public static readonly SYS_PATHID_LASTNAME = 1842;
	public static readonly SYS_PATHID_LASTNMSPC = 1843;
	public static readonly SYS_PATH_REVERSE = 1844;
	public static readonly SYS_PXQEXTRACT = 1845;
	public static readonly SYS_RAW_TO_XSID = 1846;
	public static readonly SYS_RID_ORDER = 1847;
	public static readonly SYS_ROW_DELTA = 1848;
	public static readonly SYS_SC_2_XMLT = 1849;
	public static readonly SYS_SYNRCIREDO = 1850;
	public static readonly SYSTEM_DEFINED = 1851;
	public static readonly SYSTEM = 1852;
	public static readonly SYSTIMESTAMP = 1853;
	public static readonly SYS_TYPEID = 1854;
	public static readonly SYS_UMAKEXML = 1855;
	public static readonly SYS_XMLANALYZE = 1856;
	public static readonly SYS_XMLCONTAINS = 1857;
	public static readonly SYS_XMLCONV = 1858;
	public static readonly SYS_XMLEXNSURI = 1859;
	public static readonly SYS_XMLGEN = 1860;
	public static readonly SYS_XMLI_LOC_ISNODE = 1861;
	public static readonly SYS_XMLI_LOC_ISTEXT = 1862;
	public static readonly SYS_XMLINSTR = 1863;
	public static readonly SYS_XMLLOCATOR_GETSVAL = 1864;
	public static readonly SYS_XMLNODEID_GETCID = 1865;
	public static readonly SYS_XMLNODEID_GETLOCATOR = 1866;
	public static readonly SYS_XMLNODEID_GETOKEY = 1867;
	public static readonly SYS_XMLNODEID_GETPATHID = 1868;
	public static readonly SYS_XMLNODEID_GETPTRID = 1869;
	public static readonly SYS_XMLNODEID_GETRID = 1870;
	public static readonly SYS_XMLNODEID_GETSVAL = 1871;
	public static readonly SYS_XMLNODEID_GETTID = 1872;
	public static readonly SYS_XMLNODEID = 1873;
	public static readonly SYS_XMLT_2_SC = 1874;
	public static readonly SYS_XMLTRANSLATE = 1875;
	public static readonly SYS_XMLTYPE2SQL = 1876;
	public static readonly SYS_XQ_ASQLCNV = 1877;
	public static readonly SYS_XQ_ATOMCNVCHK = 1878;
	public static readonly SYS_XQBASEURI = 1879;
	public static readonly SYS_XQCASTABLEERRH = 1880;
	public static readonly SYS_XQCODEP2STR = 1881;
	public static readonly SYS_XQCODEPEQ = 1882;
	public static readonly SYS_XQCON2SEQ = 1883;
	public static readonly SYS_XQCONCAT = 1884;
	public static readonly SYS_XQDELETE = 1885;
	public static readonly SYS_XQDFLTCOLATION = 1886;
	public static readonly SYS_XQDOC = 1887;
	public static readonly SYS_XQDOCURI = 1888;
	public static readonly SYS_XQDURDIV = 1889;
	public static readonly SYS_XQED4URI = 1890;
	public static readonly SYS_XQENDSWITH = 1891;
	public static readonly SYS_XQERRH = 1892;
	public static readonly SYS_XQERR = 1893;
	public static readonly SYS_XQESHTMLURI = 1894;
	public static readonly SYS_XQEXLOBVAL = 1895;
	public static readonly SYS_XQEXSTWRP = 1896;
	public static readonly SYS_XQEXTRACT = 1897;
	public static readonly SYS_XQEXTRREF = 1898;
	public static readonly SYS_XQEXVAL = 1899;
	public static readonly SYS_XQFB2STR = 1900;
	public static readonly SYS_XQFNBOOL = 1901;
	public static readonly SYS_XQFNCMP = 1902;
	public static readonly SYS_XQFNDATIM = 1903;
	public static readonly SYS_XQFNLNAME = 1904;
	public static readonly SYS_XQFNNM = 1905;
	public static readonly SYS_XQFNNSURI = 1906;
	public static readonly SYS_XQFNPREDTRUTH = 1907;
	public static readonly SYS_XQFNQNM = 1908;
	public static readonly SYS_XQFNROOT = 1909;
	public static readonly SYS_XQFORMATNUM = 1910;
	public static readonly SYS_XQFTCONTAIN = 1911;
	public static readonly SYS_XQFUNCR = 1912;
	public static readonly SYS_XQGETCONTENT = 1913;
	public static readonly SYS_XQINDXOF = 1914;
	public static readonly SYS_XQINSERT = 1915;
	public static readonly SYS_XQINSPFX = 1916;
	public static readonly SYS_XQIRI2URI = 1917;
	public static readonly SYS_XQLANG = 1918;
	public static readonly SYS_XQLLNMFRMQNM = 1919;
	public static readonly SYS_XQMKNODEREF = 1920;
	public static readonly SYS_XQNILLED = 1921;
	public static readonly SYS_XQNODENAME = 1922;
	public static readonly SYS_XQNORMSPACE = 1923;
	public static readonly SYS_XQNORMUCODE = 1924;
	public static readonly SYS_XQ_NRNG = 1925;
	public static readonly SYS_XQNSP4PFX = 1926;
	public static readonly SYS_XQNSPFRMQNM = 1927;
	public static readonly SYS_XQPFXFRMQNM = 1928;
	public static readonly SYS_XQ_PKSQL2XML = 1929;
	public static readonly SYS_XQPOLYABS = 1930;
	public static readonly SYS_XQPOLYADD = 1931;
	public static readonly SYS_XQPOLYCEL = 1932;
	public static readonly SYS_XQPOLYCSTBL = 1933;
	public static readonly SYS_XQPOLYCST = 1934;
	public static readonly SYS_XQPOLYDIV = 1935;
	public static readonly SYS_XQPOLYFLR = 1936;
	public static readonly SYS_XQPOLYMOD = 1937;
	public static readonly SYS_XQPOLYMUL = 1938;
	public static readonly SYS_XQPOLYRND = 1939;
	public static readonly SYS_XQPOLYSQRT = 1940;
	public static readonly SYS_XQPOLYSUB = 1941;
	public static readonly SYS_XQPOLYUMUS = 1942;
	public static readonly SYS_XQPOLYUPLS = 1943;
	public static readonly SYS_XQPOLYVEQ = 1944;
	public static readonly SYS_XQPOLYVGE = 1945;
	public static readonly SYS_XQPOLYVGT = 1946;
	public static readonly SYS_XQPOLYVLE = 1947;
	public static readonly SYS_XQPOLYVLT = 1948;
	public static readonly SYS_XQPOLYVNE = 1949;
	public static readonly SYS_XQREF2VAL = 1950;
	public static readonly SYS_XQRENAME = 1951;
	public static readonly SYS_XQREPLACE = 1952;
	public static readonly SYS_XQRESVURI = 1953;
	public static readonly SYS_XQRNDHALF2EVN = 1954;
	public static readonly SYS_XQRSLVQNM = 1955;
	public static readonly SYS_XQRYENVPGET = 1956;
	public static readonly SYS_XQRYVARGET = 1957;
	public static readonly SYS_XQRYWRP = 1958;
	public static readonly SYS_XQSEQ2CON4XC = 1959;
	public static readonly SYS_XQSEQ2CON = 1960;
	public static readonly SYS_XQSEQDEEPEQ = 1961;
	public static readonly SYS_XQSEQINSB = 1962;
	public static readonly SYS_XQSEQRM = 1963;
	public static readonly SYS_XQSEQRVS = 1964;
	public static readonly SYS_XQSEQSUB = 1965;
	public static readonly SYS_XQSEQTYPMATCH = 1966;
	public static readonly SYS_XQSTARTSWITH = 1967;
	public static readonly SYS_XQSTATBURI = 1968;
	public static readonly SYS_XQSTR2CODEP = 1969;
	public static readonly SYS_XQSTRJOIN = 1970;
	public static readonly SYS_XQSUBSTRAFT = 1971;
	public static readonly SYS_XQSUBSTRBEF = 1972;
	public static readonly SYS_XQTOKENIZE = 1973;
	public static readonly SYS_XQTREATAS = 1974;
	public static readonly SYS_XQ_UPKXML2SQL = 1975;
	public static readonly SYS_XQXFORM = 1976;
	public static readonly SYS_XSID_TO_RAW = 1977;
	public static readonly SYS_ZMAP_FILTER = 1978;
	public static readonly SYS_ZMAP_REFRESH = 1979;
	public static readonly T_LETTER = 1980;
	public static readonly TABLE_LOOKUP_BY_NL = 1981;
	public static readonly TABLESPACE_NO = 1982;
	public static readonly TABLESPACE = 1983;
	public static readonly TABLES = 1984;
	public static readonly TABLE_STATS = 1985;
	public static readonly TABLE = 1986;
	public static readonly TABNO = 1987;
	public static readonly TAG = 1988;
	public static readonly TANH = 1989;
	public static readonly TAN = 1990;
	public static readonly TBLORIDXPARTNUM = 1991;
	public static readonly TEMPFILE = 1992;
	public static readonly TEMPLATE = 1993;
	public static readonly TEMPORARY = 1994;
	public static readonly TEMP_TABLE = 1995;
	public static readonly TEST = 1996;
	public static readonly TEXT = 1997;
	public static readonly THAN = 1998;
	public static readonly THEN = 1999;
	public static readonly THE = 2000;
	public static readonly THREAD = 2001;
	public static readonly THROUGH = 2002;
	public static readonly TIER = 2003;
	public static readonly TIES = 2004;
	public static readonly TIMEOUT = 2005;
	public static readonly TIMESTAMP_LTZ_UNCONSTRAINED = 2006;
	public static readonly TIMESTAMP = 2007;
	public static readonly TIMESTAMP_TZ_UNCONSTRAINED = 2008;
	public static readonly TIMESTAMP_UNCONSTRAINED = 2009;
	public static readonly TIMES = 2010;
	public static readonly TIME = 2011;
	public static readonly TIMEZONE = 2012;
	public static readonly TIMEZONE_ABBR = 2013;
	public static readonly TIMEZONE_HOUR = 2014;
	public static readonly TIMEZONE_MINUTE = 2015;
	public static readonly TIMEZONE_OFFSET = 2016;
	public static readonly TIMEZONE_REGION = 2017;
	public static readonly TIME_ZONE = 2018;
	public static readonly TIMING = 2019;
	public static readonly TIV_GB = 2020;
	public static readonly TIV_SSF = 2021;
	public static readonly TO_ACLID = 2022;
	public static readonly TO_BINARY_DOUBLE = 2023;
	public static readonly TO_BINARY_FLOAT = 2024;
	public static readonly TO_BLOB = 2025;
	public static readonly TO_CLOB = 2026;
	public static readonly TO_DSINTERVAL = 2027;
	public static readonly TO_LOB = 2028;
	public static readonly TO_MULTI_BYTE = 2029;
	public static readonly TO_NCHAR = 2030;
	public static readonly TO_NCLOB = 2031;
	public static readonly TO_NUMBER = 2032;
	public static readonly TOPLEVEL = 2033;
	public static readonly TO_SINGLE_BYTE = 2034;
	public static readonly TO_TIMESTAMP = 2035;
	public static readonly TO_TIMESTAMP_TZ = 2036;
	public static readonly TO_TIME = 2037;
	public static readonly TO_TIME_TZ = 2038;
	public static readonly TO = 2039;
	public static readonly TO_YMINTERVAL = 2040;
	public static readonly TRACE = 2041;
	public static readonly TRACING = 2042;
	public static readonly TRACKING = 2043;
	public static readonly TRAILING = 2044;
	public static readonly TRANSACTION = 2045;
	public static readonly TRANSFORM = 2046;
	public static readonly TRANSFORM_DISTINCT_AGG = 2047;
	public static readonly TRANSITIONAL = 2048;
	public static readonly TRANSITION = 2049;
	public static readonly TRANSLATE = 2050;
	public static readonly TRANSLATION = 2051;
	public static readonly TREAT = 2052;
	public static readonly TRIGGERS = 2053;
	public static readonly TRIGGER = 2054;
	public static readonly TRUE = 2055;
	public static readonly TRUNCATE = 2056;
	public static readonly TRUNC = 2057;
	public static readonly TRUSTED = 2058;
	public static readonly TRUST = 2059;
	public static readonly TUNING = 2060;
	public static readonly TX = 2061;
	public static readonly TYPES = 2062;
	public static readonly TYPE = 2063;
	public static readonly TZ_OFFSET = 2064;
	public static readonly UB2 = 2065;
	public static readonly UBA = 2066;
	public static readonly UCS2 = 2067;
	public static readonly UID = 2068;
	public static readonly UNARCHIVED = 2069;
	public static readonly UNBOUNDED = 2070;
	public static readonly UNBOUND = 2071;
	public static readonly UNCONDITIONAL = 2072;
	public static readonly UNDER = 2073;
	public static readonly UNDO = 2074;
	public static readonly UNDROP = 2075;
	public static readonly UNIFORM = 2076;
	public static readonly UNION = 2077;
	public static readonly UNIQUE = 2078;
	public static readonly UNISTR = 2079;
	public static readonly UNLIMITED = 2080;
	public static readonly UNLOAD = 2081;
	public static readonly UNLOCK = 2082;
	public static readonly UNMATCHED = 2083;
	public static readonly UNNEST_INNERJ_DISTINCT_VIEW = 2084;
	public static readonly UNNEST_NOSEMIJ_NODISTINCTVIEW = 2085;
	public static readonly UNNEST_SEMIJ_VIEW = 2086;
	public static readonly UNNEST = 2087;
	public static readonly UNPACKED = 2088;
	public static readonly UNPIVOT = 2089;
	public static readonly UNPLUG = 2090;
	public static readonly UNPROTECTED = 2091;
	public static readonly UNQUIESCE = 2092;
	public static readonly UNRECOVERABLE = 2093;
	public static readonly UNRESTRICTED = 2094;
	public static readonly UNSUBSCRIBE = 2095;
	public static readonly UNTIL = 2096;
	public static readonly UNUSABLE = 2097;
	public static readonly UNUSED = 2098;
	public static readonly UPDATABLE = 2099;
	public static readonly UPDATED = 2100;
	public static readonly UPDATE = 2101;
	public static readonly UPDATEXML = 2102;
	public static readonly UPD_INDEXES = 2103;
	public static readonly UPD_JOININDEX = 2104;
	public static readonly UPGRADE = 2105;
	public static readonly UPPER = 2106;
	public static readonly UPSERT = 2107;
	public static readonly UROWID = 2108;
	public static readonly USABLE = 2109;
	public static readonly USAGE = 2110;
	public static readonly USE_ANTI = 2111;
	public static readonly USE_CONCAT = 2112;
	public static readonly USE_CUBE = 2113;
	public static readonly USE_HASH_AGGREGATION = 2114;
	public static readonly USE_HASH_GBY_FOR_PUSHDOWN = 2115;
	public static readonly USE_HASH = 2116;
	public static readonly USE_HIDDEN_PARTITIONS = 2117;
	public static readonly USE_INVISIBLE_INDEXES = 2118;
	public static readonly USE_MERGE_CARTESIAN = 2119;
	public static readonly USE_MERGE = 2120;
	public static readonly USE_NL = 2121;
	public static readonly USE_NL_WITH_INDEX = 2122;
	public static readonly USE_PRIVATE_OUTLINES = 2123;
	public static readonly USER_DATA = 2124;
	public static readonly USER_DEFINED = 2125;
	public static readonly USERENV = 2126;
	public static readonly USERGROUP = 2127;
	public static readonly USER_RECYCLEBIN = 2128;
	public static readonly USERS = 2129;
	public static readonly USER_TABLESPACES = 2130;
	public static readonly USER = 2131;
	public static readonly USE_SEMI = 2132;
	public static readonly USE_STORED_OUTLINES = 2133;
	public static readonly USE_TTT_FOR_GSETS = 2134;
	public static readonly USE = 2135;
	public static readonly USE_VECTOR_AGGREGATION = 2136;
	public static readonly USE_WEAK_NAME_RESL = 2137;
	public static readonly USING_NO_EXPAND = 2138;
	public static readonly USING = 2139;
	public static readonly UTF16BE = 2140;
	public static readonly UTF16LE = 2141;
	public static readonly UTF32 = 2142;
	public static readonly UTF8 = 2143;
	public static readonly V1 = 2144;
	public static readonly V2 = 2145;
	public static readonly VALIDATE = 2146;
	public static readonly VALIDATE_CONVERSION = 2147;
	public static readonly VALIDATION = 2148;
	public static readonly VALID_TIME_END = 2149;
	public static readonly VALUES = 2150;
	public static readonly VALUE = 2151;
	public static readonly VARCHAR2 = 2152;
	public static readonly VARCHAR = 2153;
	public static readonly VARIABLE = 2154;
	public static readonly VAR_POP = 2155;
	public static readonly VARRAYS = 2156;
	public static readonly VARRAY = 2157;
	public static readonly VAR_SAMP = 2158;
	public static readonly VARYING = 2159;
	public static readonly VECTOR_READ_TRACE = 2160;
	public static readonly VECTOR_READ = 2161;
	public static readonly VECTOR_TRANSFORM_DIMS = 2162;
	public static readonly VECTOR_TRANSFORM_FACT = 2163;
	public static readonly VECTOR_TRANSFORM = 2164;
	public static readonly VERIFIER = 2165;
	public static readonly VERIFY = 2166;
	public static readonly VERSIONING = 2167;
	public static readonly VERSIONS_ENDSCN = 2168;
	public static readonly VERSIONS_ENDTIME = 2169;
	public static readonly VERSIONS_OPERATION = 2170;
	public static readonly VERSIONS_STARTSCN = 2171;
	public static readonly VERSIONS_STARTTIME = 2172;
	public static readonly VERSIONS = 2173;
	public static readonly VERSIONS_XID = 2174;
	public static readonly VERSION = 2175;
	public static readonly VIEW = 2176;
	public static readonly VIOLATION = 2177;
	public static readonly VIRTUAL = 2178;
	public static readonly VISIBILITY = 2179;
	public static readonly VISIBLE = 2180;
	public static readonly VOLUME = 2181;
	public static readonly VSIZE = 2182;
	public static readonly WAIT = 2183;
	public static readonly WALLET = 2184;
	public static readonly WARNING = 2185;
	public static readonly WEEKS = 2186;
	public static readonly WEEK = 2187;
	public static readonly WELLFORMED = 2188;
	public static readonly WHENEVER = 2189;
	public static readonly WHEN = 2190;
	public static readonly WHERE = 2191;
	public static readonly WHILE = 2192;
	public static readonly WHITESPACE = 2193;
	public static readonly WIDTH_BUCKET = 2194;
	public static readonly WITHIN = 2195;
	public static readonly WITHOUT = 2196;
	public static readonly WITH_PLSQL = 2197;
	public static readonly WITH = 2198;
	public static readonly WORK = 2199;
	public static readonly WRAPPED = 2200;
	public static readonly WRAPPER = 2201;
	public static readonly WRITE = 2202;
	public static readonly XDB_FASTPATH_INSERT = 2203;
	public static readonly XDB = 2204;
	public static readonly X_DYN_PRUNE = 2205;
	public static readonly XID = 2206;
	public static readonly XML2OBJECT = 2207;
	public static readonly XMLAGG = 2208;
	public static readonly XMLATTRIBUTES = 2209;
	public static readonly XMLCAST = 2210;
	public static readonly XMLCDATA = 2211;
	public static readonly XMLCOLATTVAL = 2212;
	public static readonly XMLCOMMENT = 2213;
	public static readonly XMLCONCAT = 2214;
	public static readonly XMLDIFF = 2215;
	public static readonly XML_DML_RWT_STMT = 2216;
	public static readonly XMLELEMENT = 2217;
	public static readonly XMLEXISTS2 = 2218;
	public static readonly XMLEXISTS = 2219;
	public static readonly XMLFOREST = 2220;
	public static readonly XMLINDEX = 2221;
	public static readonly XMLINDEX_REWRITE_IN_SELECT = 2222;
	public static readonly XMLINDEX_REWRITE = 2223;
	public static readonly XMLINDEX_SEL_IDX_TBL = 2224;
	public static readonly XMLISNODE = 2225;
	public static readonly XMLISVALID = 2226;
	public static readonly XMLNAMESPACES = 2227;
	public static readonly XMLPARSE = 2228;
	public static readonly XMLPATCH = 2229;
	public static readonly XMLPI = 2230;
	public static readonly XMLQUERYVAL = 2231;
	public static readonly XMLQUERY = 2232;
	public static readonly XMLROOT = 2233;
	public static readonly XMLSCHEMA = 2234;
	public static readonly XMLSERIALIZE = 2235;
	public static readonly XMLTABLE = 2236;
	public static readonly XMLTRANSFORMBLOB = 2237;
	public static readonly XMLTRANSFORM = 2238;
	public static readonly XMLTYPE = 2239;
	public static readonly XML = 2240;
	public static readonly XPATHTABLE = 2241;
	public static readonly XS_SYS_CONTEXT = 2242;
	public static readonly XS = 2243;
	public static readonly XTRANSPORT = 2244;
	public static readonly YEARS = 2245;
	public static readonly YEAR = 2246;
	public static readonly YES = 2247;
	public static readonly YMINTERVAL_UNCONSTRAINED = 2248;
	public static readonly ZONEMAP = 2249;
	public static readonly ZONE = 2250;
	public static readonly PREDICTION = 2251;
	public static readonly PREDICTION_BOUNDS = 2252;
	public static readonly PREDICTION_COST = 2253;
	public static readonly PREDICTION_DETAILS = 2254;
	public static readonly PREDICTION_PROBABILITY = 2255;
	public static readonly PREDICTION_SET = 2256;
	public static readonly BLOCKCHAIN = 2257;
	public static readonly COLLATE = 2258;
	public static readonly COLLATION = 2259;
	public static readonly DEFINITION = 2260;
	public static readonly DUPLICATED = 2261;
	public static readonly EXTENDED = 2262;
	public static readonly HASHING = 2263;
	public static readonly IDLE = 2264;
	public static readonly IMMUTABLE = 2265;
	public static readonly ORACLE_DATAPUMP = 2266;
	public static readonly ORACLE_HDFS = 2267;
	public static readonly ORACLE_HIVE = 2268;
	public static readonly ORACLE_LOADER = 2269;
	public static readonly SHA2_512_Q = 2270;
	public static readonly SHARDED = 2271;
	public static readonly V1_Q = 2272;
	public static readonly ISOLATE = 2273;
	public static readonly ROOT = 2274;
	public static readonly UNITE = 2275;
	public static readonly ALGORITHM = 2276;
	public static readonly CUME_DIST = 2277;
	public static readonly DENSE_RANK = 2278;
	public static readonly LISTAGG = 2279;
	public static readonly PERCENT_RANK = 2280;
	public static readonly PERCENTILE_CONT = 2281;
	public static readonly PERCENTILE_DISC = 2282;
	public static readonly RANK = 2283;
	public static readonly AVG = 2284;
	public static readonly CORR = 2285;
	public static readonly COVAR_ = 2286;
	public static readonly DECODE = 2287;
	public static readonly LAG = 2288;
	public static readonly LAG_DIFF = 2289;
	public static readonly LAG_DIFF_PERCENT = 2290;
	public static readonly LEAD = 2291;
	public static readonly MAX = 2292;
	public static readonly MEDIAN = 2293;
	public static readonly MEMOPTIMIZE = 2294;
	public static readonly MIN = 2295;
	public static readonly NTILE = 2296;
	public static readonly NVL = 2297;
	public static readonly RATIO_TO_REPORT = 2298;
	public static readonly REGR_ = 2299;
	public static readonly ROUND = 2300;
	public static readonly ROW_NUMBER = 2301;
	public static readonly SUBSTR = 2302;
	public static readonly TO_CHAR = 2303;
	public static readonly TRIM = 2304;
	public static readonly SUM = 2305;
	public static readonly STDDEV = 2306;
	public static readonly VAR_ = 2307;
	public static readonly VARIANCE = 2308;
	public static readonly LEAST = 2309;
	public static readonly GREATEST = 2310;
	public static readonly TO_DATE = 2311;
	public static readonly NATIONAL_CHAR_STRING_LIT = 2312;
	public static readonly BIT_STRING_LIT = 2313;
	public static readonly HEX_STRING_LIT = 2314;
	public static readonly DOUBLE_PERIOD = 2315;
	public static readonly PERIOD = 2316;
	public static readonly UNSIGNED_INTEGER = 2317;
	public static readonly APPROXIMATE_NUM_LIT = 2318;
	public static readonly CHAR_STRING = 2319;
	public static readonly DELIMITED_ID = 2320;
	public static readonly PERCENT = 2321;
	public static readonly AMPERSAND = 2322;
	public static readonly LEFT_PAREN = 2323;
	public static readonly RIGHT_PAREN = 2324;
	public static readonly DOUBLE_ASTERISK = 2325;
	public static readonly ASTERISK = 2326;
	public static readonly PLUS_SIGN = 2327;
	public static readonly MINUS_SIGN = 2328;
	public static readonly COMMA = 2329;
	public static readonly SOLIDUS = 2330;
	public static readonly AT_SIGN = 2331;
	public static readonly ASSIGN_OP = 2332;
	public static readonly HASH_OP = 2333;
	public static readonly SQ = 2334;
	public static readonly BINDVAR = 2335;
	public static readonly NOT_EQUAL_OP = 2336;
	public static readonly CARRET_OPERATOR_PART = 2337;
	public static readonly TILDE_OPERATOR_PART = 2338;
	public static readonly EXCLAMATION_OPERATOR_PART = 2339;
	public static readonly GREATER_THAN_OP = 2340;
	public static readonly LESS_THAN_OP = 2341;
	public static readonly COLON = 2342;
	public static readonly SEMICOLON = 2343;
	public static readonly BAR = 2344;
	public static readonly EQUALS_OP = 2345;
	public static readonly LEFT_BRACKET = 2346;
	public static readonly RIGHT_BRACKET = 2347;
	public static readonly INTRODUCER = 2348;
	public static readonly SINGLE_LINE_COMMENT = 2349;
	public static readonly MULTI_LINE_COMMENT = 2350;
	public static readonly REMARK_COMMENT = 2351;
	public static readonly PROMPT_MESSAGE = 2352;
	public static readonly START_CMD = 2353;
	public static readonly REGULAR_ID = 2354;
	public static readonly SPACES = 2355;
	public static readonly EOF = Token.EOF;

	public static readonly channelNames: string[] = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	public static readonly literalNames: (string | null)[] = [ null, "'ABORT'", 
                                                            "'ABS'", "'ABSENT'", 
                                                            "'ACCESS'", 
                                                            "'ACCESSED'", 
                                                            "'ACCOUNT'", 
                                                            "'ACL'", "'ACOS'", 
                                                            "'ACROSS'", 
                                                            "'ACTION'", 
                                                            "'ACTIONS'", 
                                                            "'ACTIVATE'", 
                                                            "'ACTIVE'", 
                                                            "'ACTIVE_COMPONENT'", 
                                                            "'ACTIVE_DATA'", 
                                                            "'ACTIVE_FUNCTION'", 
                                                            "'ACTIVE_TAG'", 
                                                            "'ACTIVITY'", 
                                                            "'ADAPTIVE_PLAN'", 
                                                            "'ADD'", "'ADD_COLUMN'", 
                                                            "'ADD_GROUP'", 
                                                            "'ADD_MONTHS'", 
                                                            "'ADJ_DATE'", 
                                                            "'ADMIN'", "'ADMINISTER'", 
                                                            "'ADMINISTRATOR'", 
                                                            "'ADVANCED'", 
                                                            "'ADVISE'", 
                                                            "'ADVISOR'", 
                                                            "'AFD_DISKSTRING'", 
                                                            "'AFTER'", "'AGENT'", 
                                                            "'AGGREGATE'", 
                                                            "'A'", "'ALIAS'", 
                                                            "'ALL'", "'ALLOCATE'", 
                                                            "'ALLOW'", "'ALL_ROWS'", 
                                                            "'ALTER'", "'ALTERNATE'", 
                                                            "'ALWAYS'", 
                                                            "'ANALYTIC'", 
                                                            "'ANALYZE'", 
                                                            "'ANCESTOR'", 
                                                            "'ANCILLARY'", 
                                                            "'AND'", "'AND_EQUAL'", 
                                                            "'ANOMALY'", 
                                                            "'ANSI_REARCH'", 
                                                            "'ANTIJOIN'", 
                                                            "'ANY'", "'ANYSCHEMA'", 
                                                            "'APPEND'", 
                                                            "'APPENDCHILDXML'", 
                                                            "'APPEND_VALUES'", 
                                                            "'APPLICATION'", 
                                                            "'APPLY'", "'APPROX_COUNT_DISTINCT'", 
                                                            "'ARCHIVAL'", 
                                                            "'ARCHIVE'", 
                                                            "'ARCHIVED'", 
                                                            "'ARCHIVELOG'", 
                                                            "'ARRAY'", "'AS'", 
                                                            "'ASC'", "'ASCII'", 
                                                            "'ASCIISTR'", 
                                                            "'ASIN'", "'ASIS'", 
                                                            "'ASSEMBLY'", 
                                                            "'ASSIGN'", 
                                                            "'ASSOCIATE'", 
                                                            "'ASYNC'", "'ASYNCHRONOUS'", 
                                                            "'ATAN2'", "'ATAN'", 
                                                            "'AT'", "'ATTRIBUTE'", 
                                                            "'ATTRIBUTES'", 
                                                            "'AUDIT'", "'AUTHENTICATED'", 
                                                            "'AUTHENTICATION'", 
                                                            "'AUTHID'", 
                                                            "'AUTHORIZATION'", 
                                                            "'AUTOALLOCATE'", 
                                                            "'AUTO'", "'AUTOBACKUP'", 
                                                            "'AUTOEXTEND'", 
                                                            "'AUTO_LOGIN'", 
                                                            "'AUTOMATIC'", 
                                                            "'AUTONOMOUS_TRANSACTION'", 
                                                            "'AUTO_REOPTIMIZE'", 
                                                            "'AVAILABILITY'", 
                                                            "'AVRO'", "'BACKGROUND'", 
                                                            "'BACKINGFILE'", 
                                                            "'BACKUP'", 
                                                            "'BACKUPS'", 
                                                            "'BACKUPSET'", 
                                                            "'BASIC'", "'BASICFILE'", 
                                                            "'BATCH'", "'BATCHSIZE'", 
                                                            "'BATCH_TABLE_ACCESS_BY_ROWID'", 
                                                            "'BECOME'", 
                                                            "'BEFORE'", 
                                                            "'BEGIN'", "'BEGINNING'", 
                                                            "'BEGIN_OUTLINE_DATA'", 
                                                            "'BEHALF'", 
                                                            "'BEQUEATH'", 
                                                            "'BETWEEN'", 
                                                            "'BFILE'", "'BFILENAME'", 
                                                            "'BIGFILE'", 
                                                            "'BINARY'", 
                                                            "'BINARY_DOUBLE'", 
                                                            "'BINARY_DOUBLE_INFINITY'", 
                                                            "'BINARY_DOUBLE_NAN'", 
                                                            "'BINARY_FLOAT'", 
                                                            "'BINARY_FLOAT_INFINITY'", 
                                                            "'BINARY_FLOAT_NAN'", 
                                                            "'BINARY_INTEGER'", 
                                                            "'BIND_AWARE'", 
                                                            "'BINDING'", 
                                                            "'BIN_TO_NUM'", 
                                                            "'BITAND'", 
                                                            "'BITMAP_AND'", 
                                                            "'BITMAP'", 
                                                            "'BITMAPS'", 
                                                            "'BITMAP_TREE'", 
                                                            "'BITS'", "'BLOB'", 
                                                            "'BLOCK'", "'BLOCK_RANGE'", 
                                                            "'BLOCKS'", 
                                                            "'BLOCKSIZE'", 
                                                            "'BODY'", "'BOOLEAN'", 
                                                            "'BOTH'", "'BOUND'", 
                                                            "'BRANCH'", 
                                                            "'BREADTH'", 
                                                            "'BROADCAST'", 
                                                            "'BSON'", "'BUFFER'", 
                                                            "'BUFFER_CACHE'", 
                                                            "'BUFFER_POOL'", 
                                                            "'BUILD'", "'BULK'", 
                                                            "'BY'", "'BYPASS_RECURSIVE_CHECK'", 
                                                            "'BYPASS_UJVC'", 
                                                            "'BYTE'", "'CACHE'", 
                                                            "'CACHE_CB'", 
                                                            "'CACHE_INSTANCES'", 
                                                            "'CACHE_TEMP_TABLE'", 
                                                            "'CACHING'", 
                                                            "'CALCULATED'", 
                                                            "'CALLBACK'", 
                                                            "'CALL'", "'CANCEL'", 
                                                            "'CANONICAL'", 
                                                            "'CAPACITY'", 
                                                            "'CAPTION'", 
                                                            "'CARDINALITY'", 
                                                            "'CASCADE'", 
                                                            "'CASE'", "'CAST'", 
                                                            "'CASE-SENSITIVE'", 
                                                            "'CATEGORY'", 
                                                            "'CDB$DEFAULT'", 
                                                            "'CEIL'", "'CELL_FLASH_CACHE'", 
                                                            "'CERTIFICATE'", 
                                                            "'CFILE'", "'CHAINED'", 
                                                            "'CHANGE'", 
                                                            "'CHANGETRACKING'", 
                                                            "'CHANGE_DUPKEY_ERROR_INDEX'", 
                                                            "'CHARACTER'", 
                                                            "'CHAR'", "'CHAR_CS'", 
                                                            "'CHARTOROWID'", 
                                                            "'CHECK_ACL_REWRITE'", 
                                                            "'CHECK'", "'CHECKPOINT'", 
                                                            "'CHILD'", "'CHOOSE'", 
                                                            "'CHR'", "'CHUNK'", 
                                                            "'CLASS'", "'CLASSIFICATION'", 
                                                            "'CLASSIFIER'", 
                                                            "'CLAUSE'", 
                                                            "'CLEAN'", "'CLEANUP'", 
                                                            "'CLEAR'", "'C'", 
                                                            "'CLIENT'", 
                                                            "'CLOB'", "'CLONE'", 
                                                            "'CLOSE_CACHED_OPEN_CURSORS'", 
                                                            "'CLOSE'", "'CLUSTER_BY_ROWID'", 
                                                            "'CLUSTER'", 
                                                            "'CLUSTER_DETAILS'", 
                                                            "'CLUSTER_DISTANCE'", 
                                                            "'CLUSTER_ID'", 
                                                            "'CLUSTERING'", 
                                                            "'CLUSTERING_FACTOR'", 
                                                            "'CLUSTER_PROBABILITY'", 
                                                            "'CLUSTER_SET'", 
                                                            "'COALESCE'", 
                                                            "'COALESCE_SQ'", 
                                                            "'COARSE'", 
                                                            "'CO_AUTH_IND'", 
                                                            "'COLD'", "'COLLECT'", 
                                                            "'COLUMNAR'", 
                                                            "'COLUMN_AUTH_INDICATOR'", 
                                                            "'COLUMN'", 
                                                            "'COLUMNS'", 
                                                            "'COLUMN_STATS'", 
                                                            "'COLUMN_VALUE'", 
                                                            "'COMMENT'", 
                                                            "'COMMIT'", 
                                                            "'COMMITTED'", 
                                                            "'COMMON'", 
                                                            "'COMMON_DATA'", 
                                                            "'COMPACT'", 
                                                            "'COMPATIBILITY'", 
                                                            "'COMPILE'", 
                                                            "'COMPLETE'", 
                                                            "'COMPLIANCE'", 
                                                            "'COMPONENT'", 
                                                            "'COMPONENTS'", 
                                                            "'COMPOSE'", 
                                                            "'COMPOSITE'", 
                                                            "'COMPOSITE_LIMIT'", 
                                                            "'COMPOUND'", 
                                                            "'COMPRESS'", 
                                                            "'COMPUTE'", 
                                                            "'CONCAT'", 
                                                            "'CON_DBID_TO_ID'", 
                                                            "'CONDITIONAL'", 
                                                            "'CONDITION'", 
                                                            "'CONFIRM'", 
                                                            "'CONFORMING'", 
                                                            "'CON_GUID_TO_ID'", 
                                                            "'CON_ID'", 
                                                            "'CON_NAME_TO_ID'", 
                                                            "'CONNECT_BY_CB_WHR_ONLY'", 
                                                            "'CONNECT_BY_COMBINE_SW'", 
                                                            "'CONNECT_BY_COST_BASED'", 
                                                            "'CONNECT_BY_ELIM_DUPS'", 
                                                            "'CONNECT_BY_FILTERING'", 
                                                            "'CONNECT_BY_ISCYCLE'", 
                                                            "'CONNECT_BY_ISLEAF'", 
                                                            "'CONNECT_BY_ROOT'", 
                                                            "'CONNECT'", 
                                                            "'CONNECT_TIME'", 
                                                            "'CONSIDER'", 
                                                            "'CONSISTENT'", 
                                                            "'CONSTANT'", 
                                                            "'CONST'", "'CONSTRAINT'", 
                                                            "'CONSTRAINTS'", 
                                                            "'CONSTRUCTOR'", 
                                                            "'CONTAINER'", 
                                                            "'CONTAINERS'", 
                                                            "'CONTAINERS_DEFAULT'", 
                                                            "'CONTAINER_DATA'", 
                                                            "'CONTAINER_MAP'", 
                                                            "'CONTENT'", 
                                                            "'CONTENTS'", 
                                                            "'CONTEXT'", 
                                                            "'CONTINUE'", 
                                                            "'CONTROLFILE'", 
                                                            "'CON_UID_TO_ID'", 
                                                            "'CONVERT'", 
                                                            "'CONVERSION'", 
                                                            "'COOKIE'", 
                                                            "'COPY'", "'CORR_K'", 
                                                            "'CORR_S'", 
                                                            "'CORRUPTION'", 
                                                            "'CORRUPT_XID_ALL'", 
                                                            "'CORRUPT_XID'", 
                                                            "'COS'", "'COSH'", 
                                                            "'COST'", "'COST_XML_QUERY_REWRITE'", 
                                                            "'COUNT'", "'COVAR_POP'", 
                                                            "'COVAR_SAMP'", 
                                                            "'CPU_COSTING'", 
                                                            "'CPU_PER_CALL'", 
                                                            "'CPU_PER_SESSION'", 
                                                            "'CRASH'", "'CREATE'", 
                                                            "'CREATE_FILE_DEST'", 
                                                            "'CREATE_STORED_OUTLINES'", 
                                                            "'CREATION'", 
                                                            "'CREDENTIAL'", 
                                                            "'CRITICAL'", 
                                                            "'CROSS'", "'CROSSEDITION'", 
                                                            "'CSCONVERT'", 
                                                            "'CUBE_AJ'", 
                                                            "'CUBE'", "'CUBE_GB'", 
                                                            "'CUBE_SJ'", 
                                                            "'CUME_DISTM'", 
                                                            "'CURRENT'", 
                                                            "'CURRENT_DATE'", 
                                                            "'CURRENT_SCHEMA'", 
                                                            "'CURRENT_TIME'", 
                                                            "'CURRENT_TIMESTAMP'", 
                                                            "'CURRENT_USER'", 
                                                            "'CURRENTV'", 
                                                            "'CURSOR'", 
                                                            "'CURSOR_SHARING_EXACT'", 
                                                            "'CURSOR_SPECIFIC_SEGMENT'", 
                                                            "'CUSTOMDATUM'", 
                                                            "'CV'", "'CYCLE'", 
                                                            "'DANGLING'", 
                                                            "'DATABASE'", 
                                                            "'DATA'", "'DATAFILE'", 
                                                            "'DATAFILES'", 
                                                            "'DATAGUARDCONFIG'", 
                                                            "'DATAMOVEMENT'", 
                                                            "'DATAOBJNO'", 
                                                            "'DATAOBJ_TO_MAT_PARTITION'", 
                                                            "'DATAOBJ_TO_PARTITION'", 
                                                            "'DATAPUMP'", 
                                                            "'DATA_SECURITY_REWRITE_LIMIT'", 
                                                            "'DATE'", "'DATE_MODE'", 
                                                            "'DAY'", "'DAYS'", 
                                                            "'DBA'", "'DBA_RECYCLEBIN'", 
                                                            "'DBMS_STATS'", 
                                                            "'DB_ROLE_CHANGE'", 
                                                            "'DBTIMEZONE'", 
                                                            "'DB_UNIQUE_NAME'", 
                                                            "'DB_VERSION'", 
                                                            "'DDL'", "'DEALLOCATE'", 
                                                            "'DEBUG'", "'DEBUGGER'", 
                                                            "'DEC'", "'DECIMAL'", 
                                                            "'DECLARE'", 
                                                            "'DECOMPOSE'", 
                                                            "'DECORRELATE'", 
                                                            "'DECR'", "'DECREMENT'", 
                                                            "'DECRYPT'", 
                                                            "'DEDUPLICATE'", 
                                                            "'DEFAULT'", 
                                                            "'DEFAULTS'", 
                                                            "'DEFAULT_COLLATION'", 
                                                            "'DEFAULT_CREDENTIAL'", 
                                                            "'DEFERRABLE'", 
                                                            "'DEFERRED'", 
                                                            "'DEFINED'", 
                                                            "'DEFINE'", 
                                                            "'DEFINER'", 
                                                            "'DEGREE'", 
                                                            "'DELAY'", "'DELEGATE'", 
                                                            "'DELETE_ALL'", 
                                                            "'DELETE'", 
                                                            "'DELETEXML'", 
                                                            "'DEMAND'", 
                                                            "'DENSE_RANKM'", 
                                                            "'DEPENDENT'", 
                                                            "'DEPTH'", "'DEQUEUE'", 
                                                            "'DEREF'", "'DEREF_NO_REWRITE'", 
                                                            "'DESC'", "'DESCRIPTION'", 
                                                            "'DESTROY'", 
                                                            "'DETACHED'", 
                                                            "'DETERMINES'", 
                                                            "'DETERMINISTIC'", 
                                                            "'DICTIONARY'", 
                                                            "'DIMENSION'", 
                                                            "'DIMENSIONS'", 
                                                            "'DIRECT_LOAD'", 
                                                            "'DIRECTORY'", 
                                                            "'DIRECT_PATH'", 
                                                            "'DISABLE_ALL'", 
                                                            "'DISABLE'", 
                                                            "'DISABLE_PARALLEL_DML'", 
                                                            "'DISABLE_PRESET'", 
                                                            "'DISABLE_RPKE'", 
                                                            "'DISALLOW'", 
                                                            "'DISASSOCIATE'", 
                                                            "'DISCARD'", 
                                                            "'DISCONNECT'", 
                                                            "'DISK'", "'DISKGROUP'", 
                                                            "''+ DISKGROUP'", 
                                                            "'DISKS'", "'DISMOUNT'", 
                                                            "'DISTINCT'", 
                                                            "'DISTINGUISHED'", 
                                                            "'DISTRIBUTED'", 
                                                            "'DISTRIBUTE'", 
                                                            "'DML'", "'DML_UPDATE'", 
                                                            "'DOCFIDELITY'", 
                                                            "'DOCUMENT'", 
                                                            "'DOMAIN_INDEX_FILTER'", 
                                                            "'DOMAIN_INDEX_NO_SORT'", 
                                                            "'DOMAIN_INDEX_SORT'", 
                                                            "'DOUBLE'", 
                                                            "'DOWNGRADE'", 
                                                            "'DRIVING_SITE'", 
                                                            "'DROP_COLUMN'", 
                                                            "'DROP'", "'DROP_GROUP'", 
                                                            "'DSINTERVAL_UNCONSTRAINED'", 
                                                            "'DST_UPGRADE_INSERT_CONV'", 
                                                            "'DUMP'", "'DUMPSET'", 
                                                            "'DUPLICATE'", 
                                                            "'DV'", "'DYNAMIC'", 
                                                            "'DYNAMIC_SAMPLING'", 
                                                            "'DYNAMIC_SAMPLING_EST_CDN'", 
                                                            "'E'", "'EACH'", 
                                                            "'EDITIONABLE'", 
                                                            "'EDITION'", 
                                                            "'EDITIONING'", 
                                                            "'EDITIONS'", 
                                                            "'ELEMENT'", 
                                                            "'ELIM_GROUPBY'", 
                                                            "'ELIMINATE_JOIN'", 
                                                            "'ELIMINATE_OBY'", 
                                                            "'ELIMINATE_OUTER_JOIN'", 
                                                            "'ELSE'", "'ELSIF'", 
                                                            "'EM'", "'EMPTY_BLOB'", 
                                                            "'EMPTY_CLOB'", 
                                                            "'EMPTY'", "'ENABLE_ALL'", 
                                                            "'ENABLE'", 
                                                            "'ENABLE_PARALLEL_DML'", 
                                                            "'ENABLE_PRESET'", 
                                                            "'ENCODING'", 
                                                            "'ENCRYPT'", 
                                                            "'ENCRYPTION'", 
                                                            "'END'", "'END_OUTLINE_DATA'", 
                                                            "'ENFORCED'", 
                                                            "'ENFORCE'", 
                                                            "'ENQUEUE'", 
                                                            "'ENTERPRISE'", 
                                                            "'ENTITYESCAPING'", 
                                                            "'ENTRY'", "'EQUIPART'", 
                                                            "'ERR'", "'ERROR_ARGUMENT'", 
                                                            "'ERROR'", "'ERROR_ON_OVERLAP_TIME'", 
                                                            "'ERRORS'", 
                                                            "'ESCAPE'", 
                                                            "'ESTIMATE'", 
                                                            "'EVAL'", "'EVALNAME'", 
                                                            "'EVALUATE'", 
                                                            "'EVALUATION'", 
                                                            "'EVENTS'", 
                                                            "'EVERY'", "'EXCEPT'", 
                                                            "'EXCEPTION'", 
                                                            "'EXCEPTION_INIT'", 
                                                            "'EXCEPTIONS'", 
                                                            "'EXCHANGE'", 
                                                            "'EXCLUDE'", 
                                                            "'EXCLUDING'", 
                                                            "'EXCLUSIVE'", 
                                                            "'EXECUTE'", 
                                                            "'EXEMPT'", 
                                                            "'EXISTING'", 
                                                            "'EXISTS'", 
                                                            "'EXISTSNODE'", 
                                                            "'EXIT'", "'EXPAND_GSET_TO_UNION'", 
                                                            "'EXPAND_TABLE'", 
                                                            "'EXP'", "'EXPIRE'", 
                                                            "'EXPLAIN'", 
                                                            "'EXPLOSION'", 
                                                            "'EXPORT'", 
                                                            "'EXPR_CORR_CHECK'", 
                                                            "'EXPRESS'", 
                                                            "'EXTENDS'", 
                                                            "'EXTENT'", 
                                                            "'EXTENTS'", 
                                                            "'EXTERNAL'", 
                                                            "'EXTERNALLY'", 
                                                            "'EXTRACTCLOBXML'", 
                                                            "'EXTRACT'", 
                                                            "'EXTRACTVALUE'", 
                                                            "'EXTRA'", "'FACILITY'", 
                                                            "'FACT'", "'FACTOR'", 
                                                            "'FACTORIZE_JOIN'", 
                                                            "'FAILED'", 
                                                            "'FAILED_LOGIN_ATTEMPTS'", 
                                                            "'FAILGROUP'", 
                                                            "'FAILOVER'", 
                                                            "'FAILURE'", 
                                                            "'FALSE'", "'FAMILY'", 
                                                            "'FAR'", "'FAST'", 
                                                            "'FASTSTART'", 
                                                            "'FBTSCAN'", 
                                                            "'FEATURE'", 
                                                            "'FEATURE_DETAILS'", 
                                                            "'FEATURE_ID'", 
                                                            "'FEATURE_SET'", 
                                                            "'FEATURE_VALUE'", 
                                                            "'FETCH'", "'FILE'", 
                                                            "'FILE_NAME_CONVERT'", 
                                                            "'FILEGROUP'", 
                                                            "'FILESTORE'", 
                                                            "'FILESYSTEM_LIKE_LOGGING'", 
                                                            "'FILTER'", 
                                                            "'FINAL'", "'FINE'", 
                                                            "'FINISH'", 
                                                            "'FIRST'", "'FIRSTM'", 
                                                            "'FIRST_ROWS'", 
                                                            "'FIRST_VALUE'", 
                                                            "'FIXED_VIEW_DATA'", 
                                                            "'FLAGGER'", 
                                                            "'FLASHBACK'", 
                                                            "'FLASH_CACHE'", 
                                                            "'FLOAT'", "'FLOB'", 
                                                            "'FLEX'", "'FLOOR'", 
                                                            "'FLUSH'", "'FOLDER'", 
                                                            "'FOLLOWING'", 
                                                            "'FOLLOWS'", 
                                                            "'FORALL'", 
                                                            "'FORCE'", "'FORCE_XML_QUERY_REWRITE'", 
                                                            "'FOREIGN'", 
                                                            "'FOREVER'", 
                                                            "'FOR'", "'FORMAT'", 
                                                            "'FORWARD'", 
                                                            "'FRAGMENT_NUMBER'", 
                                                            "'FREELIST'", 
                                                            "'FREELISTS'", 
                                                            "'FREEPOOLS'", 
                                                            "'FRESH'", "'FROM'", 
                                                            "'FROM_TZ'", 
                                                            "'FULL'", "'FULL_OUTER_JOIN_TO_OUTER'", 
                                                            "'FUNCTION'", 
                                                            "'FUNCTIONS'", 
                                                            "'FTP'", "'G'", 
                                                            "'GATHER_OPTIMIZER_STATISTICS'", 
                                                            "'GATHER_PLAN_STATISTICS'", 
                                                            "'GBY_CONC_ROLLUP'", 
                                                            "'GBY_PUSHDOWN'", 
                                                            "'GENERATED'", 
                                                            "'GET'", "'GLOBAL'", 
                                                            "'GLOBALLY'", 
                                                            "'GLOBAL_NAME'", 
                                                            "'GLOBAL_TOPIC_ENABLED'", 
                                                            "'GOTO'", "'GRANT'", 
                                                            "'GROUP_BY'", 
                                                            "'GROUP'", "'GROUP_ID'", 
                                                            "'GROUPING'", 
                                                            "'GROUPING_ID'", 
                                                            "'GROUPS'", 
                                                            "'GUARANTEED'", 
                                                            "'GUARANTEE'", 
                                                            "'GUARD'", "'HALF_YEARS'", 
                                                            "'HASH_AJ'", 
                                                            "'HASH'", "'HASHKEYS'", 
                                                            "'HASH_SJ'", 
                                                            "'HAVING'", 
                                                            "'HEADER'", 
                                                            "'HEAP'", "'HELP'", 
                                                            "'HEXTORAW'", 
                                                            "'HEXTOREF'", 
                                                            "'HIDDEN'", 
                                                            "'HIDE'", "'HIER_ORDER'", 
                                                            "'HIERARCHICAL'", 
                                                            "'HIERARCHIES'", 
                                                            "'HIERARCHY'", 
                                                            "'HIGH'", "'HINTSET_BEGIN'", 
                                                            "'HINTSET_END'", 
                                                            "'HOT'", "'HOUR'", 
                                                            "'HOURS'", "'HTTP'", 
                                                            "'HWM_BROKERED'", 
                                                            "'HYBRID'", 
                                                            "'H'", "'IDENTIFIED'", 
                                                            "'IDENTIFIER'", 
                                                            "'IDENTITY'", 
                                                            "'IDGENERATORS'", 
                                                            "'ID'", "'IDLE_TIME'", 
                                                            "'IF'", "'IGNORE'", 
                                                            "'IGNORE_OPTIM_EMBEDDED_HINTS'", 
                                                            "'IGNORE_ROW_ON_DUPKEY_INDEX'", 
                                                            "'IGNORE_WHERE_CLAUSE'", 
                                                            "'ILM'", "'IMMEDIATE'", 
                                                            "'IMPACT'", 
                                                            "'IMPORT'", 
                                                            "'INACTIVE'", 
                                                            "'INACTIVE_ACCOUNT_TIME'", 
                                                            "'INCLUDE'", 
                                                            "'INCLUDE_VERSION'", 
                                                            "'INCLUDING'", 
                                                            "'INCREMENTAL'", 
                                                            "'INCREMENT'", 
                                                            "'INCR'", "'INDENT'", 
                                                            "'INDEX_ASC'", 
                                                            "'INDEX_COMBINE'", 
                                                            "'INDEX_DESC'", 
                                                            "'INDEXED'", 
                                                            "'INDEXES'", 
                                                            "'INDEX_FFS'", 
                                                            "'INDEX_FILTER'", 
                                                            "'INDEX'", "'INDEXING'", 
                                                            "'INDEX_JOIN'", 
                                                            "'INDEX_ROWS'", 
                                                            "'INDEX_RRS'", 
                                                            "'INDEX_RS_ASC'", 
                                                            "'INDEX_RS_DESC'", 
                                                            "'INDEX_RS'", 
                                                            "'INDEX_SCAN'", 
                                                            "'INDEX_SKIP_SCAN'", 
                                                            "'INDEX_SS_ASC'", 
                                                            "'INDEX_SS_DESC'", 
                                                            "'INDEX_SS'", 
                                                            "'INDEX_STATS'", 
                                                            "'INDEXTYPE'", 
                                                            "'INDEXTYPES'", 
                                                            "'INDICATOR'", 
                                                            "'INDICES'", 
                                                            "'INFINITE'", 
                                                            "'INFORMATIONAL'", 
                                                            "'INHERIT'", 
                                                            "'IN'", "'INITCAP'", 
                                                            "'INITIAL'", 
                                                            "'INITIALIZED'", 
                                                            "'INITIALLY'", 
                                                            "'INITRANS'", 
                                                            "'INLINE'", 
                                                            "'INLINE_XMLTYPE_NT'", 
                                                            "'INMEMORY'", 
                                                            "'IN_MEMORY_METADATA'", 
                                                            "'INMEMORY_PRUNING'", 
                                                            "'INNER'", "'INOUT'", 
                                                            "'INPLACE'", 
                                                            "'INSERTCHILDXMLAFTER'", 
                                                            "'INSERTCHILDXMLBEFORE'", 
                                                            "'INSERTCHILDXML'", 
                                                            "'INSERT'", 
                                                            "'INSERTXMLAFTER'", 
                                                            "'INSERTXMLBEFORE'", 
                                                            "'INSTANCE'", 
                                                            "'INSTANCES'", 
                                                            "'INSTANTIABLE'", 
                                                            "'INSTANTLY'", 
                                                            "'INSTEAD'", 
                                                            "'INSTR2'", 
                                                            "'INSTR4'", 
                                                            "'INSTRB'", 
                                                            "'INSTRC'", 
                                                            "'INSTR'", "'INTEGER'", 
                                                            "'INTERLEAVED'", 
                                                            "'INTERMEDIATE'", 
                                                            "'INTERNAL_CONVERT'", 
                                                            "'INTERNAL_USE'", 
                                                            "'INTERPRETED'", 
                                                            "'INTERSECT'", 
                                                            "'INTERVAL'", 
                                                            "'INT'", "'INTO'", 
                                                            "'INVALIDATE'", 
                                                            "'INVISIBLE'", 
                                                            "'IN_XQUERY'", 
                                                            "'IS'", "'IS_LEAF'", 
                                                            "'ISOLATION'", 
                                                            "'ISOLATION_LEVEL'", 
                                                            "'ITERATE'", 
                                                            "'ITERATION_NUMBER'", 
                                                            "'JAVA'", "'JOB'", 
                                                            "'JOIN'", "'JSON_ARRAYAGG'", 
                                                            "'JSON_ARRAY'", 
                                                            "'JSON_EQUAL'", 
                                                            "'JSON_EXISTS2'", 
                                                            "'JSON_EXISTS'", 
                                                            "'JSONGET'", 
                                                            "'JSON'", "'JSON_OBJECTAGG'", 
                                                            "'JSON_OBJECT'", 
                                                            "'JSONPARSE'", 
                                                            "'JSON_QUERY'", 
                                                            "'JSON_SERIALIZE'", 
                                                            "'JSON_TABLE'", 
                                                            "'JSON_TEXTCONTAINS2'", 
                                                            "'JSON_TEXTCONTAINS'", 
                                                            "'JSON_TRANSFORM'", 
                                                            "'JSON_VALUE'", 
                                                            "'K'", "'KEEP_DUPLICATES'", 
                                                            "'KEEP'", "'KERBEROS'", 
                                                            "'KEY'", "'KEY_LENGTH'", 
                                                            "'KEYSIZE'", 
                                                            "'KEYS'", "'KEYSTORE'", 
                                                            "'KILL'", "'LABEL'", 
                                                            "'LANGUAGE'", 
                                                            "'LAST_DAY'", 
                                                            "'LAST'", "'LAST_VALUE'", 
                                                            "'LATERAL'", 
                                                            "'LAX'", "'LAYER'", 
                                                            "'LDAP_REGISTRATION_ENABLED'", 
                                                            "'LDAP_REGISTRATION'", 
                                                            "'LDAP_REG_SYNC_INTERVAL'", 
                                                            "'LEAF'", "'LEAD_CDB'", 
                                                            "'LEAD_CDB_URI'", 
                                                            "'LEADING'", 
                                                            "'LEFT'", "'LENGTH2'", 
                                                            "'LENGTH4'", 
                                                            "'LENGTHB'", 
                                                            "'LENGTHC'", 
                                                            "'LENGTH'", 
                                                            "'LESS'", "'LEVEL'", 
                                                            "'LEVEL_NAME'", 
                                                            "'LEVELS'", 
                                                            "'LIBRARY'", 
                                                            "'LIFECYCLE'", 
                                                            "'LIFE'", "'LIFETIME'", 
                                                            "'LIKE2'", "'LIKE4'", 
                                                            "'LIKEC'", "'LIKE_EXPAND'", 
                                                            "'LIKE'", "'LIMIT'", 
                                                            "'LINEAR'", 
                                                            "'LINK'", "'LIST'", 
                                                            "'LN'", "'LNNVL'", 
                                                            "'LOAD'", "'LOB'", 
                                                            "'LOBNVL'", 
                                                            "'LOBS'", "'LOCAL_INDEXES'", 
                                                            "'LOCAL'", "'LOCALTIME'", 
                                                            "'LOCALTIMESTAMP'", 
                                                            "'LOCATION'", 
                                                            "'LOCATOR'", 
                                                            "'LOCKDOWN'", 
                                                            "'LOCKED'", 
                                                            "'LOCKING'", 
                                                            "'LOCK'", "'LOGFILE'", 
                                                            "'LOGFILES'", 
                                                            "'LOGGING'", 
                                                            "'LOGICAL'", 
                                                            "'LOGICAL_READS_PER_CALL'", 
                                                            "'LOGICAL_READS_PER_SESSION'", 
                                                            "'LOG'", "'LOGMINING'", 
                                                            "'LOGOFF'", 
                                                            "'LOGON'", "'LOG_READ_ONLY_VIOLATIONS'", 
                                                            "'LONG'", "'LOOP'", 
                                                            "'LOST'", "'LOWER'", 
                                                            "'LOW'", "'LPAD'", 
                                                            "'LTRIM'", "'M'", 
                                                            "'MAIN'", "'MAKE_REF'", 
                                                            "'MANAGED'", 
                                                            "'MANAGE'", 
                                                            "'MANAGEMENT'", 
                                                            "'MANAGER'", 
                                                            "'MANDATORY'", 
                                                            "'MANUAL'", 
                                                            "'MAP'", "'MAPPING'", 
                                                            "'MASTER'", 
                                                            "'MATCHED'", 
                                                            "'MATCHES'", 
                                                            "'MATCH'", "'MATCH_NUMBER'", 
                                                            "'MATCH_RECOGNIZE'", 
                                                            "'MATERIALIZED'", 
                                                            "'MATERIALIZE'", 
                                                            "'MAXARCHLOGS'", 
                                                            "'MAXDATAFILES'", 
                                                            "'MAXEXTENTS'", 
                                                            "'MAXIMIZE'", 
                                                            "'MAXINSTANCES'", 
                                                            "'MAXLOGFILES'", 
                                                            "'MAXLOGHISTORY'", 
                                                            "'MAXLOGMEMBERS'", 
                                                            "'MAX_SHARED_TEMP_SIZE'", 
                                                            "'MAXSIZE'", 
                                                            "'MAXTRANS'", 
                                                            "'MAXVALUE'", 
                                                            "'MEASURE'", 
                                                            "'MEASURES'", 
                                                            "'MEDIUM'", 
                                                            "'MEMBER'", 
                                                            "'MEMBER_CAPTION'", 
                                                            "'MEMBER_DESCRIPTION'", 
                                                            "'MEMBER_NAME'", 
                                                            "'MEMBER_UNIQUE_NAME'", 
                                                            "'MEMCOMPRESS'", 
                                                            "'MEMORY'", 
                                                            "'MERGE$ACTIONS'", 
                                                            "'MERGE_AJ'", 
                                                            "'MERGE_CONST_ON'", 
                                                            "'MERGE'", "'MERGE_SJ'", 
                                                            "'METADATA'", 
                                                            "'METHOD'", 
                                                            "'MIGRATE'", 
                                                            "'MIGRATION'", 
                                                            "'MINEXTENTS'", 
                                                            "'MINIMIZE'", 
                                                            "'MINIMUM'", 
                                                            "'MINING'", 
                                                            "'MINUS'", "'MINUS_NULL'", 
                                                            "'MINUTE'", 
                                                            "'MINUTES'", 
                                                            "'MINVALUE'", 
                                                            "'MIRRORCOLD'", 
                                                            "'MIRRORHOT'", 
                                                            "'MIRROR'", 
                                                            "'MISSING'", 
                                                            "'MISMATCH'", 
                                                            "'MLSLABEL'", 
                                                            "'MODEL_COMPILE_SUBQUERY'", 
                                                            "'MODEL_DONTVERIFY_UNIQUENESS'", 
                                                            "'MODEL_DYNAMIC_SUBQUERY'", 
                                                            "'MODEL_MIN_ANALYSIS'", 
                                                            "'MODEL'", "'MODEL_NB'", 
                                                            "'MODEL_NO_ANALYSIS'", 
                                                            "'MODEL_PBY'", 
                                                            "'MODEL_PUSH_REF'", 
                                                            "'MODEL_SV'", 
                                                            "'MODE'", "'MODIFICATION'", 
                                                            "'MODIFY_COLUMN_TYPE'", 
                                                            "'MODIFY'", 
                                                            "'MOD'", "'MODULE'", 
                                                            "'MONITORING'", 
                                                            "'MONITOR'", 
                                                            "'MONTH'", "'MONTHS_BETWEEN'", 
                                                            "'MONTHS'", 
                                                            "'MOUNT'", "'MOUNTPATH'", 
                                                            "'MOUNTPOINT'", 
                                                            "'MOVEMENT'", 
                                                            "'MOVE'", "'MULTIDIMENSIONAL'", 
                                                            "'MULTISET'", 
                                                            "'MV_MERGE'", 
                                                            "'NAMED'", "'NAME'", 
                                                            "'NAMESPACE'", 
                                                            "'NAN'", "'NANVL'", 
                                                            "'NATIONAL'", 
                                                            "'NATIVE_FULL_OUTER_JOIN'", 
                                                            "'NATIVE'", 
                                                            "'NATURAL'", 
                                                            "'NATURALN'", 
                                                            "'NAV'", "'NCHAR_CS'", 
                                                            "'NCHAR'", "'NCHR'", 
                                                            "'NCLOB'", "'NEEDED'", 
                                                            "'NEG'", "'NESTED'", 
                                                            "'NESTED_TABLE_FAST_INSERT'", 
                                                            "'NESTED_TABLE_GET_REFS'", 
                                                            "'NESTED_TABLE_ID'", 
                                                            "'NESTED_TABLE_SET_REFS'", 
                                                            "'NESTED_TABLE_SET_SETID'", 
                                                            "'NETWORK'", 
                                                            "'NEVER'", "'NEW'", 
                                                            "'NEW_TIME'", 
                                                            "'NEXT_DAY'", 
                                                            "'NEXT'", "'NL_AJ'", 
                                                            "'NLJ_BATCHING'", 
                                                            "'NLJ_INDEX_FILTER'", 
                                                            "'NLJ_INDEX_SCAN'", 
                                                            "'NLJ_PREFETCH'", 
                                                            "'NLS_CALENDAR'", 
                                                            "'NLS_CHARACTERSET'", 
                                                            "'NLS_CHARSET_DECL_LEN'", 
                                                            "'NLS_CHARSET_ID'", 
                                                            "'NLS_CHARSET_NAME'", 
                                                            "'NLS_COMP'", 
                                                            "'NLS_CURRENCY'", 
                                                            "'NLS_DATE_FORMAT'", 
                                                            "'NLS_DATE_LANGUAGE'", 
                                                            "'NLS_INITCAP'", 
                                                            "'NLS_ISO_CURRENCY'", 
                                                            "'NL_SJ'", "'NLS_LANG'", 
                                                            "'NLS_LANGUAGE'", 
                                                            "'NLS_LENGTH_SEMANTICS'", 
                                                            "'NLS_LOWER'", 
                                                            "'NLS_NCHAR_CONV_EXCP'", 
                                                            "'NLS_NUMERIC_CHARACTERS'", 
                                                            "'NLS_SORT'", 
                                                            "'NLSSORT'", 
                                                            "'NLS_SPECIAL_CHARS'", 
                                                            "'NLS_TERRITORY'", 
                                                            "'NLS_UPPER'", 
                                                            "'NO_ACCESS'", 
                                                            "'NO_ADAPTIVE_PLAN'", 
                                                            "'NO_ANSI_REARCH'", 
                                                            "'NOAPPEND'", 
                                                            "'NOARCHIVELOG'", 
                                                            "'NOAUDIT'", 
                                                            "'NO_AUTO_REOPTIMIZE'", 
                                                            "'NO_BASETABLE_MULTIMV_REWRITE'", 
                                                            "'NO_BATCH_TABLE_ACCESS_BY_ROWID'", 
                                                            "'NO_BIND_AWARE'", 
                                                            "'NO_BUFFER'", 
                                                            "'NOCACHE'", 
                                                            "'NO_CARTESIAN'", 
                                                            "'NO_CHECK_ACL_REWRITE'", 
                                                            "'NO_CLUSTER_BY_ROWID'", 
                                                            "'NO_CLUSTERING'", 
                                                            "'NO_COALESCE_SQ'", 
                                                            "'NO_COMMON_DATA'", 
                                                            "'NOCOMPRESS'", 
                                                            "'NO_CONNECT_BY_CB_WHR_ONLY'", 
                                                            "'NO_CONNECT_BY_COMBINE_SW'", 
                                                            "'NO_CONNECT_BY_COST_BASED'", 
                                                            "'NO_CONNECT_BY_ELIM_DUPS'", 
                                                            "'NO_CONNECT_BY_FILTERING'", 
                                                            "'NOCOPY'", 
                                                            "'NO_COST_XML_QUERY_REWRITE'", 
                                                            "'NO_CPU_COSTING'", 
                                                            "'NOCPU_COSTING'", 
                                                            "'NOCYCLE'", 
                                                            "'NO_DATA_SECURITY_REWRITE'", 
                                                            "'NO_DECORRELATE'", 
                                                            "'NODELAY'", 
                                                            "'NO_DOMAIN_INDEX_FILTER'", 
                                                            "'NO_DST_UPGRADE_INSERT_CONV'", 
                                                            "'NO_ELIM_GROUPBY'", 
                                                            "'NO_ELIMINATE_JOIN'", 
                                                            "'NO_ELIMINATE_OBY'", 
                                                            "'NO_ELIMINATE_OUTER_JOIN'", 
                                                            "'NOENTITYESCAPING'", 
                                                            "'NO_EXPAND_GSET_TO_UNION'", 
                                                            "'NO_EXPAND'", 
                                                            "'NO_EXPAND_TABLE'", 
                                                            "'NO_FACT'", 
                                                            "'NO_FACTORIZE_JOIN'", 
                                                            "'NO_FILTERING'", 
                                                            "'NOFORCE'", 
                                                            "'NO_FULL_OUTER_JOIN_TO_OUTER'", 
                                                            "'NO_GATHER_OPTIMIZER_STATISTICS'", 
                                                            "'NO_GBY_PUSHDOWN'", 
                                                            "'NOGUARANTEE'", 
                                                            "'NO_INDEX_FFS'", 
                                                            "'NO_INDEX'", 
                                                            "'NO_INDEX_SS'", 
                                                            "'NO_INMEMORY'", 
                                                            "'NO_INMEMORY_PRUNING'", 
                                                            "'NOKEEP'", 
                                                            "'NO_LOAD'", 
                                                            "'NOLOCAL'", 
                                                            "'NOLOGGING'", 
                                                            "'NOMAPPING'", 
                                                            "'NOMAXVALUE'", 
                                                            "'NO_MERGE'", 
                                                            "'NOMINIMIZE'", 
                                                            "'NOMINVALUE'", 
                                                            "'NO_MODEL_PUSH_REF'", 
                                                            "'NO_MONITORING'", 
                                                            "'NOMONITORING'", 
                                                            "'NO_MONITOR'", 
                                                            "'NO_MULTIMV_REWRITE'", 
                                                            "'NO_NATIVE_FULL_OUTER_JOIN'", 
                                                            "'NONBLOCKING'", 
                                                            "'NONEDITIONABLE'", 
                                                            "'NONE'", "'NO_NLJ_BATCHING'", 
                                                            "'NO_NLJ_PREFETCH'", 
                                                            "'NO'", "'NONSCHEMA'", 
                                                            "'NO_OBJECT_LINK'", 
                                                            "'NOORDER'", 
                                                            "'NO_ORDER_ROLLUPS'", 
                                                            "'NO_OUTER_JOIN_TO_ANTI'", 
                                                            "'NO_OUTER_JOIN_TO_INNER'", 
                                                            "'NOOVERRIDE'", 
                                                            "'NO_PARALLEL_INDEX'", 
                                                            "'NOPARALLEL_INDEX'", 
                                                            "'NO_PARALLEL'", 
                                                            "'NOPARALLEL'", 
                                                            "'NO_PARTIAL_COMMIT'", 
                                                            "'NO_PARTIAL_JOIN'", 
                                                            "'NO_PARTIAL_ROLLUP_PUSHDOWN'", 
                                                            "'NOPARTITION'", 
                                                            "'NO_PLACE_DISTINCT'", 
                                                            "'NO_PLACE_GROUP_BY'", 
                                                            "'NO_PQ_CONCURRENT_UNION'", 
                                                            "'NO_PQ_MAP'", 
                                                            "'NOPROMPT'", 
                                                            "'NO_PQ_REPLICATE'", 
                                                            "'NO_PQ_SKEW'", 
                                                            "'NO_PRUNE_GSETS'", 
                                                            "'NO_PULL_PRED'", 
                                                            "'NO_PUSH_PRED'", 
                                                            "'NO_PUSH_SUBQ'", 
                                                            "'NO_PX_FAULT_TOLERANCE'", 
                                                            "'NO_PX_JOIN_FILTER'", 
                                                            "'NO_QKN_BUFF'", 
                                                            "'NO_QUERY_TRANSFORMATION'", 
                                                            "'NO_REF_CASCADE'", 
                                                            "'NORELOCATE'", 
                                                            "'NORELY'", 
                                                            "'NOREPAIR'", 
                                                            "'NOREPLAY'", 
                                                            "'NORESETLOGS'", 
                                                            "'NO_RESULT_CACHE'", 
                                                            "'NOREVERSE'", 
                                                            "'NO_REWRITE'", 
                                                            "'NOREWRITE'", 
                                                            "'NORMAL'", 
                                                            "'NO_ROOT_SW_FOR_LOCAL'", 
                                                            "'NOROWDEPENDENCIES'", 
                                                            "'NOSCHEMACHECK'", 
                                                            "'NOSEGMENT'", 
                                                            "'NO_SEMIJOIN'", 
                                                            "'NO_SEMI_TO_INNER'", 
                                                            "'NO_SET_TO_JOIN'", 
                                                            "'NOSORT'", 
                                                            "'NO_SQL_TRANSLATION'", 
                                                            "'NO_SQL_TUNE'", 
                                                            "'NO_STAR_TRANSFORMATION'", 
                                                            "'NO_STATEMENT_QUEUING'", 
                                                            "'NO_STATS_GSETS'", 
                                                            "'NOSTRICT'", 
                                                            "'NO_SUBQUERY_PRUNING'", 
                                                            "'NO_SUBSTRB_PAD'", 
                                                            "'NO_SWAP_JOIN_INPUTS'", 
                                                            "'NOSWITCH'", 
                                                            "'NO_TABLE_LOOKUP_BY_NL'", 
                                                            "'NO_TEMP_TABLE'", 
                                                            "'NOTHING'", 
                                                            "'NOTIFICATION'", 
                                                            "'NOT'", "'NO_TRANSFORM_DISTINCT_AGG'", 
                                                            "'NO_UNNEST'", 
                                                            "'NO_USE_CUBE'", 
                                                            "'NO_USE_HASH_AGGREGATION'", 
                                                            "'NO_USE_HASH_GBY_FOR_PUSHDOWN'", 
                                                            "'NO_USE_HASH'", 
                                                            "'NO_USE_INVISIBLE_INDEXES'", 
                                                            "'NO_USE_MERGE'", 
                                                            "'NO_USE_NL'", 
                                                            "'NO_USE_VECTOR_AGGREGATION'", 
                                                            "'NOVALIDATE'", 
                                                            "'NO_VECTOR_TRANSFORM_DIMS'", 
                                                            "'NO_VECTOR_TRANSFORM_FACT'", 
                                                            "'NO_VECTOR_TRANSFORM'", 
                                                            "'NOWAIT'", 
                                                            "'NO_XDB_FASTPATH_INSERT'", 
                                                            "'NO_XML_DML_REWRITE'", 
                                                            "'NO_XMLINDEX_REWRITE_IN_SELECT'", 
                                                            "'NO_XMLINDEX_REWRITE'", 
                                                            "'NO_XML_QUERY_REWRITE'", 
                                                            "'NO_ZONEMAP'", 
                                                            "'NTH_VALUE'", 
                                                            "'NULLIF'", 
                                                            "'NULL'", "'NULLS'", 
                                                            "'NUMBER'", 
                                                            "'NUMERIC'", 
                                                            "'NUM_INDEX_KEYS'", 
                                                            "'NUMTODSINTERVAL'", 
                                                            "'NUMTOYMINTERVAL'", 
                                                            "'NVARCHAR2'", 
                                                            "'NVL2'", "'OBJECT2XML'", 
                                                            "'OBJECT'", 
                                                            "'OBJ_ID'", 
                                                            "'OBJNO'", "'OBJNO_REUSE'", 
                                                            "'OCCURENCES'", 
                                                            "'OFFLINE'", 
                                                            "'OFF'", "'OFFSET'", 
                                                            "'OF'", "'OIDINDEX'", 
                                                            "'OID'", "'OLAP'", 
                                                            "'OLD'", "'OLD_PUSH_PRED'", 
                                                            "'OLS'", "'OLTP'", 
                                                            "'OMIT'", "'ONE'", 
                                                            "'ONLINE'", 
                                                            "'ONLINELOG'", 
                                                            "'ONLY'", "'ON'", 
                                                            "'OPAQUE'", 
                                                            "'OPAQUE_TRANSFORM'", 
                                                            "'OPAQUE_XCANONICAL'", 
                                                            "'OPCODE'", 
                                                            "'OPEN'", "'OPERATIONS'", 
                                                            "'OPERATOR'", 
                                                            "'OPT_ESTIMATE'", 
                                                            "'OPTIMAL'", 
                                                            "'OPTIMIZE'", 
                                                            "'OPTIMIZER_FEATURES_ENABLE'", 
                                                            "'OPTIMIZER_GOAL'", 
                                                            "'OPTION'", 
                                                            "'OPT_PARAM'", 
                                                            "'ORA_BRANCH'", 
                                                            "'ORA_CHECK_ACL'", 
                                                            "'ORA_CHECK_PRIVILEGE'", 
                                                            "'ORA_CLUSTERING'", 
                                                            "'ORADATA'", 
                                                            "'ORADEBUG'", 
                                                            "'ORA_DST_AFFECTED'", 
                                                            "'ORA_DST_CONVERT'", 
                                                            "'ORA_DST_ERROR'", 
                                                            "'ORA_GET_ACLIDS'", 
                                                            "'ORA_GET_PRIVILEGES'", 
                                                            "'ORA_HASH'", 
                                                            "'ORA_INVOKING_USERID'", 
                                                            "'ORA_INVOKING_USER'", 
                                                            "'ORA_INVOKING_XS_USER_GUID'", 
                                                            "'ORA_INVOKING_XS_USER'", 
                                                            "'ORA_RAWCOMPARE'", 
                                                            "'ORA_RAWCONCAT'", 
                                                            "'ORA_ROWSCN'", 
                                                            "'ORA_ROWSCN_RAW'", 
                                                            "'ORA_ROWVERSION'", 
                                                            "'ORA_TABVERSION'", 
                                                            "'ORA_WRITE_TIME'", 
                                                            "'ORDERED'", 
                                                            "'ORDERED_PREDICATES'", 
                                                            "'ORDER'", "'ORDINALITY'", 
                                                            "'OR_EXPAND'", 
                                                            "'ORGANIZATION'", 
                                                            "'OR'", "'OR_PREDICATES'", 
                                                            "'OSERROR'", 
                                                            "'OTHER'", "'OUTER_JOIN_TO_ANTI'", 
                                                            "'OUTER_JOIN_TO_INNER'", 
                                                            "'OUTER'", "'OUTLINE_LEAF'", 
                                                            "'OUTLINE'", 
                                                            "'OUT_OF_LINE'", 
                                                            "'OUT'", "'OVERFLOW_NOMOVE'", 
                                                            "'OVERFLOW'", 
                                                            "'OVERLAPS'", 
                                                            "'OVER'", "'OVERRIDING'", 
                                                            "'OWNER'", "'OWNERSHIP'", 
                                                            "'OWN'", "'P'", 
                                                            "'PACKAGE'", 
                                                            "'PACKAGES'", 
                                                            "'PARALLEL_ENABLE'", 
                                                            "'PARALLEL_INDEX'", 
                                                            "'PARALLEL'", 
                                                            "'PARAMETERFILE'", 
                                                            "'PARAMETERS'", 
                                                            "'PARAM'", "'PARENT'", 
                                                            "'PARENT_LEVEL_NAME'", 
                                                            "'PARENT_UNIQUE_NAME'", 
                                                            "'PARITY'", 
                                                            "'PARTIAL_JOIN'", 
                                                            "'PARTIALLY'", 
                                                            "'PARTIAL'", 
                                                            "'PARTIAL_ROLLUP_PUSHDOWN'", 
                                                            "'PARTITION_HASH'", 
                                                            "'PARTITION_LIST'", 
                                                            "'PARTITION'", 
                                                            "'PARTITION_RANGE'", 
                                                            "'PARTITIONS'", 
                                                            "'PART$NUM$INST'", 
                                                            "'PASSING'", 
                                                            "'PASSWORD_GRACE_TIME'", 
                                                            "'PASSWORD_LIFE_TIME'", 
                                                            "'PASSWORD_LOCK_TIME'", 
                                                            "'PASSWORD'", 
                                                            "'PASSWORD_REUSE_MAX'", 
                                                            "'PASSWORD_REUSE_TIME'", 
                                                            "'PASSWORD_ROLLOVER_TIME'", 
                                                            "'PASSWORD_VERIFY_FUNCTION'", 
                                                            "'PAST'", "'PATCH'", 
                                                            "'PATH'", "'PATH_PREFIX'", 
                                                            "'PATHS'", "'PATTERN'", 
                                                            "'PBL_HS_BEGIN'", 
                                                            "'PBL_HS_END'", 
                                                            "'PCTFREE'", 
                                                            "'PCTINCREASE'", 
                                                            "'PCTTHRESHOLD'", 
                                                            "'PCTUSED'", 
                                                            "'PCTVERSION'", 
                                                            "'PENDING'", 
                                                            null, null, 
                                                            null, "'PERCENT'", 
                                                            "'PERCENT_RANKM'", 
                                                            null, null, 
                                                            null, "'PERFORMANCE'", 
                                                            "'PERIOD'", 
                                                            "'PERMANENT'", 
                                                            "'PERMISSION'", 
                                                            "'PERMUTE'", 
                                                            "'PER'", "'PFILE'", 
                                                            "'PHYSICAL'", 
                                                            "'PIKEY'", "'PIPELINED'", 
                                                            "'PIPE'", "'PIV_GB'", 
                                                            "'PIVOT'", "'PIV_SSF'", 
                                                            "'PLACE_DISTINCT'", 
                                                            "'PLACE_GROUP_BY'", 
                                                            "'PLAN'", "'PLSCOPE_SETTINGS'", 
                                                            "'PLS_INTEGER'", 
                                                            "'PLSQL_CCFLAGS'", 
                                                            "'PLSQL_CODE_TYPE'", 
                                                            "'PLSQL_DEBUG'", 
                                                            "'PLSQL_OPTIMIZE_LEVEL'", 
                                                            "'PLSQL_WARNINGS'", 
                                                            "'PLUGGABLE'", 
                                                            "'PMEM'", "'POINT'", 
                                                            "'POLICY'", 
                                                            "'POOL_16K'", 
                                                            "'POOL_2K'", 
                                                            "'POOL_32K'", 
                                                            "'POOL_4K'", 
                                                            "'POOL_8K'", 
                                                            "'POSITIVEN'", 
                                                            "'POSITIVE'", 
                                                            "'POST_TRANSACTION'", 
                                                            "'POWERMULTISET_BY_CARDINALITY'", 
                                                            "'POWERMULTISET'", 
                                                            "'POWER'", "'PQ_CONCURRENT_UNION'", 
                                                            "'PQ_DISTRIBUTE'", 
                                                            "'PQ_DISTRIBUTE_WINDOW'", 
                                                            "'PQ_FILTER'", 
                                                            "'PQ_MAP'", 
                                                            "'PQ_NOMAP'", 
                                                            "'PQ_REPLICATE'", 
                                                            "'PQ_SKEW'", 
                                                            "'PRAGMA'", 
                                                            "'PREBUILT'", 
                                                            "'PRECEDES'", 
                                                            "'PRECEDING'", 
                                                            "'PRECISION'", 
                                                            "'PRECOMPUTE_SUBQUERY'", 
                                                            "'PREDICATE_REORDERS'", 
                                                            "'PRELOAD'", 
                                                            "'PREPARE'", 
                                                            "'PRESENTNNV'", 
                                                            "'PRESENT'", 
                                                            "'PRESENTV'", 
                                                            "'PRESERVE_OID'", 
                                                            "'PRESERVE'", 
                                                            "'PRETTY'", 
                                                            "'PREVIOUS'", 
                                                            "'PREV'", "'PRIMARY'", 
                                                            "'PRINTBLOBTOCLOB'", 
                                                            "'PRIORITY'", 
                                                            "'PRIOR'", "'PRIVATE'", 
                                                            "'PRIVATE_SGA'", 
                                                            "'PRIVILEGED'", 
                                                            "'PRIVILEGE'", 
                                                            "'PRIVILEGES'", 
                                                            "'PROCEDURAL'", 
                                                            "'PROCEDURE'", 
                                                            "'PROCESS'", 
                                                            "'PROFILE'", 
                                                            "'PROGRAM'", 
                                                            "'PROJECT'", 
                                                            "'PROPAGATE'", 
                                                            "'PROPERTY'", 
                                                            "'PROTECTED'", 
                                                            "'PROTECTION'", 
                                                            "'PROTOCOL'", 
                                                            "'PROXY'", "'PRUNING'", 
                                                            "'PUBLIC'", 
                                                            "'PULL_PRED'", 
                                                            "'PURGE'", "'PUSH_PRED'", 
                                                            "'PUSH_SUBQ'", 
                                                            "'PX_FAULT_TOLERANCE'", 
                                                            "'PX_GRANULE'", 
                                                            "'PX_JOIN_FILTER'", 
                                                            "'QB_NAME'", 
                                                            "'QUARTERS'", 
                                                            "'QUERY_BLOCK'", 
                                                            "'QUERY'", "'QUEUE_CURR'", 
                                                            "'QUEUE'", "'QUEUE_ROWP'", 
                                                            "'QUIESCE'", 
                                                            "'QUORUM'", 
                                                            "'QUOTA'", "'QUOTAGROUP'", 
                                                            "'RAISE'", "'RANDOM_LOCAL'", 
                                                            "'RANDOM'", 
                                                            "'RANGE'", "'RANKM'", 
                                                            "'RAPIDLY'", 
                                                            "'RAW'", "'RAWTOHEX'", 
                                                            "'RAWTONHEX'", 
                                                            "'RBA'", "'RBO_OUTLINE'", 
                                                            "'RDBA'", "'READ'", 
                                                            "'READS'", "'REALM'", 
                                                            "'REAL'", "'REBALANCE'", 
                                                            "'REBUILD'", 
                                                            "'RECORD'", 
                                                            "'RECORDS_PER_BLOCK'", 
                                                            "'RECOVERABLE'", 
                                                            "'RECOVER'", 
                                                            "'RECOVERY'", 
                                                            "'RECYCLEBIN'", 
                                                            "'RECYCLE'", 
                                                            "'REDACTION'", 
                                                            "'REDEFINE'", 
                                                            "'REDO'", "'REDUCED'", 
                                                            "'REDUNDANCY'", 
                                                            "'REF_CASCADE_CURSOR'", 
                                                            "'REFERENCED'", 
                                                            "'REFERENCE'", 
                                                            "'REFERENCES'", 
                                                            "'REFERENCING'", 
                                                            "'REF'", "'REFRESH'", 
                                                            "'REFTOHEX'", 
                                                            "'REGEXP_COUNT'", 
                                                            "'REGEXP_INSTR'", 
                                                            "'REGEXP_LIKE'", 
                                                            "'REGEXP_REPLACE'", 
                                                            "'REGEXP_SUBSTR'", 
                                                            "'REGISTER'", 
                                                            "'REGR_AVGX'", 
                                                            "'REGR_AVGY'", 
                                                            "'REGR_COUNT'", 
                                                            "'REGR_INTERCEPT'", 
                                                            "'REGR_R2'", 
                                                            "'REGR_SLOPE'", 
                                                            "'REGR_SXX'", 
                                                            "'REGR_SXY'", 
                                                            "'REGR_SYY'", 
                                                            "'REGULAR'", 
                                                            "'REJECT'", 
                                                            "'REKEY'", "'RELATIONAL'", 
                                                            "'RELIES_ON'", 
                                                            "'RELOCATE'", 
                                                            "'RELY'", "'REMAINDER'", 
                                                            "'REMOTE'", 
                                                            "'REMOTE_MAPPED'", 
                                                            "'REMOVE'", 
                                                            "'RENAME'", 
                                                            "'REPAIR'", 
                                                            "'REPEAT'", 
                                                            "'REPLACE'", 
                                                            "'REPLICATION'", 
                                                            "'REQUIRED'", 
                                                            "'RESETLOGS'", 
                                                            "'RESET'", "'RESIZE'", 
                                                            "'RESOLVE'", 
                                                            "'RESOLVER'", 
                                                            "'RESOURCE'", 
                                                            "'RESPECT'", 
                                                            "'RESTART'", 
                                                            "'RESTORE_AS_INTERVALS'", 
                                                            "'RESTORE'", 
                                                            "'RESTRICT_ALL_REF_CONS'", 
                                                            "'RESTRICTED'", 
                                                            "'RESTRICT_REFERENCES'", 
                                                            "'RESTRICT'", 
                                                            "'RESULT_CACHE'", 
                                                            "'RESULT'", 
                                                            "'RESUMABLE'", 
                                                            "'RESUME'", 
                                                            "'RETENTION'", 
                                                            "'RETRY_ON_ROW_CHANGE'", 
                                                            "'RETURNING'", 
                                                            "'RETURN'", 
                                                            "'REUSE'", "'REVERSE'", 
                                                            "'REVOKE'", 
                                                            "'REWRITE_OR_ERROR'", 
                                                            "'REWRITE'", 
                                                            "'RIGHT'", "'ROLE'", 
                                                            "'ROLESET'", 
                                                            "'ROLES'", "'ROLLBACK'", 
                                                            "'ROLLING'", 
                                                            "'ROLLUP'", 
                                                            "'ROWDEPENDENCIES'", 
                                                            "'ROWID_MAPPING_TABLE'", 
                                                            "'ROWID'", "'ROWIDTOCHAR'", 
                                                            "'ROWIDTONCHAR'", 
                                                            "'ROW_LENGTH'", 
                                                            "'ROWNUM'", 
                                                            "'ROW'", "'ROWS'", 
                                                            "'RPAD'", "'RTRIM'", 
                                                            "'RULE'", "'RULES'", 
                                                            "'RUNNING'", 
                                                            "'SALT'", "'SAMPLE'", 
                                                            "'SAVE_AS_INTERVALS'", 
                                                            "'SAVEPOINT'", 
                                                            "'SAVE'", "'SB4'", 
                                                            "'SCALE_ROWS'", 
                                                            "'SCALE'", "'SCAN_INSTANCES'", 
                                                            "'SCAN'", "'SCHEDULER'", 
                                                            "'SCHEMACHECK'", 
                                                            "'SCHEMA'", 
                                                            "'SCN_ASCENDING'", 
                                                            "'SCN'", "'SCOPE'", 
                                                            "'SCRUB'", "'SD_ALL'", 
                                                            "'SD_INHIBIT'", 
                                                            "'SDO_GEOM_MBR'", 
                                                            "'SDO_GEOMETRY'", 
                                                            "'SD_SHOW'", 
                                                            "'SEARCH'", 
                                                            "'SECOND'", 
                                                            "'SECONDS'", 
                                                            "'SECRET'", 
                                                            "'SECUREFILE_DBA'", 
                                                            "'SECUREFILE'", 
                                                            "'SECURITY'", 
                                                            "'SEED'", "'SEG_BLOCK'", 
                                                            "'SEG_FILE'", 
                                                            "'SEGMENT'", 
                                                            "'SELECTIVITY'", 
                                                            "'SELECT'", 
                                                            "'SELF'", "'SEMIJOIN_DRIVER'", 
                                                            "'SEMIJOIN'", 
                                                            "'SEMI_TO_INNER'", 
                                                            "'SEQUENCED'", 
                                                            "'SEQUENCE'", 
                                                            "'SEQUENTIAL'", 
                                                            "'SEQ'", "'SERIALIZABLE'", 
                                                            "'SERIALLY_REUSABLE'", 
                                                            "'SERIAL'", 
                                                            "'SERVERERROR'", 
                                                            "'SERVICE_NAME_CONVERT'", 
                                                            "'SERVICE'", 
                                                            "'SERVICES'", 
                                                            "'SESSION_CACHED_CURSORS'", 
                                                            "'SESSION'", 
                                                            "'SESSIONS_PER_USER'", 
                                                            "'SESSIONTIMEZONE'", 
                                                            "'SESSIONTZNAME'", 
                                                            "'SET'", "'SETS'", 
                                                            "'SETTINGS'", 
                                                            "'SET_TO_JOIN'", 
                                                            "'SEVERE'", 
                                                            "'SHARDSPACE'", 
                                                            "'SHARED_POOL'", 
                                                            "'SHARED'", 
                                                            "'SHARE'", "'SHARING'", 
                                                            "'SHELFLIFE'", 
                                                            "'SHOW'", "'SHRINK'", 
                                                            "'SHUTDOWN'", 
                                                            "'SIBLINGS'", 
                                                            "'SID'", "'SITE'", 
                                                            "'SIGNAL_COMPONENT'", 
                                                            "'SIGNAL_FUNCTION'", 
                                                            "'SIGN'", "'SIGNTYPE'", 
                                                            "'SIMPLE_INTEGER'", 
                                                            "'SIMPLE'", 
                                                            "'SINGLE'", 
                                                            "'SINGLETASK'", 
                                                            "'SINH'", "'SIN'", 
                                                            "'SIZE'", "'SKIP_EXT_OPTIMIZER'", 
                                                            "'SKIP'", "'SKIP_UNQ_UNUSABLE_IDX'", 
                                                            "'SKIP_UNUSABLE_INDEXES'", 
                                                            "'SMALLFILE'", 
                                                            "'SMALLINT'", 
                                                            "'SNAPSHOT'", 
                                                            "'SOME'", "'SORT'", 
                                                            "'SOUNDEX'", 
                                                            "'SOURCE_FILE_DIRECTORY'", 
                                                            "'SOURCE_FILE_NAME_CONVERT'", 
                                                            "'SOURCE'", 
                                                            "'SPACE'", "'SPECIFICATION'", 
                                                            "'SPFILE'", 
                                                            "'SPLIT'", "'SPREADSHEET'", 
                                                            "'SQLDATA'", 
                                                            "'SQLERROR'", 
                                                            "'SQLLDR'", 
                                                            "'SQL'", null, 
                                                            "'SQL_MACRO'", 
                                                            "'SQL_TRACE'", 
                                                            "'SQL_TRANSLATION_PROFILE'", 
                                                            "'SQRT'", "'STALE'", 
                                                            "'STANDALONE'", 
                                                            "'STANDARD'", 
                                                            "'STANDARD_HASH'", 
                                                            "'STANDBY_MAX_DATA_DELAY'", 
                                                            "'STANDBYS'", 
                                                            "'STANDBY'", 
                                                            "'STAR'", "'STAR_TRANSFORMATION'", 
                                                            "'START'", "'STARTUP'", 
                                                            "'STATEMENT_ID'", 
                                                            "'STATEMENT_QUEUING'", 
                                                            "'STATEMENTS'", 
                                                            "'STATEMENT'", 
                                                            "'STATE'", "'STATIC'", 
                                                            "'STATISTICS'", 
                                                            "'STATS_BINOMIAL_TEST'", 
                                                            "'STATS_CROSSTAB'", 
                                                            "'STATS_F_TEST'", 
                                                            "'STATS_KS_TEST'", 
                                                            "'STATS_MODE'", 
                                                            "'STATS_MW_TEST'", 
                                                            "'STATS_ONE_WAY_ANOVA'", 
                                                            "'STATS_T_TEST_INDEP'", 
                                                            "'STATS_T_TEST_INDEPU'", 
                                                            "'STATS_T_TEST_ONE'", 
                                                            "'STATS_T_TEST_PAIRED'", 
                                                            "'STATS_WSR_TEST'", 
                                                            "'STDDEV_POP'", 
                                                            "'STDDEV_SAMP'", 
                                                            "'STOP'", "'STORAGE'", 
                                                            "'STORE'", "'STREAMS'", 
                                                            "'STREAM'", 
                                                            "'STRICT'", 
                                                            "'STRING'", 
                                                            "'STRIPE_COLUMNS'", 
                                                            "'STRIPE_WIDTH'", 
                                                            "'STRIP'", "'STRUCTURE'", 
                                                            "'SUBMULTISET'", 
                                                            "'SUBPARTITION_REL'", 
                                                            "'SUBPARTITIONS'", 
                                                            "'SUBPARTITION'", 
                                                            "'SUBQUERIES'", 
                                                            "'SUBQUERY_PRUNING'", 
                                                            "'SUBSCRIBE'", 
                                                            "'SUBSET'", 
                                                            "'SUBSTITUTABLE'", 
                                                            "'SUBSTR2'", 
                                                            "'SUBSTR4'", 
                                                            "'SUBSTRB'", 
                                                            "'SUBSTRC'", 
                                                            "'SUBTYPE'", 
                                                            "'SUCCESSFUL'", 
                                                            "'SUCCESS'", 
                                                            "'SUMMARY'", 
                                                            "'SUPPLEMENTAL'", 
                                                            "'SUSPEND'", 
                                                            "'SWAP_JOIN_INPUTS'", 
                                                            "'SWITCHOVER'", 
                                                            "'SWITCH'", 
                                                            "'SYNCHRONOUS'", 
                                                            "'SYNC'", "'SYNONYM'", 
                                                            "'SYS'", "'SYSASM'", 
                                                            "'SYS_AUDIT'", 
                                                            "'SYSAUX'", 
                                                            "'SYSBACKUP'", 
                                                            "'SYS_CHECKACL'", 
                                                            "'SYS_CHECK_PRIVILEGE'", 
                                                            "'SYS_CONNECT_BY_PATH'", 
                                                            "'SYS_CONTEXT'", 
                                                            "'SYSDATE'", 
                                                            "'SYSDBA'", 
                                                            "'SYS_DBURIGEN'", 
                                                            "'SYSDG'", "'SYS_DL_CURSOR'", 
                                                            "'SYS_DM_RXFORM_CHR'", 
                                                            "'SYS_DM_RXFORM_NUM'", 
                                                            "'SYS_DOM_COMPARE'", 
                                                            "'SYS_DST_PRIM2SEC'", 
                                                            "'SYS_DST_SEC2PRIM'", 
                                                            "'SYS_ET_BFILE_TO_RAW'", 
                                                            "'SYS_ET_BLOB_TO_IMAGE'", 
                                                            "'SYS_ET_IMAGE_TO_BLOB'", 
                                                            "'SYS_ET_RAW_TO_BFILE'", 
                                                            "'SYS_EXTPDTXT'", 
                                                            "'SYS_EXTRACT_UTC'", 
                                                            "'SYS_FBT_INSDEL'", 
                                                            "'SYS_FILTER_ACLS'", 
                                                            "'SYS_FNMATCHES'", 
                                                            "'SYS_FNREPLACE'", 
                                                            "'SYS_GET_ACLIDS'", 
                                                            "'SYS_GET_COL_ACLIDS'", 
                                                            "'SYS_GET_PRIVILEGES'", 
                                                            "'SYS_GETTOKENID'", 
                                                            "'SYS_GETXTIVAL'", 
                                                            "'SYS_GUID'", 
                                                            "'SYSGUID'", 
                                                            "'SYSKM'", "'SYS_MAKE_XMLNODEID'", 
                                                            "'SYS_MAKEXML'", 
                                                            "'SYS_MKXMLATTR'", 
                                                            "'SYS_MKXTI'", 
                                                            "'SYSOBJ'", 
                                                            "'SYS_OP_ADT2BIN'", 
                                                            "'SYS_OP_ADTCONS'", 
                                                            "'SYS_OP_ALSCRVAL'", 
                                                            "'SYS_OP_ATG'", 
                                                            "'SYS_OP_BIN2ADT'", 
                                                            "'SYS_OP_BITVEC'", 
                                                            "'SYS_OP_BL2R'", 
                                                            "'SYS_OP_BLOOM_FILTER_LIST'", 
                                                            "'SYS_OP_BLOOM_FILTER'", 
                                                            "'SYS_OP_C2C'", 
                                                            "'SYS_OP_CAST'", 
                                                            "'SYS_OP_CEG'", 
                                                            "'SYS_OP_CL2C'", 
                                                            "'SYS_OP_COMBINED_HASH'", 
                                                            "'SYS_OP_COMP'", 
                                                            "'SYS_OP_CONVERT'", 
                                                            "'SYS_OP_COUNTCHG'", 
                                                            "'SYS_OP_CSCONV'", 
                                                            "'SYS_OP_CSCONVTEST'", 
                                                            "'SYS_OP_CSR'", 
                                                            "'SYS_OP_CSX_PATCH'", 
                                                            "'SYS_OP_CYCLED_SEQ'", 
                                                            "'SYS_OP_DECOMP'", 
                                                            "'SYS_OP_DESCEND'", 
                                                            "'SYS_OP_DISTINCT'", 
                                                            "'SYS_OP_DRA'", 
                                                            "'SYS_OP_DUMP'", 
                                                            "'SYS_OP_DV_CHECK'", 
                                                            "'SYS_OP_ENFORCE_NOT_NULL$'", 
                                                            "'SYSOPER'", 
                                                            "'SYS_OP_EXTRACT'", 
                                                            "'SYS_OP_GROUPING'", 
                                                            "'SYS_OP_GUID'", 
                                                            "'SYS_OP_HASH'", 
                                                            "'SYS_OP_IIX'", 
                                                            "'SYS_OP_ITR'", 
                                                            "'SYS_OP_KEY_VECTOR_CREATE'", 
                                                            "'SYS_OP_KEY_VECTOR_FILTER_LIST'", 
                                                            "'SYS_OP_KEY_VECTOR_FILTER'", 
                                                            "'SYS_OP_KEY_VECTOR_SUCCEEDED'", 
                                                            "'SYS_OP_KEY_VECTOR_USE'", 
                                                            "'SYS_OP_LBID'", 
                                                            "'SYS_OP_LOBLOC2BLOB'", 
                                                            "'SYS_OP_LOBLOC2CLOB'", 
                                                            "'SYS_OP_LOBLOC2ID'", 
                                                            "'SYS_OP_LOBLOC2NCLOB'", 
                                                            "'SYS_OP_LOBLOC2TYP'", 
                                                            "'SYS_OP_LSVI'", 
                                                            "'SYS_OP_LVL'", 
                                                            "'SYS_OP_MAKEOID'", 
                                                            "'SYS_OP_MAP_NONNULL'", 
                                                            "'SYS_OP_MSR'", 
                                                            "'SYS_OP_NICOMBINE'", 
                                                            "'SYS_OP_NIEXTRACT'", 
                                                            "'SYS_OP_NII'", 
                                                            "'SYS_OP_NIX'", 
                                                            "'SYS_OP_NOEXPAND'", 
                                                            "'SYS_OP_NTCIMG$'", 
                                                            "'SYS_OP_NUMTORAW'", 
                                                            "'SYS_OP_OIDVALUE'", 
                                                            "'SYS_OP_OPNSIZE'", 
                                                            "'SYS_OP_PAR_1'", 
                                                            "'SYS_OP_PARGID_1'", 
                                                            "'SYS_OP_PARGID'", 
                                                            "'SYS_OP_PAR'", 
                                                            "'SYS_OP_PART_ID'", 
                                                            "'SYS_OP_PIVOT'", 
                                                            "'SYS_OP_R2O'", 
                                                            "'SYS_OP_RAWTONUM'", 
                                                            "'SYS_OP_RDTM'", 
                                                            "'SYS_OP_REF'", 
                                                            "'SYS_OP_RMTD'", 
                                                            "'SYS_OP_ROWIDTOOBJ'", 
                                                            "'SYS_OP_RPB'", 
                                                            "'SYS_OPTLOBPRBSC'", 
                                                            "'SYS_OP_TOSETID'", 
                                                            "'SYS_OP_TPR'", 
                                                            "'SYS_OP_TRTB'", 
                                                            "'SYS_OPTXICMP'", 
                                                            "'SYS_OPTXQCASTASNQ'", 
                                                            "'SYS_OP_UNDESCEND'", 
                                                            "'SYS_OP_VECAND'", 
                                                            "'SYS_OP_VECBIT'", 
                                                            "'SYS_OP_VECOR'", 
                                                            "'SYS_OP_VECXOR'", 
                                                            "'SYS_OP_VERSION'", 
                                                            "'SYS_OP_VREF'", 
                                                            "'SYS_OP_VVD'", 
                                                            "'SYS_OP_XMLCONS_FOR_CSX'", 
                                                            "'SYS_OP_XPTHATG'", 
                                                            "'SYS_OP_XPTHIDX'", 
                                                            "'SYS_OP_XPTHOP'", 
                                                            "'SYS_OP_XTXT2SQLT'", 
                                                            "'SYS_OP_ZONE_ID'", 
                                                            "'SYS_ORDERKEY_DEPTH'", 
                                                            "'SYS_ORDERKEY_MAXCHILD'", 
                                                            "'SYS_ORDERKEY_PARENT'", 
                                                            "'SYS_PARALLEL_TXN'", 
                                                            "'SYS_PATHID_IS_ATTR'", 
                                                            "'SYS_PATHID_IS_NMSPC'", 
                                                            "'SYS_PATHID_LASTNAME'", 
                                                            "'SYS_PATHID_LASTNMSPC'", 
                                                            "'SYS_PATH_REVERSE'", 
                                                            "'SYS_PXQEXTRACT'", 
                                                            "'SYS_RAW_TO_XSID'", 
                                                            "'SYS_RID_ORDER'", 
                                                            "'SYS_ROW_DELTA'", 
                                                            "'SYS_SC_2_XMLT'", 
                                                            "'SYS_SYNRCIREDO'", 
                                                            "'SYSTEM_DEFINED'", 
                                                            "'SYSTEM'", 
                                                            "'SYSTIMESTAMP'", 
                                                            "'SYS_TYPEID'", 
                                                            "'SYS_UMAKEXML'", 
                                                            "'SYS_XMLANALYZE'", 
                                                            "'SYS_XMLCONTAINS'", 
                                                            "'SYS_XMLCONV'", 
                                                            "'SYS_XMLEXNSURI'", 
                                                            "'SYS_XMLGEN'", 
                                                            "'SYS_XMLI_LOC_ISNODE'", 
                                                            "'SYS_XMLI_LOC_ISTEXT'", 
                                                            "'SYS_XMLINSTR'", 
                                                            "'SYS_XMLLOCATOR_GETSVAL'", 
                                                            "'SYS_XMLNODEID_GETCID'", 
                                                            "'SYS_XMLNODEID_GETLOCATOR'", 
                                                            "'SYS_XMLNODEID_GETOKEY'", 
                                                            "'SYS_XMLNODEID_GETPATHID'", 
                                                            "'SYS_XMLNODEID_GETPTRID'", 
                                                            "'SYS_XMLNODEID_GETRID'", 
                                                            "'SYS_XMLNODEID_GETSVAL'", 
                                                            "'SYS_XMLNODEID_GETTID'", 
                                                            "'SYS_XMLNODEID'", 
                                                            "'SYS_XMLT_2_SC'", 
                                                            "'SYS_XMLTRANSLATE'", 
                                                            "'SYS_XMLTYPE2SQL'", 
                                                            "'SYS_XQ_ASQLCNV'", 
                                                            "'SYS_XQ_ATOMCNVCHK'", 
                                                            "'SYS_XQBASEURI'", 
                                                            "'SYS_XQCASTABLEERRH'", 
                                                            "'SYS_XQCODEP2STR'", 
                                                            "'SYS_XQCODEPEQ'", 
                                                            "'SYS_XQCON2SEQ'", 
                                                            "'SYS_XQCONCAT'", 
                                                            "'SYS_XQDELETE'", 
                                                            "'SYS_XQDFLTCOLATION'", 
                                                            "'SYS_XQDOC'", 
                                                            "'SYS_XQDOCURI'", 
                                                            "'SYS_XQDURDIV'", 
                                                            "'SYS_XQED4URI'", 
                                                            "'SYS_XQENDSWITH'", 
                                                            "'SYS_XQERRH'", 
                                                            "'SYS_XQERR'", 
                                                            "'SYS_XQESHTMLURI'", 
                                                            "'SYS_XQEXLOBVAL'", 
                                                            "'SYS_XQEXSTWRP'", 
                                                            "'SYS_XQEXTRACT'", 
                                                            "'SYS_XQEXTRREF'", 
                                                            "'SYS_XQEXVAL'", 
                                                            "'SYS_XQFB2STR'", 
                                                            "'SYS_XQFNBOOL'", 
                                                            "'SYS_XQFNCMP'", 
                                                            "'SYS_XQFNDATIM'", 
                                                            "'SYS_XQFNLNAME'", 
                                                            "'SYS_XQFNNM'", 
                                                            "'SYS_XQFNNSURI'", 
                                                            "'SYS_XQFNPREDTRUTH'", 
                                                            "'SYS_XQFNQNM'", 
                                                            "'SYS_XQFNROOT'", 
                                                            "'SYS_XQFORMATNUM'", 
                                                            "'SYS_XQFTCONTAIN'", 
                                                            "'SYS_XQFUNCR'", 
                                                            "'SYS_XQGETCONTENT'", 
                                                            "'SYS_XQINDXOF'", 
                                                            "'SYS_XQINSERT'", 
                                                            "'SYS_XQINSPFX'", 
                                                            "'SYS_XQIRI2URI'", 
                                                            "'SYS_XQLANG'", 
                                                            "'SYS_XQLLNMFRMQNM'", 
                                                            "'SYS_XQMKNODEREF'", 
                                                            "'SYS_XQNILLED'", 
                                                            "'SYS_XQNODENAME'", 
                                                            "'SYS_XQNORMSPACE'", 
                                                            "'SYS_XQNORMUCODE'", 
                                                            "'SYS_XQ_NRNG'", 
                                                            "'SYS_XQNSP4PFX'", 
                                                            "'SYS_XQNSPFRMQNM'", 
                                                            "'SYS_XQPFXFRMQNM'", 
                                                            "'SYS_XQ_PKSQL2XML'", 
                                                            "'SYS_XQPOLYABS'", 
                                                            "'SYS_XQPOLYADD'", 
                                                            "'SYS_XQPOLYCEL'", 
                                                            "'SYS_XQPOLYCSTBL'", 
                                                            "'SYS_XQPOLYCST'", 
                                                            "'SYS_XQPOLYDIV'", 
                                                            "'SYS_XQPOLYFLR'", 
                                                            "'SYS_XQPOLYMOD'", 
                                                            "'SYS_XQPOLYMUL'", 
                                                            "'SYS_XQPOLYRND'", 
                                                            "'SYS_XQPOLYSQRT'", 
                                                            "'SYS_XQPOLYSUB'", 
                                                            "'SYS_XQPOLYUMUS'", 
                                                            "'SYS_XQPOLYUPLS'", 
                                                            "'SYS_XQPOLYVEQ'", 
                                                            "'SYS_XQPOLYVGE'", 
                                                            "'SYS_XQPOLYVGT'", 
                                                            "'SYS_XQPOLYVLE'", 
                                                            "'SYS_XQPOLYVLT'", 
                                                            "'SYS_XQPOLYVNE'", 
                                                            "'SYS_XQREF2VAL'", 
                                                            "'SYS_XQRENAME'", 
                                                            "'SYS_XQREPLACE'", 
                                                            "'SYS_XQRESVURI'", 
                                                            "'SYS_XQRNDHALF2EVN'", 
                                                            "'SYS_XQRSLVQNM'", 
                                                            "'SYS_XQRYENVPGET'", 
                                                            "'SYS_XQRYVARGET'", 
                                                            "'SYS_XQRYWRP'", 
                                                            "'SYS_XQSEQ2CON4XC'", 
                                                            "'SYS_XQSEQ2CON'", 
                                                            "'SYS_XQSEQDEEPEQ'", 
                                                            "'SYS_XQSEQINSB'", 
                                                            "'SYS_XQSEQRM'", 
                                                            "'SYS_XQSEQRVS'", 
                                                            "'SYS_XQSEQSUB'", 
                                                            "'SYS_XQSEQTYPMATCH'", 
                                                            "'SYS_XQSTARTSWITH'", 
                                                            "'SYS_XQSTATBURI'", 
                                                            "'SYS_XQSTR2CODEP'", 
                                                            "'SYS_XQSTRJOIN'", 
                                                            "'SYS_XQSUBSTRAFT'", 
                                                            "'SYS_XQSUBSTRBEF'", 
                                                            "'SYS_XQTOKENIZE'", 
                                                            "'SYS_XQTREATAS'", 
                                                            "'SYS_XQ_UPKXML2SQL'", 
                                                            "'SYS_XQXFORM'", 
                                                            "'SYS_XSID_TO_RAW'", 
                                                            "'SYS_ZMAP_FILTER'", 
                                                            "'SYS_ZMAP_REFRESH'", 
                                                            "'T'", "'TABLE_LOOKUP_BY_NL'", 
                                                            "'TABLESPACE_NO'", 
                                                            "'TABLESPACE'", 
                                                            "'TABLES'", 
                                                            "'TABLE_STATS'", 
                                                            "'TABLE'", "'TABNO'", 
                                                            "'TAG'", "'TANH'", 
                                                            "'TAN'", "'TBL$OR$IDX$PART$NUM'", 
                                                            "'TEMPFILE'", 
                                                            "'TEMPLATE'", 
                                                            "'TEMPORARY'", 
                                                            "'TEMP_TABLE'", 
                                                            "'TEST'", "'TEXT'", 
                                                            "'THAN'", "'THEN'", 
                                                            "'THE'", "'THREAD'", 
                                                            "'THROUGH'", 
                                                            "'TIER'", "'TIES'", 
                                                            "'TIMEOUT'", 
                                                            "'TIMESTAMP_LTZ_UNCONSTRAINED'", 
                                                            "'TIMESTAMP'", 
                                                            "'TIMESTAMP_TZ_UNCONSTRAINED'", 
                                                            "'TIMESTAMP_UNCONSTRAINED'", 
                                                            "'TIMES'", "'TIME'", 
                                                            "'TIMEZONE'", 
                                                            "'TIMEZONE_ABBR'", 
                                                            "'TIMEZONE_HOUR'", 
                                                            "'TIMEZONE_MINUTE'", 
                                                            "'TIMEZONE_OFFSET'", 
                                                            "'TIMEZONE_REGION'", 
                                                            "'TIME_ZONE'", 
                                                            "'TIMING'", 
                                                            "'TIV_GB'", 
                                                            "'TIV_SSF'", 
                                                            "'TO_ACLID'", 
                                                            "'TO_BINARY_DOUBLE'", 
                                                            "'TO_BINARY_FLOAT'", 
                                                            "'TO_BLOB'", 
                                                            "'TO_CLOB'", 
                                                            "'TO_DSINTERVAL'", 
                                                            "'TO_LOB'", 
                                                            "'TO_MULTI_BYTE'", 
                                                            "'TO_NCHAR'", 
                                                            "'TO_NCLOB'", 
                                                            "'TO_NUMBER'", 
                                                            "'TOPLEVEL'", 
                                                            "'TO_SINGLE_BYTE'", 
                                                            "'TO_TIMESTAMP'", 
                                                            "'TO_TIMESTAMP_TZ'", 
                                                            "'TO_TIME'", 
                                                            "'TO_TIME_TZ'", 
                                                            "'TO'", "'TO_YMINTERVAL'", 
                                                            "'TRACE'", "'TRACING'", 
                                                            "'TRACKING'", 
                                                            "'TRAILING'", 
                                                            "'TRANSACTION'", 
                                                            "'TRANSFORM'", 
                                                            "'TRANSFORM_DISTINCT_AGG'", 
                                                            "'TRANSITIONAL'", 
                                                            "'TRANSITION'", 
                                                            "'TRANSLATE'", 
                                                            "'TRANSLATION'", 
                                                            "'TREAT'", "'TRIGGERS'", 
                                                            "'TRIGGER'", 
                                                            "'TRUE'", "'TRUNCATE'", 
                                                            "'TRUNC'", "'TRUSTED'", 
                                                            "'TRUST'", "'TUNING'", 
                                                            "'TX'", "'TYPES'", 
                                                            "'TYPE'", "'TZ_OFFSET'", 
                                                            "'UB2'", "'UBA'", 
                                                            "'UCS2'", "'UID'", 
                                                            "'UNARCHIVED'", 
                                                            "'UNBOUNDED'", 
                                                            "'UNBOUND'", 
                                                            "'UNCONDITIONAL'", 
                                                            "'UNDER'", "'UNDO'", 
                                                            "'UNDROP'", 
                                                            "'UNIFORM'", 
                                                            "'UNION'", "'UNIQUE'", 
                                                            "'UNISTR'", 
                                                            "'UNLIMITED'", 
                                                            "'UNLOAD'", 
                                                            "'UNLOCK'", 
                                                            "'UNMATCHED'", 
                                                            "'UNNEST_INNERJ_DISTINCT_VIEW'", 
                                                            "'UNNEST_NOSEMIJ_NODISTINCTVIEW'", 
                                                            "'UNNEST_SEMIJ_VIEW'", 
                                                            "'UNNEST'", 
                                                            "'UNPACKED'", 
                                                            "'UNPIVOT'", 
                                                            "'UNPLUG'", 
                                                            "'UNPROTECTED'", 
                                                            "'UNQUIESCE'", 
                                                            "'UNRECOVERABLE'", 
                                                            "'UNRESTRICTED'", 
                                                            "'UNSUBSCRIBE'", 
                                                            "'UNTIL'", "'UNUSABLE'", 
                                                            "'UNUSED'", 
                                                            "'UPDATABLE'", 
                                                            "'UPDATED'", 
                                                            "'UPDATE'", 
                                                            "'UPDATEXML'", 
                                                            "'UPD_INDEXES'", 
                                                            "'UPD_JOININDEX'", 
                                                            "'UPGRADE'", 
                                                            "'UPPER'", "'UPSERT'", 
                                                            "'UROWID'", 
                                                            "'USABLE'", 
                                                            "'USAGE'", "'USE_ANTI'", 
                                                            "'USE_CONCAT'", 
                                                            "'USE_CUBE'", 
                                                            "'USE_HASH_AGGREGATION'", 
                                                            "'USE_HASH_GBY_FOR_PUSHDOWN'", 
                                                            "'USE_HASH'", 
                                                            "'USE_HIDDEN_PARTITIONS'", 
                                                            "'USE_INVISIBLE_INDEXES'", 
                                                            "'USE_MERGE_CARTESIAN'", 
                                                            "'USE_MERGE'", 
                                                            "'USE_NL'", 
                                                            "'USE_NL_WITH_INDEX'", 
                                                            "'USE_PRIVATE_OUTLINES'", 
                                                            "'USER_DATA'", 
                                                            "'USER_DEFINED'", 
                                                            "'USERENV'", 
                                                            "'USERGROUP'", 
                                                            "'USER_RECYCLEBIN'", 
                                                            "'USERS'", "'USER_TABLESPACES'", 
                                                            "'USER'", "'USE_SEMI'", 
                                                            "'USE_STORED_OUTLINES'", 
                                                            "'USE_TTT_FOR_GSETS'", 
                                                            "'USE'", "'USE_VECTOR_AGGREGATION'", 
                                                            "'USE_WEAK_NAME_RESL'", 
                                                            "'USING_NO_EXPAND'", 
                                                            "'USING'", "'UTF16BE'", 
                                                            "'UTF16LE'", 
                                                            "'UTF32'", "'UTF8'", 
                                                            "'V1'", "'V2'", 
                                                            "'VALIDATE'", 
                                                            "'VALIDATE_CONVERSION'", 
                                                            "'VALIDATION'", 
                                                            "'VALID_TIME_END'", 
                                                            "'VALUES'", 
                                                            "'VALUE'", "'VARCHAR2'", 
                                                            "'VARCHAR'", 
                                                            "'VARIABLE'", 
                                                            "'VAR_POP'", 
                                                            "'VARRAYS'", 
                                                            "'VARRAY'", 
                                                            "'VAR_SAMP'", 
                                                            "'VARYING'", 
                                                            "'VECTOR_READ_TRACE'", 
                                                            "'VECTOR_READ'", 
                                                            "'VECTOR_TRANSFORM_DIMS'", 
                                                            "'VECTOR_TRANSFORM_FACT'", 
                                                            "'VECTOR_TRANSFORM'", 
                                                            "'VERIFIER'", 
                                                            "'VERIFY'", 
                                                            "'VERSIONING'", 
                                                            "'VERSIONS_ENDSCN'", 
                                                            "'VERSIONS_ENDTIME'", 
                                                            "'VERSIONS_OPERATION'", 
                                                            "'VERSIONS_STARTSCN'", 
                                                            "'VERSIONS_STARTTIME'", 
                                                            "'VERSIONS'", 
                                                            "'VERSIONS_XID'", 
                                                            "'VERSION'", 
                                                            "'VIEW'", "'VIOLATION'", 
                                                            "'VIRTUAL'", 
                                                            "'VISIBILITY'", 
                                                            "'VISIBLE'", 
                                                            "'VOLUME'", 
                                                            "'VSIZE'", "'WAIT'", 
                                                            "'WALLET'", 
                                                            "'WARNING'", 
                                                            "'WEEKS'", "'WEEK'", 
                                                            "'WELLFORMED'", 
                                                            "'WHENEVER'", 
                                                            "'WHEN'", "'WHERE'", 
                                                            "'WHILE'", "'WHITESPACE'", 
                                                            "'WIDTH_BUCKET'", 
                                                            "'WITHIN'", 
                                                            "'WITHOUT'", 
                                                            "'WITH_PLSQL'", 
                                                            "'WITH'", "'WORK'", 
                                                            "'WRAPPED'", 
                                                            "'WRAPPER'", 
                                                            "'WRITE'", "'XDB_FASTPATH_INSERT'", 
                                                            "'XDB'", "'X_DYN_PRUNE'", 
                                                            "'XID'", "'XML2OBJECT'", 
                                                            "'XMLAGG'", 
                                                            "'XMLATTRIBUTES'", 
                                                            "'XMLCAST'", 
                                                            "'XMLCDATA'", 
                                                            "'XMLCOLATTVAL'", 
                                                            "'XMLCOMMENT'", 
                                                            "'XMLCONCAT'", 
                                                            "'XMLDIFF'", 
                                                            "'XML_DML_RWT_STMT'", 
                                                            "'XMLELEMENT'", 
                                                            "'XMLEXISTS2'", 
                                                            "'XMLEXISTS'", 
                                                            "'XMLFOREST'", 
                                                            "'XMLINDEX'", 
                                                            "'XMLINDEX_REWRITE_IN_SELECT'", 
                                                            "'XMLINDEX_REWRITE'", 
                                                            "'XMLINDEX_SEL_IDX_TBL'", 
                                                            "'XMLISNODE'", 
                                                            "'XMLISVALID'", 
                                                            "'XMLNAMESPACES'", 
                                                            "'XMLPARSE'", 
                                                            "'XMLPATCH'", 
                                                            "'XMLPI'", "'XMLQUERYVAL'", 
                                                            "'XMLQUERY'", 
                                                            "'XMLROOT'", 
                                                            "'XMLSCHEMA'", 
                                                            "'XMLSERIALIZE'", 
                                                            "'XMLTABLE'", 
                                                            "'XMLTRANSFORMBLOB'", 
                                                            "'XMLTRANSFORM'", 
                                                            "'XMLTYPE'", 
                                                            "'XML'", "'XPATHTABLE'", 
                                                            "'XS_SYS_CONTEXT'", 
                                                            "'XS'", "'XTRANSPORT'", 
                                                            "'YEARS'", "'YEAR'", 
                                                            "'YES'", "'YMINTERVAL_UNCONSTRAINED'", 
                                                            "'ZONEMAP'", 
                                                            "'ZONE'", "'PREDICTION'", 
                                                            "'PREDICTION_BOUNDS'", 
                                                            "'PREDICTION_COST'", 
                                                            "'PREDICTION_DETAILS'", 
                                                            "'PREDICTION_PROBABILITY'", 
                                                            "'PREDICTION_SET'", 
                                                            "'BLOCKCHAIN'", 
                                                            "'COLLATE'", 
                                                            "'COLLATION'", 
                                                            "'DEFINITION'", 
                                                            "'DUPLICATED'", 
                                                            "'EXTENDED'", 
                                                            "'HASHING'", 
                                                            "'IDLE'", "'IMMUTABLE'", 
                                                            "'ORACLE_DATAPUMP'", 
                                                            "'ORACLE_HDFS'", 
                                                            "'ORACLE_HIVE'", 
                                                            "'ORACLE_LOADER'", 
                                                            "'\"SHA2_512\"'", 
                                                            "'SHARDED'", 
                                                            "'\"V1\"'", 
                                                            "'ISOLATE'", 
                                                            "'ROOT'", "'UNITE'", 
                                                            "'ALGORITHM'", 
                                                            "'CUME_DIST'", 
                                                            "'DENSE_RANK'", 
                                                            "'LISTAGG'", 
                                                            "'PERCENT_RANK'", 
                                                            "'PERCENTILE_CONT'", 
                                                            "'PERCENTILE_DISC'", 
                                                            "'RANK'", "'AVG'", 
                                                            "'CORR'", "'COVAR_'", 
                                                            "'DECODE'", 
                                                            "'LAG'", "'LAG_DIFF'", 
                                                            "'LAG_DIFF_PERCENT'", 
                                                            "'LEAD'", "'MAX'", 
                                                            "'MEDIAN'", 
                                                            "'MEMOPTIMIZE'", 
                                                            "'MIN'", "'NTILE'", 
                                                            "'NVL'", "'RATIO_TO_REPORT'", 
                                                            "'REGR_'", "'ROUND'", 
                                                            "'ROW_NUMBER'", 
                                                            "'SUBSTR'", 
                                                            "'TO_CHAR'", 
                                                            "'TRIM'", "'SUM'", 
                                                            "'STDDEV'", 
                                                            "'VAR_'", "'VARIANCE'", 
                                                            "'LEAST'", "'GREATEST'", 
                                                            "'TO_DATE'", 
                                                            null, null, 
                                                            null, "'..'", 
                                                            "'.'", null, 
                                                            null, null, 
                                                            null, "'%'", 
                                                            "'&'", "'('", 
                                                            "')'", "'**'", 
                                                            "'*'", "'+'", 
                                                            "'-'", "','", 
                                                            "'/'", "'@'", 
                                                            "':='", "'#'", 
                                                            "'''", null, 
                                                            null, "'^'", 
                                                            "'~'", "'!'", 
                                                            "'>'", "'<'", 
                                                            "':'", "';'", 
                                                            "'|'", "'='", 
                                                            "'['", "']'", 
                                                            "'_'" ];
	public static readonly symbolicNames: (string | null)[] = [ null, "ABORT", 
                                                             "ABS", "ABSENT", 
                                                             "ACCESS", "ACCESSED", 
                                                             "ACCOUNT", 
                                                             "ACL", "ACOS", 
                                                             "ACROSS", "ACTION", 
                                                             "ACTIONS", 
                                                             "ACTIVATE", 
                                                             "ACTIVE", "ACTIVE_COMPONENT", 
                                                             "ACTIVE_DATA", 
                                                             "ACTIVE_FUNCTION", 
                                                             "ACTIVE_TAG", 
                                                             "ACTIVITY", 
                                                             "ADAPTIVE_PLAN", 
                                                             "ADD", "ADD_COLUMN", 
                                                             "ADD_GROUP", 
                                                             "ADD_MONTHS", 
                                                             "ADJ_DATE", 
                                                             "ADMIN", "ADMINISTER", 
                                                             "ADMINISTRATOR", 
                                                             "ADVANCED", 
                                                             "ADVISE", "ADVISOR", 
                                                             "AFD_DISKSTRING", 
                                                             "AFTER", "AGENT", 
                                                             "AGGREGATE", 
                                                             "A_LETTER", 
                                                             "ALIAS", "ALL", 
                                                             "ALLOCATE", 
                                                             "ALLOW", "ALL_ROWS", 
                                                             "ALTER", "ALTERNATE", 
                                                             "ALWAYS", "ANALYTIC", 
                                                             "ANALYZE", 
                                                             "ANCESTOR", 
                                                             "ANCILLARY", 
                                                             "AND", "AND_EQUAL", 
                                                             "ANOMALY", 
                                                             "ANSI_REARCH", 
                                                             "ANTIJOIN", 
                                                             "ANY", "ANYSCHEMA", 
                                                             "APPEND", "APPENDCHILDXML", 
                                                             "APPEND_VALUES", 
                                                             "APPLICATION", 
                                                             "APPLY", "APPROX_COUNT_DISTINCT", 
                                                             "ARCHIVAL", 
                                                             "ARCHIVE", 
                                                             "ARCHIVED", 
                                                             "ARCHIVELOG", 
                                                             "ARRAY", "AS", 
                                                             "ASC", "ASCII", 
                                                             "ASCIISTR", 
                                                             "ASIN", "ASIS", 
                                                             "ASSEMBLY", 
                                                             "ASSIGN", "ASSOCIATE", 
                                                             "ASYNC", "ASYNCHRONOUS", 
                                                             "ATAN2", "ATAN", 
                                                             "AT", "ATTRIBUTE", 
                                                             "ATTRIBUTES", 
                                                             "AUDIT", "AUTHENTICATED", 
                                                             "AUTHENTICATION", 
                                                             "AUTHID", "AUTHORIZATION", 
                                                             "AUTOALLOCATE", 
                                                             "AUTO", "AUTOBACKUP", 
                                                             "AUTOEXTEND", 
                                                             "AUTO_LOGIN", 
                                                             "AUTOMATIC", 
                                                             "AUTONOMOUS_TRANSACTION", 
                                                             "AUTO_REOPTIMIZE", 
                                                             "AVAILABILITY", 
                                                             "AVRO", "BACKGROUND", 
                                                             "BACKINGFILE", 
                                                             "BACKUP", "BACKUPS", 
                                                             "BACKUPSET", 
                                                             "BASIC", "BASICFILE", 
                                                             "BATCH", "BATCHSIZE", 
                                                             "BATCH_TABLE_ACCESS_BY_ROWID", 
                                                             "BECOME", "BEFORE", 
                                                             "BEGIN", "BEGINNING", 
                                                             "BEGIN_OUTLINE_DATA", 
                                                             "BEHALF", "BEQUEATH", 
                                                             "BETWEEN", 
                                                             "BFILE", "BFILENAME", 
                                                             "BIGFILE", 
                                                             "BINARY", "BINARY_DOUBLE", 
                                                             "BINARY_DOUBLE_INFINITY", 
                                                             "BINARY_DOUBLE_NAN", 
                                                             "BINARY_FLOAT", 
                                                             "BINARY_FLOAT_INFINITY", 
                                                             "BINARY_FLOAT_NAN", 
                                                             "BINARY_INTEGER", 
                                                             "BIND_AWARE", 
                                                             "BINDING", 
                                                             "BIN_TO_NUM", 
                                                             "BITAND", "BITMAP_AND", 
                                                             "BITMAP", "BITMAPS", 
                                                             "BITMAP_TREE", 
                                                             "BITS", "BLOB", 
                                                             "BLOCK", "BLOCK_RANGE", 
                                                             "BLOCKS", "BLOCKSIZE", 
                                                             "BODY", "BOOLEAN", 
                                                             "BOTH", "BOUND", 
                                                             "BRANCH", "BREADTH", 
                                                             "BROADCAST", 
                                                             "BSON", "BUFFER", 
                                                             "BUFFER_CACHE", 
                                                             "BUFFER_POOL", 
                                                             "BUILD", "BULK", 
                                                             "BY", "BYPASS_RECURSIVE_CHECK", 
                                                             "BYPASS_UJVC", 
                                                             "BYTE", "CACHE", 
                                                             "CACHE_CB", 
                                                             "CACHE_INSTANCES", 
                                                             "CACHE_TEMP_TABLE", 
                                                             "CACHING", 
                                                             "CALCULATED", 
                                                             "CALLBACK", 
                                                             "CALL", "CANCEL", 
                                                             "CANONICAL", 
                                                             "CAPACITY", 
                                                             "CAPTION", 
                                                             "CARDINALITY", 
                                                             "CASCADE", 
                                                             "CASE", "CAST", 
                                                             "CASESENSITIVE", 
                                                             "CATEGORY", 
                                                             "CDBDEFAULT", 
                                                             "CEIL", "CELL_FLASH_CACHE", 
                                                             "CERTIFICATE", 
                                                             "CFILE", "CHAINED", 
                                                             "CHANGE", "CHANGETRACKING", 
                                                             "CHANGE_DUPKEY_ERROR_INDEX", 
                                                             "CHARACTER", 
                                                             "CHAR", "CHAR_CS", 
                                                             "CHARTOROWID", 
                                                             "CHECK_ACL_REWRITE", 
                                                             "CHECK", "CHECKPOINT", 
                                                             "CHILD", "CHOOSE", 
                                                             "CHR", "CHUNK", 
                                                             "CLASS", "CLASSIFICATION", 
                                                             "CLASSIFIER", 
                                                             "CLAUSE", "CLEAN", 
                                                             "CLEANUP", 
                                                             "CLEAR", "C_LETTER", 
                                                             "CLIENT", "CLOB", 
                                                             "CLONE", "CLOSE_CACHED_OPEN_CURSORS", 
                                                             "CLOSE", "CLUSTER_BY_ROWID", 
                                                             "CLUSTER", 
                                                             "CLUSTER_DETAILS", 
                                                             "CLUSTER_DISTANCE", 
                                                             "CLUSTER_ID", 
                                                             "CLUSTERING", 
                                                             "CLUSTERING_FACTOR", 
                                                             "CLUSTER_PROBABILITY", 
                                                             "CLUSTER_SET", 
                                                             "COALESCE", 
                                                             "COALESCE_SQ", 
                                                             "COARSE", "CO_AUTH_IND", 
                                                             "COLD", "COLLECT", 
                                                             "COLUMNAR", 
                                                             "COLUMN_AUTH_INDICATOR", 
                                                             "COLUMN", "COLUMNS", 
                                                             "COLUMN_STATS", 
                                                             "COLUMN_VALUE", 
                                                             "COMMENT", 
                                                             "COMMIT", "COMMITTED", 
                                                             "COMMON", "COMMON_DATA", 
                                                             "COMPACT", 
                                                             "COMPATIBILITY", 
                                                             "COMPILE", 
                                                             "COMPLETE", 
                                                             "COMPLIANCE", 
                                                             "COMPONENT", 
                                                             "COMPONENTS", 
                                                             "COMPOSE", 
                                                             "COMPOSITE", 
                                                             "COMPOSITE_LIMIT", 
                                                             "COMPOUND", 
                                                             "COMPRESS", 
                                                             "COMPUTE", 
                                                             "CONCAT", "CON_DBID_TO_ID", 
                                                             "CONDITIONAL", 
                                                             "CONDITION", 
                                                             "CONFIRM", 
                                                             "CONFORMING", 
                                                             "CON_GUID_TO_ID", 
                                                             "CON_ID", "CON_NAME_TO_ID", 
                                                             "CONNECT_BY_CB_WHR_ONLY", 
                                                             "CONNECT_BY_COMBINE_SW", 
                                                             "CONNECT_BY_COST_BASED", 
                                                             "CONNECT_BY_ELIM_DUPS", 
                                                             "CONNECT_BY_FILTERING", 
                                                             "CONNECT_BY_ISCYCLE", 
                                                             "CONNECT_BY_ISLEAF", 
                                                             "CONNECT_BY_ROOT", 
                                                             "CONNECT", 
                                                             "CONNECT_TIME", 
                                                             "CONSIDER", 
                                                             "CONSISTENT", 
                                                             "CONSTANT", 
                                                             "CONST", "CONSTRAINT", 
                                                             "CONSTRAINTS", 
                                                             "CONSTRUCTOR", 
                                                             "CONTAINER", 
                                                             "CONTAINERS", 
                                                             "CONTAINERS_DEFAULT", 
                                                             "CONTAINER_DATA", 
                                                             "CONTAINER_MAP", 
                                                             "CONTENT", 
                                                             "CONTENTS", 
                                                             "CONTEXT", 
                                                             "CONTINUE", 
                                                             "CONTROLFILE", 
                                                             "CON_UID_TO_ID", 
                                                             "CONVERT", 
                                                             "CONVERSION", 
                                                             "COOKIE", "COPY", 
                                                             "CORR_K", "CORR_S", 
                                                             "CORRUPTION", 
                                                             "CORRUPT_XID_ALL", 
                                                             "CORRUPT_XID", 
                                                             "COS", "COSH", 
                                                             "COST", "COST_XML_QUERY_REWRITE", 
                                                             "COUNT", "COVAR_POP", 
                                                             "COVAR_SAMP", 
                                                             "CPU_COSTING", 
                                                             "CPU_PER_CALL", 
                                                             "CPU_PER_SESSION", 
                                                             "CRASH", "CREATE", 
                                                             "CREATE_FILE_DEST", 
                                                             "CREATE_STORED_OUTLINES", 
                                                             "CREATION", 
                                                             "CREDENTIAL", 
                                                             "CRITICAL", 
                                                             "CROSS", "CROSSEDITION", 
                                                             "CSCONVERT", 
                                                             "CUBE_AJ", 
                                                             "CUBE", "CUBE_GB", 
                                                             "CUBE_SJ", 
                                                             "CUME_DISTM", 
                                                             "CURRENT", 
                                                             "CURRENT_DATE", 
                                                             "CURRENT_SCHEMA", 
                                                             "CURRENT_TIME", 
                                                             "CURRENT_TIMESTAMP", 
                                                             "CURRENT_USER", 
                                                             "CURRENTV", 
                                                             "CURSOR", "CURSOR_SHARING_EXACT", 
                                                             "CURSOR_SPECIFIC_SEGMENT", 
                                                             "CUSTOMDATUM", 
                                                             "CV", "CYCLE", 
                                                             "DANGLING", 
                                                             "DATABASE", 
                                                             "DATA", "DATAFILE", 
                                                             "DATAFILES", 
                                                             "DATAGUARDCONFIG", 
                                                             "DATAMOVEMENT", 
                                                             "DATAOBJNO", 
                                                             "DATAOBJ_TO_MAT_PARTITION", 
                                                             "DATAOBJ_TO_PARTITION", 
                                                             "DATAPUMP", 
                                                             "DATA_SECURITY_REWRITE_LIMIT", 
                                                             "DATE", "DATE_MODE", 
                                                             "DAY", "DAYS", 
                                                             "DBA", "DBA_RECYCLEBIN", 
                                                             "DBMS_STATS", 
                                                             "DB_ROLE_CHANGE", 
                                                             "DBTIMEZONE", 
                                                             "DB_UNIQUE_NAME", 
                                                             "DB_VERSION", 
                                                             "DDL", "DEALLOCATE", 
                                                             "DEBUG", "DEBUGGER", 
                                                             "DEC", "DECIMAL", 
                                                             "DECLARE", 
                                                             "DECOMPOSE", 
                                                             "DECORRELATE", 
                                                             "DECR", "DECREMENT", 
                                                             "DECRYPT", 
                                                             "DEDUPLICATE", 
                                                             "DEFAULT", 
                                                             "DEFAULTS", 
                                                             "DEFAULT_COLLATION", 
                                                             "DEFAULT_CREDENTIAL", 
                                                             "DEFERRABLE", 
                                                             "DEFERRED", 
                                                             "DEFINED", 
                                                             "DEFINE", "DEFINER", 
                                                             "DEGREE", "DELAY", 
                                                             "DELEGATE", 
                                                             "DELETE_ALL", 
                                                             "DELETE", "DELETEXML", 
                                                             "DEMAND", "DENSE_RANKM", 
                                                             "DEPENDENT", 
                                                             "DEPTH", "DEQUEUE", 
                                                             "DEREF", "DEREF_NO_REWRITE", 
                                                             "DESC", "DESCRIPTION", 
                                                             "DESTROY", 
                                                             "DETACHED", 
                                                             "DETERMINES", 
                                                             "DETERMINISTIC", 
                                                             "DICTIONARY", 
                                                             "DIMENSION", 
                                                             "DIMENSIONS", 
                                                             "DIRECT_LOAD", 
                                                             "DIRECTORY", 
                                                             "DIRECT_PATH", 
                                                             "DISABLE_ALL", 
                                                             "DISABLE", 
                                                             "DISABLE_PARALLEL_DML", 
                                                             "DISABLE_PRESET", 
                                                             "DISABLE_RPKE", 
                                                             "DISALLOW", 
                                                             "DISASSOCIATE", 
                                                             "DISCARD", 
                                                             "DISCONNECT", 
                                                             "DISK", "DISKGROUP", 
                                                             "DISKGROUP_PLUS", 
                                                             "DISKS", "DISMOUNT", 
                                                             "DISTINCT", 
                                                             "DISTINGUISHED", 
                                                             "DISTRIBUTED", 
                                                             "DISTRIBUTE", 
                                                             "DML", "DML_UPDATE", 
                                                             "DOCFIDELITY", 
                                                             "DOCUMENT", 
                                                             "DOMAIN_INDEX_FILTER", 
                                                             "DOMAIN_INDEX_NO_SORT", 
                                                             "DOMAIN_INDEX_SORT", 
                                                             "DOUBLE", "DOWNGRADE", 
                                                             "DRIVING_SITE", 
                                                             "DROP_COLUMN", 
                                                             "DROP", "DROP_GROUP", 
                                                             "DSINTERVAL_UNCONSTRAINED", 
                                                             "DST_UPGRADE_INSERT_CONV", 
                                                             "DUMP", "DUMPSET", 
                                                             "DUPLICATE", 
                                                             "DV", "DYNAMIC", 
                                                             "DYNAMIC_SAMPLING", 
                                                             "DYNAMIC_SAMPLING_EST_CDN", 
                                                             "E_LETTER", 
                                                             "EACH", "EDITIONABLE", 
                                                             "EDITION", 
                                                             "EDITIONING", 
                                                             "EDITIONS", 
                                                             "ELEMENT", 
                                                             "ELIM_GROUPBY", 
                                                             "ELIMINATE_JOIN", 
                                                             "ELIMINATE_OBY", 
                                                             "ELIMINATE_OUTER_JOIN", 
                                                             "ELSE", "ELSIF", 
                                                             "EM", "EMPTY_BLOB", 
                                                             "EMPTY_CLOB", 
                                                             "EMPTY_", "ENABLE_ALL", 
                                                             "ENABLE", "ENABLE_PARALLEL_DML", 
                                                             "ENABLE_PRESET", 
                                                             "ENCODING", 
                                                             "ENCRYPT", 
                                                             "ENCRYPTION", 
                                                             "END", "END_OUTLINE_DATA", 
                                                             "ENFORCED", 
                                                             "ENFORCE", 
                                                             "ENQUEUE", 
                                                             "ENTERPRISE", 
                                                             "ENTITYESCAPING", 
                                                             "ENTRY", "EQUIPART", 
                                                             "ERR", "ERROR_ARGUMENT", 
                                                             "ERROR", "ERROR_ON_OVERLAP_TIME", 
                                                             "ERRORS", "ESCAPE", 
                                                             "ESTIMATE", 
                                                             "EVAL", "EVALNAME", 
                                                             "EVALUATE", 
                                                             "EVALUATION", 
                                                             "EVENTS", "EVERY", 
                                                             "EXCEPT", "EXCEPTION", 
                                                             "EXCEPTION_INIT", 
                                                             "EXCEPTIONS", 
                                                             "EXCHANGE", 
                                                             "EXCLUDE", 
                                                             "EXCLUDING", 
                                                             "EXCLUSIVE", 
                                                             "EXECUTE", 
                                                             "EXEMPT", "EXISTING", 
                                                             "EXISTS", "EXISTSNODE", 
                                                             "EXIT", "EXPAND_GSET_TO_UNION", 
                                                             "EXPAND_TABLE", 
                                                             "EXP", "EXPIRE", 
                                                             "EXPLAIN", 
                                                             "EXPLOSION", 
                                                             "EXPORT", "EXPR_CORR_CHECK", 
                                                             "EXPRESS", 
                                                             "EXTENDS", 
                                                             "EXTENT", "EXTENTS", 
                                                             "EXTERNAL", 
                                                             "EXTERNALLY", 
                                                             "EXTRACTCLOBXML", 
                                                             "EXTRACT", 
                                                             "EXTRACTVALUE", 
                                                             "EXTRA", "FACILITY", 
                                                             "FACT", "FACTOR", 
                                                             "FACTORIZE_JOIN", 
                                                             "FAILED", "FAILED_LOGIN_ATTEMPTS", 
                                                             "FAILGROUP", 
                                                             "FAILOVER", 
                                                             "FAILURE", 
                                                             "FALSE", "FAMILY", 
                                                             "FAR", "FAST", 
                                                             "FASTSTART", 
                                                             "FBTSCAN", 
                                                             "FEATURE", 
                                                             "FEATURE_DETAILS", 
                                                             "FEATURE_ID", 
                                                             "FEATURE_SET", 
                                                             "FEATURE_VALUE", 
                                                             "FETCH", "FILE", 
                                                             "FILE_NAME_CONVERT", 
                                                             "FILEGROUP", 
                                                             "FILESTORE", 
                                                             "FILESYSTEM_LIKE_LOGGING", 
                                                             "FILTER", "FINAL", 
                                                             "FINE", "FINISH", 
                                                             "FIRST", "FIRSTM", 
                                                             "FIRST_ROWS", 
                                                             "FIRST_VALUE", 
                                                             "FIXED_VIEW_DATA", 
                                                             "FLAGGER", 
                                                             "FLASHBACK", 
                                                             "FLASH_CACHE", 
                                                             "FLOAT", "FLOB", 
                                                             "FLEX", "FLOOR", 
                                                             "FLUSH", "FOLDER", 
                                                             "FOLLOWING", 
                                                             "FOLLOWS", 
                                                             "FORALL", "FORCE", 
                                                             "FORCE_XML_QUERY_REWRITE", 
                                                             "FOREIGN", 
                                                             "FOREVER", 
                                                             "FOR", "FORMAT", 
                                                             "FORWARD", 
                                                             "FRAGMENT_NUMBER", 
                                                             "FREELIST", 
                                                             "FREELISTS", 
                                                             "FREEPOOLS", 
                                                             "FRESH", "FROM", 
                                                             "FROM_TZ", 
                                                             "FULL", "FULL_OUTER_JOIN_TO_OUTER", 
                                                             "FUNCTION", 
                                                             "FUNCTIONS", 
                                                             "FTP", "G_LETTER", 
                                                             "GATHER_OPTIMIZER_STATISTICS", 
                                                             "GATHER_PLAN_STATISTICS", 
                                                             "GBY_CONC_ROLLUP", 
                                                             "GBY_PUSHDOWN", 
                                                             "GENERATED", 
                                                             "GET", "GLOBAL", 
                                                             "GLOBALLY", 
                                                             "GLOBAL_NAME", 
                                                             "GLOBAL_TOPIC_ENABLED", 
                                                             "GOTO", "GRANT", 
                                                             "GROUP_BY", 
                                                             "GROUP", "GROUP_ID", 
                                                             "GROUPING", 
                                                             "GROUPING_ID", 
                                                             "GROUPS", "GUARANTEED", 
                                                             "GUARANTEE", 
                                                             "GUARD", "HALF_YEARS", 
                                                             "HASH_AJ", 
                                                             "HASH", "HASHKEYS", 
                                                             "HASH_SJ", 
                                                             "HAVING", "HEADER", 
                                                             "HEAP", "HELP", 
                                                             "HEXTORAW", 
                                                             "HEXTOREF", 
                                                             "HIDDEN_KEYWORD", 
                                                             "HIDE", "HIER_ORDER", 
                                                             "HIERARCHICAL", 
                                                             "HIERARCHIES", 
                                                             "HIERARCHY", 
                                                             "HIGH", "HINTSET_BEGIN", 
                                                             "HINTSET_END", 
                                                             "HOT", "HOUR", 
                                                             "HOURS", "HTTP", 
                                                             "HWM_BROKERED", 
                                                             "HYBRID", "H_LETTER", 
                                                             "IDENTIFIED", 
                                                             "IDENTIFIER", 
                                                             "IDENTITY", 
                                                             "IDGENERATORS", 
                                                             "ID", "IDLE_TIME", 
                                                             "IF", "IGNORE", 
                                                             "IGNORE_OPTIM_EMBEDDED_HINTS", 
                                                             "IGNORE_ROW_ON_DUPKEY_INDEX", 
                                                             "IGNORE_WHERE_CLAUSE", 
                                                             "ILM", "IMMEDIATE", 
                                                             "IMPACT", "IMPORT", 
                                                             "INACTIVE", 
                                                             "INACTIVE_ACCOUNT_TIME", 
                                                             "INCLUDE", 
                                                             "INCLUDE_VERSION", 
                                                             "INCLUDING", 
                                                             "INCREMENTAL", 
                                                             "INCREMENT", 
                                                             "INCR", "INDENT", 
                                                             "INDEX_ASC", 
                                                             "INDEX_COMBINE", 
                                                             "INDEX_DESC", 
                                                             "INDEXED", 
                                                             "INDEXES", 
                                                             "INDEX_FFS", 
                                                             "INDEX_FILTER", 
                                                             "INDEX", "INDEXING", 
                                                             "INDEX_JOIN", 
                                                             "INDEX_ROWS", 
                                                             "INDEX_RRS", 
                                                             "INDEX_RS_ASC", 
                                                             "INDEX_RS_DESC", 
                                                             "INDEX_RS", 
                                                             "INDEX_SCAN", 
                                                             "INDEX_SKIP_SCAN", 
                                                             "INDEX_SS_ASC", 
                                                             "INDEX_SS_DESC", 
                                                             "INDEX_SS", 
                                                             "INDEX_STATS", 
                                                             "INDEXTYPE", 
                                                             "INDEXTYPES", 
                                                             "INDICATOR", 
                                                             "INDICES", 
                                                             "INFINITE", 
                                                             "INFORMATIONAL", 
                                                             "INHERIT", 
                                                             "IN", "INITCAP", 
                                                             "INITIAL", 
                                                             "INITIALIZED", 
                                                             "INITIALLY", 
                                                             "INITRANS", 
                                                             "INLINE", "INLINE_XMLTYPE_NT", 
                                                             "INMEMORY", 
                                                             "IN_MEMORY_METADATA", 
                                                             "INMEMORY_PRUNING", 
                                                             "INNER", "INOUT", 
                                                             "INPLACE", 
                                                             "INSERTCHILDXMLAFTER", 
                                                             "INSERTCHILDXMLBEFORE", 
                                                             "INSERTCHILDXML", 
                                                             "INSERT", "INSERTXMLAFTER", 
                                                             "INSERTXMLBEFORE", 
                                                             "INSTANCE", 
                                                             "INSTANCES", 
                                                             "INSTANTIABLE", 
                                                             "INSTANTLY", 
                                                             "INSTEAD", 
                                                             "INSTR2", "INSTR4", 
                                                             "INSTRB", "INSTRC", 
                                                             "INSTR", "INTEGER", 
                                                             "INTERLEAVED", 
                                                             "INTERMEDIATE", 
                                                             "INTERNAL_CONVERT", 
                                                             "INTERNAL_USE", 
                                                             "INTERPRETED", 
                                                             "INTERSECT", 
                                                             "INTERVAL", 
                                                             "INT", "INTO", 
                                                             "INVALIDATE", 
                                                             "INVISIBLE", 
                                                             "IN_XQUERY", 
                                                             "IS", "IS_LEAF", 
                                                             "ISOLATION", 
                                                             "ISOLATION_LEVEL", 
                                                             "ITERATE", 
                                                             "ITERATION_NUMBER", 
                                                             "JAVA", "JOB", 
                                                             "JOIN", "JSON_ARRAYAGG", 
                                                             "JSON_ARRAY", 
                                                             "JSON_EQUAL", 
                                                             "JSON_EXISTS2", 
                                                             "JSON_EXISTS", 
                                                             "JSONGET", 
                                                             "JSON", "JSON_OBJECTAGG", 
                                                             "JSON_OBJECT", 
                                                             "JSONPARSE", 
                                                             "JSON_QUERY", 
                                                             "JSON_SERIALIZE", 
                                                             "JSON_TABLE", 
                                                             "JSON_TEXTCONTAINS2", 
                                                             "JSON_TEXTCONTAINS", 
                                                             "JSON_TRANSFORM", 
                                                             "JSON_VALUE", 
                                                             "K_LETTER", 
                                                             "KEEP_DUPLICATES", 
                                                             "KEEP", "KERBEROS", 
                                                             "KEY", "KEY_LENGTH", 
                                                             "KEYSIZE", 
                                                             "KEYS", "KEYSTORE", 
                                                             "KILL", "LABEL", 
                                                             "LANGUAGE", 
                                                             "LAST_DAY", 
                                                             "LAST", "LAST_VALUE", 
                                                             "LATERAL", 
                                                             "LAX", "LAYER", 
                                                             "LDAP_REGISTRATION_ENABLED", 
                                                             "LDAP_REGISTRATION", 
                                                             "LDAP_REG_SYNC_INTERVAL", 
                                                             "LEAF", "LEAD_CDB", 
                                                             "LEAD_CDB_URI", 
                                                             "LEADING", 
                                                             "LEFT", "LENGTH2", 
                                                             "LENGTH4", 
                                                             "LENGTHB", 
                                                             "LENGTHC", 
                                                             "LENGTH", "LESS", 
                                                             "LEVEL", "LEVEL_NAME", 
                                                             "LEVELS", "LIBRARY", 
                                                             "LIFECYCLE", 
                                                             "LIFE", "LIFETIME", 
                                                             "LIKE2", "LIKE4", 
                                                             "LIKEC", "LIKE_EXPAND", 
                                                             "LIKE", "LIMIT", 
                                                             "LINEAR", "LINK", 
                                                             "LIST", "LN", 
                                                             "LNNVL", "LOAD", 
                                                             "LOB", "LOBNVL", 
                                                             "LOBS", "LOCAL_INDEXES", 
                                                             "LOCAL", "LOCALTIME", 
                                                             "LOCALTIMESTAMP", 
                                                             "LOCATION", 
                                                             "LOCATOR", 
                                                             "LOCKDOWN", 
                                                             "LOCKED", "LOCKING", 
                                                             "LOCK", "LOGFILE", 
                                                             "LOGFILES", 
                                                             "LOGGING", 
                                                             "LOGICAL", 
                                                             "LOGICAL_READS_PER_CALL", 
                                                             "LOGICAL_READS_PER_SESSION", 
                                                             "LOG", "LOGMINING", 
                                                             "LOGOFF", "LOGON", 
                                                             "LOG_READ_ONLY_VIOLATIONS", 
                                                             "LONG", "LOOP", 
                                                             "LOST", "LOWER", 
                                                             "LOW", "LPAD", 
                                                             "LTRIM", "M_LETTER", 
                                                             "MAIN", "MAKE_REF", 
                                                             "MANAGED", 
                                                             "MANAGE", "MANAGEMENT", 
                                                             "MANAGER", 
                                                             "MANDATORY", 
                                                             "MANUAL", "MAP", 
                                                             "MAPPING", 
                                                             "MASTER", "MATCHED", 
                                                             "MATCHES", 
                                                             "MATCH", "MATCH_NUMBER", 
                                                             "MATCH_RECOGNIZE", 
                                                             "MATERIALIZED", 
                                                             "MATERIALIZE", 
                                                             "MAXARCHLOGS", 
                                                             "MAXDATAFILES", 
                                                             "MAXEXTENTS", 
                                                             "MAXIMIZE", 
                                                             "MAXINSTANCES", 
                                                             "MAXLOGFILES", 
                                                             "MAXLOGHISTORY", 
                                                             "MAXLOGMEMBERS", 
                                                             "MAX_SHARED_TEMP_SIZE", 
                                                             "MAXSIZE", 
                                                             "MAXTRANS", 
                                                             "MAXVALUE", 
                                                             "MEASURE", 
                                                             "MEASURES", 
                                                             "MEDIUM", "MEMBER", 
                                                             "MEMBER_CAPTION", 
                                                             "MEMBER_DESCRIPTION", 
                                                             "MEMBER_NAME", 
                                                             "MEMBER_UNIQUE_NAME", 
                                                             "MEMCOMPRESS", 
                                                             "MEMORY", "MERGEACTIONS", 
                                                             "MERGE_AJ", 
                                                             "MERGE_CONST_ON", 
                                                             "MERGE", "MERGE_SJ", 
                                                             "METADATA", 
                                                             "METHOD", "MIGRATE", 
                                                             "MIGRATION", 
                                                             "MINEXTENTS", 
                                                             "MINIMIZE", 
                                                             "MINIMUM", 
                                                             "MINING", "MINUS", 
                                                             "MINUS_NULL", 
                                                             "MINUTE", "MINUTES", 
                                                             "MINVALUE", 
                                                             "MIRRORCOLD", 
                                                             "MIRRORHOT", 
                                                             "MIRROR", "MISSING", 
                                                             "MISMATCH", 
                                                             "MLSLABEL", 
                                                             "MODEL_COMPILE_SUBQUERY", 
                                                             "MODEL_DONTVERIFY_UNIQUENESS", 
                                                             "MODEL_DYNAMIC_SUBQUERY", 
                                                             "MODEL_MIN_ANALYSIS", 
                                                             "MODEL", "MODEL_NB", 
                                                             "MODEL_NO_ANALYSIS", 
                                                             "MODEL_PBY", 
                                                             "MODEL_PUSH_REF", 
                                                             "MODEL_SV", 
                                                             "MODE", "MODIFICATION", 
                                                             "MODIFY_COLUMN_TYPE", 
                                                             "MODIFY", "MOD", 
                                                             "MODULE", "MONITORING", 
                                                             "MONITOR", 
                                                             "MONTH", "MONTHS_BETWEEN", 
                                                             "MONTHS", "MOUNT", 
                                                             "MOUNTPATH", 
                                                             "MOUNTPOINT", 
                                                             "MOVEMENT", 
                                                             "MOVE", "MULTIDIMENSIONAL", 
                                                             "MULTISET", 
                                                             "MV_MERGE", 
                                                             "NAMED", "NAME", 
                                                             "NAMESPACE", 
                                                             "NAN", "NANVL", 
                                                             "NATIONAL", 
                                                             "NATIVE_FULL_OUTER_JOIN", 
                                                             "NATIVE", "NATURAL", 
                                                             "NATURALN", 
                                                             "NAV", "NCHAR_CS", 
                                                             "NCHAR", "NCHR", 
                                                             "NCLOB", "NEEDED", 
                                                             "NEG", "NESTED", 
                                                             "NESTED_TABLE_FAST_INSERT", 
                                                             "NESTED_TABLE_GET_REFS", 
                                                             "NESTED_TABLE_ID", 
                                                             "NESTED_TABLE_SET_REFS", 
                                                             "NESTED_TABLE_SET_SETID", 
                                                             "NETWORK", 
                                                             "NEVER", "NEW", 
                                                             "NEW_TIME", 
                                                             "NEXT_DAY", 
                                                             "NEXT", "NL_AJ", 
                                                             "NLJ_BATCHING", 
                                                             "NLJ_INDEX_FILTER", 
                                                             "NLJ_INDEX_SCAN", 
                                                             "NLJ_PREFETCH", 
                                                             "NLS_CALENDAR", 
                                                             "NLS_CHARACTERSET", 
                                                             "NLS_CHARSET_DECL_LEN", 
                                                             "NLS_CHARSET_ID", 
                                                             "NLS_CHARSET_NAME", 
                                                             "NLS_COMP", 
                                                             "NLS_CURRENCY", 
                                                             "NLS_DATE_FORMAT", 
                                                             "NLS_DATE_LANGUAGE", 
                                                             "NLS_INITCAP", 
                                                             "NLS_ISO_CURRENCY", 
                                                             "NL_SJ", "NLS_LANG", 
                                                             "NLS_LANGUAGE", 
                                                             "NLS_LENGTH_SEMANTICS", 
                                                             "NLS_LOWER", 
                                                             "NLS_NCHAR_CONV_EXCP", 
                                                             "NLS_NUMERIC_CHARACTERS", 
                                                             "NLS_SORT", 
                                                             "NLSSORT", 
                                                             "NLS_SPECIAL_CHARS", 
                                                             "NLS_TERRITORY", 
                                                             "NLS_UPPER", 
                                                             "NO_ACCESS", 
                                                             "NO_ADAPTIVE_PLAN", 
                                                             "NO_ANSI_REARCH", 
                                                             "NOAPPEND", 
                                                             "NOARCHIVELOG", 
                                                             "NOAUDIT", 
                                                             "NO_AUTO_REOPTIMIZE", 
                                                             "NO_BASETABLE_MULTIMV_REWRITE", 
                                                             "NO_BATCH_TABLE_ACCESS_BY_ROWID", 
                                                             "NO_BIND_AWARE", 
                                                             "NO_BUFFER", 
                                                             "NOCACHE", 
                                                             "NO_CARTESIAN", 
                                                             "NO_CHECK_ACL_REWRITE", 
                                                             "NO_CLUSTER_BY_ROWID", 
                                                             "NO_CLUSTERING", 
                                                             "NO_COALESCE_SQ", 
                                                             "NO_COMMON_DATA", 
                                                             "NOCOMPRESS", 
                                                             "NO_CONNECT_BY_CB_WHR_ONLY", 
                                                             "NO_CONNECT_BY_COMBINE_SW", 
                                                             "NO_CONNECT_BY_COST_BASED", 
                                                             "NO_CONNECT_BY_ELIM_DUPS", 
                                                             "NO_CONNECT_BY_FILTERING", 
                                                             "NOCOPY", "NO_COST_XML_QUERY_REWRITE", 
                                                             "NO_CPU_COSTING", 
                                                             "NOCPU_COSTING", 
                                                             "NOCYCLE", 
                                                             "NO_DATA_SECURITY_REWRITE", 
                                                             "NO_DECORRELATE", 
                                                             "NODELAY", 
                                                             "NO_DOMAIN_INDEX_FILTER", 
                                                             "NO_DST_UPGRADE_INSERT_CONV", 
                                                             "NO_ELIM_GROUPBY", 
                                                             "NO_ELIMINATE_JOIN", 
                                                             "NO_ELIMINATE_OBY", 
                                                             "NO_ELIMINATE_OUTER_JOIN", 
                                                             "NOENTITYESCAPING", 
                                                             "NO_EXPAND_GSET_TO_UNION", 
                                                             "NO_EXPAND", 
                                                             "NO_EXPAND_TABLE", 
                                                             "NO_FACT", 
                                                             "NO_FACTORIZE_JOIN", 
                                                             "NO_FILTERING", 
                                                             "NOFORCE", 
                                                             "NO_FULL_OUTER_JOIN_TO_OUTER", 
                                                             "NO_GATHER_OPTIMIZER_STATISTICS", 
                                                             "NO_GBY_PUSHDOWN", 
                                                             "NOGUARANTEE", 
                                                             "NO_INDEX_FFS", 
                                                             "NO_INDEX", 
                                                             "NO_INDEX_SS", 
                                                             "NO_INMEMORY", 
                                                             "NO_INMEMORY_PRUNING", 
                                                             "NOKEEP", "NO_LOAD", 
                                                             "NOLOCAL", 
                                                             "NOLOGGING", 
                                                             "NOMAPPING", 
                                                             "NOMAXVALUE", 
                                                             "NO_MERGE", 
                                                             "NOMINIMIZE", 
                                                             "NOMINVALUE", 
                                                             "NO_MODEL_PUSH_REF", 
                                                             "NO_MONITORING", 
                                                             "NOMONITORING", 
                                                             "NO_MONITOR", 
                                                             "NO_MULTIMV_REWRITE", 
                                                             "NO_NATIVE_FULL_OUTER_JOIN", 
                                                             "NONBLOCKING", 
                                                             "NONEDITIONABLE", 
                                                             "NONE", "NO_NLJ_BATCHING", 
                                                             "NO_NLJ_PREFETCH", 
                                                             "NO", "NONSCHEMA", 
                                                             "NO_OBJECT_LINK", 
                                                             "NOORDER", 
                                                             "NO_ORDER_ROLLUPS", 
                                                             "NO_OUTER_JOIN_TO_ANTI", 
                                                             "NO_OUTER_JOIN_TO_INNER", 
                                                             "NOOVERRIDE", 
                                                             "NO_PARALLEL_INDEX", 
                                                             "NOPARALLEL_INDEX", 
                                                             "NO_PARALLEL", 
                                                             "NOPARALLEL", 
                                                             "NO_PARTIAL_COMMIT", 
                                                             "NO_PARTIAL_JOIN", 
                                                             "NO_PARTIAL_ROLLUP_PUSHDOWN", 
                                                             "NOPARTITION", 
                                                             "NO_PLACE_DISTINCT", 
                                                             "NO_PLACE_GROUP_BY", 
                                                             "NO_PQ_CONCURRENT_UNION", 
                                                             "NO_PQ_MAP", 
                                                             "NOPROMPT", 
                                                             "NO_PQ_REPLICATE", 
                                                             "NO_PQ_SKEW", 
                                                             "NO_PRUNE_GSETS", 
                                                             "NO_PULL_PRED", 
                                                             "NO_PUSH_PRED", 
                                                             "NO_PUSH_SUBQ", 
                                                             "NO_PX_FAULT_TOLERANCE", 
                                                             "NO_PX_JOIN_FILTER", 
                                                             "NO_QKN_BUFF", 
                                                             "NO_QUERY_TRANSFORMATION", 
                                                             "NO_REF_CASCADE", 
                                                             "NORELOCATE", 
                                                             "NORELY", "NOREPAIR", 
                                                             "NOREPLAY", 
                                                             "NORESETLOGS", 
                                                             "NO_RESULT_CACHE", 
                                                             "NOREVERSE", 
                                                             "NO_REWRITE", 
                                                             "NOREWRITE", 
                                                             "NORMAL", "NO_ROOT_SW_FOR_LOCAL", 
                                                             "NOROWDEPENDENCIES", 
                                                             "NOSCHEMACHECK", 
                                                             "NOSEGMENT", 
                                                             "NO_SEMIJOIN", 
                                                             "NO_SEMI_TO_INNER", 
                                                             "NO_SET_TO_JOIN", 
                                                             "NOSORT", "NO_SQL_TRANSLATION", 
                                                             "NO_SQL_TUNE", 
                                                             "NO_STAR_TRANSFORMATION", 
                                                             "NO_STATEMENT_QUEUING", 
                                                             "NO_STATS_GSETS", 
                                                             "NOSTRICT", 
                                                             "NO_SUBQUERY_PRUNING", 
                                                             "NO_SUBSTRB_PAD", 
                                                             "NO_SWAP_JOIN_INPUTS", 
                                                             "NOSWITCH", 
                                                             "NO_TABLE_LOOKUP_BY_NL", 
                                                             "NO_TEMP_TABLE", 
                                                             "NOTHING", 
                                                             "NOTIFICATION", 
                                                             "NOT", "NO_TRANSFORM_DISTINCT_AGG", 
                                                             "NO_UNNEST", 
                                                             "NO_USE_CUBE", 
                                                             "NO_USE_HASH_AGGREGATION", 
                                                             "NO_USE_HASH_GBY_FOR_PUSHDOWN", 
                                                             "NO_USE_HASH", 
                                                             "NO_USE_INVISIBLE_INDEXES", 
                                                             "NO_USE_MERGE", 
                                                             "NO_USE_NL", 
                                                             "NO_USE_VECTOR_AGGREGATION", 
                                                             "NOVALIDATE", 
                                                             "NO_VECTOR_TRANSFORM_DIMS", 
                                                             "NO_VECTOR_TRANSFORM_FACT", 
                                                             "NO_VECTOR_TRANSFORM", 
                                                             "NOWAIT", "NO_XDB_FASTPATH_INSERT", 
                                                             "NO_XML_DML_REWRITE", 
                                                             "NO_XMLINDEX_REWRITE_IN_SELECT", 
                                                             "NO_XMLINDEX_REWRITE", 
                                                             "NO_XML_QUERY_REWRITE", 
                                                             "NO_ZONEMAP", 
                                                             "NTH_VALUE", 
                                                             "NULLIF", "NULL_", 
                                                             "NULLS", "NUMBER", 
                                                             "NUMERIC", 
                                                             "NUM_INDEX_KEYS", 
                                                             "NUMTODSINTERVAL", 
                                                             "NUMTOYMINTERVAL", 
                                                             "NVARCHAR2", 
                                                             "NVL2", "OBJECT2XML", 
                                                             "OBJECT", "OBJ_ID", 
                                                             "OBJNO", "OBJNO_REUSE", 
                                                             "OCCURENCES", 
                                                             "OFFLINE", 
                                                             "OFF", "OFFSET", 
                                                             "OF", "OIDINDEX", 
                                                             "OID", "OLAP", 
                                                             "OLD", "OLD_PUSH_PRED", 
                                                             "OLS", "OLTP", 
                                                             "OMIT", "ONE", 
                                                             "ONLINE", "ONLINELOG", 
                                                             "ONLY", "ON", 
                                                             "OPAQUE", "OPAQUE_TRANSFORM", 
                                                             "OPAQUE_XCANONICAL", 
                                                             "OPCODE", "OPEN", 
                                                             "OPERATIONS", 
                                                             "OPERATOR", 
                                                             "OPT_ESTIMATE", 
                                                             "OPTIMAL", 
                                                             "OPTIMIZE", 
                                                             "OPTIMIZER_FEATURES_ENABLE", 
                                                             "OPTIMIZER_GOAL", 
                                                             "OPTION", "OPT_PARAM", 
                                                             "ORA_BRANCH", 
                                                             "ORA_CHECK_ACL", 
                                                             "ORA_CHECK_PRIVILEGE", 
                                                             "ORA_CLUSTERING", 
                                                             "ORADATA", 
                                                             "ORADEBUG", 
                                                             "ORA_DST_AFFECTED", 
                                                             "ORA_DST_CONVERT", 
                                                             "ORA_DST_ERROR", 
                                                             "ORA_GET_ACLIDS", 
                                                             "ORA_GET_PRIVILEGES", 
                                                             "ORA_HASH", 
                                                             "ORA_INVOKING_USERID", 
                                                             "ORA_INVOKING_USER", 
                                                             "ORA_INVOKING_XS_USER_GUID", 
                                                             "ORA_INVOKING_XS_USER", 
                                                             "ORA_RAWCOMPARE", 
                                                             "ORA_RAWCONCAT", 
                                                             "ORA_ROWSCN", 
                                                             "ORA_ROWSCN_RAW", 
                                                             "ORA_ROWVERSION", 
                                                             "ORA_TABVERSION", 
                                                             "ORA_WRITE_TIME", 
                                                             "ORDERED", 
                                                             "ORDERED_PREDICATES", 
                                                             "ORDER", "ORDINALITY", 
                                                             "OR_EXPAND", 
                                                             "ORGANIZATION", 
                                                             "OR", "OR_PREDICATES", 
                                                             "OSERROR", 
                                                             "OTHER", "OUTER_JOIN_TO_ANTI", 
                                                             "OUTER_JOIN_TO_INNER", 
                                                             "OUTER", "OUTLINE_LEAF", 
                                                             "OUTLINE", 
                                                             "OUT_OF_LINE", 
                                                             "OUT", "OVERFLOW_NOMOVE", 
                                                             "OVERFLOW", 
                                                             "OVERLAPS", 
                                                             "OVER", "OVERRIDING", 
                                                             "OWNER", "OWNERSHIP", 
                                                             "OWN", "P_LETTER", 
                                                             "PACKAGE", 
                                                             "PACKAGES", 
                                                             "PARALLEL_ENABLE", 
                                                             "PARALLEL_INDEX", 
                                                             "PARALLEL", 
                                                             "PARAMETERFILE", 
                                                             "PARAMETERS", 
                                                             "PARAM", "PARENT", 
                                                             "PARENT_LEVEL_NAME", 
                                                             "PARENT_UNIQUE_NAME", 
                                                             "PARITY", "PARTIAL_JOIN", 
                                                             "PARTIALLY", 
                                                             "PARTIAL", 
                                                             "PARTIAL_ROLLUP_PUSHDOWN", 
                                                             "PARTITION_HASH", 
                                                             "PARTITION_LIST", 
                                                             "PARTITION", 
                                                             "PARTITION_RANGE", 
                                                             "PARTITIONS", 
                                                             "PARTNUMINST", 
                                                             "PASSING", 
                                                             "PASSWORD_GRACE_TIME", 
                                                             "PASSWORD_LIFE_TIME", 
                                                             "PASSWORD_LOCK_TIME", 
                                                             "PASSWORD", 
                                                             "PASSWORD_REUSE_MAX", 
                                                             "PASSWORD_REUSE_TIME", 
                                                             "PASSWORD_ROLLOVER_TIME", 
                                                             "PASSWORD_VERIFY_FUNCTION", 
                                                             "PAST", "PATCH", 
                                                             "PATH", "PATH_PREFIX", 
                                                             "PATHS", "PATTERN", 
                                                             "PBL_HS_BEGIN", 
                                                             "PBL_HS_END", 
                                                             "PCTFREE", 
                                                             "PCTINCREASE", 
                                                             "PCTTHRESHOLD", 
                                                             "PCTUSED", 
                                                             "PCTVERSION", 
                                                             "PENDING", 
                                                             "PERCENT_FOUND", 
                                                             "PERCENT_ISOPEN", 
                                                             "PERCENT_NOTFOUND", 
                                                             "PERCENT_KEYWORD", 
                                                             "PERCENT_RANKM", 
                                                             "PERCENT_ROWCOUNT", 
                                                             "PERCENT_ROWTYPE", 
                                                             "PERCENT_TYPE", 
                                                             "PERFORMANCE", 
                                                             "PERIOD_KEYWORD", 
                                                             "PERMANENT", 
                                                             "PERMISSION", 
                                                             "PERMUTE", 
                                                             "PER", "PFILE", 
                                                             "PHYSICAL", 
                                                             "PIKEY", "PIPELINED", 
                                                             "PIPE", "PIV_GB", 
                                                             "PIVOT", "PIV_SSF", 
                                                             "PLACE_DISTINCT", 
                                                             "PLACE_GROUP_BY", 
                                                             "PLAN", "PLSCOPE_SETTINGS", 
                                                             "PLS_INTEGER", 
                                                             "PLSQL_CCFLAGS", 
                                                             "PLSQL_CODE_TYPE", 
                                                             "PLSQL_DEBUG", 
                                                             "PLSQL_OPTIMIZE_LEVEL", 
                                                             "PLSQL_WARNINGS", 
                                                             "PLUGGABLE", 
                                                             "PMEM", "POINT", 
                                                             "POLICY", "POOL_16K", 
                                                             "POOL_2K", 
                                                             "POOL_32K", 
                                                             "POOL_4K", 
                                                             "POOL_8K", 
                                                             "POSITIVEN", 
                                                             "POSITIVE", 
                                                             "POST_TRANSACTION", 
                                                             "POWERMULTISET_BY_CARDINALITY", 
                                                             "POWERMULTISET", 
                                                             "POWER", "PQ_CONCURRENT_UNION", 
                                                             "PQ_DISTRIBUTE", 
                                                             "PQ_DISTRIBUTE_WINDOW", 
                                                             "PQ_FILTER", 
                                                             "PQ_MAP", "PQ_NOMAP", 
                                                             "PQ_REPLICATE", 
                                                             "PQ_SKEW", 
                                                             "PRAGMA", "PREBUILT", 
                                                             "PRECEDES", 
                                                             "PRECEDING", 
                                                             "PRECISION", 
                                                             "PRECOMPUTE_SUBQUERY", 
                                                             "PREDICATE_REORDERS", 
                                                             "PRELOAD", 
                                                             "PREPARE", 
                                                             "PRESENTNNV", 
                                                             "PRESENT", 
                                                             "PRESENTV", 
                                                             "PRESERVE_OID", 
                                                             "PRESERVE", 
                                                             "PRETTY", "PREVIOUS", 
                                                             "PREV", "PRIMARY", 
                                                             "PRINTBLOBTOCLOB", 
                                                             "PRIORITY", 
                                                             "PRIOR", "PRIVATE", 
                                                             "PRIVATE_SGA", 
                                                             "PRIVILEGED", 
                                                             "PRIVILEGE", 
                                                             "PRIVILEGES", 
                                                             "PROCEDURAL", 
                                                             "PROCEDURE", 
                                                             "PROCESS", 
                                                             "PROFILE", 
                                                             "PROGRAM", 
                                                             "PROJECT", 
                                                             "PROPAGATE", 
                                                             "PROPERTY", 
                                                             "PROTECTED", 
                                                             "PROTECTION", 
                                                             "PROTOCOL", 
                                                             "PROXY", "PRUNING", 
                                                             "PUBLIC", "PULL_PRED", 
                                                             "PURGE", "PUSH_PRED", 
                                                             "PUSH_SUBQ", 
                                                             "PX_FAULT_TOLERANCE", 
                                                             "PX_GRANULE", 
                                                             "PX_JOIN_FILTER", 
                                                             "QB_NAME", 
                                                             "QUARTERS", 
                                                             "QUERY_BLOCK", 
                                                             "QUERY", "QUEUE_CURR", 
                                                             "QUEUE", "QUEUE_ROWP", 
                                                             "QUIESCE", 
                                                             "QUORUM", "QUOTA", 
                                                             "QUOTAGROUP", 
                                                             "RAISE", "RANDOM_LOCAL", 
                                                             "RANDOM", "RANGE", 
                                                             "RANKM", "RAPIDLY", 
                                                             "RAW", "RAWTOHEX", 
                                                             "RAWTONHEX", 
                                                             "RBA", "RBO_OUTLINE", 
                                                             "RDBA", "READ", 
                                                             "READS", "REALM", 
                                                             "REAL", "REBALANCE", 
                                                             "REBUILD", 
                                                             "RECORD", "RECORDS_PER_BLOCK", 
                                                             "RECOVERABLE", 
                                                             "RECOVER", 
                                                             "RECOVERY", 
                                                             "RECYCLEBIN", 
                                                             "RECYCLE", 
                                                             "REDACTION", 
                                                             "REDEFINE", 
                                                             "REDO", "REDUCED", 
                                                             "REDUNDANCY", 
                                                             "REF_CASCADE_CURSOR", 
                                                             "REFERENCED", 
                                                             "REFERENCE", 
                                                             "REFERENCES", 
                                                             "REFERENCING", 
                                                             "REF", "REFRESH", 
                                                             "REFTOHEX", 
                                                             "REGEXP_COUNT", 
                                                             "REGEXP_INSTR", 
                                                             "REGEXP_LIKE", 
                                                             "REGEXP_REPLACE", 
                                                             "REGEXP_SUBSTR", 
                                                             "REGISTER", 
                                                             "REGR_AVGX", 
                                                             "REGR_AVGY", 
                                                             "REGR_COUNT", 
                                                             "REGR_INTERCEPT", 
                                                             "REGR_R2", 
                                                             "REGR_SLOPE", 
                                                             "REGR_SXX", 
                                                             "REGR_SXY", 
                                                             "REGR_SYY", 
                                                             "REGULAR", 
                                                             "REJECT", "REKEY", 
                                                             "RELATIONAL", 
                                                             "RELIES_ON", 
                                                             "RELOCATE", 
                                                             "RELY", "REMAINDER", 
                                                             "REMOTE", "REMOTE_MAPPED", 
                                                             "REMOVE", "RENAME", 
                                                             "REPAIR", "REPEAT", 
                                                             "REPLACE", 
                                                             "REPLICATION", 
                                                             "REQUIRED", 
                                                             "RESETLOGS", 
                                                             "RESET", "RESIZE", 
                                                             "RESOLVE", 
                                                             "RESOLVER", 
                                                             "RESOURCE", 
                                                             "RESPECT", 
                                                             "RESTART", 
                                                             "RESTORE_AS_INTERVALS", 
                                                             "RESTORE", 
                                                             "RESTRICT_ALL_REF_CONS", 
                                                             "RESTRICTED", 
                                                             "RESTRICT_REFERENCES", 
                                                             "RESTRICT", 
                                                             "RESULT_CACHE", 
                                                             "RESULT", "RESUMABLE", 
                                                             "RESUME", "RETENTION", 
                                                             "RETRY_ON_ROW_CHANGE", 
                                                             "RETURNING", 
                                                             "RETURN", "REUSE", 
                                                             "REVERSE", 
                                                             "REVOKE", "REWRITE_OR_ERROR", 
                                                             "REWRITE", 
                                                             "RIGHT", "ROLE", 
                                                             "ROLESET", 
                                                             "ROLES", "ROLLBACK", 
                                                             "ROLLING", 
                                                             "ROLLUP", "ROWDEPENDENCIES", 
                                                             "ROWID_MAPPING_TABLE", 
                                                             "ROWID", "ROWIDTOCHAR", 
                                                             "ROWIDTONCHAR", 
                                                             "ROW_LENGTH", 
                                                             "ROWNUM", "ROW", 
                                                             "ROWS", "RPAD", 
                                                             "RTRIM", "RULE", 
                                                             "RULES", "RUNNING", 
                                                             "SALT", "SAMPLE", 
                                                             "SAVE_AS_INTERVALS", 
                                                             "SAVEPOINT", 
                                                             "SAVE", "SB4", 
                                                             "SCALE_ROWS", 
                                                             "SCALE", "SCAN_INSTANCES", 
                                                             "SCAN", "SCHEDULER", 
                                                             "SCHEMACHECK", 
                                                             "SCHEMA", "SCN_ASCENDING", 
                                                             "SCN", "SCOPE", 
                                                             "SCRUB", "SD_ALL", 
                                                             "SD_INHIBIT", 
                                                             "SDO_GEOM_MBR", 
                                                             "SDO_GEOMETRY", 
                                                             "SD_SHOW", 
                                                             "SEARCH", "SECOND", 
                                                             "SECONDS", 
                                                             "SECRET", "SECUREFILE_DBA", 
                                                             "SECUREFILE", 
                                                             "SECURITY", 
                                                             "SEED", "SEG_BLOCK", 
                                                             "SEG_FILE", 
                                                             "SEGMENT", 
                                                             "SELECTIVITY", 
                                                             "SELECT", "SELF", 
                                                             "SEMIJOIN_DRIVER", 
                                                             "SEMIJOIN", 
                                                             "SEMI_TO_INNER", 
                                                             "SEQUENCED", 
                                                             "SEQUENCE", 
                                                             "SEQUENTIAL", 
                                                             "SEQ", "SERIALIZABLE", 
                                                             "SERIALLY_REUSABLE", 
                                                             "SERIAL", "SERVERERROR", 
                                                             "SERVICE_NAME_CONVERT", 
                                                             "SERVICE", 
                                                             "SERVICES", 
                                                             "SESSION_CACHED_CURSORS", 
                                                             "SESSION", 
                                                             "SESSIONS_PER_USER", 
                                                             "SESSIONTIMEZONE", 
                                                             "SESSIONTZNAME", 
                                                             "SET", "SETS", 
                                                             "SETTINGS", 
                                                             "SET_TO_JOIN", 
                                                             "SEVERE", "SHARDSPACE", 
                                                             "SHARED_POOL", 
                                                             "SHARED", "SHARE", 
                                                             "SHARING", 
                                                             "SHELFLIFE", 
                                                             "SHOW", "SHRINK", 
                                                             "SHUTDOWN", 
                                                             "SIBLINGS", 
                                                             "SID", "SITE", 
                                                             "SIGNAL_COMPONENT", 
                                                             "SIGNAL_FUNCTION", 
                                                             "SIGN", "SIGNTYPE", 
                                                             "SIMPLE_INTEGER", 
                                                             "SIMPLE", "SINGLE", 
                                                             "SINGLETASK", 
                                                             "SINH", "SIN", 
                                                             "SIZE", "SKIP_EXT_OPTIMIZER", 
                                                             "SKIP_", "SKIP_UNQ_UNUSABLE_IDX", 
                                                             "SKIP_UNUSABLE_INDEXES", 
                                                             "SMALLFILE", 
                                                             "SMALLINT", 
                                                             "SNAPSHOT", 
                                                             "SOME", "SORT", 
                                                             "SOUNDEX", 
                                                             "SOURCE_FILE_DIRECTORY", 
                                                             "SOURCE_FILE_NAME_CONVERT", 
                                                             "SOURCE", "SPACE_KEYWORD", 
                                                             "SPECIFICATION", 
                                                             "SPFILE", "SPLIT", 
                                                             "SPREADSHEET", 
                                                             "SQLDATA", 
                                                             "SQLERROR", 
                                                             "SQLLDR", "SQL", 
                                                             "FILE_EXT", 
                                                             "SQL_MACRO", 
                                                             "SQL_TRACE", 
                                                             "SQL_TRANSLATION_PROFILE", 
                                                             "SQRT", "STALE", 
                                                             "STANDALONE", 
                                                             "STANDARD", 
                                                             "STANDARD_HASH", 
                                                             "STANDBY_MAX_DATA_DELAY", 
                                                             "STANDBYS", 
                                                             "STANDBY", 
                                                             "STAR", "STAR_TRANSFORMATION", 
                                                             "START", "STARTUP", 
                                                             "STATEMENT_ID", 
                                                             "STATEMENT_QUEUING", 
                                                             "STATEMENTS", 
                                                             "STATEMENT", 
                                                             "STATE", "STATIC", 
                                                             "STATISTICS", 
                                                             "STATS_BINOMIAL_TEST", 
                                                             "STATS_CROSSTAB", 
                                                             "STATS_F_TEST", 
                                                             "STATS_KS_TEST", 
                                                             "STATS_MODE", 
                                                             "STATS_MW_TEST", 
                                                             "STATS_ONE_WAY_ANOVA", 
                                                             "STATS_T_TEST_INDEP", 
                                                             "STATS_T_TEST_INDEPU", 
                                                             "STATS_T_TEST_ONE", 
                                                             "STATS_T_TEST_PAIRED", 
                                                             "STATS_WSR_TEST", 
                                                             "STDDEV_POP", 
                                                             "STDDEV_SAMP", 
                                                             "STOP", "STORAGE", 
                                                             "STORE", "STREAMS", 
                                                             "STREAM", "STRICT", 
                                                             "STRING", "STRIPE_COLUMNS", 
                                                             "STRIPE_WIDTH", 
                                                             "STRIP", "STRUCTURE", 
                                                             "SUBMULTISET", 
                                                             "SUBPARTITION_REL", 
                                                             "SUBPARTITIONS", 
                                                             "SUBPARTITION", 
                                                             "SUBQUERIES", 
                                                             "SUBQUERY_PRUNING", 
                                                             "SUBSCRIBE", 
                                                             "SUBSET", "SUBSTITUTABLE", 
                                                             "SUBSTR2", 
                                                             "SUBSTR4", 
                                                             "SUBSTRB", 
                                                             "SUBSTRC", 
                                                             "SUBTYPE", 
                                                             "SUCCESSFUL", 
                                                             "SUCCESS", 
                                                             "SUMMARY", 
                                                             "SUPPLEMENTAL", 
                                                             "SUSPEND", 
                                                             "SWAP_JOIN_INPUTS", 
                                                             "SWITCHOVER", 
                                                             "SWITCH", "SYNCHRONOUS", 
                                                             "SYNC", "SYNONYM", 
                                                             "SYS", "SYSASM", 
                                                             "SYS_AUDIT", 
                                                             "SYSAUX", "SYSBACKUP", 
                                                             "SYS_CHECKACL", 
                                                             "SYS_CHECK_PRIVILEGE", 
                                                             "SYS_CONNECT_BY_PATH", 
                                                             "SYS_CONTEXT", 
                                                             "SYSDATE", 
                                                             "SYSDBA", "SYS_DBURIGEN", 
                                                             "SYSDG", "SYS_DL_CURSOR", 
                                                             "SYS_DM_RXFORM_CHR", 
                                                             "SYS_DM_RXFORM_NUM", 
                                                             "SYS_DOM_COMPARE", 
                                                             "SYS_DST_PRIM2SEC", 
                                                             "SYS_DST_SEC2PRIM", 
                                                             "SYS_ET_BFILE_TO_RAW", 
                                                             "SYS_ET_BLOB_TO_IMAGE", 
                                                             "SYS_ET_IMAGE_TO_BLOB", 
                                                             "SYS_ET_RAW_TO_BFILE", 
                                                             "SYS_EXTPDTXT", 
                                                             "SYS_EXTRACT_UTC", 
                                                             "SYS_FBT_INSDEL", 
                                                             "SYS_FILTER_ACLS", 
                                                             "SYS_FNMATCHES", 
                                                             "SYS_FNREPLACE", 
                                                             "SYS_GET_ACLIDS", 
                                                             "SYS_GET_COL_ACLIDS", 
                                                             "SYS_GET_PRIVILEGES", 
                                                             "SYS_GETTOKENID", 
                                                             "SYS_GETXTIVAL", 
                                                             "SYS_GUID", 
                                                             "SYSGUID", 
                                                             "SYSKM", "SYS_MAKE_XMLNODEID", 
                                                             "SYS_MAKEXML", 
                                                             "SYS_MKXMLATTR", 
                                                             "SYS_MKXTI", 
                                                             "SYSOBJ", "SYS_OP_ADT2BIN", 
                                                             "SYS_OP_ADTCONS", 
                                                             "SYS_OP_ALSCRVAL", 
                                                             "SYS_OP_ATG", 
                                                             "SYS_OP_BIN2ADT", 
                                                             "SYS_OP_BITVEC", 
                                                             "SYS_OP_BL2R", 
                                                             "SYS_OP_BLOOM_FILTER_LIST", 
                                                             "SYS_OP_BLOOM_FILTER", 
                                                             "SYS_OP_C2C", 
                                                             "SYS_OP_CAST", 
                                                             "SYS_OP_CEG", 
                                                             "SYS_OP_CL2C", 
                                                             "SYS_OP_COMBINED_HASH", 
                                                             "SYS_OP_COMP", 
                                                             "SYS_OP_CONVERT", 
                                                             "SYS_OP_COUNTCHG", 
                                                             "SYS_OP_CSCONV", 
                                                             "SYS_OP_CSCONVTEST", 
                                                             "SYS_OP_CSR", 
                                                             "SYS_OP_CSX_PATCH", 
                                                             "SYS_OP_CYCLED_SEQ", 
                                                             "SYS_OP_DECOMP", 
                                                             "SYS_OP_DESCEND", 
                                                             "SYS_OP_DISTINCT", 
                                                             "SYS_OP_DRA", 
                                                             "SYS_OP_DUMP", 
                                                             "SYS_OP_DV_CHECK", 
                                                             "SYS_OP_ENFORCE_NOT_NULL", 
                                                             "SYSOPER", 
                                                             "SYS_OP_EXTRACT", 
                                                             "SYS_OP_GROUPING", 
                                                             "SYS_OP_GUID", 
                                                             "SYS_OP_HASH", 
                                                             "SYS_OP_IIX", 
                                                             "SYS_OP_ITR", 
                                                             "SYS_OP_KEY_VECTOR_CREATE", 
                                                             "SYS_OP_KEY_VECTOR_FILTER_LIST", 
                                                             "SYS_OP_KEY_VECTOR_FILTER", 
                                                             "SYS_OP_KEY_VECTOR_SUCCEEDED", 
                                                             "SYS_OP_KEY_VECTOR_USE", 
                                                             "SYS_OP_LBID", 
                                                             "SYS_OP_LOBLOC2BLOB", 
                                                             "SYS_OP_LOBLOC2CLOB", 
                                                             "SYS_OP_LOBLOC2ID", 
                                                             "SYS_OP_LOBLOC2NCLOB", 
                                                             "SYS_OP_LOBLOC2TYP", 
                                                             "SYS_OP_LSVI", 
                                                             "SYS_OP_LVL", 
                                                             "SYS_OP_MAKEOID", 
                                                             "SYS_OP_MAP_NONNULL", 
                                                             "SYS_OP_MSR", 
                                                             "SYS_OP_NICOMBINE", 
                                                             "SYS_OP_NIEXTRACT", 
                                                             "SYS_OP_NII", 
                                                             "SYS_OP_NIX", 
                                                             "SYS_OP_NOEXPAND", 
                                                             "SYS_OP_NTCIMG", 
                                                             "SYS_OP_NUMTORAW", 
                                                             "SYS_OP_OIDVALUE", 
                                                             "SYS_OP_OPNSIZE", 
                                                             "SYS_OP_PAR_1", 
                                                             "SYS_OP_PARGID_1", 
                                                             "SYS_OP_PARGID", 
                                                             "SYS_OP_PAR", 
                                                             "SYS_OP_PART_ID", 
                                                             "SYS_OP_PIVOT", 
                                                             "SYS_OP_R2O", 
                                                             "SYS_OP_RAWTONUM", 
                                                             "SYS_OP_RDTM", 
                                                             "SYS_OP_REF", 
                                                             "SYS_OP_RMTD", 
                                                             "SYS_OP_ROWIDTOOBJ", 
                                                             "SYS_OP_RPB", 
                                                             "SYS_OPTLOBPRBSC", 
                                                             "SYS_OP_TOSETID", 
                                                             "SYS_OP_TPR", 
                                                             "SYS_OP_TRTB", 
                                                             "SYS_OPTXICMP", 
                                                             "SYS_OPTXQCASTASNQ", 
                                                             "SYS_OP_UNDESCEND", 
                                                             "SYS_OP_VECAND", 
                                                             "SYS_OP_VECBIT", 
                                                             "SYS_OP_VECOR", 
                                                             "SYS_OP_VECXOR", 
                                                             "SYS_OP_VERSION", 
                                                             "SYS_OP_VREF", 
                                                             "SYS_OP_VVD", 
                                                             "SYS_OP_XMLCONS_FOR_CSX", 
                                                             "SYS_OP_XPTHATG", 
                                                             "SYS_OP_XPTHIDX", 
                                                             "SYS_OP_XPTHOP", 
                                                             "SYS_OP_XTXT2SQLT", 
                                                             "SYS_OP_ZONE_ID", 
                                                             "SYS_ORDERKEY_DEPTH", 
                                                             "SYS_ORDERKEY_MAXCHILD", 
                                                             "SYS_ORDERKEY_PARENT", 
                                                             "SYS_PARALLEL_TXN", 
                                                             "SYS_PATHID_IS_ATTR", 
                                                             "SYS_PATHID_IS_NMSPC", 
                                                             "SYS_PATHID_LASTNAME", 
                                                             "SYS_PATHID_LASTNMSPC", 
                                                             "SYS_PATH_REVERSE", 
                                                             "SYS_PXQEXTRACT", 
                                                             "SYS_RAW_TO_XSID", 
                                                             "SYS_RID_ORDER", 
                                                             "SYS_ROW_DELTA", 
                                                             "SYS_SC_2_XMLT", 
                                                             "SYS_SYNRCIREDO", 
                                                             "SYSTEM_DEFINED", 
                                                             "SYSTEM", "SYSTIMESTAMP", 
                                                             "SYS_TYPEID", 
                                                             "SYS_UMAKEXML", 
                                                             "SYS_XMLANALYZE", 
                                                             "SYS_XMLCONTAINS", 
                                                             "SYS_XMLCONV", 
                                                             "SYS_XMLEXNSURI", 
                                                             "SYS_XMLGEN", 
                                                             "SYS_XMLI_LOC_ISNODE", 
                                                             "SYS_XMLI_LOC_ISTEXT", 
                                                             "SYS_XMLINSTR", 
                                                             "SYS_XMLLOCATOR_GETSVAL", 
                                                             "SYS_XMLNODEID_GETCID", 
                                                             "SYS_XMLNODEID_GETLOCATOR", 
                                                             "SYS_XMLNODEID_GETOKEY", 
                                                             "SYS_XMLNODEID_GETPATHID", 
                                                             "SYS_XMLNODEID_GETPTRID", 
                                                             "SYS_XMLNODEID_GETRID", 
                                                             "SYS_XMLNODEID_GETSVAL", 
                                                             "SYS_XMLNODEID_GETTID", 
                                                             "SYS_XMLNODEID", 
                                                             "SYS_XMLT_2_SC", 
                                                             "SYS_XMLTRANSLATE", 
                                                             "SYS_XMLTYPE2SQL", 
                                                             "SYS_XQ_ASQLCNV", 
                                                             "SYS_XQ_ATOMCNVCHK", 
                                                             "SYS_XQBASEURI", 
                                                             "SYS_XQCASTABLEERRH", 
                                                             "SYS_XQCODEP2STR", 
                                                             "SYS_XQCODEPEQ", 
                                                             "SYS_XQCON2SEQ", 
                                                             "SYS_XQCONCAT", 
                                                             "SYS_XQDELETE", 
                                                             "SYS_XQDFLTCOLATION", 
                                                             "SYS_XQDOC", 
                                                             "SYS_XQDOCURI", 
                                                             "SYS_XQDURDIV", 
                                                             "SYS_XQED4URI", 
                                                             "SYS_XQENDSWITH", 
                                                             "SYS_XQERRH", 
                                                             "SYS_XQERR", 
                                                             "SYS_XQESHTMLURI", 
                                                             "SYS_XQEXLOBVAL", 
                                                             "SYS_XQEXSTWRP", 
                                                             "SYS_XQEXTRACT", 
                                                             "SYS_XQEXTRREF", 
                                                             "SYS_XQEXVAL", 
                                                             "SYS_XQFB2STR", 
                                                             "SYS_XQFNBOOL", 
                                                             "SYS_XQFNCMP", 
                                                             "SYS_XQFNDATIM", 
                                                             "SYS_XQFNLNAME", 
                                                             "SYS_XQFNNM", 
                                                             "SYS_XQFNNSURI", 
                                                             "SYS_XQFNPREDTRUTH", 
                                                             "SYS_XQFNQNM", 
                                                             "SYS_XQFNROOT", 
                                                             "SYS_XQFORMATNUM", 
                                                             "SYS_XQFTCONTAIN", 
                                                             "SYS_XQFUNCR", 
                                                             "SYS_XQGETCONTENT", 
                                                             "SYS_XQINDXOF", 
                                                             "SYS_XQINSERT", 
                                                             "SYS_XQINSPFX", 
                                                             "SYS_XQIRI2URI", 
                                                             "SYS_XQLANG", 
                                                             "SYS_XQLLNMFRMQNM", 
                                                             "SYS_XQMKNODEREF", 
                                                             "SYS_XQNILLED", 
                                                             "SYS_XQNODENAME", 
                                                             "SYS_XQNORMSPACE", 
                                                             "SYS_XQNORMUCODE", 
                                                             "SYS_XQ_NRNG", 
                                                             "SYS_XQNSP4PFX", 
                                                             "SYS_XQNSPFRMQNM", 
                                                             "SYS_XQPFXFRMQNM", 
                                                             "SYS_XQ_PKSQL2XML", 
                                                             "SYS_XQPOLYABS", 
                                                             "SYS_XQPOLYADD", 
                                                             "SYS_XQPOLYCEL", 
                                                             "SYS_XQPOLYCSTBL", 
                                                             "SYS_XQPOLYCST", 
                                                             "SYS_XQPOLYDIV", 
                                                             "SYS_XQPOLYFLR", 
                                                             "SYS_XQPOLYMOD", 
                                                             "SYS_XQPOLYMUL", 
                                                             "SYS_XQPOLYRND", 
                                                             "SYS_XQPOLYSQRT", 
                                                             "SYS_XQPOLYSUB", 
                                                             "SYS_XQPOLYUMUS", 
                                                             "SYS_XQPOLYUPLS", 
                                                             "SYS_XQPOLYVEQ", 
                                                             "SYS_XQPOLYVGE", 
                                                             "SYS_XQPOLYVGT", 
                                                             "SYS_XQPOLYVLE", 
                                                             "SYS_XQPOLYVLT", 
                                                             "SYS_XQPOLYVNE", 
                                                             "SYS_XQREF2VAL", 
                                                             "SYS_XQRENAME", 
                                                             "SYS_XQREPLACE", 
                                                             "SYS_XQRESVURI", 
                                                             "SYS_XQRNDHALF2EVN", 
                                                             "SYS_XQRSLVQNM", 
                                                             "SYS_XQRYENVPGET", 
                                                             "SYS_XQRYVARGET", 
                                                             "SYS_XQRYWRP", 
                                                             "SYS_XQSEQ2CON4XC", 
                                                             "SYS_XQSEQ2CON", 
                                                             "SYS_XQSEQDEEPEQ", 
                                                             "SYS_XQSEQINSB", 
                                                             "SYS_XQSEQRM", 
                                                             "SYS_XQSEQRVS", 
                                                             "SYS_XQSEQSUB", 
                                                             "SYS_XQSEQTYPMATCH", 
                                                             "SYS_XQSTARTSWITH", 
                                                             "SYS_XQSTATBURI", 
                                                             "SYS_XQSTR2CODEP", 
                                                             "SYS_XQSTRJOIN", 
                                                             "SYS_XQSUBSTRAFT", 
                                                             "SYS_XQSUBSTRBEF", 
                                                             "SYS_XQTOKENIZE", 
                                                             "SYS_XQTREATAS", 
                                                             "SYS_XQ_UPKXML2SQL", 
                                                             "SYS_XQXFORM", 
                                                             "SYS_XSID_TO_RAW", 
                                                             "SYS_ZMAP_FILTER", 
                                                             "SYS_ZMAP_REFRESH", 
                                                             "T_LETTER", 
                                                             "TABLE_LOOKUP_BY_NL", 
                                                             "TABLESPACE_NO", 
                                                             "TABLESPACE", 
                                                             "TABLES", "TABLE_STATS", 
                                                             "TABLE", "TABNO", 
                                                             "TAG", "TANH", 
                                                             "TAN", "TBLORIDXPARTNUM", 
                                                             "TEMPFILE", 
                                                             "TEMPLATE", 
                                                             "TEMPORARY", 
                                                             "TEMP_TABLE", 
                                                             "TEST", "TEXT", 
                                                             "THAN", "THEN", 
                                                             "THE", "THREAD", 
                                                             "THROUGH", 
                                                             "TIER", "TIES", 
                                                             "TIMEOUT", 
                                                             "TIMESTAMP_LTZ_UNCONSTRAINED", 
                                                             "TIMESTAMP", 
                                                             "TIMESTAMP_TZ_UNCONSTRAINED", 
                                                             "TIMESTAMP_UNCONSTRAINED", 
                                                             "TIMES", "TIME", 
                                                             "TIMEZONE", 
                                                             "TIMEZONE_ABBR", 
                                                             "TIMEZONE_HOUR", 
                                                             "TIMEZONE_MINUTE", 
                                                             "TIMEZONE_OFFSET", 
                                                             "TIMEZONE_REGION", 
                                                             "TIME_ZONE", 
                                                             "TIMING", "TIV_GB", 
                                                             "TIV_SSF", 
                                                             "TO_ACLID", 
                                                             "TO_BINARY_DOUBLE", 
                                                             "TO_BINARY_FLOAT", 
                                                             "TO_BLOB", 
                                                             "TO_CLOB", 
                                                             "TO_DSINTERVAL", 
                                                             "TO_LOB", "TO_MULTI_BYTE", 
                                                             "TO_NCHAR", 
                                                             "TO_NCLOB", 
                                                             "TO_NUMBER", 
                                                             "TOPLEVEL", 
                                                             "TO_SINGLE_BYTE", 
                                                             "TO_TIMESTAMP", 
                                                             "TO_TIMESTAMP_TZ", 
                                                             "TO_TIME", 
                                                             "TO_TIME_TZ", 
                                                             "TO", "TO_YMINTERVAL", 
                                                             "TRACE", "TRACING", 
                                                             "TRACKING", 
                                                             "TRAILING", 
                                                             "TRANSACTION", 
                                                             "TRANSFORM", 
                                                             "TRANSFORM_DISTINCT_AGG", 
                                                             "TRANSITIONAL", 
                                                             "TRANSITION", 
                                                             "TRANSLATE", 
                                                             "TRANSLATION", 
                                                             "TREAT", "TRIGGERS", 
                                                             "TRIGGER", 
                                                             "TRUE", "TRUNCATE", 
                                                             "TRUNC", "TRUSTED", 
                                                             "TRUST", "TUNING", 
                                                             "TX", "TYPES", 
                                                             "TYPE", "TZ_OFFSET", 
                                                             "UB2", "UBA", 
                                                             "UCS2", "UID", 
                                                             "UNARCHIVED", 
                                                             "UNBOUNDED", 
                                                             "UNBOUND", 
                                                             "UNCONDITIONAL", 
                                                             "UNDER", "UNDO", 
                                                             "UNDROP", "UNIFORM", 
                                                             "UNION", "UNIQUE", 
                                                             "UNISTR", "UNLIMITED", 
                                                             "UNLOAD", "UNLOCK", 
                                                             "UNMATCHED", 
                                                             "UNNEST_INNERJ_DISTINCT_VIEW", 
                                                             "UNNEST_NOSEMIJ_NODISTINCTVIEW", 
                                                             "UNNEST_SEMIJ_VIEW", 
                                                             "UNNEST", "UNPACKED", 
                                                             "UNPIVOT", 
                                                             "UNPLUG", "UNPROTECTED", 
                                                             "UNQUIESCE", 
                                                             "UNRECOVERABLE", 
                                                             "UNRESTRICTED", 
                                                             "UNSUBSCRIBE", 
                                                             "UNTIL", "UNUSABLE", 
                                                             "UNUSED", "UPDATABLE", 
                                                             "UPDATED", 
                                                             "UPDATE", "UPDATEXML", 
                                                             "UPD_INDEXES", 
                                                             "UPD_JOININDEX", 
                                                             "UPGRADE", 
                                                             "UPPER", "UPSERT", 
                                                             "UROWID", "USABLE", 
                                                             "USAGE", "USE_ANTI", 
                                                             "USE_CONCAT", 
                                                             "USE_CUBE", 
                                                             "USE_HASH_AGGREGATION", 
                                                             "USE_HASH_GBY_FOR_PUSHDOWN", 
                                                             "USE_HASH", 
                                                             "USE_HIDDEN_PARTITIONS", 
                                                             "USE_INVISIBLE_INDEXES", 
                                                             "USE_MERGE_CARTESIAN", 
                                                             "USE_MERGE", 
                                                             "USE_NL", "USE_NL_WITH_INDEX", 
                                                             "USE_PRIVATE_OUTLINES", 
                                                             "USER_DATA", 
                                                             "USER_DEFINED", 
                                                             "USERENV", 
                                                             "USERGROUP", 
                                                             "USER_RECYCLEBIN", 
                                                             "USERS", "USER_TABLESPACES", 
                                                             "USER", "USE_SEMI", 
                                                             "USE_STORED_OUTLINES", 
                                                             "USE_TTT_FOR_GSETS", 
                                                             "USE", "USE_VECTOR_AGGREGATION", 
                                                             "USE_WEAK_NAME_RESL", 
                                                             "USING_NO_EXPAND", 
                                                             "USING", "UTF16BE", 
                                                             "UTF16LE", 
                                                             "UTF32", "UTF8", 
                                                             "V1", "V2", 
                                                             "VALIDATE", 
                                                             "VALIDATE_CONVERSION", 
                                                             "VALIDATION", 
                                                             "VALID_TIME_END", 
                                                             "VALUES", "VALUE", 
                                                             "VARCHAR2", 
                                                             "VARCHAR", 
                                                             "VARIABLE", 
                                                             "VAR_POP", 
                                                             "VARRAYS", 
                                                             "VARRAY", "VAR_SAMP", 
                                                             "VARYING", 
                                                             "VECTOR_READ_TRACE", 
                                                             "VECTOR_READ", 
                                                             "VECTOR_TRANSFORM_DIMS", 
                                                             "VECTOR_TRANSFORM_FACT", 
                                                             "VECTOR_TRANSFORM", 
                                                             "VERIFIER", 
                                                             "VERIFY", "VERSIONING", 
                                                             "VERSIONS_ENDSCN", 
                                                             "VERSIONS_ENDTIME", 
                                                             "VERSIONS_OPERATION", 
                                                             "VERSIONS_STARTSCN", 
                                                             "VERSIONS_STARTTIME", 
                                                             "VERSIONS", 
                                                             "VERSIONS_XID", 
                                                             "VERSION", 
                                                             "VIEW", "VIOLATION", 
                                                             "VIRTUAL", 
                                                             "VISIBILITY", 
                                                             "VISIBLE", 
                                                             "VOLUME", "VSIZE", 
                                                             "WAIT", "WALLET", 
                                                             "WARNING", 
                                                             "WEEKS", "WEEK", 
                                                             "WELLFORMED", 
                                                             "WHENEVER", 
                                                             "WHEN", "WHERE", 
                                                             "WHILE", "WHITESPACE", 
                                                             "WIDTH_BUCKET", 
                                                             "WITHIN", "WITHOUT", 
                                                             "WITH_PLSQL", 
                                                             "WITH", "WORK", 
                                                             "WRAPPED", 
                                                             "WRAPPER", 
                                                             "WRITE", "XDB_FASTPATH_INSERT", 
                                                             "XDB", "X_DYN_PRUNE", 
                                                             "XID", "XML2OBJECT", 
                                                             "XMLAGG", "XMLATTRIBUTES", 
                                                             "XMLCAST", 
                                                             "XMLCDATA", 
                                                             "XMLCOLATTVAL", 
                                                             "XMLCOMMENT", 
                                                             "XMLCONCAT", 
                                                             "XMLDIFF", 
                                                             "XML_DML_RWT_STMT", 
                                                             "XMLELEMENT", 
                                                             "XMLEXISTS2", 
                                                             "XMLEXISTS", 
                                                             "XMLFOREST", 
                                                             "XMLINDEX", 
                                                             "XMLINDEX_REWRITE_IN_SELECT", 
                                                             "XMLINDEX_REWRITE", 
                                                             "XMLINDEX_SEL_IDX_TBL", 
                                                             "XMLISNODE", 
                                                             "XMLISVALID", 
                                                             "XMLNAMESPACES", 
                                                             "XMLPARSE", 
                                                             "XMLPATCH", 
                                                             "XMLPI", "XMLQUERYVAL", 
                                                             "XMLQUERY", 
                                                             "XMLROOT", 
                                                             "XMLSCHEMA", 
                                                             "XMLSERIALIZE", 
                                                             "XMLTABLE", 
                                                             "XMLTRANSFORMBLOB", 
                                                             "XMLTRANSFORM", 
                                                             "XMLTYPE", 
                                                             "XML", "XPATHTABLE", 
                                                             "XS_SYS_CONTEXT", 
                                                             "XS", "XTRANSPORT", 
                                                             "YEARS", "YEAR", 
                                                             "YES", "YMINTERVAL_UNCONSTRAINED", 
                                                             "ZONEMAP", 
                                                             "ZONE", "PREDICTION", 
                                                             "PREDICTION_BOUNDS", 
                                                             "PREDICTION_COST", 
                                                             "PREDICTION_DETAILS", 
                                                             "PREDICTION_PROBABILITY", 
                                                             "PREDICTION_SET", 
                                                             "BLOCKCHAIN", 
                                                             "COLLATE", 
                                                             "COLLATION", 
                                                             "DEFINITION", 
                                                             "DUPLICATED", 
                                                             "EXTENDED", 
                                                             "HASHING", 
                                                             "IDLE", "IMMUTABLE", 
                                                             "ORACLE_DATAPUMP", 
                                                             "ORACLE_HDFS", 
                                                             "ORACLE_HIVE", 
                                                             "ORACLE_LOADER", 
                                                             "SHA2_512_Q", 
                                                             "SHARDED", 
                                                             "V1_Q", "ISOLATE", 
                                                             "ROOT", "UNITE", 
                                                             "ALGORITHM", 
                                                             "CUME_DIST", 
                                                             "DENSE_RANK", 
                                                             "LISTAGG", 
                                                             "PERCENT_RANK", 
                                                             "PERCENTILE_CONT", 
                                                             "PERCENTILE_DISC", 
                                                             "RANK", "AVG", 
                                                             "CORR", "COVAR_", 
                                                             "DECODE", "LAG", 
                                                             "LAG_DIFF", 
                                                             "LAG_DIFF_PERCENT", 
                                                             "LEAD", "MAX", 
                                                             "MEDIAN", "MEMOPTIMIZE", 
                                                             "MIN", "NTILE", 
                                                             "NVL", "RATIO_TO_REPORT", 
                                                             "REGR_", "ROUND", 
                                                             "ROW_NUMBER", 
                                                             "SUBSTR", "TO_CHAR", 
                                                             "TRIM", "SUM", 
                                                             "STDDEV", "VAR_", 
                                                             "VARIANCE", 
                                                             "LEAST", "GREATEST", 
                                                             "TO_DATE", 
                                                             "NATIONAL_CHAR_STRING_LIT", 
                                                             "BIT_STRING_LIT", 
                                                             "HEX_STRING_LIT", 
                                                             "DOUBLE_PERIOD", 
                                                             "PERIOD", "UNSIGNED_INTEGER", 
                                                             "APPROXIMATE_NUM_LIT", 
                                                             "CHAR_STRING", 
                                                             "DELIMITED_ID", 
                                                             "PERCENT", 
                                                             "AMPERSAND", 
                                                             "LEFT_PAREN", 
                                                             "RIGHT_PAREN", 
                                                             "DOUBLE_ASTERISK", 
                                                             "ASTERISK", 
                                                             "PLUS_SIGN", 
                                                             "MINUS_SIGN", 
                                                             "COMMA", "SOLIDUS", 
                                                             "AT_SIGN", 
                                                             "ASSIGN_OP", 
                                                             "HASH_OP", 
                                                             "SQ", "BINDVAR", 
                                                             "NOT_EQUAL_OP", 
                                                             "CARRET_OPERATOR_PART", 
                                                             "TILDE_OPERATOR_PART", 
                                                             "EXCLAMATION_OPERATOR_PART", 
                                                             "GREATER_THAN_OP", 
                                                             "LESS_THAN_OP", 
                                                             "COLON", "SEMICOLON", 
                                                             "BAR", "EQUALS_OP", 
                                                             "LEFT_BRACKET", 
                                                             "RIGHT_BRACKET", 
                                                             "INTRODUCER", 
                                                             "SINGLE_LINE_COMMENT", 
                                                             "MULTI_LINE_COMMENT", 
                                                             "REMARK_COMMENT", 
                                                             "PROMPT_MESSAGE", 
                                                             "START_CMD", 
                                                             "REGULAR_ID", 
                                                             "SPACES" ];
	public static readonly modeNames: string[] = [ "DEFAULT_MODE", ];

	public static readonly ruleNames: string[] = [
		"ABORT", "ABS", "ABSENT", "ACCESS", "ACCESSED", "ACCOUNT", "ACL", "ACOS", 
		"ACROSS", "ACTION", "ACTIONS", "ACTIVATE", "ACTIVE", "ACTIVE_COMPONENT", 
		"ACTIVE_DATA", "ACTIVE_FUNCTION", "ACTIVE_TAG", "ACTIVITY", "ADAPTIVE_PLAN", 
		"ADD", "ADD_COLUMN", "ADD_GROUP", "ADD_MONTHS", "ADJ_DATE", "ADMIN", "ADMINISTER", 
		"ADMINISTRATOR", "ADVANCED", "ADVISE", "ADVISOR", "AFD_DISKSTRING", "AFTER", 
		"AGENT", "AGGREGATE", "A_LETTER", "ALIAS", "ALL", "ALLOCATE", "ALLOW", 
		"ALL_ROWS", "ALTER", "ALTERNATE", "ALWAYS", "ANALYTIC", "ANALYZE", "ANCESTOR", 
		"ANCILLARY", "AND", "AND_EQUAL", "ANOMALY", "ANSI_REARCH", "ANTIJOIN", 
		"ANY", "ANYSCHEMA", "APPEND", "APPENDCHILDXML", "APPEND_VALUES", "APPLICATION", 
		"APPLY", "APPROX_COUNT_DISTINCT", "ARCHIVAL", "ARCHIVE", "ARCHIVED", "ARCHIVELOG", 
		"ARRAY", "AS", "ASC", "ASCII", "ASCIISTR", "ASIN", "ASIS", "ASSEMBLY", 
		"ASSIGN", "ASSOCIATE", "ASYNC", "ASYNCHRONOUS", "ATAN2", "ATAN", "AT", 
		"ATTRIBUTE", "ATTRIBUTES", "AUDIT", "AUTHENTICATED", "AUTHENTICATION", 
		"AUTHID", "AUTHORIZATION", "AUTOALLOCATE", "AUTO", "AUTOBACKUP", "AUTOEXTEND", 
		"AUTO_LOGIN", "AUTOMATIC", "AUTONOMOUS_TRANSACTION", "AUTO_REOPTIMIZE", 
		"AVAILABILITY", "AVRO", "BACKGROUND", "BACKINGFILE", "BACKUP", "BACKUPS", 
		"BACKUPSET", "BASIC", "BASICFILE", "BATCH", "BATCHSIZE", "BATCH_TABLE_ACCESS_BY_ROWID", 
		"BECOME", "BEFORE", "BEGIN", "BEGINNING", "BEGIN_OUTLINE_DATA", "BEHALF", 
		"BEQUEATH", "BETWEEN", "BFILE", "BFILENAME", "BIGFILE", "BINARY", "BINARY_DOUBLE", 
		"BINARY_DOUBLE_INFINITY", "BINARY_DOUBLE_NAN", "BINARY_FLOAT", "BINARY_FLOAT_INFINITY", 
		"BINARY_FLOAT_NAN", "BINARY_INTEGER", "BIND_AWARE", "BINDING", "BIN_TO_NUM", 
		"BITAND", "BITMAP_AND", "BITMAP", "BITMAPS", "BITMAP_TREE", "BITS", "BLOB", 
		"BLOCK", "BLOCK_RANGE", "BLOCKS", "BLOCKSIZE", "BODY", "BOOLEAN", "BOTH", 
		"BOUND", "BRANCH", "BREADTH", "BROADCAST", "BSON", "BUFFER", "BUFFER_CACHE", 
		"BUFFER_POOL", "BUILD", "BULK", "BY", "BYPASS_RECURSIVE_CHECK", "BYPASS_UJVC", 
		"BYTE", "CACHE", "CACHE_CB", "CACHE_INSTANCES", "CACHE_TEMP_TABLE", "CACHING", 
		"CALCULATED", "CALLBACK", "CALL", "CANCEL", "CANONICAL", "CAPACITY", "CAPTION", 
		"CARDINALITY", "CASCADE", "CASE", "CAST", "CASESENSITIVE", "CATEGORY", 
		"CDBDEFAULT", "CEIL", "CELL_FLASH_CACHE", "CERTIFICATE", "CFILE", "CHAINED", 
		"CHANGE", "CHANGETRACKING", "CHANGE_DUPKEY_ERROR_INDEX", "CHARACTER", 
		"CHAR", "CHAR_CS", "CHARTOROWID", "CHECK_ACL_REWRITE", "CHECK", "CHECKPOINT", 
		"CHILD", "CHOOSE", "CHR", "CHUNK", "CLASS", "CLASSIFICATION", "CLASSIFIER", 
		"CLAUSE", "CLEAN", "CLEANUP", "CLEAR", "C_LETTER", "CLIENT", "CLOB", "CLONE", 
		"CLOSE_CACHED_OPEN_CURSORS", "CLOSE", "CLUSTER_BY_ROWID", "CLUSTER", "CLUSTER_DETAILS", 
		"CLUSTER_DISTANCE", "CLUSTER_ID", "CLUSTERING", "CLUSTERING_FACTOR", "CLUSTER_PROBABILITY", 
		"CLUSTER_SET", "COALESCE", "COALESCE_SQ", "COARSE", "CO_AUTH_IND", "COLD", 
		"COLLECT", "COLUMNAR", "COLUMN_AUTH_INDICATOR", "COLUMN", "COLUMNS", "COLUMN_STATS", 
		"COLUMN_VALUE", "COMMENT", "COMMIT", "COMMITTED", "COMMON", "COMMON_DATA", 
		"COMPACT", "COMPATIBILITY", "COMPILE", "COMPLETE", "COMPLIANCE", "COMPONENT", 
		"COMPONENTS", "COMPOSE", "COMPOSITE", "COMPOSITE_LIMIT", "COMPOUND", "COMPRESS", 
		"COMPUTE", "CONCAT", "CON_DBID_TO_ID", "CONDITIONAL", "CONDITION", "CONFIRM", 
		"CONFORMING", "CON_GUID_TO_ID", "CON_ID", "CON_NAME_TO_ID", "CONNECT_BY_CB_WHR_ONLY", 
		"CONNECT_BY_COMBINE_SW", "CONNECT_BY_COST_BASED", "CONNECT_BY_ELIM_DUPS", 
		"CONNECT_BY_FILTERING", "CONNECT_BY_ISCYCLE", "CONNECT_BY_ISLEAF", "CONNECT_BY_ROOT", 
		"CONNECT", "CONNECT_TIME", "CONSIDER", "CONSISTENT", "CONSTANT", "CONST", 
		"CONSTRAINT", "CONSTRAINTS", "CONSTRUCTOR", "CONTAINER", "CONTAINERS", 
		"CONTAINERS_DEFAULT", "CONTAINER_DATA", "CONTAINER_MAP", "CONTENT", "CONTENTS", 
		"CONTEXT", "CONTINUE", "CONTROLFILE", "CON_UID_TO_ID", "CONVERT", "CONVERSION", 
		"COOKIE", "COPY", "CORR_K", "CORR_S", "CORRUPTION", "CORRUPT_XID_ALL", 
		"CORRUPT_XID", "COS", "COSH", "COST", "COST_XML_QUERY_REWRITE", "COUNT", 
		"COVAR_POP", "COVAR_SAMP", "CPU_COSTING", "CPU_PER_CALL", "CPU_PER_SESSION", 
		"CRASH", "CREATE", "CREATE_FILE_DEST", "CREATE_STORED_OUTLINES", "CREATION", 
		"CREDENTIAL", "CRITICAL", "CROSS", "CROSSEDITION", "CSCONVERT", "CUBE_AJ", 
		"CUBE", "CUBE_GB", "CUBE_SJ", "CUME_DISTM", "CURRENT", "CURRENT_DATE", 
		"CURRENT_SCHEMA", "CURRENT_TIME", "CURRENT_TIMESTAMP", "CURRENT_USER", 
		"CURRENTV", "CURSOR", "CURSOR_SHARING_EXACT", "CURSOR_SPECIFIC_SEGMENT", 
		"CUSTOMDATUM", "CV", "CYCLE", "DANGLING", "DATABASE", "DATA", "DATAFILE", 
		"DATAFILES", "DATAGUARDCONFIG", "DATAMOVEMENT", "DATAOBJNO", "DATAOBJ_TO_MAT_PARTITION", 
		"DATAOBJ_TO_PARTITION", "DATAPUMP", "DATA_SECURITY_REWRITE_LIMIT", "DATE", 
		"DATE_MODE", "DAY", "DAYS", "DBA", "DBA_RECYCLEBIN", "DBMS_STATS", "DB_ROLE_CHANGE", 
		"DBTIMEZONE", "DB_UNIQUE_NAME", "DB_VERSION", "DDL", "DEALLOCATE", "DEBUG", 
		"DEBUGGER", "DEC", "DECIMAL", "DECLARE", "DECOMPOSE", "DECORRELATE", "DECR", 
		"DECREMENT", "DECRYPT", "DEDUPLICATE", "DEFAULT", "DEFAULTS", "DEFAULT_COLLATION", 
		"DEFAULT_CREDENTIAL", "DEFERRABLE", "DEFERRED", "DEFINED", "DEFINE", "DEFINER", 
		"DEGREE", "DELAY", "DELEGATE", "DELETE_ALL", "DELETE", "DELETEXML", "DEMAND", 
		"DENSE_RANKM", "DEPENDENT", "DEPTH", "DEQUEUE", "DEREF", "DEREF_NO_REWRITE", 
		"DESC", "DESCRIPTION", "DESTROY", "DETACHED", "DETERMINES", "DETERMINISTIC", 
		"DICTIONARY", "DIMENSION", "DIMENSIONS", "DIRECT_LOAD", "DIRECTORY", "DIRECT_PATH", 
		"DISABLE_ALL", "DISABLE", "DISABLE_PARALLEL_DML", "DISABLE_PRESET", "DISABLE_RPKE", 
		"DISALLOW", "DISASSOCIATE", "DISCARD", "DISCONNECT", "DISK", "DISKGROUP", 
		"DISKGROUP_PLUS", "DISKS", "DISMOUNT", "DISTINCT", "DISTINGUISHED", "DISTRIBUTED", 
		"DISTRIBUTE", "DML", "DML_UPDATE", "DOCFIDELITY", "DOCUMENT", "DOMAIN_INDEX_FILTER", 
		"DOMAIN_INDEX_NO_SORT", "DOMAIN_INDEX_SORT", "DOUBLE", "DOWNGRADE", "DRIVING_SITE", 
		"DROP_COLUMN", "DROP", "DROP_GROUP", "DSINTERVAL_UNCONSTRAINED", "DST_UPGRADE_INSERT_CONV", 
		"DUMP", "DUMPSET", "DUPLICATE", "DV", "DYNAMIC", "DYNAMIC_SAMPLING", "DYNAMIC_SAMPLING_EST_CDN", 
		"E_LETTER", "EACH", "EDITIONABLE", "EDITION", "EDITIONING", "EDITIONS", 
		"ELEMENT", "ELIM_GROUPBY", "ELIMINATE_JOIN", "ELIMINATE_OBY", "ELIMINATE_OUTER_JOIN", 
		"ELSE", "ELSIF", "EM", "EMPTY_BLOB", "EMPTY_CLOB", "EMPTY_", "ENABLE_ALL", 
		"ENABLE", "ENABLE_PARALLEL_DML", "ENABLE_PRESET", "ENCODING", "ENCRYPT", 
		"ENCRYPTION", "END", "END_OUTLINE_DATA", "ENFORCED", "ENFORCE", "ENQUEUE", 
		"ENTERPRISE", "ENTITYESCAPING", "ENTRY", "EQUIPART", "ERR", "ERROR_ARGUMENT", 
		"ERROR", "ERROR_ON_OVERLAP_TIME", "ERRORS", "ESCAPE", "ESTIMATE", "EVAL", 
		"EVALNAME", "EVALUATE", "EVALUATION", "EVENTS", "EVERY", "EXCEPT", "EXCEPTION", 
		"EXCEPTION_INIT", "EXCEPTIONS", "EXCHANGE", "EXCLUDE", "EXCLUDING", "EXCLUSIVE", 
		"EXECUTE", "EXEMPT", "EXISTING", "EXISTS", "EXISTSNODE", "EXIT", "EXPAND_GSET_TO_UNION", 
		"EXPAND_TABLE", "EXP", "EXPIRE", "EXPLAIN", "EXPLOSION", "EXPORT", "EXPR_CORR_CHECK", 
		"EXPRESS", "EXTENDS", "EXTENT", "EXTENTS", "EXTERNAL", "EXTERNALLY", "EXTRACTCLOBXML", 
		"EXTRACT", "EXTRACTVALUE", "EXTRA", "FACILITY", "FACT", "FACTOR", "FACTORIZE_JOIN", 
		"FAILED", "FAILED_LOGIN_ATTEMPTS", "FAILGROUP", "FAILOVER", "FAILURE", 
		"FALSE", "FAMILY", "FAR", "FAST", "FASTSTART", "FBTSCAN", "FEATURE", "FEATURE_DETAILS", 
		"FEATURE_ID", "FEATURE_SET", "FEATURE_VALUE", "FETCH", "FILE", "FILE_NAME_CONVERT", 
		"FILEGROUP", "FILESTORE", "FILESYSTEM_LIKE_LOGGING", "FILTER", "FINAL", 
		"FINE", "FINISH", "FIRST", "FIRSTM", "FIRST_ROWS", "FIRST_VALUE", "FIXED_VIEW_DATA", 
		"FLAGGER", "FLASHBACK", "FLASH_CACHE", "FLOAT", "FLOB", "FLEX", "FLOOR", 
		"FLUSH", "FOLDER", "FOLLOWING", "FOLLOWS", "FORALL", "FORCE", "FORCE_XML_QUERY_REWRITE", 
		"FOREIGN", "FOREVER", "FOR", "FORMAT", "FORWARD", "FRAGMENT_NUMBER", "FREELIST", 
		"FREELISTS", "FREEPOOLS", "FRESH", "FROM", "FROM_TZ", "FULL", "FULL_OUTER_JOIN_TO_OUTER", 
		"FUNCTION", "FUNCTIONS", "FTP", "G_LETTER", "GATHER_OPTIMIZER_STATISTICS", 
		"GATHER_PLAN_STATISTICS", "GBY_CONC_ROLLUP", "GBY_PUSHDOWN", "GENERATED", 
		"GET", "GLOBAL", "GLOBALLY", "GLOBAL_NAME", "GLOBAL_TOPIC_ENABLED", "GOTO", 
		"GRANT", "GROUP_BY", "GROUP", "GROUP_ID", "GROUPING", "GROUPING_ID", "GROUPS", 
		"GUARANTEED", "GUARANTEE", "GUARD", "HALF_YEARS", "HASH_AJ", "HASH", "HASHKEYS", 
		"HASH_SJ", "HAVING", "HEADER", "HEAP", "HELP", "HEXTORAW", "HEXTOREF", 
		"HIDDEN_KEYWORD", "HIDE", "HIER_ORDER", "HIERARCHICAL", "HIERARCHIES", 
		"HIERARCHY", "HIGH", "HINTSET_BEGIN", "HINTSET_END", "HOT", "HOUR", "HOURS", 
		"HTTP", "HWM_BROKERED", "HYBRID", "H_LETTER", "IDENTIFIED", "IDENTIFIER", 
		"IDENTITY", "IDGENERATORS", "ID", "IDLE_TIME", "IF", "IGNORE", "IGNORE_OPTIM_EMBEDDED_HINTS", 
		"IGNORE_ROW_ON_DUPKEY_INDEX", "IGNORE_WHERE_CLAUSE", "ILM", "IMMEDIATE", 
		"IMPACT", "IMPORT", "INACTIVE", "INACTIVE_ACCOUNT_TIME", "INCLUDE", "INCLUDE_VERSION", 
		"INCLUDING", "INCREMENTAL", "INCREMENT", "INCR", "INDENT", "INDEX_ASC", 
		"INDEX_COMBINE", "INDEX_DESC", "INDEXED", "INDEXES", "INDEX_FFS", "INDEX_FILTER", 
		"INDEX", "INDEXING", "INDEX_JOIN", "INDEX_ROWS", "INDEX_RRS", "INDEX_RS_ASC", 
		"INDEX_RS_DESC", "INDEX_RS", "INDEX_SCAN", "INDEX_SKIP_SCAN", "INDEX_SS_ASC", 
		"INDEX_SS_DESC", "INDEX_SS", "INDEX_STATS", "INDEXTYPE", "INDEXTYPES", 
		"INDICATOR", "INDICES", "INFINITE", "INFORMATIONAL", "INHERIT", "IN", 
		"INITCAP", "INITIAL", "INITIALIZED", "INITIALLY", "INITRANS", "INLINE", 
		"INLINE_XMLTYPE_NT", "INMEMORY", "IN_MEMORY_METADATA", "INMEMORY_PRUNING", 
		"INNER", "INOUT", "INPLACE", "INSERTCHILDXMLAFTER", "INSERTCHILDXMLBEFORE", 
		"INSERTCHILDXML", "INSERT", "INSERTXMLAFTER", "INSERTXMLBEFORE", "INSTANCE", 
		"INSTANCES", "INSTANTIABLE", "INSTANTLY", "INSTEAD", "INSTR2", "INSTR4", 
		"INSTRB", "INSTRC", "INSTR", "INTEGER", "INTERLEAVED", "INTERMEDIATE", 
		"INTERNAL_CONVERT", "INTERNAL_USE", "INTERPRETED", "INTERSECT", "INTERVAL", 
		"INT", "INTO", "INVALIDATE", "INVISIBLE", "IN_XQUERY", "IS", "IS_LEAF", 
		"ISOLATION", "ISOLATION_LEVEL", "ITERATE", "ITERATION_NUMBER", "JAVA", 
		"JOB", "JOIN", "JSON_ARRAYAGG", "JSON_ARRAY", "JSON_EQUAL", "JSON_EXISTS2", 
		"JSON_EXISTS", "JSONGET", "JSON", "JSON_OBJECTAGG", "JSON_OBJECT", "JSONPARSE", 
		"JSON_QUERY", "JSON_SERIALIZE", "JSON_TABLE", "JSON_TEXTCONTAINS2", "JSON_TEXTCONTAINS", 
		"JSON_TRANSFORM", "JSON_VALUE", "K_LETTER", "KEEP_DUPLICATES", "KEEP", 
		"KERBEROS", "KEY", "KEY_LENGTH", "KEYSIZE", "KEYS", "KEYSTORE", "KILL", 
		"LABEL", "LANGUAGE", "LAST_DAY", "LAST", "LAST_VALUE", "LATERAL", "LAX", 
		"LAYER", "LDAP_REGISTRATION_ENABLED", "LDAP_REGISTRATION", "LDAP_REG_SYNC_INTERVAL", 
		"LEAF", "LEAD_CDB", "LEAD_CDB_URI", "LEADING", "LEFT", "LENGTH2", "LENGTH4", 
		"LENGTHB", "LENGTHC", "LENGTH", "LESS", "LEVEL", "LEVEL_NAME", "LEVELS", 
		"LIBRARY", "LIFECYCLE", "LIFE", "LIFETIME", "LIKE2", "LIKE4", "LIKEC", 
		"LIKE_EXPAND", "LIKE", "LIMIT", "LINEAR", "LINK", "LIST", "LN", "LNNVL", 
		"LOAD", "LOB", "LOBNVL", "LOBS", "LOCAL_INDEXES", "LOCAL", "LOCALTIME", 
		"LOCALTIMESTAMP", "LOCATION", "LOCATOR", "LOCKDOWN", "LOCKED", "LOCKING", 
		"LOCK", "LOGFILE", "LOGFILES", "LOGGING", "LOGICAL", "LOGICAL_READS_PER_CALL", 
		"LOGICAL_READS_PER_SESSION", "LOG", "LOGMINING", "LOGOFF", "LOGON", "LOG_READ_ONLY_VIOLATIONS", 
		"LONG", "LOOP", "LOST", "LOWER", "LOW", "LPAD", "LTRIM", "M_LETTER", "MAIN", 
		"MAKE_REF", "MANAGED", "MANAGE", "MANAGEMENT", "MANAGER", "MANDATORY", 
		"MANUAL", "MAP", "MAPPING", "MASTER", "MATCHED", "MATCHES", "MATCH", "MATCH_NUMBER", 
		"MATCH_RECOGNIZE", "MATERIALIZED", "MATERIALIZE", "MAXARCHLOGS", "MAXDATAFILES", 
		"MAXEXTENTS", "MAXIMIZE", "MAXINSTANCES", "MAXLOGFILES", "MAXLOGHISTORY", 
		"MAXLOGMEMBERS", "MAX_SHARED_TEMP_SIZE", "MAXSIZE", "MAXTRANS", "MAXVALUE", 
		"MEASURE", "MEASURES", "MEDIUM", "MEMBER", "MEMBER_CAPTION", "MEMBER_DESCRIPTION", 
		"MEMBER_NAME", "MEMBER_UNIQUE_NAME", "MEMCOMPRESS", "MEMORY", "MERGEACTIONS", 
		"MERGE_AJ", "MERGE_CONST_ON", "MERGE", "MERGE_SJ", "METADATA", "METHOD", 
		"MIGRATE", "MIGRATION", "MINEXTENTS", "MINIMIZE", "MINIMUM", "MINING", 
		"MINUS", "MINUS_NULL", "MINUTE", "MINUTES", "MINVALUE", "MIRRORCOLD", 
		"MIRRORHOT", "MIRROR", "MISSING", "MISMATCH", "MLSLABEL", "MODEL_COMPILE_SUBQUERY", 
		"MODEL_DONTVERIFY_UNIQUENESS", "MODEL_DYNAMIC_SUBQUERY", "MODEL_MIN_ANALYSIS", 
		"MODEL", "MODEL_NB", "MODEL_NO_ANALYSIS", "MODEL_PBY", "MODEL_PUSH_REF", 
		"MODEL_SV", "MODE", "MODIFICATION", "MODIFY_COLUMN_TYPE", "MODIFY", "MOD", 
		"MODULE", "MONITORING", "MONITOR", "MONTH", "MONTHS_BETWEEN", "MONTHS", 
		"MOUNT", "MOUNTPATH", "MOUNTPOINT", "MOVEMENT", "MOVE", "MULTIDIMENSIONAL", 
		"MULTISET", "MV_MERGE", "NAMED", "NAME", "NAMESPACE", "NAN", "NANVL", 
		"NATIONAL", "NATIVE_FULL_OUTER_JOIN", "NATIVE", "NATURAL", "NATURALN", 
		"NAV", "NCHAR_CS", "NCHAR", "NCHR", "NCLOB", "NEEDED", "NEG", "NESTED", 
		"NESTED_TABLE_FAST_INSERT", "NESTED_TABLE_GET_REFS", "NESTED_TABLE_ID", 
		"NESTED_TABLE_SET_REFS", "NESTED_TABLE_SET_SETID", "NETWORK", "NEVER", 
		"NEW", "NEW_TIME", "NEXT_DAY", "NEXT", "NL_AJ", "NLJ_BATCHING", "NLJ_INDEX_FILTER", 
		"NLJ_INDEX_SCAN", "NLJ_PREFETCH", "NLS_CALENDAR", "NLS_CHARACTERSET", 
		"NLS_CHARSET_DECL_LEN", "NLS_CHARSET_ID", "NLS_CHARSET_NAME", "NLS_COMP", 
		"NLS_CURRENCY", "NLS_DATE_FORMAT", "NLS_DATE_LANGUAGE", "NLS_INITCAP", 
		"NLS_ISO_CURRENCY", "NL_SJ", "NLS_LANG", "NLS_LANGUAGE", "NLS_LENGTH_SEMANTICS", 
		"NLS_LOWER", "NLS_NCHAR_CONV_EXCP", "NLS_NUMERIC_CHARACTERS", "NLS_SORT", 
		"NLSSORT", "NLS_SPECIAL_CHARS", "NLS_TERRITORY", "NLS_UPPER", "NO_ACCESS", 
		"NO_ADAPTIVE_PLAN", "NO_ANSI_REARCH", "NOAPPEND", "NOARCHIVELOG", "NOAUDIT", 
		"NO_AUTO_REOPTIMIZE", "NO_BASETABLE_MULTIMV_REWRITE", "NO_BATCH_TABLE_ACCESS_BY_ROWID", 
		"NO_BIND_AWARE", "NO_BUFFER", "NOCACHE", "NO_CARTESIAN", "NO_CHECK_ACL_REWRITE", 
		"NO_CLUSTER_BY_ROWID", "NO_CLUSTERING", "NO_COALESCE_SQ", "NO_COMMON_DATA", 
		"NOCOMPRESS", "NO_CONNECT_BY_CB_WHR_ONLY", "NO_CONNECT_BY_COMBINE_SW", 
		"NO_CONNECT_BY_COST_BASED", "NO_CONNECT_BY_ELIM_DUPS", "NO_CONNECT_BY_FILTERING", 
		"NOCOPY", "NO_COST_XML_QUERY_REWRITE", "NO_CPU_COSTING", "NOCPU_COSTING", 
		"NOCYCLE", "NO_DATA_SECURITY_REWRITE", "NO_DECORRELATE", "NODELAY", "NO_DOMAIN_INDEX_FILTER", 
		"NO_DST_UPGRADE_INSERT_CONV", "NO_ELIM_GROUPBY", "NO_ELIMINATE_JOIN", 
		"NO_ELIMINATE_OBY", "NO_ELIMINATE_OUTER_JOIN", "NOENTITYESCAPING", "NO_EXPAND_GSET_TO_UNION", 
		"NO_EXPAND", "NO_EXPAND_TABLE", "NO_FACT", "NO_FACTORIZE_JOIN", "NO_FILTERING", 
		"NOFORCE", "NO_FULL_OUTER_JOIN_TO_OUTER", "NO_GATHER_OPTIMIZER_STATISTICS", 
		"NO_GBY_PUSHDOWN", "NOGUARANTEE", "NO_INDEX_FFS", "NO_INDEX", "NO_INDEX_SS", 
		"NO_INMEMORY", "NO_INMEMORY_PRUNING", "NOKEEP", "NO_LOAD", "NOLOCAL", 
		"NOLOGGING", "NOMAPPING", "NOMAXVALUE", "NO_MERGE", "NOMINIMIZE", "NOMINVALUE", 
		"NO_MODEL_PUSH_REF", "NO_MONITORING", "NOMONITORING", "NO_MONITOR", "NO_MULTIMV_REWRITE", 
		"NO_NATIVE_FULL_OUTER_JOIN", "NONBLOCKING", "NONEDITIONABLE", "NONE", 
		"NO_NLJ_BATCHING", "NO_NLJ_PREFETCH", "NO", "NONSCHEMA", "NO_OBJECT_LINK", 
		"NOORDER", "NO_ORDER_ROLLUPS", "NO_OUTER_JOIN_TO_ANTI", "NO_OUTER_JOIN_TO_INNER", 
		"NOOVERRIDE", "NO_PARALLEL_INDEX", "NOPARALLEL_INDEX", "NO_PARALLEL", 
		"NOPARALLEL", "NO_PARTIAL_COMMIT", "NO_PARTIAL_JOIN", "NO_PARTIAL_ROLLUP_PUSHDOWN", 
		"NOPARTITION", "NO_PLACE_DISTINCT", "NO_PLACE_GROUP_BY", "NO_PQ_CONCURRENT_UNION", 
		"NO_PQ_MAP", "NOPROMPT", "NO_PQ_REPLICATE", "NO_PQ_SKEW", "NO_PRUNE_GSETS", 
		"NO_PULL_PRED", "NO_PUSH_PRED", "NO_PUSH_SUBQ", "NO_PX_FAULT_TOLERANCE", 
		"NO_PX_JOIN_FILTER", "NO_QKN_BUFF", "NO_QUERY_TRANSFORMATION", "NO_REF_CASCADE", 
		"NORELOCATE", "NORELY", "NOREPAIR", "NOREPLAY", "NORESETLOGS", "NO_RESULT_CACHE", 
		"NOREVERSE", "NO_REWRITE", "NOREWRITE", "NORMAL", "NO_ROOT_SW_FOR_LOCAL", 
		"NOROWDEPENDENCIES", "NOSCHEMACHECK", "NOSEGMENT", "NO_SEMIJOIN", "NO_SEMI_TO_INNER", 
		"NO_SET_TO_JOIN", "NOSORT", "NO_SQL_TRANSLATION", "NO_SQL_TUNE", "NO_STAR_TRANSFORMATION", 
		"NO_STATEMENT_QUEUING", "NO_STATS_GSETS", "NOSTRICT", "NO_SUBQUERY_PRUNING", 
		"NO_SUBSTRB_PAD", "NO_SWAP_JOIN_INPUTS", "NOSWITCH", "NO_TABLE_LOOKUP_BY_NL", 
		"NO_TEMP_TABLE", "NOTHING", "NOTIFICATION", "NOT", "NO_TRANSFORM_DISTINCT_AGG", 
		"NO_UNNEST", "NO_USE_CUBE", "NO_USE_HASH_AGGREGATION", "NO_USE_HASH_GBY_FOR_PUSHDOWN", 
		"NO_USE_HASH", "NO_USE_INVISIBLE_INDEXES", "NO_USE_MERGE", "NO_USE_NL", 
		"NO_USE_VECTOR_AGGREGATION", "NOVALIDATE", "NO_VECTOR_TRANSFORM_DIMS", 
		"NO_VECTOR_TRANSFORM_FACT", "NO_VECTOR_TRANSFORM", "NOWAIT", "NO_XDB_FASTPATH_INSERT", 
		"NO_XML_DML_REWRITE", "NO_XMLINDEX_REWRITE_IN_SELECT", "NO_XMLINDEX_REWRITE", 
		"NO_XML_QUERY_REWRITE", "NO_ZONEMAP", "NTH_VALUE", "NULLIF", "NULL_", 
		"NULLS", "NUMBER", "NUMERIC", "NUM_INDEX_KEYS", "NUMTODSINTERVAL", "NUMTOYMINTERVAL", 
		"NVARCHAR2", "NVL2", "OBJECT2XML", "OBJECT", "OBJ_ID", "OBJNO", "OBJNO_REUSE", 
		"OCCURENCES", "OFFLINE", "OFF", "OFFSET", "OF", "OIDINDEX", "OID", "OLAP", 
		"OLD", "OLD_PUSH_PRED", "OLS", "OLTP", "OMIT", "ONE", "ONLINE", "ONLINELOG", 
		"ONLY", "ON", "OPAQUE", "OPAQUE_TRANSFORM", "OPAQUE_XCANONICAL", "OPCODE", 
		"OPEN", "OPERATIONS", "OPERATOR", "OPT_ESTIMATE", "OPTIMAL", "OPTIMIZE", 
		"OPTIMIZER_FEATURES_ENABLE", "OPTIMIZER_GOAL", "OPTION", "OPT_PARAM", 
		"ORA_BRANCH", "ORA_CHECK_ACL", "ORA_CHECK_PRIVILEGE", "ORA_CLUSTERING", 
		"ORADATA", "ORADEBUG", "ORA_DST_AFFECTED", "ORA_DST_CONVERT", "ORA_DST_ERROR", 
		"ORA_GET_ACLIDS", "ORA_GET_PRIVILEGES", "ORA_HASH", "ORA_INVOKING_USERID", 
		"ORA_INVOKING_USER", "ORA_INVOKING_XS_USER_GUID", "ORA_INVOKING_XS_USER", 
		"ORA_RAWCOMPARE", "ORA_RAWCONCAT", "ORA_ROWSCN", "ORA_ROWSCN_RAW", "ORA_ROWVERSION", 
		"ORA_TABVERSION", "ORA_WRITE_TIME", "ORDERED", "ORDERED_PREDICATES", "ORDER", 
		"ORDINALITY", "OR_EXPAND", "ORGANIZATION", "OR", "OR_PREDICATES", "OSERROR", 
		"OTHER", "OUTER_JOIN_TO_ANTI", "OUTER_JOIN_TO_INNER", "OUTER", "OUTLINE_LEAF", 
		"OUTLINE", "OUT_OF_LINE", "OUT", "OVERFLOW_NOMOVE", "OVERFLOW", "OVERLAPS", 
		"OVER", "OVERRIDING", "OWNER", "OWNERSHIP", "OWN", "P_LETTER", "PACKAGE", 
		"PACKAGES", "PARALLEL_ENABLE", "PARALLEL_INDEX", "PARALLEL", "PARAMETERFILE", 
		"PARAMETERS", "PARAM", "PARENT", "PARENT_LEVEL_NAME", "PARENT_UNIQUE_NAME", 
		"PARITY", "PARTIAL_JOIN", "PARTIALLY", "PARTIAL", "PARTIAL_ROLLUP_PUSHDOWN", 
		"PARTITION_HASH", "PARTITION_LIST", "PARTITION", "PARTITION_RANGE", "PARTITIONS", 
		"PARTNUMINST", "PASSING", "PASSWORD_GRACE_TIME", "PASSWORD_LIFE_TIME", 
		"PASSWORD_LOCK_TIME", "PASSWORD", "PASSWORD_REUSE_MAX", "PASSWORD_REUSE_TIME", 
		"PASSWORD_ROLLOVER_TIME", "PASSWORD_VERIFY_FUNCTION", "PAST", "PATCH", 
		"PATH", "PATH_PREFIX", "PATHS", "PATTERN", "PBL_HS_BEGIN", "PBL_HS_END", 
		"PCTFREE", "PCTINCREASE", "PCTTHRESHOLD", "PCTUSED", "PCTVERSION", "PENDING", 
		"PERCENT_FOUND", "PERCENT_ISOPEN", "PERCENT_NOTFOUND", "PERCENT_KEYWORD", 
		"PERCENT_RANKM", "PERCENT_ROWCOUNT", "PERCENT_ROWTYPE", "PERCENT_TYPE", 
		"PERFORMANCE", "PERIOD_KEYWORD", "PERMANENT", "PERMISSION", "PERMUTE", 
		"PER", "PFILE", "PHYSICAL", "PIKEY", "PIPELINED", "PIPE", "PIV_GB", "PIVOT", 
		"PIV_SSF", "PLACE_DISTINCT", "PLACE_GROUP_BY", "PLAN", "PLSCOPE_SETTINGS", 
		"PLS_INTEGER", "PLSQL_CCFLAGS", "PLSQL_CODE_TYPE", "PLSQL_DEBUG", "PLSQL_OPTIMIZE_LEVEL", 
		"PLSQL_WARNINGS", "PLUGGABLE", "PMEM", "POINT", "POLICY", "POOL_16K", 
		"POOL_2K", "POOL_32K", "POOL_4K", "POOL_8K", "POSITIVEN", "POSITIVE", 
		"POST_TRANSACTION", "POWERMULTISET_BY_CARDINALITY", "POWERMULTISET", "POWER", 
		"PQ_CONCURRENT_UNION", "PQ_DISTRIBUTE", "PQ_DISTRIBUTE_WINDOW", "PQ_FILTER", 
		"PQ_MAP", "PQ_NOMAP", "PQ_REPLICATE", "PQ_SKEW", "PRAGMA", "PREBUILT", 
		"PRECEDES", "PRECEDING", "PRECISION", "PRECOMPUTE_SUBQUERY", "PREDICATE_REORDERS", 
		"PRELOAD", "PREPARE", "PRESENTNNV", "PRESENT", "PRESENTV", "PRESERVE_OID", 
		"PRESERVE", "PRETTY", "PREVIOUS", "PREV", "PRIMARY", "PRINTBLOBTOCLOB", 
		"PRIORITY", "PRIOR", "PRIVATE", "PRIVATE_SGA", "PRIVILEGED", "PRIVILEGE", 
		"PRIVILEGES", "PROCEDURAL", "PROCEDURE", "PROCESS", "PROFILE", "PROGRAM", 
		"PROJECT", "PROPAGATE", "PROPERTY", "PROTECTED", "PROTECTION", "PROTOCOL", 
		"PROXY", "PRUNING", "PUBLIC", "PULL_PRED", "PURGE", "PUSH_PRED", "PUSH_SUBQ", 
		"PX_FAULT_TOLERANCE", "PX_GRANULE", "PX_JOIN_FILTER", "QB_NAME", "QUARTERS", 
		"QUERY_BLOCK", "QUERY", "QUEUE_CURR", "QUEUE", "QUEUE_ROWP", "QUIESCE", 
		"QUORUM", "QUOTA", "QUOTAGROUP", "RAISE", "RANDOM_LOCAL", "RANDOM", "RANGE", 
		"RANKM", "RAPIDLY", "RAW", "RAWTOHEX", "RAWTONHEX", "RBA", "RBO_OUTLINE", 
		"RDBA", "READ", "READS", "REALM", "REAL", "REBALANCE", "REBUILD", "RECORD", 
		"RECORDS_PER_BLOCK", "RECOVERABLE", "RECOVER", "RECOVERY", "RECYCLEBIN", 
		"RECYCLE", "REDACTION", "REDEFINE", "REDO", "REDUCED", "REDUNDANCY", "REF_CASCADE_CURSOR", 
		"REFERENCED", "REFERENCE", "REFERENCES", "REFERENCING", "REF", "REFRESH", 
		"REFTOHEX", "REGEXP_COUNT", "REGEXP_INSTR", "REGEXP_LIKE", "REGEXP_REPLACE", 
		"REGEXP_SUBSTR", "REGISTER", "REGR_AVGX", "REGR_AVGY", "REGR_COUNT", "REGR_INTERCEPT", 
		"REGR_R2", "REGR_SLOPE", "REGR_SXX", "REGR_SXY", "REGR_SYY", "REGULAR", 
		"REJECT", "REKEY", "RELATIONAL", "RELIES_ON", "RELOCATE", "RELY", "REMAINDER", 
		"REMOTE", "REMOTE_MAPPED", "REMOVE", "RENAME", "REPAIR", "REPEAT", "REPLACE", 
		"REPLICATION", "REQUIRED", "RESETLOGS", "RESET", "RESIZE", "RESOLVE", 
		"RESOLVER", "RESOURCE", "RESPECT", "RESTART", "RESTORE_AS_INTERVALS", 
		"RESTORE", "RESTRICT_ALL_REF_CONS", "RESTRICTED", "RESTRICT_REFERENCES", 
		"RESTRICT", "RESULT_CACHE", "RESULT", "RESUMABLE", "RESUME", "RETENTION", 
		"RETRY_ON_ROW_CHANGE", "RETURNING", "RETURN", "REUSE", "REVERSE", "REVOKE", 
		"REWRITE_OR_ERROR", "REWRITE", "RIGHT", "ROLE", "ROLESET", "ROLES", "ROLLBACK", 
		"ROLLING", "ROLLUP", "ROWDEPENDENCIES", "ROWID_MAPPING_TABLE", "ROWID", 
		"ROWIDTOCHAR", "ROWIDTONCHAR", "ROW_LENGTH", "ROWNUM", "ROW", "ROWS", 
		"RPAD", "RTRIM", "RULE", "RULES", "RUNNING", "SALT", "SAMPLE", "SAVE_AS_INTERVALS", 
		"SAVEPOINT", "SAVE", "SB4", "SCALE_ROWS", "SCALE", "SCAN_INSTANCES", "SCAN", 
		"SCHEDULER", "SCHEMACHECK", "SCHEMA", "SCN_ASCENDING", "SCN", "SCOPE", 
		"SCRUB", "SD_ALL", "SD_INHIBIT", "SDO_GEOM_MBR", "SDO_GEOMETRY", "SD_SHOW", 
		"SEARCH", "SECOND", "SECONDS", "SECRET", "SECUREFILE_DBA", "SECUREFILE", 
		"SECURITY", "SEED", "SEG_BLOCK", "SEG_FILE", "SEGMENT", "SELECTIVITY", 
		"SELECT", "SELF", "SEMIJOIN_DRIVER", "SEMIJOIN", "SEMI_TO_INNER", "SEQUENCED", 
		"SEQUENCE", "SEQUENTIAL", "SEQ", "SERIALIZABLE", "SERIALLY_REUSABLE", 
		"SERIAL", "SERVERERROR", "SERVICE_NAME_CONVERT", "SERVICE", "SERVICES", 
		"SESSION_CACHED_CURSORS", "SESSION", "SESSIONS_PER_USER", "SESSIONTIMEZONE", 
		"SESSIONTZNAME", "SET", "SETS", "SETTINGS", "SET_TO_JOIN", "SEVERE", "SHARDSPACE", 
		"SHARED_POOL", "SHARED", "SHARE", "SHARING", "SHELFLIFE", "SHOW", "SHRINK", 
		"SHUTDOWN", "SIBLINGS", "SID", "SITE", "SIGNAL_COMPONENT", "SIGNAL_FUNCTION", 
		"SIGN", "SIGNTYPE", "SIMPLE_INTEGER", "SIMPLE", "SINGLE", "SINGLETASK", 
		"SINH", "SIN", "SIZE", "SKIP_EXT_OPTIMIZER", "SKIP_", "SKIP_UNQ_UNUSABLE_IDX", 
		"SKIP_UNUSABLE_INDEXES", "SMALLFILE", "SMALLINT", "SNAPSHOT", "SOME", 
		"SORT", "SOUNDEX", "SOURCE_FILE_DIRECTORY", "SOURCE_FILE_NAME_CONVERT", 
		"SOURCE", "SPACE_KEYWORD", "SPECIFICATION", "SPFILE", "SPLIT", "SPREADSHEET", 
		"SQLDATA", "SQLERROR", "SQLLDR", "SQL", "FILE_EXT", "SQL_MACRO", "SQL_TRACE", 
		"SQL_TRANSLATION_PROFILE", "SQRT", "STALE", "STANDALONE", "STANDARD", 
		"STANDARD_HASH", "STANDBY_MAX_DATA_DELAY", "STANDBYS", "STANDBY", "STAR", 
		"STAR_TRANSFORMATION", "START", "STARTUP", "STATEMENT_ID", "STATEMENT_QUEUING", 
		"STATEMENTS", "STATEMENT", "STATE", "STATIC", "STATISTICS", "STATS_BINOMIAL_TEST", 
		"STATS_CROSSTAB", "STATS_F_TEST", "STATS_KS_TEST", "STATS_MODE", "STATS_MW_TEST", 
		"STATS_ONE_WAY_ANOVA", "STATS_T_TEST_INDEP", "STATS_T_TEST_INDEPU", "STATS_T_TEST_ONE", 
		"STATS_T_TEST_PAIRED", "STATS_WSR_TEST", "STDDEV_POP", "STDDEV_SAMP", 
		"STOP", "STORAGE", "STORE", "STREAMS", "STREAM", "STRICT", "STRING", "STRIPE_COLUMNS", 
		"STRIPE_WIDTH", "STRIP", "STRUCTURE", "SUBMULTISET", "SUBPARTITION_REL", 
		"SUBPARTITIONS", "SUBPARTITION", "SUBQUERIES", "SUBQUERY_PRUNING", "SUBSCRIBE", 
		"SUBSET", "SUBSTITUTABLE", "SUBSTR2", "SUBSTR4", "SUBSTRB", "SUBSTRC", 
		"SUBTYPE", "SUCCESSFUL", "SUCCESS", "SUMMARY", "SUPPLEMENTAL", "SUSPEND", 
		"SWAP_JOIN_INPUTS", "SWITCHOVER", "SWITCH", "SYNCHRONOUS", "SYNC", "SYNONYM", 
		"SYS", "SYSASM", "SYS_AUDIT", "SYSAUX", "SYSBACKUP", "SYS_CHECKACL", "SYS_CHECK_PRIVILEGE", 
		"SYS_CONNECT_BY_PATH", "SYS_CONTEXT", "SYSDATE", "SYSDBA", "SYS_DBURIGEN", 
		"SYSDG", "SYS_DL_CURSOR", "SYS_DM_RXFORM_CHR", "SYS_DM_RXFORM_NUM", "SYS_DOM_COMPARE", 
		"SYS_DST_PRIM2SEC", "SYS_DST_SEC2PRIM", "SYS_ET_BFILE_TO_RAW", "SYS_ET_BLOB_TO_IMAGE", 
		"SYS_ET_IMAGE_TO_BLOB", "SYS_ET_RAW_TO_BFILE", "SYS_EXTPDTXT", "SYS_EXTRACT_UTC", 
		"SYS_FBT_INSDEL", "SYS_FILTER_ACLS", "SYS_FNMATCHES", "SYS_FNREPLACE", 
		"SYS_GET_ACLIDS", "SYS_GET_COL_ACLIDS", "SYS_GET_PRIVILEGES", "SYS_GETTOKENID", 
		"SYS_GETXTIVAL", "SYS_GUID", "SYSGUID", "SYSKM", "SYS_MAKE_XMLNODEID", 
		"SYS_MAKEXML", "SYS_MKXMLATTR", "SYS_MKXTI", "SYSOBJ", "SYS_OP_ADT2BIN", 
		"SYS_OP_ADTCONS", "SYS_OP_ALSCRVAL", "SYS_OP_ATG", "SYS_OP_BIN2ADT", "SYS_OP_BITVEC", 
		"SYS_OP_BL2R", "SYS_OP_BLOOM_FILTER_LIST", "SYS_OP_BLOOM_FILTER", "SYS_OP_C2C", 
		"SYS_OP_CAST", "SYS_OP_CEG", "SYS_OP_CL2C", "SYS_OP_COMBINED_HASH", "SYS_OP_COMP", 
		"SYS_OP_CONVERT", "SYS_OP_COUNTCHG", "SYS_OP_CSCONV", "SYS_OP_CSCONVTEST", 
		"SYS_OP_CSR", "SYS_OP_CSX_PATCH", "SYS_OP_CYCLED_SEQ", "SYS_OP_DECOMP", 
		"SYS_OP_DESCEND", "SYS_OP_DISTINCT", "SYS_OP_DRA", "SYS_OP_DUMP", "SYS_OP_DV_CHECK", 
		"SYS_OP_ENFORCE_NOT_NULL", "SYSOPER", "SYS_OP_EXTRACT", "SYS_OP_GROUPING", 
		"SYS_OP_GUID", "SYS_OP_HASH", "SYS_OP_IIX", "SYS_OP_ITR", "SYS_OP_KEY_VECTOR_CREATE", 
		"SYS_OP_KEY_VECTOR_FILTER_LIST", "SYS_OP_KEY_VECTOR_FILTER", "SYS_OP_KEY_VECTOR_SUCCEEDED", 
		"SYS_OP_KEY_VECTOR_USE", "SYS_OP_LBID", "SYS_OP_LOBLOC2BLOB", "SYS_OP_LOBLOC2CLOB", 
		"SYS_OP_LOBLOC2ID", "SYS_OP_LOBLOC2NCLOB", "SYS_OP_LOBLOC2TYP", "SYS_OP_LSVI", 
		"SYS_OP_LVL", "SYS_OP_MAKEOID", "SYS_OP_MAP_NONNULL", "SYS_OP_MSR", "SYS_OP_NICOMBINE", 
		"SYS_OP_NIEXTRACT", "SYS_OP_NII", "SYS_OP_NIX", "SYS_OP_NOEXPAND", "SYS_OP_NTCIMG", 
		"SYS_OP_NUMTORAW", "SYS_OP_OIDVALUE", "SYS_OP_OPNSIZE", "SYS_OP_PAR_1", 
		"SYS_OP_PARGID_1", "SYS_OP_PARGID", "SYS_OP_PAR", "SYS_OP_PART_ID", "SYS_OP_PIVOT", 
		"SYS_OP_R2O", "SYS_OP_RAWTONUM", "SYS_OP_RDTM", "SYS_OP_REF", "SYS_OP_RMTD", 
		"SYS_OP_ROWIDTOOBJ", "SYS_OP_RPB", "SYS_OPTLOBPRBSC", "SYS_OP_TOSETID", 
		"SYS_OP_TPR", "SYS_OP_TRTB", "SYS_OPTXICMP", "SYS_OPTXQCASTASNQ", "SYS_OP_UNDESCEND", 
		"SYS_OP_VECAND", "SYS_OP_VECBIT", "SYS_OP_VECOR", "SYS_OP_VECXOR", "SYS_OP_VERSION", 
		"SYS_OP_VREF", "SYS_OP_VVD", "SYS_OP_XMLCONS_FOR_CSX", "SYS_OP_XPTHATG", 
		"SYS_OP_XPTHIDX", "SYS_OP_XPTHOP", "SYS_OP_XTXT2SQLT", "SYS_OP_ZONE_ID", 
		"SYS_ORDERKEY_DEPTH", "SYS_ORDERKEY_MAXCHILD", "SYS_ORDERKEY_PARENT", 
		"SYS_PARALLEL_TXN", "SYS_PATHID_IS_ATTR", "SYS_PATHID_IS_NMSPC", "SYS_PATHID_LASTNAME", 
		"SYS_PATHID_LASTNMSPC", "SYS_PATH_REVERSE", "SYS_PXQEXTRACT", "SYS_RAW_TO_XSID", 
		"SYS_RID_ORDER", "SYS_ROW_DELTA", "SYS_SC_2_XMLT", "SYS_SYNRCIREDO", "SYSTEM_DEFINED", 
		"SYSTEM", "SYSTIMESTAMP", "SYS_TYPEID", "SYS_UMAKEXML", "SYS_XMLANALYZE", 
		"SYS_XMLCONTAINS", "SYS_XMLCONV", "SYS_XMLEXNSURI", "SYS_XMLGEN", "SYS_XMLI_LOC_ISNODE", 
		"SYS_XMLI_LOC_ISTEXT", "SYS_XMLINSTR", "SYS_XMLLOCATOR_GETSVAL", "SYS_XMLNODEID_GETCID", 
		"SYS_XMLNODEID_GETLOCATOR", "SYS_XMLNODEID_GETOKEY", "SYS_XMLNODEID_GETPATHID", 
		"SYS_XMLNODEID_GETPTRID", "SYS_XMLNODEID_GETRID", "SYS_XMLNODEID_GETSVAL", 
		"SYS_XMLNODEID_GETTID", "SYS_XMLNODEID", "SYS_XMLT_2_SC", "SYS_XMLTRANSLATE", 
		"SYS_XMLTYPE2SQL", "SYS_XQ_ASQLCNV", "SYS_XQ_ATOMCNVCHK", "SYS_XQBASEURI", 
		"SYS_XQCASTABLEERRH", "SYS_XQCODEP2STR", "SYS_XQCODEPEQ", "SYS_XQCON2SEQ", 
		"SYS_XQCONCAT", "SYS_XQDELETE", "SYS_XQDFLTCOLATION", "SYS_XQDOC", "SYS_XQDOCURI", 
		"SYS_XQDURDIV", "SYS_XQED4URI", "SYS_XQENDSWITH", "SYS_XQERRH", "SYS_XQERR", 
		"SYS_XQESHTMLURI", "SYS_XQEXLOBVAL", "SYS_XQEXSTWRP", "SYS_XQEXTRACT", 
		"SYS_XQEXTRREF", "SYS_XQEXVAL", "SYS_XQFB2STR", "SYS_XQFNBOOL", "SYS_XQFNCMP", 
		"SYS_XQFNDATIM", "SYS_XQFNLNAME", "SYS_XQFNNM", "SYS_XQFNNSURI", "SYS_XQFNPREDTRUTH", 
		"SYS_XQFNQNM", "SYS_XQFNROOT", "SYS_XQFORMATNUM", "SYS_XQFTCONTAIN", "SYS_XQFUNCR", 
		"SYS_XQGETCONTENT", "SYS_XQINDXOF", "SYS_XQINSERT", "SYS_XQINSPFX", "SYS_XQIRI2URI", 
		"SYS_XQLANG", "SYS_XQLLNMFRMQNM", "SYS_XQMKNODEREF", "SYS_XQNILLED", "SYS_XQNODENAME", 
		"SYS_XQNORMSPACE", "SYS_XQNORMUCODE", "SYS_XQ_NRNG", "SYS_XQNSP4PFX", 
		"SYS_XQNSPFRMQNM", "SYS_XQPFXFRMQNM", "SYS_XQ_PKSQL2XML", "SYS_XQPOLYABS", 
		"SYS_XQPOLYADD", "SYS_XQPOLYCEL", "SYS_XQPOLYCSTBL", "SYS_XQPOLYCST", 
		"SYS_XQPOLYDIV", "SYS_XQPOLYFLR", "SYS_XQPOLYMOD", "SYS_XQPOLYMUL", "SYS_XQPOLYRND", 
		"SYS_XQPOLYSQRT", "SYS_XQPOLYSUB", "SYS_XQPOLYUMUS", "SYS_XQPOLYUPLS", 
		"SYS_XQPOLYVEQ", "SYS_XQPOLYVGE", "SYS_XQPOLYVGT", "SYS_XQPOLYVLE", "SYS_XQPOLYVLT", 
		"SYS_XQPOLYVNE", "SYS_XQREF2VAL", "SYS_XQRENAME", "SYS_XQREPLACE", "SYS_XQRESVURI", 
		"SYS_XQRNDHALF2EVN", "SYS_XQRSLVQNM", "SYS_XQRYENVPGET", "SYS_XQRYVARGET", 
		"SYS_XQRYWRP", "SYS_XQSEQ2CON4XC", "SYS_XQSEQ2CON", "SYS_XQSEQDEEPEQ", 
		"SYS_XQSEQINSB", "SYS_XQSEQRM", "SYS_XQSEQRVS", "SYS_XQSEQSUB", "SYS_XQSEQTYPMATCH", 
		"SYS_XQSTARTSWITH", "SYS_XQSTATBURI", "SYS_XQSTR2CODEP", "SYS_XQSTRJOIN", 
		"SYS_XQSUBSTRAFT", "SYS_XQSUBSTRBEF", "SYS_XQTOKENIZE", "SYS_XQTREATAS", 
		"SYS_XQ_UPKXML2SQL", "SYS_XQXFORM", "SYS_XSID_TO_RAW", "SYS_ZMAP_FILTER", 
		"SYS_ZMAP_REFRESH", "T_LETTER", "TABLE_LOOKUP_BY_NL", "TABLESPACE_NO", 
		"TABLESPACE", "TABLES", "TABLE_STATS", "TABLE", "TABNO", "TAG", "TANH", 
		"TAN", "TBLORIDXPARTNUM", "TEMPFILE", "TEMPLATE", "TEMPORARY", "TEMP_TABLE", 
		"TEST", "TEXT", "THAN", "THEN", "THE", "THREAD", "THROUGH", "TIER", "TIES", 
		"TIMEOUT", "TIMESTAMP_LTZ_UNCONSTRAINED", "TIMESTAMP", "TIMESTAMP_TZ_UNCONSTRAINED", 
		"TIMESTAMP_UNCONSTRAINED", "TIMES", "TIME", "TIMEZONE", "TIMEZONE_ABBR", 
		"TIMEZONE_HOUR", "TIMEZONE_MINUTE", "TIMEZONE_OFFSET", "TIMEZONE_REGION", 
		"TIME_ZONE", "TIMING", "TIV_GB", "TIV_SSF", "TO_ACLID", "TO_BINARY_DOUBLE", 
		"TO_BINARY_FLOAT", "TO_BLOB", "TO_CLOB", "TO_DSINTERVAL", "TO_LOB", "TO_MULTI_BYTE", 
		"TO_NCHAR", "TO_NCLOB", "TO_NUMBER", "TOPLEVEL", "TO_SINGLE_BYTE", "TO_TIMESTAMP", 
		"TO_TIMESTAMP_TZ", "TO_TIME", "TO_TIME_TZ", "TO", "TO_YMINTERVAL", "TRACE", 
		"TRACING", "TRACKING", "TRAILING", "TRANSACTION", "TRANSFORM", "TRANSFORM_DISTINCT_AGG", 
		"TRANSITIONAL", "TRANSITION", "TRANSLATE", "TRANSLATION", "TREAT", "TRIGGERS", 
		"TRIGGER", "TRUE", "TRUNCATE", "TRUNC", "TRUSTED", "TRUST", "TUNING", 
		"TX", "TYPES", "TYPE", "TZ_OFFSET", "UB2", "UBA", "UCS2", "UID", "UNARCHIVED", 
		"UNBOUNDED", "UNBOUND", "UNCONDITIONAL", "UNDER", "UNDO", "UNDROP", "UNIFORM", 
		"UNION", "UNIQUE", "UNISTR", "UNLIMITED", "UNLOAD", "UNLOCK", "UNMATCHED", 
		"UNNEST_INNERJ_DISTINCT_VIEW", "UNNEST_NOSEMIJ_NODISTINCTVIEW", "UNNEST_SEMIJ_VIEW", 
		"UNNEST", "UNPACKED", "UNPIVOT", "UNPLUG", "UNPROTECTED", "UNQUIESCE", 
		"UNRECOVERABLE", "UNRESTRICTED", "UNSUBSCRIBE", "UNTIL", "UNUSABLE", "UNUSED", 
		"UPDATABLE", "UPDATED", "UPDATE", "UPDATEXML", "UPD_INDEXES", "UPD_JOININDEX", 
		"UPGRADE", "UPPER", "UPSERT", "UROWID", "USABLE", "USAGE", "USE_ANTI", 
		"USE_CONCAT", "USE_CUBE", "USE_HASH_AGGREGATION", "USE_HASH_GBY_FOR_PUSHDOWN", 
		"USE_HASH", "USE_HIDDEN_PARTITIONS", "USE_INVISIBLE_INDEXES", "USE_MERGE_CARTESIAN", 
		"USE_MERGE", "USE_NL", "USE_NL_WITH_INDEX", "USE_PRIVATE_OUTLINES", "USER_DATA", 
		"USER_DEFINED", "USERENV", "USERGROUP", "USER_RECYCLEBIN", "USERS", "USER_TABLESPACES", 
		"USER", "USE_SEMI", "USE_STORED_OUTLINES", "USE_TTT_FOR_GSETS", "USE", 
		"USE_VECTOR_AGGREGATION", "USE_WEAK_NAME_RESL", "USING_NO_EXPAND", "USING", 
		"UTF16BE", "UTF16LE", "UTF32", "UTF8", "V1", "V2", "VALIDATE", "VALIDATE_CONVERSION", 
		"VALIDATION", "VALID_TIME_END", "VALUES", "VALUE", "VARCHAR2", "VARCHAR", 
		"VARIABLE", "VAR_POP", "VARRAYS", "VARRAY", "VAR_SAMP", "VARYING", "VECTOR_READ_TRACE", 
		"VECTOR_READ", "VECTOR_TRANSFORM_DIMS", "VECTOR_TRANSFORM_FACT", "VECTOR_TRANSFORM", 
		"VERIFIER", "VERIFY", "VERSIONING", "VERSIONS_ENDSCN", "VERSIONS_ENDTIME", 
		"VERSIONS_OPERATION", "VERSIONS_STARTSCN", "VERSIONS_STARTTIME", "VERSIONS", 
		"VERSIONS_XID", "VERSION", "VIEW", "VIOLATION", "VIRTUAL", "VISIBILITY", 
		"VISIBLE", "VOLUME", "VSIZE", "WAIT", "WALLET", "WARNING", "WEEKS", "WEEK", 
		"WELLFORMED", "WHENEVER", "WHEN", "WHERE", "WHILE", "WHITESPACE", "WIDTH_BUCKET", 
		"WITHIN", "WITHOUT", "WITH_PLSQL", "WITH", "WORK", "WRAPPED", "WRAPPER", 
		"WRITE", "XDB_FASTPATH_INSERT", "XDB", "X_DYN_PRUNE", "XID", "XML2OBJECT", 
		"XMLAGG", "XMLATTRIBUTES", "XMLCAST", "XMLCDATA", "XMLCOLATTVAL", "XMLCOMMENT", 
		"XMLCONCAT", "XMLDIFF", "XML_DML_RWT_STMT", "XMLELEMENT", "XMLEXISTS2", 
		"XMLEXISTS", "XMLFOREST", "XMLINDEX", "XMLINDEX_REWRITE_IN_SELECT", "XMLINDEX_REWRITE", 
		"XMLINDEX_SEL_IDX_TBL", "XMLISNODE", "XMLISVALID", "XMLNAMESPACES", "XMLPARSE", 
		"XMLPATCH", "XMLPI", "XMLQUERYVAL", "XMLQUERY", "XMLROOT", "XMLSCHEMA", 
		"XMLSERIALIZE", "XMLTABLE", "XMLTRANSFORMBLOB", "XMLTRANSFORM", "XMLTYPE", 
		"XML", "XPATHTABLE", "XS_SYS_CONTEXT", "XS", "XTRANSPORT", "YEARS", "YEAR", 
		"YES", "YMINTERVAL_UNCONSTRAINED", "ZONEMAP", "ZONE", "PREDICTION", "PREDICTION_BOUNDS", 
		"PREDICTION_COST", "PREDICTION_DETAILS", "PREDICTION_PROBABILITY", "PREDICTION_SET", 
		"BLOCKCHAIN", "COLLATE", "COLLATION", "DEFINITION", "DUPLICATED", "EXTENDED", 
		"HASHING", "IDLE", "IMMUTABLE", "ORACLE_DATAPUMP", "ORACLE_HDFS", "ORACLE_HIVE", 
		"ORACLE_LOADER", "SHA2_512_Q", "SHARDED", "V1_Q", "ISOLATE", "ROOT", "UNITE", 
		"ALGORITHM", "CUME_DIST", "DENSE_RANK", "LISTAGG", "PERCENT_RANK", "PERCENTILE_CONT", 
		"PERCENTILE_DISC", "RANK", "AVG", "CORR", "COVAR_", "DECODE", "LAG", "LAG_DIFF", 
		"LAG_DIFF_PERCENT", "LEAD", "MAX", "MEDIAN", "MEMOPTIMIZE", "MIN", "NTILE", 
		"NVL", "RATIO_TO_REPORT", "REGR_", "ROUND", "ROW_NUMBER", "SUBSTR", "TO_CHAR", 
		"TRIM", "SUM", "STDDEV", "VAR_", "VARIANCE", "LEAST", "GREATEST", "TO_DATE", 
		"NATIONAL_CHAR_STRING_LIT", "BIT_STRING_LIT", "HEX_STRING_LIT", "DOUBLE_PERIOD", 
		"PERIOD", "UNSIGNED_INTEGER", "APPROXIMATE_NUM_LIT", "CHAR_STRING", "CHAR_STRING_PERL", 
		"QS_ANGLE", "QS_BRACE", "QS_BRACK", "QS_PAREN", "QS_EXCLAM", "QS_SHARP", 
		"QS_QUOTE", "QS_DQUOTE", "DELIMITED_ID", "PERCENT", "AMPERSAND", "LEFT_PAREN", 
		"RIGHT_PAREN", "DOUBLE_ASTERISK", "ASTERISK", "PLUS_SIGN", "MINUS_SIGN", 
		"COMMA", "SOLIDUS", "AT_SIGN", "ASSIGN_OP", "HASH_OP", "SQ", "BINDVAR", 
		"NOT_EQUAL_OP", "CARRET_OPERATOR_PART", "TILDE_OPERATOR_PART", "EXCLAMATION_OPERATOR_PART", 
		"GREATER_THAN_OP", "LESS_THAN_OP", "COLON", "SEMICOLON", "BAR", "EQUALS_OP", 
		"LEFT_BRACKET", "RIGHT_BRACKET", "INTRODUCER", "SINGLE_LINE_COMMENT", 
		"MULTI_LINE_COMMENT", "REMARK_COMMENT", "PROMPT_MESSAGE", "START_CMD", 
		"REGULAR_ID", "SPACES", "NEWLINE_EOF", "QUESTION_MARK", "SIMPLE_LETTER", 
		"FLOAT_FRAGMENT", "NEWLINE", "SPACE",
	];


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(this, PlSqlLexer._ATN, PlSqlLexer.DecisionsToDFA, new PredictionContextCache());
	}

	public get grammarFileName(): string { return "PlSqlLexer.g4"; }

	public get literalNames(): (string | null)[] { return PlSqlLexer.literalNames; }
	public get symbolicNames(): (string | null)[] { return PlSqlLexer.symbolicNames; }
	public get ruleNames(): string[] { return PlSqlLexer.ruleNames; }

	public get serializedATN(): number[] { return PlSqlLexer._serializedATN; }

	public get channelNames(): string[] { return PlSqlLexer.channelNames; }

	public get modeNames(): string[] { return PlSqlLexer.modeNames; }

	// @Override
	public sempred(localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 2359:
			return this.REMARK_COMMENT_sempred(localctx, predIndex);
		case 2360:
			return this.PROMPT_MESSAGE_sempred(localctx, predIndex);
		}
		return true;
	}
	private REMARK_COMMENT_sempred(localctx: RuleContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.IsNewlineAtPos(-4);
		}
		return true;
	}
	private PROMPT_MESSAGE_sempred(localctx: RuleContext, predIndex: number): boolean {
		switch (predIndex) {
		case 1:
			return this.IsNewlineAtPos(-4);
		}
		return true;
	}

	public static readonly _serializedATN: number[] = [4,0,2355,30932,6,-1,
	2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,
	2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,
	7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,
	23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,
	2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,
	38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,
	7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,
	52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,
	2,60,7,60,2,61,7,61,2,62,7,62,2,63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,
	67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,
	7,74,2,75,7,75,2,76,7,76,2,77,7,77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,
	81,2,82,7,82,2,83,7,83,2,84,7,84,2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,
	2,89,7,89,2,90,7,90,2,91,7,91,2,92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,
	96,7,96,2,97,7,97,2,98,7,98,2,99,7,99,2,100,7,100,2,101,7,101,2,102,7,102,
	2,103,7,103,2,104,7,104,2,105,7,105,2,106,7,106,2,107,7,107,2,108,7,108,
	2,109,7,109,2,110,7,110,2,111,7,111,2,112,7,112,2,113,7,113,2,114,7,114,
	2,115,7,115,2,116,7,116,2,117,7,117,2,118,7,118,2,119,7,119,2,120,7,120,
	2,121,7,121,2,122,7,122,2,123,7,123,2,124,7,124,2,125,7,125,2,126,7,126,
	2,127,7,127,2,128,7,128,2,129,7,129,2,130,7,130,2,131,7,131,2,132,7,132,
	2,133,7,133,2,134,7,134,2,135,7,135,2,136,7,136,2,137,7,137,2,138,7,138,
	2,139,7,139,2,140,7,140,2,141,7,141,2,142,7,142,2,143,7,143,2,144,7,144,
	2,145,7,145,2,146,7,146,2,147,7,147,2,148,7,148,2,149,7,149,2,150,7,150,
	2,151,7,151,2,152,7,152,2,153,7,153,2,154,7,154,2,155,7,155,2,156,7,156,
	2,157,7,157,2,158,7,158,2,159,7,159,2,160,7,160,2,161,7,161,2,162,7,162,
	2,163,7,163,2,164,7,164,2,165,7,165,2,166,7,166,2,167,7,167,2,168,7,168,
	2,169,7,169,2,170,7,170,2,171,7,171,2,172,7,172,2,173,7,173,2,174,7,174,
	2,175,7,175,2,176,7,176,2,177,7,177,2,178,7,178,2,179,7,179,2,180,7,180,
	2,181,7,181,2,182,7,182,2,183,7,183,2,184,7,184,2,185,7,185,2,186,7,186,
	2,187,7,187,2,188,7,188,2,189,7,189,2,190,7,190,2,191,7,191,2,192,7,192,
	2,193,7,193,2,194,7,194,2,195,7,195,2,196,7,196,2,197,7,197,2,198,7,198,
	2,199,7,199,2,200,7,200,2,201,7,201,2,202,7,202,2,203,7,203,2,204,7,204,
	2,205,7,205,2,206,7,206,2,207,7,207,2,208,7,208,2,209,7,209,2,210,7,210,
	2,211,7,211,2,212,7,212,2,213,7,213,2,214,7,214,2,215,7,215,2,216,7,216,
	2,217,7,217,2,218,7,218,2,219,7,219,2,220,7,220,2,221,7,221,2,222,7,222,
	2,223,7,223,2,224,7,224,2,225,7,225,2,226,7,226,2,227,7,227,2,228,7,228,
	2,229,7,229,2,230,7,230,2,231,7,231,2,232,7,232,2,233,7,233,2,234,7,234,
	2,235,7,235,2,236,7,236,2,237,7,237,2,238,7,238,2,239,7,239,2,240,7,240,
	2,241,7,241,2,242,7,242,2,243,7,243,2,244,7,244,2,245,7,245,2,246,7,246,
	2,247,7,247,2,248,7,248,2,249,7,249,2,250,7,250,2,251,7,251,2,252,7,252,
	2,253,7,253,2,254,7,254,2,255,7,255,2,256,7,256,2,257,7,257,2,258,7,258,
	2,259,7,259,2,260,7,260,2,261,7,261,2,262,7,262,2,263,7,263,2,264,7,264,
	2,265,7,265,2,266,7,266,2,267,7,267,2,268,7,268,2,269,7,269,2,270,7,270,
	2,271,7,271,2,272,7,272,2,273,7,273,2,274,7,274,2,275,7,275,2,276,7,276,
	2,277,7,277,2,278,7,278,2,279,7,279,2,280,7,280,2,281,7,281,2,282,7,282,
	2,283,7,283,2,284,7,284,2,285,7,285,2,286,7,286,2,287,7,287,2,288,7,288,
	2,289,7,289,2,290,7,290,2,291,7,291,2,292,7,292,2,293,7,293,2,294,7,294,
	2,295,7,295,2,296,7,296,2,297,7,297,2,298,7,298,2,299,7,299,2,300,7,300,
	2,301,7,301,2,302,7,302,2,303,7,303,2,304,7,304,2,305,7,305,2,306,7,306,
	2,307,7,307,2,308,7,308,2,309,7,309,2,310,7,310,2,311,7,311,2,312,7,312,
	2,313,7,313,2,314,7,314,2,315,7,315,2,316,7,316,2,317,7,317,2,318,7,318,
	2,319,7,319,2,320,7,320,2,321,7,321,2,322,7,322,2,323,7,323,2,324,7,324,
	2,325,7,325,2,326,7,326,2,327,7,327,2,328,7,328,2,329,7,329,2,330,7,330,
	2,331,7,331,2,332,7,332,2,333,7,333,2,334,7,334,2,335,7,335,2,336,7,336,
	2,337,7,337,2,338,7,338,2,339,7,339,2,340,7,340,2,341,7,341,2,342,7,342,
	2,343,7,343,2,344,7,344,2,345,7,345,2,346,7,346,2,347,7,347,2,348,7,348,
	2,349,7,349,2,350,7,350,2,351,7,351,2,352,7,352,2,353,7,353,2,354,7,354,
	2,355,7,355,2,356,7,356,2,357,7,357,2,358,7,358,2,359,7,359,2,360,7,360,
	2,361,7,361,2,362,7,362,2,363,7,363,2,364,7,364,2,365,7,365,2,366,7,366,
	2,367,7,367,2,368,7,368,2,369,7,369,2,370,7,370,2,371,7,371,2,372,7,372,
	2,373,7,373,2,374,7,374,2,375,7,375,2,376,7,376,2,377,7,377,2,378,7,378,
	2,379,7,379,2,380,7,380,2,381,7,381,2,382,7,382,2,383,7,383,2,384,7,384,
	2,385,7,385,2,386,7,386,2,387,7,387,2,388,7,388,2,389,7,389,2,390,7,390,
	2,391,7,391,2,392,7,392,2,393,7,393,2,394,7,394,2,395,7,395,2,396,7,396,
	2,397,7,397,2,398,7,398,2,399,7,399,2,400,7,400,2,401,7,401,2,402,7,402,
	2,403,7,403,2,404,7,404,2,405,7,405,2,406,7,406,2,407,7,407,2,408,7,408,
	2,409,7,409,2,410,7,410,2,411,7,411,2,412,7,412,2,413,7,413,2,414,7,414,
	2,415,7,415,2,416,7,416,2,417,7,417,2,418,7,418,2,419,7,419,2,420,7,420,
	2,421,7,421,2,422,7,422,2,423,7,423,2,424,7,424,2,425,7,425,2,426,7,426,
	2,427,7,427,2,428,7,428,2,429,7,429,2,430,7,430,2,431,7,431,2,432,7,432,
	2,433,7,433,2,434,7,434,2,435,7,435,2,436,7,436,2,437,7,437,2,438,7,438,
	2,439,7,439,2,440,7,440,2,441,7,441,2,442,7,442,2,443,7,443,2,444,7,444,
	2,445,7,445,2,446,7,446,2,447,7,447,2,448,7,448,2,449,7,449,2,450,7,450,
	2,451,7,451,2,452,7,452,2,453,7,453,2,454,7,454,2,455,7,455,2,456,7,456,
	2,457,7,457,2,458,7,458,2,459,7,459,2,460,7,460,2,461,7,461,2,462,7,462,
	2,463,7,463,2,464,7,464,2,465,7,465,2,466,7,466,2,467,7,467,2,468,7,468,
	2,469,7,469,2,470,7,470,2,471,7,471,2,472,7,472,2,473,7,473,2,474,7,474,
	2,475,7,475,2,476,7,476,2,477,7,477,2,478,7,478,2,479,7,479,2,480,7,480,
	2,481,7,481,2,482,7,482,2,483,7,483,2,484,7,484,2,485,7,485,2,486,7,486,
	2,487,7,487,2,488,7,488,2,489,7,489,2,490,7,490,2,491,7,491,2,492,7,492,
	2,493,7,493,2,494,7,494,2,495,7,495,2,496,7,496,2,497,7,497,2,498,7,498,
	2,499,7,499,2,500,7,500,2,501,7,501,2,502,7,502,2,503,7,503,2,504,7,504,
	2,505,7,505,2,506,7,506,2,507,7,507,2,508,7,508,2,509,7,509,2,510,7,510,
	2,511,7,511,2,512,7,512,2,513,7,513,2,514,7,514,2,515,7,515,2,516,7,516,
	2,517,7,517,2,518,7,518,2,519,7,519,2,520,7,520,2,521,7,521,2,522,7,522,
	2,523,7,523,2,524,7,524,2,525,7,525,2,526,7,526,2,527,7,527,2,528,7,528,
	2,529,7,529,2,530,7,530,2,531,7,531,2,532,7,532,2,533,7,533,2,534,7,534,
	2,535,7,535,2,536,7,536,2,537,7,537,2,538,7,538,2,539,7,539,2,540,7,540,
	2,541,7,541,2,542,7,542,2,543,7,543,2,544,7,544,2,545,7,545,2,546,7,546,
	2,547,7,547,2,548,7,548,2,549,7,549,2,550,7,550,2,551,7,551,2,552,7,552,
	2,553,7,553,2,554,7,554,2,555,7,555,2,556,7,556,2,557,7,557,2,558,7,558,
	2,559,7,559,2,560,7,560,2,561,7,561,2,562,7,562,2,563,7,563,2,564,7,564,
	2,565,7,565,2,566,7,566,2,567,7,567,2,568,7,568,2,569,7,569,2,570,7,570,
	2,571,7,571,2,572,7,572,2,573,7,573,2,574,7,574,2,575,7,575,2,576,7,576,
	2,577,7,577,2,578,7,578,2,579,7,579,2,580,7,580,2,581,7,581,2,582,7,582,
	2,583,7,583,2,584,7,584,2,585,7,585,2,586,7,586,2,587,7,587,2,588,7,588,
	2,589,7,589,2,590,7,590,2,591,7,591,2,592,7,592,2,593,7,593,2,594,7,594,
	2,595,7,595,2,596,7,596,2,597,7,597,2,598,7,598,2,599,7,599,2,600,7,600,
	2,601,7,601,2,602,7,602,2,603,7,603,2,604,7,604,2,605,7,605,2,606,7,606,
	2,607,7,607,2,608,7,608,2,609,7,609,2,610,7,610,2,611,7,611,2,612,7,612,
	2,613,7,613,2,614,7,614,2,615,7,615,2,616,7,616,2,617,7,617,2,618,7,618,
	2,619,7,619,2,620,7,620,2,621,7,621,2,622,7,622,2,623,7,623,2,624,7,624,
	2,625,7,625,2,626,7,626,2,627,7,627,2,628,7,628,2,629,7,629,2,630,7,630,
	2,631,7,631,2,632,7,632,2,633,7,633,2,634,7,634,2,635,7,635,2,636,7,636,
	2,637,7,637,2,638,7,638,2,639,7,639,2,640,7,640,2,641,7,641,2,642,7,642,
	2,643,7,643,2,644,7,644,2,645,7,645,2,646,7,646,2,647,7,647,2,648,7,648,
	2,649,7,649,2,650,7,650,2,651,7,651,2,652,7,652,2,653,7,653,2,654,7,654,
	2,655,7,655,2,656,7,656,2,657,7,657,2,658,7,658,2,659,7,659,2,660,7,660,
	2,661,7,661,2,662,7,662,2,663,7,663,2,664,7,664,2,665,7,665,2,666,7,666,
	2,667,7,667,2,668,7,668,2,669,7,669,2,670,7,670,2,671,7,671,2,672,7,672,
	2,673,7,673,2,674,7,674,2,675,7,675,2,676,7,676,2,677,7,677,2,678,7,678,
	2,679,7,679,2,680,7,680,2,681,7,681,2,682,7,682,2,683,7,683,2,684,7,684,
	2,685,7,685,2,686,7,686,2,687,7,687,2,688,7,688,2,689,7,689,2,690,7,690,
	2,691,7,691,2,692,7,692,2,693,7,693,2,694,7,694,2,695,7,695,2,696,7,696,
	2,697,7,697,2,698,7,698,2,699,7,699,2,700,7,700,2,701,7,701,2,702,7,702,
	2,703,7,703,2,704,7,704,2,705,7,705,2,706,7,706,2,707,7,707,2,708,7,708,
	2,709,7,709,2,710,7,710,2,711,7,711,2,712,7,712,2,713,7,713,2,714,7,714,
	2,715,7,715,2,716,7,716,2,717,7,717,2,718,7,718,2,719,7,719,2,720,7,720,
	2,721,7,721,2,722,7,722,2,723,7,723,2,724,7,724,2,725,7,725,2,726,7,726,
	2,727,7,727,2,728,7,728,2,729,7,729,2,730,7,730,2,731,7,731,2,732,7,732,
	2,733,7,733,2,734,7,734,2,735,7,735,2,736,7,736,2,737,7,737,2,738,7,738,
	2,739,7,739,2,740,7,740,2,741,7,741,2,742,7,742,2,743,7,743,2,744,7,744,
	2,745,7,745,2,746,7,746,2,747,7,747,2,748,7,748,2,749,7,749,2,750,7,750,
	2,751,7,751,2,752,7,752,2,753,7,753,2,754,7,754,2,755,7,755,2,756,7,756,
	2,757,7,757,2,758,7,758,2,759,7,759,2,760,7,760,2,761,7,761,2,762,7,762,
	2,763,7,763,2,764,7,764,2,765,7,765,2,766,7,766,2,767,7,767,2,768,7,768,
	2,769,7,769,2,770,7,770,2,771,7,771,2,772,7,772,2,773,7,773,2,774,7,774,
	2,775,7,775,2,776,7,776,2,777,7,777,2,778,7,778,2,779,7,779,2,780,7,780,
	2,781,7,781,2,782,7,782,2,783,7,783,2,784,7,784,2,785,7,785,2,786,7,786,
	2,787,7,787,2,788,7,788,2,789,7,789,2,790,7,790,2,791,7,791,2,792,7,792,
	2,793,7,793,2,794,7,794,2,795,7,795,2,796,7,796,2,797,7,797,2,798,7,798,
	2,799,7,799,2,800,7,800,2,801,7,801,2,802,7,802,2,803,7,803,2,804,7,804,
	2,805,7,805,2,806,7,806,2,807,7,807,2,808,7,808,2,809,7,809,2,810,7,810,
	2,811,7,811,2,812,7,812,2,813,7,813,2,814,7,814,2,815,7,815,2,816,7,816,
	2,817,7,817,2,818,7,818,2,819,7,819,2,820,7,820,2,821,7,821,2,822,7,822,
	2,823,7,823,2,824,7,824,2,825,7,825,2,826,7,826,2,827,7,827,2,828,7,828,
	2,829,7,829,2,830,7,830,2,831,7,831,2,832,7,832,2,833,7,833,2,834,7,834,
	2,835,7,835,2,836,7,836,2,837,7,837,2,838,7,838,2,839,7,839,2,840,7,840,
	2,841,7,841,2,842,7,842,2,843,7,843,2,844,7,844,2,845,7,845,2,846,7,846,
	2,847,7,847,2,848,7,848,2,849,7,849,2,850,7,850,2,851,7,851,2,852,7,852,
	2,853,7,853,2,854,7,854,2,855,7,855,2,856,7,856,2,857,7,857,2,858,7,858,
	2,859,7,859,2,860,7,860,2,861,7,861,2,862,7,862,2,863,7,863,2,864,7,864,
	2,865,7,865,2,866,7,866,2,867,7,867,2,868,7,868,2,869,7,869,2,870,7,870,
	2,871,7,871,2,872,7,872,2,873,7,873,2,874,7,874,2,875,7,875,2,876,7,876,
	2,877,7,877,2,878,7,878,2,879,7,879,2,880,7,880,2,881,7,881,2,882,7,882,
	2,883,7,883,2,884,7,884,2,885,7,885,2,886,7,886,2,887,7,887,2,888,7,888,
	2,889,7,889,2,890,7,890,2,891,7,891,2,892,7,892,2,893,7,893,2,894,7,894,
	2,895,7,895,2,896,7,896,2,897,7,897,2,898,7,898,2,899,7,899,2,900,7,900,
	2,901,7,901,2,902,7,902,2,903,7,903,2,904,7,904,2,905,7,905,2,906,7,906,
	2,907,7,907,2,908,7,908,2,909,7,909,2,910,7,910,2,911,7,911,2,912,7,912,
	2,913,7,913,2,914,7,914,2,915,7,915,2,916,7,916,2,917,7,917,2,918,7,918,
	2,919,7,919,2,920,7,920,2,921,7,921,2,922,7,922,2,923,7,923,2,924,7,924,
	2,925,7,925,2,926,7,926,2,927,7,927,2,928,7,928,2,929,7,929,2,930,7,930,
	2,931,7,931,2,932,7,932,2,933,7,933,2,934,7,934,2,935,7,935,2,936,7,936,
	2,937,7,937,2,938,7,938,2,939,7,939,2,940,7,940,2,941,7,941,2,942,7,942,
	2,943,7,943,2,944,7,944,2,945,7,945,2,946,7,946,2,947,7,947,2,948,7,948,
	2,949,7,949,2,950,7,950,2,951,7,951,2,952,7,952,2,953,7,953,2,954,7,954,
	2,955,7,955,2,956,7,956,2,957,7,957,2,958,7,958,2,959,7,959,2,960,7,960,
	2,961,7,961,2,962,7,962,2,963,7,963,2,964,7,964,2,965,7,965,2,966,7,966,
	2,967,7,967,2,968,7,968,2,969,7,969,2,970,7,970,2,971,7,971,2,972,7,972,
	2,973,7,973,2,974,7,974,2,975,7,975,2,976,7,976,2,977,7,977,2,978,7,978,
	2,979,7,979,2,980,7,980,2,981,7,981,2,982,7,982,2,983,7,983,2,984,7,984,
	2,985,7,985,2,986,7,986,2,987,7,987,2,988,7,988,2,989,7,989,2,990,7,990,
	2,991,7,991,2,992,7,992,2,993,7,993,2,994,7,994,2,995,7,995,2,996,7,996,
	2,997,7,997,2,998,7,998,2,999,7,999,2,1000,7,1000,2,1001,7,1001,2,1002,
	7,1002,2,1003,7,1003,2,1004,7,1004,2,1005,7,1005,2,1006,7,1006,2,1007,7,
	1007,2,1008,7,1008,2,1009,7,1009,2,1010,7,1010,2,1011,7,1011,2,1012,7,1012,
	2,1013,7,1013,2,1014,7,1014,2,1015,7,1015,2,1016,7,1016,2,1017,7,1017,2,
	1018,7,1018,2,1019,7,1019,2,1020,7,1020,2,1021,7,1021,2,1022,7,1022,2,1023,
	7,1023,2,1024,7,1024,2,1025,7,1025,2,1026,7,1026,2,1027,7,1027,2,1028,7,
	1028,2,1029,7,1029,2,1030,7,1030,2,1031,7,1031,2,1032,7,1032,2,1033,7,1033,
	2,1034,7,1034,2,1035,7,1035,2,1036,7,1036,2,1037,7,1037,2,1038,7,1038,2,
	1039,7,1039,2,1040,7,1040,2,1041,7,1041,2,1042,7,1042,2,1043,7,1043,2,1044,
	7,1044,2,1045,7,1045,2,1046,7,1046,2,1047,7,1047,2,1048,7,1048,2,1049,7,
	1049,2,1050,7,1050,2,1051,7,1051,2,1052,7,1052,2,1053,7,1053,2,1054,7,1054,
	2,1055,7,1055,2,1056,7,1056,2,1057,7,1057,2,1058,7,1058,2,1059,7,1059,2,
	1060,7,1060,2,1061,7,1061,2,1062,7,1062,2,1063,7,1063,2,1064,7,1064,2,1065,
	7,1065,2,1066,7,1066,2,1067,7,1067,2,1068,7,1068,2,1069,7,1069,2,1070,7,
	1070,2,1071,7,1071,2,1072,7,1072,2,1073,7,1073,2,1074,7,1074,2,1075,7,1075,
	2,1076,7,1076,2,1077,7,1077,2,1078,7,1078,2,1079,7,1079,2,1080,7,1080,2,
	1081,7,1081,2,1082,7,1082,2,1083,7,1083,2,1084,7,1084,2,1085,7,1085,2,1086,
	7,1086,2,1087,7,1087,2,1088,7,1088,2,1089,7,1089,2,1090,7,1090,2,1091,7,
	1091,2,1092,7,1092,2,1093,7,1093,2,1094,7,1094,2,1095,7,1095,2,1096,7,1096,
	2,1097,7,1097,2,1098,7,1098,2,1099,7,1099,2,1100,7,1100,2,1101,7,1101,2,
	1102,7,1102,2,1103,7,1103,2,1104,7,1104,2,1105,7,1105,2,1106,7,1106,2,1107,
	7,1107,2,1108,7,1108,2,1109,7,1109,2,1110,7,1110,2,1111,7,1111,2,1112,7,
	1112,2,1113,7,1113,2,1114,7,1114,2,1115,7,1115,2,1116,7,1116,2,1117,7,1117,
	2,1118,7,1118,2,1119,7,1119,2,1120,7,1120,2,1121,7,1121,2,1122,7,1122,2,
	1123,7,1123,2,1124,7,1124,2,1125,7,1125,2,1126,7,1126,2,1127,7,1127,2,1128,
	7,1128,2,1129,7,1129,2,1130,7,1130,2,1131,7,1131,2,1132,7,1132,2,1133,7,
	1133,2,1134,7,1134,2,1135,7,1135,2,1136,7,1136,2,1137,7,1137,2,1138,7,1138,
	2,1139,7,1139,2,1140,7,1140,2,1141,7,1141,2,1142,7,1142,2,1143,7,1143,2,
	1144,7,1144,2,1145,7,1145,2,1146,7,1146,2,1147,7,1147,2,1148,7,1148,2,1149,
	7,1149,2,1150,7,1150,2,1151,7,1151,2,1152,7,1152,2,1153,7,1153,2,1154,7,
	1154,2,1155,7,1155,2,1156,7,1156,2,1157,7,1157,2,1158,7,1158,2,1159,7,1159,
	2,1160,7,1160,2,1161,7,1161,2,1162,7,1162,2,1163,7,1163,2,1164,7,1164,2,
	1165,7,1165,2,1166,7,1166,2,1167,7,1167,2,1168,7,1168,2,1169,7,1169,2,1170,
	7,1170,2,1171,7,1171,2,1172,7,1172,2,1173,7,1173,2,1174,7,1174,2,1175,7,
	1175,2,1176,7,1176,2,1177,7,1177,2,1178,7,1178,2,1179,7,1179,2,1180,7,1180,
	2,1181,7,1181,2,1182,7,1182,2,1183,7,1183,2,1184,7,1184,2,1185,7,1185,2,
	1186,7,1186,2,1187,7,1187,2,1188,7,1188,2,1189,7,1189,2,1190,7,1190,2,1191,
	7,1191,2,1192,7,1192,2,1193,7,1193,2,1194,7,1194,2,1195,7,1195,2,1196,7,
	1196,2,1197,7,1197,2,1198,7,1198,2,1199,7,1199,2,1200,7,1200,2,1201,7,1201,
	2,1202,7,1202,2,1203,7,1203,2,1204,7,1204,2,1205,7,1205,2,1206,7,1206,2,
	1207,7,1207,2,1208,7,1208,2,1209,7,1209,2,1210,7,1210,2,1211,7,1211,2,1212,
	7,1212,2,1213,7,1213,2,1214,7,1214,2,1215,7,1215,2,1216,7,1216,2,1217,7,
	1217,2,1218,7,1218,2,1219,7,1219,2,1220,7,1220,2,1221,7,1221,2,1222,7,1222,
	2,1223,7,1223,2,1224,7,1224,2,1225,7,1225,2,1226,7,1226,2,1227,7,1227,2,
	1228,7,1228,2,1229,7,1229,2,1230,7,1230,2,1231,7,1231,2,1232,7,1232,2,1233,
	7,1233,2,1234,7,1234,2,1235,7,1235,2,1236,7,1236,2,1237,7,1237,2,1238,7,
	1238,2,1239,7,1239,2,1240,7,1240,2,1241,7,1241,2,1242,7,1242,2,1243,7,1243,
	2,1244,7,1244,2,1245,7,1245,2,1246,7,1246,2,1247,7,1247,2,1248,7,1248,2,
	1249,7,1249,2,1250,7,1250,2,1251,7,1251,2,1252,7,1252,2,1253,7,1253,2,1254,
	7,1254,2,1255,7,1255,2,1256,7,1256,2,1257,7,1257,2,1258,7,1258,2,1259,7,
	1259,2,1260,7,1260,2,1261,7,1261,2,1262,7,1262,2,1263,7,1263,2,1264,7,1264,
	2,1265,7,1265,2,1266,7,1266,2,1267,7,1267,2,1268,7,1268,2,1269,7,1269,2,
	1270,7,1270,2,1271,7,1271,2,1272,7,1272,2,1273,7,1273,2,1274,7,1274,2,1275,
	7,1275,2,1276,7,1276,2,1277,7,1277,2,1278,7,1278,2,1279,7,1279,2,1280,7,
	1280,2,1281,7,1281,2,1282,7,1282,2,1283,7,1283,2,1284,7,1284,2,1285,7,1285,
	2,1286,7,1286,2,1287,7,1287,2,1288,7,1288,2,1289,7,1289,2,1290,7,1290,2,
	1291,7,1291,2,1292,7,1292,2,1293,7,1293,2,1294,7,1294,2,1295,7,1295,2,1296,
	7,1296,2,1297,7,1297,2,1298,7,1298,2,1299,7,1299,2,1300,7,1300,2,1301,7,
	1301,2,1302,7,1302,2,1303,7,1303,2,1304,7,1304,2,1305,7,1305,2,1306,7,1306,
	2,1307,7,1307,2,1308,7,1308,2,1309,7,1309,2,1310,7,1310,2,1311,7,1311,2,
	1312,7,1312,2,1313,7,1313,2,1314,7,1314,2,1315,7,1315,2,1316,7,1316,2,1317,
	7,1317,2,1318,7,1318,2,1319,7,1319,2,1320,7,1320,2,1321,7,1321,2,1322,7,
	1322,2,1323,7,1323,2,1324,7,1324,2,1325,7,1325,2,1326,7,1326,2,1327,7,1327,
	2,1328,7,1328,2,1329,7,1329,2,1330,7,1330,2,1331,7,1331,2,1332,7,1332,2,
	1333,7,1333,2,1334,7,1334,2,1335,7,1335,2,1336,7,1336,2,1337,7,1337,2,1338,
	7,1338,2,1339,7,1339,2,1340,7,1340,2,1341,7,1341,2,1342,7,1342,2,1343,7,
	1343,2,1344,7,1344,2,1345,7,1345,2,1346,7,1346,2,1347,7,1347,2,1348,7,1348,
	2,1349,7,1349,2,1350,7,1350,2,1351,7,1351,2,1352,7,1352,2,1353,7,1353,2,
	1354,7,1354,2,1355,7,1355,2,1356,7,1356,2,1357,7,1357,2,1358,7,1358,2,1359,
	7,1359,2,1360,7,1360,2,1361,7,1361,2,1362,7,1362,2,1363,7,1363,2,1364,7,
	1364,2,1365,7,1365,2,1366,7,1366,2,1367,7,1367,2,1368,7,1368,2,1369,7,1369,
	2,1370,7,1370,2,1371,7,1371,2,1372,7,1372,2,1373,7,1373,2,1374,7,1374,2,
	1375,7,1375,2,1376,7,1376,2,1377,7,1377,2,1378,7,1378,2,1379,7,1379,2,1380,
	7,1380,2,1381,7,1381,2,1382,7,1382,2,1383,7,1383,2,1384,7,1384,2,1385,7,
	1385,2,1386,7,1386,2,1387,7,1387,2,1388,7,1388,2,1389,7,1389,2,1390,7,1390,
	2,1391,7,1391,2,1392,7,1392,2,1393,7,1393,2,1394,7,1394,2,1395,7,1395,2,
	1396,7,1396,2,1397,7,1397,2,1398,7,1398,2,1399,7,1399,2,1400,7,1400,2,1401,
	7,1401,2,1402,7,1402,2,1403,7,1403,2,1404,7,1404,2,1405,7,1405,2,1406,7,
	1406,2,1407,7,1407,2,1408,7,1408,2,1409,7,1409,2,1410,7,1410,2,1411,7,1411,
	2,1412,7,1412,2,1413,7,1413,2,1414,7,1414,2,1415,7,1415,2,1416,7,1416,2,
	1417,7,1417,2,1418,7,1418,2,1419,7,1419,2,1420,7,1420,2,1421,7,1421,2,1422,
	7,1422,2,1423,7,1423,2,1424,7,1424,2,1425,7,1425,2,1426,7,1426,2,1427,7,
	1427,2,1428,7,1428,2,1429,7,1429,2,1430,7,1430,2,1431,7,1431,2,1432,7,1432,
	2,1433,7,1433,2,1434,7,1434,2,1435,7,1435,2,1436,7,1436,2,1437,7,1437,2,
	1438,7,1438,2,1439,7,1439,2,1440,7,1440,2,1441,7,1441,2,1442,7,1442,2,1443,
	7,1443,2,1444,7,1444,2,1445,7,1445,2,1446,7,1446,2,1447,7,1447,2,1448,7,
	1448,2,1449,7,1449,2,1450,7,1450,2,1451,7,1451,2,1452,7,1452,2,1453,7,1453,
	2,1454,7,1454,2,1455,7,1455,2,1456,7,1456,2,1457,7,1457,2,1458,7,1458,2,
	1459,7,1459,2,1460,7,1460,2,1461,7,1461,2,1462,7,1462,2,1463,7,1463,2,1464,
	7,1464,2,1465,7,1465,2,1466,7,1466,2,1467,7,1467,2,1468,7,1468,2,1469,7,
	1469,2,1470,7,1470,2,1471,7,1471,2,1472,7,1472,2,1473,7,1473,2,1474,7,1474,
	2,1475,7,1475,2,1476,7,1476,2,1477,7,1477,2,1478,7,1478,2,1479,7,1479,2,
	1480,7,1480,2,1481,7,1481,2,1482,7,1482,2,1483,7,1483,2,1484,7,1484,2,1485,
	7,1485,2,1486,7,1486,2,1487,7,1487,2,1488,7,1488,2,1489,7,1489,2,1490,7,
	1490,2,1491,7,1491,2,1492,7,1492,2,1493,7,1493,2,1494,7,1494,2,1495,7,1495,
	2,1496,7,1496,2,1497,7,1497,2,1498,7,1498,2,1499,7,1499,2,1500,7,1500,2,
	1501,7,1501,2,1502,7,1502,2,1503,7,1503,2,1504,7,1504,2,1505,7,1505,2,1506,
	7,1506,2,1507,7,1507,2,1508,7,1508,2,1509,7,1509,2,1510,7,1510,2,1511,7,
	1511,2,1512,7,1512,2,1513,7,1513,2,1514,7,1514,2,1515,7,1515,2,1516,7,1516,
	2,1517,7,1517,2,1518,7,1518,2,1519,7,1519,2,1520,7,1520,2,1521,7,1521,2,
	1522,7,1522,2,1523,7,1523,2,1524,7,1524,2,1525,7,1525,2,1526,7,1526,2,1527,
	7,1527,2,1528,7,1528,2,1529,7,1529,2,1530,7,1530,2,1531,7,1531,2,1532,7,
	1532,2,1533,7,1533,2,1534,7,1534,2,1535,7,1535,2,1536,7,1536,2,1537,7,1537,
	2,1538,7,1538,2,1539,7,1539,2,1540,7,1540,2,1541,7,1541,2,1542,7,1542,2,
	1543,7,1543,2,1544,7,1544,2,1545,7,1545,2,1546,7,1546,2,1547,7,1547,2,1548,
	7,1548,2,1549,7,1549,2,1550,7,1550,2,1551,7,1551,2,1552,7,1552,2,1553,7,
	1553,2,1554,7,1554,2,1555,7,1555,2,1556,7,1556,2,1557,7,1557,2,1558,7,1558,
	2,1559,7,1559,2,1560,7,1560,2,1561,7,1561,2,1562,7,1562,2,1563,7,1563,2,
	1564,7,1564,2,1565,7,1565,2,1566,7,1566,2,1567,7,1567,2,1568,7,1568,2,1569,
	7,1569,2,1570,7,1570,2,1571,7,1571,2,1572,7,1572,2,1573,7,1573,2,1574,7,
	1574,2,1575,7,1575,2,1576,7,1576,2,1577,7,1577,2,1578,7,1578,2,1579,7,1579,
	2,1580,7,1580,2,1581,7,1581,2,1582,7,1582,2,1583,7,1583,2,1584,7,1584,2,
	1585,7,1585,2,1586,7,1586,2,1587,7,1587,2,1588,7,1588,2,1589,7,1589,2,1590,
	7,1590,2,1591,7,1591,2,1592,7,1592,2,1593,7,1593,2,1594,7,1594,2,1595,7,
	1595,2,1596,7,1596,2,1597,7,1597,2,1598,7,1598,2,1599,7,1599,2,1600,7,1600,
	2,1601,7,1601,2,1602,7,1602,2,1603,7,1603,2,1604,7,1604,2,1605,7,1605,2,
	1606,7,1606,2,1607,7,1607,2,1608,7,1608,2,1609,7,1609,2,1610,7,1610,2,1611,
	7,1611,2,1612,7,1612,2,1613,7,1613,2,1614,7,1614,2,1615,7,1615,2,1616,7,
	1616,2,1617,7,1617,2,1618,7,1618,2,1619,7,1619,2,1620,7,1620,2,1621,7,1621,
	2,1622,7,1622,2,1623,7,1623,2,1624,7,1624,2,1625,7,1625,2,1626,7,1626,2,
	1627,7,1627,2,1628,7,1628,2,1629,7,1629,2,1630,7,1630,2,1631,7,1631,2,1632,
	7,1632,2,1633,7,1633,2,1634,7,1634,2,1635,7,1635,2,1636,7,1636,2,1637,7,
	1637,2,1638,7,1638,2,1639,7,1639,2,1640,7,1640,2,1641,7,1641,2,1642,7,1642,
	2,1643,7,1643,2,1644,7,1644,2,1645,7,1645,2,1646,7,1646,2,1647,7,1647,2,
	1648,7,1648,2,1649,7,1649,2,1650,7,1650,2,1651,7,1651,2,1652,7,1652,2,1653,
	7,1653,2,1654,7,1654,2,1655,7,1655,2,1656,7,1656,2,1657,7,1657,2,1658,7,
	1658,2,1659,7,1659,2,1660,7,1660,2,1661,7,1661,2,1662,7,1662,2,1663,7,1663,
	2,1664,7,1664,2,1665,7,1665,2,1666,7,1666,2,1667,7,1667,2,1668,7,1668,2,
	1669,7,1669,2,1670,7,1670,2,1671,7,1671,2,1672,7,1672,2,1673,7,1673,2,1674,
	7,1674,2,1675,7,1675,2,1676,7,1676,2,1677,7,1677,2,1678,7,1678,2,1679,7,
	1679,2,1680,7,1680,2,1681,7,1681,2,1682,7,1682,2,1683,7,1683,2,1684,7,1684,
	2,1685,7,1685,2,1686,7,1686,2,1687,7,1687,2,1688,7,1688,2,1689,7,1689,2,
	1690,7,1690,2,1691,7,1691,2,1692,7,1692,2,1693,7,1693,2,1694,7,1694,2,1695,
	7,1695,2,1696,7,1696,2,1697,7,1697,2,1698,7,1698,2,1699,7,1699,2,1700,7,
	1700,2,1701,7,1701,2,1702,7,1702,2,1703,7,1703,2,1704,7,1704,2,1705,7,1705,
	2,1706,7,1706,2,1707,7,1707,2,1708,7,1708,2,1709,7,1709,2,1710,7,1710,2,
	1711,7,1711,2,1712,7,1712,2,1713,7,1713,2,1714,7,1714,2,1715,7,1715,2,1716,
	7,1716,2,1717,7,1717,2,1718,7,1718,2,1719,7,1719,2,1720,7,1720,2,1721,7,
	1721,2,1722,7,1722,2,1723,7,1723,2,1724,7,1724,2,1725,7,1725,2,1726,7,1726,
	2,1727,7,1727,2,1728,7,1728,2,1729,7,1729,2,1730,7,1730,2,1731,7,1731,2,
	1732,7,1732,2,1733,7,1733,2,1734,7,1734,2,1735,7,1735,2,1736,7,1736,2,1737,
	7,1737,2,1738,7,1738,2,1739,7,1739,2,1740,7,1740,2,1741,7,1741,2,1742,7,
	1742,2,1743,7,1743,2,1744,7,1744,2,1745,7,1745,2,1746,7,1746,2,1747,7,1747,
	2,1748,7,1748,2,1749,7,1749,2,1750,7,1750,2,1751,7,1751,2,1752,7,1752,2,
	1753,7,1753,2,1754,7,1754,2,1755,7,1755,2,1756,7,1756,2,1757,7,1757,2,1758,
	7,1758,2,1759,7,1759,2,1760,7,1760,2,1761,7,1761,2,1762,7,1762,2,1763,7,
	1763,2,1764,7,1764,2,1765,7,1765,2,1766,7,1766,2,1767,7,1767,2,1768,7,1768,
	2,1769,7,1769,2,1770,7,1770,2,1771,7,1771,2,1772,7,1772,2,1773,7,1773,2,
	1774,7,1774,2,1775,7,1775,2,1776,7,1776,2,1777,7,1777,2,1778,7,1778,2,1779,
	7,1779,2,1780,7,1780,2,1781,7,1781,2,1782,7,1782,2,1783,7,1783,2,1784,7,
	1784,2,1785,7,1785,2,1786,7,1786,2,1787,7,1787,2,1788,7,1788,2,1789,7,1789,
	2,1790,7,1790,2,1791,7,1791,2,1792,7,1792,2,1793,7,1793,2,1794,7,1794,2,
	1795,7,1795,2,1796,7,1796,2,1797,7,1797,2,1798,7,1798,2,1799,7,1799,2,1800,
	7,1800,2,1801,7,1801,2,1802,7,1802,2,1803,7,1803,2,1804,7,1804,2,1805,7,
	1805,2,1806,7,1806,2,1807,7,1807,2,1808,7,1808,2,1809,7,1809,2,1810,7,1810,
	2,1811,7,1811,2,1812,7,1812,2,1813,7,1813,2,1814,7,1814,2,1815,7,1815,2,
	1816,7,1816,2,1817,7,1817,2,1818,7,1818,2,1819,7,1819,2,1820,7,1820,2,1821,
	7,1821,2,1822,7,1822,2,1823,7,1823,2,1824,7,1824,2,1825,7,1825,2,1826,7,
	1826,2,1827,7,1827,2,1828,7,1828,2,1829,7,1829,2,1830,7,1830,2,1831,7,1831,
	2,1832,7,1832,2,1833,7,1833,2,1834,7,1834,2,1835,7,1835,2,1836,7,1836,2,
	1837,7,1837,2,1838,7,1838,2,1839,7,1839,2,1840,7,1840,2,1841,7,1841,2,1842,
	7,1842,2,1843,7,1843,2,1844,7,1844,2,1845,7,1845,2,1846,7,1846,2,1847,7,
	1847,2,1848,7,1848,2,1849,7,1849,2,1850,7,1850,2,1851,7,1851,2,1852,7,1852,
	2,1853,7,1853,2,1854,7,1854,2,1855,7,1855,2,1856,7,1856,2,1857,7,1857,2,
	1858,7,1858,2,1859,7,1859,2,1860,7,1860,2,1861,7,1861,2,1862,7,1862,2,1863,
	7,1863,2,1864,7,1864,2,1865,7,1865,2,1866,7,1866,2,1867,7,1867,2,1868,7,
	1868,2,1869,7,1869,2,1870,7,1870,2,1871,7,1871,2,1872,7,1872,2,1873,7,1873,
	2,1874,7,1874,2,1875,7,1875,2,1876,7,1876,2,1877,7,1877,2,1878,7,1878,2,
	1879,7,1879,2,1880,7,1880,2,1881,7,1881,2,1882,7,1882,2,1883,7,1883,2,1884,
	7,1884,2,1885,7,1885,2,1886,7,1886,2,1887,7,1887,2,1888,7,1888,2,1889,7,
	1889,2,1890,7,1890,2,1891,7,1891,2,1892,7,1892,2,1893,7,1893,2,1894,7,1894,
	2,1895,7,1895,2,1896,7,1896,2,1897,7,1897,2,1898,7,1898,2,1899,7,1899,2,
	1900,7,1900,2,1901,7,1901,2,1902,7,1902,2,1903,7,1903,2,1904,7,1904,2,1905,
	7,1905,2,1906,7,1906,2,1907,7,1907,2,1908,7,1908,2,1909,7,1909,2,1910,7,
	1910,2,1911,7,1911,2,1912,7,1912,2,1913,7,1913,2,1914,7,1914,2,1915,7,1915,
	2,1916,7,1916,2,1917,7,1917,2,1918,7,1918,2,1919,7,1919,2,1920,7,1920,2,
	1921,7,1921,2,1922,7,1922,2,1923,7,1923,2,1924,7,1924,2,1925,7,1925,2,1926,
	7,1926,2,1927,7,1927,2,1928,7,1928,2,1929,7,1929,2,1930,7,1930,2,1931,7,
	1931,2,1932,7,1932,2,1933,7,1933,2,1934,7,1934,2,1935,7,1935,2,1936,7,1936,
	2,1937,7,1937,2,1938,7,1938,2,1939,7,1939,2,1940,7,1940,2,1941,7,1941,2,
	1942,7,1942,2,1943,7,1943,2,1944,7,1944,2,1945,7,1945,2,1946,7,1946,2,1947,
	7,1947,2,1948,7,1948,2,1949,7,1949,2,1950,7,1950,2,1951,7,1951,2,1952,7,
	1952,2,1953,7,1953,2,1954,7,1954,2,1955,7,1955,2,1956,7,1956,2,1957,7,1957,
	2,1958,7,1958,2,1959,7,1959,2,1960,7,1960,2,1961,7,1961,2,1962,7,1962,2,
	1963,7,1963,2,1964,7,1964,2,1965,7,1965,2,1966,7,1966,2,1967,7,1967,2,1968,
	7,1968,2,1969,7,1969,2,1970,7,1970,2,1971,7,1971,2,1972,7,1972,2,1973,7,
	1973,2,1974,7,1974,2,1975,7,1975,2,1976,7,1976,2,1977,7,1977,2,1978,7,1978,
	2,1979,7,1979,2,1980,7,1980,2,1981,7,1981,2,1982,7,1982,2,1983,7,1983,2,
	1984,7,1984,2,1985,7,1985,2,1986,7,1986,2,1987,7,1987,2,1988,7,1988,2,1989,
	7,1989,2,1990,7,1990,2,1991,7,1991,2,1992,7,1992,2,1993,7,1993,2,1994,7,
	1994,2,1995,7,1995,2,1996,7,1996,2,1997,7,1997,2,1998,7,1998,2,1999,7,1999,
	2,2000,7,2000,2,2001,7,2001,2,2002,7,2002,2,2003,7,2003,2,2004,7,2004,2,
	2005,7,2005,2,2006,7,2006,2,2007,7,2007,2,2008,7,2008,2,2009,7,2009,2,2010,
	7,2010,2,2011,7,2011,2,2012,7,2012,2,2013,7,2013,2,2014,7,2014,2,2015,7,
	2015,2,2016,7,2016,2,2017,7,2017,2,2018,7,2018,2,2019,7,2019,2,2020,7,2020,
	2,2021,7,2021,2,2022,7,2022,2,2023,7,2023,2,2024,7,2024,2,2025,7,2025,2,
	2026,7,2026,2,2027,7,2027,2,2028,7,2028,2,2029,7,2029,2,2030,7,2030,2,2031,
	7,2031,2,2032,7,2032,2,2033,7,2033,2,2034,7,2034,2,2035,7,2035,2,2036,7,
	2036,2,2037,7,2037,2,2038,7,2038,2,2039,7,2039,2,2040,7,2040,2,2041,7,2041,
	2,2042,7,2042,2,2043,7,2043,2,2044,7,2044,2,2045,7,2045,2,2046,7,2046,2,
	2047,7,2047,2,2048,7,2048,2,2049,7,2049,2,2050,7,2050,2,2051,7,2051,2,2052,
	7,2052,2,2053,7,2053,2,2054,7,2054,2,2055,7,2055,2,2056,7,2056,2,2057,7,
	2057,2,2058,7,2058,2,2059,7,2059,2,2060,7,2060,2,2061,7,2061,2,2062,7,2062,
	2,2063,7,2063,2,2064,7,2064,2,2065,7,2065,2,2066,7,2066,2,2067,7,2067,2,
	2068,7,2068,2,2069,7,2069,2,2070,7,2070,2,2071,7,2071,2,2072,7,2072,2,2073,
	7,2073,2,2074,7,2074,2,2075,7,2075,2,2076,7,2076,2,2077,7,2077,2,2078,7,
	2078,2,2079,7,2079,2,2080,7,2080,2,2081,7,2081,2,2082,7,2082,2,2083,7,2083,
	2,2084,7,2084,2,2085,7,2085,2,2086,7,2086,2,2087,7,2087,2,2088,7,2088,2,
	2089,7,2089,2,2090,7,2090,2,2091,7,2091,2,2092,7,2092,2,2093,7,2093,2,2094,
	7,2094,2,2095,7,2095,2,2096,7,2096,2,2097,7,2097,2,2098,7,2098,2,2099,7,
	2099,2,2100,7,2100,2,2101,7,2101,2,2102,7,2102,2,2103,7,2103,2,2104,7,2104,
	2,2105,7,2105,2,2106,7,2106,2,2107,7,2107,2,2108,7,2108,2,2109,7,2109,2,
	2110,7,2110,2,2111,7,2111,2,2112,7,2112,2,2113,7,2113,2,2114,7,2114,2,2115,
	7,2115,2,2116,7,2116,2,2117,7,2117,2,2118,7,2118,2,2119,7,2119,2,2120,7,
	2120,2,2121,7,2121,2,2122,7,2122,2,2123,7,2123,2,2124,7,2124,2,2125,7,2125,
	2,2126,7,2126,2,2127,7,2127,2,2128,7,2128,2,2129,7,2129,2,2130,7,2130,2,
	2131,7,2131,2,2132,7,2132,2,2133,7,2133,2,2134,7,2134,2,2135,7,2135,2,2136,
	7,2136,2,2137,7,2137,2,2138,7,2138,2,2139,7,2139,2,2140,7,2140,2,2141,7,
	2141,2,2142,7,2142,2,2143,7,2143,2,2144,7,2144,2,2145,7,2145,2,2146,7,2146,
	2,2147,7,2147,2,2148,7,2148,2,2149,7,2149,2,2150,7,2150,2,2151,7,2151,2,
	2152,7,2152,2,2153,7,2153,2,2154,7,2154,2,2155,7,2155,2,2156,7,2156,2,2157,
	7,2157,2,2158,7,2158,2,2159,7,2159,2,2160,7,2160,2,2161,7,2161,2,2162,7,
	2162,2,2163,7,2163,2,2164,7,2164,2,2165,7,2165,2,2166,7,2166,2,2167,7,2167,
	2,2168,7,2168,2,2169,7,2169,2,2170,7,2170,2,2171,7,2171,2,2172,7,2172,2,
	2173,7,2173,2,2174,7,2174,2,2175,7,2175,2,2176,7,2176,2,2177,7,2177,2,2178,
	7,2178,2,2179,7,2179,2,2180,7,2180,2,2181,7,2181,2,2182,7,2182,2,2183,7,
	2183,2,2184,7,2184,2,2185,7,2185,2,2186,7,2186,2,2187,7,2187,2,2188,7,2188,
	2,2189,7,2189,2,2190,7,2190,2,2191,7,2191,2,2192,7,2192,2,2193,7,2193,2,
	2194,7,2194,2,2195,7,2195,2,2196,7,2196,2,2197,7,2197,2,2198,7,2198,2,2199,
	7,2199,2,2200,7,2200,2,2201,7,2201,2,2202,7,2202,2,2203,7,2203,2,2204,7,
	2204,2,2205,7,2205,2,2206,7,2206,2,2207,7,2207,2,2208,7,2208,2,2209,7,2209,
	2,2210,7,2210,2,2211,7,2211,2,2212,7,2212,2,2213,7,2213,2,2214,7,2214,2,
	2215,7,2215,2,2216,7,2216,2,2217,7,2217,2,2218,7,2218,2,2219,7,2219,2,2220,
	7,2220,2,2221,7,2221,2,2222,7,2222,2,2223,7,2223,2,2224,7,2224,2,2225,7,
	2225,2,2226,7,2226,2,2227,7,2227,2,2228,7,2228,2,2229,7,2229,2,2230,7,2230,
	2,2231,7,2231,2,2232,7,2232,2,2233,7,2233,2,2234,7,2234,2,2235,7,2235,2,
	2236,7,2236,2,2237,7,2237,2,2238,7,2238,2,2239,7,2239,2,2240,7,2240,2,2241,
	7,2241,2,2242,7,2242,2,2243,7,2243,2,2244,7,2244,2,2245,7,2245,2,2246,7,
	2246,2,2247,7,2247,2,2248,7,2248,2,2249,7,2249,2,2250,7,2250,2,2251,7,2251,
	2,2252,7,2252,2,2253,7,2253,2,2254,7,2254,2,2255,7,2255,2,2256,7,2256,2,
	2257,7,2257,2,2258,7,2258,2,2259,7,2259,2,2260,7,2260,2,2261,7,2261,2,2262,
	7,2262,2,2263,7,2263,2,2264,7,2264,2,2265,7,2265,2,2266,7,2266,2,2267,7,
	2267,2,2268,7,2268,2,2269,7,2269,2,2270,7,2270,2,2271,7,2271,2,2272,7,2272,
	2,2273,7,2273,2,2274,7,2274,2,2275,7,2275,2,2276,7,2276,2,2277,7,2277,2,
	2278,7,2278,2,2279,7,2279,2,2280,7,2280,2,2281,7,2281,2,2282,7,2282,2,2283,
	7,2283,2,2284,7,2284,2,2285,7,2285,2,2286,7,2286,2,2287,7,2287,2,2288,7,
	2288,2,2289,7,2289,2,2290,7,2290,2,2291,7,2291,2,2292,7,2292,2,2293,7,2293,
	2,2294,7,2294,2,2295,7,2295,2,2296,7,2296,2,2297,7,2297,2,2298,7,2298,2,
	2299,7,2299,2,2300,7,2300,2,2301,7,2301,2,2302,7,2302,2,2303,7,2303,2,2304,
	7,2304,2,2305,7,2305,2,2306,7,2306,2,2307,7,2307,2,2308,7,2308,2,2309,7,
	2309,2,2310,7,2310,2,2311,7,2311,2,2312,7,2312,2,2313,7,2313,2,2314,7,2314,
	2,2315,7,2315,2,2316,7,2316,2,2317,7,2317,2,2318,7,2318,2,2319,7,2319,2,
	2320,7,2320,2,2321,7,2321,2,2322,7,2322,2,2323,7,2323,2,2324,7,2324,2,2325,
	7,2325,2,2326,7,2326,2,2327,7,2327,2,2328,7,2328,2,2329,7,2329,2,2330,7,
	2330,2,2331,7,2331,2,2332,7,2332,2,2333,7,2333,2,2334,7,2334,2,2335,7,2335,
	2,2336,7,2336,2,2337,7,2337,2,2338,7,2338,2,2339,7,2339,2,2340,7,2340,2,
	2341,7,2341,2,2342,7,2342,2,2343,7,2343,2,2344,7,2344,2,2345,7,2345,2,2346,
	7,2346,2,2347,7,2347,2,2348,7,2348,2,2349,7,2349,2,2350,7,2350,2,2351,7,
	2351,2,2352,7,2352,2,2353,7,2353,2,2354,7,2354,2,2355,7,2355,2,2356,7,2356,
	2,2357,7,2357,2,2358,7,2358,2,2359,7,2359,2,2360,7,2360,2,2361,7,2361,2,
	2362,7,2362,2,2363,7,2363,2,2364,7,2364,2,2365,7,2365,2,2366,7,2366,2,2367,
	7,2367,2,2368,7,2368,2,2369,7,2369,1,0,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,
	1,1,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,3,1,3,1,3,1,3,1,3,1,3,1,3,1,4,1,4,1,4,
	1,4,1,4,1,4,1,4,1,4,1,4,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,6,1,6,1,6,1,6,
	1,7,1,7,1,7,1,7,1,7,1,8,1,8,1,8,1,8,1,8,1,8,1,8,1,9,1,9,1,9,1,9,1,9,1,9,
	1,9,1,10,1,10,1,10,1,10,1,10,1,10,1,10,1,10,1,11,1,11,1,11,1,11,1,11,1,
	11,1,11,1,11,1,11,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,13,1,13,1,13,1,13,
	1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,13,1,14,1,
	14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,14,1,15,1,15,1,15,1,15,
	1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,16,1,16,1,
	16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,17,1,17,1,17,1,17,1,17,1,17,
	1,17,1,17,1,17,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,
	18,1,18,1,18,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,20,1,20,1,20,
	1,20,1,20,1,20,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,22,1,
	22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,23,1,23,1,23,1,23,1,23,
	1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,24,1,24,1,24,1,25,1,25,1,25,1,25,1,
	25,1,25,1,25,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,
	1,26,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,27,1,27,1,27,1,27,1,27,1,
	27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,29,1,29,1,29,1,29,1,29,1,29,1,29,
	1,29,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,
	30,1,30,1,31,1,31,1,31,1,31,1,31,1,31,1,32,1,32,1,32,1,32,1,32,1,32,1,33,
	1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,34,1,34,1,35,1,35,1,35,1,
	35,1,35,1,35,1,36,1,36,1,36,1,36,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,
	1,37,1,38,1,38,1,38,1,38,1,38,1,38,1,39,1,39,1,39,1,39,1,39,1,39,1,39,1,
	39,1,39,1,40,1,40,1,40,1,40,1,40,1,40,1,41,1,41,1,41,1,41,1,41,1,41,1,41,
	1,41,1,41,1,41,1,42,1,42,1,42,1,42,1,42,1,42,1,42,1,43,1,43,1,43,1,43,1,
	43,1,43,1,43,1,43,1,43,1,44,1,44,1,44,1,44,1,44,1,44,1,44,1,44,1,45,1,45,
	1,45,1,45,1,45,1,45,1,45,1,45,1,45,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,
	46,1,46,1,46,1,47,1,47,1,47,1,47,1,48,1,48,1,48,1,48,1,48,1,48,1,48,1,48,
	1,48,1,48,1,49,1,49,1,49,1,49,1,49,1,49,1,49,1,49,1,50,1,50,1,50,1,50,1,
	50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,51,1,51,1,51,1,51,1,51,1,51,1,51,
	1,51,1,51,1,52,1,52,1,52,1,52,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,
	53,1,53,1,54,1,54,1,54,1,54,1,54,1,54,1,54,1,55,1,55,1,55,1,55,1,55,1,55,
	1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,56,1,
	56,1,56,1,56,1,56,1,56,1,56,1,56,1,56,1,56,1,57,1,57,1,57,1,57,1,57,1,57,
	1,57,1,57,1,57,1,57,1,57,1,57,1,58,1,58,1,58,1,58,1,58,1,58,1,59,1,59,1,
	59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,59,
	1,59,1,59,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,
	61,1,61,1,61,1,61,1,61,1,61,1,61,1,61,1,62,1,62,1,62,1,62,1,62,1,62,1,62,
	1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,64,1,
	64,1,64,1,64,1,64,1,64,1,65,1,65,1,65,1,66,1,66,1,66,1,66,1,67,1,67,1,67,
	1,67,1,67,1,67,1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,69,1,69,1,
	69,1,69,1,69,1,70,1,70,1,70,1,70,1,70,1,71,1,71,1,71,1,71,1,71,1,71,1,71,
	1,71,1,71,1,72,1,72,1,72,1,72,1,72,1,72,1,72,1,73,1,73,1,73,1,73,1,73,1,
	73,1,73,1,73,1,73,1,73,1,74,1,74,1,74,1,74,1,74,1,74,1,75,1,75,1,75,1,75,
	1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,75,1,76,1,76,1,76,1,76,1,76,1,
	76,1,77,1,77,1,77,1,77,1,77,1,78,1,78,1,78,1,79,1,79,1,79,1,79,1,79,1,79,
	1,79,1,79,1,79,1,79,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,80,1,
	80,1,81,1,81,1,81,1,81,1,81,1,81,1,82,1,82,1,82,1,82,1,82,1,82,1,82,1,82,
	1,82,1,82,1,82,1,82,1,82,1,82,1,83,1,83,1,83,1,83,1,83,1,83,1,83,1,83,1,
	83,1,83,1,83,1,83,1,83,1,83,1,83,1,84,1,84,1,84,1,84,1,84,1,84,1,84,1,85,
	1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,85,1,86,1,
	86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,87,1,87,1,87,
	1,87,1,87,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,89,1,
	89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,89,1,90,1,90,1,90,1,90,1,90,
	1,90,1,90,1,90,1,90,1,90,1,90,1,91,1,91,1,91,1,91,1,91,1,91,1,91,1,91,1,
	91,1,91,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,
	1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,92,1,93,1,93,1,93,1,93,1,
	93,1,93,1,93,1,93,1,93,1,93,1,93,1,93,1,93,1,93,1,93,1,93,1,94,1,94,1,94,
	1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,94,1,95,1,95,1,95,1,95,1,
	95,1,96,1,96,1,96,1,96,1,96,1,96,1,96,1,96,1,96,1,96,1,96,1,97,1,97,1,97,
	1,97,1,97,1,97,1,97,1,97,1,97,1,97,1,97,1,97,1,98,1,98,1,98,1,98,1,98,1,
	98,1,98,1,99,1,99,1,99,1,99,1,99,1,99,1,99,1,99,1,100,1,100,1,100,1,100,
	1,100,1,100,1,100,1,100,1,100,1,100,1,101,1,101,1,101,1,101,1,101,1,101,
	1,102,1,102,1,102,1,102,1,102,1,102,1,102,1,102,1,102,1,102,1,103,1,103,
	1,103,1,103,1,103,1,103,1,104,1,104,1,104,1,104,1,104,1,104,1,104,1,104,
	1,104,1,104,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,
	1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,1,105,
	1,105,1,105,1,105,1,105,1,105,1,105,1,106,1,106,1,106,1,106,1,106,1,106,
	1,106,1,107,1,107,1,107,1,107,1,107,1,107,1,107,1,108,1,108,1,108,1,108,
	1,108,1,108,1,109,1,109,1,109,1,109,1,109,1,109,1,109,1,109,1,109,1,109,
	1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,110,
	1,110,1,110,1,110,1,110,1,110,1,110,1,110,1,111,1,111,1,111,1,111,1,111,
	1,111,1,111,1,112,1,112,1,112,1,112,1,112,1,112,1,112,1,112,1,112,1,113,
	1,113,1,113,1,113,1,113,1,113,1,113,1,113,1,114,1,114,1,114,1,114,1,114,
	1,114,1,115,1,115,1,115,1,115,1,115,1,115,1,115,1,115,1,115,1,115,1,116,
	1,116,1,116,1,116,1,116,1,116,1,116,1,116,1,117,1,117,1,117,1,117,1,117,
	1,117,1,117,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,1,118,
	1,118,1,118,1,118,1,118,1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,119,
	1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,119,1,119,
	1,119,1,119,1,119,1,120,1,120,1,120,1,120,1,120,1,120,1,120,1,120,1,120,
	1,120,1,120,1,120,1,120,1,120,1,120,1,120,1,120,1,120,1,121,1,121,1,121,
	1,121,1,121,1,121,1,121,1,121,1,121,1,121,1,121,1,121,1,121,1,122,1,122,
	1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,122,
	1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,122,1,123,1,123,1,123,1,123,
	1,123,1,123,1,123,1,123,1,123,1,123,1,123,1,123,1,123,1,123,1,123,1,123,
	1,123,1,124,1,124,1,124,1,124,1,124,1,124,1,124,1,124,1,124,1,124,1,124,
	1,124,1,124,1,124,1,124,1,125,1,125,1,125,1,125,1,125,1,125,1,125,1,125,
	1,125,1,125,1,125,1,126,1,126,1,126,1,126,1,126,1,126,1,126,1,126,1,127,
	1,127,1,127,1,127,1,127,1,127,1,127,1,127,1,127,1,127,1,127,1,128,1,128,
	1,128,1,128,1,128,1,128,1,128,1,129,1,129,1,129,1,129,1,129,1,129,1,129,
	1,129,1,129,1,129,1,129,1,130,1,130,1,130,1,130,1,130,1,130,1,130,1,131,
	1,131,1,131,1,131,1,131,1,131,1,131,1,131,1,132,1,132,1,132,1,132,1,132,
	1,132,1,132,1,132,1,132,1,132,1,132,1,132,1,133,1,133,1,133,1,133,1,133,
	1,134,1,134,1,134,1,134,1,134,1,135,1,135,1,135,1,135,1,135,1,135,1,136,
	1,136,1,136,1,136,1,136,1,136,1,136,1,136,1,136,1,136,1,136,1,136,1,137,
	1,137,1,137,1,137,1,137,1,137,1,137,1,138,1,138,1,138,1,138,1,138,1,138,
	1,138,1,138,1,138,1,138,1,139,1,139,1,139,1,139,1,139,1,140,1,140,1,140,
	1,140,1,140,1,140,1,140,1,140,1,141,1,141,1,141,1,141,1,141,1,142,1,142,
	1,142,1,142,1,142,1,142,1,143,1,143,1,143,1,143,1,143,1,143,1,143,1,144,
	1,144,1,144,1,144,1,144,1,144,1,144,1,144,1,145,1,145,1,145,1,145,1,145,
	1,145,1,145,1,145,1,145,1,145,1,146,1,146,1,146,1,146,1,146,1,147,1,147,
	1,147,1,147,1,147,1,147,1,147,1,148,1,148,1,148,1,148,1,148,1,148,1,148,
	1,148,1,148,1,148,1,148,1,148,1,148,1,149,1,149,1,149,1,149,1,149,1,149,
	1,149,1,149,1,149,1,149,1,149,1,149,1,150,1,150,1,150,1,150,1,150,1,150,
	1,151,1,151,1,151,1,151,1,151,1,152,1,152,1,152,1,153,1,153,1,153,1,153,
	1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,153,
	1,153,1,153,1,153,1,153,1,153,1,153,1,153,1,154,1,154,1,154,1,154,1,154,
	1,154,1,154,1,154,1,154,1,154,1,154,1,154,1,155,1,155,1,155,1,155,1,155,
	1,156,1,156,1,156,1,156,1,156,1,156,1,157,1,157,1,157,1,157,1,157,1,157,
	1,157,1,157,1,157,1,158,1,158,1,158,1,158,1,158,1,158,1,158,1,158,1,158,
	1,158,1,158,1,158,1,158,1,158,1,158,1,158,1,159,1,159,1,159,1,159,1,159,
	1,159,1,159,1,159,1,159,1,159,1,159,1,159,1,159,1,159,1,159,1,159,1,159,
	1,160,1,160,1,160,1,160,1,160,1,160,1,160,1,160,1,161,1,161,1,161,1,161,
	1,161,1,161,1,161,1,161,1,161,1,161,1,161,1,162,1,162,1,162,1,162,1,162,
	1,162,1,162,1,162,1,162,1,163,1,163,1,163,1,163,1,163,1,164,1,164,1,164,
	1,164,1,164,1,164,1,164,1,165,1,165,1,165,1,165,1,165,1,165,1,165,1,165,
	1,165,1,165,1,166,1,166,1,166,1,166,1,166,1,166,1,166,1,166,1,166,1,167,
	1,167,1,167,1,167,1,167,1,167,1,167,1,167,1,168,1,168,1,168,1,168,1,168,
	1,168,1,168,1,168,1,168,1,168,1,168,1,168,1,169,1,169,1,169,1,169,1,169,
	1,169,1,169,1,169,1,170,1,170,1,170,1,170,1,170,1,171,1,171,1,171,1,171,
	1,171,1,172,1,172,1,172,1,172,1,172,1,172,1,172,1,172,1,172,1,172,1,172,
	1,172,1,172,1,172,1,172,1,173,1,173,1,173,1,173,1,173,1,173,1,173,1,173,
	1,173,1,174,1,174,1,174,1,174,1,174,1,174,1,174,1,174,1,174,1,174,1,174,
	1,174,1,175,1,175,1,175,1,175,1,175,1,176,1,176,1,176,1,176,1,176,1,176,
	1,176,1,176,1,176,1,176,1,176,1,176,1,176,1,176,1,176,1,176,1,176,1,177,
	1,177,1,177,1,177,1,177,1,177,1,177,1,177,1,177,1,177,1,177,1,177,1,178,
	1,178,1,178,1,178,1,178,1,178,1,179,1,179,1,179,1,179,1,179,1,179,1,179,
	1,179,1,180,1,180,1,180,1,180,1,180,1,180,1,180,1,181,1,181,1,181,1,181,
	1,181,1,181,1,181,1,181,1,181,1,181,1,181,1,181,1,181,1,181,1,181,1,182,
	1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,
	1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,1,182,
	1,182,1,183,1,183,1,183,1,183,1,183,1,183,1,183,1,183,1,183,1,183,1,184,
	1,184,1,184,1,184,1,184,1,185,1,185,1,185,1,185,1,185,1,185,1,185,1,185,
	1,186,1,186,1,186,1,186,1,186,1,186,1,186,1,186,1,186,1,186,1,186,1,186,
	1,187,1,187,1,187,1,187,1,187,1,187,1,187,1,187,1,187,1,187,1,187,1,187,
	1,187,1,187,1,187,1,187,1,187,1,187,1,188,1,188,1,188,1,188,1,188,1,188,
	1,189,1,189,1,189,1,189,1,189,1,189,1,189,1,189,1,189,1,189,1,189,1,190,
	1,190,1,190,1,190,1,190,1,190,1,191,1,191,1,191,1,191,1,191,1,191,1,191,
	1,192,1,192,1,192,1,192,1,193,1,193,1,193,1,193,1,193,1,193,1,194,1,194,
	1,194,1,194,1,194,1,194,1,195,1,195,1,195,1,195,1,195,1,195,1,195,1,195,
	1,195,1,195,1,195,1,195,1,195,1,195,1,195,1,196,1,196,1,196,1,196,1,196,
	1,196,1,196,1,196,1,196,1,196,1,196,1,197,1,197,1,197,1,197,1,197,1,197,
	1,197,1,198,1,198,1,198,1,198,1,198,1,198,1,199,1,199,1,199,1,199,1,199,
	1,199,1,199,1,199,1,200,1,200,1,200,1,200,1,200,1,200,1,201,1,201,1,202,
	1,202,1,202,1,202,1,202,1,202,1,202,1,203,1,203,1,203,1,203,1,203,1,204,
	1,204,1,204,1,204,1,204,1,204,1,205,1,205,1,205,1,205,1,205,1,205,1,205,
	1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,205,
	1,205,1,205,1,205,1,205,1,205,1,205,1,205,1,206,1,206,1,206,1,206,1,206,
	1,206,1,207,1,207,1,207,1,207,1,207,1,207,1,207,1,207,1,207,1,207,1,207,
	1,207,1,207,1,207,1,207,1,207,1,207,1,208,1,208,1,208,1,208,1,208,1,208,
	1,208,1,208,1,209,1,209,1,209,1,209,1,209,1,209,1,209,1,209,1,209,1,209,
	1,209,1,209,1,209,1,209,1,209,1,209,1,210,1,210,1,210,1,210,1,210,1,210,
	1,210,1,210,1,210,1,210,1,210,1,210,1,210,1,210,1,210,1,210,1,210,1,211,
	1,211,1,211,1,211,1,211,1,211,1,211,1,211,1,211,1,211,1,211,1,212,1,212,
	1,212,1,212,1,212,1,212,1,212,1,212,1,212,1,212,1,212,1,213,1,213,1,213,
	1,213,1,213,1,213,1,213,1,213,1,213,1,213,1,213,1,213,1,213,1,213,1,213,
	1,213,1,213,1,213,1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,214,
	1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,214,1,215,
	1,215,1,215,1,215,1,215,1,215,1,215,1,215,1,215,1,215,1,215,1,215,1,216,
	1,216,1,216,1,216,1,216,1,216,1,216,1,216,1,216,1,217,1,217,1,217,1,217,
	1,217,1,217,1,217,1,217,1,217,1,217,1,217,1,217,1,218,1,218,1,218,1,218,
	1,218,1,218,1,218,1,219,1,219,1,219,1,219,1,219,1,219,1,219,1,219,1,219,
	1,219,1,219,1,219,1,220,1,220,1,220,1,220,1,220,1,221,1,221,1,221,1,221,
	1,221,1,221,1,221,1,221,1,222,1,222,1,222,1,222,1,222,1,222,1,222,1,222,
	1,222,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,
	1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,223,1,224,
	1,224,1,224,1,224,1,224,1,224,1,224,1,225,1,225,1,225,1,225,1,225,1,225,
	1,225,1,225,1,226,1,226,1,226,1,226,1,226,1,226,1,226,1,226,1,226,1,226,
	1,226,1,226,1,226,1,227,1,227,1,227,1,227,1,227,1,227,1,227,1,227,1,227,
	1,227,1,227,1,227,1,227,1,228,1,228,1,228,1,228,1,228,1,228,1,228,1,228,
	1,229,1,229,1,229,1,229,1,229,1,229,1,229,1,230,1,230,1,230,1,230,1,230,
	1,230,1,230,1,230,1,230,1,230,1,231,1,231,1,231,1,231,1,231,1,231,1,231,
	1,232,1,232,1,232,1,232,1,232,1,232,1,232,1,232,1,232,1,232,1,232,1,232,
	1,233,1,233,1,233,1,233,1,233,1,233,1,233,1,233,1,234,1,234,1,234,1,234,
	1,234,1,234,1,234,1,234,1,234,1,234,1,234,1,234,1,234,1,234,1,235,1,235,
	1,235,1,235,1,235,1,235,1,235,1,235,1,236,1,236,1,236,1,236,1,236,1,236,
	1,236,1,236,1,236,1,237,1,237,1,237,1,237,1,237,1,237,1,237,1,237,1,237,
	1,237,1,237,1,238,1,238,1,238,1,238,1,238,1,238,1,238,1,238,1,238,1,238,
	1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,239,1,240,
	1,240,1,240,1,240,1,240,1,240,1,240,1,240,1,241,1,241,1,241,1,241,1,241,
	1,241,1,241,1,241,1,241,1,241,1,242,1,242,1,242,1,242,1,242,1,242,1,242,
	1,242,1,242,1,242,1,242,1,242,1,242,1,242,1,242,1,242,1,243,1,243,1,243,
	1,243,1,243,1,243,1,243,1,243,1,243,1,244,1,244,1,244,1,244,1,244,1,244,
	1,244,1,244,1,244,1,245,1,245,1,245,1,245,1,245,1,245,1,245,1,245,1,246,
	1,246,1,246,1,246,1,246,1,246,1,246,1,247,1,247,1,247,1,247,1,247,1,247,
	1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,247,1,248,1,248,1,248,
	1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,248,1,249,1,249,1,249,
	1,249,1,249,1,249,1,249,1,249,1,249,1,249,1,250,1,250,1,250,1,250,1,250,
	1,250,1,250,1,250,1,251,1,251,1,251,1,251,1,251,1,251,1,251,1,251,1,251,
	1,251,1,251,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,1,252,
	1,252,1,252,1,252,1,252,1,252,1,253,1,253,1,253,1,253,1,253,1,253,1,253,
	1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,1,254,
	1,254,1,254,1,254,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,
	1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,1,255,
	1,255,1,255,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,
	1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,1,256,
	1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,
	1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,257,1,258,1,258,
	1,258,1,258,1,258,1,258,1,258,1,258,1,258,1,258,1,258,1,258,1,258,1,258,
	1,258,1,258,1,258,1,258,1,258,1,258,1,258,1,259,1,259,1,259,1,259,1,259,
	1,259,1,259,1,259,1,259,1,259,1,259,1,259,1,259,1,259,1,259,1,259,1,259,
	1,259,1,259,1,259,1,259,1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,260,
	1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,260,1,261,
	1,261,1,261,1,261,1,261,1,261,1,261,1,261,1,261,1,261,1,261,1,261,1,261,
	1,261,1,261,1,261,1,261,1,261,1,262,1,262,1,262,1,262,1,262,1,262,1,262,
	1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,262,1,263,1,263,1,263,
	1,263,1,263,1,263,1,263,1,263,1,264,1,264,1,264,1,264,1,264,1,264,1,264,
	1,264,1,264,1,264,1,264,1,264,1,264,1,265,1,265,1,265,1,265,1,265,1,265,
	1,265,1,265,1,265,1,266,1,266,1,266,1,266,1,266,1,266,1,266,1,266,1,266,
	1,266,1,266,1,267,1,267,1,267,1,267,1,267,1,267,1,267,1,267,1,267,1,268,
	1,268,1,268,1,268,1,268,1,268,1,269,1,269,1,269,1,269,1,269,1,269,1,269,
	1,269,1,269,1,269,1,269,1,270,1,270,1,270,1,270,1,270,1,270,1,270,1,270,
	1,270,1,270,1,270,1,270,1,271,1,271,1,271,1,271,1,271,1,271,1,271,1,271,
	1,271,1,271,1,271,1,271,1,272,1,272,1,272,1,272,1,272,1,272,1,272,1,272,
	1,272,1,272,1,273,1,273,1,273,1,273,1,273,1,273,1,273,1,273,1,273,1,273,
	1,273,1,274,1,274,1,274,1,274,1,274,1,274,1,274,1,274,1,274,1,274,1,274,
	1,274,1,274,1,274,1,274,1,274,1,274,1,274,1,274,1,275,1,275,1,275,1,275,
	1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,275,1,276,
	1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,1,276,
	1,276,1,277,1,277,1,277,1,277,1,277,1,277,1,277,1,277,1,278,1,278,1,278,
	1,278,1,278,1,278,1,278,1,278,1,278,1,279,1,279,1,279,1,279,1,279,1,279,
	1,279,1,279,1,280,1,280,1,280,1,280,1,280,1,280,1,280,1,280,1,280,1,281,
	1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,281,1,282,
	1,282,1,282,1,282,1,282,1,282,1,282,1,282,1,282,1,282,1,282,1,282,1,282,
	1,282,1,283,1,283,1,283,1,283,1,283,1,283,1,283,1,283,1,284,1,284,1,284,
	1,284,1,284,1,284,1,284,1,284,1,284,1,284,1,284,1,285,1,285,1,285,1,285,
	1,285,1,285,1,285,1,286,1,286,1,286,1,286,1,286,1,287,1,287,1,287,1,287,
	1,287,1,287,1,287,1,288,1,288,1,288,1,288,1,288,1,288,1,288,1,289,1,289,
	1,289,1,289,1,289,1,289,1,289,1,289,1,289,1,289,1,289,1,290,1,290,1,290,
	1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,290,1,290,
	1,290,1,291,1,291,1,291,1,291,1,291,1,291,1,291,1,291,1,291,1,291,1,291,
	1,291,1,292,1,292,1,292,1,292,1,293,1,293,1,293,1,293,1,293,1,294,1,294,
	1,294,1,294,1,294,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,
	1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,1,295,
	1,295,1,295,1,296,1,296,1,296,1,296,1,296,1,296,1,297,1,297,1,297,1,297,
	1,297,1,297,1,297,1,297,1,297,1,297,1,298,1,298,1,298,1,298,1,298,1,298,
	1,298,1,298,1,298,1,298,1,298,1,299,1,299,1,299,1,299,1,299,1,299,1,299,
	1,299,1,299,1,299,1,299,1,299,1,300,1,300,1,300,1,300,1,300,1,300,1,300,
	1,300,1,300,1,300,1,300,1,300,1,300,1,301,1,301,1,301,1,301,1,301,1,301,
	1,301,1,301,1,301,1,301,1,301,1,301,1,301,1,301,1,301,1,301,1,302,1,302,
	1,302,1,302,1,302,1,302,1,303,1,303,1,303,1,303,1,303,1,303,1,303,1,304,
	1,304,1,304,1,304,1,304,1,304,1,304,1,304,1,304,1,304,1,304,1,304,1,304,
	1,304,1,304,1,304,1,304,1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,305,
	1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,305,1,305,
	1,305,1,305,1,305,1,306,1,306,1,306,1,306,1,306,1,306,1,306,1,306,1,306,
	1,307,1,307,1,307,1,307,1,307,1,307,1,307,1,307,1,307,1,307,1,307,1,308,
	1,308,1,308,1,308,1,308,1,308,1,308,1,308,1,308,1,309,1,309,1,309,1,309,
	1,309,1,309,1,310,1,310,1,310,1,310,1,310,1,310,1,310,1,310,1,310,1,310,
	1,310,1,310,1,310,1,311,1,311,1,311,1,311,1,311,1,311,1,311,1,311,1,311,
	1,311,1,312,1,312,1,312,1,312,1,312,1,312,1,312,1,312,1,313,1,313,1,313,
	1,313,1,313,1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,314,1,315,1,315,
	1,315,1,315,1,315,1,315,1,315,1,315,1,316,1,316,1,316,1,316,1,316,1,316,
	1,316,1,316,1,316,1,316,1,316,1,317,1,317,1,317,1,317,1,317,1,317,1,317,
	1,317,1,318,1,318,1,318,1,318,1,318,1,318,1,318,1,318,1,318,1,318,1,318,
	1,318,1,318,1,319,1,319,1,319,1,319,1,319,1,319,1,319,1,319,1,319,1,319,
	1,319,1,319,1,319,1,319,1,319,1,320,1,320,1,320,1,320,1,320,1,320,1,320,
	1,320,1,320,1,320,1,320,1,320,1,320,1,321,1,321,1,321,1,321,1,321,1,321,
	1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,1,321,
	1,322,1,322,1,322,1,322,1,322,1,322,1,322,1,322,1,322,1,322,1,322,1,322,
	1,322,1,323,1,323,1,323,1,323,1,323,1,323,1,323,1,323,1,323,1,324,1,324,
	1,324,1,324,1,324,1,324,1,324,1,325,1,325,1,325,1,325,1,325,1,325,1,325,
	1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,1,325,
	1,325,1,325,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,
	1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,1,326,
	1,326,1,326,1,327,1,327,1,327,1,327,1,327,1,327,1,327,1,327,1,327,1,327,
	1,327,1,327,1,328,1,328,1,328,1,329,1,329,1,329,1,329,1,329,1,329,1,330,
	1,330,1,330,1,330,1,330,1,330,1,330,1,330,1,330,1,331,1,331,1,331,1,331,
	1,331,1,331,1,331,1,331,1,331,1,332,1,332,1,332,1,332,1,332,1,333,1,333,
	1,333,1,333,1,333,1,333,1,333,1,333,1,333,1,334,1,334,1,334,1,334,1,334,
	1,334,1,334,1,334,1,334,1,334,1,335,1,335,1,335,1,335,1,335,1,335,1,335,
	1,335,1,335,1,335,1,335,1,335,1,335,1,335,1,335,1,335,1,336,1,336,1,336,
	1,336,1,336,1,336,1,336,1,336,1,336,1,336,1,336,1,336,1,336,1,337,1,337,
	1,337,1,337,1,337,1,337,1,337,1,337,1,337,1,337,1,338,1,338,1,338,1,338,
	1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,
	1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,338,1,339,1,339,1,339,
	1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,1,339,
	1,339,1,339,1,339,1,339,1,339,1,339,1,340,1,340,1,340,1,340,1,340,1,340,
	1,340,1,340,1,340,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,
	1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,341,
	1,341,1,341,1,341,1,341,1,341,1,341,1,341,1,342,1,342,1,342,1,342,1,342,
	1,343,1,343,1,343,1,343,1,343,1,343,1,343,1,343,1,343,1,343,1,344,1,344,
	1,344,1,344,1,345,1,345,1,345,1,345,1,345,1,346,1,346,1,346,1,346,1,347,
	1,347,1,347,1,347,1,347,1,347,1,347,1,347,1,347,1,347,1,347,1,347,1,347,
	1,347,1,347,1,348,1,348,1,348,1,348,1,348,1,348,1,348,1,348,1,348,1,348,
	1,348,1,349,1,349,1,349,1,349,1,349,1,349,1,349,1,349,1,349,1,349,1,349,
	1,349,1,349,1,349,1,349,1,350,1,350,1,350,1,350,1,350,1,350,1,350,1,350,
	1,350,1,350,1,350,1,351,1,351,1,351,1,351,1,351,1,351,1,351,1,351,1,351,
	1,351,1,351,1,351,1,351,1,351,1,351,1,352,1,352,1,352,1,352,1,352,1,352,
	1,352,1,352,1,352,1,352,1,352,1,353,1,353,1,353,1,353,1,354,1,354,1,354,
	1,354,1,354,1,354,1,354,1,354,1,354,1,354,1,354,1,355,1,355,1,355,1,355,
	1,355,1,355,1,356,1,356,1,356,1,356,1,356,1,356,1,356,1,356,1,356,1,357,
	1,357,1,357,1,357,1,358,1,358,1,358,1,358,1,358,1,358,1,358,1,358,1,359,
	1,359,1,359,1,359,1,359,1,359,1,359,1,359,1,360,1,360,1,360,1,360,1,360,
	1,360,1,360,1,360,1,360,1,360,1,361,1,361,1,361,1,361,1,361,1,361,1,361,
	1,361,1,361,1,361,1,361,1,361,1,362,1,362,1,362,1,362,1,362,1,363,1,363,
	1,363,1,363,1,363,1,363,1,363,1,363,1,363,1,363,1,364,1,364,1,364,1,364,
	1,364,1,364,1,364,1,364,1,365,1,365,1,365,1,365,1,365,1,365,1,365,1,365,
	1,365,1,365,1,365,1,365,1,366,1,366,1,366,1,366,1,366,1,366,1,366,1,366,
	1,367,1,367,1,367,1,367,1,367,1,367,1,367,1,367,1,367,1,368,1,368,1,368,
	1,368,1,368,1,368,1,368,1,368,1,368,1,368,1,368,1,368,1,368,1,368,1,368,
	1,368,1,368,1,368,1,369,1,369,1,369,1,369,1,369,1,369,1,369,1,369,1,369,
	1,369,1,369,1,369,1,369,1,369,1,369,1,369,1,369,1,369,1,369,1,370,1,370,
	1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,370,1,371,1,371,1,371,
	1,371,1,371,1,371,1,371,1,371,1,371,1,372,1,372,1,372,1,372,1,372,1,372,
	1,372,1,372,1,373,1,373,1,373,1,373,1,373,1,373,1,373,1,374,1,374,1,374,
	1,374,1,374,1,374,1,374,1,374,1,375,1,375,1,375,1,375,1,375,1,375,1,375,
	1,376,1,376,1,376,1,376,1,376,1,376,1,377,1,377,1,377,1,377,1,377,1,377,
	1,377,1,377,1,377,1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,1,378,
	1,378,1,378,1,379,1,379,1,379,1,379,1,379,1,379,1,379,1,380,1,380,1,380,
	1,380,1,380,1,380,1,380,1,380,1,380,1,380,1,381,1,381,1,381,1,381,1,381,
	1,381,1,381,1,382,1,382,1,382,1,382,1,382,1,382,1,382,1,382,1,382,1,382,
	1,382,1,382,1,383,1,383,1,383,1,383,1,383,1,383,1,383,1,383,1,383,1,383,
	1,384,1,384,1,384,1,384,1,384,1,384,1,385,1,385,1,385,1,385,1,385,1,385,
	1,385,1,385,1,386,1,386,1,386,1,386,1,386,1,386,1,387,1,387,1,387,1,387,
	1,387,1,387,1,387,1,387,1,387,1,387,1,387,1,387,1,387,1,387,1,387,1,387,
	1,387,1,388,1,388,1,388,1,388,1,388,1,389,1,389,1,389,1,389,1,389,1,389,
	1,389,1,389,1,389,1,389,1,389,1,389,1,390,1,390,1,390,1,390,1,390,1,390,
	1,390,1,390,1,391,1,391,1,391,1,391,1,391,1,391,1,391,1,391,1,391,1,392,
	1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,392,1,393,1,393,
	1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,1,393,
	1,394,1,394,1,394,1,394,1,394,1,394,1,394,1,394,1,394,1,394,1,394,1,395,
	1,395,1,395,1,395,1,395,1,395,1,395,1,395,1,395,1,395,1,396,1,396,1,396,
	1,396,1,396,1,396,1,396,1,396,1,396,1,396,1,396,1,397,1,397,1,397,1,397,
	1,397,1,397,1,397,1,397,1,397,1,397,1,397,1,397,1,398,1,398,1,398,1,398,
	1,398,1,398,1,398,1,398,1,398,1,398,1,399,1,399,1,399,1,399,1,399,1,399,
	1,399,1,399,1,399,1,399,1,399,1,399,1,400,1,400,1,400,1,400,1,400,1,400,
	1,400,1,400,1,400,1,400,1,400,1,400,1,401,1,401,1,401,1,401,1,401,1,401,
	1,401,1,401,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,
	1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,402,1,403,
	1,403,1,403,1,403,1,403,1,403,1,403,1,403,1,403,1,403,1,403,1,403,1,403,
	1,403,1,403,1,404,1,404,1,404,1,404,1,404,1,404,1,404,1,404,1,404,1,404,
	1,404,1,404,1,404,1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,405,1,405,
	1,406,1,406,1,406,1,406,1,406,1,406,1,406,1,406,1,406,1,406,1,406,1,406,
	1,406,1,407,1,407,1,407,1,407,1,407,1,407,1,407,1,407,1,408,1,408,1,408,
	1,408,1,408,1,408,1,408,1,408,1,408,1,408,1,408,1,409,1,409,1,409,1,409,
	1,409,1,410,1,410,1,410,1,410,1,410,1,410,1,410,1,410,1,410,1,410,1,411,
	1,411,1,411,1,411,1,411,1,411,1,411,1,411,1,411,1,411,1,411,1,411,1,411,
	1,412,1,412,1,412,1,412,1,412,1,412,1,413,1,413,1,413,1,413,1,413,1,413,
	1,413,1,413,1,413,1,414,1,414,1,414,1,414,1,414,1,414,1,414,1,414,1,414,
	1,415,1,415,1,415,1,415,1,415,1,415,1,415,1,415,1,415,1,415,1,415,1,415,
	1,415,1,415,1,416,1,416,1,416,1,416,1,416,1,416,1,416,1,416,1,416,1,416,
	1,416,1,416,1,417,1,417,1,417,1,417,1,417,1,417,1,417,1,417,1,417,1,417,
	1,417,1,418,1,418,1,418,1,418,1,419,1,419,1,419,1,419,1,419,1,419,1,419,
	1,419,1,419,1,419,1,419,1,420,1,420,1,420,1,420,1,420,1,420,1,420,1,420,
	1,420,1,420,1,420,1,420,1,421,1,421,1,421,1,421,1,421,1,421,1,421,1,421,
	1,421,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,
	1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,422,1,423,1,423,1,423,
	1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,1,423,
	1,423,1,423,1,423,1,423,1,423,1,423,1,424,1,424,1,424,1,424,1,424,1,424,
	1,424,1,424,1,424,1,424,1,424,1,424,1,424,1,424,1,424,1,424,1,424,1,424,
	1,425,1,425,1,425,1,425,1,425,1,425,1,425,1,426,1,426,1,426,1,426,1,426,
	1,426,1,426,1,426,1,426,1,426,1,427,1,427,1,427,1,427,1,427,1,427,1,427,
	1,427,1,427,1,427,1,427,1,427,1,427,1,428,1,428,1,428,1,428,1,428,1,428,
	1,428,1,428,1,428,1,428,1,428,1,428,1,429,1,429,1,429,1,429,1,429,1,430,
	1,430,1,430,1,430,1,430,1,430,1,430,1,430,1,430,1,430,1,430,1,431,1,431,
	1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,
	1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,431,1,432,
	1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,
	1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,432,1,433,
	1,433,1,433,1,433,1,433,1,434,1,434,1,434,1,434,1,434,1,434,1,434,1,434,
	1,435,1,435,1,435,1,435,1,435,1,435,1,435,1,435,1,435,1,435,1,436,1,436,
	1,436,1,437,1,437,1,437,1,437,1,437,1,437,1,437,1,437,1,438,1,438,1,438,
	1,438,1,438,1,438,1,438,1,438,1,438,1,438,1,438,1,438,1,438,1,438,1,438,
	1,438,1,438,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,
	1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,1,439,
	1,439,1,439,1,439,1,440,1,440,1,441,1,441,1,441,1,441,1,441,1,442,1,442,
	1,442,1,442,1,442,1,442,1,442,1,442,1,442,1,442,1,442,1,442,1,443,1,443,
	1,443,1,443,1,443,1,443,1,443,1,443,1,444,1,444,1,444,1,444,1,444,1,444,
	1,444,1,444,1,444,1,444,1,444,1,445,1,445,1,445,1,445,1,445,1,445,1,445,
	1,445,1,445,1,446,1,446,1,446,1,446,1,446,1,446,1,446,1,446,1,447,1,447,
	1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,447,1,448,
	1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,1,448,
	1,448,1,448,1,449,1,449,1,449,1,449,1,449,1,449,1,449,1,449,1,449,1,449,
	1,449,1,449,1,449,1,449,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,
	1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,1,450,
	1,450,1,451,1,451,1,451,1,451,1,451,1,452,1,452,1,452,1,452,1,452,1,452,
	1,453,1,453,1,453,1,454,1,454,1,454,1,454,1,454,1,454,1,454,1,454,1,454,
	1,454,1,454,1,455,1,455,1,455,1,455,1,455,1,455,1,455,1,455,1,455,1,455,
	1,455,1,456,1,456,1,456,1,456,1,456,1,456,1,457,1,457,1,457,1,457,1,457,
	1,457,1,457,1,457,1,457,1,457,1,457,1,458,1,458,1,458,1,458,1,458,1,458,
	1,458,1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,459,
	1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,459,1,460,1,460,1,460,
	1,460,1,460,1,460,1,460,1,460,1,460,1,460,1,460,1,460,1,460,1,460,1,461,
	1,461,1,461,1,461,1,461,1,461,1,461,1,461,1,461,1,462,1,462,1,462,1,462,
	1,462,1,462,1,462,1,462,1,463,1,463,1,463,1,463,1,463,1,463,1,463,1,463,
	1,463,1,463,1,463,1,464,1,464,1,464,1,464,1,465,1,465,1,465,1,465,1,465,
	1,465,1,465,1,465,1,465,1,465,1,465,1,465,1,465,1,465,1,465,1,465,1,465,
	1,466,1,466,1,466,1,466,1,466,1,466,1,466,1,466,1,466,1,467,1,467,1,467,
	1,467,1,467,1,467,1,467,1,467,1,468,1,468,1,468,1,468,1,468,1,468,1,468,
	1,468,1,469,1,469,1,469,1,469,1,469,1,469,1,469,1,469,1,469,1,469,1,469,
	1,470,1,470,1,470,1,470,1,470,1,470,1,470,1,470,1,470,1,470,1,470,1,470,
	1,470,1,470,1,470,1,471,1,471,1,471,1,471,1,471,1,471,1,472,1,472,1,472,
	1,472,1,472,1,472,1,472,1,472,1,472,1,473,1,473,1,473,1,473,1,474,1,474,
	1,474,1,474,1,474,1,474,1,474,1,474,1,474,1,474,1,474,1,474,1,474,1,474,
	1,474,1,475,1,475,1,475,1,475,1,475,1,475,1,476,1,476,1,476,1,476,1,476,
	1,476,1,476,1,476,1,476,1,476,1,476,1,476,1,476,1,476,1,476,1,476,1,476,
	1,476,1,476,1,476,1,476,1,476,1,477,1,477,1,477,1,477,1,477,1,477,1,477,
	1,478,1,478,1,478,1,478,1,478,1,478,1,478,1,479,1,479,1,479,1,479,1,479,
	1,479,1,479,1,479,1,479,1,480,1,480,1,480,1,480,1,480,1,481,1,481,1,481,
	1,481,1,481,1,481,1,481,1,481,1,481,1,482,1,482,1,482,1,482,1,482,1,482,
	1,482,1,482,1,482,1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,1,483,
	1,483,1,483,1,484,1,484,1,484,1,484,1,484,1,484,1,484,1,485,1,485,1,485,
	1,485,1,485,1,485,1,486,1,486,1,486,1,486,1,486,1,486,1,486,1,487,1,487,
	1,487,1,487,1,487,1,487,1,487,1,487,1,487,1,487,1,488,1,488,1,488,1,488,
	1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,488,1,489,
	1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,489,1,490,1,490,
	1,490,1,490,1,490,1,490,1,490,1,490,1,490,1,491,1,491,1,491,1,491,1,491,
	1,491,1,491,1,491,1,492,1,492,1,492,1,492,1,492,1,492,1,492,1,492,1,492,
	1,492,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,493,1,494,
	1,494,1,494,1,494,1,494,1,494,1,494,1,494,1,495,1,495,1,495,1,495,1,495,
	1,495,1,495,1,496,1,496,1,496,1,496,1,496,1,496,1,496,1,496,1,496,1,497,
	1,497,1,497,1,497,1,497,1,497,1,497,1,498,1,498,1,498,1,498,1,498,1,498,
	1,498,1,498,1,498,1,498,1,498,1,499,1,499,1,499,1,499,1,499,1,500,1,500,
	1,500,1,500,1,500,1,500,1,500,1,500,1,500,1,500,1,500,1,500,1,500,1,500,
	1,500,1,500,1,500,1,500,1,500,1,500,1,500,1,501,1,501,1,501,1,501,1,501,
	1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,501,1,502,1,502,1,502,1,502,
	1,503,1,503,1,503,1,503,1,503,1,503,1,503,1,504,1,504,1,504,1,504,1,504,
	1,504,1,504,1,504,1,505,1,505,1,505,1,505,1,505,1,505,1,505,1,505,1,505,
	1,505,1,506,1,506,1,506,1,506,1,506,1,506,1,506,1,507,1,507,1,507,1,507,
	1,507,1,507,1,507,1,507,1,507,1,507,1,507,1,507,1,507,1,507,1,507,1,507,
	1,508,1,508,1,508,1,508,1,508,1,508,1,508,1,508,1,509,1,509,1,509,1,509,
	1,509,1,509,1,509,1,509,1,510,1,510,1,510,1,510,1,510,1,510,1,510,1,511,
	1,511,1,511,1,511,1,511,1,511,1,511,1,511,1,512,1,512,1,512,1,512,1,512,
	1,512,1,512,1,512,1,512,1,513,1,513,1,513,1,513,1,513,1,513,1,513,1,513,
	1,513,1,513,1,513,1,514,1,514,1,514,1,514,1,514,1,514,1,514,1,514,1,514,
	1,514,1,514,1,514,1,514,1,514,1,514,1,515,1,515,1,515,1,515,1,515,1,515,
	1,515,1,515,1,516,1,516,1,516,1,516,1,516,1,516,1,516,1,516,1,516,1,516,
	1,516,1,516,1,516,1,517,1,517,1,517,1,517,1,517,1,517,1,518,1,518,1,518,
	1,518,1,518,1,518,1,518,1,518,1,518,1,519,1,519,1,519,1,519,1,519,1,520,
	1,520,1,520,1,520,1,520,1,520,1,520,1,521,1,521,1,521,1,521,1,521,1,521,
	1,521,1,521,1,521,1,521,1,521,1,521,1,521,1,521,1,521,1,522,1,522,1,522,
	1,522,1,522,1,522,1,522,1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,
	1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,1,523,
	1,523,1,523,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,1,524,
	1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,525,1,526,1,526,1,526,
	1,526,1,526,1,526,1,526,1,526,1,527,1,527,1,527,1,527,1,527,1,527,1,528,
	1,528,1,528,1,528,1,528,1,528,1,528,1,529,1,529,1,529,1,529,1,530,1,530,
	1,530,1,530,1,530,1,531,1,531,1,531,1,531,1,531,1,531,1,531,1,531,1,531,
	1,531,1,532,1,532,1,532,1,532,1,532,1,532,1,532,1,532,1,533,1,533,1,533,
	1,533,1,533,1,533,1,533,1,533,1,534,1,534,1,534,1,534,1,534,1,534,1,534,
	1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,534,1,535,1,535,1,535,
	1,535,1,535,1,535,1,535,1,535,1,535,1,535,1,535,1,536,1,536,1,536,1,536,
	1,536,1,536,1,536,1,536,1,536,1,536,1,536,1,536,1,537,1,537,1,537,1,537,
	1,537,1,537,1,537,1,537,1,537,1,537,1,537,1,537,1,537,1,537,1,538,1,538,
	1,538,1,538,1,538,1,538,1,539,1,539,1,539,1,539,1,539,1,540,1,540,1,540,
	1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,540,1,540,
	1,540,1,540,1,540,1,541,1,541,1,541,1,541,1,541,1,541,1,541,1,541,1,541,
	1,541,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,542,1,543,
	1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,
	1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,543,1,544,
	1,544,1,544,1,544,1,544,1,544,1,544,1,545,1,545,1,545,1,545,1,545,1,545,
	1,546,1,546,1,546,1,546,1,546,1,547,1,547,1,547,1,547,1,547,1,547,1,547,
	1,548,1,548,1,548,1,548,1,548,1,548,1,549,1,549,1,549,1,549,1,549,1,549,
	1,549,1,550,1,550,1,550,1,550,1,550,1,550,1,550,1,550,1,550,1,550,1,550,
	1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,1,551,
	1,552,1,552,1,552,1,552,1,552,1,552,1,552,1,552,1,552,1,552,1,552,1,552,
	1,552,1,552,1,552,1,552,1,553,1,553,1,553,1,553,1,553,1,553,1,553,1,553,
	1,554,1,554,1,554,1,554,1,554,1,554,1,554,1,554,1,554,1,554,1,555,1,555,
	1,555,1,555,1,555,1,555,1,555,1,555,1,555,1,555,1,555,1,555,1,556,1,556,
	1,556,1,556,1,556,1,556,1,557,1,557,1,557,1,557,1,557,1,558,1,558,1,558,
	1,558,1,558,1,559,1,559,1,559,1,559,1,559,1,559,1,560,1,560,1,560,1,560,
	1,560,1,560,1,561,1,561,1,561,1,561,1,561,1,561,1,561,1,562,1,562,1,562,
	1,562,1,562,1,562,1,562,1,562,1,562,1,562,1,563,1,563,1,563,1,563,1,563,
	1,563,1,563,1,563,1,564,1,564,1,564,1,564,1,564,1,564,1,564,1,565,1,565,
	1,565,1,565,1,565,1,565,1,566,1,566,1,566,1,566,1,566,1,566,1,566,1,566,
	1,566,1,566,1,566,1,566,1,566,1,566,1,566,1,566,1,566,1,566,1,566,1,566,
	1,566,1,566,1,566,1,566,1,567,1,567,1,567,1,567,1,567,1,567,1,567,1,567,
	1,568,1,568,1,568,1,568,1,568,1,568,1,568,1,568,1,569,1,569,1,569,1,569,
	1,570,1,570,1,570,1,570,1,570,1,570,1,570,1,571,1,571,1,571,1,571,1,571,
	1,571,1,571,1,571,1,572,1,572,1,572,1,572,1,572,1,572,1,572,1,572,1,572,
	1,572,1,572,1,572,1,572,1,572,1,572,1,572,1,573,1,573,1,573,1,573,1,573,
	1,573,1,573,1,573,1,573,1,574,1,574,1,574,1,574,1,574,1,574,1,574,1,574,
	1,574,1,574,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,1,575,
	1,576,1,576,1,576,1,576,1,576,1,576,1,577,1,577,1,577,1,577,1,577,1,578,
	1,578,1,578,1,578,1,578,1,578,1,578,1,578,1,579,1,579,1,579,1,579,1,579,
	1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,
	1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,1,580,
	1,580,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,581,1,582,1,582,
	1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,582,1,583,1,583,1,583,1,583,
	1,584,1,584,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,
	1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,1,585,
	1,585,1,585,1,585,1,585,1,585,1,585,1,586,1,586,1,586,1,586,1,586,1,586,
	1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,1,586,
	1,586,1,586,1,586,1,586,1,586,1,587,1,587,1,587,1,587,1,587,1,587,1,587,
	1,587,1,587,1,587,1,587,1,587,1,587,1,587,1,587,1,587,1,588,1,588,1,588,
	1,588,1,588,1,588,1,588,1,588,1,588,1,588,1,588,1,588,1,588,1,589,1,589,
	1,589,1,589,1,589,1,589,1,589,1,589,1,589,1,589,1,590,1,590,1,590,1,590,
	1,591,1,591,1,591,1,591,1,591,1,591,1,591,1,592,1,592,1,592,1,592,1,592,
	1,592,1,592,1,592,1,592,1,593,1,593,1,593,1,593,1,593,1,593,1,593,1,593,
	1,593,1,593,1,593,1,593,1,594,1,594,1,594,1,594,1,594,1,594,1,594,1,594,
	1,594,1,594,1,594,1,594,1,594,1,594,1,594,1,594,1,594,1,594,1,594,1,594,
	1,594,1,595,1,595,1,595,1,595,1,595,1,596,1,596,1,596,1,596,1,596,1,596,
	1,597,1,597,1,597,1,597,1,597,1,597,1,597,1,597,1,597,1,598,1,598,1,598,
	1,598,1,598,1,598,1,599,1,599,1,599,1,599,1,599,1,599,1,599,1,599,1,599,
	1,600,1,600,1,600,1,600,1,600,1,600,1,600,1,600,1,600,1,601,1,601,1,601,
	1,601,1,601,1,601,1,601,1,601,1,601,1,601,1,601,1,601,1,602,1,602,1,602,
	1,602,1,602,1,602,1,602,1,603,1,603,1,603,1,603,1,603,1,603,1,603,1,603,
	1,603,1,603,1,603,1,604,1,604,1,604,1,604,1,604,1,604,1,604,1,604,1,604,
	1,604,1,605,1,605,1,605,1,605,1,605,1,605,1,606,1,606,1,606,1,606,1,606,
	1,606,1,606,1,606,1,606,1,606,1,606,1,607,1,607,1,607,1,607,1,607,1,607,
	1,607,1,607,1,608,1,608,1,608,1,608,1,608,1,609,1,609,1,609,1,609,1,609,
	1,609,1,609,1,609,1,609,1,610,1,610,1,610,1,610,1,610,1,610,1,610,1,610,
	1,611,1,611,1,611,1,611,1,611,1,611,1,611,1,612,1,612,1,612,1,612,1,612,
	1,612,1,612,1,613,1,613,1,613,1,613,1,613,1,614,1,614,1,614,1,614,1,614,
	1,615,1,615,1,615,1,615,1,615,1,615,1,615,1,615,1,615,1,616,1,616,1,616,
	1,616,1,616,1,616,1,616,1,616,1,616,1,617,1,617,1,617,1,617,1,617,1,617,
	1,617,1,618,1,618,1,618,1,618,1,618,1,619,1,619,1,619,1,619,1,619,1,619,
	1,619,1,619,1,619,1,619,1,619,1,620,1,620,1,620,1,620,1,620,1,620,1,620,
	1,620,1,620,1,620,1,620,1,620,1,620,1,621,1,621,1,621,1,621,1,621,1,621,
	1,621,1,621,1,621,1,621,1,621,1,621,1,622,1,622,1,622,1,622,1,622,1,622,
	1,622,1,622,1,622,1,622,1,623,1,623,1,623,1,623,1,623,1,624,1,624,1,624,
	1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,624,1,625,
	1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,625,1,626,
	1,626,1,626,1,626,1,627,1,627,1,627,1,627,1,627,1,628,1,628,1,628,1,628,
	1,628,1,628,1,629,1,629,1,629,1,629,1,629,1,630,1,630,1,630,1,630,1,630,
	1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,630,1,631,1,631,1,631,1,631,
	1,631,1,631,1,631,1,632,1,632,1,633,1,633,1,633,1,633,1,633,1,633,1,633,
	1,633,1,633,1,633,1,633,1,634,1,634,1,634,1,634,1,634,1,634,1,634,1,634,
	1,634,1,634,1,634,1,635,1,635,1,635,1,635,1,635,1,635,1,635,1,635,1,635,
	1,636,1,636,1,636,1,636,1,636,1,636,1,636,1,636,1,636,1,636,1,636,1,636,
	1,636,1,637,1,637,1,637,1,638,1,638,1,638,1,638,1,638,1,638,1,638,1,638,
	1,638,1,638,1,639,1,639,1,639,1,640,1,640,1,640,1,640,1,640,1,640,1,640,
	1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,
	1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,1,641,
	1,641,1,641,1,641,1,641,1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,642,
	1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,642,
	1,642,1,642,1,642,1,642,1,642,1,642,1,642,1,643,1,643,1,643,1,643,1,643,
	1,643,1,643,1,643,1,643,1,643,1,643,1,643,1,643,1,643,1,643,1,643,1,643,
	1,643,1,643,1,643,1,644,1,644,1,644,1,644,1,645,1,645,1,645,1,645,1,645,
	1,645,1,645,1,645,1,645,1,645,1,646,1,646,1,646,1,646,1,646,1,646,1,646,
	1,647,1,647,1,647,1,647,1,647,1,647,1,647,1,648,1,648,1,648,1,648,1,648,
	1,648,1,648,1,648,1,648,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,
	1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,1,649,
	1,649,1,649,1,650,1,650,1,650,1,650,1,650,1,650,1,650,1,650,1,651,1,651,
	1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,1,651,
	1,651,1,651,1,652,1,652,1,652,1,652,1,652,1,652,1,652,1,652,1,652,1,652,
	1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,1,653,
	1,654,1,654,1,654,1,654,1,654,1,654,1,654,1,654,1,654,1,654,1,655,1,655,
	1,655,1,655,1,655,1,656,1,656,1,656,1,656,1,656,1,656,1,656,1,657,1,657,
	1,657,1,657,1,657,1,657,1,657,1,657,1,657,1,657,1,658,1,658,1,658,1,658,
	1,658,1,658,1,658,1,658,1,658,1,658,1,658,1,658,1,658,1,658,1,659,1,659,
	1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,659,1,660,1,660,1,660,
	1,660,1,660,1,660,1,660,1,660,1,661,1,661,1,661,1,661,1,661,1,661,1,661,
	1,661,1,662,1,662,1,662,1,662,1,662,1,662,1,662,1,662,1,662,1,662,1,663,
	1,663,1,663,1,663,1,663,1,663,1,663,1,663,1,663,1,663,1,663,1,663,1,663,
	1,664,1,664,1,664,1,664,1,664,1,664,1,665,1,665,1,665,1,665,1,665,1,665,
	1,665,1,665,1,665,1,666,1,666,1,666,1,666,1,666,1,666,1,666,1,666,1,666,
	1,666,1,666,1,667,1,667,1,667,1,667,1,667,1,667,1,667,1,667,1,667,1,667,
	1,667,1,668,1,668,1,668,1,668,1,668,1,668,1,668,1,668,1,668,1,668,1,669,
	1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,1,669,
	1,670,1,670,1,670,1,670,1,670,1,670,1,670,1,670,1,670,1,670,1,670,1,670,
	1,670,1,670,1,671,1,671,1,671,1,671,1,671,1,671,1,671,1,671,1,671,1,672,
	1,672,1,672,1,672,1,672,1,672,1,672,1,672,1,672,1,672,1,672,1,673,1,673,
	1,673,1,673,1,673,1,673,1,673,1,673,1,673,1,673,1,673,1,673,1,673,1,673,
	1,673,1,673,1,674,1,674,1,674,1,674,1,674,1,674,1,674,1,674,1,674,1,674,
	1,674,1,674,1,674,1,675,1,675,1,675,1,675,1,675,1,675,1,675,1,675,1,675,
	1,675,1,675,1,675,1,675,1,675,1,676,1,676,1,676,1,676,1,676,1,676,1,676,
	1,676,1,676,1,677,1,677,1,677,1,677,1,677,1,677,1,677,1,677,1,677,1,677,
	1,677,1,677,1,678,1,678,1,678,1,678,1,678,1,678,1,678,1,678,1,678,1,678,
	1,679,1,679,1,679,1,679,1,679,1,679,1,679,1,679,1,679,1,679,1,679,1,680,
	1,680,1,680,1,680,1,680,1,680,1,680,1,680,1,680,1,680,1,681,1,681,1,681,
	1,681,1,681,1,681,1,681,1,681,1,682,1,682,1,682,1,682,1,682,1,682,1,682,
	1,682,1,682,1,683,1,683,1,683,1,683,1,683,1,683,1,683,1,683,1,683,1,683,
	1,683,1,683,1,683,1,683,1,684,1,684,1,684,1,684,1,684,1,684,1,684,1,684,
	1,685,1,685,1,685,1,686,1,686,1,686,1,686,1,686,1,686,1,686,1,686,1,687,
	1,687,1,687,1,687,1,687,1,687,1,687,1,687,1,688,1,688,1,688,1,688,1,688,
	1,688,1,688,1,688,1,688,1,688,1,688,1,688,1,689,1,689,1,689,1,689,1,689,
	1,689,1,689,1,689,1,689,1,689,1,690,1,690,1,690,1,690,1,690,1,690,1,690,
	1,690,1,690,1,691,1,691,1,691,1,691,1,691,1,691,1,691,1,692,1,692,1,692,
	1,692,1,692,1,692,1,692,1,692,1,692,1,692,1,692,1,692,1,692,1,692,1,692,
	1,692,1,692,1,692,1,693,1,693,1,693,1,693,1,693,1,693,1,693,1,693,1,693,
	1,694,1,694,1,694,1,694,1,694,1,694,1,694,1,694,1,694,1,694,1,694,1,694,
	1,694,1,694,1,694,1,694,1,694,1,694,1,694,1,695,1,695,1,695,1,695,1,695,
	1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,1,695,
	1,696,1,696,1,696,1,696,1,696,1,696,1,697,1,697,1,697,1,697,1,697,1,697,
	1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,698,1,699,1,699,1,699,1,699,
	1,699,1,699,1,699,1,699,1,699,1,699,1,699,1,699,1,699,1,699,1,699,1,699,
	1,699,1,699,1,699,1,699,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,
	1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,1,700,
	1,700,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,1,701,
	1,701,1,701,1,701,1,701,1,702,1,702,1,702,1,702,1,702,1,702,1,702,1,703,
	1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,1,703,
	1,703,1,703,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,1,704,
	1,704,1,704,1,704,1,704,1,704,1,704,1,705,1,705,1,705,1,705,1,705,1,705,
	1,705,1,705,1,705,1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,1,706,
	1,706,1,707,1,707,1,707,1,707,1,707,1,707,1,707,1,707,1,707,1,707,1,707,
	1,707,1,707,1,708,1,708,1,708,1,708,1,708,1,708,1,708,1,708,1,708,1,708,
	1,709,1,709,1,709,1,709,1,709,1,709,1,709,1,709,1,710,1,710,1,710,1,710,
	1,710,1,710,1,710,1,711,1,711,1,711,1,711,1,711,1,711,1,711,1,712,1,712,
	1,712,1,712,1,712,1,712,1,712,1,713,1,713,1,713,1,713,1,713,1,713,1,713,
	1,714,1,714,1,714,1,714,1,714,1,714,1,715,1,715,1,715,1,715,1,715,1,715,
	1,715,1,715,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,1,716,
	1,716,1,716,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,1,717,
	1,717,1,717,1,717,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,
	1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,718,1,719,1,719,1,719,1,719,
	1,719,1,719,1,719,1,719,1,719,1,719,1,719,1,719,1,719,1,720,1,720,1,720,
	1,720,1,720,1,720,1,720,1,720,1,720,1,720,1,720,1,720,1,721,1,721,1,721,
	1,721,1,721,1,721,1,721,1,721,1,721,1,721,1,722,1,722,1,722,1,722,1,722,
	1,722,1,722,1,722,1,722,1,723,1,723,1,723,1,723,1,724,1,724,1,724,1,724,
	1,724,1,725,1,725,1,725,1,725,1,725,1,725,1,725,1,725,1,725,1,725,1,725,
	1,726,1,726,1,726,1,726,1,726,1,726,1,726,1,726,1,726,1,726,1,727,1,727,
	1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,727,1,728,1,728,1,728,1,729,
	1,729,1,729,1,729,1,729,1,729,1,729,1,729,1,730,1,730,1,730,1,730,1,730,
	1,730,1,730,1,730,1,730,1,730,1,731,1,731,1,731,1,731,1,731,1,731,1,731,
	1,731,1,731,1,731,1,731,1,731,1,731,1,731,1,731,1,731,1,732,1,732,1,732,
	1,732,1,732,1,732,1,732,1,732,1,733,1,733,1,733,1,733,1,733,1,733,1,733,
	1,733,1,733,1,733,1,733,1,733,1,733,1,733,1,733,1,733,1,733,1,734,1,734,
	1,734,1,734,1,734,1,735,1,735,1,735,1,735,1,736,1,736,1,736,1,736,1,736,
	1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,1,737,
	1,737,1,737,1,738,1,738,1,738,1,738,1,738,1,738,1,738,1,738,1,738,1,738,
	1,738,1,739,1,739,1,739,1,739,1,739,1,739,1,739,1,739,1,739,1,739,1,739,
	1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,1,740,
	1,740,1,741,1,741,1,741,1,741,1,741,1,741,1,741,1,741,1,741,1,741,1,741,
	1,741,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,742,1,743,1,743,1,743,
	1,743,1,743,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,1,744,
	1,744,1,744,1,744,1,744,1,744,1,745,1,745,1,745,1,745,1,745,1,745,1,745,
	1,745,1,745,1,745,1,745,1,745,1,746,1,746,1,746,1,746,1,746,1,746,1,746,
	1,746,1,746,1,746,1,747,1,747,1,747,1,747,1,747,1,747,1,747,1,747,1,747,
	1,747,1,747,1,748,1,748,1,748,1,748,1,748,1,748,1,748,1,748,1,748,1,748,
	1,748,1,748,1,748,1,748,1,748,1,749,1,749,1,749,1,749,1,749,1,749,1,749,
	1,749,1,749,1,749,1,749,1,750,1,750,1,750,1,750,1,750,1,750,1,750,1,750,
	1,750,1,750,1,750,1,750,1,750,1,750,1,750,1,750,1,750,1,750,1,750,1,751,
	1,751,1,751,1,751,1,751,1,751,1,751,1,751,1,751,1,751,1,751,1,751,1,751,
	1,751,1,751,1,751,1,751,1,751,1,752,1,752,1,752,1,752,1,752,1,752,1,752,
	1,752,1,752,1,752,1,752,1,752,1,752,1,752,1,752,1,753,1,753,1,753,1,753,
	1,753,1,753,1,753,1,753,1,753,1,753,1,753,1,754,1,754,1,755,1,755,1,755,
	1,755,1,755,1,755,1,755,1,755,1,755,1,755,1,755,1,755,1,755,1,755,1,755,
	1,755,1,756,1,756,1,756,1,756,1,756,1,757,1,757,1,757,1,757,1,757,1,757,
	1,757,1,757,1,757,1,758,1,758,1,758,1,758,1,759,1,759,1,759,1,759,1,759,
	1,759,1,759,1,759,1,759,1,759,1,759,1,760,1,760,1,760,1,760,1,760,1,760,
	1,760,1,760,1,761,1,761,1,761,1,761,1,761,1,762,1,762,1,762,1,762,1,762,
	1,762,1,762,1,762,1,762,1,763,1,763,1,763,1,763,1,763,1,764,1,764,1,764,
	1,764,1,764,1,764,1,765,1,765,1,765,1,765,1,765,1,765,1,765,1,765,1,765,
	1,766,1,766,1,766,1,766,1,766,1,766,1,766,1,766,1,766,1,767,1,767,1,767,
	1,767,1,767,1,768,1,768,1,768,1,768,1,768,1,768,1,768,1,768,1,768,1,768,
	1,768,1,769,1,769,1,769,1,769,1,769,1,769,1,769,1,769,1,770,1,770,1,770,
	1,770,1,771,1,771,1,771,1,771,1,771,1,771,1,772,1,772,1,772,1,772,1,772,
	1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,
	1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,772,1,773,1,773,1,773,
	1,773,1,773,1,773,1,773,1,773,1,773,1,773,1,773,1,773,1,773,1,773,1,773,
	1,773,1,773,1,773,1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,
	1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,1,774,
	1,774,1,774,1,775,1,775,1,775,1,775,1,775,1,776,1,776,1,776,1,776,1,776,
	1,776,1,776,1,776,1,776,1,777,1,777,1,777,1,777,1,777,1,777,1,777,1,777,
	1,777,1,777,1,777,1,777,1,777,1,778,1,778,1,778,1,778,1,778,1,778,1,778,
	1,778,1,779,1,779,1,779,1,779,1,779,1,780,1,780,1,780,1,780,1,780,1,780,
	1,780,1,780,1,781,1,781,1,781,1,781,1,781,1,781,1,781,1,781,1,782,1,782,
	1,782,1,782,1,782,1,782,1,782,1,782,1,783,1,783,1,783,1,783,1,783,1,783,
	1,783,1,783,1,784,1,784,1,784,1,784,1,784,1,784,1,784,1,785,1,785,1,785,
	1,785,1,785,1,786,1,786,1,786,1,786,1,786,1,786,1,787,1,787,1,787,1,787,
	1,787,1,787,1,787,1,787,1,787,1,787,1,787,1,788,1,788,1,788,1,788,1,788,
	1,788,1,788,1,789,1,789,1,789,1,789,1,789,1,789,1,789,1,789,1,790,1,790,
	1,790,1,790,1,790,1,790,1,790,1,790,1,790,1,790,1,791,1,791,1,791,1,791,
	1,791,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,792,1,793,1,793,
	1,793,1,793,1,793,1,793,1,794,1,794,1,794,1,794,1,794,1,794,1,795,1,795,
	1,795,1,795,1,795,1,795,1,796,1,796,1,796,1,796,1,796,1,796,1,796,1,796,
	1,796,1,796,1,796,1,796,1,797,1,797,1,797,1,797,1,797,1,798,1,798,1,798,
	1,798,1,798,1,798,1,799,1,799,1,799,1,799,1,799,1,799,1,799,1,800,1,800,
	1,800,1,800,1,800,1,801,1,801,1,801,1,801,1,801,1,802,1,802,1,802,1,803,
	1,803,1,803,1,803,1,803,1,803,1,804,1,804,1,804,1,804,1,804,1,805,1,805,
	1,805,1,805,1,806,1,806,1,806,1,806,1,806,1,806,1,806,1,807,1,807,1,807,
	1,807,1,807,1,808,1,808,1,808,1,808,1,808,1,808,1,808,1,808,1,808,1,808,
	1,808,1,808,1,808,1,808,1,809,1,809,1,809,1,809,1,809,1,809,1,810,1,810,
	1,810,1,810,1,810,1,810,1,810,1,810,1,810,1,810,1,811,1,811,1,811,1,811,
	1,811,1,811,1,811,1,811,1,811,1,811,1,811,1,811,1,811,1,811,1,811,1,812,
	1,812,1,812,1,812,1,812,1,812,1,812,1,812,1,812,1,813,1,813,1,813,1,813,
	1,813,1,813,1,813,1,813,1,814,1,814,1,814,1,814,1,814,1,814,1,814,1,814,
	1,814,1,815,1,815,1,815,1,815,1,815,1,815,1,815,1,816,1,816,1,816,1,816,
	1,816,1,816,1,816,1,816,1,817,1,817,1,817,1,817,1,817,1,818,1,818,1,818,
	1,818,1,818,1,818,1,818,1,818,1,819,1,819,1,819,1,819,1,819,1,819,1,819,
	1,819,1,819,1,820,1,820,1,820,1,820,1,820,1,820,1,820,1,820,1,821,1,821,
	1,821,1,821,1,821,1,821,1,821,1,821,1,822,1,822,1,822,1,822,1,822,1,822,
	1,822,1,822,1,822,1,822,1,822,1,822,1,822,1,822,1,822,1,822,1,822,1,822,
	1,822,1,822,1,822,1,822,1,822,1,823,1,823,1,823,1,823,1,823,1,823,1,823,
	1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,823,
	1,823,1,823,1,823,1,823,1,823,1,823,1,823,1,824,1,824,1,824,1,824,1,825,
	1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,825,1,826,1,826,1,826,
	1,826,1,826,1,826,1,826,1,827,1,827,1,827,1,827,1,827,1,827,1,828,1,828,
	1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,
	1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,828,1,829,
	1,829,1,829,1,829,1,829,1,830,1,830,1,830,1,830,1,830,1,831,1,831,1,831,
	1,831,1,831,1,832,1,832,1,832,1,832,1,832,1,832,1,833,1,833,1,833,1,833,
	1,834,1,834,1,834,1,834,1,834,1,835,1,835,1,835,1,835,1,835,1,835,1,836,
	1,836,1,837,1,837,1,837,1,837,1,837,1,838,1,838,1,838,1,838,1,838,1,838,
	1,838,1,838,1,838,1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,839,1,840,
	1,840,1,840,1,840,1,840,1,840,1,840,1,841,1,841,1,841,1,841,1,841,1,841,
	1,841,1,841,1,841,1,841,1,841,1,842,1,842,1,842,1,842,1,842,1,842,1,842,
	1,842,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,843,1,844,
	1,844,1,844,1,844,1,844,1,844,1,844,1,845,1,845,1,845,1,845,1,846,1,846,
	1,846,1,846,1,846,1,846,1,846,1,846,1,847,1,847,1,847,1,847,1,847,1,847,
	1,847,1,848,1,848,1,848,1,848,1,848,1,848,1,848,1,848,1,849,1,849,1,849,
	1,849,1,849,1,849,1,849,1,849,1,850,1,850,1,850,1,850,1,850,1,850,1,851,
	1,851,1,851,1,851,1,851,1,851,1,851,1,851,1,851,1,851,1,851,1,851,1,851,
	1,852,1,852,1,852,1,852,1,852,1,852,1,852,1,852,1,852,1,852,1,852,1,852,
	1,852,1,852,1,852,1,852,1,853,1,853,1,853,1,853,1,853,1,853,1,853,1,853,
	1,853,1,853,1,853,1,853,1,853,1,854,1,854,1,854,1,854,1,854,1,854,1,854,
	1,854,1,854,1,854,1,854,1,854,1,855,1,855,1,855,1,855,1,855,1,855,1,855,
	1,855,1,855,1,855,1,855,1,855,1,856,1,856,1,856,1,856,1,856,1,856,1,856,
	1,856,1,856,1,856,1,856,1,856,1,856,1,857,1,857,1,857,1,857,1,857,1,857,
	1,857,1,857,1,857,1,857,1,857,1,858,1,858,1,858,1,858,1,858,1,858,1,858,
	1,858,1,858,1,859,1,859,1,859,1,859,1,859,1,859,1,859,1,859,1,859,1,859,
	1,859,1,859,1,859,1,860,1,860,1,860,1,860,1,860,1,860,1,860,1,860,1,860,
	1,860,1,860,1,860,1,861,1,861,1,861,1,861,1,861,1,861,1,861,1,861,1,861,
	1,861,1,861,1,861,1,861,1,861,1,862,1,862,1,862,1,862,1,862,1,862,1,862,
	1,862,1,862,1,862,1,862,1,862,1,862,1,862,1,863,1,863,1,863,1,863,1,863,
	1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,1,863,
	1,863,1,863,1,863,1,863,1,864,1,864,1,864,1,864,1,864,1,864,1,864,1,864,
	1,865,1,865,1,865,1,865,1,865,1,865,1,865,1,865,1,865,1,866,1,866,1,866,
	1,866,1,866,1,866,1,866,1,866,1,866,1,867,1,867,1,867,1,867,1,867,1,867,
	1,867,1,867,1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,868,1,869,
	1,869,1,869,1,869,1,869,1,869,1,869,1,870,1,870,1,870,1,870,1,870,1,870,
	1,870,1,871,1,871,1,871,1,871,1,871,1,871,1,871,1,871,1,871,1,871,1,871,
	1,871,1,871,1,871,1,871,1,872,1,872,1,872,1,872,1,872,1,872,1,872,1,872,
	1,872,1,872,1,872,1,872,1,872,1,872,1,872,1,872,1,872,1,872,1,872,1,873,
	1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,873,1,874,
	1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,1,874,
	1,874,1,874,1,874,1,874,1,874,1,874,1,875,1,875,1,875,1,875,1,875,1,875,
	1,875,1,875,1,875,1,875,1,875,1,875,1,876,1,876,1,876,1,876,1,876,1,876,
	1,876,1,877,1,877,1,877,1,877,1,877,1,877,1,877,1,877,1,877,1,877,1,877,
	1,877,1,877,1,877,1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,878,1,878,
	1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,1,879,
	1,879,1,879,1,879,1,880,1,880,1,880,1,880,1,880,1,880,1,881,1,881,1,881,
	1,881,1,881,1,881,1,881,1,881,1,881,1,882,1,882,1,882,1,882,1,882,1,882,
	1,882,1,882,1,882,1,883,1,883,1,883,1,883,1,883,1,883,1,883,1,884,1,884,
	1,884,1,884,1,884,1,884,1,884,1,884,1,885,1,885,1,885,1,885,1,885,1,885,
	1,885,1,885,1,885,1,885,1,886,1,886,1,886,1,886,1,886,1,886,1,886,1,886,
	1,886,1,886,1,886,1,887,1,887,1,887,1,887,1,887,1,887,1,887,1,887,1,887,
	1,888,1,888,1,888,1,888,1,888,1,888,1,888,1,888,1,889,1,889,1,889,1,889,
	1,889,1,889,1,889,1,890,1,890,1,890,1,890,1,890,1,890,1,891,1,891,1,891,
	1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,891,1,892,1,892,1,892,1,892,
	1,892,1,892,1,892,1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,893,1,894,
	1,894,1,894,1,894,1,894,1,894,1,894,1,894,1,894,1,895,1,895,1,895,1,895,
	1,895,1,895,1,895,1,895,1,895,1,895,1,895,1,896,1,896,1,896,1,896,1,896,
	1,896,1,896,1,896,1,896,1,896,1,897,1,897,1,897,1,897,1,897,1,897,1,897,
	1,898,1,898,1,898,1,898,1,898,1,898,1,898,1,898,1,899,1,899,1,899,1,899,
	1,899,1,899,1,899,1,899,1,899,1,900,1,900,1,900,1,900,1,900,1,900,1,900,
	1,900,1,900,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,
	1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,1,901,
	1,901,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,
	1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,1,902,
	1,902,1,902,1,902,1,902,1,902,1,903,1,903,1,903,1,903,1,903,1,903,1,903,
	1,903,1,903,1,903,1,903,1,903,1,903,1,903,1,903,1,903,1,903,1,903,1,903,
	1,903,1,903,1,903,1,903,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,
	1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,904,1,905,
	1,905,1,905,1,905,1,905,1,905,1,906,1,906,1,906,1,906,1,906,1,906,1,906,
	1,906,1,906,1,907,1,907,1,907,1,907,1,907,1,907,1,907,1,907,1,907,1,907,
	1,907,1,907,1,907,1,907,1,907,1,907,1,907,1,907,1,908,1,908,1,908,1,908,
	1,908,1,908,1,908,1,908,1,908,1,908,1,909,1,909,1,909,1,909,1,909,1,909,
	1,909,1,909,1,909,1,909,1,909,1,909,1,909,1,909,1,909,1,910,1,910,1,910,
	1,910,1,910,1,910,1,910,1,910,1,910,1,911,1,911,1,911,1,911,1,911,1,912,
	1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,1,912,
	1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,913,
	1,913,1,913,1,913,1,913,1,913,1,913,1,913,1,914,1,914,1,914,1,914,1,914,
	1,914,1,914,1,915,1,915,1,915,1,915,1,916,1,916,1,916,1,916,1,916,1,916,
	1,916,1,917,1,917,1,917,1,917,1,917,1,917,1,917,1,917,1,917,1,917,1,917,
	1,918,1,918,1,918,1,918,1,918,1,918,1,918,1,918,1,919,1,919,1,919,1,919,
	1,919,1,919,1,920,1,920,1,920,1,920,1,920,1,920,1,920,1,920,1,920,1,920,
	1,920,1,920,1,920,1,920,1,920,1,921,1,921,1,921,1,921,1,921,1,921,1,921,
	1,922,1,922,1,922,1,922,1,922,1,922,1,923,1,923,1,923,1,923,1,923,1,923,
	1,923,1,923,1,923,1,923,1,924,1,924,1,924,1,924,1,924,1,924,1,924,1,924,
	1,924,1,924,1,924,1,925,1,925,1,925,1,925,1,925,1,925,1,925,1,925,1,925,
	1,926,1,926,1,926,1,926,1,926,1,927,1,927,1,927,1,927,1,927,1,927,1,927,
	1,927,1,927,1,927,1,927,1,927,1,927,1,927,1,927,1,927,1,927,1,928,1,928,
	1,928,1,928,1,928,1,928,1,928,1,928,1,928,1,929,1,929,1,929,1,929,1,929,
	1,929,1,929,1,929,1,929,1,930,1,930,1,930,1,930,1,930,1,930,1,931,1,931,
	1,931,1,931,1,931,1,932,1,932,1,932,1,932,1,932,1,932,1,932,1,932,1,932,
	1,932,1,933,1,933,1,933,1,933,1,934,1,934,1,934,1,934,1,934,1,934,1,935,
	1,935,1,935,1,935,1,935,1,935,1,935,1,935,1,935,1,936,1,936,1,936,1,936,
	1,936,1,936,1,936,1,936,1,936,1,936,1,936,1,936,1,936,1,936,1,936,1,936,
	1,936,1,936,1,936,1,936,1,936,1,936,1,936,1,937,1,937,1,937,1,937,1,937,
	1,937,1,937,1,938,1,938,1,938,1,938,1,938,1,938,1,938,1,938,1,939,1,939,
	1,939,1,939,1,939,1,939,1,939,1,939,1,939,1,940,1,940,1,940,1,940,1,941,
	1,941,1,941,1,941,1,941,1,941,1,941,1,941,1,941,1,942,1,942,1,942,1,942,
	1,942,1,942,1,943,1,943,1,943,1,943,1,943,1,944,1,944,1,944,1,944,1,944,
	1,944,1,945,1,945,1,945,1,945,1,945,1,945,1,945,1,946,1,946,1,946,1,946,
	1,947,1,947,1,947,1,947,1,947,1,947,1,947,1,948,1,948,1,948,1,948,1,948,
	1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,
	1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,948,1,949,1,949,1,949,1,949,
	1,949,1,949,1,949,1,949,1,949,1,949,1,949,1,949,1,949,1,949,1,949,1,949,
	1,949,1,949,1,949,1,949,1,949,1,949,1,950,1,950,1,950,1,950,1,950,1,950,
	1,950,1,950,1,950,1,950,1,950,1,950,1,950,1,950,1,950,1,950,1,951,1,951,
	1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,
	1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,951,1,952,1,952,1,952,1,952,
	1,952,1,952,1,952,1,952,1,952,1,952,1,952,1,952,1,952,1,952,1,952,1,952,
	1,952,1,952,1,952,1,952,1,952,1,952,1,952,1,953,1,953,1,953,1,953,1,953,
	1,953,1,953,1,953,1,954,1,954,1,954,1,954,1,954,1,954,1,955,1,955,1,955,
	1,955,1,956,1,956,1,956,1,956,1,956,1,956,1,956,1,956,1,956,1,957,1,957,
	1,957,1,957,1,957,1,957,1,957,1,957,1,957,1,958,1,958,1,958,1,958,1,958,
	1,959,1,959,1,959,1,959,1,959,1,959,1,960,1,960,1,960,1,960,1,960,1,960,
	1,960,1,960,1,960,1,960,1,960,1,960,1,960,1,961,1,961,1,961,1,961,1,961,
	1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,1,961,
	1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,1,962,
	1,962,1,962,1,962,1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,1,963,
	1,963,1,963,1,963,1,963,1,964,1,964,1,964,1,964,1,964,1,964,1,964,1,964,
	1,964,1,964,1,964,1,964,1,964,1,965,1,965,1,965,1,965,1,965,1,965,1,965,
	1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,965,1,966,1,966,
	1,966,1,966,1,966,1,966,1,966,1,966,1,966,1,966,1,966,1,966,1,966,1,966,
	1,966,1,966,1,966,1,966,1,966,1,966,1,966,1,967,1,967,1,967,1,967,1,967,
	1,967,1,967,1,967,1,967,1,967,1,967,1,967,1,967,1,967,1,967,1,968,1,968,
	1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,1,968,
	1,968,1,968,1,968,1,969,1,969,1,969,1,969,1,969,1,969,1,969,1,969,1,969,
	1,970,1,970,1,970,1,970,1,970,1,970,1,970,1,970,1,970,1,970,1,970,1,970,
	1,970,1,971,1,971,1,971,1,971,1,971,1,971,1,971,1,971,1,971,1,971,1,971,
	1,971,1,971,1,971,1,971,1,971,1,972,1,972,1,972,1,972,1,972,1,972,1,972,
	1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,972,1,973,
	1,973,1,973,1,973,1,973,1,973,1,973,1,973,1,973,1,973,1,973,1,973,1,974,
	1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,1,974,
	1,974,1,974,1,974,1,974,1,975,1,975,1,975,1,975,1,975,1,975,1,976,1,976,
	1,976,1,976,1,976,1,976,1,976,1,976,1,976,1,977,1,977,1,977,1,977,1,977,
	1,977,1,977,1,977,1,977,1,977,1,977,1,977,1,977,1,978,1,978,1,978,1,978,
	1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,1,978,
	1,978,1,978,1,978,1,978,1,978,1,979,1,979,1,979,1,979,1,979,1,979,1,979,
	1,979,1,979,1,979,1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,980,
	1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,980,1,981,
	1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,
	1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,981,1,982,1,982,
	1,982,1,982,1,982,1,982,1,982,1,982,1,982,1,983,1,983,1,983,1,983,1,983,
	1,983,1,983,1,983,1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,984,
	1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,984,1,985,1,985,1,985,
	1,985,1,985,1,985,1,985,1,985,1,985,1,985,1,985,1,985,1,985,1,985,1,986,
	1,986,1,986,1,986,1,986,1,986,1,986,1,986,1,986,1,986,1,987,1,987,1,987,
	1,987,1,987,1,987,1,987,1,987,1,987,1,987,1,988,1,988,1,988,1,988,1,988,
	1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,988,1,988,
	1,989,1,989,1,989,1,989,1,989,1,989,1,989,1,989,1,989,1,989,1,989,1,989,
	1,989,1,989,1,989,1,990,1,990,1,990,1,990,1,990,1,990,1,990,1,990,1,990,
	1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,1,991,
	1,991,1,992,1,992,1,992,1,992,1,992,1,992,1,992,1,992,1,993,1,993,1,993,
	1,993,1,993,1,993,1,993,1,993,1,993,1,993,1,993,1,993,1,993,1,993,1,993,
	1,993,1,993,1,993,1,993,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,
	1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,
	1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,994,1,995,1,995,1,995,
	1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,
	1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,1,995,
	1,995,1,995,1,995,1,995,1,996,1,996,1,996,1,996,1,996,1,996,1,996,1,996,
	1,996,1,996,1,996,1,996,1,996,1,996,1,997,1,997,1,997,1,997,1,997,1,997,
	1,997,1,997,1,997,1,997,1,998,1,998,1,998,1,998,1,998,1,998,1,998,1,998,
	1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,1,999,
	1,999,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,
	1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,1,1000,
	1,1000,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,
	1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,1,1001,
	1,1002,1,1002,1,1002,1,1002,1,1002,1,1002,1,1002,1,1002,1,1002,1,1002,1,
	1002,1,1002,1,1002,1,1002,1,1003,1,1003,1,1003,1,1003,1,1003,1,1003,1,1003,
	1,1003,1,1003,1,1003,1,1003,1,1003,1,1003,1,1003,1,1003,1,1004,1,1004,1,
	1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,1,1004,
	1,1004,1,1004,1,1005,1,1005,1,1005,1,1005,1,1005,1,1005,1,1005,1,1005,1,
	1005,1,1005,1,1005,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,
	1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,
	1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1006,1,1007,1,1007,1,1007,
	1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,
	1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,1,1007,
	1,1007,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,
	1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,1,1008,
	1,1008,1,1008,1,1008,1,1008,1,1008,1,1009,1,1009,1,1009,1,1009,1,1009,1,
	1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,
	1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1009,1,1010,1,1010,1,
	1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,
	1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,1010,1,
	1010,1,1011,1,1011,1,1011,1,1011,1,1011,1,1011,1,1011,1,1012,1,1012,1,1012,
	1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,
	1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,1,1012,
	1,1012,1,1012,1,1013,1,1013,1,1013,1,1013,1,1013,1,1013,1,1013,1,1013,1,
	1013,1,1013,1,1013,1,1013,1,1013,1,1013,1,1013,1,1014,1,1014,1,1014,1,1014,
	1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,1014,1,
	1015,1,1015,1,1015,1,1015,1,1015,1,1015,1,1015,1,1015,1,1016,1,1016,1,1016,
	1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,
	1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,1,1016,
	1,1016,1,1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,
	1017,1,1017,1,1017,1,1017,1,1017,1,1017,1,1018,1,1018,1,1018,1,1018,1,1018,
	1,1018,1,1018,1,1018,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,
	1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,1,1019,
	1,1019,1,1019,1,1019,1,1019,1,1019,1,1020,1,1020,1,1020,1,1020,1,1020,1,
	1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,
	1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,1020,1,
	1020,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,
	1,1021,1,1021,1,1021,1,1021,1,1021,1,1021,1,1022,1,1022,1,1022,1,1022,1,
	1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,1,1022,
	1,1022,1,1022,1,1022,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,
	1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1023,1,1024,
	1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,
	1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,1,1024,
	1,1024,1,1024,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,
	1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1025,1,1026,1,1026,
	1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,
	1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,1,1026,
	1,1026,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,1027,1,
	1027,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,
	1,1028,1,1028,1,1028,1,1028,1,1028,1,1028,1,1029,1,1029,1,1029,1,1029,1,
	1029,1,1029,1,1029,1,1029,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,
	1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,1030,1,
	1030,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,1,1031,
	1,1031,1,1031,1,1031,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,1032,1,
	1032,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,
	1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,
	1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1033,1,1034,1,1034,1,1034,
	1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,
	1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,
	1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1034,1,1035,1,1035,1,1035,1,
	1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,1,1035,
	1,1035,1,1035,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,1036,1,
	1036,1,1036,1,1036,1,1036,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,
	1,1037,1,1037,1,1037,1,1037,1,1037,1,1037,1,1038,1,1038,1,1038,1,1038,1,
	1038,1,1038,1,1038,1,1038,1,1038,1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,
	1,1039,1,1039,1,1039,1,1039,1,1039,1,1039,1,1040,1,1040,1,1040,1,1040,1,
	1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1040,1,1041,1,1041,1,1041,
	1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,
	1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1041,1,1042,1,1042,1,1042,1,1042,
	1,1042,1,1042,1,1042,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,1043,1,
	1043,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1044,1,1045,1,1045,
	1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1045,1,1046,1,1046,1,
	1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1046,1,1047,1,1047,1,1047,
	1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1047,1,1048,1,1048,1,
	1048,1,1048,1,1048,1,1048,1,1048,1,1048,1,1048,1,1049,1,1049,1,1049,1,1049,
	1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1049,1,1050,1,1050,1,1050,1,
	1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1050,1,1051,1,1051,1,1051,
	1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,1051,1,
	1051,1,1051,1,1051,1,1051,1,1051,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,
	1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1052,1,1053,1,1053,1,
	1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,1,1053,
	1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,1054,1,
	1054,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,
	1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1055,1,1056,1,
	1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,
	1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,1056,1,
	1056,1,1056,1,1056,1,1056,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,1,1057,
	1,1057,1,1057,1,1057,1,1057,1,1057,1,1058,1,1058,1,1058,1,1058,1,1058,1,
	1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1058,1,1059,
	1,1059,1,1059,1,1059,1,1059,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,
	1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1060,1,1061,
	1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,1061,1,
	1061,1,1061,1,1061,1,1061,1,1061,1,1062,1,1062,1,1062,1,1063,1,1063,1,1063,
	1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1063,1,1064,1,1064,1,1064,1,
	1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,1,1064,
	1,1064,1,1065,1,1065,1,1065,1,1065,1,1065,1,1065,1,1065,1,1065,1,1066,1,
	1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,1,1066,
	1,1066,1,1066,1,1066,1,1066,1,1066,1,1067,1,1067,1,1067,1,1067,1,1067,1,
	1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,
	1,1067,1,1067,1,1067,1,1067,1,1067,1,1067,1,1068,1,1068,1,1068,1,1068,1,
	1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,
	1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1068,1,1069,1,1069,1,
	1069,1,1069,1,1069,1,1069,1,1069,1,1069,1,1069,1,1069,1,1069,1,1070,1,1070,
	1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,
	1070,1,1070,1,1070,1,1070,1,1070,1,1070,1,1071,1,1071,1,1071,1,1071,1,1071,
	1,1071,1,1071,1,1071,1,1071,1,1071,1,1071,1,1071,1,1071,1,1071,1,1071,1,
	1071,1,1071,1,1072,1,1072,1,1072,1,1072,1,1072,1,1072,1,1072,1,1072,1,1072,
	1,1072,1,1072,1,1072,1,1073,1,1073,1,1073,1,1073,1,1073,1,1073,1,1073,1,
	1073,1,1073,1,1073,1,1073,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,
	1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,1074,1,
	1074,1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,
	1,1075,1,1075,1,1075,1,1075,1,1075,1,1075,1,1076,1,1076,1,1076,1,1076,1,
	1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,
	1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,1076,1,
	1076,1,1076,1,1077,1,1077,1,1077,1,1077,1,1077,1,1077,1,1077,1,1077,1,1077,
	1,1077,1,1077,1,1077,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,
	1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,1,1078,
	1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,
	1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1079,1,1080,1,1080,1,1080,
	1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,
	1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1080,1,1081,
	1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1081,1,1082,1,
	1082,1,1082,1,1082,1,1082,1,1082,1,1082,1,1082,1,1082,1,1083,1,1083,1,1083,
	1,1083,1,1083,1,1083,1,1083,1,1083,1,1083,1,1083,1,1083,1,1083,1,1083,1,
	1083,1,1083,1,1083,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,1,1084,
	1,1084,1,1084,1,1084,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,
	1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1085,1,1086,1,1086,1,1086,
	1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,1086,1,
	1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,1,1087,
	1,1087,1,1087,1,1088,1,1088,1,1088,1,1088,1,1088,1,1088,1,1088,1,1088,1,
	1088,1,1088,1,1088,1,1088,1,1088,1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,
	1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,
	1089,1,1089,1,1089,1,1089,1,1089,1,1089,1,1090,1,1090,1,1090,1,1090,1,1090,
	1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,1090,1,
	1090,1,1090,1,1090,1,1091,1,1091,1,1091,1,1091,1,1091,1,1091,1,1091,1,1091,
	1,1091,1,1091,1,1091,1,1091,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,
	1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,
	1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1092,1,1093,1,1093,1,1093,1,
	1093,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,1,1093,
	1,1093,1,1094,1,1094,1,1094,1,1094,1,1094,1,1094,1,1094,1,1094,1,1094,1,
	1094,1,1094,1,1095,1,1095,1,1095,1,1095,1,1095,1,1095,1,1095,1,1096,1,1096,
	1,1096,1,1096,1,1096,1,1096,1,1096,1,1096,1,1096,1,1097,1,1097,1,1097,1,
	1097,1,1097,1,1097,1,1097,1,1097,1,1097,1,1098,1,1098,1,1098,1,1098,1,1098,
	1,1098,1,1098,1,1098,1,1098,1,1098,1,1098,1,1098,1,1099,1,1099,1,1099,1,
	1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,1,1099,
	1,1099,1,1099,1,1100,1,1100,1,1100,1,1100,1,1100,1,1100,1,1100,1,1100,1,
	1100,1,1100,1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,1,1101,
	1,1101,1,1101,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,1102,1,
	1102,1,1102,1,1103,1,1103,1,1103,1,1103,1,1103,1,1103,1,1103,1,1104,1,1104,
	1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,
	1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1104,1,1105,1,1105,
	1,1105,1,1105,1,1105,1,1105,1,1105,1,1105,1,1105,1,1105,1,1105,1,1105,1,
	1105,1,1105,1,1105,1,1105,1,1105,1,1105,1,1106,1,1106,1,1106,1,1106,1,1106,
	1,1106,1,1106,1,1106,1,1106,1,1106,1,1106,1,1106,1,1106,1,1106,1,1107,1,
	1107,1,1107,1,1107,1,1107,1,1107,1,1107,1,1107,1,1107,1,1107,1,1108,1,1108,
	1,1108,1,1108,1,1108,1,1108,1,1108,1,1108,1,1108,1,1108,1,1108,1,1108,1,
	1109,1,1109,1,1109,1,1109,1,1109,1,1109,1,1109,1,1109,1,1109,1,1109,1,1109,
	1,1109,1,1109,1,1109,1,1109,1,1109,1,1109,1,1110,1,1110,1,1110,1,1110,1,
	1110,1,1110,1,1110,1,1110,1,1110,1,1110,1,1110,1,1110,1,1110,1,1110,1,1110,
	1,1111,1,1111,1,1111,1,1111,1,1111,1,1111,1,1111,1,1112,1,1112,1,1112,1,
	1112,1,1112,1,1112,1,1112,1,1112,1,1112,1,1112,1,1112,1,1112,1,1112,1,1112,
	1,1112,1,1112,1,1112,1,1112,1,1112,1,1113,1,1113,1,1113,1,1113,1,1113,1,
	1113,1,1113,1,1113,1,1113,1,1113,1,1113,1,1113,1,1114,1,1114,1,1114,1,1114,
	1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,
	1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1114,1,1115,1,1115,
	1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,
	1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1115,1,1116,1,1116,
	1,1116,1,1116,1,1116,1,1116,1,1116,1,1116,1,1116,1,1116,1,1116,1,1116,1,
	1116,1,1116,1,1116,1,1117,1,1117,1,1117,1,1117,1,1117,1,1117,1,1117,1,1117,
	1,1117,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,
	1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,1,1118,
	1,1119,1,1119,1,1119,1,1119,1,1119,1,1119,1,1119,1,1119,1,1119,1,1119,1,
	1119,1,1119,1,1119,1,1119,1,1119,1,1120,1,1120,1,1120,1,1120,1,1120,1,1120,
	1,1120,1,1120,1,1120,1,1120,1,1120,1,1120,1,1120,1,1120,1,1120,1,1120,1,
	1120,1,1120,1,1120,1,1120,1,1121,1,1121,1,1121,1,1121,1,1121,1,1121,1,1121,
	1,1121,1,1121,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,
	1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,1,1122,
	1,1122,1,1122,1,1122,1,1123,1,1123,1,1123,1,1123,1,1123,1,1123,1,1123,1,
	1123,1,1123,1,1123,1,1123,1,1123,1,1123,1,1123,1,1124,1,1124,1,1124,1,1124,
	1,1124,1,1124,1,1124,1,1124,1,1125,1,1125,1,1125,1,1125,1,1125,1,1125,1,
	1125,1,1125,1,1125,1,1125,1,1125,1,1125,1,1125,1,1126,1,1126,1,1126,1,1126,
	1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,
	1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,1,1127,
	1,1127,1,1127,1,1127,1,1127,1,1127,1,1128,1,1128,1,1128,1,1128,1,1128,1,
	1128,1,1128,1,1128,1,1128,1,1128,1,1129,1,1129,1,1129,1,1129,1,1129,1,1129,
	1,1129,1,1129,1,1129,1,1129,1,1129,1,1129,1,1130,1,1130,1,1130,1,1130,1,
	1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,
	1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1130,1,1131,1,
	1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,
	1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,
	1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1131,1,1132,1,1132,1,1132,1,1132,
	1,1132,1,1132,1,1132,1,1132,1,1132,1,1132,1,1132,1,1132,1,1133,1,1133,1,
	1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,
	1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,1133,1,
	1133,1,1133,1,1134,1,1134,1,1134,1,1134,1,1134,1,1134,1,1134,1,1134,1,1134,
	1,1134,1,1134,1,1134,1,1134,1,1135,1,1135,1,1135,1,1135,1,1135,1,1135,1,
	1135,1,1135,1,1135,1,1135,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,
	1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,
	1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1136,1,1137,1,1137,
	1,1137,1,1137,1,1137,1,1137,1,1137,1,1137,1,1137,1,1137,1,1137,1,1138,1,
	1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,
	1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,1138,1,
	1138,1,1138,1,1138,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,
	1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,
	1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1139,1,1140,1,1140,1,1140,1,1140,
	1,1140,1,1140,1,1140,1,1140,1,1140,1,1140,1,1140,1,1140,1,1140,1,1140,1,
	1140,1,1140,1,1140,1,1140,1,1140,1,1140,1,1141,1,1141,1,1141,1,1141,1,1141,
	1,1141,1,1141,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,
	1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,1,1142,
	1,1142,1,1142,1,1142,1,1142,1,1143,1,1143,1,1143,1,1143,1,1143,1,1143,1,
	1143,1,1143,1,1143,1,1143,1,1143,1,1143,1,1143,1,1143,1,1143,1,1143,1,1143,
	1,1143,1,1143,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,
	1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,
	1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,1144,1,
	1144,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,
	1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,1145,1,
	1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,
	1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,1146,1,
	1147,1,1147,1,1147,1,1147,1,1147,1,1147,1,1147,1,1147,1,1147,1,1147,1,1147,
	1,1148,1,1148,1,1148,1,1148,1,1148,1,1148,1,1148,1,1148,1,1148,1,1148,1,
	1149,1,1149,1,1149,1,1149,1,1149,1,1149,1,1149,1,1150,1,1150,1,1150,1,1150,
	1,1150,1,1151,1,1151,1,1151,1,1151,1,1151,1,1151,1,1152,1,1152,1,1152,1,
	1152,1,1152,1,1152,1,1152,1,1153,1,1153,1,1153,1,1153,1,1153,1,1153,1,1153,
	1,1153,1,1154,1,1154,1,1154,1,1154,1,1154,1,1154,1,1154,1,1154,1,1154,1,
	1154,1,1154,1,1154,1,1154,1,1154,1,1154,1,1155,1,1155,1,1155,1,1155,1,1155,
	1,1155,1,1155,1,1155,1,1155,1,1155,1,1155,1,1155,1,1155,1,1155,1,1155,1,
	1155,1,1156,1,1156,1,1156,1,1156,1,1156,1,1156,1,1156,1,1156,1,1156,1,1156,
	1,1156,1,1156,1,1156,1,1156,1,1156,1,1156,1,1157,1,1157,1,1157,1,1157,1,
	1157,1,1157,1,1157,1,1157,1,1157,1,1157,1,1158,1,1158,1,1158,1,1158,1,1158,
	1,1159,1,1159,1,1159,1,1159,1,1159,1,1159,1,1159,1,1159,1,1159,1,1159,1,
	1159,1,1160,1,1160,1,1160,1,1160,1,1160,1,1160,1,1160,1,1161,1,1161,1,1161,
	1,1161,1,1161,1,1161,1,1161,1,1162,1,1162,1,1162,1,1162,1,1162,1,1162,1,
	1163,1,1163,1,1163,1,1163,1,1163,1,1163,1,1163,1,1163,1,1163,1,1163,1,1163,
	1,1163,1,1164,1,1164,1,1164,1,1164,1,1164,1,1164,1,1164,1,1164,1,1164,1,
	1164,1,1164,1,1165,1,1165,1,1165,1,1165,1,1165,1,1165,1,1165,1,1165,1,1166,
	1,1166,1,1166,1,1166,1,1167,1,1167,1,1167,1,1167,1,1167,1,1167,1,1167,1,
	1168,1,1168,1,1168,1,1169,1,1169,1,1169,1,1169,1,1169,1,1169,1,1169,1,1169,
	1,1169,1,1170,1,1170,1,1170,1,1170,1,1171,1,1171,1,1171,1,1171,1,1171,1,
	1172,1,1172,1,1172,1,1172,1,1173,1,1173,1,1173,1,1173,1,1173,1,1173,1,1173,
	1,1173,1,1173,1,1173,1,1173,1,1173,1,1173,1,1173,1,1174,1,1174,1,1174,1,
	1174,1,1175,1,1175,1,1175,1,1175,1,1175,1,1176,1,1176,1,1176,1,1176,1,1176,
	1,1177,1,1177,1,1177,1,1177,1,1178,1,1178,1,1178,1,1178,1,1178,1,1178,1,
	1178,1,1179,1,1179,1,1179,1,1179,1,1179,1,1179,1,1179,1,1179,1,1179,1,1179,
	1,1180,1,1180,1,1180,1,1180,1,1180,1,1181,1,1181,1,1181,1,1182,1,1182,1,
	1182,1,1182,1,1182,1,1182,1,1182,1,1183,1,1183,1,1183,1,1183,1,1183,1,1183,
	1,1183,1,1183,1,1183,1,1183,1,1183,1,1183,1,1183,1,1183,1,1183,1,1183,1,
	1183,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,
	1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1184,1,1185,1,1185,1,
	1185,1,1185,1,1185,1,1185,1,1185,1,1186,1,1186,1,1186,1,1186,1,1186,1,1187,
	1,1187,1,1187,1,1187,1,1187,1,1187,1,1187,1,1187,1,1187,1,1187,1,1187,1,
	1188,1,1188,1,1188,1,1188,1,1188,1,1188,1,1188,1,1188,1,1188,1,1189,1,1189,
	1,1189,1,1189,1,1189,1,1189,1,1189,1,1189,1,1189,1,1189,1,1189,1,1189,1,
	1189,1,1190,1,1190,1,1190,1,1190,1,1190,1,1190,1,1190,1,1190,1,1191,1,1191,
	1,1191,1,1191,1,1191,1,1191,1,1191,1,1191,1,1191,1,1192,1,1192,1,1192,1,
	1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,
	1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,1192,1,
	1192,1,1192,1,1193,1,1193,1,1193,1,1193,1,1193,1,1193,1,1193,1,1193,1,1193,
	1,1193,1,1193,1,1193,1,1193,1,1193,1,1193,1,1194,1,1194,1,1194,1,1194,1,
	1194,1,1194,1,1194,1,1195,1,1195,1,1195,1,1195,1,1195,1,1195,1,1195,1,1195,
	1,1195,1,1195,1,1196,1,1196,1,1196,1,1196,1,1196,1,1196,1,1196,1,1196,1,
	1196,1,1196,1,1196,1,1197,1,1197,1,1197,1,1197,1,1197,1,1197,1,1197,1,1197,
	1,1197,1,1197,1,1197,1,1197,1,1197,1,1197,1,1198,1,1198,1,1198,1,1198,1,
	1198,1,1198,1,1198,1,1198,1,1198,1,1198,1,1198,1,1198,1,1198,1,1198,1,1198,
	1,1198,1,1198,1,1198,1,1198,1,1198,1,1199,1,1199,1,1199,1,1199,1,1199,1,
	1199,1,1199,1,1199,1,1199,1,1199,1,1199,1,1199,1,1199,1,1199,1,1199,1,1200,
	1,1200,1,1200,1,1200,1,1200,1,1200,1,1200,1,1200,1,1201,1,1201,1,1201,1,
	1201,1,1201,1,1201,1,1201,1,1201,1,1201,1,1202,1,1202,1,1202,1,1202,1,1202,
	1,1202,1,1202,1,1202,1,1202,1,1202,1,1202,1,1202,1,1202,1,1202,1,1202,1,
	1202,1,1202,1,1203,1,1203,1,1203,1,1203,1,1203,1,1203,1,1203,1,1203,1,1203,
	1,1203,1,1203,1,1203,1,1203,1,1203,1,1203,1,1203,1,1204,1,1204,1,1204,1,
	1204,1,1204,1,1204,1,1204,1,1204,1,1204,1,1204,1,1204,1,1204,1,1204,1,1204,
	1,1205,1,1205,1,1205,1,1205,1,1205,1,1205,1,1205,1,1205,1,1205,1,1205,1,
	1205,1,1205,1,1205,1,1205,1,1205,1,1206,1,1206,1,1206,1,1206,1,1206,1,1206,
	1,1206,1,1206,1,1206,1,1206,1,1206,1,1206,1,1206,1,1206,1,1206,1,1206,1,
	1206,1,1206,1,1206,1,1207,1,1207,1,1207,1,1207,1,1207,1,1207,1,1207,1,1207,
	1,1207,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,
	1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,1,1208,
	1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,
	1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1209,1,1210,1,1210,1,1210,
	1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,
	1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,1,1210,
	1,1210,1,1210,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,
	1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,1,1211,
	1,1211,1,1211,1,1212,1,1212,1,1212,1,1212,1,1212,1,1212,1,1212,1,1212,1,
	1212,1,1212,1,1212,1,1212,1,1212,1,1212,1,1212,1,1213,1,1213,1,1213,1,1213,
	1,1213,1,1213,1,1213,1,1213,1,1213,1,1213,1,1213,1,1213,1,1213,1,1213,1,
	1214,1,1214,1,1214,1,1214,1,1214,1,1214,1,1214,1,1214,1,1214,1,1214,1,1214,
	1,1215,1,1215,1,1215,1,1215,1,1215,1,1215,1,1215,1,1215,1,1215,1,1215,1,
	1215,1,1215,1,1215,1,1215,1,1215,1,1216,1,1216,1,1216,1,1216,1,1216,1,1216,
	1,1216,1,1216,1,1216,1,1216,1,1216,1,1216,1,1216,1,1216,1,1216,1,1217,1,
	1217,1,1217,1,1217,1,1217,1,1217,1,1217,1,1217,1,1217,1,1217,1,1217,1,1217,
	1,1217,1,1217,1,1217,1,1218,1,1218,1,1218,1,1218,1,1218,1,1218,1,1218,1,
	1218,1,1218,1,1218,1,1218,1,1218,1,1218,1,1218,1,1218,1,1219,1,1219,1,1219,
	1,1219,1,1219,1,1219,1,1219,1,1219,1,1220,1,1220,1,1220,1,1220,1,1220,1,
	1220,1,1220,1,1220,1,1220,1,1220,1,1220,1,1220,1,1220,1,1220,1,1220,1,1220,
	1,1220,1,1220,1,1220,1,1221,1,1221,1,1221,1,1221,1,1221,1,1221,1,1222,1,
	1222,1,1222,1,1222,1,1222,1,1222,1,1222,1,1222,1,1222,1,1222,1,1222,1,1223,
	1,1223,1,1223,1,1223,1,1223,1,1223,1,1223,1,1223,1,1223,1,1223,1,1224,1,
	1224,1,1224,1,1224,1,1224,1,1224,1,1224,1,1224,1,1224,1,1224,1,1224,1,1224,
	1,1224,1,1225,1,1225,1,1225,1,1226,1,1226,1,1226,1,1226,1,1226,1,1226,1,
	1226,1,1226,1,1226,1,1226,1,1226,1,1226,1,1226,1,1226,1,1227,1,1227,1,1227,
	1,1227,1,1227,1,1227,1,1227,1,1227,1,1228,1,1228,1,1228,1,1228,1,1228,1,
	1228,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,
	1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1229,1,1230,1,
	1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,
	1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1230,1,1231,1,1231,1,
	1231,1,1231,1,1231,1,1231,1,1232,1,1232,1,1232,1,1232,1,1232,1,1232,1,1232,
	1,1232,1,1232,1,1232,1,1232,1,1232,1,1232,1,1233,1,1233,1,1233,1,1233,1,
	1233,1,1233,1,1233,1,1233,1,1234,1,1234,1,1234,1,1234,1,1234,1,1234,1,1234,
	1,1234,1,1234,1,1234,1,1234,1,1234,1,1235,1,1235,1,1235,1,1235,1,1236,1,
	1236,1,1236,1,1236,1,1236,1,1236,1,1236,1,1236,1,1236,1,1236,1,1236,1,1236,
	1,1236,1,1236,1,1236,1,1236,1,1237,1,1237,1,1237,1,1237,1,1237,1,1237,1,
	1237,1,1237,1,1237,1,1238,1,1238,1,1238,1,1238,1,1238,1,1238,1,1238,1,1238,
	1,1238,1,1239,1,1239,1,1239,1,1239,1,1239,1,1240,1,1240,1,1240,1,1240,1,
	1240,1,1240,1,1240,1,1240,1,1240,1,1240,1,1240,1,1241,1,1241,1,1241,1,1241,
	1,1241,1,1241,1,1242,1,1242,1,1242,1,1242,1,1242,1,1242,1,1242,1,1242,1,
	1242,1,1242,1,1243,1,1243,1,1243,1,1243,1,1244,1,1244,1,1245,1,1245,1,1245,
	1,1245,1,1245,1,1245,1,1245,1,1245,1,1246,1,1246,1,1246,1,1246,1,1246,1,
	1246,1,1246,1,1246,1,1246,1,1247,1,1247,1,1247,1,1247,1,1247,1,1247,1,1247,
	1,1247,1,1247,1,1247,1,1247,1,1247,1,1247,1,1247,1,1247,1,1247,1,1248,1,
	1248,1,1248,1,1248,1,1248,1,1248,1,1248,1,1248,1,1248,1,1248,1,1248,1,1248,
	1,1248,1,1248,1,1248,1,1249,1,1249,1,1249,1,1249,1,1249,1,1249,1,1249,1,
	1249,1,1249,1,1250,1,1250,1,1250,1,1250,1,1250,1,1250,1,1250,1,1250,1,1250,
	1,1250,1,1250,1,1250,1,1250,1,1250,1,1251,1,1251,1,1251,1,1251,1,1251,1,
	1251,1,1251,1,1251,1,1251,1,1251,1,1251,1,1252,1,1252,1,1252,1,1252,1,1252,
	1,1252,1,1253,1,1253,1,1253,1,1253,1,1253,1,1253,1,1253,1,1254,1,1254,1,
	1254,1,1254,1,1254,1,1254,1,1254,1,1254,1,1254,1,1254,1,1254,1,1254,1,1254,
	1,1254,1,1254,1,1254,1,1254,1,1254,1,1255,1,1255,1,1255,1,1255,1,1255,1,
	1255,1,1255,1,1255,1,1255,1,1255,1,1255,1,1255,1,1255,1,1255,1,1255,1,1255,
	1,1255,1,1255,1,1255,1,1256,1,1256,1,1256,1,1256,1,1256,1,1256,1,1256,1,
	1257,1,1257,1,1257,1,1257,1,1257,1,1257,1,1257,1,1257,1,1257,1,1257,1,1257,
	1,1257,1,1257,1,1258,1,1258,1,1258,1,1258,1,1258,1,1258,1,1258,1,1258,1,
	1258,1,1258,1,1259,1,1259,1,1259,1,1259,1,1259,1,1259,1,1259,1,1259,1,1260,
	1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,
	1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,1,1260,
	1,1260,1,1260,1,1261,1,1261,1,1261,1,1261,1,1261,1,1261,1,1261,1,1261,1,
	1261,1,1261,1,1261,1,1261,1,1261,1,1261,1,1261,1,1262,1,1262,1,1262,1,1262,
	1,1262,1,1262,1,1262,1,1262,1,1262,1,1262,1,1262,1,1262,1,1262,1,1262,1,
	1262,1,1263,1,1263,1,1263,1,1263,1,1263,1,1263,1,1263,1,1263,1,1263,1,1263,
	1,1264,1,1264,1,1264,1,1264,1,1264,1,1264,1,1264,1,1264,1,1264,1,1264,1,
	1264,1,1264,1,1264,1,1264,1,1264,1,1264,1,1265,1,1265,1,1265,1,1265,1,1265,
	1,1265,1,1265,1,1265,1,1265,1,1265,1,1265,1,1266,1,1266,1,1266,1,1266,1,
	1266,1,1266,1,1266,1,1266,1,1266,1,1266,1,1266,1,1266,1,1266,1,1266,1,1267,
	1,1267,1,1267,1,1267,1,1267,1,1267,1,1267,1,1267,1,1268,1,1268,1,1268,1,
	1268,1,1268,1,1268,1,1268,1,1268,1,1268,1,1268,1,1268,1,1268,1,1268,1,1268,
	1,1268,1,1268,1,1268,1,1268,1,1268,1,1268,1,1269,1,1269,1,1269,1,1269,1,
	1269,1,1269,1,1269,1,1269,1,1269,1,1269,1,1269,1,1269,1,1269,1,1269,1,1269,
	1,1269,1,1269,1,1269,1,1269,1,1270,1,1270,1,1270,1,1270,1,1270,1,1270,1,
	1270,1,1270,1,1270,1,1270,1,1270,1,1270,1,1270,1,1270,1,1270,1,1270,1,1270,
	1,1270,1,1270,1,1271,1,1271,1,1271,1,1271,1,1271,1,1271,1,1271,1,1271,1,
	1271,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,
	1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1272,1,1273,1,
	1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,
	1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1273,1,1274,1,1274,1,
	1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,
	1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,1274,1,
	1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,
	1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,1275,1,
	1275,1,1275,1,1275,1,1275,1,1276,1,1276,1,1276,1,1276,1,1276,1,1277,1,1277,
	1,1277,1,1277,1,1277,1,1277,1,1278,1,1278,1,1278,1,1278,1,1278,1,1279,1,
	1279,1,1279,1,1279,1,1279,1,1279,1,1279,1,1279,1,1279,1,1279,1,1279,1,1279,
	1,1280,1,1280,1,1280,1,1280,1,1280,1,1280,1,1281,1,1281,1,1281,1,1281,1,
	1281,1,1281,1,1281,1,1281,1,1282,1,1282,1,1282,1,1282,1,1282,1,1282,1,1282,
	1,1282,1,1282,1,1282,1,1282,1,1282,1,1282,1,1283,1,1283,1,1283,1,1283,1,
	1283,1,1283,1,1283,1,1283,1,1283,1,1283,1,1283,1,1284,1,1284,1,1284,1,1284,
	1,1284,1,1284,1,1284,1,1284,1,1285,1,1285,1,1285,1,1285,1,1285,1,1285,1,
	1285,1,1285,1,1285,1,1285,1,1285,1,1285,1,1286,1,1286,1,1286,1,1286,1,1286,
	1,1286,1,1286,1,1286,1,1286,1,1286,1,1286,1,1286,1,1286,1,1287,1,1287,1,
	1287,1,1287,1,1287,1,1287,1,1287,1,1287,1,1288,1,1288,1,1288,1,1288,1,1288,
	1,1288,1,1288,1,1288,1,1288,1,1288,1,1288,1,1289,1,1289,1,1289,1,1289,1,
	1289,1,1289,1,1289,1,1289,1,1290,1,1290,5,1290,18846,8,1290,10,1290,12,
	1290,18849,9,1290,1,1290,1,1290,1,1290,1,1290,1,1290,1,1290,1,1291,1,1291,
	5,1291,18859,8,1291,10,1291,12,1291,18862,9,1291,1,1291,1,1291,1,1291,1,
	1291,1,1291,1,1291,1,1291,1,1292,1,1292,5,1292,18873,8,1292,10,1292,12,
	1292,18876,9,1292,1,1292,1,1292,1,1292,1,1292,1,1292,1,1292,1,1292,1,1292,
	1,1292,1,1293,1,1293,1,1293,1,1293,1,1293,1,1293,1,1293,1,1293,1,1294,1,
	1294,1,1294,1,1294,1,1294,1,1294,1,1294,1,1294,1,1294,1,1294,1,1294,1,1294,
	1,1294,1,1294,1,1295,1,1295,5,1295,18911,8,1295,10,1295,12,1295,18914,9,
	1295,1,1295,1,1295,1,1295,1,1295,1,1295,1,1295,1,1295,1,1295,1,1295,1,1296,
	1,1296,5,1296,18927,8,1296,10,1296,12,1296,18930,9,1296,1,1296,1,1296,1,
	1296,1,1296,1,1296,1,1296,1,1296,1,1296,1,1297,1,1297,5,1297,18942,8,1297,
	10,1297,12,1297,18945,9,1297,1,1297,1,1297,1,1297,1,1297,1,1297,1,1298,
	1,1298,1,1298,1,1298,1,1298,1,1298,1,1298,1,1298,1,1298,1,1298,1,1298,1,
	1298,1,1299,1,1299,1,1299,1,1299,1,1299,1,1299,1,1299,1,1300,1,1300,1,1300,
	1,1300,1,1300,1,1300,1,1300,1,1300,1,1300,1,1300,1,1301,1,1301,1,1301,1,
	1301,1,1301,1,1301,1,1301,1,1301,1,1301,1,1301,1,1301,1,1302,1,1302,1,1302,
	1,1302,1,1302,1,1302,1,1302,1,1302,1,1303,1,1303,1,1303,1,1303,1,1304,1,
	1304,1,1304,1,1304,1,1304,1,1304,1,1305,1,1305,1,1305,1,1305,1,1305,1,1305,
	1,1305,1,1305,1,1305,1,1306,1,1306,1,1306,1,1306,1,1306,1,1306,1,1307,1,
	1307,1,1307,1,1307,1,1307,1,1307,1,1307,1,1307,1,1307,1,1307,1,1308,1,1308,
	1,1308,1,1308,1,1308,1,1309,1,1309,1,1309,1,1309,1,1309,1,1309,1,1309,1,
	1310,1,1310,1,1310,1,1310,1,1310,1,1310,1,1311,1,1311,1,1311,1,1311,1,1311,
	1,1311,1,1311,1,1311,1,1312,1,1312,1,1312,1,1312,1,1312,1,1312,1,1312,1,
	1312,1,1312,1,1312,1,1312,1,1312,1,1312,1,1312,1,1312,1,1313,1,1313,1,1313,
	1,1313,1,1313,1,1313,1,1313,1,1313,1,1313,1,1313,1,1313,1,1313,1,1313,1,
	1313,1,1313,1,1314,1,1314,1,1314,1,1314,1,1314,1,1315,1,1315,1,1315,1,1315,
	1,1315,1,1315,1,1315,1,1315,1,1315,1,1315,1,1315,1,1315,1,1315,1,1315,1,
	1315,1,1315,1,1315,1,1316,1,1316,1,1316,1,1316,1,1316,1,1316,1,1316,1,1316,
	1,1316,1,1316,1,1316,1,1316,1,1317,1,1317,1,1317,1,1317,1,1317,1,1317,1,
	1317,1,1317,1,1317,1,1317,1,1317,1,1317,1,1317,1,1317,1,1318,1,1318,1,1318,
	1,1318,1,1318,1,1318,1,1318,1,1318,1,1318,1,1318,1,1318,1,1318,1,1318,1,
	1318,1,1318,1,1318,1,1319,1,1319,1,1319,1,1319,1,1319,1,1319,1,1319,1,1319,
	1,1319,1,1319,1,1319,1,1319,1,1320,1,1320,1,1320,1,1320,1,1320,1,1320,1,
	1320,1,1320,1,1320,1,1320,1,1320,1,1320,1,1320,1,1320,1,1320,1,1320,1,1320,
	1,1320,1,1320,1,1320,1,1320,1,1321,1,1321,1,1321,1,1321,1,1321,1,1321,1,
	1321,1,1321,1,1321,1,1321,1,1321,1,1321,1,1321,1,1321,1,1321,1,1322,1,1322,
	1,1322,1,1322,1,1322,1,1322,1,1322,1,1322,1,1322,1,1322,1,1323,1,1323,1,
	1323,1,1323,1,1323,1,1324,1,1324,1,1324,1,1324,1,1324,1,1324,1,1325,1,1325,
	1,1325,1,1325,1,1325,1,1325,1,1325,1,1326,1,1326,1,1326,1,1326,1,1326,1,
	1326,1,1326,1,1326,1,1326,1,1327,1,1327,1,1327,1,1327,1,1327,1,1327,1,1327,
	1,1327,1,1328,1,1328,1,1328,1,1328,1,1328,1,1328,1,1328,1,1328,1,1328,1,
	1329,1,1329,1,1329,1,1329,1,1329,1,1329,1,1329,1,1329,1,1330,1,1330,1,1330,
	1,1330,1,1330,1,1330,1,1330,1,1330,1,1331,1,1331,1,1331,1,1331,1,1331,1,
	1331,1,1331,1,1331,1,1331,1,1331,1,1332,1,1332,1,1332,1,1332,1,1332,1,1332,
	1,1332,1,1332,1,1332,1,1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,
	1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,1333,1,1334,
	1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,
	1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,
	1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1334,1,1335,1,1335,1,1335,1,
	1335,1,1335,1,1335,1,1335,1,1335,1,1335,1,1335,1,1335,1,1335,1,1335,1,1335,
	1,1336,1,1336,1,1336,1,1336,1,1336,1,1336,1,1337,1,1337,1,1337,1,1337,1,
	1337,1,1337,1,1337,1,1337,1,1337,1,1337,1,1337,1,1337,1,1337,1,1337,1,1337,
	1,1337,1,1337,1,1337,1,1337,1,1337,1,1338,1,1338,1,1338,1,1338,1,1338,1,
	1338,1,1338,1,1338,1,1338,1,1338,1,1338,1,1338,1,1338,1,1338,1,1339,1,1339,
	1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,
	1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1339,1,1340,1,1340,
	1,1340,1,1340,1,1340,1,1340,1,1340,1,1340,1,1340,1,1340,1,1341,1,1341,1,
	1341,1,1341,1,1341,1,1341,1,1341,1,1342,1,1342,1,1342,1,1342,1,1342,1,1342,
	1,1342,1,1342,1,1342,1,1343,1,1343,1,1343,1,1343,1,1343,1,1343,1,1343,1,
	1343,1,1343,1,1343,1,1343,1,1343,1,1343,1,1344,1,1344,1,1344,1,1344,1,1344,
	1,1344,1,1344,1,1344,1,1345,1,1345,1,1345,1,1345,1,1345,1,1345,1,1345,1,
	1346,1,1346,1,1346,1,1346,1,1346,1,1346,1,1346,1,1346,1,1346,1,1347,1,1347,
	1,1347,1,1347,1,1347,1,1347,1,1347,1,1347,1,1347,1,1348,1,1348,1,1348,1,
	1348,1,1348,1,1348,1,1348,1,1348,1,1348,1,1348,1,1349,1,1349,1,1349,1,1349,
	1,1349,1,1349,1,1349,1,1349,1,1349,1,1349,1,1350,1,1350,1,1350,1,1350,1,
	1350,1,1350,1,1350,1,1350,1,1350,1,1350,1,1350,1,1350,1,1350,1,1350,1,1350,
	1,1350,1,1350,1,1350,1,1350,1,1350,1,1351,1,1351,1,1351,1,1351,1,1351,1,
	1351,1,1351,1,1351,1,1351,1,1351,1,1351,1,1351,1,1351,1,1351,1,1351,1,1351,
	1,1351,1,1351,1,1351,1,1352,1,1352,1,1352,1,1352,1,1352,1,1352,1,1352,1,
	1352,1,1353,1,1353,1,1353,1,1353,1,1353,1,1353,1,1353,1,1353,1,1354,1,1354,
	1,1354,1,1354,1,1354,1,1354,1,1354,1,1354,1,1354,1,1354,1,1354,1,1355,1,
	1355,1,1355,1,1355,1,1355,1,1355,1,1355,1,1355,1,1356,1,1356,1,1356,1,1356,
	1,1356,1,1356,1,1356,1,1356,1,1356,1,1357,1,1357,1,1357,1,1357,1,1357,1,
	1357,1,1357,1,1357,1,1357,1,1357,1,1357,1,1357,1,1357,1,1358,1,1358,1,1358,
	1,1358,1,1358,1,1358,1,1358,1,1358,1,1358,1,1359,1,1359,1,1359,1,1359,1,
	1359,1,1359,1,1359,1,1360,1,1360,1,1360,1,1360,1,1360,1,1360,1,1360,1,1360,
	1,1360,1,1361,1,1361,1,1361,1,1361,1,1361,1,1362,1,1362,1,1362,1,1362,1,
	1362,1,1362,1,1362,1,1362,1,1363,1,1363,1,1363,1,1363,1,1363,1,1363,1,1363,
	1,1363,1,1363,1,1363,1,1363,1,1363,1,1363,1,1363,1,1363,1,1363,1,1364,1,
	1364,1,1364,1,1364,1,1364,1,1364,1,1364,1,1364,1,1364,1,1365,1,1365,1,1365,
	1,1365,1,1365,1,1365,1,1366,1,1366,1,1366,1,1366,1,1366,1,1366,1,1366,1,
	1366,1,1367,1,1367,1,1367,1,1367,1,1367,1,1367,1,1367,1,1367,1,1367,1,1367,
	1,1367,1,1367,1,1368,1,1368,1,1368,1,1368,1,1368,1,1368,1,1368,1,1368,1,
	1368,1,1368,1,1368,1,1369,1,1369,1,1369,1,1369,1,1369,1,1369,1,1369,1,1369,
	1,1369,1,1369,1,1370,1,1370,1,1370,1,1370,1,1370,1,1370,1,1370,1,1370,1,
	1370,1,1370,1,1370,1,1371,1,1371,1,1371,1,1371,1,1371,1,1371,1,1371,1,1371,
	1,1371,1,1371,1,1371,1,1372,1,1372,1,1372,1,1372,1,1372,1,1372,1,1372,1,
	1372,1,1372,1,1372,1,1373,1,1373,1,1373,1,1373,1,1373,1,1373,1,1373,1,1373,
	1,1374,1,1374,1,1374,1,1374,1,1374,1,1374,1,1374,1,1374,1,1375,1,1375,1,
	1375,1,1375,1,1375,1,1375,1,1375,1,1375,1,1376,1,1376,1,1376,1,1376,1,1376,
	1,1376,1,1376,1,1376,1,1377,1,1377,1,1377,1,1377,1,1377,1,1377,1,1377,1,
	1377,1,1377,1,1377,1,1378,1,1378,1,1378,1,1378,1,1378,1,1378,1,1378,1,1378,
	1,1378,1,1379,1,1379,1,1379,1,1379,1,1379,1,1379,1,1379,1,1379,1,1379,1,
	1379,1,1380,1,1380,1,1380,1,1380,1,1380,1,1380,1,1380,1,1380,1,1380,1,1380,
	1,1380,1,1381,1,1381,1,1381,1,1381,1,1381,1,1381,1,1381,1,1381,1,1381,1,
	1382,1,1382,1,1382,1,1382,1,1382,1,1382,1,1383,1,1383,1,1383,1,1383,1,1383,
	1,1383,1,1383,1,1383,1,1384,1,1384,1,1384,1,1384,1,1384,1,1384,1,1384,1,
	1385,1,1385,1,1385,1,1385,1,1385,1,1385,1,1385,1,1385,1,1385,1,1385,1,1386,
	1,1386,1,1386,1,1386,1,1386,1,1386,1,1387,1,1387,1,1387,1,1387,1,1387,1,
	1387,1,1387,1,1387,1,1387,1,1387,1,1388,1,1388,1,1388,1,1388,1,1388,1,1388,
	1,1388,1,1388,1,1388,1,1388,1,1389,1,1389,1,1389,1,1389,1,1389,1,1389,1,
	1389,1,1389,1,1389,1,1389,1,1389,1,1389,1,1389,1,1389,1,1389,1,1389,1,1389,
	1,1389,1,1389,1,1390,1,1390,1,1390,1,1390,1,1390,1,1390,1,1390,1,1390,1,
	1390,1,1390,1,1390,1,1391,1,1391,1,1391,1,1391,1,1391,1,1391,1,1391,1,1391,
	1,1391,1,1391,1,1391,1,1391,1,1391,1,1391,1,1391,1,1392,1,1392,1,1392,1,
	1392,1,1392,1,1392,1,1392,1,1392,1,1393,1,1393,1,1393,1,1393,1,1393,1,1393,
	1,1393,1,1393,1,1393,1,1394,1,1394,1,1394,1,1394,1,1394,1,1394,1,1394,1,
	1394,1,1394,1,1394,1,1394,1,1394,1,1395,1,1395,1,1395,1,1395,1,1395,1,1395,
	1,1396,1,1396,1,1396,1,1396,1,1396,1,1396,1,1396,1,1396,1,1396,1,1396,1,
	1396,1,1397,1,1397,1,1397,1,1397,1,1397,1,1397,1,1398,1,1398,1,1398,1,1398,
	1,1398,1,1398,1,1398,1,1398,1,1398,1,1398,1,1398,1,1399,1,1399,1,1399,1,
	1399,1,1399,1,1399,1,1399,1,1399,1,1400,1,1400,1,1400,1,1400,1,1400,1,1400,
	1,1400,1,1401,1,1401,1,1401,1,1401,1,1401,1,1401,1,1402,1,1402,1,1402,1,
	1402,1,1402,1,1402,1,1402,1,1402,1,1402,1,1402,1,1402,1,1403,1,1403,1,1403,
	1,1403,1,1403,1,1403,1,1404,1,1404,1,1404,1,1404,1,1404,1,1404,1,1404,1,
	1404,1,1404,1,1404,1,1404,1,1404,1,1404,1,1405,1,1405,1,1405,1,1405,1,1405,
	1,1405,1,1405,1,1406,1,1406,1,1406,1,1406,1,1406,1,1406,1,1407,1,1407,1,
	1407,1,1407,1,1407,1,1407,1,1408,1,1408,1,1408,1,1408,1,1408,1,1408,1,1408,
	1,1408,1,1409,1,1409,1,1409,1,1409,1,1410,1,1410,1,1410,1,1410,1,1410,1,
	1410,1,1410,1,1410,1,1410,1,1411,1,1411,1,1411,1,1411,1,1411,1,1411,1,1411,
	1,1411,1,1411,1,1411,1,1412,1,1412,1,1412,1,1412,1,1413,1,1413,1,1413,1,
	1413,1,1413,1,1413,1,1413,1,1413,1,1413,1,1413,1,1413,1,1413,1,1414,1,1414,
	1,1414,1,1414,1,1414,1,1415,1,1415,1,1415,1,1415,1,1415,1,1416,1,1416,1,
	1416,1,1416,1,1416,1,1416,1,1417,1,1417,1,1417,1,1417,1,1417,1,1417,1,1418,
	1,1418,1,1418,1,1418,1,1418,1,1419,1,1419,1,1419,1,1419,1,1419,1,1419,1,
	1419,1,1419,1,1419,1,1419,1,1420,1,1420,1,1420,1,1420,1,1420,1,1420,1,1420,
	1,1420,1,1421,1,1421,1,1421,1,1421,1,1421,1,1421,1,1421,1,1422,1,1422,1,
	1422,1,1422,1,1422,1,1422,1,1422,1,1422,1,1422,1,1422,1,1422,1,1422,1,1422,
	1,1422,1,1422,1,1422,1,1422,1,1422,1,1423,1,1423,1,1423,1,1423,1,1423,1,
	1423,1,1423,1,1423,1,1423,1,1423,1,1423,1,1423,1,1424,1,1424,1,1424,1,1424,
	1,1424,1,1424,1,1424,1,1424,1,1425,1,1425,1,1425,1,1425,1,1425,1,1425,1,
	1425,1,1425,1,1425,1,1426,1,1426,1,1426,1,1426,1,1426,1,1426,1,1426,1,1426,
	1,1426,1,1426,1,1426,1,1427,1,1427,1,1427,1,1427,1,1427,1,1427,1,1427,1,
	1427,1,1428,1,1428,1,1428,1,1428,1,1428,1,1428,1,1428,1,1428,1,1428,1,1428,
	1,1429,1,1429,1,1429,1,1429,1,1429,1,1429,1,1429,1,1429,1,1429,1,1430,1,
	1430,1,1430,1,1430,1,1430,1,1431,1,1431,1,1431,1,1431,1,1431,1,1431,1,1431,
	1,1431,1,1432,1,1432,1,1432,1,1432,1,1432,1,1432,1,1432,1,1432,1,1432,1,
	1432,1,1432,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,
	1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,1433,1,
	1434,1,1434,1,1434,1,1434,1,1434,1,1434,1,1434,1,1434,1,1434,1,1434,1,1434,
	1,1435,1,1435,1,1435,1,1435,1,1435,1,1435,1,1435,1,1435,1,1435,1,1435,1,
	1436,1,1436,1,1436,1,1436,1,1436,1,1436,1,1436,1,1436,1,1436,1,1436,1,1436,
	1,1437,1,1437,1,1437,1,1437,1,1437,1,1437,1,1437,1,1437,1,1437,1,1437,1,
	1437,1,1437,1,1438,1,1438,1,1438,1,1438,1,1439,1,1439,1,1439,1,1439,1,1439,
	1,1439,1,1439,1,1439,1,1440,1,1440,1,1440,1,1440,1,1440,1,1440,1,1440,1,
	1440,1,1440,1,1441,1,1441,1,1441,1,1441,1,1441,1,1441,1,1441,1,1441,1,1441,
	1,1441,1,1441,1,1441,1,1441,1,1442,1,1442,1,1442,1,1442,1,1442,1,1442,1,
	1442,1,1442,1,1442,1,1442,1,1442,1,1442,1,1442,1,1443,1,1443,1,1443,1,1443,
	1,1443,1,1443,1,1443,1,1443,1,1443,1,1443,1,1443,1,1443,1,1444,1,1444,1,
	1444,1,1444,1,1444,1,1444,1,1444,1,1444,1,1444,1,1444,1,1444,1,1444,1,1444,
	1,1444,1,1444,1,1445,1,1445,1,1445,1,1445,1,1445,1,1445,1,1445,1,1445,1,
	1445,1,1445,1,1445,1,1445,1,1445,1,1445,1,1446,1,1446,1,1446,1,1446,1,1446,
	1,1446,1,1446,1,1446,1,1446,1,1447,1,1447,1,1447,1,1447,1,1447,1,1447,1,
	1447,1,1447,1,1447,1,1447,1,1448,1,1448,1,1448,1,1448,1,1448,1,1448,1,1448,
	1,1448,1,1448,1,1448,1,1449,1,1449,1,1449,1,1449,1,1449,1,1449,1,1449,1,
	1449,1,1449,1,1449,1,1449,1,1450,1,1450,1,1450,1,1450,1,1450,1,1450,1,1450,
	1,1450,1,1450,1,1450,1,1450,1,1450,1,1450,1,1450,1,1450,1,1451,1,1451,1,
	1451,1,1451,1,1451,1,1451,1,1451,1,1451,1,1452,1,1452,1,1452,1,1452,1,1452,
	1,1452,1,1452,1,1452,1,1452,1,1452,1,1452,1,1453,1,1453,1,1453,1,1453,1,
	1453,1,1453,1,1453,1,1453,1,1453,1,1454,1,1454,1,1454,1,1454,1,1454,1,1454,
	1,1454,1,1454,1,1454,1,1455,1,1455,1,1455,1,1455,1,1455,1,1455,1,1455,1,
	1455,1,1455,1,1456,1,1456,1,1456,1,1456,1,1456,1,1456,1,1456,1,1456,1,1457,
	1,1457,1,1457,1,1457,1,1457,1,1457,1,1457,1,1458,1,1458,1,1458,1,1458,1,
	1458,1,1458,1,1459,1,1459,1,1459,1,1459,1,1459,1,1459,1,1459,1,1459,1,1459,
	1,1459,1,1459,1,1460,1,1460,1,1460,1,1460,1,1460,1,1460,1,1460,1,1460,1,
	1460,1,1460,1,1461,1,1461,1,1461,1,1461,1,1461,1,1461,1,1461,1,1461,1,1461,
	1,1462,1,1462,1,1462,1,1462,1,1462,1,1463,1,1463,1,1463,1,1463,1,1463,1,
	1463,1,1463,1,1463,1,1463,1,1463,1,1464,1,1464,1,1464,1,1464,1,1464,1,1464,
	1,1464,1,1465,1,1465,1,1465,1,1465,1,1465,1,1465,1,1465,1,1465,1,1465,1,
	1465,1,1465,1,1465,1,1465,1,1465,1,1466,1,1466,1,1466,1,1466,1,1466,1,1466,
	1,1466,1,1467,1,1467,1,1467,1,1467,1,1467,1,1467,1,1467,1,1468,1,1468,1,
	1468,1,1468,1,1468,1,1468,1,1468,1,1469,1,1469,1,1469,1,1469,1,1469,1,1469,
	1,1469,1,1470,1,1470,1,1470,1,1470,1,1470,1,1470,1,1470,1,1470,1,1471,1,
	1471,1,1471,1,1471,1,1471,1,1471,1,1471,1,1471,1,1471,1,1471,1,1471,1,1471,
	1,1472,1,1472,1,1472,1,1472,1,1472,1,1472,1,1472,1,1472,1,1472,1,1473,1,
	1473,1,1473,1,1473,1,1473,1,1473,1,1473,1,1473,1,1473,1,1473,1,1474,1,1474,
	1,1474,1,1474,1,1474,1,1474,1,1475,1,1475,1,1475,1,1475,1,1475,1,1475,1,
	1475,1,1476,1,1476,1,1476,1,1476,1,1476,1,1476,1,1476,1,1476,1,1477,1,1477,
	1,1477,1,1477,1,1477,1,1477,1,1477,1,1477,1,1477,1,1478,1,1478,1,1478,1,
	1478,1,1478,1,1478,1,1478,1,1478,1,1478,1,1479,1,1479,1,1479,1,1479,1,1479,
	1,1479,1,1479,1,1479,1,1480,1,1480,1,1480,1,1480,1,1480,1,1480,1,1480,1,
	1480,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,
	1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,1481,1,
	1481,1,1482,1,1482,1,1482,1,1482,1,1482,1,1482,1,1482,1,1482,1,1483,1,1483,
	1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,
	1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1483,1,1484,
	1,1484,1,1484,1,1484,1,1484,1,1484,1,1484,1,1484,1,1484,1,1484,1,1484,1,
	1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,
	1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1485,1,1486,1,
	1486,1,1486,1,1486,1,1486,1,1486,1,1486,1,1486,1,1486,1,1487,1,1487,1,1487,
	1,1487,1,1487,1,1487,1,1487,1,1487,1,1487,1,1487,1,1487,1,1487,1,1487,1,
	1488,1,1488,1,1488,1,1488,1,1488,1,1488,1,1488,1,1489,1,1489,1,1489,1,1489,
	1,1489,1,1489,1,1489,1,1489,1,1489,1,1489,1,1490,1,1490,1,1490,1,1490,1,
	1490,1,1490,1,1490,1,1491,1,1491,1,1491,1,1491,1,1491,1,1491,1,1491,1,1491,
	1,1491,1,1491,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,
	1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,1,1492,
	1,1492,1,1493,1,1493,1,1493,1,1493,1,1493,1,1493,1,1493,1,1493,1,1493,1,
	1493,1,1494,1,1494,1,1494,1,1494,1,1494,1,1494,1,1494,1,1495,1,1495,1,1495,
	1,1495,1,1495,1,1495,1,1496,1,1496,1,1496,1,1496,1,1496,1,1496,1,1496,1,
	1496,1,1497,1,1497,1,1497,1,1497,1,1497,1,1497,1,1497,1,1498,1,1498,1,1498,
	1,1498,1,1498,1,1498,1,1498,1,1498,1,1498,1,1498,1,1498,1,1498,1,1498,1,
	1498,1,1498,1,1498,1,1498,1,1499,1,1499,1,1499,1,1499,1,1499,1,1499,1,1499,
	1,1499,1,1500,1,1500,1,1500,1,1500,1,1500,1,1500,1,1501,1,1501,1,1501,1,
	1501,1,1501,1,1502,1,1502,1,1502,1,1502,1,1502,1,1502,1,1502,1,1502,1,1503,
	1,1503,1,1503,1,1503,1,1503,1,1503,1,1504,1,1504,1,1504,1,1504,1,1504,1,
	1504,1,1504,1,1504,1,1504,1,1505,1,1505,1,1505,1,1505,1,1505,1,1505,1,1505,
	1,1505,1,1506,1,1506,1,1506,1,1506,1,1506,1,1506,1,1506,1,1507,1,1507,1,
	1507,1,1507,1,1507,1,1507,1,1507,1,1507,1,1507,1,1507,1,1507,1,1507,1,1507,
	1,1507,1,1507,1,1507,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,
	1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,1,1508,
	1,1508,1,1508,1,1509,1,1509,1,1509,1,1509,1,1509,1,1509,1,1510,1,1510,1,
	1510,1,1510,1,1510,1,1510,1,1510,1,1510,1,1510,1,1510,1,1510,1,1510,1,1511,
	1,1511,1,1511,1,1511,1,1511,1,1511,1,1511,1,1511,1,1511,1,1511,1,1511,1,
	1511,1,1511,1,1512,1,1512,1,1512,1,1512,1,1512,1,1512,1,1512,1,1512,1,1512,
	1,1512,1,1512,1,1513,1,1513,1,1513,1,1513,1,1513,1,1513,1,1513,1,1514,1,
	1514,1,1514,1,1514,1,1515,1,1515,1,1515,1,1515,1,1515,1,1516,1,1516,1,1516,
	1,1516,1,1516,1,1517,1,1517,1,1517,1,1517,1,1517,1,1517,1,1518,1,1518,1,
	1518,1,1518,1,1518,1,1519,1,1519,1,1519,1,1519,1,1519,1,1519,1,1520,1,1520,
	1,1520,1,1520,1,1520,1,1520,1,1520,1,1520,1,1521,1,1521,1,1521,1,1521,1,
	1521,1,1522,1,1522,1,1522,1,1522,1,1522,1,1522,1,1522,1,1523,1,1523,1,1523,
	1,1523,1,1523,1,1523,1,1523,1,1523,1,1523,1,1523,1,1523,1,1523,1,1523,1,
	1523,1,1523,1,1523,1,1523,1,1523,1,1524,1,1524,1,1524,1,1524,1,1524,1,1524,
	1,1524,1,1524,1,1524,1,1524,1,1525,1,1525,1,1525,1,1525,1,1525,1,1526,1,
	1526,1,1526,1,1526,1,1527,1,1527,1,1527,1,1527,1,1527,1,1527,1,1527,1,1527,
	1,1527,1,1527,1,1527,1,1528,1,1528,1,1528,1,1528,1,1528,1,1528,1,1529,1,
	1529,1,1529,1,1529,1,1529,1,1529,1,1529,1,1529,1,1529,1,1529,1,1529,1,1529,
	1,1529,1,1529,1,1529,1,1530,1,1530,1,1530,1,1530,1,1530,1,1531,1,1531,1,
	1531,1,1531,1,1531,1,1531,1,1531,1,1531,1,1531,1,1531,1,1532,1,1532,1,1532,
	1,1532,1,1532,1,1532,1,1532,1,1532,1,1532,1,1532,1,1532,1,1532,1,1533,1,
	1533,1,1533,1,1533,1,1533,1,1533,1,1533,1,1534,1,1534,1,1534,1,1534,1,1534,
	1,1534,1,1534,1,1534,1,1534,1,1534,1,1534,1,1534,1,1534,1,1534,1,1535,1,
	1535,1,1535,1,1535,1,1536,1,1536,1,1536,1,1536,1,1536,1,1536,1,1537,1,1537,
	1,1537,1,1537,1,1537,1,1537,1,1538,1,1538,1,1538,1,1538,1,1538,1,1538,1,
	1538,1,1539,1,1539,1,1539,1,1539,1,1539,1,1539,1,1539,1,1539,1,1539,1,1539,
	1,1539,1,1540,1,1540,1,1540,1,1540,1,1540,1,1540,1,1540,1,1540,1,1540,1,
	1540,1,1540,1,1540,1,1540,1,1541,1,1541,1,1541,1,1541,1,1541,1,1541,1,1541,
	1,1541,1,1541,1,1541,1,1541,1,1541,1,1541,1,1542,1,1542,1,1542,1,1542,1,
	1542,1,1542,1,1542,1,1542,1,1543,1,1543,1,1543,1,1543,1,1543,1,1543,1,1543,
	1,1544,1,1544,1,1544,1,1544,1,1544,1,1544,1,1544,1,1545,1,1545,1,1545,1,
	1545,1,1545,1,1545,1,1545,1,1545,1,1546,1,1546,1,1546,1,1546,1,1546,1,1546,
	1,1546,1,1547,1,1547,1,1547,1,1547,1,1547,1,1547,1,1547,1,1547,1,1547,1,
	1547,1,1547,1,1547,1,1547,1,1547,1,1547,1,1548,1,1548,1,1548,1,1548,1,1548,
	1,1548,1,1548,1,1548,1,1548,1,1548,1,1548,1,1549,1,1549,1,1549,1,1549,1,
	1549,1,1549,1,1549,1,1549,1,1549,1,1550,1,1550,1,1550,1,1550,1,1550,1,1551,
	1,1551,1,1551,1,1551,1,1551,1,1551,1,1551,1,1551,1,1551,1,1551,1,1552,1,
	1552,1,1552,1,1552,1,1552,1,1552,1,1552,1,1552,1,1552,1,1553,1,1553,1,1553,
	1,1553,1,1553,1,1553,1,1553,1,1553,1,1554,1,1554,1,1554,1,1554,1,1554,1,
	1554,1,1554,1,1554,1,1554,1,1554,1,1554,1,1554,1,1555,1,1555,1,1555,1,1555,
	1,1555,1,1555,1,1555,1,1556,1,1556,1,1556,1,1556,1,1556,1,1557,1,1557,1,
	1557,1,1557,1,1557,1,1557,1,1557,1,1557,1,1557,1,1557,1,1557,1,1557,1,1557,
	1,1557,1,1557,1,1557,1,1558,1,1558,1,1558,1,1558,1,1558,1,1558,1,1558,1,
	1558,1,1558,1,1559,1,1559,1,1559,1,1559,1,1559,1,1559,1,1559,1,1559,1,1559,
	1,1559,1,1559,1,1559,1,1559,1,1559,1,1560,1,1560,1,1560,1,1560,1,1560,1,
	1560,1,1560,1,1560,1,1560,1,1560,1,1561,1,1561,1,1561,1,1561,1,1561,1,1561,
	1,1561,1,1561,1,1561,1,1562,1,1562,1,1562,1,1562,1,1562,1,1562,1,1562,1,
	1562,1,1562,1,1562,1,1562,1,1563,1,1563,1,1563,1,1563,1,1564,1,1564,1,1564,
	1,1564,1,1564,1,1564,1,1564,1,1564,1,1564,1,1564,1,1564,1,1564,1,1564,1,
	1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,
	1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1565,1,1566,1,1566,1,1566,1,
	1566,1,1566,1,1566,1,1566,1,1567,1,1567,1,1567,1,1567,1,1567,1,1567,1,1567,
	1,1567,1,1567,1,1567,1,1567,1,1567,1,1568,1,1568,1,1568,1,1568,1,1568,1,
	1568,1,1568,1,1568,1,1568,1,1568,1,1568,1,1568,1,1568,1,1568,1,1568,1,1568,
	1,1568,1,1568,1,1568,1,1568,1,1568,1,1569,1,1569,1,1569,1,1569,1,1569,1,
	1569,1,1569,1,1569,1,1570,1,1570,1,1570,1,1570,1,1570,1,1570,1,1570,1,1570,
	1,1570,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,
	1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,1,1571,
	1,1571,1,1571,1,1571,1,1572,1,1572,1,1572,1,1572,1,1572,1,1572,1,1572,1,
	1572,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,
	1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1573,1,1574,1,1574,1,
	1574,1,1574,1,1574,1,1574,1,1574,1,1574,1,1574,1,1574,1,1574,1,1574,1,1574,
	1,1574,1,1574,1,1574,1,1575,1,1575,1,1575,1,1575,1,1575,1,1575,1,1575,1,
	1575,1,1575,1,1575,1,1575,1,1575,1,1575,1,1575,1,1576,1,1576,1,1576,1,1576,
	1,1577,1,1577,1,1577,1,1577,1,1577,1,1578,1,1578,1,1578,1,1578,1,1578,1,
	1578,1,1578,1,1578,1,1578,1,1579,1,1579,1,1579,1,1579,1,1579,1,1579,1,1579,
	1,1579,1,1579,1,1579,1,1579,1,1579,1,1580,1,1580,1,1580,1,1580,1,1580,1,
	1580,1,1580,1,1581,1,1581,1,1581,1,1581,1,1581,1,1581,1,1581,1,1581,1,1581,
	1,1581,1,1581,1,1582,1,1582,1,1582,1,1582,1,1582,1,1582,1,1582,1,1582,1,
	1582,1,1582,1,1582,1,1582,1,1583,1,1583,1,1583,1,1583,1,1583,1,1583,1,1583,
	1,1584,1,1584,1,1584,1,1584,1,1584,1,1584,1,1585,1,1585,1,1585,1,1585,1,
	1585,1,1585,1,1585,1,1585,1,1586,1,1586,1,1586,1,1586,1,1586,1,1586,1,1586,
	1,1586,1,1586,1,1586,1,1587,1,1587,1,1587,1,1587,1,1587,1,1588,1,1588,1,
	1588,1,1588,1,1588,1,1588,1,1588,1,1589,1,1589,1,1589,1,1589,1,1589,1,1589,
	1,1589,1,1589,1,1589,1,1590,1,1590,1,1590,1,1590,1,1590,1,1590,1,1590,1,
	1590,1,1590,1,1591,1,1591,1,1591,1,1591,1,1592,1,1592,1,1592,1,1592,1,1592,
	1,1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,
	1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,1593,1,1594,1,1594,1,1594,1,1594,
	1,1594,1,1594,1,1594,1,1594,1,1594,1,1594,1,1594,1,1594,1,1594,1,1594,1,
	1594,1,1594,1,1595,1,1595,1,1595,1,1595,1,1595,1,1596,1,1596,1,1596,1,1596,
	1,1596,1,1596,1,1596,1,1596,1,1596,1,1597,1,1597,1,1597,1,1597,1,1597,1,
	1597,1,1597,1,1597,1,1597,1,1597,1,1597,1,1597,1,1597,1,1597,1,1597,1,1598,
	1,1598,1,1598,1,1598,1,1598,1,1598,1,1598,1,1599,1,1599,1,1599,1,1599,1,
	1599,1,1599,1,1599,1,1600,1,1600,1,1600,1,1600,1,1600,1,1600,1,1600,1,1600,
	1,1600,1,1600,1,1600,1,1601,1,1601,1,1601,1,1601,1,1601,1,1602,1,1602,1,
	1602,1,1602,1,1603,1,1603,1,1603,1,1603,1,1603,1,1604,1,1604,1,1604,1,1604,
	1,1604,1,1604,1,1604,1,1604,1,1604,1,1604,1,1604,1,1604,1,1604,1,1604,1,
	1604,1,1604,1,1604,1,1604,1,1604,1,1605,1,1605,1,1605,1,1605,1,1605,1,1606,
	1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,
	1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,1,1606,
	1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,
	1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,1,1607,
	1,1607,1,1608,1,1608,1,1608,1,1608,1,1608,1,1608,1,1608,1,1608,1,1608,1,
	1608,1,1609,1,1609,1,1609,1,1609,1,1609,1,1609,1,1609,1,1609,1,1609,1,1610,
	1,1610,1,1610,1,1610,1,1610,1,1610,1,1610,1,1610,1,1610,1,1611,1,1611,1,
	1611,1,1611,1,1611,1,1612,1,1612,1,1612,1,1612,1,1612,1,1613,1,1613,1,1613,
	1,1613,1,1613,1,1613,1,1613,1,1613,1,1614,1,1614,1,1614,1,1614,1,1614,1,
	1614,1,1614,1,1614,1,1614,1,1614,1,1614,1,1614,1,1614,1,1614,1,1614,1,1614,
	1,1614,1,1614,1,1614,1,1614,1,1614,1,1614,1,1615,1,1615,1,1615,1,1615,1,
	1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,
	1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,1615,1,
	1616,1,1616,1,1616,1,1616,1,1616,1,1616,1,1616,1,1617,1,1617,1,1617,1,1617,
	1,1617,1,1617,1,1618,1,1618,1,1618,1,1618,1,1618,1,1618,1,1618,1,1618,1,
	1618,1,1618,1,1618,1,1618,1,1618,1,1618,1,1619,1,1619,1,1619,1,1619,1,1619,
	1,1619,1,1619,1,1620,1,1620,1,1620,1,1620,1,1620,1,1620,1,1621,1,1621,1,
	1621,1,1621,1,1621,1,1621,1,1621,1,1621,1,1621,1,1621,1,1621,1,1621,1,1622,
	1,1622,1,1622,1,1622,1,1622,1,1622,1,1622,1,1622,1,1623,1,1623,1,1623,1,
	1623,1,1623,1,1623,1,1623,1,1623,1,1623,1,1624,1,1624,1,1624,1,1624,1,1624,
	1,1624,1,1624,1,1625,1,1625,1,1625,1,1625,1,1626,1,1626,1,1626,1,1626,1,
	1626,1,1626,3,1626,22157,8,1626,1,1627,1,1627,1,1627,1,1627,1,1627,1,1627,
	1,1627,1,1627,1,1627,1,1627,1,1628,1,1628,1,1628,1,1628,1,1628,1,1628,1,
	1628,1,1628,1,1628,1,1628,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,
	1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,
	1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1629,1,1630,1,1630,1,1630,1,1630,
	1,1630,1,1631,1,1631,1,1631,1,1631,1,1631,1,1631,1,1632,1,1632,1,1632,1,
	1632,1,1632,1,1632,1,1632,1,1632,1,1632,1,1632,1,1632,1,1633,1,1633,1,1633,
	1,1633,1,1633,1,1633,1,1633,1,1633,1,1633,1,1634,1,1634,1,1634,1,1634,1,
	1634,1,1634,1,1634,1,1634,1,1634,1,1634,1,1634,1,1634,1,1634,1,1634,1,1635,
	1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,
	1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,1,1635,
	1,1635,1,1636,1,1636,1,1636,1,1636,1,1636,1,1636,1,1636,1,1636,1,1636,1,
	1637,1,1637,1,1637,1,1637,1,1637,1,1637,1,1637,1,1637,1,1638,1,1638,1,1638,
	1,1638,1,1638,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,
	1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,1,1639,
	1,1639,1,1640,1,1640,1,1640,1,1640,1,1640,1,1640,1,1641,1,1641,1,1641,1,
	1641,1,1641,1,1641,1,1641,1,1641,1,1642,1,1642,1,1642,1,1642,1,1642,1,1642,
	1,1642,1,1642,1,1642,1,1642,1,1642,1,1642,1,1642,1,1643,1,1643,1,1643,1,
	1643,1,1643,1,1643,1,1643,1,1643,1,1643,1,1643,1,1643,1,1643,1,1643,1,1643,
	1,1643,1,1643,1,1643,1,1643,1,1644,1,1644,1,1644,1,1644,1,1644,1,1644,1,
	1644,1,1644,1,1644,1,1644,1,1644,1,1645,1,1645,1,1645,1,1645,1,1645,1,1645,
	1,1645,1,1645,1,1645,1,1645,1,1646,1,1646,1,1646,1,1646,1,1646,1,1646,1,
	1647,1,1647,1,1647,1,1647,1,1647,1,1647,1,1647,1,1648,1,1648,1,1648,1,1648,
	1,1648,1,1648,1,1648,1,1648,1,1648,1,1648,1,1648,1,1649,1,1649,1,1649,1,
	1649,1,1649,1,1649,1,1649,1,1649,1,1649,1,1649,1,1649,1,1649,1,1649,1,1649,
	1,1649,1,1649,1,1649,1,1649,1,1649,1,1649,1,1650,1,1650,1,1650,1,1650,1,
	1650,1,1650,1,1650,1,1650,1,1650,1,1650,1,1650,1,1650,1,1650,1,1650,1,1650,
	1,1651,1,1651,1,1651,1,1651,1,1651,1,1651,1,1651,1,1651,1,1651,1,1651,1,
	1651,1,1651,1,1651,1,1652,1,1652,1,1652,1,1652,1,1652,1,1652,1,1652,1,1652,
	1,1652,1,1652,1,1652,1,1652,1,1652,1,1652,1,1653,1,1653,1,1653,1,1653,1,
	1653,1,1653,1,1653,1,1653,1,1653,1,1653,1,1653,1,1654,1,1654,1,1654,1,1654,
	1,1654,1,1654,1,1654,1,1654,1,1654,1,1654,1,1654,1,1654,1,1654,1,1654,1,
	1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,
	1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1655,1,1656,1,
	1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,
	1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1656,1,1657,1,1657,1,1657,1,
	1657,1,1657,1,1657,1,1657,1,1657,1,1657,1,1657,1,1657,1,1657,1,1657,1,1657,
	1,1657,1,1657,1,1657,1,1657,1,1657,1,1657,1,1658,1,1658,1,1658,1,1658,1,
	1658,1,1658,1,1658,1,1658,1,1658,1,1658,1,1658,1,1658,1,1658,1,1658,1,1658,
	1,1658,1,1658,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,
	1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,1,1659,
	1,1659,1,1660,1,1660,1,1660,1,1660,1,1660,1,1660,1,1660,1,1660,1,1660,1,
	1660,1,1660,1,1660,1,1660,1,1660,1,1660,1,1661,1,1661,1,1661,1,1661,1,1661,
	1,1661,1,1661,1,1661,1,1661,1,1661,1,1661,1,1662,1,1662,1,1662,1,1662,1,
	1662,1,1662,1,1662,1,1662,1,1662,1,1662,1,1662,1,1662,1,1663,1,1663,1,1663,
	1,1663,1,1663,1,1664,1,1664,1,1664,1,1664,1,1664,1,1664,1,1664,1,1664,1,
	1665,1,1665,1,1665,1,1665,1,1665,1,1665,1,1666,1,1666,1,1666,1,1666,1,1666,
	1,1666,1,1666,1,1666,1,1667,1,1667,1,1667,1,1667,1,1667,1,1667,1,1667,1,
	1668,1,1668,1,1668,1,1668,1,1668,1,1668,1,1668,1,1669,1,1669,1,1669,1,1669,
	1,1669,1,1669,1,1669,1,1670,1,1670,1,1670,1,1670,1,1670,1,1670,1,1670,1,
	1670,1,1670,1,1670,1,1670,1,1670,1,1670,1,1670,1,1670,1,1671,1,1671,1,1671,
	1,1671,1,1671,1,1671,1,1671,1,1671,1,1671,1,1671,1,1671,1,1671,1,1671,1,
	1672,1,1672,1,1672,1,1672,1,1672,1,1672,1,1673,1,1673,1,1673,1,1673,1,1673,
	1,1673,1,1673,1,1673,1,1673,1,1673,1,1674,1,1674,1,1674,1,1674,1,1674,1,
	1674,1,1674,1,1674,1,1674,1,1674,1,1674,1,1674,1,1675,1,1675,1,1675,1,1675,
	1,1675,1,1675,1,1675,1,1675,1,1675,1,1675,1,1675,1,1675,1,1675,1,1675,1,
	1675,1,1675,1,1675,1,1676,1,1676,1,1676,1,1676,1,1676,1,1676,1,1676,1,1676,
	1,1676,1,1676,1,1676,1,1676,1,1676,1,1676,1,1677,1,1677,1,1677,1,1677,1,
	1677,1,1677,1,1677,1,1677,1,1677,1,1677,1,1677,1,1677,1,1677,1,1678,1,1678,
	1,1678,1,1678,1,1678,1,1678,1,1678,1,1678,1,1678,1,1678,1,1678,1,1679,1,
	1679,1,1679,1,1679,1,1679,1,1679,1,1679,1,1679,1,1679,1,1679,1,1679,1,1679,
	1,1679,1,1679,1,1679,1,1679,1,1679,1,1680,1,1680,1,1680,1,1680,1,1680,1,
	1680,1,1680,1,1680,1,1680,1,1680,1,1681,1,1681,1,1681,1,1681,1,1681,1,1681,
	1,1681,1,1682,1,1682,1,1682,1,1682,1,1682,1,1682,1,1682,1,1682,1,1682,1,
	1682,1,1682,1,1682,1,1682,1,1682,1,1683,1,1683,1,1683,1,1683,1,1683,1,1683,
	1,1683,1,1683,1,1684,1,1684,1,1684,1,1684,1,1684,1,1684,1,1684,1,1684,1,
	1685,1,1685,1,1685,1,1685,1,1685,1,1685,1,1685,1,1685,1,1686,1,1686,1,1686,
	1,1686,1,1686,1,1686,1,1686,1,1686,1,1687,1,1687,1,1687,1,1687,1,1687,1,
	1687,1,1687,1,1687,1,1688,1,1688,1,1688,1,1688,1,1688,1,1688,1,1688,1,1688,
	1,1688,1,1688,1,1688,1,1689,1,1689,1,1689,1,1689,1,1689,1,1689,1,1689,1,
	1689,1,1690,1,1690,1,1690,1,1690,1,1690,1,1690,1,1690,1,1690,1,1691,1,1691,
	1,1691,1,1691,1,1691,1,1691,1,1691,1,1691,1,1691,1,1691,1,1691,1,1691,1,
	1691,1,1692,1,1692,1,1692,1,1692,1,1692,1,1692,1,1692,1,1692,1,1693,1,1693,
	1,1693,1,1693,1,1693,1,1693,1,1693,1,1693,1,1693,1,1693,1,1693,1,1693,1,
	1693,1,1693,1,1693,1,1693,1,1693,1,1694,1,1694,1,1694,1,1694,1,1694,1,1694,
	1,1694,1,1694,1,1694,1,1694,1,1694,1,1695,1,1695,1,1695,1,1695,1,1695,1,
	1695,1,1695,1,1696,1,1696,1,1696,1,1696,1,1696,1,1696,1,1696,1,1696,1,1696,
	1,1696,1,1696,1,1696,1,1697,1,1697,1,1697,1,1697,1,1697,1,1698,1,1698,1,
	1698,1,1698,1,1698,1,1698,1,1698,1,1698,1,1699,1,1699,1,1699,1,1699,1,1700,
	1,1700,1,1700,1,1700,1,1700,1,1700,1,1700,1,1701,1,1701,1,1701,1,1701,1,
	1701,1,1701,1,1701,1,1701,1,1701,1,1701,1,1702,1,1702,1,1702,1,1702,1,1702,
	1,1702,1,1702,1,1703,1,1703,1,1703,1,1703,1,1703,1,1703,1,1703,1,1703,1,
	1703,1,1703,1,1704,1,1704,1,1704,1,1704,1,1704,1,1704,1,1704,1,1704,1,1704,
	1,1704,1,1704,1,1704,1,1704,1,1705,1,1705,1,1705,1,1705,1,1705,1,1705,1,
	1705,1,1705,1,1705,1,1705,1,1705,1,1705,1,1705,1,1705,1,1705,1,1705,1,1705,
	1,1705,1,1705,1,1705,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,
	1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,1,1706,
	1,1706,1,1706,1,1707,1,1707,1,1707,1,1707,1,1707,1,1707,1,1707,1,1707,1,
	1707,1,1707,1,1707,1,1707,1,1708,1,1708,1,1708,1,1708,1,1708,1,1708,1,1708,
	1,1708,1,1709,1,1709,1,1709,1,1709,1,1709,1,1709,1,1709,1,1710,1,1710,1,
	1710,1,1710,1,1710,1,1710,1,1710,1,1710,1,1710,1,1710,1,1710,1,1710,1,1710,
	1,1711,1,1711,1,1711,1,1711,1,1711,1,1711,1,1712,1,1712,1,1712,1,1712,1,
	1712,1,1712,1,1712,1,1712,1,1712,1,1712,1,1712,1,1712,1,1712,1,1712,1,1713,
	1,1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,
	1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,1713,1,1714,1,1714,1,1714,1,1714,
	1,1714,1,1714,1,1714,1,1714,1,1714,1,1714,1,1714,1,1714,1,1714,1,1714,1,
	1714,1,1714,1,1714,1,1714,1,1715,1,1715,1,1715,1,1715,1,1715,1,1715,1,1715,
	1,1715,1,1715,1,1715,1,1715,1,1715,1,1715,1,1715,1,1715,1,1715,1,1716,1,
	1716,1,1716,1,1716,1,1716,1,1716,1,1716,1,1716,1,1716,1,1716,1,1716,1,1716,
	1,1716,1,1716,1,1716,1,1716,1,1716,1,1717,1,1717,1,1717,1,1717,1,1717,1,
	1717,1,1717,1,1717,1,1717,1,1717,1,1717,1,1717,1,1717,1,1717,1,1717,1,1717,
	1,1717,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,
	1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,1,1718,
	1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,
	1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,1,1719,
	1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,
	1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,1,1720,
	1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,
	1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1721,1,1722,
	1,1722,1,1722,1,1722,1,1722,1,1722,1,1722,1,1722,1,1722,1,1722,1,1722,1,
	1722,1,1722,1,1723,1,1723,1,1723,1,1723,1,1723,1,1723,1,1723,1,1723,1,1723,
	1,1723,1,1723,1,1723,1,1723,1,1723,1,1723,1,1723,1,1724,1,1724,1,1724,1,
	1724,1,1724,1,1724,1,1724,1,1724,1,1724,1,1724,1,1724,1,1724,1,1724,1,1724,
	1,1724,1,1725,1,1725,1,1725,1,1725,1,1725,1,1725,1,1725,1,1725,1,1725,1,
	1725,1,1725,1,1725,1,1725,1,1725,1,1725,1,1725,1,1726,1,1726,1,1726,1,1726,
	1,1726,1,1726,1,1726,1,1726,1,1726,1,1726,1,1726,1,1726,1,1726,1,1726,1,
	1727,1,1727,1,1727,1,1727,1,1727,1,1727,1,1727,1,1727,1,1727,1,1727,1,1727,
	1,1727,1,1727,1,1727,1,1728,1,1728,1,1728,1,1728,1,1728,1,1728,1,1728,1,
	1728,1,1728,1,1728,1,1728,1,1728,1,1728,1,1728,1,1728,1,1729,1,1729,1,1729,
	1,1729,1,1729,1,1729,1,1729,1,1729,1,1729,1,1729,1,1729,1,1729,1,1729,1,
	1729,1,1729,1,1729,1,1729,1,1729,1,1729,1,1730,1,1730,1,1730,1,1730,1,1730,
	1,1730,1,1730,1,1730,1,1730,1,1730,1,1730,1,1730,1,1730,1,1730,1,1730,1,
	1730,1,1730,1,1730,1,1730,1,1731,1,1731,1,1731,1,1731,1,1731,1,1731,1,1731,
	1,1731,1,1731,1,1731,1,1731,1,1731,1,1731,1,1731,1,1731,1,1732,1,1732,1,
	1732,1,1732,1,1732,1,1732,1,1732,1,1732,1,1732,1,1732,1,1732,1,1732,1,1732,
	1,1732,1,1733,1,1733,1,1733,1,1733,1,1733,1,1733,1,1733,1,1733,1,1733,1,
	1734,1,1734,1,1734,1,1734,1,1734,1,1734,1,1734,1,1734,1,1735,1,1735,1,1735,
	1,1735,1,1735,1,1735,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,
	1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,1,1736,
	1,1736,1,1737,1,1737,1,1737,1,1737,1,1737,1,1737,1,1737,1,1737,1,1737,1,
	1737,1,1737,1,1737,1,1738,1,1738,1,1738,1,1738,1,1738,1,1738,1,1738,1,1738,
	1,1738,1,1738,1,1738,1,1738,1,1738,1,1738,1,1739,1,1739,1,1739,1,1739,1,
	1739,1,1739,1,1739,1,1739,1,1739,1,1739,1,1740,1,1740,1,1740,1,1740,1,1740,
	1,1740,1,1740,1,1741,1,1741,1,1741,1,1741,1,1741,1,1741,1,1741,1,1741,1,
	1741,1,1741,1,1741,1,1741,1,1741,1,1741,1,1741,1,1742,1,1742,1,1742,1,1742,
	1,1742,1,1742,1,1742,1,1742,1,1742,1,1742,1,1742,1,1742,1,1742,1,1742,1,
	1742,1,1743,1,1743,1,1743,1,1743,1,1743,1,1743,1,1743,1,1743,1,1743,1,1743,
	1,1743,1,1743,1,1743,1,1743,1,1743,1,1743,1,1744,1,1744,1,1744,1,1744,1,
	1744,1,1744,1,1744,1,1744,1,1744,1,1744,1,1744,1,1745,1,1745,1,1745,1,1745,
	1,1745,1,1745,1,1745,1,1745,1,1745,1,1745,1,1745,1,1745,1,1745,1,1745,1,
	1745,1,1746,1,1746,1,1746,1,1746,1,1746,1,1746,1,1746,1,1746,1,1746,1,1746,
	1,1746,1,1746,1,1746,1,1746,1,1747,1,1747,1,1747,1,1747,1,1747,1,1747,1,
	1747,1,1747,1,1747,1,1747,1,1747,1,1747,1,1748,1,1748,1,1748,1,1748,1,1748,
	1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,
	1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1748,1,1749,
	1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,
	1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1749,1,1750,1,1750,
	1,1750,1,1750,1,1750,1,1750,1,1750,1,1750,1,1750,1,1750,1,1750,1,1751,1,
	1751,1,1751,1,1751,1,1751,1,1751,1,1751,1,1751,1,1751,1,1751,1,1751,1,1751,
	1,1752,1,1752,1,1752,1,1752,1,1752,1,1752,1,1752,1,1752,1,1752,1,1752,1,
	1752,1,1753,1,1753,1,1753,1,1753,1,1753,1,1753,1,1753,1,1753,1,1753,1,1753,
	1,1753,1,1753,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,
	1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,1,1754,
	1,1754,1,1754,1,1755,1,1755,1,1755,1,1755,1,1755,1,1755,1,1755,1,1755,1,
	1755,1,1755,1,1755,1,1755,1,1756,1,1756,1,1756,1,1756,1,1756,1,1756,1,1756,
	1,1756,1,1756,1,1756,1,1756,1,1756,1,1756,1,1756,1,1756,1,1757,1,1757,1,
	1757,1,1757,1,1757,1,1757,1,1757,1,1757,1,1757,1,1757,1,1757,1,1757,1,1757,
	1,1757,1,1757,1,1757,1,1758,1,1758,1,1758,1,1758,1,1758,1,1758,1,1758,1,
	1758,1,1758,1,1758,1,1758,1,1758,1,1758,1,1758,1,1759,1,1759,1,1759,1,1759,
	1,1759,1,1759,1,1759,1,1759,1,1759,1,1759,1,1759,1,1759,1,1759,1,1759,1,
	1759,1,1759,1,1759,1,1759,1,1760,1,1760,1,1760,1,1760,1,1760,1,1760,1,1760,
	1,1760,1,1760,1,1760,1,1760,1,1761,1,1761,1,1761,1,1761,1,1761,1,1761,1,
	1761,1,1761,1,1761,1,1761,1,1761,1,1761,1,1761,1,1761,1,1761,1,1761,1,1761,
	1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,
	1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1762,1,1763,1,1763,1,1763,
	1,1763,1,1763,1,1763,1,1763,1,1763,1,1763,1,1763,1,1763,1,1763,1,1763,1,
	1763,1,1764,1,1764,1,1764,1,1764,1,1764,1,1764,1,1764,1,1764,1,1764,1,1764,
	1,1764,1,1764,1,1764,1,1764,1,1764,1,1765,1,1765,1,1765,1,1765,1,1765,1,
	1765,1,1765,1,1765,1,1765,1,1765,1,1765,1,1765,1,1765,1,1765,1,1765,1,1765,
	1,1766,1,1766,1,1766,1,1766,1,1766,1,1766,1,1766,1,1766,1,1766,1,1766,1,
	1766,1,1767,1,1767,1,1767,1,1767,1,1767,1,1767,1,1767,1,1767,1,1767,1,1767,
	1,1767,1,1767,1,1768,1,1768,1,1768,1,1768,1,1768,1,1768,1,1768,1,1768,1,
	1768,1,1768,1,1768,1,1768,1,1768,1,1768,1,1768,1,1768,1,1769,1,1769,1,1769,
	1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,
	1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,1,1769,
	1,1769,1,1770,1,1770,1,1770,1,1770,1,1770,1,1770,1,1770,1,1770,1,1771,1,
	1771,1,1771,1,1771,1,1771,1,1771,1,1771,1,1771,1,1771,1,1771,1,1771,1,1771,
	1,1771,1,1771,1,1771,1,1772,1,1772,1,1772,1,1772,1,1772,1,1772,1,1772,1,
	1772,1,1772,1,1772,1,1772,1,1772,1,1772,1,1772,1,1772,1,1772,1,1773,1,1773,
	1,1773,1,1773,1,1773,1,1773,1,1773,1,1773,1,1773,1,1773,1,1773,1,1773,1,
	1774,1,1774,1,1774,1,1774,1,1774,1,1774,1,1774,1,1774,1,1774,1,1774,1,1774,
	1,1774,1,1775,1,1775,1,1775,1,1775,1,1775,1,1775,1,1775,1,1775,1,1775,1,
	1775,1,1775,1,1776,1,1776,1,1776,1,1776,1,1776,1,1776,1,1776,1,1776,1,1776,
	1,1776,1,1776,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,
	1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,
	1,1777,1,1777,1,1777,1,1777,1,1777,1,1777,1,1778,1,1778,1,1778,1,1778,1,
	1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,
	1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,1778,1,
	1778,1,1778,1,1778,1,1778,1,1778,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,
	1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,
	1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1779,1,1780,1,1780,
	1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,
	1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,1,1780,
	1,1780,1,1780,1,1780,1,1780,1,1780,1,1781,1,1781,1,1781,1,1781,1,1781,1,
	1781,1,1781,1,1781,1,1781,1,1781,1,1781,1,1781,1,1781,1,1781,1,1781,1,1781,
	1,1781,1,1781,1,1781,1,1781,1,1781,1,1781,1,1782,1,1782,1,1782,1,1782,1,
	1782,1,1782,1,1782,1,1782,1,1782,1,1782,1,1782,1,1782,1,1783,1,1783,1,1783,
	1,1783,1,1783,1,1783,1,1783,1,1783,1,1783,1,1783,1,1783,1,1783,1,1783,1,
	1783,1,1783,1,1783,1,1783,1,1783,1,1783,1,1784,1,1784,1,1784,1,1784,1,1784,
	1,1784,1,1784,1,1784,1,1784,1,1784,1,1784,1,1784,1,1784,1,1784,1,1784,1,
	1784,1,1784,1,1784,1,1784,1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,
	1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,1,1785,1,
	1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,
	1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1786,1,1787,1,
	1787,1,1787,1,1787,1,1787,1,1787,1,1787,1,1787,1,1787,1,1787,1,1787,1,1787,
	1,1787,1,1787,1,1787,1,1787,1,1787,1,1787,1,1788,1,1788,1,1788,1,1788,1,
	1788,1,1788,1,1788,1,1788,1,1788,1,1788,1,1788,1,1788,1,1789,1,1789,1,1789,
	1,1789,1,1789,1,1789,1,1789,1,1789,1,1789,1,1789,1,1789,1,1790,1,1790,1,
	1790,1,1790,1,1790,1,1790,1,1790,1,1790,1,1790,1,1790,1,1790,1,1790,1,1790,
	1,1790,1,1790,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,
	1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,1,1791,
	1,1792,1,1792,1,1792,1,1792,1,1792,1,1792,1,1792,1,1792,1,1792,1,1792,1,
	1792,1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,
	1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,1,1793,1,1794,1,1794,1,1794,1,
	1794,1,1794,1,1794,1,1794,1,1794,1,1794,1,1794,1,1794,1,1794,1,1794,1,1794,
	1,1794,1,1794,1,1794,1,1795,1,1795,1,1795,1,1795,1,1795,1,1795,1,1795,1,
	1795,1,1795,1,1795,1,1795,1,1796,1,1796,1,1796,1,1796,1,1796,1,1796,1,1796,
	1,1796,1,1796,1,1796,1,1796,1,1797,1,1797,1,1797,1,1797,1,1797,1,1797,1,
	1797,1,1797,1,1797,1,1797,1,1797,1,1797,1,1797,1,1797,1,1797,1,1797,1,1798,
	1,1798,1,1798,1,1798,1,1798,1,1798,1,1798,1,1798,1,1798,1,1798,1,1798,1,
	1798,1,1798,1,1798,1,1798,1,1799,1,1799,1,1799,1,1799,1,1799,1,1799,1,1799,
	1,1799,1,1799,1,1799,1,1799,1,1799,1,1799,1,1799,1,1799,1,1799,1,1800,1,
	1800,1,1800,1,1800,1,1800,1,1800,1,1800,1,1800,1,1800,1,1800,1,1800,1,1800,
	1,1800,1,1800,1,1800,1,1800,1,1801,1,1801,1,1801,1,1801,1,1801,1,1801,1,
	1801,1,1801,1,1801,1,1801,1,1801,1,1801,1,1801,1,1801,1,1801,1,1802,1,1802,
	1,1802,1,1802,1,1802,1,1802,1,1802,1,1802,1,1802,1,1802,1,1802,1,1802,1,
	1802,1,1803,1,1803,1,1803,1,1803,1,1803,1,1803,1,1803,1,1803,1,1803,1,1803,
	1,1803,1,1803,1,1803,1,1803,1,1803,1,1803,1,1804,1,1804,1,1804,1,1804,1,
	1804,1,1804,1,1804,1,1804,1,1804,1,1804,1,1804,1,1804,1,1804,1,1804,1,1805,
	1,1805,1,1805,1,1805,1,1805,1,1805,1,1805,1,1805,1,1805,1,1805,1,1805,1,
	1806,1,1806,1,1806,1,1806,1,1806,1,1806,1,1806,1,1806,1,1806,1,1806,1,1806,
	1,1806,1,1806,1,1806,1,1806,1,1807,1,1807,1,1807,1,1807,1,1807,1,1807,1,
	1807,1,1807,1,1807,1,1807,1,1807,1,1807,1,1807,1,1808,1,1808,1,1808,1,1808,
	1,1808,1,1808,1,1808,1,1808,1,1808,1,1808,1,1808,1,1809,1,1809,1,1809,1,
	1809,1,1809,1,1809,1,1809,1,1809,1,1809,1,1809,1,1809,1,1809,1,1809,1,1809,
	1,1809,1,1809,1,1810,1,1810,1,1810,1,1810,1,1810,1,1810,1,1810,1,1810,1,
	1810,1,1810,1,1810,1,1810,1,1811,1,1811,1,1811,1,1811,1,1811,1,1811,1,1811,
	1,1811,1,1811,1,1811,1,1811,1,1812,1,1812,1,1812,1,1812,1,1812,1,1812,1,
	1812,1,1812,1,1812,1,1812,1,1812,1,1812,1,1813,1,1813,1,1813,1,1813,1,1813,
	1,1813,1,1813,1,1813,1,1813,1,1813,1,1813,1,1813,1,1813,1,1813,1,1813,1,
	1813,1,1813,1,1813,1,1814,1,1814,1,1814,1,1814,1,1814,1,1814,1,1814,1,1814,
	1,1814,1,1814,1,1814,1,1815,1,1815,1,1815,1,1815,1,1815,1,1815,1,1815,1,
	1815,1,1815,1,1815,1,1815,1,1815,1,1815,1,1815,1,1815,1,1815,1,1816,1,1816,
	1,1816,1,1816,1,1816,1,1816,1,1816,1,1816,1,1816,1,1816,1,1816,1,1816,1,
	1816,1,1816,1,1816,1,1817,1,1817,1,1817,1,1817,1,1817,1,1817,1,1817,1,1817,
	1,1817,1,1817,1,1817,1,1818,1,1818,1,1818,1,1818,1,1818,1,1818,1,1818,1,
	1818,1,1818,1,1818,1,1818,1,1818,1,1819,1,1819,1,1819,1,1819,1,1819,1,1819,
	1,1819,1,1819,1,1819,1,1819,1,1819,1,1819,1,1819,1,1820,1,1820,1,1820,1,
	1820,1,1820,1,1820,1,1820,1,1820,1,1820,1,1820,1,1820,1,1820,1,1820,1,1820,
	1,1820,1,1820,1,1820,1,1820,1,1821,1,1821,1,1821,1,1821,1,1821,1,1821,1,
	1821,1,1821,1,1821,1,1821,1,1821,1,1821,1,1821,1,1821,1,1821,1,1821,1,1821,
	1,1822,1,1822,1,1822,1,1822,1,1822,1,1822,1,1822,1,1822,1,1822,1,1822,1,
	1822,1,1822,1,1822,1,1822,1,1823,1,1823,1,1823,1,1823,1,1823,1,1823,1,1823,
	1,1823,1,1823,1,1823,1,1823,1,1823,1,1823,1,1823,1,1824,1,1824,1,1824,1,
	1824,1,1824,1,1824,1,1824,1,1824,1,1824,1,1824,1,1824,1,1824,1,1824,1,1825,
	1,1825,1,1825,1,1825,1,1825,1,1825,1,1825,1,1825,1,1825,1,1825,1,1825,1,
	1825,1,1825,1,1825,1,1826,1,1826,1,1826,1,1826,1,1826,1,1826,1,1826,1,1826,
	1,1826,1,1826,1,1826,1,1826,1,1826,1,1826,1,1826,1,1827,1,1827,1,1827,1,
	1827,1,1827,1,1827,1,1827,1,1827,1,1827,1,1827,1,1827,1,1827,1,1828,1,1828,
	1,1828,1,1828,1,1828,1,1828,1,1828,1,1828,1,1828,1,1828,1,1828,1,1829,1,
	1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,
	1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,1829,1,
	1829,1,1830,1,1830,1,1830,1,1830,1,1830,1,1830,1,1830,1,1830,1,1830,1,1830,
	1,1830,1,1830,1,1830,1,1830,1,1830,1,1831,1,1831,1,1831,1,1831,1,1831,1,
	1831,1,1831,1,1831,1,1831,1,1831,1,1831,1,1831,1,1831,1,1831,1,1831,1,1832,
	1,1832,1,1832,1,1832,1,1832,1,1832,1,1832,1,1832,1,1832,1,1832,1,1832,1,
	1832,1,1832,1,1832,1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,
	1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,1,1833,1,1834,1,
	1834,1,1834,1,1834,1,1834,1,1834,1,1834,1,1834,1,1834,1,1834,1,1834,1,1834,
	1,1834,1,1834,1,1834,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,
	1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,1,1835,
	1,1835,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,
	1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,1,1836,
	1,1836,1,1836,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,
	1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,1,1837,
	1,1837,1,1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,
	1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,1838,1,1839,1,1839,1,1839,
	1,1839,1,1839,1,1839,1,1839,1,1839,1,1839,1,1839,1,1839,1,1839,1,1839,1,
	1839,1,1839,1,1839,1,1839,1,1839,1,1839,1,1840,1,1840,1,1840,1,1840,1,1840,
	1,1840,1,1840,1,1840,1,1840,1,1840,1,1840,1,1840,1,1840,1,1840,1,1840,1,
	1840,1,1840,1,1840,1,1840,1,1840,1,1841,1,1841,1,1841,1,1841,1,1841,1,1841,
	1,1841,1,1841,1,1841,1,1841,1,1841,1,1841,1,1841,1,1841,1,1841,1,1841,1,
	1841,1,1841,1,1841,1,1841,1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,
	1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,1,1842,1,
	1842,1,1842,1,1842,1,1842,1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,
	1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,1,1843,1,
	1844,1,1844,1,1844,1,1844,1,1844,1,1844,1,1844,1,1844,1,1844,1,1844,1,1844,
	1,1844,1,1844,1,1844,1,1844,1,1845,1,1845,1,1845,1,1845,1,1845,1,1845,1,
	1845,1,1845,1,1845,1,1845,1,1845,1,1845,1,1845,1,1845,1,1845,1,1845,1,1846,
	1,1846,1,1846,1,1846,1,1846,1,1846,1,1846,1,1846,1,1846,1,1846,1,1846,1,
	1846,1,1846,1,1846,1,1847,1,1847,1,1847,1,1847,1,1847,1,1847,1,1847,1,1847,
	1,1847,1,1847,1,1847,1,1847,1,1847,1,1847,1,1848,1,1848,1,1848,1,1848,1,
	1848,1,1848,1,1848,1,1848,1,1848,1,1848,1,1848,1,1848,1,1848,1,1848,1,1849,
	1,1849,1,1849,1,1849,1,1849,1,1849,1,1849,1,1849,1,1849,1,1849,1,1849,1,
	1849,1,1849,1,1849,1,1849,1,1850,1,1850,1,1850,1,1850,1,1850,1,1850,1,1850,
	1,1850,1,1850,1,1850,1,1850,1,1850,1,1850,1,1850,1,1850,1,1851,1,1851,1,
	1851,1,1851,1,1851,1,1851,1,1851,1,1852,1,1852,1,1852,1,1852,1,1852,1,1852,
	1,1852,1,1852,1,1852,1,1852,1,1852,1,1852,1,1852,1,1853,1,1853,1,1853,1,
	1853,1,1853,1,1853,1,1853,1,1853,1,1853,1,1853,1,1853,1,1854,1,1854,1,1854,
	1,1854,1,1854,1,1854,1,1854,1,1854,1,1854,1,1854,1,1854,1,1854,1,1854,1,
	1855,1,1855,1,1855,1,1855,1,1855,1,1855,1,1855,1,1855,1,1855,1,1855,1,1855,
	1,1855,1,1855,1,1855,1,1855,1,1856,1,1856,1,1856,1,1856,1,1856,1,1856,1,
	1856,1,1856,1,1856,1,1856,1,1856,1,1856,1,1856,1,1856,1,1856,1,1856,1,1857,
	1,1857,1,1857,1,1857,1,1857,1,1857,1,1857,1,1857,1,1857,1,1857,1,1857,1,
	1857,1,1858,1,1858,1,1858,1,1858,1,1858,1,1858,1,1858,1,1858,1,1858,1,1858,
	1,1858,1,1858,1,1858,1,1858,1,1858,1,1859,1,1859,1,1859,1,1859,1,1859,1,
	1859,1,1859,1,1859,1,1859,1,1859,1,1859,1,1860,1,1860,1,1860,1,1860,1,1860,
	1,1860,1,1860,1,1860,1,1860,1,1860,1,1860,1,1860,1,1860,1,1860,1,1860,1,
	1860,1,1860,1,1860,1,1860,1,1860,1,1861,1,1861,1,1861,1,1861,1,1861,1,1861,
	1,1861,1,1861,1,1861,1,1861,1,1861,1,1861,1,1861,1,1861,1,1861,1,1861,1,
	1861,1,1861,1,1861,1,1861,1,1862,1,1862,1,1862,1,1862,1,1862,1,1862,1,1862,
	1,1862,1,1862,1,1862,1,1862,1,1862,1,1862,1,1863,1,1863,1,1863,1,1863,1,
	1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,
	1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1863,1,1864,1,1864,1,
	1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,
	1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1864,1,1865,1,1865,1,
	1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,
	1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,1865,1,
	1865,1,1865,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,
	1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,1866,1,
	1866,1,1866,1,1866,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,
	1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,
	1867,1,1867,1,1867,1,1867,1,1867,1,1867,1,1868,1,1868,1,1868,1,1868,1,1868,
	1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,
	1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1868,1,1869,1,1869,1,1869,
	1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,
	1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1869,1,1870,1,1870,1,1870,
	1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,
	1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1870,1,1871,1,1871,
	1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,
	1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1871,1,1872,1,1872,
	1,1872,1,1872,1,1872,1,1872,1,1872,1,1872,1,1872,1,1872,1,1872,1,1872,1,
	1872,1,1872,1,1873,1,1873,1,1873,1,1873,1,1873,1,1873,1,1873,1,1873,1,1873,
	1,1873,1,1873,1,1873,1,1873,1,1873,1,1874,1,1874,1,1874,1,1874,1,1874,1,
	1874,1,1874,1,1874,1,1874,1,1874,1,1874,1,1874,1,1874,1,1874,1,1874,1,1874,
	1,1874,1,1875,1,1875,1,1875,1,1875,1,1875,1,1875,1,1875,1,1875,1,1875,1,
	1875,1,1875,1,1875,1,1875,1,1875,1,1875,1,1875,1,1876,1,1876,1,1876,1,1876,
	1,1876,1,1876,1,1876,1,1876,1,1876,1,1876,1,1876,1,1876,1,1876,1,1876,1,
	1876,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,
	1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1877,1,1878,1,1878,1,
	1878,1,1878,1,1878,1,1878,1,1878,1,1878,1,1878,1,1878,1,1878,1,1878,1,1878,
	1,1878,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,
	1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1879,1,1880,
	1,1880,1,1880,1,1880,1,1880,1,1880,1,1880,1,1880,1,1880,1,1880,1,1880,1,
	1880,1,1880,1,1880,1,1880,1,1880,1,1881,1,1881,1,1881,1,1881,1,1881,1,1881,
	1,1881,1,1881,1,1881,1,1881,1,1881,1,1881,1,1881,1,1881,1,1882,1,1882,1,
	1882,1,1882,1,1882,1,1882,1,1882,1,1882,1,1882,1,1882,1,1882,1,1882,1,1882,
	1,1882,1,1883,1,1883,1,1883,1,1883,1,1883,1,1883,1,1883,1,1883,1,1883,1,
	1883,1,1883,1,1883,1,1883,1,1884,1,1884,1,1884,1,1884,1,1884,1,1884,1,1884,
	1,1884,1,1884,1,1884,1,1884,1,1884,1,1884,1,1885,1,1885,1,1885,1,1885,1,
	1885,1,1885,1,1885,1,1885,1,1885,1,1885,1,1885,1,1885,1,1885,1,1885,1,1885,
	1,1885,1,1885,1,1885,1,1885,1,1886,1,1886,1,1886,1,1886,1,1886,1,1886,1,
	1886,1,1886,1,1886,1,1886,1,1887,1,1887,1,1887,1,1887,1,1887,1,1887,1,1887,
	1,1887,1,1887,1,1887,1,1887,1,1887,1,1887,1,1888,1,1888,1,1888,1,1888,1,
	1888,1,1888,1,1888,1,1888,1,1888,1,1888,1,1888,1,1888,1,1888,1,1889,1,1889,
	1,1889,1,1889,1,1889,1,1889,1,1889,1,1889,1,1889,1,1889,1,1889,1,1889,1,
	1889,1,1890,1,1890,1,1890,1,1890,1,1890,1,1890,1,1890,1,1890,1,1890,1,1890,
	1,1890,1,1890,1,1890,1,1890,1,1890,1,1891,1,1891,1,1891,1,1891,1,1891,1,
	1891,1,1891,1,1891,1,1891,1,1891,1,1891,1,1892,1,1892,1,1892,1,1892,1,1892,
	1,1892,1,1892,1,1892,1,1892,1,1892,1,1893,1,1893,1,1893,1,1893,1,1893,1,
	1893,1,1893,1,1893,1,1893,1,1893,1,1893,1,1893,1,1893,1,1893,1,1893,1,1893,
	1,1894,1,1894,1,1894,1,1894,1,1894,1,1894,1,1894,1,1894,1,1894,1,1894,1,
	1894,1,1894,1,1894,1,1894,1,1894,1,1895,1,1895,1,1895,1,1895,1,1895,1,1895,
	1,1895,1,1895,1,1895,1,1895,1,1895,1,1895,1,1895,1,1895,1,1896,1,1896,1,
	1896,1,1896,1,1896,1,1896,1,1896,1,1896,1,1896,1,1896,1,1896,1,1896,1,1896,
	1,1896,1,1897,1,1897,1,1897,1,1897,1,1897,1,1897,1,1897,1,1897,1,1897,1,
	1897,1,1897,1,1897,1,1897,1,1897,1,1898,1,1898,1,1898,1,1898,1,1898,1,1898,
	1,1898,1,1898,1,1898,1,1898,1,1898,1,1898,1,1899,1,1899,1,1899,1,1899,1,
	1899,1,1899,1,1899,1,1899,1,1899,1,1899,1,1899,1,1899,1,1899,1,1900,1,1900,
	1,1900,1,1900,1,1900,1,1900,1,1900,1,1900,1,1900,1,1900,1,1900,1,1900,1,
	1900,1,1901,1,1901,1,1901,1,1901,1,1901,1,1901,1,1901,1,1901,1,1901,1,1901,
	1,1901,1,1901,1,1902,1,1902,1,1902,1,1902,1,1902,1,1902,1,1902,1,1902,1,
	1902,1,1902,1,1902,1,1902,1,1902,1,1902,1,1903,1,1903,1,1903,1,1903,1,1903,
	1,1903,1,1903,1,1903,1,1903,1,1903,1,1903,1,1903,1,1903,1,1903,1,1904,1,
	1904,1,1904,1,1904,1,1904,1,1904,1,1904,1,1904,1,1904,1,1904,1,1904,1,1905,
	1,1905,1,1905,1,1905,1,1905,1,1905,1,1905,1,1905,1,1905,1,1905,1,1905,1,
	1905,1,1905,1,1905,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,
	1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,1906,1,
	1907,1,1907,1,1907,1,1907,1,1907,1,1907,1,1907,1,1907,1,1907,1,1907,1,1907,
	1,1907,1,1908,1,1908,1,1908,1,1908,1,1908,1,1908,1,1908,1,1908,1,1908,1,
	1908,1,1908,1,1908,1,1908,1,1909,1,1909,1,1909,1,1909,1,1909,1,1909,1,1909,
	1,1909,1,1909,1,1909,1,1909,1,1909,1,1909,1,1909,1,1909,1,1909,1,1910,1,
	1910,1,1910,1,1910,1,1910,1,1910,1,1910,1,1910,1,1910,1,1910,1,1910,1,1910,
	1,1910,1,1910,1,1910,1,1910,1,1911,1,1911,1,1911,1,1911,1,1911,1,1911,1,
	1911,1,1911,1,1911,1,1911,1,1911,1,1911,1,1912,1,1912,1,1912,1,1912,1,1912,
	1,1912,1,1912,1,1912,1,1912,1,1912,1,1912,1,1912,1,1912,1,1912,1,1912,1,
	1912,1,1912,1,1913,1,1913,1,1913,1,1913,1,1913,1,1913,1,1913,1,1913,1,1913,
	1,1913,1,1913,1,1913,1,1913,1,1914,1,1914,1,1914,1,1914,1,1914,1,1914,1,
	1914,1,1914,1,1914,1,1914,1,1914,1,1914,1,1914,1,1915,1,1915,1,1915,1,1915,
	1,1915,1,1915,1,1915,1,1915,1,1915,1,1915,1,1915,1,1915,1,1915,1,1916,1,
	1916,1,1916,1,1916,1,1916,1,1916,1,1916,1,1916,1,1916,1,1916,1,1916,1,1916,
	1,1916,1,1916,1,1917,1,1917,1,1917,1,1917,1,1917,1,1917,1,1917,1,1917,1,
	1917,1,1917,1,1917,1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,
	1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,1,1918,1,1919,1,
	1919,1,1919,1,1919,1,1919,1,1919,1,1919,1,1919,1,1919,1,1919,1,1919,1,1919,
	1,1919,1,1919,1,1919,1,1919,1,1920,1,1920,1,1920,1,1920,1,1920,1,1920,1,
	1920,1,1920,1,1920,1,1920,1,1920,1,1920,1,1920,1,1921,1,1921,1,1921,1,1921,
	1,1921,1,1921,1,1921,1,1921,1,1921,1,1921,1,1921,1,1921,1,1921,1,1921,1,
	1921,1,1922,1,1922,1,1922,1,1922,1,1922,1,1922,1,1922,1,1922,1,1922,1,1922,
	1,1922,1,1922,1,1922,1,1922,1,1922,1,1922,1,1923,1,1923,1,1923,1,1923,1,
	1923,1,1923,1,1923,1,1923,1,1923,1,1923,1,1923,1,1923,1,1923,1,1923,1,1923,
	1,1923,1,1924,1,1924,1,1924,1,1924,1,1924,1,1924,1,1924,1,1924,1,1924,1,
	1924,1,1924,1,1924,1,1925,1,1925,1,1925,1,1925,1,1925,1,1925,1,1925,1,1925,
	1,1925,1,1925,1,1925,1,1925,1,1925,1,1925,1,1926,1,1926,1,1926,1,1926,1,
	1926,1,1926,1,1926,1,1926,1,1926,1,1926,1,1926,1,1926,1,1926,1,1926,1,1926,
	1,1926,1,1927,1,1927,1,1927,1,1927,1,1927,1,1927,1,1927,1,1927,1,1927,1,
	1927,1,1927,1,1927,1,1927,1,1927,1,1927,1,1927,1,1928,1,1928,1,1928,1,1928,
	1,1928,1,1928,1,1928,1,1928,1,1928,1,1928,1,1928,1,1928,1,1928,1,1928,1,
	1928,1,1928,1,1928,1,1929,1,1929,1,1929,1,1929,1,1929,1,1929,1,1929,1,1929,
	1,1929,1,1929,1,1929,1,1929,1,1929,1,1929,1,1930,1,1930,1,1930,1,1930,1,
	1930,1,1930,1,1930,1,1930,1,1930,1,1930,1,1930,1,1930,1,1930,1,1930,1,1931,
	1,1931,1,1931,1,1931,1,1931,1,1931,1,1931,1,1931,1,1931,1,1931,1,1931,1,
	1931,1,1931,1,1931,1,1932,1,1932,1,1932,1,1932,1,1932,1,1932,1,1932,1,1932,
	1,1932,1,1932,1,1932,1,1932,1,1932,1,1932,1,1932,1,1932,1,1933,1,1933,1,
	1933,1,1933,1,1933,1,1933,1,1933,1,1933,1,1933,1,1933,1,1933,1,1933,1,1933,
	1,1933,1,1934,1,1934,1,1934,1,1934,1,1934,1,1934,1,1934,1,1934,1,1934,1,
	1934,1,1934,1,1934,1,1934,1,1934,1,1935,1,1935,1,1935,1,1935,1,1935,1,1935,
	1,1935,1,1935,1,1935,1,1935,1,1935,1,1935,1,1935,1,1935,1,1936,1,1936,1,
	1936,1,1936,1,1936,1,1936,1,1936,1,1936,1,1936,1,1936,1,1936,1,1936,1,1936,
	1,1936,1,1937,1,1937,1,1937,1,1937,1,1937,1,1937,1,1937,1,1937,1,1937,1,
	1937,1,1937,1,1937,1,1937,1,1937,1,1938,1,1938,1,1938,1,1938,1,1938,1,1938,
	1,1938,1,1938,1,1938,1,1938,1,1938,1,1938,1,1938,1,1938,1,1939,1,1939,1,
	1939,1,1939,1,1939,1,1939,1,1939,1,1939,1,1939,1,1939,1,1939,1,1939,1,1939,
	1,1939,1,1939,1,1940,1,1940,1,1940,1,1940,1,1940,1,1940,1,1940,1,1940,1,
	1940,1,1940,1,1940,1,1940,1,1940,1,1940,1,1941,1,1941,1,1941,1,1941,1,1941,
	1,1941,1,1941,1,1941,1,1941,1,1941,1,1941,1,1941,1,1941,1,1941,1,1941,1,
	1942,1,1942,1,1942,1,1942,1,1942,1,1942,1,1942,1,1942,1,1942,1,1942,1,1942,
	1,1942,1,1942,1,1942,1,1942,1,1943,1,1943,1,1943,1,1943,1,1943,1,1943,1,
	1943,1,1943,1,1943,1,1943,1,1943,1,1943,1,1943,1,1943,1,1944,1,1944,1,1944,
	1,1944,1,1944,1,1944,1,1944,1,1944,1,1944,1,1944,1,1944,1,1944,1,1944,1,
	1944,1,1945,1,1945,1,1945,1,1945,1,1945,1,1945,1,1945,1,1945,1,1945,1,1945,
	1,1945,1,1945,1,1945,1,1945,1,1946,1,1946,1,1946,1,1946,1,1946,1,1946,1,
	1946,1,1946,1,1946,1,1946,1,1946,1,1946,1,1946,1,1946,1,1947,1,1947,1,1947,
	1,1947,1,1947,1,1947,1,1947,1,1947,1,1947,1,1947,1,1947,1,1947,1,1947,1,
	1947,1,1948,1,1948,1,1948,1,1948,1,1948,1,1948,1,1948,1,1948,1,1948,1,1948,
	1,1948,1,1948,1,1948,1,1948,1,1949,1,1949,1,1949,1,1949,1,1949,1,1949,1,
	1949,1,1949,1,1949,1,1949,1,1949,1,1949,1,1949,1,1949,1,1950,1,1950,1,1950,
	1,1950,1,1950,1,1950,1,1950,1,1950,1,1950,1,1950,1,1950,1,1950,1,1950,1,
	1951,1,1951,1,1951,1,1951,1,1951,1,1951,1,1951,1,1951,1,1951,1,1951,1,1951,
	1,1951,1,1951,1,1951,1,1952,1,1952,1,1952,1,1952,1,1952,1,1952,1,1952,1,
	1952,1,1952,1,1952,1,1952,1,1952,1,1952,1,1952,1,1953,1,1953,1,1953,1,1953,
	1,1953,1,1953,1,1953,1,1953,1,1953,1,1953,1,1953,1,1953,1,1953,1,1953,1,
	1953,1,1953,1,1953,1,1953,1,1954,1,1954,1,1954,1,1954,1,1954,1,1954,1,1954,
	1,1954,1,1954,1,1954,1,1954,1,1954,1,1954,1,1954,1,1955,1,1955,1,1955,1,
	1955,1,1955,1,1955,1,1955,1,1955,1,1955,1,1955,1,1955,1,1955,1,1955,1,1955,
	1,1955,1,1955,1,1956,1,1956,1,1956,1,1956,1,1956,1,1956,1,1956,1,1956,1,
	1956,1,1956,1,1956,1,1956,1,1956,1,1956,1,1956,1,1957,1,1957,1,1957,1,1957,
	1,1957,1,1957,1,1957,1,1957,1,1957,1,1957,1,1957,1,1957,1,1958,1,1958,1,
	1958,1,1958,1,1958,1,1958,1,1958,1,1958,1,1958,1,1958,1,1958,1,1958,1,1958,
	1,1958,1,1958,1,1958,1,1958,1,1959,1,1959,1,1959,1,1959,1,1959,1,1959,1,
	1959,1,1959,1,1959,1,1959,1,1959,1,1959,1,1959,1,1959,1,1960,1,1960,1,1960,
	1,1960,1,1960,1,1960,1,1960,1,1960,1,1960,1,1960,1,1960,1,1960,1,1960,1,
	1960,1,1960,1,1960,1,1961,1,1961,1,1961,1,1961,1,1961,1,1961,1,1961,1,1961,
	1,1961,1,1961,1,1961,1,1961,1,1961,1,1961,1,1962,1,1962,1,1962,1,1962,1,
	1962,1,1962,1,1962,1,1962,1,1962,1,1962,1,1962,1,1962,1,1963,1,1963,1,1963,
	1,1963,1,1963,1,1963,1,1963,1,1963,1,1963,1,1963,1,1963,1,1963,1,1963,1,
	1964,1,1964,1,1964,1,1964,1,1964,1,1964,1,1964,1,1964,1,1964,1,1964,1,1964,
	1,1964,1,1964,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,
	1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1965,1,1966,
	1,1966,1,1966,1,1966,1,1966,1,1966,1,1966,1,1966,1,1966,1,1966,1,1966,1,
	1966,1,1966,1,1966,1,1966,1,1966,1,1966,1,1967,1,1967,1,1967,1,1967,1,1967,
	1,1967,1,1967,1,1967,1,1967,1,1967,1,1967,1,1967,1,1967,1,1967,1,1967,1,
	1968,1,1968,1,1968,1,1968,1,1968,1,1968,1,1968,1,1968,1,1968,1,1968,1,1968,
	1,1968,1,1968,1,1968,1,1968,1,1968,1,1969,1,1969,1,1969,1,1969,1,1969,1,
	1969,1,1969,1,1969,1,1969,1,1969,1,1969,1,1969,1,1969,1,1969,1,1970,1,1970,
	1,1970,1,1970,1,1970,1,1970,1,1970,1,1970,1,1970,1,1970,1,1970,1,1970,1,
	1970,1,1970,1,1970,1,1970,1,1971,1,1971,1,1971,1,1971,1,1971,1,1971,1,1971,
	1,1971,1,1971,1,1971,1,1971,1,1971,1,1971,1,1971,1,1971,1,1971,1,1972,1,
	1972,1,1972,1,1972,1,1972,1,1972,1,1972,1,1972,1,1972,1,1972,1,1972,1,1972,
	1,1972,1,1972,1,1972,1,1973,1,1973,1,1973,1,1973,1,1973,1,1973,1,1973,1,
	1973,1,1973,1,1973,1,1973,1,1973,1,1973,1,1973,1,1974,1,1974,1,1974,1,1974,
	1,1974,1,1974,1,1974,1,1974,1,1974,1,1974,1,1974,1,1974,1,1974,1,1974,1,
	1974,1,1974,1,1974,1,1974,1,1975,1,1975,1,1975,1,1975,1,1975,1,1975,1,1975,
	1,1975,1,1975,1,1975,1,1975,1,1975,1,1976,1,1976,1,1976,1,1976,1,1976,1,
	1976,1,1976,1,1976,1,1976,1,1976,1,1976,1,1976,1,1976,1,1976,1,1976,1,1976,
	1,1977,1,1977,1,1977,1,1977,1,1977,1,1977,1,1977,1,1977,1,1977,1,1977,1,
	1977,1,1977,1,1977,1,1977,1,1977,1,1977,1,1978,1,1978,1,1978,1,1978,1,1978,
	1,1978,1,1978,1,1978,1,1978,1,1978,1,1978,1,1978,1,1978,1,1978,1,1978,1,
	1978,1,1978,1,1979,1,1979,1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,
	1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,1,1980,1,
	1980,1,1980,1,1981,1,1981,1,1981,1,1981,1,1981,1,1981,1,1981,1,1981,1,1981,
	1,1981,1,1981,1,1981,1,1981,1,1981,1,1982,1,1982,1,1982,1,1982,1,1982,1,
	1982,1,1982,1,1982,1,1982,1,1982,1,1982,1,1983,1,1983,1,1983,1,1983,1,1983,
	1,1983,1,1983,1,1984,1,1984,1,1984,1,1984,1,1984,1,1984,1,1984,1,1984,1,
	1984,1,1984,1,1984,1,1984,1,1985,1,1985,1,1985,1,1985,1,1985,1,1985,1,1986,
	1,1986,1,1986,1,1986,1,1986,1,1986,1,1987,1,1987,1,1987,1,1987,1,1988,1,
	1988,1,1988,1,1988,1,1988,1,1989,1,1989,1,1989,1,1989,1,1990,1,1990,1,1990,
	1,1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,
	1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,1990,1,1991,1,1991,1,1991,1,1991,
	1,1991,1,1991,1,1991,1,1991,1,1991,1,1992,1,1992,1,1992,1,1992,1,1992,1,
	1992,1,1992,1,1992,1,1992,1,1993,1,1993,1,1993,1,1993,1,1993,1,1993,1,1993,
	1,1993,1,1993,1,1993,1,1994,1,1994,1,1994,1,1994,1,1994,1,1994,1,1994,1,
	1994,1,1994,1,1994,1,1994,1,1995,1,1995,1,1995,1,1995,1,1995,1,1996,1,1996,
	1,1996,1,1996,1,1996,1,1997,1,1997,1,1997,1,1997,1,1997,1,1998,1,1998,1,
	1998,1,1998,1,1998,1,1999,1,1999,1,1999,1,1999,1,2000,1,2000,1,2000,1,2000,
	1,2000,1,2000,1,2000,1,2001,1,2001,1,2001,1,2001,1,2001,1,2001,1,2001,1,
	2001,1,2002,1,2002,1,2002,1,2002,1,2002,1,2003,1,2003,1,2003,1,2003,1,2003,
	1,2004,1,2004,1,2004,1,2004,1,2004,1,2004,1,2004,1,2004,1,2005,1,2005,1,
	2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,
	1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,2005,1,
	2005,1,2005,1,2005,1,2005,1,2005,1,2006,1,2006,1,2006,1,2006,1,2006,1,2006,
	1,2006,1,2006,1,2006,1,2006,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,
	2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,
	1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,2007,1,
	2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,
	1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,2008,1,
	2008,1,2008,1,2008,1,2009,1,2009,1,2009,1,2009,1,2009,1,2009,1,2010,1,2010,
	1,2010,1,2010,1,2010,1,2011,1,2011,1,2011,1,2011,1,2011,1,2011,1,2011,1,
	2011,1,2011,1,2012,1,2012,1,2012,1,2012,1,2012,1,2012,1,2012,1,2012,1,2012,
	1,2012,1,2012,1,2012,1,2012,1,2012,1,2013,1,2013,1,2013,1,2013,1,2013,1,
	2013,1,2013,1,2013,1,2013,1,2013,1,2013,1,2013,1,2013,1,2013,1,2014,1,2014,
	1,2014,1,2014,1,2014,1,2014,1,2014,1,2014,1,2014,1,2014,1,2014,1,2014,1,
	2014,1,2014,1,2014,1,2014,1,2015,1,2015,1,2015,1,2015,1,2015,1,2015,1,2015,
	1,2015,1,2015,1,2015,1,2015,1,2015,1,2015,1,2015,1,2015,1,2015,1,2016,1,
	2016,1,2016,1,2016,1,2016,1,2016,1,2016,1,2016,1,2016,1,2016,1,2016,1,2016,
	1,2016,1,2016,1,2016,1,2016,1,2017,1,2017,1,2017,1,2017,1,2017,1,2017,1,
	2017,1,2017,1,2017,1,2017,1,2018,1,2018,1,2018,1,2018,1,2018,1,2018,1,2018,
	1,2019,1,2019,1,2019,1,2019,1,2019,1,2019,1,2019,1,2020,1,2020,1,2020,1,
	2020,1,2020,1,2020,1,2020,1,2020,1,2021,1,2021,1,2021,1,2021,1,2021,1,2021,
	1,2021,1,2021,1,2021,1,2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,
	2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,2022,1,2023,
	1,2023,1,2023,1,2023,1,2023,1,2023,1,2023,1,2023,1,2023,1,2023,1,2023,1,
	2023,1,2023,1,2023,1,2023,1,2023,1,2024,1,2024,1,2024,1,2024,1,2024,1,2024,
	1,2024,1,2024,1,2025,1,2025,1,2025,1,2025,1,2025,1,2025,1,2025,1,2025,1,
	2026,1,2026,1,2026,1,2026,1,2026,1,2026,1,2026,1,2026,1,2026,1,2026,1,2026,
	1,2026,1,2026,1,2026,1,2027,1,2027,1,2027,1,2027,1,2027,1,2027,1,2027,1,
	2028,1,2028,1,2028,1,2028,1,2028,1,2028,1,2028,1,2028,1,2028,1,2028,1,2028,
	1,2028,1,2028,1,2028,1,2029,1,2029,1,2029,1,2029,1,2029,1,2029,1,2029,1,
	2029,1,2029,1,2030,1,2030,1,2030,1,2030,1,2030,1,2030,1,2030,1,2030,1,2030,
	1,2031,1,2031,1,2031,1,2031,1,2031,1,2031,1,2031,1,2031,1,2031,1,2031,1,
	2032,1,2032,1,2032,1,2032,1,2032,1,2032,1,2032,1,2032,1,2032,1,2033,1,2033,
	1,2033,1,2033,1,2033,1,2033,1,2033,1,2033,1,2033,1,2033,1,2033,1,2033,1,
	2033,1,2033,1,2033,1,2034,1,2034,1,2034,1,2034,1,2034,1,2034,1,2034,1,2034,
	1,2034,1,2034,1,2034,1,2034,1,2034,1,2035,1,2035,1,2035,1,2035,1,2035,1,
	2035,1,2035,1,2035,1,2035,1,2035,1,2035,1,2035,1,2035,1,2035,1,2035,1,2035,
	1,2036,1,2036,1,2036,1,2036,1,2036,1,2036,1,2036,1,2036,1,2037,1,2037,1,
	2037,1,2037,1,2037,1,2037,1,2037,1,2037,1,2037,1,2037,1,2037,1,2038,1,2038,
	1,2038,1,2039,1,2039,1,2039,1,2039,1,2039,1,2039,1,2039,1,2039,1,2039,1,
	2039,1,2039,1,2039,1,2039,1,2039,1,2040,1,2040,1,2040,1,2040,1,2040,1,2040,
	1,2041,1,2041,1,2041,1,2041,1,2041,1,2041,1,2041,1,2041,1,2042,1,2042,1,
	2042,1,2042,1,2042,1,2042,1,2042,1,2042,1,2042,1,2043,1,2043,1,2043,1,2043,
	1,2043,1,2043,1,2043,1,2043,1,2043,1,2044,1,2044,1,2044,1,2044,1,2044,1,
	2044,1,2044,1,2044,1,2044,1,2044,1,2044,1,2044,1,2045,1,2045,1,2045,1,2045,
	1,2045,1,2045,1,2045,1,2045,1,2045,1,2045,1,2046,1,2046,1,2046,1,2046,1,
	2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,
	1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2046,1,2047,1,2047,1,
	2047,1,2047,1,2047,1,2047,1,2047,1,2047,1,2047,1,2047,1,2047,1,2047,1,2047,
	1,2048,1,2048,1,2048,1,2048,1,2048,1,2048,1,2048,1,2048,1,2048,1,2048,1,
	2048,1,2049,1,2049,1,2049,1,2049,1,2049,1,2049,1,2049,1,2049,1,2049,1,2049,
	1,2050,1,2050,1,2050,1,2050,1,2050,1,2050,1,2050,1,2050,1,2050,1,2050,1,
	2050,1,2050,1,2051,1,2051,1,2051,1,2051,1,2051,1,2051,1,2052,1,2052,1,2052,
	1,2052,1,2052,1,2052,1,2052,1,2052,1,2052,1,2053,1,2053,1,2053,1,2053,1,
	2053,1,2053,1,2053,1,2053,1,2054,1,2054,1,2054,1,2054,1,2054,1,2055,1,2055,
	1,2055,1,2055,1,2055,1,2055,1,2055,1,2055,1,2055,1,2056,1,2056,1,2056,1,
	2056,1,2056,1,2056,1,2057,1,2057,1,2057,1,2057,1,2057,1,2057,1,2057,1,2057,
	1,2058,1,2058,1,2058,1,2058,1,2058,1,2058,1,2059,1,2059,1,2059,1,2059,1,
	2059,1,2059,1,2059,1,2060,1,2060,1,2060,1,2061,1,2061,1,2061,1,2061,1,2061,
	1,2061,1,2062,1,2062,1,2062,1,2062,1,2062,1,2063,1,2063,1,2063,1,2063,1,
	2063,1,2063,1,2063,1,2063,1,2063,1,2063,1,2064,1,2064,1,2064,1,2064,1,2065,
	1,2065,1,2065,1,2065,1,2066,1,2066,1,2066,1,2066,1,2066,1,2067,1,2067,1,
	2067,1,2067,1,2068,1,2068,1,2068,1,2068,1,2068,1,2068,1,2068,1,2068,1,2068,
	1,2068,1,2068,1,2069,1,2069,1,2069,1,2069,1,2069,1,2069,1,2069,1,2069,1,
	2069,1,2069,1,2070,1,2070,1,2070,1,2070,1,2070,1,2070,1,2070,1,2070,1,2071,
	1,2071,1,2071,1,2071,1,2071,1,2071,1,2071,1,2071,1,2071,1,2071,1,2071,1,
	2071,1,2071,1,2071,1,2072,1,2072,1,2072,1,2072,1,2072,1,2072,1,2073,1,2073,
	1,2073,1,2073,1,2073,1,2074,1,2074,1,2074,1,2074,1,2074,1,2074,1,2074,1,
	2075,1,2075,1,2075,1,2075,1,2075,1,2075,1,2075,1,2075,1,2076,1,2076,1,2076,
	1,2076,1,2076,1,2076,1,2077,1,2077,1,2077,1,2077,1,2077,1,2077,1,2077,1,
	2078,1,2078,1,2078,1,2078,1,2078,1,2078,1,2078,1,2079,1,2079,1,2079,1,2079,
	1,2079,1,2079,1,2079,1,2079,1,2079,1,2079,1,2080,1,2080,1,2080,1,2080,1,
	2080,1,2080,1,2080,1,2081,1,2081,1,2081,1,2081,1,2081,1,2081,1,2081,1,2082,
	1,2082,1,2082,1,2082,1,2082,1,2082,1,2082,1,2082,1,2082,1,2082,1,2083,1,
	2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,
	1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,
	2083,1,2083,1,2083,1,2083,1,2083,1,2083,1,2084,1,2084,1,2084,1,2084,1,2084,
	1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,
	2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,1,2084,
	1,2084,1,2084,1,2084,1,2084,1,2085,1,2085,1,2085,1,2085,1,2085,1,2085,1,
	2085,1,2085,1,2085,1,2085,1,2085,1,2085,1,2085,1,2085,1,2085,1,2085,1,2085,
	1,2085,1,2086,1,2086,1,2086,1,2086,1,2086,1,2086,1,2086,1,2087,1,2087,1,
	2087,1,2087,1,2087,1,2087,1,2087,1,2087,1,2087,1,2088,1,2088,1,2088,1,2088,
	1,2088,1,2088,1,2088,1,2088,1,2089,1,2089,1,2089,1,2089,1,2089,1,2089,1,
	2089,1,2090,1,2090,1,2090,1,2090,1,2090,1,2090,1,2090,1,2090,1,2090,1,2090,
	1,2090,1,2090,1,2091,1,2091,1,2091,1,2091,1,2091,1,2091,1,2091,1,2091,1,
	2091,1,2091,1,2092,1,2092,1,2092,1,2092,1,2092,1,2092,1,2092,1,2092,1,2092,
	1,2092,1,2092,1,2092,1,2092,1,2092,1,2093,1,2093,1,2093,1,2093,1,2093,1,
	2093,1,2093,1,2093,1,2093,1,2093,1,2093,1,2093,1,2093,1,2094,1,2094,1,2094,
	1,2094,1,2094,1,2094,1,2094,1,2094,1,2094,1,2094,1,2094,1,2094,1,2095,1,
	2095,1,2095,1,2095,1,2095,1,2095,1,2096,1,2096,1,2096,1,2096,1,2096,1,2096,
	1,2096,1,2096,1,2096,1,2097,1,2097,1,2097,1,2097,1,2097,1,2097,1,2097,1,
	2098,1,2098,1,2098,1,2098,1,2098,1,2098,1,2098,1,2098,1,2098,1,2098,1,2099,
	1,2099,1,2099,1,2099,1,2099,1,2099,1,2099,1,2099,1,2100,1,2100,1,2100,1,
	2100,1,2100,1,2100,1,2100,1,2101,1,2101,1,2101,1,2101,1,2101,1,2101,1,2101,
	1,2101,1,2101,1,2101,1,2102,1,2102,1,2102,1,2102,1,2102,1,2102,1,2102,1,
	2102,1,2102,1,2102,1,2102,1,2102,1,2103,1,2103,1,2103,1,2103,1,2103,1,2103,
	1,2103,1,2103,1,2103,1,2103,1,2103,1,2103,1,2103,1,2103,1,2104,1,2104,1,
	2104,1,2104,1,2104,1,2104,1,2104,1,2104,1,2105,1,2105,1,2105,1,2105,1,2105,
	1,2105,1,2106,1,2106,1,2106,1,2106,1,2106,1,2106,1,2106,1,2107,1,2107,1,
	2107,1,2107,1,2107,1,2107,1,2107,1,2108,1,2108,1,2108,1,2108,1,2108,1,2108,
	1,2108,1,2109,1,2109,1,2109,1,2109,1,2109,1,2109,1,2110,1,2110,1,2110,1,
	2110,1,2110,1,2110,1,2110,1,2110,1,2110,1,2111,1,2111,1,2111,1,2111,1,2111,
	1,2111,1,2111,1,2111,1,2111,1,2111,1,2111,1,2112,1,2112,1,2112,1,2112,1,
	2112,1,2112,1,2112,1,2112,1,2112,1,2113,1,2113,1,2113,1,2113,1,2113,1,2113,
	1,2113,1,2113,1,2113,1,2113,1,2113,1,2113,1,2113,1,2113,1,2113,1,2113,1,
	2113,1,2113,1,2113,1,2113,1,2113,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,
	1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,
	2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2114,1,2115,
	1,2115,1,2115,1,2115,1,2115,1,2115,1,2115,1,2115,1,2115,1,2116,1,2116,1,
	2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,
	1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2116,1,2117,1,
	2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,
	1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,2117,1,
	2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,
	1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2118,1,2119,1,
	2119,1,2119,1,2119,1,2119,1,2119,1,2119,1,2119,1,2119,1,2119,1,2120,1,2120,
	1,2120,1,2120,1,2120,1,2120,1,2120,1,2121,1,2121,1,2121,1,2121,1,2121,1,
	2121,1,2121,1,2121,1,2121,1,2121,1,2121,1,2121,1,2121,1,2121,1,2121,1,2121,
	1,2121,1,2121,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,
	2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,1,2122,
	1,2122,1,2122,1,2123,1,2123,1,2123,1,2123,1,2123,1,2123,1,2123,1,2123,1,
	2123,1,2123,1,2124,1,2124,1,2124,1,2124,1,2124,1,2124,1,2124,1,2124,1,2124,
	1,2124,1,2124,1,2124,1,2124,1,2125,1,2125,1,2125,1,2125,1,2125,1,2125,1,
	2125,1,2125,1,2126,1,2126,1,2126,1,2126,1,2126,1,2126,1,2126,1,2126,1,2126,
	1,2126,1,2127,1,2127,1,2127,1,2127,1,2127,1,2127,1,2127,1,2127,1,2127,1,
	2127,1,2127,1,2127,1,2127,1,2127,1,2127,1,2127,1,2128,1,2128,1,2128,1,2128,
	1,2128,1,2128,1,2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,
	2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,2129,1,2130,1,2130,
	1,2130,1,2130,1,2130,1,2131,1,2131,1,2131,1,2131,1,2131,1,2131,1,2131,1,
	2131,1,2131,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,
	1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,2132,1,
	2132,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,
	1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2133,1,2134,1,2134,1,
	2134,1,2134,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,
	1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,2135,1,
	2135,1,2135,1,2135,1,2135,1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,
	1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,1,2136,1,
	2136,1,2136,1,2137,1,2137,1,2137,1,2137,1,2137,1,2137,1,2137,1,2137,1,2137,
	1,2137,1,2137,1,2137,1,2137,1,2137,1,2137,1,2137,1,2138,1,2138,1,2138,1,
	2138,1,2138,1,2138,1,2139,1,2139,1,2139,1,2139,1,2139,1,2139,1,2139,1,2139,
	1,2140,1,2140,1,2140,1,2140,1,2140,1,2140,1,2140,1,2140,1,2141,1,2141,1,
	2141,1,2141,1,2141,1,2141,1,2142,1,2142,1,2142,1,2142,1,2142,1,2143,1,2143,
	1,2143,1,2144,1,2144,1,2144,1,2145,1,2145,1,2145,1,2145,1,2145,1,2145,1,
	2145,1,2145,1,2145,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,
	1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,2146,1,
	2146,1,2146,1,2147,1,2147,1,2147,1,2147,1,2147,1,2147,1,2147,1,2147,1,2147,
	1,2147,1,2147,1,2148,1,2148,1,2148,1,2148,1,2148,1,2148,1,2148,1,2148,1,
	2148,1,2148,1,2148,1,2148,1,2148,1,2148,1,2148,1,2149,1,2149,1,2149,1,2149,
	1,2149,1,2149,1,2149,1,2150,1,2150,1,2150,1,2150,1,2150,1,2150,1,2151,1,
	2151,1,2151,1,2151,1,2151,1,2151,1,2151,1,2151,1,2151,1,2152,1,2152,1,2152,
	1,2152,1,2152,1,2152,1,2152,1,2152,1,2153,1,2153,1,2153,1,2153,1,2153,1,
	2153,1,2153,1,2153,1,2153,1,2154,1,2154,1,2154,1,2154,1,2154,1,2154,1,2154,
	1,2154,1,2155,1,2155,1,2155,1,2155,1,2155,1,2155,1,2155,1,2155,1,2156,1,
	2156,1,2156,1,2156,1,2156,1,2156,1,2156,1,2157,1,2157,1,2157,1,2157,1,2157,
	1,2157,1,2157,1,2157,1,2157,1,2158,1,2158,1,2158,1,2158,1,2158,1,2158,1,
	2158,1,2158,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,
	1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2159,1,2160,1,
	2160,1,2160,1,2160,1,2160,1,2160,1,2160,1,2160,1,2160,1,2160,1,2160,1,2160,
	1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,
	2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,1,2161,
	1,2161,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,
	2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,1,2162,
	1,2162,1,2162,1,2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,
	2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,2163,1,2164,1,2164,
	1,2164,1,2164,1,2164,1,2164,1,2164,1,2164,1,2164,1,2165,1,2165,1,2165,1,
	2165,1,2165,1,2165,1,2165,1,2166,1,2166,1,2166,1,2166,1,2166,1,2166,1,2166,
	1,2166,1,2166,1,2166,1,2166,1,2167,1,2167,1,2167,1,2167,1,2167,1,2167,1,
	2167,1,2167,1,2167,1,2167,1,2167,1,2167,1,2167,1,2167,1,2167,1,2167,1,2168,
	1,2168,1,2168,1,2168,1,2168,1,2168,1,2168,1,2168,1,2168,1,2168,1,2168,1,
	2168,1,2168,1,2168,1,2168,1,2168,1,2168,1,2169,1,2169,1,2169,1,2169,1,2169,
	1,2169,1,2169,1,2169,1,2169,1,2169,1,2169,1,2169,1,2169,1,2169,1,2169,1,
	2169,1,2169,1,2169,1,2169,1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,
	1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,1,2170,1,
	2170,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,
	1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2171,1,2172,1,
	2172,1,2172,1,2172,1,2172,1,2172,1,2172,1,2172,1,2172,1,2173,1,2173,1,2173,
	1,2173,1,2173,1,2173,1,2173,1,2173,1,2173,1,2173,1,2173,1,2173,1,2173,1,
	2174,1,2174,1,2174,1,2174,1,2174,1,2174,1,2174,1,2174,1,2175,1,2175,1,2175,
	1,2175,1,2175,1,2176,1,2176,1,2176,1,2176,1,2176,1,2176,1,2176,1,2176,1,
	2176,1,2176,1,2177,1,2177,1,2177,1,2177,1,2177,1,2177,1,2177,1,2177,1,2178,
	1,2178,1,2178,1,2178,1,2178,1,2178,1,2178,1,2178,1,2178,1,2178,1,2178,1,
	2179,1,2179,1,2179,1,2179,1,2179,1,2179,1,2179,1,2179,1,2180,1,2180,1,2180,
	1,2180,1,2180,1,2180,1,2180,1,2181,1,2181,1,2181,1,2181,1,2181,1,2181,1,
	2182,1,2182,1,2182,1,2182,1,2182,1,2183,1,2183,1,2183,1,2183,1,2183,1,2183,
	1,2183,1,2184,1,2184,1,2184,1,2184,1,2184,1,2184,1,2184,1,2184,1,2185,1,
	2185,1,2185,1,2185,1,2185,1,2185,1,2186,1,2186,1,2186,1,2186,1,2186,1,2187,
	1,2187,1,2187,1,2187,1,2187,1,2187,1,2187,1,2187,1,2187,1,2187,1,2187,1,
	2188,1,2188,1,2188,1,2188,1,2188,1,2188,1,2188,1,2188,1,2188,1,2189,1,2189,
	1,2189,1,2189,1,2189,1,2190,1,2190,1,2190,1,2190,1,2190,1,2190,1,2191,1,
	2191,1,2191,1,2191,1,2191,1,2191,1,2192,1,2192,1,2192,1,2192,1,2192,1,2192,
	1,2192,1,2192,1,2192,1,2192,1,2192,1,2193,1,2193,1,2193,1,2193,1,2193,1,
	2193,1,2193,1,2193,1,2193,1,2193,1,2193,1,2193,1,2193,1,2194,1,2194,1,2194,
	1,2194,1,2194,1,2194,1,2194,1,2195,1,2195,1,2195,1,2195,1,2195,1,2195,1,
	2195,1,2195,1,2196,1,2196,1,2196,1,2196,1,2196,1,2196,1,2196,1,2196,1,2196,
	1,2196,1,2196,1,2197,1,2197,1,2197,1,2197,1,2197,1,2198,1,2198,1,2198,1,
	2198,1,2198,1,2199,1,2199,1,2199,1,2199,1,2199,1,2199,1,2199,1,2199,1,2200,
	1,2200,1,2200,1,2200,1,2200,1,2200,1,2200,1,2200,1,2201,1,2201,1,2201,1,
	2201,1,2201,1,2201,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,
	1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,2202,1,
	2202,1,2202,1,2203,1,2203,1,2203,1,2203,1,2204,1,2204,1,2204,1,2204,1,2204,
	1,2204,1,2204,1,2204,1,2204,1,2204,1,2204,1,2204,1,2205,1,2205,1,2205,1,
	2205,1,2206,1,2206,1,2206,1,2206,1,2206,1,2206,1,2206,1,2206,1,2206,1,2206,
	1,2206,1,2207,1,2207,1,2207,1,2207,1,2207,1,2207,1,2207,1,2208,1,2208,1,
	2208,1,2208,1,2208,1,2208,1,2208,1,2208,1,2208,1,2208,1,2208,1,2208,1,2208,
	1,2208,1,2209,1,2209,1,2209,1,2209,1,2209,1,2209,1,2209,1,2209,1,2210,1,
	2210,1,2210,1,2210,1,2210,1,2210,1,2210,1,2210,1,2210,1,2211,1,2211,1,2211,
	1,2211,1,2211,1,2211,1,2211,1,2211,1,2211,1,2211,1,2211,1,2211,1,2211,1,
	2212,1,2212,1,2212,1,2212,1,2212,1,2212,1,2212,1,2212,1,2212,1,2212,1,2212,
	1,2213,1,2213,1,2213,1,2213,1,2213,1,2213,1,2213,1,2213,1,2213,1,2213,1,
	2214,1,2214,1,2214,1,2214,1,2214,1,2214,1,2214,1,2214,1,2215,1,2215,1,2215,
	1,2215,1,2215,1,2215,1,2215,1,2215,1,2215,1,2215,1,2215,1,2215,1,2215,1,
	2215,1,2215,1,2215,1,2215,1,2216,1,2216,1,2216,1,2216,1,2216,1,2216,1,2216,
	1,2216,1,2216,1,2216,1,2216,1,2217,1,2217,1,2217,1,2217,1,2217,1,2217,1,
	2217,1,2217,1,2217,1,2217,1,2217,1,2218,1,2218,1,2218,1,2218,1,2218,1,2218,
	1,2218,1,2218,1,2218,1,2218,1,2219,1,2219,1,2219,1,2219,1,2219,1,2219,1,
	2219,1,2219,1,2219,1,2219,1,2220,1,2220,1,2220,1,2220,1,2220,1,2220,1,2220,
	1,2220,1,2220,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,
	2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,
	1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2221,1,2222,1,2222,1,
	2222,1,2222,1,2222,1,2222,1,2222,1,2222,1,2222,1,2222,1,2222,1,2222,1,2222,
	1,2222,1,2222,1,2222,1,2222,1,2223,1,2223,1,2223,1,2223,1,2223,1,2223,1,
	2223,1,2223,1,2223,1,2223,1,2223,1,2223,1,2223,1,2223,1,2223,1,2223,1,2223,
	1,2223,1,2223,1,2223,1,2223,1,2224,1,2224,1,2224,1,2224,1,2224,1,2224,1,
	2224,1,2224,1,2224,1,2224,1,2225,1,2225,1,2225,1,2225,1,2225,1,2225,1,2225,
	1,2225,1,2225,1,2225,1,2225,1,2226,1,2226,1,2226,1,2226,1,2226,1,2226,1,
	2226,1,2226,1,2226,1,2226,1,2226,1,2226,1,2226,1,2226,1,2227,1,2227,1,2227,
	1,2227,1,2227,1,2227,1,2227,1,2227,1,2227,1,2228,1,2228,1,2228,1,2228,1,
	2228,1,2228,1,2228,1,2228,1,2228,1,2229,1,2229,1,2229,1,2229,1,2229,1,2229,
	1,2230,1,2230,1,2230,1,2230,1,2230,1,2230,1,2230,1,2230,1,2230,1,2230,1,
	2230,1,2230,1,2231,1,2231,1,2231,1,2231,1,2231,1,2231,1,2231,1,2231,1,2231,
	1,2232,1,2232,1,2232,1,2232,1,2232,1,2232,1,2232,1,2232,1,2233,1,2233,1,
	2233,1,2233,1,2233,1,2233,1,2233,1,2233,1,2233,1,2233,1,2234,1,2234,1,2234,
	1,2234,1,2234,1,2234,1,2234,1,2234,1,2234,1,2234,1,2234,1,2234,1,2234,1,
	2235,1,2235,1,2235,1,2235,1,2235,1,2235,1,2235,1,2235,1,2235,1,2236,1,2236,
	1,2236,1,2236,1,2236,1,2236,1,2236,1,2236,1,2236,1,2236,1,2236,1,2236,1,
	2236,1,2236,1,2236,1,2236,1,2236,1,2237,1,2237,1,2237,1,2237,1,2237,1,2237,
	1,2237,1,2237,1,2237,1,2237,1,2237,1,2237,1,2237,1,2238,1,2238,1,2238,1,
	2238,1,2238,1,2238,1,2238,1,2238,1,2239,1,2239,1,2239,1,2239,1,2240,1,2240,
	1,2240,1,2240,1,2240,1,2240,1,2240,1,2240,1,2240,1,2240,1,2240,1,2241,1,
	2241,1,2241,1,2241,1,2241,1,2241,1,2241,1,2241,1,2241,1,2241,1,2241,1,2241,
	1,2241,1,2241,1,2241,1,2242,1,2242,1,2242,1,2243,1,2243,1,2243,1,2243,1,
	2243,1,2243,1,2243,1,2243,1,2243,1,2243,1,2243,1,2244,1,2244,1,2244,1,2244,
	1,2244,1,2244,1,2245,1,2245,1,2245,1,2245,1,2245,1,2246,1,2246,1,2246,1,
	2246,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,
	1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,2247,1,
	2247,1,2247,1,2247,1,2247,1,2247,1,2248,1,2248,1,2248,1,2248,1,2248,1,2248,
	1,2248,1,2248,1,2249,1,2249,1,2249,1,2249,1,2249,1,2250,1,2250,1,2250,1,
	2250,1,2250,1,2250,1,2250,1,2250,1,2250,1,2250,1,2250,1,2251,1,2251,1,2251,
	1,2251,1,2251,1,2251,1,2251,1,2251,1,2251,1,2251,1,2251,1,2251,1,2251,1,
	2251,1,2251,1,2251,1,2251,1,2251,1,2252,1,2252,1,2252,1,2252,1,2252,1,2252,
	1,2252,1,2252,1,2252,1,2252,1,2252,1,2252,1,2252,1,2252,1,2252,1,2252,1,
	2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,
	1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2253,1,2254,1,2254,1,
	2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,
	1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,2254,1,
	2255,1,2255,1,2255,1,2255,1,2255,1,2255,1,2255,1,2255,1,2255,1,2255,1,2255,
	1,2255,1,2255,1,2255,1,2255,1,2256,1,2256,1,2256,1,2256,1,2256,1,2256,1,
	2256,1,2256,1,2256,1,2256,1,2256,1,2257,1,2257,1,2257,1,2257,1,2257,1,2257,
	1,2257,1,2257,1,2258,1,2258,1,2258,1,2258,1,2258,1,2258,1,2258,1,2258,1,
	2258,1,2258,1,2259,1,2259,1,2259,1,2259,1,2259,1,2259,1,2259,1,2259,1,2259,
	1,2259,1,2259,1,2260,1,2260,1,2260,1,2260,1,2260,1,2260,1,2260,1,2260,1,
	2260,1,2260,1,2260,1,2261,1,2261,1,2261,1,2261,1,2261,1,2261,1,2261,1,2261,
	1,2261,1,2262,1,2262,1,2262,1,2262,1,2262,1,2262,1,2262,1,2262,1,2263,1,
	2263,1,2263,1,2263,1,2263,1,2264,1,2264,1,2264,1,2264,1,2264,1,2264,1,2264,
	1,2264,1,2264,1,2264,1,2265,1,2265,1,2265,1,2265,1,2265,1,2265,1,2265,1,
	2265,1,2265,1,2265,1,2265,1,2265,1,2265,1,2265,1,2265,1,2265,1,2266,1,2266,
	1,2266,1,2266,1,2266,1,2266,1,2266,1,2266,1,2266,1,2266,1,2266,1,2266,1,
	2267,1,2267,1,2267,1,2267,1,2267,1,2267,1,2267,1,2267,1,2267,1,2267,1,2267,
	1,2267,1,2268,1,2268,1,2268,1,2268,1,2268,1,2268,1,2268,1,2268,1,2268,1,
	2268,1,2268,1,2268,1,2268,1,2268,1,2269,1,2269,1,2269,1,2269,1,2269,1,2269,
	1,2269,1,2269,1,2269,1,2269,1,2269,1,2270,1,2270,1,2270,1,2270,1,2270,1,
	2270,1,2270,1,2270,1,2271,1,2271,1,2271,1,2271,1,2271,1,2272,1,2272,1,2272,
	1,2272,1,2272,1,2272,1,2272,1,2272,1,2273,1,2273,1,2273,1,2273,1,2273,1,
	2274,1,2274,1,2274,1,2274,1,2274,1,2274,1,2275,1,2275,1,2275,1,2275,1,2275,
	1,2275,1,2275,1,2275,1,2275,1,2275,1,2276,1,2276,1,2276,1,2276,1,2276,1,
	2276,1,2276,1,2276,1,2276,1,2276,1,2277,1,2277,1,2277,1,2277,1,2277,1,2277,
	1,2277,1,2277,1,2277,1,2277,1,2277,1,2278,1,2278,1,2278,1,2278,1,2278,1,
	2278,1,2278,1,2278,1,2279,1,2279,1,2279,1,2279,1,2279,1,2279,1,2279,1,2279,
	1,2279,1,2279,1,2279,1,2279,1,2279,1,2280,1,2280,1,2280,1,2280,1,2280,1,
	2280,1,2280,1,2280,1,2280,1,2280,1,2280,1,2280,1,2280,1,2280,1,2280,1,2280,
	1,2281,1,2281,1,2281,1,2281,1,2281,1,2281,1,2281,1,2281,1,2281,1,2281,1,
	2281,1,2281,1,2281,1,2281,1,2281,1,2281,1,2282,1,2282,1,2282,1,2282,1,2282,
	1,2283,1,2283,1,2283,1,2283,1,2284,1,2284,1,2284,1,2284,1,2284,1,2285,1,
	2285,1,2285,1,2285,1,2285,1,2285,1,2285,1,2286,1,2286,1,2286,1,2286,1,2286,
	1,2286,1,2286,1,2287,1,2287,1,2287,1,2287,1,2288,1,2288,1,2288,1,2288,1,
	2288,1,2288,1,2288,1,2288,1,2288,1,2289,1,2289,1,2289,1,2289,1,2289,1,2289,
	1,2289,1,2289,1,2289,1,2289,1,2289,1,2289,1,2289,1,2289,1,2289,1,2289,1,
	2289,1,2290,1,2290,1,2290,1,2290,1,2290,1,2291,1,2291,1,2291,1,2291,1,2292,
	1,2292,1,2292,1,2292,1,2292,1,2292,1,2292,1,2293,1,2293,1,2293,1,2293,1,
	2293,1,2293,1,2293,1,2293,1,2293,1,2293,1,2293,1,2293,1,2294,1,2294,1,2294,
	1,2294,1,2295,1,2295,1,2295,1,2295,1,2295,1,2295,1,2296,1,2296,1,2296,1,
	2296,1,2297,1,2297,1,2297,1,2297,1,2297,1,2297,1,2297,1,2297,1,2297,1,2297,
	1,2297,1,2297,1,2297,1,2297,1,2297,1,2297,1,2298,1,2298,1,2298,1,2298,1,
	2298,1,2298,1,2299,1,2299,1,2299,1,2299,1,2299,1,2299,1,2300,1,2300,1,2300,
	1,2300,1,2300,1,2300,1,2300,1,2300,1,2300,1,2300,1,2300,1,2301,1,2301,1,
	2301,1,2301,1,2301,1,2301,1,2301,1,2302,1,2302,1,2302,1,2302,1,2302,1,2302,
	1,2302,1,2302,1,2303,1,2303,1,2303,1,2303,1,2303,1,2304,1,2304,1,2304,1,
	2304,1,2305,1,2305,1,2305,1,2305,1,2305,1,2305,1,2305,1,2306,1,2306,1,2306,
	1,2306,1,2306,1,2307,1,2307,1,2307,1,2307,1,2307,1,2307,1,2307,1,2307,1,
	2307,1,2308,1,2308,1,2308,1,2308,1,2308,1,2308,1,2309,1,2309,1,2309,1,2309,
	1,2309,1,2309,1,2309,1,2309,1,2309,1,2310,1,2310,1,2310,1,2310,1,2310,1,
	2310,1,2310,1,2310,1,2311,1,2311,1,2311,1,2311,1,2311,1,2311,5,2311,30563,
	8,2311,10,2311,12,2311,30566,9,2311,1,2311,1,2311,1,2312,1,2312,1,2312,
	5,2312,30573,8,2312,10,2312,12,2312,30576,9,2312,1,2312,4,2312,30579,8,
	2312,11,2312,12,2312,30580,1,2313,1,2313,1,2313,5,2313,30586,8,2313,10,
	2313,12,2313,30589,9,2313,1,2313,4,2313,30592,8,2313,11,2313,12,2313,30593,
	1,2314,1,2314,1,2314,1,2315,1,2315,1,2316,4,2316,30602,8,2316,11,2316,12,
	2316,30603,1,2317,1,2317,1,2317,3,2317,30609,8,2317,1,2317,1,2317,4,2317,
	30613,8,2317,11,2317,12,2317,30614,3,2317,30617,8,2317,3,2317,30619,8,2317,
	1,2317,3,2317,30622,8,2317,1,2318,1,2318,1,2318,1,2318,1,2318,5,2318,30629,
	8,2318,10,2318,12,2318,30632,9,2318,1,2318,1,2318,1,2319,1,2319,1,2319,
	1,2319,1,2319,1,2319,1,2319,1,2319,1,2319,1,2319,3,2319,30646,8,2319,1,
	2319,1,2319,1,2319,1,2319,1,2320,1,2320,5,2320,30654,8,2320,10,2320,12,
	2320,30657,9,2320,1,2320,1,2320,1,2321,1,2321,5,2321,30663,8,2321,10,2321,
	12,2321,30666,9,2321,1,2321,1,2321,1,2322,1,2322,5,2322,30672,8,2322,10,
	2322,12,2322,30675,9,2322,1,2322,1,2322,1,2323,1,2323,5,2323,30681,8,2323,
	10,2323,12,2323,30684,9,2323,1,2323,1,2323,1,2324,1,2324,5,2324,30690,8,
	2324,10,2324,12,2324,30693,9,2324,1,2324,1,2324,1,2325,1,2325,5,2325,30699,
	8,2325,10,2325,12,2325,30702,9,2325,1,2325,1,2325,1,2326,1,2326,5,2326,
	30708,8,2326,10,2326,12,2326,30711,9,2326,1,2326,1,2326,1,2327,1,2327,5,
	2327,30717,8,2327,10,2327,12,2327,30720,9,2327,1,2327,1,2327,1,2328,1,2328,
	1,2328,1,2328,4,2328,30728,8,2328,11,2328,12,2328,30729,1,2328,1,2328,1,
	2329,1,2329,1,2330,1,2330,1,2331,1,2331,1,2332,1,2332,1,2333,1,2333,1,2333,
	1,2334,1,2334,1,2335,1,2335,1,2336,1,2336,1,2337,1,2337,1,2338,1,2338,1,
	2339,1,2339,1,2340,1,2340,1,2340,1,2341,1,2341,1,2342,1,2342,1,2343,1,2343,
	1,2343,1,2343,5,2343,30768,8,2343,10,2343,12,2343,30771,9,2343,1,2343,1,
	2343,1,2343,1,2343,1,2343,3,2343,30778,8,2343,1,2344,1,2344,1,2344,1,2344,
	1,2344,1,2344,1,2344,1,2344,3,2344,30788,8,2344,1,2345,1,2345,1,2346,1,
	2346,1,2347,1,2347,1,2348,1,2348,1,2349,1,2349,1,2350,1,2350,1,2351,1,2351,
	1,2352,1,2352,1,2353,1,2353,1,2354,1,2354,1,2355,1,2355,1,2356,1,2356,1,
	2357,1,2357,1,2357,1,2357,5,2357,30818,8,2357,10,2357,12,2357,30821,9,2357,
	1,2357,1,2357,1,2357,1,2357,1,2358,1,2358,1,2358,1,2358,5,2358,30831,8,
	2358,10,2358,12,2358,30834,9,2358,1,2358,1,2358,1,2358,1,2358,1,2358,1,
	2359,1,2359,1,2359,1,2359,1,2359,1,2359,1,2359,1,2359,3,2359,30849,8,2359,
	1,2359,1,2359,5,2359,30853,8,2359,10,2359,12,2359,30856,9,2359,3,2359,30858,
	8,2359,1,2359,1,2359,1,2359,1,2359,1,2360,1,2360,1,2360,1,2360,1,2360,1,
	2360,1,2360,1,2360,3,2360,30872,8,2360,1,2360,1,2360,5,2360,30876,8,2360,
	10,2360,12,2360,30879,9,2360,3,2360,30881,8,2360,1,2360,1,2360,1,2361,1,
	2361,3,2361,30887,8,2361,1,2362,1,2362,1,2362,5,2362,30892,8,2362,10,2362,
	12,2362,30895,9,2362,1,2363,4,2363,30898,8,2363,11,2363,12,2363,30899,1,
	2363,1,2363,1,2364,1,2364,3,2364,30906,8,2364,1,2365,1,2365,1,2366,1,2366,
	1,2367,5,2367,30913,8,2367,10,2367,12,2367,30916,9,2367,1,2367,3,2367,30919,
	8,2367,1,2367,4,2367,30922,8,2367,11,2367,12,2367,30923,1,2368,3,2368,30927,
	8,2368,1,2368,1,2368,1,2369,1,2369,9,30655,30664,30673,30682,30691,30700,
	30709,30718,30832,0,2370,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,17,9,19,10,
	21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,39,20,41,21,43,22,
	45,23,47,24,49,25,51,26,53,27,55,28,57,29,59,30,61,31,63,32,65,33,67,34,
	69,35,71,36,73,37,75,38,77,39,79,40,81,41,83,42,85,43,87,44,89,45,91,46,
	93,47,95,48,97,49,99,50,101,51,103,52,105,53,107,54,109,55,111,56,113,57,
	115,58,117,59,119,60,121,61,123,62,125,63,127,64,129,65,131,66,133,67,135,
	68,137,69,139,70,141,71,143,72,145,73,147,74,149,75,151,76,153,77,155,78,
	157,79,159,80,161,81,163,82,165,83,167,84,169,85,171,86,173,87,175,88,177,
	89,179,90,181,91,183,92,185,93,187,94,189,95,191,96,193,97,195,98,197,99,
	199,100,201,101,203,102,205,103,207,104,209,105,211,106,213,107,215,108,
	217,109,219,110,221,111,223,112,225,113,227,114,229,115,231,116,233,117,
	235,118,237,119,239,120,241,121,243,122,245,123,247,124,249,125,251,126,
	253,127,255,128,257,129,259,130,261,131,263,132,265,133,267,134,269,135,
	271,136,273,137,275,138,277,139,279,140,281,141,283,142,285,143,287,144,
	289,145,291,146,293,147,295,148,297,149,299,150,301,151,303,152,305,153,
	307,154,309,155,311,156,313,157,315,158,317,159,319,160,321,161,323,162,
	325,163,327,164,329,165,331,166,333,167,335,168,337,169,339,170,341,171,
	343,172,345,173,347,174,349,175,351,176,353,177,355,178,357,179,359,180,
	361,181,363,182,365,183,367,184,369,185,371,186,373,187,375,188,377,189,
	379,190,381,191,383,192,385,193,387,194,389,195,391,196,393,197,395,198,
	397,199,399,200,401,201,403,202,405,203,407,204,409,205,411,206,413,207,
	415,208,417,209,419,210,421,211,423,212,425,213,427,214,429,215,431,216,
	433,217,435,218,437,219,439,220,441,221,443,222,445,223,447,224,449,225,
	451,226,453,227,455,228,457,229,459,230,461,231,463,232,465,233,467,234,
	469,235,471,236,473,237,475,238,477,239,479,240,481,241,483,242,485,243,
	487,244,489,245,491,246,493,247,495,248,497,249,499,250,501,251,503,252,
	505,253,507,254,509,255,511,256,513,257,515,258,517,259,519,260,521,261,
	523,262,525,263,527,264,529,265,531,266,533,267,535,268,537,269,539,270,
	541,271,543,272,545,273,547,274,549,275,551,276,553,277,555,278,557,279,
	559,280,561,281,563,282,565,283,567,284,569,285,571,286,573,287,575,288,
	577,289,579,290,581,291,583,292,585,293,587,294,589,295,591,296,593,297,
	595,298,597,299,599,300,601,301,603,302,605,303,607,304,609,305,611,306,
	613,307,615,308,617,309,619,310,621,311,623,312,625,313,627,314,629,315,
	631,316,633,317,635,318,637,319,639,320,641,321,643,322,645,323,647,324,
	649,325,651,326,653,327,655,328,657,329,659,330,661,331,663,332,665,333,
	667,334,669,335,671,336,673,337,675,338,677,339,679,340,681,341,683,342,
	685,343,687,344,689,345,691,346,693,347,695,348,697,349,699,350,701,351,
	703,352,705,353,707,354,709,355,711,356,713,357,715,358,717,359,719,360,
	721,361,723,362,725,363,727,364,729,365,731,366,733,367,735,368,737,369,
	739,370,741,371,743,372,745,373,747,374,749,375,751,376,753,377,755,378,
	757,379,759,380,761,381,763,382,765,383,767,384,769,385,771,386,773,387,
	775,388,777,389,779,390,781,391,783,392,785,393,787,394,789,395,791,396,
	793,397,795,398,797,399,799,400,801,401,803,402,805,403,807,404,809,405,
	811,406,813,407,815,408,817,409,819,410,821,411,823,412,825,413,827,414,
	829,415,831,416,833,417,835,418,837,419,839,420,841,421,843,422,845,423,
	847,424,849,425,851,426,853,427,855,428,857,429,859,430,861,431,863,432,
	865,433,867,434,869,435,871,436,873,437,875,438,877,439,879,440,881,441,
	883,442,885,443,887,444,889,445,891,446,893,447,895,448,897,449,899,450,
	901,451,903,452,905,453,907,454,909,455,911,456,913,457,915,458,917,459,
	919,460,921,461,923,462,925,463,927,464,929,465,931,466,933,467,935,468,
	937,469,939,470,941,471,943,472,945,473,947,474,949,475,951,476,953,477,
	955,478,957,479,959,480,961,481,963,482,965,483,967,484,969,485,971,486,
	973,487,975,488,977,489,979,490,981,491,983,492,985,493,987,494,989,495,
	991,496,993,497,995,498,997,499,999,500,1001,501,1003,502,1005,503,1007,
	504,1009,505,1011,506,1013,507,1015,508,1017,509,1019,510,1021,511,1023,
	512,1025,513,1027,514,1029,515,1031,516,1033,517,1035,518,1037,519,1039,
	520,1041,521,1043,522,1045,523,1047,524,1049,525,1051,526,1053,527,1055,
	528,1057,529,1059,530,1061,531,1063,532,1065,533,1067,534,1069,535,1071,
	536,1073,537,1075,538,1077,539,1079,540,1081,541,1083,542,1085,543,1087,
	544,1089,545,1091,546,1093,547,1095,548,1097,549,1099,550,1101,551,1103,
	552,1105,553,1107,554,1109,555,1111,556,1113,557,1115,558,1117,559,1119,
	560,1121,561,1123,562,1125,563,1127,564,1129,565,1131,566,1133,567,1135,
	568,1137,569,1139,570,1141,571,1143,572,1145,573,1147,574,1149,575,1151,
	576,1153,577,1155,578,1157,579,1159,580,1161,581,1163,582,1165,583,1167,
	584,1169,585,1171,586,1173,587,1175,588,1177,589,1179,590,1181,591,1183,
	592,1185,593,1187,594,1189,595,1191,596,1193,597,1195,598,1197,599,1199,
	600,1201,601,1203,602,1205,603,1207,604,1209,605,1211,606,1213,607,1215,
	608,1217,609,1219,610,1221,611,1223,612,1225,613,1227,614,1229,615,1231,
	616,1233,617,1235,618,1237,619,1239,620,1241,621,1243,622,1245,623,1247,
	624,1249,625,1251,626,1253,627,1255,628,1257,629,1259,630,1261,631,1263,
	632,1265,633,1267,634,1269,635,1271,636,1273,637,1275,638,1277,639,1279,
	640,1281,641,1283,642,1285,643,1287,644,1289,645,1291,646,1293,647,1295,
	648,1297,649,1299,650,1301,651,1303,652,1305,653,1307,654,1309,655,1311,
	656,1313,657,1315,658,1317,659,1319,660,1321,661,1323,662,1325,663,1327,
	664,1329,665,1331,666,1333,667,1335,668,1337,669,1339,670,1341,671,1343,
	672,1345,673,1347,674,1349,675,1351,676,1353,677,1355,678,1357,679,1359,
	680,1361,681,1363,682,1365,683,1367,684,1369,685,1371,686,1373,687,1375,
	688,1377,689,1379,690,1381,691,1383,692,1385,693,1387,694,1389,695,1391,
	696,1393,697,1395,698,1397,699,1399,700,1401,701,1403,702,1405,703,1407,
	704,1409,705,1411,706,1413,707,1415,708,1417,709,1419,710,1421,711,1423,
	712,1425,713,1427,714,1429,715,1431,716,1433,717,1435,718,1437,719,1439,
	720,1441,721,1443,722,1445,723,1447,724,1449,725,1451,726,1453,727,1455,
	728,1457,729,1459,730,1461,731,1463,732,1465,733,1467,734,1469,735,1471,
	736,1473,737,1475,738,1477,739,1479,740,1481,741,1483,742,1485,743,1487,
	744,1489,745,1491,746,1493,747,1495,748,1497,749,1499,750,1501,751,1503,
	752,1505,753,1507,754,1509,755,1511,756,1513,757,1515,758,1517,759,1519,
	760,1521,761,1523,762,1525,763,1527,764,1529,765,1531,766,1533,767,1535,
	768,1537,769,1539,770,1541,771,1543,772,1545,773,1547,774,1549,775,1551,
	776,1553,777,1555,778,1557,779,1559,780,1561,781,1563,782,1565,783,1567,
	784,1569,785,1571,786,1573,787,1575,788,1577,789,1579,790,1581,791,1583,
	792,1585,793,1587,794,1589,795,1591,796,1593,797,1595,798,1597,799,1599,
	800,1601,801,1603,802,1605,803,1607,804,1609,805,1611,806,1613,807,1615,
	808,1617,809,1619,810,1621,811,1623,812,1625,813,1627,814,1629,815,1631,
	816,1633,817,1635,818,1637,819,1639,820,1641,821,1643,822,1645,823,1647,
	824,1649,825,1651,826,1653,827,1655,828,1657,829,1659,830,1661,831,1663,
	832,1665,833,1667,834,1669,835,1671,836,1673,837,1675,838,1677,839,1679,
	840,1681,841,1683,842,1685,843,1687,844,1689,845,1691,846,1693,847,1695,
	848,1697,849,1699,850,1701,851,1703,852,1705,853,1707,854,1709,855,1711,
	856,1713,857,1715,858,1717,859,1719,860,1721,861,1723,862,1725,863,1727,
	864,1729,865,1731,866,1733,867,1735,868,1737,869,1739,870,1741,871,1743,
	872,1745,873,1747,874,1749,875,1751,876,1753,877,1755,878,1757,879,1759,
	880,1761,881,1763,882,1765,883,1767,884,1769,885,1771,886,1773,887,1775,
	888,1777,889,1779,890,1781,891,1783,892,1785,893,1787,894,1789,895,1791,
	896,1793,897,1795,898,1797,899,1799,900,1801,901,1803,902,1805,903,1807,
	904,1809,905,1811,906,1813,907,1815,908,1817,909,1819,910,1821,911,1823,
	912,1825,913,1827,914,1829,915,1831,916,1833,917,1835,918,1837,919,1839,
	920,1841,921,1843,922,1845,923,1847,924,1849,925,1851,926,1853,927,1855,
	928,1857,929,1859,930,1861,931,1863,932,1865,933,1867,934,1869,935,1871,
	936,1873,937,1875,938,1877,939,1879,940,1881,941,1883,942,1885,943,1887,
	944,1889,945,1891,946,1893,947,1895,948,1897,949,1899,950,1901,951,1903,
	952,1905,953,1907,954,1909,955,1911,956,1913,957,1915,958,1917,959,1919,
	960,1921,961,1923,962,1925,963,1927,964,1929,965,1931,966,1933,967,1935,
	968,1937,969,1939,970,1941,971,1943,972,1945,973,1947,974,1949,975,1951,
	976,1953,977,1955,978,1957,979,1959,980,1961,981,1963,982,1965,983,1967,
	984,1969,985,1971,986,1973,987,1975,988,1977,989,1979,990,1981,991,1983,
	992,1985,993,1987,994,1989,995,1991,996,1993,997,1995,998,1997,999,1999,
	1000,2001,1001,2003,1002,2005,1003,2007,1004,2009,1005,2011,1006,2013,1007,
	2015,1008,2017,1009,2019,1010,2021,1011,2023,1012,2025,1013,2027,1014,2029,
	1015,2031,1016,2033,1017,2035,1018,2037,1019,2039,1020,2041,1021,2043,1022,
	2045,1023,2047,1024,2049,1025,2051,1026,2053,1027,2055,1028,2057,1029,2059,
	1030,2061,1031,2063,1032,2065,1033,2067,1034,2069,1035,2071,1036,2073,1037,
	2075,1038,2077,1039,2079,1040,2081,1041,2083,1042,2085,1043,2087,1044,2089,
	1045,2091,1046,2093,1047,2095,1048,2097,1049,2099,1050,2101,1051,2103,1052,
	2105,1053,2107,1054,2109,1055,2111,1056,2113,1057,2115,1058,2117,1059,2119,
	1060,2121,1061,2123,1062,2125,1063,2127,1064,2129,1065,2131,1066,2133,1067,
	2135,1068,2137,1069,2139,1070,2141,1071,2143,1072,2145,1073,2147,1074,2149,
	1075,2151,1076,2153,1077,2155,1078,2157,1079,2159,1080,2161,1081,2163,1082,
	2165,1083,2167,1084,2169,1085,2171,1086,2173,1087,2175,1088,2177,1089,2179,
	1090,2181,1091,2183,1092,2185,1093,2187,1094,2189,1095,2191,1096,2193,1097,
	2195,1098,2197,1099,2199,1100,2201,1101,2203,1102,2205,1103,2207,1104,2209,
	1105,2211,1106,2213,1107,2215,1108,2217,1109,2219,1110,2221,1111,2223,1112,
	2225,1113,2227,1114,2229,1115,2231,1116,2233,1117,2235,1118,2237,1119,2239,
	1120,2241,1121,2243,1122,2245,1123,2247,1124,2249,1125,2251,1126,2253,1127,
	2255,1128,2257,1129,2259,1130,2261,1131,2263,1132,2265,1133,2267,1134,2269,
	1135,2271,1136,2273,1137,2275,1138,2277,1139,2279,1140,2281,1141,2283,1142,
	2285,1143,2287,1144,2289,1145,2291,1146,2293,1147,2295,1148,2297,1149,2299,
	1150,2301,1151,2303,1152,2305,1153,2307,1154,2309,1155,2311,1156,2313,1157,
	2315,1158,2317,1159,2319,1160,2321,1161,2323,1162,2325,1163,2327,1164,2329,
	1165,2331,1166,2333,1167,2335,1168,2337,1169,2339,1170,2341,1171,2343,1172,
	2345,1173,2347,1174,2349,1175,2351,1176,2353,1177,2355,1178,2357,1179,2359,
	1180,2361,1181,2363,1182,2365,1183,2367,1184,2369,1185,2371,1186,2373,1187,
	2375,1188,2377,1189,2379,1190,2381,1191,2383,1192,2385,1193,2387,1194,2389,
	1195,2391,1196,2393,1197,2395,1198,2397,1199,2399,1200,2401,1201,2403,1202,
	2405,1203,2407,1204,2409,1205,2411,1206,2413,1207,2415,1208,2417,1209,2419,
	1210,2421,1211,2423,1212,2425,1213,2427,1214,2429,1215,2431,1216,2433,1217,
	2435,1218,2437,1219,2439,1220,2441,1221,2443,1222,2445,1223,2447,1224,2449,
	1225,2451,1226,2453,1227,2455,1228,2457,1229,2459,1230,2461,1231,2463,1232,
	2465,1233,2467,1234,2469,1235,2471,1236,2473,1237,2475,1238,2477,1239,2479,
	1240,2481,1241,2483,1242,2485,1243,2487,1244,2489,1245,2491,1246,2493,1247,
	2495,1248,2497,1249,2499,1250,2501,1251,2503,1252,2505,1253,2507,1254,2509,
	1255,2511,1256,2513,1257,2515,1258,2517,1259,2519,1260,2521,1261,2523,1262,
	2525,1263,2527,1264,2529,1265,2531,1266,2533,1267,2535,1268,2537,1269,2539,
	1270,2541,1271,2543,1272,2545,1273,2547,1274,2549,1275,2551,1276,2553,1277,
	2555,1278,2557,1279,2559,1280,2561,1281,2563,1282,2565,1283,2567,1284,2569,
	1285,2571,1286,2573,1287,2575,1288,2577,1289,2579,1290,2581,1291,2583,1292,
	2585,1293,2587,1294,2589,1295,2591,1296,2593,1297,2595,1298,2597,1299,2599,
	1300,2601,1301,2603,1302,2605,1303,2607,1304,2609,1305,2611,1306,2613,1307,
	2615,1308,2617,1309,2619,1310,2621,1311,2623,1312,2625,1313,2627,1314,2629,
	1315,2631,1316,2633,1317,2635,1318,2637,1319,2639,1320,2641,1321,2643,1322,
	2645,1323,2647,1324,2649,1325,2651,1326,2653,1327,2655,1328,2657,1329,2659,
	1330,2661,1331,2663,1332,2665,1333,2667,1334,2669,1335,2671,1336,2673,1337,
	2675,1338,2677,1339,2679,1340,2681,1341,2683,1342,2685,1343,2687,1344,2689,
	1345,2691,1346,2693,1347,2695,1348,2697,1349,2699,1350,2701,1351,2703,1352,
	2705,1353,2707,1354,2709,1355,2711,1356,2713,1357,2715,1358,2717,1359,2719,
	1360,2721,1361,2723,1362,2725,1363,2727,1364,2729,1365,2731,1366,2733,1367,
	2735,1368,2737,1369,2739,1370,2741,1371,2743,1372,2745,1373,2747,1374,2749,
	1375,2751,1376,2753,1377,2755,1378,2757,1379,2759,1380,2761,1381,2763,1382,
	2765,1383,2767,1384,2769,1385,2771,1386,2773,1387,2775,1388,2777,1389,2779,
	1390,2781,1391,2783,1392,2785,1393,2787,1394,2789,1395,2791,1396,2793,1397,
	2795,1398,2797,1399,2799,1400,2801,1401,2803,1402,2805,1403,2807,1404,2809,
	1405,2811,1406,2813,1407,2815,1408,2817,1409,2819,1410,2821,1411,2823,1412,
	2825,1413,2827,1414,2829,1415,2831,1416,2833,1417,2835,1418,2837,1419,2839,
	1420,2841,1421,2843,1422,2845,1423,2847,1424,2849,1425,2851,1426,2853,1427,
	2855,1428,2857,1429,2859,1430,2861,1431,2863,1432,2865,1433,2867,1434,2869,
	1435,2871,1436,2873,1437,2875,1438,2877,1439,2879,1440,2881,1441,2883,1442,
	2885,1443,2887,1444,2889,1445,2891,1446,2893,1447,2895,1448,2897,1449,2899,
	1450,2901,1451,2903,1452,2905,1453,2907,1454,2909,1455,2911,1456,2913,1457,
	2915,1458,2917,1459,2919,1460,2921,1461,2923,1462,2925,1463,2927,1464,2929,
	1465,2931,1466,2933,1467,2935,1468,2937,1469,2939,1470,2941,1471,2943,1472,
	2945,1473,2947,1474,2949,1475,2951,1476,2953,1477,2955,1478,2957,1479,2959,
	1480,2961,1481,2963,1482,2965,1483,2967,1484,2969,1485,2971,1486,2973,1487,
	2975,1488,2977,1489,2979,1490,2981,1491,2983,1492,2985,1493,2987,1494,2989,
	1495,2991,1496,2993,1497,2995,1498,2997,1499,2999,1500,3001,1501,3003,1502,
	3005,1503,3007,1504,3009,1505,3011,1506,3013,1507,3015,1508,3017,1509,3019,
	1510,3021,1511,3023,1512,3025,1513,3027,1514,3029,1515,3031,1516,3033,1517,
	3035,1518,3037,1519,3039,1520,3041,1521,3043,1522,3045,1523,3047,1524,3049,
	1525,3051,1526,3053,1527,3055,1528,3057,1529,3059,1530,3061,1531,3063,1532,
	3065,1533,3067,1534,3069,1535,3071,1536,3073,1537,3075,1538,3077,1539,3079,
	1540,3081,1541,3083,1542,3085,1543,3087,1544,3089,1545,3091,1546,3093,1547,
	3095,1548,3097,1549,3099,1550,3101,1551,3103,1552,3105,1553,3107,1554,3109,
	1555,3111,1556,3113,1557,3115,1558,3117,1559,3119,1560,3121,1561,3123,1562,
	3125,1563,3127,1564,3129,1565,3131,1566,3133,1567,3135,1568,3137,1569,3139,
	1570,3141,1571,3143,1572,3145,1573,3147,1574,3149,1575,3151,1576,3153,1577,
	3155,1578,3157,1579,3159,1580,3161,1581,3163,1582,3165,1583,3167,1584,3169,
	1585,3171,1586,3173,1587,3175,1588,3177,1589,3179,1590,3181,1591,3183,1592,
	3185,1593,3187,1594,3189,1595,3191,1596,3193,1597,3195,1598,3197,1599,3199,
	1600,3201,1601,3203,1602,3205,1603,3207,1604,3209,1605,3211,1606,3213,1607,
	3215,1608,3217,1609,3219,1610,3221,1611,3223,1612,3225,1613,3227,1614,3229,
	1615,3231,1616,3233,1617,3235,1618,3237,1619,3239,1620,3241,1621,3243,1622,
	3245,1623,3247,1624,3249,1625,3251,1626,3253,1627,3255,1628,3257,1629,3259,
	1630,3261,1631,3263,1632,3265,1633,3267,1634,3269,1635,3271,1636,3273,1637,
	3275,1638,3277,1639,3279,1640,3281,1641,3283,1642,3285,1643,3287,1644,3289,
	1645,3291,1646,3293,1647,3295,1648,3297,1649,3299,1650,3301,1651,3303,1652,
	3305,1653,3307,1654,3309,1655,3311,1656,3313,1657,3315,1658,3317,1659,3319,
	1660,3321,1661,3323,1662,3325,1663,3327,1664,3329,1665,3331,1666,3333,1667,
	3335,1668,3337,1669,3339,1670,3341,1671,3343,1672,3345,1673,3347,1674,3349,
	1675,3351,1676,3353,1677,3355,1678,3357,1679,3359,1680,3361,1681,3363,1682,
	3365,1683,3367,1684,3369,1685,3371,1686,3373,1687,3375,1688,3377,1689,3379,
	1690,3381,1691,3383,1692,3385,1693,3387,1694,3389,1695,3391,1696,3393,1697,
	3395,1698,3397,1699,3399,1700,3401,1701,3403,1702,3405,1703,3407,1704,3409,
	1705,3411,1706,3413,1707,3415,1708,3417,1709,3419,1710,3421,1711,3423,1712,
	3425,1713,3427,1714,3429,1715,3431,1716,3433,1717,3435,1718,3437,1719,3439,
	1720,3441,1721,3443,1722,3445,1723,3447,1724,3449,1725,3451,1726,3453,1727,
	3455,1728,3457,1729,3459,1730,3461,1731,3463,1732,3465,1733,3467,1734,3469,
	1735,3471,1736,3473,1737,3475,1738,3477,1739,3479,1740,3481,1741,3483,1742,
	3485,1743,3487,1744,3489,1745,3491,1746,3493,1747,3495,1748,3497,1749,3499,
	1750,3501,1751,3503,1752,3505,1753,3507,1754,3509,1755,3511,1756,3513,1757,
	3515,1758,3517,1759,3519,1760,3521,1761,3523,1762,3525,1763,3527,1764,3529,
	1765,3531,1766,3533,1767,3535,1768,3537,1769,3539,1770,3541,1771,3543,1772,
	3545,1773,3547,1774,3549,1775,3551,1776,3553,1777,3555,1778,3557,1779,3559,
	1780,3561,1781,3563,1782,3565,1783,3567,1784,3569,1785,3571,1786,3573,1787,
	3575,1788,3577,1789,3579,1790,3581,1791,3583,1792,3585,1793,3587,1794,3589,
	1795,3591,1796,3593,1797,3595,1798,3597,1799,3599,1800,3601,1801,3603,1802,
	3605,1803,3607,1804,3609,1805,3611,1806,3613,1807,3615,1808,3617,1809,3619,
	1810,3621,1811,3623,1812,3625,1813,3627,1814,3629,1815,3631,1816,3633,1817,
	3635,1818,3637,1819,3639,1820,3641,1821,3643,1822,3645,1823,3647,1824,3649,
	1825,3651,1826,3653,1827,3655,1828,3657,1829,3659,1830,3661,1831,3663,1832,
	3665,1833,3667,1834,3669,1835,3671,1836,3673,1837,3675,1838,3677,1839,3679,
	1840,3681,1841,3683,1842,3685,1843,3687,1844,3689,1845,3691,1846,3693,1847,
	3695,1848,3697,1849,3699,1850,3701,1851,3703,1852,3705,1853,3707,1854,3709,
	1855,3711,1856,3713,1857,3715,1858,3717,1859,3719,1860,3721,1861,3723,1862,
	3725,1863,3727,1864,3729,1865,3731,1866,3733,1867,3735,1868,3737,1869,3739,
	1870,3741,1871,3743,1872,3745,1873,3747,1874,3749,1875,3751,1876,3753,1877,
	3755,1878,3757,1879,3759,1880,3761,1881,3763,1882,3765,1883,3767,1884,3769,
	1885,3771,1886,3773,1887,3775,1888,3777,1889,3779,1890,3781,1891,3783,1892,
	3785,1893,3787,1894,3789,1895,3791,1896,3793,1897,3795,1898,3797,1899,3799,
	1900,3801,1901,3803,1902,3805,1903,3807,1904,3809,1905,3811,1906,3813,1907,
	3815,1908,3817,1909,3819,1910,3821,1911,3823,1912,3825,1913,3827,1914,3829,
	1915,3831,1916,3833,1917,3835,1918,3837,1919,3839,1920,3841,1921,3843,1922,
	3845,1923,3847,1924,3849,1925,3851,1926,3853,1927,3855,1928,3857,1929,3859,
	1930,3861,1931,3863,1932,3865,1933,3867,1934,3869,1935,3871,1936,3873,1937,
	3875,1938,3877,1939,3879,1940,3881,1941,3883,1942,3885,1943,3887,1944,3889,
	1945,3891,1946,3893,1947,3895,1948,3897,1949,3899,1950,3901,1951,3903,1952,
	3905,1953,3907,1954,3909,1955,3911,1956,3913,1957,3915,1958,3917,1959,3919,
	1960,3921,1961,3923,1962,3925,1963,3927,1964,3929,1965,3931,1966,3933,1967,
	3935,1968,3937,1969,3939,1970,3941,1971,3943,1972,3945,1973,3947,1974,3949,
	1975,3951,1976,3953,1977,3955,1978,3957,1979,3959,1980,3961,1981,3963,1982,
	3965,1983,3967,1984,3969,1985,3971,1986,3973,1987,3975,1988,3977,1989,3979,
	1990,3981,1991,3983,1992,3985,1993,3987,1994,3989,1995,3991,1996,3993,1997,
	3995,1998,3997,1999,3999,2000,4001,2001,4003,2002,4005,2003,4007,2004,4009,
	2005,4011,2006,4013,2007,4015,2008,4017,2009,4019,2010,4021,2011,4023,2012,
	4025,2013,4027,2014,4029,2015,4031,2016,4033,2017,4035,2018,4037,2019,4039,
	2020,4041,2021,4043,2022,4045,2023,4047,2024,4049,2025,4051,2026,4053,2027,
	4055,2028,4057,2029,4059,2030,4061,2031,4063,2032,4065,2033,4067,2034,4069,
	2035,4071,2036,4073,2037,4075,2038,4077,2039,4079,2040,4081,2041,4083,2042,
	4085,2043,4087,2044,4089,2045,4091,2046,4093,2047,4095,2048,4097,2049,4099,
	2050,4101,2051,4103,2052,4105,2053,4107,2054,4109,2055,4111,2056,4113,2057,
	4115,2058,4117,2059,4119,2060,4121,2061,4123,2062,4125,2063,4127,2064,4129,
	2065,4131,2066,4133,2067,4135,2068,4137,2069,4139,2070,4141,2071,4143,2072,
	4145,2073,4147,2074,4149,2075,4151,2076,4153,2077,4155,2078,4157,2079,4159,
	2080,4161,2081,4163,2082,4165,2083,4167,2084,4169,2085,4171,2086,4173,2087,
	4175,2088,4177,2089,4179,2090,4181,2091,4183,2092,4185,2093,4187,2094,4189,
	2095,4191,2096,4193,2097,4195,2098,4197,2099,4199,2100,4201,2101,4203,2102,
	4205,2103,4207,2104,4209,2105,4211,2106,4213,2107,4215,2108,4217,2109,4219,
	2110,4221,2111,4223,2112,4225,2113,4227,2114,4229,2115,4231,2116,4233,2117,
	4235,2118,4237,2119,4239,2120,4241,2121,4243,2122,4245,2123,4247,2124,4249,
	2125,4251,2126,4253,2127,4255,2128,4257,2129,4259,2130,4261,2131,4263,2132,
	4265,2133,4267,2134,4269,2135,4271,2136,4273,2137,4275,2138,4277,2139,4279,
	2140,4281,2141,4283,2142,4285,2143,4287,2144,4289,2145,4291,2146,4293,2147,
	4295,2148,4297,2149,4299,2150,4301,2151,4303,2152,4305,2153,4307,2154,4309,
	2155,4311,2156,4313,2157,4315,2158,4317,2159,4319,2160,4321,2161,4323,2162,
	4325,2163,4327,2164,4329,2165,4331,2166,4333,2167,4335,2168,4337,2169,4339,
	2170,4341,2171,4343,2172,4345,2173,4347,2174,4349,2175,4351,2176,4353,2177,
	4355,2178,4357,2179,4359,2180,4361,2181,4363,2182,4365,2183,4367,2184,4369,
	2185,4371,2186,4373,2187,4375,2188,4377,2189,4379,2190,4381,2191,4383,2192,
	4385,2193,4387,2194,4389,2195,4391,2196,4393,2197,4395,2198,4397,2199,4399,
	2200,4401,2201,4403,2202,4405,2203,4407,2204,4409,2205,4411,2206,4413,2207,
	4415,2208,4417,2209,4419,2210,4421,2211,4423,2212,4425,2213,4427,2214,4429,
	2215,4431,2216,4433,2217,4435,2218,4437,2219,4439,2220,4441,2221,4443,2222,
	4445,2223,4447,2224,4449,2225,4451,2226,4453,2227,4455,2228,4457,2229,4459,
	2230,4461,2231,4463,2232,4465,2233,4467,2234,4469,2235,4471,2236,4473,2237,
	4475,2238,4477,2239,4479,2240,4481,2241,4483,2242,4485,2243,4487,2244,4489,
	2245,4491,2246,4493,2247,4495,2248,4497,2249,4499,2250,4501,2251,4503,2252,
	4505,2253,4507,2254,4509,2255,4511,2256,4513,2257,4515,2258,4517,2259,4519,
	2260,4521,2261,4523,2262,4525,2263,4527,2264,4529,2265,4531,2266,4533,2267,
	4535,2268,4537,2269,4539,2270,4541,2271,4543,2272,4545,2273,4547,2274,4549,
	2275,4551,2276,4553,2277,4555,2278,4557,2279,4559,2280,4561,2281,4563,2282,
	4565,2283,4567,2284,4569,2285,4571,2286,4573,2287,4575,2288,4577,2289,4579,
	2290,4581,2291,4583,2292,4585,2293,4587,2294,4589,2295,4591,2296,4593,2297,
	4595,2298,4597,2299,4599,2300,4601,2301,4603,2302,4605,2303,4607,2304,4609,
	2305,4611,2306,4613,2307,4615,2308,4617,2309,4619,2310,4621,2311,4623,2312,
	4625,2313,4627,2314,4629,2315,4631,2316,4633,2317,4635,2318,4637,2319,4639,
	0,4641,0,4643,0,4645,0,4647,0,4649,0,4651,0,4653,0,4655,0,4657,2320,4659,
	2321,4661,2322,4663,2323,4665,2324,4667,2325,4669,2326,4671,2327,4673,2328,
	4675,2329,4677,2330,4679,2331,4681,2332,4683,2333,4685,2334,4687,2335,4689,
	2336,4691,2337,4693,2338,4695,2339,4697,2340,4699,2341,4701,2342,4703,2343,
	4705,2344,4707,2345,4709,2346,4711,2347,4713,2348,4715,2349,4717,2350,4719,
	2351,4721,2352,4723,2353,4725,2354,4727,2355,4729,0,4731,0,4733,0,4735,
	0,4737,0,4739,0,1,0,39,2,0,65,65,97,97,2,0,66,66,98,98,2,0,79,79,111,111,
	2,0,82,82,114,114,2,0,84,84,116,116,2,0,83,83,115,115,2,0,69,69,101,101,
	2,0,78,78,110,110,2,0,67,67,99,99,2,0,68,68,100,100,2,0,85,85,117,117,2,
	0,76,76,108,108,2,0,73,73,105,105,2,0,86,86,118,118,2,0,77,77,109,109,2,
	0,80,80,112,112,2,0,70,70,102,102,2,0,71,71,103,103,2,0,89,89,121,121,2,
	0,72,72,104,104,2,0,74,74,106,106,2,0,75,75,107,107,2,0,87,87,119,119,2,
	0,90,90,122,122,2,0,81,81,113,113,2,0,88,88,120,120,3,0,10,10,13,13,39,
	39,1,0,48,49,3,0,48,57,65,70,97,102,1,0,48,57,2,0,43,43,45,45,4,0,68,68,
	70,70,100,100,102,102,3,0,10,10,13,13,34,34,2,0,48,57,95,95,2,0,10,10,13,
	13,3,0,35,36,48,57,95,95,3,0,9,10,13,13,32,32,2,0,65,90,97,122,2,0,9,9,
	32,32,30982,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,
	0,11,1,0,0,0,0,13,1,0,0,0,0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,
	0,0,0,0,23,1,0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,
	0,33,1,0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,
	0,0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,
	0,55,1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,
	0,0,0,0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,0,75,1,0,0,0,
	0,77,1,0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,0,85,1,0,0,0,0,87,1,
	0,0,0,0,89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,0,0,0,95,1,0,0,0,0,97,1,0,0,0,
	0,99,1,0,0,0,0,101,1,0,0,0,0,103,1,0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,
	109,1,0,0,0,0,111,1,0,0,0,0,113,1,0,0,0,0,115,1,0,0,0,0,117,1,0,0,0,0,119,
	1,0,0,0,0,121,1,0,0,0,0,123,1,0,0,0,0,125,1,0,0,0,0,127,1,0,0,0,0,129,1,
	0,0,0,0,131,1,0,0,0,0,133,1,0,0,0,0,135,1,0,0,0,0,137,1,0,0,0,0,139,1,0,
	0,0,0,141,1,0,0,0,0,143,1,0,0,0,0,145,1,0,0,0,0,147,1,0,0,0,0,149,1,0,0,
	0,0,151,1,0,0,0,0,153,1,0,0,0,0,155,1,0,0,0,0,157,1,0,0,0,0,159,1,0,0,0,
	0,161,1,0,0,0,0,163,1,0,0,0,0,165,1,0,0,0,0,167,1,0,0,0,0,169,1,0,0,0,0,
	171,1,0,0,0,0,173,1,0,0,0,0,175,1,0,0,0,0,177,1,0,0,0,0,179,1,0,0,0,0,181,
	1,0,0,0,0,183,1,0,0,0,0,185,1,0,0,0,0,187,1,0,0,0,0,189,1,0,0,0,0,191,1,
	0,0,0,0,193,1,0,0,0,0,195,1,0,0,0,0,197,1,0,0,0,0,199,1,0,0,0,0,201,1,0,
	0,0,0,203,1,0,0,0,0,205,1,0,0,0,0,207,1,0,0,0,0,209,1,0,0,0,0,211,1,0,0,
	0,0,213,1,0,0,0,0,215,1,0,0,0,0,217,1,0,0,0,0,219,1,0,0,0,0,221,1,0,0,0,
	0,223,1,0,0,0,0,225,1,0,0,0,0,227,1,0,0,0,0,229,1,0,0,0,0,231,1,0,0,0,0,
	233,1,0,0,0,0,235,1,0,0,0,0,237,1,0,0,0,0,239,1,0,0,0,0,241,1,0,0,0,0,243,
	1,0,0,0,0,245,1,0,0,0,0,247,1,0,0,0,0,249,1,0,0,0,0,251,1,0,0,0,0,253,1,
	0,0,0,0,255,1,0,0,0,0,257,1,0,0,0,0,259,1,0,0,0,0,261,1,0,0,0,0,263,1,0,
	0,0,0,265,1,0,0,0,0,267,1,0,0,0,0,269,1,0,0,0,0,271,1,0,0,0,0,273,1,0,0,
	0,0,275,1,0,0,0,0,277,1,0,0,0,0,279,1,0,0,0,0,281,1,0,0,0,0,283,1,0,0,0,
	0,285,1,0,0,0,0,287,1,0,0,0,0,289,1,0,0,0,0,291,1,0,0,0,0,293,1,0,0,0,0,
	295,1,0,0,0,0,297,1,0,0,0,0,299,1,0,0,0,0,301,1,0,0,0,0,303,1,0,0,0,0,305,
	1,0,0,0,0,307,1,0,0,0,0,309,1,0,0,0,0,311,1,0,0,0,0,313,1,0,0,0,0,315,1,
	0,0,0,0,317,1,0,0,0,0,319,1,0,0,0,0,321,1,0,0,0,0,323,1,0,0,0,0,325,1,0,
	0,0,0,327,1,0,0,0,0,329,1,0,0,0,0,331,1,0,0,0,0,333,1,0,0,0,0,335,1,0,0,
	0,0,337,1,0,0,0,0,339,1,0,0,0,0,341,1,0,0,0,0,343,1,0,0,0,0,345,1,0,0,0,
	0,347,1,0,0,0,0,349,1,0,0,0,0,351,1,0,0,0,0,353,1,0,0,0,0,355,1,0,0,0,0,
	357,1,0,0,0,0,359,1,0,0,0,0,361,1,0,0,0,0,363,1,0,0,0,0,365,1,0,0,0,0,367,
	1,0,0,0,0,369,1,0,0,0,0,371,1,0,0,0,0,373,1,0,0,0,0,375,1,0,0,0,0,377,1,
	0,0,0,0,379,1,0,0,0,0,381,1,0,0,0,0,383,1,0,0,0,0,385,1,0,0,0,0,387,1,0,
	0,0,0,389,1,0,0,0,0,391,1,0,0,0,0,393,1,0,0,0,0,395,1,0,0,0,0,397,1,0,0,
	0,0,399,1,0,0,0,0,401,1,0,0,0,0,403,1,0,0,0,0,405,1,0,0,0,0,407,1,0,0,0,
	0,409,1,0,0,0,0,411,1,0,0,0,0,413,1,0,0,0,0,415,1,0,0,0,0,417,1,0,0,0,0,
	419,1,0,0,0,0,421,1,0,0,0,0,423,1,0,0,0,0,425,1,0,0,0,0,427,1,0,0,0,0,429,
	1,0,0,0,0,431,1,0,0,0,0,433,1,0,0,0,0,435,1,0,0,0,0,437,1,0,0,0,0,439,1,
	0,0,0,0,441,1,0,0,0,0,443,1,0,0,0,0,445,1,0,0,0,0,447,1,0,0,0,0,449,1,0,
	0,0,0,451,1,0,0,0,0,453,1,0,0,0,0,455,1,0,0,0,0,457,1,0,0,0,0,459,1,0,0,
	0,0,461,1,0,0,0,0,463,1,0,0,0,0,465,1,0,0,0,0,467,1,0,0,0,0,469,1,0,0,0,
	0,471,1,0,0,0,0,473,1,0,0,0,0,475,1,0,0,0,0,477,1,0,0,0,0,479,1,0,0,0,0,
	481,1,0,0,0,0,483,1,0,0,0,0,485,1,0,0,0,0,487,1,0,0,0,0,489,1,0,0,0,0,491,
	1,0,0,0,0,493,1,0,0,0,0,495,1,0,0,0,0,497,1,0,0,0,0,499,1,0,0,0,0,501,1,
	0,0,0,0,503,1,0,0,0,0,505,1,0,0,0,0,507,1,0,0,0,0,509,1,0,0,0,0,511,1,0,
	0,0,0,513,1,0,0,0,0,515,1,0,0,0,0,517,1,0,0,0,0,519,1,0,0,0,0,521,1,0,0,
	0,0,523,1,0,0,0,0,525,1,0,0,0,0,527,1,0,0,0,0,529,1,0,0,0,0,531,1,0,0,0,
	0,533,1,0,0,0,0,535,1,0,0,0,0,537,1,0,0,0,0,539,1,0,0,0,0,541,1,0,0,0,0,
	543,1,0,0,0,0,545,1,0,0,0,0,547,1,0,0,0,0,549,1,0,0,0,0,551,1,0,0,0,0,553,
	1,0,0,0,0,555,1,0,0,0,0,557,1,0,0,0,0,559,1,0,0,0,0,561,1,0,0,0,0,563,1,
	0,0,0,0,565,1,0,0,0,0,567,1,0,0,0,0,569,1,0,0,0,0,571,1,0,0,0,0,573,1,0,
	0,0,0,575,1,0,0,0,0,577,1,0,0,0,0,579,1,0,0,0,0,581,1,0,0,0,0,583,1,0,0,
	0,0,585,1,0,0,0,0,587,1,0,0,0,0,589,1,0,0,0,0,591,1,0,0,0,0,593,1,0,0,0,
	0,595,1,0,0,0,0,597,1,0,0,0,0,599,1,0,0,0,0,601,1,0,0,0,0,603,1,0,0,0,0,
	605,1,0,0,0,0,607,1,0,0,0,0,609,1,0,0,0,0,611,1,0,0,0,0,613,1,0,0,0,0,615,
	1,0,0,0,0,617,1,0,0,0,0,619,1,0,0,0,0,621,1,0,0,0,0,623,1,0,0,0,0,625,1,
	0,0,0,0,627,1,0,0,0,0,629,1,0,0,0,0,631,1,0,0,0,0,633,1,0,0,0,0,635,1,0,
	0,0,0,637,1,0,0,0,0,639,1,0,0,0,0,641,1,0,0,0,0,643,1,0,0,0,0,645,1,0,0,
	0,0,647,1,0,0,0,0,649,1,0,0,0,0,651,1,0,0,0,0,653,1,0,0,0,0,655,1,0,0,0,
	0,657,1,0,0,0,0,659,1,0,0,0,0,661,1,0,0,0,0,663,1,0,0,0,0,665,1,0,0,0,0,
	667,1,0,0,0,0,669,1,0,0,0,0,671,1,0,0,0,0,673,1,0,0,0,0,675,1,0,0,0,0,677,
	1,0,0,0,0,679,1,0,0,0,0,681,1,0,0,0,0,683,1,0,0,0,0,685,1,0,0,0,0,687,1,
	0,0,0,0,689,1,0,0,0,0,691,1,0,0,0,0,693,1,0,0,0,0,695,1,0,0,0,0,697,1,0,
	0,0,0,699,1,0,0,0,0,701,1,0,0,0,0,703,1,0,0,0,0,705,1,0,0,0,0,707,1,0,0,
	0,0,709,1,0,0,0,0,711,1,0,0,0,0,713,1,0,0,0,0,715,1,0,0,0,0,717,1,0,0,0,
	0,719,1,0,0,0,0,721,1,0,0,0,0,723,1,0,0,0,0,725,1,0,0,0,0,727,1,0,0,0,0,
	729,1,0,0,0,0,731,1,0,0,0,0,733,1,0,0,0,0,735,1,0,0,0,0,737,1,0,0,0,0,739,
	1,0,0,0,0,741,1,0,0,0,0,743,1,0,0,0,0,745,1,0,0,0,0,747,1,0,0,0,0,749,1,
	0,0,0,0,751,1,0,0,0,0,753,1,0,0,0,0,755,1,0,0,0,0,757,1,0,0,0,0,759,1,0,
	0,0,0,761,1,0,0,0,0,763,1,0,0,0,0,765,1,0,0,0,0,767,1,0,0,0,0,769,1,0,0,
	0,0,771,1,0,0,0,0,773,1,0,0,0,0,775,1,0,0,0,0,777,1,0,0,0,0,779,1,0,0,0,
	0,781,1,0,0,0,0,783,1,0,0,0,0,785,1,0,0,0,0,787,1,0,0,0,0,789,1,0,0,0,0,
	791,1,0,0,0,0,793,1,0,0,0,0,795,1,0,0,0,0,797,1,0,0,0,0,799,1,0,0,0,0,801,
	1,0,0,0,0,803,1,0,0,0,0,805,1,0,0,0,0,807,1,0,0,0,0,809,1,0,0,0,0,811,1,
	0,0,0,0,813,1,0,0,0,0,815,1,0,0,0,0,817,1,0,0,0,0,819,1,0,0,0,0,821,1,0,
	0,0,0,823,1,0,0,0,0,825,1,0,0,0,0,827,1,0,0,0,0,829,1,0,0,0,0,831,1,0,0,
	0,0,833,1,0,0,0,0,835,1,0,0,0,0,837,1,0,0,0,0,839,1,0,0,0,0,841,1,0,0,0,
	0,843,1,0,0,0,0,845,1,0,0,0,0,847,1,0,0,0,0,849,1,0,0,0,0,851,1,0,0,0,0,
	853,1,0,0,0,0,855,1,0,0,0,0,857,1,0,0,0,0,859,1,0,0,0,0,861,1,0,0,0,0,863,
	1,0,0,0,0,865,1,0,0,0,0,867,1,0,0,0,0,869,1,0,0,0,0,871,1,0,0,0,0,873,1,
	0,0,0,0,875,1,0,0,0,0,877,1,0,0,0,0,879,1,0,0,0,0,881,1,0,0,0,0,883,1,0,
	0,0,0,885,1,0,0,0,0,887,1,0,0,0,0,889,1,0,0,0,0,891,1,0,0,0,0,893,1,0,0,
	0,0,895,1,0,0,0,0,897,1,0,0,0,0,899,1,0,0,0,0,901,1,0,0,0,0,903,1,0,0,0,
	0,905,1,0,0,0,0,907,1,0,0,0,0,909,1,0,0,0,0,911,1,0,0,0,0,913,1,0,0,0,0,
	915,1,0,0,0,0,917,1,0,0,0,0,919,1,0,0,0,0,921,1,0,0,0,0,923,1,0,0,0,0,925,
	1,0,0,0,0,927,1,0,0,0,0,929,1,0,0,0,0,931,1,0,0,0,0,933,1,0,0,0,0,935,1,
	0,0,0,0,937,1,0,0,0,0,939,1,0,0,0,0,941,1,0,0,0,0,943,1,0,0,0,0,945,1,0,
	0,0,0,947,1,0,0,0,0,949,1,0,0,0,0,951,1,0,0,0,0,953,1,0,0,0,0,955,1,0,0,
	0,0,957,1,0,0,0,0,959,1,0,0,0,0,961,1,0,0,0,0,963,1,0,0,0,0,965,1,0,0,0,
	0,967,1,0,0,0,0,969,1,0,0,0,0,971,1,0,0,0,0,973,1,0,0,0,0,975,1,0,0,0,0,
	977,1,0,0,0,0,979,1,0,0,0,0,981,1,0,0,0,0,983,1,0,0,0,0,985,1,0,0,0,0,987,
	1,0,0,0,0,989,1,0,0,0,0,991,1,0,0,0,0,993,1,0,0,0,0,995,1,0,0,0,0,997,1,
	0,0,0,0,999,1,0,0,0,0,1001,1,0,0,0,0,1003,1,0,0,0,0,1005,1,0,0,0,0,1007,
	1,0,0,0,0,1009,1,0,0,0,0,1011,1,0,0,0,0,1013,1,0,0,0,0,1015,1,0,0,0,0,1017,
	1,0,0,0,0,1019,1,0,0,0,0,1021,1,0,0,0,0,1023,1,0,0,0,0,1025,1,0,0,0,0,1027,
	1,0,0,0,0,1029,1,0,0,0,0,1031,1,0,0,0,0,1033,1,0,0,0,0,1035,1,0,0,0,0,1037,
	1,0,0,0,0,1039,1,0,0,0,0,1041,1,0,0,0,0,1043,1,0,0,0,0,1045,1,0,0,0,0,1047,
	1,0,0,0,0,1049,1,0,0,0,0,1051,1,0,0,0,0,1053,1,0,0,0,0,1055,1,0,0,0,0,1057,
	1,0,0,0,0,1059,1,0,0,0,0,1061,1,0,0,0,0,1063,1,0,0,0,0,1065,1,0,0,0,0,1067,
	1,0,0,0,0,1069,1,0,0,0,0,1071,1,0,0,0,0,1073,1,0,0,0,0,1075,1,0,0,0,0,1077,
	1,0,0,0,0,1079,1,0,0,0,0,1081,1,0,0,0,0,1083,1,0,0,0,0,1085,1,0,0,0,0,1087,
	1,0,0,0,0,1089,1,0,0,0,0,1091,1,0,0,0,0,1093,1,0,0,0,0,1095,1,0,0,0,0,1097,
	1,0,0,0,0,1099,1,0,0,0,0,1101,1,0,0,0,0,1103,1,0,0,0,0,1105,1,0,0,0,0,1107,
	1,0,0,0,0,1109,1,0,0,0,0,1111,1,0,0,0,0,1113,1,0,0,0,0,1115,1,0,0,0,0,1117,
	1,0,0,0,0,1119,1,0,0,0,0,1121,1,0,0,0,0,1123,1,0,0,0,0,1125,1,0,0,0,0,1127,
	1,0,0,0,0,1129,1,0,0,0,0,1131,1,0,0,0,0,1133,1,0,0,0,0,1135,1,0,0,0,0,1137,
	1,0,0,0,0,1139,1,0,0,0,0,1141,1,0,0,0,0,1143,1,0,0,0,0,1145,1,0,0,0,0,1147,
	1,0,0,0,0,1149,1,0,0,0,0,1151,1,0,0,0,0,1153,1,0,0,0,0,1155,1,0,0,0,0,1157,
	1,0,0,0,0,1159,1,0,0,0,0,1161,1,0,0,0,0,1163,1,0,0,0,0,1165,1,0,0,0,0,1167,
	1,0,0,0,0,1169,1,0,0,0,0,1171,1,0,0,0,0,1173,1,0,0,0,0,1175,1,0,0,0,0,1177,
	1,0,0,0,0,1179,1,0,0,0,0,1181,1,0,0,0,0,1183,1,0,0,0,0,1185,1,0,0,0,0,1187,
	1,0,0,0,0,1189,1,0,0,0,0,1191,1,0,0,0,0,1193,1,0,0,0,0,1195,1,0,0,0,0,1197,
	1,0,0,0,0,1199,1,0,0,0,0,1201,1,0,0,0,0,1203,1,0,0,0,0,1205,1,0,0,0,0,1207,
	1,0,0,0,0,1209,1,0,0,0,0,1211,1,0,0,0,0,1213,1,0,0,0,0,1215,1,0,0,0,0,1217,
	1,0,0,0,0,1219,1,0,0,0,0,1221,1,0,0,0,0,1223,1,0,0,0,0,1225,1,0,0,0,0,1227,
	1,0,0,0,0,1229,1,0,0,0,0,1231,1,0,0,0,0,1233,1,0,0,0,0,1235,1,0,0,0,0,1237,
	1,0,0,0,0,1239,1,0,0,0,0,1241,1,0,0,0,0,1243,1,0,0,0,0,1245,1,0,0,0,0,1247,
	1,0,0,0,0,1249,1,0,0,0,0,1251,1,0,0,0,0,1253,1,0,0,0,0,1255,1,0,0,0,0,1257,
	1,0,0,0,0,1259,1,0,0,0,0,1261,1,0,0,0,0,1263,1,0,0,0,0,1265,1,0,0,0,0,1267,
	1,0,0,0,0,1269,1,0,0,0,0,1271,1,0,0,0,0,1273,1,0,0,0,0,1275,1,0,0,0,0,1277,
	1,0,0,0,0,1279,1,0,0,0,0,1281,1,0,0,0,0,1283,1,0,0,0,0,1285,1,0,0,0,0,1287,
	1,0,0,0,0,1289,1,0,0,0,0,1291,1,0,0,0,0,1293,1,0,0,0,0,1295,1,0,0,0,0,1297,
	1,0,0,0,0,1299,1,0,0,0,0,1301,1,0,0,0,0,1303,1,0,0,0,0,1305,1,0,0,0,0,1307,
	1,0,0,0,0,1309,1,0,0,0,0,1311,1,0,0,0,0,1313,1,0,0,0,0,1315,1,0,0,0,0,1317,
	1,0,0,0,0,1319,1,0,0,0,0,1321,1,0,0,0,0,1323,1,0,0,0,0,1325,1,0,0,0,0,1327,
	1,0,0,0,0,1329,1,0,0,0,0,1331,1,0,0,0,0,1333,1,0,0,0,0,1335,1,0,0,0,0,1337,
	1,0,0,0,0,1339,1,0,0,0,0,1341,1,0,0,0,0,1343,1,0,0,0,0,1345,1,0,0,0,0,1347,
	1,0,0,0,0,1349,1,0,0,0,0,1351,1,0,0,0,0,1353,1,0,0,0,0,1355,1,0,0,0,0,1357,
	1,0,0,0,0,1359,1,0,0,0,0,1361,1,0,0,0,0,1363,1,0,0,0,0,1365,1,0,0,0,0,1367,
	1,0,0,0,0,1369,1,0,0,0,0,1371,1,0,0,0,0,1373,1,0,0,0,0,1375,1,0,0,0,0,1377,
	1,0,0,0,0,1379,1,0,0,0,0,1381,1,0,0,0,0,1383,1,0,0,0,0,1385,1,0,0,0,0,1387,
	1,0,0,0,0,1389,1,0,0,0,0,1391,1,0,0,0,0,1393,1,0,0,0,0,1395,1,0,0,0,0,1397,
	1,0,0,0,0,1399,1,0,0,0,0,1401,1,0,0,0,0,1403,1,0,0,0,0,1405,1,0,0,0,0,1407,
	1,0,0,0,0,1409,1,0,0,0,0,1411,1,0,0,0,0,1413,1,0,0,0,0,1415,1,0,0,0,0,1417,
	1,0,0,0,0,1419,1,0,0,0,0,1421,1,0,0,0,0,1423,1,0,0,0,0,1425,1,0,0,0,0,1427,
	1,0,0,0,0,1429,1,0,0,0,0,1431,1,0,0,0,0,1433,1,0,0,0,0,1435,1,0,0,0,0,1437,
	1,0,0,0,0,1439,1,0,0,0,0,1441,1,0,0,0,0,1443,1,0,0,0,0,1445,1,0,0,0,0,1447,
	1,0,0,0,0,1449,1,0,0,0,0,1451,1,0,0,0,0,1453,1,0,0,0,0,1455,1,0,0,0,0,1457,
	1,0,0,0,0,1459,1,0,0,0,0,1461,1,0,0,0,0,1463,1,0,0,0,0,1465,1,0,0,0,0,1467,
	1,0,0,0,0,1469,1,0,0,0,0,1471,1,0,0,0,0,1473,1,0,0,0,0,1475,1,0,0,0,0,1477,
	1,0,0,0,0,1479,1,0,0,0,0,1481,1,0,0,0,0,1483,1,0,0,0,0,1485,1,0,0,0,0,1487,
	1,0,0,0,0,1489,1,0,0,0,0,1491,1,0,0,0,0,1493,1,0,0,0,0,1495,1,0,0,0,0,1497,
	1,0,0,0,0,1499,1,0,0,0,0,1501,1,0,0,0,0,1503,1,0,0,0,0,1505,1,0,0,0,0,1507,
	1,0,0,0,0,1509,1,0,0,0,0,1511,1,0,0,0,0,1513,1,0,0,0,0,1515,1,0,0,0,0,1517,
	1,0,0,0,0,1519,1,0,0,0,0,1521,1,0,0,0,0,1523,1,0,0,0,0,1525,1,0,0,0,0,1527,
	1,0,0,0,0,1529,1,0,0,0,0,1531,1,0,0,0,0,1533,1,0,0,0,0,1535,1,0,0,0,0,1537,
	1,0,0,0,0,1539,1,0,0,0,0,1541,1,0,0,0,0,1543,1,0,0,0,0,1545,1,0,0,0,0,1547,
	1,0,0,0,0,1549,1,0,0,0,0,1551,1,0,0,0,0,1553,1,0,0,0,0,1555,1,0,0,0,0,1557,
	1,0,0,0,0,1559,1,0,0,0,0,1561,1,0,0,0,0,1563,1,0,0,0,0,1565,1,0,0,0,0,1567,
	1,0,0,0,0,1569,1,0,0,0,0,1571,1,0,0,0,0,1573,1,0,0,0,0,1575,1,0,0,0,0,1577,
	1,0,0,0,0,1579,1,0,0,0,0,1581,1,0,0,0,0,1583,1,0,0,0,0,1585,1,0,0,0,0,1587,
	1,0,0,0,0,1589,1,0,0,0,0,1591,1,0,0,0,0,1593,1,0,0,0,0,1595,1,0,0,0,0,1597,
	1,0,0,0,0,1599,1,0,0,0,0,1601,1,0,0,0,0,1603,1,0,0,0,0,1605,1,0,0,0,0,1607,
	1,0,0,0,0,1609,1,0,0,0,0,1611,1,0,0,0,0,1613,1,0,0,0,0,1615,1,0,0,0,0,1617,
	1,0,0,0,0,1619,1,0,0,0,0,1621,1,0,0,0,0,1623,1,0,0,0,0,1625,1,0,0,0,0,1627,
	1,0,0,0,0,1629,1,0,0,0,0,1631,1,0,0,0,0,1633,1,0,0,0,0,1635,1,0,0,0,0,1637,
	1,0,0,0,0,1639,1,0,0,0,0,1641,1,0,0,0,0,1643,1,0,0,0,0,1645,1,0,0,0,0,1647,
	1,0,0,0,0,1649,1,0,0,0,0,1651,1,0,0,0,0,1653,1,0,0,0,0,1655,1,0,0,0,0,1657,
	1,0,0,0,0,1659,1,0,0,0,0,1661,1,0,0,0,0,1663,1,0,0,0,0,1665,1,0,0,0,0,1667,
	1,0,0,0,0,1669,1,0,0,0,0,1671,1,0,0,0,0,1673,1,0,0,0,0,1675,1,0,0,0,0,1677,
	1,0,0,0,0,1679,1,0,0,0,0,1681,1,0,0,0,0,1683,1,0,0,0,0,1685,1,0,0,0,0,1687,
	1,0,0,0,0,1689,1,0,0,0,0,1691,1,0,0,0,0,1693,1,0,0,0,0,1695,1,0,0,0,0,1697,
	1,0,0,0,0,1699,1,0,0,0,0,1701,1,0,0,0,0,1703,1,0,0,0,0,1705,1,0,0,0,0,1707,
	1,0,0,0,0,1709,1,0,0,0,0,1711,1,0,0,0,0,1713,1,0,0,0,0,1715,1,0,0,0,0,1717,
	1,0,0,0,0,1719,1,0,0,0,0,1721,1,0,0,0,0,1723,1,0,0,0,0,1725,1,0,0,0,0,1727,
	1,0,0,0,0,1729,1,0,0,0,0,1731,1,0,0,0,0,1733,1,0,0,0,0,1735,1,0,0,0,0,1737,
	1,0,0,0,0,1739,1,0,0,0,0,1741,1,0,0,0,0,1743,1,0,0,0,0,1745,1,0,0,0,0,1747,
	1,0,0,0,0,1749,1,0,0,0,0,1751,1,0,0,0,0,1753,1,0,0,0,0,1755,1,0,0,0,0,1757,
	1,0,0,0,0,1759,1,0,0,0,0,1761,1,0,0,0,0,1763,1,0,0,0,0,1765,1,0,0,0,0,1767,
	1,0,0,0,0,1769,1,0,0,0,0,1771,1,0,0,0,0,1773,1,0,0,0,0,1775,1,0,0,0,0,1777,
	1,0,0,0,0,1779,1,0,0,0,0,1781,1,0,0,0,0,1783,1,0,0,0,0,1785,1,0,0,0,0,1787,
	1,0,0,0,0,1789,1,0,0,0,0,1791,1,0,0,0,0,1793,1,0,0,0,0,1795,1,0,0,0,0,1797,
	1,0,0,0,0,1799,1,0,0,0,0,1801,1,0,0,0,0,1803,1,0,0,0,0,1805,1,0,0,0,0,1807,
	1,0,0,0,0,1809,1,0,0,0,0,1811,1,0,0,0,0,1813,1,0,0,0,0,1815,1,0,0,0,0,1817,
	1,0,0,0,0,1819,1,0,0,0,0,1821,1,0,0,0,0,1823,1,0,0,0,0,1825,1,0,0,0,0,1827,
	1,0,0,0,0,1829,1,0,0,0,0,1831,1,0,0,0,0,1833,1,0,0,0,0,1835,1,0,0,0,0,1837,
	1,0,0,0,0,1839,1,0,0,0,0,1841,1,0,0,0,0,1843,1,0,0,0,0,1845,1,0,0,0,0,1847,
	1,0,0,0,0,1849,1,0,0,0,0,1851,1,0,0,0,0,1853,1,0,0,0,0,1855,1,0,0,0,0,1857,
	1,0,0,0,0,1859,1,0,0,0,0,1861,1,0,0,0,0,1863,1,0,0,0,0,1865,1,0,0,0,0,1867,
	1,0,0,0,0,1869,1,0,0,0,0,1871,1,0,0,0,0,1873,1,0,0,0,0,1875,1,0,0,0,0,1877,
	1,0,0,0,0,1879,1,0,0,0,0,1881,1,0,0,0,0,1883,1,0,0,0,0,1885,1,0,0,0,0,1887,
	1,0,0,0,0,1889,1,0,0,0,0,1891,1,0,0,0,0,1893,1,0,0,0,0,1895,1,0,0,0,0,1897,
	1,0,0,0,0,1899,1,0,0,0,0,1901,1,0,0,0,0,1903,1,0,0,0,0,1905,1,0,0,0,0,1907,
	1,0,0,0,0,1909,1,0,0,0,0,1911,1,0,0,0,0,1913,1,0,0,0,0,1915,1,0,0,0,0,1917,
	1,0,0,0,0,1919,1,0,0,0,0,1921,1,0,0,0,0,1923,1,0,0,0,0,1925,1,0,0,0,0,1927,
	1,0,0,0,0,1929,1,0,0,0,0,1931,1,0,0,0,0,1933,1,0,0,0,0,1935,1,0,0,0,0,1937,
	1,0,0,0,0,1939,1,0,0,0,0,1941,1,0,0,0,0,1943,1,0,0,0,0,1945,1,0,0,0,0,1947,
	1,0,0,0,0,1949,1,0,0,0,0,1951,1,0,0,0,0,1953,1,0,0,0,0,1955,1,0,0,0,0,1957,
	1,0,0,0,0,1959,1,0,0,0,0,1961,1,0,0,0,0,1963,1,0,0,0,0,1965,1,0,0,0,0,1967,
	1,0,0,0,0,1969,1,0,0,0,0,1971,1,0,0,0,0,1973,1,0,0,0,0,1975,1,0,0,0,0,1977,
	1,0,0,0,0,1979,1,0,0,0,0,1981,1,0,0,0,0,1983,1,0,0,0,0,1985,1,0,0,0,0,1987,
	1,0,0,0,0,1989,1,0,0,0,0,1991,1,0,0,0,0,1993,1,0,0,0,0,1995,1,0,0,0,0,1997,
	1,0,0,0,0,1999,1,0,0,0,0,2001,1,0,0,0,0,2003,1,0,0,0,0,2005,1,0,0,0,0,2007,
	1,0,0,0,0,2009,1,0,0,0,0,2011,1,0,0,0,0,2013,1,0,0,0,0,2015,1,0,0,0,0,2017,
	1,0,0,0,0,2019,1,0,0,0,0,2021,1,0,0,0,0,2023,1,0,0,0,0,2025,1,0,0,0,0,2027,
	1,0,0,0,0,2029,1,0,0,0,0,2031,1,0,0,0,0,2033,1,0,0,0,0,2035,1,0,0,0,0,2037,
	1,0,0,0,0,2039,1,0,0,0,0,2041,1,0,0,0,0,2043,1,0,0,0,0,2045,1,0,0,0,0,2047,
	1,0,0,0,0,2049,1,0,0,0,0,2051,1,0,0,0,0,2053,1,0,0,0,0,2055,1,0,0,0,0,2057,
	1,0,0,0,0,2059,1,0,0,0,0,2061,1,0,0,0,0,2063,1,0,0,0,0,2065,1,0,0,0,0,2067,
	1,0,0,0,0,2069,1,0,0,0,0,2071,1,0,0,0,0,2073,1,0,0,0,0,2075,1,0,0,0,0,2077,
	1,0,0,0,0,2079,1,0,0,0,0,2081,1,0,0,0,0,2083,1,0,0,0,0,2085,1,0,0,0,0,2087,
	1,0,0,0,0,2089,1,0,0,0,0,2091,1,0,0,0,0,2093,1,0,0,0,0,2095,1,0,0,0,0,2097,
	1,0,0,0,0,2099,1,0,0,0,0,2101,1,0,0,0,0,2103,1,0,0,0,0,2105,1,0,0,0,0,2107,
	1,0,0,0,0,2109,1,0,0,0,0,2111,1,0,0,0,0,2113,1,0,0,0,0,2115,1,0,0,0,0,2117,
	1,0,0,0,0,2119,1,0,0,0,0,2121,1,0,0,0,0,2123,1,0,0,0,0,2125,1,0,0,0,0,2127,
	1,0,0,0,0,2129,1,0,0,0,0,2131,1,0,0,0,0,2133,1,0,0,0,0,2135,1,0,0,0,0,2137,
	1,0,0,0,0,2139,1,0,0,0,0,2141,1,0,0,0,0,2143,1,0,0,0,0,2145,1,0,0,0,0,2147,
	1,0,0,0,0,2149,1,0,0,0,0,2151,1,0,0,0,0,2153,1,0,0,0,0,2155,1,0,0,0,0,2157,
	1,0,0,0,0,2159,1,0,0,0,0,2161,1,0,0,0,0,2163,1,0,0,0,0,2165,1,0,0,0,0,2167,
	1,0,0,0,0,2169,1,0,0,0,0,2171,1,0,0,0,0,2173,1,0,0,0,0,2175,1,0,0,0,0,2177,
	1,0,0,0,0,2179,1,0,0,0,0,2181,1,0,0,0,0,2183,1,0,0,0,0,2185,1,0,0,0,0,2187,
	1,0,0,0,0,2189,1,0,0,0,0,2191,1,0,0,0,0,2193,1,0,0,0,0,2195,1,0,0,0,0,2197,
	1,0,0,0,0,2199,1,0,0,0,0,2201,1,0,0,0,0,2203,1,0,0,0,0,2205,1,0,0,0,0,2207,
	1,0,0,0,0,2209,1,0,0,0,0,2211,1,0,0,0,0,2213,1,0,0,0,0,2215,1,0,0,0,0,2217,
	1,0,0,0,0,2219,1,0,0,0,0,2221,1,0,0,0,0,2223,1,0,0,0,0,2225,1,0,0,0,0,2227,
	1,0,0,0,0,2229,1,0,0,0,0,2231,1,0,0,0,0,2233,1,0,0,0,0,2235,1,0,0,0,0,2237,
	1,0,0,0,0,2239,1,0,0,0,0,2241,1,0,0,0,0,2243,1,0,0,0,0,2245,1,0,0,0,0,2247,
	1,0,0,0,0,2249,1,0,0,0,0,2251,1,0,0,0,0,2253,1,0,0,0,0,2255,1,0,0,0,0,2257,
	1,0,0,0,0,2259,1,0,0,0,0,2261,1,0,0,0,0,2263,1,0,0,0,0,2265,1,0,0,0,0,2267,
	1,0,0,0,0,2269,1,0,0,0,0,2271,1,0,0,0,0,2273,1,0,0,0,0,2275,1,0,0,0,0,2277,
	1,0,0,0,0,2279,1,0,0,0,0,2281,1,0,0,0,0,2283,1,0,0,0,0,2285,1,0,0,0,0,2287,
	1,0,0,0,0,2289,1,0,0,0,0,2291,1,0,0,0,0,2293,1,0,0,0,0,2295,1,0,0,0,0,2297,
	1,0,0,0,0,2299,1,0,0,0,0,2301,1,0,0,0,0,2303,1,0,0,0,0,2305,1,0,0,0,0,2307,
	1,0,0,0,0,2309,1,0,0,0,0,2311,1,0,0,0,0,2313,1,0,0,0,0,2315,1,0,0,0,0,2317,
	1,0,0,0,0,2319,1,0,0,0,0,2321,1,0,0,0,0,2323,1,0,0,0,0,2325,1,0,0,0,0,2327,
	1,0,0,0,0,2329,1,0,0,0,0,2331,1,0,0,0,0,2333,1,0,0,0,0,2335,1,0,0,0,0,2337,
	1,0,0,0,0,2339,1,0,0,0,0,2341,1,0,0,0,0,2343,1,0,0,0,0,2345,1,0,0,0,0,2347,
	1,0,0,0,0,2349,1,0,0,0,0,2351,1,0,0,0,0,2353,1,0,0,0,0,2355,1,0,0,0,0,2357,
	1,0,0,0,0,2359,1,0,0,0,0,2361,1,0,0,0,0,2363,1,0,0,0,0,2365,1,0,0,0,0,2367,
	1,0,0,0,0,2369,1,0,0,0,0,2371,1,0,0,0,0,2373,1,0,0,0,0,2375,1,0,0,0,0,2377,
	1,0,0,0,0,2379,1,0,0,0,0,2381,1,0,0,0,0,2383,1,0,0,0,0,2385,1,0,0,0,0,2387,
	1,0,0,0,0,2389,1,0,0,0,0,2391,1,0,0,0,0,2393,1,0,0,0,0,2395,1,0,0,0,0,2397,
	1,0,0,0,0,2399,1,0,0,0,0,2401,1,0,0,0,0,2403,1,0,0,0,0,2405,1,0,0,0,0,2407,
	1,0,0,0,0,2409,1,0,0,0,0,2411,1,0,0,0,0,2413,1,0,0,0,0,2415,1,0,0,0,0,2417,
	1,0,0,0,0,2419,1,0,0,0,0,2421,1,0,0,0,0,2423,1,0,0,0,0,2425,1,0,0,0,0,2427,
	1,0,0,0,0,2429,1,0,0,0,0,2431,1,0,0,0,0,2433,1,0,0,0,0,2435,1,0,0,0,0,2437,
	1,0,0,0,0,2439,1,0,0,0,0,2441,1,0,0,0,0,2443,1,0,0,0,0,2445,1,0,0,0,0,2447,
	1,0,0,0,0,2449,1,0,0,0,0,2451,1,0,0,0,0,2453,1,0,0,0,0,2455,1,0,0,0,0,2457,
	1,0,0,0,0,2459,1,0,0,0,0,2461,1,0,0,0,0,2463,1,0,0,0,0,2465,1,0,0,0,0,2467,
	1,0,0,0,0,2469,1,0,0,0,0,2471,1,0,0,0,0,2473,1,0,0,0,0,2475,1,0,0,0,0,2477,
	1,0,0,0,0,2479,1,0,0,0,0,2481,1,0,0,0,0,2483,1,0,0,0,0,2485,1,0,0,0,0,2487,
	1,0,0,0,0,2489,1,0,0,0,0,2491,1,0,0,0,0,2493,1,0,0,0,0,2495,1,0,0,0,0,2497,
	1,0,0,0,0,2499,1,0,0,0,0,2501,1,0,0,0,0,2503,1,0,0,0,0,2505,1,0,0,0,0,2507,
	1,0,0,0,0,2509,1,0,0,0,0,2511,1,0,0,0,0,2513,1,0,0,0,0,2515,1,0,0,0,0,2517,
	1,0,0,0,0,2519,1,0,0,0,0,2521,1,0,0,0,0,2523,1,0,0,0,0,2525,1,0,0,0,0,2527,
	1,0,0,0,0,2529,1,0,0,0,0,2531,1,0,0,0,0,2533,1,0,0,0,0,2535,1,0,0,0,0,2537,
	1,0,0,0,0,2539,1,0,0,0,0,2541,1,0,0,0,0,2543,1,0,0,0,0,2545,1,0,0,0,0,2547,
	1,0,0,0,0,2549,1,0,0,0,0,2551,1,0,0,0,0,2553,1,0,0,0,0,2555,1,0,0,0,0,2557,
	1,0,0,0,0,2559,1,0,0,0,0,2561,1,0,0,0,0,2563,1,0,0,0,0,2565,1,0,0,0,0,2567,
	1,0,0,0,0,2569,1,0,0,0,0,2571,1,0,0,0,0,2573,1,0,0,0,0,2575,1,0,0,0,0,2577,
	1,0,0,0,0,2579,1,0,0,0,0,2581,1,0,0,0,0,2583,1,0,0,0,0,2585,1,0,0,0,0,2587,
	1,0,0,0,0,2589,1,0,0,0,0,2591,1,0,0,0,0,2593,1,0,0,0,0,2595,1,0,0,0,0,2597,
	1,0,0,0,0,2599,1,0,0,0,0,2601,1,0,0,0,0,2603,1,0,0,0,0,2605,1,0,0,0,0,2607,
	1,0,0,0,0,2609,1,0,0,0,0,2611,1,0,0,0,0,2613,1,0,0,0,0,2615,1,0,0,0,0,2617,
	1,0,0,0,0,2619,1,0,0,0,0,2621,1,0,0,0,0,2623,1,0,0,0,0,2625,1,0,0,0,0,2627,
	1,0,0,0,0,2629,1,0,0,0,0,2631,1,0,0,0,0,2633,1,0,0,0,0,2635,1,0,0,0,0,2637,
	1,0,0,0,0,2639,1,0,0,0,0,2641,1,0,0,0,0,2643,1,0,0,0,0,2645,1,0,0,0,0,2647,
	1,0,0,0,0,2649,1,0,0,0,0,2651,1,0,0,0,0,2653,1,0,0,0,0,2655,1,0,0,0,0,2657,
	1,0,0,0,0,2659,1,0,0,0,0,2661,1,0,0,0,0,2663,1,0,0,0,0,2665,1,0,0,0,0,2667,
	1,0,0,0,0,2669,1,0,0,0,0,2671,1,0,0,0,0,2673,1,0,0,0,0,2675,1,0,0,0,0,2677,
	1,0,0,0,0,2679,1,0,0,0,0,2681,1,0,0,0,0,2683,1,0,0,0,0,2685,1,0,0,0,0,2687,
	1,0,0,0,0,2689,1,0,0,0,0,2691,1,0,0,0,0,2693,1,0,0,0,0,2695,1,0,0,0,0,2697,
	1,0,0,0,0,2699,1,0,0,0,0,2701,1,0,0,0,0,2703,1,0,0,0,0,2705,1,0,0,0,0,2707,
	1,0,0,0,0,2709,1,0,0,0,0,2711,1,0,0,0,0,2713,1,0,0,0,0,2715,1,0,0,0,0,2717,
	1,0,0,0,0,2719,1,0,0,0,0,2721,1,0,0,0,0,2723,1,0,0,0,0,2725,1,0,0,0,0,2727,
	1,0,0,0,0,2729,1,0,0,0,0,2731,1,0,0,0,0,2733,1,0,0,0,0,2735,1,0,0,0,0,2737,
	1,0,0,0,0,2739,1,0,0,0,0,2741,1,0,0,0,0,2743,1,0,0,0,0,2745,1,0,0,0,0,2747,
	1,0,0,0,0,2749,1,0,0,0,0,2751,1,0,0,0,0,2753,1,0,0,0,0,2755,1,0,0,0,0,2757,
	1,0,0,0,0,2759,1,0,0,0,0,2761,1,0,0,0,0,2763,1,0,0,0,0,2765,1,0,0,0,0,2767,
	1,0,0,0,0,2769,1,0,0,0,0,2771,1,0,0,0,0,2773,1,0,0,0,0,2775,1,0,0,0,0,2777,
	1,0,0,0,0,2779,1,0,0,0,0,2781,1,0,0,0,0,2783,1,0,0,0,0,2785,1,0,0,0,0,2787,
	1,0,0,0,0,2789,1,0,0,0,0,2791,1,0,0,0,0,2793,1,0,0,0,0,2795,1,0,0,0,0,2797,
	1,0,0,0,0,2799,1,0,0,0,0,2801,1,0,0,0,0,2803,1,0,0,0,0,2805,1,0,0,0,0,2807,
	1,0,0,0,0,2809,1,0,0,0,0,2811,1,0,0,0,0,2813,1,0,0,0,0,2815,1,0,0,0,0,2817,
	1,0,0,0,0,2819,1,0,0,0,0,2821,1,0,0,0,0,2823,1,0,0,0,0,2825,1,0,0,0,0,2827,
	1,0,0,0,0,2829,1,0,0,0,0,2831,1,0,0,0,0,2833,1,0,0,0,0,2835,1,0,0,0,0,2837,
	1,0,0,0,0,2839,1,0,0,0,0,2841,1,0,0,0,0,2843,1,0,0,0,0,2845,1,0,0,0,0,2847,
	1,0,0,0,0,2849,1,0,0,0,0,2851,1,0,0,0,0,2853,1,0,0,0,0,2855,1,0,0,0,0,2857,
	1,0,0,0,0,2859,1,0,0,0,0,2861,1,0,0,0,0,2863,1,0,0,0,0,2865,1,0,0,0,0,2867,
	1,0,0,0,0,2869,1,0,0,0,0,2871,1,0,0,0,0,2873,1,0,0,0,0,2875,1,0,0,0,0,2877,
	1,0,0,0,0,2879,1,0,0,0,0,2881,1,0,0,0,0,2883,1,0,0,0,0,2885,1,0,0,0,0,2887,
	1,0,0,0,0,2889,1,0,0,0,0,2891,1,0,0,0,0,2893,1,0,0,0,0,2895,1,0,0,0,0,2897,
	1,0,0,0,0,2899,1,0,0,0,0,2901,1,0,0,0,0,2903,1,0,0,0,0,2905,1,0,0,0,0,2907,
	1,0,0,0,0,2909,1,0,0,0,0,2911,1,0,0,0,0,2913,1,0,0,0,0,2915,1,0,0,0,0,2917,
	1,0,0,0,0,2919,1,0,0,0,0,2921,1,0,0,0,0,2923,1,0,0,0,0,2925,1,0,0,0,0,2927,
	1,0,0,0,0,2929,1,0,0,0,0,2931,1,0,0,0,0,2933,1,0,0,0,0,2935,1,0,0,0,0,2937,
	1,0,0,0,0,2939,1,0,0,0,0,2941,1,0,0,0,0,2943,1,0,0,0,0,2945,1,0,0,0,0,2947,
	1,0,0,0,0,2949,1,0,0,0,0,2951,1,0,0,0,0,2953,1,0,0,0,0,2955,1,0,0,0,0,2957,
	1,0,0,0,0,2959,1,0,0,0,0,2961,1,0,0,0,0,2963,1,0,0,0,0,2965,1,0,0,0,0,2967,
	1,0,0,0,0,2969,1,0,0,0,0,2971,1,0,0,0,0,2973,1,0,0,0,0,2975,1,0,0,0,0,2977,
	1,0,0,0,0,2979,1,0,0,0,0,2981,1,0,0,0,0,2983,1,0,0,0,0,2985,1,0,0,0,0,2987,
	1,0,0,0,0,2989,1,0,0,0,0,2991,1,0,0,0,0,2993,1,0,0,0,0,2995,1,0,0,0,0,2997,
	1,0,0,0,0,2999,1,0,0,0,0,3001,1,0,0,0,0,3003,1,0,0,0,0,3005,1,0,0,0,0,3007,
	1,0,0,0,0,3009,1,0,0,0,0,3011,1,0,0,0,0,3013,1,0,0,0,0,3015,1,0,0,0,0,3017,
	1,0,0,0,0,3019,1,0,0,0,0,3021,1,0,0,0,0,3023,1,0,0,0,0,3025,1,0,0,0,0,3027,
	1,0,0,0,0,3029,1,0,0,0,0,3031,1,0,0,0,0,3033,1,0,0,0,0,3035,1,0,0,0,0,3037,
	1,0,0,0,0,3039,1,0,0,0,0,3041,1,0,0,0,0,3043,1,0,0,0,0,3045,1,0,0,0,0,3047,
	1,0,0,0,0,3049,1,0,0,0,0,3051,1,0,0,0,0,3053,1,0,0,0,0,3055,1,0,0,0,0,3057,
	1,0,0,0,0,3059,1,0,0,0,0,3061,1,0,0,0,0,3063,1,0,0,0,0,3065,1,0,0,0,0,3067,
	1,0,0,0,0,3069,1,0,0,0,0,3071,1,0,0,0,0,3073,1,0,0,0,0,3075,1,0,0,0,0,3077,
	1,0,0,0,0,3079,1,0,0,0,0,3081,1,0,0,0,0,3083,1,0,0,0,0,3085,1,0,0,0,0,3087,
	1,0,0,0,0,3089,1,0,0,0,0,3091,1,0,0,0,0,3093,1,0,0,0,0,3095,1,0,0,0,0,3097,
	1,0,0,0,0,3099,1,0,0,0,0,3101,1,0,0,0,0,3103,1,0,0,0,0,3105,1,0,0,0,0,3107,
	1,0,0,0,0,3109,1,0,0,0,0,3111,1,0,0,0,0,3113,1,0,0,0,0,3115,1,0,0,0,0,3117,
	1,0,0,0,0,3119,1,0,0,0,0,3121,1,0,0,0,0,3123,1,0,0,0,0,3125,1,0,0,0,0,3127,
	1,0,0,0,0,3129,1,0,0,0,0,3131,1,0,0,0,0,3133,1,0,0,0,0,3135,1,0,0,0,0,3137,
	1,0,0,0,0,3139,1,0,0,0,0,3141,1,0,0,0,0,3143,1,0,0,0,0,3145,1,0,0,0,0,3147,
	1,0,0,0,0,3149,1,0,0,0,0,3151,1,0,0,0,0,3153,1,0,0,0,0,3155,1,0,0,0,0,3157,
	1,0,0,0,0,3159,1,0,0,0,0,3161,1,0,0,0,0,3163,1,0,0,0,0,3165,1,0,0,0,0,3167,
	1,0,0,0,0,3169,1,0,0,0,0,3171,1,0,0,0,0,3173,1,0,0,0,0,3175,1,0,0,0,0,3177,
	1,0,0,0,0,3179,1,0,0,0,0,3181,1,0,0,0,0,3183,1,0,0,0,0,3185,1,0,0,0,0,3187,
	1,0,0,0,0,3189,1,0,0,0,0,3191,1,0,0,0,0,3193,1,0,0,0,0,3195,1,0,0,0,0,3197,
	1,0,0,0,0,3199,1,0,0,0,0,3201,1,0,0,0,0,3203,1,0,0,0,0,3205,1,0,0,0,0,3207,
	1,0,0,0,0,3209,1,0,0,0,0,3211,1,0,0,0,0,3213,1,0,0,0,0,3215,1,0,0,0,0,3217,
	1,0,0,0,0,3219,1,0,0,0,0,3221,1,0,0,0,0,3223,1,0,0,0,0,3225,1,0,0,0,0,3227,
	1,0,0,0,0,3229,1,0,0,0,0,3231,1,0,0,0,0,3233,1,0,0,0,0,3235,1,0,0,0,0,3237,
	1,0,0,0,0,3239,1,0,0,0,0,3241,1,0,0,0,0,3243,1,0,0,0,0,3245,1,0,0,0,0,3247,
	1,0,0,0,0,3249,1,0,0,0,0,3251,1,0,0,0,0,3253,1,0,0,0,0,3255,1,0,0,0,0,3257,
	1,0,0,0,0,3259,1,0,0,0,0,3261,1,0,0,0,0,3263,1,0,0,0,0,3265,1,0,0,0,0,3267,
	1,0,0,0,0,3269,1,0,0,0,0,3271,1,0,0,0,0,3273,1,0,0,0,0,3275,1,0,0,0,0,3277,
	1,0,0,0,0,3279,1,0,0,0,0,3281,1,0,0,0,0,3283,1,0,0,0,0,3285,1,0,0,0,0,3287,
	1,0,0,0,0,3289,1,0,0,0,0,3291,1,0,0,0,0,3293,1,0,0,0,0,3295,1,0,0,0,0,3297,
	1,0,0,0,0,3299,1,0,0,0,0,3301,1,0,0,0,0,3303,1,0,0,0,0,3305,1,0,0,0,0,3307,
	1,0,0,0,0,3309,1,0,0,0,0,3311,1,0,0,0,0,3313,1,0,0,0,0,3315,1,0,0,0,0,3317,
	1,0,0,0,0,3319,1,0,0,0,0,3321,1,0,0,0,0,3323,1,0,0,0,0,3325,1,0,0,0,0,3327,
	1,0,0,0,0,3329,1,0,0,0,0,3331,1,0,0,0,0,3333,1,0,0,0,0,3335,1,0,0,0,0,3337,
	1,0,0,0,0,3339,1,0,0,0,0,3341,1,0,0,0,0,3343,1,0,0,0,0,3345,1,0,0,0,0,3347,
	1,0,0,0,0,3349,1,0,0,0,0,3351,1,0,0,0,0,3353,1,0,0,0,0,3355,1,0,0,0,0,3357,
	1,0,0,0,0,3359,1,0,0,0,0,3361,1,0,0,0,0,3363,1,0,0,0,0,3365,1,0,0,0,0,3367,
	1,0,0,0,0,3369,1,0,0,0,0,3371,1,0,0,0,0,3373,1,0,0,0,0,3375,1,0,0,0,0,3377,
	1,0,0,0,0,3379,1,0,0,0,0,3381,1,0,0,0,0,3383,1,0,0,0,0,3385,1,0,0,0,0,3387,
	1,0,0,0,0,3389,1,0,0,0,0,3391,1,0,0,0,0,3393,1,0,0,0,0,3395,1,0,0,0,0,3397,
	1,0,0,0,0,3399,1,0,0,0,0,3401,1,0,0,0,0,3403,1,0,0,0,0,3405,1,0,0,0,0,3407,
	1,0,0,0,0,3409,1,0,0,0,0,3411,1,0,0,0,0,3413,1,0,0,0,0,3415,1,0,0,0,0,3417,
	1,0,0,0,0,3419,1,0,0,0,0,3421,1,0,0,0,0,3423,1,0,0,0,0,3425,1,0,0,0,0,3427,
	1,0,0,0,0,3429,1,0,0,0,0,3431,1,0,0,0,0,3433,1,0,0,0,0,3435,1,0,0,0,0,3437,
	1,0,0,0,0,3439,1,0,0,0,0,3441,1,0,0,0,0,3443,1,0,0,0,0,3445,1,0,0,0,0,3447,
	1,0,0,0,0,3449,1,0,0,0,0,3451,1,0,0,0,0,3453,1,0,0,0,0,3455,1,0,0,0,0,3457,
	1,0,0,0,0,3459,1,0,0,0,0,3461,1,0,0,0,0,3463,1,0,0,0,0,3465,1,0,0,0,0,3467,
	1,0,0,0,0,3469,1,0,0,0,0,3471,1,0,0,0,0,3473,1,0,0,0,0,3475,1,0,0,0,0,3477,
	1,0,0,0,0,3479,1,0,0,0,0,3481,1,0,0,0,0,3483,1,0,0,0,0,3485,1,0,0,0,0,3487,
	1,0,0,0,0,3489,1,0,0,0,0,3491,1,0,0,0,0,3493,1,0,0,0,0,3495,1,0,0,0,0,3497,
	1,0,0,0,0,3499,1,0,0,0,0,3501,1,0,0,0,0,3503,1,0,0,0,0,3505,1,0,0,0,0,3507,
	1,0,0,0,0,3509,1,0,0,0,0,3511,1,0,0,0,0,3513,1,0,0,0,0,3515,1,0,0,0,0,3517,
	1,0,0,0,0,3519,1,0,0,0,0,3521,1,0,0,0,0,3523,1,0,0,0,0,3525,1,0,0,0,0,3527,
	1,0,0,0,0,3529,1,0,0,0,0,3531,1,0,0,0,0,3533,1,0,0,0,0,3535,1,0,0,0,0,3537,
	1,0,0,0,0,3539,1,0,0,0,0,3541,1,0,0,0,0,3543,1,0,0,0,0,3545,1,0,0,0,0,3547,
	1,0,0,0,0,3549,1,0,0,0,0,3551,1,0,0,0,0,3553,1,0,0,0,0,3555,1,0,0,0,0,3557,
	1,0,0,0,0,3559,1,0,0,0,0,3561,1,0,0,0,0,3563,1,0,0,0,0,3565,1,0,0,0,0,3567,
	1,0,0,0,0,3569,1,0,0,0,0,3571,1,0,0,0,0,3573,1,0,0,0,0,3575,1,0,0,0,0,3577,
	1,0,0,0,0,3579,1,0,0,0,0,3581,1,0,0,0,0,3583,1,0,0,0,0,3585,1,0,0,0,0,3587,
	1,0,0,0,0,3589,1,0,0,0,0,3591,1,0,0,0,0,3593,1,0,0,0,0,3595,1,0,0,0,0,3597,
	1,0,0,0,0,3599,1,0,0,0,0,3601,1,0,0,0,0,3603,1,0,0,0,0,3605,1,0,0,0,0,3607,
	1,0,0,0,0,3609,1,0,0,0,0,3611,1,0,0,0,0,3613,1,0,0,0,0,3615,1,0,0,0,0,3617,
	1,0,0,0,0,3619,1,0,0,0,0,3621,1,0,0,0,0,3623,1,0,0,0,0,3625,1,0,0,0,0,3627,
	1,0,0,0,0,3629,1,0,0,0,0,3631,1,0,0,0,0,3633,1,0,0,0,0,3635,1,0,0,0,0,3637,
	1,0,0,0,0,3639,1,0,0,0,0,3641,1,0,0,0,0,3643,1,0,0,0,0,3645,1,0,0,0,0,3647,
	1,0,0,0,0,3649,1,0,0,0,0,3651,1,0,0,0,0,3653,1,0,0,0,0,3655,1,0,0,0,0,3657,
	1,0,0,0,0,3659,1,0,0,0,0,3661,1,0,0,0,0,3663,1,0,0,0,0,3665,1,0,0,0,0,3667,
	1,0,0,0,0,3669,1,0,0,0,0,3671,1,0,0,0,0,3673,1,0,0,0,0,3675,1,0,0,0,0,3677,
	1,0,0,0,0,3679,1,0,0,0,0,3681,1,0,0,0,0,3683,1,0,0,0,0,3685,1,0,0,0,0,3687,
	1,0,0,0,0,3689,1,0,0,0,0,3691,1,0,0,0,0,3693,1,0,0,0,0,3695,1,0,0,0,0,3697,
	1,0,0,0,0,3699,1,0,0,0,0,3701,1,0,0,0,0,3703,1,0,0,0,0,3705,1,0,0,0,0,3707,
	1,0,0,0,0,3709,1,0,0,0,0,3711,1,0,0,0,0,3713,1,0,0,0,0,3715,1,0,0,0,0,3717,
	1,0,0,0,0,3719,1,0,0,0,0,3721,1,0,0,0,0,3723,1,0,0,0,0,3725,1,0,0,0,0,3727,
	1,0,0,0,0,3729,1,0,0,0,0,3731,1,0,0,0,0,3733,1,0,0,0,0,3735,1,0,0,0,0,3737,
	1,0,0,0,0,3739,1,0,0,0,0,3741,1,0,0,0,0,3743,1,0,0,0,0,3745,1,0,0,0,0,3747,
	1,0,0,0,0,3749,1,0,0,0,0,3751,1,0,0,0,0,3753,1,0,0,0,0,3755,1,0,0,0,0,3757,
	1,0,0,0,0,3759,1,0,0,0,0,3761,1,0,0,0,0,3763,1,0,0,0,0,3765,1,0,0,0,0,3767,
	1,0,0,0,0,3769,1,0,0,0,0,3771,1,0,0,0,0,3773,1,0,0,0,0,3775,1,0,0,0,0,3777,
	1,0,0,0,0,3779,1,0,0,0,0,3781,1,0,0,0,0,3783,1,0,0,0,0,3785,1,0,0,0,0,3787,
	1,0,0,0,0,3789,1,0,0,0,0,3791,1,0,0,0,0,3793,1,0,0,0,0,3795,1,0,0,0,0,3797,
	1,0,0,0,0,3799,1,0,0,0,0,3801,1,0,0,0,0,3803,1,0,0,0,0,3805,1,0,0,0,0,3807,
	1,0,0,0,0,3809,1,0,0,0,0,3811,1,0,0,0,0,3813,1,0,0,0,0,3815,1,0,0,0,0,3817,
	1,0,0,0,0,3819,1,0,0,0,0,3821,1,0,0,0,0,3823,1,0,0,0,0,3825,1,0,0,0,0,3827,
	1,0,0,0,0,3829,1,0,0,0,0,3831,1,0,0,0,0,3833,1,0,0,0,0,3835,1,0,0,0,0,3837,
	1,0,0,0,0,3839,1,0,0,0,0,3841,1,0,0,0,0,3843,1,0,0,0,0,3845,1,0,0,0,0,3847,
	1,0,0,0,0,3849,1,0,0,0,0,3851,1,0,0,0,0,3853,1,0,0,0,0,3855,1,0,0,0,0,3857,
	1,0,0,0,0,3859,1,0,0,0,0,3861,1,0,0,0,0,3863,1,0,0,0,0,3865,1,0,0,0,0,3867,
	1,0,0,0,0,3869,1,0,0,0,0,3871,1,0,0,0,0,3873,1,0,0,0,0,3875,1,0,0,0,0,3877,
	1,0,0,0,0,3879,1,0,0,0,0,3881,1,0,0,0,0,3883,1,0,0,0,0,3885,1,0,0,0,0,3887,
	1,0,0,0,0,3889,1,0,0,0,0,3891,1,0,0,0,0,3893,1,0,0,0,0,3895,1,0,0,0,0,3897,
	1,0,0,0,0,3899,1,0,0,0,0,3901,1,0,0,0,0,3903,1,0,0,0,0,3905,1,0,0,0,0,3907,
	1,0,0,0,0,3909,1,0,0,0,0,3911,1,0,0,0,0,3913,1,0,0,0,0,3915,1,0,0,0,0,3917,
	1,0,0,0,0,3919,1,0,0,0,0,3921,1,0,0,0,0,3923,1,0,0,0,0,3925,1,0,0,0,0,3927,
	1,0,0,0,0,3929,1,0,0,0,0,3931,1,0,0,0,0,3933,1,0,0,0,0,3935,1,0,0,0,0,3937,
	1,0,0,0,0,3939,1,0,0,0,0,3941,1,0,0,0,0,3943,1,0,0,0,0,3945,1,0,0,0,0,3947,
	1,0,0,0,0,3949,1,0,0,0,0,3951,1,0,0,0,0,3953,1,0,0,0,0,3955,1,0,0,0,0,3957,
	1,0,0,0,0,3959,1,0,0,0,0,3961,1,0,0,0,0,3963,1,0,0,0,0,3965,1,0,0,0,0,3967,
	1,0,0,0,0,3969,1,0,0,0,0,3971,1,0,0,0,0,3973,1,0,0,0,0,3975,1,0,0,0,0,3977,
	1,0,0,0,0,3979,1,0,0,0,0,3981,1,0,0,0,0,3983,1,0,0,0,0,3985,1,0,0,0,0,3987,
	1,0,0,0,0,3989,1,0,0,0,0,3991,1,0,0,0,0,3993,1,0,0,0,0,3995,1,0,0,0,0,3997,
	1,0,0,0,0,3999,1,0,0,0,0,4001,1,0,0,0,0,4003,1,0,0,0,0,4005,1,0,0,0,0,4007,
	1,0,0,0,0,4009,1,0,0,0,0,4011,1,0,0,0,0,4013,1,0,0,0,0,4015,1,0,0,0,0,4017,
	1,0,0,0,0,4019,1,0,0,0,0,4021,1,0,0,0,0,4023,1,0,0,0,0,4025,1,0,0,0,0,4027,
	1,0,0,0,0,4029,1,0,0,0,0,4031,1,0,0,0,0,4033,1,0,0,0,0,4035,1,0,0,0,0,4037,
	1,0,0,0,0,4039,1,0,0,0,0,4041,1,0,0,0,0,4043,1,0,0,0,0,4045,1,0,0,0,0,4047,
	1,0,0,0,0,4049,1,0,0,0,0,4051,1,0,0,0,0,4053,1,0,0,0,0,4055,1,0,0,0,0,4057,
	1,0,0,0,0,4059,1,0,0,0,0,4061,1,0,0,0,0,4063,1,0,0,0,0,4065,1,0,0,0,0,4067,
	1,0,0,0,0,4069,1,0,0,0,0,4071,1,0,0,0,0,4073,1,0,0,0,0,4075,1,0,0,0,0,4077,
	1,0,0,0,0,4079,1,0,0,0,0,4081,1,0,0,0,0,4083,1,0,0,0,0,4085,1,0,0,0,0,4087,
	1,0,0,0,0,4089,1,0,0,0,0,4091,1,0,0,0,0,4093,1,0,0,0,0,4095,1,0,0,0,0,4097,
	1,0,0,0,0,4099,1,0,0,0,0,4101,1,0,0,0,0,4103,1,0,0,0,0,4105,1,0,0,0,0,4107,
	1,0,0,0,0,4109,1,0,0,0,0,4111,1,0,0,0,0,4113,1,0,0,0,0,4115,1,0,0,0,0,4117,
	1,0,0,0,0,4119,1,0,0,0,0,4121,1,0,0,0,0,4123,1,0,0,0,0,4125,1,0,0,0,0,4127,
	1,0,0,0,0,4129,1,0,0,0,0,4131,1,0,0,0,0,4133,1,0,0,0,0,4135,1,0,0,0,0,4137,
	1,0,0,0,0,4139,1,0,0,0,0,4141,1,0,0,0,0,4143,1,0,0,0,0,4145,1,0,0,0,0,4147,
	1,0,0,0,0,4149,1,0,0,0,0,4151,1,0,0,0,0,4153,1,0,0,0,0,4155,1,0,0,0,0,4157,
	1,0,0,0,0,4159,1,0,0,0,0,4161,1,0,0,0,0,4163,1,0,0,0,0,4165,1,0,0,0,0,4167,
	1,0,0,0,0,4169,1,0,0,0,0,4171,1,0,0,0,0,4173,1,0,0,0,0,4175,1,0,0,0,0,4177,
	1,0,0,0,0,4179,1,0,0,0,0,4181,1,0,0,0,0,4183,1,0,0,0,0,4185,1,0,0,0,0,4187,
	1,0,0,0,0,4189,1,0,0,0,0,4191,1,0,0,0,0,4193,1,0,0,0,0,4195,1,0,0,0,0,4197,
	1,0,0,0,0,4199,1,0,0,0,0,4201,1,0,0,0,0,4203,1,0,0,0,0,4205,1,0,0,0,0,4207,
	1,0,0,0,0,4209,1,0,0,0,0,4211,1,0,0,0,0,4213,1,0,0,0,0,4215,1,0,0,0,0,4217,
	1,0,0,0,0,4219,1,0,0,0,0,4221,1,0,0,0,0,4223,1,0,0,0,0,4225,1,0,0,0,0,4227,
	1,0,0,0,0,4229,1,0,0,0,0,4231,1,0,0,0,0,4233,1,0,0,0,0,4235,1,0,0,0,0,4237,
	1,0,0,0,0,4239,1,0,0,0,0,4241,1,0,0,0,0,4243,1,0,0,0,0,4245,1,0,0,0,0,4247,
	1,0,0,0,0,4249,1,0,0,0,0,4251,1,0,0,0,0,4253,1,0,0,0,0,4255,1,0,0,0,0,4257,
	1,0,0,0,0,4259,1,0,0,0,0,4261,1,0,0,0,0,4263,1,0,0,0,0,4265,1,0,0,0,0,4267,
	1,0,0,0,0,4269,1,0,0,0,0,4271,1,0,0,0,0,4273,1,0,0,0,0,4275,1,0,0,0,0,4277,
	1,0,0,0,0,4279,1,0,0,0,0,4281,1,0,0,0,0,4283,1,0,0,0,0,4285,1,0,0,0,0,4287,
	1,0,0,0,0,4289,1,0,0,0,0,4291,1,0,0,0,0,4293,1,0,0,0,0,4295,1,0,0,0,0,4297,
	1,0,0,0,0,4299,1,0,0,0,0,4301,1,0,0,0,0,4303,1,0,0,0,0,4305,1,0,0,0,0,4307,
	1,0,0,0,0,4309,1,0,0,0,0,4311,1,0,0,0,0,4313,1,0,0,0,0,4315,1,0,0,0,0,4317,
	1,0,0,0,0,4319,1,0,0,0,0,4321,1,0,0,0,0,4323,1,0,0,0,0,4325,1,0,0,0,0,4327,
	1,0,0,0,0,4329,1,0,0,0,0,4331,1,0,0,0,0,4333,1,0,0,0,0,4335,1,0,0,0,0,4337,
	1,0,0,0,0,4339,1,0,0,0,0,4341,1,0,0,0,0,4343,1,0,0,0,0,4345,1,0,0,0,0,4347,
	1,0,0,0,0,4349,1,0,0,0,0,4351,1,0,0,0,0,4353,1,0,0,0,0,4355,1,0,0,0,0,4357,
	1,0,0,0,0,4359,1,0,0,0,0,4361,1,0,0,0,0,4363,1,0,0,0,0,4365,1,0,0,0,0,4367,
	1,0,0,0,0,4369,1,0,0,0,0,4371,1,0,0,0,0,4373,1,0,0,0,0,4375,1,0,0,0,0,4377,
	1,0,0,0,0,4379,1,0,0,0,0,4381,1,0,0,0,0,4383,1,0,0,0,0,4385,1,0,0,0,0,4387,
	1,0,0,0,0,4389,1,0,0,0,0,4391,1,0,0,0,0,4393,1,0,0,0,0,4395,1,0,0,0,0,4397,
	1,0,0,0,0,4399,1,0,0,0,0,4401,1,0,0,0,0,4403,1,0,0,0,0,4405,1,0,0,0,0,4407,
	1,0,0,0,0,4409,1,0,0,0,0,4411,1,0,0,0,0,4413,1,0,0,0,0,4415,1,0,0,0,0,4417,
	1,0,0,0,0,4419,1,0,0,0,0,4421,1,0,0,0,0,4423,1,0,0,0,0,4425,1,0,0,0,0,4427,
	1,0,0,0,0,4429,1,0,0,0,0,4431,1,0,0,0,0,4433,1,0,0,0,0,4435,1,0,0,0,0,4437,
	1,0,0,0,0,4439,1,0,0,0,0,4441,1,0,0,0,0,4443,1,0,0,0,0,4445,1,0,0,0,0,4447,
	1,0,0,0,0,4449,1,0,0,0,0,4451,1,0,0,0,0,4453,1,0,0,0,0,4455,1,0,0,0,0,4457,
	1,0,0,0,0,4459,1,0,0,0,0,4461,1,0,0,0,0,4463,1,0,0,0,0,4465,1,0,0,0,0,4467,
	1,0,0,0,0,4469,1,0,0,0,0,4471,1,0,0,0,0,4473,1,0,0,0,0,4475,1,0,0,0,0,4477,
	1,0,0,0,0,4479,1,0,0,0,0,4481,1,0,0,0,0,4483,1,0,0,0,0,4485,1,0,0,0,0,4487,
	1,0,0,0,0,4489,1,0,0,0,0,4491,1,0,0,0,0,4493,1,0,0,0,0,4495,1,0,0,0,0,4497,
	1,0,0,0,0,4499,1,0,0,0,0,4501,1,0,0,0,0,4503,1,0,0,0,0,4505,1,0,0,0,0,4507,
	1,0,0,0,0,4509,1,0,0,0,0,4511,1,0,0,0,0,4513,1,0,0,0,0,4515,1,0,0,0,0,4517,
	1,0,0,0,0,4519,1,0,0,0,0,4521,1,0,0,0,0,4523,1,0,0,0,0,4525,1,0,0,0,0,4527,
	1,0,0,0,0,4529,1,0,0,0,0,4531,1,0,0,0,0,4533,1,0,0,0,0,4535,1,0,0,0,0,4537,
	1,0,0,0,0,4539,1,0,0,0,0,4541,1,0,0,0,0,4543,1,0,0,0,0,4545,1,0,0,0,0,4547,
	1,0,0,0,0,4549,1,0,0,0,0,4551,1,0,0,0,0,4553,1,0,0,0,0,4555,1,0,0,0,0,4557,
	1,0,0,0,0,4559,1,0,0,0,0,4561,1,0,0,0,0,4563,1,0,0,0,0,4565,1,0,0,0,0,4567,
	1,0,0,0,0,4569,1,0,0,0,0,4571,1,0,0,0,0,4573,1,0,0,0,0,4575,1,0,0,0,0,4577,
	1,0,0,0,0,4579,1,0,0,0,0,4581,1,0,0,0,0,4583,1,0,0,0,0,4585,1,0,0,0,0,4587,
	1,0,0,0,0,4589,1,0,0,0,0,4591,1,0,0,0,0,4593,1,0,0,0,0,4595,1,0,0,0,0,4597,
	1,0,0,0,0,4599,1,0,0,0,0,4601,1,0,0,0,0,4603,1,0,0,0,0,4605,1,0,0,0,0,4607,
	1,0,0,0,0,4609,1,0,0,0,0,4611,1,0,0,0,0,4613,1,0,0,0,0,4615,1,0,0,0,0,4617,
	1,0,0,0,0,4619,1,0,0,0,0,4621,1,0,0,0,0,4623,1,0,0,0,0,4625,1,0,0,0,0,4627,
	1,0,0,0,0,4629,1,0,0,0,0,4631,1,0,0,0,0,4633,1,0,0,0,0,4635,1,0,0,0,0,4637,
	1,0,0,0,0,4639,1,0,0,0,0,4657,1,0,0,0,0,4659,1,0,0,0,0,4661,1,0,0,0,0,4663,
	1,0,0,0,0,4665,1,0,0,0,0,4667,1,0,0,0,0,4669,1,0,0,0,0,4671,1,0,0,0,0,4673,
	1,0,0,0,0,4675,1,0,0,0,0,4677,1,0,0,0,0,4679,1,0,0,0,0,4681,1,0,0,0,0,4683,
	1,0,0,0,0,4685,1,0,0,0,0,4687,1,0,0,0,0,4689,1,0,0,0,0,4691,1,0,0,0,0,4693,
	1,0,0,0,0,4695,1,0,0,0,0,4697,1,0,0,0,0,4699,1,0,0,0,0,4701,1,0,0,0,0,4703,
	1,0,0,0,0,4705,1,0,0,0,0,4707,1,0,0,0,0,4709,1,0,0,0,0,4711,1,0,0,0,0,4713,
	1,0,0,0,0,4715,1,0,0,0,0,4717,1,0,0,0,0,4719,1,0,0,0,0,4721,1,0,0,0,0,4723,
	1,0,0,0,0,4725,1,0,0,0,0,4727,1,0,0,0,1,4741,1,0,0,0,3,4747,1,0,0,0,5,4751,
	1,0,0,0,7,4758,1,0,0,0,9,4765,1,0,0,0,11,4774,1,0,0,0,13,4782,1,0,0,0,15,
	4786,1,0,0,0,17,4791,1,0,0,0,19,4798,1,0,0,0,21,4805,1,0,0,0,23,4813,1,
	0,0,0,25,4822,1,0,0,0,27,4829,1,0,0,0,29,4846,1,0,0,0,31,4858,1,0,0,0,33,
	4874,1,0,0,0,35,4885,1,0,0,0,37,4894,1,0,0,0,39,4908,1,0,0,0,41,4912,1,
	0,0,0,43,4923,1,0,0,0,45,4933,1,0,0,0,47,4944,1,0,0,0,49,4953,1,0,0,0,51,
	4959,1,0,0,0,53,4970,1,0,0,0,55,4984,1,0,0,0,57,4993,1,0,0,0,59,5000,1,
	0,0,0,61,5008,1,0,0,0,63,5023,1,0,0,0,65,5029,1,0,0,0,67,5035,1,0,0,0,69,
	5045,1,0,0,0,71,5047,1,0,0,0,73,5053,1,0,0,0,75,5057,1,0,0,0,77,5066,1,
	0,0,0,79,5072,1,0,0,0,81,5081,1,0,0,0,83,5087,1,0,0,0,85,5097,1,0,0,0,87,
	5104,1,0,0,0,89,5113,1,0,0,0,91,5121,1,0,0,0,93,5130,1,0,0,0,95,5140,1,
	0,0,0,97,5144,1,0,0,0,99,5154,1,0,0,0,101,5162,1,0,0,0,103,5174,1,0,0,0,
	105,5183,1,0,0,0,107,5187,1,0,0,0,109,5197,1,0,0,0,111,5204,1,0,0,0,113,
	5219,1,0,0,0,115,5233,1,0,0,0,117,5245,1,0,0,0,119,5251,1,0,0,0,121,5273,
	1,0,0,0,123,5282,1,0,0,0,125,5290,1,0,0,0,127,5299,1,0,0,0,129,5310,1,0,
	0,0,131,5316,1,0,0,0,133,5319,1,0,0,0,135,5323,1,0,0,0,137,5329,1,0,0,0,
	139,5338,1,0,0,0,141,5343,1,0,0,0,143,5348,1,0,0,0,145,5357,1,0,0,0,147,
	5364,1,0,0,0,149,5374,1,0,0,0,151,5380,1,0,0,0,153,5393,1,0,0,0,155,5399,
	1,0,0,0,157,5404,1,0,0,0,159,5407,1,0,0,0,161,5417,1,0,0,0,163,5428,1,0,
	0,0,165,5434,1,0,0,0,167,5448,1,0,0,0,169,5463,1,0,0,0,171,5470,1,0,0,0,
	173,5484,1,0,0,0,175,5497,1,0,0,0,177,5502,1,0,0,0,179,5513,1,0,0,0,181,
	5524,1,0,0,0,183,5535,1,0,0,0,185,5545,1,0,0,0,187,5568,1,0,0,0,189,5584,
	1,0,0,0,191,5597,1,0,0,0,193,5602,1,0,0,0,195,5613,1,0,0,0,197,5625,1,0,
	0,0,199,5632,1,0,0,0,201,5640,1,0,0,0,203,5650,1,0,0,0,205,5656,1,0,0,0,
	207,5666,1,0,0,0,209,5672,1,0,0,0,211,5682,1,0,0,0,213,5710,1,0,0,0,215,
	5717,1,0,0,0,217,5724,1,0,0,0,219,5730,1,0,0,0,221,5740,1,0,0,0,223,5759,
	1,0,0,0,225,5766,1,0,0,0,227,5775,1,0,0,0,229,5783,1,0,0,0,231,5789,1,0,
	0,0,233,5799,1,0,0,0,235,5807,1,0,0,0,237,5814,1,0,0,0,239,5828,1,0,0,0,
	241,5851,1,0,0,0,243,5869,1,0,0,0,245,5882,1,0,0,0,247,5904,1,0,0,0,249,
	5921,1,0,0,0,251,5936,1,0,0,0,253,5947,1,0,0,0,255,5955,1,0,0,0,257,5966,
	1,0,0,0,259,5973,1,0,0,0,261,5984,1,0,0,0,263,5991,1,0,0,0,265,5999,1,0,
	0,0,267,6011,1,0,0,0,269,6016,1,0,0,0,271,6021,1,0,0,0,273,6027,1,0,0,0,
	275,6039,1,0,0,0,277,6046,1,0,0,0,279,6056,1,0,0,0,281,6061,1,0,0,0,283,
	6069,1,0,0,0,285,6074,1,0,0,0,287,6080,1,0,0,0,289,6087,1,0,0,0,291,6095,
	1,0,0,0,293,6105,1,0,0,0,295,6110,1,0,0,0,297,6117,1,0,0,0,299,6130,1,0,
	0,0,301,6142,1,0,0,0,303,6148,1,0,0,0,305,6153,1,0,0,0,307,6156,1,0,0,0,
	309,6179,1,0,0,0,311,6191,1,0,0,0,313,6196,1,0,0,0,315,6202,1,0,0,0,317,
	6211,1,0,0,0,319,6227,1,0,0,0,321,6244,1,0,0,0,323,6252,1,0,0,0,325,6263,
	1,0,0,0,327,6272,1,0,0,0,329,6277,1,0,0,0,331,6284,1,0,0,0,333,6294,1,0,
	0,0,335,6303,1,0,0,0,337,6311,1,0,0,0,339,6323,1,0,0,0,341,6331,1,0,0,0,
	343,6336,1,0,0,0,345,6341,1,0,0,0,347,6356,1,0,0,0,349,6365,1,0,0,0,351,
	6377,1,0,0,0,353,6382,1,0,0,0,355,6399,1,0,0,0,357,6411,1,0,0,0,359,6417,
	1,0,0,0,361,6425,1,0,0,0,363,6432,1,0,0,0,365,6447,1,0,0,0,367,6473,1,0,
	0,0,369,6483,1,0,0,0,371,6488,1,0,0,0,373,6496,1,0,0,0,375,6508,1,0,0,0,
	377,6526,1,0,0,0,379,6532,1,0,0,0,381,6543,1,0,0,0,383,6549,1,0,0,0,385,
	6556,1,0,0,0,387,6560,1,0,0,0,389,6566,1,0,0,0,391,6572,1,0,0,0,393,6587,
	1,0,0,0,395,6598,1,0,0,0,397,6605,1,0,0,0,399,6611,1,0,0,0,401,6619,1,0,
	0,0,403,6625,1,0,0,0,405,6627,1,0,0,0,407,6634,1,0,0,0,409,6639,1,0,0,0,
	411,6645,1,0,0,0,413,6671,1,0,0,0,415,6677,1,0,0,0,417,6694,1,0,0,0,419,
	6702,1,0,0,0,421,6718,1,0,0,0,423,6735,1,0,0,0,425,6746,1,0,0,0,427,6757,
	1,0,0,0,429,6775,1,0,0,0,431,6795,1,0,0,0,433,6807,1,0,0,0,435,6816,1,0,
	0,0,437,6828,1,0,0,0,439,6835,1,0,0,0,441,6847,1,0,0,0,443,6852,1,0,0,0,
	445,6860,1,0,0,0,447,6869,1,0,0,0,449,6891,1,0,0,0,451,6898,1,0,0,0,453,
	6906,1,0,0,0,455,6919,1,0,0,0,457,6932,1,0,0,0,459,6940,1,0,0,0,461,6947,
	1,0,0,0,463,6957,1,0,0,0,465,6964,1,0,0,0,467,6976,1,0,0,0,469,6984,1,0,
	0,0,471,6998,1,0,0,0,473,7006,1,0,0,0,475,7015,1,0,0,0,477,7026,1,0,0,0,
	479,7036,1,0,0,0,481,7047,1,0,0,0,483,7055,1,0,0,0,485,7065,1,0,0,0,487,
	7081,1,0,0,0,489,7090,1,0,0,0,491,7099,1,0,0,0,493,7107,1,0,0,0,495,7114,
	1,0,0,0,497,7129,1,0,0,0,499,7141,1,0,0,0,501,7151,1,0,0,0,503,7159,1,0,
	0,0,505,7170,1,0,0,0,507,7185,1,0,0,0,509,7192,1,0,0,0,511,7207,1,0,0,0,
	513,7230,1,0,0,0,515,7252,1,0,0,0,517,7274,1,0,0,0,519,7295,1,0,0,0,521,
	7316,1,0,0,0,523,7335,1,0,0,0,525,7353,1,0,0,0,527,7369,1,0,0,0,529,7377,
	1,0,0,0,531,7390,1,0,0,0,533,7399,1,0,0,0,535,7410,1,0,0,0,537,7419,1,0,
	0,0,539,7425,1,0,0,0,541,7436,1,0,0,0,543,7448,1,0,0,0,545,7460,1,0,0,0,
	547,7470,1,0,0,0,549,7481,1,0,0,0,551,7500,1,0,0,0,553,7515,1,0,0,0,555,
	7529,1,0,0,0,557,7537,1,0,0,0,559,7546,1,0,0,0,561,7554,1,0,0,0,563,7563,
	1,0,0,0,565,7575,1,0,0,0,567,7589,1,0,0,0,569,7597,1,0,0,0,571,7608,1,0,
	0,0,573,7615,1,0,0,0,575,7620,1,0,0,0,577,7627,1,0,0,0,579,7634,1,0,0,0,
	581,7645,1,0,0,0,583,7661,1,0,0,0,585,7673,1,0,0,0,587,7677,1,0,0,0,589,
	7682,1,0,0,0,591,7687,1,0,0,0,593,7710,1,0,0,0,595,7716,1,0,0,0,597,7726,
	1,0,0,0,599,7737,1,0,0,0,601,7749,1,0,0,0,603,7762,1,0,0,0,605,7778,1,0,
	0,0,607,7784,1,0,0,0,609,7791,1,0,0,0,611,7808,1,0,0,0,613,7831,1,0,0,0,
	615,7840,1,0,0,0,617,7851,1,0,0,0,619,7860,1,0,0,0,621,7866,1,0,0,0,623,
	7879,1,0,0,0,625,7889,1,0,0,0,627,7897,1,0,0,0,629,7902,1,0,0,0,631,7910,
	1,0,0,0,633,7918,1,0,0,0,635,7929,1,0,0,0,637,7937,1,0,0,0,639,7950,1,0,
	0,0,641,7965,1,0,0,0,643,7978,1,0,0,0,645,7996,1,0,0,0,647,8009,1,0,0,0,
	649,8018,1,0,0,0,651,8025,1,0,0,0,653,8046,1,0,0,0,655,8070,1,0,0,0,657,
	8082,1,0,0,0,659,8085,1,0,0,0,661,8091,1,0,0,0,663,8100,1,0,0,0,665,8109,
	1,0,0,0,667,8114,1,0,0,0,669,8123,1,0,0,0,671,8133,1,0,0,0,673,8149,1,0,
	0,0,675,8162,1,0,0,0,677,8172,1,0,0,0,679,8197,1,0,0,0,681,8218,1,0,0,0,
	683,8227,1,0,0,0,685,8255,1,0,0,0,687,8260,1,0,0,0,689,8270,1,0,0,0,691,
	8274,1,0,0,0,693,8279,1,0,0,0,695,8283,1,0,0,0,697,8298,1,0,0,0,699,8309,
	1,0,0,0,701,8324,1,0,0,0,703,8335,1,0,0,0,705,8350,1,0,0,0,707,8361,1,0,
	0,0,709,8365,1,0,0,0,711,8376,1,0,0,0,713,8382,1,0,0,0,715,8391,1,0,0,0,
	717,8395,1,0,0,0,719,8403,1,0,0,0,721,8411,1,0,0,0,723,8421,1,0,0,0,725,
	8433,1,0,0,0,727,8438,1,0,0,0,729,8448,1,0,0,0,731,8456,1,0,0,0,733,8468,
	1,0,0,0,735,8476,1,0,0,0,737,8485,1,0,0,0,739,8503,1,0,0,0,741,8522,1,0,
	0,0,743,8533,1,0,0,0,745,8542,1,0,0,0,747,8550,1,0,0,0,749,8557,1,0,0,0,
	751,8565,1,0,0,0,753,8572,1,0,0,0,755,8578,1,0,0,0,757,8587,1,0,0,0,759,
	8598,1,0,0,0,761,8605,1,0,0,0,763,8615,1,0,0,0,765,8622,1,0,0,0,767,8634,
	1,0,0,0,769,8644,1,0,0,0,771,8650,1,0,0,0,773,8658,1,0,0,0,775,8664,1,0,
	0,0,777,8681,1,0,0,0,779,8686,1,0,0,0,781,8698,1,0,0,0,783,8706,1,0,0,0,
	785,8715,1,0,0,0,787,8726,1,0,0,0,789,8740,1,0,0,0,791,8751,1,0,0,0,793,
	8761,1,0,0,0,795,8772,1,0,0,0,797,8784,1,0,0,0,799,8794,1,0,0,0,801,8806,
	1,0,0,0,803,8818,1,0,0,0,805,8826,1,0,0,0,807,8847,1,0,0,0,809,8862,1,0,
	0,0,811,8875,1,0,0,0,813,8884,1,0,0,0,815,8897,1,0,0,0,817,8905,1,0,0,0,
	819,8916,1,0,0,0,821,8921,1,0,0,0,823,8931,1,0,0,0,825,8944,1,0,0,0,827,
	8950,1,0,0,0,829,8959,1,0,0,0,831,8968,1,0,0,0,833,8982,1,0,0,0,835,8994,
	1,0,0,0,837,9005,1,0,0,0,839,9009,1,0,0,0,841,9020,1,0,0,0,843,9032,1,0,
	0,0,845,9041,1,0,0,0,847,9061,1,0,0,0,849,9082,1,0,0,0,851,9100,1,0,0,0,
	853,9107,1,0,0,0,855,9117,1,0,0,0,857,9130,1,0,0,0,859,9142,1,0,0,0,861,
	9147,1,0,0,0,863,9158,1,0,0,0,865,9183,1,0,0,0,867,9207,1,0,0,0,869,9212,
	1,0,0,0,871,9220,1,0,0,0,873,9230,1,0,0,0,875,9233,1,0,0,0,877,9241,1,0,
	0,0,879,9258,1,0,0,0,881,9283,1,0,0,0,883,9285,1,0,0,0,885,9290,1,0,0,0,
	887,9302,1,0,0,0,889,9310,1,0,0,0,891,9321,1,0,0,0,893,9330,1,0,0,0,895,
	9338,1,0,0,0,897,9351,1,0,0,0,899,9366,1,0,0,0,901,9380,1,0,0,0,903,9401,
	1,0,0,0,905,9406,1,0,0,0,907,9412,1,0,0,0,909,9415,1,0,0,0,911,9426,1,0,
	0,0,913,9437,1,0,0,0,915,9443,1,0,0,0,917,9454,1,0,0,0,919,9461,1,0,0,0,
	921,9481,1,0,0,0,923,9495,1,0,0,0,925,9504,1,0,0,0,927,9512,1,0,0,0,929,
	9523,1,0,0,0,931,9527,1,0,0,0,933,9544,1,0,0,0,935,9553,1,0,0,0,937,9561,
	1,0,0,0,939,9569,1,0,0,0,941,9580,1,0,0,0,943,9595,1,0,0,0,945,9601,1,0,
	0,0,947,9610,1,0,0,0,949,9614,1,0,0,0,951,9629,1,0,0,0,953,9635,1,0,0,0,
	955,9657,1,0,0,0,957,9664,1,0,0,0,959,9671,1,0,0,0,961,9680,1,0,0,0,963,
	9685,1,0,0,0,965,9694,1,0,0,0,967,9703,1,0,0,0,969,9714,1,0,0,0,971,9721,
	1,0,0,0,973,9727,1,0,0,0,975,9734,1,0,0,0,977,9744,1,0,0,0,979,9759,1,0,
	0,0,981,9770,1,0,0,0,983,9779,1,0,0,0,985,9787,1,0,0,0,987,9797,1,0,0,0,
	989,9807,1,0,0,0,991,9815,1,0,0,0,993,9822,1,0,0,0,995,9831,1,0,0,0,997,
	9838,1,0,0,0,999,9849,1,0,0,0,1001,9854,1,0,0,0,1003,9875,1,0,0,0,1005,
	9888,1,0,0,0,1007,9892,1,0,0,0,1009,9899,1,0,0,0,1011,9907,1,0,0,0,1013,
	9917,1,0,0,0,1015,9924,1,0,0,0,1017,9940,1,0,0,0,1019,9948,1,0,0,0,1021,
	9956,1,0,0,0,1023,9963,1,0,0,0,1025,9971,1,0,0,0,1027,9980,1,0,0,0,1029,
	9991,1,0,0,0,1031,10006,1,0,0,0,1033,10014,1,0,0,0,1035,10027,1,0,0,0,1037,
	10033,1,0,0,0,1039,10042,1,0,0,0,1041,10047,1,0,0,0,1043,10054,1,0,0,0,
	1045,10069,1,0,0,0,1047,10076,1,0,0,0,1049,10098,1,0,0,0,1051,10108,1,0,
	0,0,1053,10117,1,0,0,0,1055,10125,1,0,0,0,1057,10131,1,0,0,0,1059,10138,
	1,0,0,0,1061,10142,1,0,0,0,1063,10147,1,0,0,0,1065,10157,1,0,0,0,1067,10165,
	1,0,0,0,1069,10173,1,0,0,0,1071,10189,1,0,0,0,1073,10200,1,0,0,0,1075,10212,
	1,0,0,0,1077,10226,1,0,0,0,1079,10232,1,0,0,0,1081,10237,1,0,0,0,1083,10255,
	1,0,0,0,1085,10265,1,0,0,0,1087,10275,1,0,0,0,1089,10299,1,0,0,0,1091,10306,
	1,0,0,0,1093,10312,1,0,0,0,1095,10317,1,0,0,0,1097,10324,1,0,0,0,1099,10330,
	1,0,0,0,1101,10337,1,0,0,0,1103,10348,1,0,0,0,1105,10360,1,0,0,0,1107,10376,
	1,0,0,0,1109,10384,1,0,0,0,1111,10394,1,0,0,0,1113,10406,1,0,0,0,1115,10412,
	1,0,0,0,1117,10417,1,0,0,0,1119,10422,1,0,0,0,1121,10428,1,0,0,0,1123,10434,
	1,0,0,0,1125,10441,1,0,0,0,1127,10451,1,0,0,0,1129,10459,1,0,0,0,1131,10466,
	1,0,0,0,1133,10472,1,0,0,0,1135,10496,1,0,0,0,1137,10504,1,0,0,0,1139,10512,
	1,0,0,0,1141,10516,1,0,0,0,1143,10523,1,0,0,0,1145,10531,1,0,0,0,1147,10547,
	1,0,0,0,1149,10556,1,0,0,0,1151,10566,1,0,0,0,1153,10576,1,0,0,0,1155,10582,
	1,0,0,0,1157,10587,1,0,0,0,1159,10595,1,0,0,0,1161,10600,1,0,0,0,1163,10625,
	1,0,0,0,1165,10634,1,0,0,0,1167,10644,1,0,0,0,1169,10648,1,0,0,0,1171,10650,
	1,0,0,0,1173,10678,1,0,0,0,1175,10701,1,0,0,0,1177,10717,1,0,0,0,1179,10730,
	1,0,0,0,1181,10740,1,0,0,0,1183,10744,1,0,0,0,1185,10751,1,0,0,0,1187,10760,
	1,0,0,0,1189,10772,1,0,0,0,1191,10793,1,0,0,0,1193,10798,1,0,0,0,1195,10804,
	1,0,0,0,1197,10813,1,0,0,0,1199,10819,1,0,0,0,1201,10828,1,0,0,0,1203,10837,
	1,0,0,0,1205,10849,1,0,0,0,1207,10856,1,0,0,0,1209,10867,1,0,0,0,1211,10877,
	1,0,0,0,1213,10883,1,0,0,0,1215,10894,1,0,0,0,1217,10902,1,0,0,0,1219,10907,
	1,0,0,0,1221,10916,1,0,0,0,1223,10924,1,0,0,0,1225,10931,1,0,0,0,1227,10938,
	1,0,0,0,1229,10943,1,0,0,0,1231,10948,1,0,0,0,1233,10957,1,0,0,0,1235,10966,
	1,0,0,0,1237,10973,1,0,0,0,1239,10978,1,0,0,0,1241,10989,1,0,0,0,1243,11002,
	1,0,0,0,1245,11014,1,0,0,0,1247,11024,1,0,0,0,1249,11029,1,0,0,0,1251,11043,
	1,0,0,0,1253,11055,1,0,0,0,1255,11059,1,0,0,0,1257,11064,1,0,0,0,1259,11070,
	1,0,0,0,1261,11075,1,0,0,0,1263,11088,1,0,0,0,1265,11095,1,0,0,0,1267,11097,
	1,0,0,0,1269,11108,1,0,0,0,1271,11119,1,0,0,0,1273,11128,1,0,0,0,1275,11141,
	1,0,0,0,1277,11144,1,0,0,0,1279,11154,1,0,0,0,1281,11157,1,0,0,0,1283,11164,
	1,0,0,0,1285,11192,1,0,0,0,1287,11219,1,0,0,0,1289,11239,1,0,0,0,1291,11243,
	1,0,0,0,1293,11253,1,0,0,0,1295,11260,1,0,0,0,1297,11267,1,0,0,0,1299,11276,
	1,0,0,0,1301,11298,1,0,0,0,1303,11306,1,0,0,0,1305,11322,1,0,0,0,1307,11332,
	1,0,0,0,1309,11344,1,0,0,0,1311,11354,1,0,0,0,1313,11359,1,0,0,0,1315,11366,
	1,0,0,0,1317,11376,1,0,0,0,1319,11390,1,0,0,0,1321,11401,1,0,0,0,1323,11409,
	1,0,0,0,1325,11417,1,0,0,0,1327,11427,1,0,0,0,1329,11440,1,0,0,0,1331,11446,
	1,0,0,0,1333,11455,1,0,0,0,1335,11466,1,0,0,0,1337,11477,1,0,0,0,1339,11487,
	1,0,0,0,1341,11500,1,0,0,0,1343,11514,1,0,0,0,1345,11523,1,0,0,0,1347,11534,
	1,0,0,0,1349,11550,1,0,0,0,1351,11563,1,0,0,0,1353,11577,1,0,0,0,1355,11586,
	1,0,0,0,1357,11598,1,0,0,0,1359,11608,1,0,0,0,1361,11619,1,0,0,0,1363,11629,
	1,0,0,0,1365,11637,1,0,0,0,1367,11646,1,0,0,0,1369,11660,1,0,0,0,1371,11668,
	1,0,0,0,1373,11671,1,0,0,0,1375,11679,1,0,0,0,1377,11687,1,0,0,0,1379,11699,
	1,0,0,0,1381,11709,1,0,0,0,1383,11718,1,0,0,0,1385,11725,1,0,0,0,1387,11743,
	1,0,0,0,1389,11752,1,0,0,0,1391,11771,1,0,0,0,1393,11788,1,0,0,0,1395,11794,
	1,0,0,0,1397,11800,1,0,0,0,1399,11808,1,0,0,0,1401,11828,1,0,0,0,1403,11849,
	1,0,0,0,1405,11864,1,0,0,0,1407,11871,1,0,0,0,1409,11886,1,0,0,0,1411,11902,
	1,0,0,0,1413,11911,1,0,0,0,1415,11921,1,0,0,0,1417,11934,1,0,0,0,1419,11944,
	1,0,0,0,1421,11952,1,0,0,0,1423,11959,1,0,0,0,1425,11966,1,0,0,0,1427,11973,
	1,0,0,0,1429,11980,1,0,0,0,1431,11986,1,0,0,0,1433,11994,1,0,0,0,1435,12006,
	1,0,0,0,1437,12019,1,0,0,0,1439,12036,1,0,0,0,1441,12049,1,0,0,0,1443,12061,
	1,0,0,0,1445,12071,1,0,0,0,1447,12080,1,0,0,0,1449,12084,1,0,0,0,1451,12089,
	1,0,0,0,1453,12100,1,0,0,0,1455,12110,1,0,0,0,1457,12120,1,0,0,0,1459,12123,
	1,0,0,0,1461,12131,1,0,0,0,1463,12141,1,0,0,0,1465,12157,1,0,0,0,1467,12165,
	1,0,0,0,1469,12182,1,0,0,0,1471,12187,1,0,0,0,1473,12191,1,0,0,0,1475,12196,
	1,0,0,0,1477,12210,1,0,0,0,1479,12221,1,0,0,0,1481,12232,1,0,0,0,1483,12245,
	1,0,0,0,1485,12257,1,0,0,0,1487,12265,1,0,0,0,1489,12270,1,0,0,0,1491,12285,
	1,0,0,0,1493,12297,1,0,0,0,1495,12307,1,0,0,0,1497,12318,1,0,0,0,1499,12333,
	1,0,0,0,1501,12344,1,0,0,0,1503,12363,1,0,0,0,1505,12381,1,0,0,0,1507,12396,
	1,0,0,0,1509,12407,1,0,0,0,1511,12409,1,0,0,0,1513,12425,1,0,0,0,1515,12430,
	1,0,0,0,1517,12439,1,0,0,0,1519,12443,1,0,0,0,1521,12454,1,0,0,0,1523,12462,
	1,0,0,0,1525,12467,1,0,0,0,1527,12476,1,0,0,0,1529,12481,1,0,0,0,1531,12487,
	1,0,0,0,1533,12496,1,0,0,0,1535,12505,1,0,0,0,1537,12510,1,0,0,0,1539,12521,
	1,0,0,0,1541,12529,1,0,0,0,1543,12533,1,0,0,0,1545,12539,1,0,0,0,1547,12565,
	1,0,0,0,1549,12583,1,0,0,0,1551,12606,1,0,0,0,1553,12611,1,0,0,0,1555,12620,
	1,0,0,0,1557,12633,1,0,0,0,1559,12641,1,0,0,0,1561,12646,1,0,0,0,1563,12654,
	1,0,0,0,1565,12662,1,0,0,0,1567,12670,1,0,0,0,1569,12678,1,0,0,0,1571,12685,
	1,0,0,0,1573,12690,1,0,0,0,1575,12696,1,0,0,0,1577,12707,1,0,0,0,1579,12714,
	1,0,0,0,1581,12722,1,0,0,0,1583,12732,1,0,0,0,1585,12737,1,0,0,0,1587,12746,
	1,0,0,0,1589,12752,1,0,0,0,1591,12758,1,0,0,0,1593,12764,1,0,0,0,1595,12776,
	1,0,0,0,1597,12781,1,0,0,0,1599,12787,1,0,0,0,1601,12794,1,0,0,0,1603,12799,
	1,0,0,0,1605,12804,1,0,0,0,1607,12807,1,0,0,0,1609,12813,1,0,0,0,1611,12818,
	1,0,0,0,1613,12822,1,0,0,0,1615,12829,1,0,0,0,1617,12834,1,0,0,0,1619,12848,
	1,0,0,0,1621,12854,1,0,0,0,1623,12864,1,0,0,0,1625,12879,1,0,0,0,1627,12888,
	1,0,0,0,1629,12896,1,0,0,0,1631,12905,1,0,0,0,1633,12912,1,0,0,0,1635,12920,
	1,0,0,0,1637,12925,1,0,0,0,1639,12933,1,0,0,0,1641,12942,1,0,0,0,1643,12950,
	1,0,0,0,1645,12958,1,0,0,0,1647,12981,1,0,0,0,1649,13007,1,0,0,0,1651,13011,
	1,0,0,0,1653,13021,1,0,0,0,1655,13028,1,0,0,0,1657,13034,1,0,0,0,1659,13059,
	1,0,0,0,1661,13064,1,0,0,0,1663,13069,1,0,0,0,1665,13074,1,0,0,0,1667,13080,
	1,0,0,0,1669,13084,1,0,0,0,1671,13089,1,0,0,0,1673,13095,1,0,0,0,1675,13097,
	1,0,0,0,1677,13102,1,0,0,0,1679,13111,1,0,0,0,1681,13119,1,0,0,0,1683,13126,
	1,0,0,0,1685,13137,1,0,0,0,1687,13145,1,0,0,0,1689,13155,1,0,0,0,1691,13162,
	1,0,0,0,1693,13166,1,0,0,0,1695,13174,1,0,0,0,1697,13181,1,0,0,0,1699,13189,
	1,0,0,0,1701,13197,1,0,0,0,1703,13203,1,0,0,0,1705,13216,1,0,0,0,1707,13232,
	1,0,0,0,1709,13245,1,0,0,0,1711,13257,1,0,0,0,1713,13269,1,0,0,0,1715,13282,
	1,0,0,0,1717,13293,1,0,0,0,1719,13302,1,0,0,0,1721,13315,1,0,0,0,1723,13327,
	1,0,0,0,1725,13341,1,0,0,0,1727,13355,1,0,0,0,1729,13376,1,0,0,0,1731,13384,
	1,0,0,0,1733,13393,1,0,0,0,1735,13402,1,0,0,0,1737,13410,1,0,0,0,1739,13419,
	1,0,0,0,1741,13426,1,0,0,0,1743,13433,1,0,0,0,1745,13448,1,0,0,0,1747,13467,
	1,0,0,0,1749,13479,1,0,0,0,1751,13498,1,0,0,0,1753,13510,1,0,0,0,1755,13517,
	1,0,0,0,1757,13531,1,0,0,0,1759,13540,1,0,0,0,1761,13555,1,0,0,0,1763,13561,
	1,0,0,0,1765,13570,1,0,0,0,1767,13579,1,0,0,0,1769,13586,1,0,0,0,1771,13594,
	1,0,0,0,1773,13604,1,0,0,0,1775,13615,1,0,0,0,1777,13624,1,0,0,0,1779,13632,
	1,0,0,0,1781,13639,1,0,0,0,1783,13645,1,0,0,0,1785,13656,1,0,0,0,1787,13663,
	1,0,0,0,1789,13671,1,0,0,0,1791,13680,1,0,0,0,1793,13691,1,0,0,0,1795,13701,
	1,0,0,0,1797,13708,1,0,0,0,1799,13716,1,0,0,0,1801,13725,1,0,0,0,1803,13734,
	1,0,0,0,1805,13757,1,0,0,0,1807,13785,1,0,0,0,1809,13808,1,0,0,0,1811,13827,
	1,0,0,0,1813,13833,1,0,0,0,1815,13842,1,0,0,0,1817,13860,1,0,0,0,1819,13870,
	1,0,0,0,1821,13885,1,0,0,0,1823,13894,1,0,0,0,1825,13899,1,0,0,0,1827,13912,
	1,0,0,0,1829,13931,1,0,0,0,1831,13938,1,0,0,0,1833,13942,1,0,0,0,1835,13949,
	1,0,0,0,1837,13960,1,0,0,0,1839,13968,1,0,0,0,1841,13974,1,0,0,0,1843,13989,
	1,0,0,0,1845,13996,1,0,0,0,1847,14002,1,0,0,0,1849,14012,1,0,0,0,1851,14023,
	1,0,0,0,1853,14032,1,0,0,0,1855,14037,1,0,0,0,1857,14054,1,0,0,0,1859,14063,
	1,0,0,0,1861,14072,1,0,0,0,1863,14078,1,0,0,0,1865,14083,1,0,0,0,1867,14093,
	1,0,0,0,1869,14097,1,0,0,0,1871,14103,1,0,0,0,1873,14112,1,0,0,0,1875,14135,
	1,0,0,0,1877,14142,1,0,0,0,1879,14150,1,0,0,0,1881,14159,1,0,0,0,1883,14163,
	1,0,0,0,1885,14172,1,0,0,0,1887,14178,1,0,0,0,1889,14183,1,0,0,0,1891,14189,
	1,0,0,0,1893,14196,1,0,0,0,1895,14200,1,0,0,0,1897,14207,1,0,0,0,1899,14232,
	1,0,0,0,1901,14254,1,0,0,0,1903,14270,1,0,0,0,1905,14292,1,0,0,0,1907,14315,
	1,0,0,0,1909,14323,1,0,0,0,1911,14329,1,0,0,0,1913,14333,1,0,0,0,1915,14342,
	1,0,0,0,1917,14351,1,0,0,0,1919,14356,1,0,0,0,1921,14362,1,0,0,0,1923,14375,
	1,0,0,0,1925,14392,1,0,0,0,1927,14407,1,0,0,0,1929,14420,1,0,0,0,1931,14433,
	1,0,0,0,1933,14450,1,0,0,0,1935,14471,1,0,0,0,1937,14486,1,0,0,0,1939,14503,
	1,0,0,0,1941,14512,1,0,0,0,1943,14525,1,0,0,0,1945,14541,1,0,0,0,1947,14559,
	1,0,0,0,1949,14571,1,0,0,0,1951,14588,1,0,0,0,1953,14594,1,0,0,0,1955,14603,
	1,0,0,0,1957,14616,1,0,0,0,1959,14637,1,0,0,0,1961,14647,1,0,0,0,1963,14667,
	1,0,0,0,1965,14690,1,0,0,0,1967,14699,1,0,0,0,1969,14707,1,0,0,0,1971,14725,
	1,0,0,0,1973,14739,1,0,0,0,1975,14749,1,0,0,0,1977,14759,1,0,0,0,1979,14776,
	1,0,0,0,1981,14791,1,0,0,0,1983,14800,1,0,0,0,1985,14813,1,0,0,0,1987,14821,
	1,0,0,0,1989,14840,1,0,0,0,1991,14869,1,0,0,0,1993,14900,1,0,0,0,1995,14914,
	1,0,0,0,1997,14924,1,0,0,0,1999,14932,1,0,0,0,2001,14945,1,0,0,0,2003,14966,
	1,0,0,0,2005,14986,1,0,0,0,2007,15000,1,0,0,0,2009,15015,1,0,0,0,2011,15030,
	1,0,0,0,2013,15041,1,0,0,0,2015,15067,1,0,0,0,2017,15092,1,0,0,0,2019,15117,
	1,0,0,0,2021,15141,1,0,0,0,2023,15165,1,0,0,0,2025,15172,1,0,0,0,2027,15198,
	1,0,0,0,2029,15213,1,0,0,0,2031,15227,1,0,0,0,2033,15235,1,0,0,0,2035,15260,
	1,0,0,0,2037,15275,1,0,0,0,2039,15283,1,0,0,0,2041,15306,1,0,0,0,2043,15333,
	1,0,0,0,2045,15349,1,0,0,0,2047,15367,1,0,0,0,2049,15384,1,0,0,0,2051,15408,
	1,0,0,0,2053,15425,1,0,0,0,2055,15449,1,0,0,0,2057,15459,1,0,0,0,2059,15475,
	1,0,0,0,2061,15483,1,0,0,0,2063,15501,1,0,0,0,2065,15514,1,0,0,0,2067,15522,
	1,0,0,0,2069,15550,1,0,0,0,2071,15581,1,0,0,0,2073,15597,1,0,0,0,2075,15609,
	1,0,0,0,2077,15622,1,0,0,0,2079,15631,1,0,0,0,2081,15643,1,0,0,0,2083,15655,
	1,0,0,0,2085,15675,1,0,0,0,2087,15682,1,0,0,0,2089,15690,1,0,0,0,2091,15698,
	1,0,0,0,2093,15708,1,0,0,0,2095,15718,1,0,0,0,2097,15729,1,0,0,0,2099,15738,
	1,0,0,0,2101,15749,1,0,0,0,2103,15760,1,0,0,0,2105,15778,1,0,0,0,2107,15792,
	1,0,0,0,2109,15805,1,0,0,0,2111,15816,1,0,0,0,2113,15835,1,0,0,0,2115,15861,
	1,0,0,0,2117,15873,1,0,0,0,2119,15888,1,0,0,0,2121,15893,1,0,0,0,2123,15909,
	1,0,0,0,2125,15925,1,0,0,0,2127,15928,1,0,0,0,2129,15938,1,0,0,0,2131,15953,
	1,0,0,0,2133,15961,1,0,0,0,2135,15978,1,0,0,0,2137,16000,1,0,0,0,2139,16023,
	1,0,0,0,2141,16034,1,0,0,0,2143,16052,1,0,0,0,2145,16069,1,0,0,0,2147,16081,
	1,0,0,0,2149,16092,1,0,0,0,2151,16110,1,0,0,0,2153,16126,1,0,0,0,2155,16153,
	1,0,0,0,2157,16165,1,0,0,0,2159,16183,1,0,0,0,2161,16201,1,0,0,0,2163,16224,
	1,0,0,0,2165,16234,1,0,0,0,2167,16243,1,0,0,0,2169,16259,1,0,0,0,2171,16270,
	1,0,0,0,2173,16285,1,0,0,0,2175,16298,1,0,0,0,2177,16311,1,0,0,0,2179,16324,
	1,0,0,0,2181,16346,1,0,0,0,2183,16364,1,0,0,0,2185,16376,1,0,0,0,2187,16400,
	1,0,0,0,2189,16415,1,0,0,0,2191,16426,1,0,0,0,2193,16433,1,0,0,0,2195,16442,
	1,0,0,0,2197,16451,1,0,0,0,2199,16463,1,0,0,0,2201,16479,1,0,0,0,2203,16489,
	1,0,0,0,2205,16500,1,0,0,0,2207,16510,1,0,0,0,2209,16517,1,0,0,0,2211,16538,
	1,0,0,0,2213,16556,1,0,0,0,2215,16570,1,0,0,0,2217,16580,1,0,0,0,2219,16592,
	1,0,0,0,2221,16609,1,0,0,0,2223,16624,1,0,0,0,2225,16631,1,0,0,0,2227,16650,
	1,0,0,0,2229,16662,1,0,0,0,2231,16685,1,0,0,0,2233,16706,1,0,0,0,2235,16721,
	1,0,0,0,2237,16730,1,0,0,0,2239,16750,1,0,0,0,2241,16765,1,0,0,0,2243,16785,
	1,0,0,0,2245,16794,1,0,0,0,2247,16816,1,0,0,0,2249,16830,1,0,0,0,2251,16838,
	1,0,0,0,2253,16851,1,0,0,0,2255,16855,1,0,0,0,2257,16881,1,0,0,0,2259,16891,
	1,0,0,0,2261,16903,1,0,0,0,2263,16927,1,0,0,0,2265,16956,1,0,0,0,2267,16968,
	1,0,0,0,2269,16993,1,0,0,0,2271,17006,1,0,0,0,2273,17016,1,0,0,0,2275,17042,
	1,0,0,0,2277,17053,1,0,0,0,2279,17078,1,0,0,0,2281,17103,1,0,0,0,2283,17123,
	1,0,0,0,2285,17130,1,0,0,0,2287,17153,1,0,0,0,2289,17172,1,0,0,0,2291,17202,
	1,0,0,0,2293,17222,1,0,0,0,2295,17243,1,0,0,0,2297,17254,1,0,0,0,2299,17264,
	1,0,0,0,2301,17271,1,0,0,0,2303,17276,1,0,0,0,2305,17282,1,0,0,0,2307,17289,
	1,0,0,0,2309,17297,1,0,0,0,2311,17312,1,0,0,0,2313,17328,1,0,0,0,2315,17344,
	1,0,0,0,2317,17354,1,0,0,0,2319,17359,1,0,0,0,2321,17370,1,0,0,0,2323,17377,
	1,0,0,0,2325,17384,1,0,0,0,2327,17390,1,0,0,0,2329,17402,1,0,0,0,2331,17413,
	1,0,0,0,2333,17421,1,0,0,0,2335,17425,1,0,0,0,2337,17432,1,0,0,0,2339,17435,
	1,0,0,0,2341,17444,1,0,0,0,2343,17448,1,0,0,0,2345,17453,1,0,0,0,2347,17457,
	1,0,0,0,2349,17471,1,0,0,0,2351,17475,1,0,0,0,2353,17480,1,0,0,0,2355,17485,
	1,0,0,0,2357,17489,1,0,0,0,2359,17496,1,0,0,0,2361,17506,1,0,0,0,2363,17511,
	1,0,0,0,2365,17514,1,0,0,0,2367,17521,1,0,0,0,2369,17538,1,0,0,0,2371,17556,
	1,0,0,0,2373,17563,1,0,0,0,2375,17568,1,0,0,0,2377,17579,1,0,0,0,2379,17588,
	1,0,0,0,2381,17601,1,0,0,0,2383,17609,1,0,0,0,2385,17618,1,0,0,0,2387,17644,
	1,0,0,0,2389,17659,1,0,0,0,2391,17666,1,0,0,0,2393,17676,1,0,0,0,2395,17687,
	1,0,0,0,2397,17701,1,0,0,0,2399,17721,1,0,0,0,2401,17736,1,0,0,0,2403,17744,
	1,0,0,0,2405,17753,1,0,0,0,2407,17770,1,0,0,0,2409,17786,1,0,0,0,2411,17800,
	1,0,0,0,2413,17815,1,0,0,0,2415,17834,1,0,0,0,2417,17843,1,0,0,0,2419,17863,
	1,0,0,0,2421,17881,1,0,0,0,2423,17907,1,0,0,0,2425,17928,1,0,0,0,2427,17943,
	1,0,0,0,2429,17957,1,0,0,0,2431,17968,1,0,0,0,2433,17983,1,0,0,0,2435,17998,
	1,0,0,0,2437,18013,1,0,0,0,2439,18028,1,0,0,0,2441,18036,1,0,0,0,2443,18055,
	1,0,0,0,2445,18061,1,0,0,0,2447,18072,1,0,0,0,2449,18082,1,0,0,0,2451,18095,
	1,0,0,0,2453,18098,1,0,0,0,2455,18112,1,0,0,0,2457,18120,1,0,0,0,2459,18126,
	1,0,0,0,2461,18145,1,0,0,0,2463,18165,1,0,0,0,2465,18171,1,0,0,0,2467,18184,
	1,0,0,0,2469,18192,1,0,0,0,2471,18204,1,0,0,0,2473,18208,1,0,0,0,2475,18224,
	1,0,0,0,2477,18233,1,0,0,0,2479,18242,1,0,0,0,2481,18247,1,0,0,0,2483,18258,
	1,0,0,0,2485,18264,1,0,0,0,2487,18274,1,0,0,0,2489,18278,1,0,0,0,2491,18280,
	1,0,0,0,2493,18288,1,0,0,0,2495,18297,1,0,0,0,2497,18313,1,0,0,0,2499,18328,
	1,0,0,0,2501,18337,1,0,0,0,2503,18351,1,0,0,0,2505,18362,1,0,0,0,2507,18368,
	1,0,0,0,2509,18375,1,0,0,0,2511,18393,1,0,0,0,2513,18412,1,0,0,0,2515,18419,
	1,0,0,0,2517,18432,1,0,0,0,2519,18442,1,0,0,0,2521,18450,1,0,0,0,2523,18474,
	1,0,0,0,2525,18489,1,0,0,0,2527,18504,1,0,0,0,2529,18514,1,0,0,0,2531,18530,
	1,0,0,0,2533,18541,1,0,0,0,2535,18555,1,0,0,0,2537,18563,1,0,0,0,2539,18583,
	1,0,0,0,2541,18602,1,0,0,0,2543,18621,1,0,0,0,2545,18630,1,0,0,0,2547,18649,
	1,0,0,0,2549,18669,1,0,0,0,2551,18692,1,0,0,0,2553,18717,1,0,0,0,2555,18722,
	1,0,0,0,2557,18728,1,0,0,0,2559,18733,1,0,0,0,2561,18745,1,0,0,0,2563,18751,
	1,0,0,0,2565,18759,1,0,0,0,2567,18772,1,0,0,0,2569,18783,1,0,0,0,2571,18791,
	1,0,0,0,2573,18803,1,0,0,0,2575,18816,1,0,0,0,2577,18824,1,0,0,0,2579,18835,
	1,0,0,0,2581,18843,1,0,0,0,2583,18856,1,0,0,0,2585,18870,1,0,0,0,2587,18886,
	1,0,0,0,2589,18894,1,0,0,0,2591,18908,1,0,0,0,2593,18924,1,0,0,0,2595,18939,
	1,0,0,0,2597,18951,1,0,0,0,2599,18963,1,0,0,0,2601,18970,1,0,0,0,2603,18980,
	1,0,0,0,2605,18991,1,0,0,0,2607,18999,1,0,0,0,2609,19003,1,0,0,0,2611,19009,
	1,0,0,0,2613,19018,1,0,0,0,2615,19024,1,0,0,0,2617,19034,1,0,0,0,2619,19039,
	1,0,0,0,2621,19046,1,0,0,0,2623,19052,1,0,0,0,2625,19060,1,0,0,0,2627,19075,
	1,0,0,0,2629,19090,1,0,0,0,2631,19095,1,0,0,0,2633,19112,1,0,0,0,2635,19124,
	1,0,0,0,2637,19138,1,0,0,0,2639,19154,1,0,0,0,2641,19166,1,0,0,0,2643,19187,
	1,0,0,0,2645,19202,1,0,0,0,2647,19212,1,0,0,0,2649,19217,1,0,0,0,2651,19223,
	1,0,0,0,2653,19230,1,0,0,0,2655,19239,1,0,0,0,2657,19247,1,0,0,0,2659,19256,
	1,0,0,0,2661,19264,1,0,0,0,2663,19272,1,0,0,0,2665,19282,1,0,0,0,2667,19291,
	1,0,0,0,2669,19308,1,0,0,0,2671,19337,1,0,0,0,2673,19351,1,0,0,0,2675,19357,
	1,0,0,0,2677,19377,1,0,0,0,2679,19391,1,0,0,0,2681,19412,1,0,0,0,2683,19422,
	1,0,0,0,2685,19429,1,0,0,0,2687,19438,1,0,0,0,2689,19451,1,0,0,0,2691,19459,
	1,0,0,0,2693,19466,1,0,0,0,2695,19475,1,0,0,0,2697,19484,1,0,0,0,2699,19494,
	1,0,0,0,2701,19504,1,0,0,0,2703,19524,1,0,0,0,2705,19543,1,0,0,0,2707,19551,
	1,0,0,0,2709,19559,1,0,0,0,2711,19570,1,0,0,0,2713,19578,1,0,0,0,2715,19587,
	1,0,0,0,2717,19600,1,0,0,0,2719,19609,1,0,0,0,2721,19616,1,0,0,0,2723,19625,
	1,0,0,0,2725,19630,1,0,0,0,2727,19638,1,0,0,0,2729,19654,1,0,0,0,2731,19663,
	1,0,0,0,2733,19669,1,0,0,0,2735,19677,1,0,0,0,2737,19689,1,0,0,0,2739,19700,
	1,0,0,0,2741,19710,1,0,0,0,2743,19721,1,0,0,0,2745,19732,1,0,0,0,2747,19742,
	1,0,0,0,2749,19750,1,0,0,0,2751,19758,1,0,0,0,2753,19766,1,0,0,0,2755,19774,
	1,0,0,0,2757,19784,1,0,0,0,2759,19793,1,0,0,0,2761,19803,1,0,0,0,2763,19814,
	1,0,0,0,2765,19823,1,0,0,0,2767,19829,1,0,0,0,2769,19837,1,0,0,0,2771,19844,
	1,0,0,0,2773,19854,1,0,0,0,2775,19860,1,0,0,0,2777,19870,1,0,0,0,2779,19880,
	1,0,0,0,2781,19899,1,0,0,0,2783,19910,1,0,0,0,2785,19925,1,0,0,0,2787,19933,
	1,0,0,0,2789,19942,1,0,0,0,2791,19954,1,0,0,0,2793,19960,1,0,0,0,2795,19971,
	1,0,0,0,2797,19977,1,0,0,0,2799,19988,1,0,0,0,2801,19996,1,0,0,0,2803,20003,
	1,0,0,0,2805,20009,1,0,0,0,2807,20020,1,0,0,0,2809,20026,1,0,0,0,2811,20039,
	1,0,0,0,2813,20046,1,0,0,0,2815,20052,1,0,0,0,2817,20058,1,0,0,0,2819,20066,
	1,0,0,0,2821,20070,1,0,0,0,2823,20079,1,0,0,0,2825,20089,1,0,0,0,2827,20093,
	1,0,0,0,2829,20105,1,0,0,0,2831,20110,1,0,0,0,2833,20115,1,0,0,0,2835,20121,
	1,0,0,0,2837,20127,1,0,0,0,2839,20132,1,0,0,0,2841,20142,1,0,0,0,2843,20150,
	1,0,0,0,2845,20157,1,0,0,0,2847,20175,1,0,0,0,2849,20187,1,0,0,0,2851,20195,
	1,0,0,0,2853,20204,1,0,0,0,2855,20215,1,0,0,0,2857,20223,1,0,0,0,2859,20233,
	1,0,0,0,2861,20242,1,0,0,0,2863,20247,1,0,0,0,2865,20255,1,0,0,0,2867,20266,
	1,0,0,0,2869,20285,1,0,0,0,2871,20296,1,0,0,0,2873,20306,1,0,0,0,2875,20317,
	1,0,0,0,2877,20329,1,0,0,0,2879,20333,1,0,0,0,2881,20341,1,0,0,0,2883,20350,
	1,0,0,0,2885,20363,1,0,0,0,2887,20376,1,0,0,0,2889,20388,1,0,0,0,2891,20403,
	1,0,0,0,2893,20417,1,0,0,0,2895,20426,1,0,0,0,2897,20436,1,0,0,0,2899,20446,
	1,0,0,0,2901,20457,1,0,0,0,2903,20472,1,0,0,0,2905,20480,1,0,0,0,2907,20491,
	1,0,0,0,2909,20500,1,0,0,0,2911,20509,1,0,0,0,2913,20518,1,0,0,0,2915,20526,
	1,0,0,0,2917,20533,1,0,0,0,2919,20539,1,0,0,0,2921,20550,1,0,0,0,2923,20560,
	1,0,0,0,2925,20569,1,0,0,0,2927,20574,1,0,0,0,2929,20584,1,0,0,0,2931,20591,
	1,0,0,0,2933,20605,1,0,0,0,2935,20612,1,0,0,0,2937,20619,1,0,0,0,2939,20626,
	1,0,0,0,2941,20633,1,0,0,0,2943,20641,1,0,0,0,2945,20653,1,0,0,0,2947,20662,
	1,0,0,0,2949,20672,1,0,0,0,2951,20678,1,0,0,0,2953,20685,1,0,0,0,2955,20693,
	1,0,0,0,2957,20702,1,0,0,0,2959,20711,1,0,0,0,2961,20719,1,0,0,0,2963,20727,
	1,0,0,0,2965,20748,1,0,0,0,2967,20756,1,0,0,0,2969,20778,1,0,0,0,2971,20789,
	1,0,0,0,2973,20809,1,0,0,0,2975,20818,1,0,0,0,2977,20831,1,0,0,0,2979,20838,
	1,0,0,0,2981,20848,1,0,0,0,2983,20855,1,0,0,0,2985,20865,1,0,0,0,2987,20885,
	1,0,0,0,2989,20895,1,0,0,0,2991,20902,1,0,0,0,2993,20908,1,0,0,0,2995,20916,
	1,0,0,0,2997,20923,1,0,0,0,2999,20940,1,0,0,0,3001,20948,1,0,0,0,3003,20954,
	1,0,0,0,3005,20959,1,0,0,0,3007,20967,1,0,0,0,3009,20973,1,0,0,0,3011,20982,
	1,0,0,0,3013,20990,1,0,0,0,3015,20997,1,0,0,0,3017,21013,1,0,0,0,3019,21033,
	1,0,0,0,3021,21039,1,0,0,0,3023,21051,1,0,0,0,3025,21064,1,0,0,0,3027,21075,
	1,0,0,0,3029,21082,1,0,0,0,3031,21086,1,0,0,0,3033,21091,1,0,0,0,3035,21096,
	1,0,0,0,3037,21102,1,0,0,0,3039,21107,1,0,0,0,3041,21113,1,0,0,0,3043,21121,
	1,0,0,0,3045,21126,1,0,0,0,3047,21133,1,0,0,0,3049,21151,1,0,0,0,3051,21161,
	1,0,0,0,3053,21166,1,0,0,0,3055,21170,1,0,0,0,3057,21181,1,0,0,0,3059,21187,
	1,0,0,0,3061,21202,1,0,0,0,3063,21207,1,0,0,0,3065,21217,1,0,0,0,3067,21229,
	1,0,0,0,3069,21236,1,0,0,0,3071,21250,1,0,0,0,3073,21254,1,0,0,0,3075,21260,
	1,0,0,0,3077,21266,1,0,0,0,3079,21273,1,0,0,0,3081,21284,1,0,0,0,3083,21297,
	1,0,0,0,3085,21310,1,0,0,0,3087,21318,1,0,0,0,3089,21325,1,0,0,0,3091,21332,
	1,0,0,0,3093,21340,1,0,0,0,3095,21347,1,0,0,0,3097,21362,1,0,0,0,3099,21373,
	1,0,0,0,3101,21382,1,0,0,0,3103,21387,1,0,0,0,3105,21397,1,0,0,0,3107,21406,
	1,0,0,0,3109,21414,1,0,0,0,3111,21426,1,0,0,0,3113,21433,1,0,0,0,3115,21438,
	1,0,0,0,3117,21454,1,0,0,0,3119,21463,1,0,0,0,3121,21477,1,0,0,0,3123,21487,
	1,0,0,0,3125,21496,1,0,0,0,3127,21507,1,0,0,0,3129,21511,1,0,0,0,3131,21524,
	1,0,0,0,3133,21542,1,0,0,0,3135,21549,1,0,0,0,3137,21561,1,0,0,0,3139,21582,
	1,0,0,0,3141,21590,1,0,0,0,3143,21599,1,0,0,0,3145,21622,1,0,0,0,3147,21630,
	1,0,0,0,3149,21648,1,0,0,0,3151,21664,1,0,0,0,3153,21678,1,0,0,0,3155,21682,
	1,0,0,0,3157,21687,1,0,0,0,3159,21696,1,0,0,0,3161,21708,1,0,0,0,3163,21715,
	1,0,0,0,3165,21726,1,0,0,0,3167,21738,1,0,0,0,3169,21745,1,0,0,0,3171,21751,
	1,0,0,0,3173,21759,1,0,0,0,3175,21769,1,0,0,0,3177,21774,1,0,0,0,3179,21781,
	1,0,0,0,3181,21790,1,0,0,0,3183,21799,1,0,0,0,3185,21803,1,0,0,0,3187,21808,
	1,0,0,0,3189,21825,1,0,0,0,3191,21841,1,0,0,0,3193,21846,1,0,0,0,3195,21855,
	1,0,0,0,3197,21870,1,0,0,0,3199,21877,1,0,0,0,3201,21884,1,0,0,0,3203,21895,
	1,0,0,0,3205,21900,1,0,0,0,3207,21904,1,0,0,0,3209,21909,1,0,0,0,3211,21928,
	1,0,0,0,3213,21933,1,0,0,0,3215,21955,1,0,0,0,3217,21977,1,0,0,0,3219,21987,
	1,0,0,0,3221,21996,1,0,0,0,3223,22005,1,0,0,0,3225,22010,1,0,0,0,3227,22015,
	1,0,0,0,3229,22023,1,0,0,0,3231,22045,1,0,0,0,3233,22070,1,0,0,0,3235,22077,
	1,0,0,0,3237,22083,1,0,0,0,3239,22097,1,0,0,0,3241,22104,1,0,0,0,3243,22110,
	1,0,0,0,3245,22122,1,0,0,0,3247,22130,1,0,0,0,3249,22139,1,0,0,0,3251,22146,
	1,0,0,0,3253,22156,1,0,0,0,3255,22158,1,0,0,0,3257,22168,1,0,0,0,3259,22178,
	1,0,0,0,3261,22202,1,0,0,0,3263,22207,1,0,0,0,3265,22213,1,0,0,0,3267,22224,
	1,0,0,0,3269,22233,1,0,0,0,3271,22247,1,0,0,0,3273,22270,1,0,0,0,3275,22279,
	1,0,0,0,3277,22287,1,0,0,0,3279,22292,1,0,0,0,3281,22312,1,0,0,0,3283,22318,
	1,0,0,0,3285,22326,1,0,0,0,3287,22339,1,0,0,0,3289,22357,1,0,0,0,3291,22368,
	1,0,0,0,3293,22378,1,0,0,0,3295,22384,1,0,0,0,3297,22391,1,0,0,0,3299,22402,
	1,0,0,0,3301,22422,1,0,0,0,3303,22437,1,0,0,0,3305,22450,1,0,0,0,3307,22464,
	1,0,0,0,3309,22475,1,0,0,0,3311,22489,1,0,0,0,3313,22509,1,0,0,0,3315,22528,
	1,0,0,0,3317,22548,1,0,0,0,3319,22565,1,0,0,0,3321,22585,1,0,0,0,3323,22600,
	1,0,0,0,3325,22611,1,0,0,0,3327,22623,1,0,0,0,3329,22628,1,0,0,0,3331,22636,
	1,0,0,0,3333,22642,1,0,0,0,3335,22650,1,0,0,0,3337,22657,1,0,0,0,3339,22664,
	1,0,0,0,3341,22671,1,0,0,0,3343,22686,1,0,0,0,3345,22699,1,0,0,0,3347,22705,
	1,0,0,0,3349,22715,1,0,0,0,3351,22727,1,0,0,0,3353,22744,1,0,0,0,3355,22758,
	1,0,0,0,3357,22771,1,0,0,0,3359,22782,1,0,0,0,3361,22799,1,0,0,0,3363,22809,
	1,0,0,0,3365,22816,1,0,0,0,3367,22830,1,0,0,0,3369,22838,1,0,0,0,3371,22846,
	1,0,0,0,3373,22854,1,0,0,0,3375,22862,1,0,0,0,3377,22870,1,0,0,0,3379,22881,
	1,0,0,0,3381,22889,1,0,0,0,3383,22897,1,0,0,0,3385,22910,1,0,0,0,3387,22918,
	1,0,0,0,3389,22935,1,0,0,0,3391,22946,1,0,0,0,3393,22953,1,0,0,0,3395,22965,
	1,0,0,0,3397,22970,1,0,0,0,3399,22978,1,0,0,0,3401,22982,1,0,0,0,3403,22989,
	1,0,0,0,3405,22999,1,0,0,0,3407,23006,1,0,0,0,3409,23016,1,0,0,0,3411,23029,
	1,0,0,0,3413,23049,1,0,0,0,3415,23069,1,0,0,0,3417,23081,1,0,0,0,3419,23089,
	1,0,0,0,3421,23096,1,0,0,0,3423,23109,1,0,0,0,3425,23115,1,0,0,0,3427,23129,
	1,0,0,0,3429,23147,1,0,0,0,3431,23165,1,0,0,0,3433,23181,1,0,0,0,3435,23198,
	1,0,0,0,3437,23215,1,0,0,0,3439,23235,1,0,0,0,3441,23256,1,0,0,0,3443,23277,
	1,0,0,0,3445,23297,1,0,0,0,3447,23310,1,0,0,0,3449,23326,1,0,0,0,3451,23341,
	1,0,0,0,3453,23357,1,0,0,0,3455,23371,1,0,0,0,3457,23385,1,0,0,0,3459,23400,
	1,0,0,0,3461,23419,1,0,0,0,3463,23438,1,0,0,0,3465,23453,1,0,0,0,3467,23467,
	1,0,0,0,3469,23476,1,0,0,0,3471,23484,1,0,0,0,3473,23490,1,0,0,0,3475,23509,
	1,0,0,0,3477,23521,1,0,0,0,3479,23535,1,0,0,0,3481,23545,1,0,0,0,3483,23552,
	1,0,0,0,3485,23567,1,0,0,0,3487,23582,1,0,0,0,3489,23598,1,0,0,0,3491,23609,
	1,0,0,0,3493,23624,1,0,0,0,3495,23638,1,0,0,0,3497,23650,1,0,0,0,3499,23675,
	1,0,0,0,3501,23695,1,0,0,0,3503,23706,1,0,0,0,3505,23718,1,0,0,0,3507,23729,
	1,0,0,0,3509,23741,1,0,0,0,3511,23762,1,0,0,0,3513,23774,1,0,0,0,3515,23789,
	1,0,0,0,3517,23805,1,0,0,0,3519,23819,1,0,0,0,3521,23837,1,0,0,0,3523,23848,
	1,0,0,0,3525,23865,1,0,0,0,3527,23883,1,0,0,0,3529,23897,1,0,0,0,3531,23912,
	1,0,0,0,3533,23928,1,0,0,0,3535,23939,1,0,0,0,3537,23951,1,0,0,0,3539,23967,
	1,0,0,0,3541,23992,1,0,0,0,3543,24000,1,0,0,0,3545,24015,1,0,0,0,3547,24031,
	1,0,0,0,3549,24043,1,0,0,0,3551,24055,1,0,0,0,3553,24066,1,0,0,0,3555,24077,
	1,0,0,0,3557,24102,1,0,0,0,3559,24132,1,0,0,0,3561,24157,1,0,0,0,3563,24185,
	1,0,0,0,3565,24207,1,0,0,0,3567,24219,1,0,0,0,3569,24238,1,0,0,0,3571,24257,
	1,0,0,0,3573,24274,1,0,0,0,3575,24294,1,0,0,0,3577,24312,1,0,0,0,3579,24324,
	1,0,0,0,3581,24335,1,0,0,0,3583,24350,1,0,0,0,3585,24369,1,0,0,0,3587,24380,
	1,0,0,0,3589,24397,1,0,0,0,3591,24414,1,0,0,0,3593,24425,1,0,0,0,3595,24436,
	1,0,0,0,3597,24452,1,0,0,0,3599,24467,1,0,0,0,3601,24483,1,0,0,0,3603,24499,
	1,0,0,0,3605,24514,1,0,0,0,3607,24527,1,0,0,0,3609,24543,1,0,0,0,3611,24557,
	1,0,0,0,3613,24568,1,0,0,0,3615,24583,1,0,0,0,3617,24596,1,0,0,0,3619,24607,
	1,0,0,0,3621,24623,1,0,0,0,3623,24635,1,0,0,0,3625,24646,1,0,0,0,3627,24658,
	1,0,0,0,3629,24676,1,0,0,0,3631,24687,1,0,0,0,3633,24703,1,0,0,0,3635,24718,
	1,0,0,0,3637,24729,1,0,0,0,3639,24741,1,0,0,0,3641,24754,1,0,0,0,3643,24772,
	1,0,0,0,3645,24789,1,0,0,0,3647,24803,1,0,0,0,3649,24817,1,0,0,0,3651,24830,
	1,0,0,0,3653,24844,1,0,0,0,3655,24859,1,0,0,0,3657,24871,1,0,0,0,3659,24882,
	1,0,0,0,3661,24905,1,0,0,0,3663,24920,1,0,0,0,3665,24935,1,0,0,0,3667,24949,
	1,0,0,0,3669,24966,1,0,0,0,3671,24981,1,0,0,0,3673,25000,1,0,0,0,3675,25022,
	1,0,0,0,3677,25042,1,0,0,0,3679,25059,1,0,0,0,3681,25078,1,0,0,0,3683,25098,
	1,0,0,0,3685,25118,1,0,0,0,3687,25139,1,0,0,0,3689,25156,1,0,0,0,3691,25171,
	1,0,0,0,3693,25187,1,0,0,0,3695,25201,1,0,0,0,3697,25215,1,0,0,0,3699,25229,
	1,0,0,0,3701,25244,1,0,0,0,3703,25259,1,0,0,0,3705,25266,1,0,0,0,3707,25279,
	1,0,0,0,3709,25290,1,0,0,0,3711,25303,1,0,0,0,3713,25318,1,0,0,0,3715,25334,
	1,0,0,0,3717,25346,1,0,0,0,3719,25361,1,0,0,0,3721,25372,1,0,0,0,3723,25392,
	1,0,0,0,3725,25412,1,0,0,0,3727,25425,1,0,0,0,3729,25448,1,0,0,0,3731,25469,
	1,0,0,0,3733,25494,1,0,0,0,3735,25516,1,0,0,0,3737,25540,1,0,0,0,3739,25563,
	1,0,0,0,3741,25584,1,0,0,0,3743,25606,1,0,0,0,3745,25627,1,0,0,0,3747,25641,
	1,0,0,0,3749,25655,1,0,0,0,3751,25672,1,0,0,0,3753,25688,1,0,0,0,3755,25703,
	1,0,0,0,3757,25721,1,0,0,0,3759,25735,1,0,0,0,3761,25754,1,0,0,0,3763,25770,
	1,0,0,0,3765,25784,1,0,0,0,3767,25798,1,0,0,0,3769,25811,1,0,0,0,3771,25824,
	1,0,0,0,3773,25843,1,0,0,0,3775,25853,1,0,0,0,3777,25866,1,0,0,0,3779,25879,
	1,0,0,0,3781,25892,1,0,0,0,3783,25907,1,0,0,0,3785,25918,1,0,0,0,3787,25928,
	1,0,0,0,3789,25944,1,0,0,0,3791,25959,1,0,0,0,3793,25973,1,0,0,0,3795,25987,
	1,0,0,0,3797,26001,1,0,0,0,3799,26013,1,0,0,0,3801,26026,1,0,0,0,3803,26039,
	1,0,0,0,3805,26051,1,0,0,0,3807,26065,1,0,0,0,3809,26079,1,0,0,0,3811,26090,
	1,0,0,0,3813,26104,1,0,0,0,3815,26122,1,0,0,0,3817,26134,1,0,0,0,3819,26147,
	1,0,0,0,3821,26163,1,0,0,0,3823,26179,1,0,0,0,3825,26191,1,0,0,0,3827,26208,
	1,0,0,0,3829,26221,1,0,0,0,3831,26234,1,0,0,0,3833,26247,1,0,0,0,3835,26261,
	1,0,0,0,3837,26272,1,0,0,0,3839,26289,1,0,0,0,3841,26305,1,0,0,0,3843,26318,
	1,0,0,0,3845,26333,1,0,0,0,3847,26349,1,0,0,0,3849,26365,1,0,0,0,3851,26377,
	1,0,0,0,3853,26391,1,0,0,0,3855,26407,1,0,0,0,3857,26423,1,0,0,0,3859,26440,
	1,0,0,0,3861,26454,1,0,0,0,3863,26468,1,0,0,0,3865,26482,1,0,0,0,3867,26498,
	1,0,0,0,3869,26512,1,0,0,0,3871,26526,1,0,0,0,3873,26540,1,0,0,0,3875,26554,
	1,0,0,0,3877,26568,1,0,0,0,3879,26582,1,0,0,0,3881,26597,1,0,0,0,3883,26611,
	1,0,0,0,3885,26626,1,0,0,0,3887,26641,1,0,0,0,3889,26655,1,0,0,0,3891,26669,
	1,0,0,0,3893,26683,1,0,0,0,3895,26697,1,0,0,0,3897,26711,1,0,0,0,3899,26725,
	1,0,0,0,3901,26739,1,0,0,0,3903,26752,1,0,0,0,3905,26766,1,0,0,0,3907,26780,
	1,0,0,0,3909,26798,1,0,0,0,3911,26812,1,0,0,0,3913,26828,1,0,0,0,3915,26843,
	1,0,0,0,3917,26855,1,0,0,0,3919,26872,1,0,0,0,3921,26886,1,0,0,0,3923,26902,
	1,0,0,0,3925,26916,1,0,0,0,3927,26928,1,0,0,0,3929,26941,1,0,0,0,3931,26954,
	1,0,0,0,3933,26972,1,0,0,0,3935,26989,1,0,0,0,3937,27004,1,0,0,0,3939,27020,
	1,0,0,0,3941,27034,1,0,0,0,3943,27050,1,0,0,0,3945,27066,1,0,0,0,3947,27081,
	1,0,0,0,3949,27095,1,0,0,0,3951,27113,1,0,0,0,3953,27125,1,0,0,0,3955,27141,
	1,0,0,0,3957,27157,1,0,0,0,3959,27174,1,0,0,0,3961,27176,1,0,0,0,3963,27195,
	1,0,0,0,3965,27209,1,0,0,0,3967,27220,1,0,0,0,3969,27227,1,0,0,0,3971,27239,
	1,0,0,0,3973,27245,1,0,0,0,3975,27251,1,0,0,0,3977,27255,1,0,0,0,3979,27260,
	1,0,0,0,3981,27264,1,0,0,0,3983,27284,1,0,0,0,3985,27293,1,0,0,0,3987,27302,
	1,0,0,0,3989,27312,1,0,0,0,3991,27323,1,0,0,0,3993,27328,1,0,0,0,3995,27333,
	1,0,0,0,3997,27338,1,0,0,0,3999,27343,1,0,0,0,4001,27347,1,0,0,0,4003,27354,
	1,0,0,0,4005,27362,1,0,0,0,4007,27367,1,0,0,0,4009,27372,1,0,0,0,4011,27380,
	1,0,0,0,4013,27408,1,0,0,0,4015,27418,1,0,0,0,4017,27445,1,0,0,0,4019,27469,
	1,0,0,0,4021,27475,1,0,0,0,4023,27480,1,0,0,0,4025,27489,1,0,0,0,4027,27503,
	1,0,0,0,4029,27517,1,0,0,0,4031,27533,1,0,0,0,4033,27549,1,0,0,0,4035,27565,
	1,0,0,0,4037,27575,1,0,0,0,4039,27582,1,0,0,0,4041,27589,1,0,0,0,4043,27597,
	1,0,0,0,4045,27606,1,0,0,0,4047,27623,1,0,0,0,4049,27639,1,0,0,0,4051,27647,
	1,0,0,0,4053,27655,1,0,0,0,4055,27669,1,0,0,0,4057,27676,1,0,0,0,4059,27690,
	1,0,0,0,4061,27699,1,0,0,0,4063,27708,1,0,0,0,4065,27718,1,0,0,0,4067,27727,
	1,0,0,0,4069,27742,1,0,0,0,4071,27755,1,0,0,0,4073,27771,1,0,0,0,4075,27779,
	1,0,0,0,4077,27790,1,0,0,0,4079,27793,1,0,0,0,4081,27807,1,0,0,0,4083,27813,
	1,0,0,0,4085,27821,1,0,0,0,4087,27830,1,0,0,0,4089,27839,1,0,0,0,4091,27851,
	1,0,0,0,4093,27861,1,0,0,0,4095,27884,1,0,0,0,4097,27897,1,0,0,0,4099,27908,
	1,0,0,0,4101,27918,1,0,0,0,4103,27930,1,0,0,0,4105,27936,1,0,0,0,4107,27945,
	1,0,0,0,4109,27953,1,0,0,0,4111,27958,1,0,0,0,4113,27967,1,0,0,0,4115,27973,
	1,0,0,0,4117,27981,1,0,0,0,4119,27987,1,0,0,0,4121,27994,1,0,0,0,4123,27997,
	1,0,0,0,4125,28003,1,0,0,0,4127,28008,1,0,0,0,4129,28018,1,0,0,0,4131,28022,
	1,0,0,0,4133,28026,1,0,0,0,4135,28031,1,0,0,0,4137,28035,1,0,0,0,4139,28046,
	1,0,0,0,4141,28056,1,0,0,0,4143,28064,1,0,0,0,4145,28078,1,0,0,0,4147,28084,
	1,0,0,0,4149,28089,1,0,0,0,4151,28096,1,0,0,0,4153,28104,1,0,0,0,4155,28110,
	1,0,0,0,4157,28117,1,0,0,0,4159,28124,1,0,0,0,4161,28134,1,0,0,0,4163,28141,
	1,0,0,0,4165,28148,1,0,0,0,4167,28158,1,0,0,0,4169,28186,1,0,0,0,4171,28216,
	1,0,0,0,4173,28234,1,0,0,0,4175,28241,1,0,0,0,4177,28250,1,0,0,0,4179,28258,
	1,0,0,0,4181,28265,1,0,0,0,4183,28277,1,0,0,0,4185,28287,1,0,0,0,4187,28301,
	1,0,0,0,4189,28314,1,0,0,0,4191,28326,1,0,0,0,4193,28332,1,0,0,0,4195,28341,
	1,0,0,0,4197,28348,1,0,0,0,4199,28358,1,0,0,0,4201,28366,1,0,0,0,4203,28373,
	1,0,0,0,4205,28383,1,0,0,0,4207,28395,1,0,0,0,4209,28409,1,0,0,0,4211,28417,
	1,0,0,0,4213,28423,1,0,0,0,4215,28430,1,0,0,0,4217,28437,1,0,0,0,4219,28444,
	1,0,0,0,4221,28450,1,0,0,0,4223,28459,1,0,0,0,4225,28470,1,0,0,0,4227,28479,
	1,0,0,0,4229,28500,1,0,0,0,4231,28526,1,0,0,0,4233,28535,1,0,0,0,4235,28557,
	1,0,0,0,4237,28579,1,0,0,0,4239,28599,1,0,0,0,4241,28609,1,0,0,0,4243,28616,
	1,0,0,0,4245,28634,1,0,0,0,4247,28655,1,0,0,0,4249,28665,1,0,0,0,4251,28678,
	1,0,0,0,4253,28686,1,0,0,0,4255,28696,1,0,0,0,4257,28712,1,0,0,0,4259,28718,
	1,0,0,0,4261,28735,1,0,0,0,4263,28740,1,0,0,0,4265,28749,1,0,0,0,4267,28769,
	1,0,0,0,4269,28787,1,0,0,0,4271,28791,1,0,0,0,4273,28814,1,0,0,0,4275,28833,
	1,0,0,0,4277,28849,1,0,0,0,4279,28855,1,0,0,0,4281,28863,1,0,0,0,4283,28871,
	1,0,0,0,4285,28877,1,0,0,0,4287,28882,1,0,0,0,4289,28885,1,0,0,0,4291,28888,
	1,0,0,0,4293,28897,1,0,0,0,4295,28917,1,0,0,0,4297,28928,1,0,0,0,4299,28943,
	1,0,0,0,4301,28950,1,0,0,0,4303,28956,1,0,0,0,4305,28965,1,0,0,0,4307,28973,
	1,0,0,0,4309,28982,1,0,0,0,4311,28990,1,0,0,0,4313,28998,1,0,0,0,4315,29005,
	1,0,0,0,4317,29014,1,0,0,0,4319,29022,1,0,0,0,4321,29040,1,0,0,0,4323,29052,
	1,0,0,0,4325,29074,1,0,0,0,4327,29096,1,0,0,0,4329,29113,1,0,0,0,4331,29122,
	1,0,0,0,4333,29129,1,0,0,0,4335,29140,1,0,0,0,4337,29156,1,0,0,0,4339,29173,
	1,0,0,0,4341,29192,1,0,0,0,4343,29210,1,0,0,0,4345,29229,1,0,0,0,4347,29238,
	1,0,0,0,4349,29251,1,0,0,0,4351,29259,1,0,0,0,4353,29264,1,0,0,0,4355,29274,
	1,0,0,0,4357,29282,1,0,0,0,4359,29293,1,0,0,0,4361,29301,1,0,0,0,4363,29308,
	1,0,0,0,4365,29314,1,0,0,0,4367,29319,1,0,0,0,4369,29326,1,0,0,0,4371,29334,
	1,0,0,0,4373,29340,1,0,0,0,4375,29345,1,0,0,0,4377,29356,1,0,0,0,4379,29365,
	1,0,0,0,4381,29370,1,0,0,0,4383,29376,1,0,0,0,4385,29382,1,0,0,0,4387,29393,
	1,0,0,0,4389,29406,1,0,0,0,4391,29413,1,0,0,0,4393,29421,1,0,0,0,4395,29432,
	1,0,0,0,4397,29437,1,0,0,0,4399,29442,1,0,0,0,4401,29450,1,0,0,0,4403,29458,
	1,0,0,0,4405,29464,1,0,0,0,4407,29484,1,0,0,0,4409,29488,1,0,0,0,4411,29500,
	1,0,0,0,4413,29504,1,0,0,0,4415,29515,1,0,0,0,4417,29522,1,0,0,0,4419,29536,
	1,0,0,0,4421,29544,1,0,0,0,4423,29553,1,0,0,0,4425,29566,1,0,0,0,4427,29577,
	1,0,0,0,4429,29587,1,0,0,0,4431,29595,1,0,0,0,4433,29612,1,0,0,0,4435,29623,
	1,0,0,0,4437,29634,1,0,0,0,4439,29644,1,0,0,0,4441,29654,1,0,0,0,4443,29663,
	1,0,0,0,4445,29690,1,0,0,0,4447,29707,1,0,0,0,4449,29728,1,0,0,0,4451,29738,
	1,0,0,0,4453,29749,1,0,0,0,4455,29763,1,0,0,0,4457,29772,1,0,0,0,4459,29781,
	1,0,0,0,4461,29787,1,0,0,0,4463,29799,1,0,0,0,4465,29808,1,0,0,0,4467,29816,
	1,0,0,0,4469,29826,1,0,0,0,4471,29839,1,0,0,0,4473,29848,1,0,0,0,4475,29865,
	1,0,0,0,4477,29878,1,0,0,0,4479,29886,1,0,0,0,4481,29890,1,0,0,0,4483,29901,
	1,0,0,0,4485,29916,1,0,0,0,4487,29919,1,0,0,0,4489,29930,1,0,0,0,4491,29936,
	1,0,0,0,4493,29941,1,0,0,0,4495,29945,1,0,0,0,4497,29970,1,0,0,0,4499,29978,
	1,0,0,0,4501,29983,1,0,0,0,4503,29994,1,0,0,0,4505,30012,1,0,0,0,4507,30028,
	1,0,0,0,4509,30047,1,0,0,0,4511,30070,1,0,0,0,4513,30085,1,0,0,0,4515,30096,
	1,0,0,0,4517,30104,1,0,0,0,4519,30114,1,0,0,0,4521,30125,1,0,0,0,4523,30136,
	1,0,0,0,4525,30145,1,0,0,0,4527,30153,1,0,0,0,4529,30158,1,0,0,0,4531,30168,
	1,0,0,0,4533,30184,1,0,0,0,4535,30196,1,0,0,0,4537,30208,1,0,0,0,4539,30222,
	1,0,0,0,4541,30233,1,0,0,0,4543,30241,1,0,0,0,4545,30246,1,0,0,0,4547,30254,
	1,0,0,0,4549,30259,1,0,0,0,4551,30265,1,0,0,0,4553,30275,1,0,0,0,4555,30285,
	1,0,0,0,4557,30296,1,0,0,0,4559,30304,1,0,0,0,4561,30317,1,0,0,0,4563,30333,
	1,0,0,0,4565,30349,1,0,0,0,4567,30354,1,0,0,0,4569,30358,1,0,0,0,4571,30363,
	1,0,0,0,4573,30370,1,0,0,0,4575,30377,1,0,0,0,4577,30381,1,0,0,0,4579,30390,
	1,0,0,0,4581,30407,1,0,0,0,4583,30412,1,0,0,0,4585,30416,1,0,0,0,4587,30423,
	1,0,0,0,4589,30435,1,0,0,0,4591,30439,1,0,0,0,4593,30445,1,0,0,0,4595,30449,
	1,0,0,0,4597,30465,1,0,0,0,4599,30471,1,0,0,0,4601,30477,1,0,0,0,4603,30488,
	1,0,0,0,4605,30495,1,0,0,0,4607,30503,1,0,0,0,4609,30508,1,0,0,0,4611,30512,
	1,0,0,0,4613,30519,1,0,0,0,4615,30524,1,0,0,0,4617,30533,1,0,0,0,4619,30539,
	1,0,0,0,4621,30548,1,0,0,0,4623,30556,1,0,0,0,4625,30569,1,0,0,0,4627,30582,
	1,0,0,0,4629,30595,1,0,0,0,4631,30598,1,0,0,0,4633,30601,1,0,0,0,4635,30605,
	1,0,0,0,4637,30623,1,0,0,0,4639,30635,1,0,0,0,4641,30651,1,0,0,0,4643,30660,
	1,0,0,0,4645,30669,1,0,0,0,4647,30678,1,0,0,0,4649,30687,1,0,0,0,4651,30696,
	1,0,0,0,4653,30705,1,0,0,0,4655,30714,1,0,0,0,4657,30723,1,0,0,0,4659,30733,
	1,0,0,0,4661,30735,1,0,0,0,4663,30737,1,0,0,0,4665,30739,1,0,0,0,4667,30741,
	1,0,0,0,4669,30744,1,0,0,0,4671,30746,1,0,0,0,4673,30748,1,0,0,0,4675,30750,
	1,0,0,0,4677,30752,1,0,0,0,4679,30754,1,0,0,0,4681,30756,1,0,0,0,4683,30759,
	1,0,0,0,4685,30761,1,0,0,0,4687,30777,1,0,0,0,4689,30787,1,0,0,0,4691,30789,
	1,0,0,0,4693,30791,1,0,0,0,4695,30793,1,0,0,0,4697,30795,1,0,0,0,4699,30797,
	1,0,0,0,4701,30799,1,0,0,0,4703,30801,1,0,0,0,4705,30803,1,0,0,0,4707,30805,
	1,0,0,0,4709,30807,1,0,0,0,4711,30809,1,0,0,0,4713,30811,1,0,0,0,4715,30813,
	1,0,0,0,4717,30826,1,0,0,0,4719,30840,1,0,0,0,4721,30863,1,0,0,0,4723,30884,
	1,0,0,0,4725,30888,1,0,0,0,4727,30897,1,0,0,0,4729,30905,1,0,0,0,4731,30907,
	1,0,0,0,4733,30909,1,0,0,0,4735,30914,1,0,0,0,4737,30926,1,0,0,0,4739,30930,
	1,0,0,0,4741,4742,7,0,0,0,4742,4743,7,1,0,0,4743,4744,7,2,0,0,4744,4745,
	7,3,0,0,4745,4746,7,4,0,0,4746,2,1,0,0,0,4747,4748,7,0,0,0,4748,4749,7,
	1,0,0,4749,4750,7,5,0,0,4750,4,1,0,0,0,4751,4752,7,0,0,0,4752,4753,7,1,
	0,0,4753,4754,7,5,0,0,4754,4755,7,6,0,0,4755,4756,7,7,0,0,4756,4757,7,4,
	0,0,4757,6,1,0,0,0,4758,4759,7,0,0,0,4759,4760,7,8,0,0,4760,4761,7,8,0,
	0,4761,4762,7,6,0,0,4762,4763,7,5,0,0,4763,4764,7,5,0,0,4764,8,1,0,0,0,
	4765,4766,7,0,0,0,4766,4767,7,8,0,0,4767,4768,7,8,0,0,4768,4769,7,6,0,0,
	4769,4770,7,5,0,0,4770,4771,7,5,0,0,4771,4772,7,6,0,0,4772,4773,7,9,0,0,
	4773,10,1,0,0,0,4774,4775,7,0,0,0,4775,4776,7,8,0,0,4776,4777,7,8,0,0,4777,
	4778,7,2,0,0,4778,4779,7,10,0,0,4779,4780,7,7,0,0,4780,4781,7,4,0,0,4781,
	12,1,0,0,0,4782,4783,7,0,0,0,4783,4784,7,8,0,0,4784,4785,7,11,0,0,4785,
	14,1,0,0,0,4786,4787,7,0,0,0,4787,4788,7,8,0,0,4788,4789,7,2,0,0,4789,4790,
	7,5,0,0,4790,16,1,0,0,0,4791,4792,7,0,0,0,4792,4793,7,8,0,0,4793,4794,7,
	3,0,0,4794,4795,7,2,0,0,4795,4796,7,5,0,0,4796,4797,7,5,0,0,4797,18,1,0,
	0,0,4798,4799,7,0,0,0,4799,4800,7,8,0,0,4800,4801,7,4,0,0,4801,4802,7,12,
	0,0,4802,4803,7,2,0,0,4803,4804,7,7,0,0,4804,20,1,0,0,0,4805,4806,7,0,0,
	0,4806,4807,7,8,0,0,4807,4808,7,4,0,0,4808,4809,7,12,0,0,4809,4810,7,2,
	0,0,4810,4811,7,7,0,0,4811,4812,7,5,0,0,4812,22,1,0,0,0,4813,4814,7,0,0,
	0,4814,4815,7,8,0,0,4815,4816,7,4,0,0,4816,4817,7,12,0,0,4817,4818,7,13,
	0,0,4818,4819,7,0,0,0,4819,4820,7,4,0,0,4820,4821,7,6,0,0,4821,24,1,0,0,
	0,4822,4823,7,0,0,0,4823,4824,7,8,0,0,4824,4825,7,4,0,0,4825,4826,7,12,
	0,0,4826,4827,7,13,0,0,4827,4828,7,6,0,0,4828,26,1,0,0,0,4829,4830,7,0,
	0,0,4830,4831,7,8,0,0,4831,4832,7,4,0,0,4832,4833,7,12,0,0,4833,4834,7,
	13,0,0,4834,4835,7,6,0,0,4835,4836,5,95,0,0,4836,4837,7,8,0,0,4837,4838,
	7,2,0,0,4838,4839,7,14,0,0,4839,4840,7,15,0,0,4840,4841,7,2,0,0,4841,4842,
	7,7,0,0,4842,4843,7,6,0,0,4843,4844,7,7,0,0,4844,4845,7,4,0,0,4845,28,1,
	0,0,0,4846,4847,7,0,0,0,4847,4848,7,8,0,0,4848,4849,7,4,0,0,4849,4850,7,
	12,0,0,4850,4851,7,13,0,0,4851,4852,7,6,0,0,4852,4853,5,95,0,0,4853,4854,
	7,9,0,0,4854,4855,7,0,0,0,4855,4856,7,4,0,0,4856,4857,7,0,0,0,4857,30,1,
	0,0,0,4858,4859,7,0,0,0,4859,4860,7,8,0,0,4860,4861,7,4,0,0,4861,4862,7,
	12,0,0,4862,4863,7,13,0,0,4863,4864,7,6,0,0,4864,4865,5,95,0,0,4865,4866,
	7,16,0,0,4866,4867,7,10,0,0,4867,4868,7,7,0,0,4868,4869,7,8,0,0,4869,4870,
	7,4,0,0,4870,4871,7,12,0,0,4871,4872,7,2,0,0,4872,4873,7,7,0,0,4873,32,
	1,0,0,0,4874,4875,7,0,0,0,4875,4876,7,8,0,0,4876,4877,7,4,0,0,4877,4878,
	7,12,0,0,4878,4879,7,13,0,0,4879,4880,7,6,0,0,4880,4881,5,95,0,0,4881,4882,
	7,4,0,0,4882,4883,7,0,0,0,4883,4884,7,17,0,0,4884,34,1,0,0,0,4885,4886,
	7,0,0,0,4886,4887,7,8,0,0,4887,4888,7,4,0,0,4888,4889,7,12,0,0,4889,4890,
	7,13,0,0,4890,4891,7,12,0,0,4891,4892,7,4,0,0,4892,4893,7,18,0,0,4893,36,
	1,0,0,0,4894,4895,7,0,0,0,4895,4896,7,9,0,0,4896,4897,7,0,0,0,4897,4898,
	7,15,0,0,4898,4899,7,4,0,0,4899,4900,7,12,0,0,4900,4901,7,13,0,0,4901,4902,
	7,6,0,0,4902,4903,5,95,0,0,4903,4904,7,15,0,0,4904,4905,7,11,0,0,4905,4906,
	7,0,0,0,4906,4907,7,7,0,0,4907,38,1,0,0,0,4908,4909,7,0,0,0,4909,4910,7,
	9,0,0,4910,4911,7,9,0,0,4911,40,1,0,0,0,4912,4913,7,0,0,0,4913,4914,7,9,
	0,0,4914,4915,7,9,0,0,4915,4916,5,95,0,0,4916,4917,7,8,0,0,4917,4918,7,
	2,0,0,4918,4919,7,11,0,0,4919,4920,7,10,0,0,4920,4921,7,14,0,0,4921,4922,
	7,7,0,0,4922,42,1,0,0,0,4923,4924,7,0,0,0,4924,4925,7,9,0,0,4925,4926,7,
	9,0,0,4926,4927,5,95,0,0,4927,4928,7,17,0,0,4928,4929,7,3,0,0,4929,4930,
	7,2,0,0,4930,4931,7,10,0,0,4931,4932,7,15,0,0,4932,44,1,0,0,0,4933,4934,
	7,0,0,0,4934,4935,7,9,0,0,4935,4936,7,9,0,0,4936,4937,5,95,0,0,4937,4938,
	7,14,0,0,4938,4939,7,2,0,0,4939,4940,7,7,0,0,4940,4941,7,4,0,0,4941,4942,
	7,19,0,0,4942,4943,7,5,0,0,4943,46,1,0,0,0,4944,4945,7,0,0,0,4945,4946,
	7,9,0,0,4946,4947,7,20,0,0,4947,4948,5,95,0,0,4948,4949,7,9,0,0,4949,4950,
	7,0,0,0,4950,4951,7,4,0,0,4951,4952,7,6,0,0,4952,48,1,0,0,0,4953,4954,7,
	0,0,0,4954,4955,7,9,0,0,4955,4956,7,14,0,0,4956,4957,7,12,0,0,4957,4958,
	7,7,0,0,4958,50,1,0,0,0,4959,4960,7,0,0,0,4960,4961,7,9,0,0,4961,4962,7,
	14,0,0,4962,4963,7,12,0,0,4963,4964,7,7,0,0,4964,4965,7,12,0,0,4965,4966,
	7,5,0,0,4966,4967,7,4,0,0,4967,4968,7,6,0,0,4968,4969,7,3,0,0,4969,52,1,
	0,0,0,4970,4971,7,0,0,0,4971,4972,7,9,0,0,4972,4973,7,14,0,0,4973,4974,
	7,12,0,0,4974,4975,7,7,0,0,4975,4976,7,12,0,0,4976,4977,7,5,0,0,4977,4978,
	7,4,0,0,4978,4979,7,3,0,0,4979,4980,7,0,0,0,4980,4981,7,4,0,0,4981,4982,
	7,2,0,0,4982,4983,7,3,0,0,4983,54,1,0,0,0,4984,4985,7,0,0,0,4985,4986,7,
	9,0,0,4986,4987,7,13,0,0,4987,4988,7,0,0,0,4988,4989,7,7,0,0,4989,4990,
	7,8,0,0,4990,4991,7,6,0,0,4991,4992,7,9,0,0,4992,56,1,0,0,0,4993,4994,7,
	0,0,0,4994,4995,7,9,0,0,4995,4996,7,13,0,0,4996,4997,7,12,0,0,4997,4998,
	7,5,0,0,4998,4999,7,6,0,0,4999,58,1,0,0,0,5000,5001,7,0,0,0,5001,5002,7,
	9,0,0,5002,5003,7,13,0,0,5003,5004,7,12,0,0,5004,5005,7,5,0,0,5005,5006,
	7,2,0,0,5006,5007,7,3,0,0,5007,60,1,0,0,0,5008,5009,7,0,0,0,5009,5010,7,
	16,0,0,5010,5011,7,9,0,0,5011,5012,5,95,0,0,5012,5013,7,9,0,0,5013,5014,
	7,12,0,0,5014,5015,7,5,0,0,5015,5016,7,21,0,0,5016,5017,7,5,0,0,5017,5018,
	7,4,0,0,5018,5019,7,3,0,0,5019,5020,7,12,0,0,5020,5021,7,7,0,0,5021,5022,
	7,17,0,0,5022,62,1,0,0,0,5023,5024,7,0,0,0,5024,5025,7,16,0,0,5025,5026,
	7,4,0,0,5026,5027,7,6,0,0,5027,5028,7,3,0,0,5028,64,1,0,0,0,5029,5030,7,
	0,0,0,5030,5031,7,17,0,0,5031,5032,7,6,0,0,5032,5033,7,7,0,0,5033,5034,
	7,4,0,0,5034,66,1,0,0,0,5035,5036,7,0,0,0,5036,5037,7,17,0,0,5037,5038,
	7,17,0,0,5038,5039,7,3,0,0,5039,5040,7,6,0,0,5040,5041,7,17,0,0,5041,5042,
	7,0,0,0,5042,5043,7,4,0,0,5043,5044,7,6,0,0,5044,68,1,0,0,0,5045,5046,7,
	0,0,0,5046,70,1,0,0,0,5047,5048,7,0,0,0,5048,5049,7,11,0,0,5049,5050,7,
	12,0,0,5050,5051,7,0,0,0,5051,5052,7,5,0,0,5052,72,1,0,0,0,5053,5054,7,
	0,0,0,5054,5055,7,11,0,0,5055,5056,7,11,0,0,5056,74,1,0,0,0,5057,5058,7,
	0,0,0,5058,5059,7,11,0,0,5059,5060,7,11,0,0,5060,5061,7,2,0,0,5061,5062,
	7,8,0,0,5062,5063,7,0,0,0,5063,5064,7,4,0,0,5064,5065,7,6,0,0,5065,76,1,
	0,0,0,5066,5067,7,0,0,0,5067,5068,7,11,0,0,5068,5069,7,11,0,0,5069,5070,
	7,2,0,0,5070,5071,7,22,0,0,5071,78,1,0,0,0,5072,5073,7,0,0,0,5073,5074,
	7,11,0,0,5074,5075,7,11,0,0,5075,5076,5,95,0,0,5076,5077,7,3,0,0,5077,5078,
	7,2,0,0,5078,5079,7,22,0,0,5079,5080,7,5,0,0,5080,80,1,0,0,0,5081,5082,
	7,0,0,0,5082,5083,7,11,0,0,5083,5084,7,4,0,0,5084,5085,7,6,0,0,5085,5086,
	7,3,0,0,5086,82,1,0,0,0,5087,5088,7,0,0,0,5088,5089,7,11,0,0,5089,5090,
	7,4,0,0,5090,5091,7,6,0,0,5091,5092,7,3,0,0,5092,5093,7,7,0,0,5093,5094,
	7,0,0,0,5094,5095,7,4,0,0,5095,5096,7,6,0,0,5096,84,1,0,0,0,5097,5098,7,
	0,0,0,5098,5099,7,11,0,0,5099,5100,7,22,0,0,5100,5101,7,0,0,0,5101,5102,
	7,18,0,0,5102,5103,7,5,0,0,5103,86,1,0,0,0,5104,5105,7,0,0,0,5105,5106,
	7,7,0,0,5106,5107,7,0,0,0,5107,5108,7,11,0,0,5108,5109,7,18,0,0,5109,5110,
	7,4,0,0,5110,5111,7,12,0,0,5111,5112,7,8,0,0,5112,88,1,0,0,0,5113,5114,
	7,0,0,0,5114,5115,7,7,0,0,5115,5116,7,0,0,0,5116,5117,7,11,0,0,5117,5118,
	7,18,0,0,5118,5119,7,23,0,0,5119,5120,7,6,0,0,5120,90,1,0,0,0,5121,5122,
	7,0,0,0,5122,5123,7,7,0,0,5123,5124,7,8,0,0,5124,5125,7,6,0,0,5125,5126,
	7,5,0,0,5126,5127,7,4,0,0,5127,5128,7,2,0,0,5128,5129,7,3,0,0,5129,92,1,
	0,0,0,5130,5131,7,0,0,0,5131,5132,7,7,0,0,5132,5133,7,8,0,0,5133,5134,7,
	12,0,0,5134,5135,7,11,0,0,5135,5136,7,11,0,0,5136,5137,7,0,0,0,5137,5138,
	7,3,0,0,5138,5139,7,18,0,0,5139,94,1,0,0,0,5140,5141,7,0,0,0,5141,5142,
	7,7,0,0,5142,5143,7,9,0,0,5143,96,1,0,0,0,5144,5145,7,0,0,0,5145,5146,7,
	7,0,0,5146,5147,7,9,0,0,5147,5148,5,95,0,0,5148,5149,7,6,0,0,5149,5150,
	7,24,0,0,5150,5151,7,10,0,0,5151,5152,7,0,0,0,5152,5153,7,11,0,0,5153,98,
	1,0,0,0,5154,5155,7,0,0,0,5155,5156,7,7,0,0,5156,5157,7,2,0,0,5157,5158,
	7,14,0,0,5158,5159,7,0,0,0,5159,5160,7,11,0,0,5160,5161,7,18,0,0,5161,100,
	1,0,0,0,5162,5163,7,0,0,0,5163,5164,7,7,0,0,5164,5165,7,5,0,0,5165,5166,
	7,12,0,0,5166,5167,5,95,0,0,5167,5168,7,3,0,0,5168,5169,7,6,0,0,5169,5170,
	7,0,0,0,5170,5171,7,3,0,0,5171,5172,7,8,0,0,5172,5173,7,19,0,0,5173,102,
	1,0,0,0,5174,5175,7,0,0,0,5175,5176,7,7,0,0,5176,5177,7,4,0,0,5177,5178,
	7,12,0,0,5178,5179,7,20,0,0,5179,5180,7,2,0,0,5180,5181,7,12,0,0,5181,5182,
	7,7,0,0,5182,104,1,0,0,0,5183,5184,7,0,0,0,5184,5185,7,7,0,0,5185,5186,
	7,18,0,0,5186,106,1,0,0,0,5187,5188,7,0,0,0,5188,5189,7,7,0,0,5189,5190,
	7,18,0,0,5190,5191,7,5,0,0,5191,5192,7,8,0,0,5192,5193,7,19,0,0,5193,5194,
	7,6,0,0,5194,5195,7,14,0,0,5195,5196,7,0,0,0,5196,108,1,0,0,0,5197,5198,
	7,0,0,0,5198,5199,7,15,0,0,5199,5200,7,15,0,0,5200,5201,7,6,0,0,5201,5202,
	7,7,0,0,5202,5203,7,9,0,0,5203,110,1,0,0,0,5204,5205,7,0,0,0,5205,5206,
	7,15,0,0,5206,5207,7,15,0,0,5207,5208,7,6,0,0,5208,5209,7,7,0,0,5209,5210,
	7,9,0,0,5210,5211,7,8,0,0,5211,5212,7,19,0,0,5212,5213,7,12,0,0,5213,5214,
	7,11,0,0,5214,5215,7,9,0,0,5215,5216,7,25,0,0,5216,5217,7,14,0,0,5217,5218,
	7,11,0,0,5218,112,1,0,0,0,5219,5220,7,0,0,0,5220,5221,7,15,0,0,5221,5222,
	7,15,0,0,5222,5223,7,6,0,0,5223,5224,7,7,0,0,5224,5225,7,9,0,0,5225,5226,
	5,95,0,0,5226,5227,7,13,0,0,5227,5228,7,0,0,0,5228,5229,7,11,0,0,5229,5230,
	7,10,0,0,5230,5231,7,6,0,0,5231,5232,7,5,0,0,5232,114,1,0,0,0,5233,5234,
	7,0,0,0,5234,5235,7,15,0,0,5235,5236,7,15,0,0,5236,5237,7,11,0,0,5237,5238,
	7,12,0,0,5238,5239,7,8,0,0,5239,5240,7,0,0,0,5240,5241,7,4,0,0,5241,5242,
	7,12,0,0,5242,5243,7,2,0,0,5243,5244,7,7,0,0,5244,116,1,0,0,0,5245,5246,
	7,0,0,0,5246,5247,7,15,0,0,5247,5248,7,15,0,0,5248,5249,7,11,0,0,5249,5250,
	7,18,0,0,5250,118,1,0,0,0,5251,5252,7,0,0,0,5252,5253,7,15,0,0,5253,5254,
	7,15,0,0,5254,5255,7,3,0,0,5255,5256,7,2,0,0,5256,5257,7,25,0,0,5257,5258,
	5,95,0,0,5258,5259,7,8,0,0,5259,5260,7,2,0,0,5260,5261,7,10,0,0,5261,5262,
	7,7,0,0,5262,5263,7,4,0,0,5263,5264,5,95,0,0,5264,5265,7,9,0,0,5265,5266,
	7,12,0,0,5266,5267,7,5,0,0,5267,5268,7,4,0,0,5268,5269,7,12,0,0,5269,5270,
	7,7,0,0,5270,5271,7,8,0,0,5271,5272,7,4,0,0,5272,120,1,0,0,0,5273,5274,
	7,0,0,0,5274,5275,7,3,0,0,5275,5276,7,8,0,0,5276,5277,7,19,0,0,5277,5278,
	7,12,0,0,5278,5279,7,13,0,0,5279,5280,7,0,0,0,5280,5281,7,11,0,0,5281,122,
	1,0,0,0,5282,5283,7,0,0,0,5283,5284,7,3,0,0,5284,5285,7,8,0,0,5285,5286,
	7,19,0,0,5286,5287,7,12,0,0,5287,5288,7,13,0,0,5288,5289,7,6,0,0,5289,124,
	1,0,0,0,5290,5291,7,0,0,0,5291,5292,7,3,0,0,5292,5293,7,8,0,0,5293,5294,
	7,19,0,0,5294,5295,7,12,0,0,5295,5296,7,13,0,0,5296,5297,7,6,0,0,5297,5298,
	7,9,0,0,5298,126,1,0,0,0,5299,5300,7,0,0,0,5300,5301,7,3,0,0,5301,5302,
	7,8,0,0,5302,5303,7,19,0,0,5303,5304,7,12,0,0,5304,5305,7,13,0,0,5305,5306,
	7,6,0,0,5306,5307,7,11,0,0,5307,5308,7,2,0,0,5308,5309,7,17,0,0,5309,128,
	1,0,0,0,5310,5311,7,0,0,0,5311,5312,7,3,0,0,5312,5313,7,3,0,0,5313,5314,
	7,0,0,0,5314,5315,7,18,0,0,5315,130,1,0,0,0,5316,5317,7,0,0,0,5317,5318,
	7,5,0,0,5318,132,1,0,0,0,5319,5320,7,0,0,0,5320,5321,7,5,0,0,5321,5322,
	7,8,0,0,5322,134,1,0,0,0,5323,5324,7,0,0,0,5324,5325,7,5,0,0,5325,5326,
	7,8,0,0,5326,5327,7,12,0,0,5327,5328,7,12,0,0,5328,136,1,0,0,0,5329,5330,
	7,0,0,0,5330,5331,7,5,0,0,5331,5332,7,8,0,0,5332,5333,7,12,0,0,5333,5334,
	7,12,0,0,5334,5335,7,5,0,0,5335,5336,7,4,0,0,5336,5337,7,3,0,0,5337,138,
	1,0,0,0,5338,5339,7,0,0,0,5339,5340,7,5,0,0,5340,5341,7,12,0,0,5341,5342,
	7,7,0,0,5342,140,1,0,0,0,5343,5344,7,0,0,0,5344,5345,7,5,0,0,5345,5346,
	7,12,0,0,5346,5347,7,5,0,0,5347,142,1,0,0,0,5348,5349,7,0,0,0,5349,5350,
	7,5,0,0,5350,5351,7,5,0,0,5351,5352,7,6,0,0,5352,5353,7,14,0,0,5353,5354,
	7,1,0,0,5354,5355,7,11,0,0,5355,5356,7,18,0,0,5356,144,1,0,0,0,5357,5358,
	7,0,0,0,5358,5359,7,5,0,0,5359,5360,7,5,0,0,5360,5361,7,12,0,0,5361,5362,
	7,17,0,0,5362,5363,7,7,0,0,5363,146,1,0,0,0,5364,5365,7,0,0,0,5365,5366,
	7,5,0,0,5366,5367,7,5,0,0,5367,5368,7,2,0,0,5368,5369,7,8,0,0,5369,5370,
	7,12,0,0,5370,5371,7,0,0,0,5371,5372,7,4,0,0,5372,5373,7,6,0,0,5373,148,
	1,0,0,0,5374,5375,7,0,0,0,5375,5376,7,5,0,0,5376,5377,7,18,0,0,5377,5378,
	7,7,0,0,5378,5379,7,8,0,0,5379,150,1,0,0,0,5380,5381,7,0,0,0,5381,5382,
	7,5,0,0,5382,5383,7,18,0,0,5383,5384,7,7,0,0,5384,5385,7,8,0,0,5385,5386,
	7,19,0,0,5386,5387,7,3,0,0,5387,5388,7,2,0,0,5388,5389,7,7,0,0,5389,5390,
	7,2,0,0,5390,5391,7,10,0,0,5391,5392,7,5,0,0,5392,152,1,0,0,0,5393,5394,
	7,0,0,0,5394,5395,7,4,0,0,5395,5396,7,0,0,0,5396,5397,7,7,0,0,5397,5398,
	5,50,0,0,5398,154,1,0,0,0,5399,5400,7,0,0,0,5400,5401,7,4,0,0,5401,5402,
	7,0,0,0,5402,5403,7,7,0,0,5403,156,1,0,0,0,5404,5405,7,0,0,0,5405,5406,
	7,4,0,0,5406,158,1,0,0,0,5407,5408,7,0,0,0,5408,5409,7,4,0,0,5409,5410,
	7,4,0,0,5410,5411,7,3,0,0,5411,5412,7,12,0,0,5412,5413,7,1,0,0,5413,5414,
	7,10,0,0,5414,5415,7,4,0,0,5415,5416,7,6,0,0,5416,160,1,0,0,0,5417,5418,
	7,0,0,0,5418,5419,7,4,0,0,5419,5420,7,4,0,0,5420,5421,7,3,0,0,5421,5422,
	7,12,0,0,5422,5423,7,1,0,0,5423,5424,7,10,0,0,5424,5425,7,4,0,0,5425,5426,
	7,6,0,0,5426,5427,7,5,0,0,5427,162,1,0,0,0,5428,5429,7,0,0,0,5429,5430,
	7,10,0,0,5430,5431,7,9,0,0,5431,5432,7,12,0,0,5432,5433,7,4,0,0,5433,164,
	1,0,0,0,5434,5435,7,0,0,0,5435,5436,7,10,0,0,5436,5437,7,4,0,0,5437,5438,
	7,19,0,0,5438,5439,7,6,0,0,5439,5440,7,7,0,0,5440,5441,7,4,0,0,5441,5442,
	7,12,0,0,5442,5443,7,8,0,0,5443,5444,7,0,0,0,5444,5445,7,4,0,0,5445,5446,
	7,6,0,0,5446,5447,7,9,0,0,5447,166,1,0,0,0,5448,5449,7,0,0,0,5449,5450,
	7,10,0,0,5450,5451,7,4,0,0,5451,5452,7,19,0,0,5452,5453,7,6,0,0,5453,5454,
	7,7,0,0,5454,5455,7,4,0,0,5455,5456,7,12,0,0,5456,5457,7,8,0,0,5457,5458,
	7,0,0,0,5458,5459,7,4,0,0,5459,5460,7,12,0,0,5460,5461,7,2,0,0,5461,5462,
	7,7,0,0,5462,168,1,0,0,0,5463,5464,7,0,0,0,5464,5465,7,10,0,0,5465,5466,
	7,4,0,0,5466,5467,7,19,0,0,5467,5468,7,12,0,0,5468,5469,7,9,0,0,5469,170,
	1,0,0,0,5470,5471,7,0,0,0,5471,5472,7,10,0,0,5472,5473,7,4,0,0,5473,5474,
	7,19,0,0,5474,5475,7,2,0,0,5475,5476,7,3,0,0,5476,5477,7,12,0,0,5477,5478,
	7,23,0,0,5478,5479,7,0,0,0,5479,5480,7,4,0,0,5480,5481,7,12,0,0,5481,5482,
	7,2,0,0,5482,5483,7,7,0,0,5483,172,1,0,0,0,5484,5485,7,0,0,0,5485,5486,
	7,10,0,0,5486,5487,7,4,0,0,5487,5488,7,2,0,0,5488,5489,7,0,0,0,5489,5490,
	7,11,0,0,5490,5491,7,11,0,0,5491,5492,7,2,0,0,5492,5493,7,8,0,0,5493,5494,
	7,0,0,0,5494,5495,7,4,0,0,5495,5496,7,6,0,0,5496,174,1,0,0,0,5497,5498,
	7,0,0,0,5498,5499,7,10,0,0,5499,5500,7,4,0,0,5500,5501,7,2,0,0,5501,176,
	1,0,0,0,5502,5503,7,0,0,0,5503,5504,7,10,0,0,5504,5505,7,4,0,0,5505,5506,
	7,2,0,0,5506,5507,7,1,0,0,5507,5508,7,0,0,0,5508,5509,7,8,0,0,5509,5510,
	7,21,0,0,5510,5511,7,10,0,0,5511,5512,7,15,0,0,5512,178,1,0,0,0,5513,5514,
	7,0,0,0,5514,5515,7,10,0,0,5515,5516,7,4,0,0,5516,5517,7,2,0,0,5517,5518,
	7,6,0,0,5518,5519,7,25,0,0,5519,5520,7,4,0,0,5520,5521,7,6,0,0,5521,5522,
	7,7,0,0,5522,5523,7,9,0,0,5523,180,1,0,0,0,5524,5525,7,0,0,0,5525,5526,
	7,10,0,0,5526,5527,7,4,0,0,5527,5528,7,2,0,0,5528,5529,5,95,0,0,5529,5530,
	7,11,0,0,5530,5531,7,2,0,0,5531,5532,7,17,0,0,5532,5533,7,12,0,0,5533,5534,
	7,7,0,0,5534,182,1,0,0,0,5535,5536,7,0,0,0,5536,5537,7,10,0,0,5537,5538,
	7,4,0,0,5538,5539,7,2,0,0,5539,5540,7,14,0,0,5540,5541,7,0,0,0,5541,5542,
	7,4,0,0,5542,5543,7,12,0,0,5543,5544,7,8,0,0,5544,184,1,0,0,0,5545,5546,
	7,0,0,0,5546,5547,7,10,0,0,5547,5548,7,4,0,0,5548,5549,7,2,0,0,5549,5550,
	7,7,0,0,5550,5551,7,2,0,0,5551,5552,7,14,0,0,5552,5553,7,2,0,0,5553,5554,
	7,10,0,0,5554,5555,7,5,0,0,5555,5556,5,95,0,0,5556,5557,7,4,0,0,5557,5558,
	7,3,0,0,5558,5559,7,0,0,0,5559,5560,7,7,0,0,5560,5561,7,5,0,0,5561,5562,
	7,0,0,0,5562,5563,7,8,0,0,5563,5564,7,4,0,0,5564,5565,7,12,0,0,5565,5566,
	7,2,0,0,5566,5567,7,7,0,0,5567,186,1,0,0,0,5568,5569,7,0,0,0,5569,5570,
	7,10,0,0,5570,5571,7,4,0,0,5571,5572,7,2,0,0,5572,5573,5,95,0,0,5573,5574,
	7,3,0,0,5574,5575,7,6,0,0,5575,5576,7,2,0,0,5576,5577,7,15,0,0,5577,5578,
	7,4,0,0,5578,5579,7,12,0,0,5579,5580,7,14,0,0,5580,5581,7,12,0,0,5581,5582,
	7,23,0,0,5582,5583,7,6,0,0,5583,188,1,0,0,0,5584,5585,7,0,0,0,5585,5586,
	7,13,0,0,5586,5587,7,0,0,0,5587,5588,7,12,0,0,5588,5589,7,11,0,0,5589,5590,
	7,0,0,0,5590,5591,7,1,0,0,5591,5592,7,12,0,0,5592,5593,7,11,0,0,5593,5594,
	7,12,0,0,5594,5595,7,4,0,0,5595,5596,7,18,0,0,5596,190,1,0,0,0,5597,5598,
	7,0,0,0,5598,5599,7,13,0,0,5599,5600,7,3,0,0,5600,5601,7,2,0,0,5601,192,
	1,0,0,0,5602,5603,7,1,0,0,5603,5604,7,0,0,0,5604,5605,7,8,0,0,5605,5606,
	7,21,0,0,5606,5607,7,17,0,0,5607,5608,7,3,0,0,5608,5609,7,2,0,0,5609,5610,
	7,10,0,0,5610,5611,7,7,0,0,5611,5612,7,9,0,0,5612,194,1,0,0,0,5613,5614,
	7,1,0,0,5614,5615,7,0,0,0,5615,5616,7,8,0,0,5616,5617,7,21,0,0,5617,5618,
	7,12,0,0,5618,5619,7,7,0,0,5619,5620,7,17,0,0,5620,5621,7,16,0,0,5621,5622,
	7,12,0,0,5622,5623,7,11,0,0,5623,5624,7,6,0,0,5624,196,1,0,0,0,5625,5626,
	7,1,0,0,5626,5627,7,0,0,0,5627,5628,7,8,0,0,5628,5629,7,21,0,0,5629,5630,
	7,10,0,0,5630,5631,7,15,0,0,5631,198,1,0,0,0,5632,5633,7,1,0,0,5633,5634,
	7,0,0,0,5634,5635,7,8,0,0,5635,5636,7,21,0,0,5636,5637,7,10,0,0,5637,5638,
	7,15,0,0,5638,5639,7,5,0,0,5639,200,1,0,0,0,5640,5641,7,1,0,0,5641,5642,
	7,0,0,0,5642,5643,7,8,0,0,5643,5644,7,21,0,0,5644,5645,7,10,0,0,5645,5646,
	7,15,0,0,5646,5647,7,5,0,0,5647,5648,7,6,0,0,5648,5649,7,4,0,0,5649,202,
	1,0,0,0,5650,5651,7,1,0,0,5651,5652,7,0,0,0,5652,5653,7,5,0,0,5653,5654,
	7,12,0,0,5654,5655,7,8,0,0,5655,204,1,0,0,0,5656,5657,7,1,0,0,5657,5658,
	7,0,0,0,5658,5659,7,5,0,0,5659,5660,7,12,0,0,5660,5661,7,8,0,0,5661,5662,
	7,16,0,0,5662,5663,7,12,0,0,5663,5664,7,11,0,0,5664,5665,7,6,0,0,5665,206,
	1,0,0,0,5666,5667,7,1,0,0,5667,5668,7,0,0,0,5668,5669,7,4,0,0,5669,5670,
	7,8,0,0,5670,5671,7,19,0,0,5671,208,1,0,0,0,5672,5673,7,1,0,0,5673,5674,
	7,0,0,0,5674,5675,7,4,0,0,5675,5676,7,8,0,0,5676,5677,7,19,0,0,5677,5678,
	7,5,0,0,5678,5679,7,12,0,0,5679,5680,7,23,0,0,5680,5681,7,6,0,0,5681,210,
	1,0,0,0,5682,5683,7,1,0,0,5683,5684,7,0,0,0,5684,5685,7,4,0,0,5685,5686,
	7,8,0,0,5686,5687,7,19,0,0,5687,5688,5,95,0,0,5688,5689,7,4,0,0,5689,5690,
	7,0,0,0,5690,5691,7,1,0,0,5691,5692,7,11,0,0,5692,5693,7,6,0,0,5693,5694,
	5,95,0,0,5694,5695,7,0,0,0,5695,5696,7,8,0,0,5696,5697,7,8,0,0,5697,5698,
	7,6,0,0,5698,5699,7,5,0,0,5699,5700,7,5,0,0,5700,5701,5,95,0,0,5701,5702,
	7,1,0,0,5702,5703,7,18,0,0,5703,5704,5,95,0,0,5704,5705,7,3,0,0,5705,5706,
	7,2,0,0,5706,5707,7,22,0,0,5707,5708,7,12,0,0,5708,5709,7,9,0,0,5709,212,
	1,0,0,0,5710,5711,7,1,0,0,5711,5712,7,6,0,0,5712,5713,7,8,0,0,5713,5714,
	7,2,0,0,5714,5715,7,14,0,0,5715,5716,7,6,0,0,5716,214,1,0,0,0,5717,5718,
	7,1,0,0,5718,5719,7,6,0,0,5719,5720,7,16,0,0,5720,5721,7,2,0,0,5721,5722,
	7,3,0,0,5722,5723,7,6,0,0,5723,216,1,0,0,0,5724,5725,7,1,0,0,5725,5726,
	7,6,0,0,5726,5727,7,17,0,0,5727,5728,7,12,0,0,5728,5729,7,7,0,0,5729,218,
	1,0,0,0,5730,5731,7,1,0,0,5731,5732,7,6,0,0,5732,5733,7,17,0,0,5733,5734,
	7,12,0,0,5734,5735,7,7,0,0,5735,5736,7,7,0,0,5736,5737,7,12,0,0,5737,5738,
	7,7,0,0,5738,5739,7,17,0,0,5739,220,1,0,0,0,5740,5741,7,1,0,0,5741,5742,
	7,6,0,0,5742,5743,7,17,0,0,5743,5744,7,12,0,0,5744,5745,7,7,0,0,5745,5746,
	5,95,0,0,5746,5747,7,2,0,0,5747,5748,7,10,0,0,5748,5749,7,4,0,0,5749,5750,
	7,11,0,0,5750,5751,7,12,0,0,5751,5752,7,7,0,0,5752,5753,7,6,0,0,5753,5754,
	5,95,0,0,5754,5755,7,9,0,0,5755,5756,7,0,0,0,5756,5757,7,4,0,0,5757,5758,
	7,0,0,0,5758,222,1,0,0,0,5759,5760,7,1,0,0,5760,5761,7,6,0,0,5761,5762,
	7,19,0,0,5762,5763,7,0,0,0,5763,5764,7,11,0,0,5764,5765,7,16,0,0,5765,224,
	1,0,0,0,5766,5767,7,1,0,0,5767,5768,7,6,0,0,5768,5769,7,24,0,0,5769,5770,
	7,10,0,0,5770,5771,7,6,0,0,5771,5772,7,0,0,0,5772,5773,7,4,0,0,5773,5774,
	7,19,0,0,5774,226,1,0,0,0,5775,5776,7,1,0,0,5776,5777,7,6,0,0,5777,5778,
	7,4,0,0,5778,5779,7,22,0,0,5779,5780,7,6,0,0,5780,5781,7,6,0,0,5781,5782,
	7,7,0,0,5782,228,1,0,0,0,5783,5784,7,1,0,0,5784,5785,7,16,0,0,5785,5786,
	7,12,0,0,5786,5787,7,11,0,0,5787,5788,7,6,0,0,5788,230,1,0,0,0,5789,5790,
	7,1,0,0,5790,5791,7,16,0,0,5791,5792,7,12,0,0,5792,5793,7,11,0,0,5793,5794,
	7,6,0,0,5794,5795,7,7,0,0,5795,5796,7,0,0,0,5796,5797,7,14,0,0,5797,5798,
	7,6,0,0,5798,232,1,0,0,0,5799,5800,7,1,0,0,5800,5801,7,12,0,0,5801,5802,
	7,17,0,0,5802,5803,7,16,0,0,5803,5804,7,12,0,0,5804,5805,7,11,0,0,5805,
	5806,7,6,0,0,5806,234,1,0,0,0,5807,5808,7,1,0,0,5808,5809,7,12,0,0,5809,
	5810,7,7,0,0,5810,5811,7,0,0,0,5811,5812,7,3,0,0,5812,5813,7,18,0,0,5813,
	236,1,0,0,0,5814,5815,7,1,0,0,5815,5816,7,12,0,0,5816,5817,7,7,0,0,5817,
	5818,7,0,0,0,5818,5819,7,3,0,0,5819,5820,7,18,0,0,5820,5821,5,95,0,0,5821,
	5822,7,9,0,0,5822,5823,7,2,0,0,5823,5824,7,10,0,0,5824,5825,7,1,0,0,5825,
	5826,7,11,0,0,5826,5827,7,6,0,0,5827,238,1,0,0,0,5828,5829,7,1,0,0,5829,
	5830,7,12,0,0,5830,5831,7,7,0,0,5831,5832,7,0,0,0,5832,5833,7,3,0,0,5833,
	5834,7,18,0,0,5834,5835,5,95,0,0,5835,5836,7,9,0,0,5836,5837,7,2,0,0,5837,
	5838,7,10,0,0,5838,5839,7,1,0,0,5839,5840,7,11,0,0,5840,5841,7,6,0,0,5841,
	5842,5,95,0,0,5842,5843,7,12,0,0,5843,5844,7,7,0,0,5844,5845,7,16,0,0,5845,
	5846,7,12,0,0,5846,5847,7,7,0,0,5847,5848,7,12,0,0,5848,5849,7,4,0,0,5849,
	5850,7,18,0,0,5850,240,1,0,0,0,5851,5852,7,1,0,0,5852,5853,7,12,0,0,5853,
	5854,7,7,0,0,5854,5855,7,0,0,0,5855,5856,7,3,0,0,5856,5857,7,18,0,0,5857,
	5858,5,95,0,0,5858,5859,7,9,0,0,5859,5860,7,2,0,0,5860,5861,7,10,0,0,5861,
	5862,7,1,0,0,5862,5863,7,11,0,0,5863,5864,7,6,0,0,5864,5865,5,95,0,0,5865,
	5866,7,7,0,0,5866,5867,7,0,0,0,5867,5868,7,7,0,0,5868,242,1,0,0,0,5869,
	5870,7,1,0,0,5870,5871,7,12,0,0,5871,5872,7,7,0,0,5872,5873,7,0,0,0,5873,
	5874,7,3,0,0,5874,5875,7,18,0,0,5875,5876,5,95,0,0,5876,5877,7,16,0,0,5877,
	5878,7,11,0,0,5878,5879,7,2,0,0,5879,5880,7,0,0,0,5880,5881,7,4,0,0,5881,
	244,1,0,0,0,5882,5883,7,1,0,0,5883,5884,7,12,0,0,5884,5885,7,7,0,0,5885,
	5886,7,0,0,0,5886,5887,7,3,0,0,5887,5888,7,18,0,0,5888,5889,5,95,0,0,5889,
	5890,7,16,0,0,5890,5891,7,11,0,0,5891,5892,7,2,0,0,5892,5893,7,0,0,0,5893,
	5894,7,4,0,0,5894,5895,5,95,0,0,5895,5896,7,12,0,0,5896,5897,7,7,0,0,5897,
	5898,7,16,0,0,5898,5899,7,12,0,0,5899,5900,7,7,0,0,5900,5901,7,12,0,0,5901,
	5902,7,4,0,0,5902,5903,7,18,0,0,5903,246,1,0,0,0,5904,5905,7,1,0,0,5905,
	5906,7,12,0,0,5906,5907,7,7,0,0,5907,5908,7,0,0,0,5908,5909,7,3,0,0,5909,
	5910,7,18,0,0,5910,5911,5,95,0,0,5911,5912,7,16,0,0,5912,5913,7,11,0,0,
	5913,5914,7,2,0,0,5914,5915,7,0,0,0,5915,5916,7,4,0,0,5916,5917,5,95,0,
	0,5917,5918,7,7,0,0,5918,5919,7,0,0,0,5919,5920,7,7,0,0,5920,248,1,0,0,
	0,5921,5922,7,1,0,0,5922,5923,7,12,0,0,5923,5924,7,7,0,0,5924,5925,7,0,
	0,0,5925,5926,7,3,0,0,5926,5927,7,18,0,0,5927,5928,5,95,0,0,5928,5929,7,
	12,0,0,5929,5930,7,7,0,0,5930,5931,7,4,0,0,5931,5932,7,6,0,0,5932,5933,
	7,17,0,0,5933,5934,7,6,0,0,5934,5935,7,3,0,0,5935,250,1,0,0,0,5936,5937,
	7,1,0,0,5937,5938,7,12,0,0,5938,5939,7,7,0,0,5939,5940,7,9,0,0,5940,5941,
	5,95,0,0,5941,5942,7,0,0,0,5942,5943,7,22,0,0,5943,5944,7,0,0,0,5944,5945,
	7,3,0,0,5945,5946,7,6,0,0,5946,252,1,0,0,0,5947,5948,7,1,0,0,5948,5949,
	7,12,0,0,5949,5950,7,7,0,0,5950,5951,7,9,0,0,5951,5952,7,12,0,0,5952,5953,
	7,7,0,0,5953,5954,7,17,0,0,5954,254,1,0,0,0,5955,5956,7,1,0,0,5956,5957,
	7,12,0,0,5957,5958,7,7,0,0,5958,5959,5,95,0,0,5959,5960,7,4,0,0,5960,5961,
	7,2,0,0,5961,5962,5,95,0,0,5962,5963,7,7,0,0,5963,5964,7,10,0,0,5964,5965,
	7,14,0,0,5965,256,1,0,0,0,5966,5967,7,1,0,0,5967,5968,7,12,0,0,5968,5969,
	7,4,0,0,5969,5970,7,0,0,0,5970,5971,7,7,0,0,5971,5972,7,9,0,0,5972,258,
	1,0,0,0,5973,5974,7,1,0,0,5974,5975,7,12,0,0,5975,5976,7,4,0,0,5976,5977,
	7,14,0,0,5977,5978,7,0,0,0,5978,5979,7,15,0,0,5979,5980,5,95,0,0,5980,5981,
	7,0,0,0,5981,5982,7,7,0,0,5982,5983,7,9,0,0,5983,260,1,0,0,0,5984,5985,
	7,1,0,0,5985,5986,7,12,0,0,5986,5987,7,4,0,0,5987,5988,7,14,0,0,5988,5989,
	7,0,0,0,5989,5990,7,15,0,0,5990,262,1,0,0,0,5991,5992,7,1,0,0,5992,5993,
	7,12,0,0,5993,5994,7,4,0,0,5994,5995,7,14,0,0,5995,5996,7,0,0,0,5996,5997,
	7,15,0,0,5997,5998,7,5,0,0,5998,264,1,0,0,0,5999,6000,7,1,0,0,6000,6001,
	7,12,0,0,6001,6002,7,4,0,0,6002,6003,7,14,0,0,6003,6004,7,0,0,0,6004,6005,
	7,15,0,0,6005,6006,5,95,0,0,6006,6007,7,4,0,0,6007,6008,7,3,0,0,6008,6009,
	7,6,0,0,6009,6010,7,6,0,0,6010,266,1,0,0,0,6011,6012,7,1,0,0,6012,6013,
	7,12,0,0,6013,6014,7,4,0,0,6014,6015,7,5,0,0,6015,268,1,0,0,0,6016,6017,
	7,1,0,0,6017,6018,7,11,0,0,6018,6019,7,2,0,0,6019,6020,7,1,0,0,6020,270,
	1,0,0,0,6021,6022,7,1,0,0,6022,6023,7,11,0,0,6023,6024,7,2,0,0,6024,6025,
	7,8,0,0,6025,6026,7,21,0,0,6026,272,1,0,0,0,6027,6028,7,1,0,0,6028,6029,
	7,11,0,0,6029,6030,7,2,0,0,6030,6031,7,8,0,0,6031,6032,7,21,0,0,6032,6033,
	5,95,0,0,6033,6034,7,3,0,0,6034,6035,7,0,0,0,6035,6036,7,7,0,0,6036,6037,
	7,17,0,0,6037,6038,7,6,0,0,6038,274,1,0,0,0,6039,6040,7,1,0,0,6040,6041,
	7,11,0,0,6041,6042,7,2,0,0,6042,6043,7,8,0,0,6043,6044,7,21,0,0,6044,6045,
	7,5,0,0,6045,276,1,0,0,0,6046,6047,7,1,0,0,6047,6048,7,11,0,0,6048,6049,
	7,2,0,0,6049,6050,7,8,0,0,6050,6051,7,21,0,0,6051,6052,7,5,0,0,6052,6053,
	7,12,0,0,6053,6054,7,23,0,0,6054,6055,7,6,0,0,6055,278,1,0,0,0,6056,6057,
	7,1,0,0,6057,6058,7,2,0,0,6058,6059,7,9,0,0,6059,6060,7,18,0,0,6060,280,
	1,0,0,0,6061,6062,7,1,0,0,6062,6063,7,2,0,0,6063,6064,7,2,0,0,6064,6065,
	7,11,0,0,6065,6066,7,6,0,0,6066,6067,7,0,0,0,6067,6068,7,7,0,0,6068,282,
	1,0,0,0,6069,6070,7,1,0,0,6070,6071,7,2,0,0,6071,6072,7,4,0,0,6072,6073,
	7,19,0,0,6073,284,1,0,0,0,6074,6075,7,1,0,0,6075,6076,7,2,0,0,6076,6077,
	7,10,0,0,6077,6078,7,7,0,0,6078,6079,7,9,0,0,6079,286,1,0,0,0,6080,6081,
	7,1,0,0,6081,6082,7,3,0,0,6082,6083,7,0,0,0,6083,6084,7,7,0,0,6084,6085,
	7,8,0,0,6085,6086,7,19,0,0,6086,288,1,0,0,0,6087,6088,7,1,0,0,6088,6089,
	7,3,0,0,6089,6090,7,6,0,0,6090,6091,7,0,0,0,6091,6092,7,9,0,0,6092,6093,
	7,4,0,0,6093,6094,7,19,0,0,6094,290,1,0,0,0,6095,6096,7,1,0,0,6096,6097,
	7,3,0,0,6097,6098,7,2,0,0,6098,6099,7,0,0,0,6099,6100,7,9,0,0,6100,6101,
	7,8,0,0,6101,6102,7,0,0,0,6102,6103,7,5,0,0,6103,6104,7,4,0,0,6104,292,
	1,0,0,0,6105,6106,7,1,0,0,6106,6107,7,5,0,0,6107,6108,7,2,0,0,6108,6109,
	7,7,0,0,6109,294,1,0,0,0,6110,6111,7,1,0,0,6111,6112,7,10,0,0,6112,6113,
	7,16,0,0,6113,6114,7,16,0,0,6114,6115,7,6,0,0,6115,6116,7,3,0,0,6116,296,
	1,0,0,0,6117,6118,7,1,0,0,6118,6119,7,10,0,0,6119,6120,7,16,0,0,6120,6121,
	7,16,0,0,6121,6122,7,6,0,0,6122,6123,7,3,0,0,6123,6124,5,95,0,0,6124,6125,
	7,8,0,0,6125,6126,7,0,0,0,6126,6127,7,8,0,0,6127,6128,7,19,0,0,6128,6129,
	7,6,0,0,6129,298,1,0,0,0,6130,6131,7,1,0,0,6131,6132,7,10,0,0,6132,6133,
	7,16,0,0,6133,6134,7,16,0,0,6134,6135,7,6,0,0,6135,6136,7,3,0,0,6136,6137,
	5,95,0,0,6137,6138,7,15,0,0,6138,6139,7,2,0,0,6139,6140,7,2,0,0,6140,6141,
	7,11,0,0,6141,300,1,0,0,0,6142,6143,7,1,0,0,6143,6144,7,10,0,0,6144,6145,
	7,12,0,0,6145,6146,7,11,0,0,6146,6147,7,9,0,0,6147,302,1,0,0,0,6148,6149,
	7,1,0,0,6149,6150,7,10,0,0,6150,6151,7,11,0,0,6151,6152,7,21,0,0,6152,304,
	1,0,0,0,6153,6154,7,1,0,0,6154,6155,7,18,0,0,6155,306,1,0,0,0,6156,6157,
	7,1,0,0,6157,6158,7,18,0,0,6158,6159,7,15,0,0,6159,6160,7,0,0,0,6160,6161,
	7,5,0,0,6161,6162,7,5,0,0,6162,6163,5,95,0,0,6163,6164,7,3,0,0,6164,6165,
	7,6,0,0,6165,6166,7,8,0,0,6166,6167,7,10,0,0,6167,6168,7,3,0,0,6168,6169,
	7,5,0,0,6169,6170,7,12,0,0,6170,6171,7,13,0,0,6171,6172,7,6,0,0,6172,6173,
	5,95,0,0,6173,6174,7,8,0,0,6174,6175,7,19,0,0,6175,6176,7,6,0,0,6176,6177,
	7,8,0,0,6177,6178,7,21,0,0,6178,308,1,0,0,0,6179,6180,7,1,0,0,6180,6181,
	7,18,0,0,6181,6182,7,15,0,0,6182,6183,7,0,0,0,6183,6184,7,5,0,0,6184,6185,
	7,5,0,0,6185,6186,5,95,0,0,6186,6187,7,10,0,0,6187,6188,7,20,0,0,6188,6189,
	7,13,0,0,6189,6190,7,8,0,0,6190,310,1,0,0,0,6191,6192,7,1,0,0,6192,6193,
	7,18,0,0,6193,6194,7,4,0,0,6194,6195,7,6,0,0,6195,312,1,0,0,0,6196,6197,
	7,8,0,0,6197,6198,7,0,0,0,6198,6199,7,8,0,0,6199,6200,7,19,0,0,6200,6201,
	7,6,0,0,6201,314,1,0,0,0,6202,6203,7,8,0,0,6203,6204,7,0,0,0,6204,6205,
	7,8,0,0,6205,6206,7,19,0,0,6206,6207,7,6,0,0,6207,6208,5,95,0,0,6208,6209,
	7,8,0,0,6209,6210,7,1,0,0,6210,316,1,0,0,0,6211,6212,7,8,0,0,6212,6213,
	7,0,0,0,6213,6214,7,8,0,0,6214,6215,7,19,0,0,6215,6216,7,6,0,0,6216,6217,
	5,95,0,0,6217,6218,7,12,0,0,6218,6219,7,7,0,0,6219,6220,7,5,0,0,6220,6221,
	7,4,0,0,6221,6222,7,0,0,0,6222,6223,7,7,0,0,6223,6224,7,8,0,0,6224,6225,
	7,6,0,0,6225,6226,7,5,0,0,6226,318,1,0,0,0,6227,6228,7,8,0,0,6228,6229,
	7,0,0,0,6229,6230,7,8,0,0,6230,6231,7,19,0,0,6231,6232,7,6,0,0,6232,6233,
	5,95,0,0,6233,6234,7,4,0,0,6234,6235,7,6,0,0,6235,6236,7,14,0,0,6236,6237,
	7,15,0,0,6237,6238,5,95,0,0,6238,6239,7,4,0,0,6239,6240,7,0,0,0,6240,6241,
	7,1,0,0,6241,6242,7,11,0,0,6242,6243,7,6,0,0,6243,320,1,0,0,0,6244,6245,
	7,8,0,0,6245,6246,7,0,0,0,6246,6247,7,8,0,0,6247,6248,7,19,0,0,6248,6249,
	7,12,0,0,6249,6250,7,7,0,0,6250,6251,7,17,0,0,6251,322,1,0,0,0,6252,6253,
	7,8,0,0,6253,6254,7,0,0,0,6254,6255,7,11,0,0,6255,6256,7,8,0,0,6256,6257,
	7,10,0,0,6257,6258,7,11,0,0,6258,6259,7,0,0,0,6259,6260,7,4,0,0,6260,6261,
	7,6,0,0,6261,6262,7,9,0,0,6262,324,1,0,0,0,6263,6264,7,8,0,0,6264,6265,
	7,0,0,0,6265,6266,7,11,0,0,6266,6267,7,11,0,0,6267,6268,7,1,0,0,6268,6269,
	7,0,0,0,6269,6270,7,8,0,0,6270,6271,7,21,0,0,6271,326,1,0,0,0,6272,6273,
	7,8,0,0,6273,6274,7,0,0,0,6274,6275,7,11,0,0,6275,6276,7,11,0,0,6276,328,
	1,0,0,0,6277,6278,7,8,0,0,6278,6279,7,0,0,0,6279,6280,7,7,0,0,6280,6281,
	7,8,0,0,6281,6282,7,6,0,0,6282,6283,7,11,0,0,6283,330,1,0,0,0,6284,6285,
	7,8,0,0,6285,6286,7,0,0,0,6286,6287,7,7,0,0,6287,6288,7,2,0,0,6288,6289,
	7,7,0,0,6289,6290,7,12,0,0,6290,6291,7,8,0,0,6291,6292,7,0,0,0,6292,6293,
	7,11,0,0,6293,332,1,0,0,0,6294,6295,7,8,0,0,6295,6296,7,0,0,0,6296,6297,
	7,15,0,0,6297,6298,7,0,0,0,6298,6299,7,8,0,0,6299,6300,7,12,0,0,6300,6301,
	7,4,0,0,6301,6302,7,18,0,0,6302,334,1,0,0,0,6303,6304,7,8,0,0,6304,6305,
	7,0,0,0,6305,6306,7,15,0,0,6306,6307,7,4,0,0,6307,6308,7,12,0,0,6308,6309,
	7,2,0,0,6309,6310,7,7,0,0,6310,336,1,0,0,0,6311,6312,7,8,0,0,6312,6313,
	7,0,0,0,6313,6314,7,3,0,0,6314,6315,7,9,0,0,6315,6316,7,12,0,0,6316,6317,
	7,7,0,0,6317,6318,7,0,0,0,6318,6319,7,11,0,0,6319,6320,7,12,0,0,6320,6321,
	7,4,0,0,6321,6322,7,18,0,0,6322,338,1,0,0,0,6323,6324,7,8,0,0,6324,6325,
	7,0,0,0,6325,6326,7,5,0,0,6326,6327,7,8,0,0,6327,6328,7,0,0,0,6328,6329,
	7,9,0,0,6329,6330,7,6,0,0,6330,340,1,0,0,0,6331,6332,7,8,0,0,6332,6333,
	7,0,0,0,6333,6334,7,5,0,0,6334,6335,7,6,0,0,6335,342,1,0,0,0,6336,6337,
	7,8,0,0,6337,6338,7,0,0,0,6338,6339,7,5,0,0,6339,6340,7,4,0,0,6340,344,
	1,0,0,0,6341,6342,7,8,0,0,6342,6343,7,0,0,0,6343,6344,7,5,0,0,6344,6345,
	7,6,0,0,6345,6346,5,45,0,0,6346,6347,7,5,0,0,6347,6348,7,6,0,0,6348,6349,
	7,7,0,0,6349,6350,7,5,0,0,6350,6351,7,12,0,0,6351,6352,7,4,0,0,6352,6353,
	7,12,0,0,6353,6354,7,13,0,0,6354,6355,7,6,0,0,6355,346,1,0,0,0,6356,6357,
	7,8,0,0,6357,6358,7,0,0,0,6358,6359,7,4,0,0,6359,6360,7,6,0,0,6360,6361,
	7,17,0,0,6361,6362,7,2,0,0,6362,6363,7,3,0,0,6363,6364,7,18,0,0,6364,348,
	1,0,0,0,6365,6366,7,8,0,0,6366,6367,7,9,0,0,6367,6368,7,1,0,0,6368,6369,
	5,36,0,0,6369,6370,7,9,0,0,6370,6371,7,6,0,0,6371,6372,7,16,0,0,6372,6373,
	7,0,0,0,6373,6374,7,10,0,0,6374,6375,7,11,0,0,6375,6376,7,4,0,0,6376,350,
	1,0,0,0,6377,6378,7,8,0,0,6378,6379,7,6,0,0,6379,6380,7,12,0,0,6380,6381,
	7,11,0,0,6381,352,1,0,0,0,6382,6383,7,8,0,0,6383,6384,7,6,0,0,6384,6385,
	7,11,0,0,6385,6386,7,11,0,0,6386,6387,5,95,0,0,6387,6388,7,16,0,0,6388,
	6389,7,11,0,0,6389,6390,7,0,0,0,6390,6391,7,5,0,0,6391,6392,7,19,0,0,6392,
	6393,5,95,0,0,6393,6394,7,8,0,0,6394,6395,7,0,0,0,6395,6396,7,8,0,0,6396,
	6397,7,19,0,0,6397,6398,7,6,0,0,6398,354,1,0,0,0,6399,6400,7,8,0,0,6400,
	6401,7,6,0,0,6401,6402,7,3,0,0,6402,6403,7,4,0,0,6403,6404,7,12,0,0,6404,
	6405,7,16,0,0,6405,6406,7,12,0,0,6406,6407,7,8,0,0,6407,6408,7,0,0,0,6408,
	6409,7,4,0,0,6409,6410,7,6,0,0,6410,356,1,0,0,0,6411,6412,7,8,0,0,6412,
	6413,7,16,0,0,6413,6414,7,12,0,0,6414,6415,7,11,0,0,6415,6416,7,6,0,0,6416,
	358,1,0,0,0,6417,6418,7,8,0,0,6418,6419,7,19,0,0,6419,6420,7,0,0,0,6420,
	6421,7,12,0,0,6421,6422,7,7,0,0,6422,6423,7,6,0,0,6423,6424,7,9,0,0,6424,
	360,1,0,0,0,6425,6426,7,8,0,0,6426,6427,7,19,0,0,6427,6428,7,0,0,0,6428,
	6429,7,7,0,0,6429,6430,7,17,0,0,6430,6431,7,6,0,0,6431,362,1,0,0,0,6432,
	6433,7,8,0,0,6433,6434,7,19,0,0,6434,6435,7,0,0,0,6435,6436,7,7,0,0,6436,
	6437,7,17,0,0,6437,6438,7,6,0,0,6438,6439,7,4,0,0,6439,6440,7,3,0,0,6440,
	6441,7,0,0,0,6441,6442,7,8,0,0,6442,6443,7,21,0,0,6443,6444,7,12,0,0,6444,
	6445,7,7,0,0,6445,6446,7,17,0,0,6446,364,1,0,0,0,6447,6448,7,8,0,0,6448,
	6449,7,19,0,0,6449,6450,7,0,0,0,6450,6451,7,7,0,0,6451,6452,7,17,0,0,6452,
	6453,7,6,0,0,6453,6454,5,95,0,0,6454,6455,7,9,0,0,6455,6456,7,10,0,0,6456,
	6457,7,15,0,0,6457,6458,7,21,0,0,6458,6459,7,6,0,0,6459,6460,7,18,0,0,6460,
	6461,5,95,0,0,6461,6462,7,6,0,0,6462,6463,7,3,0,0,6463,6464,7,3,0,0,6464,
	6465,7,2,0,0,6465,6466,7,3,0,0,6466,6467,5,95,0,0,6467,6468,7,12,0,0,6468,
	6469,7,7,0,0,6469,6470,7,9,0,0,6470,6471,7,6,0,0,6471,6472,7,25,0,0,6472,
	366,1,0,0,0,6473,6474,7,8,0,0,6474,6475,7,19,0,0,6475,6476,7,0,0,0,6476,
	6477,7,3,0,0,6477,6478,7,0,0,0,6478,6479,7,8,0,0,6479,6480,7,4,0,0,6480,
	6481,7,6,0,0,6481,6482,7,3,0,0,6482,368,1,0,0,0,6483,6484,7,8,0,0,6484,
	6485,7,19,0,0,6485,6486,7,0,0,0,6486,6487,7,3,0,0,6487,370,1,0,0,0,6488,
	6489,7,8,0,0,6489,6490,7,19,0,0,6490,6491,7,0,0,0,6491,6492,7,3,0,0,6492,
	6493,5,95,0,0,6493,6494,7,8,0,0,6494,6495,7,5,0,0,6495,372,1,0,0,0,6496,
	6497,7,8,0,0,6497,6498,7,19,0,0,6498,6499,7,0,0,0,6499,6500,7,3,0,0,6500,
	6501,7,4,0,0,6501,6502,7,2,0,0,6502,6503,7,3,0,0,6503,6504,7,2,0,0,6504,
	6505,7,22,0,0,6505,6506,7,12,0,0,6506,6507,7,9,0,0,6507,374,1,0,0,0,6508,
	6509,7,8,0,0,6509,6510,7,19,0,0,6510,6511,7,6,0,0,6511,6512,7,8,0,0,6512,
	6513,7,21,0,0,6513,6514,5,95,0,0,6514,6515,7,0,0,0,6515,6516,7,8,0,0,6516,
	6517,7,11,0,0,6517,6518,5,95,0,0,6518,6519,7,3,0,0,6519,6520,7,6,0,0,6520,
	6521,7,22,0,0,6521,6522,7,3,0,0,6522,6523,7,12,0,0,6523,6524,7,4,0,0,6524,
	6525,7,6,0,0,6525,376,1,0,0,0,6526,6527,7,8,0,0,6527,6528,7,19,0,0,6528,
	6529,7,6,0,0,6529,6530,7,8,0,0,6530,6531,7,21,0,0,6531,378,1,0,0,0,6532,
	6533,7,8,0,0,6533,6534,7,19,0,0,6534,6535,7,6,0,0,6535,6536,7,8,0,0,6536,
	6537,7,21,0,0,6537,6538,7,15,0,0,6538,6539,7,2,0,0,6539,6540,7,12,0,0,6540,
	6541,7,7,0,0,6541,6542,7,4,0,0,6542,380,1,0,0,0,6543,6544,7,8,0,0,6544,
	6545,7,19,0,0,6545,6546,7,12,0,0,6546,6547,7,11,0,0,6547,6548,7,9,0,0,6548,
	382,1,0,0,0,6549,6550,7,8,0,0,6550,6551,7,19,0,0,6551,6552,7,2,0,0,6552,
	6553,7,2,0,0,6553,6554,7,5,0,0,6554,6555,7,6,0,0,6555,384,1,0,0,0,6556,
	6557,7,8,0,0,6557,6558,7,19,0,0,6558,6559,7,3,0,0,6559,386,1,0,0,0,6560,
	6561,7,8,0,0,6561,6562,7,19,0,0,6562,6563,7,10,0,0,6563,6564,7,7,0,0,6564,
	6565,7,21,0,0,6565,388,1,0,0,0,6566,6567,7,8,0,0,6567,6568,7,11,0,0,6568,
	6569,7,0,0,0,6569,6570,7,5,0,0,6570,6571,7,5,0,0,6571,390,1,0,0,0,6572,
	6573,7,8,0,0,6573,6574,7,11,0,0,6574,6575,7,0,0,0,6575,6576,7,5,0,0,6576,
	6577,7,5,0,0,6577,6578,7,12,0,0,6578,6579,7,16,0,0,6579,6580,7,12,0,0,6580,
	6581,7,8,0,0,6581,6582,7,0,0,0,6582,6583,7,4,0,0,6583,6584,7,12,0,0,6584,
	6585,7,2,0,0,6585,6586,7,7,0,0,6586,392,1,0,0,0,6587,6588,7,8,0,0,6588,
	6589,7,11,0,0,6589,6590,7,0,0,0,6590,6591,7,5,0,0,6591,6592,7,5,0,0,6592,
	6593,7,12,0,0,6593,6594,7,16,0,0,6594,6595,7,12,0,0,6595,6596,7,6,0,0,6596,
	6597,7,3,0,0,6597,394,1,0,0,0,6598,6599,7,8,0,0,6599,6600,7,11,0,0,6600,
	6601,7,0,0,0,6601,6602,7,10,0,0,6602,6603,7,5,0,0,6603,6604,7,6,0,0,6604,
	396,1,0,0,0,6605,6606,7,8,0,0,6606,6607,7,11,0,0,6607,6608,7,6,0,0,6608,
	6609,7,0,0,0,6609,6610,7,7,0,0,6610,398,1,0,0,0,6611,6612,7,8,0,0,6612,
	6613,7,11,0,0,6613,6614,7,6,0,0,6614,6615,7,0,0,0,6615,6616,7,7,0,0,6616,
	6617,7,10,0,0,6617,6618,7,15,0,0,6618,400,1,0,0,0,6619,6620,7,8,0,0,6620,
	6621,7,11,0,0,6621,6622,7,6,0,0,6622,6623,7,0,0,0,6623,6624,7,3,0,0,6624,
	402,1,0,0,0,6625,6626,7,8,0,0,6626,404,1,0,0,0,6627,6628,7,8,0,0,6628,6629,
	7,11,0,0,6629,6630,7,12,0,0,6630,6631,7,6,0,0,6631,6632,7,7,0,0,6632,6633,
	7,4,0,0,6633,406,1,0,0,0,6634,6635,7,8,0,0,6635,6636,7,11,0,0,6636,6637,
	7,2,0,0,6637,6638,7,1,0,0,6638,408,1,0,0,0,6639,6640,7,8,0,0,6640,6641,
	7,11,0,0,6641,6642,7,2,0,0,6642,6643,7,7,0,0,6643,6644,7,6,0,0,6644,410,
	1,0,0,0,6645,6646,7,8,0,0,6646,6647,7,11,0,0,6647,6648,7,2,0,0,6648,6649,
	7,5,0,0,6649,6650,7,6,0,0,6650,6651,5,95,0,0,6651,6652,7,8,0,0,6652,6653,
	7,0,0,0,6653,6654,7,8,0,0,6654,6655,7,19,0,0,6655,6656,7,6,0,0,6656,6657,
	7,9,0,0,6657,6658,5,95,0,0,6658,6659,7,2,0,0,6659,6660,7,15,0,0,6660,6661,
	7,6,0,0,6661,6662,7,7,0,0,6662,6663,5,95,0,0,6663,6664,7,8,0,0,6664,6665,
	7,10,0,0,6665,6666,7,3,0,0,6666,6667,7,5,0,0,6667,6668,7,2,0,0,6668,6669,
	7,3,0,0,6669,6670,7,5,0,0,6670,412,1,0,0,0,6671,6672,7,8,0,0,6672,6673,
	7,11,0,0,6673,6674,7,2,0,0,6674,6675,7,5,0,0,6675,6676,7,6,0,0,6676,414,
	1,0,0,0,6677,6678,7,8,0,0,6678,6679,7,11,0,0,6679,6680,7,10,0,0,6680,6681,
	7,5,0,0,6681,6682,7,4,0,0,6682,6683,7,6,0,0,6683,6684,7,3,0,0,6684,6685,
	5,95,0,0,6685,6686,7,1,0,0,6686,6687,7,18,0,0,6687,6688,5,95,0,0,6688,6689,
	7,3,0,0,6689,6690,7,2,0,0,6690,6691,7,22,0,0,6691,6692,7,12,0,0,6692,6693,
	7,9,0,0,6693,416,1,0,0,0,6694,6695,7,8,0,0,6695,6696,7,11,0,0,6696,6697,
	7,10,0,0,6697,6698,7,5,0,0,6698,6699,7,4,0,0,6699,6700,7,6,0,0,6700,6701,
	7,3,0,0,6701,418,1,0,0,0,6702,6703,7,8,0,0,6703,6704,7,11,0,0,6704,6705,
	7,10,0,0,6705,6706,7,5,0,0,6706,6707,7,4,0,0,6707,6708,7,6,0,0,6708,6709,
	7,3,0,0,6709,6710,5,95,0,0,6710,6711,7,9,0,0,6711,6712,7,6,0,0,6712,6713,
	7,4,0,0,6713,6714,7,0,0,0,6714,6715,7,12,0,0,6715,6716,7,11,0,0,6716,6717,
	7,5,0,0,6717,420,1,0,0,0,6718,6719,7,8,0,0,6719,6720,7,11,0,0,6720,6721,
	7,10,0,0,6721,6722,7,5,0,0,6722,6723,7,4,0,0,6723,6724,7,6,0,0,6724,6725,
	7,3,0,0,6725,6726,5,95,0,0,6726,6727,7,9,0,0,6727,6728,7,12,0,0,6728,6729,
	7,5,0,0,6729,6730,7,4,0,0,6730,6731,7,0,0,0,6731,6732,7,7,0,0,6732,6733,
	7,8,0,0,6733,6734,7,6,0,0,6734,422,1,0,0,0,6735,6736,7,8,0,0,6736,6737,
	7,11,0,0,6737,6738,7,10,0,0,6738,6739,7,5,0,0,6739,6740,7,4,0,0,6740,6741,
	7,6,0,0,6741,6742,7,3,0,0,6742,6743,5,95,0,0,6743,6744,7,12,0,0,6744,6745,
	7,9,0,0,6745,424,1,0,0,0,6746,6747,7,8,0,0,6747,6748,7,11,0,0,6748,6749,
	7,10,0,0,6749,6750,7,5,0,0,6750,6751,7,4,0,0,6751,6752,7,6,0,0,6752,6753,
	7,3,0,0,6753,6754,7,12,0,0,6754,6755,7,7,0,0,6755,6756,7,17,0,0,6756,426,
	1,0,0,0,6757,6758,7,8,0,0,6758,6759,7,11,0,0,6759,6760,7,10,0,0,6760,6761,
	7,5,0,0,6761,6762,7,4,0,0,6762,6763,7,6,0,0,6763,6764,7,3,0,0,6764,6765,
	7,12,0,0,6765,6766,7,7,0,0,6766,6767,7,17,0,0,6767,6768,5,95,0,0,6768,6769,
	7,16,0,0,6769,6770,7,0,0,0,6770,6771,7,8,0,0,6771,6772,7,4,0,0,6772,6773,
	7,2,0,0,6773,6774,7,3,0,0,6774,428,1,0,0,0,6775,6776,7,8,0,0,6776,6777,
	7,11,0,0,6777,6778,7,10,0,0,6778,6779,7,5,0,0,6779,6780,7,4,0,0,6780,6781,
	7,6,0,0,6781,6782,7,3,0,0,6782,6783,5,95,0,0,6783,6784,7,15,0,0,6784,6785,
	7,3,0,0,6785,6786,7,2,0,0,6786,6787,7,1,0,0,6787,6788,7,0,0,0,6788,6789,
	7,1,0,0,6789,6790,7,12,0,0,6790,6791,7,11,0,0,6791,6792,7,12,0,0,6792,6793,
	7,4,0,0,6793,6794,7,18,0,0,6794,430,1,0,0,0,6795,6796,7,8,0,0,6796,6797,
	7,11,0,0,6797,6798,7,10,0,0,6798,6799,7,5,0,0,6799,6800,7,4,0,0,6800,6801,
	7,6,0,0,6801,6802,7,3,0,0,6802,6803,5,95,0,0,6803,6804,7,5,0,0,6804,6805,
	7,6,0,0,6805,6806,7,4,0,0,6806,432,1,0,0,0,6807,6808,7,8,0,0,6808,6809,
	7,2,0,0,6809,6810,7,0,0,0,6810,6811,7,11,0,0,6811,6812,7,6,0,0,6812,6813,
	7,5,0,0,6813,6814,7,8,0,0,6814,6815,7,6,0,0,6815,434,1,0,0,0,6816,6817,
	7,8,0,0,6817,6818,7,2,0,0,6818,6819,7,0,0,0,6819,6820,7,11,0,0,6820,6821,
	7,6,0,0,6821,6822,7,5,0,0,6822,6823,7,8,0,0,6823,6824,7,6,0,0,6824,6825,
	5,95,0,0,6825,6826,7,5,0,0,6826,6827,7,24,0,0,6827,436,1,0,0,0,6828,6829,
	7,8,0,0,6829,6830,7,2,0,0,6830,6831,7,0,0,0,6831,6832,7,3,0,0,6832,6833,
	7,5,0,0,6833,6834,7,6,0,0,6834,438,1,0,0,0,6835,6836,7,8,0,0,6836,6837,
	7,2,0,0,6837,6838,5,95,0,0,6838,6839,7,0,0,0,6839,6840,7,10,0,0,6840,6841,
	7,4,0,0,6841,6842,7,19,0,0,6842,6843,5,95,0,0,6843,6844,7,12,0,0,6844,6845,
	7,7,0,0,6845,6846,7,9,0,0,6846,440,1,0,0,0,6847,6848,7,8,0,0,6848,6849,
	7,2,0,0,6849,6850,7,11,0,0,6850,6851,7,9,0,0,6851,442,1,0,0,0,6852,6853,
	7,8,0,0,6853,6854,7,2,0,0,6854,6855,7,11,0,0,6855,6856,7,11,0,0,6856,6857,
	7,6,0,0,6857,6858,7,8,0,0,6858,6859,7,4,0,0,6859,444,1,0,0,0,6860,6861,
	7,8,0,0,6861,6862,7,2,0,0,6862,6863,7,11,0,0,6863,6864,7,10,0,0,6864,6865,
	7,14,0,0,6865,6866,7,7,0,0,6866,6867,7,0,0,0,6867,6868,7,3,0,0,6868,446,
	1,0,0,0,6869,6870,7,8,0,0,6870,6871,7,2,0,0,6871,6872,7,11,0,0,6872,6873,
	7,10,0,0,6873,6874,7,14,0,0,6874,6875,7,7,0,0,6875,6876,5,95,0,0,6876,6877,
	7,0,0,0,6877,6878,7,10,0,0,6878,6879,7,4,0,0,6879,6880,7,19,0,0,6880,6881,
	5,95,0,0,6881,6882,7,12,0,0,6882,6883,7,7,0,0,6883,6884,7,9,0,0,6884,6885,
	7,12,0,0,6885,6886,7,8,0,0,6886,6887,7,0,0,0,6887,6888,7,4,0,0,6888,6889,
	7,2,0,0,6889,6890,7,3,0,0,6890,448,1,0,0,0,6891,6892,7,8,0,0,6892,6893,
	7,2,0,0,6893,6894,7,11,0,0,6894,6895,7,10,0,0,6895,6896,7,14,0,0,6896,6897,
	7,7,0,0,6897,450,1,0,0,0,6898,6899,7,8,0,0,6899,6900,7,2,0,0,6900,6901,
	7,11,0,0,6901,6902,7,10,0,0,6902,6903,7,14,0,0,6903,6904,7,7,0,0,6904,6905,
	7,5,0,0,6905,452,1,0,0,0,6906,6907,7,8,0,0,6907,6908,7,2,0,0,6908,6909,
	7,11,0,0,6909,6910,7,10,0,0,6910,6911,7,14,0,0,6911,6912,7,7,0,0,6912,6913,
	5,95,0,0,6913,6914,7,5,0,0,6914,6915,7,4,0,0,6915,6916,7,0,0,0,6916,6917,
	7,4,0,0,6917,6918,7,5,0,0,6918,454,1,0,0,0,6919,6920,7,8,0,0,6920,6921,
	7,2,0,0,6921,6922,7,11,0,0,6922,6923,7,10,0,0,6923,6924,7,14,0,0,6924,6925,
	7,7,0,0,6925,6926,5,95,0,0,6926,6927,7,13,0,0,6927,6928,7,0,0,0,6928,6929,
	7,11,0,0,6929,6930,7,10,0,0,6930,6931,7,6,0,0,6931,456,1,0,0,0,6932,6933,
	7,8,0,0,6933,6934,7,2,0,0,6934,6935,7,14,0,0,6935,6936,7,14,0,0,6936,6937,
	7,6,0,0,6937,6938,7,7,0,0,6938,6939,7,4,0,0,6939,458,1,0,0,0,6940,6941,
	7,8,0,0,6941,6942,7,2,0,0,6942,6943,7,14,0,0,6943,6944,7,14,0,0,6944,6945,
	7,12,0,0,6945,6946,7,4,0,0,6946,460,1,0,0,0,6947,6948,7,8,0,0,6948,6949,
	7,2,0,0,6949,6950,7,14,0,0,6950,6951,7,14,0,0,6951,6952,7,12,0,0,6952,6953,
	7,4,0,0,6953,6954,7,4,0,0,6954,6955,7,6,0,0,6955,6956,7,9,0,0,6956,462,
	1,0,0,0,6957,6958,7,8,0,0,6958,6959,7,2,0,0,6959,6960,7,14,0,0,6960,6961,
	7,14,0,0,6961,6962,7,2,0,0,6962,6963,7,7,0,0,6963,464,1,0,0,0,6964,6965,
	7,8,0,0,6965,6966,7,2,0,0,6966,6967,7,14,0,0,6967,6968,7,14,0,0,6968,6969,
	7,2,0,0,6969,6970,7,7,0,0,6970,6971,5,95,0,0,6971,6972,7,9,0,0,6972,6973,
	7,0,0,0,6973,6974,7,4,0,0,6974,6975,7,0,0,0,6975,466,1,0,0,0,6976,6977,
	7,8,0,0,6977,6978,7,2,0,0,6978,6979,7,14,0,0,6979,6980,7,15,0,0,6980,6981,
	7,0,0,0,6981,6982,7,8,0,0,6982,6983,7,4,0,0,6983,468,1,0,0,0,6984,6985,
	7,8,0,0,6985,6986,7,2,0,0,6986,6987,7,14,0,0,6987,6988,7,15,0,0,6988,6989,
	7,0,0,0,6989,6990,7,4,0,0,6990,6991,7,12,0,0,6991,6992,7,1,0,0,6992,6993,
	7,12,0,0,6993,6994,7,11,0,0,6994,6995,7,12,0,0,6995,6996,7,4,0,0,6996,6997,
	7,18,0,0,6997,470,1,0,0,0,6998,6999,7,8,0,0,6999,7000,7,2,0,0,7000,7001,
	7,14,0,0,7001,7002,7,15,0,0,7002,7003,7,12,0,0,7003,7004,7,11,0,0,7004,
	7005,7,6,0,0,7005,472,1,0,0,0,7006,7007,7,8,0,0,7007,7008,7,2,0,0,7008,
	7009,7,14,0,0,7009,7010,7,15,0,0,7010,7011,7,11,0,0,7011,7012,7,6,0,0,7012,
	7013,7,4,0,0,7013,7014,7,6,0,0,7014,474,1,0,0,0,7015,7016,7,8,0,0,7016,
	7017,7,2,0,0,7017,7018,7,14,0,0,7018,7019,7,15,0,0,7019,7020,7,11,0,0,7020,
	7021,7,12,0,0,7021,7022,7,0,0,0,7022,7023,7,7,0,0,7023,7024,7,8,0,0,7024,
	7025,7,6,0,0,7025,476,1,0,0,0,7026,7027,7,8,0,0,7027,7028,7,2,0,0,7028,
	7029,7,14,0,0,7029,7030,7,15,0,0,7030,7031,7,2,0,0,7031,7032,7,7,0,0,7032,
	7033,7,6,0,0,7033,7034,7,7,0,0,7034,7035,7,4,0,0,7035,478,1,0,0,0,7036,
	7037,7,8,0,0,7037,7038,7,2,0,0,7038,7039,7,14,0,0,7039,7040,7,15,0,0,7040,
	7041,7,2,0,0,7041,7042,7,7,0,0,7042,7043,7,6,0,0,7043,7044,7,7,0,0,7044,
	7045,7,4,0,0,7045,7046,7,5,0,0,7046,480,1,0,0,0,7047,7048,7,8,0,0,7048,
	7049,7,2,0,0,7049,7050,7,14,0,0,7050,7051,7,15,0,0,7051,7052,7,2,0,0,7052,
	7053,7,5,0,0,7053,7054,7,6,0,0,7054,482,1,0,0,0,7055,7056,7,8,0,0,7056,
	7057,7,2,0,0,7057,7058,7,14,0,0,7058,7059,7,15,0,0,7059,7060,7,2,0,0,7060,
	7061,7,5,0,0,7061,7062,7,12,0,0,7062,7063,7,4,0,0,7063,7064,7,6,0,0,7064,
	484,1,0,0,0,7065,7066,7,8,0,0,7066,7067,7,2,0,0,7067,7068,7,14,0,0,7068,
	7069,7,15,0,0,7069,7070,7,2,0,0,7070,7071,7,5,0,0,7071,7072,7,12,0,0,7072,
	7073,7,4,0,0,7073,7074,7,6,0,0,7074,7075,5,95,0,0,7075,7076,7,11,0,0,7076,
	7077,7,12,0,0,7077,7078,7,14,0,0,7078,7079,7,12,0,0,7079,7080,7,4,0,0,7080,
	486,1,0,0,0,7081,7082,7,8,0,0,7082,7083,7,2,0,0,7083,7084,7,14,0,0,7084,
	7085,7,15,0,0,7085,7086,7,2,0,0,7086,7087,7,10,0,0,7087,7088,7,7,0,0,7088,
	7089,7,9,0,0,7089,488,1,0,0,0,7090,7091,7,8,0,0,7091,7092,7,2,0,0,7092,
	7093,7,14,0,0,7093,7094,7,15,0,0,7094,7095,7,3,0,0,7095,7096,7,6,0,0,7096,
	7097,7,5,0,0,7097,7098,7,5,0,0,7098,490,1,0,0,0,7099,7100,7,8,0,0,7100,
	7101,7,2,0,0,7101,7102,7,14,0,0,7102,7103,7,15,0,0,7103,7104,7,10,0,0,7104,
	7105,7,4,0,0,7105,7106,7,6,0,0,7106,492,1,0,0,0,7107,7108,7,8,0,0,7108,
	7109,7,2,0,0,7109,7110,7,7,0,0,7110,7111,7,8,0,0,7111,7112,7,0,0,0,7112,
	7113,7,4,0,0,7113,494,1,0,0,0,7114,7115,7,8,0,0,7115,7116,7,2,0,0,7116,
	7117,7,7,0,0,7117,7118,5,95,0,0,7118,7119,7,9,0,0,7119,7120,7,1,0,0,7120,
	7121,7,12,0,0,7121,7122,7,9,0,0,7122,7123,5,95,0,0,7123,7124,7,4,0,0,7124,
	7125,7,2,0,0,7125,7126,5,95,0,0,7126,7127,7,12,0,0,7127,7128,7,9,0,0,7128,
	496,1,0,0,0,7129,7130,7,8,0,0,7130,7131,7,2,0,0,7131,7132,7,7,0,0,7132,
	7133,7,9,0,0,7133,7134,7,12,0,0,7134,7135,7,4,0,0,7135,7136,7,12,0,0,7136,
	7137,7,2,0,0,7137,7138,7,7,0,0,7138,7139,7,0,0,0,7139,7140,7,11,0,0,7140,
	498,1,0,0,0,7141,7142,7,8,0,0,7142,7143,7,2,0,0,7143,7144,7,7,0,0,7144,
	7145,7,9,0,0,7145,7146,7,12,0,0,7146,7147,7,4,0,0,7147,7148,7,12,0,0,7148,
	7149,7,2,0,0,7149,7150,7,7,0,0,7150,500,1,0,0,0,7151,7152,7,8,0,0,7152,
	7153,7,2,0,0,7153,7154,7,7,0,0,7154,7155,7,16,0,0,7155,7156,7,12,0,0,7156,
	7157,7,3,0,0,7157,7158,7,14,0,0,7158,502,1,0,0,0,7159,7160,7,8,0,0,7160,
	7161,7,2,0,0,7161,7162,7,7,0,0,7162,7163,7,16,0,0,7163,7164,7,2,0,0,7164,
	7165,7,3,0,0,7165,7166,7,14,0,0,7166,7167,7,12,0,0,7167,7168,7,7,0,0,7168,
	7169,7,17,0,0,7169,504,1,0,0,0,7170,7171,7,8,0,0,7171,7172,7,2,0,0,7172,
	7173,7,7,0,0,7173,7174,5,95,0,0,7174,7175,7,17,0,0,7175,7176,7,10,0,0,7176,
	7177,7,12,0,0,7177,7178,7,9,0,0,7178,7179,5,95,0,0,7179,7180,7,4,0,0,7180,
	7181,7,2,0,0,7181,7182,5,95,0,0,7182,7183,7,12,0,0,7183,7184,7,9,0,0,7184,
	506,1,0,0,0,7185,7186,7,8,0,0,7186,7187,7,2,0,0,7187,7188,7,7,0,0,7188,
	7189,5,95,0,0,7189,7190,7,12,0,0,7190,7191,7,9,0,0,7191,508,1,0,0,0,7192,
	7193,7,8,0,0,7193,7194,7,2,0,0,7194,7195,7,7,0,0,7195,7196,5,95,0,0,7196,
	7197,7,7,0,0,7197,7198,7,0,0,0,7198,7199,7,14,0,0,7199,7200,7,6,0,0,7200,
	7201,5,95,0,0,7201,7202,7,4,0,0,7202,7203,7,2,0,0,7203,7204,5,95,0,0,7204,
	7205,7,12,0,0,7205,7206,7,9,0,0,7206,510,1,0,0,0,7207,7208,7,8,0,0,7208,
	7209,7,2,0,0,7209,7210,7,7,0,0,7210,7211,7,7,0,0,7211,7212,7,6,0,0,7212,
	7213,7,8,0,0,7213,7214,7,4,0,0,7214,7215,5,95,0,0,7215,7216,7,1,0,0,7216,
	7217,7,18,0,0,7217,7218,5,95,0,0,7218,7219,7,8,0,0,7219,7220,7,1,0,0,7220,
	7221,5,95,0,0,7221,7222,7,22,0,0,7222,7223,7,19,0,0,7223,7224,7,3,0,0,7224,
	7225,5,95,0,0,7225,7226,7,2,0,0,7226,7227,7,7,0,0,7227,7228,7,11,0,0,7228,
	7229,7,18,0,0,7229,512,1,0,0,0,7230,7231,7,8,0,0,7231,7232,7,2,0,0,7232,
	7233,7,7,0,0,7233,7234,7,7,0,0,7234,7235,7,6,0,0,7235,7236,7,8,0,0,7236,
	7237,7,4,0,0,7237,7238,5,95,0,0,7238,7239,7,1,0,0,7239,7240,7,18,0,0,7240,
	7241,5,95,0,0,7241,7242,7,8,0,0,7242,7243,7,2,0,0,7243,7244,7,14,0,0,7244,
	7245,7,1,0,0,7245,7246,7,12,0,0,7246,7247,7,7,0,0,7247,7248,7,6,0,0,7248,
	7249,5,95,0,0,7249,7250,7,5,0,0,7250,7251,7,22,0,0,7251,514,1,0,0,0,7252,
	7253,7,8,0,0,7253,7254,7,2,0,0,7254,7255,7,7,0,0,7255,7256,7,7,0,0,7256,
	7257,7,6,0,0,7257,7258,7,8,0,0,7258,7259,7,4,0,0,7259,7260,5,95,0,0,7260,
	7261,7,1,0,0,7261,7262,7,18,0,0,7262,7263,5,95,0,0,7263,7264,7,8,0,0,7264,
	7265,7,2,0,0,7265,7266,7,5,0,0,7266,7267,7,4,0,0,7267,7268,5,95,0,0,7268,
	7269,7,1,0,0,7269,7270,7,0,0,0,7270,7271,7,5,0,0,7271,7272,7,6,0,0,7272,
	7273,7,9,0,0,7273,516,1,0,0,0,7274,7275,7,8,0,0,7275,7276,7,2,0,0,7276,
	7277,7,7,0,0,7277,7278,7,7,0,0,7278,7279,7,6,0,0,7279,7280,7,8,0,0,7280,
	7281,7,4,0,0,7281,7282,5,95,0,0,7282,7283,7,1,0,0,7283,7284,7,18,0,0,7284,
	7285,5,95,0,0,7285,7286,7,6,0,0,7286,7287,7,11,0,0,7287,7288,7,12,0,0,7288,
	7289,7,14,0,0,7289,7290,5,95,0,0,7290,7291,7,9,0,0,7291,7292,7,10,0,0,7292,
	7293,7,15,0,0,7293,7294,7,5,0,0,7294,518,1,0,0,0,7295,7296,7,8,0,0,7296,
	7297,7,2,0,0,7297,7298,7,7,0,0,7298,7299,7,7,0,0,7299,7300,7,6,0,0,7300,
	7301,7,8,0,0,7301,7302,7,4,0,0,7302,7303,5,95,0,0,7303,7304,7,1,0,0,7304,
	7305,7,18,0,0,7305,7306,5,95,0,0,7306,7307,7,16,0,0,7307,7308,7,12,0,0,
	7308,7309,7,11,0,0,7309,7310,7,4,0,0,7310,7311,7,6,0,0,7311,7312,7,3,0,
	0,7312,7313,7,12,0,0,7313,7314,7,7,0,0,7314,7315,7,17,0,0,7315,520,1,0,
	0,0,7316,7317,7,8,0,0,7317,7318,7,2,0,0,7318,7319,7,7,0,0,7319,7320,7,7,
	0,0,7320,7321,7,6,0,0,7321,7322,7,8,0,0,7322,7323,7,4,0,0,7323,7324,5,95,
	0,0,7324,7325,7,1,0,0,7325,7326,7,18,0,0,7326,7327,5,95,0,0,7327,7328,7,
	12,0,0,7328,7329,7,5,0,0,7329,7330,7,8,0,0,7330,7331,7,18,0,0,7331,7332,
	7,8,0,0,7332,7333,7,11,0,0,7333,7334,7,6,0,0,7334,522,1,0,0,0,7335,7336,
	7,8,0,0,7336,7337,7,2,0,0,7337,7338,7,7,0,0,7338,7339,7,7,0,0,7339,7340,
	7,6,0,0,7340,7341,7,8,0,0,7341,7342,7,4,0,0,7342,7343,5,95,0,0,7343,7344,
	7,1,0,0,7344,7345,7,18,0,0,7345,7346,5,95,0,0,7346,7347,7,12,0,0,7347,7348,
	7,5,0,0,7348,7349,7,11,0,0,7349,7350,7,6,0,0,7350,7351,7,0,0,0,7351,7352,
	7,16,0,0,7352,524,1,0,0,0,7353,7354,7,8,0,0,7354,7355,7,2,0,0,7355,7356,
	7,7,0,0,7356,7357,7,7,0,0,7357,7358,7,6,0,0,7358,7359,7,8,0,0,7359,7360,
	7,4,0,0,7360,7361,5,95,0,0,7361,7362,7,1,0,0,7362,7363,7,18,0,0,7363,7364,
	5,95,0,0,7364,7365,7,3,0,0,7365,7366,7,2,0,0,7366,7367,7,2,0,0,7367,7368,
	7,4,0,0,7368,526,1,0,0,0,7369,7370,7,8,0,0,7370,7371,7,2,0,0,7371,7372,
	7,7,0,0,7372,7373,7,7,0,0,7373,7374,7,6,0,0,7374,7375,7,8,0,0,7375,7376,
	7,4,0,0,7376,528,1,0,0,0,7377,7378,7,8,0,0,7378,7379,7,2,0,0,7379,7380,
	7,7,0,0,7380,7381,7,7,0,0,7381,7382,7,6,0,0,7382,7383,7,8,0,0,7383,7384,
	7,4,0,0,7384,7385,5,95,0,0,7385,7386,7,4,0,0,7386,7387,7,12,0,0,7387,7388,
	7,14,0,0,7388,7389,7,6,0,0,7389,530,1,0,0,0,7390,7391,7,8,0,0,7391,7392,
	7,2,0,0,7392,7393,7,7,0,0,7393,7394,7,5,0,0,7394,7395,7,12,0,0,7395,7396,
	7,9,0,0,7396,7397,7,6,0,0,7397,7398,7,3,0,0,7398,532,1,0,0,0,7399,7400,
	7,8,0,0,7400,7401,7,2,0,0,7401,7402,7,7,0,0,7402,7403,7,5,0,0,7403,7404,
	7,12,0,0,7404,7405,7,5,0,0,7405,7406,7,4,0,0,7406,7407,7,6,0,0,7407,7408,
	7,7,0,0,7408,7409,7,4,0,0,7409,534,1,0,0,0,7410,7411,7,8,0,0,7411,7412,
	7,2,0,0,7412,7413,7,7,0,0,7413,7414,7,5,0,0,7414,7415,7,4,0,0,7415,7416,
	7,0,0,0,7416,7417,7,7,0,0,7417,7418,7,4,0,0,7418,536,1,0,0,0,7419,7420,
	7,8,0,0,7420,7421,7,2,0,0,7421,7422,7,7,0,0,7422,7423,7,5,0,0,7423,7424,
	7,4,0,0,7424,538,1,0,0,0,7425,7426,7,8,0,0,7426,7427,7,2,0,0,7427,7428,
	7,7,0,0,7428,7429,7,5,0,0,7429,7430,7,4,0,0,7430,7431,7,3,0,0,7431,7432,
	7,0,0,0,7432,7433,7,12,0,0,7433,7434,7,7,0,0,7434,7435,7,4,0,0,7435,540,
	1,0,0,0,7436,7437,7,8,0,0,7437,7438,7,2,0,0,7438,7439,7,7,0,0,7439,7440,
	7,5,0,0,7440,7441,7,4,0,0,7441,7442,7,3,0,0,7442,7443,7,0,0,0,7443,7444,
	7,12,0,0,7444,7445,7,7,0,0,7445,7446,7,4,0,0,7446,7447,7,5,0,0,7447,542,
	1,0,0,0,7448,7449,7,8,0,0,7449,7450,7,2,0,0,7450,7451,7,7,0,0,7451,7452,
	7,5,0,0,7452,7453,7,4,0,0,7453,7454,7,3,0,0,7454,7455,7,10,0,0,7455,7456,
	7,8,0,0,7456,7457,7,4,0,0,7457,7458,7,2,0,0,7458,7459,7,3,0,0,7459,544,
	1,0,0,0,7460,7461,7,8,0,0,7461,7462,7,2,0,0,7462,7463,7,7,0,0,7463,7464,
	7,4,0,0,7464,7465,7,0,0,0,7465,7466,7,12,0,0,7466,7467,7,7,0,0,7467,7468,
	7,6,0,0,7468,7469,7,3,0,0,7469,546,1,0,0,0,7470,7471,7,8,0,0,7471,7472,
	7,2,0,0,7472,7473,7,7,0,0,7473,7474,7,4,0,0,7474,7475,7,0,0,0,7475,7476,
	7,12,0,0,7476,7477,7,7,0,0,7477,7478,7,6,0,0,7478,7479,7,3,0,0,7479,7480,
	7,5,0,0,7480,548,1,0,0,0,7481,7482,7,8,0,0,7482,7483,7,2,0,0,7483,7484,
	7,7,0,0,7484,7485,7,4,0,0,7485,7486,7,0,0,0,7486,7487,7,12,0,0,7487,7488,
	7,7,0,0,7488,7489,7,6,0,0,7489,7490,7,3,0,0,7490,7491,7,5,0,0,7491,7492,
	5,95,0,0,7492,7493,7,9,0,0,7493,7494,7,6,0,0,7494,7495,7,16,0,0,7495,7496,
	7,0,0,0,7496,7497,7,10,0,0,7497,7498,7,11,0,0,7498,7499,7,4,0,0,7499,550,
	1,0,0,0,7500,7501,7,8,0,0,7501,7502,7,2,0,0,7502,7503,7,7,0,0,7503,7504,
	7,4,0,0,7504,7505,7,0,0,0,7505,7506,7,12,0,0,7506,7507,7,7,0,0,7507,7508,
	7,6,0,0,7508,7509,7,3,0,0,7509,7510,5,95,0,0,7510,7511,7,9,0,0,7511,7512,
	7,0,0,0,7512,7513,7,4,0,0,7513,7514,7,0,0,0,7514,552,1,0,0,0,7515,7516,
	7,8,0,0,7516,7517,7,2,0,0,7517,7518,7,7,0,0,7518,7519,7,4,0,0,7519,7520,
	7,0,0,0,7520,7521,7,12,0,0,7521,7522,7,7,0,0,7522,7523,7,6,0,0,7523,7524,
	7,3,0,0,7524,7525,5,95,0,0,7525,7526,7,14,0,0,7526,7527,7,0,0,0,7527,7528,
	7,15,0,0,7528,554,1,0,0,0,7529,7530,7,8,0,0,7530,7531,7,2,0,0,7531,7532,
	7,7,0,0,7532,7533,7,4,0,0,7533,7534,7,6,0,0,7534,7535,7,7,0,0,7535,7536,
	7,4,0,0,7536,556,1,0,0,0,7537,7538,7,8,0,0,7538,7539,7,2,0,0,7539,7540,
	7,7,0,0,7540,7541,7,4,0,0,7541,7542,7,6,0,0,7542,7543,7,7,0,0,7543,7544,
	7,4,0,0,7544,7545,7,5,0,0,7545,558,1,0,0,0,7546,7547,7,8,0,0,7547,7548,
	7,2,0,0,7548,7549,7,7,0,0,7549,7550,7,4,0,0,7550,7551,7,6,0,0,7551,7552,
	7,25,0,0,7552,7553,7,4,0,0,7553,560,1,0,0,0,7554,7555,7,8,0,0,7555,7556,
	7,2,0,0,7556,7557,7,7,0,0,7557,7558,7,4,0,0,7558,7559,7,12,0,0,7559,7560,
	7,7,0,0,7560,7561,7,10,0,0,7561,7562,7,6,0,0,7562,562,1,0,0,0,7563,7564,
	7,8,0,0,7564,7565,7,2,0,0,7565,7566,7,7,0,0,7566,7567,7,4,0,0,7567,7568,
	7,3,0,0,7568,7569,7,2,0,0,7569,7570,7,11,0,0,7570,7571,7,16,0,0,7571,7572,
	7,12,0,0,7572,7573,7,11,0,0,7573,7574,7,6,0,0,7574,564,1,0,0,0,7575,7576,
	7,8,0,0,7576,7577,7,2,0,0,7577,7578,7,7,0,0,7578,7579,5,95,0,0,7579,7580,
	7,10,0,0,7580,7581,7,12,0,0,7581,7582,7,9,0,0,7582,7583,5,95,0,0,7583,7584,
	7,4,0,0,7584,7585,7,2,0,0,7585,7586,5,95,0,0,7586,7587,7,12,0,0,7587,7588,
	7,9,0,0,7588,566,1,0,0,0,7589,7590,7,8,0,0,7590,7591,7,2,0,0,7591,7592,
	7,7,0,0,7592,7593,7,13,0,0,7593,7594,7,6,0,0,7594,7595,7,3,0,0,7595,7596,
	7,4,0,0,7596,568,1,0,0,0,7597,7598,7,8,0,0,7598,7599,7,2,0,0,7599,7600,
	7,7,0,0,7600,7601,7,13,0,0,7601,7602,7,6,0,0,7602,7603,7,3,0,0,7603,7604,
	7,5,0,0,7604,7605,7,12,0,0,7605,7606,7,2,0,0,7606,7607,7,7,0,0,7607,570,
	1,0,0,0,7608,7609,7,8,0,0,7609,7610,7,2,0,0,7610,7611,7,2,0,0,7611,7612,
	7,21,0,0,7612,7613,7,12,0,0,7613,7614,7,6,0,0,7614,572,1,0,0,0,7615,7616,
	7,8,0,0,7616,7617,7,2,0,0,7617,7618,7,15,0,0,7618,7619,7,18,0,0,7619,574,
	1,0,0,0,7620,7621,7,8,0,0,7621,7622,7,2,0,0,7622,7623,7,3,0,0,7623,7624,
	7,3,0,0,7624,7625,5,95,0,0,7625,7626,7,21,0,0,7626,576,1,0,0,0,7627,7628,
	7,8,0,0,7628,7629,7,2,0,0,7629,7630,7,3,0,0,7630,7631,7,3,0,0,7631,7632,
	5,95,0,0,7632,7633,7,5,0,0,7633,578,1,0,0,0,7634,7635,7,8,0,0,7635,7636,
	7,2,0,0,7636,7637,7,3,0,0,7637,7638,7,3,0,0,7638,7639,7,10,0,0,7639,7640,
	7,15,0,0,7640,7641,7,4,0,0,7641,7642,7,12,0,0,7642,7643,7,2,0,0,7643,7644,
	7,7,0,0,7644,580,1,0,0,0,7645,7646,7,8,0,0,7646,7647,7,2,0,0,7647,7648,
	7,3,0,0,7648,7649,7,3,0,0,7649,7650,7,10,0,0,7650,7651,7,15,0,0,7651,7652,
	7,4,0,0,7652,7653,5,95,0,0,7653,7654,7,25,0,0,7654,7655,7,12,0,0,7655,7656,
	7,9,0,0,7656,7657,5,95,0,0,7657,7658,7,0,0,0,7658,7659,7,11,0,0,7659,7660,
	7,11,0,0,7660,582,1,0,0,0,7661,7662,7,8,0,0,7662,7663,7,2,0,0,7663,7664,
	7,3,0,0,7664,7665,7,3,0,0,7665,7666,7,10,0,0,7666,7667,7,15,0,0,7667,7668,
	7,4,0,0,7668,7669,5,95,0,0,7669,7670,7,25,0,0,7670,7671,7,12,0,0,7671,7672,
	7,9,0,0,7672,584,1,0,0,0,7673,7674,7,8,0,0,7674,7675,7,2,0,0,7675,7676,
	7,5,0,0,7676,586,1,0,0,0,7677,7678,7,8,0,0,7678,7679,7,2,0,0,7679,7680,
	7,5,0,0,7680,7681,7,19,0,0,7681,588,1,0,0,0,7682,7683,7,8,0,0,7683,7684,
	7,2,0,0,7684,7685,7,5,0,0,7685,7686,7,4,0,0,7686,590,1,0,0,0,7687,7688,
	7,8,0,0,7688,7689,7,2,0,0,7689,7690,7,5,0,0,7690,7691,7,4,0,0,7691,7692,
	5,95,0,0,7692,7693,7,25,0,0,7693,7694,7,14,0,0,7694,7695,7,11,0,0,7695,
	7696,5,95,0,0,7696,7697,7,24,0,0,7697,7698,7,10,0,0,7698,7699,7,6,0,0,7699,
	7700,7,3,0,0,7700,7701,7,18,0,0,7701,7702,5,95,0,0,7702,7703,7,3,0,0,7703,
	7704,7,6,0,0,7704,7705,7,22,0,0,7705,7706,7,3,0,0,7706,7707,7,12,0,0,7707,
	7708,7,4,0,0,7708,7709,7,6,0,0,7709,592,1,0,0,0,7710,7711,7,8,0,0,7711,
	7712,7,2,0,0,7712,7713,7,10,0,0,7713,7714,7,7,0,0,7714,7715,7,4,0,0,7715,
	594,1,0,0,0,7716,7717,7,8,0,0,7717,7718,7,2,0,0,7718,7719,7,13,0,0,7719,
	7720,7,0,0,0,7720,7721,7,3,0,0,7721,7722,5,95,0,0,7722,7723,7,15,0,0,7723,
	7724,7,2,0,0,7724,7725,7,15,0,0,7725,596,1,0,0,0,7726,7727,7,8,0,0,7727,
	7728,7,2,0,0,7728,7729,7,13,0,0,7729,7730,7,0,0,0,7730,7731,7,3,0,0,7731,
	7732,5,95,0,0,7732,7733,7,5,0,0,7733,7734,7,0,0,0,7734,7735,7,14,0,0,7735,
	7736,7,15,0,0,7736,598,1,0,0,0,7737,7738,7,8,0,0,7738,7739,7,15,0,0,7739,
	7740,7,10,0,0,7740,7741,5,95,0,0,7741,7742,7,8,0,0,7742,7743,7,2,0,0,7743,
	7744,7,5,0,0,7744,7745,7,4,0,0,7745,7746,7,12,0,0,7746,7747,7,7,0,0,7747,
	7748,7,17,0,0,7748,600,1,0,0,0,7749,7750,7,8,0,0,7750,7751,7,15,0,0,7751,
	7752,7,10,0,0,7752,7753,5,95,0,0,7753,7754,7,15,0,0,7754,7755,7,6,0,0,7755,
	7756,7,3,0,0,7756,7757,5,95,0,0,7757,7758,7,8,0,0,7758,7759,7,0,0,0,7759,
	7760,7,11,0,0,7760,7761,7,11,0,0,7761,602,1,0,0,0,7762,7763,7,8,0,0,7763,
	7764,7,15,0,0,7764,7765,7,10,0,0,7765,7766,5,95,0,0,7766,7767,7,15,0,0,
	7767,7768,7,6,0,0,7768,7769,7,3,0,0,7769,7770,5,95,0,0,7770,7771,7,5,0,
	0,7771,7772,7,6,0,0,7772,7773,7,5,0,0,7773,7774,7,5,0,0,7774,7775,7,12,
	0,0,7775,7776,7,2,0,0,7776,7777,7,7,0,0,7777,604,1,0,0,0,7778,7779,7,8,
	0,0,7779,7780,7,3,0,0,7780,7781,7,0,0,0,7781,7782,7,5,0,0,7782,7783,7,19,
	0,0,7783,606,1,0,0,0,7784,7785,7,8,0,0,7785,7786,7,3,0,0,7786,7787,7,6,
	0,0,7787,7788,7,0,0,0,7788,7789,7,4,0,0,7789,7790,7,6,0,0,7790,608,1,0,
	0,0,7791,7792,7,8,0,0,7792,7793,7,3,0,0,7793,7794,7,6,0,0,7794,7795,7,0,
	0,0,7795,7796,7,4,0,0,7796,7797,7,6,0,0,7797,7798,5,95,0,0,7798,7799,7,
	16,0,0,7799,7800,7,12,0,0,7800,7801,7,11,0,0,7801,7802,7,6,0,0,7802,7803,
	5,95,0,0,7803,7804,7,9,0,0,7804,7805,7,6,0,0,7805,7806,7,5,0,0,7806,7807,
	7,4,0,0,7807,610,1,0,0,0,7808,7809,7,8,0,0,7809,7810,7,3,0,0,7810,7811,
	7,6,0,0,7811,7812,7,0,0,0,7812,7813,7,4,0,0,7813,7814,7,6,0,0,7814,7815,
	5,95,0,0,7815,7816,7,5,0,0,7816,7817,7,4,0,0,7817,7818,7,2,0,0,7818,7819,
	7,3,0,0,7819,7820,7,6,0,0,7820,7821,7,9,0,0,7821,7822,5,95,0,0,7822,7823,
	7,2,0,0,7823,7824,7,10,0,0,7824,7825,7,4,0,0,7825,7826,7,11,0,0,7826,7827,
	7,12,0,0,7827,7828,7,7,0,0,7828,7829,7,6,0,0,7829,7830,7,5,0,0,7830,612,
	1,0,0,0,7831,7832,7,8,0,0,7832,7833,7,3,0,0,7833,7834,7,6,0,0,7834,7835,
	7,0,0,0,7835,7836,7,4,0,0,7836,7837,7,12,0,0,7837,7838,7,2,0,0,7838,7839,
	7,7,0,0,7839,614,1,0,0,0,7840,7841,7,8,0,0,7841,7842,7,3,0,0,7842,7843,
	7,6,0,0,7843,7844,7,9,0,0,7844,7845,7,6,0,0,7845,7846,7,7,0,0,7846,7847,
	7,4,0,0,7847,7848,7,12,0,0,7848,7849,7,0,0,0,7849,7850,7,11,0,0,7850,616,
	1,0,0,0,7851,7852,7,8,0,0,7852,7853,7,3,0,0,7853,7854,7,12,0,0,7854,7855,
	7,4,0,0,7855,7856,7,12,0,0,7856,7857,7,8,0,0,7857,7858,7,0,0,0,7858,7859,
	7,11,0,0,7859,618,1,0,0,0,7860,7861,7,8,0,0,7861,7862,7,3,0,0,7862,7863,
	7,2,0,0,7863,7864,7,5,0,0,7864,7865,7,5,0,0,7865,620,1,0,0,0,7866,7867,
	7,8,0,0,7867,7868,7,3,0,0,7868,7869,7,2,0,0,7869,7870,7,5,0,0,7870,7871,
	7,5,0,0,7871,7872,7,6,0,0,7872,7873,7,9,0,0,7873,7874,7,12,0,0,7874,7875,
	7,4,0,0,7875,7876,7,12,0,0,7876,7877,7,2,0,0,7877,7878,7,7,0,0,7878,622,
	1,0,0,0,7879,7880,7,8,0,0,7880,7881,7,5,0,0,7881,7882,7,8,0,0,7882,7883,
	7,2,0,0,7883,7884,7,7,0,0,7884,7885,7,13,0,0,7885,7886,7,6,0,0,7886,7887,
	7,3,0,0,7887,7888,7,4,0,0,7888,624,1,0,0,0,7889,7890,7,8,0,0,7890,7891,
	7,10,0,0,7891,7892,7,1,0,0,7892,7893,7,6,0,0,7893,7894,5,95,0,0,7894,7895,
	7,0,0,0,7895,7896,7,20,0,0,7896,626,1,0,0,0,7897,7898,7,8,0,0,7898,7899,
	7,10,0,0,7899,7900,7,1,0,0,7900,7901,7,6,0,0,7901,628,1,0,0,0,7902,7903,
	7,8,0,0,7903,7904,7,10,0,0,7904,7905,7,1,0,0,7905,7906,7,6,0,0,7906,7907,
	5,95,0,0,7907,7908,7,17,0,0,7908,7909,7,1,0,0,7909,630,1,0,0,0,7910,7911,
	7,8,0,0,7911,7912,7,10,0,0,7912,7913,7,1,0,0,7913,7914,7,6,0,0,7914,7915,
	5,95,0,0,7915,7916,7,5,0,0,7916,7917,7,20,0,0,7917,632,1,0,0,0,7918,7919,
	7,8,0,0,7919,7920,7,10,0,0,7920,7921,7,14,0,0,7921,7922,7,6,0,0,7922,7923,
	5,95,0,0,7923,7924,7,9,0,0,7924,7925,7,12,0,0,7925,7926,7,5,0,0,7926,7927,
	7,4,0,0,7927,7928,7,14,0,0,7928,634,1,0,0,0,7929,7930,7,8,0,0,7930,7931,
	7,10,0,0,7931,7932,7,3,0,0,7932,7933,7,3,0,0,7933,7934,7,6,0,0,7934,7935,
	7,7,0,0,7935,7936,7,4,0,0,7936,636,1,0,0,0,7937,7938,7,8,0,0,7938,7939,
	7,10,0,0,7939,7940,7,3,0,0,7940,7941,7,3,0,0,7941,7942,7,6,0,0,7942,7943,
	7,7,0,0,7943,7944,7,4,0,0,7944,7945,5,95,0,0,7945,7946,7,9,0,0,7946,7947,
	7,0,0,0,7947,7948,7,4,0,0,7948,7949,7,6,0,0,7949,638,1,0,0,0,7950,7951,
	7,8,0,0,7951,7952,7,10,0,0,7952,7953,7,3,0,0,7953,7954,7,3,0,0,7954,7955,
	7,6,0,0,7955,7956,7,7,0,0,7956,7957,7,4,0,0,7957,7958,5,95,0,0,7958,7959,
	7,5,0,0,7959,7960,7,8,0,0,7960,7961,7,19,0,0,7961,7962,7,6,0,0,7962,7963,
	7,14,0,0,7963,7964,7,0,0,0,7964,640,1,0,0,0,7965,7966,7,8,0,0,7966,7967,
	7,10,0,0,7967,7968,7,3,0,0,7968,7969,7,3,0,0,7969,7970,7,6,0,0,7970,7971,
	7,7,0,0,7971,7972,7,4,0,0,7972,7973,5,95,0,0,7973,7974,7,4,0,0,7974,7975,
	7,12,0,0,7975,7976,7,14,0,0,7976,7977,7,6,0,0,7977,642,1,0,0,0,7978,7979,
	7,8,0,0,7979,7980,7,10,0,0,7980,7981,7,3,0,0,7981,7982,7,3,0,0,7982,7983,
	7,6,0,0,7983,7984,7,7,0,0,7984,7985,7,4,0,0,7985,7986,5,95,0,0,7986,7987,
	7,4,0,0,7987,7988,7,12,0,0,7988,7989,7,14,0,0,7989,7990,7,6,0,0,7990,7991,
	7,5,0,0,7991,7992,7,4,0,0,7992,7993,7,0,0,0,7993,7994,7,14,0,0,7994,7995,
	7,15,0,0,7995,644,1,0,0,0,7996,7997,7,8,0,0,7997,7998,7,10,0,0,7998,7999,
	7,3,0,0,7999,8000,7,3,0,0,8000,8001,7,6,0,0,8001,8002,7,7,0,0,8002,8003,
	7,4,0,0,8003,8004,5,95,0,0,8004,8005,7,10,0,0,8005,8006,7,5,0,0,8006,8007,
	7,6,0,0,8007,8008,7,3,0,0,8008,646,1,0,0,0,8009,8010,7,8,0,0,8010,8011,
	7,10,0,0,8011,8012,7,3,0,0,8012,8013,7,3,0,0,8013,8014,7,6,0,0,8014,8015,
	7,7,0,0,8015,8016,7,4,0,0,8016,8017,7,13,0,0,8017,648,1,0,0,0,8018,8019,
	7,8,0,0,8019,8020,7,10,0,0,8020,8021,7,3,0,0,8021,8022,7,5,0,0,8022,8023,
	7,2,0,0,8023,8024,7,3,0,0,8024,650,1,0,0,0,8025,8026,7,8,0,0,8026,8027,
	7,10,0,0,8027,8028,7,3,0,0,8028,8029,7,5,0,0,8029,8030,7,2,0,0,8030,8031,
	7,3,0,0,8031,8032,5,95,0,0,8032,8033,7,5,0,0,8033,8034,7,19,0,0,8034,8035,
	7,0,0,0,8035,8036,7,3,0,0,8036,8037,7,12,0,0,8037,8038,7,7,0,0,8038,8039,
	7,17,0,0,8039,8040,5,95,0,0,8040,8041,7,6,0,0,8041,8042,7,25,0,0,8042,8043,
	7,0,0,0,8043,8044,7,8,0,0,8044,8045,7,4,0,0,8045,652,1,0,0,0,8046,8047,
	7,8,0,0,8047,8048,7,10,0,0,8048,8049,7,3,0,0,8049,8050,7,5,0,0,8050,8051,
	7,2,0,0,8051,8052,7,3,0,0,8052,8053,5,95,0,0,8053,8054,7,5,0,0,8054,8055,
	7,15,0,0,8055,8056,7,6,0,0,8056,8057,7,8,0,0,8057,8058,7,12,0,0,8058,8059,
	7,16,0,0,8059,8060,7,12,0,0,8060,8061,7,8,0,0,8061,8062,5,95,0,0,8062,8063,
	7,5,0,0,8063,8064,7,6,0,0,8064,8065,7,17,0,0,8065,8066,7,14,0,0,8066,8067,
	7,6,0,0,8067,8068,7,7,0,0,8068,8069,7,4,0,0,8069,654,1,0,0,0,8070,8071,
	7,8,0,0,8071,8072,7,10,0,0,8072,8073,7,5,0,0,8073,8074,7,4,0,0,8074,8075,
	7,2,0,0,8075,8076,7,14,0,0,8076,8077,7,9,0,0,8077,8078,7,0,0,0,8078,8079,
	7,4,0,0,8079,8080,7,10,0,0,8080,8081,7,14,0,0,8081,656,1,0,0,0,8082,8083,
	7,8,0,0,8083,8084,7,13,0,0,8084,658,1,0,0,0,8085,8086,7,8,0,0,8086,8087,
	7,18,0,0,8087,8088,7,8,0,0,8088,8089,7,11,0,0,8089,8090,7,6,0,0,8090,660,
	1,0,0,0,8091,8092,7,9,0,0,8092,8093,7,0,0,0,8093,8094,7,7,0,0,8094,8095,
	7,17,0,0,8095,8096,7,11,0,0,8096,8097,7,12,0,0,8097,8098,7,7,0,0,8098,8099,
	7,17,0,0,8099,662,1,0,0,0,8100,8101,7,9,0,0,8101,8102,7,0,0,0,8102,8103,
	7,4,0,0,8103,8104,7,0,0,0,8104,8105,7,1,0,0,8105,8106,7,0,0,0,8106,8107,
	7,5,0,0,8107,8108,7,6,0,0,8108,664,1,0,0,0,8109,8110,7,9,0,0,8110,8111,
	7,0,0,0,8111,8112,7,4,0,0,8112,8113,7,0,0,0,8113,666,1,0,0,0,8114,8115,
	7,9,0,0,8115,8116,7,0,0,0,8116,8117,7,4,0,0,8117,8118,7,0,0,0,8118,8119,
	7,16,0,0,8119,8120,7,12,0,0,8120,8121,7,11,0,0,8121,8122,7,6,0,0,8122,668,
	1,0,0,0,8123,8124,7,9,0,0,8124,8125,7,0,0,0,8125,8126,7,4,0,0,8126,8127,
	7,0,0,0,8127,8128,7,16,0,0,8128,8129,7,12,0,0,8129,8130,7,11,0,0,8130,8131,
	7,6,0,0,8131,8132,7,5,0,0,8132,670,1,0,0,0,8133,8134,7,9,0,0,8134,8135,
	7,0,0,0,8135,8136,7,4,0,0,8136,8137,7,0,0,0,8137,8138,7,17,0,0,8138,8139,
	7,10,0,0,8139,8140,7,0,0,0,8140,8141,7,3,0,0,8141,8142,7,9,0,0,8142,8143,
	7,8,0,0,8143,8144,7,2,0,0,8144,8145,7,7,0,0,8145,8146,7,16,0,0,8146,8147,
	7,12,0,0,8147,8148,7,17,0,0,8148,672,1,0,0,0,8149,8150,7,9,0,0,8150,8151,
	7,0,0,0,8151,8152,7,4,0,0,8152,8153,7,0,0,0,8153,8154,7,14,0,0,8154,8155,
	7,2,0,0,8155,8156,7,13,0,0,8156,8157,7,6,0,0,8157,8158,7,14,0,0,8158,8159,
	7,6,0,0,8159,8160,7,7,0,0,8160,8161,7,4,0,0,8161,674,1,0,0,0,8162,8163,
	7,9,0,0,8163,8164,7,0,0,0,8164,8165,7,4,0,0,8165,8166,7,0,0,0,8166,8167,
	7,2,0,0,8167,8168,7,1,0,0,8168,8169,7,20,0,0,8169,8170,7,7,0,0,8170,8171,
	7,2,0,0,8171,676,1,0,0,0,8172,8173,7,9,0,0,8173,8174,7,0,0,0,8174,8175,
	7,4,0,0,8175,8176,7,0,0,0,8176,8177,7,2,0,0,8177,8178,7,1,0,0,8178,8179,
	7,20,0,0,8179,8180,5,95,0,0,8180,8181,7,4,0,0,8181,8182,7,2,0,0,8182,8183,
	5,95,0,0,8183,8184,7,14,0,0,8184,8185,7,0,0,0,8185,8186,7,4,0,0,8186,8187,
	5,95,0,0,8187,8188,7,15,0,0,8188,8189,7,0,0,0,8189,8190,7,3,0,0,8190,8191,
	7,4,0,0,8191,8192,7,12,0,0,8192,8193,7,4,0,0,8193,8194,7,12,0,0,8194,8195,
	7,2,0,0,8195,8196,7,7,0,0,8196,678,1,0,0,0,8197,8198,7,9,0,0,8198,8199,
	7,0,0,0,8199,8200,7,4,0,0,8200,8201,7,0,0,0,8201,8202,7,2,0,0,8202,8203,
	7,1,0,0,8203,8204,7,20,0,0,8204,8205,5,95,0,0,8205,8206,7,4,0,0,8206,8207,
	7,2,0,0,8207,8208,5,95,0,0,8208,8209,7,15,0,0,8209,8210,7,0,0,0,8210,8211,
	7,3,0,0,8211,8212,7,4,0,0,8212,8213,7,12,0,0,8213,8214,7,4,0,0,8214,8215,
	7,12,0,0,8215,8216,7,2,0,0,8216,8217,7,7,0,0,8217,680,1,0,0,0,8218,8219,
	7,9,0,0,8219,8220,7,0,0,0,8220,8221,7,4,0,0,8221,8222,7,0,0,0,8222,8223,
	7,15,0,0,8223,8224,7,10,0,0,8224,8225,7,14,0,0,8225,8226,7,15,0,0,8226,
	682,1,0,0,0,8227,8228,7,9,0,0,8228,8229,7,0,0,0,8229,8230,7,4,0,0,8230,
	8231,7,0,0,0,8231,8232,5,95,0,0,8232,8233,7,5,0,0,8233,8234,7,6,0,0,8234,
	8235,7,8,0,0,8235,8236,7,10,0,0,8236,8237,7,3,0,0,8237,8238,7,12,0,0,8238,
	8239,7,4,0,0,8239,8240,7,18,0,0,8240,8241,5,95,0,0,8241,8242,7,3,0,0,8242,
	8243,7,6,0,0,8243,8244,7,22,0,0,8244,8245,7,3,0,0,8245,8246,7,12,0,0,8246,
	8247,7,4,0,0,8247,8248,7,6,0,0,8248,8249,5,95,0,0,8249,8250,7,11,0,0,8250,
	8251,7,12,0,0,8251,8252,7,14,0,0,8252,8253,7,12,0,0,8253,8254,7,4,0,0,8254,
	684,1,0,0,0,8255,8256,7,9,0,0,8256,8257,7,0,0,0,8257,8258,7,4,0,0,8258,
	8259,7,6,0,0,8259,686,1,0,0,0,8260,8261,7,9,0,0,8261,8262,7,0,0,0,8262,
	8263,7,4,0,0,8263,8264,7,6,0,0,8264,8265,5,95,0,0,8265,8266,7,14,0,0,8266,
	8267,7,2,0,0,8267,8268,7,9,0,0,8268,8269,7,6,0,0,8269,688,1,0,0,0,8270,
	8271,7,9,0,0,8271,8272,7,0,0,0,8272,8273,7,18,0,0,8273,690,1,0,0,0,8274,
	8275,7,9,0,0,8275,8276,7,0,0,0,8276,8277,7,18,0,0,8277,8278,7,5,0,0,8278,
	692,1,0,0,0,8279,8280,7,9,0,0,8280,8281,7,1,0,0,8281,8282,7,0,0,0,8282,
	694,1,0,0,0,8283,8284,7,9,0,0,8284,8285,7,1,0,0,8285,8286,7,0,0,0,8286,
	8287,5,95,0,0,8287,8288,7,3,0,0,8288,8289,7,6,0,0,8289,8290,7,8,0,0,8290,
	8291,7,18,0,0,8291,8292,7,8,0,0,8292,8293,7,11,0,0,8293,8294,7,6,0,0,8294,
	8295,7,1,0,0,8295,8296,7,12,0,0,8296,8297,7,7,0,0,8297,696,1,0,0,0,8298,
	8299,7,9,0,0,8299,8300,7,1,0,0,8300,8301,7,14,0,0,8301,8302,7,5,0,0,8302,
	8303,5,95,0,0,8303,8304,7,5,0,0,8304,8305,7,4,0,0,8305,8306,7,0,0,0,8306,
	8307,7,4,0,0,8307,8308,7,5,0,0,8308,698,1,0,0,0,8309,8310,7,9,0,0,8310,
	8311,7,1,0,0,8311,8312,5,95,0,0,8312,8313,7,3,0,0,8313,8314,7,2,0,0,8314,
	8315,7,11,0,0,8315,8316,7,6,0,0,8316,8317,5,95,0,0,8317,8318,7,8,0,0,8318,
	8319,7,19,0,0,8319,8320,7,0,0,0,8320,8321,7,7,0,0,8321,8322,7,17,0,0,8322,
	8323,7,6,0,0,8323,700,1,0,0,0,8324,8325,7,9,0,0,8325,8326,7,1,0,0,8326,
	8327,7,4,0,0,8327,8328,7,12,0,0,8328,8329,7,14,0,0,8329,8330,7,6,0,0,8330,
	8331,7,23,0,0,8331,8332,7,2,0,0,8332,8333,7,7,0,0,8333,8334,7,6,0,0,8334,
	702,1,0,0,0,8335,8336,7,9,0,0,8336,8337,7,1,0,0,8337,8338,5,95,0,0,8338,
	8339,7,10,0,0,8339,8340,7,7,0,0,8340,8341,7,12,0,0,8341,8342,7,24,0,0,8342,
	8343,7,10,0,0,8343,8344,7,6,0,0,8344,8345,5,95,0,0,8345,8346,7,7,0,0,8346,
	8347,7,0,0,0,8347,8348,7,14,0,0,8348,8349,7,6,0,0,8349,704,1,0,0,0,8350,
	8351,7,9,0,0,8351,8352,7,1,0,0,8352,8353,5,95,0,0,8353,8354,7,13,0,0,8354,
	8355,7,6,0,0,8355,8356,7,3,0,0,8356,8357,7,5,0,0,8357,8358,7,12,0,0,8358,
	8359,7,2,0,0,8359,8360,7,7,0,0,8360,706,1,0,0,0,8361,8362,7,9,0,0,8362,
	8363,7,9,0,0,8363,8364,7,11,0,0,8364,708,1,0,0,0,8365,8366,7,9,0,0,8366,
	8367,7,6,0,0,8367,8368,7,0,0,0,8368,8369,7,11,0,0,8369,8370,7,11,0,0,8370,
	8371,7,2,0,0,8371,8372,7,8,0,0,8372,8373,7,0,0,0,8373,8374,7,4,0,0,8374,
	8375,7,6,0,0,8375,710,1,0,0,0,8376,8377,7,9,0,0,8377,8378,7,6,0,0,8378,
	8379,7,1,0,0,8379,8380,7,10,0,0,8380,8381,7,17,0,0,8381,712,1,0,0,0,8382,
	8383,7,9,0,0,8383,8384,7,6,0,0,8384,8385,7,1,0,0,8385,8386,7,10,0,0,8386,
	8387,7,17,0,0,8387,8388,7,17,0,0,8388,8389,7,6,0,0,8389,8390,7,3,0,0,8390,
	714,1,0,0,0,8391,8392,7,9,0,0,8392,8393,7,6,0,0,8393,8394,7,8,0,0,8394,
	716,1,0,0,0,8395,8396,7,9,0,0,8396,8397,7,6,0,0,8397,8398,7,8,0,0,8398,
	8399,7,12,0,0,8399,8400,7,14,0,0,8400,8401,7,0,0,0,8401,8402,7,11,0,0,8402,
	718,1,0,0,0,8403,8404,7,9,0,0,8404,8405,7,6,0,0,8405,8406,7,8,0,0,8406,
	8407,7,11,0,0,8407,8408,7,0,0,0,8408,8409,7,3,0,0,8409,8410,7,6,0,0,8410,
	720,1,0,0,0,8411,8412,7,9,0,0,8412,8413,7,6,0,0,8413,8414,7,8,0,0,8414,
	8415,7,2,0,0,8415,8416,7,14,0,0,8416,8417,7,15,0,0,8417,8418,7,2,0,0,8418,
	8419,7,5,0,0,8419,8420,7,6,0,0,8420,722,1,0,0,0,8421,8422,7,9,0,0,8422,
	8423,7,6,0,0,8423,8424,7,8,0,0,8424,8425,7,2,0,0,8425,8426,7,3,0,0,8426,
	8427,7,3,0,0,8427,8428,7,6,0,0,8428,8429,7,11,0,0,8429,8430,7,0,0,0,8430,
	8431,7,4,0,0,8431,8432,7,6,0,0,8432,724,1,0,0,0,8433,8434,7,9,0,0,8434,
	8435,7,6,0,0,8435,8436,7,8,0,0,8436,8437,7,3,0,0,8437,726,1,0,0,0,8438,
	8439,7,9,0,0,8439,8440,7,6,0,0,8440,8441,7,8,0,0,8441,8442,7,3,0,0,8442,
	8443,7,6,0,0,8443,8444,7,14,0,0,8444,8445,7,6,0,0,8445,8446,7,7,0,0,8446,
	8447,7,4,0,0,8447,728,1,0,0,0,8448,8449,7,9,0,0,8449,8450,7,6,0,0,8450,
	8451,7,8,0,0,8451,8452,7,3,0,0,8452,8453,7,18,0,0,8453,8454,7,15,0,0,8454,
	8455,7,4,0,0,8455,730,1,0,0,0,8456,8457,7,9,0,0,8457,8458,7,6,0,0,8458,
	8459,7,9,0,0,8459,8460,7,10,0,0,8460,8461,7,15,0,0,8461,8462,7,11,0,0,8462,
	8463,7,12,0,0,8463,8464,7,8,0,0,8464,8465,7,0,0,0,8465,8466,7,4,0,0,8466,
	8467,7,6,0,0,8467,732,1,0,0,0,8468,8469,7,9,0,0,8469,8470,7,6,0,0,8470,
	8471,7,16,0,0,8471,8472,7,0,0,0,8472,8473,7,10,0,0,8473,8474,7,11,0,0,8474,
	8475,7,4,0,0,8475,734,1,0,0,0,8476,8477,7,9,0,0,8477,8478,7,6,0,0,8478,
	8479,7,16,0,0,8479,8480,7,0,0,0,8480,8481,7,10,0,0,8481,8482,7,11,0,0,8482,
	8483,7,4,0,0,8483,8484,7,5,0,0,8484,736,1,0,0,0,8485,8486,7,9,0,0,8486,
	8487,7,6,0,0,8487,8488,7,16,0,0,8488,8489,7,0,0,0,8489,8490,7,10,0,0,8490,
	8491,7,11,0,0,8491,8492,7,4,0,0,8492,8493,5,95,0,0,8493,8494,7,8,0,0,8494,
	8495,7,2,0,0,8495,8496,7,11,0,0,8496,8497,7,11,0,0,8497,8498,7,0,0,0,8498,
	8499,7,4,0,0,8499,8500,7,12,0,0,8500,8501,7,2,0,0,8501,8502,7,7,0,0,8502,
	738,1,0,0,0,8503,8504,7,9,0,0,8504,8505,7,6,0,0,8505,8506,7,16,0,0,8506,
	8507,7,0,0,0,8507,8508,7,10,0,0,8508,8509,7,11,0,0,8509,8510,7,4,0,0,8510,
	8511,5,95,0,0,8511,8512,7,8,0,0,8512,8513,7,3,0,0,8513,8514,7,6,0,0,8514,
	8515,7,9,0,0,8515,8516,7,6,0,0,8516,8517,7,7,0,0,8517,8518,7,4,0,0,8518,
	8519,7,12,0,0,8519,8520,7,0,0,0,8520,8521,7,11,0,0,8521,740,1,0,0,0,8522,
	8523,7,9,0,0,8523,8524,7,6,0,0,8524,8525,7,16,0,0,8525,8526,7,6,0,0,8526,
	8527,7,3,0,0,8527,8528,7,3,0,0,8528,8529,7,0,0,0,8529,8530,7,1,0,0,8530,
	8531,7,11,0,0,8531,8532,7,6,0,0,8532,742,1,0,0,0,8533,8534,7,9,0,0,8534,
	8535,7,6,0,0,8535,8536,7,16,0,0,8536,8537,7,6,0,0,8537,8538,7,3,0,0,8538,
	8539,7,3,0,0,8539,8540,7,6,0,0,8540,8541,7,9,0,0,8541,744,1,0,0,0,8542,
	8543,7,9,0,0,8543,8544,7,6,0,0,8544,8545,7,16,0,0,8545,8546,7,12,0,0,8546,
	8547,7,7,0,0,8547,8548,7,6,0,0,8548,8549,7,9,0,0,8549,746,1,0,0,0,8550,
	8551,7,9,0,0,8551,8552,7,6,0,0,8552,8553,7,16,0,0,8553,8554,7,12,0,0,8554,
	8555,7,7,0,0,8555,8556,7,6,0,0,8556,748,1,0,0,0,8557,8558,7,9,0,0,8558,
	8559,7,6,0,0,8559,8560,7,16,0,0,8560,8561,7,12,0,0,8561,8562,7,7,0,0,8562,
	8563,7,6,0,0,8563,8564,7,3,0,0,8564,750,1,0,0,0,8565,8566,7,9,0,0,8566,
	8567,7,6,0,0,8567,8568,7,17,0,0,8568,8569,7,3,0,0,8569,8570,7,6,0,0,8570,
	8571,7,6,0,0,8571,752,1,0,0,0,8572,8573,7,9,0,0,8573,8574,7,6,0,0,8574,
	8575,7,11,0,0,8575,8576,7,0,0,0,8576,8577,7,18,0,0,8577,754,1,0,0,0,8578,
	8579,7,9,0,0,8579,8580,7,6,0,0,8580,8581,7,11,0,0,8581,8582,7,6,0,0,8582,
	8583,7,17,0,0,8583,8584,7,0,0,0,8584,8585,7,4,0,0,8585,8586,7,6,0,0,8586,
	756,1,0,0,0,8587,8588,7,9,0,0,8588,8589,7,6,0,0,8589,8590,7,11,0,0,8590,
	8591,7,6,0,0,8591,8592,7,4,0,0,8592,8593,7,6,0,0,8593,8594,5,95,0,0,8594,
	8595,7,0,0,0,8595,8596,7,11,0,0,8596,8597,7,11,0,0,8597,758,1,0,0,0,8598,
	8599,7,9,0,0,8599,8600,7,6,0,0,8600,8601,7,11,0,0,8601,8602,7,6,0,0,8602,
	8603,7,4,0,0,8603,8604,7,6,0,0,8604,760,1,0,0,0,8605,8606,7,9,0,0,8606,
	8607,7,6,0,0,8607,8608,7,11,0,0,8608,8609,7,6,0,0,8609,8610,7,4,0,0,8610,
	8611,7,6,0,0,8611,8612,7,25,0,0,8612,8613,7,14,0,0,8613,8614,7,11,0,0,8614,
	762,1,0,0,0,8615,8616,7,9,0,0,8616,8617,7,6,0,0,8617,8618,7,14,0,0,8618,
	8619,7,0,0,0,8619,8620,7,7,0,0,8620,8621,7,9,0,0,8621,764,1,0,0,0,8622,
	8623,7,9,0,0,8623,8624,7,6,0,0,8624,8625,7,7,0,0,8625,8626,7,5,0,0,8626,
	8627,7,6,0,0,8627,8628,5,95,0,0,8628,8629,7,3,0,0,8629,8630,7,0,0,0,8630,
	8631,7,7,0,0,8631,8632,7,21,0,0,8632,8633,7,14,0,0,8633,766,1,0,0,0,8634,
	8635,7,9,0,0,8635,8636,7,6,0,0,8636,8637,7,15,0,0,8637,8638,7,6,0,0,8638,
	8639,7,7,0,0,8639,8640,7,9,0,0,8640,8641,7,6,0,0,8641,8642,7,7,0,0,8642,
	8643,7,4,0,0,8643,768,1,0,0,0,8644,8645,7,9,0,0,8645,8646,7,6,0,0,8646,
	8647,7,15,0,0,8647,8648,7,4,0,0,8648,8649,7,19,0,0,8649,770,1,0,0,0,8650,
	8651,7,9,0,0,8651,8652,7,6,0,0,8652,8653,7,24,0,0,8653,8654,7,10,0,0,8654,
	8655,7,6,0,0,8655,8656,7,10,0,0,8656,8657,7,6,0,0,8657,772,1,0,0,0,8658,
	8659,7,9,0,0,8659,8660,7,6,0,0,8660,8661,7,3,0,0,8661,8662,7,6,0,0,8662,
	8663,7,16,0,0,8663,774,1,0,0,0,8664,8665,7,9,0,0,8665,8666,7,6,0,0,8666,
	8667,7,3,0,0,8667,8668,7,6,0,0,8668,8669,7,16,0,0,8669,8670,5,95,0,0,8670,
	8671,7,7,0,0,8671,8672,7,2,0,0,8672,8673,5,95,0,0,8673,8674,7,3,0,0,8674,
	8675,7,6,0,0,8675,8676,7,22,0,0,8676,8677,7,3,0,0,8677,8678,7,12,0,0,8678,
	8679,7,4,0,0,8679,8680,7,6,0,0,8680,776,1,0,0,0,8681,8682,7,9,0,0,8682,
	8683,7,6,0,0,8683,8684,7,5,0,0,8684,8685,7,8,0,0,8685,778,1,0,0,0,8686,
	8687,7,9,0,0,8687,8688,7,6,0,0,8688,8689,7,5,0,0,8689,8690,7,8,0,0,8690,
	8691,7,3,0,0,8691,8692,7,12,0,0,8692,8693,7,15,0,0,8693,8694,7,4,0,0,8694,
	8695,7,12,0,0,8695,8696,7,2,0,0,8696,8697,7,7,0,0,8697,780,1,0,0,0,8698,
	8699,7,9,0,0,8699,8700,7,6,0,0,8700,8701,7,5,0,0,8701,8702,7,4,0,0,8702,
	8703,7,3,0,0,8703,8704,7,2,0,0,8704,8705,7,18,0,0,8705,782,1,0,0,0,8706,
	8707,7,9,0,0,8707,8708,7,6,0,0,8708,8709,7,4,0,0,8709,8710,7,0,0,0,8710,
	8711,7,8,0,0,8711,8712,7,19,0,0,8712,8713,7,6,0,0,8713,8714,7,9,0,0,8714,
	784,1,0,0,0,8715,8716,7,9,0,0,8716,8717,7,6,0,0,8717,8718,7,4,0,0,8718,
	8719,7,6,0,0,8719,8720,7,3,0,0,8720,8721,7,14,0,0,8721,8722,7,12,0,0,8722,
	8723,7,7,0,0,8723,8724,7,6,0,0,8724,8725,7,5,0,0,8725,786,1,0,0,0,8726,
	8727,7,9,0,0,8727,8728,7,6,0,0,8728,8729,7,4,0,0,8729,8730,7,6,0,0,8730,
	8731,7,3,0,0,8731,8732,7,14,0,0,8732,8733,7,12,0,0,8733,8734,7,7,0,0,8734,
	8735,7,12,0,0,8735,8736,7,5,0,0,8736,8737,7,4,0,0,8737,8738,7,12,0,0,8738,
	8739,7,8,0,0,8739,788,1,0,0,0,8740,8741,7,9,0,0,8741,8742,7,12,0,0,8742,
	8743,7,8,0,0,8743,8744,7,4,0,0,8744,8745,7,12,0,0,8745,8746,7,2,0,0,8746,
	8747,7,7,0,0,8747,8748,7,0,0,0,8748,8749,7,3,0,0,8749,8750,7,18,0,0,8750,
	790,1,0,0,0,8751,8752,7,9,0,0,8752,8753,7,12,0,0,8753,8754,7,14,0,0,8754,
	8755,7,6,0,0,8755,8756,7,7,0,0,8756,8757,7,5,0,0,8757,8758,7,12,0,0,8758,
	8759,7,2,0,0,8759,8760,7,7,0,0,8760,792,1,0,0,0,8761,8762,7,9,0,0,8762,
	8763,7,12,0,0,8763,8764,7,14,0,0,8764,8765,7,6,0,0,8765,8766,7,7,0,0,8766,
	8767,7,5,0,0,8767,8768,7,12,0,0,8768,8769,7,2,0,0,8769,8770,7,7,0,0,8770,
	8771,7,5,0,0,8771,794,1,0,0,0,8772,8773,7,9,0,0,8773,8774,7,12,0,0,8774,
	8775,7,3,0,0,8775,8776,7,6,0,0,8776,8777,7,8,0,0,8777,8778,7,4,0,0,8778,
	8779,5,95,0,0,8779,8780,7,11,0,0,8780,8781,7,2,0,0,8781,8782,7,0,0,0,8782,
	8783,7,9,0,0,8783,796,1,0,0,0,8784,8785,7,9,0,0,8785,8786,7,12,0,0,8786,
	8787,7,3,0,0,8787,8788,7,6,0,0,8788,8789,7,8,0,0,8789,8790,7,4,0,0,8790,
	8791,7,2,0,0,8791,8792,7,3,0,0,8792,8793,7,18,0,0,8793,798,1,0,0,0,8794,
	8795,7,9,0,0,8795,8796,7,12,0,0,8796,8797,7,3,0,0,8797,8798,7,6,0,0,8798,
	8799,7,8,0,0,8799,8800,7,4,0,0,8800,8801,5,95,0,0,8801,8802,7,15,0,0,8802,
	8803,7,0,0,0,8803,8804,7,4,0,0,8804,8805,7,19,0,0,8805,800,1,0,0,0,8806,
	8807,7,9,0,0,8807,8808,7,12,0,0,8808,8809,7,5,0,0,8809,8810,7,0,0,0,8810,
	8811,7,1,0,0,8811,8812,7,11,0,0,8812,8813,7,6,0,0,8813,8814,5,95,0,0,8814,
	8815,7,0,0,0,8815,8816,7,11,0,0,8816,8817,7,11,0,0,8817,802,1,0,0,0,8818,
	8819,7,9,0,0,8819,8820,7,12,0,0,8820,8821,7,5,0,0,8821,8822,7,0,0,0,8822,
	8823,7,1,0,0,8823,8824,7,11,0,0,8824,8825,7,6,0,0,8825,804,1,0,0,0,8826,
	8827,7,9,0,0,8827,8828,7,12,0,0,8828,8829,7,5,0,0,8829,8830,7,0,0,0,8830,
	8831,7,1,0,0,8831,8832,7,11,0,0,8832,8833,7,6,0,0,8833,8834,5,95,0,0,8834,
	8835,7,15,0,0,8835,8836,7,0,0,0,8836,8837,7,3,0,0,8837,8838,7,0,0,0,8838,
	8839,7,11,0,0,8839,8840,7,11,0,0,8840,8841,7,6,0,0,8841,8842,7,11,0,0,8842,
	8843,5,95,0,0,8843,8844,7,9,0,0,8844,8845,7,14,0,0,8845,8846,7,11,0,0,8846,
	806,1,0,0,0,8847,8848,7,9,0,0,8848,8849,7,12,0,0,8849,8850,7,5,0,0,8850,
	8851,7,0,0,0,8851,8852,7,1,0,0,8852,8853,7,11,0,0,8853,8854,7,6,0,0,8854,
	8855,5,95,0,0,8855,8856,7,15,0,0,8856,8857,7,3,0,0,8857,8858,7,6,0,0,8858,
	8859,7,5,0,0,8859,8860,7,6,0,0,8860,8861,7,4,0,0,8861,808,1,0,0,0,8862,
	8863,7,9,0,0,8863,8864,7,12,0,0,8864,8865,7,5,0,0,8865,8866,7,0,0,0,8866,
	8867,7,1,0,0,8867,8868,7,11,0,0,8868,8869,7,6,0,0,8869,8870,5,95,0,0,8870,
	8871,7,3,0,0,8871,8872,7,15,0,0,8872,8873,7,21,0,0,8873,8874,7,6,0,0,8874,
	810,1,0,0,0,8875,8876,7,9,0,0,8876,8877,7,12,0,0,8877,8878,7,5,0,0,8878,
	8879,7,0,0,0,8879,8880,7,11,0,0,8880,8881,7,11,0,0,8881,8882,7,2,0,0,8882,
	8883,7,22,0,0,8883,812,1,0,0,0,8884,8885,7,9,0,0,8885,8886,7,12,0,0,8886,
	8887,7,5,0,0,8887,8888,7,0,0,0,8888,8889,7,5,0,0,8889,8890,7,5,0,0,8890,
	8891,7,2,0,0,8891,8892,7,8,0,0,8892,8893,7,12,0,0,8893,8894,7,0,0,0,8894,
	8895,7,4,0,0,8895,8896,7,6,0,0,8896,814,1,0,0,0,8897,8898,7,9,0,0,8898,
	8899,7,12,0,0,8899,8900,7,5,0,0,8900,8901,7,8,0,0,8901,8902,7,0,0,0,8902,
	8903,7,3,0,0,8903,8904,7,9,0,0,8904,816,1,0,0,0,8905,8906,7,9,0,0,8906,
	8907,7,12,0,0,8907,8908,7,5,0,0,8908,8909,7,8,0,0,8909,8910,7,2,0,0,8910,
	8911,7,7,0,0,8911,8912,7,7,0,0,8912,8913,7,6,0,0,8913,8914,7,8,0,0,8914,
	8915,7,4,0,0,8915,818,1,0,0,0,8916,8917,7,9,0,0,8917,8918,7,12,0,0,8918,
	8919,7,5,0,0,8919,8920,7,21,0,0,8920,820,1,0,0,0,8921,8922,7,9,0,0,8922,
	8923,7,12,0,0,8923,8924,7,5,0,0,8924,8925,7,21,0,0,8925,8926,7,17,0,0,8926,
	8927,7,3,0,0,8927,8928,7,2,0,0,8928,8929,7,10,0,0,8929,8930,7,15,0,0,8930,
	822,1,0,0,0,8931,8932,5,39,0,0,8932,8933,5,43,0,0,8933,8934,5,32,0,0,8934,
	8935,7,9,0,0,8935,8936,7,12,0,0,8936,8937,7,5,0,0,8937,8938,7,21,0,0,8938,
	8939,7,17,0,0,8939,8940,7,3,0,0,8940,8941,7,2,0,0,8941,8942,7,10,0,0,8942,
	8943,7,15,0,0,8943,824,1,0,0,0,8944,8945,7,9,0,0,8945,8946,7,12,0,0,8946,
	8947,7,5,0,0,8947,8948,7,21,0,0,8948,8949,7,5,0,0,8949,826,1,0,0,0,8950,
	8951,7,9,0,0,8951,8952,7,12,0,0,8952,8953,7,5,0,0,8953,8954,7,14,0,0,8954,
	8955,7,2,0,0,8955,8956,7,10,0,0,8956,8957,7,7,0,0,8957,8958,7,4,0,0,8958,
	828,1,0,0,0,8959,8960,7,9,0,0,8960,8961,7,12,0,0,8961,8962,7,5,0,0,8962,
	8963,7,4,0,0,8963,8964,7,12,0,0,8964,8965,7,7,0,0,8965,8966,7,8,0,0,8966,
	8967,7,4,0,0,8967,830,1,0,0,0,8968,8969,7,9,0,0,8969,8970,7,12,0,0,8970,
	8971,7,5,0,0,8971,8972,7,4,0,0,8972,8973,7,12,0,0,8973,8974,7,7,0,0,8974,
	8975,7,17,0,0,8975,8976,7,10,0,0,8976,8977,7,12,0,0,8977,8978,7,5,0,0,8978,
	8979,7,19,0,0,8979,8980,7,6,0,0,8980,8981,7,9,0,0,8981,832,1,0,0,0,8982,
	8983,7,9,0,0,8983,8984,7,12,0,0,8984,8985,7,5,0,0,8985,8986,7,4,0,0,8986,
	8987,7,3,0,0,8987,8988,7,12,0,0,8988,8989,7,1,0,0,8989,8990,7,10,0,0,8990,
	8991,7,4,0,0,8991,8992,7,6,0,0,8992,8993,7,9,0,0,8993,834,1,0,0,0,8994,
	8995,7,9,0,0,8995,8996,7,12,0,0,8996,8997,7,5,0,0,8997,8998,7,4,0,0,8998,
	8999,7,3,0,0,8999,9000,7,12,0,0,9000,9001,7,1,0,0,9001,9002,7,10,0,0,9002,
	9003,7,4,0,0,9003,9004,7,6,0,0,9004,836,1,0,0,0,9005,9006,7,9,0,0,9006,
	9007,7,14,0,0,9007,9008,7,11,0,0,9008,838,1,0,0,0,9009,9010,7,9,0,0,9010,
	9011,7,14,0,0,9011,9012,7,11,0,0,9012,9013,5,95,0,0,9013,9014,7,10,0,0,
	9014,9015,7,15,0,0,9015,9016,7,9,0,0,9016,9017,7,0,0,0,9017,9018,7,4,0,
	0,9018,9019,7,6,0,0,9019,840,1,0,0,0,9020,9021,7,9,0,0,9021,9022,7,2,0,
	0,9022,9023,7,8,0,0,9023,9024,7,16,0,0,9024,9025,7,12,0,0,9025,9026,7,9,
	0,0,9026,9027,7,6,0,0,9027,9028,7,11,0,0,9028,9029,7,12,0,0,9029,9030,7,
	4,0,0,9030,9031,7,18,0,0,9031,842,1,0,0,0,9032,9033,7,9,0,0,9033,9034,7,
	2,0,0,9034,9035,7,8,0,0,9035,9036,7,10,0,0,9036,9037,7,14,0,0,9037,9038,
	7,6,0,0,9038,9039,7,7,0,0,9039,9040,7,4,0,0,9040,844,1,0,0,0,9041,9042,
	7,9,0,0,9042,9043,7,2,0,0,9043,9044,7,14,0,0,9044,9045,7,0,0,0,9045,9046,
	7,12,0,0,9046,9047,7,7,0,0,9047,9048,5,95,0,0,9048,9049,7,12,0,0,9049,9050,
	7,7,0,0,9050,9051,7,9,0,0,9051,9052,7,6,0,0,9052,9053,7,25,0,0,9053,9054,
	5,95,0,0,9054,9055,7,16,0,0,9055,9056,7,12,0,0,9056,9057,7,11,0,0,9057,
	9058,7,4,0,0,9058,9059,7,6,0,0,9059,9060,7,3,0,0,9060,846,1,0,0,0,9061,
	9062,7,9,0,0,9062,9063,7,2,0,0,9063,9064,7,14,0,0,9064,9065,7,0,0,0,9065,
	9066,7,12,0,0,9066,9067,7,7,0,0,9067,9068,5,95,0,0,9068,9069,7,12,0,0,9069,
	9070,7,7,0,0,9070,9071,7,9,0,0,9071,9072,7,6,0,0,9072,9073,7,25,0,0,9073,
	9074,5,95,0,0,9074,9075,7,7,0,0,9075,9076,7,2,0,0,9076,9077,5,95,0,0,9077,
	9078,7,5,0,0,9078,9079,7,2,0,0,9079,9080,7,3,0,0,9080,9081,7,4,0,0,9081,
	848,1,0,0,0,9082,9083,7,9,0,0,9083,9084,7,2,0,0,9084,9085,7,14,0,0,9085,
	9086,7,0,0,0,9086,9087,7,12,0,0,9087,9088,7,7,0,0,9088,9089,5,95,0,0,9089,
	9090,7,12,0,0,9090,9091,7,7,0,0,9091,9092,7,9,0,0,9092,9093,7,6,0,0,9093,
	9094,7,25,0,0,9094,9095,5,95,0,0,9095,9096,7,5,0,0,9096,9097,7,2,0,0,9097,
	9098,7,3,0,0,9098,9099,7,4,0,0,9099,850,1,0,0,0,9100,9101,7,9,0,0,9101,
	9102,7,2,0,0,9102,9103,7,10,0,0,9103,9104,7,1,0,0,9104,9105,7,11,0,0,9105,
	9106,7,6,0,0,9106,852,1,0,0,0,9107,9108,7,9,0,0,9108,9109,7,2,0,0,9109,
	9110,7,22,0,0,9110,9111,7,7,0,0,9111,9112,7,17,0,0,9112,9113,7,3,0,0,9113,
	9114,7,0,0,0,9114,9115,7,9,0,0,9115,9116,7,6,0,0,9116,854,1,0,0,0,9117,
	9118,7,9,0,0,9118,9119,7,3,0,0,9119,9120,7,12,0,0,9120,9121,7,13,0,0,9121,
	9122,7,12,0,0,9122,9123,7,7,0,0,9123,9124,7,17,0,0,9124,9125,5,95,0,0,9125,
	9126,7,5,0,0,9126,9127,7,12,0,0,9127,9128,7,4,0,0,9128,9129,7,6,0,0,9129,
	856,1,0,0,0,9130,9131,7,9,0,0,9131,9132,7,3,0,0,9132,9133,7,2,0,0,9133,
	9134,7,15,0,0,9134,9135,5,95,0,0,9135,9136,7,8,0,0,9136,9137,7,2,0,0,9137,
	9138,7,11,0,0,9138,9139,7,10,0,0,9139,9140,7,14,0,0,9140,9141,7,7,0,0,9141,
	858,1,0,0,0,9142,9143,7,9,0,0,9143,9144,7,3,0,0,9144,9145,7,2,0,0,9145,
	9146,7,15,0,0,9146,860,1,0,0,0,9147,9148,7,9,0,0,9148,9149,7,3,0,0,9149,
	9150,7,2,0,0,9150,9151,7,15,0,0,9151,9152,5,95,0,0,9152,9153,7,17,0,0,9153,
	9154,7,3,0,0,9154,9155,7,2,0,0,9155,9156,7,10,0,0,9156,9157,7,15,0,0,9157,
	862,1,0,0,0,9158,9159,7,9,0,0,9159,9160,7,5,0,0,9160,9161,7,12,0,0,9161,
	9162,7,7,0,0,9162,9163,7,4,0,0,9163,9164,7,6,0,0,9164,9165,7,3,0,0,9165,
	9166,7,13,0,0,9166,9167,7,0,0,0,9167,9168,7,11,0,0,9168,9169,5,95,0,0,9169,
	9170,7,10,0,0,9170,9171,7,7,0,0,9171,9172,7,8,0,0,9172,9173,7,2,0,0,9173,
	9174,7,7,0,0,9174,9175,7,5,0,0,9175,9176,7,4,0,0,9176,9177,7,3,0,0,9177,
	9178,7,0,0,0,9178,9179,7,12,0,0,9179,9180,7,7,0,0,9180,9181,7,6,0,0,9181,
	9182,7,9,0,0,9182,864,1,0,0,0,9183,9184,7,9,0,0,9184,9185,7,5,0,0,9185,
	9186,7,4,0,0,9186,9187,5,95,0,0,9187,9188,7,10,0,0,9188,9189,7,15,0,0,9189,
	9190,7,17,0,0,9190,9191,7,3,0,0,9191,9192,7,0,0,0,9192,9193,7,9,0,0,9193,
	9194,7,6,0,0,9194,9195,5,95,0,0,9195,9196,7,12,0,0,9196,9197,7,7,0,0,9197,
	9198,7,5,0,0,9198,9199,7,6,0,0,9199,9200,7,3,0,0,9200,9201,7,4,0,0,9201,
	9202,5,95,0,0,9202,9203,7,8,0,0,9203,9204,7,2,0,0,9204,9205,7,7,0,0,9205,
	9206,7,13,0,0,9206,866,1,0,0,0,9207,9208,7,9,0,0,9208,9209,7,10,0,0,9209,
	9210,7,14,0,0,9210,9211,7,15,0,0,9211,868,1,0,0,0,9212,9213,7,9,0,0,9213,
	9214,7,10,0,0,9214,9215,7,14,0,0,9215,9216,7,15,0,0,9216,9217,7,5,0,0,9217,
	9218,7,6,0,0,9218,9219,7,4,0,0,9219,870,1,0,0,0,9220,9221,7,9,0,0,9221,
	9222,7,10,0,0,9222,9223,7,15,0,0,9223,9224,7,11,0,0,9224,9225,7,12,0,0,
	9225,9226,7,8,0,0,9226,9227,7,0,0,0,9227,9228,7,4,0,0,9228,9229,7,6,0,0,
	9229,872,1,0,0,0,9230,9231,7,9,0,0,9231,9232,7,13,0,0,9232,874,1,0,0,0,
	9233,9234,7,9,0,0,9234,9235,7,18,0,0,9235,9236,7,7,0,0,9236,9237,7,0,0,
	0,9237,9238,7,14,0,0,9238,9239,7,12,0,0,9239,9240,7,8,0,0,9240,876,1,0,
	0,0,9241,9242,7,9,0,0,9242,9243,7,18,0,0,9243,9244,7,7,0,0,9244,9245,7,
	0,0,0,9245,9246,7,14,0,0,9246,9247,7,12,0,0,9247,9248,7,8,0,0,9248,9249,
	5,95,0,0,9249,9250,7,5,0,0,9250,9251,7,0,0,0,9251,9252,7,14,0,0,9252,9253,
	7,15,0,0,9253,9254,7,11,0,0,9254,9255,7,12,0,0,9255,9256,7,7,0,0,9256,9257,
	7,17,0,0,9257,878,1,0,0,0,9258,9259,7,9,0,0,9259,9260,7,18,0,0,9260,9261,
	7,7,0,0,9261,9262,7,0,0,0,9262,9263,7,14,0,0,9263,9264,7,12,0,0,9264,9265,
	7,8,0,0,9265,9266,5,95,0,0,9266,9267,7,5,0,0,9267,9268,7,0,0,0,9268,9269,
	7,14,0,0,9269,9270,7,15,0,0,9270,9271,7,11,0,0,9271,9272,7,12,0,0,9272,
	9273,7,7,0,0,9273,9274,7,17,0,0,9274,9275,5,95,0,0,9275,9276,7,6,0,0,9276,
	9277,7,5,0,0,9277,9278,7,4,0,0,9278,9279,5,95,0,0,9279,9280,7,8,0,0,9280,
	9281,7,9,0,0,9281,9282,7,7,0,0,9282,880,1,0,0,0,9283,9284,7,6,0,0,9284,
	882,1,0,0,0,9285,9286,7,6,0,0,9286,9287,7,0,0,0,9287,9288,7,8,0,0,9288,
	9289,7,19,0,0,9289,884,1,0,0,0,9290,9291,7,6,0,0,9291,9292,7,9,0,0,9292,
	9293,7,12,0,0,9293,9294,7,4,0,0,9294,9295,7,12,0,0,9295,9296,7,2,0,0,9296,
	9297,7,7,0,0,9297,9298,7,0,0,0,9298,9299,7,1,0,0,9299,9300,7,11,0,0,9300,
	9301,7,6,0,0,9301,886,1,0,0,0,9302,9303,7,6,0,0,9303,9304,7,9,0,0,9304,
	9305,7,12,0,0,9305,9306,7,4,0,0,9306,9307,7,12,0,0,9307,9308,7,2,0,0,9308,
	9309,7,7,0,0,9309,888,1,0,0,0,9310,9311,7,6,0,0,9311,9312,7,9,0,0,9312,
	9313,7,12,0,0,9313,9314,7,4,0,0,9314,9315,7,12,0,0,9315,9316,7,2,0,0,9316,
	9317,7,7,0,0,9317,9318,7,12,0,0,9318,9319,7,7,0,0,9319,9320,7,17,0,0,9320,
	890,1,0,0,0,9321,9322,7,6,0,0,9322,9323,7,9,0,0,9323,9324,7,12,0,0,9324,
	9325,7,4,0,0,9325,9326,7,12,0,0,9326,9327,7,2,0,0,9327,9328,7,7,0,0,9328,
	9329,7,5,0,0,9329,892,1,0,0,0,9330,9331,7,6,0,0,9331,9332,7,11,0,0,9332,
	9333,7,6,0,0,9333,9334,7,14,0,0,9334,9335,7,6,0,0,9335,9336,7,7,0,0,9336,
	9337,7,4,0,0,9337,894,1,0,0,0,9338,9339,7,6,0,0,9339,9340,7,11,0,0,9340,
	9341,7,12,0,0,9341,9342,7,14,0,0,9342,9343,5,95,0,0,9343,9344,7,17,0,0,
	9344,9345,7,3,0,0,9345,9346,7,2,0,0,9346,9347,7,10,0,0,9347,9348,7,15,0,
	0,9348,9349,7,1,0,0,9349,9350,7,18,0,0,9350,896,1,0,0,0,9351,9352,7,6,0,
	0,9352,9353,7,11,0,0,9353,9354,7,12,0,0,9354,9355,7,14,0,0,9355,9356,7,
	12,0,0,9356,9357,7,7,0,0,9357,9358,7,0,0,0,9358,9359,7,4,0,0,9359,9360,
	7,6,0,0,9360,9361,5,95,0,0,9361,9362,7,20,0,0,9362,9363,7,2,0,0,9363,9364,
	7,12,0,0,9364,9365,7,7,0,0,9365,898,1,0,0,0,9366,9367,7,6,0,0,9367,9368,
	7,11,0,0,9368,9369,7,12,0,0,9369,9370,7,14,0,0,9370,9371,7,12,0,0,9371,
	9372,7,7,0,0,9372,9373,7,0,0,0,9373,9374,7,4,0,0,9374,9375,7,6,0,0,9375,
	9376,5,95,0,0,9376,9377,7,2,0,0,9377,9378,7,1,0,0,9378,9379,7,18,0,0,9379,
	900,1,0,0,0,9380,9381,7,6,0,0,9381,9382,7,11,0,0,9382,9383,7,12,0,0,9383,
	9384,7,14,0,0,9384,9385,7,12,0,0,9385,9386,7,7,0,0,9386,9387,7,0,0,0,9387,
	9388,7,4,0,0,9388,9389,7,6,0,0,9389,9390,5,95,0,0,9390,9391,7,2,0,0,9391,
	9392,7,10,0,0,9392,9393,7,4,0,0,9393,9394,7,6,0,0,9394,9395,7,3,0,0,9395,
	9396,5,95,0,0,9396,9397,7,20,0,0,9397,9398,7,2,0,0,9398,9399,7,12,0,0,9399,
	9400,7,7,0,0,9400,902,1,0,0,0,9401,9402,7,6,0,0,9402,9403,7,11,0,0,9403,
	9404,7,5,0,0,9404,9405,7,6,0,0,9405,904,1,0,0,0,9406,9407,7,6,0,0,9407,
	9408,7,11,0,0,9408,9409,7,5,0,0,9409,9410,7,12,0,0,9410,9411,7,16,0,0,9411,
	906,1,0,0,0,9412,9413,7,6,0,0,9413,9414,7,14,0,0,9414,908,1,0,0,0,9415,
	9416,7,6,0,0,9416,9417,7,14,0,0,9417,9418,7,15,0,0,9418,9419,7,4,0,0,9419,
	9420,7,18,0,0,9420,9421,5,95,0,0,9421,9422,7,1,0,0,9422,9423,7,11,0,0,9423,
	9424,7,2,0,0,9424,9425,7,1,0,0,9425,910,1,0,0,0,9426,9427,7,6,0,0,9427,
	9428,7,14,0,0,9428,9429,7,15,0,0,9429,9430,7,4,0,0,9430,9431,7,18,0,0,9431,
	9432,5,95,0,0,9432,9433,7,8,0,0,9433,9434,7,11,0,0,9434,9435,7,2,0,0,9435,
	9436,7,1,0,0,9436,912,1,0,0,0,9437,9438,7,6,0,0,9438,9439,7,14,0,0,9439,
	9440,7,15,0,0,9440,9441,7,4,0,0,9441,9442,7,18,0,0,9442,914,1,0,0,0,9443,
	9444,7,6,0,0,9444,9445,7,7,0,0,9445,9446,7,0,0,0,9446,9447,7,1,0,0,9447,
	9448,7,11,0,0,9448,9449,7,6,0,0,9449,9450,5,95,0,0,9450,9451,7,0,0,0,9451,
	9452,7,11,0,0,9452,9453,7,11,0,0,9453,916,1,0,0,0,9454,9455,7,6,0,0,9455,
	9456,7,7,0,0,9456,9457,7,0,0,0,9457,9458,7,1,0,0,9458,9459,7,11,0,0,9459,
	9460,7,6,0,0,9460,918,1,0,0,0,9461,9462,7,6,0,0,9462,9463,7,7,0,0,9463,
	9464,7,0,0,0,9464,9465,7,1,0,0,9465,9466,7,11,0,0,9466,9467,7,6,0,0,9467,
	9468,5,95,0,0,9468,9469,7,15,0,0,9469,9470,7,0,0,0,9470,9471,7,3,0,0,9471,
	9472,7,0,0,0,9472,9473,7,11,0,0,9473,9474,7,11,0,0,9474,9475,7,6,0,0,9475,
	9476,7,11,0,0,9476,9477,5,95,0,0,9477,9478,7,9,0,0,9478,9479,7,14,0,0,9479,
	9480,7,11,0,0,9480,920,1,0,0,0,9481,9482,7,6,0,0,9482,9483,7,7,0,0,9483,
	9484,7,0,0,0,9484,9485,7,1,0,0,9485,9486,7,11,0,0,9486,9487,7,6,0,0,9487,
	9488,5,95,0,0,9488,9489,7,15,0,0,9489,9490,7,3,0,0,9490,9491,7,6,0,0,9491,
	9492,7,5,0,0,9492,9493,7,6,0,0,9493,9494,7,4,0,0,9494,922,1,0,0,0,9495,
	9496,7,6,0,0,9496,9497,7,7,0,0,9497,9498,7,8,0,0,9498,9499,7,2,0,0,9499,
	9500,7,9,0,0,9500,9501,7,12,0,0,9501,9502,7,7,0,0,9502,9503,7,17,0,0,9503,
	924,1,0,0,0,9504,9505,7,6,0,0,9505,9506,7,7,0,0,9506,9507,7,8,0,0,9507,
	9508,7,3,0,0,9508,9509,7,18,0,0,9509,9510,7,15,0,0,9510,9511,7,4,0,0,9511,
	926,1,0,0,0,9512,9513,7,6,0,0,9513,9514,7,7,0,0,9514,9515,7,8,0,0,9515,
	9516,7,3,0,0,9516,9517,7,18,0,0,9517,9518,7,15,0,0,9518,9519,7,4,0,0,9519,
	9520,7,12,0,0,9520,9521,7,2,0,0,9521,9522,7,7,0,0,9522,928,1,0,0,0,9523,
	9524,7,6,0,0,9524,9525,7,7,0,0,9525,9526,7,9,0,0,9526,930,1,0,0,0,9527,
	9528,7,6,0,0,9528,9529,7,7,0,0,9529,9530,7,9,0,0,9530,9531,5,95,0,0,9531,
	9532,7,2,0,0,9532,9533,7,10,0,0,9533,9534,7,4,0,0,9534,9535,7,11,0,0,9535,
	9536,7,12,0,0,9536,9537,7,7,0,0,9537,9538,7,6,0,0,9538,9539,5,95,0,0,9539,
	9540,7,9,0,0,9540,9541,7,0,0,0,9541,9542,7,4,0,0,9542,9543,7,0,0,0,9543,
	932,1,0,0,0,9544,9545,7,6,0,0,9545,9546,7,7,0,0,9546,9547,7,16,0,0,9547,
	9548,7,2,0,0,9548,9549,7,3,0,0,9549,9550,7,8,0,0,9550,9551,7,6,0,0,9551,
	9552,7,9,0,0,9552,934,1,0,0,0,9553,9554,7,6,0,0,9554,9555,7,7,0,0,9555,
	9556,7,16,0,0,9556,9557,7,2,0,0,9557,9558,7,3,0,0,9558,9559,7,8,0,0,9559,
	9560,7,6,0,0,9560,936,1,0,0,0,9561,9562,7,6,0,0,9562,9563,7,7,0,0,9563,
	9564,7,24,0,0,9564,9565,7,10,0,0,9565,9566,7,6,0,0,9566,9567,7,10,0,0,9567,
	9568,7,6,0,0,9568,938,1,0,0,0,9569,9570,7,6,0,0,9570,9571,7,7,0,0,9571,
	9572,7,4,0,0,9572,9573,7,6,0,0,9573,9574,7,3,0,0,9574,9575,7,15,0,0,9575,
	9576,7,3,0,0,9576,9577,7,12,0,0,9577,9578,7,5,0,0,9578,9579,7,6,0,0,9579,
	940,1,0,0,0,9580,9581,7,6,0,0,9581,9582,7,7,0,0,9582,9583,7,4,0,0,9583,
	9584,7,12,0,0,9584,9585,7,4,0,0,9585,9586,7,18,0,0,9586,9587,7,6,0,0,9587,
	9588,7,5,0,0,9588,9589,7,8,0,0,9589,9590,7,0,0,0,9590,9591,7,15,0,0,9591,
	9592,7,12,0,0,9592,9593,7,7,0,0,9593,9594,7,17,0,0,9594,942,1,0,0,0,9595,
	9596,7,6,0,0,9596,9597,7,7,0,0,9597,9598,7,4,0,0,9598,9599,7,3,0,0,9599,
	9600,7,18,0,0,9600,944,1,0,0,0,9601,9602,7,6,0,0,9602,9603,7,24,0,0,9603,
	9604,7,10,0,0,9604,9605,7,12,0,0,9605,9606,7,15,0,0,9606,9607,7,0,0,0,9607,
	9608,7,3,0,0,9608,9609,7,4,0,0,9609,946,1,0,0,0,9610,9611,7,6,0,0,9611,
	9612,7,3,0,0,9612,9613,7,3,0,0,9613,948,1,0,0,0,9614,9615,7,6,0,0,9615,
	9616,7,3,0,0,9616,9617,7,3,0,0,9617,9618,7,2,0,0,9618,9619,7,3,0,0,9619,
	9620,5,95,0,0,9620,9621,7,0,0,0,9621,9622,7,3,0,0,9622,9623,7,17,0,0,9623,
	9624,7,10,0,0,9624,9625,7,14,0,0,9625,9626,7,6,0,0,9626,9627,7,7,0,0,9627,
	9628,7,4,0,0,9628,950,1,0,0,0,9629,9630,7,6,0,0,9630,9631,7,3,0,0,9631,
	9632,7,3,0,0,9632,9633,7,2,0,0,9633,9634,7,3,0,0,9634,952,1,0,0,0,9635,
	9636,7,6,0,0,9636,9637,7,3,0,0,9637,9638,7,3,0,0,9638,9639,7,2,0,0,9639,
	9640,7,3,0,0,9640,9641,5,95,0,0,9641,9642,7,2,0,0,9642,9643,7,7,0,0,9643,
	9644,5,95,0,0,9644,9645,7,2,0,0,9645,9646,7,13,0,0,9646,9647,7,6,0,0,9647,
	9648,7,3,0,0,9648,9649,7,11,0,0,9649,9650,7,0,0,0,9650,9651,7,15,0,0,9651,
	9652,5,95,0,0,9652,9653,7,4,0,0,9653,9654,7,12,0,0,9654,9655,7,14,0,0,9655,
	9656,7,6,0,0,9656,954,1,0,0,0,9657,9658,7,6,0,0,9658,9659,7,3,0,0,9659,
	9660,7,3,0,0,9660,9661,7,2,0,0,9661,9662,7,3,0,0,9662,9663,7,5,0,0,9663,
	956,1,0,0,0,9664,9665,7,6,0,0,9665,9666,7,5,0,0,9666,9667,7,8,0,0,9667,
	9668,7,0,0,0,9668,9669,7,15,0,0,9669,9670,7,6,0,0,9670,958,1,0,0,0,9671,
	9672,7,6,0,0,9672,9673,7,5,0,0,9673,9674,7,4,0,0,9674,9675,7,12,0,0,9675,
	9676,7,14,0,0,9676,9677,7,0,0,0,9677,9678,7,4,0,0,9678,9679,7,6,0,0,9679,
	960,1,0,0,0,9680,9681,7,6,0,0,9681,9682,7,13,0,0,9682,9683,7,0,0,0,9683,
	9684,7,11,0,0,9684,962,1,0,0,0,9685,9686,7,6,0,0,9686,9687,7,13,0,0,9687,
	9688,7,0,0,0,9688,9689,7,11,0,0,9689,9690,7,7,0,0,9690,9691,7,0,0,0,9691,
	9692,7,14,0,0,9692,9693,7,6,0,0,9693,964,1,0,0,0,9694,9695,7,6,0,0,9695,
	9696,7,13,0,0,9696,9697,7,0,0,0,9697,9698,7,11,0,0,9698,9699,7,10,0,0,9699,
	9700,7,0,0,0,9700,9701,7,4,0,0,9701,9702,7,6,0,0,9702,966,1,0,0,0,9703,
	9704,7,6,0,0,9704,9705,7,13,0,0,9705,9706,7,0,0,0,9706,9707,7,11,0,0,9707,
	9708,7,10,0,0,9708,9709,7,0,0,0,9709,9710,7,4,0,0,9710,9711,7,12,0,0,9711,
	9712,7,2,0,0,9712,9713,7,7,0,0,9713,968,1,0,0,0,9714,9715,7,6,0,0,9715,
	9716,7,13,0,0,9716,9717,7,6,0,0,9717,9718,7,7,0,0,9718,9719,7,4,0,0,9719,
	9720,7,5,0,0,9720,970,1,0,0,0,9721,9722,7,6,0,0,9722,9723,7,13,0,0,9723,
	9724,7,6,0,0,9724,9725,7,3,0,0,9725,9726,7,18,0,0,9726,972,1,0,0,0,9727,
	9728,7,6,0,0,9728,9729,7,25,0,0,9729,9730,7,8,0,0,9730,9731,7,6,0,0,9731,
	9732,7,15,0,0,9732,9733,7,4,0,0,9733,974,1,0,0,0,9734,9735,7,6,0,0,9735,
	9736,7,25,0,0,9736,9737,7,8,0,0,9737,9738,7,6,0,0,9738,9739,7,15,0,0,9739,
	9740,7,4,0,0,9740,9741,7,12,0,0,9741,9742,7,2,0,0,9742,9743,7,7,0,0,9743,
	976,1,0,0,0,9744,9745,7,6,0,0,9745,9746,7,25,0,0,9746,9747,7,8,0,0,9747,
	9748,7,6,0,0,9748,9749,7,15,0,0,9749,9750,7,4,0,0,9750,9751,7,12,0,0,9751,
	9752,7,2,0,0,9752,9753,7,7,0,0,9753,9754,5,95,0,0,9754,9755,7,12,0,0,9755,
	9756,7,7,0,0,9756,9757,7,12,0,0,9757,9758,7,4,0,0,9758,978,1,0,0,0,9759,
	9760,7,6,0,0,9760,9761,7,25,0,0,9761,9762,7,8,0,0,9762,9763,7,6,0,0,9763,
	9764,7,15,0,0,9764,9765,7,4,0,0,9765,9766,7,12,0,0,9766,9767,7,2,0,0,9767,
	9768,7,7,0,0,9768,9769,7,5,0,0,9769,980,1,0,0,0,9770,9771,7,6,0,0,9771,
	9772,7,25,0,0,9772,9773,7,8,0,0,9773,9774,7,19,0,0,9774,9775,7,0,0,0,9775,
	9776,7,7,0,0,9776,9777,7,17,0,0,9777,9778,7,6,0,0,9778,982,1,0,0,0,9779,
	9780,7,6,0,0,9780,9781,7,25,0,0,9781,9782,7,8,0,0,9782,9783,7,11,0,0,9783,
	9784,7,10,0,0,9784,9785,7,9,0,0,9785,9786,7,6,0,0,9786,984,1,0,0,0,9787,
	9788,7,6,0,0,9788,9789,7,25,0,0,9789,9790,7,8,0,0,9790,9791,7,11,0,0,9791,
	9792,7,10,0,0,9792,9793,7,9,0,0,9793,9794,7,12,0,0,9794,9795,7,7,0,0,9795,
	9796,7,17,0,0,9796,986,1,0,0,0,9797,9798,7,6,0,0,9798,9799,7,25,0,0,9799,
	9800,7,8,0,0,9800,9801,7,11,0,0,9801,9802,7,10,0,0,9802,9803,7,5,0,0,9803,
	9804,7,12,0,0,9804,9805,7,13,0,0,9805,9806,7,6,0,0,9806,988,1,0,0,0,9807,
	9808,7,6,0,0,9808,9809,7,25,0,0,9809,9810,7,6,0,0,9810,9811,7,8,0,0,9811,
	9812,7,10,0,0,9812,9813,7,4,0,0,9813,9814,7,6,0,0,9814,990,1,0,0,0,9815,
	9816,7,6,0,0,9816,9817,7,25,0,0,9817,9818,7,6,0,0,9818,9819,7,14,0,0,9819,
	9820,7,15,0,0,9820,9821,7,4,0,0,9821,992,1,0,0,0,9822,9823,7,6,0,0,9823,
	9824,7,25,0,0,9824,9825,7,12,0,0,9825,9826,7,5,0,0,9826,9827,7,4,0,0,9827,
	9828,7,12,0,0,9828,9829,7,7,0,0,9829,9830,7,17,0,0,9830,994,1,0,0,0,9831,
	9832,7,6,0,0,9832,9833,7,25,0,0,9833,9834,7,12,0,0,9834,9835,7,5,0,0,9835,
	9836,7,4,0,0,9836,9837,7,5,0,0,9837,996,1,0,0,0,9838,9839,7,6,0,0,9839,
	9840,7,25,0,0,9840,9841,7,12,0,0,9841,9842,7,5,0,0,9842,9843,7,4,0,0,9843,
	9844,7,5,0,0,9844,9845,7,7,0,0,9845,9846,7,2,0,0,9846,9847,7,9,0,0,9847,
	9848,7,6,0,0,9848,998,1,0,0,0,9849,9850,7,6,0,0,9850,9851,7,25,0,0,9851,
	9852,7,12,0,0,9852,9853,7,4,0,0,9853,1000,1,0,0,0,9854,9855,7,6,0,0,9855,
	9856,7,25,0,0,9856,9857,7,15,0,0,9857,9858,7,0,0,0,9858,9859,7,7,0,0,9859,
	9860,7,9,0,0,9860,9861,5,95,0,0,9861,9862,7,17,0,0,9862,9863,7,5,0,0,9863,
	9864,7,6,0,0,9864,9865,7,4,0,0,9865,9866,5,95,0,0,9866,9867,7,4,0,0,9867,
	9868,7,2,0,0,9868,9869,5,95,0,0,9869,9870,7,10,0,0,9870,9871,7,7,0,0,9871,
	9872,7,12,0,0,9872,9873,7,2,0,0,9873,9874,7,7,0,0,9874,1002,1,0,0,0,9875,
	9876,7,6,0,0,9876,9877,7,25,0,0,9877,9878,7,15,0,0,9878,9879,7,0,0,0,9879,
	9880,7,7,0,0,9880,9881,7,9,0,0,9881,9882,5,95,0,0,9882,9883,7,4,0,0,9883,
	9884,7,0,0,0,9884,9885,7,1,0,0,9885,9886,7,11,0,0,9886,9887,7,6,0,0,9887,
	1004,1,0,0,0,9888,9889,7,6,0,0,9889,9890,7,25,0,0,9890,9891,7,15,0,0,9891,
	1006,1,0,0,0,9892,9893,7,6,0,0,9893,9894,7,25,0,0,9894,9895,7,15,0,0,9895,
	9896,7,12,0,0,9896,9897,7,3,0,0,9897,9898,7,6,0,0,9898,1008,1,0,0,0,9899,
	9900,7,6,0,0,9900,9901,7,25,0,0,9901,9902,7,15,0,0,9902,9903,7,11,0,0,9903,
	9904,7,0,0,0,9904,9905,7,12,0,0,9905,9906,7,7,0,0,9906,1010,1,0,0,0,9907,
	9908,7,6,0,0,9908,9909,7,25,0,0,9909,9910,7,15,0,0,9910,9911,7,11,0,0,9911,
	9912,7,2,0,0,9912,9913,7,5,0,0,9913,9914,7,12,0,0,9914,9915,7,2,0,0,9915,
	9916,7,7,0,0,9916,1012,1,0,0,0,9917,9918,7,6,0,0,9918,9919,7,25,0,0,9919,
	9920,7,15,0,0,9920,9921,7,2,0,0,9921,9922,7,3,0,0,9922,9923,7,4,0,0,9923,
	1014,1,0,0,0,9924,9925,7,6,0,0,9925,9926,7,25,0,0,9926,9927,7,15,0,0,9927,
	9928,7,3,0,0,9928,9929,5,95,0,0,9929,9930,7,8,0,0,9930,9931,7,2,0,0,9931,
	9932,7,3,0,0,9932,9933,7,3,0,0,9933,9934,5,95,0,0,9934,9935,7,8,0,0,9935,
	9936,7,19,0,0,9936,9937,7,6,0,0,9937,9938,7,8,0,0,9938,9939,7,21,0,0,9939,
	1016,1,0,0,0,9940,9941,7,6,0,0,9941,9942,7,25,0,0,9942,9943,7,15,0,0,9943,
	9944,7,3,0,0,9944,9945,7,6,0,0,9945,9946,7,5,0,0,9946,9947,7,5,0,0,9947,
	1018,1,0,0,0,9948,9949,7,6,0,0,9949,9950,7,25,0,0,9950,9951,7,4,0,0,9951,
	9952,7,6,0,0,9952,9953,7,7,0,0,9953,9954,7,9,0,0,9954,9955,7,5,0,0,9955,
	1020,1,0,0,0,9956,9957,7,6,0,0,9957,9958,7,25,0,0,9958,9959,7,4,0,0,9959,
	9960,7,6,0,0,9960,9961,7,7,0,0,9961,9962,7,4,0,0,9962,1022,1,0,0,0,9963,
	9964,7,6,0,0,9964,9965,7,25,0,0,9965,9966,7,4,0,0,9966,9967,7,6,0,0,9967,
	9968,7,7,0,0,9968,9969,7,4,0,0,9969,9970,7,5,0,0,9970,1024,1,0,0,0,9971,
	9972,7,6,0,0,9972,9973,7,25,0,0,9973,9974,7,4,0,0,9974,9975,7,6,0,0,9975,
	9976,7,3,0,0,9976,9977,7,7,0,0,9977,9978,7,0,0,0,9978,9979,7,11,0,0,9979,
	1026,1,0,0,0,9980,9981,7,6,0,0,9981,9982,7,25,0,0,9982,9983,7,4,0,0,9983,
	9984,7,6,0,0,9984,9985,7,3,0,0,9985,9986,7,7,0,0,9986,9987,7,0,0,0,9987,
	9988,7,11,0,0,9988,9989,7,11,0,0,9989,9990,7,18,0,0,9990,1028,1,0,0,0,9991,
	9992,7,6,0,0,9992,9993,7,25,0,0,9993,9994,7,4,0,0,9994,9995,7,3,0,0,9995,
	9996,7,0,0,0,9996,9997,7,8,0,0,9997,9998,7,4,0,0,9998,9999,7,8,0,0,9999,
	10000,7,11,0,0,10000,10001,7,2,0,0,10001,10002,7,1,0,0,10002,10003,7,25,
	0,0,10003,10004,7,14,0,0,10004,10005,7,11,0,0,10005,1030,1,0,0,0,10006,
	10007,7,6,0,0,10007,10008,7,25,0,0,10008,10009,7,4,0,0,10009,10010,7,3,
	0,0,10010,10011,7,0,0,0,10011,10012,7,8,0,0,10012,10013,7,4,0,0,10013,1032,
	1,0,0,0,10014,10015,7,6,0,0,10015,10016,7,25,0,0,10016,10017,7,4,0,0,10017,
	10018,7,3,0,0,10018,10019,7,0,0,0,10019,10020,7,8,0,0,10020,10021,7,4,0,
	0,10021,10022,7,13,0,0,10022,10023,7,0,0,0,10023,10024,7,11,0,0,10024,10025,
	7,10,0,0,10025,10026,7,6,0,0,10026,1034,1,0,0,0,10027,10028,7,6,0,0,10028,
	10029,7,25,0,0,10029,10030,7,4,0,0,10030,10031,7,3,0,0,10031,10032,7,0,
	0,0,10032,1036,1,0,0,0,10033,10034,7,16,0,0,10034,10035,7,0,0,0,10035,10036,
	7,8,0,0,10036,10037,7,12,0,0,10037,10038,7,11,0,0,10038,10039,7,12,0,0,
	10039,10040,7,4,0,0,10040,10041,7,18,0,0,10041,1038,1,0,0,0,10042,10043,
	7,16,0,0,10043,10044,7,0,0,0,10044,10045,7,8,0,0,10045,10046,7,4,0,0,10046,
	1040,1,0,0,0,10047,10048,7,16,0,0,10048,10049,7,0,0,0,10049,10050,7,8,0,
	0,10050,10051,7,4,0,0,10051,10052,7,2,0,0,10052,10053,7,3,0,0,10053,1042,
	1,0,0,0,10054,10055,7,16,0,0,10055,10056,7,0,0,0,10056,10057,7,8,0,0,10057,
	10058,7,4,0,0,10058,10059,7,2,0,0,10059,10060,7,3,0,0,10060,10061,7,12,
	0,0,10061,10062,7,23,0,0,10062,10063,7,6,0,0,10063,10064,5,95,0,0,10064,
	10065,7,20,0,0,10065,10066,7,2,0,0,10066,10067,7,12,0,0,10067,10068,7,7,
	0,0,10068,1044,1,0,0,0,10069,10070,7,16,0,0,10070,10071,7,0,0,0,10071,10072,
	7,12,0,0,10072,10073,7,11,0,0,10073,10074,7,6,0,0,10074,10075,7,9,0,0,10075,
	1046,1,0,0,0,10076,10077,7,16,0,0,10077,10078,7,0,0,0,10078,10079,7,12,
	0,0,10079,10080,7,11,0,0,10080,10081,7,6,0,0,10081,10082,7,9,0,0,10082,
	10083,5,95,0,0,10083,10084,7,11,0,0,10084,10085,7,2,0,0,10085,10086,7,17,
	0,0,10086,10087,7,12,0,0,10087,10088,7,7,0,0,10088,10089,5,95,0,0,10089,
	10090,7,0,0,0,10090,10091,7,4,0,0,10091,10092,7,4,0,0,10092,10093,7,6,0,
	0,10093,10094,7,14,0,0,10094,10095,7,15,0,0,10095,10096,7,4,0,0,10096,10097,
	7,5,0,0,10097,1048,1,0,0,0,10098,10099,7,16,0,0,10099,10100,7,0,0,0,10100,
	10101,7,12,0,0,10101,10102,7,11,0,0,10102,10103,7,17,0,0,10103,10104,7,
	3,0,0,10104,10105,7,2,0,0,10105,10106,7,10,0,0,10106,10107,7,15,0,0,10107,
	1050,1,0,0,0,10108,10109,7,16,0,0,10109,10110,7,0,0,0,10110,10111,7,12,
	0,0,10111,10112,7,11,0,0,10112,10113,7,2,0,0,10113,10114,7,13,0,0,10114,
	10115,7,6,0,0,10115,10116,7,3,0,0,10116,1052,1,0,0,0,10117,10118,7,16,0,
	0,10118,10119,7,0,0,0,10119,10120,7,12,0,0,10120,10121,7,11,0,0,10121,10122,
	7,10,0,0,10122,10123,7,3,0,0,10123,10124,7,6,0,0,10124,1054,1,0,0,0,10125,
	10126,7,16,0,0,10126,10127,7,0,0,0,10127,10128,7,11,0,0,10128,10129,7,5,
	0,0,10129,10130,7,6,0,0,10130,1056,1,0,0,0,10131,10132,7,16,0,0,10132,10133,
	7,0,0,0,10133,10134,7,14,0,0,10134,10135,7,12,0,0,10135,10136,7,11,0,0,
	10136,10137,7,18,0,0,10137,1058,1,0,0,0,10138,10139,7,16,0,0,10139,10140,
	7,0,0,0,10140,10141,7,3,0,0,10141,1060,1,0,0,0,10142,10143,7,16,0,0,10143,
	10144,7,0,0,0,10144,10145,7,5,0,0,10145,10146,7,4,0,0,10146,1062,1,0,0,
	0,10147,10148,7,16,0,0,10148,10149,7,0,0,0,10149,10150,7,5,0,0,10150,10151,
	7,4,0,0,10151,10152,7,5,0,0,10152,10153,7,4,0,0,10153,10154,7,0,0,0,10154,
	10155,7,3,0,0,10155,10156,7,4,0,0,10156,1064,1,0,0,0,10157,10158,7,16,0,
	0,10158,10159,7,1,0,0,10159,10160,7,4,0,0,10160,10161,7,5,0,0,10161,10162,
	7,8,0,0,10162,10163,7,0,0,0,10163,10164,7,7,0,0,10164,1066,1,0,0,0,10165,
	10166,7,16,0,0,10166,10167,7,6,0,0,10167,10168,7,0,0,0,10168,10169,7,4,
	0,0,10169,10170,7,10,0,0,10170,10171,7,3,0,0,10171,10172,7,6,0,0,10172,
	1068,1,0,0,0,10173,10174,7,16,0,0,10174,10175,7,6,0,0,10175,10176,7,0,0,
	0,10176,10177,7,4,0,0,10177,10178,7,10,0,0,10178,10179,7,3,0,0,10179,10180,
	7,6,0,0,10180,10181,5,95,0,0,10181,10182,7,9,0,0,10182,10183,7,6,0,0,10183,
	10184,7,4,0,0,10184,10185,7,0,0,0,10185,10186,7,12,0,0,10186,10187,7,11,
	0,0,10187,10188,7,5,0,0,10188,1070,1,0,0,0,10189,10190,7,16,0,0,10190,10191,
	7,6,0,0,10191,10192,7,0,0,0,10192,10193,7,4,0,0,10193,10194,7,10,0,0,10194,
	10195,7,3,0,0,10195,10196,7,6,0,0,10196,10197,5,95,0,0,10197,10198,7,12,
	0,0,10198,10199,7,9,0,0,10199,1072,1,0,0,0,10200,10201,7,16,0,0,10201,10202,
	7,6,0,0,10202,10203,7,0,0,0,10203,10204,7,4,0,0,10204,10205,7,10,0,0,10205,
	10206,7,3,0,0,10206,10207,7,6,0,0,10207,10208,5,95,0,0,10208,10209,7,5,
	0,0,10209,10210,7,6,0,0,10210,10211,7,4,0,0,10211,1074,1,0,0,0,10212,10213,
	7,16,0,0,10213,10214,7,6,0,0,10214,10215,7,0,0,0,10215,10216,7,4,0,0,10216,
	10217,7,10,0,0,10217,10218,7,3,0,0,10218,10219,7,6,0,0,10219,10220,5,95,
	0,0,10220,10221,7,13,0,0,10221,10222,7,0,0,0,10222,10223,7,11,0,0,10223,
	10224,7,10,0,0,10224,10225,7,6,0,0,10225,1076,1,0,0,0,10226,10227,7,16,
	0,0,10227,10228,7,6,0,0,10228,10229,7,4,0,0,10229,10230,7,8,0,0,10230,10231,
	7,19,0,0,10231,1078,1,0,0,0,10232,10233,7,16,0,0,10233,10234,7,12,0,0,10234,
	10235,7,11,0,0,10235,10236,7,6,0,0,10236,1080,1,0,0,0,10237,10238,7,16,
	0,0,10238,10239,7,12,0,0,10239,10240,7,11,0,0,10240,10241,7,6,0,0,10241,
	10242,5,95,0,0,10242,10243,7,7,0,0,10243,10244,7,0,0,0,10244,10245,7,14,
	0,0,10245,10246,7,6,0,0,10246,10247,5,95,0,0,10247,10248,7,8,0,0,10248,
	10249,7,2,0,0,10249,10250,7,7,0,0,10250,10251,7,13,0,0,10251,10252,7,6,
	0,0,10252,10253,7,3,0,0,10253,10254,7,4,0,0,10254,1082,1,0,0,0,10255,10256,
	7,16,0,0,10256,10257,7,12,0,0,10257,10258,7,11,0,0,10258,10259,7,6,0,0,
	10259,10260,7,17,0,0,10260,10261,7,3,0,0,10261,10262,7,2,0,0,10262,10263,
	7,10,0,0,10263,10264,7,15,0,0,10264,1084,1,0,0,0,10265,10266,7,16,0,0,10266,
	10267,7,12,0,0,10267,10268,7,11,0,0,10268,10269,7,6,0,0,10269,10270,7,5,
	0,0,10270,10271,7,4,0,0,10271,10272,7,2,0,0,10272,10273,7,3,0,0,10273,10274,
	7,6,0,0,10274,1086,1,0,0,0,10275,10276,7,16,0,0,10276,10277,7,12,0,0,10277,
	10278,7,11,0,0,10278,10279,7,6,0,0,10279,10280,7,5,0,0,10280,10281,7,18,
	0,0,10281,10282,7,5,0,0,10282,10283,7,4,0,0,10283,10284,7,6,0,0,10284,10285,
	7,14,0,0,10285,10286,5,95,0,0,10286,10287,7,11,0,0,10287,10288,7,12,0,0,
	10288,10289,7,21,0,0,10289,10290,7,6,0,0,10290,10291,5,95,0,0,10291,10292,
	7,11,0,0,10292,10293,7,2,0,0,10293,10294,7,17,0,0,10294,10295,7,17,0,0,
	10295,10296,7,12,0,0,10296,10297,7,7,0,0,10297,10298,7,17,0,0,10298,1088,
	1,0,0,0,10299,10300,7,16,0,0,10300,10301,7,12,0,0,10301,10302,7,11,0,0,
	10302,10303,7,4,0,0,10303,10304,7,6,0,0,10304,10305,7,3,0,0,10305,1090,
	1,0,0,0,10306,10307,7,16,0,0,10307,10308,7,12,0,0,10308,10309,7,7,0,0,10309,
	10310,7,0,0,0,10310,10311,7,11,0,0,10311,1092,1,0,0,0,10312,10313,7,16,
	0,0,10313,10314,7,12,0,0,10314,10315,7,7,0,0,10315,10316,7,6,0,0,10316,
	1094,1,0,0,0,10317,10318,7,16,0,0,10318,10319,7,12,0,0,10319,10320,7,7,
	0,0,10320,10321,7,12,0,0,10321,10322,7,5,0,0,10322,10323,7,19,0,0,10323,
	1096,1,0,0,0,10324,10325,7,16,0,0,10325,10326,7,12,0,0,10326,10327,7,3,
	0,0,10327,10328,7,5,0,0,10328,10329,7,4,0,0,10329,1098,1,0,0,0,10330,10331,
	7,16,0,0,10331,10332,7,12,0,0,10332,10333,7,3,0,0,10333,10334,7,5,0,0,10334,
	10335,7,4,0,0,10335,10336,7,14,0,0,10336,1100,1,0,0,0,10337,10338,7,16,
	0,0,10338,10339,7,12,0,0,10339,10340,7,3,0,0,10340,10341,7,5,0,0,10341,
	10342,7,4,0,0,10342,10343,5,95,0,0,10343,10344,7,3,0,0,10344,10345,7,2,
	0,0,10345,10346,7,22,0,0,10346,10347,7,5,0,0,10347,1102,1,0,0,0,10348,10349,
	7,16,0,0,10349,10350,7,12,0,0,10350,10351,7,3,0,0,10351,10352,7,5,0,0,10352,
	10353,7,4,0,0,10353,10354,5,95,0,0,10354,10355,7,13,0,0,10355,10356,7,0,
	0,0,10356,10357,7,11,0,0,10357,10358,7,10,0,0,10358,10359,7,6,0,0,10359,
	1104,1,0,0,0,10360,10361,7,16,0,0,10361,10362,7,12,0,0,10362,10363,7,25,
	0,0,10363,10364,7,6,0,0,10364,10365,7,9,0,0,10365,10366,5,95,0,0,10366,
	10367,7,13,0,0,10367,10368,7,12,0,0,10368,10369,7,6,0,0,10369,10370,7,22,
	0,0,10370,10371,5,95,0,0,10371,10372,7,9,0,0,10372,10373,7,0,0,0,10373,
	10374,7,4,0,0,10374,10375,7,0,0,0,10375,1106,1,0,0,0,10376,10377,7,16,0,
	0,10377,10378,7,11,0,0,10378,10379,7,0,0,0,10379,10380,7,17,0,0,10380,10381,
	7,17,0,0,10381,10382,7,6,0,0,10382,10383,7,3,0,0,10383,1108,1,0,0,0,10384,
	10385,7,16,0,0,10385,10386,7,11,0,0,10386,10387,7,0,0,0,10387,10388,7,5,
	0,0,10388,10389,7,19,0,0,10389,10390,7,1,0,0,10390,10391,7,0,0,0,10391,
	10392,7,8,0,0,10392,10393,7,21,0,0,10393,1110,1,0,0,0,10394,10395,7,16,
	0,0,10395,10396,7,11,0,0,10396,10397,7,0,0,0,10397,10398,7,5,0,0,10398,
	10399,7,19,0,0,10399,10400,5,95,0,0,10400,10401,7,8,0,0,10401,10402,7,0,
	0,0,10402,10403,7,8,0,0,10403,10404,7,19,0,0,10404,10405,7,6,0,0,10405,
	1112,1,0,0,0,10406,10407,7,16,0,0,10407,10408,7,11,0,0,10408,10409,7,2,
	0,0,10409,10410,7,0,0,0,10410,10411,7,4,0,0,10411,1114,1,0,0,0,10412,10413,
	7,16,0,0,10413,10414,7,11,0,0,10414,10415,7,2,0,0,10415,10416,7,1,0,0,10416,
	1116,1,0,0,0,10417,10418,7,16,0,0,10418,10419,7,11,0,0,10419,10420,7,6,
	0,0,10420,10421,7,25,0,0,10421,1118,1,0,0,0,10422,10423,7,16,0,0,10423,
	10424,7,11,0,0,10424,10425,7,2,0,0,10425,10426,7,2,0,0,10426,10427,7,3,
	0,0,10427,1120,1,0,0,0,10428,10429,7,16,0,0,10429,10430,7,11,0,0,10430,
	10431,7,10,0,0,10431,10432,7,5,0,0,10432,10433,7,19,0,0,10433,1122,1,0,
	0,0,10434,10435,7,16,0,0,10435,10436,7,2,0,0,10436,10437,7,11,0,0,10437,
	10438,7,9,0,0,10438,10439,7,6,0,0,10439,10440,7,3,0,0,10440,1124,1,0,0,
	0,10441,10442,7,16,0,0,10442,10443,7,2,0,0,10443,10444,7,11,0,0,10444,10445,
	7,11,0,0,10445,10446,7,2,0,0,10446,10447,7,22,0,0,10447,10448,7,12,0,0,
	10448,10449,7,7,0,0,10449,10450,7,17,0,0,10450,1126,1,0,0,0,10451,10452,
	7,16,0,0,10452,10453,7,2,0,0,10453,10454,7,11,0,0,10454,10455,7,11,0,0,
	10455,10456,7,2,0,0,10456,10457,7,22,0,0,10457,10458,7,5,0,0,10458,1128,
	1,0,0,0,10459,10460,7,16,0,0,10460,10461,7,2,0,0,10461,10462,7,3,0,0,10462,
	10463,7,0,0,0,10463,10464,7,11,0,0,10464,10465,7,11,0,0,10465,1130,1,0,
	0,0,10466,10467,7,16,0,0,10467,10468,7,2,0,0,10468,10469,7,3,0,0,10469,
	10470,7,8,0,0,10470,10471,7,6,0,0,10471,1132,1,0,0,0,10472,10473,7,16,0,
	0,10473,10474,7,2,0,0,10474,10475,7,3,0,0,10475,10476,7,8,0,0,10476,10477,
	7,6,0,0,10477,10478,5,95,0,0,10478,10479,7,25,0,0,10479,10480,7,14,0,0,
	10480,10481,7,11,0,0,10481,10482,5,95,0,0,10482,10483,7,24,0,0,10483,10484,
	7,10,0,0,10484,10485,7,6,0,0,10485,10486,7,3,0,0,10486,10487,7,18,0,0,10487,
	10488,5,95,0,0,10488,10489,7,3,0,0,10489,10490,7,6,0,0,10490,10491,7,22,
	0,0,10491,10492,7,3,0,0,10492,10493,7,12,0,0,10493,10494,7,4,0,0,10494,
	10495,7,6,0,0,10495,1134,1,0,0,0,10496,10497,7,16,0,0,10497,10498,7,2,0,
	0,10498,10499,7,3,0,0,10499,10500,7,6,0,0,10500,10501,7,12,0,0,10501,10502,
	7,17,0,0,10502,10503,7,7,0,0,10503,1136,1,0,0,0,10504,10505,7,16,0,0,10505,
	10506,7,2,0,0,10506,10507,7,3,0,0,10507,10508,7,6,0,0,10508,10509,7,13,
	0,0,10509,10510,7,6,0,0,10510,10511,7,3,0,0,10511,1138,1,0,0,0,10512,10513,
	7,16,0,0,10513,10514,7,2,0,0,10514,10515,7,3,0,0,10515,1140,1,0,0,0,10516,
	10517,7,16,0,0,10517,10518,7,2,0,0,10518,10519,7,3,0,0,10519,10520,7,14,
	0,0,10520,10521,7,0,0,0,10521,10522,7,4,0,0,10522,1142,1,0,0,0,10523,10524,
	7,16,0,0,10524,10525,7,2,0,0,10525,10526,7,3,0,0,10526,10527,7,22,0,0,10527,
	10528,7,0,0,0,10528,10529,7,3,0,0,10529,10530,7,9,0,0,10530,1144,1,0,0,
	0,10531,10532,7,16,0,0,10532,10533,7,3,0,0,10533,10534,7,0,0,0,10534,10535,
	7,17,0,0,10535,10536,7,14,0,0,10536,10537,7,6,0,0,10537,10538,7,7,0,0,10538,
	10539,7,4,0,0,10539,10540,5,95,0,0,10540,10541,7,7,0,0,10541,10542,7,10,
	0,0,10542,10543,7,14,0,0,10543,10544,7,1,0,0,10544,10545,7,6,0,0,10545,
	10546,7,3,0,0,10546,1146,1,0,0,0,10547,10548,7,16,0,0,10548,10549,7,3,0,
	0,10549,10550,7,6,0,0,10550,10551,7,6,0,0,10551,10552,7,11,0,0,10552,10553,
	7,12,0,0,10553,10554,7,5,0,0,10554,10555,7,4,0,0,10555,1148,1,0,0,0,10556,
	10557,7,16,0,0,10557,10558,7,3,0,0,10558,10559,7,6,0,0,10559,10560,7,6,
	0,0,10560,10561,7,11,0,0,10561,10562,7,12,0,0,10562,10563,7,5,0,0,10563,
	10564,7,4,0,0,10564,10565,7,5,0,0,10565,1150,1,0,0,0,10566,10567,7,16,0,
	0,10567,10568,7,3,0,0,10568,10569,7,6,0,0,10569,10570,7,6,0,0,10570,10571,
	7,15,0,0,10571,10572,7,2,0,0,10572,10573,7,2,0,0,10573,10574,7,11,0,0,10574,
	10575,7,5,0,0,10575,1152,1,0,0,0,10576,10577,7,16,0,0,10577,10578,7,3,0,
	0,10578,10579,7,6,0,0,10579,10580,7,5,0,0,10580,10581,7,19,0,0,10581,1154,
	1,0,0,0,10582,10583,7,16,0,0,10583,10584,7,3,0,0,10584,10585,7,2,0,0,10585,
	10586,7,14,0,0,10586,1156,1,0,0,0,10587,10588,7,16,0,0,10588,10589,7,3,
	0,0,10589,10590,7,2,0,0,10590,10591,7,14,0,0,10591,10592,5,95,0,0,10592,
	10593,7,4,0,0,10593,10594,7,23,0,0,10594,1158,1,0,0,0,10595,10596,7,16,
	0,0,10596,10597,7,10,0,0,10597,10598,7,11,0,0,10598,10599,7,11,0,0,10599,
	1160,1,0,0,0,10600,10601,7,16,0,0,10601,10602,7,10,0,0,10602,10603,7,11,
	0,0,10603,10604,7,11,0,0,10604,10605,5,95,0,0,10605,10606,7,2,0,0,10606,
	10607,7,10,0,0,10607,10608,7,4,0,0,10608,10609,7,6,0,0,10609,10610,7,3,
	0,0,10610,10611,5,95,0,0,10611,10612,7,20,0,0,10612,10613,7,2,0,0,10613,
	10614,7,12,0,0,10614,10615,7,7,0,0,10615,10616,5,95,0,0,10616,10617,7,4,
	0,0,10617,10618,7,2,0,0,10618,10619,5,95,0,0,10619,10620,7,2,0,0,10620,
	10621,7,10,0,0,10621,10622,7,4,0,0,10622,10623,7,6,0,0,10623,10624,7,3,
	0,0,10624,1162,1,0,0,0,10625,10626,7,16,0,0,10626,10627,7,10,0,0,10627,
	10628,7,7,0,0,10628,10629,7,8,0,0,10629,10630,7,4,0,0,10630,10631,7,12,
	0,0,10631,10632,7,2,0,0,10632,10633,7,7,0,0,10633,1164,1,0,0,0,10634,10635,
	7,16,0,0,10635,10636,7,10,0,0,10636,10637,7,7,0,0,10637,10638,7,8,0,0,10638,
	10639,7,4,0,0,10639,10640,7,12,0,0,10640,10641,7,2,0,0,10641,10642,7,7,
	0,0,10642,10643,7,5,0,0,10643,1166,1,0,0,0,10644,10645,7,16,0,0,10645,10646,
	7,4,0,0,10646,10647,7,15,0,0,10647,1168,1,0,0,0,10648,10649,7,17,0,0,10649,
	1170,1,0,0,0,10650,10651,7,17,0,0,10651,10652,7,0,0,0,10652,10653,7,4,0,
	0,10653,10654,7,19,0,0,10654,10655,7,6,0,0,10655,10656,7,3,0,0,10656,10657,
	5,95,0,0,10657,10658,7,2,0,0,10658,10659,7,15,0,0,10659,10660,7,4,0,0,10660,
	10661,7,12,0,0,10661,10662,7,14,0,0,10662,10663,7,12,0,0,10663,10664,7,
	23,0,0,10664,10665,7,6,0,0,10665,10666,7,3,0,0,10666,10667,5,95,0,0,10667,
	10668,7,5,0,0,10668,10669,7,4,0,0,10669,10670,7,0,0,0,10670,10671,7,4,0,
	0,10671,10672,7,12,0,0,10672,10673,7,5,0,0,10673,10674,7,4,0,0,10674,10675,
	7,12,0,0,10675,10676,7,8,0,0,10676,10677,7,5,0,0,10677,1172,1,0,0,0,10678,
	10679,7,17,0,0,10679,10680,7,0,0,0,10680,10681,7,4,0,0,10681,10682,7,19,
	0,0,10682,10683,7,6,0,0,10683,10684,7,3,0,0,10684,10685,5,95,0,0,10685,
	10686,7,15,0,0,10686,10687,7,11,0,0,10687,10688,7,0,0,0,10688,10689,7,7,
	0,0,10689,10690,5,95,0,0,10690,10691,7,5,0,0,10691,10692,7,4,0,0,10692,
	10693,7,0,0,0,10693,10694,7,4,0,0,10694,10695,7,12,0,0,10695,10696,7,5,
	0,0,10696,10697,7,4,0,0,10697,10698,7,12,0,0,10698,10699,7,8,0,0,10699,
	10700,7,5,0,0,10700,1174,1,0,0,0,10701,10702,7,17,0,0,10702,10703,7,1,0,
	0,10703,10704,7,18,0,0,10704,10705,5,95,0,0,10705,10706,7,8,0,0,10706,10707,
	7,2,0,0,10707,10708,7,7,0,0,10708,10709,7,8,0,0,10709,10710,5,95,0,0,10710,
	10711,7,3,0,0,10711,10712,7,2,0,0,10712,10713,7,11,0,0,10713,10714,7,11,
	0,0,10714,10715,7,10,0,0,10715,10716,7,15,0,0,10716,1176,1,0,0,0,10717,
	10718,7,17,0,0,10718,10719,7,1,0,0,10719,10720,7,18,0,0,10720,10721,5,95,
	0,0,10721,10722,7,15,0,0,10722,10723,7,10,0,0,10723,10724,7,5,0,0,10724,
	10725,7,19,0,0,10725,10726,7,9,0,0,10726,10727,7,2,0,0,10727,10728,7,22,
	0,0,10728,10729,7,7,0,0,10729,1178,1,0,0,0,10730,10731,7,17,0,0,10731,10732,
	7,6,0,0,10732,10733,7,7,0,0,10733,10734,7,6,0,0,10734,10735,7,3,0,0,10735,
	10736,7,0,0,0,10736,10737,7,4,0,0,10737,10738,7,6,0,0,10738,10739,7,9,0,
	0,10739,1180,1,0,0,0,10740,10741,7,17,0,0,10741,10742,7,6,0,0,10742,10743,
	7,4,0,0,10743,1182,1,0,0,0,10744,10745,7,17,0,0,10745,10746,7,11,0,0,10746,
	10747,7,2,0,0,10747,10748,7,1,0,0,10748,10749,7,0,0,0,10749,10750,7,11,
	0,0,10750,1184,1,0,0,0,10751,10752,7,17,0,0,10752,10753,7,11,0,0,10753,
	10754,7,2,0,0,10754,10755,7,1,0,0,10755,10756,7,0,0,0,10756,10757,7,11,
	0,0,10757,10758,7,11,0,0,10758,10759,7,18,0,0,10759,1186,1,0,0,0,10760,
	10761,7,17,0,0,10761,10762,7,11,0,0,10762,10763,7,2,0,0,10763,10764,7,1,
	0,0,10764,10765,7,0,0,0,10765,10766,7,11,0,0,10766,10767,5,95,0,0,10767,
	10768,7,7,0,0,10768,10769,7,0,0,0,10769,10770,7,14,0,0,10770,10771,7,6,
	0,0,10771,1188,1,0,0,0,10772,10773,7,17,0,0,10773,10774,7,11,0,0,10774,
	10775,7,2,0,0,10775,10776,7,1,0,0,10776,10777,7,0,0,0,10777,10778,7,11,
	0,0,10778,10779,5,95,0,0,10779,10780,7,4,0,0,10780,10781,7,2,0,0,10781,
	10782,7,15,0,0,10782,10783,7,12,0,0,10783,10784,7,8,0,0,10784,10785,5,95,
	0,0,10785,10786,7,6,0,0,10786,10787,7,7,0,0,10787,10788,7,0,0,0,10788,10789,
	7,1,0,0,10789,10790,7,11,0,0,10790,10791,7,6,0,0,10791,10792,7,9,0,0,10792,
	1190,1,0,0,0,10793,10794,7,17,0,0,10794,10795,7,2,0,0,10795,10796,7,4,0,
	0,10796,10797,7,2,0,0,10797,1192,1,0,0,0,10798,10799,7,17,0,0,10799,10800,
	7,3,0,0,10800,10801,7,0,0,0,10801,10802,7,7,0,0,10802,10803,7,4,0,0,10803,
	1194,1,0,0,0,10804,10805,7,17,0,0,10805,10806,7,3,0,0,10806,10807,7,2,0,
	0,10807,10808,7,10,0,0,10808,10809,7,15,0,0,10809,10810,5,95,0,0,10810,
	10811,7,1,0,0,10811,10812,7,18,0,0,10812,1196,1,0,0,0,10813,10814,7,17,
	0,0,10814,10815,7,3,0,0,10815,10816,7,2,0,0,10816,10817,7,10,0,0,10817,
	10818,7,15,0,0,10818,1198,1,0,0,0,10819,10820,7,17,0,0,10820,10821,7,3,
	0,0,10821,10822,7,2,0,0,10822,10823,7,10,0,0,10823,10824,7,15,0,0,10824,
	10825,5,95,0,0,10825,10826,7,12,0,0,10826,10827,7,9,0,0,10827,1200,1,0,
	0,0,10828,10829,7,17,0,0,10829,10830,7,3,0,0,10830,10831,7,2,0,0,10831,
	10832,7,10,0,0,10832,10833,7,15,0,0,10833,10834,7,12,0,0,10834,10835,7,
	7,0,0,10835,10836,7,17,0,0,10836,1202,1,0,0,0,10837,10838,7,17,0,0,10838,
	10839,7,3,0,0,10839,10840,7,2,0,0,10840,10841,7,10,0,0,10841,10842,7,15,
	0,0,10842,10843,7,12,0,0,10843,10844,7,7,0,0,10844,10845,7,17,0,0,10845,
	10846,5,95,0,0,10846,10847,7,12,0,0,10847,10848,7,9,0,0,10848,1204,1,0,
	0,0,10849,10850,7,17,0,0,10850,10851,7,3,0,0,10851,10852,7,2,0,0,10852,
	10853,7,10,0,0,10853,10854,7,15,0,0,10854,10855,7,5,0,0,10855,1206,1,0,
	0,0,10856,10857,7,17,0,0,10857,10858,7,10,0,0,10858,10859,7,0,0,0,10859,
	10860,7,3,0,0,10860,10861,7,0,0,0,10861,10862,7,7,0,0,10862,10863,7,4,0,
	0,10863,10864,7,6,0,0,10864,10865,7,6,0,0,10865,10866,7,9,0,0,10866,1208,
	1,0,0,0,10867,10868,7,17,0,0,10868,10869,7,10,0,0,10869,10870,7,0,0,0,10870,
	10871,7,3,0,0,10871,10872,7,0,0,0,10872,10873,7,7,0,0,10873,10874,7,4,0,
	0,10874,10875,7,6,0,0,10875,10876,7,6,0,0,10876,1210,1,0,0,0,10877,10878,
	7,17,0,0,10878,10879,7,10,0,0,10879,10880,7,0,0,0,10880,10881,7,3,0,0,10881,
	10882,7,9,0,0,10882,1212,1,0,0,0,10883,10884,7,19,0,0,10884,10885,7,0,0,
	0,10885,10886,7,11,0,0,10886,10887,7,16,0,0,10887,10888,5,95,0,0,10888,
	10889,7,18,0,0,10889,10890,7,6,0,0,10890,10891,7,0,0,0,10891,10892,7,3,
	0,0,10892,10893,7,5,0,0,10893,1214,1,0,0,0,10894,10895,7,19,0,0,10895,10896,
	7,0,0,0,10896,10897,7,5,0,0,10897,10898,7,19,0,0,10898,10899,5,95,0,0,10899,
	10900,7,0,0,0,10900,10901,7,20,0,0,10901,1216,1,0,0,0,10902,10903,7,19,
	0,0,10903,10904,7,0,0,0,10904,10905,7,5,0,0,10905,10906,7,19,0,0,10906,
	1218,1,0,0,0,10907,10908,7,19,0,0,10908,10909,7,0,0,0,10909,10910,7,5,0,
	0,10910,10911,7,19,0,0,10911,10912,7,21,0,0,10912,10913,7,6,0,0,10913,10914,
	7,18,0,0,10914,10915,7,5,0,0,10915,1220,1,0,0,0,10916,10917,7,19,0,0,10917,
	10918,7,0,0,0,10918,10919,7,5,0,0,10919,10920,7,19,0,0,10920,10921,5,95,
	0,0,10921,10922,7,5,0,0,10922,10923,7,20,0,0,10923,1222,1,0,0,0,10924,10925,
	7,19,0,0,10925,10926,7,0,0,0,10926,10927,7,13,0,0,10927,10928,7,12,0,0,
	10928,10929,7,7,0,0,10929,10930,7,17,0,0,10930,1224,1,0,0,0,10931,10932,
	7,19,0,0,10932,10933,7,6,0,0,10933,10934,7,0,0,0,10934,10935,7,9,0,0,10935,
	10936,7,6,0,0,10936,10937,7,3,0,0,10937,1226,1,0,0,0,10938,10939,7,19,0,
	0,10939,10940,7,6,0,0,10940,10941,7,0,0,0,10941,10942,7,15,0,0,10942,1228,
	1,0,0,0,10943,10944,7,19,0,0,10944,10945,7,6,0,0,10945,10946,7,11,0,0,10946,
	10947,7,15,0,0,10947,1230,1,0,0,0,10948,10949,7,19,0,0,10949,10950,7,6,
	0,0,10950,10951,7,25,0,0,10951,10952,7,4,0,0,10952,10953,7,2,0,0,10953,
	10954,7,3,0,0,10954,10955,7,0,0,0,10955,10956,7,22,0,0,10956,1232,1,0,0,
	0,10957,10958,7,19,0,0,10958,10959,7,6,0,0,10959,10960,7,25,0,0,10960,10961,
	7,4,0,0,10961,10962,7,2,0,0,10962,10963,7,3,0,0,10963,10964,7,6,0,0,10964,
	10965,7,16,0,0,10965,1234,1,0,0,0,10966,10967,7,19,0,0,10967,10968,7,12,
	0,0,10968,10969,7,9,0,0,10969,10970,7,9,0,0,10970,10971,7,6,0,0,10971,10972,
	7,7,0,0,10972,1236,1,0,0,0,10973,10974,7,19,0,0,10974,10975,7,12,0,0,10975,
	10976,7,9,0,0,10976,10977,7,6,0,0,10977,1238,1,0,0,0,10978,10979,7,19,0,
	0,10979,10980,7,12,0,0,10980,10981,7,6,0,0,10981,10982,7,3,0,0,10982,10983,
	5,95,0,0,10983,10984,7,2,0,0,10984,10985,7,3,0,0,10985,10986,7,9,0,0,10986,
	10987,7,6,0,0,10987,10988,7,3,0,0,10988,1240,1,0,0,0,10989,10990,7,19,0,
	0,10990,10991,7,12,0,0,10991,10992,7,6,0,0,10992,10993,7,3,0,0,10993,10994,
	7,0,0,0,10994,10995,7,3,0,0,10995,10996,7,8,0,0,10996,10997,7,19,0,0,10997,
	10998,7,12,0,0,10998,10999,7,8,0,0,10999,11000,7,0,0,0,11000,11001,7,11,
	0,0,11001,1242,1,0,0,0,11002,11003,7,19,0,0,11003,11004,7,12,0,0,11004,
	11005,7,6,0,0,11005,11006,7,3,0,0,11006,11007,7,0,0,0,11007,11008,7,3,0,
	0,11008,11009,7,8,0,0,11009,11010,7,19,0,0,11010,11011,7,12,0,0,11011,11012,
	7,6,0,0,11012,11013,7,5,0,0,11013,1244,1,0,0,0,11014,11015,7,19,0,0,11015,
	11016,7,12,0,0,11016,11017,7,6,0,0,11017,11018,7,3,0,0,11018,11019,7,0,
	0,0,11019,11020,7,3,0,0,11020,11021,7,8,0,0,11021,11022,7,19,0,0,11022,
	11023,7,18,0,0,11023,1246,1,0,0,0,11024,11025,7,19,0,0,11025,11026,7,12,
	0,0,11026,11027,7,17,0,0,11027,11028,7,19,0,0,11028,1248,1,0,0,0,11029,
	11030,7,19,0,0,11030,11031,7,12,0,0,11031,11032,7,7,0,0,11032,11033,7,4,
	0,0,11033,11034,7,5,0,0,11034,11035,7,6,0,0,11035,11036,7,4,0,0,11036,11037,
	5,95,0,0,11037,11038,7,1,0,0,11038,11039,7,6,0,0,11039,11040,7,17,0,0,11040,
	11041,7,12,0,0,11041,11042,7,7,0,0,11042,1250,1,0,0,0,11043,11044,7,19,
	0,0,11044,11045,7,12,0,0,11045,11046,7,7,0,0,11046,11047,7,4,0,0,11047,
	11048,7,5,0,0,11048,11049,7,6,0,0,11049,11050,7,4,0,0,11050,11051,5,95,
	0,0,11051,11052,7,6,0,0,11052,11053,7,7,0,0,11053,11054,7,9,0,0,11054,1252,
	1,0,0,0,11055,11056,7,19,0,0,11056,11057,7,2,0,0,11057,11058,7,4,0,0,11058,
	1254,1,0,0,0,11059,11060,7,19,0,0,11060,11061,7,2,0,0,11061,11062,7,10,
	0,0,11062,11063,7,3,0,0,11063,1256,1,0,0,0,11064,11065,7,19,0,0,11065,11066,
	7,2,0,0,11066,11067,7,10,0,0,11067,11068,7,3,0,0,11068,11069,7,5,0,0,11069,
	1258,1,0,0,0,11070,11071,7,19,0,0,11071,11072,7,4,0,0,11072,11073,7,4,0,
	0,11073,11074,7,15,0,0,11074,1260,1,0,0,0,11075,11076,7,19,0,0,11076,11077,
	7,22,0,0,11077,11078,7,14,0,0,11078,11079,5,95,0,0,11079,11080,7,1,0,0,
	11080,11081,7,3,0,0,11081,11082,7,2,0,0,11082,11083,7,21,0,0,11083,11084,
	7,6,0,0,11084,11085,7,3,0,0,11085,11086,7,6,0,0,11086,11087,7,9,0,0,11087,
	1262,1,0,0,0,11088,11089,7,19,0,0,11089,11090,7,18,0,0,11090,11091,7,1,
	0,0,11091,11092,7,3,0,0,11092,11093,7,12,0,0,11093,11094,7,9,0,0,11094,
	1264,1,0,0,0,11095,11096,7,19,0,0,11096,1266,1,0,0,0,11097,11098,7,12,0,
	0,11098,11099,7,9,0,0,11099,11100,7,6,0,0,11100,11101,7,7,0,0,11101,11102,
	7,4,0,0,11102,11103,7,12,0,0,11103,11104,7,16,0,0,11104,11105,7,12,0,0,
	11105,11106,7,6,0,0,11106,11107,7,9,0,0,11107,1268,1,0,0,0,11108,11109,
	7,12,0,0,11109,11110,7,9,0,0,11110,11111,7,6,0,0,11111,11112,7,7,0,0,11112,
	11113,7,4,0,0,11113,11114,7,12,0,0,11114,11115,7,16,0,0,11115,11116,7,12,
	0,0,11116,11117,7,6,0,0,11117,11118,7,3,0,0,11118,1270,1,0,0,0,11119,11120,
	7,12,0,0,11120,11121,7,9,0,0,11121,11122,7,6,0,0,11122,11123,7,7,0,0,11123,
	11124,7,4,0,0,11124,11125,7,12,0,0,11125,11126,7,4,0,0,11126,11127,7,18,
	0,0,11127,1272,1,0,0,0,11128,11129,7,12,0,0,11129,11130,7,9,0,0,11130,11131,
	7,17,0,0,11131,11132,7,6,0,0,11132,11133,7,7,0,0,11133,11134,7,6,0,0,11134,
	11135,7,3,0,0,11135,11136,7,0,0,0,11136,11137,7,4,0,0,11137,11138,7,2,0,
	0,11138,11139,7,3,0,0,11139,11140,7,5,0,0,11140,1274,1,0,0,0,11141,11142,
	7,12,0,0,11142,11143,7,9,0,0,11143,1276,1,0,0,0,11144,11145,7,12,0,0,11145,
	11146,7,9,0,0,11146,11147,7,11,0,0,11147,11148,7,6,0,0,11148,11149,5,95,
	0,0,11149,11150,7,4,0,0,11150,11151,7,12,0,0,11151,11152,7,14,0,0,11152,
	11153,7,6,0,0,11153,1278,1,0,0,0,11154,11155,7,12,0,0,11155,11156,7,16,
	0,0,11156,1280,1,0,0,0,11157,11158,7,12,0,0,11158,11159,7,17,0,0,11159,
	11160,7,7,0,0,11160,11161,7,2,0,0,11161,11162,7,3,0,0,11162,11163,7,6,0,
	0,11163,1282,1,0,0,0,11164,11165,7,12,0,0,11165,11166,7,17,0,0,11166,11167,
	7,7,0,0,11167,11168,7,2,0,0,11168,11169,7,3,0,0,11169,11170,7,6,0,0,11170,
	11171,5,95,0,0,11171,11172,7,2,0,0,11172,11173,7,15,0,0,11173,11174,7,4,
	0,0,11174,11175,7,12,0,0,11175,11176,7,14,0,0,11176,11177,5,95,0,0,11177,
	11178,7,6,0,0,11178,11179,7,14,0,0,11179,11180,7,1,0,0,11180,11181,7,6,
	0,0,11181,11182,7,9,0,0,11182,11183,7,9,0,0,11183,11184,7,6,0,0,11184,11185,
	7,9,0,0,11185,11186,5,95,0,0,11186,11187,7,19,0,0,11187,11188,7,12,0,0,
	11188,11189,7,7,0,0,11189,11190,7,4,0,0,11190,11191,7,5,0,0,11191,1284,
	1,0,0,0,11192,11193,7,12,0,0,11193,11194,7,17,0,0,11194,11195,7,7,0,0,11195,
	11196,7,2,0,0,11196,11197,7,3,0,0,11197,11198,7,6,0,0,11198,11199,5,95,
	0,0,11199,11200,7,3,0,0,11200,11201,7,2,0,0,11201,11202,7,22,0,0,11202,
	11203,5,95,0,0,11203,11204,7,2,0,0,11204,11205,7,7,0,0,11205,11206,5,95,
	0,0,11206,11207,7,9,0,0,11207,11208,7,10,0,0,11208,11209,7,15,0,0,11209,
	11210,7,21,0,0,11210,11211,7,6,0,0,11211,11212,7,18,0,0,11212,11213,5,95,
	0,0,11213,11214,7,12,0,0,11214,11215,7,7,0,0,11215,11216,7,9,0,0,11216,
	11217,7,6,0,0,11217,11218,7,25,0,0,11218,1286,1,0,0,0,11219,11220,7,12,
	0,0,11220,11221,7,17,0,0,11221,11222,7,7,0,0,11222,11223,7,2,0,0,11223,
	11224,7,3,0,0,11224,11225,7,6,0,0,11225,11226,5,95,0,0,11226,11227,7,22,
	0,0,11227,11228,7,19,0,0,11228,11229,7,6,0,0,11229,11230,7,3,0,0,11230,
	11231,7,6,0,0,11231,11232,5,95,0,0,11232,11233,7,8,0,0,11233,11234,7,11,
	0,0,11234,11235,7,0,0,0,11235,11236,7,10,0,0,11236,11237,7,5,0,0,11237,
	11238,7,6,0,0,11238,1288,1,0,0,0,11239,11240,7,12,0,0,11240,11241,7,11,
	0,0,11241,11242,7,14,0,0,11242,1290,1,0,0,0,11243,11244,7,12,0,0,11244,
	11245,7,14,0,0,11245,11246,7,14,0,0,11246,11247,7,6,0,0,11247,11248,7,9,
	0,0,11248,11249,7,12,0,0,11249,11250,7,0,0,0,11250,11251,7,4,0,0,11251,
	11252,7,6,0,0,11252,1292,1,0,0,0,11253,11254,7,12,0,0,11254,11255,7,14,
	0,0,11255,11256,7,15,0,0,11256,11257,7,0,0,0,11257,11258,7,8,0,0,11258,
	11259,7,4,0,0,11259,1294,1,0,0,0,11260,11261,7,12,0,0,11261,11262,7,14,
	0,0,11262,11263,7,15,0,0,11263,11264,7,2,0,0,11264,11265,7,3,0,0,11265,
	11266,7,4,0,0,11266,1296,1,0,0,0,11267,11268,7,12,0,0,11268,11269,7,7,0,
	0,11269,11270,7,0,0,0,11270,11271,7,8,0,0,11271,11272,7,4,0,0,11272,11273,
	7,12,0,0,11273,11274,7,13,0,0,11274,11275,7,6,0,0,11275,1298,1,0,0,0,11276,
	11277,7,12,0,0,11277,11278,7,7,0,0,11278,11279,7,0,0,0,11279,11280,7,8,
	0,0,11280,11281,7,4,0,0,11281,11282,7,12,0,0,11282,11283,7,13,0,0,11283,
	11284,7,6,0,0,11284,11285,5,95,0,0,11285,11286,7,0,0,0,11286,11287,7,8,
	0,0,11287,11288,7,8,0,0,11288,11289,7,2,0,0,11289,11290,7,10,0,0,11290,
	11291,7,7,0,0,11291,11292,7,4,0,0,11292,11293,5,95,0,0,11293,11294,7,4,
	0,0,11294,11295,7,12,0,0,11295,11296,7,14,0,0,11296,11297,7,6,0,0,11297,
	1300,1,0,0,0,11298,11299,7,12,0,0,11299,11300,7,7,0,0,11300,11301,7,8,0,
	0,11301,11302,7,11,0,0,11302,11303,7,10,0,0,11303,11304,7,9,0,0,11304,11305,
	7,6,0,0,11305,1302,1,0,0,0,11306,11307,7,12,0,0,11307,11308,7,7,0,0,11308,
	11309,7,8,0,0,11309,11310,7,11,0,0,11310,11311,7,10,0,0,11311,11312,7,9,
	0,0,11312,11313,7,6,0,0,11313,11314,5,95,0,0,11314,11315,7,13,0,0,11315,
	11316,7,6,0,0,11316,11317,7,3,0,0,11317,11318,7,5,0,0,11318,11319,7,12,
	0,0,11319,11320,7,2,0,0,11320,11321,7,7,0,0,11321,1304,1,0,0,0,11322,11323,
	7,12,0,0,11323,11324,7,7,0,0,11324,11325,7,8,0,0,11325,11326,7,11,0,0,11326,
	11327,7,10,0,0,11327,11328,7,9,0,0,11328,11329,7,12,0,0,11329,11330,7,7,
	0,0,11330,11331,7,17,0,0,11331,1306,1,0,0,0,11332,11333,7,12,0,0,11333,
	11334,7,7,0,0,11334,11335,7,8,0,0,11335,11336,7,3,0,0,11336,11337,7,6,0,
	0,11337,11338,7,14,0,0,11338,11339,7,6,0,0,11339,11340,7,7,0,0,11340,11341,
	7,4,0,0,11341,11342,7,0,0,0,11342,11343,7,11,0,0,11343,1308,1,0,0,0,11344,
	11345,7,12,0,0,11345,11346,7,7,0,0,11346,11347,7,8,0,0,11347,11348,7,3,
	0,0,11348,11349,7,6,0,0,11349,11350,7,14,0,0,11350,11351,7,6,0,0,11351,
	11352,7,7,0,0,11352,11353,7,4,0,0,11353,1310,1,0,0,0,11354,11355,7,12,0,
	0,11355,11356,7,7,0,0,11356,11357,7,8,0,0,11357,11358,7,3,0,0,11358,1312,
	1,0,0,0,11359,11360,7,12,0,0,11360,11361,7,7,0,0,11361,11362,7,9,0,0,11362,
	11363,7,6,0,0,11363,11364,7,7,0,0,11364,11365,7,4,0,0,11365,1314,1,0,0,
	0,11366,11367,7,12,0,0,11367,11368,7,7,0,0,11368,11369,7,9,0,0,11369,11370,
	7,6,0,0,11370,11371,7,25,0,0,11371,11372,5,95,0,0,11372,11373,7,0,0,0,11373,
	11374,7,5,0,0,11374,11375,7,8,0,0,11375,1316,1,0,0,0,11376,11377,7,12,0,
	0,11377,11378,7,7,0,0,11378,11379,7,9,0,0,11379,11380,7,6,0,0,11380,11381,
	7,25,0,0,11381,11382,5,95,0,0,11382,11383,7,8,0,0,11383,11384,7,2,0,0,11384,
	11385,7,14,0,0,11385,11386,7,1,0,0,11386,11387,7,12,0,0,11387,11388,7,7,
	0,0,11388,11389,7,6,0,0,11389,1318,1,0,0,0,11390,11391,7,12,0,0,11391,11392,
	7,7,0,0,11392,11393,7,9,0,0,11393,11394,7,6,0,0,11394,11395,7,25,0,0,11395,
	11396,5,95,0,0,11396,11397,7,9,0,0,11397,11398,7,6,0,0,11398,11399,7,5,
	0,0,11399,11400,7,8,0,0,11400,1320,1,0,0,0,11401,11402,7,12,0,0,11402,11403,
	7,7,0,0,11403,11404,7,9,0,0,11404,11405,7,6,0,0,11405,11406,7,25,0,0,11406,
	11407,7,6,0,0,11407,11408,7,9,0,0,11408,1322,1,0,0,0,11409,11410,7,12,0,
	0,11410,11411,7,7,0,0,11411,11412,7,9,0,0,11412,11413,7,6,0,0,11413,11414,
	7,25,0,0,11414,11415,7,6,0,0,11415,11416,7,5,0,0,11416,1324,1,0,0,0,11417,
	11418,7,12,0,0,11418,11419,7,7,0,0,11419,11420,7,9,0,0,11420,11421,7,6,
	0,0,11421,11422,7,25,0,0,11422,11423,5,95,0,0,11423,11424,7,16,0,0,11424,
	11425,7,16,0,0,11425,11426,7,5,0,0,11426,1326,1,0,0,0,11427,11428,7,12,
	0,0,11428,11429,7,7,0,0,11429,11430,7,9,0,0,11430,11431,7,6,0,0,11431,11432,
	7,25,0,0,11432,11433,5,95,0,0,11433,11434,7,16,0,0,11434,11435,7,12,0,0,
	11435,11436,7,11,0,0,11436,11437,7,4,0,0,11437,11438,7,6,0,0,11438,11439,
	7,3,0,0,11439,1328,1,0,0,0,11440,11441,7,12,0,0,11441,11442,7,7,0,0,11442,
	11443,7,9,0,0,11443,11444,7,6,0,0,11444,11445,7,25,0,0,11445,1330,1,0,0,
	0,11446,11447,7,12,0,0,11447,11448,7,7,0,0,11448,11449,7,9,0,0,11449,11450,
	7,6,0,0,11450,11451,7,25,0,0,11451,11452,7,12,0,0,11452,11453,7,7,0,0,11453,
	11454,7,17,0,0,11454,1332,1,0,0,0,11455,11456,7,12,0,0,11456,11457,7,7,
	0,0,11457,11458,7,9,0,0,11458,11459,7,6,0,0,11459,11460,7,25,0,0,11460,
	11461,5,95,0,0,11461,11462,7,20,0,0,11462,11463,7,2,0,0,11463,11464,7,12,
	0,0,11464,11465,7,7,0,0,11465,1334,1,0,0,0,11466,11467,7,12,0,0,11467,11468,
	7,7,0,0,11468,11469,7,9,0,0,11469,11470,7,6,0,0,11470,11471,7,25,0,0,11471,
	11472,5,95,0,0,11472,11473,7,3,0,0,11473,11474,7,2,0,0,11474,11475,7,22,
	0,0,11475,11476,7,5,0,0,11476,1336,1,0,0,0,11477,11478,7,12,0,0,11478,11479,
	7,7,0,0,11479,11480,7,9,0,0,11480,11481,7,6,0,0,11481,11482,7,25,0,0,11482,
	11483,5,95,0,0,11483,11484,7,3,0,0,11484,11485,7,3,0,0,11485,11486,7,5,
	0,0,11486,1338,1,0,0,0,11487,11488,7,12,0,0,11488,11489,7,7,0,0,11489,11490,
	7,9,0,0,11490,11491,7,6,0,0,11491,11492,7,25,0,0,11492,11493,5,95,0,0,11493,
	11494,7,3,0,0,11494,11495,7,5,0,0,11495,11496,5,95,0,0,11496,11497,7,0,
	0,0,11497,11498,7,5,0,0,11498,11499,7,8,0,0,11499,1340,1,0,0,0,11500,11501,
	7,12,0,0,11501,11502,7,7,0,0,11502,11503,7,9,0,0,11503,11504,7,6,0,0,11504,
	11505,7,25,0,0,11505,11506,5,95,0,0,11506,11507,7,3,0,0,11507,11508,7,5,
	0,0,11508,11509,5,95,0,0,11509,11510,7,9,0,0,11510,11511,7,6,0,0,11511,
	11512,7,5,0,0,11512,11513,7,8,0,0,11513,1342,1,0,0,0,11514,11515,7,12,0,
	0,11515,11516,7,7,0,0,11516,11517,7,9,0,0,11517,11518,7,6,0,0,11518,11519,
	7,25,0,0,11519,11520,5,95,0,0,11520,11521,7,3,0,0,11521,11522,7,5,0,0,11522,
	1344,1,0,0,0,11523,11524,7,12,0,0,11524,11525,7,7,0,0,11525,11526,7,9,0,
	0,11526,11527,7,6,0,0,11527,11528,7,25,0,0,11528,11529,5,95,0,0,11529,11530,
	7,5,0,0,11530,11531,7,8,0,0,11531,11532,7,0,0,0,11532,11533,7,7,0,0,11533,
	1346,1,0,0,0,11534,11535,7,12,0,0,11535,11536,7,7,0,0,11536,11537,7,9,0,
	0,11537,11538,7,6,0,0,11538,11539,7,25,0,0,11539,11540,5,95,0,0,11540,11541,
	7,5,0,0,11541,11542,7,21,0,0,11542,11543,7,12,0,0,11543,11544,7,15,0,0,
	11544,11545,5,95,0,0,11545,11546,7,5,0,0,11546,11547,7,8,0,0,11547,11548,
	7,0,0,0,11548,11549,7,7,0,0,11549,1348,1,0,0,0,11550,11551,7,12,0,0,11551,
	11552,7,7,0,0,11552,11553,7,9,0,0,11553,11554,7,6,0,0,11554,11555,7,25,
	0,0,11555,11556,5,95,0,0,11556,11557,7,5,0,0,11557,11558,7,5,0,0,11558,
	11559,5,95,0,0,11559,11560,7,0,0,0,11560,11561,7,5,0,0,11561,11562,7,8,
	0,0,11562,1350,1,0,0,0,11563,11564,7,12,0,0,11564,11565,7,7,0,0,11565,11566,
	7,9,0,0,11566,11567,7,6,0,0,11567,11568,7,25,0,0,11568,11569,5,95,0,0,11569,
	11570,7,5,0,0,11570,11571,7,5,0,0,11571,11572,5,95,0,0,11572,11573,7,9,
	0,0,11573,11574,7,6,0,0,11574,11575,7,5,0,0,11575,11576,7,8,0,0,11576,1352,
	1,0,0,0,11577,11578,7,12,0,0,11578,11579,7,7,0,0,11579,11580,7,9,0,0,11580,
	11581,7,6,0,0,11581,11582,7,25,0,0,11582,11583,5,95,0,0,11583,11584,7,5,
	0,0,11584,11585,7,5,0,0,11585,1354,1,0,0,0,11586,11587,7,12,0,0,11587,11588,
	7,7,0,0,11588,11589,7,9,0,0,11589,11590,7,6,0,0,11590,11591,7,25,0,0,11591,
	11592,5,95,0,0,11592,11593,7,5,0,0,11593,11594,7,4,0,0,11594,11595,7,0,
	0,0,11595,11596,7,4,0,0,11596,11597,7,5,0,0,11597,1356,1,0,0,0,11598,11599,
	7,12,0,0,11599,11600,7,7,0,0,11600,11601,7,9,0,0,11601,11602,7,6,0,0,11602,
	11603,7,25,0,0,11603,11604,7,4,0,0,11604,11605,7,18,0,0,11605,11606,7,15,
	0,0,11606,11607,7,6,0,0,11607,1358,1,0,0,0,11608,11609,7,12,0,0,11609,11610,
	7,7,0,0,11610,11611,7,9,0,0,11611,11612,7,6,0,0,11612,11613,7,25,0,0,11613,
	11614,7,4,0,0,11614,11615,7,18,0,0,11615,11616,7,15,0,0,11616,11617,7,6,
	0,0,11617,11618,7,5,0,0,11618,1360,1,0,0,0,11619,11620,7,12,0,0,11620,11621,
	7,7,0,0,11621,11622,7,9,0,0,11622,11623,7,12,0,0,11623,11624,7,8,0,0,11624,
	11625,7,0,0,0,11625,11626,7,4,0,0,11626,11627,7,2,0,0,11627,11628,7,3,0,
	0,11628,1362,1,0,0,0,11629,11630,7,12,0,0,11630,11631,7,7,0,0,11631,11632,
	7,9,0,0,11632,11633,7,12,0,0,11633,11634,7,8,0,0,11634,11635,7,6,0,0,11635,
	11636,7,5,0,0,11636,1364,1,0,0,0,11637,11638,7,12,0,0,11638,11639,7,7,0,
	0,11639,11640,7,16,0,0,11640,11641,7,12,0,0,11641,11642,7,7,0,0,11642,11643,
	7,12,0,0,11643,11644,7,4,0,0,11644,11645,7,6,0,0,11645,1366,1,0,0,0,11646,
	11647,7,12,0,0,11647,11648,7,7,0,0,11648,11649,7,16,0,0,11649,11650,7,2,
	0,0,11650,11651,7,3,0,0,11651,11652,7,14,0,0,11652,11653,7,0,0,0,11653,
	11654,7,4,0,0,11654,11655,7,12,0,0,11655,11656,7,2,0,0,11656,11657,7,7,
	0,0,11657,11658,7,0,0,0,11658,11659,7,11,0,0,11659,1368,1,0,0,0,11660,11661,
	7,12,0,0,11661,11662,7,7,0,0,11662,11663,7,19,0,0,11663,11664,7,6,0,0,11664,
	11665,7,3,0,0,11665,11666,7,12,0,0,11666,11667,7,4,0,0,11667,1370,1,0,0,
	0,11668,11669,7,12,0,0,11669,11670,7,7,0,0,11670,1372,1,0,0,0,11671,11672,
	7,12,0,0,11672,11673,7,7,0,0,11673,11674,7,12,0,0,11674,11675,7,4,0,0,11675,
	11676,7,8,0,0,11676,11677,7,0,0,0,11677,11678,7,15,0,0,11678,1374,1,0,0,
	0,11679,11680,7,12,0,0,11680,11681,7,7,0,0,11681,11682,7,12,0,0,11682,11683,
	7,4,0,0,11683,11684,7,12,0,0,11684,11685,7,0,0,0,11685,11686,7,11,0,0,11686,
	1376,1,0,0,0,11687,11688,7,12,0,0,11688,11689,7,7,0,0,11689,11690,7,12,
	0,0,11690,11691,7,4,0,0,11691,11692,7,12,0,0,11692,11693,7,0,0,0,11693,
	11694,7,11,0,0,11694,11695,7,12,0,0,11695,11696,7,23,0,0,11696,11697,7,
	6,0,0,11697,11698,7,9,0,0,11698,1378,1,0,0,0,11699,11700,7,12,0,0,11700,
	11701,7,7,0,0,11701,11702,7,12,0,0,11702,11703,7,4,0,0,11703,11704,7,12,
	0,0,11704,11705,7,0,0,0,11705,11706,7,11,0,0,11706,11707,7,11,0,0,11707,
	11708,7,18,0,0,11708,1380,1,0,0,0,11709,11710,7,12,0,0,11710,11711,7,7,
	0,0,11711,11712,7,12,0,0,11712,11713,7,4,0,0,11713,11714,7,3,0,0,11714,
	11715,7,0,0,0,11715,11716,7,7,0,0,11716,11717,7,5,0,0,11717,1382,1,0,0,
	0,11718,11719,7,12,0,0,11719,11720,7,7,0,0,11720,11721,7,11,0,0,11721,11722,
	7,12,0,0,11722,11723,7,7,0,0,11723,11724,7,6,0,0,11724,1384,1,0,0,0,11725,
	11726,7,12,0,0,11726,11727,7,7,0,0,11727,11728,7,11,0,0,11728,11729,7,12,
	0,0,11729,11730,7,7,0,0,11730,11731,7,6,0,0,11731,11732,5,95,0,0,11732,
	11733,7,25,0,0,11733,11734,7,14,0,0,11734,11735,7,11,0,0,11735,11736,7,
	4,0,0,11736,11737,7,18,0,0,11737,11738,7,15,0,0,11738,11739,7,6,0,0,11739,
	11740,5,95,0,0,11740,11741,7,7,0,0,11741,11742,7,4,0,0,11742,1386,1,0,0,
	0,11743,11744,7,12,0,0,11744,11745,7,7,0,0,11745,11746,7,14,0,0,11746,11747,
	7,6,0,0,11747,11748,7,14,0,0,11748,11749,7,2,0,0,11749,11750,7,3,0,0,11750,
	11751,7,18,0,0,11751,1388,1,0,0,0,11752,11753,7,12,0,0,11753,11754,7,7,
	0,0,11754,11755,5,95,0,0,11755,11756,7,14,0,0,11756,11757,7,6,0,0,11757,
	11758,7,14,0,0,11758,11759,7,2,0,0,11759,11760,7,3,0,0,11760,11761,7,18,
	0,0,11761,11762,5,95,0,0,11762,11763,7,14,0,0,11763,11764,7,6,0,0,11764,
	11765,7,4,0,0,11765,11766,7,0,0,0,11766,11767,7,9,0,0,11767,11768,7,0,0,
	0,11768,11769,7,4,0,0,11769,11770,7,0,0,0,11770,1390,1,0,0,0,11771,11772,
	7,12,0,0,11772,11773,7,7,0,0,11773,11774,7,14,0,0,11774,11775,7,6,0,0,11775,
	11776,7,14,0,0,11776,11777,7,2,0,0,11777,11778,7,3,0,0,11778,11779,7,18,
	0,0,11779,11780,5,95,0,0,11780,11781,7,15,0,0,11781,11782,7,3,0,0,11782,
	11783,7,10,0,0,11783,11784,7,7,0,0,11784,11785,7,12,0,0,11785,11786,7,7,
	0,0,11786,11787,7,17,0,0,11787,1392,1,0,0,0,11788,11789,7,12,0,0,11789,
	11790,7,7,0,0,11790,11791,7,7,0,0,11791,11792,7,6,0,0,11792,11793,7,3,0,
	0,11793,1394,1,0,0,0,11794,11795,7,12,0,0,11795,11796,7,7,0,0,11796,11797,
	7,2,0,0,11797,11798,7,10,0,0,11798,11799,7,4,0,0,11799,1396,1,0,0,0,11800,
	11801,7,12,0,0,11801,11802,7,7,0,0,11802,11803,7,15,0,0,11803,11804,7,11,
	0,0,11804,11805,7,0,0,0,11805,11806,7,8,0,0,11806,11807,7,6,0,0,11807,1398,
	1,0,0,0,11808,11809,7,12,0,0,11809,11810,7,7,0,0,11810,11811,7,5,0,0,11811,
	11812,7,6,0,0,11812,11813,7,3,0,0,11813,11814,7,4,0,0,11814,11815,7,8,0,
	0,11815,11816,7,19,0,0,11816,11817,7,12,0,0,11817,11818,7,11,0,0,11818,
	11819,7,9,0,0,11819,11820,7,25,0,0,11820,11821,7,14,0,0,11821,11822,7,11,
	0,0,11822,11823,7,0,0,0,11823,11824,7,16,0,0,11824,11825,7,4,0,0,11825,
	11826,7,6,0,0,11826,11827,7,3,0,0,11827,1400,1,0,0,0,11828,11829,7,12,0,
	0,11829,11830,7,7,0,0,11830,11831,7,5,0,0,11831,11832,7,6,0,0,11832,11833,
	7,3,0,0,11833,11834,7,4,0,0,11834,11835,7,8,0,0,11835,11836,7,19,0,0,11836,
	11837,7,12,0,0,11837,11838,7,11,0,0,11838,11839,7,9,0,0,11839,11840,7,25,
	0,0,11840,11841,7,14,0,0,11841,11842,7,11,0,0,11842,11843,7,1,0,0,11843,
	11844,7,6,0,0,11844,11845,7,16,0,0,11845,11846,7,2,0,0,11846,11847,7,3,
	0,0,11847,11848,7,6,0,0,11848,1402,1,0,0,0,11849,11850,7,12,0,0,11850,11851,
	7,7,0,0,11851,11852,7,5,0,0,11852,11853,7,6,0,0,11853,11854,7,3,0,0,11854,
	11855,7,4,0,0,11855,11856,7,8,0,0,11856,11857,7,19,0,0,11857,11858,7,12,
	0,0,11858,11859,7,11,0,0,11859,11860,7,9,0,0,11860,11861,7,25,0,0,11861,
	11862,7,14,0,0,11862,11863,7,11,0,0,11863,1404,1,0,0,0,11864,11865,7,12,
	0,0,11865,11866,7,7,0,0,11866,11867,7,5,0,0,11867,11868,7,6,0,0,11868,11869,
	7,3,0,0,11869,11870,7,4,0,0,11870,1406,1,0,0,0,11871,11872,7,12,0,0,11872,
	11873,7,7,0,0,11873,11874,7,5,0,0,11874,11875,7,6,0,0,11875,11876,7,3,0,
	0,11876,11877,7,4,0,0,11877,11878,7,25,0,0,11878,11879,7,14,0,0,11879,11880,
	7,11,0,0,11880,11881,7,0,0,0,11881,11882,7,16,0,0,11882,11883,7,4,0,0,11883,
	11884,7,6,0,0,11884,11885,7,3,0,0,11885,1408,1,0,0,0,11886,11887,7,12,0,
	0,11887,11888,7,7,0,0,11888,11889,7,5,0,0,11889,11890,7,6,0,0,11890,11891,
	7,3,0,0,11891,11892,7,4,0,0,11892,11893,7,25,0,0,11893,11894,7,14,0,0,11894,
	11895,7,11,0,0,11895,11896,7,1,0,0,11896,11897,7,6,0,0,11897,11898,7,16,
	0,0,11898,11899,7,2,0,0,11899,11900,7,3,0,0,11900,11901,7,6,0,0,11901,1410,
	1,0,0,0,11902,11903,7,12,0,0,11903,11904,7,7,0,0,11904,11905,7,5,0,0,11905,
	11906,7,4,0,0,11906,11907,7,0,0,0,11907,11908,7,7,0,0,11908,11909,7,8,0,
	0,11909,11910,7,6,0,0,11910,1412,1,0,0,0,11911,11912,7,12,0,0,11912,11913,
	7,7,0,0,11913,11914,7,5,0,0,11914,11915,7,4,0,0,11915,11916,7,0,0,0,11916,
	11917,7,7,0,0,11917,11918,7,8,0,0,11918,11919,7,6,0,0,11919,11920,7,5,0,
	0,11920,1414,1,0,0,0,11921,11922,7,12,0,0,11922,11923,7,7,0,0,11923,11924,
	7,5,0,0,11924,11925,7,4,0,0,11925,11926,7,0,0,0,11926,11927,7,7,0,0,11927,
	11928,7,4,0,0,11928,11929,7,12,0,0,11929,11930,7,0,0,0,11930,11931,7,1,
	0,0,11931,11932,7,11,0,0,11932,11933,7,6,0,0,11933,1416,1,0,0,0,11934,11935,
	7,12,0,0,11935,11936,7,7,0,0,11936,11937,7,5,0,0,11937,11938,7,4,0,0,11938,
	11939,7,0,0,0,11939,11940,7,7,0,0,11940,11941,7,4,0,0,11941,11942,7,11,
	0,0,11942,11943,7,18,0,0,11943,1418,1,0,0,0,11944,11945,7,12,0,0,11945,
	11946,7,7,0,0,11946,11947,7,5,0,0,11947,11948,7,4,0,0,11948,11949,7,6,0,
	0,11949,11950,7,0,0,0,11950,11951,7,9,0,0,11951,1420,1,0,0,0,11952,11953,
	7,12,0,0,11953,11954,7,7,0,0,11954,11955,7,5,0,0,11955,11956,7,4,0,0,11956,
	11957,7,3,0,0,11957,11958,5,50,0,0,11958,1422,1,0,0,0,11959,11960,7,12,
	0,0,11960,11961,7,7,0,0,11961,11962,7,5,0,0,11962,11963,7,4,0,0,11963,11964,
	7,3,0,0,11964,11965,5,52,0,0,11965,1424,1,0,0,0,11966,11967,7,12,0,0,11967,
	11968,7,7,0,0,11968,11969,7,5,0,0,11969,11970,7,4,0,0,11970,11971,7,3,0,
	0,11971,11972,7,1,0,0,11972,1426,1,0,0,0,11973,11974,7,12,0,0,11974,11975,
	7,7,0,0,11975,11976,7,5,0,0,11976,11977,7,4,0,0,11977,11978,7,3,0,0,11978,
	11979,7,8,0,0,11979,1428,1,0,0,0,11980,11981,7,12,0,0,11981,11982,7,7,0,
	0,11982,11983,7,5,0,0,11983,11984,7,4,0,0,11984,11985,7,3,0,0,11985,1430,
	1,0,0,0,11986,11987,7,12,0,0,11987,11988,7,7,0,0,11988,11989,7,4,0,0,11989,
	11990,7,6,0,0,11990,11991,7,17,0,0,11991,11992,7,6,0,0,11992,11993,7,3,
	0,0,11993,1432,1,0,0,0,11994,11995,7,12,0,0,11995,11996,7,7,0,0,11996,11997,
	7,4,0,0,11997,11998,7,6,0,0,11998,11999,7,3,0,0,11999,12000,7,11,0,0,12000,
	12001,7,6,0,0,12001,12002,7,0,0,0,12002,12003,7,13,0,0,12003,12004,7,6,
	0,0,12004,12005,7,9,0,0,12005,1434,1,0,0,0,12006,12007,7,12,0,0,12007,12008,
	7,7,0,0,12008,12009,7,4,0,0,12009,12010,7,6,0,0,12010,12011,7,3,0,0,12011,
	12012,7,14,0,0,12012,12013,7,6,0,0,12013,12014,7,9,0,0,12014,12015,7,12,
	0,0,12015,12016,7,0,0,0,12016,12017,7,4,0,0,12017,12018,7,6,0,0,12018,1436,
	1,0,0,0,12019,12020,7,12,0,0,12020,12021,7,7,0,0,12021,12022,7,4,0,0,12022,
	12023,7,6,0,0,12023,12024,7,3,0,0,12024,12025,7,7,0,0,12025,12026,7,0,0,
	0,12026,12027,7,11,0,0,12027,12028,5,95,0,0,12028,12029,7,8,0,0,12029,12030,
	7,2,0,0,12030,12031,7,7,0,0,12031,12032,7,13,0,0,12032,12033,7,6,0,0,12033,
	12034,7,3,0,0,12034,12035,7,4,0,0,12035,1438,1,0,0,0,12036,12037,7,12,0,
	0,12037,12038,7,7,0,0,12038,12039,7,4,0,0,12039,12040,7,6,0,0,12040,12041,
	7,3,0,0,12041,12042,7,7,0,0,12042,12043,7,0,0,0,12043,12044,7,11,0,0,12044,
	12045,5,95,0,0,12045,12046,7,10,0,0,12046,12047,7,5,0,0,12047,12048,7,6,
	0,0,12048,1440,1,0,0,0,12049,12050,7,12,0,0,12050,12051,7,7,0,0,12051,12052,
	7,4,0,0,12052,12053,7,6,0,0,12053,12054,7,3,0,0,12054,12055,7,15,0,0,12055,
	12056,7,3,0,0,12056,12057,7,6,0,0,12057,12058,7,4,0,0,12058,12059,7,6,0,
	0,12059,12060,7,9,0,0,12060,1442,1,0,0,0,12061,12062,7,12,0,0,12062,12063,
	7,7,0,0,12063,12064,7,4,0,0,12064,12065,7,6,0,0,12065,12066,7,3,0,0,12066,
	12067,7,5,0,0,12067,12068,7,6,0,0,12068,12069,7,8,0,0,12069,12070,7,4,0,
	0,12070,1444,1,0,0,0,12071,12072,7,12,0,0,12072,12073,7,7,0,0,12073,12074,
	7,4,0,0,12074,12075,7,6,0,0,12075,12076,7,3,0,0,12076,12077,7,13,0,0,12077,
	12078,7,0,0,0,12078,12079,7,11,0,0,12079,1446,1,0,0,0,12080,12081,7,12,
	0,0,12081,12082,7,7,0,0,12082,12083,7,4,0,0,12083,1448,1,0,0,0,12084,12085,
	7,12,0,0,12085,12086,7,7,0,0,12086,12087,7,4,0,0,12087,12088,7,2,0,0,12088,
	1450,1,0,0,0,12089,12090,7,12,0,0,12090,12091,7,7,0,0,12091,12092,7,13,
	0,0,12092,12093,7,0,0,0,12093,12094,7,11,0,0,12094,12095,7,12,0,0,12095,
	12096,7,9,0,0,12096,12097,7,0,0,0,12097,12098,7,4,0,0,12098,12099,7,6,0,
	0,12099,1452,1,0,0,0,12100,12101,7,12,0,0,12101,12102,7,7,0,0,12102,12103,
	7,13,0,0,12103,12104,7,12,0,0,12104,12105,7,5,0,0,12105,12106,7,12,0,0,
	12106,12107,7,1,0,0,12107,12108,7,11,0,0,12108,12109,7,6,0,0,12109,1454,
	1,0,0,0,12110,12111,7,12,0,0,12111,12112,7,7,0,0,12112,12113,5,95,0,0,12113,
	12114,7,25,0,0,12114,12115,7,24,0,0,12115,12116,7,10,0,0,12116,12117,7,
	6,0,0,12117,12118,7,3,0,0,12118,12119,7,18,0,0,12119,1456,1,0,0,0,12120,
	12121,7,12,0,0,12121,12122,7,5,0,0,12122,1458,1,0,0,0,12123,12124,7,12,
	0,0,12124,12125,7,5,0,0,12125,12126,5,95,0,0,12126,12127,7,11,0,0,12127,
	12128,7,6,0,0,12128,12129,7,0,0,0,12129,12130,7,16,0,0,12130,1460,1,0,0,
	0,12131,12132,7,12,0,0,12132,12133,7,5,0,0,12133,12134,7,2,0,0,12134,12135,
	7,11,0,0,12135,12136,7,0,0,0,12136,12137,7,4,0,0,12137,12138,7,12,0,0,12138,
	12139,7,2,0,0,12139,12140,7,7,0,0,12140,1462,1,0,0,0,12141,12142,7,12,0,
	0,12142,12143,7,5,0,0,12143,12144,7,2,0,0,12144,12145,7,11,0,0,12145,12146,
	7,0,0,0,12146,12147,7,4,0,0,12147,12148,7,12,0,0,12148,12149,7,2,0,0,12149,
	12150,7,7,0,0,12150,12151,5,95,0,0,12151,12152,7,11,0,0,12152,12153,7,6,
	0,0,12153,12154,7,13,0,0,12154,12155,7,6,0,0,12155,12156,7,11,0,0,12156,
	1464,1,0,0,0,12157,12158,7,12,0,0,12158,12159,7,4,0,0,12159,12160,7,6,0,
	0,12160,12161,7,3,0,0,12161,12162,7,0,0,0,12162,12163,7,4,0,0,12163,12164,
	7,6,0,0,12164,1466,1,0,0,0,12165,12166,7,12,0,0,12166,12167,7,4,0,0,12167,
	12168,7,6,0,0,12168,12169,7,3,0,0,12169,12170,7,0,0,0,12170,12171,7,4,0,
	0,12171,12172,7,12,0,0,12172,12173,7,2,0,0,12173,12174,7,7,0,0,12174,12175,
	5,95,0,0,12175,12176,7,7,0,0,12176,12177,7,10,0,0,12177,12178,7,14,0,0,
	12178,12179,7,1,0,0,12179,12180,7,6,0,0,12180,12181,7,3,0,0,12181,1468,
	1,0,0,0,12182,12183,7,20,0,0,12183,12184,7,0,0,0,12184,12185,7,13,0,0,12185,
	12186,7,0,0,0,12186,1470,1,0,0,0,12187,12188,7,20,0,0,12188,12189,7,2,0,
	0,12189,12190,7,1,0,0,12190,1472,1,0,0,0,12191,12192,7,20,0,0,12192,12193,
	7,2,0,0,12193,12194,7,12,0,0,12194,12195,7,7,0,0,12195,1474,1,0,0,0,12196,
	12197,7,20,0,0,12197,12198,7,5,0,0,12198,12199,7,2,0,0,12199,12200,7,7,
	0,0,12200,12201,5,95,0,0,12201,12202,7,0,0,0,12202,12203,7,3,0,0,12203,
	12204,7,3,0,0,12204,12205,7,0,0,0,12205,12206,7,18,0,0,12206,12207,7,0,
	0,0,12207,12208,7,17,0,0,12208,12209,7,17,0,0,12209,1476,1,0,0,0,12210,
	12211,7,20,0,0,12211,12212,7,5,0,0,12212,12213,7,2,0,0,12213,12214,7,7,
	0,0,12214,12215,5,95,0,0,12215,12216,7,0,0,0,12216,12217,7,3,0,0,12217,
	12218,7,3,0,0,12218,12219,7,0,0,0,12219,12220,7,18,0,0,12220,1478,1,0,0,
	0,12221,12222,7,20,0,0,12222,12223,7,5,0,0,12223,12224,7,2,0,0,12224,12225,
	7,7,0,0,12225,12226,5,95,0,0,12226,12227,7,6,0,0,12227,12228,7,24,0,0,12228,
	12229,7,10,0,0,12229,12230,7,0,0,0,12230,12231,7,11,0,0,12231,1480,1,0,
	0,0,12232,12233,7,20,0,0,12233,12234,7,5,0,0,12234,12235,7,2,0,0,12235,
	12236,7,7,0,0,12236,12237,5,95,0,0,12237,12238,7,6,0,0,12238,12239,7,25,
	0,0,12239,12240,7,12,0,0,12240,12241,7,5,0,0,12241,12242,7,4,0,0,12242,
	12243,7,5,0,0,12243,12244,5,50,0,0,12244,1482,1,0,0,0,12245,12246,7,20,
	0,0,12246,12247,7,5,0,0,12247,12248,7,2,0,0,12248,12249,7,7,0,0,12249,12250,
	5,95,0,0,12250,12251,7,6,0,0,12251,12252,7,25,0,0,12252,12253,7,12,0,0,
	12253,12254,7,5,0,0,12254,12255,7,4,0,0,12255,12256,7,5,0,0,12256,1484,
	1,0,0,0,12257,12258,7,20,0,0,12258,12259,7,5,0,0,12259,12260,7,2,0,0,12260,
	12261,7,7,0,0,12261,12262,7,17,0,0,12262,12263,7,6,0,0,12263,12264,7,4,
	0,0,12264,1486,1,0,0,0,12265,12266,7,20,0,0,12266,12267,7,5,0,0,12267,12268,
	7,2,0,0,12268,12269,7,7,0,0,12269,1488,1,0,0,0,12270,12271,7,20,0,0,12271,
	12272,7,5,0,0,12272,12273,7,2,0,0,12273,12274,7,7,0,0,12274,12275,5,95,
	0,0,12275,12276,7,2,0,0,12276,12277,7,1,0,0,12277,12278,7,20,0,0,12278,
	12279,7,6,0,0,12279,12280,7,8,0,0,12280,12281,7,4,0,0,12281,12282,7,0,0,
	0,12282,12283,7,17,0,0,12283,12284,7,17,0,0,12284,1490,1,0,0,0,12285,12286,
	7,20,0,0,12286,12287,7,5,0,0,12287,12288,7,2,0,0,12288,12289,7,7,0,0,12289,
	12290,5,95,0,0,12290,12291,7,2,0,0,12291,12292,7,1,0,0,12292,12293,7,20,
	0,0,12293,12294,7,6,0,0,12294,12295,7,8,0,0,12295,12296,7,4,0,0,12296,1492,
	1,0,0,0,12297,12298,7,20,0,0,12298,12299,7,5,0,0,12299,12300,7,2,0,0,12300,
	12301,7,7,0,0,12301,12302,7,15,0,0,12302,12303,7,0,0,0,12303,12304,7,3,
	0,0,12304,12305,7,5,0,0,12305,12306,7,6,0,0,12306,1494,1,0,0,0,12307,12308,
	7,20,0,0,12308,12309,7,5,0,0,12309,12310,7,2,0,0,12310,12311,7,7,0,0,12311,
	12312,5,95,0,0,12312,12313,7,24,0,0,12313,12314,7,10,0,0,12314,12315,7,
	6,0,0,12315,12316,7,3,0,0,12316,12317,7,18,0,0,12317,1496,1,0,0,0,12318,
	12319,7,20,0,0,12319,12320,7,5,0,0,12320,12321,7,2,0,0,12321,12322,7,7,
	0,0,12322,12323,5,95,0,0,12323,12324,7,5,0,0,12324,12325,7,6,0,0,12325,
	12326,7,3,0,0,12326,12327,7,12,0,0,12327,12328,7,0,0,0,12328,12329,7,11,
	0,0,12329,12330,7,12,0,0,12330,12331,7,23,0,0,12331,12332,7,6,0,0,12332,
	1498,1,0,0,0,12333,12334,7,20,0,0,12334,12335,7,5,0,0,12335,12336,7,2,0,
	0,12336,12337,7,7,0,0,12337,12338,5,95,0,0,12338,12339,7,4,0,0,12339,12340,
	7,0,0,0,12340,12341,7,1,0,0,12341,12342,7,11,0,0,12342,12343,7,6,0,0,12343,
	1500,1,0,0,0,12344,12345,7,20,0,0,12345,12346,7,5,0,0,12346,12347,7,2,0,
	0,12347,12348,7,7,0,0,12348,12349,5,95,0,0,12349,12350,7,4,0,0,12350,12351,
	7,6,0,0,12351,12352,7,25,0,0,12352,12353,7,4,0,0,12353,12354,7,8,0,0,12354,
	12355,7,2,0,0,12355,12356,7,7,0,0,12356,12357,7,4,0,0,12357,12358,7,0,0,
	0,12358,12359,7,12,0,0,12359,12360,7,7,0,0,12360,12361,7,5,0,0,12361,12362,
	5,50,0,0,12362,1502,1,0,0,0,12363,12364,7,20,0,0,12364,12365,7,5,0,0,12365,
	12366,7,2,0,0,12366,12367,7,7,0,0,12367,12368,5,95,0,0,12368,12369,7,4,
	0,0,12369,12370,7,6,0,0,12370,12371,7,25,0,0,12371,12372,7,4,0,0,12372,
	12373,7,8,0,0,12373,12374,7,2,0,0,12374,12375,7,7,0,0,12375,12376,7,4,0,
	0,12376,12377,7,0,0,0,12377,12378,7,12,0,0,12378,12379,7,7,0,0,12379,12380,
	7,5,0,0,12380,1504,1,0,0,0,12381,12382,7,20,0,0,12382,12383,7,5,0,0,12383,
	12384,7,2,0,0,12384,12385,7,7,0,0,12385,12386,5,95,0,0,12386,12387,7,4,
	0,0,12387,12388,7,3,0,0,12388,12389,7,0,0,0,12389,12390,7,7,0,0,12390,12391,
	7,5,0,0,12391,12392,7,16,0,0,12392,12393,7,2,0,0,12393,12394,7,3,0,0,12394,
	12395,7,14,0,0,12395,1506,1,0,0,0,12396,12397,7,20,0,0,12397,12398,7,5,
	0,0,12398,12399,7,2,0,0,12399,12400,7,7,0,0,12400,12401,5,95,0,0,12401,
	12402,7,13,0,0,12402,12403,7,0,0,0,12403,12404,7,11,0,0,12404,12405,7,10,
	0,0,12405,12406,7,6,0,0,12406,1508,1,0,0,0,12407,12408,7,21,0,0,12408,1510,
	1,0,0,0,12409,12410,7,21,0,0,12410,12411,7,6,0,0,12411,12412,7,6,0,0,12412,
	12413,7,15,0,0,12413,12414,5,95,0,0,12414,12415,7,9,0,0,12415,12416,7,10,
	0,0,12416,12417,7,15,0,0,12417,12418,7,11,0,0,12418,12419,7,12,0,0,12419,
	12420,7,8,0,0,12420,12421,7,0,0,0,12421,12422,7,4,0,0,12422,12423,7,6,0,
	0,12423,12424,7,5,0,0,12424,1512,1,0,0,0,12425,12426,7,21,0,0,12426,12427,
	7,6,0,0,12427,12428,7,6,0,0,12428,12429,7,15,0,0,12429,1514,1,0,0,0,12430,
	12431,7,21,0,0,12431,12432,7,6,0,0,12432,12433,7,3,0,0,12433,12434,7,1,
	0,0,12434,12435,7,6,0,0,12435,12436,7,3,0,0,12436,12437,7,2,0,0,12437,12438,
	7,5,0,0,12438,1516,1,0,0,0,12439,12440,7,21,0,0,12440,12441,7,6,0,0,12441,
	12442,7,18,0,0,12442,1518,1,0,0,0,12443,12444,7,21,0,0,12444,12445,7,6,
	0,0,12445,12446,7,18,0,0,12446,12447,5,95,0,0,12447,12448,7,11,0,0,12448,
	12449,7,6,0,0,12449,12450,7,7,0,0,12450,12451,7,17,0,0,12451,12452,7,4,
	0,0,12452,12453,7,19,0,0,12453,1520,1,0,0,0,12454,12455,7,21,0,0,12455,
	12456,7,6,0,0,12456,12457,7,18,0,0,12457,12458,7,5,0,0,12458,12459,7,12,
	0,0,12459,12460,7,23,0,0,12460,12461,7,6,0,0,12461,1522,1,0,0,0,12462,12463,
	7,21,0,0,12463,12464,7,6,0,0,12464,12465,7,18,0,0,12465,12466,7,5,0,0,12466,
	1524,1,0,0,0,12467,12468,7,21,0,0,12468,12469,7,6,0,0,12469,12470,7,18,
	0,0,12470,12471,7,5,0,0,12471,12472,7,4,0,0,12472,12473,7,2,0,0,12473,12474,
	7,3,0,0,12474,12475,7,6,0,0,12475,1526,1,0,0,0,12476,12477,7,21,0,0,12477,
	12478,7,12,0,0,12478,12479,7,11,0,0,12479,12480,7,11,0,0,12480,1528,1,0,
	0,0,12481,12482,7,11,0,0,12482,12483,7,0,0,0,12483,12484,7,1,0,0,12484,
	12485,7,6,0,0,12485,12486,7,11,0,0,12486,1530,1,0,0,0,12487,12488,7,11,
	0,0,12488,12489,7,0,0,0,12489,12490,7,7,0,0,12490,12491,7,17,0,0,12491,
	12492,7,10,0,0,12492,12493,7,0,0,0,12493,12494,7,17,0,0,12494,12495,7,6,
	0,0,12495,1532,1,0,0,0,12496,12497,7,11,0,0,12497,12498,7,0,0,0,12498,12499,
	7,5,0,0,12499,12500,7,4,0,0,12500,12501,5,95,0,0,12501,12502,7,9,0,0,12502,
	12503,7,0,0,0,12503,12504,7,18,0,0,12504,1534,1,0,0,0,12505,12506,7,11,
	0,0,12506,12507,7,0,0,0,12507,12508,7,5,0,0,12508,12509,7,4,0,0,12509,1536,
	1,0,0,0,12510,12511,7,11,0,0,12511,12512,7,0,0,0,12512,12513,7,5,0,0,12513,
	12514,7,4,0,0,12514,12515,5,95,0,0,12515,12516,7,13,0,0,12516,12517,7,0,
	0,0,12517,12518,7,11,0,0,12518,12519,7,10,0,0,12519,12520,7,6,0,0,12520,
	1538,1,0,0,0,12521,12522,7,11,0,0,12522,12523,7,0,0,0,12523,12524,7,4,0,
	0,12524,12525,7,6,0,0,12525,12526,7,3,0,0,12526,12527,7,0,0,0,12527,12528,
	7,11,0,0,12528,1540,1,0,0,0,12529,12530,7,11,0,0,12530,12531,7,0,0,0,12531,
	12532,7,25,0,0,12532,1542,1,0,0,0,12533,12534,7,11,0,0,12534,12535,7,0,
	0,0,12535,12536,7,18,0,0,12536,12537,7,6,0,0,12537,12538,7,3,0,0,12538,
	1544,1,0,0,0,12539,12540,7,11,0,0,12540,12541,7,9,0,0,12541,12542,7,0,0,
	0,12542,12543,7,15,0,0,12543,12544,5,95,0,0,12544,12545,7,3,0,0,12545,12546,
	7,6,0,0,12546,12547,7,17,0,0,12547,12548,7,12,0,0,12548,12549,7,5,0,0,12549,
	12550,7,4,0,0,12550,12551,7,3,0,0,12551,12552,7,0,0,0,12552,12553,7,4,0,
	0,12553,12554,7,12,0,0,12554,12555,7,2,0,0,12555,12556,7,7,0,0,12556,12557,
	5,95,0,0,12557,12558,7,6,0,0,12558,12559,7,7,0,0,12559,12560,7,0,0,0,12560,
	12561,7,1,0,0,12561,12562,7,11,0,0,12562,12563,7,6,0,0,12563,12564,7,9,
	0,0,12564,1546,1,0,0,0,12565,12566,7,11,0,0,12566,12567,7,9,0,0,12567,12568,
	7,0,0,0,12568,12569,7,15,0,0,12569,12570,5,95,0,0,12570,12571,7,3,0,0,12571,
	12572,7,6,0,0,12572,12573,7,17,0,0,12573,12574,7,12,0,0,12574,12575,7,5,
	0,0,12575,12576,7,4,0,0,12576,12577,7,3,0,0,12577,12578,7,0,0,0,12578,12579,
	7,4,0,0,12579,12580,7,12,0,0,12580,12581,7,2,0,0,12581,12582,7,7,0,0,12582,
	1548,1,0,0,0,12583,12584,7,11,0,0,12584,12585,7,9,0,0,12585,12586,7,0,0,
	0,12586,12587,7,15,0,0,12587,12588,5,95,0,0,12588,12589,7,3,0,0,12589,12590,
	7,6,0,0,12590,12591,7,17,0,0,12591,12592,5,95,0,0,12592,12593,7,5,0,0,12593,
	12594,7,18,0,0,12594,12595,7,7,0,0,12595,12596,7,8,0,0,12596,12597,5,95,
	0,0,12597,12598,7,12,0,0,12598,12599,7,7,0,0,12599,12600,7,4,0,0,12600,
	12601,7,6,0,0,12601,12602,7,3,0,0,12602,12603,7,13,0,0,12603,12604,7,0,
	0,0,12604,12605,7,11,0,0,12605,1550,1,0,0,0,12606,12607,7,11,0,0,12607,
	12608,7,6,0,0,12608,12609,7,0,0,0,12609,12610,7,16,0,0,12610,1552,1,0,0,
	0,12611,12612,7,11,0,0,12612,12613,7,6,0,0,12613,12614,7,0,0,0,12614,12615,
	7,9,0,0,12615,12616,5,95,0,0,12616,12617,7,8,0,0,12617,12618,7,9,0,0,12618,
	12619,7,1,0,0,12619,1554,1,0,0,0,12620,12621,7,11,0,0,12621,12622,7,6,0,
	0,12622,12623,7,0,0,0,12623,12624,7,9,0,0,12624,12625,5,95,0,0,12625,12626,
	7,8,0,0,12626,12627,7,9,0,0,12627,12628,7,1,0,0,12628,12629,5,95,0,0,12629,
	12630,7,10,0,0,12630,12631,7,3,0,0,12631,12632,7,12,0,0,12632,1556,1,0,
	0,0,12633,12634,7,11,0,0,12634,12635,7,6,0,0,12635,12636,7,0,0,0,12636,
	12637,7,9,0,0,12637,12638,7,12,0,0,12638,12639,7,7,0,0,12639,12640,7,17,
	0,0,12640,1558,1,0,0,0,12641,12642,7,11,0,0,12642,12643,7,6,0,0,12643,12644,
	7,16,0,0,12644,12645,7,4,0,0,12645,1560,1,0,0,0,12646,12647,7,11,0,0,12647,
	12648,7,6,0,0,12648,12649,7,7,0,0,12649,12650,7,17,0,0,12650,12651,7,4,
	0,0,12651,12652,7,19,0,0,12652,12653,5,50,0,0,12653,1562,1,0,0,0,12654,
	12655,7,11,0,0,12655,12656,7,6,0,0,12656,12657,7,7,0,0,12657,12658,7,17,
	0,0,12658,12659,7,4,0,0,12659,12660,7,19,0,0,12660,12661,5,52,0,0,12661,
	1564,1,0,0,0,12662,12663,7,11,0,0,12663,12664,7,6,0,0,12664,12665,7,7,0,
	0,12665,12666,7,17,0,0,12666,12667,7,4,0,0,12667,12668,7,19,0,0,12668,12669,
	7,1,0,0,12669,1566,1,0,0,0,12670,12671,7,11,0,0,12671,12672,7,6,0,0,12672,
	12673,7,7,0,0,12673,12674,7,17,0,0,12674,12675,7,4,0,0,12675,12676,7,19,
	0,0,12676,12677,7,8,0,0,12677,1568,1,0,0,0,12678,12679,7,11,0,0,12679,12680,
	7,6,0,0,12680,12681,7,7,0,0,12681,12682,7,17,0,0,12682,12683,7,4,0,0,12683,
	12684,7,19,0,0,12684,1570,1,0,0,0,12685,12686,7,11,0,0,12686,12687,7,6,
	0,0,12687,12688,7,5,0,0,12688,12689,7,5,0,0,12689,1572,1,0,0,0,12690,12691,
	7,11,0,0,12691,12692,7,6,0,0,12692,12693,7,13,0,0,12693,12694,7,6,0,0,12694,
	12695,7,11,0,0,12695,1574,1,0,0,0,12696,12697,7,11,0,0,12697,12698,7,6,
	0,0,12698,12699,7,13,0,0,12699,12700,7,6,0,0,12700,12701,7,11,0,0,12701,
	12702,5,95,0,0,12702,12703,7,7,0,0,12703,12704,7,0,0,0,12704,12705,7,14,
	0,0,12705,12706,7,6,0,0,12706,1576,1,0,0,0,12707,12708,7,11,0,0,12708,12709,
	7,6,0,0,12709,12710,7,13,0,0,12710,12711,7,6,0,0,12711,12712,7,11,0,0,12712,
	12713,7,5,0,0,12713,1578,1,0,0,0,12714,12715,7,11,0,0,12715,12716,7,12,
	0,0,12716,12717,7,1,0,0,12717,12718,7,3,0,0,12718,12719,7,0,0,0,12719,12720,
	7,3,0,0,12720,12721,7,18,0,0,12721,1580,1,0,0,0,12722,12723,7,11,0,0,12723,
	12724,7,12,0,0,12724,12725,7,16,0,0,12725,12726,7,6,0,0,12726,12727,7,8,
	0,0,12727,12728,7,18,0,0,12728,12729,7,8,0,0,12729,12730,7,11,0,0,12730,
	12731,7,6,0,0,12731,1582,1,0,0,0,12732,12733,7,11,0,0,12733,12734,7,12,
	0,0,12734,12735,7,16,0,0,12735,12736,7,6,0,0,12736,1584,1,0,0,0,12737,12738,
	7,11,0,0,12738,12739,7,12,0,0,12739,12740,7,16,0,0,12740,12741,7,6,0,0,
	12741,12742,7,4,0,0,12742,12743,7,12,0,0,12743,12744,7,14,0,0,12744,12745,
	7,6,0,0,12745,1586,1,0,0,0,12746,12747,7,11,0,0,12747,12748,7,12,0,0,12748,
	12749,7,21,0,0,12749,12750,7,6,0,0,12750,12751,5,50,0,0,12751,1588,1,0,
	0,0,12752,12753,7,11,0,0,12753,12754,7,12,0,0,12754,12755,7,21,0,0,12755,
	12756,7,6,0,0,12756,12757,5,52,0,0,12757,1590,1,0,0,0,12758,12759,7,11,
	0,0,12759,12760,7,12,0,0,12760,12761,7,21,0,0,12761,12762,7,6,0,0,12762,
	12763,7,8,0,0,12763,1592,1,0,0,0,12764,12765,7,11,0,0,12765,12766,7,12,
	0,0,12766,12767,7,21,0,0,12767,12768,7,6,0,0,12768,12769,5,95,0,0,12769,
	12770,7,6,0,0,12770,12771,7,25,0,0,12771,12772,7,15,0,0,12772,12773,7,0,
	0,0,12773,12774,7,7,0,0,12774,12775,7,9,0,0,12775,1594,1,0,0,0,12776,12777,
	7,11,0,0,12777,12778,7,12,0,0,12778,12779,7,21,0,0,12779,12780,7,6,0,0,
	12780,1596,1,0,0,0,12781,12782,7,11,0,0,12782,12783,7,12,0,0,12783,12784,
	7,14,0,0,12784,12785,7,12,0,0,12785,12786,7,4,0,0,12786,1598,1,0,0,0,12787,
	12788,7,11,0,0,12788,12789,7,12,0,0,12789,12790,7,7,0,0,12790,12791,7,6,
	0,0,12791,12792,7,0,0,0,12792,12793,7,3,0,0,12793,1600,1,0,0,0,12794,12795,
	7,11,0,0,12795,12796,7,12,0,0,12796,12797,7,7,0,0,12797,12798,7,21,0,0,
	12798,1602,1,0,0,0,12799,12800,7,11,0,0,12800,12801,7,12,0,0,12801,12802,
	7,5,0,0,12802,12803,7,4,0,0,12803,1604,1,0,0,0,12804,12805,7,11,0,0,12805,
	12806,7,7,0,0,12806,1606,1,0,0,0,12807,12808,7,11,0,0,12808,12809,7,7,0,
	0,12809,12810,7,7,0,0,12810,12811,7,13,0,0,12811,12812,7,11,0,0,12812,1608,
	1,0,0,0,12813,12814,7,11,0,0,12814,12815,7,2,0,0,12815,12816,7,0,0,0,12816,
	12817,7,9,0,0,12817,1610,1,0,0,0,12818,12819,7,11,0,0,12819,12820,7,2,0,
	0,12820,12821,7,1,0,0,12821,1612,1,0,0,0,12822,12823,7,11,0,0,12823,12824,
	7,2,0,0,12824,12825,7,1,0,0,12825,12826,7,7,0,0,12826,12827,7,13,0,0,12827,
	12828,7,11,0,0,12828,1614,1,0,0,0,12829,12830,7,11,0,0,12830,12831,7,2,
	0,0,12831,12832,7,1,0,0,12832,12833,7,5,0,0,12833,1616,1,0,0,0,12834,12835,
	7,11,0,0,12835,12836,7,2,0,0,12836,12837,7,8,0,0,12837,12838,7,0,0,0,12838,
	12839,7,11,0,0,12839,12840,5,95,0,0,12840,12841,7,12,0,0,12841,12842,7,
	7,0,0,12842,12843,7,9,0,0,12843,12844,7,6,0,0,12844,12845,7,25,0,0,12845,
	12846,7,6,0,0,12846,12847,7,5,0,0,12847,1618,1,0,0,0,12848,12849,7,11,0,
	0,12849,12850,7,2,0,0,12850,12851,7,8,0,0,12851,12852,7,0,0,0,12852,12853,
	7,11,0,0,12853,1620,1,0,0,0,12854,12855,7,11,0,0,12855,12856,7,2,0,0,12856,
	12857,7,8,0,0,12857,12858,7,0,0,0,12858,12859,7,11,0,0,12859,12860,7,4,
	0,0,12860,12861,7,12,0,0,12861,12862,7,14,0,0,12862,12863,7,6,0,0,12863,
	1622,1,0,0,0,12864,12865,7,11,0,0,12865,12866,7,2,0,0,12866,12867,7,8,0,
	0,12867,12868,7,0,0,0,12868,12869,7,11,0,0,12869,12870,7,4,0,0,12870,12871,
	7,12,0,0,12871,12872,7,14,0,0,12872,12873,7,6,0,0,12873,12874,7,5,0,0,12874,
	12875,7,4,0,0,12875,12876,7,0,0,0,12876,12877,7,14,0,0,12877,12878,7,15,
	0,0,12878,1624,1,0,0,0,12879,12880,7,11,0,0,12880,12881,7,2,0,0,12881,12882,
	7,8,0,0,12882,12883,7,0,0,0,12883,12884,7,4,0,0,12884,12885,7,12,0,0,12885,
	12886,7,2,0,0,12886,12887,7,7,0,0,12887,1626,1,0,0,0,12888,12889,7,11,0,
	0,12889,12890,7,2,0,0,12890,12891,7,8,0,0,12891,12892,7,0,0,0,12892,12893,
	7,4,0,0,12893,12894,7,2,0,0,12894,12895,7,3,0,0,12895,1628,1,0,0,0,12896,
	12897,7,11,0,0,12897,12898,7,2,0,0,12898,12899,7,8,0,0,12899,12900,7,21,
	0,0,12900,12901,7,9,0,0,12901,12902,7,2,0,0,12902,12903,7,22,0,0,12903,
	12904,7,7,0,0,12904,1630,1,0,0,0,12905,12906,7,11,0,0,12906,12907,7,2,0,
	0,12907,12908,7,8,0,0,12908,12909,7,21,0,0,12909,12910,7,6,0,0,12910,12911,
	7,9,0,0,12911,1632,1,0,0,0,12912,12913,7,11,0,0,12913,12914,7,2,0,0,12914,
	12915,7,8,0,0,12915,12916,7,21,0,0,12916,12917,7,12,0,0,12917,12918,7,7,
	0,0,12918,12919,7,17,0,0,12919,1634,1,0,0,0,12920,12921,7,11,0,0,12921,
	12922,7,2,0,0,12922,12923,7,8,0,0,12923,12924,7,21,0,0,12924,1636,1,0,0,
	0,12925,12926,7,11,0,0,12926,12927,7,2,0,0,12927,12928,7,17,0,0,12928,12929,
	7,16,0,0,12929,12930,7,12,0,0,12930,12931,7,11,0,0,12931,12932,7,6,0,0,
	12932,1638,1,0,0,0,12933,12934,7,11,0,0,12934,12935,7,2,0,0,12935,12936,
	7,17,0,0,12936,12937,7,16,0,0,12937,12938,7,12,0,0,12938,12939,7,11,0,0,
	12939,12940,7,6,0,0,12940,12941,7,5,0,0,12941,1640,1,0,0,0,12942,12943,
	7,11,0,0,12943,12944,7,2,0,0,12944,12945,7,17,0,0,12945,12946,7,17,0,0,
	12946,12947,7,12,0,0,12947,12948,7,7,0,0,12948,12949,7,17,0,0,12949,1642,
	1,0,0,0,12950,12951,7,11,0,0,12951,12952,7,2,0,0,12952,12953,7,17,0,0,12953,
	12954,7,12,0,0,12954,12955,7,8,0,0,12955,12956,7,0,0,0,12956,12957,7,11,
	0,0,12957,1644,1,0,0,0,12958,12959,7,11,0,0,12959,12960,7,2,0,0,12960,12961,
	7,17,0,0,12961,12962,7,12,0,0,12962,12963,7,8,0,0,12963,12964,7,0,0,0,12964,
	12965,7,11,0,0,12965,12966,5,95,0,0,12966,12967,7,3,0,0,12967,12968,7,6,
	0,0,12968,12969,7,0,0,0,12969,12970,7,9,0,0,12970,12971,7,5,0,0,12971,12972,
	5,95,0,0,12972,12973,7,15,0,0,12973,12974,7,6,0,0,12974,12975,7,3,0,0,12975,
	12976,5,95,0,0,12976,12977,7,8,0,0,12977,12978,7,0,0,0,12978,12979,7,11,
	0,0,12979,12980,7,11,0,0,12980,1646,1,0,0,0,12981,12982,7,11,0,0,12982,
	12983,7,2,0,0,12983,12984,7,17,0,0,12984,12985,7,12,0,0,12985,12986,7,8,
	0,0,12986,12987,7,0,0,0,12987,12988,7,11,0,0,12988,12989,5,95,0,0,12989,
	12990,7,3,0,0,12990,12991,7,6,0,0,12991,12992,7,0,0,0,12992,12993,7,9,0,
	0,12993,12994,7,5,0,0,12994,12995,5,95,0,0,12995,12996,7,15,0,0,12996,12997,
	7,6,0,0,12997,12998,7,3,0,0,12998,12999,5,95,0,0,12999,13000,7,5,0,0,13000,
	13001,7,6,0,0,13001,13002,7,5,0,0,13002,13003,7,5,0,0,13003,13004,7,12,
	0,0,13004,13005,7,2,0,0,13005,13006,7,7,0,0,13006,1648,1,0,0,0,13007,13008,
	7,11,0,0,13008,13009,7,2,0,0,13009,13010,7,17,0,0,13010,1650,1,0,0,0,13011,
	13012,7,11,0,0,13012,13013,7,2,0,0,13013,13014,7,17,0,0,13014,13015,7,14,
	0,0,13015,13016,7,12,0,0,13016,13017,7,7,0,0,13017,13018,7,12,0,0,13018,
	13019,7,7,0,0,13019,13020,7,17,0,0,13020,1652,1,0,0,0,13021,13022,7,11,
	0,0,13022,13023,7,2,0,0,13023,13024,7,17,0,0,13024,13025,7,2,0,0,13025,
	13026,7,16,0,0,13026,13027,7,16,0,0,13027,1654,1,0,0,0,13028,13029,7,11,
	0,0,13029,13030,7,2,0,0,13030,13031,7,17,0,0,13031,13032,7,2,0,0,13032,
	13033,7,7,0,0,13033,1656,1,0,0,0,13034,13035,7,11,0,0,13035,13036,7,2,0,
	0,13036,13037,7,17,0,0,13037,13038,5,95,0,0,13038,13039,7,3,0,0,13039,13040,
	7,6,0,0,13040,13041,7,0,0,0,13041,13042,7,9,0,0,13042,13043,5,95,0,0,13043,
	13044,7,2,0,0,13044,13045,7,7,0,0,13045,13046,7,11,0,0,13046,13047,7,18,
	0,0,13047,13048,5,95,0,0,13048,13049,7,13,0,0,13049,13050,7,12,0,0,13050,
	13051,7,2,0,0,13051,13052,7,11,0,0,13052,13053,7,0,0,0,13053,13054,7,4,
	0,0,13054,13055,7,12,0,0,13055,13056,7,2,0,0,13056,13057,7,7,0,0,13057,
	13058,7,5,0,0,13058,1658,1,0,0,0,13059,13060,7,11,0,0,13060,13061,7,2,0,
	0,13061,13062,7,7,0,0,13062,13063,7,17,0,0,13063,1660,1,0,0,0,13064,13065,
	7,11,0,0,13065,13066,7,2,0,0,13066,13067,7,2,0,0,13067,13068,7,15,0,0,13068,
	1662,1,0,0,0,13069,13070,7,11,0,0,13070,13071,7,2,0,0,13071,13072,7,5,0,
	0,13072,13073,7,4,0,0,13073,1664,1,0,0,0,13074,13075,7,11,0,0,13075,13076,
	7,2,0,0,13076,13077,7,22,0,0,13077,13078,7,6,0,0,13078,13079,7,3,0,0,13079,
	1666,1,0,0,0,13080,13081,7,11,0,0,13081,13082,7,2,0,0,13082,13083,7,22,
	0,0,13083,1668,1,0,0,0,13084,13085,7,11,0,0,13085,13086,7,15,0,0,13086,
	13087,7,0,0,0,13087,13088,7,9,0,0,13088,1670,1,0,0,0,13089,13090,7,11,0,
	0,13090,13091,7,4,0,0,13091,13092,7,3,0,0,13092,13093,7,12,0,0,13093,13094,
	7,14,0,0,13094,1672,1,0,0,0,13095,13096,7,14,0,0,13096,1674,1,0,0,0,13097,
	13098,7,14,0,0,13098,13099,7,0,0,0,13099,13100,7,12,0,0,13100,13101,7,7,
	0,0,13101,1676,1,0,0,0,13102,13103,7,14,0,0,13103,13104,7,0,0,0,13104,13105,
	7,21,0,0,13105,13106,7,6,0,0,13106,13107,5,95,0,0,13107,13108,7,3,0,0,13108,
	13109,7,6,0,0,13109,13110,7,16,0,0,13110,1678,1,0,0,0,13111,13112,7,14,
	0,0,13112,13113,7,0,0,0,13113,13114,7,7,0,0,13114,13115,7,0,0,0,13115,13116,
	7,17,0,0,13116,13117,7,6,0,0,13117,13118,7,9,0,0,13118,1680,1,0,0,0,13119,
	13120,7,14,0,0,13120,13121,7,0,0,0,13121,13122,7,7,0,0,13122,13123,7,0,
	0,0,13123,13124,7,17,0,0,13124,13125,7,6,0,0,13125,1682,1,0,0,0,13126,13127,
	7,14,0,0,13127,13128,7,0,0,0,13128,13129,7,7,0,0,13129,13130,7,0,0,0,13130,
	13131,7,17,0,0,13131,13132,7,6,0,0,13132,13133,7,14,0,0,13133,13134,7,6,
	0,0,13134,13135,7,7,0,0,13135,13136,7,4,0,0,13136,1684,1,0,0,0,13137,13138,
	7,14,0,0,13138,13139,7,0,0,0,13139,13140,7,7,0,0,13140,13141,7,0,0,0,13141,
	13142,7,17,0,0,13142,13143,7,6,0,0,13143,13144,7,3,0,0,13144,1686,1,0,0,
	0,13145,13146,7,14,0,0,13146,13147,7,0,0,0,13147,13148,7,7,0,0,13148,13149,
	7,9,0,0,13149,13150,7,0,0,0,13150,13151,7,4,0,0,13151,13152,7,2,0,0,13152,
	13153,7,3,0,0,13153,13154,7,18,0,0,13154,1688,1,0,0,0,13155,13156,7,14,
	0,0,13156,13157,7,0,0,0,13157,13158,7,7,0,0,13158,13159,7,10,0,0,13159,
	13160,7,0,0,0,13160,13161,7,11,0,0,13161,1690,1,0,0,0,13162,13163,7,14,
	0,0,13163,13164,7,0,0,0,13164,13165,7,15,0,0,13165,1692,1,0,0,0,13166,13167,
	7,14,0,0,13167,13168,7,0,0,0,13168,13169,7,15,0,0,13169,13170,7,15,0,0,
	13170,13171,7,12,0,0,13171,13172,7,7,0,0,13172,13173,7,17,0,0,13173,1694,
	1,0,0,0,13174,13175,7,14,0,0,13175,13176,7,0,0,0,13176,13177,7,5,0,0,13177,
	13178,7,4,0,0,13178,13179,7,6,0,0,13179,13180,7,3,0,0,13180,1696,1,0,0,
	0,13181,13182,7,14,0,0,13182,13183,7,0,0,0,13183,13184,7,4,0,0,13184,13185,
	7,8,0,0,13185,13186,7,19,0,0,13186,13187,7,6,0,0,13187,13188,7,9,0,0,13188,
	1698,1,0,0,0,13189,13190,7,14,0,0,13190,13191,7,0,0,0,13191,13192,7,4,0,
	0,13192,13193,7,8,0,0,13193,13194,7,19,0,0,13194,13195,7,6,0,0,13195,13196,
	7,5,0,0,13196,1700,1,0,0,0,13197,13198,7,14,0,0,13198,13199,7,0,0,0,13199,
	13200,7,4,0,0,13200,13201,7,8,0,0,13201,13202,7,19,0,0,13202,1702,1,0,0,
	0,13203,13204,7,14,0,0,13204,13205,7,0,0,0,13205,13206,7,4,0,0,13206,13207,
	7,8,0,0,13207,13208,7,19,0,0,13208,13209,5,95,0,0,13209,13210,7,7,0,0,13210,
	13211,7,10,0,0,13211,13212,7,14,0,0,13212,13213,7,1,0,0,13213,13214,7,6,
	0,0,13214,13215,7,3,0,0,13215,1704,1,0,0,0,13216,13217,7,14,0,0,13217,13218,
	7,0,0,0,13218,13219,7,4,0,0,13219,13220,7,8,0,0,13220,13221,7,19,0,0,13221,
	13222,5,95,0,0,13222,13223,7,3,0,0,13223,13224,7,6,0,0,13224,13225,7,8,
	0,0,13225,13226,7,2,0,0,13226,13227,7,17,0,0,13227,13228,7,7,0,0,13228,
	13229,7,12,0,0,13229,13230,7,23,0,0,13230,13231,7,6,0,0,13231,1706,1,0,
	0,0,13232,13233,7,14,0,0,13233,13234,7,0,0,0,13234,13235,7,4,0,0,13235,
	13236,7,6,0,0,13236,13237,7,3,0,0,13237,13238,7,12,0,0,13238,13239,7,0,
	0,0,13239,13240,7,11,0,0,13240,13241,7,12,0,0,13241,13242,7,23,0,0,13242,
	13243,7,6,0,0,13243,13244,7,9,0,0,13244,1708,1,0,0,0,13245,13246,7,14,0,
	0,13246,13247,7,0,0,0,13247,13248,7,4,0,0,13248,13249,7,6,0,0,13249,13250,
	7,3,0,0,13250,13251,7,12,0,0,13251,13252,7,0,0,0,13252,13253,7,11,0,0,13253,
	13254,7,12,0,0,13254,13255,7,23,0,0,13255,13256,7,6,0,0,13256,1710,1,0,
	0,0,13257,13258,7,14,0,0,13258,13259,7,0,0,0,13259,13260,7,25,0,0,13260,
	13261,7,0,0,0,13261,13262,7,3,0,0,13262,13263,7,8,0,0,13263,13264,7,19,
	0,0,13264,13265,7,11,0,0,13265,13266,7,2,0,0,13266,13267,7,17,0,0,13267,
	13268,7,5,0,0,13268,1712,1,0,0,0,13269,13270,7,14,0,0,13270,13271,7,0,0,
	0,13271,13272,7,25,0,0,13272,13273,7,9,0,0,13273,13274,7,0,0,0,13274,13275,
	7,4,0,0,13275,13276,7,0,0,0,13276,13277,7,16,0,0,13277,13278,7,12,0,0,13278,
	13279,7,11,0,0,13279,13280,7,6,0,0,13280,13281,7,5,0,0,13281,1714,1,0,0,
	0,13282,13283,7,14,0,0,13283,13284,7,0,0,0,13284,13285,7,25,0,0,13285,13286,
	7,6,0,0,13286,13287,7,25,0,0,13287,13288,7,4,0,0,13288,13289,7,6,0,0,13289,
	13290,7,7,0,0,13290,13291,7,4,0,0,13291,13292,7,5,0,0,13292,1716,1,0,0,
	0,13293,13294,7,14,0,0,13294,13295,7,0,0,0,13295,13296,7,25,0,0,13296,13297,
	7,12,0,0,13297,13298,7,14,0,0,13298,13299,7,12,0,0,13299,13300,7,23,0,0,
	13300,13301,7,6,0,0,13301,1718,1,0,0,0,13302,13303,7,14,0,0,13303,13304,
	7,0,0,0,13304,13305,7,25,0,0,13305,13306,7,12,0,0,13306,13307,7,7,0,0,13307,
	13308,7,5,0,0,13308,13309,7,4,0,0,13309,13310,7,0,0,0,13310,13311,7,7,0,
	0,13311,13312,7,8,0,0,13312,13313,7,6,0,0,13313,13314,7,5,0,0,13314,1720,
	1,0,0,0,13315,13316,7,14,0,0,13316,13317,7,0,0,0,13317,13318,7,25,0,0,13318,
	13319,7,11,0,0,13319,13320,7,2,0,0,13320,13321,7,17,0,0,13321,13322,7,16,
	0,0,13322,13323,7,12,0,0,13323,13324,7,11,0,0,13324,13325,7,6,0,0,13325,
	13326,7,5,0,0,13326,1722,1,0,0,0,13327,13328,7,14,0,0,13328,13329,7,0,0,
	0,13329,13330,7,25,0,0,13330,13331,7,11,0,0,13331,13332,7,2,0,0,13332,13333,
	7,17,0,0,13333,13334,7,19,0,0,13334,13335,7,12,0,0,13335,13336,7,5,0,0,
	13336,13337,7,4,0,0,13337,13338,7,2,0,0,13338,13339,7,3,0,0,13339,13340,
	7,18,0,0,13340,1724,1,0,0,0,13341,13342,7,14,0,0,13342,13343,7,0,0,0,13343,
	13344,7,25,0,0,13344,13345,7,11,0,0,13345,13346,7,2,0,0,13346,13347,7,17,
	0,0,13347,13348,7,14,0,0,13348,13349,7,6,0,0,13349,13350,7,14,0,0,13350,
	13351,7,1,0,0,13351,13352,7,6,0,0,13352,13353,7,3,0,0,13353,13354,7,5,0,
	0,13354,1726,1,0,0,0,13355,13356,7,14,0,0,13356,13357,7,0,0,0,13357,13358,
	7,25,0,0,13358,13359,5,95,0,0,13359,13360,7,5,0,0,13360,13361,7,19,0,0,
	13361,13362,7,0,0,0,13362,13363,7,3,0,0,13363,13364,7,6,0,0,13364,13365,
	7,9,0,0,13365,13366,5,95,0,0,13366,13367,7,4,0,0,13367,13368,7,6,0,0,13368,
	13369,7,14,0,0,13369,13370,7,15,0,0,13370,13371,5,95,0,0,13371,13372,7,
	5,0,0,13372,13373,7,12,0,0,13373,13374,7,23,0,0,13374,13375,7,6,0,0,13375,
	1728,1,0,0,0,13376,13377,7,14,0,0,13377,13378,7,0,0,0,13378,13379,7,25,
	0,0,13379,13380,7,5,0,0,13380,13381,7,12,0,0,13381,13382,7,23,0,0,13382,
	13383,7,6,0,0,13383,1730,1,0,0,0,13384,13385,7,14,0,0,13385,13386,7,0,0,
	0,13386,13387,7,25,0,0,13387,13388,7,4,0,0,13388,13389,7,3,0,0,13389,13390,
	7,0,0,0,13390,13391,7,7,0,0,13391,13392,7,5,0,0,13392,1732,1,0,0,0,13393,
	13394,7,14,0,0,13394,13395,7,0,0,0,13395,13396,7,25,0,0,13396,13397,7,13,
	0,0,13397,13398,7,0,0,0,13398,13399,7,11,0,0,13399,13400,7,10,0,0,13400,
	13401,7,6,0,0,13401,1734,1,0,0,0,13402,13403,7,14,0,0,13403,13404,7,6,0,
	0,13404,13405,7,0,0,0,13405,13406,7,5,0,0,13406,13407,7,10,0,0,13407,13408,
	7,3,0,0,13408,13409,7,6,0,0,13409,1736,1,0,0,0,13410,13411,7,14,0,0,13411,
	13412,7,6,0,0,13412,13413,7,0,0,0,13413,13414,7,5,0,0,13414,13415,7,10,
	0,0,13415,13416,7,3,0,0,13416,13417,7,6,0,0,13417,13418,7,5,0,0,13418,1738,
	1,0,0,0,13419,13420,7,14,0,0,13420,13421,7,6,0,0,13421,13422,7,9,0,0,13422,
	13423,7,12,0,0,13423,13424,7,10,0,0,13424,13425,7,14,0,0,13425,1740,1,0,
	0,0,13426,13427,7,14,0,0,13427,13428,7,6,0,0,13428,13429,7,14,0,0,13429,
	13430,7,1,0,0,13430,13431,7,6,0,0,13431,13432,7,3,0,0,13432,1742,1,0,0,
	0,13433,13434,7,14,0,0,13434,13435,7,6,0,0,13435,13436,7,14,0,0,13436,13437,
	7,1,0,0,13437,13438,7,6,0,0,13438,13439,7,3,0,0,13439,13440,5,95,0,0,13440,
	13441,7,8,0,0,13441,13442,7,0,0,0,13442,13443,7,15,0,0,13443,13444,7,4,
	0,0,13444,13445,7,12,0,0,13445,13446,7,2,0,0,13446,13447,7,7,0,0,13447,
	1744,1,0,0,0,13448,13449,7,14,0,0,13449,13450,7,6,0,0,13450,13451,7,14,
	0,0,13451,13452,7,1,0,0,13452,13453,7,6,0,0,13453,13454,7,3,0,0,13454,13455,
	5,95,0,0,13455,13456,7,9,0,0,13456,13457,7,6,0,0,13457,13458,7,5,0,0,13458,
	13459,7,8,0,0,13459,13460,7,3,0,0,13460,13461,7,12,0,0,13461,13462,7,15,
	0,0,13462,13463,7,4,0,0,13463,13464,7,12,0,0,13464,13465,7,2,0,0,13465,
	13466,7,7,0,0,13466,1746,1,0,0,0,13467,13468,7,14,0,0,13468,13469,7,6,0,
	0,13469,13470,7,14,0,0,13470,13471,7,1,0,0,13471,13472,7,6,0,0,13472,13473,
	7,3,0,0,13473,13474,5,95,0,0,13474,13475,7,7,0,0,13475,13476,7,0,0,0,13476,
	13477,7,14,0,0,13477,13478,7,6,0,0,13478,1748,1,0,0,0,13479,13480,7,14,
	0,0,13480,13481,7,6,0,0,13481,13482,7,14,0,0,13482,13483,7,1,0,0,13483,
	13484,7,6,0,0,13484,13485,7,3,0,0,13485,13486,5,95,0,0,13486,13487,7,10,
	0,0,13487,13488,7,7,0,0,13488,13489,7,12,0,0,13489,13490,7,24,0,0,13490,
	13491,7,10,0,0,13491,13492,7,6,0,0,13492,13493,5,95,0,0,13493,13494,7,7,
	0,0,13494,13495,7,0,0,0,13495,13496,7,14,0,0,13496,13497,7,6,0,0,13497,
	1750,1,0,0,0,13498,13499,7,14,0,0,13499,13500,7,6,0,0,13500,13501,7,14,
	0,0,13501,13502,7,8,0,0,13502,13503,7,2,0,0,13503,13504,7,14,0,0,13504,
	13505,7,15,0,0,13505,13506,7,3,0,0,13506,13507,7,6,0,0,13507,13508,7,5,
	0,0,13508,13509,7,5,0,0,13509,1752,1,0,0,0,13510,13511,7,14,0,0,13511,13512,
	7,6,0,0,13512,13513,7,14,0,0,13513,13514,7,2,0,0,13514,13515,7,3,0,0,13515,
	13516,7,18,0,0,13516,1754,1,0,0,0,13517,13518,7,14,0,0,13518,13519,7,6,
	0,0,13519,13520,7,3,0,0,13520,13521,7,17,0,0,13521,13522,7,6,0,0,13522,
	13523,5,36,0,0,13523,13524,7,0,0,0,13524,13525,7,8,0,0,13525,13526,7,4,
	0,0,13526,13527,7,12,0,0,13527,13528,7,2,0,0,13528,13529,7,7,0,0,13529,
	13530,7,5,0,0,13530,1756,1,0,0,0,13531,13532,7,14,0,0,13532,13533,7,6,0,
	0,13533,13534,7,3,0,0,13534,13535,7,17,0,0,13535,13536,7,6,0,0,13536,13537,
	5,95,0,0,13537,13538,7,0,0,0,13538,13539,7,20,0,0,13539,1758,1,0,0,0,13540,
	13541,7,14,0,0,13541,13542,7,6,0,0,13542,13543,7,3,0,0,13543,13544,7,17,
	0,0,13544,13545,7,6,0,0,13545,13546,5,95,0,0,13546,13547,7,8,0,0,13547,
	13548,7,2,0,0,13548,13549,7,7,0,0,13549,13550,7,5,0,0,13550,13551,7,4,0,
	0,13551,13552,5,95,0,0,13552,13553,7,2,0,0,13553,13554,7,7,0,0,13554,1760,
	1,0,0,0,13555,13556,7,14,0,0,13556,13557,7,6,0,0,13557,13558,7,3,0,0,13558,
	13559,7,17,0,0,13559,13560,7,6,0,0,13560,1762,1,0,0,0,13561,13562,7,14,
	0,0,13562,13563,7,6,0,0,13563,13564,7,3,0,0,13564,13565,7,17,0,0,13565,
	13566,7,6,0,0,13566,13567,5,95,0,0,13567,13568,7,5,0,0,13568,13569,7,20,
	0,0,13569,1764,1,0,0,0,13570,13571,7,14,0,0,13571,13572,7,6,0,0,13572,13573,
	7,4,0,0,13573,13574,7,0,0,0,13574,13575,7,9,0,0,13575,13576,7,0,0,0,13576,
	13577,7,4,0,0,13577,13578,7,0,0,0,13578,1766,1,0,0,0,13579,13580,7,14,0,
	0,13580,13581,7,6,0,0,13581,13582,7,4,0,0,13582,13583,7,19,0,0,13583,13584,
	7,2,0,0,13584,13585,7,9,0,0,13585,1768,1,0,0,0,13586,13587,7,14,0,0,13587,
	13588,7,12,0,0,13588,13589,7,17,0,0,13589,13590,7,3,0,0,13590,13591,7,0,
	0,0,13591,13592,7,4,0,0,13592,13593,7,6,0,0,13593,1770,1,0,0,0,13594,13595,
	7,14,0,0,13595,13596,7,12,0,0,13596,13597,7,17,0,0,13597,13598,7,3,0,0,
	13598,13599,7,0,0,0,13599,13600,7,4,0,0,13600,13601,7,12,0,0,13601,13602,
	7,2,0,0,13602,13603,7,7,0,0,13603,1772,1,0,0,0,13604,13605,7,14,0,0,13605,
	13606,7,12,0,0,13606,13607,7,7,0,0,13607,13608,7,6,0,0,13608,13609,7,25,
	0,0,13609,13610,7,4,0,0,13610,13611,7,6,0,0,13611,13612,7,7,0,0,13612,13613,
	7,4,0,0,13613,13614,7,5,0,0,13614,1774,1,0,0,0,13615,13616,7,14,0,0,13616,
	13617,7,12,0,0,13617,13618,7,7,0,0,13618,13619,7,12,0,0,13619,13620,7,14,
	0,0,13620,13621,7,12,0,0,13621,13622,7,23,0,0,13622,13623,7,6,0,0,13623,
	1776,1,0,0,0,13624,13625,7,14,0,0,13625,13626,7,12,0,0,13626,13627,7,7,
	0,0,13627,13628,7,12,0,0,13628,13629,7,14,0,0,13629,13630,7,10,0,0,13630,
	13631,7,14,0,0,13631,1778,1,0,0,0,13632,13633,7,14,0,0,13633,13634,7,12,
	0,0,13634,13635,7,7,0,0,13635,13636,7,12,0,0,13636,13637,7,7,0,0,13637,
	13638,7,17,0,0,13638,1780,1,0,0,0,13639,13640,7,14,0,0,13640,13641,7,12,
	0,0,13641,13642,7,7,0,0,13642,13643,7,10,0,0,13643,13644,7,5,0,0,13644,
	1782,1,0,0,0,13645,13646,7,14,0,0,13646,13647,7,12,0,0,13647,13648,7,7,
	0,0,13648,13649,7,10,0,0,13649,13650,7,5,0,0,13650,13651,5,95,0,0,13651,
	13652,7,7,0,0,13652,13653,7,10,0,0,13653,13654,7,11,0,0,13654,13655,7,11,
	0,0,13655,1784,1,0,0,0,13656,13657,7,14,0,0,13657,13658,7,12,0,0,13658,
	13659,7,7,0,0,13659,13660,7,10,0,0,13660,13661,7,4,0,0,13661,13662,7,6,
	0,0,13662,1786,1,0,0,0,13663,13664,7,14,0,0,13664,13665,7,12,0,0,13665,
	13666,7,7,0,0,13666,13667,7,10,0,0,13667,13668,7,4,0,0,13668,13669,7,6,
	0,0,13669,13670,7,5,0,0,13670,1788,1,0,0,0,13671,13672,7,14,0,0,13672,13673,
	7,12,0,0,13673,13674,7,7,0,0,13674,13675,7,13,0,0,13675,13676,7,0,0,0,13676,
	13677,7,11,0,0,13677,13678,7,10,0,0,13678,13679,7,6,0,0,13679,1790,1,0,
	0,0,13680,13681,7,14,0,0,13681,13682,7,12,0,0,13682,13683,7,3,0,0,13683,
	13684,7,3,0,0,13684,13685,7,2,0,0,13685,13686,7,3,0,0,13686,13687,7,8,0,
	0,13687,13688,7,2,0,0,13688,13689,7,11,0,0,13689,13690,7,9,0,0,13690,1792,
	1,0,0,0,13691,13692,7,14,0,0,13692,13693,7,12,0,0,13693,13694,7,3,0,0,13694,
	13695,7,3,0,0,13695,13696,7,2,0,0,13696,13697,7,3,0,0,13697,13698,7,19,
	0,0,13698,13699,7,2,0,0,13699,13700,7,4,0,0,13700,1794,1,0,0,0,13701,13702,
	7,14,0,0,13702,13703,7,12,0,0,13703,13704,7,3,0,0,13704,13705,7,3,0,0,13705,
	13706,7,2,0,0,13706,13707,7,3,0,0,13707,1796,1,0,0,0,13708,13709,7,14,0,
	0,13709,13710,7,12,0,0,13710,13711,7,5,0,0,13711,13712,7,5,0,0,13712,13713,
	7,12,0,0,13713,13714,7,7,0,0,13714,13715,7,17,0,0,13715,1798,1,0,0,0,13716,
	13717,7,14,0,0,13717,13718,7,12,0,0,13718,13719,7,5,0,0,13719,13720,7,14,
	0,0,13720,13721,7,0,0,0,13721,13722,7,4,0,0,13722,13723,7,8,0,0,13723,13724,
	7,19,0,0,13724,1800,1,0,0,0,13725,13726,7,14,0,0,13726,13727,7,11,0,0,13727,
	13728,7,5,0,0,13728,13729,7,11,0,0,13729,13730,7,0,0,0,13730,13731,7,1,
	0,0,13731,13732,7,6,0,0,13732,13733,7,11,0,0,13733,1802,1,0,0,0,13734,13735,
	7,14,0,0,13735,13736,7,2,0,0,13736,13737,7,9,0,0,13737,13738,7,6,0,0,13738,
	13739,7,11,0,0,13739,13740,5,95,0,0,13740,13741,7,8,0,0,13741,13742,7,2,
	0,0,13742,13743,7,14,0,0,13743,13744,7,15,0,0,13744,13745,7,12,0,0,13745,
	13746,7,11,0,0,13746,13747,7,6,0,0,13747,13748,5,95,0,0,13748,13749,7,5,
	0,0,13749,13750,7,10,0,0,13750,13751,7,1,0,0,13751,13752,7,24,0,0,13752,
	13753,7,10,0,0,13753,13754,7,6,0,0,13754,13755,7,3,0,0,13755,13756,7,18,
	0,0,13756,1804,1,0,0,0,13757,13758,7,14,0,0,13758,13759,7,2,0,0,13759,13760,
	7,9,0,0,13760,13761,7,6,0,0,13761,13762,7,11,0,0,13762,13763,5,95,0,0,13763,
	13764,7,9,0,0,13764,13765,7,2,0,0,13765,13766,7,7,0,0,13766,13767,7,4,0,
	0,13767,13768,7,13,0,0,13768,13769,7,6,0,0,13769,13770,7,3,0,0,13770,13771,
	7,12,0,0,13771,13772,7,16,0,0,13772,13773,7,18,0,0,13773,13774,5,95,0,0,
	13774,13775,7,10,0,0,13775,13776,7,7,0,0,13776,13777,7,12,0,0,13777,13778,
	7,24,0,0,13778,13779,7,10,0,0,13779,13780,7,6,0,0,13780,13781,7,7,0,0,13781,
	13782,7,6,0,0,13782,13783,7,5,0,0,13783,13784,7,5,0,0,13784,1806,1,0,0,
	0,13785,13786,7,14,0,0,13786,13787,7,2,0,0,13787,13788,7,9,0,0,13788,13789,
	7,6,0,0,13789,13790,7,11,0,0,13790,13791,5,95,0,0,13791,13792,7,9,0,0,13792,
	13793,7,18,0,0,13793,13794,7,7,0,0,13794,13795,7,0,0,0,13795,13796,7,14,
	0,0,13796,13797,7,12,0,0,13797,13798,7,8,0,0,13798,13799,5,95,0,0,13799,
	13800,7,5,0,0,13800,13801,7,10,0,0,13801,13802,7,1,0,0,13802,13803,7,24,
	0,0,13803,13804,7,10,0,0,13804,13805,7,6,0,0,13805,13806,7,3,0,0,13806,
	13807,7,18,0,0,13807,1808,1,0,0,0,13808,13809,7,14,0,0,13809,13810,7,2,
	0,0,13810,13811,7,9,0,0,13811,13812,7,6,0,0,13812,13813,7,11,0,0,13813,
	13814,5,95,0,0,13814,13815,7,14,0,0,13815,13816,7,12,0,0,13816,13817,7,
	7,0,0,13817,13818,5,95,0,0,13818,13819,7,0,0,0,13819,13820,7,7,0,0,13820,
	13821,7,0,0,0,13821,13822,7,11,0,0,13822,13823,7,18,0,0,13823,13824,7,5,
	0,0,13824,13825,7,12,0,0,13825,13826,7,5,0,0,13826,1810,1,0,0,0,13827,13828,
	7,14,0,0,13828,13829,7,2,0,0,13829,13830,7,9,0,0,13830,13831,7,6,0,0,13831,
	13832,7,11,0,0,13832,1812,1,0,0,0,13833,13834,7,14,0,0,13834,13835,7,2,
	0,0,13835,13836,7,9,0,0,13836,13837,7,6,0,0,13837,13838,7,11,0,0,13838,
	13839,5,95,0,0,13839,13840,7,7,0,0,13840,13841,7,1,0,0,13841,1814,1,0,0,
	0,13842,13843,7,14,0,0,13843,13844,7,2,0,0,13844,13845,7,9,0,0,13845,13846,
	7,6,0,0,13846,13847,7,11,0,0,13847,13848,5,95,0,0,13848,13849,7,7,0,0,13849,
	13850,7,2,0,0,13850,13851,5,95,0,0,13851,13852,7,0,0,0,13852,13853,7,7,
	0,0,13853,13854,7,0,0,0,13854,13855,7,11,0,0,13855,13856,7,18,0,0,13856,
	13857,7,5,0,0,13857,13858,7,12,0,0,13858,13859,7,5,0,0,13859,1816,1,0,0,
	0,13860,13861,7,14,0,0,13861,13862,7,2,0,0,13862,13863,7,9,0,0,13863,13864,
	7,6,0,0,13864,13865,7,11,0,0,13865,13866,5,95,0,0,13866,13867,7,15,0,0,
	13867,13868,7,1,0,0,13868,13869,7,18,0,0,13869,1818,1,0,0,0,13870,13871,
	7,14,0,0,13871,13872,7,2,0,0,13872,13873,7,9,0,0,13873,13874,7,6,0,0,13874,
	13875,7,11,0,0,13875,13876,5,95,0,0,13876,13877,7,15,0,0,13877,13878,7,
	10,0,0,13878,13879,7,5,0,0,13879,13880,7,19,0,0,13880,13881,5,95,0,0,13881,
	13882,7,3,0,0,13882,13883,7,6,0,0,13883,13884,7,16,0,0,13884,1820,1,0,0,
	0,13885,13886,7,14,0,0,13886,13887,7,2,0,0,13887,13888,7,9,0,0,13888,13889,
	7,6,0,0,13889,13890,7,11,0,0,13890,13891,5,95,0,0,13891,13892,7,5,0,0,13892,
	13893,7,13,0,0,13893,1822,1,0,0,0,13894,13895,7,14,0,0,13895,13896,7,2,
	0,0,13896,13897,7,9,0,0,13897,13898,7,6,0,0,13898,1824,1,0,0,0,13899,13900,
	7,14,0,0,13900,13901,7,2,0,0,13901,13902,7,9,0,0,13902,13903,7,12,0,0,13903,
	13904,7,16,0,0,13904,13905,7,12,0,0,13905,13906,7,8,0,0,13906,13907,7,0,
	0,0,13907,13908,7,4,0,0,13908,13909,7,12,0,0,13909,13910,7,2,0,0,13910,
	13911,7,7,0,0,13911,1826,1,0,0,0,13912,13913,7,14,0,0,13913,13914,7,2,0,
	0,13914,13915,7,9,0,0,13915,13916,7,12,0,0,13916,13917,7,16,0,0,13917,13918,
	7,18,0,0,13918,13919,5,95,0,0,13919,13920,7,8,0,0,13920,13921,7,2,0,0,13921,
	13922,7,11,0,0,13922,13923,7,10,0,0,13923,13924,7,14,0,0,13924,13925,7,
	7,0,0,13925,13926,5,95,0,0,13926,13927,7,4,0,0,13927,13928,7,18,0,0,13928,
	13929,7,15,0,0,13929,13930,7,6,0,0,13930,1828,1,0,0,0,13931,13932,7,14,
	0,0,13932,13933,7,2,0,0,13933,13934,7,9,0,0,13934,13935,7,12,0,0,13935,
	13936,7,16,0,0,13936,13937,7,18,0,0,13937,1830,1,0,0,0,13938,13939,7,14,
	0,0,13939,13940,7,2,0,0,13940,13941,7,9,0,0,13941,1832,1,0,0,0,13942,13943,
	7,14,0,0,13943,13944,7,2,0,0,13944,13945,7,9,0,0,13945,13946,7,10,0,0,13946,
	13947,7,11,0,0,13947,13948,7,6,0,0,13948,1834,1,0,0,0,13949,13950,7,14,
	0,0,13950,13951,7,2,0,0,13951,13952,7,7,0,0,13952,13953,7,12,0,0,13953,
	13954,7,4,0,0,13954,13955,7,2,0,0,13955,13956,7,3,0,0,13956,13957,7,12,
	0,0,13957,13958,7,7,0,0,13958,13959,7,17,0,0,13959,1836,1,0,0,0,13960,13961,
	7,14,0,0,13961,13962,7,2,0,0,13962,13963,7,7,0,0,13963,13964,7,12,0,0,13964,
	13965,7,4,0,0,13965,13966,7,2,0,0,13966,13967,7,3,0,0,13967,1838,1,0,0,
	0,13968,13969,7,14,0,0,13969,13970,7,2,0,0,13970,13971,7,7,0,0,13971,13972,
	7,4,0,0,13972,13973,7,19,0,0,13973,1840,1,0,0,0,13974,13975,7,14,0,0,13975,
	13976,7,2,0,0,13976,13977,7,7,0,0,13977,13978,7,4,0,0,13978,13979,7,19,
	0,0,13979,13980,7,5,0,0,13980,13981,5,95,0,0,13981,13982,7,1,0,0,13982,
	13983,7,6,0,0,13983,13984,7,4,0,0,13984,13985,7,22,0,0,13985,13986,7,6,
	0,0,13986,13987,7,6,0,0,13987,13988,7,7,0,0,13988,1842,1,0,0,0,13989,13990,
	7,14,0,0,13990,13991,7,2,0,0,13991,13992,7,7,0,0,13992,13993,7,4,0,0,13993,
	13994,7,19,0,0,13994,13995,7,5,0,0,13995,1844,1,0,0,0,13996,13997,7,14,
	0,0,13997,13998,7,2,0,0,13998,13999,7,10,0,0,13999,14000,7,7,0,0,14000,
	14001,7,4,0,0,14001,1846,1,0,0,0,14002,14003,7,14,0,0,14003,14004,7,2,0,
	0,14004,14005,7,10,0,0,14005,14006,7,7,0,0,14006,14007,7,4,0,0,14007,14008,
	7,15,0,0,14008,14009,7,0,0,0,14009,14010,7,4,0,0,14010,14011,7,19,0,0,14011,
	1848,1,0,0,0,14012,14013,7,14,0,0,14013,14014,7,2,0,0,14014,14015,7,10,
	0,0,14015,14016,7,7,0,0,14016,14017,7,4,0,0,14017,14018,7,15,0,0,14018,
	14019,7,2,0,0,14019,14020,7,12,0,0,14020,14021,7,7,0,0,14021,14022,7,4,
	0,0,14022,1850,1,0,0,0,14023,14024,7,14,0,0,14024,14025,7,2,0,0,14025,14026,
	7,13,0,0,14026,14027,7,6,0,0,14027,14028,7,14,0,0,14028,14029,7,6,0,0,14029,
	14030,7,7,0,0,14030,14031,7,4,0,0,14031,1852,1,0,0,0,14032,14033,7,14,0,
	0,14033,14034,7,2,0,0,14034,14035,7,13,0,0,14035,14036,7,6,0,0,14036,1854,
	1,0,0,0,14037,14038,7,14,0,0,14038,14039,7,10,0,0,14039,14040,7,11,0,0,
	14040,14041,7,4,0,0,14041,14042,7,12,0,0,14042,14043,7,9,0,0,14043,14044,
	7,12,0,0,14044,14045,7,14,0,0,14045,14046,7,6,0,0,14046,14047,7,7,0,0,14047,
	14048,7,5,0,0,14048,14049,7,12,0,0,14049,14050,7,2,0,0,14050,14051,7,7,
	0,0,14051,14052,7,0,0,0,14052,14053,7,11,0,0,14053,1856,1,0,0,0,14054,14055,
	7,14,0,0,14055,14056,7,10,0,0,14056,14057,7,11,0,0,14057,14058,7,4,0,0,
	14058,14059,7,12,0,0,14059,14060,7,5,0,0,14060,14061,7,6,0,0,14061,14062,
	7,4,0,0,14062,1858,1,0,0,0,14063,14064,7,14,0,0,14064,14065,7,13,0,0,14065,
	14066,5,95,0,0,14066,14067,7,14,0,0,14067,14068,7,6,0,0,14068,14069,7,3,
	0,0,14069,14070,7,17,0,0,14070,14071,7,6,0,0,14071,1860,1,0,0,0,14072,14073,
	7,7,0,0,14073,14074,7,0,0,0,14074,14075,7,14,0,0,14075,14076,7,6,0,0,14076,
	14077,7,9,0,0,14077,1862,1,0,0,0,14078,14079,7,7,0,0,14079,14080,7,0,0,
	0,14080,14081,7,14,0,0,14081,14082,7,6,0,0,14082,1864,1,0,0,0,14083,14084,
	7,7,0,0,14084,14085,7,0,0,0,14085,14086,7,14,0,0,14086,14087,7,6,0,0,14087,
	14088,7,5,0,0,14088,14089,7,15,0,0,14089,14090,7,0,0,0,14090,14091,7,8,
	0,0,14091,14092,7,6,0,0,14092,1866,1,0,0,0,14093,14094,7,7,0,0,14094,14095,
	7,0,0,0,14095,14096,7,7,0,0,14096,1868,1,0,0,0,14097,14098,7,7,0,0,14098,
	14099,7,0,0,0,14099,14100,7,7,0,0,14100,14101,7,13,0,0,14101,14102,7,11,
	0,0,14102,1870,1,0,0,0,14103,14104,7,7,0,0,14104,14105,7,0,0,0,14105,14106,
	7,4,0,0,14106,14107,7,12,0,0,14107,14108,7,2,0,0,14108,14109,7,7,0,0,14109,
	14110,7,0,0,0,14110,14111,7,11,0,0,14111,1872,1,0,0,0,14112,14113,7,7,0,
	0,14113,14114,7,0,0,0,14114,14115,7,4,0,0,14115,14116,7,12,0,0,14116,14117,
	7,13,0,0,14117,14118,7,6,0,0,14118,14119,5,95,0,0,14119,14120,7,16,0,0,
	14120,14121,7,10,0,0,14121,14122,7,11,0,0,14122,14123,7,11,0,0,14123,14124,
	5,95,0,0,14124,14125,7,2,0,0,14125,14126,7,10,0,0,14126,14127,7,4,0,0,14127,
	14128,7,6,0,0,14128,14129,7,3,0,0,14129,14130,5,95,0,0,14130,14131,7,20,
	0,0,14131,14132,7,2,0,0,14132,14133,7,12,0,0,14133,14134,7,7,0,0,14134,
	1874,1,0,0,0,14135,14136,7,7,0,0,14136,14137,7,0,0,0,14137,14138,7,4,0,
	0,14138,14139,7,12,0,0,14139,14140,7,13,0,0,14140,14141,7,6,0,0,14141,1876,
	1,0,0,0,14142,14143,7,7,0,0,14143,14144,7,0,0,0,14144,14145,7,4,0,0,14145,
	14146,7,10,0,0,14146,14147,7,3,0,0,14147,14148,7,0,0,0,14148,14149,7,11,
	0,0,14149,1878,1,0,0,0,14150,14151,7,7,0,0,14151,14152,7,0,0,0,14152,14153,
	7,4,0,0,14153,14154,7,10,0,0,14154,14155,7,3,0,0,14155,14156,7,0,0,0,14156,
	14157,7,11,0,0,14157,14158,7,7,0,0,14158,1880,1,0,0,0,14159,14160,7,7,0,
	0,14160,14161,7,0,0,0,14161,14162,7,13,0,0,14162,1882,1,0,0,0,14163,14164,
	7,7,0,0,14164,14165,7,8,0,0,14165,14166,7,19,0,0,14166,14167,7,0,0,0,14167,
	14168,7,3,0,0,14168,14169,5,95,0,0,14169,14170,7,8,0,0,14170,14171,7,5,
	0,0,14171,1884,1,0,0,0,14172,14173,7,7,0,0,14173,14174,7,8,0,0,14174,14175,
	7,19,0,0,14175,14176,7,0,0,0,14176,14177,7,3,0,0,14177,1886,1,0,0,0,14178,
	14179,7,7,0,0,14179,14180,7,8,0,0,14180,14181,7,19,0,0,14181,14182,7,3,
	0,0,14182,1888,1,0,0,0,14183,14184,7,7,0,0,14184,14185,7,8,0,0,14185,14186,
	7,11,0,0,14186,14187,7,2,0,0,14187,14188,7,1,0,0,14188,1890,1,0,0,0,14189,
	14190,7,7,0,0,14190,14191,7,6,0,0,14191,14192,7,6,0,0,14192,14193,7,9,0,
	0,14193,14194,7,6,0,0,14194,14195,7,9,0,0,14195,1892,1,0,0,0,14196,14197,
	7,7,0,0,14197,14198,7,6,0,0,14198,14199,7,17,0,0,14199,1894,1,0,0,0,14200,
	14201,7,7,0,0,14201,14202,7,6,0,0,14202,14203,7,5,0,0,14203,14204,7,4,0,
	0,14204,14205,7,6,0,0,14205,14206,7,9,0,0,14206,1896,1,0,0,0,14207,14208,
	7,7,0,0,14208,14209,7,6,0,0,14209,14210,7,5,0,0,14210,14211,7,4,0,0,14211,
	14212,7,6,0,0,14212,14213,7,9,0,0,14213,14214,5,95,0,0,14214,14215,7,4,
	0,0,14215,14216,7,0,0,0,14216,14217,7,1,0,0,14217,14218,7,11,0,0,14218,
	14219,7,6,0,0,14219,14220,5,95,0,0,14220,14221,7,16,0,0,14221,14222,7,0,
	0,0,14222,14223,7,5,0,0,14223,14224,7,4,0,0,14224,14225,5,95,0,0,14225,
	14226,7,12,0,0,14226,14227,7,7,0,0,14227,14228,7,5,0,0,14228,14229,7,6,
	0,0,14229,14230,7,3,0,0,14230,14231,7,4,0,0,14231,1898,1,0,0,0,14232,14233,
	7,7,0,0,14233,14234,7,6,0,0,14234,14235,7,5,0,0,14235,14236,7,4,0,0,14236,
	14237,7,6,0,0,14237,14238,7,9,0,0,14238,14239,5,95,0,0,14239,14240,7,4,
	0,0,14240,14241,7,0,0,0,14241,14242,7,1,0,0,14242,14243,7,11,0,0,14243,
	14244,7,6,0,0,14244,14245,5,95,0,0,14245,14246,7,17,0,0,14246,14247,7,6,
	0,0,14247,14248,7,4,0,0,14248,14249,5,95,0,0,14249,14250,7,3,0,0,14250,
	14251,7,6,0,0,14251,14252,7,16,0,0,14252,14253,7,5,0,0,14253,1900,1,0,0,
	0,14254,14255,7,7,0,0,14255,14256,7,6,0,0,14256,14257,7,5,0,0,14257,14258,
	7,4,0,0,14258,14259,7,6,0,0,14259,14260,7,9,0,0,14260,14261,5,95,0,0,14261,
	14262,7,4,0,0,14262,14263,7,0,0,0,14263,14264,7,1,0,0,14264,14265,7,11,
	0,0,14265,14266,7,6,0,0,14266,14267,5,95,0,0,14267,14268,7,12,0,0,14268,
	14269,7,9,0,0,14269,1902,1,0,0,0,14270,14271,7,7,0,0,14271,14272,7,6,0,
	0,14272,14273,7,5,0,0,14273,14274,7,4,0,0,14274,14275,7,6,0,0,14275,14276,
	7,9,0,0,14276,14277,5,95,0,0,14277,14278,7,4,0,0,14278,14279,7,0,0,0,14279,
	14280,7,1,0,0,14280,14281,7,11,0,0,14281,14282,7,6,0,0,14282,14283,5,95,
	0,0,14283,14284,7,5,0,0,14284,14285,7,6,0,0,14285,14286,7,4,0,0,14286,14287,
	5,95,0,0,14287,14288,7,3,0,0,14288,14289,7,6,0,0,14289,14290,7,16,0,0,14290,
	14291,7,5,0,0,14291,1904,1,0,0,0,14292,14293,7,7,0,0,14293,14294,7,6,0,
	0,14294,14295,7,5,0,0,14295,14296,7,4,0,0,14296,14297,7,6,0,0,14297,14298,
	7,9,0,0,14298,14299,5,95,0,0,14299,14300,7,4,0,0,14300,14301,7,0,0,0,14301,
	14302,7,1,0,0,14302,14303,7,11,0,0,14303,14304,7,6,0,0,14304,14305,5,95,
	0,0,14305,14306,7,5,0,0,14306,14307,7,6,0,0,14307,14308,7,4,0,0,14308,14309,
	5,95,0,0,14309,14310,7,5,0,0,14310,14311,7,6,0,0,14311,14312,7,4,0,0,14312,
	14313,7,12,0,0,14313,14314,7,9,0,0,14314,1906,1,0,0,0,14315,14316,7,7,0,
	0,14316,14317,7,6,0,0,14317,14318,7,4,0,0,14318,14319,7,22,0,0,14319,14320,
	7,2,0,0,14320,14321,7,3,0,0,14321,14322,7,21,0,0,14322,1908,1,0,0,0,14323,
	14324,7,7,0,0,14324,14325,7,6,0,0,14325,14326,7,13,0,0,14326,14327,7,6,
	0,0,14327,14328,7,3,0,0,14328,1910,1,0,0,0,14329,14330,7,7,0,0,14330,14331,
	7,6,0,0,14331,14332,7,22,0,0,14332,1912,1,0,0,0,14333,14334,7,7,0,0,14334,
	14335,7,6,0,0,14335,14336,7,22,0,0,14336,14337,5,95,0,0,14337,14338,7,4,
	0,0,14338,14339,7,12,0,0,14339,14340,7,14,0,0,14340,14341,7,6,0,0,14341,
	1914,1,0,0,0,14342,14343,7,7,0,0,14343,14344,7,6,0,0,14344,14345,7,25,0,
	0,14345,14346,7,4,0,0,14346,14347,5,95,0,0,14347,14348,7,9,0,0,14348,14349,
	7,0,0,0,14349,14350,7,18,0,0,14350,1916,1,0,0,0,14351,14352,7,7,0,0,14352,
	14353,7,6,0,0,14353,14354,7,25,0,0,14354,14355,7,4,0,0,14355,1918,1,0,0,
	0,14356,14357,7,7,0,0,14357,14358,7,11,0,0,14358,14359,5,95,0,0,14359,14360,
	7,0,0,0,14360,14361,7,20,0,0,14361,1920,1,0,0,0,14362,14363,7,7,0,0,14363,
	14364,7,11,0,0,14364,14365,7,20,0,0,14365,14366,5,95,0,0,14366,14367,7,
	1,0,0,14367,14368,7,0,0,0,14368,14369,7,4,0,0,14369,14370,7,8,0,0,14370,
	14371,7,19,0,0,14371,14372,7,12,0,0,14372,14373,7,7,0,0,14373,14374,7,17,
	0,0,14374,1922,1,0,0,0,14375,14376,7,7,0,0,14376,14377,7,11,0,0,14377,14378,
	7,20,0,0,14378,14379,5,95,0,0,14379,14380,7,12,0,0,14380,14381,7,7,0,0,
	14381,14382,7,9,0,0,14382,14383,7,6,0,0,14383,14384,7,25,0,0,14384,14385,
	5,95,0,0,14385,14386,7,16,0,0,14386,14387,7,12,0,0,14387,14388,7,11,0,0,
	14388,14389,7,4,0,0,14389,14390,7,6,0,0,14390,14391,7,3,0,0,14391,1924,
	1,0,0,0,14392,14393,7,7,0,0,14393,14394,7,11,0,0,14394,14395,7,20,0,0,14395,
	14396,5,95,0,0,14396,14397,7,12,0,0,14397,14398,7,7,0,0,14398,14399,7,9,
	0,0,14399,14400,7,6,0,0,14400,14401,7,25,0,0,14401,14402,5,95,0,0,14402,
	14403,7,5,0,0,14403,14404,7,8,0,0,14404,14405,7,0,0,0,14405,14406,7,7,0,
	0,14406,1926,1,0,0,0,14407,14408,7,7,0,0,14408,14409,7,11,0,0,14409,14410,
	7,20,0,0,14410,14411,5,95,0,0,14411,14412,7,15,0,0,14412,14413,7,3,0,0,
	14413,14414,7,6,0,0,14414,14415,7,16,0,0,14415,14416,7,6,0,0,14416,14417,
	7,4,0,0,14417,14418,7,8,0,0,14418,14419,7,19,0,0,14419,1928,1,0,0,0,14420,
	14421,7,7,0,0,14421,14422,7,11,0,0,14422,14423,7,5,0,0,14423,14424,5,95,
	0,0,14424,14425,7,8,0,0,14425,14426,7,0,0,0,14426,14427,7,11,0,0,14427,
	14428,7,6,0,0,14428,14429,7,7,0,0,14429,14430,7,9,0,0,14430,14431,7,0,0,
	0,14431,14432,7,3,0,0,14432,1930,1,0,0,0,14433,14434,7,7,0,0,14434,14435,
	7,11,0,0,14435,14436,7,5,0,0,14436,14437,5,95,0,0,14437,14438,7,8,0,0,14438,
	14439,7,19,0,0,14439,14440,7,0,0,0,14440,14441,7,3,0,0,14441,14442,7,0,
	0,0,14442,14443,7,8,0,0,14443,14444,7,4,0,0,14444,14445,7,6,0,0,14445,14446,
	7,3,0,0,14446,14447,7,5,0,0,14447,14448,7,6,0,0,14448,14449,7,4,0,0,14449,
	1932,1,0,0,0,14450,14451,7,7,0,0,14451,14452,7,11,0,0,14452,14453,7,5,0,
	0,14453,14454,5,95,0,0,14454,14455,7,8,0,0,14455,14456,7,19,0,0,14456,14457,
	7,0,0,0,14457,14458,7,3,0,0,14458,14459,7,5,0,0,14459,14460,7,6,0,0,14460,
	14461,7,4,0,0,14461,14462,5,95,0,0,14462,14463,7,9,0,0,14463,14464,7,6,
	0,0,14464,14465,7,8,0,0,14465,14466,7,11,0,0,14466,14467,5,95,0,0,14467,
	14468,7,11,0,0,14468,14469,7,6,0,0,14469,14470,7,7,0,0,14470,1934,1,0,0,
	0,14471,14472,7,7,0,0,14472,14473,7,11,0,0,14473,14474,7,5,0,0,14474,14475,
	5,95,0,0,14475,14476,7,8,0,0,14476,14477,7,19,0,0,14477,14478,7,0,0,0,14478,
	14479,7,3,0,0,14479,14480,7,5,0,0,14480,14481,7,6,0,0,14481,14482,7,4,0,
	0,14482,14483,5,95,0,0,14483,14484,7,12,0,0,14484,14485,7,9,0,0,14485,1936,
	1,0,0,0,14486,14487,7,7,0,0,14487,14488,7,11,0,0,14488,14489,7,5,0,0,14489,
	14490,5,95,0,0,14490,14491,7,8,0,0,14491,14492,7,19,0,0,14492,14493,7,0,
	0,0,14493,14494,7,3,0,0,14494,14495,7,5,0,0,14495,14496,7,6,0,0,14496,14497,
	7,4,0,0,14497,14498,5,95,0,0,14498,14499,7,7,0,0,14499,14500,7,0,0,0,14500,
	14501,7,14,0,0,14501,14502,7,6,0,0,14502,1938,1,0,0,0,14503,14504,7,7,0,
	0,14504,14505,7,11,0,0,14505,14506,7,5,0,0,14506,14507,5,95,0,0,14507,14508,
	7,8,0,0,14508,14509,7,2,0,0,14509,14510,7,14,0,0,14510,14511,7,15,0,0,14511,
	1940,1,0,0,0,14512,14513,7,7,0,0,14513,14514,7,11,0,0,14514,14515,7,5,0,
	0,14515,14516,5,95,0,0,14516,14517,7,8,0,0,14517,14518,7,10,0,0,14518,14519,
	7,3,0,0,14519,14520,7,3,0,0,14520,14521,7,6,0,0,14521,14522,7,7,0,0,14522,
	14523,7,8,0,0,14523,14524,7,18,0,0,14524,1942,1,0,0,0,14525,14526,7,7,0,
	0,14526,14527,7,11,0,0,14527,14528,7,5,0,0,14528,14529,5,95,0,0,14529,14530,
	7,9,0,0,14530,14531,7,0,0,0,14531,14532,7,4,0,0,14532,14533,7,6,0,0,14533,
	14534,5,95,0,0,14534,14535,7,16,0,0,14535,14536,7,2,0,0,14536,14537,7,3,
	0,0,14537,14538,7,14,0,0,14538,14539,7,0,0,0,14539,14540,7,4,0,0,14540,
	1944,1,0,0,0,14541,14542,7,7,0,0,14542,14543,7,11,0,0,14543,14544,7,5,0,
	0,14544,14545,5,95,0,0,14545,14546,7,9,0,0,14546,14547,7,0,0,0,14547,14548,
	7,4,0,0,14548,14549,7,6,0,0,14549,14550,5,95,0,0,14550,14551,7,11,0,0,14551,
	14552,7,0,0,0,14552,14553,7,7,0,0,14553,14554,7,17,0,0,14554,14555,7,10,
	0,0,14555,14556,7,0,0,0,14556,14557,7,17,0,0,14557,14558,7,6,0,0,14558,
	1946,1,0,0,0,14559,14560,7,7,0,0,14560,14561,7,11,0,0,14561,14562,7,5,0,
	0,14562,14563,5,95,0,0,14563,14564,7,12,0,0,14564,14565,7,7,0,0,14565,14566,
	7,12,0,0,14566,14567,7,4,0,0,14567,14568,7,8,0,0,14568,14569,7,0,0,0,14569,
	14570,7,15,0,0,14570,1948,1,0,0,0,14571,14572,7,7,0,0,14572,14573,7,11,
	0,0,14573,14574,7,5,0,0,14574,14575,5,95,0,0,14575,14576,7,12,0,0,14576,
	14577,7,5,0,0,14577,14578,7,2,0,0,14578,14579,5,95,0,0,14579,14580,7,8,
	0,0,14580,14581,7,10,0,0,14581,14582,7,3,0,0,14582,14583,7,3,0,0,14583,
	14584,7,6,0,0,14584,14585,7,7,0,0,14585,14586,7,8,0,0,14586,14587,7,18,
	0,0,14587,1950,1,0,0,0,14588,14589,7,7,0,0,14589,14590,7,11,0,0,14590,14591,
	5,95,0,0,14591,14592,7,5,0,0,14592,14593,7,20,0,0,14593,1952,1,0,0,0,14594,
	14595,7,7,0,0,14595,14596,7,11,0,0,14596,14597,7,5,0,0,14597,14598,5,95,
	0,0,14598,14599,7,11,0,0,14599,14600,7,0,0,0,14600,14601,7,7,0,0,14601,
	14602,7,17,0,0,14602,1954,1,0,0,0,14603,14604,7,7,0,0,14604,14605,7,11,
	0,0,14605,14606,7,5,0,0,14606,14607,5,95,0,0,14607,14608,7,11,0,0,14608,
	14609,7,0,0,0,14609,14610,7,7,0,0,14610,14611,7,17,0,0,14611,14612,7,10,
	0,0,14612,14613,7,0,0,0,14613,14614,7,17,0,0,14614,14615,7,6,0,0,14615,
	1956,1,0,0,0,14616,14617,7,7,0,0,14617,14618,7,11,0,0,14618,14619,7,5,0,
	0,14619,14620,5,95,0,0,14620,14621,7,11,0,0,14621,14622,7,6,0,0,14622,14623,
	7,7,0,0,14623,14624,7,17,0,0,14624,14625,7,4,0,0,14625,14626,7,19,0,0,14626,
	14627,5,95,0,0,14627,14628,7,5,0,0,14628,14629,7,6,0,0,14629,14630,7,14,
	0,0,14630,14631,7,0,0,0,14631,14632,7,7,0,0,14632,14633,7,4,0,0,14633,14634,
	7,12,0,0,14634,14635,7,8,0,0,14635,14636,7,5,0,0,14636,1958,1,0,0,0,14637,
	14638,7,7,0,0,14638,14639,7,11,0,0,14639,14640,7,5,0,0,14640,14641,5,95,
	0,0,14641,14642,7,11,0,0,14642,14643,7,2,0,0,14643,14644,7,22,0,0,14644,
	14645,7,6,0,0,14645,14646,7,3,0,0,14646,1960,1,0,0,0,14647,14648,7,7,0,
	0,14648,14649,7,11,0,0,14649,14650,7,5,0,0,14650,14651,5,95,0,0,14651,14652,
	7,7,0,0,14652,14653,7,8,0,0,14653,14654,7,19,0,0,14654,14655,7,0,0,0,14655,
	14656,7,3,0,0,14656,14657,5,95,0,0,14657,14658,7,8,0,0,14658,14659,7,2,
	0,0,14659,14660,7,7,0,0,14660,14661,7,13,0,0,14661,14662,5,95,0,0,14662,
	14663,7,6,0,0,14663,14664,7,25,0,0,14664,14665,7,8,0,0,14665,14666,7,15,
	0,0,14666,1962,1,0,0,0,14667,14668,7,7,0,0,14668,14669,7,11,0,0,14669,14670,
	7,5,0,0,14670,14671,5,95,0,0,14671,14672,7,7,0,0,14672,14673,7,10,0,0,14673,
	14674,7,14,0,0,14674,14675,7,6,0,0,14675,14676,7,3,0,0,14676,14677,7,12,
	0,0,14677,14678,7,8,0,0,14678,14679,5,95,0,0,14679,14680,7,8,0,0,14680,
	14681,7,19,0,0,14681,14682,7,0,0,0,14682,14683,7,3,0,0,14683,14684,7,0,
	0,0,14684,14685,7,8,0,0,14685,14686,7,4,0,0,14686,14687,7,6,0,0,14687,14688,
	7,3,0,0,14688,14689,7,5,0,0,14689,1964,1,0,0,0,14690,14691,7,7,0,0,14691,
	14692,7,11,0,0,14692,14693,7,5,0,0,14693,14694,5,95,0,0,14694,14695,7,5,
	0,0,14695,14696,7,2,0,0,14696,14697,7,3,0,0,14697,14698,7,4,0,0,14698,1966,
	1,0,0,0,14699,14700,7,7,0,0,14700,14701,7,11,0,0,14701,14702,7,5,0,0,14702,
	14703,7,5,0,0,14703,14704,7,2,0,0,14704,14705,7,3,0,0,14705,14706,7,4,0,
	0,14706,1968,1,0,0,0,14707,14708,7,7,0,0,14708,14709,7,11,0,0,14709,14710,
	7,5,0,0,14710,14711,5,95,0,0,14711,14712,7,5,0,0,14712,14713,7,15,0,0,14713,
	14714,7,6,0,0,14714,14715,7,8,0,0,14715,14716,7,12,0,0,14716,14717,7,0,
	0,0,14717,14718,7,11,0,0,14718,14719,5,95,0,0,14719,14720,7,8,0,0,14720,
	14721,7,19,0,0,14721,14722,7,0,0,0,14722,14723,7,3,0,0,14723,14724,7,5,
	0,0,14724,1970,1,0,0,0,14725,14726,7,7,0,0,14726,14727,7,11,0,0,14727,14728,
	7,5,0,0,14728,14729,5,95,0,0,14729,14730,7,4,0,0,14730,14731,7,6,0,0,14731,
	14732,7,3,0,0,14732,14733,7,3,0,0,14733,14734,7,12,0,0,14734,14735,7,4,
	0,0,14735,14736,7,2,0,0,14736,14737,7,3,0,0,14737,14738,7,18,0,0,14738,
	1972,1,0,0,0,14739,14740,7,7,0,0,14740,14741,7,11,0,0,14741,14742,7,5,0,
	0,14742,14743,5,95,0,0,14743,14744,7,10,0,0,14744,14745,7,15,0,0,14745,
	14746,7,15,0,0,14746,14747,7,6,0,0,14747,14748,7,3,0,0,14748,1974,1,0,0,
	0,14749,14750,7,7,0,0,14750,14751,7,2,0,0,14751,14752,5,95,0,0,14752,14753,
	7,0,0,0,14753,14754,7,8,0,0,14754,14755,7,8,0,0,14755,14756,7,6,0,0,14756,
	14757,7,5,0,0,14757,14758,7,5,0,0,14758,1976,1,0,0,0,14759,14760,7,7,0,
	0,14760,14761,7,2,0,0,14761,14762,5,95,0,0,14762,14763,7,0,0,0,14763,14764,
	7,9,0,0,14764,14765,7,0,0,0,14765,14766,7,15,0,0,14766,14767,7,4,0,0,14767,
	14768,7,12,0,0,14768,14769,7,13,0,0,14769,14770,7,6,0,0,14770,14771,5,95,
	0,0,14771,14772,7,15,0,0,14772,14773,7,11,0,0,14773,14774,7,0,0,0,14774,
	14775,7,7,0,0,14775,1978,1,0,0,0,14776,14777,7,7,0,0,14777,14778,7,2,0,
	0,14778,14779,5,95,0,0,14779,14780,7,0,0,0,14780,14781,7,7,0,0,14781,14782,
	7,5,0,0,14782,14783,7,12,0,0,14783,14784,5,95,0,0,14784,14785,7,3,0,0,14785,
	14786,7,6,0,0,14786,14787,7,0,0,0,14787,14788,7,3,0,0,14788,14789,7,8,0,
	0,14789,14790,7,19,0,0,14790,1980,1,0,0,0,14791,14792,7,7,0,0,14792,14793,
	7,2,0,0,14793,14794,7,0,0,0,14794,14795,7,15,0,0,14795,14796,7,15,0,0,14796,
	14797,7,6,0,0,14797,14798,7,7,0,0,14798,14799,7,9,0,0,14799,1982,1,0,0,
	0,14800,14801,7,7,0,0,14801,14802,7,2,0,0,14802,14803,7,0,0,0,14803,14804,
	7,3,0,0,14804,14805,7,8,0,0,14805,14806,7,19,0,0,14806,14807,7,12,0,0,14807,
	14808,7,13,0,0,14808,14809,7,6,0,0,14809,14810,7,11,0,0,14810,14811,7,2,
	0,0,14811,14812,7,17,0,0,14812,1984,1,0,0,0,14813,14814,7,7,0,0,14814,14815,
	7,2,0,0,14815,14816,7,0,0,0,14816,14817,7,10,0,0,14817,14818,7,9,0,0,14818,
	14819,7,12,0,0,14819,14820,7,4,0,0,14820,1986,1,0,0,0,14821,14822,7,7,0,
	0,14822,14823,7,2,0,0,14823,14824,5,95,0,0,14824,14825,7,0,0,0,14825,14826,
	7,10,0,0,14826,14827,7,4,0,0,14827,14828,7,2,0,0,14828,14829,5,95,0,0,14829,
	14830,7,3,0,0,14830,14831,7,6,0,0,14831,14832,7,2,0,0,14832,14833,7,15,
	0,0,14833,14834,7,4,0,0,14834,14835,7,12,0,0,14835,14836,7,14,0,0,14836,
	14837,7,12,0,0,14837,14838,7,23,0,0,14838,14839,7,6,0,0,14839,1988,1,0,
	0,0,14840,14841,7,7,0,0,14841,14842,7,2,0,0,14842,14843,5,95,0,0,14843,
	14844,7,1,0,0,14844,14845,7,0,0,0,14845,14846,7,5,0,0,14846,14847,7,6,0,
	0,14847,14848,7,4,0,0,14848,14849,7,0,0,0,14849,14850,7,1,0,0,14850,14851,
	7,11,0,0,14851,14852,7,6,0,0,14852,14853,5,95,0,0,14853,14854,7,14,0,0,
	14854,14855,7,10,0,0,14855,14856,7,11,0,0,14856,14857,7,4,0,0,14857,14858,
	7,12,0,0,14858,14859,7,14,0,0,14859,14860,7,13,0,0,14860,14861,5,95,0,0,
	14861,14862,7,3,0,0,14862,14863,7,6,0,0,14863,14864,7,22,0,0,14864,14865,
	7,3,0,0,14865,14866,7,12,0,0,14866,14867,7,4,0,0,14867,14868,7,6,0,0,14868,
	1990,1,0,0,0,14869,14870,7,7,0,0,14870,14871,7,2,0,0,14871,14872,5,95,0,
	0,14872,14873,7,1,0,0,14873,14874,7,0,0,0,14874,14875,7,4,0,0,14875,14876,
	7,8,0,0,14876,14877,7,19,0,0,14877,14878,5,95,0,0,14878,14879,7,4,0,0,14879,
	14880,7,0,0,0,14880,14881,7,1,0,0,14881,14882,7,11,0,0,14882,14883,7,6,
	0,0,14883,14884,5,95,0,0,14884,14885,7,0,0,0,14885,14886,7,8,0,0,14886,
	14887,7,8,0,0,14887,14888,7,6,0,0,14888,14889,7,5,0,0,14889,14890,7,5,0,
	0,14890,14891,5,95,0,0,14891,14892,7,1,0,0,14892,14893,7,18,0,0,14893,14894,
	5,95,0,0,14894,14895,7,3,0,0,14895,14896,7,2,0,0,14896,14897,7,22,0,0,14897,
	14898,7,12,0,0,14898,14899,7,9,0,0,14899,1992,1,0,0,0,14900,14901,7,7,0,
	0,14901,14902,7,2,0,0,14902,14903,5,95,0,0,14903,14904,7,1,0,0,14904,14905,
	7,12,0,0,14905,14906,7,7,0,0,14906,14907,7,9,0,0,14907,14908,5,95,0,0,14908,
	14909,7,0,0,0,14909,14910,7,22,0,0,14910,14911,7,0,0,0,14911,14912,7,3,
	0,0,14912,14913,7,6,0,0,14913,1994,1,0,0,0,14914,14915,7,7,0,0,14915,14916,
	7,2,0,0,14916,14917,5,95,0,0,14917,14918,7,1,0,0,14918,14919,7,10,0,0,14919,
	14920,7,16,0,0,14920,14921,7,16,0,0,14921,14922,7,6,0,0,14922,14923,7,3,
	0,0,14923,1996,1,0,0,0,14924,14925,7,7,0,0,14925,14926,7,2,0,0,14926,14927,
	7,8,0,0,14927,14928,7,0,0,0,14928,14929,7,8,0,0,14929,14930,7,19,0,0,14930,
	14931,7,6,0,0,14931,1998,1,0,0,0,14932,14933,7,7,0,0,14933,14934,7,2,0,
	0,14934,14935,5,95,0,0,14935,14936,7,8,0,0,14936,14937,7,0,0,0,14937,14938,
	7,3,0,0,14938,14939,7,4,0,0,14939,14940,7,6,0,0,14940,14941,7,5,0,0,14941,
	14942,7,12,0,0,14942,14943,7,0,0,0,14943,14944,7,7,0,0,14944,2000,1,0,0,
	0,14945,14946,7,7,0,0,14946,14947,7,2,0,0,14947,14948,5,95,0,0,14948,14949,
	7,8,0,0,14949,14950,7,19,0,0,14950,14951,7,6,0,0,14951,14952,7,8,0,0,14952,
	14953,7,21,0,0,14953,14954,5,95,0,0,14954,14955,7,0,0,0,14955,14956,7,8,
	0,0,14956,14957,7,11,0,0,14957,14958,5,95,0,0,14958,14959,7,3,0,0,14959,
	14960,7,6,0,0,14960,14961,7,22,0,0,14961,14962,7,3,0,0,14962,14963,7,12,
	0,0,14963,14964,7,4,0,0,14964,14965,7,6,0,0,14965,2002,1,0,0,0,14966,14967,
	7,7,0,0,14967,14968,7,2,0,0,14968,14969,5,95,0,0,14969,14970,7,8,0,0,14970,
	14971,7,11,0,0,14971,14972,7,10,0,0,14972,14973,7,5,0,0,14973,14974,7,4,
	0,0,14974,14975,7,6,0,0,14975,14976,7,3,0,0,14976,14977,5,95,0,0,14977,
	14978,7,1,0,0,14978,14979,7,18,0,0,14979,14980,5,95,0,0,14980,14981,7,3,
	0,0,14981,14982,7,2,0,0,14982,14983,7,22,0,0,14983,14984,7,12,0,0,14984,
	14985,7,9,0,0,14985,2004,1,0,0,0,14986,14987,7,7,0,0,14987,14988,7,2,0,
	0,14988,14989,5,95,0,0,14989,14990,7,8,0,0,14990,14991,7,11,0,0,14991,14992,
	7,10,0,0,14992,14993,7,5,0,0,14993,14994,7,4,0,0,14994,14995,7,6,0,0,14995,
	14996,7,3,0,0,14996,14997,7,12,0,0,14997,14998,7,7,0,0,14998,14999,7,17,
	0,0,14999,2006,1,0,0,0,15000,15001,7,7,0,0,15001,15002,7,2,0,0,15002,15003,
	5,95,0,0,15003,15004,7,8,0,0,15004,15005,7,2,0,0,15005,15006,7,0,0,0,15006,
	15007,7,11,0,0,15007,15008,7,6,0,0,15008,15009,7,5,0,0,15009,15010,7,8,
	0,0,15010,15011,7,6,0,0,15011,15012,5,95,0,0,15012,15013,7,5,0,0,15013,
	15014,7,24,0,0,15014,2008,1,0,0,0,15015,15016,7,7,0,0,15016,15017,7,2,0,
	0,15017,15018,5,95,0,0,15018,15019,7,8,0,0,15019,15020,7,2,0,0,15020,15021,
	7,14,0,0,15021,15022,7,14,0,0,15022,15023,7,2,0,0,15023,15024,7,7,0,0,15024,
	15025,5,95,0,0,15025,15026,7,9,0,0,15026,15027,7,0,0,0,15027,15028,7,4,
	0,0,15028,15029,7,0,0,0,15029,2010,1,0,0,0,15030,15031,7,7,0,0,15031,15032,
	7,2,0,0,15032,15033,7,8,0,0,15033,15034,7,2,0,0,15034,15035,7,14,0,0,15035,
	15036,7,15,0,0,15036,15037,7,3,0,0,15037,15038,7,6,0,0,15038,15039,7,5,
	0,0,15039,15040,7,5,0,0,15040,2012,1,0,0,0,15041,15042,7,7,0,0,15042,15043,
	7,2,0,0,15043,15044,5,95,0,0,15044,15045,7,8,0,0,15045,15046,7,2,0,0,15046,
	15047,7,7,0,0,15047,15048,7,7,0,0,15048,15049,7,6,0,0,15049,15050,7,8,0,
	0,15050,15051,7,4,0,0,15051,15052,5,95,0,0,15052,15053,7,1,0,0,15053,15054,
	7,18,0,0,15054,15055,5,95,0,0,15055,15056,7,8,0,0,15056,15057,7,1,0,0,15057,
	15058,5,95,0,0,15058,15059,7,22,0,0,15059,15060,7,19,0,0,15060,15061,7,
	3,0,0,15061,15062,5,95,0,0,15062,15063,7,2,0,0,15063,15064,7,7,0,0,15064,
	15065,7,11,0,0,15065,15066,7,18,0,0,15066,2014,1,0,0,0,15067,15068,7,7,
	0,0,15068,15069,7,2,0,0,15069,15070,5,95,0,0,15070,15071,7,8,0,0,15071,
	15072,7,2,0,0,15072,15073,7,7,0,0,15073,15074,7,7,0,0,15074,15075,7,6,0,
	0,15075,15076,7,8,0,0,15076,15077,7,4,0,0,15077,15078,5,95,0,0,15078,15079,
	7,1,0,0,15079,15080,7,18,0,0,15080,15081,5,95,0,0,15081,15082,7,8,0,0,15082,
	15083,7,2,0,0,15083,15084,7,14,0,0,15084,15085,7,1,0,0,15085,15086,7,12,
	0,0,15086,15087,7,7,0,0,15087,15088,7,6,0,0,15088,15089,5,95,0,0,15089,
	15090,7,5,0,0,15090,15091,7,22,0,0,15091,2016,1,0,0,0,15092,15093,7,7,0,
	0,15093,15094,7,2,0,0,15094,15095,5,95,0,0,15095,15096,7,8,0,0,15096,15097,
	7,2,0,0,15097,15098,7,7,0,0,15098,15099,7,7,0,0,15099,15100,7,6,0,0,15100,
	15101,7,8,0,0,15101,15102,7,4,0,0,15102,15103,5,95,0,0,15103,15104,7,1,
	0,0,15104,15105,7,18,0,0,15105,15106,5,95,0,0,15106,15107,7,8,0,0,15107,
	15108,7,2,0,0,15108,15109,7,5,0,0,15109,15110,7,4,0,0,15110,15111,5,95,
	0,0,15111,15112,7,1,0,0,15112,15113,7,0,0,0,15113,15114,7,5,0,0,15114,15115,
	7,6,0,0,15115,15116,7,9,0,0,15116,2018,1,0,0,0,15117,15118,7,7,0,0,15118,
	15119,7,2,0,0,15119,15120,5,95,0,0,15120,15121,7,8,0,0,15121,15122,7,2,
	0,0,15122,15123,7,7,0,0,15123,15124,7,7,0,0,15124,15125,7,6,0,0,15125,15126,
	7,8,0,0,15126,15127,7,4,0,0,15127,15128,5,95,0,0,15128,15129,7,1,0,0,15129,
	15130,7,18,0,0,15130,15131,5,95,0,0,15131,15132,7,6,0,0,15132,15133,7,11,
	0,0,15133,15134,7,12,0,0,15134,15135,7,14,0,0,15135,15136,5,95,0,0,15136,
	15137,7,9,0,0,15137,15138,7,10,0,0,15138,15139,7,15,0,0,15139,15140,7,5,
	0,0,15140,2020,1,0,0,0,15141,15142,7,7,0,0,15142,15143,7,2,0,0,15143,15144,
	5,95,0,0,15144,15145,7,8,0,0,15145,15146,7,2,0,0,15146,15147,7,7,0,0,15147,
	15148,7,7,0,0,15148,15149,7,6,0,0,15149,15150,7,8,0,0,15150,15151,7,4,0,
	0,15151,15152,5,95,0,0,15152,15153,7,1,0,0,15153,15154,7,18,0,0,15154,15155,
	5,95,0,0,15155,15156,7,16,0,0,15156,15157,7,12,0,0,15157,15158,7,11,0,0,
	15158,15159,7,4,0,0,15159,15160,7,6,0,0,15160,15161,7,3,0,0,15161,15162,
	7,12,0,0,15162,15163,7,7,0,0,15163,15164,7,17,0,0,15164,2022,1,0,0,0,15165,
	15166,7,7,0,0,15166,15167,7,2,0,0,15167,15168,7,8,0,0,15168,15169,7,2,0,
	0,15169,15170,7,15,0,0,15170,15171,7,18,0,0,15171,2024,1,0,0,0,15172,15173,
	7,7,0,0,15173,15174,7,2,0,0,15174,15175,5,95,0,0,15175,15176,7,8,0,0,15176,
	15177,7,2,0,0,15177,15178,7,5,0,0,15178,15179,7,4,0,0,15179,15180,5,95,
	0,0,15180,15181,7,25,0,0,15181,15182,7,14,0,0,15182,15183,7,11,0,0,15183,
	15184,5,95,0,0,15184,15185,7,24,0,0,15185,15186,7,10,0,0,15186,15187,7,
	6,0,0,15187,15188,7,3,0,0,15188,15189,7,18,0,0,15189,15190,5,95,0,0,15190,
	15191,7,3,0,0,15191,15192,7,6,0,0,15192,15193,7,22,0,0,15193,15194,7,3,
	0,0,15194,15195,7,12,0,0,15195,15196,7,4,0,0,15196,15197,7,6,0,0,15197,
	2026,1,0,0,0,15198,15199,7,7,0,0,15199,15200,7,2,0,0,15200,15201,5,95,0,
	0,15201,15202,7,8,0,0,15202,15203,7,15,0,0,15203,15204,7,10,0,0,15204,15205,
	5,95,0,0,15205,15206,7,8,0,0,15206,15207,7,2,0,0,15207,15208,7,5,0,0,15208,
	15209,7,4,0,0,15209,15210,7,12,0,0,15210,15211,7,7,0,0,15211,15212,7,17,
	0,0,15212,2028,1,0,0,0,15213,15214,7,7,0,0,15214,15215,7,2,0,0,15215,15216,
	7,8,0,0,15216,15217,7,15,0,0,15217,15218,7,10,0,0,15218,15219,5,95,0,0,
	15219,15220,7,8,0,0,15220,15221,7,2,0,0,15221,15222,7,5,0,0,15222,15223,
	7,4,0,0,15223,15224,7,12,0,0,15224,15225,7,7,0,0,15225,15226,7,17,0,0,15226,
	2030,1,0,0,0,15227,15228,7,7,0,0,15228,15229,7,2,0,0,15229,15230,7,8,0,
	0,15230,15231,7,18,0,0,15231,15232,7,8,0,0,15232,15233,7,11,0,0,15233,15234,
	7,6,0,0,15234,2032,1,0,0,0,15235,15236,7,7,0,0,15236,15237,7,2,0,0,15237,
	15238,5,95,0,0,15238,15239,7,9,0,0,15239,15240,7,0,0,0,15240,15241,7,4,
	0,0,15241,15242,7,0,0,0,15242,15243,5,95,0,0,15243,15244,7,5,0,0,15244,
	15245,7,6,0,0,15245,15246,7,8,0,0,15246,15247,7,10,0,0,15247,15248,7,3,
	0,0,15248,15249,7,12,0,0,15249,15250,7,4,0,0,15250,15251,7,18,0,0,15251,
	15252,5,95,0,0,15252,15253,7,3,0,0,15253,15254,7,6,0,0,15254,15255,7,22,
	0,0,15255,15256,7,3,0,0,15256,15257,7,12,0,0,15257,15258,7,4,0,0,15258,
	15259,7,6,0,0,15259,2034,1,0,0,0,15260,15261,7,7,0,0,15261,15262,7,2,0,
	0,15262,15263,5,95,0,0,15263,15264,7,9,0,0,15264,15265,7,6,0,0,15265,15266,
	7,8,0,0,15266,15267,7,2,0,0,15267,15268,7,3,0,0,15268,15269,7,3,0,0,15269,
	15270,7,6,0,0,15270,15271,7,11,0,0,15271,15272,7,0,0,0,15272,15273,7,4,
	0,0,15273,15274,7,6,0,0,15274,2036,1,0,0,0,15275,15276,7,7,0,0,15276,15277,
	7,2,0,0,15277,15278,7,9,0,0,15278,15279,7,6,0,0,15279,15280,7,11,0,0,15280,
	15281,7,0,0,0,15281,15282,7,18,0,0,15282,2038,1,0,0,0,15283,15284,7,7,0,
	0,15284,15285,7,2,0,0,15285,15286,5,95,0,0,15286,15287,7,9,0,0,15287,15288,
	7,2,0,0,15288,15289,7,14,0,0,15289,15290,7,0,0,0,15290,15291,7,12,0,0,15291,
	15292,7,7,0,0,15292,15293,5,95,0,0,15293,15294,7,12,0,0,15294,15295,7,7,
	0,0,15295,15296,7,9,0,0,15296,15297,7,6,0,0,15297,15298,7,25,0,0,15298,
	15299,5,95,0,0,15299,15300,7,16,0,0,15300,15301,7,12,0,0,15301,15302,7,
	11,0,0,15302,15303,7,4,0,0,15303,15304,7,6,0,0,15304,15305,7,3,0,0,15305,
	2040,1,0,0,0,15306,15307,7,7,0,0,15307,15308,7,2,0,0,15308,15309,5,95,0,
	0,15309,15310,7,9,0,0,15310,15311,7,5,0,0,15311,15312,7,4,0,0,15312,15313,
	5,95,0,0,15313,15314,7,10,0,0,15314,15315,7,15,0,0,15315,15316,7,17,0,0,
	15316,15317,7,3,0,0,15317,15318,7,0,0,0,15318,15319,7,9,0,0,15319,15320,
	7,6,0,0,15320,15321,5,95,0,0,15321,15322,7,12,0,0,15322,15323,7,7,0,0,15323,
	15324,7,5,0,0,15324,15325,7,6,0,0,15325,15326,7,3,0,0,15326,15327,7,4,0,
	0,15327,15328,5,95,0,0,15328,15329,7,8,0,0,15329,15330,7,2,0,0,15330,15331,
	7,7,0,0,15331,15332,7,13,0,0,15332,2042,1,0,0,0,15333,15334,7,7,0,0,15334,
	15335,7,2,0,0,15335,15336,5,95,0,0,15336,15337,7,6,0,0,15337,15338,7,11,
	0,0,15338,15339,7,12,0,0,15339,15340,7,14,0,0,15340,15341,5,95,0,0,15341,
	15342,7,17,0,0,15342,15343,7,3,0,0,15343,15344,7,2,0,0,15344,15345,7,10,
	0,0,15345,15346,7,15,0,0,15346,15347,7,1,0,0,15347,15348,7,18,0,0,15348,
	2044,1,0,0,0,15349,15350,7,7,0,0,15350,15351,7,2,0,0,15351,15352,5,95,0,
	0,15352,15353,7,6,0,0,15353,15354,7,11,0,0,15354,15355,7,12,0,0,15355,15356,
	7,14,0,0,15356,15357,7,12,0,0,15357,15358,7,7,0,0,15358,15359,7,0,0,0,15359,
	15360,7,4,0,0,15360,15361,7,6,0,0,15361,15362,5,95,0,0,15362,15363,7,20,
	0,0,15363,15364,7,2,0,0,15364,15365,7,12,0,0,15365,15366,7,7,0,0,15366,
	2046,1,0,0,0,15367,15368,7,7,0,0,15368,15369,7,2,0,0,15369,15370,5,95,0,
	0,15370,15371,7,6,0,0,15371,15372,7,11,0,0,15372,15373,7,12,0,0,15373,15374,
	7,14,0,0,15374,15375,7,12,0,0,15375,15376,7,7,0,0,15376,15377,7,0,0,0,15377,
	15378,7,4,0,0,15378,15379,7,6,0,0,15379,15380,5,95,0,0,15380,15381,7,2,
	0,0,15381,15382,7,1,0,0,15382,15383,7,18,0,0,15383,2048,1,0,0,0,15384,15385,
	7,7,0,0,15385,15386,7,2,0,0,15386,15387,5,95,0,0,15387,15388,7,6,0,0,15388,
	15389,7,11,0,0,15389,15390,7,12,0,0,15390,15391,7,14,0,0,15391,15392,7,
	12,0,0,15392,15393,7,7,0,0,15393,15394,7,0,0,0,15394,15395,7,4,0,0,15395,
	15396,7,6,0,0,15396,15397,5,95,0,0,15397,15398,7,2,0,0,15398,15399,7,10,
	0,0,15399,15400,7,4,0,0,15400,15401,7,6,0,0,15401,15402,7,3,0,0,15402,15403,
	5,95,0,0,15403,15404,7,20,0,0,15404,15405,7,2,0,0,15405,15406,7,12,0,0,
	15406,15407,7,7,0,0,15407,2050,1,0,0,0,15408,15409,7,7,0,0,15409,15410,
	7,2,0,0,15410,15411,7,6,0,0,15411,15412,7,7,0,0,15412,15413,7,4,0,0,15413,
	15414,7,12,0,0,15414,15415,7,4,0,0,15415,15416,7,18,0,0,15416,15417,7,6,
	0,0,15417,15418,7,5,0,0,15418,15419,7,8,0,0,15419,15420,7,0,0,0,15420,15421,
	7,15,0,0,15421,15422,7,12,0,0,15422,15423,7,7,0,0,15423,15424,7,17,0,0,
	15424,2052,1,0,0,0,15425,15426,7,7,0,0,15426,15427,7,2,0,0,15427,15428,
	5,95,0,0,15428,15429,7,6,0,0,15429,15430,7,25,0,0,15430,15431,7,15,0,0,
	15431,15432,7,0,0,0,15432,15433,7,7,0,0,15433,15434,7,9,0,0,15434,15435,
	5,95,0,0,15435,15436,7,17,0,0,15436,15437,7,5,0,0,15437,15438,7,6,0,0,15438,
	15439,7,4,0,0,15439,15440,5,95,0,0,15440,15441,7,4,0,0,15441,15442,7,2,
	0,0,15442,15443,5,95,0,0,15443,15444,7,10,0,0,15444,15445,7,7,0,0,15445,
	15446,7,12,0,0,15446,15447,7,2,0,0,15447,15448,7,7,0,0,15448,2054,1,0,0,
	0,15449,15450,7,7,0,0,15450,15451,7,2,0,0,15451,15452,5,95,0,0,15452,15453,
	7,6,0,0,15453,15454,7,25,0,0,15454,15455,7,15,0,0,15455,15456,7,0,0,0,15456,
	15457,7,7,0,0,15457,15458,7,9,0,0,15458,2056,1,0,0,0,15459,15460,7,7,0,
	0,15460,15461,7,2,0,0,15461,15462,5,95,0,0,15462,15463,7,6,0,0,15463,15464,
	7,25,0,0,15464,15465,7,15,0,0,15465,15466,7,0,0,0,15466,15467,7,7,0,0,15467,
	15468,7,9,0,0,15468,15469,5,95,0,0,15469,15470,7,4,0,0,15470,15471,7,0,
	0,0,15471,15472,7,1,0,0,15472,15473,7,11,0,0,15473,15474,7,6,0,0,15474,
	2058,1,0,0,0,15475,15476,7,7,0,0,15476,15477,7,2,0,0,15477,15478,5,95,0,
	0,15478,15479,7,16,0,0,15479,15480,7,0,0,0,15480,15481,7,8,0,0,15481,15482,
	7,4,0,0,15482,2060,1,0,0,0,15483,15484,7,7,0,0,15484,15485,7,2,0,0,15485,
	15486,5,95,0,0,15486,15487,7,16,0,0,15487,15488,7,0,0,0,15488,15489,7,8,
	0,0,15489,15490,7,4,0,0,15490,15491,7,2,0,0,15491,15492,7,3,0,0,15492,15493,
	7,12,0,0,15493,15494,7,23,0,0,15494,15495,7,6,0,0,15495,15496,5,95,0,0,
	15496,15497,7,20,0,0,15497,15498,7,2,0,0,15498,15499,7,12,0,0,15499,15500,
	7,7,0,0,15500,2062,1,0,0,0,15501,15502,7,7,0,0,15502,15503,7,2,0,0,15503,
	15504,5,95,0,0,15504,15505,7,16,0,0,15505,15506,7,12,0,0,15506,15507,7,
	11,0,0,15507,15508,7,4,0,0,15508,15509,7,6,0,0,15509,15510,7,3,0,0,15510,
	15511,7,12,0,0,15511,15512,7,7,0,0,15512,15513,7,17,0,0,15513,2064,1,0,
	0,0,15514,15515,7,7,0,0,15515,15516,7,2,0,0,15516,15517,7,16,0,0,15517,
	15518,7,2,0,0,15518,15519,7,3,0,0,15519,15520,7,8,0,0,15520,15521,7,6,0,
	0,15521,2066,1,0,0,0,15522,15523,7,7,0,0,15523,15524,7,2,0,0,15524,15525,
	5,95,0,0,15525,15526,7,16,0,0,15526,15527,7,10,0,0,15527,15528,7,11,0,0,
	15528,15529,7,11,0,0,15529,15530,5,95,0,0,15530,15531,7,2,0,0,15531,15532,
	7,10,0,0,15532,15533,7,4,0,0,15533,15534,7,6,0,0,15534,15535,7,3,0,0,15535,
	15536,5,95,0,0,15536,15537,7,20,0,0,15537,15538,7,2,0,0,15538,15539,7,12,
	0,0,15539,15540,7,7,0,0,15540,15541,5,95,0,0,15541,15542,7,4,0,0,15542,
	15543,7,2,0,0,15543,15544,5,95,0,0,15544,15545,7,2,0,0,15545,15546,7,10,
	0,0,15546,15547,7,4,0,0,15547,15548,7,6,0,0,15548,15549,7,3,0,0,15549,2068,
	1,0,0,0,15550,15551,7,7,0,0,15551,15552,7,2,0,0,15552,15553,5,95,0,0,15553,
	15554,7,17,0,0,15554,15555,7,0,0,0,15555,15556,7,4,0,0,15556,15557,7,19,
	0,0,15557,15558,7,6,0,0,15558,15559,7,3,0,0,15559,15560,5,95,0,0,15560,
	15561,7,2,0,0,15561,15562,7,15,0,0,15562,15563,7,4,0,0,15563,15564,7,12,
	0,0,15564,15565,7,14,0,0,15565,15566,7,12,0,0,15566,15567,7,23,0,0,15567,
	15568,7,6,0,0,15568,15569,7,3,0,0,15569,15570,5,95,0,0,15570,15571,7,5,
	0,0,15571,15572,7,4,0,0,15572,15573,7,0,0,0,15573,15574,7,4,0,0,15574,15575,
	7,12,0,0,15575,15576,7,5,0,0,15576,15577,7,4,0,0,15577,15578,7,12,0,0,15578,
	15579,7,8,0,0,15579,15580,7,5,0,0,15580,2070,1,0,0,0,15581,15582,7,7,0,
	0,15582,15583,7,2,0,0,15583,15584,5,95,0,0,15584,15585,7,17,0,0,15585,15586,
	7,1,0,0,15586,15587,7,18,0,0,15587,15588,5,95,0,0,15588,15589,7,15,0,0,
	15589,15590,7,10,0,0,15590,15591,7,5,0,0,15591,15592,7,19,0,0,15592,15593,
	7,9,0,0,15593,15594,7,2,0,0,15594,15595,7,22,0,0,15595,15596,7,7,0,0,15596,
	2072,1,0,0,0,15597,15598,7,7,0,0,15598,15599,7,2,0,0,15599,15600,7,17,0,
	0,15600,15601,7,10,0,0,15601,15602,7,0,0,0,15602,15603,7,3,0,0,15603,15604,
	7,0,0,0,15604,15605,7,7,0,0,15605,15606,7,4,0,0,15606,15607,7,6,0,0,15607,
	15608,7,6,0,0,15608,2074,1,0,0,0,15609,15610,7,7,0,0,15610,15611,7,2,0,
	0,15611,15612,5,95,0,0,15612,15613,7,12,0,0,15613,15614,7,7,0,0,15614,15615,
	7,9,0,0,15615,15616,7,6,0,0,15616,15617,7,25,0,0,15617,15618,5,95,0,0,15618,
	15619,7,16,0,0,15619,15620,7,16,0,0,15620,15621,7,5,0,0,15621,2076,1,0,
	0,0,15622,15623,7,7,0,0,15623,15624,7,2,0,0,15624,15625,5,95,0,0,15625,
	15626,7,12,0,0,15626,15627,7,7,0,0,15627,15628,7,9,0,0,15628,15629,7,6,
	0,0,15629,15630,7,25,0,0,15630,2078,1,0,0,0,15631,15632,7,7,0,0,15632,15633,
	7,2,0,0,15633,15634,5,95,0,0,15634,15635,7,12,0,0,15635,15636,7,7,0,0,15636,
	15637,7,9,0,0,15637,15638,7,6,0,0,15638,15639,7,25,0,0,15639,15640,5,95,
	0,0,15640,15641,7,5,0,0,15641,15642,7,5,0,0,15642,2080,1,0,0,0,15643,15644,
	7,7,0,0,15644,15645,7,2,0,0,15645,15646,5,95,0,0,15646,15647,7,12,0,0,15647,
	15648,7,7,0,0,15648,15649,7,14,0,0,15649,15650,7,6,0,0,15650,15651,7,14,
	0,0,15651,15652,7,2,0,0,15652,15653,7,3,0,0,15653,15654,7,18,0,0,15654,
	2082,1,0,0,0,15655,15656,7,7,0,0,15656,15657,7,2,0,0,15657,15658,5,95,0,
	0,15658,15659,7,12,0,0,15659,15660,7,7,0,0,15660,15661,7,14,0,0,15661,15662,
	7,6,0,0,15662,15663,7,14,0,0,15663,15664,7,2,0,0,15664,15665,7,3,0,0,15665,
	15666,7,18,0,0,15666,15667,5,95,0,0,15667,15668,7,15,0,0,15668,15669,7,
	3,0,0,15669,15670,7,10,0,0,15670,15671,7,7,0,0,15671,15672,7,12,0,0,15672,
	15673,7,7,0,0,15673,15674,7,17,0,0,15674,2084,1,0,0,0,15675,15676,7,7,0,
	0,15676,15677,7,2,0,0,15677,15678,7,21,0,0,15678,15679,7,6,0,0,15679,15680,
	7,6,0,0,15680,15681,7,15,0,0,15681,2086,1,0,0,0,15682,15683,7,7,0,0,15683,
	15684,7,2,0,0,15684,15685,5,95,0,0,15685,15686,7,11,0,0,15686,15687,7,2,
	0,0,15687,15688,7,0,0,0,15688,15689,7,9,0,0,15689,2088,1,0,0,0,15690,15691,
	7,7,0,0,15691,15692,7,2,0,0,15692,15693,7,11,0,0,15693,15694,7,2,0,0,15694,
	15695,7,8,0,0,15695,15696,7,0,0,0,15696,15697,7,11,0,0,15697,2090,1,0,0,
	0,15698,15699,7,7,0,0,15699,15700,7,2,0,0,15700,15701,7,11,0,0,15701,15702,
	7,2,0,0,15702,15703,7,17,0,0,15703,15704,7,17,0,0,15704,15705,7,12,0,0,
	15705,15706,7,7,0,0,15706,15707,7,17,0,0,15707,2092,1,0,0,0,15708,15709,
	7,7,0,0,15709,15710,7,2,0,0,15710,15711,7,14,0,0,15711,15712,7,0,0,0,15712,
	15713,7,15,0,0,15713,15714,7,15,0,0,15714,15715,7,12,0,0,15715,15716,7,
	7,0,0,15716,15717,7,17,0,0,15717,2094,1,0,0,0,15718,15719,7,7,0,0,15719,
	15720,7,2,0,0,15720,15721,7,14,0,0,15721,15722,7,0,0,0,15722,15723,7,25,
	0,0,15723,15724,7,13,0,0,15724,15725,7,0,0,0,15725,15726,7,11,0,0,15726,
	15727,7,10,0,0,15727,15728,7,6,0,0,15728,2096,1,0,0,0,15729,15730,7,7,0,
	0,15730,15731,7,2,0,0,15731,15732,5,95,0,0,15732,15733,7,14,0,0,15733,15734,
	7,6,0,0,15734,15735,7,3,0,0,15735,15736,7,17,0,0,15736,15737,7,6,0,0,15737,
	2098,1,0,0,0,15738,15739,7,7,0,0,15739,15740,7,2,0,0,15740,15741,7,14,0,
	0,15741,15742,7,12,0,0,15742,15743,7,7,0,0,15743,15744,7,12,0,0,15744,15745,
	7,14,0,0,15745,15746,7,12,0,0,15746,15747,7,23,0,0,15747,15748,7,6,0,0,
	15748,2100,1,0,0,0,15749,15750,7,7,0,0,15750,15751,7,2,0,0,15751,15752,
	7,14,0,0,15752,15753,7,12,0,0,15753,15754,7,7,0,0,15754,15755,7,13,0,0,
	15755,15756,7,0,0,0,15756,15757,7,11,0,0,15757,15758,7,10,0,0,15758,15759,
	7,6,0,0,15759,2102,1,0,0,0,15760,15761,7,7,0,0,15761,15762,7,2,0,0,15762,
	15763,5,95,0,0,15763,15764,7,14,0,0,15764,15765,7,2,0,0,15765,15766,7,9,
	0,0,15766,15767,7,6,0,0,15767,15768,7,11,0,0,15768,15769,5,95,0,0,15769,
	15770,7,15,0,0,15770,15771,7,10,0,0,15771,15772,7,5,0,0,15772,15773,7,19,
	0,0,15773,15774,5,95,0,0,15774,15775,7,3,0,0,15775,15776,7,6,0,0,15776,
	15777,7,16,0,0,15777,2104,1,0,0,0,15778,15779,7,7,0,0,15779,15780,7,2,0,
	0,15780,15781,5,95,0,0,15781,15782,7,14,0,0,15782,15783,7,2,0,0,15783,15784,
	7,7,0,0,15784,15785,7,12,0,0,15785,15786,7,4,0,0,15786,15787,7,2,0,0,15787,
	15788,7,3,0,0,15788,15789,7,12,0,0,15789,15790,7,7,0,0,15790,15791,7,17,
	0,0,15791,2106,1,0,0,0,15792,15793,7,7,0,0,15793,15794,7,2,0,0,15794,15795,
	7,14,0,0,15795,15796,7,2,0,0,15796,15797,7,7,0,0,15797,15798,7,12,0,0,15798,
	15799,7,4,0,0,15799,15800,7,2,0,0,15800,15801,7,3,0,0,15801,15802,7,12,
	0,0,15802,15803,7,7,0,0,15803,15804,7,17,0,0,15804,2108,1,0,0,0,15805,15806,
	7,7,0,0,15806,15807,7,2,0,0,15807,15808,5,95,0,0,15808,15809,7,14,0,0,15809,
	15810,7,2,0,0,15810,15811,7,7,0,0,15811,15812,7,12,0,0,15812,15813,7,4,
	0,0,15813,15814,7,2,0,0,15814,15815,7,3,0,0,15815,2110,1,0,0,0,15816,15817,
	7,7,0,0,15817,15818,7,2,0,0,15818,15819,5,95,0,0,15819,15820,7,14,0,0,15820,
	15821,7,10,0,0,15821,15822,7,11,0,0,15822,15823,7,4,0,0,15823,15824,7,12,
	0,0,15824,15825,7,14,0,0,15825,15826,7,13,0,0,15826,15827,5,95,0,0,15827,
	15828,7,3,0,0,15828,15829,7,6,0,0,15829,15830,7,22,0,0,15830,15831,7,3,
	0,0,15831,15832,7,12,0,0,15832,15833,7,4,0,0,15833,15834,7,6,0,0,15834,
	2112,1,0,0,0,15835,15836,7,7,0,0,15836,15837,7,2,0,0,15837,15838,5,95,0,
	0,15838,15839,7,7,0,0,15839,15840,7,0,0,0,15840,15841,7,4,0,0,15841,15842,
	7,12,0,0,15842,15843,7,13,0,0,15843,15844,7,6,0,0,15844,15845,5,95,0,0,
	15845,15846,7,16,0,0,15846,15847,7,10,0,0,15847,15848,7,11,0,0,15848,15849,
	7,11,0,0,15849,15850,5,95,0,0,15850,15851,7,2,0,0,15851,15852,7,10,0,0,
	15852,15853,7,4,0,0,15853,15854,7,6,0,0,15854,15855,7,3,0,0,15855,15856,
	5,95,0,0,15856,15857,7,20,0,0,15857,15858,7,2,0,0,15858,15859,7,12,0,0,
	15859,15860,7,7,0,0,15860,2114,1,0,0,0,15861,15862,7,7,0,0,15862,15863,
	7,2,0,0,15863,15864,7,7,0,0,15864,15865,7,1,0,0,15865,15866,7,11,0,0,15866,
	15867,7,2,0,0,15867,15868,7,8,0,0,15868,15869,7,21,0,0,15869,15870,7,12,
	0,0,15870,15871,7,7,0,0,15871,15872,7,17,0,0,15872,2116,1,0,0,0,15873,15874,
	7,7,0,0,15874,15875,7,2,0,0,15875,15876,7,7,0,0,15876,15877,7,6,0,0,15877,
	15878,7,9,0,0,15878,15879,7,12,0,0,15879,15880,7,4,0,0,15880,15881,7,12,
	0,0,15881,15882,7,2,0,0,15882,15883,7,7,0,0,15883,15884,7,0,0,0,15884,15885,
	7,1,0,0,15885,15886,7,11,0,0,15886,15887,7,6,0,0,15887,2118,1,0,0,0,15888,
	15889,7,7,0,0,15889,15890,7,2,0,0,15890,15891,7,7,0,0,15891,15892,7,6,0,
	0,15892,2120,1,0,0,0,15893,15894,7,7,0,0,15894,15895,7,2,0,0,15895,15896,
	5,95,0,0,15896,15897,7,7,0,0,15897,15898,7,11,0,0,15898,15899,7,20,0,0,
	15899,15900,5,95,0,0,15900,15901,7,1,0,0,15901,15902,7,0,0,0,15902,15903,
	7,4,0,0,15903,15904,7,8,0,0,15904,15905,7,19,0,0,15905,15906,7,12,0,0,15906,
	15907,7,7,0,0,15907,15908,7,17,0,0,15908,2122,1,0,0,0,15909,15910,7,7,0,
	0,15910,15911,7,2,0,0,15911,15912,5,95,0,0,15912,15913,7,7,0,0,15913,15914,
	7,11,0,0,15914,15915,7,20,0,0,15915,15916,5,95,0,0,15916,15917,7,15,0,0,
	15917,15918,7,3,0,0,15918,15919,7,6,0,0,15919,15920,7,16,0,0,15920,15921,
	7,6,0,0,15921,15922,7,4,0,0,15922,15923,7,8,0,0,15923,15924,7,19,0,0,15924,
	2124,1,0,0,0,15925,15926,7,7,0,0,15926,15927,7,2,0,0,15927,2126,1,0,0,0,
	15928,15929,7,7,0,0,15929,15930,7,2,0,0,15930,15931,7,7,0,0,15931,15932,
	7,5,0,0,15932,15933,7,8,0,0,15933,15934,7,19,0,0,15934,15935,7,6,0,0,15935,
	15936,7,14,0,0,15936,15937,7,0,0,0,15937,2128,1,0,0,0,15938,15939,7,7,0,
	0,15939,15940,7,2,0,0,15940,15941,5,95,0,0,15941,15942,7,2,0,0,15942,15943,
	7,1,0,0,15943,15944,7,20,0,0,15944,15945,7,6,0,0,15945,15946,7,8,0,0,15946,
	15947,7,4,0,0,15947,15948,5,95,0,0,15948,15949,7,11,0,0,15949,15950,7,12,
	0,0,15950,15951,7,7,0,0,15951,15952,7,21,0,0,15952,2130,1,0,0,0,15953,15954,
	7,7,0,0,15954,15955,7,2,0,0,15955,15956,7,2,0,0,15956,15957,7,3,0,0,15957,
	15958,7,9,0,0,15958,15959,7,6,0,0,15959,15960,7,3,0,0,15960,2132,1,0,0,
	0,15961,15962,7,7,0,0,15962,15963,7,2,0,0,15963,15964,5,95,0,0,15964,15965,
	7,2,0,0,15965,15966,7,3,0,0,15966,15967,7,9,0,0,15967,15968,7,6,0,0,15968,
	15969,7,3,0,0,15969,15970,5,95,0,0,15970,15971,7,3,0,0,15971,15972,7,2,
	0,0,15972,15973,7,11,0,0,15973,15974,7,11,0,0,15974,15975,7,10,0,0,15975,
	15976,7,15,0,0,15976,15977,7,5,0,0,15977,2134,1,0,0,0,15978,15979,7,7,0,
	0,15979,15980,7,2,0,0,15980,15981,5,95,0,0,15981,15982,7,2,0,0,15982,15983,
	7,10,0,0,15983,15984,7,4,0,0,15984,15985,7,6,0,0,15985,15986,7,3,0,0,15986,
	15987,5,95,0,0,15987,15988,7,20,0,0,15988,15989,7,2,0,0,15989,15990,7,12,
	0,0,15990,15991,7,7,0,0,15991,15992,5,95,0,0,15992,15993,7,4,0,0,15993,
	15994,7,2,0,0,15994,15995,5,95,0,0,15995,15996,7,0,0,0,15996,15997,7,7,
	0,0,15997,15998,7,4,0,0,15998,15999,7,12,0,0,15999,2136,1,0,0,0,16000,16001,
	7,7,0,0,16001,16002,7,2,0,0,16002,16003,5,95,0,0,16003,16004,7,2,0,0,16004,
	16005,7,10,0,0,16005,16006,7,4,0,0,16006,16007,7,6,0,0,16007,16008,7,3,
	0,0,16008,16009,5,95,0,0,16009,16010,7,20,0,0,16010,16011,7,2,0,0,16011,
	16012,7,12,0,0,16012,16013,7,7,0,0,16013,16014,5,95,0,0,16014,16015,7,4,
	0,0,16015,16016,7,2,0,0,16016,16017,5,95,0,0,16017,16018,7,12,0,0,16018,
	16019,7,7,0,0,16019,16020,7,7,0,0,16020,16021,7,6,0,0,16021,16022,7,3,0,
	0,16022,2138,1,0,0,0,16023,16024,7,7,0,0,16024,16025,7,2,0,0,16025,16026,
	7,2,0,0,16026,16027,7,13,0,0,16027,16028,7,6,0,0,16028,16029,7,3,0,0,16029,
	16030,7,3,0,0,16030,16031,7,12,0,0,16031,16032,7,9,0,0,16032,16033,7,6,
	0,0,16033,2140,1,0,0,0,16034,16035,7,7,0,0,16035,16036,7,2,0,0,16036,16037,
	5,95,0,0,16037,16038,7,15,0,0,16038,16039,7,0,0,0,16039,16040,7,3,0,0,16040,
	16041,7,0,0,0,16041,16042,7,11,0,0,16042,16043,7,11,0,0,16043,16044,7,6,
	0,0,16044,16045,7,11,0,0,16045,16046,5,95,0,0,16046,16047,7,12,0,0,16047,
	16048,7,7,0,0,16048,16049,7,9,0,0,16049,16050,7,6,0,0,16050,16051,7,25,
	0,0,16051,2142,1,0,0,0,16052,16053,7,7,0,0,16053,16054,7,2,0,0,16054,16055,
	7,15,0,0,16055,16056,7,0,0,0,16056,16057,7,3,0,0,16057,16058,7,0,0,0,16058,
	16059,7,11,0,0,16059,16060,7,11,0,0,16060,16061,7,6,0,0,16061,16062,7,11,
	0,0,16062,16063,5,95,0,0,16063,16064,7,12,0,0,16064,16065,7,7,0,0,16065,
	16066,7,9,0,0,16066,16067,7,6,0,0,16067,16068,7,25,0,0,16068,2144,1,0,0,
	0,16069,16070,7,7,0,0,16070,16071,7,2,0,0,16071,16072,5,95,0,0,16072,16073,
	7,15,0,0,16073,16074,7,0,0,0,16074,16075,7,3,0,0,16075,16076,7,0,0,0,16076,
	16077,7,11,0,0,16077,16078,7,11,0,0,16078,16079,7,6,0,0,16079,16080,7,11,
	0,0,16080,2146,1,0,0,0,16081,16082,7,7,0,0,16082,16083,7,2,0,0,16083,16084,
	7,15,0,0,16084,16085,7,0,0,0,16085,16086,7,3,0,0,16086,16087,7,0,0,0,16087,
	16088,7,11,0,0,16088,16089,7,11,0,0,16089,16090,7,6,0,0,16090,16091,7,11,
	0,0,16091,2148,1,0,0,0,16092,16093,7,7,0,0,16093,16094,7,2,0,0,16094,16095,
	5,95,0,0,16095,16096,7,15,0,0,16096,16097,7,0,0,0,16097,16098,7,3,0,0,16098,
	16099,7,4,0,0,16099,16100,7,12,0,0,16100,16101,7,0,0,0,16101,16102,7,11,
	0,0,16102,16103,5,95,0,0,16103,16104,7,8,0,0,16104,16105,7,2,0,0,16105,
	16106,7,14,0,0,16106,16107,7,14,0,0,16107,16108,7,12,0,0,16108,16109,7,
	4,0,0,16109,2150,1,0,0,0,16110,16111,7,7,0,0,16111,16112,7,2,0,0,16112,
	16113,5,95,0,0,16113,16114,7,15,0,0,16114,16115,7,0,0,0,16115,16116,7,3,
	0,0,16116,16117,7,4,0,0,16117,16118,7,12,0,0,16118,16119,7,0,0,0,16119,
	16120,7,11,0,0,16120,16121,5,95,0,0,16121,16122,7,20,0,0,16122,16123,7,
	2,0,0,16123,16124,7,12,0,0,16124,16125,7,7,0,0,16125,2152,1,0,0,0,16126,
	16127,7,7,0,0,16127,16128,7,2,0,0,16128,16129,5,95,0,0,16129,16130,7,15,
	0,0,16130,16131,7,0,0,0,16131,16132,7,3,0,0,16132,16133,7,4,0,0,16133,16134,
	7,12,0,0,16134,16135,7,0,0,0,16135,16136,7,11,0,0,16136,16137,5,95,0,0,
	16137,16138,7,3,0,0,16138,16139,7,2,0,0,16139,16140,7,11,0,0,16140,16141,
	7,11,0,0,16141,16142,7,10,0,0,16142,16143,7,15,0,0,16143,16144,5,95,0,0,
	16144,16145,7,15,0,0,16145,16146,7,10,0,0,16146,16147,7,5,0,0,16147,16148,
	7,19,0,0,16148,16149,7,9,0,0,16149,16150,7,2,0,0,16150,16151,7,22,0,0,16151,
	16152,7,7,0,0,16152,2154,1,0,0,0,16153,16154,7,7,0,0,16154,16155,7,2,0,
	0,16155,16156,7,15,0,0,16156,16157,7,0,0,0,16157,16158,7,3,0,0,16158,16159,
	7,4,0,0,16159,16160,7,12,0,0,16160,16161,7,4,0,0,16161,16162,7,12,0,0,16162,
	16163,7,2,0,0,16163,16164,7,7,0,0,16164,2156,1,0,0,0,16165,16166,7,7,0,
	0,16166,16167,7,2,0,0,16167,16168,5,95,0,0,16168,16169,7,15,0,0,16169,16170,
	7,11,0,0,16170,16171,7,0,0,0,16171,16172,7,8,0,0,16172,16173,7,6,0,0,16173,
	16174,5,95,0,0,16174,16175,7,9,0,0,16175,16176,7,12,0,0,16176,16177,7,5,
	0,0,16177,16178,7,4,0,0,16178,16179,7,12,0,0,16179,16180,7,7,0,0,16180,
	16181,7,8,0,0,16181,16182,7,4,0,0,16182,2158,1,0,0,0,16183,16184,7,7,0,
	0,16184,16185,7,2,0,0,16185,16186,5,95,0,0,16186,16187,7,15,0,0,16187,16188,
	7,11,0,0,16188,16189,7,0,0,0,16189,16190,7,8,0,0,16190,16191,7,6,0,0,16191,
	16192,5,95,0,0,16192,16193,7,17,0,0,16193,16194,7,3,0,0,16194,16195,7,2,
	0,0,16195,16196,7,10,0,0,16196,16197,7,15,0,0,16197,16198,5,95,0,0,16198,
	16199,7,1,0,0,16199,16200,7,18,0,0,16200,2160,1,0,0,0,16201,16202,7,7,0,
	0,16202,16203,7,2,0,0,16203,16204,5,95,0,0,16204,16205,7,15,0,0,16205,16206,
	7,24,0,0,16206,16207,5,95,0,0,16207,16208,7,8,0,0,16208,16209,7,2,0,0,16209,
	16210,7,7,0,0,16210,16211,7,8,0,0,16211,16212,7,10,0,0,16212,16213,7,3,
	0,0,16213,16214,7,3,0,0,16214,16215,7,6,0,0,16215,16216,7,7,0,0,16216,16217,
	7,4,0,0,16217,16218,5,95,0,0,16218,16219,7,10,0,0,16219,16220,7,7,0,0,16220,
	16221,7,12,0,0,16221,16222,7,2,0,0,16222,16223,7,7,0,0,16223,2162,1,0,0,
	0,16224,16225,7,7,0,0,16225,16226,7,2,0,0,16226,16227,5,95,0,0,16227,16228,
	7,15,0,0,16228,16229,7,24,0,0,16229,16230,5,95,0,0,16230,16231,7,14,0,0,
	16231,16232,7,0,0,0,16232,16233,7,15,0,0,16233,2164,1,0,0,0,16234,16235,
	7,7,0,0,16235,16236,7,2,0,0,16236,16237,7,15,0,0,16237,16238,7,3,0,0,16238,
	16239,7,2,0,0,16239,16240,7,14,0,0,16240,16241,7,15,0,0,16241,16242,7,4,
	0,0,16242,2166,1,0,0,0,16243,16244,7,7,0,0,16244,16245,7,2,0,0,16245,16246,
	5,95,0,0,16246,16247,7,15,0,0,16247,16248,7,24,0,0,16248,16249,5,95,0,0,
	16249,16250,7,3,0,0,16250,16251,7,6,0,0,16251,16252,7,15,0,0,16252,16253,
	7,11,0,0,16253,16254,7,12,0,0,16254,16255,7,8,0,0,16255,16256,7,0,0,0,16256,
	16257,7,4,0,0,16257,16258,7,6,0,0,16258,2168,1,0,0,0,16259,16260,7,7,0,
	0,16260,16261,7,2,0,0,16261,16262,5,95,0,0,16262,16263,7,15,0,0,16263,16264,
	7,24,0,0,16264,16265,5,95,0,0,16265,16266,7,5,0,0,16266,16267,7,21,0,0,
	16267,16268,7,6,0,0,16268,16269,7,22,0,0,16269,2170,1,0,0,0,16270,16271,
	7,7,0,0,16271,16272,7,2,0,0,16272,16273,5,95,0,0,16273,16274,7,15,0,0,16274,
	16275,7,3,0,0,16275,16276,7,10,0,0,16276,16277,7,7,0,0,16277,16278,7,6,
	0,0,16278,16279,5,95,0,0,16279,16280,7,17,0,0,16280,16281,7,5,0,0,16281,
	16282,7,6,0,0,16282,16283,7,4,0,0,16283,16284,7,5,0,0,16284,2172,1,0,0,
	0,16285,16286,7,7,0,0,16286,16287,7,2,0,0,16287,16288,5,95,0,0,16288,16289,
	7,15,0,0,16289,16290,7,10,0,0,16290,16291,7,11,0,0,16291,16292,7,11,0,0,
	16292,16293,5,95,0,0,16293,16294,7,15,0,0,16294,16295,7,3,0,0,16295,16296,
	7,6,0,0,16296,16297,7,9,0,0,16297,2174,1,0,0,0,16298,16299,7,7,0,0,16299,
	16300,7,2,0,0,16300,16301,5,95,0,0,16301,16302,7,15,0,0,16302,16303,7,10,
	0,0,16303,16304,7,5,0,0,16304,16305,7,19,0,0,16305,16306,5,95,0,0,16306,
	16307,7,15,0,0,16307,16308,7,3,0,0,16308,16309,7,6,0,0,16309,16310,7,9,
	0,0,16310,2176,1,0,0,0,16311,16312,7,7,0,0,16312,16313,7,2,0,0,16313,16314,
	5,95,0,0,16314,16315,7,15,0,0,16315,16316,7,10,0,0,16316,16317,7,5,0,0,
	16317,16318,7,19,0,0,16318,16319,5,95,0,0,16319,16320,7,5,0,0,16320,16321,
	7,10,0,0,16321,16322,7,1,0,0,16322,16323,7,24,0,0,16323,2178,1,0,0,0,16324,
	16325,7,7,0,0,16325,16326,7,2,0,0,16326,16327,5,95,0,0,16327,16328,7,15,
	0,0,16328,16329,7,25,0,0,16329,16330,5,95,0,0,16330,16331,7,16,0,0,16331,
	16332,7,0,0,0,16332,16333,7,10,0,0,16333,16334,7,11,0,0,16334,16335,7,4,
	0,0,16335,16336,5,95,0,0,16336,16337,7,4,0,0,16337,16338,7,2,0,0,16338,
	16339,7,11,0,0,16339,16340,7,6,0,0,16340,16341,7,3,0,0,16341,16342,7,0,
	0,0,16342,16343,7,7,0,0,16343,16344,7,8,0,0,16344,16345,7,6,0,0,16345,2180,
	1,0,0,0,16346,16347,7,7,0,0,16347,16348,7,2,0,0,16348,16349,5,95,0,0,16349,
	16350,7,15,0,0,16350,16351,7,25,0,0,16351,16352,5,95,0,0,16352,16353,7,
	20,0,0,16353,16354,7,2,0,0,16354,16355,7,12,0,0,16355,16356,7,7,0,0,16356,
	16357,5,95,0,0,16357,16358,7,16,0,0,16358,16359,7,12,0,0,16359,16360,7,
	11,0,0,16360,16361,7,4,0,0,16361,16362,7,6,0,0,16362,16363,7,3,0,0,16363,
	2182,1,0,0,0,16364,16365,7,7,0,0,16365,16366,7,2,0,0,16366,16367,5,95,0,
	0,16367,16368,7,24,0,0,16368,16369,7,21,0,0,16369,16370,7,7,0,0,16370,16371,
	5,95,0,0,16371,16372,7,1,0,0,16372,16373,7,10,0,0,16373,16374,7,16,0,0,
	16374,16375,7,16,0,0,16375,2184,1,0,0,0,16376,16377,7,7,0,0,16377,16378,
	7,2,0,0,16378,16379,5,95,0,0,16379,16380,7,24,0,0,16380,16381,7,10,0,0,
	16381,16382,7,6,0,0,16382,16383,7,3,0,0,16383,16384,7,18,0,0,16384,16385,
	5,95,0,0,16385,16386,7,4,0,0,16386,16387,7,3,0,0,16387,16388,7,0,0,0,16388,
	16389,7,7,0,0,16389,16390,7,5,0,0,16390,16391,7,16,0,0,16391,16392,7,2,
	0,0,16392,16393,7,3,0,0,16393,16394,7,14,0,0,16394,16395,7,0,0,0,16395,
	16396,7,4,0,0,16396,16397,7,12,0,0,16397,16398,7,2,0,0,16398,16399,7,7,
	0,0,16399,2186,1,0,0,0,16400,16401,7,7,0,0,16401,16402,7,2,0,0,16402,16403,
	5,95,0,0,16403,16404,7,3,0,0,16404,16405,7,6,0,0,16405,16406,7,16,0,0,16406,
	16407,5,95,0,0,16407,16408,7,8,0,0,16408,16409,7,0,0,0,16409,16410,7,5,
	0,0,16410,16411,7,8,0,0,16411,16412,7,0,0,0,16412,16413,7,9,0,0,16413,16414,
	7,6,0,0,16414,2188,1,0,0,0,16415,16416,7,7,0,0,16416,16417,7,2,0,0,16417,
	16418,7,3,0,0,16418,16419,7,6,0,0,16419,16420,7,11,0,0,16420,16421,7,2,
	0,0,16421,16422,7,8,0,0,16422,16423,7,0,0,0,16423,16424,7,4,0,0,16424,16425,
	7,6,0,0,16425,2190,1,0,0,0,16426,16427,7,7,0,0,16427,16428,7,2,0,0,16428,
	16429,7,3,0,0,16429,16430,7,6,0,0,16430,16431,7,11,0,0,16431,16432,7,18,
	0,0,16432,2192,1,0,0,0,16433,16434,7,7,0,0,16434,16435,7,2,0,0,16435,16436,
	7,3,0,0,16436,16437,7,6,0,0,16437,16438,7,15,0,0,16438,16439,7,0,0,0,16439,
	16440,7,12,0,0,16440,16441,7,3,0,0,16441,2194,1,0,0,0,16442,16443,7,7,0,
	0,16443,16444,7,2,0,0,16444,16445,7,3,0,0,16445,16446,7,6,0,0,16446,16447,
	7,15,0,0,16447,16448,7,11,0,0,16448,16449,7,0,0,0,16449,16450,7,18,0,0,
	16450,2196,1,0,0,0,16451,16452,7,7,0,0,16452,16453,7,2,0,0,16453,16454,
	7,3,0,0,16454,16455,7,6,0,0,16455,16456,7,5,0,0,16456,16457,7,6,0,0,16457,
	16458,7,4,0,0,16458,16459,7,11,0,0,16459,16460,7,2,0,0,16460,16461,7,17,
	0,0,16461,16462,7,5,0,0,16462,2198,1,0,0,0,16463,16464,7,7,0,0,16464,16465,
	7,2,0,0,16465,16466,5,95,0,0,16466,16467,7,3,0,0,16467,16468,7,6,0,0,16468,
	16469,7,5,0,0,16469,16470,7,10,0,0,16470,16471,7,11,0,0,16471,16472,7,4,
	0,0,16472,16473,5,95,0,0,16473,16474,7,8,0,0,16474,16475,7,0,0,0,16475,
	16476,7,8,0,0,16476,16477,7,19,0,0,16477,16478,7,6,0,0,16478,2200,1,0,0,
	0,16479,16480,7,7,0,0,16480,16481,7,2,0,0,16481,16482,7,3,0,0,16482,16483,
	7,6,0,0,16483,16484,7,13,0,0,16484,16485,7,6,0,0,16485,16486,7,3,0,0,16486,
	16487,7,5,0,0,16487,16488,7,6,0,0,16488,2202,1,0,0,0,16489,16490,7,7,0,
	0,16490,16491,7,2,0,0,16491,16492,5,95,0,0,16492,16493,7,3,0,0,16493,16494,
	7,6,0,0,16494,16495,7,22,0,0,16495,16496,7,3,0,0,16496,16497,7,12,0,0,16497,
	16498,7,4,0,0,16498,16499,7,6,0,0,16499,2204,1,0,0,0,16500,16501,7,7,0,
	0,16501,16502,7,2,0,0,16502,16503,7,3,0,0,16503,16504,7,6,0,0,16504,16505,
	7,22,0,0,16505,16506,7,3,0,0,16506,16507,7,12,0,0,16507,16508,7,4,0,0,16508,
	16509,7,6,0,0,16509,2206,1,0,0,0,16510,16511,7,7,0,0,16511,16512,7,2,0,
	0,16512,16513,7,3,0,0,16513,16514,7,14,0,0,16514,16515,7,0,0,0,16515,16516,
	7,11,0,0,16516,2208,1,0,0,0,16517,16518,7,7,0,0,16518,16519,7,2,0,0,16519,
	16520,5,95,0,0,16520,16521,7,3,0,0,16521,16522,7,2,0,0,16522,16523,7,2,
	0,0,16523,16524,7,4,0,0,16524,16525,5,95,0,0,16525,16526,7,5,0,0,16526,
	16527,7,22,0,0,16527,16528,5,95,0,0,16528,16529,7,16,0,0,16529,16530,7,
	2,0,0,16530,16531,7,3,0,0,16531,16532,5,95,0,0,16532,16533,7,11,0,0,16533,
	16534,7,2,0,0,16534,16535,7,8,0,0,16535,16536,7,0,0,0,16536,16537,7,11,
	0,0,16537,2210,1,0,0,0,16538,16539,7,7,0,0,16539,16540,7,2,0,0,16540,16541,
	7,3,0,0,16541,16542,7,2,0,0,16542,16543,7,22,0,0,16543,16544,7,9,0,0,16544,
	16545,7,6,0,0,16545,16546,7,15,0,0,16546,16547,7,6,0,0,16547,16548,7,7,
	0,0,16548,16549,7,9,0,0,16549,16550,7,6,0,0,16550,16551,7,7,0,0,16551,16552,
	7,8,0,0,16552,16553,7,12,0,0,16553,16554,7,6,0,0,16554,16555,7,5,0,0,16555,
	2212,1,0,0,0,16556,16557,7,7,0,0,16557,16558,7,2,0,0,16558,16559,7,5,0,
	0,16559,16560,7,8,0,0,16560,16561,7,19,0,0,16561,16562,7,6,0,0,16562,16563,
	7,14,0,0,16563,16564,7,0,0,0,16564,16565,7,8,0,0,16565,16566,7,19,0,0,16566,
	16567,7,6,0,0,16567,16568,7,8,0,0,16568,16569,7,21,0,0,16569,2214,1,0,0,
	0,16570,16571,7,7,0,0,16571,16572,7,2,0,0,16572,16573,7,5,0,0,16573,16574,
	7,6,0,0,16574,16575,7,17,0,0,16575,16576,7,14,0,0,16576,16577,7,6,0,0,16577,
	16578,7,7,0,0,16578,16579,7,4,0,0,16579,2216,1,0,0,0,16580,16581,7,7,0,
	0,16581,16582,7,2,0,0,16582,16583,5,95,0,0,16583,16584,7,5,0,0,16584,16585,
	7,6,0,0,16585,16586,7,14,0,0,16586,16587,7,12,0,0,16587,16588,7,20,0,0,
	16588,16589,7,2,0,0,16589,16590,7,12,0,0,16590,16591,7,7,0,0,16591,2218,
	1,0,0,0,16592,16593,7,7,0,0,16593,16594,7,2,0,0,16594,16595,5,95,0,0,16595,
	16596,7,5,0,0,16596,16597,7,6,0,0,16597,16598,7,14,0,0,16598,16599,7,12,
	0,0,16599,16600,5,95,0,0,16600,16601,7,4,0,0,16601,16602,7,2,0,0,16602,
	16603,5,95,0,0,16603,16604,7,12,0,0,16604,16605,7,7,0,0,16605,16606,7,7,
	0,0,16606,16607,7,6,0,0,16607,16608,7,3,0,0,16608,2220,1,0,0,0,16609,16610,
	7,7,0,0,16610,16611,7,2,0,0,16611,16612,5,95,0,0,16612,16613,7,5,0,0,16613,
	16614,7,6,0,0,16614,16615,7,4,0,0,16615,16616,5,95,0,0,16616,16617,7,4,
	0,0,16617,16618,7,2,0,0,16618,16619,5,95,0,0,16619,16620,7,20,0,0,16620,
	16621,7,2,0,0,16621,16622,7,12,0,0,16622,16623,7,7,0,0,16623,2222,1,0,0,
	0,16624,16625,7,7,0,0,16625,16626,7,2,0,0,16626,16627,7,5,0,0,16627,16628,
	7,2,0,0,16628,16629,7,3,0,0,16629,16630,7,4,0,0,16630,2224,1,0,0,0,16631,
	16632,7,7,0,0,16632,16633,7,2,0,0,16633,16634,5,95,0,0,16634,16635,7,5,
	0,0,16635,16636,7,24,0,0,16636,16637,7,11,0,0,16637,16638,5,95,0,0,16638,
	16639,7,4,0,0,16639,16640,7,3,0,0,16640,16641,7,0,0,0,16641,16642,7,7,0,
	0,16642,16643,7,5,0,0,16643,16644,7,11,0,0,16644,16645,7,0,0,0,16645,16646,
	7,4,0,0,16646,16647,7,12,0,0,16647,16648,7,2,0,0,16648,16649,7,7,0,0,16649,
	2226,1,0,0,0,16650,16651,7,7,0,0,16651,16652,7,2,0,0,16652,16653,5,95,0,
	0,16653,16654,7,5,0,0,16654,16655,7,24,0,0,16655,16656,7,11,0,0,16656,16657,
	5,95,0,0,16657,16658,7,4,0,0,16658,16659,7,10,0,0,16659,16660,7,7,0,0,16660,
	16661,7,6,0,0,16661,2228,1,0,0,0,16662,16663,7,7,0,0,16663,16664,7,2,0,
	0,16664,16665,5,95,0,0,16665,16666,7,5,0,0,16666,16667,7,4,0,0,16667,16668,
	7,0,0,0,16668,16669,7,3,0,0,16669,16670,5,95,0,0,16670,16671,7,4,0,0,16671,
	16672,7,3,0,0,16672,16673,7,0,0,0,16673,16674,7,7,0,0,16674,16675,7,5,0,
	0,16675,16676,7,16,0,0,16676,16677,7,2,0,0,16677,16678,7,3,0,0,16678,16679,
	7,14,0,0,16679,16680,7,0,0,0,16680,16681,7,4,0,0,16681,16682,7,12,0,0,16682,
	16683,7,2,0,0,16683,16684,7,7,0,0,16684,2230,1,0,0,0,16685,16686,7,7,0,
	0,16686,16687,7,2,0,0,16687,16688,5,95,0,0,16688,16689,7,5,0,0,16689,16690,
	7,4,0,0,16690,16691,7,0,0,0,16691,16692,7,4,0,0,16692,16693,7,6,0,0,16693,
	16694,7,14,0,0,16694,16695,7,6,0,0,16695,16696,7,7,0,0,16696,16697,7,4,
	0,0,16697,16698,5,95,0,0,16698,16699,7,24,0,0,16699,16700,7,10,0,0,16700,
	16701,7,6,0,0,16701,16702,7,10,0,0,16702,16703,7,12,0,0,16703,16704,7,7,
	0,0,16704,16705,7,17,0,0,16705,2232,1,0,0,0,16706,16707,7,7,0,0,16707,16708,
	7,2,0,0,16708,16709,5,95,0,0,16709,16710,7,5,0,0,16710,16711,7,4,0,0,16711,
	16712,7,0,0,0,16712,16713,7,4,0,0,16713,16714,7,5,0,0,16714,16715,5,95,
	0,0,16715,16716,7,17,0,0,16716,16717,7,5,0,0,16717,16718,7,6,0,0,16718,
	16719,7,4,0,0,16719,16720,7,5,0,0,16720,2234,1,0,0,0,16721,16722,7,7,0,
	0,16722,16723,7,2,0,0,16723,16724,7,5,0,0,16724,16725,7,4,0,0,16725,16726,
	7,3,0,0,16726,16727,7,12,0,0,16727,16728,7,8,0,0,16728,16729,7,4,0,0,16729,
	2236,1,0,0,0,16730,16731,7,7,0,0,16731,16732,7,2,0,0,16732,16733,5,95,0,
	0,16733,16734,7,5,0,0,16734,16735,7,10,0,0,16735,16736,7,1,0,0,16736,16737,
	7,24,0,0,16737,16738,7,10,0,0,16738,16739,7,6,0,0,16739,16740,7,3,0,0,16740,
	16741,7,18,0,0,16741,16742,5,95,0,0,16742,16743,7,15,0,0,16743,16744,7,
	3,0,0,16744,16745,7,10,0,0,16745,16746,7,7,0,0,16746,16747,7,12,0,0,16747,
	16748,7,7,0,0,16748,16749,7,17,0,0,16749,2238,1,0,0,0,16750,16751,7,7,0,
	0,16751,16752,7,2,0,0,16752,16753,5,95,0,0,16753,16754,7,5,0,0,16754,16755,
	7,10,0,0,16755,16756,7,1,0,0,16756,16757,7,5,0,0,16757,16758,7,4,0,0,16758,
	16759,7,3,0,0,16759,16760,7,1,0,0,16760,16761,5,95,0,0,16761,16762,7,15,
	0,0,16762,16763,7,0,0,0,16763,16764,7,9,0,0,16764,2240,1,0,0,0,16765,16766,
	7,7,0,0,16766,16767,7,2,0,0,16767,16768,5,95,0,0,16768,16769,7,5,0,0,16769,
	16770,7,22,0,0,16770,16771,7,0,0,0,16771,16772,7,15,0,0,16772,16773,5,95,
	0,0,16773,16774,7,20,0,0,16774,16775,7,2,0,0,16775,16776,7,12,0,0,16776,
	16777,7,7,0,0,16777,16778,5,95,0,0,16778,16779,7,12,0,0,16779,16780,7,7,
	0,0,16780,16781,7,15,0,0,16781,16782,7,10,0,0,16782,16783,7,4,0,0,16783,
	16784,7,5,0,0,16784,2242,1,0,0,0,16785,16786,7,7,0,0,16786,16787,7,2,0,
	0,16787,16788,7,5,0,0,16788,16789,7,22,0,0,16789,16790,7,12,0,0,16790,16791,
	7,4,0,0,16791,16792,7,8,0,0,16792,16793,7,19,0,0,16793,2244,1,0,0,0,16794,
	16795,7,7,0,0,16795,16796,7,2,0,0,16796,16797,5,95,0,0,16797,16798,7,4,
	0,0,16798,16799,7,0,0,0,16799,16800,7,1,0,0,16800,16801,7,11,0,0,16801,
	16802,7,6,0,0,16802,16803,5,95,0,0,16803,16804,7,11,0,0,16804,16805,7,2,
	0,0,16805,16806,7,2,0,0,16806,16807,7,21,0,0,16807,16808,7,10,0,0,16808,
	16809,7,15,0,0,16809,16810,5,95,0,0,16810,16811,7,1,0,0,16811,16812,7,18,
	0,0,16812,16813,5,95,0,0,16813,16814,7,7,0,0,16814,16815,7,11,0,0,16815,
	2246,1,0,0,0,16816,16817,7,7,0,0,16817,16818,7,2,0,0,16818,16819,5,95,0,
	0,16819,16820,7,4,0,0,16820,16821,7,6,0,0,16821,16822,7,14,0,0,16822,16823,
	7,15,0,0,16823,16824,5,95,0,0,16824,16825,7,4,0,0,16825,16826,7,0,0,0,16826,
	16827,7,1,0,0,16827,16828,7,11,0,0,16828,16829,7,6,0,0,16829,2248,1,0,0,
	0,16830,16831,7,7,0,0,16831,16832,7,2,0,0,16832,16833,7,4,0,0,16833,16834,
	7,19,0,0,16834,16835,7,12,0,0,16835,16836,7,7,0,0,16836,16837,7,17,0,0,
	16837,2250,1,0,0,0,16838,16839,7,7,0,0,16839,16840,7,2,0,0,16840,16841,
	7,4,0,0,16841,16842,7,12,0,0,16842,16843,7,16,0,0,16843,16844,7,12,0,0,
	16844,16845,7,8,0,0,16845,16846,7,0,0,0,16846,16847,7,4,0,0,16847,16848,
	7,12,0,0,16848,16849,7,2,0,0,16849,16850,7,7,0,0,16850,2252,1,0,0,0,16851,
	16852,7,7,0,0,16852,16853,7,2,0,0,16853,16854,7,4,0,0,16854,2254,1,0,0,
	0,16855,16856,7,7,0,0,16856,16857,7,2,0,0,16857,16858,5,95,0,0,16858,16859,
	7,4,0,0,16859,16860,7,3,0,0,16860,16861,7,0,0,0,16861,16862,7,7,0,0,16862,
	16863,7,5,0,0,16863,16864,7,16,0,0,16864,16865,7,2,0,0,16865,16866,7,3,
	0,0,16866,16867,7,14,0,0,16867,16868,5,95,0,0,16868,16869,7,9,0,0,16869,
	16870,7,12,0,0,16870,16871,7,5,0,0,16871,16872,7,4,0,0,16872,16873,7,12,
	0,0,16873,16874,7,7,0,0,16874,16875,7,8,0,0,16875,16876,7,4,0,0,16876,16877,
	5,95,0,0,16877,16878,7,0,0,0,16878,16879,7,17,0,0,16879,16880,7,17,0,0,
	16880,2256,1,0,0,0,16881,16882,7,7,0,0,16882,16883,7,2,0,0,16883,16884,
	5,95,0,0,16884,16885,7,10,0,0,16885,16886,7,7,0,0,16886,16887,7,7,0,0,16887,
	16888,7,6,0,0,16888,16889,7,5,0,0,16889,16890,7,4,0,0,16890,2258,1,0,0,
	0,16891,16892,7,7,0,0,16892,16893,7,2,0,0,16893,16894,5,95,0,0,16894,16895,
	7,10,0,0,16895,16896,7,5,0,0,16896,16897,7,6,0,0,16897,16898,5,95,0,0,16898,
	16899,7,8,0,0,16899,16900,7,10,0,0,16900,16901,7,1,0,0,16901,16902,7,6,
	0,0,16902,2260,1,0,0,0,16903,16904,7,7,0,0,16904,16905,7,2,0,0,16905,16906,
	5,95,0,0,16906,16907,7,10,0,0,16907,16908,7,5,0,0,16908,16909,7,6,0,0,16909,
	16910,5,95,0,0,16910,16911,7,19,0,0,16911,16912,7,0,0,0,16912,16913,7,5,
	0,0,16913,16914,7,19,0,0,16914,16915,5,95,0,0,16915,16916,7,0,0,0,16916,
	16917,7,17,0,0,16917,16918,7,17,0,0,16918,16919,7,3,0,0,16919,16920,7,6,
	0,0,16920,16921,7,17,0,0,16921,16922,7,0,0,0,16922,16923,7,4,0,0,16923,
	16924,7,12,0,0,16924,16925,7,2,0,0,16925,16926,7,7,0,0,16926,2262,1,0,0,
	0,16927,16928,7,7,0,0,16928,16929,7,2,0,0,16929,16930,5,95,0,0,16930,16931,
	7,10,0,0,16931,16932,7,5,0,0,16932,16933,7,6,0,0,16933,16934,5,95,0,0,16934,
	16935,7,19,0,0,16935,16936,7,0,0,0,16936,16937,7,5,0,0,16937,16938,7,19,
	0,0,16938,16939,5,95,0,0,16939,16940,7,17,0,0,16940,16941,7,1,0,0,16941,
	16942,7,18,0,0,16942,16943,5,95,0,0,16943,16944,7,16,0,0,16944,16945,7,
	2,0,0,16945,16946,7,3,0,0,16946,16947,5,95,0,0,16947,16948,7,15,0,0,16948,
	16949,7,10,0,0,16949,16950,7,5,0,0,16950,16951,7,19,0,0,16951,16952,7,9,
	0,0,16952,16953,7,2,0,0,16953,16954,7,22,0,0,16954,16955,7,7,0,0,16955,
	2264,1,0,0,0,16956,16957,7,7,0,0,16957,16958,7,2,0,0,16958,16959,5,95,0,
	0,16959,16960,7,10,0,0,16960,16961,7,5,0,0,16961,16962,7,6,0,0,16962,16963,
	5,95,0,0,16963,16964,7,19,0,0,16964,16965,7,0,0,0,16965,16966,7,5,0,0,16966,
	16967,7,19,0,0,16967,2266,1,0,0,0,16968,16969,7,7,0,0,16969,16970,7,2,0,
	0,16970,16971,5,95,0,0,16971,16972,7,10,0,0,16972,16973,7,5,0,0,16973,16974,
	7,6,0,0,16974,16975,5,95,0,0,16975,16976,7,12,0,0,16976,16977,7,7,0,0,16977,
	16978,7,13,0,0,16978,16979,7,12,0,0,16979,16980,7,5,0,0,16980,16981,7,12,
	0,0,16981,16982,7,1,0,0,16982,16983,7,11,0,0,16983,16984,7,6,0,0,16984,
	16985,5,95,0,0,16985,16986,7,12,0,0,16986,16987,7,7,0,0,16987,16988,7,9,
	0,0,16988,16989,7,6,0,0,16989,16990,7,25,0,0,16990,16991,7,6,0,0,16991,
	16992,7,5,0,0,16992,2268,1,0,0,0,16993,16994,7,7,0,0,16994,16995,7,2,0,
	0,16995,16996,5,95,0,0,16996,16997,7,10,0,0,16997,16998,7,5,0,0,16998,16999,
	7,6,0,0,16999,17000,5,95,0,0,17000,17001,7,14,0,0,17001,17002,7,6,0,0,17002,
	17003,7,3,0,0,17003,17004,7,17,0,0,17004,17005,7,6,0,0,17005,2270,1,0,0,
	0,17006,17007,7,7,0,0,17007,17008,7,2,0,0,17008,17009,5,95,0,0,17009,17010,
	7,10,0,0,17010,17011,7,5,0,0,17011,17012,7,6,0,0,17012,17013,5,95,0,0,17013,
	17014,7,7,0,0,17014,17015,7,11,0,0,17015,2272,1,0,0,0,17016,17017,7,7,0,
	0,17017,17018,7,2,0,0,17018,17019,5,95,0,0,17019,17020,7,10,0,0,17020,17021,
	7,5,0,0,17021,17022,7,6,0,0,17022,17023,5,95,0,0,17023,17024,7,13,0,0,17024,
	17025,7,6,0,0,17025,17026,7,8,0,0,17026,17027,7,4,0,0,17027,17028,7,2,0,
	0,17028,17029,7,3,0,0,17029,17030,5,95,0,0,17030,17031,7,0,0,0,17031,17032,
	7,17,0,0,17032,17033,7,17,0,0,17033,17034,7,3,0,0,17034,17035,7,6,0,0,17035,
	17036,7,17,0,0,17036,17037,7,0,0,0,17037,17038,7,4,0,0,17038,17039,7,12,
	0,0,17039,17040,7,2,0,0,17040,17041,7,7,0,0,17041,2274,1,0,0,0,17042,17043,
	7,7,0,0,17043,17044,7,2,0,0,17044,17045,7,13,0,0,17045,17046,7,0,0,0,17046,
	17047,7,11,0,0,17047,17048,7,12,0,0,17048,17049,7,9,0,0,17049,17050,7,0,
	0,0,17050,17051,7,4,0,0,17051,17052,7,6,0,0,17052,2276,1,0,0,0,17053,17054,
	7,7,0,0,17054,17055,7,2,0,0,17055,17056,5,95,0,0,17056,17057,7,13,0,0,17057,
	17058,7,6,0,0,17058,17059,7,8,0,0,17059,17060,7,4,0,0,17060,17061,7,2,0,
	0,17061,17062,7,3,0,0,17062,17063,5,95,0,0,17063,17064,7,4,0,0,17064,17065,
	7,3,0,0,17065,17066,7,0,0,0,17066,17067,7,7,0,0,17067,17068,7,5,0,0,17068,
	17069,7,16,0,0,17069,17070,7,2,0,0,17070,17071,7,3,0,0,17071,17072,7,14,
	0,0,17072,17073,5,95,0,0,17073,17074,7,9,0,0,17074,17075,7,12,0,0,17075,
	17076,7,14,0,0,17076,17077,7,5,0,0,17077,2278,1,0,0,0,17078,17079,7,7,0,
	0,17079,17080,7,2,0,0,17080,17081,5,95,0,0,17081,17082,7,13,0,0,17082,17083,
	7,6,0,0,17083,17084,7,8,0,0,17084,17085,7,4,0,0,17085,17086,7,2,0,0,17086,
	17087,7,3,0,0,17087,17088,5,95,0,0,17088,17089,7,4,0,0,17089,17090,7,3,
	0,0,17090,17091,7,0,0,0,17091,17092,7,7,0,0,17092,17093,7,5,0,0,17093,17094,
	7,16,0,0,17094,17095,7,2,0,0,17095,17096,7,3,0,0,17096,17097,7,14,0,0,17097,
	17098,5,95,0,0,17098,17099,7,16,0,0,17099,17100,7,0,0,0,17100,17101,7,8,
	0,0,17101,17102,7,4,0,0,17102,2280,1,0,0,0,17103,17104,7,7,0,0,17104,17105,
	7,2,0,0,17105,17106,5,95,0,0,17106,17107,7,13,0,0,17107,17108,7,6,0,0,17108,
	17109,7,8,0,0,17109,17110,7,4,0,0,17110,17111,7,2,0,0,17111,17112,7,3,0,
	0,17112,17113,5,95,0,0,17113,17114,7,4,0,0,17114,17115,7,3,0,0,17115,17116,
	7,0,0,0,17116,17117,7,7,0,0,17117,17118,7,5,0,0,17118,17119,7,16,0,0,17119,
	17120,7,2,0,0,17120,17121,7,3,0,0,17121,17122,7,14,0,0,17122,2282,1,0,0,
	0,17123,17124,7,7,0,0,17124,17125,7,2,0,0,17125,17126,7,22,0,0,17126,17127,
	7,0,0,0,17127,17128,7,12,0,0,17128,17129,7,4,0,0,17129,2284,1,0,0,0,17130,
	17131,7,7,0,0,17131,17132,7,2,0,0,17132,17133,5,95,0,0,17133,17134,7,25,
	0,0,17134,17135,7,9,0,0,17135,17136,7,1,0,0,17136,17137,5,95,0,0,17137,
	17138,7,16,0,0,17138,17139,7,0,0,0,17139,17140,7,5,0,0,17140,17141,7,4,
	0,0,17141,17142,7,15,0,0,17142,17143,7,0,0,0,17143,17144,7,4,0,0,17144,
	17145,7,19,0,0,17145,17146,5,95,0,0,17146,17147,7,12,0,0,17147,17148,7,
	7,0,0,17148,17149,7,5,0,0,17149,17150,7,6,0,0,17150,17151,7,3,0,0,17151,
	17152,7,4,0,0,17152,2286,1,0,0,0,17153,17154,7,7,0,0,17154,17155,7,2,0,
	0,17155,17156,5,95,0,0,17156,17157,7,25,0,0,17157,17158,7,14,0,0,17158,
	17159,7,11,0,0,17159,17160,5,95,0,0,17160,17161,7,9,0,0,17161,17162,7,14,
	0,0,17162,17163,7,11,0,0,17163,17164,5,95,0,0,17164,17165,7,3,0,0,17165,
	17166,7,6,0,0,17166,17167,7,22,0,0,17167,17168,7,3,0,0,17168,17169,7,12,
	0,0,17169,17170,7,4,0,0,17170,17171,7,6,0,0,17171,2288,1,0,0,0,17172,17173,
	7,7,0,0,17173,17174,7,2,0,0,17174,17175,5,95,0,0,17175,17176,7,25,0,0,17176,
	17177,7,14,0,0,17177,17178,7,11,0,0,17178,17179,7,12,0,0,17179,17180,7,
	7,0,0,17180,17181,7,9,0,0,17181,17182,7,6,0,0,17182,17183,7,25,0,0,17183,
	17184,5,95,0,0,17184,17185,7,3,0,0,17185,17186,7,6,0,0,17186,17187,7,22,
	0,0,17187,17188,7,3,0,0,17188,17189,7,12,0,0,17189,17190,7,4,0,0,17190,
	17191,7,6,0,0,17191,17192,5,95,0,0,17192,17193,7,12,0,0,17193,17194,7,7,
	0,0,17194,17195,5,95,0,0,17195,17196,7,5,0,0,17196,17197,7,6,0,0,17197,
	17198,7,11,0,0,17198,17199,7,6,0,0,17199,17200,7,8,0,0,17200,17201,7,4,
	0,0,17201,2290,1,0,0,0,17202,17203,7,7,0,0,17203,17204,7,2,0,0,17204,17205,
	5,95,0,0,17205,17206,7,25,0,0,17206,17207,7,14,0,0,17207,17208,7,11,0,0,
	17208,17209,7,12,0,0,17209,17210,7,7,0,0,17210,17211,7,9,0,0,17211,17212,
	7,6,0,0,17212,17213,7,25,0,0,17213,17214,5,95,0,0,17214,17215,7,3,0,0,17215,
	17216,7,6,0,0,17216,17217,7,22,0,0,17217,17218,7,3,0,0,17218,17219,7,12,
	0,0,17219,17220,7,4,0,0,17220,17221,7,6,0,0,17221,2292,1,0,0,0,17222,17223,
	7,7,0,0,17223,17224,7,2,0,0,17224,17225,5,95,0,0,17225,17226,7,25,0,0,17226,
	17227,7,14,0,0,17227,17228,7,11,0,0,17228,17229,5,95,0,0,17229,17230,7,
	24,0,0,17230,17231,7,10,0,0,17231,17232,7,6,0,0,17232,17233,7,3,0,0,17233,
	17234,7,18,0,0,17234,17235,5,95,0,0,17235,17236,7,3,0,0,17236,17237,7,6,
	0,0,17237,17238,7,22,0,0,17238,17239,7,3,0,0,17239,17240,7,12,0,0,17240,
	17241,7,4,0,0,17241,17242,7,6,0,0,17242,2294,1,0,0,0,17243,17244,7,7,0,
	0,17244,17245,7,2,0,0,17245,17246,5,95,0,0,17246,17247,7,23,0,0,17247,17248,
	7,2,0,0,17248,17249,7,7,0,0,17249,17250,7,6,0,0,17250,17251,7,14,0,0,17251,
	17252,7,0,0,0,17252,17253,7,15,0,0,17253,2296,1,0,0,0,17254,17255,7,7,0,
	0,17255,17256,7,4,0,0,17256,17257,7,19,0,0,17257,17258,5,95,0,0,17258,17259,
	7,13,0,0,17259,17260,7,0,0,0,17260,17261,7,11,0,0,17261,17262,7,10,0,0,
	17262,17263,7,6,0,0,17263,2298,1,0,0,0,17264,17265,7,7,0,0,17265,17266,
	7,10,0,0,17266,17267,7,11,0,0,17267,17268,7,11,0,0,17268,17269,7,12,0,0,
	17269,17270,7,16,0,0,17270,2300,1,0,0,0,17271,17272,7,7,0,0,17272,17273,
	7,10,0,0,17273,17274,7,11,0,0,17274,17275,7,11,0,0,17275,2302,1,0,0,0,17276,
	17277,7,7,0,0,17277,17278,7,10,0,0,17278,17279,7,11,0,0,17279,17280,7,11,
	0,0,17280,17281,7,5,0,0,17281,2304,1,0,0,0,17282,17283,7,7,0,0,17283,17284,
	7,10,0,0,17284,17285,7,14,0,0,17285,17286,7,1,0,0,17286,17287,7,6,0,0,17287,
	17288,7,3,0,0,17288,2306,1,0,0,0,17289,17290,7,7,0,0,17290,17291,7,10,0,
	0,17291,17292,7,14,0,0,17292,17293,7,6,0,0,17293,17294,7,3,0,0,17294,17295,
	7,12,0,0,17295,17296,7,8,0,0,17296,2308,1,0,0,0,17297,17298,7,7,0,0,17298,
	17299,7,10,0,0,17299,17300,7,14,0,0,17300,17301,5,95,0,0,17301,17302,7,
	12,0,0,17302,17303,7,7,0,0,17303,17304,7,9,0,0,17304,17305,7,6,0,0,17305,
	17306,7,25,0,0,17306,17307,5,95,0,0,17307,17308,7,21,0,0,17308,17309,7,
	6,0,0,17309,17310,7,18,0,0,17310,17311,7,5,0,0,17311,2310,1,0,0,0,17312,
	17313,7,7,0,0,17313,17314,7,10,0,0,17314,17315,7,14,0,0,17315,17316,7,4,
	0,0,17316,17317,7,2,0,0,17317,17318,7,9,0,0,17318,17319,7,5,0,0,17319,17320,
	7,12,0,0,17320,17321,7,7,0,0,17321,17322,7,4,0,0,17322,17323,7,6,0,0,17323,
	17324,7,3,0,0,17324,17325,7,13,0,0,17325,17326,7,0,0,0,17326,17327,7,11,
	0,0,17327,2312,1,0,0,0,17328,17329,7,7,0,0,17329,17330,7,10,0,0,17330,17331,
	7,14,0,0,17331,17332,7,4,0,0,17332,17333,7,2,0,0,17333,17334,7,18,0,0,17334,
	17335,7,14,0,0,17335,17336,7,12,0,0,17336,17337,7,7,0,0,17337,17338,7,4,
	0,0,17338,17339,7,6,0,0,17339,17340,7,3,0,0,17340,17341,7,13,0,0,17341,
	17342,7,0,0,0,17342,17343,7,11,0,0,17343,2314,1,0,0,0,17344,17345,7,7,0,
	0,17345,17346,7,13,0,0,17346,17347,7,0,0,0,17347,17348,7,3,0,0,17348,17349,
	7,8,0,0,17349,17350,7,19,0,0,17350,17351,7,0,0,0,17351,17352,7,3,0,0,17352,
	17353,5,50,0,0,17353,2316,1,0,0,0,17354,17355,7,7,0,0,17355,17356,7,13,
	0,0,17356,17357,7,11,0,0,17357,17358,5,50,0,0,17358,2318,1,0,0,0,17359,
	17360,7,2,0,0,17360,17361,7,1,0,0,17361,17362,7,20,0,0,17362,17363,7,6,
	0,0,17363,17364,7,8,0,0,17364,17365,7,4,0,0,17365,17366,5,50,0,0,17366,
	17367,7,25,0,0,17367,17368,7,14,0,0,17368,17369,7,11,0,0,17369,2320,1,0,
	0,0,17370,17371,7,2,0,0,17371,17372,7,1,0,0,17372,17373,7,20,0,0,17373,
	17374,7,6,0,0,17374,17375,7,8,0,0,17375,17376,7,4,0,0,17376,2322,1,0,0,
	0,17377,17378,7,2,0,0,17378,17379,7,1,0,0,17379,17380,7,20,0,0,17380,17381,
	5,95,0,0,17381,17382,7,12,0,0,17382,17383,7,9,0,0,17383,2324,1,0,0,0,17384,
	17385,7,2,0,0,17385,17386,7,1,0,0,17386,17387,7,20,0,0,17387,17388,7,7,
	0,0,17388,17389,7,2,0,0,17389,2326,1,0,0,0,17390,17391,7,2,0,0,17391,17392,
	7,1,0,0,17392,17393,7,20,0,0,17393,17394,7,7,0,0,17394,17395,7,2,0,0,17395,
	17396,5,95,0,0,17396,17397,7,3,0,0,17397,17398,7,6,0,0,17398,17399,7,10,
	0,0,17399,17400,7,5,0,0,17400,17401,7,6,0,0,17401,2328,1,0,0,0,17402,17403,
	7,2,0,0,17403,17404,7,8,0,0,17404,17405,7,8,0,0,17405,17406,7,10,0,0,17406,
	17407,7,3,0,0,17407,17408,7,6,0,0,17408,17409,7,7,0,0,17409,17410,7,8,0,
	0,17410,17411,7,6,0,0,17411,17412,7,5,0,0,17412,2330,1,0,0,0,17413,17414,
	7,2,0,0,17414,17415,7,16,0,0,17415,17416,7,16,0,0,17416,17417,7,11,0,0,
	17417,17418,7,12,0,0,17418,17419,7,7,0,0,17419,17420,7,6,0,0,17420,2332,
	1,0,0,0,17421,17422,7,2,0,0,17422,17423,7,16,0,0,17423,17424,7,16,0,0,17424,
	2334,1,0,0,0,17425,17426,7,2,0,0,17426,17427,7,16,0,0,17427,17428,7,16,
	0,0,17428,17429,7,5,0,0,17429,17430,7,6,0,0,17430,17431,7,4,0,0,17431,2336,
	1,0,0,0,17432,17433,7,2,0,0,17433,17434,7,16,0,0,17434,2338,1,0,0,0,17435,
	17436,7,2,0,0,17436,17437,7,12,0,0,17437,17438,7,9,0,0,17438,17439,7,12,
	0,0,17439,17440,7,7,0,0,17440,17441,7,9,0,0,17441,17442,7,6,0,0,17442,17443,
	7,25,0,0,17443,2340,1,0,0,0,17444,17445,7,2,0,0,17445,17446,7,12,0,0,17446,
	17447,7,9,0,0,17447,2342,1,0,0,0,17448,17449,7,2,0,0,17449,17450,7,11,0,
	0,17450,17451,7,0,0,0,17451,17452,7,15,0,0,17452,2344,1,0,0,0,17453,17454,
	7,2,0,0,17454,17455,7,11,0,0,17455,17456,7,9,0,0,17456,2346,1,0,0,0,17457,
	17458,7,2,0,0,17458,17459,7,11,0,0,17459,17460,7,9,0,0,17460,17461,5,95,
	0,0,17461,17462,7,15,0,0,17462,17463,7,10,0,0,17463,17464,7,5,0,0,17464,
	17465,7,19,0,0,17465,17466,5,95,0,0,17466,17467,7,15,0,0,17467,17468,7,
	3,0,0,17468,17469,7,6,0,0,17469,17470,7,9,0,0,17470,2348,1,0,0,0,17471,
	17472,7,2,0,0,17472,17473,7,11,0,0,17473,17474,7,5,0,0,17474,2350,1,0,0,
	0,17475,17476,7,2,0,0,17476,17477,7,11,0,0,17477,17478,7,4,0,0,17478,17479,
	7,15,0,0,17479,2352,1,0,0,0,17480,17481,7,2,0,0,17481,17482,7,14,0,0,17482,
	17483,7,12,0,0,17483,17484,7,4,0,0,17484,2354,1,0,0,0,17485,17486,7,2,0,
	0,17486,17487,7,7,0,0,17487,17488,7,6,0,0,17488,2356,1,0,0,0,17489,17490,
	7,2,0,0,17490,17491,7,7,0,0,17491,17492,7,11,0,0,17492,17493,7,12,0,0,17493,
	17494,7,7,0,0,17494,17495,7,6,0,0,17495,2358,1,0,0,0,17496,17497,7,2,0,
	0,17497,17498,7,7,0,0,17498,17499,7,11,0,0,17499,17500,7,12,0,0,17500,17501,
	7,7,0,0,17501,17502,7,6,0,0,17502,17503,7,11,0,0,17503,17504,7,2,0,0,17504,
	17505,7,17,0,0,17505,2360,1,0,0,0,17506,17507,7,2,0,0,17507,17508,7,7,0,
	0,17508,17509,7,11,0,0,17509,17510,7,18,0,0,17510,2362,1,0,0,0,17511,17512,
	7,2,0,0,17512,17513,7,7,0,0,17513,2364,1,0,0,0,17514,17515,7,2,0,0,17515,
	17516,7,15,0,0,17516,17517,7,0,0,0,17517,17518,7,24,0,0,17518,17519,7,10,
	0,0,17519,17520,7,6,0,0,17520,2366,1,0,0,0,17521,17522,7,2,0,0,17522,17523,
	7,15,0,0,17523,17524,7,0,0,0,17524,17525,7,24,0,0,17525,17526,7,10,0,0,
	17526,17527,7,6,0,0,17527,17528,5,95,0,0,17528,17529,7,4,0,0,17529,17530,
	7,3,0,0,17530,17531,7,0,0,0,17531,17532,7,7,0,0,17532,17533,7,5,0,0,17533,
	17534,7,16,0,0,17534,17535,7,2,0,0,17535,17536,7,3,0,0,17536,17537,7,14,
	0,0,17537,2368,1,0,0,0,17538,17539,7,2,0,0,17539,17540,7,15,0,0,17540,17541,
	7,0,0,0,17541,17542,7,24,0,0,17542,17543,7,10,0,0,17543,17544,7,6,0,0,17544,
	17545,5,95,0,0,17545,17546,7,25,0,0,17546,17547,7,8,0,0,17547,17548,7,0,
	0,0,17548,17549,7,7,0,0,17549,17550,7,2,0,0,17550,17551,7,7,0,0,17551,17552,
	7,12,0,0,17552,17553,7,8,0,0,17553,17554,7,0,0,0,17554,17555,7,11,0,0,17555,
	2370,1,0,0,0,17556,17557,7,2,0,0,17557,17558,7,15,0,0,17558,17559,7,8,0,
	0,17559,17560,7,2,0,0,17560,17561,7,9,0,0,17561,17562,7,6,0,0,17562,2372,
	1,0,0,0,17563,17564,7,2,0,0,17564,17565,7,15,0,0,17565,17566,7,6,0,0,17566,
	17567,7,7,0,0,17567,2374,1,0,0,0,17568,17569,7,2,0,0,17569,17570,7,15,0,
	0,17570,17571,7,6,0,0,17571,17572,7,3,0,0,17572,17573,7,0,0,0,17573,17574,
	7,4,0,0,17574,17575,7,12,0,0,17575,17576,7,2,0,0,17576,17577,7,7,0,0,17577,
	17578,7,5,0,0,17578,2376,1,0,0,0,17579,17580,7,2,0,0,17580,17581,7,15,0,
	0,17581,17582,7,6,0,0,17582,17583,7,3,0,0,17583,17584,7,0,0,0,17584,17585,
	7,4,0,0,17585,17586,7,2,0,0,17586,17587,7,3,0,0,17587,2378,1,0,0,0,17588,
	17589,7,2,0,0,17589,17590,7,15,0,0,17590,17591,7,4,0,0,17591,17592,5,95,
	0,0,17592,17593,7,6,0,0,17593,17594,7,5,0,0,17594,17595,7,4,0,0,17595,17596,
	7,12,0,0,17596,17597,7,14,0,0,17597,17598,7,0,0,0,17598,17599,7,4,0,0,17599,
	17600,7,6,0,0,17600,2380,1,0,0,0,17601,17602,7,2,0,0,17602,17603,7,15,0,
	0,17603,17604,7,4,0,0,17604,17605,7,12,0,0,17605,17606,7,14,0,0,17606,17607,
	7,0,0,0,17607,17608,7,11,0,0,17608,2382,1,0,0,0,17609,17610,7,2,0,0,17610,
	17611,7,15,0,0,17611,17612,7,4,0,0,17612,17613,7,12,0,0,17613,17614,7,14,
	0,0,17614,17615,7,12,0,0,17615,17616,7,23,0,0,17616,17617,7,6,0,0,17617,
	2384,1,0,0,0,17618,17619,7,2,0,0,17619,17620,7,15,0,0,17620,17621,7,4,0,
	0,17621,17622,7,12,0,0,17622,17623,7,14,0,0,17623,17624,7,12,0,0,17624,
	17625,7,23,0,0,17625,17626,7,6,0,0,17626,17627,7,3,0,0,17627,17628,5,95,
	0,0,17628,17629,7,16,0,0,17629,17630,7,6,0,0,17630,17631,7,0,0,0,17631,
	17632,7,4,0,0,17632,17633,7,10,0,0,17633,17634,7,3,0,0,17634,17635,7,6,
	0,0,17635,17636,7,5,0,0,17636,17637,5,95,0,0,17637,17638,7,6,0,0,17638,
	17639,7,7,0,0,17639,17640,7,0,0,0,17640,17641,7,1,0,0,17641,17642,7,11,
	0,0,17642,17643,7,6,0,0,17643,2386,1,0,0,0,17644,17645,7,2,0,0,17645,17646,
	7,15,0,0,17646,17647,7,4,0,0,17647,17648,7,12,0,0,17648,17649,7,14,0,0,
	17649,17650,7,12,0,0,17650,17651,7,23,0,0,17651,17652,7,6,0,0,17652,17653,
	7,3,0,0,17653,17654,5,95,0,0,17654,17655,7,17,0,0,17655,17656,7,2,0,0,17656,
	17657,7,0,0,0,17657,17658,7,11,0,0,17658,2388,1,0,0,0,17659,17660,7,2,0,
	0,17660,17661,7,15,0,0,17661,17662,7,4,0,0,17662,17663,7,12,0,0,17663,17664,
	7,2,0,0,17664,17665,7,7,0,0,17665,2390,1,0,0,0,17666,17667,7,2,0,0,17667,
	17668,7,15,0,0,17668,17669,7,4,0,0,17669,17670,5,95,0,0,17670,17671,7,15,
	0,0,17671,17672,7,0,0,0,17672,17673,7,3,0,0,17673,17674,7,0,0,0,17674,17675,
	7,14,0,0,17675,2392,1,0,0,0,17676,17677,7,2,0,0,17677,17678,7,3,0,0,17678,
	17679,7,0,0,0,17679,17680,5,95,0,0,17680,17681,7,1,0,0,17681,17682,7,3,
	0,0,17682,17683,7,0,0,0,17683,17684,7,7,0,0,17684,17685,7,8,0,0,17685,17686,
	7,19,0,0,17686,2394,1,0,0,0,17687,17688,7,2,0,0,17688,17689,7,3,0,0,17689,
	17690,7,0,0,0,17690,17691,5,95,0,0,17691,17692,7,8,0,0,17692,17693,7,19,
	0,0,17693,17694,7,6,0,0,17694,17695,7,8,0,0,17695,17696,7,21,0,0,17696,
	17697,5,95,0,0,17697,17698,7,0,0,0,17698,17699,7,8,0,0,17699,17700,7,11,
	0,0,17700,2396,1,0,0,0,17701,17702,7,2,0,0,17702,17703,7,3,0,0,17703,17704,
	7,0,0,0,17704,17705,5,95,0,0,17705,17706,7,8,0,0,17706,17707,7,19,0,0,17707,
	17708,7,6,0,0,17708,17709,7,8,0,0,17709,17710,7,21,0,0,17710,17711,5,95,
	0,0,17711,17712,7,15,0,0,17712,17713,7,3,0,0,17713,17714,7,12,0,0,17714,
	17715,7,13,0,0,17715,17716,7,12,0,0,17716,17717,7,11,0,0,17717,17718,7,
	6,0,0,17718,17719,7,17,0,0,17719,17720,7,6,0,0,17720,2398,1,0,0,0,17721,
	17722,7,2,0,0,17722,17723,7,3,0,0,17723,17724,7,0,0,0,17724,17725,5,95,
	0,0,17725,17726,7,8,0,0,17726,17727,7,11,0,0,17727,17728,7,10,0,0,17728,
	17729,7,5,0,0,17729,17730,7,4,0,0,17730,17731,7,6,0,0,17731,17732,7,3,0,
	0,17732,17733,7,12,0,0,17733,17734,7,7,0,0,17734,17735,7,17,0,0,17735,2400,
	1,0,0,0,17736,17737,7,2,0,0,17737,17738,7,3,0,0,17738,17739,7,0,0,0,17739,
	17740,7,9,0,0,17740,17741,7,0,0,0,17741,17742,7,4,0,0,17742,17743,7,0,0,
	0,17743,2402,1,0,0,0,17744,17745,7,2,0,0,17745,17746,7,3,0,0,17746,17747,
	7,0,0,0,17747,17748,7,9,0,0,17748,17749,7,6,0,0,17749,17750,7,1,0,0,17750,
	17751,7,10,0,0,17751,17752,7,17,0,0,17752,2404,1,0,0,0,17753,17754,7,2,
	0,0,17754,17755,7,3,0,0,17755,17756,7,0,0,0,17756,17757,5,95,0,0,17757,
	17758,7,9,0,0,17758,17759,7,5,0,0,17759,17760,7,4,0,0,17760,17761,5,95,
	0,0,17761,17762,7,0,0,0,17762,17763,7,16,0,0,17763,17764,7,16,0,0,17764,
	17765,7,6,0,0,17765,17766,7,8,0,0,17766,17767,7,4,0,0,17767,17768,7,6,0,
	0,17768,17769,7,9,0,0,17769,2406,1,0,0,0,17770,17771,7,2,0,0,17771,17772,
	7,3,0,0,17772,17773,7,0,0,0,17773,17774,5,95,0,0,17774,17775,7,9,0,0,17775,
	17776,7,5,0,0,17776,17777,7,4,0,0,17777,17778,5,95,0,0,17778,17779,7,8,
	0,0,17779,17780,7,2,0,0,17780,17781,7,7,0,0,17781,17782,7,13,0,0,17782,
	17783,7,6,0,0,17783,17784,7,3,0,0,17784,17785,7,4,0,0,17785,2408,1,0,0,
	0,17786,17787,7,2,0,0,17787,17788,7,3,0,0,17788,17789,7,0,0,0,17789,17790,
	5,95,0,0,17790,17791,7,9,0,0,17791,17792,7,5,0,0,17792,17793,7,4,0,0,17793,
	17794,5,95,0,0,17794,17795,7,6,0,0,17795,17796,7,3,0,0,17796,17797,7,3,
	0,0,17797,17798,7,2,0,0,17798,17799,7,3,0,0,17799,2410,1,0,0,0,17800,17801,
	7,2,0,0,17801,17802,7,3,0,0,17802,17803,7,0,0,0,17803,17804,5,95,0,0,17804,
	17805,7,17,0,0,17805,17806,7,6,0,0,17806,17807,7,4,0,0,17807,17808,5,95,
	0,0,17808,17809,7,0,0,0,17809,17810,7,8,0,0,17810,17811,7,11,0,0,17811,
	17812,7,12,0,0,17812,17813,7,9,0,0,17813,17814,7,5,0,0,17814,2412,1,0,0,
	0,17815,17816,7,2,0,0,17816,17817,7,3,0,0,17817,17818,7,0,0,0,17818,17819,
	5,95,0,0,17819,17820,7,17,0,0,17820,17821,7,6,0,0,17821,17822,7,4,0,0,17822,
	17823,5,95,0,0,17823,17824,7,15,0,0,17824,17825,7,3,0,0,17825,17826,7,12,
	0,0,17826,17827,7,13,0,0,17827,17828,7,12,0,0,17828,17829,7,11,0,0,17829,
	17830,7,6,0,0,17830,17831,7,17,0,0,17831,17832,7,6,0,0,17832,17833,7,5,
	0,0,17833,2414,1,0,0,0,17834,17835,7,2,0,0,17835,17836,7,3,0,0,17836,17837,
	7,0,0,0,17837,17838,5,95,0,0,17838,17839,7,19,0,0,17839,17840,7,0,0,0,17840,
	17841,7,5,0,0,17841,17842,7,19,0,0,17842,2416,1,0,0,0,17843,17844,7,2,0,
	0,17844,17845,7,3,0,0,17845,17846,7,0,0,0,17846,17847,5,95,0,0,17847,17848,
	7,12,0,0,17848,17849,7,7,0,0,17849,17850,7,13,0,0,17850,17851,7,2,0,0,17851,
	17852,7,21,0,0,17852,17853,7,12,0,0,17853,17854,7,7,0,0,17854,17855,7,17,
	0,0,17855,17856,5,95,0,0,17856,17857,7,10,0,0,17857,17858,7,5,0,0,17858,
	17859,7,6,0,0,17859,17860,7,3,0,0,17860,17861,7,12,0,0,17861,17862,7,9,
	0,0,17862,2418,1,0,0,0,17863,17864,7,2,0,0,17864,17865,7,3,0,0,17865,17866,
	7,0,0,0,17866,17867,5,95,0,0,17867,17868,7,12,0,0,17868,17869,7,7,0,0,17869,
	17870,7,13,0,0,17870,17871,7,2,0,0,17871,17872,7,21,0,0,17872,17873,7,12,
	0,0,17873,17874,7,7,0,0,17874,17875,7,17,0,0,17875,17876,5,95,0,0,17876,
	17877,7,10,0,0,17877,17878,7,5,0,0,17878,17879,7,6,0,0,17879,17880,7,3,
	0,0,17880,2420,1,0,0,0,17881,17882,7,2,0,0,17882,17883,7,3,0,0,17883,17884,
	7,0,0,0,17884,17885,5,95,0,0,17885,17886,7,12,0,0,17886,17887,7,7,0,0,17887,
	17888,7,13,0,0,17888,17889,7,2,0,0,17889,17890,7,21,0,0,17890,17891,7,12,
	0,0,17891,17892,7,7,0,0,17892,17893,7,17,0,0,17893,17894,5,95,0,0,17894,
	17895,7,25,0,0,17895,17896,7,5,0,0,17896,17897,5,95,0,0,17897,17898,7,10,
	0,0,17898,17899,7,5,0,0,17899,17900,7,6,0,0,17900,17901,7,3,0,0,17901,17902,
	5,95,0,0,17902,17903,7,17,0,0,17903,17904,7,10,0,0,17904,17905,7,12,0,0,
	17905,17906,7,9,0,0,17906,2422,1,0,0,0,17907,17908,7,2,0,0,17908,17909,
	7,3,0,0,17909,17910,7,0,0,0,17910,17911,5,95,0,0,17911,17912,7,12,0,0,17912,
	17913,7,7,0,0,17913,17914,7,13,0,0,17914,17915,7,2,0,0,17915,17916,7,21,
	0,0,17916,17917,7,12,0,0,17917,17918,7,7,0,0,17918,17919,7,17,0,0,17919,
	17920,5,95,0,0,17920,17921,7,25,0,0,17921,17922,7,5,0,0,17922,17923,5,95,
	0,0,17923,17924,7,10,0,0,17924,17925,7,5,0,0,17925,17926,7,6,0,0,17926,
	17927,7,3,0,0,17927,2424,1,0,0,0,17928,17929,7,2,0,0,17929,17930,7,3,0,
	0,17930,17931,7,0,0,0,17931,17932,5,95,0,0,17932,17933,7,3,0,0,17933,17934,
	7,0,0,0,17934,17935,7,22,0,0,17935,17936,7,8,0,0,17936,17937,7,2,0,0,17937,
	17938,7,14,0,0,17938,17939,7,15,0,0,17939,17940,7,0,0,0,17940,17941,7,3,
	0,0,17941,17942,7,6,0,0,17942,2426,1,0,0,0,17943,17944,7,2,0,0,17944,17945,
	7,3,0,0,17945,17946,7,0,0,0,17946,17947,5,95,0,0,17947,17948,7,3,0,0,17948,
	17949,7,0,0,0,17949,17950,7,22,0,0,17950,17951,7,8,0,0,17951,17952,7,2,
	0,0,17952,17953,7,7,0,0,17953,17954,7,8,0,0,17954,17955,7,0,0,0,17955,17956,
	7,4,0,0,17956,2428,1,0,0,0,17957,17958,7,2,0,0,17958,17959,7,3,0,0,17959,
	17960,7,0,0,0,17960,17961,5,95,0,0,17961,17962,7,3,0,0,17962,17963,7,2,
	0,0,17963,17964,7,22,0,0,17964,17965,7,5,0,0,17965,17966,7,8,0,0,17966,
	17967,7,7,0,0,17967,2430,1,0,0,0,17968,17969,7,2,0,0,17969,17970,7,3,0,
	0,17970,17971,7,0,0,0,17971,17972,5,95,0,0,17972,17973,7,3,0,0,17973,17974,
	7,2,0,0,17974,17975,7,22,0,0,17975,17976,7,5,0,0,17976,17977,7,8,0,0,17977,
	17978,7,7,0,0,17978,17979,5,95,0,0,17979,17980,7,3,0,0,17980,17981,7,0,
	0,0,17981,17982,7,22,0,0,17982,2432,1,0,0,0,17983,17984,7,2,0,0,17984,17985,
	7,3,0,0,17985,17986,7,0,0,0,17986,17987,5,95,0,0,17987,17988,7,3,0,0,17988,
	17989,7,2,0,0,17989,17990,7,22,0,0,17990,17991,7,13,0,0,17991,17992,7,6,
	0,0,17992,17993,7,3,0,0,17993,17994,7,5,0,0,17994,17995,7,12,0,0,17995,
	17996,7,2,0,0,17996,17997,7,7,0,0,17997,2434,1,0,0,0,17998,17999,7,2,0,
	0,17999,18000,7,3,0,0,18000,18001,7,0,0,0,18001,18002,5,95,0,0,18002,18003,
	7,4,0,0,18003,18004,7,0,0,0,18004,18005,7,1,0,0,18005,18006,7,13,0,0,18006,
	18007,7,6,0,0,18007,18008,7,3,0,0,18008,18009,7,5,0,0,18009,18010,7,12,
	0,0,18010,18011,7,2,0,0,18011,18012,7,7,0,0,18012,2436,1,0,0,0,18013,18014,
	7,2,0,0,18014,18015,7,3,0,0,18015,18016,7,0,0,0,18016,18017,5,95,0,0,18017,
	18018,7,22,0,0,18018,18019,7,3,0,0,18019,18020,7,12,0,0,18020,18021,7,4,
	0,0,18021,18022,7,6,0,0,18022,18023,5,95,0,0,18023,18024,7,4,0,0,18024,
	18025,7,12,0,0,18025,18026,7,14,0,0,18026,18027,7,6,0,0,18027,2438,1,0,
	0,0,18028,18029,7,2,0,0,18029,18030,7,3,0,0,18030,18031,7,9,0,0,18031,18032,
	7,6,0,0,18032,18033,7,3,0,0,18033,18034,7,6,0,0,18034,18035,7,9,0,0,18035,
	2440,1,0,0,0,18036,18037,7,2,0,0,18037,18038,7,3,0,0,18038,18039,7,9,0,
	0,18039,18040,7,6,0,0,18040,18041,7,3,0,0,18041,18042,7,6,0,0,18042,18043,
	7,9,0,0,18043,18044,5,95,0,0,18044,18045,7,15,0,0,18045,18046,7,3,0,0,18046,
	18047,7,6,0,0,18047,18048,7,9,0,0,18048,18049,7,12,0,0,18049,18050,7,8,
	0,0,18050,18051,7,0,0,0,18051,18052,7,4,0,0,18052,18053,7,6,0,0,18053,18054,
	7,5,0,0,18054,2442,1,0,0,0,18055,18056,7,2,0,0,18056,18057,7,3,0,0,18057,
	18058,7,9,0,0,18058,18059,7,6,0,0,18059,18060,7,3,0,0,18060,2444,1,0,0,
	0,18061,18062,7,2,0,0,18062,18063,7,3,0,0,18063,18064,7,9,0,0,18064,18065,
	7,12,0,0,18065,18066,7,7,0,0,18066,18067,7,0,0,0,18067,18068,7,11,0,0,18068,
	18069,7,12,0,0,18069,18070,7,4,0,0,18070,18071,7,18,0,0,18071,2446,1,0,
	0,0,18072,18073,7,2,0,0,18073,18074,7,3,0,0,18074,18075,5,95,0,0,18075,
	18076,7,6,0,0,18076,18077,7,25,0,0,18077,18078,7,15,0,0,18078,18079,7,0,
	0,0,18079,18080,7,7,0,0,18080,18081,7,9,0,0,18081,2448,1,0,0,0,18082,18083,
	7,2,0,0,18083,18084,7,3,0,0,18084,18085,7,17,0,0,18085,18086,7,0,0,0,18086,
	18087,7,7,0,0,18087,18088,7,12,0,0,18088,18089,7,23,0,0,18089,18090,7,0,
	0,0,18090,18091,7,4,0,0,18091,18092,7,12,0,0,18092,18093,7,2,0,0,18093,
	18094,7,7,0,0,18094,2450,1,0,0,0,18095,18096,7,2,0,0,18096,18097,7,3,0,
	0,18097,2452,1,0,0,0,18098,18099,7,2,0,0,18099,18100,7,3,0,0,18100,18101,
	5,95,0,0,18101,18102,7,15,0,0,18102,18103,7,3,0,0,18103,18104,7,6,0,0,18104,
	18105,7,9,0,0,18105,18106,7,12,0,0,18106,18107,7,8,0,0,18107,18108,7,0,
	0,0,18108,18109,7,4,0,0,18109,18110,7,6,0,0,18110,18111,7,5,0,0,18111,2454,
	1,0,0,0,18112,18113,7,2,0,0,18113,18114,7,5,0,0,18114,18115,7,6,0,0,18115,
	18116,7,3,0,0,18116,18117,7,3,0,0,18117,18118,7,2,0,0,18118,18119,7,3,0,
	0,18119,2456,1,0,0,0,18120,18121,7,2,0,0,18121,18122,7,4,0,0,18122,18123,
	7,19,0,0,18123,18124,7,6,0,0,18124,18125,7,3,0,0,18125,2458,1,0,0,0,18126,
	18127,7,2,0,0,18127,18128,7,10,0,0,18128,18129,7,4,0,0,18129,18130,7,6,
	0,0,18130,18131,7,3,0,0,18131,18132,5,95,0,0,18132,18133,7,20,0,0,18133,
	18134,7,2,0,0,18134,18135,7,12,0,0,18135,18136,7,7,0,0,18136,18137,5,95,
	0,0,18137,18138,7,4,0,0,18138,18139,7,2,0,0,18139,18140,5,95,0,0,18140,
	18141,7,0,0,0,18141,18142,7,7,0,0,18142,18143,7,4,0,0,18143,18144,7,12,
	0,0,18144,2460,1,0,0,0,18145,18146,7,2,0,0,18146,18147,7,10,0,0,18147,18148,
	7,4,0,0,18148,18149,7,6,0,0,18149,18150,7,3,0,0,18150,18151,5,95,0,0,18151,
	18152,7,20,0,0,18152,18153,7,2,0,0,18153,18154,7,12,0,0,18154,18155,7,7,
	0,0,18155,18156,5,95,0,0,18156,18157,7,4,0,0,18157,18158,7,2,0,0,18158,
	18159,5,95,0,0,18159,18160,7,12,0,0,18160,18161,7,7,0,0,18161,18162,7,7,
	0,0,18162,18163,7,6,0,0,18163,18164,7,3,0,0,18164,2462,1,0,0,0,18165,18166,
	7,2,0,0,18166,18167,7,10,0,0,18167,18168,7,4,0,0,18168,18169,7,6,0,0,18169,
	18170,7,3,0,0,18170,2464,1,0,0,0,18171,18172,7,2,0,0,18172,18173,7,10,0,
	0,18173,18174,7,4,0,0,18174,18175,7,11,0,0,18175,18176,7,12,0,0,18176,18177,
	7,7,0,0,18177,18178,7,6,0,0,18178,18179,5,95,0,0,18179,18180,7,11,0,0,18180,
	18181,7,6,0,0,18181,18182,7,0,0,0,18182,18183,7,16,0,0,18183,2466,1,0,0,
	0,18184,18185,7,2,0,0,18185,18186,7,10,0,0,18186,18187,7,4,0,0,18187,18188,
	7,11,0,0,18188,18189,7,12,0,0,18189,18190,7,7,0,0,18190,18191,7,6,0,0,18191,
	2468,1,0,0,0,18192,18193,7,2,0,0,18193,18194,7,10,0,0,18194,18195,7,4,0,
	0,18195,18196,5,95,0,0,18196,18197,7,2,0,0,18197,18198,7,16,0,0,18198,18199,
	5,95,0,0,18199,18200,7,11,0,0,18200,18201,7,12,0,0,18201,18202,7,7,0,0,
	18202,18203,7,6,0,0,18203,2470,1,0,0,0,18204,18205,7,2,0,0,18205,18206,
	7,10,0,0,18206,18207,7,4,0,0,18207,2472,1,0,0,0,18208,18209,7,2,0,0,18209,
	18210,7,13,0,0,18210,18211,7,6,0,0,18211,18212,7,3,0,0,18212,18213,7,16,
	0,0,18213,18214,7,11,0,0,18214,18215,7,2,0,0,18215,18216,7,22,0,0,18216,
	18217,5,95,0,0,18217,18218,7,7,0,0,18218,18219,7,2,0,0,18219,18220,7,14,
	0,0,18220,18221,7,2,0,0,18221,18222,7,13,0,0,18222,18223,7,6,0,0,18223,
	2474,1,0,0,0,18224,18225,7,2,0,0,18225,18226,7,13,0,0,18226,18227,7,6,0,
	0,18227,18228,7,3,0,0,18228,18229,7,16,0,0,18229,18230,7,11,0,0,18230,18231,
	7,2,0,0,18231,18232,7,22,0,0,18232,2476,1,0,0,0,18233,18234,7,2,0,0,18234,
	18235,7,13,0,0,18235,18236,7,6,0,0,18236,18237,7,3,0,0,18237,18238,7,11,
	0,0,18238,18239,7,0,0,0,18239,18240,7,15,0,0,18240,18241,7,5,0,0,18241,
	2478,1,0,0,0,18242,18243,7,2,0,0,18243,18244,7,13,0,0,18244,18245,7,6,0,
	0,18245,18246,7,3,0,0,18246,2480,1,0,0,0,18247,18248,7,2,0,0,18248,18249,
	7,13,0,0,18249,18250,7,6,0,0,18250,18251,7,3,0,0,18251,18252,7,3,0,0,18252,
	18253,7,12,0,0,18253,18254,7,9,0,0,18254,18255,7,12,0,0,18255,18256,7,7,
	0,0,18256,18257,7,17,0,0,18257,2482,1,0,0,0,18258,18259,7,2,0,0,18259,18260,
	7,22,0,0,18260,18261,7,7,0,0,18261,18262,7,6,0,0,18262,18263,7,3,0,0,18263,
	2484,1,0,0,0,18264,18265,7,2,0,0,18265,18266,7,22,0,0,18266,18267,7,7,0,
	0,18267,18268,7,6,0,0,18268,18269,7,3,0,0,18269,18270,7,5,0,0,18270,18271,
	7,19,0,0,18271,18272,7,12,0,0,18272,18273,7,15,0,0,18273,2486,1,0,0,0,18274,
	18275,7,2,0,0,18275,18276,7,22,0,0,18276,18277,7,7,0,0,18277,2488,1,0,0,
	0,18278,18279,7,15,0,0,18279,2490,1,0,0,0,18280,18281,7,15,0,0,18281,18282,
	7,0,0,0,18282,18283,7,8,0,0,18283,18284,7,21,0,0,18284,18285,7,0,0,0,18285,
	18286,7,17,0,0,18286,18287,7,6,0,0,18287,2492,1,0,0,0,18288,18289,7,15,
	0,0,18289,18290,7,0,0,0,18290,18291,7,8,0,0,18291,18292,7,21,0,0,18292,
	18293,7,0,0,0,18293,18294,7,17,0,0,18294,18295,7,6,0,0,18295,18296,7,5,
	0,0,18296,2494,1,0,0,0,18297,18298,7,15,0,0,18298,18299,7,0,0,0,18299,18300,
	7,3,0,0,18300,18301,7,0,0,0,18301,18302,7,11,0,0,18302,18303,7,11,0,0,18303,
	18304,7,6,0,0,18304,18305,7,11,0,0,18305,18306,5,95,0,0,18306,18307,7,6,
	0,0,18307,18308,7,7,0,0,18308,18309,7,0,0,0,18309,18310,7,1,0,0,18310,18311,
	7,11,0,0,18311,18312,7,6,0,0,18312,2496,1,0,0,0,18313,18314,7,15,0,0,18314,
	18315,7,0,0,0,18315,18316,7,3,0,0,18316,18317,7,0,0,0,18317,18318,7,11,
	0,0,18318,18319,7,11,0,0,18319,18320,7,6,0,0,18320,18321,7,11,0,0,18321,
	18322,5,95,0,0,18322,18323,7,12,0,0,18323,18324,7,7,0,0,18324,18325,7,9,
	0,0,18325,18326,7,6,0,0,18326,18327,7,25,0,0,18327,2498,1,0,0,0,18328,18329,
	7,15,0,0,18329,18330,7,0,0,0,18330,18331,7,3,0,0,18331,18332,7,0,0,0,18332,
	18333,7,11,0,0,18333,18334,7,11,0,0,18334,18335,7,6,0,0,18335,18336,7,11,
	0,0,18336,2500,1,0,0,0,18337,18338,7,15,0,0,18338,18339,7,0,0,0,18339,18340,
	7,3,0,0,18340,18341,7,0,0,0,18341,18342,7,14,0,0,18342,18343,7,6,0,0,18343,
	18344,7,4,0,0,18344,18345,7,6,0,0,18345,18346,7,3,0,0,18346,18347,7,16,
	0,0,18347,18348,7,12,0,0,18348,18349,7,11,0,0,18349,18350,7,6,0,0,18350,
	2502,1,0,0,0,18351,18352,7,15,0,0,18352,18353,7,0,0,0,18353,18354,7,3,0,
	0,18354,18355,7,0,0,0,18355,18356,7,14,0,0,18356,18357,7,6,0,0,18357,18358,
	7,4,0,0,18358,18359,7,6,0,0,18359,18360,7,3,0,0,18360,18361,7,5,0,0,18361,
	2504,1,0,0,0,18362,18363,7,15,0,0,18363,18364,7,0,0,0,18364,18365,7,3,0,
	0,18365,18366,7,0,0,0,18366,18367,7,14,0,0,18367,2506,1,0,0,0,18368,18369,
	7,15,0,0,18369,18370,7,0,0,0,18370,18371,7,3,0,0,18371,18372,7,6,0,0,18372,
	18373,7,7,0,0,18373,18374,7,4,0,0,18374,2508,1,0,0,0,18375,18376,7,15,0,
	0,18376,18377,7,0,0,0,18377,18378,7,3,0,0,18378,18379,7,6,0,0,18379,18380,
	7,7,0,0,18380,18381,7,4,0,0,18381,18382,5,95,0,0,18382,18383,7,11,0,0,18383,
	18384,7,6,0,0,18384,18385,7,13,0,0,18385,18386,7,6,0,0,18386,18387,7,11,
	0,0,18387,18388,5,95,0,0,18388,18389,7,7,0,0,18389,18390,7,0,0,0,18390,
	18391,7,14,0,0,18391,18392,7,6,0,0,18392,2510,1,0,0,0,18393,18394,7,15,
	0,0,18394,18395,7,0,0,0,18395,18396,7,3,0,0,18396,18397,7,6,0,0,18397,18398,
	7,7,0,0,18398,18399,7,4,0,0,18399,18400,5,95,0,0,18400,18401,7,10,0,0,18401,
	18402,7,7,0,0,18402,18403,7,12,0,0,18403,18404,7,24,0,0,18404,18405,7,10,
	0,0,18405,18406,7,6,0,0,18406,18407,5,95,0,0,18407,18408,7,7,0,0,18408,
	18409,7,0,0,0,18409,18410,7,14,0,0,18410,18411,7,6,0,0,18411,2512,1,0,0,
	0,18412,18413,7,15,0,0,18413,18414,7,0,0,0,18414,18415,7,3,0,0,18415,18416,
	7,12,0,0,18416,18417,7,4,0,0,18417,18418,7,18,0,0,18418,2514,1,0,0,0,18419,
	18420,7,15,0,0,18420,18421,7,0,0,0,18421,18422,7,3,0,0,18422,18423,7,4,
	0,0,18423,18424,7,12,0,0,18424,18425,7,0,0,0,18425,18426,7,11,0,0,18426,
	18427,5,95,0,0,18427,18428,7,20,0,0,18428,18429,7,2,0,0,18429,18430,7,12,
	0,0,18430,18431,7,7,0,0,18431,2516,1,0,0,0,18432,18433,7,15,0,0,18433,18434,
	7,0,0,0,18434,18435,7,3,0,0,18435,18436,7,4,0,0,18436,18437,7,12,0,0,18437,
	18438,7,0,0,0,18438,18439,7,11,0,0,18439,18440,7,11,0,0,18440,18441,7,18,
	0,0,18441,2518,1,0,0,0,18442,18443,7,15,0,0,18443,18444,7,0,0,0,18444,18445,
	7,3,0,0,18445,18446,7,4,0,0,18446,18447,7,12,0,0,18447,18448,7,0,0,0,18448,
	18449,7,11,0,0,18449,2520,1,0,0,0,18450,18451,7,15,0,0,18451,18452,7,0,
	0,0,18452,18453,7,3,0,0,18453,18454,7,4,0,0,18454,18455,7,12,0,0,18455,
	18456,7,0,0,0,18456,18457,7,11,0,0,18457,18458,5,95,0,0,18458,18459,7,3,
	0,0,18459,18460,7,2,0,0,18460,18461,7,11,0,0,18461,18462,7,11,0,0,18462,
	18463,7,10,0,0,18463,18464,7,15,0,0,18464,18465,5,95,0,0,18465,18466,7,
	15,0,0,18466,18467,7,10,0,0,18467,18468,7,5,0,0,18468,18469,7,19,0,0,18469,
	18470,7,9,0,0,18470,18471,7,2,0,0,18471,18472,7,22,0,0,18472,18473,7,7,
	0,0,18473,2522,1,0,0,0,18474,18475,7,15,0,0,18475,18476,7,0,0,0,18476,18477,
	7,3,0,0,18477,18478,7,4,0,0,18478,18479,7,12,0,0,18479,18480,7,4,0,0,18480,
	18481,7,12,0,0,18481,18482,7,2,0,0,18482,18483,7,7,0,0,18483,18484,5,95,
	0,0,18484,18485,7,19,0,0,18485,18486,7,0,0,0,18486,18487,7,5,0,0,18487,
	18488,7,19,0,0,18488,2524,1,0,0,0,18489,18490,7,15,0,0,18490,18491,7,0,
	0,0,18491,18492,7,3,0,0,18492,18493,7,4,0,0,18493,18494,7,12,0,0,18494,
	18495,7,4,0,0,18495,18496,7,12,0,0,18496,18497,7,2,0,0,18497,18498,7,7,
	0,0,18498,18499,5,95,0,0,18499,18500,7,11,0,0,18500,18501,7,12,0,0,18501,
	18502,7,5,0,0,18502,18503,7,4,0,0,18503,2526,1,0,0,0,18504,18505,7,15,0,
	0,18505,18506,7,0,0,0,18506,18507,7,3,0,0,18507,18508,7,4,0,0,18508,18509,
	7,12,0,0,18509,18510,7,4,0,0,18510,18511,7,12,0,0,18511,18512,7,2,0,0,18512,
	18513,7,7,0,0,18513,2528,1,0,0,0,18514,18515,7,15,0,0,18515,18516,7,0,0,
	0,18516,18517,7,3,0,0,18517,18518,7,4,0,0,18518,18519,7,12,0,0,18519,18520,
	7,4,0,0,18520,18521,7,12,0,0,18521,18522,7,2,0,0,18522,18523,7,7,0,0,18523,
	18524,5,95,0,0,18524,18525,7,3,0,0,18525,18526,7,0,0,0,18526,18527,7,7,
	0,0,18527,18528,7,17,0,0,18528,18529,7,6,0,0,18529,2530,1,0,0,0,18530,18531,
	7,15,0,0,18531,18532,7,0,0,0,18532,18533,7,3,0,0,18533,18534,7,4,0,0,18534,
	18535,7,12,0,0,18535,18536,7,4,0,0,18536,18537,7,12,0,0,18537,18538,7,2,
	0,0,18538,18539,7,7,0,0,18539,18540,7,5,0,0,18540,2532,1,0,0,0,18541,18542,
	7,15,0,0,18542,18543,7,0,0,0,18543,18544,7,3,0,0,18544,18545,7,4,0,0,18545,
	18546,5,36,0,0,18546,18547,7,7,0,0,18547,18548,7,10,0,0,18548,18549,7,14,
	0,0,18549,18550,5,36,0,0,18550,18551,7,12,0,0,18551,18552,7,7,0,0,18552,
	18553,7,5,0,0,18553,18554,7,4,0,0,18554,2534,1,0,0,0,18555,18556,7,15,0,
	0,18556,18557,7,0,0,0,18557,18558,7,5,0,0,18558,18559,7,5,0,0,18559,18560,
	7,12,0,0,18560,18561,7,7,0,0,18561,18562,7,17,0,0,18562,2536,1,0,0,0,18563,
	18564,7,15,0,0,18564,18565,7,0,0,0,18565,18566,7,5,0,0,18566,18567,7,5,
	0,0,18567,18568,7,22,0,0,18568,18569,7,2,0,0,18569,18570,7,3,0,0,18570,
	18571,7,9,0,0,18571,18572,5,95,0,0,18572,18573,7,17,0,0,18573,18574,7,3,
	0,0,18574,18575,7,0,0,0,18575,18576,7,8,0,0,18576,18577,7,6,0,0,18577,18578,
	5,95,0,0,18578,18579,7,4,0,0,18579,18580,7,12,0,0,18580,18581,7,14,0,0,
	18581,18582,7,6,0,0,18582,2538,1,0,0,0,18583,18584,7,15,0,0,18584,18585,
	7,0,0,0,18585,18586,7,5,0,0,18586,18587,7,5,0,0,18587,18588,7,22,0,0,18588,
	18589,7,2,0,0,18589,18590,7,3,0,0,18590,18591,7,9,0,0,18591,18592,5,95,
	0,0,18592,18593,7,11,0,0,18593,18594,7,12,0,0,18594,18595,7,16,0,0,18595,
	18596,7,6,0,0,18596,18597,5,95,0,0,18597,18598,7,4,0,0,18598,18599,7,12,
	0,0,18599,18600,7,14,0,0,18600,18601,7,6,0,0,18601,2540,1,0,0,0,18602,18603,
	7,15,0,0,18603,18604,7,0,0,0,18604,18605,7,5,0,0,18605,18606,7,5,0,0,18606,
	18607,7,22,0,0,18607,18608,7,2,0,0,18608,18609,7,3,0,0,18609,18610,7,9,
	0,0,18610,18611,5,95,0,0,18611,18612,7,11,0,0,18612,18613,7,2,0,0,18613,
	18614,7,8,0,0,18614,18615,7,21,0,0,18615,18616,5,95,0,0,18616,18617,7,4,
	0,0,18617,18618,7,12,0,0,18618,18619,7,14,0,0,18619,18620,7,6,0,0,18620,
	2542,1,0,0,0,18621,18622,7,15,0,0,18622,18623,7,0,0,0,18623,18624,7,5,0,
	0,18624,18625,7,5,0,0,18625,18626,7,22,0,0,18626,18627,7,2,0,0,18627,18628,
	7,3,0,0,18628,18629,7,9,0,0,18629,2544,1,0,0,0,18630,18631,7,15,0,0,18631,
	18632,7,0,0,0,18632,18633,7,5,0,0,18633,18634,7,5,0,0,18634,18635,7,22,
	0,0,18635,18636,7,2,0,0,18636,18637,7,3,0,0,18637,18638,7,9,0,0,18638,18639,
	5,95,0,0,18639,18640,7,3,0,0,18640,18641,7,6,0,0,18641,18642,7,10,0,0,18642,
	18643,7,5,0,0,18643,18644,7,6,0,0,18644,18645,5,95,0,0,18645,18646,7,14,
	0,0,18646,18647,7,0,0,0,18647,18648,7,25,0,0,18648,2546,1,0,0,0,18649,18650,
	7,15,0,0,18650,18651,7,0,0,0,18651,18652,7,5,0,0,18652,18653,7,5,0,0,18653,
	18654,7,22,0,0,18654,18655,7,2,0,0,18655,18656,7,3,0,0,18656,18657,7,9,
	0,0,18657,18658,5,95,0,0,18658,18659,7,3,0,0,18659,18660,7,6,0,0,18660,
	18661,7,10,0,0,18661,18662,7,5,0,0,18662,18663,7,6,0,0,18663,18664,5,95,
	0,0,18664,18665,7,4,0,0,18665,18666,7,12,0,0,18666,18667,7,14,0,0,18667,
	18668,7,6,0,0,18668,2548,1,0,0,0,18669,18670,7,15,0,0,18670,18671,7,0,0,
	0,18671,18672,7,5,0,0,18672,18673,7,5,0,0,18673,18674,7,22,0,0,18674,18675,
	7,2,0,0,18675,18676,7,3,0,0,18676,18677,7,9,0,0,18677,18678,5,95,0,0,18678,
	18679,7,3,0,0,18679,18680,7,2,0,0,18680,18681,7,11,0,0,18681,18682,7,11,
	0,0,18682,18683,7,2,0,0,18683,18684,7,13,0,0,18684,18685,7,6,0,0,18685,
	18686,7,3,0,0,18686,18687,5,95,0,0,18687,18688,7,4,0,0,18688,18689,7,12,
	0,0,18689,18690,7,14,0,0,18690,18691,7,6,0,0,18691,2550,1,0,0,0,18692,18693,
	7,15,0,0,18693,18694,7,0,0,0,18694,18695,7,5,0,0,18695,18696,7,5,0,0,18696,
	18697,7,22,0,0,18697,18698,7,2,0,0,18698,18699,7,3,0,0,18699,18700,7,9,
	0,0,18700,18701,5,95,0,0,18701,18702,7,13,0,0,18702,18703,7,6,0,0,18703,
	18704,7,3,0,0,18704,18705,7,12,0,0,18705,18706,7,16,0,0,18706,18707,7,18,
	0,0,18707,18708,5,95,0,0,18708,18709,7,16,0,0,18709,18710,7,10,0,0,18710,
	18711,7,7,0,0,18711,18712,7,8,0,0,18712,18713,7,4,0,0,18713,18714,7,12,
	0,0,18714,18715,7,2,0,0,18715,18716,7,7,0,0,18716,2552,1,0,0,0,18717,18718,
	7,15,0,0,18718,18719,7,0,0,0,18719,18720,7,5,0,0,18720,18721,7,4,0,0,18721,
	2554,1,0,0,0,18722,18723,7,15,0,0,18723,18724,7,0,0,0,18724,18725,7,4,0,
	0,18725,18726,7,8,0,0,18726,18727,7,19,0,0,18727,2556,1,0,0,0,18728,18729,
	7,15,0,0,18729,18730,7,0,0,0,18730,18731,7,4,0,0,18731,18732,7,19,0,0,18732,
	2558,1,0,0,0,18733,18734,7,15,0,0,18734,18735,7,0,0,0,18735,18736,7,4,0,
	0,18736,18737,7,19,0,0,18737,18738,5,95,0,0,18738,18739,7,15,0,0,18739,
	18740,7,3,0,0,18740,18741,7,6,0,0,18741,18742,7,16,0,0,18742,18743,7,12,
	0,0,18743,18744,7,25,0,0,18744,2560,1,0,0,0,18745,18746,7,15,0,0,18746,
	18747,7,0,0,0,18747,18748,7,4,0,0,18748,18749,7,19,0,0,18749,18750,7,5,
	0,0,18750,2562,1,0,0,0,18751,18752,7,15,0,0,18752,18753,7,0,0,0,18753,18754,
	7,4,0,0,18754,18755,7,4,0,0,18755,18756,7,6,0,0,18756,18757,7,3,0,0,18757,
	18758,7,7,0,0,18758,2564,1,0,0,0,18759,18760,7,15,0,0,18760,18761,7,1,0,
	0,18761,18762,7,11,0,0,18762,18763,5,95,0,0,18763,18764,7,19,0,0,18764,
	18765,7,5,0,0,18765,18766,5,95,0,0,18766,18767,7,1,0,0,18767,18768,7,6,
	0,0,18768,18769,7,17,0,0,18769,18770,7,12,0,0,18770,18771,7,7,0,0,18771,
	2566,1,0,0,0,18772,18773,7,15,0,0,18773,18774,7,1,0,0,18774,18775,7,11,
	0,0,18775,18776,5,95,0,0,18776,18777,7,19,0,0,18777,18778,7,5,0,0,18778,
	18779,5,95,0,0,18779,18780,7,6,0,0,18780,18781,7,7,0,0,18781,18782,7,9,
	0,0,18782,2568,1,0,0,0,18783,18784,7,15,0,0,18784,18785,7,8,0,0,18785,18786,
	7,4,0,0,18786,18787,7,16,0,0,18787,18788,7,3,0,0,18788,18789,7,6,0,0,18789,
	18790,7,6,0,0,18790,2570,1,0,0,0,18791,18792,7,15,0,0,18792,18793,7,8,0,
	0,18793,18794,7,4,0,0,18794,18795,7,12,0,0,18795,18796,7,7,0,0,18796,18797,
	7,8,0,0,18797,18798,7,3,0,0,18798,18799,7,6,0,0,18799,18800,7,0,0,0,18800,
	18801,7,5,0,0,18801,18802,7,6,0,0,18802,2572,1,0,0,0,18803,18804,7,15,0,
	0,18804,18805,7,8,0,0,18805,18806,7,4,0,0,18806,18807,7,4,0,0,18807,18808,
	7,19,0,0,18808,18809,7,3,0,0,18809,18810,7,6,0,0,18810,18811,7,5,0,0,18811,
	18812,7,19,0,0,18812,18813,7,2,0,0,18813,18814,7,11,0,0,18814,18815,7,9,
	0,0,18815,2574,1,0,0,0,18816,18817,7,15,0,0,18817,18818,7,8,0,0,18818,18819,
	7,4,0,0,18819,18820,7,10,0,0,18820,18821,7,5,0,0,18821,18822,7,6,0,0,18822,
	18823,7,9,0,0,18823,2576,1,0,0,0,18824,18825,7,15,0,0,18825,18826,7,8,0,
	0,18826,18827,7,4,0,0,18827,18828,7,13,0,0,18828,18829,7,6,0,0,18829,18830,
	7,3,0,0,18830,18831,7,5,0,0,18831,18832,7,12,0,0,18832,18833,7,2,0,0,18833,
	18834,7,7,0,0,18834,2578,1,0,0,0,18835,18836,7,15,0,0,18836,18837,7,6,0,
	0,18837,18838,7,7,0,0,18838,18839,7,9,0,0,18839,18840,7,12,0,0,18840,18841,
	7,7,0,0,18841,18842,7,17,0,0,18842,2580,1,0,0,0,18843,18847,5,37,0,0,18844,
	18846,3,4739,2369,0,18845,18844,1,0,0,0,18846,18849,1,0,0,0,18847,18845,
	1,0,0,0,18847,18848,1,0,0,0,18848,18850,1,0,0,0,18849,18847,1,0,0,0,18850,
	18851,7,16,0,0,18851,18852,7,2,0,0,18852,18853,7,10,0,0,18853,18854,7,7,
	0,0,18854,18855,7,9,0,0,18855,2582,1,0,0,0,18856,18860,5,37,0,0,18857,18859,
	3,4739,2369,0,18858,18857,1,0,0,0,18859,18862,1,0,0,0,18860,18858,1,0,0,
	0,18860,18861,1,0,0,0,18861,18863,1,0,0,0,18862,18860,1,0,0,0,18863,18864,
	7,12,0,0,18864,18865,7,5,0,0,18865,18866,7,2,0,0,18866,18867,7,15,0,0,18867,
	18868,7,6,0,0,18868,18869,7,7,0,0,18869,2584,1,0,0,0,18870,18874,5,37,0,
	0,18871,18873,3,4739,2369,0,18872,18871,1,0,0,0,18873,18876,1,0,0,0,18874,
	18872,1,0,0,0,18874,18875,1,0,0,0,18875,18877,1,0,0,0,18876,18874,1,0,0,
	0,18877,18878,7,7,0,0,18878,18879,7,2,0,0,18879,18880,7,4,0,0,18880,18881,
	7,16,0,0,18881,18882,7,2,0,0,18882,18883,7,10,0,0,18883,18884,7,7,0,0,18884,
	18885,7,9,0,0,18885,2586,1,0,0,0,18886,18887,7,15,0,0,18887,18888,7,6,0,
	0,18888,18889,7,3,0,0,18889,18890,7,8,0,0,18890,18891,7,6,0,0,18891,18892,
	7,7,0,0,18892,18893,7,4,0,0,18893,2588,1,0,0,0,18894,18895,7,15,0,0,18895,
	18896,7,6,0,0,18896,18897,7,3,0,0,18897,18898,7,8,0,0,18898,18899,7,6,0,
	0,18899,18900,7,7,0,0,18900,18901,7,4,0,0,18901,18902,5,95,0,0,18902,18903,
	7,3,0,0,18903,18904,7,0,0,0,18904,18905,7,7,0,0,18905,18906,7,21,0,0,18906,
	18907,7,14,0,0,18907,2590,1,0,0,0,18908,18912,5,37,0,0,18909,18911,3,4739,
	2369,0,18910,18909,1,0,0,0,18911,18914,1,0,0,0,18912,18910,1,0,0,0,18912,
	18913,1,0,0,0,18913,18915,1,0,0,0,18914,18912,1,0,0,0,18915,18916,7,3,0,
	0,18916,18917,7,2,0,0,18917,18918,7,22,0,0,18918,18919,7,8,0,0,18919,18920,
	7,2,0,0,18920,18921,7,10,0,0,18921,18922,7,7,0,0,18922,18923,7,4,0,0,18923,
	2592,1,0,0,0,18924,18928,5,37,0,0,18925,18927,3,4739,2369,0,18926,18925,
	1,0,0,0,18927,18930,1,0,0,0,18928,18926,1,0,0,0,18928,18929,1,0,0,0,18929,
	18931,1,0,0,0,18930,18928,1,0,0,0,18931,18932,7,3,0,0,18932,18933,7,2,0,
	0,18933,18934,7,22,0,0,18934,18935,7,4,0,0,18935,18936,7,18,0,0,18936,18937,
	7,15,0,0,18937,18938,7,6,0,0,18938,2594,1,0,0,0,18939,18943,5,37,0,0,18940,
	18942,3,4739,2369,0,18941,18940,1,0,0,0,18942,18945,1,0,0,0,18943,18941,
	1,0,0,0,18943,18944,1,0,0,0,18944,18946,1,0,0,0,18945,18943,1,0,0,0,18946,
	18947,7,4,0,0,18947,18948,7,18,0,0,18948,18949,7,15,0,0,18949,18950,7,6,
	0,0,18950,2596,1,0,0,0,18951,18952,7,15,0,0,18952,18953,7,6,0,0,18953,18954,
	7,3,0,0,18954,18955,7,16,0,0,18955,18956,7,2,0,0,18956,18957,7,3,0,0,18957,
	18958,7,14,0,0,18958,18959,7,0,0,0,18959,18960,7,7,0,0,18960,18961,7,8,
	0,0,18961,18962,7,6,0,0,18962,2598,1,0,0,0,18963,18964,7,15,0,0,18964,18965,
	7,6,0,0,18965,18966,7,3,0,0,18966,18967,7,12,0,0,18967,18968,7,2,0,0,18968,
	18969,7,9,0,0,18969,2600,1,0,0,0,18970,18971,7,15,0,0,18971,18972,7,6,0,
	0,18972,18973,7,3,0,0,18973,18974,7,14,0,0,18974,18975,7,0,0,0,18975,18976,
	7,7,0,0,18976,18977,7,6,0,0,18977,18978,7,7,0,0,18978,18979,7,4,0,0,18979,
	2602,1,0,0,0,18980,18981,7,15,0,0,18981,18982,7,6,0,0,18982,18983,7,3,0,
	0,18983,18984,7,14,0,0,18984,18985,7,12,0,0,18985,18986,7,5,0,0,18986,18987,
	7,5,0,0,18987,18988,7,12,0,0,18988,18989,7,2,0,0,18989,18990,7,7,0,0,18990,
	2604,1,0,0,0,18991,18992,7,15,0,0,18992,18993,7,6,0,0,18993,18994,7,3,0,
	0,18994,18995,7,14,0,0,18995,18996,7,10,0,0,18996,18997,7,4,0,0,18997,18998,
	7,6,0,0,18998,2606,1,0,0,0,18999,19000,7,15,0,0,19000,19001,7,6,0,0,19001,
	19002,7,3,0,0,19002,2608,1,0,0,0,19003,19004,7,15,0,0,19004,19005,7,16,
	0,0,19005,19006,7,12,0,0,19006,19007,7,11,0,0,19007,19008,7,6,0,0,19008,
	2610,1,0,0,0,19009,19010,7,15,0,0,19010,19011,7,19,0,0,19011,19012,7,18,
	0,0,19012,19013,7,5,0,0,19013,19014,7,12,0,0,19014,19015,7,8,0,0,19015,
	19016,7,0,0,0,19016,19017,7,11,0,0,19017,2612,1,0,0,0,19018,19019,7,15,
	0,0,19019,19020,7,12,0,0,19020,19021,7,21,0,0,19021,19022,7,6,0,0,19022,
	19023,7,18,0,0,19023,2614,1,0,0,0,19024,19025,7,15,0,0,19025,19026,7,12,
	0,0,19026,19027,7,15,0,0,19027,19028,7,6,0,0,19028,19029,7,11,0,0,19029,
	19030,7,12,0,0,19030,19031,7,7,0,0,19031,19032,7,6,0,0,19032,19033,7,9,
	0,0,19033,2616,1,0,0,0,19034,19035,7,15,0,0,19035,19036,7,12,0,0,19036,
	19037,7,15,0,0,19037,19038,7,6,0,0,19038,2618,1,0,0,0,19039,19040,7,15,
	0,0,19040,19041,7,12,0,0,19041,19042,7,13,0,0,19042,19043,5,95,0,0,19043,
	19044,7,17,0,0,19044,19045,7,1,0,0,19045,2620,1,0,0,0,19046,19047,7,15,
	0,0,19047,19048,7,12,0,0,19048,19049,7,13,0,0,19049,19050,7,2,0,0,19050,
	19051,7,4,0,0,19051,2622,1,0,0,0,19052,19053,7,15,0,0,19053,19054,7,12,
	0,0,19054,19055,7,13,0,0,19055,19056,5,95,0,0,19056,19057,7,5,0,0,19057,
	19058,7,5,0,0,19058,19059,7,16,0,0,19059,2624,1,0,0,0,19060,19061,7,15,
	0,0,19061,19062,7,11,0,0,19062,19063,7,0,0,0,19063,19064,7,8,0,0,19064,
	19065,7,6,0,0,19065,19066,5,95,0,0,19066,19067,7,9,0,0,19067,19068,7,12,
	0,0,19068,19069,7,5,0,0,19069,19070,7,4,0,0,19070,19071,7,12,0,0,19071,
	19072,7,7,0,0,19072,19073,7,8,0,0,19073,19074,7,4,0,0,19074,2626,1,0,0,
	0,19075,19076,7,15,0,0,19076,19077,7,11,0,0,19077,19078,7,0,0,0,19078,19079,
	7,8,0,0,19079,19080,7,6,0,0,19080,19081,5,95,0,0,19081,19082,7,17,0,0,19082,
	19083,7,3,0,0,19083,19084,7,2,0,0,19084,19085,7,10,0,0,19085,19086,7,15,
	0,0,19086,19087,5,95,0,0,19087,19088,7,1,0,0,19088,19089,7,18,0,0,19089,
	2628,1,0,0,0,19090,19091,7,15,0,0,19091,19092,7,11,0,0,19092,19093,7,0,
	0,0,19093,19094,7,7,0,0,19094,2630,1,0,0,0,19095,19096,7,15,0,0,19096,19097,
	7,11,0,0,19097,19098,7,5,0,0,19098,19099,7,8,0,0,19099,19100,7,2,0,0,19100,
	19101,7,15,0,0,19101,19102,7,6,0,0,19102,19103,5,95,0,0,19103,19104,7,5,
	0,0,19104,19105,7,6,0,0,19105,19106,7,4,0,0,19106,19107,7,4,0,0,19107,19108,
	7,12,0,0,19108,19109,7,7,0,0,19109,19110,7,17,0,0,19110,19111,7,5,0,0,19111,
	2632,1,0,0,0,19112,19113,7,15,0,0,19113,19114,7,11,0,0,19114,19115,7,5,
	0,0,19115,19116,5,95,0,0,19116,19117,7,12,0,0,19117,19118,7,7,0,0,19118,
	19119,7,4,0,0,19119,19120,7,6,0,0,19120,19121,7,17,0,0,19121,19122,7,6,
	0,0,19122,19123,7,3,0,0,19123,2634,1,0,0,0,19124,19125,7,15,0,0,19125,19126,
	7,11,0,0,19126,19127,7,5,0,0,19127,19128,7,24,0,0,19128,19129,7,11,0,0,
	19129,19130,5,95,0,0,19130,19131,7,8,0,0,19131,19132,7,8,0,0,19132,19133,
	7,16,0,0,19133,19134,7,11,0,0,19134,19135,7,0,0,0,19135,19136,7,17,0,0,
	19136,19137,7,5,0,0,19137,2636,1,0,0,0,19138,19139,7,15,0,0,19139,19140,
	7,11,0,0,19140,19141,7,5,0,0,19141,19142,7,24,0,0,19142,19143,7,11,0,0,
	19143,19144,5,95,0,0,19144,19145,7,8,0,0,19145,19146,7,2,0,0,19146,19147,
	7,9,0,0,19147,19148,7,6,0,0,19148,19149,5,95,0,0,19149,19150,7,4,0,0,19150,
	19151,7,18,0,0,19151,19152,7,15,0,0,19152,19153,7,6,0,0,19153,2638,1,0,
	0,0,19154,19155,7,15,0,0,19155,19156,7,11,0,0,19156,19157,7,5,0,0,19157,
	19158,7,24,0,0,19158,19159,7,11,0,0,19159,19160,5,95,0,0,19160,19161,7,
	9,0,0,19161,19162,7,6,0,0,19162,19163,7,1,0,0,19163,19164,7,10,0,0,19164,
	19165,7,17,0,0,19165,2640,1,0,0,0,19166,19167,7,15,0,0,19167,19168,7,11,
	0,0,19168,19169,7,5,0,0,19169,19170,7,24,0,0,19170,19171,7,11,0,0,19171,
	19172,5,95,0,0,19172,19173,7,2,0,0,19173,19174,7,15,0,0,19174,19175,7,4,
	0,0,19175,19176,7,12,0,0,19176,19177,7,14,0,0,19177,19178,7,12,0,0,19178,
	19179,7,23,0,0,19179,19180,7,6,0,0,19180,19181,5,95,0,0,19181,19182,7,11,
	0,0,19182,19183,7,6,0,0,19183,19184,7,13,0,0,19184,19185,7,6,0,0,19185,
	19186,7,11,0,0,19186,2642,1,0,0,0,19187,19188,7,15,0,0,19188,19189,7,11,
	0,0,19189,19190,7,5,0,0,19190,19191,7,24,0,0,19191,19192,7,11,0,0,19192,
	19193,5,95,0,0,19193,19194,7,22,0,0,19194,19195,7,0,0,0,19195,19196,7,3,
	0,0,19196,19197,7,7,0,0,19197,19198,7,12,0,0,19198,19199,7,7,0,0,19199,
	19200,7,17,0,0,19200,19201,7,5,0,0,19201,2644,1,0,0,0,19202,19203,7,15,
	0,0,19203,19204,7,11,0,0,19204,19205,7,10,0,0,19205,19206,7,17,0,0,19206,
	19207,7,17,0,0,19207,19208,7,0,0,0,19208,19209,7,1,0,0,19209,19210,7,11,
	0,0,19210,19211,7,6,0,0,19211,2646,1,0,0,0,19212,19213,7,15,0,0,19213,19214,
	7,14,0,0,19214,19215,7,6,0,0,19215,19216,7,14,0,0,19216,2648,1,0,0,0,19217,
	19218,7,15,0,0,19218,19219,7,2,0,0,19219,19220,7,12,0,0,19220,19221,7,7,
	0,0,19221,19222,7,4,0,0,19222,2650,1,0,0,0,19223,19224,7,15,0,0,19224,19225,
	7,2,0,0,19225,19226,7,11,0,0,19226,19227,7,12,0,0,19227,19228,7,8,0,0,19228,
	19229,7,18,0,0,19229,2652,1,0,0,0,19230,19231,7,15,0,0,19231,19232,7,2,
	0,0,19232,19233,7,2,0,0,19233,19234,7,11,0,0,19234,19235,5,95,0,0,19235,
	19236,5,49,0,0,19236,19237,5,54,0,0,19237,19238,7,21,0,0,19238,2654,1,0,
	0,0,19239,19240,7,15,0,0,19240,19241,7,2,0,0,19241,19242,7,2,0,0,19242,
	19243,7,11,0,0,19243,19244,5,95,0,0,19244,19245,5,50,0,0,19245,19246,7,
	21,0,0,19246,2656,1,0,0,0,19247,19248,7,15,0,0,19248,19249,7,2,0,0,19249,
	19250,7,2,0,0,19250,19251,7,11,0,0,19251,19252,5,95,0,0,19252,19253,5,51,
	0,0,19253,19254,5,50,0,0,19254,19255,7,21,0,0,19255,2658,1,0,0,0,19256,
	19257,7,15,0,0,19257,19258,7,2,0,0,19258,19259,7,2,0,0,19259,19260,7,11,
	0,0,19260,19261,5,95,0,0,19261,19262,5,52,0,0,19262,19263,7,21,0,0,19263,
	2660,1,0,0,0,19264,19265,7,15,0,0,19265,19266,7,2,0,0,19266,19267,7,2,0,
	0,19267,19268,7,11,0,0,19268,19269,5,95,0,0,19269,19270,5,56,0,0,19270,
	19271,7,21,0,0,19271,2662,1,0,0,0,19272,19273,7,15,0,0,19273,19274,7,2,
	0,0,19274,19275,7,5,0,0,19275,19276,7,12,0,0,19276,19277,7,4,0,0,19277,
	19278,7,12,0,0,19278,19279,7,13,0,0,19279,19280,7,6,0,0,19280,19281,7,7,
	0,0,19281,2664,1,0,0,0,19282,19283,7,15,0,0,19283,19284,7,2,0,0,19284,19285,
	7,5,0,0,19285,19286,7,12,0,0,19286,19287,7,4,0,0,19287,19288,7,12,0,0,19288,
	19289,7,13,0,0,19289,19290,7,6,0,0,19290,2666,1,0,0,0,19291,19292,7,15,
	0,0,19292,19293,7,2,0,0,19293,19294,7,5,0,0,19294,19295,7,4,0,0,19295,19296,
	5,95,0,0,19296,19297,7,4,0,0,19297,19298,7,3,0,0,19298,19299,7,0,0,0,19299,
	19300,7,7,0,0,19300,19301,7,5,0,0,19301,19302,7,0,0,0,19302,19303,7,8,0,
	0,19303,19304,7,4,0,0,19304,19305,7,12,0,0,19305,19306,7,2,0,0,19306,19307,
	7,7,0,0,19307,2668,1,0,0,0,19308,19309,7,15,0,0,19309,19310,7,2,0,0,19310,
	19311,7,22,0,0,19311,19312,7,6,0,0,19312,19313,7,3,0,0,19313,19314,7,14,
	0,0,19314,19315,7,10,0,0,19315,19316,7,11,0,0,19316,19317,7,4,0,0,19317,
	19318,7,12,0,0,19318,19319,7,5,0,0,19319,19320,7,6,0,0,19320,19321,7,4,
	0,0,19321,19322,5,95,0,0,19322,19323,7,1,0,0,19323,19324,7,18,0,0,19324,
	19325,5,95,0,0,19325,19326,7,8,0,0,19326,19327,7,0,0,0,19327,19328,7,3,
	0,0,19328,19329,7,9,0,0,19329,19330,7,12,0,0,19330,19331,7,7,0,0,19331,
	19332,7,0,0,0,19332,19333,7,11,0,0,19333,19334,7,12,0,0,19334,19335,7,4,
	0,0,19335,19336,7,18,0,0,19336,2670,1,0,0,0,19337,19338,7,15,0,0,19338,
	19339,7,2,0,0,19339,19340,7,22,0,0,19340,19341,7,6,0,0,19341,19342,7,3,
	0,0,19342,19343,7,14,0,0,19343,19344,7,10,0,0,19344,19345,7,11,0,0,19345,
	19346,7,4,0,0,19346,19347,7,12,0,0,19347,19348,7,5,0,0,19348,19349,7,6,
	0,0,19349,19350,7,4,0,0,19350,2672,1,0,0,0,19351,19352,7,15,0,0,19352,19353,
	7,2,0,0,19353,19354,7,22,0,0,19354,19355,7,6,0,0,19355,19356,7,3,0,0,19356,
	2674,1,0,0,0,19357,19358,7,15,0,0,19358,19359,7,24,0,0,19359,19360,5,95,
	0,0,19360,19361,7,8,0,0,19361,19362,7,2,0,0,19362,19363,7,7,0,0,19363,19364,
	7,8,0,0,19364,19365,7,10,0,0,19365,19366,7,3,0,0,19366,19367,7,3,0,0,19367,
	19368,7,6,0,0,19368,19369,7,7,0,0,19369,19370,7,4,0,0,19370,19371,5,95,
	0,0,19371,19372,7,10,0,0,19372,19373,7,7,0,0,19373,19374,7,12,0,0,19374,
	19375,7,2,0,0,19375,19376,7,7,0,0,19376,2676,1,0,0,0,19377,19378,7,15,0,
	0,19378,19379,7,24,0,0,19379,19380,5,95,0,0,19380,19381,7,9,0,0,19381,19382,
	7,12,0,0,19382,19383,7,5,0,0,19383,19384,7,4,0,0,19384,19385,7,3,0,0,19385,
	19386,7,12,0,0,19386,19387,7,1,0,0,19387,19388,7,10,0,0,19388,19389,7,4,
	0,0,19389,19390,7,6,0,0,19390,2678,1,0,0,0,19391,19392,7,15,0,0,19392,19393,
	7,24,0,0,19393,19394,5,95,0,0,19394,19395,7,9,0,0,19395,19396,7,12,0,0,
	19396,19397,7,5,0,0,19397,19398,7,4,0,0,19398,19399,7,3,0,0,19399,19400,
	7,12,0,0,19400,19401,7,1,0,0,19401,19402,7,10,0,0,19402,19403,7,4,0,0,19403,
	19404,7,6,0,0,19404,19405,5,95,0,0,19405,19406,7,22,0,0,19406,19407,7,12,
	0,0,19407,19408,7,7,0,0,19408,19409,7,9,0,0,19409,19410,7,2,0,0,19410,19411,
	7,22,0,0,19411,2680,1,0,0,0,19412,19413,7,15,0,0,19413,19414,7,24,0,0,19414,
	19415,5,95,0,0,19415,19416,7,16,0,0,19416,19417,7,12,0,0,19417,19418,7,
	11,0,0,19418,19419,7,4,0,0,19419,19420,7,6,0,0,19420,19421,7,3,0,0,19421,
	2682,1,0,0,0,19422,19423,7,15,0,0,19423,19424,7,24,0,0,19424,19425,5,95,
	0,0,19425,19426,7,14,0,0,19426,19427,7,0,0,0,19427,19428,7,15,0,0,19428,
	2684,1,0,0,0,19429,19430,7,15,0,0,19430,19431,7,24,0,0,19431,19432,5,95,
	0,0,19432,19433,7,7,0,0,19433,19434,7,2,0,0,19434,19435,7,14,0,0,19435,
	19436,7,0,0,0,19436,19437,7,15,0,0,19437,2686,1,0,0,0,19438,19439,7,15,
	0,0,19439,19440,7,24,0,0,19440,19441,5,95,0,0,19441,19442,7,3,0,0,19442,
	19443,7,6,0,0,19443,19444,7,15,0,0,19444,19445,7,11,0,0,19445,19446,7,12,
	0,0,19446,19447,7,8,0,0,19447,19448,7,0,0,0,19448,19449,7,4,0,0,19449,19450,
	7,6,0,0,19450,2688,1,0,0,0,19451,19452,7,15,0,0,19452,19453,7,24,0,0,19453,
	19454,5,95,0,0,19454,19455,7,5,0,0,19455,19456,7,21,0,0,19456,19457,7,6,
	0,0,19457,19458,7,22,0,0,19458,2690,1,0,0,0,19459,19460,7,15,0,0,19460,
	19461,7,3,0,0,19461,19462,7,0,0,0,19462,19463,7,17,0,0,19463,19464,7,14,
	0,0,19464,19465,7,0,0,0,19465,2692,1,0,0,0,19466,19467,7,15,0,0,19467,19468,
	7,3,0,0,19468,19469,7,6,0,0,19469,19470,7,1,0,0,19470,19471,7,10,0,0,19471,
	19472,7,12,0,0,19472,19473,7,11,0,0,19473,19474,7,4,0,0,19474,2694,1,0,
	0,0,19475,19476,7,15,0,0,19476,19477,7,3,0,0,19477,19478,7,6,0,0,19478,
	19479,7,8,0,0,19479,19480,7,6,0,0,19480,19481,7,9,0,0,19481,19482,7,6,0,
	0,19482,19483,7,5,0,0,19483,2696,1,0,0,0,19484,19485,7,15,0,0,19485,19486,
	7,3,0,0,19486,19487,7,6,0,0,19487,19488,7,8,0,0,19488,19489,7,6,0,0,19489,
	19490,7,9,0,0,19490,19491,7,12,0,0,19491,19492,7,7,0,0,19492,19493,7,17,
	0,0,19493,2698,1,0,0,0,19494,19495,7,15,0,0,19495,19496,7,3,0,0,19496,19497,
	7,6,0,0,19497,19498,7,8,0,0,19498,19499,7,12,0,0,19499,19500,7,5,0,0,19500,
	19501,7,12,0,0,19501,19502,7,2,0,0,19502,19503,7,7,0,0,19503,2700,1,0,0,
	0,19504,19505,7,15,0,0,19505,19506,7,3,0,0,19506,19507,7,6,0,0,19507,19508,
	7,8,0,0,19508,19509,7,2,0,0,19509,19510,7,14,0,0,19510,19511,7,15,0,0,19511,
	19512,7,10,0,0,19512,19513,7,4,0,0,19513,19514,7,6,0,0,19514,19515,5,95,
	0,0,19515,19516,7,5,0,0,19516,19517,7,10,0,0,19517,19518,7,1,0,0,19518,
	19519,7,24,0,0,19519,19520,7,10,0,0,19520,19521,7,6,0,0,19521,19522,7,3,
	0,0,19522,19523,7,18,0,0,19523,2702,1,0,0,0,19524,19525,7,15,0,0,19525,
	19526,7,3,0,0,19526,19527,7,6,0,0,19527,19528,7,9,0,0,19528,19529,7,12,
	0,0,19529,19530,7,8,0,0,19530,19531,7,0,0,0,19531,19532,7,4,0,0,19532,19533,
	7,6,0,0,19533,19534,5,95,0,0,19534,19535,7,3,0,0,19535,19536,7,6,0,0,19536,
	19537,7,2,0,0,19537,19538,7,3,0,0,19538,19539,7,9,0,0,19539,19540,7,6,0,
	0,19540,19541,7,3,0,0,19541,19542,7,5,0,0,19542,2704,1,0,0,0,19543,19544,
	7,15,0,0,19544,19545,7,3,0,0,19545,19546,7,6,0,0,19546,19547,7,11,0,0,19547,
	19548,7,2,0,0,19548,19549,7,0,0,0,19549,19550,7,9,0,0,19550,2706,1,0,0,
	0,19551,19552,7,15,0,0,19552,19553,7,3,0,0,19553,19554,7,6,0,0,19554,19555,
	7,15,0,0,19555,19556,7,0,0,0,19556,19557,7,3,0,0,19557,19558,7,6,0,0,19558,
	2708,1,0,0,0,19559,19560,7,15,0,0,19560,19561,7,3,0,0,19561,19562,7,6,0,
	0,19562,19563,7,5,0,0,19563,19564,7,6,0,0,19564,19565,7,7,0,0,19565,19566,
	7,4,0,0,19566,19567,7,7,0,0,19567,19568,7,7,0,0,19568,19569,7,13,0,0,19569,
	2710,1,0,0,0,19570,19571,7,15,0,0,19571,19572,7,3,0,0,19572,19573,7,6,0,
	0,19573,19574,7,5,0,0,19574,19575,7,6,0,0,19575,19576,7,7,0,0,19576,19577,
	7,4,0,0,19577,2712,1,0,0,0,19578,19579,7,15,0,0,19579,19580,7,3,0,0,19580,
	19581,7,6,0,0,19581,19582,7,5,0,0,19582,19583,7,6,0,0,19583,19584,7,7,0,
	0,19584,19585,7,4,0,0,19585,19586,7,13,0,0,19586,2714,1,0,0,0,19587,19588,
	7,15,0,0,19588,19589,7,3,0,0,19589,19590,7,6,0,0,19590,19591,7,5,0,0,19591,
	19592,7,6,0,0,19592,19593,7,3,0,0,19593,19594,7,13,0,0,19594,19595,7,6,
	0,0,19595,19596,5,95,0,0,19596,19597,7,2,0,0,19597,19598,7,12,0,0,19598,
	19599,7,9,0,0,19599,2716,1,0,0,0,19600,19601,7,15,0,0,19601,19602,7,3,0,
	0,19602,19603,7,6,0,0,19603,19604,7,5,0,0,19604,19605,7,6,0,0,19605,19606,
	7,3,0,0,19606,19607,7,13,0,0,19607,19608,7,6,0,0,19608,2718,1,0,0,0,19609,
	19610,7,15,0,0,19610,19611,7,3,0,0,19611,19612,7,6,0,0,19612,19613,7,4,
	0,0,19613,19614,7,4,0,0,19614,19615,7,18,0,0,19615,2720,1,0,0,0,19616,19617,
	7,15,0,0,19617,19618,7,3,0,0,19618,19619,7,6,0,0,19619,19620,7,13,0,0,19620,
	19621,7,12,0,0,19621,19622,7,2,0,0,19622,19623,7,10,0,0,19623,19624,7,5,
	0,0,19624,2722,1,0,0,0,19625,19626,7,15,0,0,19626,19627,7,3,0,0,19627,19628,
	7,6,0,0,19628,19629,7,13,0,0,19629,2724,1,0,0,0,19630,19631,7,15,0,0,19631,
	19632,7,3,0,0,19632,19633,7,12,0,0,19633,19634,7,14,0,0,19634,19635,7,0,
	0,0,19635,19636,7,3,0,0,19636,19637,7,18,0,0,19637,2726,1,0,0,0,19638,19639,
	7,15,0,0,19639,19640,7,3,0,0,19640,19641,7,12,0,0,19641,19642,7,7,0,0,19642,
	19643,7,4,0,0,19643,19644,7,1,0,0,19644,19645,7,11,0,0,19645,19646,7,2,
	0,0,19646,19647,7,1,0,0,19647,19648,7,4,0,0,19648,19649,7,2,0,0,19649,19650,
	7,8,0,0,19650,19651,7,11,0,0,19651,19652,7,2,0,0,19652,19653,7,1,0,0,19653,
	2728,1,0,0,0,19654,19655,7,15,0,0,19655,19656,7,3,0,0,19656,19657,7,12,
	0,0,19657,19658,7,2,0,0,19658,19659,7,3,0,0,19659,19660,7,12,0,0,19660,
	19661,7,4,0,0,19661,19662,7,18,0,0,19662,2730,1,0,0,0,19663,19664,7,15,
	0,0,19664,19665,7,3,0,0,19665,19666,7,12,0,0,19666,19667,7,2,0,0,19667,
	19668,7,3,0,0,19668,2732,1,0,0,0,19669,19670,7,15,0,0,19670,19671,7,3,0,
	0,19671,19672,7,12,0,0,19672,19673,7,13,0,0,19673,19674,7,0,0,0,19674,19675,
	7,4,0,0,19675,19676,7,6,0,0,19676,2734,1,0,0,0,19677,19678,7,15,0,0,19678,
	19679,7,3,0,0,19679,19680,7,12,0,0,19680,19681,7,13,0,0,19681,19682,7,0,
	0,0,19682,19683,7,4,0,0,19683,19684,7,6,0,0,19684,19685,5,95,0,0,19685,
	19686,7,5,0,0,19686,19687,7,17,0,0,19687,19688,7,0,0,0,19688,2736,1,0,0,
	0,19689,19690,7,15,0,0,19690,19691,7,3,0,0,19691,19692,7,12,0,0,19692,19693,
	7,13,0,0,19693,19694,7,12,0,0,19694,19695,7,11,0,0,19695,19696,7,6,0,0,
	19696,19697,7,17,0,0,19697,19698,7,6,0,0,19698,19699,7,9,0,0,19699,2738,
	1,0,0,0,19700,19701,7,15,0,0,19701,19702,7,3,0,0,19702,19703,7,12,0,0,19703,
	19704,7,13,0,0,19704,19705,7,12,0,0,19705,19706,7,11,0,0,19706,19707,7,
	6,0,0,19707,19708,7,17,0,0,19708,19709,7,6,0,0,19709,2740,1,0,0,0,19710,
	19711,7,15,0,0,19711,19712,7,3,0,0,19712,19713,7,12,0,0,19713,19714,7,13,
	0,0,19714,19715,7,12,0,0,19715,19716,7,11,0,0,19716,19717,7,6,0,0,19717,
	19718,7,17,0,0,19718,19719,7,6,0,0,19719,19720,7,5,0,0,19720,2742,1,0,0,
	0,19721,19722,7,15,0,0,19722,19723,7,3,0,0,19723,19724,7,2,0,0,19724,19725,
	7,8,0,0,19725,19726,7,6,0,0,19726,19727,7,9,0,0,19727,19728,7,10,0,0,19728,
	19729,7,3,0,0,19729,19730,7,0,0,0,19730,19731,7,11,0,0,19731,2744,1,0,0,
	0,19732,19733,7,15,0,0,19733,19734,7,3,0,0,19734,19735,7,2,0,0,19735,19736,
	7,8,0,0,19736,19737,7,6,0,0,19737,19738,7,9,0,0,19738,19739,7,10,0,0,19739,
	19740,7,3,0,0,19740,19741,7,6,0,0,19741,2746,1,0,0,0,19742,19743,7,15,0,
	0,19743,19744,7,3,0,0,19744,19745,7,2,0,0,19745,19746,7,8,0,0,19746,19747,
	7,6,0,0,19747,19748,7,5,0,0,19748,19749,7,5,0,0,19749,2748,1,0,0,0,19750,
	19751,7,15,0,0,19751,19752,7,3,0,0,19752,19753,7,2,0,0,19753,19754,7,16,
	0,0,19754,19755,7,12,0,0,19755,19756,7,11,0,0,19756,19757,7,6,0,0,19757,
	2750,1,0,0,0,19758,19759,7,15,0,0,19759,19760,7,3,0,0,19760,19761,7,2,0,
	0,19761,19762,7,17,0,0,19762,19763,7,3,0,0,19763,19764,7,0,0,0,19764,19765,
	7,14,0,0,19765,2752,1,0,0,0,19766,19767,7,15,0,0,19767,19768,7,3,0,0,19768,
	19769,7,2,0,0,19769,19770,7,20,0,0,19770,19771,7,6,0,0,19771,19772,7,8,
	0,0,19772,19773,7,4,0,0,19773,2754,1,0,0,0,19774,19775,7,15,0,0,19775,19776,
	7,3,0,0,19776,19777,7,2,0,0,19777,19778,7,15,0,0,19778,19779,7,0,0,0,19779,
	19780,7,17,0,0,19780,19781,7,0,0,0,19781,19782,7,4,0,0,19782,19783,7,6,
	0,0,19783,2756,1,0,0,0,19784,19785,7,15,0,0,19785,19786,7,3,0,0,19786,19787,
	7,2,0,0,19787,19788,7,15,0,0,19788,19789,7,6,0,0,19789,19790,7,3,0,0,19790,
	19791,7,4,0,0,19791,19792,7,18,0,0,19792,2758,1,0,0,0,19793,19794,7,15,
	0,0,19794,19795,7,3,0,0,19795,19796,7,2,0,0,19796,19797,7,4,0,0,19797,19798,
	7,6,0,0,19798,19799,7,8,0,0,19799,19800,7,4,0,0,19800,19801,7,6,0,0,19801,
	19802,7,9,0,0,19802,2760,1,0,0,0,19803,19804,7,15,0,0,19804,19805,7,3,0,
	0,19805,19806,7,2,0,0,19806,19807,7,4,0,0,19807,19808,7,6,0,0,19808,19809,
	7,8,0,0,19809,19810,7,4,0,0,19810,19811,7,12,0,0,19811,19812,7,2,0,0,19812,
	19813,7,7,0,0,19813,2762,1,0,0,0,19814,19815,7,15,0,0,19815,19816,7,3,0,
	0,19816,19817,7,2,0,0,19817,19818,7,4,0,0,19818,19819,7,2,0,0,19819,19820,
	7,8,0,0,19820,19821,7,2,0,0,19821,19822,7,11,0,0,19822,2764,1,0,0,0,19823,
	19824,7,15,0,0,19824,19825,7,3,0,0,19825,19826,7,2,0,0,19826,19827,7,25,
	0,0,19827,19828,7,18,0,0,19828,2766,1,0,0,0,19829,19830,7,15,0,0,19830,
	19831,7,3,0,0,19831,19832,7,10,0,0,19832,19833,7,7,0,0,19833,19834,7,12,
	0,0,19834,19835,7,7,0,0,19835,19836,7,17,0,0,19836,2768,1,0,0,0,19837,19838,
	7,15,0,0,19838,19839,7,10,0,0,19839,19840,7,1,0,0,19840,19841,7,11,0,0,
	19841,19842,7,12,0,0,19842,19843,7,8,0,0,19843,2770,1,0,0,0,19844,19845,
	7,15,0,0,19845,19846,7,10,0,0,19846,19847,7,11,0,0,19847,19848,7,11,0,0,
	19848,19849,5,95,0,0,19849,19850,7,15,0,0,19850,19851,7,3,0,0,19851,19852,
	7,6,0,0,19852,19853,7,9,0,0,19853,2772,1,0,0,0,19854,19855,7,15,0,0,19855,
	19856,7,10,0,0,19856,19857,7,3,0,0,19857,19858,7,17,0,0,19858,19859,7,6,
	0,0,19859,2774,1,0,0,0,19860,19861,7,15,0,0,19861,19862,7,10,0,0,19862,
	19863,7,5,0,0,19863,19864,7,19,0,0,19864,19865,5,95,0,0,19865,19866,7,15,
	0,0,19866,19867,7,3,0,0,19867,19868,7,6,0,0,19868,19869,7,9,0,0,19869,2776,
	1,0,0,0,19870,19871,7,15,0,0,19871,19872,7,10,0,0,19872,19873,7,5,0,0,19873,
	19874,7,19,0,0,19874,19875,5,95,0,0,19875,19876,7,5,0,0,19876,19877,7,10,
	0,0,19877,19878,7,1,0,0,19878,19879,7,24,0,0,19879,2778,1,0,0,0,19880,19881,
	7,15,0,0,19881,19882,7,25,0,0,19882,19883,5,95,0,0,19883,19884,7,16,0,0,
	19884,19885,7,0,0,0,19885,19886,7,10,0,0,19886,19887,7,11,0,0,19887,19888,
	7,4,0,0,19888,19889,5,95,0,0,19889,19890,7,4,0,0,19890,19891,7,2,0,0,19891,
	19892,7,11,0,0,19892,19893,7,6,0,0,19893,19894,7,3,0,0,19894,19895,7,0,
	0,0,19895,19896,7,7,0,0,19896,19897,7,8,0,0,19897,19898,7,6,0,0,19898,2780,
	1,0,0,0,19899,19900,7,15,0,0,19900,19901,7,25,0,0,19901,19902,5,95,0,0,
	19902,19903,7,17,0,0,19903,19904,7,3,0,0,19904,19905,7,0,0,0,19905,19906,
	7,7,0,0,19906,19907,7,10,0,0,19907,19908,7,11,0,0,19908,19909,7,6,0,0,19909,
	2782,1,0,0,0,19910,19911,7,15,0,0,19911,19912,7,25,0,0,19912,19913,5,95,
	0,0,19913,19914,7,20,0,0,19914,19915,7,2,0,0,19915,19916,7,12,0,0,19916,
	19917,7,7,0,0,19917,19918,5,95,0,0,19918,19919,7,16,0,0,19919,19920,7,12,
	0,0,19920,19921,7,11,0,0,19921,19922,7,4,0,0,19922,19923,7,6,0,0,19923,
	19924,7,3,0,0,19924,2784,1,0,0,0,19925,19926,7,24,0,0,19926,19927,7,1,0,
	0,19927,19928,5,95,0,0,19928,19929,7,7,0,0,19929,19930,7,0,0,0,19930,19931,
	7,14,0,0,19931,19932,7,6,0,0,19932,2786,1,0,0,0,19933,19934,7,24,0,0,19934,
	19935,7,10,0,0,19935,19936,7,0,0,0,19936,19937,7,3,0,0,19937,19938,7,4,
	0,0,19938,19939,7,6,0,0,19939,19940,7,3,0,0,19940,19941,7,5,0,0,19941,2788,
	1,0,0,0,19942,19943,7,24,0,0,19943,19944,7,10,0,0,19944,19945,7,6,0,0,19945,
	19946,7,3,0,0,19946,19947,7,18,0,0,19947,19948,5,95,0,0,19948,19949,7,1,
	0,0,19949,19950,7,11,0,0,19950,19951,7,2,0,0,19951,19952,7,8,0,0,19952,
	19953,7,21,0,0,19953,2790,1,0,0,0,19954,19955,7,24,0,0,19955,19956,7,10,
	0,0,19956,19957,7,6,0,0,19957,19958,7,3,0,0,19958,19959,7,18,0,0,19959,
	2792,1,0,0,0,19960,19961,7,24,0,0,19961,19962,7,10,0,0,19962,19963,7,6,
	0,0,19963,19964,7,10,0,0,19964,19965,7,6,0,0,19965,19966,5,95,0,0,19966,
	19967,7,8,0,0,19967,19968,7,10,0,0,19968,19969,7,3,0,0,19969,19970,7,3,
	0,0,19970,2794,1,0,0,0,19971,19972,7,24,0,0,19972,19973,7,10,0,0,19973,
	19974,7,6,0,0,19974,19975,7,10,0,0,19975,19976,7,6,0,0,19976,2796,1,0,0,
	0,19977,19978,7,24,0,0,19978,19979,7,10,0,0,19979,19980,7,6,0,0,19980,19981,
	7,10,0,0,19981,19982,7,6,0,0,19982,19983,5,95,0,0,19983,19984,7,3,0,0,19984,
	19985,7,2,0,0,19985,19986,7,22,0,0,19986,19987,7,15,0,0,19987,2798,1,0,
	0,0,19988,19989,7,24,0,0,19989,19990,7,10,0,0,19990,19991,7,12,0,0,19991,
	19992,7,6,0,0,19992,19993,7,5,0,0,19993,19994,7,8,0,0,19994,19995,7,6,0,
	0,19995,2800,1,0,0,0,19996,19997,7,24,0,0,19997,19998,7,10,0,0,19998,19999,
	7,2,0,0,19999,20000,7,3,0,0,20000,20001,7,10,0,0,20001,20002,7,14,0,0,20002,
	2802,1,0,0,0,20003,20004,7,24,0,0,20004,20005,7,10,0,0,20005,20006,7,2,
	0,0,20006,20007,7,4,0,0,20007,20008,7,0,0,0,20008,2804,1,0,0,0,20009,20010,
	7,24,0,0,20010,20011,7,10,0,0,20011,20012,7,2,0,0,20012,20013,7,4,0,0,20013,
	20014,7,0,0,0,20014,20015,7,17,0,0,20015,20016,7,3,0,0,20016,20017,7,2,
	0,0,20017,20018,7,10,0,0,20018,20019,7,15,0,0,20019,2806,1,0,0,0,20020,
	20021,7,3,0,0,20021,20022,7,0,0,0,20022,20023,7,12,0,0,20023,20024,7,5,
	0,0,20024,20025,7,6,0,0,20025,2808,1,0,0,0,20026,20027,7,3,0,0,20027,20028,
	7,0,0,0,20028,20029,7,7,0,0,20029,20030,7,9,0,0,20030,20031,7,2,0,0,20031,
	20032,7,14,0,0,20032,20033,5,95,0,0,20033,20034,7,11,0,0,20034,20035,7,
	2,0,0,20035,20036,7,8,0,0,20036,20037,7,0,0,0,20037,20038,7,11,0,0,20038,
	2810,1,0,0,0,20039,20040,7,3,0,0,20040,20041,7,0,0,0,20041,20042,7,7,0,
	0,20042,20043,7,9,0,0,20043,20044,7,2,0,0,20044,20045,7,14,0,0,20045,2812,
	1,0,0,0,20046,20047,7,3,0,0,20047,20048,7,0,0,0,20048,20049,7,7,0,0,20049,
	20050,7,17,0,0,20050,20051,7,6,0,0,20051,2814,1,0,0,0,20052,20053,7,3,0,
	0,20053,20054,7,0,0,0,20054,20055,7,7,0,0,20055,20056,7,21,0,0,20056,20057,
	7,14,0,0,20057,2816,1,0,0,0,20058,20059,7,3,0,0,20059,20060,7,0,0,0,20060,
	20061,7,15,0,0,20061,20062,7,12,0,0,20062,20063,7,9,0,0,20063,20064,7,11,
	0,0,20064,20065,7,18,0,0,20065,2818,1,0,0,0,20066,20067,7,3,0,0,20067,20068,
	7,0,0,0,20068,20069,7,22,0,0,20069,2820,1,0,0,0,20070,20071,7,3,0,0,20071,
	20072,7,0,0,0,20072,20073,7,22,0,0,20073,20074,7,4,0,0,20074,20075,7,2,
	0,0,20075,20076,7,19,0,0,20076,20077,7,6,0,0,20077,20078,7,25,0,0,20078,
	2822,1,0,0,0,20079,20080,7,3,0,0,20080,20081,7,0,0,0,20081,20082,7,22,0,
	0,20082,20083,7,4,0,0,20083,20084,7,2,0,0,20084,20085,7,7,0,0,20085,20086,
	7,19,0,0,20086,20087,7,6,0,0,20087,20088,7,25,0,0,20088,2824,1,0,0,0,20089,
	20090,7,3,0,0,20090,20091,7,1,0,0,20091,20092,7,0,0,0,20092,2826,1,0,0,
	0,20093,20094,7,3,0,0,20094,20095,7,1,0,0,20095,20096,7,2,0,0,20096,20097,
	5,95,0,0,20097,20098,7,2,0,0,20098,20099,7,10,0,0,20099,20100,7,4,0,0,20100,
	20101,7,11,0,0,20101,20102,7,12,0,0,20102,20103,7,7,0,0,20103,20104,7,6,
	0,0,20104,2828,1,0,0,0,20105,20106,7,3,0,0,20106,20107,7,9,0,0,20107,20108,
	7,1,0,0,20108,20109,7,0,0,0,20109,2830,1,0,0,0,20110,20111,7,3,0,0,20111,
	20112,7,6,0,0,20112,20113,7,0,0,0,20113,20114,7,9,0,0,20114,2832,1,0,0,
	0,20115,20116,7,3,0,0,20116,20117,7,6,0,0,20117,20118,7,0,0,0,20118,20119,
	7,9,0,0,20119,20120,7,5,0,0,20120,2834,1,0,0,0,20121,20122,7,3,0,0,20122,
	20123,7,6,0,0,20123,20124,7,0,0,0,20124,20125,7,11,0,0,20125,20126,7,14,
	0,0,20126,2836,1,0,0,0,20127,20128,7,3,0,0,20128,20129,7,6,0,0,20129,20130,
	7,0,0,0,20130,20131,7,11,0,0,20131,2838,1,0,0,0,20132,20133,7,3,0,0,20133,
	20134,7,6,0,0,20134,20135,7,1,0,0,20135,20136,7,0,0,0,20136,20137,7,11,
	0,0,20137,20138,7,0,0,0,20138,20139,7,7,0,0,20139,20140,7,8,0,0,20140,20141,
	7,6,0,0,20141,2840,1,0,0,0,20142,20143,7,3,0,0,20143,20144,7,6,0,0,20144,
	20145,7,1,0,0,20145,20146,7,10,0,0,20146,20147,7,12,0,0,20147,20148,7,11,
	0,0,20148,20149,7,9,0,0,20149,2842,1,0,0,0,20150,20151,7,3,0,0,20151,20152,
	7,6,0,0,20152,20153,7,8,0,0,20153,20154,7,2,0,0,20154,20155,7,3,0,0,20155,
	20156,7,9,0,0,20156,2844,1,0,0,0,20157,20158,7,3,0,0,20158,20159,7,6,0,
	0,20159,20160,7,8,0,0,20160,20161,7,2,0,0,20161,20162,7,3,0,0,20162,20163,
	7,9,0,0,20163,20164,7,5,0,0,20164,20165,5,95,0,0,20165,20166,7,15,0,0,20166,
	20167,7,6,0,0,20167,20168,7,3,0,0,20168,20169,5,95,0,0,20169,20170,7,1,
	0,0,20170,20171,7,11,0,0,20171,20172,7,2,0,0,20172,20173,7,8,0,0,20173,
	20174,7,21,0,0,20174,2846,1,0,0,0,20175,20176,7,3,0,0,20176,20177,7,6,0,
	0,20177,20178,7,8,0,0,20178,20179,7,2,0,0,20179,20180,7,13,0,0,20180,20181,
	7,6,0,0,20181,20182,7,3,0,0,20182,20183,7,0,0,0,20183,20184,7,1,0,0,20184,
	20185,7,11,0,0,20185,20186,7,6,0,0,20186,2848,1,0,0,0,20187,20188,7,3,0,
	0,20188,20189,7,6,0,0,20189,20190,7,8,0,0,20190,20191,7,2,0,0,20191,20192,
	7,13,0,0,20192,20193,7,6,0,0,20193,20194,7,3,0,0,20194,2850,1,0,0,0,20195,
	20196,7,3,0,0,20196,20197,7,6,0,0,20197,20198,7,8,0,0,20198,20199,7,2,0,
	0,20199,20200,7,13,0,0,20200,20201,7,6,0,0,20201,20202,7,3,0,0,20202,20203,
	7,18,0,0,20203,2852,1,0,0,0,20204,20205,7,3,0,0,20205,20206,7,6,0,0,20206,
	20207,7,8,0,0,20207,20208,7,18,0,0,20208,20209,7,8,0,0,20209,20210,7,11,
	0,0,20210,20211,7,6,0,0,20211,20212,7,1,0,0,20212,20213,7,12,0,0,20213,
	20214,7,7,0,0,20214,2854,1,0,0,0,20215,20216,7,3,0,0,20216,20217,7,6,0,
	0,20217,20218,7,8,0,0,20218,20219,7,18,0,0,20219,20220,7,8,0,0,20220,20221,
	7,11,0,0,20221,20222,7,6,0,0,20222,2856,1,0,0,0,20223,20224,7,3,0,0,20224,
	20225,7,6,0,0,20225,20226,7,9,0,0,20226,20227,7,0,0,0,20227,20228,7,8,0,
	0,20228,20229,7,4,0,0,20229,20230,7,12,0,0,20230,20231,7,2,0,0,20231,20232,
	7,7,0,0,20232,2858,1,0,0,0,20233,20234,7,3,0,0,20234,20235,7,6,0,0,20235,
	20236,7,9,0,0,20236,20237,7,6,0,0,20237,20238,7,16,0,0,20238,20239,7,12,
	0,0,20239,20240,7,7,0,0,20240,20241,7,6,0,0,20241,2860,1,0,0,0,20242,20243,
	7,3,0,0,20243,20244,7,6,0,0,20244,20245,7,9,0,0,20245,20246,7,2,0,0,20246,
	2862,1,0,0,0,20247,20248,7,3,0,0,20248,20249,7,6,0,0,20249,20250,7,9,0,
	0,20250,20251,7,10,0,0,20251,20252,7,8,0,0,20252,20253,7,6,0,0,20253,20254,
	7,9,0,0,20254,2864,1,0,0,0,20255,20256,7,3,0,0,20256,20257,7,6,0,0,20257,
	20258,7,9,0,0,20258,20259,7,10,0,0,20259,20260,7,7,0,0,20260,20261,7,9,
	0,0,20261,20262,7,0,0,0,20262,20263,7,7,0,0,20263,20264,7,8,0,0,20264,20265,
	7,18,0,0,20265,2866,1,0,0,0,20266,20267,7,3,0,0,20267,20268,7,6,0,0,20268,
	20269,7,16,0,0,20269,20270,5,95,0,0,20270,20271,7,8,0,0,20271,20272,7,0,
	0,0,20272,20273,7,5,0,0,20273,20274,7,8,0,0,20274,20275,7,0,0,0,20275,20276,
	7,9,0,0,20276,20277,7,6,0,0,20277,20278,5,95,0,0,20278,20279,7,8,0,0,20279,
	20280,7,10,0,0,20280,20281,7,3,0,0,20281,20282,7,5,0,0,20282,20283,7,2,
	0,0,20283,20284,7,3,0,0,20284,2868,1,0,0,0,20285,20286,7,3,0,0,20286,20287,
	7,6,0,0,20287,20288,7,16,0,0,20288,20289,7,6,0,0,20289,20290,7,3,0,0,20290,
	20291,7,6,0,0,20291,20292,7,7,0,0,20292,20293,7,8,0,0,20293,20294,7,6,0,
	0,20294,20295,7,9,0,0,20295,2870,1,0,0,0,20296,20297,7,3,0,0,20297,20298,
	7,6,0,0,20298,20299,7,16,0,0,20299,20300,7,6,0,0,20300,20301,7,3,0,0,20301,
	20302,7,6,0,0,20302,20303,7,7,0,0,20303,20304,7,8,0,0,20304,20305,7,6,0,
	0,20305,2872,1,0,0,0,20306,20307,7,3,0,0,20307,20308,7,6,0,0,20308,20309,
	7,16,0,0,20309,20310,7,6,0,0,20310,20311,7,3,0,0,20311,20312,7,6,0,0,20312,
	20313,7,7,0,0,20313,20314,7,8,0,0,20314,20315,7,6,0,0,20315,20316,7,5,0,
	0,20316,2874,1,0,0,0,20317,20318,7,3,0,0,20318,20319,7,6,0,0,20319,20320,
	7,16,0,0,20320,20321,7,6,0,0,20321,20322,7,3,0,0,20322,20323,7,6,0,0,20323,
	20324,7,7,0,0,20324,20325,7,8,0,0,20325,20326,7,12,0,0,20326,20327,7,7,
	0,0,20327,20328,7,17,0,0,20328,2876,1,0,0,0,20329,20330,7,3,0,0,20330,20331,
	7,6,0,0,20331,20332,7,16,0,0,20332,2878,1,0,0,0,20333,20334,7,3,0,0,20334,
	20335,7,6,0,0,20335,20336,7,16,0,0,20336,20337,7,3,0,0,20337,20338,7,6,
	0,0,20338,20339,7,5,0,0,20339,20340,7,19,0,0,20340,2880,1,0,0,0,20341,20342,
	7,3,0,0,20342,20343,7,6,0,0,20343,20344,7,16,0,0,20344,20345,7,4,0,0,20345,
	20346,7,2,0,0,20346,20347,7,19,0,0,20347,20348,7,6,0,0,20348,20349,7,25,
	0,0,20349,2882,1,0,0,0,20350,20351,7,3,0,0,20351,20352,7,6,0,0,20352,20353,
	7,17,0,0,20353,20354,7,6,0,0,20354,20355,7,25,0,0,20355,20356,7,15,0,0,
	20356,20357,5,95,0,0,20357,20358,7,8,0,0,20358,20359,7,2,0,0,20359,20360,
	7,10,0,0,20360,20361,7,7,0,0,20361,20362,7,4,0,0,20362,2884,1,0,0,0,20363,
	20364,7,3,0,0,20364,20365,7,6,0,0,20365,20366,7,17,0,0,20366,20367,7,6,
	0,0,20367,20368,7,25,0,0,20368,20369,7,15,0,0,20369,20370,5,95,0,0,20370,
	20371,7,12,0,0,20371,20372,7,7,0,0,20372,20373,7,5,0,0,20373,20374,7,4,
	0,0,20374,20375,7,3,0,0,20375,2886,1,0,0,0,20376,20377,7,3,0,0,20377,20378,
	7,6,0,0,20378,20379,7,17,0,0,20379,20380,7,6,0,0,20380,20381,7,25,0,0,20381,
	20382,7,15,0,0,20382,20383,5,95,0,0,20383,20384,7,11,0,0,20384,20385,7,
	12,0,0,20385,20386,7,21,0,0,20386,20387,7,6,0,0,20387,2888,1,0,0,0,20388,
	20389,7,3,0,0,20389,20390,7,6,0,0,20390,20391,7,17,0,0,20391,20392,7,6,
	0,0,20392,20393,7,25,0,0,20393,20394,7,15,0,0,20394,20395,5,95,0,0,20395,
	20396,7,3,0,0,20396,20397,7,6,0,0,20397,20398,7,15,0,0,20398,20399,7,11,
	0,0,20399,20400,7,0,0,0,20400,20401,7,8,0,0,20401,20402,7,6,0,0,20402,2890,
	1,0,0,0,20403,20404,7,3,0,0,20404,20405,7,6,0,0,20405,20406,7,17,0,0,20406,
	20407,7,6,0,0,20407,20408,7,25,0,0,20408,20409,7,15,0,0,20409,20410,5,95,
	0,0,20410,20411,7,5,0,0,20411,20412,7,10,0,0,20412,20413,7,1,0,0,20413,
	20414,7,5,0,0,20414,20415,7,4,0,0,20415,20416,7,3,0,0,20416,2892,1,0,0,
	0,20417,20418,7,3,0,0,20418,20419,7,6,0,0,20419,20420,7,17,0,0,20420,20421,
	7,12,0,0,20421,20422,7,5,0,0,20422,20423,7,4,0,0,20423,20424,7,6,0,0,20424,
	20425,7,3,0,0,20425,2894,1,0,0,0,20426,20427,7,3,0,0,20427,20428,7,6,0,
	0,20428,20429,7,17,0,0,20429,20430,7,3,0,0,20430,20431,5,95,0,0,20431,20432,
	7,0,0,0,20432,20433,7,13,0,0,20433,20434,7,17,0,0,20434,20435,7,25,0,0,
	20435,2896,1,0,0,0,20436,20437,7,3,0,0,20437,20438,7,6,0,0,20438,20439,
	7,17,0,0,20439,20440,7,3,0,0,20440,20441,5,95,0,0,20441,20442,7,0,0,0,20442,
	20443,7,13,0,0,20443,20444,7,17,0,0,20444,20445,7,18,0,0,20445,2898,1,0,
	0,0,20446,20447,7,3,0,0,20447,20448,7,6,0,0,20448,20449,7,17,0,0,20449,
	20450,7,3,0,0,20450,20451,5,95,0,0,20451,20452,7,8,0,0,20452,20453,7,2,
	0,0,20453,20454,7,10,0,0,20454,20455,7,7,0,0,20455,20456,7,4,0,0,20456,
	2900,1,0,0,0,20457,20458,7,3,0,0,20458,20459,7,6,0,0,20459,20460,7,17,0,
	0,20460,20461,7,3,0,0,20461,20462,5,95,0,0,20462,20463,7,12,0,0,20463,20464,
	7,7,0,0,20464,20465,7,4,0,0,20465,20466,7,6,0,0,20466,20467,7,3,0,0,20467,
	20468,7,8,0,0,20468,20469,7,6,0,0,20469,20470,7,15,0,0,20470,20471,7,4,
	0,0,20471,2902,1,0,0,0,20472,20473,7,3,0,0,20473,20474,7,6,0,0,20474,20475,
	7,17,0,0,20475,20476,7,3,0,0,20476,20477,5,95,0,0,20477,20478,7,3,0,0,20478,
	20479,5,50,0,0,20479,2904,1,0,0,0,20480,20481,7,3,0,0,20481,20482,7,6,0,
	0,20482,20483,7,17,0,0,20483,20484,7,3,0,0,20484,20485,5,95,0,0,20485,20486,
	7,5,0,0,20486,20487,7,11,0,0,20487,20488,7,2,0,0,20488,20489,7,15,0,0,20489,
	20490,7,6,0,0,20490,2906,1,0,0,0,20491,20492,7,3,0,0,20492,20493,7,6,0,
	0,20493,20494,7,17,0,0,20494,20495,7,3,0,0,20495,20496,5,95,0,0,20496,20497,
	7,5,0,0,20497,20498,7,25,0,0,20498,20499,7,25,0,0,20499,2908,1,0,0,0,20500,
	20501,7,3,0,0,20501,20502,7,6,0,0,20502,20503,7,17,0,0,20503,20504,7,3,
	0,0,20504,20505,5,95,0,0,20505,20506,7,5,0,0,20506,20507,7,25,0,0,20507,
	20508,7,18,0,0,20508,2910,1,0,0,0,20509,20510,7,3,0,0,20510,20511,7,6,0,
	0,20511,20512,7,17,0,0,20512,20513,7,3,0,0,20513,20514,5,95,0,0,20514,20515,
	7,5,0,0,20515,20516,7,18,0,0,20516,20517,7,18,0,0,20517,2912,1,0,0,0,20518,
	20519,7,3,0,0,20519,20520,7,6,0,0,20520,20521,7,17,0,0,20521,20522,7,10,
	0,0,20522,20523,7,11,0,0,20523,20524,7,0,0,0,20524,20525,7,3,0,0,20525,
	2914,1,0,0,0,20526,20527,7,3,0,0,20527,20528,7,6,0,0,20528,20529,7,20,0,
	0,20529,20530,7,6,0,0,20530,20531,7,8,0,0,20531,20532,7,4,0,0,20532,2916,
	1,0,0,0,20533,20534,7,3,0,0,20534,20535,7,6,0,0,20535,20536,7,21,0,0,20536,
	20537,7,6,0,0,20537,20538,7,18,0,0,20538,2918,1,0,0,0,20539,20540,7,3,0,
	0,20540,20541,7,6,0,0,20541,20542,7,11,0,0,20542,20543,7,0,0,0,20543,20544,
	7,4,0,0,20544,20545,7,12,0,0,20545,20546,7,2,0,0,20546,20547,7,7,0,0,20547,
	20548,7,0,0,0,20548,20549,7,11,0,0,20549,2920,1,0,0,0,20550,20551,7,3,0,
	0,20551,20552,7,6,0,0,20552,20553,7,11,0,0,20553,20554,7,12,0,0,20554,20555,
	7,6,0,0,20555,20556,7,5,0,0,20556,20557,5,95,0,0,20557,20558,7,2,0,0,20558,
	20559,7,7,0,0,20559,2922,1,0,0,0,20560,20561,7,3,0,0,20561,20562,7,6,0,
	0,20562,20563,7,11,0,0,20563,20564,7,2,0,0,20564,20565,7,8,0,0,20565,20566,
	7,0,0,0,20566,20567,7,4,0,0,20567,20568,7,6,0,0,20568,2924,1,0,0,0,20569,
	20570,7,3,0,0,20570,20571,7,6,0,0,20571,20572,7,11,0,0,20572,20573,7,18,
	0,0,20573,2926,1,0,0,0,20574,20575,7,3,0,0,20575,20576,7,6,0,0,20576,20577,
	7,14,0,0,20577,20578,7,0,0,0,20578,20579,7,12,0,0,20579,20580,7,7,0,0,20580,
	20581,7,9,0,0,20581,20582,7,6,0,0,20582,20583,7,3,0,0,20583,2928,1,0,0,
	0,20584,20585,7,3,0,0,20585,20586,7,6,0,0,20586,20587,7,14,0,0,20587,20588,
	7,2,0,0,20588,20589,7,4,0,0,20589,20590,7,6,0,0,20590,2930,1,0,0,0,20591,
	20592,7,3,0,0,20592,20593,7,6,0,0,20593,20594,7,14,0,0,20594,20595,7,2,
	0,0,20595,20596,7,4,0,0,20596,20597,7,6,0,0,20597,20598,5,95,0,0,20598,
	20599,7,14,0,0,20599,20600,7,0,0,0,20600,20601,7,15,0,0,20601,20602,7,15,
	0,0,20602,20603,7,6,0,0,20603,20604,7,9,0,0,20604,2932,1,0,0,0,20605,20606,
	7,3,0,0,20606,20607,7,6,0,0,20607,20608,7,14,0,0,20608,20609,7,2,0,0,20609,
	20610,7,13,0,0,20610,20611,7,6,0,0,20611,2934,1,0,0,0,20612,20613,7,3,0,
	0,20613,20614,7,6,0,0,20614,20615,7,7,0,0,20615,20616,7,0,0,0,20616,20617,
	7,14,0,0,20617,20618,7,6,0,0,20618,2936,1,0,0,0,20619,20620,7,3,0,0,20620,
	20621,7,6,0,0,20621,20622,7,15,0,0,20622,20623,7,0,0,0,20623,20624,7,12,
	0,0,20624,20625,7,3,0,0,20625,2938,1,0,0,0,20626,20627,7,3,0,0,20627,20628,
	7,6,0,0,20628,20629,7,15,0,0,20629,20630,7,6,0,0,20630,20631,7,0,0,0,20631,
	20632,7,4,0,0,20632,2940,1,0,0,0,20633,20634,7,3,0,0,20634,20635,7,6,0,
	0,20635,20636,7,15,0,0,20636,20637,7,11,0,0,20637,20638,7,0,0,0,20638,20639,
	7,8,0,0,20639,20640,7,6,0,0,20640,2942,1,0,0,0,20641,20642,7,3,0,0,20642,
	20643,7,6,0,0,20643,20644,7,15,0,0,20644,20645,7,11,0,0,20645,20646,7,12,
	0,0,20646,20647,7,8,0,0,20647,20648,7,0,0,0,20648,20649,7,4,0,0,20649,20650,
	7,12,0,0,20650,20651,7,2,0,0,20651,20652,7,7,0,0,20652,2944,1,0,0,0,20653,
	20654,7,3,0,0,20654,20655,7,6,0,0,20655,20656,7,24,0,0,20656,20657,7,10,
	0,0,20657,20658,7,12,0,0,20658,20659,7,3,0,0,20659,20660,7,6,0,0,20660,
	20661,7,9,0,0,20661,2946,1,0,0,0,20662,20663,7,3,0,0,20663,20664,7,6,0,
	0,20664,20665,7,5,0,0,20665,20666,7,6,0,0,20666,20667,7,4,0,0,20667,20668,
	7,11,0,0,20668,20669,7,2,0,0,20669,20670,7,17,0,0,20670,20671,7,5,0,0,20671,
	2948,1,0,0,0,20672,20673,7,3,0,0,20673,20674,7,6,0,0,20674,20675,7,5,0,
	0,20675,20676,7,6,0,0,20676,20677,7,4,0,0,20677,2950,1,0,0,0,20678,20679,
	7,3,0,0,20679,20680,7,6,0,0,20680,20681,7,5,0,0,20681,20682,7,12,0,0,20682,
	20683,7,23,0,0,20683,20684,7,6,0,0,20684,2952,1,0,0,0,20685,20686,7,3,0,
	0,20686,20687,7,6,0,0,20687,20688,7,5,0,0,20688,20689,7,2,0,0,20689,20690,
	7,11,0,0,20690,20691,7,13,0,0,20691,20692,7,6,0,0,20692,2954,1,0,0,0,20693,
	20694,7,3,0,0,20694,20695,7,6,0,0,20695,20696,7,5,0,0,20696,20697,7,2,0,
	0,20697,20698,7,11,0,0,20698,20699,7,13,0,0,20699,20700,7,6,0,0,20700,20701,
	7,3,0,0,20701,2956,1,0,0,0,20702,20703,7,3,0,0,20703,20704,7,6,0,0,20704,
	20705,7,5,0,0,20705,20706,7,2,0,0,20706,20707,7,10,0,0,20707,20708,7,3,
	0,0,20708,20709,7,8,0,0,20709,20710,7,6,0,0,20710,2958,1,0,0,0,20711,20712,
	7,3,0,0,20712,20713,7,6,0,0,20713,20714,7,5,0,0,20714,20715,7,15,0,0,20715,
	20716,7,6,0,0,20716,20717,7,8,0,0,20717,20718,7,4,0,0,20718,2960,1,0,0,
	0,20719,20720,7,3,0,0,20720,20721,7,6,0,0,20721,20722,7,5,0,0,20722,20723,
	7,4,0,0,20723,20724,7,0,0,0,20724,20725,7,3,0,0,20725,20726,7,4,0,0,20726,
	2962,1,0,0,0,20727,20728,7,3,0,0,20728,20729,7,6,0,0,20729,20730,7,5,0,
	0,20730,20731,7,4,0,0,20731,20732,7,2,0,0,20732,20733,7,3,0,0,20733,20734,
	7,6,0,0,20734,20735,5,95,0,0,20735,20736,7,0,0,0,20736,20737,7,5,0,0,20737,
	20738,5,95,0,0,20738,20739,7,12,0,0,20739,20740,7,7,0,0,20740,20741,7,4,
	0,0,20741,20742,7,6,0,0,20742,20743,7,3,0,0,20743,20744,7,13,0,0,20744,
	20745,7,0,0,0,20745,20746,7,11,0,0,20746,20747,7,5,0,0,20747,2964,1,0,0,
	0,20748,20749,7,3,0,0,20749,20750,7,6,0,0,20750,20751,7,5,0,0,20751,20752,
	7,4,0,0,20752,20753,7,2,0,0,20753,20754,7,3,0,0,20754,20755,7,6,0,0,20755,
	2966,1,0,0,0,20756,20757,7,3,0,0,20757,20758,7,6,0,0,20758,20759,7,5,0,
	0,20759,20760,7,4,0,0,20760,20761,7,3,0,0,20761,20762,7,12,0,0,20762,20763,
	7,8,0,0,20763,20764,7,4,0,0,20764,20765,5,95,0,0,20765,20766,7,0,0,0,20766,
	20767,7,11,0,0,20767,20768,7,11,0,0,20768,20769,5,95,0,0,20769,20770,7,
	3,0,0,20770,20771,7,6,0,0,20771,20772,7,16,0,0,20772,20773,5,95,0,0,20773,
	20774,7,8,0,0,20774,20775,7,2,0,0,20775,20776,7,7,0,0,20776,20777,7,5,0,
	0,20777,2968,1,0,0,0,20778,20779,7,3,0,0,20779,20780,7,6,0,0,20780,20781,
	7,5,0,0,20781,20782,7,4,0,0,20782,20783,7,3,0,0,20783,20784,7,12,0,0,20784,
	20785,7,8,0,0,20785,20786,7,4,0,0,20786,20787,7,6,0,0,20787,20788,7,9,0,
	0,20788,2970,1,0,0,0,20789,20790,7,3,0,0,20790,20791,7,6,0,0,20791,20792,
	7,5,0,0,20792,20793,7,4,0,0,20793,20794,7,3,0,0,20794,20795,7,12,0,0,20795,
	20796,7,8,0,0,20796,20797,7,4,0,0,20797,20798,5,95,0,0,20798,20799,7,3,
	0,0,20799,20800,7,6,0,0,20800,20801,7,16,0,0,20801,20802,7,6,0,0,20802,
	20803,7,3,0,0,20803,20804,7,6,0,0,20804,20805,7,7,0,0,20805,20806,7,8,0,
	0,20806,20807,7,6,0,0,20807,20808,7,5,0,0,20808,2972,1,0,0,0,20809,20810,
	7,3,0,0,20810,20811,7,6,0,0,20811,20812,7,5,0,0,20812,20813,7,4,0,0,20813,
	20814,7,3,0,0,20814,20815,7,12,0,0,20815,20816,7,8,0,0,20816,20817,7,4,
	0,0,20817,2974,1,0,0,0,20818,20819,7,3,0,0,20819,20820,7,6,0,0,20820,20821,
	7,5,0,0,20821,20822,7,10,0,0,20822,20823,7,11,0,0,20823,20824,7,4,0,0,20824,
	20825,5,95,0,0,20825,20826,7,8,0,0,20826,20827,7,0,0,0,20827,20828,7,8,
	0,0,20828,20829,7,19,0,0,20829,20830,7,6,0,0,20830,2976,1,0,0,0,20831,20832,
	7,3,0,0,20832,20833,7,6,0,0,20833,20834,7,5,0,0,20834,20835,7,10,0,0,20835,
	20836,7,11,0,0,20836,20837,7,4,0,0,20837,2978,1,0,0,0,20838,20839,7,3,0,
	0,20839,20840,7,6,0,0,20840,20841,7,5,0,0,20841,20842,7,10,0,0,20842,20843,
	7,14,0,0,20843,20844,7,0,0,0,20844,20845,7,1,0,0,20845,20846,7,11,0,0,20846,
	20847,7,6,0,0,20847,2980,1,0,0,0,20848,20849,7,3,0,0,20849,20850,7,6,0,
	0,20850,20851,7,5,0,0,20851,20852,7,10,0,0,20852,20853,7,14,0,0,20853,20854,
	7,6,0,0,20854,2982,1,0,0,0,20855,20856,7,3,0,0,20856,20857,7,6,0,0,20857,
	20858,7,4,0,0,20858,20859,7,6,0,0,20859,20860,7,7,0,0,20860,20861,7,4,0,
	0,20861,20862,7,12,0,0,20862,20863,7,2,0,0,20863,20864,7,7,0,0,20864,2984,
	1,0,0,0,20865,20866,7,3,0,0,20866,20867,7,6,0,0,20867,20868,7,4,0,0,20868,
	20869,7,3,0,0,20869,20870,7,18,0,0,20870,20871,5,95,0,0,20871,20872,7,2,
	0,0,20872,20873,7,7,0,0,20873,20874,5,95,0,0,20874,20875,7,3,0,0,20875,
	20876,7,2,0,0,20876,20877,7,22,0,0,20877,20878,5,95,0,0,20878,20879,7,8,
	0,0,20879,20880,7,19,0,0,20880,20881,7,0,0,0,20881,20882,7,7,0,0,20882,
	20883,7,17,0,0,20883,20884,7,6,0,0,20884,2986,1,0,0,0,20885,20886,7,3,0,
	0,20886,20887,7,6,0,0,20887,20888,7,4,0,0,20888,20889,7,10,0,0,20889,20890,
	7,3,0,0,20890,20891,7,7,0,0,20891,20892,7,12,0,0,20892,20893,7,7,0,0,20893,
	20894,7,17,0,0,20894,2988,1,0,0,0,20895,20896,7,3,0,0,20896,20897,7,6,0,
	0,20897,20898,7,4,0,0,20898,20899,7,10,0,0,20899,20900,7,3,0,0,20900,20901,
	7,7,0,0,20901,2990,1,0,0,0,20902,20903,7,3,0,0,20903,20904,7,6,0,0,20904,
	20905,7,10,0,0,20905,20906,7,5,0,0,20906,20907,7,6,0,0,20907,2992,1,0,0,
	0,20908,20909,7,3,0,0,20909,20910,7,6,0,0,20910,20911,7,13,0,0,20911,20912,
	7,6,0,0,20912,20913,7,3,0,0,20913,20914,7,5,0,0,20914,20915,7,6,0,0,20915,
	2994,1,0,0,0,20916,20917,7,3,0,0,20917,20918,7,6,0,0,20918,20919,7,13,0,
	0,20919,20920,7,2,0,0,20920,20921,7,21,0,0,20921,20922,7,6,0,0,20922,2996,
	1,0,0,0,20923,20924,7,3,0,0,20924,20925,7,6,0,0,20925,20926,7,22,0,0,20926,
	20927,7,3,0,0,20927,20928,7,12,0,0,20928,20929,7,4,0,0,20929,20930,7,6,
	0,0,20930,20931,5,95,0,0,20931,20932,7,2,0,0,20932,20933,7,3,0,0,20933,
	20934,5,95,0,0,20934,20935,7,6,0,0,20935,20936,7,3,0,0,20936,20937,7,3,
	0,0,20937,20938,7,2,0,0,20938,20939,7,3,0,0,20939,2998,1,0,0,0,20940,20941,
	7,3,0,0,20941,20942,7,6,0,0,20942,20943,7,22,0,0,20943,20944,7,3,0,0,20944,
	20945,7,12,0,0,20945,20946,7,4,0,0,20946,20947,7,6,0,0,20947,3000,1,0,0,
	0,20948,20949,7,3,0,0,20949,20950,7,12,0,0,20950,20951,7,17,0,0,20951,20952,
	7,19,0,0,20952,20953,7,4,0,0,20953,3002,1,0,0,0,20954,20955,7,3,0,0,20955,
	20956,7,2,0,0,20956,20957,7,11,0,0,20957,20958,7,6,0,0,20958,3004,1,0,0,
	0,20959,20960,7,3,0,0,20960,20961,7,2,0,0,20961,20962,7,11,0,0,20962,20963,
	7,6,0,0,20963,20964,7,5,0,0,20964,20965,7,6,0,0,20965,20966,7,4,0,0,20966,
	3006,1,0,0,0,20967,20968,7,3,0,0,20968,20969,7,2,0,0,20969,20970,7,11,0,
	0,20970,20971,7,6,0,0,20971,20972,7,5,0,0,20972,3008,1,0,0,0,20973,20974,
	7,3,0,0,20974,20975,7,2,0,0,20975,20976,7,11,0,0,20976,20977,7,11,0,0,20977,
	20978,7,1,0,0,20978,20979,7,0,0,0,20979,20980,7,8,0,0,20980,20981,7,21,
	0,0,20981,3010,1,0,0,0,20982,20983,7,3,0,0,20983,20984,7,2,0,0,20984,20985,
	7,11,0,0,20985,20986,7,11,0,0,20986,20987,7,12,0,0,20987,20988,7,7,0,0,
	20988,20989,7,17,0,0,20989,3012,1,0,0,0,20990,20991,7,3,0,0,20991,20992,
	7,2,0,0,20992,20993,7,11,0,0,20993,20994,7,11,0,0,20994,20995,7,10,0,0,
	20995,20996,7,15,0,0,20996,3014,1,0,0,0,20997,20998,7,3,0,0,20998,20999,
	7,2,0,0,20999,21000,7,22,0,0,21000,21001,7,9,0,0,21001,21002,7,6,0,0,21002,
	21003,7,15,0,0,21003,21004,7,6,0,0,21004,21005,7,7,0,0,21005,21006,7,9,
	0,0,21006,21007,7,6,0,0,21007,21008,7,7,0,0,21008,21009,7,8,0,0,21009,21010,
	7,12,0,0,21010,21011,7,6,0,0,21011,21012,7,5,0,0,21012,3016,1,0,0,0,21013,
	21014,7,3,0,0,21014,21015,7,2,0,0,21015,21016,7,22,0,0,21016,21017,7,12,
	0,0,21017,21018,7,9,0,0,21018,21019,5,95,0,0,21019,21020,7,14,0,0,21020,
	21021,7,0,0,0,21021,21022,7,15,0,0,21022,21023,7,15,0,0,21023,21024,7,12,
	0,0,21024,21025,7,7,0,0,21025,21026,7,17,0,0,21026,21027,5,95,0,0,21027,
	21028,7,4,0,0,21028,21029,7,0,0,0,21029,21030,7,1,0,0,21030,21031,7,11,
	0,0,21031,21032,7,6,0,0,21032,3018,1,0,0,0,21033,21034,7,3,0,0,21034,21035,
	7,2,0,0,21035,21036,7,22,0,0,21036,21037,7,12,0,0,21037,21038,7,9,0,0,21038,
	3020,1,0,0,0,21039,21040,7,3,0,0,21040,21041,7,2,0,0,21041,21042,7,22,0,
	0,21042,21043,7,12,0,0,21043,21044,7,9,0,0,21044,21045,7,4,0,0,21045,21046,
	7,2,0,0,21046,21047,7,8,0,0,21047,21048,7,19,0,0,21048,21049,7,0,0,0,21049,
	21050,7,3,0,0,21050,3022,1,0,0,0,21051,21052,7,3,0,0,21052,21053,7,2,0,
	0,21053,21054,7,22,0,0,21054,21055,7,12,0,0,21055,21056,7,9,0,0,21056,21057,
	7,4,0,0,21057,21058,7,2,0,0,21058,21059,7,7,0,0,21059,21060,7,8,0,0,21060,
	21061,7,19,0,0,21061,21062,7,0,0,0,21062,21063,7,3,0,0,21063,3024,1,0,0,
	0,21064,21065,7,3,0,0,21065,21066,7,2,0,0,21066,21067,7,22,0,0,21067,21068,
	5,95,0,0,21068,21069,7,11,0,0,21069,21070,7,6,0,0,21070,21071,7,7,0,0,21071,
	21072,7,17,0,0,21072,21073,7,4,0,0,21073,21074,7,19,0,0,21074,3026,1,0,
	0,0,21075,21076,7,3,0,0,21076,21077,7,2,0,0,21077,21078,7,22,0,0,21078,
	21079,7,7,0,0,21079,21080,7,10,0,0,21080,21081,7,14,0,0,21081,3028,1,0,
	0,0,21082,21083,7,3,0,0,21083,21084,7,2,0,0,21084,21085,7,22,0,0,21085,
	3030,1,0,0,0,21086,21087,7,3,0,0,21087,21088,7,2,0,0,21088,21089,7,22,0,
	0,21089,21090,7,5,0,0,21090,3032,1,0,0,0,21091,21092,7,3,0,0,21092,21093,
	7,15,0,0,21093,21094,7,0,0,0,21094,21095,7,9,0,0,21095,3034,1,0,0,0,21096,
	21097,7,3,0,0,21097,21098,7,4,0,0,21098,21099,7,3,0,0,21099,21100,7,12,
	0,0,21100,21101,7,14,0,0,21101,3036,1,0,0,0,21102,21103,7,3,0,0,21103,21104,
	7,10,0,0,21104,21105,7,11,0,0,21105,21106,7,6,0,0,21106,3038,1,0,0,0,21107,
	21108,7,3,0,0,21108,21109,7,10,0,0,21109,21110,7,11,0,0,21110,21111,7,6,
	0,0,21111,21112,7,5,0,0,21112,3040,1,0,0,0,21113,21114,7,3,0,0,21114,21115,
	7,10,0,0,21115,21116,7,7,0,0,21116,21117,7,7,0,0,21117,21118,7,12,0,0,21118,
	21119,7,7,0,0,21119,21120,7,17,0,0,21120,3042,1,0,0,0,21121,21122,7,5,0,
	0,21122,21123,7,0,0,0,21123,21124,7,11,0,0,21124,21125,7,4,0,0,21125,3044,
	1,0,0,0,21126,21127,7,5,0,0,21127,21128,7,0,0,0,21128,21129,7,14,0,0,21129,
	21130,7,15,0,0,21130,21131,7,11,0,0,21131,21132,7,6,0,0,21132,3046,1,0,
	0,0,21133,21134,7,5,0,0,21134,21135,7,0,0,0,21135,21136,7,13,0,0,21136,
	21137,7,6,0,0,21137,21138,5,95,0,0,21138,21139,7,0,0,0,21139,21140,7,5,
	0,0,21140,21141,5,95,0,0,21141,21142,7,12,0,0,21142,21143,7,7,0,0,21143,
	21144,7,4,0,0,21144,21145,7,6,0,0,21145,21146,7,3,0,0,21146,21147,7,13,
	0,0,21147,21148,7,0,0,0,21148,21149,7,11,0,0,21149,21150,7,5,0,0,21150,
	3048,1,0,0,0,21151,21152,7,5,0,0,21152,21153,7,0,0,0,21153,21154,7,13,0,
	0,21154,21155,7,6,0,0,21155,21156,7,15,0,0,21156,21157,7,2,0,0,21157,21158,
	7,12,0,0,21158,21159,7,7,0,0,21159,21160,7,4,0,0,21160,3050,1,0,0,0,21161,
	21162,7,5,0,0,21162,21163,7,0,0,0,21163,21164,7,13,0,0,21164,21165,7,6,
	0,0,21165,3052,1,0,0,0,21166,21167,7,5,0,0,21167,21168,7,1,0,0,21168,21169,
	5,52,0,0,21169,3054,1,0,0,0,21170,21171,7,5,0,0,21171,21172,7,8,0,0,21172,
	21173,7,0,0,0,21173,21174,7,11,0,0,21174,21175,7,6,0,0,21175,21176,5,95,
	0,0,21176,21177,7,3,0,0,21177,21178,7,2,0,0,21178,21179,7,22,0,0,21179,
	21180,7,5,0,0,21180,3056,1,0,0,0,21181,21182,7,5,0,0,21182,21183,7,8,0,
	0,21183,21184,7,0,0,0,21184,21185,7,11,0,0,21185,21186,7,6,0,0,21186,3058,
	1,0,0,0,21187,21188,7,5,0,0,21188,21189,7,8,0,0,21189,21190,7,0,0,0,21190,
	21191,7,7,0,0,21191,21192,5,95,0,0,21192,21193,7,12,0,0,21193,21194,7,7,
	0,0,21194,21195,7,5,0,0,21195,21196,7,4,0,0,21196,21197,7,0,0,0,21197,21198,
	7,7,0,0,21198,21199,7,8,0,0,21199,21200,7,6,0,0,21200,21201,7,5,0,0,21201,
	3060,1,0,0,0,21202,21203,7,5,0,0,21203,21204,7,8,0,0,21204,21205,7,0,0,
	0,21205,21206,7,7,0,0,21206,3062,1,0,0,0,21207,21208,7,5,0,0,21208,21209,
	7,8,0,0,21209,21210,7,19,0,0,21210,21211,7,6,0,0,21211,21212,7,9,0,0,21212,
	21213,7,10,0,0,21213,21214,7,11,0,0,21214,21215,7,6,0,0,21215,21216,7,3,
	0,0,21216,3064,1,0,0,0,21217,21218,7,5,0,0,21218,21219,7,8,0,0,21219,21220,
	7,19,0,0,21220,21221,7,6,0,0,21221,21222,7,14,0,0,21222,21223,7,0,0,0,21223,
	21224,7,8,0,0,21224,21225,7,19,0,0,21225,21226,7,6,0,0,21226,21227,7,8,
	0,0,21227,21228,7,21,0,0,21228,3066,1,0,0,0,21229,21230,7,5,0,0,21230,21231,
	7,8,0,0,21231,21232,7,19,0,0,21232,21233,7,6,0,0,21233,21234,7,14,0,0,21234,
	21235,7,0,0,0,21235,3068,1,0,0,0,21236,21237,7,5,0,0,21237,21238,7,8,0,
	0,21238,21239,7,7,0,0,21239,21240,5,95,0,0,21240,21241,7,0,0,0,21241,21242,
	7,5,0,0,21242,21243,7,8,0,0,21243,21244,7,6,0,0,21244,21245,7,7,0,0,21245,
	21246,7,9,0,0,21246,21247,7,12,0,0,21247,21248,7,7,0,0,21248,21249,7,17,
	0,0,21249,3070,1,0,0,0,21250,21251,7,5,0,0,21251,21252,7,8,0,0,21252,21253,
	7,7,0,0,21253,3072,1,0,0,0,21254,21255,7,5,0,0,21255,21256,7,8,0,0,21256,
	21257,7,2,0,0,21257,21258,7,15,0,0,21258,21259,7,6,0,0,21259,3074,1,0,0,
	0,21260,21261,7,5,0,0,21261,21262,7,8,0,0,21262,21263,7,3,0,0,21263,21264,
	7,10,0,0,21264,21265,7,1,0,0,21265,3076,1,0,0,0,21266,21267,7,5,0,0,21267,
	21268,7,9,0,0,21268,21269,5,95,0,0,21269,21270,7,0,0,0,21270,21271,7,11,
	0,0,21271,21272,7,11,0,0,21272,3078,1,0,0,0,21273,21274,7,5,0,0,21274,21275,
	7,9,0,0,21275,21276,5,95,0,0,21276,21277,7,12,0,0,21277,21278,7,7,0,0,21278,
	21279,7,19,0,0,21279,21280,7,12,0,0,21280,21281,7,1,0,0,21281,21282,7,12,
	0,0,21282,21283,7,4,0,0,21283,3080,1,0,0,0,21284,21285,7,5,0,0,21285,21286,
	7,9,0,0,21286,21287,7,2,0,0,21287,21288,5,95,0,0,21288,21289,7,17,0,0,21289,
	21290,7,6,0,0,21290,21291,7,2,0,0,21291,21292,7,14,0,0,21292,21293,5,95,
	0,0,21293,21294,7,14,0,0,21294,21295,7,1,0,0,21295,21296,7,3,0,0,21296,
	3082,1,0,0,0,21297,21298,7,5,0,0,21298,21299,7,9,0,0,21299,21300,7,2,0,
	0,21300,21301,5,95,0,0,21301,21302,7,17,0,0,21302,21303,7,6,0,0,21303,21304,
	7,2,0,0,21304,21305,7,14,0,0,21305,21306,7,6,0,0,21306,21307,7,4,0,0,21307,
	21308,7,3,0,0,21308,21309,7,18,0,0,21309,3084,1,0,0,0,21310,21311,7,5,0,
	0,21311,21312,7,9,0,0,21312,21313,5,95,0,0,21313,21314,7,5,0,0,21314,21315,
	7,19,0,0,21315,21316,7,2,0,0,21316,21317,7,22,0,0,21317,3086,1,0,0,0,21318,
	21319,7,5,0,0,21319,21320,7,6,0,0,21320,21321,7,0,0,0,21321,21322,7,3,0,
	0,21322,21323,7,8,0,0,21323,21324,7,19,0,0,21324,3088,1,0,0,0,21325,21326,
	7,5,0,0,21326,21327,7,6,0,0,21327,21328,7,8,0,0,21328,21329,7,2,0,0,21329,
	21330,7,7,0,0,21330,21331,7,9,0,0,21331,3090,1,0,0,0,21332,21333,7,5,0,
	0,21333,21334,7,6,0,0,21334,21335,7,8,0,0,21335,21336,7,2,0,0,21336,21337,
	7,7,0,0,21337,21338,7,9,0,0,21338,21339,7,5,0,0,21339,3092,1,0,0,0,21340,
	21341,7,5,0,0,21341,21342,7,6,0,0,21342,21343,7,8,0,0,21343,21344,7,3,0,
	0,21344,21345,7,6,0,0,21345,21346,7,4,0,0,21346,3094,1,0,0,0,21347,21348,
	7,5,0,0,21348,21349,7,6,0,0,21349,21350,7,8,0,0,21350,21351,7,10,0,0,21351,
	21352,7,3,0,0,21352,21353,7,6,0,0,21353,21354,7,16,0,0,21354,21355,7,12,
	0,0,21355,21356,7,11,0,0,21356,21357,7,6,0,0,21357,21358,5,95,0,0,21358,
	21359,7,9,0,0,21359,21360,7,1,0,0,21360,21361,7,0,0,0,21361,3096,1,0,0,
	0,21362,21363,7,5,0,0,21363,21364,7,6,0,0,21364,21365,7,8,0,0,21365,21366,
	7,10,0,0,21366,21367,7,3,0,0,21367,21368,7,6,0,0,21368,21369,7,16,0,0,21369,
	21370,7,12,0,0,21370,21371,7,11,0,0,21371,21372,7,6,0,0,21372,3098,1,0,
	0,0,21373,21374,7,5,0,0,21374,21375,7,6,0,0,21375,21376,7,8,0,0,21376,21377,
	7,10,0,0,21377,21378,7,3,0,0,21378,21379,7,12,0,0,21379,21380,7,4,0,0,21380,
	21381,7,18,0,0,21381,3100,1,0,0,0,21382,21383,7,5,0,0,21383,21384,7,6,0,
	0,21384,21385,7,6,0,0,21385,21386,7,9,0,0,21386,3102,1,0,0,0,21387,21388,
	7,5,0,0,21388,21389,7,6,0,0,21389,21390,7,17,0,0,21390,21391,5,95,0,0,21391,
	21392,7,1,0,0,21392,21393,7,11,0,0,21393,21394,7,2,0,0,21394,21395,7,8,
	0,0,21395,21396,7,21,0,0,21396,3104,1,0,0,0,21397,21398,7,5,0,0,21398,21399,
	7,6,0,0,21399,21400,7,17,0,0,21400,21401,5,95,0,0,21401,21402,7,16,0,0,
	21402,21403,7,12,0,0,21403,21404,7,11,0,0,21404,21405,7,6,0,0,21405,3106,
	1,0,0,0,21406,21407,7,5,0,0,21407,21408,7,6,0,0,21408,21409,7,17,0,0,21409,
	21410,7,14,0,0,21410,21411,7,6,0,0,21411,21412,7,7,0,0,21412,21413,7,4,
	0,0,21413,3108,1,0,0,0,21414,21415,7,5,0,0,21415,21416,7,6,0,0,21416,21417,
	7,11,0,0,21417,21418,7,6,0,0,21418,21419,7,8,0,0,21419,21420,7,4,0,0,21420,
	21421,7,12,0,0,21421,21422,7,13,0,0,21422,21423,7,12,0,0,21423,21424,7,
	4,0,0,21424,21425,7,18,0,0,21425,3110,1,0,0,0,21426,21427,7,5,0,0,21427,
	21428,7,6,0,0,21428,21429,7,11,0,0,21429,21430,7,6,0,0,21430,21431,7,8,
	0,0,21431,21432,7,4,0,0,21432,3112,1,0,0,0,21433,21434,7,5,0,0,21434,21435,
	7,6,0,0,21435,21436,7,11,0,0,21436,21437,7,16,0,0,21437,3114,1,0,0,0,21438,
	21439,7,5,0,0,21439,21440,7,6,0,0,21440,21441,7,14,0,0,21441,21442,7,12,
	0,0,21442,21443,7,20,0,0,21443,21444,7,2,0,0,21444,21445,7,12,0,0,21445,
	21446,7,7,0,0,21446,21447,5,95,0,0,21447,21448,7,9,0,0,21448,21449,7,3,
	0,0,21449,21450,7,12,0,0,21450,21451,7,13,0,0,21451,21452,7,6,0,0,21452,
	21453,7,3,0,0,21453,3116,1,0,0,0,21454,21455,7,5,0,0,21455,21456,7,6,0,
	0,21456,21457,7,14,0,0,21457,21458,7,12,0,0,21458,21459,7,20,0,0,21459,
	21460,7,2,0,0,21460,21461,7,12,0,0,21461,21462,7,7,0,0,21462,3118,1,0,0,
	0,21463,21464,7,5,0,0,21464,21465,7,6,0,0,21465,21466,7,14,0,0,21466,21467,
	7,12,0,0,21467,21468,5,95,0,0,21468,21469,7,4,0,0,21469,21470,7,2,0,0,21470,
	21471,5,95,0,0,21471,21472,7,12,0,0,21472,21473,7,7,0,0,21473,21474,7,7,
	0,0,21474,21475,7,6,0,0,21475,21476,7,3,0,0,21476,3120,1,0,0,0,21477,21478,
	7,5,0,0,21478,21479,7,6,0,0,21479,21480,7,24,0,0,21480,21481,7,10,0,0,21481,
	21482,7,6,0,0,21482,21483,7,7,0,0,21483,21484,7,8,0,0,21484,21485,7,6,0,
	0,21485,21486,7,9,0,0,21486,3122,1,0,0,0,21487,21488,7,5,0,0,21488,21489,
	7,6,0,0,21489,21490,7,24,0,0,21490,21491,7,10,0,0,21491,21492,7,6,0,0,21492,
	21493,7,7,0,0,21493,21494,7,8,0,0,21494,21495,7,6,0,0,21495,3124,1,0,0,
	0,21496,21497,7,5,0,0,21497,21498,7,6,0,0,21498,21499,7,24,0,0,21499,21500,
	7,10,0,0,21500,21501,7,6,0,0,21501,21502,7,7,0,0,21502,21503,7,4,0,0,21503,
	21504,7,12,0,0,21504,21505,7,0,0,0,21505,21506,7,11,0,0,21506,3126,1,0,
	0,0,21507,21508,7,5,0,0,21508,21509,7,6,0,0,21509,21510,7,24,0,0,21510,
	3128,1,0,0,0,21511,21512,7,5,0,0,21512,21513,7,6,0,0,21513,21514,7,3,0,
	0,21514,21515,7,12,0,0,21515,21516,7,0,0,0,21516,21517,7,11,0,0,21517,21518,
	7,12,0,0,21518,21519,7,23,0,0,21519,21520,7,0,0,0,21520,21521,7,1,0,0,21521,
	21522,7,11,0,0,21522,21523,7,6,0,0,21523,3130,1,0,0,0,21524,21525,7,5,0,
	0,21525,21526,7,6,0,0,21526,21527,7,3,0,0,21527,21528,7,12,0,0,21528,21529,
	7,0,0,0,21529,21530,7,11,0,0,21530,21531,7,11,0,0,21531,21532,7,18,0,0,
	21532,21533,5,95,0,0,21533,21534,7,3,0,0,21534,21535,7,6,0,0,21535,21536,
	7,10,0,0,21536,21537,7,5,0,0,21537,21538,7,0,0,0,21538,21539,7,1,0,0,21539,
	21540,7,11,0,0,21540,21541,7,6,0,0,21541,3132,1,0,0,0,21542,21543,7,5,0,
	0,21543,21544,7,6,0,0,21544,21545,7,3,0,0,21545,21546,7,12,0,0,21546,21547,
	7,0,0,0,21547,21548,7,11,0,0,21548,3134,1,0,0,0,21549,21550,7,5,0,0,21550,
	21551,7,6,0,0,21551,21552,7,3,0,0,21552,21553,7,13,0,0,21553,21554,7,6,
	0,0,21554,21555,7,3,0,0,21555,21556,7,6,0,0,21556,21557,7,3,0,0,21557,21558,
	7,3,0,0,21558,21559,7,2,0,0,21559,21560,7,3,0,0,21560,3136,1,0,0,0,21561,
	21562,7,5,0,0,21562,21563,7,6,0,0,21563,21564,7,3,0,0,21564,21565,7,13,
	0,0,21565,21566,7,12,0,0,21566,21567,7,8,0,0,21567,21568,7,6,0,0,21568,
	21569,5,95,0,0,21569,21570,7,7,0,0,21570,21571,7,0,0,0,21571,21572,7,14,
	0,0,21572,21573,7,6,0,0,21573,21574,5,95,0,0,21574,21575,7,8,0,0,21575,
	21576,7,2,0,0,21576,21577,7,7,0,0,21577,21578,7,13,0,0,21578,21579,7,6,
	0,0,21579,21580,7,3,0,0,21580,21581,7,4,0,0,21581,3138,1,0,0,0,21582,21583,
	7,5,0,0,21583,21584,7,6,0,0,21584,21585,7,3,0,0,21585,21586,7,13,0,0,21586,
	21587,7,12,0,0,21587,21588,7,8,0,0,21588,21589,7,6,0,0,21589,3140,1,0,0,
	0,21590,21591,7,5,0,0,21591,21592,7,6,0,0,21592,21593,7,3,0,0,21593,21594,
	7,13,0,0,21594,21595,7,12,0,0,21595,21596,7,8,0,0,21596,21597,7,6,0,0,21597,
	21598,7,5,0,0,21598,3142,1,0,0,0,21599,21600,7,5,0,0,21600,21601,7,6,0,
	0,21601,21602,7,5,0,0,21602,21603,7,5,0,0,21603,21604,7,12,0,0,21604,21605,
	7,2,0,0,21605,21606,7,7,0,0,21606,21607,5,95,0,0,21607,21608,7,8,0,0,21608,
	21609,7,0,0,0,21609,21610,7,8,0,0,21610,21611,7,19,0,0,21611,21612,7,6,
	0,0,21612,21613,7,9,0,0,21613,21614,5,95,0,0,21614,21615,7,8,0,0,21615,
	21616,7,10,0,0,21616,21617,7,3,0,0,21617,21618,7,5,0,0,21618,21619,7,2,
	0,0,21619,21620,7,3,0,0,21620,21621,7,5,0,0,21621,3144,1,0,0,0,21622,21623,
	7,5,0,0,21623,21624,7,6,0,0,21624,21625,7,5,0,0,21625,21626,7,5,0,0,21626,
	21627,7,12,0,0,21627,21628,7,2,0,0,21628,21629,7,7,0,0,21629,3146,1,0,0,
	0,21630,21631,7,5,0,0,21631,21632,7,6,0,0,21632,21633,7,5,0,0,21633,21634,
	7,5,0,0,21634,21635,7,12,0,0,21635,21636,7,2,0,0,21636,21637,7,7,0,0,21637,
	21638,7,5,0,0,21638,21639,5,95,0,0,21639,21640,7,15,0,0,21640,21641,7,6,
	0,0,21641,21642,7,3,0,0,21642,21643,5,95,0,0,21643,21644,7,10,0,0,21644,
	21645,7,5,0,0,21645,21646,7,6,0,0,21646,21647,7,3,0,0,21647,3148,1,0,0,
	0,21648,21649,7,5,0,0,21649,21650,7,6,0,0,21650,21651,7,5,0,0,21651,21652,
	7,5,0,0,21652,21653,7,12,0,0,21653,21654,7,2,0,0,21654,21655,7,7,0,0,21655,
	21656,7,4,0,0,21656,21657,7,12,0,0,21657,21658,7,14,0,0,21658,21659,7,6,
	0,0,21659,21660,7,23,0,0,21660,21661,7,2,0,0,21661,21662,7,7,0,0,21662,
	21663,7,6,0,0,21663,3150,1,0,0,0,21664,21665,7,5,0,0,21665,21666,7,6,0,
	0,21666,21667,7,5,0,0,21667,21668,7,5,0,0,21668,21669,7,12,0,0,21669,21670,
	7,2,0,0,21670,21671,7,7,0,0,21671,21672,7,4,0,0,21672,21673,7,23,0,0,21673,
	21674,7,7,0,0,21674,21675,7,0,0,0,21675,21676,7,14,0,0,21676,21677,7,6,
	0,0,21677,3152,1,0,0,0,21678,21679,7,5,0,0,21679,21680,7,6,0,0,21680,21681,
	7,4,0,0,21681,3154,1,0,0,0,21682,21683,7,5,0,0,21683,21684,7,6,0,0,21684,
	21685,7,4,0,0,21685,21686,7,5,0,0,21686,3156,1,0,0,0,21687,21688,7,5,0,
	0,21688,21689,7,6,0,0,21689,21690,7,4,0,0,21690,21691,7,4,0,0,21691,21692,
	7,12,0,0,21692,21693,7,7,0,0,21693,21694,7,17,0,0,21694,21695,7,5,0,0,21695,
	3158,1,0,0,0,21696,21697,7,5,0,0,21697,21698,7,6,0,0,21698,21699,7,4,0,
	0,21699,21700,5,95,0,0,21700,21701,7,4,0,0,21701,21702,7,2,0,0,21702,21703,
	5,95,0,0,21703,21704,7,20,0,0,21704,21705,7,2,0,0,21705,21706,7,12,0,0,
	21706,21707,7,7,0,0,21707,3160,1,0,0,0,21708,21709,7,5,0,0,21709,21710,
	7,6,0,0,21710,21711,7,13,0,0,21711,21712,7,6,0,0,21712,21713,7,3,0,0,21713,
	21714,7,6,0,0,21714,3162,1,0,0,0,21715,21716,7,5,0,0,21716,21717,7,19,0,
	0,21717,21718,7,0,0,0,21718,21719,7,3,0,0,21719,21720,7,9,0,0,21720,21721,
	7,5,0,0,21721,21722,7,15,0,0,21722,21723,7,0,0,0,21723,21724,7,8,0,0,21724,
	21725,7,6,0,0,21725,3164,1,0,0,0,21726,21727,7,5,0,0,21727,21728,7,19,0,
	0,21728,21729,7,0,0,0,21729,21730,7,3,0,0,21730,21731,7,6,0,0,21731,21732,
	7,9,0,0,21732,21733,5,95,0,0,21733,21734,7,15,0,0,21734,21735,7,2,0,0,21735,
	21736,7,2,0,0,21736,21737,7,11,0,0,21737,3166,1,0,0,0,21738,21739,7,5,0,
	0,21739,21740,7,19,0,0,21740,21741,7,0,0,0,21741,21742,7,3,0,0,21742,21743,
	7,6,0,0,21743,21744,7,9,0,0,21744,3168,1,0,0,0,21745,21746,7,5,0,0,21746,
	21747,7,19,0,0,21747,21748,7,0,0,0,21748,21749,7,3,0,0,21749,21750,7,6,
	0,0,21750,3170,1,0,0,0,21751,21752,7,5,0,0,21752,21753,7,19,0,0,21753,21754,
	7,0,0,0,21754,21755,7,3,0,0,21755,21756,7,12,0,0,21756,21757,7,7,0,0,21757,
	21758,7,17,0,0,21758,3172,1,0,0,0,21759,21760,7,5,0,0,21760,21761,7,19,
	0,0,21761,21762,7,6,0,0,21762,21763,7,11,0,0,21763,21764,7,16,0,0,21764,
	21765,7,11,0,0,21765,21766,7,12,0,0,21766,21767,7,16,0,0,21767,21768,7,
	6,0,0,21768,3174,1,0,0,0,21769,21770,7,5,0,0,21770,21771,7,19,0,0,21771,
	21772,7,2,0,0,21772,21773,7,22,0,0,21773,3176,1,0,0,0,21774,21775,7,5,0,
	0,21775,21776,7,19,0,0,21776,21777,7,3,0,0,21777,21778,7,12,0,0,21778,21779,
	7,7,0,0,21779,21780,7,21,0,0,21780,3178,1,0,0,0,21781,21782,7,5,0,0,21782,
	21783,7,19,0,0,21783,21784,7,10,0,0,21784,21785,7,4,0,0,21785,21786,7,9,
	0,0,21786,21787,7,2,0,0,21787,21788,7,22,0,0,21788,21789,7,7,0,0,21789,
	3180,1,0,0,0,21790,21791,7,5,0,0,21791,21792,7,12,0,0,21792,21793,7,1,0,
	0,21793,21794,7,11,0,0,21794,21795,7,12,0,0,21795,21796,7,7,0,0,21796,21797,
	7,17,0,0,21797,21798,7,5,0,0,21798,3182,1,0,0,0,21799,21800,7,5,0,0,21800,
	21801,7,12,0,0,21801,21802,7,9,0,0,21802,3184,1,0,0,0,21803,21804,7,5,0,
	0,21804,21805,7,12,0,0,21805,21806,7,4,0,0,21806,21807,7,6,0,0,21807,3186,
	1,0,0,0,21808,21809,7,5,0,0,21809,21810,7,12,0,0,21810,21811,7,17,0,0,21811,
	21812,7,7,0,0,21812,21813,7,0,0,0,21813,21814,7,11,0,0,21814,21815,5,95,
	0,0,21815,21816,7,8,0,0,21816,21817,7,2,0,0,21817,21818,7,14,0,0,21818,
	21819,7,15,0,0,21819,21820,7,2,0,0,21820,21821,7,7,0,0,21821,21822,7,6,
	0,0,21822,21823,7,7,0,0,21823,21824,7,4,0,0,21824,3188,1,0,0,0,21825,21826,
	7,5,0,0,21826,21827,7,12,0,0,21827,21828,7,17,0,0,21828,21829,7,7,0,0,21829,
	21830,7,0,0,0,21830,21831,7,11,0,0,21831,21832,5,95,0,0,21832,21833,7,16,
	0,0,21833,21834,7,10,0,0,21834,21835,7,7,0,0,21835,21836,7,8,0,0,21836,
	21837,7,4,0,0,21837,21838,7,12,0,0,21838,21839,7,2,0,0,21839,21840,7,7,
	0,0,21840,3190,1,0,0,0,21841,21842,7,5,0,0,21842,21843,7,12,0,0,21843,21844,
	7,17,0,0,21844,21845,7,7,0,0,21845,3192,1,0,0,0,21846,21847,7,5,0,0,21847,
	21848,7,12,0,0,21848,21849,7,17,0,0,21849,21850,7,7,0,0,21850,21851,7,4,
	0,0,21851,21852,7,18,0,0,21852,21853,7,15,0,0,21853,21854,7,6,0,0,21854,
	3194,1,0,0,0,21855,21856,7,5,0,0,21856,21857,7,12,0,0,21857,21858,7,14,
	0,0,21858,21859,7,15,0,0,21859,21860,7,11,0,0,21860,21861,7,6,0,0,21861,
	21862,5,95,0,0,21862,21863,7,12,0,0,21863,21864,7,7,0,0,21864,21865,7,4,
	0,0,21865,21866,7,6,0,0,21866,21867,7,17,0,0,21867,21868,7,6,0,0,21868,
	21869,7,3,0,0,21869,3196,1,0,0,0,21870,21871,7,5,0,0,21871,21872,7,12,0,
	0,21872,21873,7,14,0,0,21873,21874,7,15,0,0,21874,21875,7,11,0,0,21875,
	21876,7,6,0,0,21876,3198,1,0,0,0,21877,21878,7,5,0,0,21878,21879,7,12,0,
	0,21879,21880,7,7,0,0,21880,21881,7,17,0,0,21881,21882,7,11,0,0,21882,21883,
	7,6,0,0,21883,3200,1,0,0,0,21884,21885,7,5,0,0,21885,21886,7,12,0,0,21886,
	21887,7,7,0,0,21887,21888,7,17,0,0,21888,21889,7,11,0,0,21889,21890,7,6,
	0,0,21890,21891,7,4,0,0,21891,21892,7,0,0,0,21892,21893,7,5,0,0,21893,21894,
	7,21,0,0,21894,3202,1,0,0,0,21895,21896,7,5,0,0,21896,21897,7,12,0,0,21897,
	21898,7,7,0,0,21898,21899,7,19,0,0,21899,3204,1,0,0,0,21900,21901,7,5,0,
	0,21901,21902,7,12,0,0,21902,21903,7,7,0,0,21903,3206,1,0,0,0,21904,21905,
	7,5,0,0,21905,21906,7,12,0,0,21906,21907,7,23,0,0,21907,21908,7,6,0,0,21908,
	3208,1,0,0,0,21909,21910,7,5,0,0,21910,21911,7,21,0,0,21911,21912,7,12,
	0,0,21912,21913,7,15,0,0,21913,21914,5,95,0,0,21914,21915,7,6,0,0,21915,
	21916,7,25,0,0,21916,21917,7,4,0,0,21917,21918,5,95,0,0,21918,21919,7,2,
	0,0,21919,21920,7,15,0,0,21920,21921,7,4,0,0,21921,21922,7,12,0,0,21922,
	21923,7,14,0,0,21923,21924,7,12,0,0,21924,21925,7,23,0,0,21925,21926,7,
	6,0,0,21926,21927,7,3,0,0,21927,3210,1,0,0,0,21928,21929,7,5,0,0,21929,
	21930,7,21,0,0,21930,21931,7,12,0,0,21931,21932,7,15,0,0,21932,3212,1,0,
	0,0,21933,21934,7,5,0,0,21934,21935,7,21,0,0,21935,21936,7,12,0,0,21936,
	21937,7,15,0,0,21937,21938,5,95,0,0,21938,21939,7,10,0,0,21939,21940,7,
	7,0,0,21940,21941,7,24,0,0,21941,21942,5,95,0,0,21942,21943,7,10,0,0,21943,
	21944,7,7,0,0,21944,21945,7,10,0,0,21945,21946,7,5,0,0,21946,21947,7,0,
	0,0,21947,21948,7,1,0,0,21948,21949,7,11,0,0,21949,21950,7,6,0,0,21950,
	21951,5,95,0,0,21951,21952,7,12,0,0,21952,21953,7,9,0,0,21953,21954,7,25,
	0,0,21954,3214,1,0,0,0,21955,21956,7,5,0,0,21956,21957,7,21,0,0,21957,21958,
	7,12,0,0,21958,21959,7,15,0,0,21959,21960,5,95,0,0,21960,21961,7,10,0,0,
	21961,21962,7,7,0,0,21962,21963,7,10,0,0,21963,21964,7,5,0,0,21964,21965,
	7,0,0,0,21965,21966,7,1,0,0,21966,21967,7,11,0,0,21967,21968,7,6,0,0,21968,
	21969,5,95,0,0,21969,21970,7,12,0,0,21970,21971,7,7,0,0,21971,21972,7,9,
	0,0,21972,21973,7,6,0,0,21973,21974,7,25,0,0,21974,21975,7,6,0,0,21975,
	21976,7,5,0,0,21976,3216,1,0,0,0,21977,21978,7,5,0,0,21978,21979,7,14,0,
	0,21979,21980,7,0,0,0,21980,21981,7,11,0,0,21981,21982,7,11,0,0,21982,21983,
	7,16,0,0,21983,21984,7,12,0,0,21984,21985,7,11,0,0,21985,21986,7,6,0,0,
	21986,3218,1,0,0,0,21987,21988,7,5,0,0,21988,21989,7,14,0,0,21989,21990,
	7,0,0,0,21990,21991,7,11,0,0,21991,21992,7,11,0,0,21992,21993,7,12,0,0,
	21993,21994,7,7,0,0,21994,21995,7,4,0,0,21995,3220,1,0,0,0,21996,21997,
	7,5,0,0,21997,21998,7,7,0,0,21998,21999,7,0,0,0,21999,22000,7,15,0,0,22000,
	22001,7,5,0,0,22001,22002,7,19,0,0,22002,22003,7,2,0,0,22003,22004,7,4,
	0,0,22004,3222,1,0,0,0,22005,22006,7,5,0,0,22006,22007,7,2,0,0,22007,22008,
	7,14,0,0,22008,22009,7,6,0,0,22009,3224,1,0,0,0,22010,22011,7,5,0,0,22011,
	22012,7,2,0,0,22012,22013,7,3,0,0,22013,22014,7,4,0,0,22014,3226,1,0,0,
	0,22015,22016,7,5,0,0,22016,22017,7,2,0,0,22017,22018,7,10,0,0,22018,22019,
	7,7,0,0,22019,22020,7,9,0,0,22020,22021,7,6,0,0,22021,22022,7,25,0,0,22022,
	3228,1,0,0,0,22023,22024,7,5,0,0,22024,22025,7,2,0,0,22025,22026,7,10,0,
	0,22026,22027,7,3,0,0,22027,22028,7,8,0,0,22028,22029,7,6,0,0,22029,22030,
	5,95,0,0,22030,22031,7,16,0,0,22031,22032,7,12,0,0,22032,22033,7,11,0,0,
	22033,22034,7,6,0,0,22034,22035,5,95,0,0,22035,22036,7,9,0,0,22036,22037,
	7,12,0,0,22037,22038,7,3,0,0,22038,22039,7,6,0,0,22039,22040,7,8,0,0,22040,
	22041,7,4,0,0,22041,22042,7,2,0,0,22042,22043,7,3,0,0,22043,22044,7,18,
	0,0,22044,3230,1,0,0,0,22045,22046,7,5,0,0,22046,22047,7,2,0,0,22047,22048,
	7,10,0,0,22048,22049,7,3,0,0,22049,22050,7,8,0,0,22050,22051,7,6,0,0,22051,
	22052,5,95,0,0,22052,22053,7,16,0,0,22053,22054,7,12,0,0,22054,22055,7,
	11,0,0,22055,22056,7,6,0,0,22056,22057,5,95,0,0,22057,22058,7,7,0,0,22058,
	22059,7,0,0,0,22059,22060,7,14,0,0,22060,22061,7,6,0,0,22061,22062,5,95,
	0,0,22062,22063,7,8,0,0,22063,22064,7,2,0,0,22064,22065,7,7,0,0,22065,22066,
	7,13,0,0,22066,22067,7,6,0,0,22067,22068,7,3,0,0,22068,22069,7,4,0,0,22069,
	3232,1,0,0,0,22070,22071,7,5,0,0,22071,22072,7,2,0,0,22072,22073,7,10,0,
	0,22073,22074,7,3,0,0,22074,22075,7,8,0,0,22075,22076,7,6,0,0,22076,3234,
	1,0,0,0,22077,22078,7,5,0,0,22078,22079,7,15,0,0,22079,22080,7,0,0,0,22080,
	22081,7,8,0,0,22081,22082,7,6,0,0,22082,3236,1,0,0,0,22083,22084,7,5,0,
	0,22084,22085,7,15,0,0,22085,22086,7,6,0,0,22086,22087,7,8,0,0,22087,22088,
	7,12,0,0,22088,22089,7,16,0,0,22089,22090,7,12,0,0,22090,22091,7,8,0,0,
	22091,22092,7,0,0,0,22092,22093,7,4,0,0,22093,22094,7,12,0,0,22094,22095,
	7,2,0,0,22095,22096,7,7,0,0,22096,3238,1,0,0,0,22097,22098,7,5,0,0,22098,
	22099,7,15,0,0,22099,22100,7,16,0,0,22100,22101,7,12,0,0,22101,22102,7,
	11,0,0,22102,22103,7,6,0,0,22103,3240,1,0,0,0,22104,22105,7,5,0,0,22105,
	22106,7,15,0,0,22106,22107,7,11,0,0,22107,22108,7,12,0,0,22108,22109,7,
	4,0,0,22109,3242,1,0,0,0,22110,22111,7,5,0,0,22111,22112,7,15,0,0,22112,
	22113,7,3,0,0,22113,22114,7,6,0,0,22114,22115,7,0,0,0,22115,22116,7,9,0,
	0,22116,22117,7,5,0,0,22117,22118,7,19,0,0,22118,22119,7,6,0,0,22119,22120,
	7,6,0,0,22120,22121,7,4,0,0,22121,3244,1,0,0,0,22122,22123,7,5,0,0,22123,
	22124,7,24,0,0,22124,22125,7,11,0,0,22125,22126,7,9,0,0,22126,22127,7,0,
	0,0,22127,22128,7,4,0,0,22128,22129,7,0,0,0,22129,3246,1,0,0,0,22130,22131,
	7,5,0,0,22131,22132,7,24,0,0,22132,22133,7,11,0,0,22133,22134,7,6,0,0,22134,
	22135,7,3,0,0,22135,22136,7,3,0,0,22136,22137,7,2,0,0,22137,22138,7,3,0,
	0,22138,3248,1,0,0,0,22139,22140,7,5,0,0,22140,22141,7,24,0,0,22141,22142,
	7,11,0,0,22142,22143,7,11,0,0,22143,22144,7,9,0,0,22144,22145,7,3,0,0,22145,
	3250,1,0,0,0,22146,22147,7,5,0,0,22147,22148,7,24,0,0,22148,22149,7,11,
	0,0,22149,3252,1,0,0,0,22150,22151,7,15,0,0,22151,22152,7,21,0,0,22152,
	22157,7,1,0,0,22153,22154,7,15,0,0,22154,22155,7,21,0,0,22155,22157,7,5,
	0,0,22156,22150,1,0,0,0,22156,22153,1,0,0,0,22157,3254,1,0,0,0,22158,22159,
	7,5,0,0,22159,22160,7,24,0,0,22160,22161,7,11,0,0,22161,22162,5,95,0,0,
	22162,22163,7,14,0,0,22163,22164,7,0,0,0,22164,22165,7,8,0,0,22165,22166,
	7,3,0,0,22166,22167,7,2,0,0,22167,3256,1,0,0,0,22168,22169,7,5,0,0,22169,
	22170,7,24,0,0,22170,22171,7,11,0,0,22171,22172,5,95,0,0,22172,22173,7,
	4,0,0,22173,22174,7,3,0,0,22174,22175,7,0,0,0,22175,22176,7,8,0,0,22176,
	22177,7,6,0,0,22177,3258,1,0,0,0,22178,22179,7,5,0,0,22179,22180,7,24,0,
	0,22180,22181,7,11,0,0,22181,22182,5,95,0,0,22182,22183,7,4,0,0,22183,22184,
	7,3,0,0,22184,22185,7,0,0,0,22185,22186,7,7,0,0,22186,22187,7,5,0,0,22187,
	22188,7,11,0,0,22188,22189,7,0,0,0,22189,22190,7,4,0,0,22190,22191,7,12,
	0,0,22191,22192,7,2,0,0,22192,22193,7,7,0,0,22193,22194,5,95,0,0,22194,
	22195,7,15,0,0,22195,22196,7,3,0,0,22196,22197,7,2,0,0,22197,22198,7,16,
	0,0,22198,22199,7,12,0,0,22199,22200,7,11,0,0,22200,22201,7,6,0,0,22201,
	3260,1,0,0,0,22202,22203,7,5,0,0,22203,22204,7,24,0,0,22204,22205,7,3,0,
	0,22205,22206,7,4,0,0,22206,3262,1,0,0,0,22207,22208,7,5,0,0,22208,22209,
	7,4,0,0,22209,22210,7,0,0,0,22210,22211,7,11,0,0,22211,22212,7,6,0,0,22212,
	3264,1,0,0,0,22213,22214,7,5,0,0,22214,22215,7,4,0,0,22215,22216,7,0,0,
	0,22216,22217,7,7,0,0,22217,22218,7,9,0,0,22218,22219,7,0,0,0,22219,22220,
	7,11,0,0,22220,22221,7,2,0,0,22221,22222,7,7,0,0,22222,22223,7,6,0,0,22223,
	3266,1,0,0,0,22224,22225,7,5,0,0,22225,22226,7,4,0,0,22226,22227,7,0,0,
	0,22227,22228,7,7,0,0,22228,22229,7,9,0,0,22229,22230,7,0,0,0,22230,22231,
	7,3,0,0,22231,22232,7,9,0,0,22232,3268,1,0,0,0,22233,22234,7,5,0,0,22234,
	22235,7,4,0,0,22235,22236,7,0,0,0,22236,22237,7,7,0,0,22237,22238,7,9,0,
	0,22238,22239,7,0,0,0,22239,22240,7,3,0,0,22240,22241,7,9,0,0,22241,22242,
	5,95,0,0,22242,22243,7,19,0,0,22243,22244,7,0,0,0,22244,22245,7,5,0,0,22245,
	22246,7,19,0,0,22246,3270,1,0,0,0,22247,22248,7,5,0,0,22248,22249,7,4,0,
	0,22249,22250,7,0,0,0,22250,22251,7,7,0,0,22251,22252,7,9,0,0,22252,22253,
	7,1,0,0,22253,22254,7,18,0,0,22254,22255,5,95,0,0,22255,22256,7,14,0,0,
	22256,22257,7,0,0,0,22257,22258,7,25,0,0,22258,22259,5,95,0,0,22259,22260,
	7,9,0,0,22260,22261,7,0,0,0,22261,22262,7,4,0,0,22262,22263,7,0,0,0,22263,
	22264,5,95,0,0,22264,22265,7,9,0,0,22265,22266,7,6,0,0,22266,22267,7,11,
	0,0,22267,22268,7,0,0,0,22268,22269,7,18,0,0,22269,3272,1,0,0,0,22270,22271,
	7,5,0,0,22271,22272,7,4,0,0,22272,22273,7,0,0,0,22273,22274,7,7,0,0,22274,
	22275,7,9,0,0,22275,22276,7,1,0,0,22276,22277,7,18,0,0,22277,22278,7,5,
	0,0,22278,3274,1,0,0,0,22279,22280,7,5,0,0,22280,22281,7,4,0,0,22281,22282,
	7,0,0,0,22282,22283,7,7,0,0,22283,22284,7,9,0,0,22284,22285,7,1,0,0,22285,
	22286,7,18,0,0,22286,3276,1,0,0,0,22287,22288,7,5,0,0,22288,22289,7,4,0,
	0,22289,22290,7,0,0,0,22290,22291,7,3,0,0,22291,3278,1,0,0,0,22292,22293,
	7,5,0,0,22293,22294,7,4,0,0,22294,22295,7,0,0,0,22295,22296,7,3,0,0,22296,
	22297,5,95,0,0,22297,22298,7,4,0,0,22298,22299,7,3,0,0,22299,22300,7,0,
	0,0,22300,22301,7,7,0,0,22301,22302,7,5,0,0,22302,22303,7,16,0,0,22303,
	22304,7,2,0,0,22304,22305,7,3,0,0,22305,22306,7,14,0,0,22306,22307,7,0,
	0,0,22307,22308,7,4,0,0,22308,22309,7,12,0,0,22309,22310,7,2,0,0,22310,
	22311,7,7,0,0,22311,3280,1,0,0,0,22312,22313,7,5,0,0,22313,22314,7,4,0,
	0,22314,22315,7,0,0,0,22315,22316,7,3,0,0,22316,22317,7,4,0,0,22317,3282,
	1,0,0,0,22318,22319,7,5,0,0,22319,22320,7,4,0,0,22320,22321,7,0,0,0,22321,
	22322,7,3,0,0,22322,22323,7,4,0,0,22323,22324,7,10,0,0,22324,22325,7,15,
	0,0,22325,3284,1,0,0,0,22326,22327,7,5,0,0,22327,22328,7,4,0,0,22328,22329,
	7,0,0,0,22329,22330,7,4,0,0,22330,22331,7,6,0,0,22331,22332,7,14,0,0,22332,
	22333,7,6,0,0,22333,22334,7,7,0,0,22334,22335,7,4,0,0,22335,22336,5,95,
	0,0,22336,22337,7,12,0,0,22337,22338,7,9,0,0,22338,3286,1,0,0,0,22339,22340,
	7,5,0,0,22340,22341,7,4,0,0,22341,22342,7,0,0,0,22342,22343,7,4,0,0,22343,
	22344,7,6,0,0,22344,22345,7,14,0,0,22345,22346,7,6,0,0,22346,22347,7,7,
	0,0,22347,22348,7,4,0,0,22348,22349,5,95,0,0,22349,22350,7,24,0,0,22350,
	22351,7,10,0,0,22351,22352,7,6,0,0,22352,22353,7,10,0,0,22353,22354,7,12,
	0,0,22354,22355,7,7,0,0,22355,22356,7,17,0,0,22356,3288,1,0,0,0,22357,22358,
	7,5,0,0,22358,22359,7,4,0,0,22359,22360,7,0,0,0,22360,22361,7,4,0,0,22361,
	22362,7,6,0,0,22362,22363,7,14,0,0,22363,22364,7,6,0,0,22364,22365,7,7,
	0,0,22365,22366,7,4,0,0,22366,22367,7,5,0,0,22367,3290,1,0,0,0,22368,22369,
	7,5,0,0,22369,22370,7,4,0,0,22370,22371,7,0,0,0,22371,22372,7,4,0,0,22372,
	22373,7,6,0,0,22373,22374,7,14,0,0,22374,22375,7,6,0,0,22375,22376,7,7,
	0,0,22376,22377,7,4,0,0,22377,3292,1,0,0,0,22378,22379,7,5,0,0,22379,22380,
	7,4,0,0,22380,22381,7,0,0,0,22381,22382,7,4,0,0,22382,22383,7,6,0,0,22383,
	3294,1,0,0,0,22384,22385,7,5,0,0,22385,22386,7,4,0,0,22386,22387,7,0,0,
	0,22387,22388,7,4,0,0,22388,22389,7,12,0,0,22389,22390,7,8,0,0,22390,3296,
	1,0,0,0,22391,22392,7,5,0,0,22392,22393,7,4,0,0,22393,22394,7,0,0,0,22394,
	22395,7,4,0,0,22395,22396,7,12,0,0,22396,22397,7,5,0,0,22397,22398,7,4,
	0,0,22398,22399,7,12,0,0,22399,22400,7,8,0,0,22400,22401,7,5,0,0,22401,
	3298,1,0,0,0,22402,22403,7,5,0,0,22403,22404,7,4,0,0,22404,22405,7,0,0,
	0,22405,22406,7,4,0,0,22406,22407,7,5,0,0,22407,22408,5,95,0,0,22408,22409,
	7,1,0,0,22409,22410,7,12,0,0,22410,22411,7,7,0,0,22411,22412,7,2,0,0,22412,
	22413,7,14,0,0,22413,22414,7,12,0,0,22414,22415,7,0,0,0,22415,22416,7,11,
	0,0,22416,22417,5,95,0,0,22417,22418,7,4,0,0,22418,22419,7,6,0,0,22419,
	22420,7,5,0,0,22420,22421,7,4,0,0,22421,3300,1,0,0,0,22422,22423,7,5,0,
	0,22423,22424,7,4,0,0,22424,22425,7,0,0,0,22425,22426,7,4,0,0,22426,22427,
	7,5,0,0,22427,22428,5,95,0,0,22428,22429,7,8,0,0,22429,22430,7,3,0,0,22430,
	22431,7,2,0,0,22431,22432,7,5,0,0,22432,22433,7,5,0,0,22433,22434,7,4,0,
	0,22434,22435,7,0,0,0,22435,22436,7,1,0,0,22436,3302,1,0,0,0,22437,22438,
	7,5,0,0,22438,22439,7,4,0,0,22439,22440,7,0,0,0,22440,22441,7,4,0,0,22441,
	22442,7,5,0,0,22442,22443,5,95,0,0,22443,22444,7,16,0,0,22444,22445,5,95,
	0,0,22445,22446,7,4,0,0,22446,22447,7,6,0,0,22447,22448,7,5,0,0,22448,22449,
	7,4,0,0,22449,3304,1,0,0,0,22450,22451,7,5,0,0,22451,22452,7,4,0,0,22452,
	22453,7,0,0,0,22453,22454,7,4,0,0,22454,22455,7,5,0,0,22455,22456,5,95,
	0,0,22456,22457,7,21,0,0,22457,22458,7,5,0,0,22458,22459,5,95,0,0,22459,
	22460,7,4,0,0,22460,22461,7,6,0,0,22461,22462,7,5,0,0,22462,22463,7,4,0,
	0,22463,3306,1,0,0,0,22464,22465,7,5,0,0,22465,22466,7,4,0,0,22466,22467,
	7,0,0,0,22467,22468,7,4,0,0,22468,22469,7,5,0,0,22469,22470,5,95,0,0,22470,
	22471,7,14,0,0,22471,22472,7,2,0,0,22472,22473,7,9,0,0,22473,22474,7,6,
	0,0,22474,3308,1,0,0,0,22475,22476,7,5,0,0,22476,22477,7,4,0,0,22477,22478,
	7,0,0,0,22478,22479,7,4,0,0,22479,22480,7,5,0,0,22480,22481,5,95,0,0,22481,
	22482,7,14,0,0,22482,22483,7,22,0,0,22483,22484,5,95,0,0,22484,22485,7,
	4,0,0,22485,22486,7,6,0,0,22486,22487,7,5,0,0,22487,22488,7,4,0,0,22488,
	3310,1,0,0,0,22489,22490,7,5,0,0,22490,22491,7,4,0,0,22491,22492,7,0,0,
	0,22492,22493,7,4,0,0,22493,22494,7,5,0,0,22494,22495,5,95,0,0,22495,22496,
	7,2,0,0,22496,22497,7,7,0,0,22497,22498,7,6,0,0,22498,22499,5,95,0,0,22499,
	22500,7,22,0,0,22500,22501,7,0,0,0,22501,22502,7,18,0,0,22502,22503,5,95,
	0,0,22503,22504,7,0,0,0,22504,22505,7,7,0,0,22505,22506,7,2,0,0,22506,22507,
	7,13,0,0,22507,22508,7,0,0,0,22508,3312,1,0,0,0,22509,22510,7,5,0,0,22510,
	22511,7,4,0,0,22511,22512,7,0,0,0,22512,22513,7,4,0,0,22513,22514,7,5,0,
	0,22514,22515,5,95,0,0,22515,22516,7,4,0,0,22516,22517,5,95,0,0,22517,22518,
	7,4,0,0,22518,22519,7,6,0,0,22519,22520,7,5,0,0,22520,22521,7,4,0,0,22521,
	22522,5,95,0,0,22522,22523,7,12,0,0,22523,22524,7,7,0,0,22524,22525,7,9,
	0,0,22525,22526,7,6,0,0,22526,22527,7,15,0,0,22527,3314,1,0,0,0,22528,22529,
	7,5,0,0,22529,22530,7,4,0,0,22530,22531,7,0,0,0,22531,22532,7,4,0,0,22532,
	22533,7,5,0,0,22533,22534,5,95,0,0,22534,22535,7,4,0,0,22535,22536,5,95,
	0,0,22536,22537,7,4,0,0,22537,22538,7,6,0,0,22538,22539,7,5,0,0,22539,22540,
	7,4,0,0,22540,22541,5,95,0,0,22541,22542,7,12,0,0,22542,22543,7,7,0,0,22543,
	22544,7,9,0,0,22544,22545,7,6,0,0,22545,22546,7,15,0,0,22546,22547,7,10,
	0,0,22547,3316,1,0,0,0,22548,22549,7,5,0,0,22549,22550,7,4,0,0,22550,22551,
	7,0,0,0,22551,22552,7,4,0,0,22552,22553,7,5,0,0,22553,22554,5,95,0,0,22554,
	22555,7,4,0,0,22555,22556,5,95,0,0,22556,22557,7,4,0,0,22557,22558,7,6,
	0,0,22558,22559,7,5,0,0,22559,22560,7,4,0,0,22560,22561,5,95,0,0,22561,
	22562,7,2,0,0,22562,22563,7,7,0,0,22563,22564,7,6,0,0,22564,3318,1,0,0,
	0,22565,22566,7,5,0,0,22566,22567,7,4,0,0,22567,22568,7,0,0,0,22568,22569,
	7,4,0,0,22569,22570,7,5,0,0,22570,22571,5,95,0,0,22571,22572,7,4,0,0,22572,
	22573,5,95,0,0,22573,22574,7,4,0,0,22574,22575,7,6,0,0,22575,22576,7,5,
	0,0,22576,22577,7,4,0,0,22577,22578,5,95,0,0,22578,22579,7,15,0,0,22579,
	22580,7,0,0,0,22580,22581,7,12,0,0,22581,22582,7,3,0,0,22582,22583,7,6,
	0,0,22583,22584,7,9,0,0,22584,3320,1,0,0,0,22585,22586,7,5,0,0,22586,22587,
	7,4,0,0,22587,22588,7,0,0,0,22588,22589,7,4,0,0,22589,22590,7,5,0,0,22590,
	22591,5,95,0,0,22591,22592,7,22,0,0,22592,22593,7,5,0,0,22593,22594,7,3,
	0,0,22594,22595,5,95,0,0,22595,22596,7,4,0,0,22596,22597,7,6,0,0,22597,
	22598,7,5,0,0,22598,22599,7,4,0,0,22599,3322,1,0,0,0,22600,22601,7,5,0,
	0,22601,22602,7,4,0,0,22602,22603,7,9,0,0,22603,22604,7,9,0,0,22604,22605,
	7,6,0,0,22605,22606,7,13,0,0,22606,22607,5,95,0,0,22607,22608,7,15,0,0,
	22608,22609,7,2,0,0,22609,22610,7,15,0,0,22610,3324,1,0,0,0,22611,22612,
	7,5,0,0,22612,22613,7,4,0,0,22613,22614,7,9,0,0,22614,22615,7,9,0,0,22615,
	22616,7,6,0,0,22616,22617,7,13,0,0,22617,22618,5,95,0,0,22618,22619,7,5,
	0,0,22619,22620,7,0,0,0,22620,22621,7,14,0,0,22621,22622,7,15,0,0,22622,
	3326,1,0,0,0,22623,22624,7,5,0,0,22624,22625,7,4,0,0,22625,22626,7,2,0,
	0,22626,22627,7,15,0,0,22627,3328,1,0,0,0,22628,22629,7,5,0,0,22629,22630,
	7,4,0,0,22630,22631,7,2,0,0,22631,22632,7,3,0,0,22632,22633,7,0,0,0,22633,
	22634,7,17,0,0,22634,22635,7,6,0,0,22635,3330,1,0,0,0,22636,22637,7,5,0,
	0,22637,22638,7,4,0,0,22638,22639,7,2,0,0,22639,22640,7,3,0,0,22640,22641,
	7,6,0,0,22641,3332,1,0,0,0,22642,22643,7,5,0,0,22643,22644,7,4,0,0,22644,
	22645,7,3,0,0,22645,22646,7,6,0,0,22646,22647,7,0,0,0,22647,22648,7,14,
	0,0,22648,22649,7,5,0,0,22649,3334,1,0,0,0,22650,22651,7,5,0,0,22651,22652,
	7,4,0,0,22652,22653,7,3,0,0,22653,22654,7,6,0,0,22654,22655,7,0,0,0,22655,
	22656,7,14,0,0,22656,3336,1,0,0,0,22657,22658,7,5,0,0,22658,22659,7,4,0,
	0,22659,22660,7,3,0,0,22660,22661,7,12,0,0,22661,22662,7,8,0,0,22662,22663,
	7,4,0,0,22663,3338,1,0,0,0,22664,22665,7,5,0,0,22665,22666,7,4,0,0,22666,
	22667,7,3,0,0,22667,22668,7,12,0,0,22668,22669,7,7,0,0,22669,22670,7,17,
	0,0,22670,3340,1,0,0,0,22671,22672,7,5,0,0,22672,22673,7,4,0,0,22673,22674,
	7,3,0,0,22674,22675,7,12,0,0,22675,22676,7,15,0,0,22676,22677,7,6,0,0,22677,
	22678,5,95,0,0,22678,22679,7,8,0,0,22679,22680,7,2,0,0,22680,22681,7,11,
	0,0,22681,22682,7,10,0,0,22682,22683,7,14,0,0,22683,22684,7,7,0,0,22684,
	22685,7,5,0,0,22685,3342,1,0,0,0,22686,22687,7,5,0,0,22687,22688,7,4,0,
	0,22688,22689,7,3,0,0,22689,22690,7,12,0,0,22690,22691,7,15,0,0,22691,22692,
	7,6,0,0,22692,22693,5,95,0,0,22693,22694,7,22,0,0,22694,22695,7,12,0,0,
	22695,22696,7,9,0,0,22696,22697,7,4,0,0,22697,22698,7,19,0,0,22698,3344,
	1,0,0,0,22699,22700,7,5,0,0,22700,22701,7,4,0,0,22701,22702,7,3,0,0,22702,
	22703,7,12,0,0,22703,22704,7,15,0,0,22704,3346,1,0,0,0,22705,22706,7,5,
	0,0,22706,22707,7,4,0,0,22707,22708,7,3,0,0,22708,22709,7,10,0,0,22709,
	22710,7,8,0,0,22710,22711,7,4,0,0,22711,22712,7,10,0,0,22712,22713,7,3,
	0,0,22713,22714,7,6,0,0,22714,3348,1,0,0,0,22715,22716,7,5,0,0,22716,22717,
	7,10,0,0,22717,22718,7,1,0,0,22718,22719,7,14,0,0,22719,22720,7,10,0,0,
	22720,22721,7,11,0,0,22721,22722,7,4,0,0,22722,22723,7,12,0,0,22723,22724,
	7,5,0,0,22724,22725,7,6,0,0,22725,22726,7,4,0,0,22726,3350,1,0,0,0,22727,
	22728,7,5,0,0,22728,22729,7,10,0,0,22729,22730,7,1,0,0,22730,22731,7,15,
	0,0,22731,22732,7,0,0,0,22732,22733,7,3,0,0,22733,22734,7,4,0,0,22734,22735,
	7,12,0,0,22735,22736,7,4,0,0,22736,22737,7,12,0,0,22737,22738,7,2,0,0,22738,
	22739,7,7,0,0,22739,22740,5,95,0,0,22740,22741,7,3,0,0,22741,22742,7,6,
	0,0,22742,22743,7,11,0,0,22743,3352,1,0,0,0,22744,22745,7,5,0,0,22745,22746,
	7,10,0,0,22746,22747,7,1,0,0,22747,22748,7,15,0,0,22748,22749,7,0,0,0,22749,
	22750,7,3,0,0,22750,22751,7,4,0,0,22751,22752,7,12,0,0,22752,22753,7,4,
	0,0,22753,22754,7,12,0,0,22754,22755,7,2,0,0,22755,22756,7,7,0,0,22756,
	22757,7,5,0,0,22757,3354,1,0,0,0,22758,22759,7,5,0,0,22759,22760,7,10,0,
	0,22760,22761,7,1,0,0,22761,22762,7,15,0,0,22762,22763,7,0,0,0,22763,22764,
	7,3,0,0,22764,22765,7,4,0,0,22765,22766,7,12,0,0,22766,22767,7,4,0,0,22767,
	22768,7,12,0,0,22768,22769,7,2,0,0,22769,22770,7,7,0,0,22770,3356,1,0,0,
	0,22771,22772,7,5,0,0,22772,22773,7,10,0,0,22773,22774,7,1,0,0,22774,22775,
	7,24,0,0,22775,22776,7,10,0,0,22776,22777,7,6,0,0,22777,22778,7,3,0,0,22778,
	22779,7,12,0,0,22779,22780,7,6,0,0,22780,22781,7,5,0,0,22781,3358,1,0,0,
	0,22782,22783,7,5,0,0,22783,22784,7,10,0,0,22784,22785,7,1,0,0,22785,22786,
	7,24,0,0,22786,22787,7,10,0,0,22787,22788,7,6,0,0,22788,22789,7,3,0,0,22789,
	22790,7,18,0,0,22790,22791,5,95,0,0,22791,22792,7,15,0,0,22792,22793,7,
	3,0,0,22793,22794,7,10,0,0,22794,22795,7,7,0,0,22795,22796,7,12,0,0,22796,
	22797,7,7,0,0,22797,22798,7,17,0,0,22798,3360,1,0,0,0,22799,22800,7,5,0,
	0,22800,22801,7,10,0,0,22801,22802,7,1,0,0,22802,22803,7,5,0,0,22803,22804,
	7,8,0,0,22804,22805,7,3,0,0,22805,22806,7,12,0,0,22806,22807,7,1,0,0,22807,
	22808,7,6,0,0,22808,3362,1,0,0,0,22809,22810,7,5,0,0,22810,22811,7,10,0,
	0,22811,22812,7,1,0,0,22812,22813,7,5,0,0,22813,22814,7,6,0,0,22814,22815,
	7,4,0,0,22815,3364,1,0,0,0,22816,22817,7,5,0,0,22817,22818,7,10,0,0,22818,
	22819,7,1,0,0,22819,22820,7,5,0,0,22820,22821,7,4,0,0,22821,22822,7,12,
	0,0,22822,22823,7,4,0,0,22823,22824,7,10,0,0,22824,22825,7,4,0,0,22825,
	22826,7,0,0,0,22826,22827,7,1,0,0,22827,22828,7,11,0,0,22828,22829,7,6,
	0,0,22829,3366,1,0,0,0,22830,22831,7,5,0,0,22831,22832,7,10,0,0,22832,22833,
	7,1,0,0,22833,22834,7,5,0,0,22834,22835,7,4,0,0,22835,22836,7,3,0,0,22836,
	22837,5,50,0,0,22837,3368,1,0,0,0,22838,22839,7,5,0,0,22839,22840,7,10,
	0,0,22840,22841,7,1,0,0,22841,22842,7,5,0,0,22842,22843,7,4,0,0,22843,22844,
	7,3,0,0,22844,22845,5,52,0,0,22845,3370,1,0,0,0,22846,22847,7,5,0,0,22847,
	22848,7,10,0,0,22848,22849,7,1,0,0,22849,22850,7,5,0,0,22850,22851,7,4,
	0,0,22851,22852,7,3,0,0,22852,22853,7,1,0,0,22853,3372,1,0,0,0,22854,22855,
	7,5,0,0,22855,22856,7,10,0,0,22856,22857,7,1,0,0,22857,22858,7,5,0,0,22858,
	22859,7,4,0,0,22859,22860,7,3,0,0,22860,22861,7,8,0,0,22861,3374,1,0,0,
	0,22862,22863,7,5,0,0,22863,22864,7,10,0,0,22864,22865,7,1,0,0,22865,22866,
	7,4,0,0,22866,22867,7,18,0,0,22867,22868,7,15,0,0,22868,22869,7,6,0,0,22869,
	3376,1,0,0,0,22870,22871,7,5,0,0,22871,22872,7,10,0,0,22872,22873,7,8,0,
	0,22873,22874,7,8,0,0,22874,22875,7,6,0,0,22875,22876,7,5,0,0,22876,22877,
	7,5,0,0,22877,22878,7,16,0,0,22878,22879,7,10,0,0,22879,22880,7,11,0,0,
	22880,3378,1,0,0,0,22881,22882,7,5,0,0,22882,22883,7,10,0,0,22883,22884,
	7,8,0,0,22884,22885,7,8,0,0,22885,22886,7,6,0,0,22886,22887,7,5,0,0,22887,
	22888,7,5,0,0,22888,3380,1,0,0,0,22889,22890,7,5,0,0,22890,22891,7,10,0,
	0,22891,22892,7,14,0,0,22892,22893,7,14,0,0,22893,22894,7,0,0,0,22894,22895,
	7,3,0,0,22895,22896,7,18,0,0,22896,3382,1,0,0,0,22897,22898,7,5,0,0,22898,
	22899,7,10,0,0,22899,22900,7,15,0,0,22900,22901,7,15,0,0,22901,22902,7,
	11,0,0,22902,22903,7,6,0,0,22903,22904,7,14,0,0,22904,22905,7,6,0,0,22905,
	22906,7,7,0,0,22906,22907,7,4,0,0,22907,22908,7,0,0,0,22908,22909,7,11,
	0,0,22909,3384,1,0,0,0,22910,22911,7,5,0,0,22911,22912,7,10,0,0,22912,22913,
	7,5,0,0,22913,22914,7,15,0,0,22914,22915,7,6,0,0,22915,22916,7,7,0,0,22916,
	22917,7,9,0,0,22917,3386,1,0,0,0,22918,22919,7,5,0,0,22919,22920,7,22,0,
	0,22920,22921,7,0,0,0,22921,22922,7,15,0,0,22922,22923,5,95,0,0,22923,22924,
	7,20,0,0,22924,22925,7,2,0,0,22925,22926,7,12,0,0,22926,22927,7,7,0,0,22927,
	22928,5,95,0,0,22928,22929,7,12,0,0,22929,22930,7,7,0,0,22930,22931,7,15,
	0,0,22931,22932,7,10,0,0,22932,22933,7,4,0,0,22933,22934,7,5,0,0,22934,
	3388,1,0,0,0,22935,22936,7,5,0,0,22936,22937,7,22,0,0,22937,22938,7,12,
	0,0,22938,22939,7,4,0,0,22939,22940,7,8,0,0,22940,22941,7,19,0,0,22941,
	22942,7,2,0,0,22942,22943,7,13,0,0,22943,22944,7,6,0,0,22944,22945,7,3,
	0,0,22945,3390,1,0,0,0,22946,22947,7,5,0,0,22947,22948,7,22,0,0,22948,22949,
	7,12,0,0,22949,22950,7,4,0,0,22950,22951,7,8,0,0,22951,22952,7,19,0,0,22952,
	3392,1,0,0,0,22953,22954,7,5,0,0,22954,22955,7,18,0,0,22955,22956,7,7,0,
	0,22956,22957,7,8,0,0,22957,22958,7,19,0,0,22958,22959,7,3,0,0,22959,22960,
	7,2,0,0,22960,22961,7,7,0,0,22961,22962,7,2,0,0,22962,22963,7,10,0,0,22963,
	22964,7,5,0,0,22964,3394,1,0,0,0,22965,22966,7,5,0,0,22966,22967,7,18,0,
	0,22967,22968,7,7,0,0,22968,22969,7,8,0,0,22969,3396,1,0,0,0,22970,22971,
	7,5,0,0,22971,22972,7,18,0,0,22972,22973,7,7,0,0,22973,22974,7,2,0,0,22974,
	22975,7,7,0,0,22975,22976,7,18,0,0,22976,22977,7,14,0,0,22977,3398,1,0,
	0,0,22978,22979,7,5,0,0,22979,22980,7,18,0,0,22980,22981,7,5,0,0,22981,
	3400,1,0,0,0,22982,22983,7,5,0,0,22983,22984,7,18,0,0,22984,22985,7,5,0,
	0,22985,22986,7,0,0,0,22986,22987,7,5,0,0,22987,22988,7,14,0,0,22988,3402,
	1,0,0,0,22989,22990,7,5,0,0,22990,22991,7,18,0,0,22991,22992,7,5,0,0,22992,
	22993,5,95,0,0,22993,22994,7,0,0,0,22994,22995,7,10,0,0,22995,22996,7,9,
	0,0,22996,22997,7,12,0,0,22997,22998,7,4,0,0,22998,3404,1,0,0,0,22999,23000,
	7,5,0,0,23000,23001,7,18,0,0,23001,23002,7,5,0,0,23002,23003,7,0,0,0,23003,
	23004,7,10,0,0,23004,23005,7,25,0,0,23005,3406,1,0,0,0,23006,23007,7,5,
	0,0,23007,23008,7,18,0,0,23008,23009,7,5,0,0,23009,23010,7,1,0,0,23010,
	23011,7,0,0,0,23011,23012,7,8,0,0,23012,23013,7,21,0,0,23013,23014,7,10,
	0,0,23014,23015,7,15,0,0,23015,3408,1,0,0,0,23016,23017,7,5,0,0,23017,23018,
	7,18,0,0,23018,23019,7,5,0,0,23019,23020,5,95,0,0,23020,23021,7,8,0,0,23021,
	23022,7,19,0,0,23022,23023,7,6,0,0,23023,23024,7,8,0,0,23024,23025,7,21,
	0,0,23025,23026,7,0,0,0,23026,23027,7,8,0,0,23027,23028,7,11,0,0,23028,
	3410,1,0,0,0,23029,23030,7,5,0,0,23030,23031,7,18,0,0,23031,23032,7,5,0,
	0,23032,23033,5,95,0,0,23033,23034,7,8,0,0,23034,23035,7,19,0,0,23035,23036,
	7,6,0,0,23036,23037,7,8,0,0,23037,23038,7,21,0,0,23038,23039,5,95,0,0,23039,
	23040,7,15,0,0,23040,23041,7,3,0,0,23041,23042,7,12,0,0,23042,23043,7,13,
	0,0,23043,23044,7,12,0,0,23044,23045,7,11,0,0,23045,23046,7,6,0,0,23046,
	23047,7,17,0,0,23047,23048,7,6,0,0,23048,3412,1,0,0,0,23049,23050,7,5,0,
	0,23050,23051,7,18,0,0,23051,23052,7,5,0,0,23052,23053,5,95,0,0,23053,23054,
	7,8,0,0,23054,23055,7,2,0,0,23055,23056,7,7,0,0,23056,23057,7,7,0,0,23057,
	23058,7,6,0,0,23058,23059,7,8,0,0,23059,23060,7,4,0,0,23060,23061,5,95,
	0,0,23061,23062,7,1,0,0,23062,23063,7,18,0,0,23063,23064,5,95,0,0,23064,
	23065,7,15,0,0,23065,23066,7,0,0,0,23066,23067,7,4,0,0,23067,23068,7,19,
	0,0,23068,3414,1,0,0,0,23069,23070,7,5,0,0,23070,23071,7,18,0,0,23071,23072,
	7,5,0,0,23072,23073,5,95,0,0,23073,23074,7,8,0,0,23074,23075,7,2,0,0,23075,
	23076,7,7,0,0,23076,23077,7,4,0,0,23077,23078,7,6,0,0,23078,23079,7,25,
	0,0,23079,23080,7,4,0,0,23080,3416,1,0,0,0,23081,23082,7,5,0,0,23082,23083,
	7,18,0,0,23083,23084,7,5,0,0,23084,23085,7,9,0,0,23085,23086,7,0,0,0,23086,
	23087,7,4,0,0,23087,23088,7,6,0,0,23088,3418,1,0,0,0,23089,23090,7,5,0,
	0,23090,23091,7,18,0,0,23091,23092,7,5,0,0,23092,23093,7,9,0,0,23093,23094,
	7,1,0,0,23094,23095,7,0,0,0,23095,3420,1,0,0,0,23096,23097,7,5,0,0,23097,
	23098,7,18,0,0,23098,23099,7,5,0,0,23099,23100,5,95,0,0,23100,23101,7,9,
	0,0,23101,23102,7,1,0,0,23102,23103,7,10,0,0,23103,23104,7,3,0,0,23104,
	23105,7,12,0,0,23105,23106,7,17,0,0,23106,23107,7,6,0,0,23107,23108,7,7,
	0,0,23108,3422,1,0,0,0,23109,23110,7,5,0,0,23110,23111,7,18,0,0,23111,23112,
	7,5,0,0,23112,23113,7,9,0,0,23113,23114,7,17,0,0,23114,3424,1,0,0,0,23115,
	23116,7,5,0,0,23116,23117,7,18,0,0,23117,23118,7,5,0,0,23118,23119,5,95,
	0,0,23119,23120,7,9,0,0,23120,23121,7,11,0,0,23121,23122,5,95,0,0,23122,
	23123,7,8,0,0,23123,23124,7,10,0,0,23124,23125,7,3,0,0,23125,23126,7,5,
	0,0,23126,23127,7,2,0,0,23127,23128,7,3,0,0,23128,3426,1,0,0,0,23129,23130,
	7,5,0,0,23130,23131,7,18,0,0,23131,23132,7,5,0,0,23132,23133,5,95,0,0,23133,
	23134,7,9,0,0,23134,23135,7,14,0,0,23135,23136,5,95,0,0,23136,23137,7,3,
	0,0,23137,23138,7,25,0,0,23138,23139,7,16,0,0,23139,23140,7,2,0,0,23140,
	23141,7,3,0,0,23141,23142,7,14,0,0,23142,23143,5,95,0,0,23143,23144,7,8,
	0,0,23144,23145,7,19,0,0,23145,23146,7,3,0,0,23146,3428,1,0,0,0,23147,23148,
	7,5,0,0,23148,23149,7,18,0,0,23149,23150,7,5,0,0,23150,23151,5,95,0,0,23151,
	23152,7,9,0,0,23152,23153,7,14,0,0,23153,23154,5,95,0,0,23154,23155,7,3,
	0,0,23155,23156,7,25,0,0,23156,23157,7,16,0,0,23157,23158,7,2,0,0,23158,
	23159,7,3,0,0,23159,23160,7,14,0,0,23160,23161,5,95,0,0,23161,23162,7,7,
	0,0,23162,23163,7,10,0,0,23163,23164,7,14,0,0,23164,3430,1,0,0,0,23165,
	23166,7,5,0,0,23166,23167,7,18,0,0,23167,23168,7,5,0,0,23168,23169,5,95,
	0,0,23169,23170,7,9,0,0,23170,23171,7,2,0,0,23171,23172,7,14,0,0,23172,
	23173,5,95,0,0,23173,23174,7,8,0,0,23174,23175,7,2,0,0,23175,23176,7,14,
	0,0,23176,23177,7,15,0,0,23177,23178,7,0,0,0,23178,23179,7,3,0,0,23179,
	23180,7,6,0,0,23180,3432,1,0,0,0,23181,23182,7,5,0,0,23182,23183,7,18,0,
	0,23183,23184,7,5,0,0,23184,23185,5,95,0,0,23185,23186,7,9,0,0,23186,23187,
	7,5,0,0,23187,23188,7,4,0,0,23188,23189,5,95,0,0,23189,23190,7,15,0,0,23190,
	23191,7,3,0,0,23191,23192,7,12,0,0,23192,23193,7,14,0,0,23193,23194,5,50,
	0,0,23194,23195,7,5,0,0,23195,23196,7,6,0,0,23196,23197,7,8,0,0,23197,3434,
	1,0,0,0,23198,23199,7,5,0,0,23199,23200,7,18,0,0,23200,23201,7,5,0,0,23201,
	23202,5,95,0,0,23202,23203,7,9,0,0,23203,23204,7,5,0,0,23204,23205,7,4,
	0,0,23205,23206,5,95,0,0,23206,23207,7,5,0,0,23207,23208,7,6,0,0,23208,
	23209,7,8,0,0,23209,23210,5,50,0,0,23210,23211,7,15,0,0,23211,23212,7,3,
	0,0,23212,23213,7,12,0,0,23213,23214,7,14,0,0,23214,3436,1,0,0,0,23215,
	23216,7,5,0,0,23216,23217,7,18,0,0,23217,23218,7,5,0,0,23218,23219,5,95,
	0,0,23219,23220,7,6,0,0,23220,23221,7,4,0,0,23221,23222,5,95,0,0,23222,
	23223,7,1,0,0,23223,23224,7,16,0,0,23224,23225,7,12,0,0,23225,23226,7,11,
	0,0,23226,23227,7,6,0,0,23227,23228,5,95,0,0,23228,23229,7,4,0,0,23229,
	23230,7,2,0,0,23230,23231,5,95,0,0,23231,23232,7,3,0,0,23232,23233,7,0,
	0,0,23233,23234,7,22,0,0,23234,3438,1,0,0,0,23235,23236,7,5,0,0,23236,23237,
	7,18,0,0,23237,23238,7,5,0,0,23238,23239,5,95,0,0,23239,23240,7,6,0,0,23240,
	23241,7,4,0,0,23241,23242,5,95,0,0,23242,23243,7,1,0,0,23243,23244,7,11,
	0,0,23244,23245,7,2,0,0,23245,23246,7,1,0,0,23246,23247,5,95,0,0,23247,
	23248,7,4,0,0,23248,23249,7,2,0,0,23249,23250,5,95,0,0,23250,23251,7,12,
	0,0,23251,23252,7,14,0,0,23252,23253,7,0,0,0,23253,23254,7,17,0,0,23254,
	23255,7,6,0,0,23255,3440,1,0,0,0,23256,23257,7,5,0,0,23257,23258,7,18,0,
	0,23258,23259,7,5,0,0,23259,23260,5,95,0,0,23260,23261,7,6,0,0,23261,23262,
	7,4,0,0,23262,23263,5,95,0,0,23263,23264,7,12,0,0,23264,23265,7,14,0,0,
	23265,23266,7,0,0,0,23266,23267,7,17,0,0,23267,23268,7,6,0,0,23268,23269,
	5,95,0,0,23269,23270,7,4,0,0,23270,23271,7,2,0,0,23271,23272,5,95,0,0,23272,
	23273,7,1,0,0,23273,23274,7,11,0,0,23274,23275,7,2,0,0,23275,23276,7,1,
	0,0,23276,3442,1,0,0,0,23277,23278,7,5,0,0,23278,23279,7,18,0,0,23279,23280,
	7,5,0,0,23280,23281,5,95,0,0,23281,23282,7,6,0,0,23282,23283,7,4,0,0,23283,
	23284,5,95,0,0,23284,23285,7,3,0,0,23285,23286,7,0,0,0,23286,23287,7,22,
	0,0,23287,23288,5,95,0,0,23288,23289,7,4,0,0,23289,23290,7,2,0,0,23290,
	23291,5,95,0,0,23291,23292,7,1,0,0,23292,23293,7,16,0,0,23293,23294,7,12,
	0,0,23294,23295,7,11,0,0,23295,23296,7,6,0,0,23296,3444,1,0,0,0,23297,23298,
	7,5,0,0,23298,23299,7,18,0,0,23299,23300,7,5,0,0,23300,23301,5,95,0,0,23301,
	23302,7,6,0,0,23302,23303,7,25,0,0,23303,23304,7,4,0,0,23304,23305,7,15,
	0,0,23305,23306,7,9,0,0,23306,23307,7,4,0,0,23307,23308,7,25,0,0,23308,
	23309,7,4,0,0,23309,3446,1,0,0,0,23310,23311,7,5,0,0,23311,23312,7,18,0,
	0,23312,23313,7,5,0,0,23313,23314,5,95,0,0,23314,23315,7,6,0,0,23315,23316,
	7,25,0,0,23316,23317,7,4,0,0,23317,23318,7,3,0,0,23318,23319,7,0,0,0,23319,
	23320,7,8,0,0,23320,23321,7,4,0,0,23321,23322,5,95,0,0,23322,23323,7,10,
	0,0,23323,23324,7,4,0,0,23324,23325,7,8,0,0,23325,3448,1,0,0,0,23326,23327,
	7,5,0,0,23327,23328,7,18,0,0,23328,23329,7,5,0,0,23329,23330,5,95,0,0,23330,
	23331,7,16,0,0,23331,23332,7,1,0,0,23332,23333,7,4,0,0,23333,23334,5,95,
	0,0,23334,23335,7,12,0,0,23335,23336,7,7,0,0,23336,23337,7,5,0,0,23337,
	23338,7,9,0,0,23338,23339,7,6,0,0,23339,23340,7,11,0,0,23340,3450,1,0,0,
	0,23341,23342,7,5,0,0,23342,23343,7,18,0,0,23343,23344,7,5,0,0,23344,23345,
	5,95,0,0,23345,23346,7,16,0,0,23346,23347,7,12,0,0,23347,23348,7,11,0,0,
	23348,23349,7,4,0,0,23349,23350,7,6,0,0,23350,23351,7,3,0,0,23351,23352,
	5,95,0,0,23352,23353,7,0,0,0,23353,23354,7,8,0,0,23354,23355,7,11,0,0,23355,
	23356,7,5,0,0,23356,3452,1,0,0,0,23357,23358,7,5,0,0,23358,23359,7,18,0,
	0,23359,23360,7,5,0,0,23360,23361,5,95,0,0,23361,23362,7,16,0,0,23362,23363,
	7,7,0,0,23363,23364,7,14,0,0,23364,23365,7,0,0,0,23365,23366,7,4,0,0,23366,
	23367,7,8,0,0,23367,23368,7,19,0,0,23368,23369,7,6,0,0,23369,23370,7,5,
	0,0,23370,3454,1,0,0,0,23371,23372,7,5,0,0,23372,23373,7,18,0,0,23373,23374,
	7,5,0,0,23374,23375,5,95,0,0,23375,23376,7,16,0,0,23376,23377,7,7,0,0,23377,
	23378,7,3,0,0,23378,23379,7,6,0,0,23379,23380,7,15,0,0,23380,23381,7,11,
	0,0,23381,23382,7,0,0,0,23382,23383,7,8,0,0,23383,23384,7,6,0,0,23384,3456,
	1,0,0,0,23385,23386,7,5,0,0,23386,23387,7,18,0,0,23387,23388,7,5,0,0,23388,
	23389,5,95,0,0,23389,23390,7,17,0,0,23390,23391,7,6,0,0,23391,23392,7,4,
	0,0,23392,23393,5,95,0,0,23393,23394,7,0,0,0,23394,23395,7,8,0,0,23395,
	23396,7,11,0,0,23396,23397,7,12,0,0,23397,23398,7,9,0,0,23398,23399,7,5,
	0,0,23399,3458,1,0,0,0,23400,23401,7,5,0,0,23401,23402,7,18,0,0,23402,23403,
	7,5,0,0,23403,23404,5,95,0,0,23404,23405,7,17,0,0,23405,23406,7,6,0,0,23406,
	23407,7,4,0,0,23407,23408,5,95,0,0,23408,23409,7,8,0,0,23409,23410,7,2,
	0,0,23410,23411,7,11,0,0,23411,23412,5,95,0,0,23412,23413,7,0,0,0,23413,
	23414,7,8,0,0,23414,23415,7,11,0,0,23415,23416,7,12,0,0,23416,23417,7,9,
	0,0,23417,23418,7,5,0,0,23418,3460,1,0,0,0,23419,23420,7,5,0,0,23420,23421,
	7,18,0,0,23421,23422,7,5,0,0,23422,23423,5,95,0,0,23423,23424,7,17,0,0,
	23424,23425,7,6,0,0,23425,23426,7,4,0,0,23426,23427,5,95,0,0,23427,23428,
	7,15,0,0,23428,23429,7,3,0,0,23429,23430,7,12,0,0,23430,23431,7,13,0,0,
	23431,23432,7,12,0,0,23432,23433,7,11,0,0,23433,23434,7,6,0,0,23434,23435,
	7,17,0,0,23435,23436,7,6,0,0,23436,23437,7,5,0,0,23437,3462,1,0,0,0,23438,
	23439,7,5,0,0,23439,23440,7,18,0,0,23440,23441,7,5,0,0,23441,23442,5,95,
	0,0,23442,23443,7,17,0,0,23443,23444,7,6,0,0,23444,23445,7,4,0,0,23445,
	23446,7,4,0,0,23446,23447,7,2,0,0,23447,23448,7,21,0,0,23448,23449,7,6,
	0,0,23449,23450,7,7,0,0,23450,23451,7,12,0,0,23451,23452,7,9,0,0,23452,
	3464,1,0,0,0,23453,23454,7,5,0,0,23454,23455,7,18,0,0,23455,23456,7,5,0,
	0,23456,23457,5,95,0,0,23457,23458,7,17,0,0,23458,23459,7,6,0,0,23459,23460,
	7,4,0,0,23460,23461,7,25,0,0,23461,23462,7,4,0,0,23462,23463,7,12,0,0,23463,
	23464,7,13,0,0,23464,23465,7,0,0,0,23465,23466,7,11,0,0,23466,3466,1,0,
	0,0,23467,23468,7,5,0,0,23468,23469,7,18,0,0,23469,23470,7,5,0,0,23470,
	23471,5,95,0,0,23471,23472,7,17,0,0,23472,23473,7,10,0,0,23473,23474,7,
	12,0,0,23474,23475,7,9,0,0,23475,3468,1,0,0,0,23476,23477,7,5,0,0,23477,
	23478,7,18,0,0,23478,23479,7,5,0,0,23479,23480,7,17,0,0,23480,23481,7,10,
	0,0,23481,23482,7,12,0,0,23482,23483,7,9,0,0,23483,3470,1,0,0,0,23484,23485,
	7,5,0,0,23485,23486,7,18,0,0,23486,23487,7,5,0,0,23487,23488,7,21,0,0,23488,
	23489,7,14,0,0,23489,3472,1,0,0,0,23490,23491,7,5,0,0,23491,23492,7,18,
	0,0,23492,23493,7,5,0,0,23493,23494,5,95,0,0,23494,23495,7,14,0,0,23495,
	23496,7,0,0,0,23496,23497,7,21,0,0,23497,23498,7,6,0,0,23498,23499,5,95,
	0,0,23499,23500,7,25,0,0,23500,23501,7,14,0,0,23501,23502,7,11,0,0,23502,
	23503,7,7,0,0,23503,23504,7,2,0,0,23504,23505,7,9,0,0,23505,23506,7,6,0,
	0,23506,23507,7,12,0,0,23507,23508,7,9,0,0,23508,3474,1,0,0,0,23509,23510,
	7,5,0,0,23510,23511,7,18,0,0,23511,23512,7,5,0,0,23512,23513,5,95,0,0,23513,
	23514,7,14,0,0,23514,23515,7,0,0,0,23515,23516,7,21,0,0,23516,23517,7,6,
	0,0,23517,23518,7,25,0,0,23518,23519,7,14,0,0,23519,23520,7,11,0,0,23520,
	3476,1,0,0,0,23521,23522,7,5,0,0,23522,23523,7,18,0,0,23523,23524,7,5,0,
	0,23524,23525,5,95,0,0,23525,23526,7,14,0,0,23526,23527,7,21,0,0,23527,
	23528,7,25,0,0,23528,23529,7,14,0,0,23529,23530,7,11,0,0,23530,23531,7,
	0,0,0,23531,23532,7,4,0,0,23532,23533,7,4,0,0,23533,23534,7,3,0,0,23534,
	3478,1,0,0,0,23535,23536,7,5,0,0,23536,23537,7,18,0,0,23537,23538,7,5,0,
	0,23538,23539,5,95,0,0,23539,23540,7,14,0,0,23540,23541,7,21,0,0,23541,
	23542,7,25,0,0,23542,23543,7,4,0,0,23543,23544,7,12,0,0,23544,3480,1,0,
	0,0,23545,23546,7,5,0,0,23546,23547,7,18,0,0,23547,23548,7,5,0,0,23548,
	23549,7,2,0,0,23549,23550,7,1,0,0,23550,23551,7,20,0,0,23551,3482,1,0,0,
	0,23552,23553,7,5,0,0,23553,23554,7,18,0,0,23554,23555,7,5,0,0,23555,23556,
	5,95,0,0,23556,23557,7,2,0,0,23557,23558,7,15,0,0,23558,23559,5,95,0,0,
	23559,23560,7,0,0,0,23560,23561,7,9,0,0,23561,23562,7,4,0,0,23562,23563,
	5,50,0,0,23563,23564,7,1,0,0,23564,23565,7,12,0,0,23565,23566,7,7,0,0,23566,
	3484,1,0,0,0,23567,23568,7,5,0,0,23568,23569,7,18,0,0,23569,23570,7,5,0,
	0,23570,23571,5,95,0,0,23571,23572,7,2,0,0,23572,23573,7,15,0,0,23573,23574,
	5,95,0,0,23574,23575,7,0,0,0,23575,23576,7,9,0,0,23576,23577,7,4,0,0,23577,
	23578,7,8,0,0,23578,23579,7,2,0,0,23579,23580,7,7,0,0,23580,23581,7,5,0,
	0,23581,3486,1,0,0,0,23582,23583,7,5,0,0,23583,23584,7,18,0,0,23584,23585,
	7,5,0,0,23585,23586,5,95,0,0,23586,23587,7,2,0,0,23587,23588,7,15,0,0,23588,
	23589,5,95,0,0,23589,23590,7,0,0,0,23590,23591,7,11,0,0,23591,23592,7,5,
	0,0,23592,23593,7,8,0,0,23593,23594,7,3,0,0,23594,23595,7,13,0,0,23595,
	23596,7,0,0,0,23596,23597,7,11,0,0,23597,3488,1,0,0,0,23598,23599,7,5,0,
	0,23599,23600,7,18,0,0,23600,23601,7,5,0,0,23601,23602,5,95,0,0,23602,23603,
	7,2,0,0,23603,23604,7,15,0,0,23604,23605,5,95,0,0,23605,23606,7,0,0,0,23606,
	23607,7,4,0,0,23607,23608,7,17,0,0,23608,3490,1,0,0,0,23609,23610,7,5,0,
	0,23610,23611,7,18,0,0,23611,23612,7,5,0,0,23612,23613,5,95,0,0,23613,23614,
	7,2,0,0,23614,23615,7,15,0,0,23615,23616,5,95,0,0,23616,23617,7,1,0,0,23617,
	23618,7,12,0,0,23618,23619,7,7,0,0,23619,23620,5,50,0,0,23620,23621,7,0,
	0,0,23621,23622,7,9,0,0,23622,23623,7,4,0,0,23623,3492,1,0,0,0,23624,23625,
	7,5,0,0,23625,23626,7,18,0,0,23626,23627,7,5,0,0,23627,23628,5,95,0,0,23628,
	23629,7,2,0,0,23629,23630,7,15,0,0,23630,23631,5,95,0,0,23631,23632,7,1,
	0,0,23632,23633,7,12,0,0,23633,23634,7,4,0,0,23634,23635,7,13,0,0,23635,
	23636,7,6,0,0,23636,23637,7,8,0,0,23637,3494,1,0,0,0,23638,23639,7,5,0,
	0,23639,23640,7,18,0,0,23640,23641,7,5,0,0,23641,23642,5,95,0,0,23642,23643,
	7,2,0,0,23643,23644,7,15,0,0,23644,23645,5,95,0,0,23645,23646,7,1,0,0,23646,
	23647,7,11,0,0,23647,23648,5,50,0,0,23648,23649,7,3,0,0,23649,3496,1,0,
	0,0,23650,23651,7,5,0,0,23651,23652,7,18,0,0,23652,23653,7,5,0,0,23653,
	23654,5,95,0,0,23654,23655,7,2,0,0,23655,23656,7,15,0,0,23656,23657,5,95,
	0,0,23657,23658,7,1,0,0,23658,23659,7,11,0,0,23659,23660,7,2,0,0,23660,
	23661,7,2,0,0,23661,23662,7,14,0,0,23662,23663,5,95,0,0,23663,23664,7,16,
	0,0,23664,23665,7,12,0,0,23665,23666,7,11,0,0,23666,23667,7,4,0,0,23667,
	23668,7,6,0,0,23668,23669,7,3,0,0,23669,23670,5,95,0,0,23670,23671,7,11,
	0,0,23671,23672,7,12,0,0,23672,23673,7,5,0,0,23673,23674,7,4,0,0,23674,
	3498,1,0,0,0,23675,23676,7,5,0,0,23676,23677,7,18,0,0,23677,23678,7,5,0,
	0,23678,23679,5,95,0,0,23679,23680,7,2,0,0,23680,23681,7,15,0,0,23681,23682,
	5,95,0,0,23682,23683,7,1,0,0,23683,23684,7,11,0,0,23684,23685,7,2,0,0,23685,
	23686,7,2,0,0,23686,23687,7,14,0,0,23687,23688,5,95,0,0,23688,23689,7,16,
	0,0,23689,23690,7,12,0,0,23690,23691,7,11,0,0,23691,23692,7,4,0,0,23692,
	23693,7,6,0,0,23693,23694,7,3,0,0,23694,3500,1,0,0,0,23695,23696,7,5,0,
	0,23696,23697,7,18,0,0,23697,23698,7,5,0,0,23698,23699,5,95,0,0,23699,23700,
	7,2,0,0,23700,23701,7,15,0,0,23701,23702,5,95,0,0,23702,23703,7,8,0,0,23703,
	23704,5,50,0,0,23704,23705,7,8,0,0,23705,3502,1,0,0,0,23706,23707,7,5,0,
	0,23707,23708,7,18,0,0,23708,23709,7,5,0,0,23709,23710,5,95,0,0,23710,23711,
	7,2,0,0,23711,23712,7,15,0,0,23712,23713,5,95,0,0,23713,23714,7,8,0,0,23714,
	23715,7,0,0,0,23715,23716,7,5,0,0,23716,23717,7,4,0,0,23717,3504,1,0,0,
	0,23718,23719,7,5,0,0,23719,23720,7,18,0,0,23720,23721,7,5,0,0,23721,23722,
	5,95,0,0,23722,23723,7,2,0,0,23723,23724,7,15,0,0,23724,23725,5,95,0,0,
	23725,23726,7,8,0,0,23726,23727,7,6,0,0,23727,23728,7,17,0,0,23728,3506,
	1,0,0,0,23729,23730,7,5,0,0,23730,23731,7,18,0,0,23731,23732,7,5,0,0,23732,
	23733,5,95,0,0,23733,23734,7,2,0,0,23734,23735,7,15,0,0,23735,23736,5,95,
	0,0,23736,23737,7,8,0,0,23737,23738,7,11,0,0,23738,23739,5,50,0,0,23739,
	23740,7,8,0,0,23740,3508,1,0,0,0,23741,23742,7,5,0,0,23742,23743,7,18,0,
	0,23743,23744,7,5,0,0,23744,23745,5,95,0,0,23745,23746,7,2,0,0,23746,23747,
	7,15,0,0,23747,23748,5,95,0,0,23748,23749,7,8,0,0,23749,23750,7,2,0,0,23750,
	23751,7,14,0,0,23751,23752,7,1,0,0,23752,23753,7,12,0,0,23753,23754,7,7,
	0,0,23754,23755,7,6,0,0,23755,23756,7,9,0,0,23756,23757,5,95,0,0,23757,
	23758,7,19,0,0,23758,23759,7,0,0,0,23759,23760,7,5,0,0,23760,23761,7,19,
	0,0,23761,3510,1,0,0,0,23762,23763,7,5,0,0,23763,23764,7,18,0,0,23764,23765,
	7,5,0,0,23765,23766,5,95,0,0,23766,23767,7,2,0,0,23767,23768,7,15,0,0,23768,
	23769,5,95,0,0,23769,23770,7,8,0,0,23770,23771,7,2,0,0,23771,23772,7,14,
	0,0,23772,23773,7,15,0,0,23773,3512,1,0,0,0,23774,23775,7,5,0,0,23775,23776,
	7,18,0,0,23776,23777,7,5,0,0,23777,23778,5,95,0,0,23778,23779,7,2,0,0,23779,
	23780,7,15,0,0,23780,23781,5,95,0,0,23781,23782,7,8,0,0,23782,23783,7,2,
	0,0,23783,23784,7,7,0,0,23784,23785,7,13,0,0,23785,23786,7,6,0,0,23786,
	23787,7,3,0,0,23787,23788,7,4,0,0,23788,3514,1,0,0,0,23789,23790,7,5,0,
	0,23790,23791,7,18,0,0,23791,23792,7,5,0,0,23792,23793,5,95,0,0,23793,23794,
	7,2,0,0,23794,23795,7,15,0,0,23795,23796,5,95,0,0,23796,23797,7,8,0,0,23797,
	23798,7,2,0,0,23798,23799,7,10,0,0,23799,23800,7,7,0,0,23800,23801,7,4,
	0,0,23801,23802,7,8,0,0,23802,23803,7,19,0,0,23803,23804,7,17,0,0,23804,
	3516,1,0,0,0,23805,23806,7,5,0,0,23806,23807,7,18,0,0,23807,23808,7,5,0,
	0,23808,23809,5,95,0,0,23809,23810,7,2,0,0,23810,23811,7,15,0,0,23811,23812,
	5,95,0,0,23812,23813,7,8,0,0,23813,23814,7,5,0,0,23814,23815,7,8,0,0,23815,
	23816,7,2,0,0,23816,23817,7,7,0,0,23817,23818,7,13,0,0,23818,3518,1,0,0,
	0,23819,23820,7,5,0,0,23820,23821,7,18,0,0,23821,23822,7,5,0,0,23822,23823,
	5,95,0,0,23823,23824,7,2,0,0,23824,23825,7,15,0,0,23825,23826,5,95,0,0,
	23826,23827,7,8,0,0,23827,23828,7,5,0,0,23828,23829,7,8,0,0,23829,23830,
	7,2,0,0,23830,23831,7,7,0,0,23831,23832,7,13,0,0,23832,23833,7,4,0,0,23833,
	23834,7,6,0,0,23834,23835,7,5,0,0,23835,23836,7,4,0,0,23836,3520,1,0,0,
	0,23837,23838,7,5,0,0,23838,23839,7,18,0,0,23839,23840,7,5,0,0,23840,23841,
	5,95,0,0,23841,23842,7,2,0,0,23842,23843,7,15,0,0,23843,23844,5,95,0,0,
	23844,23845,7,8,0,0,23845,23846,7,5,0,0,23846,23847,7,3,0,0,23847,3522,
	1,0,0,0,23848,23849,7,5,0,0,23849,23850,7,18,0,0,23850,23851,7,5,0,0,23851,
	23852,5,95,0,0,23852,23853,7,2,0,0,23853,23854,7,15,0,0,23854,23855,5,95,
	0,0,23855,23856,7,8,0,0,23856,23857,7,5,0,0,23857,23858,7,25,0,0,23858,
	23859,5,95,0,0,23859,23860,7,15,0,0,23860,23861,7,0,0,0,23861,23862,7,4,
	0,0,23862,23863,7,8,0,0,23863,23864,7,19,0,0,23864,3524,1,0,0,0,23865,23866,
	7,5,0,0,23866,23867,7,18,0,0,23867,23868,7,5,0,0,23868,23869,5,95,0,0,23869,
	23870,7,2,0,0,23870,23871,7,15,0,0,23871,23872,5,95,0,0,23872,23873,7,8,
	0,0,23873,23874,7,18,0,0,23874,23875,7,8,0,0,23875,23876,7,11,0,0,23876,
	23877,7,6,0,0,23877,23878,7,9,0,0,23878,23879,5,95,0,0,23879,23880,7,5,
	0,0,23880,23881,7,6,0,0,23881,23882,7,24,0,0,23882,3526,1,0,0,0,23883,23884,
	7,5,0,0,23884,23885,7,18,0,0,23885,23886,7,5,0,0,23886,23887,5,95,0,0,23887,
	23888,7,2,0,0,23888,23889,7,15,0,0,23889,23890,5,95,0,0,23890,23891,7,9,
	0,0,23891,23892,7,6,0,0,23892,23893,7,8,0,0,23893,23894,7,2,0,0,23894,23895,
	7,14,0,0,23895,23896,7,15,0,0,23896,3528,1,0,0,0,23897,23898,7,5,0,0,23898,
	23899,7,18,0,0,23899,23900,7,5,0,0,23900,23901,5,95,0,0,23901,23902,7,2,
	0,0,23902,23903,7,15,0,0,23903,23904,5,95,0,0,23904,23905,7,9,0,0,23905,
	23906,7,6,0,0,23906,23907,7,5,0,0,23907,23908,7,8,0,0,23908,23909,7,6,0,
	0,23909,23910,7,7,0,0,23910,23911,7,9,0,0,23911,3530,1,0,0,0,23912,23913,
	7,5,0,0,23913,23914,7,18,0,0,23914,23915,7,5,0,0,23915,23916,5,95,0,0,23916,
	23917,7,2,0,0,23917,23918,7,15,0,0,23918,23919,5,95,0,0,23919,23920,7,9,
	0,0,23920,23921,7,12,0,0,23921,23922,7,5,0,0,23922,23923,7,4,0,0,23923,
	23924,7,12,0,0,23924,23925,7,7,0,0,23925,23926,7,8,0,0,23926,23927,7,4,
	0,0,23927,3532,1,0,0,0,23928,23929,7,5,0,0,23929,23930,7,18,0,0,23930,23931,
	7,5,0,0,23931,23932,5,95,0,0,23932,23933,7,2,0,0,23933,23934,7,15,0,0,23934,
	23935,5,95,0,0,23935,23936,7,9,0,0,23936,23937,7,3,0,0,23937,23938,7,0,
	0,0,23938,3534,1,0,0,0,23939,23940,7,5,0,0,23940,23941,7,18,0,0,23941,23942,
	7,5,0,0,23942,23943,5,95,0,0,23943,23944,7,2,0,0,23944,23945,7,15,0,0,23945,
	23946,5,95,0,0,23946,23947,7,9,0,0,23947,23948,7,10,0,0,23948,23949,7,14,
	0,0,23949,23950,7,15,0,0,23950,3536,1,0,0,0,23951,23952,7,5,0,0,23952,23953,
	7,18,0,0,23953,23954,7,5,0,0,23954,23955,5,95,0,0,23955,23956,7,2,0,0,23956,
	23957,7,15,0,0,23957,23958,5,95,0,0,23958,23959,7,9,0,0,23959,23960,7,13,
	0,0,23960,23961,5,95,0,0,23961,23962,7,8,0,0,23962,23963,7,19,0,0,23963,
	23964,7,6,0,0,23964,23965,7,8,0,0,23965,23966,7,21,0,0,23966,3538,1,0,0,
	0,23967,23968,7,5,0,0,23968,23969,7,18,0,0,23969,23970,7,5,0,0,23970,23971,
	5,95,0,0,23971,23972,7,2,0,0,23972,23973,7,15,0,0,23973,23974,5,95,0,0,
	23974,23975,7,6,0,0,23975,23976,7,7,0,0,23976,23977,7,16,0,0,23977,23978,
	7,2,0,0,23978,23979,7,3,0,0,23979,23980,7,8,0,0,23980,23981,7,6,0,0,23981,
	23982,5,95,0,0,23982,23983,7,7,0,0,23983,23984,7,2,0,0,23984,23985,7,4,
	0,0,23985,23986,5,95,0,0,23986,23987,7,7,0,0,23987,23988,7,10,0,0,23988,
	23989,7,11,0,0,23989,23990,7,11,0,0,23990,23991,5,36,0,0,23991,3540,1,0,
	0,0,23992,23993,7,5,0,0,23993,23994,7,18,0,0,23994,23995,7,5,0,0,23995,
	23996,7,2,0,0,23996,23997,7,15,0,0,23997,23998,7,6,0,0,23998,23999,7,3,
	0,0,23999,3542,1,0,0,0,24000,24001,7,5,0,0,24001,24002,7,18,0,0,24002,24003,
	7,5,0,0,24003,24004,5,95,0,0,24004,24005,7,2,0,0,24005,24006,7,15,0,0,24006,
	24007,5,95,0,0,24007,24008,7,6,0,0,24008,24009,7,25,0,0,24009,24010,7,4,
	0,0,24010,24011,7,3,0,0,24011,24012,7,0,0,0,24012,24013,7,8,0,0,24013,24014,
	7,4,0,0,24014,3544,1,0,0,0,24015,24016,7,5,0,0,24016,24017,7,18,0,0,24017,
	24018,7,5,0,0,24018,24019,5,95,0,0,24019,24020,7,2,0,0,24020,24021,7,15,
	0,0,24021,24022,5,95,0,0,24022,24023,7,17,0,0,24023,24024,7,3,0,0,24024,
	24025,7,2,0,0,24025,24026,7,10,0,0,24026,24027,7,15,0,0,24027,24028,7,12,
	0,0,24028,24029,7,7,0,0,24029,24030,7,17,0,0,24030,3546,1,0,0,0,24031,24032,
	7,5,0,0,24032,24033,7,18,0,0,24033,24034,7,5,0,0,24034,24035,5,95,0,0,24035,
	24036,7,2,0,0,24036,24037,7,15,0,0,24037,24038,5,95,0,0,24038,24039,7,17,
	0,0,24039,24040,7,10,0,0,24040,24041,7,12,0,0,24041,24042,7,9,0,0,24042,
	3548,1,0,0,0,24043,24044,7,5,0,0,24044,24045,7,18,0,0,24045,24046,7,5,0,
	0,24046,24047,5,95,0,0,24047,24048,7,2,0,0,24048,24049,7,15,0,0,24049,24050,
	5,95,0,0,24050,24051,7,19,0,0,24051,24052,7,0,0,0,24052,24053,7,5,0,0,24053,
	24054,7,19,0,0,24054,3550,1,0,0,0,24055,24056,7,5,0,0,24056,24057,7,18,
	0,0,24057,24058,7,5,0,0,24058,24059,5,95,0,0,24059,24060,7,2,0,0,24060,
	24061,7,15,0,0,24061,24062,5,95,0,0,24062,24063,7,12,0,0,24063,24064,7,
	12,0,0,24064,24065,7,25,0,0,24065,3552,1,0,0,0,24066,24067,7,5,0,0,24067,
	24068,7,18,0,0,24068,24069,7,5,0,0,24069,24070,5,95,0,0,24070,24071,7,2,
	0,0,24071,24072,7,15,0,0,24072,24073,5,95,0,0,24073,24074,7,12,0,0,24074,
	24075,7,4,0,0,24075,24076,7,3,0,0,24076,3554,1,0,0,0,24077,24078,7,5,0,
	0,24078,24079,7,18,0,0,24079,24080,7,5,0,0,24080,24081,5,95,0,0,24081,24082,
	7,2,0,0,24082,24083,7,15,0,0,24083,24084,5,95,0,0,24084,24085,7,21,0,0,
	24085,24086,7,6,0,0,24086,24087,7,18,0,0,24087,24088,5,95,0,0,24088,24089,
	7,13,0,0,24089,24090,7,6,0,0,24090,24091,7,8,0,0,24091,24092,7,4,0,0,24092,
	24093,7,2,0,0,24093,24094,7,3,0,0,24094,24095,5,95,0,0,24095,24096,7,8,
	0,0,24096,24097,7,3,0,0,24097,24098,7,6,0,0,24098,24099,7,0,0,0,24099,24100,
	7,4,0,0,24100,24101,7,6,0,0,24101,3556,1,0,0,0,24102,24103,7,5,0,0,24103,
	24104,7,18,0,0,24104,24105,7,5,0,0,24105,24106,5,95,0,0,24106,24107,7,2,
	0,0,24107,24108,7,15,0,0,24108,24109,5,95,0,0,24109,24110,7,21,0,0,24110,
	24111,7,6,0,0,24111,24112,7,18,0,0,24112,24113,5,95,0,0,24113,24114,7,13,
	0,0,24114,24115,7,6,0,0,24115,24116,7,8,0,0,24116,24117,7,4,0,0,24117,24118,
	7,2,0,0,24118,24119,7,3,0,0,24119,24120,5,95,0,0,24120,24121,7,16,0,0,24121,
	24122,7,12,0,0,24122,24123,7,11,0,0,24123,24124,7,4,0,0,24124,24125,7,6,
	0,0,24125,24126,7,3,0,0,24126,24127,5,95,0,0,24127,24128,7,11,0,0,24128,
	24129,7,12,0,0,24129,24130,7,5,0,0,24130,24131,7,4,0,0,24131,3558,1,0,0,
	0,24132,24133,7,5,0,0,24133,24134,7,18,0,0,24134,24135,7,5,0,0,24135,24136,
	5,95,0,0,24136,24137,7,2,0,0,24137,24138,7,15,0,0,24138,24139,5,95,0,0,
	24139,24140,7,21,0,0,24140,24141,7,6,0,0,24141,24142,7,18,0,0,24142,24143,
	5,95,0,0,24143,24144,7,13,0,0,24144,24145,7,6,0,0,24145,24146,7,8,0,0,24146,
	24147,7,4,0,0,24147,24148,7,2,0,0,24148,24149,7,3,0,0,24149,24150,5,95,
	0,0,24150,24151,7,16,0,0,24151,24152,7,12,0,0,24152,24153,7,11,0,0,24153,
	24154,7,4,0,0,24154,24155,7,6,0,0,24155,24156,7,3,0,0,24156,3560,1,0,0,
	0,24157,24158,7,5,0,0,24158,24159,7,18,0,0,24159,24160,7,5,0,0,24160,24161,
	5,95,0,0,24161,24162,7,2,0,0,24162,24163,7,15,0,0,24163,24164,5,95,0,0,
	24164,24165,7,21,0,0,24165,24166,7,6,0,0,24166,24167,7,18,0,0,24167,24168,
	5,95,0,0,24168,24169,7,13,0,0,24169,24170,7,6,0,0,24170,24171,7,8,0,0,24171,
	24172,7,4,0,0,24172,24173,7,2,0,0,24173,24174,7,3,0,0,24174,24175,5,95,
	0,0,24175,24176,7,5,0,0,24176,24177,7,10,0,0,24177,24178,7,8,0,0,24178,
	24179,7,8,0,0,24179,24180,7,6,0,0,24180,24181,7,6,0,0,24181,24182,7,9,0,
	0,24182,24183,7,6,0,0,24183,24184,7,9,0,0,24184,3562,1,0,0,0,24185,24186,
	7,5,0,0,24186,24187,7,18,0,0,24187,24188,7,5,0,0,24188,24189,5,95,0,0,24189,
	24190,7,2,0,0,24190,24191,7,15,0,0,24191,24192,5,95,0,0,24192,24193,7,21,
	0,0,24193,24194,7,6,0,0,24194,24195,7,18,0,0,24195,24196,5,95,0,0,24196,
	24197,7,13,0,0,24197,24198,7,6,0,0,24198,24199,7,8,0,0,24199,24200,7,4,
	0,0,24200,24201,7,2,0,0,24201,24202,7,3,0,0,24202,24203,5,95,0,0,24203,
	24204,7,10,0,0,24204,24205,7,5,0,0,24205,24206,7,6,0,0,24206,3564,1,0,0,
	0,24207,24208,7,5,0,0,24208,24209,7,18,0,0,24209,24210,7,5,0,0,24210,24211,
	5,95,0,0,24211,24212,7,2,0,0,24212,24213,7,15,0,0,24213,24214,5,95,0,0,
	24214,24215,7,11,0,0,24215,24216,7,1,0,0,24216,24217,7,12,0,0,24217,24218,
	7,9,0,0,24218,3566,1,0,0,0,24219,24220,7,5,0,0,24220,24221,7,18,0,0,24221,
	24222,7,5,0,0,24222,24223,5,95,0,0,24223,24224,7,2,0,0,24224,24225,7,15,
	0,0,24225,24226,5,95,0,0,24226,24227,7,11,0,0,24227,24228,7,2,0,0,24228,
	24229,7,1,0,0,24229,24230,7,11,0,0,24230,24231,7,2,0,0,24231,24232,7,8,
	0,0,24232,24233,5,50,0,0,24233,24234,7,1,0,0,24234,24235,7,11,0,0,24235,
	24236,7,2,0,0,24236,24237,7,1,0,0,24237,3568,1,0,0,0,24238,24239,7,5,0,
	0,24239,24240,7,18,0,0,24240,24241,7,5,0,0,24241,24242,5,95,0,0,24242,24243,
	7,2,0,0,24243,24244,7,15,0,0,24244,24245,5,95,0,0,24245,24246,7,11,0,0,
	24246,24247,7,2,0,0,24247,24248,7,1,0,0,24248,24249,7,11,0,0,24249,24250,
	7,2,0,0,24250,24251,7,8,0,0,24251,24252,5,50,0,0,24252,24253,7,8,0,0,24253,
	24254,7,11,0,0,24254,24255,7,2,0,0,24255,24256,7,1,0,0,24256,3570,1,0,0,
	0,24257,24258,7,5,0,0,24258,24259,7,18,0,0,24259,24260,7,5,0,0,24260,24261,
	5,95,0,0,24261,24262,7,2,0,0,24262,24263,7,15,0,0,24263,24264,5,95,0,0,
	24264,24265,7,11,0,0,24265,24266,7,2,0,0,24266,24267,7,1,0,0,24267,24268,
	7,11,0,0,24268,24269,7,2,0,0,24269,24270,7,8,0,0,24270,24271,5,50,0,0,24271,
	24272,7,12,0,0,24272,24273,7,9,0,0,24273,3572,1,0,0,0,24274,24275,7,5,0,
	0,24275,24276,7,18,0,0,24276,24277,7,5,0,0,24277,24278,5,95,0,0,24278,24279,
	7,2,0,0,24279,24280,7,15,0,0,24280,24281,5,95,0,0,24281,24282,7,11,0,0,
	24282,24283,7,2,0,0,24283,24284,7,1,0,0,24284,24285,7,11,0,0,24285,24286,
	7,2,0,0,24286,24287,7,8,0,0,24287,24288,5,50,0,0,24288,24289,7,7,0,0,24289,
	24290,7,8,0,0,24290,24291,7,11,0,0,24291,24292,7,2,0,0,24292,24293,7,1,
	0,0,24293,3574,1,0,0,0,24294,24295,7,5,0,0,24295,24296,7,18,0,0,24296,24297,
	7,5,0,0,24297,24298,5,95,0,0,24298,24299,7,2,0,0,24299,24300,7,15,0,0,24300,
	24301,5,95,0,0,24301,24302,7,11,0,0,24302,24303,7,2,0,0,24303,24304,7,1,
	0,0,24304,24305,7,11,0,0,24305,24306,7,2,0,0,24306,24307,7,8,0,0,24307,
	24308,5,50,0,0,24308,24309,7,4,0,0,24309,24310,7,18,0,0,24310,24311,7,15,
	0,0,24311,3576,1,0,0,0,24312,24313,7,5,0,0,24313,24314,7,18,0,0,24314,24315,
	7,5,0,0,24315,24316,5,95,0,0,24316,24317,7,2,0,0,24317,24318,7,15,0,0,24318,
	24319,5,95,0,0,24319,24320,7,11,0,0,24320,24321,7,5,0,0,24321,24322,7,13,
	0,0,24322,24323,7,12,0,0,24323,3578,1,0,0,0,24324,24325,7,5,0,0,24325,24326,
	7,18,0,0,24326,24327,7,5,0,0,24327,24328,5,95,0,0,24328,24329,7,2,0,0,24329,
	24330,7,15,0,0,24330,24331,5,95,0,0,24331,24332,7,11,0,0,24332,24333,7,
	13,0,0,24333,24334,7,11,0,0,24334,3580,1,0,0,0,24335,24336,7,5,0,0,24336,
	24337,7,18,0,0,24337,24338,7,5,0,0,24338,24339,5,95,0,0,24339,24340,7,2,
	0,0,24340,24341,7,15,0,0,24341,24342,5,95,0,0,24342,24343,7,14,0,0,24343,
	24344,7,0,0,0,24344,24345,7,21,0,0,24345,24346,7,6,0,0,24346,24347,7,2,
	0,0,24347,24348,7,12,0,0,24348,24349,7,9,0,0,24349,3582,1,0,0,0,24350,24351,
	7,5,0,0,24351,24352,7,18,0,0,24352,24353,7,5,0,0,24353,24354,5,95,0,0,24354,
	24355,7,2,0,0,24355,24356,7,15,0,0,24356,24357,5,95,0,0,24357,24358,7,14,
	0,0,24358,24359,7,0,0,0,24359,24360,7,15,0,0,24360,24361,5,95,0,0,24361,
	24362,7,7,0,0,24362,24363,7,2,0,0,24363,24364,7,7,0,0,24364,24365,7,7,0,
	0,24365,24366,7,10,0,0,24366,24367,7,11,0,0,24367,24368,7,11,0,0,24368,
	3584,1,0,0,0,24369,24370,7,5,0,0,24370,24371,7,18,0,0,24371,24372,7,5,0,
	0,24372,24373,5,95,0,0,24373,24374,7,2,0,0,24374,24375,7,15,0,0,24375,24376,
	5,95,0,0,24376,24377,7,14,0,0,24377,24378,7,5,0,0,24378,24379,7,3,0,0,24379,
	3586,1,0,0,0,24380,24381,7,5,0,0,24381,24382,7,18,0,0,24382,24383,7,5,0,
	0,24383,24384,5,95,0,0,24384,24385,7,2,0,0,24385,24386,7,15,0,0,24386,24387,
	5,95,0,0,24387,24388,7,7,0,0,24388,24389,7,12,0,0,24389,24390,7,8,0,0,24390,
	24391,7,2,0,0,24391,24392,7,14,0,0,24392,24393,7,1,0,0,24393,24394,7,12,
	0,0,24394,24395,7,7,0,0,24395,24396,7,6,0,0,24396,3588,1,0,0,0,24397,24398,
	7,5,0,0,24398,24399,7,18,0,0,24399,24400,7,5,0,0,24400,24401,5,95,0,0,24401,
	24402,7,2,0,0,24402,24403,7,15,0,0,24403,24404,5,95,0,0,24404,24405,7,7,
	0,0,24405,24406,7,12,0,0,24406,24407,7,6,0,0,24407,24408,7,25,0,0,24408,
	24409,7,4,0,0,24409,24410,7,3,0,0,24410,24411,7,0,0,0,24411,24412,7,8,0,
	0,24412,24413,7,4,0,0,24413,3590,1,0,0,0,24414,24415,7,5,0,0,24415,24416,
	7,18,0,0,24416,24417,7,5,0,0,24417,24418,5,95,0,0,24418,24419,7,2,0,0,24419,
	24420,7,15,0,0,24420,24421,5,95,0,0,24421,24422,7,7,0,0,24422,24423,7,12,
	0,0,24423,24424,7,12,0,0,24424,3592,1,0,0,0,24425,24426,7,5,0,0,24426,24427,
	7,18,0,0,24427,24428,7,5,0,0,24428,24429,5,95,0,0,24429,24430,7,2,0,0,24430,
	24431,7,15,0,0,24431,24432,5,95,0,0,24432,24433,7,7,0,0,24433,24434,7,12,
	0,0,24434,24435,7,25,0,0,24435,3594,1,0,0,0,24436,24437,7,5,0,0,24437,24438,
	7,18,0,0,24438,24439,7,5,0,0,24439,24440,5,95,0,0,24440,24441,7,2,0,0,24441,
	24442,7,15,0,0,24442,24443,5,95,0,0,24443,24444,7,7,0,0,24444,24445,7,2,
	0,0,24445,24446,7,6,0,0,24446,24447,7,25,0,0,24447,24448,7,15,0,0,24448,
	24449,7,0,0,0,24449,24450,7,7,0,0,24450,24451,7,9,0,0,24451,3596,1,0,0,
	0,24452,24453,7,5,0,0,24453,24454,7,18,0,0,24454,24455,7,5,0,0,24455,24456,
	5,95,0,0,24456,24457,7,2,0,0,24457,24458,7,15,0,0,24458,24459,5,95,0,0,
	24459,24460,7,7,0,0,24460,24461,7,4,0,0,24461,24462,7,8,0,0,24462,24463,
	7,12,0,0,24463,24464,7,14,0,0,24464,24465,7,17,0,0,24465,24466,5,36,0,0,
	24466,3598,1,0,0,0,24467,24468,7,5,0,0,24468,24469,7,18,0,0,24469,24470,
	7,5,0,0,24470,24471,5,95,0,0,24471,24472,7,2,0,0,24472,24473,7,15,0,0,24473,
	24474,5,95,0,0,24474,24475,7,7,0,0,24475,24476,7,10,0,0,24476,24477,7,14,
	0,0,24477,24478,7,4,0,0,24478,24479,7,2,0,0,24479,24480,7,3,0,0,24480,24481,
	7,0,0,0,24481,24482,7,22,0,0,24482,3600,1,0,0,0,24483,24484,7,5,0,0,24484,
	24485,7,18,0,0,24485,24486,7,5,0,0,24486,24487,5,95,0,0,24487,24488,7,2,
	0,0,24488,24489,7,15,0,0,24489,24490,5,95,0,0,24490,24491,7,2,0,0,24491,
	24492,7,12,0,0,24492,24493,7,9,0,0,24493,24494,7,13,0,0,24494,24495,7,0,
	0,0,24495,24496,7,11,0,0,24496,24497,7,10,0,0,24497,24498,7,6,0,0,24498,
	3602,1,0,0,0,24499,24500,7,5,0,0,24500,24501,7,18,0,0,24501,24502,7,5,0,
	0,24502,24503,5,95,0,0,24503,24504,7,2,0,0,24504,24505,7,15,0,0,24505,24506,
	5,95,0,0,24506,24507,7,2,0,0,24507,24508,7,15,0,0,24508,24509,7,7,0,0,24509,
	24510,7,5,0,0,24510,24511,7,12,0,0,24511,24512,7,23,0,0,24512,24513,7,6,
	0,0,24513,3604,1,0,0,0,24514,24515,7,5,0,0,24515,24516,7,18,0,0,24516,24517,
	7,5,0,0,24517,24518,5,95,0,0,24518,24519,7,2,0,0,24519,24520,7,15,0,0,24520,
	24521,5,95,0,0,24521,24522,7,15,0,0,24522,24523,7,0,0,0,24523,24524,7,3,
	0,0,24524,24525,5,95,0,0,24525,24526,5,49,0,0,24526,3606,1,0,0,0,24527,
	24528,7,5,0,0,24528,24529,7,18,0,0,24529,24530,7,5,0,0,24530,24531,5,95,
	0,0,24531,24532,7,2,0,0,24532,24533,7,15,0,0,24533,24534,5,95,0,0,24534,
	24535,7,15,0,0,24535,24536,7,0,0,0,24536,24537,7,3,0,0,24537,24538,7,17,
	0,0,24538,24539,7,12,0,0,24539,24540,7,9,0,0,24540,24541,5,95,0,0,24541,
	24542,5,49,0,0,24542,3608,1,0,0,0,24543,24544,7,5,0,0,24544,24545,7,18,
	0,0,24545,24546,7,5,0,0,24546,24547,5,95,0,0,24547,24548,7,2,0,0,24548,
	24549,7,15,0,0,24549,24550,5,95,0,0,24550,24551,7,15,0,0,24551,24552,7,
	0,0,0,24552,24553,7,3,0,0,24553,24554,7,17,0,0,24554,24555,7,12,0,0,24555,
	24556,7,9,0,0,24556,3610,1,0,0,0,24557,24558,7,5,0,0,24558,24559,7,18,0,
	0,24559,24560,7,5,0,0,24560,24561,5,95,0,0,24561,24562,7,2,0,0,24562,24563,
	7,15,0,0,24563,24564,5,95,0,0,24564,24565,7,15,0,0,24565,24566,7,0,0,0,
	24566,24567,7,3,0,0,24567,3612,1,0,0,0,24568,24569,7,5,0,0,24569,24570,
	7,18,0,0,24570,24571,7,5,0,0,24571,24572,5,95,0,0,24572,24573,7,2,0,0,24573,
	24574,7,15,0,0,24574,24575,5,95,0,0,24575,24576,7,15,0,0,24576,24577,7,
	0,0,0,24577,24578,7,3,0,0,24578,24579,7,4,0,0,24579,24580,5,95,0,0,24580,
	24581,7,12,0,0,24581,24582,7,9,0,0,24582,3614,1,0,0,0,24583,24584,7,5,0,
	0,24584,24585,7,18,0,0,24585,24586,7,5,0,0,24586,24587,5,95,0,0,24587,24588,
	7,2,0,0,24588,24589,7,15,0,0,24589,24590,5,95,0,0,24590,24591,7,15,0,0,
	24591,24592,7,12,0,0,24592,24593,7,13,0,0,24593,24594,7,2,0,0,24594,24595,
	7,4,0,0,24595,3616,1,0,0,0,24596,24597,7,5,0,0,24597,24598,7,18,0,0,24598,
	24599,7,5,0,0,24599,24600,5,95,0,0,24600,24601,7,2,0,0,24601,24602,7,15,
	0,0,24602,24603,5,95,0,0,24603,24604,7,3,0,0,24604,24605,5,50,0,0,24605,
	24606,7,2,0,0,24606,3618,1,0,0,0,24607,24608,7,5,0,0,24608,24609,7,18,0,
	0,24609,24610,7,5,0,0,24610,24611,5,95,0,0,24611,24612,7,2,0,0,24612,24613,
	7,15,0,0,24613,24614,5,95,0,0,24614,24615,7,3,0,0,24615,24616,7,0,0,0,24616,
	24617,7,22,0,0,24617,24618,7,4,0,0,24618,24619,7,2,0,0,24619,24620,7,7,
	0,0,24620,24621,7,10,0,0,24621,24622,7,14,0,0,24622,3620,1,0,0,0,24623,
	24624,7,5,0,0,24624,24625,7,18,0,0,24625,24626,7,5,0,0,24626,24627,5,95,
	0,0,24627,24628,7,2,0,0,24628,24629,7,15,0,0,24629,24630,5,95,0,0,24630,
	24631,7,3,0,0,24631,24632,7,9,0,0,24632,24633,7,4,0,0,24633,24634,7,14,
	0,0,24634,3622,1,0,0,0,24635,24636,7,5,0,0,24636,24637,7,18,0,0,24637,24638,
	7,5,0,0,24638,24639,5,95,0,0,24639,24640,7,2,0,0,24640,24641,7,15,0,0,24641,
	24642,5,95,0,0,24642,24643,7,3,0,0,24643,24644,7,6,0,0,24644,24645,7,16,
	0,0,24645,3624,1,0,0,0,24646,24647,7,5,0,0,24647,24648,7,18,0,0,24648,24649,
	7,5,0,0,24649,24650,5,95,0,0,24650,24651,7,2,0,0,24651,24652,7,15,0,0,24652,
	24653,5,95,0,0,24653,24654,7,3,0,0,24654,24655,7,14,0,0,24655,24656,7,4,
	0,0,24656,24657,7,9,0,0,24657,3626,1,0,0,0,24658,24659,7,5,0,0,24659,24660,
	7,18,0,0,24660,24661,7,5,0,0,24661,24662,5,95,0,0,24662,24663,7,2,0,0,24663,
	24664,7,15,0,0,24664,24665,5,95,0,0,24665,24666,7,3,0,0,24666,24667,7,2,
	0,0,24667,24668,7,22,0,0,24668,24669,7,12,0,0,24669,24670,7,9,0,0,24670,
	24671,7,4,0,0,24671,24672,7,2,0,0,24672,24673,7,2,0,0,24673,24674,7,1,0,
	0,24674,24675,7,20,0,0,24675,3628,1,0,0,0,24676,24677,7,5,0,0,24677,24678,
	7,18,0,0,24678,24679,7,5,0,0,24679,24680,5,95,0,0,24680,24681,7,2,0,0,24681,
	24682,7,15,0,0,24682,24683,5,95,0,0,24683,24684,7,3,0,0,24684,24685,7,15,
	0,0,24685,24686,7,1,0,0,24686,3630,1,0,0,0,24687,24688,7,5,0,0,24688,24689,
	7,18,0,0,24689,24690,7,5,0,0,24690,24691,5,95,0,0,24691,24692,7,2,0,0,24692,
	24693,7,15,0,0,24693,24694,7,4,0,0,24694,24695,7,11,0,0,24695,24696,7,2,
	0,0,24696,24697,7,1,0,0,24697,24698,7,15,0,0,24698,24699,7,3,0,0,24699,
	24700,7,1,0,0,24700,24701,7,5,0,0,24701,24702,7,8,0,0,24702,3632,1,0,0,
	0,24703,24704,7,5,0,0,24704,24705,7,18,0,0,24705,24706,7,5,0,0,24706,24707,
	5,95,0,0,24707,24708,7,2,0,0,24708,24709,7,15,0,0,24709,24710,5,95,0,0,
	24710,24711,7,4,0,0,24711,24712,7,2,0,0,24712,24713,7,5,0,0,24713,24714,
	7,6,0,0,24714,24715,7,4,0,0,24715,24716,7,12,0,0,24716,24717,7,9,0,0,24717,
	3634,1,0,0,0,24718,24719,7,5,0,0,24719,24720,7,18,0,0,24720,24721,7,5,0,
	0,24721,24722,5,95,0,0,24722,24723,7,2,0,0,24723,24724,7,15,0,0,24724,24725,
	5,95,0,0,24725,24726,7,4,0,0,24726,24727,7,15,0,0,24727,24728,7,3,0,0,24728,
	3636,1,0,0,0,24729,24730,7,5,0,0,24730,24731,7,18,0,0,24731,24732,7,5,0,
	0,24732,24733,5,95,0,0,24733,24734,7,2,0,0,24734,24735,7,15,0,0,24735,24736,
	5,95,0,0,24736,24737,7,4,0,0,24737,24738,7,3,0,0,24738,24739,7,4,0,0,24739,
	24740,7,1,0,0,24740,3638,1,0,0,0,24741,24742,7,5,0,0,24742,24743,7,18,0,
	0,24743,24744,7,5,0,0,24744,24745,5,95,0,0,24745,24746,7,2,0,0,24746,24747,
	7,15,0,0,24747,24748,7,4,0,0,24748,24749,7,25,0,0,24749,24750,7,12,0,0,
	24750,24751,7,8,0,0,24751,24752,7,14,0,0,24752,24753,7,15,0,0,24753,3640,
	1,0,0,0,24754,24755,7,5,0,0,24755,24756,7,18,0,0,24756,24757,7,5,0,0,24757,
	24758,5,95,0,0,24758,24759,7,2,0,0,24759,24760,7,15,0,0,24760,24761,7,4,
	0,0,24761,24762,7,25,0,0,24762,24763,7,24,0,0,24763,24764,7,8,0,0,24764,
	24765,7,0,0,0,24765,24766,7,5,0,0,24766,24767,7,4,0,0,24767,24768,7,0,0,
	0,24768,24769,7,5,0,0,24769,24770,7,7,0,0,24770,24771,7,24,0,0,24771,3642,
	1,0,0,0,24772,24773,7,5,0,0,24773,24774,7,18,0,0,24774,24775,7,5,0,0,24775,
	24776,5,95,0,0,24776,24777,7,2,0,0,24777,24778,7,15,0,0,24778,24779,5,95,
	0,0,24779,24780,7,10,0,0,24780,24781,7,7,0,0,24781,24782,7,9,0,0,24782,
	24783,7,6,0,0,24783,24784,7,5,0,0,24784,24785,7,8,0,0,24785,24786,7,6,0,
	0,24786,24787,7,7,0,0,24787,24788,7,9,0,0,24788,3644,1,0,0,0,24789,24790,
	7,5,0,0,24790,24791,7,18,0,0,24791,24792,7,5,0,0,24792,24793,5,95,0,0,24793,
	24794,7,2,0,0,24794,24795,7,15,0,0,24795,24796,5,95,0,0,24796,24797,7,13,
	0,0,24797,24798,7,6,0,0,24798,24799,7,8,0,0,24799,24800,7,0,0,0,24800,24801,
	7,7,0,0,24801,24802,7,9,0,0,24802,3646,1,0,0,0,24803,24804,7,5,0,0,24804,
	24805,7,18,0,0,24805,24806,7,5,0,0,24806,24807,5,95,0,0,24807,24808,7,2,
	0,0,24808,24809,7,15,0,0,24809,24810,5,95,0,0,24810,24811,7,13,0,0,24811,
	24812,7,6,0,0,24812,24813,7,8,0,0,24813,24814,7,1,0,0,24814,24815,7,12,
	0,0,24815,24816,7,4,0,0,24816,3648,1,0,0,0,24817,24818,7,5,0,0,24818,24819,
	7,18,0,0,24819,24820,7,5,0,0,24820,24821,5,95,0,0,24821,24822,7,2,0,0,24822,
	24823,7,15,0,0,24823,24824,5,95,0,0,24824,24825,7,13,0,0,24825,24826,7,
	6,0,0,24826,24827,7,8,0,0,24827,24828,7,2,0,0,24828,24829,7,3,0,0,24829,
	3650,1,0,0,0,24830,24831,7,5,0,0,24831,24832,7,18,0,0,24832,24833,7,5,0,
	0,24833,24834,5,95,0,0,24834,24835,7,2,0,0,24835,24836,7,15,0,0,24836,24837,
	5,95,0,0,24837,24838,7,13,0,0,24838,24839,7,6,0,0,24839,24840,7,8,0,0,24840,
	24841,7,25,0,0,24841,24842,7,2,0,0,24842,24843,7,3,0,0,24843,3652,1,0,0,
	0,24844,24845,7,5,0,0,24845,24846,7,18,0,0,24846,24847,7,5,0,0,24847,24848,
	5,95,0,0,24848,24849,7,2,0,0,24849,24850,7,15,0,0,24850,24851,5,95,0,0,
	24851,24852,7,13,0,0,24852,24853,7,6,0,0,24853,24854,7,3,0,0,24854,24855,
	7,5,0,0,24855,24856,7,12,0,0,24856,24857,7,2,0,0,24857,24858,7,7,0,0,24858,
	3654,1,0,0,0,24859,24860,7,5,0,0,24860,24861,7,18,0,0,24861,24862,7,5,0,
	0,24862,24863,5,95,0,0,24863,24864,7,2,0,0,24864,24865,7,15,0,0,24865,24866,
	5,95,0,0,24866,24867,7,13,0,0,24867,24868,7,3,0,0,24868,24869,7,6,0,0,24869,
	24870,7,16,0,0,24870,3656,1,0,0,0,24871,24872,7,5,0,0,24872,24873,7,18,
	0,0,24873,24874,7,5,0,0,24874,24875,5,95,0,0,24875,24876,7,2,0,0,24876,
	24877,7,15,0,0,24877,24878,5,95,0,0,24878,24879,7,13,0,0,24879,24880,7,
	13,0,0,24880,24881,7,9,0,0,24881,3658,1,0,0,0,24882,24883,7,5,0,0,24883,
	24884,7,18,0,0,24884,24885,7,5,0,0,24885,24886,5,95,0,0,24886,24887,7,2,
	0,0,24887,24888,7,15,0,0,24888,24889,5,95,0,0,24889,24890,7,25,0,0,24890,
	24891,7,14,0,0,24891,24892,7,11,0,0,24892,24893,7,8,0,0,24893,24894,7,2,
	0,0,24894,24895,7,7,0,0,24895,24896,7,5,0,0,24896,24897,5,95,0,0,24897,
	24898,7,16,0,0,24898,24899,7,2,0,0,24899,24900,7,3,0,0,24900,24901,5,95,
	0,0,24901,24902,7,8,0,0,24902,24903,7,5,0,0,24903,24904,7,25,0,0,24904,
	3660,1,0,0,0,24905,24906,7,5,0,0,24906,24907,7,18,0,0,24907,24908,7,5,0,
	0,24908,24909,5,95,0,0,24909,24910,7,2,0,0,24910,24911,7,15,0,0,24911,24912,
	5,95,0,0,24912,24913,7,25,0,0,24913,24914,7,15,0,0,24914,24915,7,4,0,0,
	24915,24916,7,19,0,0,24916,24917,7,0,0,0,24917,24918,7,4,0,0,24918,24919,
	7,17,0,0,24919,3662,1,0,0,0,24920,24921,7,5,0,0,24921,24922,7,18,0,0,24922,
	24923,7,5,0,0,24923,24924,5,95,0,0,24924,24925,7,2,0,0,24925,24926,7,15,
	0,0,24926,24927,5,95,0,0,24927,24928,7,25,0,0,24928,24929,7,15,0,0,24929,
	24930,7,4,0,0,24930,24931,7,19,0,0,24931,24932,7,12,0,0,24932,24933,7,9,
	0,0,24933,24934,7,25,0,0,24934,3664,1,0,0,0,24935,24936,7,5,0,0,24936,24937,
	7,18,0,0,24937,24938,7,5,0,0,24938,24939,5,95,0,0,24939,24940,7,2,0,0,24940,
	24941,7,15,0,0,24941,24942,5,95,0,0,24942,24943,7,25,0,0,24943,24944,7,
	15,0,0,24944,24945,7,4,0,0,24945,24946,7,19,0,0,24946,24947,7,2,0,0,24947,
	24948,7,15,0,0,24948,3666,1,0,0,0,24949,24950,7,5,0,0,24950,24951,7,18,
	0,0,24951,24952,7,5,0,0,24952,24953,5,95,0,0,24953,24954,7,2,0,0,24954,
	24955,7,15,0,0,24955,24956,5,95,0,0,24956,24957,7,25,0,0,24957,24958,7,
	4,0,0,24958,24959,7,25,0,0,24959,24960,7,4,0,0,24960,24961,5,50,0,0,24961,
	24962,7,5,0,0,24962,24963,7,24,0,0,24963,24964,7,11,0,0,24964,24965,7,4,
	0,0,24965,3668,1,0,0,0,24966,24967,7,5,0,0,24967,24968,7,18,0,0,24968,24969,
	7,5,0,0,24969,24970,5,95,0,0,24970,24971,7,2,0,0,24971,24972,7,15,0,0,24972,
	24973,5,95,0,0,24973,24974,7,23,0,0,24974,24975,7,2,0,0,24975,24976,7,7,
	0,0,24976,24977,7,6,0,0,24977,24978,5,95,0,0,24978,24979,7,12,0,0,24979,
	24980,7,9,0,0,24980,3670,1,0,0,0,24981,24982,7,5,0,0,24982,24983,7,18,0,
	0,24983,24984,7,5,0,0,24984,24985,5,95,0,0,24985,24986,7,2,0,0,24986,24987,
	7,3,0,0,24987,24988,7,9,0,0,24988,24989,7,6,0,0,24989,24990,7,3,0,0,24990,
	24991,7,21,0,0,24991,24992,7,6,0,0,24992,24993,7,18,0,0,24993,24994,5,95,
	0,0,24994,24995,7,9,0,0,24995,24996,7,6,0,0,24996,24997,7,15,0,0,24997,
	24998,7,4,0,0,24998,24999,7,19,0,0,24999,3672,1,0,0,0,25000,25001,7,5,0,
	0,25001,25002,7,18,0,0,25002,25003,7,5,0,0,25003,25004,5,95,0,0,25004,25005,
	7,2,0,0,25005,25006,7,3,0,0,25006,25007,7,9,0,0,25007,25008,7,6,0,0,25008,
	25009,7,3,0,0,25009,25010,7,21,0,0,25010,25011,7,6,0,0,25011,25012,7,18,
	0,0,25012,25013,5,95,0,0,25013,25014,7,14,0,0,25014,25015,7,0,0,0,25015,
	25016,7,25,0,0,25016,25017,7,8,0,0,25017,25018,7,19,0,0,25018,25019,7,12,
	0,0,25019,25020,7,11,0,0,25020,25021,7,9,0,0,25021,3674,1,0,0,0,25022,25023,
	7,5,0,0,25023,25024,7,18,0,0,25024,25025,7,5,0,0,25025,25026,5,95,0,0,25026,
	25027,7,2,0,0,25027,25028,7,3,0,0,25028,25029,7,9,0,0,25029,25030,7,6,0,
	0,25030,25031,7,3,0,0,25031,25032,7,21,0,0,25032,25033,7,6,0,0,25033,25034,
	7,18,0,0,25034,25035,5,95,0,0,25035,25036,7,15,0,0,25036,25037,7,0,0,0,
	25037,25038,7,3,0,0,25038,25039,7,6,0,0,25039,25040,7,7,0,0,25040,25041,
	7,4,0,0,25041,3676,1,0,0,0,25042,25043,7,5,0,0,25043,25044,7,18,0,0,25044,
	25045,7,5,0,0,25045,25046,5,95,0,0,25046,25047,7,15,0,0,25047,25048,7,0,
	0,0,25048,25049,7,3,0,0,25049,25050,7,0,0,0,25050,25051,7,11,0,0,25051,
	25052,7,11,0,0,25052,25053,7,6,0,0,25053,25054,7,11,0,0,25054,25055,5,95,
	0,0,25055,25056,7,4,0,0,25056,25057,7,25,0,0,25057,25058,7,7,0,0,25058,
	3678,1,0,0,0,25059,25060,7,5,0,0,25060,25061,7,18,0,0,25061,25062,7,5,0,
	0,25062,25063,5,95,0,0,25063,25064,7,15,0,0,25064,25065,7,0,0,0,25065,25066,
	7,4,0,0,25066,25067,7,19,0,0,25067,25068,7,12,0,0,25068,25069,7,9,0,0,25069,
	25070,5,95,0,0,25070,25071,7,12,0,0,25071,25072,7,5,0,0,25072,25073,5,95,
	0,0,25073,25074,7,0,0,0,25074,25075,7,4,0,0,25075,25076,7,4,0,0,25076,25077,
	7,3,0,0,25077,3680,1,0,0,0,25078,25079,7,5,0,0,25079,25080,7,18,0,0,25080,
	25081,7,5,0,0,25081,25082,5,95,0,0,25082,25083,7,15,0,0,25083,25084,7,0,
	0,0,25084,25085,7,4,0,0,25085,25086,7,19,0,0,25086,25087,7,12,0,0,25087,
	25088,7,9,0,0,25088,25089,5,95,0,0,25089,25090,7,12,0,0,25090,25091,7,5,
	0,0,25091,25092,5,95,0,0,25092,25093,7,7,0,0,25093,25094,7,14,0,0,25094,
	25095,7,5,0,0,25095,25096,7,15,0,0,25096,25097,7,8,0,0,25097,3682,1,0,0,
	0,25098,25099,7,5,0,0,25099,25100,7,18,0,0,25100,25101,7,5,0,0,25101,25102,
	5,95,0,0,25102,25103,7,15,0,0,25103,25104,7,0,0,0,25104,25105,7,4,0,0,25105,
	25106,7,19,0,0,25106,25107,7,12,0,0,25107,25108,7,9,0,0,25108,25109,5,95,
	0,0,25109,25110,7,11,0,0,25110,25111,7,0,0,0,25111,25112,7,5,0,0,25112,
	25113,7,4,0,0,25113,25114,7,7,0,0,25114,25115,7,0,0,0,25115,25116,7,14,
	0,0,25116,25117,7,6,0,0,25117,3684,1,0,0,0,25118,25119,7,5,0,0,25119,25120,
	7,18,0,0,25120,25121,7,5,0,0,25121,25122,5,95,0,0,25122,25123,7,15,0,0,
	25123,25124,7,0,0,0,25124,25125,7,4,0,0,25125,25126,7,19,0,0,25126,25127,
	7,12,0,0,25127,25128,7,9,0,0,25128,25129,5,95,0,0,25129,25130,7,11,0,0,
	25130,25131,7,0,0,0,25131,25132,7,5,0,0,25132,25133,7,4,0,0,25133,25134,
	7,7,0,0,25134,25135,7,14,0,0,25135,25136,7,5,0,0,25136,25137,7,15,0,0,25137,
	25138,7,8,0,0,25138,3686,1,0,0,0,25139,25140,7,5,0,0,25140,25141,7,18,0,
	0,25141,25142,7,5,0,0,25142,25143,5,95,0,0,25143,25144,7,15,0,0,25144,25145,
	7,0,0,0,25145,25146,7,4,0,0,25146,25147,7,19,0,0,25147,25148,5,95,0,0,25148,
	25149,7,3,0,0,25149,25150,7,6,0,0,25150,25151,7,13,0,0,25151,25152,7,6,
	0,0,25152,25153,7,3,0,0,25153,25154,7,5,0,0,25154,25155,7,6,0,0,25155,3688,
	1,0,0,0,25156,25157,7,5,0,0,25157,25158,7,18,0,0,25158,25159,7,5,0,0,25159,
	25160,5,95,0,0,25160,25161,7,15,0,0,25161,25162,7,25,0,0,25162,25163,7,
	24,0,0,25163,25164,7,6,0,0,25164,25165,7,25,0,0,25165,25166,7,4,0,0,25166,
	25167,7,3,0,0,25167,25168,7,0,0,0,25168,25169,7,8,0,0,25169,25170,7,4,0,
	0,25170,3690,1,0,0,0,25171,25172,7,5,0,0,25172,25173,7,18,0,0,25173,25174,
	7,5,0,0,25174,25175,5,95,0,0,25175,25176,7,3,0,0,25176,25177,7,0,0,0,25177,
	25178,7,22,0,0,25178,25179,5,95,0,0,25179,25180,7,4,0,0,25180,25181,7,2,
	0,0,25181,25182,5,95,0,0,25182,25183,7,25,0,0,25183,25184,7,5,0,0,25184,
	25185,7,12,0,0,25185,25186,7,9,0,0,25186,3692,1,0,0,0,25187,25188,7,5,0,
	0,25188,25189,7,18,0,0,25189,25190,7,5,0,0,25190,25191,5,95,0,0,25191,25192,
	7,3,0,0,25192,25193,7,12,0,0,25193,25194,7,9,0,0,25194,25195,5,95,0,0,25195,
	25196,7,2,0,0,25196,25197,7,3,0,0,25197,25198,7,9,0,0,25198,25199,7,6,0,
	0,25199,25200,7,3,0,0,25200,3694,1,0,0,0,25201,25202,7,5,0,0,25202,25203,
	7,18,0,0,25203,25204,7,5,0,0,25204,25205,5,95,0,0,25205,25206,7,3,0,0,25206,
	25207,7,2,0,0,25207,25208,7,22,0,0,25208,25209,5,95,0,0,25209,25210,7,9,
	0,0,25210,25211,7,6,0,0,25211,25212,7,11,0,0,25212,25213,7,4,0,0,25213,
	25214,7,0,0,0,25214,3696,1,0,0,0,25215,25216,7,5,0,0,25216,25217,7,18,0,
	0,25217,25218,7,5,0,0,25218,25219,5,95,0,0,25219,25220,7,5,0,0,25220,25221,
	7,8,0,0,25221,25222,5,95,0,0,25222,25223,5,50,0,0,25223,25224,5,95,0,0,
	25224,25225,7,25,0,0,25225,25226,7,14,0,0,25226,25227,7,11,0,0,25227,25228,
	7,4,0,0,25228,3698,1,0,0,0,25229,25230,7,5,0,0,25230,25231,7,18,0,0,25231,
	25232,7,5,0,0,25232,25233,5,95,0,0,25233,25234,7,5,0,0,25234,25235,7,18,
	0,0,25235,25236,7,7,0,0,25236,25237,7,3,0,0,25237,25238,7,8,0,0,25238,25239,
	7,12,0,0,25239,25240,7,3,0,0,25240,25241,7,6,0,0,25241,25242,7,9,0,0,25242,
	25243,7,2,0,0,25243,3700,1,0,0,0,25244,25245,7,5,0,0,25245,25246,7,18,0,
	0,25246,25247,7,5,0,0,25247,25248,7,4,0,0,25248,25249,7,6,0,0,25249,25250,
	7,14,0,0,25250,25251,5,95,0,0,25251,25252,7,9,0,0,25252,25253,7,6,0,0,25253,
	25254,7,16,0,0,25254,25255,7,12,0,0,25255,25256,7,7,0,0,25256,25257,7,6,
	0,0,25257,25258,7,9,0,0,25258,3702,1,0,0,0,25259,25260,7,5,0,0,25260,25261,
	7,18,0,0,25261,25262,7,5,0,0,25262,25263,7,4,0,0,25263,25264,7,6,0,0,25264,
	25265,7,14,0,0,25265,3704,1,0,0,0,25266,25267,7,5,0,0,25267,25268,7,18,
	0,0,25268,25269,7,5,0,0,25269,25270,7,4,0,0,25270,25271,7,12,0,0,25271,
	25272,7,14,0,0,25272,25273,7,6,0,0,25273,25274,7,5,0,0,25274,25275,7,4,
	0,0,25275,25276,7,0,0,0,25276,25277,7,14,0,0,25277,25278,7,15,0,0,25278,
	3706,1,0,0,0,25279,25280,7,5,0,0,25280,25281,7,18,0,0,25281,25282,7,5,0,
	0,25282,25283,5,95,0,0,25283,25284,7,4,0,0,25284,25285,7,18,0,0,25285,25286,
	7,15,0,0,25286,25287,7,6,0,0,25287,25288,7,12,0,0,25288,25289,7,9,0,0,25289,
	3708,1,0,0,0,25290,25291,7,5,0,0,25291,25292,7,18,0,0,25292,25293,7,5,0,
	0,25293,25294,5,95,0,0,25294,25295,7,10,0,0,25295,25296,7,14,0,0,25296,
	25297,7,0,0,0,25297,25298,7,21,0,0,25298,25299,7,6,0,0,25299,25300,7,25,
	0,0,25300,25301,7,14,0,0,25301,25302,7,11,0,0,25302,3710,1,0,0,0,25303,
	25304,7,5,0,0,25304,25305,7,18,0,0,25305,25306,7,5,0,0,25306,25307,5,95,
	0,0,25307,25308,7,25,0,0,25308,25309,7,14,0,0,25309,25310,7,11,0,0,25310,
	25311,7,0,0,0,25311,25312,7,7,0,0,25312,25313,7,0,0,0,25313,25314,7,11,
	0,0,25314,25315,7,18,0,0,25315,25316,7,23,0,0,25316,25317,7,6,0,0,25317,
	3712,1,0,0,0,25318,25319,7,5,0,0,25319,25320,7,18,0,0,25320,25321,7,5,0,
	0,25321,25322,5,95,0,0,25322,25323,7,25,0,0,25323,25324,7,14,0,0,25324,
	25325,7,11,0,0,25325,25326,7,8,0,0,25326,25327,7,2,0,0,25327,25328,7,7,
	0,0,25328,25329,7,4,0,0,25329,25330,7,0,0,0,25330,25331,7,12,0,0,25331,
	25332,7,7,0,0,25332,25333,7,5,0,0,25333,3714,1,0,0,0,25334,25335,7,5,0,
	0,25335,25336,7,18,0,0,25336,25337,7,5,0,0,25337,25338,5,95,0,0,25338,25339,
	7,25,0,0,25339,25340,7,14,0,0,25340,25341,7,11,0,0,25341,25342,7,8,0,0,
	25342,25343,7,2,0,0,25343,25344,7,7,0,0,25344,25345,7,13,0,0,25345,3716,
	1,0,0,0,25346,25347,7,5,0,0,25347,25348,7,18,0,0,25348,25349,7,5,0,0,25349,
	25350,5,95,0,0,25350,25351,7,25,0,0,25351,25352,7,14,0,0,25352,25353,7,
	11,0,0,25353,25354,7,6,0,0,25354,25355,7,25,0,0,25355,25356,7,7,0,0,25356,
	25357,7,5,0,0,25357,25358,7,10,0,0,25358,25359,7,3,0,0,25359,25360,7,12,
	0,0,25360,3718,1,0,0,0,25361,25362,7,5,0,0,25362,25363,7,18,0,0,25363,25364,
	7,5,0,0,25364,25365,5,95,0,0,25365,25366,7,25,0,0,25366,25367,7,14,0,0,
	25367,25368,7,11,0,0,25368,25369,7,17,0,0,25369,25370,7,6,0,0,25370,25371,
	7,7,0,0,25371,3720,1,0,0,0,25372,25373,7,5,0,0,25373,25374,7,18,0,0,25374,
	25375,7,5,0,0,25375,25376,5,95,0,0,25376,25377,7,25,0,0,25377,25378,7,14,
	0,0,25378,25379,7,11,0,0,25379,25380,7,12,0,0,25380,25381,5,95,0,0,25381,
	25382,7,11,0,0,25382,25383,7,2,0,0,25383,25384,7,8,0,0,25384,25385,5,95,
	0,0,25385,25386,7,12,0,0,25386,25387,7,5,0,0,25387,25388,7,7,0,0,25388,
	25389,7,2,0,0,25389,25390,7,9,0,0,25390,25391,7,6,0,0,25391,3722,1,0,0,
	0,25392,25393,7,5,0,0,25393,25394,7,18,0,0,25394,25395,7,5,0,0,25395,25396,
	5,95,0,0,25396,25397,7,25,0,0,25397,25398,7,14,0,0,25398,25399,7,11,0,0,
	25399,25400,7,12,0,0,25400,25401,5,95,0,0,25401,25402,7,11,0,0,25402,25403,
	7,2,0,0,25403,25404,7,8,0,0,25404,25405,5,95,0,0,25405,25406,7,12,0,0,25406,
	25407,7,5,0,0,25407,25408,7,4,0,0,25408,25409,7,6,0,0,25409,25410,7,25,
	0,0,25410,25411,7,4,0,0,25411,3724,1,0,0,0,25412,25413,7,5,0,0,25413,25414,
	7,18,0,0,25414,25415,7,5,0,0,25415,25416,5,95,0,0,25416,25417,7,25,0,0,
	25417,25418,7,14,0,0,25418,25419,7,11,0,0,25419,25420,7,12,0,0,25420,25421,
	7,7,0,0,25421,25422,7,5,0,0,25422,25423,7,4,0,0,25423,25424,7,3,0,0,25424,
	3726,1,0,0,0,25425,25426,7,5,0,0,25426,25427,7,18,0,0,25427,25428,7,5,0,
	0,25428,25429,5,95,0,0,25429,25430,7,25,0,0,25430,25431,7,14,0,0,25431,
	25432,7,11,0,0,25432,25433,7,11,0,0,25433,25434,7,2,0,0,25434,25435,7,8,
	0,0,25435,25436,7,0,0,0,25436,25437,7,4,0,0,25437,25438,7,2,0,0,25438,25439,
	7,3,0,0,25439,25440,5,95,0,0,25440,25441,7,17,0,0,25441,25442,7,6,0,0,25442,
	25443,7,4,0,0,25443,25444,7,5,0,0,25444,25445,7,13,0,0,25445,25446,7,0,
	0,0,25446,25447,7,11,0,0,25447,3728,1,0,0,0,25448,25449,7,5,0,0,25449,25450,
	7,18,0,0,25450,25451,7,5,0,0,25451,25452,5,95,0,0,25452,25453,7,25,0,0,
	25453,25454,7,14,0,0,25454,25455,7,11,0,0,25455,25456,7,7,0,0,25456,25457,
	7,2,0,0,25457,25458,7,9,0,0,25458,25459,7,6,0,0,25459,25460,7,12,0,0,25460,
	25461,7,9,0,0,25461,25462,5,95,0,0,25462,25463,7,17,0,0,25463,25464,7,6,
	0,0,25464,25465,7,4,0,0,25465,25466,7,8,0,0,25466,25467,7,12,0,0,25467,
	25468,7,9,0,0,25468,3730,1,0,0,0,25469,25470,7,5,0,0,25470,25471,7,18,0,
	0,25471,25472,7,5,0,0,25472,25473,5,95,0,0,25473,25474,7,25,0,0,25474,25475,
	7,14,0,0,25475,25476,7,11,0,0,25476,25477,7,7,0,0,25477,25478,7,2,0,0,25478,
	25479,7,9,0,0,25479,25480,7,6,0,0,25480,25481,7,12,0,0,25481,25482,7,9,
	0,0,25482,25483,5,95,0,0,25483,25484,7,17,0,0,25484,25485,7,6,0,0,25485,
	25486,7,4,0,0,25486,25487,7,11,0,0,25487,25488,7,2,0,0,25488,25489,7,8,
	0,0,25489,25490,7,0,0,0,25490,25491,7,4,0,0,25491,25492,7,2,0,0,25492,25493,
	7,3,0,0,25493,3732,1,0,0,0,25494,25495,7,5,0,0,25495,25496,7,18,0,0,25496,
	25497,7,5,0,0,25497,25498,5,95,0,0,25498,25499,7,25,0,0,25499,25500,7,14,
	0,0,25500,25501,7,11,0,0,25501,25502,7,7,0,0,25502,25503,7,2,0,0,25503,
	25504,7,9,0,0,25504,25505,7,6,0,0,25505,25506,7,12,0,0,25506,25507,7,9,
	0,0,25507,25508,5,95,0,0,25508,25509,7,17,0,0,25509,25510,7,6,0,0,25510,
	25511,7,4,0,0,25511,25512,7,2,0,0,25512,25513,7,21,0,0,25513,25514,7,6,
	0,0,25514,25515,7,18,0,0,25515,3734,1,0,0,0,25516,25517,7,5,0,0,25517,25518,
	7,18,0,0,25518,25519,7,5,0,0,25519,25520,5,95,0,0,25520,25521,7,25,0,0,
	25521,25522,7,14,0,0,25522,25523,7,11,0,0,25523,25524,7,7,0,0,25524,25525,
	7,2,0,0,25525,25526,7,9,0,0,25526,25527,7,6,0,0,25527,25528,7,12,0,0,25528,
	25529,7,9,0,0,25529,25530,5,95,0,0,25530,25531,7,17,0,0,25531,25532,7,6,
	0,0,25532,25533,7,4,0,0,25533,25534,7,15,0,0,25534,25535,7,0,0,0,25535,
	25536,7,4,0,0,25536,25537,7,19,0,0,25537,25538,7,12,0,0,25538,25539,7,9,
	0,0,25539,3736,1,0,0,0,25540,25541,7,5,0,0,25541,25542,7,18,0,0,25542,25543,
	7,5,0,0,25543,25544,5,95,0,0,25544,25545,7,25,0,0,25545,25546,7,14,0,0,
	25546,25547,7,11,0,0,25547,25548,7,7,0,0,25548,25549,7,2,0,0,25549,25550,
	7,9,0,0,25550,25551,7,6,0,0,25551,25552,7,12,0,0,25552,25553,7,9,0,0,25553,
	25554,5,95,0,0,25554,25555,7,17,0,0,25555,25556,7,6,0,0,25556,25557,7,4,
	0,0,25557,25558,7,15,0,0,25558,25559,7,4,0,0,25559,25560,7,3,0,0,25560,
	25561,7,12,0,0,25561,25562,7,9,0,0,25562,3738,1,0,0,0,25563,25564,7,5,0,
	0,25564,25565,7,18,0,0,25565,25566,7,5,0,0,25566,25567,5,95,0,0,25567,25568,
	7,25,0,0,25568,25569,7,14,0,0,25569,25570,7,11,0,0,25570,25571,7,7,0,0,
	25571,25572,7,2,0,0,25572,25573,7,9,0,0,25573,25574,7,6,0,0,25574,25575,
	7,12,0,0,25575,25576,7,9,0,0,25576,25577,5,95,0,0,25577,25578,7,17,0,0,
	25578,25579,7,6,0,0,25579,25580,7,4,0,0,25580,25581,7,3,0,0,25581,25582,
	7,12,0,0,25582,25583,7,9,0,0,25583,3740,1,0,0,0,25584,25585,7,5,0,0,25585,
	25586,7,18,0,0,25586,25587,7,5,0,0,25587,25588,5,95,0,0,25588,25589,7,25,
	0,0,25589,25590,7,14,0,0,25590,25591,7,11,0,0,25591,25592,7,7,0,0,25592,
	25593,7,2,0,0,25593,25594,7,9,0,0,25594,25595,7,6,0,0,25595,25596,7,12,
	0,0,25596,25597,7,9,0,0,25597,25598,5,95,0,0,25598,25599,7,17,0,0,25599,
	25600,7,6,0,0,25600,25601,7,4,0,0,25601,25602,7,5,0,0,25602,25603,7,13,
	0,0,25603,25604,7,0,0,0,25604,25605,7,11,0,0,25605,3742,1,0,0,0,25606,25607,
	7,5,0,0,25607,25608,7,18,0,0,25608,25609,7,5,0,0,25609,25610,5,95,0,0,25610,
	25611,7,25,0,0,25611,25612,7,14,0,0,25612,25613,7,11,0,0,25613,25614,7,
	7,0,0,25614,25615,7,2,0,0,25615,25616,7,9,0,0,25616,25617,7,6,0,0,25617,
	25618,7,12,0,0,25618,25619,7,9,0,0,25619,25620,5,95,0,0,25620,25621,7,17,
	0,0,25621,25622,7,6,0,0,25622,25623,7,4,0,0,25623,25624,7,4,0,0,25624,25625,
	7,12,0,0,25625,25626,7,9,0,0,25626,3744,1,0,0,0,25627,25628,7,5,0,0,25628,
	25629,7,18,0,0,25629,25630,7,5,0,0,25630,25631,5,95,0,0,25631,25632,7,25,
	0,0,25632,25633,7,14,0,0,25633,25634,7,11,0,0,25634,25635,7,7,0,0,25635,
	25636,7,2,0,0,25636,25637,7,9,0,0,25637,25638,7,6,0,0,25638,25639,7,12,
	0,0,25639,25640,7,9,0,0,25640,3746,1,0,0,0,25641,25642,7,5,0,0,25642,25643,
	7,18,0,0,25643,25644,7,5,0,0,25644,25645,5,95,0,0,25645,25646,7,25,0,0,
	25646,25647,7,14,0,0,25647,25648,7,11,0,0,25648,25649,7,4,0,0,25649,25650,
	5,95,0,0,25650,25651,5,50,0,0,25651,25652,5,95,0,0,25652,25653,7,5,0,0,
	25653,25654,7,8,0,0,25654,3748,1,0,0,0,25655,25656,7,5,0,0,25656,25657,
	7,18,0,0,25657,25658,7,5,0,0,25658,25659,5,95,0,0,25659,25660,7,25,0,0,
	25660,25661,7,14,0,0,25661,25662,7,11,0,0,25662,25663,7,4,0,0,25663,25664,
	7,3,0,0,25664,25665,7,0,0,0,25665,25666,7,7,0,0,25666,25667,7,5,0,0,25667,
	25668,7,11,0,0,25668,25669,7,0,0,0,25669,25670,7,4,0,0,25670,25671,7,6,
	0,0,25671,3750,1,0,0,0,25672,25673,7,5,0,0,25673,25674,7,18,0,0,25674,25675,
	7,5,0,0,25675,25676,5,95,0,0,25676,25677,7,25,0,0,25677,25678,7,14,0,0,
	25678,25679,7,11,0,0,25679,25680,7,4,0,0,25680,25681,7,18,0,0,25681,25682,
	7,15,0,0,25682,25683,7,6,0,0,25683,25684,5,50,0,0,25684,25685,7,5,0,0,25685,
	25686,7,24,0,0,25686,25687,7,11,0,0,25687,3752,1,0,0,0,25688,25689,7,5,
	0,0,25689,25690,7,18,0,0,25690,25691,7,5,0,0,25691,25692,5,95,0,0,25692,
	25693,7,25,0,0,25693,25694,7,24,0,0,25694,25695,5,95,0,0,25695,25696,7,
	0,0,0,25696,25697,7,5,0,0,25697,25698,7,24,0,0,25698,25699,7,11,0,0,25699,
	25700,7,8,0,0,25700,25701,7,7,0,0,25701,25702,7,13,0,0,25702,3754,1,0,0,
	0,25703,25704,7,5,0,0,25704,25705,7,18,0,0,25705,25706,7,5,0,0,25706,25707,
	5,95,0,0,25707,25708,7,25,0,0,25708,25709,7,24,0,0,25709,25710,5,95,0,0,
	25710,25711,7,0,0,0,25711,25712,7,4,0,0,25712,25713,7,2,0,0,25713,25714,
	7,14,0,0,25714,25715,7,8,0,0,25715,25716,7,7,0,0,25716,25717,7,13,0,0,25717,
	25718,7,8,0,0,25718,25719,7,19,0,0,25719,25720,7,21,0,0,25720,3756,1,0,
	0,0,25721,25722,7,5,0,0,25722,25723,7,18,0,0,25723,25724,7,5,0,0,25724,
	25725,5,95,0,0,25725,25726,7,25,0,0,25726,25727,7,24,0,0,25727,25728,7,
	1,0,0,25728,25729,7,0,0,0,25729,25730,7,5,0,0,25730,25731,7,6,0,0,25731,
	25732,7,10,0,0,25732,25733,7,3,0,0,25733,25734,7,12,0,0,25734,3758,1,0,
	0,0,25735,25736,7,5,0,0,25736,25737,7,18,0,0,25737,25738,7,5,0,0,25738,
	25739,5,95,0,0,25739,25740,7,25,0,0,25740,25741,7,24,0,0,25741,25742,7,
	8,0,0,25742,25743,7,0,0,0,25743,25744,7,5,0,0,25744,25745,7,4,0,0,25745,
	25746,7,0,0,0,25746,25747,7,1,0,0,25747,25748,7,11,0,0,25748,25749,7,6,
	0,0,25749,25750,7,6,0,0,25750,25751,7,3,0,0,25751,25752,7,3,0,0,25752,25753,
	7,19,0,0,25753,3760,1,0,0,0,25754,25755,7,5,0,0,25755,25756,7,18,0,0,25756,
	25757,7,5,0,0,25757,25758,5,95,0,0,25758,25759,7,25,0,0,25759,25760,7,24,
	0,0,25760,25761,7,8,0,0,25761,25762,7,2,0,0,25762,25763,7,9,0,0,25763,25764,
	7,6,0,0,25764,25765,7,15,0,0,25765,25766,5,50,0,0,25766,25767,7,5,0,0,25767,
	25768,7,4,0,0,25768,25769,7,3,0,0,25769,3762,1,0,0,0,25770,25771,7,5,0,
	0,25771,25772,7,18,0,0,25772,25773,7,5,0,0,25773,25774,5,95,0,0,25774,25775,
	7,25,0,0,25775,25776,7,24,0,0,25776,25777,7,8,0,0,25777,25778,7,2,0,0,25778,
	25779,7,9,0,0,25779,25780,7,6,0,0,25780,25781,7,15,0,0,25781,25782,7,6,
	0,0,25782,25783,7,24,0,0,25783,3764,1,0,0,0,25784,25785,7,5,0,0,25785,25786,
	7,18,0,0,25786,25787,7,5,0,0,25787,25788,5,95,0,0,25788,25789,7,25,0,0,
	25789,25790,7,24,0,0,25790,25791,7,8,0,0,25791,25792,7,2,0,0,25792,25793,
	7,7,0,0,25793,25794,5,50,0,0,25794,25795,7,5,0,0,25795,25796,7,6,0,0,25796,
	25797,7,24,0,0,25797,3766,1,0,0,0,25798,25799,7,5,0,0,25799,25800,7,18,
	0,0,25800,25801,7,5,0,0,25801,25802,5,95,0,0,25802,25803,7,25,0,0,25803,
	25804,7,24,0,0,25804,25805,7,8,0,0,25805,25806,7,2,0,0,25806,25807,7,7,
	0,0,25807,25808,7,8,0,0,25808,25809,7,0,0,0,25809,25810,7,4,0,0,25810,3768,
	1,0,0,0,25811,25812,7,5,0,0,25812,25813,7,18,0,0,25813,25814,7,5,0,0,25814,
	25815,5,95,0,0,25815,25816,7,25,0,0,25816,25817,7,24,0,0,25817,25818,7,
	9,0,0,25818,25819,7,6,0,0,25819,25820,7,11,0,0,25820,25821,7,6,0,0,25821,
	25822,7,4,0,0,25822,25823,7,6,0,0,25823,3770,1,0,0,0,25824,25825,7,5,0,
	0,25825,25826,7,18,0,0,25826,25827,7,5,0,0,25827,25828,5,95,0,0,25828,25829,
	7,25,0,0,25829,25830,7,24,0,0,25830,25831,7,9,0,0,25831,25832,7,16,0,0,
	25832,25833,7,11,0,0,25833,25834,7,4,0,0,25834,25835,7,8,0,0,25835,25836,
	7,2,0,0,25836,25837,7,11,0,0,25837,25838,7,0,0,0,25838,25839,7,4,0,0,25839,
	25840,7,12,0,0,25840,25841,7,2,0,0,25841,25842,7,7,0,0,25842,3772,1,0,0,
	0,25843,25844,7,5,0,0,25844,25845,7,18,0,0,25845,25846,7,5,0,0,25846,25847,
	5,95,0,0,25847,25848,7,25,0,0,25848,25849,7,24,0,0,25849,25850,7,9,0,0,
	25850,25851,7,2,0,0,25851,25852,7,8,0,0,25852,3774,1,0,0,0,25853,25854,
	7,5,0,0,25854,25855,7,18,0,0,25855,25856,7,5,0,0,25856,25857,5,95,0,0,25857,
	25858,7,25,0,0,25858,25859,7,24,0,0,25859,25860,7,9,0,0,25860,25861,7,2,
	0,0,25861,25862,7,8,0,0,25862,25863,7,10,0,0,25863,25864,7,3,0,0,25864,
	25865,7,12,0,0,25865,3776,1,0,0,0,25866,25867,7,5,0,0,25867,25868,7,18,
	0,0,25868,25869,7,5,0,0,25869,25870,5,95,0,0,25870,25871,7,25,0,0,25871,
	25872,7,24,0,0,25872,25873,7,9,0,0,25873,25874,7,10,0,0,25874,25875,7,3,
	0,0,25875,25876,7,9,0,0,25876,25877,7,12,0,0,25877,25878,7,13,0,0,25878,
	3778,1,0,0,0,25879,25880,7,5,0,0,25880,25881,7,18,0,0,25881,25882,7,5,0,
	0,25882,25883,5,95,0,0,25883,25884,7,25,0,0,25884,25885,7,24,0,0,25885,
	25886,7,6,0,0,25886,25887,7,9,0,0,25887,25888,5,52,0,0,25888,25889,7,10,
	0,0,25889,25890,7,3,0,0,25890,25891,7,12,0,0,25891,3780,1,0,0,0,25892,25893,
	7,5,0,0,25893,25894,7,18,0,0,25894,25895,7,5,0,0,25895,25896,5,95,0,0,25896,
	25897,7,25,0,0,25897,25898,7,24,0,0,25898,25899,7,6,0,0,25899,25900,7,7,
	0,0,25900,25901,7,9,0,0,25901,25902,7,5,0,0,25902,25903,7,22,0,0,25903,
	25904,7,12,0,0,25904,25905,7,4,0,0,25905,25906,7,19,0,0,25906,3782,1,0,
	0,0,25907,25908,7,5,0,0,25908,25909,7,18,0,0,25909,25910,7,5,0,0,25910,
	25911,5,95,0,0,25911,25912,7,25,0,0,25912,25913,7,24,0,0,25913,25914,7,
	6,0,0,25914,25915,7,3,0,0,25915,25916,7,3,0,0,25916,25917,7,19,0,0,25917,
	3784,1,0,0,0,25918,25919,7,5,0,0,25919,25920,7,18,0,0,25920,25921,7,5,0,
	0,25921,25922,5,95,0,0,25922,25923,7,25,0,0,25923,25924,7,24,0,0,25924,
	25925,7,6,0,0,25925,25926,7,3,0,0,25926,25927,7,3,0,0,25927,3786,1,0,0,
	0,25928,25929,7,5,0,0,25929,25930,7,18,0,0,25930,25931,7,5,0,0,25931,25932,
	5,95,0,0,25932,25933,7,25,0,0,25933,25934,7,24,0,0,25934,25935,7,6,0,0,
	25935,25936,7,5,0,0,25936,25937,7,19,0,0,25937,25938,7,4,0,0,25938,25939,
	7,14,0,0,25939,25940,7,11,0,0,25940,25941,7,10,0,0,25941,25942,7,3,0,0,
	25942,25943,7,12,0,0,25943,3788,1,0,0,0,25944,25945,7,5,0,0,25945,25946,
	7,18,0,0,25946,25947,7,5,0,0,25947,25948,5,95,0,0,25948,25949,7,25,0,0,
	25949,25950,7,24,0,0,25950,25951,7,6,0,0,25951,25952,7,25,0,0,25952,25953,
	7,11,0,0,25953,25954,7,2,0,0,25954,25955,7,1,0,0,25955,25956,7,13,0,0,25956,
	25957,7,0,0,0,25957,25958,7,11,0,0,25958,3790,1,0,0,0,25959,25960,7,5,0,
	0,25960,25961,7,18,0,0,25961,25962,7,5,0,0,25962,25963,5,95,0,0,25963,25964,
	7,25,0,0,25964,25965,7,24,0,0,25965,25966,7,6,0,0,25966,25967,7,25,0,0,
	25967,25968,7,5,0,0,25968,25969,7,4,0,0,25969,25970,7,22,0,0,25970,25971,
	7,3,0,0,25971,25972,7,15,0,0,25972,3792,1,0,0,0,25973,25974,7,5,0,0,25974,
	25975,7,18,0,0,25975,25976,7,5,0,0,25976,25977,5,95,0,0,25977,25978,7,25,
	0,0,25978,25979,7,24,0,0,25979,25980,7,6,0,0,25980,25981,7,25,0,0,25981,
	25982,7,4,0,0,25982,25983,7,3,0,0,25983,25984,7,0,0,0,25984,25985,7,8,0,
	0,25985,25986,7,4,0,0,25986,3794,1,0,0,0,25987,25988,7,5,0,0,25988,25989,
	7,18,0,0,25989,25990,7,5,0,0,25990,25991,5,95,0,0,25991,25992,7,25,0,0,
	25992,25993,7,24,0,0,25993,25994,7,6,0,0,25994,25995,7,25,0,0,25995,25996,
	7,4,0,0,25996,25997,7,3,0,0,25997,25998,7,3,0,0,25998,25999,7,6,0,0,25999,
	26000,7,16,0,0,26000,3796,1,0,0,0,26001,26002,7,5,0,0,26002,26003,7,18,
	0,0,26003,26004,7,5,0,0,26004,26005,5,95,0,0,26005,26006,7,25,0,0,26006,
	26007,7,24,0,0,26007,26008,7,6,0,0,26008,26009,7,25,0,0,26009,26010,7,13,
	0,0,26010,26011,7,0,0,0,26011,26012,7,11,0,0,26012,3798,1,0,0,0,26013,26014,
	7,5,0,0,26014,26015,7,18,0,0,26015,26016,7,5,0,0,26016,26017,5,95,0,0,26017,
	26018,7,25,0,0,26018,26019,7,24,0,0,26019,26020,7,16,0,0,26020,26021,7,
	1,0,0,26021,26022,5,50,0,0,26022,26023,7,5,0,0,26023,26024,7,4,0,0,26024,
	26025,7,3,0,0,26025,3800,1,0,0,0,26026,26027,7,5,0,0,26027,26028,7,18,0,
	0,26028,26029,7,5,0,0,26029,26030,5,95,0,0,26030,26031,7,25,0,0,26031,26032,
	7,24,0,0,26032,26033,7,16,0,0,26033,26034,7,7,0,0,26034,26035,7,1,0,0,26035,
	26036,7,2,0,0,26036,26037,7,2,0,0,26037,26038,7,11,0,0,26038,3802,1,0,0,
	0,26039,26040,7,5,0,0,26040,26041,7,18,0,0,26041,26042,7,5,0,0,26042,26043,
	5,95,0,0,26043,26044,7,25,0,0,26044,26045,7,24,0,0,26045,26046,7,16,0,0,
	26046,26047,7,7,0,0,26047,26048,7,8,0,0,26048,26049,7,14,0,0,26049,26050,
	7,15,0,0,26050,3804,1,0,0,0,26051,26052,7,5,0,0,26052,26053,7,18,0,0,26053,
	26054,7,5,0,0,26054,26055,5,95,0,0,26055,26056,7,25,0,0,26056,26057,7,24,
	0,0,26057,26058,7,16,0,0,26058,26059,7,7,0,0,26059,26060,7,9,0,0,26060,
	26061,7,0,0,0,26061,26062,7,4,0,0,26062,26063,7,12,0,0,26063,26064,7,14,
	0,0,26064,3806,1,0,0,0,26065,26066,7,5,0,0,26066,26067,7,18,0,0,26067,26068,
	7,5,0,0,26068,26069,5,95,0,0,26069,26070,7,25,0,0,26070,26071,7,24,0,0,
	26071,26072,7,16,0,0,26072,26073,7,7,0,0,26073,26074,7,11,0,0,26074,26075,
	7,7,0,0,26075,26076,7,0,0,0,26076,26077,7,14,0,0,26077,26078,7,6,0,0,26078,
	3808,1,0,0,0,26079,26080,7,5,0,0,26080,26081,7,18,0,0,26081,26082,7,5,0,
	0,26082,26083,5,95,0,0,26083,26084,7,25,0,0,26084,26085,7,24,0,0,26085,
	26086,7,16,0,0,26086,26087,7,7,0,0,26087,26088,7,7,0,0,26088,26089,7,14,
	0,0,26089,3810,1,0,0,0,26090,26091,7,5,0,0,26091,26092,7,18,0,0,26092,26093,
	7,5,0,0,26093,26094,5,95,0,0,26094,26095,7,25,0,0,26095,26096,7,24,0,0,
	26096,26097,7,16,0,0,26097,26098,7,7,0,0,26098,26099,7,7,0,0,26099,26100,
	7,5,0,0,26100,26101,7,10,0,0,26101,26102,7,3,0,0,26102,26103,7,12,0,0,26103,
	3812,1,0,0,0,26104,26105,7,5,0,0,26105,26106,7,18,0,0,26106,26107,7,5,0,
	0,26107,26108,5,95,0,0,26108,26109,7,25,0,0,26109,26110,7,24,0,0,26110,
	26111,7,16,0,0,26111,26112,7,7,0,0,26112,26113,7,15,0,0,26113,26114,7,3,
	0,0,26114,26115,7,6,0,0,26115,26116,7,9,0,0,26116,26117,7,4,0,0,26117,26118,
	7,3,0,0,26118,26119,7,10,0,0,26119,26120,7,4,0,0,26120,26121,7,19,0,0,26121,
	3814,1,0,0,0,26122,26123,7,5,0,0,26123,26124,7,18,0,0,26124,26125,7,5,0,
	0,26125,26126,5,95,0,0,26126,26127,7,25,0,0,26127,26128,7,24,0,0,26128,
	26129,7,16,0,0,26129,26130,7,7,0,0,26130,26131,7,24,0,0,26131,26132,7,7,
	0,0,26132,26133,7,14,0,0,26133,3816,1,0,0,0,26134,26135,7,5,0,0,26135,26136,
	7,18,0,0,26136,26137,7,5,0,0,26137,26138,5,95,0,0,26138,26139,7,25,0,0,
	26139,26140,7,24,0,0,26140,26141,7,16,0,0,26141,26142,7,7,0,0,26142,26143,
	7,3,0,0,26143,26144,7,2,0,0,26144,26145,7,2,0,0,26145,26146,7,4,0,0,26146,
	3818,1,0,0,0,26147,26148,7,5,0,0,26148,26149,7,18,0,0,26149,26150,7,5,0,
	0,26150,26151,5,95,0,0,26151,26152,7,25,0,0,26152,26153,7,24,0,0,26153,
	26154,7,16,0,0,26154,26155,7,2,0,0,26155,26156,7,3,0,0,26156,26157,7,14,
	0,0,26157,26158,7,0,0,0,26158,26159,7,4,0,0,26159,26160,7,7,0,0,26160,26161,
	7,10,0,0,26161,26162,7,14,0,0,26162,3820,1,0,0,0,26163,26164,7,5,0,0,26164,
	26165,7,18,0,0,26165,26166,7,5,0,0,26166,26167,5,95,0,0,26167,26168,7,25,
	0,0,26168,26169,7,24,0,0,26169,26170,7,16,0,0,26170,26171,7,4,0,0,26171,
	26172,7,8,0,0,26172,26173,7,2,0,0,26173,26174,7,7,0,0,26174,26175,7,4,0,
	0,26175,26176,7,0,0,0,26176,26177,7,12,0,0,26177,26178,7,7,0,0,26178,3822,
	1,0,0,0,26179,26180,7,5,0,0,26180,26181,7,18,0,0,26181,26182,7,5,0,0,26182,
	26183,5,95,0,0,26183,26184,7,25,0,0,26184,26185,7,24,0,0,26185,26186,7,
	16,0,0,26186,26187,7,10,0,0,26187,26188,7,7,0,0,26188,26189,7,8,0,0,26189,
	26190,7,3,0,0,26190,3824,1,0,0,0,26191,26192,7,5,0,0,26192,26193,7,18,0,
	0,26193,26194,7,5,0,0,26194,26195,5,95,0,0,26195,26196,7,25,0,0,26196,26197,
	7,24,0,0,26197,26198,7,17,0,0,26198,26199,7,6,0,0,26199,26200,7,4,0,0,26200,
	26201,7,8,0,0,26201,26202,7,2,0,0,26202,26203,7,7,0,0,26203,26204,7,4,0,
	0,26204,26205,7,6,0,0,26205,26206,7,7,0,0,26206,26207,7,4,0,0,26207,3826,
	1,0,0,0,26208,26209,7,5,0,0,26209,26210,7,18,0,0,26210,26211,7,5,0,0,26211,
	26212,5,95,0,0,26212,26213,7,25,0,0,26213,26214,7,24,0,0,26214,26215,7,
	12,0,0,26215,26216,7,7,0,0,26216,26217,7,9,0,0,26217,26218,7,25,0,0,26218,
	26219,7,2,0,0,26219,26220,7,16,0,0,26220,3828,1,0,0,0,26221,26222,7,5,0,
	0,26222,26223,7,18,0,0,26223,26224,7,5,0,0,26224,26225,5,95,0,0,26225,26226,
	7,25,0,0,26226,26227,7,24,0,0,26227,26228,7,12,0,0,26228,26229,7,7,0,0,
	26229,26230,7,5,0,0,26230,26231,7,6,0,0,26231,26232,7,3,0,0,26232,26233,
	7,4,0,0,26233,3830,1,0,0,0,26234,26235,7,5,0,0,26235,26236,7,18,0,0,26236,
	26237,7,5,0,0,26237,26238,5,95,0,0,26238,26239,7,25,0,0,26239,26240,7,24,
	0,0,26240,26241,7,12,0,0,26241,26242,7,7,0,0,26242,26243,7,5,0,0,26243,
	26244,7,15,0,0,26244,26245,7,16,0,0,26245,26246,7,25,0,0,26246,3832,1,0,
	0,0,26247,26248,7,5,0,0,26248,26249,7,18,0,0,26249,26250,7,5,0,0,26250,
	26251,5,95,0,0,26251,26252,7,25,0,0,26252,26253,7,24,0,0,26253,26254,7,
	12,0,0,26254,26255,7,3,0,0,26255,26256,7,12,0,0,26256,26257,5,50,0,0,26257,
	26258,7,10,0,0,26258,26259,7,3,0,0,26259,26260,7,12,0,0,26260,3834,1,0,
	0,0,26261,26262,7,5,0,0,26262,26263,7,18,0,0,26263,26264,7,5,0,0,26264,
	26265,5,95,0,0,26265,26266,7,25,0,0,26266,26267,7,24,0,0,26267,26268,7,
	11,0,0,26268,26269,7,0,0,0,26269,26270,7,7,0,0,26270,26271,7,17,0,0,26271,
	3836,1,0,0,0,26272,26273,7,5,0,0,26273,26274,7,18,0,0,26274,26275,7,5,0,
	0,26275,26276,5,95,0,0,26276,26277,7,25,0,0,26277,26278,7,24,0,0,26278,
	26279,7,11,0,0,26279,26280,7,11,0,0,26280,26281,7,7,0,0,26281,26282,7,14,
	0,0,26282,26283,7,16,0,0,26283,26284,7,3,0,0,26284,26285,7,14,0,0,26285,
	26286,7,24,0,0,26286,26287,7,7,0,0,26287,26288,7,14,0,0,26288,3838,1,0,
	0,0,26289,26290,7,5,0,0,26290,26291,7,18,0,0,26291,26292,7,5,0,0,26292,
	26293,5,95,0,0,26293,26294,7,25,0,0,26294,26295,7,24,0,0,26295,26296,7,
	14,0,0,26296,26297,7,21,0,0,26297,26298,7,7,0,0,26298,26299,7,2,0,0,26299,
	26300,7,9,0,0,26300,26301,7,6,0,0,26301,26302,7,3,0,0,26302,26303,7,6,0,
	0,26303,26304,7,16,0,0,26304,3840,1,0,0,0,26305,26306,7,5,0,0,26306,26307,
	7,18,0,0,26307,26308,7,5,0,0,26308,26309,5,95,0,0,26309,26310,7,25,0,0,
	26310,26311,7,24,0,0,26311,26312,7,7,0,0,26312,26313,7,12,0,0,26313,26314,
	7,11,0,0,26314,26315,7,11,0,0,26315,26316,7,6,0,0,26316,26317,7,9,0,0,26317,
	3842,1,0,0,0,26318,26319,7,5,0,0,26319,26320,7,18,0,0,26320,26321,7,5,0,
	0,26321,26322,5,95,0,0,26322,26323,7,25,0,0,26323,26324,7,24,0,0,26324,
	26325,7,7,0,0,26325,26326,7,2,0,0,26326,26327,7,9,0,0,26327,26328,7,6,0,
	0,26328,26329,7,7,0,0,26329,26330,7,0,0,0,26330,26331,7,14,0,0,26331,26332,
	7,6,0,0,26332,3844,1,0,0,0,26333,26334,7,5,0,0,26334,26335,7,18,0,0,26335,
	26336,7,5,0,0,26336,26337,5,95,0,0,26337,26338,7,25,0,0,26338,26339,7,24,
	0,0,26339,26340,7,7,0,0,26340,26341,7,2,0,0,26341,26342,7,3,0,0,26342,26343,
	7,14,0,0,26343,26344,7,5,0,0,26344,26345,7,15,0,0,26345,26346,7,0,0,0,26346,
	26347,7,8,0,0,26347,26348,7,6,0,0,26348,3846,1,0,0,0,26349,26350,7,5,0,
	0,26350,26351,7,18,0,0,26351,26352,7,5,0,0,26352,26353,5,95,0,0,26353,26354,
	7,25,0,0,26354,26355,7,24,0,0,26355,26356,7,7,0,0,26356,26357,7,2,0,0,26357,
	26358,7,3,0,0,26358,26359,7,14,0,0,26359,26360,7,10,0,0,26360,26361,7,8,
	0,0,26361,26362,7,2,0,0,26362,26363,7,9,0,0,26363,26364,7,6,0,0,26364,3848,
	1,0,0,0,26365,26366,7,5,0,0,26366,26367,7,18,0,0,26367,26368,7,5,0,0,26368,
	26369,5,95,0,0,26369,26370,7,25,0,0,26370,26371,7,24,0,0,26371,26372,5,
	95,0,0,26372,26373,7,7,0,0,26373,26374,7,3,0,0,26374,26375,7,7,0,0,26375,
	26376,7,17,0,0,26376,3850,1,0,0,0,26377,26378,7,5,0,0,26378,26379,7,18,
	0,0,26379,26380,7,5,0,0,26380,26381,5,95,0,0,26381,26382,7,25,0,0,26382,
	26383,7,24,0,0,26383,26384,7,7,0,0,26384,26385,7,5,0,0,26385,26386,7,15,
	0,0,26386,26387,5,52,0,0,26387,26388,7,15,0,0,26388,26389,7,16,0,0,26389,
	26390,7,25,0,0,26390,3852,1,0,0,0,26391,26392,7,5,0,0,26392,26393,7,18,
	0,0,26393,26394,7,5,0,0,26394,26395,5,95,0,0,26395,26396,7,25,0,0,26396,
	26397,7,24,0,0,26397,26398,7,7,0,0,26398,26399,7,5,0,0,26399,26400,7,15,
	0,0,26400,26401,7,16,0,0,26401,26402,7,3,0,0,26402,26403,7,14,0,0,26403,
	26404,7,24,0,0,26404,26405,7,7,0,0,26405,26406,7,14,0,0,26406,3854,1,0,
	0,0,26407,26408,7,5,0,0,26408,26409,7,18,0,0,26409,26410,7,5,0,0,26410,
	26411,5,95,0,0,26411,26412,7,25,0,0,26412,26413,7,24,0,0,26413,26414,7,
	15,0,0,26414,26415,7,16,0,0,26415,26416,7,25,0,0,26416,26417,7,16,0,0,26417,
	26418,7,3,0,0,26418,26419,7,14,0,0,26419,26420,7,24,0,0,26420,26421,7,7,
	0,0,26421,26422,7,14,0,0,26422,3856,1,0,0,0,26423,26424,7,5,0,0,26424,26425,
	7,18,0,0,26425,26426,7,5,0,0,26426,26427,5,95,0,0,26427,26428,7,25,0,0,
	26428,26429,7,24,0,0,26429,26430,5,95,0,0,26430,26431,7,15,0,0,26431,26432,
	7,21,0,0,26432,26433,7,5,0,0,26433,26434,7,24,0,0,26434,26435,7,11,0,0,
	26435,26436,5,50,0,0,26436,26437,7,25,0,0,26437,26438,7,14,0,0,26438,26439,
	7,11,0,0,26439,3858,1,0,0,0,26440,26441,7,5,0,0,26441,26442,7,18,0,0,26442,
	26443,7,5,0,0,26443,26444,5,95,0,0,26444,26445,7,25,0,0,26445,26446,7,24,
	0,0,26446,26447,7,15,0,0,26447,26448,7,2,0,0,26448,26449,7,11,0,0,26449,
	26450,7,18,0,0,26450,26451,7,0,0,0,26451,26452,7,1,0,0,26452,26453,7,5,
	0,0,26453,3860,1,0,0,0,26454,26455,7,5,0,0,26455,26456,7,18,0,0,26456,26457,
	7,5,0,0,26457,26458,5,95,0,0,26458,26459,7,25,0,0,26459,26460,7,24,0,0,
	26460,26461,7,15,0,0,26461,26462,7,2,0,0,26462,26463,7,11,0,0,26463,26464,
	7,18,0,0,26464,26465,7,0,0,0,26465,26466,7,9,0,0,26466,26467,7,9,0,0,26467,
	3862,1,0,0,0,26468,26469,7,5,0,0,26469,26470,7,18,0,0,26470,26471,7,5,0,
	0,26471,26472,5,95,0,0,26472,26473,7,25,0,0,26473,26474,7,24,0,0,26474,
	26475,7,15,0,0,26475,26476,7,2,0,0,26476,26477,7,11,0,0,26477,26478,7,18,
	0,0,26478,26479,7,8,0,0,26479,26480,7,6,0,0,26480,26481,7,11,0,0,26481,
	3864,1,0,0,0,26482,26483,7,5,0,0,26483,26484,7,18,0,0,26484,26485,7,5,0,
	0,26485,26486,5,95,0,0,26486,26487,7,25,0,0,26487,26488,7,24,0,0,26488,
	26489,7,15,0,0,26489,26490,7,2,0,0,26490,26491,7,11,0,0,26491,26492,7,18,
	0,0,26492,26493,7,8,0,0,26493,26494,7,5,0,0,26494,26495,7,4,0,0,26495,26496,
	7,1,0,0,26496,26497,7,11,0,0,26497,3866,1,0,0,0,26498,26499,7,5,0,0,26499,
	26500,7,18,0,0,26500,26501,7,5,0,0,26501,26502,5,95,0,0,26502,26503,7,25,
	0,0,26503,26504,7,24,0,0,26504,26505,7,15,0,0,26505,26506,7,2,0,0,26506,
	26507,7,11,0,0,26507,26508,7,18,0,0,26508,26509,7,8,0,0,26509,26510,7,5,
	0,0,26510,26511,7,4,0,0,26511,3868,1,0,0,0,26512,26513,7,5,0,0,26513,26514,
	7,18,0,0,26514,26515,7,5,0,0,26515,26516,5,95,0,0,26516,26517,7,25,0,0,
	26517,26518,7,24,0,0,26518,26519,7,15,0,0,26519,26520,7,2,0,0,26520,26521,
	7,11,0,0,26521,26522,7,18,0,0,26522,26523,7,9,0,0,26523,26524,7,12,0,0,
	26524,26525,7,13,0,0,26525,3870,1,0,0,0,26526,26527,7,5,0,0,26527,26528,
	7,18,0,0,26528,26529,7,5,0,0,26529,26530,5,95,0,0,26530,26531,7,25,0,0,
	26531,26532,7,24,0,0,26532,26533,7,15,0,0,26533,26534,7,2,0,0,26534,26535,
	7,11,0,0,26535,26536,7,18,0,0,26536,26537,7,16,0,0,26537,26538,7,11,0,0,
	26538,26539,7,3,0,0,26539,3872,1,0,0,0,26540,26541,7,5,0,0,26541,26542,
	7,18,0,0,26542,26543,7,5,0,0,26543,26544,5,95,0,0,26544,26545,7,25,0,0,
	26545,26546,7,24,0,0,26546,26547,7,15,0,0,26547,26548,7,2,0,0,26548,26549,
	7,11,0,0,26549,26550,7,18,0,0,26550,26551,7,14,0,0,26551,26552,7,2,0,0,
	26552,26553,7,9,0,0,26553,3874,1,0,0,0,26554,26555,7,5,0,0,26555,26556,
	7,18,0,0,26556,26557,7,5,0,0,26557,26558,5,95,0,0,26558,26559,7,25,0,0,
	26559,26560,7,24,0,0,26560,26561,7,15,0,0,26561,26562,7,2,0,0,26562,26563,
	7,11,0,0,26563,26564,7,18,0,0,26564,26565,7,14,0,0,26565,26566,7,10,0,0,
	26566,26567,7,11,0,0,26567,3876,1,0,0,0,26568,26569,7,5,0,0,26569,26570,
	7,18,0,0,26570,26571,7,5,0,0,26571,26572,5,95,0,0,26572,26573,7,25,0,0,
	26573,26574,7,24,0,0,26574,26575,7,15,0,0,26575,26576,7,2,0,0,26576,26577,
	7,11,0,0,26577,26578,7,18,0,0,26578,26579,7,3,0,0,26579,26580,7,7,0,0,26580,
	26581,7,9,0,0,26581,3878,1,0,0,0,26582,26583,7,5,0,0,26583,26584,7,18,0,
	0,26584,26585,7,5,0,0,26585,26586,5,95,0,0,26586,26587,7,25,0,0,26587,26588,
	7,24,0,0,26588,26589,7,15,0,0,26589,26590,7,2,0,0,26590,26591,7,11,0,0,
	26591,26592,7,18,0,0,26592,26593,7,5,0,0,26593,26594,7,24,0,0,26594,26595,
	7,3,0,0,26595,26596,7,4,0,0,26596,3880,1,0,0,0,26597,26598,7,5,0,0,26598,
	26599,7,18,0,0,26599,26600,7,5,0,0,26600,26601,5,95,0,0,26601,26602,7,25,
	0,0,26602,26603,7,24,0,0,26603,26604,7,15,0,0,26604,26605,7,2,0,0,26605,
	26606,7,11,0,0,26606,26607,7,18,0,0,26607,26608,7,5,0,0,26608,26609,7,10,
	0,0,26609,26610,7,1,0,0,26610,3882,1,0,0,0,26611,26612,7,5,0,0,26612,26613,
	7,18,0,0,26613,26614,7,5,0,0,26614,26615,5,95,0,0,26615,26616,7,25,0,0,
	26616,26617,7,24,0,0,26617,26618,7,15,0,0,26618,26619,7,2,0,0,26619,26620,
	7,11,0,0,26620,26621,7,18,0,0,26621,26622,7,10,0,0,26622,26623,7,14,0,0,
	26623,26624,7,10,0,0,26624,26625,7,5,0,0,26625,3884,1,0,0,0,26626,26627,
	7,5,0,0,26627,26628,7,18,0,0,26628,26629,7,5,0,0,26629,26630,5,95,0,0,26630,
	26631,7,25,0,0,26631,26632,7,24,0,0,26632,26633,7,15,0,0,26633,26634,7,
	2,0,0,26634,26635,7,11,0,0,26635,26636,7,18,0,0,26636,26637,7,10,0,0,26637,
	26638,7,15,0,0,26638,26639,7,11,0,0,26639,26640,7,5,0,0,26640,3886,1,0,
	0,0,26641,26642,7,5,0,0,26642,26643,7,18,0,0,26643,26644,7,5,0,0,26644,
	26645,5,95,0,0,26645,26646,7,25,0,0,26646,26647,7,24,0,0,26647,26648,7,
	15,0,0,26648,26649,7,2,0,0,26649,26650,7,11,0,0,26650,26651,7,18,0,0,26651,
	26652,7,13,0,0,26652,26653,7,6,0,0,26653,26654,7,24,0,0,26654,3888,1,0,
	0,0,26655,26656,7,5,0,0,26656,26657,7,18,0,0,26657,26658,7,5,0,0,26658,
	26659,5,95,0,0,26659,26660,7,25,0,0,26660,26661,7,24,0,0,26661,26662,7,
	15,0,0,26662,26663,7,2,0,0,26663,26664,7,11,0,0,26664,26665,7,18,0,0,26665,
	26666,7,13,0,0,26666,26667,7,17,0,0,26667,26668,7,6,0,0,26668,3890,1,0,
	0,0,26669,26670,7,5,0,0,26670,26671,7,18,0,0,26671,26672,7,5,0,0,26672,
	26673,5,95,0,0,26673,26674,7,25,0,0,26674,26675,7,24,0,0,26675,26676,7,
	15,0,0,26676,26677,7,2,0,0,26677,26678,7,11,0,0,26678,26679,7,18,0,0,26679,
	26680,7,13,0,0,26680,26681,7,17,0,0,26681,26682,7,4,0,0,26682,3892,1,0,
	0,0,26683,26684,7,5,0,0,26684,26685,7,18,0,0,26685,26686,7,5,0,0,26686,
	26687,5,95,0,0,26687,26688,7,25,0,0,26688,26689,7,24,0,0,26689,26690,7,
	15,0,0,26690,26691,7,2,0,0,26691,26692,7,11,0,0,26692,26693,7,18,0,0,26693,
	26694,7,13,0,0,26694,26695,7,11,0,0,26695,26696,7,6,0,0,26696,3894,1,0,
	0,0,26697,26698,7,5,0,0,26698,26699,7,18,0,0,26699,26700,7,5,0,0,26700,
	26701,5,95,0,0,26701,26702,7,25,0,0,26702,26703,7,24,0,0,26703,26704,7,
	15,0,0,26704,26705,7,2,0,0,26705,26706,7,11,0,0,26706,26707,7,18,0,0,26707,
	26708,7,13,0,0,26708,26709,7,11,0,0,26709,26710,7,4,0,0,26710,3896,1,0,
	0,0,26711,26712,7,5,0,0,26712,26713,7,18,0,0,26713,26714,7,5,0,0,26714,
	26715,5,95,0,0,26715,26716,7,25,0,0,26716,26717,7,24,0,0,26717,26718,7,
	15,0,0,26718,26719,7,2,0,0,26719,26720,7,11,0,0,26720,26721,7,18,0,0,26721,
	26722,7,13,0,0,26722,26723,7,7,0,0,26723,26724,7,6,0,0,26724,3898,1,0,0,
	0,26725,26726,7,5,0,0,26726,26727,7,18,0,0,26727,26728,7,5,0,0,26728,26729,
	5,95,0,0,26729,26730,7,25,0,0,26730,26731,7,24,0,0,26731,26732,7,3,0,0,
	26732,26733,7,6,0,0,26733,26734,7,16,0,0,26734,26735,5,50,0,0,26735,26736,
	7,13,0,0,26736,26737,7,0,0,0,26737,26738,7,11,0,0,26738,3900,1,0,0,0,26739,
	26740,7,5,0,0,26740,26741,7,18,0,0,26741,26742,7,5,0,0,26742,26743,5,95,
	0,0,26743,26744,7,25,0,0,26744,26745,7,24,0,0,26745,26746,7,3,0,0,26746,
	26747,7,6,0,0,26747,26748,7,7,0,0,26748,26749,7,0,0,0,26749,26750,7,14,
	0,0,26750,26751,7,6,0,0,26751,3902,1,0,0,0,26752,26753,7,5,0,0,26753,26754,
	7,18,0,0,26754,26755,7,5,0,0,26755,26756,5,95,0,0,26756,26757,7,25,0,0,
	26757,26758,7,24,0,0,26758,26759,7,3,0,0,26759,26760,7,6,0,0,26760,26761,
	7,15,0,0,26761,26762,7,11,0,0,26762,26763,7,0,0,0,26763,26764,7,8,0,0,26764,
	26765,7,6,0,0,26765,3904,1,0,0,0,26766,26767,7,5,0,0,26767,26768,7,18,0,
	0,26768,26769,7,5,0,0,26769,26770,5,95,0,0,26770,26771,7,25,0,0,26771,26772,
	7,24,0,0,26772,26773,7,3,0,0,26773,26774,7,6,0,0,26774,26775,7,5,0,0,26775,
	26776,7,13,0,0,26776,26777,7,10,0,0,26777,26778,7,3,0,0,26778,26779,7,12,
	0,0,26779,3906,1,0,0,0,26780,26781,7,5,0,0,26781,26782,7,18,0,0,26782,26783,
	7,5,0,0,26783,26784,5,95,0,0,26784,26785,7,25,0,0,26785,26786,7,24,0,0,
	26786,26787,7,3,0,0,26787,26788,7,7,0,0,26788,26789,7,9,0,0,26789,26790,
	7,19,0,0,26790,26791,7,0,0,0,26791,26792,7,11,0,0,26792,26793,7,16,0,0,
	26793,26794,5,50,0,0,26794,26795,7,6,0,0,26795,26796,7,13,0,0,26796,26797,
	7,7,0,0,26797,3908,1,0,0,0,26798,26799,7,5,0,0,26799,26800,7,18,0,0,26800,
	26801,7,5,0,0,26801,26802,5,95,0,0,26802,26803,7,25,0,0,26803,26804,7,24,
	0,0,26804,26805,7,3,0,0,26805,26806,7,5,0,0,26806,26807,7,11,0,0,26807,
	26808,7,13,0,0,26808,26809,7,24,0,0,26809,26810,7,7,0,0,26810,26811,7,14,
	0,0,26811,3910,1,0,0,0,26812,26813,7,5,0,0,26813,26814,7,18,0,0,26814,26815,
	7,5,0,0,26815,26816,5,95,0,0,26816,26817,7,25,0,0,26817,26818,7,24,0,0,
	26818,26819,7,3,0,0,26819,26820,7,18,0,0,26820,26821,7,6,0,0,26821,26822,
	7,7,0,0,26822,26823,7,13,0,0,26823,26824,7,15,0,0,26824,26825,7,17,0,0,
	26825,26826,7,6,0,0,26826,26827,7,4,0,0,26827,3912,1,0,0,0,26828,26829,
	7,5,0,0,26829,26830,7,18,0,0,26830,26831,7,5,0,0,26831,26832,5,95,0,0,26832,
	26833,7,25,0,0,26833,26834,7,24,0,0,26834,26835,7,3,0,0,26835,26836,7,18,
	0,0,26836,26837,7,13,0,0,26837,26838,7,0,0,0,26838,26839,7,3,0,0,26839,
	26840,7,17,0,0,26840,26841,7,6,0,0,26841,26842,7,4,0,0,26842,3914,1,0,0,
	0,26843,26844,7,5,0,0,26844,26845,7,18,0,0,26845,26846,7,5,0,0,26846,26847,
	5,95,0,0,26847,26848,7,25,0,0,26848,26849,7,24,0,0,26849,26850,7,3,0,0,
	26850,26851,7,18,0,0,26851,26852,7,22,0,0,26852,26853,7,3,0,0,26853,26854,
	7,15,0,0,26854,3916,1,0,0,0,26855,26856,7,5,0,0,26856,26857,7,18,0,0,26857,
	26858,7,5,0,0,26858,26859,5,95,0,0,26859,26860,7,25,0,0,26860,26861,7,24,
	0,0,26861,26862,7,5,0,0,26862,26863,7,6,0,0,26863,26864,7,24,0,0,26864,
	26865,5,50,0,0,26865,26866,7,8,0,0,26866,26867,7,2,0,0,26867,26868,7,7,
	0,0,26868,26869,5,52,0,0,26869,26870,7,25,0,0,26870,26871,7,8,0,0,26871,
	3918,1,0,0,0,26872,26873,7,5,0,0,26873,26874,7,18,0,0,26874,26875,7,5,0,
	0,26875,26876,5,95,0,0,26876,26877,7,25,0,0,26877,26878,7,24,0,0,26878,
	26879,7,5,0,0,26879,26880,7,6,0,0,26880,26881,7,24,0,0,26881,26882,5,50,
	0,0,26882,26883,7,8,0,0,26883,26884,7,2,0,0,26884,26885,7,7,0,0,26885,3920,
	1,0,0,0,26886,26887,7,5,0,0,26887,26888,7,18,0,0,26888,26889,7,5,0,0,26889,
	26890,5,95,0,0,26890,26891,7,25,0,0,26891,26892,7,24,0,0,26892,26893,7,
	5,0,0,26893,26894,7,6,0,0,26894,26895,7,24,0,0,26895,26896,7,9,0,0,26896,
	26897,7,6,0,0,26897,26898,7,6,0,0,26898,26899,7,15,0,0,26899,26900,7,6,
	0,0,26900,26901,7,24,0,0,26901,3922,1,0,0,0,26902,26903,7,5,0,0,26903,26904,
	7,18,0,0,26904,26905,7,5,0,0,26905,26906,5,95,0,0,26906,26907,7,25,0,0,
	26907,26908,7,24,0,0,26908,26909,7,5,0,0,26909,26910,7,6,0,0,26910,26911,
	7,24,0,0,26911,26912,7,12,0,0,26912,26913,7,7,0,0,26913,26914,7,5,0,0,26914,
	26915,7,1,0,0,26915,3924,1,0,0,0,26916,26917,7,5,0,0,26917,26918,7,18,0,
	0,26918,26919,7,5,0,0,26919,26920,5,95,0,0,26920,26921,7,25,0,0,26921,26922,
	7,24,0,0,26922,26923,7,5,0,0,26923,26924,7,6,0,0,26924,26925,7,24,0,0,26925,
	26926,7,3,0,0,26926,26927,7,14,0,0,26927,3926,1,0,0,0,26928,26929,7,5,0,
	0,26929,26930,7,18,0,0,26930,26931,7,5,0,0,26931,26932,5,95,0,0,26932,26933,
	7,25,0,0,26933,26934,7,24,0,0,26934,26935,7,5,0,0,26935,26936,7,6,0,0,26936,
	26937,7,24,0,0,26937,26938,7,3,0,0,26938,26939,7,13,0,0,26939,26940,7,5,
	0,0,26940,3928,1,0,0,0,26941,26942,7,5,0,0,26942,26943,7,18,0,0,26943,26944,
	7,5,0,0,26944,26945,5,95,0,0,26945,26946,7,25,0,0,26946,26947,7,24,0,0,
	26947,26948,7,5,0,0,26948,26949,7,6,0,0,26949,26950,7,24,0,0,26950,26951,
	7,5,0,0,26951,26952,7,10,0,0,26952,26953,7,1,0,0,26953,3930,1,0,0,0,26954,
	26955,7,5,0,0,26955,26956,7,18,0,0,26956,26957,7,5,0,0,26957,26958,5,95,
	0,0,26958,26959,7,25,0,0,26959,26960,7,24,0,0,26960,26961,7,5,0,0,26961,
	26962,7,6,0,0,26962,26963,7,24,0,0,26963,26964,7,4,0,0,26964,26965,7,18,
	0,0,26965,26966,7,15,0,0,26966,26967,7,14,0,0,26967,26968,7,0,0,0,26968,
	26969,7,4,0,0,26969,26970,7,8,0,0,26970,26971,7,19,0,0,26971,3932,1,0,0,
	0,26972,26973,7,5,0,0,26973,26974,7,18,0,0,26974,26975,7,5,0,0,26975,26976,
	5,95,0,0,26976,26977,7,25,0,0,26977,26978,7,24,0,0,26978,26979,7,5,0,0,
	26979,26980,7,4,0,0,26980,26981,7,0,0,0,26981,26982,7,3,0,0,26982,26983,
	7,4,0,0,26983,26984,7,5,0,0,26984,26985,7,22,0,0,26985,26986,7,12,0,0,26986,
	26987,7,4,0,0,26987,26988,7,19,0,0,26988,3934,1,0,0,0,26989,26990,7,5,0,
	0,26990,26991,7,18,0,0,26991,26992,7,5,0,0,26992,26993,5,95,0,0,26993,26994,
	7,25,0,0,26994,26995,7,24,0,0,26995,26996,7,5,0,0,26996,26997,7,4,0,0,26997,
	26998,7,0,0,0,26998,26999,7,4,0,0,26999,27000,7,1,0,0,27000,27001,7,10,
	0,0,27001,27002,7,3,0,0,27002,27003,7,12,0,0,27003,3936,1,0,0,0,27004,27005,
	7,5,0,0,27005,27006,7,18,0,0,27006,27007,7,5,0,0,27007,27008,5,95,0,0,27008,
	27009,7,25,0,0,27009,27010,7,24,0,0,27010,27011,7,5,0,0,27011,27012,7,4,
	0,0,27012,27013,7,3,0,0,27013,27014,5,50,0,0,27014,27015,7,8,0,0,27015,
	27016,7,2,0,0,27016,27017,7,9,0,0,27017,27018,7,6,0,0,27018,27019,7,15,
	0,0,27019,3938,1,0,0,0,27020,27021,7,5,0,0,27021,27022,7,18,0,0,27022,27023,
	7,5,0,0,27023,27024,5,95,0,0,27024,27025,7,25,0,0,27025,27026,7,24,0,0,
	27026,27027,7,5,0,0,27027,27028,7,4,0,0,27028,27029,7,3,0,0,27029,27030,
	7,20,0,0,27030,27031,7,2,0,0,27031,27032,7,12,0,0,27032,27033,7,7,0,0,27033,
	3940,1,0,0,0,27034,27035,7,5,0,0,27035,27036,7,18,0,0,27036,27037,7,5,0,
	0,27037,27038,5,95,0,0,27038,27039,7,25,0,0,27039,27040,7,24,0,0,27040,
	27041,7,5,0,0,27041,27042,7,10,0,0,27042,27043,7,1,0,0,27043,27044,7,5,
	0,0,27044,27045,7,4,0,0,27045,27046,7,3,0,0,27046,27047,7,0,0,0,27047,27048,
	7,16,0,0,27048,27049,7,4,0,0,27049,3942,1,0,0,0,27050,27051,7,5,0,0,27051,
	27052,7,18,0,0,27052,27053,7,5,0,0,27053,27054,5,95,0,0,27054,27055,7,25,
	0,0,27055,27056,7,24,0,0,27056,27057,7,5,0,0,27057,27058,7,10,0,0,27058,
	27059,7,1,0,0,27059,27060,7,5,0,0,27060,27061,7,4,0,0,27061,27062,7,3,0,
	0,27062,27063,7,1,0,0,27063,27064,7,6,0,0,27064,27065,7,16,0,0,27065,3944,
	1,0,0,0,27066,27067,7,5,0,0,27067,27068,7,18,0,0,27068,27069,7,5,0,0,27069,
	27070,5,95,0,0,27070,27071,7,25,0,0,27071,27072,7,24,0,0,27072,27073,7,
	4,0,0,27073,27074,7,2,0,0,27074,27075,7,21,0,0,27075,27076,7,6,0,0,27076,
	27077,7,7,0,0,27077,27078,7,12,0,0,27078,27079,7,23,0,0,27079,27080,7,6,
	0,0,27080,3946,1,0,0,0,27081,27082,7,5,0,0,27082,27083,7,18,0,0,27083,27084,
	7,5,0,0,27084,27085,5,95,0,0,27085,27086,7,25,0,0,27086,27087,7,24,0,0,
	27087,27088,7,4,0,0,27088,27089,7,3,0,0,27089,27090,7,6,0,0,27090,27091,
	7,0,0,0,27091,27092,7,4,0,0,27092,27093,7,0,0,0,27093,27094,7,5,0,0,27094,
	3948,1,0,0,0,27095,27096,7,5,0,0,27096,27097,7,18,0,0,27097,27098,7,5,0,
	0,27098,27099,5,95,0,0,27099,27100,7,25,0,0,27100,27101,7,24,0,0,27101,
	27102,5,95,0,0,27102,27103,7,10,0,0,27103,27104,7,15,0,0,27104,27105,7,
	21,0,0,27105,27106,7,25,0,0,27106,27107,7,14,0,0,27107,27108,7,11,0,0,27108,
	27109,5,50,0,0,27109,27110,7,5,0,0,27110,27111,7,24,0,0,27111,27112,7,11,
	0,0,27112,3950,1,0,0,0,27113,27114,7,5,0,0,27114,27115,7,18,0,0,27115,27116,
	7,5,0,0,27116,27117,5,95,0,0,27117,27118,7,25,0,0,27118,27119,7,24,0,0,
	27119,27120,7,25,0,0,27120,27121,7,16,0,0,27121,27122,7,2,0,0,27122,27123,
	7,3,0,0,27123,27124,7,14,0,0,27124,3952,1,0,0,0,27125,27126,7,5,0,0,27126,
	27127,7,18,0,0,27127,27128,7,5,0,0,27128,27129,5,95,0,0,27129,27130,7,25,
	0,0,27130,27131,7,5,0,0,27131,27132,7,12,0,0,27132,27133,7,9,0,0,27133,
	27134,5,95,0,0,27134,27135,7,4,0,0,27135,27136,7,2,0,0,27136,27137,5,95,
	0,0,27137,27138,7,3,0,0,27138,27139,7,0,0,0,27139,27140,7,22,0,0,27140,
	3954,1,0,0,0,27141,27142,7,5,0,0,27142,27143,7,18,0,0,27143,27144,7,5,0,
	0,27144,27145,5,95,0,0,27145,27146,7,23,0,0,27146,27147,7,14,0,0,27147,
	27148,7,0,0,0,27148,27149,7,15,0,0,27149,27150,5,95,0,0,27150,27151,7,16,
	0,0,27151,27152,7,12,0,0,27152,27153,7,11,0,0,27153,27154,7,4,0,0,27154,
	27155,7,6,0,0,27155,27156,7,3,0,0,27156,3956,1,0,0,0,27157,27158,7,5,0,
	0,27158,27159,7,18,0,0,27159,27160,7,5,0,0,27160,27161,5,95,0,0,27161,27162,
	7,23,0,0,27162,27163,7,14,0,0,27163,27164,7,0,0,0,27164,27165,7,15,0,0,
	27165,27166,5,95,0,0,27166,27167,7,3,0,0,27167,27168,7,6,0,0,27168,27169,
	7,16,0,0,27169,27170,7,3,0,0,27170,27171,7,6,0,0,27171,27172,7,5,0,0,27172,
	27173,7,19,0,0,27173,3958,1,0,0,0,27174,27175,7,4,0,0,27175,3960,1,0,0,
	0,27176,27177,7,4,0,0,27177,27178,7,0,0,0,27178,27179,7,1,0,0,27179,27180,
	7,11,0,0,27180,27181,7,6,0,0,27181,27182,5,95,0,0,27182,27183,7,11,0,0,
	27183,27184,7,2,0,0,27184,27185,7,2,0,0,27185,27186,7,21,0,0,27186,27187,
	7,10,0,0,27187,27188,7,15,0,0,27188,27189,5,95,0,0,27189,27190,7,1,0,0,
	27190,27191,7,18,0,0,27191,27192,5,95,0,0,27192,27193,7,7,0,0,27193,27194,
	7,11,0,0,27194,3962,1,0,0,0,27195,27196,7,4,0,0,27196,27197,7,0,0,0,27197,
	27198,7,1,0,0,27198,27199,7,11,0,0,27199,27200,7,6,0,0,27200,27201,7,5,
	0,0,27201,27202,7,15,0,0,27202,27203,7,0,0,0,27203,27204,7,8,0,0,27204,
	27205,7,6,0,0,27205,27206,5,95,0,0,27206,27207,7,7,0,0,27207,27208,7,2,
	0,0,27208,3964,1,0,0,0,27209,27210,7,4,0,0,27210,27211,7,0,0,0,27211,27212,
	7,1,0,0,27212,27213,7,11,0,0,27213,27214,7,6,0,0,27214,27215,7,5,0,0,27215,
	27216,7,15,0,0,27216,27217,7,0,0,0,27217,27218,7,8,0,0,27218,27219,7,6,
	0,0,27219,3966,1,0,0,0,27220,27221,7,4,0,0,27221,27222,7,0,0,0,27222,27223,
	7,1,0,0,27223,27224,7,11,0,0,27224,27225,7,6,0,0,27225,27226,7,5,0,0,27226,
	3968,1,0,0,0,27227,27228,7,4,0,0,27228,27229,7,0,0,0,27229,27230,7,1,0,
	0,27230,27231,7,11,0,0,27231,27232,7,6,0,0,27232,27233,5,95,0,0,27233,27234,
	7,5,0,0,27234,27235,7,4,0,0,27235,27236,7,0,0,0,27236,27237,7,4,0,0,27237,
	27238,7,5,0,0,27238,3970,1,0,0,0,27239,27240,7,4,0,0,27240,27241,7,0,0,
	0,27241,27242,7,1,0,0,27242,27243,7,11,0,0,27243,27244,7,6,0,0,27244,3972,
	1,0,0,0,27245,27246,7,4,0,0,27246,27247,7,0,0,0,27247,27248,7,1,0,0,27248,
	27249,7,7,0,0,27249,27250,7,2,0,0,27250,3974,1,0,0,0,27251,27252,7,4,0,
	0,27252,27253,7,0,0,0,27253,27254,7,17,0,0,27254,3976,1,0,0,0,27255,27256,
	7,4,0,0,27256,27257,7,0,0,0,27257,27258,7,7,0,0,27258,27259,7,19,0,0,27259,
	3978,1,0,0,0,27260,27261,7,4,0,0,27261,27262,7,0,0,0,27262,27263,7,7,0,
	0,27263,3980,1,0,0,0,27264,27265,7,4,0,0,27265,27266,7,1,0,0,27266,27267,
	7,11,0,0,27267,27268,5,36,0,0,27268,27269,7,2,0,0,27269,27270,7,3,0,0,27270,
	27271,5,36,0,0,27271,27272,7,12,0,0,27272,27273,7,9,0,0,27273,27274,7,25,
	0,0,27274,27275,5,36,0,0,27275,27276,7,15,0,0,27276,27277,7,0,0,0,27277,
	27278,7,3,0,0,27278,27279,7,4,0,0,27279,27280,5,36,0,0,27280,27281,7,7,
	0,0,27281,27282,7,10,0,0,27282,27283,7,14,0,0,27283,3982,1,0,0,0,27284,
	27285,7,4,0,0,27285,27286,7,6,0,0,27286,27287,7,14,0,0,27287,27288,7,15,
	0,0,27288,27289,7,16,0,0,27289,27290,7,12,0,0,27290,27291,7,11,0,0,27291,
	27292,7,6,0,0,27292,3984,1,0,0,0,27293,27294,7,4,0,0,27294,27295,7,6,0,
	0,27295,27296,7,14,0,0,27296,27297,7,15,0,0,27297,27298,7,11,0,0,27298,
	27299,7,0,0,0,27299,27300,7,4,0,0,27300,27301,7,6,0,0,27301,3986,1,0,0,
	0,27302,27303,7,4,0,0,27303,27304,7,6,0,0,27304,27305,7,14,0,0,27305,27306,
	7,15,0,0,27306,27307,7,2,0,0,27307,27308,7,3,0,0,27308,27309,7,0,0,0,27309,
	27310,7,3,0,0,27310,27311,7,18,0,0,27311,3988,1,0,0,0,27312,27313,7,4,0,
	0,27313,27314,7,6,0,0,27314,27315,7,14,0,0,27315,27316,7,15,0,0,27316,27317,
	5,95,0,0,27317,27318,7,4,0,0,27318,27319,7,0,0,0,27319,27320,7,1,0,0,27320,
	27321,7,11,0,0,27321,27322,7,6,0,0,27322,3990,1,0,0,0,27323,27324,7,4,0,
	0,27324,27325,7,6,0,0,27325,27326,7,5,0,0,27326,27327,7,4,0,0,27327,3992,
	1,0,0,0,27328,27329,7,4,0,0,27329,27330,7,6,0,0,27330,27331,7,25,0,0,27331,
	27332,7,4,0,0,27332,3994,1,0,0,0,27333,27334,7,4,0,0,27334,27335,7,19,0,
	0,27335,27336,7,0,0,0,27336,27337,7,7,0,0,27337,3996,1,0,0,0,27338,27339,
	7,4,0,0,27339,27340,7,19,0,0,27340,27341,7,6,0,0,27341,27342,7,7,0,0,27342,
	3998,1,0,0,0,27343,27344,7,4,0,0,27344,27345,7,19,0,0,27345,27346,7,6,0,
	0,27346,4000,1,0,0,0,27347,27348,7,4,0,0,27348,27349,7,19,0,0,27349,27350,
	7,3,0,0,27350,27351,7,6,0,0,27351,27352,7,0,0,0,27352,27353,7,9,0,0,27353,
	4002,1,0,0,0,27354,27355,7,4,0,0,27355,27356,7,19,0,0,27356,27357,7,3,0,
	0,27357,27358,7,2,0,0,27358,27359,7,10,0,0,27359,27360,7,17,0,0,27360,27361,
	7,19,0,0,27361,4004,1,0,0,0,27362,27363,7,4,0,0,27363,27364,7,12,0,0,27364,
	27365,7,6,0,0,27365,27366,7,3,0,0,27366,4006,1,0,0,0,27367,27368,7,4,0,
	0,27368,27369,7,12,0,0,27369,27370,7,6,0,0,27370,27371,7,5,0,0,27371,4008,
	1,0,0,0,27372,27373,7,4,0,0,27373,27374,7,12,0,0,27374,27375,7,14,0,0,27375,
	27376,7,6,0,0,27376,27377,7,2,0,0,27377,27378,7,10,0,0,27378,27379,7,4,
	0,0,27379,4010,1,0,0,0,27380,27381,7,4,0,0,27381,27382,7,12,0,0,27382,27383,
	7,14,0,0,27383,27384,7,6,0,0,27384,27385,7,5,0,0,27385,27386,7,4,0,0,27386,
	27387,7,0,0,0,27387,27388,7,14,0,0,27388,27389,7,15,0,0,27389,27390,5,95,
	0,0,27390,27391,7,11,0,0,27391,27392,7,4,0,0,27392,27393,7,23,0,0,27393,
	27394,5,95,0,0,27394,27395,7,10,0,0,27395,27396,7,7,0,0,27396,27397,7,8,
	0,0,27397,27398,7,2,0,0,27398,27399,7,7,0,0,27399,27400,7,5,0,0,27400,27401,
	7,4,0,0,27401,27402,7,3,0,0,27402,27403,7,0,0,0,27403,27404,7,12,0,0,27404,
	27405,7,7,0,0,27405,27406,7,6,0,0,27406,27407,7,9,0,0,27407,4012,1,0,0,
	0,27408,27409,7,4,0,0,27409,27410,7,12,0,0,27410,27411,7,14,0,0,27411,27412,
	7,6,0,0,27412,27413,7,5,0,0,27413,27414,7,4,0,0,27414,27415,7,0,0,0,27415,
	27416,7,14,0,0,27416,27417,7,15,0,0,27417,4014,1,0,0,0,27418,27419,7,4,
	0,0,27419,27420,7,12,0,0,27420,27421,7,14,0,0,27421,27422,7,6,0,0,27422,
	27423,7,5,0,0,27423,27424,7,4,0,0,27424,27425,7,0,0,0,27425,27426,7,14,
	0,0,27426,27427,7,15,0,0,27427,27428,5,95,0,0,27428,27429,7,4,0,0,27429,
	27430,7,23,0,0,27430,27431,5,95,0,0,27431,27432,7,10,0,0,27432,27433,7,
	7,0,0,27433,27434,7,8,0,0,27434,27435,7,2,0,0,27435,27436,7,7,0,0,27436,
	27437,7,5,0,0,27437,27438,7,4,0,0,27438,27439,7,3,0,0,27439,27440,7,0,0,
	0,27440,27441,7,12,0,0,27441,27442,7,7,0,0,27442,27443,7,6,0,0,27443,27444,
	7,9,0,0,27444,4016,1,0,0,0,27445,27446,7,4,0,0,27446,27447,7,12,0,0,27447,
	27448,7,14,0,0,27448,27449,7,6,0,0,27449,27450,7,5,0,0,27450,27451,7,4,
	0,0,27451,27452,7,0,0,0,27452,27453,7,14,0,0,27453,27454,7,15,0,0,27454,
	27455,5,95,0,0,27455,27456,7,10,0,0,27456,27457,7,7,0,0,27457,27458,7,8,
	0,0,27458,27459,7,2,0,0,27459,27460,7,7,0,0,27460,27461,7,5,0,0,27461,27462,
	7,4,0,0,27462,27463,7,3,0,0,27463,27464,7,0,0,0,27464,27465,7,12,0,0,27465,
	27466,7,7,0,0,27466,27467,7,6,0,0,27467,27468,7,9,0,0,27468,4018,1,0,0,
	0,27469,27470,7,4,0,0,27470,27471,7,12,0,0,27471,27472,7,14,0,0,27472,27473,
	7,6,0,0,27473,27474,7,5,0,0,27474,4020,1,0,0,0,27475,27476,7,4,0,0,27476,
	27477,7,12,0,0,27477,27478,7,14,0,0,27478,27479,7,6,0,0,27479,4022,1,0,
	0,0,27480,27481,7,4,0,0,27481,27482,7,12,0,0,27482,27483,7,14,0,0,27483,
	27484,7,6,0,0,27484,27485,7,23,0,0,27485,27486,7,2,0,0,27486,27487,7,7,
	0,0,27487,27488,7,6,0,0,27488,4024,1,0,0,0,27489,27490,7,4,0,0,27490,27491,
	7,12,0,0,27491,27492,7,14,0,0,27492,27493,7,6,0,0,27493,27494,7,23,0,0,
	27494,27495,7,2,0,0,27495,27496,7,7,0,0,27496,27497,7,6,0,0,27497,27498,
	5,95,0,0,27498,27499,7,0,0,0,27499,27500,7,1,0,0,27500,27501,7,1,0,0,27501,
	27502,7,3,0,0,27502,4026,1,0,0,0,27503,27504,7,4,0,0,27504,27505,7,12,0,
	0,27505,27506,7,14,0,0,27506,27507,7,6,0,0,27507,27508,7,23,0,0,27508,27509,
	7,2,0,0,27509,27510,7,7,0,0,27510,27511,7,6,0,0,27511,27512,5,95,0,0,27512,
	27513,7,19,0,0,27513,27514,7,2,0,0,27514,27515,7,10,0,0,27515,27516,7,3,
	0,0,27516,4028,1,0,0,0,27517,27518,7,4,0,0,27518,27519,7,12,0,0,27519,27520,
	7,14,0,0,27520,27521,7,6,0,0,27521,27522,7,23,0,0,27522,27523,7,2,0,0,27523,
	27524,7,7,0,0,27524,27525,7,6,0,0,27525,27526,5,95,0,0,27526,27527,7,14,
	0,0,27527,27528,7,12,0,0,27528,27529,7,7,0,0,27529,27530,7,10,0,0,27530,
	27531,7,4,0,0,27531,27532,7,6,0,0,27532,4030,1,0,0,0,27533,27534,7,4,0,
	0,27534,27535,7,12,0,0,27535,27536,7,14,0,0,27536,27537,7,6,0,0,27537,27538,
	7,23,0,0,27538,27539,7,2,0,0,27539,27540,7,7,0,0,27540,27541,7,6,0,0,27541,
	27542,5,95,0,0,27542,27543,7,2,0,0,27543,27544,7,16,0,0,27544,27545,7,16,
	0,0,27545,27546,7,5,0,0,27546,27547,7,6,0,0,27547,27548,7,4,0,0,27548,4032,
	1,0,0,0,27549,27550,7,4,0,0,27550,27551,7,12,0,0,27551,27552,7,14,0,0,27552,
	27553,7,6,0,0,27553,27554,7,23,0,0,27554,27555,7,2,0,0,27555,27556,7,7,
	0,0,27556,27557,7,6,0,0,27557,27558,5,95,0,0,27558,27559,7,3,0,0,27559,
	27560,7,6,0,0,27560,27561,7,17,0,0,27561,27562,7,12,0,0,27562,27563,7,2,
	0,0,27563,27564,7,7,0,0,27564,4034,1,0,0,0,27565,27566,7,4,0,0,27566,27567,
	7,12,0,0,27567,27568,7,14,0,0,27568,27569,7,6,0,0,27569,27570,5,95,0,0,
	27570,27571,7,23,0,0,27571,27572,7,2,0,0,27572,27573,7,7,0,0,27573,27574,
	7,6,0,0,27574,4036,1,0,0,0,27575,27576,7,4,0,0,27576,27577,7,12,0,0,27577,
	27578,7,14,0,0,27578,27579,7,12,0,0,27579,27580,7,7,0,0,27580,27581,7,17,
	0,0,27581,4038,1,0,0,0,27582,27583,7,4,0,0,27583,27584,7,12,0,0,27584,27585,
	7,13,0,0,27585,27586,5,95,0,0,27586,27587,7,17,0,0,27587,27588,7,1,0,0,
	27588,4040,1,0,0,0,27589,27590,7,4,0,0,27590,27591,7,12,0,0,27591,27592,
	7,13,0,0,27592,27593,5,95,0,0,27593,27594,7,5,0,0,27594,27595,7,5,0,0,27595,
	27596,7,16,0,0,27596,4042,1,0,0,0,27597,27598,7,4,0,0,27598,27599,7,2,0,
	0,27599,27600,5,95,0,0,27600,27601,7,0,0,0,27601,27602,7,8,0,0,27602,27603,
	7,11,0,0,27603,27604,7,12,0,0,27604,27605,7,9,0,0,27605,4044,1,0,0,0,27606,
	27607,7,4,0,0,27607,27608,7,2,0,0,27608,27609,5,95,0,0,27609,27610,7,1,
	0,0,27610,27611,7,12,0,0,27611,27612,7,7,0,0,27612,27613,7,0,0,0,27613,
	27614,7,3,0,0,27614,27615,7,18,0,0,27615,27616,5,95,0,0,27616,27617,7,9,
	0,0,27617,27618,7,2,0,0,27618,27619,7,10,0,0,27619,27620,7,1,0,0,27620,
	27621,7,11,0,0,27621,27622,7,6,0,0,27622,4046,1,0,0,0,27623,27624,7,4,0,
	0,27624,27625,7,2,0,0,27625,27626,5,95,0,0,27626,27627,7,1,0,0,27627,27628,
	7,12,0,0,27628,27629,7,7,0,0,27629,27630,7,0,0,0,27630,27631,7,3,0,0,27631,
	27632,7,18,0,0,27632,27633,5,95,0,0,27633,27634,7,16,0,0,27634,27635,7,
	11,0,0,27635,27636,7,2,0,0,27636,27637,7,0,0,0,27637,27638,7,4,0,0,27638,
	4048,1,0,0,0,27639,27640,7,4,0,0,27640,27641,7,2,0,0,27641,27642,5,95,0,
	0,27642,27643,7,1,0,0,27643,27644,7,11,0,0,27644,27645,7,2,0,0,27645,27646,
	7,1,0,0,27646,4050,1,0,0,0,27647,27648,7,4,0,0,27648,27649,7,2,0,0,27649,
	27650,5,95,0,0,27650,27651,7,8,0,0,27651,27652,7,11,0,0,27652,27653,7,2,
	0,0,27653,27654,7,1,0,0,27654,4052,1,0,0,0,27655,27656,7,4,0,0,27656,27657,
	7,2,0,0,27657,27658,5,95,0,0,27658,27659,7,9,0,0,27659,27660,7,5,0,0,27660,
	27661,7,12,0,0,27661,27662,7,7,0,0,27662,27663,7,4,0,0,27663,27664,7,6,
	0,0,27664,27665,7,3,0,0,27665,27666,7,13,0,0,27666,27667,7,0,0,0,27667,
	27668,7,11,0,0,27668,4054,1,0,0,0,27669,27670,7,4,0,0,27670,27671,7,2,0,
	0,27671,27672,5,95,0,0,27672,27673,7,11,0,0,27673,27674,7,2,0,0,27674,27675,
	7,1,0,0,27675,4056,1,0,0,0,27676,27677,7,4,0,0,27677,27678,7,2,0,0,27678,
	27679,5,95,0,0,27679,27680,7,14,0,0,27680,27681,7,10,0,0,27681,27682,7,
	11,0,0,27682,27683,7,4,0,0,27683,27684,7,12,0,0,27684,27685,5,95,0,0,27685,
	27686,7,1,0,0,27686,27687,7,18,0,0,27687,27688,7,4,0,0,27688,27689,7,6,
	0,0,27689,4058,1,0,0,0,27690,27691,7,4,0,0,27691,27692,7,2,0,0,27692,27693,
	5,95,0,0,27693,27694,7,7,0,0,27694,27695,7,8,0,0,27695,27696,7,19,0,0,27696,
	27697,7,0,0,0,27697,27698,7,3,0,0,27698,4060,1,0,0,0,27699,27700,7,4,0,
	0,27700,27701,7,2,0,0,27701,27702,5,95,0,0,27702,27703,7,7,0,0,27703,27704,
	7,8,0,0,27704,27705,7,11,0,0,27705,27706,7,2,0,0,27706,27707,7,1,0,0,27707,
	4062,1,0,0,0,27708,27709,7,4,0,0,27709,27710,7,2,0,0,27710,27711,5,95,0,
	0,27711,27712,7,7,0,0,27712,27713,7,10,0,0,27713,27714,7,14,0,0,27714,27715,
	7,1,0,0,27715,27716,7,6,0,0,27716,27717,7,3,0,0,27717,4064,1,0,0,0,27718,
	27719,7,4,0,0,27719,27720,7,2,0,0,27720,27721,7,15,0,0,27721,27722,7,11,
	0,0,27722,27723,7,6,0,0,27723,27724,7,13,0,0,27724,27725,7,6,0,0,27725,
	27726,7,11,0,0,27726,4066,1,0,0,0,27727,27728,7,4,0,0,27728,27729,7,2,0,
	0,27729,27730,5,95,0,0,27730,27731,7,5,0,0,27731,27732,7,12,0,0,27732,27733,
	7,7,0,0,27733,27734,7,17,0,0,27734,27735,7,11,0,0,27735,27736,7,6,0,0,27736,
	27737,5,95,0,0,27737,27738,7,1,0,0,27738,27739,7,18,0,0,27739,27740,7,4,
	0,0,27740,27741,7,6,0,0,27741,4068,1,0,0,0,27742,27743,7,4,0,0,27743,27744,
	7,2,0,0,27744,27745,5,95,0,0,27745,27746,7,4,0,0,27746,27747,7,12,0,0,27747,
	27748,7,14,0,0,27748,27749,7,6,0,0,27749,27750,7,5,0,0,27750,27751,7,4,
	0,0,27751,27752,7,0,0,0,27752,27753,7,14,0,0,27753,27754,7,15,0,0,27754,
	4070,1,0,0,0,27755,27756,7,4,0,0,27756,27757,7,2,0,0,27757,27758,5,95,0,
	0,27758,27759,7,4,0,0,27759,27760,7,12,0,0,27760,27761,7,14,0,0,27761,27762,
	7,6,0,0,27762,27763,7,5,0,0,27763,27764,7,4,0,0,27764,27765,7,0,0,0,27765,
	27766,7,14,0,0,27766,27767,7,15,0,0,27767,27768,5,95,0,0,27768,27769,7,
	4,0,0,27769,27770,7,23,0,0,27770,4072,1,0,0,0,27771,27772,7,4,0,0,27772,
	27773,7,2,0,0,27773,27774,5,95,0,0,27774,27775,7,4,0,0,27775,27776,7,12,
	0,0,27776,27777,7,14,0,0,27777,27778,7,6,0,0,27778,4074,1,0,0,0,27779,27780,
	7,4,0,0,27780,27781,7,2,0,0,27781,27782,5,95,0,0,27782,27783,7,4,0,0,27783,
	27784,7,12,0,0,27784,27785,7,14,0,0,27785,27786,7,6,0,0,27786,27787,5,95,
	0,0,27787,27788,7,4,0,0,27788,27789,7,23,0,0,27789,4076,1,0,0,0,27790,27791,
	7,4,0,0,27791,27792,7,2,0,0,27792,4078,1,0,0,0,27793,27794,7,4,0,0,27794,
	27795,7,2,0,0,27795,27796,5,95,0,0,27796,27797,7,18,0,0,27797,27798,7,14,
	0,0,27798,27799,7,12,0,0,27799,27800,7,7,0,0,27800,27801,7,4,0,0,27801,
	27802,7,6,0,0,27802,27803,7,3,0,0,27803,27804,7,13,0,0,27804,27805,7,0,
	0,0,27805,27806,7,11,0,0,27806,4080,1,0,0,0,27807,27808,7,4,0,0,27808,27809,
	7,3,0,0,27809,27810,7,0,0,0,27810,27811,7,8,0,0,27811,27812,7,6,0,0,27812,
	4082,1,0,0,0,27813,27814,7,4,0,0,27814,27815,7,3,0,0,27815,27816,7,0,0,
	0,27816,27817,7,8,0,0,27817,27818,7,12,0,0,27818,27819,7,7,0,0,27819,27820,
	7,17,0,0,27820,4084,1,0,0,0,27821,27822,7,4,0,0,27822,27823,7,3,0,0,27823,
	27824,7,0,0,0,27824,27825,7,8,0,0,27825,27826,7,21,0,0,27826,27827,7,12,
	0,0,27827,27828,7,7,0,0,27828,27829,7,17,0,0,27829,4086,1,0,0,0,27830,27831,
	7,4,0,0,27831,27832,7,3,0,0,27832,27833,7,0,0,0,27833,27834,7,12,0,0,27834,
	27835,7,11,0,0,27835,27836,7,12,0,0,27836,27837,7,7,0,0,27837,27838,7,17,
	0,0,27838,4088,1,0,0,0,27839,27840,7,4,0,0,27840,27841,7,3,0,0,27841,27842,
	7,0,0,0,27842,27843,7,7,0,0,27843,27844,7,5,0,0,27844,27845,7,0,0,0,27845,
	27846,7,8,0,0,27846,27847,7,4,0,0,27847,27848,7,12,0,0,27848,27849,7,2,
	0,0,27849,27850,7,7,0,0,27850,4090,1,0,0,0,27851,27852,7,4,0,0,27852,27853,
	7,3,0,0,27853,27854,7,0,0,0,27854,27855,7,7,0,0,27855,27856,7,5,0,0,27856,
	27857,7,16,0,0,27857,27858,7,2,0,0,27858,27859,7,3,0,0,27859,27860,7,14,
	0,0,27860,4092,1,0,0,0,27861,27862,7,4,0,0,27862,27863,7,3,0,0,27863,27864,
	7,0,0,0,27864,27865,7,7,0,0,27865,27866,7,5,0,0,27866,27867,7,16,0,0,27867,
	27868,7,2,0,0,27868,27869,7,3,0,0,27869,27870,7,14,0,0,27870,27871,5,95,
	0,0,27871,27872,7,9,0,0,27872,27873,7,12,0,0,27873,27874,7,5,0,0,27874,
	27875,7,4,0,0,27875,27876,7,12,0,0,27876,27877,7,7,0,0,27877,27878,7,8,
	0,0,27878,27879,7,4,0,0,27879,27880,5,95,0,0,27880,27881,7,0,0,0,27881,
	27882,7,17,0,0,27882,27883,7,17,0,0,27883,4094,1,0,0,0,27884,27885,7,4,
	0,0,27885,27886,7,3,0,0,27886,27887,7,0,0,0,27887,27888,7,7,0,0,27888,27889,
	7,5,0,0,27889,27890,7,12,0,0,27890,27891,7,4,0,0,27891,27892,7,12,0,0,27892,
	27893,7,2,0,0,27893,27894,7,7,0,0,27894,27895,7,0,0,0,27895,27896,7,11,
	0,0,27896,4096,1,0,0,0,27897,27898,7,4,0,0,27898,27899,7,3,0,0,27899,27900,
	7,0,0,0,27900,27901,7,7,0,0,27901,27902,7,5,0,0,27902,27903,7,12,0,0,27903,
	27904,7,4,0,0,27904,27905,7,12,0,0,27905,27906,7,2,0,0,27906,27907,7,7,
	0,0,27907,4098,1,0,0,0,27908,27909,7,4,0,0,27909,27910,7,3,0,0,27910,27911,
	7,0,0,0,27911,27912,7,7,0,0,27912,27913,7,5,0,0,27913,27914,7,11,0,0,27914,
	27915,7,0,0,0,27915,27916,7,4,0,0,27916,27917,7,6,0,0,27917,4100,1,0,0,
	0,27918,27919,7,4,0,0,27919,27920,7,3,0,0,27920,27921,7,0,0,0,27921,27922,
	7,7,0,0,27922,27923,7,5,0,0,27923,27924,7,11,0,0,27924,27925,7,0,0,0,27925,
	27926,7,4,0,0,27926,27927,7,12,0,0,27927,27928,7,2,0,0,27928,27929,7,7,
	0,0,27929,4102,1,0,0,0,27930,27931,7,4,0,0,27931,27932,7,3,0,0,27932,27933,
	7,6,0,0,27933,27934,7,0,0,0,27934,27935,7,4,0,0,27935,4104,1,0,0,0,27936,
	27937,7,4,0,0,27937,27938,7,3,0,0,27938,27939,7,12,0,0,27939,27940,7,17,
	0,0,27940,27941,7,17,0,0,27941,27942,7,6,0,0,27942,27943,7,3,0,0,27943,
	27944,7,5,0,0,27944,4106,1,0,0,0,27945,27946,7,4,0,0,27946,27947,7,3,0,
	0,27947,27948,7,12,0,0,27948,27949,7,17,0,0,27949,27950,7,17,0,0,27950,
	27951,7,6,0,0,27951,27952,7,3,0,0,27952,4108,1,0,0,0,27953,27954,7,4,0,
	0,27954,27955,7,3,0,0,27955,27956,7,10,0,0,27956,27957,7,6,0,0,27957,4110,
	1,0,0,0,27958,27959,7,4,0,0,27959,27960,7,3,0,0,27960,27961,7,10,0,0,27961,
	27962,7,7,0,0,27962,27963,7,8,0,0,27963,27964,7,0,0,0,27964,27965,7,4,0,
	0,27965,27966,7,6,0,0,27966,4112,1,0,0,0,27967,27968,7,4,0,0,27968,27969,
	7,3,0,0,27969,27970,7,10,0,0,27970,27971,7,7,0,0,27971,27972,7,8,0,0,27972,
	4114,1,0,0,0,27973,27974,7,4,0,0,27974,27975,7,3,0,0,27975,27976,7,10,0,
	0,27976,27977,7,5,0,0,27977,27978,7,4,0,0,27978,27979,7,6,0,0,27979,27980,
	7,9,0,0,27980,4116,1,0,0,0,27981,27982,7,4,0,0,27982,27983,7,3,0,0,27983,
	27984,7,10,0,0,27984,27985,7,5,0,0,27985,27986,7,4,0,0,27986,4118,1,0,0,
	0,27987,27988,7,4,0,0,27988,27989,7,10,0,0,27989,27990,7,7,0,0,27990,27991,
	7,12,0,0,27991,27992,7,7,0,0,27992,27993,7,17,0,0,27993,4120,1,0,0,0,27994,
	27995,7,4,0,0,27995,27996,7,25,0,0,27996,4122,1,0,0,0,27997,27998,7,4,0,
	0,27998,27999,7,18,0,0,27999,28000,7,15,0,0,28000,28001,7,6,0,0,28001,28002,
	7,5,0,0,28002,4124,1,0,0,0,28003,28004,7,4,0,0,28004,28005,7,18,0,0,28005,
	28006,7,15,0,0,28006,28007,7,6,0,0,28007,4126,1,0,0,0,28008,28009,7,4,0,
	0,28009,28010,7,23,0,0,28010,28011,5,95,0,0,28011,28012,7,2,0,0,28012,28013,
	7,16,0,0,28013,28014,7,16,0,0,28014,28015,7,5,0,0,28015,28016,7,6,0,0,28016,
	28017,7,4,0,0,28017,4128,1,0,0,0,28018,28019,7,10,0,0,28019,28020,7,1,0,
	0,28020,28021,5,50,0,0,28021,4130,1,0,0,0,28022,28023,7,10,0,0,28023,28024,
	7,1,0,0,28024,28025,7,0,0,0,28025,4132,1,0,0,0,28026,28027,7,10,0,0,28027,
	28028,7,8,0,0,28028,28029,7,5,0,0,28029,28030,5,50,0,0,28030,4134,1,0,0,
	0,28031,28032,7,10,0,0,28032,28033,7,12,0,0,28033,28034,7,9,0,0,28034,4136,
	1,0,0,0,28035,28036,7,10,0,0,28036,28037,7,7,0,0,28037,28038,7,0,0,0,28038,
	28039,7,3,0,0,28039,28040,7,8,0,0,28040,28041,7,19,0,0,28041,28042,7,12,
	0,0,28042,28043,7,13,0,0,28043,28044,7,6,0,0,28044,28045,7,9,0,0,28045,
	4138,1,0,0,0,28046,28047,7,10,0,0,28047,28048,7,7,0,0,28048,28049,7,1,0,
	0,28049,28050,7,2,0,0,28050,28051,7,10,0,0,28051,28052,7,7,0,0,28052,28053,
	7,9,0,0,28053,28054,7,6,0,0,28054,28055,7,9,0,0,28055,4140,1,0,0,0,28056,
	28057,7,10,0,0,28057,28058,7,7,0,0,28058,28059,7,1,0,0,28059,28060,7,2,
	0,0,28060,28061,7,10,0,0,28061,28062,7,7,0,0,28062,28063,7,9,0,0,28063,
	4142,1,0,0,0,28064,28065,7,10,0,0,28065,28066,7,7,0,0,28066,28067,7,8,0,
	0,28067,28068,7,2,0,0,28068,28069,7,7,0,0,28069,28070,7,9,0,0,28070,28071,
	7,12,0,0,28071,28072,7,4,0,0,28072,28073,7,12,0,0,28073,28074,7,2,0,0,28074,
	28075,7,7,0,0,28075,28076,7,0,0,0,28076,28077,7,11,0,0,28077,4144,1,0,0,
	0,28078,28079,7,10,0,0,28079,28080,7,7,0,0,28080,28081,7,9,0,0,28081,28082,
	7,6,0,0,28082,28083,7,3,0,0,28083,4146,1,0,0,0,28084,28085,7,10,0,0,28085,
	28086,7,7,0,0,28086,28087,7,9,0,0,28087,28088,7,2,0,0,28088,4148,1,0,0,
	0,28089,28090,7,10,0,0,28090,28091,7,7,0,0,28091,28092,7,9,0,0,28092,28093,
	7,3,0,0,28093,28094,7,2,0,0,28094,28095,7,15,0,0,28095,4150,1,0,0,0,28096,
	28097,7,10,0,0,28097,28098,7,7,0,0,28098,28099,7,12,0,0,28099,28100,7,16,
	0,0,28100,28101,7,2,0,0,28101,28102,7,3,0,0,28102,28103,7,14,0,0,28103,
	4152,1,0,0,0,28104,28105,7,10,0,0,28105,28106,7,7,0,0,28106,28107,7,12,
	0,0,28107,28108,7,2,0,0,28108,28109,7,7,0,0,28109,4154,1,0,0,0,28110,28111,
	7,10,0,0,28111,28112,7,7,0,0,28112,28113,7,12,0,0,28113,28114,7,24,0,0,
	28114,28115,7,10,0,0,28115,28116,7,6,0,0,28116,4156,1,0,0,0,28117,28118,
	7,10,0,0,28118,28119,7,7,0,0,28119,28120,7,12,0,0,28120,28121,7,5,0,0,28121,
	28122,7,4,0,0,28122,28123,7,3,0,0,28123,4158,1,0,0,0,28124,28125,7,10,0,
	0,28125,28126,7,7,0,0,28126,28127,7,11,0,0,28127,28128,7,12,0,0,28128,28129,
	7,14,0,0,28129,28130,7,12,0,0,28130,28131,7,4,0,0,28131,28132,7,6,0,0,28132,
	28133,7,9,0,0,28133,4160,1,0,0,0,28134,28135,7,10,0,0,28135,28136,7,7,0,
	0,28136,28137,7,11,0,0,28137,28138,7,2,0,0,28138,28139,7,0,0,0,28139,28140,
	7,9,0,0,28140,4162,1,0,0,0,28141,28142,7,10,0,0,28142,28143,7,7,0,0,28143,
	28144,7,11,0,0,28144,28145,7,2,0,0,28145,28146,7,8,0,0,28146,28147,7,21,
	0,0,28147,4164,1,0,0,0,28148,28149,7,10,0,0,28149,28150,7,7,0,0,28150,28151,
	7,14,0,0,28151,28152,7,0,0,0,28152,28153,7,4,0,0,28153,28154,7,8,0,0,28154,
	28155,7,19,0,0,28155,28156,7,6,0,0,28156,28157,7,9,0,0,28157,4166,1,0,0,
	0,28158,28159,7,10,0,0,28159,28160,7,7,0,0,28160,28161,7,7,0,0,28161,28162,
	7,6,0,0,28162,28163,7,5,0,0,28163,28164,7,4,0,0,28164,28165,5,95,0,0,28165,
	28166,7,12,0,0,28166,28167,7,7,0,0,28167,28168,7,7,0,0,28168,28169,7,6,
	0,0,28169,28170,7,3,0,0,28170,28171,7,20,0,0,28171,28172,5,95,0,0,28172,
	28173,7,9,0,0,28173,28174,7,12,0,0,28174,28175,7,5,0,0,28175,28176,7,4,
	0,0,28176,28177,7,12,0,0,28177,28178,7,7,0,0,28178,28179,7,8,0,0,28179,
	28180,7,4,0,0,28180,28181,5,95,0,0,28181,28182,7,13,0,0,28182,28183,7,12,
	0,0,28183,28184,7,6,0,0,28184,28185,7,22,0,0,28185,4168,1,0,0,0,28186,28187,
	7,10,0,0,28187,28188,7,7,0,0,28188,28189,7,7,0,0,28189,28190,7,6,0,0,28190,
	28191,7,5,0,0,28191,28192,7,4,0,0,28192,28193,5,95,0,0,28193,28194,7,7,
	0,0,28194,28195,7,2,0,0,28195,28196,7,5,0,0,28196,28197,7,6,0,0,28197,28198,
	7,14,0,0,28198,28199,7,12,0,0,28199,28200,7,20,0,0,28200,28201,5,95,0,0,
	28201,28202,7,7,0,0,28202,28203,7,2,0,0,28203,28204,7,9,0,0,28204,28205,
	7,12,0,0,28205,28206,7,5,0,0,28206,28207,7,4,0,0,28207,28208,7,12,0,0,28208,
	28209,7,7,0,0,28209,28210,7,8,0,0,28210,28211,7,4,0,0,28211,28212,7,13,
	0,0,28212,28213,7,12,0,0,28213,28214,7,6,0,0,28214,28215,7,22,0,0,28215,
	4170,1,0,0,0,28216,28217,7,10,0,0,28217,28218,7,7,0,0,28218,28219,7,7,0,
	0,28219,28220,7,6,0,0,28220,28221,7,5,0,0,28221,28222,7,4,0,0,28222,28223,
	5,95,0,0,28223,28224,7,5,0,0,28224,28225,7,6,0,0,28225,28226,7,14,0,0,28226,
	28227,7,12,0,0,28227,28228,7,20,0,0,28228,28229,5,95,0,0,28229,28230,7,
	13,0,0,28230,28231,7,12,0,0,28231,28232,7,6,0,0,28232,28233,7,22,0,0,28233,
	4172,1,0,0,0,28234,28235,7,10,0,0,28235,28236,7,7,0,0,28236,28237,7,7,0,
	0,28237,28238,7,6,0,0,28238,28239,7,5,0,0,28239,28240,7,4,0,0,28240,4174,
	1,0,0,0,28241,28242,7,10,0,0,28242,28243,7,7,0,0,28243,28244,7,15,0,0,28244,
	28245,7,0,0,0,28245,28246,7,8,0,0,28246,28247,7,21,0,0,28247,28248,7,6,
	0,0,28248,28249,7,9,0,0,28249,4176,1,0,0,0,28250,28251,7,10,0,0,28251,28252,
	7,7,0,0,28252,28253,7,15,0,0,28253,28254,7,12,0,0,28254,28255,7,13,0,0,
	28255,28256,7,2,0,0,28256,28257,7,4,0,0,28257,4178,1,0,0,0,28258,28259,
	7,10,0,0,28259,28260,7,7,0,0,28260,28261,7,15,0,0,28261,28262,7,11,0,0,
	28262,28263,7,10,0,0,28263,28264,7,17,0,0,28264,4180,1,0,0,0,28265,28266,
	7,10,0,0,28266,28267,7,7,0,0,28267,28268,7,15,0,0,28268,28269,7,3,0,0,28269,
	28270,7,2,0,0,28270,28271,7,4,0,0,28271,28272,7,6,0,0,28272,28273,7,8,0,
	0,28273,28274,7,4,0,0,28274,28275,7,6,0,0,28275,28276,7,9,0,0,28276,4182,
	1,0,0,0,28277,28278,7,10,0,0,28278,28279,7,7,0,0,28279,28280,7,24,0,0,28280,
	28281,7,10,0,0,28281,28282,7,12,0,0,28282,28283,7,6,0,0,28283,28284,7,5,
	0,0,28284,28285,7,8,0,0,28285,28286,7,6,0,0,28286,4184,1,0,0,0,28287,28288,
	7,10,0,0,28288,28289,7,7,0,0,28289,28290,7,3,0,0,28290,28291,7,6,0,0,28291,
	28292,7,8,0,0,28292,28293,7,2,0,0,28293,28294,7,13,0,0,28294,28295,7,6,
	0,0,28295,28296,7,3,0,0,28296,28297,7,0,0,0,28297,28298,7,1,0,0,28298,28299,
	7,11,0,0,28299,28300,7,6,0,0,28300,4186,1,0,0,0,28301,28302,7,10,0,0,28302,
	28303,7,7,0,0,28303,28304,7,3,0,0,28304,28305,7,6,0,0,28305,28306,7,5,0,
	0,28306,28307,7,4,0,0,28307,28308,7,3,0,0,28308,28309,7,12,0,0,28309,28310,
	7,8,0,0,28310,28311,7,4,0,0,28311,28312,7,6,0,0,28312,28313,7,9,0,0,28313,
	4188,1,0,0,0,28314,28315,7,10,0,0,28315,28316,7,7,0,0,28316,28317,7,5,0,
	0,28317,28318,7,10,0,0,28318,28319,7,1,0,0,28319,28320,7,5,0,0,28320,28321,
	7,8,0,0,28321,28322,7,3,0,0,28322,28323,7,12,0,0,28323,28324,7,1,0,0,28324,
	28325,7,6,0,0,28325,4190,1,0,0,0,28326,28327,7,10,0,0,28327,28328,7,7,0,
	0,28328,28329,7,4,0,0,28329,28330,7,12,0,0,28330,28331,7,11,0,0,28331,4192,
	1,0,0,0,28332,28333,7,10,0,0,28333,28334,7,7,0,0,28334,28335,7,10,0,0,28335,
	28336,7,5,0,0,28336,28337,7,0,0,0,28337,28338,7,1,0,0,28338,28339,7,11,
	0,0,28339,28340,7,6,0,0,28340,4194,1,0,0,0,28341,28342,7,10,0,0,28342,28343,
	7,7,0,0,28343,28344,7,10,0,0,28344,28345,7,5,0,0,28345,28346,7,6,0,0,28346,
	28347,7,9,0,0,28347,4196,1,0,0,0,28348,28349,7,10,0,0,28349,28350,7,15,
	0,0,28350,28351,7,9,0,0,28351,28352,7,0,0,0,28352,28353,7,4,0,0,28353,28354,
	7,0,0,0,28354,28355,7,1,0,0,28355,28356,7,11,0,0,28356,28357,7,6,0,0,28357,
	4198,1,0,0,0,28358,28359,7,10,0,0,28359,28360,7,15,0,0,28360,28361,7,9,
	0,0,28361,28362,7,0,0,0,28362,28363,7,4,0,0,28363,28364,7,6,0,0,28364,28365,
	7,9,0,0,28365,4200,1,0,0,0,28366,28367,7,10,0,0,28367,28368,7,15,0,0,28368,
	28369,7,9,0,0,28369,28370,7,0,0,0,28370,28371,7,4,0,0,28371,28372,7,6,0,
	0,28372,4202,1,0,0,0,28373,28374,7,10,0,0,28374,28375,7,15,0,0,28375,28376,
	7,9,0,0,28376,28377,7,0,0,0,28377,28378,7,4,0,0,28378,28379,7,6,0,0,28379,
	28380,7,25,0,0,28380,28381,7,14,0,0,28381,28382,7,11,0,0,28382,4204,1,0,
	0,0,28383,28384,7,10,0,0,28384,28385,7,15,0,0,28385,28386,7,9,0,0,28386,
	28387,5,95,0,0,28387,28388,7,12,0,0,28388,28389,7,7,0,0,28389,28390,7,9,
	0,0,28390,28391,7,6,0,0,28391,28392,7,25,0,0,28392,28393,7,6,0,0,28393,
	28394,7,5,0,0,28394,4206,1,0,0,0,28395,28396,7,10,0,0,28396,28397,7,15,
	0,0,28397,28398,7,9,0,0,28398,28399,5,95,0,0,28399,28400,7,20,0,0,28400,
	28401,7,2,0,0,28401,28402,7,12,0,0,28402,28403,7,7,0,0,28403,28404,7,12,
	0,0,28404,28405,7,7,0,0,28405,28406,7,9,0,0,28406,28407,7,6,0,0,28407,28408,
	7,25,0,0,28408,4208,1,0,0,0,28409,28410,7,10,0,0,28410,28411,7,15,0,0,28411,
	28412,7,17,0,0,28412,28413,7,3,0,0,28413,28414,7,0,0,0,28414,28415,7,9,
	0,0,28415,28416,7,6,0,0,28416,4210,1,0,0,0,28417,28418,7,10,0,0,28418,28419,
	7,15,0,0,28419,28420,7,15,0,0,28420,28421,7,6,0,0,28421,28422,7,3,0,0,28422,
	4212,1,0,0,0,28423,28424,7,10,0,0,28424,28425,7,15,0,0,28425,28426,7,5,
	0,0,28426,28427,7,6,0,0,28427,28428,7,3,0,0,28428,28429,7,4,0,0,28429,4214,
	1,0,0,0,28430,28431,7,10,0,0,28431,28432,7,3,0,0,28432,28433,7,2,0,0,28433,
	28434,7,22,0,0,28434,28435,7,12,0,0,28435,28436,7,9,0,0,28436,4216,1,0,
	0,0,28437,28438,7,10,0,0,28438,28439,7,5,0,0,28439,28440,7,0,0,0,28440,
	28441,7,1,0,0,28441,28442,7,11,0,0,28442,28443,7,6,0,0,28443,4218,1,0,0,
	0,28444,28445,7,10,0,0,28445,28446,7,5,0,0,28446,28447,7,0,0,0,28447,28448,
	7,17,0,0,28448,28449,7,6,0,0,28449,4220,1,0,0,0,28450,28451,7,10,0,0,28451,
	28452,7,5,0,0,28452,28453,7,6,0,0,28453,28454,5,95,0,0,28454,28455,7,0,
	0,0,28455,28456,7,7,0,0,28456,28457,7,4,0,0,28457,28458,7,12,0,0,28458,
	4222,1,0,0,0,28459,28460,7,10,0,0,28460,28461,7,5,0,0,28461,28462,7,6,0,
	0,28462,28463,5,95,0,0,28463,28464,7,8,0,0,28464,28465,7,2,0,0,28465,28466,
	7,7,0,0,28466,28467,7,8,0,0,28467,28468,7,0,0,0,28468,28469,7,4,0,0,28469,
	4224,1,0,0,0,28470,28471,7,10,0,0,28471,28472,7,5,0,0,28472,28473,7,6,0,
	0,28473,28474,5,95,0,0,28474,28475,7,8,0,0,28475,28476,7,10,0,0,28476,28477,
	7,1,0,0,28477,28478,7,6,0,0,28478,4226,1,0,0,0,28479,28480,7,10,0,0,28480,
	28481,7,5,0,0,28481,28482,7,6,0,0,28482,28483,5,95,0,0,28483,28484,7,19,
	0,0,28484,28485,7,0,0,0,28485,28486,7,5,0,0,28486,28487,7,19,0,0,28487,
	28488,5,95,0,0,28488,28489,7,0,0,0,28489,28490,7,17,0,0,28490,28491,7,17,
	0,0,28491,28492,7,3,0,0,28492,28493,7,6,0,0,28493,28494,7,17,0,0,28494,
	28495,7,0,0,0,28495,28496,7,4,0,0,28496,28497,7,12,0,0,28497,28498,7,2,
	0,0,28498,28499,7,7,0,0,28499,4228,1,0,0,0,28500,28501,7,10,0,0,28501,28502,
	7,5,0,0,28502,28503,7,6,0,0,28503,28504,5,95,0,0,28504,28505,7,19,0,0,28505,
	28506,7,0,0,0,28506,28507,7,5,0,0,28507,28508,7,19,0,0,28508,28509,5,95,
	0,0,28509,28510,7,17,0,0,28510,28511,7,1,0,0,28511,28512,7,18,0,0,28512,
	28513,5,95,0,0,28513,28514,7,16,0,0,28514,28515,7,2,0,0,28515,28516,7,3,
	0,0,28516,28517,5,95,0,0,28517,28518,7,15,0,0,28518,28519,7,10,0,0,28519,
	28520,7,5,0,0,28520,28521,7,19,0,0,28521,28522,7,9,0,0,28522,28523,7,2,
	0,0,28523,28524,7,22,0,0,28524,28525,7,7,0,0,28525,4230,1,0,0,0,28526,28527,
	7,10,0,0,28527,28528,7,5,0,0,28528,28529,7,6,0,0,28529,28530,5,95,0,0,28530,
	28531,7,19,0,0,28531,28532,7,0,0,0,28532,28533,7,5,0,0,28533,28534,7,19,
	0,0,28534,4232,1,0,0,0,28535,28536,7,10,0,0,28536,28537,7,5,0,0,28537,28538,
	7,6,0,0,28538,28539,5,95,0,0,28539,28540,7,19,0,0,28540,28541,7,12,0,0,
	28541,28542,7,9,0,0,28542,28543,7,9,0,0,28543,28544,7,6,0,0,28544,28545,
	7,7,0,0,28545,28546,5,95,0,0,28546,28547,7,15,0,0,28547,28548,7,0,0,0,28548,
	28549,7,3,0,0,28549,28550,7,4,0,0,28550,28551,7,12,0,0,28551,28552,7,4,
	0,0,28552,28553,7,12,0,0,28553,28554,7,2,0,0,28554,28555,7,7,0,0,28555,
	28556,7,5,0,0,28556,4234,1,0,0,0,28557,28558,7,10,0,0,28558,28559,7,5,0,
	0,28559,28560,7,6,0,0,28560,28561,5,95,0,0,28561,28562,7,12,0,0,28562,28563,
	7,7,0,0,28563,28564,7,13,0,0,28564,28565,7,12,0,0,28565,28566,7,5,0,0,28566,
	28567,7,12,0,0,28567,28568,7,1,0,0,28568,28569,7,11,0,0,28569,28570,7,6,
	0,0,28570,28571,5,95,0,0,28571,28572,7,12,0,0,28572,28573,7,7,0,0,28573,
	28574,7,9,0,0,28574,28575,7,6,0,0,28575,28576,7,25,0,0,28576,28577,7,6,
	0,0,28577,28578,7,5,0,0,28578,4236,1,0,0,0,28579,28580,7,10,0,0,28580,28581,
	7,5,0,0,28581,28582,7,6,0,0,28582,28583,5,95,0,0,28583,28584,7,14,0,0,28584,
	28585,7,6,0,0,28585,28586,7,3,0,0,28586,28587,7,17,0,0,28587,28588,7,6,
	0,0,28588,28589,5,95,0,0,28589,28590,7,8,0,0,28590,28591,7,0,0,0,28591,
	28592,7,3,0,0,28592,28593,7,4,0,0,28593,28594,7,6,0,0,28594,28595,7,5,0,
	0,28595,28596,7,12,0,0,28596,28597,7,0,0,0,28597,28598,7,7,0,0,28598,4238,
	1,0,0,0,28599,28600,7,10,0,0,28600,28601,7,5,0,0,28601,28602,7,6,0,0,28602,
	28603,5,95,0,0,28603,28604,7,14,0,0,28604,28605,7,6,0,0,28605,28606,7,3,
	0,0,28606,28607,7,17,0,0,28607,28608,7,6,0,0,28608,4240,1,0,0,0,28609,28610,
	7,10,0,0,28610,28611,7,5,0,0,28611,28612,7,6,0,0,28612,28613,5,95,0,0,28613,
	28614,7,7,0,0,28614,28615,7,11,0,0,28615,4242,1,0,0,0,28616,28617,7,10,
	0,0,28617,28618,7,5,0,0,28618,28619,7,6,0,0,28619,28620,5,95,0,0,28620,
	28621,7,7,0,0,28621,28622,7,11,0,0,28622,28623,5,95,0,0,28623,28624,7,22,
	0,0,28624,28625,7,12,0,0,28625,28626,7,4,0,0,28626,28627,7,19,0,0,28627,
	28628,5,95,0,0,28628,28629,7,12,0,0,28629,28630,7,7,0,0,28630,28631,7,9,
	0,0,28631,28632,7,6,0,0,28632,28633,7,25,0,0,28633,4244,1,0,0,0,28634,28635,
	7,10,0,0,28635,28636,7,5,0,0,28636,28637,7,6,0,0,28637,28638,5,95,0,0,28638,
	28639,7,15,0,0,28639,28640,7,3,0,0,28640,28641,7,12,0,0,28641,28642,7,13,
	0,0,28642,28643,7,0,0,0,28643,28644,7,4,0,0,28644,28645,7,6,0,0,28645,28646,
	5,95,0,0,28646,28647,7,2,0,0,28647,28648,7,10,0,0,28648,28649,7,4,0,0,28649,
	28650,7,11,0,0,28650,28651,7,12,0,0,28651,28652,7,7,0,0,28652,28653,7,6,
	0,0,28653,28654,7,5,0,0,28654,4246,1,0,0,0,28655,28656,7,10,0,0,28656,28657,
	7,5,0,0,28657,28658,7,6,0,0,28658,28659,7,3,0,0,28659,28660,5,95,0,0,28660,
	28661,7,9,0,0,28661,28662,7,0,0,0,28662,28663,7,4,0,0,28663,28664,7,0,0,
	0,28664,4248,1,0,0,0,28665,28666,7,10,0,0,28666,28667,7,5,0,0,28667,28668,
	7,6,0,0,28668,28669,7,3,0,0,28669,28670,5,95,0,0,28670,28671,7,9,0,0,28671,
	28672,7,6,0,0,28672,28673,7,16,0,0,28673,28674,7,12,0,0,28674,28675,7,7,
	0,0,28675,28676,7,6,0,0,28676,28677,7,9,0,0,28677,4250,1,0,0,0,28678,28679,
	7,10,0,0,28679,28680,7,5,0,0,28680,28681,7,6,0,0,28681,28682,7,3,0,0,28682,
	28683,7,6,0,0,28683,28684,7,7,0,0,28684,28685,7,13,0,0,28685,4252,1,0,0,
	0,28686,28687,7,10,0,0,28687,28688,7,5,0,0,28688,28689,7,6,0,0,28689,28690,
	7,3,0,0,28690,28691,7,17,0,0,28691,28692,7,3,0,0,28692,28693,7,2,0,0,28693,
	28694,7,10,0,0,28694,28695,7,15,0,0,28695,4254,1,0,0,0,28696,28697,7,10,
	0,0,28697,28698,7,5,0,0,28698,28699,7,6,0,0,28699,28700,7,3,0,0,28700,28701,
	5,95,0,0,28701,28702,7,3,0,0,28702,28703,7,6,0,0,28703,28704,7,8,0,0,28704,
	28705,7,18,0,0,28705,28706,7,8,0,0,28706,28707,7,11,0,0,28707,28708,7,6,
	0,0,28708,28709,7,1,0,0,28709,28710,7,12,0,0,28710,28711,7,7,0,0,28711,
	4256,1,0,0,0,28712,28713,7,10,0,0,28713,28714,7,5,0,0,28714,28715,7,6,0,
	0,28715,28716,7,3,0,0,28716,28717,7,5,0,0,28717,4258,1,0,0,0,28718,28719,
	7,10,0,0,28719,28720,7,5,0,0,28720,28721,7,6,0,0,28721,28722,7,3,0,0,28722,
	28723,5,95,0,0,28723,28724,7,4,0,0,28724,28725,7,0,0,0,28725,28726,7,1,
	0,0,28726,28727,7,11,0,0,28727,28728,7,6,0,0,28728,28729,7,5,0,0,28729,
	28730,7,15,0,0,28730,28731,7,0,0,0,28731,28732,7,8,0,0,28732,28733,7,6,
	0,0,28733,28734,7,5,0,0,28734,4260,1,0,0,0,28735,28736,7,10,0,0,28736,28737,
	7,5,0,0,28737,28738,7,6,0,0,28738,28739,7,3,0,0,28739,4262,1,0,0,0,28740,
	28741,7,10,0,0,28741,28742,7,5,0,0,28742,28743,7,6,0,0,28743,28744,5,95,
	0,0,28744,28745,7,5,0,0,28745,28746,7,6,0,0,28746,28747,7,14,0,0,28747,
	28748,7,12,0,0,28748,4264,1,0,0,0,28749,28750,7,10,0,0,28750,28751,7,5,
	0,0,28751,28752,7,6,0,0,28752,28753,5,95,0,0,28753,28754,7,5,0,0,28754,
	28755,7,4,0,0,28755,28756,7,2,0,0,28756,28757,7,3,0,0,28757,28758,7,6,0,
	0,28758,28759,7,9,0,0,28759,28760,5,95,0,0,28760,28761,7,2,0,0,28761,28762,
	7,10,0,0,28762,28763,7,4,0,0,28763,28764,7,11,0,0,28764,28765,7,12,0,0,
	28765,28766,7,7,0,0,28766,28767,7,6,0,0,28767,28768,7,5,0,0,28768,4266,
	1,0,0,0,28769,28770,7,10,0,0,28770,28771,7,5,0,0,28771,28772,7,6,0,0,28772,
	28773,5,95,0,0,28773,28774,7,4,0,0,28774,28775,7,4,0,0,28775,28776,7,4,
	0,0,28776,28777,5,95,0,0,28777,28778,7,16,0,0,28778,28779,7,2,0,0,28779,
	28780,7,3,0,0,28780,28781,5,95,0,0,28781,28782,7,17,0,0,28782,28783,7,5,
	0,0,28783,28784,7,6,0,0,28784,28785,7,4,0,0,28785,28786,7,5,0,0,28786,4268,
	1,0,0,0,28787,28788,7,10,0,0,28788,28789,7,5,0,0,28789,28790,7,6,0,0,28790,
	4270,1,0,0,0,28791,28792,7,10,0,0,28792,28793,7,5,0,0,28793,28794,7,6,0,
	0,28794,28795,5,95,0,0,28795,28796,7,13,0,0,28796,28797,7,6,0,0,28797,28798,
	7,8,0,0,28798,28799,7,4,0,0,28799,28800,7,2,0,0,28800,28801,7,3,0,0,28801,
	28802,5,95,0,0,28802,28803,7,0,0,0,28803,28804,7,17,0,0,28804,28805,7,17,
	0,0,28805,28806,7,3,0,0,28806,28807,7,6,0,0,28807,28808,7,17,0,0,28808,
	28809,7,0,0,0,28809,28810,7,4,0,0,28810,28811,7,12,0,0,28811,28812,7,2,
	0,0,28812,28813,7,7,0,0,28813,4272,1,0,0,0,28814,28815,7,10,0,0,28815,28816,
	7,5,0,0,28816,28817,7,6,0,0,28817,28818,5,95,0,0,28818,28819,7,22,0,0,28819,
	28820,7,6,0,0,28820,28821,7,0,0,0,28821,28822,7,21,0,0,28822,28823,5,95,
	0,0,28823,28824,7,7,0,0,28824,28825,7,0,0,0,28825,28826,7,14,0,0,28826,
	28827,7,6,0,0,28827,28828,5,95,0,0,28828,28829,7,3,0,0,28829,28830,7,6,
	0,0,28830,28831,7,5,0,0,28831,28832,7,11,0,0,28832,4274,1,0,0,0,28833,28834,
	7,10,0,0,28834,28835,7,5,0,0,28835,28836,7,12,0,0,28836,28837,7,7,0,0,28837,
	28838,7,17,0,0,28838,28839,5,95,0,0,28839,28840,7,7,0,0,28840,28841,7,2,
	0,0,28841,28842,5,95,0,0,28842,28843,7,6,0,0,28843,28844,7,25,0,0,28844,
	28845,7,15,0,0,28845,28846,7,0,0,0,28846,28847,7,7,0,0,28847,28848,7,9,
	0,0,28848,4276,1,0,0,0,28849,28850,7,10,0,0,28850,28851,7,5,0,0,28851,28852,
	7,12,0,0,28852,28853,7,7,0,0,28853,28854,7,17,0,0,28854,4278,1,0,0,0,28855,
	28856,7,10,0,0,28856,28857,7,4,0,0,28857,28858,7,16,0,0,28858,28859,5,49,
	0,0,28859,28860,5,54,0,0,28860,28861,7,1,0,0,28861,28862,7,6,0,0,28862,
	4280,1,0,0,0,28863,28864,7,10,0,0,28864,28865,7,4,0,0,28865,28866,7,16,
	0,0,28866,28867,5,49,0,0,28867,28868,5,54,0,0,28868,28869,7,11,0,0,28869,
	28870,7,6,0,0,28870,4282,1,0,0,0,28871,28872,7,10,0,0,28872,28873,7,4,0,
	0,28873,28874,7,16,0,0,28874,28875,5,51,0,0,28875,28876,5,50,0,0,28876,
	4284,1,0,0,0,28877,28878,7,10,0,0,28878,28879,7,4,0,0,28879,28880,7,16,
	0,0,28880,28881,5,56,0,0,28881,4286,1,0,0,0,28882,28883,7,13,0,0,28883,
	28884,5,49,0,0,28884,4288,1,0,0,0,28885,28886,7,13,0,0,28886,28887,5,50,
	0,0,28887,4290,1,0,0,0,28888,28889,7,13,0,0,28889,28890,7,0,0,0,28890,28891,
	7,11,0,0,28891,28892,7,12,0,0,28892,28893,7,9,0,0,28893,28894,7,0,0,0,28894,
	28895,7,4,0,0,28895,28896,7,6,0,0,28896,4292,1,0,0,0,28897,28898,7,13,0,
	0,28898,28899,7,0,0,0,28899,28900,7,11,0,0,28900,28901,7,12,0,0,28901,28902,
	7,9,0,0,28902,28903,7,0,0,0,28903,28904,7,4,0,0,28904,28905,7,6,0,0,28905,
	28906,5,95,0,0,28906,28907,7,8,0,0,28907,28908,7,2,0,0,28908,28909,7,7,
	0,0,28909,28910,7,13,0,0,28910,28911,7,6,0,0,28911,28912,7,3,0,0,28912,
	28913,7,5,0,0,28913,28914,7,12,0,0,28914,28915,7,2,0,0,28915,28916,7,7,
	0,0,28916,4294,1,0,0,0,28917,28918,7,13,0,0,28918,28919,7,0,0,0,28919,28920,
	7,11,0,0,28920,28921,7,12,0,0,28921,28922,7,9,0,0,28922,28923,7,0,0,0,28923,
	28924,7,4,0,0,28924,28925,7,12,0,0,28925,28926,7,2,0,0,28926,28927,7,7,
	0,0,28927,4296,1,0,0,0,28928,28929,7,13,0,0,28929,28930,7,0,0,0,28930,28931,
	7,11,0,0,28931,28932,7,12,0,0,28932,28933,7,9,0,0,28933,28934,5,95,0,0,
	28934,28935,7,4,0,0,28935,28936,7,12,0,0,28936,28937,7,14,0,0,28937,28938,
	7,6,0,0,28938,28939,5,95,0,0,28939,28940,7,6,0,0,28940,28941,7,7,0,0,28941,
	28942,7,9,0,0,28942,4298,1,0,0,0,28943,28944,7,13,0,0,28944,28945,7,0,0,
	0,28945,28946,7,11,0,0,28946,28947,7,10,0,0,28947,28948,7,6,0,0,28948,28949,
	7,5,0,0,28949,4300,1,0,0,0,28950,28951,7,13,0,0,28951,28952,7,0,0,0,28952,
	28953,7,11,0,0,28953,28954,7,10,0,0,28954,28955,7,6,0,0,28955,4302,1,0,
	0,0,28956,28957,7,13,0,0,28957,28958,7,0,0,0,28958,28959,7,3,0,0,28959,
	28960,7,8,0,0,28960,28961,7,19,0,0,28961,28962,7,0,0,0,28962,28963,7,3,
	0,0,28963,28964,5,50,0,0,28964,4304,1,0,0,0,28965,28966,7,13,0,0,28966,
	28967,7,0,0,0,28967,28968,7,3,0,0,28968,28969,7,8,0,0,28969,28970,7,19,
	0,0,28970,28971,7,0,0,0,28971,28972,7,3,0,0,28972,4306,1,0,0,0,28973,28974,
	7,13,0,0,28974,28975,7,0,0,0,28975,28976,7,3,0,0,28976,28977,7,12,0,0,28977,
	28978,7,0,0,0,28978,28979,7,1,0,0,28979,28980,7,11,0,0,28980,28981,7,6,
	0,0,28981,4308,1,0,0,0,28982,28983,7,13,0,0,28983,28984,7,0,0,0,28984,28985,
	7,3,0,0,28985,28986,5,95,0,0,28986,28987,7,15,0,0,28987,28988,7,2,0,0,28988,
	28989,7,15,0,0,28989,4310,1,0,0,0,28990,28991,7,13,0,0,28991,28992,7,0,
	0,0,28992,28993,7,3,0,0,28993,28994,7,3,0,0,28994,28995,7,0,0,0,28995,28996,
	7,18,0,0,28996,28997,7,5,0,0,28997,4312,1,0,0,0,28998,28999,7,13,0,0,28999,
	29000,7,0,0,0,29000,29001,7,3,0,0,29001,29002,7,3,0,0,29002,29003,7,0,0,
	0,29003,29004,7,18,0,0,29004,4314,1,0,0,0,29005,29006,7,13,0,0,29006,29007,
	7,0,0,0,29007,29008,7,3,0,0,29008,29009,5,95,0,0,29009,29010,7,5,0,0,29010,
	29011,7,0,0,0,29011,29012,7,14,0,0,29012,29013,7,15,0,0,29013,4316,1,0,
	0,0,29014,29015,7,13,0,0,29015,29016,7,0,0,0,29016,29017,7,3,0,0,29017,
	29018,7,18,0,0,29018,29019,7,12,0,0,29019,29020,7,7,0,0,29020,29021,7,17,
	0,0,29021,4318,1,0,0,0,29022,29023,7,13,0,0,29023,29024,7,6,0,0,29024,29025,
	7,8,0,0,29025,29026,7,4,0,0,29026,29027,7,2,0,0,29027,29028,7,3,0,0,29028,
	29029,5,95,0,0,29029,29030,7,3,0,0,29030,29031,7,6,0,0,29031,29032,7,0,
	0,0,29032,29033,7,9,0,0,29033,29034,5,95,0,0,29034,29035,7,4,0,0,29035,
	29036,7,3,0,0,29036,29037,7,0,0,0,29037,29038,7,8,0,0,29038,29039,7,6,0,
	0,29039,4320,1,0,0,0,29040,29041,7,13,0,0,29041,29042,7,6,0,0,29042,29043,
	7,8,0,0,29043,29044,7,4,0,0,29044,29045,7,2,0,0,29045,29046,7,3,0,0,29046,
	29047,5,95,0,0,29047,29048,7,3,0,0,29048,29049,7,6,0,0,29049,29050,7,0,
	0,0,29050,29051,7,9,0,0,29051,4322,1,0,0,0,29052,29053,7,13,0,0,29053,29054,
	7,6,0,0,29054,29055,7,8,0,0,29055,29056,7,4,0,0,29056,29057,7,2,0,0,29057,
	29058,7,3,0,0,29058,29059,5,95,0,0,29059,29060,7,4,0,0,29060,29061,7,3,
	0,0,29061,29062,7,0,0,0,29062,29063,7,7,0,0,29063,29064,7,5,0,0,29064,29065,
	7,16,0,0,29065,29066,7,2,0,0,29066,29067,7,3,0,0,29067,29068,7,14,0,0,29068,
	29069,5,95,0,0,29069,29070,7,9,0,0,29070,29071,7,12,0,0,29071,29072,7,14,
	0,0,29072,29073,7,5,0,0,29073,4324,1,0,0,0,29074,29075,7,13,0,0,29075,29076,
	7,6,0,0,29076,29077,7,8,0,0,29077,29078,7,4,0,0,29078,29079,7,2,0,0,29079,
	29080,7,3,0,0,29080,29081,5,95,0,0,29081,29082,7,4,0,0,29082,29083,7,3,
	0,0,29083,29084,7,0,0,0,29084,29085,7,7,0,0,29085,29086,7,5,0,0,29086,29087,
	7,16,0,0,29087,29088,7,2,0,0,29088,29089,7,3,0,0,29089,29090,7,14,0,0,29090,
	29091,5,95,0,0,29091,29092,7,16,0,0,29092,29093,7,0,0,0,29093,29094,7,8,
	0,0,29094,29095,7,4,0,0,29095,4326,1,0,0,0,29096,29097,7,13,0,0,29097,29098,
	7,6,0,0,29098,29099,7,8,0,0,29099,29100,7,4,0,0,29100,29101,7,2,0,0,29101,
	29102,7,3,0,0,29102,29103,5,95,0,0,29103,29104,7,4,0,0,29104,29105,7,3,
	0,0,29105,29106,7,0,0,0,29106,29107,7,7,0,0,29107,29108,7,5,0,0,29108,29109,
	7,16,0,0,29109,29110,7,2,0,0,29110,29111,7,3,0,0,29111,29112,7,14,0,0,29112,
	4328,1,0,0,0,29113,29114,7,13,0,0,29114,29115,7,6,0,0,29115,29116,7,3,0,
	0,29116,29117,7,12,0,0,29117,29118,7,16,0,0,29118,29119,7,12,0,0,29119,
	29120,7,6,0,0,29120,29121,7,3,0,0,29121,4330,1,0,0,0,29122,29123,7,13,0,
	0,29123,29124,7,6,0,0,29124,29125,7,3,0,0,29125,29126,7,12,0,0,29126,29127,
	7,16,0,0,29127,29128,7,18,0,0,29128,4332,1,0,0,0,29129,29130,7,13,0,0,29130,
	29131,7,6,0,0,29131,29132,7,3,0,0,29132,29133,7,5,0,0,29133,29134,7,12,
	0,0,29134,29135,7,2,0,0,29135,29136,7,7,0,0,29136,29137,7,12,0,0,29137,
	29138,7,7,0,0,29138,29139,7,17,0,0,29139,4334,1,0,0,0,29140,29141,7,13,
	0,0,29141,29142,7,6,0,0,29142,29143,7,3,0,0,29143,29144,7,5,0,0,29144,29145,
	7,12,0,0,29145,29146,7,2,0,0,29146,29147,7,7,0,0,29147,29148,7,5,0,0,29148,
	29149,5,95,0,0,29149,29150,7,6,0,0,29150,29151,7,7,0,0,29151,29152,7,9,
	0,0,29152,29153,7,5,0,0,29153,29154,7,8,0,0,29154,29155,7,7,0,0,29155,4336,
	1,0,0,0,29156,29157,7,13,0,0,29157,29158,7,6,0,0,29158,29159,7,3,0,0,29159,
	29160,7,5,0,0,29160,29161,7,12,0,0,29161,29162,7,2,0,0,29162,29163,7,7,
	0,0,29163,29164,7,5,0,0,29164,29165,5,95,0,0,29165,29166,7,6,0,0,29166,
	29167,7,7,0,0,29167,29168,7,9,0,0,29168,29169,7,4,0,0,29169,29170,7,12,
	0,0,29170,29171,7,14,0,0,29171,29172,7,6,0,0,29172,4338,1,0,0,0,29173,29174,
	7,13,0,0,29174,29175,7,6,0,0,29175,29176,7,3,0,0,29176,29177,7,5,0,0,29177,
	29178,7,12,0,0,29178,29179,7,2,0,0,29179,29180,7,7,0,0,29180,29181,7,5,
	0,0,29181,29182,5,95,0,0,29182,29183,7,2,0,0,29183,29184,7,15,0,0,29184,
	29185,7,6,0,0,29185,29186,7,3,0,0,29186,29187,7,0,0,0,29187,29188,7,4,0,
	0,29188,29189,7,12,0,0,29189,29190,7,2,0,0,29190,29191,7,7,0,0,29191,4340,
	1,0,0,0,29192,29193,7,13,0,0,29193,29194,7,6,0,0,29194,29195,7,3,0,0,29195,
	29196,7,5,0,0,29196,29197,7,12,0,0,29197,29198,7,2,0,0,29198,29199,7,7,
	0,0,29199,29200,7,5,0,0,29200,29201,5,95,0,0,29201,29202,7,5,0,0,29202,
	29203,7,4,0,0,29203,29204,7,0,0,0,29204,29205,7,3,0,0,29205,29206,7,4,0,
	0,29206,29207,7,5,0,0,29207,29208,7,8,0,0,29208,29209,7,7,0,0,29209,4342,
	1,0,0,0,29210,29211,7,13,0,0,29211,29212,7,6,0,0,29212,29213,7,3,0,0,29213,
	29214,7,5,0,0,29214,29215,7,12,0,0,29215,29216,7,2,0,0,29216,29217,7,7,
	0,0,29217,29218,7,5,0,0,29218,29219,5,95,0,0,29219,29220,7,5,0,0,29220,
	29221,7,4,0,0,29221,29222,7,0,0,0,29222,29223,7,3,0,0,29223,29224,7,4,0,
	0,29224,29225,7,4,0,0,29225,29226,7,12,0,0,29226,29227,7,14,0,0,29227,29228,
	7,6,0,0,29228,4344,1,0,0,0,29229,29230,7,13,0,0,29230,29231,7,6,0,0,29231,
	29232,7,3,0,0,29232,29233,7,5,0,0,29233,29234,7,12,0,0,29234,29235,7,2,
	0,0,29235,29236,7,7,0,0,29236,29237,7,5,0,0,29237,4346,1,0,0,0,29238,29239,
	7,13,0,0,29239,29240,7,6,0,0,29240,29241,7,3,0,0,29241,29242,7,5,0,0,29242,
	29243,7,12,0,0,29243,29244,7,2,0,0,29244,29245,7,7,0,0,29245,29246,7,5,
	0,0,29246,29247,5,95,0,0,29247,29248,7,25,0,0,29248,29249,7,12,0,0,29249,
	29250,7,9,0,0,29250,4348,1,0,0,0,29251,29252,7,13,0,0,29252,29253,7,6,0,
	0,29253,29254,7,3,0,0,29254,29255,7,5,0,0,29255,29256,7,12,0,0,29256,29257,
	7,2,0,0,29257,29258,7,7,0,0,29258,4350,1,0,0,0,29259,29260,7,13,0,0,29260,
	29261,7,12,0,0,29261,29262,7,6,0,0,29262,29263,7,22,0,0,29263,4352,1,0,
	0,0,29264,29265,7,13,0,0,29265,29266,7,12,0,0,29266,29267,7,2,0,0,29267,
	29268,7,11,0,0,29268,29269,7,0,0,0,29269,29270,7,4,0,0,29270,29271,7,12,
	0,0,29271,29272,7,2,0,0,29272,29273,7,7,0,0,29273,4354,1,0,0,0,29274,29275,
	7,13,0,0,29275,29276,7,12,0,0,29276,29277,7,3,0,0,29277,29278,7,4,0,0,29278,
	29279,7,10,0,0,29279,29280,7,0,0,0,29280,29281,7,11,0,0,29281,4356,1,0,
	0,0,29282,29283,7,13,0,0,29283,29284,7,12,0,0,29284,29285,7,5,0,0,29285,
	29286,7,12,0,0,29286,29287,7,1,0,0,29287,29288,7,12,0,0,29288,29289,7,11,
	0,0,29289,29290,7,12,0,0,29290,29291,7,4,0,0,29291,29292,7,18,0,0,29292,
	4358,1,0,0,0,29293,29294,7,13,0,0,29294,29295,7,12,0,0,29295,29296,7,5,
	0,0,29296,29297,7,12,0,0,29297,29298,7,1,0,0,29298,29299,7,11,0,0,29299,
	29300,7,6,0,0,29300,4360,1,0,0,0,29301,29302,7,13,0,0,29302,29303,7,2,0,
	0,29303,29304,7,11,0,0,29304,29305,7,10,0,0,29305,29306,7,14,0,0,29306,
	29307,7,6,0,0,29307,4362,1,0,0,0,29308,29309,7,13,0,0,29309,29310,7,5,0,
	0,29310,29311,7,12,0,0,29311,29312,7,23,0,0,29312,29313,7,6,0,0,29313,4364,
	1,0,0,0,29314,29315,7,22,0,0,29315,29316,7,0,0,0,29316,29317,7,12,0,0,29317,
	29318,7,4,0,0,29318,4366,1,0,0,0,29319,29320,7,22,0,0,29320,29321,7,0,0,
	0,29321,29322,7,11,0,0,29322,29323,7,11,0,0,29323,29324,7,6,0,0,29324,29325,
	7,4,0,0,29325,4368,1,0,0,0,29326,29327,7,22,0,0,29327,29328,7,0,0,0,29328,
	29329,7,3,0,0,29329,29330,7,7,0,0,29330,29331,7,12,0,0,29331,29332,7,7,
	0,0,29332,29333,7,17,0,0,29333,4370,1,0,0,0,29334,29335,7,22,0,0,29335,
	29336,7,6,0,0,29336,29337,7,6,0,0,29337,29338,7,21,0,0,29338,29339,7,5,
	0,0,29339,4372,1,0,0,0,29340,29341,7,22,0,0,29341,29342,7,6,0,0,29342,29343,
	7,6,0,0,29343,29344,7,21,0,0,29344,4374,1,0,0,0,29345,29346,7,22,0,0,29346,
	29347,7,6,0,0,29347,29348,7,11,0,0,29348,29349,7,11,0,0,29349,29350,7,16,
	0,0,29350,29351,7,2,0,0,29351,29352,7,3,0,0,29352,29353,7,14,0,0,29353,
	29354,7,6,0,0,29354,29355,7,9,0,0,29355,4376,1,0,0,0,29356,29357,7,22,0,
	0,29357,29358,7,19,0,0,29358,29359,7,6,0,0,29359,29360,7,7,0,0,29360,29361,
	7,6,0,0,29361,29362,7,13,0,0,29362,29363,7,6,0,0,29363,29364,7,3,0,0,29364,
	4378,1,0,0,0,29365,29366,7,22,0,0,29366,29367,7,19,0,0,29367,29368,7,6,
	0,0,29368,29369,7,7,0,0,29369,4380,1,0,0,0,29370,29371,7,22,0,0,29371,29372,
	7,19,0,0,29372,29373,7,6,0,0,29373,29374,7,3,0,0,29374,29375,7,6,0,0,29375,
	4382,1,0,0,0,29376,29377,7,22,0,0,29377,29378,7,19,0,0,29378,29379,7,12,
	0,0,29379,29380,7,11,0,0,29380,29381,7,6,0,0,29381,4384,1,0,0,0,29382,29383,
	7,22,0,0,29383,29384,7,19,0,0,29384,29385,7,12,0,0,29385,29386,7,4,0,0,
	29386,29387,7,6,0,0,29387,29388,7,5,0,0,29388,29389,7,15,0,0,29389,29390,
	7,0,0,0,29390,29391,7,8,0,0,29391,29392,7,6,0,0,29392,4386,1,0,0,0,29393,
	29394,7,22,0,0,29394,29395,7,12,0,0,29395,29396,7,9,0,0,29396,29397,7,4,
	0,0,29397,29398,7,19,0,0,29398,29399,5,95,0,0,29399,29400,7,1,0,0,29400,
	29401,7,10,0,0,29401,29402,7,8,0,0,29402,29403,7,21,0,0,29403,29404,7,6,
	0,0,29404,29405,7,4,0,0,29405,4388,1,0,0,0,29406,29407,7,22,0,0,29407,29408,
	7,12,0,0,29408,29409,7,4,0,0,29409,29410,7,19,0,0,29410,29411,7,12,0,0,
	29411,29412,7,7,0,0,29412,4390,1,0,0,0,29413,29414,7,22,0,0,29414,29415,
	7,12,0,0,29415,29416,7,4,0,0,29416,29417,7,19,0,0,29417,29418,7,2,0,0,29418,
	29419,7,10,0,0,29419,29420,7,4,0,0,29420,4392,1,0,0,0,29421,29422,7,22,
	0,0,29422,29423,7,12,0,0,29423,29424,7,4,0,0,29424,29425,7,19,0,0,29425,
	29426,5,95,0,0,29426,29427,7,15,0,0,29427,29428,7,11,0,0,29428,29429,7,
	5,0,0,29429,29430,7,24,0,0,29430,29431,7,11,0,0,29431,4394,1,0,0,0,29432,
	29433,7,22,0,0,29433,29434,7,12,0,0,29434,29435,7,4,0,0,29435,29436,7,19,
	0,0,29436,4396,1,0,0,0,29437,29438,7,22,0,0,29438,29439,7,2,0,0,29439,29440,
	7,3,0,0,29440,29441,7,21,0,0,29441,4398,1,0,0,0,29442,29443,7,22,0,0,29443,
	29444,7,3,0,0,29444,29445,7,0,0,0,29445,29446,7,15,0,0,29446,29447,7,15,
	0,0,29447,29448,7,6,0,0,29448,29449,7,9,0,0,29449,4400,1,0,0,0,29450,29451,
	7,22,0,0,29451,29452,7,3,0,0,29452,29453,7,0,0,0,29453,29454,7,15,0,0,29454,
	29455,7,15,0,0,29455,29456,7,6,0,0,29456,29457,7,3,0,0,29457,4402,1,0,0,
	0,29458,29459,7,22,0,0,29459,29460,7,3,0,0,29460,29461,7,12,0,0,29461,29462,
	7,4,0,0,29462,29463,7,6,0,0,29463,4404,1,0,0,0,29464,29465,7,25,0,0,29465,
	29466,7,9,0,0,29466,29467,7,1,0,0,29467,29468,5,95,0,0,29468,29469,7,16,
	0,0,29469,29470,7,0,0,0,29470,29471,7,5,0,0,29471,29472,7,4,0,0,29472,29473,
	7,15,0,0,29473,29474,7,0,0,0,29474,29475,7,4,0,0,29475,29476,7,19,0,0,29476,
	29477,5,95,0,0,29477,29478,7,12,0,0,29478,29479,7,7,0,0,29479,29480,7,5,
	0,0,29480,29481,7,6,0,0,29481,29482,7,3,0,0,29482,29483,7,4,0,0,29483,4406,
	1,0,0,0,29484,29485,7,25,0,0,29485,29486,7,9,0,0,29486,29487,7,1,0,0,29487,
	4408,1,0,0,0,29488,29489,7,25,0,0,29489,29490,5,95,0,0,29490,29491,7,9,
	0,0,29491,29492,7,18,0,0,29492,29493,7,7,0,0,29493,29494,5,95,0,0,29494,
	29495,7,15,0,0,29495,29496,7,3,0,0,29496,29497,7,10,0,0,29497,29498,7,7,
	0,0,29498,29499,7,6,0,0,29499,4410,1,0,0,0,29500,29501,7,25,0,0,29501,29502,
	7,12,0,0,29502,29503,7,9,0,0,29503,4412,1,0,0,0,29504,29505,7,25,0,0,29505,
	29506,7,14,0,0,29506,29507,7,11,0,0,29507,29508,5,50,0,0,29508,29509,7,
	2,0,0,29509,29510,7,1,0,0,29510,29511,7,20,0,0,29511,29512,7,6,0,0,29512,
	29513,7,8,0,0,29513,29514,7,4,0,0,29514,4414,1,0,0,0,29515,29516,7,25,0,
	0,29516,29517,7,14,0,0,29517,29518,7,11,0,0,29518,29519,7,0,0,0,29519,29520,
	7,17,0,0,29520,29521,7,17,0,0,29521,4416,1,0,0,0,29522,29523,7,25,0,0,29523,
	29524,7,14,0,0,29524,29525,7,11,0,0,29525,29526,7,0,0,0,29526,29527,7,4,
	0,0,29527,29528,7,4,0,0,29528,29529,7,3,0,0,29529,29530,7,12,0,0,29530,
	29531,7,1,0,0,29531,29532,7,10,0,0,29532,29533,7,4,0,0,29533,29534,7,6,
	0,0,29534,29535,7,5,0,0,29535,4418,1,0,0,0,29536,29537,7,25,0,0,29537,29538,
	7,14,0,0,29538,29539,7,11,0,0,29539,29540,7,8,0,0,29540,29541,7,0,0,0,29541,
	29542,7,5,0,0,29542,29543,7,4,0,0,29543,4420,1,0,0,0,29544,29545,7,25,0,
	0,29545,29546,7,14,0,0,29546,29547,7,11,0,0,29547,29548,7,8,0,0,29548,29549,
	7,9,0,0,29549,29550,7,0,0,0,29550,29551,7,4,0,0,29551,29552,7,0,0,0,29552,
	4422,1,0,0,0,29553,29554,7,25,0,0,29554,29555,7,14,0,0,29555,29556,7,11,
	0,0,29556,29557,7,8,0,0,29557,29558,7,2,0,0,29558,29559,7,11,0,0,29559,
	29560,7,0,0,0,29560,29561,7,4,0,0,29561,29562,7,4,0,0,29562,29563,7,13,
	0,0,29563,29564,7,0,0,0,29564,29565,7,11,0,0,29565,4424,1,0,0,0,29566,29567,
	7,25,0,0,29567,29568,7,14,0,0,29568,29569,7,11,0,0,29569,29570,7,8,0,0,
	29570,29571,7,2,0,0,29571,29572,7,14,0,0,29572,29573,7,14,0,0,29573,29574,
	7,6,0,0,29574,29575,7,7,0,0,29575,29576,7,4,0,0,29576,4426,1,0,0,0,29577,
	29578,7,25,0,0,29578,29579,7,14,0,0,29579,29580,7,11,0,0,29580,29581,7,
	8,0,0,29581,29582,7,2,0,0,29582,29583,7,7,0,0,29583,29584,7,8,0,0,29584,
	29585,7,0,0,0,29585,29586,7,4,0,0,29586,4428,1,0,0,0,29587,29588,7,25,0,
	0,29588,29589,7,14,0,0,29589,29590,7,11,0,0,29590,29591,7,9,0,0,29591,29592,
	7,12,0,0,29592,29593,7,16,0,0,29593,29594,7,16,0,0,29594,4430,1,0,0,0,29595,
	29596,7,25,0,0,29596,29597,7,14,0,0,29597,29598,7,11,0,0,29598,29599,5,
	95,0,0,29599,29600,7,9,0,0,29600,29601,7,14,0,0,29601,29602,7,11,0,0,29602,
	29603,5,95,0,0,29603,29604,7,3,0,0,29604,29605,7,22,0,0,29605,29606,7,4,
	0,0,29606,29607,5,95,0,0,29607,29608,7,5,0,0,29608,29609,7,4,0,0,29609,
	29610,7,14,0,0,29610,29611,7,4,0,0,29611,4432,1,0,0,0,29612,29613,7,25,
	0,0,29613,29614,7,14,0,0,29614,29615,7,11,0,0,29615,29616,7,6,0,0,29616,
	29617,7,11,0,0,29617,29618,7,6,0,0,29618,29619,7,14,0,0,29619,29620,7,6,
	0,0,29620,29621,7,7,0,0,29621,29622,7,4,0,0,29622,4434,1,0,0,0,29623,29624,
	7,25,0,0,29624,29625,7,14,0,0,29625,29626,7,11,0,0,29626,29627,7,6,0,0,
	29627,29628,7,25,0,0,29628,29629,7,12,0,0,29629,29630,7,5,0,0,29630,29631,
	7,4,0,0,29631,29632,7,5,0,0,29632,29633,5,50,0,0,29633,4436,1,0,0,0,29634,
	29635,7,25,0,0,29635,29636,7,14,0,0,29636,29637,7,11,0,0,29637,29638,7,
	6,0,0,29638,29639,7,25,0,0,29639,29640,7,12,0,0,29640,29641,7,5,0,0,29641,
	29642,7,4,0,0,29642,29643,7,5,0,0,29643,4438,1,0,0,0,29644,29645,7,25,0,
	0,29645,29646,7,14,0,0,29646,29647,7,11,0,0,29647,29648,7,16,0,0,29648,
	29649,7,2,0,0,29649,29650,7,3,0,0,29650,29651,7,6,0,0,29651,29652,7,5,0,
	0,29652,29653,7,4,0,0,29653,4440,1,0,0,0,29654,29655,7,25,0,0,29655,29656,
	7,14,0,0,29656,29657,7,11,0,0,29657,29658,7,12,0,0,29658,29659,7,7,0,0,
	29659,29660,7,9,0,0,29660,29661,7,6,0,0,29661,29662,7,25,0,0,29662,4442,
	1,0,0,0,29663,29664,7,25,0,0,29664,29665,7,14,0,0,29665,29666,7,11,0,0,
	29666,29667,7,12,0,0,29667,29668,7,7,0,0,29668,29669,7,9,0,0,29669,29670,
	7,6,0,0,29670,29671,7,25,0,0,29671,29672,5,95,0,0,29672,29673,7,3,0,0,29673,
	29674,7,6,0,0,29674,29675,7,22,0,0,29675,29676,7,3,0,0,29676,29677,7,12,
	0,0,29677,29678,7,4,0,0,29678,29679,7,6,0,0,29679,29680,5,95,0,0,29680,
	29681,7,12,0,0,29681,29682,7,7,0,0,29682,29683,5,95,0,0,29683,29684,7,5,
	0,0,29684,29685,7,6,0,0,29685,29686,7,11,0,0,29686,29687,7,6,0,0,29687,
	29688,7,8,0,0,29688,29689,7,4,0,0,29689,4444,1,0,0,0,29690,29691,7,25,0,
	0,29691,29692,7,14,0,0,29692,29693,7,11,0,0,29693,29694,7,12,0,0,29694,
	29695,7,7,0,0,29695,29696,7,9,0,0,29696,29697,7,6,0,0,29697,29698,7,25,
	0,0,29698,29699,5,95,0,0,29699,29700,7,3,0,0,29700,29701,7,6,0,0,29701,
	29702,7,22,0,0,29702,29703,7,3,0,0,29703,29704,7,12,0,0,29704,29705,7,4,
	0,0,29705,29706,7,6,0,0,29706,4446,1,0,0,0,29707,29708,7,25,0,0,29708,29709,
	7,14,0,0,29709,29710,7,11,0,0,29710,29711,7,12,0,0,29711,29712,7,7,0,0,
	29712,29713,7,9,0,0,29713,29714,7,6,0,0,29714,29715,7,25,0,0,29715,29716,
	5,95,0,0,29716,29717,7,5,0,0,29717,29718,7,6,0,0,29718,29719,7,11,0,0,29719,
	29720,5,95,0,0,29720,29721,7,12,0,0,29721,29722,7,9,0,0,29722,29723,7,25,
	0,0,29723,29724,5,95,0,0,29724,29725,7,4,0,0,29725,29726,7,1,0,0,29726,
	29727,7,11,0,0,29727,4448,1,0,0,0,29728,29729,7,25,0,0,29729,29730,7,14,
	0,0,29730,29731,7,11,0,0,29731,29732,7,12,0,0,29732,29733,7,5,0,0,29733,
	29734,7,7,0,0,29734,29735,7,2,0,0,29735,29736,7,9,0,0,29736,29737,7,6,0,
	0,29737,4450,1,0,0,0,29738,29739,7,25,0,0,29739,29740,7,14,0,0,29740,29741,
	7,11,0,0,29741,29742,7,12,0,0,29742,29743,7,5,0,0,29743,29744,7,13,0,0,
	29744,29745,7,0,0,0,29745,29746,7,11,0,0,29746,29747,7,12,0,0,29747,29748,
	7,9,0,0,29748,4452,1,0,0,0,29749,29750,7,25,0,0,29750,29751,7,14,0,0,29751,
	29752,7,11,0,0,29752,29753,7,7,0,0,29753,29754,7,0,0,0,29754,29755,7,14,
	0,0,29755,29756,7,6,0,0,29756,29757,7,5,0,0,29757,29758,7,15,0,0,29758,
	29759,7,0,0,0,29759,29760,7,8,0,0,29760,29761,7,6,0,0,29761,29762,7,5,0,
	0,29762,4454,1,0,0,0,29763,29764,7,25,0,0,29764,29765,7,14,0,0,29765,29766,
	7,11,0,0,29766,29767,7,15,0,0,29767,29768,7,0,0,0,29768,29769,7,3,0,0,29769,
	29770,7,5,0,0,29770,29771,7,6,0,0,29771,4456,1,0,0,0,29772,29773,7,25,0,
	0,29773,29774,7,14,0,0,29774,29775,7,11,0,0,29775,29776,7,15,0,0,29776,
	29777,7,0,0,0,29777,29778,7,4,0,0,29778,29779,7,8,0,0,29779,29780,7,19,
	0,0,29780,4458,1,0,0,0,29781,29782,7,25,0,0,29782,29783,7,14,0,0,29783,
	29784,7,11,0,0,29784,29785,7,15,0,0,29785,29786,7,12,0,0,29786,4460,1,0,
	0,0,29787,29788,7,25,0,0,29788,29789,7,14,0,0,29789,29790,7,11,0,0,29790,
	29791,7,24,0,0,29791,29792,7,10,0,0,29792,29793,7,6,0,0,29793,29794,7,3,
	0,0,29794,29795,7,18,0,0,29795,29796,7,13,0,0,29796,29797,7,0,0,0,29797,
	29798,7,11,0,0,29798,4462,1,0,0,0,29799,29800,7,25,0,0,29800,29801,7,14,
	0,0,29801,29802,7,11,0,0,29802,29803,7,24,0,0,29803,29804,7,10,0,0,29804,
	29805,7,6,0,0,29805,29806,7,3,0,0,29806,29807,7,18,0,0,29807,4464,1,0,0,
	0,29808,29809,7,25,0,0,29809,29810,7,14,0,0,29810,29811,7,11,0,0,29811,
	29812,7,3,0,0,29812,29813,7,2,0,0,29813,29814,7,2,0,0,29814,29815,7,4,0,
	0,29815,4466,1,0,0,0,29816,29817,7,25,0,0,29817,29818,7,14,0,0,29818,29819,
	7,11,0,0,29819,29820,7,5,0,0,29820,29821,7,8,0,0,29821,29822,7,19,0,0,29822,
	29823,7,6,0,0,29823,29824,7,14,0,0,29824,29825,7,0,0,0,29825,4468,1,0,0,
	0,29826,29827,7,25,0,0,29827,29828,7,14,0,0,29828,29829,7,11,0,0,29829,
	29830,7,5,0,0,29830,29831,7,6,0,0,29831,29832,7,3,0,0,29832,29833,7,12,
	0,0,29833,29834,7,0,0,0,29834,29835,7,11,0,0,29835,29836,7,12,0,0,29836,
	29837,7,23,0,0,29837,29838,7,6,0,0,29838,4470,1,0,0,0,29839,29840,7,25,
	0,0,29840,29841,7,14,0,0,29841,29842,7,11,0,0,29842,29843,7,4,0,0,29843,
	29844,7,0,0,0,29844,29845,7,1,0,0,29845,29846,7,11,0,0,29846,29847,7,6,
	0,0,29847,4472,1,0,0,0,29848,29849,7,25,0,0,29849,29850,7,14,0,0,29850,
	29851,7,11,0,0,29851,29852,7,4,0,0,29852,29853,7,3,0,0,29853,29854,7,0,
	0,0,29854,29855,7,7,0,0,29855,29856,7,5,0,0,29856,29857,7,16,0,0,29857,
	29858,7,2,0,0,29858,29859,7,3,0,0,29859,29860,7,14,0,0,29860,29861,7,1,
	0,0,29861,29862,7,11,0,0,29862,29863,7,2,0,0,29863,29864,7,1,0,0,29864,
	4474,1,0,0,0,29865,29866,7,25,0,0,29866,29867,7,14,0,0,29867,29868,7,11,
	0,0,29868,29869,7,4,0,0,29869,29870,7,3,0,0,29870,29871,7,0,0,0,29871,29872,
	7,7,0,0,29872,29873,7,5,0,0,29873,29874,7,16,0,0,29874,29875,7,2,0,0,29875,
	29876,7,3,0,0,29876,29877,7,14,0,0,29877,4476,1,0,0,0,29878,29879,7,25,
	0,0,29879,29880,7,14,0,0,29880,29881,7,11,0,0,29881,29882,7,4,0,0,29882,
	29883,7,18,0,0,29883,29884,7,15,0,0,29884,29885,7,6,0,0,29885,4478,1,0,
	0,0,29886,29887,7,25,0,0,29887,29888,7,14,0,0,29888,29889,7,11,0,0,29889,
	4480,1,0,0,0,29890,29891,7,25,0,0,29891,29892,7,15,0,0,29892,29893,7,0,
	0,0,29893,29894,7,4,0,0,29894,29895,7,19,0,0,29895,29896,7,4,0,0,29896,
	29897,7,0,0,0,29897,29898,7,1,0,0,29898,29899,7,11,0,0,29899,29900,7,6,
	0,0,29900,4482,1,0,0,0,29901,29902,7,25,0,0,29902,29903,7,5,0,0,29903,29904,
	5,95,0,0,29904,29905,7,5,0,0,29905,29906,7,18,0,0,29906,29907,7,5,0,0,29907,
	29908,5,95,0,0,29908,29909,7,8,0,0,29909,29910,7,2,0,0,29910,29911,7,7,
	0,0,29911,29912,7,4,0,0,29912,29913,7,6,0,0,29913,29914,7,25,0,0,29914,
	29915,7,4,0,0,29915,4484,1,0,0,0,29916,29917,7,25,0,0,29917,29918,7,5,0,
	0,29918,4486,1,0,0,0,29919,29920,7,25,0,0,29920,29921,7,4,0,0,29921,29922,
	7,3,0,0,29922,29923,7,0,0,0,29923,29924,7,7,0,0,29924,29925,7,5,0,0,29925,
	29926,7,15,0,0,29926,29927,7,2,0,0,29927,29928,7,3,0,0,29928,29929,7,4,
	0,0,29929,4488,1,0,0,0,29930,29931,7,18,0,0,29931,29932,7,6,0,0,29932,29933,
	7,0,0,0,29933,29934,7,3,0,0,29934,29935,7,5,0,0,29935,4490,1,0,0,0,29936,
	29937,7,18,0,0,29937,29938,7,6,0,0,29938,29939,7,0,0,0,29939,29940,7,3,
	0,0,29940,4492,1,0,0,0,29941,29942,7,18,0,0,29942,29943,7,6,0,0,29943,29944,
	7,5,0,0,29944,4494,1,0,0,0,29945,29946,7,18,0,0,29946,29947,7,14,0,0,29947,
	29948,7,12,0,0,29948,29949,7,7,0,0,29949,29950,7,4,0,0,29950,29951,7,6,
	0,0,29951,29952,7,3,0,0,29952,29953,7,13,0,0,29953,29954,7,0,0,0,29954,
	29955,7,11,0,0,29955,29956,5,95,0,0,29956,29957,7,10,0,0,29957,29958,7,
	7,0,0,29958,29959,7,8,0,0,29959,29960,7,2,0,0,29960,29961,7,7,0,0,29961,
	29962,7,5,0,0,29962,29963,7,4,0,0,29963,29964,7,3,0,0,29964,29965,7,0,0,
	0,29965,29966,7,12,0,0,29966,29967,7,7,0,0,29967,29968,7,6,0,0,29968,29969,
	7,9,0,0,29969,4496,1,0,0,0,29970,29971,7,23,0,0,29971,29972,7,2,0,0,29972,
	29973,7,7,0,0,29973,29974,7,6,0,0,29974,29975,7,14,0,0,29975,29976,7,0,
	0,0,29976,29977,7,15,0,0,29977,4498,1,0,0,0,29978,29979,7,23,0,0,29979,
	29980,7,2,0,0,29980,29981,7,7,0,0,29981,29982,7,6,0,0,29982,4500,1,0,0,
	0,29983,29984,7,15,0,0,29984,29985,7,3,0,0,29985,29986,7,6,0,0,29986,29987,
	7,9,0,0,29987,29988,7,12,0,0,29988,29989,7,8,0,0,29989,29990,7,4,0,0,29990,
	29991,7,12,0,0,29991,29992,7,2,0,0,29992,29993,7,7,0,0,29993,4502,1,0,0,
	0,29994,29995,7,15,0,0,29995,29996,7,3,0,0,29996,29997,7,6,0,0,29997,29998,
	7,9,0,0,29998,29999,7,12,0,0,29999,30000,7,8,0,0,30000,30001,7,4,0,0,30001,
	30002,7,12,0,0,30002,30003,7,2,0,0,30003,30004,7,7,0,0,30004,30005,5,95,
	0,0,30005,30006,7,1,0,0,30006,30007,7,2,0,0,30007,30008,7,10,0,0,30008,
	30009,7,7,0,0,30009,30010,7,9,0,0,30010,30011,7,5,0,0,30011,4504,1,0,0,
	0,30012,30013,7,15,0,0,30013,30014,7,3,0,0,30014,30015,7,6,0,0,30015,30016,
	7,9,0,0,30016,30017,7,12,0,0,30017,30018,7,8,0,0,30018,30019,7,4,0,0,30019,
	30020,7,12,0,0,30020,30021,7,2,0,0,30021,30022,7,7,0,0,30022,30023,5,95,
	0,0,30023,30024,7,8,0,0,30024,30025,7,2,0,0,30025,30026,7,5,0,0,30026,30027,
	7,4,0,0,30027,4506,1,0,0,0,30028,30029,7,15,0,0,30029,30030,7,3,0,0,30030,
	30031,7,6,0,0,30031,30032,7,9,0,0,30032,30033,7,12,0,0,30033,30034,7,8,
	0,0,30034,30035,7,4,0,0,30035,30036,7,12,0,0,30036,30037,7,2,0,0,30037,
	30038,7,7,0,0,30038,30039,5,95,0,0,30039,30040,7,9,0,0,30040,30041,7,6,
	0,0,30041,30042,7,4,0,0,30042,30043,7,0,0,0,30043,30044,7,12,0,0,30044,
	30045,7,11,0,0,30045,30046,7,5,0,0,30046,4508,1,0,0,0,30047,30048,7,15,
	0,0,30048,30049,7,3,0,0,30049,30050,7,6,0,0,30050,30051,7,9,0,0,30051,30052,
	7,12,0,0,30052,30053,7,8,0,0,30053,30054,7,4,0,0,30054,30055,7,12,0,0,30055,
	30056,7,2,0,0,30056,30057,7,7,0,0,30057,30058,5,95,0,0,30058,30059,7,15,
	0,0,30059,30060,7,3,0,0,30060,30061,7,2,0,0,30061,30062,7,1,0,0,30062,30063,
	7,0,0,0,30063,30064,7,1,0,0,30064,30065,7,12,0,0,30065,30066,7,11,0,0,30066,
	30067,7,12,0,0,30067,30068,7,4,0,0,30068,30069,7,18,0,0,30069,4510,1,0,
	0,0,30070,30071,7,15,0,0,30071,30072,7,3,0,0,30072,30073,7,6,0,0,30073,
	30074,7,9,0,0,30074,30075,7,12,0,0,30075,30076,7,8,0,0,30076,30077,7,4,
	0,0,30077,30078,7,12,0,0,30078,30079,7,2,0,0,30079,30080,7,7,0,0,30080,
	30081,5,95,0,0,30081,30082,7,5,0,0,30082,30083,7,6,0,0,30083,30084,7,4,
	0,0,30084,4512,1,0,0,0,30085,30086,7,1,0,0,30086,30087,7,11,0,0,30087,30088,
	7,2,0,0,30088,30089,7,8,0,0,30089,30090,7,21,0,0,30090,30091,7,8,0,0,30091,
	30092,7,19,0,0,30092,30093,7,0,0,0,30093,30094,7,12,0,0,30094,30095,7,7,
	0,0,30095,4514,1,0,0,0,30096,30097,7,8,0,0,30097,30098,7,2,0,0,30098,30099,
	7,11,0,0,30099,30100,7,11,0,0,30100,30101,7,0,0,0,30101,30102,7,4,0,0,30102,
	30103,7,6,0,0,30103,4516,1,0,0,0,30104,30105,7,8,0,0,30105,30106,7,2,0,
	0,30106,30107,7,11,0,0,30107,30108,7,11,0,0,30108,30109,7,0,0,0,30109,30110,
	7,4,0,0,30110,30111,7,12,0,0,30111,30112,7,2,0,0,30112,30113,7,7,0,0,30113,
	4518,1,0,0,0,30114,30115,7,9,0,0,30115,30116,7,6,0,0,30116,30117,7,16,0,
	0,30117,30118,7,12,0,0,30118,30119,7,7,0,0,30119,30120,7,12,0,0,30120,30121,
	7,4,0,0,30121,30122,7,12,0,0,30122,30123,7,2,0,0,30123,30124,7,7,0,0,30124,
	4520,1,0,0,0,30125,30126,7,9,0,0,30126,30127,7,10,0,0,30127,30128,7,15,
	0,0,30128,30129,7,11,0,0,30129,30130,7,12,0,0,30130,30131,7,8,0,0,30131,
	30132,7,0,0,0,30132,30133,7,4,0,0,30133,30134,7,6,0,0,30134,30135,7,9,0,
	0,30135,4522,1,0,0,0,30136,30137,7,6,0,0,30137,30138,7,25,0,0,30138,30139,
	7,4,0,0,30139,30140,7,6,0,0,30140,30141,7,7,0,0,30141,30142,7,9,0,0,30142,
	30143,7,6,0,0,30143,30144,7,9,0,0,30144,4524,1,0,0,0,30145,30146,7,19,0,
	0,30146,30147,7,0,0,0,30147,30148,7,5,0,0,30148,30149,7,19,0,0,30149,30150,
	7,12,0,0,30150,30151,7,7,0,0,30151,30152,7,17,0,0,30152,4526,1,0,0,0,30153,
	30154,7,12,0,0,30154,30155,7,9,0,0,30155,30156,7,11,0,0,30156,30157,7,6,
	0,0,30157,4528,1,0,0,0,30158,30159,7,12,0,0,30159,30160,7,14,0,0,30160,
	30161,7,14,0,0,30161,30162,7,10,0,0,30162,30163,7,4,0,0,30163,30164,7,0,
	0,0,30164,30165,7,1,0,0,30165,30166,7,11,0,0,30166,30167,7,6,0,0,30167,
	4530,1,0,0,0,30168,30169,7,2,0,0,30169,30170,7,3,0,0,30170,30171,7,0,0,
	0,30171,30172,7,8,0,0,30172,30173,7,11,0,0,30173,30174,7,6,0,0,30174,30175,
	5,95,0,0,30175,30176,7,9,0,0,30176,30177,7,0,0,0,30177,30178,7,4,0,0,30178,
	30179,7,0,0,0,30179,30180,7,15,0,0,30180,30181,7,10,0,0,30181,30182,7,14,
	0,0,30182,30183,7,15,0,0,30183,4532,1,0,0,0,30184,30185,7,2,0,0,30185,30186,
	7,3,0,0,30186,30187,7,0,0,0,30187,30188,7,8,0,0,30188,30189,7,11,0,0,30189,
	30190,7,6,0,0,30190,30191,5,95,0,0,30191,30192,7,19,0,0,30192,30193,7,9,
	0,0,30193,30194,7,16,0,0,30194,30195,7,5,0,0,30195,4534,1,0,0,0,30196,30197,
	7,2,0,0,30197,30198,7,3,0,0,30198,30199,7,0,0,0,30199,30200,7,8,0,0,30200,
	30201,7,11,0,0,30201,30202,7,6,0,0,30202,30203,5,95,0,0,30203,30204,7,19,
	0,0,30204,30205,7,12,0,0,30205,30206,7,13,0,0,30206,30207,7,6,0,0,30207,
	4536,1,0,0,0,30208,30209,7,2,0,0,30209,30210,7,3,0,0,30210,30211,7,0,0,
	0,30211,30212,7,8,0,0,30212,30213,7,11,0,0,30213,30214,7,6,0,0,30214,30215,
	5,95,0,0,30215,30216,7,11,0,0,30216,30217,7,2,0,0,30217,30218,7,0,0,0,30218,
	30219,7,9,0,0,30219,30220,7,6,0,0,30220,30221,7,3,0,0,30221,4538,1,0,0,
	0,30222,30223,5,34,0,0,30223,30224,7,5,0,0,30224,30225,7,19,0,0,30225,30226,
	7,0,0,0,30226,30227,5,50,0,0,30227,30228,5,95,0,0,30228,30229,5,53,0,0,
	30229,30230,5,49,0,0,30230,30231,5,50,0,0,30231,30232,5,34,0,0,30232,4540,
	1,0,0,0,30233,30234,7,5,0,0,30234,30235,7,19,0,0,30235,30236,7,0,0,0,30236,
	30237,7,3,0,0,30237,30238,7,9,0,0,30238,30239,7,6,0,0,30239,30240,7,9,0,
	0,30240,4542,1,0,0,0,30241,30242,5,34,0,0,30242,30243,7,13,0,0,30243,30244,
	5,49,0,0,30244,30245,5,34,0,0,30245,4544,1,0,0,0,30246,30247,7,12,0,0,30247,
	30248,7,5,0,0,30248,30249,7,2,0,0,30249,30250,7,11,0,0,30250,30251,7,0,
	0,0,30251,30252,7,4,0,0,30252,30253,7,6,0,0,30253,4546,1,0,0,0,30254,30255,
	7,3,0,0,30255,30256,7,2,0,0,30256,30257,7,2,0,0,30257,30258,7,4,0,0,30258,
	4548,1,0,0,0,30259,30260,7,10,0,0,30260,30261,7,7,0,0,30261,30262,7,12,
	0,0,30262,30263,7,4,0,0,30263,30264,7,6,0,0,30264,4550,1,0,0,0,30265,30266,
	7,0,0,0,30266,30267,7,11,0,0,30267,30268,7,17,0,0,30268,30269,7,2,0,0,30269,
	30270,7,3,0,0,30270,30271,7,12,0,0,30271,30272,7,4,0,0,30272,30273,7,19,
	0,0,30273,30274,7,14,0,0,30274,4552,1,0,0,0,30275,30276,7,8,0,0,30276,30277,
	7,10,0,0,30277,30278,7,14,0,0,30278,30279,7,6,0,0,30279,30280,5,95,0,0,
	30280,30281,7,9,0,0,30281,30282,7,12,0,0,30282,30283,7,5,0,0,30283,30284,
	7,4,0,0,30284,4554,1,0,0,0,30285,30286,7,9,0,0,30286,30287,7,6,0,0,30287,
	30288,7,7,0,0,30288,30289,7,5,0,0,30289,30290,7,6,0,0,30290,30291,5,95,
	0,0,30291,30292,7,3,0,0,30292,30293,7,0,0,0,30293,30294,7,7,0,0,30294,30295,
	7,21,0,0,30295,4556,1,0,0,0,30296,30297,7,11,0,0,30297,30298,7,12,0,0,30298,
	30299,7,5,0,0,30299,30300,7,4,0,0,30300,30301,7,0,0,0,30301,30302,7,17,
	0,0,30302,30303,7,17,0,0,30303,4558,1,0,0,0,30304,30305,7,15,0,0,30305,
	30306,7,6,0,0,30306,30307,7,3,0,0,30307,30308,7,8,0,0,30308,30309,7,6,0,
	0,30309,30310,7,7,0,0,30310,30311,7,4,0,0,30311,30312,5,95,0,0,30312,30313,
	7,3,0,0,30313,30314,7,0,0,0,30314,30315,7,7,0,0,30315,30316,7,21,0,0,30316,
	4560,1,0,0,0,30317,30318,7,15,0,0,30318,30319,7,6,0,0,30319,30320,7,3,0,
	0,30320,30321,7,8,0,0,30321,30322,7,6,0,0,30322,30323,7,7,0,0,30323,30324,
	7,4,0,0,30324,30325,7,12,0,0,30325,30326,7,11,0,0,30326,30327,7,6,0,0,30327,
	30328,5,95,0,0,30328,30329,7,8,0,0,30329,30330,7,2,0,0,30330,30331,7,7,
	0,0,30331,30332,7,4,0,0,30332,4562,1,0,0,0,30333,30334,7,15,0,0,30334,30335,
	7,6,0,0,30335,30336,7,3,0,0,30336,30337,7,8,0,0,30337,30338,7,6,0,0,30338,
	30339,7,7,0,0,30339,30340,7,4,0,0,30340,30341,7,12,0,0,30341,30342,7,11,
	0,0,30342,30343,7,6,0,0,30343,30344,5,95,0,0,30344,30345,7,9,0,0,30345,
	30346,7,12,0,0,30346,30347,7,5,0,0,30347,30348,7,8,0,0,30348,4564,1,0,0,
	0,30349,30350,7,3,0,0,30350,30351,7,0,0,0,30351,30352,7,7,0,0,30352,30353,
	7,21,0,0,30353,4566,1,0,0,0,30354,30355,7,0,0,0,30355,30356,7,13,0,0,30356,
	30357,7,17,0,0,30357,4568,1,0,0,0,30358,30359,7,8,0,0,30359,30360,7,2,0,
	0,30360,30361,7,3,0,0,30361,30362,7,3,0,0,30362,4570,1,0,0,0,30363,30364,
	7,8,0,0,30364,30365,7,2,0,0,30365,30366,7,13,0,0,30366,30367,7,0,0,0,30367,
	30368,7,3,0,0,30368,30369,5,95,0,0,30369,4572,1,0,0,0,30370,30371,7,9,0,
	0,30371,30372,7,6,0,0,30372,30373,7,8,0,0,30373,30374,7,2,0,0,30374,30375,
	7,9,0,0,30375,30376,7,6,0,0,30376,4574,1,0,0,0,30377,30378,7,11,0,0,30378,
	30379,7,0,0,0,30379,30380,7,17,0,0,30380,4576,1,0,0,0,30381,30382,7,11,
	0,0,30382,30383,7,0,0,0,30383,30384,7,17,0,0,30384,30385,5,95,0,0,30385,
	30386,7,9,0,0,30386,30387,7,12,0,0,30387,30388,7,16,0,0,30388,30389,7,16,
	0,0,30389,4578,1,0,0,0,30390,30391,7,11,0,0,30391,30392,7,0,0,0,30392,30393,
	7,17,0,0,30393,30394,5,95,0,0,30394,30395,7,9,0,0,30395,30396,7,12,0,0,
	30396,30397,7,16,0,0,30397,30398,7,16,0,0,30398,30399,5,95,0,0,30399,30400,
	7,15,0,0,30400,30401,7,6,0,0,30401,30402,7,3,0,0,30402,30403,7,8,0,0,30403,
	30404,7,6,0,0,30404,30405,7,7,0,0,30405,30406,7,4,0,0,30406,4580,1,0,0,
	0,30407,30408,7,11,0,0,30408,30409,7,6,0,0,30409,30410,7,0,0,0,30410,30411,
	7,9,0,0,30411,4582,1,0,0,0,30412,30413,7,14,0,0,30413,30414,7,0,0,0,30414,
	30415,7,25,0,0,30415,4584,1,0,0,0,30416,30417,7,14,0,0,30417,30418,7,6,
	0,0,30418,30419,7,9,0,0,30419,30420,7,12,0,0,30420,30421,7,0,0,0,30421,
	30422,7,7,0,0,30422,4586,1,0,0,0,30423,30424,7,14,0,0,30424,30425,7,6,0,
	0,30425,30426,7,14,0,0,30426,30427,7,2,0,0,30427,30428,7,15,0,0,30428,30429,
	7,4,0,0,30429,30430,7,12,0,0,30430,30431,7,14,0,0,30431,30432,7,12,0,0,
	30432,30433,7,23,0,0,30433,30434,7,6,0,0,30434,4588,1,0,0,0,30435,30436,
	7,14,0,0,30436,30437,7,12,0,0,30437,30438,7,7,0,0,30438,4590,1,0,0,0,30439,
	30440,7,7,0,0,30440,30441,7,4,0,0,30441,30442,7,12,0,0,30442,30443,7,11,
	0,0,30443,30444,7,6,0,0,30444,4592,1,0,0,0,30445,30446,7,7,0,0,30446,30447,
	7,13,0,0,30447,30448,7,11,0,0,30448,4594,1,0,0,0,30449,30450,7,3,0,0,30450,
	30451,7,0,0,0,30451,30452,7,4,0,0,30452,30453,7,12,0,0,30453,30454,7,2,
	0,0,30454,30455,5,95,0,0,30455,30456,7,4,0,0,30456,30457,7,2,0,0,30457,
	30458,5,95,0,0,30458,30459,7,3,0,0,30459,30460,7,6,0,0,30460,30461,7,15,
	0,0,30461,30462,7,2,0,0,30462,30463,7,3,0,0,30463,30464,7,4,0,0,30464,4596,
	1,0,0,0,30465,30466,7,3,0,0,30466,30467,7,6,0,0,30467,30468,7,17,0,0,30468,
	30469,7,3,0,0,30469,30470,5,95,0,0,30470,4598,1,0,0,0,30471,30472,7,3,0,
	0,30472,30473,7,2,0,0,30473,30474,7,10,0,0,30474,30475,7,7,0,0,30475,30476,
	7,9,0,0,30476,4600,1,0,0,0,30477,30478,7,3,0,0,30478,30479,7,2,0,0,30479,
	30480,7,22,0,0,30480,30481,5,95,0,0,30481,30482,7,7,0,0,30482,30483,7,10,
	0,0,30483,30484,7,14,0,0,30484,30485,7,1,0,0,30485,30486,7,6,0,0,30486,
	30487,7,3,0,0,30487,4602,1,0,0,0,30488,30489,7,5,0,0,30489,30490,7,10,0,
	0,30490,30491,7,1,0,0,30491,30492,7,5,0,0,30492,30493,7,4,0,0,30493,30494,
	7,3,0,0,30494,4604,1,0,0,0,30495,30496,7,4,0,0,30496,30497,7,2,0,0,30497,
	30498,5,95,0,0,30498,30499,7,8,0,0,30499,30500,7,19,0,0,30500,30501,7,0,
	0,0,30501,30502,7,3,0,0,30502,4606,1,0,0,0,30503,30504,7,4,0,0,30504,30505,
	7,3,0,0,30505,30506,7,12,0,0,30506,30507,7,14,0,0,30507,4608,1,0,0,0,30508,
	30509,7,5,0,0,30509,30510,7,10,0,0,30510,30511,7,14,0,0,30511,4610,1,0,
	0,0,30512,30513,7,5,0,0,30513,30514,7,4,0,0,30514,30515,7,9,0,0,30515,30516,
	7,9,0,0,30516,30517,7,6,0,0,30517,30518,7,13,0,0,30518,4612,1,0,0,0,30519,
	30520,7,13,0,0,30520,30521,7,0,0,0,30521,30522,7,3,0,0,30522,30523,5,95,
	0,0,30523,4614,1,0,0,0,30524,30525,7,13,0,0,30525,30526,7,0,0,0,30526,30527,
	7,3,0,0,30527,30528,7,12,0,0,30528,30529,7,0,0,0,30529,30530,7,7,0,0,30530,
	30531,7,8,0,0,30531,30532,7,6,0,0,30532,4616,1,0,0,0,30533,30534,7,11,0,
	0,30534,30535,7,6,0,0,30535,30536,7,0,0,0,30536,30537,7,5,0,0,30537,30538,
	7,4,0,0,30538,4618,1,0,0,0,30539,30540,7,17,0,0,30540,30541,7,3,0,0,30541,
	30542,7,6,0,0,30542,30543,7,0,0,0,30543,30544,7,4,0,0,30544,30545,7,6,0,
	0,30545,30546,7,5,0,0,30546,30547,7,4,0,0,30547,4620,1,0,0,0,30548,30549,
	7,4,0,0,30549,30550,7,2,0,0,30550,30551,5,95,0,0,30551,30552,7,9,0,0,30552,
	30553,7,0,0,0,30553,30554,7,4,0,0,30554,30555,7,6,0,0,30555,4622,1,0,0,
	0,30556,30557,7,7,0,0,30557,30564,5,39,0,0,30558,30563,8,26,0,0,30559,30560,
	5,39,0,0,30560,30563,5,39,0,0,30561,30563,3,4737,2368,0,30562,30558,1,0,
	0,0,30562,30559,1,0,0,0,30562,30561,1,0,0,0,30563,30566,1,0,0,0,30564,30562,
	1,0,0,0,30564,30565,1,0,0,0,30565,30567,1,0,0,0,30566,30564,1,0,0,0,30567,
	30568,5,39,0,0,30568,4624,1,0,0,0,30569,30578,7,1,0,0,30570,30574,5,39,
	0,0,30571,30573,7,27,0,0,30572,30571,1,0,0,0,30573,30576,1,0,0,0,30574,
	30572,1,0,0,0,30574,30575,1,0,0,0,30575,30577,1,0,0,0,30576,30574,1,0,0,
	0,30577,30579,5,39,0,0,30578,30570,1,0,0,0,30579,30580,1,0,0,0,30580,30578,
	1,0,0,0,30580,30581,1,0,0,0,30581,4626,1,0,0,0,30582,30591,7,25,0,0,30583,
	30587,5,39,0,0,30584,30586,7,28,0,0,30585,30584,1,0,0,0,30586,30589,1,0,
	0,0,30587,30585,1,0,0,0,30587,30588,1,0,0,0,30588,30590,1,0,0,0,30589,30587,
	1,0,0,0,30590,30592,5,39,0,0,30591,30583,1,0,0,0,30592,30593,1,0,0,0,30593,
	30591,1,0,0,0,30593,30594,1,0,0,0,30594,4628,1,0,0,0,30595,30596,5,46,0,
	0,30596,30597,5,46,0,0,30597,4630,1,0,0,0,30598,30599,5,46,0,0,30599,4632,
	1,0,0,0,30600,30602,7,29,0,0,30601,30600,1,0,0,0,30602,30603,1,0,0,0,30603,
	30601,1,0,0,0,30603,30604,1,0,0,0,30604,4634,1,0,0,0,30605,30618,3,4735,
	2367,0,30606,30608,7,6,0,0,30607,30609,7,30,0,0,30608,30607,1,0,0,0,30608,
	30609,1,0,0,0,30609,30616,1,0,0,0,30610,30617,3,4735,2367,0,30611,30613,
	7,29,0,0,30612,30611,1,0,0,0,30613,30614,1,0,0,0,30614,30612,1,0,0,0,30614,
	30615,1,0,0,0,30615,30617,1,0,0,0,30616,30610,1,0,0,0,30616,30612,1,0,0,
	0,30617,30619,1,0,0,0,30618,30606,1,0,0,0,30618,30619,1,0,0,0,30619,30621,
	1,0,0,0,30620,30622,7,31,0,0,30621,30620,1,0,0,0,30621,30622,1,0,0,0,30622,
	4636,1,0,0,0,30623,30630,5,39,0,0,30624,30629,8,26,0,0,30625,30626,5,39,
	0,0,30626,30629,5,39,0,0,30627,30629,3,4737,2368,0,30628,30624,1,0,0,0,
	30628,30625,1,0,0,0,30628,30627,1,0,0,0,30629,30632,1,0,0,0,30630,30628,
	1,0,0,0,30630,30631,1,0,0,0,30631,30633,1,0,0,0,30632,30630,1,0,0,0,30633,
	30634,5,39,0,0,30634,4638,1,0,0,0,30635,30636,7,24,0,0,30636,30645,5,39,
	0,0,30637,30646,3,4641,2320,0,30638,30646,3,4643,2321,0,30639,30646,3,4645,
	2322,0,30640,30646,3,4647,2323,0,30641,30646,3,4649,2324,0,30642,30646,
	3,4651,2325,0,30643,30646,3,4653,2326,0,30644,30646,3,4655,2327,0,30645,
	30637,1,0,0,0,30645,30638,1,0,0,0,30645,30639,1,0,0,0,30645,30640,1,0,0,
	0,30645,30641,1,0,0,0,30645,30642,1,0,0,0,30645,30643,1,0,0,0,30645,30644,
	1,0,0,0,30646,30647,1,0,0,0,30647,30648,5,39,0,0,30648,30649,1,0,0,0,30649,
	30650,6,2319,0,0,30650,4640,1,0,0,0,30651,30655,5,60,0,0,30652,30654,9,
	0,0,0,30653,30652,1,0,0,0,30654,30657,1,0,0,0,30655,30656,1,0,0,0,30655,
	30653,1,0,0,0,30656,30658,1,0,0,0,30657,30655,1,0,0,0,30658,30659,5,62,
	0,0,30659,4642,1,0,0,0,30660,30664,5,123,0,0,30661,30663,9,0,0,0,30662,
	30661,1,0,0,0,30663,30666,1,0,0,0,30664,30665,1,0,0,0,30664,30662,1,0,0,
	0,30665,30667,1,0,0,0,30666,30664,1,0,0,0,30667,30668,5,125,0,0,30668,4644,
	1,0,0,0,30669,30673,5,91,0,0,30670,30672,9,0,0,0,30671,30670,1,0,0,0,30672,
	30675,1,0,0,0,30673,30674,1,0,0,0,30673,30671,1,0,0,0,30674,30676,1,0,0,
	0,30675,30673,1,0,0,0,30676,30677,5,93,0,0,30677,4646,1,0,0,0,30678,30682,
	5,40,0,0,30679,30681,9,0,0,0,30680,30679,1,0,0,0,30681,30684,1,0,0,0,30682,
	30683,1,0,0,0,30682,30680,1,0,0,0,30683,30685,1,0,0,0,30684,30682,1,0,0,
	0,30685,30686,5,41,0,0,30686,4648,1,0,0,0,30687,30691,5,33,0,0,30688,30690,
	9,0,0,0,30689,30688,1,0,0,0,30690,30693,1,0,0,0,30691,30692,1,0,0,0,30691,
	30689,1,0,0,0,30692,30694,1,0,0,0,30693,30691,1,0,0,0,30694,30695,5,33,
	0,0,30695,4650,1,0,0,0,30696,30700,5,35,0,0,30697,30699,9,0,0,0,30698,30697,
	1,0,0,0,30699,30702,1,0,0,0,30700,30701,1,0,0,0,30700,30698,1,0,0,0,30701,
	30703,1,0,0,0,30702,30700,1,0,0,0,30703,30704,5,35,0,0,30704,4652,1,0,0,
	0,30705,30709,5,39,0,0,30706,30708,9,0,0,0,30707,30706,1,0,0,0,30708,30711,
	1,0,0,0,30709,30710,1,0,0,0,30709,30707,1,0,0,0,30710,30712,1,0,0,0,30711,
	30709,1,0,0,0,30712,30713,5,39,0,0,30713,4654,1,0,0,0,30714,30718,5,34,
	0,0,30715,30717,9,0,0,0,30716,30715,1,0,0,0,30717,30720,1,0,0,0,30718,30719,
	1,0,0,0,30718,30716,1,0,0,0,30719,30721,1,0,0,0,30720,30718,1,0,0,0,30721,
	30722,5,34,0,0,30722,4656,1,0,0,0,30723,30727,5,34,0,0,30724,30728,8,32,
	0,0,30725,30726,5,34,0,0,30726,30728,5,34,0,0,30727,30724,1,0,0,0,30727,
	30725,1,0,0,0,30728,30729,1,0,0,0,30729,30727,1,0,0,0,30729,30730,1,0,0,
	0,30730,30731,1,0,0,0,30731,30732,5,34,0,0,30732,4658,1,0,0,0,30733,30734,
	5,37,0,0,30734,4660,1,0,0,0,30735,30736,5,38,0,0,30736,4662,1,0,0,0,30737,
	30738,5,40,0,0,30738,4664,1,0,0,0,30739,30740,5,41,0,0,30740,4666,1,0,0,
	0,30741,30742,5,42,0,0,30742,30743,5,42,0,0,30743,4668,1,0,0,0,30744,30745,
	5,42,0,0,30745,4670,1,0,0,0,30746,30747,5,43,0,0,30747,4672,1,0,0,0,30748,
	30749,5,45,0,0,30749,4674,1,0,0,0,30750,30751,5,44,0,0,30751,4676,1,0,0,
	0,30752,30753,5,47,0,0,30753,4678,1,0,0,0,30754,30755,5,64,0,0,30755,4680,
	1,0,0,0,30756,30757,5,58,0,0,30757,30758,5,61,0,0,30758,4682,1,0,0,0,30759,
	30760,5,35,0,0,30760,4684,1,0,0,0,30761,30762,5,39,0,0,30762,4686,1,0,0,
	0,30763,30764,5,58,0,0,30764,30769,3,4733,2366,0,30765,30768,3,4733,2366,
	0,30766,30768,7,33,0,0,30767,30765,1,0,0,0,30767,30766,1,0,0,0,30768,30771,
	1,0,0,0,30769,30767,1,0,0,0,30769,30770,1,0,0,0,30770,30778,1,0,0,0,30771,
	30769,1,0,0,0,30772,30773,5,58,0,0,30773,30778,3,4657,2328,0,30774,30775,
	5,58,0,0,30775,30778,3,4633,2316,0,30776,30778,3,4731,2365,0,30777,30763,
	1,0,0,0,30777,30772,1,0,0,0,30777,30774,1,0,0,0,30777,30776,1,0,0,0,30778,
	4688,1,0,0,0,30779,30780,5,33,0,0,30780,30788,5,61,0,0,30781,30782,5,60,
	0,0,30782,30788,5,62,0,0,30783,30784,5,94,0,0,30784,30788,5,61,0,0,30785,
	30786,5,126,0,0,30786,30788,5,61,0,0,30787,30779,1,0,0,0,30787,30781,1,
	0,0,0,30787,30783,1,0,0,0,30787,30785,1,0,0,0,30788,4690,1,0,0,0,30789,
	30790,5,94,0,0,30790,4692,1,0,0,0,30791,30792,5,126,0,0,30792,4694,1,0,
	0,0,30793,30794,5,33,0,0,30794,4696,1,0,0,0,30795,30796,5,62,0,0,30796,
	4698,1,0,0,0,30797,30798,5,60,0,0,30798,4700,1,0,0,0,30799,30800,5,58,0,
	0,30800,4702,1,0,0,0,30801,30802,5,59,0,0,30802,4704,1,0,0,0,30803,30804,
	5,124,0,0,30804,4706,1,0,0,0,30805,30806,5,61,0,0,30806,4708,1,0,0,0,30807,
	30808,5,91,0,0,30808,4710,1,0,0,0,30809,30810,5,93,0,0,30810,4712,1,0,0,
	0,30811,30812,5,95,0,0,30812,4714,1,0,0,0,30813,30814,5,45,0,0,30814,30815,
	5,45,0,0,30815,30819,1,0,0,0,30816,30818,8,34,0,0,30817,30816,1,0,0,0,30818,
	30821,1,0,0,0,30819,30817,1,0,0,0,30819,30820,1,0,0,0,30820,30822,1,0,0,
	0,30821,30819,1,0,0,0,30822,30823,3,4729,2364,0,30823,30824,1,0,0,0,30824,
	30825,6,2357,1,0,30825,4716,1,0,0,0,30826,30827,5,47,0,0,30827,30828,5,
	42,0,0,30828,30832,1,0,0,0,30829,30831,9,0,0,0,30830,30829,1,0,0,0,30831,
	30834,1,0,0,0,30832,30833,1,0,0,0,30832,30830,1,0,0,0,30833,30835,1,0,0,
	0,30834,30832,1,0,0,0,30835,30836,5,42,0,0,30836,30837,5,47,0,0,30837,30838,
	1,0,0,0,30838,30839,6,2358,1,0,30839,4718,1,0,0,0,30840,30841,7,3,0,0,30841,
	30842,7,6,0,0,30842,30843,7,14,0,0,30843,30844,1,0,0,0,30844,30848,4,2359,
	0,0,30845,30846,7,0,0,0,30846,30847,7,3,0,0,30847,30849,7,21,0,0,30848,
	30845,1,0,0,0,30848,30849,1,0,0,0,30849,30857,1,0,0,0,30850,30854,5,32,
	0,0,30851,30853,8,34,0,0,30852,30851,1,0,0,0,30853,30856,1,0,0,0,30854,
	30852,1,0,0,0,30854,30855,1,0,0,0,30855,30858,1,0,0,0,30856,30854,1,0,0,
	0,30857,30850,1,0,0,0,30857,30858,1,0,0,0,30858,30859,1,0,0,0,30859,30860,
	3,4729,2364,0,30860,30861,1,0,0,0,30861,30862,6,2359,1,0,30862,4720,1,0,
	0,0,30863,30864,7,15,0,0,30864,30865,7,3,0,0,30865,30866,7,2,0,0,30866,
	30867,1,0,0,0,30867,30871,4,2360,1,0,30868,30869,7,14,0,0,30869,30870,7,
	15,0,0,30870,30872,7,4,0,0,30871,30868,1,0,0,0,30871,30872,1,0,0,0,30872,
	30880,1,0,0,0,30873,30877,5,32,0,0,30874,30876,8,34,0,0,30875,30874,1,0,
	0,0,30876,30879,1,0,0,0,30877,30875,1,0,0,0,30877,30878,1,0,0,0,30878,30881,
	1,0,0,0,30879,30877,1,0,0,0,30880,30873,1,0,0,0,30880,30881,1,0,0,0,30881,
	30882,1,0,0,0,30882,30883,3,4729,2364,0,30883,4722,1,0,0,0,30884,30886,
	5,64,0,0,30885,30887,5,64,0,0,30886,30885,1,0,0,0,30886,30887,1,0,0,0,30887,
	4724,1,0,0,0,30888,30893,3,4733,2366,0,30889,30892,3,4733,2366,0,30890,
	30892,7,35,0,0,30891,30889,1,0,0,0,30891,30890,1,0,0,0,30892,30895,1,0,
	0,0,30893,30891,1,0,0,0,30893,30894,1,0,0,0,30894,4726,1,0,0,0,30895,30893,
	1,0,0,0,30896,30898,7,36,0,0,30897,30896,1,0,0,0,30898,30899,1,0,0,0,30899,
	30897,1,0,0,0,30899,30900,1,0,0,0,30900,30901,1,0,0,0,30901,30902,6,2363,
	1,0,30902,4728,1,0,0,0,30903,30906,3,4737,2368,0,30904,30906,5,0,0,1,30905,
	30903,1,0,0,0,30905,30904,1,0,0,0,30906,4730,1,0,0,0,30907,30908,5,63,0,
	0,30908,4732,1,0,0,0,30909,30910,7,37,0,0,30910,4734,1,0,0,0,30911,30913,
	3,4633,2316,0,30912,30911,1,0,0,0,30913,30916,1,0,0,0,30914,30912,1,0,0,
	0,30914,30915,1,0,0,0,30915,30918,1,0,0,0,30916,30914,1,0,0,0,30917,30919,
	5,46,0,0,30918,30917,1,0,0,0,30918,30919,1,0,0,0,30919,30921,1,0,0,0,30920,
	30922,3,4633,2316,0,30921,30920,1,0,0,0,30922,30923,1,0,0,0,30923,30921,
	1,0,0,0,30923,30924,1,0,0,0,30924,4736,1,0,0,0,30925,30927,5,13,0,0,30926,
	30925,1,0,0,0,30926,30927,1,0,0,0,30927,30928,1,0,0,0,30928,30929,5,10,
	0,0,30929,4738,1,0,0,0,30930,30931,7,38,0,0,30931,4740,1,0,0,0,54,0,18847,
	18860,18874,18912,18928,18943,22156,30562,30564,30574,30580,30587,30593,
	30603,30608,30614,30616,30618,30621,30628,30630,30645,30655,30664,30673,
	30682,30691,30700,30709,30718,30727,30729,30767,30769,30777,30787,30819,
	30832,30848,30854,30857,30871,30877,30880,30886,30891,30893,30899,30905,
	30914,30918,30923,30926,2,7,2319,0,0,1,0];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!PlSqlLexer.__ATN) {
			PlSqlLexer.__ATN = new ATNDeserializer().deserialize(PlSqlLexer._serializedATN);
		}

		return PlSqlLexer.__ATN;
	}


	static DecisionsToDFA = PlSqlLexer._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );
}