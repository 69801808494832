import React, { useState, useEffect, useImperativeHandle } from "react";
import { DownOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Spin,
  Select,
  Form,
  Dropdown,
  Menu,
  Button,
  Input,
  Space,
  Row,
  Col,
  Table,
  Popconfirm,
  InputNumber,
  Tooltip,
  message
} from "antd";
import { levelMenu, highRiskLevelMenu } from "src/constants/authManagement";
import * as _ from 'lodash';
import styles from './index.module.scss'
import classNames from "classnames";
import { BatchEditModal } from "./components/BatchEditModal";
import { useRequest, useSelector } from "src/hook";
import { saveHighRiskPermission } from "src/api";
import PermissionTooltip from "src/components/PermissionTooltip/PermissionTooltip";

interface dataSourceIprops {
  key: string,
  operationType: string;  // 操作类型
  operationName: string;  // 操作类型name
  objectType: string; // 对象类型
  objectTypeName: string;
  value: number | string; // 行数限制
  level: number | string;  // 高危提示
  highRiskLevel: string | number; // 高危等级
  childObjects?: string[]; // 下层级是否有自定义设置
  hasValues?: boolean;  // 为true时, 行数限制才可编辑
}
interface optionsIprops {
  label: string;
  value: string | number
}
interface operationsIProps {
  operation: string, // 操作
  value: string | number, // 影响行数
  level: string | number, // 高危提示
  highRiskLevel: string | number // 高危等级
}
interface operationsVoListIProps {
  objectType: string, 
  operations: operationsIProps[]
}
interface IProps {
  loading: boolean;
  permissionTemplate: any,
  [p: string]: any;
}


const HighRiskOperationSetting = (props: IProps, ref: any) => {
  const {
    loading,
    permissionTemplate,
    permissionlist,
    setEditPermissionList
  } = props

  const [form] = Form.useForm()

  const [objectTypeOptions, setObjectTypeOptions] = useState<optionsIprops[]>([])
  const [dataSource, setDataSource] = useState<any>([])
  const [filterDataSource, setFilterDataSource] = useState<any>([])
  const [selectValue, setSelectValue] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [filterValidate, setFilterValidate] = useState<{selectValue: string, searchValue: string}>({selectValue: '', searchValue: ''})
  const [selectedRows, setSelectedRows] = useState([])  // 批量操作，记录 operation、objectType
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [disableds, setDisableds] = useState<boolean>(true) // 批量按钮
  const [batchEditModalOpen, setBatchEditModalOpen] = useState<boolean>(false) // 批量编辑 modal
  const { selectedTreeNode } = useSelector(
    (state) => state.authorizationManagement
  );
  const {isOnlyRead} = permissionlist || {}

  const { run: runSaveHighRiskPermission } = useRequest(
    saveHighRiskPermission,
    {
      manual: true,
      onSuccess: () => {
        message.success('编辑成功')
      },
    }
  );

  let params: any = {
    dataSourceType: selectedTreeNode?.connection?.connectionType,
    groupId: null,
    nodePathWithTypeList: [selectedTreeNode?.nodePathWithType],
    // nodePathWithTypeList: showObject
    //   ? nodePathWithTypeList
    //   : [selectedTreeNode?.nodePathWithType],
  };
  if (selectedTreeNode?.nodeType === 'datasource') {
    params.dataSourceType = selectedTreeNode?.nodeName;
    params.nodePathWithTypeList = null;
  } else if (selectedTreeNode?.nodeType === 'group') {
    params = {
      ...params,
      groupId: selectedTreeNode?.id,
      dataSourceType: selectedTreeNode?.connection?.connectionType,
      nodePathWithTypeList: null,
    };
  }

  const updateFilterValidate = () => {
    setFilterValidate({selectValue: '', searchValue: ''})
    setSelectValue('')
  }

  // 暴露方法给父组件
  useImperativeHandle(ref, () => ({
    updateFilterValidate
  }));

 
  useEffect(() => {
    setSearchValue('')
    !selectValue && setSelectValue('all')
    setSelectedRowKeys([])
    setEditingKey('')
    setSelectedRows([])

    let objectTypeOption: optionsIprops[] = [{label: "全部", value: "all"}]
    let dataSourceTmp: dataSourceIprops[] = []
    permissionTemplate?.map((item: any, itemIndex: number) => {
      objectTypeOption.push({label: item?.objectTypeName, value: item?.objectType})
      
      //@ts-ignore
      item?.operations?.map((i: any, index: number) => {
        let dataSourceTmpItem: dataSourceIprops = {
          key: "",
          operationType: "",
          operationName: "",
          objectType: item?.objectType,
          objectTypeName: item?.objectTypeName,
          value: 0,
          level: 0,
          highRiskLevel: "",
          childObjects: [],
          hasValues: false
        }
        dataSourceTmpItem.key = i?.operation
        dataSourceTmpItem.operationType = `${i?.operation}(${i?.operationName})`
        dataSourceTmpItem.operationName = i?.operationName
        dataSourceTmpItem.value = i?.value === null ? '' :  i?.value// 行数限制
        dataSourceTmpItem.level = i?.level // 高危提示
        dataSourceTmpItem.highRiskLevel = i?.highRiskLevel // 高危等级
        dataSourceTmpItem.childObjects = i?.childObjects
        dataSourceTmpItem.hasValues = i?.hasValues  // 行数限制是否可编辑
        dataSourceTmp.push(dataSourceTmpItem)
      })
    } )
    setObjectTypeOptions(objectTypeOption)
    
    setDataSource(dataSourceTmp)
    setFilterDataSource([])
  },[permissionTemplate])

  // 搜索
  useEffect(() => {
    const { selectValue, searchValue } = filterValidate
    let newData: dataSourceIprops[] = []
    if (!(selectValue || searchValue) || ( selectValue === "all" && !searchValue)) {
      setFilterDataSource([])
    } else {
      if ((selectValue && selectValue!=="all") && searchValue) {
        newData = dataSource.filter((item: dataSourceIprops) => {
          const lowercaseKey = item.key.toLowerCase()
          const lowercaseSearchValue = searchValue.toLowerCase()
          
          return (
            (lowercaseKey.includes(lowercaseSearchValue) 
            || item.operationName.includes(searchValue)) 
            && item.objectType === selectValue
          )
        })
      } else if (selectValue && selectValue!=="all") {
        newData = dataSource.filter((item: dataSourceIprops) => item.objectType === selectValue)
      } else if (searchValue) {
        newData = dataSource.filter((item: dataSourceIprops) => {
          const lowercaseKey = item.key.toLowerCase()
          const lowercaseSearchValue = searchValue.toLowerCase()

          return (
            (lowercaseKey.includes(lowercaseSearchValue) || item.operationName.includes(searchValue)) 
          )
        })
      }
      setFilterDataSource(newData)
    }

    setSelectedRowKeys([])
  }, [dataSource, filterValidate])

  // 批量操作按钮
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setDisableds(false)
    } else {
      setDisableds(true)
    }
  }, [selectedRowKeys])

  const selectHandleChange = (value: string) => {
    let tmp = {...filterValidate}
    tmp.selectValue = value
    setFilterValidate(tmp)
    setSelectValue(value)
  }

  const searchHandleChange = (e: any) => {
    let tmp = {...filterValidate}
    let value = e.target.value
    tmp.searchValue = value
    setFilterValidate(tmp)
    setSearchValue(value)
  }

  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: dataSourceIprops) => record.key === editingKey;

  // 编辑
  const edit = (record: any & { key: string | number }) => {
    form.resetFields()
    setEditingKey(record.key);
  };

  // api 保存
  // isTableForm: Table中的Form.Item的Key 唯一，因此在字段名前加上前缀
  const save = async (
    newValues:any,
    record: any,
    isTableForm?: boolean,
  ) => {
    const operationsVoListJson: {[key: string]: operationsVoListIProps} = {}
    record.forEach((item: any) => {
      if (!operationsVoListJson[item.objectType]) {
        operationsVoListJson[item.objectType] = {
          objectType: item.objectType,
          operations: []
        }
      }

      operationsVoListJson[item.objectType].operations.push({
        operation: item.key,
        value: isTableForm? newValues[`${item.key}_value`] : newValues.value,
        level: isTableForm? newValues[`${item.key}_level`] : newValues.level,
        highRiskLevel: isTableForm? newValues[`${item.key}_highRiskLevel`] : newValues.highRiskLevel,
      })
      
    })
    const operationsVoList = Object.values(operationsVoListJson)

    try{
      //只有高危操作
      runSaveHighRiskPermission({
        ...params,
        ...{operationsVoList: operationsVoList},
      }).then((res) => {
        setEditingKey('')
        setSelectedRows([])
        setEditPermissionList(true)
        console.log('runSaveHighRiskPermission res :>> ', res);
      });
    } catch (errInfo) {
      console.log('Batch Edit Validate Failed save:', errInfo)
    }
  }
  // table operation 保存
  const hanldSave = async (record: any) => {
    const row = (await form.validateFields()) as dataSourceIprops
    save(row, [record], true)
  }

  // 清空
  const handleClear = (record?: any) => {
    if (record === undefined) {
      save({
        value: null,
        level: null,
        highRiskLevel: null,
      }, selectedRows)
    } else {
      save({
        value: null,
        level: null,
        highRiskLevel: null,
      }, [record])
    }
  }

  // 高危提示
  const getLevelStr = (index: number, options: optionsIprops[]) => {
    const foundItem = options.find((item: optionsIprops) => item.value === index)
    if (foundItem) {
      return foundItem.label
    } else {
      return ''
    }
  }

  // 批量编辑
  const batchEdit = () => {
    selectedRows.length > 0 && setBatchEditModalOpen(true)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRowKeys(selectedRowKeys)
      setSelectedRows(selectedRows)
    },
  };

  const handleFormatter = (value: any) => {
    if (value === '') {
      return value;
    }
    const numberValue = Number(value);
    return isNaN(numberValue) ? '' : Math.abs(numberValue);
  };
  
  const columns = [
    {
      title: '操作类型',
      dataIndex: 'operationType',
      key: 'operationType',
      width: 250,
      render: (text: string, record: any) => {
        return (
          (record?.childObjects.length > 0) ?
          <Tooltip placement="topLeft" title={`${record?.childObjects[0]}上存在自定义设置`}>
            <span style={{textDecoration: "line-through"}}>{text}</span>
          </Tooltip>
          : <span>{text}</span>
        )
      },
    },
    {
      title: '对象类型',
      dataIndex: 'objectTypeName',
      key: 'objectTypeName',
      width: 90,
    },
    {
      title: (
        <>
          行数限制&nbsp;
          <Tooltip title="行数限制仅对UID(update、insert、delete)生效">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'value',
      key: 'value',
      width: 110,
      render: (text: any, record: dataSourceIprops) => {
        return (
          <>
          {editingKey === record.key ? 
          (
            <Form.Item
              name={`${record.key}_value`}
              style={{ margin: 0 }}
              initialValue={text || ''}
            >
              <InputNumber
                formatter={handleFormatter}
                parser={value => Math.abs(Number(value))}
                min={0}
                size="middle"
                disabled={!record.hasValues}
              />
            </Form.Item>
          )
          :
          (<span>{record.hasValues ? text : ''}</span>)}
          </>
        )
      }
    },
    {
      title: '高危提示',
      dataIndex: 'level',
      key: 'level',
      width: 210,
      render: (text: number, record: dataSourceIprops) => {
        return (
          <>
          {editingKey === record.key ? 
          (
            <Form.Item
              name={`${record.key}_level`}
              style={{ margin: 0 }}
              initialValue={text}
            >
              <Select
                placeholder="请选择高危提示"
                allowClear
                bordered={false}
                options={levelMenu}
              />
            </Form.Item>
          )
          :
          (<span>{getLevelStr(text, levelMenu)}</span>)}
          </>
        )
      }
    },
    {
      title: '高危等级',
      dataIndex: 'highRiskLevel',
      key: 'highRiskLevel',
      width: 90,
      render: (text: any, record: dataSourceIprops) => {
                return (
          <>
          {editingKey === record.key ? 
          (
            <Form.Item
              name={`${record.key}_highRiskLevel`}
              style={{ margin: 0 }}
              initialValue={text}
            >
              <Select
                placeholder="请选择高危等级"
                style={{ width: 70 }}
                allowClear
                bordered={false}
                options={highRiskLevelMenu}
              />
            </Form.Item>
          )
          :
          (<span>{getLevelStr(text, highRiskLevelMenu)}</span>)}
          </>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: dataSourceIprops) => {
        const editable = isEditing(record);
        return (
          <>
          <PermissionTooltip
            permissionlist={permissionlist}
            width={200}
            title="手动授权"
          >
            {
              editable && dataSource.length >= 1 ? (
                <Button type="link"  onClick={() => hanldSave(record)}>
                  保存
                </Button>
              ) : (
                <Button type="link" disabled={editingKey !== '' || isOnlyRead} onClick={() => edit(record)}>
                  编辑
                </Button>
              )
            }
            <Popconfirm 
              title={
                <>
                <h3>确认清空?</h3>
                <span>清空将清空此操作在此层级下的所有设置</span>
                </>
              }
              placement="topRight"
              onConfirm={() => handleClear(record)}
            >
              <Button type="text" disabled={isOnlyRead}>清空</Button>
            </Popconfirm>
          </PermissionTooltip>
          </>
        )
      }
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="edit" onClick={() => batchEdit()}>批量编辑</Menu.Item>
      <Menu.Item disabled={disableds}>
        <Popconfirm
          title={
            <>
            <h3>确认清空?</h3>
            <span>清空将清空此操作在此层级下的所有设置</span>
            </>
          }
          placement="topRight"
          onConfirm={() => {
            handleClear()
          }}
        >
          <span>批量清空</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );


  // 批量编辑Modal 关闭
  const handleCancel = () => {
    setBatchEditModalOpen(false);
  };
  // 批量编辑 Modal的返回值
  const handleSubmit = (values: any) => {
    save(values, selectedRows)
    setBatchEditModalOpen(false)
  }


  return (
    <Spin spinning={loading}>
      <div className={styles.highRiskOperationSetting}>
        <Row>
          <Col span={10}>
          对象类型: &nbsp;&nbsp;<Select
              value={selectValue}
              placeholder="选择对象类型"
              style={{ width: 180 }}
              onChange={selectHandleChange}
              allowClear
              options={objectTypeOptions}
            />
          </Col>
          <Col span={14}>
            <div className={styles.rightCol}>
              <Input
                allowClear
                className={styles.searchInput}
                prefix={<SearchOutlined />}
                placeholder="搜索操作类型"
                value={searchValue}
                onChange={searchHandleChange}
              />
              <PermissionTooltip
                permissionlist={permissionlist}
                width={200}
                title="手动授权"
              >
                <Dropdown overlay={disableds || isOnlyRead? <></> : menu} className={isOnlyRead ? styles.dropDownDisabled : ''}>
                  <Button type="primary">
                    <Space>
                      批量操作
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </PermissionTooltip>
            </div>
          </Col>
        </Row>
        <Form form={form} >
          <Table
            className={classNames(styles.tableTop, styles.customTable)}
            dataSource={
              !(filterValidate.selectValue || filterValidate.searchValue) || 
              (filterValidate.selectValue === "all" && !filterValidate.searchValue) ?
              dataSource : filterDataSource
            }
            rowKey={(record) => record.key}
            columns={columns}
            pagination={false}
            bordered
            rowSelection={rowSelection}
            scroll={{ y: 550 }} // 设置垂直滚动
          />
        </Form>
      </div>
      {
        batchEditModalOpen && 
        <BatchEditModal
          visible={batchEditModalOpen}
          onCancel={handleCancel}
          onSubmit={handleSubmit} 
        />
      }
    </Spin>
  );
};

export default React.forwardRef(HighRiskOperationSetting);
