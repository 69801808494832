import React from "react";
import { Config, ConfigKey } from "./type";

interface IViewService {
  setView(view: JSX.Element): void;
  getView(): JSX.Element;
}

interface IDispatchViewService {
  addInstance(key: String, instance: InstanceType<typeof ViewServiceImpl>): void;

  apply(key: String): JSX.Element;
}

class ViewServiceImpl implements IViewService {
  view: JSX.Element;

  constructor(view?: JSX.Element) {
    this.view = view ?? <></>;
  }

  setView(view: JSX.Element): void {
    this.view = view ?? <></>;
  }

  getView() {
    return this.view;
  }
}

class DispatchViewService implements IDispatchViewService {
  instanceStore: Map<String, InstanceType<typeof ViewServiceImpl>>;

  constructor() {
    this.instanceStore = new Map();
    this.addInstance("default", new ViewServiceImpl());
  }

  /**
   * Initialize the DispatchViewService with configuration options
   * @param config 
   */
  init(config: Config) {
    Object.keys(config).forEach(key => {
      const viewServiceInstance = new ViewServiceImpl(config[key as ConfigKey]);
      this.addInstance(key, viewServiceInstance);
    });
  }

  /**
   * Add an instance of ViewServiceImpl to the instanceStore map
   * @param key 
   * @param instance 
   */
  addInstance(key: String, instance: InstanceType<typeof ViewServiceImpl>): void {
    this.instanceStore.set(key, instance);
  }

  /**
   * Return the TableView JSX.Element based on the provided key
   * @param key 
   * @returns 
   */
  apply(key: String): JSX.Element {
    if (this.instanceStore.has(key)) {
      const view = this.instanceStore.get(key)!;
      return view.getView();
    }
    return this.instanceStore.get("default")!.getView();
  }
}

export { DispatchViewService };

