import { Breadcrumb, Layout } from "antd";
import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { ResizableBox, ResizableProps } from "react-resizable";
import { ErrorBoundary, Iconfont, SimpleBreadcrumbs } from "src/components";
import "src/styles/layout.scss";
import { DataProtectionMain } from "./DataProtectionMain";
import { SdtTree } from "./SdtTree";
import styles from "./index.module.scss";
import { useSelector } from "src/hook";
import { getCurrentModulePermissionByUrl } from "src/util/utils";

const { Content } = Layout;
const ResizableBoxProps: ResizableProps = {
	axis: "x",
	width: 320,
	height: 0,
	minConstraints: [260, 0],
	maxConstraints: [600, 0],
};

const BREADCRUMB_ITEMS = [
	{
		title: "数据保护管理",
	},
	{
		title: "数据保护",
	},
];

export const DataProtection = () => {
  // 判断 该登录权限下,该模块 只读/可编辑
  const { permissionList } = useSelector((state) => state?.login)

	const ResizeHandle = (
		<div className={styles.resizeHandle}>
			<Iconfont type="icon-handle-8"></Iconfont>
		</div>
	);

  //模块权限查询
  const modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, 'DATA_PROTECT')
  },[JSON.stringify(permissionList)])

	return (
		<Layout className={styles.dataProtectionPageWrap}>
			<div className={styles.breadcrumbHeader}>
				<SimpleBreadcrumbs items={BREADCRUMB_ITEMS} />
			</div>
			<Layout className={styles.layout}>
				<ResizableBox
					className={styles.resizableBox}
					handle={ResizeHandle}
					{...ResizableBoxProps}
				>
					<div className={classnames(styles.leftWrap)}>
						<ErrorBoundary>
							<SdtTree permissionlist={modulePermissionObj}></SdtTree>
						</ErrorBoundary>
					</div>
				</ResizableBox>

				<Content className={styles.rightWrap}>
					<ErrorBoundary>
						<DataProtectionMain></DataProtectionMain>
					</ErrorBoundary>
				</Content>
			</Layout>
		</Layout>
	);
};
