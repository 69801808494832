import React, { useState, useEffect } from 'react'
import { message, Table } from 'antd'

import { useRequest } from 'src/hook'
import { getTextImportTaskList, getStartTask, getTextImportTaskNumber } from 'src/api'
import { LinkButton } from 'src/components'

const taskStatusMap = {
  FAILURE: '失败',
  SUCCESS: '成功',
  PROCESSING: '进行中',
  QUEUING: '队列中',
}

// 连接名 数据库名 模式 表名 导入文件名 状态 开始时间 结束时间
const BaseColumn = [
  {
    dataIndex: 'connectionName',
    title: '连接名',
    // width: 100,
    ellipsis: true,
  },
  {
    dataIndex: 'databaseName',
    title: '数据库名',
    width: 150,
  },
  {
    dataIndex: 'schemaName',
    title: '模式',
    width: 150,
  },
  {
    dataIndex: 'tableName',
    title: '表名',
    width: 150,
  },
  {
    dataIndex: 'fileName',
    title: '导入文件名',
  },
  {
    title: '文件读取进度',
    render: ({ importPercentage }: { importPercentage: number }) => {
      return <span>{(importPercentage * 100).toFixed(2)}%</span>
    },
    width: 100,
  },
  {
    title: '状态',
    render: ({ taskStatus }: { taskStatus: keyof typeof taskStatusMap }) =>
      taskStatus === 'FAILURE' ? (
        <div style={{ color: 'rgb(213, 96, 38)' }}>失败</div>
      ) : (
        taskStatusMap[taskStatus]
      ),
    width: 80,
    ellipsis: true,
  },
  {
    dataIndex: 'startTime',
    title: '开始时间',
    ellipsis: true,
  },
  {
    dataIndex: 'endTime',
    title: '结束时间'
  }
]

export default function TextImportTaskTable() {
  const [pageState, setPageState] = useState({
    pageNum: 1,
    pageSize: 10,
  })
  const { data, run: getTaskList } = useRequest(getTextImportTaskList, {
    manual: true,
  })

  useEffect(() => {
    getTaskList(pageState.pageNum, pageState.pageSize)
  }, [pageState, getTaskList])

  const { data: taskNumber, run: getTaskNumber } = useRequest(getTextImportTaskNumber, {
    manual: true,
  })

  useEffect(() => {
    getTaskNumber()
  }, [getTaskNumber])

  const {run: startTask} = useRequest(getStartTask, {
    manual: true,
    onSuccess: () => {
      message.info('继续导入开始')
      getTaskList(pageState.pageNum, pageState.pageSize)
      getTaskNumber()
    }
  })

  const extraColumn = [
    {
      title: '操作',
      width: 200,
      render: ({ taskStatus, taskId }: any) =>
        ['SUCCESS', 'FAILURE'].includes(taskStatus) && (
          <>
            {/* 继续导入条件判断 */}
            <LinkButton disabled={taskStatus === 'SUCCESS'} onClick={() => {
              // 需要刷新处理
              startTask(taskId)
            }}>继续导入</LinkButton>
            <LinkButton
              disabled={taskStatus === 'SUCCESS'}
              onClick={() =>
                window.open(`/export/importer/download/${taskId}`)
              }
              size="small"
            >
              日志下载
            </LinkButton>
          </>
        ),
    },
  ]

  return (
    <div>
      <Table
        dataSource={data}
        columns={[...BaseColumn, ...extraColumn]}
        size="small"
        rowKey="taskId"
        pagination={{
          ...pageState,
          onChange: (page, pageSize) => {
            setPageState({ pageNum: page, pageSize: pageSize || 10 })
          },
          total: taskNumber,
          showQuickJumper: true,
        }}
      ></Table>
    </div>
  )
}

