/**
 * 流程表单 数据库元素字段控件（不包括 FormItem）{数据订正单选}
 */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { TreeSelect } from 'antd'
import {
  getFlowConnectionList,
  getFlowConnectionNodes,
  FlowElementTreeNodeEntity,
} from 'src/api'
import { Iconfont } from 'src/components'
import type { FormFieldElementEntity } from 'src/types'
import type { LegacyDataNode } from 'rc-tree-select/lib/interface'
import styles from '../flowForm.module.scss'

/** 元素节点元信息 */
type ElementNodeProps = Pick<
  FlowElementTreeNodeEntity,
  | 'connectionId'
  | 'connectionType'
  | 'nodeName'
  | 'nodePath'
  | 'nodePathWithType'
  | 'nodeType'
  | 'hasChild'
>
interface ElementTreeSelectProps {
  value?: [FormFieldElementEntity]
  onChange?: (value: [FormFieldElementEntity]) => void
}

//此组件 流程- 数据订正专用 涉及太多业务逻辑 公用性0
export const ElementTreeSelectSingle: React.FC<ElementTreeSelectProps> =
  React.memo(({ value, onChange }) => {
    // tree select 受控属性 expandedKeys & loadedKeys
    const [treeExpandedKeys, setTreeExpandedKeys] = useState<React.Key[]>([])
    const [treeLoadedKeys, setTreeLoadedKeys] = useState<React.Key[]>([])
    // tree map, 记录元素树节点的引用
    const treeMapRef = useRef(new Map<string, LegacyDataNode>())

    // ! 后续后端应该从 cookie 获取用户信息，不从表单传参
    const { userId } = useSelector((state) => state.login.userInfo)

    const mapDataToTreeNode = useCallback((data: FlowElementTreeNodeEntity) => {
      // 从 data 中解构出需要的参数
      const {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      } = data
      const nodeProps: ElementNodeProps = {
        connectionId,
        connectionType,
        nodeName,
        nodePath,
        nodePathWithType,
        nodeType,
        hasChild,
      }

      const treeNode = {
        key: nodePath,
        value: nodePath,
        title: nodeName,
        icon: <Iconfont type={`icon-${nodeType}`} />,
        props: nodeProps,
        isLeaf: !hasChild,
      }
      treeMapRef.current.set(nodePath, treeNode)
      return treeNode
    }, [])

    // 获取元素树连接层级
    const {
      data: root,
      run: fetchConnections,
      mutate: setRoot,
    } = useRequest(getFlowConnectionList, {
      manual: true,
      formatResult: (data) => {
        // 构造与子节点结构统一的连接节点
        const root = data.map((node) => {
          const {
            connectionId,
            nodeName,
            nodeType,
            nodePath,
            nodePathWithType,
            connectionType,
            hasChild,
          } = node
          const icon = `icon-connection-${connectionType}`
          const nodeProps: ElementNodeProps = {
            connectionId,
            connectionType,
            nodePath,
            nodePathWithType,
            nodeType,
            nodeName,
            hasChild,
          }
          const treeNode = {
            key: nodePath,
            value: nodePath,
            title: nodeName,
            icon: <Iconfont type={icon} />,
            props: nodeProps,
            selectable: false,
            isLeaf: !hasChild,
          }
          treeMapRef.current.set(nodePath, treeNode)
          return treeNode
        })
        return root
      },
    })
    useEffect(() => {
      if (userId) {
        fetchConnections({ flowType: 'dataCorrection', userId })
      }
    }, [fetchConnections, userId])

    // 获取数据库元素节点子列表
    const { run: loadChildren } = useRequest(getFlowConnectionNodes, {
      manual: true,
      fetchKey: (params) => params.nodePath,
      formatResult: (data) => data.map(mapDataToTreeNode),
    })

    const labeledValue = useMemo(() => {
      const element = value?.[0]
      if (element) {
        return {
          value: element.nodePath,
          label: element.nodeName,
        }
      }
    }, [value])

    return (
      <TreeSelect
        dropdownClassName={styles.treeSelectDropdown}
        treeData={root}
        // value={labeledValue}
        loadData={async (node) => {
          const key = node.key as string
          const props = node.props as ElementNodeProps
          try {
            const children = await loadChildren({
              ...props,
              flowType: 'dataCorrection',
              userId,
            })
            const targetNode = treeMapRef.current.get(key)
            if (!targetNode) return
            targetNode.children = children
            setRoot((root) => [...root])
            // 懒加载成功，加入 loadedKeys
            setTreeLoadedKeys((keys) => [...keys, key])
          } catch {
            // 懒加载失败，自动收起节点
            setTreeExpandedKeys((keys) => keys.filter((el) => el !== key))
          }
        }}
        value={labeledValue}
        onChange={(cvalue) => {
          if (!cvalue) {
            onChange?.(cvalue)
          }
          const treeMap = treeMapRef.current
          // 过滤掉不存在的节点
          const targetNode = treeMap.get(cvalue?.value)
          if (!targetNode) return
          const { hasChild, ...elementValue } =
            targetNode.props as ElementNodeProps
          onChange?.([elementValue])
        }}
        treeExpandedKeys={treeExpandedKeys}
        onTreeExpand={setTreeExpandedKeys}
        treeLoadedKeys={treeLoadedKeys}
        showSearch
        treeNodeFilterProp="title"
        treeIcon
        allowClear
        placeholder="请选择数据库元素"
        // 为了在回填 元素树选中节点 时，在相关节点没有加载时能给出正确的渲染
        labelInValue={true}
      />
    )
  })
