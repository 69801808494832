import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  ReactNode,
} from 'react'
import { ICellRendererParams } from '@ag-grid-community/core'
import styles from './ColumnSelectEditor.module.scss'
import { Select, Switch } from 'antd'
import { IIndexColumnOrder } from 'src/types'

interface IColumnSelectEditorProps extends ICellRendererParams {
  values: string
  columnsOptions: string[]
}

const formatterValue = (value: string) => {
  if (!value) return []
  return value.split(',')
}

export const ColumnSelectEditor = forwardRef(
  (props: IColumnSelectEditorProps, ref) => {
    const [value, setValue] = useState<string[]>([])

    const { data, colDef, columnsOptions = [] } = props
    useEffect(() => {
      const originValue = formatterValue(data[colDef?.field || ''])
      setValue(originValue)
    }, [colDef, data])

    const [editing, setEditing] = useState(true)
    const [order, setOrder] = useState<IIndexColumnOrder>('ASC')
    const refContainer = useRef(null)

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value.join(',')
        },
        isPopup() {
          return true
        },
        afterGuiAttached: () => {
          getSelection()?.empty()
        },
      }
    })

    useEffect(() => {
      if (!editing) {
        props.api.stopEditing()
      }
    }, [editing, props.api])

    const changeOrder = (checked: boolean) => {
      setOrder(checked ? 'ASC' : 'DESC')
    }

    const dropdownRender = (originNode: ReactNode) => {
      return (
        <div className={styles.selecdropdown}>
          <div className={styles.switchwrap}>
            <Switch
              className={styles.switch}
              checked={order === 'ASC'}
              checkedChildren="ASC"
              unCheckedChildren="DESC"
              onChange={changeOrder}
              size="small"
            />
          </div>
          {originNode}
        </div>
      )
    }

    const options = columnsOptions?.map((colName) => ({
      label: colName,
      value: `${colName} ${order}`,
    }))

    const onChange = (v: string[]) => {
      setEditing(true)
      setValue(v)
    }

    const onDropdownVisibleChange = (open: boolean) => {
      setEditing(open)
    }

    return (
      <div
        ref={refContainer}
        tabIndex={1} // ag-grd: important - without this the key presses wont be caught
      >
        <Select
          className={'columnselect'}
          value={value}
          options={options}
          dropdownRender={dropdownRender}
          onChange={onChange}
          mode="multiple"
          style={{ width: '250px' }}
          onDropdownVisibleChange={onDropdownVisibleChange}
          optionLabelProp="value"
          dropdownStyle={{ padding: 0 }}
          dropdownClassName={'selectdropdown'}
        ></Select>
      </div>
    )
  },
)
