const assignee=""
const assginee=""
const assigneelist=""
const nrOfCompletedInstances=""
const number = 3
/* eslint-disable no-undef */

const flowbpmn1 = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:activiti="http://activiti.org/bpmn" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI" xmlns:tns="http://www.activiti.org/test" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" expressionLanguage="http://www.w3.org/1999/XPath" id="m1667439751490" name="" targetNamespace="http://www.activiti.org/test" typeLanguage="http://www.w3.org/2001/XMLSchema">
  <process id="myProcess_1" isClosed="false" isExecutable="true" processType="None">
    <startEvent id="_2" name="StartEvent"/>
    <userTask activiti:exclusive="true" id="_3" name="UserTask" activiti:assignee="${assignee}">
      <documentation id="_3_D_1"><![CDATA[一级审批]]></documentation>
      <extensionElements>
        <activiti:taskListener class="cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener" event="create"/>
      </extensionElements>
    </userTask>
    <userTask activiti:assignee="${assignee}" activiti:exclusive="true" id="_4" name="UserTask"  activiti:formKey="true">
      <documentation id="_4_D_1"><![CDATA[二级审批]]></documentation>
      <extensionElements>
        <activiti:taskListener class="cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener" event="create"/>
      </extensionElements>
      <multiInstanceLoopCharacteristics activiti:collection="${assigneelist}" activiti:elementVariable="assignee" isSequential="false">
        <loopCardinality><![CDATA[3]]></loopCardinality>
        <completionCondition><![CDATA[${nrOfCompletedInstances == 2}]]></completionCondition>
      </multiInstanceLoopCharacteristics>
    </userTask>
    <userTask activiti:assignee="${assignee}" activiti:exclusive="true" id="_5" name="UserTask">
      <documentation id="_5_D_1"><![CDATA[三级审批]]></documentation>
      <extensionElements>
        <activiti:taskListener class="cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener" event="create"/>
      </extensionElements>
      <multiInstanceLoopCharacteristics activiti:collection="${assigneelist}" activiti:elementVariable="assignee" isSequential="false">
        <loopCardinality><![CDATA[3]]></loopCardinality>
        <completionCondition><![CDATA[${nrOfCompletedInstances == 2}]]></completionCondition>
      </multiInstanceLoopCharacteristics>
    </userTask>
    <endEvent id="_6" name="EndEvent"/>
    <sequenceFlow id="_7" sourceRef="_2" targetRef="_3"/>
    <sequenceFlow id="_8" sourceRef="_3" targetRef="_4"/>
    <sequenceFlow id="_9" sourceRef="_4" targetRef="_5"/>
    <userTask activiti:assignee="${assginee}" activiti:exclusive="true" id="_11" name="UserTask">
      <documentation id="_11_D_1"><![CDATA[四级审批]]></documentation>
      <extensionElements>
        <activiti:taskListener class="cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener" event="create"/>
      </extensionElements>
    </userTask>
    <userTask activiti:assignee="${assginee}" activiti:exclusive="true" id="_12" name="UserTask">
      <documentation id="_12_D_1"><![CDATA[四级审批]]></documentation>
      <extensionElements>
        <activiti:taskListener class="cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener" event="create"/>
      </extensionElements>
    </userTask>
    <userTask activiti:exclusive="true" id="_13" name="UserTask" activiti:assignee="${assginee}">
      <documentation id="_13_D_1"><![CDATA[五级审批]]></documentation>
      <extensionElements>
        <activiti:taskListener class="cn.bintools.cloudquery.flow.listener.TaskCreateCustomListener" event="create"/>
      </extensionElements>
    </userTask>
    <sequenceFlow id="_14" sourceRef="_5" targetRef="_11">
      <conditionExpression xsi:type="tFormalExpression"><![CDATA[${number >= 3}]]></conditionExpression>
    </sequenceFlow>
    <sequenceFlow id="_15" sourceRef="_5" targetRef="_12">
      <conditionExpression xsi:type="tFormalExpression"><![CDATA[${number < 3}]]></conditionExpression>
    </sequenceFlow>
    <sequenceFlow id="_16" sourceRef="_12" targetRef="_13"/>
    <sequenceFlow id="_17" sourceRef="_11" targetRef="_13"/>
    <sequenceFlow id="_18" sourceRef="_13" targetRef="_6"/>
  </process>
  <bpmndi:BPMNDiagram documentation="background=#3C3F41;count=1;horizontalcount=1;orientation=0;width=842.4;height=1195.2;imageableWidth=832.4;imageableHeight=1185.2;imageableX=5.0;imageableY=5.0" id="Diagram-_1" name="New Diagram">
    <bpmndi:BPMNPlane bpmnElement="myProcess_1">
      <bpmndi:BPMNShape bpmnElement="_2" id="Shape-_2">
        <omgdc:Bounds height="32.0" width="32.0" x="150.0" y="195.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="32.0" width="32.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_3" id="Shape-_3">
        <omgdc:Bounds height="55.0" width="85.0" x="260.0" y="185.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="55.0" width="85.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_4" id="Shape-_4">
        <omgdc:Bounds height="55.0" width="85.0" x="425.0" y="185.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="55.0" width="85.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_5" id="Shape-_5">
        <omgdc:Bounds height="55.0" width="85.0" x="600.0" y="185.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="55.0" width="85.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_6" id="Shape-_6">
        <omgdc:Bounds height="32.0" width="32.0" x="1130.0" y="200.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="32.0" width="32.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_11" id="Shape-_11">
        <omgdc:Bounds height="55.0" width="85.0" x="775.0" y="110.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="55.0" width="85.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_12" id="Shape-_12">
        <omgdc:Bounds height="55.0" width="85.0" x="780.0" y="295.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="55.0" width="85.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape bpmnElement="_13" id="Shape-_13">
        <omgdc:Bounds height="55.0" width="85.0" x="980.0" y="190.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="55.0" width="85.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge bpmnElement="_15" id="BPMNEdge__15" sourceElement="_5" targetElement="_12">
        <omgdi:waypoint x="685.0" y="212.5"/>
        <omgdi:waypoint x="780.0" y="322.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_14" id="BPMNEdge__14" sourceElement="_5" targetElement="_11">
        <omgdi:waypoint x="685.0" y="212.5"/>
        <omgdi:waypoint x="775.0" y="137.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_17" id="BPMNEdge__17" sourceElement="_11" targetElement="_13">
        <omgdi:waypoint x="860.0" y="137.5"/>
        <omgdi:waypoint x="980.0" y="217.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_16" id="BPMNEdge__16" sourceElement="_12" targetElement="_13">
        <omgdi:waypoint x="865.0" y="322.5"/>
        <omgdi:waypoint x="980.0" y="217.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_18" id="BPMNEdge__18" sourceElement="_13" targetElement="_6">
        <omgdi:waypoint x="1065.0" y="217.5"/>
        <omgdi:waypoint x="1130.0" y="216.0"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_7" id="BPMNEdge__7" sourceElement="_2" targetElement="_3">
        <omgdi:waypoint x="182.0" y="211.0"/>
        <omgdi:waypoint x="260.0" y="212.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_8" id="BPMNEdge__8" sourceElement="_3" targetElement="_4">
        <omgdi:waypoint x="345.0" y="212.5"/>
        <omgdi:waypoint x="425.0" y="212.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge bpmnElement="_9" id="BPMNEdge__9" sourceElement="_4" targetElement="_5">
        <omgdi:waypoint x="510.0" y="212.5"/>
        <omgdi:waypoint x="600.0" y="212.5"/>
        <bpmndi:BPMNLabel>
          <omgdc:Bounds height="0.0" width="0.0" x="0.0" y="0.0"/>
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</definitions>
`
export default flowbpmn1 