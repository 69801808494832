import React, { useState } from 'react'
import { Layout, Breadcrumb, Anchor, Tabs } from 'antd'
import { PersonalBasicPage } from './PersonalBasicPage'
import 'src/styles/layout.scss'
import { Iconfont } from 'src/components'
import styles from './index.module.scss'
const { Header, Sider, Content } = Layout

export const PersonalCenterPage = () => {
  const [tabsKey, setTabesKey] = useState('Information')

  const SettingTabs = (
    <Tabs
      className={styles.personalTabs}
      tabPosition={'left'}
      type="card"
      activeKey={tabsKey}
      onChange={(e) => {
        setTabesKey(e)
      }}
    >
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-gerenxinxi"></Iconfont>
          <span>个人信息</span>
        </>
      } key="Information" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-anquanshezhi"></Iconfont>
          <span>安全设置</span>
        </>
      } key="SecuritySettings" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-kefangwenziyuan"></Iconfont>
          <span>可访问资源</span>
        </>
      } key="AccessibleResource" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-pianhaoshezhi"></Iconfont>
          <span>偏好设置</span>
        </>
      } key="Preferences" />
    </Tabs>
  )
  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header">
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>个人中心</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item>个人设置</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main" style={{padding: '0 10px 0 0'}}>
        <Sider className="cq-aside" width={246}>
          <section className="cq-card" style={{ background: '#F7F9FC', border: 'none' }}>
            <div className={styles.personalTabsTitle} >
              个人设置
            </div>
            <div className="cq-card__content" style={{ width: '94%', margin: '0 auto' }}>
              {SettingTabs}
            </div>
          </section>
        </Sider>
        <Content className="cq-content" id="anchor-box" style={{marginLeft: '10px'}}>
          <div className='cq-content-card' style={{}}>
            <PersonalBasicPage activeKey={tabsKey} />
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
