import React, { useEffect, useMemo, useState, useCallback } from 'react'
import * as _ from 'lodash';
import { Tree, Select, Spin } from 'antd'
import classnames from 'classnames'
import { Iconfont } from 'src/components'
import { useDispatch, useRequest, useSelector } from 'src/hook';
import { DataNode } from "antd/lib/tree";
import {
  TreeNode,
  FlowElementTreeNodeEntity,
  queryTreeNode,
  queryGroupNodes,
  getDataAuthSdtSearchList,
  permissionSupport
} from 'src/api';
import type { ResponseNodeEntity } from "src/types";
import { actionIconNodeTypes } from 'src/constants';
import {
  generateTree,
  matchKeyword,
  getConnExpandNodeNeedPathKeys,
  getTargetNode
} from './utils';
import styles from './index.module.scss'
import { getCurrentModulePermissionByUrl } from 'src/util';
import { setSelectedNodePermissionInfo } from '../dataProtection/DataProtectionPageSlice';

/** 元素节点元信息 */
export type ElementNodeProps = Pick<FlowElementTreeNodeEntity, "connectionId" | "connectionType" | "nodeName" | "nodePath" | "nodeType" | "hasChild"> & {
  nodePathWithType: string;
  groupId: number | null | undefined;
};

type NewResponseNodeEntity = ResponseNodeEntity & {
  nodePathWithType: string;
  sdt: any;
};


interface IProps {
  selectedNode: any;
  handleTreeSelect: (keys: any[], info: any) => void
  [p: string]: any
}

const renderOptionLabel = (i: any) => {
  let iconType = `icon-${i?.nodeType === 'connection' ? i?.sdt?.connectionType : i?.nodeType}`

  return <div>
    <Iconfont
      type={iconType}
      style={{ marginRight: 4 }} /><span>{i?.nodeShowName}</span>
  </div>
}

function formatTreeNode(node: NewResponseNodeEntity): DataNode {  
  const { nodeName, nodePath, nodeType, connectionType, connection ={} } = node?.sdt || {};

  let icon = `icon-${nodeType}`;
  if (nodeType === "connection") {
    icon = `icon-connection-${connectionType}`;
  }

  const upperNodeType = (node?.nodeType || nodeType)?.toUpperCase();
  const treeNode = {
    key: nodePath,
    value: nodePath,
    title: nodeName,
    icon: <Iconfont type={icon} />,
    ...node,
    isLeaf: upperNodeType.endsWith("GROUP"),
  };
  return treeNode;
}

const TreeComponent = (props: IProps) => {
  const {
    selectedNode,
    handleTreeSelect,
    handleTreeDefaultSelect,
  } = props

  const [expandedKeys, setExpandedKeys] = useState<any[]>([])

  const [curConnectionId, setCurConnectionId] = useState<string>()  // 记录当前操作数据的connectionId,加载连接级和其下级的树结构数据需要该参数

  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  //所有点开过的节点 做缓存{path: []}
  const [treeNodeChildrenMap, setTreeNodeChildrenMap] = useState<any>({});
  //字典搜索内容
  const [dictSearchValue, setDictSearchValue] = useState<any>(null);
  //字典搜索options
  const [dictOptions, setDictOptions] = useState<any>([])

  const { permissionList } = useSelector((state) => state?.login);
  const { selectedNodePermissionInfo } = useSelector(state => state.dataProtection);
  const dispatch = useDispatch();

  // 左侧treeData
  const { data: treeWrapData, loading: rootLoading, run: getTreeWrapList } = useRequest(() =>queryGroupNodes(true), {
    formatResult(res){
      return {
        ...res,
        nodeList: generateTree(res?.nodeList || []).filter(node => node?.children?.length)
      }
    },
  });


  //数据字典远程搜索 全部资源
  const { loading: dictLoading, run: onSearchSdtNodes } = useRequest(getDataAuthSdtSearchList, {
    manual: true,
    debounceInterval: 800,
    formatResult: (data) => {
      return data?.map((i: any) => ({
        label: renderOptionLabel(i),
        value: i?.nodePath,
        key: i?.nodePath,
        ...i,
      }))
    },
    onSuccess(res) {
      setDictOptions(res)
    }
  })

  /**
 * 获取子节点
 */
  const { run: loadChildren, loading: childLoading } = useRequest(queryTreeNode, {
    manual: true,
    formatResult: res => {
      return res.map(formatTreeNode);
    },
  });

  const moduleName = "DATABASE_MANAGEMENT";
  const subModuleName = "auth_management".toLocaleUpperCase();
  //模块权限查询
  const modulePermissionObj: { isOnlyRead: boolean; roleNameList: string[]} = useMemo(() => {
    return getCurrentModulePermissionByUrl(permissionList, moduleName)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(permissionList)])
  // 判断是否可编辑
  const { run: runPermissionSupport } = useRequest(permissionSupport, {
    manual: true,
    onSuccess: (res) => {
      dispatch(setSelectedNodePermissionInfo({...selectedNodePermissionInfo,
        ["auth_management".toLocaleUpperCase()] :{
          "modulePermissionObj": modulePermissionObj,
          "permissionSupportData": res
        }
      }))
    }
  })


  useEffect(() => {
   
    if (treeWrapData) {
      const defaultSelectedItem = treeWrapData && treeWrapData?.nodeList?.find(
        (item: any) => item.nodeType === 'datasource',
      )
      if(!defaultSelectedItem){
        return
      }
      !!defaultSelectedItem && handleTreeDefaultSelect(defaultSelectedItem)
      setTreeData(treeWrapData?.nodeList || []);
    }

  }, [treeWrapData])

  const handleExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
  }

  const handleSelect = (item: any[], info: any) => {
    // 获取该node下，用户是否可编辑
    if (!info?.node?.nodePathWithType) {
      runPermissionSupport({
        systemPermissionType: subModuleName,
        nodePathWithType: null
      })
    } else {
      runPermissionSupport({
        systemPermissionType: subModuleName,
        nodePathWithType: info?.node?.nodePathWithType
      })
    }
    handleTreeSelect(item, info)
  }

  // 异步逐级加载数据 (连接及以下层级加载树内容)
  async function onLoadData(node: any) {
    const { nodeType } = node.props;
    if (nodeType === "datasource" || nodeType === "group") {
      return;
    }
    let cloneTreeData = _.cloneDeep(treeData);
    let cloneTreeNodeChildrenMap = _.cloneDeep(treeNodeChildrenMap)
    const { newTreeData, newTreeNodeChildrenMap } = await fetchNodeChildren(node);

    cloneTreeData = newTreeData;
    cloneTreeNodeChildrenMap = newTreeNodeChildrenMap;
    setTreeData(cloneTreeData);
    setTreeNodeChildrenMap(cloneTreeNodeChildrenMap)
  }



  const fetchNodeChildren: any = async (node: any, updatedTreeData?: TreeNode[], updateTreeNodeChildrenMap?: any) => {


    //不存在缓存 重新请求
    const key = node?.nodePath;
    let cloneTreeData: TreeNode[] = _.cloneDeep(updatedTreeData || treeData);
    let cloneTreeNodeChildrenMap = _.cloneDeep(updateTreeNodeChildrenMap || treeNodeChildrenMap);

    const {
      id,
      connectionId,
      connection,
      nodeType,
      nodeName,
      nodePath,
      nodePathWithType,
    } = node
    let newConnectionId = curConnectionId || connectionId

    if (nodeType === 'connection') {
      newConnectionId = id
      setCurConnectionId(id)
    }
    const { connectionType } = connection || {}
    const params = {
      connectionId: newConnectionId,
      connectionType,
      nodeType,
      nodeName,
      nodePath,
      nodePathWithType: nodePathWithType,
    }
    //@ts-ignore
    const children = await loadChildren({
      ...params
    });

    const target = getTargetNode(cloneTreeData, key);

    if (target) {
      target.children = children || [];

      cloneTreeNodeChildrenMap[key] = children;

      return {
        newTreeData: cloneTreeData,
        newTreeNodeChildrenMap: cloneTreeNodeChildrenMap
      };
    }
  }

  const onSelectFilterOption = async (v: string, option: any) => {

    setDictSearchValue(v);
    const searchName: string = _.last(option?.nodeShowName?.split('.')) || ''
    const { nodePath, nodeType, nodePathWithType, key, sdt = {} } = option;
    const { connectionType, groupName } = sdt ;
    const allExpandKeys = getConnExpandNodeNeedPathKeys(option?.nodePath, true);
    //顶层为数据源 对应的所有nodePath
    let shouldExpandKeys = allExpandKeys.slice(0, allExpandKeys.length - 1);

    //如果选中是连接 则不需要掉接口
    if (['connection', 'group'].includes(nodeType)) {
      //展开节点是 数据源类型、组
      setExpandedKeys([`/${connectionType}`, `/${connectionType}/${groupName}`]);
      
      handleTreeSelect(option, {...option,selected: true, selectedNodes: [option], node: option })
      return
    }
    //再次搜索 tree已经生成 不需要再次请求接口
    if (treeNodeChildrenMap[nodePath]) {
      setDictSearchValue(searchName);
      setExpandedKeys([...new Set(shouldExpandKeys)])
      handleTreeSelect(option, { ...option,selected: true,selectedNodes: [option], node: option })
      return
    }

    //父级 nodePath总长度
    const parentNodePathWithTypes = getConnExpandNodeNeedPathKeys(nodePathWithType);

    //以下层级 nodePathWithTypes 和上个节点保持一致
    const level1Names = ['表', '外表', '视图组', '集合', '物化视图', '键组', '文件组', '函数组', '存储过程组', '任务组', '同义词', '序列组', '触发器组', '数据库连接', '包', '包体', '任务组', '字典组', 'GridFs存储桶', '用户自定义函数', 'flexTableGroup']
    const level2Names = ['列组', '索引组', '约束组', '外键组', '触发器组', '分区组'];
    let asyncActions: any = [];
    let nodePathWithTypeArr = parentNodePathWithTypes;

    //@ts-ignore
    shouldExpandKeys?.map((key: string, index: number) => {
      //是否是表...结尾
      let endType = key.substr(key.lastIndexOf('/') + 1);
      //如果是组 则nodePathWithType = null;
      if (level1Names.includes(endType) || level2Names.includes(endType)) {
        const preNType = nodePathWithTypeArr[index - 1]; //这里可能不对
        nodePathWithTypeArr.splice(index, 0, preNType);
      }
    })

    shouldExpandKeys.map((key, index) => asyncActions.push({ nodePath: key, nodePathWithType: nodePathWithTypeArr[index] }))

    let cloneTreeData = _.cloneDeep(treeData);
    let cloneTreeNodeChildrenMap = _.cloneDeep(treeNodeChildrenMap);

    for (const node of asyncActions) {
      try {

        //防止重新请求 导致其他查询过数据被清空
        if (!cloneTreeNodeChildrenMap[node.nodePath]) {

          const { newTreeData, newTreeNodeChildrenMap } = await fetchNodeChildren({ ...node}, cloneTreeData, cloneTreeNodeChildrenMap)
          cloneTreeData = newTreeData;
          cloneTreeNodeChildrenMap = newTreeNodeChildrenMap;
        };

      } catch (error) {
        console.log('异步调用出错了', error);
        break;
      }
    }
    //包括数据源 或组
    shouldExpandKeys = shouldExpandKeys.concat([`/${connectionType}`, `/${connectionType}/${groupName}`])
    await setTreeData(cloneTreeData);
    setTreeNodeChildrenMap(cloneTreeNodeChildrenMap)
    setExpandedKeys([...new Set(shouldExpandKeys)])
    handleTreeSelect(option, {...option,selected: true, selectedNodes: [option], node: option })
    setDictSearchValue(searchName)
  }

  const filterNodesNotMatch = useCallback(
    (nodes: any[]): any[] =>
      nodes.filter((node) => {
        // console.log(node, selectedNode, '--',(node.connectionType || node?.sdt?.connectionType));

        //@ts-ignore
        if (selectedNode?.sdt?.connectionType !== (node.connectionType || node?.sdt?.connectionType)) return false
       
        //@ts-ignore
        const name: string = _.last(selectedNode?.nodeShowName?.split('.')) || ''

        const isSameConnection = selectedNode?.nodePath?.startsWith(node?.nodePath);

        //后端搜索 筛选信息只有一条， 前端筛选模糊匹配
        const keywordHit = isSameConnection && matchKeyword(node.title, name)  //确保位置准确 不然可能出现多个层级

        if (!keywordHit && node?.children) {
          node.children = filterNodesNotMatch(node.children)
        }
        return keywordHit || node.children?.length
      }),
    [dictSearchValue],
  )

  const filteredTreeData: any[] = useMemo(
    () =>
      dictSearchValue ? filterNodesNotMatch(_.cloneDeep(treeData)) : treeData,
    [dictSearchValue, JSON.stringify(treeData)],
  )

  // 生成tree搜索标题
  const generatorSearchTitle = (
    nodeName: string,
    title: any,
    nodeType: string,
    nodeCount: number,
    testModel: number,
    dataSourceType: string,
  ) => {
    return (
      <>
        <Iconfont
          className={classnames(styles.mr4, styles.color008dff, {
            [styles.colorf00]: testModel === 1 && nodeType === "connection",
            [styles.colorgreen]: !!testModel && testModel !== 1 && nodeType === "connection",
          })}
          type=
          {`${nodeType === "datasource"
              ? `icon-connection-${nodeName}`
              : nodeType === "group"
                ? "icon-shujukuwenjianjia"
                : nodeType === "connection"
                  ? `icon-${dataSourceType}`
                  : `icon-${nodeType}`
            } `}
        />
        <span className={styles.titleTxt}>
          {title}
          {["datasource", "group"].includes(nodeType) && `(${nodeCount})`}
        </span>
      </>
    );
  };

  // 渲染tree title完整内容
  const treeTitleRender = (node: any) => {
    const { nodeName, title, nodeType, layer, connection } = node;
    return (
      <div className={styles.treeTitleItem}>
        {
          generatorSearchTitle(
            nodeName,
            title,
            nodeType,
            layer?.childCount,
            connection?.testModel,
            connection?.connectionType,
          )
        }
      </div>
    );
  }
// console.log(filteredTreeData, 'treeData---',selectedNode,expandedKeys);

  return (
    <div className={styles.leftWrap}>
      <div className={classnames(styles.title, styles.mb10)} style={{ color: '#667084' }}>
        资源列表:{treeWrapData?.total || 0}
      </div>
     
      <Select
        allowClear
        showSearch
        filterOption={false}
        loading={dictLoading}
        showArrow={false}
        value={dictSearchValue}
        dropdownMatchSelectWidth={false}
        onSelect={onSelectFilterOption}
        onClear={() => { setDictSearchValue(null); }}
        onSearch={(text) => {
          setDictOptions([])
          onSearchSdtNodes({ query: text })
        }}
        className={styles.protectSearch}
        placeholder="请输入搜索内容"
        options={dictOptions || []}
      />
       <Spin spinning={rootLoading} wrapperClassName={styles.spinContainer}>
        <Tree
          className={styles.treeWrap}
          titleRender={treeTitleRender} // 自定义渲染节点 --- 节点前图标的配置
          treeData={filteredTreeData}
          expandedKeys={expandedKeys}
          selectedKeys={selectedNode?.key ? [selectedNode.key] : undefined}
          onSelect={handleSelect}
          onExpand={handleExpand}
          loadData={onLoadData}
        />
        {!rootLoading && !filteredTreeData.length && (
              <div className={styles.treePlaceholder}>暂无元素</div>
            )}
        </Spin>
    </div>
  )
}
export default TreeComponent