import React, { PropsWithChildren } from 'react'
import { Drawer, Button } from 'antd'

interface IProps {
  visible: boolean
  title: string
  width: number
  onCancel: () => void
  onOk: (fields: string[]) => void
  footer?: React.ReactNode
}

export const DrawerWrapper = ({
  visible = false,
  title,
  width,
  onCancel,
  onOk,
  footer,
  children,
}: PropsWithChildren<IProps>) => {
  return (
    <Drawer
      title={title}
      width={width || 400}
      onClose={onCancel}
      visible={visible}
      footer={
        footer ? (
          footer
        ) : (
          <>
            <Button
              type="primary"
              style={{ marginRight: 10 }}
              onClick={() => onOk(['d'])}
            >
              确定
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </>
        )
      }
    >
      {children}
    </Drawer>
  )
}
