import React, { useEffect, useMemo, useState } from 'react'
import { useRequest, useSelector } from 'src/hook'
import { UIModal } from 'src/components'
import {
  approveApplication,
  executeDataCorrection,
  getFlowDetails,
  getFlowProcess,
  getNewFlowProcess,
  rejectApplication,
  rollbackDataCorrection,
  withdrawApplication,
} from 'src/api'
import {
  Button,
  Divider,
  message,
  Result,
  Skeleton,
  Space,
  Spin,
  Input,
  Modal,
} from 'antd'
import { FlowDescriptions } from './FlowDescriptions'
import { OldFlowProcess } from './FlowProcess'
import { NewFlowProcess } from './newFlowProcess'
import type { ApplyStatus, FlowDetailFields } from 'src/types'
import { flowTypeLabelMap } from '../constants'
import { renderNewStatusTag, renderStatusTag } from '../utils'
import styles from './flowDetail.module.scss'
import flowStyles from '../flowPage.module.scss'

/**
 * @description: 流程处理模态框脚部 订正/已订正/已回退 按钮显示
 * @param {boolean} isExecute 订正/已订正/已回退显示判断 true表示已经已经订正过
 * @param {boolean} isRollBack 已订正/已回退显示判断 true表示已经已经回退
 * @param {string} applyId 当前record的id,用于进行请求标识
 * @param {any} executeRequest  request hook订正请求
 * @param {any} rollbackRequest  request hook回退请求
 * @return {React.ReactElement}
 */
const dataExecuteRender = (
  isExecute: boolean,
  isRollBack: boolean,
  applyId: string,
  executeRequest: any,
  rollbackRequest: any,
): React.ReactElement => {
  return (
    <Space>
      {!isExecute && (
        <Button
          onClick={() => executeRequest.run(applyId)}
          loading={executeRequest.loading}
          disabled={rollbackRequest.loading}
        >
          订正
        </Button>
      )}
      {isExecute && !isRollBack && <Button disabled>已订正</Button>}
      {isExecute && !isRollBack && (
        <Button
          onClick={() => rollbackRequest.run(applyId)}
          loading={rollbackRequest.loading}
          disabled={executeRequest.loading}
        >
          回退
        </Button>
      )}
      {isExecute && isRollBack && <Button disabled>已回退</Button>}
    </Space>
  )
}

/**
 * @description:  流程处理模态框脚部 撤销 按钮显示
 * @param {any} withdrawRequest request hook撤销请求
 * @param {String} applyId 当前record的id,用于进行请求标识
 * @return {React.ReactElement}
 */
const undoRender = (withdrawRequest: any, applyId: any): React.ReactElement => {
  return (
    <Button
      onClick={() => withdrawRequest.run(applyId)}
      loading={withdrawRequest.loading}
    >
      撤销
    </Button>
  )
}

/**
 * @description: 流程处理模态框脚部 通过/驳回 按钮显示
 * @param {any} rejectRequest request hook驳回请求
 * @param {any} approveRequest request hook通过请求
 * @param {any} confirm 通过按钮调用
 * @param {any} applyId
 * @param {any} taskId
 * @return {React.ReactElement}
 */
const rejectAndFullfilledRender = (
  rejectRequest: any,
  approveRequest: any,
  confirm: Function,
  applyId: string,
  taskId: string,
  approve?: Function,
): React.ReactElement => {
  return (
    <Space>
      <Button
        className={flowStyles.successBtn}
        onClick={() => {
          approve && approve()
          approveRequest.run({ flowId: applyId, approvedFlag: true, taskId })
        }}
        loading={approveRequest.loading}
        // 发起驳回时禁用通过按钮
        disabled={rejectRequest.loading}
      >
        通过
      </Button>
      <Button
        onClick={() => {
          confirm()
        }}
        loading={rejectRequest.loading}
        // 发起通过时禁用驳回按钮
        disabled={approveRequest.loading}
        type="primary"
        danger
      >
        驳回
      </Button>
    </Space>
  )
}

export const ModalFlowDetail = ({
  applyId,
  kind,
  visible,
  onCancel,
  refreshList,
  record,
  footer,
}: {
  applyId: string
  kind: 'application' | 'approval'
  visible?: boolean
  onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  refreshList?: () => void
  record?: any
  footer?: React.ReactNode | null
}) => {
  const { userId } = useSelector((state) => state.login.userInfo)
  const isNewFlow = useMemo(() => {
    return record?.hasOwnProperty('flowInstanceId')
  }, [record])

  let [currentOperationType, setCurrentOperationType] = useState<
    'approve' | 'reject' | null
  >(null)

  //模态框-属性面板-请求request
  const {
    data: details,
    run: fetchDetails,
    refresh: refreshDetails,
    loading: loadingDetails,
    error: detailsRequestError,
  } = useRequest(
    async (
      ...args: Parameters<typeof getFlowDetails>
    ): Promise<FlowDetailFields> => {
      try {
        return await getFlowDetails(...args)
      } catch {
        throw new Error('获取流程详情错误')
      }
    },
    { manual: true },
  )

  //模态框-流程图-老流程数据库请求request
  const {
    data: process,
    run: fetchProcess,
    refresh: refreshProcess,
    loading: loadingProcess,
    error: processRequestError,
  } = useRequest(
    async (...args: Parameters<typeof getFlowProcess>) => {
      try {
        return await getFlowProcess(...args)
      } catch {
        throw new Error('获取流程进度错误')
      }
    },
    { manual: true },
  )

  //模态框-流程图-新流程数据请求request
  const {
    data: newProcess,
    run: fetchNewProcess,
    refresh: refreshNewProcess,
    loading: loadingNewProcess,
    error: newProcessRequestError,
  } = useRequest(
    async (...args: Parameters<typeof getFlowProcess>) => {
      try {
        return await getNewFlowProcess(...args)
      } catch {
        throw new Error('获取流程进度错误')
      }
    },
    { manual: true },
  )

  //模态框-属性面板-请求
  useEffect(() => {
    if (applyId !== undefined && visible) {
      fetchDetails({ flowId: applyId, taskId: record.flowTaskId })
      // fetchProcess(applyId)
    }
  }, [applyId, fetchDetails, visible])

  //模态框-流程图-请求
  useEffect(() => {
    /**
     *  如果父组件调用中,当前行数据record
     *  record含有flowInstanceId字段--->新流程展示数据请求
     */
    if (
      record &&
      record.hasOwnProperty('flowInstanceId') &&
      record.flowInstanceId
    ) {
      fetchNewProcess(record.flowInstanceId)
    } else {
      if (applyId !== undefined && visible) {
        fetchProcess(applyId)
      }
    }
  }, [applyId, fetchDetails, fetchProcess, visible])

  //模态框-流程图-老流程-请求数据转换为流程数据
  const steps = useMemo(() => {
    if (!details || !process) return
    const {
      applyUserName,
      applyTime,
      firstApproveTime,
      firstApproveUserName,
      firstApproveStatus,
      secondApproveUserName,
      secondApproveStatus,
      secondApproveTime,
      finishTime,
      finishStatus,
    } = process

    const steps = []
    const startNode = {
      label: '创建单据',
      userName: applyUserName,
      time: applyTime,
      status: 'pass',
    }
    steps.push(startNode)
    const fstNode = {
      label: '一级审批人',
      userName: firstApproveUserName,
      time: firstApproveTime,
      status: firstApproveStatus,
    }
    steps.push(fstNode)
    // 只有 ui 提权有二级审批
    if (details.flowType === 'menuAccess') {
      const secondNode = {
        label: '二级审批人',
        userName: secondApproveUserName,
        time: secondApproveTime,
        status: secondApproveStatus,
      }
      steps.push(secondNode)
    }

    const finalNode = {
      label: '完成',
      time: finishTime,
      status: finishStatus,
    }
    steps.push(finalNode)
    return steps
  }, [details, process])

  //模态框-流程图-新流程-请求数据转换为流程数据
  const stepInfo = useMemo(() => {
    if (!details || !newProcess) return

    const newProcessNodes = []

    for (const key in newProcess) {
      if (Object.prototype.hasOwnProperty.call(newProcess, key)) {
        const element = newProcess[key]
        const localNode = {
          title: key,
          content: element,
        }
        newProcessNodes.push(localNode)
      }
    }

    return newProcessNodes

    // const {
    //   applyUserName,
    //   applyTime,
    //   firstApproveTime,
    //   firstApproveUserName,
    //   firstApproveStatus,
    //   secondApproveUserName,
    //   secondApproveStatus,
    //   secondApproveTime,
    //   finishTime,
    //   finishStatus,
    // } = process

    // const steps = []
    // const startNode = {
    //   label: '创建单据',
    //   userName: applyUserName,
    //   time: applyTime,
    //   status: 'pass',
    // }
    // steps.push(startNode)
    // const fstNode = {
    //   label: '一级审批人',
    //   userName: firstApproveUserName,
    //   time: firstApproveTime,
    //   status: firstApproveStatus,
    // }
    // steps.push(fstNode)
    // // 只有 ui 提权有二级审批
    // if (details.flowType === 'menuAccess') {
    //   const secondNode = {
    //     label: '二级审批人',
    //     userName: secondApproveUserName,
    //     time: secondApproveTime,
    //     status: secondApproveStatus,
    //   }
    //   steps.push(secondNode)
    // }

    // const finalNode = {
    //   label: '完成',
    //   time: finishTime,
    //   status: finishStatus,
    // }
    // steps.push(finalNode)
    // return steps
  }, [details])

  //refresh刷新处理
  const handleRefresh = () => {
    refreshDetails()
    refreshProcess()
    refreshList?.()
    refreshNewProcess()
    setCurrentOperationType(null)
  }

  //审批通过-request
  const approveRequest = useRequest(approveApplication, {
    manual: true,
    onSuccess: () => {
      if (currentOperationType === 'approve') {
        message.success('通过成功')
      }

      if (currentOperationType === 'reject') {
        message.success('驳回成功')
      }
      handleRefresh()
    },
  })

  //审批驳回-request
  const rejectRequest = useRequest(rejectApplication, {
    manual: true,
    onSuccess: () => {
      message.success('驳回成功')
      handleRefresh()
    },
  })
  const withdrawRequest = useRequest(withdrawApplication, {
    manual: true,
    onSuccess: () => {
      message.success('撤销成功')
      handleRefresh()
    },
  })
  const executeRequest = useRequest(executeDataCorrection, {
    manual: true,
    onSuccess: () => {
      message.success('订正成功')
      handleRefresh()
    },
  })
  const rollbackRequest = useRequest(rollbackDataCorrection, {
    manual: true,
    onSuccess: () => {
      message.success('回退成功')
      handleRefresh()
    },
  })

  /** 申请详情的 footer */
  const renderApplicationFooter = () => {
    // 未获取到详情，无操作
    if (applyId === undefined || !details) {
      return null
    }

    //老流程渲染逻辑
    if (process) {
      // 审批中，申请人可撤销
      if (process.finishStatus === 'pending') {
        return undoRender(withdrawRequest, applyId)
      }
      // 已通过，且是数据订正流程，申请人可执行订正或回退
      if (
        process.finishStatus === 'pass' &&
        details.flowType === 'dataCorrection'
      ) {
        return dataExecuteRender(
          !!details.isExecute,
          !!details.isRollBack,
          applyId,
          executeRequest,
          rollbackRequest,
        )
      }
    }

    //新流程逻辑渲染
    if (newProcess) {
      let newLastNodeResult = newProcess?.[0]?.flowNodeResult
      // 审批中，申请人可撤销
      if (newLastNodeResult === 'pending') {
        return undoRender(withdrawRequest, applyId)
      }
      // 已通过，且是数据订正流程，申请人可执行订正或回退
      if (
        (newLastNodeResult === 'pass' ||
          newLastNodeResult === 'signPass' ||
          newLastNodeResult === 'orSignPass') &&
        details.flowType === 'dataCorrection'
      ) {
        return dataExecuteRender(
          !!details.isExecute,
          !!details.isRollBack,
          applyId,
          executeRequest,
          rollbackRequest,
        )
      }
    }

    // 其他情况，无操作
    return null
  }

  function confirm() {
    function isEmpty(closeFn: any, val?: string) {
      if (!val) {
        message.warn('请输入驳回理由')
        return
      }
      setCurrentOperationType('reject')
      approveRequest.run({
        flowId: applyId,
        taskId: record?.flowTaskId || record?.flowInstanceId,
        approvedFlag: false,
        approvedComment: val,
        approvedTime: '',
      })
      closeFn()
    }

    const textOnchange = (val: string) => {
      update({
        onOk: (closeFn) => isEmpty(closeFn, val),
      })
    }

    const { update } = Modal.confirm({
      title: '驳回理由',
      content: (
        <Input.TextArea
          rows={5}
          onChange={(e) => textOnchange(e.target.value)}
        />
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: (closeFn) => isEmpty(closeFn),
    })
  }

  /** 审批详情的 footer */
  const renderApprovalFooter = () => { 
    // 未获取到数据，无操作
    if (applyId === undefined || !details) {
      return null
    }

    if (process) {
      // 已通过，且是数据订正流程，审批人可执行订正或回退
      if (
        process.finishStatus === 'pass' &&
        details.flowType === 'dataCorrection'
      ) {
        return dataExecuteRender(
          !!details.isExecute,
          !!details.isRollBack,
          applyId,
          executeRequest,
          rollbackRequest,
        )
      }

      if (
        // 当前用户是一级审批人且一级审批 pending 状态，返回 通过/驳回 按钮
        (process.firstApproveStatus === 'pending' &&
          process.firstApproveUserId === userId) ||
        // 当前用户是二级审批人且二级审批 pending 状态，返回 通过/驳回 按钮
        (process.secondApproveStatus === 'pending' &&
          process.secondApproveUserId === userId)
      ) {
        return rejectAndFullfilledRender(
          rejectRequest,
          approveRequest,
          confirm,
          applyId,
          record?.flowInstanceId,
        )
      }
    }

    if (newProcess) {
      let newLastNodeResult = newProcess?.[0]?.flowNodeResult
      // 已通过，且是数据订正流程，审批人可执行订正或回退
      // let newLastNodeResult = newProcess[newProcess.length].flowNodeResult
      // // 审批中，申请人可撤销
      // if (newLastNodeResult === 'pending') {
      //   return undoRender(withdrawRequest, applyId)
      // }
      if (
        (newLastNodeResult === 'pass' ||
          newLastNodeResult === 'signPass' ||
          newLastNodeResult === 'orSignPass') &&
        details.flowType === 'dataCorrection'
      ) {
        return dataExecuteRender(
          !!details.isExecute,
          !!details.isRollBack,
          applyId,
          executeRequest,
          rollbackRequest,
        )
      }

      if (newLastNodeResult === 'pending') {
        return rejectAndFullfilledRender(
          approveRequest,
          approveRequest,
          confirm,
          applyId,
          record?.flowTaskId,
          () => {
            setCurrentOperationType('approve')
          },
        )
      }
    }

    // 其他情况
    return null
  }

  /**
   * @description: 状态标签渲染(函数内部对新老流程区分)
   * @param {boolean} isNewProcess true表示新流程, false表示老流程
   * @param {any} process 老流程节点种类
   * @param {any} newProcess 新流程节点种类
   * @return {React.ReactElement | null}
   */
  const statusTagHandledRender = (
    isNewProcess: boolean,
    process: any,
    newProcess: any,
  ): React.ReactElement | null => {
    let resultRender: React.ReactElement | null
    if (isNewProcess) {
      resultRender = renderNewStatusTag(newProcess)
    } else {
      resultRender = renderStatusTag(process?.finishStatus as ApplyStatus)
    }
    return resultRender
  }

  return detailsRequestError ||
    (processRequestError && newProcessRequestError) ? (
    // 获取详情失败
    <UIModal
      title={<span className={styles.flowDetailTitle}>流程详情</span>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Result
        status="error"
        title="信息获取失败"
        subTitle="请点击刷新按钮，或稍后重新尝试"
        extra={
          <Button
            onClick={() => {
              refreshDetails()
              refreshProcess()
            }}
          >
            刷新
          </Button>
        }
      />
    </UIModal>
  ) : (
    // 获取详情成功
    <UIModal
      title={
        details ? (
          <Space className={styles.flowDetailTitle}>
            <span>
              {`${details.applyUserName?.replace(/(\w)$/, '$1 ')}的${
                flowTypeLabelMap[details.flowType]
              }审批`}
            </span>
            {statusTagHandledRender(
              record?.hasOwnProperty('flowInstanceId'),
              process?.finishStatus as ApplyStatus,

              newProcess ? details?.applyResult : null,
            )}
          </Space>
        ) : (
          '流程详情'
        )
      }
      visible={visible}
      onCancel={onCancel}
      footer={
        //语句明细不需要操作按钮
        footer === null ? null :
        //@ts-ignore
        details?.isShowButton 
          ? kind === 'application'
            ? renderApplicationFooter()
            : renderApprovalFooter()
          : []
      }
    >
      <div className={styles.flowDescriptions}>
        <div className={styles.flowDetailSubTitle}>单据详情</div>
        <Skeleton loading={loadingDetails} paragraph={{ rows: 8 }}>
          <FlowDescriptions details={details} />
        </Skeleton>
      </div>
      <Divider />
      <div className={styles.flowProcess}>
        <div className={styles.flowDetailSubTitle}>流程进度</div>
        <Spin spinning={loadingProcess}>
          {record?.hasOwnProperty('flowInstanceId') && record.flowInstanceId ? (
            <NewFlowProcess steps={newProcess} />
          ) : (
            <OldFlowProcess steps={steps} />
          )}
        </Spin>
      </div>
    </UIModal>
  )
}
