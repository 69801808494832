import React, { useEffect } from 'react'
import { Form, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { getSeparators } from 'src/api'
import { useRequest, useSelector } from 'src/hook'

export function SeparateSettingForm({ form }: { form?: FormInstance }) {

  const { fileType } = useSelector((state) => state.textImport);

  const { data, run } = useRequest(getSeparators, {
    manual: true,
  })
  useEffect(() => {
    run()
  }, [run])
  
  useEffect(() => {
    if (fileType === 'csv') {
      form?.setFieldsValue({fieldRecognize: '',fieldSeparator: ','});
    }else if (fileType === 'txt') {
      form?.setFieldsValue({fieldSeparator: '\t',fieldRecognize: '' });
    }
  },[fileType, form])

  return (
    <Form form={form} labelCol={{ span: 8 }} labelAlign="left">
      <Form.Item
        label="记录行分隔符"
        name="rowSeparator"
        rules={[{ required: true, message: '请选择行分隔符' }]}
        initialValue={'\r\n'}
      >
        <Select>
          {data?.rowSeparator.map((r: any) => {
            return (
              <Select.Option key={r} value={r}>
                {r.replace(/\r/, '\\r').replace(/\n/, '\\n')}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="字段分隔符"
        name="fieldSeparator"
        rules={[{ required: true, message: '请选择字段分隔符' }]}
        initialValue=","
      >
        <Select>
          {data?.fieldSeparator.map((f: any) => {
            return (
              <Select.Option key={f} value={f}>{f.replace(/\t/, '\\t')}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
      {/* 识别符可为空，其他为必填项 */}
      <Form.Item label="字段识别符" name="fieldRecognize">
        <Select allowClear>
          {data?.fieldRecognize.map((f: any) => {
            return (
              // eslint-disable-next-line no-useless-escape
              <Select.Option key={f} value={f}>{f.replace(/\"/, '\\"')}</Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    </Form>
  )
}
