import React, { FC, useEffect } from 'react'
import { Button, Card, DatePicker, Form, Input, message } from 'antd'
import classnames from 'classnames'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { useRequest, useSelector } from 'src/hook'
import { saveDraftFat, startFlowInstance } from 'src/api'
import { useHistory } from 'react-router-dom'
import styles from '../index.module.scss'
moment.locale('zh-cn')

const { RangePicker } = DatePicker;

const FormLayout = {
  labelCol: { span: 2},
  wrapperCol: { offset: 0, span: 8 },
}

interface ListProps {
  beginTime?: string
  endTime?: string
  remark?: string
  title?: string
}

export const SubmitApplicationsPage: FC<ListProps> = (props) => {
  const history = useHistory()
  const { beginTime, endTime, remark, title } = props
  const [form] = useForm()
  const { requestListData } = useSelector((state) => state.accessRequest)

  const { run: draftRun } = useRequest(saveDraftFat, { manual: true })

  const { data: startFlow, run: runStartFlow } = useRequest(startFlowInstance, {
    manual: true,
    onSuccess(data) {
      message.success('提交成功')
      history.push('/mine_apply')

    }
  })

  useEffect(() => {
    form.setFields([
      { name: "time", value: (beginTime && endTime) ? [ moment(beginTime), moment(endTime)] : []},
      { name: "title", value: title },
      { name: "remark", value: remark },
    ])
  }, [beginTime, endTime, remark, title])

  const onSaveDraft = () => {
    form.validateFields().then((res) => {
      const parmas = {
        title: res?.title,
        remark: res?.remark,
        beginTime: res?.time[0].format('YYYY-MM-DD HH:mm:ss'),
        endTime: res?.time[1].format('YYYY-MM-DD HH:mm:ss'),
        list: requestListData
      }
      draftRun(parmas).then(res => {
        message.success('保存草稿')
      })
    })
  }

  const onSubmit = () => {
    form.validateFields().then().then((values: any) => {
      const  beginTime =  values?.time[0].format('YYYY-MM-DD HH:mm:ss');
      const  endTime = values?.time[1].format('YYYY-MM-DD HH:mm:ss');
 
      const e = moment(endTime).valueOf();
      
      if (e < moment().valueOf()) {
        return message.error('请重新选择时间，结束时间必须大于当前时间')
      }
      let params = {
        title: values?.title,
        remark: values?.remark,
        beginTime,
        endTime
      }
           
      draftRun({ ...params, list: requestListData }).then(res => {
        runStartFlow({
          ...params, priGranType: 'FAT',
          flowType: 'dataManipulation'
        })
      })
    })
  }

  const disabledDate = (current: any) => {
    return current && current < moment().startOf('day');
  };
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledTime = (now: any, type: any) => {

      let time: any = moment();
      let hour = moment(time).hour();
      let minute = moment(time).minute();
      let second = moment(time).second();
      if (type === 'start') {
        return ({
          disabledHours: () =>range(0, hour),
          disabledMinutes: () => range(0,minute),
          disabledSeconds: () =>  range(0,second)
        })
      }
       return {}
  }
  return (
    <Card
      title="提交申请"
      className={classnames(styles.borderShow, styles.mt27, styles.mb27, styles.detailCard)}
      actions={[
        <div className={styles.subBtn}>
          <Button
            type='primary'
            className={classnames(styles.ml20, styles.mr20)}
            onClick={() => onSubmit()}
            disabled={!requestListData?.length}
          >
            提交申请
          </Button>
          <Button
            onClick={onSaveDraft}
            disabled={!requestListData?.length}
          >
            保存草稿
          </Button>
        </div>
      ]}
    >
      <Form form={form} {...FormLayout} component={false}>
        <Form.Item
          label="生效时间"
          name="time"
          rules={[{ required: true }, {
            validator(_rule, value,cb) {
      
              const e = moment(value?.[1]).valueOf();
              
              if (e < moment().valueOf()) {
                cb('请重新选择时间，结束时间必须大于当前时间')
              }
             cb();
            },
          }]}
        >
          <RangePicker
            disabledDate={disabledDate}
            disabledTime={disabledTime}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </Form.Item>
        <Form.Item
          label="标题"
          name="title"
          rules={[{ required: true }, {max: 20, min: 2}]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
          // rules={[{ required: true }]}
        >
          <Input.TextArea allowClear maxLength={100}/>
        </Form.Item>
      </Form>
    </Card>
  )
}
