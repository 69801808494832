import React, { useState } from 'react'
import {
  Button,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { useRequest, useSelector } from 'src/hook'
import { LinkButton, UIModal } from 'src/components'
import {
  appKeyDelete,
  appKeyEdit,
  appKeyGenerate,
  appKeyReset,
  getAppKeyList,
} from 'src/api'
import 'src/styles/layout.scss'

const { Header, Content } = Layout
const { Text } = Typography

export interface AppSecretRecord {
  id: number
  appKey: string
  appSecret: string
  note?: string
  createAt: string
  creator: string
  isValid: boolean
}

export const AppSecret = () => {
  const [exposeAppSecret, setExposeAppSecret] = useState('')
  const [isPwdModalOpen, setIsPwdModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editUserId, setEditUserId] = useState(0)
  const { userId } = useSelector((state) => state.login.userInfo)
  const [form] = useForm<{ appKey: string; note: string }>()
  const [editForm] = useForm<{ note: string }>()

  const {
    data,
    run: getList,
    loading: listLoading,
    refresh,
  } = useRequest((condition) =>
    getAppKeyList({
      pageNo: 1,
      pageSize: 10,
      ...condition,
    }),
  )
  // 生成 appKey
  const { run: generateAppSecret, loading: generateLoading } = useRequest(
    appKeyGenerate,
    {
      manual: true,
      onSuccess: (data) => {
        refresh()
        openPwdModal(data)
      },
    },
  )
  // 重置 appSecret
  const { run: resetAppSecret } = useRequest(appKeyReset, {
    manual: true,
    onSuccess: (data) => {
      refresh()
      openPwdModal(data)
    },
  })
  // 编辑
  const { run: editAppSecret } = useRequest(appKeyEdit, {
    manual: true,
    onSuccess() {
      refresh()
    }
  })
  // 删除
  const { run: deleteAppSecret } = useRequest(appKeyDelete, {
    manual: true,
    onSuccess() {
      refresh()
      message.success('删除成功')
    },
  })

  function onGenerate() {
    form.validateFields().then(({ appKey, note }) => {
      generateAppSecret({ appKey: appKey.trim(), note, user: userId! })
      form.resetFields()
    })
  }
  function onQuery() {
    const { note, appKey } = form.getFieldsValue()
    getList({
      appKey: appKey?.trim() || undefined,
      note: note || undefined,
    })
  }
  function onReset(r: AppSecretRecord) {
    resetAppSecret({ appKey: r.appKey, user: userId! })
  }
  function onEdit(r: AppSecretRecord) {
    setIsEditModalOpen(true)
    setEditUserId(r.id)
    editForm.setFieldsValue({ note: r.note })
  }

  function openPwdModal(p: string) {
    setExposeAppSecret(p)
    setIsPwdModalOpen(true)
  }
  function closePwdModal() {
    setExposeAppSecret('')
    setIsPwdModalOpen(false)
  }
  function handleEditOk() {
    const { note } = editForm.getFieldsValue()
    editAppSecret({ note, id: editUserId })
    setIsEditModalOpen(false)
    setEditUserId(0)
  }

  const appSecretColumns: ColumnsType<AppSecretRecord> = [
    {
      title: 'appKey',
      dataIndex: 'appKey',
      ellipsis: true,
    },
    {
      title: 'appSecret',
      dataIndex: 'appSecret',
      ellipsis: true,
    },
    {
      title: '备注',
      dataIndex: 'note',
      ellipsis: true,
    },
    {
      title: '创建时间',
      dataIndex: 'createAt',
      ellipsis: true,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      ellipsis: true,
    },
    {
      key: 'action',
      title: '操作',
      render: (text, record) => (
        <Space>
          <LinkButton onClick={() => onEdit(record)}>编辑</LinkButton>
          <Popconfirm
            title="确定重置该 appSecret ?"
            onConfirm={() => onReset(record)}
            okText="是"
            cancelText="否"
          >
            <LinkButton danger>重置</LinkButton>
          </Popconfirm>
          <Popconfirm
            title="确定删除该 appSecret ?"
            onConfirm={() => deleteAppSecret(record.id)}
            okText="是"
            cancelText="否"
          >
            <LinkButton danger>删除</LinkButton>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const pagination: TablePaginationConfig = {
    showSizeChanger: true,
    showTotal: (total: number) => `共 ${total} 条`,
    pageSizeOptions: ['10', '20', '40'],
    defaultPageSize: 10,
    total: data?.totalElements || 0,
    current: data?.number + 1 || 1,
    onChange(page, pageSize?) {
      const { note, appKey } = form.getFieldsValue()
      getList({
        pageNo: page,
        pageSize,
        note: note || undefined,
        appKey: appKey || undefined,
      })
    },
  }

  return (
    <Layout className="cq-container">
      <Header className="cq-header">
        <div className="cq-header__main">
          <h1>AppSecret 管理</h1>
        </div>
      </Header>
      <Layout className="cq-main">
        <Content className="cq-content">
          <section className="cq-card cq-card--padding">
            <div className="cq-card__headerbar" style={{ height: 80 }}>
              <div>
                <Form name="appKeyForm" layout="inline" form={form}>
                  <Form.Item
                    label="appKey"
                    name="appKey"
                    rules={[
                      {
                        validateTrigger: [],
                        required: true,
                        message: '请输入appKey',
                      },
                      // 是否符合x.x.x的规则
                      // {
                      //   validateTrigger: [],
                      //   validator(_, value) {
                      //     if (!value) return Promise.resolve()
                      //     if (value.split('.').length !== 3) {
                      //       return Promise.reject('appKey格式不正确')
                      //     }
                      //     return Promise.resolve()
                      //   },
                      // },
                    ]}
                  >
                    <Input
                      placeholder="请输入appKey"
                      allowClear
                      style={{ width: 400 }}
                    />
                  </Form.Item>
                  <Form.Item label="备注" name="note">
                    <Input
                      placeholder="请输入备注"
                      allowClear
                      style={{ width: 200 }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space size="middle">
                      <Button
                        type="primary"
                        onClick={onGenerate}
                        loading={generateLoading}
                      >
                        生成
                      </Button>
                      <Button onClick={onQuery} loading={listLoading}>
                        查询
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </div>
            </div>

            <section className="cq-table">
              <Table
                dataSource={data?.content}
                columns={appSecretColumns}
                rowKey={(record) => record.id}
                pagination={pagination}
              />
            </section>
          </section>
          <UIModal
            title="AppSecret"
            visible={isPwdModalOpen}
            onCancel={closePwdModal}
            onOk={() => setIsPwdModalOpen(false)}
            width={510}
          >
            <Descriptions>
              <Descriptions.Item
                label="AppSecret"
                style={{ wordBreak: 'break-word' }}
              >
                <Text strong copyable>
                  {exposeAppSecret}
                </Text>
              </Descriptions.Item>
            </Descriptions>
          </UIModal>
          <UIModal
            title="编辑 AppSecret"
            visible={isEditModalOpen}
            onCancel={() => setIsEditModalOpen(false)}
            onOk={handleEditOk}
            width={510}
          >
            <Form
              name="editingForm"
              layout="inline"
              form={editForm}
              preserve={false}
            >
              <Form.Item label="备注" name="note">
                <Input
                  placeholder="请输入备注"
                  allowClear
                  style={{ width: 400 }}
                />
              </Form.Item>
            </Form>
          </UIModal>
        </Content>
      </Layout>
    </Layout>
  )
}
