import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch, useRequest } from 'src/hook'
import { Form, Input, message } from 'antd'
import { UIModal } from 'src/components'
import { fetchOrgTreeData } from '../organizationSlice'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { updateCompanyName } from 'src/api'
import { nameValidator } from 'src/util/nameValidator'

export const ModalChangeCompanyName = () => {
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.ModalChangeCompanyName)

  const { loading, run } = useRequest(updateCompanyName, {
    manual: true,
    onSuccess: () => {
      message.success('修改成功')
      dispatch(fetchOrgTreeData())
      dispatch(hideModal('ModalChangeCompanyName'))
    },
  })

  const inputRef = useRef<Input>(null)
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus()
    }
  }, [visible])

  return (
    <UIModal
      title="修改公司名称"
      visible={visible}
      onCancel={() => {
        dispatch(hideModal('ModalChangeCompanyName'))
      }}
      onOk={() => {
        form.validateFields().then(({ company }) => {
          run(company)
        })
      }}
      confirmLoading={loading}
      width={320}
    >
      <Form form={form}>
        <Form.Item
          name="company"
          rules={[
            { required: true, message: '请输入公司名称' },
            { validator: nameValidator },
          ]}
        >
          <Input ref={inputRef} placeholder="公司名称" />
        </Form.Item>
      </Form>
    </UIModal>
  )
}
