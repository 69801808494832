import React from 'react'
import { Descriptions } from 'antd'
import { TreeNode } from 'src/api'

const { Item } = Descriptions

interface NodeInfoProps {
  treeData: Partial<TreeNode>
}

export const NodeInfo = ({ treeData }: NodeInfoProps) => {
  return (
    <>
      {treeData.nodePath && (
        <Descriptions title="Node Info" column={1}>
          {Object.entries(treeData).map((entry) => (
            <Item label={entry[0]} key={entry[0]}>
              {JSON.stringify(entry[1])}
            </Item>
          ))}
        </Descriptions>
      )}
    </>
  )
}
