import {
  DocumentFilter,
  DocumentSelector,
  TextDocument,
  MonacoLanguageClient,
  testGlob,
  Position,
} from 'monaco-languageclient'
import { DataSourceType } from 'src/types'
import { editor } from 'monaco-editor'
import { SqlSplitter } from 'src/util'
interface IdidChangeLanguageContextParams {
  /**
   * @description 连接id
   * @type {(string | number)}
   * @memberof IdidChangeLanguageContextParams
   */
  connectionId: string | number
  /**
   * @description 数据源类型
   * @type {DataSourceType}
   * @memberof IdidChangeLanguageContextParams
   */
  dataSourceType: DataSourceType
  /**
   * @description 语言类型，同数据源可能会支持多种类型
   * @type {DataSourceType}
   * @memberof IdidChangeLanguageContextParams
   */
  languageType: DataSourceType
  /**
   * @description 用户id
   * @type {string}
   * @memberof IdidChangeLanguageContextParams
   */
  userId: string
  /**
   * @description 连接操作上下文 database
   * @type {string}
   * @memberof IdidChangeLanguageContextParams
   */
  operatingDatabase: string
  /**
   * @description 连接操作上下文 schema
   * @type {string}
   * @memberof IdidChangeLanguageContextParams
   */
  operatingSchema?: string
}

export interface IEnhancedMonacoLanguageClient extends MonacoLanguageClient {
  /**
   * @description client增强api
   * @memberof IToolsMonacoLanguageClient
   */
  enhancedApi: {
    editorInstance: editor.IStandaloneCodeEditor | null
    /**
     * @description 切换，需要同步解析上下文
     */
    didChangeLanguageContext?: (params: IdidChangeLanguageContextParams) => void
  }
}

export function enhanceLanguageClient(
  client: MonacoLanguageClient,
  editorInstance: editor.IStandaloneCodeEditor | null,
): IEnhancedMonacoLanguageClient {
  // 0 初始化 enhancedApi，挂载 editorInstance
  const enhancedClient = client as IEnhancedMonacoLanguageClient
  enhancedClient.enhancedApi = {
    editorInstance,
  }
  // 1 init didChangeLanguageContext
  enhancedClient.enhancedApi.didChangeLanguageContext =
    initDidChangeLanguageContext(enhancedClient)
  return enhancedClient
}

function initDidChangeLanguageContext(
  client: IEnhancedMonacoLanguageClient,
): IEnhancedMonacoLanguageClient['enhancedApi']['didChangeLanguageContext'] {
  const methodName = 'textDocument/changeLanguageContext'
  return function (options: IdidChangeLanguageContextParams) {
    const privateClient = client as any
    if (privateClient.state !== 3) {
      return
    }
    const targetModel = client.enhancedApi.editorInstance?.getModel()
    if (!targetModel) return
    const isMatch = matchModel(
      client?.clientOptions?.documentSelector || '',
      targetModel,
    )
    if (!isMatch) return
    const uri = targetModel?.uri.toString()
    const params = {
      textDocument: { uri },
      options,
    }
    client.sendRequest(methodName, params)
  }
}

// document Selector match Model
function matchModel(
  selector: string | DocumentFilter | DocumentSelector,
  model: editor.ITextModel,
): boolean {
  if (Array.isArray(selector)) {
    return selector.some((filter) => matchModel(filter, model))
  }
  const languageId = model.getModeId()
  if (DocumentFilter.is(selector)) {
    if (!!selector.language && selector.language !== languageId) {
      return false
    }
    if (!!selector.scheme && selector.scheme !== model.uri.scheme) {
      return false
    }
    if (!!selector.pattern && !testGlob(selector.pattern, model.uri.path)) {
      return false
    }
    return true
  }
  return selector === languageId
}

/**
 * @description 用于获取提示语句定位范围
 * @param {TextDocument} document
 * @param {Position} position
 * @returns
 */
export function getSqlRange(document: TextDocument, position: Position) {
  const model = editor.getModel(document.uri as any)
  if (!model) return
  // languageClient position从0开始计算，monacoEditor 从1开始计算
  const baseMonacoLine = position.line + 1
  const baseColumn = position.character
  const offset = model.getOffsetAt({
    lineNumber: baseMonacoLine,
    column: baseColumn,
  })
  const splitter = new SqlSplitter(model.getValue())
  const statements = splitter.splitWithPos()
  const matchStatementIndex = statements.findIndex(
    (state) => state.start <= offset && state.end >= offset,
  )
  if (matchStatementIndex === -1)
    return { startOffset: offset, endOffset: offset }
  return {
    startOffset: statements[matchStatementIndex].start,
    // 统一社区版企业版
    endOffset: statements[matchStatementIndex].end + 1,
  }
}
