import React, { useState, useMemo, useRef } from 'react'
import { Alert, Form, Input, Select } from 'antd'
import { GridApi } from '@ag-grid-community/core'
import loadable from '@loadable/component'
import type XLSX from 'xlsx'

import { UIModal } from 'src/components'
import { FormLayout } from 'src/constants'

const Xlsx = loadable.lib(() => import('xlsx'))

const ExportFormats = ['EXCEL'] as const

const ExportSelectOptions = ExportFormats.map((format) => ({
  value: format,
  label: format,
  disabled: false
}))

interface Iprops {
  gridApi: GridApi | null
  visible: boolean
  setVisible: (visible: boolean) => void
  [p: string]: any
}

export const AddSelectedResultExportModal = (props: Iprops) => {
  const { gridApi, visible, permissionResult, setVisible } = props
  const [confirmLoading] = useState<boolean>(false)
  const [addResultExportForm] = Form.useForm()

  const xlsxRef = useRef<any>()

  const selectRows = useMemo(() => {
    if (!gridApi || !visible) return []
    return gridApi.getSelectedRows()
  }, [gridApi, visible])

  const handleSubmitAddResultExport = () => {
    // todo 暂时只支持 excel
    if (!gridApi) return
    addResultExportForm
      .validateFields()
      .then((values) => {
        const xlsx: typeof XLSX = xlsxRef.current?.default
        if (!xlsx || !selectRows) return
        const { fileName } = values
        var ws = xlsx.utils.json_to_sheet(selectRows)
        const wb = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(wb, ws, fileName)
        xlsx.writeFile(wb, `${fileName}.xlsx`)
        setVisible(false)
      })
  }

  const initExportFormatValuie = ExportSelectOptions?.filter(i=>!i?.disabled)[0]?.value
  return (
    <UIModal
      title="选中行导出"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={handleSubmitAddResultExport}
      afterClose={() => addResultExportForm.resetFields()}
      width={480}
      confirmLoading={confirmLoading}
    >
      <Form form={addResultExportForm} {...FormLayout}>
        <Form.Item
          name="fileName"
          label="文件名"
          rules={[{ required: true, message: '请填写文件名' }]}
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name="exportFormat"
          label="导出格式"
          rules={[{ required: true, message: '请选择导出格式' }]}
          initialValue={initExportFormatValuie}
        >
          <Select options={ExportSelectOptions}></Select>
        </Form.Item>
        <Form.Item label="已选行数">
          {selectRows ? selectRows.length : 0}
        </Form.Item>
      </Form>
      <Xlsx ref={xlsxRef} />
    </UIModal>
  )
}
