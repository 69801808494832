import { Button, Tabs } from "antd";
import { RedoOutlined } from '@ant-design/icons'
import React, { useRef, useState } from "react";
import { OnCancelTable } from "../TableView/onCancelTable";
import { OnFinishTable } from "../TableView/onFinishTable";
import { OnLoadTable } from "../TableView/onLoadTable";
import styles from "../index.module.scss";

const MainContent = (props: { searchValue: string }): JSX.Element => {
  const { searchValue } = props;
	const operations = <Button icon={<RedoOutlined style={{padding: '4px 4px'}} />} onClick={handleRefresh} />
	const [activeKey, setActiveKey] = useState<string>('load')
	const setterMethod = useRef<any>(null)
	const refreshMethod = useRef<any>(null)

	function getRefreshMethod({setter, refresh}: {setter: React.Dispatch<React.SetStateAction<boolean>>, refresh: () => void}): void {
		setterMethod.current = setter
		refreshMethod.current = refresh
	}

	function handleRefresh() {
		setterMethod.current && setterMethod.current(true);
		refreshMethod.current && refreshMethod.current()
	}

	return (
		<Tabs
			size="small"
			className={styles.card}
			type="card"
			tabPosition="top"
			animated
			activeKey={activeKey}
			onChange={(key) => setActiveKey(key)}
			tabBarExtraContent={activeKey === "load" ? operations : ""}
		>
			<Tabs.TabPane
				tab="正在脱敏"
				key="load"
			>
        <OnLoadTable searchValue={searchValue} getRefreshMethod={getRefreshMethod} />
			</Tabs.TabPane>
			<Tabs.TabPane
				tab="脱敏成功"
				key="success"
			>
        <OnFinishTable searchValue={searchValue} />
			</Tabs.TabPane>
			<Tabs.TabPane
				tab="已取消"
				key="cancel"
			>
        <OnCancelTable searchValue={searchValue} />
			</Tabs.TabPane>
		</Tabs>
	);
};

export { MainContent };

