import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { store, persistor } from './store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { PersistGate } from 'redux-persist/integration/react'
import 'src/util/TsqlSplitter'

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

export const ProviderStore = ({children}:{children:ReactElement}) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}

const CloudQuery = () => {
  return (
    <ProviderStore>
      <App />
    </ProviderStore>
  )
}

ReactDOM.render(<CloudQuery />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
