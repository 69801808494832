// 可搜索树结构
import React, { useEffect, useMemo, useState } from 'react'
import { Tree,  } from 'antd'
import { Iconfont } from 'src/components'
import { treeToArray } from './util'
import classnames from 'classnames'
import styles from './index.module.scss'

// 选中标题样式处理
const higlightTitle = (title: any, searchValue: string) => {
  const index = title?.indexOf(searchValue)
  const beforeStr = title?.substring(0, index)
  const afterStr = title?.slice(index + searchValue?.length)

  let showTitle = title
  if (index > -1) {
    showTitle = (
      <>
        {beforeStr}
        <span style={{color:'#008dff'}}>{searchValue}</span>
        {afterStr}
      </>
    )
  }
  return showTitle
}

// 构造树结构
const generateTree = (data: any[], searchValue: string) => {
  const allId = data
    ?.map((i) => i.id)
    ?.filter((i, index, arr) => arr.indexOf(i) === index)
  const allParentId = data
    ?.map((i) => i.parentId)
    ?.filter((i, index, arr) => arr.indexOf(i) === index)
  
  const validateParentId = (item:any) => { 
    return !!data?.filter((i: any) => { 
      return i?.parentId===item?.id && item?.nodeType !== i?.nodeType
    })?.length
  }
	
  const filterData = data.filter((item, _, arr) => {
    // 自身是父级（且id和parentId不能重复）
    if (allParentId.includes(item.id) && validateParentId(item)) {
      item.children = arr.filter((i) => i.parentId === item.id)
    }
    // 没有父级
    if (!allId.includes(item.parentId) || !item.parentId) {
      return true
    }
    return false
  })

  const formatTree = (data: any[]): any[] =>
    data.map((item: any) => {
      item.key = item?.nodePath  // 唯一key
      item.title = higlightTitle(item.nodeName, searchValue)
      item.dataSourceType = item?.connection?.connectionType;
      if (item.children) {
        item.children = formatTree(item.children);
      }
      return { ...item }
    })
  return formatTree(filterData)
}

interface IProps {
  defaultSelectedNodeKeys?: string[];
  handleTreeSelect: (keys: any[], info: any) => void
  [p: string]: any
}

export const TreeComponent = (props: IProps) => {
  const {
    data=[],
    searchValue = '',
    defaultSelectedNodeKeys,
    handleTreeSelect,
    handleTreeDefaultSelect,
    queryTreeData,
  } = props

  const [expandedKeys, setExpandedKeys] = useState<any[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])
  const [curConnectionId, setCurConnectionId] = useState<string>()  // 记录当前操作数据的connectionId,加载连接级和其下级的树结构数据需要该参数

  const changeExpandedKeys = (data: any[], searchValue: string) => {
    if (['', undefined, null].includes(searchValue)) {
      return setExpandedKeys([])
    }
    // 获取匹配搜获内容的父级id设置expandedKeys,【注意】接口数据中连接级别下的数据的parentId是用nodePath构造的
    const searchValueParentIds = treeToArray(data)
      ?.filter((i: any) => i?.nodeName?.includes(searchValue))
      ?.map((i: any) => i?.parentId);

    const generateExpandedKeys = treeToArray(data)
      ?.filter(
        (i: any) =>
          searchValueParentIds.includes(i?.id) ||
          searchValueParentIds.includes(i?.nodePath) // 兼容连接级别下的数据的parentId用nodePath构造的场景
      )
      ?.map((i: any) => i?.nodePath);

    setExpandedKeys(generateExpandedKeys)
    setAutoExpandParent(true)
  }

	// 生成树
  const treeData = useMemo(() => {
    return generateTree(data, searchValue)?.filter(node => node?.children?.length)
  }, [data, searchValue])

  useEffect(() => { 
    if (data?.length) {
      changeExpandedKeys(data, searchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchValue])

  useEffect(() => {
    defaultSelectedNodeKeys && setSelectedKeys(defaultSelectedNodeKeys)
  },[JSON.stringify(defaultSelectedNodeKeys)])

  useEffect(() => {
    if (treeData?.length && !selectedKeys?.length) {
      const defaultSelectedItem = treeData.find(
        (item: any) => item.nodeType === 'datasource',
      )
      const defaultSelectedKeys = defaultSelectedItem?.key
      setSelectedKeys([defaultSelectedKeys])
      handleTreeDefaultSelect(defaultSelectedItem)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTreeDefaultSelect, treeData])

  const handleExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

  const handleSelect = (item: any[], info: any) => {
    if (info?.selected) {
      setSelectedKeys(item)
      handleTreeSelect(item, info)
    }
  }

  // 异步逐级加载数据 (连接及以下层级加载树内容)
  const handleLoadData = (node: any) => {
    if (!['datasource','group'].includes(node.nodeType)) {
      const {
        id,
        connection,
        nodeType,
        nodeName,
        nodePath,
        nodePathWithType,
      } = node
      let connectionId = curConnectionId
      if (nodeType === 'connection') { 
        connectionId = id
        setCurConnectionId(id)
      }
      const { connectionType } = connection || {}
      const params = {
        connectionId,
        connectionType,
        nodeType,
        nodeName,
        nodePath,
        nodePathWithType: nodePathWithType,
      }
      queryTreeData(params, id, nodeType)
    }
   
    return new Promise<void>((resolve) => {
      resolve()
    })
  }

  // 生成tree搜索标题
  const generatorSearchTitle = (
    nodeName: string,
    title: any,
    nodeType: string,
    nodeCount: number,
    testModel: number,
    dataSourceType: string,
  ) => {
    return (
      <>
        <Iconfont
          className={classnames(styles.mr4, styles.color008dff, {
            [styles.colorf00]: testModel === 1 && nodeType === "connection",
            [styles.colorgreen]: !!testModel && testModel !== 1 && nodeType === "connection",
          })}
          type={`${
            nodeType === "datasource"
              ? `icon-connection-${nodeName}`
              : nodeType === "group"
              ? "icon-shujukuwenjianjia"
              : nodeType === "connection"
              ? `icon-${dataSourceType}`
              : `icon-${nodeType}`
          } `}
        />
        <span className={styles.titleTxt}>
          {title}
          {["datasource", "group"].includes(nodeType) && `(${nodeCount})`}
        </span>
      </>
    );
  };

  // 渲染tree title完整内容
  const treeTitleRender = (node: any) => {
    const { nodeName, title, nodeType, layer, connection } = node;
    return (
      <div 
      className={styles.treeTitleItem}
      >
        {generatorSearchTitle(
          nodeName,
          title,
          nodeType,
          layer?.childCount,
          connection?.testModel,
          connection?.connectionType,
        )}
      </div>
    );
  }

  return (
    <Tree
      className={styles.treeWrap}
      titleRender={treeTitleRender}
      treeData={treeData}
      expandedKeys={expandedKeys}
      selectedKeys={selectedKeys}
      autoExpandParent={autoExpandParent}
      onSelect={handleSelect}
      onExpand={handleExpand}
      loadData={handleLoadData}
    />
  )
}