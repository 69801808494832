import React from 'react';
import classNames from 'classnames';
import { Layout, Breadcrumb } from 'antd';
import Watermark from '@pansy/react-watermark'
import { useRequest } from 'src/hook';
import { Link, useHistory } from 'react-router-dom';
import { getWatermarkValue } from 'src/api';
import { CustomColumnTable } from './CustomColumnTable';
import 'src/styles/layout.scss';
import styles from './index.module.scss';

const { Header, Content } = Layout

export const AuditOperationPage = () => {
  const { data = [] } = useRequest(getWatermarkValue)
  const history = useHistory()
  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header" title='操作记录'>
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>审计分析</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item><Link to="/audit_view">审计概览</Link></Breadcrumb.Item>
          <Breadcrumb.Separator>/</Breadcrumb.Separator>
          <Breadcrumb.Item>操作记录</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main">
        <Content
          className={classNames('cq-content', { [styles.operationContent]: true })}
        >
          <CustomColumnTable />
          <Watermark
            opacity={0.2}
            text={data.join(' ')}
            zIndex={99} />
        </Content>
      </Layout>
    </Layout>
  )
}
