/*
 * @Author: yangchao yangchao@bintools.cn
 * @Date: 2023-09-08 14:35:50
 * @LastEditors: yangchao yangchao@bintools.cn
 * @LastEditTime: 2023-09-08 17:14:38
 * @FilePath: /cq-enterprise-frontend/src/api/rowFilter/index.tsx
 * @Description: 
 */
import { fetchGet, fetchPost } from "../customFetch";

interface TableInfo {
  connectionId: number;
  connectionType: string;
  nodeType: string;
  nodeName: string;
  nodePath: string;
  nodePathWithType: string;
}

interface TableTitle {
  connectionId: number,
  connectionType: string,
  databaseName: string,
  schemaName: string,
  tableName:string
}

interface FilterRuleParams {
  nodePath: string;
  nodePathWithType: string;
  dataSourceType: string;
}

interface AddFilterRuleParams {
  content: string;
  nodePath: string;
  nodePathWithType: string;
  dataSourceType: string;
}

/**
 * 查询 表的列字段,类型,数据样例
 * @param params
 * @returns
 */
export function getRowFilterTableInfo(params: TableInfo) {
  return fetchPost("/dms/rowFilter/tableInfo", params);
}

/**
 * @description: 获取表的其他信息，如注释
 * @param {TableInfo} params
 * @return {*}
 */
export function getTableTitleInfo(params: TableTitle) {
  return fetchPost("/dms/meta/table/info", params);
}

export function getTableListBySchema(params: TableInfo) {
  return fetchPost("/dms/rowFilter/tableList", params);
}

/**
 * 查询行过滤规则(连接,SCHEMA,表级别)
 * @param params
 * @returns
 */
export function getFilterRule(params: FilterRuleParams) {
  return fetchPost("/user/rowFilter/list", params);
}

/**
 * 查询行过滤规则(数据源)
 * @param params
 * @returns
 */
export function getFilterRuleInDatasource(params: { dataSourceType: string }) {
  return fetchPost("/user/rowFilter/dataSource/list", params);
}

/**
 * 查询行过滤规则(组级别)
 * @param params
 * @returns
 */
export function getFilterRuleInGroup(params: { groupId: number }) {
  return fetchPost("/user/rowFilter/group/list", params);
}

/**
 * 添加行过滤规则
 * @param params
 * @returns
 */
export function addFilterRule(params: AddFilterRuleParams) {
  return fetchPost("/user/rowFilter/add", params);
}

/**
 * 测试行过滤规则
 * @param params 
 * @returns 
 */
export function testFilterRule(params: AddFilterRuleParams) {
  return fetchPost("/user/rowFilter/test", params);
}

/**
 * 测试行过滤规则
 * @param params 
 * @returns 
 */
export function testSqlFilterRule(params: AddFilterRuleParams) {
  return fetchPost("/user/rowFilter/testSql", params);
}

/**
 * 删除行过滤规则
 * @param params
 * @returns
 */
export function removeFilterRule(params: { id: string }) {
  return fetchPost("/user/rowFilter/delete", params);
}

/**
 * 修改行过滤规则状态
 * @param params.id 接口返回的唯一id
 * @param params.status 0 禁用  1启用
 * @returns
 */
export function updateFilterRule(params: { id: string; status: 0 | 1 }) {
  return fetchPost("/user/rowFilter/status", params);
}

/**
 * 查询所有内置参数
 * @returns
 */
export function queryExpression() {
  return fetchGet("/user/rowFilter/expression");
}
