import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, Col, Row, Upload, Form, Typography, message, Input } from 'antd'
import { useDispatch, useRequest, useSelector } from 'src/hook'
import { FormInstance } from 'antd/lib/form'
import { PersonalFormContext } from '../PersonalFormContext'
import { getUserDetail, IMaskStatus, updateUserSupplementInfo, changeUserName } from 'src/api'
import ImageCropper from 'antd-image-cropper'
import { Iconfont, LinkButton } from 'src/components'
import styles from './index.module.scss'
import { CopyOutlined, FormOutlined } from '@ant-design/icons'
import { UploadFile, UploadProps } from 'antd/lib/upload/interface'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { setUserInfo } from 'src/appPages/login/loginSlice'
import { setUserAvatar } from 'src/store/extraSlice/userAvatarSlice'

export const InformationDetails = () => {
  const dispatch = useDispatch()
  const form = useContext(PersonalFormContext) as FormInstance
  const baseAddress = "/user";
  const { userInfo } = useSelector((state) => state.login)
  const userId = userInfo?.userId
  const [userInfoState, setUserInfoState] = useState<any>()
  const [maskStatus, setMaskStatus] = useState<IMaskStatus>({
    emailMask: true,
    phoneMask: true,
  })
  const [editing, setEditing] = useState(false)
  const [editingUserName, setEditingUserName] = useState(false)
  const [logoImg, setlogoImg] = useState<UploadFile[]>([
    //@ts-ignore
    {
      uid: '-1',
      name: 'image.png',
      status: 'done',
      url: '',
    }
  ]);
  const userInfoEntries = Object.entries(userInfoState?.user || {})
  userInfoEntries.push(['dept', userInfoState?.departmentName])
  const getUserInfo = useCallback(
    () => getUserDetail(maskStatus, userId!).then((e) => {
      if (e) {
        dispatch(setUserInfo({ ...userInfo, userName: e?.user?.userName }))
        form.setFields([
          {
            name: 'userName',
            value: e?.user?.userName,
          }
        ])
        form.setFieldsValue({
          "parameterValues": e?.user?.parameterValues?.map((a: any) => {
            const userValue = a;
            return userValue
          })
        })

        let img = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: e?.user && e?.user?.avatarUrl ? baseAddress + e?.user?.avatarUrl : '',
        }
        logoImg.pop()
        //@ts-ignore
        logoImg.push(img)
        setlogoImg([...logoImg])
      }
      setUserInfoState(e)
    }),
    [userId, maskStatus],
  )
  // 修改补充信息
  const { run: undateInfo } = useRequest(updateUserSupplementInfo, {
    manual: true,
    onSuccess() {
      setEditing(false)
      message.success('修改成功')
      getUserInfo()
    }
  })

  // 修改姓名
  const { run: updateUserName } = useRequest(changeUserName, {
    manual: true,
    onSuccess() {
      message.success('修改成功')
      setEditingUserName(false)
      getUserInfo()
    }
  })

  const handleMask = (key: keyof IMaskStatus) => {
    setMaskStatus({ ...maskStatus, [key]: !maskStatus[key] })
  }

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])
  if (!userInfoState) return null

  // const beforeUpload = (file: RcFile) => {
  // const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpg';
  // if (!isJpgOrPng) {
  //   message.error('只支持png|jpg格式的图片');
  // }
  // const isLt2M = file.size / 1024 < 200;
  // if (!isLt2M) {
  //   message.error('图片不能超过200kb!');
  // }
  // return isJpgOrPng
  // };

  const onChangeLogo: UploadProps['onChange'] = ({ fileList: newFileList, event, file }) => {
    if (file?.response?.resCode === 10000) {
      message.success('头像上传成功')
      file.url = baseAddress + file?.response?.data
      dispatch(setUserAvatar(file.url))
      setlogoImg([file]);
    } else {
      file?.response?.resMsg && message.error(file?.response?.resMsg)
    }
  };

  const setSave = () => {
    form.validateFields(['dynamicFieldName']).then((values) => {
      const { dynamicFieldName } = values
      let parameterValues: any[] = []
      form.getFieldValue("parameterValues")?.forEach((item: any) => {
        parameterValues.push({
          id: item?.id,
          parameter: item.parameter,
          value: dynamicFieldName && dynamicFieldName[item?.parameter?.tagName] ? dynamicFieldName[item?.parameter?.tagName] : ''
        })
      })
      undateInfo({
        userId,
        parameterValues
      })
    })
  }

  const onBlurUserName = (e: any) => {
    form.validateFields(['userName']).then(({ userName }) => {
      const params = {
        userId,
        userName
      }
      updateUserName(params)
    })
  }

  const imgprops = {
    aspectRatio: 1 / 1,
    grid: true,
    crop: true,
    modalTitle: '上传图片',
    modalOk: '上传',
    minWidth: 50,
    minHeight: 50,
  }

  const extraOperations = (
    <div className={styles.settingCardExtra}>
      {editing ? (
        [
          <LinkButton key="save" onClick={setSave}>
            保存
          </LinkButton>,
          <LinkButton
            key="cancel"
            onClick={() => {
              form.resetFields()
              form.setFields([
                {
                  name: 'userName',
                  value: userInfoState?.user?.userName,
                }
              ])
              form.setFieldsValue({
                "parameterValues": userInfoState?.user?.parameterValues?.map((a: any) => {
                  const userValue = a;
                  return userValue
                })
              })
              setEditing(false)
            }}
          >
            取消
          </LinkButton>,
        ]
      ) : (
        <LinkButton onClick={() => setEditing(true)}>编辑</LinkButton>
      )}
    </div>
  )

  return (
    <div>
      <section className="cq-new-card flow-card" style={{ marginBottom: '10.5px' }}>
        <div className="cq-card__headerbar">
          <h3 className="cq-card__title">个人信息</h3>
        </div>
        <section className="card__content">
          <Card
            bordered={false}
            bodyStyle={{ display: 'flex' }}
          >
            <div className={styles.portraitWrap}>
              <ImageCropper {...imgprops}>
                <Upload
                  className={classNames(styles.uploadImg)}
                  action="/user/users/uploadAvatar"
                  listType="picture-card"
                  showUploadList={false}
                  fileList={logoImg}
                  // beforeUpload={beforeUpload}
                  onChange={onChangeLogo}
                >
                  {
                    logoImg && logoImg[0]?.url ? <img src={logoImg[0]?.url} alt="avatar" style={{ width: '100%', height: '100%', borderRadius: '50%' }} /> : '上传头像'
                  }
                </Upload>
              </ImageCropper>
              <div>{userInfoState?.user?.userId} <CopyOutlined onClick={() => {
                copy(userInfoState?.user?.userId)
                message.success('复制成功')
              }}
              /></div>
            </div>
            <div className={styles.infoWrap}>
              <Row style={{alignItems: 'center'}}>
                <Col span={10} style={{ display: 'flex', alignItems: 'center' }}>
                  <label className={styles.tagNames}>姓名：</label>
                  <Form.Item>
                    <Form.Item
                      name="userName"
                      noStyle
                      hidden={!editingUserName}
                      rules={[{ required: true, message: '姓名不可为空' }]}
                    >
                      <Input
                        autoFocus
                        placeholder="请输入姓名"
                        style={{ width: '150px' }}
                        onBlur={onBlurUserName}
                      />
                    </Form.Item>
                  </Form.Item>
                  {
                    !editingUserName && <div>
                      <span style={{ display: 'inline-block', width: '120px' }}>{userInfoState?.user?.userName}</span>
                      <FormOutlined onClick={() => {
                        setEditingUserName(true)
                      }} />
                    </div>
                  }
                </Col>
                <Col span={14}>
                  <label className={styles.tagNames}>系统角色：</label>
                  <span>
                    {
                      userInfoState?.systemRoles && userInfoState?.systemRoles.length ? userInfoState?.systemRoles?.join('、') : '-'
                    }
                  </span>
                </Col>
              </Row>
              <Row className={styles.mb17}>
                <Col span={10}>
                  <label className={styles.tagNames}>部门：</label>
                  <span>{userInfoState?.departmentName}</span>
                </Col>
                <Col span={14}>
                  <label className={styles.tagNames}>注册时间：</label>
                  <span>{userInfoState?.user?.createdAt}</span>
                </Col>
              </Row>
              <Row className={styles.mb17}>
                <Col span={10}>
                  <label className={styles.tagNames}>邮箱：</label>
                  <span>{userInfoState?.user?.email}</span>
                  <LinkButton onClick={() => handleMask('emailMask')}>
                    <Iconfont type={`icon-kejian-${!maskStatus.emailMask}`} />
                  </LinkButton>
                </Col>
                <Col span={14}>
                  <label className={styles.tagNames}>上次登录时间：</label>
                  <span>{userInfoState?.lastLoginTime}</span>
                </Col>
              </Row>
              <Row className={styles.mb17}>
                <Col span={10}>
                  <label className={styles.tagNames}>手机号：</label>
                  <span>{userInfoState?.user?.telephone}</span>
                  <LinkButton onClick={() => handleMask('phoneMask')}>
                    <Iconfont type={`icon-kejian-${!maskStatus.phoneMask}`} />
                  </LinkButton>
                </Col>
              </Row>
            </div>
          </Card>
        </section>
      </section>
      <section className="cq-new-card flow-card" style={{ marginBottom: '10.5px' }}>
        <div className="cq-card__headerbar">
          <h3 className="cq-card__title">补充信息</h3>
          {
            userInfoState?.user?.parameterValues && userInfoState?.user?.parameterValues.length ? extraOperations : null
          }
        </div>
        <section className="card__content">
          <Card
            className={styles.settingCardContent}
            bordered={false}
          // loading={loading}
          >
            {
              form.getFieldValue("parameterValues") && form.getFieldValue("parameterValues")
                .map((item: any, index: number) => {
                  return <div key={index}>
                    {
                      !editing && <div className={classNames(styles.mb10, styles.flex)}>
                        <p className={classNames(styles.flex2, styles.textAlignRight)}>{item?.parameter?.parameterName} : </p>
                        <p className={classNames(styles.flex8, styles.ml30)}> {item?.value}</p>
                      </div>
                    }

                    <Form.Item
                      hidden={!editing}
                      initialValue={item?.value} name={["dynamicFieldName", item?.parameter?.tagName as string]}
                      label={item?.parameter?.parameterName}
                      rules={[
                        { required: item?.parameter?.needed, message: `请输入${item?.parameter?.parameterName}名称` },
                      ]}>
                      <Input
                        placeholder={`请输入${item?.parameter?.parameterName}`}
                      />
                    </Form.Item>
                  </div>
                })
            }
          </Card>
        </section>
      </section>
    </div>
  )
}
