import React, { useState, useEffect, useRef, useCallback } from 'react'
import { ConfigProvider, Form, Input, Row, Table } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table/interface'
import { LinkButton, CustomInputNumber } from 'src/components'

interface EditableCellProps {
  record: ConnectionMembersValue
  dataIndex: 'connectionUrl' | 'connectionPort'
  editing: boolean
  title: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = (props) => {
  const { record, dataIndex, editing, title, children, ...restProps } = props

  const inputRef = useRef<Input>(null)

  useEffect(() => {
    if (editing && dataIndex === 'connectionUrl') {
      inputRef.current?.focus()
    }
  }, [dataIndex, editing])

  if (!editing) {
    return <td {...restProps}>{children}</td>
  }

  let input
  if (dataIndex === 'connectionUrl') {
    input = <Input ref={inputRef} />
  }
  if (dataIndex === 'connectionPort') {
    input = <CustomInputNumber inputRef={inputRef} maxLength={5} />
  }
  return (
    <td {...restProps}>
      <Form.Item
        name={dataIndex}
        rules={[{ required: true, message: `请输入${title}` }]}
        style={{ margin: 0 }}
      >
        {input}
      </Form.Item>
    </td>
  )
}

type ConnectionMembersValue = {
  connectionUrl: string
  connectionPort: string
}
interface ConnectionMembersTableProps {
  value?: ConnectionMembersValue[]
  onChange?: (value: ConnectionMembersValue[]) => void
}

const columns = [
  {
    title: '地址',
    dataIndex: 'connectionUrl',
    width: '50%',
  },
  {
    title: '端口',
    dataIndex: 'connectionPort',
    width: '30%',
  },
]

export const ConnectionMembersTable: React.FC<ConnectionMembersTableProps> = (
  props,
) => {
  const { value, onChange } = props
  const [dataSource, setDataSource] = useState<any[]>([])
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [connectionMembersForm] = Form.useForm()

  const isAdding = editingIndex === value?.length

  useEffect(() => {
    // dataSource is derived by value prop
    if (value !== undefined) {
      setDataSource(value)
    }
  }, [value])

  const handleStartEdit = useCallback(
    (index: number) => {
      connectionMembersForm.setFieldsValue(dataSource[index])
      setEditingIndex(index)
    },
    [connectionMembersForm, dataSource],
  )

  const handleDelete = useCallback(
    (index: number) => {
      const changedValue = dataSource.filter(
        (_item, itemIndex) => itemIndex !== index,
      )
      if (onChange) {
        onChange(changedValue)
      }
    },
    [dataSource, onChange],
  )

  const handleSave = useCallback(async () => {
    if (!value || !onChange) return
    try {
      const newValue = [...value]
      const record = (await connectionMembersForm.validateFields()) as ConnectionMembersValue
      if (isAdding) {
        newValue.push(record)
      } else {
        newValue.splice(editingIndex!, 1, record)
      }
      onChange(newValue)
      setEditingIndex(null)
    } catch {}
  }, [connectionMembersForm, editingIndex, isAdding, value, onChange])

  const handleCancel = useCallback(() => {
    if (value) {
      setDataSource(value)
    }
    setEditingIndex(null)
  }, [value])

  const columnsWithCellContext: ColumnsType<ConnectionMembersValue> = columns.map(
    (column) => ({
      ...column,
      onCell: (record, index) => ({
        record,
        editing: editingIndex === index,
        title: column.title,
        dataIndex: column.dataIndex,
      }),
    }),
  )

  const columnsWithAction: ColumnsType<ConnectionMembersValue> = [
    ...columnsWithCellContext,
    {
      key: 'action',
      width: '20%',
      render: (_text, _record, index) => {
        let buttonList = null
        if (editingIndex === null) {
          buttonList = [
            <EditOutlined
              key="edit"
              onClick={() => {
                handleStartEdit(index)
              }}
            />,
            <DeleteOutlined
              key="delete"
              onClick={() => {
                handleDelete(index)
              }}
            />,
          ]
        } else if (editingIndex === index) {
          buttonList = [
            <CheckOutlined
              key="save"
              onClick={() => {
                handleSave()
              }}
            />,
            <CloseOutlined
              key="cancel"
              onClick={() => {
                handleCancel()
              }}
            />,
          ]
        }
        return <Row justify="space-around">{buttonList}</Row>
      },
    },
  ]

  return (
    <ConfigProvider renderEmpty={() => <div style={{ height: 24 }}></div>}>
      <Form form={connectionMembersForm} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={columnsWithAction}
          dataSource={dataSource.map((row, index) => ({ ...row, key: index }))}
          pagination={false}
          size="small"
        />
      </Form>
      <LinkButton
        onClick={() => {
          const newRecord: ConnectionMembersValue = {
            connectionUrl: '',
            connectionPort: '',
          }
          connectionMembersForm.setFieldsValue(newRecord)
          setDataSource(dataSource.concat(newRecord))
          setEditingIndex(dataSource.length)
        }}
        disabled={editingIndex !== null}
      >
        添加
      </LinkButton>
    </ConfigProvider>
  )
}
