import React, { useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { useSelector } from 'src/hook'

export function SettingTwoForm({ form }: { form?: FormInstance }) {
  const { fileType } = useSelector((state) => state.textImport);

  useEffect(() => {
   
      form?.setFieldsValue({dateSort: 'YMD', dateSeparator: '-'});
  },[fileType, form])

  function posIntValidator(_:any, value: number) {
    const posIntReg = /(^[0-9]\d*$)/
    if (!value) {
      return Promise.reject('不能为空')
    }
    if (!posIntReg.test(String(value))) {
      return Promise.reject('只能输入正整数')
    }
    return Promise.resolve()
  }
  return (
    <Form form={form} labelCol={{ span: 8 }} labelAlign="left">
      <Form.Item
        label="字段名行"
        name="fieldRow"
        initialValue={1}
        required
        rules={[{ validator: posIntValidator }]}
      >
        <Input min={1} type="number"></Input>
      </Form.Item>
      <Form.Item
        label="第一个数据行"
        name="firstRow"
        initialValue={2}
        required
        rules={[{ validator: posIntValidator }]}
      >
        <Input min={1} type="number"></Input>
      </Form.Item>
      <Form.Item
        label="最后一个数据行"
        name="lastRow"
        rules={[{ validator: (_:any, value: number) => {
          const posIntReg = /(^[0-9]\d*$)/
          if (value && !posIntReg.test(String(value))) {
            return Promise.reject('只能输入正整数')
          }
          return Promise.resolve()
        } }]}
      >
        <Input min={1} type="number"></Input>
      </Form.Item>
      {/* 以下全是必填项 */}
      <Form.Item
        label="日期排序"
        name="dateSort"
        rules={[{ required: true, message: '请选择日期排序' }]}
        initialValue="YMD"
      >
        {/* MDY, DMY, YMD */}
        <Select options={dateTypeOpitons}></Select>
      </Form.Item>
      <Form.Item
        label="日期分隔符"
        name="dateSeparator"
        initialValue="-"
        rules={[{ required: true, message: '不能为空' }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="时间分隔符"
        name="timeSeparator"
        initialValue=":"
        rules={[{ required: true, message: '不能为空' }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="小数点符号"
        name="decimalDot"
        initialValue="."
        rules={[{ required: true, message: '不能为空' }]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="日期时间排序"
        name="dateTimeSort"
        rules={[{ required: true, message: '请选择日期时间排序' }]}
        initialValue="DATE TIME"
      >
        {/* date time, date time zone  */}
        <Select>
          <Select.Option value={'DATE TIME'}>日期 时间</Select.Option>
          <Select.Option value={'DATE TIME ZONE'}>日期 时间 时区</Select.Option>
          <Select.Option value={'DATE'}>日期</Select.Option>
          <Select.Option value={'TIME'}>时间</Select.Option>
        </Select>
      </Form.Item>
      {fileType !== 'xls' && fileType !== 'xlsx' && (
        <Form.Item
          label="二进制数据编码"
          name="binaryCode"
          rules={[{ required: true, message: '请选择二进制数据编码' }]}
          initialValue="HEX"
        >
          {/* base64, hex */}
          <Select options={binaryCodeTypes}></Select>
        </Form.Item>
      )}
    </Form>
  )
}

const dateTypeOpitons = ['MDY', 'DMY', 'YMD'].map((d) => ({
  label: d,
  value: d,
}))

const binaryCodeTypes = ['BASE64', 'HEX'].map((b) => ({
  label: b,
  value: b,
}))
