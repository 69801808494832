import { Modal } from 'antd';
import React from 'react';

interface Iprops {
  isModalVisible: boolean
  errorLog: string
  setIsModalVisible: (b: boolean) => void
}

export const ErrorLogModal = (props: Iprops) => {
  const { isModalVisible, errorLog, setIsModalVisible } = props

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal 
        title="日志信息" 
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
      >
        <p>
        {
          errorLog ? 
          errorLog
          : "暂无信息 ...... "
        }
        </p>
      </Modal>
    </>
  );
};