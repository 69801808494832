import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'src/hook'
import { UIModal } from 'src/components'
import { TextImportWizard } from './TextImportWizard'
import { hideModal } from 'src/store/extraSlice/modalVisibleSlice'
import { Descriptions, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const ModalTextImportWizard = () => {
  const dispatch = useDispatch()
  const visible = useSelector((state) => state.modal.ModalTextImportWizard)
  const submitterRef = useRef<HTMLDivElement>(null)

  return (
    <UIModal
      title={<TitleTooltip />}
      visible={visible}
      footer={<div ref={submitterRef}></div>}
      onCancel={() => dispatch(hideModal('ModalTextImportWizard'))}
      width={800}
      style={{ overflow: 'auto' }}
    >
      <TextImportWizard submitterRef={submitterRef} />
    </UIModal>
  )
}

const TitleTooltip = () => {
  return (
    <>
      <span>文本导入</span>
      <Tooltip title={<HelpMessage />} placement="leftBottom" color="white">
        <QuestionCircleOutlined />
      </Tooltip>
    </>
  )
}

const HelpMessage = () => {
  return (
    <Descriptions
      size="small"
      column={1}
    >
      <Descriptions.Item label="编码">
        字符编码格式，txt和csv文件需指定
      </Descriptions.Item>
      <Descriptions.Item label="记录行分隔符">
        换行符。类型：CR（\r）、LF(\n)、CRLF(\r\n)
      </Descriptions.Item>
      <Descriptions.Item label="字段分隔符">
        分割一行的字段。类型：逗号（,）、分号（;）、制表符（\t）
      </Descriptions.Item>
      <Descriptions.Item label="字段识别符">
        识别字符串字段，可以用单引号（'）或者双引号（"）
      </Descriptions.Item>
      <Descriptions.Item label="字段名行">
        1表示第一行是字段名, 0表示没有字段名
      </Descriptions.Item>
      <Descriptions.Item label="第一个数据行">
        2表示从第二行开始导入数据
      </Descriptions.Item>
      <Descriptions.Item label="最后一个数据行">
        导入数据的截至行数
      </Descriptions.Item>
      <Descriptions.Item label="日期排序">
        年月日顺序:YMD（年月日）
      </Descriptions.Item>
      <Descriptions.Item label="日期分隔符">
        分割年月日:2021-12-12，分隔符为-
      </Descriptions.Item>
      <Descriptions.Item label="时间分隔符">
        分割时分秒; 12:12:12，分隔符为:
      </Descriptions.Item>
      <Descriptions.Item label="小数点符号">
        时间小数标点符号；一般为点号（.）
      </Descriptions.Item>
      <Descriptions.Item label="日期时间排序">
        指定日期和时间的顺序
      </Descriptions.Item>
      <Descriptions.Item label="二进制数据编码">
        二进制数据的编码形式
      </Descriptions.Item>
    </Descriptions>
  )
}
