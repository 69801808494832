import React, { useState } from 'react'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'

interface CustomInputNumberProps extends Omit<InputProps, 'onChange'> {
  value?: string
  onChange?: (value: string) => void
  inputRef?: React.RefObject<Input>
  type?: string
}

export const CustomInputNumber: React.FC<CustomInputNumberProps> = (props) => {
  const { value, onChange, inputRef, type, ...rest } = props
  const [inputValue, setInputValue] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    if (Number.isNaN(Number(newValue))) {
      return
    }
    // int 类型的值
    if (type === "int") {
      console.log(Number.isInteger(Number(newValue)))
      if (!Number.isInteger(Number(newValue))) {
        // 不是 int 整型
        return
      } else if (Number(newValue) > 2147483647 || Number(newValue) < -2147483648) {
        // 超出 int 范围的情况
        return
      } else if (newValue.endsWith(".")) {
        setInputValue(Number(newValue).toString())
        return 
      }
      // 正数
    } else if (type === "positiveNum") {
      const regex = /^[0-9]*\.?[0-9]+$/;
      if (regex.test(newValue)) {
        setInputValue(newValue);
      }
    }
    setInputValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  return (
    <Input
      {...rest}
      value={value !== undefined ? value : inputValue}
      onChange={handleChange}
      ref={inputRef}
    />
  )
}
