/*
 * @Author: fuzhenghao
 * @Date: 2022-11-16 16:52:21
 * @LastEditTime: 2022-11-17 15:05:53
 * @LastEditors: fuzhenghao
 * @Description:
 * @FilePath: \cq-enterprise-frontend\src\pageTabs\flowPages\FlowDesginer.tsx
 */
import React, { useEffect, useMemo, useState } from 'react'
import { Card } from 'antd'
import { useRequest } from 'src/hook'
import { IKeyProps, getFlowModel, getFlowModelDetail, saveFlowModel } from 'src/api/flow'
import ProcessDesign from 'src/components/Bpmn/ProcessManage/ProcessDesign'
import { SimpleBreadcrumbs } from 'src/components'
import { generateFlowType } from './utils/generateFlowType'
import { MENU_FLOW, DATA_CHANGE_FLOW } from 'src/constants'
import styles from './flowPage.module.scss'

export const FlowDesginer = React.memo(() => {
  const [currentFlow, setCurrentFlow] = useState<IKeyProps>(generateFlowType());

  const { data: processList, run } = useRequest(
    // 数据变更和流程都用的同一个组件，根据条件判断需要调用哪个接口
    () => getFlowModel(currentFlow)
  )

  const breadcrumbData = useMemo(() => {
    const flowType = generateFlowType()
    const pageTitle = flowType === 'apply' ? MENU_FLOW : DATA_CHANGE_FLOW
    setCurrentFlow(flowType)

    return [
      { title: pageTitle },
      {
        title: "自定义流程设计",
      }
    ]
  }, [location.pathname]);


  return (
    <div className={styles.flowDesginWrap}>
      <div style={{ padding: '0 20px' }}>
        <SimpleBreadcrumbs items={breadcrumbData} />
      </div>
      <Card
        className={styles.contentCard}
        // title="自定义流程设计"
        bordered={false}
        bodyStyle={{ padding: 0, flex: 1, overflow: 'hidden' }}
      >
        <ProcessDesign
          currentFlow={currentFlow}
          processList={processList}
          requestModelListReset={run}
          requestModelSaving={saveFlowModel}
          requestModelSelect={getFlowModelDetail}
        />
      </Card>
    </div>

  )
})
