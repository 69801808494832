import React, { useEffect, useState } from 'react'
import { useRequest } from 'src/hook'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row, Tooltip } from 'antd'
import styles from './index.module.scss'
import classNames from 'classnames'
import ActiveUserChart from './Charts/ActiveUserChart'
import { getActiveUserCard } from 'src/api/getNewData';
import { QuestionCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';

export const UserCard = () => {

  const [activeUserChartData, setActiveUserChartData] = useState<any[]>([]);
  const [activeUserUnit, setActiveUserUnit] = useState<string>("DAY");  // 活跃用户card

  // 活跃用户Card Active users
  const {
    data: activeUserData = {
      allUser: 0, //用户总量
      onlineUser: 0, //当前用户数
      onlinePercentage: 0, //在线百分比
      offlinePercentage: 0, //离线百分比
      growth: 0, // 环比增长
    },
    loading: activeUserLoading,
    run: queryActiveUserData,
  } = useRequest(getActiveUserCard, {
    manual: true,
  });

  useEffect(() => {
    queryActiveUserData({ unit: activeUserUnit }).then((res: { onlinePercentage: string }) => {
      setActiveUserChartData([{
        type: "在线",
        value: Number(res.onlinePercentage),
      }])
    });
  }, []);

  useEffect(() => {
    queryActiveUserData({ unit: activeUserUnit }).then((res: { onlinePercentage: string }) => {
      setActiveUserChartData([{
        type: "在线",
        value: Number(res.onlinePercentage),
      }])
    });
  }, [activeUserUnit]);

  // 活跃用户Card
  const {
    allUser, //用户总量
    onlineUser, //当前用户数
    onlinePercentage, //在线百分比
    offlinePercentage, //离线百分比
    growth: growth_activeUser, // 环比增长
  } = activeUserData;

  const history = useHistory();

  const operateLinkMap = new Map([
    ["用户总量", "/suser_audit"],
    ["当前用户数", "/suser_audit"],
  ]);

  // 跳转
  const toLink = (link: any, str: string) => {
    let state: any;
    let timeKey: any = activeUserUnit.toLocaleLowerCase();
    let timeRange = [dayjs().startOf(timeKey).valueOf(),
    dayjs().endOf(timeKey).valueOf(),];
    switch (str) {
      case "用户总量":
        state = {
          timeRange: timeRange,
          tertiaryDirectoryMark: 1
        };
        break;
      case "当前用户数":
        state = {
          onLineFlag: 1,
          isOnLineUser: 1,
          tertiaryDirectoryMark: 1,
        };
        break;
    }
    history.push({
      pathname: link,
      state: state,
    })
  }


  const renderOperateCardDiv = (text: string, color: string, amount: any, colSpan: number) => {
    let res: any = [];
    for (let [key, value] of operateLinkMap) {
      if (key === text) {
        if (value) {
          res.push(
            <Col span={colSpan} className={styles.countNumber} key={key}>
              <div onClick={() => toLink(value, key)} className={classNames(styles.dataCardHover, styles.dataCard)}>
                <span style={{ color: color }}>{amount}</span>
                <div className={classNames(styles.countText)}>{key}</div>
              </div>
            </Col>
          )
        } else {
          res.push(
            <Col span={colSpan} className={styles.countNumber} key={key}>
              <div className={classNames(styles.dataCard)}>
                <span style={{ color: color }}>{amount}</span>
                <div className={classNames(styles.countText)}>{key}</div>
              </div>
            </Col>
          )
        }
      }
    }
    return (res);
  }

  const RowContent = (props: {
    dataCount?: any
    dataText?: any
    color?: any
    colCount: number
    colSpan: number
    countSize?: string
  }) => {
    const {
      dataCount,
      dataText,
      color,
      colCount,
      colSpan,
      countSize,
    } = props;

    const res: any = [];
    let textIndex = 0;
    let colorIndex = 0;
    dataCount.map((item: any, index: React.Key | null | undefined) => {
      let data_text = dataText[textIndex++];
      let data_color = color[colorIndex++];

      if (countSize === "small") {
        res.push(
          <Col span={colSpan} className={styles.countNumberSize} key={index + item}>
            {
              (item ? (item + "%") : '-')
            }
            <div className={styles.countText}>{data_text}</div>
          </Col>
        )
      } else {
        res.push(
          renderOperateCardDiv(data_text, data_color, item, colSpan)
        )
      }
    });
    while (res.length < colCount) {
      res.push(
        <Col span={colSpan} className={styles.countNumber} key={res.length}>
          <div className={styles.countText}></div>
        </Col>
      )
    }
    return (
      res
    )
  }

  const renderTooltip = (
    <div className={styles.userCardTooltip}>
      <Tooltip title={"当前系统中可审计的用户数"}>
        <QuestionCircleOutlined />
      </Tooltip>
    </div>
  )


  // 活跃用户
  const OverviewCountRight = (props: {
    title: string,
  }) => {
    const {
      title,
    } = props;
    const CardContent = (
      <Card
        title={title}
        headStyle={{ border: 0, fontSize: 18, fontWeight: 'bolder' }}
        className={styles.statisticalCardWrapper}
        extra={renderTooltip}
      >
        <Row>
          <Col span={8}>
            <Row>
              <RowContent
                dataCount={[allUser]}
                dataText={["用户总量"]}
                color={[]}
                colCount={1}
                colSpan={24}
              />
            </Row>
            <Row>
              <RowContent
                dataCount={[onlineUser]}
                dataText={["当前用户数"]}
                color={[]}
                colCount={1}
                colSpan={24}
              />
            </Row>
          </Col>
          <Col span={16}>
            <Row>
              <Col span={24}>
                <ActiveUserChart
                  data={activeUserChartData}
                  unit={activeUserUnit}
                  setUnit={setActiveUserUnit}
                  loading={activeUserLoading}
                />
              </Col>
            </Row>
            <Row align='bottom'>
              <RowContent
                dataCount={[growth_activeUser]}
                dataText={["环比"]}
                color={[]}
                colCount={1}
                colSpan={4}
                countSize="small"
              />
            </Row>
          </Col>
        </Row>
      </Card>
    );
    return (
      <Col span={8}>
        {CardContent}
      </Col>
    )
  }

  return (
    <OverviewCountRight
      title={"活跃用户"}
    />
  );
}
