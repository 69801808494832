export function findTreeParentNode(root: any, target: any): any {
  if(!root || !root.children || !target) return null
  const key = target.key
  const rootKey = root.key
  if(!rootKey || !key) return null

  const keyArray = key.split('/').slice(0, -1)
  const { children } = root
  const parentNode = children.filter((node: any) => {
    if(node.key) {
      const nodeKeyArray = node.key.split('/')
      return nodeKeyArray.every((ele: any, index: number) => ele === keyArray[index])
    }
  })[0]

  if(parentNode?.key?.split('/')?.length < keyArray?.length) {
    return findTreeParentNode(parentNode, target)
  }
 
  return parentNode
}

export function findTreeRootNode(treeData: any, targetNode: any): any {
  if(!treeData || !targetNode) return null
  const root = treeData.find((t: any) => {
    const { nodePath } = t
    if(!nodePath) return null
    const targetArr = targetNode.nodePath.split('/')
    const sourceArr = nodePath.split('/')
    return sourceArr.every((s: any, index: number) => {
      return sourceArr[index] === targetArr[index]
    })
  })
  return root
}

//查询包含搜索条件的节点信息 tree结构保持不变
export function filterTreeNodesNotMatch(treeData: any,searchValue: string) {
      //@ts-ignore
      return treeData?.reduce((acc, node) => {
        const { children, ...rest } = node;
        if (children) {
          const filteredChildren = filterTreeNodesNotMatch(children, searchValue);
          if (filteredChildren.length > 0 || rest.title.toLowerCase().includes(searchValue.toLowerCase())) {
            acc.push({ ...rest, children: filteredChildren });
          }
        } else if (rest.title.toLowerCase().includes(searchValue.toLowerCase())) {
          acc.push({ ...rest });
        }
        return acc;
      }, []);

} 

//获取当前树结构的所有父节点信息
export function findParentKeysFormTreeStructure(treeNode: any) {
  const keys: string[] = []

  const getKeys = (node: any) => {
    if (node?.children?.length) keys.push(node.key)
    if (node?.children?.length) {
      node.children.forEach((child: any) => getKeys(child))
    }
  }
  getKeys(treeNode)
 
  return keys
}

export const matchKeyword = (target = '', substring = '') => {
  if (!target) return false
  return target.toLowerCase().indexOf(substring.toLowerCase()) > -1
}

//只获取包含搜索字段的父节点 keys
export const getParentKeysAboutContainSearchValue = (treeData: any, searValue: string) => {
  let expandKeys: string[] = [];
  const getParentNode = (data: any, name: any, parentKey?:any) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      if (node.title && node.title.toLowerCase().includes(name.toLowerCase())) {
        expandKeys.push(parentKey);
        if (node.children && node.children.length) {
          getParentNode(node.children, name, node.key);
        }
      } else if (node.children && node.children.length) {
        getParentNode(node.children, name, node.key);
      }
    }
  };

  getParentNode(treeData, searValue, null);
  return expandKeys;
};