import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { TreeNodeMenu } from './TreeNodeMenu'

export const DropdownMenu = ({ children }: any) => {
  const [visibleMenu, setVisibleMenu] = useState(false)
  return (
    <Dropdown
      trigger={['contextMenu']}
      getPopupContainer={() => document.getElementById('sdt')!}
      overlay={<TreeNodeMenu setVisibleMenu={setVisibleMenu} />}
      visible={visibleMenu}
      onVisibleChange={(visible) => setVisibleMenu(visible)}
    >
      {/* todo: Dropdown 包裹 Tree，需要给 Tree 包裹一层 div，否则无法右键打开菜单 */}
      <div id="sdt">{children}</div>
    </Dropdown>
  )
}
