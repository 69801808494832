import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, useRequest } from 'src/hook'
import {
  // Alert,
  Input,
  message,
  Button,
  Table,
  Popconfirm,
  Form,
  Tag,
  Modal,
  Typography,
  Descriptions,
  Switch,
  Tooltip,
  Dropdown,
  Space,
  Menu,
} from 'antd'
import {
  fetchDeptUserList,
  fetchOrgTreeData,
  fetchOrgTreeDataNoSelectNode,
  setDeptUsersManagerId,
  setOrganizationSearch
} from '../organizationSlice'
import { LinkButton, CreateButton } from 'src/components'
import {
  AddUserModal,
  UserManagementModal,
  UserPermDetaiModal,
} from '../orgModals'
import { showModal } from 'src/store/extraSlice/modalVisibleSlice'
import { ColumnsType } from 'antd/lib/table'
import {
  deleteUser,
  editGroupUsers,
  UserEntity,
  resetUserPassword,
  changeUserStatus,
  resetOtpKey,
  getTwoFactor,
  getDeptUsersManagerId,
  updateUsersStatus,
  resetPwd,
  deleteUsers,
} from 'src/api'
import { getRandomUserPassword } from 'src/util'
import { renderItemField } from 'src/constants'
import { PlusSquareOutlined, SearchOutlined, TeamOutlined, UpOutlined } from '@ant-design/icons'
import Service from 'src/service'
import styles from './index.module.scss'
import { toInteger } from 'lodash'
import PermissionTooltip from 'src/components/PermissionTooltip/PermissionTooltip';

const { useForm } = Form
const { Text } = Typography

interface IProps {
  [p: string]: any
}

interface RemoveButtonProps {
  operation: () => Promise<any>
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ operation }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  return (
    <LinkButton
      onClick={() => {
        setLoading(true)
        operation()
          .then(() => {
            dispatch(fetchOrgTreeDataNoSelectNode())
          })
          .finally(() => setLoading(false))
      }}
      loading={loading}
    >
      移出
    </LinkButton>
  )
}

export const UserList = (props: IProps) => {
  const { permissionlist } = props
  const { isOnlyRead, roleNameList } = permissionlist || {}
  const history = useHistory();
  const dispatch = useDispatch()
  const [mode, setMode] = useState<'add' | 'edit' | 'editAndAdd'>('add') // add（新增） edit（编辑） editAndAdd （先编辑当前数据后新增）
  const {
    userList,
    userListLoading,
    organizationPageNum,
    organizationPageSize,
    organizationTotalNum,
    organizationSearch,
  } = useSelector((state) => state.organization)
  const { userId: currentUserId } = useSelector((state) => state.login.userInfo)
  const { companyId, companyName } = useSelector((state) => state.companyInfo)

  const dept = useSelector((state) => state.organization.selectedNode)
  const isGroup = dept?.orgType === 'GROUP'

  const [selectRecord, setSelectRecord] = useState({}) as any

  const [search, setSearch] = useState<string>('')
  const [searchOutput, setSearchOutput] = useState<string>('')
  const [createFrom, setCreateFrom] = useState<string>('')

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [disableds, setDisableds] = useState<boolean>(true);
  const [requestUserList, setRequestUserList] = useState<boolean>(false);

  // ! HACK
  // 重构时要按标准实现
  useEffect(() => {
    dispatch(setOrganizationSearch(''))
    setSearchOutput('')
  }, [dept])

  const [form] = useForm()
  useEffect(() => {
    if (!dept) return
    if (dept?.id) {
      getUsersManagerId(dept?.id)
    }
    const promise = dispatch(
      fetchDeptUserList({
        dept,
        search: searchOutput,
        pageNum: 0,
        pageSize: organizationPageSize,
      }),
    )
    return () => promise.abort()
  }, [dispatch, dept, searchOutput, requestUserList])

  const { run: resetPassword } = useRequest(resetUserPassword, {
    manual: true,
  })
  const { fetches, run: toggleStatus } = useRequest(changeUserStatus, {
    manual: true,
    fetchKey: ({ userId }) => userId?.toString() || '',
    onSuccess: () => {
      dept &&
        dispatch(
          fetchDeptUserList({
            dept,
            search: searchOutput,
            pageNum: 0,
            pageSize: organizationPageSize,
          }),
        )
    },
  })

  /* 是否开启 otp */
  // const { data: isOtpOpen } = useRequest(getTwoFactor, {
  //   formatResult: (data) => data === 'OTP',
  // })

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      setDisableds(false)
    } else {
      setDisableds(true)
    }
  }, [selectedRowKeys])

  // 批量启用/禁用
  function bulkChangeStatus(status: boolean) {
    const userIds = selectedRowKeys;
    updateUsersStatus({ userIds, status }).then(res => {
      if (res) {
        setRequestUserList(!requestUserList);
        message.success('设置成功')
      } else {
        message.success('设置失败')
      }
    });
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => bulkChangeStatus(false)}>批量启用</Menu.Item>
      <Menu.Item onClick={() => bulkChangeStatus(true)}>批量禁用</Menu.Item>
      <Menu.Item>
        <Popconfirm
          title="确定重置密码?"
          onConfirm={() => {
            const randomPassword = getRandomUserPassword()
            resetPwd({
              userIds: selectedRowKeys,
              newP: randomPassword,
            }).then(({ code, message }) => {
              Modal.info({
                title: '密码重置成功',
                content: (
                  <>
                    <Descriptions
                      className={styles.descriptions}
                      column={1}
                    >
                      <Descriptions.Item label="用户">
                        <div className={styles.selectRowDiv}>
                          <Text strong style={{ whiteSpace: 'nowrap' }}>
                            {`${selectedRowKeys}`}
                          </Text>
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item label="密码">
                        {
                          <Text strong copyable>
                            {randomPassword}
                          </Text>
                        }
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                ),
                centered: true,
              })
            })
          }}
          okButtonProps={{ danger: true }}
        >
          <span>批量重置密码</span>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item disabled={disableds}>
        <Popconfirm
          title="确定删除用户?"
          onConfirm={() => {
            const userIds: any = selectedRowKeys;
            deleteUsers({ userIds })
              .then(res => {
                message.success('删除成功')
                setSelectedRowKeys([])
                if (dept) {
                  dispatch(
                    fetchDeptUserList({
                      dept,
                      search: searchOutput,
                      pageNum: 0,
                      pageSize: organizationPageSize,
                    }),
                  )
                }
                dispatch(fetchOrgTreeData())
              })
              .catch(() => {
                message.success('删除失败')
              })
          }}
          okButtonProps={{ danger: true }}
        >
          <span>批量删除用户</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const { run: getUsersManagerId } = useRequest(getDeptUsersManagerId, {
    manual: true,
    onSuccess(data) {
      !!data && dispatch(setDeptUsersManagerId(data?.userId))
    }
  })

  const renderAuditRange = (_: any, record: UserEntity) => {
    // 角色里有审计员，展示审计范围，审计员id为2
    const { auditUser, rolesId } = record || {}
    let auditInfo = '';
    if (auditUser) {
      auditInfo = Object.keys(auditUser).map(item => {
        return `${auditUser[item]}(${item})`
      }).join('、')
    }
    if (rolesId?.includes(2)) {
      if (auditInfo?.length > 30) {
        return <>
          <Tooltip title={auditInfo}>
            <div style={{ wordWrap: 'break-word' }}>
              {auditInfo.slice(0, 30)}...
            </div>
          </Tooltip>
        </>
      } else if (auditInfo?.length > 0) {
        return auditInfo
      } else {
        return '全部用户'
      }
    } else {
      return '-'
    }
  }

  const columns: ColumnsType<UserEntity> = [
    {
      title: '',
      render: (onlineStatus, record) => {
        return (
          <span>
            {record?.onlineStatus ?
              <span style={{ width: '8px', height: '8px', borderRadius: '50%', background: '#1AD42D', display: "inline-block" }}></span>
              : <span style={{ width: '8px', height: '8px', borderRadius: '50%', background: 'gray', display: "inline-block" }}></span>
            }
          </span>
        )
      },
      width: 50
    },
    {
      title: '用户名',
      dataIndex: 'userName',
      render: (_text, record) => {
        const { systemAuthCode, userName } = record
        const isAdmin = Service.permService.checkAuthWithCode(
          'SystemSettings',
          systemAuthCode,
        )

        if (!isAdmin) return userName
        return (
          <span>
            <span>{userName}</span>
            <Tag className={styles.tag} color="geekblue">
              Admin
            </Tag>
          </span>
        )
      },
      ellipsis: true,
    },
    {
      title: '登录账号',
      dataIndex: 'userId',
      ellipsis: true,
      render: (_text, record) => {
        const { userId } = record
        return <span>{userId}</span>
      },
    },
    {
      title: '所属部门',
      dataIndex: 'departmentName',
      ellipsis: true,
      render: (val, record) => {
        return <span>{record?.principalFlag ? <TeamOutlined style={{ color: '#2B58BA', fontSize: '20px' }} /> : null}{record?.departmentName}</span>
      }
    },
    // {
    //   title: '性别',
    //   dataIndex: 'userGender',
    //   render: renderItemField('userGender'),
    //   width: '48px',
    // },
    {
      title: '用户角色',
      dataIndex: 'systemRoles',
      width: '140px',
      // ellipsis: true,
      render: (_, record) => {
        const { systemRoles } = record
        const roleString = systemRoles?.join(', ')
        return (
          <Tooltip title={roleString}>
            <span>{roleString}</span>
          </Tooltip>
        )
      },
    },
    {
      title: '手机',
      dataIndex: 'telephone',
      width: '128px',
      ellipsis: true,
      render: (_, record) => {
        const { telephone } = record
        return <span>{telephone}</span>
      },
    },
    {
      title: 'CQ/域',
      dataIndex: 'createFrom',
      width: '100px',
      ellipsis: true,
      render: (_text) => {
        let text
        switch (_text) {
          case null:
            text = 'CQ用户'
            break
          case 'adLogin':
            text = 'AD 域'
            break
          case 'casLogin':
            text = 'CAS'
            break
          case 'openLdapLogin':
            text = 'OpenLdap'
            break
          case 'OauthLogin':
            text = 'Oauth2.0'
            break
        }
        return text
      },
    },
    {
      title: '审计范围',
      dataIndex: 'auditRange',
      width: '200px',
      render: renderAuditRange,
    },
    {
      title: '状态',
      dataIndex: 'userStatus',
      render: (_text, record) => (
        <PermissionTooltip
          permissionlist={permissionlist}
          title='用户管理'
        >
          <Switch
            checked={record.userStatus === 'NORMAL'}
            checkedChildren="正常"
            unCheckedChildren="锁定"
            loading={fetches[record.userId]?.loading}
            onChange={(checked) => {
              const flag = !checked
              toggleStatus({ userId: record.userId, flag })
            }}
            disabled={isOnlyRead}
          />
        </PermissionTooltip>
      ),
      width: '90px',
    },
  ]

  const deptColumnsWithAction: ColumnsType<UserEntity> = [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_text, record) => {
        const { systemAuthCode, permitIps, createFrom = null } = record
        const isAdmin = Service.permService.checkAuthWithCode(
          'SystemSettings',
          systemAuthCode,
        )
        return (
          <>
            {/*<LinkButton*/}
            {/*  onClick={() => {*/}
            {/*    setSelectRecord(record)*/}
            {/*    dispatch(showModal('UserPermDetaiModal'))*/}
            {/*  }}*/}
            {/*>*/}
            {/*  数据源连接权限*/}
            {/*</LinkButton>*/}
            <PermissionTooltip
              permissionlist={permissionlist}
              title='用户管理'
            >
            <Button
              type='link'
              onClick={() => {
                setMode('edit')
                const { createFrom, auditRange } = record || {}
                setCreateFrom(createFrom)
                // 修改允许登录ip的显示格式
                if (Array.isArray(record.permitIps)) {
                  form.setFieldsValue({ permitIps: permitIps.join(';') })
                }
                form.setFieldsValue({
                  ...record,
                  rangeType: auditRange?.length ? 'custom' : 'all',
                  auditRange: auditRange === null ? undefined : auditRange
                })
                form.setFieldsValue({ departmentId: record.departmentId + '' })
                // form.setFieldsValue({ rolesId: record.systemRoles })
                dispatch(showModal('AddUser'))
              }}
              disabled={isOnlyRead}
            >
              编辑
            </Button>
            <Button
              type='link'
              onClick={() => {
                setMode('editAndAdd')
                // 修改允许登录ip的显示格式
                if (Array.isArray(record.permitIps)) {
                  form.setFieldsValue({ permitIps: permitIps.join(';') })
                }
                setCreateFrom(record.createFrom)
                form.setFieldsValue(record)
                form.setFieldsValue({ departmentId: record.departmentId + '' })
                // form.setFieldsValue({ rolesId: record.systemRoles })
                dispatch(showModal('AddUser'))
              }}
              disabled={isOnlyRead}
            >
              克隆
            </Button>
            
            {record?.twoFactorSetting === 'OTP' && (
              <Popconfirm
                title="确定重置 OTP 秘钥?"
                onConfirm={() => {
                  resetOtpKey({ userId: record.userId })
                    .then(() => {
                      message.success('重置成功')
                    })
                    .catch(() => {
                      message.success('重置失败')
                    })
                }}
                okButtonProps={{ danger: true }}
              >
                <Button type='link' danger disabled={isOnlyRead}>重置OTP</Button>
              </Popconfirm>
            )}

            {record.userId !== currentUserId && (
              <Popconfirm
                title="确定重置该用户密码?"
                onConfirm={() => {
                  const randomPassword = getRandomUserPassword()
                  resetPassword({
                    userId: record.userId,
                    newP: randomPassword,
                  }).then(({ code, message }) => {
                    Modal.info({
                      title: '密码重置成功',
                      content: (
                        <>
                          <Descriptions
                            className={styles.descriptions}
                            column={1}
                          >
                            <Descriptions.Item label="用户">
                              <Text
                                strong
                              >{`${record.userName}（${record.userId}）`}</Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="密码">
                              {
                                <Text strong copyable>
                                  {randomPassword}
                                </Text>
                              }
                            </Descriptions.Item>
                          </Descriptions>
                          {/* <Alert
                              message={message}
                              type={code === 'SUCCESS' ? 'success' : 'error'}
                            /> */}
                        </>
                      ),
                      centered: true,
                    })
                  })
                }}
                okButtonProps={{ danger: true }}
                disabled={!!createFrom ||  isOnlyRead}
              >
                <Button type='link' danger disabled={!!createFrom ||  isOnlyRead}>
                  重置密码
                </Button>
              </Popconfirm>
            )}

            {!isAdmin && (
              <Popconfirm
                title="确定删除该用户?"
                onConfirm={() => {
                  deleteUser(record.userId)
                    .then(() => {
                      message.success('删除成功')
                      if (dept) {
                        dispatch(
                          fetchDeptUserList({
                            dept,
                            search: searchOutput,
                            pageNum: 0,
                            pageSize: organizationPageSize,
                          }),
                        )
                      }
                      dispatch(fetchOrgTreeData())
                    })
                    .catch(() => { })
                }}
                okButtonProps={{ danger: true }}
              >
                <Button type='link' danger disabled={isOnlyRead}>删除</Button>
              </Popconfirm>
            )}
            </PermissionTooltip>
          </>
        )
      },
      width: '254px',
    },
  ]

  const groupColumnsWithAction: ColumnsType<UserEntity> = [
    ...columns,
    {
      title: '',
      key: 'action',
      render: (_text, record) => {
        const { userId } = record
        return (
          <RemoveButton
            operation={() => {
              const { id: groupId, orgType } = dept!
              return editGroupUsers({
                groupId: toInteger(groupId),
                orgType,
                removeUsers: [userId],
                addUsers: [],
              })
                .then(() => {
                  message.success('移出成功')
                  if (dept) {
                    dispatch(
                      fetchDeptUserList({
                        dept,
                        search: searchOutput,
                        pageNum: 0,
                        pageSize: organizationPageSize,
                      }),
                    )
                  }
                })
                .catch(() => { })
            }}
          />
        )
      },
    },
  ]
  return (
    <div className={styles.userList}>
      <div className={styles.actions}>
        <span>
          <Input
            className={styles.searchInput}
            prefix={<SearchOutlined />}
            placeholder="请输入用户名/账号"
            value={organizationSearch}
            onChange={(e) => {
              setSearch(e.target.value)
              setSearchOutput(e.target.value);
              dispatch(setOrganizationSearch(e.target.value));
            }}
            allowClear
          />
          {isGroup ? (
            <Button
              onClick={() => {
                // 这里再请求一下,主要是解决,TransferTable里面右table数据无法分页问题,暂时这样解决
                //这里要改成直接根据公司id查
                dispatch(
                  fetchDeptUserList({
                    dept,
                    pageNum: 0,
                    pageSize: 999,
                  })
                );
                dispatch(showModal("UserManagement"));
              }}
            >
              成员管理
            </Button>
          ) : (
            <>
              <PermissionTooltip
                permissionlist={permissionlist}
                title='用户管理'
              >
                <Dropdown
                  overlay={ disableds || isOnlyRead ? <></> : menu}
                  className={ disableds || isOnlyRead ? styles.dropDownDisabled : styles.dropDown}
                >
                <Button>
                  <Space>
                    批量操作
                    <UpOutlined />
                  </Space>
                </Button>
                </Dropdown>
              </PermissionTooltip>
              {
                isOnlyRead?
                <Tooltip title={`您当前的角色是[${roleNameList.join(", ")}], 对[用户管理]没有操作权限`} 
                  overlayStyle={{width: 180}}
                >
                  <Button
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    disabled={isOnlyRead}
                    className={styles.ml10}
                  >
                    新增用户
                  </Button>
                </Tooltip>
                : <CreateButton
                    onClick={() => {
                      setMode("add");
                      const params = {
                        userId: null,
                        name: null,
                        userGender: "MALE",
                        phone: null,
                        email: null,
                        departmentName:
                          dept?.name && "COMPANY" !== dept.orgType ? dept?.name : null,
                        postId: null,
                        companyId: companyId,
                        departmentId:
                          dept?.id && "COMPANY" !== dept.orgType ? dept?.id : null,
                        userName: null,
                        jobNumber: null,
                        rolesId: [],
                        permitIps: null,
                      };
                      form.setFieldsValue(params);
                      dispatch(showModal("AddUser"));
                    }}
                    className={styles.ml10}
                  >
                    新增用户
                  </CreateButton>
              }
            </>
          )}
        </span>
      </div>
      <Table
        className={styles.tableTop}
        loading={userListLoading}
        dataSource={userList}
        rowKey={(record) => record.userId}
        columns={isGroup ? groupColumnsWithAction : deptColumnsWithAction}
        rowSelection={isGroup ? undefined : rowSelection}
        pagination={{
          current: organizationPageNum + 1,
          total: organizationTotalNum,
          showSizeChanger: true,
        }}
        onChange={(val) => {
          dept &&
            dispatch(
              fetchDeptUserList({
                dept,
                search,
                pageNum: Number(val.current) - 1,
                pageSize: Number(val.pageSize),
              })
            );
        }}
        scroll={{ x: true, y: `calc(100vh - 370px)` }}
      ></Table>
      {/* Instance created by `useForm` is not connected to any Form element. Forget to pass `form` prop display类型组件,本不应该用form传递的 */}
      <AddUserModal mode={mode} form={form} createFrom={createFrom} />
      <UserManagementModal />
      <UserPermDetaiModal record={selectRecord} />
    </div>
  );
}
