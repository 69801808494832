import React from 'react'
import { useContextMenu } from './useContextMenu'
import type { ICellRendererParams } from '@ag-grid-community/core'

export const RowIndexRenderer = (params: ICellRendererParams) => {
  const {
    node: { rowIndex },
  } = params

  if (rowIndex === null || rowIndex === undefined) return null;

  return <div>{rowIndex + 1}</div>
}

export const RowIndexRendererWithContextMenu = (
  params: ICellRendererParams,
) => {
  const [wrapper] = useContextMenu(params)

  return wrapper(RowIndexRenderer(params))
}
