
import moment from 'moment';
import type { EffectStatusType } from './constants';

export const getEffectiveStatus = (s:string,e: string, a?: string ): EffectStatusType => {

   const sTime: number = moment(s).valueOf();
   const eTime: number = moment(e).valueOf();
   const curTime: number = moment().valueOf();
   if (a === 'exportTask') {  // 导出申请 没有时间，一直处于生效状态
    return '生效中'
   }
   if (curTime < sTime) {
    return '待生效';
   }else if (sTime <= curTime && curTime <= eTime) {
    return '生效中'
   }else {
    return '已失效'
   }
}