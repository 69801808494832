import { RULES_MAP, DESENS_BATCH_ACTIONS, DESENS_STATUS } from '../constant';
import {
  getInternalDatasourceFind,
  getInternalGroupFind,
  getInternalDenseRules,
  updateDatasourceInternal,
  updateGroupInternalStatus,
  updateSchemaInternal
} from 'src/api';

export function generateObjectName(path: string): string {
  try {
    const targetType: string[] = ['DATABASE', 'SCHEMA', 'TABLE']

    const path2Arr = path.split('/').filter((i) => i)
    const result: string[] = []

    path2Arr.forEach((item) => {
      const splitArr = item.split(':')
      if (targetType.includes(splitArr[0])) {
        result.push(splitArr[1])
      }
    })

    return result.length ? result.join('.') : ''
  } catch (error) {
    return ''
  }
}

export function formatRuleName(key: string): string {
  return RULES_MAP[key] ? RULES_MAP[key] : ''
}

export const getDesensInternalDataAction = (type: any) => {
  switch (type) {
    case "datasource":
      return getInternalDatasourceFind;
    case "group":
      return getInternalGroupFind;
    default:
      return getInternalDenseRules;
  }
};
export const getDesensInternalStatusAction = (type: any) => {
  switch (type) {
    case "datasource":
      return updateDatasourceInternal;
    case "group":
      return updateGroupInternalStatus;
    default:
      return updateSchemaInternal;
  }
};

export const formatSelectOptions = Object.keys(DESENS_BATCH_ACTIONS).map((key: string) => ({label: DESENS_BATCH_ACTIONS[key], value: key}))

export const formatStatusSelectOptions =  Object.keys(DESENS_STATUS).map((key: string) => ({label: DESENS_STATUS[key], value: key}))