import React from 'react'
import styles from './index.module.scss'

export const Status = ({ status }: { status: string }) => {
  const style = status === '未读' ? styles.toView : styles.viewed
  return (
    <div className={styles.circle}>
      <div className={style}></div>
      <div>{status}</div>
    </div>
  )
}
