import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as _ from 'lodash';
import { AppThunk, RootState } from 'src/store'
import { getConnectionNodeWithGroup, NodeEntity } from 'src/api'
import { setTreeNodeKey } from 'src/pageTabs/queryPage/queryTabs/monacoPane/common';

interface InitialState {
  loadingConnections: boolean
  /** 查询面板 toolbar 下拉选择框待选 connection 列表 */
  toolbarConnections?: NodeEntity[]
  // 存储开发者模式connectionIds
  devModelConnectionIds?: any[]
  //带分组的连接
  toolbarConnectionsWithGroup?: any[]
}

const initialState: InitialState = {
  loadingConnections: false,
}

export const getToolbarConnections = createAsyncThunk(
  'editor/getToolbarConnections',
  async (__, {getState}) => {
    //@ts-ignore
    const status = getState()?.sdtPermission?.status
  
    const toolbarConnectionsWithGroup  = await getConnectionNodeWithGroup({
      nodeType: 'root', nodePath: '/root', globalHavePermissionFlag: status
    })
      // 过滤只需要connectio信息
   let toolbarConnections: any = [];
   let formatToolbarConnections = _.cloneDeep(toolbarConnectionsWithGroup?.data || [])

   function findConnections(node: any) {
     if (node.nodeType === "connection") {
      toolbarConnections.push(node);
     }
   
     if (node.children && node.children.length > 0) {
       node.children.forEach((child: any) => findConnections(child));
     }
   }
   
   toolbarConnectionsWithGroup?.data?.forEach((node: any) => findConnections(node));
   formatToolbarConnections.forEach((item: any) => setTreeNodeKey(item))
  
    return {
      toolbarConnections,
      toolbarConnectionsWithGroup: formatToolbarConnections
    }

  },
)

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {},
  extraReducers: ({ addCase }) => {
    addCase(getToolbarConnections.fulfilled, (state, action) => {
      const {toolbarConnections = [],toolbarConnectionsWithGroup } = action.payload
      state.toolbarConnections = toolbarConnections
      state.toolbarConnectionsWithGroup = toolbarConnectionsWithGroup
      state.loadingConnections = false
      let devModelIds  = [] as any
      if (toolbarConnections && toolbarConnections.length > 0) {
        toolbarConnections.map((item:NodeEntity) => {
         item?.devModel &&  devModelIds.push(item.connectionId)
        }) 
      }
      state.devModelConnectionIds =  devModelIds;
    })
    addCase(getToolbarConnections.pending, (state) => {
      state.loadingConnections = true
    })
    addCase(getToolbarConnections.rejected, (state) => {
      state.toolbarConnections = undefined
      state.toolbarConnectionsWithGroup = undefined
      state.loadingConnections = false
    })
  },
})

export const editorReducer = editorSlice.reducer
