import React, { Fragment } from 'react'
import { Breadcrumb } from 'antd'
import { useHistory } from 'react-router-dom';
import type { BreadcrumbItemProps } from 'antd/lib/breadcrumb'
import styles from './index.module.scss';

interface IItem extends BreadcrumbItemProps {
  title: any,
  hidden?: boolean
  onclick?: () => void
}

export const SimpleBreadcrumbs = ({
  items = [],
  defaultSeparator = '|',
}: {
  items: IItem[]
  defaultSeparator?: React.ReactNode
}) => {

    const history = useHistory()
  return (
    <Breadcrumb  separator = '' className={styles['simple-breadcrumb']}>
      {items.filter(({hidden=false}) => !hidden).map((item, index) => (
        <Fragment key={index}>
            {index > 0 &&(
            <Breadcrumb.Separator><span style={{color:'#D8D8D8'}}>{item?.separator || defaultSeparator}</span></Breadcrumb.Separator>
          )}
          <Breadcrumb.Item 
          key={index}
           onClick={() => item?.onclick ? item.onclick() : item?.href && history.push(item.href)}
          >
            <span className={item?.href && styles['link-item']}>{item?.title}</span>
          </Breadcrumb.Item>
        
        </Fragment>
      ))}
    </Breadcrumb>
  )
}
