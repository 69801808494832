import React, { PureComponent } from 'react'
import { Button, message, Modal, notification, Space } from 'antd'
import propertiesPanelModule from './bpmn-js-properties-panel/lib' //Bpmn-右侧属性栏的框
import flowableModdle from '../static/flowModel/flowable.json' //Bpmn-引入flowable的节点文件
import propertiesProviderModule from './bpmn-js-properties-panel/lib/provider/flowable' //bpmn-右侧属性栏里的内容
import EditingTools from './BpmnEditor/EditingTools' //bpmn-工具栏
import BpmnModeler from './BpmnEditor/Modeler' //bpmn-modeler定义
import 'bpmn-js/dist/assets/diagram-js.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import getDefaultXml from './BpmnEditor/sources/xml'
import { validateRequired } from './validateProcess'
import * as bpmnlintConfig from './packed-config' //bpmn-lint校验配置
import lintModule from './bpmn-js-bpmnlint/assets/index'
import { RcSegmented } from '../../../../src/components'
import './bpmn-js-bpmnlint/assets/bpmn-js-bpmnlint.css' //bpmn-lint检验样式
import './BpmnEditor/sources/Bpmn.less' //全局样式文件
// import lintModule from '../ProcessManage/bpmn-js-properties-panel/lib' //bpmn-lint校验
import $ from 'jquery';

/**
 * @description: bpmn规则校验
 * @return {*}
 */
const rulesCheckout = (modeler) => {
  validateRequired()
  return new Promise((resolve, rejected) => {
    let lintingState = modeler.get('linting').isActive()
    let lintingIssues = modeler.get('linting')._issues
    console.log(lintingIssues, 'lintingIssues')

    if (!lintingState) {
      modeler.get('linting').toggle()
    }
    console.log(lintingIssues, 'lintingIssues')
    if (Object.keys(lintingIssues).length > 0) {
      message.error('流程结构有错误,请检查流程节点')
      rejected()
    } else {
      resolve()
    }
  })
}

/**
 * @description: 通过修改url, 添加url尾部name, 调用getUrlParam,启用流程校验
 * @param {*} name url尾部添加值
 * @param {*} value 值
 * @return {void}
 */
const setUrlParam = (name, value) => {
  var url = new URL(window.location.href)
  if (value) {
    url.searchParams.set(name, 1)
  } else {
    url.searchParams.delete(name)
  }
  window.history.replaceState({}, null, url.href)
}

/**
 * 下载xml/svg
 *  @param  type  类型  svg / xml
 *  @param  data  数据
 *  @param  name  文件名称
 */
const download = (type, data, name) => {
  let dataTrack = ''
  const a = document.createElement('a')

  switch (type) {
    case 'xml':
      dataTrack = 'bpmn'
      break
    case 'svg':
      dataTrack = 'svg'
      break
    default:
      break
  }

  name = name || `diagram.${dataTrack}`

  a.setAttribute(
    'href',
    `data:application/bpmn20-xml;charset=UTF-8,${encodeURIComponent(data)}`,
  )
  a.setAttribute('target', '_blank')
  a.setAttribute('dataTrack', `diagram:download-${dataTrack}`)
  a.setAttribute('download', name)

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * @description:流程校验调用判断
 * @param {*} name 值
 * @return {Boolean}
 */
const getUrlParam = (name) => {
  var url = new URL(window.location.href)
  return url.searchParams.has(name)
}

class ProcessDesign extends PureComponent {
  state = {
    scale: 1, // 流程图比例
    svgVisible: false, // 预览图片
    svgSrc: '', // 图片地址
    bpmnModeler: null,
    selectedProcess: null,
    defaultModelInfo: null,
    buttonText: null,
    newArr: [],
    checkValue: ''
  }

  componentDidMount() {
    const that = this
    this.bpmnModeler = new BpmnModeler({
      container: '#canvas',
      propertiesPanel: {
        parent: '#properties-panel',
      },
      linting: {
        bpmnlint: bpmnlintConfig,
        active: getUrlParam('linting'),
      },
      additionalModules: [
        propertiesPanelModule,
        propertiesProviderModule,
        lintModule,
      ],
      moddleExtensions: {
        flowable: flowableModdle,
        // activiti: activitiModdele,
      },
      height: '100%',
      width: '100%',
    })
    const diagramXML = getDefaultXml()
    this.renderDiagram(diagramXML)

    this.bpmnModeler.on('linting.toggle', function (event) {
      const active = event.active
      setUrlParam('linting', active)
    })

    // 启用事件监听，属性面板改变的时候实时更改属性的值
    this.bpmnModeler.on('propertiesPanel.changed', (event) => {
      $('#flowable-assignee-select').on('change', (e) => {
        try {
          this.bpmnModeler.get("modeling").updateProperties(event?.current?.element            , {
            assignee: $('#flowable-assignee-select').val()
          })
        } catch (err) {
          console.error(err)
        }
      });
    })

    //this.bpmnModeler.on selection.changed
    // const dndHandler = fileDrop('Drop BPMN Diagram here.', function(files) {
    //   this.bpmnModeler.importXML(files[0].contents);
    // });
    // document.querySelector('body').addEventListener('dragover', dndHandler);

    window.bpmnModeler = this.bpmnModeler
    // 删除 bpmn logo  bpmn.io官方要求不给删或者隐藏，否则侵权
    // const bjsIoLogo = document.querySelector('.bjs-powered-by');
    // while (bjsIoLogo.firstChild) {
    //     bjsIoLogo.removeChild(bjsIoLogo.firstChild);
    // }
  }

  // 导入 xml 文件
  handleOpenFile = (e) => {
    const that = this
    if (e.target.files.length > 0) {
      const file = e.target.files[0]
      const reader = new FileReader()
      let data = ''
      reader.readAsText(file)
      reader.onload = function (event) {
        data = event.target.result
        that.renderDiagram(data, 'open')
      }
    }
  }

  //load XML
  handleLoadXML = (e) => {
    this.bpmnModeler.importXML(e)
  }

  // 保存
  handleSave = () => {
    let bpmnXml = ''
    let svgXml = ''
    rulesCheckout(this.bpmnModeler).then(() => {
      //缺少审批人信息检查
      const  elementRegistry= this.bpmnModeler.get('elementRegistry');
      const userTaskList = elementRegistry.filter(
        (item) => item.type === 'bpmn:UserTask'
      );
     if (userTaskList.length === 0) {
       return message.error('缺少用户任务节点')
     }
      userTaskList.forEach(item => {
        if (!item?.businessObject.get('activiti:formKey')) {
          try {
            this.bpmnModeler.get("modeling").updateProperties(item, {
              formKey: 'org',
            })
          } catch (err) {
            console.error('给formKey赋值失败了', err)
          }
        }
        if (item?.businessObject.get('activiti:assignee') === '${assignee}') {
          try {
            this.bpmnModeler.get("modeling").updateProperties(item, {
              assignee: $('#flowable-assignee-select').val() || '${assignee}'
            })
          } catch (err) {
            console.error("$('#flowable-assignee-select').val()", $('#flowable-assignee-select'))
            console.error('给assignee赋值失败了', err)
          }
        }
      })

      this.bpmnModeler.saveXML({ format: true }, (err, xml) => {
        console.log('xml :>> ', xml);
        bpmnXml = xml
        let flowType = this.bpmnModeler.getDefinitions().rootElements[0].id
        let { requestModelListReset, requestModelSaving } = this.props
        // 将bpmnXml和svgXml传给后台
        requestModelSaving({
          bpmnBase64Code: btoa(unescape(encodeURIComponent(xml))),
          flowType,
        }).then(() => {
          requestModelListReset()
          message.success('保存成功')
        })
      })
    })

    // this.bpmnModeler.saveSVG({ format: true }, (err, data) => {
    //   console.log(data);
    //   svgXml = data;
    // });

    // console.log(this.bpmnModeler, 'bpmnModeler')
  }

  // 前进
  handleRedo = () => {
    this.bpmnModeler.get('commandStack').redo()
  }

  // 后退
  handleUndo = () => {
    this.bpmnModeler.get('commandStack').undo()
  }

  // 下载 SVG 格式
  handleDownloadSvg = () => {
    this.bpmnModeler.saveSVG({ format: true }, (err, data) => {
      download('svg', data)
    })
  }

  // 下载 XML 格式
  handleDownloadXml = () => {
    this.bpmnModeler.saveXML({ format: true }, (err, data) => {
      console.log('xml', data)
      download('xml', data)
    })
  }

  // 流程图放大缩小
  handleZoom = (radio) => {
    const newScale = !radio
      ? 1.0 // 不输入radio则还原
      : this.state.scale + radio <= 0.2 // 最小缩小倍数
        ? 0.2
        : this.state.scale + radio

    this.bpmnModeler.get('canvas').zoom(newScale)
    this.setState({
      scale: newScale,
    })
  }

  /**
   * @description: 渲染 xml 格式
   * @param {String} xml
   * @return {void}
   */
  renderDiagram = (xml) => {
    this.bpmnModeler.importXML(xml, (err) => {
      if (err) {
        console.log(err)
        console.log(xml)
        notification.error({
          message: '提示',
          description: '导入失败',
        })
      }
    })
  }

  /**
   * @description: 预览图片
   * @return {void}
   */
  handlePreview = () => {
    this.bpmnModeler.saveSVG({ format: true }, (err, data) => {
      this.setState({
        svgSrc: data,
        svgVisible: true,
      })
    })
  }

  /**
   * @description: 预览XML
   * @return {void}
   */
  handlePreviewXml = () => {
    this.bpmnModeler.saveXML({ format: true }, (err, data) => {
      console.log(data)
    })
  }

  /**
   * @description: 折叠
   * @return {void}
   */
  handlePanelFold = () => {
    const { hidePanel } = this.state
    this.setState({
      hidePanel: !hidePanel,
      hideCount: 1,
    })
  }

  /**
   * @description: 关闭流程图弹窗
   * @return {void}
   */
  handleCancel = () => {
    this.setState({
      svgSrc: '',
      svgVisible: false,
    })
  }

  modelSelect = (key, buttonText) => {
    this.props.requestModelSelect(key)?.then((value) => {
      if (value) {
        this.handleLoadXML(value)
        this.setState({
          selectedProcess: key,
          defaultModelInfo: value,
          buttonText,
        })

        this.setState({
          checkValue: key
        })
        message.success(`切换至\n${buttonText}\n流程模型成功`)
      } else {
        message.warning('当前流程模型无数据')
      }
    })
  }

  /**
   * @description: header流程类型切换
   * @param {string} key model类型key
   * @param {string} buttonText button名称
   * @return {}
   */
  handleModelSelect = (key, buttonText) => {
    let { defaultModelInfo } = this.state
    if (defaultModelInfo !== null) {
      this.bpmnModeler.saveXML({ format: true }, (err, xml) => {
        if (xml !== defaultModelInfo) {
          Modal.confirm({
            title: '提示',
            content: <p>当前流程节点发生变化未保存,确定要切换吗?</p>,
            onOk: () => {
              this.modelSelect(key, buttonText)
            },
          })
        }
        return null
      })
    } else {
      this.modelSelect(key, buttonText)
    }
  }
  componentWillReceiveProps(nextProps) {
    const { newArr } = this.state
    const { processList } = nextProps
    //应该只是重新设置newArr processList长度是固定的
    if (newArr?.length !== processList?.length) {
      processList && processList?.length && processList?.forEach(item => {
        newArr.push({
          label: item?.value,
          value: item?.key
        })
      })
      this.setState(newArr)
      newArr?.length > 1 && this.modelSelect(newArr?.[0]?.value, newArr?.[0]?.label)
    }
  }

  render() {
    const {
      hidePanel,
      hideFold,
      hideCount,
      selectedProcess,
      buttonText,
      svgVisible,
      svgSrc,
      newArr,
      checkValue
    } = this.state
    // const { processList, onProcessModelSelect } = this.props

    return (
      <>
        <div className="processList">
          <div>
            {
              newArr?.length ? <RcSegmented
                options={newArr}
                // defaultValue={newArr?.[0]?.value}
                value={checkValue}
                onChange={(val) => {
                  let label = ''
                  newArr.forEach(item => {
                    if (item?.value === val) {
                      label = item?.label
                    }
                  })
                  this.handleModelSelect(val, label)
                }}
              /> : null
            }
          </div>
          {/* <Space>
            {processList?.map(({ key, value }) => {
              console.log(key, 'key')
              return (
                <Button
                  key={key}
                  type={selectedProcess === key ? 'primary' : 'default'}
                  onClick={() => {
                    this.handleModelSelect(key, value)
                    console.log(this.bpmnModeler, 'this.bpmnModeler')
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </Space> */}
        </div>
        <div
          className="container"
          style={{ display: selectedProcess === null ? 'none' : 'block' }}
          id="js-drop-zone"
        >
          <div className="canvas" id="canvas" />
          <div
            className={`properties-panel-fold
                                ${hideCount === 1
                ? hidePanel
                  ? 'fold'
                  : ''
                : ''
              }
                                ${hideCount === 1
                ? hideFold
                  ? 'hide'
                  : ''
                : ''
              }
                            `}
            id="js-panel-fold"
            title="折叠"
            onClick={this.handlePanelFold}
          />
          <div
            className={`properties-panel-parent ${hideCount === 1 ? (hidePanel ? 'hidePanel' : 'showPanel') : ''
              }`}
            id="properties-panel"
            style={{
              height: '100%',
            }}
          />
          <EditingTools
            selectedProcess={selectedProcess}
            buttonText={buttonText}
            onOpenFIle={this.handleOpenFile}
            onSave={this.handleSave}
            onUndo={this.handleUndo}
            onRedo={this.handleRedo}
            onDownloadSvg={this.handleDownloadSvg}
            onDownloadXml={this.handleDownloadXml}
            onZoomIn={() => this.handleZoom(0.1)}
            onZoomOut={() => this.handleZoom(-0.1)}
            onZoomReset={() => this.handleZoom()}
            onPreview={this.handlePreview}
            onPreviewXml={this.handlePreviewXml}
          />
        </div>
      </>
    )
  }
}

export default ProcessDesign
