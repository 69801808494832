import React, { useEffect, useState } from 'react'
import { SettingBasicPage } from './SettingBasicPage'
import { Layout, Breadcrumb, Anchor, Tabs } from 'antd'
import { useSelector } from 'src/hook'
import { Iconfont } from 'src/components'
import 'src/styles/layout.scss'
import styles from './settingPage.module.scss'
import {getDataMigration} from "../../api";
import { useHistory, useLocation } from 'react-router-dom'
const { Header, Sider, Content } = Layout
const { Link } = Anchor

const SettingConfig = [
  { label: 'UI配置', id: '#LogoImageCard' },
  { label: '应用密钥', id: '#AppSecretPage' },
  { label: '邮箱设置', id: '#EmailCard' },
  { label: '短信网关设置', id: '#SmsGatewayCard' },
  { label: '单点登录配置', id: '#SingleSignOnCard' },
  { label: '基础设置', id: '#OtherCard' },
  { label: '密码策略', id: '#PasswordCard' },
  { label: '解析配置', id: '#ParsingCard' },
  { label: '授权信息', id: '#AuthorizationCard' },
  { label: '数据迁移', id: '#UploadCqDataCard' },
  { label: '告警配置', id: '#WarnConifg' },
]

const SettingAside = (
  <Anchor
    getContainer={() => document.getElementById('anchor-box') as HTMLElement}
    affix={false}
    className="cq-anchor"
  >
    {SettingConfig.map(({ label, id }) => (
      <Link href={id} title={label} key={id}></Link>
    ))}
  </Anchor>
)

let dataMigration = false;

getDataMigration().then((enable) => {
  dataMigration = enable;
})

export const SettingPage = () => {
  const {
    userInfo
  } = useSelector((state) => state.login)
  const [tabsKey, setTabesKey] = useState(userInfo?.version !== 'community' ? 'LogoImageCard' : 'EmailCard')
  const [subTabKey, setSubTabKey] = useState<string>('')
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const state = location?.state as any
    if (state?.tabKey) {
      setTabesKey(state?.tabKey)
      setSubTabKey(state?.subTabKey)
      history.replace({
        ...location,
        state: null, // 将 state 置空
      });
    } else {
      setSubTabKey('')
    }
  }, [tabsKey])

  const SettingTabs = (
    <Tabs
      className={styles.settingPagesTabs}
      tabPosition={'left'}
      type="card"
      activeKey={tabsKey}
      onChange={(e) => {
        setTabesKey(e)
      }}
    >
      {
        userInfo?.version !== 'community' && <Tabs.TabPane tab={
          <>
            <Iconfont type="icon-UIpeizhi"></Iconfont>
            <span>UI配置</span>
          </>
        } key="LogoImageCard" />
      }
       <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-jichupeizhi"></Iconfont>
          <span>应用密钥</span>
        </>
      } key="AppSecretPage" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-youxiangshezhi"></Iconfont>
          <span>邮箱设置</span>
        </>
      } key="EmailCard" />
      {
        userInfo?.version !== 'community' && <Tabs.TabPane tab={
          <>
            <Iconfont type="icon-duanxinshezhi"></Iconfont>
            <span>短信网关设置</span>
          </>
        } key="SmsGatewayCard" />
      }
      {
        userInfo?.version !== 'community' && <Tabs.TabPane tab={
          <>
            <Iconfont type="icon-dandiandenglushezhi"></Iconfont>
            <span>单点登录配置</span>
          </>
        } key="SingleSignOnCard" />
      }
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-jichupeizhi"></Iconfont>
          <span>基础设置</span>
        </>
      } key="OtherCard" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-mimashezhi"></Iconfont>
          <span>密码策略</span>
        </>
      } key="PasswordCard" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-jiexipeizhi"></Iconfont>
          解析配置
        </>
      } key="ParsingCard" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-shouquanxinxi"></Iconfont>
          <span>授权信息</span>
        </>
      } key="AuthorizationCard" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-shuiyinshezhi"></Iconfont>
          <span>水印设置</span>
        </>
      } key="WatermarkCard" />
      <Tabs.TabPane tab={
        <>
          <Iconfont type="icon-fangwenshezhi"></Iconfont>
          <span>访问设置</span>
        </>
      } key="AccessPolicyCard" />
      {
        (userInfo?.version !== 'community' && dataMigration) && <Tabs.TabPane tab={
            <>
              <Iconfont type="icon-jiexipeizhi"></Iconfont>
              数据迁移
            </>
          } key="UploadCqDataCard" />
      }
      {
        <Tabs.TabPane tab={
            <>
              <Iconfont type="icon-gaojingpeizhi"></Iconfont>
              告警配置
            </>
          } key="AlarmConfigCard" />
      }
    </Tabs>
  )
  return (
    <Layout className="cq-container">
      <Header className="breadcrumb-header">
        <Breadcrumb className="breadcrumb" separator=''>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <Breadcrumb.Item>系统设置</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <Layout className="cq-main setting-warp" style={{padding: '0 10px 0 0'}}>
        <Sider className="cq-aside" width={246}>
          <section className="cq-card" style={{ background: '#F7F9FC', border: 'none' }}>
            <div className={styles.settingHeaderTitle} >
              系统设置
            </div>
            <div className="cq-card__content" style={{ width: '94%', margin: '0 auto' }}>
              {SettingTabs}
            </div>
          </section>
        </Sider>
        <Content className="cq-content" id="anchor-box" style={{marginLeft: '10px'}}>
          <div className='cq-content-card' style={{}}>
            <SettingBasicPage activeKey={tabsKey} subTabKey={subTabKey}/>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
