import { QueryBase } from 'src/api'
import { SqlSplitter, TransactSqlSplitter } from 'src/util'

interface Options extends QueryBase {
  size?: number
}

type QueryPoolRequest = (
  params: {
    statements: string[]
    segmentIndex: number
  } & QueryBase,
) => Promise<void>

/**
 * 查询池，用于处理一轮查询逻辑
 */
export class QueryPool {
  constructor(request: QueryPoolRequest, options: Options) {
    const { size = 50, ...rest } = options
    this.size = size
    this.request = request
    this.queryContext = rest
  }
  /** 查询请求 */
  private request: QueryPoolRequest
  /** 查询的连接上下文 */
  private queryContext: QueryBase
  /** 当前请求池中所有的语句 */
  private pool?: string[]
  /** 每个 segment 的长度 */
  private size: number
  /** 分割后的查询语句分片数组 */
  private segments?: string[][]
  /** 分割起始点 */
  private segmentIndex: number = 0
  /** pool generator */
  private generator?: Generator<Promise<void>>

  /**
   * 开启一轮语句查询
   */
  public query(text: string) {
    const { plSql, tSql } = this.queryContext
    const splitter = tSql
      ? new TransactSqlSplitter(text)
      : new SqlSplitter(text)
    // ! 这儿判断混乱是因为查询窗口信息的数据结构定义又问题
    // ! tSql 情况下 plSql 字段也被写成了 true，建议用一个枚举类型来区分
    if (tSql || !plSql) {
      // this.pool = splitter.splitWithComment()
      // this.pool = CaptureSingleLineCommentMergeToPrevious(splitter.splitWithComment())
      /** PL TSql pg monaco 不分句 */
      this.pool = [text]
    } else {
      this.pool = [text]
    }

    this.slicePool()
    this.generator = this.initQueryGenerator()
    this.generator.next()
  }

  /**
   * 初始化查询迭代器
   */
  private *initQueryGenerator() {
    while (this.segments?.length) {
      const segment = this.segments.shift()
      if (segment) {
        const p = this.request({
          statements: segment,
          segmentIndex: this.segmentIndex,
          ...this.queryContext,
        })
        yield p
        this.segmentIndex += this.size
      }
    }
  }

  /**
   * 从 segmentIndex 处按 size 切分 pool
   */
  public slicePool(from: number = 0) {
    if (!this.pool) return
    this.segmentIndex = from
    let mark = this.segmentIndex
    let segments = []
    while (mark < this.pool.length) {
      const segment = this.pool.slice(mark, mark + this.size)
      segments.push(segment)
      mark += this.size
    }
    this.segments = segments
  }

  /**
   * 获取查询上下文
   */
  public getQueryContext() {
    return this.queryContext
  }

  // 获取查询语句
  public getQuerysegments() {
    return this.pool
  }

  /**
   * 获取查询迭代器
   */
  public getQueryGenerator() {
    return this.generator
  }
}
