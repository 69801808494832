import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { ICellRendererParams } from '@ag-grid-community/core'
import styles from './BooleanEditor.module.scss'
import { Switch } from 'antd'

interface BooleanEditorProps extends ICellRendererParams {
  values: string[]
}

export const BooleanEditor = forwardRef((props: BooleanEditorProps, ref) => {
  const [value, setValue] = useState(props.value)
  const [editing, setEditing] = useState(true)
  const refContainer = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value
      },
      isPopup() {
        return true
      },
      afterGuiAttached: () => {
        getSelection()?.empty()
      },
    }
  })

  // todo: 渲染组件时 focus
  // todo: 键盘操作

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing()
    }
  }, [editing, props.api])

  return (
    <div
      ref={refContainer}
      className={styles.booleanEditor}
      tabIndex={1} // ag-grd: important - without this the key presses wont be caught
    >
      <Switch
        className={styles.switch}
        defaultChecked={value}
        onChange={(checked) => {
          setValue(checked)
          setEditing(false)
        }}
        size="small"
      ></Switch>
    </div>
  )
})
