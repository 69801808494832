import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RoleEntity } from 'src/api'
import { AppThunk } from 'src/store'


interface MySysRolesState {
    tabsKey: string | number
    rolesSysData: RoleEntity[]
    rolesObj: {
        description: string
        valid: boolean
        validPeriod: string
        userInfos: any[]
        roleName: string
        roleId: number | string 
        [p: string]: any
    }
    viewRoles: any[],
    authorizationSucceeds: boolean

}
const initialState: MySysRolesState = {
    tabsKey: '',
    rolesSysData: [],
    rolesObj: {
        description: '',
        valid: false,
        validPeriod: '',
        userInfos: [],
        roleName: '',
        roleId: '',
        roleTemplate: '',
    },
    viewRoles: [],
    authorizationSucceeds: false
}
export const mySysRolesSlice = createSlice({
    name: 'mySysRoles',
    initialState,
    reducers: {
        setTabsKeyRoleId: (state, action: PayloadAction<string | number>) => {
            state.tabsKey = action.payload
            state.rolesSysData.forEach(item => {
                if (String(item.roleId) == action.payload) {
                    state.rolesObj = {
                        description: item?.description,
                        valid: item?.valid,
                        validPeriod: item?.validPeriod,
                        userInfos: item?.userInfos,
                        roleName: item?.roleName,
                        roleId: item?.roleId,
                        type: item?.type,
                        roleTemplate: item?.roleTemplate,
                    }
                }
            })
        },
        setSysRoles: (state, action: PayloadAction<RoleEntity[]>) => {
            state.rolesSysData = action.payload
        },
        setViewingRoles: (state, action: PayloadAction<any[]>) => {
            state.viewRoles = action.payload
        },
        setauthorizationSucceeds: (state, action: PayloadAction<any>) => {
            state.authorizationSucceeds = action.payload
        }
    }
})

export const mySysRolesReducer = mySysRolesSlice.reducer
export const { setTabsKeyRoleId, setSysRoles, setViewingRoles, setauthorizationSucceeds } = mySysRolesSlice.actions
